/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { AppFeatureBadge } from './AppFeatureBadge';

export const AppFeatureListItem = ({
  children,
  enabled,
  isDiveCenterSpecificFeature,
  label,
  className = '',
}: {
  children?: React.ReactNode | React.ReactNode[];
  enabled: boolean;
  isDiveCenterSpecificFeature?: boolean;
  label: string;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  return (
    <li
      className={`-m-1 p-1 rounded-lg transition ease-in-out duration-150 ${className}`}
    >
      <AppFeatureBadge
        children={children}
        enabled={enabled}
        extraBadgeContent={
          isMultiDiveCenters && isDiveCenterSpecificFeature ? (
            <DiveCenterProBadge
              className="inline-block mx-auto"
              diveCenter={diveCenterResume}
            />
          ) : null
        }
        label={label}
      />
    </li>
  );
};
