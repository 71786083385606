/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';

import { AppFormControlRHF, AppInputBooleanSwitchRHF } from 'src/lib/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { ClubSettingsGeneralFormModel } from '../../../_model';
export const ClubSettingsGeneralMainFormFeatures = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return !isSuperAdmin ? null : (
    <ClubSettingsSection title="Fonctionnalités">
      <div className="grid gap-4 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
        <AppFormControlRHF
          label="Facturation"
          control={form.control}
          name={'clubSettings.general.billing.enabled'}
          required={false}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        <AppFormControlRHF
          label="Taxes"
          helpDescription="Ajouter les taxes à la facturation"
          control={form.control}
          name={'clubSettings.general.taxes.enabled'}
          required={false}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        <AppFormControlRHF
          label="Paiements"
          control={form.control}
          name={'clubSettings.general.payments.enabled'}
          required={false}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        <AppFormControlRHF
          label="Hébergements"
          helpDescription="Indiquez dans quel hôtel logent vos clients"
          control={form.control}
          name={'clubSettings.general.accommodations.enabled'}
          required={false}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        <AppFormControlRHF
          label="Agences"
          helpDescription="Associez les réservations aux agences de voyages"
          control={form.control}
          name={'clubSettings.general.agencies.enabled'}
          required={false}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        <AppFormControlRHF
          label="Questionnaires"
          helpDescription="Collectez les informations de vos clients"
          control={form.control}
          name={'clubSettings.general.inquiry.enabled'}
          required={false}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
      </div>
    </ClubSettingsSection>
  );
};
