/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubAuthUserProfile } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import {
  ClubUserProfilesAuthorizationTableBody,
  ClubUserProfilesAuthorizationTableHeader,
} from './ClubUserProfilesAuthorizationTable';

export const ClubUserProfilesDescription = ({
  clubProfiles,
  className,
}: {
  clubProfiles: ClubAuthUserProfile[];
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        'my-2 app-card p-4 md:divide-y divide-gray-200',
        className,
      )}
    >
      <div className={'hidden lg:flex gap-x-4 py-1'}>
        <div className="w-60 text-xs text-gray-400 font-bold uppercase">
          Profil
        </div>
        <div className="flex-grow">
          <ClubUserProfilesAuthorizationTableHeader className="w-full" />
        </div>
      </div>
      {clubProfiles.map((clubProfile, i) => (
        <div
          key={clubProfile._id}
          className={
            'mb-4 lg:mb-0 flex flex-col lg:flex-row lg:items-center gap-x-4'
          }
        >
          <h3 className="lg:w-60 text-sm font-bold uppercase text-app-primary">
            {clubProfile.label}
          </h3>
          <div className="mt-2 lg:mt-0 flex-grow">
            <ClubUserProfilesAuthorizationTableHeader className="w-full lg:hidden" />
            <ClubUserProfilesAuthorizationTableBody
              className="w-full"
              authorizations={clubProfile.authorizations}
            />
          </div>
        </div>
      ))}
      {/* <ClubUserProfilesDescription /> */}
    </div>
  );
};
