/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubProductPackageOffer,
  ClubProductPackageOfferView,
  DiveMode,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import React, { useCallback, useMemo, useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppPageContainer,
  AppPageContentContainer,
  AppTabsBar,
  AppTabsBarTab,
  SearchInput,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppActionButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlV2, AppSingleAutocomplete2 } from 'src/lib/form';
import { ValueLabel } from '../../../_common/form/components/ValueLabel.model';
import { AppHamburgerMenu, AppHamburgerMenuItem } from '../../../_common/ui';
import { clubSettingsOffersUrlBuilder } from '../../../url-builders';
import { CLUB_OFFER_SPACE_TABS, ClubOfferTabId } from '../_common';
import { useOfferListPageLoadData } from '../_common/hooks';
import { ClubOfferDiveEditor } from './ClubOfferDiveEditor';
import { ClubOfferDiveEditorResult } from './ClubOfferDiveEditor/ClubOfferDiveEditorResult.type';
import { ClubOfferDiveListPageState } from './ClubOfferDiveListPageState.type';
import { ClubOfferDiveMultiEditor } from './ClubOfferDiveMultiEditor';
import { ClubOfferDiveTable } from './ClubOfferDiveTable';
import { getOffersDivePlanGenericGeneratorOptions } from './offers-generator/configurations';
import { GenerateOffersOptions } from './offers-generator/model';
import { offersDivePlanGenerator } from './offers-generator/services';
export const ClubOfferDiveListPage = () => {
  const autoFocus = useAutoFocus();
  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const [searchText, setSearchText] = useState('');
  const [diveMode, setDiveMode] = useState('');

  const [state, setState] = useState<ClubOfferDiveListPageState>({
    mode: 'view',
  });

  const isAdvancedEditEnabled = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const generateOffers = useCallback(async () => {
    if (
      await confirmDialog.confirmPromise({
        title: 'Générer les prestations',
        message: 'Êtes-vous sûr de vouloir générer les prestations?',
        type: 'noYesDanger',
      })
    ) {
      const options: GenerateOffersOptions =
        getOffersDivePlanGenericGeneratorOptions();

      let offers: ClubProductPackageOffer[] = [];

      if (options.divePlan) {
        offers = offers.concat(
          offersDivePlanGenerator.generateDivePlansOffers({
            clubReference,
            options: options.divePlan,
          }),
        );
      }

      if (offers.length) {
        await clubMassiveUpdatorClient.update({
          newProductPackageOffers: offers,
        });
      }
    }
  }, [clubReference]);

  const productPackageOffersLoaded = useOfferListPageLoadData({
    type: 'dive',
    searchText,
    sortBy: 'dive',
  });
  const diveModeOptions = useMemo(
    () =>
      (
        [
          'observer',
          'snorkeling',
          'snorkelingSupervised',
          'watchingTour',
          'first-dive',
          // 'training',
          'supervised',
          'autonomous',
          'theoretical-training',
          'free-dive',
          'instructor',
          'autoSupervised',
          // 'unknown',
        ] as DiveMode[]
      )
        .filter(
          (diveMode) =>
            productPackageOffersLoaded.findIndex(
              (x) => x.productPackage.diveAttributes.diveMode === diveMode,
            ) !== -1,
        )
        .map((diveMode) => {
          const option: ValueLabel<DiveMode> = {
            label: diveModeFormatter
              .formatDiveMode(diveMode, { format: 'short-ref-label' })
              .toUpperCase(),
            value: diveMode,
          };
          return option;
        }),
    [productPackageOffersLoaded],
  );

  const productPackageOffers = useMemo(
    () =>
      diveMode
        ? productPackageOffersLoaded.filter(
            (x) => x.productPackage?.diveAttributes?.diveMode === diveMode,
          )
        : productPackageOffersLoaded,
    [diveMode, productPackageOffersLoaded],
  );

  const hamburgerMenuItems = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    if (state.mode === 'view') {
      if (isAdvancedEditEnabled) {
        hamburgerMenuItems.push({
          id: 'create-offer',
          title: 'Ajouter',
          description: 'Ajouter une prestation',
          icon: AppHeroIcons.actionAdd,
          onClick: () => {
            setState({
              mode: 'create',
            });
          },
        });
      }
      hamburgerMenuItems.push({
        id: 'edit-offers',
        title: 'Modifier',
        description: 'Modifier les tarifs des prestations visibles',
        icon: AppHeroIcons.actionEdit,
        onClick: () => {
          setState({
            mode: 'edit-multi',
          });
        },
      });
      if (isAdvancedEditEnabled) {
        hamburgerMenuItems.push({
          id: 'generate-offers',
          title: 'Générer les prestations',
          description:
            'Génération des prestations par défaut en fonction de la configuration du club',
          icon: AppHeroIcons.autoFill,
          onClick: async () => {
            generateOffers();
          },
        });
      }
    }
    return hamburgerMenuItems;
  }, [state.mode, isAdvancedEditEnabled, generateOffers]);

  const quickAction: AppHamburgerMenuItem = useMemo(() => {
    {
      const action = hamburgerMenuItems.find((x) => x.id === 'create-offer');
      if (action && !action.hidden) {
        return action;
      }
    }
    {
      const action = hamburgerMenuItems.find((x) => x.id === 'edit-offers');
      if (action && !action.hidden) {
        return action;
      }
    }
  }, [hamburgerMenuItems]);

  const onCreateOrUpdateOffer = useCallback(
    async (result: ClubOfferDiveEditorResult) => {
      setState({
        mode: 'view',
      });
      if (result) {
        await clubMassiveUpdatorClient.update({
          newProductPackageOffers: result.newOffer
            ? [result.newOffer]
            : undefined,
          updatedProductPackageOffers: result.updatedOffer
            ? [result.updatedOffer]
            : undefined,
        });
      }
    },
    [],
  );
  const onDeleteOffer = useCallback(
    async (offer: ClubProductPackageOfferView) => {
      if (offer) {
        await clubMassiveUpdatorClient.update({
          deletedProductPackageOfferIds: [offer._id],
        });
      }
    },
    [],
  );
  const onConfirmOffersChanges = useCallback(
    async ({
      updatedProductPackageOffers,
    }: {
      updatedProductPackageOffers: AppEntityUpdatePatch[];
    }) => {
      setState({
        mode: 'view',
      });
      if (updatedProductPackageOffers.length) {
        await clubMassiveUpdatorClient.update({
          updatedProductPackageOffers,
        });
      }
    },
    [],
  );
  return (
    <AppPageContainer className={'bg-white'}>
      {/* <ClubOfferBreadcrumbBar /> */}

      <AppTabsBar
        theme="settings"
        tabs={CLUB_OFFER_SPACE_TABS}
        value="offers-dive"
        onChange={(tab) => selectTab(tab)}
      />

      <AppPageContentContainer className="bg-white app-px-content">
        {state.mode === 'view' && (
          <>
            <div
              className={`${globalClasses.pageBlock} w-full flex flex-col md:flex-row gap-2`}
            >
              <SearchInput
                className="flex-grow"
                initialValue={searchText}
                autoFocus={autoFocus}
                onSearchChange={(text) => setSearchText(text)}
                placeholder="Recherche"
              />
              <div className="flex gap-2 justify-end">
                <AppFormControlV2
                  className="w-full md:w-60"
                  label="Type de plongée"
                  renderComponent={() => (
                    <AppSingleAutocomplete2
                      className="w-full md:w-60"
                      fullWidth={false}
                      options={diveModeOptions}
                      value={diveMode}
                      onChange={(diveMode) => {
                        setDiveMode(diveMode);
                      }}
                    />
                  )}
                />
                {quickAction && (
                  <AppActionButton className="ml-2" action={quickAction} />
                )}
                {(!quickAction || hamburgerMenuItems.length !== 1) && (
                  <AppHamburgerMenu
                    buttonClassName="ml-2 py-3 bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white"
                    position="left-start"
                    items={hamburgerMenuItems}
                    buttonIcon={AppHeroIcons.actionMenu}
                    buttonLabel={'Prestations'}
                  />
                )}
              </div>
            </div>

            <div className={globalClasses.pageBlock}>
              <ClubOfferDiveTable
                productPackageOffers={productPackageOffers}
                setState={setState}
                onDelete={onDeleteOffer}
              />
            </div>
          </>
        )}
        {(state.mode === 'create' ||
          state.mode === 'edit-single' ||
          state.mode === 'duplicate-single') && (
          <>
            <div className={globalClasses.pageBlock}>
              <ClubOfferDiveEditor
                onCancel={() =>
                  setState({
                    mode: 'view',
                  })
                }
                context={{
                  mode:
                    state.mode === 'create'
                      ? 'create'
                      : state.mode === 'duplicate-single'
                      ? 'duplicate'
                      : 'edit',
                  offer: state.selectedOffer,
                }}
                onConfirm={(offer) => onCreateOrUpdateOffer(offer)}
              />
            </div>
          </>
        )}
        {state.mode === 'edit-multi' && (
          <>
            <div className={globalClasses.pageBlock}>
              <ClubOfferDiveMultiEditor
                productPackageOffers={productPackageOffers}
                onCancel={() => setState({ mode: 'view' })}
                onConfirm={({ updatedProductPackageOffers }) =>
                  onConfirmOffersChanges({ updatedProductPackageOffers })
                }
              />
            </div>
          </>
        )}
      </AppPageContentContainer>
    </AppPageContainer>
  );

  function selectTab(tab: AppTabsBarTab<ClubOfferTabId>) {
    return redirectTo(
      clubSettingsOffersUrlBuilder.buildTabUrl({
        tabId: tab.id,
      }),
    );
  }
};
