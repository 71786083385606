import { OnlineBookingResumeForList } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useProgressiveListRendering } from 'src/business/_core/data/hooks';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubOrdersTableRow } from './ClubOrdersTableRow';

export function ClubOrdersTable({
  orders: ordersInput,
  className,
}: {
  orders: OnlineBookingResumeForList[];
  className?: string;
}) {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const ordersToRender = useProgressiveListRendering(ordersInput, {
    initialSize: 50,
    step: 100,
  });

  return (
    <table className={clsx('app-table min-w-full', className)}>
      <thead>
        <tr>
          {isMultiDiveCenters && (
            <th
              scope="col"
              className="px-2 py-1  uppercase tracking-wider hidden xs:table-cell"
            >
              Centre
            </th>
          )}
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden lg:table-cell"
          >
            Création
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden xs:table-cell"
          >
            Mise à jour
          </th>
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Contact
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden lg:table-cell"
          >
            Période plongées
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden md:table-cell"
          >
            Plongeurs
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden md:table-cell"
          >
            Montant
          </th>
          {/* <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Total plongées
          </th> */}
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Statut
          </th>
        </tr>
      </thead>
      <tbody>
        {ordersToRender.map((onlineBooking) => (
          <ClubOrdersTableRow
            key={onlineBooking._id}
            onlineBooking={onlineBooking}
          />
        ))}
      </tbody>
    </table>
  );
}
