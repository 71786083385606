import { useCallback, useEffect, useState } from 'react';
import { browserCache } from 'src/_common-browser';
import {
  TailwindBreakpoint,
  useMediaSizeTailwind,
} from 'src/business/_core/modules/layout';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  PlanningViewConfig,
  PlanningViewConfigContextId,
} from '../../club-planning/_model';
import { JWT_PLANNING_CONFIG_PLANNING_PAGE_STORAGE_ID } from './JWT_PLANNING_CONFIG_PLANNING_PAGE_STORAGE_ID.const';
import { JWT_PLANNING_CONFIG_SESSION_SELECTOR_DIALOG_STORAGE_ID } from './JWT_PLANNING_CONFIG_SESSION_SELECTOR_DIALOG_STORAGE_ID.const';
import { PLANNING_CONFIG_CONTEXT_ID_PLANNING_STAFF_VIEW } from './PLANNING_CONFIG_CONTEXT_ID_PLANNING_STAFF_VIEW.const';
import { useInitialPlanningConfig } from './useInitialPlanningConfig.hook';
import { useUpdateURLFromPlanningConfig } from './useUpdateURLFromPlanningConfig.hook';
import { useUpdateUiStoreFromPlanningConfig } from './useUpdateUiStoreFromPlanningConfig.hook';

export type SetPlanningConfigOptions = {
  origin: string;
};

export function usePlanningConfig({
  enableRouteParams,
  context,
  defaultFocusDate,
}: {
  enableRouteParams: boolean;
  context: PlanningViewConfigContextId;
  defaultFocusDate?: Date;
}): [
  PlanningViewConfig,
  (config: PlanningViewConfig, options: SetPlanningConfigOptions) => void,
] {
  const initialPlanningConfig = useInitialPlanningConfig({
    enableRouteParams,
    context,
    defaultFocusDate,
  });
  const [initMediaSize, setInitiMediaSize] = useState<TailwindBreakpoint>();
  const mediaSize = useMediaSizeTailwind();

  const [planningConfig, setPlanningConfigInner] = useState<PlanningViewConfig>(
    initialPlanningConfig,
  );

  const securityUser = useAppSecurityUser();
  const setPlanningConfig = useCallback(
    (config: PlanningViewConfig, options: SetPlanningConfigOptions) => {
      if (appWebConfig.envId === 'dev') {
        // eslint-disable-next-line no-console
        console.log(
          `[setPlanningConfig] ${options?.origin} viewPeriod="${config.viewPeriod}", focusSessionReference="${config.focusSessionReference}"`,
        );
      }
      setPlanningConfigInner(config);
      if (context === 'planning-selector') {
        browserCache
          .set(JWT_PLANNING_CONFIG_SESSION_SELECTOR_DIALOG_STORAGE_ID, config, {
            ignoreError: true,
          })
          .subscribe();
      } else if (context === 'planning-view') {
        browserCache
          .set(JWT_PLANNING_CONFIG_PLANNING_PAGE_STORAGE_ID, config, {
            ignoreError: true,
          })
          .subscribe();
      } else if (context === 'planning-staff') {
        browserCache
          .set(PLANNING_CONFIG_CONTEXT_ID_PLANNING_STAFF_VIEW, config, {
            ignoreError: true,
          })
          .subscribe();
      }
    },
    [context],
  );

  const isMediaSizeInitialized = !!mediaSize;
  useEffect(() => {
    if (isMediaSizeInitialized && !initMediaSize) {
      // update config once mediaSize has been initialized
      setPlanningConfig(initialPlanningConfig, {
        origin:
          '[usePlanningConfig] useEffect (isMediaSizeInitialized && !initMediaSize)',
      });
      setInitiMediaSize(mediaSize);
    }
  }, [
    initMediaSize,
    initialPlanningConfig,
    mediaSize,
    isMediaSizeInitialized,
    setPlanningConfig,
  ]);

  useUpdateURLFromPlanningConfig(planningConfig, {
    enableRouteParams: enableRouteParams && isMediaSizeInitialized,
  });
  useUpdateUiStoreFromPlanningConfig(planningConfig, {
    context: isMediaSizeInitialized ? context : undefined,
  });

  return [planningConfig, setPlanningConfig];
}
