/* eslint-disable @typescript-eslint/no-unused-vars */
import { Transition } from '@headlessui/react';
import {
  commonDiveSessionReferenceParser,
  dateService,
  diveSessionsSorter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubEventCardGroup } from '../../club-events';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningLightPanelStaffDailyCounters } from '../ClubPlanningLightPanelViewPeriodDay';
import {
  CreateEventButton,
  CreateSessionButton,
  DiveSessionSecuritySheetCard,
  DiveSessionSecuritySheetCardMenuActionsDef,
} from '../components';
import { SetPlanningConfigOptions } from '../hooks';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
} from '../model';
import { usePlanningViewPeriosSessionSwipe } from '../usePlanningViewPeriosSessionSwipe.hook';

export const ClubPlanningLightPanelViewPeriodSession = ({
  days,
  planningConfig,
  setPlanningConfig,
  clickActions,
  sessionsDisplayConfigs = [],
  sessionCardMenuActions,
  className,
}: {
  days: ClubPlanningDayFull[];
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  clickActions: ClubPlanningLightPanelViewClickActions;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  sessionCardMenuActions?: DiveSessionSecuritySheetCardMenuActionsDef;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume?.clubReference;
  const day = useMemo(() => {
    if (days) {
      return days.find((d) =>
        dateService.isSameDayUTC(planningConfig.focusDate, d.date),
      );
    }
  }, [days, planningConfig.focusDate]);

  const {
    onClickEvent,
    onClickSession,
    onClickSessionOpenButton,
    onClickAddParticipant,
    onClickEditSessionStaffMembers,
    onClickParticipant,
    anchorLinkParticipant,
    onClickCreateNewSession,
    onClickCreateNewEvent,
    onClickGroupDetails,
  } = clickActions;
  const { session, sessionIndex } = useMemo(() => {
    if (day?.sessions?.length) {
      const sortedSessions = diveSessionsSorter.sortDiveSessionsBy(
        day.sessions,
        {
          attributeName: 'time',
          asc: true,
        },
      );

      let sessionIndex = sortedSessions.findIndex(
        (s) => s.reference === planningConfig.focusSessionReference,
      );
      if (sessionIndex !== -1) {
        return {
          session: sortedSessions[sessionIndex],
          sessionIndex: {
            index: sessionIndex + 1,
            total: sortedSessions.length,
          },
        };
      }

      if (sortedSessions.length) {
        let session = sortedSessions.find((x) => x.participants.length !== 0); // return first non-empty session
        if (session) {
          setPlanningConfig(
            {
              ...planningConfig,
              focusSessionReference: session.reference,
              viewSessionOptions: {
                ...planningConfig.viewSessionOptions,
              },
            },
            {
              origin:
                '[ClubPlanningLightPanelViewPeriodSession] useMemo effect: first non-empty session',
            },
          );
        } else if (sortedSessions.length) {
          const session = sortedSessions[0]; // default: first session
          setPlanningConfig(
            {
              ...planningConfig,
              focusSessionReference: session.reference,
              viewSessionOptions: {
                ...planningConfig.viewSessionOptions,
              },
            },
            {
              origin:
                '[ClubPlanningLightPanelViewPeriodSession] useMemo effect: first session',
            },
          );
        } else {
          const focusDate = commonDiveSessionReferenceParser.parseDayReference(
            session.dayReference,
          );
          setPlanningConfig(
            {
              ...planningConfig,
              viewPeriod: 'day',
              focusDate,
            },
            {
              origin:
                '[ClubPlanningLightPanelViewPeriodSession] useMemo effect: day without session',
            },
          );
        }
      }
    }
    return {
      session: undefined,
      daySessionIndex: undefined,
    };
  }, [day?.sessions, planningConfig, setPlanningConfig]);

  const { transitionDirection, transitionHandlers } =
    usePlanningViewPeriosSessionSwipe({
      sessionReference: session?.reference,
      planningConfig,
      setPlanningConfig,
    });

  return day && session ? (
    <div
      className={`max-h-full overflow-y-auto overflow-x-hidden ${
        className ?? ''
      }`}
    >
      <div
        {...transitionHandlers}
        className={
          'w-full select-none block text-gray-700 text-sm leading-4 relative'
        }
      >
        <Transition
          key={session?.reference}
          show={true}
          className="relative w-full h-full transition duration-100"
          enter="ease-in-out"
          enterFrom={`${
            transitionDirection === true
              ? '-translate-x-1/2'
              : transitionDirection === false
              ? 'translate-x-1/2'
              : ''
          } opacity-90`}
          enterTo={'translate-x-0 opacity-100'}
          leave="ease-out"
          leaveFrom={'translate-x-0 opacity-100'}
          leaveTo={`${
            transitionDirection === true
              ? '-translate-x-1/2'
              : transitionDirection === false
              ? 'translate-x-1/2'
              : ''
          } opacity-90`}
        >
          {planningConfig.showStaffCountsPerDay && (
            <div className="my-1">
              <ClubPlanningLightPanelStaffDailyCounters
                planningConfig={planningConfig}
                date={day.date}
                showTooltip={true}
                staffMembersResumeSessions={day.staffMembersResumeSessions}
                className="inline-flex text-app-xxs md:text-xs"
                onClick={() => {
                  clickActions.onClickStaffDailyCounters &&
                    clickActions.onClickStaffDailyCounters({
                      clubReference,
                      day,
                    });
                }}
              />
            </div>
          )}
          {day.clubEvents?.length > 0 && (
            <ClubEventCardGroup
              clubEvents={day.clubEvents}
              widthMode={'large'}
              onClick={onClickEvent}
            />
          )}
          <DiveSessionSecuritySheetCard
            dailyConfig={day.dailyConfig}
            diveSession={session}
            sessionCardMenuActions={sessionCardMenuActions}
            hamburgerMenuPosition={'left-end'}
            display={{
              date: true,
              boats: true,
              tide: true,
              weather: true,
              comment: true,
              addParticipantButton1: false,
              addParticipantButton2: !!onClickAddParticipant,
              openSessionButton: false,
              isExpandable: false,
              sessionIndex,
            }}
            // NOTE: il faudrait revoir un peu tout ça en mode `full`, pour que les participants soient tout le temps visible, mais tout en ayant la possibilité d'"ouvrir" les détails, avec :
            // - le bouton "add" toujours visible
            // - le commentaire et le champ "open" seulement visible si on ouvre la session
            selectionColor={
              sessionsDisplayConfigs.find(
                (x) => x.diveSessionReference === session.reference,
              )?.style
            }
            onClickSession={onClickSession}
            onClickEditSessionStaffMembers={onClickEditSessionStaffMembers}
            onClickParticipant={
              !onClickParticipant
                ? undefined
                : ({ diveSession, participant }) =>
                    onClickParticipant({
                      session: diveSession,
                      participant,
                    })
            }
            anchorLinkParticipant={anchorLinkParticipant}
            onClickAddParticipant={onClickAddParticipant}
            onClickGroupDetails={
              !onClickGroupDetails
                ? undefined
                : ({ diveSession, participants, group }) =>
                    onClickGroupDetails({
                      diveSessionResume: diveSession,
                      participants,
                      group,
                    })
            }
            planningConfig={planningConfig}
          />
          <div className="mx-auto flex flex-col md:flex-row md:justify-center gap-2 md:gap-6 lg:gap-12">
            <CreateSessionButton
              className="px-2"
              mode="large"
              day={day}
              onClickCreateNewSession={onClickCreateNewSession}
            />
            <CreateEventButton
              className="px-2"
              mode="large"
              day={day}
              onClickCreateNewEvent={onClickCreateNewEvent}
            />
          </div>
        </Transition>
      </div>
    </div>
  ) : null;
};
