import { combineLatest } from 'rxjs';
import { rxjsFormBuilder } from 'src/lib/form/components/rxjs-form';
import { ChoosePasswordFormComponentsModel } from './ChoosePasswordFormComponentsModel.type';
import { ChoosePasswordFormModel } from './ChoosePasswordFormModel.type';

export const choosePasswordRepository = {
  loadInitialData,
}

function loadInitialData({
  defaultAuthLogin,
}: {
    defaultAuthLogin: string;
}) {

  const { actions, components$: choosePasswordComponents$, form$: choosePasswordForm$ } = buildForm();

  actions.updateInitialValue({
    userAuthEmailLogin: defaultAuthLogin,
  }, 'external');

  return combineLatest([
    choosePasswordForm$,
    choosePasswordComponents$
  ], (choosePasswordForm, choosePasswordComponents) => ({ choosePasswordForm, choosePasswordComponents }));

}

function buildForm() {

  return rxjsFormBuilder.buildForm<ChoosePasswordFormModel, ChoosePasswordFormComponentsModel>([{
    name: 'userAuthPassword',
    required: true,
  }, {
    name: 'userAuthEmailLogin',
    required: true,
  }]);
}
