/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubSettingsGeneralOnlineBookingEmailOption } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppIconsSocialMedia } from '../../../../../../../../business/_core/modules/layout/icons';
import { MessageTemplateLabel } from '../../../../../../../_components/options';

export const ClubSettingsOnlineBookingEmailsViewPanel_EmailOption = ({
  emailOption,
  label,
}: {
  emailOption: ClubSettingsGeneralOnlineBookingEmailOption;
  label: string;
}) => {
  const clubResume = useClubResume();

  const emailEnabled = emailOption?.enabled;
  const messageTemplateId = emailOption?.messageTemplateId;
  const copyToAddress = emailOption?.copyToAddress;

  const messageTemplate = useMemo(
    () =>
      emailEnabled && !!messageTemplateId
        ? (clubResume?.messageTemplates ?? []).find(
            (x) => x._id === messageTemplateId,
          )
        : undefined,
    [clubResume?.messageTemplates, emailEnabled, messageTemplateId],
  );
  return (
    <div>
      <h3
        className={clsx(
          'text-base  font-bold uppercase',
          emailEnabled ? 'text-app-primary' : 'text-gray-400',
        )}
      >
        <AppIconsSocialMedia.mail className="inline-block w-6 h-6 mr-2" />
        {label}
      </h3>
      {emailEnabled && (
        <div className="ml-8">
          {/* <AppFeatureBadge enabled={emailEnabled} label={'Activé'} /> */}
          <div>
            {messageTemplate?.name && (
              <div className="text-xs uppercase text-app-primary">
                <MessageTemplateLabel messageTemplate={messageTemplate} />
              </div>
            )}
            {copyToAddress?.trim()?.length > 0 && (
              <div className="mt-1 text-xs uppercase text-gray-600">
                Copie à{' '}
                <span className="font-bold text-gray-800">{copyToAddress}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
