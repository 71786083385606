import { Booking } from '@mabadive/app-common-model';
import { bookingMemberFullSorter } from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import {
  AppButton,
  AppTransition,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { BookingResumeOnClickMemberAction } from '../../../../_from-diver-app';
import {
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
  PRO_BookingResume,
} from '../../../models';
import { DiverBookingPageActions } from '../../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../../useDiverBookingPageGlobalState.hook';
import { BookingResumeMembersListItem } from './BookingResumeMembersListItem';

export function BookingResumeMembersList({
  booking,
  aggregatedBookingResume,
  bookingMembersFull,
  bookingParticipantsFull,
  futureSessionsCount,
  globalState,
  actions,
  onClickMember,
  className,
}: {
  booking: Booking;
  aggregatedBookingResume: PRO_BookingResume;
  bookingMembersFull: PRO_BookingMemberFull_WithDocs[];
  bookingParticipantsFull: PRO_BookingParticipantFull[];
  futureSessionsCount: number;
  globalState: DiverBookingPageGlobalState;
  actions: DiverBookingPageActions;
  onClickMember?: (
    bookingMemberFull: PRO_BookingMemberFull_WithDocs,
    action: BookingResumeOnClickMemberAction,
  ) => void;
  className?: string;
}) {
  const { bookingOpeningStates, setBookingOpeningStates } = globalState;

  const bookingId = booking._id;

  const { updateState } = globalState;

  const isExpanded = useMemo(() => {
    return (
      bookingOpeningStates.find((s) => s?.bookingId === bookingId)
        ?.isOpenMembers === true
    );
  }, [bookingOpeningStates, bookingId]);

  const setIsExpanded = useCallback(
    (isOpenMembers: boolean) => {
      const state = bookingOpeningStates.find((s) => s.bookingId === bookingId);
      if (state) {
        setBookingOpeningStates(
          bookingOpeningStates.map((s) => {
            if (s.bookingId === bookingId) {
              return {
                ...s,
                isOpenMembers,
              };
            }
            return s;
          }),
        );
      }
    },
    [bookingOpeningStates, bookingId, setBookingOpeningStates],
  );

  const bookingMembersFullSorted = useMemo(
    () =>
      bookingMemberFullSorter.sort(bookingMembersFull, {
        bookingContactDiverId: booking?.bookingContactDiverId,
      }),
    [booking.bookingContactDiverId, bookingMembersFull],
  );
  const isUnique = bookingMembersFullSorted.length === 1;

  const hiddenParticipantsCount = useMemo(() => {
    let count = bookingMembersFullSorted.length - 1;
    if (
      updateState.filteredDiverId &&
      booking.bookingContactDiverId !== updateState.filteredDiverId
    ) {
      count--;
    }
    return count;
  }, [
    booking.bookingContactDiverId,
    bookingMembersFullSorted.length,
    updateState.filteredDiverId,
  ]);

  return (
    <>
      <AppTransition
        type="scale"
        show={!isExpanded}
        as="div"
        className="min-w-full border border-gray-200 bg-white divide-y divide-gray-200 text-xs px-2 py-1"
      >
        {bookingMembersFullSorted
          .filter(
            (bookingMemberFull) =>
              bookingMemberFull.diver._id ===
                bookingMemberFull.booking.bookingContactDiverId ||
              bookingMemberFull.diver._id === updateState.filteredDiverId,
          )
          .map((bookingMemberFull) => (
            <BookingResumeMembersListItem
              key={bookingMemberFull.bookingMember._id}
              bookingMembersCount={bookingMembersFullSorted.length}
              bookingMemberFull={bookingMemberFull}
              aggregatedBookingResume={aggregatedBookingResume}
              booking={booking}
              globalState={globalState}
              onClick={(bookingMember, action) =>
                onClickMember ? onClickMember(bookingMember, action) : undefined
              }
            />
          ))}
      </AppTransition>

      <AppTransition
        type="scale"
        show={isExpanded}
        as="div"
        className="min-w-full border border-gray-200 bg-white divide-y divide-gray-200 text-xs px-2 py-1"
      >
        {bookingMembersFullSorted.map((bookingMemberFull) => (
          <BookingResumeMembersListItem
            key={bookingMemberFull.bookingMember._id}
            bookingMembersCount={bookingMembersFullSorted.length}
            bookingMemberFull={bookingMemberFull}
            aggregatedBookingResume={aggregatedBookingResume}
            booking={booking}
            globalState={globalState}
            onClick={(bookingMember, action) =>
              onClickMember ? onClickMember(bookingMember, action) : undefined
            }
          />
        ))}
      </AppTransition>
      {hiddenParticipantsCount > 0 && (
        <div className="w-full px-1 flex flex-col sm:flex-row gap-2">
          {bookingMembersFull.length > 5 && (
            <>
              {isExpanded ? (
                <AppButton
                  fullWidth={true}
                  color="primary-outline-light"
                  onClick={() => setIsExpanded(!isExpanded)}
                  icon={AppHeroIcons.eyeOff}
                >
                  Masquer les participants
                </AppButton>
              ) : (
                <AppButton
                  fullWidth={true}
                  color="primary-outline-light"
                  onClick={() => setIsExpanded(!isExpanded)}
                  icon={AppHeroIcons.eye}
                >
                  Afficher les {hiddenParticipantsCount} autres participants
                </AppButton>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
