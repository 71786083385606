/* eslint-disable @typescript-eslint/no-unused-vars */
import { diveSessionsSorter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useProgressiveListRendering } from 'src/business/_core/data/hooks';
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
} from '../model';
import { ClubPlanningLightViewDaySession } from './ClubPlanningLightViewDaySession';
import { ClubDialogsState } from '../../../../../pages/_dialogs';

export const ClubPlanningLightViewDaySessions = ({
  context,
  day,
  sessionsDisplayConfigs = [],
  clickActions,
  planningConfig,
  className,
  planningParticipantsLocalState,
  dialogsState,
}: {
  planningParticipantsLocalState?: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
  dialogsState?: ClubDialogsState; // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  context: ClubPlanningDisplayConfigurationBarContext;
  day: ClubPlanningDayFull;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  clickActions: ClubPlanningLightPanelViewClickActions;
  planningConfig: PlanningViewConfig;
  className?: string;
}) => {
  const dailySessions = useMemo(() => {
    const dailySessions = diveSessionsSorter.sortDiveSessionsBy(day.sessions, {
      attributeName: 'time',
      asc: true,
    });
    return dailySessions;
  }, [day.sessions]);

  const dailySessionsToRender = useProgressiveListRendering(dailySessions);

  return (
    <div
      className={clsx(
        `select-none text-gray-700 text-sm leading-4 
        grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10 gap-x-2 gap-y-1
    `,
        className,
      )}
    >
      {/* sessions resume */}
      {dailySessionsToRender.map((session, i) => (
        <ClubPlanningLightViewDaySession
          planningParticipantsLocalState={planningParticipantsLocalState}
          dialogsState={dialogsState}
          key={i}
          dailyConfig={day.dailyConfig}
          context={context}
          planningConfig={planningConfig}
          session={session}
          sessionIndex={{ index: i + 1, total: day.sessions.length }}
          sessionsDisplayConfigs={sessionsDisplayConfigs}
          clickActions={clickActions}
        />
      ))}
    </div>
  );
};
