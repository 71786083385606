import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppButton, AppMessageLight } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useClubSettings } from 'src/business/club/data/hooks';
import { SETTINGS_CUSTOMERS_PAGE_CUSTOMER_SPACE } from 'src/pages/SE-settings/SE-03-customers/SETTINGS_CUSTOMERS.const';
import { BookingCustomerConfigEditDialogInputState } from '../_model';
import { BookingCustomerConfigEditDialogLocalState } from '../useBookingCustomerConfigEditDialogLocalState.hook';
import { BookingCustomerConfigCustomerSpaceDiverLink } from './components';

export const BookingCustomerConfigEditDialogPanel2Links = ({
  localState,
  inputState,
  className,
}: {
  localState: BookingCustomerConfigEditDialogLocalState;
  inputState: BookingCustomerConfigEditDialogInputState;
  className?: string;
}) => {
  const { state, data, actions } = localState;

  const { contactLink, otherMembersLinks } = useMemo(
    () =>
      (data.diverLinks ?? []).reduce(
        (acc, diverLink) => {
          if (diverLink.isBookingContact) {
            acc.contactLink = diverLink;
          } else {
            acc.otherMembersLinks.push(diverLink);
          }
          return acc;
        },
        {
          contactLink: null,
          otherMembersLinks: [],
        },
      ),
    [data.diverLinks],
  );

  const az = useAppSecurityUserClubAuthorizations();

  const clubSettings = useClubSettings();

  const sendMessageEnabled =
    clubSettings.communication?.booking?.enableMessage &&
    az.communication?.action?.sendBookingMessage;
  const individualLinksEnabled =
    clubSettings.communication.customerSpace?.individualLinksEnabled;

  return (
    <div className={clsx('grid gap-2', className)}>
      <h3 className="uppercase text-base sm:text-lg text-app-blue font-bold">
        Connexion à l'espace client
      </h3>
      <AppMessageLight className="" hideIcon={true} hideBorder={true}>
        <>
          <p className="my-2 font-bold">
            Communiquez ce lien au client par mail ou SMS, pour lui donner accès
            au détail de sa réservation.
          </p>
          <p className="my-2">
            Pour personnaliser l'espace client, cliquez sur le bouton ci-dessous
            :
          </p>
          {az?.edit?.settings?.customerSpace && (
            <AppButton
              className="my-2xs:max-w-[18rem]"
              fullWidth={true}
              color="primary-outline-light"
              icon={AppHeroIcons.actionSettings}
              href={SETTINGS_CUSTOMERS_PAGE_CUSTOMER_SPACE.url}
              onClick={(e) => {
                e.preventDefault();
                actions.openCustomerSpaceConfigDialog();
              }}
            >
              Configurer l'espace client
            </AppButton>
          )}
        </>
      </AppMessageLight>
      {contactLink && (
        <BookingCustomerConfigCustomerSpaceDiverLink
          className="my-2"
          key={contactLink.url}
          inputState={inputState}
          diverLink={contactLink}
          persistChanges={actions.persistChanges}
          customerUpdateRequested={state.customerUpdateRequested}
        />
      )}
      {sendMessageEnabled && (
        <AppButton
          fullWidth={true}
          className=" xs:max-w-[12rem]"
          color="primary-outline-light"
          icon={AppHeroIcons.sms}
          onClick={() => {
            let bookingMembersFull = data.bookingMembersFull;
            let booking = data.initialBooking;

            state.dialogsState.createMessageToCustomersDialog.openDialog({
              mode: 'create',
              defaultValue: {
                //
              },
              context: 'booking',
              bookingContext: {
                booking,
                bookingMembersFull,
              },
            });
          }}
        >
          Envoyer un MESSAGE
        </AppButton>
      )}

      {individualLinksEnabled && otherMembersLinks.length > 0 && (
        <div>
          <div>
            <AppMessageLight hideIcon={false} hideBorder={true}>
              <b>NOUVEAU:</b> envoyez un lien individuel à chaque participant
            </AppMessageLight>
          </div>
          <div className="my-4 min-w-full border border-gray-200 bg-white divide-y divide-gray-200 text-xs px-2 py-1">
            {otherMembersLinks.map((diverLink) => (
              <BookingCustomerConfigCustomerSpaceDiverLink
                className="my-2"
                key={diverLink.url}
                inputState={inputState}
                diverLink={diverLink}
                persistChanges={actions.persistChanges}
                customerUpdateRequested={state.customerUpdateRequested}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
