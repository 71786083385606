/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveSession,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import { clubDiveSessionThemeBuilder } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { BiWater } from 'react-icons/bi';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
export const ClubDiveSessionHeaderComments = ({
  session,
  display,
  onClick = () => {},
  disableBgColor,
  className = '',
}: {
  session: Pick<
    DiveSession,
    | '_id'
    | 'diveSiteId'
    | 'boatsIds'
    | 'status'
    | 'name'
    | 'time'
    | 'comment'
    | 'importantNotes'
    | 'type'
    | 'theme'
    | 'tide'
    | 'weather'
  >;
  display: {
    tide: boolean;
    weather: boolean;
    comment: boolean;
  };
  onClick?: () => void;
  disableBgColor?: boolean;
  className?: string;
}) => {
  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );

  const isCommentVisible: boolean = display.comment && !!session.comment;
  const isImportantNotesVisible: boolean =
    display.comment && !!session.importantNotes;
  const isTideVisible: boolean = display.tide && !!session.tide;
  const isWeatherVisible: boolean = display.weather && !!session.weather;

  const hasAnyTitle =
    isImportantNotesVisible ||
    isCommentVisible ||
    isTideVisible ||
    isWeatherVisible;
  return (
    <>
      {hasAnyTitle && (
        <div
          className={clsx(
            'cursor-pointer ring-opacity-30',
            onClick && 'cursor-pointer',
            !disableBgColor &&
              `ring-day-period-${timeDayPeriod}-dark bg-day-period-${timeDayPeriod}-light bg-opacity-80`,
            className,
          )}
          onClick={onClick}
        >
          <div
            className={
              'leading-3 text-gray-600 text-app-xxs md:text-xs block text-left font-normal truncate'
            }
          >
            {isImportantNotesVisible && (
              <div className={'flex items-center font-bold'}>
                <AppHeroIcons.error className="ml-0.5 w-3 h-3 text-red-400" />
                <div className={'ml-0.5 max-w-full truncate sm:overflow-clip'}>
                  {session.importantNotes}
                </div>
              </div>
            )}
            {isCommentVisible && (
              <div className={'flex items-center'}>
                <AppHeroIcons.warn className="ml-0.5 w-3 h-3" />
                <div className={'ml-0.5 max-w-full truncate sm:overflow-clip'}>
                  {session.comment}
                </div>
              </div>
            )}{' '}
            {isTideVisible && (
              <div className={'flex items-center'}>
                <BiWater className="ml-0.5 w-3 h-3" />
                <div className={'ml-0.5 max-w-full truncate sm:overflow-clip'}>
                  {session.tide}
                </div>
              </div>
            )}
            {isWeatherVisible && (
              <div className={'flex items-center'}>
                <AppHeroIcons.meteo className="ml-0.5 w-3 h-3" />
                <div className={'ml-0.5 max-w-full truncate sm:overflow-clip'}>
                  {session.weather}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
