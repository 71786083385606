import { CommercePaymentPlatformForClubResumeGql_Company } from '@mabadive/app-common-model';
import {
  paymentPlatformFormatter,
  PaymentPlatformFormatterFormat,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useAppPaymentPlatformOptions({
  format,
  filter,
}: {
  format: PaymentPlatformFormatterFormat;
  filter: 'all' | 'active';
}): ValueLabel<string>[] {
  const clubResume = useClubResume();
  const paymentPlatforms: CommercePaymentPlatformForClubResumeGql_Company[] =
    clubResume.paymentPlatforms;

  const options: ValueLabel<string>[] = useMemo(
    () =>
      paymentPlatforms
        .filter((x) => filter === 'all' || x.enabled)
        .map((x) => ({
          value: x?._id,
          label: paymentPlatformFormatter.formatPaymentPlatform(x, { format }),
        })),
    [filter, format, paymentPlatforms],
  );
  return options;
}
