/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppPageBlock } from 'src/business/_core/modules/layout';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageMessagingTabCreateMessageArea } from './DiverBookingPageMessagingTabCreateMessageArea';
import { DiverBookingPageMessagingTabOutbox } from './DiverBookingPageMessagingTabOutbox';
export const DiverBookingPageMessagingTab = ({
  globalState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const {
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
    loadedContent,
    includeArchivedBookings,
    linkedData,
  } = globalState;

  return (
    <AppPageBlock className="app-p-content grid gap-4">
      <DiverBookingPageMessagingTabCreateMessageArea
        globalState={globalState}
        actions={actions}
      />
      <DiverBookingPageMessagingTabOutbox
        globalState={globalState}
        dialogs={dialogs}
        actions={actions}
        messageSchedules={globalState.loadedContent?.messageSchedules}
      />
    </AppPageBlock>
  );
};
