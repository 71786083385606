import { BookingDeposit } from '@mabadive/app-common-model';
import { PaymentTabModelCounts } from '../../../models';
import { DiverBookingPageClubDiverLinkedData } from '../../01.loaded-content';
import { PaymentTabModelBuilderFilterCriteria } from './paymentTabModelBuilder.service';

export const billingTabModelBuilderBookingDepositsFilter = {
  filterBookingDeposits,
};

function filterBookingDeposits({
  bookingDeposits,
  criteria,
  counts,
  linkedData,
}: {
  bookingDeposits: BookingDeposit[];
  criteria: PaymentTabModelBuilderFilterCriteria & {
    filteredDiverId: string;
    currentDiveCenterId: string;
  };
  counts: PaymentTabModelCounts;
  linkedData: DiverBookingPageClubDiverLinkedData;
}) {
  return bookingDeposits.filter((bookingDeposit) => {
    if (!linkedData?.diverIdsBookingLoaded.includes(bookingDeposit.diverId)) {
      return false;
    }
    if (
      criteria.filteredDiverId &&
      criteria.filteredDiverId !== bookingDeposit.diverId
    ) {
      counts.otherParticipants++;
      if (criteria.filteredDiverId) {
        counts.hiddenParticipants++;
        return false;
      }
    }
    if (
      criteria.currentDiveCenterId &&
      criteria.currentDiveCenterId !== bookingDeposit.diveCenterId
    ) {
      counts.otherDiveCentersBookingDeposit++;
      if (criteria.ignoreOtherDiveCenters) {
        counts.hiddenBookingDeposits++;
        return false;
      }
    }

    return true;
  });
}
