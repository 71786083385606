/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  CreateOfferPayload,
  clubSettingsProductOfferBuilder,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export const ClubSettingsServicesViewPanelOffersPricesActionsBar_FirstDive = ({
  localState,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
}) => {
  const { data, state, actions } = localState;
  const publicSettings = data.publicSettings;
  const clubReference = data.clubReference;
  const clubSettings = data.clubSettings;

  return (
    <>
      <AppButton
        className="whitespace-nowrap"
        color="primary-outline-light"
        size="normal"
        icon={AppHeroIcons.actionAdd}
        onClick={() => {
          const newOffer: CreateOfferPayload =
            clubSettingsProductOfferBuilder.buildNewOffer({
              clubReference,
              diveMode: 'first-dive',
              i: 0,
            });
          actions.openOfferEditDialog({
            mode: 'create',
            offers: [newOffer],
          });
        }}
      >
        Baptême
      </AppButton>
    </>
  );
};
