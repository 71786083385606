/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EXPORT_DAILY_CONFIG_CONTENT_SESSIONS_EQUIPMENT_COLUMNS,
  ExportDailyConfigContentSessionsEquipmentColumnId,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
//import { RegionalTaxLabel } from '../company/RegionalTax/RegionalTaxLabel';
import { equipmentFormatter } from '@mabadive/app-common-services';
export function useExportDailyConfigContentSessionsEquipmentColumnsOptions(): ValueLabel<
  ExportDailyConfigContentSessionsEquipmentColumnId,
  string
>[] {
  return useMemo(() => {
    const taxesOptions: ValueLabel<
      ExportDailyConfigContentSessionsEquipmentColumnId,
      string
    >[] = EXPORT_DAILY_CONFIG_CONTENT_SESSIONS_EQUIPMENT_COLUMNS.map(
      (type) => ({
        value: type,
        label:
          equipmentFormatter.formatExportDailyConfigContentSessionsEquipmentColumn(
            type,
          ),
      }),
    );

    return taxesOptions;
  }, []);
}
