/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { AggregatedBookingPurchasePaymentWithDetails } from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPagePaymentTabCard } from './DiverBookingPagePaymentTabCard';

export const DiverBookingPagePaymentTabPaymentsResumeTable = ({
  globalState,
  dialogs,
  actions,
  purchasePaymentsWithDetails,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  purchasePaymentsWithDetails: AggregatedBookingPurchasePaymentWithDetails[];
}) => {
  return (
    <div className="app-card app-px-content grid divide-y divide-gray-200">
      {purchasePaymentsWithDetails.map((purchasePaymentWithDetails, i) => (
        <div key={`${i}`} className="py-1">
          <DiverBookingPagePaymentTabCard
            className={clsx(
              'py-1',
              purchasePaymentsWithDetails.length > 1 &&
                'border-l-4 pl-2 border-gray-200 ',
            )}
            purchasePaymentWithDetails={purchasePaymentWithDetails}
            globalState={globalState}
            dialogs={dialogs}
            actions={actions}
            index={i}
            total={purchasePaymentsWithDetails.length}
          />
        </div>
      ))}
    </div>
  );
};
