import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES,
  ClubSettingsUIPlanning,
  ClubSettingsUIPlanningShowPassMode,
  DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { ColoredTagAvatarCardParticipantPayment } from 'src/business/_core/modules/layout';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { ValueLabel } from '../../_common/form/components/ValueLabel.model';

const CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES_OPTIONS =
  CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES.map((value) => {
    const option: ValueLabel<ClubSettingsUIPlanningShowPassMode> = {
      label: (value === 'consumed'
        ? 'consommé (8/10)'
        : 'restant (2)'
      ).toUpperCase(),
      value,
    };
    return option;
  });

export function ClubPlanningParticipantsConfigDialogFormPayment<
  T extends ClubSettingsUIPlanning,
>({
  settingsUiPlanning,
  setSettingsUiPlanning,
}: {
  settingsUiPlanning: T;
  setSettingsUiPlanning: (settingsUiPlanning: T) => void;
}) {
  const onTooglePayment = useCallback(() => {
    if (settingsUiPlanning.showPaymentFlag) {
      // disable all
      setSettingsUiPlanning({
        ...settingsUiPlanning,
        showPaymentFlag: false,
        showPaymentFlagNotPurchased: false,
        showPaymentFlagNotPurchasedPass: false,
        showPaymentFlagNotPurchasedPassMode: 'consumed',
        showPaymentFlagPayed: false,
        showPaymentFlagNotPayed: false,
      });
    } else {
      // enable default
      setSettingsUiPlanning({
        ...settingsUiPlanning,
        showPaymentFlag: true,
        // à l'activation, on définit les paramètres par défaut d'un nouveau club
        showPaymentFlagPastOnly:
          DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT.showPaymentFlagPastOnly,
        showPaymentFlagNotPurchased:
          DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT.showPaymentFlagNotPurchased,
        showPaymentFlagNotPurchasedPass:
          DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT.showPaymentFlagNotPurchasedPass,
        showPaymentFlagNotPurchasedPassMode:
          DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT.showPaymentFlagNotPurchasedPassMode,
        showPaymentFlagPayed:
          DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT.showPaymentFlagPayed,
        showPaymentFlagNotPayed:
          DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT.showPaymentFlagNotPayed,
      });
    }
  }, [setSettingsUiPlanning, settingsUiPlanning]);

  const enabledCount = useMemo(() => {
    let count = 0;
    if (!settingsUiPlanning.showPaymentFlagPastOnly) {
      count++; // condition inversée car option présentée à l'envers ("afficher le futur" !== "masquer le futur")
    }
    if (settingsUiPlanning.showPaymentFlagNotPurchased) {
      count++;
    }
    if (settingsUiPlanning.showPaymentFlagNotPurchasedPass) {
      count++;
    }
    if (settingsUiPlanning.showPaymentFlagPayed) {
      count++;
    }
    if (settingsUiPlanning.showPaymentFlagNotPayed) {
      count++;
    }

    return count;
  }, [
    settingsUiPlanning.showPaymentFlagNotPayed,
    settingsUiPlanning.showPaymentFlagNotPurchased,
    settingsUiPlanning.showPaymentFlagNotPurchasedPass,
    settingsUiPlanning.showPaymentFlagPastOnly,
    settingsUiPlanning.showPaymentFlagPayed,
  ]);

  return (
    <Disclosure defaultOpen={settingsUiPlanning.showPaymentFlag}>
      {({ open }) => (
        <div className={clsx(open && 'bg-gray-100')}>
          <Disclosure.Button
            className={clsx(
              'flex w-full justify-between rounded-lg text-left text-sm font-medium',
              open &&
                'hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75',
            )}
          >
            <div onClick={(e) => e.stopPropagation()}>
              <AppInputBooleanSwitch
                value={settingsUiPlanning.showPaymentFlag}
                label={
                  <div className="flex gap-1 md:gap-1.5 justify-center items-center">
                    <ColoredTagAvatarCardParticipantPayment
                      size={'normal'}
                      purchaseStatus="done"
                      purchasePaymentStatus="done"
                      isPast={false}
                    />
                    Statut du paiement{' '}
                    {settingsUiPlanning.showPaymentFlag && (
                      <span className="text-gray-400">({enabledCount}/5)</span>
                    )}
                  </div>
                }
                onChange={onTooglePayment}
              />
            </div>
            {settingsUiPlanning.showPaymentFlag && (
              <div className="flex gap-2 text-gray-600 ">
                <span>Configuration avancée</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-gray-500`}
                />
              </div>
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            {settingsUiPlanning.showPaymentFlag && (
              <div className="mb-3 flex flex-col gap-2">
                <AppInputBooleanSwitch
                  value={settingsUiPlanning.showPaymentFlagPayed}
                  label={
                    <div className="flex gap-1 md:gap-1.5 justify-center items-center">
                      <ColoredTagAvatarCardParticipantPayment
                        className={
                          settingsUiPlanning.showPaymentFlagPayed
                            ? ''
                            : 'opacity-50'
                        }
                        size={'normal'}
                        purchaseStatus="done"
                        purchasePaymentStatus="done"
                        isPast={false}
                      />
                      PAYÉ
                    </div>
                  }
                  onChange={() => {
                    setSettingsUiPlanning({
                      ...settingsUiPlanning,
                      showPaymentFlagPayed:
                        !settingsUiPlanning.showPaymentFlagPayed,
                    });
                  }}
                />
                <AppInputBooleanSwitch
                  value={settingsUiPlanning.showPaymentFlagNotPayed}
                  label={
                    <div className="flex gap-1 md:gap-1.5 justify-center items-center">
                      <ColoredTagAvatarCardParticipantPayment
                        className={
                          settingsUiPlanning.showPaymentFlagNotPayed
                            ? ''
                            : 'opacity-50'
                        }
                        size={'normal'}
                        purchaseStatus="done"
                        purchasePaymentStatus="todo"
                        isPast={false}
                      />
                      NON-PAYÉ
                    </div>
                  }
                  onChange={() => {
                    setSettingsUiPlanning({
                      ...settingsUiPlanning,
                      showPaymentFlagNotPayed:
                        !settingsUiPlanning.showPaymentFlagNotPayed,
                    });
                  }}
                />
                <AppInputBooleanSwitch
                  value={settingsUiPlanning.showPaymentFlagNotPurchased}
                  label={
                    <div className="flex gap-1 md:gap-1.5 justify-center items-center">
                      <ColoredTagAvatarCardParticipantPayment
                        className={
                          settingsUiPlanning.showPaymentFlagNotPurchased
                            ? ''
                            : 'opacity-50'
                        }
                        size={'normal'}
                        purchaseStatus="todo"
                        isPast={false}
                      />
                      NON-FACTURÉ
                    </div>
                  }
                  onChange={() => {
                    setSettingsUiPlanning({
                      ...settingsUiPlanning,
                      showPaymentFlagNotPurchased:
                        !settingsUiPlanning.showPaymentFlagNotPurchased,
                    });
                  }}
                />
                <AppInputBooleanSwitch
                  value={settingsUiPlanning.showPaymentFlagNotPurchasedPass}
                  label={
                    <div className="flex items-center flex-wrap gap-1">
                      <ColoredTagAvatarCardParticipantPayment
                        className={
                          settingsUiPlanning.showPaymentFlagNotPurchasedPass
                            ? ''
                            : 'opacity-50'
                        }
                        size={'normal'}
                        purchaseStatus="todo"
                        passStatus="open"
                        avatarLabel={
                          settingsUiPlanning.showPaymentFlagNotPurchasedPassMode ===
                          'remaining'
                            ? '# 3'
                            : '# 7/10'
                        }
                        isPast={false}
                      />
                      FORFAITS ACTIFS (si NON-FACTURÉ)
                      {settingsUiPlanning.showPaymentFlagNotPurchasedPass && (
                        <div onClick={(e) => e.stopPropagation()}>
                          <AppSingleSelect2HeadlessUI
                            className="w-40"
                            disableClearButton={true}
                            options={
                              CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES_OPTIONS
                            }
                            theme="tailwind"
                            value={
                              settingsUiPlanning.showPaymentFlagNotPurchasedPassMode ??
                              'consumed'
                            }
                            onChange={(showPaymentFlagNotPurchasedPassMode) => {
                              setSettingsUiPlanning({
                                ...settingsUiPlanning,
                                showPaymentFlagNotPurchasedPassMode,
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  }
                  onChange={() => {
                    setSettingsUiPlanning({
                      ...settingsUiPlanning,
                      showPaymentFlagNotPurchasedPass:
                        !settingsUiPlanning.showPaymentFlagNotPurchasedPass,
                    });
                  }}
                />
                <AppInputBooleanSwitch
                  value={!settingsUiPlanning.showPaymentFlagPastOnly}
                  label={
                    <div className="flex gap-1 md:gap-1.5 justify-center items-center">
                      AFFICHER AUSSI sur les prestations futures
                    </div>
                  }
                  onChange={() => {
                    setSettingsUiPlanning({
                      ...settingsUiPlanning,
                      showPaymentFlagPastOnly:
                        !settingsUiPlanning.showPaymentFlagPastOnly,
                    });
                  }}
                />
              </div>
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
