/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { AppInputBoolean, AppInputBooleanSwitch } from 'src/lib/form';
import { CreateMessageToCustomersFormModel } from '../model';
import { CreateMessageToCustomersAggregatedRecipient } from '../model/CreateMessageToCustomersAggregatedRecipient.type';
import { CreateMessageToCustomersFormStep2_RecipientsTableRow } from './CreateMessageToCustomersFormStep2_RecipientsTableRow';

export const CreateMessageToCustomersFormStep2_RecipientsTable = ({
  refDate,
  recipients,
  includeCancelledParticipants,
  setIncludeCancelledParticipants,
  cancelledParticipantsCount,
  form,
  className = '',
}: {
  refDate: Date;
  recipients: CreateMessageToCustomersAggregatedRecipient[];
  includeCancelledParticipants: boolean;
  setIncludeCancelledParticipants: (value: boolean) => void;
  cancelledParticipantsCount: number;
  form: UseFormReturn<CreateMessageToCustomersFormModel>;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const { control, setValue } = form;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'recipients',
    },
  );

  const [checkAll, setCheckAll] = useState(!fields.find((x) => !x.isSelected));

  const toggleAll = useCallback(() => {
    fields.forEach((field, diverIndex) => {
      let newCheckValue = !checkAll;
      if (
        !includeCancelledParticipants &&
        field?.bookingStatus === 'cancelled'
      ) {
        newCheckValue = false;
      }
      setValue(`recipients.${diverIndex}.isSelected`, newCheckValue);
    });
    setCheckAll(!checkAll);
  }, [checkAll, fields, includeCancelledParticipants, setValue]);

  const uncheckCancelledParticipants = useCallback(() => {
    fields.forEach((field, diverIndex) => {
      if (field?.bookingStatus === 'cancelled') {
        setValue(`recipients.${diverIndex}.isSelected`, false);
      }
    });
    setCheckAll(!checkAll);
  }, [checkAll, fields, setValue]);

  const [messageTarget] = useWatch({
    control,
    name: ['messageTarget'],
  });

  return (
    <div className={`w-full ${className}`}>
      <table
        className={`min-w-full divide-y divide-gray-200 ${className ?? ''}`}
      >
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="pr-1 md:pr-3 text-left text-sm">
              <AppInputBoolean
                color="red"
                className="-mt-1 scale-125 block mx-auto"
                value={checkAll}
                onChange={() => toggleAll()}
                type="checkbox"
              />
            </th>
            <th
              scope="col"
              className="px-1 md:px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Nom
            </th>
            <th
              scope="col"
              className={clsx(
                'px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
                messageTarget !== 'email' && 'hidden lg:table-cell',
              )}
            >
              {'E-mail'}
            </th>

            <th
              scope="col"
              className={clsx(
                'px-1 md:px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
                messageTarget === 'email' && 'hidden lg:table-cell',
              )}
            >
              {'Téléphone'}
            </th>
          </tr>
        </thead>
        <tbody>
          {recipients
            .filter(
              (x) =>
                includeCancelledParticipants ||
                x.formRecipient.bookingStatus !== 'cancelled',
            )
            .map((recipient) => (
              <CreateMessageToCustomersFormStep2_RecipientsTableRow
                key={recipient?.formRecipient.diver._id}
                form={form}
                refDate={refDate}
                recipient={recipient}
                formDiverIndex={recipient.formRecipient.formDiverIndex}
              />
            ))}
        </tbody>
      </table>
      {cancelledParticipantsCount > 0 && (
        <AppInputBooleanSwitch
          label={`Inclure les participants annulés (${cancelledParticipantsCount})`}
          theme="danger"
          value={includeCancelledParticipants}
          onChange={(includeCancelledParticipants) => {
            setIncludeCancelledParticipants(includeCancelledParticipants);
            if (!includeCancelledParticipants) {
              uncheckCancelledParticipants();
            }
          }}
        />
      )}
    </div>
  );
};
