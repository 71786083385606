import {
  ClubPublicSettings,
  ClubPurchasePackage,
  ClubPurchasePackageMeta,
  ClubSettings,
} from '@mabadive/app-common-model';
import {
  clubPurchasePackageMetaReader,
  dateService,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  useClubResume,
  useClubSettings,
  useDiveModeColor,
} from 'src/business/club/data/hooks';
export function DiverPurchasePaymentEditorPurchasePackageLabel({
  purchasePackage,
  opacity,
  className,
}: {
  purchasePackage: ClubPurchasePackage;
  opacity?: 60;
  className?: string;
}) {
  const redirectTo = useRedirect();

  const purchasePackageMeta: ClubPurchasePackageMeta =
    clubPurchasePackageMetaReader.readMeta(purchasePackage);

  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const groupAutonomousAndSupervised =
    clubResume?.clubSettings?.general?.billing?.explorations?.groupsCriteria
      ?.groupAutonomousAndSupervised;

  const diveModeColor = useDiveModeColor(purchasePackageMeta.diveMode);
  return (
    <div className={className}>
      <div className="w-full flex flex-col items-start">
        <div className="w-full flex flex-row items-center">
          <div
            className={`text-sm font-bold ${
              opacity === 60 ? 'text-opacity-60' : ''
            }`}
            style={{ color: diveModeColor }}
          >
            {formatPurchase({
              groupAutonomousAndSupervised,
              purchasePackage,
              purchasePackageMeta,
              publicSettings,
              clubSettings,
            })}
          </div>
        </div>
        {purchasePackage?.comment && (
          <div className="py-1 text-gray-500 text-xs">
            {purchasePackage.comment}
          </div>
        )}
      </div>
      <div
        className={`pt-0.5 whitespace-nowrap font-bold text-gray-600 text-xs ${
          opacity === 60 ? 'text-opacity-60' : ''
        }`}
      >
        {purchasePackage?.purchaseDate &&
          dateService.formatUTC(purchasePackage?.purchaseDate, 'DD/MM/YYYY')}
      </div>
    </div>
  );
}

function formatPurchase({
  purchasePackage,
  purchasePackageMeta,
  groupAutonomousAndSupervised,
  publicSettings,
  clubSettings,
}: {
  purchasePackage: ClubPurchasePackage;
  purchasePackageMeta: ClubPurchasePackageMeta;
  groupAutonomousAndSupervised: boolean;
  publicSettings: ClubPublicSettings;
  clubSettings: ClubSettings;
}): string {
  if (purchasePackage?.productPackageOffer?.productPackage) {
    // real purchase
    return productPackageFormatter.formatNameString(
      purchasePackage?.productPackageOffer?.productPackage,
      {
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );
  } else {
    // virtual purchase
    const isSingleDive = purchasePackageMeta.credits.creditsTotalCount === 1;

    const trainingAttributes = purchasePackageMeta.trainingAttributes;
    const diveAttributes = purchasePackageMeta.diveAttributes;

    return productPackageFormatter.formatNameStringVirtualOffer({
      diveMode: purchasePackageMeta.diveMode,
      isSingleDive,
      diveTrainingReference: trainingAttributes?.diveTrainingReference,
      successiveDivesCount: diveAttributes?.successiveDivesCount,
      minDistance: diveAttributes?.minDistance,
      maxDistance: diveAttributes?.maxDistance,
      groupAutonomousAndSupervised,
      publicSettings,
      firstDiveReference: diveAttributes?.firstDiveTrainingReference,
      diveModesConf: clubSettings?.ui?.diveMode,
    });
  }
}
