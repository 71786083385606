import {
  APP_COMPANY_MESSAGE_STYLES,
  AppCompanyMessageStyle,
} from '@mabadive/app-common-model';
import { appCompanyMessageStyleFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form';

export function useAppCompanyMessageStylesOptions() {
  return APP_COMPANY_MESSAGE_STYLES.map((style: AppCompanyMessageStyle) => {
    const option: ValueLabel<AppCompanyMessageStyle, React.ReactNode> = {
      value: style,
      label: (
        <div
          className={clsx(
            'inline-block font-bold text-white py-px px-1',
            style === 'success'
              ? 'bg-status-success '
              : style === 'warning'
              ? 'bg-status-warn'
              : 'bg-status-error',
          )}
        >
          {appCompanyMessageStyleFormatter.formatStyle(style).toUpperCase()}
        </div>
      ),
    };
    return option;
  });
}
