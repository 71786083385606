import { DiveModeGroup } from '@mabadive/app-common-model';
import {
  DiveModeGroupStringFormat,
  diveModeGroupFormatter,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useDiveModeGroups } from './useDiveModeGroups.hook';

export function useDiveModeGroupsOptions({
  currentDiveModeGroup,
  format,
}: {
  currentDiveModeGroup?: DiveModeGroup;
  format: DiveModeGroupStringFormat;
}): ValueLabel<DiveModeGroup, string>[] {
  const diveModeGroups = useDiveModeGroups({
    currentDiveModeGroup,
  });
  return useMemo(
    () =>
      diveModeGroups.map((diveModeGroup) => ({
        value: diveModeGroup,
        label: diveModeGroupFormatter.formatDiveModeGroup(diveModeGroup, {
          format,
        }),
      })),
    [diveModeGroups, format],
  );
}
