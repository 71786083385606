import { ClubDiver, ClubParticipant } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export const ParticipantCommentCard = ({
  participant,
  diver,
  className = '',
}: {
  participant: Pick<ClubParticipant, 'comment'>;
  diver: Pick<ClubDiver, 'comment' | 'divingComment'>;
  className?: string;
}) => {
  const comments = useMemo(
    () =>
      [participant?.comment, diver?.divingComment, diver?.comment]
        .filter((x) => x?.trim().length)
        .join(' - '),
    [diver?.comment, diver?.divingComment, participant?.comment],
  );

  return comments.length === 0 ? null : (
    <div className={`text-xs text-gray-500 text-left gap-1 ${className}`}>
      <AppHeroIcons.comment className="inline mr-1 w-3 h-3 sm:w-4 sm:h-4 text-gray-500" />
      {comments}
    </div>
  );
};
