import {
  AppBookletPageGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import { useClubResume } from '../../../../../../../business/club/data/hooks';
import {
  EcommerceProductArticleEditorPayload,
  ecommerceProductArticleEditorResultBuilder,
} from './ecommerceProductArticleEditorResultBuilder.service';
import {
  EcommerceProductArticleEditorEditMode,
  EcommerceProductArticleEditorFormModel,
  EcommerceProductArticleEditorFormModelDefaultValue,
  EcommerceProductArticleEditorFormModelEditValue,
} from './model';
import {
  ecommerceFormDefaultImageBuilder,
  ecommerceProductArticleEditorformBuilder,
} from './services';
import { useDiveModeGroupOffers } from './useDiveModeGroupOffers.hook';

export type EcommerceProductArticleEditorInputProps = {
  mode: EcommerceProductArticleEditorEditMode;
  productBookletPage: AppBookletPageGql_Company;
  editValue?: EcommerceProductArticleEditorFormModelEditValue;
  defaultValue?: EcommerceProductArticleEditorFormModelDefaultValue;
  product: EcommerceProductGql_Company;
};
export type EcommerceProductArticleEditorCallbackProps = {
  onCancel: () => any;
  onConfirm: (result: EcommerceProductArticleEditorPayload) => any;
};

export type EcommerceProductArticleEditorProps =
  EcommerceProductArticleEditorInputProps & {
    callback: EcommerceProductArticleEditorCallbackProps;
  };
export function useEcommerceProductArticleEditorPanelLocalState(
  inputProps: EcommerceProductArticleEditorProps,
) {
  const {
    productBookletPage,
    product,
    defaultValue,
    editValue,
    mode,
    callback,
  } = inputProps;

  const clubResume = useClubResume();
  const offersAll = clubResume.productPackageOffers;

  const { diveModeGroup, offers } = useDiveModeGroupOffers({
    product,
    offersAll,
  });

  const initialFormValueBase = useMemo<
    Partial<EcommerceProductArticleEditorFormModel>
  >(() => {
    if (mode === 'edit' || mode === 'duplicate') {
      // on supprime tous les ids (en cas de clonage)
      const model: Partial<EcommerceProductArticleEditorFormModel> = {
        productArticle: {
          _id: null,
          ...editValue?.productArticle,
        },
        appBooklet: {
          _id: null,
          ...editValue?.appBooklet,
        },
        appBookletPage: {
          _id: null,
          ...editValue?.appBookletPage,
        },
      };
      return model;
    }
    // create
    return ecommerceProductArticleEditorformBuilder.buildInitialFormValue({
      defaultValue,
    });
  }, [defaultValue, editValue, mode]);

  const initialFormValue = useMemo(
    () =>
      ecommerceFormDefaultImageBuilder.setDefaultImagesFromParent({
        initialFormValueBase,
        parentBookletPage: productBookletPage,
      }),
    [initialFormValueBase, productBookletPage],
  );

  const form = useForm<EcommerceProductArticleEditorFormModel>({
    defaultValues: initialFormValue,
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    formState,
    setValue,
  } = form;
  const [persistInProgress, setPersistInProgress] = useState(false);

  const promptDeleteConfirm = useCallback(() => {
    confirmDialog
      .confirm({
        title: 'Supprimer le tarif',
        message: 'Êtes-vous sûr de vouloir supprimer ce tarif ?',
        type: 'remove',
      })
      .subscribe(async (confirmed) => {
        if (confirmed) {
          setPersistInProgress(true);
          try {
            const payload: EcommerceProductArticleEditorPayload = {
              actionContext: 'delete productArticle',
              hasChanges: true,
              appBooklet: {
                deletedIds: editValue?.appBooklet?._id
                  ? [editValue?.appBooklet?._id]
                  : [],
              },
              appBookletPage: {
                deletedIds: editValue?.appBookletPage?._id
                  ? [editValue?.appBookletPage?._id]
                  : [],
              },
              ecommerceProductArticle: {
                deletedIds: editValue?.productArticle?._id
                  ? [editValue?.productArticle?._id]
                  : [],
              },
            };
            await callback.onConfirm(payload);
          } catch (err) {
            appLogger.error(
              '[EcommerceProductArticleEditor] Error saving changes',
              err,
            );
            uiStore.snackbarMessage.set({
              type: 'error',
              content:
                'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
            });
          } finally {
            setPersistInProgress(false);
          }
        }
      });
  }, [
    callback,
    editValue?.appBooklet?._id,
    editValue?.appBookletPage?._id,
    editValue?.productArticle?._id,
  ]);

  async function submit() {
    return handleSubmit(
      async (formValue: EcommerceProductArticleEditorFormModel) => {
        try {
          if (persistInProgress) {
            return;
          }
          setPersistInProgress(true);
          if (mode === 'create' || mode === 'duplicate') {
            const payload: EcommerceProductArticleEditorPayload =
              await ecommerceProductArticleEditorResultBuilder.buildCreatePayload(
                {
                  formValue,
                },
              );
            await callback.onConfirm(payload);
          } else {
            const payload: EcommerceProductArticleEditorPayload =
              await ecommerceProductArticleEditorResultBuilder.buildUpdatePayload(
                {
                  formValue,
                  editValue,
                },
              );
            if (payload.hasChanges) {
              await callback.onConfirm(payload);
            } else {
              callback.onCancel();
            }
          }
        } catch (err) {
          appLogger.error(
            '[EcommerceProductArticleEditor] Error saving changes',
            err,
          );
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
        } finally {
          setPersistInProgress(false);
        }
      },
    )();
  }

  return {
    data: {
      diveModeGroup,
      offers,
    },
    state: {
      mode,
      persistInProgress,
      form,
    },
    actions: {
      promptDeleteConfirm,
      submit,
      cancel: callback.onCancel,
    },
  };
}

export type EcommerceProductArticleEditorLocalState = ReturnType<
  typeof useEcommerceProductArticleEditorPanelLocalState
>;
