/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { AppDocEditDialogPanel } from './AppDocEditDialogPanel';
import { AppDocEditDialogState } from './_model';

export const AppDocEditDialog: FC<AppDocEditDialogState> = (inputState) => {
  const { isOpen } = inputState;

  return isOpen ? (
    // parentState?.loadableContent?.contentState === 'full' ? (
    <AppDocEditDialogPanel inputState={inputState} />
  ) : // ) : (
  //   <LoadingProgressBar>Chargement en cours...</LoadingProgressBar>
  // )
  null;
};
