import {
  ClubDiver,
  ClubParticipant,
  DiveSession,
} from '@mabadive/app-common-model';
import {
  dateService,
  diveSessionFormatter,
  diveSessionServiceFormatter,
  participantAptitudeBuilder,
} from '@mabadive/app-common-services';
import React, { FC, useCallback, useMemo } from 'react';
import {
  AppExpansionPanel,
  AppExpansionPanelProps,
  AppExpansionPanelState,
} from 'src/business/_core/modules/layout/components/AppExpansionPanel';
import { useAppSecurityUserHasRoles } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ParticipantFormExpansionPanelId } from '../../../../models';
import {
  ClubParticipantCardAttribute,
  ClubParticipantCardAttributeName,
} from '../../_common';

const panelId = 'panel3';

export const BookingParticipantEditorDialogEditPanel3: FC<{
  diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
  participant: ClubParticipant;
  diveSession: Pick<DiveSession, 'reference' | 'name' | 'time'>;
  participantCardMoreAttributes: ClubParticipantCardAttributeName[];
  participantCardDiveAttributes: ClubParticipantCardAttributeName[];
  expandedPanel: ParticipantFormExpansionPanelId;
  expandPanelToogle: (panelId: ParticipantFormExpansionPanelId) => void;
  renderDetails: (state?: AppExpansionPanelState) => JSX.Element;
  renderQuickActions: (state?: AppExpansionPanelState) => JSX.Element;
  className?: string;
}> = ({
  diver,
  participant,
  diveSession,
  participantCardDiveAttributes,
  participantCardMoreAttributes,
  expandedPanel,
  expandPanelToogle,
  renderDetails,
  renderQuickActions,
  className = '',
}) => {
  const isEditParticipantRole = useAppSecurityUserHasRoles(
    'club-edit-participant-diver',
    'club-edit-participant-booking',
  );
  const isExpanded = expandedPanel === panelId && isEditParticipantRole;
  const clubResume = useClubResume();

  const expandPanel = useCallback(
    () => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      expandPanelToogle(panelId);
    },
    [expandPanelToogle],
  );
  const appExpansionPanelProps: Pick<
    AppExpansionPanelProps,
    'panelId' | 'isExpanded' | 'expandPanel'
  > = {
    panelId,
    isExpanded,
    expandPanel,
  };

  const clubReference = clubResume?.reference;
  const aptitude = useMemo(
    () =>
      participantAptitudeBuilder.formatAptitude(
        {
          diver,
          participant,
        },
        {
          clubReference,
        },
      ),
    [clubReference, diver, participant],
  );

  const clubSettings = useClubSettings();
  const clubPublicSettings = clubSettings?.publicSettings;
  const diveSessionService: string = useMemo(() => {
    if (participant) {
      diveSessionServiceFormatter.formatServiceFromParticipant(
        { diver, participant },
        {
          format: 'short-ref',
          addSuffix: false,
          clubReference,
          clubPublicSettings,
          diveModesConf: clubSettings?.ui?.diveMode,
        },
      );
    }
    return '';
  }, [
    participant,
    diver,
    clubReference,
    clubPublicSettings,
    clubSettings?.ui?.diveMode,
  ]);

  const renderSummaryExtra = () => (
    <div className="w-full flex flex-wrap gap-4">
      {participantCardDiveAttributes.map((attr) => (
        <ClubParticipantCardAttribute
          key={attr}
          club={clubResume}
          diver={diver}
          participant={participant}
          attributeName={attr}
        />
      ))}
    </div>
  );
  return (
    <AppExpansionPanel
      isExpandable={isEditParticipantRole}
      className={`${className}`}
      {...appExpansionPanelProps}
      renderSummaryTitle={() => (
        <div className="flex justify-between">
          <span
            className={`${
              participant?.bookingState?.value === 'cancelled'
                ? 'text-red-400 line-through'
                : ''
            }`}
          >
            {diveSessionFormatter.formatDiveSession(diveSession, {
              formatUTC: dateService.formatUTC,
              format: 'prefix-date-name',
            })}{' '}
            {(diveSessionService?.length > 0 || aptitude?.length > 0) && (
              <span className="ml-1 py-1 bg-app-blue text-white text-sm">
                {diveSessionService?.length > 0 && (
                  <span className="px-1">{diveSessionService}</span>
                )}
                {aptitude?.length > 0 && (
                  <span className="px-1">{aptitude}</span>
                )}
              </span>
            )}
          </span>
          {isEditParticipantRole && !isExpanded && (
            <span className="hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR MODIFIER
            </span>
          )}
        </div>
      )}
      renderSummaryExtra={renderSummaryExtra}
      renderDetails={renderDetails}
      renderAlwaysFooter={() => (
        <div className="relative w-full flex flex-wrap gap-4">
          {participantCardMoreAttributes.map((attr) => (
            <ClubParticipantCardAttribute
              key={attr}
              club={clubResume}
              diver={diver}
              participant={participant}
              attributeName={attr}
            />
          ))}
          {renderQuickActions && isEditParticipantRole && (
            <div
              className={`absolute -bottom-1 ${
                isExpanded ? '-right-1' : '-right-12'
              } flex gap-10 items-center`}
            >
              {renderQuickActions({ panelId, isExpanded })}
            </div>
          )}
        </div>
      )}
    />
  );
};
