import { ClubDiver, DiveCenterResume } from '@mabadive/app-common-model';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { buildParticipantEditorCreateOpenInputState } from '../../../components/useDiverBookingPageBookingCardLocalState.hook';
import {
  BookingParticipantEditorParticipant,
  DiverBookingPageAggregatedData,
  DiverBookingPageTriggerAction,
  DiverBookingPageUpdateState,
} from '../../../models';
import { quickBookingMemberCreator } from '../../02.update-state';
import { quickBookingCreator } from './quickBookingCreator.service';
import { quickBookingDiveSessionCreator } from './quickBookingDiveSessionCreator.service';
import { quickBookingSessionCreator } from './quickBookingSessionCreator.service';

export function updateStateWithInitialActionCreateBookingParticipant({
  diverId,
  diveSessionReference,
  updateState: updatedStateInput,
  aggregatedData,
  diveCenterResume,
}: {
  diverId: string;
  diveSessionReference: string;
  updateState: DiverBookingPageUpdateState;
  aggregatedData: DiverBookingPageAggregatedData;
  diveCenterResume: DiveCenterResume;
}): {
  updatedState?: DiverBookingPageUpdateState;
  triggerNextAction?: DiverBookingPageTriggerAction;
} {
  if (!diverId) {
    appLogger.warn(
      '[updateStateWithInitialActionCreateBookingParticipant] Action diverId not set',
    );
    return {};
  }
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  let updateStateLocal = {
    ...updatedStateInput,
  };

  // on vérifie que le participant n'existe pas déjà

  // 1) SELECT OR CREATE BOOKING
  const { aggregatedBooking } = quickBookingCreator.selectOrCreateBooking({
    aggregatedData,
    clubReference,
    diveCenterId,
    diverId,
    updateStateLocal,
    publicSettings: diveCenterResume?.clubResume.clubSettings.publicSettings,
  });
  const bookingId = aggregatedBooking?.booking._id;

  // 2) LOAD DIVER
  let diver: ClubDiver = aggregatedData.divers.find((d) => d._id === diverId);
  if (!diver) {
    appLogger.warn(
      '[updateStateWithInitialActionCreateBookingParticipant] Action diver not found',
    );
    return {};
  }

  // 3) SELECT OR CREATE MEMBER
  const { bookingMember } = quickBookingMemberCreator.selectOrCreateMember({
    aggregatedBooking,
    diver,
    bookingId,
    clubReference,
    updateStateLocal,
    diveCenterId,
  });

  if (diveSessionReference) {
    // 4.A) LOAD DIVE SESSION
    const { diveSessionResume } =
      quickBookingDiveSessionCreator.selectOrCreateDiveSession({
        aggregatedData,
        diveCenterResume,
        diveSessionReference,
        updateStateLocal,
      });
    if (!diveSessionResume) {
      // pas de diveSessionResume trouvée
      // 4.B) OPEN SELECT SESSION DIALOG
      return openSessionSelectDialog({ updateStateLocal, bookingId });
    }

    // 5) SELECT OR CREATE BOOKING SESSION

    const { bookingSession } =
      quickBookingSessionCreator.selectOrCreateBookingSession({
        updateStateLocal,
        aggregatedBooking,
        clubReference,
        diveCenterId,
        diveSessionResume,
      });

    const existingProduct = (
      aggregatedBooking?.bookingParticipantsFull ?? []
    ).find(
      (p) =>
        p.bookingMember._id === bookingMember?._id &&
        p.diveSession.reference === diveSessionReference,
    );

    if (!existingProduct) {
      // 6.A) OPEN CREATE PARTICIPANT DIALOG
      const clubDiver = {
        ...diver,
        __typename: undefined,
      } as ClubDiver;

      const participant: BookingParticipantEditorParticipant =
        buildParticipantEditorCreateOpenInputState({
          bookingId,
          diver: clubDiver,
          bookingMember,
          bookingSessionId: bookingSession._id,
          diveSession: diveSessionResume,
          docResumes: [],
        });

      // 6.A) OPEN CREATE PARTICIPANT DIALOG
      return {
        updatedState: updateStateLocal,
        triggerNextAction: {
          id: 'open-create-participant-dialog',
          participant,
        },
      };
    }
  } else {
    // pas de diveSessionReference de spécifiée
    // 6.B) OPEN SELECT SESSION DIALOG
    return openSessionSelectDialog({ updateStateLocal, bookingId });
  }
  return {
    updatedState: updateStateLocal,
  };
}
export function openSessionSelectDialog({
  updateStateLocal,
  bookingId,
}: {
  updateStateLocal: DiverBookingPageUpdateState;
  bookingId: string;
}): {
  updatedState?: DiverBookingPageUpdateState;
  triggerNextAction?: DiverBookingPageTriggerAction;
} {
  return {
    updatedState: updateStateLocal,
    triggerNextAction: {
      id: 'open-session-selector-dialog',
      bookingId,
      defaultFocusDate: undefined,
    },
  };
}
