/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { ClubDialogsState } from '../../../../../../../_dialogs';
import {
  ClubSettingsForm_LegalAddress,
  ClubSettingsForm_LegalInformation,
} from '../../../../../../SE-02_account/ClubSettingsAccountCompanyInfoPage/DiveCenterEditDialog';
import { ClubSettingsSection } from '../../../../../../_core';
import { ClubSettingsOnlineBookingFormModel } from '../../../_model';

export const ClubSettingsOnlineBookingLegalInformationForm = ({
  form,
  dialogsState,
}: {
  form: UseFormReturn<ClubSettingsOnlineBookingFormModel>;
  dialogsState: ClubDialogsState;
}) => {
  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsSection
        title="Mentions légales"
        comment={
          'Renseignez les informations légales à destination des clients de la vente en ligne'
        }
      >
        <div className="grid gap-4">
          <ClubSettingsForm_LegalInformation form={form} />
          <ClubSettingsForm_LegalAddress form={form} />
        </div>
      </ClubSettingsSection>
    </div>
  );
};
