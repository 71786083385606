export const clubSettingsCommunicationPageTabLabelFormatter = {
  format,
};

export type ClubSettingsCommunicationPageTabId =
  | 'main'
  | 'templates'
  | 'email-auto';

function format(tabId: ClubSettingsCommunicationPageTabId): string {
  switch (tabId) {
    case 'templates':
      return 'Messages';
    case 'main':
      return 'Configuration';
    case 'email-auto':
      return 'Email directs';
  }
}
