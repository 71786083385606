import {
  ClubDiver,
  ClubEvent,
  ClubParticipant,
  ClubPlanningLightSessionDef,
  DiveSessionResumeFull,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
} from '@mabadive/app-common-model';
import { commonDiveSessionReferenceParser } from '@mabadive/app-common-services';
import { useCallback, useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { BookingParticipantViewParticipant } from '../club-diver-participant/pages/DiverBookingPage/models';
import { PlanningParticipantsLocalState } from '../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import {
  clubBookingUrlBuilder,
  clubParticipantUrlBuilder,
} from '../url-builders';
import { useClubPlanningLightPageSessionCardMenuActions } from './hooks';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
} from './model';

export function useClubPlanningActions(
  localState: PlanningParticipantsLocalState,
) {
  const redirectTo = useRedirect();

  const clubResume = useClubResume();

  const isEditPlanningEventRole = useAppSecurityUserHasRole(
    'club-edit-planning-event',
  );
  const isEditPlanningSessionRole = useAppSecurityUserHasRole(
    'club-edit-planning-session',
  );

  const securityUser = useAppSecurityUser();

  const az = securityUser.club?.authorizations;

  const {
    planningConfig,
    setPlanningConfig,
    weekPlanning,
    diveSessionResumes,
    dailyConfigs,
    clubEvents,
    loadableContent,
    dialogsOld,
  } = localState;

  const onClickEditSessionStaffMembers = useCallback(
    (diveSession: ClubPlanningLightSessionDef) => {
      dialogsOld.setSessionEditorDialogState({
        isOpen: true,
        tabId: 'list-staff-members',
        mode: 'edit-session',
        originalSessionFull: diveSession,
        diveSessionResumes,
      });
    },
    [dialogsOld, diveSessionResumes],
  );

  const onClickEditSession = useCallback(
    (session: ClubPlanningLightSessionDef) => {
      dialogsOld.setSessionEditorDialogState({
        isOpen: true,
        mode: 'edit-session',
        originalSessionFull: session,
        diveSessionResumes,
        dailyConfigs,
      });
    },
    [dailyConfigs, dialogsOld, diveSessionResumes],
  );
  const onClickEditSessionDiveGroups = useCallback(
    (session: ClubPlanningLightSessionDef) => {
      // open session groups editor
      dialogsOld.setSessionEditorDialogState({
        isOpen: true,
        tabId: 'edit-dive-groups',
        mode: 'edit-session',
        originalSessionFull: session,
        diveSessionResumes,
        dailyConfigs,
      });
    },
    [dailyConfigs, dialogsOld, diveSessionResumes],
  );

  const onClickSession = useCallback(
    (session: ClubPlanningLightSessionDef) => {
      const isStaffPlanning =
        (planningConfig.viewPeriod === 'week' &&
          (planningConfig.viewWeekOptions.displayMode === 'staff-sessions' ||
            planningConfig.viewWeekOptions.displayMode === 'staff-roles')) ||
        (planningConfig.viewPeriod === 'day' &&
          (planningConfig.viewDayOptions.displayMode === 'staff-sessions' ||
            planningConfig.viewDayOptions.displayMode === 'staff-roles'));

      if (
        isEditPlanningSessionRole &&
        (isStaffPlanning ||
          (planningConfig?.viewPeriod === 'session' &&
            planningConfig?.focusSessionReference === session.reference))
      ) {
        dialogsOld.setSessionEditorDialogState({
          isOpen: true,
          mode: 'edit-session',
          originalSessionFull: session,
          diveSessionResumes,
          dailyConfigs,
        });
      } else {
        if (!isStaffPlanning) {
          // zoom to session
          const focusDate = commonDiveSessionReferenceParser.parseDayReference(
            session.dayReference,
          );

          setPlanningConfig(
            {
              ...planningConfig,
              viewPeriod: 'session',
              focusDate: focusDate,
              focusSessionReference: session.reference,
              viewSessionOptions: {
                ...planningConfig.viewSessionOptions,
              },
            },
            {
              origin: '[useClubPlanningActions] onClickSession',
            },
          );
        }
      }
    },
    [
      dialogsOld,
      isEditPlanningSessionRole,
      planningConfig,
      setPlanningConfig,
      diveSessionResumes,
      dailyConfigs,
    ],
  );

  const onClickEvent = useCallback(
    (clubEvent: ClubEvent) => {
      dialogsOld.setClubEventDialogState({
        isOpen: true,
        mode: 'edit',
        originalEvent: clubEvent,
      });
    },
    [dialogsOld],
  );

  const onClickAddParticipant = useCallback(
    (session: ClubPlanningLightSessionDef) => {
      (async () => {
        redirectTo(
          clubBookingUrlBuilder.buildCreateBookingUrl({
            navigationContext: {
              diveSessionReference: session.reference,
              origin: 'planning',
              planningFocusDateRef: planningConfig?.focusDateRef,
            },
          }),
        );
      })();
    },
    [planningConfig?.focusDateRef, redirectTo],
  );

  const createNewSession = useCallback(
    ({
      clubReference,
      day,
    }: {
      clubReference: string;
      day: ClubPlanningDayFull;
    }) => {
      const time = new Date(day.date);
      time.setUTCHours(10);

      dialogsOld.setSessionEditorDialogState({
        isOpen: true,
        mode: 'create-session',
        defaultValue: {
          time,
        },
        diveSessionResumes,
        dailyConfigs,
      });
    },
    [dailyConfigs, dialogsOld, diveSessionResumes],
  );
  const onClickCreateNewEvent = useCallback(
    ({
      clubReference,
      day,
    }: {
      clubReference: string;
      day: ClubPlanningDayFull;
    }) => {
      const beginDate = new Date(day.date);
      beginDate.setUTCHours(0);
      const endDate = new Date(beginDate.getTime());

      dialogsOld.setClubEventDialogState({
        isOpen: true,
        mode: 'create',
        defaultValue: {
          beginDate,
          endDate,
          description: {
            title: '',
            comment: '',
          },
          meta: {
            staffMembers: [],
          },
          priority: 'normal',
          status: 'confirmed',
          schedule: {},
        },
      });
    },
    [dialogsOld],
  );

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    diveSessionEditorMassive: {}, // ici on peut récupérer un callback si besoin
  });
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const onClickStaffDailyCounters = useCallback(
    ({
      clubReference,
      day,
    }: {
      clubReference: string;
      day: ClubPlanningDayFull;
    }) => {
      dialogsState.clubPlanningDailyStaffDialog.openDialog({
        clubReference,
        day,
      });
    },
    [dialogsState.clubPlanningDailyStaffDialog],
  );

  const sessionCardMenuActions = useClubPlanningLightPageSessionCardMenuActions(
    {
      diveSessionResumes,
      dialogs: dialogsOld,
      dialogsState,
      dailyConfigs,
    },
  );

  const onClickParticipant = useCallback(
    ({
      participant,
      session,
    }: {
      participant: DiveSessionResumeParticipant;
      session: ClubPlanningLightSessionDef;
    }) => {
      const participantView: BookingParticipantViewParticipant = {
        // TODO: charger les données complètes en arrière plan, puis mettre à jour
        diver: participant.diver as unknown as ClubDiver,
        clubParticipant: participant as unknown as ClubParticipant,
        diveSession: session,
      };
      dialogsOld.setBookingParticipantViewState({
        isOpen: true,
        participant: participantView,
      });
      return;
    },
    [dialogsOld],
  );

  const anchorLinkParticipant = useCallback(
    (participant?: DiveSessionResumeParticipant) => {
      if (participant) {
        return clubParticipantUrlBuilder.buildTabUrl({
          tabId: 'booking',
          navigationContext: {
            origin: 'planning',
            diverId: participant.diver?._id,
            diveSessionReference: participant.diveSession?.reference,
          },
        });
      }
    },
    [],
  );

  const onClickGroupDetails = useCallback(
    ({
      group,
      diveSessionResume,
      participants,
    }: {
      group?: DiveSessionResumeGroup;
      diveSessionResume: DiveSessionResumeFull;
      participants: DiveSessionResumeParticipant[];
    }) => {
      // open session groups editor
      dialogsOld.setSessionEditorDialogState({
        isOpen: true,
        tabId: 'edit-dive-groups',
        mode: 'edit-session',
        originalSessionFull: diveSessionResume,
        diveSessionResumes,
        dailyConfigs,
      });
    },
    [dailyConfigs, dialogsOld, diveSessionResumes],
  );

  const clickActions: ClubPlanningLightPanelViewClickActions = useMemo(
    () => ({
      onClickStaffDailyCounters:
        az.staff?.view?.staffPlanning && onClickStaffDailyCounters,
      onClickEvent: az.view.planning.event && onClickEvent,
      onClickEditSession: az.edit.planning.securitySheet && onClickEditSession,
      onClickEditSessionDiveGroups:
        az.edit.planning.session && onClickEditSessionDiveGroups,
      onClickSession,
      onClickEditSessionStaffMembers:
        az.edit.settings.diveCenter && onClickEditSessionStaffMembers,
      onClickSessionOpenButton: az.edit.planning.session && onClickSession,
      onClickAddParticipant:
        (az.edit.participant.booking && onClickAddParticipant) ??
        onClickSession,
      onClickParticipant: onClickParticipant,
      anchorLinkParticipant: anchorLinkParticipant,
      onClickGroupDetails:
        az.edit.planning.securitySheet && onClickGroupDetails,
      navigationBarDisplay: { view: true, mode: true, actions: true },
      onClickCreateNewSession: az.edit.planning.session && createNewSession,
      onClickCreateNewEvent: az.edit.planning.event && onClickCreateNewEvent,
    }),
    [
      az.staff?.view?.staffPlanning,
      az.view.planning.event,
      az.edit.planning.securitySheet,
      az.edit.planning.session,
      az.edit.planning.event,
      az.edit.settings.diveCenter,
      az.edit.participant.booking,
      onClickStaffDailyCounters,
      onClickEvent,
      onClickEditSession,
      onClickEditSessionDiveGroups,
      onClickSession,
      onClickEditSessionStaffMembers,
      onClickAddParticipant,
      onClickParticipant,
      anchorLinkParticipant,
      onClickGroupDetails,
      createNewSession,
      onClickCreateNewEvent,
    ],
  );
  return { sessionCardMenuActions, clickActions, dialogsState };
}

export type ClubPlanningAction = ReturnType<typeof useClubPlanningActions>;
