/* eslint-disable @typescript-eslint/no-unused-vars */
import { ProMultiOperationPayload } from '@mabadive/app-common-model';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { ClubPlanningStaffPageCacheUpdateState } from '../_model';

export const clubPlanningStaffWeekStaffPresencePageClientUpdator = {
  persistChanges,
};

function persistChanges(
  updateState: ClubPlanningStaffPageCacheUpdateState,
  {
    logContext,
    bookingIdsToClean = [],
  }: { logContext: string; bookingIdsToClean: string[] },
): Promise<void> {
  const payload: ProMultiOperationPayload =
    clubMassiveUpdatorClient.createEmptyPayload({
      logContext,
      bookingIdsToClean,
    });

  const dailyConfigsChanges = updateState.dailyConfigsChanges ?? [];
  payload.diveCenterDailyConfigs = {
    created: dailyConfigsChanges.map((d) => d.created).filter((x) => !!x),
    updated: dailyConfigsChanges.map((d) => d.updated).filter((x) => !!x),
  };
  const diveSessionsChanges = updateState.diveSessionsChanges ?? [];
  payload.newDiveSessions = diveSessionsChanges
    .map((d) => d.created)
    .filter((x) => !!x);
  payload.updatedDiveSessions = diveSessionsChanges
    .map((d) => d.updated)
    .filter((x) => !!x);

  return clubMassiveUpdatorClient.update(payload);
}
