/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export const ClubSettingsViewAttribute = ({
  label,
  children,
}: {
  label: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  return !children ? null : (
    <div>
      <div className="text-gray-800 font-medium">{label}</div>
      <div className="text-app-primary">{children}</div>
    </div>
  );
};
