import { BaseEntity } from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';

/** @deprecated use commonEntityBuilder */
export const repositoryEntityBuilder = {
  buildEntity,
  buildOneCreate,
  buildOneUpdate,
  buildDefaultAttributes,
};

function buildOneUpdate<T extends BaseEntity>({
  source,
  item,
  now,
}: {
  source: string;
  item: T;
  now?: Date;
}): T {
  if (!now) {
    now = new Date();
  }
  // il faut toujours incrémenter, car le patch ne contient pas date et version
  return {
    ...item,
    _updatedAt: now,
    _version: incrementVersion<T>(item),
  };
}

function buildOneCreate<T extends BaseEntity>({
  source = 'local',
  item,
  now = new Date(),
}: {
  source: string;
  item: Partial<T>;
  now?: Date;
}) {
  return source === 'local' ? buildEntity<T>(item, now) : (item as T);
}

function incrementVersion<T extends BaseEntity>(item: T): number {
  return item && item._version ? item._version + 1 : 1;
}

function buildEntity<T extends BaseEntity>(item: Partial<T>, now = new Date()) {
  const newItem = {
    ...item,
    _id: item._id ?? uuidGenerator.random(),
    _createdAt: item._createdAt ?? now,
    _updatedAt: now,
    _version: (item._version ?? 0) + 1,
  } as T;
  return newItem;
}

function buildDefaultAttributes() {
  const now = new Date();
  return {
    _id: uuidGenerator.random(),
    _createdAt: now,
    _updatedAt: now,
    _version: 1,
  };
}
