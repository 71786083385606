import {
  ClubDiveSiteGroup,
  DiveCenterResume,
} from '@mabadive/app-common-model';
import { combineLatest } from 'rxjs';
import { rxjsFormBuilder } from 'src/lib/form/components/rxjs-form';
import { DiveSiteGroupEditPageContext } from './DiveSiteGroupEditPage';
import {
  DiveSiteGroupFormComponentsModel,
  DiveSiteGroupFormModel,
} from './DiveSiteGroupForm';

export const diveSiteGroupEditPageRepository = {
  loadInitialData,
};

function loadInitialData({
  navigationContext,
  diveSiteGroupId,
  diveCenterResume,
}: {
  navigationContext: DiveSiteGroupEditPageContext;
  diveSiteGroupId: string;
  diveCenterResume: DiveCenterResume;
}) {
  const { actions, components$, form$ } = buildForm();

  const diveSiteGroup: ClubDiveSiteGroup =
    navigationContext.mode === 'create'
      ? ({} as unknown as ClubDiveSiteGroup)
      : diveCenterResume.diveSiteGroups.find((g) => g._id === diveSiteGroupId);

  const initialFormValue: DiveSiteGroupFormModel = diveSiteGroup ?? {};
  actions.updateInitialValue(initialFormValue, 'external');

  return combineLatest([form$, components$], (form, components) => ({
    diveSiteGroup,
    form,
    components,
  }));
}

function buildForm() {
  return rxjsFormBuilder.buildForm<
    DiveSiteGroupFormModel,
    DiveSiteGroupFormComponentsModel
  >([
    {
      name: 'name',
      required: true,
    },
  ]);
}
