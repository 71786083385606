import { nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  AppHeroIcons,
  AppIconsUI,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { clubSettingsAccountUrlBuilder } from '../../../clubSettingsAccountUrlBuilder.service';
import { SettingsUserAccount } from '../model';

export const ClubSettingsUserAccountCard = ({
  userAccount,
  className,
  as = 'div',
}: {
  userAccount: SettingsUserAccount;

  className?: string;
  as?: React.ElementType; // TODO personnaliser le componsant (ici, un "li", mais on pourrait vouloir un "div")
}) => {
  const hasRoleEditUserAccount = useAppSecurityUserHasRole(
    'club-edit-settings-user-accounts',
  );
  const href = useMemo(
    () =>
      hasRoleEditUserAccount
        ? clubSettingsAccountUrlBuilder.userAccounts.editUserAccount({
            userAccountId: userAccount.authUser._id,
          })
        : '#',
    [hasRoleEditUserAccount, userAccount.authUser._id],
  );

  return React.createElement(
    as,
    {
      className: clsx('group/account cursor-pointer', className),
      style: {
        // backgroundColor: colorGenerator.rgba(category.color, 0.1),
      },
    },
    <Link to={href} className="block hover:bg-gray-50">
      <div className="flex items-center px-4 py-2 sm:px-6">
        <div className="flex min-w-0 flex-1 items-center">
          <div className="flex-shrink-0">
            <AppHeroIcons.actionEditAlt className="text-gray-200 group-hover/account:text-gray-400 h-10 w-10" />
          </div>
          <div className="min-w-0 flex-1 px-4 flex justify-between gap-4">
            <div>
              <p className="truncate text-sm font-medium text-app-primary">
                {nameFormatter.formatFullName(userAccount)}
              </p>
              <div className="mt-1 flex gap-4">
                {userAccount.staffRole?.trim().length > 0 && (
                  <p className="flex items-center text-sm text-gray-500">
                    <AppHeroIcons.staffRole
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="truncate">{userAccount.staffRole}</span>
                  </p>
                )}
              </div>
            </div>
            <div className="w-60 hidden md:block">
              <div>
                {userAccount.emailAddress?.trim().length > 0 && (
                  <p className="flex items-center text-sm text-gray-500">
                    <AppHeroIcons.mail
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="truncate">{userAccount.emailAddress}</span>
                  </p>
                )}
                {userAccount.phoneNumber?.trim().length > 0 && (
                  <p className="flex items-center text-sm text-gray-500">
                    <AppHeroIcons.phone
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="truncate">{userAccount.phoneNumber}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <AppIconsUI.navRight
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </Link>,
  );
};
