/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppCompanyMessageSchedule } from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageMessagingTabOutboxMessage } from './DiverBookingPageMessagingTabOutboxMessage';
export const DiverBookingPageMessagingTabOutbox = ({
  globalState,
  dialogs,
  actions,
  messageSchedules,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  messageSchedules: AppCompanyMessageSchedule[];
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const {
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
    loadedContent,
    includeArchivedBookings,
    linkedData,
  } = globalState;

  const sortedMessages = useMemo(() => {
    return dataSorter.sortMultiple(messageSchedules ?? [], {
      getSortAttributes: (messageSchedule) => [
        {
          value: messageSchedule._createdAt,
          asc: false,
        },
      ],
    });
  }, [messageSchedules]);

  return (
    <div className="grid gap-2">
      <h2 className="my-2 text-base sm:text-lg font-medium text-gray-800 leading-6 pt-0 mt-0 flex items-center">
        Messages envoyés ({sortedMessages?.length})
      </h2>
      {sortedMessages?.length === 0 ? (
        <AppMessageLight>
          <div className="grid gap-2">Aucun message envoyé</div>
        </AppMessageLight>
      ) : (
        <div className="grid divide-y divide-gray-200">
          {sortedMessages.map((messageSchedule) => (
            <DiverBookingPageMessagingTabOutboxMessage
              key={messageSchedule._id}
              actions={actions}
              dialogs={dialogs}
              globalState={globalState}
              messageSchedule={messageSchedule}
            />
          ))}
        </div>
      )}
    </div>
  );
};
