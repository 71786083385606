/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DEFAULT_EQUIPMENT_SETTINGS,
  DiveCenterEquipmentConfig,
  DiveCenterEquipmentModel,
  DiveCenterPrivateSettingsEquipment,
  EquipmentDescription,
  EquipmentType,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Path, useController } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppFormControlRHF_Deprecated_BaseProps,
  AppFormControlV2,
  AppSingleAutocomplete2,
} from 'src/lib/form';

const EQUIPPED_PERSO = 'equipped-perso';
const EQUIPPED_CLUB = 'equipped-club';

export type BookingParticipantEquipmentFormGroupProps<T = any> =
  AppFormControlRHF_Deprecated_BaseProps<T, EquipmentDescription> & {
    type: EquipmentType;
    label?: string;
    className?: string;
    classNameIfModel2?: string;
    onChange?: (value: {
      selection?: {
        type: 'club' | 'self';
        model: DiveCenterEquipmentModel;
      };
      newValue: EquipmentDescription;
    }) => any;
  };

export function BookingParticipantEquipmentFormGroup<T>({
  control,
  label,
  name,
  type,
  onChange: onChangeEmit,
  className = '',
  classNameIfModel2 = '',
}: BookingParticipantEquipmentFormGroupProps<T>) {
  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = clubResume.clubSettings;
  const customerSettings = clubResume.clubSettings?.customer;

  const services = clubResume.clubSettings.services;

  const state = useController<T>({
    name: name as unknown as Path<T>,
    control,
  });
  const {
    field: { ref, value: valueToCast, onChange, onBlur },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = state;

  const value = valueToCast as unknown as EquipmentDescription;

  const equipmentsSettings: DiveCenterPrivateSettingsEquipment =
    diveCenterResume?.privateSettings?.equipment;

  const equipmentDefaultAppConfig: DiveCenterEquipmentConfig = useMemo(
    () => DEFAULT_EQUIPMENT_SETTINGS.find((x) => x.type === type),
    [type],
  );

  const equipmentSettings: DiveCenterEquipmentConfig = useMemo(() => {
    return (
      (equipmentsSettings?.equipments ?? []).find((x) => x.type === type) ?? {
        ...equipmentDefaultAppConfig,
        enabled: false,
      }
    );
  }, [equipmentDefaultAppConfig, equipmentsSettings?.equipments, type]);

  const selfCustomModels: {
    type: 'club' | 'self';
    model: DiveCenterEquipmentModel;
  }[] = useMemo(() => {
    let customModels: {
      type: 'club' | 'self';
      model: DiveCenterEquipmentModel;
    }[] = [];

    if (
      equipmentSettings?.self?.enabled &&
      equipmentSettings?.self?.models?.length > 0
    ) {
      customModels = customModels.concat(
        equipmentSettings?.self?.models.map((model) => ({
          model,
          type: 'self',
        })),
      );
    }
    return customModels;
  }, [equipmentSettings?.self?.enabled, equipmentSettings?.self?.models]);

  const clubCustomModels: {
    type: 'club' | 'self';
    model: DiveCenterEquipmentModel;
  }[] = useMemo(() => {
    let customModels: {
      type: 'club' | 'self';
      model: DiveCenterEquipmentModel;
    }[] = [];

    if (
      equipmentSettings?.club?.enabled &&
      equipmentSettings?.club?.models?.length > 0
    ) {
      customModels = customModels.concat(
        equipmentSettings?.club?.models.map((model) => ({
          model,
          type: 'club',
        })),
      );
    }
    return customModels;
  }, [equipmentSettings?.club?.enabled, equipmentSettings?.club?.models]);

  const customModels: {
    type: 'club' | 'self';
    model: DiveCenterEquipmentModel;
  }[] = useMemo(() => {
    let customModels: {
      type: 'club' | 'self';
      model: DiveCenterEquipmentModel;
    }[] = selfCustomModels.concat(clubCustomModels);
    return customModels;
  }, [clubCustomModels, selfCustomModels]);

  const allOptions: ValueLabel<string>[] = useMemo(() => {
    const baseOptions: ValueLabel<string>[] = [].concat(
      [
        {
          value: EQUIPPED_PERSO,
          label: 'PERSO',
        },
      ],
      selfCustomModels.map(({ model }) => ({
        value: model.ref,
        label: model.ref,
      })),
      [
        {
          value: EQUIPPED_CLUB,
          label: 'CLUB',
        },
      ],
      clubCustomModels.map(({ model }) => ({
        value: model.ref,
        label: model.ref,
      })),
    );
    return baseOptions;
  }, [clubCustomModels, selfCustomModels]);

  const modelOptions: ValueLabel<string>[] = useMemo(
    () =>
      customModels
        .filter((x) => x?.model?.ref !== undefined)
        .map(({ model }) => ({
          value: model.ref,
          label: model.ref,
        })),
    [customModels],
  );

  const allOptionsValue = useMemo(() => {
    if (value?.model1?.ref) {
      // NOTE, on a maintenant des modèles "self" ou "club"
      return value?.model1?.ref;
    }
    if (value?.selfEquipped) {
      return EQUIPPED_PERSO;
    } else if (value?.clubEquipped) {
      return EQUIPPED_CLUB;
    }
  }, [value?.clubEquipped, value?.model1, value?.selfEquipped]);

  const isAltEquipmentVisible =
    equipmentSettings.altEnabled && (value?.model1 || value?.model2);

  return !equipmentSettings?.enabled ? null : (
    <div
      className={clsx(
        className,
        equipmentSettings?.altEnabled && classNameIfModel2,
      )}
    >
      <AppFormControlV2
        label={label}
        required={false}
        renderComponent={() => (
          <div className="w-full flex gap-2 justify-between items-start">
            <AppSingleAutocomplete2
              className={equipmentSettings.altEnabled ? 'w-1/2' : 'w-full'}
              fullWidth={true}
              options={allOptions}
              value={allOptionsValue}
              customRenderOption={(selected, option) => {
                if (selected) {
                  if (
                    EQUIPPED_PERSO === selected?.value ||
                    EQUIPPED_CLUB === selected?.value
                  ) {
                    return (
                      <div
                        className={clsx(
                          'font-medium text-sm',
                          EQUIPPED_PERSO === selected?.value
                            ? 'text-green-600'
                            : 'text-app-primary',
                        )}
                      >
                        <span>{selected?.label}</span>
                      </div>
                    );
                  }
                  const selection: {
                    type: 'club' | 'self';
                    model: DiveCenterEquipmentModel;
                  } = customModels.find((x) => x.model.ref === selected?.value);
                  return (
                    <div className={'w-full flex gap-2 justify-between'}>
                      <span
                        className={clsx(
                          'font-medium text-xs',
                          selection?.type === 'self'
                            ? 'text-green-600'
                            : 'text-app-primary',
                        )}
                      >
                        {selection?.model?.ref}
                      </span>{' '}
                      {selection?.type === 'self' && (
                        <span className="m text-app-xxs">PERSO</span>
                      )}
                    </div>
                  );
                }
              }}
              onChange={(selected) => {
                const selection: {
                  type: 'club' | 'self';
                  model: DiveCenterEquipmentModel;
                } = customModels.find((x) => x.model.ref === selected);
                let newValue: EquipmentDescription = value
                  ? {
                      ...value,
                    }
                  : {};
                switch (selected) {
                  case EQUIPPED_PERSO: {
                    newValue.selfEquipped = true;
                    newValue.clubEquipped = false;
                    newValue.model1 = undefined;
                    newValue.model2 = undefined;
                    break;
                  }
                  case EQUIPPED_CLUB: {
                    newValue.selfEquipped = false;
                    newValue.clubEquipped = true;
                    newValue.model1 = undefined;
                    break;
                  }
                  default: {
                    if (selected) {
                      if (selection) {
                        newValue.selfEquipped = selection.type === 'self';
                        newValue.clubEquipped = selection.type === 'club';
                        newValue.model1 = {
                          ...selection.model,
                        };
                      }
                    } else {
                      newValue = null;
                      // newValue.selfEquipped = undefined;
                      // newValue.clubEquipped = undefined;
                    }
                    break;
                  }
                }
                onChange(newValue);
                onChangeEmit && onChangeEmit({ selection, newValue });
              }}
            />
            {isAltEquipmentVisible && (
              <div className="'flex-grow w-1/2 relative">
                <label className="absolute -top-3 left-0 uppercase text-app-xxs text-gray-500">
                  {label} alt
                </label>
                <AppSingleAutocomplete2
                  className={'w-full'}
                  fullWidth={true}
                  disabled={value?.selfEquipped}
                  options={modelOptions}
                  value={value?.model2?.ref}
                  onChange={(selected) => {
                    const selection: {
                      type: 'club' | 'self';
                      model: DiveCenterEquipmentModel;
                    } = customModels.find((x) => x.model.ref === selected);
                    const newValue: EquipmentDescription = value
                      ? {
                          ...value,
                          model2: selection?.model,
                        }
                      : {
                          model2: selection?.model,
                        };
                    onChange(newValue);
                    onChangeEmit && onChangeEmit({ selection, newValue });
                  }}
                />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
}
