import { DiveSessionResumeParticipant } from '@mabadive/app-common-model';
import {
  participantAptitudeBuilder,
  participantTrainingMainLevelFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';

export const ParticipantDiveAptitudeBadge: FC<{
  participant: DiveSessionResumeParticipant;
}> = ({ participant }) => {
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const diver = participant.diver;
  const aptitude = useMemo(
    () =>
      participantAptitudeBuilder.formatAptitude(
        {
          diver: participant.diver,
          participant,
        },
        {
          clubReference,
        },
      ),
    [clubReference, participant],
  );
  const clubSettings = useClubSettings();
  const mainLevelForTraining = useMemo(
    () =>
      participantTrainingMainLevelFormatter.formatMainLevelForTraining({
        diver,
        participant,
        clubSettings,
      }),
    [clubSettings, diver, participant],
  );

  const chunks = [aptitude, mainLevelForTraining].filter(
    (x) => x?.trim()?.length > 0,
  );

  return chunks.length > 0 ? (
    <div
      className={clsx(
        'text-center px-0.5 py-0.5 rounded border border-gray-600 text-xs font-bold',
        chunks.length === 2 && 'leading-3',
      )}
    >
      {aptitude?.trim()?.length > 0 && (
        <div className=" whitespace-nowrap">{aptitude}</div>
      )}
      {mainLevelForTraining?.trim()?.length > 0 && (
        <div className=" whitespace-nowrap">{mainLevelForTraining}</div>
      )}
    </div>
  ) : null;
};
