/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
} from '@mabadive/app-common-model';
import { diveSessionGroupDiveModeBuilder } from '@mabadive/app-common-services';
import React, { FC, useCallback, useMemo } from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiveSessionEditorDialogLocalState } from '../../useDiveSessionEditorDialogLocalState.hook';
import { useOnUpdateSessionStaffConfig } from '../DiveSessionDialogTab2StaffMembers/useOnUpdateSessionStaffConfig.hook';
import {
  DiveSessionDialogTab3EditableGroup,
  DiveSessionDialogTab3EditableGroupFake,
  DiveSessionDialogTab3ParticipantInfo,
} from './components';
import { diveSessionDialogTab3GroupsStateUpdator } from './diveSessionDialogTab3GroupsStateUpdator.service';

export const DiveSessionDialogTab3GroupsPanel2SessionGroups: FC<{
  localState: DiveSessionEditorDialogLocalState;
  className?: string;
}> = ({ localState, className = '' }) => {
  const {
    form,
    clubReference,
    aggregatedData: {
      groups,
      clubParticipants,
      diveSession,
      divers,
      staffMembers,
    },
    data,
    actions,
    updateState,
    setUpdateState,
    participantFirstNameBefore,
  } = localState;

  const clubResume = useClubResume();

  function addSelectedDiversToGroup(
    {
      group,
    }: {
      group: DiveSessionResumeGroup;
    } = {
      group: undefined,
    },
  ) {
    const diveSessionGroupCurrentDiveMode = group.diveMode;
    const hasDiveGuide = !!group.diveTourGroupSession1?.diveGuide;
    const hasInstructor = !!group.diveTourGroupSession1?.instructor;

    const selectedUngrouppedParticipants =
      data.selectedUngrouppedParticipantsIds
        .map((participantId) => {
          const participant = data.participantsResumes.find(
            (participant) => participantId === participant._id,
          );
          if (participant) {
            return participant;
          }
          return undefined;
        })
        .filter((x) => !!x);

    const selectedMember: ClubResumeStaffMember = data.selectedMember;

    // const staffMember: ClubResumeStaffMember[] = useMemo(() => {
    //   const staffIds = [
    //     group?.diveTourGroupSession1?.instructor?.staffId,
    //     group?.diveTourGroupSession2?.instructor?.staffId,
    //   ].filter((x) => !!x);
    //   const selectedMembers: ClubResumeStaffMember[] = [];

    //   if (selectedMember && !staffIds.includes(selectedMember?._id)) {
    //     selectedMembers.push(selectedMember);
    //   }
    //   return selectedMembers;
    // }, [group, selectedMember]);

    console.log('xxx selectedMember:', selectedMember);

    const diveMode =
      diveSessionGroupDiveModeBuilder.buildFromParticipantsDiveModes({
        diveModes: selectedUngrouppedParticipants.map((p) => p.diveMode),
        hasDiveGuide,
        hasInstructor,
        debugContext: '2 groupSelectedDivers',
      });
    const { isVirtualGroup, booking, ...existingGroup } = group;
    let localUpdateState =
      diveSessionDialogTab3GroupsStateUpdator.groupParticipants({
        updateState,
        existingGroup,
        diveSession,
        diveMode,
        clubReference,
        participants: selectedUngrouppedParticipants,
        staffMember: selectedMember,
      });

    if (group) {
      localUpdateState =
        diveSessionDialogTab3GroupsStateUpdator.updateGroupDiveMode({
          _id: group._id,
          previousDiveMode: diveSessionGroupCurrentDiveMode,
          newDiveMode: diveMode,
          updateState: localUpdateState,
        });
    }

    setUpdateState(localUpdateState);
    actions.setSelectedUngrouppedParticipantsIds([]);
  }
  const onRemoveParticipantsFromGroup = useCallback(
    (
      participants: DiveSessionResumeParticipant[],
      group: DiveSessionResumeGroup,
    ) => {
      const localUpdateState =
        diveSessionDialogTab3GroupsStateUpdator.removeParticipants({
          updateState,
          participants,
          diveSessionParticipantsByGroup: data.diveSessionParticipantsByGroup,
          group,
        });
      setUpdateState(localUpdateState);
      // add participant to selection
      actions.setSelectedUngrouppedParticipantsIds(
        data.selectedUngrouppedParticipantsIds.concat(
          participants.map((x) => x._id),
        ),
      );
    },
    [
      updateState,
      data.diveSessionParticipantsByGroup,
      data.selectedUngrouppedParticipantsIds,
      setUpdateState,
      actions,
    ],
  );
  const observers = useMemo(
    () =>
      data.diveSessionParticipantsByGroup.ungroupped.filter(
        (participant) =>
          participant.bookingState &&
          ['pending', 'confirmed'].includes(participant.bookingState.value) &&
          participant.diveMode === 'observer',
      ),
    [data.diveSessionParticipantsByGroup.ungroupped],
  );

  const onUpdateSessionStaffConfig = useOnUpdateSessionStaffConfig({
    localState,
  });

  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <h2 className="text-center text-sm sm:text-lg leading-6 p-2 font-medium text-app-blue uppercase">
        Palanquées
      </h2>

      {data.diveSessionParticipantsByGroup.all.length === 0 ? (
        <AppMessageLight hideBorder>Aucun participant inscrit</AppMessageLight>
      ) : data.diveSessionParticipantsByGroup.byGroup.length === 0 ? (
        <AppMessageLight className="my-2">
          Pour créer une palanquée, sélectionnez des participants puis cliquez
          sur le bouton "Nouvelle palanquée".
        </AppMessageLight>
      ) : null}
      <>
        {data.diveSessionParticipantsByGroup.byGroup.map(
          ({ group, participants }, groupIndex) => (
            <DiveSessionDialogTab3EditableGroup
              key={group._id}
              group={group}
              participants={participants}
              localState={localState}
              onRemoveParticipantsFromGroup={onRemoveParticipantsFromGroup}
              onAddSelectedParticipantsToGroup={(group) => {
                addSelectedDiversToGroup({
                  group,
                });
              }}
            />
          ),
        )}
        {observers.length !== 0 && (
          <div className={'border-2 border-l-4 border-gray-400 pl-px p-1'}>
            <div className={' px-2'}>
              {observers.map((participant, participantIndex) => (
                <div
                  key={participant._id}
                  className={
                    'select-none flex items-start gap-2 text-gray-600 py-1'
                  }
                >
                  <DiveSessionDialogTab3ParticipantInfo
                    localState={localState}
                    participant={participant}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </>
      {data.diveSessionParticipantsByGroup.assignedFreeInstructors?.length >
        0 && (
        <div className="grid gap-1">
          {data.diveSessionParticipantsByGroup.assignedFreeInstructors.map(
            (staffMember, staffMemberIndex) => (
              <DiveSessionDialogTab3EditableGroupFake
                key={staffMember._id}
                staffMember={staffMember}
                localState={localState}
                onAddSelectedParticipantsToFakeGroup={() => {
                  actions.groupSelectedDivers({
                    staffMember,
                  });
                }}
                onRemoveFakeGroup={() => {
                  onUpdateSessionStaffConfig(staffMember, {
                    availabilityPeriod: 'day',
                    isAvailableOnSession: true,
                    toogleRole: {
                      assigned: true,
                      staffRoleRef: 'scuba-diving-instructor',
                    },
                  });
                }}
                // onAddSelectedParticipantsToGroup={(group) => {
                //   groupSelectedDivers({
                //     diveSessionGroupId: group._id,
                //     diveSessionGroupCurrentDiveMode: group.diveMode,
                //     hasDiveGuide: !!group.diveTourGroupSession1?.diveGuide,
                //     hasInstructor: !!group.diveTourGroupSession1?.instructor,
                //   });
                // }}
              />
            ),
          )}
        </div>
      )}
    </div>
  );
};
