import { DiveSessionTimeDayPeriod } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';

export const ClubDiveSessionHeaderTitleDbBlock = function ({
  sessionsCount,
  timeDayPeriod,
  format,
}: {
  sessionsCount: number;
  timeDayPeriod: DiveSessionTimeDayPeriod;
  format: 'short' | 'full';
}) {
  return sessionsCount > 1 ? (
    <span
      className={clsx(
        'font-bold rounded-full py-0.5 text-xs text-white text-center align-middle',
        `bg-day-period-${timeDayPeriod}-dark`,
        format === 'full' ? 'px-2' : 'px-1',
      )}
    >
      x{sessionsCount}{' '}
      {format === 'full' && <span className="hidden sm:inline">BLOCKS</span>}
    </span>
  ) : null;
};
