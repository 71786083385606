import { CompanyAccommodation } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  AppIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';

export const AccommodationLabel = ({
  accommodation: accommodationInput,
  accommodationId,
  pickupActive,
  className,
}: {
  accommodation?: CompanyAccommodation;
  accommodationId?: string;
  pickupActive?: boolean;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const accommodations = clubResume.accommodations;
  const accommodation = useMemo(
    () =>
      accommodationInput ??
      (accommodationId
        ? accommodations.find((x) => x._id === accommodationId)
        : undefined),
    [accommodationInput, accommodationId, accommodations],
  );

  return !accommodation ? null : (
    <div className={clsx('flex gap-2 items-center', className)}>
      <AppIcons.settings.customers.accomodation
        className="w-6 h-4 border border-gray-600 py-px px-pt"
        style={{
          background: accommodation.color,
          color: accommodation.colorText,
        }}
      />
      <span>{accommodation.name}</span>
      {accommodation.pickup?.enabled && pickupActive ? (
        <AppIconsSettings.customers.pickup
          className="w-6 h-4 border border-gray-600 py-px px-pt"
          style={{
            background: accommodation.color,
            color: accommodation.colorText,
          }}
        />
      ) : null}
    </div>
  );
};
