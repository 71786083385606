/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubParticipantSpecialDiveType,
  ClubProductPackageAttributesDive,
  ClubProductPackageAttributesDivePriceType,
  ClubProductPackageAttributesDiveType,
  DIVE_MODES,
  DIVE_SERVICE_OFFER_EQUIPMENTS,
  DIVE_SERVICE_OFFER_SUPERVISIONS,
  DIVE_TYPES,
  DiveMode,
  DiveServiceOfferEquipment,
  DiveServiceOfferSupervision,
  PACKAGE_ATTRIBUTES_DIVES_COUNT_EXTENSION_MODES,
  PARTICIPANT_SPECIAL_DIVE_TYPES,
  PRODUCT_PACKAGE_PRICE_TYPES,
  PackageAttributeDiveSingleAttributes,
  PackageAttributeDivesCountExtensionMode,
} from '@mabadive/app-common-model';
import {
  ClubProductPackageNameDetailsFormatProps,
  diveModeAnalyser,
  diveModeFormatter,
  divePriceTypeFormatter,
  diveTypeFormatter,
  offerEquipmentFormatter,
  offerSupervisionFormatter,
  packageAttributeDivesCountExtensionModeFormatter,
  participantSpecialDiveTypeFormatter,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import { Box, FormControl, FormLabel } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { AppFixedButtonsBar } from 'src/business/_core/modules/layout/components/AppFixedButtonsBar/AppFixedButtonsBar';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { ClubOfferNameDetailsLabel } from 'src/business/club/modules/club-diver-participant/components';
import { useResidentTypesOptions } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms';
import {
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppMultiCheckboxesRHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useDiveSessionThemesOptions,
  useDiveSessionTypesOptions,
  useFirstDiveTrainingOptions,
} from 'src/pages/_components/options';
import { ClubOfferCustomNameForm } from './ClubOfferCustomNameForm';
import { ClubOfferDiveEditorContext } from './ClubOfferDiveEditorContext.type';
import { ClubOfferDiveEditorFormModel } from './ClubOfferDiveEditorFormModel.type';
import { ClubOfferDiveEditorResult } from './ClubOfferDiveEditorResult.type';
import { clubOfferDiveEditorResultBuilder } from './clubOfferDiveEditorResultBuilder.service';

const PARTICIPANT_SPECIAL_DIVE_TYPES_OPTIONS: ValueLabel<ClubParticipantSpecialDiveType>[] =
  PARTICIPANT_SPECIAL_DIVE_TYPES.map((status) => ({
    value: status,
    label: participantSpecialDiveTypeFormatter.formatSpecialDiveType(status, {
      format: 'long',
    }),
  }));

const ALLOWED_DIVE_MODES: DiveMode[] = [
  'first-dive',
  'supervised',
  'autonomous',
  'free-dive',
  'snorkeling',
  'snorkelingSupervised',
  'watchingTour',
  'autoSupervised',
  'observer',
  'instructor',
];

const ALLOWED_DIVE_MODES_EXTRA: DiveMode[] = ALLOWED_DIVE_MODES.concat([
  'training',
]);
const ALLOWED_DIVE_MODES_EXTRA_OPTIONS = ALLOWED_DIVE_MODES_EXTRA.map(
  (diveMode) => {
    const option: ValueLabel<DiveMode> = {
      label: diveModeFormatter
        .formatDiveMode(diveMode, { format: 'short-ref-label' })
        .toUpperCase(),
      value: diveMode,
    };
    return option;
  },
);

export const ClubOfferDiveEditor = ({
  onCancel,
  onConfirm,
  className,
  context,
}: {
  onCancel?: () => void;
  onConfirm?: (result: ClubOfferDiveEditorResult) => void;
  className?: string;
  context: ClubOfferDiveEditorContext;
}) => {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const generalSettings = clubResume.clubSettings.general;

  // TODO plus tard : on pourrait améliorer ça en filtrant par clubResume.serviceTypes,
  // mais il faudrait que celui-ci soit éditable depuis la même page, pour ce qui concerne cette page
  const diveModeOptions = useMemo(
    () =>
      DIVE_MODES.filter((x) => ALLOWED_DIVE_MODES.includes(x)).map(
        (diveMode) => {
          const option: ValueLabel<DiveMode> = {
            label: diveModeFormatter
              .formatDiveMode(diveMode, { format: 'short-ref-label' })
              .toUpperCase(),
            value: diveMode,
          };
          return option;
        },
      ),
    [],
  );

  const initialFormValue = useMemo(() => {
    if (context.mode === 'create') {
      const model: ClubOfferDiveEditorFormModel = {
        type: 'dive',
        productType: null,
        price: null,
        thirdPartyCollectPrice: null,
        namingConfiguration: {
          includeEquipment: true,
          includeSupervision: true,
          includeDefaultName: true,
        },
        comment: null,
        diveMode: null,
        minDistance: null,
        maxDistance: null,
        diveType: null,
        divesCount: 1,
        divesCountMax: undefined,
        divesCountInfinite: false,
        equipment: null,
        supervision: null,
        specialDiveType: null,
        minDepth: null,
        maxDepth: null,
        divePriceType: 'single',
        successiveDivesCount: null,
        diveSingleAttributes: {
          minDivesCount: null,
          maxDivesCount: null,
        },
        divePackageAttributes: {
          divesCountExtensionMode: 'free',
        },
        productAttributes: {
          minAge: null,
          maxAge: null,
          sharedOfferMaxPersonsCount: null,
          validityPeriodInDays: null,
        },
      };
      return model;
    } else {
      const o = context.offer;
      const productAttributes = o.productPackage.productAttributes;
      const diveAttributes = o.productPackage.diveAttributes;
      const diveSingleAttributes = diveAttributes?.diveSingleAttributes;
      const divePackageAttributes = diveAttributes?.divePackageAttributes;
      const salesCriteria = o.productPackage.salesCriteria;
      const model: ClubOfferDiveEditorFormModel = {
        type: 'dive',
        productType: null,
        salesCriteria,
        price: o.price,
        thirdPartyCollectPrice: o.thirdPartyCollectPrice,
        label: o.productPackage.label,
        comment: o.productPackage.comment,
        namingConfiguration: o.productPackage.namingConfiguration,
        diveMode: diveAttributes.diveMode,
        firstDiveTrainingReference: diveAttributes.firstDiveTrainingReference,
        extraDiveModes: diveAttributes.extraDiveModes,
        diveType: diveAttributes.diveType,
        divesCount: diveAttributes.divesCount,
        divesCountMax: diveAttributes.divesCountMax,
        divesCountInfinite: diveAttributes.divesCountInfinite,
        equipment: diveAttributes.equipment,
        supervision: diveAttributes.supervision,
        specialDiveType: diveAttributes.specialDiveType,
        minDepth: diveAttributes.minDepth,
        maxDepth: diveAttributes.maxDepth,
        minDistance: diveAttributes.minDistance,
        maxDistance: diveAttributes.maxDistance,
        divePriceType: diveAttributes.divePriceType,
        successiveDivesCount: diveAttributes.successiveDivesCount,
        diveSingleAttributes,
        divePackageAttributes,
        productAttributes: {
          minAge: productAttributes.minAge,
          maxAge: productAttributes.maxAge,
          sharedOfferMaxPersonsCount:
            productAttributes.sharedOfferMaxPersonsCount,
          validityPeriodInDays: productAttributes.validityPeriodInDays,
        },
      };
      return model;
    }
  }, [context.mode, context.offer]);

  const form = useForm<ClubOfferDiveEditorFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const [
    diveMode,
    namingConfiguration,
    label,
    diveType,
    divesCount,
    divesCountMax,
    divesCountInfinite,
    equipment,
    supervision,
    divePriceType,
    minDepth,
    maxDepth,
    minDistance,
    maxDistance,
    minDivesCount,
    maxDivesCount,
    overrideDefaultNameEnabled,
  ] = useWatch({
    control,
    name: [
      'diveMode',
      'namingConfiguration',
      'label',
      'diveType',
      'divesCount',
      'divesCountMax',
      'divesCountInfinite',
      'equipment',
      'supervision',
      'divePriceType',
      'minDepth',
      'maxDepth',
      'minDistance',
      'maxDistance',
      'diveSingleAttributes.minDivesCount',
      'diveSingleAttributes.maxDivesCount',
      'namingConfiguration.overrideDefaultName.enabled',
    ],
  });

  const divePriceTypeOptions = useMemo(
    () =>
      PRODUCT_PACKAGE_PRICE_TYPES.filter((x) => {
        if (x === 'package') {
          return (
            diveMode === 'instructor' ||
            diveMode === 'autonomous' ||
            diveMode === 'supervised' ||
            diveMode === 'first-dive' ||
            diveMode === 'snorkeling' ||
            diveMode === 'snorkelingSupervised' ||
            diveMode === 'watchingTour' ||
            diveMode === 'autoSupervised' ||
            diveMode === 'free-dive'
          );
        } else if (x === 'successive') {
          return (
            diveMode === 'autonomous' ||
            diveMode === 'supervised' ||
            diveMode === 'instructor'
          );
        }
        return true;
      }).map((divePriceType) => {
        const option: ValueLabel<ClubProductPackageAttributesDivePriceType> = {
          label: divePriceTypeFormatter
            .formatDivePriceType(divePriceType)
            .toUpperCase(),
          value: divePriceType,
        };
        return option;
      }),
    [diveMode],
  );

  const diveTypesOptions = useMemo(
    () =>
      DIVE_TYPES.filter((x) => {
        if (
          diveMode === 'first-dive' ||
          diveMode === 'supervised' ||
          diveMode === 'autonomous' ||
          diveMode === 'instructor'
        ) {
          return x === 'scuba';
        }
        return false;
      }).map((diveType) => {
        const option: ValueLabel<ClubProductPackageAttributesDiveType> = {
          label: diveTypeFormatter
            .formatDiveType(diveType, {
              format: 'full',
            })
            .toUpperCase(),
          value: diveType,
        };
        return option;
      }),
    [diveMode],
  );
  const equipmentsOptions = useMemo(
    () =>
      DIVE_SERVICE_OFFER_EQUIPMENTS.filter((x) => {
        if (diveMode === 'first-dive') {
          return x === 'not-equipped'; // éq. club
        }
        if (diveModeAnalyser.hasExploPricingEquipmentOption(diveMode)) {
          return true;
        }

        return false;
      }).map((equipment) => {
        const option: ValueLabel<DiveServiceOfferEquipment> = {
          label: offerEquipmentFormatter
            .formatOfferEquipment(equipment, { format: 'full' })
            .toUpperCase(),
          value: equipment,
        };
        return option;
      }),
    // .concat([
    //   {
    //     label: '',
    //     value: null,
    //   },
    // ])

    [diveMode],
  );
  const supervisionOptions = useMemo(
    () =>
      DIVE_SERVICE_OFFER_SUPERVISIONS.filter((x) => {
        if (diveMode === 'first-dive') {
          return x === 'supervised';
        }
        if (diveMode === 'supervised') {
          return x === 'supervised';
        }
        if (diveMode === 'autonomous' || diveMode === 'instructor') {
          return x === 'autonomous';
        }
        return false;
      }).map((supervision) => {
        const option: ValueLabel<DiveServiceOfferSupervision> = {
          label: offerSupervisionFormatter
            .formatOfferSupervision(supervision, { format: 'full' })
            .toUpperCase(),
          value: supervision,
        };
        return option;
      }),
    [diveMode],
  );

  const onDivePriceTypeChanges = useCallback(
    (divePriceType: ClubProductPackageAttributesDivePriceType) => {
      if (divePriceType === 'successive') {
        setValue('successiveDivesCount', 2, {
          shouldDirty: true,
          shouldTouch: true,
        });
      } else {
        setValue('successiveDivesCount', null, {
          shouldDirty: true,
          shouldTouch: true,
        });
      }
      if (divePriceType !== 'package') {
        setValue('divesCount', 1, {
          shouldDirty: true,
          shouldTouch: true,
        });
        setValue('divesCountInfinite', false, {
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    },
    [setValue],
  );
  const onDiveModeChanges = useCallback(
    (diveMode: DiveMode) => {
      if (
        divePriceType === 'successive' &&
        diveMode !== 'autonomous' &&
        diveMode !== 'supervised' &&
        diveMode !== 'instructor'
      ) {
        setValue('divePriceType', 'single', {
          shouldDirty: true,
          shouldTouch: true,
        });
        setValue('successiveDivesCount', null, {
          shouldDirty: true,
          shouldTouch: true,
        });
        setValue('divesCountInfinite', false, {
          shouldDirty: true,
          shouldTouch: true,
        });
      }

      if (divePriceType === 'single' && divesCount !== 1) {
        setValue('divesCount', 1, {
          shouldDirty: true,
          shouldTouch: true,
        });
      }
      if (divePriceType === 'single' && divesCountInfinite) {
        setValue('divesCountInfinite', false, {
          shouldDirty: true,
          shouldTouch: true,
        });
      }

      switch (diveMode) {
        case 'observer': {
          setValue(
            'diveType',
            'observer' as ClubProductPackageAttributesDiveType,
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );
          setValue('minDepth', null, {
            shouldDirty: true,
            shouldTouch: true,
          });
          setValue('maxDepth', null, {
            shouldDirty: true,
            shouldTouch: true,
          });
          setValue('divePriceType', 'single', {
            shouldDirty: true,
            shouldTouch: true,
          });
          setValue('divesCountInfinite', false, {
            shouldDirty: true,
            shouldTouch: true,
          });
          break;
        }
        case 'snorkeling':
        case 'snorkelingSupervised':
        case 'watchingTour':
        case 'free-dive': {
          setValue('diveType', 'free' as ClubProductPackageAttributesDiveType, {
            shouldDirty: true,
            shouldTouch: true,
          });
          break;
        }
        case 'instructor': {
          setValue(
            'diveType',
            'scuba' as ClubProductPackageAttributesDiveType,
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );
          if (!divesCount) {
            setValue('divesCount', 1, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue('divesCountInfinite', false, {
              shouldDirty: true,
              shouldTouch: true,
            });
          }
          setValue('equipment', 'any-equipment', {
            shouldDirty: true,
            shouldTouch: true,
          });
          setValue('supervision', 'autonomous', {
            shouldDirty: true,
            shouldTouch: true,
          });
          break;
        }
        case 'autoSupervised': {
          setValue(
            'diveType',
            'scuba' as ClubProductPackageAttributesDiveType,
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );
          if (!divesCount) {
            setValue('divesCount', 1, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue('divesCountInfinite', false, {
              shouldDirty: true,
              shouldTouch: true,
            });
          }
          setValue('equipment', 'any-equipment', {
            shouldDirty: true,
            shouldTouch: true,
          });
          // setValue('supervision', 'auto', {
          //   shouldDirty: true,
          //   shouldTouch: true,
          // });
          break;
        }
        case 'first-dive':
        case 'autonomous':
        case 'supervised': {
          // set
          setValue(
            'diveType',
            'scuba' as ClubProductPackageAttributesDiveType,
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );
          if (diveMode === 'first-dive' || !equipment) {
            setValue('equipment', 'not-equipped', {
              shouldDirty: true,
              shouldTouch: true,
            });
          }

          setValue(
            'supervision',
            diveMode === 'first-dive' || diveMode === 'supervised'
              ? 'supervised'
              : 'autonomous',
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );
          if (diveMode === 'first-dive') {
            setValue('maxDepth', 6, {
              shouldDirty: true,
              shouldTouch: true,
            });
          }
          if (!divesCount) {
            setValue('divesCount', 1, {
              shouldDirty: true,
              shouldTouch: true,
            });
            setValue('divesCountInfinite', false, {
              shouldDirty: true,
              shouldTouch: true,
            });
          }
          break;
        }
      }
    },
    [divePriceType, divesCount, divesCountInfinite, equipment, setValue],
  );

  const user = useAppSecurityUser();

  const divesCountExtensionModesOptions: ValueLabel<
    PackageAttributeDivesCountExtensionMode,
    React.ReactNode
  >[] = useMemo(
    () =>
      PACKAGE_ATTRIBUTES_DIVES_COUNT_EXTENSION_MODES.map((mode) => {
        return {
          value: mode,
          label:
            packageAttributeDivesCountExtensionModeFormatter.formatDivesCountExtensionMode(
              mode,
            ),
        };
      }),
    [],
  );

  const residentTypesOptions = useResidentTypesOptions();

  const nameDetails = useMemo(() => {
    const diveSingleAttributes: PackageAttributeDiveSingleAttributes =
      divePriceType === 'single'
        ? {
            minDivesCount,
            maxDivesCount,
          }
        : null;

    const diveAttributes: ClubProductPackageAttributesDive = {
      diveMode,
      diveType,
      divePriceType,
      equipment,
      supervision,
      divesCount,
      divesCountMax,
      minDepth,
      maxDepth,
      minDistance,
      maxDistance,
      diveSingleAttributes,
    };

    const productPackage: ClubProductPackageNameDetailsFormatProps = {
      label,
      namingConfiguration,
      diveAttributes,
      type: 'dive',
    };

    const nameDetails = productPackageFormatter.formatNameDetails(
      productPackage,
      {
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );
    return nameDetails;
  }, [
    divePriceType,
    minDivesCount,
    maxDivesCount,
    diveMode,
    diveType,
    equipment,
    supervision,
    divesCount,
    divesCountMax,
    minDepth,
    maxDepth,
    minDistance,
    maxDistance,
    label,
    namingConfiguration,
    publicSettings,
    clubSettings?.ui?.diveMode,
  ]);

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: ClubOfferDiveEditorFormModel, event) => {
        const { result, hasChanges } =
          clubOfferDiveEditorResultBuilder.buildResult({
            clubReference: clubResume.reference,
            context,
            formValue,
          });

        if (!hasChanges) {
          // no changes
          onCancel();
          return;
        } else {
          onConfirm(result);
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [clubResume.reference, context, handleSubmit, onCancel, onConfirm]);

  const diveSessionThemesOptions = useDiveSessionThemesOptions({
    scope: 'session-settings',
  });
  const diveSessionTypesOptions = useDiveSessionTypesOptions({
    scope: 'session-settings',
  });

  const firstDiveTrainingOptions = useFirstDiveTrainingOptions(clubSettings, {
    scope: 'enabled',
  });
  return (
    <div className={className}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <AppFormControlRHF_Deprecated
          label="Prestation"
          control={control}
          name="diveMode"
          required={true}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={diveModeOptions}
              onChange={(diveMode) => onDiveModeChanges(diveMode)}
            />
          )}
        />

        {diveMode === 'first-dive' && firstDiveTrainingOptions.length > 1 && (
          <AppFormControlRHF_Deprecated
            className={clsx('w-80')}
            label="Type de baptême"
            control={control}
            name={'firstDiveTrainingReference'}
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={firstDiveTrainingOptions}
              />
            )}
          />
        )}
        {diveMode && diveTypesOptions.length > 1 && (
          <AppFormControlRHF_Deprecated
            label="Mode de plongée"
            control={control}
            name="diveType"
            required={true}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={diveTypesOptions}
              />
            )}
          />
        )}
        {diveMode && equipmentsOptions.length > 1 && (
          <AppFormControlRHF_Deprecated
            label="Équipement"
            control={control}
            name="equipment"
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={equipmentsOptions}
              />
            )}
          />
        )}
        {diveMode && supervisionOptions.length > 1 && (
          <AppFormControlRHF_Deprecated
            label="Encadrement"
            control={control}
            name="supervision"
            required={true}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={supervisionOptions}
              />
            )}
          />
        )}
        {diveMode &&
          ['autonomous', 'supervised', 'instructor'].includes(diveMode) && (
            <AppFormControlRHF_Deprecated
              className={'sm:col-span-2 w-full'}
              label="Plongée spéciale"
              control={control}
              name={'specialDiveType'}
              renderComponent={(props) => (
                <AppSingleAutocomplete2RHF
                  {...props}
                  options={PARTICIPANT_SPECIAL_DIVE_TYPES_OPTIONS}
                />
              )}
            />
          )}
      </div>
      {diveMode && (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-8 gap-4">
            {diveMode && divePriceTypeOptions.length > 1 && (
              <AppFormControlRHF_Deprecated
                className="col-span-2"
                label="Type"
                control={control}
                name="divePriceType"
                required={true}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={divePriceTypeOptions}
                    onChange={(divePriceType) =>
                      onDivePriceTypeChanges(divePriceType)
                    }
                  />
                )}
              />
            )}
            <AppFormControlRHF_Deprecated
              className={clsx('', divesCountInfinite && 'invisible')}
              control={control}
              label={'Quantité'}
              required={true}
              disabled={divePriceType !== 'package'}
              name={'divesCount'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              className={clsx('', divesCountInfinite && 'invisible')}
              control={control}
              label={'Quantité max'}
              name={'divesCountMax'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            {divePriceType !== 'successive' && (
              <AppFormControlRHF_Deprecated
                control={control}
                label="PASS illimité"
                name={'divesCountInfinite' as any}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF {...props} />
                )}
              />
            )}
            <AppFormControlRHF_Deprecated
              label="Tarif"
              control={control}
              required={false}
              name={'price'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            {generalSettings?.billing?.thirdPartyCollectEnabled && (
              <AppFormControlRHF_Deprecated
                label="Tarif (part tiers)"
                control={control}
                required={false}
                name={'thirdPartyCollectPrice'}
                renderComponent={(props) => (
                  <AppInputRHF {...props} type="number" fullWidth />
                )}
              />
            )}

            {diveMode && divePriceType === 'successive' && (
              <AppFormControlRHF_Deprecated
                control={control}
                label={'Plongées successives'}
                required={true}
                name={'successiveDivesCount'}
                renderComponent={(props) => (
                  <AppInputRHF {...props} type="number" fullWidth />
                )}
              />
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div className={`col-span-2 flex flex-col ${className ?? ''}`}>
              <FormControl>
                <FormLabel className="form-label pl-1" component="legend">
                  <span className="text-xs uppercase">Libellé affiché</span>
                </FormLabel>
                <Box className="form-input">
                  <ClubOfferNameDetailsLabel
                    className="font-bold text-app-blue"
                    nameDetails={nameDetails}
                  />
                </Box>
              </FormControl>
            </div>

            <AppFormControlRHF_Deprecated
              label="Libellé additionnel"
              control={control}
              name="label"
              renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
            />
            <div>
              {label?.length > 0 && (
                <AppFormControlRHF_Deprecated
                  className="mb-2"
                  label={'Nommage'}
                  control={control}
                  name="namingConfiguration.includeDefaultName"
                  renderComponent={(props) => (
                    <AppInputBooleanRHF
                      {...props}
                      type="checkbox"
                      description={'inclure le nom par défaut'}
                    />
                  )}
                />
              )}
              {namingConfiguration.includeDefaultName &&
                (diveMode === 'supervised' || diveMode === 'autonomous') && (
                  <AppFormControlRHF_Deprecated
                    className="mb-2"
                    control={control}
                    name="namingConfiguration.includeSupervision"
                    renderComponent={(props) => (
                      <AppInputBooleanRHF
                        {...props}
                        type="checkbox"
                        description={"inclure l'encadrement"}
                      />
                    )}
                  />
                )}
              {namingConfiguration.includeDefaultName &&
                (diveMode === 'supervised' || diveMode === 'autonomous') && (
                  <AppFormControlRHF_Deprecated
                    className="mb-2"
                    control={control}
                    name="namingConfiguration.includeEquipment"
                    renderComponent={(props) => (
                      <AppInputBooleanRHF
                        {...props}
                        type="checkbox"
                        description={"inclure l'équipement"}
                      />
                    )}
                  />
                )}
            </div>
          </div>
          <ClubOfferCustomNameForm
            form={form as any}
            nameDetails={nameDetails}
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <AppFormControlRHF_Deprecated
              control={control}
              label="Age mini (inclu)"
              name={'productAttributes.minAge'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label="Age maxi (exclu)"
              name={'productAttributes.maxAge'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />

            {diveMode !== 'observer' && (
              <>
                <AppFormControlRHF_Deprecated
                  control={control}
                  label="Profondeur mini"
                  name={'minDepth'}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} type="number" fullWidth />
                  )}
                />
                <AppFormControlRHF_Deprecated
                  control={control}
                  label="Profondeur maxi"
                  name={'maxDepth'}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} type="number" fullWidth />
                  )}
                />
              </>
            )}
            <AppFormControlRHF_Deprecated
              control={control}
              label="Partagé entre (nombre personnes)"
              name={'productAttributes.sharedOfferMaxPersonsCount'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <AppFormControlRHF_Deprecated
              control={control}
              label="Distance mini (nmi) INCLUSIF"
              name={'minDistance'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label="Distance maxi (nmi) EXCLUSIF"
              name={'maxDistance'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            {divePriceType === 'single' && (
              <>
                <AppFormControlRHF_Deprecated
                  control={control}
                  label="Min plongées tarif"
                  name={'diveSingleAttributes.minDivesCount'}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} type="number" fullWidth />
                  )}
                />
                <AppFormControlRHF_Deprecated
                  control={control}
                  label="Max plongées tarif"
                  name={'diveSingleAttributes.maxDivesCount'}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} type="number" fullWidth />
                  )}
                />
              </>
            )}
            {divePriceType === 'package' && (
              <>
                <AppFormControlRHF_Deprecated
                  className="w-full"
                  label={'Tarif augmentation quantité'}
                  control={control}
                  name={'divePackageAttributes.divesCountExtensionMode'}
                  renderComponent={(props) => (
                    <AppSingleSelect2HeadlessUIRHF
                      className={'w-full'}
                      theme="material-ui"
                      {...props}
                      options={divesCountExtensionModesOptions}
                      // onChange={(value) => onChangeDiveSessionType(value)}
                    />
                  )}
                />
              </>
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <AppFormControlRHF_Deprecated
              className={'w-full'}
              label="Résidence"
              control={control}
              name={'salesCriteria.residentType'}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  className={'w-full'}
                  theme="material-ui"
                  color="theme"
                  {...props}
                  options={residentTypesOptions}
                />
              )}
            />
            {diveSessionTypesOptions.length > 0 && (
              <AppFormControlRHF_Deprecated
                className="w-full"
                label={'Type de sortie'}
                control={control}
                name={'salesCriteria.diveSessionType'}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    className={'w-full'}
                    theme="material-ui"
                    {...props}
                    options={diveSessionTypesOptions}
                    // onChange={(value) => onChangeDiveSessionType(value)}
                  />
                )}
              />
            )}
            {diveSessionThemesOptions.length > 0 && (
              <AppFormControlRHF_Deprecated
                className="w-full"
                label={'Thème de la sortie'}
                control={control}
                name={'salesCriteria.diveSessionTheme'}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    className={'w-full'}
                    theme="material-ui"
                    {...props}
                    options={diveSessionThemesOptions}
                    // onChange={(value) => onChangeDiveSessionTheme(value)}
                  />
                )}
              />
            )}
            <AppFormControlRHF_Deprecated
              control={control}
              label="Validité (jours)"
              name={'productAttributes.validityPeriodInDays'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              className="mb-2"
              label={'Offre par défaut'}
              control={control}
              name="salesCriteria.dontSelectByDefault"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  description={'ne pas pré-selectionner'}
                />
              )}
            />
          </div>
          <div>
            <AppFormControlRHF_Deprecated
              label="Commentaire"
              control={control}
              name="comment"
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
          </div>
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Prestations associées"
            control={control}
            name={'extraDiveModes'}
            renderComponent={(props) => (
              <AppMultiCheckboxesRHF
                className="flex flex-wrap text-xs"
                {...props}
                options={ALLOWED_DIVE_MODES_EXTRA_OPTIONS}
              />
            )}
          />
        </>
      )}

      <AppFixedButtonsBar hasChanges={formState.isDirty}>
        <AppButton
          fullWidth
          icon={AppHeroIcons.actionCancel}
          color="gray-outline-light"
          size="normal"
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          Annuler
        </AppButton>
        <AppButton
          fullWidth
          icon={AppHeroIcons.actionSave}
          color="primary-outline-light"
          onClick={() => {
            submitForm();
          }}
        >
          Confirmer
        </AppButton>
      </AppFixedButtonsBar>
    </div>
  );
};
