import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Placement } from '@popperjs/core';
import React, { Fragment, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import {
  AppButton,
  useMediaSizeTailwind,
} from 'src/business/_core/modules/layout';
import { AppSimpleMenuButtonItemContent } from './AppSimpleMenuButtonItemContent';
import { AppSimpleMenuButtonItemGroup } from './model';
import { AppIcon } from 'src/business/_core/modules/layout/icons/AppIcon.type';

export function AppSimpleMenuButton({
  header,
  children,
  childrenPosition = 'top',
  buttonLabel = 'ACTIONS',
  buttonLabelClasses = 'hidden sm:inline-block',
  menu = [],
  position,
  className = '',
  buttonClassName,
  ...otherProps
}: {
  header?: React.ReactNode;
  children?: React.ReactNode;
  childrenPosition?: 'top' | 'bottom';
  menu: AppSimpleMenuButtonItemGroup[];
  buttonLabel?: string;
  buttonLabelClasses?: string;
  position?: Placement;
  className?: string;
  buttonClassName?: string;
  buttonIcon?: AppIcon;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null);

  const mediaSize = useMediaSizeTailwind();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: mediaSize === 'xxs' || mediaSize === 'xs' ? 'auto' : position,
  });

  const filteredItemsGroups = useMemo(() => {
    return menu
      .map((g) => ({
        ...g,
        items: g.items.filter((x) => !x.hidden),
      }))
      .filter((x) => x.items.length);
  }, [menu]);

  const uniqueItem = useMemo(() => {
    if (
      filteredItemsGroups?.length === 1 &&
      filteredItemsGroups[0].items.length === 1
    ) {
      return filteredItemsGroups[0].items[0];
    }
  }, [filteredItemsGroups]);

  return !filteredItemsGroups?.length ? null : uniqueItem ? (
    // display single button
    <AppButton
      color={'primary-bg'}
      className={'group text-base'}
      href={uniqueItem.hrefRoute}
      onClick={uniqueItem.onClick}
    >
      <uniqueItem.icon className="h-5 w-5 p-0.5 bg-white text-app-primary-light group-hover:text-app-primary rounded-full" />
      <span className="hidden sm:inline font-bold uppercase">
        <span className="lg:hidden">
          {uniqueItem.titleShort ?? uniqueItem.title}
        </span>
        <span className="hidden lg:inline">{uniqueItem.title}</span>
      </span>
    </AppButton>
  ) : (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none">
          {buttonLabel}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {filteredItemsGroups.map((group, groupIndex) => (
            <div key={groupIndex} className="py-1">
              {group.items.map((item, itemIndex) => (
                <Menu.Item key={itemIndex}>
                  {(state) => (
                    <AppSimpleMenuButtonItemContent item={item} state={state} />
                  )}
                </Menu.Item>
              ))}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
