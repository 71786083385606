/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlRHF, AppInputBooleanRHF } from 'src/lib/form';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';

export const DECOP_SessionItemDive_Title = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  sessionItemIndex,
  sessionItemDiveIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  sessionItemIndex: number;
  sessionItemDiveIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}.items.${sessionItemIndex}.items.${sessionItemDiveIndex}` as const;

  const [sessionDiveId] = useWatch({
    control,
    name: [`${baseFormName}.id`],
  });

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  return (
    <div className={clsx('grid gap-2', className)}>
      <h4 className="text-left text-base font-medium text-gray-600">
        Titre {sessionItemDiveIndex + 1}
      </h4>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
        <AppFormControlRHF
          label={'N° plongée (si multi-tank)'}
          control={control}
          name={`${baseFormName}.number`}
          renderComponent={(props) => (
            <AppInputBooleanRHF type="checkbox" label="Afficher" {...props} />
          )}
        />
        <AppFormControlRHF
          label={'Heure'}
          control={control}
          name={`${baseFormName}.time`}
          renderComponent={(props) => (
            <AppInputBooleanRHF type="checkbox" label="Afficher" {...props} />
          )}
        />{' '}
        <AppFormControlRHF
          label={'N° session'}
          control={control}
          name={`${baseFormName}.session.number`}
          renderComponent={(props) => (
            <AppInputBooleanRHF type="checkbox" label="Afficher" {...props} />
          )}
        />
        <AppFormControlRHF
          label={'Nom session'}
          control={control}
          name={`${baseFormName}.session.number`}
          renderComponent={(props) => (
            <AppInputBooleanRHF type="checkbox" label="Afficher" {...props} />
          )}
        />
        <AppFormControlRHF
          label={'Bateau'}
          control={control}
          name={`${baseFormName}.boat`}
          renderComponent={(props) => (
            <AppInputBooleanRHF type="checkbox" label="Afficher" {...props} />
          )}
        />
        <AppFormControlRHF
          label={'Site plongée'}
          control={control}
          name={`${baseFormName}.diveSite`}
          renderComponent={(props) => (
            <AppInputBooleanRHF type="checkbox" label="Afficher" {...props} />
          )}
        />
        <AppFormControlRHF
          label={'Directeur Plongée (DP)'}
          control={control}
          name={`${baseFormName}.divingDirector`}
          renderComponent={(props) => (
            <AppInputBooleanRHF type="checkbox" label="Afficher" {...props} />
          )}
        />
        <AppFormControlRHF
          label={'Sécurité Surface'}
          control={control}
          name={`${baseFormName}.surfaceSecurity`}
          renderComponent={(props) => (
            <AppInputBooleanRHF type="checkbox" label="Afficher" {...props} />
          )}
        />
      </div>
    </div>
  );
};
