import { ClubCommercePurchasePaymentGql_Company } from '@mabadive/app-common-model';
import { MQueryDescription } from 'src/_common-browser';
import { PurchasePaymentCollectionCriteria } from '../../model';
import { PurchasePaymentGraphqlQueryResult } from '../../model/PurchasePaymentGraphqlQueryResult.type';

export function buildPurchasePaymentCollectionGraphqlQuery({
  diversIds,
  returnName,
}: // status: { purchasePackages },
PurchasePaymentCollectionCriteria) {
  const diversIdsString = diversIds.map((x) => `"${x}"`).join(',');

  const purchasePackagesFilters: string[] = [];
  // if (purchasePackages === 'only-active') {
  //   purchasePackagesFilters.push(
  //     '_or: [{validityStatus: {_eq: "active"}}, {purchasePaymentStatus: {_neq: "done"}}]',
  //   );
  // }
  purchasePackagesFilters.push(`diverId: {_in: [${diversIdsString}]}`);

  const where = `{${purchasePackagesFilters.join(',')}}`;

  const queryDescription: MQueryDescription<PurchasePaymentGraphqlQueryResult> =
    {
      returnName: returnName ?? 'ClubPurchasePayments',
      queryName: 'club_commerce_purchase_payment',
      returnType: 'all',
      where,
      orderBy: '{paymentDate: asc, dueDate: asc, creationDate: asc}',
      returnAttributes: ClubCommercePurchasePaymentGql_Company,
    };

  return queryDescription;
}
