/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  RegionalSettingsCriteria,
  RegionalTaxReference,
} from '@mabadive/app-common-model';
import React, { ReactNode, useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { RegionalTaxLabel } from '../company/RegionalTax/RegionalTaxLabel';
//import { RegionalTaxLabel } from '../company/RegionalTax/RegionalTaxLabel';
import { useRegionalSettings } from './useRegionalSettings.hook';
export function useTaxesOptions(
  criteria: RegionalSettingsCriteria,
): ValueLabel<RegionalTaxReference, ReactNode>[] {
  const regionalSettings = useRegionalSettings(criteria);

  return useMemo(() => {
    const taxes = regionalSettings?.taxes ?? [];

    const taxesOptions: ValueLabel<RegionalTaxReference, ReactNode>[] =
      taxes.map((tax) => ({
        value: tax.reference,
        label: <RegionalTaxLabel tax={tax} />,
      }));

    return taxesOptions;
  }, [regionalSettings?.taxes]);
}
