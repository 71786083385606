/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppMessageLight,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';

import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubOfferCard } from 'src/business/club/modules/club-offer';
import { useClubOfferState } from 'src/business/club/modules/club-offer/ClubOfferViewPage/useClubOfferState.hook';
import useRouter from 'use-react-router';
import { ClubSettingsCategoryCard } from './componentts';
import { useSettingsCategories } from './useSettingsCategories.hook';

export const ClubSettingsHomePage = () => {
  const { match } = useRouter();

  const redirectTo = useRedirect();

  const categories = useSettingsCategories();

  const clubResume = useClubResume();
  const hasRoleClubSettingsGlobal = useAppSecurityUserHasRole(
    'club-edit-settings-global',
  );
  const clubOfferState = useClubOfferState(clubResume?.offer);

  const { isPaymentDeadlineOver, isPaymentDeadlineSoon } = clubOfferState;
  const showMabadiveOfferWarning =
    hasRoleClubSettingsGlobal &&
    (isPaymentDeadlineOver || isPaymentDeadlineSoon);

  return (
    <AppPageContainer className={'bg-gray-50'}>
      <AppBreadcrumbBar
        color={'#555'}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
        ]}
      />
      <AppPageContentContainer
        paddingBottom={false}
        className="bg-gray-50 app-p-content"
      >
        {showMabadiveOfferWarning && (
          <AppMessageLight type="danger" className="hidden lg:block my-2">
            <ClubOfferCard offer={clubResume?.offer} />
          </AppMessageLight>
        )}
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          {categories.map((category) => (
            <ClubSettingsCategoryCard
              key={category.id}
              as="li"
              className="col-span-1"
              category={category}
            />
          ))}
        </ul>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
