import {
  ProStatsChartConfig,
  ProStatsChartConfigDefOptional,
  ProStatsChartId,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { DashboardFetchCriteriaPeriod } from '../model';

export const proStatsApiChartsConfigBuilder = {
  buildChartsConfig,
  setChartsPeriod,
};

function buildChartsConfig({
  period,
  chartsIds = [],
  charts: chartsDefInput = [],
}: {
  period: DashboardFetchCriteriaPeriod;
  chartsIds?: ProStatsChartId[];
  charts?: ProStatsChartConfigDefOptional[];
}): ProStatsChartConfig {
  // group all graphql queries into one fetch

  const chartsDef: ProStatsChartConfigDefOptional[] = chartsIds
    .map((chartId) => {
      const x: ProStatsChartConfigDefOptional = {
        chartId,
        criteria: {},
      };
      return x;
    })
    .concat(chartsDefInput);

  return setChartsPeriod(chartsDef, period);
}
function setChartsPeriod(
  chartsDef: ProStatsChartConfigDefOptional[],
  period: DashboardFetchCriteriaPeriod,
) {
  const durationInDays =
    (period.endDate.getTime() - period.beginDate.getTime()) / 1000 / 3600 / 24;

  const stepInterval =
    durationInDays > 365 / 2
      ? '1 month'
      : durationInDays >= 2 * 7 * 4 - 1
      ? '1 week'
      : '1 day';

  // si la période totale est < 7 jours, on prend l'évolution sur 1 semaine
  const minDatePeriodEvolution =
    durationInDays < 7
      ? dateService.add(period.endDate, -7, 'day')
      : period.beginDate;

  const charts: ProStatsChartConfig = chartsDef.map((chartDef) => {
    const { chartId } = chartDef;
    switch (chartId) {
      case 'participantsOverPeriod':
      case 'bookingsOverPeriod':
      case 'paymentsOverPeriod':
      case 'purchasesTrainingsOverPeriod':
      case 'purchasesExplosOverPeriod':
      case 'purchasesFirstDivesOverPeriod':
      case 'trainingParticipantsOverPeriod':
      case 'exploParticipantsOverPeriod':
      case 'firstDiveParticipantsOverPeriod':
      case 'bookingsCleanAnalysisOverPeriod':
      case 'bookingsCleanPeriodOverPeriod':
        return {
          ...chartDef,
          chartId,
          criteria: {
            ...chartDef.criteria,
            minDate: period.beginDate,
            maxDate: period.endDate,
          },
        };
      case 'participantsByPeriod':
      case 'bookingsByPeriod':
      case 'paymentsByPeriod':
        return {
          ...chartDef,
          chartId,
          criteria: {
            ...chartDef.criteria,
            minDate: minDatePeriodEvolution,
            maxDate: period.endDate,
            stepInterval,
          },
        };
      default:
        throw new Error(`Unsupported chartId "${chartId}"`);
    }
  });

  return charts;
}
