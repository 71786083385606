import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { clubSettingsOtherUrlBuilder } from 'src/pages/SE-settings/SE-04_other-settings/clubSettingsOtherUrlBuilder.service';
import { clubSettingsCustomerEditorResultBuilder } from './clubSettingsCustomerEditorResultBuilder.service';
import { ClubSettingsCustomerEditorFormModel } from './model';

export function useClubSettingsCustomerFormFieldsEditPageLocalState() {
  const clubResume = useClubResume();

  const diveCenterResume = useDiveCenterResume();
  const clubSettings = clubResume.clubSettings;

  const redirectTo = useRedirect();

  const back = useCallback(
    () => redirectTo(clubSettingsOtherUrlBuilder.formFields.view()),
    [redirectTo],
  );

  const initialFormValue = useMemo(() => {
    const model: ClubSettingsCustomerEditorFormModel = {
      clubSettings: {
        customer: clubSettings.customer,
      },
      diveCenter: {
        publicSettings: diveCenterResume.publicSettings,
      },
    };
    return model;
  }, [clubSettings, diveCenterResume.publicSettings]);

  const form = useForm<ClubSettingsCustomerEditorFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const user = useAppSecurityUser();

  const submitForm = useCallback(() => {
    handleSubmit(
      async (formValue: ClubSettingsCustomerEditorFormModel, event) => {
        const { payload, hasChanges } =
          clubSettingsCustomerEditorResultBuilder.buildResult({
            initialValue: { clubSettings, diveCenter: diveCenterResume },
            formValue,
          });

        if (!hasChanges) {
          // no changes
          back();
          return;
        } else {
          await clubMassiveUpdatorClient.update(payload);
          back();
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [back, clubSettings, diveCenterResume, handleSubmit]);

  return {
    clubResume,
    form,
    submitForm,
    back,
    clubSettings,
  };
}

export type ClubSettingsCustomerFormFieldsEditPageLocalState = ReturnType<
  typeof useClubSettingsCustomerFormFieldsEditPageLocalState
>;
