/* eslint-disable @typescript-eslint/no-unused-vars */

import { ClubSettingsPlanningPeriodConfig } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubSettingsPlanningViewWeekDayHeader } from './ClubSettingsPlanningViewWeekDayHeader';
import { ClubSettingsPlanningViewWeekDiveTour } from './ClubSettingsPlanningViewWeekDiveTour';
import { clubSettingsPlanningViewWeekVMBuilder } from './clubSettingsPlanningViewWeekVMBuilder.service';
import {
  ClubSettingsPlanningViewDayDiveTourModel,
  ClubSettingsPlanningViewDayModel,
} from './model';

export const ClubSettingsPlanningViewWeek = ({
  periodConfig,
  onClickHeader,
  onClickTour,
  className,
}: {
  periodConfig: ClubSettingsPlanningPeriodConfig;
  onClickHeader?: (day: ClubSettingsPlanningViewDayModel) => void;
  onClickTour?: (
    diveTour: ClubSettingsPlanningViewDayDiveTourModel,
    day: ClubSettingsPlanningViewDayModel,
  ) => void;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const weekDays: ClubSettingsPlanningViewDayModel[] = useMemo(
    () =>
      clubSettingsPlanningViewWeekVMBuilder.buildModel({
        diveCenterResume,
        initialPeriodConfig: periodConfig,
      }),
    [diveCenterResume, periodConfig],
  );

  return (
    <div className={className}>
      <div className={'grid grid-cols-7 gap-0.5 sm:gap-2'}>
        {weekDays.map((day, i) => (
          <div className={'flex flex-col'} key={i}>
            <ClubSettingsPlanningViewWeekDayHeader
              className=""
              day={day}
              onClick={onClickHeader ? () => onClickHeader(day) : undefined}
            />

            <div
              className={'flex-col text-app-xxs md:text-xs font-bold uppercase'}
            >
              {day.diveTours.map((diveTour, i) => {
                return (
                  <ClubSettingsPlanningViewWeekDiveTour
                    key={i}
                    className="my-2"
                    day={day}
                    diveTour={diveTour}
                    onClick={
                      onClickHeader
                        ? () => onClickTour(diveTour, day)
                        : undefined
                    }
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
