import {
  BookingProduct,
  ClubPurchasePackage,
  DiveServicePurchaseStatus,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { useClubResume } from 'src/business/club/data/hooks';
import { creditsCountBuilder } from '../../../components/DiverPurchaseCommonEditorDialog';
import {
  DiverBookingPageAggregatedData,
  DiverBookingPageUpdateState,
} from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
import { bookingPagePackageConsumedCounter } from '../services';

export function useAssignProductsToPurchasePackage({
  aggregatedData,
  setUpdateState,
  updateState,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  const clubResume = useClubResume();

  return useCallback(
    async ({
      purchasePackageId,
      bookingProducts,
      operation,
      confirmValidityStatusUpdate,
      countSuccessiveAsSingle,
    }: {
      purchasePackageId: string;
      bookingProducts: BookingProduct[];
      operation: 'assign' | 'unassign';
      confirmValidityStatusUpdate: boolean;
      countSuccessiveAsSingle: boolean;
    }) => {
      let updateStateLocal = updateState;

      const purchasePackage = aggregatedData.purchasePackages.find(
        (x) => x._id === purchasePackageId,
      );

      // const previousConsumedAppCount = aggregatedData.bookingProducts.filter(
      //   (x) => x.purchasePackageId === purchasePackageId,
      // ).length;

      const updatedBookingProductsIds = bookingProducts.map((x) => x._id);

      const consumedAppCount =
        bookingPagePackageConsumedCounter.buildConsumedAppCountOnAssign({
          clubSettings: clubResume.clubSettings,
          purchasePackageId,
          bookingParticipants: aggregatedData.bookingParticipantsFull,
          updatedBookingProductsIds,
          operation,
          countSuccessiveAsSingle,
        });

      for (const bookingProduct of bookingProducts) {
        updateStateLocal = updateBookingProduct({
          purchasePackage,
          bookingProduct,
          updateStateLocal,
          operation,
        });
      }
      updateStateLocal = await updatePurchasePackageCounts({
        purchasePackage,
        updateStateLocal,
        consumedAppCount,
        confirmValidityStatusUpdate,
      });

      setUpdateState(updateStateLocal, {
        action: 'assignProductsToPurchasePackage',
      });
    },
    [
      aggregatedData.bookingParticipantsFull,
      aggregatedData.purchasePackages,
      clubResume.clubSettings,
      setUpdateState,
      updateState,
    ],
  );
}

function updateBookingProduct({
  purchasePackage,
  bookingProduct,
  updateStateLocal,
  operation,
}: {
  purchasePackage: ClubPurchasePackage;
  bookingProduct: BookingProduct;
  updateStateLocal: DiverBookingPageUpdateState;
  operation: 'assign' | 'unassign';
}): DiverBookingPageUpdateState {
  // NOTE: on considère que le produit n'est pas déjà assigné à un autre package!!!

  const updatedBookingProduct: BookingProduct = {
    ...bookingProduct,
    purchasePackageId: operation === 'assign' ? purchasePackage._id : null,
    purchaseStatus: operation === 'assign' ? 'purchased' : 'pending',
  };

  const patchOperations = jsonPatcher.compareObjects(
    bookingProduct,
    updatedBookingProduct,
    {
      replaceDeleteByNullValue: true,
    },
  );

  if (patchOperations.length) {
    const bookingProductsChanges = changeDescriptorManager.updateOne(
      {
        pk: bookingProduct._id,
        patchOperations,
      },
      {
        changeDescriptors: updateStateLocal.bookingProductsChanges,
      },
    );

    updateStateLocal = {
      ...updateStateLocal,
      bookingProductsChanges,
    };
  }
  return updateStateLocal;
}
async function updatePurchasePackageCounts({
  purchasePackage,
  updateStateLocal,
  consumedAppCount,
  confirmValidityStatusUpdate,
}: {
  purchasePackage: ClubPurchasePackage;
  updateStateLocal: DiverBookingPageUpdateState;
  consumedAppCount: number;
  confirmValidityStatusUpdate: boolean;
}): Promise<DiverBookingPageUpdateState> {
  const { consumedTotalCount, creditsRemainingCount, creditsTotalCount } =
    creditsCountBuilder.updateCounts({
      ...purchasePackage.credits,
      consumedAppCount,
      unitQuantityAvailableInPackage: purchasePackage.unitQuantity ?? 1,
    });

  const credits = {
    ...purchasePackage.credits,
    consumedAppCount,
    consumedTotalCount,
    creditsRemainingCount,
    creditsTotalCount,
  };

  const updatedPurchasePackage: ClubPurchasePackage = {
    ...purchasePackage,
    credits,
  };

  let newValidityStatus: DiveServicePurchaseStatus = undefined;
  if (
    purchasePackage.validityStatus === 'active' &&
    credits.creditsRemainingCount <= 0
  ) {
    newValidityStatus = 'completed';
  } else if (
    purchasePackage.validityStatus === 'completed' &&
    credits.creditsRemainingCount > 0
  ) {
    newValidityStatus = 'active';
  }

  if (newValidityStatus) {
    if (confirmValidityStatusUpdate) {
      const confirmed = await confirmDialog.confirmPromise({
        title:
          newValidityStatus === 'completed'
            ? 'Passer la prestation au statut "IMPUTÉ"'
            : 'Passer la prestation au statut "EN COURS"',
        message:
          newValidityStatus === 'completed'
            ? 'Vous avez atteint le quota de cette prestation, voulez-vous la passer au statut "IMPUTÉ"?'
            : 'Vous avez retirer une imputation de cette prestation, voulez-vous la passer au statut "EN COURS"?',
        type: 'noYesInfo',
      });
      if (confirmed) {
        updatedPurchasePackage.validityStatus = newValidityStatus;
      }
    } else {
      updatedPurchasePackage.validityStatus = newValidityStatus;
    }
  }

  const patchOperations = jsonPatcher.compareObjects(
    purchasePackage,
    updatedPurchasePackage,
    {},
  );

  if (patchOperations.length) {
    const purchasePackagesChanges = changeDescriptorManager.updateOne(
      {
        pk: purchasePackage._id,
        patchOperations,
      },
      {
        changeDescriptors: updateStateLocal.purchasePackagesChanges,
      },
    );
    updateStateLocal = {
      ...updateStateLocal,
      purchasePackagesChanges,
    };
  }
  return updateStateLocal;
}
