import {
  ParticipantActionDescription,
  ParticipantBookingStatus,
} from '@mabadive/app-common-model';
import { participantBookingStatusFormatter } from '@mabadive/app-common-services';
import React from 'react';

export function BookingStatusBadge({
  bookingState,
  mode = 'status+user',
  className = '',
}: {
  bookingState: ParticipantActionDescription<ParticipantBookingStatus>;
  mode?: 'status+user' | 'status-only';
  className?: string;
}) {
  return !bookingState || !bookingState.value ? null : (
    <div
      className={`text-gray-400 uppercase
        ${
          bookingState.value === 'cancelled'
            ? 'text-status-error'
            : bookingState.value === 'pending'
            ? 'text-status-warn'
            : 'text-app-blue'
        }
        ${className}
    `}
    >
      <div>
        {participantBookingStatusFormatter.formatStatus(bookingState, {
          mode: 'status-only',
        })}
      </div>
      {mode !== 'status-only' && (
        <div className="text-app-xxs">
          {participantBookingStatusFormatter.formatStatus(bookingState, {
            mode: 'user-only',
          })}
        </div>
      )}
    </div>
  );
}
