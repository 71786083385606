/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import { AppIconsSocialMedia } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

export const ClubSettingsCommunicationEmailAutoViewPanel_General = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const directModeSettings =
    clubSettings.communication?.channels?.email?.directMode;

  const replyToAddress = directModeSettings?.replyToAddress;
  const copyToAddress = directModeSettings?.copyToAddress;

  const directModeEnabled =
    directModeSettings?.directManualEnabled ||
    directModeSettings?.directAutoEnabled;
  return (
    <ClubSettingsSection
      title="Emails direct"
      comment="Configuration des e-mails envoyés par mabadive"
    >
      <div className="grid gap-y-4">
        <AppFeatureBadge
          enabled={directModeSettings?.directManualEnabled}
          label={'Envoi direct des messages manuels ⚡'}
          icon={<AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />}
        />
        <AppFeatureBadge
          enabled={directModeSettings?.directAutoEnabled}
          label={'Envoi automatique de messages (confirmation, rappel) 🚀'}
          icon={<AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />}
        />
        {directModeEnabled && (
          <div className="grid gap-2 text-gray-600">
            <div>
              <h4 className="font-medium uppercase">Expéditeur:</h4>
              <div className="text-app-blue">
                <span className="font-bold">
                  {directModeSettings?.fromName}
                </span>{' '}
                <span className="">{`<${replyToAddress}>`}</span>
              </div>
            </div>
            {copyToAddress?.trim()?.length > 0 && (
              <div className="">
                <h4 className="font-medium uppercase">Copie cachée:</h4>
                <div className="text-app-blue">{copyToAddress}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </ClubSettingsSection>
  );
};
