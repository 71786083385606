/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  ClubSettingsPlanningPeriodConfig,
  ClubSettingsPlanningSession,
  DiveCenterResume,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dataSorter,
  dateService,
} from '@mabadive/app-common-services';
import {
  ClubSettingsPlanningViewDayDiveTourModel,
  ClubSettingsPlanningViewDayDiveTourSessionModel,
  ClubSettingsPlanningViewDayModel,
} from './model';

export const clubSettingsPlanningViewWeekVMBuilder = { buildModel };

function buildModel({
  diveCenterResume,
  initialPeriodConfig,
}: {
  diveCenterResume: DiveCenterResume;
  initialPeriodConfig: ClubSettingsPlanningPeriodConfig;
}): ClubSettingsPlanningViewDayModel[] {
  return (initialPeriodConfig?.weekDays ?? []).map((d) => {
    const dayDate = dateService.setUTCWeekDayIso(new Date(), d.isoWeekDay);

    const diveTours: ClubSettingsPlanningViewDayDiveTourModel[] =
      d.diveTours.map((dt) => {
        const session1: ClubSettingsPlanningViewDayDiveTourSessionModel =
          buildSessionModel({ session: dt.session1, diveCenterResume });

        const session2: ClubSettingsPlanningViewDayDiveTourSessionModel =
          buildSessionModel({ session: dt.session2, diveCenterResume });

        const diveTour: ClubSettingsPlanningViewDayDiveTourModel = {
          reference: dt.reference,
          session1,
          session2,
          isOpen: dt.isOpen,
          type: dt.type,
          theme: dt.theme,
          defaultDiveMode: dt.defaultDiveMode,
          bookingConfigs: dt.bookingConfigs,
        };
        return diveTour;
      });

    const dayModel: ClubSettingsPlanningViewDayModel = {
      ...d,
      dayDate,
      diveTours: dataSorter.sortMultiple(diveTours, {
        getSortAttributes: (x: ClubSettingsPlanningViewDayDiveTourModel) => [
          {
            value: x.session1.time,
          },
          {
            value: x.isOpen,
          },
          {
            value: x.session1.name,
          },
          {
            value: x.reference,
          },
        ],
        asc: true,
      }),
    };
    return dayModel;
  });
}
function buildSessionModel({
  session,
  diveCenterResume,
}: {
  session: ClubSettingsPlanningSession;
  diveCenterResume: DiveCenterResume;
}): ClubSettingsPlanningViewDayDiveTourSessionModel {
  if (!session) {
    return;
  }
  const time = new Date();
  time.setUTCHours(session.dayTime.hours);
  time.setUTCMinutes(session.dayTime.minutes);

  const timeDayPeriod: DiveSessionTimeDayPeriod =
    clubDiveSessionThemeBuilder.buildTimeDayPeriod(time);

  const diveSite = diveCenterResume?.diveSites.find(
    (x) => x._id === session.diveSiteId,
  );
  const boats = diveCenterResume?.boats.filter((x) =>
    (session.boatsIds ?? []).includes(x._id),
  );
  const model: ClubSettingsPlanningViewDayDiveTourSessionModel = {
    name: session.name,
    special: session.special,
    emojiId: session.emojiId,
    time,
    timeDayPeriod,
    diveSite,
    boats,
  };
  return model;
}
