/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { diveSessionTitleBuilder } from 'src/business/club/modules/club-dive-session/services';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppEmojiIcon } from 'src/lib/form/components/AppEmojiPicker';
import { ClubSettingsPlanningViewDayDiveTourModel } from './model';
import { ClubSettingsPlanningViewDayDiveTourSessionModel } from './model/ClubSettingsPlanningViewDayDiveTourSessionModel.type';

export const ClubSettingsPlanningViewWeekDiveTourSessionTime = ({
  diveTour,
  displaySessionIndex,
  onClick,
  className,
}: {
  diveTour: ClubSettingsPlanningViewDayDiveTourModel;
  displaySessionIndex?: { index: number; total: number };
  onClick?: () => void;
  className?: string;
}) => {
  const session: ClubSettingsPlanningViewDayDiveTourSessionModel =
    diveTour.session1;
  const mediaSize = useMediaSizeTailwind();
  const sessionTitle = useMemo(
    () =>
      diveSessionTitleBuilder.build({
        name: diveTour.session1.name,
        theme: diveTour.theme,
        type: diveTour.type,
        special: diveTour.session1.special,
      }),
    [
      diveTour.session1.name,
      diveTour.session1.special,
      diveTour.theme,
      diveTour.type,
    ],
  );
  return (
    <div
      className={`font-bold bg-opacity-80 group-hover:bg-opacity-100 flex justify-center 
        bg-day-period-${session?.timeDayPeriod}-dark
        text-white relative ${className ?? ''}`}
      onClick={onClick}
    >
      <div className={`flex items-center ${className ?? ''}`}>
        <div className="px-5 py-0.5">
          {dateService.formatUTC(session.time, 'HH:mm')}
        </div>
      </div>
      {displaySessionIndex && (
        <div className="absolute top-1 right-1 text-app-xxs text-gray-800">
          {displaySessionIndex.index}/{displaySessionIndex.total}
        </div>
      )}
      {session?.emojiId && !sessionTitle && (
        <div className="hidden xs:block absolute top-0 left-0 sm:left-0.5">
          <AppEmojiIcon
            id={session.emojiId}
            size={mediaSize === 'xs' ? 14 : 18}
          />
        </div>
      )}
    </div>
  );
};
