/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubStaffMemberRole,
  DiveSessionStaffMembersTableContext,
  DiveSessionStaffMemberTableModel,
  DiveSessionStaffMemberTableModelStaffMember,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import { arrayBuilder } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { OnUpdateSessionStaffConfigFn } from '../../../DiveSessionDialogTab2StaffMembers/useOnUpdateSessionStaffConfig.hook';
import { DiveSessionStaffMembersCardStaff } from './DiveSessionStaffMembersCardStaff';

export const DiveSessionStaffMembersCard: FC<{
  diveSessionReference: string;
  staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
  isMultiSessionConfigForStaff: boolean;
  staffFirstNameBefore: boolean;
  context: DiveSessionStaffMembersTableContext;
  staffMembersTableModel: DiveSessionStaffMemberTableModel;
  onClickEdit?: (
    staffMemberFull: DiveSessionStaffMemberTableModelStaffMember,
  ) => any;
  onUpdateSessionStaffConfig?: OnUpdateSessionStaffConfigFn;
  date: Date;
  selectedMemberId?: string;
  toogleSelectMember?: (memberId: string) => void;
  className?: string;
}> = ({
  diveSessionReference,
  staffMembersResumeSessions,
  isMultiSessionConfigForStaff,
  staffFirstNameBefore,
  context,
  staffMembersTableModel,
  onClickEdit,
  onUpdateSessionStaffConfig,
  date,
  selectedMemberId,
  toogleSelectMember,
  className = '',
}) => {
  const { groupsWithoutMonitors, rowsCount, staffMembersFull } =
    staffMembersTableModel;

  const availableRoles: ClubStaffMemberRole[] = useMemo(
    () =>
      arrayBuilder.filterDuplicated(
        staffMembersFull.reduce(
          (acc, x) => acc.concat(x.staffMember.profile.roles),
          [] as ClubStaffMemberRole[],
        ),
      ),
    [staffMembersFull],
  );

  return (
    <div className={clsx('grid gap-x-4 gap-y-2 items-stretch', className)}>
      {staffMembersFull.map((staffMemberFull, i) => (
        <DiveSessionStaffMembersCardStaff
          key={i}
          diveSessionReference={diveSessionReference}
          date={date}
          staffMembersResumeSessions={staffMembersResumeSessions}
          onClickEdit={
            onClickEdit ? () => onClickEdit(staffMemberFull) : undefined
          }
          onUpdateSessionStaffConfig={
            onUpdateSessionStaffConfig ? onUpdateSessionStaffConfig : undefined
          }
          staffMemberFull={staffMemberFull}
          isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
          context={context}
          availableRoles={availableRoles}
          staffFirstNameBefore={staffFirstNameBefore}
          isSelected={selectedMemberId === staffMemberFull.staffMember._id}
          toogleSelect={() =>
            toogleSelectMember(staffMemberFull.staffMember._id)
          }
        />
      ))}
    </div>
  );
};
