/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';
import { CreateMessageToCustomersFormStep4_RecipientsTableRow } from './CreateMessageToCustomersFormStep4_RecipientsTableRow';

export const CreateMessageToCustomersFormStep4_RecipientsTable = ({
  localState,
  className = '',
}: {
  localState: CreateMessageToCustomersLocalState;
  className?: string;
}) => {
  const {
    data: { form, result },
  } = localState;

  const {
    aggregated: { validSelectedRecipients },
  } = result;

  return (
    <div className={`w-full grid sm:grid-cols-2 gap-4 ${className}`}>
      {validSelectedRecipients.map((recipient) => (
        <CreateMessageToCustomersFormStep4_RecipientsTableRow
          key={recipient?.formRecipient.diver._id}
          localState={localState}
          recipient={recipient}
        />
      ))}
    </div>
  );
};
