import { DiveServiceOrganizationReference } from '@mabadive/app-common-model';
import {
  GenerateOffersDivePlanOptions,
  GenerateOffersOptions,
  GenerateOffersTrainingOptions,
} from '../model';

export const getOffersTrainingInternationalGenericGeneratorOptions = ({
  defaultOrganizationReference,
}: {
  defaultOrganizationReference?: DiveServiceOrganizationReference;
}): GenerateOffersOptions => {
  const divePlan: GenerateOffersDivePlanOptions = {
    snorkelings: {
      enabled: false,
      offers: [],
    },
    firstDives: {
      enabled: false,
      offers: [],
    },
    scubaDives: {
      enabled: false,
      offers: [],
    },
  };
  const training: GenerateOffersTrainingOptions = {
    enabled: true,
    offers: [
      {
        trainingAttributes: {
          diveTrainingReference: 'FOW', // Open Water
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 6,
        },
        price: 300,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FSD', // Scuba Diver
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 3,
        },
        price: 150,
      },
      // {
      //   trainingAttributes: {
      //     diveTrainingReference: 'FRO', // Referral Open Water
      //   },
      //   productAttributes: {
      //     defaultOrganizationReference,
      //   },
      //   diveAttributes: {
      //     divesCount: 4,
      //   },
      //   price: 200,
      // },
      {
        trainingAttributes: {
          diveTrainingReference: 'FDEEP', // Deep Diver
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 5,
        },
        price: 250,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FRSC', // Rescue Diver
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 6,
        },
        price: 300,
      },
    ],
  };
  const options: GenerateOffersOptions = {
    divePlan,
    training,
  };
  return options;
};
