/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { clubDiverSelectorDialogAggregatedDataBuilder } from './components';
import {
  ClubDiverSelectorDialogAggregatedData,
  ClubDiverSelectorDialogInputState,
  ClubDiverSelectorDialogUpdateState,
} from './model';

export function useClubDiverSelectorDialogGlobalState({
  inputState,
}: {
  inputState: ClubDiverSelectorDialogInputState;
}) {
  const [updateState, setUpdateStateInner] =
    useState<ClubDiverSelectorDialogUpdateState>({
      hasChanges: false,
      bookingMembersChanges: [],
      diversChanges: [],
      newBookingMembers: [],
    });

  const setUpdateState = useCallback(
    (state: ClubDiverSelectorDialogUpdateState) => {
      const updatedState: ClubDiverSelectorDialogUpdateState = {
        ...state,
        hasChanges: true,
      };
      setUpdateStateInner(updatedState);
    },
    [],
  );

  const clubResume = useClubResume();

  const clubReference = clubResume?.reference;

  // loaded bookings + changes applied
  const aggregatedData: ClubDiverSelectorDialogAggregatedData = useMemo(
    () =>
      clubDiverSelectorDialogAggregatedDataBuilder.aggregate({
        inputState,
        updateState,
        clubResume,
      }),
    [clubResume, inputState, updateState],
  );

  return {
    aggregatedData,
    updateState,
    setUpdateStateInner,
    setUpdateState,
    clubReference,
  };
}

export type ClubDiverSelectorDialogGlobalState = ReturnType<
  typeof useClubDiverSelectorDialogGlobalState
>;
