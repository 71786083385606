import { BookingAgency } from '@mabadive/app-common-model';

export const agencyFormResultBuilder = { buildResult };

function buildResult(formValue: BookingAgency): Partial<BookingAgency> {
  const value: Partial<BookingAgency> = {
    ...formValue,
  };
  return value;
}
