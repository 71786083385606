/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  ClubParticipant,
} from '@mabadive/app-common-model';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import {
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResult,
  DiverBookingUpdateProductParticipant,
} from '../../../../../models';
import { diverBookingParticipantCreator } from '../../../../../services/02.update-state/entity-creators';
import { bookingMemberChangesBuilder } from './booking-member/bookingMemberChangesBuilder.service';
import { clubDiverChangesBuilder } from './diver';
import { participantFormParser } from './participant';

export const bookingParticipantEditorDialogChangesBuilderCreateParticipant = {
  buildChangesResult,
};

function buildChangesResult({
  participantData,
  initialFormValue,
  formValue,
  clubReference,
  diveCenterId,
}: {
  participantData?: BookingParticipantEditorParticipant;
  initialFormValue: BookingParticipantEditorFormModel;
  formValue: BookingParticipantEditorFormModel;
  clubReference: string;
  diveCenterId: string;
}): {
  hasChanges: boolean;
  result?: BookingParticipantEditorResult;
} {
  const diveSessionReference = participantData?.diveSession?.reference;
  const bookingSessionId = participantData?.bookingSessionId;
  const bookingId = participantData?.bookingId;
  const diverId = participantData?.diver?._id;
  const bookingMemberId = participantData?.bookingMember?._id;
  const sessionsCount = participantData?.diveSession?.sessionsCount;

  const payload = clubMassiveUpdatorClient.createEmptyPayload({
    logContext: 'create participant',
    bookingIdsToClean: [bookingId],
  });
  const result: BookingParticipantEditorResult = {
    bookingId: bookingId,
    initialDiver: participantData.diver,
    changes: payload,
    aggregated: {},
    bookingMemberId: participantData?.bookingMember?._id,
  };

  const initialParticipant: ClubParticipant = participantData?.clubParticipant;

  const {
    clubParticipant: clubParticipantFromForm,
    bookingSessionParticipant: bookingSessionParticipantFromForm,
  } = participantFormParser.parseFormValueClubParticipant({
    formValue,
    existingParticipant: participantData?.clubParticipant,
    sessionsCount,
  });

  const finalParticipant: ClubParticipant = {
    ...initialParticipant,
    ...clubParticipantFromForm,
  };

  const { finalDiver } = clubDiverChangesBuilder.buildClubDiverChanges({
    formValue,
    participantData,
    result,
  });

  const createdParticipant: DiverBookingUpdateProductParticipant =
    diverBookingParticipantCreator.createParticipant({
      bookingSessionId,
      bookingId,
      clubReference,
      diveCenterId,
      diveSessionReference,
      bookingMemberId,
      diverId,
      participant: finalParticipant,
      sessionsCount,
    });
  const { bookingProduct, bookingSessionParticipant, clubParticipant } =
    createdParticipant;
  result.changes.newBookingProducts.push(bookingProduct);
  result.changes.newBookingSessionParticipants.push(bookingSessionParticipant);
  result.changes.newClubParticipants.push(clubParticipant);
  result.aggregated.finalBookingProduct = bookingProduct;
  result.aggregated.finalBookingSessionParticipant = bookingSessionParticipant;
  result.aggregated.finalClubParticipant = clubParticipant;

  bookingMemberChangesBuilder.buildBookingMemberChanges({
    finalDiver,
    participantData,
    result,
    formValue,
    initialFormValue,
  });
  return { hasChanges: true, result };
}
