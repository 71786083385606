/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSessionResumeGroup } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { FC } from 'react';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { AppFormControlV2 } from 'src/lib/form/components/AppFormControl';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { DiveSessionEditorDialogLocalState } from '../../../../useDiveSessionEditorDialogLocalState.hook';
import { DiveSessionDialogTab3EditableGroupLocalState } from './useDiveSessionDialogTab3EditableGroupLocalState';

export const DiveSessionDialogTab3EditableGroupConfigBar_Tour1: FC<{
  parentLocalState: DiveSessionEditorDialogLocalState;
  localState: DiveSessionDialogTab3EditableGroupLocalState;
  group: DiveSessionResumeGroup;
  className?: string;
}> = ({ localState, parentLocalState, group, className }) => {
  const {
    aggregatedData: { diveSession },
    isMultiSessionConfigForStaff,
  } = parentLocalState;

  const { data, actions } = localState;

  const hasAnyInstructor =
    !!group.diveTourGroupSession1?.instructor ||
    !!group.diveTourGroupSession2?.instructor;

  return (
    <div
      className={clsx(
        'w-full flex',
        isMultiSessionConfigForStaff ? 'flex-col gap-y-2' : 'gap-x-2',
        className,
      )}
    >
      {data.displayAutonomousField && !hasAnyInstructor && (
        <>
          <AppFormControlV2
            className={`${isMultiSessionConfigForStaff ? 'w-full' : ''}`}
            label={`Autonomes ${isMultiSessionConfigForStaff ? 'tour 1' : ''}`}
            renderComponent={() => (
              <AppInputBooleanSwitch
                value={group.diveTourGroupSession1?.isAutonomous}
                onChange={(value) => {
                  actions.onUpdateGroupIsAutonomous({
                    group,
                    isAutonomous: value,
                    sessionNumber: 1,
                  });
                }}
              />
            )}
          />
        </>
      )}

      <div
        className={clsx(
          'w-full grid gap-x-2 gap-y-1',
          isMultiSessionConfigForStaff ? '' : 'grid-cols-2',
        )}
      >
        {!group.diveTourGroupSession1?.isAutonomous && (
          <div className="basis-0 flex-grow">
            <AppFormControlV2
              className="w-full"
              label={`Moniteur de plongée ${
                isMultiSessionConfigForStaff ? 'tour 1' : ''
              }`}
              renderComponent={() => (
                <AppSingleSelect2HeadlessUI
                  disableClearButton={false}
                  theme={'material-ui'}
                  className="text-app-xxs"
                  value={group.diveTourGroupSession1?.instructor?.staffId}
                  options={data.divingInstructorsStaffOptions}
                  onChange={(value) => {
                    actions.onUpdateGroupInstructor_GP({
                      group,
                      instructorStaffId: value,
                      sessionNumber: 1,
                    });
                  }}
                />
              )}
            />
          </div>
        )}
        {data.groupGuidesOptions?.length !== 0 && (
          <>
            {!group.diveTourGroupSession1?.isAutonomous && (
              <div className="basis-0 flex-grow">
                <AppFormControlV2
                  className="w-full"
                  label={`Guide de palanquée ${
                    isMultiSessionConfigForStaff ? 'tour 1' : ''
                  }`}
                  renderComponent={() => (
                    <AppSingleSelect2HeadlessUI
                      disableClearButton={false}
                      theme={'material-ui'}
                      className="text-app-xxs"
                      value={
                        group.diveTourGroupSession1?.diveGuide?.participantId
                      }
                      options={data.groupGuidesOptions}
                      onChange={(value) => {
                        actions.onUpdateGroupGuide({
                          group,
                          guideDiverId: value,
                          sessionNumber: 1,
                        });
                      }}
                    />
                  )}
                />
              </div>
            )}
          </>
        )}
        {(group.diveTourGroupSession1?.divingInstructors ?? [])?.map(
          (divingInstructor) => (
            <div className="basis-0 flex-grow">
              <AppFormControlV2
                className="w-full"
                label={'Autre moniteur'}
                renderComponent={() => (
                  <AppSingleSelect2HeadlessUI
                    disableClearButton={false}
                    theme={'material-ui'}
                    className="text-app-xxs"
                    value={divingInstructor?.staffId}
                    options={data.divingInstructorsStaffOptions}
                    onChange={(value) => {
                      actions.onUpdateGroupInstructor_ExtraDiver({
                        group,
                        instructorStaffIdToAdd: value,
                        instructorStaffIdToRemove: divingInstructor?.staffId,
                        sessionNumber: 1,
                      });
                    }}
                  />
                )}
              />
            </div>
          ),
        )}
      </div>
    </div>
  );
};
