import {
  ClubSettingsPlanningDiveTour,
  ClubSettingsPlanningSession,
} from '@mabadive/app-common-model';
import { referenceBuilder } from '@mabadive/app-common-services';

export const virtualDiveTourReferenceBuilder = {
  generateUniqueDiveTourReference,
};

function generateUniqueDiveTourReference({
  diveTour,
  diveTours,
}: {
  diveTour: ClubSettingsPlanningDiveTour;
  diveTours: ClubSettingsPlanningDiveTour[];
}) {
  const diveTourReference = buildDiveTourReference(diveTour);
  let i = 0;
  let diveTourWithSameRef: ClubSettingsPlanningDiveTour;
  do {
    i++;
    const reference = `${diveTourReference}-${i}`;
    diveTourWithSameRef = diveTours.find((x) => x.reference === reference);
  } while (!!diveTourWithSameRef);
  return `${diveTourReference}-${i}`;
}

function buildDiveTourReference(
  diveTour: ClubSettingsPlanningDiveTour,
): string {
  const baseRef = `${buildDiveTourSessionReference(
    diveTour.session1,
  )}-${buildDiveTourSessionReference(diveTour.session2)}`;

  return referenceBuilder.buildCleanReference(baseRef);
}

function buildDiveTourSessionReference(
  session: ClubSettingsPlanningSession,
): string {
  return session
    ? `${session.dayTime.hours}-${session.dayTime.minutes}-${(
        session.boatsIds ?? []
      ).join(';')}-${session.name ?? ''}`
    : '';
}
