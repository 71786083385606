import { WeekPlanning } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { DiveSessionResumeLoadBounds } from 'src/business/_core/data/store-repository/dive-sessions/model';

export function useWeekPlanningBounds({
  weekPlanning,
}: {
  weekPlanning: WeekPlanning;
}): DiveSessionResumeLoadBounds {
  const bounds = useMemo(() => {
    if (weekPlanning?.daysPlanning) {
      const minDateInclusive = weekPlanning.daysPlanning[0].weekDate.date;
      const maxDateExclusive = dateService.add(
        weekPlanning.daysPlanning[weekPlanning.daysPlanning.length - 1].weekDate
          .date,
        1,
        'day',
      );
      return {
        minDateInclusive: minDateInclusive,
        maxDateExclusive: maxDateExclusive,
      };
    }
    return {
      minDateInclusive: null,
      maxDateExclusive: null,
    };
  }, [weekPlanning.daysPlanning]);

  return bounds;
}
