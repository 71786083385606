/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import {
  ClubSettingsSection,
  ClubSettingsViewActivableFeature,
  ClubSettingsViewAttribute,
} from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';

export const ClubSettingsServicesTrainingViewPanelTrainingOrgs = ({
  localState,
}: {
  localState: ClubSettingsServicesPageLocalState;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();

  const { state, dialogs } = localState;

  const switchToEditMode = () => dialogs.clubSettingsServices.open('training');

  return (
    <ClubSettingsSection
      title={'Organismes'}
      comment="Définissez les types de formations que vous proposez"
      onClick={state.editEnabled ? switchToEditMode : undefined}
      actions={
        <>
          {state.editEnabled && (
            <div className="">
              <AppButton
                color={'primary-outline-light'}
                size="normal"
                icon={AppIcons.action?.edit}
                onClick={() => {
                  switchToEditMode();
                }}
              >
                Modifier
              </AppButton>
            </div>
          )}
        </>
      }
    >
      <div className="grid  gap-x-4 gap-y-2">
        <ClubSettingsViewActivableFeature
          isActive={clubSettings.services.french.enabled}
        >
          {/* <ClubSettingsSection
          title={`Cursus français${
            clubSettings.services.french.enabled &&
            clubSettings.services.defaultOrgGroup === 'french'
              ? ' (default)'
              : ''
          }`}
        > */}
          <ClubSettingsViewAttribute label={'Cursus français'}>
            <div className="uppercase flex gap-2 flex-wrap">
              {clubSettings.services.french.organizationReferences.map(
                (organizationReference, i) => (
                  <span key={organizationReference}>
                    <span
                      className={
                        organizationReference ===
                        clubSettings.services.french
                          .defaultOrganizationReference
                          ? 'font-bold'
                          : ''
                      }
                    >
                      {organizationReference}
                    </span>
                    {i !==
                      clubSettings.services.french.organizationReferences
                        .length -
                        1 && <>, </>}
                  </span>
                ),
              )}
            </div>
          </ClubSettingsViewAttribute>
          {/* </ClubSettingsSection> */}
        </ClubSettingsViewActivableFeature>
        <ClubSettingsViewActivableFeature
          isActive={clubSettings.services.international.enabled}
        >
          {/* <ClubSettingsSection
          title={`Cursus international${
            clubSettings.services.international.enabled &&
            clubSettings.services.defaultOrgGroup === 'international'
              ? ' (default)'
              : ''
          }`}
        > */}
          <ClubSettingsViewAttribute label={'Cursus international'}>
            <div className="uppercase flex gap-2 flex-wrap">
              {clubSettings.services.international.organizationReferences.map(
                (organizationReference, i) => (
                  <span key={organizationReference}>
                    <span
                      className={
                        organizationReference ===
                        clubSettings.services.international
                          .defaultOrganizationReference
                          ? 'font-bold'
                          : ''
                      }
                    >
                      {organizationReference}
                    </span>
                    {i !==
                      clubSettings.services.international.organizationReferences
                        .length -
                        1 && <>, </>}
                  </span>
                ),
              )}
            </div>
          </ClubSettingsViewAttribute>
          {/* </ClubSettingsSection> */}
        </ClubSettingsViewActivableFeature>
        <AppFeatureBadge
          enabled={clubSettings.ui?.planning?.displayMainLevelForTraining}
          label="Afficher le niveau du plongeur en formation sur le planning"
        />
      </div>
    </ClubSettingsSection>
  );
};
