import { DiveCenterResume } from '@mabadive/app-common-model';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { graphqlClient } from 'src/_common-browser';
import { GraphqlClientQueryType } from 'src/_common-browser/graphql-client/apollo';
import { buildDiveCenterResumeOneGraphqlQuery } from './query-builder';

export const diveCenterResumeGraphqlFetcher = {
  findOne,
};

function findOne({
  diveCenterId,
  type = 'query',
}: {
  diveCenterId: string;
  type?: GraphqlClientQueryType;
}): Observable<DiveCenterResume> {
  appLogger.warn('[diveCenterResumeGraphqlFetcher.findOne] start');
  const queryDescription = buildDiveCenterResumeOneGraphqlQuery({
    diveCenterId,
  });

  return graphqlClient.query
    .runOne<DiveCenterResume>(queryDescription, { type })
    .pipe(
      tap((x) => {
        appLogger.warn('[diveCenterResumeGraphqlFetcher.findOne] done', x);
      }),
      catchError((err) => {
        appLogger.warn(
          '[diveCenterResumeGraphqlFetcher.findOne] error fetching data',
          err,
        );
        return throwError(err);
      }),
    );
}
