/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import {
  useClubResume,
  useClubSettingsMessageChannels,
} from 'src/business/club/data/hooks';

import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { AppMessagePreview, useAppMessageParams } from 'src/pages/_components';
import {
  ClubSettingsCommunicationDialogInitialState,
  ClubSettingsCommunicationFormModel,
} from '../../../_model';

export const ClubSettingsCommunicationMessageTemplateSignatureForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsCommunicationFormModel>;
  initialState: ClubSettingsCommunicationDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const [emailSignatureContent, smsSignatureContent, whatsAppSignatureContent] =
    useWatch({
      control,
      name: [
        'clubSettings.communication.templates.emailSignature.content',
        'clubSettings.communication.templates.smsSignature.content',
        'clubSettings.communication.templates.whatsAppSignature.content',
      ],
    });

  const paramsToReplace = useAppMessageParams({
    context: 'none',
    recipientType: 'personal',
  });

  const msg = useClubSettingsMessageChannels();

  return (
    <div className="grid gap-4 md:gap-6">
      {' '}
      <ClubSettingsSection
        title="Signature des messages"
        comment="Ajouter automatiquement la signature de votre choix à la fin des messages"
      >
        <div className="grid gap-x-4 gap-y-2">
          <AppFormControlRHF_Deprecated
            control={control}
            label="Conserver la signature pour les e-mails envoyés manuellement"
            name={
              'clubSettings.communication.templates.emailSignature.enabledIfManual'
            }
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
          {msg?.email?.channelEnabled && (
            <div className="grid gap-4 lg:grid-cols-2">
              <AppFormControlRHF_Deprecated
                className="w-full"
                label="Signature E-MAIL"
                control={control}
                name={
                  'clubSettings.communication.templates.emailSignature.content'
                }
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    fullWidth
                    multiline
                    rowsMin={3}
                    rowsMax={15}
                    rows={2}
                  />
                )}
              />
              {emailSignatureContent && (
                <AppMessagePreview
                  target="email"
                  body={emailSignatureContent}
                  paramsToReplace={paramsToReplace}
                />
              )}
            </div>
          )}
          {msg?.sms?.channelEnabled && (
            <div className="grid gap-4 lg:grid-cols-2">
              <AppFormControlRHF_Deprecated
                className="w-full"
                label="Signature SMS"
                control={control}
                name={
                  'clubSettings.communication.templates.smsSignature.content'
                }
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    fullWidth
                    multiline
                    rowsMin={3}
                    rowsMax={15}
                    rows={2}
                  />
                )}
              />
              {smsSignatureContent && (
                <AppMessagePreview
                  target="sms"
                  body={smsSignatureContent}
                  paramsToReplace={paramsToReplace}
                />
              )}
            </div>
          )}
          {msg?.whatsApp?.channelEnabled && (
            <div className="grid gap-4 lg:grid-cols-2">
              <AppFormControlRHF_Deprecated
                className="w-full"
                label="Signature WHATSAPP"
                control={control}
                name={
                  'clubSettings.communication.templates.whatsAppSignature.content'
                }
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    fullWidth
                    multiline
                    rowsMin={3}
                    rowsMax={15}
                    rows={2}
                  />
                )}
              />
              {whatsAppSignatureContent && (
                <AppMessagePreview
                  target="whatsapp"
                  body={whatsAppSignatureContent}
                  paramsToReplace={paramsToReplace}
                />
              )}
            </div>
          )}
        </div>
      </ClubSettingsSection>
    </div>
  );
};
