import React from 'react';

export const AppRichTextEditorActionsBarButton = ({
  isActive,
  icon,
  onClick,
  className = '',
  title,
}: {
  isActive: boolean;
  icon: (props: React.ComponentProps<'svg'>) => React.JSX.Element;
  onClick: () => any;
  title?: string;
  className?: string;
}) => {
  const Icon = icon;

  return (
    <button
      title={title}
      className={`cursor-pointer bg-gray-100 hover:bg-gray-200 relative inline-flex items-center border shadow-sm font-medium rounded 
        ${isActive ? 'font-bold text-gray-800' : 'font-normal text-gray-400'}
        ${className}`}
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
    >
      <Icon className="w-6 h-6" />
    </button>
  );
};
