import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppIconsUI } from '../../icons';
import { AppBreadcrumbItem } from './model';

export const AppBreadcrumbBarItem = ({
  item,
  color,
  isFirst,
  isActive,
  firstItemClassName = 'ml-4',
}: {
  item: AppBreadcrumbItem;
  color?: string;
  isFirst: boolean;
  isActive: boolean;
  firstItemClassName?: string;
}) => {
  const labelElement: React.ElementType = item.url ? Link : 'a';

  return (
    <li className="flex items-center py-2 text-white">
      {!isFirst && (
        <AppIconsUI.navRight
          className={clsx('h-5 w-5 flex-shrink-0 opacity-80')}
          aria-hidden="true"
        />
      )}
      {React.createElement(
        labelElement,
        {
          className: clsx(
            'text-sm md:text-base xl:text-lg flex items-center gap-1 sm:gap-2',
            isActive && 'font-medium',
            isFirst ? firstItemClassName : 'ml-4',
            item.url && 'opacity-80 hover:opacity-100',
          ),
          to: item.url,

          // style: { color },
        },
        <>
          {item.icon && (
            <item.icon
              className="inline-block h-5 w-5 md:h-7 md:w-7 xl:h-8 xl:w-8 flex-shrink-0"
              // style={{ color }}
              aria-hidden="true"
            />
          )}
          <span className="hidden xs:inline">{item.label}</span>
          <span className="sr-only">{item.label}</span>
        </>,
      )}
    </li>
  );
};
