import {
  ClipboardDocumentCheckIcon,
  CurrencyEuroIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/solid';
import { BsFillPeopleFill } from 'react-icons/bs';
import { ReactComponent as deposit } from 'src/assets/icons/entities/deposit.svg';
import { ReactComponent as creditNote } from 'src/assets/icons/entities/refund.svg';

import { ShoppingBagIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';

export const EntitiesIcons = {
  participants: BsFillPeopleFill,
  booking: ClipboardDocumentCheckIcon,
  diver: UserIcon,
  divers: UsersIcon,
  payment: CurrencyEuroIcon,
  deposit,
  creditNote,
  order: ShoppingCartIcon,
  onlineBooking: ShoppingBagIcon,
};
