/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubResumeAdmin } from '@mabadive/app-common-model';
import React from 'react';
import { AppDateTimeLabel } from 'src/business/club/modules/_common/ui';
import { AdminCompaniesListPageLocalState } from '../useAdminCompaniesListPageLocalState';

export const ClubResumeAdminCardActivityDates = ({
  localState,
  clubResumeAdmin: club,
}: {
  clubResumeAdmin: ClubResumeAdmin;
  localState: AdminCompaniesListPageLocalState;
}) => {
  const { filters } = localState;
  const { showDetails } = filters;

  return (
    <div className={'text-left'}>
      {showDetails && (
        <>
          <div className="text-gray-600 text-xs">
            <span className="text-gray-600 font-bold">LOGIN: </span>
            <AppDateTimeLabel
              date={club.clubLogs?.lastLoginDate}
              format="date-time"
              timezone={'local'}
              steps={[
                {
                  hours: 1,
                  className: 'text-green-600',
                },
                {
                  hours: 48,
                  className: 'text-gray-600',
                },
                {
                  className: 'text-red-600',
                },
              ]}
              hoursSteps={[1, 48]}
            />
            {club.clubLogs?.lastLoginAppVersion &&
              ` (${club.clubLogs.lastLoginAppVersion})`}
          </div>
          <div className="text-gray-600 text-xs">
            <span className="text-gray-600 font-bold">UPDATE: </span>
            <AppDateTimeLabel
              date={club.clubLogs?.lastUpdate}
              format="date-time"
              timezone={'local'}
              steps={[
                {
                  hours: 1,
                  className: 'text-green-600',
                },
                {
                  hours: 48,
                  className: 'text-gray-600',
                },
                {
                  className: 'text-red-600',
                },
              ]}
            />
            {club.clubLogs?.lastUpdateAppVersion &&
              ` (${club.clubLogs.lastUpdateAppVersion})`}
          </div>
          <div className="text-gray-600 text-xs">
            <span className="text-gray-600 font-bold">CLIENT: </span>
            <AppDateTimeLabel
              date={club?.lastAppUrlAlias?._updatedAt}
              format="date-time"
              timezone={'local'}
              steps={[
                {
                  hours: 1,
                  className: 'text-green-600',
                },
                {
                  hours: 2,
                  className: 'text-orange-600',
                },
                {
                  hours: 3,
                  className: 'text-red-600',
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
};
