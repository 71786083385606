import {
  ClubPurchasePackagePaymentStatus,
  PURCHASE_PAYMENT_STATUSES,
} from '@mabadive/app-common-model';
import { clubPurchasePackagePaymentStatusFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from '../../../business/club/modules/_common/form';

export function useClubPurchasePackagePaymentStatusOptions(): ValueLabel<ClubPurchasePackagePaymentStatus>[] {
  return useMemo(
    () =>
      PURCHASE_PAYMENT_STATUSES.map((value) => {
        const option: ValueLabel<ClubPurchasePackagePaymentStatus> = {
          label: clubPurchasePackagePaymentStatusFormatter
            .formatStatus(value, undefined)
            .toUpperCase(),
          value,
        };
        return option;
      }),
    [],
  );
}

export function useClubPurchasePackagePaymentStatusOptionsWithPending(): ValueLabel<
  ClubPurchasePackagePaymentStatus | 'pending'
>[] {
  const options: ValueLabel<ClubPurchasePackagePaymentStatus | 'pending'>[] =
    useClubPurchasePackagePaymentStatusOptions();
  return useMemo(
    () =>
      options.concat({
        label: clubPurchasePackagePaymentStatusFormatter
          .formatStatus('done', true)
          .toUpperCase(),
        value: 'pending',
      }),
    [options],
  );
}
