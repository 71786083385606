import { AppUserTrackingClient } from '@mabadive/app-common-model';
import { merge, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { appConfigurationGraphqlFetcher } from 'src/business/_core/data/graphql';
import { uiStore } from 'src/business/_core/store';
import { appWebConfig } from '../config';
import { appLogger } from '../logger';
import { appDataLoaderManager } from './appDataLoaderManager.service';
import { refreshTokenManager } from './refreshTokenManager.service';

const logger = appLogger.child({
  module: 'bootstrap',
  filename: 'clubDataBootstrap.service',
});

export const dataBootstrap = {
  init,
};

function init({ trackingClient }: { trackingClient: AppUserTrackingClient }) {
  try {
    return uiStore.app.get().pipe(
      first(),
      switchMap((app) =>
        merge(
          refreshTokenManager.manageRefreshToken(),
          // TODO: supprimer le data loading ici!!!
          appDataLoaderManager.manageDataLoading({
            appClientId: app.clientId,
            trackingClient,
          }),
          appWebConfig.data.graphql.enabled
            ? appConfigurationGraphqlFetcher.fetch()
            : of(undefined),
        ),
      ),
    );
  } catch (err) {
    logger.error('[appBootstrap] Error initializing authConfigurer', err);
  }
}
