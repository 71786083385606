import { useCallback, useState } from 'react';
import { useAppRouter } from 'src/business/_core/data/hooks/useAppRouter';
import {
  AppDialogPanelProps,
  AppDialogPanelState,
} from 'src/pages/_components';

// NOTE: rien à voir avec useAppDialogModal
export function useAppDialogPanel<
  InitialState,
  SuccessResult,
  CancelResult = any,
>({
  onUpdate: onUpdateEmit,
  onConfirm: onConfirmEmit,
  onCancel: onCancelEmit,
}: AppDialogPanelProps<
  InitialState,
  SuccessResult,
  CancelResult
> = {}): AppDialogPanelState<InitialState, SuccessResult, CancelResult> {
  const [isOpen, setIsOpen] = useState(false);

  const [initialState, setInitialState] = useState<InitialState>();

  const { scrollToTop } = useAppRouter();

  const openDialog = useCallback(
    (initialState: InitialState) => {
      scrollToTop('app-scrollable-dialog', {
        behavior: 'instant' as ScrollBehavior,
        delayInMs: 100,
      });

      setInitialState(initialState);
      setIsOpen(true);
    },
    [scrollToTop],
  );

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    setInitialState(undefined);
  }, []);

  // fermeture automatique (si pas souhaité, utiliser onUpdate ou bien autoCloseDialog)
  const onConfirmAndClose = useCallback(
    (result: SuccessResult) => {
      onConfirmEmit && onConfirmEmit(result, initialState);
      closeDialog();
    },
    [closeDialog, initialState, onConfirmEmit],
  );
  const onUpdate = useCallback(
    (result: SuccessResult) => {
      onUpdateEmit && onUpdateEmit(result, initialState);
    },
    [initialState, onUpdateEmit],
  );

  const onCancel = useCallback(
    (result: CancelResult) => {
      onCancelEmit && onCancelEmit(result, initialState);
      setIsOpen(false);
      setInitialState(undefined);
    },
    [initialState, onCancelEmit],
  );

  const state: AppDialogPanelState<InitialState, SuccessResult, CancelResult> =
    {
      isOpen,
      initialState,
      openDialog,
      closeDialog,
      onCancel,
      onConfirmAndClose,
      onConfirm: onConfirmAndClose,
      onUpdate,
    };

  return state;
}
