import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AppSimpleMenuButtonItem } from './model';

export function AppSimpleMenuButtonItemContent({
  item,
  state,
}: {
  item: AppSimpleMenuButtonItem;
  state: {
    active: boolean;
    disabled: boolean;
    close: () => void;
  };
}) {
  const { active } = state;

  const buttonClassName = useMemo(
    () =>
      clsx(
        'group flex items-center px-4 py-2 text-sm',
        active && 'bg-gray-100 font-bold',
        item.style === 'primary'
          ? 'text-app-primary group-hover:text-app-primary-dark'
          : active
          ? 'text-gray-900'
          : 'text-gray-700',
      ),
    [active, item.style],
  );
  const iconClassName = useMemo(
    () =>
      clsx(
        'mr-3 h-5 w-5',
        item.style === 'primary'
          ? 'text-app-primary group-hover:text-app-primary-dark'
          : ' text-gray-400 group-hover:text-gray-500',
      ),
    [item.style],
  );

  return item.hrefRoute ? (
    <Link
      to={item.hrefRoute}
      onClick={(e) => {
        if (item.onClick) {
          // aussi un onClick: on désactive la navigation (sauf si l'user ouvre un nouvel onglet)
          e.preventDefault();
        }
      }}
      className={buttonClassName}
    >
      {item.icon && <item.icon className={iconClassName} aria-hidden="true" />}
      {item.title}
    </Link>
  ) : (
    <button
      onClick={(e) => {
        if (item.onClick) {
          e.preventDefault();
        }
      }}
      className={buttonClassName}
    >
      {item.icon && <item.icon className={iconClassName} aria-hidden="true" />}
      {item.title}
    </button>
  );
}
