// ISO 3166-1 codes - https://github.com/umpirsky/country-list

import {
  ClubDiverResidentType,
  DIVER_RESIDENT_TYPES,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { ResidentTypeLabelBadge } from '../../components/ResidentTypeLabelBadge';

export function useResidentTypesOptions() {
  const diveModesOptions: ValueLabel<ClubDiverResidentType, React.ReactNode>[] =
    useMemo(
      () =>
        DIVER_RESIDENT_TYPES.map((residentType) => {
          return {
            value: residentType,
            label: (
              <ResidentTypeLabelBadge
                className="text-xs"
                residentType={residentType}
              />
            ),
          };
        }),
      [],
    );

  return diveModesOptions;
}
