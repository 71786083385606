/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  ClubDiverSelectorDialogCreateDiverFormModel,
} from '@mabadive/app-common-model';
import React, { FC, useEffect, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { fakerClient } from 'src/business/_core/data/app-operation';
import { useAutoFill } from 'src/business/_core/data/hooks';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  BookingMemberCreateFormRHF,
  BookingMemberDiverEditFormRHFFieldsToDisplay,
} from '../../forms';
import { FieldsToDisplayToggleButton } from './components';

export const ClubDiverSelectorDialogCreateDiverMemberForm: FC<{
  isDiverWithMember?: boolean;
  allowNameOnly?: boolean;
  autoFillLastName: string;
  showConfirmButton: boolean;
  form: UseFormReturn<ClubDiverSelectorDialogCreateDiverFormModel>;
  onSubmit: () => void;
  className?: string;
}> = ({
  isDiverWithMember = true,
  allowNameOnly = true,
  autoFillLastName,
  showConfirmButton,
  form,
  onSubmit,
  className = '',
}) => {
  const { register, handleSubmit, watch, formState, control, reset, setValue } =
    form;

  const [firstName, lastName] = useWatch({
    control,
    name: ['diver.firstName', 'diver.lastName'],
  });

  const formValue = useWatch({
    control,
  });

  const isMassiveCreationEnabled = useWatch({
    control,
    name: 'massiveCreation.enabled',
  });
  const massiveCreationQuantity = useWatch({
    control,
    name: 'massiveCreation.quantity',
  });

  const mediaSize = useMediaSizeTailwind();

  const [fieldsToDisplay, setFieldsToDisplay] =
    useState<BookingMemberDiverEditFormRHFFieldsToDisplay>(
      allowNameOnly ? 'name-only' : 'frequent-only',
    );

  useEffect(() => {
    if (allowNameOnly) {
      const isFormFirstNameOrLastNameSet =
        !!firstName?.trim().length || !!lastName?.trim().length;
      if (isFormFirstNameOrLastNameSet && fieldsToDisplay === 'name-only') {
        setFieldsToDisplay('frequent-only');
      } else if (
        !isFormFirstNameOrLastNameSet &&
        fieldsToDisplay !== 'name-only'
      ) {
        setFieldsToDisplay('name-only');
      }
    }
  }, [allowNameOnly, fieldsToDisplay, firstName, lastName]);

  const autoFill = useAutoFill();
  // const [autoFillCount, setAutoFillCount] = useState(1);
  // const [showAutoFillCount, setShowAutoFillCount] = useState(false);

  return (
    <div
      className={`relative border border-gray-300 hover:border-gray-800 focus-within:border-2 focus-within:border-app-blue hover:focus-within:border-app-blue rounded ${className}`}
    >
      {autoFill && (
        <div className="absolute top-0 right-0 p-1">
          <AppButton
            className="z-50"
            color={'primary-outline-light'}
            size="normal"
            icon={AppHeroIcons.autoFill}
            onClick={async () => {
              // setShowAutoFillCount(true);
              const model: ClubDiverSelectorDialogCreateDiverFormModel =
                await fakerClient.generateDiver({
                  lastName: autoFillLastName,
                });
              reset(model, {
                keepDirty: true,
              });
              // onSubmit();
            }}
          >
            {/* {showAutoFillCount && (
              <input
                className="w-24"
                type="number"
                value={autoFillCount}
                onChange={(e) => {
                  const count = parseInt(e.target.value, 10);
                  if (count >= 1) {
                    setAutoFillCount(count);
                  }
                }}
              />
            )} */}
          </AppButton>
        </div>
      )}
      <label
        className="z-1 ml-2 px-1.5 relative bg-white text-xs text-app-blue"
        style={{ top: '-16px', bottom: '-16px' }}
      >
        {mediaSize === 'xxs' || mediaSize === 'xs' || mediaSize === 'sm'
          ? 'Nouveau plongeur'
          : 'Création d\'un nouveau plongeur'}
      </label>
      <form
        className="px-3 mb-5"
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <BookingMemberCreateFormRHF
          isDiverWithMember={isDiverWithMember}
          form={
            form as unknown as UseFormReturn<BookingParticipantEditorFormModel>
          }
          fieldsToDisplay={fieldsToDisplay}
        />
        {(fieldsToDisplay === 'frequent-only' || fieldsToDisplay === 'all') && (
          <FieldsToDisplayToggleButton
            className="mt-2 text-right"
            displayAll={fieldsToDisplay === 'all'}
            onChange={(displayAll) => {
              if (displayAll) {
                setFieldsToDisplay('all');
              } else {
                setFieldsToDisplay('frequent-only');
              }
            }}
          />
        )}

        <input type="submit" className="hidden" />
        {formState.isValid && showConfirmButton && (
          <div className="my-5 flex justify-around">
            <AppButton
              type="button"
              disabled={!formState.isValid}
              size="normal"
              icon={AppHeroIcons.actionAddUser}
              color="primary-outline-light"
              onClick={() => {
                onSubmit();
              }}
            >
              {isMassiveCreationEnabled && massiveCreationQuantity > 1
                ? 'Créer les plongeurs'
                : 'Créer le plongeur'}
            </AppButton>
          </div>
        )}
      </form>
    </div>
  );
};
