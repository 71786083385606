/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';

import {
  CustomerSpaceConfigDialogInitialState,
  CustomerSpaceConfigDialogSuccessResult,
} from 'src/pages/SE-settings/SE-03-customers/customer-space/CustomerSpaceConfigDialog';
import { AppDialogPanelState } from 'src/pages/_components';
import { useAppDialogPanel } from '../_core';

import {
  AppDocEditDialogInitialState,
  AppDocEditDialogSuccessResult,
} from 'src/business/club/modules/app-doc/AppDocEditDialog';
import {
  BookingCreditNoteEditDialogInitialState,
  BookingCreditNoteEditDialogSuccessResult,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingCreditNoteEditDialog';
import {
  BookingDepositEditDialogInitialState,
  BookingDepositEditDialogSuccessResult,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingDepositEditDialog';
import {
  ClubPlanningDailyStaffDialogInitialState,
  ClubPlanningDailyStaffDialogSuccessResult,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/ClubPlanningDailyStaffDialog';
import {
  DiveSessionEditorMassiveDialogInitialState,
  DiveSessionEditorMassiveDialogSuccessResult,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorMassiveDialog';
import {
  CreateMessageToCustomersDialogInitialState,
  CreateMessageToCustomersDialogSuccessResult,
} from 'src/business/club/modules/club-messaging/components/CreateMessageToCustomersDialog';
import {
  StaffMemberEditDialogInitialState,
  StaffMemberEditDialogSuccessResult,
} from 'src/pages/SE-settings/SE-01_dive-center/staff-members/StaffMemberEditDialog';
import {
  DiveCenterEditDialogInitialState,
  DiveCenterEditDialogSuccessResult,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsAccountCompanyInfoPage/DiveCenterEditDialog';
import {
  ClubSettingsProductOffersDialogInitialState,
  ClubSettingsProductOffersDialogSuccessResult,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import {
  ClubSettingsServicesDialogInitialState,
  ClubSettingsServicesDialogSuccessResult,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsServices/_model';
import {
  ClubSettingsCommunicationDialogInitialState,
  ClubSettingsCommunicationDialogSuccessResult,
} from 'src/pages/SE-settings/SE-03-customers/communication/_model';
import {
  AppCompanyMessageTemplateEditDialogInitialState,
  AppCompanyMessageTemplateEditDialogSuccessResult,
} from 'src/pages/SE-settings/SE-03-customers/communication/tabs/02.message-templates/AppCompanyMessageTemplateEditDialog';

import {
  ClubSettingsGeneralDialogInitialState,
  ClubSettingsGeneralDialogSuccessResult,
} from 'src/pages/SE-settings/SE-04_other-settings/ClubSettingsGeneral/_model';
import {
  DailyExportConfigDialogInitialState,
  DailyExportConfigDialogSuccessResult,
} from 'src/pages/SE-settings/SE-07_exports';
import {
  ClubSettingsOnlineBookingDialogInitialState,
  ClubSettingsOnlineBookingDialogSuccessResult,
} from '../../SE-settings/SE-03-customers/online-booking/ClubSettingsOnlineBookingConfigPage/_model';
import { UseClubDialogsProps } from './model/UseClubDialogsProps.type';

/**
 *
 * Dialogs de l'espace Club
 *
 * TODO: migrer progressivement tous dialogs ici
 */
export function useClubDialogs({
  diveCenterEdit,
  customerSpaceConfig,
  communicationConfigDialog,
  onlineBookingConfigDialog,
  clubSettingsGeneral,
  clubSettingsServices,
  clubSettingsProductOffers,
  staffMemberEdit,
  bookingDepositEdit,
  createMessageToCustomers,
  bookingCreditNoteEdit,
  diveSessionEditorMassive,
  dailyExportConfig,
  appCompanyMessageTemplateEdit,
  appDocEdit,
}: UseClubDialogsProps = {}) {
  const diveCenterEditDialog =
    useAppDialogPanel<
      DiveCenterEditDialogInitialState,
      DiveCenterEditDialogSuccessResult
    >(diveCenterEdit);

  const customerSpaceConfigDialog =
    useAppDialogPanel<
      CustomerSpaceConfigDialogInitialState,
      CustomerSpaceConfigDialogSuccessResult
    >(customerSpaceConfig);

  const clubSettingsCommunicationDialog = useAppDialogPanel<
    ClubSettingsCommunicationDialogInitialState,
    ClubSettingsCommunicationDialogSuccessResult
  >(communicationConfigDialog);

  const clubSettingsOnlineBookingDialog = useAppDialogPanel<
    ClubSettingsOnlineBookingDialogInitialState,
    ClubSettingsOnlineBookingDialogSuccessResult
  >(onlineBookingConfigDialog);

  const clubSettingsGeneralDialog =
    useAppDialogPanel<
      ClubSettingsGeneralDialogInitialState,
      ClubSettingsGeneralDialogSuccessResult
    >(clubSettingsGeneral);
  const clubSettingsServicesDialog =
    useAppDialogPanel<
      ClubSettingsServicesDialogInitialState,
      ClubSettingsServicesDialogSuccessResult
    >(clubSettingsServices);

  const clubSettingsProductOffersDialog = useAppDialogPanel<
    ClubSettingsProductOffersDialogInitialState,
    ClubSettingsProductOffersDialogSuccessResult
  >(clubSettingsProductOffers);

  const staffMemberEditDialog =
    useAppDialogPanel<
      StaffMemberEditDialogInitialState,
      StaffMemberEditDialogSuccessResult
    >(staffMemberEdit);

  const bookingDepositEditDialog =
    useAppDialogPanel<
      BookingDepositEditDialogInitialState,
      BookingDepositEditDialogSuccessResult
    >(bookingDepositEdit);

  const createMessageToCustomersDialog = useAppDialogPanel<
    CreateMessageToCustomersDialogInitialState,
    CreateMessageToCustomersDialogSuccessResult
  >(createMessageToCustomers);

  const diveSessionEditorMassiveDialog = useAppDialogPanel<
    DiveSessionEditorMassiveDialogInitialState,
    DiveSessionEditorMassiveDialogSuccessResult
  >(diveSessionEditorMassive);

  const dailyExportConfigDialog =
    useAppDialogPanel<
      DailyExportConfigDialogInitialState,
      DailyExportConfigDialogSuccessResult
    >(dailyExportConfig);

  const clubPlanningDailyStaffDialog =
    useAppDialogPanel<
      ClubPlanningDailyStaffDialogInitialState,
      ClubPlanningDailyStaffDialogSuccessResult
    >();
  const appCompanyMessageTemplateEditDialog = useAppDialogPanel<
    AppCompanyMessageTemplateEditDialogInitialState,
    AppCompanyMessageTemplateEditDialogSuccessResult
  >(appCompanyMessageTemplateEdit);

  const appDocEditDialog =
    useAppDialogPanel<
      AppDocEditDialogInitialState,
      AppDocEditDialogSuccessResult
    >(appDocEdit);

  const bookingCreditNoteEditDialog = useAppDialogPanel<
    BookingCreditNoteEditDialogInitialState,
    BookingCreditNoteEditDialogSuccessResult
  >(bookingCreditNoteEdit);

  const dialogs: AppDialogPanelState<any, any>[] = useMemo(() => {
    const dialogs: AppDialogPanelState<any, any>[] = [
      diveCenterEditDialog,
      customerSpaceConfigDialog,
      clubSettingsCommunicationDialog,
      clubSettingsOnlineBookingDialog,
      clubSettingsGeneralDialog,
      clubSettingsServicesDialog,
      clubSettingsProductOffersDialog,
      staffMemberEditDialog,
      bookingDepositEditDialog,
      createMessageToCustomersDialog,
      diveSessionEditorMassiveDialog,
      dailyExportConfigDialog,
      clubPlanningDailyStaffDialog,
      appCompanyMessageTemplateEditDialog,
      appDocEditDialog,
      bookingCreditNoteEditDialog,
    ];
    return dialogs;
  }, [
    diveCenterEditDialog,
    customerSpaceConfigDialog,
    clubSettingsCommunicationDialog,
    clubSettingsOnlineBookingDialog,
    clubSettingsGeneralDialog,
    clubSettingsServicesDialog,
    clubSettingsProductOffersDialog,
    staffMemberEditDialog,
    bookingDepositEditDialog,
    createMessageToCustomersDialog,
    diveSessionEditorMassiveDialog,
    dailyExportConfigDialog,
    clubPlanningDailyStaffDialog,
    appCompanyMessageTemplateEditDialog,
    appDocEditDialog,
    bookingCreditNoteEditDialog,
  ]);

  const isAnyDialogOpen = useMemo(
    () => dialogs.reduce((acc, d) => acc || d.isOpen, false),
    [dialogs],
  );

  return {
    isAnyDialogOpen,
    dialogs,
    diveCenterEditDialog,
    customerSpaceConfigDialog,
    clubSettingsCommunicationDialog,
    clubSettingsOnlineBookingDialog,
    clubSettingsGeneralDialog,
    clubSettingsServicesDialog,
    clubSettingsProductOffersDialog,
    staffMemberEditDialog,
    bookingDepositEditDialog,
    createMessageToCustomersDialog,
    diveSessionEditorMassiveDialog,
    dailyExportConfigDialog,
    clubPlanningDailyStaffDialog,
    appCompanyMessageTemplateEditDialog,
    appDocEditDialog,
    bookingCreditNoteEditDialog,
  };
}
