/* eslint-disable @typescript-eslint/no-unused-vars */

import clsx from 'clsx';
import React from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubSettingsPlanningAssistantFormModel } from '../model';
import { ClubSettingsPlanningAssistantSimpleDiveTourForm } from './ClubSettingsPlanningAssistantSimpleDiveTourForm';

export const ClubSettingsPlanningAssistantForm = ({
  form,
  className,
}: {
  form: UseFormReturn<ClubSettingsPlanningAssistantFormModel, any>;
  className?: string;
}) => {
  const clubResume = useClubResume();

  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const diveToursDefFieldArray = useFieldArray({
    control,
    name: 'assistant.diveToursDef',
  });

  return (
    <div className={clsx('flex flex-col gap-2', className)}>
      <div className={'flex flex-col gap-4'}>
        {diveToursDefFieldArray.fields.map((field, diveTourDefIndex) => {
          return (
            <ClubSettingsPlanningAssistantSimpleDiveTourForm
              className="app-card p-4 "
              diveTourDefIndex={diveTourDefIndex}
              key={diveTourDefIndex}
              formDiveTourDef={field as any}
              form={form}
              onClickDelete={() =>
                diveToursDefFieldArray.remove(diveTourDefIndex)
              }
            />
          );
        })}
      </div>
      {/* <div className="text-center my-4">
        <AppButton
          className={'text-xs font-bold uppercase'}
          style={{ minWidth: '6rem' }}
          color={'primary-bg'}
          icon={AppHeroIcons.actionAdd}
          tabIndex={500}
          
        >
          Ajouter une configuration
        </AppButton>
      </div> */}
    </div>
  );
};
