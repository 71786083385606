/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveMode } from '@mabadive/app-common-model';
import { clubDiveSessionThemeBuilder } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

import clsx from 'clsx';
import { AppInputRHF, AppInputTimeRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useDiveModesOptions,
  useDiveSessionThemesOptions,
  useDiveSessionTypesOptions,
} from 'src/pages/_components/options';
import { useClubBoatOptions } from 'src/pages/_components/options/useClubBoatOptions.hook';
import { useDiveSitesOptions } from 'src/pages/_components/options/useDiveSitesOptions.hook';
import {
  ClubSettingsPlanningViewDayDiveTourModel,
  ClubSettingsPlanningViewDayModel,
} from '../../../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek';
import { ClubSettingsPlanningViewWeekDiveTourHeader } from '../../../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek/ClubSettingsPlanningViewWeekDiveTourHeader';
import { ClubSettingsPlanningViewWeekDiveTourSessionTime } from '../../../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek/ClubSettingsPlanningViewWeekDiveTourSessionTime';
import {
  ClubSettingsPlanningViewWeekDayDiveTourFormModel,
  ClubSettingsPlanningViewWeekFormModel,
} from '../../model';
import { usePlanningSettingsDiveTourModel } from '../ClubSettingsPlanningViewWeekDayGeneralForm/usePlanningSettingsDiveTourModel.hook';
import { usePlanningSettingsOnChangeDiveSessionType } from '../ClubSettingsPlanningViewWeekDayGeneralForm/usePlanningSettingsOnChangeDiveSessionType.hook';
import { ClubSettingsPlanningViewWeekDaySessionBookingForm_TourBookingConfig } from './ClubSettingsPlanningViewWeekDaySessionBookingForm_TourBookingConfig';

const diveSessionStatusOptions: ValueLabel<boolean, string>[] = [
  {
    value: true, // 'on',
    label: 'Ouvert',
  },
  {
    value: false, // 'off',
    label: 'Fermé',
  },
];

export const ClubSettingsPlanningViewWeekDaySessionBookingForm_Tour = ({
  day,
  formTour,
  dayIndex,
  diveTourIndex,
  form,
  className = '',
  onClickDelete,
}: {
  day: ClubSettingsPlanningViewDayModel;
  dayIndex: number;
  diveTourIndex: number;
  formTour: ClubSettingsPlanningViewWeekDayDiveTourFormModel;
  form: UseFormReturn<ClubSettingsPlanningViewWeekFormModel, any>;
  className?: string;
  onClickDelete: () => void;
}) => {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const diveTourControlBaseName =
    `days.${dayIndex}.diveTours.${diveTourIndex}` as const;

  const hasMultiSessionsEnabledRole =
    clubResume.clubSettings.general?.sessions?.multiSessions?.enabled;

  const currentDiveSitesIds = useMemo(
    () => [formTour.session1?.diveSiteId, formTour.session2?.diveSiteId],
    [formTour.session1?.diveSiteId, formTour.session2?.diveSiteId],
  );

  const diveSiteOptions = useDiveSitesOptions({ currentDiveSitesIds });

  const boatsOptions = useClubBoatOptions({
    currentClubBoatsIds: formTour.session1?.boatsIds,
  });

  const [
    multiSessionsEnabled,
    session1Name,
    session1Time,
    session1Boats,
    session1Special,
    session1DiveSiteId,
    session1EmojiId,
  ] = useWatch({
    control,
    name: [
      `${diveTourControlBaseName}.multiSessionsEnabled`,
      `${diveTourControlBaseName}.session1.name`,
      `${diveTourControlBaseName}.session1.time`,
      `${diveTourControlBaseName}.session1.boatsIds`,
      `${diveTourControlBaseName}.session1.special`,
      `${diveTourControlBaseName}.session1.diveSiteId`,
      `${diveTourControlBaseName}.session1.emojiId`,
    ],
  });

  const diveSessionThemesOptions = useDiveSessionThemesOptions({
    scope: 'session-settings',
  });
  const diveSessionTypesOptions = useDiveSessionTypesOptions({
    scope: 'session-settings',
  });

  const diveModesOptions: ValueLabel<DiveMode>[] = useDiveModesOptions({
    format: 'short-ref-label',
  });

  const time = session1Time;
  const timeDayPeriod = useMemo(
    () =>
      time?.getUTCHours
        ? clubDiveSessionThemeBuilder.buildTimeDayPeriod(time)
        : undefined,
    [time],
  );

  const diveTour: ClubSettingsPlanningViewDayDiveTourModel =
    usePlanningSettingsDiveTourModel({
      form,
      timeDayPeriod,
      diveCenterResume,
      diveTourControlBaseName,
      formTour,
      time,
    });

  const onChangeDiveSessionType = usePlanningSettingsOnChangeDiveSessionType(
    form,
    diveTourControlBaseName,
  );

  const bookingConfigsFieldArray = useFieldArray({
    control,
    name: `${diveTourControlBaseName}.bookingConfigs`,
  });

  return (
    <div className={`bg-white rounded-2xl ${className ?? ''}`}>
      <div
        className={clsx(
          "w-full relative focus:outline-none 'border-b",
          `border-t border-x border-dashed border-day-period-${timeDayPeriod}-dark`,
        )}
      >
        <ClubSettingsPlanningViewWeekDiveTourSessionTime diveTour={diveTour} />
        <ClubSettingsPlanningViewWeekDiveTourHeader
          className="h-12"
          diveTour={diveTour}
        />
      </div>
      <div className="text-sm text-gray-500">
        <div
          className={`relative bg-white mb-1.5 border-x border-b border-dashed
                    border-day-period-${timeDayPeriod}-dark
                    text-left flex flex-col py-2 px-1`}
        >
          <div className={'bg-white'}>
            <div className="mb-1 bg-white rounded text-gray-500 px-1">
              <div className={'flex flex-col gap-2'}>
                {diveSessionTypesOptions.length > 0 && (
                  <AppFormControlRHF_Deprecated
                    className="w-full"
                    label={'Type de sortie'}
                    control={control}
                    name={`${diveTourControlBaseName}.type` as const}
                    renderComponent={(props) => (
                      <AppSingleSelect2HeadlessUIRHF
                        theme="material-ui"
                        {...props}
                        options={diveSessionTypesOptions}
                        onChange={(value) => onChangeDiveSessionType(value)}
                      />
                    )}
                  />
                )}

                <div className="2xl:flex 2xl:gap-2">
                  <AppFormControlRHF_Deprecated
                    className=""
                    label={'Heure'}
                    control={control}
                    name={`${diveTourControlBaseName}.session1.time` as const}
                    required={true}
                    renderComponent={(props) => <AppInputTimeRHF {...props} />}
                  />
                  <AppFormControlRHF_Deprecated
                    className={'w-full'}
                    label={'Nom'}
                    control={control}
                    name={`${diveTourControlBaseName}.session1.name` as const}
                    renderComponent={(props) => (
                      <AppInputRHF {...props} fullWidth type="text" />
                    )}
                  />
                </div>

                <div
                  className={
                    'flex flex-col gap-x-2 sm:flex-row sm:items-start sm:flex-wrap lg:flex-col text-app-xxs md:text-xs font-bold uppercase'
                  }
                >
                  {bookingConfigsFieldArray.fields.map(
                    (field, diveTourBookingConfigIndex) => {
                      return (
                        <ClubSettingsPlanningViewWeekDaySessionBookingForm_TourBookingConfig
                          key={`${field.reference}-${field.id}-${diveTourIndex}`}
                          className="w-full sm:max-w-[31%] lg:max-w-full"
                          dayIndex={dayIndex}
                          diveTourIndex={diveTourIndex}
                          diveTourBookingConfigIndex={
                            diveTourBookingConfigIndex
                          }
                          form={form}
                          onClickDelete={() =>
                            bookingConfigsFieldArray.remove(diveTourIndex)
                          }
                        />
                      );
                    },
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
