import {
  AppBooklet,
  AppBookletPage,
  ClubEcommerceProduct,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  uuidGenerator,
} from '@mabadive/app-common-services';
import {
  EcommerceProductEditorFormModel,
  EcommerceProductEditorFormModelDefaultValue,
} from '../model';

const defaultBooklet: Partial<AppBookletPage> = {
  mainContent: {
    languageCode: 'fr',
    content: {
      title: '',
      items: [],
    },
    cover: {},
  },
};

export const ecommerceProductEditorformBuilder = {
  buildInitialFormValue: buildInitialNewProduct,
};

function buildInitialNewProduct({
  defaultValue,
}: {
  defaultValue: EcommerceProductEditorFormModelDefaultValue;
}): Partial<EcommerceProductEditorFormModel> {
  const bookletId = uuidGenerator.random();
  const appBookletPage = commonEntityBuilder.buildEntity<AppBookletPage>({
    ...defaultBooklet,
    bookletId,
    bookletType: 'ecommerce-product',
    level: 1,
    sortIndex: 1,
    visible: true,
  });
  const appBooklet = commonEntityBuilder.buildEntity<AppBooklet>({
    _id: bookletId,
    rootPageId: appBookletPage._id,
    bookletType: 'ecommerce-product',
  });
  const product = commonEntityBuilder.buildEntity<ClubEcommerceProduct>({
    name: '',
    enabled: true,
    bookletId,
    ...(defaultValue.product ?? {}),
  });
  const value: Partial<EcommerceProductEditorFormModel> = {
    product,
    categoryConfigs: defaultValue.categoryConfigs || [],
    appBookletPage,
    appBooklet,
  };
  return value;
}
