import { AppOperation } from '@mabadive/app-common-model';
import { SimpleStore } from '@mabadive/app-common-services';
import { libLogger } from 'src/_common-browser/libLogger.service';
import { clubAppOperationUpdateClientStoreProvider } from './clubAppOperationUpdateClientStoreProvider.service';
import { ClubAppOperationUpdateClientErrorMode } from './model/ClubAppOperationUpdateClientErrorMode.type';

const logger = libLogger.child({
  module: 'payload',
  filename: 'clubAppOperationUpdateClientSender.service',
});

export const clubAppOperationUpdateClientSender = {
  init,
  persistOneLater,
  persistManyLater,
};

function init({ baseStore }: { baseStore: SimpleStore<any> }) {
  logger.info('init payload store');

  return clubAppOperationUpdateClientStoreProvider.init(baseStore);
}

function persistOneLater({
  userId,
  operation,
  actionId,
  errorMode,
  maxErrorCount,
}: {
  userId: string;
  operation: AppOperation<any>;
  actionId?: string;
  errorMode?: ClubAppOperationUpdateClientErrorMode;
  maxErrorCount?: number;
}) {
  clubAppOperationUpdateClientStoreProvider.addOne({
    userId,
    operation,
    actionId,
    errorMode,
    maxErrorCount,
  });
}

function persistManyLater({
  userId,
  operations,
  actionId,
  errorMode,
  maxErrorCount,
}: {
  userId: string;
  operations: AppOperation<any>[];
  actionId?: string;
  errorMode?: ClubAppOperationUpdateClientErrorMode;
  maxErrorCount?: number;
}) {
  clubAppOperationUpdateClientStoreProvider.addMany({
    userId,
    operations,
    actionId,
    errorMode,
    maxErrorCount,
  });
}
