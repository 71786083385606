/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react';
import { AppTabTailwind } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

import { AppCompanyMessageTemplate } from '@mabadive/app-common-model';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useAppRouter } from '../../../../../business/_core/data/hooks';
import {
  ClubSettingsOnlineBookingPageTabEditMode,
  ClubSettingsOnlineBookingPageViewState,
} from './_model';
import {
  ClubSettingsOnlineBookingPageTabId,
  clubSettingsOnlineBookingPageTabLabelFormatter,
  clubSettingsOnlineBookingPageUrlManager,
} from './_services';
import { useClubSettingsOnlineBookingPageUpdateURL } from './useClubSettingsOnlineBookingPageUpdateURL.hook';

export const useClubSettingsOnlineBookingConfigPageLocalState = () => {
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    // appCompanyMessageTemplateEdit: {}, // ici on peut récupérer un callback si besoin
  });
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const openClubSettingsOnlineBookingDialog = useCallback(
    (tabId: ClubSettingsOnlineBookingPageTabId) => {
      dialogsState.clubSettingsOnlineBookingDialog.openDialog({
        tabId,
        defaultValue: {
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.clubSettingsOnlineBookingDialog],
  );

  const openMessageTemplateCreateDialog = useCallback(
    (messageTemplate: AppCompanyMessageTemplate) => {
      dialogsState.appCompanyMessageTemplateEditDialog.openDialog({
        mode: 'create',
        defaultValue: {
          messageTemplate,
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.appCompanyMessageTemplateEditDialog],
  );
  const openMessageTemplateEditDialog = useCallback(
    (messageTemplate: AppCompanyMessageTemplate) => {
      dialogsState.appCompanyMessageTemplateEditDialog.openDialog({
        mode: 'edit',
        defaultValue: {
          messageTemplate,
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.appCompanyMessageTemplateEditDialog],
  );

  const queryParams =
    clubSettingsOnlineBookingPageUrlManager.getQueryParameters();

  const [viewState, setViewState] =
    useState<ClubSettingsOnlineBookingPageViewState>({
      tabId: queryParams.tabId ?? 'config',
      mode: queryParams.mode ?? 'view',
    });

  useClubSettingsOnlineBookingPageUpdateURL(viewState, {
    enableRouteParams: true,
  });

  const selectedTab = viewState.tabId;

  const setSelectedTab = useCallback(
    (tabId: ClubSettingsOnlineBookingPageTabId) => {
      setViewState((prev) => ({
        ...prev,
        tabId,
      }));
    },
    [],
  );

  const isEditAllowedBase = useAppSecurityUserHasRole(
    'club-edit-settings-general',
  );
  const editMode: ClubSettingsOnlineBookingPageTabEditMode = useMemo(() => {
    // if (isEditAllowedBase) {
    //   if (selectedTab === 'pricing') {
    //     return 'forbidden';
    //   }
    // }
    return isEditAllowedBase ? 'allowed' : 'forbidden';
  }, [isEditAllowedBase]) as ClubSettingsOnlineBookingPageTabEditMode;

  const editEnabled = editMode === 'allowed'; // || (editMode === 'super-admin-only' && isSuperAdmin);

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const customerSpaceEnabled =
    clubSettings?.publicSettings?.customerSpace?.enabled;
  const onlineBookingEnabled = clubSettings?.general?.onlineBooking?.enabled;

  const appRouter = useAppRouter();

  const tabs = useMemo(() => {
    const tabs: AppTabTailwind<ClubSettingsOnlineBookingPageTabId>[] = [
      {
        id: 'products',
        label:
          clubSettingsOnlineBookingPageTabLabelFormatter.format('products'),
        onClick: () => {
          appRouter.navigate(
            '/club/settings/customers/online-booking/customers/online-booking',
            {
              cause: 'click-tab',
            },
          );
        },
      },
      {
        id: 'config',
        label: clubSettingsOnlineBookingPageTabLabelFormatter.format('config'),
      },
      {
        id: 'emails',
        label: clubSettingsOnlineBookingPageTabLabelFormatter.format('emails'),
      },
      {
        id: 'links',
        label: clubSettingsOnlineBookingPageTabLabelFormatter.format('links'),
      },
      {
        id: 'legal-information',
        label:
          clubSettingsOnlineBookingPageTabLabelFormatter.format(
            'legal-information',
          ),
      },
    ];
    return tabs;
  }, [appRouter]);

  return {
    state: {
      editEnabled,
      editMode,
      tabs: {
        selected: selectedTab,
        setSelected: setSelectedTab,
        data: tabs,
      },
    },
    dialogs: {
      state: dialogsState,
      communication: {
        open: openClubSettingsOnlineBookingDialog,
      },
      messageTemplate: {
        openCreate: openMessageTemplateCreateDialog,
        openEdit: openMessageTemplateEditDialog,
      },
    },
  };
};

export type ClubSettingsOnlineBookingConfigPageLocalState = ReturnType<
  typeof useClubSettingsOnlineBookingConfigPageLocalState
>;
