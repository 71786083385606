/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubEvent,
  ClubEventDescription,
  ClubEventMeta,
  ClubEventSchedule,
  DiveCenterResume,
} from '@mabadive/app-common-model';
import {
  changeDescriptorAggregator,
  changeDescriptorManager,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import {} from '../../club-diver-participant/pages/DiverBookingPage/services';

import {
  ClubEventDialogAggregatedData,
  ClubEventDialogInputState,
  ClubEventDialogUpdateState,
  ClubEventEditorFormModel,
} from './model';

export const clubEventDialogChangesAggregator = {
  aggregateChanges,
};

function aggregateChanges({
  diveCenterResume,
  formValue,
  isDirty,
  inputState,
  updateState: inputUpdateState,
}: {
  diveCenterResume: DiveCenterResume;
  formValue: ClubEventEditorFormModel;
  isDirty: boolean;
  inputState: ClubEventDialogInputState;
  updateState: ClubEventDialogUpdateState;
}): {
  updateState: ClubEventDialogUpdateState;
  aggregatedData: ClubEventDialogAggregatedData;
} {
  // 1) apply form changes to updateState

  const clubEventFromInputUpdateState: ClubEvent =
    buildUpdatedClubEventFromState(inputUpdateState, inputState);

  const localUpdateState = updateStateFromFormChanges({
    diveCenterResume,
    formValue,
    isDirty,
    inputState,
    updateState: inputUpdateState,
    clubEventFromInputUpdateState,
  });

  // 2) aggregate data

  const clubEvent: ClubEvent = buildUpdatedClubEventFromState(
    localUpdateState,
    inputState,
  );

  const aggregatedData: ClubEventDialogAggregatedData = {
    clubEvent,
  };
  return {
    updateState: localUpdateState,
    aggregatedData,
  };
}

function updateStateFromFormChanges({
  diveCenterResume,
  formValue,
  isDirty,
  inputState,
  updateState: inputUpdateState,
  clubEventFromInputUpdateState,
}: {
  diveCenterResume: DiveCenterResume;
  formValue: ClubEventEditorFormModel;
  isDirty: boolean;
  inputState: ClubEventDialogInputState;
  updateState: ClubEventDialogUpdateState;
  clubEventFromInputUpdateState: ClubEvent;
}): ClubEventDialogUpdateState {
  const createOrCloneMode =
    inputState.mode === 'create' || inputState.mode === 'clone';

  let localUpdateState = {
    ...inputUpdateState,
  };

  if (createOrCloneMode) {
    const newClubEvent: ClubEvent = buildNewClubEvent({
      diveCenterResume,
      formValue,
    });

    localUpdateState.hasChanges = true;
    localUpdateState.clubEventsChanges = changeDescriptorManager.createOne(
      newClubEvent,
      {
        changeDescriptors: localUpdateState.clubEventsChanges,
      },
    );

    return localUpdateState;
  } else if (inputState.mode === 'edit') {
    if (!isDirty) {
      return localUpdateState;
    }
    localUpdateState.hasChanges = true;

    const patchOperations = jsonPatcher.compareObjects(
      {
        ...clubEventFromInputUpdateState,
      },
      {
        ...clubEventFromInputUpdateState,
        ...buildClubEventPartial(formValue),
      },
      {
        // else, value won't be deleted by typeorm
        // https://github.com/typeorm/typeorm/issues/2934
        replaceDeleteByNullValue: true,
        attributesToReplaceFully: ['meta'],
      },
    );

    if (patchOperations.length) {
      localUpdateState.clubEventsChanges = changeDescriptorManager.updateOne(
        {
          pk: inputState.originalEvent?._id,
          patchOperations,
        },
        {
          changeDescriptors: localUpdateState.clubEventsChanges,
        },
      );
    }
  }
  return localUpdateState;
}

function buildNewClubEvent({
  formValue,
  diveCenterResume,
}: {
  formValue: ClubEventEditorFormModel;
  diveCenterResume: DiveCenterResume;
}): ClubEvent {
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const formValueEvent: Partial<ClubEvent> = formValue.clubEvent;

  const newClubEvent: ClubEvent = repositoryEntityBuilder.buildEntity<
    ClubEvent & {
      divingDirectorInstructorDegreeName: undefined;
    }
  >({
    ...buildClubEventPartial(formValue),
    clubReference,
    diveCenterId,
  });
  return newClubEvent;
}
function buildUpdatedClubEventFromState(
  localUpdateState: ClubEventDialogUpdateState,
  inputState: ClubEventDialogInputState,
): ClubEvent {
  const clubEvents: ClubEvent[] = changeDescriptorAggregator.aggregateMany(
    localUpdateState.clubEventsChanges,
    {
      pk: '_id',
      initials:
        inputState.mode === 'edit'
          ? [inputState.originalEvent as ClubEvent]
          : [],
    },
  );
  const clubEvent: ClubEvent = clubEvents.length ? clubEvents[0] : undefined;
  return clubEvent;
}

function buildClubEventPartial(
  formValue: ClubEventEditorFormModel,
): Partial<ClubEvent> {
  const formValueEvent: Partial<ClubEvent> = formValue.clubEvent;

  const beginDate = formValueEvent.beginDate;
  let endDate = formValueEvent.endDate;
  if (!endDate || endDate.getTime() < beginDate.getTime()) {
    endDate = new Date(beginDate.getTime());
  }
  // const beginTime = dateService.getUTCDateSetTime(
  //   formValueEvent.beginDate,
  //   formValueEvent.schedule.beginTime?.getUTCHours() ?? 0,
  //   formValueEvent.schedule.beginTime?.getUTCMinutes() ?? 0,
  // );

  // const endTime = dateService.getUTCDateSetTime(
  //   formValueEvent.beginDate,
  //   formValueEvent.schedule.beginTime?.getUTCHours() ?? 23,
  //   formValueEvent.schedule.beginTime?.getUTCMinutes() ?? 59,
  // );

  const description: ClubEventDescription = {
    emojiId: emptyStringOrNull(formValueEvent.description.emojiId),
    title: emptyStringOrNull(formValueEvent.description.title),
    comment: emptyStringOrNull(formValueEvent.description.comment),
  };
  const schedule: ClubEventSchedule = {
    ...formValueEvent.schedule,
  };
  const meta: ClubEventMeta = {
    ...formValueEvent.meta,
  };

  const clubEvent: Partial<ClubEvent> = {
    ...formValueEvent,
    beginDate,
    endDate,
    description,
    schedule,
    meta,
  };
  delete (clubEvent as any)['date'];
  delete (clubEvent as any)['divingDirectorStaffDivingLevel'];
  delete (clubEvent as any)['divingDirectorInstructorDegreeName'];

  return clubEvent as Partial<ClubEvent>;
}

function emptyStringOrNull(str: string): string | null {
  return str && str.trim().length !== 0 ? str.trim() : null;
}
