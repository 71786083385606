import { ClubParticipant, ClubSettings } from '@mabadive/app-common-model';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  BillingTabDiveSessionBillingResume,
  PRO_BookingParticipantFull,
} from '../../../models';

export const bookingPagePackageConsumedCounter = {
  buildConsumedAppCount,
  buildConsumedAppCountOnAssign,
  buildConsumedAppCountOnBuildUpdateResume,
  testIfCountSuccessiveAsSingle,
};
function testIfCountSuccessiveAsSingle({
  clubSettings,
  isSuccessivePackage,
}: {
  clubSettings: ClubSettings;
  isSuccessivePackage: boolean;
}) {
  // utilisé par seacretdive-port-louis-98825 et la-bulle-d-her-noirmoutier-27455
  // NOTE: côté backend, on a l'équivalent SQL quand on modifie les settings dans purchasePackageUpdator.updateOnClubSettingsChanges
  const countSuccessiveAsSingle =
    //  Grouper les explorations par plongée simple ou double-bloc
    // clubSettings.general.billing?.explorations?.groupsCriteria (17/04/2023: condition supprimée car sinon ça compte 2/1 sur les forfaits doubles => dès que c'est un forfait double, on ne compte qu'une plongée (TODO: il faudrait faire l'inverse, car si on associe des plongées simple à un forfait double, ça va bugger))
    // ?.multiSessions &&
    isSuccessivePackage || // purchaseProductPackage?.diveAttributes?.divePriceType === 'successive'
    // Tarif fixe plongée successive N°2
    clubSettings.general.billing?.explorations?.pricing.session2FixedPrice > 0;

  return countSuccessiveAsSingle;
}
function buildConsumedAppCountOnBuildUpdateResume({
  clubSettings,
  countSuccessiveAsSingle,
  billingResumes,
  assignedBookingProductsIds,
  diverId,
}: {
  clubSettings: ClubSettings;
  countSuccessiveAsSingle: boolean;
  billingResumes: BillingTabDiveSessionBillingResume[];
  assignedBookingProductsIds: string[];
  diverId: string; // propriétaire du forfait
}): { associatedDiversIds: string[]; consumedAppCount: number } {
  const associatedBookingParticipants = billingResumes.filter((x) => {
    if (
      assignedBookingProductsIds.includes(
        x.purchaseParticipant.bookingProduct._id,
      )
    ) {
      // updated product
      return true;
    }
    return false;
  });

  const associatedDiversIds = associatedBookingParticipants.reduce(
    (acc, x) => {
      const diverId =
        x.purchaseParticipant.participant.bookingMemberFull.diver._id;
      if (!acc.includes(diverId)) {
        acc.push(diverId);
      }
      return acc;
    },
    [diverId],
  );

  const consumedAppCount = buildConsumedAppCount({
    diveSessionParticipants: associatedBookingParticipants.map(
      (x) =>
        x.purchaseParticipant?.participant?.bookingParticipantFull
          ?.diveSessionParticipant,
    ),
    clubSettings,
    countSuccessiveAsSingle,
  });
  return { consumedAppCount, associatedDiversIds };
}

function buildConsumedAppCountOnAssign({
  purchasePackageId,
  countSuccessiveAsSingle,
  clubSettings,
  bookingParticipants,
  updatedBookingProductsIds,
  operation,
}: {
  purchasePackageId: string;
  countSuccessiveAsSingle: boolean;
  clubSettings: ClubSettings;
  bookingParticipants: PRO_BookingParticipantFull[];
  updatedBookingProductsIds: string[];
  operation: 'assign' | 'unassign';
}): number {
  const associatedDiveSessionParticipants = bookingParticipants
    .filter((x) => {
      if (updatedBookingProductsIds.includes(x.bookingProductDive._id)) {
        // updated product
        if (operation === 'assign') {
          return true;
        } else {
          return false;
        }
      }
      if (x.bookingProductDive.purchasePackageId === purchasePackageId) {
        // already assigned product
        return true;
      }
      return false;
    })
    .map((x) => x.diveSessionParticipant);
  const consumedAppCount = buildConsumedAppCount({
    diveSessionParticipants: associatedDiveSessionParticipants,
    clubSettings,
    countSuccessiveAsSingle,
  });
  return consumedAppCount;
}

function buildConsumedAppCount({
  clubSettings,
  countSuccessiveAsSingle,
  diveSessionParticipants,
}: {
  clubSettings: ClubSettings;
  countSuccessiveAsSingle: boolean;
  diveSessionParticipants: Pick<ClubParticipant, 'divesCount'>[];
}): number {
  if (countSuccessiveAsSingle) {
    return diveSessionParticipants.length;
  }
  const consumedAppCount = diveSessionParticipants.reduce(
    (sum, diveSessionParticipant) => {
      if (diveSessionParticipant?.divesCount === undefined) {
        appLogger.error('diveSessionParticipant?.divesCount is not set');
        throw new Error('Divecount not set');
      }
      return sum + (diveSessionParticipant?.divesCount ?? 1);
    },
    0,
  );
  return consumedAppCount;
}
