/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettingsPlanningDiveTour,
  ClubSettingsPlanningPeriodConfig,
  ClubSettingsPlanningPeriodConfigDay,
  ClubSettingsPlanningSession,
} from '@mabadive/app-common-model';
import { ClubSettingsPlanningViewWeekFormModel } from './model';

export const clubSettingsPlanningViewWeekEditorResultBuilder = {
  buildUpdatedPlanningPeriodConfig,
};

function buildUpdatedPlanningPeriodConfig({
  initialPeriodConfig,
  formValue,
}: {
  initialPeriodConfig: ClubSettingsPlanningPeriodConfig;
  formValue: ClubSettingsPlanningViewWeekFormModel;
}): ClubSettingsPlanningPeriodConfig {
  return {
    ...initialPeriodConfig,
    enabled: formValue.enabled,
    name: formValue.name,
    conflictMode: formValue.conflictMode,
    beginDate: formValue.beginDate,
    endDate: formValue.endDate,
    weekDays: initialPeriodConfig.weekDays.map((initialWeekDay, dayIndex) => {
      const formValueDay = formValue.days[dayIndex];

      const weekDay: ClubSettingsPlanningPeriodConfigDay = {
        ...initialWeekDay,
        // isOpen: formValueDay.isOpen, // TODO
        diveTours: formValueDay.diveTours.map(
          (formValueDiveTour, diveTourIndex) => {
            const formValueSession1 = formValueDiveTour.session1;

            const session1DayTime = {
              hours: formValueSession1.time?.getUTCHours(),
              minutes: formValueSession1.time?.getUTCMinutes(),
            };
            const session1: ClubSettingsPlanningSession = {
              diveSiteId: formValueSession1.diveSiteId,
              boatsIds: formValueSession1.boatsIds,
              name: formValueSession1.name,
              special: formValueSession1.special,
              emojiId: formValueSession1.emojiId,
              dayTime: session1DayTime,
            };

            let session2: ClubSettingsPlanningSession;
            const formValueSession2 = formValueDiveTour.session2;
            if (
              formValueDiveTour.multiSessionsEnabled &&
              formValueSession2.time
            ) {
              const session2DayTime = {
                hours: formValueSession2.time.getUTCHours(),
                minutes: formValueSession2.time.getUTCMinutes(),
              };
              session2 = {
                diveSiteId: formValueSession2.diveSiteId,
                boatsIds: formValueSession2.boatsIds,
                name: formValueSession2.name,
                special: formValueSession2.special,
                emojiId: formValueSession2.emojiId,
                dayTime: session2DayTime,
              };
            }

            const bookingConfigs = (
              formValueDiveTour.bookingConfigs ?? []
            ).filter(
              (x) =>
                (x.conf?.firstDive?.enabled &&
                  x.conf?.firstDive?.maxParticipants > 0) ||
                (x.conf?.snorkelingSupervised?.enabled &&
                  x.conf?.snorkelingSupervised?.maxParticipants > 0),
            );
            const diveTour: ClubSettingsPlanningDiveTour = {
              reference: formValueDiveTour.reference,
              isOpen: formValueDiveTour.isOpen,
              type: formValueDiveTour.type,
              theme: formValueDiveTour.theme,
              defaultDiveMode: formValueDiveTour.defaultDiveMode,
              session1,
              session2,
              bookingConfigs,
            };
            return diveTour;
          },
        ),
      };
      return weekDay;
    }),
  };
}
