import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppLoader,
  AppMessage,
  AppPageContainer,
  AppPageContentContainer,
  AppTabsBar,
  AppTabsBarTab,
  useAppPxContentSize,
  usePageWidth,
} from 'src/business/_core/modules/layout';
import { AppTitleDateNavigatorWithPeriodSelector } from 'src/pages/_components/title-navigators';
import {
  DASHBOARD_SPACE_TABS,
  DashboardTabId,
} from '../_core/components/DashboardTab';

import clsx from 'clsx';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppFormControlV2, AppToogleButtonGroupTailwind } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { AppTitleDateNavigatorPeriodRange } from 'src/pages/_components/title-navigators/model';
import { dashboardUrlBuilder } from '../_core/services';
import { DashboardReportPageCompareActionsBar } from './DashboardReportPageCompareActionsBar';
import { DashboardReportPanel } from './DashboardReportPanel';
import { DASHBOARD_REPORT_PAGE_MODES, DashboardReportPageMode } from './model';
import { dashboardReportPageModeFormatter } from './services';
import {
  DashboardReportPageLocalState,
  useDashboardReportPageLocalState,
} from './useDashboardReportPageLocalState.hook';

export const DashboardReportPage = () => {
  const localState: DashboardReportPageLocalState =
    useDashboardReportPageLocalState();

  const { state, actions } = localState;
  const {
    uiConfig,
    setUIConfig,
    viewParameters,
    setViewParameters,
    loadingInProgress,
  } = state;

  const redirectTo = useRedirect();

  const totalColsCount = viewParameters.showPeriodRangeComparison ? 2 : 1;

  const maxTotalWitdh = 2000; // max-w-screen-2xl
  // const pagePadding = 32; // // app-px-content: px-2 sm:px-3 md:px-4 lg:px-5 xl:px-6 2xl:px-7 (variable, FIXME)
  const pagePadding = useAppPxContentSize();
  const panelsGap = 16; // // gap-4 = 16px
  const panelsPadding = 16; // // gap-4 = 16px

  const pageWidth = usePageWidth({
    ignoreMenu: false,
    maxWidth: maxTotalWitdh,
  });

  const chartWidth = Math.floor(
    (pageWidth - // page (sans le menu)
      2 * pagePadding - // padding page
      (panelsGap * totalColsCount - 1)) / // gap panels
      totalColsCount -
      2 * panelsPadding, // padding panel
  );

  const modesOptions: ValueLabel<DashboardReportPageMode>[] = useMemo(
    () =>
      DASHBOARD_REPORT_PAGE_MODES.map((pageMode) => ({
        value: pageMode,
        label: dashboardReportPageModeFormatter.formatMode(pageMode),
      })),
    [],
  );

  return (
    <>
      <AppPageContainer
        className={'bg-white'}
        style={{ maxWidth: maxTotalWitdh }}
      >
        {/* maxWidth={'w-full md:max-w-7xl'} */}

        <AppTabsBar
          tabs={DASHBOARD_SPACE_TABS}
          value="report"
          onChange={(tab) => selectTab(tab)}
        />

        <div className="sticky top-0 app-p-content z-[10] bg-gray-50 flex gap-4 justify-between items-end">
          {viewParameters.showPeriodRangeComparison && (
            <AppTitleDateNavigatorWithPeriodSelector
              // regenerate if main period changes or if current period dates change
              key={`left-${
                viewParameters.periodRange?.period
              }-${viewParameters.periodRangeComparison?.value?.beginDate?.getTime()}-${viewParameters.periodRangeComparison?.value?.endDate?.getTime()}`}
              className="w-full"
              hidePeriodSelector={true}
              hideNavLeft={viewParameters.autoSyncPeriod !== 'none'}
              hideNavRight={viewParameters.autoSyncPeriod !== 'none'}
              periodRange={viewParameters.periodRangeComparison}
              onChange={(periodRangeComparison) => {
                setViewParameters({
                  ...viewParameters,
                  periodRangeComparison,
                  autoSyncPeriod: 'none',
                });
              }}
            >
              <div className="hidden md:flex gap-4 justify-between">
                <DashboardReportPageCompareActionsBar localState={localState} />
              </div>
            </AppTitleDateNavigatorWithPeriodSelector>
          )}
          <AppTitleDateNavigatorWithPeriodSelector
            // key={`right-${viewParameters.periodRange.period}`}
            className="w-full"
            periodRange={viewParameters.periodRange}
            ajustPeriodRangeToCurrentDate={
              viewParameters.showPeriodRangeComparison &&
              viewParameters.autoSyncPeriod &&
              viewParameters.ajustPeriodRangeToCurrentDate
            }
            onChange={(periodRange) => {
              let periodRangeComparison: AppTitleDateNavigatorPeriodRange =
                viewParameters.periodRangeComparison;

              if (
                viewParameters.showPeriodRangeComparison &&
                (periodRange?.period !== viewParameters?.periodRange?.period ||
                  viewParameters.autoSyncPeriod !== 'none')
              ) {
                // auto-select previous value
                periodRangeComparison = actions.buildPeriodRangeComparison({
                  ...viewParameters,
                  periodRange,
                });
              }
              setViewParameters({
                ...viewParameters,
                periodRange,
                periodRangeComparison,
              });
            }}
          >
            {!viewParameters.showPeriodRangeComparison && (
              <>
                <DashboardReportPageCompareActionsBar
                  className="hidden md:flex"
                  localState={localState}
                />
              </>
            )}
          </AppTitleDateNavigatorWithPeriodSelector>
        </div>
        <AppPageContentContainer className="bg-gray-50 app-px-content">
          {loadingInProgress && !localState?.data?.hasAnyResultOrFake ? (
            <AppLoader className="my-4" />
          ) : (
            <div className="w-full flex flex-col gap-4">
              <AppFormControlV2
                label={'Mode d\'affichage'}
                renderComponent={() => (
                  <>
                    <AppSingleSelect2HeadlessUI
                      className="w-full md:hidden"
                      theme={'tailwind'}
                      required
                      disableClearButton={true}
                      options={modesOptions}
                      value={uiConfig?.pageMode}
                      onChange={(pageMode) => {
                        setUIConfig({
                          ...uiConfig,
                          pageMode,
                        });
                      }}
                    />
                    <AppToogleButtonGroupTailwind
                      fullWidthMobile={false}
                      className="hidden md:block lg:max-w-[50%]"
                      customClassName="flex flex-col sm:grid sm:grid-cols-4"
                      selected={uiConfig?.pageMode}
                      onChanges={(pageMode) => {
                        setUIConfig({
                          ...uiConfig,
                          pageMode,
                        });
                      }}
                      items={modesOptions}
                      color={'green'}
                      theme={'light'}
                      size={'sm'}
                    />
                  </>
                )}
              />
              <DashboardReportPanel
                chartWidth={chartWidth}
                className={clsx(
                  'w-full',
                  !localState?.data?.main.data && 'blur-sm',
                )}
                localState={localState}
                totalColsCount={totalColsCount}
              />
              <AppMessage
                className="my-5"
                title={'Que manque-t-il sur cette page?'}
                message={
                  <>
                    <p>
                      Vous souhaiteriez voir un nouvel indicateur sur cette
                      page? N'hésitez pas à le demander!
                    </p>

                    <AppButton
                      className="mt-5"
                      color="primary-outline-light"
                      icon={AppHeroIcons.contact}
                      onClick={() =>
                        redirectTo(appRouteBuilder.getContactRoute())
                      }
                    >
                      Contactez-nous
                    </AppButton>
                  </>
                }
              />
            </div>
          )}
        </AppPageContentContainer>
      </AppPageContainer>
    </>
  );
  function selectTab(tab: AppTabsBarTab<DashboardTabId>) {
    return redirectTo(
      dashboardUrlBuilder.buildTabUrl({
        tabId: tab.id,
      }),
    );
  }
};
