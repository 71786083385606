import React, { FC } from 'react';
import { useAppSecurityUserHasRoles } from 'src/business/auth/services';
import { clubParticipantUrlBuilder } from 'src/business/club/modules/url-builders';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppTabsBar } from 'src/business/_core/modules/layout';
import { BookingParticipantViewDialogLocalState } from '../useBookingParticipantViewDialogLocalState.hook';
import { PARTICIPANT_VIEW_DIALOG_TABS } from './PARTICIPANT_VIEW_DIALOG_TABS.const';

export const BookingParticipantViewDialogTabBar: FC<{
  localState: BookingParticipantViewDialogLocalState;
  confirmPersistChangesBeforeLeave: () => Promise<boolean>;
}> = ({ localState, confirmPersistChangesBeforeLeave }) => {
  const redirectTo = useRedirect();

  const { aggregatedData, setIsOpen } = localState;
  const { participantData } = aggregatedData;

  const hasChanges = aggregatedData.hasChanges;

  const isEditParticipantRole = useAppSecurityUserHasRoles(
    'club-edit-participant-diver',
    'club-edit-participant-booking',
  );
  return (
    <AppTabsBar
      theme="participant"
      tabs={PARTICIPANT_VIEW_DIALOG_TABS}
      context={{
        diverId: participantData.diver?._id,
        diveSessionReference: participantData.diveSession?.reference,
      }}
      value={'edit-participant'}
      onChange={async (tab) => {
        if (
          !hasChanges ||
          !isEditParticipantRole ||
          (await confirmPersistChangesBeforeLeave())
        ) {
          // redirect
          setIsOpen(false);
          redirectTo(
            clubParticipantUrlBuilder.buildTabUrl({
              tabId: tab?.id,
              navigationContext: {
                origin: 'planning',
                diverId: participantData.diver?._id,
                diveSessionReference: participantData.diveSession?.reference,
              },
            }),
          );
        }
      }}
    />
  );
};
