/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubOffer, ClubResumeAdmin } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { uiStore } from 'src/business/_core/store';
import { AdminCompaniesListPageLocalState } from '../useAdminCompaniesListPageLocalState';
import { ClubResumeAdminCardActivityDates } from './ClubResumeAdminCardActivityDates';
import { ClubResumeAdminCardCompanyBrand } from './ClubResumeAdminCardCompanyBrand';
import { ClubResumeAdminCardCompanyLogo } from './ClubResumeAdminCardCompanyLogo';
import { ClubResumeAdminCardOffer } from './ClubResumeAdminCardOffer';

export const ClubResumeAdminCard = ({
  localState,
  clubResumeAdmin: club,
  onClick,
  className,
  as = 'div',
}: {
  clubResumeAdmin: ClubResumeAdmin;
  localState: AdminCompaniesListPageLocalState;
  onClick?: () => any;
  className?: string;
  as?: React.ElementType; // TODO personnaliser le componsant (ici, un "li", mais on pourrait vouloir un "div")
}) => {
  const redirectTo = useRedirect();
  const { filters } = localState;
  const { showDetails } = filters;

  const offer: ClubOffer = club.offer;

  const copyClubReferenceToClipboard = useCallback(() => {
    navigator.clipboard.writeText(club.reference).then(
      function () {
        uiStore.snackbarMessage.set({
          type: 'success',
          content: 'Référence copié dans le presse-papier!',
        });
      },
      function () {
        uiStore.snackbarMessage.set({
          type: 'error',
          content:
            'Erreur lors de la copie de la référence dans le presse-papier!',
        });
      },
    );
  }, [club.reference]);

  const borderBgColor =
    offer?.state === 'draft'
      ? 'bg-red-600'
      : offer?.state === 'trial'
      ? 'bg-green-600'
      : offer?.state === 'active'
      ? 'bg-gray-600'
      : offer?.state === 'archived'
      ? 'bg-gray-300'
      : 'bg-white';

  return React.createElement(
    as,
    {
      className: clsx(
        'group flex flex-col divide-y divide-gray-100 app-card-no-padding rounded-l-md',
        onClick && 'cursor-pointer',
        className,
      ),
      onClick,
      style: {
        // backgroundColor: colorGenerator.rgba(category.color, 0.1),
      },
    },
    <div className={clsx('w-full flex gap-4')}>
      {showDetails && (
        <div className={clsx('w-2 rounded-l-md', borderBgColor)}></div>
      )}
      <div className="flex-grow app-p-content  flex flex-col md:flex-row md:items-end gap-4">
        <div
          className={clsx(
            'flex flex-col xs:flex-wrap xs:flex-row xs:justify-start gap-4 text-gray-800',
          )}
        >
          <div className="md:w-[400px] flex flex-col xs:flex-row gap-3 xs:gap-4">
            <ClubResumeAdminCardCompanyLogo
              clubResumeAdmin={club}
              localState={localState}
            />
            <ClubResumeAdminCardCompanyBrand
              clubResumeAdmin={club}
              localState={localState}
            />
          </div>
        </div>
        {showDetails && (
          <div className="flex flex-col xs:flex-row gap-3 xs:gap-4 text-xs">
            <ClubResumeAdminCardActivityDates
              clubResumeAdmin={club}
              localState={localState}
            />
            <ClubResumeAdminCardOffer offer={offer} />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-1 justify-around">
        <AppButton
          icon={AppHeroIcons.actionSettings}
          color="primary-outline-light"
          onClick={(e) => {
            e.stopPropagation();
            redirectTo(`/admin/companies/${club.reference}/edit`);
          }}
        >
          Configurer
        </AppButton>
        {club.name.startsWith('@') ? (
          <AppButton
            icon={AppHeroIcons.actionDelete}
            color="danger-bg"
            onClick={(e) => {
              e.stopPropagation();
              localState.actions.deleteClub({
                clubId: club._id,
                clubName: club.name,
              });
            }}
          >
            Supprimer
          </AppButton>
        ) : club.offer?.state === 'draft' &&
          dateService.getAgeInDays(club._updatedAt) > 7 ? (
          <AppButton
            icon={AppHeroIcons.actionArchive}
            color="danger-outline-light"
            onClick={(e) => {
              e.stopPropagation();
              localState.actions.archiveClub(club);
            }}
          >
            Archiver
          </AppButton>
        ) : (
          <AppButton
            icon={AppHeroIcons.copyClipboard}
            color="gray-outline-light"
            onClick={(e) => {
              e.stopPropagation();
              copyClubReferenceToClipboard();
            }}
          >
            <span className="text-xs">Référence</span>
          </AppButton>
        )}
      </div>
      {showDetails && <div className={clsx('w-2', borderBgColor)}></div>}
    </div>,
  );
};
