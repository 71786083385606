import {
  ClubProductPackageOfferView,
  ClubProductPackageType,
} from '@mabadive/app-common-model';
import {
  ClubProductPackageOfferViewSortByAttribute,
  clubProductPackageOfferViewSorter,
  productPackageFormatter,
  search,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useInputModelAsObservable } from 'src/business/_core/data/hooks';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';

export function useOfferListPageLoadData({
  searchText,
  sortBy,
  type,
}: {
  type: ClubProductPackageType;
  sortBy: ClubProductPackageOfferViewSortByAttribute;
  searchText: string;
}): ClubProductPackageOfferView[] {
  const searchText$ = useInputModelAsObservable(searchText);
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = useClubSettings();
  const productPackageOffers = useMemo(
    () =>
      clubResume.productPackageOffers.filter(
        (offer) => offer.productPackage.type === type,
      ),
    [clubResume.productPackageOffers, type],
  );

  const productPackageOffersFiltered = useMemo(
    () =>
      search.filter(productPackageOffers, {
        getAttributes: (x) => {
          const name = productPackageFormatter.formatNameString(
            x.productPackage,
            {
              publicSettings,
              diveModesConf: clubSettings?.ui?.diveMode,
            },
          );
          return [x.reference, name];
        },
        searchText,
      }),
    [
      clubSettings?.ui?.diveMode,
      productPackageOffers,
      publicSettings,
      searchText,
    ],
  );

  const productPackageOffersFilteredAndSorted = useMemo(
    () =>
      clubProductPackageOfferViewSorter.sortOffersBy(
        productPackageOffersFiltered,
        {
          publicSettings,
          attributeName: sortBy,
          asc: true,
          diveModesConf: clubSettings?.ui?.diveMode,
        },
      ),
    [
      clubSettings?.ui?.diveMode,
      productPackageOffersFiltered,
      publicSettings,
      sortBy,
    ],
  );

  return productPackageOffersFilteredAndSorted;
}
