/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';

import {
  ClubSettingsServicesDialogInitialState,
  ClubSettingsServicesFormModel,
} from '../../../_model';
import { ClubSettingsServicesTrainingFormOrgs } from './ClubSettingsServicesTrainingFormOrgs';

export const ClubSettingsServicesTrainingForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
  initialState: ClubSettingsServicesDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const clubSettingsFirstDiveEnabled: boolean = useWatch({
    control,
    name: 'clubSettings.firstDive.enabled',
  });

  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsServicesTrainingFormOrgs form={form} />
    </div>
  );
};
