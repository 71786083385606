import React from 'react';
import { DashboardReportPageEvolutionParticipantsByDay } from '../_core';

import clsx from 'clsx';
import { DashboardReportPageExploParticipants } from '../_core/components/charts/01-participants/DashboardReportPageExploParticipants';
import { DashboardReportPageGlobalActivityPurchasesExplosOverPeriod } from '../_core/components/charts/03-purchase/DashboardReportPageGlobalActivityPurchasesExplosOverPeriod';
import { DashboardReportComparison } from './DashboardReportComparison';
import {
  DashboardReportComparisonContext,
  DashboardReportPageLocalState,
} from './useDashboardReportPageLocalState.hook';

export const DashboardReportPanelExplos = ({
  chartWidth,
  totalColsCount,
  localState,
  comparisonContext,
  className,
}: {
  chartWidth: number;
  totalColsCount: number;
  localState: DashboardReportPageLocalState;
  comparisonContext: DashboardReportComparisonContext;
  className?: string;
}) => {
  const showPeriodRangeComparison =
    localState.state.viewParameters.showPeriodRangeComparison;

  return (
    <div className="w-full flex flex-col gap-5">
      <div
        className={clsx(
          'flex flex-col gap-5',
          showPeriodRangeComparison ? '' : '2xl:grid 2xl:grid-cols-2',
        )}
      >
        <DashboardReportComparison
          comparisonContext={comparisonContext}
          render={(fetchResult, comparisonDetails) =>
            fetchResult.exploParticipantsOverPeriod && (
              <DashboardReportPageExploParticipants
                // key={JSON.stringify(comparisonDetails?.sharedState)}
                title={'Plongées d\'exploration'}
                exploParticipantsOverPeriod={
                  fetchResult.exploParticipantsOverPeriod
                }
                comparisonDetails={comparisonDetails}
              />
            )
          }
        />
        <DashboardReportComparison
          comparisonContext={comparisonContext}
          render={(fetchResult, comparisonDetails) =>
            fetchResult.purchasesExplosOverPeriod && (
              <DashboardReportPageGlobalActivityPurchasesExplosOverPeriod
                title="Explorations facturées"
                purchasesExplosOverPeriod={
                  fetchResult.purchasesExplosOverPeriod
                }
                comparisonDetails={comparisonDetails}
              />
            )
          }
        />
      </div>

      <DashboardReportComparison
        comparisonContext={comparisonContext}
        render={(fetchResult, comparisonDetails) =>
          fetchResult.participantsByPeriod && (
            <DashboardReportPageEvolutionParticipantsByDay
              participantsByPeriod={fetchResult.participantsByPeriod}
              chartWidth={chartWidth}
              pageMode="explos"
              comparisonDetails={comparisonDetails}
            />
          )
        }
      />
    </div>
  );
};
