/* eslint-disable @typescript-eslint/no-unused-vars */
import { uiStore } from 'src/business/_core/store';

export const appClipboardCopier = {
  copyToClipboard,
};
function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    function () {
      uiStore.snackbarMessage.set({
        type: 'success',
        content: 'Lien copié dans le presse-papier!',
      });
    },
    function () {
      uiStore.snackbarMessage.set({
        type: 'error',
        content: 'Erreur lors de la copie du lien dans le presse-papier!',
      });
    },
  );
}
