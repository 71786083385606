import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { BookingResumeParticipantForSessionWithSession } from '../../../models';
import { BookingMassiveEditorActions } from '../useBookingMassiveEditorDialogActions.hook';
import { BookingMassiveEditorInnerLocalState } from '../useBookingMassiveEditorInnerLocalState.hook';

export function BookingMassiveEditorSessionsTableRegisterAllButton({
  localState,
  actions,
  className,
}: {
  localState: BookingMassiveEditorInnerLocalState;
  actions: Pick<
    BookingMassiveEditorActions,
    'onClickDeleteAllDailyParticipants' | 'onClickRegisterAll'
  >;
  className?: string;
}) {
  const { participantsBySessions } = localState.data;

  const registeredParticipants = useMemo(
    () =>
      participantsBySessions.reduce(
        (acc, x) =>
          acc.concat(
            x.participants.filter(
              ({ bookingParticipantFullAnyBooking }) =>
                !!bookingParticipantFullAnyBooking,
            ),
          ),
        [] as BookingResumeParticipantForSessionWithSession[],
      ),
    [participantsBySessions],
  );
  const unregisteredParticipantsCount = useMemo(
    () =>
      participantsBySessions.reduce(
        (count, x) =>
          count +
          x.participants.filter(
            ({ bookingParticipantFullAnyBooking, bookingMemberFull }) =>
              !bookingParticipantFullAnyBooking &&
              bookingMemberFull?.diver?.defaultDiveConfig?.diveMode,
          ).length,
        0,
      ),
    [participantsBySessions],
  );

  return participantsBySessions.length > 1 ? (
    <div
      className={clsx(
        'mt-4 sm:mt-0 text-left',
        localState.data.isUniqueMember ? 'sm:mb-6' : 'sm:mb-10',
        className,
      )}
    >
      {unregisteredParticipantsCount + registeredParticipants.length > 0 && (
        <AppButton
          fullWidth={true}
          className="text-sm font-bold py-1 px-1 whitespace-nowrap max-w-sm"
          color="primary-bg"
          onClick={() => {
            if (unregisteredParticipantsCount === 0) {
              actions.onClickDeleteAllDailyParticipants({
                participants: registeredParticipants,
              });
            } else {
              actions.onClickRegisterAll();
            }
          }}
        >
          <div className="w-full flex flex-row justify-start items-center gap-2 px-0">
            <input
              className="mx-1 text-app-primary ring-1 ring-white"
              type="checkbox"
              readOnly={true}
              checked={unregisteredParticipantsCount === 0}
            />
            {localState.data.isUniqueMember ? (
              <div className="">INSCRIRE À TOUTES LES SESSIONS</div>
            ) : (
              <div className="">INSCRIRE TOUS LES PARTICIPANTS</div>
            )}
          </div>
        </AppButton>
      )}
    </div>
  ) : null;
}
