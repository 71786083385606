/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButtonsBar } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppIcons } from 'src/business/_core/modules/layout/icons';

export const AppCompanyMessageTemplateEditDialogActionsBar = ({
  onCancel,
  submitForm,
}: {
  onCancel: () => any;
  submitForm: () => any;
}) => {
  return (
    <AppButtonsBar hasChanges={true}>
      <>
        <AppButton
          size="normal"
          icon={AppIcons.action.cancel}
          tabIndex={500}
          color="gray-outline-light"
          onClick={() => {
            onCancel();
          }}
        >
          Annuler
        </AppButton>
        <AppButton
          type="submit"
          size="normal"
          icon={AppIcons.action.save}
          color="primary-outline-light"
          onClick={() => {
            submitForm();
          }}
        >
          Confirmer
        </AppButton>
      </>
    </AppButtonsBar>
  );
};
