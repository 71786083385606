/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useMemo } from 'react';
import { AppPageContainer } from 'src/business/_core/modules/layout';
import { ClubDialogsState } from 'src/pages/_dialogs';
import {
  BookingParticipantEditorDialogState,
  DiverBookingPageUpdateState,
} from '../../models';
import { diverBookingPageUpdateStateManager } from '../../services';
import { BookingParticipantEditorDialog } from '../BookingParticipantDialog';
import { BookingMassiveEditorDialogEditMemberPanel } from './BookingMassiveEditorDialogEditMemberPanel';
import { BookingMassiveEditorDialogRegistrationPanel } from './BookingMassiveEditorDialogRegistrationPanel';
import {
  BookingMassiveEditorDialogInputState,
  BookingMassiveEditorDialogLocalState,
} from './model';
import {
  BookingMassiveEditorActions,
  useBookingMassiveEditorDialogActions,
} from './useBookingMassiveEditorDialogActions.hook';
import { useBookingMassiveEditorDialogLocalState } from './useBookingMassiveEditorDialogLocalState.hook';

export const BookingMassiveEditorDialog: FC<{
  dialogsState: ClubDialogsState;
  state: BookingMassiveEditorDialogInputState;
  setState: React.Dispatch<
    React.SetStateAction<BookingMassiveEditorDialogInputState>
  >;
  onConfirm?: (updateState: DiverBookingPageUpdateState) => void;
  onCancel?: () => void;
}> = ({
  dialogsState,
  onConfirm = () => {},
  onCancel = () => {},
  state: inputState,
  setState: setInputState,
}) => {
  const localState: BookingMassiveEditorDialogLocalState =
    useBookingMassiveEditorDialogLocalState({
      inputState,
      setInputState,
    });

  const { viewState, setViewState, updateState, setUpdateState, innerState } =
    localState;

  const { participantsBySessions, bookingParticipantsWithSessionFull } =
    innerState;

  const onMemberUpdated = () =>
    setViewState({
      mode: 'edit-massive-registration',
    });

  const actions: BookingMassiveEditorActions =
    useBookingMassiveEditorDialogActions({
      eventsTriggers: localState.eventsTriggers,
      localState: localState.innerState,
      onMemberUpdated,
    });

  const { onConfirmMemberUpdate, onConfirmParticipantUpdate } = actions;

  const participantEditorInputState: BookingParticipantEditorDialogState =
    useMemo(() => {
      if (
        viewState.mode === 'create-participant' ||
        viewState.mode === 'edit-participant'
      ) {
        return {
          isOpen: true,
          // defaultExpandedPanel: 'panel3',
          mode: viewState.mode,
          participant: viewState.participant,
        };
      } else {
        return {
          isOpen: false,
        };
      }
    }, [viewState.mode, viewState.participant]);

  return (
    <>
      {viewState.mode === 'edit-massive-registration' && (
        <BookingMassiveEditorDialogRegistrationPanel
          localState={localState}
          actions={actions}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
      {viewState.mode === 'edit-member' && (
        <BookingMassiveEditorDialogEditMemberPanel
          dialogsState={dialogsState}
          bookingMemberFull={localState.viewState?.bookingMemberFull}
          onConfirm={onConfirmMemberUpdate}
          onCancel={() => {
            setViewState({
              mode: 'edit-massive-registration',
            });
          }}
        />
      )}

      {(viewState.mode === 'create-participant' ||
        viewState.mode === 'edit-participant') &&
        !dialogsState.isAnyDialogOpen && (
          <AppPageContainer className="bg-white relative overflow-y-hidden">
            <BookingParticipantEditorDialog
              dialogsState={dialogsState}
              state={participantEditorInputState}
              onClose={() =>
                setViewState({
                  mode: 'edit-massive-registration',
                })
              }
              onConfirm={(result) => {
                onConfirmParticipantUpdate({
                  result,
                  bookingParticipants: bookingParticipantsWithSessionFull,
                  applyDefaultConfigChanges: false,
                  logContext: 'Massive editor confirm',
                });
              }}
              onDelete={(participant) => {
                const updateStateLocal =
                  diverBookingPageUpdateStateManager.deleteParticipantsFromState(
                    {
                      updateState,
                      participants: [
                        {
                          bookingProductId: participant.bookingProduct._id,
                          clubParticipantId: participant.clubParticipant._id,
                          bookingSessionParticipantId:
                            participant.bookingSessionParticipant._id,
                        },
                      ],
                    },
                  );
                setUpdateState(updateStateLocal);
              }}
            />
          </AppPageContainer>
        )}
    </>
  );
};
