/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningParticipantsConfigDialog } from '../ClubPlanningParticipantsConfigDialog';
import {
  ClubPlanningDateNavigationBar,
  ClubPlanningDisplayModeToggleButton,
  ClubPlanningTodayButton,
  ClubPlanningViewPeriodToggleButton,
} from '../club-planning-navigation';
import { SetPlanningConfigOptions } from '../hooks';

export const ClubPlanningSessionSelectorConfigBar = ({
  planningConfig,
  setPlanningConfig,
  display,
  className,
}: {
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  display?: {
    view?: boolean;
    mode?: boolean;
    actions?: boolean;
  };
  className?: string;
}) => {
  return (
    <>
      <div
        className={clsx(
          'relative flex flex-row justify-between items-center py-1',
          className,
        )}
      >
        <div className={clsx('absolute top-0 left-0 flex gap-2')}>
          <ClubPlanningTodayButton
            className="flex mx-1.5 my-1"
            planningConfig={planningConfig}
            setPlanningConfig={setPlanningConfig}
          />
        </div>
        <ClubPlanningDateNavigationBar
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
        />
      </div>
      <div
        className={clsx(
          'flex items-stretch gap-4 justify-between py-1 px-2',
          className,
        )}
      >
        <div className={'flex flex-row items-stretch gap-4'}>
          <ClubPlanningParticipantsConfigDialog
            planningConfig={planningConfig}
            setPlanningConfig={setPlanningConfig}
          />
          <div
            className={
              'h-full flex flex-row items-stretch gap-4 sm:gap-8 mg:gap-12'
            }
          >
            {display?.view && (
              <ClubPlanningViewPeriodToggleButton
                className="h-full flex uppercase text-xs"
                context={'session-selector-dialog'}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
              />
            )}
            {display?.mode && (
              <ClubPlanningDisplayModeToggleButton
                className="h-full flex uppercase text-xs"
                context={'session-selector-dialog'}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
