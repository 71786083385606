import { DiveSessionResumeFull } from '@mabadive/app-common-model';
import { useDiveCenterResume } from 'src/business/club/data/hooks/useDiveCenterResume.hook';
import {
  LoadableContentPartial,
  useLoadableContent,
} from '../../../app-loading/loadable';
import { diveSessionResumeFullRepository } from '../diveSessionResumeFullRepository.service';

export function useLoadableDiveSessionResumeFull({
  diveSessionReference,
  createVirtualIfNotExists,
}: {
  diveSessionReference: string;
  createVirtualIfNotExists: boolean;
}): LoadableContentPartial<DiveSessionResumeFull> {
  const diveCenterResume = useDiveCenterResume();

  return useLoadableContent(
    () =>
      diveSessionResumeFullRepository.findOneOrCreateVirtual(
        { diveSessionReference, diveCenterResume },
        { forceReload: true, createVirtualIfNotExists },
      ),
    [diveSessionReference, diveCenterResume, createVirtualIfNotExists],
    {
      debugName: 'useLoadableDiveSessionResumeFull',
      useSnapshot: true,
    },
  );
}
