/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdateDescriptor,
  AppEntityUpdatePatch,
  BookingParticipantEditorFormModel,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import {
  BookingParticipantEditorDialogState,
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResultChanges,
} from '../../../models';
import { participantEditorFormInitialValueBuilder } from '../BookingParticipantEditorDialog/services';
import { bookingParticipantEditDialogAggregator } from '../BookingParticipantEditorDialog/services/changes-builder';

export function useBookingParticipantEditDialogLocalState({
  state,
}: {
  state: BookingParticipantEditorDialogState;
}) {
  const [isPersistInProgress, setIsPersistInProgress] = useState(false);

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;
  const clubSettings = diveCenterResume?.clubResume?.clubSettings;

  const participantDataInitialFull: BookingParticipantEditorParticipant =
    state.participant;

  const initialFormValue = useMemo(
    () =>
      participantEditorFormInitialValueBuilder.buildInitialFormValue({
        mode: state.mode,
        participantData: participantDataInitialFull,
        diveCenterResume,
      }),
    [diveCenterResume, participantDataInitialFull, state.mode],
  );

  const form = useForm<BookingParticipantEditorFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const formValue = useWatch({
    control,
  }) as BookingParticipantEditorFormModel;

  const [docResumesChanges, setDocResumesChanges] = useState<
    AppEntityUpdateDescriptor<PRO_AppDocResume>[]
  >([]);

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    appDocEdit: {
      onCreateAndPersisted: ({ appDoc }: { appDoc: PRO_AppDocResume }) => {
        const docResumesChangesUpdated = changeDescriptorManager.addOneOriginal(
          appDoc,
          {
            changeDescriptors: docResumesChanges,
          },
        );
        setDocResumesChanges(docResumesChangesUpdated);
      },
      onUpdateNotPersisted: ({
        patches,
      }: {
        patches: AppEntityUpdatePatch[];
      }) => {
        const docResumesChangesUpdated = changeDescriptorManager.updateMany(
          patches,
          {
            optimizePatches: false,
            changeDescriptors: docResumesChanges,
          },
        );
        setDocResumesChanges(docResumesChangesUpdated);
      },
      onDeleteNotPersisted: ({ appDocIds }: { appDocIds: string[] }) => {
        const docResumesChangesUpdated = changeDescriptorManager.deleteMany(
          appDocIds,
          {
            changeDescriptors: docResumesChanges,
          },
        );
        setDocResumesChanges(docResumesChangesUpdated);
      },
    },
  });
  const dialogsStateLocal: ClubDialogsState = useClubDialogs(actionsPersist);

  // loaded bookings + changes applied
  const {
    aggregatedParticipantData,
    changes,
  }: {
    aggregatedParticipantData: BookingParticipantEditorParticipant;
    changes?: BookingParticipantEditorResultChanges;
  } = useMemo(() => {
    // return { aggregatedParticipantData };

    const { aggregatedParticipantData, changes } =
      bookingParticipantEditDialogAggregator.aggregate({
        state,
        formValue,
        clubReference,
        diveCenterId,
        initialFormValue,
        participantDataInitialFull,
        form,
        docResumesChanges,
      });
    return { aggregatedParticipantData, changes };
  }, [
    state,
    formValue,
    clubReference,
    diveCenterId,
    initialFormValue,
    participantDataInitialFull,
    form,
    docResumesChanges,
  ]);

  return {
    docResumesChanges,
    dialogsStateLocal,
    initialFormValue,
    form,
    aggregatedParticipantData,
    changes,
    clubReference,
    isPersistInProgress,
    setIsPersistInProgress,
  };
}

export type BookingParticipantEditDialogLocalState = ReturnType<
  typeof useBookingParticipantEditDialogLocalState
>;
