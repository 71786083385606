import React from 'react';
import { Switch } from 'react-router-dom';
import { of } from 'rxjs';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import { AppAuth } from 'src/business/auth/services';
import useRouter from 'use-react-router';
import { ClubOfferDiveListPage } from '../../club-settings-offer-dive';
import { ClubOfferProductListPage } from '../../club-settings-offer-products';
import { ClubOfferTrainingListPage } from '../../club-settings-offer-training';

export const ClubSettingsOfferRoot = React.memo(function ClubRootSpaceMemo() {
  const { match } = useRouter();

  return (
    <Switch>
      <AppRoute
        path={`${match.url}/dive`}
        exact
        component={ClubOfferDiveListPage}
        auth={{ requiredRoles: ['club-view-settings-offers'] }}
      />
      <AppRoute
        path={`${match.url}/training`}
        exact
        component={ClubOfferTrainingListPage}
        auth={{ requiredRoles: ['club-view-settings-offers'] }}
      />
      <AppRoute
        path={`${match.url}/products`}
        exact
        component={ClubOfferProductListPage}
        auth={{ requiredRoles: ['club-view-settings-offers'] }}
      />

      {/* default route */}
      <AppRoute
        path={`${match.url}/`}
        redirectTo={({ appAuth }) =>
          of(redirectToDefaultRoute({ appAuth, matchUrl: match.url }))
        }
      />
    </Switch>
  );
});

function redirectToDefaultRoute({
  appAuth,
  matchUrl,
}: {
  appAuth: AppAuth;
  matchUrl: string;
}): string {
  if (appAuth) {
    return `${matchUrl}/services`;
  }
  return '/';
}
