import { Dispatch, SetStateAction, useState } from 'react';
import { DiverBookingPageConfig } from '../../models';
import { useInitialDiverBookingPageConfig } from './useInitialDiverBookingPageConfig.hook';
import { useUpdateUiStoreFromDiverBookingPageConfig } from './useUpdateUiStoreFromDiverBookingPageConfig.hook';
import { useUpdateURLFromDiverBookingPageConfig } from './useUpdateURLFromDiverBookingPageConfig.hook';

export function useDiverBookingPageConfig({
  enableRouteParams,
  enableStoreParams,
}: {
  enableRouteParams: boolean;
  enableStoreParams: boolean;
}): [DiverBookingPageConfig, Dispatch<SetStateAction<DiverBookingPageConfig>>] {
  const initialConfig = useInitialDiverBookingPageConfig({
    enableRouteParams,
    enableStoreParams,
  });

  const [pageConfig, setPageConfig] =
    useState<DiverBookingPageConfig>(initialConfig);

  useUpdateURLFromDiverBookingPageConfig(pageConfig, { enableRouteParams });
  useUpdateUiStoreFromDiverBookingPageConfig(pageConfig, {
    enableStoreParams,
  });

  return [pageConfig, setPageConfig];
}
