import React, { FC } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppDialogModal,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import { CreateMessageToCustomersPanel } from './CreateMessageToCustomersPanel';
import { CreateMessageToCustomersDialogState } from './model';
import {
  CreateMessageToCustomersLocalState,
  useCreateMessageToCustomersLocalState,
} from './useCreateMessageToCustomersLocalState.hook';
export const CreateMessageToCustomersDialog: FC<CreateMessageToCustomersDialogState> =
  (inputState) => {
    const redirectTo = useRedirect();

    const {
      isOpen,
      initialState,
      onCancel: onCancelInput,
      onConfirm,
      openDialog,
      closeDialog,
    } = inputState;

    const clubResume = useClubResume();
    const clubReference = clubResume?.reference;

    const localState: CreateMessageToCustomersLocalState =
      useCreateMessageToCustomersLocalState(inputState);

    const {
      data: { form, result },
      actions,
    } = localState;

    const { register, handleSubmit, watch, control, setValue } = form;

    const formState = useFormState({
      control,
    });
    const hasChanges = result.hasChanges;
    const isValid = formState.isValid;

    const [messageTarget] = useWatch({
      control,
      name: ['messageTarget'],
    });

    const validSelectedRecipients = result?.aggregated?.validSelectedRecipients;

    return !isOpen ? null : (
      <>
        <div className={`${isOpen ? '' : 'hidden'}`}>
          <AppDialogModal
            maxHeight="md:max-h-[900px]"
            expandWidth={true}
            expandHeight={true}
            className="bg-white"
            open={isOpen}
            setOpen={(isOpen) => {
              if (!isOpen) {
                actions.onCancel();
              }
            }}
          >
            <AppPageContentContainer className="m-2 app-p-content flex flex-col gap-4">
              <h2 className="my-2 uppercase text-base xs:text-lg sm:text-xl font-bold text-gray-700 mr-12">
                Envoyer un{' '}
                {messageTarget === 'email'
                  ? 'email'
                  : messageTarget === 'sms'
                  ? 'SMS'
                  : messageTarget === 'whatsapp'
                  ? 'message Whatsapp'
                  : 'message'}
                <span className="hidden sm:inline">
                  {validSelectedRecipients?.length === 1
                    ? ' au participant'
                    : ' aux participants'}
                </span>
                {/* {validCount > 0 && (
                  <span className={'font-normal text-gray-800'}>
                    {' '}
                    ({validCount}/{totalCount})
                  </span>
                )} */}
              </h2>

              <CreateMessageToCustomersPanel
                className="flex-grow"
                localState={localState}
              />
            </AppPageContentContainer>
          </AppDialogModal>
        </div>
      </>
    );
  };
