/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageAttributesDive,
  ClubProductPackageAttributesDiveType,
  ClubProductPackageAttributesTraining,
  DIVE_SERVICE_ORGANIZATIONS,
  DIVE_TRAININGS,
  DIVE_TYPES,
  DiveMode,
  DiveServiceOrganizationReference,
  DiveServiceTrainingType,
  DiveTrainingReference,
  TRAINING_TYPES,
} from '@mabadive/app-common-model';
import {
  ClubProductPackageNameDetailsFormatProps,
  diveModeFormatter,
  diveServiceOrganizationFilter,
  diveServiceTrainingFormatter,
  diveTypeFormatter,
  productPackageFormatter,
  trainingTypeFormatter,
} from '@mabadive/app-common-services';
import { Box, FormControl, FormLabel } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { AppFixedButtonsBar } from 'src/business/_core/modules/layout/components/AppFixedButtonsBar/AppFixedButtonsBar';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { ClubOfferNameDetailsLabel } from 'src/business/club/modules/club-diver-participant/components';
import { useResidentTypesOptions } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms';
import {
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppMultiCheckboxesRHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { ClubOfferCustomNameForm } from '../../club-settings-offer-dive/ClubOfferDiveEditor/ClubOfferCustomNameForm';
import { ClubOfferTrainingEditorContext } from './ClubOfferTrainingEditorContext.type';
import { ClubOfferTrainingEditorFormModel } from './ClubOfferTrainingEditorFormModel.type';
import { ClubOfferTrainingEditorResult } from './ClubOfferTrainingEditorResult.type';
import { clubOfferTrainingEditorResultBuilder } from './clubOfferTrainingEditorResultBuilder.service';

const ALLOWED_DIVE_MODES_EXTRA: DiveMode[] = [
  'first-dive',
  'supervised',
  'autonomous',
  'free-dive',
  'snorkeling',
  'snorkelingSupervised',
  'watchingTour',
  'autoSupervised',
  'observer',
  'training',
];
const ALLOWED_DIVE_MODES_EXTRA_OPTIONS = ALLOWED_DIVE_MODES_EXTRA.map(
  (diveMode) => {
    const option: ValueLabel<DiveMode> = {
      label: diveModeFormatter
        .formatDiveMode(diveMode, { format: 'short-ref-label' })
        .toUpperCase(),
      value: diveMode,
    };
    return option;
  },
);

export const ClubOfferTrainingEditor = ({
  context,
  onCancel,
  onConfirm,
  className,
}: {
  context: ClubOfferTrainingEditorContext;
  onCancel?: () => void;
  onConfirm?: (result: ClubOfferTrainingEditorResult) => void;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const generalSettings = clubResume.clubSettings.general;
  const clubSettings = useClubSettings();

  const services = clubResume.clubSettings.services;

  // TODO plus tard : on pourrait améliorer ça en filtrant par clubResume.serviceTypes,
  // mais il faudrait que celui-ci soit éditable depuis la même page, pour ce qui concerne cette page
  const trainingTypeOptions = useMemo(
    () =>
      TRAINING_TYPES.map((trainingType) => {
        const option: ValueLabel<DiveServiceTrainingType> = {
          label: trainingTypeFormatter
            .formatTrainingType(trainingType)
            .toUpperCase(),
          value: trainingType,
        };
        return option;
      }),
    [],
  );

  const initialFormValue = useMemo(() => {
    if (context.mode === 'create') {
      const model: ClubOfferTrainingEditorFormModel = {
        trainingType: 'main-certification',
        divesCount: 5,
        theoricalClassesCounts: 0,
        price: null,
        thirdPartyCollectPrice: null,
        comment: null,
        minAge: null,
        maxAge: null,
        diveType: 'scuba',
        minDepth: null,
        maxDepth: null,
        diveTrainingReference: null,
        namingConfiguration: {
          includeEquipment: true,
          includeSupervision: true,
          includeDefaultName: true,
        },
        defaultOrganizationReference:
          diveServiceOrganizationFilter.getDefaultOrganizationReference({
            services,
          }),
      };
      return model;
    } else {
      const o = context.offer;
      const productAttributes = o.productPackage.productAttributes;
      const diveAttributes = o.productPackage.diveAttributes;
      const trainingAttributes = o.productPackage.trainingAttributes;
      const salesCriteria = o.productPackage.salesCriteria;
      const model: ClubOfferTrainingEditorFormModel = {
        price: o.price,
        thirdPartyCollectPrice: o.thirdPartyCollectPrice,
        comment: o.productPackage.comment,
        label: o.productPackage.label,
        namingConfiguration: o.productPackage.namingConfiguration,
        defaultOrganizationReference:
          productAttributes.defaultOrganizationReference,
        minAge: productAttributes.minAge,
        maxAge: productAttributes.maxAge,
        diveType: diveAttributes.diveType,
        divesCount: diveAttributes.divesCount,
        validityPeriodInDays: productAttributes.validityPeriodInDays,
        divesCountInfinite: diveAttributes.divesCountInfinite,
        minDepth: diveAttributes.minDepth,
        maxDepth: diveAttributes.maxDepth,
        extraDiveModes: diveAttributes.extraDiveModes,
        trainingType: trainingAttributes.trainingType,
        diveTrainingReference: trainingAttributes.diveTrainingReference,
        theoricalClassesCounts: trainingAttributes.theoricalClassesCounts,
        salesCriteria,
      };
      return model;
    }
  }, [context.mode, context.offer, services]);

  const form = useForm<ClubOfferTrainingEditorFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const [
    trainingType,
    defaultOrganizationReference,
    label,
    namingConfiguration,
    diveType,
    divesCount,
    minDepth,
    maxDepth,
    diveTrainingReference,
    theoricalClassesCounts,
    divesCountInfinite,
    overrideDefaultNameEnabled,
  ] = useWatch({
    control,
    name: [
      'trainingType',
      'defaultOrganizationReference',
      'label',
      'namingConfiguration',
      'diveType',
      'divesCount',
      'minDepth',
      'maxDepth',
      'diveTrainingReference',
      'theoricalClassesCounts',
      'divesCountInfinite',
      'namingConfiguration.overrideDefaultName.enabled',
    ],
  });

  const selectedOrganization = useMemo(
    () =>
      DIVE_SERVICE_ORGANIZATIONS.find(
        (x) => x.reference === defaultOrganizationReference,
      ),
    [defaultOrganizationReference],
  );

  const residentTypesOptions = useResidentTypesOptions();

  const diveTypesOptions = useMemo(
    () =>
      DIVE_TYPES.filter((x) => {
        if (
          trainingType === 'main-certification' ||
          trainingType === 'gaz-certification'
        ) {
          return x === 'scuba';
        }
        return false;
      }).map((diveType) => {
        const option: ValueLabel<ClubProductPackageAttributesDiveType> = {
          label: diveTypeFormatter
            .formatDiveType(diveType, {
              format: 'full',
            })
            .toUpperCase(),
          value: diveType,
        };
        return option;
      }),
    [trainingType],
  );

  const organizationsOptions = useMemo(
    () =>
      DIVE_SERVICE_ORGANIZATIONS.filter(
        (org) =>
          org?.reference === initialFormValue.defaultOrganizationReference ||
          diveServiceOrganizationFilter.isOrgEnabled(org?.reference, {
            services,
          }),
      ).map((org) => {
        const option: ValueLabel<DiveServiceOrganizationReference> = {
          label: org.label.toUpperCase(),
          value: org?.reference,
        };
        return option;
      }),
    [services, initialFormValue.defaultOrganizationReference],
  );

  const diveTrainingOptions = useMemo(
    () =>
      DIVE_TRAININGS.filter(
        (diveTraining) =>
          diveTraining.trainingType === trainingType &&
          (!selectedOrganization ||
            selectedOrganization.orgGroupReference ===
              diveTraining.organization?.reference),
      ).map((training) => {
        const option: ValueLabel<DiveTrainingReference> = {
          label: diveServiceTrainingFormatter
            .formatTraining(training, {
              format: 'ref-name',
            })
            .toUpperCase(),
          value: training.reference,
        };
        return option;
      }),
    [selectedOrganization, trainingType],
  );

  const user = useAppSecurityUser();

  const nameDetails = useMemo(() => {
    const diveAttributes: ClubProductPackageAttributesDive = {
      diveMode: 'training',
      diveType: diveType,
      equipment: 'not-equipped',
      supervision: 'supervised',
      divesCount: divesCount,
      minDepth: minDepth,
      maxDepth: maxDepth,
    };
    const trainingAttributes: ClubProductPackageAttributesTraining = {
      trainingType: trainingType,
      diveTrainingReference: diveTrainingReference,
      theoricalClassesCounts: theoricalClassesCounts,
    };
    const productPackage: ClubProductPackageNameDetailsFormatProps = {
      label,
      namingConfiguration,
      diveAttributes,
      trainingAttributes,
      type: 'dive',
    };

    const nameDetails = productPackageFormatter.formatNameDetails(
      productPackage,
      {
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );
    return nameDetails;
  }, [
    clubSettings?.ui?.diveMode,
    diveTrainingReference,
    diveType,
    divesCount,
    label,
    maxDepth,
    minDepth,
    namingConfiguration,
    publicSettings,
    theoricalClassesCounts,
    trainingType,
  ]);

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: ClubOfferTrainingEditorFormModel, event) => {
        const { result, hasChanges } =
          clubOfferTrainingEditorResultBuilder.buildResult({
            clubReference: clubResume.reference,
            context,
            formValue,
          });

        if (!hasChanges) {
          // no changes
          onCancel();
          return;
        } else {
          onConfirm(result);
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [clubResume.reference, context, handleSubmit, onCancel, onConfirm]);

  return (
    <div className={className}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <AppFormControlRHF_Deprecated
          label="Catégorie"
          control={control}
          name="trainingType"
          required={true}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={trainingTypeOptions}
            />
          )}
        />
        {trainingType && organizationsOptions.length > 1 && (
          <AppFormControlRHF_Deprecated
            label="Organisme"
            control={control}
            name="defaultOrganizationReference"
            required={true}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={organizationsOptions}
              />
            )}
          />
        )}
        <AppFormControlRHF_Deprecated
          label="Formation"
          control={control}
          name="diveTrainingReference"
          required={true}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={diveTrainingOptions}
            />
          )}
        />
        {trainingType && diveTypesOptions.length > 1 && (
          <AppFormControlRHF_Deprecated
            label="Type de plongée"
            control={control}
            name="diveType"
            required={true}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={diveTypesOptions}
              />
            )}
          />
        )}
      </div>
      {trainingType && (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <AppFormControlRHF_Deprecated
              className={clsx('', divesCountInfinite && 'invisible')}
              control={control}
              label="Nombre de plongées"
              required={true}
              name={'divesCount'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label="PASS illimité"
              name={'divesCountInfinite' as any}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label="Nombre de cours théoriques"
              required={true}
              name={'theoricalClassesCounts'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label="Validité (jours)"
              name={'validityPeriodInDays'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              className={'w-full'}
              label="Résidence"
              control={control}
              name={'salesCriteria.residentType'}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  className={'w-full'}
                  theme="material-ui"
                  color="theme"
                  {...props}
                  options={residentTypesOptions}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              className="mb-2"
              label={'Offre par défaut'}
              control={control}
              name="salesCriteria.dontSelectByDefault"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  description={'ne pas pré-selectionner'}
                />
              )}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div className={`col-span-2 flex flex-col ${className ?? ''}`}>
              <FormControl>
                <FormLabel className="form-label pl-1" component="legend">
                  <span className="text-xs uppercase">Libellé affiché</span>
                </FormLabel>
                <Box className="form-input">
                  <ClubOfferNameDetailsLabel
                    className="font-bold text-app-blue"
                    nameDetails={nameDetails}
                  />
                </Box>
              </FormControl>
            </div>

            <AppFormControlRHF_Deprecated
              label="Libellé additionnel"
              control={control}
              name="label"
              renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
            />
            <div>
              {label?.length > 0 && (
                <AppFormControlRHF_Deprecated
                  className="mb-2"
                  // label={'Construction du libellé'}
                  control={control}
                  name="namingConfiguration.includeDefaultName"
                  renderComponent={(props) => (
                    <AppInputBooleanRHF
                      {...props}
                      type="checkbox"
                      description={'inclure le nom par défaut'}
                    />
                  )}
                />
              )}
            </div>
          </div>
          <ClubOfferCustomNameForm
            form={form as any}
            nameDetails={nameDetails}
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <AppFormControlRHF_Deprecated
              control={control}
              label="Age mini (inclu)"
              name={'minAge'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label="Age maxi (exclu)"
              name={'maxAge'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label="Profondeur mini"
              name={'minDepth'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label="Profondeur maxi"
              name={'maxDepth'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              label="Tarif"
              control={control}
              required={false}
              name={'price'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />{' '}
            {generalSettings?.billing?.thirdPartyCollectEnabled && (
              <AppFormControlRHF_Deprecated
                label="Tarif (part tiers)"
                control={control}
                required={false}
                name={'thirdPartyCollectPrice'}
                renderComponent={(props) => (
                  <AppInputRHF {...props} type="number" fullWidth />
                )}
              />
            )}
          </div>
          <div>
            <AppFormControlRHF_Deprecated
              label="Commentaire"
              control={control}
              name="comment"
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
          </div>
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Prestations  associées"
            control={control}
            name={'extraDiveModes'}
            renderComponent={(props) => (
              <AppMultiCheckboxesRHF
                className="flex flex-wrap text-xs"
                {...props}
                options={ALLOWED_DIVE_MODES_EXTRA_OPTIONS}
              />
            )}
          />
        </>
      )}

      <AppFixedButtonsBar hasChanges={formState.isDirty}>
        <AppButton
          fullWidth
          icon={AppHeroIcons.actionCancel}
          color="gray-outline-light"
          size="normal"
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          Annuler
        </AppButton>
        <AppButton
          fullWidth
          icon={AppHeroIcons.actionSave}
          color="primary-outline-light"
          onClick={() => {
            submitForm();
          }}
        >
          Confirmer
        </AppButton>
      </AppFixedButtonsBar>
    </div>
  );
};
