/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingCustomerUpdate,
  DiveCertificationReference,
  JsonPatchOperation,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  jsonPatcher,
} from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { usePageWidth } from 'src/business/_core/modules/layout/services/usePageWidth.hook';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { diveCenterBookingPageAggregatedBookingResumeBuilderCore } from '../../../../_from-diver-app';
import { DiveCenterBookingMembersDiffList } from '../../../../_from-diver-app/DiveCenterBookingMembersList/DiveCenterBookingMembersDiffList';
import {
  BookingTabModel,
  DiverBookingPageAggregatedDataCore,
  DiverBookingPageUpdateState,
  PRO_BookingMemberFull,
} from '../../../models';
import { diverBookingPageAggregatedBookingResumeBuilderCore } from '../../../services';

import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { DiverBookingPageActions } from '../../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../../useDiverBookingPageGlobalState.hook';

const showComment = appWebConfig.envId === 'dev' && false;

export const DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates = ({
  globalState,
  dialogs,
  actions,
  bookingCustomerUpdates,
  bookingTabModel,
  setIsExpanded,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingCustomerUpdates: BookingCustomerUpdate[];
  bookingTabModel: BookingTabModel;
  setIsExpanded: (isOpen: boolean) => void;
}) => {
  const pageWidth = usePageWidth({ ignoreMenu: false });

  const {
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
    loadedContent,
  } = globalState;

  const {
    openDiverSelector,
    openSessionDialog,
    openSessionSelector,
    createNewBooking,
    deleteDiverOnly,
  } = actions;

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const booking = bookingTabModel.aggregatedBooking.booking;

  const bookingMembersFullWithAutoFix: PRO_BookingMemberFull[] = useMemo(() => {
    if (bookingCustomerUpdates?.length > 0) {
      try {
        let aggregatedDataCore: DiverBookingPageAggregatedDataCore =
          diverBookingPageAggregatedBookingResumeBuilderCore.aggregateDataCore({
            updateState,
            loadedContent,
          });

        for (const customerUpdate of bookingCustomerUpdates) {
          // apply all changes
          aggregatedDataCore =
            diveCenterBookingPageAggregatedBookingResumeBuilderCore.aggregateDataCore(
              {
                updateState: customerUpdate.payload.updateState,
                entities: aggregatedDataCore,
              },
            );
        }

        const bookingMembersFull: PRO_BookingMemberFull[] =
          aggregatedDataCore.bookingMembers
            .filter((x) => x.bookingId === booking._id)
            .map((bookingMember) => {
              const diver = aggregatedDataCore.divers.find(
                (x) => x._id === bookingMember.diverId,
              );
              const memberFull: PRO_BookingMemberFull = {
                booking,
                bookingMember,
                diver,
              };
              return memberFull;
            });

        return bookingMembersFull;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        appLogger.error(
          `[${clubResume.reference}] unable to apply auto-fix on booking ${booking._id}`,
        );
        return bookingTabModel.aggregatedBooking.bookingMembersFull;
      }
    } else {
      return bookingTabModel.aggregatedBooking.bookingMembersFull;
    }
  }, [
    booking,
    bookingCustomerUpdates,
    bookingTabModel.aggregatedBooking.bookingMembersFull,
    clubResume.reference,
    loadedContent,
    updateState,
  ]);

  const updateStateWithAutoFixNow = useMemo(() => {
    let updateStateLocal: DiverBookingPageUpdateState = {
      ...updateState,
    };
    bookingCustomerUpdates.forEach((upd) => {
      if (upd.payload.updateState) {
        updateStateLocal.bookingMembersChanges =
          updateStateLocal.bookingMembersChanges.concat(
            upd.payload.updateState.bookingMembersChanges,
          );
        updateStateLocal.diversChanges = updateStateLocal.diversChanges.concat(
          upd.payload.updateState.diversChanges,
        );
      }

      const patchOperations = jsonPatcher.compareObjects(
        upd,
        {
          ...upd,
          status: 'ok',
          reviewDate: new Date(),
        },
        {},
      );
      if (patchOperations.length) {
        const patch: AppEntityUpdatePatch = {
          pk: upd._id,
          patchOperations,
        };
        updateStateLocal.bookingCustomerUpdatesChanges =
          changeDescriptorManager.updateOne(patch, {
            changeDescriptors:
              updateStateLocal.bookingCustomerUpdatesChanges ?? [],
          });
      }

      // updateStateLocal.bookingCustomerUpdatesChanges =
      //   changeDescriptorManager.deleteOne(upd._id, {
      //     changeDescriptors: updateStateLocal.bookingCustomerUpdatesChanges,
      //   });
    });

    bookingMembersFullWithAutoFix.forEach((bookingMemberFull) => {
      const { diver, bookingMember } = bookingMemberFull;
      const expectedDive = bookingMember?.details?.expectedDive;
      const defaultDiveConfig = diver.defaultDiveConfig
        ? { ...diver.defaultDiveConfig }
        : {};
      let defaultDiveConfigUpdated = false;
      if (!defaultDiveConfig?.diveMode && expectedDive?.diveMode) {
        defaultDiveConfig.diveMode = expectedDive?.diveMode;
        defaultDiveConfigUpdated = true;
      }
      if (
        defaultDiveConfig?.diveMode === 'autonomous' ||
        defaultDiveConfig?.diveMode === 'supervised' ||
        defaultDiveConfig?.diveMode === 'instructor'
      ) {
        // TOOD gérer spécifiquement autoSupervisedDetails
        if (
          !defaultDiveConfig?.certificationReference &&
          diver.mainCertificationReference
        ) {
          defaultDiveConfig.certificationReference =
            diver.mainCertificationReference as unknown as DiveCertificationReference;
          defaultDiveConfigUpdated = true;
        }
        if (
          !defaultDiveConfig?.certificationDeepDiver &&
          diver.divingCertification1?.specialties?.deepDiver
        ) {
          defaultDiveConfig.certificationDeepDiver =
            diver.divingCertification1?.specialties?.deepDiver;
          defaultDiveConfigUpdated = true;
        }
      }
      if (defaultDiveConfigUpdated) {
        const patchOperations = jsonPatcher.compareObjects(
          diver,
          {
            ...diver,
            defaultDiveConfig,
          },
          {},
        );
        if (patchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: diver._id,
            patchOperations,
          };
          updateStateLocal.diversChanges = changeDescriptorManager.updateOne(
            patch,
            {
              changeDescriptors: updateStateLocal.diversChanges,
            },
          );
        }
      }
    });

    return updateStateLocal;
  }, [bookingCustomerUpdates, bookingMembersFullWithAutoFix, updateState]);

  const acceptCustomerUpdates = useCallback(
    ({ disableCustomerUpdate }: { disableCustomerUpdate: boolean }) => {
      const updateStateLocal = {
        ...updateStateWithAutoFixNow,
      };
      if (
        disableCustomerUpdate &&
        booking.bookingCustomerConfig?.customerUi?.enableCustomerUpdate
      ) {
        // mark update as accepted
        const patchOperations: JsonPatchOperation = {
          op: 'replace',
          path: '/bookingCustomerConfig/customerUi/enableCustomerUpdate',
          value: false,
        };
        const patch: AppEntityUpdatePatch = {
          pk: booking._id,
          patchOperations: [patchOperations],
        };
        updateStateLocal.bookingsChanges = changeDescriptorManager.updateOne(
          patch,
          {
            changeDescriptors: updateStateLocal.bookingsChanges ?? [],
          },
        );
      }
      setUpdateState(updateStateLocal, {
        action: 'accept customer updates',
        meta: {
          disableCustomerUpdate,
          bookingId: booking._id,
        },
      });
    },
    [
      booking._id,
      booking.bookingCustomerConfig?.customerUi?.enableCustomerUpdate,
      setUpdateState,
      updateStateWithAutoFixNow,
    ],
  );

  const denyCustomerUpdates = useCallback(() => {
    const updateStateLocal: DiverBookingPageUpdateState = {
      ...updateState,
    };

    bookingCustomerUpdates.forEach((upd) => {
      const patchOperations = jsonPatcher.compareObjects(
        upd,
        {
          ...upd,
          status: 'ko',
          reviewDate: new Date(),
        },
        {},
      );
      if (patchOperations.length) {
        const patch: AppEntityUpdatePatch = {
          pk: upd._id,
          patchOperations,
        };
        updateStateLocal.bookingCustomerUpdatesChanges =
          changeDescriptorManager.updateOne(patch, {
            changeDescriptors:
              updateStateLocal.bookingCustomerUpdatesChanges ?? [],
          });
      }
    });

    setUpdateState(updateStateLocal, {
      action: 'deny customer updates',
      meta: {
        bookingId: booking._id,
      },
    });
  }, [booking._id, bookingCustomerUpdates, setUpdateState, updateState]);

  return bookingCustomerUpdates.length !== 0 ? (
    <AppMessage
      className="w-full my-5"
      type="warn"
      isExpanded={true}
      isExpandable={true}
      title={'Le client a renseigné de nouvelles informations'}
      message={
        <div className="max-w-2xl md:max-w-none">
          <h4 className="text-center font-bold uppercase">
            Modifications à valider
          </h4>
          <DiveCenterBookingMembersDiffList
            aggregatedBookingResume={bookingTabModel.aggregatedBooking}
            bookingMembersFull1={
              bookingTabModel.aggregatedBooking.bookingMembersFull
            }
            bookingMembersFull2={bookingMembersFullWithAutoFix}
            bookingCustomerUpdates={bookingCustomerUpdates}
            enableCustomerUpdate={false}
            onClick={(bookingMembersFull, options) => {
              // throw new Error('Function not implemented.');
            }}
          />
        </div>
      }
    >
      <div className="mt-5 flex gap-4 justify-between">
        <AppButton
          className="font-bold"
          style={{ minWidth: '6rem' }}
          size="normal"
          color={'primary-bg'}
          icon={AppHeroIcons.actionConfirm}
          onClick={async () => {
            const disableCustomerUpdate = await confirmDialog.confirmPromise({
              title: 'Informations enregistrées',
              message: 'Le client a-t-il complété toutes les informations ?',
              type: 'noYesInfo',
            });

            acceptCustomerUpdates({
              disableCustomerUpdate,
            });
          }}
        >
          Accepter les modifications
        </AppButton>
        <AppButton
          className="font-bold"
          style={{ minWidth: '6rem' }}
          size="normal"
          color={'danger-outline-light'}
          icon={AppIconsAction.delete}
          onClick={async () => {
            if (
              await confirmDialog.confirmPromise({
                title: 'Supprimer les modifications',
                message:
                  'Les modifications effectuées par le client seront annulées',
                type: 'noYesDanger',
              })
            ) {
              denyCustomerUpdates();
            }
          }}
        >
          Supprimer les modifications
        </AppButton>
      </div>
    </AppMessage>
  ) : null;
};
