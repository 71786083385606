import { AppCurrency } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';

export const AppPriceLabel = ({
  amount,
  mainCurrency,
  centsCountInput,
  addSpacesToLargeNumbers,
  preserveSignClass,
  centsClassName,
  signClassName,
  signColorClassName = 'text-gray-600',
  className = '',
}: {
  amount: number;
  mainCurrency: AppCurrency;
  centsCountInput?: number;
  addSpacesToLargeNumbers?: boolean;
  preserveSignClass?: boolean;
  centsClassName?: string;
  signClassName?: string;
  signColorClassName?: string;
  className?: string;
}) => {
  const signFontSize = preserveSignClass
    ? undefined
    : mainCurrency?.sign?.length > 1
    ? 'text-app-xxs'
    : 'text-xs';

  const integersString = useMemo(() => {
    if (amount === null || amount === undefined) {
      return null;
    }
    if (addSpacesToLargeNumbers) {
      return `${Math.floor(amount)}`.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
    }
    return Math.floor(amount);
  }, [addSpacesToLargeNumbers, amount]);

  const centsCount = centsCountInput ?? mainCurrency?.cents;

  const centsString = useMemo(() => {
    if (amount === null || amount === undefined) {
      return null;
    }
    return centsCount > 0
      ? `${(amount - Math.floor(amount)).toFixed(centsCount)}`.substring(2)
      : '';
  }, [amount, centsCount]);

  return mainCurrency && integersString !== null ? (
    <span className={`inline-block whitespace-nowrap ${className}`}>
      <span className="">{integersString}</span>
      <span className={centsClassName}>{centsString && `,${centsString}`}</span>
      <span
        className={
          signClassName
            ? signClassName
            : `${signFontSize} ml-0.5 text-bold ${
                preserveSignClass ? '' : signColorClassName
              }`
        }
      >
        {/* {isKiloDisplay && 'K'} */}
        {mainCurrency.sign}
      </span>
    </span>
  ) : null;
};
