/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, FormControl, FormLabel } from '@material-ui/core';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { CSSProperties, ReactNode, useCallback, useState } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export type AppFormControlV2BaseProps<T> = {
  required?: boolean;
  disabled?: boolean;
  value?: any;
  helpDescription?: string;
  theme?: 'default' | 'warn';
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};
export function AppFormControlV2<T>({
  label,
  labelClassName,
  renderComponent,
  className,
  required,
  disabled,
  style,
  theme,
  helpDescription,
  onClick,
}: AppFormControlV2BaseProps<T> & {
  label?: string;
  renderComponent: () => ReactNode;
  className?: string;
  labelClassName?: string;
  style?: CSSProperties;
}) {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(undefined);
  const toggleTooltip = useCallback(() => {
    if (helpDescription) {
      // une fois passé en mode "manuel" (activation au clic), il le reste
      setIsTooltipVisible(!isTooltipVisible);
    }
  }, [helpDescription, isTooltipVisible]);

  return (
    <div
      className={`flex flex-col ${className ?? ''}`}
      style={style}
      onClick={onClick}
    >
      <FormControl required={required} error={false}>
        <FormLabel
          className={'form-label pl-1'}
          component="legend"
          onClick={() => toggleTooltip()}
        >
          {label &&
            (helpDescription ? (
              <Tippy
                visible={isTooltipVisible}
                disabled={!helpDescription?.trim()}
                delay={[100, 100]}
                placement="top"
                content={helpDescription}
                onClickOutside={() => setIsTooltipVisible(false)}
              >
                <span
                  className={clsx(
                    'text-xs uppercase truncate',
                    disabled
                      ? 'text-gray-400 italic'
                      : theme === 'warn'
                      ? 'text-red-500 font-bold'
                      : null,
                  )}
                >
                  {label}
                  <AppHeroIcons.help className="inline-block ml-2 h-4 w-4 rounded-full bg-gray-500 text-white" />
                </span>
              </Tippy>
            ) : (
              <span
                className={clsx(
                  'text-xs uppercase truncate',
                  disabled ? 'text-gray-400 italic' : labelClassName,
                )}
              >
                {label}
              </span>
            ))}
        </FormLabel>
        <Box className="form-input">{renderComponent()}</Box>
      </FormControl>
    </div>
  );
}
