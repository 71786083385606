import { BookingCustomerUpdate } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import {
  PRO_BookingMemberFull,
  PRO_BookingResume,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingMembersListDiffItem } from './DiveCenterBookingMembersListDiffItem';
import {
  BookingResumeOnClickMemberAction,
  DiveCenterBookingMemberDiff,
  DiveCenterBookingMemberDiffChanges,
} from './model';

// Note: composant copié et adapté à partir de "DiveCenterBookingMembersList" sur l'espace plongeur
export function DiveCenterBookingMembersDiffList({
  enableCustomerUpdate,
  aggregatedBookingResume,
  bookingMembersFull1,
  bookingMembersFull2,
  bookingCustomerUpdates,
  onClick,
  className,
}: {
  enableCustomerUpdate: boolean;
  aggregatedBookingResume: PRO_BookingResume;
  bookingMembersFull1: PRO_BookingMemberFull[];
  bookingMembersFull2: PRO_BookingMemberFull[];
  bookingCustomerUpdates: BookingCustomerUpdate[];
  onClick: (
    memberDiff: DiveCenterBookingMemberDiff,
    options: {
      action: BookingResumeOnClickMemberAction;
      participantsIndex: number;
    },
  ) => any;
  className?: string;
}) {
  const membersDiff: DiveCenterBookingMemberDiff[] = useMemo(() => {
    const results: DiveCenterBookingMemberDiff[] = bookingMembersFull1
      .map((original) => {
        const final = bookingMembersFull2.find(
          (x) => x.diver?._id === original.diver?._id,
        );

        const changes: DiveCenterBookingMemberDiffChanges = {
          diversChanges: [],
          bookingMembersChanges: [],
        };
        bookingCustomerUpdates.forEach((upd) => {
          const updateState = upd.payload.updateState;
          if (updateState) {
            changes.diversChanges = changes.diversChanges.concat(
              updateState.diversChanges.filter(
                (x) => x.pk === original.diver._id,
              ),
            );
            changes.bookingMembersChanges =
              changes.bookingMembersChanges.concat(
                updateState.bookingMembersChanges.filter(
                  (x) => x.pk === original.bookingMember._id,
                ),
              );
          }
        });
        const hasChanges =
          changes.diversChanges.length + changes.bookingMembersChanges.length >
          0;
        const memberDiff: DiveCenterBookingMemberDiff = {
          status: final && hasChanges ? 'updated' : 'not-updated',
          original,
          final,
          changes, // TODO
        };
        return memberDiff;
      })
      .filter((x) => x.status !== 'not-updated');
    return results;
  }, [bookingCustomerUpdates, bookingMembersFull1, bookingMembersFull2]);

  return (
    <>
      <div
        className={`bg-white shadow overflow-hidden sm:rounded-md text-xs ${className}`}
      >
        <ul role="list" className="divide-y divide-gray-200">
          {membersDiff.map((memberDiff, participantsIndex) => (
            <DiveCenterBookingMembersListDiffItem
              key={`${memberDiff.original?.diver?._id}-${memberDiff.final?.diver?._id}`}
              enableCustomerUpdate={enableCustomerUpdate}
              aggregatedBookingResume={aggregatedBookingResume}
              memberDiff={memberDiff}
              onClick={(memberDiff, action) => {
                onClick && onClick(memberDiff, { action, participantsIndex });
              }}
            />
          ))}
        </ul>
      </div>
    </>
  );
}
