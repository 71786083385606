import React from 'react';
import { useClubResume, useDiveModeColor } from 'src/business/club/data/hooks';
import { DiveSessionParticipantsStats } from 'src/business/club/modules/club-dive-session/model';

export const DiveSessionSimpleCardTotalGroupsAndStaffCounters = ({
  diveSessionParticipantsStats: stats,
  showStaffCounts,
  context,
  className,
}: {
  diveSessionParticipantsStats: DiveSessionParticipantsStats;
  showStaffCounts: boolean;
  context: 'tiny' | 'large';
  className?: string;
}) => {
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const firstDiveDiveModeColor = useDiveModeColor('first-dive');
  const supervisedDiveModeColor = useDiveModeColor('supervised');
  const trainingDiveModeColor = useDiveModeColor('training');

  return (
    <div
      className={`w-full p-0.5 flex flex-wrap ${
        context === 'large'
          ? 'justify-between'
          : 'flex-col sm:flex-row sm:justify-between gap-y-0.5'
      } sm:items-center text-app-xxs sm:text-xs md:text-sm sm:leading-none md:leading-none ${className}`}
    >
      <div>
        {context === 'large' && (
          <div className="mb-0.5 uppercase text-xs font-bold text-gray-500">
            Palanquées
          </div>
        )}

        <div className="w-full flex justify-start gap-0.5">
          {stats.groups.firstDive > 0 && (
            <span
              className={`py-0.5 px-1 ${
                stats.realGroups ? '' : 'bg-opacity-50'
              } text-white font-bold`}
              style={{ backgroundColor: firstDiveDiveModeColor }}
            >
              {stats.groups.firstDive}{' '}
              {context === 'large' && (
                <span className="ml-0.5 hidden sm:inline text-xs">BPT</span>
              )}
            </span>
          )}
          {stats.groups.supervised +
            stats.groups.training +
            stats.groups.assignedFreeInstructors >
            0 && (
            <span
              className={`py-0.5 px-1 ${
                stats.realGroups ? '' : 'bg-opacity-50'
              } text-white font-bold`}
              style={{
                backgroundImage: `linear-gradient(to right, ${supervisedDiveModeColor}, ${trainingDiveModeColor})`,
              }}
            >
              {stats.groups.supervised +
                stats.groups.training +
                stats.groups.assignedFreeInstructors}{' '}
              {context === 'large' && (
                <span className="ml-0.5 hidden sm:inline text-xs">ENCAD.</span>
              )}
            </span>
          )}
          {stats.groups.autonomous > 0 && (
            <span
              className={`py-0.5 px-1 ${
                stats.realGroups ? '' : 'bg-opacity-50'
              } bg-dive-mode-autonomous text-white font-bold`}
            >
              {stats.groups.autonomous}
              {context === 'large' && (
                <span className="ml-0.5 hidden sm:inline text-xs">AUTON.</span>
              )}
            </span>
          )}
        </div>
      </div>

      {showStaffCounts && (
        <div className="text-right">
          {context === 'large' && (
            <div className="mb-0.5 uppercase text-xs font-bold text-gray-500">
              Moniteurs
            </div>
          )}

          <div className="w-full flex justify-end gap-0.5">
            {stats.staff.regularInstructors.total > 0 && (
              <span
                className={
                  'py-0.5 px-1 border-2 border-violet-400 text-violet-400 font-bold'
                }
              >
                {stats.staff.regularInstructors.assigned !==
                  stats.staff.regularInstructors.total && (
                  <span
                    className={
                      context === 'large'
                        ? 'hidden sm:inline'
                        : 'hidden lg:inline'
                    }
                  >
                    {stats.staff.regularInstructors.assigned}/
                  </span>
                )}
                {stats.staff.regularInstructors.total}
              </span>
            )}
            {stats.staff.casualInstructors.total > 0 && (
              <span
                className={
                  'py-0.5 px-1 border-2 border-pink-400 text-pink-400 font-bold'
                }
              >
                {stats.staff.casualInstructors.assigned !==
                  stats.staff.casualInstructors.total && (
                  <span
                    className={
                      context === 'large'
                        ? 'hidden sm:inline'
                        : 'hidden lg:inline'
                    }
                  >
                    {stats.staff.casualInstructors.assigned}/
                  </span>
                )}
                {stats.staff.casualInstructors.total}
              </span>
            )}{' '}
          </div>
        </div>
      )}
    </div>
  );
};
