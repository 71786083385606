import {
  AppEntityUpdatePatch,
  BookingProduct,
  BookingSessionParticipant,
  ClubParticipant,
  DiveSessionStatus,
  ParticipantActionDescription,
  ParticipantActionUser,
  ParticipantBookingStatus,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';

export const participantBookingStateUpdator = {
  generateBookingStatusUpdatePatchs,
};

function generateBookingStatusUpdatePatchs({
  participants,
  status,
  comment,
  user,
}: {
  participants: {
    clubParticipant: Pick<
      ClubParticipant,
      '_id' | 'bookingState' | 'bookingHistory'
    >;
    bookingProduct: Pick<
      BookingProduct,
      | '_id'
      | 'bookingProductStatus'
      | 'bookingProductState'
      | 'bookingProductHistory'
    >;
    bookingSessionParticipant: Pick<
      BookingSessionParticipant,
      '_id' | 'participantBookingStatus'
    >;
  }[];
  status: DiveSessionStatus;
  comment: string;
  user: ParticipantActionUser;
}): {
  updatedClubParticipants: AppEntityUpdatePatch[];
  updatedBookingProducts: AppEntityUpdatePatch[];
  updatedBookingSessionParticipants: AppEntityUpdatePatch[];
} {
  const bookingState: ParticipantActionDescription<ParticipantBookingStatus> = {
    date: new Date(),
    value: status === 'cancelled' ? 'cancelled' : 'confirmed',
    user,
    comment,
  };

  const participantsToUpdate = participants.filter(
    (p) =>
      (status === 'cancelled' &&
        p.clubParticipant.bookingState?.value !== 'cancelled') ||
      (status === 'on' &&
        p.clubParticipant.bookingState?.value === 'cancelled'),
  );

  const updatedClubParticipants: AppEntityUpdatePatch[] =
    participantsToUpdate.map((p) => {
      const { clubParticipant } = p;
      const patchOperations = jsonPatcher.compareObjects(
        { ...clubParticipant },
        {
          ...clubParticipant,
          bookingState,
          // state changed: save history
          bookingHistory: clubParticipant.bookingHistory.concat([bookingState]),
        },
        {
          attributesToReplaceFully: ['bookingState', 'bookingHistory'],
        },
      );
      const patch: AppEntityUpdatePatch = {
        pk: clubParticipant._id,
        patchOperations,
      };
      return patch;
    });
  const updatedBookingProducts: AppEntityUpdatePatch[] =
    participantsToUpdate.map((p) => {
      const { bookingProduct } = p;
      const patchOperations = jsonPatcher.compareObjects(
        { ...bookingProduct },
        {
          ...bookingProduct,
          bookingProductStatus: bookingState?.value,
          bookingProductState: bookingState,
          // state changed: save history
          bookingProductHistory: bookingProduct.bookingProductHistory.concat([
            bookingState,
          ]),
        },
        {
          attributesToReplaceFully: [
            'bookingProductState',
            'bookingProductHistory',
          ],
        },
      );
      const patch: AppEntityUpdatePatch = {
        pk: bookingProduct._id,
        patchOperations,
      };
      return patch;
    });

  const updatedBookingSessionParticipants: AppEntityUpdatePatch[] =
    participantsToUpdate.map((p) => {
      const { bookingSessionParticipant } = p;
      const patchOperations = jsonPatcher.compareObjects(
        { ...bookingSessionParticipant },
        {
          ...bookingSessionParticipant,
          participantBookingStatus: bookingState.value,
        },
        {},
      );
      const patch: AppEntityUpdatePatch = {
        pk: bookingSessionParticipant._id,
        patchOperations,
      };
      return patch;
    });

  return {
    updatedClubParticipants,
    updatedBookingProducts,
    updatedBookingSessionParticipants,
  };
}
