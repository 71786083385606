/* eslint-disable @typescript-eslint/no-unused-vars */
import { nameFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIconsSocialMedia } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { ParticipantDiverAgeLabel } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant';
import { CreateMessageToCustomersAggregatedRecipient } from '../model/CreateMessageToCustomersAggregatedRecipient.type';
import { useCreateMessageSendActionLink } from '../services';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';

export const CreateMessageToCustomersFormStep4_RecipientsTableRow = ({
  localState,
  recipient,
  className = '',
}: {
  localState: CreateMessageToCustomersLocalState;
  recipient: CreateMessageToCustomersAggregatedRecipient;
  className?: string;
}) => {
  const {
    state,
    data: { refDate, form, result, initialState },
    actions,
  } = localState;

  const securityUser = useAppSecurityUser();

  const diver = recipient?.formRecipient?.diver;
  const isBookingContact = recipient?.formRecipient?.isBookingContact;

  const sendActionLink = useCreateMessageSendActionLink({
    localState,
    recipient,
  });

  const [messageTarget] = useWatch({
    control: form.control,
    name: ['messageTarget'],
  });

  return (
    <AppButton
      className="w-full"
      fullWidth={true}
      size="normal"
      iconClassName="h-12 w-12 mr-4"
      icon={
        messageTarget === 'email'
          ? AppIconsSocialMedia.mail
          : messageTarget === 'whatsapp'
          ? AppIconsSocialMedia.whatsapp
          : messageTarget === 'sms'
          ? AppIconsSocialMedia.sms
          : undefined
      }
      color={
        messageTarget === 'email'
          ? 'mail-outline'
          : messageTarget === 'sms'
          ? 'phone-outline'
          : 'whatsapp-outline'
      }
      href={sendActionLink}
      target={
        messageTarget === 'email'
          ? 'mabadive_mail_client'
          : messageTarget === 'whatsapp'
          ? 'mabadive_whatsapp_client'
          : undefined
      }
      onClick={async () => {
        actions.persistMessageSchedule(recipient);
      }}
    >
      <div className={'w-full'}>
        <div className="text-gray-600">
          <div className="w-full flex-grow flex  whitespace-nowrap flex-wrap">
            {diver.lastName ? (
              <span className={'truncate font-bold mr-1'}>
                {nameFormatter.formatLastName(diver)}
              </span>
            ) : null}
            <span className={'truncate flex-shrink'}>
              {nameFormatter.formatFirstName(diver)}
            </span>
          </div>
          <div className="flex gap-4">
            <ParticipantDiverAgeLabel
              refDate={refDate}
              diver={diver}
              filter="none"
            />
            {isBookingContact && (
              <span className="uppercase px-0.5 text-app-orange font-medium text-app-xxs">
                contact résa
              </span>
            )}
          </div>
        </div>
        <div className="font-bold">
          {messageTarget === 'whatsapp' && '+'}
          {recipient.targetValidContact}
        </div>
      </div>
    </AppButton>
  );
};
