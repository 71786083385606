import { BookingAgency } from '@mabadive/app-common-model';
import { bookingAgencyFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

export const AgencyLabel = ({
  agencyId,
  showDetails,
  className,
}: {
  agencyId: string;
  showDetails?: boolean;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const agencies: BookingAgency[] = useMemo(
    () => diveCenterResume.clubResume.bookingAgencies ?? [],
    [diveCenterResume.clubResume.bookingAgencies],
  );

  const agency: BookingAgency = useMemo(
    () => (agencyId ? agencies.find((x) => x._id === agencyId) : undefined),
    [agencyId, agencies],
  );

  return !agency ? null : (
    <div
      className={clsx(
        'flex gap-1 items-center border border-gray-600 bg-white text-gray-600 px-pt',
        className,
      )}
    >
      <AppIcons.settings.customers.agencies
        className="w-6 h-3"
        style={{
          background: agency.details?.color,
          color: agency.details?.colorText,
        }}
      />
      <div className="flex gap-1">
        <span className="font-medium uppercase ">{agency.name}</span>
        {showDetails && agency.details.locationType && (
          <span className="font-normal text-gray-400">
            (
            {bookingAgencyFormatter.formatLocationType(
              agency.details.locationType,
            )}
            )
          </span>
        )}
      </div>
    </div>
  );
};
