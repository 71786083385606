import {
  AppDurationParserString,
  durationParser,
} from '@mabadive/app-common-services';
import { useCallback, useEffect, useState } from 'react';
import {
  LoadableContentActionStatus,
  LoadableContentState,
} from 'src/business/_core/data/app-loading';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { AppFetchDataCacheStore } from './appFetchDataCacheStoreBuilder.service';

export type AppFetchType = 'full' | 'partial' | 'not-updated';

export type AppFetchResult<R> = {
  data: R;
  dataModifiedAt: Date; // data updated at
  _fetchType: AppFetchType;
};

export type AppFetchStateMeta<R> = {
  initialFetch: Date;
  lastFullFetch: Date;
  lastPartialFetch: Date;
};

export type AppInnerCacheState<R> = {
  // données additionnelles
  fetchResult?: AppFetchResult<R>;
  meta: AppFetchStateMeta<R>;
  loadableContent: AppFetchedDataLoadableContent;
};

export type AppFetchedDataLoadableContent = {
  contentState: LoadableContentState;
  lastActionStatus: LoadableContentActionStatus;
  isLoading: boolean;
};

export type AppFetchedDataState<R> = {
  data: R;
  dataModifiedAt: Date; // date of last data change (data modification, NOT cache update)
  loadableContent: AppFetchedDataLoadableContent;
  meta: AppFetchStateMeta<R>;
  // _tanstackQuery: UseQueryResult<AppInnerCacheState<R>, unknown>;
};

export type UseAppFetchDataWithCacheOptions<FC, R> = {
  store: AppFetchDataCacheStore<FC, R>;
  autoRefetchInterval: AppDurationParserString;
  fetch: (
    criteria: FC,
    cacheState?: AppInnerCacheState<R>,
  ) => Promise<AppFetchResult<R>>;
};

const EMPTY_STATE: AppInnerCacheState<any> = {
  loadableContent: {
    contentState: 'none',
    isLoading: true,
    lastActionStatus: 'in-progress',
  },
  meta: {
    initialFetch: undefined,
    lastFullFetch: undefined,
    lastPartialFetch: undefined,
  },
  fetchResult: undefined,
};

export function useAppFetchDataWithCache<FC, R>(
  fetchCriteria: FC,
  options: UseAppFetchDataWithCacheOptions<FC, R>,
): AppFetchedDataState<R> {
  // TODO refactorer, react-query n'apporte plus grand chose étant donné le niveau de customization

  const [cacheState, setCacheState] =
    useState<AppInnerCacheState<R>>(EMPTY_STATE);

  const fetchDataAndUpdateState = useCallback(
    async ({
      fetchCriteria,
      options,
      cacheState,
    }: {
      fetchCriteria: FC;
      options: UseAppFetchDataWithCacheOptions<FC, R>;
      cacheState: AppInnerCacheState<R>;
    }) => {
      const cacheStateFromFetch = (await fetchData<FC, R>({
        options,
        fetchCriteria,
        cacheState,
      })) as unknown as AppInnerCacheState<R>; // FIXME: la version utilisée de cra+babel est incompatible avec le typage de ReturnType dans appFetchDataCacheStoreBuilder;
      if (cacheStateFromFetch) {
        await options.store.set(fetchCriteria, cacheStateFromFetch);
        setCacheState(cacheStateFromFetch);
      }
      return cacheStateFromFetch;
    },
    [],
  );

  useEffect(() => {
    let handler: NodeJS.Timeout;

    (async () => {
      // manage initial fetch from cache, api, then refresh from api

      // load initial value from cache
      let cacheState = (await options.store.get(
        fetchCriteria,
      )) as unknown as AppInnerCacheState<R>; // FIXME: la version utilisée de cra+babel est incompatible avec le typage de ReturnType dans appFetchDataCacheStoreBuilder
      if (cacheState) {
        setCacheState(cacheState);
      } else {
        setCacheState(EMPTY_STATE);
      }

      // initial fetch (full or partial, depending of cache state and options.fetch implementation)
      cacheState = await fetchDataAndUpdateState({
        fetchCriteria,
        options,
        cacheState,
      });

      if (options.autoRefetchInterval) {
        const timeout = durationParser.parseTimestamp(
          options.autoRefetchInterval,
        ); // refresh periodically
        handler = setInterval(async () => {
          // refresh
          cacheState = await fetchDataAndUpdateState({
            fetchCriteria,
            options,
            cacheState,
          });
        }, timeout);
      }
    })();

    return () => {
      // TODO annulation de la requête précédente
      if (handler) {
        clearInterval(handler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCriteria]);

  // const queryClient = useQueryClient();

  // const query: UseQueryResult<AppInnerCacheState<R>, unknown> = useQuery({
  //   queryKey: [options.baseKey, fetchCriteria],
  //   queryFn: async ({ queryKey }) => {
  //     const now = new Date();

  //     const cacheState: AppInnerCacheState<R> =
  //       queryClient.getQueryData<AppInnerCacheState<R>>(queryKey);

  //     const fetchResult: AppFetchResult<R> = await options.fetch(
  //       fetchCriteria,
  //       cacheState,
  //     );

  //     if (cacheState && fetchResult._fetchType === 'not-updated') {
  //       return cacheState;
  //     }

  //     if (fetchResult) {
  //       if (cacheState) {
  //         const isFullFetch = fetchResult._fetchType === 'full';
  //         // update cache state
  //         const meta: AppFetchStateMeta<R> = {
  //           ...cacheState.meta,
  //           lastFullFetch: isFullFetch ? now : cacheState.meta.lastFullFetch,
  //           lastPartialFetch: !isFullFetch
  //             ? now
  //             : cacheState.meta.lastPartialFetch,
  //         };
  //         const updatedAppCacheState: AppInnerCacheState<R> = {
  //           ...cacheState,
  //           fetchResult,
  //           meta,
  //         };
  //         return updatedAppCacheState;
  //       } else {
  //         // initial fetch
  //         const meta: AppFetchStateMeta<R> = {
  //           initialFetch: now,
  //           lastFullFetch: now,
  //           lastPartialFetch: undefined,
  //         };
  //         const initialAppCacheState: AppInnerCacheState<R> = {
  //           fetchResult,
  //           meta,
  //         };
  //         return initialAppCacheState;
  //       }
  //     }

  //     return cacheState;
  //   },
  //   gcTime: 1000 * 60 * 60 * 24 * options.cacheValidityInDays, // durée de conservation dans le cache, qu'on l'utilise ou non
  //   retry: 3,
  //   // retryDelay is set to double (starting at 1000ms) with each attempt, but not exceed 30 seconds
  //   staleTime: 0, // au chargement, on rafraichi systématiquement les données (full ou partiel)
  //   refetchInterval: options.autoRefetchIntervalInSeconds * 1000, // toutes les 60s (update partiel)
  // });

  // const cacheState = query.data;

  const state: AppFetchedDataState<R> = {
    data: cacheState?.fetchResult?.data,
    loadableContent: cacheState?.loadableContent,
    dataModifiedAt: cacheState?.fetchResult?.dataModifiedAt,
    meta: cacheState?.meta,
  };
  return state;
}

async function fetchData<FC, R>({
  options,
  fetchCriteria,
  cacheState,
}: {
  options: UseAppFetchDataWithCacheOptions<FC, R>;
  fetchCriteria: FC;
  cacheState: AppInnerCacheState<R>;
}): Promise<AppInnerCacheState<R>> {
  const now = new Date();
  try {
    const fetchResult: AppFetchResult<R> = await options.fetch(
      fetchCriteria,
      cacheState,
    );

    if (cacheState && fetchResult._fetchType === 'not-updated') {
      return cacheState;
    }

    if (fetchResult) {
      if (cacheState) {
        const isFullFetch = fetchResult._fetchType === 'full';
        // update cache state
        const meta: AppFetchStateMeta<R> = {
          ...cacheState.meta,
          lastFullFetch: isFullFetch ? now : cacheState.meta.lastFullFetch,
          lastPartialFetch: !isFullFetch
            ? now
            : cacheState.meta.lastPartialFetch,
        };
        const updatedAppCacheState: AppInnerCacheState<R> = {
          ...cacheState,
          fetchResult,
          meta,
          loadableContent: {
            contentState: 'full',
            lastActionStatus: 'success',
            isLoading: false,
          },
        };
        return updatedAppCacheState;
      } else {
        // initial fetch
        const meta: AppFetchStateMeta<R> = {
          initialFetch: now,
          lastFullFetch: now,
          lastPartialFetch: undefined,
        };
        const initialAppCacheState: AppInnerCacheState<R> = {
          fetchResult,
          meta,
          loadableContent: {
            contentState: 'full',
            lastActionStatus: 'success',
            isLoading: false,
          },
        };
        return initialAppCacheState;
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    appLogger.error('Unexpected fetch error');
    const updatedAppCacheState: AppInnerCacheState<R> = {
      fetchResult: null,
      meta: cacheState?.meta,
      loadableContent: {
        contentState: cacheState?.loadableContent?.contentState ?? 'full',
        lastActionStatus: 'error',
        isLoading: false,
      },
    };
    return updatedAppCacheState;
  }
}
