/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import {
  AppIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureListItem } from 'src/business/club/modules/_common/ui';
import {
  ClubDialogsProvider,
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

import useRouter from 'use-react-router';

export const ClubSettingsCustomerSpaceConfigPage = () => {
  const { match } = useRouter();

  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const openCustomerSpaceConfigDialog = useCallback(() => {
    dialogsState.customerSpaceConfigDialog.openDialog({
      defaultValue: {
        clubSettings,
      },
    });
  }, [clubSettings, dialogsState.customerSpaceConfigDialog]);

  return (
    <AppPageContainer className={'bg-white'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.customers.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.customers.main,
            label: 'Clients',
            url: '/club/settings',
          },
          {
            label: 'Espace client',
          },
        ]}
      />
      <ClubDialogsProvider dialogsState={dialogsState}>
        <AppPageContentContainer
          paddingBottom={false}
          className="bg-gray-50 app-p-content"
        >
          <div
            className={clsx('app-card p-4 app-card-highlight', 'mx-2')}
            onClick={() => {
              openCustomerSpaceConfigDialog();
            }}
          >
            <h2 className="my-3 mx-2 text-left uppercase text-lg leading-6 py-2 font-medium text-gray-800">
              Espace client
            </h2>
            <div className="">
              <ul className="relative grid gap-6 p-2 sm:gap-4 sm:px-4 font-bold text-gray-600">
                <AppFeatureListItem
                  enabled={clubSettings.publicSettings?.customerSpace?.enabled}
                  label="Espace client"
                />
                <AppFeatureListItem
                  enabled={
                    clubSettings.publicSettings?.customerSpace?.features
                      ?.insurance?.enabled
                  }
                  label="Assurance"
                />
                <AppFeatureListItem
                  enabled={
                    clubSettings.publicSettings?.customerSpace?.features
                      ?.newsletter?.enabled
                  }
                  label="Newsletter"
                />
                <AppFeatureListItem
                  enabled={
                    clubSettings.publicSettings?.customerSpace?.consents?.gdpr
                      ?.enabled
                  }
                  label="RGPD"
                />
                <AppFeatureListItem
                  enabled={
                    clubSettings.publicSettings?.customerSpace?.consents
                      ?.padSignature?.enabled
                  }
                  label="Signature manuscrite"
                />
              </ul>
              <div className="my-4">
                <AppButton
                  color={'primary-outline-light'}
                  size="normal"
                  icon={AppIcons.action?.edit}
                  onClick={(e) => {
                    e.stopPropagation();
                    openCustomerSpaceConfigDialog();
                  }}
                >
                  Modifier
                </AppButton>
              </div>
            </div>
          </div>
        </AppPageContentContainer>
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
