import { Popover, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppEmojiIcon } from './AppEmojiIcon';
import { AppEmojiPicker, AppEmojiPickerProps } from './AppEmojiPicker';

export function AppEmojiPickerPopover({
  className,
  ...emojiPickerProps
}: {
  className?: string;
} & AppEmojiPickerProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLAnchorElement | HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  //   const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  // const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  // const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)

  const buttonEmojiId = emojiPickerProps.value;

  return (
    <Popover as="nav" className={`inline-block ${className}`}>
      {({ open }) => (
        <>
          <div className="flex gap-1 items-center">
            <AppButton
              type="button"
              className={
                'px-1 inline-flex items-center justify-center focus:outline-none'
              }
              setReferenceElement={setReferenceElement}
              color="primary-outline-light"
              size={'hamburger'}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <div className="flex gap-4 items-center py-1">
                <span className="text-xs font-bold uppercase">
                  {buttonEmojiId ? 'Changer' : 'Choisir'}
                </span>
                {buttonEmojiId && <AppEmojiIcon id={buttonEmojiId} size={16} />}
              </div>
            </AppButton>
            {buttonEmojiId && (
              <AppButton
                type="button"
                className={'px-0.5 py-1'}
                setReferenceElement={setReferenceElement}
                color="danger-outline-light"
                size={'hamburger'}
                onClick={() => {
                  emojiPickerProps &&
                    emojiPickerProps.onChange(undefined, undefined);
                  setIsOpen(false);
                }}
              >
                <AppHeroIcons.actionDelete className="w-4 h-4" />
              </AppButton>
            )}
          </div>

          <Transition show={isOpen}>
            <Popover.Overlay
              style={{
                zIndex: 1500, // > .makeStyles-drawerPaper-33 (left menu material-ui)
              }}
              className={`${
                isOpen
                  ? 'opacity-30 fixed top-0 bottom-0 left-0 right-0'
                  : 'opacity-0'
              } bg-black`}
            >
              <div
                className="fixed top-0 bottom-0 left-0 right-0"
                onClick={() => {
                  setIsOpen(false);
                }}
              ></div>
            </Popover.Overlay>
            <Popover.Panel
              static
              // tabIndex={-1}
              ref={setPopperElement}
              style={{
                ...(styles.popper ?? {}),
                zIndex: 1500, // > .makeStyles-drawerPaper-33 (left menu material-ui)
              }}
              {...(attributes.popper ?? {})}
              className={`
                max-w-screen-sm
                ${attributes.popper?.className ?? ''}
                px-1 sm:px-0
              `}
            >
              <AppHeroIcons.actionClose
                className={
                  'absolute cursor-pointer mr-1 sm:mr-0 p-2 top-0 -right-10 z-50 w-8 h-8 sm:w-10 sm:h-10 bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white'
                }
                aria-hidden="true"
                onClick={() => {
                  setIsOpen(false);
                }}
              />
              <div
                className={
                  'text-left border border-gray-400 shadow-lg ring-1 ring-gray-600 ring-opacity-20 overflow-x-hidden overflow-y-auto bg-white'
                }
              >
                <AppEmojiPicker
                  className="z-50"
                  {...emojiPickerProps}
                  onChange={(emojiId, emojiData) => {
                    setIsOpen(false);
                    emojiPickerProps.onChange(emojiId, emojiData);
                  }}
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
