import { AccommodationPickup } from '@mabadive/app-common-model';
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';

export const AccommodationPickupCard = ({
  isResaFlottante,
  accommodationPickup,
  mode,
  className,
}: {
  isResaFlottante: boolean;
  accommodationPickup: AccommodationPickup;
  mode: 'list' | 'resume';
  className?: string;
}) => {
  const { accommodation, participants, pickupTime } = accommodationPickup;

  return (
    <div className={clsx('flex flex-col gap-1', className)}>
      <Tippy
        disabled={mode === 'list'}
        delay={[100, 100]}
        placement="left"
        offset={[50, -150]}
        content={
          mode === 'list' ? null : (
            <AccommodationPickupCard
              accommodationPickup={accommodationPickup}
              mode="list"
              isResaFlottante={isResaFlottante}
            />
          )
        }
      >
        <div className={'flex flex-wrap gap-1 items-center'}>
          <AppIconsSettings.customers.pickup
            className="w-6 h-4 border border-gray-600 py-px px-pt"
            style={{
              background: accommodation.color,
              color: accommodation.colorText,
            }}
          />
          {!isResaFlottante && (
            <span className="font-medium">
              {dateService.formatUTC(pickupTime, 'HH:mm')}
            </span>
          )}
          <span className="font-medium whitespace-nowrap">
            {accommodation.name}
          </span>
          <span className="text-gray-500 whitespace-nowrap text-sm">
            ({participants.length} personne
            {participants.length === 1 ? '' : 's'})
          </span>
        </div>
      </Tippy>
      {mode === 'list' && (
        <ul className={'list-disc ml-3 text-xs'}>
          {participants.map((p, i) => (
            <li className="" key={i}>
              {nameFormatter.formatFullName(p.diver)}
              {p.dailySessions?.length > 1 && (
                <>
                  {' '}
                  (
                  {p.dailySessions
                    .map((x) => dateService.formatUTC(x.time, 'HH:mm'))
                    .join(', ')}
                  )
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
