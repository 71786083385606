/* eslint-disable @typescript-eslint/no-unused-vars */
import { EcommerceProductArticleGql_Company } from '@mabadive/app-common-model';
import React from 'react';

export const ProProductsPage_ProductArticleParticipantsCount = ({
  className,
  productArticle,
}: {
  productArticle: EcommerceProductArticleGql_Company;

  className?: string;
}) => {
  const min = productArticle?.details?.minParticipantsCount;
  const max = productArticle?.details?.maxParticipantsCount;
  const showParticipantsCount = min !== 1 || max !== 1;

  return showParticipantsCount ? (
    <div className={className}>
      {min === max ? (
        <>{max} participants</>
      ) : (
        <>
          {min}-{max} participants
        </>
      )}
    </div>
  ) : null;
};
