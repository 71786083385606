import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppButton } from '../../../../../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../../../../../business/_core/modules/layout/icons';
import { EcommerceCategoryEditorLocalState } from '../useEcommerceCategoryEditorPanelLocalState.hook';

export const EcommerceCategoryEditorFormSettings = ({
  localState,
  className = '',
}: {
  localState: EcommerceCategoryEditorLocalState;
  className?: string;
}) => {
  const {
    state: { form },
  } = localState;
  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    formState,
    setValue,
  } = form;
  const [bookletPage, categoryName] = useWatch({
    control,
    name: ['appBookletPage', 'category.name'],
  });

  const createBooklet = useCallback(() => {
    setValue('appBookletPage', {
      mainContent: {
        languageCode: 'fr',
        content: {
          title: categoryName,
          items: [],
        },
        cover: {},
      },
    });
  }, [categoryName, setValue]);
  return (
    <div className={clsx('grid grid-cols-1 gap-4', className)}>
      <h3 className="mx-1 mt-6 mb-2 text-sm font-bold text-app-primary uppercase">
        {'Paramètres'}
      </h3>
      <div className={'flex flex-wrap gap-y-4 gap-x-4'}>
        {/* <AppFormControlRHF
          label="Nom (interface PRO)"
          helpDescription="Nom utilisé sur l'interface PRO uniquement"
          control={control}
          name={'category.name'}
          validation={{
            rules: {
              maxLength: 30,
            },
          }}
          required={true}
          renderComponent={(props) => <AppInputRHF {...props} type="text" />}
        /> */}
        <AppFormControlRHF
          control={control}
          label={'Activé'}
          name="category.enabled"
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        <AppFormControlRHF
          control={control}
          label={'Age mini'}
          name="category.details.minAge"
          validation={{
            rules: {
              min: 1,
            },
          }}
          renderComponent={(props) => <AppInputRHF type="number" {...props} />}
        />
        <AppFormControlRHF
          control={control}
          label={'Age maxi'}
          name="category.details.maxAge"
          validation={{
            rules: {
              min: 1,
            },
          }}
          renderComponent={(props) => <AppInputRHF type="number" {...props} />}
        />

        {/* <AppFormControlRHF_Deprecated
        className={'sm:col-span-4'}
        label={`Couleur`}
        required={true}
        control={control}
        name={'ecommerceCategory.content.color'}
        renderComponent={(props) => <AppInputColorRHF {...props} />}
      />  */}
      </div>
      <div className="flex gap-4">
        {!bookletPage && (
          <AppButton
            size="normal"
            color="gray-outline-light"
            icon={AppIconsAction.edit}
            onClick={(e) => {
              e.stopPropagation();
              createBooklet();
            }}
          >
            {'Ajouter une description'}
          </AppButton>
        )}
      </div>
    </div>
  );
};
