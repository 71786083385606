/* eslint-disable @typescript-eslint/no-unused-vars */
import { CompanyAccommodation } from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';

import React, { useMemo } from 'react';
import { CustomerAccomodationsTableRow } from './CustomerAccomodationsTableRow';

export const CustomerAccomodationsTable = ({
  accommodations,
  onClickCompanyAccommodation,
}: {
  accommodations: CompanyAccommodation[];
  onClickCompanyAccommodation?: (accommodationId: string) => void;
}) => {
  const orderedCompanyAccommodations = useMemo(
    () =>
      dataSorter.sortMultiple(accommodations, {
        getSortAttributes: (x) => [
          {
            value: x.pickup?.enabled,
            asc: false,
          },
          {
            value: x.pickup?.enabled ? x.pickup?.offsetInMinutes : 0,
          },
          {
            value: x.name,
          },
          {
            value: x._id,
          },
        ],
        asc: true,
      }),
    [accommodations],
  );

  function clickCompanyAccommodation(accommodationId: string) {
    if (onClickCompanyAccommodation) {
      onClickCompanyAccommodation(accommodationId);
    }
  }

  return (
    <table className={'w-full divide-y divide-gray-200 text-gray-600'}>
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Nom
          </th>
          <th
            scope="col"
            className="hidden xs:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Pickup
          </th>
          <th
            scope="col"
            className="hidden lg:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Commentaire
          </th>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          ></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {orderedCompanyAccommodations.map((accommodation, i) => (
          <CustomerAccomodationsTableRow
            key={i}
            accommodation={accommodation}
            onClick={() => clickCompanyAccommodation(accommodation._id)}
          />
        ))}
      </tbody>
    </table>
  );
};
