import React, { FC } from 'react';
import { AppFixedButtonsBar } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export const BookingParticipantEditorDialogFixedButtonsBar: FC<{
  onSubmit: () => void;
  onClose: () => void;
  hasChanges: boolean;
  isValid: boolean;
  className?: string;
  noBackground?: boolean;
}> = ({ onSubmit, onClose, hasChanges, isValid, className, noBackground }) => {
  return (
    <AppFixedButtonsBar
      noBackground={noBackground}
      className={className}
      hasChanges={true}
    >
      <>
        <AppButton
          size="normal"
          icon={AppHeroIcons.actionCancel}
          color={hasChanges ? 'danger-outline-light' : 'gray-outline-light'}
          tabIndex={500}
          onClick={() => {
            onClose();
          }}
        >
          <span className="font-bold">
            {hasChanges ? 'Annuler les changements' : 'Retour'}
          </span>
        </AppButton>

        <AppButton
          size="normal"
          icon={AppHeroIcons.check}
          color="primary-outline-light"
          disabled={!isValid}
          onClick={() => {
            isValid && onSubmit();
          }}
        >
          <span className="font-bold">Valider</span>
        </AppButton>
      </>
    </AppFixedButtonsBar>
  );
};
