/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubSettingsPlanning,
  ClubSettingsPlanningPeriodConfig,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppHeroIcons,
  AppIconsAction,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { DateRangeLabel } from 'src/pages/_components';
import { clubSettingsDiveCenterUrlBuilder } from '../../clubSettingsDiveCenterUrlBuilder.service';
import { ClubSettingsPlanningViewWeek } from './ClubSettingsPlanningViewWeek';
import { ClubSettingsPlanningViewPageLocalState } from './useClubSettingsPlanningViewPageLocalState.hook';

export const ClubSettingsPlanningDefaultViewCard = ({
  localState,
  periodConfig,
  className,
}: {
  localState: ClubSettingsPlanningViewPageLocalState;
  periodConfig: ClubSettingsPlanningPeriodConfig;
  className?: string;
}) => {
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume?.clubResume?.clubSettings;

  const clubSettingsPlanning = diveCenterResume.settingsPlanning;
  const periodConfigId = periodConfig._id;

  const editConfigHref = useMemo(
    () =>
      clubSettingsDiveCenterUrlBuilder.planning.edit({
        periodConfigId,
      }),
    [periodConfigId],
  );
  const editSessionBookingConfigHref = useMemo(
    () =>
      clubSettingsDiveCenterUrlBuilder.planning.editSessionBooking({
        periodConfigId,
      }),
    [periodConfigId],
  );

  const az = useAppSecurityUserClubAuthorizations();
  const azEditSettings = az.edit.settings;
  const hasRoleEditDiveCenter = azEditSettings.diveCenter;

  const EditConfigConditionalLink: any = hasRoleEditDiveCenter
    ? Link
    : ({
        className,
        children,
      }: {
        className?: string;
        children: React.ReactNode;
      }) => <div className={className}>{children}</div>;

  return (
    <div className={clsx('app-card app-p-content', className)}>
      <div className="flex flex-col md:flex-row gap-4 items-start">
        <EditConfigConditionalLink
          to={editConfigHref}
          className={clsx(
            'flex-grow group/config-title',
            hasRoleEditDiveCenter && 'cursor-pointer',
          )}
        >
          <h2 className="flex-grow text-left uppercase text-base md:text-lg leading-6 font-normal text-gray-600">
            <div className="font-bold text-app-primary-light">
              {hasRoleEditDiveCenter && (
                <AppHeroIcons.actionEditAlt className="inline mr-2 text-gray-400 group-hover/config-title:text-gray-600 h-6 w-6" />
              )}
              {periodConfig?.name}
            </div>
            <DateRangeLabel
              className="text-sm"
              beginDate={periodConfig?.beginDate}
              endDate={periodConfig?.endDate}
            />
          </h2>
        </EditConfigConditionalLink>
        <div className="flex gap-4 justify-end">
          <AppButton
            color="primary-outline-light"
            icon={AppIconsAction.generate}
            href={clubSettingsDiveCenterUrlBuilder.planning.assistant({
              periodConfigId,
            })}
          >
            Ajouter des sessions
          </AppButton>
          <AppButton
            color="primary-outline-light"
            icon={AppIconsAction.edit}
            href={editConfigHref}
          >
            Modifier
          </AppButton>

          <AppButton
            color="gray-outline-light"
            icon={AppIconsAction.clone}
            href={clubSettingsDiveCenterUrlBuilder.planning.duplicate({
              periodConfigId,
            })}
          >
            Dupliquer
          </AppButton>
          {clubSettingsPlanning.periodConfigs.length !== 1 && (
            <AppButton
              color="danger-outline-light"
              icon={AppIconsAction.delete}
              onClick={async () => {
                if (
                  await confirmDialog.confirmPromise({
                    title: 'Supprimer la configuration',
                    message:
                      'Êtes-vous sûr de vouloir supprimer cette configuration ?',
                    type: 'noYesDanger',
                  })
                ) {
                  const updatedClubSettingsPlanning: ClubSettingsPlanning = {
                    ...diveCenterResume.settingsPlanning,
                    periodConfigs:
                      diveCenterResume.settingsPlanning.periodConfigs.filter(
                        (c) => c._id !== periodConfigId,
                      ),
                  };

                  const ClubSettingsPlanningPatchOperations =
                    jsonPatcher.compareObjects(
                      diveCenterResume.settingsPlanning,
                      updatedClubSettingsPlanning,
                      {
                        replaceDeleteByNullValue: true,
                        attributesToReplaceFully: ['periodConfigs'],
                      },
                    );
                  if (ClubSettingsPlanningPatchOperations.length) {
                    const clubSettingsPlanningPatch: AppEntityUpdatePatch = {
                      pk: updatedClubSettingsPlanning._id,
                      patchOperations: ClubSettingsPlanningPatchOperations,
                    };
                    await clubMassiveUpdatorClient.update({
                      clubSettingsPlanning: {
                        updated: clubSettingsPlanningPatch,
                      },
                    });
                  }
                }
              }}
            >
              Supprimer
            </AppButton>
          )}
        </div>
      </div>
      <>
        <div className={'app-my-content'}>
          <ClubSettingsPlanningViewWeek
            className="my-1"
            onClickHeader={() => {
              redirectTo(
                clubSettingsDiveCenterUrlBuilder.planning.edit({
                  periodConfigId,
                }),
              );
            }}
            onClickTour={() => {
              redirectTo(
                clubSettingsDiveCenterUrlBuilder.planning.edit({
                  periodConfigId,
                }),
              );
            }}
            periodConfig={periodConfig}
          />
        </div>
      </>
      {clubSettings?.general?.onlineBooking?.sessionConfig?.enabled &&
        (clubSettings?.general?.onlineBooking?.sessionConfig?.firstDive
          .enabled ||
          clubSettings?.general?.onlineBooking?.sessionConfig
            ?.snorkelingSupervised.enabled) && (
          <div className="flex gap-4 justify-start">
            <AppButton
              color="primary-outline-light"
              icon={EntitiesIcons.order}
              href={editSessionBookingConfigHref}
            >
              Configurer la réservation automatique
            </AppButton>
          </div>
        )}
    </div>
  );
};
