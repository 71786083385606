import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import {
  DiverBookingPageUpdateState,
  DiverPurchasePaymentEditorDialogResult,
} from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
export function useDiverBookingPageUpdatePurchasePayment({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    ({
      updatedClubPurchasePayment,
      updatedClubPurchasePackages,
      updatedBookingDeposits,
    }: Pick<
      DiverPurchasePaymentEditorDialogResult,
      | 'updatedClubPurchasePayment'
      | 'updatedClubPurchasePackages'
      | 'updatedBookingDeposits'
    >) => {
      let updateStateLocal = updateState;

      if (updatedClubPurchasePayment) {
        const purchasePaymentsChanges = changeDescriptorManager.updateOne(
          updatedClubPurchasePayment,
          {
            changeDescriptors: updateStateLocal.purchasePaymentsChanges,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          purchasePaymentsChanges,
        };
      }
      if (updatedClubPurchasePackages?.length > 0) {
        const purchasePackagesChanges = changeDescriptorManager.updateMany(
          updatedClubPurchasePackages,
          {
            changeDescriptors: updateStateLocal.purchasePackagesChanges,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          purchasePackagesChanges,
        };
      }
      if (updatedBookingDeposits?.length > 0) {
        const bookingDepositsChanges = changeDescriptorManager.updateMany(
          updatedBookingDeposits,
          {
            changeDescriptors: updateStateLocal.bookingDepositsChanges,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          bookingDepositsChanges,
        };
      }

      setUpdateState(updateStateLocal, {
        action: 'bookingPurchasePaymentEdit',
      });
    },
    [setUpdateState, updateState],
  );
}
