/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPublicData,
  ClubResumeAdmin,
  ClubSettingsGeneral,
  DEFAULT_APP_UI_THEME,
  RegionalSettings,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { AppUiThemeTextCard } from 'src/business/_core/modules/layout';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import { AdminCompaniesListPageLocalState } from '../useAdminCompaniesListPageLocalState';

export const ClubResumeAdminCardCompanyBrand = ({
  localState,
  clubResumeAdmin: club,
}: {
  clubResumeAdmin: ClubResumeAdmin;
  localState: AdminCompaniesListPageLocalState;
}) => {
  const { filters } = localState;
  const { showDetails } = filters;
  const clubPublicData: ClubPublicData = club.clubSettings?.publicData;
  const generalSettings: ClubSettingsGeneral = club.clubSettings?.general;

  const clubBrand = useMemo(
    () => ({
      name: clubPublicData?.brand?.name,
      locationResume: clubPublicData?.brand?.locationResume,
    }),
    [clubPublicData?.brand?.locationResume, clubPublicData?.brand?.name],
  );

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
    countryIsoCode: generalSettings?.countryIsoCode,
    generalSettings,
  });

  const mainCurrency = useAppCurrencyMain({ generalSettings });

  return (
    <div className={'text-left'}>
      <AppUiThemeTextCard
        className="text-lg font-bold leading-4 uppercase"
        theme={DEFAULT_APP_UI_THEME.title1}
      >
        {clubBrand.name}
      </AppUiThemeTextCard>
      <AppUiThemeTextCard
        className="text-base leading-normal uppercase"
        theme={DEFAULT_APP_UI_THEME.title2}
      >
        {clubBrand.locationResume}
      </AppUiThemeTextCard>
      {showDetails && (
        <div className="text-gray-600 text-xs">
          <div>{club.city}</div>
          <div>{club.country}</div>
          <div className="text-app-primary font-medium">
            {regionalSettings?.countryLabel}{' '}
            {generalSettings?.timeZoneName && (
              <span className="text-gray-600">
                ({generalSettings?.timeZoneName})
              </span>
            )}
          </div>
          {mainCurrency && (
            <div className="text-app-primary font-medium">
              {mainCurrency.label}{' '}
              <span className="text-app-xxs">({mainCurrency.sign})</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
