import { OnlineBookingState } from '@mabadive/app-common-model';
import { onlineBookingStateFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';

export const OrderStatusLabel = ({
  state,
  fontSizeClass = 'text-xs',
}: {
  state: OnlineBookingState;
  fontSizeClass?: string;
}) => {
  return (
    <span
      className={clsx(
        'mr-2 sm:mr-4 md:mr-5 px-1 font-bold',
        fontSizeClass,
        state === 'draft'
          ? 'bg-gray-600 text-white'
          : state === 'new'
          ? 'bg-status-success text-white'
          : state === 'confirmed'
          ? 'bg-status-info text-white'
          : state === 'pending-customer' || state === 'pending-club'
          ? 'bg-status-warn text-white'
          : 'bg-gray-200 text-white',
        // : 'bg-gray-600 text-white',
      )}
    >
      {onlineBookingStateFormatter.formatState(state)?.toUpperCase()}
    </span>
  );
};
