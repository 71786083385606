/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppInquiry } from '@mabadive/app-common-model';
import { useCallback, useState } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import useRouter from 'use-react-router';
import { clubSettingsCustomersUrlBuilder } from '../../clubSettingsCustomersUrlBuilder.service';
import { useFetchInquiries } from '../_services';

export function useClubSettingsInquiryEditPageLocalState({
  mode,
}: {
  mode: 'create' | 'edit';
}) {
  const { match } =
    useRouter<{
      inquiryId: string;
    }>();
  const [operationPending, setOperationPending] = useState(false);

  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();

  const { status, fetchedData } = useFetchInquiries();

  const inquiries: AppInquiry[] = fetchedData?.inquiries ?? [];

  const initialValue: AppInquiry =
    mode === 'create'
      ? undefined
      : inquiries.find((x) => x._id === match.params.inquiryId);

  const back = useCallback(() => {
    return redirectTo(clubSettingsCustomersUrlBuilder.inquiries.list());
  }, [redirectTo]);

  return {
    state: {
      operationPending,
      fetchStatus: status,
      mode,
    },
    data: {
      initialValue,
    },
    actions: {
      back,
    },
  };
}

export type ClubSettingsInquiryEditPageLocalState = ReturnType<
  typeof useClubSettingsInquiryEditPageLocalState
>;
