/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { PlanningViewConfigPeriod } from '../../club-planning/_model';

export const ClubPlanningLightPanelViewPeriodMonthWeekDayResumeTitle = ({
  date,
  viewPeriod,
  theme,
  className,
  onClick,
}: {
  date: Date;
  viewPeriod: PlanningViewConfigPeriod;
  theme?: 'default' | 'today';
  className?: string;
  onClick?: () => void;
}) => {
  const builtTheme = useMemo(
    () => theme ?? (dateService.isTodayUTC(date) ? 'today' : 'default'),
    [date, theme],
  );
  const isPast = useMemo(
    () => !dateService.isTodayUTC(date) && dateService.isPastUTC(date),
    [date],
  );
  return (
    <div
      className={clsx(
        'text-center bg-white',
        onClick && 'cursor-pointer',
        className,
      )}
      onClick={onClick}
    >
      <div
        className={clsx(
          'rounded-full inline-flex justify-center items-center font-bold',
          'h-5 w-5 sm:h-6 sm:w-6 md:h-7 md:w-7',
          'p-0.5 sm:p-1',
          'text-xs md:text-sm',
          builtTheme === 'today'
            ? 'rounded-full bg-app-orange3 text-white'
            : isPast
            ? 'text-gray-400'
            : 'text-gray-800',
          // builtTheme === 'today'
          //   ? 'rounded-full bg-app-orange3 text-white'
          //   : isPast
          //   ? 'bg-gray-400 text-white'
          //   : 'bg-gray-600 text-white',
        )}
      >
        {dateService.formatUTC(date, 'D')}
      </div>
    </div>
  );
};
