/* eslint-disable @typescript-eslint/no-unused-vars */
import { SessionPickup } from '@mabadive/app-common-model';
import { diveSessionsSorter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { SessionsPickupCard } from '../../club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents/SessionsPickupCard';
import { ClubEventCardGroup } from '../../club-events/ClubEventCard';
import { PlanningViewConfig } from '../../club-planning/_model';
import {
  DiveSessionSecuritySheetCard,
  DiveSessionSecuritySheetCardMenuActionsDef,
} from '../components';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
} from '../model';
import { useSessionsPickups } from '../session-pickup';
export const ClubPlanningLightPanelViewPeriodDayBeforeDive = ({
  day,
  planningConfig,
  clickActions,
  sessionsDisplayConfigs = [],
  sessionCardMenuActions,
  className,
}: {
  day: ClubPlanningDayFull;
  planningConfig: PlanningViewConfig;
  clickActions: ClubPlanningLightPanelViewClickActions;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  sessionCardMenuActions?: DiveSessionSecuritySheetCardMenuActionsDef;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const sessionsPickups: SessionPickup[] = useSessionsPickups({
    sessions: day?.sessions,
  });

  const {
    onClickSession,
    onClickSessionOpenButton,
    onClickAddParticipant,
    onClickEditSessionStaffMembers,
    onClickParticipant,
    anchorLinkParticipant,
    onClickCreateNewSession,
    onClickCreateNewEvent,
    onClickGroupDetails,
    onClickEvent,
  } = clickActions;

  const filterVirtualSessionsBeforeDive = false;
  const filterSessionsWithoutParticipantsBeforeDive = false; // TODO il faudrait configure ça sur l'écran, sinon l'utilisateur ne va pas comprendre pourquoi la session n'apparait pas!

  const dailySessions = useMemo(() => {
    const dailySessions = diveSessionsSorter.sortDiveSessionsBy(day.sessions, {
      attributeName: 'time',
      asc: true,
    });
    return dailySessions;
  }, [day.sessions]);

  const filteredSessionsWithIndex = useMemo(() => {
    return dailySessions
      .map((session, sessionIndex) => ({ session, sessionIndex }))
      .filter(
        ({ session }) =>
          (!filterVirtualSessionsBeforeDive || !session.isVirtual) &&
          (!filterSessionsWithoutParticipantsBeforeDive ||
            session.participants?.length),
      );
  }, [
    dailySessions,
    filterSessionsWithoutParticipantsBeforeDive,
    filterVirtualSessionsBeforeDive,
  ]);

  return (
    <>
      <div
        className={clsx(
          'select-none overflow-x-hidden text-gray-700 text-sm leading-4 grid grid-cols-1 gap-2',
          className,
        )}
      >
        {day.clubEvents?.length > 0 && (
          <div className="mb-2 lg:mb-5">
            <ClubEventCardGroup
              clubEvents={day.clubEvents}
              widthMode={'large'}
              onClick={onClickEvent}
            />
          </div>
        )}
        {sessionsPickups.length !== 0 && (
          <div
            className={
              'bg-white flex flex-col md:grid md:grid-cols-2 divide-y md:divide-y-0 divide-gray-200'
            }
          >
            {sessionsPickups.map((sessionPickup, i) => (
              <SessionsPickupCard
                key={i}
                className="py-1"
                sessionPickup={sessionPickup}
                mode="resume"
              />
            ))}
          </div>
        )}

        {filteredSessionsWithIndex.map(({ session, sessionIndex }, i) => (
          <DiveSessionSecuritySheetCard
            key={i}
            dailyConfig={day.dailyConfig}
            diveSession={session}
            sessionCardMenuActions={sessionCardMenuActions}
            hamburgerMenuPosition={i % 2 === 0 ? 'right-end' : 'left-end'}
            display={{
              boats: diveCenterResume?.boats.length > 1,
              date: true,
              tide: true,
              weather: true,
              comment: true,
              addParticipantButton1: false,
              addParticipantButton2: !!onClickAddParticipant,
              openSessionButton: false,
              isExpandable: false,
              sessionIndex: {
                index: sessionIndex + 1,
                total: day.sessions.length,
              },
            }}
            // minHeaderHeight={80}
            // NOTE: il faudrait revoir un peu tout ça en mode `full`, pour que les participants soient tout le temps visible, mais tout en ayant la possibilité d'"ouvrir" les détails, avec :
            // - le bouton "add" toujours visible
            // - le commentaire et le champ "open" seulement visible si on ouvre la session
            selectionColor={
              sessionsDisplayConfigs.find(
                (x) => x.diveSessionReference === session.reference,
              )?.style
            }
            onClickSession={onClickSession}
            onClickEditSessionStaffMembers={onClickEditSessionStaffMembers}
            onClickParticipant={
              onClickParticipant
                ? ({ participant, diveSession }) =>
                    onClickParticipant({
                      participant,
                      session: diveSession,
                    })
                : undefined
            }
            anchorLinkParticipant={anchorLinkParticipant}
            onClickAddParticipant={onClickAddParticipant}
            onClickGroupDetails={
              !onClickGroupDetails
                ? undefined
                : ({ participants, group, diveSession }) =>
                    onClickGroupDetails({
                      diveSessionResume: diveSession,
                      participants,
                      group,
                    })
            }
            planningConfig={planningConfig}
          />
        ))}
      </div>
    </>
  );
};
