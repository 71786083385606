import { merge, Observable, of } from 'rxjs';
import { catchError, last, map, scan, share, startWith, switchMap } from 'rxjs/operators';
import { AppProcessMonitorMessage } from './AppProcessMonitorMessage.type';
import { AppProcessMonitorState } from './AppProcessMonitorState.type';


export const processMonitor = {
  run,
  runOnComplete,
}

function runOnComplete<I, V>({
  parent,
  onSuccess,
  name,
}: {
  parent: AppProcessMonitorState<I>,
  onSuccess: (i: I) => Observable<V>,
  name?: string,
}) {
  if (parent.state === 'success') {
    // console.info(`[processMonitor.runOnComplete] success`);
    return processMonitor.run(onSuccess(parent.lastMessage.value), { name }).pipe(
      map(process => ([parent, process])),
    );
  } else {
    return of([parent]);
  }
}

function run<V>(process: Observable<V>, options?: { name: string }): Observable<AppProcessMonitorState<V>> {

  const name = options && options.name ? options.name : 'proc-' + Math.round(Math.random() * 10000);

  const obs$ = of(undefined).pipe(
    map(() => new Date()),
    // tap(startTime => console.info('[Process] start at ', startTime)),
    switchMap(startTime => scanProcess({
      name,
      startTime,
      process,
    })),
    share(),
  );
  return merge(obs$, obs$.pipe(last()).pipe(
    map(progress => ({
      ...progress,
      isRunning: false,
      endTime: new Date(),
      state: progress.lastMessage.success ? 'success' : 'error',
    } as AppProcessMonitorState<V>)),
  ));
}

function scanProcess<V>({
  name,
  startTime,
  process,
}: {
  name: string;
  startTime: Date;
  process: Observable<V>;
}) {

  const initialState = ({
    name,
    messagesHistory: [],
    startTime,
    state: 'running',
  } as AppProcessMonitorState<V>);

  return runInMessages<V>({
    process,
  }).pipe(
    scan((progress: AppProcessMonitorState<V>, lastMessage: AppProcessMonitorMessage<V>) => ({
      ...progress,
      messagesHistory: progress.messagesHistory.concat([lastMessage]),
      lastMessage,
    }), initialState),
    startWith(initialState),
  );
}

function runInMessages<V>({
  process,
}: {
  process: Observable<V>;
}): Observable<AppProcessMonitorMessage<V>> {
  return process.pipe(
    map(value => ({
      time: new Date(),
      value,
      success: true,
    })),
    catchError(err => {
      return of({
        time: new Date(),
        err,
        success: false,
      });
    }),
  );
}