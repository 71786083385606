/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  ClubStaffMemberDailyAvailabilityPeriod,
  DiveSessionStaffMemberTableModelStaffMember,
  StaffMemberResumeSessionsDayModel,
} from '@mabadive/app-common-model';
import {
  StaffMemberAvailabilityFilter,
  diveSessionStaffMembersTableModelBuilder,
  search,
} from '@mabadive/app-common-services';
import { Checkbox } from '@material-ui/core';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  AppPageBlock,
  SearchInput,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { useDiveCenterResume } from '../../../../../../../../data/hooks';
import { planningStaffWeekPresenceCacheUpdator } from '../../../../../../../club-planning/ClubPlanningStaffPage/cache';
import { DiveSessionEditorDialogLocalState } from '../../useDiveSessionEditorDialogLocalState.hook';
import { DiveSessionStaffMembersCountLabel } from '../DiveSessionDialogTab3Groups/components/DiveSessionStaffMembersTable/DiveSessionStaffMembersCountLabel';
import { DiveSessionDialogTab2StaffMembersListTable } from './DiveSessionDialogTab2StaffMembersListTable';
import { useOnUpdateSessionStaffConfig } from './useOnUpdateSessionStaffConfig.hook';

export const DiveSessionDialogTab2StaffMembersList: FC<{
  localState: DiveSessionEditorDialogLocalState;
}> = ({ localState }) => {
  const autoFocus = useAutoFocus();
  const {
    form,
    data,
    aggregatedData: {
      groups,
      clubParticipants,
      diveSession,
      divers,
      staffMembers,
    },
    setTabId,
    setStaffMemberIdToEdit,
    updateState,
    setUpdateState,
    isMultiSessionConfigForStaff,
    staffMembersFull,
    participantFirstNameBefore,
    staffFirstNameBefore,
    editStaffMember,
  } = localState;
  const { control } = form;

  const [searchText, setSearchText] = useState('');
  const [showOldStaff, setShowOldStaff] = useState(false);

  const filterMode: StaffMemberAvailabilityFilter = useMemo(() => {
    if (showOldStaff) {
      return 'all';
    }
    return 'active-period';
  }, [showOldStaff]);

  const onUpdateSessionStaffConfig = useOnUpdateSessionStaffConfig({
    localState,
  });

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;
  const updateDayConfig = useCallback(
    (params: {
      staffMember: ClubResumeStaffMember;
      staffMemberResumeSessionsDay: StaffMemberResumeSessionsDayModel;
      availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod;
    }) => {
      const { availabilityPeriod, staffMember, staffMemberResumeSessionsDay } =
        params;
      const { dailyConfig, date, dayReference } = staffMemberResumeSessionsDay;
      const localUpdateState =
        planningStaffWeekPresenceCacheUpdator.updateStaffMemberPresence(
          {
            availabilityPeriod,
            date,
            dayReference,
            staffMember,
            dailyConfig,
          },
          {
            clubReference,
            diveCenterId,
            updateState,
          },
        );

      setUpdateState(localUpdateState);
    },
    [clubReference, diveCenterId, setUpdateState, updateState],
  );

  const filteredStaffMembersFull: DiveSessionStaffMemberTableModelStaffMember[] =
    useMemo(() => {
      const filtered =
        diveSessionStaffMembersTableModelBuilder.filterStaffMembersFull({
          staffMembersFull,
          filterMode,
        });
      if (searchText) {
        return search.filter(filtered, {
          getAttributes: (x) => [
            x.staffMember.profile.firstName,
            x.staffMember.profile.lastName,
          ],
          searchText,
          sortResultsByBestMatch: false,
        });
      }
      return filtered;
    }, [filterMode, searchText, staffMembersFull]);

  const staffMembersTableModel = useMemo(
    () =>
      diveSessionStaffMembersTableModelBuilder.buildTableModel({
        clubParticipants,
        groups,
        isMultiSessionConfigForStaff,
        staffMembersFull: filteredStaffMembersFull,
      }),
    [
      clubParticipants,
      filteredStaffMembersFull,
      groups,
      isMultiSessionConfigForStaff,
    ],
  );

  return (
    <div className="px-2">
      <h2 className="text-center text-sm sm:text-lg leading-6 p-2 font-medium text-app-blue uppercase">
        Moniteurs{' '}
        <DiveSessionStaffMembersCountLabel
          staffMembersFull={staffMembersTableModel.staffMembersFull}
        />
      </h2>
      <AppPageBlock>
        <div className="my-1 flex flex-col md:flex-row gap-2">
          <SearchInput
            className="flex-grow"
            initialValue={searchText}
            autoFocus={autoFocus}
            onSearchChange={(text) => setSearchText(text)}
            placeholder="Recherche"
          />

          <div className="mx-2 flex flex-row-reverse justify-between gap-2 md:flex-col">
            {/* <Button
                startIcon={<AppIconsMaterial.add />}
                variant="contained"
                color="primary"
                className={globalClasses.buttonNavigate}
                onClick={() => redirectToStaffMemberCreatePage()}
              >
                Ajouter
              </Button> */}
            <div className="flex items-center text-gray-600">
              <Checkbox
                checked={showOldStaff}
                onChange={(e) => setShowOldStaff(e.target.checked)}
              />
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => setShowOldStaff(!showOldStaff)}
              >
                anciens moniteurs
              </span>
            </div>
          </div>
        </div>
      </AppPageBlock>
      <AppPageBlock>
        <DiveSessionDialogTab2StaffMembersListTable
          diveSessionReference={diveSession.reference}
          date={diveSession.time}
          staffMembersResumeSessions={data.staffMembersResumeSessions}
          staffMembersTableModel={staffMembersTableModel}
          isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
          onClickEdit={(x) => {
            editStaffMember(x);
          }}
          onUpdateSessionStaffConfig={onUpdateSessionStaffConfig}
          updateDayConfig={updateDayConfig}
          staffFirstNameBefore={staffFirstNameBefore}
        />
        {/* <DiveSessionStaffMembersCard
          diveSessionReference={diveSession.reference}
          date={diveSession.time}
          staffMembersResumeSessions={data.staffMembersResumeSessions}
          staffMembersTableModel={staffMembersTableModel}
          isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
          context={'staff-edit-roles'}
          onClickEdit={(x) => {
            editStaffMember(x);
          }}
          onUpdateSessionStaffConfig={onUpdateSessionStaffConfig}
          staffFirstNameBefore={staffFirstNameBefore}
        /> */}
      </AppPageBlock>
      {/* <AppPageBlock>
        <AppMessage
          type="info"
          title="NOUVEAU: jours de travail des moniteurs"
          message={
            <>
              <p>
                Cliquez sur un moniteur pour configurer dans sa fiche les jours
                de travail habituels.
              </p>
              <p className="my-3 font-bold">
                Ensuite, pour chaque session de plongée, sur le tableau
                ci-dessus:
              </p>
              <ul className="ml-3 list-disc">
                <li>
                  Cochez la case si un moniteur est exceptionnellement présent
                </li>
                <li>
                  Décochez la case si un moniteur est exceptionnellement absent
                </li>
              </ul>
            </>
          }
        />
      </AppPageBlock> */}
    </div>
  );
};
