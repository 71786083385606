/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubDiver,
  ClubDiverSelectorDialogCreateDiverFormModel,
} from '@mabadive/app-common-model';
import { changeDescriptorManager } from '@mabadive/app-common-services';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { uiStore } from 'src/business/_core/store';
import { appWebLogger } from 'src/lib/browser';
import { clubDiverFormParser } from '../../DiverBookingPage/components/BookingParticipantDialog/BookingParticipantEditorDialog/services/changes-builder/diver';
import { ClubDiverSelectorDialogUpdateState } from '../../DiverBookingPage/components/ClubDiverSelectorDialog/model';
import { DiverBookingPageUpdateState } from '../../DiverBookingPage/models';
import { diverBookingPageClientUpdator } from '../../DiverBookingPage/services';

export const createSimpleDiverAndBookingPagePersister = { persistChanges };

async function persistChanges({
  formValue,
  clubReference,
}: {
  formValue: ClubDiverSelectorDialogCreateDiverFormModel;
  clubReference: string;
}): Promise<{
  success: boolean;
  diverId?: string;
}> {
  const updateStateLocal: ClubDiverSelectorDialogUpdateState = {
    hasChanges: false,
    bookingMembersChanges: [],
    diversChanges: [],
    newBookingMembers: [],
  };

  const diverFormValue = clubDiverFormParser.parseFormValueDiver(formValue);

  const diver = repositoryEntityBuilder.buildEntity<ClubDiver>({
    ...diverFormValue,
    clubReference,
  });
  updateStateLocal.diversChanges = changeDescriptorManager.createOne(diver, {
    changeDescriptors: updateStateLocal.diversChanges,
  });

  // create diver
  const updateState: Partial<DiverBookingPageUpdateState> = {
    hasChanges: true,
    diversChanges: updateStateLocal.diversChanges,
  };

  return diverBookingPageClientUpdator
    .persistChanges(updateState, {
      logContext: 'create simple diver and booking',
      bookingIdsToClean: [],
    })
    .then(
      () => {
        return {
          success: true,
          diverId: diver._id,
        };
      },
      (err) => {
        // eslint-disable-next-line no-console

        appWebLogger.captureMessage(
          'Error while trying to select or create diver and booking',
          {
            logContext: 'createSimpleDiverAndBookingPagePersister',
            clubReference,
            extra: {
              err,
              updateState,
            },
          },
        );
        if (appWebConfig.envId === 'dev') {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
          });
        }
        return {
          success: false,
        };
      },
    );
}
