import React, { useCallback } from 'react';
import { DiverPurchaseTrainingEditorDialogState } from '../../models';

export function useOpenEditTrainingDialog({
  setTrainingEditorDialogState,
}: {
  setTrainingEditorDialogState: React.Dispatch<
    React.SetStateAction<DiverPurchaseTrainingEditorDialogState>
  >;
}) {
  return useCallback(
    ({
      diver,
      purchasePackage,
      sessionsBillingResumes,
      aggregatedData,
    }: Pick<
      DiverPurchaseTrainingEditorDialogState,
      'diver' | 'purchasePackage' | 'sessionsBillingResumes' | 'aggregatedData'
    >) =>
      setTrainingEditorDialogState({
        isOpen: true,
        mode: 'edit',
        diver,
        purchasePackage,
        sessionsBillingResumes,
        aggregatedData,
      }),
    [setTrainingEditorDialogState],
  );
}
