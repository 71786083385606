import { useEffect } from 'react';
import { uiStore } from 'src/business/_core/store';
import { DiverBookingPageConfig } from '../../models';
export function useUpdateUiStoreFromDiverBookingPageConfig(
  pageConfig: DiverBookingPageConfig,
  { enableStoreParams }: { enableStoreParams: boolean },
) {
  useEffect(() => {
    if (enableStoreParams) {
      // update store
      uiStore.diverBookingPageConfig.set(pageConfig);
    }
  }, [enableStoreParams, pageConfig]);
}
