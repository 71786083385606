import { BookingJourneyStep } from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';

export const bookingJourneyStepBuilder = {
  buildDefaultJourneyStep,
};
function buildDefaultJourneyStep({
  diveCenterId,
  arrivalDate,
  departureDate,
}: {
  diveCenterId: string;
  arrivalDate?: Date;
  departureDate?: Date;
}): BookingJourneyStep {
  const step: BookingJourneyStep = {
    diveCenterId,
    _id: uuidGenerator.random(),
    arrival: {
      date: arrivalDate ?? null,
    },
    departure: {
      date: departureDate ?? null,
    },
    accommodationId: undefined,
    pickup: {
      active: false,
    },
  };
  return step;
}
