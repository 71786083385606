/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubSettingsGeneralDialogInitialState,
  ClubSettingsGeneralFormModel,
} from '../_model';

export function useClubSettingsGeneralDialogLocalState({
  initialState,
}: {
  initialState: ClubSettingsGeneralDialogInitialState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const form = useForm<ClubSettingsGeneralFormModel>({
    defaultValues: initialState.defaultValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  return { form, hasFormChanges, initialState };
}

export type ClubSettingsGeneralDialogLocalState = ReturnType<
  typeof useClubSettingsGeneralDialogLocalState
>;
