import { ClubPurchasePayment } from '@mabadive/app-common-model';
import { purchasePaymentStateFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { AppDateTimeLabel } from 'src/business/club/modules/_common/ui';

export function PurchasePaymentStatusDetails({
  purchasePayment,
  className,
}: {
  purchasePayment: Pick<
    ClubPurchasePayment,
    'paymentState' | 'onlinePlatformDetails'
  >;
  className?: string;
}) {
  const paymentIntents = purchasePayment.onlinePlatformDetails?.paymentIntents;

  return (
    <div className={className}>
      {purchasePaymentStateFormatter.formatPaymentState(
        purchasePayment.paymentState,
      )}
      {purchasePayment.paymentState === 'authorized' &&
        paymentIntents?.nextCaptureExpirationDate && (
          <div className="text-xs">
            <AppDateTimeLabel
              timezone="utc"
              date={paymentIntents?.nextCaptureExpirationDate}
              format="date"
              steps={[
                {
                  hours: 24 * 1,
                  className: 'text-status-error',
                },
                {
                  hours: 24 * 3,
                  className: 'text-status-warn',
                },
                {
                  className: 'text-gray-400',
                },
              ]}
            />
          </div>
        )}
    </div>
  );
}
