import {
  BookingStatus,
  Club,
  ClubSettingsUIPlanning,
  DiveSession,
  MultipleDiveSessionNumber,
} from '@mabadive/app-common-model';
import { diveTypeFullFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useClubSettings } from 'src/business/club/data/hooks';
import { useMergedBookingParticipantStatus } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/tabs/DiverBookingPageSessionsHistoryTab/DiverSessionHistoryCard/useMergedBookingParticipantStatus.hook';
import { participantTagsBuilder } from 'src/business/club/modules/club-planning/_components';
import { ParticipantDiveTypeExtra } from '../../participant';
import { CalendarParticipantCellDiveModeBadgeInner } from './CalendarParticipantCellDiveModeBadgeInner';
import { CalendarSessionWidthMode } from './CalendarSessionWidthMode.type';
import { CalendarParticipantCellParticipant } from './model';
import { useParticipantExtraBadgeLabel } from './useParticipantExtraBadgeLabel.hook';

export function CalendarParticipantCellDiveModeBadge<
  T extends CalendarParticipantCellParticipant,
>({
  multipleSessionsGroup,
  multipleSessionNumber,
  settingsUIPlanning,
  club,
  participant,
  diveSession,
  isPast,
  hoverable,
  widthMode,
  recentUpdate,
  className,
}: {
  multipleSessionsGroup: boolean;
  multipleSessionNumber?: MultipleDiveSessionNumber; //  if multiplemultipleSessionsGrouppedSessionsTogether === false
  settingsUIPlanning: ClubSettingsUIPlanning;
  club: Pick<Club, 'participantTags' | 'reference'>;
  participant?: T;
  diveSession: Pick<DiveSession, 'time' | 'status'>;
  isPast?: boolean;
  hoverable?: boolean;
  widthMode: CalendarSessionWidthMode;
  recentUpdate: boolean;
  className?: string;
}) {
  if (isPast === undefined) {
    isPast = false;
  }

  const clubReference = club.reference;

  const diveTypeTag = useMemo(() => {
    if (participant) {
      return participantTagsBuilder.getParticipantSpecialDiveTypeTag({
        participant,
      });
    }
  }, [participant]);

  const diveSessionStatus =
    participant && participant.bookingState.value === 'cancelled'
      ? 'cancelled'
      : diveSession.status;

  const isSessionOpen = diveSessionStatus === 'on';

  const clubSettings = useClubSettings();
  const clubPublicSettings = clubSettings?.publicSettings;

  const diveTypeLabel = useMemo(() => {
    if (
      widthMode !== 'tiny' &&
      participant?.diveMode === 'autoSupervised' &&
      participant?.autoSupervisedDetails?.diveMode
    ) {
      // on masque "AE", pour laisser + de place au détail
      return;
    }

    return diveTypeFullFormatter.formatDiveTypeFull(participant, {
      format: 'short',
      clubReference,
      clubPublicSettings,
      diveModesConf: clubSettings?.ui?.diveMode,
    });
  }, [
    widthMode,
    participant,
    clubReference,
    clubPublicSettings,
    clubSettings?.ui?.diveMode,
  ]);

  const mergedBookingParticipantStatus: BookingStatus =
    useMergedBookingParticipantStatus({
      bookingState: participant.bookingState,
      bookingStatus: 'confirmed',
    });

  const extraBadgeLabel = useParticipantExtraBadgeLabel<T>({
    participant,
    settingsUIPlanning,
    multipleSessionsGroup,
    multipleSessionNumber,
  });

  return (
    <CalendarParticipantCellDiveModeBadgeInner
      isSessionOpen={isSessionOpen}
      diveTypeLabel={diveTypeLabel}
      diveTypeTag={diveTypeTag}
      diveMode={participant.diveMode}
      bookingStatus={mergedBookingParticipantStatus}
      diveSessionStatus={diveSessionStatus}
      extraBadgeLabel={extraBadgeLabel}
      clubReference={clubReference}
      isPast={isPast}
      hoverable={hoverable}
      widthMode={widthMode}
      recentUpdate={recentUpdate}
      className={className}
    >
      {!diveTypeLabel && (
        <ParticipantDiveTypeExtra
          className="w-full"
          participant={participant}
          diveSession={diveSession}
        />
      )}
    </CalendarParticipantCellDiveModeBadgeInner>
    // <div
    //   className={clsx(
    //     'relative px-0.5 flex justify-center items-center text-center text-white font-bold',
    //     bookingStatusBorderStyleBuilder.buildStyle(
    //       mergedBookingParticipantStatus,
    //       {
    //         widthMode,
    //       },
    //     ),
    //     !isSessionOpen
    //       ? 'bg-gray-400'
    //       : `bg-dive-mode-${diveModeTailwindClass}`,
    //     diveSessionStatus === 'cancelled' &&
    //       'line-through decoration-2 decoration-red-600',
    //     diveSessionStatus === 'off' &&
    //       'line-through decoration-2 decoration-gray-600',
    //     diveSessionStatus === 'deleted' &&
    //       'line-through decoration-2 decoration-gray-600',
    //     hoverable && 'group-hover:bg-app-orange3 group-hover:text-white',
    //     recentUpdate && '  animate-flash-red',
    //     widthMode === 'tiny' || widthMode === 'small'
    //       ? 'py-0.5'
    //       : widthMode === 'medium'
    //       ? `min-w-[30px] lg:min-w-[35px] xl:min-w-[40px] ${
    //           diveTypeLabel.length > 4
    //             ? 'text-[.6rem] lg:text-[.65rem] xl:text-[.7rem] 2xl:text-[.75rem]'
    //             : diveTypeLabel.length > 3
    //             ? 'text-app-xxs lg:text-[.7rem] xl:text-[.75rem] 2xl:text-[.8rem]'
    //             : ''
    //         }`
    //       : 'min-w-[70px]', // widthMode === 'large'
    //     className,
    //   )}
    // >
    //   <div className={widthMode === 'small' ? 'flex gap-2' : ''}>
    //     {diveTypeLabel}
    //     {diveTypeTag && widthMode !== 'tiny' && (
    //       <ColoredTagAvatarCard
    //         className={`absolute ${
    //           widthMode === 'small'
    //             ? 'top-0 right-px'
    //             : widthMode === 'large'
    //             ? 'top-px right-px'
    //             : '-top-1 right-0'
    //         }`}
    //         tag={diveTypeTag}
    //         size={'small'}
    //       />
    //     )}
    //     {extraBadgeLabel && widthMode !== 'tiny' && (
    //       <div className="text-app-xxs">{extraBadgeLabel}</div>
    //     )}
    //   </div>
    // </div>
  );
}
