import clsx from 'clsx';
import React from 'react';
import { AppLoader } from 'src/business/_core/modules/layout';
import { AppTitleDateNavigatorWithPeriodSelector } from 'src/pages/_components/title-navigators';
import { DataManagementDangerPanel } from './DataManagementDangerPanel';
import { DataManagementBookingsCleanAnalysis } from './components';
import { DataManagementPageLocalState } from './useDataManagementPageLocalState.hook';

export const DataManagementArchiveDataPanel = ({
  localState,
  className,
}: {
  localState: DataManagementPageLocalState;
  className?: string;
}) => {
  const { state, actions, data } = localState;

  const bookingsCleanAnalysisOverPeriod =
    data.fetchResult?.bookingsCleanAnalysisOverPeriod;
  const bookingsCleanPeriodOverPeriod =
    data.fetchResult?.bookingsCleanPeriodOverPeriod;

  return (
    <div className="grid gap-8">
      <DataManagementDangerPanel localState={localState} />
      <div className="app-card app-p-content grid gap-4">
        <div className="flex gap-5 justify-between">
          <AppTitleDateNavigatorWithPeriodSelector
            className="w-full"
            periodRange={state.viewParameters.periodRange}
            onChange={(periodRange) => {
              state.setViewParameters({
                ...state.viewParameters,
                periodRange,
              });
            }}
          />
        </div>
        {state.loadingInProgress ? (
          <AppLoader type="loading" />
        ) : (
          <>
            <div className={clsx('w-full', className)}>
              <div className="w-full flex flex-col gap-5">
                {bookingsCleanAnalysisOverPeriod && (
                  <DataManagementBookingsCleanAnalysis
                    title="Archivage des réservations"
                    fetchCriteria={state.fetchCriteria}
                    bookingsCleanAnalysisOverPeriod={
                      bookingsCleanAnalysisOverPeriod.data
                    }
                    bookingsCleanPeriodOverPeriod={
                      bookingsCleanPeriodOverPeriod.data
                    }
                    reload={actions.reload}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
