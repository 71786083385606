import {
  BookingDeposit,
  ClubDiver,
  ClubPurchasePayment,
  ClubPurchasePaymentBookingDepositDetails,
  ClubPurchasePaymentBookingDepositDetailsResume,
  ClubPurchasePaymentPackageDetailsResume,
} from '@mabadive/app-common-model';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  AggregatedBookingDepositWithPayments,
  AggregatedBookingPurchasePackageWithPayments,
  AggregatedBookingPurchasePaymentDepositDetails,
  AggregatedBookingPurchasePaymentWithDetails,
  DiverBookingPageAggregatedData,
  DiverBookingPageAggregatedDataCore,
  DiverBookingPageAggregatedDataFocus,
  DiverBookingPageLoadedContent,
  DiverBookingPageUpdateState,
  PRO_BookingParticipantFull,
  PRO_BookingResume,
} from '../../models';
import { DiverBookingPageAdditionalDataToLoad } from '../01.loaded-content';
import { proBookingSorter } from './bookingSorter.service';
import { diverBookingPageAggregatedBookingResumeBuilderBookingFull } from './diverBookingPageAggregatedBookingResumeBuilderBookingFull.service';
import { diverBookingPageAggregatedBookingResumeBuilderCore } from './diverBookingPageAggregatedBookingResumeBuilderCore.service';
import { diverBookingPageAggregatedBookingResumeBuilderParticipantFull } from './diverBookingPageAggregatedBookingResumeBuilderParticipantFull.service';

export const diverBookingPageAggregatedBookingResumeBuilder = {
  aggregateAll,
};

function aggregateAll({
  loadedContent,
  updateState,
  isLoaded,
  diveCenterId,
}: {
  loadedContent: DiverBookingPageLoadedContent;
  updateState: DiverBookingPageUpdateState;
  isLoaded: boolean;
  diveCenterId: string;
}): {
  aggregatedData: DiverBookingPageAggregatedData;
  missingData: DiverBookingPageAdditionalDataToLoad;
} {
  const missingData: DiverBookingPageAdditionalDataToLoad = {
    bookingIds: [],
    diverIds: [],
    newBookingIds: [],
  };
  if (
    !isLoaded ||
    !loadedContent?.bookingResumes ||
    !loadedContent?.bookingProductsResumes ||
    !updateState ||
    !updateState.isInitialized ||
    !updateState.bookingSessionsChanges
  ) {
    const aggregatedData: DiverBookingPageAggregatedData = {
      // core
      isFullyLoaded: false,
      linkedData: null,
      bookings: [],
      onlineBookings: [],
      bookingSessions: [],
      bookingMembers: [],
      bookingSessionParticipants: [],
      bookingProducts: [],
      clubParticipants: [],
      divers: [],
      diversLoaded: [],
      diveSessions: [],
      purchasePackages: [],
      paymentsPackagesDetails: [],
      purchasePackagesWithPayments: [],
      bookingDepositsWithPayments: [],
      purchasePaymentsWithDetails: [],
      purchasePayments: [],
      bookingCustomerUpdates: [],
      bookingDeposits: [],
      creditNotes: [],
      paymentsBookingDepositsDetails: [],
      // extension
      bookingResumesLoaded: [],
      bookingResumesVisible: [],
      bookingParticipantsFull: [],
      docResumes: [],
      // meta
      created: {
        bookingIds: [],
        bookingSessionsIds: [],
        bookingSessionParticipantsIds: [],
      },
      focus: {
        clubDiver: undefined,
        filteredDiverId: updateState?.filteredDiverId,
      },
    };
    return { aggregatedData, missingData };
  }

  const linkedData = loadedContent?.linkedData;

  const aggregatedDataCore: DiverBookingPageAggregatedDataCore =
    diverBookingPageAggregatedBookingResumeBuilderCore.aggregateDataCore({
      updateState,
      loadedContent,
    });
  const bookingParticipantsFull: PRO_BookingParticipantFull[] =
    aggregatedDataCore.bookingProducts
      .filter((x) => x.type === 'dive')
      .map((bookingProduct) => {
        const bookingParticipantFull =
          diverBookingPageAggregatedBookingResumeBuilderParticipantFull.buildParticipantFull(
            {
              aggregatedDataCore,
              bookingProduct,
              missingData,
            },
          );
        return bookingParticipantFull;
      })
      .filter((x) => {
        if (!x?.diver) {
          // si le diver n'est pas trouvé, c'est que le chargement des données est incohérent
          // eslint-disable-next-line no-console
          appLogger.warn('Diver not found in booking page data', x);
          // appLogger.error('Diver not found in booking page data');
          // pas d'erreur, car maintenant c'est normal, on corrige le problème avec missingData
        }
        return !!x?.diver;
      });

  const bookingResumesLoadedUnsorted: PRO_BookingResume[] =
    aggregatedDataCore.bookings.map((booking) =>
      diverBookingPageAggregatedBookingResumeBuilderBookingFull.buildBookingFull(
        {
          aggregatedDataCore,
          booking,
          bookingParticipantsFull,
          clubReference: loadedContent.clubResume.reference,
        },
      ),
    );
  // NOTE: tri cohérent avec les autres tris
  const bookingResumesLoaded: PRO_BookingResume[] =
    proBookingSorter.sortProBookingResumes(bookingResumesLoadedUnsorted, {
      diveCenterId,
      focusDiverId: loadedContent.focus?.clubDiver?._id,
    });

  const bookingResumesVisible: PRO_BookingResume[] =
    bookingResumesLoaded.filter(
      (x) =>
        x.isNewBooking ||
        linkedData?.bookingVisibleAndPersistedIds.includes(x.booking?._id),
    );

  const purchasePackagesWithPayments: AggregatedBookingPurchasePackageWithPayments[] =
    aggregatedDataCore.purchasePackages.map((purchasePackage) => {
      const paymentsDetails = aggregatedDataCore.paymentsPackagesDetails
        .filter((x) => x.purchasePackageId === purchasePackage._id)
        .map((x) => {
          const creditNotes = aggregatedDataCore.creditNotes.filter(
            (cn) => x.purchasePaymentId === cn.purchasePaymentId,
          );
          const paymentPackageDetailsResume: ClubPurchasePaymentPackageDetailsResume =
            {
              ...x,
              purchasePayment: aggregatedDataCore.purchasePayments.find(
                (y) => y._id === x.purchasePaymentId,
              ),
              creditNotes,
            };
          return paymentPackageDetailsResume;
        });

      const packageWithPayments: AggregatedBookingPurchasePackageWithPayments =
        {
          purchasePackage,
          paymentsDetails,
        };
      return packageWithPayments;
    });

  const bookingDepositsWithPayments: AggregatedBookingDepositWithPayments[] =
    buildBookingDepositsWithPayments({
      bookingDeposits: aggregatedDataCore.bookingDeposits,
      paymentsBookingDepositsDetails:
        aggregatedDataCore.paymentsBookingDepositsDetails,
      purchasePayments: aggregatedDataCore.purchasePayments,
      bookingResumesLoaded,
      diversLoaded: aggregatedDataCore.diversLoaded,
    });

  const purchasePaymentsWithDetails: AggregatedBookingPurchasePaymentWithDetails[] =
    aggregatedDataCore.purchasePayments.map((purchasePayment) => {
      const paymentsBookingDepositsDetails: ClubPurchasePaymentBookingDepositDetails[] =
        aggregatedDataCore.paymentsBookingDepositsDetails.filter(
          (x) => x.purchasePaymentId === purchasePayment._id,
        );

      const bookingDepositsPaymentDetails: AggregatedBookingPurchasePaymentDepositDetails[] =
        paymentsBookingDepositsDetails
          .map((paymentBookingDepositDetails) => {
            const details: AggregatedBookingPurchasePaymentDepositDetails =
              buildPaymentDepositDetails({
                aggregatedDataCore,
                paymentBookingDepositDetails,
                bookingResumesLoaded,
              });
            return details;
          })
          ?.filter((x) => !!x.bookingDeposit);

      const bookingResume: PRO_BookingResume = bookingResumesLoaded.find(
        (x) => x.booking?._id === purchasePayment?.bookingId,
      );
      const diver: ClubDiver = aggregatedDataCore.diversLoaded.find(
        (x) => x?._id === purchasePayment?.diverId,
      );

      const paymentWithDetails: AggregatedBookingPurchasePaymentWithDetails = {
        bookingResume,
        diver,
        purchasePayment,
        creditNotes: aggregatedDataCore.creditNotes.filter(
          (x) => x.purchasePaymentId === purchasePayment._id,
        ),
        bookingDepositsPaymentDetails,
      };
      return paymentWithDetails;
    });

  const focus: DiverBookingPageAggregatedDataFocus = {
    clubDiver: loadedContent.focus?.clubDiver
      ? aggregatedDataCore.divers.find(
          (x) => x._id === loadedContent.focus.clubDiver?._id,
        )
      : undefined,
    filteredDiverId: updateState?.filteredDiverId,
  };

  const aggregatedData: DiverBookingPageAggregatedData = {
    ...aggregatedDataCore,
    bookingResumesLoaded,
    bookingResumesVisible,
    bookingParticipantsFull,
    purchasePackagesWithPayments,
    bookingDepositsWithPayments,
    purchasePaymentsWithDetails,
    focus,
  };
  return { aggregatedData, missingData };
}
function buildPaymentDepositDetails({
  aggregatedDataCore,
  paymentBookingDepositDetails,
  bookingResumesLoaded,
}: {
  aggregatedDataCore: DiverBookingPageAggregatedDataCore;
  paymentBookingDepositDetails: ClubPurchasePaymentBookingDepositDetails;
  bookingResumesLoaded: PRO_BookingResume[];
}) {
  const bookingDeposit: BookingDeposit =
    aggregatedDataCore.bookingDeposits.find(
      (x) => x._id === paymentBookingDepositDetails.bookingDepositId,
    );

  const bookingResume: PRO_BookingResume = bookingResumesLoaded.find(
    (x) => x.booking?._id === bookingDeposit?.bookingId,
  );
  const diver: ClubDiver = aggregatedDataCore.diversLoaded.find(
    (x) => x?._id === bookingDeposit?.diverId,
  );

  const details: AggregatedBookingPurchasePaymentDepositDetails = {
    diver,
    bookingResume,
    bookingDeposit,
    paymentDetails: {
      _id: paymentBookingDepositDetails._id,
      amount: paymentBookingDepositDetails.amount,
    },
  };
  return details;
}

export function buildBookingDepositsWithPayments({
  diversLoaded,
  bookingResumesLoaded,
  bookingDeposits,
  paymentsBookingDepositsDetails,
  purchasePayments,
}: {
  diversLoaded: ClubDiver[];
  bookingResumesLoaded: PRO_BookingResume[];
  bookingDeposits: BookingDeposit[];
  paymentsBookingDepositsDetails: ClubPurchasePaymentBookingDepositDetails[];
  purchasePayments: ClubPurchasePayment[];
}): AggregatedBookingDepositWithPayments[] {
  return bookingDeposits.map((bookingDeposit) => {
    const paymentsDetails = paymentsBookingDepositsDetails
      .filter((x) => x.bookingDepositId === bookingDeposit._id)
      .map((x) => {
        const paymentBookingDepositDetailsResume: ClubPurchasePaymentBookingDepositDetailsResume =
          {
            ...x,
            purchasePayment: purchasePayments.find(
              (y) => y._id === x.purchasePaymentId,
            ),
          };
        return paymentBookingDepositDetailsResume;
      });
    const bookingResume: PRO_BookingResume = bookingResumesLoaded.find(
      (x) => x.booking?._id === bookingDeposit?.bookingId,
    );
    const diver: ClubDiver = diversLoaded.find(
      (x) => x?._id === bookingDeposit?.diverId,
    );
    const bookingDepositWithPayments: AggregatedBookingDepositWithPayments = {
      bookingResume,
      diver,
      bookingDeposit,
      paymentsDetails,
    };
    return bookingDepositWithPayments;
  });
}
