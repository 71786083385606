/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiverBookingPageUpdateState } from './models';
const BOOKING_PAGE_EMPTY_UPDATE_STATE: DiverBookingPageUpdateState = {
  isInitialized: false,
  hasChanges: false,
  actionSteps: [],
  bookingsChanges: [],
  bookingSessionsChanges: [],
  bookingMembersChanges: [],
  bookingSessionParticipantsChanges: [],
  bookingProductsChanges: [],
  clubParticipantsChanges: [],
  diversChanges: [],
  paymentsPackagesDetailsChanges: [],
  diveSessionsChanges: [],
  purchasePackagesChanges: [],
  purchasePaymentsChanges: [],
  bookingDepositsChanges: [],
  diveSessionGroupsChanges: [],
  staffMembersChanges: [],
  bookingCustomerUpdatesChanges: [],
  clubEventsChanges: [],
  dailyConfigsChanges: [],
  onlineBookingsChanges: [],
};

export const bookingUpdateStateBuilder = {
  createEmptyUpdateState: (
    defaultState: Pick<DiverBookingPageUpdateState, 'hasChanges'> &
      Partial<Omit<DiverBookingPageUpdateState, 'hasChanges'>>,
  ): DiverBookingPageUpdateState => {
    return JSON.parse(
      JSON.stringify({
        ...BOOKING_PAGE_EMPTY_UPDATE_STATE,
        ...defaultState,
      }),
    );
  },
};
