/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingSession,
  DiveCenterResume,
  DiveSessionGroup,
  DiveSessionResumeFullBookingSessionParticipant,
  DiveSessionResumeParticipant,
  MultipleDiveSessionNumber,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  commonEntityBuilder,
} from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { DiverBookingUpdateProductParticipant } from '../../models';
import {
  diverBookingPageSessionCreator,
  diverBookingParticipantCreator,
} from '../../services/02.update-state/entity-creators';
import {
  DiveSessionEditorInitialValue,
  DiveSessionEditorUpdateState,
} from './model';

export const diveSessionEditorDialogParticipantsCloner = {
  cloneParticipants,
};

function cloneParticipants({
  diveCenterResume,
  targetDiveSessionReference,
  initialValue,
  updateState: inputUpdateState,
  cloneGroups,
  cloneGuidesAndInstructors,
  targetSessionsCount,
}: {
  diveCenterResume: DiveCenterResume;
  targetDiveSessionReference: string;
  initialValue: DiveSessionEditorInitialValue;
  updateState: DiveSessionEditorUpdateState;
  cloneGroups: boolean;
  cloneGuidesAndInstructors: boolean;
  targetSessionsCount: MultipleDiveSessionNumber;
}): DiveSessionEditorUpdateState {
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  let localUpdateState = {
    ...inputUpdateState,
  };
  let newSessionGroupsByOriginalGroupId: {
    [originalGroupId: string]: DiveSessionGroup;
  } = {};

  if (cloneGroups) {
    newSessionGroupsByOriginalGroupId = initialValue.initialGroups.reduce(
      (acc, originalGroup) => {
        const newGroup = commonEntityBuilder.buildEntity<DiveSessionGroup>({
          clubReference,
          diveCenterId,
          diveSessionReference: targetDiveSessionReference,
          diveMode: originalGroup.diveMode,
        });
        if (cloneGuidesAndInstructors) {
          newGroup.diveTourGroupSession1 = originalGroup.diveTourGroupSession1;
          newGroup.diveTourGroupSession2 = originalGroup.diveTourGroupSession2;
        }
        localUpdateState.diveSessionGroupsChanges =
          changeDescriptorManager.createOne(newGroup, {
            changeDescriptors: localUpdateState.diveSessionGroupsChanges,
          });

        acc[originalGroup._id] = newGroup;
        return acc;
      },
      newSessionGroupsByOriginalGroupId,
    );
  }

  const participantsByBooking: {
    [bookingId: string]: {
      diveSessionResumeParticipant: DiveSessionResumeParticipant;
      bookingSessionParticipant: DiveSessionResumeFullBookingSessionParticipant;
    }[];
  } = initialValue.initialParticipants.reduce(
    (acc, p) => {
      const bookingSessionParticipant =
        initialValue.initialBookingSessionParticipants.find(
          (bsp) => bsp.bookingMember.diverId === p.diverId,
        );
      if (bookingSessionParticipant) {
        const bookingId = bookingSessionParticipant.bookingId;
        if (!acc[bookingId]) {
          acc[bookingId] = [];
        }
        acc[bookingId].push({
          diveSessionResumeParticipant: p,
          bookingSessionParticipant,
        });
      } else {
        appLogger.error(
          '[diveSessionEditorDialogParticipantsCloner] BookingSessionParticipant not found',
          p.diverId,
        );
      }
      return acc;
    },
    {} as {
      [bookingId: string]: {
        diveSessionResumeParticipant: DiveSessionResumeParticipant;
        bookingSessionParticipant: DiveSessionResumeFullBookingSessionParticipant;
      }[];
    },
  );

  // 2) pour chaque bookingId
  // - on créé une bookingSession
  for (const bookingId of Object.keys(participantsByBooking)) {
    const bookingSession: BookingSession =
      diverBookingPageSessionCreator.createBookingSession({
        bookingId,
        clubReference: diveCenterResume.clubReference,
        diveCenterId,
        diveSessionReference: targetDiveSessionReference,
        bookingSessionStatus: 'confirmed',
      });

    localUpdateState.bookingSessionsChanges = changeDescriptorManager.createOne(
      bookingSession,
      {
        changeDescriptors: localUpdateState.bookingSessionsChanges,
      },
    );

    const participants = participantsByBooking[bookingId].filter(
      (x) =>
        x.bookingSessionParticipant.participantBookingStatus !== 'cancelled',
    );

    // 3) pour chaque participant
    // - on créé le DiverBookingUpdateProductParticipant (participant + bookingParticipant + bookingProduct)
    for (const x of participants) {
      const originalParticipant: DiveSessionResumeParticipant =
        x.diveSessionResumeParticipant;
      const originalBookingSessionParticipant: DiveSessionResumeFullBookingSessionParticipant =
        x.bookingSessionParticipant;
      const bookingMember = originalBookingSessionParticipant.bookingMember;

      const targetDiveSessionGroupId: string = cloneGroups
        ? newSessionGroupsByOriginalGroupId[
            originalParticipant.diveSessionGroupId
          ]?._id
        : undefined;
      const targetDiveSessionGroupDiveGuide = cloneGuidesAndInstructors
        ? originalParticipant.diveSessionGroupDiveGuide
        : null;

      const newBookingProductParticipant: DiverBookingUpdateProductParticipant =
        diverBookingParticipantCreator.createParticipantFromSessionClone({
          sourceClubParticipant: originalParticipant,
          clubReference,
          diveCenterId,
          bookingSessionId: bookingSession._id,
          targetDiveSessionReference,
          bookingMemberId: bookingMember._id,
          diverId: bookingMember.diverId,
          bookingId: bookingSession.bookingId,
          targetDiveSessionGroupId,
          targetSessionsCount,
          targetDiveSessionGroupDiveGuide,
        });

      localUpdateState.bookingProductsChanges =
        changeDescriptorManager.createOne(
          newBookingProductParticipant.bookingProduct,
          {
            changeDescriptors: localUpdateState.bookingProductsChanges,
          },
        );

      localUpdateState.clubParticipantsChanges =
        changeDescriptorManager.createOne(
          newBookingProductParticipant.clubParticipant,
          {
            changeDescriptors: localUpdateState.clubParticipantsChanges,
          },
        );
      localUpdateState.bookingSessionParticipantsChanges =
        changeDescriptorManager.createOne(
          newBookingProductParticipant.bookingSessionParticipant,
          {
            changeDescriptors:
              localUpdateState.bookingSessionParticipantsChanges,
          },
        );
    }
  }

  return localUpdateState;
}
