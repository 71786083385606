import React, { useMemo } from 'react';
import { AppTabTailwind } from '../../../../../../../business/_core/modules/layout';
import {
  EcommerceCategoryEditorFormContentWithPreview,
  EcommerceCategoryEditorFormSettings,
} from './form';
import {
  EcommerceCategoryEditorFormTabId,
  ecommerceCategoryEditorFormTabLabelFormatter,
} from './services';
import { EcommerceCategoryEditorLocalState } from './useEcommerceCategoryEditorPanelLocalState.hook';

export const EcommerceCategoryEditorPanelEditForm = ({
  localState,
}: {
  localState: EcommerceCategoryEditorLocalState;
}) => {
  const { state, actions } = localState;

  const tabs = useMemo(() => {
    const tabsIds: EcommerceCategoryEditorFormTabId[] = [
      'content',
      'cover',
      'config',
    ];

    const tabs: AppTabTailwind<EcommerceCategoryEditorFormTabId>[] =
      tabsIds.map((tabId) => ({
        id: tabId,
        label: ecommerceCategoryEditorFormTabLabelFormatter.format(tabId),
      }));
    return tabs;
  }, []);

  const [selectedTab, setSelectedTab] =
    React.useState<EcommerceCategoryEditorFormTabId>('content');

  return (
    <div className="grid gap-4 max-w-6xl">
      {/* <AppTabsTailwind
        className="w-full"
        tabs={tabs}
        selected={selectedTab}
        theme="underline"
        breakpoint="sm"
        onChange={(tabId) => setSelectedTab(tabId)}
      /> */}
      {/* {selectedTab === 'cover' && ( */}
      {/* <EcommerceCategoryEditorFormCoverWithPreview localState={localState} /> */}
      {/* )} */}
      {/* {selectedTab === 'content' && ( */}
      <EcommerceCategoryEditorFormContentWithPreview localState={localState} />
      {/* )} */}
      {/* {selectedTab === 'config' && ( */}
      <EcommerceCategoryEditorFormSettings localState={localState} />
      {/* )} */}
    </div>
  );
};
