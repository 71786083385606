/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';

import {
  ClubSettingsCommunicationDialogInitialState,
  ClubSettingsCommunicationFormModel,
} from '../../../_model';
import { ClubSettingsCommunicationEmailAutoForm_General } from './ClubSettingsCommunicationEmailAutoForm_General';

export const ClubSettingsCommunicationEmailAutoForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsCommunicationFormModel>;
  initialState: ClubSettingsCommunicationDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const enableBookingReminderAuto: boolean = useWatch({
    control,
    name: 'clubSettings.communication.booking.reminder.auto',
  });

  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsCommunicationEmailAutoForm_General
        form={form}
        initialState={initialState}
      />
    </div>
  );
};
