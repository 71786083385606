/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EcommerceCategoryGql_Company,
  EcommerceProductArticleGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import { default as React, useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import { ProProductsPage_CategoryContentCardProductArticlesArea } from './ProProductsPage_CategoryContentCardProductArticlesArea';
import { ProProductsPage_CategoryProductCoverCard } from './ProProductsPage_CategoryProductCoverCard';

export const ProProductsPage_CategoryContentCardProduct = ({
  category,
  product,
  localState,
  className,
}: {
  category: EcommerceCategoryGql_Company;
  product: EcommerceProductGql_Company;
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
  className?: string;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const { state, dialogs, data, actions } = localState;

  const productArticles: EcommerceProductArticleGql_Company[] = useMemo(() => {
    return data.productArticles.filter((a) => a.productId === product._id);
  }, [data.productArticles, product._id]);

  const productBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product.bookletId);
  }, [data.booklets, product.bookletId]);

  const productBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === productBooklet?.rootPageId);
  }, [data.bookletPages, productBooklet?.rootPageId]);

  return (
    <div
      key={product._id}
      className={clsx(
        'border-l-4 border-gray-400 bg-gray-50 p-4 grid gap-4',
        className,
      )}
    >
      <ProProductsPage_CategoryProductCoverCard
        category={category}
        product={product}
        localState={localState}
      />
      <ProProductsPage_CategoryContentCardProductArticlesArea
        product={product}
        localState={localState}
      />
    </div>
  );
};
