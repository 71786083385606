import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { DiveSessionResumeLoadBounds } from 'src/business/_core/data/store-repository/dive-sessions/model';

import { appLoaderMerger } from 'src/business/_core/data/app-loading/loader/appLoaderMerger.service';
import {
  useCalendarWeekClubEvents,
  useCalendarWeekDailyConfigs,
  useFetchCalendarWeekDiveSessionResumes,
} from 'src/business/club/modules/club-planning/_components';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { useDashboardHomeFetchStats } from './useDashboardHomeFetchStats.hook';
import { useDashboardHomePageViewParameters } from './useDashboardHomePageViewParameters';

export function useDashboardHomePageLocalState() {
  const { viewParameters, setViewParameters } =
    useDashboardHomePageViewParameters();

  const { beginDate, endDate } = viewParameters.periodRange.value;

  const { loadingInProgress: loadingInProgressStats, fetchResult } =
    useDashboardHomeFetchStats({
      period: { beginDate, endDate },
    });

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    dailyExportConfig: {}, // ici on peut récupérer un callback si besoin
  });
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const criteria: DiveSessionResumeLoadBounds = useMemo(
    () => ({
      minDateInclusive: beginDate,
      maxDateExclusive: dateService.add(beginDate, 1, 'day'),
    }),
    [beginDate],
  );

  const { content: diveSessionResumes, ...loadableContent1 } =
    useFetchCalendarWeekDiveSessionResumes({
      criteria,
      mode: 'normal',
    });

  const { content: clubEvents, ...loadableContent2 } =
    useCalendarWeekClubEvents({
      criteria,
      mode: 'normal',
    });

  const { content: dailyConfigs, ...loadableContent3 } =
    useCalendarWeekDailyConfigs({
      criteria,
      mode: 'force-reload',
    });

  const loadableContent = useMemo(
    () =>
      appLoaderMerger.mergeLoadableContents(
        loadableContent1,
        loadableContent2,
        loadableContent3,
      ),
    [loadableContent1, loadableContent2, loadableContent3],
  );

  const loadingInProgress =
    loadingInProgressStats ||
    loadableContent?.lastActionStatus === 'in-progress';

  return {
    state: {
      dialogsState,
      viewParameters,
      setViewParameters,
      loadingInProgress,
    },
    data: {
      fetchResult,
      diveSessionResumes,
      clubEvents,
      dailyConfigs,
    },
  };
}
export type DashboardHomePageLocalState = ReturnType<
  typeof useDashboardHomePageLocalState
>;
