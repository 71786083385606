import {
  changeDescriptorManager,
  dataSorter,
  diverBookingCreator,
  diverBookingMemberCreator,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  BookingTabModel,
  DiverBookingPageAggregatedDataCore,
  DiverBookingPageUpdateState,
  DiverBookingUpdateProductParticipant,
  PRO_BookingMemberFull,
} from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
import { diverBookingPageUpdateStateManager } from '../../02.update-state';
import {
  diverBookingPageSessionCreator,
  diverBookingParticipantCreator,
} from '../entity-creators';

export function useDiverBookingPageExtractMemberToNewBooking({
  clubReference,
  diveCenterId,
  updateState,
  aggregatedData,
  setUpdateState,
}: {
  clubReference: string;
  diveCenterId: string;
  updateState: DiverBookingPageUpdateState;
  aggregatedData: DiverBookingPageAggregatedDataCore;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
}) {
  const diveCenterResume = useDiveCenterResume();
  return useCallback(
    ({
      diverId,
      bookingSource,
    }: {
      diverId: string;
      bookingSource: BookingTabModel;
    }) => {
      let updateStateLocal = updateState;

      const sourceMember = bookingSource.aggregatedBooking.bookingMembers.find(
        (m) => m.diverId === diverId,
      );
      const productsToCreate =
        bookingSource.aggregatedBooking.bookingParticipantsFull.filter(
          (sourceParticipantFullProducts) =>
            sourceParticipantFullProducts.bookingMember._id ===
            sourceMember._id,
        );

      if (productsToCreate.length) {
        // on crée une nouvelle résa seulement si il y a au moins un produit à créer

        // create booking
        const newBooking = diverBookingCreator.createBooking({
          clubReference,
          diveCenterId,
          bookingContactDiverId: diverId,
          publicSettings:
            diveCenterResume?.clubResume.clubSettings.publicSettings,
        });
        const bookingsChanges = changeDescriptorManager.createOne(newBooking, {
          changeDescriptors: updateStateLocal.bookingsChanges,
        });
        updateStateLocal = {
          ...updateStateLocal,
          bookingsChanges,
        };

        // create member

        const newBookingMember =
          diverBookingMemberCreator.createBookingMemberFromSourceMember({
            sourceMember,
            bookingId: newBooking._id,
            clubReference,
            diveCenterId,
          });

        const diver = aggregatedData.divers.find(
          (d) => d._id === sourceMember.diverId,
        );
        updateStateLocal =
          diverBookingPageUpdateStateManager.addNewBookingMemberToState({
            updateState: updateStateLocal,
            newBookingMember,
            diver,
          });

        // create sessions
        const newSessions: {
          diveSessionReference: string;
          bookingSessionId: string;
        }[] = [];
        for (const sourceSession of bookingSource.aggregatedBooking
          .bookingSessions) {
          const diveSessionReference = sourceSession.diveSessionReference;

          const diveSession = aggregatedData.diveSessions.find(
            (d) => d.reference === diveSessionReference,
          );

          const isSessionToCreate =
            bookingSource.aggregatedBooking.bookingParticipantsFull.find(
              (p) =>
                p.bookingMember._id === sourceMember._id &&
                p.bookingSession.diveSessionReference ===
                  sourceSession.diveSessionReference,
            ) !== undefined;

          if (isSessionToCreate) {
            // only create session if this member participate to it
            const newBookingSession =
              diverBookingPageSessionCreator.createBookingSessionFromSourceSession(
                {
                  bookingId: newBooking._id,
                  clubReference,
                  sourceSession,
                },
              );
            newSessions.push({
              diveSessionReference: newBookingSession.diveSessionReference,
              bookingSessionId: newBookingSession._id,
            });

            const bookingSessionsChanges = changeDescriptorManager.createOne(
              newBookingSession,
              {
                changeDescriptors: updateStateLocal.bookingSessionsChanges,
              },
            );
            updateStateLocal = {
              ...updateStateLocal,
              bookingSessionsChanges,
            };
          }
        }

        // create products
        for (const sourceParticipantFullProduct of productsToCreate) {
          const diverId = sourceParticipantFullProduct.bookingMember.diverId;

          const diveSessionReference =
            sourceParticipantFullProduct.bookingSession.diveSessionReference;

          // create new product
          const newSession = newSessions.find(
            (m) => m.diveSessionReference === diveSessionReference,
          );
          const updatedParticipant: DiverBookingUpdateProductParticipant =
            diverBookingParticipantCreator.createParticipantFromSourceProduct({
              participantProductFull: sourceParticipantFullProduct,
              bookingId: newBooking._id,
              bookingMemberId: newBookingMember._id,
              bookingSessionId: newSession.bookingSessionId,
            });
          updateStateLocal =
            diverBookingPageUpdateStateManager.addNewParticipantToState({
              updateState: updateStateLocal,
              newBookingProductParticipant: updatedParticipant,
            });
          // delete old product
          updateStateLocal =
            diverBookingPageUpdateStateManager.deleteParticipantFromState({
              updateState: updateStateLocal,
              bookingProductId:
                sourceParticipantFullProduct.bookingProductDive._id,
              clubParticipantId:
                sourceParticipantFullProduct.bookingSessionParticipant
                  .participantId,
              bookingSessionParticipantId:
                sourceParticipantFullProduct.bookingSessionParticipant?._id,
            });
        }
      }

      // delete old member
      const bookingMembersChanges = changeDescriptorManager.deleteOne(
        sourceMember._id,
        {
          changeDescriptors: updateStateLocal.bookingMembersChanges,
        },
      );
      updateStateLocal = {
        ...updateStateLocal,
        bookingMembersChanges,
      };

      if (
        bookingSource.aggregatedBooking.booking?.bookingContactDiverId ===
        diverId
      ) {
        updateStateLocal = replaceBookingContact({
          bookingSource,
          updateStateLocal,
        });
      }

      setUpdateState(updateStateLocal, {
        action: 'DiverBookingPageExtractMemberToNewBooking',
      });
    },
    [
      aggregatedData.diveSessions,
      aggregatedData.divers,
      clubReference,
      diveCenterId,
      diveCenterResume?.clubResume.clubSettings.publicSettings,
      setUpdateState,
      updateState,
    ],
  );
}
export function replaceBookingContact({
  bookingSource,
  updateStateLocal,
}: {
  bookingSource: BookingTabModel;
  updateStateLocal: DiverBookingPageUpdateState;
}) {
  const diverId = bookingSource.aggregatedBooking.booking.bookingContactDiverId;
  const otherMembers =
    bookingSource.aggregatedBooking.bookingMembersFull.filter(
      (x) => x.diver._id !== diverId,
    );

  const otherMembersOrdered = dataSorter.sortMultiple(otherMembers, {
    getSortAttributes: (x: PRO_BookingMemberFull) => [
      {
        value: x.diver?.birthdate,
        type: 'default',
      },
      {
        value: x.diver?.lastName,
        type: 'full-text',
      },
      {
        value: x.diver?.firstName,
        type: 'full-text',
      },
      {
        value: x.diver?._id,
        type: 'full-text',
      },
    ],
  });

  const newContactMember = otherMembersOrdered.length
    ? otherMembersOrdered[0]
    : undefined;

  if (!newContactMember) {
    appLogger.error('Contact member not fount');
    return updateStateLocal;
  }

  const patchOperations = jsonPatcher.compareObjects(
    {
      bookingId: bookingSource.bookingId,
      bookingContactDiverId: null,
    },
    {
      bookingId: bookingSource.bookingId,
      bookingContactDiverId: newContactMember.diver._id,
    },
    {},
  );

  const bookingsChanges = changeDescriptorManager.updateOne(
    {
      pk: bookingSource.bookingId,
      patchOperations,
    },
    {
      changeDescriptors: updateStateLocal.bookingsChanges,
    },
  );
  updateStateLocal = {
    ...updateStateLocal,
    bookingsChanges,
  };

  return updateStateLocal;
}
