import {
  TailwindBreakpoint,
  useMediaSizeTailwind,
} from './useMediaSizeTailwind.hook';

export function useAppPxContentSize() {
  const s: TailwindBreakpoint = useMediaSizeTailwind();

  //  app-px-content: px-2 sm:px-3 md:px-4 lg:px-5 xl:px-6 2xl:px-7
  const ratio =
    s === '2xl'
      ? 7
      : s === 'xl'
      ? 6
      : s === 'lg'
      ? 5
      : s === 'md'
      ? 4
      : s === 'sm'
      ? 3
      : 2;

  return ratio * 4; // px-2 = px-[8px]
}
