/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubProductPackageOfferView } from '@mabadive/app-common-model';
import {
  clubResidentTypeFormatter,
  diveSessionThemeFormatter,
  diveSessionTypeFormatter,
  productPackageFormatter,
  productTypeFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import {
  AppHamburgerMenu,
  AppHamburgerMenuItem,
  AppPriceLabel,
} from 'src/business/club/modules/_common/ui';
import { ClubOfferNameDetailsLabel } from 'src/business/club/modules/club-diver-participant/components';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { ClubOfferProductListPageState } from '../ClubOfferProductListPageState.type';

export const ClubOfferProductTableRow = ({
  offer,
  setState,
  onDelete,
  className,
}: {
  offer: ClubProductPackageOfferView;
  setState: (state: ClubOfferProductListPageState) => void;
  onDelete: () => void;
  className?: string;
}) => {
  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const publicSettings = clubResume.clubSettings.publicSettings;

  const confirmDelete = useCallback(() => {
    const productPackageName = productPackageFormatter.formatNameString(
      offer.productPackage,
      {
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );

    confirmDialog
      .confirm({
        isTailwind: true,
        title: `Supprimer le produit "${productPackageName}"`,
        message: 'Êtes-vous sûr de vouloir supprimer ce produit?',
        type: 'remove',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          onDelete();
        }
      });
  }, [
    clubSettings?.ui?.diveMode,
    offer.productPackage,
    onDelete,
    publicSettings,
  ]);

  const isAdvancedEditEnabled = useAppSecurityUserHasRole('super-admin');

  const hamburgerMenuItems = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    if (isAdvancedEditEnabled) {
      hamburgerMenuItems.push({
        title: 'Modifier',
        description: 'Modifier ce produit',
        icon: AppHeroIcons.actionEdit,
        onClick: () => {
          setState({
            mode: 'edit-single',
            selectedOffer: offer,
          });
        },
      });
      hamburgerMenuItems.push({
        title: 'Dupliquer',
        description: 'Dupliquer ce produit',
        icon: AppHeroIcons.actionClone,
        onClick: () => {
          setState({
            mode: 'duplicate-single',
            selectedOffer: offer,
          });
        },
      });
      hamburgerMenuItems.push({
        title: 'Supprimer',
        description: 'Supprimer ce produit',
        severity: 'danger',
        icon: AppHeroIcons.actionDelete,
        onClick: () => {
          confirmDelete();
        },
      });
    }
    return hamburgerMenuItems;
  }, [confirmDelete, isAdvancedEditEnabled, offer, setState]);

  const productAttributes = offer.productPackage.productAttributes;
  const salesCriteria = offer.productPackage.salesCriteria;

  const onClickRow = useCallback(() => {
    if (isAdvancedEditEnabled) {
      setState({
        mode: 'edit-single',
        selectedOffer: offer,
      });
    }
  }, [isAdvancedEditEnabled, offer, setState]);

  return (
    <tbody>
      <tr className={className}>
        <td
          onClick={() => onClickRow()}
          className={`relative px-2 py-0.5 ${
            isAdvancedEditEnabled ? 'cursor-pointer' : null
          } whitespace-nowrap`}
        >
          <ClubOfferNameDetailsLabel
            className="font-bold text-app-blue"
            productPackage={offer.productPackage}
          />
          {salesCriteria?.dontSelectByDefault && (
            <AppHeroIcons.forbidden className="h-4 w-4 text-gray-400 absolute top-0 right-0" />
          )}
          {offer.productPackage.comment?.trim().length > 0 && (
            <div className="text-xs text-gray-600">
              {offer.productPackage.comment}
            </div>
          )}
          <div>
            {productTypeFormatter.format(offer.productPackage.productType)}{' '}
            {productPackageFormatter.formatAgeDescription(productAttributes)}
          </div>
        </td>
        <td
          onClick={() => onClickRow()}
          className={`px-2 py-0.5 text-right ${
            isAdvancedEditEnabled ? 'cursor-pointer' : null
          } whitespace-nowrap`}
        >
          {offer.price > 0 && (
            <AppPriceLabel amount={offer.price} mainCurrency={mainCurrency} />
          )}
          {offer.thirdPartyCollectPrice > 0 && (
            <span className="text-xs">
              {' '}
              (dont{' '}
              <AppPriceLabel
                amount={offer.thirdPartyCollectPrice}
                mainCurrency={mainCurrency}
              />{' '}
              tiers)
            </span>
          )}
          {salesCriteria?.residentType && (
            <div
              className={clsx(
                'text-xs ',
                salesCriteria?.residentType === 'holidays'
                  ? 'text-green-600'
                  : 'text-blue-600',
              )}
            >
              {clubResidentTypeFormatter.format(salesCriteria?.residentType)}
            </div>
          )}
        </td>
        <td
          onClick={() => onClickRow()}
          className={`px-2 py-0.5 ${
            isAdvancedEditEnabled ? 'cursor-pointer' : null
          } whitespace-nowrap`}
        >
          {salesCriteria?.diveSessionType && (
            <div className={clsx('text-xs text-gray-600')}>
              {diveSessionTypeFormatter.formatType(
                salesCriteria?.diveSessionType,
                {
                  diveModesConf: clubSettings?.ui?.diveMode,
                },
              )}
            </div>
          )}
          {salesCriteria?.diveSessionTheme && (
            <div className={clsx('text-xs text-gray-600')}>
              {diveSessionThemeFormatter.formatTheme(
                salesCriteria?.diveSessionTheme,
              )}
            </div>
          )}
        </td>
        <td className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <input
            disabled={true}
            type="checkbox"
            checked={offer.productPackage?.salesCriteria?.standalone}
          />
        </td>
        <td className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <input
            disabled={true}
            type="checkbox"
            checked={offer.productPackage?.salesCriteria?.extraCostTraining}
          />
        </td>
        <td className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <input
            disabled={true}
            type="checkbox"
            checked={offer.productPackage?.salesCriteria?.extraCostFirstDive}
          />
        </td>
        <td className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <input
            disabled={true}
            type="checkbox"
            checked={offer.productPackage?.salesCriteria?.extraCostDivePlan}
          />
        </td>
        <td className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <input
            disabled={true}
            type="checkbox"
            checked={offer.productPackage?.salesCriteria?.extraCostUnitDive}
          />
        </td>
        <td className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <input
            disabled={true}
            type="checkbox"
            checked={offer.productPackage?.salesCriteria?.extraCostSession}
          />
        </td>
        <td className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <input
            disabled={true}
            type="checkbox"
            checked={offer.productPackage?.salesCriteria?.favorite}
          />
        </td>
        <td className="py-1 whitespace-nowrap">
          <div>
            <AppHamburgerMenu
              buttonClassName="bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white"
              position="left-start"
              items={hamburgerMenuItems}
              buttonIcon={AppHeroIcons.actionMenu}
            />
          </div>
        </td>
      </tr>
    </tbody>
  );
};
