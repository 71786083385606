import React, { FC, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppMessage } from 'src/business/_core/modules/layout';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { clubSettingsCustomersUrlBuilder } from 'src/pages/SE-settings/SE-03-customers/clubSettingsCustomersUrlBuilder.service';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';

export const CreateMessageToCustomersFormStep3_ContentInfoMessage: FC<{
  localState: CreateMessageToCustomersLocalState;
  sendActionLink: string;
}> = ({ localState, sendActionLink }) => {
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume.clubResume;
  const clubReference = clubResume?.reference;

  const {
    state,
    data: { form, result },
    actions,
  } = localState;

  const { register, handleSubmit, watch, control, setValue } = form;

  const [messageTarget] = useWatch({
    control,
    name: ['messageTarget'],
  });

  // <AppMessage
  //       className="my-2 text-justify sm:text-left"
  //       type="info"
  //       title="Instructions"
  //       message={
  //         <>
  //           <p>
  //             Pour configurer les modèles de messages, rendez-vous sur{' '}
  //             <Link
  //               to={settingsCommunicationTabTemplatesUrl}
  //               className="font-bold text-app-blue underline"
  //             >
  //               la page de configuration
  //             </Link>
  //           </p>
  //         </>
  //       }
  //     />

  const settingsCommunicationTabTemplatesUrl = useMemo(
    () =>
      clubSettingsCustomersUrlBuilder.communication.view({
        tabId: 'templates',
      }),
    [],
  );

  return messageTarget === 'email' ? (
    <AppMessage
      className="text-justify sm:text-left"
      type="info"
      title="Instructions"
      isExpanded={false}
      message={
        <div className="grid gap-4">
          <p>
            Les E-MAILS sont envoyés <b>via votre logiciel de messagerie</b>.
            Vous recevrez donc la réponse des plongeurs directement sur votre
            compte.
          </p>
          <p>
            Si besoin, vous pouvez avoir à configurer votre navigateur pour
            qu'il ouvre la bonne application (outlook, thunderbird, gmail...).
            En cas de difficulté, n'hésitez pas à nous contacter pour obtenir de
            l'aide.
          </p>
          <p>
            Pour configurer les modèles de messages, rendez-vous sur{' '}
            <Link
              to={settingsCommunicationTabTemplatesUrl}
              className="font-bold text-app-blue underline"
            >
              la page de configuration
            </Link>
          </p>
        </div>
      }
    />
  ) : messageTarget === 'sms' ? (
    <AppMessage
      className="text-justify sm:text-left"
      type="info"
      title="Instructions"
      isExpanded={false}
      message={
        <div className="grid gap-4">
          <p>
            Les SMS sont envoyés <b>via votre téléphone</b>, et donc via votre
            forfait téléphonique. Vous recevrez donc la réponse des plongeurs
            directement sur votre téléphone.
          </p>
          <p className="mt-5">
            Pour utiliser cette fonctionnalité, vous devez donc être connecté
            avec soit:
          </p>
          <ul className="ml-3 list-disc text-left">
            <li>
              <b>un smartphone</b>
            </li>
            <li>
              une tablette <b>équipée d'une carte SIM</b>
            </li>
            <li>
              une tablette <b>associée à un smartphone</b> (exemple:{' '}
              <a
                className="font-bold underline"
                href="https://fr.wikihow.com/synchroniser-un-t%C3%A9l%C3%A9phone-et-une-tablette-Samsung"
              >
                Samsung
              </a>
              )
            </li>
          </ul>
          <p>
            Pour configurer les modèles de messages, rendez-vous sur{' '}
            <Link
              to={settingsCommunicationTabTemplatesUrl}
              className="font-bold text-app-blue underline"
            >
              la page de configuration
            </Link>
          </p>
        </div>
      }
    />
  ) : null;
};
