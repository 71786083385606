import { DiverBookingPageAggregatedData } from '../../models';

export const defaultDiverBuilder = {
  getDefaultDiverId,
};
function getDefaultDiverId(
  aggregatedData: DiverBookingPageAggregatedData,
): string {
  if (aggregatedData?.focus?.filteredDiverId) {
    // filtered diver
    return aggregatedData?.focus?.filteredDiverId;
  }
  if (aggregatedData?.focus?.clubDiver?._id) {
    // focus diver
    return aggregatedData?.focus?.clubDiver?._id;
  }
  // sinon, first diver (ça ne doit pas arriver souvent, mais sinon on pourrait aussi privilégier le premier responsable de la dernière résa)
  return aggregatedData?.divers?.[0]?._id;
}
