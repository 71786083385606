import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';

export const AppDateTimeLabel = ({
  date,
  className = '',
  format,
  timezone,
  steps = [
    {
      hours: 12,
      className: 'text-gray-700',
    },
    {
      hours: 48,
      className: 'text-gray-600',
    },
  ],
}: {
  date: Date;
  className?: string;
  format: 'date' | 'date-time' | 'date-time-seconds';
  timezone: 'utc' | 'local';
  steps?: {
    hours?: number;
    className: string;
  }[];
  hoursSteps?: [number, number];
  colorsSteps?: [number, number];
}) => {
  const dateColor = useMemo(() => {
    if (date) {
      const ageInHours = dateService.getDiffInHours(date, new Date());
      for (const step of steps) {
        if (step.hours === undefined || ageInHours <= step.hours) {
          return step.className;
        }
      }
    }
    return 'text-gray-400';
  }, [date, steps]);

  return !date ? null : (
    <span className={`${dateColor} ${className}`}>
      <span className="font-bold">
        {timezone === 'utc'
          ? dateService.formatUTC(date, 'DD/MM/YYYY')
          : dateService.formatLocal(date, 'DD/MM/YYYY')}
      </span>
      {format === 'date-time' && (
        <>
          {' '}
          {timezone === 'utc'
            ? dateService.formatUTC(date, 'HH:mm')
            : dateService.formatLocal(date, 'HH:mm')}
        </>
      )}
      {format === 'date-time-seconds' && (
        <>
          {' '}
          {timezone === 'utc'
            ? dateService.formatUTC(date, 'HH:mm:ss')
            : dateService.formatLocal(date, 'HH:mm:ss')}
        </>
      )}
    </span>
  );
};
