/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react';
import { AppTabTailwind } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

import { AppCompanyMessageTemplate } from '@mabadive/app-common-model';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  ClubSettingsCommunicationPageTabEditMode,
  ClubSettingsCommunicationPageViewState,
} from './_model';
import {
  ClubSettingsCommunicationPageTabId,
  clubSettingsCommunicationPageTabLabelFormatter,
  clubSettingsCommunicationPageUrlManager,
} from './_services';
import { useClubSettingsCommunicationPageUpdateURL } from './useClubSettingsCommunicationPageUpdateURL.hook';

export const useClubSettingsCommunicationPageLocalState = () => {
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    // appCompanyMessageTemplateEdit: {}, // ici on peut récupérer un callback si besoin
  });
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const openClubSettingsCommunicationDialog = useCallback(
    (tabId: ClubSettingsCommunicationPageTabId) => {
      dialogsState.clubSettingsCommunicationDialog.openDialog({
        tabId,
        defaultValue: {
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.clubSettingsCommunicationDialog],
  );

  const openMessageTemplateCreateDialog = useCallback(
    (messageTemplate: AppCompanyMessageTemplate) => {
      dialogsState.appCompanyMessageTemplateEditDialog.openDialog({
        mode: 'create',
        defaultValue: {
          messageTemplate,
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.appCompanyMessageTemplateEditDialog],
  );
  const openMessageTemplateEditDialog = useCallback(
    (messageTemplate: AppCompanyMessageTemplate) => {
      dialogsState.appCompanyMessageTemplateEditDialog.openDialog({
        mode: 'edit',
        defaultValue: {
          messageTemplate,
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.appCompanyMessageTemplateEditDialog],
  );

  const queryParams =
    clubSettingsCommunicationPageUrlManager.getQueryParameters();

  const [viewState, setViewState] =
    useState<ClubSettingsCommunicationPageViewState>({
      tabId: queryParams.tabId ?? 'templates',
      mode: queryParams.mode ?? 'view',
    });

  useClubSettingsCommunicationPageUpdateURL(viewState, {
    enableRouteParams: true,
  });

  const selectedTab = viewState.tabId;

  const setSelectedTab = useCallback(
    (tabId: ClubSettingsCommunicationPageTabId) => {
      setViewState((prev) => ({
        ...prev,
        tabId,
      }));
    },
    [],
  );

  const isEditAllowedBase = useAppSecurityUserHasRole(
    'club-edit-settings-general',
  );
  const editMode: ClubSettingsCommunicationPageTabEditMode = useMemo(() => {
    // if (isEditAllowedBase) {
    //   if (selectedTab === 'pricing') {
    //     return 'forbidden';
    //   }
    // }
    return isEditAllowedBase ? 'allowed' : 'forbidden';
  }, [isEditAllowedBase]) as ClubSettingsCommunicationPageTabEditMode;

  const editEnabled = editMode === 'allowed'; // || (editMode === 'super-admin-only' && isSuperAdmin);

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const tabs = useMemo(() => {
    const tabsIds: ClubSettingsCommunicationPageTabId[] = ['templates'];

    if (clubSettings.communication.channels?.email?.enabled) {
      tabsIds.push('email-auto');
    }

    tabsIds.push('main');

    const tabs: AppTabTailwind<ClubSettingsCommunicationPageTabId>[] =
      tabsIds.map((tabId) => ({
        id: tabId,
        label: clubSettingsCommunicationPageTabLabelFormatter.format(tabId),
      }));
    return tabs;
  }, [clubSettings.communication.channels?.email?.enabled]);

  return {
    state: {
      editEnabled,
      editMode,
      tabs: {
        selected: selectedTab,
        setSelected: setSelectedTab,
        data: tabs,
      },
    },
    dialogs: {
      state: dialogsState,
      communication: {
        open: openClubSettingsCommunicationDialog,
      },
      messageTemplate: {
        openCreate: openMessageTemplateCreateDialog,
        openEdit: openMessageTemplateEditDialog,
      },
    },
  };
};

export type ClubSettingsCommunicationPageLocalState = ReturnType<
  typeof useClubSettingsCommunicationPageLocalState
>;
