import {
  BOOKING_DEPOSIT_STATES,
  BookingDepositState,
} from '@mabadive/app-common-model';
import { bookingDepositStateFormatter } from '@mabadive/app-common-services';
import { ValueLabel } from '../../../business/club/modules/_common/form';

export function useBookingDepositStateOptions() {
  return BOOKING_DEPOSIT_STATES.map((value) => {
    const option: ValueLabel<BookingDepositState> = {
      label: bookingDepositStateFormatter
        .formatDepositState(value, {
          isPaymentPending: false,
        })
        .toUpperCase(),
      value,
    };
    return option;
  });
}
