/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EXPORT_DAILY_GROUP_PARAM_COLUMN,
  ExportDailyConfigContentSessionsGroupParamColumnId,
} from '@mabadive/app-common-model';
import { exportGroupParamColumnFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { FieldArrayWithId, useFieldArray, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppFormControl } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DailyExportConfigFormModel } from '../../_model';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';
import { DECOP_SessionItemDive_GroupParamColumnModel } from './DECOP_SessionItemDive_GroupParamColumnModel';

export const DECOP_SessionItemDive_GroupParamColumns = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  sessionItemIndex,
  sessionItemDiveIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  sessionItemIndex: number;
  sessionItemDiveIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    getValues,
  } = form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}.items.${sessionItemIndex}.items.${sessionItemDiveIndex}` as const;

  const [sessionDiveId] = useWatch({
    control,
    name: [`${baseFormName}.id`],
  });
  const [paramColumns] = useWatch({
    control,
    name: [`${baseFormName}.paramColumns`],
  });

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  const paramColumnsFieldArray = useFieldArray({
    control,
    name: `${baseFormName}.paramColumns`,
    keyName: 'id',
  });

  const remainingGroupParamColumnsOptions = useMemo(() => {
    const paramColumnsIds = paramColumns.map((x) => x.id);
    const options = EXPORT_DAILY_GROUP_PARAM_COLUMN.filter(
      (x) => !paramColumnsIds.includes(x),
    ).map((value) => {
      const option: ValueLabel<ExportDailyConfigContentSessionsGroupParamColumnId> =
        {
          label: exportGroupParamColumnFormatter.formatColumnName(value, {
            format: 'option-label',
          }),
          value,
        };
      return option;
    });
    return options;
  }, [paramColumns]);

  const [modelAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - modelAnimations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      field: FieldArrayWithId<
        DailyExportConfigFormModel,
        `dailyResumes.${number}.exportConfig.sheets.${number}.content.items.${number}.items.${number}.items.${number}.paramColumns`,
        'id'
      >,
      fieldIndex: number,
      action: string,
    ) => {
      const from = fieldIndex;
      const update = new Date();
      switch (action) {
        case 'move-down': {
          paramColumnsFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          paramColumnsFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'delete': {
          setModelAnimations({ update, remove: from });
          paramColumnsFieldArray.remove(from);
          // setTimeout(() => paramColumnsFieldArray.remove(from), 100);
          break;
        }
      }
    },
    [paramColumnsFieldArray],
  );

  return (
    <div className={clsx('grid gap-2', className)}>
      <h4 className="text-left text-base font-medium text-gray-600">
        Paramètres: colonnes visibles
      </h4>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
        <div className="grid gap-2">
          {paramColumnsFieldArray.fields.map((field, fieldIndex) => {
            return (
              <DECOP_SessionItemDive_GroupParamColumnModel
                key={`${field.id}-${fieldIndex}`}
                form={form}
                fieldArray={paramColumnsFieldArray as any}
                field={field as any}
                fieldIndex={fieldIndex}
                dailyResumeIndex={dailyResumeIndex}
                sheetIndex={sheetIndex}
                contentItemIndex={contentItemIndex}
                sessionItemIndex={sessionItemIndex}
                sessionItemDiveIndex={sessionItemDiveIndex}
                className={clsx(
                  isRecentAnimationUpdate &&
                    modelAnimations?.up === fieldIndex &&
                    'animate-move-up',
                  isRecentAnimationUpdate &&
                    modelAnimations?.down === fieldIndex &&
                    'animate-move-down',
                  isRecentAnimationUpdate &&
                    modelAnimations?.clone === fieldIndex &&
                    'animate-create',
                  isRecentAnimationUpdate &&
                    modelAnimations?.remove === fieldIndex &&
                    'animate-remove',
                )}
                onClick={(action) => onClick(field, fieldIndex, action)}
              />
            );
          })}
          {remainingGroupParamColumnsOptions.length > 0 && (
            <div className="my-4 w-60">
              <AppFormControl
                label="Ajouter une colonne"
                renderComponent={() => (
                  <div className="my-2">
                    <AppSingleSelect2HeadlessUI
                      value={''}
                      required={false}
                      options={remainingGroupParamColumnsOptions}
                      theme={'tailwind'}
                      disableClearButton={true}
                      onChange={(value) => {
                        if (value) {
                          paramColumnsFieldArray.append({
                            id: value,
                            label: '',
                          } as any);
                        }
                      }}
                    />
                  </div>
                )}
              />
              {/* <AppButton
                size="normal"
                icon={AppHeroIcons.actionAdd}
                color="primary-outline-light"
                onClick={() => {
                  paramColumnsFieldArray.append({
                    ref: '',
                    label: '',
                  } as any);
                }}
              >
                Ajouter
              </AppButton> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
