import {
  AppTranslationContentItem,
  AppUiTheme,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useAppTheme } from '../../../../../business/club/data/hooks';
import { AppRichTextArea } from '../../AppRichText';
import { AppTranslationContentButton } from './AppTranslationContentButton';
import { AppTranslationContentImage } from './AppTranslationContentImage';

export const AppTranslationContentPanel = ({
  item,
  theme: inputTheme,
  className,
}: {
  item: AppTranslationContentItem;
  theme?: AppUiTheme;
  className?: string;
}) => {
  const theme: AppUiTheme = useAppTheme(inputTheme);

  const style: React.CSSProperties = useMemo(
    () => ({
      color: theme.text?.textColor,
    }),
    [theme.text?.textColor],
  );
  return !item ? null : (
    <>
      {item.type === 'text' && item.text?.length !== 0 && (
        <div className={`my-3 leading-6 ${className}`} style={style}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.text.replace(/\n/g, '<br/>'),
            }}
          />
        </div>
      )}
      {item.type === 'rich-text' && item.richText?.elements.length !== 0 && (
        <AppRichTextArea
          theme={theme}
          className={`my-3 leading-6 ${className}`}
          content={item.richText}
        />
      )}
      {item.type === 'app-doc-image' && item.appDoc && (
        <AppTranslationContentImage
          className={`my-4  ${className}`}
          appDoc={item.appDoc}
        />
      )}
      {item.type === 'button' && item.button && (
        <div className={`text-center  ${className}`}>
          <AppTranslationContentButton
            theme={theme}
            className="my-2"
            button={item.button}
          />
        </div>
      )}
    </>
  );
};
