import { DiveSession } from '@mabadive/app-common-model';
import React, { useCallback, useMemo } from 'react';
import { AppTransition } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ClubPurchasesExtraCostDescription } from 'src/pages/LI-lists/LI-03_purchase/ClubPurchasesTable/ClubPurchasesExtraCostDescription';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  BillingTabDiveSessionBillingResumeMultiDivers,
  BillingTabParticipantPurchase,
  BookingParticipantEditorParticipant,
  PRO_BookingParticipantFull,
} from '../../models';
import { DiverBookingPageBillingTabState } from '../../tabs/DiverBookingPageBillingTab/useDiverBookingPageBillingTabState.hook';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBillingSessionsTable } from '../DiverBillingSessionsTable';
import { billingResumeMultiDiversBuilder } from '../DiverPurchaseCommonEditorDialog';
import { buildParticipantEditorEditOpenInputState } from '../useDiverBookingPageBookingCardLocalState.hook';

export function BillingParticipantPurchaseResumeTableRow2({
  participantPurchase,
  billingTabLocalState,
  globalState,
  dialogs,
  actions,
  isExpanded,
  className,
}: {
  participantPurchase: BillingTabParticipantPurchase;
  billingTabLocalState: DiverBookingPageBillingTabState;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  isExpanded: boolean;
  className?: string;
}) {
  const {
    aggregatedData,
    model,
    selectedDiverId,
    setSelectedDiverId,
    diversFilterOptions,
    createPlan,
    createTraining,
    createFirstDive,
  } = billingTabLocalState;

  const { sessionsBillingResumes: sessionsBillingResumesInput, isArchived } =
    participantPurchase;

  const sessionsBillingResumes: BillingTabDiveSessionBillingResumeMultiDivers[] =
    useMemo(
      () =>
        billingResumeMultiDiversBuilder.buildMultiDivers(
          sessionsBillingResumesInput.sameTypeSameDiver,
          {},
        ),
      [sessionsBillingResumesInput],
    );

  const { openSessionDialog } = actions;
  const { setBookingParticipantEditorState } = dialogs;

  const onClickSession = useCallback(
    (diveSession: DiveSession) => {
      openSessionDialog({
        diveSession,
      });
    },
    [openSessionDialog],
  );
  const onClickParticipant = useCallback(
    ({
      bookingParticipantFull,
    }: {
      bookingParticipantFull: PRO_BookingParticipantFull;
    }) => {
      const participant: BookingParticipantEditorParticipant =
        buildParticipantEditorEditOpenInputState({
          bookingParticipantFull,
        });
      setBookingParticipantEditorState({
        isOpen: true,
        mode: 'edit-participant',
        participant,
      });
    },
    [setBookingParticipantEditorState],
  );
  const divesCounts = participantPurchase.divesCounts;
  const totalConsumedCount = useMemo(
    () =>
      divesCounts.assigned +
      // divesCounts.toAssign +
      divesCounts.consumedExternalCount,
    [
      divesCounts.assigned,
      divesCounts.consumedExternalCount,
      // divesCounts.toAssign,
    ],
  );

  // NOTE: ici, on pourrait activer includeOtherDivers
  // (comme dans l'édition via includeOtherDiversDefaultValue)
  // mais c'est trop permissif, ça affiche tous les plongeurs,
  // alors que le forfait n'est sans doute partagé qu'avec un seul plongeur
  // TODO: pouvoir associer le forfait explicitement à 1 ou plusieurs plongeurs

  return (
    <>
      {(participantPurchase.purchasePackage?.comment?.trim()?.length > 0 ||
        participantPurchase?.purchasePackage?.extraCosts?.length > 0) && (
        <tr className={`${isExpanded ? '' : 'hidden'}`}>
          <td colSpan={8}>
            <AppTransition
              type="scale"
              show={isExpanded}
              className="app-px-content"
            >
              {participantPurchase.purchasePackage?.comment?.trim()?.length >
                0 && (
                <div
                  className={`my-1 text-xs text-gray-500 text-left gap-1 ${className}`}
                >
                  <AppHeroIcons.comment className="inline mr-1 w-3 h-3 sm:w-4 sm:h-4 text-gray-500" />
                  {participantPurchase.purchasePackage.comment}
                </div>
              )}
              <ClubPurchasesExtraCostDescription
                className="my-1 text-xs text-gray-500"
                extraCosts={participantPurchase?.purchasePackage?.extraCosts}
              />
            </AppTransition>
          </td>
        </tr>
      )}
      <tr className={`${isExpanded ? '' : 'hidden'}`}>
        <td colSpan={8}>
          <AppTransition type="scale" show={isExpanded}>
            <DiverBillingSessionsTable
              participantsConfig={{
                includeOtherDivers: false,
                includeOtherTypes: false,
              }}
              setParticipantsConfig={null}
              sessionsBillingResumesOtherDiversCount={0}
              sessionsBillingResumesOtherTypeCount={0}
              totalConsumedCount={totalConsumedCount}
              totalCreditsCount={divesCounts.total}
              className={'mb-2 sm:mb-5'}
              onClickAssignProducts={async ({ bookingProducts, operation }) => {
                await actions.assignProductsToPurchasePackage({
                  purchasePackageId: participantPurchase?.purchasePackage?._id,
                  bookingProducts,
                  operation,
                  confirmValidityStatusUpdate: true,
                  countSuccessiveAsSingle:
                    participantPurchase?.countSuccessiveAsSingle,
                });
              }}
              displayAssignButton={
                !isArchived && !!participantPurchase.purchasePackage
              }
              sessionsBillingResumes={sessionsBillingResumes}
              onClickSession={(s) => onClickSession(s)}
              onClickParticipant={onClickParticipant}
            />
          </AppTransition>
        </td>
      </tr>
    </>
  );
}
