import React from 'react';
import { Switch } from 'react-router-dom';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING } from './SETTINGS_CUSTOMERS.const';
import { ClubSettingsCommunicationPage } from './communication';
import { ClubSettingsCustomerSpaceConfigPage } from './customer-space';
import {
  ClubSettingsInquiriesListPage,
  ClubSettingsInquiryEditPage,
} from './inquiry';
import { ClubSettingsOnlineBookingConfigPage } from './online-booking';
import { ClubSettingsOnlineBookingProductsPage } from './online-booking/ClubSettingsOnlineBookingProductsPage';
import { ClubSettingsPaymentPlatformListPage } from './payment-platform';

export const ClubSettingsCustomersRoutes = React.memo(
  function ClubSettingsSecurityRoutesMemo() {
    const { match } = useRouter();
    return (
      <Switch>
        <AppRoute
          path={`${match.url}/customer-space`}
          component={ClubSettingsCustomerSpaceConfigPage}
          auth={{ requiredRoles: ['club-edit-settings'] }}
        />
        <AppRoute
          path={`${match.url}/communication`}
          component={ClubSettingsCommunicationPage}
          auth={{ requiredRoles: ['club-edit-settings'] }}
        />
        <AppRoute
          path={`${match.url}/inquiries`}
          exact
          component={ClubSettingsInquiriesListPage}
          checkAuth={({ az, clubSettings }) =>
            az?.edit?.settings?.inquiry &&
            clubSettings?.general?.inquiry?.enabled
          }
        />
        <AppRoute
          path={`${match.url}/inquiries/create`}
          exact
          component={ClubSettingsInquiryEditPage}
          navigationContext={{ mode: 'create' }}
          checkAuth={({ az, clubSettings }) =>
            az?.edit?.settings?.inquiry &&
            clubSettings?.general?.inquiry?.enabled
          }
        />
        <AppRoute
          path={`${match.url}/inquiries/edit/:inquiryId`}
          exact
          component={ClubSettingsInquiryEditPage}
          // navigationContext={{ mode: 'create' }}
          navigationContext={{ mode: 'edit' }}
          checkAuth={({ az, clubSettings }) =>
            az?.edit?.settings?.inquiry &&
            clubSettings?.general?.inquiry?.enabled
          }
        />
        <AppRoute
          path={`${match.url}/online-booking/config`}
          component={ClubSettingsOnlineBookingConfigPage}
          checkAuth={SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING.checkAuth}
        />
        <AppRoute
          path={`${match.url}/online-booking`}
          component={ClubSettingsOnlineBookingProductsPage}
          checkAuth={SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING.checkAuth}
        />
        <AppRoute
          path={`${match.url}/payment-platforms`}
          exact
          component={ClubSettingsPaymentPlatformListPage}
          auth={{ requiredRoles: ['club-edit-settings'] }}
          checkAuth={({ auth, az }) => az.edit.settings.onlinePayment}
        />
        {/* default route */}
        <AppRoute
          path={`${match.url}/`}
          exact={false}
          redirectToUrl="/club/settings"
        />
      </Switch>
    );
  },
);
