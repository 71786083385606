import clsx from 'clsx';
import { FC, default as React, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppDialogModalFixedBar,
  AppMessageSingleLine,
} from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import {
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFormControlV2, AppInputBooleanSwitch } from 'src/lib/form';
import { clubSettingsCustomersUrlBuilder } from 'src/pages/SE-settings/SE-03-customers/clubSettingsCustomersUrlBuilder.service';
import { AppMessagePreview } from 'src/pages/_components';
import {
  useCreateMessageParamsToReplace,
  useCreateMessageSendActionLink,
} from '../services';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';
import { CreateMessageToCustomersFormStep3_ContentActions } from './CreateMessageToCustomersFormStep3_ContentActions';
import { CreateMessageToCustomersFormStep3_ContentFormEdit } from './CreateMessageToCustomersFormStep3_ContentFormEdit';
import { CreateMessageToCustomersFormStep3_ContentInfoMessage } from './CreateMessageToCustomersFormStep3_ContentInfoMessage';
import { CreateMessageToCustomersFormStep4_ContentTranslateBar } from './CreateMessageToCustomersFormStep4_ContentTranslateBar';

export const CreateMessageToCustomersFormStep3_Content: FC<{
  localState: CreateMessageToCustomersLocalState;
  className?: string;
}> = ({ localState, className }) => {
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume.clubResume;
  const clubReference = clubResume?.reference;

  const {
    state,
    data: { form, result, isConnexionLinkFountInMessage },
    actions,
  } = localState;

  const validSelectedRecipients = result?.aggregated?.validSelectedRecipients;

  const { register, handleSubmit, watch, control, setValue } = form;

  const clubSettings = useClubSettings();

  const [
    targetModeEmail,
    messageTarget,
    title,
    body,
    signature,
    recipientType,
  ] = useWatch({
    control,
    name: [
      'targetModeEmail',
      'messageTarget',
      'messageContent.title',
      'messageContent.body',
      'messageContent.signature',
      'recipientType',
    ],
  });

  const context = localState.state.context;

  const defaultParticipantIndex = useMemo(() => {
    const bookingContact = validSelectedRecipients.find(
      (x) => x.formRecipient?.isBookingContact,
    );
    if (bookingContact) {
      return bookingContact.formRecipient.formDiverIndex;
    }
    return validSelectedRecipients[0]?.formRecipient?.formDiverIndex ?? 0;
  }, [validSelectedRecipients]);

  const [recipientIndexIfPersonal, setRecipientIndexIfPersonal] = useState(
    defaultParticipantIndex,
  );

  const paramsToReplace = useCreateMessageParamsToReplace({
    localState,
    recipientIndexIfPersonal,
  });

  const [mode, setMode] = useState<'preview' | 'edit'>('preview');

  const sendActionLink = useCreateMessageSendActionLink({
    localState,
  });
  const settingsCommunicationTabMainUrl = useMemo(
    () =>
      clubSettingsCustomersUrlBuilder.communication.view({
        tabId: 'main',
      }),
    [],
  );
  return (
    <div className={clsx('mb-12 flex flex-col gap-8', className)}>
      {mode === 'preview' ? (
        <>
          <>
            <AppMessagePreview
              showHeaderBar={true}
              showMessageIndexNavigationBar={
                recipientType === 'personal' &&
                validSelectedRecipients?.length > 1
              }
              currentIndex={recipientIndexIfPersonal}
              totalIndexes={validSelectedRecipients?.length}
              setCurrentIndex={setRecipientIndexIfPersonal}
              target={messageTarget}
              title={title}
              body={body}
              signature={signature}
              paramsToReplace={paramsToReplace}
              onClick={() => setMode('edit')}
            >
              <div className="my-2 flex gap-4 justify-around">
                <AppButton
                  className="uppercase"
                  fullWidth={false}
                  size="normal"
                  icon={AppIconsAction.edit}
                  color={'primary-outline-light'}
                  tabIndex={500}
                >
                  Modifier
                </AppButton>
              </div>
            </AppMessagePreview>
            <div className="flex justify-end">
              <CreateMessageToCustomersFormStep4_ContentTranslateBar
                localState={localState}
              />
            </div>
          </>
        </>
      ) : mode === 'edit' ? (
        <>
          <CreateMessageToCustomersFormStep3_ContentFormEdit
            localState={localState}
          />
        </>
      ) : null}

      {mode === 'preview' && (
        <>
          {isConnexionLinkFountInMessage &&
          clubSettings.communication?.customerSpace?.individualLinksEnabled ? (
            <AppMessageSingleLine className="flex-grow" type="info">
              Chaque participant d'une réservation reçoit un lien de connexion
              individuel
            </AppMessageSingleLine>
          ) : (
            validSelectedRecipients.length > 1 && (
              <>
                <div className="flex flex-col sm:flex-row gap-y-1 gap-x-4 sm:justify-between">
                  {recipientType === 'personal' ? (
                    <AppMessageSingleLine className="flex-grow" type="info">
                      Un message individuel sera envoyé séparément à chaque
                      destinataire.
                    </AppMessageSingleLine>
                  ) : (
                    <AppMessageSingleLine className="flex-grow" type="info">
                      Un message commun sera envoyé à tous les destinataires.
                    </AppMessageSingleLine>
                  )}
                  {context === 'booking' &&
                    clubSettings.communication?.booking?.recipients
                      ?.personalMessageSwitchEnabled && (
                      <div className="flex justify-end">
                        <AppFormControlV2
                          className="w-full"
                          label={'Message individuel?'}
                          renderComponent={() => (
                            <AppInputBooleanSwitch
                              value={recipientType === 'personal'}
                              onChange={(value) => {
                                setValue(
                                  'recipientType',
                                  value ? 'personal' : 'group',
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                </div>
              </>
            )
          )}
          {messageTarget === 'email' &&
            sendActionLink &&
            targetModeEmail === 'auto' && (
              <div className="grid gap-4">
                <p className="font-bold">
                  Les mails seront envoyés automatiquement par Mabadive.
                </p>
                <p>
                  Pour modifier la configuration, rendez-vous sur{' '}
                  <Link
                    to={settingsCommunicationTabMainUrl}
                    className="font-bold text-app-blue underline"
                  >
                    la page de configuration
                  </Link>
                </p>
              </div>
            )}
        </>
      )}

      <div className="flex-grow"></div>
      {mode === 'preview' && (
        <CreateMessageToCustomersFormStep3_ContentInfoMessage
          localState={localState}
          sendActionLink={sendActionLink}
        />
      )}
      <AppDialogModalFixedBar>
        <CreateMessageToCustomersFormStep3_ContentActions
          localState={localState}
          mode={mode}
          setMode={setMode}
          sendActionLink={sendActionLink}
        />
      </AppDialogModalFixedBar>
    </div>
  );
};
