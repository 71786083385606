/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import {
  DIVE_SERVICE_ORGANIZATIONS,
  DIVE_TRAININGS,
  DiveTrainingReference,
} from '@mabadive/app-common-model';
import { diveServiceTrainingFormatter } from '@mabadive/app-common-services';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { ClubSettingsProductOffersFormModel } from '../../_model';

export const ClubSettingsProductOffersFormOffer_Training_Reference = ({
  form,
  offerField,
  offersFieldArray,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
}) => {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offersFieldArray.fields.findIndex(
    (x) => x.reference === offerField.reference,
  );

  const showDetails = offersFieldArray.fields.length === 1;

  const [trainingType, defaultOrganizationReference] = useWatch({
    control,
    name: [
      `offers.${i}.productPackage.trainingAttributes.trainingType`,
      `offers.${i}.productPackage.productAttributes.defaultOrganizationReference`,
    ],
  });

  const selectedOrganization = useMemo(
    () =>
      DIVE_SERVICE_ORGANIZATIONS.find(
        (x) => x.reference === defaultOrganizationReference,
      ),
    [defaultOrganizationReference],
  );

  const diveTrainingOptions = useMemo(
    () =>
      DIVE_TRAININGS.filter(
        (diveTraining) =>
          diveTraining.trainingType === trainingType &&
          (!selectedOrganization ||
            selectedOrganization.orgGroupReference ===
              diveTraining.organization?.reference),
      ).map((training) => {
        const option: ValueLabel<DiveTrainingReference> = {
          label: diveServiceTrainingFormatter
            .formatTraining(training, {
              format: 'ref-name',
            })
            .toUpperCase(),
          value: training.reference,
        };
        return option;
      }),
    [selectedOrganization, trainingType],
  );

  return (
    <AppFormControlRHF_Deprecated
      className="w-full col-span-2"
      label="Formation"
      control={control}
      name={`offers.${i}.productPackage.trainingAttributes.diveTrainingReference`}
      required={true}
      renderComponent={(props) => (
        <AppSingleAutocomplete2RHF {...props} options={diveTrainingOptions} />
      )}
    />
  );
};
