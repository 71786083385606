import React from 'react';
import { Control, Path, useController } from 'react-hook-form';
import { AppMultiCheckboxes } from './AppMultiCheckboxes';
import { AppMultiCheckboxesProps } from './AppMultiCheckboxesProps.type';

export function AppMultiCheckboxesRHF<
  S extends string | number,
  T,
  L extends string | React.ReactNode = string,
>({
  control,
  name,
  className = 'flex flex-wrap',
  buildLabel,
  onChange: onChangeInput,
  ...extraProps
}: Omit<AppMultiCheckboxesProps<S, L>, 'defaultValue' | 'onChange'> & {
  control: Control<T>;
  name: Path<T>;
  buildLabel?: (label: L) => React.ReactNode;
  onChange?: (value: S[]) => any;
  className?: string;
}) {
  const {
    field: { ref, onChange: onChangeRHF, value: valueRHF, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<T>({
    name,
    control,
    // rules: {
    // required: extraProps.required,
    // },
  });

  return (
    <AppMultiCheckboxes<S, L>
      {...extraProps}
      className={className}
      defaultValue={valueRHF as any}
      onChange={(value) => {
        onChangeRHF(value);
        onChangeInput && onChangeInput(value);
      }}
      buildLabel={buildLabel}
    />
  );
}
