import {
  DiveCenterDailyResumeFetchedData,
  DiveSessionResumeFull,
  SessionPickup,
} from '@mabadive/app-common-model';
import { diveCenterDailyResumeModelBuilder } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppButton, AppMessageLight } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { SessionsPickupCard } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents/SessionsPickupCard';
import { ClubEventCardGroup } from 'src/business/club/modules/club-events';
import { useSessionsPickups } from 'src/business/club/modules/club-planning-light/session-pickup';
import { ClubDialogsState } from 'src/pages/_dialogs';
import { useDailyExportDownloadModal } from './DailyExportDownloadModal';
import { DailySessionsResumeRow } from './DailySessionsResumeRow';

export const DailySessionsResumeCard = ({
  dateUTC,
  dateLabel,
  diveSessionResumes,
  clubEvents,
  dailyConfigs,
  dialogsState,
}: DiveCenterDailyResumeFetchedData & {
  dialogsState: ClubDialogsState;
}) => {
  const sessions: DiveSessionResumeFull[] = useMemo(
    () => diveCenterDailyResumeModelBuilder.buildSessions(diveSessionResumes),
    [diveSessionResumes],
  );

  const sessionsPickups: SessionPickup[] = useSessionsPickups({
    sessions,
  });

  const az = useAppSecurityUserClubAuthorizations();

  const diveCenterResume = useDiveCenterResume();

  const dailyExportsSettings =
    diveCenterResume.privateSettings.exports.dailyResumes;

  const dailyExportModal = useDailyExportDownloadModal({
    dialogsState,
  });

  return sessions === undefined ? null : (
    <div className="app-card p-4 app-py-content text-gray-600 flex flex-col gap-2">
      <div className={'flex gap-2 justify-start items-center rounded-t-lg'}>
        <div>
          <div className="uppercase text-xl leading-5 font-bold">
            Récapitulatif de la journée - {dateLabel}
          </div>
        </div>
      </div>
      {sessions.length === 0 && (
        <AppMessageLight className="my-4">
          Aucun participant inscrit
        </AppMessageLight>
      )}
      {sessions.length > 0 &&
        az.download?.dailyResume &&
        dailyExportsSettings
          .filter((x) => x.enabled && x.visibility.dashboardDaily)
          .map((dailyExportSettings) => (
            <div
              key={dailyExportSettings._id}
              className="flex justify-end items-end"
            >
              <AppButton
                color="primary-outline-light"
                className={''}
                icon={AppHeroIcons.actionDownload}
                onClick={() => {
                  dailyExportModal.openModal({
                    dailyExportSettings,
                    sessions,
                    dateUTC,
                  });
                }}
              >
                {dailyExportSettings.label}
              </AppButton>
            </div>
          ))}

      {clubEvents?.length > 0 && (
        <div className="mb-2 lg:mb-5">
          <ClubEventCardGroup
            clubEvents={clubEvents}
            widthMode={'large'}
            // onClick={onClickEvent}
          />
        </div>
      )}

      {sessionsPickups.length !== 0 && (
        <div
          className={
            'bg-white flex flex-col md:grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 divide-y md:divide-y-0 divide-gray-200'
          }
        >
          {sessionsPickups.map((sessionPickup, i) => (
            <SessionsPickupCard
              key={i}
              className="py-1"
              sessionPickup={sessionPickup}
              mode="resume"
            />
          ))}
        </div>
      )}
      <div className={'min-w-full text-sm flex flex-col gap-4 md:gap-8'}>
        {/* <div className="bg-gray-500 font-bold divide-x divide-white text-white text-center uppercase">
          <div>Session</div>
        </div> */}
        {sessions.map((session, sessionIndex) => (
          <DailySessionsResumeRow
            key={sessionIndex}
            dailyConfigs={dailyConfigs}
            session={session}
            sessionNumber={sessionIndex + 1}
          />
        ))}
      </div>
    </div>
  );
};
