import {
  ProStatsFetchCriteria,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  DashboardFetchCriteriaPeriod,
  proStatsApiChartsConfigBuilder,
  proStatsApiClient,
} from 'src/pages/DA-dashboard/_core';

export function useDashboardCashFetchStats({
  period,
}: {
  period: DashboardFetchCriteriaPeriod;
}): {
  loadingInProgress: boolean;
  fetchResult: ProStatsFetchResults;
  fetchResultFake: ProStatsFetchResults;
} {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const clubReference = diveCenterResume.clubReference;

  const charts = proStatsApiChartsConfigBuilder.buildChartsConfig({
    period,
    chartsIds: ['paymentsOverPeriod'],
  });

  const fetchCriteria: ProStatsFetchCriteria = useMemo(() => {
    const apiFetchCriteria: ProStatsFetchCriteria = {
      beginDate: period.beginDate,
      endDate: period.endDate,
      clubReference,
      diveCenterId,
      charts,
    };
    return apiFetchCriteria;
  }, [charts, clubReference, diveCenterId, period.beginDate, period.endDate]);

  const query: UseQueryResult<ProStatsFetchResults, unknown> = useQuery({
    queryKey: ['pro-stats-cash', fetchCriteria],
    queryFn: async () => {
      return proStatsApiClient.fetch(fetchCriteria);
    },
    refetchInterval: 1000 * 60 * 60, // refresh every 60 minutes
    enabled: !!fetchCriteria,
  });

  const { isLoading: loadingInProgress, data: fetchResult } = query;

  const [fetchResultFake, setFetchResultFake] =
    useState<ProStatsFetchResults>();

  useEffect(() => {
    if (!!fetchResult && fetchResult !== fetchResultFake) {
      // on mémorise le dernier résultat, même si il ne correspond pas aux criteria, afin d'afficher le composant en "flou" plutôt que de le masquer
      setFetchResultFake(fetchResult);
    }
  }, [fetchResult, fetchResultFake]);

  return {
    loadingInProgress,
    fetchResult,
    fetchResultFake,
  };
}
