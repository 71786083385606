/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubPublicData, ClubResumeAdmin } from '@mabadive/app-common-model';
import React from 'react';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { AdminCompaniesListPageLocalState } from '../useAdminCompaniesListPageLocalState';

export const ClubResumeAdminCardCompanyLogo = ({
  localState,
  clubResumeAdmin: club,
}: {
  clubResumeAdmin: ClubResumeAdmin;
  localState: AdminCompaniesListPageLocalState;
}) => {
  const { filters } = localState;
  const { showDetails } = filters;
  const clubPublicData: ClubPublicData = club.clubSettings?.publicData;

  const logoSquareUrl = clubPublicData?.brand?.logoSquareUrl;

  return (
    <div className={'text-center h-[80px] w-[80px]'}>
      {logoSquareUrl ? (
        <img
          src={logoSquareUrl}
          className={'inline max-h-[80px] max-w-[80px]'}
          alt="logo"
        />
      ) : (
        <AppIcons.ui.emptyPhoto
          className={'inline max-h-[80px] max-w-[80px]'}
          title="Votre logo ici"
        />
      )}
    </div>
  );
};
