/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubProductPackageNamingConfiguration } from '@mabadive/app-common-model';
import { ClubProductPackageNameDetails } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

export const ClubOfferCustomNameForm = ({
  formPathPrefix = '',
  form,
  nameDetails,
  className,
}: {
  formPathPrefix?: string;
  form: UseFormReturn<
    { namingConfiguration: ClubProductPackageNamingConfiguration },
    any,
    undefined
  >;
  nameDetails: ClubProductPackageNameDetails;
  className?: string;
}) => {
  const { control, setValue } = form;

  const [overrideDefaultNameEnabled] = useWatch({
    control,
    name: [
      `${formPathPrefix}namingConfiguration.overrideDefaultName.enabled` as any,
    ],
  });

  return (
    <div className={clsx('flex flex-wrap gap-4', className)}>
      <div>
        <AppFormControlRHF_Deprecated
          label={'Nom personnalisé'}
          control={control}
          name={
            `${formPathPrefix}namingConfiguration.overrideDefaultName.enabled` as any
          }
          renderComponent={(props) => (
            <AppInputBooleanRHF
              {...props}
              type="checkbox"
              description={'Activer'}
              onChange={(overrideDefaultNameEnabled) => {
                if (overrideDefaultNameEnabled) {
                  setValue(
                    `${formPathPrefix}namingConfiguration.overrideDefaultName.prefix` as any,
                    nameDetails.prefix,
                  );
                  setValue(
                    `${formPathPrefix}namingConfiguration.overrideDefaultName.name` as any,
                    nameDetails.name,
                  );
                  setValue(
                    `${formPathPrefix}namingConfiguration.overrideDefaultName.label` as any,
                    nameDetails.label,
                  );
                  setValue(
                    `${formPathPrefix}namingConfiguration.overrideDefaultName.suffix` as any,
                    nameDetails.suffix,
                  );
                }
              }}
            />
          )}
        />
      </div>
      {overrideDefaultNameEnabled ? (
        <div className="flex-grow flex flex-wrap gap-2">
          <AppFormControlRHF_Deprecated
            control={control}
            label="Diminutif"
            name={
              `${formPathPrefix}namingConfiguration.overrideDefaultName.prefix` as any
            }
            validation={{
              rules: {
                maxLength: 6,
              },
            }}
            required={overrideDefaultNameEnabled}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          <AppFormControlRHF_Deprecated
            control={control}
            label="Nom (bleu)"
            name={
              `${formPathPrefix}namingConfiguration.overrideDefaultName.name` as any
            }
            required={overrideDefaultNameEnabled}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />{' '}
          <AppFormControlRHF_Deprecated
            control={control}
            label="Complément (orange)"
            name={
              `${formPathPrefix}namingConfiguration.overrideDefaultName.label` as any
            }
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          <AppFormControlRHF_Deprecated
            control={control}
            label="Suffixe"
            name={
              `${formPathPrefix}namingConfiguration.overrideDefaultName.suffix` as any
            }
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        </div>
      ) : null}
    </div>
  );
};
