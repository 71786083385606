/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  ClubResumeStaffMember,
  DiveCenterDailyConfig,
  DiveSessionResumeParticipant,
  DiveSessionResumeParticipantsByGroup,
  DiveSessionStaffMemberTableModelStaffMember,
  DiveSessionTimeDayPeriod,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  diveSessionGroupBuilder,
  diveSessionGroupDiveModeBuilder,
  diveSessionMultipleBuilder,
  diveSessionStaffMembersTableModelBuilder,
  staffMemberResumeSessionsBuilder,
} from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useAppRouter } from 'src/business/_core/data/hooks/useAppRouter';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiveSessionEditorFormModel } from '../../forms/DiveSessionEditForm/model';
import { DiveSessionEditorDialogInputState } from '../../models';
import { DiveSessionEditorTabId } from './DiveSessionEditorTab';
import { diveSessionEditorDialogChangesAggregator } from './diveSessionEditorDialogChangesAggregator';
import {
  DiveSessionEditorInitialValue,
  DiveSessionEditorUpdateState,
} from './model';
import {
  diveSessionEditorDialogInitialFormValueBuilder,
  diveSessionEditorDialogInitialValueBuilder,
} from './services';
import { diveSessionDialogTab3GroupsStateUpdator } from './tabs/DiveSessionDialogTab3Groups/diveSessionDialogTab3GroupsStateUpdator.service';

export function useDiveSessionEditorDialogLocalState({
  inputState,
}: {
  inputState: DiveSessionEditorDialogInputState;
}) {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const { mode, isOpen } = inputState;

  const initialValue: DiveSessionEditorInitialValue = useMemo(
    () =>
      diveSessionEditorDialogInitialValueBuilder.buildInitialValue({
        inputState,
        diveCenterResume,
      }),
    [diveCenterResume, inputState],
  );

  const initialFormValue: DiveSessionEditorFormModel = useMemo(
    () =>
      diveSessionEditorDialogInitialFormValueBuilder.buildInitialFormValue({
        inputState,
        initialValue,
      }),
    [initialValue, inputState],
  );

  const participantFirstNameBefore = useMemo(
    () =>
      clubResume.clubSettings.ui?.planning?.participantFirstNameBefore ?? false,
    [clubResume.clubSettings.ui?.planning?.participantFirstNameBefore],
  );
  const staffFirstNameBefore = useMemo(
    () => clubResume.clubSettings.ui?.planning?.staffFirstNameBefore ?? false,
    [clubResume.clubSettings.ui?.planning?.staffFirstNameBefore],
  );
  const [tabId, setTabIdInner] = useState<DiveSessionEditorTabId>(
    inputState.tabId ?? 'edit-session',
  );
  const [previousTabId, setPreviousTabId] =
    useState<DiveSessionEditorTabId>(undefined);
  const setTabId = useCallback(
    (newTabId: DiveSessionEditorTabId) => {
      setPreviousTabId(tabId);
      setTabIdInner(newTabId);
    },
    [tabId],
  );

  const [staffMemberIdToEdit, setStaffMemberIdToEdit] = useState<string>();

  const [isPersistInProgress, setIsPersistInProgress] = useState(false);

  const form = useForm<DiveSessionEditorFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  // const formState2 = useFormState({control})

  const formValue = useWatch({
    control,
  }) as DiveSessionEditorFormModel;

  const initialUpdateState: DiveSessionEditorUpdateState = useMemo(() => {
    const state: DiveSessionEditorUpdateState = {
      hasChanges: false,
      diveSessionGroupsChanges: [],
      clubParticipantsChanges: [],
      diversChanges: [],
      diveSessionsChanges: [],
      bookingProductsChanges: [],
      bookingSessionParticipantsChanges: [],
      bookingSessionsChanges: [],
      staffMembersChanges: [],
      dailyConfigsChanges: [],
    };

    return state;
  }, []);

  const [updateStateInner, setUpdateStateInner] =
    useState<DiveSessionEditorUpdateState>(initialUpdateState);

  const setUpdateState = useCallback(
    (updateState: DiveSessionEditorUpdateState) => {
      const newSessionRef =
        updateState.diveSessionsChanges?.[0]?.created?.reference;
      if (newSessionRef) {
        // FIXME (@see hack SESSION_MULTIPLE_CREATE_REF): nouvelle session, avec la référence qui change plusieurs fois (!)
        for (const group of updateState.diveSessionGroupsChanges ?? []) {
          if (
            group?.created &&
            group?.created?.diveSessionReference !== newSessionRef
          )
            // FIXME (@see hack SESSION_MULTIPLE_CREATE_REF): on corrige la diveSessionReference dans le groupe
            group.created.diveSessionReference = newSessionRef;
        }
      }
      setUpdateStateInner({
        ...updateState,
        hasChanges: true,
      });
    },
    [setUpdateStateInner],
  );

  // loaded data + changes applied
  const { updateState, aggregatedData } = useMemo(
    () =>
      diveSessionEditorDialogChangesAggregator.aggregateChanges({
        diveCenterResume,
        formValue,
        isDirty: formState.isDirty, // Note: il semble que formState n'est pas immuable, donc il faut avoir un observer sur formState.isDirty et pas juste sur formState
        initialValue,
        updateState: updateStateInner,
        initialDailyConfigs: inputState.dailyConfigs,
      }),
    [
      diveCenterResume,
      formValue,
      formState.isDirty,
      initialValue,
      updateStateInner,
      inputState.dailyConfigs,
    ],
  );

  const dayDiveSessions: ClubPlanningLightSessionDef[] = useMemo(() => {
    return inputState.diveSessionResumes.map((s) => {
      const timeDayPeriod: DiveSessionTimeDayPeriod =
        clubDiveSessionThemeBuilder.buildTimeDayPeriod(s.time);
      return {
        ...s,
        timeDayPeriod,
      };
    });
  }, [inputState.diveSessionResumes]);

  const daySessionsAggregated: ClubPlanningLightSessionDef[] = useMemo(() => {
    return dayDiveSessions.map((x) => {
      if (x._id === aggregatedData.diveSession._id) {
        return {
          ...x,
          ...aggregatedData.diveSession,
          groups: aggregatedData.groups,
          clubParticipants: aggregatedData.clubParticipants,
        };
      }
      return x;
    });
  }, [
    aggregatedData.clubParticipants,
    aggregatedData.diveSession,
    aggregatedData.groups,
    dayDiveSessions,
  ]);

  const dailyConfigsAggregated: DiveCenterDailyConfig[] = useMemo(() => {
    return aggregatedData.dailyConfigs.filter(
      (x) => x.dateReference === aggregatedData.diveSession.dayReference,
    );
  }, [aggregatedData.dailyConfigs, aggregatedData.diveSession.dayReference]);

  const {
    diveSessions: dailySessions,
    staffMembersResumeSessions,
  }: {
    diveSessions: ClubPlanningLightSessionDef[];
    staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
  } = useMemo(
    () =>
      staffMemberResumeSessionsBuilder.buildStaffMemberResumeSessions({
        diveCenterResume,
        sessions: daySessionsAggregated,
        staffFirstNameBefore,
        staffScope: 'all-with-active-working-period',
        dailyConfigs: dailyConfigsAggregated,
        days: [], // pas utilisé ici?
      }),
    [
      dailyConfigsAggregated,
      daySessionsAggregated,
      diveCenterResume,
      staffFirstNameBefore,
    ],
  );

  const [selectedMemberId, setSelectedMemberId] = useState<string>();

  const toogleSelectMember = useCallback(
    (memberId: string) => {
      setSelectedMemberId(selectedMemberId === memberId ? undefined : memberId);
    },
    [selectedMemberId],
  );

  const [
    selectedUngrouppedParticipantsIds,
    setSelectedUngrouppedParticipantsIds,
  ] = useState<string[]>([]);

  const {
    diveSession: originalDiveSession,
    groups,
    staffMembers,
    clubParticipants,
    divers,
    dailyConfigs,
  } = aggregatedData;

  const selectedMember: ClubResumeStaffMember = useMemo(
    () => staffMembers.find((x) => x._id === selectedMemberId),
    [selectedMemberId, staffMembers],
  );

  const isMultiSession = !!originalDiveSession.diveTourSession2;

  const [sameStaffSession1, sameParticipantsSession1] = useWatch({
    control,
    name: [
      'diveSession.diveTourSession2.sameStaffSession1',
      'sameParticipantsSession1',
    ],
  });
  const isMultiSessionConfigForStaff =
    !!originalDiveSession.diveTourSession2 &&
    diveSessionMultipleBuilder.isMultiSessionConfigForStaff({
      sameStaffSession1,
    });
  const isMultiSessionConfigForParticipants =
    !!originalDiveSession.diveTourSession2 && !sameParticipantsSession1;

  const staffMembersFull: DiveSessionStaffMemberTableModelStaffMember[] =
    useMemo(
      () =>
        diveSessionStaffMembersTableModelBuilder.buildStaffMembersFull({
          clubParticipants,
          diveSession: originalDiveSession,
          groups,
          staffMembers,
          filterMode: 'all',
          staffFirstNameBefore: true,
          dailyConfigs,
          clubSettings: clubResume?.clubSettings,
        }),
      [
        clubParticipants,
        originalDiveSession,
        groups,
        staffMembers,
        dailyConfigs,
        clubResume?.clubSettings,
      ],
    );

  const editStaffMember = useCallback(
    (x: DiveSessionStaffMemberTableModelStaffMember) => {
      setStaffMemberIdToEdit(x.staffMember._id);
      setTabId('edit-staff-members');
    },
    [setTabId],
  );

  const participantsResumes: DiveSessionResumeParticipant[] = useMemo(
    () =>
      aggregatedData.clubParticipants.map((p) => {
        const resume: DiveSessionResumeParticipant = {
          ...p,
          diver: aggregatedData.divers.find((d) => d._id === p.diverId),
          diveSession: undefined,
          bookingJourney: undefined,
        };
        return resume;
      }),
    [aggregatedData.clubParticipants, aggregatedData.divers],
  );

  const diveSessionParticipantsByGroup: DiveSessionResumeParticipantsByGroup =
    useMemo(
      () =>
        diveSessionGroupBuilder.buildDiveSessionParticipantsGroups({
          diveSession: originalDiveSession,
          groups,
          participants: participantsResumes,
          staffMembers,
          ignoreCancelledParticipants: true,
        }),
      [originalDiveSession, groups, participantsResumes, staffMembers],
    );
  const { scrollToTop } = useAppRouter();
  const groupSelectedDivers = useCallback(
    ({ staffMember }: { staffMember?: ClubResumeStaffMember }) => {
      const selectedUngrouppedParticipants = selectedUngrouppedParticipantsIds
        .map((participantId) => {
          const participant = participantsResumes.find(
            (p) => participantId === p._id,
          );
          if (participant) {
            return participant;
          }
          return undefined;
        })
        .filter((x) => !!x);

      const diveMode =
        diveSessionGroupDiveModeBuilder.buildFromParticipantsDiveModes({
          diveModes: selectedUngrouppedParticipants.map((p) => p.diveMode),
          hasDiveGuide: false,
          hasInstructor: false,
          debugContext: '1 groupSelectedDivers',
        });
      let localUpdateState: DiveSessionEditorUpdateState = {
        ...updateState,
        hasChanges: true,
      };
      const diveSession = originalDiveSession;
      localUpdateState =
        diveSessionDialogTab3GroupsStateUpdator.groupParticipants({
          updateState: localUpdateState,
          existingGroup: undefined,
          diveSession,
          diveMode,
          clubReference,
          participants: selectedUngrouppedParticipants,
          staffMember,
        });

      setUpdateState(localUpdateState);
      setSelectedUngrouppedParticipantsIds([]);
      setSelectedMemberId(undefined);
      scrollToTop('app-page-content-container', {
        behavior: 'smooth',
      });
    },
    [
      clubReference,
      originalDiveSession,
      participantsResumes,
      scrollToTop,
      selectedUngrouppedParticipantsIds,
      setUpdateState,
      updateState,
    ],
  );

  return {
    data: {
      participantsResumes,
      selectedUngrouppedParticipantsIds,
      diveSessionParticipantsByGroup,
      selectedMember,
      tabId,
      previousTabId,
      staffMembersResumeSessions,
    },
    actions: {
      groupSelectedDivers,
      setSelectedUngrouppedParticipantsIds,
      toogleSelectMember,
    },
    form,
    aggregatedData,
    clubReference,
    isPersistInProgress,
    setIsPersistInProgress,
    mode,
    isOpen,
    initialValue,
    setUpdateState,
    updateState,
    inputState,
    tabId,
    setTabId,
    staffMemberIdToEdit,
    setStaffMemberIdToEdit,
    isMultiSessionConfigForStaff,
    isMultiSessionConfigForParticipants,
    staffMembersFull,
    participantFirstNameBefore,
    staffFirstNameBefore,
    editStaffMember,
  };
}

export type DiveSessionEditorDialogLocalState = ReturnType<
  typeof useDiveSessionEditorDialogLocalState
>;
