import { dateService } from '@mabadive/app-common-services';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { of } from 'rxjs';
import { delay, filter, first, map, switchMap } from 'rxjs/operators';
import { useLoadable, useRedirect } from 'src/business/_core/data/hooks';
import { diveSessionResumeFullRepository } from 'src/business/_core/data/store-repository';
import { AppLoader, AppPageContainer } from 'src/business/_core/modules/layout';
import { SideMenu } from 'src/business/_core/modules/layout/components/SideMenu';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import { clubStore, uiStore } from 'src/business/_core/store';
import { AppAuth, useAppSecurityUser } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DashboardHomePage, DashboardReportPage } from 'src/pages/DA-dashboard';

import { weekPlanningBuilder } from '@mabadive/app-common-services';
import { DashboardExportPage } from 'src/pages/DA-dashboard/DA-09-dashboard-export';
import { ClubListsRoutes } from 'src/pages/LI-lists/ClubListsRoutes';
import { ClubSettingsRoutes } from 'src/pages/SE-settings/ClubSettingsRoutes';
import useRouter from 'use-react-router';
import { GenericNavigationContext } from '../../../club-diver-participant/ClubParticipantNavigationContext/GenericNavigationContext.type';
import { ClubParticipantRoot } from '../../../club-diver-participant/ClubParticipantRoot';
import {
  CreateSimpleDiverAndBookingPage,
  SelectOrCreateDiverAndBookingPage,
} from '../../../club-diver-participant/pages/CreateBookingPages';
import { CreateBookingInitialPlanPage } from '../../../club-diver-participant/pages/CreateBookingPages/BO-03_CreateBookingInitialPlanPage/CreateBookingInitialPlanPage';
import { ContactPage } from '../../../club-offer';
import { ClubOfferViewPage } from '../../../club-offer/ClubOfferViewPage';
import { ClubPlanningParticipantsPage } from '../../../club-planning/ClubPlanningParticipantsPage';
import { ClubPlanningStaffPage } from '../../../club-planning/ClubPlanningStaffPage';
import { ClubSettingsOfferRoot } from '../../../club-settings/club-settings-offers';
import { useClubMenuEntries } from './useClubMenuEntries.service';
import { useClubRootLoadableContent } from './useClubRootLoadableContent.hook';
import { useClubRootSpaceNotifications } from './useClubRootSpaceNotifications.hook';

export const ClubRootSpace = React.memo(function ClubRootSpaceMemo() {
  const { _loaded, initialDataLoaded, ...dataLoading } = useLoadable({
    debugName: 'ClubRootSpace.bootstrap',
    initialValueFromLoadSnapshot: true,
    load: () =>
      clubStore.initialDataLoaded.get().pipe(
        filter((x) => !!x),
        first(),
        map((initialDataLoaded) => ({ initialDataLoaded })),
      ),
  });

  const { match } = useRouter();

  const user = useAppSecurityUser();

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = user?.diveCenterIdFocus;
  const clubReference = user?.clubReference;

  const redirectTo = useRedirect();

  const { content: diveCenterResumeFetched, ...loadableContent } =
    useClubRootLoadableContent();

  useEffect(() => {
    if (loadableContent?.lastActionStatus === 'error') {
      appLogger.error('Dive center not found: logout');
      redirectTo('/logout');
    }
  }, [loadableContent?.lastActionStatus, redirectTo]);

  const notificationsContent = useClubRootSpaceNotifications();

  useEffect(() => {
    if (loadableContent?.lastActionStatus === 'success') {
      const delayInMs = 1000;
      setTimeout(() => {
        // after delayInMs, pre-fetch more data

        const weekPlanning = weekPlanningBuilder.buildWeekPlanning({
          focusDate: new Date(),
        });
        const baseDay = weekPlanning.daysPlanning[0].weekDate.date;

        // NOTE: la semaine en cours n'est pas préchargée, on pourrait le faire si on n'est pas sur le planning
        prefetchPlanning({
          baseDay,
          weekOffset: 1, // w+1: next week
          clubReference,
          diveCenterId,
        })
          .pipe(
            delay(1000), // wait 1s before next fetch
            switchMap(() =>
              prefetchPlanning({
                baseDay,
                weekOffset: -1, // w-1: previous week
                clubReference,
                diveCenterId,
              }),
            ),
            delay(1000), // wait 1s before next fetch
            switchMap(() =>
              prefetchPlanning({
                baseDay,
                weekOffset: 2, // w+2
                clubReference,
                diveCenterId,
              }),
            ),
          )
          .subscribe(
            () => appLogger.info('[ClubRootSpace] data pre-fetched'),
            (err) =>
              appLogger.error('[ClubRootSpace] data pre-fetch error', err),
          );
      }, delayInMs);
    }
  }, [clubReference, diveCenterId, loadableContent?.lastActionStatus]);

  const { entries, extraButtons } = useClubMenuEntries({
    user,
    notificationsContent,
  });

  useEffect(() => {
    uiStore.isMenuEmpty.set(false);
    return () => {
      uiStore.isMenuEmpty.set(true);
    };
  });

  const queryParams = queryString.parse(window.location.search) as any;

  const navigationContext: GenericNavigationContext = {
    origin: queryParams.origin,
    diveSessionReference: queryParams.diveSessionReference,
    planningFocusDateRef: queryParams.planningFocusDateRef,
    action: queryParams.action,
  };

  return _loaded &&
    initialDataLoaded &&
    loadableContent?.contentState === 'full' &&
    clubResume &&
    diveCenterResume ? (
    <>
      <SideMenu entries={entries} extraButtons={extraButtons} />
      <main className={'flex w-screen h-screen overflow-y-hidden pt-[50px]'}>
        <Switch>
          <AppRoute
            path={`${match.url}/offer`}
            exact
            component={ClubOfferViewPage}
            checkAuth={({ az }) => az?.view?.settings?.mabadiveBilling}
          />
          <AppRoute
            path={`${match.url}/contact`}
            exact
            component={ContactPage}
          />
          <AppRoute
            path={`${match.url}/staff`}
            exact
            component={ClubPlanningStaffPage}
            checkAuth={({ az }) => az?.staff?.view?.staffPlanning}
          />
          <AppRoute
            path={`${match.url}/planning`}
            exact
            component={ClubPlanningParticipantsPage}
            checkAuth={({ az }) => az?.view?.planning?.participant}
          />
          <AppRoute
            path={`${match.url}/planning-:year-:month-:day`} // URL déprécié, à retirer
            exact
            component={ClubPlanningParticipantsPage}
            checkAuth={({ az }) => az?.view?.planning?.participant}
          />
          <AppRoute
            path={`${match.url}/booking/create`}
            exact
            component={SelectOrCreateDiverAndBookingPage}
            navigationContext={navigationContext}
            auth={{ requiredRoles: ['club-edit-participant'] }}
          />
          <AppRoute
            path={`${match.url}/booking/create/simple`}
            exact
            component={CreateSimpleDiverAndBookingPage}
            navigationContext={navigationContext}
            auth={{ requiredRoles: ['club-edit-participant'] }}
          />
          <AppRoute
            path={`${match.url}/booking/create/initial-plan`}
            exact
            component={CreateBookingInitialPlanPage}
            navigationContext={navigationContext}
            auth={{ requiredRoles: ['club-edit-participant'] }}
          />
          <AppRoute
            path={`${match.url}/diver-:diverId`}
            component={ClubParticipantRoot}
            auth={{ requiredRoles: ['club-view-participant-diver'] }}
          />
          <AppRoute
            path={`${match.url}/dashboard`}
            exact
            component={DashboardHomePage}
            auth={{ requiredRoles: ['club-view-dashboard'] }}
          />
          <AppRoute
            path={`${match.url}/dashboard/report`}
            exact
            component={DashboardReportPage}
            auth={{ requiredRoles: ['club-view-dashboard-stats'] }}
          />
          <AppRoute
            path={`${match.url}/dashboard/export`}
            exact
            component={DashboardExportPage}
            auth={{
              requiredRolesOneOf: [
                'club-download-bookings',
                'club-download-daily-cash',
              ],
            }}
          />
          <AppRoute
            path={`${match.url}/settings`}
            component={ClubSettingsRoutes}
            auth={{ requiredRoles: ['club-view-settings'] }}
          />
          <AppRoute
            path={`${match.url}/lists`}
            component={ClubListsRoutes}
            auth={{ requiredRoles: ['club-view-lists'] }}
          />
          <AppRoute
            path={`${match.url}/services`} // TODO remplacer par /settings/offers
            component={ClubSettingsOfferRoot}
            auth={{ requiredRoles: ['club-view-settings'] }}
          />
          {/* default route */}
          <AppRoute
            path={`${match.url}/`}
            redirectTo={({ appAuth }) =>
              of(redirectToDefaultRoute({ appAuth, matchUrl: match.url }))
            }
          />
        </Switch>
      </main>
    </>
  ) : (
    <main className={'flex w-screen h-screen overflow-y-hidden pt-[50px]'}>
      <AppPageContainer className={'bg-gray-50 p-4'}>
        <AppLoader type="loading" />
      </AppPageContainer>
    </main>
  );
});

function prefetchPlanning({
  baseDay,
  weekOffset,
  clubReference,
  diveCenterId,
}: {
  baseDay: Date;
  weekOffset: number;
  clubReference: string;
  diveCenterId: string;
}) {
  const minDateInclusive = dateService.add(baseDay, weekOffset * 7, 'day');
  const maxDateExclusive = dateService.add(minDateInclusive, 7, 'day');
  const loadCriteria = {
    clubReference,
    diveCenterId,
    minDateInclusive: minDateInclusive,
    maxDateExclusive: maxDateExclusive,
  };

  // fetch data and save to store
  const prefetch$ = diveSessionResumeFullRepository
    .findManyWithSubscription(loadCriteria, {
      mode: 'remote-only',
    })
    .pipe(first((x) => x.lastActionStatus !== 'in-progress'));

  return prefetch$;
}

function redirectToDefaultRoute({
  appAuth,
  matchUrl,
}: {
  appAuth: AppAuth;
  matchUrl: string;
}): string {
  if (appAuth) {
    return appRouteBuilder.getDefaultRouteFromAuth({ appAuth });
  }
  return '/';
}
