import clsx from 'clsx';
import React, { useMemo } from 'react';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';

import {
  DashboardPurchasesFirstDivesOverPeriod,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { PurchasesFirstDivesOverPeriodTotal } from './DashboardReportPageGlobalActivityPurchasesFirstDivesOverPeriod';
import { DashboardReportPageGlobalActivityPurchasesFirstDivesOverPeriodTableRow } from './DashboardReportPageGlobalActivityPurchasesFirstDivesOverPeriodTableRow';

export const DashboardReportPageGlobalActivityPurchasesFirstDivesOverPeriodTable =
  ({
    purchasesFirstDivesOverPeriod: results,
    total,
    comparisonDetails,
    className,
  }: {
    purchasesFirstDivesOverPeriod: ProStatsFetchResults['purchasesFirstDivesOverPeriod'];
    total: PurchasesFirstDivesOverPeriodTotal;
    comparisonDetails: DashboardReportComparisonDetails<any>;
    className?: string;
  }) => {
    const mainCurrency = useAppCurrencyMain();

    const data = results?.data;

    const {
      localState,
      comparisonFetchResult,
      showPeriodRangeComparison,
      // sharedState: [sharedState, setSharedState],
    } = comparisonDetails;

    const comparisonData =
      comparisonFetchResult?.purchasesFirstDivesOverPeriod?.data;

    const firstDivesDetails = useMemo(() => {
      const baseFirstDives = data ?? [];
      const comparisonFirstDives = comparisonData ?? [];

      const missingFirstDives = comparisonFirstDives.filter(
        (itemB) =>
          !baseFirstDives.some((itemA) => itemA.offerName === itemB.offerName),
      );

      const updatedFirstDives: DashboardPurchasesFirstDivesOverPeriod[] = [
        ...baseFirstDives,
        ...missingFirstDives.map((missingFirstDive) => {
          const x: DashboardPurchasesFirstDivesOverPeriod = {
            ...missingFirstDive,
            purchasePackageCount: 0,
            purchasePackagePriceSum: 0,
            purchasePackagePriceAvg: 0,
            purchasePackagePayedAmount: 0,
            purchasePackageTotalPriceThirdPartyCollect: 0,
          };
          return x;
        }),
      ];

      return dataSorter.sortMultiple(updatedFirstDives, {
        getSortAttributes: (firstDive) => {
          // const firstDiveIndex = DIVE_TRAININGS.map(x=>x.reference).indexOf(firstDive.firstDiveReference)
          return [
            {
              value: firstDive.offerName,
            },
            {
              value: firstDive.purchasePackageCount,
              asc: false,
            },
          ];
        },
      });
    }, [comparisonData, data]);

    return !firstDivesDetails?.length ? null : (
      <table className={clsx('app-table text-gray-600', className)}>
        <thead>
          <tr className="uppercase">
            <th className="text-left text-base 2xl:text-lg px-2">Baptême</th>
            <th className="text-center text-base 2xl:text-lg px-2">Qté</th>
            <th
              className={clsx(
                'text-center text-base px-2 leading-3 truncate',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              Prix moyen
            </th>
            <th className="text-center text-base 2xl:text-lg px-2">facturé</th>
            <th
              className={clsx(
                'text-center text-base 2xl:text-lg px-2',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              encaissé
            </th>
          </tr>
        </thead>
        <tbody>
          {firstDivesDetails.map((firstDive) => (
            <DashboardReportPageGlobalActivityPurchasesFirstDivesOverPeriodTableRow
              key={[firstDive.offerName].join('-')}
              firstDive={firstDive}
              comparisonDetails={comparisonDetails}
            />
          ))}
        </tbody>
        <tbody>
          <tr className="text-left">
            <th
              className={clsx(
                'text-base 2xl:text-lg bg-gray-500 text-white text-left whitespace-nowrap px-2',
              )}
            >
              TOTAL
            </th>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackageCount === 0 ? 'opacity-30' : '',
              )}
            >
              {total.purchasePackageCount}{' '}
              <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
            </td>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackagePriceAvg === 0 ? 'opacity-30' : '',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              <AppPriceLabel
                amount={total.purchasePackagePriceAvg}
                mainCurrency={mainCurrency}
                signColorClassName="text-white"
              />
            </td>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackagePriceSum === 0 ? 'opacity-30' : '',
              )}
            >
              <AppPriceLabel
                amount={total.purchasePackagePriceSum}
                mainCurrency={mainCurrency}
                signColorClassName="text-white"
              />
            </td>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackagePayedAmount === 0 ? 'opacity-30' : '',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              <AppPriceLabel
                amount={total.purchasePackagePayedAmount}
                mainCurrency={mainCurrency}
                signColorClassName="text-white"
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
