import {
  ClubPaymentPlatformCaptureMethod,
  CLUB_PAYMENT_PLATFORM_CAPTURE_METHODS,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useAppPaymentPlatformCaptureMethodsOptions(): ValueLabel<ClubPaymentPlatformCaptureMethod>[] {
  const options: ValueLabel<ClubPaymentPlatformCaptureMethod>[] = useMemo(
    () =>
      CLUB_PAYMENT_PLATFORM_CAPTURE_METHODS.map((value) => ({
        value: value,
        label: value,
      })),
    [],
  );
  return options;
}
