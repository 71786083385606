/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_CURRENCIES } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppInputBooleanSwitchRHF,
  AppMultiCheckboxesRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppFormLabel } from 'src/lib/form/components/AppFormLabel';
import {
  useAppCurrencyOptions,
  useAppPaymentMethodsOptions,
} from 'src/pages/_components/options';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { ClubSettingsGeneralFormModel } from '../../../_model';
import { ClubSettingsGeneralPaymentsEditFormCurrency } from './ClubSettingsGeneralPaymentsEditFormCurrency';

export const ClubSettingsGeneralPaymentsEditFormMain = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    setFocus,
  } = form;

  const clubResume = useClubResume();

  const [paymentsEnabled, stripeEnabled] = useWatch({
    control,
    name: [
      'clubSettings.general.payments.enabled',
      'clubSettings.general.payments.online.stripe.enabled',
    ],
  });

  const customerPaymentTypeOptions = useAppPaymentMethodsOptions('all');

  const currenciesFieldArray = useFieldArray({
    control,
    name: 'clubSettings.general.payments.currencies',
    shouldUnregister: true,
    keyName: 'reference',
  });

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const { fields, append, prepend, remove, swap, move, insert } =
    currenciesFieldArray;

  const remainingPaymentCurrencies = useMemo(() => {
    const alreadyUsedCurrencyIsoCode = fields.map((x) => x.currencyIsoCode);
    return APP_CURRENCIES.filter(
      (x) => !alreadyUsedCurrencyIsoCode.includes(x.isoCode),
    );
  }, [fields]);
  const remainingPaymentCurrencyOptions = useAppCurrencyOptions(
    remainingPaymentCurrencies,
  );

  return (
    <ClubSettingsSection title="Paiements">
      {isSuperAdmin && (
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Activer les paiements"
          control={control}
          name={'clubSettings.general.payments.enabled'}
          required={false}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
      )}
      {paymentsEnabled && (
        <>
          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Moyens de paiement"
            control={control}
            name={'clubSettings.general.payments.methods'}
            required={false}
            renderComponent={(props) => (
              <AppMultiCheckboxesRHF
                {...props}
                options={customerPaymentTypeOptions}
              />
            )}
          />
          <AppFormLabel label="Devises de paiement" />
          {currenciesFieldArray.fields.map((field, fieldIndex) => {
            return (
              <ClubSettingsGeneralPaymentsEditFormCurrency
                key={field.reference}
                form={form}
                fieldArray={currenciesFieldArray}
                field={field}
                fieldIndex={fieldIndex}
              />
            );
          })}
          {remainingPaymentCurrencyOptions.length > 0 && (
            <div className="">
              <AppFormControlRHF_Deprecated
                key={fields.length} // key pour forcer à recréer le composant, et désélectionner la valeur précédente
                className="w-[16rem]"
                label="Ajouter une devise"
                control={control}
                name={'selectedPaymentCurrencyToAddIsoCode'}
                required={false}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={remainingPaymentCurrencyOptions}
                    onChange={(selectedPaymentCurrencyToAddIsoCode) => {
                      if (selectedPaymentCurrencyToAddIsoCode) {
                        setValue('selectedPaymentCurrencyToAddIsoCode', null);
                        append({
                          currencyIsoCode: selectedPaymentCurrencyToAddIsoCode,
                          defaultRate: 1,
                          defaultRateReverse: false,
                        });
                      }
                    }}
                  />
                )}
              />
            </div>
          )}
        </>
      )}
    </ClubSettingsSection>
  );
};
