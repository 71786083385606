// https://heroicons.com/ ?

import { HomeIcon } from '@heroicons/react/24/outline';
import { IoMdBoat } from 'react-icons/io';
import { ReactComponent as border } from 'src/assets/icons/boats/beach.svg';
import { ReactComponent as pool } from 'src/assets/icons/sites/swimming-pool.svg';

export const AppIconsBoatDeparture = {
  pool,
  border,
  boat: IoMdBoat,
  room: HomeIcon,
};
