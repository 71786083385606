import { OnlineBookingResumeForList } from '@mabadive/app-common-model';
import { AppDurationParserString } from '@mabadive/app-common-services';
import { useMemo } from 'react';

import {
  AppFetchDataCacheStore,
  appFetchDataCacheStoreBuilder,
} from 'src/business/_core/modules/root/app-cache/AppFetchDataCacheStore';
import { useAppFetchDataListEntitiesWithCache } from 'src/business/_core/modules/root/app-cache/AppFetchDataCacheStore/useAppFetchDataListEntitiesWithCache.hook';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  OnlineBookingGqlCollectionCriteria,
  OnlineBookingGqlCollectionCriteriaUI,
  onlineBookingGqlGraphqlFetcher,
} from './fetch';

export const clubOrdersCacheStore: AppFetchDataCacheStore<
  OnlineBookingGqlCollectionCriteria,
  OnlineBookingResumeForList[]
> = appFetchDataCacheStoreBuilder.buildCache({
  baseKey: 'club-online-bookings',
  cacheValidity: '2 days',
});

export function useFetchOrdersWithCache(
  criteriaUI: OnlineBookingGqlCollectionCriteriaUI,
  options: {
    autoRefetchInterval: AppDurationParserString;
    staleInterval: AppDurationParserString;
  },
) {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const clubReference = diveCenterResume.clubReference;
  const fetchCriteria: OnlineBookingGqlCollectionCriteria = useMemo(() => {
    // ces critères vont générer la clé du cache
    const criteria: OnlineBookingGqlCollectionCriteria = {
      ...criteriaUI,
      // important: ne pas utiliser ...criteria car ça entraine une mise à jour de l'objet
      clubReference,
      diveCenterId,
      // arrivalDate: criteria?.arrivalDate,
      // departureDate: criteria?.departureDate,
      // archivedExclusive: criteria?.archivedExclusive,
    };
    return criteria;
  }, [clubReference, criteriaUI, diveCenterId]);

  const cacheState =
    useAppFetchDataListEntitiesWithCache<OnlineBookingResumeForList>(
      fetchCriteria,
      {
        store: clubOrdersCacheStore,
        ...options,
        fetch: async (
          fetchCriteria,
          { cacheState, fetchType },
        ): Promise<OnlineBookingResumeForList[]> => {
          // NOTE: maxResults ne doit pas être dans les criteria, sinon il va impacter la clé du cache

          const results = await onlineBookingGqlGraphqlFetcher
            .findMany(
              {
                ...fetchCriteria,
                maxResults: 1000,
                updatedAfter:
                  fetchType === 'partial'
                    ? cacheState?.fetchResult?.dataModifiedAt
                    : undefined,
              },
              { type: 'query' },
            )
            .toPromise();
          return results;
        },

        getUpdateDate: (a: OnlineBookingResumeForList) =>
          new Date(Math.max(a.onlineBookingLastUpdateDate?.getTime())),
      },
    );

  return cacheState;
}
