/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { AppNavigationStep } from 'src/business/_core/modules/layout';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  DiverPurchasePaymentEditorDialogResult,
  DiverPurchasePaymentEditorDialogState,
} from '../../models';
import { DiverPurchasePaymentEditorBookingDepositInitialModel } from './DiverPurchasePaymentEditorBookingDepositModel.type';
import { DiverPurchasePaymentEditorPackageInitialModel } from './DiverPurchasePaymentEditorPackageInitialModel.type';
import { DiverPurchasePaymentEditorPaymentChangesModel } from './DiverPurchasePaymentEditorPaymentChangesModel.type';
import { DiverPurchasePaymentEditorFormModel } from './components';
import { diverDiverPurchasePaymentEditorDialogUpdateBuilder } from './diverDiverPurchasePaymentEditorDialogUpdateBuilder';
import { useAggregatePaymentChangesModel } from './useAggregatePaymentChangesModel.hook';
import { useDiverPurchasePaymentEditorDialogInitialValue } from './useDiverPurchasePaymentEditorDialogInitialValue.hook';
import { useInitialPurchasePaymentBookingDeposits } from './useInitialPurchasePaymentBookingDeposits.hook';
import { useInitialPurchasePaymentPackages } from './useInitialPurchasePaymentPackages.hook';

export type DiverPurchasePaymentEditorDialogLocalState = ReturnType<
  typeof useDiverPurchasePaymentEditorDialogLocalState
>;

export type EditPaymentNavigationStepId = 'select-purchases' | 'edit-details';

export function useDiverPurchasePaymentEditorDialogLocalState({
  onConfirm,
  inputState,
  setInputState,
}: {
  inputState: DiverPurchasePaymentEditorDialogState;
  setInputState: React.Dispatch<
    React.SetStateAction<DiverPurchasePaymentEditorDialogState>
  >;
  onConfirm?: (result: DiverPurchasePaymentEditorDialogResult) => void;
}) {
  const {
    purchasePayment,
    mode,
    defaultPackagesIds,
    defaultBookingDepositsIds,
    bookingDepositsWithPayments,
  } = inputState;

  const {
    divers,
    paymentsBookingDepositsDetails,
    purchasePackagesWithPayments,
    bookingResumesLoaded,
  } = inputState.aggregatedData;

  const purchasePackages = purchasePackagesWithPayments?.map(
    (x) => x.purchasePackage,
  );
  const bookingDepositsInput = bookingDepositsWithPayments?.map(
    (x) => x.bookingDeposit,
  );

  const [currentStep, setCurrentStep] = useState<EditPaymentNavigationStepId>(
    inputState.mode === 'create' ? 'select-purchases' : 'edit-details',
  );

  const steps: AppNavigationStep<EditPaymentNavigationStepId>[] = [
    { id: 'select-purchases', label: 'Prestations' },
    { id: 'edit-details', label: 'Paiement' },
  ];

  // TODO: empêcher de passer à l'onglet suivant tant que le précédent n'est pas valide

  const navigation = {
    steps,
    currentStep,
    setCurrentStep,
  };

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setInputState({
        ...inputState,
        isOpen,
      });
    },
    [setInputState, inputState],
  );

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const paymentsSettings = clubSettings?.general?.payments;
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const initialPurchasePaymentPackages: DiverPurchasePaymentEditorPackageInitialModel[] =
    useInitialPurchasePaymentPackages(
      mode,
      purchasePayment,
      divers,
      purchasePackages,
    );

  const initialPurchasePaymentBookingDeposits: DiverPurchasePaymentEditorBookingDepositInitialModel[] =
    useInitialPurchasePaymentBookingDeposits(
      mode,
      purchasePayment,
      paymentsBookingDepositsDetails,
      divers,
      bookingDepositsInput,
    );

  const initialFormValue = useDiverPurchasePaymentEditorDialogInitialValue({
    purchasePayment, // undefined if 'create' mode
    initialPurchasePaymentPackages,
    initialPurchasePaymentBookingDeposits,
    defaultPackagesIds,
    defaultBookingDepositsIds,
    mode,
    aggregatedData: inputState.aggregatedData,
    diveCenterId,
    defaultDiverId: inputState.defaultDiverId,
  });

  const form = useForm<DiverPurchasePaymentEditorFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const [formPackages, bookingDeposits, bookingDepositId, diverId] = useWatch({
    control,
    name: ['packages', 'bookingDeposits', 'bookingDepositId', 'diverId'],
  });

  const selectedDiver = useMemo(() => {
    const localDiverId =
      diverId ?? inputState?.defaultDiverId ?? initialFormValue?.diverId;
    if (localDiverId) {
      const diver = inputState.aggregatedData.divers.find(
        (x) => x._id === localDiverId,
      );
      return diver;
    }
  }, [
    diverId,
    inputState?.defaultDiverId,
    inputState.aggregatedData.divers,
    initialFormValue?.diverId,
  ]);

  const paymentWithBookingDeposit = useMemo(
    () =>
      bookingDepositsWithPayments.find(
        (x) => x.bookingDeposit._id === bookingDepositId,
      )?.bookingDeposit,
    [bookingDepositsWithPayments, bookingDepositId],
  );

  const paymentChangesModel: DiverPurchasePaymentEditorPaymentChangesModel =
    useAggregatePaymentChangesModel({
      formPackages,
      bookingDeposits,
      initialPurchasePaymentPackages,
      initialPurchasePaymentBookingDeposits,
      paymentWithBookingDeposit,
    });

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: DiverPurchasePaymentEditorFormModel, event) => {
        const { result, hasChanges } =
          diverDiverPurchasePaymentEditorDialogUpdateBuilder.buildUpdateResult({
            paymentChangesModel,
            diveCenterResume,
            initialPurchasePayment: purchasePayment,
            formValue,
            isDirty: formState.isDirty, // Note: il semble que formState n'est pas immuable, donc il faut avoir un observer sur formState.isDirty et pas juste sur formState
            state: inputState,
          });

        if (!hasChanges) {
          // no changes
          setIsOpen(false);
          return;
        } else {
          onConfirm(result);
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [
    handleSubmit,
    paymentChangesModel,
    diveCenterResume,
    purchasePayment,
    formState.isDirty,
    inputState,
    setIsOpen,
    onConfirm,
  ]);

  const aggregatedData = inputState.aggregatedData;
  const linkedData = aggregatedData.linkedData;

  return {
    data: {
      selectedDiver,
      aggregatedData,
      linkedData,
      purchasePayment,
      mode,
      defaultPackagesIds,
      defaultBookingDepositsIds,
      bookingDepositsWithPayments,
      divers,
      paymentsBookingDepositsDetails,
      purchasePackagesWithPayments,
      bookingResumesLoaded,
    },
    navigation,
    form,
    initialFormValue,
    formPackages,
    paymentChangesModel,
    inputState,
    actions: {
      submitForm,
      setIsOpen,
    },
  };
}
