import { EquipmentType } from '@mabadive/app-common-model';
import { urlBuilder } from 'src/_common-browser';
import { SETTINGS_DIVE_CENTER_PAGE_EQUIPMENTS } from '../SE-02_account/SETTINGS_ACCOUNT.const';
import {
  SETTINGS_DIVE_CENTER_PAGE_BOATS,
  SETTINGS_DIVE_CENTER_PAGE_DIVE_SITES,
  SETTINGS_DIVE_CENTER_PAGE_PLANNING,
  SETTINGS_DIVE_CENTER_PAGE_STAFF,
} from './SETTINGS_DIVE_CENTER.const';

export const clubSettingsDiveCenterUrlBuilder = {
  equipments: {
    list: () => SETTINGS_DIVE_CENTER_PAGE_EQUIPMENTS.url,
    edit: ({ equipmentType }: { equipmentType: EquipmentType }) =>
      `${SETTINGS_DIVE_CENTER_PAGE_EQUIPMENTS.url}/${equipmentType}/edit`,
  },
  planning: {
    view: () => SETTINGS_DIVE_CENTER_PAGE_PLANNING.url,
    edit: ({ periodConfigId }: { periodConfigId: string }) =>
      `${SETTINGS_DIVE_CENTER_PAGE_PLANNING.url}/edit/${periodConfigId}`,
    editSessionBooking: ({ periodConfigId }: { periodConfigId: string }) =>
      `${SETTINGS_DIVE_CENTER_PAGE_PLANNING.url}/edit-session-booking/${periodConfigId}`,
    duplicate: ({ periodConfigId }: { periodConfigId: string }) =>
      `${SETTINGS_DIVE_CENTER_PAGE_PLANNING.url}/clone/${periodConfigId}`,
    create: () => `${SETTINGS_DIVE_CENTER_PAGE_PLANNING.url}/create`,
    assistant: ({ periodConfigId }: { periodConfigId: string }) =>
      `${SETTINGS_DIVE_CENTER_PAGE_PLANNING.url}/assistant/${periodConfigId}`,
  },
  staff: {
    list: () => SETTINGS_DIVE_CENTER_PAGE_STAFF.url,
    create: () => `${SETTINGS_DIVE_CENTER_PAGE_STAFF.url}/nouveau`,
    edit: ({ staffMemberId }: { staffMemberId: string }) =>
      `${SETTINGS_DIVE_CENTER_PAGE_STAFF.url}/${staffMemberId}/edit`,
  },
  diveSites: {
    list: () => SETTINGS_DIVE_CENTER_PAGE_DIVE_SITES.url,
    create: ({
      defaultDiveSiteGroupId,
    }: { defaultDiveSiteGroupId?: string } = {}) =>
      urlBuilder.build(`${SETTINGS_DIVE_CENTER_PAGE_DIVE_SITES.url}/nouveau`, {
        queryParams: { defaultDiveSiteGroupId },
      }),
    edit: ({ diveSiteId }: { diveSiteId: string }) =>
      `${SETTINGS_DIVE_CENTER_PAGE_DIVE_SITES.url}/${diveSiteId}/edit`,
  },
  diveSitesGroups: {
    create: () => `${SETTINGS_DIVE_CENTER_PAGE_DIVE_SITES.url}/groups/nouveau`,
    edit: ({ diveSiteGroupId }: { diveSiteGroupId: string }) =>
      `${SETTINGS_DIVE_CENTER_PAGE_DIVE_SITES.url}/groups/${diveSiteGroupId}/edit`,
  },
  boats: {
    list: () => SETTINGS_DIVE_CENTER_PAGE_BOATS.url,
    create: () => `${SETTINGS_DIVE_CENTER_PAGE_BOATS.url}/nouveau`,
    edit: ({ clubBoatId }: { clubBoatId: string }) =>
      `${SETTINGS_DIVE_CENTER_PAGE_BOATS.url}/${clubBoatId}/edit`,
  },
};
