export const clubParticipantAttributesBuilder = {
  buildAttributes,
};

/**
 * Build graphql attributes for ClubParticipant
 */
function buildAttributes() {
  return `
    _id
    _createdAt
    _updatedAt
    _version
    diverId
    diveSessionReference
    diveSessionGroupId
    diveSessionGroupDiveGuide
    clubReference
    firstDiveReference
    trainingReference
    certificationReference
    creationMode
    diveMode
    divesCount
    aptitude
    targetDeep
    certificationDeepDiver
    autoSupervisedDetails
    tags
    comment
    bookingState
    bookingHistory
    paymentStatus
    paymentComment
    activePurchasedPackage
    activePurchasedTraining
    details
    gaz
    equipment
 `;
}
