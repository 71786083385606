/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppMessage } from 'src/business/_core/modules/layout/components/_tailwind';

export const ClubEventDialogTab1EditInfoBox = () => {
  const redirectTo = useRedirect();

  return (
    <AppMessage
      className="my-5"
      type="info"
      title="Note"
      message={
        <>
          <p>
            Les notes permettent d'afficher des informations en haut du planning
            (RV, maintenance, arrivée d'un moniteur, personne à aller chercher à
            l'aéroport...)
          </p>
        </>
      }
    />
  );
};
