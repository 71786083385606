/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { SetPlanningConfigOptions } from '../../../club-planning-light/hooks';
import { ClubPlanningDayFull } from '../../../club-planning-light/model';
import { PlanningViewConfig } from '../../_model';
import { ClubPlanningStaffWeekStaffPresenceMembersEditRow } from './ClubPlanningStaffWeekStaffPresenceMembersEditRow';
import { ClubPlanningStaffWeekStaffPresenceLocalState } from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';

export const ClubPlanningStaffWeekStaffPresenceMembersEdit = ({
  days,
  planningConfig,
  setPlanningConfig,
  localState,
  gridClassName,
  className,
}: {
  days: ClubPlanningDayFull[];
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  localState: ClubPlanningStaffWeekStaffPresenceLocalState;
  gridClassName?: string;
  className?: string;
}) => {
  const {
    data, 
  } = localState;

  return (
    <div
      className={clsx(
        'grid border border-gray-400 divide-y divide-y-gray-200',
        className,
      )}
    >
      {data.aggregateData.staffMembersResumeSessions.length > 0 ? (
        data.aggregateData.staffMembersResumeSessions.map(
          (staffMemberResumeSessions, staffMemberIndex) => (
            <ClubPlanningStaffWeekStaffPresenceMembersEditRow
              key={staffMemberResumeSessions.staffMember?._id}
              days={days}
              planningConfig={planningConfig}
              setPlanningConfig={setPlanningConfig}
              localState={localState}
              gridClassName={gridClassName}
              staffMemberResumeSessions={staffMemberResumeSessions}
            />
          ),
        )
      ) : (
        <AppMessageLight>Aucun moniteur</AppMessageLight>
      )}
    </div>
  );
};
