/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSessionResumeFull } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import {
  ClubDiveSessionHeaderComments,
  ClubDiveSessionHeaderStatus,
  ClubDiveSessionHeaderTimeAndDiveSite,
  ClubDiveSessionHeaderTitle,
  ClubDiveSessionHeaderTitleWithPrefix,
} from '../../../../club-dive-session/components';
import { ClubPlanningPanelSessionDisplayStyle } from '../../../model';
import { diveSessionStyleBuilder } from '../diveSessionStyleBuilder.service';

export const DiveSessionCardCommonHeader = ({
  session,
  display,
  onClick,
  selectionColor,
  className,
}: {
  session: DiveSessionResumeFull;
  display: {
    sessionIndex?: { index: number; total: number };
    tide: boolean;
    weather: boolean;
    comment: boolean;
  };
  selectionColor?: ClubPlanningPanelSessionDisplayStyle;
  onClick?: () => any;
  className?: string;
}) => {
  const isMultiSession = !!session.diveTourSession2;
  const groupDiveSite1AndTitle = !isMultiSession && !session.diveSiteId;

  const sessionBorderStyle = diveSessionStyleBuilder.buildBorderStyleFromTime({
    isVirtual: false,
    time: session.time,
  });

  return (
    <div
      className={clsx(onClick && 'cursor-pointer', className)}
      onClick={onClick}
    >
      {display.sessionIndex && (
        <div className="absolute top-0.5 right-1 text-app-xxs text-gray-600 font-bold">
          {display.sessionIndex.index}/{display.sessionIndex.total}
        </div>
      )}
      {groupDiveSite1AndTitle ? (
        <ClubDiveSessionHeaderTitleWithPrefix
          className={`border-t-0 ${sessionBorderStyle}`}
          diveSession={session}
          sessionIndex={1}
          selectionColor={selectionColor}
        />
      ) : (
        <>
          <ClubDiveSessionHeaderTimeAndDiveSite
            className={`border-t-0 ${sessionBorderStyle}`}
            diveSiteId={session.diveSiteId}
            time={session.time}
            isMultiSession={isMultiSession}
            sessionIndex={1}
            widthMode={'large'}
            selectionColor={selectionColor}
          />
          {isMultiSession && session.diveTourSession2?.time && (
            <ClubDiveSessionHeaderTimeAndDiveSite
              className={`border-t ${sessionBorderStyle}`}
              diveSiteId={session.diveTourSession2?.diveSiteId}
              time={session.diveTourSession2?.time}
              isMultiSession={isMultiSession}
              sessionIndex={2}
              widthMode={'large'}
              selectionColor={selectionColor}
            />
          )}
          <ClubDiveSessionHeaderTitle
            diveSession={session}
            className={`py-1 border-t ${sessionBorderStyle}`}
          />
        </>
      )}
      <ClubDiveSessionHeaderStatus className="py-1" diveSession={session} />
      <ClubDiveSessionHeaderComments
        className={`py-1 border-t ${sessionBorderStyle}`}
        session={session}
        display={display}
        disableBgColor={true}
      />
    </div>
  );
};
