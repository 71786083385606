import { LoadableAttributeCollectionStore } from '@mabadive/app-common-services';
import { CollectionRepositoryStoreAdapters } from '../../model';
import { collectionSimpleStoreReadableAdapterFactory } from './collectionSimpleStoreReadableAdapterFactory.service';
import { collectionSimpleStoreWritableAdapterFactory } from './collectionSimpleStoreWritableAdapterFactory.service';

export const collectionSimpleStoreAdaptersFactory = {
  createAdapters,
};

function createAdapters<T>(store: LoadableAttributeCollectionStore<T>): CollectionRepositoryStoreAdapters<T> {

  return {
    read: collectionSimpleStoreReadableAdapterFactory.createAdapter(store),
    write: collectionSimpleStoreWritableAdapterFactory.createAdapter(store),
  };
}

