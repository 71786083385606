import clsx from 'clsx';
import { ValueLabel } from 'src/business/club/modules/_common/form';

export type AppToogleButtonGroupTailwindV2OptionStyle = {
  className: string;
};

export const appToogleButtonGroupTailwindV2OptionStyleBuilder = {
  buildStyle,
};

function buildStyle<T>(option: ValueLabel<T, React.ReactNode, React.ReactNode>, {
  isDisabled,
  isActive,
  isSelected,
  isChecked,
}: {
  isDisabled?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
  isChecked?: boolean; // différent de isSelected ?
}) {
  const className = clsx(
    isDisabled
      ? 'opacity-25 cursor-not-allowed'
      : 'cursor-pointer focus:outline-none',
    isActive && 'ring-2 ring-offset-2 ring-blue-500',
    isSelected
      ? 'bg-blue-500 border-transparent text-white hover:bg-blue-600'
      : 'bg-white border-gray-400 text-gray-600 hover:bg-gray-50',
  );

  return {
    className,
  };
}

export type AppToogleButtonGroupTailwindV2OptionStyleBuilder =
  typeof buildStyle;
