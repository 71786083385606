/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButtonsBar } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { DiveSessionEditorMassiveDialogLocalState } from './useDiveSessionEditorMassiveDialogLocalState.hook';
import { useDiveSessionEditorMassiveDialogSubmit } from './useDiveSessionEditorMassiveDialogSubmit';

export const DiveSessionEditorMassiveDialogActionsBar = ({
  localState,
}: {
  localState: DiveSessionEditorMassiveDialogLocalState;
}) => {
  const { data, inputState } = localState;
  const { pageState, setPageState } = data;

  const submitForm = useDiveSessionEditorMassiveDialogSubmit({
    localState,
  });

  return (
    <AppButtonsBar hasChanges={true}>
      <>
        <AppButton
          size="normal"
          icon={AppIcons.action.cancel}
          tabIndex={500}
          color="gray-outline-light"
          onClick={() => {
            inputState.onCancel();
          }}
        >
          Annuler
        </AppButton>
        <AppButton
          type="submit"
          size="normal"
          icon={AppIcons.action.save}
          color="primary-outline-light"
          disabled={data.participantsCheckState.participantsCheckedCount === 0}
          onClick={() => {
            if (pageState.step === 'select-divers') {
              setPageState({
                ...pageState,
                step: 'select-session',
              });
              // } else if (pageState.step === 'select-session') {
              //   setPageState({
              //     ...pageState,
              //     step: 'select-session',
              //   });
            } else {
              submitForm();
            }
          }}
        >
          Confirmer
        </AppButton>
      </>
    </AppButtonsBar>
  );
};
