import { DiveCenterDailyConfig } from '@mabadive/app-common-model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { graphqlClient } from 'src/_common-browser';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { buildDailyConfigCollectionGraphqlQuery } from './query-builder';

export const dailyConfigGraphqlFetcher = {
  findMany,
};

function findMany(
  {
    clubReference,
    diveCenterId,
    minDateInclusive,
    maxDateExclusive,
  }: {
    clubReference: string;
    diveCenterId: string;
    minDateInclusive: Date;
    maxDateExclusive: Date;
  },
  {
    type,
  }: {
    type: 'subscription' | 'query';
  },
): Observable<DiveCenterDailyConfig[]> {
  const queryDescription = buildDailyConfigCollectionGraphqlQuery({
    clubReference,
    diveCenterId,
    minDateInclusive,
    maxDateExclusive,
  });

  return graphqlClient.query
    .runOne<DiveCenterDailyConfig[]>(queryDescription, {
      type,
    })
    .pipe(
      // tap((dailyConfigs) => {
      //   appLogger.info(
      //     '[dailyConfigGraphqlFetcher.findMany] dailyConfigs:',
      //     dailyConfigs.length,
      //   );
      // }),
      catchError((err) => {
        appLogger.warn('[dailyConfigGraphqlFetcher] error fetching data', err);
        return throwError(err);
      }),
    );
}
