import { appWebConfig } from '../../../../../../../_core/modules/root/config';
import { appLogger } from '../../../../../../../_core/modules/root/logger';
import {
  DiverBookingPageAggregatedDataCore,
  DiverBookingPageLoadedContent,
  DiverBookingPageUpdateState,
  PRO_BookingParticipantFull,
  PRO_BookingResume,
} from '../../models';
import {
  diverBookingPageAggregatedBookingResumeBuilderBookingFull,
  diverBookingPageAggregatedBookingResumeBuilderCore,
  diverBookingPageAggregatedBookingResumeBuilderParticipantFull,
} from '../../services/04.aggregated-state';
import { BookingMassiveEditorDialogAggregatedData } from './model';

export const useBookingMassiveEditorDialogAggregatedDataBuilder = {
  aggregate,
};

function aggregate({
  bookingId,
  loadedContent,
  updateState,
}: {
  bookingId: string;
  loadedContent: DiverBookingPageLoadedContent;
  updateState: DiverBookingPageUpdateState;
}): BookingMassiveEditorDialogAggregatedData {
  // on réutilise l'aggregateur de la page de booking
  const aggregatedDataCore: DiverBookingPageAggregatedDataCore =
    diverBookingPageAggregatedBookingResumeBuilderCore.aggregateDataCore({
      updateState,
      loadedContent,
    });

  const booking = aggregatedDataCore.bookings.find((x) => x._id === bookingId);

  const bookingParticipantsFullAllBookings: PRO_BookingParticipantFull[] =
    aggregatedDataCore.bookingProducts
      .filter((x) => x.type === 'dive')
      .map((bookingProduct) => {
        const p =
          diverBookingPageAggregatedBookingResumeBuilderParticipantFull.buildParticipantFull(
            {
              aggregatedDataCore,
              bookingProduct,
            },
          );
        if (!p && appWebConfig.envId === 'dev') {
          appLogger.info(
            '[useBookingMassiveEditorDialogAggregatedDataBuilder] bookingProduct missing',
            bookingProduct,
          );
        }
        return p;
      })
      .filter((x) => !!x);

  const bookingParticipantsFull: PRO_BookingParticipantFull[] =
    bookingParticipantsFullAllBookings.filter(
      (x) => x?.booking?._id === bookingId, // skip all other bookings
    );

  const aggregatedBooking: PRO_BookingResume =
    diverBookingPageAggregatedBookingResumeBuilderBookingFull.buildBookingFull({
      aggregatedDataCore,
      booking,
      bookingParticipantsFull,
      clubReference: loadedContent.clubResume.reference,
    });

  const data: BookingMassiveEditorDialogAggregatedData = {
    booking,
    aggregatedBooking: aggregatedBooking,
    bookingMembersFull: aggregatedBooking.bookingMembersFull,
    bookingParticipantsFull: aggregatedBooking.bookingParticipantsFull,
    bookingParticipantsFullAllBookings,
    bookingSessionsFull: aggregatedBooking.bookingSessionsFull,
  };

  return data;
}
