/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppPaymentMethod } from '@mabadive/app-common-model';
import { paymentMethodFormatter } from '@mabadive/app-common-services';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppPaymentMethodsListScope,
  useAppPaymentMethods,
} from './useAppPaymentMethods.hook';

export function useAppPaymentMethodsOptions(
  scope?: AppPaymentMethodsListScope,
): ValueLabel<AppPaymentMethod>[] {
  const customerPaymentTypes: AppPaymentMethod[] = useAppPaymentMethods(scope);

  const customerPaymentTypeOptions = customerPaymentTypes.map((type) => {
    const option: ValueLabel<AppPaymentMethod> = {
      label: paymentMethodFormatter.formatPaymentMethod(type),
      value: type,
    };
    return option;
  });

  return customerPaymentTypeOptions;
}
