import {
  ClubProductPackageOfferView,
  ClubPurchasePackageExtraCost,
} from '@mabadive/app-common-model';
import React from 'react';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import {
  AppButton,
  AppButtonStyleColor,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppCurrencyMain } from 'src/pages/_components/options';

export const AppPackageExtraCostCreateButton = ({
  offer: o,
  extraCostUnitDiveDefaultMultiplier,
  type,
  onClick,
}: {
  offer: ClubProductPackageOfferView;
  extraCostUnitDiveDefaultMultiplier: number;
  type: 'main' | 'secondary';
  onClick: (extraCost: ClubPurchasePackageExtraCost) => any;
}) => {
  const mainCurrency = useAppCurrencyMain();

  const color: AppButtonStyleColor =
    type === 'main' ? 'primary-bg' : 'gray-outline-light';

  const priceClass = type === 'main' ? 'text-white' : 'text-app-blue';

  return (
    <AppButton
      key={o.reference}
      className={'w-full sm:w-auto text-xs font-bold uppercase'}
      style={{ minWidth: '6rem' }}
      color={color}
      icon={AppHeroIcons.actionAdd}
      onClick={() => {
        // const offer: ClubProductPackageOffer = {
        //   ...o,
        //   _id: null,
        //   clubReference: clubResume.reference,
        //   productPackage: o.productPackage,
        //   reference: o.reference,
        //   price: o.price,
        // };
        const extraCostUnitDive =
          o.productPackage.salesCriteria?.extraCostUnitDive;
        const extraCost: ClubPurchasePackageExtraCost = {
          extraCostUnitDive,
          label: o.productPackage.label,
          unitPrice: o.price,
          unitQuantity: 1,
          unitTotalPrice: undefined,
          multiplier: extraCostUnitDive
            ? extraCostUnitDiveDefaultMultiplier
            : 1,
          totalPrice: undefined,
        };

        onClick(extraCost);
        // setTimeout(() => {
        //   window.scrollTo({
        //     top: 0,
        //     behavior: 'auto',
        //     /* you can also use 'auto' behaviour
        //        in place of 'smooth' */
        //   });
        // }, 100);
      }}
    >
      <div className="flex gap-1">
        {o.productPackage.label}
        {o.price > 0 && (
          <AppPriceLabel
            preserveSignClass={true}
            className={`text-app-xxs ${priceClass}`}
            amount={o.price}
            mainCurrency={mainCurrency}
          />
        )}
      </div>
    </AppButton>
  );
};
