import { BookingSession, DiveSessionResume } from '@mabadive/app-common-model';
import { changeDescriptorManager } from '@mabadive/app-common-services';
import {
  DiverBookingNewBookingSession,
  DiverBookingPageUpdateState,
  PRO_BookingResume,
} from '../../../models';
import { diverBookingPageSessionCreator } from '../entity-creators';

export const quickBookingSessionCreator = {
  selectOrCreateBookingSession,
};

function selectOrCreateBookingSession({
  aggregatedBooking,
  diveSessionResume,
  updateStateLocal,
  clubReference,
  diveCenterId,
}: {
  aggregatedBooking: PRO_BookingResume;
  diveSessionResume: DiveSessionResume;
  updateStateLocal: DiverBookingPageUpdateState;
  clubReference: string;
  diveCenterId: string;
}): {
  bookingSession?: BookingSession;
} {
  let bookingSession: BookingSession = (
    aggregatedBooking?.bookingSessions ?? []
  ).find((s) => s.diveSessionReference === diveSessionResume.reference);

  let bookingSessionId = bookingSession?._id;
  if (!bookingSession) {
    // !!! ATTENTION !!!: côté serveur on a une méthode similaire dans clubMassiveUpdator.moveParticipants

    // create booking session
    bookingSession = diverBookingPageSessionCreator.createBookingSession({
      bookingId: aggregatedBooking.booking._id,
      clubReference,
      diveCenterId,
      diveSessionReference: diveSessionResume.reference,
      bookingSessionStatus: 'confirmed',
    });
    const newBookingSession: DiverBookingNewBookingSession = {
      diveSessionResume,
      bookingSession,
    };
    bookingSessionId = bookingSession._id;

    // if (newDiveSessions.length || originalDiveSessions.length) {
    //   // add or create (if virtual) dive sessions
    //   updateStateLocal =
    //     diverBookingPageUpdateStateManager.addNewDiveSessionsToState({
    //       updateState: updateStateLocal,
    //       newDiveSessions,
    //       originalDiveSessions,
    //     });
    // }

    updateStateLocal.bookingSessionsChanges = changeDescriptorManager.createOne(
      newBookingSession.bookingSession,
      {
        changeDescriptors: updateStateLocal.bookingSessionsChanges,
      },
    );
  }
  return { bookingSession };
}
