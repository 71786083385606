import { CalendarIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { AppInputDatePicker } from 'src/stories/components/04-form';
import { AppTitleDateNavigatorDayLabel } from '../AppTitleDateNavigatorDayLabel';
import { AppTitleDateNavigatorPeriodLabel } from '../AppTitleDateNavigatorPeriodLabel';
import { AppTitleDateNavigatorModelValue } from '../model';

export const AppTitleDateNavigatorDatePicker = ({
  value,
  onChange,
  isNow,
}: {
  value: AppTitleDateNavigatorModelValue;
  isNow?: boolean;
  onChange: (newSelectedDayDate: Date) => void;
}) => {
  return (
    <>
      <AppInputDatePicker
        value={value.selectedDate}
        onChange={(newSelectedDayDate) => {
          if (newSelectedDayDate) {
            onChange(newSelectedDayDate);
          }
        }}
      >
        <div className="group flex gap-2 justify-center items-center py-2 px-5 border-2 bg-gray-50 border-gray-200 hover:bg-white cursor-pointer">
          <CalendarIcon
            className={
              'w-6 h-6 sm:w-10 sm:h-10 text-gray-600 group-hover:text-gray-700'
            }
          />
          {value.beginDate.getTime() === value.endDate.getTime() ? (
            <AppTitleDateNavigatorDayLabel
              selectedDay={value.beginDate}
              isToday={isNow}
            />
          ) : (
            <AppTitleDateNavigatorPeriodLabel
              beginDate={value.beginDate}
              endDate={value.endDate}
              isCurrentPeriod={isNow}
            />
          )}
        </div>
      </AppInputDatePicker>
    </>
  );
};
