import clsx from 'clsx';
import React from 'react';
import {
  AppHeroIcons,
  DiveModeIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import {
  DashboardMetricTitleWithValue,
  DashboardMetricValueCard,
} from 'src/pages/DA-dashboard/_core';

import { ProStatsFetchResults } from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import { useClubSettings } from '../../../../../../business/club/data/hooks';

export const DashboardReportPageGlobalActivityParticipants = ({
  participantsOverPeriod: results,
  title,
}: {
  participantsOverPeriod: ProStatsFetchResults['participantsOverPeriod'];
  title: string;
}) => {
  const participants = results?.data;
  const clubSettings = useClubSettings();
  return !participants ? null : (
    <>
      <div className={clsx('w-full app-card p-4 app-card-highlight')}>
        <DashboardMetricTitleWithValue
          label={title}
          description="Prestations réalisées"
          valueClassName="bg-gray-600 text-white"
          icon={EntitiesIcons.participants}
          value={participants.totalCount}
        />
        <div className="mt-5 grid grid-cols-2 gap-x-2 gap-y-1">
          <DashboardMetricValueCard
            iconClassName="text-dive-mode-first-dive"
            label={'Plongeurs'}
            value={participants.diversCount}
            unitIcon={EntitiesIcons.diver}
          />
          <DashboardMetricValueCard
            iconClassName="text-dive-mode-first-dive"
            label={diveModeFormatter.formatDiveMode('first-dive', {
              format: 'short-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
            value={participants.firstDiveCount}
            unitIcon={DiveModeIcons.firstDive}
          />
          <DashboardMetricValueCard
            iconClassName="text-dive-mode-supervised"
            label={'Explorations'}
            value={participants.recreationalCount}
            unitIcon={DiveModeIcons.autonomous}
          />{' '}
          <DashboardMetricValueCard
            iconClassName="text-dive-mode-auto-supervised"
            label={diveModeFormatter.formatDiveMode('autoSupervised', {
              format: 'short-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
            value={participants.autoSupervisedCount}
            unitIcon={DiveModeIcons.autonomous}
          />
          <DashboardMetricValueCard
            iconClassName="text-dive-mode-training"
            label={diveModeFormatter.formatDiveMode('training', {
              format: 'short-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
            value={participants.trainingCount}
            unitIcon={DiveModeIcons.training}
          />
          <DashboardMetricValueCard
            // iconClassName="text-dive-mode-watching-tour" // on n'utilise plus les classe tailwind
            label={diveModeFormatter.formatDiveMode('watchingTour', {
              format: 'short-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
            value={participants.watchingTourCount}
            unitIcon={DiveModeIcons.watchingTour}
          />
          <DashboardMetricValueCard
            iconClassName="text-dive-mode-snorkeling"
            label={'Autres'}
            value={participants.otherCount}
            unitIcon={DiveModeIcons.snorkeling}
          />
        </div>
        <div className="mt-1 grid grid-cols-2 gap-x-2 gap-y-1">
          <DashboardMetricValueCard
            label={'Annulations club'}
            value={participants.cancelCountClub}
            unitIcon={AppHeroIcons.actionCancel2}
          />
          <DashboardMetricValueCard
            label={'Annul. plongeur'}
            value={participants.cancelCountDiver}
            unitIcon={AppHeroIcons.actionCancel}
          />
        </div>
      </div>
    </>
  );
};
