import { AppLanguageCode } from '@mabadive/app-common-model';
import React, { FC, useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { uiStore } from 'src/business/_core/store';
import { useClubSettings } from 'src/business/club/data/hooks';
import { AppFormControlV2, AppSingleAutocomplete2 } from 'src/lib/form';
import { useLanguagesOptions } from 'src/pages/_components/options';
import { translateApiClient } from '../../../services';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';

export const CreateMessageToCustomersFormStep4_ContentTranslateBar: FC<{
  localState: CreateMessageToCustomersLocalState;
}> = ({ localState }) => {
  const {
    data: { form },
  } = localState;

  const { control, setValue } = form;

  const [title, body, signature, languageCode] = useWatch({
    control,
    name: [
      'messageContent.title',
      'messageContent.body',
      'messageContent.signature',
      'messageContent.languageCode',
    ],
  });

  const [toLanguageCode, setToLanguageCode] =
    useState<AppLanguageCode>(languageCode);
  // languageCode === 'en' ? 'fr' : 'en',

  const languagesOptions = useLanguagesOptions({
    scope: 'company',
  });

  const [isPersistInProgress, setIsPersistInProgress] =
    useState<boolean>(false);

  const translateMessage = useCallback(
    async ({ toLanguageCode }: { toLanguageCode: AppLanguageCode }) => {
      if (isPersistInProgress) {
        return;
      }
      setIsPersistInProgress(true);
      try {
        const { translatedMessage } = await translateApiClient.translateMessage(
          {
            languageCode,
            title,
            body,
          },
          {
            toLanguageCode,
          },
        );
        if (translatedMessage) {
          setValue(
            'messageContent.languageCode',
            translatedMessage.languageCode,
          );
          setValue('messageContent.title', translatedMessage.title);
          setValue('messageContent.body', translatedMessage.body);
          // setToLanguageCode(undefined);
        }
      } catch (err) {
        uiStore.snackbarMessage.set({
          type: 'error',
          content:
            'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
        });
      } finally {
        setIsPersistInProgress(false);
      }
    },
    [body, isPersistInProgress, languageCode, setValue, title],
  );

  const clubSettings = useClubSettings();

  return clubSettings.communication?.translation?.enabledManual ? (
    <>
      {/* <AppButton
        color={'primary-outline-light'}
        icon={AppIconsAction.translate}
        onClick={async () => {
          await translateMessage({
            toLanguageCode,
          });
        }}
      >
        Traduire le message
      </AppButton>
      <AppFormControlRHF_Deprecated
        className="w-40"
        label="Depuis le"
        control={control}
        name={'messageContent.languageCode'}
        renderComponent={(props) => (
          <AppSingleAutocomplete2RHF
            {...props}
            options={languagesOptions}
            fullWidth
          />
        )}
      /> */}
      <AppFormControlV2
        className="w-40"
        label="TRADUIRE EN"
        onClick={(e) => e.stopPropagation()}
        disabled={isPersistInProgress}
        renderComponent={() => (
          <AppSingleAutocomplete2
            value={toLanguageCode}
            options={languagesOptions}
            fullWidth
            disableClearable={true}
            onChange={(toLanguageCode) => {
              if (toLanguageCode) {
                setToLanguageCode(toLanguageCode);
                if (toLanguageCode !== languageCode) {
                  translateMessage({
                    toLanguageCode,
                  });
                }
              }
            }}
          />
        )}
      />
    </>
  ) : null;
};
