/* eslint-disable @typescript-eslint/no-unused-vars */
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions_OnClickAction } from './ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions';
import { ClubSettingsCustomerEditorFormModel } from './model';

export const ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptionsItem =
  ({
    className,
    form,
    fieldArray,
    fieldIndex,
    onClick,
  }: {
    className?: string;
    form: UseFormReturn<ClubSettingsCustomerEditorFormModel>;
    fieldArray: UseFieldArrayReturn<
      ClubSettingsCustomerEditorFormModel,
      'clubSettings.customer.general.customerOriginOptions',
      'ref'
    >;

    fieldIndex: number;
    onClick: (
      action: ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions_OnClickAction,
    ) => void;
  }) => {
    const {
      register,
      handleSubmit,
      watch,
      formState,
      control,
      setValue,
      getValues,
    } = form;

    const securityUser = useAppSecurityUser();

    const clubResume = useClubResume();
    const clubReference = clubResume.reference;

    const { fields, append, prepend, remove, swap, move, insert } = fieldArray;

    const formAttributeBaseName =
      `clubSettings.customer.general.customerOriginOptions.${fieldIndex}` as const;

    const isMoveDownAllowed =
      fieldArray.fields.length > 1 && fieldIndex + 1 < fieldArray.fields.length;
    const isMoveUpAllowed = fieldArray.fields.length > 1 && fieldIndex !== 0;

    return (
      <div
        className={clsx(
          'border-l-4 border-gray-600 pl-2 grid md:flex gap-2 lg:gap-4 items-start',
          className,
        )}
      >
        <div
          className={clsx(
            'h-full flex md:flex-col md:justify-center gap-2',
            fieldArray.fields.length === 1 && 'invisible',
          )}
        >
          <Tippy
            delay={[1000, 100]}
            placement="top"
            content={'Déplacer au dessus'}
          >
            <AppIconsAction.up
              className={clsx(
                'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
                isMoveUpAllowed
                  ? 'hover:bg-gray-600 cursor-pointer'
                  : 'opacity-50 cursor-not-allowed',
              )}
              onClick={() => isMoveUpAllowed && onClick('move-up')}
            />
          </Tippy>
          <Tippy
            delay={[1000, 100]}
            placement="top"
            content={'Déplacer en dessous'}
          >
            <AppIconsAction.down
              className={clsx(
                'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
                isMoveDownAllowed
                  ? 'hover:bg-gray-600 cursor-pointer'
                  : 'opacity-50 cursor-not-allowed',
              )}
              onClick={() => isMoveDownAllowed && onClick('move-down')}
            />
          </Tippy>
        </div>
        <div>
          <div className="flex flex-row flex-wrap gap-2">
            <AppFormControlRHF
              label="Libellé"
              className="w-48"
              control={control}
              name={`${formAttributeBaseName}.label`}
              required={true}
              renderComponent={(props) => <AppInputRHF {...props} />}
            />
          </div>
        </div>
        <div className="flex gap-2 md:gap-6">
          <Tippy delay={[1000, 100]} placement="top" content={'Supliquer'}>
            <AppIconsAction.clone
              className="p-1 w-8 h-8 bg-green-200 hover:bg-green-600 rounded-full text-white cursor-pointer"
              onClick={() => {
                onClick('clone');
              }}
            />
          </Tippy>
          <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
            <AppIconsAction.delete
              className="p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer"
              onClick={() => {
                onClick('delete');
              }}
            />
          </Tippy>
        </div>
      </div>
    );
  };
