/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettingsUIPlanning,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import { commonDiveSessionBuilder } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { MouseEventHandler, useMemo } from 'react';
import { StaffRoleBadge } from '../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components/DiveSessionStaffMembersTable/StaffRoleBadge';
import { ClubPlanningLightPanelStaffDailyTable } from './ClubPlanningLightPanelStaffDailyTable';

export const ClubPlanningLightPanelStaffDailyCounters = ({
  date,
  showTooltip,
  staffMembersResumeSessions,
  planningConfig,
  onClick,
  className,
}: {
  date: Date;
  showTooltip: boolean;
  staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
  planningConfig: Pick<
    ClubSettingsUIPlanning,
    | 'staffFirstNameBefore'
    | 'showStaffCountsPerDay'
    | 'showStaffCountsPerDayDP'
    | 'showStaffCountsPerDayGP'
    | 'showStaffCountsPerDaySS'
  >;
  onClick?: MouseEventHandler<any>;
  className?: string;
}) => {
  const dayReference = useMemo(() => {
    return commonDiveSessionBuilder.buildDayReference(date);
  }, [date]);

  const stats = useMemo(() => {
    const availableStaff = staffMembersResumeSessions.filter((x) => {
      if (x.activeOnAnySession || x.availableOnAnySession) {
        const day = x.days.find((d) => d.dayReference === dayReference);
        if (!day) {
          return false;
        }
        return day.availableOrActiveToday;
      }
      return false;
    });
    const surfaceSecurityCount = availableStaff.filter((x) =>
      x.staffMember.profile.roles.includes('surface-security'),
    ).length;
    const divingDirectorsCount = availableStaff.filter((x) =>
      x.staffMember.profile.roles.includes('diving-director'),
    ).length;
    const scubaDivingInstructorsCount = availableStaff.filter((x) =>
      x.staffMember.profile.roles.includes('scuba-diving-instructor'),
    ).length;
    return {
      availableStaff,
      surfaceSecurityCount,
      divingDirectorsCount,
      scubaDivingInstructorsCount,
      staff: {
        regularInstructors: {
          assigned: availableStaff.length + 1,
          total: availableStaff.length + 1,
        },
        casualInstructors: {
          assigned: availableStaff.length + 1,
          total: availableStaff.length + 1,
        },
      },
    };
  }, [dayReference, staffMembersResumeSessions]);

  return !planningConfig.showStaffCountsPerDay ? null : (
    <Tippy
      disabled={!showTooltip || stats.availableStaff.length === 0}
      delay={[200, 100]}
      placement="top"
      interactiveBorder={1}
      interactive={true}
      className="border-2 border-gray-600 bg-white"
      content={
        <ClubPlanningLightPanelStaffDailyTable
          date={date}
          dayReference={dayReference}
          planningConfig={planningConfig}
          availableStaff={stats.availableStaff}
        />
      }
    >
      <div
        className={clsx(
          'flex gap-1 justify-end flex-wrap items-start',
          onClick &&
            'cursor-pointer hover:bg-gray-100 hover:border-x hover:border-gray-300',
          className,
        )}
        onClick={onClick}
      >
        {planningConfig.showStaffCountsPerDaySS && (
          <StaffRoleBadge
            className="px-0.5 lg:px-1"
            role={{
              enabled: true,
              label: `${stats.surfaceSecurityCount} SS`,
              staffRoleRef: 'surface-security',
              active: true,
              assigned: true,
            }}
          />
        )}
        {planningConfig.showStaffCountsPerDayDP && (
          <StaffRoleBadge
            className="px-0.5 lg:px-1"
            role={{
              enabled: true,
              label: `${stats.divingDirectorsCount} DP`,
              staffRoleRef: 'diving-director',
              active: true,
              assigned: true,
            }}
          />
        )}
        {planningConfig.showStaffCountsPerDayGP && (
          <StaffRoleBadge
            className="px-0.5 lg:px-1"
            role={{
              enabled: true,
              label: `${stats.scubaDivingInstructorsCount} GP`,
              staffRoleRef: 'scuba-diving-instructor',
              active: true,
              assigned: true,
            }}
          />
        )}
      </div>
    </Tippy>
  );
};
