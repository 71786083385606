/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useBookingAgenciesOptions,
  useBookingOptions,
  useDiversOptions,
} from 'src/pages/_components/options';
import { DiverBookingPageAggregatedData } from '../../../../models';
import { DiverPurchasePaymentEditorFormModel } from './DiverPurchasePaymentEditorFormModel.type';

export const DiverPurchasePaymentEditorFormBookingAndAgency = ({
  isCreation,
  aggregatedData,
  initialBookingId,
  form,
}: {
  isCreation: boolean;
  aggregatedData: DiverBookingPageAggregatedData;
  initialBookingId: string;
  form: UseFormReturn<DiverPurchasePaymentEditorFormModel, any, undefined>;
}) => {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;

  const clubSettings = clubResume.clubSettings;
  const agenciesOptions = useBookingAgenciesOptions();

  const { control, setValue } = form;

  const divers = aggregatedData.divers;
  const diversOptions: ValueLabel<string>[] = useDiversOptions(divers);

  const bookingOptions: ValueLabel<string, React.ReactNode>[] =
    useBookingOptions({
      aggregatedData,
      onlyActive: isCreation,
      initialBookingId,
      diveCenterId,
    });

  const [diverId, bookingId, payedToBookingAgencyId] = useWatch({
    control,
    name: ['diverId', 'bookingId', 'payedToBookingAgencyId'],
  });
  const selectedBooking = useMemo(() => {
    return !bookingId
      ? undefined
      : aggregatedData.bookings.find((b) => b._id === bookingId);
  }, [aggregatedData.bookings, bookingId]);

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const forceBookingOrDiverVisibilityIfMultiple = isSuperAdmin && !isCreation; // en édition, on affiche toujours le booking pour le super admin

  const isBookingSet = useMemo(() => {
    if (bookingId) {
      // on vérifie que la valeur fait bien partie des options (sinon, il faut afficher le champ)
      return bookingOptions.some((b) => b.value === bookingId);
    }
    return false;
  }, [bookingId, bookingOptions]);
  const isDiverSet = useMemo(() => {
    if (diverId) {
      // on vérifie que la valeur fait bien partie des options (sinon, il faut afficher le champ)
      return diversOptions.some((b) => b.value === diverId);
    }
    return false;
  }, [diverId, diversOptions]);

  const isDiverOptionsVisible =
    forceBookingOrDiverVisibilityIfMultiple &&
    diversOptions?.length > 0 && // au moins 1 option
    (!isDiverSet || diversOptions?.length > 1); // plusieurs options, ou pas d'option définie

  const isBookingOptionsVisible =
    (forceBookingOrDiverVisibilityIfMultiple ||
      clubSettings.general.billing?.booking?.enabled) &&
    bookingOptions?.length > 0 && // au moins 1 option
    (!isBookingSet || bookingOptions?.length > 1); // plusieurs options, ou pas d'option définie

  const isAgenciesOptionsVisible =
    clubSettings.general.agencies?.enabled &&
    clubSettings.general.agencies?.agencyPayment;

  const isVisible =
    isDiverOptionsVisible ||
    isBookingOptionsVisible ||
    isAgenciesOptionsVisible;

  return isVisible ? (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {isDiverOptionsVisible && (
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Client"
          control={control}
          name="diverId"
          required={true}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF {...props} options={diversOptions} />
          )}
        />
      )}
      {isBookingOptionsVisible && (
        <AppFormControlRHF_Deprecated
          className=""
          label="Réservation associée"
          control={control}
          name="bookingId"
          required={clubSettings.general.billing?.booking?.required}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              theme={'material-ui'}
              {...props}
              options={bookingOptions}
            />
          )}
        />
      )}

      {isAgenciesOptionsVisible && (
        <div className="flex gap-2">
          <AppFormControlRHF_Deprecated
            className="flex-grow w-full"
            label="Payé à l'agence"
            control={control}
            name="payedToBookingAgencyId"
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF {...props} options={agenciesOptions} />
            )}
          />
          {!payedToBookingAgencyId && selectedBooking?.details?.agencyId && (
            <AppButton
              className="w-auto text-xs font-bold uppercase mt-2 self-start whitespace-nowrap"
              style={{ minWidth: '6rem' }}
              color={'primary-outline-light'}
              icon={AppHeroIcons.autoFill}
              onClick={() =>
                setValue(
                  'payedToBookingAgencyId',
                  selectedBooking?.details?.agencyId,
                  {
                    shouldTouch: true,
                    shouldDirty: true,
                  },
                )
              }
            >
              Idem résa
            </AppButton>
          )}
        </div>
      )}
    </div>
  ) : null;
};
