import {
  DiveCenterDailyConfig,
  DiveSessionResumeFull,
  DiveSessionStaffMemberTableModel,
} from '@mabadive/app-common-model';
import {
  diveModeColorClassBuilder,
  diveSessionMultipleBuilder,
  diveSessionStaffMembersTableModelBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DailySessionsResumeStaffMembersTableRowStaff } from './DailySessionsResumeStaffMembersTableRowStaff';

export const DailySessionsResumeStaffMembersTable = ({
  session,
  dailyConfigs,
  className,
}: {
  session: DiveSessionResumeFull;
  dailyConfigs: DiveCenterDailyConfig[];
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume.clubReference;

  const isMultiSessionConfigForStaff =
    diveSessionMultipleBuilder.isMultiSessionConfigForStaff(
      session.diveTourSession2,
    );

  const staffMembersTableModel: DiveSessionStaffMemberTableModel = useMemo(
    () =>
      diveSessionStaffMembersTableModelBuilder.buildTableModel({
        clubParticipants: session.participants,
        groups: session.groups,
        isMultiSessionConfigForStaff,
        staffMembersFull:
          diveSessionStaffMembersTableModelBuilder.buildStaffMembersFull({
            staffMembers: diveCenterResume?.staffMembers,
            filterMode: 'active-today',
            clubParticipants: session.participants,
            diveSession: session,
            groups: session.groups,
            staffFirstNameBefore: true,
            dailyConfigs,
            clubSettings: diveCenterResume?.clubResume?.clubSettings,
          }),
      }),
    [
      dailyConfigs,
      diveCenterResume?.clubResume?.clubSettings,
      diveCenterResume?.staffMembers,
      isMultiSessionConfigForStaff,
      session,
    ],
  );
  const {
    groupsWithoutMonitors,
    rowsCount,
    participantsWithoutGroupsCount,
    staffMembersFull: staffMembersFullAll,
  } = staffMembersTableModel;

  const staffMembersFull = useMemo(
    () => staffMembersFullAll.filter((x) => x.meta.active),
    [staffMembersFullAll],
  );

  const clubSettings = useClubSettings();

  return (
    <table className={clsx('app-table', className)}>
      <thead>
        <tr>
          <th
            scope="col"
            className="w-1/2 px-2 sm:py-1  uppercase tracking-wider"
          >
            Encadrement
          </th>
          <th
            scope="col"
            className="px-2 sm:py-1  uppercase tracking-wider whitespace-nowrap"
          >
            {isMultiSessionConfigForStaff ? 'Plongée N°1' : 'Palanquées'}
          </th>
          {isMultiSessionConfigForStaff && (
            <th
              scope="col"
              className="px-2 sm:py-1  uppercase tracking-wider whitespace-nowrap"
            >
              Plongée N°2
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {staffMembersFull.map((staffMemberFull, i) => (
          <DailySessionsResumeStaffMembersTableRowStaff
            key={i}
            session={session}
            staffMemberFull={staffMemberFull}
          />
        ))}
        {groupsWithoutMonitors.notAutonomous.length !== 0 && (
          <tr>
            <th
              className={
                'w-1/2 text-left px-2 text-xs md:text-sm uppercase text-red-400'
              }
            >
              <span className="hidden md:inline">Palanquées </span>à encadrer
            </th>
            <td
              className="flex flex-wrap gap-2 px-2"
              colSpan={isMultiSessionConfigForStaff ? 2 : undefined}
            >
              {groupsWithoutMonitors.notAutonomous.map((group) => (
                <div
                  className={
                    'text-center px-2 py-0.5 rounded text-white text-xs font-bold'
                  }
                  style={{
                    backgroundColor: diveModeColorClassBuilder.getDiveModeColor(
                      group.diveMode,
                      { clubSettings },
                    ),
                  }}
                  key={group._id}
                >
                  x{group.participantsCount}
                </div>
              ))}
            </td>
            {isMultiSessionConfigForStaff && (
              <td
                className="flex flex-wrap gap-2 px-2"
                colSpan={isMultiSessionConfigForStaff ? 2 : undefined}
              >
                {groupsWithoutMonitors.notAutonomous.map((group) => (
                  <div
                    className={
                      'text-center px-2 py-0.5 rounded text-white text-xs font-bold'
                    }
                    style={{
                      backgroundColor:
                        diveModeColorClassBuilder.getDiveModeColor(
                          group.diveMode,
                          { clubSettings },
                        ),
                    }}
                    key={group._id}
                  >
                    x{group.participantsCount}
                  </div>
                ))}
              </td>
            )}
          </tr>
        )}
        {groupsWithoutMonitors.autonomous.length !== 0 && (
          <tr>
            <th
              className={
                'text-left px-2 text-xs md:text-sm font-bold uppercase text-gray-800'
              }
            >
              <span className="hidden md:inline">Palanquées </span> en autonomie
            </th>

            <td className="flex flex-wrap gap-2 px-2 ">
              {groupsWithoutMonitors.autonomous.map((group) => (
                <div
                  className={
                    'text-center px-2 py-0.5 rounded text-white text-xs font-bold'
                  }
                  style={{
                    backgroundColor: diveModeColorClassBuilder.getDiveModeColor(
                      group.diveMode,
                      { clubSettings },
                    ),
                  }}
                  key={group._id}
                >
                  x{group.participantsCount}
                </div>
              ))}
            </td>
            {isMultiSessionConfigForStaff && (
              <td className="flex flex-wrap gap-2 px-2">
                {groupsWithoutMonitors.autonomous.map((group) => (
                  <div
                    className={
                      'text-center px-2 py-0.5 rounded text-white text-xs font-bold'
                    }
                    style={{
                      backgroundColor:
                        diveModeColorClassBuilder.getDiveModeColor(
                          group.diveMode,
                          { clubSettings },
                        ),
                    }}
                    key={group._id}
                  >
                    x{group.participantsCount}
                  </div>
                ))}
              </td>
            )}
          </tr>
        )}
        {participantsWithoutGroupsCount !== 0 && (
          <tr>
            <th
              className={
                'text-left px-2 text-xs md:text-sm font-bold uppercase text-gray-400'
              }
            >
              Hors palanquées
            </th>

            <td className="flex flex-wrap gap-2 px-2 ">
              <div
                className={
                  'text-center px-2 py-0.5 rounded text-white text-xs font-bold bg-gray-300'
                }
              >
                x{participantsWithoutGroupsCount}
              </div>
            </td>
            {isMultiSessionConfigForStaff && (
              <td className="flex flex-wrap gap-2 px-2 ">
                <div
                  className={
                    'text-center px-2 py-0.5 rounded text-white text-xs font-bold bg-gray-300'
                  }
                >
                  x{participantsWithoutGroupsCount}
                </div>
              </td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  );
};
