/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DEFAULT_EQUIPMENT_SETTINGS,
  DiveCenterEquipmentConfig,
  DiveCenterPrivateSettingsEquipment,
} from '@mabadive/app-common-model';
import {
  dataSorter,
  equipmentDescriptionSorter,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

export function useClubSettingsEquipmentsListPageLocalState() {
  const diveCenterResume = useDiveCenterResume();

  const diveCenterSettingsEquipment: DiveCenterPrivateSettingsEquipment =
    diveCenterResume.privateSettings.equipment;

  const equipments: DiveCenterEquipmentConfig[] = useMemo(() => {
    const existingTypes = diveCenterSettingsEquipment.equipments.map(
      (x) => x.type,
    );

    const allEquipments = diveCenterSettingsEquipment.equipments.concat(
      DEFAULT_EQUIPMENT_SETTINGS.filter(
        (x) => !existingTypes.includes(x.type),
      ).map((x) => ({
        ...x,
        enabled: false,
      })),
    );
    return dataSorter.sortMultiple(allEquipments, {
      getSortAttributes: (x) => [
        {
          value: x.enabled,
          asc: false,
        },
        {
          value: equipmentDescriptionSorter.getEquipmentTypeSortIndex(x.type),
        },
        // {
        //   value: equipmentFormatter.formatEquipmentType(x.type),
        // },
      ],
    });
  }, [diveCenterSettingsEquipment.equipments]);

  return {
    diveCenterResume,
    diveCenterSettingsEquipment,
    equipments,
  };
}
