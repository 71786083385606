/* eslint-disable @typescript-eslint/no-unused-vars */
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { DiveMode, DiveSessionTheme } from '@mabadive/app-common-model';
import { clubDiveSessionThemeBuilder } from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

import { AppInputBooleanRHF, AppInputRHF, AppInputTimeRHF } from 'src/lib/form';
import { AppEmojiPickerPopoverRHF } from 'src/lib/form/components/AppEmojiPicker';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl';
import { AppMultiCheckboxesRHF } from 'src/lib/form/components/AppMultiCheckboxes';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useDiveModesOptions,
  useDiveSessionThemesOptions,
  useDiveSessionTypesOptions,
} from 'src/pages/_components/options';
import { useClubBoatOptions } from 'src/pages/_components/options/useClubBoatOptions.hook';
import { useDiveSitesOptions } from 'src/pages/_components/options/useDiveSitesOptions.hook';
import {
  ClubSettingsPlanningViewDayDiveTourModel,
  ClubSettingsPlanningViewDayModel,
} from '../../../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek';
import { ClubSettingsPlanningViewWeekDiveTourHeader } from '../../../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek/ClubSettingsPlanningViewWeekDiveTourHeader';
import { ClubSettingsPlanningViewWeekDiveTourSessionTime } from '../../../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek/ClubSettingsPlanningViewWeekDiveTourSessionTime';
import {
  ClubSettingsPlanningViewWeekDayDiveTourFormModel,
  ClubSettingsPlanningViewWeekFormModel,
} from '../../model';
import { usePlanningSettingsDiveTourModel } from './usePlanningSettingsDiveTourModel.hook';
import { usePlanningSettingsOnChangeDiveSessionType } from './usePlanningSettingsOnChangeDiveSessionType.hook';

const diveSessionStatusOptions: ValueLabel<boolean, string>[] = [
  {
    value: true, // 'on',
    label: 'Ouvert',
  },
  {
    value: false, // 'off',
    label: 'Fermé',
  },
];

export const ClubSettingsPlanningViewWeekDayGeneralForm_Tour = ({
  day,
  formTour,
  dayIndex,
  diveTourIndex,
  form,
  className = '',
  onClickDelete,
}: {
  day: ClubSettingsPlanningViewDayModel;
  dayIndex: number;
  diveTourIndex: number;
  formTour: ClubSettingsPlanningViewWeekDayDiveTourFormModel;
  form: UseFormReturn<ClubSettingsPlanningViewWeekFormModel, any>;
  className?: string;
  onClickDelete: () => void;
}) => {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const diveTourControlBaseName =
    `days.${dayIndex}.diveTours.${diveTourIndex}` as const;

  const hasMultiSessionsEnabledRole =
    clubResume.clubSettings.general?.sessions?.multiSessions?.enabled;

  const currentDiveSitesIds = useMemo(
    () => [formTour.session1?.diveSiteId, formTour.session2?.diveSiteId],
    [formTour.session1?.diveSiteId, formTour.session2?.diveSiteId],
  );

  const diveSiteOptions = useDiveSitesOptions({ currentDiveSitesIds });

  const boatsOptions = useClubBoatOptions({
    currentClubBoatsIds: formTour.session1?.boatsIds,
  });

  const [
    multiSessionsEnabled,
    session1Name,
    session1Time,
    session1Boats,
    session1Special,
    session1DiveSiteId,
    session1EmojiId,
  ] = useWatch({
    control,
    name: [
      `${diveTourControlBaseName}.multiSessionsEnabled`,
      `${diveTourControlBaseName}.session1.name`,
      `${diveTourControlBaseName}.session1.time`,
      `${diveTourControlBaseName}.session1.boatsIds`,
      `${diveTourControlBaseName}.session1.special`,
      `${diveTourControlBaseName}.session1.diveSiteId`,
      `${diveTourControlBaseName}.session1.emojiId`,
    ],
  });

  const diveSessionThemesOptions = useDiveSessionThemesOptions({
    scope: 'session-settings',
  });
  const diveSessionTypesOptions = useDiveSessionTypesOptions({
    scope: 'session-settings',
  });

  const diveModesOptions: ValueLabel<DiveMode>[] = useDiveModesOptions({
    format: 'short-ref-label',
  });

  const time = session1Time;
  const timeDayPeriod = useMemo(
    () =>
      time?.getUTCHours
        ? clubDiveSessionThemeBuilder.buildTimeDayPeriod(time)
        : undefined,
    [time],
  );

  const diveTour: ClubSettingsPlanningViewDayDiveTourModel =
    usePlanningSettingsDiveTourModel({
      form,
      timeDayPeriod,
      diveCenterResume,
      diveTourControlBaseName,
      formTour,
      time,
    });

  const onChangeDiveSessionType = usePlanningSettingsOnChangeDiveSessionType(
    form,
    diveTourControlBaseName,
  );

  const onChangeDiveSessionTheme = useCallback(
    (value: DiveSessionTheme) => {
      // NOTE: fonction similaire dans DiveSessionEditFormRHF
      switch (value) {
        case 'night': {
          setValue(
            `${diveTourControlBaseName}.session1.emojiId` as const,
            'crescent_moon',
          );
          break;
        }
        case 'shark': {
          setValue(
            `${diveTourControlBaseName}.defaultDiveMode` as const,
            'snorkeling',
          );
          setValue(
            `${diveTourControlBaseName}.session1.emojiId` as const,
            'shark',
          );
          break;
        }
        case 'whale': {
          setValue(
            `${diveTourControlBaseName}.defaultDiveMode` as const,
            'snorkeling',
          );
          setValue(
            `${diveTourControlBaseName}.session1.emojiId` as const,
            'whale',
          );
          break;
        }
        case 'turtle': {
          setValue(
            `${diveTourControlBaseName}.defaultDiveMode` as const,
            'snorkeling',
          );
          setValue(
            `${diveTourControlBaseName}.session1.emojiId` as const,
            'turtle',
          );
          break;
        }
        case 'dolphin': {
          setValue(
            `${diveTourControlBaseName}.defaultDiveMode` as const,
            'snorkeling',
          );
          setValue(
            `${diveTourControlBaseName}.session1.emojiId` as const,
            'dolphin',
          );
          break;
        }
      }
    },
    [diveTourControlBaseName, setValue],
  );

  return (
    <div className={`bg-white rounded-2xl ${className ?? ''}`}>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`w-full relative hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75
              border-t border-x border-dashed border-day-period-${timeDayPeriod}-dark ${
                open ? '' : 'border-b'
              }
            `}
            >
              <ClubSettingsPlanningViewWeekDiveTourSessionTime
                diveTour={diveTour}
              />
              <ClubSettingsPlanningViewWeekDiveTourHeader
                className="h-12"
                diveTour={diveTour}
              />
              <ChevronUpIcon
                className={`absolute bottom-0 left-0 ${
                  !open ? 'transform rotate-180' : ''
                } w-5 h-5 text-gray-400`}
              />
              <ChevronUpIcon
                className={`absolute bottom-0 right-0 ${
                  !open ? 'transform rotate-180' : ''
                } w-5 h-5 text-gray-400`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-sm text-gray-500">
              <div
                className={`relative bg-white mb-1.5 border-x border-b border-dashed
                    border-day-period-${timeDayPeriod}-dark
                    text-left flex flex-col py-2 px-1`}
              >
                <div className={'bg-white'}>
                  <div className="mb-1 bg-white rounded text-gray-500 px-1">
                    <div className={'flex flex-col gap-2'}>
                      {diveSessionTypesOptions.length > 0 && (
                        <AppFormControlRHF_Deprecated
                          className="w-full"
                          label={'Type de sortie'}
                          control={control}
                          name={`${diveTourControlBaseName}.type` as const}
                          renderComponent={(props) => (
                            <AppSingleSelect2HeadlessUIRHF
                              theme="material-ui"
                              {...props}
                              options={diveSessionTypesOptions}
                              onChange={(value) =>
                                onChangeDiveSessionType(value)
                              }
                            />
                          )}
                        />
                      )}
                      {diveSessionThemesOptions.length > 0 && (
                        <AppFormControlRHF_Deprecated
                          className="w-full"
                          label={'Thème de la sortie'}
                          control={control}
                          name={`${diveTourControlBaseName}.theme` as const}
                          renderComponent={(props) => (
                            <AppSingleSelect2HeadlessUIRHF
                              theme="material-ui"
                              {...props}
                              options={diveSessionThemesOptions}
                              onChange={(value) =>
                                onChangeDiveSessionTheme(value)
                              }
                            />
                          )}
                        />
                      )}
                      <AppFormControlRHF_Deprecated
                        className="w-full"
                        label={'Prestation forcée'}
                        helpDescription="Si vous remplissez ce champ, tous les participants seront inscrit par défaut avec cette prestation."
                        control={control}
                        name={
                          `${diveTourControlBaseName}.defaultDiveMode` as const
                        }
                        renderComponent={(props) => (
                          <AppSingleSelect2HeadlessUIRHF
                            theme="material-ui"
                            {...props}
                            options={diveModesOptions}
                          />
                        )}
                      />
                      <div className="2xl:flex 2xl:gap-2">
                        <AppFormControlRHF_Deprecated
                          className=""
                          label={'Heure'}
                          control={control}
                          name={
                            `${diveTourControlBaseName}.session1.time` as const
                          }
                          required={true}
                          renderComponent={(props) => (
                            <AppInputTimeRHF {...props} />
                          )}
                        />
                        <AppFormControlRHF_Deprecated
                          className={'w-full'}
                          label={'Nom'}
                          control={control}
                          name={
                            `${diveTourControlBaseName}.session1.name` as const
                          }
                          renderComponent={(props) => (
                            <AppInputRHF {...props} fullWidth type="text" />
                          )}
                        />
                      </div>
                      <div className="2xl:flex 2xl:gap-2">
                        <AppFormControlRHF_Deprecated
                          className="w-full "
                          label={'Spéciale?'}
                          control={control}
                          name={
                            `${diveTourControlBaseName}.session1.special` as const
                          }
                          renderComponent={(props) => (
                            <AppInputBooleanRHF {...props} type="checkbox" />
                          )}
                        />
                        <AppFormControlRHF_Deprecated
                          className="w-full"
                          label={'Icône Emoji'}
                          control={control}
                          name={
                            `${diveTourControlBaseName}.session1.emojiId` as const
                          }
                          renderComponent={(props) => (
                            <AppEmojiPickerPopoverRHF {...props} />
                          )}
                        />
                      </div>

                      <AppFormControlRHF_Deprecated
                        className="w-full col-span-4"
                        label="Site de plongée"
                        control={control}
                        name={
                          `${diveTourControlBaseName}.session1.diveSiteId` as const
                        }
                        renderComponent={(props) => (
                          <AppSingleAutocomplete2RHF
                            {...props}
                            options={diveSiteOptions}
                          />
                        )}
                      />
                      {(hasMultiSessionsEnabledRole ||
                        multiSessionsEnabled) && (
                        <AppFormControlRHF_Deprecated
                          className={'w-full overflow-hidden truncate'}
                          label={'Plongées successives?'}
                          control={control}
                          name={
                            `${diveTourControlBaseName}.multiSessionsEnabled` as const
                          }
                          renderComponent={(props) => (
                            <AppInputBooleanRHF
                              {...props}
                              type="checkbox"
                              description="Double blocs"
                            />
                          )}
                        />
                      )}
                      {multiSessionsEnabled && (
                        <>
                          <AppFormControlRHF_Deprecated
                            className="w-full "
                            label={'Heure 2'}
                            control={control}
                            name={
                              `${diveTourControlBaseName}.session2.time` as const
                            }
                            required={true}
                            renderComponent={(props) => (
                              <AppInputTimeRHF {...props} />
                            )}
                          />
                          <AppFormControlRHF_Deprecated
                            className="w-full col-span-4"
                            label="Site de plongée 2"
                            control={control}
                            name={
                              `${diveTourControlBaseName}.session2.diveSiteId` as const
                            }
                            renderComponent={(props) => (
                              <AppSingleAutocomplete2RHF
                                {...props}
                                options={diveSiteOptions}
                              />
                            )}
                          />
                        </>
                      )}
                      <AppFormControlRHF_Deprecated
                        className="w-full overflow-hidden "
                        label={'Bateaux ou ressources'}
                        control={control}
                        name={
                          `${diveTourControlBaseName}.session1.boatsIds` as const
                        }
                        renderComponent={(props) => (
                          <AppMultiCheckboxesRHF
                            {...props}
                            options={boatsOptions}
                            buildLabel={(label) => (
                              <span className="text-xs font-bold truncate">
                                {label}
                              </span>
                            )}
                          />
                        )}
                      />
                      <AppFormControlRHF_Deprecated
                        className="w-full col-span-4"
                        control={control}
                        name={`${diveTourControlBaseName}.isOpen` as const}
                        renderComponent={(props) => (
                          <AppSingleSelect2HeadlessUIRHF
                            theme="material-ui"
                            disableClearButton={true}
                            // optionsStyle={{
                            //   minWidth: '300px',
                            //   maxWidth: '80%',
                            // }}
                            {...props}
                            options={diveSessionStatusOptions}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      'my-2 cursor-pointer group text-red-400 hover:text-red-600 flex flex-col items-center'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickDelete();
                    }}
                  >
                    <AppHeroIcons.actionDelete
                      className={
                        'rounded-full border border-red-600 bg-white group-hover:bg-red-400 hover:text-white w-8 h-8 p-2'
                      }
                    />
                    {/* <span className="uppercase font-bold text-xs">Supprimer</span> */}
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
