/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageAttributesProduct,
  ClubProductPackageOffer,
  ClubProductPackageOfferView,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import { ClubOfferProductEditorContext } from './ClubOfferProductEditorContext.type';
import { ClubOfferProductEditorFormModel } from './ClubOfferProductEditorFormModel.type';
import { ClubOfferProductEditorResult } from './ClubOfferProductEditorResult.type';

export const clubOfferProductEditorResultBuilder = {
  buildResult,
  buildNewOffer,
};

function buildResult({
  formValue,
  context,
  clubReference,
}: {
  formValue: ClubOfferProductEditorFormModel;
  context: ClubOfferProductEditorContext;
  clubReference: string;
}): {
  hasChanges: boolean;
  result?: ClubOfferProductEditorResult;
} {
  if (context.mode === 'create' || context.mode === 'duplicate') {
    const result: ClubOfferProductEditorResult = buildNewOffer({
      clubReference,
      formValue,
    });
    return {
      hasChanges: true,
      result,
    };
  } else if (context.mode === 'edit') {
    const {
      initialOffer,
      updatedOffer,
    }: {
      initialOffer: ClubProductPackageOfferView;
      updatedOffer: ClubProductPackageOfferView;
    } = buildUpdatedOffer({ formValue, clubReference, context });

    const productPatchOperations = jsonPatcher.compareObjects(
      initialOffer,
      updatedOffer,
      {
        attributesToReplaceFully: ['productPackage'],
      },
    );
    if (productPatchOperations.length) {
      const updatedOffer = {
        pk: initialOffer._id,
        patchOperations: productPatchOperations,
      };
      const result: ClubOfferProductEditorResult = {
        updatedOffer,
      };
      return {
        hasChanges: true,
        result,
      };
    }
  }
  return {
    hasChanges: false,
  };
}
function buildUpdatedOffer({
  formValue,
  clubReference,
  context,
}: {
  formValue: ClubOfferProductEditorFormModel;
  clubReference: string;
  context: ClubOfferProductEditorContext;
}) {
  const productAttributes: ClubProductPackageAttributesProduct =
    buildProductAttributes({ formValue });
  const newReference = buildReference({ clubReference, formValue });
  const initialOffer = context.offer;

  const isReferenceObsolete =
    formValue.productType !== initialOffer.productPackage.productType;

  // rebuild reference if main attributes changes
  const reference = isReferenceObsolete ? newReference : initialOffer.reference;

  const updatedOffer: ClubProductPackageOfferView = {
    ...context.offer,
    reference,
    price: formValue.price,
    thirdPartyCollectPrice: formValue.thirdPartyCollectPrice,
    productPackage: {
      reference,
      type: 'product',
      productType: formValue.productType,
      salesCriteria: formValue.salesCriteria,
      productAttributes,
      namingConfiguration: formValue.namingConfiguration,
      comment: formValue.comment,
      label: formValue.label,
    },
  };
  return { initialOffer, updatedOffer };
}

function buildProductAttributes({
  formValue,
}: {
  formValue: ClubOfferProductEditorFormModel;
}): ClubProductPackageAttributesProduct {
  const defaultOrganizationReference = formValue.defaultOrganizationReference;
  return {
    includeDives: false,
    defaultOrganizationReference,
    organizationReferences: defaultOrganizationReference
      ? [defaultOrganizationReference]
      : undefined,
    minAge: formValue.minAge,
    maxAge: formValue.maxAge,
  };
}

function buildReference({
  clubReference,
  formValue,
}: {
  clubReference: string;
  formValue: ClubOfferProductEditorFormModel;
}) {
  return `${clubReference};product;${
    formValue.productType
  };${new Date().getTime()}`;
}

function buildNewOffer({
  clubReference,
  formValue,
}: {
  clubReference: string;
  formValue: ClubOfferProductEditorFormModel;
}) {
  const reference = buildReference({ clubReference, formValue });
  const productAttributes: ClubProductPackageAttributesProduct =
    buildProductAttributes({ formValue });
  const newOffer = repositoryEntityBuilder.buildEntity<ClubProductPackageOffer>(
    {
      reference,
      clubReference: clubReference,
      price: formValue.price,
      thirdPartyCollectPrice: formValue.thirdPartyCollectPrice,
      productPackage: {
        reference,
        type: 'product',
        productAttributes,
        productType: formValue.productType,
        salesCriteria: formValue.salesCriteria,
        namingConfiguration: formValue.namingConfiguration,
        comment: formValue.comment,
        label: formValue.label,
      },
    },
  );
  const result: ClubOfferProductEditorResult = {
    newOffer,
  };
  return result;
}
