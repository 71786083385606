/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubOffer } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';

export function useClubOfferState(offer: ClubOffer) {
  const yesterday = useMemo(() => dateService.add(new Date(), -1, 'day'), []);

  const isCurrentOfferOver = useMemo(() => {
    if (!offer?.currentOfferEnd) {
      return false;
    }
    return dateService.isBefore(offer?.currentOfferEnd, yesterday);
  }, [offer?.currentOfferEnd, yesterday]);

  const isPaymentDeadlineOver = useMemo(() => {
    if (!offer?.paymentDeadline) {
      return false;
    }
    return dateService.isBefore(offer?.paymentDeadline, yesterday);
  }, [offer?.paymentDeadline, yesterday]);

  const isPaymentDeadlineSoon = useMemo(() => {
    if (!offer?.paymentDeadline) {
      return false;
    }
    return dateService.isBefore(
      offer?.paymentDeadline,
      dateService.add(yesterday, 14, 'day'),
    );
  }, [offer?.paymentDeadline, yesterday]);

  return {
    isCurrentOfferOver,
    isPaymentDeadlineOver,
    isPaymentDeadlineSoon,
  };
}
