import { APP_COLORS } from '@mabadive/app-common-model';
import {
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Theme,
} from '@material-ui/core';
import { fade, lighten, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useCacheableClasses } from '../../../root/theme';
import { AppIconsMaterial } from '../../icons';

export const SearchInput = ({
  onSearchChange,
  placeholder,
  autoFocus,
  initialValue,
  className,
}: {
  onSearchChange?: (text: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  initialValue?: string;
  className?: string;
}) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(initialValue ? initialValue : '');

  const classes = useCacheableClasses({
    cacheKey: 'SearchInput',
    buildStyles,
  });

  function updateValueFromComponent(value: string) {
    setValue(value);
    onSearchChange(value);
  }

  return (
    <Paper
      className={`${classes.root} ${focus ? 'focused' : ''} ${className ?? ''}`}
      elevation={1}
    >
      <InputBase
        className={`${classes.input} ${
          value && value.trim() !== '' ? 'active' : ''
        }`}
        autoFocus={autoFocus}
        placeholder={placeholder ? placeholder : 'Recherche...'}
        onChange={(e) => updateValueFromComponent(e.target.value)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        value={value}
        startAdornment={
          <InputAdornment position="start">
            <AppIconsMaterial.close
              className="input-icon"
              onClick={() => updateValueFromComponent('')}
            />
          </InputAdornment>
        }
      />
      <IconButton className={classes.button} aria-label="Search">
        <AppIconsMaterial.search />
      </IconButton>
    </Paper>
  );
};

function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      padding: '2px 10px',
      display: 'flex',
      alignItems: 'center',
      background: fade(APP_COLORS.black, 0.01),
      '&.focused, &:hover': {
        boxShadow: `0px 1px 3px 0px ${fade(
          APP_COLORS.black,
          0.25,
        )},0px 1px 1px 0px ${fade(
          APP_COLORS.black,
          0.19,
        )},0px 2px 1px -1px ${fade(APP_COLORS.black, 0.16)}`,
        background: fade(APP_COLORS.black, 0.02),
      },
    },
    input: {
      flexGrow: 1,
      '& .input-icon': {
        color: '#ccc',
        cursor: 'default',
      },
      '&.active': {
        color: APP_COLORS.red,
        '& .input-icon': {
          color: '#999',
          '&:hover': {
            color: '#555',
          },
        },
      },
    },
    button: {
      padding: 8,
      margin: '2px 0',
      flexGrow: 0,
      backgroundColor: lighten(theme.palette.primary.light, 0.8), // lighten(theme.palette.primary.light, 0.80),
      '&:hover,&:focus': {
        backgroundColor: fade(theme.palette.primary.light, 0.5),
      },
    },
  });
}
