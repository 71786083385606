/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Control,
  Path,
  UseControllerProps,
  UseControllerReturn,
  useController,
} from 'react-hook-form';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';

export type AppFormControlRHF_Deprecated_BaseProps<T, V = any> = {
  control: Control<T>;
  name: Path<T>;
  required?: boolean;
  disabled?: boolean;
  validation?: {
    email?: boolean;
    pattern?: RegExp;
  } & Pick<UseControllerProps<T>, 'rules'>;
  value?: V;
  isDiveCenterSpecificFeature?: boolean;
  helpDescription?: string;
};

const VALIDATION_EMAIL_PATTERN_ALLOW_TRAILING_LEADING_SPACES =
  /^\s*\S+@\S+\.\S+\s*$/; // https://stackoverflow.com/a/201447 + https://stackoverflow.com/a/22025206

export function AppFormControlRHF_Deprecated<T>({
  label,
  helpDescription,
  control,
  name,
  renderComponent,
  renderError,
  className,
  required,
  disabled,
  validation,
  style,
  isDiveCenterSpecificFeature,
  visibility,
}: AppFormControlRHF_Deprecated_BaseProps<T> & {
  label?: string;
  renderComponent: (
    props: AppFormControlRHF_Deprecated_BaseProps<T>,
  ) => ReactNode;
  renderError?: (attrs: {
    baseProps: AppFormControlRHF_Deprecated_BaseProps<T>;
    state: UseControllerReturn<T, any>;
    error: any;
  }) => ReactNode | null;
  className?: string;
  style?: CSSProperties;
  visibility?: 'super-admin';
}) {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(undefined);
  const toggleTooltip = useCallback(() => {
    if (helpDescription) {
      // une fois passé en mode "manuel" (activation au clic), il le reste
      setIsTooltipVisible(!isTooltipVisible);
    }
  }, [helpDescription, isTooltipVisible]);

  // const hasError = !!control._formState.errors[name];

  // ATTENTION: sur le super admin: clubResume est null
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const isMultiDiveCenters = clubResume?.diveCenters?.length > 1;

  const pattern = useMemo(() => {
    if (validation?.email) {
      return VALIDATION_EMAIL_PATTERN_ALLOW_TRAILING_LEADING_SPACES;
    }
    if (validation?.pattern) {
      return validation?.pattern;
    }
    return undefined;
  }, [validation?.email, validation?.pattern]);

  const rules = useMemo(() => validation?.rules ?? {}, [validation?.rules]);

  const state = useController<T>({
    name: name as unknown as Path<T>,
    control,
    rules: {
      ...rules,
      required,
      pattern,
    },
  });
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = state;

  const baseProps: AppFormControlRHF_Deprecated_BaseProps<T> = useMemo(
    () => ({
      control,
      name,
      required,
      disabled,
      value: inputProps.value,
    }),
    [control, name, required, disabled, inputProps.value],
  );

  const hasError = invalid || !!error;

  return (
    <div className={`flex flex-col ${className ?? ''}`} style={style}>
      <FormControl
        className="whitespace-nowrap"
        required={required}
        error={hasError}
      >
        <FormLabel
          className={'form-label pl-1 flex justify-between gap-2 items-center'}
          component="legend"
        >
          {label && (
            <span
              onClick={() => toggleTooltip()}
              className={clsx(
                'text-xs uppercase truncate flex gap-1',
                hasError
                  ? 'text-app-status-error'
                  : disabled
                  ? 'text-gray-400 italic'
                  : null,
                helpDescription && 'cursor-pointer',
              )}
            >
              {helpDescription ? (
                <Tippy
                  visible={isTooltipVisible}
                  disabled={!helpDescription?.trim()}
                  delay={[100, 100]}
                  placement="top"
                  content={helpDescription}
                  onClickOutside={() => setIsTooltipVisible(false)}
                >
                  <span>
                    <span>{label}</span>
                    <AppHeroIcons.help className="inline-block ml-2 h-4 w-4 rounded-full bg-gray-500 text-white" />
                  </span>
                </Tippy>
              ) : (
                <span>{label}</span>
              )}
              {visibility === 'super-admin' && (
                <span className="font-bold text-purple-500">#</span>
              )}
            </span>
          )}
          {isMultiDiveCenters && isDiveCenterSpecificFeature && (
            <DiveCenterProBadge
              className="inline-block mx-auto"
              diveCenter={diveCenterResume}
            />
          )}
        </FormLabel>
        <Box className="form-input">{renderComponent(baseProps)}</Box>
        <FormHelperText error={true}>
          {hasError ? renderErrorMessage(baseProps) : null}
        </FormHelperText>
      </FormControl>
    </div>
  );

  function renderErrorMessage(
    baseProps: AppFormControlRHF_Deprecated_BaseProps<T>,
  ): React.ReactNode {
    const {
      field: { ref, ...inputProps },
      fieldState: { invalid, isTouched, isDirty, error },
      formState: { touchedFields, dirtyFields },
    } = state;
    const msg = renderError ? renderError({ error, state, baseProps }) : null;
    if (msg) {
      return msg;
    }
    return renderGenericMessages({ state });
  }
}

function renderGenericMessages<T>({
  state,
}: {
  state: UseControllerReturn<any, Path<T>>;
}): React.ReactNode {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = state;

  switch (error?.type) {
    case 'required':
      return 'Champ obligatoire';
    case 'email':
      return 'Adresse e-mail invalide';
    case 'pattern':
      return 'Format invalide';
    // case 'minLength':
    //   if (
    //     attrState.validation.errorContext &&
    //     (attrState.validation.errorContext as any).minLength
    //   ) {
    //     return `Longueur minimale: ${
    //       (attrState.validation.errorContext as any).minLength
    //     } caractères`;
    //   }
    //   return 'Longeur trop courte';
    default: {
      return 'Champ invalide';
    }
  }
}
