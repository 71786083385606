import { OnlineBookingResumeForList } from '@mabadive/app-common-model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MQueryDescription, graphqlClient } from 'src/_common-browser';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { OnlineBookingGqlCollectionCriteria } from './OnlineBookingGqlCollectionCriteria.type';
import { buildOnlineBookingGqlCollectionGraphqlQuery } from './buildOnlineBookingGqlCollectionGraphqlQuery.fn';

export const onlineBookingGqlGraphqlFetcher = {
  findMany,
};

function findMany(
  criteria: OnlineBookingGqlCollectionCriteria,
  {
    type,
    name = 'findManyBookingGql1s',
  }: {
    type: 'subscription' | 'query';
    name?: string;
  },
): Observable<OnlineBookingResumeForList[]> {
  const query: MQueryDescription<any> =
    buildOnlineBookingGqlCollectionGraphqlQuery(criteria);

  return graphqlClient.query
    .runOne<OnlineBookingResumeForList[]>(query, {
      type,
      name,
    })
    .pipe(
      // tap((res) => {
      //   appLogger.info(
      //     '[bookingBookingGql1GraphqlFetcher.findMany] BookingGql1s:',
      //     res.length,
      //   );
      // }),
      catchError((err) => {
        appLogger.warn(
          '[bookingBookingGql1GraphqlFetcher] error fetching data',
          err,
        );
        return throwError(err);
      }),
    );
}
