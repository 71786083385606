import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import {
  AppButton,
  AppVisible,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { clubBookingUrlBuilder } from 'src/business/club/modules/url-builders';
import { ClubDiversListPageLocalState } from '../useClubDiversListPageLocalState.hook';
import { ClubDiversListPageConfigBarExtraFilters } from './ClubDiversListPageConfigBarExtraFilters';
import { ClubDiversListPageConfigBarFiltersResume } from './ClubDiversListPageConfigBarFiltersResume';
export const ClubDiversListPageConfigBar = ({
  localState,
}: {
  localState: ClubDiversListPageLocalState;
}) => {
  const autoFocus = useAutoFocus();

  const { filters } = localState;
  const {
    searchText,
    setSearchText,
    hasDiveToday,
    setHasDiveToday,
    hasDiveTomorrow,
    setHasDiveTomorrow,
  } = filters;

  return (
    <div className="mx-auto w-full ">
      <Disclosure
        as="div"
        className="app-card-no-padding pb-2 xs:px-2"
        defaultOpen={false}
        accessKey=""
      >
        {({ open }) => (
          <>
            <div className="flex gap-2 sm:gap-4 w-full justify-start items-center">
              <Disclosure.Button
                as={'div'}
                className={clsx(
                  'cursor-pointer focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75 ',
                  'focus:outline-none px-1 py-1',
                )}
              >
                <div className="flex gap-2 sm:gap-4 w-full justify-start items-center">
                  <div
                    className={clsx(
                      'py-0.5 px-1 focus:outline-none',
                      'border border-gray-300 hover:border-gray-400 rounded',
                      'bg-white hover:bg-gray-100 text-gray-800 hover:text-gray-900',
                    )}
                  >
                    <AppHeroIcons.actionSettingsDisplay
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 m-1 text-gray-500 `}
                    />
                  </div>
                  <div className="flex-grow flex gap-2 justify-between items-center">
                    <h2 className="text-left text-lg leading-6 font-medium text-gray-800 uppercase">
                      Plongeurs
                    </h2>
                  </div>
                </div>
              </Disclosure.Button>
              <div
                className="hidden xs:flex flex-grow cursor-default"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  className="mx-1 w-full hover:border-app-blue rounded px-2"
                  // autoFocus={autoFocus}
                  autoComplete="new-password"
                  placeholder="Recherche par nom, e-mail, téléphone..."
                  type="text"
                  defaultValue={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
              <AppVisible role="club-edit-participant-diver">
                <AppButton
                  icon={AppIconsAction.addUser}
                  color="primary-outline-light"
                  href={clubBookingUrlBuilder.buildCreateBookingUrl({
                    navigationContext: {
                      origin: 'divers',
                    },
                  })}
                >
                  <span className="">Nouveau</span>{' '}
                  <span className="hidden md:inline">plongeur</span>
                </AppButton>
              </AppVisible>
            </div>
            {!open && (
              <ClubDiversListPageConfigBarFiltersResume
                className={clsx('pt-1')}
                localState={localState}
              />
            )}
            <Disclosure.Panel className="pt-2 pr-2 text-sm text-gray-500 ">
              <ClubDiversListPageConfigBarExtraFilters
                localState={localState}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
