/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingAgency } from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';

import React, { useMemo } from 'react';
import { BookingAgenciesTableRow } from './BookingAgenciesTableRow';

export const BookingAgenciesTable = ({
  agencies,
  onClickBookingAgency,
}: {
  agencies: BookingAgency[];
  onClickBookingAgency?: (agencyId: string) => void;
}) => {
  const orderedBookingAgencies = useMemo(
    () =>
      dataSorter.sortMultiple(agencies, {
        getSortAttributes: (x) => [
          {
            value: x.active,
            asc: false,
          },
          {
            value: x.name,
          },
          {
            value: x._id,
          },
        ],
        asc: true,
      }),
    [agencies],
  );

  function clickBookingAgency(agencyId: string) {
    if (onClickBookingAgency) {
      onClickBookingAgency(agencyId);
    }
  }

  return (
    <table className={'w-full divide-y divide-gray-200 text-gray-600'}>
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Nom
          </th>
          <th
            scope="col"
            className="hidden xs:table-cell px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Commission
          </th>
          <th
            scope="col"
            className="hidden lg:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Commentaire
          </th>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          ></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {orderedBookingAgencies.map((agency, i) => (
          <BookingAgenciesTableRow
            key={i}
            agency={agency}
            onClick={() => clickBookingAgency(agency._id)}
          />
        ))}
      </tbody>
    </table>
  );
};
