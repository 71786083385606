/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveCenterResume, DiveSession } from '@mabadive/app-common-model';
import {
  commonDiveSessionBuilder,
  commonEntityBuilder,
  dateService,
} from '@mabadive/app-common-services';

import { DiveSessionEditorDialogInputState } from '../../../models';
import { diveSessionResumeToDiveSessionConvertor } from '../../../services/02.update-state/entity-convertors';
import { DiveSessionEditorInitialValue } from '../model';

export const diveSessionEditorDialogInitialValueBuilder = {
  buildInitialValue,
};

function buildInitialValue({
  inputState,
  diveCenterResume,
}: {
  inputState: DiveSessionEditorDialogInputState;
  diveCenterResume: DiveCenterResume;
}): DiveSessionEditorInitialValue {
  const { mode, originalSessionFull, defaultValue } = inputState;

  const { clubReference, _id: diveCenterId } = diveCenterResume;

  const baseInitialValue: Pick<
    DiveSessionEditorInitialValue,
    | 'initialParticipants'
    | 'initialGroups'
    | 'initialBookingSessionParticipants'
  > = {
    initialParticipants: inputState.originalSessionFull?.participants ?? [],
    initialGroups: inputState.originalSessionFull?.groups ?? [],
    initialBookingSessionParticipants:
      inputState.originalSessionFull?.bookingSessionParticipants,
  };

  if (mode === 'create-session') {
    const defaultBoatsIds = diveCenterResume?.boats
      .filter((x) => x.isDefault)
      .map((x) => x._id);

    const initialDiveSession = buildNewDiveSession({
      ...defaultValue,
      boatsIds: defaultBoatsIds,
      diveTourSession1: defaultValue.diveTourSession1 ?? {},
      special: false,
      type: 'dive',
      status: 'on',
      clubReference,
      diveCenterId,
      dayReference: null, // will be defined later
      reference: null, // will be defined later
      details: {},
      sessionsCount: defaultValue.sessionsCount ?? 1,
    });

    return {
      ...baseInitialValue,
      createSession: true,
      initialDiveSession,
    };
  } else if (mode === 'edit-session') {
    const originalSession = diveSessionResumeToDiveSessionConvertor.convert(
      originalSessionFull,
      {
        clubReference,
      },
    );

    if (originalSession.isVirtual) {
      // création automatique à partir de la session virtuelle

      const initialDiveSession = buildNewDiveSession({
        ...originalSession,
        diveTourSession1: originalSession.diveTourSession1 ?? {},
      });
      return {
        ...baseInitialValue,
        createSession: true,
        initialDiveSession,
      };
    } else {
      // édition de la session réelle
      const initialDiveSession = {
        ...originalSession,
      };
      return {
        ...baseInitialValue,
        createSession: false,
        initialDiveSession,
      };
    }
  } else if (mode === 'clone-session') {
    const originalSession: Partial<DiveSession> = {
      // clone: on ne copie pas tous les attributs (tide, weather...)
      clubReference,
      diveCenterId,
      name: originalSessionFull.name,
      time: originalSessionFull.time,
      virtualDiveSessionReference:
        originalSessionFull.virtualDiveSessionReference,
      virtualDiveTourReference: originalSessionFull.virtualDiveTourReference,
      boatsIds: originalSessionFull.boatsIds,
      special: originalSessionFull.special,
      diveSiteId: originalSessionFull.diveSiteId,
      importantNotes: originalSessionFull.importantNotes,
      type: originalSessionFull.type,
      theme: originalSessionFull.theme,
      comment: originalSessionFull.comment,
      diveTourSession1: originalSessionFull.diveTourSession1,
      diveTourSession2: originalSessionFull.diveTourSession2,
      bookingConfig: originalSessionFull.bookingConfig,
      sessionsCount: originalSessionFull.sessionsCount,
      emojiId: originalSessionFull.emojiId,
      details: originalSessionFull.details,
      // staffConfig: originalSessionFull.staffConfig,
      status: 'on',
    };

    const initialDiveSession = buildNewDiveSession({
      ...originalSession,
      virtualDiveSessionReference: originalSession.reference,
      virtualDiveTourReference: originalSession.virtualDiveTourReference,
    });
    return {
      ...baseInitialValue,
      createSession: true,
      initialDiveSession,
    };
  }
}

function buildNewDiveSession(diveSession: Partial<DiveSession>): DiveSession {
  const dateTime = diveSession?.time ?? new Date();
  const time =
    diveSession.time ??
    dateService.getUTCDateSetTime(dateTime, dateTime.getUTCHours());

  const dayReference = commonDiveSessionBuilder.buildDayReference(time);

  const suffix = commonDiveSessionBuilder.buildSessionReferenceSuffix({
    dayTime: {
      hours: diveSession.time.getUTCHours(),
      minutes: diveSession.time.getUTCMinutes(),
    },
  });

  const diveSessionReference =
    commonDiveSessionBuilder.buildDiveSessionReference({
      clubReference: diveSession.clubReference,
      dayReference,
      suffix,
    });

  const newDiveSession: DiveSession =
    commonEntityBuilder.buildEntity<DiveSession>({
      ...diveSession,
      time,
      isVirtual: false,
      reference: diveSessionReference,
      dayReference,
      sessionsCount: diveSession.diveTourSession2 ? 2 : 1,
      status: 'on',
    });

  // utile?
  delete (newDiveSession as any)['date'];
  delete (newDiveSession as any)['divingDirectorStaffDivingLevel'];
  delete (newDiveSession as any)['divingDirectorInstructorDegreeName'];
  delete (newDiveSession as any)['participants'];
  delete (newDiveSession as any)['bookingSessionParticipants'];
  delete (newDiveSession as any)['staffConfig'];

  return newDiveSession;
}
