import { BehaviorSubject } from 'rxjs';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { RxjsFormAttributeValidationState } from '../rxjs-form-validator';
import { RxjsFormAttributeValidators } from '../rxjs-form-validator/RxjsFormAttributeValidators.type';
import { RxjsFormAttributeActions } from './RxjsFormAttributeActions.model';
import { RxjsFormAttributeState } from './RxjsFormAttributeState.model';
import { RxjsFormEventSource } from './RxjsFormEventSource.type';

export type BuildRxjsFormAttributeArgs<V, S extends string> = {
  name: S;
  initialValue?: V;
  required?: boolean;
  validators?: RxjsFormAttributeValidators<V>;
};

export function buildRxjsFormAttribute<V, S extends string>({
  name,
  initialValue,
  required,
  validators,
}: BuildRxjsFormAttributeArgs<V, S>) {

  const state$ = new BehaviorSubject<RxjsFormAttributeState<V, S>>(buildInitialState<V, S>({
    name,
    initialValue,
    required,
    validators,
  }));

  state$.subscribe((state) => {

  })

  function updateInitialValue(initialValue: V, eventSource: RxjsFormEventSource) {

    const state = state$.value;

    if (initialValue !== state.initialValue) {

      appLogger.debug('[rxjsFormComponentBuilder.updateInitialValue] newState from "%s"', eventSource, initialValue);

      const newState = {
        ...state,
        initialValue,
      };

      if (!state.changed) {
        newState.value = initialValue as V;
        newState.validation = validate({
          required: state.def.required,
          value: initialValue,
          validators,
        });
        newState.showError = newState.validation.error && newState.touched;
      }

      state$.next(newState);
    }
  }

  function updateValue(value: V, eventSource: RxjsFormEventSource) {

    const state = state$.value;

    if (value !== state.value) {

      appLogger.debug(`[buildRxjsFormAttribute.updateValue] newState "${name}" from "${eventSource}"`, value, state.value);

      const validation = validate({
        required: state.def.required,
        value: value,
        validators,
      });
      const newState: RxjsFormAttributeState<V, S> = {
        ...state,
        value,
        // touched: state.touched || eventSource === 'component',
        validation,
        changed: value !== state.initialValue,
      };
      newState.showError = newState.validation.error && newState.touched;

      state$.next(newState);

    }
  }

  function touchComponent() {

    const state = state$.value;

    appLogger.debug('[rxjsFormComponentBuilder.touchComponent]');

    const newState = {
      ...state,
      touched: true,
      showError: state.validation.error,
    };

    state$.next(newState);
  }

  function resetComponent() {

    const newState = buildInitialState<V, S>({
      name,
      initialValue,
      required,
      validators,
    });

    appLogger.debug('[rxjsFormComponentBuilder.resetComponent]');

    state$.next(newState);
  }

  const actions: RxjsFormAttributeActions<V> = {
    updateInitialValue,
    updateValue,
    touchComponent,
    resetComponent,
  };

  return {
    state$,
    actions,
  };

}

function buildInitialState<V, S extends string>({
  name,
  initialValue,
  required,
  validators,
}: {
  name: S;
  initialValue?: V;
  required?: boolean;
  validators: RxjsFormAttributeValidators<V>;
}): RxjsFormAttributeState<V, S> {

  required = required === true ? true : false;

  const validation = validate({
    value: initialValue,
    required,
    validators,
  });
  const def = {
    name,
    required,
  }
  return {
    def,
    name,
    initialValue,
    value: initialValue,
    changed: false,
    touched: false,
    validation,
    showError: false,
  };
}

function validate<V>({
  required,
  value,
  validators,
}: {
  required: boolean;
  value: V;
  validators: RxjsFormAttributeValidators<V>
}): RxjsFormAttributeValidationState {
  if (required && value === undefined) {
    return {
      error: true,
      errorKey: 'required',
    };
  }
  if (!validators) {
    return {
      error: false,
    };
  }
  return Object.keys(validators).reduce((validationState, validatorKey) => {
    if (!validationState.error) {

      const validator = validators[validatorKey];

      const validationResult = validator({ value });

      if (!validationResult.valid) {
        return {
          error: true,
          errorKey: validatorKey,
          errorContext: validationResult.context,
        };
      };
    }
    return validationState;
  }, {
    error: false,
  } as RxjsFormAttributeValidationState);
}
