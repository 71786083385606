/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  ClubDiveSessionHeaderStatus,
  ClubDiveSessionHeaderTimeAndDiveSite,
  ClubDiveSessionHeaderTitle,
  ClubDiveSessionHeaderTitleWithPrefix,
} from 'src/business/club/modules/club-dive-session/components';
import { AggregatedBookingSessionFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { diveSessionStyleBuilder } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/diveSessionStyleBuilder.service';

export const DiverSessionHistoryCardHeader = ({
  bookingSessionFull,
}: {
  bookingSessionFull: AggregatedBookingSessionFull;
}) => {
  const session = bookingSessionFull.diveSession;

  const isMultiSession = !!session.diveTourSession2;
  const groupDiveSite1AndTitle = !isMultiSession && !session.diveSiteId;

  const sessionBorderStyle = diveSessionStyleBuilder.buildBorderStyleFromTime({
    isVirtual: false,
    time: session.time,
  });

  return (
    <div className={''}>
      {/* <ClubDiveSessionHeaderCard className="my-1" diveSession={session} /> */}
      <div
        className={`text-sm text-gray-600 group:hover:bg-gray-50 relative border-b ${sessionBorderStyle}`}
      >
        {groupDiveSite1AndTitle ? (
          <ClubDiveSessionHeaderTitleWithPrefix
            isHistoryPage={true}
            className={`border-t-0 ${sessionBorderStyle}`}
            diveSession={session}
            sessionIndex={1}
          />
        ) : (
          <>
            <ClubDiveSessionHeaderTimeAndDiveSite
              isHistoryPage={true}
              className={`border-t-0 ${sessionBorderStyle}`}
              diveSiteId={session.diveSiteId}
              time={session.time}
              isMultiSession={isMultiSession}
              sessionIndex={1}
              widthMode={'small'}
            />
            {isMultiSession && (
              <ClubDiveSessionHeaderTimeAndDiveSite
                isHistoryPage={true}
                className={`border-t ${sessionBorderStyle}`}
                diveSiteId={session.diveTourSession2?.diveSiteId}
                time={session.diveTourSession2?.time}
                isMultiSession={isMultiSession}
                sessionIndex={2}
                widthMode={'small'}
              />
            )}
            <ClubDiveSessionHeaderTitle
              diveSession={session}
              hideBoats={true}
              className={`py-1 border-t ${sessionBorderStyle}`}
            />
          </>
        )}
        <ClubDiveSessionHeaderStatus className={'py-1'} diveSession={session} />
        {/* <div
          className={`flex flex-col-reverse border-t
            ${sessionBorderStyle}`}
        >
          <div className={'py-1 px-0.5 flex'}>
            <ClubDiveSessionHeaderSecurityStaff
              className={'w-1/2 text-xs'}
              diveSession={session}
              staffFirstNameBefore={true}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};
