import { DashboardPaymentByMethod } from '@mabadive/app-common-model';
import { paymentMethodFilter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';

export function useDashboardPaymentsByMethod({
  paymentsByMethod,
}: {
  paymentsByMethod: DashboardPaymentByMethod[];
}): DashboardPaymentByMethod[] {
  const clubResume = useClubResume();
  const generalSettings = clubResume.clubSettings.general;
  const paymentMethods = generalSettings.payments.methods;

  return useMemo(
    () =>
      paymentMethodFilter.filterPaymentsByMethod({
        paymentMethods,
        paymentsByMethod,
      }),
    [paymentMethods, paymentsByMethod],
  );
}
