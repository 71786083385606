/* eslint-disable @typescript-eslint/no-unused-vars */
import { AccountProfile } from '@mabadive/app-common-model';
import { collectionRepositoryFactory, collectionSimpleStoreAdaptersFactory } from 'src/business/_core/data/repository';
import { adminStore } from 'src/business/_core/store';

const store = adminStore.accountProfiles;

const storeAdapters = collectionSimpleStoreAdaptersFactory.createAdapters(store);

export const adminAccountProfilesRepository = {
  ...collectionRepositoryFactory.create<AccountProfile>({
    mutationEntity: 'accountProfile',
    synchronous: false,
    storeAdapters,
  }),
};
