import { BaseEntity, JsonPatchOperation } from '@mabadive/app-common-model';
import {
  jsonPatcher,
  LoadableAttributeStore,
} from '@mabadive/app-common-services';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { RepositoryOperationSource } from '../collection';
import { localEntityRxjs } from './../localEntityRxjs.service';
import { SingleRepository } from './SingleRepository.model';

export const singleRepositoryFactory = {
  create,
};

function create<T extends BaseEntity>({
  repositoryName,
  store,
  mutationEntity,
  synchronous,
}: {
  repositoryName: string;
  store: LoadableAttributeStore<T>;
  mutationEntity: string;
  synchronous?: boolean;
}): SingleRepository<T> {
  return {
    get,
    getSnapshot,
    unload,
    patch,
  } as SingleRepository<T>;

  function get(): Observable<T> {
    return store.get().pipe(localEntityRxjs.distinctUntilEntityChanged<T>());
  }

  function getSnapshot(): T {
    return store.getSnapshot();
  }

  function unload(): Observable<void> {
    const actionId = `${repositoryName}_unload`;
    return of(undefined).pipe(
      tap(() => {
        store.unload(actionId);
      }),
    );
  }

  function patch({
    criteria,
    patchOperations,
    source,
  }: {
    criteria: Partial<T>;
    patchOperations: JsonPatchOperation[];
    source?: RepositoryOperationSource;
  }): Observable<{ patched: T }> {
    if (!source) {
      source = 'local';
    }

    const actionId = `${repositoryName}patch`;

    return of(undefined).pipe(
      switchMap(() => {
        const patched = store.reduce((item: T) => {
          const u = jsonPatcher.applyPatchOperations(
            {
              ...item,
              _updatedAt: new Date(),
              _version: incrementVersion<T>(item),
            },
            patchOperations,
          );

          return u;
        }, actionId);

        return of({ patched });
      }),
    );
  }
}

function incrementVersion<T extends BaseEntity>(item: T): number {
  return item && item._version ? item._version + 1 : 1;
}
