import { DiveMode } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppBookingViewAuth } from 'src/business/auth/services';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { DiveCenterBookingMemberNameLabel } from '../../../../_from-diver-app';
import {
  BookingResumeParticipantForSessionWithSession,
  PRO_BookingMemberFull_WithDocs,
} from '../../../models';
import { BookingMassiveEditorActions } from '../useBookingMassiveEditorDialogActions.hook';
import { BookingMassiveEditorInnerLocalState } from '../useBookingMassiveEditorInnerLocalState.hook';
import { useBookingMassiveEditorSessionsTableMemberDiveModeOptions } from './useBookingMassiveEditorSessionsTableMemberDiveModeOptions.hook';

export function BookingMassiveEditorSessionsTableMember({
  localState,
  bookingMemberFull,
  actions,
  className,
}: {
  localState: BookingMassiveEditorInnerLocalState;
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  actions: Pick<
    BookingMassiveEditorActions,
    | 'onClickRegisterAllDailyParticipants'
    | 'onClickDeleteAllDailyParticipants'
    | 'onUpdateMemberDiveMode'
    | 'onClickEditMember'
  >;
  className?: string;
}) {
  const { aggregatedData, participantsBySessions, isUniqueMember } = localState;

  const diver = bookingMemberFull?.diver;

  const diveModesOptions: ValueLabel<DiveMode, React.ReactNode>[] =
    useBookingMassiveEditorSessionsTableMemberDiveModeOptions({
      bookingMemberFull,
    });

  const memberParticipants: BookingResumeParticipantForSessionWithSession[] =
    useMemo(() => {
      return participantsBySessions.map((x) =>
        x.participants.find(
          (p) =>
            p.bookingMemberFull?.diver?._id === bookingMemberFull?.diver?._id,
        ),
      );
    }, [bookingMemberFull?.diver?._id, participantsBySessions]);
  const registeredParticipantsCount = useMemo(
    () =>
      memberParticipants
        .filter(
          ({ bookingParticipantFullAnyBooking }) =>
            !!bookingParticipantFullAnyBooking,
        )
        .map(({ bookingMemberFull }) => bookingMemberFull).length,
    [memberParticipants],
  );

  const unregisteredParticipantsCount = useMemo(
    () =>
      memberParticipants
        .filter(
          ({ bookingParticipantFullAnyBooking }) =>
            !bookingParticipantFullAnyBooking,
        )
        .map(({ bookingMemberFull }) => bookingMemberFull).length,
    [memberParticipants],
  );
  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: undefined, // sur BookingMassiveEditor, pas besoin de vérifier ça
    isSharedBooking: true, // sur BookingMassiveEditor, pas besoin de vérifier ça
  });
  const showCheckAllButton =
    bookingViewAuth.edit &&
    participantsBySessions.length > 1 &&
    !!bookingMemberFull?.diver?.defaultDiveConfig?.diveMode &&
    unregisteredParticipantsCount + registeredParticipantsCount > 0;
  return (
    <div
      className={`h-[4.70rem] flex flex-col gap-1 justify-between bg-gray-50 ring ring-gray-100 px-1 ${className}`}
    >
      <div
        className={
          'h-full flex justify-between items-center gap-1 font-bold text-xs leading-4 sm:leading-4 text-center text-gray-700'
        }
      >
        <DiveCenterBookingMemberNameLabel
          bookingMemberFull={bookingMemberFull}
          isUniqueMember={isUniqueMember}
          className={''}
        />
        {showCheckAllButton && (
          <div>
            <div
              className={`my-1 w-full font-bold py-1 px-1 cursor-pointer
            ${
              unregisteredParticipantsCount === 0
                ? 'text-app-primary'
                : 'text-gray-400 '
            }
          `}
              onClick={() => {
                if (unregisteredParticipantsCount === 0) {
                  actions.onClickDeleteAllDailyParticipants({
                    participants: memberParticipants,
                  });
                } else {
                  actions.onClickRegisterAllDailyParticipants({
                    participants: memberParticipants,
                  });
                }
              }}
            >
              <div className="w-full flex flex-row justify-start items-center gap-1 px-0">
                <input
                  className="text-app-primary ring-1 ring-white"
                  type="checkbox"
                  readOnly={true}
                  checked={unregisteredParticipantsCount === 0}
                />
                <div>TOUS</div>
              </div>
            </div>
          </div>
        )}
      </div>
      {bookingViewAuth.edit && (
        <div className="flex gap-1">
          <div>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionEdit}
              // tabIndex={500}
              color="gray-outline-light"
              onClick={() => {
                actions.onClickEditMember(bookingMemberFull);
              }}
            >
              {/* Détails */}
            </AppButton>
          </div>
          <AppSingleSelect2HeadlessUI
            className="w-full"
            theme={'tailwind'}
            required
            disableClearButton={true}
            options={diveModesOptions}
            value={bookingMemberFull.diver.defaultDiveConfig?.diveMode}
            onChange={(diveMode: DiveMode) => {
              if (
                diveMode !== bookingMemberFull.diver.defaultDiveConfig?.diveMode
              ) {
                actions.onUpdateMemberDiveMode({
                  bookingMemberFull,
                  diveMode,
                });
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
