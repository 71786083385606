/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSessionResumeGroup } from '@mabadive/app-common-model';
import React, { FC } from 'react';
import { DiveSessionEditorDialogLocalState } from '../../../../useDiveSessionEditorDialogLocalState.hook';
import { DiveSessionDialogTab3EditableGroupConfigBar_Tour1 } from './DiveSessionDialogTab3EditableGroupConfigBar_Tour1';
import { DiveSessionDialogTab3EditableGroupConfigBar_Tour2 } from './DiveSessionDialogTab3EditableGroupConfigBar_Tour2';
import { DiveSessionDialogTab3EditableGroupLocalState } from './useDiveSessionDialogTab3EditableGroupLocalState';

export const DiveSessionDialogTab3EditableGroupConfigBar: FC<{
  parentLocalState: DiveSessionEditorDialogLocalState;
  localState: DiveSessionDialogTab3EditableGroupLocalState;
  group: DiveSessionResumeGroup;
  className?: string;
}> = ({ parentLocalState, localState, group, className }) => {
  const {
    aggregatedData: { diveSession },
    isMultiSessionConfigForStaff,
  } = parentLocalState;

  return (
    <div className={className}>
      <div className="flex gap-2 sm:gap-4 w-full justify-start items-center">
        <div
          className={`ml-1 w-full ${
            isMultiSessionConfigForStaff
              ? 'flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-2'
              : ''
          }`}
        >
          <DiveSessionDialogTab3EditableGroupConfigBar_Tour1
            localState={localState}
            parentLocalState={parentLocalState}
            group={group}
          />
          {isMultiSessionConfigForStaff && (
            <DiveSessionDialogTab3EditableGroupConfigBar_Tour2
              localState={localState}
              parentLocalState={parentLocalState}
              group={group}
            />
          )}
        </div>
      </div>
    </div>
  );
};
