/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import {
  APP_FORM_CLASSNAME,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import { PurchasePaymentCard } from 'src/pages/_components/company';
import { BookingCreditNoteEditDialogActionsBar } from './BookingCreditNoteEditDialogActionsBar';
import { BookingCreditNoteEditForm } from './form';
import {
  BookingCreditNoteEditDialogState,
  BookingCreditNoteEditFormModel,
} from './model';
import {
  BookingCreditNoteEditDialogLocalState,
  useBookingCreditNoteEditDialogLocalState,
} from './useBookingCreditNoteEditDialogLocalState.hook';
import { useBookingCreditNoteEditDialogSubmit } from './useBookingCreditNoteEditDialogSubmit';

export const BookingCreditNoteEditDialog: FC<BookingCreditNoteEditDialogState> =
  (inputState) => {
    const clubResume = useClubResume();
    const clubSettings = clubResume.clubSettings;
    const clubReference = clubResume?.reference;

    const {
      isOpen,
      initialState,
      onCancel,
      onConfirm,
      openDialog,
      closeDialog,
    } = inputState;

    const { mode, defaultValue } = initialState;

    const localState: BookingCreditNoteEditDialogLocalState =
      useBookingCreditNoteEditDialogLocalState({
        initialState,
      });

    const { form } = localState;

    const {
      register,
      handleSubmit,
      watch,
      formState,
      control,
      setValue,
      reset,
    } = form;

    const formValue: BookingCreditNoteEditFormModel = watch();

    const submitForm = useBookingCreditNoteEditDialogSubmit({
      handleSubmit,
      localState,
      inputState,
    });

    const hasChanges = form.formState.isDirty;

    const [isExpandedPaymentCard, setIsExpandedPaymentCard] = useState(false);

    return !isOpen ? null : (
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-gray-50"
        footerBar={() => (
          <BookingCreditNoteEditDialogActionsBar
            onCancel={onCancel}
            submitForm={submitForm}
          />
        )}
      >
        <div className="app-p-content grid gap-4">
          <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
            {initialState.mode === 'edit' ? 'Avoir' : 'Nouvel avoir'}
          </h3>

          <div className={clsx(APP_FORM_CLASSNAME)}>
            <BookingCreditNoteEditForm
              form={form}
              initialState={initialState}
            />
          </div>
          {inputState.initialState.purchasePaymentWithDetails && (
            <div className={clsx(APP_FORM_CLASSNAME)}>
              <h3 className="mt-5 mb-2 uppercase text-base sm:text-lg text-app-blue font-bold">
                Paiement associé
              </h3>
              <PurchasePaymentCard
                purchasePaymentWithDetails={
                  inputState.initialState.purchasePaymentWithDetails
                }
                loadedDivers={localState.data.divers}
                isExpanded={isExpandedPaymentCard}
                setIsExpanded={setIsExpandedPaymentCard}
              />
            </div>
          )}
        </div>
      </AppPageContainerWithFixedBars>
    );
  };
