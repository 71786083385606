/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { uiStore } from 'src/business/_core/store';
import { DiveCenterEditDialogState, DiveCenterEditFormModel } from './model';
import { DiveCenterEditDialogLocalState } from './useDiveCenterEditDialogLocalState.hook';

export function useDiveCenterEditDialogSubmit({
  handleSubmit,
  localState,
  inputState,
}: Pick<UseFormReturn<DiveCenterEditFormModel>, 'handleSubmit'> & {
  localState: DiveCenterEditDialogLocalState;
  inputState: React.PropsWithChildren<DiveCenterEditDialogState>;
}) {
  return useCallback(async () => {
    try {
      return handleSubmit(
        (formValue: DiveCenterEditFormModel) => {
          const { onConfirm, closeDialog } = inputState;
          if (localState.hasFormChanges) {
            return onConfirm({
              formValue,
            });
          } else {
            return closeDialog();
          }
        },
        (err) => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
          throw err;
        },
      )();
    } catch (err) {
      // message already displayed
    }
  }, [handleSubmit, inputState, localState]);
}
