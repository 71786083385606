import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppInputBooleanColor } from './AppInputBooleanColor.type';
import { AppInputBooleanType } from './AppInputBooleanType.type';

// TODO une fois complètement migré vers react-hook-form, migrer les composants de 'material-ui' vers '@headlessui/react' (voir https://tailwindui.com/components/application-ui/forms)
export function AppInputBoolean({
  clearable,
  name,
  description: descriptionInputDeprecated,
  label: labelInput,
  label2,
  icon,
  className,
  type,
  value,
  onChange,
  scale,
  margin = 'mx-2.5',
  color = 'blue',
  ...extraProps
}: {
  name?: string;
  description?: string; // DOUBLON avec label (ne garder que "label", pour faire comme AppInputBooleanSwitch)
  label?: string; // DOUBLON avec description
  label2?: string;
  icon?: React.ReactNode;
  className?: string;
  clearable?: boolean;
  margin?: string; // introduit pour des raisons de compatibilités avec l'existant, pour pouvoir remplacer mx-2.5 par mr-2.5
  type?: AppInputBooleanType;
  value?: boolean | string;
  color?: AppInputBooleanColor;
  scale?: 'lg';
  onChange?: (value: boolean) => void;
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'type' | 'value' | 'onChange' | 'color'
>) {
  const isEmptyValue = useMemo(
    () => !value || ((value as string).trim && (value as string).trim() === ''),
    [value],
  );

  const label = descriptionInputDeprecated ?? labelInput;

  return (
    <div
      className={clsx(
        'inline-flex gap-1.5 items-center',
        margin,
        scale === 'lg' && 'scale-125',
      )}
    >
      <input
        {...extraProps}
        type={type}
        className={clsx(
          isEmptyValue && 'empty',
          color === 'red' && 'focus:ring-red-400 text-red-400',
          extraProps.disabled && 'border-gray-400 cursor-not-allowed',
          className,
        )}
        checked={value === true}
        onChange={() => {
          !!onChange && onChange(value !== true);
        }}
        // error={invalid}
      />
      {(icon || label || label2) && (
        <div
          className="flex items-center gap-1 cursor-pointer"
          onClick={() => {
            !!onChange && onChange(value !== true);
          }}
        >
          {icon && (
            <span className={value ? '' : 'grayscale opacity-50'}>{icon}</span>
          )}

          {(label || label2) && (
            <div>
              {label && (
                <div
                  className={clsx(
                    'uppercase text-gray-500 text-xs',
                    value && ' font-medium',
                    label2 && 'leading-3',
                  )}
                >
                  {label}
                </div>
              )}
              {label2 && (
                <div
                  className={clsx(
                    'text-xs',
                    value ? 'text-gray-600' : 'text-gray-400',
                    label && 'leading-3',
                  )}
                >
                  {label2}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
