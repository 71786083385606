/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveMode,
  DiveServiceOrganizationReference,
  RegionalSettings,
} from '@mabadive/app-common-model';
import { productPackageFormatter, search } from '@mabadive/app-common-services';
import { useMemo, useState } from 'react';
import {
  useClubResume,
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  ClubSettingsProductOffersDialogInitialStateMode,
  ClubSettingsProductOffersFiltersState,
  CreateOfferPayload,
  useClubSettingsProductOffersFiltersState,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';
import { ClubSettingsServicesOffersPricesContextMode } from '../_model';

export function useClubSettingsServicesViewPanelOffersPricesLocalState({
  parentState,
  contextMode,
}: {
  parentState: ClubSettingsServicesPageLocalState;
  contextMode: ClubSettingsServicesOffersPricesContextMode;
}) {
  const diveCenterResume = useDiveCenterResume();
  const publicSettings = useClubSettings()?.publicSettings;
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const clubReference = clubResume.reference;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const { state, dialogs, data } = parentState;

  const mainCurrency = useAppCurrencyMain();

  const contextDiveModes: DiveMode[] = useMemo(() => {
    if (contextMode === 'first-dive') {
      return ['first-dive'];
    } else if (contextMode === 'training') {
      return ['training', 'theoretical-training'];
    } else if (contextMode === 'explo') {
      return ['supervised', 'autonomous', 'autoSupervised', 'instructor'];
    } else if (contextMode === 'others') {
      return [
        'observer',
        'snorkeling',
        'snorkelingSupervised',
        'watchingTour',
        'free-dive',
        'unknown',
      ];
    }

    return [];
  }, [contextMode]);

  const [searchText, setSearchText] = useState('');

  const offersAllBeforeSearch = useMemo(() => {
    if (contextMode === 'extras') {
      return data.productPackageOffers.filter(
        (x) => x.productPackage.type === 'product',
      );
    } else if (contextMode === 'training') {
      return data.productPackageOffers.filter(
        (x) =>
          x.productPackage.type === 'training' &&
          contextDiveModes.includes(x.productPackage?.diveAttributes?.diveMode),
      );
    } else {
      return data.productPackageOffers.filter(
        (x) =>
          x.productPackage.type === 'dive' &&
          contextDiveModes.includes(x.productPackage?.diveAttributes?.diveMode),
      );
    }
  }, [contextDiveModes, contextMode, data.productPackageOffers]);

  const offersAll = useMemo(() => {
    if (searchText?.trim()?.length > 0) {
      return search.filter(offersAllBeforeSearch, {
        searchText,
        getAttributes: (offer) => {
          const nameDetails = productPackageFormatter.formatNameDetails(
            offer.productPackage,
            {
              publicSettings,
              diveModesConf: clubSettings?.ui?.diveMode,
            },
          );
          return [
            nameDetails?.label,
            nameDetails?.name,
            nameDetails?.prefix,
            nameDetails?.suffix,
          ];
        },
      });
    }
    return offersAllBeforeSearch;
  }, [
    clubSettings?.ui?.diveMode,
    offersAllBeforeSearch,
    publicSettings,
    searchText,
  ]);

  const filtersState: ClubSettingsProductOffersFiltersState =
    useClubSettingsProductOffersFiltersState({
      contextMode,
      offers: offersAll,
    });

  const openOfferEditDialog = ({
    mode,
    offers,
  }: {
    mode: ClubSettingsProductOffersDialogInitialStateMode;
    offers: CreateOfferPayload[];
  }) => {
    dialogs.clubSettingsOffers.openEdit({
      mode,
      contextMode,
      offers,
      initialFilters: mode === 'edit' ? filtersState.state.filters : undefined,
    });
  };

  const trainingOrgsRefs =
    contextMode === 'training'
      ? (
          clubSettings.services.international
            .organizationReferences as DiveServiceOrganizationReference[]
        ).concat(clubSettings.services.french.organizationReferences)
      : [];

  return {
    data: {
      clubReference,
      publicSettings,
      clubSettings,
      offersAll,
      offersAllBeforeSearch,
      offersFiltered: filtersState.data.offersFiltered,
      trainingOrgsRefs,
    },
    state: {
      ...parentState.state,
      // filters,
      // setFilters,
      // trainingCommercialCategoriesOptions,
      // organizationsOptions,
      filtersState,
      searchText,
      setSearchText,
    },
    actions: {
      // ...parentState.actions,
      openOfferEditDialog,
      // switchToCreateTrainingsMode,
    },
  };
}

export type ClubSettingsServicesViewPanelOffersPricesLocalState = ReturnType<
  typeof useClubSettingsServicesViewPanelOffersPricesLocalState
>;
