import React from 'react';
import { Control, Path, useController } from 'react-hook-form';
import { AppInputColor } from './AppInputColor';

export function AppInputColorRHF<T>({
  clearable,
  control,
  name,
  label,
  error,
  required,
  className,
  ...extraProps
}: {
  control: Control<T>;
  name: Path<T>;
  className?: string;
  clearable?: boolean;
  onChange?: (color: string) => void;
} & {
  label?: string;
  error?: boolean;
} & Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'type'
  >) {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error: errorField },
    formState: { touchedFields, dirtyFields },
  } = useController<T>({
    name,
    control,
    rules: {
      required: required,
    },
  });

  return (
    <AppInputColor
      value={value as unknown as string}
      onChange={(x) => {
        onChange(x);
        extraProps.onChange && extraProps.onChange(x);
      }}
      {...inputProps}
    />
  );
}
