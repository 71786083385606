import { RegionalTaxContext } from '@mabadive/app-common-model';
import { taxesContextBuilder } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { useProgressiveListRendering } from 'src/business/_core/data/hooks';
import { useClubResume } from 'src/business/club/data/hooks';
import { PurchaseListPagePurchaseModel } from '../model';
import { ClubPurchasesTableRow } from './ClubPurchasesTableRow';

export function ClubPurchasesTable({
  purchases: purchasesInput,
  className,
}: {
  purchases: PurchaseListPagePurchaseModel[];
  className?: string;
}) {
  const clubResume = useClubResume();
  const generalSettings = clubResume?.clubSettings?.general;

  const taxContext: RegionalTaxContext = taxesContextBuilder.buildTaxContext({
    generalSettings,
    effectiveDate: new Date(),
  });

  const showTaxes = taxContext.enabled && false; // à voir si c'est utile, on peut activer ça via une option d'affichage

  const purchasesToRender = useProgressiveListRendering(purchasesInput, {
    initialSize: 50,
    step: 100,
  });

  return (
    <table className={clsx('app-table w-full table-fixed', className)}>
      <thead>
        <tr>
          <th
            scope="col"
            className="w-[85px] text-center text-app-xxs py-1 uppercase tracking-wider hidden xs:table-cell"
          >
            Facturation
          </th>
          <th
            scope="col"
            className="w-[115px] text-center text-app-xxs py-1 uppercase tracking-wider hidden xl:table-cell"
          >
            Mise à jour
          </th>
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Contact
          </th>
          {generalSettings?.agencies?.enabled &&
            generalSettings.agencies?.agencyPurchase && (
              <th
                scope="col"
                className="px-2 py-1 uppercase tracking-wider hidden sm:table-cell"
              >
                Agence
              </th>
            )}
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Prestation
          </th>
          <th
            scope="col"
            className="w-[60px] text-center text-app-xxs py-1 uppercase tracking-wider hidden xs:table-cell"
          >
            Statut
          </th>
          <th
            scope="col"
            className="w-[60px] text-center text-app-xxs py-1  uppercase tracking-wider hidden sm:table-cell"
          >
            Imputé
          </th>
          {showTaxes && (
            <>
              <th
                scope="col"
                className="w-[60px] text-right text-app-xxs py-1  uppercase tracking-wider hidden xl:table-cell"
              >
                HT
              </th>
              {taxContext.enabledCategories.includes('vat') && (
                <th
                  scope="col"
                  className="w-[60px] text-right text-app-xxs py-1  uppercase tracking-wider hidden xl:table-cell"
                >
                  TVA
                </th>
              )}
              {taxContext.enabledCategories.includes('social') && (
                <th
                  scope="col"
                  className="w-[60px] text-right text-app-xxs py-1  uppercase tracking-wider hidden xl:table-cell"
                >
                  CPS
                </th>
              )}
            </>
          )}
          <th
            scope="col"
            className="w-[60px] text-center text-app-xxs py-1  uppercase tracking-wider hidden xl:table-cell"
          >
            Remise
          </th>
          <th
            scope="col"
            className="text-center w-[100px] lg:w-[130px] px-2 py-1  uppercase tracking-wider hidden md:table-cell"
          >
            Paiement
          </th>
          {generalSettings?.billing?.thirdPartyCollectEnabled && (
            <th
              scope="col"
              className="text-center w-[40px] lg:w-[60px] px-1 py-1  uppercase tracking-wider hidden md:table-cell"
            >
              Tiers
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {purchasesToRender.map((purchase) => (
          <ClubPurchasesTableRow
            key={purchase._id}
            purchase={purchase}
            showTaxes={showTaxes}
            taxContext={taxContext}
          />
        ))}
      </tbody>
    </table>
  );
}
