/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import {
  AppButton,
  AppFormAutoSubmit,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';

import { AppIcons } from 'src/business/_core/modules/layout/icons';
import {
  ClubSettingsServicesDialogState,
  ClubSettingsServicesFormModel,
} from '../_model';
import { ClubSettingsServicesDialogActionsBar } from './ClubSettingsServicesDialogActionsBar';
import { ClubSettingsServicesDialogForm } from './ClubSettingsServicesDialogForm';
import {
  ClubSettingsServicesDialogLocalState,
  useClubSettingsServicesDialogLocalState,
} from './useClubSettingsServicesDialogLocalState.hook';
import { useClubSettingsServicesDialogSubmit } from './useClubSettingsServicesDialogSubmit.hook';

export const ClubSettingsServicesDialog: FC<ClubSettingsServicesDialogState> = (
  inputState,
) => {
  const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] = useState(false);
  const globalClasses = useGlobalClasses();

  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const clubReference = clubResume?.reference;

  const { isOpen, initialState, onCancel, onConfirm, openDialog, closeDialog } =
    inputState;

  const { defaultValue } = initialState;

  const localState: ClubSettingsServicesDialogLocalState =
    useClubSettingsServicesDialogLocalState({
      initialState,
    });

  const tabId = initialState.tabId;

  const { form } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue: ClubSettingsServicesFormModel = watch();

  const submitForm = useClubSettingsServicesDialogSubmit({
    handleSubmit,
    localState,
    inputState,
  });

  const hasChanges = form.formState.isDirty;

  return !isOpen ? null : (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={() => (
        <ClubSettingsServicesDialogActionsBar
          className="sm:hidden"
          onCancel={onCancel}
          submitForm={submitForm}
        />
      )}
    >
      <div className="app-p-content">
        <AppFormAutoSubmit onSubmit={submitForm}>
          <ClubSettingsServicesDialogForm localState={localState} />
          <div className="hidden sm:block sticky -bottom-8 pt-8 app-card">
            <div className="app-p-content flex gap-4 justify-start">
              <AppButton
                size="normal"
                icon={AppIcons.action.cancel}
                tabIndex={500}
                color="gray-outline-light"
                onClick={() => {
                  onCancel();
                }}
              >
                Annuler
              </AppButton>
              <AppButton
                type="submit"
                size="normal"
                icon={AppIcons.action.save}
                color="primary-bg"
                onClick={() => {
                  submitForm();
                }}
              >
                Confirmer
              </AppButton>
            </div>
          </div>
        </AppFormAutoSubmit>
      </div>
    </AppPageContainerWithFixedBars>
  );
};
