/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubProductPackageOffer,
  ClubProductPackageOfferView,
  ClubServicesGlobalSettingsOrgGroup,
  DiveServiceOrganizationReference,
  DIVE_SERVICE_ORGANIZATIONS,
} from '@mabadive/app-common-model';
import React, { useCallback, useMemo, useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppPageContainer,
  AppPageContentContainer,
  AppTabsBar,
  AppTabsBarTab,
  SearchInput,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppActionButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppFormControlV2, AppSingleAutocomplete2 } from 'src/lib/form';
import useRouter from 'use-react-router';
import { clubSettingsOffersUrlBuilder } from '../../../url-builders';
import { ValueLabel } from '../../../_common/form/components/ValueLabel.model';
import { AppHamburgerMenu, AppHamburgerMenuItem } from '../../../_common/ui';
import {
  GenerateOffersOptions,
  getOffersTrainingFrenchGenericGeneratorOptions,
  getOffersTrainingInternationalGenericGeneratorOptions,
  offersTrainingGenerator,
} from '../club-settings-offer-dive/offers-generator';
import { ClubOfferTabId, CLUB_OFFER_SPACE_TABS } from '../_common';
import { useOfferListPageLoadData } from '../_common/hooks';
import {
  ClubOfferTrainingEditor,
  ClubOfferTrainingEditorResult,
} from './ClubOfferTrainingEditor';
import { ClubOfferTrainingListPageState } from './ClubOfferTrainingListPageState.type';
import { ClubOfferTrainingMultiEditor } from './ClubOfferTrainingMultiEditor';
import { ClubOfferTrainingTable } from './ClubOfferTrainingTable';
export const ClubOfferTrainingListPage = (props: {}) => {
  const { match } = useRouter();
  const autoFocus = useAutoFocus();

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const [searchText, setSearchText] = useState('');

  const [state, setState] = useState<ClubOfferTrainingListPageState>({
    mode: 'view',
  });

  const isAdvancedEditEnabled = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const productPackageOffersLoaded = useOfferListPageLoadData({
    type: 'training',
    searchText,
    sortBy: 'training',
  });

  const [orgRef, setOrgRef] = useState('');
  const organizationsOptions = useMemo(
    () =>
      DIVE_SERVICE_ORGANIZATIONS.filter(
        (org) =>
          productPackageOffersLoaded.findIndex(
            (x) =>
              x.productPackage.productAttributes
                .defaultOrganizationReference === org.reference,
          ) !== -1,
      ).map((org) => {
        const option: ValueLabel<DiveServiceOrganizationReference> = {
          label: org.label.toUpperCase(),
          value: org?.reference,
        };
        return option;
      }),
    [productPackageOffersLoaded],
  );
  const productPackageOffers = useMemo(
    () =>
      orgRef
        ? productPackageOffersLoaded.filter(
            (x) =>
              x.productPackage.productAttributes
                .defaultOrganizationReference === orgRef,
          )
        : productPackageOffersLoaded,
    [orgRef, productPackageOffersLoaded],
  );

  const generateOffers = useCallback(
    async ({
      orgGroup,
      organizationReference,
    }: {
      orgGroup: ClubServicesGlobalSettingsOrgGroup;
      organizationReference: DiveServiceOrganizationReference;
    }) => {
      if (
        await confirmDialog.confirmPromise({
          title: `Générer les prestations "${organizationReference.toUpperCase()}"`,
          message: `Êtes-vous sûr de vouloir générer les prestations "${organizationReference.toUpperCase()}"?`,
          type: 'noYesDanger',
        })
      ) {
        const options: GenerateOffersOptions =
          orgGroup === 'french'
            ? getOffersTrainingFrenchGenericGeneratorOptions({
                defaultOrganizationReference: organizationReference,
              })
            : getOffersTrainingInternationalGenericGeneratorOptions({
                defaultOrganizationReference: organizationReference,
              });

        let offers: ClubProductPackageOffer[] = [];

        if (options.training) {
          offers = offers.concat(
            offersTrainingGenerator.generateTrainingOffers({
              clubReference,
              options: options.training,
            }),
          );
        }

        if (offers.length) {
          await clubMassiveUpdatorClient.update({
            newProductPackageOffers: offers,
          });
        }
      }
    },
    [clubReference],
  );

  const hamburgerMenuItems = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    if (state.mode === 'view') {
      if (isAdvancedEditEnabled) {
        hamburgerMenuItems.push({
          id: 'create-offer',
          title: 'Ajouter',
          description: 'Ajouter une prestation',
          icon: AppHeroIcons.actionAdd,
          onClick: () => {
            setState({
              mode: 'create',
            });
          },
        });
      }
      hamburgerMenuItems.push({
        id: 'edit-offers',
        title: 'Modifier',
        description: 'Modifier les tarifs des prestations visibles',
        icon: AppHeroIcons.actionEdit,
        onClick: () => {
          setState({
            mode: 'edit-multi',
          });
        },
      });
      if (isAdvancedEditEnabled) {
        const services = clubResume.clubSettings.services;
        if (services.french?.enabled) {
          if (services.french?.organizationReferences.includes('ffessm')) {
            hamburgerMenuItems.push({
              id: 'generate-offers',
              title: 'Générer les formations FFESSM',
              icon: AppHeroIcons.autoFill,
              onClick: () => {
                generateOffers({
                  orgGroup: 'french',
                  organizationReference: 'ffessm',
                });
              },
            });
          }
          if (services.french?.organizationReferences.includes('anmp')) {
            hamburgerMenuItems.push({
              id: 'generate-offers',
              title: 'Générer les formations ANMP',
              icon: AppHeroIcons.autoFill,
              onClick: () => {
                generateOffers({
                  orgGroup: 'french',
                  organizationReference: 'anmp',
                });
              },
            });
          }
          if (services.french?.organizationReferences.includes('fsgt')) {
            hamburgerMenuItems.push({
              id: 'generate-offers',
              title: 'Générer les formations FSGT',
              icon: AppHeroIcons.autoFill,
              onClick: () => {
                generateOffers({
                  orgGroup: 'french',
                  organizationReference: 'fsgt',
                });
              },
            });
          }
        }
        if (services.international?.enabled) {
          if (services.international?.organizationReferences.includes('padi')) {
            hamburgerMenuItems.push({
              id: 'generate-offers',
              title: 'Générer les formations PADI',
              icon: AppHeroIcons.autoFill,
              onClick: () => {
                generateOffers({
                  orgGroup: 'international',
                  organizationReference: 'padi',
                });
              },
            });
          }
          if (services.international?.organizationReferences.includes('ssi')) {
            hamburgerMenuItems.push({
              id: 'generate-offers',
              title: 'Générer les formations SSI',
              icon: AppHeroIcons.autoFill,
              onClick: () => {
                generateOffers({
                  orgGroup: 'international',
                  organizationReference: 'ssi',
                });
              },
            });
          }
        }
      }
    }
    return hamburgerMenuItems;
  }, [
    clubResume.clubSettings.services,
    generateOffers,
    isAdvancedEditEnabled,
    state.mode,
  ]);

  const quickAction: AppHamburgerMenuItem = useMemo(() => {
    {
      const action = hamburgerMenuItems.find((x) => x.id === 'create-offer');
      if (action && !action.hidden) {
        return action;
      }
    }
    {
      const action = hamburgerMenuItems.find((x) => x.id === 'edit-offers');
      if (action && !action.hidden) {
        return action;
      }
    }
  }, [hamburgerMenuItems]);
  const onCreateOrUpdateOffer = useCallback(
    async (result: ClubOfferTrainingEditorResult) => {
      setState({
        mode: 'view',
      });
      if (result) {
        await clubMassiveUpdatorClient.update({
          newProductPackageOffers: result.newOffer
            ? [result.newOffer]
            : undefined,
          updatedProductPackageOffers: result.updatedOffer
            ? [result.updatedOffer]
            : undefined,
        });
      }
    },
    [],
  );
  const onDeleteOffer = useCallback(
    async (offer: ClubProductPackageOfferView) => {
      if (offer) {
        await clubMassiveUpdatorClient.update({
          deletedProductPackageOfferIds: [offer._id],
        });
      }
    },
    [],
  );
  const onConfirmOffersChanges = useCallback(
    async ({
      updatedProductPackageOffers,
    }: {
      updatedProductPackageOffers: AppEntityUpdatePatch[];
    }) => {
      setState({
        mode: 'view',
      });
      if (updatedProductPackageOffers.length) {
        await clubMassiveUpdatorClient.update({
          updatedProductPackageOffers,
        });
      }
    },
    [],
  );
  return (
    <AppPageContainer className={'bg-white'}>
      {/* <ClubOfferBreadcrumbBar /> */}

      <AppTabsBar
        theme="settings"
        tabs={CLUB_OFFER_SPACE_TABS}
        value="offers-training"
        onChange={(tab) => selectTab(tab)}
      />

      <AppPageContentContainer className="bg-white app-px-content">
        {state.mode === 'view' && (
          <>
            <div
              className={`${globalClasses.pageBlock} w-full flex flex-col md:flex-row gap-2`}
            >
              <SearchInput
                className="flex-grow"
                initialValue={searchText}
                autoFocus={autoFocus}
                onSearchChange={(text) => setSearchText(text)}
                placeholder="Recherche"
              />
              <div className="flex gap-2 justify-end">
                {organizationsOptions.length > 1 && (
                  <AppFormControlV2
                    className="w-full md:w-32"
                    label="Organisme"
                    renderComponent={() => (
                      <AppSingleAutocomplete2
                        className="w-full md:w-32"
                        fullWidth={false}
                        options={organizationsOptions}
                        value={orgRef}
                        onChange={(orgRef) => {
                          setOrgRef(orgRef);
                        }}
                      />
                    )}
                  />
                )}
                {quickAction && (
                  <AppActionButton className="ml-2" action={quickAction} />
                )}
                {(!quickAction || hamburgerMenuItems.length !== 1) && (
                  <AppHamburgerMenu
                    buttonClassName="ml-2 py-3 bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white"
                    position="left-start"
                    items={hamburgerMenuItems}
                    buttonIcon={AppHeroIcons.actionMenu}
                    buttonLabel={'Prestations'}
                  />
                )}
              </div>
            </div>

            <div className={globalClasses.pageBlock}>
              <ClubOfferTrainingTable
                productPackageOffers={productPackageOffers}
                setState={setState}
                onDelete={onDeleteOffer}
              />
            </div>
          </>
        )}
        {(state.mode === 'create' ||
          state.mode === 'edit-single' ||
          state.mode === 'duplicate-single') && (
          <>
            <div className={globalClasses.pageBlock}>
              <ClubOfferTrainingEditor
                onCancel={() =>
                  setState({
                    mode: 'view',
                  })
                }
                context={{
                  mode:
                    state.mode === 'create'
                      ? 'create'
                      : state.mode === 'duplicate-single'
                      ? 'duplicate'
                      : 'edit',
                  offer: state.selectedOffer,
                }}
                onConfirm={(offer) => onCreateOrUpdateOffer(offer)}
              />
            </div>
          </>
        )}
        {state.mode === 'edit-multi' && (
          <>
            <div className={globalClasses.pageBlock}>
              <ClubOfferTrainingMultiEditor
                productPackageOffers={productPackageOffers}
                onCancel={() =>
                  setState({
                    mode: 'view',
                  })
                }
                onConfirm={({ updatedProductPackageOffers }) =>
                  onConfirmOffersChanges({ updatedProductPackageOffers })
                }
              />
            </div>
          </>
        )}
      </AppPageContentContainer>
    </AppPageContainer>
  );

  function selectTab(tab: AppTabsBarTab<ClubOfferTabId>) {
    return redirectTo(
      clubSettingsOffersUrlBuilder.buildTabUrl({
        tabId: tab.id,
      }),
    );
  }
};
