import {
  AppDocUploadContext,
  AppTranslationContentItem,
  AppTranslationContentItemDoc,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import React from 'react';

import {
  ArrayPath,
  FieldValues,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { AppRichTextEditorRHFControl } from 'src/lib/form/components/AppRichText';
import { AppDragableCard } from '../../../../../pages/SE-settings/SE-03-customers/online-booking/ClubSettingsOnlineBookingConfigPage/tabs/02.products/view/BookletEditor_EditablePreview';
import { AppDocResumeImageUploader } from '../../AppInputImage';

export function AppTranslationContentItemsForm<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
>({
  form,
  name,
  fieldsArray,
  appDocUploadContext,
  className,
}: {
  form: UseFormReturn<any>;
  name: TFieldArrayName;
  fieldsArray: UseFieldArrayReturn<TFieldValues, TFieldArrayName, 'id'>;
  appDocUploadContext: AppDocUploadContext;
  className?: string;
}) {
  const { control, register, setValue } = form;

  const { fields, remove, move, update } = fieldsArray;

  return (
    <>
      {fields.map((field, index) => {
        const contentItem = field as unknown as AppTranslationContentItem;

        const type = contentItem.type;

        return (
          <AppDragableCard
            key={field.id}
            index={index}
            id={field.id}
            moveCard={(dragIndex: number, hoverIndex: number) => {
              move(index, dragIndex);
            }}
          >
            <div className={`relative ${className}`} key={field.id}>
              <div
                className={`absolute z-10 p-1 cursor-pointer bg-gray-600 hover:bg-gray-800  text-red-400 hover:text-red-500
              ${type === 'button' ? 'top-1 right-1 ' : 'top-5 right-1 '}
            `}
                onClick={() => remove(index)}
              >
                <BsTrash className=" w-6 h-6 place-self-center" />
              </div>
              {/* {type === 'title' && (
                <AppInputRHF
                  {...register(`${name}.${index}.text` as const)}
                  placeholder={'Titre'}
                  className="my-2 text-center text-xl font-bold"
                />
              )} */}
              {/*  {type === 'text' && (
                <AppTextarea
                  {...register(`${name}.${index}.text` as const)}
                  label="Texte"
                  className="my-2 text-center text-xl"
                />
              )} */}
              {type === 'rich-text' && (
                <AppRichTextEditorRHFControl
                  control={control}
                  label="Paragraphe"
                  name={`${name}.${index}.richText`}
                  className="my-2"
                />
              )}
              {type === 'app-doc-image' && (
                <>
                  {!contentItem.appDoc ? (
                    <AppDocResumeImageUploader
                      uploadUrl="/pro/docs/upload"
                      expectedImage={{
                        width: 1000,
                        height: 400,
                        variantsRatio: [],
                      }}
                      uploadPayload={{ context: appDocUploadContext }}
                      onSuccess={(appDoc: PRO_AppDocResume) => {
                        const contentAppDoc: AppTranslationContentItemDoc = {
                          _id: appDoc._id,
                          fileType: appDoc.fileType,
                          publicUrl: appDoc.publicUrl,
                        };
                        setValue(
                          `${name}.${index}.appDoc`,
                          contentAppDoc as any,
                        );
                        update(index, {
                          ...contentItem,
                          appDoc: contentAppDoc,
                        } as any);
                      }}
                      onCancel={() => {
                        // TODO
                      }}
                    />
                  ) : (
                    <div>
                      {contentItem.appDoc.publicUrl && (
                        <img
                          className="max-w-sm"
                          src={contentItem.appDoc.publicUrl}
                          alt={'illustration'}
                        />
                      )}
                    </div>
                  )}
                </>
                // <AppInputPhotoRHFControl
                //   deleteLabel={t({ id: 'common.action.supprimer' })}
                //   control={control}
                //   label="Image"
                //   type="large-image"
                //   showEmptyImage={true}
                //   showGallery={false}
                //   name={`${name}.${index}.image`}
                //   url={'/business-web/activity/images'}
                //   className="my-2"
                // />
              )}
              {/* {type === 'button' && (
                <div className="px-2 border-2 border-gray-300 border-dashed bg-white">
                  <h2 className="text-left text-xs pr-2 text-gray-400 font-bold uppercase">
                    {t({ id: 'common.label.bouton' })}
                  </h2>
                  <AppInputButtonRHFControl
                    control={control}
                    label={t({ id: 'common.label.bouton' })}
                    name={`${name}.${index}.button`}
                    className="my-2"
                  />
                </div>
              )} */}
            </div>
          </AppDragableCard>
        );
      })}
    </>
  );
}
