/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButtonsBar,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  clubBookingUrlBuilder,
  clubParticipantUrlBuilder,
  useParticipantBackUrl,
} from 'src/business/club/modules/url-builders';
import { GenericNavigationContext } from '../../../ClubParticipantNavigationContext/GenericNavigationContext.type';
import { BookingInitialPlanRegistrationMode } from '../../DiverBookingPage/forms/BookingInitialPlanEditForm/model/BookingInitialPlanRegistrationMode.type';
import { SelectOrCreateDiverAndBookingPannel } from './SelectOrCreateDiverAndBookingPannel';

export const SelectOrCreateDiverAndBookingPage = ({
  navigationContext,
}: {
  navigationContext: GenericNavigationContext;
}) => {
  const redirectTo = useRedirect();

  const backUrl = useParticipantBackUrl({
    navigationContext,
  });

  const back = useCallback(() => {
    redirectTo(backUrl);
  }, [backUrl, redirectTo]);

  const redirectoToBookingCreateParticipant = useCallback(
    ({ diverId }: { diverId: string }) => {
      redirectTo(
        clubParticipantUrlBuilder.buildTabUrl({
          tabId: 'booking',
          navigationContext: {
            origin: navigationContext?.origin ?? 'planning',
            diveSessionReference: navigationContext.diveSessionReference,
            diverId,
            action: 'create-booking-participant',
          },
        }),
      );
    },
    [
      navigationContext.diveSessionReference,
      navigationContext?.origin,
      redirectTo,
    ],
  );

  return (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-white app-p-content"
      footerBar={() => (
        <AppButtonsBar hasChanges={true}>
          <>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionCancel}
              tabIndex={500}
              color="gray-outline-light"
              onClick={back}
            >
              {'Fermer'}
            </AppButton>
          </>
        </AppButtonsBar>
      )}
    >
      <SelectOrCreateDiverAndBookingPannel
        onSelectExistingDiver={({ diver }) => {
          if (diver) {
            redirectoToBookingCreateParticipant({
              diverId: diver._id,
            });
          }
        }}
        onSelectCreate1Diver={() => {
          redirectTo(
            clubBookingUrlBuilder.buildCreateBookingInitialPlanUrl({
              navigationContext,
              queryParams: {
                registrationMode:
                  'single' as BookingInitialPlanRegistrationMode,
              },
            }),
          );
        }}
        onSelectCreateManyDivers={() => {
          redirectTo(
            clubBookingUrlBuilder.buildCreateBookingInitialPlanUrl({
              navigationContext,
              queryParams: {
                registrationMode:
                  'multiple' as BookingInitialPlanRegistrationMode,
              },
            }),
          );
        }}
      />
    </AppPageContainerWithFixedBars>
  );
};
