import { DiveServiceOrganizationReference } from '@mabadive/app-common-model';
import {
  GenerateOffersDivePlanOptions,
  GenerateOffersOptions,
  GenerateOffersTrainingOptions,
} from '../model';

export const getOffersTrainingFrenchGenericGeneratorOptions = ({
  defaultOrganizationReference,
}: {
  defaultOrganizationReference?: DiveServiceOrganizationReference;
}): GenerateOffersOptions => {
  const divePlan: GenerateOffersDivePlanOptions = {
    snorkelings: {
      enabled: false,
      offers: [],
    },
    firstDives: {
      enabled: false,
      offers: [],
    },
    scubaDives: {
      enabled: false,
      offers: [],
    },
  };
  const training: GenerateOffersTrainingOptions = {
    enabled: true,
    offers: [
      {
        trainingAttributes: {
          diveTrainingReference: 'FN1', // Niveau 1
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 5,
        },
        price: 300,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FN2', // Niveau 2
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 10,
        },
        price: 500,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FN3', // Niveau 3
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 10,
        },
        price: 500,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FN4', // Niveau 4
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 10,
        },
        price: 1000,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FN5', // Niveau 5
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 10,
        },
        price: 1000,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FPE12',
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 4,
        },
        price: 250,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FPA12',
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 5,
        },
        price: 300,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FPA20',
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 6,
        },
        price: 350,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FPE40',
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 4,
        },
        price: 250,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FPE60',
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 4,
        },
        price: 250,
      },
      {
        trainingAttributes: {
          diveTrainingReference: 'FPA40',
        },
        productAttributes: {
          defaultOrganizationReference,
        },
        diveAttributes: {
          divesCount: 6,
        },
        price: 300,
      },
    ],
  };
  const options: GenerateOffersOptions = {
    divePlan,
    training,
  };
  return options;
};
