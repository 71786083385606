/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPurchasePackage,
  ClubSettings,
  PurchaseResumeOffer,
} from '@mabadive/app-common-model';
import {
  clubProductPackageMetaReader,
  dateService,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  BillingTabDiveSessionBillingResume,
  DiverBookingPageAggregatedData,
} from '../../models';
import { defaultBookingBuilder } from '../../services';
import { bookingPagePackageConsumedCounter } from '../../services/02.update-state/services';
import { DiverPurchaseTrainingFormModel } from './components';

export function useDiverPurchaseTrainingEditorDialogInitialValue({
  initialAssociatedBookingProductIds,
  purchasePackage,
  defaultProductPackageOffer,
  mode,
  clubSettings,
  billingResumes,
  aggregatedData,
  diveCenterId,
}: {
  purchasePackage?: ClubPurchasePackage;
  defaultProductPackageOffer: PurchaseResumeOffer;
  mode: 'create' | 'edit';
  initialAssociatedBookingProductIds: string[];
  clubSettings: ClubSettings;
  billingResumes: BillingTabDiveSessionBillingResume[];
  aggregatedData: DiverBookingPageAggregatedData;
  diveCenterId: string;
}) {
  const clubResume = useClubResume();

  const initialFormValue: DiverPurchaseTrainingFormModel = useMemo(() => {
    if (mode === 'create') {
      const productPackageMeta = clubProductPackageMetaReader.readMeta(
        defaultProductPackageOffer?.productPackage,
      );

      const creditsInitialCount =
        productPackageMeta?.productDefaultCreditsCount;
      const totalCreditsCount = creditsInitialCount;

      const { consumedAppCount } =
        bookingPagePackageConsumedCounter.buildConsumedAppCountOnBuildUpdateResume(
          {
            clubSettings,
            billingResumes,
            assignedBookingProductsIds: initialAssociatedBookingProductIds,
            diverId: purchasePackage?.diverId,
            countSuccessiveAsSingle: false,
          },
        );
      const consumedTotalCount = consumedAppCount;

      const trainingAttributes =
        defaultProductPackageOffer?.productPackage?.trainingAttributes;
      const productAttributes =
        defaultProductPackageOffer?.productPackage?.productAttributes;

      const initialBookingId = defaultBookingBuilder.getDefaultBookingId(
        aggregatedData.bookingResumesVisible,
        {
          diveCenterId,
          focusDiverId: aggregatedData.focus?.clubDiver._id,
        },
      );

      return {
        diveTrainingReference: trainingAttributes?.diveTrainingReference,
        productPackageOfferReference: defaultProductPackageOffer?.reference,
        unitPrice: defaultProductPackageOffer?.price,
        totalPriceThirdPartyCollect:
          defaultProductPackageOffer?.thirdPartyCollectPrice,
        creditsInitialCount,
        purchaseDate: dateService.getUTCDateWithoutTimeFromLocalTime(
          new Date(),
        ),
        extraCosts: [],
        validityStatus:
          consumedTotalCount >= totalCreditsCount ? 'completed' : 'active',
        creditsAdditionalCount: 0,
        consumedExternalCount: 0,
        bookingId: initialBookingId,
        billedByBookingAgencyId: undefined,
      };
    } else {
      if (purchasePackage) {
        const credits = purchasePackage.credits;

        const purchaseProductPackage =
          purchasePackage?.productPackageOffer?.productPackage;

        return {
          diveTrainingReference:
            purchaseProductPackage?.trainingAttributes?.diveTrainingReference,
          productPackageOfferReference:
            purchasePackage.productPackageOffer.reference,
          purchaseDate: purchasePackage.purchaseDate,
          totalPriceThirdPartyCollect:
            purchasePackage.totalPriceThirdPartyCollect,
          unitPrice: purchasePackage.unitPrice,
          discountAmount: purchasePackage.discountAmount,
          extraCosts: purchasePackage.extraCosts ?? [],
          comment: purchasePackage.comment,
          validityStatus: purchasePackage.validityStatus,
          creditsAdditionalCount: credits.creditsAdditionalCount,
          creditsInitialCount: credits.creditsInitialCount,
          consumedExternalCount: credits.consumedExternalCount,
          bookingId: purchasePackage.bookingId,
          billedByBookingAgencyId: purchasePackage.billedByBookingAgencyId,
        };
      }
    }
  }, [
    aggregatedData.bookingResumesVisible,
    aggregatedData.focus?.clubDiver._id,
    billingResumes,
    clubSettings,
    defaultProductPackageOffer?.price,
    defaultProductPackageOffer?.productPackage,
    defaultProductPackageOffer?.reference,
    defaultProductPackageOffer?.thirdPartyCollectPrice,
    diveCenterId,
    initialAssociatedBookingProductIds,
    mode,
    purchasePackage,
  ]);

  return initialFormValue;
}
