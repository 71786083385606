/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubDiverBookingBase,
  ClubDiverFull,
  ClubDiverLinkedData,
  DiveSession,
} from '@mabadive/app-common-model';
import { arrayBuilder } from '@mabadive/app-common-services';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { apiClient } from 'src/_common-browser';
import { useClubResume } from 'src/business/club/data/hooks';

export type DiverBookingPageClubDiverLinkedData = ClubDiverLinkedData & {
  bookingLoadedIds: string[]; // on doit charger des données de résas non affichées, pour pouvoir construire tous les objets dont on a besoin
  bookingVisibleAndPersistedIds: string[]; // mais ensuite, on les filtre à l'affichage (attention, ça n'inclue pas les nouvelles résas non persistées)
  diverIdsBookingLoaded: string[];
  diverIdsAll: string[];
  // bookingAllIds: string[];
  includeArchivedPurchases: boolean;
};

export type DiverBookingPageAdditionalDataToLoad = {
  newBookingIds: string[]; // booking créé, à rendre visible
  bookingIds: string[];
  diverIds: string[];
};

export function useDiverBookingPageClubDiverLinkedData({
  mainDiverId,
  focusDiveSessionReference,
  includeArchivedBookings,
  includeArchivedPurchases,
}: {
  mainDiverId: string;
  focusDiveSessionReference: string;
  includeArchivedBookings: boolean;
  includeArchivedPurchases: boolean;
}): {
  refetchLinkedData: () => void;
  linkedData: DiverBookingPageClubDiverLinkedData;
  mainDiverFull: ClubDiverFull;
  focusDiveSession?: DiveSession;
  addDiversIds: (
    newDivers: {
      diverId: string;
      isNewDiver: boolean;
    }[],
  ) => void;
  markNewDiversAsPersisted: () => void;
} {
  const clubResume = useClubResume();

  // const [linkedData, setLinkedData] = useState<ClubDiverLinkedData>(undefined);

  const [additionalDivers, setAdditionalDivers] = useState<
    {
      diverId: string;
      isNewDiver: boolean;
    }[]
  >([]);
  const additionalExistingDiversIds = useMemo(
    () => additionalDivers.filter((x) => !x.isNewDiver).map((x) => x.diverId),
    [additionalDivers],
  );

  const [additionalBookings, setAdditionalBookings] = useState<
    {
      bookingId: string;
      // NOTE: pour ajouter des résas existantes, il faudra faire comme pour les plongeurs: additionalExistingDiversIds
      isNewBooking: boolean; // toujours des nouvelles résas, pour le moment
    }[]
  >([]);

  const query: UseQueryResult<ClubDiverBookingBase, unknown> = useQuery({
    queryKey: [
      '/pro/booking/diver-booking-base',
      { mainDiverId, focusDiveSessionReference, additionalExistingDiversIds },
    ],
    queryFn: async () => {
      const results = await apiClient
        .post<ClubDiverBookingBase>(
          `/pro/booking/diver-booking-base/${mainDiverId}`,
          {
            authenticate: true,
            json: {
              mainDiverId,
              focusDiveSessionReference,
              additionalExistingDiversIds,
            },
          },
        )
        .toPromise();
      return results;
    },
    gcTime: 0, // PAS DE CACHE, sino ça créé des problèmes (doublons, donnée manquante...) 1000 * 60 * 60 * 24 * 2, // on garde ça en cache 2 jours
    staleTime: 0, // mais au chargement, on rafraichi systématiquement les données
    refetchOnWindowFocus: true, // ainsi que si la fenêtre reprend le focus
    refetchInterval: 2 * 60 * 1000, // et toutes les 2 minutes
    retry: 60, // en cas d'erreur, on ré-essaie 60 fois, chaque 2 secondes
    retryDelay: 1000 * 2,
  });
  const { isLoading: loadingInProgress, data } = query;
  const addDiversIds = useCallback(
    (
      newDivers: {
        diverId: string;
        isNewDiver: boolean;
      }[],
    ) => {
      // permet de prendre en compte les plongeurs ajoutés à la résa (nouveaux plongeurs ou existants)
      if (newDivers?.length > 0) {
        const idsToAdd = newDivers.filter(
          (newDiver) =>
            additionalDivers.find((x) => x.diverId === newDiver.diverId) ===
            undefined,
        );
        if (idsToAdd.length) {
          setAdditionalDivers(additionalDivers.concat(idsToAdd));
        }
        return idsToAdd;
      }

      return [];
    },
    [additionalDivers],
  );

  const markNewDiversAsPersisted = useCallback(() => {
    setAdditionalDivers(
      additionalDivers.map((x) => ({ ...x, isNewDiver: false })),
    );

    return [];
  }, [additionalDivers]);

  const linkedData: DiverBookingPageClubDiverLinkedData = useMemo(() => {
    const d = data?.linkedData;
    if (!d) {
      return;
    }
    const bookingIdsActive = arrayBuilder.filterDuplicated(
      d?.bookingActiveIds.concat(
        additionalBookings
          ?.filter((x) => x.isNewBooking)
          .map((x) => x.bookingId),
      ),
    );
    const bookingIdsAll = d?.bookingActiveIds.concat(d?.bookingArchivedIds);
    const diverIdsActive = arrayBuilder.filterDuplicated(
      d?.diverIdsActiveLink.concat(additionalDivers.map((x) => x.diverId)),
    );
    const diverIdsAll = arrayBuilder.filterDuplicated(
      diverIdsActive.concat(d?.diverIdsObsoleteLink),
    );
    const linkedData: DiverBookingPageClubDiverLinkedData = {
      ...d,
      // bookingAllIds: bookingIdsAll,
      bookingLoadedIds: bookingIdsAll,
      bookingVisibleAndPersistedIds: includeArchivedBookings
        ? bookingIdsAll
        : bookingIdsActive,
      diverIdsBookingLoaded: includeArchivedBookings
        ? diverIdsAll
        : diverIdsActive,
      diverIdsAll,
      includeArchivedPurchases,
    };

    return linkedData;
  }, [
    additionalBookings,
    additionalDivers,
    data?.linkedData,
    includeArchivedBookings,
    includeArchivedPurchases,
  ]);

  return {
    linkedData,
    mainDiverFull: data?.mainDiverFull,
    focusDiveSession: data?.focusDiveSession,
    addDiversIds,
    markNewDiversAsPersisted,
    refetchLinkedData: query.refetch,
  };
}
