/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState } from 'react';
import { BookingGroupAndJourneyEditDialogInputState } from 'src/business/club/modules/club-diver-participant/pages/CreateBookingPages';
import { BookingCustomerConfigEditDialogInputState } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingCustomerConfigEditDialog';
import { BookingMassiveEditorDialogInputState } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingMassiveEditorDialog';
import { ClubDiverSelectorDialogInputState } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/ClubDiverSelectorDialog/model';
import {
  BookingParticipantEditorDialogState,
  BookingParticipantViewDialogInputState,
  DiveSessionEditorDialogInputState,
  DiverPurchaseOtherEditorDialogState,
  DiverPurchasePaymentEditorDialogState,
  DiverPurchasePlanEditorDialogState,
  DiverPurchaseTrainingEditorDialogState,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { ClubEventDialogInputState } from 'src/business/club/modules/club-events/ClubEventDialog/model';
import { ClubPlanningSessionDialogState } from 'src/business/club/modules/club-planning-light/club-planning-session-dialog';
import { ClubPlanningSessionSelectorDialogState } from 'src/business/club/modules/club-planning-light/club-planning-session-selector-dialog/ClubPlanningSessionSelectorDialog';

/**
 *
 * Dialogs de l'espace Club (ANCIEN PATTERN)
 *
 * TODO: migrer progressivement vers useClubDialogs
 */
export function useClubDialogsOld({
  onCloseSessionEditorDialog,
}: {
  onCloseSessionEditorDialog?: () => void;
} = {}) {
  const [trainingEditorDialogState, setTrainingEditorDialogState] =
    useState<DiverPurchaseTrainingEditorDialogState>({
      isOpen: false,
    });

  const [sessionEditorDialogState, setSessionEditorDialogStateInner] =
    useState<DiveSessionEditorDialogInputState>({
      isOpen: false,
    });

  const setSessionEditorDialogState = useCallback(
    (s: DiveSessionEditorDialogInputState) => {
      setSessionEditorDialogStateInner(s);
      onCloseSessionEditorDialog && onCloseSessionEditorDialog();
    },
    [onCloseSessionEditorDialog],
  );

  const [clubEventDialogState, setClubEventDialogState] =
    useState<ClubEventDialogInputState>({
      isOpen: false,
    });

  const [bookingParticipantViewState, setBookingParticipantViewState] =
    useState<BookingParticipantViewDialogInputState>({
      isOpen: false,
    });

  const [planEditorDialogState, setPlanEditorDialogState] =
    useState<DiverPurchasePlanEditorDialogState>({
      isOpen: false,
    });

  const [purchaseOtherEditorDialogState, setPurchaseOtherEditorDialogState] =
    useState<DiverPurchaseOtherEditorDialogState>({
      isOpen: false,
    });

  const [paymentEditorDialogState, setPaymentEditorDialogState] =
    useState<DiverPurchasePaymentEditorDialogState>({
      isOpen: false,
    });
  const [bookingParticipantEditorState, setBookingParticipantEditorState] =
    useState<BookingParticipantEditorDialogState>({
      isOpen: false,
    });
  const [sessionDialogState, setSessionDialogState] =
    useState<ClubPlanningSessionDialogState>({
      isOpen: false,
    });

  const [diverSelectorDialogState, setDiverSelectorDialogState] =
    useState<ClubDiverSelectorDialogInputState>({
      isOpen: false,
    });

  const [
    bookingGroupAndJourneyEditDialogState,
    setBookingGroupAndJourneyEditDialogState,
  ] = useState<BookingGroupAndJourneyEditDialogInputState>({
    isOpen: false,
  });
  const [
    bookingCustomerConfigEditDialogState,
    setBookingCustomerConfigEditDialogState,
  ] = useState<BookingCustomerConfigEditDialogInputState>({
    isOpen: false,
  });
  const [bookingMassiveEditorDialogState, setBookingMassiveEditorDialogState] =
    useState<BookingMassiveEditorDialogInputState>({
      isOpen: false,
    });

  const [
    clubPlanningSessionSelectorState,
    setClubPlanningSessionSelectorState,
  ] = useState<ClubPlanningSessionSelectorDialogState>({
    isOpen: false,
    mode: 'multiple',
  });
  const isAnyRealDialogOpen = bookingParticipantViewState.isOpen;

  const isAnyFakeDialogOpen =
    clubPlanningSessionSelectorState.isOpen ||
    diverSelectorDialogState.isOpen ||
    bookingParticipantEditorState.isOpen ||
    paymentEditorDialogState.isOpen ||
    planEditorDialogState.isOpen ||
    purchaseOtherEditorDialogState.isOpen ||
    sessionDialogState.isOpen ||
    trainingEditorDialogState.isOpen ||
    bookingGroupAndJourneyEditDialogState.isOpen ||
    bookingMassiveEditorDialogState.isOpen ||
    bookingCustomerConfigEditDialogState.isOpen ||
    sessionEditorDialogState.isOpen ||
    clubEventDialogState.isOpen;

  return {
    clubPlanningSessionSelectorState,
    diverSelectorDialogState,
    isAnyFakeDialogOpen,
    isAnyRealDialogOpen,
    bookingParticipantEditorState,
    paymentEditorDialogState,
    planEditorDialogState,
    purchaseOtherEditorDialogState,
    setPurchaseOtherEditorDialogState,
    sessionDialogState,
    setClubPlanningSessionSelectorState,
    setDiverSelectorDialogState,
    setBookingParticipantEditorState,
    setPaymentEditorDialogState,
    setPlanEditorDialogState,
    setSessionDialogState,
    setTrainingEditorDialogState,
    trainingEditorDialogState,
    sessionEditorDialogState,
    setSessionEditorDialogState,
    bookingParticipantViewState,
    setBookingParticipantViewState,
    bookingGroupAndJourneyEditDialogState,
    setBookingGroupAndJourneyEditDialogState,
    bookingMassiveEditorDialogState,
    setBookingMassiveEditorDialogState,
    bookingCustomerConfigEditDialogState,
    setBookingCustomerConfigEditDialogState,
    clubEventDialogState,
    setClubEventDialogState,
  };
}
