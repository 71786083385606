/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ExportDailyConfigContent,
  TEMPLATE_EXPORT_SHEET_DEFAULT_ITEMS,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppFormControl } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { DailyExportConfigFormModel } from '../../_model';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';
import { DailyExportConfigOptionsPanelSheetContentItem } from './DailyExportConfigOptionsPanelSheetContentItem';

const SHEET_TEMPLATES_CONTENT_OPTIONS: (ValueLabel<number> & {
  item: ExportDailyConfigContent;
})[] = TEMPLATE_EXPORT_SHEET_DEFAULT_ITEMS;
// [
//   TEMPLATE_EXPORT_SHEET_RESUME,
//   TEMPLATE_EXPORT_SHEET_EQUIPMENT,
//   TEMPLATE_EXPORT_SHEET_DIVES,
// ].reduce(
//   (acc, template, templateIndex) => {
//     const options = template.content.items.map((item, i) => ({
//       value: acc.length + i + 1,
//       label: `${templateIndex} - ${item.id}`,
//       item,
//     }));

//     return acc.concat(options);
//   },
//   [] as (ValueLabel<number> & {
//     item: ExportDailyConfigContent;
//   })[],
// );

export const DailyExportConfigOptionsPanelSheetContent = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    getValues,
  } = form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}` as const;

  const contentItemsFieldArray = useFieldArray({
    control,
    name: `${baseFormName}.content.items`,
    keyName: '_ref',
  });

  const [modelAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - modelAnimations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      contentItem: FieldArrayWithId<
        DailyExportConfigFormModel,
        `dailyResumes.${number}.exportConfig.sheets.${number}.content.items`,
        'id'
      >,
      contentItemIndex: number,
      action: string,
    ) => {
      const from = contentItemIndex;
      const update = new Date();
      switch (action) {
        case 'move-down': {
          contentItemsFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          contentItemsFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'delete': {
          setModelAnimations({ update, remove: from });
          contentItemsFieldArray.remove(from);
          // setTimeout(() => contentItemsFieldArray.remove(from), 100);
          break;
        }
      }
    },
    [contentItemsFieldArray],
  );

  return (
    <div className={clsx('grid gap-4', className)}>
      <div className="w-full flex justify-between gap-x-8 gap-y-2 flex-wrap text-base text-app-primary font-medium uppercase">
        <div className="flex-grow">Contenu</div>
      </div>
      <div className="grid gap-4">
        {contentItemsFieldArray.fields.map((contentItem, contentItemIndex) => {
          return (
            <DailyExportConfigOptionsPanelSheetContentItem
              key={contentItem.id}
              localState={localState}
              dailyResumeIndex={dailyResumeIndex}
              sheetIndex={sheetIndex}
              contentItemIndex={contentItemIndex}
              fieldArray={contentItemsFieldArray}
              className={clsx(
                isRecentAnimationUpdate &&
                  modelAnimations?.up === contentItemIndex &&
                  'animate-move-up',
                isRecentAnimationUpdate &&
                  modelAnimations?.down === contentItemIndex &&
                  'animate-move-down',
                isRecentAnimationUpdate &&
                  modelAnimations?.clone === contentItemIndex &&
                  'animate-create',
                isRecentAnimationUpdate &&
                  modelAnimations?.remove === contentItemIndex &&
                  'animate-remove',
              )}
              onClick={(action) =>
                onClick(contentItem, contentItemIndex, action)
              }
            />
          );
        })}
      </div>
      {SHEET_TEMPLATES_CONTENT_OPTIONS.length > 0 && (
        <div className="my-4 w-60">
          <AppFormControl
            label="Ajouter un élément"
            renderComponent={() => (
              <div className="my-2">
                <AppSingleSelect2HeadlessUI
                  value={0}
                  required={false}
                  options={SHEET_TEMPLATES_CONTENT_OPTIONS}
                  theme={'tailwind'}
                  disableClearButton={true}
                  onChange={(value) => {
                    if (value) {
                      const option = SHEET_TEMPLATES_CONTENT_OPTIONS.find(
                        (x) => x.value === value,
                      );
                      contentItemsFieldArray.append(option.item);
                    }
                  }}
                />
              </div>
            )}
          />
          {/* <AppButton
                size="normal"
                icon={AppHeroIcons.actionAdd}
                color="primary-outline-light"
                onClick={() => {
                  sheetsFieldArray.append({
                    ref: '',
                    label: '',
                  } as any);
                }}
              >
                Ajouter
              </AppButton> */}
        </div>
      )}
    </div>
  );
};
