/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { StaffRoleBadge } from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components/DiveSessionStaffMembersTable/StaffRoleBadge';
import { ClubPlanningStaffWeekStaffPresenceLocalState } from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';

export const ClubPlanningStaffWeekStaffPresenceFooterLegend = ({
  localState,
  className,
}: {
  localState: ClubPlanningStaffWeekStaffPresenceLocalState;
  className?: string;
}) => {
  const { data, actions, criteria } = localState;

  return (
    <AppMessageLight className={className} type="info" hideIcon={true}>
      <div className="grid gap-4">
        <h3 className="font-bold text-sm text-gray-600 uppercase">
          <AppHeroIcons.info className="inline-flex w-5 h-5 mr-2 text-blue-400" />
          Légende
        </h3>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-4 gap-y-6 items-start">
          <div className="grid gap-2">
            <div className="w-40 flex gap-1">
              <AppInputBooleanSwitch
                theme={'success'}
                value={true}
                disabled={true}
                label={
                  <div className="hidden md:inline text-app-xxs xl:text-xs text-gray-600">
                    {'Dispo'}
                  </div>
                }
              />
              {criteria.displayRoles && (
                <>
                  <StaffRoleBadge
                    className="px-0.5 md:px-1 text-xs "
                    role={{
                      enabled: true,
                      label: 'SS',
                      staffRoleRef: 'surface-security',
                      active: false,
                      assigned: false,
                    }}
                  />
                  <StaffRoleBadge
                    className="px-0.5 md:px-1 text-xs "
                    role={{
                      enabled: true,
                      label: 'DP',
                      staffRoleRef: 'diving-director',
                      active: false,
                      assigned: false,
                    }}
                  />
                  <StaffRoleBadge
                    className="px-0.5 md:px-1 text-xs "
                    role={{
                      enabled: true,
                      label: 'GP',
                      staffRoleRef: 'scuba-diving-instructor',
                      active: false,
                      assigned: false,
                    }}
                  />
                </>
              )}
            </div>

            <div className="italic text-app-green">Moniteur dispo</div>
          </div>
          <div className="col-span-2 md:col-span-1 grid gap-2">
            <div className="w-40">
              <AppInputBooleanSwitch
                theme={'success'}
                value={false}
                disabled={true}
                label={
                  <div className="hidden md:inline text-app-xxs xl:text-xs text-gray-400">
                    Absent
                  </div>
                }
              />
            </div>
            <div className="italic text-gray-600">Moniteur absent</div>
          </div>
          <div className="col-span-2 md:col-span-1 grid gap-2">
            <div className="">
              <div className={'text-sm text-app-primary flex gap-2'}>
                <span className="border border-gray-300 bg-gray-50 text-gray-600 px-1">
                  <span className="font-medium">8</span>
                  <span className="text-xs">:00</span>
                </span>
                <span className="border border-gray-300 bg-gray-50 text-gray-600 px-1">
                  <span className="font-medium">14</span>
                  <span className="text-xs">:00</span>
                </span>
              </div>
            </div>
            <div className="italic text-app-blue">Moniteur à assigner</div>
          </div>
          <div className="col-span-2 md:col-span-1 grid gap-2">
            <div className="">
              <div className={'text-sm text-app-primary flex gap-2'}>
                <span className="bg-blue-400 text-white px-1">
                  <span className="font-medium">8</span>
                  <span className="text-xs">:00</span>
                </span>
                <span className="bg-blue-400 text-white px-1">
                  <span className="font-medium">14</span>
                  <span className="text-xs">:00</span>
                </span>
              </div>
            </div>
            <div className="italic text-app-blue">Moniteur assigné</div>
          </div>
          <div className="col-span-2 md:col-span-1 grid gap-2">
            <div className="">
              <div className={'text-sm text-app-primary flex gap-2'}>
                <span className="bg-blue-700 text-white px-1">
                  <span className="font-medium">8</span>
                  <span className="text-xs">:00</span>
                </span>
                <span className="bg-blue-700 text-white px-1">
                  <span className="font-medium">14</span>
                  <span className="text-xs">:00</span>
                </span>
              </div>
            </div>
            <div className="italic text-app-blue">Assigné à une palanquée</div>
          </div>
          <div className="grid gap-2">
            <div>
              <StaffRoleBadge
                className="px-0.5 md:px-1 text-xs "
                role={{
                  enabled: true,
                  label: '3 DP',
                  staffRoleRef: 'diving-director',
                  active: true,
                  assigned: true,
                }}
              />
            </div>

            <div className="italic text-gray-600">3 DP présents</div>
          </div>
          <div className="grid gap-2">
            <div>
              <StaffRoleBadge
                className="px-0.5 md:px-1 text-xs "
                role={{
                  enabled: true,
                  label: '5 GP',
                  staffRoleRef: 'scuba-diving-instructor',
                  active: true,
                  assigned: true,
                }}
              />
            </div>
            <div className="italic text-gray-600">5 moniteurs présents</div>
          </div>
        </div>
        <div className="grid gap-2">
          <p>
            <b>PRESENCE:</b> indiquer les présences et absences quotidiennes de
            chaque moniteur
          </p>
          <p>
            <span>Note:</span> un moniteur assigné à une session (ayant un rôle
            sur la session) ne peut être marqué comme absent ce jour-là.
          </p>
        </div>
      </div>
    </AppMessageLight>
  );
};
