/* eslint-disable @typescript-eslint/no-unused-vars */
import { Club } from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { map } from 'rxjs/operators';
import { useGlobalClasses } from 'src/AppTheme';
import { adminClubsRepository } from 'src/business/admin/data';
import { adminMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useLoadable, useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButtonsBar,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import useRouter from 'use-react-router';
import {
  AdminClubFormModel,
  AdminCompanyEditForm,
} from './AdminCompanyEditForm';

export type AdminCompanyEditPageContext = {
  mode: 'edit';
};

export const AdminCompanyEditPage = ({
  navigationContext,
}: {
  navigationContext: AdminCompanyEditPageContext;
}) => {
  const { match } =
    useRouter<{
      clubReference: string;
    }>();

  const clubReference = match.params.clubReference;

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const { _error, _loaded, club } = useLoadable({
    debugName: 'AdminCompanyEditPage',
    initialValueFromLoadSnapshot: true,
    load: () =>
      adminClubsRepository
        .getOne({
          criteria: { reference: clubReference },
        })
        .pipe(map((club) => ({ club }))),
  });

  const form = useForm<AdminClubFormModel>({
    defaultValues: {},
    mode: 'onChange',
  });

  const { handleSubmit } = form;

  useEffect(() => {
    if (club) {
      form.reset(club);
    }
  }, [club, form]);

  const back = useCallback(() => redirectTo('/admin/companies'), [redirectTo]);

  useMemo(() => {
    if (_error) {
      appLogger.warn('Error loading AdminCompanyEditPage: redirect');
      back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_error]);

  const submitForm = useCallback(() => {
    handleSubmit(async (formValue: AdminClubFormModel, event) => {
      const initialValue = club;

      const finalValue: Club = {
        ...initialValue,
        name: formValue.name,
        comment: formValue.comment,
        // reference: formValue.reference, désactivé, car ça doit casser des choses!
        type: formValue.type,
        webSiteUrl: formValue.webSiteUrl,
        facebookPageUrl: formValue.facebookPageUrl,
        country: formValue.country,
        city: formValue.city,
        offer: formValue.offer,
      };
      const patchOperations = jsonPatcher.compareObjects(
        initialValue,
        finalValue,
        {
          attributesToReplaceFully: ['offer'],
          replaceDeleteByNullValue: true,
        },
      );
      if (patchOperations.length) {
        await adminMassiveUpdatorClient.updateMany({
          updatedClubs: [
            {
              pk: initialValue._id,
              patchOperations,
            },
          ],
        });
      }
      back();
    })();
  }, [back, club, handleSubmit]);

  return !_loaded ? null : (
    <AppPageContainerWithFixedBars
      className={'bg-white'}
      footerBar={() => (
        <AppButtonsBar hasChanges={form.formState.isDirty}>
          <>
            <Button
              startIcon={<AppIconsMaterial.cancel />}
              variant="outlined"
              tabIndex={500}
              className={globalClasses.buttonCancel}
              onClick={() => back()}
            >
              Annuler
            </Button>
            <Button
              startIcon={<AppIconsMaterial.save />}
              variant="contained"
              className={globalClasses.buttonSave}
              onClick={() => submitForm()}
            >
              Enregistrer
            </Button>
          </>
        </AppButtonsBar>
      )}
    >
      <AppPageContentContainer className="bg-white app-px-content">
        <div className={globalClasses.pageBlock}>
          <AdminCompanyEditForm form={form} />
        </div>
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
