import { DiveMode } from '@mabadive/app-common-model';
import {
  diveModeAnalyser,
  diveModeFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  ParticipantEquipment,
  ParticipantGaz,
  ParticipantRebreather,
  ParticipantTargetDeep,
  ParticipantUnderwaterScooter,
} from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant';
import { useDiveModes } from 'src/pages/_components/options';
import { useClubSettings } from '../../../../../../../data/hooks';
import { PRO_BookingMemberFull } from '../../../models';

export function useBookingMassiveEditorSessionsTableMemberDiveModeOptions({
  bookingMemberFull,
}: {
  bookingMemberFull: PRO_BookingMemberFull;
}) {
  const diver = bookingMemberFull?.diver;
  const defaultDiveConfig = diver?.defaultDiveConfig;
  const diveModes = useDiveModes({
    currentDiveMode: undefined,
  });
  const clubSettings = useClubSettings();
  const diveModesOptions: ValueLabel<DiveMode, React.ReactNode>[] = useMemo(
    () =>
      diveModes.map((diveMode) => {
        const isScubaDivingEquipmentRequired =
          diveModeAnalyser.hasScubaDivingEquipments(diveMode);

        return {
          value: diveMode,
          label: (
            <div className="w-full flex gap-1 justify-between">
              <div>
                {diveModeFormatter.formatDiveMode(diveMode, {
                  format: 'short-ref-label',
                  diveModesConf: clubSettings?.ui?.diveMode,
                })}
              </div>
              <div className="flex-grow justify-end w-full flex gap-1">
                {isScubaDivingEquipmentRequired && (
                  <>
                    {defaultDiveConfig?.targetDeep > 0 && (
                      <ParticipantTargetDeep
                        targetDeep={defaultDiveConfig?.targetDeep}
                        mode="default"
                      />
                    )}
                    {defaultDiveConfig?.gaz && (
                      <ParticipantGaz
                        className={''}
                        gaz={defaultDiveConfig?.gaz}
                        showAir={true}
                        showDetails={true}
                        showGazQuantity={true}
                      />
                    )}
                    {defaultDiveConfig?.equipment?.rebreather && (
                      <ParticipantRebreather
                        rebreather={defaultDiveConfig?.equipment?.rebreather}
                      />
                    )}
                    {defaultDiveConfig?.equipment?.underwaterScooter && (
                      <ParticipantUnderwaterScooter
                        underwaterScooter={
                          defaultDiveConfig?.equipment?.underwaterScooter
                        }
                      />
                    )}
                    {isScubaDivingEquipmentRequired &&
                      defaultDiveConfig?.equipment && (
                        <ParticipantEquipment
                          className={''}
                          equipment={defaultDiveConfig?.equipment}
                        />
                      )}
                  </>
                )}
              </div>
            </div>
          ),
        };
      }),
    [
      clubSettings?.ui?.diveMode,
      defaultDiveConfig?.equipment,
      defaultDiveConfig?.gaz,
      defaultDiveConfig?.targetDeep,
      diveModes,
    ],
  );

  return diveModesOptions;
}
