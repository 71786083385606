import { PeriodHoursMinutes } from '@mabadive/app-common-model';
import React from 'react';
import { AppTimeHoursMinutesLabel } from './AppTimeHoursMinutesLabel';

export const AppPeriodHoursMinutesLabel = ({
  period,
  className,
}: {
  period: PeriodHoursMinutes;
  className?: string;
}) => {
  return period.start?.hours >= 0 && period.start?.hours < 24 ? (
    <span className={`inline-block whitespace-nowrap ${className}`}>
      <AppTimeHoursMinutesLabel time={period.start} /> -
      <AppTimeHoursMinutesLabel time={period.end} />
    </span>
  ) : null;
};
