import { DiveCenterResume } from '@mabadive/app-common-model';
import { of } from 'rxjs';
import { catchError, delay, map, retryWhen, take } from 'rxjs/operators';
import {
  LoadableContentPartial,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import {
  currentClubResumeStore,
  currentDiveCenterResumeStore,
  diveCenterResumeGraphqlFetcher,
} from 'src/business/_core/data/store-repository';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useAppSecurityUser } from 'src/business/auth/services';

export function useClubRootLoadableContent() {
  const user = useAppSecurityUser();
  const diveCenterId = user?.diveCenterIdFocus;
  const clubReference = user?.clubReference;

  return useLoadableContent(
    () => {
      if (!diveCenterId) {
        return of({
          contentState: 'none',
          lastActionStatus: 'in-progress',
          content: undefined,
        });
      }
      return diveCenterResumeGraphqlFetcher
        .findOne({
          diveCenterId,
          type: 'subscription',
        })
        .pipe(
          catchError((err) => {
            appLogger.error(
              '[useClubRootLoadableContent] Error refreshing currentDiveCenterResume',
              err,
            );
            throw of(err);
          }),
          retryWhen((errors) => errors.pipe(delay(60000), take(10000))),
          map((diveCenterResume) => {
            if (!diveCenterResume) {
              appLogger.error(
                '[useClubRootLoadableContent] Error loading dive center: does not exists => logout',
              );
              const result: LoadableContentPartial<DiveCenterResume> = {
                contentState: 'none',
                lastActionStatus: 'error',
                content: diveCenterResume,
              };
              return result;
            } else {
              // appLogger.info(
              //   '[useClubRootLoadableContent] diveCenterResume loaded',
              //   diveCenterResume,
              // );
              currentDiveCenterResumeStore.currentDiveCenterResume.set(
                diveCenterResume,
              );
              currentClubResumeStore.currentClubResume.set(
                diveCenterResume.clubResume,
              );
              const result: LoadableContentPartial<DiveCenterResume> = {
                contentState: 'full',
                lastActionStatus: 'success',
                content: diveCenterResume,
              };
              return result;
            }
          }),
        );
    },
    [diveCenterId],
    {
      debugName: 'useClubRootLoadableContent',
      useSnapshot: false,
    },
  );
}

// useLoadable({
//   debugName: 'clubResumeGraphqlFetcher',
//   initialValueFromLoadSnapshot: false,
//   load: () =>
//     clubReference$.pipe(
//       distinctUntilChanged(),
//       filter((x) => !!x),
//       switchMap((clubReference) =>
//         clubResumeGraphqlFetcher.findOne({
//           clubReference,
//           type: 'subscription',
//         }),
//       ),
//       catchError((err) => {
//         appLogger.error('Error refreshing clubResume', err);
//         throw of(err);
//       }),
//       retryWhen((errors) => errors.pipe(delay(60000), take(10000))),
//       tap((clubResume) => {
//         currentClubResumeStore.currentClubResume.set(clubResume);
//       }),
//     ),
// });
