/* eslint-disable @typescript-eslint/no-unused-vars */
import { ProMultiOperationPayload } from '@mabadive/app-common-model';
import { arrayBuilder } from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { DiverBookingPageUpdateState } from '../../models';

export const diverBookingPageClientUpdator = {
  persistChanges,
};

function persistChanges(
  updateState: Partial<DiverBookingPageUpdateState>,
  {
    logContext,
    bookingIdsToClean = [],
  }: { logContext: string; bookingIdsToClean: string[] },
): Promise<void> {
  const payload: ProMultiOperationPayload =
    clubMassiveUpdatorClient.createEmptyPayload({
      logContext,
      bookingIdsToClean,
      actionSteps: updateState.actionSteps,
    });

  const dailyConfigsChanges = updateState.dailyConfigsChanges ?? [];
  const paymentsPackagesDetailsChanges =
    updateState.paymentsPackagesDetailsChanges ?? [];

  if (dailyConfigsChanges.length) {
    payload.diveCenterDailyConfigs = {
      created: dailyConfigsChanges.map((d) => d.created).filter((x) => !!x),
      updated: dailyConfigsChanges.map((d) => d.updated).filter((x) => !!x),
    };
  }
  if (paymentsPackagesDetailsChanges.length) {
    payload.clubPurchasePaymentPackageDetails = {
      created: paymentsPackagesDetailsChanges
        .map((d) => d.created)
        .filter((x) => !!x),
      updated: paymentsPackagesDetailsChanges
        .map((d) => d.updated)
        .filter((x) => !!x),
      deletedIds: paymentsPackagesDetailsChanges
        .filter((x) => x?.deleted)
        .map((d) => d.pk),
    };
  }

  const staffMembersChanges = updateState.staffMembersChanges ?? [];
  payload.newClubStaffMembers = payload.newClubStaffMembers.concat(
    staffMembersChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedClubStaffMembers = payload.updatedClubStaffMembers.concat(
    staffMembersChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedClubStaffMemberIds = payload.deletedClubStaffMemberIds.concat(
    staffMembersChanges.filter((d) => d.deleted).map((x) => x.pk),
  );

  const clubEventsChanges = updateState.clubEventsChanges ?? [];
  payload.newClubEvents = payload.newClubEvents.concat(
    clubEventsChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedClubEvents = payload.updatedClubEvents.concat(
    clubEventsChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedClubEventIds = payload.deletedClubEventIds.concat(
    clubEventsChanges.filter((d) => d.deleted).map((x) => x.pk),
  );

  const bookingCustomerUpdatesChanges =
    updateState.bookingCustomerUpdatesChanges ?? [];
  // NOTE: pas de création ici, car c'est toujours à l'initiative du client
  payload.updatedBookingCustomerUpdates =
    payload.updatedBookingCustomerUpdates.concat(
      bookingCustomerUpdatesChanges.map((d) => d.updated).filter((x) => !!x),
    );
  payload.deletedBookingCustomerUpdatesIds =
    payload.deletedBookingCustomerUpdatesIds.concat(
      bookingCustomerUpdatesChanges.filter((d) => d.deleted).map((x) => x.pk),
    );

  const purchasePackagesChanges = updateState.purchasePackagesChanges ?? [];
  payload.newPurchasePackages = payload.newPurchasePackages.concat(
    purchasePackagesChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedPurchasePackages = payload.updatedPurchasePackages.concat(
    purchasePackagesChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedPurchasePackageIds = payload.deletedPurchasePackageIds.concat(
    purchasePackagesChanges.filter((d) => d.deleted).map((x) => x.pk),
  );

  const purchasePaymentsChanges = updateState.purchasePaymentsChanges ?? [];
  payload.newPurchasePayments = payload.newPurchasePayments.concat(
    purchasePaymentsChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedPurchasePayments = payload.updatedPurchasePayments.concat(
    purchasePaymentsChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedPurchasePaymentIds = payload.deletedPurchasePaymentIds.concat(
    purchasePaymentsChanges.filter((d) => d.deleted).map((x) => x.pk),
  );

  const bookingDepositsChanges = updateState.bookingDepositsChanges ?? [];

  if (bookingDepositsChanges.length) {
    payload.bookingDeposits.created = payload.bookingDeposits.created.concat(
      bookingDepositsChanges.map((d) => d.created).filter((x) => !!x),
    );
    payload.bookingDeposits.updated = payload.bookingDeposits.updated.concat(
      bookingDepositsChanges.map((d) => d.updated).filter((x) => !!x),
    );
    payload.bookingDeposits.deletedIds =
      payload.bookingDeposits.deletedIds.concat(
        bookingDepositsChanges.filter((d) => d.deleted).map((x) => x.pk),
      );

    const impactedDiverIds = bookingDepositsChanges.map(
      (d) => d.original?.diverId,
    );
    payload.bookingDeposits.impactedDiverIds = arrayBuilder
      .filterDuplicated(impactedDiverIds)
      .filter((x) => !!x);
  }
  const docResumesChanges = updateState.docResumesChanges ?? [];

  if (docResumesChanges.length) {
    payload.appDocs = {
      created: docResumesChanges.map((d) => d.created).filter((x) => !!x),
      updated: docResumesChanges.map((d) => d.updated).filter((x) => !!x),
      deletedIds: docResumesChanges.filter((d) => d.deleted).map((x) => x.pk),
    };
  }

  const paymentsBookingDepositsDetailsChanges =
    updateState.paymentsBookingDepositsDetailsChanges ?? [];

  if (paymentsBookingDepositsDetailsChanges.length) {
    payload.clubPurchasePaymentBookingDepositDetails = {
      created: paymentsBookingDepositsDetailsChanges
        .map((d) => d.created)
        .filter((x) => !!x),
      updated: paymentsBookingDepositsDetailsChanges
        .map((d) => d.updated)
        .filter((x) => !!x),
      deletedIds: paymentsBookingDepositsDetailsChanges
        .filter((x) => x?.deleted)
        .map((d) => d.pk),
    };
  }

  const creditNotesChanges = updateState.creditNotesChanges ?? [];
  if (creditNotesChanges.length) {
    if (!payload.creditNotes) {
      payload.creditNotes = {
        created: [],
        deletedIds: [],
        updated: [],
      };
    }
    payload.creditNotes.created = payload.creditNotes.created.concat(
      creditNotesChanges.map((d) => d.created).filter((x) => !!x),
    );
    payload.creditNotes.updated = payload.creditNotes.updated.concat(
      creditNotesChanges.map((d) => d.updated).filter((x) => !!x),
    );
    payload.creditNotes.deletedIds = payload.creditNotes.deletedIds.concat(
      creditNotesChanges.filter((d) => d.deleted).map((x) => x.pk),
    );
  }

  const onlineBookingsChanges = updateState.onlineBookingsChanges ?? [];
  if (onlineBookingsChanges.length) {
    if (!payload.onlineBooking) {
      payload.onlineBooking = {
        created: [],
        deletedIds: [],
        updated: [],
      };
    }
    payload.onlineBooking.created = payload.onlineBooking.created.concat(
      onlineBookingsChanges.map((d) => d.created).filter((x) => !!x),
    );
    payload.onlineBooking.updated = payload.onlineBooking.updated.concat(
      onlineBookingsChanges.map((d) => d.updated).filter((x) => !!x),
    );
    payload.onlineBooking.deletedIds = payload.onlineBooking.deletedIds.concat(
      onlineBookingsChanges.filter((d) => d.deleted).map((x) => x.pk),
    );
  }

  const diversChanges = updateState.diversChanges ?? [];
  payload.newDivers = payload.newDivers.concat(
    diversChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedDivers = payload.updatedDivers.concat(
    diversChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedDiversIds = payload.deletedDiversIds.concat(
    diversChanges.filter((d) => d.deleted).map((x) => x.pk),
  );

  const diveSessionsChanges = updateState.diveSessionsChanges ?? [];
  payload.newDiveSessions = payload.newDiveSessions.concat(
    diveSessionsChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedDiveSessions = payload.updatedDiveSessions.concat(
    diveSessionsChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedDiveSessionsIds = payload.deletedDiveSessionsIds.concat(
    diveSessionsChanges.filter((d) => d.deleted).map((x) => x.pk),
  );

  const diveSessionGroupsChanges = updateState.diveSessionGroupsChanges ?? [];
  payload.newDiveSessionGroups = payload.newDiveSessionGroups.concat(
    diveSessionGroupsChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedDiveSessionGroups = payload.updatedDiveSessionGroups.concat(
    diveSessionGroupsChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedDiveSessionGroupIds =
    payload.deletedDiveSessionGroupIds.concat(
      diveSessionGroupsChanges.filter((d) => d.deleted).map((x) => x.pk),
    );

  const bookingProductsChanges = updateState.bookingProductsChanges ?? [];
  payload.newBookingProducts = payload.newBookingProducts.concat(
    bookingProductsChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedBookingProducts = payload.updatedBookingProducts.concat(
    bookingProductsChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedBookingProductsIds = payload.deletedBookingProductsIds.concat(
    bookingProductsChanges.filter((d) => d.deleted).map((x) => x.pk),
  );

  const bookingsChanges = updateState.bookingsChanges ?? [];
  payload.newBookings = payload.newBookings.concat(
    bookingsChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedBookings = payload.updatedBookings.concat(
    bookingsChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedBookingsIds = payload.deletedBookingsIds.concat(
    bookingsChanges.filter((d) => d.deleted).map((x) => x.pk),
  );

  const bookingMembersChanges = updateState.bookingMembersChanges ?? [];
  payload.newBookingMembers = payload.newBookingMembers.concat(
    bookingMembersChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedBookingMembers = payload.updatedBookingMembers.concat(
    bookingMembersChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedBookingMembersIds = payload.deletedBookingMembersIds.concat(
    bookingMembersChanges.filter((d) => d.deleted).map((x) => x.pk),
  );

  const bookingSessionsChanges = updateState.bookingSessionsChanges ?? [];
  payload.newBookingSessions = payload.newBookingSessions.concat(
    bookingSessionsChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedBookingSessions = payload.updatedBookingSessions.concat(
    bookingSessionsChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedBookingSessionsIds = payload.deletedBookingSessionsIds.concat(
    bookingSessionsChanges.filter((d) => d.deleted).map((x) => x.pk),
  );

  const bookingSessionParticipantsChanges =
    updateState.bookingSessionParticipantsChanges ?? [];
  payload.newBookingSessionParticipants =
    payload.newBookingSessionParticipants.concat(
      bookingSessionParticipantsChanges
        .map((d) => d.created)
        .filter((x) => !!x),
    );
  payload.updatedBookingSessionParticipants =
    payload.updatedBookingSessionParticipants.concat(
      bookingSessionParticipantsChanges
        .map((d) => d.updated)
        .filter((x) => !!x),
    );
  payload.deletedBookingSessionParticipantsIds =
    payload.deletedBookingSessionParticipantsIds.concat(
      bookingSessionParticipantsChanges
        .filter((d) => d.deleted)
        .map((x) => x.pk),
    );

  const clubParticipantsChanges = updateState.clubParticipantsChanges ?? [];
  payload.newClubParticipants = payload.newClubParticipants.concat(
    clubParticipantsChanges.map((d) => d.created).filter((x) => !!x),
  );
  payload.updatedClubParticipants = payload.updatedClubParticipants.concat(
    clubParticipantsChanges.map((d) => d.updated).filter((x) => !!x),
  );
  payload.deletedClubParticipantsIds =
    payload.deletedClubParticipantsIds.concat(
      clubParticipantsChanges.filter((d) => d.deleted).map((x) => x.pk),
    );
  return clubMassiveUpdatorClient.update(payload);
}
