import { ClubProductPackageOfferView } from '@mabadive/app-common-model';
import { productPackageFormatter } from '@mabadive/app-common-services';
import { useCallback, useMemo } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserClubAuthorizations,
} from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubSettingsServicesOffersPricesContextMode } from '../_model';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export function useClubSettingsProductOffersFormOfferMenuItems({
  localState,
  offer,
  contextMode,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
  offer: ClubProductPackageOfferView;
  contextMode: ClubSettingsServicesOffersPricesContextMode;
}) {
  const user = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();

  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const publicSettings = clubResume.clubSettings.publicSettings;

  const onDeleteOffer = useCallback(
    async (offer: ClubProductPackageOfferView) => {
      if (offer) {
        await clubMassiveUpdatorClient.update({
          deletedProductPackageOfferIds: [offer._id],
        });
      }
    },
    [],
  );
  const confirmDeleteOffer = useCallback(() => {
    const productPackageName = productPackageFormatter.formatNameString(
      offer.productPackage,
      {
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );

    confirmDialog
      .confirm({
        title: `Supprimer la prestation "${productPackageName}"`,
        message: 'Êtes-vous sûr de vouloir supprimer cette prestation?',
        type: 'remove',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          onDeleteOffer(offer);
        }
      });
  }, [clubSettings?.ui?.diveMode, offer, onDeleteOffer, publicSettings]);

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    // if (az?.edit.participant.paymentEdit) {
    hamburgerMenuItems.push({
      id: 'edit-offer',
      titleShort: 'Modifier',
      title: 'Modifier',
      // description: 'Modifier les détails du tarif',
      icon: AppHeroIcons.actionEdit,
      onClick: () => {
        localState.actions.openOfferEditDialog({
          mode: 'edit',
          offers: [{ offer }],
        });
      },
    });
    hamburgerMenuItems.push({
      id: 'clone-offer',
      titleShort: 'Dupliquer',
      title: 'Dupliquer',
      // description: 'Dupliquer les détails du tarif',
      icon: AppHeroIcons.actionClone,
      onClick: () => {
        localState.actions.openOfferEditDialog({
          mode: 'create',
          offers: [
            {
              offer: {
                ...offer,
                _id: null,
              },
            },
          ],
        });
      },
    });
    hamburgerMenuItems.push({
      id: 'delete-offer',
      titleShort: 'Supprimer',
      title: 'Supprimer',
      severity: 'danger',
      // description: 'Modifier les détails du tarif',
      icon: AppHeroIcons.actionDelete,
      onClick: () => {
        confirmDeleteOffer();
      },
    });
    // }
    return hamburgerMenuItems;
  }, [confirmDeleteOffer, localState.actions, offer]);

  return hamburgerMenuItems;
}
