/* eslint-disable @typescript-eslint/no-unused-vars */
import { clubDefaultMessageTemplatesBuilder } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';
import {
  APP_FORM_CLASSNAME,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlV2,
  AppSingleSelect2HeadlessUI,
} from '../../../../../../../lib/form';
import { useAppCompanyMessageTemplatesDefaultOptions } from '../../../../../../_components/options/useAppCompanyMessageTemplatesDefaultOptions.type';
import { AppCompanyMessageTemplateEditDialogActionsBar } from './AppCompanyMessageTemplateEditDialogActionsBar';
import { AppCompanyMessageTemplateEditForm } from './form';
import { AppCompanyMessageTemplateParamsLegend } from './form/AppCompanyMessageTemplateParamsLegend';
import {
  AppCompanyMessageTemplateEditDialogState,
  AppCompanyMessageTemplateEditFormModel,
} from './model';
import {
  AppCompanyMessageTemplateEditDialogLocalState,
  useAppCompanyMessageTemplateEditDialogLocalState,
} from './useAppCompanyMessageTemplateEditDialogLocalState.hook';
import { useAppCompanyMessageTemplateEditDialogSubmit } from './useAppCompanyMessageTemplateEditDialogSubmit';

export const AppCompanyMessageTemplateEditDialog: FC<AppCompanyMessageTemplateEditDialogState> =
  (inputState) => {
    const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] =
      useState(false);
    const globalClasses = useGlobalClasses();

    const clubResume = useClubResume();
    const clubSettings = clubResume.clubSettings;
    const clubReference = clubResume?.reference;

    const {
      isOpen,
      initialState,
      onCancel,
      onConfirm,
      openDialog,
      closeDialog,
    } = inputState;

    const { mode, defaultValue } = initialState;

    const localState: AppCompanyMessageTemplateEditDialogLocalState =
      useAppCompanyMessageTemplateEditDialogLocalState({
        initialState,
      });

    const { form } = localState;

    const {
      register,
      handleSubmit,
      watch,
      formState,
      control,
      setValue,
      reset,
    } = form;

    const formValue: AppCompanyMessageTemplateEditFormModel = watch();

    const submitForm = useAppCompanyMessageTemplateEditDialogSubmit({
      handleSubmit,
      localState,
      inputState,
    });

    const hasChanges = form.formState.isDirty;

    const [context] = useWatch({
      control,
      name: ['messageTemplate.context'],
    });

    const { messageTemplatesOptions, messageTemplates } =
      useAppCompanyMessageTemplatesDefaultOptions({
        messageContext: context,
      });

    return !isOpen ? null : (
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-gray-50"
        footerBar={() => (
          <AppCompanyMessageTemplateEditDialogActionsBar
            onCancel={onCancel}
            submitForm={submitForm}
          />
        )}
      >
        <div className="app-p-content grid gap-4">
          <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
            {initialState.mode === 'edit'
              ? 'Modèle de message'
              : 'Nouveau modèle de message'}
          </h3>
          {initialState.mode === 'create' && (
            <>
              <AppFormControlV2
                className="w-full"
                label="Modèle de message"
                helpDescription={
                  'Choisissez un modèle pour votre nouveau message'
                }
                renderComponent={() => (
                  <div className="pt-1">
                    <AppSingleSelect2HeadlessUI
                      theme="tailwind"
                      disableClearButton={true}
                      options={messageTemplatesOptions}
                      onChange={(templateName) => {
                        if (templateName) {
                          const companyTemplateDefault = messageTemplates.find(
                            (x) => x.name === templateName,
                          );
                          if (companyTemplateDefault) {
                            const companyTemplate =
                              clubDefaultMessageTemplatesBuilder.buildMessageTemplateFromDefault(
                                {
                                  templateDef: companyTemplateDefault,
                                  context,
                                  clubReference,
                                },
                              );

                            setValue('messageTemplate', companyTemplate);
                          }
                        }
                      }}
                    />
                  </div>
                )}
              />
            </>
          )}
          <div className={clsx(APP_FORM_CLASSNAME)}>
            <AppCompanyMessageTemplateEditForm
              form={form}
              initialState={initialState}
            />
          </div>

          <AppCompanyMessageTemplateParamsLegend
            context={context}
            recipientType={'personal'}
          />
        </div>
      </AppPageContainerWithFixedBars>
    );
  };
