/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren } from 'react';
import { ClubDialogsState } from 'src/pages/_dialogs';
import { BookingParticipantEditorResult } from '../../../models';
import { BookingParticipantEditorDialog } from '../../BookingParticipantDialog';
import { ClubDiverSelectorDialogSubDialogs } from '../useClubDiverSelectorDialogSubDialogs.hook';

export const ClubDiverSelectorDialogSubDialogsProvider = ({
  dialogs,
  onBookingParticipantEditorConfirm,
  children,
  dialogsState,
}: PropsWithChildren<{
  dialogs: ClubDiverSelectorDialogSubDialogs;
  onBookingParticipantEditorConfirm: (
    result: BookingParticipantEditorResult,
  ) => void;
  dialogsState: ClubDialogsState;
}>) => {
  const {
    isAnyFakeDialogOpen,
    bookingParticipantEditorState,
    setBookingParticipantEditorState,
  } = dialogs;

  return (
    <>
      {bookingParticipantEditorState.isOpen &&
        !dialogsState.isAnyDialogOpen && (
          <BookingParticipantEditorDialog
            dialogsState={dialogsState}
            state={bookingParticipantEditorState}
            onClose={() => setBookingParticipantEditorState({ isOpen: false })}
            onConfirm={onBookingParticipantEditorConfirm}
          />
        )}

      <div className={`h-full w-full ${isAnyFakeDialogOpen ? 'hidden' : ''}`}>
        {children}
      </div>
    </>
  );
};
