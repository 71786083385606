/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingAgencyLocationType,
  BOOKING_AGENCY_LOCATION_TYPES,
} from '@mabadive/app-common-model';
import { bookingAgencyFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useBookingAgencyLocationTypeOptions(): ValueLabel<
  BookingAgencyLocationType,
  string
>[] {
  return useMemo(() => {
    const options: ValueLabel<BookingAgencyLocationType, string>[] =
      BOOKING_AGENCY_LOCATION_TYPES.map((value) => ({
        value,
        label: bookingAgencyFormatter.formatLocationType(value),
      }));

    return options;
  }, []);
}
