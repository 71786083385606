import React, { useCallback } from 'react';
import { DiverPurchaseOtherEditorDialogState } from '../../models';

export function useOpenCreatePurchaseOtherDialog({
  setPurchaseOtherEditorDialogState,
}: {
  setPurchaseOtherEditorDialogState: React.Dispatch<
    React.SetStateAction<DiverPurchaseOtherEditorDialogState>
  >;
}) {
  return useCallback(
    ({
      diver,
      createContext,
      sessionsBillingResumes,
      aggregatedData,
    }: Pick<
      DiverPurchaseOtherEditorDialogState,
      'diver' | 'createContext' | 'sessionsBillingResumes' | 'aggregatedData'
    >) =>
      setPurchaseOtherEditorDialogState({
        isOpen: true,
        mode: 'create',
        diver,
        createContext,
        sessionsBillingResumes,
        aggregatedData,
      }),
    [setPurchaseOtherEditorDialogState],
  );
}
