/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubDiverFull } from '@mabadive/app-common-model';
import { arrayBuilder, dataSorter } from '@mabadive/app-common-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiClient } from 'src/_common-browser';
import { clubDiverStore } from 'src/business/_core/data/store-repository';
import { useDebounce } from 'use-debounce';
import {
  ClubDiverSimilarSearchCandidate,
  ClubDiverSimilarSearchEntry,
  ClubDiverSimilarSearchEntryNames,
} from './model';
import {
  clubDiverSimilarSearchEntryBuilder,
  clubDiverSimilarSearchScoreBuilder,
} from './services';

export function useClubDiverSimilarSearchCandidates(
  searchAttributes: {
    firstName: string;
    lastName: string;
  },
  {
    onConfirmSelectedDuplicatedCandidate,
  }: {
    onConfirmSelectedDuplicatedCandidate: (
      candidate: ClubDiverSimilarSearchEntry,
    ) => void;
  },
) {
  const [selectedDuplicatedCandidate, setSelectedDuplicatedCandidate] =
    useState<ClubDiverSimilarSearchEntry>();

  const initialCachedDivers: ClubDiverFull[] =
    clubDiverStore.clubDiverFullCollection.getSnapshot();

  const [cachedDivers, setCachedDivers] =
    useState<ClubDiverFull[]>(initialCachedDivers);

  const formSearchEntry = useMemo(
    () => clubDiverSimilarSearchEntryBuilder.buildEntry(searchAttributes),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchAttributes.firstName, searchAttributes.lastName],
  );

  const searchEntries: ClubDiverSimilarSearchEntry[] = useMemo(
    () => clubDiverSimilarSearchEntryBuilder.buildEntries(cachedDivers),
    [cachedDivers],
  );

  const [formSearchEntryDebounced] = useDebounce(formSearchEntry, 500);

  const addDiversToCache = useCallback(
    (divers: ClubDiverFull[]) => {
      const updatedCachedDivers: ClubDiverFull[] =
        arrayBuilder.filterDuplicated([...cachedDivers, ...divers], {
          compare: (o1, o2) => o1._id === o2._id,
        });
      setCachedDivers(updatedCachedDivers);
    },
    [cachedDivers],
  );

  useEffect(() => {
    if (
      isSearchLongEnought({
        firstName: formSearchEntryDebounced.indexesFull.firstName,
        lastName: formSearchEntryDebounced.indexesFull.lastName,
      })
    ) {
      searchForSimilarDivers({
        formSearchEntryDebounced,
        addDiversToCache,
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formSearchEntryDebounced.indexesFull.firstName,
    formSearchEntryDebounced.indexesFull.lastName,
  ]);

  const candidates: ClubDiverSimilarSearchEntry[] = useMemo(() => {
    // return only searchEntries that match one of formSearchFirstLetters
    return searchEntries.filter((searchEntry) => {
      for (const firstLetter of formSearchEntryDebounced.firstLetters) {
        if (searchEntry.firstLetters.includes(firstLetter)) {
          return true;
        }
      }
      return false;
    });
  }, [formSearchEntryDebounced.firstLetters, searchEntries]);

  const possibleDuplicated: ClubDiverSimilarSearchCandidate[] = useMemo(() => {
    if (
      isSearchLongEnought({
        firstName: formSearchEntryDebounced?.diver?.firstName,
        lastName: formSearchEntryDebounced?.diver?.lastName,
      })
    ) {
      // first, calculate scores
      const candidatesWithScores = candidates.map((candidate) => {
        const score = clubDiverSimilarSearchScoreBuilder.calculateScore({
          candidate,
          formSearchEntry: formSearchEntryDebounced,
        });
        return {
          score,
          candidate,
        };
      });

      const bestCandatesWithScores = candidatesWithScores.filter(
        ({ candidate, score }) => {
          return score >= 50;
        },
      );
      return dataSorter
        .sortMultiple(bestCandatesWithScores, {
          getSortAttributes: ({ score }) => [
            {
              value: score,
              asc: false,
            },
          ],
        })
        .slice(0, 20);
    }
    return [];
  }, [candidates, formSearchEntryDebounced]);

  const [hideCandidates, setHideCandidates] = useState<boolean>(false);

  return {
    possibleDuplicated,
    hideCandidates,
    setHideCandidates,
    selectedDuplicatedCandidate,
    setSelectedDuplicatedCandidate,
    onConfirmSelectedDuplicatedCandidate,
  };
}

function isSearchLongEnought({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) {
  const firstNameLength = firstName?.length ?? 0;
  const lastNameLength = lastName?.length ?? 0;
  if (
    (firstNameLength > 2 && lastNameLength > 2) ||
    firstNameLength + lastNameLength > 8
  ) {
    return true;
  }
}

function searchForSimilarDivers({
  formSearchEntryDebounced,
  addDiversToCache,
}: {
  formSearchEntryDebounced: ClubDiverSimilarSearchEntry<ClubDiverSimilarSearchEntryNames>;
  addDiversToCache: (divers: ClubDiverFull[]) => void;
}) {
  return async () => {
    const searchCriteria = {
      firstName: formSearchEntryDebounced.diver.firstName,
      lastName: formSearchEntryDebounced.diver.lastName,
    };
    const results = await apiClient
      .post<{
        similarDivers: ClubDiverFull[];
      }>('/pro/divers-search/', {
        authenticate: true,
        json: searchCriteria,
      })
      .toPromise();
    addDiversToCache(results.similarDivers);
  };
}

export type ClubDiverSimilarSearchCandidatesState = ReturnType<
  typeof useClubDiverSimilarSearchCandidates
>;
