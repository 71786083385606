import { BookingMember, ClubDiver } from '@mabadive/app-common-model';
import React, { FC, useCallback } from 'react';
import { useAppSecurityUserHasRoles } from 'src/business/auth/services';
import {
  BookingMemberCardAttributeName,
  ClubDiverCardAttribute,
  ClubDiverCardAttributeName,
} from 'src/business/club/modules/club-divers/components/ClubDiverCard';
import {
  AppExpansionPanel,
  AppExpansionPanelProps,
  AppExpansionPanelState,
} from 'src/business/_core/modules/layout/components/AppExpansionPanel';
import { ParticipantFormExpansionPanelId } from '../../../../models';

const panelId = 'panel1';

export const BookingParticipantEditorDialogEditPanel1: FC<{
  diver: ClubDiver;
  bookingMember?: BookingMember; // optional
  diverCardAttributes: (
    | ClubDiverCardAttributeName
    | BookingMemberCardAttributeName
  )[];
  expandedPanel: ParticipantFormExpansionPanelId;
  expandPanelToogle: (panelId: ParticipantFormExpansionPanelId) => void;
  renderDetails: (state?: AppExpansionPanelState) => JSX.Element;
  className?: string;
}> = ({
  diver,
  bookingMember,
  diverCardAttributes,
  expandedPanel,
  expandPanelToogle,
  renderDetails,
  className = '',
}) => {
  const isEditParticipantRole = useAppSecurityUserHasRoles(
    'club-edit-participant-diver',
    'club-edit-participant-booking',
  );
  const isExpanded = expandedPanel === panelId;
  const expandPanel = useCallback(
    () => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      expandPanelToogle(panelId);
    },
    [expandPanelToogle],
  );
  const appExpansionPanelProps: Pick<
    AppExpansionPanelProps,
    'panelId' | 'isExpanded' | 'expandPanel'
  > = {
    panelId,
    isExpanded,
    expandPanel,
  };

  const renderSummaryExtra = () => (
    <div className="w-full flex flex-wrap gap-4">
      {diverCardAttributes.map((attr) => (
        <ClubDiverCardAttribute
          key={attr}
          diver={diver}
          bookingMember={bookingMember}
          attributeName={attr}
        />
      ))}
    </div>
  );
  return (
    <AppExpansionPanel
      isExpandable={isEditParticipantRole}
      className={className}
      {...appExpansionPanelProps}
      renderSummaryTitle={() => (
        <div className="flex justify-between">
          <span>coordonnées</span>
          {isEditParticipantRole && !isExpanded && (
            <span className="hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR MODIFIER
            </span>
          )}
        </div>
      )}
      renderSummaryExtra={renderSummaryExtra}
      renderDetails={renderDetails}
    />
  );
};
