import { DiveCenterDailyConfig } from '@mabadive/app-common-model';
import {
  attributeStoreFactory,
  loadableAttributeCollectionStoreFactory,
} from '@mabadive/app-common-services';
import { getBaseStore } from 'src/business/_core/store/baseStore';
import { DailyConfigCollectionLoadingState } from './DailyConfigCollectionLoadingState.type';

export const DAILY_CONFIG_COLLECTION_INITIAL_LOADING_STATE: DailyConfigCollectionLoadingState =
  {
    loaded: { loadedState: 'none', loadedWeekTimestamps: [] },
    initialLoading: {
      status: 'not-started',
    },
  };

const dailyConfigCollection =
  loadableAttributeCollectionStoreFactory.create<DiveCenterDailyConfig>(
    getBaseStore(),
    'c_daily_configs',
  );
const loadingState =
  attributeStoreFactory.create<DailyConfigCollectionLoadingState>(
    getBaseStore(),
    'c_daily_configs_load',
    DAILY_CONFIG_COLLECTION_INITIAL_LOADING_STATE,
  );

export const dailyConfigStore = {
  dailyConfigCollection,
  loadingState,
  setInitialData,
  resetStore,
  addOneToStore,
  addManyToStore,
};

function setInitialData({
  loadedWeekTimestamps,
  dailyConfigs,
}: {
  loadedWeekTimestamps: number[];
  dailyConfigs: DiveCenterDailyConfig[];
}) {
  dailyConfigCollection.setAll(dailyConfigs);

  loadingState.reduce((state) => ({
    ...state,
    initialLoading: {
      status: 'success',
    },
    loaded: {
      loadedWeekTimestamps,
      loadedState: 'partial',
    },
  }));
}

function resetStore() {
  loadingState.set(DAILY_CONFIG_COLLECTION_INITIAL_LOADING_STATE);
  dailyConfigCollection.removeAll();
}

function addOneToStore(diveSessionResume: DiveCenterDailyConfig) {
  dailyConfigCollection.setOne({
    value: diveSessionResume,
    actionId: 'addOneToStore(upd)',
    identify: (x) => x._id === diveSessionResume._id,
  });
}

function addManyToStore({
  minDateInclusive,
  dailyConfigs,
}: {
  minDateInclusive: Date;
  dailyConfigs: DiveCenterDailyConfig[];
}) {
  // NOTE: contrairement aux sessions, un évenement peut être à cheval sur plusieurs périodes,
  // donc il faut potentiellement le mettre à jour, même si la période n'a pas encore été chargée
  // update
  dailyConfigCollection.addOrUpdateMany({
    values: dailyConfigs,
    actionId: 'addManyToStore(upd)',
    identify: (a, b) => a._id === b._id,
  });

  loadingState.reduce((x) => {
    const loadedWeekTimestamps = [].concat(...x.loaded.loadedWeekTimestamps);
    if (!loadedWeekTimestamps.includes(minDateInclusive.getTime())) {
      loadedWeekTimestamps.push(minDateInclusive.getTime());
    }

    return {
      ...x,
      loaded: {
        ...x.loaded,
        loadedState: 'partial',
        loadedWeekTimestamps,
      },
    };
  });
}
