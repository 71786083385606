/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppInputBooleanRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  ClubPublicSettingsServices,
  ClubSettings,
  FIRST_DIVES,
  FIRST_DIVE_TRAININGS_REFERENCES,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubSettings } from 'src/business/club/data/hooks';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { ClubSettingsServicesFormModel } from '../../../_model';
// const FIRST_DIVE_TRAINING_REFERENCES_OPTIONS =
//   FIRST_DIVE_TRAININGS_REFERENCES.map((reference) => {
//     const option: ValueLabel<FirstDiveTrainingReference> = {
//       label: diveServiceFirstDiveFormatter.formatLabel(reference),
//       value: reference,
//       disabled: reference === 'BPT',
//     };
//     return option;
//   });

const REF_INDEXES = FIRST_DIVE_TRAININGS_REFERENCES.map((ref, i) => {
  const firstDive = FIRST_DIVES.find((x) => x.reference === ref);
  return {
    index: i,
    originalRef: ref,
    labelShort: ref,
    labelLong: firstDive.label,
  };
});

export const ClubSettingsServicesActivitiesFormScuba = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const [firstDivesCustomize, firstDivesCustomTypes] = useWatch({
    control,
    name: [
      'clubSettings.publicSettings.services.firstDives.customize',
      'clubSettings.publicSettings.services.firstDives.customTypes',
    ],
  });

  const clubSettingsInput = useClubSettings();

  const clubSettings = useMemo(() => {
    const services: ClubPublicSettingsServices = clubSettingsInput
      .publicSettings.services ?? {
      firstDives: {
        customize: false,
        customTypes: [],
      },
    };
    const clubSettings: ClubSettings = {
      ...clubSettingsInput,
      publicSettings: {
        ...clubSettingsInput.publicSettings,
        services: {
          ...services,
          firstDives: {
            ...services.firstDives,
            customize: firstDivesCustomize,
            customTypes: firstDivesCustomTypes,
          },
        },
      },
    };

    return clubSettings;
  }, [clubSettingsInput, firstDivesCustomTypes, firstDivesCustomize]);

  return (
    <ClubSettingsSection title="Plongée scaphandre">
      <AppFormControlRHF
        className="mt-2"
        control={control}
        name="clubSettings.firstDive.enabled"
        renderComponent={(props) => (
          <AppInputBooleanRHF
            {...props}
            type="checkbox"
            label={diveModeFormatter.formatDiveMode('first-dive', {
              format: 'long-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
          />
        )}
      />
      <AppFormControlRHF
        control={control}
        name="clubSettings.supervised.enabled"
        renderComponent={(props) => (
          <AppInputBooleanRHF
            {...props}
            type="checkbox"
            label={diveModeFormatter.formatDiveMode('supervised', {
              format: 'long-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
          />
        )}
      />
      <AppFormControlRHF
        control={control}
        name="clubSettings.autonomous.enabled"
        renderComponent={(props) => (
          <AppInputBooleanRHF
            {...props}
            type="checkbox"
            label={diveModeFormatter.formatDiveMode('autonomous', {
              format: 'long-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
          />
        )}
      />
      <AppFormControlRHF
        control={control}
        name="clubSettings.instructor.enabled"
        renderComponent={(props) => (
          <AppInputBooleanRHF
            {...props}
            type="checkbox"
            label={diveModeFormatter.formatDiveMode('instructor', {
              format: 'long-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
          />
        )}
      />
      <AppFormControlRHF
        control={control}
        name="clubSettings.autoSupervised.enabled"
        renderComponent={(props) => (
          <AppInputBooleanRHF
            {...props}
            type="checkbox"
            label={diveModeFormatter.formatDiveMode('autoSupervised', {
              format: 'long-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
          />
        )}
      />
    </ClubSettingsSection>
  );
};
