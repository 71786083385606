import {
  DiveCenterDailyConfig,
  DiveSessionResumeFull,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAppDevice } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUserClubAuthorizations,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { useDailyExportDownloadModal } from 'src/pages/DA-dashboard/_core/components/DailySessionsResumeCard/DailyExportDownloadModal';
import { ClubDialogsState, ClubDialogsStateOld } from 'src/pages/_dialogs';
import { AppHamburgerMenuItem } from '../../_common/ui';
import { securitySheetDownloader } from '../../club-dive-session/pages/SessionResumePage/securitySheetDownloader.service';
import {
  DiveSessionSecuritySheetCardMenuAction,
  DiveSessionSecuritySheetCardMenuActionContext,
  diveSessionStatusUpdateManager,
} from '../components';
/**
 *
 * @param param0 @deprecated remplacer par useDiveSessionMenu
 * @returns
 */
export function useClubPlanningLightPageSessionCardMenuActions({
  dailyConfigs,
  diveSessionResumes,
  dialogs,
  dialogsState,
}: {
  dailyConfigs: DiveCenterDailyConfig[];
  diveSessionResumes: DiveSessionResumeFull[];
  dialogs: ClubDialogsStateOld;
  dialogsState: ClubDialogsState;
}) {
  const redirectTo = useRedirect();

  const dailyExportModal = useDailyExportDownloadModal({
    dialogsState,
  });

  const diveCenterResume = useDiveCenterResume();
  const { settingsPlanning, _id: diveCenterId } = diveCenterResume;

  const hideOldSecuritySheetExport =
    diveCenterResume.privateSettings.exports.hideOldSecuritySheetExport;
  const dailyExportsSettings =
    diveCenterResume.privateSettings.exports.dailyResumes;

  const az = useAppSecurityUserClubAuthorizations();
  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const { isMobile } = useAppDevice();

  return useCallback(
    ({ diveSession }: DiveSessionSecuritySheetCardMenuActionContext) => {
      const menuActions: {
        [key in DiveSessionSecuritySheetCardMenuAction]?: Partial<AppHamburgerMenuItem>;
      } = {
        'create-session-dialog': {
          onClick: () => {
            dialogs.setSessionEditorDialogState({
              isOpen: true,
              mode: 'create-session',
              defaultValue: {
                time: diveSession.time,
              },
              diveSessionResumes,
              dailyConfigs,
            });
          },
        },
        'edit-session-dialog': {
          hidden: diveSession.status === 'deleted',
          onClick: () => {
            dialogs.setSessionEditorDialogState({
              isOpen: true,
              mode: 'edit-session',
              originalSessionFull: diveSession,
              diveSessionResumes,
              dailyConfigs,
            });
          },
        },
        'massive-session-move-divers-dialog': {
          hidden:
            diveSession.status === 'deleted' ||
            diveSession.participants?.length === 0,
          onClick: () => {
            // dialogs.setSessionEditorDialogState({
            //   isOpen: true,
            //   mode: 'edit',
            //   originalSessionFull: diveSession,
            //   originalParticipants: diveSession.participants,
            //   originalGroups: diveSession.groups,
            // });

            // TODO: adapter le modèle pour faire passer les mêmes paramètres que le dialog d'édition de session
            dialogsState.diveSessionEditorMassiveDialog.openDialog({
              actionId: 'move-participants',
              originalSessionFull: diveSession,
              originalParticipants: diveSession.participants,
              originalGroups: diveSession.groups,
              originalBookingSessionParticipants:
                diveSession.bookingSessionParticipants,
            });
          },
        },
        'massive-session-copy-divers-dialog': {
          hidden:
            diveSession.status === 'deleted' ||
            diveSession.participants?.length === 0,
          onClick: () => {
            // TODO: adapter le modèle pour faire passer les mêmes paramètres que le dialog d'édition de session
            dialogsState.diveSessionEditorMassiveDialog.openDialog({
              actionId: 'clone-participants',
              originalSessionFull: diveSession,
              originalParticipants: diveSession.participants,
              originalGroups: diveSession.groups,
              originalBookingSessionParticipants:
                diveSession.bookingSessionParticipants,
            });
          },
        },
        'edit-groups': {
          hidden: diveSession.status === 'deleted' || diveSession.isVirtual,
          onClick: () => {
            // open session groups editor
            dialogs.setSessionEditorDialogState({
              isOpen: true,
              tabId: 'edit-dive-groups',
              mode: 'edit-session',
              originalSessionFull: diveSession,
              diveSessionResumes,
              dailyConfigs,
            });
          },
        },
        'edit-staff-members': {
          hidden: diveSession.status === 'deleted' || diveSession.isVirtual,
          onClick: () => {
            // open session groups editor
            dialogs.setSessionEditorDialogState({
              isOpen: true,
              tabId: 'list-staff-members',
              mode: 'edit-session',
              originalSessionFull: diveSession,
              diveSessionResumes,
              dailyConfigs,
            });
          },
        },
        'duplicate-session-dialog': {
          hidden: diveSession.status === 'deleted',
          onClick: () => {
            dialogs.setSessionEditorDialogState({
              isOpen: true,
              mode: 'clone-session',
              originalSessionFull: diveSession,
              diveSessionResumes,
              dailyConfigs,
            });
          },
        },
        'cancel-session': {
          hidden:
            diveSession.status === 'deleted' ||
            diveSession.status === 'cancelled',
          onClick: () => {
            if (diveSession.isVirtual) {
              diveSessionStatusUpdateManager
                .createFromVirtualSession({
                  diveSessionReference: diveSession.reference,
                  status: 'cancelled',
                  clubReference,
                  diveCenterId,
                  settingsPlanning,
                })
                .catch((err) => {
                  console.log('xxx err:', err);
                });
            } else {
              diveSessionStatusUpdateManager
                .cancelSessionWithConfirmDialog({
                  diveSessionResume: diveSessionResumes.find(
                    (dsr) => dsr.reference === diveSession.reference,
                  ),
                })
                .catch((err) => {
                  console.log('xxx err:', err);
                });
            }
          },
        },
        'delete-session': {
          hidden:
            diveSession.status === 'deleted' ||
            diveSession.participants.length !== 0,
          onClick: async () => {
            if (diveSession.isVirtual) {
              if (
                await confirmDialog.confirmPromise({
                  title: 'Supprimer une session',
                  message:
                    'Êtes-vous sûr de vouloir supprimer définitivement cette session?',
                  type: 'noYesDanger',
                })
              ) {
                diveSessionStatusUpdateManager
                  .createFromVirtualSession({
                    diveSessionReference: diveSession.reference,
                    status: 'deleted',
                    clubReference,
                    diveCenterId,
                    settingsPlanning,
                  })
                  .catch((err) => {
                    console.log('xxx err:', err);
                  });
              }
            } else {
              diveSessionStatusUpdateManager
                .deleteSessionWithConfirmDialog({
                  diveSessionResume: diveSessionResumes.find(
                    (dsr) => dsr.reference === diveSession.reference,
                  ),
                })
                .catch((err) => {
                  console.log('xxx err:', err);
                });
            }
          },
        },
        'split-multiple-session': {
          hidden:
            // (true && // TODO: à implémenter côté serveur
            diveSession.isVirtual ||
            diveSession.status !== 'on' ||
            diveSession.sessionsCount === 1,
          onClick: async () => {
            if (
              await confirmDialog.confirmPromise({
                title: 'Diviser la double-bloc',
                message:
                  'Êtes-vous sûr de vouloir découper cette sortie double-blocs en 2 sorties simple-bloc?',
                type: 'noYesDanger',
              })
            ) {
              diveSessionStatusUpdateManager
                .splitMultiSession({
                  diveSessionId: diveSession._id,
                })
                .catch((err) => {
                  console.log('xxx err:', err);
                });
            }
          },
        },
        'open-session': {
          hidden: diveSession.status === 'on',
          onClick: () => {
            diveSessionStatusUpdateManager
              .openSessionWithConfirmDialog({
                diveSessionResume: diveSessionResumes.find(
                  (dsr) => dsr.reference === diveSession.reference,
                ),
              })
              .catch((err) => {
                console.log('xxx err:', err);
              });
          },
        },
        'close-session': {
          hidden:
            diveSession.status === 'deleted' ||
            diveSession.status === 'off' ||
            diveSession.status === 'cancelled',
          onClick: () => {
            if (diveSession.isVirtual) {
              diveSessionStatusUpdateManager
                .createFromVirtualSession({
                  diveSessionReference: diveSession.reference,
                  status: 'off',
                  clubReference,
                  diveCenterId,
                  settingsPlanning,
                })
                .catch((err) => {
                  console.log('xxx err:', err);
                });
            } else {
              diveSessionStatusUpdateManager
                .closeSession({
                  diveSessionResume: diveSessionResumes.find(
                    (dsr) => dsr.reference === diveSession.reference,
                  ),
                })
                .catch((err) => {
                  console.log('xxx err:', err);
                });
            }
          },
        },
        'print-security-sheet': {
          hidden:
            diveSession.status === 'deleted' ||
            diveSession.isVirtual ||
            hideOldSecuritySheetExport,
          onClick: () => {
            securitySheetDownloader.downloadSecuritySheet({
              diveSessionReference: diveSession.reference,
            });
          },
        },

        'send-session-message': {
          hidden:
            diveSession.status === 'deleted' ||
            !diveSession.participants?.length,
          onClick: () => {
            dialogsState.createMessageToCustomersDialog.openDialog({
              mode: 'create',
              defaultValue: {
                //
              },
              context: 'session',
              sessionContext: {
                participants: diveSession.participants,
                diveSession,
              },
            });
          },
        },
      };

      if (az.download?.dailyResume && dailyExportsSettings?.length > 0) {
        // TODO revoir ce système de menu bien trop complexe, et qui ne permet pas de faire des menus dynamiques

        dailyExportsSettings
          .filter((x) => x.enabled && x.visibility.planningSession)
          .forEach((dailyExportSettings) => {
            const menuItem: Partial<AppHamburgerMenuItem> = {
              id: 'export-daily-resume',
              title: hideOldSecuritySheetExport
                ? dailyExportSettings.label
                : `${dailyExportSettings.label} (BETA)`,
              icon: AppHeroIcons.actionPrint,
              description: dailyExportSettings.description,
              hidden: diveSession.status === 'deleted' || diveSession.isVirtual,
              onClick: () => {
                const dateUTC = dateService.getUTCDateSetTime(
                  diveSession.time,
                  0,
                );
                dailyExportModal.openModal({
                  dailyExportSettings,
                  sessions: [diveSession],
                  dateUTC,
                });
              },
            };

            menuActions['export-daily-resume'] = menuItem;
          });
      }

      return menuActions;
    },
    [
      az.download?.dailyResume,
      clubReference,
      dailyConfigs,
      dailyExportModal,
      dailyExportsSettings,
      dialogs,
      dialogsState.createMessageToCustomersDialog,
      dialogsState.diveSessionEditorMassiveDialog,
      diveCenterId,
      diveSessionResumes,
      hideOldSecuritySheetExport,
      settingsPlanning,
    ],
  );
}
