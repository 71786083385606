import {
  Booking,
  BookingDeposit,
  BookingDepositResume,
  ClubDiver,
  ClubDiverProAttachedData,
  ClubPurchasePackage,
  ClubPurchasePayment,
  ClubPurchasePaymentBookingDepositDetails,
  ClubPurchasePaymentPackageDetails,
  ClubResume,
  DiveSession,
  EditionBookingResume,
  EditionBookingResumeProduct,
  OnlineBooking,
  PurchaseResume,
} from '@mabadive/app-common-model';
import {
  LoadableContent,
  LoadableContentActionStatus,
  LoadableContentState,
} from 'src/business/_core/data/app-loading';
import { PurchasePaymentGraphqlQueryResult } from 'src/business/_core/data/store-repository/ecommerce/model/PurchasePaymentGraphqlQueryResult.type';
import { DiverBookingPageLoadedContent } from '../../models';
import {
  bookingResumeToBookingConvertor,
  diveSessionResumeToDiveSessionConvertor,
} from '../02.update-state/entity-convertors';
import { DiverBookingPageClubDiverLinkedData } from './useDiverBookingPageClubDiverLinkedData.hook';

export const diverBookingPageLoadedContentBuilder = { buildContent };
function buildContent({
  linkedData,
  focusDiveSession,
  clubDiver,
  clubResume,
  diverAttachedData,
  purchaseResumes,
  bookingDepositsResumes,
  purchasePayments,
  bookingResumes,
  bookingProductsResumes,
  clubReference,
  loadableContent,
}: {
  linkedData: DiverBookingPageClubDiverLinkedData;
  focusDiveSession: DiveSession;
  clubDiver: ClubDiver;
  clubResume: ClubResume;
  diverAttachedData: ClubDiverProAttachedData;
  purchaseResumes: PurchaseResume[];
  bookingDepositsResumes: BookingDepositResume[];
  purchasePayments: PurchasePaymentGraphqlQueryResult[];
  bookingResumes: EditionBookingResume[];
  bookingProductsResumes: EditionBookingResumeProduct[];
  clubReference: string;
  loadableContent: {
    contentState?: LoadableContentState;
    lastActionStatus?: LoadableContentActionStatus;
    startDate?: Date;
    endDate?: Date;
    durationInMs?: number;
  };
}): LoadableContent<DiverBookingPageLoadedContent> {
  const content: DiverBookingPageLoadedContent = {
    focus: {
      diveSessionReference: focusDiveSession?.reference,
      diveSession: focusDiveSession,
      clubDiver,
    },
    linkedData,
    clubResume,
    purchaseResumes: [],
    purchasePayments: [],
    paymentsPackagesDetails: [],
    paymentsBookingDepositsDetails: [],
    bookingDeposits: [],
    creditNotes: [],
    purchasePackages: [],
    diveSessions: [],
    clubDivers: [],
    bookingResumes,
    bookings: [],
    bookingProducts: [],
    bookingProductsResumes,
    bookingMembers: [],
    bookingSessions: [],
    bookingSessionParticipants: [],
    clubParticipants: [],
    bookingCustomerUpdates: [],
    messageSchedules: diverAttachedData?.messageSchedules ?? [],
    docResumes: diverAttachedData?.docResumes ?? [],
    onlineBookings: [],
  };

  if (content.bookingResumes) {
    content.bookingResumes.forEach((bookingResume: EditionBookingResume) => {
      const booking: Booking = bookingResumeToBookingConvertor.convert(
        bookingResume,
        { clubReference },
      );
      content.bookingDeposits = content.bookingDeposits.concat(
        bookingResume.bookingDeposits,
      );
      content.onlineBookings = content.onlineBookings.concat(
        // on considère les OnlineBookingResume comme des OnlineBooking
        bookingResume.onlineBookings as unknown as OnlineBooking[],
      );
      content.creditNotes = content.creditNotes.concat(
        bookingResume.creditNotes,
      );
      content.bookings.push(booking);
      bookingResume.bookingSessions.forEach((bookingResumeSession) => {
        const { diveSession: dsr } = bookingResumeSession;
        content.bookingSessions.push({
          ...bookingResumeSession,
          clubReference,
          bookingId: bookingResume._id,
        });
        if (
          content.diveSessions.find((ds) => ds.reference === dsr.reference) ===
          undefined
        ) {
          const diveSession: DiveSession =
            diveSessionResumeToDiveSessionConvertor.convert(dsr, {
              clubReference,
            });
          content.diveSessions.push(diveSession);
        }
      });
      // bookingResume.bookingProducts.forEach((bp) => {
      //   content.bookingProducts.push(bp);
      //   content.bookingSessionParticipants.push(bp.bookingSessionParticipant);
      //   content.clubParticipants.push(
      //     bp.bookingSessionParticipant.diveSessionParticipant,
      //   );

      //   if (
      //     content.bookingProducts.find((ds) => ds._id === bp._id) === undefined
      //   ) {
      //     content.bookingProducts.push(bp);
      //   }
      // });
      bookingResume.bookingResumeMembers.forEach((bookingResumeMember) => {
        const { diver, products_aggregate, ...bookingMember } =
          bookingResumeMember;
        content.bookingMembers.push(bookingMember);

        if (
          content.clubDivers.find((ds) => ds._id === diver._id) === undefined
        ) {
          content.clubDivers.push(diver);
        }
      });

      content.bookingCustomerUpdates = content.bookingCustomerUpdates.concat(
        bookingResume.bookingCustomerUpdates,
      );
    });
  }
  if (content.bookingProductsResumes) {
    content.bookingProductsResumes.forEach(
      (bp: EditionBookingResumeProduct) => {
        const {
          bookingMember,
          bookingSession,
          bookingSessionParticipant: editionBookingResumeSessionParticipant,
          ...bookingProduct
        } = bp;

        content.bookingProducts.push(bookingProduct);
        if (editionBookingResumeSessionParticipant) {
          const { diveSessionParticipant, ...bookingSessionParticipant } =
            editionBookingResumeSessionParticipant;

          content.bookingSessionParticipants.push(bookingSessionParticipant);
          content.clubParticipants.push(diveSessionParticipant);
        }

        if (
          content.bookingProducts.find((ds) => ds._id === bp._id) === undefined
        ) {
          content.bookingProducts.push(bp);
        }
      },
    );
  }
  if (purchaseResumes?.length > 0) {
    purchaseResumes.forEach(({ paymentsPackageDetails, contact, ...attrs }) => {
      const purchasePackage: ClubPurchasePackage = {
        ...attrs,
        clubReference,
        diverId: contact._id,
      };
      content.purchasePackages.push(purchasePackage);
      if (paymentsPackageDetails?.length > 0) {
        // les paymentsPackageDetails sont chargés soit par les paiements, soit par les packages, donc il ne faut pas les ajouter en double
        for (const purchasePackageDetails of paymentsPackageDetails) {
          if (
            content.paymentsPackagesDetails.find(
              (x) => x._id === purchasePackageDetails._id,
            ) === undefined
          ) {
            // not already in cache
            content.paymentsPackagesDetails.push(
              purchasePackageDetails as ClubPurchasePaymentPackageDetails,
            );
          }
        }
      }
    });
  }

  if (bookingDepositsResumes?.length > 0) {
    bookingDepositsResumes.forEach(
      ({ paymentsBookingDepositsDetails, contact, ...attrs }) => {
        const bookingDeposit: BookingDeposit = {
          ...attrs,
          clubReference,
          diverId: contact._id,
        };

        if (paymentsBookingDepositsDetails?.length > 0) {
          // les paymentsBookingDepositsDetails sont chargés soit par les paiements, soit par les bookingDeposits, donc il ne faut pas les ajouter en double
          for (const paymentBookingDepositDetails of paymentsBookingDepositsDetails) {
            if (
              content.paymentsBookingDepositsDetails.find(
                (x) => x._id === paymentBookingDepositDetails._id,
              ) === undefined
            ) {
              // not already in cache
              content.paymentsBookingDepositsDetails.push(
                paymentBookingDepositDetails as ClubPurchasePaymentBookingDepositDetails,
              );
            }
          }
        }
      },
    );
  }

  if (purchasePayments?.length > 0) {
    purchasePayments.forEach(
      ({
        // paymentPackagesDetails,
        // paymentsBookingDepositsDetails,
        ...attrs
      }) => {
        const purchasePayment: ClubPurchasePayment = {
          ...attrs,
          clubReference,
        };
        content.purchasePayments.push(purchasePayment);
        // if (paymentPackagesDetails?.length > 0) {
        //   // les paymentsPackageDetails sont chargés soit par les paiements, soit par les packages, donc il ne faut pas les ajouter en double
        //   for (const purchasePackageDetails of paymentPackagesDetails) {
        //     if (
        //       content.paymentsPackagesDetails.find(
        //         (x) => x._id === purchasePackageDetails._id,
        //       ) === undefined
        //     ) {
        //       // not already in cache
        //       content.paymentsPackagesDetails.push(
        //         purchasePackageDetails as ClubPurchasePaymentPackageDetails,
        //       );
        //     }
        //   }
        // }
        // if (paymentsBookingDepositsDetails?.length > 0) {
        //   // les paymentsBookingDepositsDetails sont chargés soit par les paiements, soit par les bookingDeposits, donc il ne faut pas les ajouter en double
        //   for (const paymentBookingDepositDetails of paymentsBookingDepositsDetails) {
        //     if (
        //       content.paymentsBookingDepositsDetails.find(
        //         (x) => x._id === paymentBookingDepositDetails._id,
        //       ) === undefined
        //     ) {
        //       // not already in cache
        //       content.paymentsBookingDepositsDetails.push(
        //         paymentBookingDepositDetails as ClubPurchasePaymentBookingDepositDetails,
        //       );
        //     }
        //   }
        // }
      },
    );
  }
  if (
    content.focus?.clubDiver?._id &&
    !content.clubDivers.some((d) => d._id === content.focus.clubDiver?._id)
  ) {
    // append focus diver
    content.clubDivers.push(content.focus.clubDiver);
  }
  if (
    focusDiveSession?._id &&
    !content.diveSessions.some((ds) => ds._id === focusDiveSession._id)
  ) {
    // append focus session
    content.diveSessions.push(focusDiveSession);
  }

  const result: LoadableContent<DiverBookingPageLoadedContent> = {
    contentState: loadableContent?.contentState,
    lastActionStatus: loadableContent?.lastActionStatus,
    startDate: loadableContent?.startDate,
    endDate: loadableContent?.endDate,
    durationInMs: loadableContent?.durationInMs,
    content,
  };
  return result;
}
