/* eslint-disable @typescript-eslint/no-unused-vars */
import { diveModeFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  CreateOfferPayload,
  clubSettingsProductOfferBuilder,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export const ClubSettingsServicesViewPanelOffersPricesActionsBar_PMT_ACC = ({
  localState,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
}) => {
  const { data, state, actions } = localState;
  const publicSettings = data.publicSettings;
  const clubReference = data.clubReference;
  const clubSettings = data.clubSettings;

  return (
    <>
      {clubSettings.freeDive?.enabled && (
        <AppButton
          className="whitespace-nowrap"
          color="primary-outline-light"
          size="normal"
          icon={AppHeroIcons.actionAdd}
          onClick={() => {
            const newOffer: CreateOfferPayload =
              clubSettingsProductOfferBuilder.buildNewOffer({
                clubReference,
                diveMode: 'free-dive',
                i: 0,
              });
            actions.openOfferEditDialog({
              mode: 'create',
              offers: [newOffer],
            });
          }}
        >
          {diveModeFormatter.formatDiveMode('free-dive', {
            format: 'short-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
        </AppButton>
      )}
      {clubSettings.observer?.enabled && (
        <AppButton
          className="whitespace-nowrap"
          color="primary-outline-light"
          size="normal"
          icon={AppHeroIcons.actionAdd}
          onClick={() => {
            const newOffer: CreateOfferPayload =
              clubSettingsProductOfferBuilder.buildNewOffer({
                clubReference,
                diveMode: 'observer',
                i: 0,
              });
            actions.openOfferEditDialog({
              mode: 'create',
              offers: [newOffer],
            });
          }}
        >
          {diveModeFormatter.formatDiveMode('observer', {
            format: 'short-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
        </AppButton>
      )}
      {clubSettings.snorkeling?.enabled && (
        <AppButton
          className="whitespace-nowrap"
          color="primary-outline-light"
          size="normal"
          icon={AppHeroIcons.actionAdd}
          onClick={() => {
            const newOffer: CreateOfferPayload =
              clubSettingsProductOfferBuilder.buildNewOffer({
                clubReference,
                diveMode: 'snorkeling',
                i: 0,
              });
            actions.openOfferEditDialog({
              mode: 'create',
              offers: [newOffer],
            });
          }}
        >
          {diveModeFormatter.formatDiveMode('snorkeling', {
            format: 'short-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
        </AppButton>
      )}
      {clubSettings.snorkelingSupervised?.enabled && (
        <AppButton
          className="whitespace-nowrap"
          color="primary-outline-light"
          size="normal"
          icon={AppHeroIcons.actionAdd}
          onClick={() => {
            const newOffer: CreateOfferPayload =
              clubSettingsProductOfferBuilder.buildNewOffer({
                clubReference,
                diveMode: 'snorkelingSupervised',
                i: 0,
              });
            actions.openOfferEditDialog({
              mode: 'create',
              offers: [newOffer],
            });
          }}
        >
          {diveModeFormatter.formatDiveMode('snorkelingSupervised', {
            format: 'short-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
        </AppButton>
      )}
      {clubSettings.watchingTour?.enabled && (
        <AppButton
          className="whitespace-nowrap"
          color="primary-outline-light"
          size="normal"
          icon={AppHeroIcons.actionAdd}
          onClick={() => {
            const newOffer: CreateOfferPayload =
              clubSettingsProductOfferBuilder.buildNewOffer({
                clubReference,
                diveMode: 'watchingTour',
                i: 0,
              });
            actions.openOfferEditDialog({
              mode: 'create',
              offers: [newOffer],
            });
          }}
        >
          {diveModeFormatter.formatDiveMode('watchingTour', {
            format: 'short-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
        </AppButton>
      )}
    </>
  );
};
