import { PRO_BookingResume } from '../../models';
import { proBookingSorter } from './bookingSorter.service';

export const defaultBookingBuilder = {
  getDefaultBookingId,
};
function getDefaultBookingId(
  proBookingResumes: PRO_BookingResume[],
  {
    diveCenterId,
    focusDiverId,
  }: {
    diveCenterId: string;
    focusDiverId: string;
  },
): string {
  const sortedBookings = proBookingSorter.sortProBookingResumes(
    proBookingResumes,
    {
      diveCenterId,
      focusDiverId,
    },
  );
  return sortedBookings?.[0]?.booking?._id;
}
