/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
} from 'src/lib/form';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';

export const DECOP_SessionItemParticipantsCount = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  sessionItemIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  sessionItemIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}.items.${sessionItemIndex}` as const;

  const [isEnabled] = useWatch({
    control,
    name: [`${baseFormName}.enabled`],
  });

  return (
    <div className={clsx('flex gap-2', className)}>
      <AppFormControlRHF_Deprecated
        control={control}
        name={`${baseFormName}.enabled`}
        renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
      />
      <div className="grid gap-2">
        <h4 className="text-left text-base font-medium text-gray-600">
          Total participants
          {isEnabled && (
            <span className="font-normal text-gray-500">
              {': cocher les informations à afficher'}
            </span>
          )}
        </h4>
        {isEnabled && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
            <AppFormControlRHF_Deprecated
              label={'Nombre de clients'}
              control={control}
              name={`${baseFormName}.divers`}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  type="checkbox"
                  label="Afficher"
                  {...props}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              label={'Nombre de moniteurs'}
              control={control}
              name={`${baseFormName}.staff`}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  type="checkbox"
                  label="Afficher"
                  {...props}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              label={'Total'}
              control={control}
              name={`${baseFormName}.total`}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  type="checkbox"
                  label="Afficher"
                  {...props}
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};
