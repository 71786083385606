/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { clubSettingsDiveCenterUrlBuilder } from 'src/pages/SE-settings/SE-01_dive-center/clubSettingsDiveCenterUrlBuilder.service';
import { ClubDialogsState } from '../../../../../../pages/_dialogs';
import {
  AppSimpleMenuButton,
  AppSimpleMenuButtonItemGroup,
} from '../../../_common/ui';
import {
  ClubPlanningDateNavigationBar,
  ClubPlanningDisplayModeToggleButton,
  ClubPlanningTodayButton,
  ClubPlanningViewPeriodToggleButton,
} from '../../../club-planning-light/club-planning-navigation';
import { SetPlanningConfigOptions } from '../../../club-planning-light/hooks';
import { PlanningViewConfig } from '../../_model';

export const ClubPlanningStaffConfigBar = ({
  dialogsState,
  planningConfig,
  setPlanningConfig,
  display,
  disableNavigation,
  className,
}: {
  dialogsState: ClubDialogsState;
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  display?: {
    view?: boolean;
    mode?: boolean;
    actions?: boolean;
  };
  disableNavigation?: boolean;
  className?: string;
}) => {
  const az = useAppSecurityUserClubAuthorizations();

  const actionsMenu: AppSimpleMenuButtonItemGroup[] = useMemo(() => {
    const g1: AppSimpleMenuButtonItemGroup = {
      items: [],
    };
    const groups: AppSimpleMenuButtonItemGroup[] = [g1];
    if (az?.edit?.settings?.general && !disableNavigation) {
      // g1.items.push({
      //   title: 'Nouveau moniteur',
      //   titleShort: 'Nouveau',
      //   icon: AppIconsAction.add,
      //   style: 'primary',
      //   severity: 'info',
      //   // hrefRoute: clubSettingsDiveCenterUrlBuilder.staff.list(),
      //   onClick: () =>
      // NOTE: le onClick ne fonctionne pas avec ce compsant
      //     dialogsState.staffMemberEditDialog.openDialog({
      //       mode: 'create',
      //       defaultValue: { staffMember: {} },
      //     }),
      // });
      g1.items.push({
        title: 'CONFIGURATION',
        titleShort: 'Config',
        icon: AppHeroIcons.staff,
        style: 'primary',
        severity: 'info',
        hrefRoute: clubSettingsDiveCenterUrlBuilder.staff.list(),
      });
    }
    return groups;
  }, [az?.edit?.settings?.general, disableNavigation]);

  return (
    <>
      <div
        className={clsx(
          'relative flex flex-row justify-between items-center py-1',
          className,
        )}
      >
        <div
          className={clsx(
            'absolute top-0 left-0 flex gap-2',
            disableNavigation && 'invisible',
          )}
        >
          <ClubPlanningTodayButton
            className="flex mx-1.5 my-1"
            planningConfig={planningConfig}
            setPlanningConfig={setPlanningConfig}
          />
        </div>

        <ClubPlanningDateNavigationBar
          className={clsx(disableNavigation && 'invisible')}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
        />
      </div>

      <div
        className={clsx(
          'flex items-stretch gap-4 justify-between py-1 px-2',
          className,
        )}
      >
        <div className={'flex flex-row items-stretch gap-4'}>
          {/* si on l'affiche, on n'a l'impression que ça ne marche pas, surtout si on est en vue "par session"
        <ClubPlanningStaffConfigDialog
          localState={{
            planningConfig,
            setPlanningConfig,
          }}
        /> */}
          <div
            className={
              'h-full flex flex-row items-stretch gap-4 sm:gap-8 mg:gap-12'
            }
          >
            {display?.mode && (
              <ClubPlanningDisplayModeToggleButton
                className="h-full flex uppercase text-xs"
                context={'planning-staff-page'}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
              />
            )}
            {display?.view && (
              <ClubPlanningViewPeriodToggleButton
                className={clsx(
                  'h-full flex uppercase text-xs',
                  disableNavigation && 'invisible',
                )}
                context={'planning-staff-page'}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
              />
            )}
          </div>
        </div>
        <AppSimpleMenuButton menu={actionsMenu} />
      </div>
    </>
  );
};
