import {
  DIVE_CERTIFICATIONS,
  GAZ_DIVE_CERTIFICATIONS_REFERENCES,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';

const certificationsToIgnore = (
  GAZ_DIVE_CERTIFICATIONS_REFERENCES as string[]
).concat(['BPT', 'BPT++', 'BPT-XL']);

export const DIVE_CERTIFICATIONS_WITHOUT_BPT_NITROX = dataSorter.sortMultiple(
  DIVE_CERTIFICATIONS.filter(
    (x) => certificationsToIgnore.indexOf(x.reference) === -1,
  ),
  {
    getSortAttributes: (cert) => {
      const org = cert.organization;
      return [
        {
          // sort by organisation
          value: !org
            ? 0
            : org?.reference === 'cmas'
            ? 1
            : org?.reference === 'padi'
            ? 2
            : 3,
        },
        {
          // then by name
          value: cert.reference,
        },
      ];
    },
  },
);
