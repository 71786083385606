import { CLUB_DIVER_FULL_GRAPHQL_RETURN_ATTRIBUTES } from '../../../../divers/graphql/query-builder/CLUB_DIVER_FULL_GRAPHQL_RETURN_ATTRIBUTES.const';

/**
 * @deprecated use ClubDiverFullGql_Company
 */
export const clubDiverAttributesBuilder = {
  buildAttributes,
};
function buildAttributes() {
  return CLUB_DIVER_FULL_GRAPHQL_RETURN_ATTRIBUTES;
}
