/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC } from 'react';

import { ClubSettingsServicesActivitiesForm } from '../tabs/01.activities';
import { ClubSettingsServicesFirstDiveForm } from '../tabs/02.first-dive';
import { ClubSettingsServicesTrainingForm } from '../tabs/03.training/form/ClubSettingsServicesTrainingForm';
import { ClubSettingsServicesExplosForm } from '../tabs/04.explos/form/ClubSettingsServicesExplosForm';
import { ClubSettingsServicesDialogLocalState } from './useClubSettingsServicesDialogLocalState.hook';

export const ClubSettingsServicesDialogForm: FC<{
  localState: ClubSettingsServicesDialogLocalState;
}> = ({ localState }) => {
  const { form, initialState } = localState;
  const { tabId } = initialState;
  return (
    <div className={clsx('app-card app-p-content')}>
      {tabId === 'activities' ? (
        <ClubSettingsServicesActivitiesForm
          form={form}
          initialState={initialState}
        />
      ) : tabId === 'first-dive' ? (
        <ClubSettingsServicesFirstDiveForm
          form={form}
          initialState={initialState}
        />
      ) : tabId === 'training' ? (
        <ClubSettingsServicesTrainingForm
          form={form}
          initialState={initialState}
        />
      ) : tabId === 'explo' ? (
        <ClubSettingsServicesExplosForm
          form={form}
          initialState={initialState}
        />
      ) : null}
    </div>
  );
};
