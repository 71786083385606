/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubUserDeleteOperation,
  ClubUserSendInitPasswordMailOperation,
} from '@mabadive/app-common-model';
import {
  clubProfilesSorter,
  nameFormatter,
} from '@mabadive/app-common-services';
import { Box, Button } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGlobalClasses } from 'src/AppTheme';
import { ApiClientHttpError, apiClient } from 'src/_common-browser';
import {
  appLoader,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppFixedButtonsBar,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIconsMaterial,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { appClipboardCopier } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingCustomerConfigEditDialog/_services';
import useRouter from 'use-react-router';
import { clubSettingsAccountUrlBuilder } from '../../clubSettingsAccountUrlBuilder.service';
import { ClubUserProfilesDescription } from '../ClubSettingsUserAccountsListPage/components';
import { SettingsUserAccount } from '../ClubSettingsUserAccountsListPage/model';
import { clubSettingsUserAccoutsPageGraphqlFetcher } from '../ClubSettingsUserAccountsListPage/services';
import { ClubSettingsUserAccountEditForm } from './ClubSettingsUserAccountEditForm';
import { clubSettingsUserAccountPersister } from './services';

export const ClubSettingsUserAccountEditPage = ({
  navigationContext: { mode },
}: {
  navigationContext: {
    mode: 'create' | 'edit';
  };
}) => {
  const { match, history } =
    useRouter<{
      userAccountId: string;
    }>();

  const params = new URLSearchParams(window.location.search);
  const clubProfileId = params.get('clubProfileId');

  const [operationPending, setOperationPending] = useState(false);
  const [resetPasswordLink, setResetPasswordLink] = useState<string>(undefined);

  const userAccountId = match.params.userAccountId;

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const appSecurityUser = useAppSecurityUser();

  const diveCenterResume = useDiveCenterResume();

  const { content, ...loadableContent } = useLoadableContent(
    () => {
      return appLoader.load(
        clubSettingsUserAccoutsPageGraphqlFetcher.fetchUserAccount({
          clubReference: diveCenterResume.clubReference,
          diveCenterId: diveCenterResume._id,
          userAccountId,
        }),
        {
          type: 'full',
          defaultValue: undefined,
          isSubscription: false,
        },
      );
    },
    [diveCenterResume._id, diveCenterResume.clubReference, userAccountId],
    {
      initialValue: undefined,
      defaultValue: undefined,
      useSnapshot: false,
      debugName: 'useClubSettingsUserAccountsListPageLocalState',
    },
  );
  const initialValue = content?.userAccount;

  const clubProfiles = useMemo(
    () => clubProfilesSorter.sortProfiles(content?.clubProfiles ?? []),
    [content?.clubProfiles],
  );

  const isMyself = useMemo(() => {
    return userAccountId === appSecurityUser.userAccountProfile?._id;
  }, [userAccountId, appSecurityUser.userAccountProfile?._id]);

  const form = useForm<SettingsUserAccount>({
    defaultValues: {
      authUser: {
        clubProfileId,
      },
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    setError,
  } = form;

  useEffect(() => {
    if (initialValue) {
      reset(initialValue);
    }
  }, [reset, initialValue]);

  const back = useCallback(() => {
    return redirectTo(clubSettingsAccountUrlBuilder.userAccounts.list());
  }, [redirectTo]);

  const submitForm = useCallback(() => {
    handleSubmit(async (formValue: SettingsUserAccount, event) => {
      setOperationPending(true);

      try {
        if (mode === 'create') {
          await clubSettingsUserAccountPersister.createUserAccount({
            formValue,
          });
          uiStore.snackbarMessage.set({
            type: 'success',
            content: 'Un e-mail de confirmation a été envoyé à cette adresse.',
          });
        } else {
          await clubSettingsUserAccountPersister.updateUserAccount({
            initialValue,
            formValue,
          });
        }
        back();
      } catch (err) {
        appLogger.error('Unexpecter error persisting user account', err);
        if ((err as ApiClientHttpError).status === 409) {
          appLogger.error(
            'Conflict error while trying to create user account',
            err,
          );
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Un utilisateur existe déjà avec cet identifiant. Veuillez en choisir un autre.',
          });
          setError(
            'authUser.login',
            { message: 'Identifiant déjà utilisée' },
            { shouldFocus: true },
          );
        }
      } finally {
        setOperationPending(false);
      }
    })();
  }, [back, handleSubmit, initialValue, mode, setError]);

  function deleteUser(userAccount: SettingsUserAccount) {
    setOperationPending(true);
    const operation: ClubUserDeleteOperation = {
      id: 'club.users.delete',
      payload: {
        authUserId: userAccount.authUser._id,
      },
    };

    confirmDialog
      .confirm({
        isTailwind: true,
        title: 'Supprimer cet utilisateur',
        message:
          "Il sera définitivement supprimé et n'aura plus accès à l'application. Confirmer?",
        type: 'noYesDanger',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          return apiClient
            .post<any>('/operations', {
              authenticate: true,
              json: {
                operations: [operation],
              },
            })
            .subscribe(
              ({ success }) => {
                uiStore.snackbarMessage.set({
                  type: 'success',
                  content: 'Utilisateur supprimé!',
                });
                setOperationPending(false);
                back();
              },
              (err) => {
                setOperationPending(false);
              },
            );
        } else {
          setOperationPending(false);
        }
      });
  }
  function sendInitPasswordMail(userAccount: SettingsUserAccount) {
    setOperationPending(true);
    const operation: ClubUserSendInitPasswordMailOperation = {
      id: 'club.users.send-init-password-mail',
      payload: {
        authUserId: userAccount.authUser._id,
      },
    };

    return apiClient
      .post<{
        operationResponse: {
          userId: string;
          resetPasswordLink: string;
        };
      }>('/operations/single', {
        authenticate: true,
        json: {
          operation,
        },
      })
      .subscribe(
        ({ operationResponse }) => {
          console.log('xxx operationResponse:', operationResponse);
          uiStore.snackbarMessage.set({
            type: 'success',
            content: 'E-mail de confirmation envoyé!',
          });
          setOperationPending(false);
          setResetPasswordLink(operationResponse.resetPasswordLink);
        },
        (err) => {
          setOperationPending(false);
        },
      );
  }

  return (
    <AppPageContainerWithFixedBars
      className={'bg-gray-50'}
      {...loadableContent}
      footerBar={() => (
        <AppFixedButtonsBar hasChanges={form.formState.isDirty}>
          <>
            <Button
              disabled={operationPending}
              startIcon={<AppIconsMaterial.cancel />}
              variant="outlined"
              tabIndex={500}
              className={globalClasses.buttonCancel}
              onClick={() => back()}
            >
              Annuler
            </Button>
            <Button
              disabled={operationPending}
              startIcon={<AppIconsMaterial.save />}
              variant="contained"
              className={globalClasses.buttonSave}
              onClick={() => submitForm()}
            >
              Enregistrer
            </Button>
          </>
        </AppFixedButtonsBar>
      )}
    >
      <AppBreadcrumbBar
        color={AppIconsSettings.account.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.account.main,
            label: 'Mon compte',
            url: '/club/settings',
          },
          {
            label:
              mode === 'create'
                ? 'NOUVEL UTILISATEUR'
                : `UTILISATEUR "${nameFormatter.formatFullName(initialValue)}"`,
          },
        ]}
      />

      <AppPageContentContainer className={'bg-gray-50 app-px-content'}>
        <ClubSettingsUserAccountEditForm
          className={'app-my-content app-card app-p-content'}
          clubProfiles={clubProfiles}
          form={form}
          isMyself={isMyself}
          mode={mode}
        />

        {resetPasswordLink ? (
          <div className="app-card app-p-content grid gap-2">
            <label
              className="relative text-sm font-medium text-status-error"
              // style={{ top: '-16px', bottom: '-16px' }}
            >
              Le lien suivant a été envoyé à l'utilisateur par e-mail :
            </label>
            <div
              className={
                'flex gap-2 items-end justify-between sm:justify-start flex-wrap'
              }
            >
              <div className="border border-gray-200 bg-gray-50 p-1 whitespace-nowrap overflow-x-auto">
                <a
                  className="w-full text-app-xxs sm:text-xs text-app-blue underline"
                  href={resetPasswordLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {resetPasswordLink}
                </a>
              </div>
              <div className="flex gap-2">
                {navigator?.clipboard && (
                  <AppButton
                    fullWidth={false}
                    color="primary-outline-light"
                    icon={AppHeroIcons.copyClipboard}
                    onClick={async () => {
                      appClipboardCopier.copyToClipboard(resetPasswordLink);
                    }}
                  >
                    <span className="hidden sm:inline">copier</span>
                  </AppButton>
                )}
                <AppButton
                  fullWidth={false}
                  color="primary-outline-light"
                  icon={AppHeroIcons.linkExternal}
                  href={resetPasswordLink}
                  target="mabadive_reset_user_password"
                >
                  <span className="hidden sm:inline">ouvrir</span>
                </AppButton>
              </div>
            </div>

            <p className="text-gray-500 text-sm">
              Si besoin, vous pouvez le communiquer manuellement à
              l'utilisateur, ou bien ré-initialiser vous-même le mot de passe en
              cliquant sur ce lien.
            </p>
          </div>
        ) : (
          <>
            {mode === 'edit' && (
              <div className="flex justify-center lg:justify-start gap-4">
                <AppButton
                  style={{ minWidth: '6rem' }}
                  size="normal"
                  color={'primary-outline-light'}
                  icon={AppHeroIcons.mail}
                  type="button"
                  onClick={() => sendInitPasswordMail(initialValue)}
                >
                  Ré-initialiser le mot de passe
                </AppButton>

                {!isMyself && (
                  <AppButton
                    style={{ minWidth: '6rem' }}
                    size="normal"
                    color={'danger-outline-light'}
                    icon={AppHeroIcons.actionDelete}
                    type="button"
                    onClick={() => deleteUser(initialValue)}
                  >
                    Supprimer cet utilisateur
                  </AppButton>
                )}
              </div>
            )}
          </>
        )}

        {mode === 'create' ? (
          <Box className={globalClasses.pageBlock} marginTop={10}>
            <AppMessage
              type="info"
              title="Informations concernant le nouveau compte utilisateur"
              message={
                <Box display="flex" flexDirection="column" alignItems="start">
                  Le nouveau compte utilisateur aura accès à plus ou moins de
                  fonctionnalités, en fonction du profil que vous lui attribuez
                  (voir tableau ci-dessous).
                  <br />
                  <br />
                  Un e-mail va être envoyé sur l'adresse indiquée permettant au
                  nouvel utilisateur de choisir son mot de passe.
                </Box>
              }
            />
          </Box>
        ) : (
          <Box className={globalClasses.pageBlock} marginTop={10}>
            <AppMessage
              type="info"
              title="Informations concernant le compte utilisateur"
              message={
                <Box display="flex" flexDirection="column" alignItems="start">
                  Le compte utilisateur a accès à plus ou moins de
                  fonctionnalités, en fonction du profil que vous lui attribuez
                  (voir tableau ci-dessous).
                </Box>
              }
            />
          </Box>
        )}
        <ClubUserProfilesDescription
          className="my-2"
          clubProfiles={clubProfiles}
        />
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
