import { AppInquiryForListGql_Company } from '@mabadive/app-common-model';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  MQueryDescription,
  graphqlClient,
} from '../../../../../_common-browser';
import { useClubResume } from '../../../../../business/club/data/hooks';

export function useFetchInquiries() {
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const query: UseQueryResult<AppInquiryForListGql_Company[], unknown> =
    useQuery({
      queryKey: buildQueryKey({ clubReference }),
      queryFn: () => fetchInquiries({ clubReference }),
      gcTime: 1000 * 60 * 60 * 24 * 2, // on garde ça en cache 2 jours
      staleTime: 0, // mais au chargement, on rafraichi systématiquement les données
      refetchOnWindowFocus: true, // ainsi que si la fenêtre reprend le focus
      refetchInterval: 120 * 60 * 1000, // et toutes les 120 minutes
    });

  const inquiries: AppInquiryForListGql_Company[] = query.data;

  return {
    status: query.status,
    fetchedData: {
      inquiries,
    },
    // updateCache: {
    //   updateCacheFromEditResult,
    // },
  };
}
function fetchInquiries({ clubReference }: { clubReference: string }) {
  const query = buildGraphqlQueryFetchInquiries({ clubReference });
  const graphqlQuery$ = graphqlClient.query.runOne(query, {
    type: 'query',
    name: 'fetchInquiries',
  });

  return graphqlQuery$?.toPromise();
}
function buildQueryKey({ clubReference }: { clubReference: string }): string[] {
  return ['fetchInquiries', clubReference];
}
function buildGraphqlQueryFetchInquiries({
  clubReference,
}: {
  clubReference: string;
}) {
  const where = `{clubReference: {_eq: "${clubReference}"}}`;

  const queryDescription: MQueryDescription<AppInquiryForListGql_Company> = {
    queryName: 'inquiry',
    returnType: 'all',
    where,
    orderBy: '{name: asc}',
    returnAttributes: AppInquiryForListGql_Company,
  };

  return queryDescription;
}
