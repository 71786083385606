import {
  DashboardBookingsOverPeriodCleanAnalysis,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useState } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { AppButton } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppIconsAction,
  AppIconsUI,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import { DashboardMetricValueCard } from 'src/pages/DA-dashboard/_core';
import { DataManagementFetchCriteria } from '../../model';
import { DataManagementBookingsCleanContext } from './DataManagementBookingsCleanContext.type';
export const DataManagementBookingsCleanAnalysisBookings = ({
  fetchCriteria,
  bookingsCleanAnalysisOverPeriod: data,
  reload,
  context,
}: {
  fetchCriteria: DataManagementFetchCriteria;
  bookingsCleanAnalysisOverPeriod: DashboardBookingsOverPeriodCleanAnalysis;
  reload: () => any;
  context: DataManagementBookingsCleanContext;
}) => {
  const bookingUnarchivedCounts =
    data?.bookingCounts - data?.bookingArchivedCounts;

  const { beginDate, endDate } = fetchCriteria;

  const [isPersistInProgress, setIsPersistInProgress] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <DashboardMetricValueCard
        label={'Réservations'}
        value={data.bookingCounts}
        unitIcon={EntitiesIcons.booking}
      />

      {data.bookingCounts > 0 && bookingUnarchivedCounts === 0 && (
        <div className="block text-left text-green-700 text-xs font-medium uppercase">
          <AppIconsAction.archive className="inline h-4 w-4 mr-1 text-green-600" />{' '}
          archivées
        </div>
      )}
      {context.enableArchiveAction && bookingUnarchivedCounts > 0 && (
        <div className="flex-grow flex flex-col gap-2 justify-between">
          <div
            className={clsx(
              'block text-left text-xs font-medium uppercase',
              context.toArchiveStyle.className,
            )}
          >
            <context.toArchiveStyle.icon
              className={clsx(
                context.toArchiveStyle.iconClassName,
                'inline h-5 w-5',
              )}
            />{' '}
            {bookingUnarchivedCounts} actives
          </div>
          <AppButton
            className="my-2 w-full"
            color="gray-outline-light"
            icon={AppIconsAction.archive}
            disabled={isPersistInProgress}
            onClick={async () => {
              //sur?
              if (
                await confirmDialog.confirmPromise({
                  title: 'Archiver toutes les réservations',
                  message:
                    'Êtes-vous sûr de vouloir archiver toutes les réservations de cette période?',
                  type: 'noYesDanger',
                })
              ) {
                const payload: ProMultiOperationPayload = {
                  logContext: 'dashboard clean analysis:  bookings',
                  archiveOverPeriod: {
                    beginDate,
                    endDate,
                    bookings: {
                      active: true,
                    },
                  },
                };
                setIsPersistInProgress(true);
                try {
                  await clubMassiveUpdatorClient.update(payload);
                  await reload();
                } finally {
                  setIsPersistInProgress(false);
                }
              }
            }}
          >
            Archiver
          </AppButton>
        </div>
      )}
      {data.bookingPendingCounts > 0 && (
        <div className="flex-grow flex flex-col gap-2 justify-between">
          <div
            className={clsx(
              'block text-left text-xs font-medium uppercase',
              context.toArchiveStyle.className,
            )}
          >
            <AppIconsUI.statusPending className="inline h-4 w-4 mr-1" />{' '}
            {data.bookingPendingCounts} en cours
            {/* Note: ce sont les reservations qui ont des plongées associées, les résas vides de plongées n'apparaissent pas ici */}
          </div>
        </div>
      )}
    </div>
  );
};
