/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubStaffMemberRole,
  StaffMemberResumeSessionsDayModel,
} from '@mabadive/app-common-model';
import { staffMemberFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiveSessionStaffRoleModel } from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components';

export const useDayStaffMemberRoles = ({
  staffMemberResumeSessionsDay: resumeSessionsDay,
  availableRoles,
}: {
  staffMemberResumeSessionsDay: StaffMemberResumeSessionsDayModel;
  availableRoles: ClubStaffMemberRole[];
}): DiveSessionStaffRoleModel[] => {
  const clubResume = useClubResume();

  const roles = useMemo(() => {
    const roles: DiveSessionStaffRoleModel[] = [];
    if (availableRoles.includes('surface-security')) {
      roles.push({
        staffRoleRef: 'surface-security',
        label: staffMemberFormatter.formatStaffRoleShort('surface-security'),
        enabled:
          resumeSessionsDay.dailyAvailableRoles.includes('surface-security'),
        active: resumeSessionsDay.dailyActiveRoles.includes('surface-security'),
        assigned:
          resumeSessionsDay.dailyAssignedRoles.includes('surface-security'),
      });
    }

    if (availableRoles.includes('diving-director')) {
      roles.push({
        staffRoleRef: 'diving-director',
        label: staffMemberFormatter.formatStaffRoleShort('diving-director'),
        enabled:
          resumeSessionsDay.dailyAvailableRoles.includes('diving-director'),
        active: resumeSessionsDay.dailyActiveRoles.includes('diving-director'),
        assigned:
          resumeSessionsDay.dailyAssignedRoles.includes('diving-director'),
      });
    }
    if (availableRoles.includes('scuba-diving-instructor')) {
      roles.push({
        staffRoleRef: 'scuba-diving-instructor',
        label: staffMemberFormatter.formatStaffRoleShort(
          'scuba-diving-instructor',
        ),
        enabled: resumeSessionsDay.dailyAvailableRoles.includes(
          'scuba-diving-instructor',
        ),
        active: resumeSessionsDay.dailyActiveRoles.includes(
          'scuba-diving-instructor',
        ),
        assigned: resumeSessionsDay.dailyAssignedRoles.includes(
          'scuba-diving-instructor',
        ),
      });
    }
    if (
      false && // disabled
      clubResume.clubSettings.freeDive.enabled &&
      availableRoles.includes('free-diving-instructor')
    ) {
      roles.push({
        staffRoleRef: 'free-diving-instructor',
        label: staffMemberFormatter.formatStaffRoleShort(
          'free-diving-instructor',
        ),
        enabled: resumeSessionsDay.dailyAvailableRoles.includes(
          'free-diving-instructor',
        ),
        active: resumeSessionsDay.dailyActiveRoles.includes(
          'free-diving-instructor',
        ),
        assigned: resumeSessionsDay.dailyAssignedRoles.includes(
          'free-diving-instructor',
        ),
      });
    }
    return roles;
  }, [
    availableRoles,
    clubResume.clubSettings.freeDive.enabled,
    resumeSessionsDay.dailyAvailableRoles,
    resumeSessionsDay.dailyActiveRoles,
    resumeSessionsDay.dailyAssignedRoles,
  ]);

  return roles;
};
