/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubOffer } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React from 'react';
import { useClubOfferState } from 'src/business/club/modules/club-offer/ClubOfferViewPage/useClubOfferState.hook';

export const ClubResumeAdminCardOffer = ({ offer }: { offer: ClubOffer }) => {
  const clubOfferState = useClubOfferState(offer);

  const { isCurrentOfferOver, isPaymentDeadlineOver, isPaymentDeadlineSoon } =
    clubOfferState;

  const label =
    offer?.state === 'draft'
      ? 'Nouveau'
      : offer?.state === 'trial'
      ? 'Essai gratuit'
      : offer?.state === 'active'
      ? 'Actif'
      : offer?.state === 'archived'
      ? 'Archivé'
      : offer?.state === 'demo'
      ? 'Démo'
      : '???';

  return !offer ? null : (
    <div className="w-28 text-gray-400">
      <div className="uppercase">{label}</div>

      {offer?.state === 'trial' && isCurrentOfferOver && (
        <b className="font-bold text-status-error">expirée</b>
      )}
      {offer?.state === 'active' ? (
        isCurrentOfferOver ? (
          <b className="font-bold text-status-error">expirée</b>
        ) : offer?.paymentDeadline ? (
          <b className="font-bold text-status-warn">règlement attendu</b>
        ) : null
      ) : null}
      {(offer?.state === 'trial' || offer?.state === 'active') && (
        <>
          <div>
            {dateService.formatUTC(offer.currentOfferStart, 'DD/MM/YYYY')}
          </div>
          <div>
            {dateService.formatUTC(offer.currentOfferEnd, 'DD/MM/YYYY')}
          </div>
        </>
      )}
    </div>
  );
};
