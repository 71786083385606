/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveCenterDailyConfig,
  DiveSessionStaffMemberTableModelStaffMember,
} from '@mabadive/app-common-model';
import {
  diveSessionMultipleBuilder,
  diveSessionStaffMembersTableModelBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { DiveSessionStaffMembersTableSession } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components';
import { CalendarStaffInstructorCell } from '../session-common/CalendarParticipantCell/CalendarStaffInstructorCell';
import { UseDiveSessionSimpleCardLocalState } from './useDiveSessionSimpleCardLocalState.hook';

export const DiveSessionSimpleCardStaffRoles = ({
  localState,
  className,
}: {
  localState: UseDiveSessionSimpleCardLocalState;
  className?: string;
}) => {
  const { state, data, actions } = localState;
  const { dailyConfig, planningConfig, widthMode } = state;
  const { isPast, session } = data;

  const isMultiSessionConfigForStaff =
    diveSessionMultipleBuilder.isMultiSessionConfigForStaff(
      session.diveTourSession2,
    );

  const diveCenterResume = useDiveCenterResume();

  const staffMembers = diveCenterResume?.staffMembers;

  const dailyConfigs: DiveCenterDailyConfig[] = useMemo(
    () => (dailyConfig ? [dailyConfig] : []),
    [dailyConfig],
  );

  const staffFirstNameBefore = useMemo(
    () =>
      diveCenterResume.clubResume.clubSettings.ui?.planning
        ?.staffFirstNameBefore ?? false,
    [
      diveCenterResume.clubResume.clubSettings.ui?.planning
        ?.staffFirstNameBefore,
    ],
  );

  const staffMembersFull: DiveSessionStaffMemberTableModelStaffMember[] =
    useMemo(
      () =>
        diveSessionStaffMembersTableModelBuilder.buildStaffMembersFull({
          staffMembers,
          filterMode: 'session-active',
          diveSession: session,
          clubParticipants: session.participants,
          groups: session.groups,
          staffFirstNameBefore,
          dailyConfigs,
          clubSettings: diveCenterResume?.clubResume?.clubSettings,
        }),
      [
        dailyConfigs,
        diveCenterResume?.clubResume?.clubSettings,
        session,
        staffFirstNameBefore,
        staffMembers,
      ],
    );

  return staffMembersFull.length > 0 ? (
    <div className={className}>
      {staffMembersFull.map((staffMemberFull) => (
        <div key={staffMemberFull.staffMember._id} className={'bg-white'}>
          <CalendarStaffInstructorCell
            className={'mt-px'}
            staffFirstNameBefore={planningConfig.staffFirstNameBefore}
            staffMember={staffMemberFull.staffMember}
            diveSession={session}
            isPast={isPast}
            widthMode={widthMode}
          />

          <div className="border border-gray-300 bg-gray-50 py-1 mb-1 grid gap-4 justify-start items-center">
            <div className={'whitespace-nowrap text-xs'}>
              <DiveSessionStaffMembersTableSession
                showRoles={true}
                session={staffMemberFull.session1}
              />
            </div>
            {isMultiSessionConfigForStaff && (
              <div className={'whitespace-nowrap text-xs'}>
                <DiveSessionStaffMembersTableSession
                  showRoles={true}
                  session={staffMemberFull.session2}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  ) : null;
};
