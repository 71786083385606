/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { PlanningViewConfig } from '../../club-planning/_model';
import { DiveSessionSimpleCard } from '../components';
import { CalendarSessionWidthMode } from '../components/ClubPlanningLightSession/session-common';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
  DiveSessionSimpleCardDisplayOptions,
} from '../model';
import { ClubDialogsState } from '../../../../../pages/_dialogs';

export const ClubPlanningLightPanelViewPeriodWeekSessionsModeFullDaySessions =
  ({
    planningParticipantsLocalState,
    dialogsState,
    day,
    clickActions,
    sessionsDisplayConfigs = [],
    planningConfig,
    widthMode,
    displayOptions,
    iDailySession,
    className,
  }: {
    planningParticipantsLocalState?: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
    dialogsState: ClubDialogsState; // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
    day: ClubPlanningDayFull;
    clickActions: ClubPlanningLightPanelViewClickActions;
    sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
    planningConfig: PlanningViewConfig;
    widthMode: CalendarSessionWidthMode;
    displayOptions: DiveSessionSimpleCardDisplayOptions;
    iDailySession: number; // index de la session à afficher, si elle existe pour ce jour
    className?: string;
  }) => {
    return (
      <div
        className={clsx(
          'flex flex-col justify-start bg-gray-50 border-l border-r border-gray-200',
          className,
        )}
      >
        {day.sessions.length > iDailySession && (
          <div
            className={
              'flex-grow select-none text-gray-700 text-sm leading-4 flex flex-col justify-start'
            }
          >
            <DiveSessionSimpleCard
              planningParticipantsLocalState={planningParticipantsLocalState}
              dialogsState={dialogsState}
              className="flex-grow"
              widthMode={widthMode}
              flexGrow={true}
              session={day.sessions[iDailySession]}
              dailyConfig={day.dailyConfig}
              planningConfig={planningConfig}
              display={{
                ...displayOptions,
                sessionIndex: {
                  index: iDailySession + 1,
                  total: day.sessions.length,
                },
              }}
              // NOTE: il faudrait revoir un peu tout ça en mode `full`, pour que les participants soient tout le temps visible, mais tout en ayant la possibilité d'"ouvrir" les détails, avec :
              // - le bouton "add" toujours visible
              // - le commentaire et le champ "open" seulement visible si on ouvre la session
              expandByDefault={true}
              selectionColor={
                sessionsDisplayConfigs.find(
                  (x) =>
                    x.diveSessionReference ===
                    day.sessions[iDailySession].reference,
                )?.style
              }
              clickActions={clickActions}
              emptyParticipantsCount={
                0
                // maxParticipantsCountByDaySessionIndex[session.daySessionIndex] -
                // session.participants.length
              }
            />
          </div>
        )}
      </div>
    );
  };
