/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppLoader, AppPageContainer } from 'src/business/_core/modules/layout';
import { ClubDialogsProvider } from 'src/pages/_dialogs';

import clsx from 'clsx';

import { EcommerceProductArticleEditorPanel } from './components';
import { EcommerceCategoryEditorPanel } from './components/EcommerceCategoryEditor';
import { EcommerceProductEditorPanel } from './components/EcommerceProductEditor';
import {
  ProProductsPage_CategoryListPanel,
  ProProductsPage_CategoryViewPanel,
} from './panels';
import { ProProductsPage_ProductViewPanel } from './panels/ProProductsPage_ProductViewPanel';
import {
  ClubSettingsOnlineBookingProductsPageLocalState,
  useClubSettingsOnlineBookingProductsPageLocalState,
} from './useClubSettingsOnlineBookingProductsPageLocalState.hook';

export const ClubSettingsOnlineBookingProductsPage = () => {
  const localState: ClubSettingsOnlineBookingProductsPageLocalState =
    useClubSettingsOnlineBookingProductsPageLocalState();

  const { state, dialogs, actions, data } = localState;

  return dialogs.isAnyDialogOpen ? (
    <>
      {dialogs.categoryEditor.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className={clsx('bg-white relative overflow-y-auto')}
        >
          <EcommerceCategoryEditorPanel
            {...dialogs.categoryEditor.props}
            callback={actions.categoryEditorCallback}
          />
        </AppPageContainer>
      )}
      {dialogs.productEditor.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className={clsx('bg-white relative overflow-y-auto')}
        >
          <EcommerceProductEditorPanel
            {...dialogs.productEditor.props}
            callback={actions.productEditorCallback}
          />
        </AppPageContainer>
      )}
      {dialogs.productArticleEditor.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className={clsx('bg-white relative overflow-y-auto')}
        >
          <EcommerceProductArticleEditorPanel
            {...dialogs.productArticleEditor.props}
            callback={actions.productArticleEditorCallback}
          />
        </AppPageContainer>
      )}
    </>
  ) : (
    <>
      <ClubDialogsProvider dialogsState={dialogs.state}>
        {state.loadableContent.contentState !== 'full' ? (
          <AppLoader type="loading" />
        ) : (
          <>
            {state.viewState.mode === 'categories-list' && (
              <ProProductsPage_CategoryListPanel localState={localState} />
            )}
            {state.viewState.mode === 'category-view' && (
              <ProProductsPage_CategoryViewPanel localState={localState} />
            )}
            {state.viewState.mode === 'product-view' && (
              <ProProductsPage_ProductViewPanel localState={localState} />
            )}
          </>
        )}
      </ClubDialogsProvider>
    </>
  );
};
