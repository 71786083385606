/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveCenterDailyConfig, DiveSession } from '@mabadive/app-common-model';
import {
  changeDescriptorAggregator,
  changeDescriptorManager,
} from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { PlanningViewConfig } from '../../_model';
import {
  ClubPlanningStaffPageCacheAggregatedDataCore,
  ClubPlanningStaffPageCacheUpdateState,
} from '../ClubPlanningStaffWeekStaffPresence/_model';

// TODO: il faudrait faire un cache générique, utilisé à l'identique sur toutes les pages qui en ont besoin
// NOTE: ça permettra par la suite d'avoir un cache global à l'application (en permettant de naviguer même avec des modifs en cours)
export const usePlanningStaffWeekPresenceCache = ({
  planningConfig,
  dailyConfigsFetched,
  diveSessionsFetched,
}: {
  planningConfig: PlanningViewConfig;
  dailyConfigsFetched: DiveCenterDailyConfig[];
  diveSessionsFetched: DiveSession[];
}) => {
  const intitialUpdateState: ClubPlanningStaffPageCacheUpdateState = useMemo(
    () =>
      buildInitialState({
        dailyConfigs: dailyConfigsFetched,
        diveSessions: diveSessionsFetched,
        focusDateRef: planningConfig.focusDateRef,
      }),
    [dailyConfigsFetched, diveSessionsFetched, planningConfig.focusDateRef],
  );

  const [updateState, setUpdateState] =
    useState<ClubPlanningStaffPageCacheUpdateState>(intitialUpdateState);

  const resetCache = useCallback(
    (fetchedData?: {
      focusDateRef: string;
      dailyConfigs: DiveCenterDailyConfig[];
      diveSessions: DiveSession[];
    }) => {
      if (fetchedData?.dailyConfigs !== undefined) {
        const updateState: ClubPlanningStaffPageCacheUpdateState =
          buildInitialState(fetchedData);
        setUpdateState(updateState);
      } else {
        setUpdateState(intitialUpdateState);
      }
    },
    [intitialUpdateState],
  );

  const aggregatedDataCore: ClubPlanningStaffPageCacheAggregatedDataCore =
    useMemo(() => {
      // aggregate cache to fetched data
      const dailyConfigs = changeDescriptorAggregator.aggregateMany(
        updateState.dailyConfigsChanges,
        {
          pk: '_id',
          initials: [], // NOTE: pas besoin de passer dailyConfigsFetched, car c'est présent dans updateState (ajouté au chargement via addManyOriginals)
          ignoreErrors: true,
          appLogger,
          logPrefix: 'dailyConfigs',
        },
      );

      const diveSessions = changeDescriptorAggregator.aggregateMany(
        updateState.diveSessionsChanges,
        {
          pk: '_id',
          initials: [],
          ignoreErrors: true,
          appLogger,
          logPrefix: 'diveSessions',
        },
      );

      return { dailyConfigs, diveSessions };
    }, [updateState.dailyConfigsChanges, updateState.diveSessionsChanges]);

  return { aggregatedDataCore, updateState, setUpdateState, reset: resetCache };
};

export type PlanningStaffWeekPresenceCache = ReturnType<
  typeof usePlanningStaffWeekPresenceCache
>;
function buildInitialState(fetchedData: {
  focusDateRef: string;
  dailyConfigs: DiveCenterDailyConfig[];
  diveSessions: DiveSession[];
}): ClubPlanningStaffPageCacheUpdateState {
  return {
    isInitialized: true,
    hasChanges: false,
    focusDateRef: fetchedData.focusDateRef,
    dailyConfigsChanges: changeDescriptorManager.addManyOriginals(
      fetchedData.dailyConfigs,
      {
        changeDescriptors: [],
      },
    ),
    diveSessionsChanges: changeDescriptorManager.addManyOriginals(
      fetchedData.diveSessions,
      {
        changeDescriptors: [],
      },
    ),
  };
}
