/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ClubSettingsServicesPageBasePanel } from '../../../_common';
import { ClubSettingsServicesViewPanelOffersPrices } from '../../../_common/offers-prices/view';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';

export const ClubSettingsServicesOthersViewPanel = ({
  localState,
}: {
  localState: ClubSettingsServicesPageLocalState;
}) => {
  return (
    <ClubSettingsServicesPageBasePanel localState={localState}>
      <div className="grid gap-4 md:gap-6">
        <ClubSettingsServicesViewPanelOffersPrices
          localState={localState}
          contextMode="others"
        />
      </div>
    </ClubSettingsServicesPageBasePanel>
  );
};
