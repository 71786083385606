import { diveModeFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { useClubSettings } from '../../../../../../../../business/club/data/hooks';
import { EvolutionParticipantsByDayBarChartAttribute } from '../model';
import { EvolutionParticipantsByDayBarChartConfigVisibility } from './EvolutionParticipantsByDayBarChart';
import { EvolutionParticipantsByDayBarChartLegendCheckbox } from './EvolutionParticipantsByDayBarChartLegendCheckbox';
import { DashboardStatsDiveModeColors } from './useDashboardStatsDiveModeColors.hook';

type ChartAttribute = EvolutionParticipantsByDayBarChartAttribute;

export const EvolutionParticipantsByDayBarChartLegend = ({
  configVisibility,
  setConfigVisibility,
  colors,
}: {
  configVisibility: EvolutionParticipantsByDayBarChartConfigVisibility;
  setConfigVisibility: (
    configVisibility: EvolutionParticipantsByDayBarChartConfigVisibility,
  ) => any;
  colors: DashboardStatsDiveModeColors;
}) => {
  const clubSettings = useClubSettings();
  return (
    <div className="mt-2 mx-2 uppercase flex flex-wrap gap-2 md:gap-4 text-sm md:text-base">
      {clubSettings.firstDive?.enabled && (
        <EvolutionParticipantsByDayBarChartLegendCheckbox
          className="text-white"
          style={{ backgroundColor: colors.firstDiveCount }}
          label="BPT"
          value={configVisibility.firstDiveCount}
          onChange={(firstDiveCount) => {
            setConfigVisibility({
              ...configVisibility,
              firstDiveCount,
            });
          }}
        />
      )}

      {clubSettings.training?.enabled && (
        <EvolutionParticipantsByDayBarChartLegendCheckbox
          className="text-white"
          style={{ backgroundColor: colors.trainingCount }}
          label="FN"
          value={configVisibility.trainingCount}
          onChange={(trainingCount) => {
            setConfigVisibility({
              ...configVisibility,
              trainingCount,
            });
          }}
        />
      )}

      {(clubSettings.supervised?.enabled ||
        clubSettings.autonomous?.enabled ||
        clubSettings.instructor?.enabled) && (
        <EvolutionParticipantsByDayBarChartLegendCheckbox
          className="text-white"
          style={{ backgroundColor: colors.recreationalSupervisedCount }}
          label="Explo"
          value={configVisibility.recreationalSupervisedCount}
          onChange={(recreationalSupervisedCount) => {
            setConfigVisibility({
              ...configVisibility,
              recreationalSupervisedCount,
            });
          }}
        />
      )}
      {clubSettings.instructor?.enabled && (
        <EvolutionParticipantsByDayBarChartLegendCheckbox
          className="text-white"
          style={{ backgroundColor: colors.recreationalInstructorsCount }}
          label="GP"
          value={configVisibility.recreationalInstructorsCount}
          onChange={(recreationalInstructorsCount) => {
            setConfigVisibility({
              ...configVisibility,
              recreationalInstructorsCount,
            });
          }}
        />
      )}
      {clubSettings.autoSupervised?.enabled && (
        <EvolutionParticipantsByDayBarChartLegendCheckbox
          className="text-white"
          style={{ backgroundColor: colors.autoSupervisedCount }}
          label="Auto-encadré"
          value={configVisibility.autoSupervisedCount}
          onChange={(autoSupervisedCount) => {
            setConfigVisibility({
              ...configVisibility,
              autoSupervisedCount,
            });
          }}
        />
      )}
      {clubSettings.watchingTour?.enabled && (
        <EvolutionParticipantsByDayBarChartLegendCheckbox
          className="text-white"
          style={{ backgroundColor: colors.watchingTourCount }}
          label={diveModeFormatter.formatDiveMode('watchingTour', {
            format: 'long-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          value={configVisibility.watchingTourCount}
          onChange={(watchingTourCount) => {
            setConfigVisibility({
              ...configVisibility,
              watchingTourCount,
            });
          }}
        />
      )}
      <EvolutionParticipantsByDayBarChartLegendCheckbox
        className="text-white"
        style={{ backgroundColor: colors.otherCount }}
        label="Autres"
        value={configVisibility.otherCount}
        onChange={(otherCount) => {
          setConfigVisibility({
            ...configVisibility,
            otherCount,
          });
        }}
      />
      <EvolutionParticipantsByDayBarChartLegendCheckbox
        className="bg-status-error text-white"
        label="ANNUL"
        value={configVisibility.cancelCount}
        onChange={(cancelCount) => {
          setConfigVisibility({
            ...configVisibility,
            cancelCount,
          });
        }}
      />
    </div>
  );
};
