/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanSwitchRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { ClubSettingsServicesFormModel } from '../../../_model';

export const ClubSettingsServicesExplosForm_Settings = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();

  return (
    <ClubSettingsSection title="Configuration">
      <div className="ml-2 grid gap-2">
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Afficher le nombre d'explos facturées des plongeurs sur l'onglet de facturation"
          control={control}
          name={'clubSettings.ui.billing.displayBilledExploSessionsCounts'}
          required={false}
          visibility="super-admin"
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
      </div>
    </ClubSettingsSection>
  );
};
