/* eslint-disable @typescript-eslint/no-unused-vars */
import { AccountProfile } from '@mabadive/app-common-model';
import { singleRepositoryFactory } from 'src/business/_core/data/repository/single/singleRepositoryFactory.service';
import { accountStore } from 'src/business/_core/store';

const store = accountStore.profile;

export const accountProfileRepository = {
  ...singleRepositoryFactory.create<AccountProfile>({
    repositoryName: 'accountProfileRepository',
    store,
    mutationEntity: 'accountProfile',
  }),
};
