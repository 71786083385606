import { dataSorter, dateService } from '@mabadive/app-common-services';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSession,
  BookingResumeParticipantsBySession,
  DiverBookingPageAggregatedData,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
  SessionsHistoryTabModel,
} from '../../../models';
import { DiverBookingPageClubDiverLinkedData } from '../../01.loaded-content';
import { sessionsHistoryTabModelBuilderFilter } from './sessionsHistoryTabModelBuilderFilter.service';

export const sessionsHistoryTabModelBuilder = { buildSessionsHistoryTabModel };

export type SessionsHistoryTabModelBuilderFilterCriteria = {
  ignoreOtherDiveCenters: boolean;
  ignoreCancelledParticipants: boolean;
};

function buildSessionsHistoryTabModel({
  aggregatedData,
  criteria,
  linkedData,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  criteria: SessionsHistoryTabModelBuilderFilterCriteria & {
    filteredDiversIds: string[];
    currentDiveCenterId: string;
  };
  linkedData: DiverBookingPageClubDiverLinkedData;
}): SessionsHistoryTabModel {
  const { counts, visibleParticipants } =
    sessionsHistoryTabModelBuilderFilter.filterParticipants({
      aggregatedData,
      criteria,
      linkedData,
    });

  const { futureSessions, pastSessions } = buildParticipantsBySession({
    visibleParticipants,
    aggregatedData,
    criteria,
  });

  const model: SessionsHistoryTabModel = {
    counts,
    futureSessions,
    pastSessions,
  };

  return model;
}

export function buildParticipantsBySession({
  visibleParticipants,
  aggregatedData,
  criteria,
}: {
  visibleParticipants: PRO_BookingParticipantFull[];
  aggregatedData: DiverBookingPageAggregatedData;
  criteria: SessionsHistoryTabModelBuilderFilterCriteria & {
    filteredDiversIds: string[];
  };
}): {
  futureSessions: BookingResumeParticipantsBySession[];
  pastSessions: BookingResumeParticipantsBySession[];
} {
  // const { bookingMembersFull, bookingParticipantsFull, bookingSessionsFull } =
  //   bookingResume;

  const bookingSessionsFull = aggregatedData.bookingResumesLoaded.reduce(
    (acc, bookingResume) => acc.concat(bookingResume.bookingSessionsFull),
    [] as AggregatedBookingSessionFull[],
  );

  const bookingParticipantsFull = visibleParticipants;

  const bookingMembersFull = aggregatedData.bookingResumesLoaded.reduce(
    (acc, bookingResume) => acc.concat(bookingResume.bookingMembersFull),
    [] as PRO_BookingMemberFull_WithDocs[],
  );

  const today = dateService.getUTCDateWithoutTime(new Date());

  const bookingSessionsFullFiltered = bookingSessionsFull.filter((bs) => {
    if (criteria.ignoreOtherDiveCenters) {
      if (
        dateService.isBefore(bs.diveSession.time, today) ||
        dateService.isTodayUTC(bs.diveSession.time)
      ) {
        // past or present
        return true;
      }
      // future
      return false;
    }
    // else if (period === 'future') {
    //   if (!dateService.isBefore(bs.diveSession.time, today)) {
    //     return true;
    //   }
    // }

    return true;
  });

  const { futureSessions, pastSessions } = bookingSessionsFullFiltered.reduce(
    (acc, bookingSessionFull) => {
      const participants: BookingResumeParticipantForSession[] =
        bookingParticipantsFull
          .filter((p) => {
            return (
              p.bookingSession._id === bookingSessionFull.bookingSession._id &&
              !(
                criteria.filteredDiversIds?.length > 0 &&
                !criteria.filteredDiversIds.includes(p.diver._id)
              )
            );
          })
          .map((p) => {
            const bookingMemberFull = bookingMembersFull.find((m) => {
              return p.bookingMember._id === m.bookingMember._id;
            });
            const participant: BookingResumeParticipantForSession = {
              bookingMemberFull,
              bookingParticipantFull: p,
              bookingParticipantFullSameBooking: p,
              bookingParticipantFullAnyBooking: p,
              style: 'normal',
            };
            return participant;
          })
          .filter((x) => x.bookingMemberFull);

      if (participants.length > 0) {
        const participantsBySession: BookingResumeParticipantsBySession = {
          bookingSessionFull,
          participants,
        };
        const bs = participantsBySession.bookingSessionFull;
        if (
          dateService.isBefore(bs.diveSession.time, today) ||
          dateService.isTodayUTC(bs.diveSession.time)
        ) {
          acc.pastSessions.push(participantsBySession);
        } else {
          acc.futureSessions.push(participantsBySession);
        }
      }

      return acc;
    },
    { futureSessions: [], pastSessions: [] } as {
      futureSessions: BookingResumeParticipantsBySession[];
      pastSessions: BookingResumeParticipantsBySession[];
    },
  );
  return {
    futureSessions: sortSessions(futureSessions, {
      asc: true,
    }),
    pastSessions: sortSessions(pastSessions, {
      asc: false,
    }),
  };
}

function sortSessions(
  all: BookingResumeParticipantsBySession[],
  { asc }: { asc: boolean },
): BookingResumeParticipantsBySession[] {
  return dataSorter.sortMultiple(all, {
    getSortAttributes: (x) => [
      {
        value: x?.bookingSessionFull.diveSession?.time,
        type: 'default',
        asc: true,
      },
      {
        value: x?.bookingSessionFull.bookingSession?._id,
        type: 'full-text',
      },
    ],
    asc,
  });
}
