export const adminErrorParser = {
  parseErrorString,
};
export type AdminErrorDetails = {
  errorString: string;
  constraint: {
    name?: string;
    type?: string;
    tableName?: string;
    columnName?: string; // Ajouté pour les erreurs NOT_NULL, peut-être utile pour d'autres types d'erreurs.
  };
  operation: {
    name?: string;
    tableName?: string;
  };
};

function parseErrorString(errorString: string): AdminErrorDetails {
  if (!errorString) {
    return undefined;
  }
  const operationMatch = errorString.match(
    /(update or delete) on table \\"([^\\"]+)\\"/,
  );
  const foreignKeyConstraintMatch = errorString.match(
    /violates foreign key constraint \\"([^\\"]+)\\" on table \\"([^\\"]+)\\"/,
  );
  const notNullConstraintMatch = errorString.match(
    /null value in column \\"([^\\"]+)\\" of relation \\"([^\\"]+)\\" violates not-null constraint/,
  );
  const uniqueConstraintMatch = errorString.match(
    /duplicate key value violates unique constraint \\"([^\\"]+)\\"/,
  );

  const result: AdminErrorDetails = {
    errorString,
    constraint: {},
    operation: {},
  };

  if (operationMatch) {
    result.operation.name = operationMatch[1];
    result.operation.tableName = operationMatch[2];
  }

  if (foreignKeyConstraintMatch) {
    result.constraint.name = foreignKeyConstraintMatch[1];
    result.constraint.tableName = foreignKeyConstraintMatch[2];
    result.constraint.type = 'FK';
  } else if (notNullConstraintMatch) {
    result.constraint.columnName = notNullConstraintMatch[1];
    result.constraint.tableName = notNullConstraintMatch[2];
    result.constraint.type = 'NOT_NULL';
  } else if (uniqueConstraintMatch) {
    result.constraint.name = uniqueConstraintMatch[1];
    // Le type de contrainte est défini à 'UK' pour unique key.
    result.constraint.type = 'UK';
    // La table concernée n'est pas directement disponible dans le message pour les contraintes uniques.
    if (result.constraint.name === 'UQ_c4140d4782579bfa34ccf1cdf11') {
      result.constraint.tableName = 'club_participant';
      result.constraint.columnName = 'diverId, diveSessionReference';
    }
  }

  return result;
}
