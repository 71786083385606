import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { AppHeroIcons } from '../../icons';
import { AppNavigationStep } from './AppNavigationStep.type';
import { AppNavigationStepStatus } from './AppNavigationStepStatus.type';

export const AppNavigationStepsBar = <T extends string | number>({
  steps,
  value,
  onChange,
  disableNavigation,
  ariaLabel,
  className,
}: {
  steps: AppNavigationStep<T>[];
  value?: T;
  onChange?: (tab: AppNavigationStep<T>) => void;
  disableNavigation?: boolean;
  ariaLabel?: string;
  className?: string;
}) => {
  const valueIndex = useMemo(
    () => steps.findIndex((step) => step.id === value),
    [steps, value],
  );

  const calculatedSteps = useMemo(
    () =>
      steps.map((step, i) => {
        const status: AppNavigationStepStatus =
          step.status ?? i > valueIndex
            ? 'upcoming'
            : i === valueIndex
            ? 'current'
            : 'complete';
        return {
          ...step,
          status,
        };
      }),
    [steps, valueIndex],
  );

  const isNavigationEnabled = useCallback(
    (step: AppNavigationStep<T>) => {
      if (disableNavigation) {
        return false;
      }
      return true;
    },
    [disableNavigation],
  );

  return (
    <nav aria-label={ariaLabel} className={className}>
      <ol className="flex items-center -ml-[0.5rem] sm:-ml-[1rem]">
        {calculatedSteps.map((step, stepIdx) => (
          <li
            key={step.id}
            className={clsx(
              'relative flex justify-center items-center min-w-[4rem] sm:min-w-[8rem]',
            )}
          >
            <>
              <div
                className="absolute top-4 left-0 right-0 flex items-center"
                aria-hidden="true"
              >
                <div
                  className={`h-0.5 w-1/2 ${
                    stepIdx === 0
                      ? 'bg-transparent'
                      : step.status === 'complete'
                      ? 'bg-gray-500'
                      : step.status === 'current'
                      ? 'bg-gray-500'
                      : 'bg-gray-200'
                  }`}
                />
                <div
                  className={`h-0.5 w-1/2 ${
                    stepIdx === steps.length - 1
                      ? 'bg-transparent'
                      : step.status === 'complete'
                      ? 'bg-gray-500'
                      : step.status === 'current'
                      ? 'bg-gray-200'
                      : 'bg-gray-200'
                  }`}
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  if (isNavigationEnabled(step)) {
                    onChange(step);
                  }
                }}
                className={`group text-center ${
                  isNavigationEnabled(step)
                    ? 'cursor-pointer'
                    : 'cursor-not-allowed'
                }`}
              >
                <div
                  className={`mx-auto relative w-8 h-8 flex items-center justify-center rounded-full
                ${
                  step.status === 'complete'
                    ? `bg-gray-500 ${
                        isNavigationEnabled(step)
                          ? 'group-hover:bg-gray-600'
                          : ''
                      }`
                    : step.status === 'current'
                    ? 'border-2 bg-white border-app-primary'
                    : `border-2 bg-white border-gray-300 ${
                        isNavigationEnabled(step)
                          ? 'group-hover:border-gray-400'
                          : ''
                      }`
                }
                `}
                >
                  {step.status === 'complete' ? (
                    <AppHeroIcons.checkSolid
                      className="w-5 h-5 text-white"
                      aria-hidden="true"
                    />
                  ) : step.status === 'current' ? (
                    <div
                      className="h-2.5 w-2.5 bg-app-primary rounded-full"
                      aria-hidden="true"
                    />
                  ) : (
                    <div
                      className={`h-2.5 w-2.5 bg-transparent rounded-full ${
                        isNavigationEnabled(step)
                          ? 'group-group-hover:bg-gray-300'
                          : ''
                      }`}
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div
                  className={`mt-0.5 sr-only sm:not-sr-only uppercase text-xs font-bold  ${
                    step.status === 'complete'
                      ? `text-gray-600 ${
                          isNavigationEnabled(step)
                            ? 'group-hover:text-gray-800'
                            : ''
                        }`
                      : step.status === 'current'
                      ? 'text-app-primary'
                      : `text-gray-400 ${
                          isNavigationEnabled(step)
                            ? 'group-hover:text-gray-600'
                            : ''
                        }`
                  }`}
                >
                  {step.label}
                </div>
              </button>
            </>
          </li>
        ))}
      </ol>
    </nav>
  );
};
