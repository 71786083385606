import {
  ClubPurchasePackageMeta,
  RegionalTaxContext,
} from '@mabadive/app-common-model';
import {
  clubPurchasePackageMetaReader,
  dateService,
  diveServicePurchaseStatusFormatter,
  taxesCalculator,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubResume, useDiveModeColor } from 'src/business/club/data/hooks';
import {
  AppDateTimeLabel,
  AppPriceLabel,
} from 'src/business/club/modules/_common/ui';
import { BillingParticipantPaymentResumeTableDiverName } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BillingParticipantPaymentResumeTable/BillingParticipantPaymentResumeTableDiverName';
import { BillingParticipantPurchaseResumePurchasePaymentState } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BillingParticipantPurchaseResumeTable/BillingParticipantPurchaseResumePurchasePaymentState';
import { clubParticipantUrlBuilder } from 'src/business/club/modules/url-builders';
import { AppLinkStopPropagation } from 'src/lib/browser';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { AgencyLabel } from '../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { PurchaseListPagePurchaseModel } from '../model';
import { ClubPurchasesExtraCostDescription } from './ClubPurchasesExtraCostDescription';
export function ClubPurchasesTableRow({
  purchase,
  taxContext,
  showTaxes,
}: {
  purchase: PurchaseListPagePurchaseModel;
  taxContext: RegionalTaxContext;
  showTaxes: boolean;
}) {
  const mainCurrency = useAppCurrencyMain();

  const clubResume = useClubResume();
  const generalSettings = clubResume.clubSettings.general;

  const {
    totalWithoutTaxes,
    vatTax,
    socialTax,
    totalWithTaxes,
    taxesRoundingDiscount,
  } = useMemo(() => {
    return taxesCalculator.getTaxesAmounts({
      price: purchase.price,
      taxContext,
      productType: 'diving',
    });
  }, [purchase.price, taxContext]);

  const diveMode =
    purchase.productPackageOffer?.productPackage?.diveAttributes?.diveMode;

  const credits = purchase.credits;

  const bookingDateColor = useMemo(() => {
    const ageInHours = dateService.getDiffInHours(
      purchase.purchaseDate,
      new Date(),
    );
    if (ageInHours <= 12) {
      return 'text-green-600';
    }
    if (ageInHours <= 48) {
      return 'text-orange-600';
    }
    return 'text-gray-600';
  }, [purchase.purchaseDate]);

  const isBookingLastUpdateDateColor = useMemo(() => {
    const ageInHours = dateService.getDiffInHours(
      purchase._updatedAt,
      new Date(),
    );
    if (ageInHours <= 12) {
      return 'text-green-600';
    }
    if (ageInHours <= 48) {
      return 'text-orange-600';
    }
    return 'text-gray-600';
  }, [purchase._updatedAt]);

  const clubReference = clubResume?.reference;

  const diveModeColor = useDiveModeColor(diveMode);

  const url = useMemo(() => {
    const url = clubParticipantUrlBuilder.buildTabUrl({
      navigationContext: {
        origin: 'bookings-list',
        diverId: purchase.contact._id,
      },
      tabId: 'billing',
    });
    return url;
  }, [purchase.contact._id]);

  const purchasePackageMeta: ClubPurchasePackageMeta = useMemo(
    () => clubPurchasePackageMetaReader.readMeta(purchase),
    [purchase],
  );

  const totalCount = useMemo(
    () =>
      purchasePackageMeta.credits?.creditsTotalCount * purchase.unitQuantity,
    [purchase.unitQuantity, purchasePackageMeta.credits?.creditsTotalCount],
  );

  const redirectTo = useRedirect();
  return (
    <tr
      className={'app-tr-highlight'}
      onClick={() => {
        // si on clique sur la ligne, mais en dehors d'un lien, on est quand même redirigé
        redirectTo(url);
      }}
    >
      <td
        className={`w-[85px] whitespace-nowrap text-xs ${bookingDateColor} hidden xs:table-cell`}
      >
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="utc"
            date={purchase.purchaseDate}
            format="date"
          />
        </AppLinkStopPropagation>
      </td>
      <td
        className={`w-[115px] whitespace-nowrap text-xs text-gray-600${isBookingLastUpdateDateColor} hidden xl:table-cell`}
      >
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="utc"
            date={purchase._updatedAt}
            format="date-time"
          />
        </AppLinkStopPropagation>
      </td>
      <td className="text-sm text-gray-600 truncate">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <div className="flex flex-wrap gap-2">
            <BillingParticipantPaymentResumeTableDiverName
              diver={purchase.contact}
              className={'flex-shrink'}
            />
          </div>
        </AppLinkStopPropagation>
      </td>
      {generalSettings?.agencies?.enabled &&
        generalSettings.agencies?.agencyPurchase && (
          <td className="text-sm text-gray-600 truncate hidden sm:table-cell">
            <AppLinkStopPropagation className="block px-2 py-1" to={url}>
              {purchase.billedByBookingAgencyId && (
                <div className="flex gap-2 items-center">
                  <AgencyLabel
                    className="text-xs"
                    agencyId={purchase.billedByBookingAgencyId}
                  />
                </div>
              )}
            </AppLinkStopPropagation>
          </td>
        )}
      <td
        className={'text-sm truncate font-bold'}
        style={{
          color: diveModeColor,
        }}
      >
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {purchase.label}
          <ClubPurchasesExtraCostDescription
            className="text-xs"
            extraCosts={purchase?.extraCosts}
          />
        </AppLinkStopPropagation>
      </td>

      <td className="w-[60px] whitespace-nowrap text-sm text-gray-600 hidden xs:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1 " to={url}>
          {credits && (
            <div className="text-app-blue font-bold">
              {credits.consumedTotalCount} / {totalCount}
            </div>
          )}
        </AppLinkStopPropagation>
      </td>
      <td className="w-[60px] whitespace-nowrap uppercase hidden sm:table-cell">
        <AppLinkStopPropagation
          className="block text-right text-xs text-gray-600  py-1 "
          to={url}
        >
          {purchase.validityStatus && (
            <div className="text-gray-500 text-xs">
              {diveServicePurchaseStatusFormatter.formatStatus(
                purchase.validityStatus,
              )}
            </div>
          )}
        </AppLinkStopPropagation>
      </td>
      {showTaxes && (
        <>
          <td className="w-[60px] whitespace-nowrap uppercase hidden xl:table-cell">
            <AppLinkStopPropagation
              className="block text-right text-xs text-gray-800  py-1 "
              to={url}
            >
              {totalWithoutTaxes !== 0 && (
                <AppPriceLabel
                  amount={totalWithoutTaxes}
                  mainCurrency={mainCurrency}
                />
              )}
            </AppLinkStopPropagation>
          </td>
          {taxContext.enabledCategories.includes('vat') && (
            <td className="w-[60px] whitespace-nowrap uppercase hidden xl:table-cell">
              <AppLinkStopPropagation
                className="block text-right text-xs text-gray-800  py-1 "
                to={url}
              >
                {vatTax !== 0 && (
                  <AppPriceLabel amount={vatTax} mainCurrency={mainCurrency} />
                )}
              </AppLinkStopPropagation>
            </td>
          )}
          {taxContext.enabledCategories.includes('social') && (
            <td className="w-[60px] whitespace-nowrap uppercase hidden xl:table-cell">
              <AppLinkStopPropagation
                className="block text-right text-xs text-gray-800  py-1 "
                to={url}
              >
                {socialTax !== 0 && (
                  <AppPriceLabel
                    amount={socialTax}
                    mainCurrency={mainCurrency}
                  />
                )}
              </AppLinkStopPropagation>
            </td>
          )}
        </>
      )}

      <td className="w-[60px] whitespace-nowrap uppercase hidden xl:table-cell">
        <AppLinkStopPropagation
          className="block text-right text-xs py-1 "
          to={url}
        >
          {purchase.discountAmount !== 0 && (
            <AppPriceLabel
              className="text-green-600"
              amount={purchase.discountAmount}
              mainCurrency={mainCurrency}
            />
          )}

          {showTaxes && taxesRoundingDiscount !== 0 && (
            <AppPriceLabel
              className="text-orange-400"
              amount={taxesRoundingDiscount}
              mainCurrency={mainCurrency}
            />
          )}
        </AppLinkStopPropagation>
      </td>
      <td className="w-[100px] lg:w-[130px] hidden md:table-cell px-1">
        <AppLinkStopPropagation className="flex justify-end py-1 " to={url}>
          <BillingParticipantPurchaseResumePurchasePaymentState
            purchasePackage={purchase}
          />
        </AppLinkStopPropagation>
      </td>
      {generalSettings?.billing?.thirdPartyCollectEnabled && (
        <td className="text-right w-[40px] lg:w-[60px] px-1 py-1  uppercase tracking-wider hidden md:table-cell">
          <AppPriceLabel
            className="text-gray-400 text-xs"
            amount={purchase.totalPriceThirdPartyCollect}
            mainCurrency={mainCurrency}
          />
        </td>
      )}
    </tr>
  );
}
