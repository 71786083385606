/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  JsonPatchOperation,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { useCallback } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { uiStore } from 'src/business/_core/store';
import { DailyExportConfigFormModel } from './_model';
import { DailyExportConfigDialogLocalState } from './useDailyExportConfigDialogLocalState.hook';

export function useDailyExportConfigDialogSubmit({
  localState,
}: {
  localState: DailyExportConfigDialogLocalState;
}) {
  const { form, inputState, data } = localState;
  const { setOperationInProgressMessage, diveCenterResume } = data;

  const { handleSubmit, control, formState } = form;

  // const [selectedSessionDef] = useWatch({
  //   control,
  //   name: ['selectedSessionDef'],
  // });

  return useCallback(async () => {
    try {
      return handleSubmit(
        async (formValue: DailyExportConfigFormModel) => {
          const { onConfirm, closeDialog } = inputState;

          if (localState.hasFormChanges) {
            setOperationInProgressMessage('Enregistrement en cours');
            const initialValue =
              diveCenterResume.privateSettings?.exports?.dailyResumes;
            const finalValue = {
              ...(initialValue ?? {}),
              hideOldSecuritySheetExport: formValue.hideOldSecuritySheetExport,
              dailyResumes: formValue?.dailyResumes,
            };

            const patchOperation: JsonPatchOperation = {
              op: 'replace',
              path: '/privateSettings/exports',
              value: finalValue,
            };

            const patchOperations = [patchOperation];

            if (patchOperations.length) {
              const patch: AppEntityUpdatePatch = {
                pk: diveCenterResume._id,
                patchOperations,
              };
              const payload: ProMultiOperationPayload =
                clubMassiveUpdatorClient.createEmptyPayload({
                  logContext: 'edit daily export config',
                  bookingIdsToClean: [],
                });
              payload.diveCenters.updated.push(patch);
              // if (props?.onUpdate) {
              //   props?.onUpdate({
              //     bookingDeposit: formValue.bookingDeposit as BookingDeposit,
              //     patch,
              //   });
              // } else {
              // }

              await clubMassiveUpdatorClient
                .update(payload)
                .then(() => {
                  return onConfirm({
                    formValue,
                  });
                })
                .catch((err) => {
                  setOperationInProgressMessage(undefined);

                  uiStore.snackbarMessage.set({
                    type: 'error',
                    content:
                      'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
                  });
                });
            } else {
              return closeDialog();
            }
          } else {
            return closeDialog();
          }
        },
        (err) => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
          throw err;
        },
      )();
    } catch (err) {
      // message already displayed
    }
  }, [
    diveCenterResume._id,
    diveCenterResume.privateSettings?.exports?.dailyResumes,
    handleSubmit,
    inputState,
    localState.hasFormChanges,
    setOperationInProgressMessage,
  ]);
}
