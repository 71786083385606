/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveGazType } from '@mabadive/app-common-model';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useGazTypeOptions } from 'src/pages/_components/options';
import { ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions } from './ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions';
import { ClubSettingsCustomerFormFieldsEditForm_Booking } from './ClubSettingsCustomerFormFieldsEditForm_Booking';
import { ClubSettingsCustomerFormFieldsEditForm_CustomerContact } from './ClubSettingsCustomerFormFieldsEditForm_CustomerContact';
import { ClubSettingsCustomerFormFieldsEditForm_CustomerMember } from './ClubSettingsCustomerFormFieldsEditForm_CustomerMember';
import { ClubSettingsCustomerFormFieldsEditForm_Equipment } from './ClubSettingsCustomerFormFieldsEditForm_Equipment';
import { ClubSettingsCustomerFormFieldsEditForm_Participant } from './ClubSettingsCustomerFormFieldsEditForm_Participant';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm = ({
  localState,
  className,
}: {
  localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
  className?: string;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const clubResume = useClubResume();
  const generalSettings = clubResume.clubSettings?.general;
  const customerSettings = clubResume?.clubSettings?.customer;

  const diveGazTypeOptions: ValueLabel<DiveGazType>[] = useGazTypeOptions();

  const az = useAppSecurityUserClubAuthorizations();

  const [
    generalExternalRef,
    generalExternalRefLabel,
    generalCustomField1,
    generalCustomField1Label,
    generalCustomField2,
    generalCustomField2Label,
    generalCustomField3,
    generalCustomField3Label,
    bookingExternalRef,
    bookingExternalRefLabel,
    bookingCustomField1,
    bookingCustomField1Label,
    bookingCustomField2,
    bookingCustomField2Label,
    bookingCustomField3,
    bookingCustomField3Label,
  ] = useWatch({
    control,
    name: [
      'clubSettings.customer.general.externalRef',
      'clubSettings.customer.general.externalRefLabel',
      'clubSettings.customer.general.customField1',
      'clubSettings.customer.general.customField1Label',
      'clubSettings.customer.general.customField2',
      'clubSettings.customer.general.customField2Label',
      'clubSettings.customer.general.customField3',
      'clubSettings.customer.general.customField3Label',
      'clubSettings.customer.booking.externalRef',
      'clubSettings.customer.booking.externalRefLabel',
      'clubSettings.customer.booking.customField1',
      'clubSettings.customer.booking.customField1Label',
      'clubSettings.customer.booking.customField2',
      'clubSettings.customer.booking.customField2Label',
      'clubSettings.customer.booking.customField3',
      'clubSettings.customer.booking.customField3Label',
    ],
  });

  return (
    <div className={className}>
      <div className="grid xl:grid-cols-2 gap-4 xl:justify-start xl:items-start">
        <div className="w-full grid gap-4 items-start">
          <ClubSettingsCustomerFormFieldsEditForm_CustomerContact
            localState={localState}
          />
          <ClubSettingsCustomerFormFieldsEditForm_CustomerMember
            localState={localState}
          />
          <ClubSettingsCustomerFormFieldsEditFormCustomerOriginOptions
            form={form}
          />
        </div>
        <div className="w-full grid gap-4 items-start">
          <ClubSettingsCustomerFormFieldsEditForm_Participant
            localState={localState}
          />
          <ClubSettingsCustomerFormFieldsEditForm_Booking
            localState={localState}
          />
          <ClubSettingsCustomerFormFieldsEditForm_Equipment
            localState={localState}
          />
        </div>
      </div>
    </div>
  );
};
