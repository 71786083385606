/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { DiveSessionEditorDialogLocalState } from '../../useDiveSessionEditorDialogLocalState.hook';
import { DiveSessionDialogTab3GroupsPanel1Participants } from './DiveSessionDialogTab3GroupsPanel1Participants';
import { DiveSessionDialogTab3GroupsPanel2SessionGroups } from './DiveSessionDialogTab3GroupsPanel2SessionGroups';
import { DiveSessionDialogTab3GroupsPanel3StaffMembers } from './DiveSessionDialogTab3GroupsPanel3StaffMembers';
import { DiveSessionDialogTab3GroupsPanel4StaffSecurity } from './DiveSessionDialogTab3GroupsPanel4StaffSecurity';

export const DiveSessionDialogTab3Groups: FC<{
  localState: DiveSessionEditorDialogLocalState;
}> = ({ localState }) => {
  return (
    <div className="px-2">
      <div className="flex flex-col lg:flex-row-reverse gap-2">
        <div className="lg:w-1/2 ">
          <DiveSessionDialogTab3GroupsPanel1Participants
            localState={localState}
          />
          <DiveSessionDialogTab3GroupsPanel3StaffMembers
            className="hidden lg:block"
            localState={localState}
          />
          <DiveSessionDialogTab3GroupsPanel4StaffSecurity
            className="hidden lg:block"
            localState={localState}
          />
        </div>
        <DiveSessionDialogTab3GroupsPanel2SessionGroups
          className="lg:w-1/2 flex flex-col gap-1"
          localState={localState}
        />
        <DiveSessionDialogTab3GroupsPanel3StaffMembers
          className="lg:hidden"
          localState={localState}
        />
        <DiveSessionDialogTab3GroupsPanel4StaffSecurity
          className="lg:hidden"
          localState={localState}
        />
      </div>
    </div>
  );
};
