/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  AppComboBoxMultiple,
  AppFormControlV2,
  AppInputBooleanSwitch,
  AppSingleAutocomplete2,
} from 'src/lib/form';
import { useBookingAgenciesOptions } from 'src/pages/_components/options';
import { AppInputDatePickerMaterial } from 'src/stories/components/04-form';
import { ClubBookingsListPageLocalState } from '../useClubBookingsListPageLocalState.hook';

export const ClubBookingsListPageConfigBarExtraFilters = ({
  localState,
  className,
}: {
  localState: ClubBookingsListPageLocalState;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const customerSettings = diveCenterResume?.clubResume?.clubSettings?.customer;
  const agenciesOptions = useBookingAgenciesOptions();

  const { filters } = localState;

  const autoFocus = useAutoFocus();
  const {
    searchText,
    setSearchText,
    bookingStatuses,
    setBookingStatuses,
    bookingGroupTypes,
    setBookingGroupTypes,
    bookingStatusOptions,
    bookingGroupTypesOptions,
    arrivalDate,
    setArrivalDate,
    departureDate,
    setDepartureDate,
    bookingAgencyId,
    setBookingAgencyId,
    archivedExclusive,
    setIncludeArchived,
  } = filters;
  return (
    <div className="flex flex-col gap-2">
      <input
        className="xs:hidden mx-1 hover:border-app-blue rounded px-2"
        autoFocus={autoFocus}
        autoComplete="new-password"
        placeholder="Recherche par nom, prénom..."
        type="text"
        defaultValue={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <div
        className={clsx(
          'grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:flex lg:flex-wrap gap-x-2 gap-y-1',
          className,
        )}
      >
        <AppFormControlV2
          className="sm:col-span-1 bg-white px-2"
          label="Statut"
          renderComponent={() => (
            <AppComboBoxMultiple
              value={bookingStatuses}
              options={bookingStatusOptions}
              onChange={(value) => setBookingStatuses(value)}
            />
          )}
        />
        <AppFormControlV2
          className="sm:col-span-1 bg-white px-2"
          label="Type"
          renderComponent={() => (
            <AppComboBoxMultiple
              value={bookingGroupTypes}
              options={bookingGroupTypesOptions}
              onChange={(value) => setBookingGroupTypes(value)}
            />
          )}
        />
        <AppFormControlV2
          className="sm:col-span-1 bg-white px-2"
          label="Archivées"
          renderComponent={() => (
            <AppInputBooleanSwitch
              value={archivedExclusive}
              onChange={(value) => setIncludeArchived(value)}
            />
          )}
        />
        <AppFormControlV2
          className="bg-white px-2"
          label="Début de séjour"
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-full"
              value={arrivalDate}
              onChange={(arrivalDate) => setArrivalDate(arrivalDate)}
            />
          )}
        />
        <AppFormControlV2
          className="bg-white px-2"
          label="Fin de séjour"
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-full"
              value={departureDate}
              onChange={(departureDate) => setDepartureDate(departureDate)}
            />
          )}
        />
        {generalSettings?.agencies?.enabled &&
          customerSettings?.booking?.agency && (
            <AppFormControlV2
              className="w-full lg:w-72 bg-white px-2"
              label="Agence"
              renderComponent={() => (
                <AppSingleAutocomplete2
                  className="w-full"
                  value={bookingAgencyId}
                  options={agenciesOptions}
                  onChange={(agencyId) => setBookingAgencyId(agencyId)}
                />
              )}
            />
          )}
      </div>
    </div>
  );
};
