import { CompanyAccommodation } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

export function useAccomodationsEditPageLocalState({
  mode,
  accommodationId,
}: {
  mode: 'create' | 'edit';
  accommodationId?: string;
}) {
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const accommodation: CompanyAccommodation = useMemo(
    () =>
      mode === 'edit'
        ? diveCenterResume?.accommodations.find(
            (x) => x._id === accommodationId,
          )
        : undefined,
    [mode, diveCenterResume?.accommodations, accommodationId],
  );

  const initialFormValue: Partial<CompanyAccommodation> = useMemo(() => {
    if (mode === 'create') {
      const createInitialValue: Partial<CompanyAccommodation> = {
        color: '#555',
        colorText: '#fff',
        pickup: {
          enabled: false,
          offsetInMinutes: 0,
        },
      };
      return createInitialValue;
    } else if (accommodation) {
      const editInitialValue: Partial<CompanyAccommodation> = {
        ...accommodation,
      };
      return editInitialValue;
    }
  }, [mode, accommodation]);

  const form = useForm<CompanyAccommodation>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  return {
    mode,
    accommodation,
    form,
    initialFormValue,
  };
}

export type CompanyAccomodationEditPageLocalState = ReturnType<
  typeof useAccomodationsEditPageLocalState
>;
