import { diveSessionStatusFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { IoStar } from 'react-icons/io5';
import { diveSessionTitleBuilder } from 'src/business/club/modules/club-dive-session/services';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppEmojiIcon } from 'src/lib/form/components/AppEmojiPicker';
import { ClubSettingsPlanningViewDayDiveTourModel } from './model';

export const ClubSettingsPlanningViewWeekDiveTourName = function ({
  diveTour,
  className,
}: {
  diveTour: ClubSettingsPlanningViewDayDiveTourModel;
  className?: string;
}) {
  const statusClass = diveTour.isOpen ? '' : 'px-1 bg-gray-500 text-white';
  const isMultiSession = !!diveTour.session2;

  const nameClass = diveTour.session1.special ? 'text-app-orange' : '';

  const nameIconClass = diveTour.session1.special
    ? 'text-app-orange'
    : 'text-gray-500';

  const diveSiteClasses = diveTour.session1.special ? 'text-app-orange' : '';
  const diveSiteIconClass = diveTour.session1.special
    ? 'text-app-orange'
    : 'text-gray-500';

  const diveSite = diveTour.session1.diveSite;
  const diveSite2 = diveTour.session2?.diveSite;

  const displayStatus = !diveTour.isOpen;
  const displayDiveSite1 = !!diveSite;
  const displayDiveSite2 = isMultiSession && !!diveSite2;
  const displayDiveSiteAny = displayDiveSite1 || displayDiveSite2;

  const sessionTitle = useMemo(
    () =>
      diveSessionTitleBuilder.build({
        name: diveTour.session1.name,
        theme: diveTour.theme,
        type: diveTour.type,
        special: diveTour.session1.special,
      }),
    [
      diveTour.session1.name,
      diveTour.session1.special,
      diveTour.theme,
      diveTour.type,
    ],
  );

  const displayName = !!sessionTitle;

  const displayMultiSessionDefaultName = isMultiSession && !displayDiveSiteAny;

  const displayAny =
    displayStatus ||
    displayDiveSiteAny ||
    displayName ||
    displayMultiSessionDefaultName;
  return displayAny ? (
    <div className={className}>
      {displayName && (
        <div className={`flex items-center overflow-hidden ${nameClass}`}>
          <span className={'ml-0.5 sm:ml-1'}>
            {diveTour.session1.emojiId ? (
              <AppEmojiIcon id={diveTour.session1.emojiId} size={14} />
            ) : (
              <IoStar className={`w-3 h-3 ${nameIconClass}`} />
            )}
          </span>
          <div className={'ml-0.5 sm:ml-1  truncate'}>
            {sessionTitle.toUpperCase()}
          </div>
        </div>
      )}
      {displayMultiSessionDefaultName && (
        <div className={'flex items-center overflow-hidden text-gray-600'}>
          <span className={'ml-0.5 sm:ml-1'}>
            <IoStar className={`w-3 h-3 ${nameIconClass}`} />
          </span>
          <div className={'ml-0.5 sm:ml-1  truncate'}>
            {'DOUBLE-BLOCK'.toUpperCase()}
          </div>
        </div>
      )}
      {displayDiveSite1 && (
        <div className={`flex items-center overflow-hidden ${diveSiteClasses}`}>
          <span className={'ml-0.5 sm:ml-1 flex items-center gap-1'}>
            <AppHeroIcons.diveSite className={`w-3 h-3 ${diveSiteIconClass}`} />
            <AppEmojiIcon id={diveSite.emojiId} size={14} />
            {displayDiveSite2 && <span>N°1</span>}
          </span>
          <div className={'ml-0.5 sm:ml-1 overflow-hidden truncate'}>
            {diveSite.name.toUpperCase()}
          </div>
        </div>
      )}
      {displayDiveSite2 && (
        <div className={`flex items-center overflow-hidden ${diveSiteClasses}`}>
          <span className={'ml-0.5 sm:ml-1 flex items-center gap-1'}>
            <AppHeroIcons.diveSite className={`w-3 h-3 ${diveSiteIconClass}`} />
            <AppEmojiIcon id={diveSite2.emojiId} size={14} />
            {displayDiveSite1 && <span>N°2</span>}
          </span>
          <div className={'ml-0.5 sm:ml-1 overflow-hidden truncate'}>
            {diveSite2.name.toUpperCase()}
          </div>
        </div>
      )}
      {displayStatus && (
        <div className={`inline-block ml-0.5 sm:ml-1 ${statusClass}`}>
          {diveSessionStatusFormatter.formatStatus('off')?.toUpperCase()}
        </div>
      )}
    </div>
  ) : null;
};
