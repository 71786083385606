import {
  changeDescriptorManager,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { staffMemberFormResultBuilder } from 'src/pages/SE-settings/SE-01_dive-center/staff-members/StaffMemberEditPage/StaffMemberEditForm';
import {
  ClubResumeStaffMemberFormModel,
  StaffMemberFormLocalState,
} from 'src/pages/SE-settings/SE-01_dive-center/staff-members/StaffMemberEditPage/model';
import { DiveSessionEditorUpdateState } from '../../model';

export const diveSessionDialogTab2StaffMemberEditStateUpdator = {
  updateStaffMember,
};

function updateStaffMember({
  updateState,
  staffMemberFormLocalState,
  formValue,
}: {
  updateState: DiveSessionEditorUpdateState;
  staffMemberFormLocalState: StaffMemberFormLocalState;
  formValue: ClubResumeStaffMemberFormModel;
}): DiveSessionEditorUpdateState {
  const { initialFormValue, staffMember } = staffMemberFormLocalState;

  const value = staffMemberFormResultBuilder.buildResult(formValue);
  const patchOperations = jsonPatcher.compareObjects(initialFormValue, value, {
    attributesToReplaceFully: [
      'availabilities',
      'profile',
      'scubaDivingInstructor',
      'equipment'
    ],
  });
  if (patchOperations.length) {
    const localUpdateState: DiveSessionEditorUpdateState = {
      ...updateState,
      hasChanges: true,
      staffMembersChanges: changeDescriptorManager.updateOne(
        {
          pk: staffMember._id,
          patchOperations,
        },
        {
          changeDescriptors: updateState.staffMembersChanges,
        },
      ),
    };
    return localUpdateState;
  }
  return updateState;
}
