import {
  ProStatsFetchCriteria,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { appLogger } from 'src/business/_core/modules/root/logger';

export const proStatsApiClient = {
  fetch,
};

async function fetch(
  fetchCriteria: ProStatsFetchCriteria,
): Promise<ProStatsFetchResults> {
  try {
    const results = await apiClient
      .post<ProStatsFetchResults>('/pro/stats', {
        authenticate: true,
        json: {
          fetchCriteria,
        },
      })
      .toPromise();

    if (appWebConfig.envId === 'dev') {
      appLogger.warn('[proStatsApiClient] DASHBOARD FETCH RESULT:', results);
    }

    return results;
  } catch (err) {
    appLogger.warn('[proStatsApiClient] error fetching data', err);
    throw err;
  }
}
