import { DashboardPurchasesFirstDivesOverPeriod } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';

import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';

export const DashboardReportPageGlobalActivityPurchasesFirstDivesOverPeriodTableRow =
  ({
    firstDive,
    comparisonDetails,
    className,
  }: {
    firstDive: DashboardPurchasesFirstDivesOverPeriod;
    comparisonDetails: DashboardReportComparisonDetails<any>;
    className?: string;
  }) => {
    const mainCurrency = useAppCurrencyMain();

    const { localState, showPeriodRangeComparison } = comparisonDetails;

    const selectedFirstDive = localState.state.uiConfig.selection?.firstDive;
    const hoverFirstDive = localState.state.uiConfig.hover?.firstDive;

    const isSelected = selectedFirstDive?.offerName === firstDive.offerName;

    const isHover = hoverFirstDive?.offerName === firstDive.offerName;

    return (
      <tr
        className={clsx(
          isSelected ? 'app-tr-selected' : isHover && 'app-tr-highlighted',
          className,
        )}
        onMouseEnter={() =>
          localState.state.setHover({
            firstDive: {
              offerName: firstDive.offerName,
            },
          })
        }
        onMouseLeave={() => localState.state.setHover()}
        onClick={() =>
          localState.state.setSelection({
            firstDive: {
              offerName: firstDive.offerName,
            },
          })
        }
      >
        <th
          className={clsx(
            'truncate text-left whitespace-nowrap px-2 w-[50%]',
            firstDive.purchasePackageCount === 0 ? 'text-gray-400' : '',
          )}
        >
          <div className="flex flex-wrap items-end gap-x-1 xl:gap-x-1.5 text-xs md:text-sm pt-1">
            <span
              className={clsx(
                'font-medium uppercase',
                showPeriodRangeComparison ? 'text-app-xxs md:text-xs' : '',
              )}
            >
              {firstDive.offerName ?? 'Baptême'}
            </span>
          </div>
        </th>
        <td
          className={clsx(
            'text-app-primary text-right text-lg px-4 whitespace-nowrap font-bold',
            firstDive.purchasePackageCount === 0 ? 'opacity-30' : '',
          )}
        >
          {firstDive.purchasePackageCount}{' '}
          <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
        </td>
        <td
          className={clsx(
            'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
            firstDive.purchasePackagePriceAvg === 0 ? 'opacity-30' : '',
            showPeriodRangeComparison
              ? 'hidden xl:table-cell'
              : 'hidden lg:table-cell',
          )}
        >
          <AppPriceLabel
            amount={firstDive.purchasePackagePriceAvg}
            mainCurrency={mainCurrency}
          />
        </td>
        <td
          className={clsx(
            'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
            firstDive.purchasePackagePriceSum === 0 ? 'opacity-30' : '',
          )}
        >
          <AppPriceLabel
            amount={firstDive.purchasePackagePriceSum}
            mainCurrency={mainCurrency}
          />
        </td>
        <td
          className={clsx(
            'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
            firstDive.purchasePackagePayedAmount === 0 ? 'opacity-30' : '',
            showPeriodRangeComparison
              ? 'hidden xl:table-cell'
              : 'hidden lg:table-cell',
          )}
        >
          <AppPriceLabel
            amount={firstDive.purchasePackagePayedAmount}
            mainCurrency={mainCurrency}
          />
        </td>
      </tr>
    );
  };
