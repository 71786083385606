/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubEventCardGroup } from '../../../club-events/ClubEventCard';
import { PlanningViewConfig } from '../../../club-planning/_model';
import { ClubPlanningDisplayConfigurationBarContext } from '../../club-planning-navigation';
import { ClubPlanningLightViewDaySessions } from '../../components';
import { SetPlanningConfigOptions } from '../../hooks';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
} from '../../model';
import { ClubPlanningLightPanelViewPeriodDaySessionsList } from './ClubPlanningLightPanelViewPeriodDaySessionsList';
import { ClubPlanningLightPanelViewPeriodDayStaffMembersList } from './ClubPlanningLightPanelViewPeriodDayStaffMembersList';

export const ClubPlanningLightPanelViewPeriodDayStaff = ({
  context,
  day,
  planningConfig: pci,
  setPlanningConfig,
  clickActions,
  sessionsDisplayConfigs = [],
  className,
}: {
  context: ClubPlanningDisplayConfigurationBarContext;
  day: ClubPlanningDayFull;
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  clickActions: ClubPlanningLightPanelViewClickActions;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  className?: string;
}) => {
  const { onClickEvent } = clickActions;

  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const diveCenterResume = useDiveCenterResume();

  const dailySessions = day.sessions;

  const staffMembersResumeSessions = useMemo(
    () =>
      day.staffMembersResumeSessions.filter(
        (x) => x.activeOnAnySession || x.availableOnAnySession,
      ),
    [day.staffMembersResumeSessions],
  );

  const planningConfig = useMemo(() => {
    const planningConfig: PlanningViewConfig = {
      ...pci,
      viewDayOptions: {
        ...pci.viewDayOptions,
        // displayMode: 'staff-sessions',
      },
    };
    return planningConfig;
  }, [pci]);

  return (
    <div className={className}>
      {day.clubEvents.length > 0 && (
        <div className="mb-2 lg:mb-5">
          <ClubEventCardGroup
            clubEvents={day.clubEvents}
            widthMode={'large'}
            onClick={onClickEvent}
          />
        </div>
      )}
      {mode === 'view' ? (
        <div className="grid gap-4">
          {planningConfig?.viewDayOptions?.displayMode === 'staff-sessions' && (
            <ClubPlanningLightViewDaySessions
              context={context}
              day={day}
              clickActions={clickActions}
              planningConfig={planningConfig}
              sessionsDisplayConfigs={sessionsDisplayConfigs}
            />
          )}

          <div className="">
            {/* <h3 className="my-4 font-medium text-base text-app-gray-400 uppercase">
              Récap par moniteur
            </h3> */}
            {planningConfig?.viewDayOptions?.displayMode === 'staff-roles' && (
              <div>
                {staffMembersResumeSessions.length > 0 ? (
                  <ClubPlanningLightPanelViewPeriodDayStaffMembersList
                    showStaffMember={true}
                    showDate={false}
                    planningConfig={planningConfig}
                    dailySessions={dailySessions}
                    staffMembersDailySessions={staffMembersResumeSessions}
                    clickActions={clickActions}
                  />
                ) : (
                  <AppMessageLight>Aucun moniteur</AppMessageLight>
                )}
              </div>
            )}
          </div>
        </div>
      ) : mode === 'edit' ? (
        // NOTE: pas encore supporté
        <div className="flex gap-2 justify-between items-start">
          <ClubPlanningLightPanelViewPeriodDaySessionsList
            className="flex-grow"
            day={day}
            planningConfig={planningConfig}
            dailySessions={dailySessions}
            staffMembersDailySessions={staffMembersResumeSessions}
          />
          <ClubPlanningLightPanelViewPeriodDayStaffMembersList
            showDate={false}
            showStaffMember={true}
            planningConfig={planningConfig}
            dailySessions={dailySessions}
            staffMembersDailySessions={staffMembersResumeSessions}
            clickActions={clickActions}
          />
        </div>
      ) : null}
    </div>
  );
};
