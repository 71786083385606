/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubProductPackageOfferView } from '@mabadive/app-common-model';
import {
  ClubProductPackageOfferViewSortByAttribute,
  clubProductPackageOfferViewSorter,
} from '@mabadive/app-common-services';
import { TableSortLabel } from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  CollectionOrder,
  useCollectionOrder,
} from 'src/business/_core/modules/layout/components/PageableTable';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ClubOfferTrainingListPageState } from '../ClubOfferTrainingListPageState.type';
import { ClubOfferTrainingTableRow } from './ClubOfferTrainingTableRow';

export const ClubOfferTrainingTable = ({
  productPackageOffers,
  onOrderChanged,
  setState,
  onDelete,
}: {
  productPackageOffers: ClubProductPackageOfferView[];
  onOrderChanged?: (
    order: CollectionOrder<ClubProductPackageOfferViewSortByAttribute>,
  ) => void;
  setState: (state: ClubOfferTrainingListPageState) => void;
  onDelete: (offer: ClubProductPackageOfferView) => void;
}) => {
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = useClubSettings();
  const [order, sortByColumn] =
    useCollectionOrder<ClubProductPackageOfferViewSortByAttribute>(
      {
        column: 'training',
        asc: true,
      },
      {
        reverseColumns: [],
        onOrderChanged,
      },
    );

  const productPackageOffersSorted = useMemo(
    () =>
      clubProductPackageOfferViewSorter.sortOffersBy(productPackageOffers, {
        attributeName: order.column,
        asc: order.asc,
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      }),
    [
      clubSettings?.ui?.diveMode,
      order.asc,
      order.column,
      productPackageOffers,
      publicSettings,
    ],
  );

  return (
    <div>
      <table className={'divide-y divide-gray-200 text-gray-600'}>
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              onClick={() => sortByColumn('name')}
            >
              <TableSortLabel
                active={order.column === 'name'}
                direction={order.asc ? 'asc' : 'desc'}
                onClick={() => sortByColumn('name')}
              >
                Nom
              </TableSortLabel>
            </th>
            <th
              scope="col"
              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Tarif
            </th>
            <th
              scope="col"
              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Caractéristiques
            </th>
            <th
              scope="col"
              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Plongées (+ théorie)
            </th>
            <th
              scope="col"
              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Age
            </th>
            <th scope="col" className="py-1"></th>
          </tr>
        </thead>
        {productPackageOffersSorted.map((offer, i) => (
          <ClubOfferTrainingTableRow
            key={`${i}`}
            className={i % 2 === 0 ? undefined : 'bg-gray-50'}
            offer={offer}
            onDelete={() => onDelete(offer)}
            setState={setState}
          />
        ))}
      </table>
    </div>
  );
};
