import { AppUiTheme } from '@mabadive/app-common-model';
import React from 'react';
import { RenderLeafProps } from 'slate-react';

export function AppRichTextSlateLeaf<A>({
  attributes,
  children,
  leaf,
  theme,
}: Omit<RenderLeafProps, 'attributes'> & {
  attributes?: A;
  theme: AppUiTheme;
}) {
  if (leaf.bold) {
    children = (
      <strong
      // className="text-gray-800"
      >
        {children}
      </strong>
    );
  }

  // if (leaf.code) {
  //   children = <code>{children}</code>;
  // }

  if (leaf.italic) {
    children = (
      <em
      // className="text-gray-800"
      >
        {children}
      </em>
    );
  }

  // if (leaf.underline) {
  //   children = <u>{children}</u>;
  // }

  return <span {...attributes}>{children}</span>;
}
