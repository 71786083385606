/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';

import {
  ClubSettingsOnlineBookingDialogInitialState,
  ClubSettingsOnlineBookingFormModel,
} from '../../../_model';
import { ClubSettingsOnlineBookingMainForm_ConfigGroups } from './ClubSettingsOnlineBookingMainForm_ConfigGroups';
import { ClubSettingsOnlineBookingMainForm_General } from './ClubSettingsOnlineBookingMainForm_General';
import { ClubSettingsOnlineBookingMainForm_SessionConfig } from './ClubSettingsOnlineBookingMainForm_SessionConfig';

export const ClubSettingsOnlineBookingMainForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsOnlineBookingFormModel>;
  initialState: ClubSettingsOnlineBookingDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const [onlineBookingEnabled] = useWatch({
    control,
    name: ['clubSettings.general.onlineBooking.enabled'],
  });

  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsOnlineBookingMainForm_General
        form={form}
        initialState={initialState}
      />

      {onlineBookingEnabled && (
        <>
          <ClubSettingsOnlineBookingMainForm_SessionConfig
            form={form}
            initialState={initialState}
          />
          <ClubSettingsOnlineBookingMainForm_ConfigGroups
            form={form}
            initialState={initialState}
          />
        </>
      )}
    </div>
  );
};
