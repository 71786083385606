import { ClubSettings } from '@mabadive/app-common-model';
import { diveModeColorClassBuilder } from '@mabadive/app-common-services';
import { useMemo } from 'react';

export type DashboardStatsDiveModeColors = {
  totalCount: string;
  cancelCountDiver: string;
  cancelCountClub: string;
  firstDiveCount: string;
  trainingCount: string;
  recreationalAutonomousCount: string;
  recreationalSupervisedCount: string;
  recreationalInstructorsCount: string;
  recreationalTotalCount: string;
  autoSupervisedCount: string;
  watchingTourCount: string;
  otherCount: string;
};

export function useDashboardStatsDiveModeColors(
  clubSettings: ClubSettings,
): DashboardStatsDiveModeColors {
  return useMemo(() => {
    return {
      totalCount: '#197297', // app-blue (tailwind)
      firstDiveCount: diveModeColorClassBuilder.getDiveModeColor('first-dive', {
        clubSettings,
      }),
      trainingCount: diveModeColorClassBuilder.getDiveModeColor('training', {
        clubSettings,
      }),
      recreationalAutonomousCount: diveModeColorClassBuilder.getDiveModeColor(
        'autonomous',
        { clubSettings },
      ),
      recreationalSupervisedCount: diveModeColorClassBuilder.getDiveModeColor(
        'supervised',
        { clubSettings },
      ),
      recreationalInstructorsCount: diveModeColorClassBuilder.getDiveModeColor(
        'instructor',
        { clubSettings },
      ),
      recreationalTotalCount: '#4b5563', // gray-600
      autoSupervisedCount: diveModeColorClassBuilder.getDiveModeColor(
        'autoSupervised',
        { clubSettings },
      ),
      watchingTourCount: diveModeColorClassBuilder.getDiveModeColor(
        'watchingTour',
        { clubSettings },
      ),
      otherCount: diveModeColorClassBuilder.getDiveModeColor('snorkeling', {
        clubSettings,
      }),
      cancelCountDiver: '#b52206', // status-error (tailwind)
      cancelCountClub: '#610505', // status-error (tailwind)
    };
  }, [clubSettings]);
}
