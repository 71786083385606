/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import {
  AppButton,
  AppPageContentContainer,
  AppTabsTailwind,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIcons,
} from 'src/business/_core/modules/layout/icons';

import { useRedirect } from 'src/business/_core/data/hooks';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { useClubSettings } from 'src/business/club/data/hooks';
import { ClubSettingsCommunicationPageLocalState } from '../../useClubSettingsCommunicationPageLocalState.hook';

export const ClubSettingsCommunicationPageBasePanel = ({
  localState,
  children,
  onClick,
}: {
  localState: ClubSettingsCommunicationPageLocalState;
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
}) => {
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  return (
    <AppPageContentContainer
      paddingBottom={false}
      className="bg-gray-50 app-p-content"
    >
      <AppTabsTailwind
        className="w-full"
        tabs={state.tabs.data}
        selected={selectedTab}
        theme="underline"
        breakpoint="sm"
        onChange={(tabId) => state.tabs.setSelected(tabId)}
      />
      <div
        className={clsx('mt-4 app-card p-4', onClick && 'app-card-highlight')}
        onClick={onClick}
      >
        {children}

        {state.editMode === 'super-admin-only' ? (
          <div className="my-8 text-gray-500 flex md:flex-col flex-wrap gap-4 justify-between items-start">
            <p>Pour modifier la configuration, veuillez nous contacter:</p>

            <AppButton
              className=""
              color="primary-outline-light"
              icon={AppHeroIcons.contact}
              onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
            >
              Contactez-nous
            </AppButton>
          </div>
        ) : state.editEnabled && onClick ? (
          <div className="mt-8 mb-2">
            <AppButton
              color={'primary-outline-light'}
              size="normal"
              icon={AppIcons.action?.edit} 
            >
              Modifier
            </AppButton>
          </div>
        ) : null}
      </div>
    </AppPageContentContainer>
  );
};
