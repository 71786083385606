import { Popover, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { usePopper } from 'react-popper';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

const COLOR_REGEXP = /^#[A-Fa-f0-9]{6}$/;

export function AppInputColor({
  value,
  onChange,
  label,
  error,
  required,
  className,
  clearable,
  ...extraProps
}: {
  className?: string;
  clearable?: boolean;
} & {
  value?: string;
  onChange: (color: string) => void;
  label?: string;
  error?: boolean;
} & Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'type' | 'onChange'
  >) {
  const classNameInput = `focus:outline-none shadow-sm ${
    error
      ? 'focus:border-gray-200 focus-visible:border-gray-200 text-status-error'
      : 'focus:border-gray-200 text-app-primary'
  } block w-full px-2 py-1.5 border border-gray-300 rounded-md`;

  const id = `appinputcolor-${Math.random() * 10000}`;

  const [isOpen, setIsOpen] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });

  return (
    <div className={`w-full relative focus:outline-none ${className ?? ''}`}>
      {label && (
        <label
          className="mb-1 text-gray-400 font-bold text-xs block text-left"
          htmlFor={id}
        >
          {label} {required && <span className="text-red-400">*</span>}
        </label>
      )}
      <div className="flex">
        <Popover as="nav" className="bg-white">
          {({ open }) => (
            <>
              <div className="flex">
                <button
                  type="button"
                  ref={setReferenceElement}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  className="p-1 inline-flex items-center justify-center bg-gray-600 text-gray-200 hover:text-white hover:bg-gray-800 focus:outline-none"
                >
                  <span className="sr-only">Ouvrir le menu</span>
                  {isOpen ? (
                    <AppHeroIcons.actionClose
                      className="block h-7 w-7"
                      aria-hidden="true"
                      style={{ background: value }}
                    />
                  ) : (
                    <div
                      className="h-7 w-7"
                      style={{ background: value }}
                    ></div>
                  )}
                </button>
              </div>
              <Transition show={isOpen}>
                <Popover.Overlay
                  style={{
                    zIndex: 1500,
                  }}
                  className={`${
                    isOpen
                      ? 'opacity-0 fixed top-0 bottom-0 left-0 right-0'
                      : 'opacity-0'
                  } bg-black`}
                >
                  <div
                    className="fixed top-0 bottom-0 left-0 right-0"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  ></div>
                </Popover.Overlay>
                <Popover.Panel
                  static
                  // tabIndex={-1}
                  ref={setPopperElement}
                  style={{
                    ...(styles.popper ?? {}),
                    zIndex: 1501, // au dessus de l'overlay
                  }}
                  {...(attributes.popper ?? {})}
                  className="absolute z-50 right-0 z-10 mt-1 px-1 sm:px-0"
                >
                  <div
                    className={`rounded-lg border border-gray-400 shadow-lg ring-1 ring-gray-600 ring-opacity-20
                    ${attributes.popper?.className ?? ''}
                `}
                  >
                    <HexColorPicker
                      color={value ?? ''}
                      onChange={(color) => {
                        if (COLOR_REGEXP.test(color)) {
                          onChange(color); // valid color
                        } else if (!COLOR_REGEXP.test(value)) {
                          onChange('#544f93'); // default color
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        <input
          {...extraProps}
          type={'text'}
          className={classNameInput}
          id={id}
          required={required}
          value={value}
          onChange={(e) => {
            const newValue = e?.target?.value;
            if (value !== newValue) {
              onChange(newValue);
            }
          }}
        />
      </div>
    </div>
  );
}
