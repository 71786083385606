import { TimeHoursMinutes } from '@mabadive/app-common-model';
import { numberFormatter } from '@mabadive/app-common-services';
import React from 'react';

export const AppTimeHoursMinutesLabel = ({
  time,
  className,
}: {
  time: TimeHoursMinutes;
  className?: string;
}) => {
  return time?.hours >= 0 && time?.hours < 24 ? (
    <span className={`inline-block whitespace-nowrap ${className}`}>
      <span>
        {numberFormatter.toFixedIntegerString(time.hours, 2)}:
        {numberFormatter.toFixedIntegerString(time.minutes, 2)}
      </span>
    </span>
  ) : null;
};
