/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';

import {
  ClubSettingsCommunicationDialogInitialState,
  ClubSettingsCommunicationFormModel,
} from '../../../_model';
import { ClubSettingsCommunicationMain_InfoMessage } from '../_common';
import { ClubSettingsCommunicationMainForm_Booking } from './ClubSettingsCommunicationMainForm_Booking';
import { ClubSettingsCommunicationMainForm_Channels } from './ClubSettingsCommunicationMainForm_Channels';
import { ClubSettingsCommunicationMainForm_Customer } from './ClubSettingsCommunicationMainForm_Customer';
import { ClubSettingsCommunicationMainForm_GeneralOptions } from './ClubSettingsCommunicationMainForm_GeneralOptions';
import { ClubSettingsCommunicationMainForm_Session } from './ClubSettingsCommunicationMainForm_Session';

export const ClubSettingsCommunicationMainForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsCommunicationFormModel>;
  initialState: ClubSettingsCommunicationDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const enableBookingReminderAuto: boolean = useWatch({
    control,
    name: 'clubSettings.communication.booking.reminder.auto',
  });

  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsCommunicationMainForm_Channels
        form={form}
        initialState={initialState}
      />
      <ClubSettingsCommunicationMainForm_Booking
        form={form}
        initialState={initialState}
      />
      <ClubSettingsCommunicationMainForm_Session
        form={form}
        initialState={initialState}
      />
      <ClubSettingsCommunicationMainForm_Customer
        form={form}
        initialState={initialState}
      />
      <ClubSettingsCommunicationMainForm_GeneralOptions
        form={form}
        initialState={initialState}
      />
      <ClubSettingsCommunicationMain_InfoMessage />
    </div>
  );
};
