/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { AppDisclosure } from 'src/business/_core/modules/layout';
import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanSwitchRHF,
} from 'src/lib/form';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';
import { DECOP_SessionItemDive } from '../DailyExportConfigOptionsPanelSheetContentItemSessionItemDive';

export const DECOP_SessionItemDives = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  sessionItemIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  sessionItemIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}.items.${sessionItemIndex}` as const;

  const sessionItemsDivesFieldArray = useFieldArray({
    control,
    name: `${baseFormName}.items`,
    keyName: '_ref',
  });

  const [isEnabled] = useWatch({
    control,
    name: [`${baseFormName}.enabled`],
  });

  return (
    <div className={clsx('flex gap-2', className)}>
      <AppFormControlRHF_Deprecated
        control={control}
        name={`${baseFormName}.enabled`}
        renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
      />
      <div className="grid gap-2">
        <h4 className="text-left text-base font-medium text-gray-600">
          Plongées: {'  '}
          <span className="font-normal text-gray-500">
            détail de chaque plongée (plusieurs plongées si multi-tank)
          </span>
        </h4>
        {isEnabled && (
          <AppDisclosure
            defaultExpanded={false}
            className="w-full"
            title={
              <div className="flex-grow text-left text-sm text-gray-400 uppercase">
                Afficher les détails
              </div>
            }
          >
            <div className="grid gap-4">
              {sessionItemsDivesFieldArray.fields.map(
                (sessionItemDive, sessionItemDiveIndex) => (
                  <DECOP_SessionItemDive
                    key={sessionItemDive._ref}
                    localState={localState}
                    dailyResumeIndex={dailyResumeIndex}
                    sheetIndex={sheetIndex}
                    contentItemIndex={contentItemIndex}
                    sessionItemIndex={sessionItemIndex}
                    sessionItemDiveIndex={sessionItemDiveIndex}
                  />
                ),
              )}
            </div>
          </AppDisclosure>
        )}
      </div>
    </div>
  );
};
