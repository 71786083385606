import { ClubDiver } from '@mabadive/app-common-model';
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';

export function BillingParticipantPaymentResumeTableDiverName({
  diver,
  opacity,
  className,
}: {
  diver: Pick<ClubDiver, 'firstName' | 'lastName' | 'fakeName'> &
    Partial<
      Pick<ClubDiver, 'birthdate' | 'mainCertificationReference' | 'age'>
    >;
  opacity?: 60;
  className?: string;
}) {
  const age = useMemo(
    () =>
      diver?.birthdate ? dateService.getAge(diver?.birthdate) : diver?.age,
    [diver?.age, diver?.birthdate],
  );

  return (
    <div className={`block text-sm ${className ?? ''}`}>
      <p
        className={`font-bold truncate text-app-blue ${
          opacity === 60 ? 'text-opacity-60' : ''
        }`}
      >
        {nameFormatter.formatFullName(diver, { format: 'firstName-first' })}
        {diver?.mainCertificationReference && (
          <span
            className={`ml-1 text-gray-600 ${
              opacity === 60 ? 'text-opacity-60' : ''
            }`}
          >
            ({diver?.mainCertificationReference})
          </span>
        )}
      </p>
      {age > 0 && (
        <p>
          <span
            className={`text-sm font-bold text-gray-500 ${
              opacity === 60 ? 'text-opacity-60' : ''
            }`}
          >
            {age} ans
          </span>
          {/* <span className="ml-2 text-xs text-gray-500">
             {dateService.formatUTC(
               bookingMember.diver.birthdate,
               'DD/MM/YYYY',
             )}
           </span> */}
        </p>
      )}
    </div>
  );
}
