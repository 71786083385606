import { ClubStaffMember } from '@mabadive/app-common-model';
import { ClubResumeStaffMemberFormModel } from '../model';

export const staffMemberFormResultBuilder = { buildResult };

function buildResult(
  formValue: ClubResumeStaffMemberFormModel,
): Partial<ClubStaffMember> {
  const beginDate = Math.min(
    ...formValue.availabilities
      .map((x) => x.beginDate?.getTime())
      .filter((x) => !!x),
  );
  const endDate = Math.max(
    ...formValue.availabilities
      .map((x) => x.endDate?.getTime())
      .filter((x) => !!x),
  );
  const value: Partial<ClubStaffMember> = {
    ...formValue,
    beginDate: beginDate ? new Date(beginDate) : undefined,
    endDate: endDate ? new Date(endDate) : undefined,
  };
  return value;
}
