/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { AppPageBlock, AppVisible } from 'src/business/_core/modules/layout';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { usePageWidth } from 'src/business/_core/modules/layout/services/usePageWidth.hook';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { DiverBookingPageBookingCard } from '../../components/DiverBookingPageBookingCard';
import { BookingTabModel } from '../../models';
import {
  bookingTabModelBuilder,
  bookingTabMultiBookingsAutoFixSuggestionDetector,
} from '../../services/05-view-state';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageBookingTabHeaderAutoFix } from './DiverBookingPageBookingTabHeaderAutoFix';
import { DiverBookingPageBookingTabHeaderNoBooking } from './DiverBookingPageBookingTabHeaderNoBooking';

export const DiverBookingPageBookingTab = ({
  globalState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) => {
  const pageWidth = usePageWidth({ ignoreMenu: false });

  const {
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
    loadedContent,
    includeArchivedBookings,
    linkedData,
  } = globalState;

  const {
    openDiverSelector,
    openSessionDialog,
    openSessionSelector,
    createNewBooking,
    deleteDiverOnly,
  } = actions;

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterId = diveCenterResume?._id;

  // view model
  const bookingTabModels: BookingTabModel[] = useMemo(
    () =>
      bookingTabModelBuilder.buildBookingTabModel({
        loadedContent,
        aggregatedData,
        focus,
        diveCenterId,
        includeArchivedBookings,
      }),
    [
      loadedContent,
      aggregatedData,
      focus,
      diveCenterId,
      includeArchivedBookings,
    ],
  );

  const showExtraCreateButtonOnTop = useMemo(() => {
    if (isMultiDiveCenters && bookingTabModels.length !== 0) {
      const firstBooking = bookingTabModels[0];
      return (
        (firstBooking.bookingDiveCenterId !== diveCenterId &&
          !firstBooking.aggregatedBooking.booking?.isSharedBooking) ||
        !firstBooking.aggregatedBooking.booking?.active
      );
    }
    return false;
  }, [bookingTabModels, diveCenterId, isMultiDiveCenters]);

  const bookingTabMultiBookingsAutoFixSuggestions = useMemo(() => {
    // apply only on current center bookings
    const currentCenterBookingTabModels = bookingTabModels.filter(
      (x) => x.bookingDiveCenterId === diveCenterId,
    );
    return bookingTabMultiBookingsAutoFixSuggestionDetector.detectAll(
      currentCenterBookingTabModels,
    );
  }, [bookingTabModels, diveCenterId]);

  const enableAutoFix =
    !bookingTabMultiBookingsAutoFixSuggestions.hasSuggestions;

  const bookingsArchivedCount = linkedData?.bookingArchivedIds?.length;

  return (
    <AppPageBlock className="app-px-content grid gap-2">
      {bookingsArchivedCount > 0 && (
        <div className="flex justify-start gap-2 flex-wrap">
          <AppInputBooleanSwitch
            label={`réservations archivées (${bookingsArchivedCount})`}
            value={globalState.includeArchivedBookings}
            onChange={(value) =>
              globalState.setIncludeArchivedBookings(
                !globalState.includeArchivedBookings,
              )
            }
          />
        </div>
      )}
      <div className="block">
        <DiverBookingPageBookingTabHeaderNoBooking
          globalState={globalState}
          actions={actions}
          dialogs={dialogs}
          bookingTabModels={bookingTabModels}
        />
        <DiverBookingPageBookingTabHeaderAutoFix
          globalState={globalState}
          actions={actions}
          dialogs={dialogs}
          bookingTabMultiBookingsAutoFixSuggestions={
            bookingTabMultiBookingsAutoFixSuggestions
          }
        />

        {showExtraCreateButtonOnTop && (
          <AppVisible role="club-edit-participant">
            <div className="text-center">
              <AppButton
                icon={AppHeroIcons.actionAdd}
                color="primary-bg"
                onClick={() => {
                  createNewBooking();
                  setTimeout(() => window.scrollTo(0, 0), 100);
                }}
              >
                <div className="flex gap-2 items-center">
                  <span>Créer une nouvelle réservation</span>
                  {isMultiDiveCenters && (
                    <DiveCenterProBadge diveCenter={diveCenterResume} />
                  )}
                </div>
              </AppButton>
            </div>
          </AppVisible>
        )}
        {/* BOOKINGS */}
        {bookingTabModels.map((bookingTabModel, bookingIndex) => (
          <DiverBookingPageBookingCard
            key={bookingTabModel.bookingId}
            enableAutoFix={enableAutoFix}
            bookingTabModel={bookingTabModel}
            globalState={globalState}
            actions={actions}
            dialogs={dialogs}
            aggregatedData={aggregatedData}
            bookingTabModels={bookingTabModels}
            focus={focus}
            updateState={updateState}
            setUpdateState={setUpdateState}
            openSessionSelector={openSessionSelector}
            openSessionDialog={openSessionDialog}
            openDiverSelector={openDiverSelector}
            navigationContext={navigationContext}
          />
        ))}
      </div>

      {bookingTabModels.length !== 0 && (
        <>
          <div className="my-3">
            <div className="flex justify-start gap-8">
              {!globalState.includeArchivedBookings &&
                bookingsArchivedCount > 0 && (
                  <AppButton
                    icon={AppHeroIcons.eye}
                    color="gray-outline-light"
                    onClick={() => {
                      globalState.setIncludeArchivedBookings(true);
                    }}
                  >
                    Afficher les {bookingsArchivedCount} réservations archivées
                  </AppButton>
                )}
              <AppVisible role="club-edit-participant">
                <AppButton
                  icon={AppHeroIcons.actionAdd}
                  color="primary-bg"
                  onClick={() => {
                    createNewBooking();
                    setTimeout(() => window.scrollTo(0, 0), 100);
                  }}
                >
                  <span>Créer une nouvelle réservation</span>
                </AppButton>
              </AppVisible>
            </div>
          </div>
          <AppVisible role="club-edit-participant">
            <AppMessage
              className="hidden sm:block mb-5"
              title={'Instruction'}
              message={
                <>
                  <div>
                    Sur cette page, vous pouvez gérer les réservations d'un ou
                    plusieurs plongeurs.
                  </div>
                  <div className="mt-2 mb-1 font-bold">
                    Principales actions:
                  </div>
                  <ul className="ml-3 list-disc">
                    <li>
                      Cliquer sur <b>"+ SESSION"</b> pour ajouter une session de
                      plongée à la réservation
                    </li>
                    <li>
                      Cliquer sur <b>"+ PLONGEUR"</b> pour ajouter un plongeur à
                      la réservation
                    </li>
                    <li>
                      Cliquer sur <b>la date ou l'heure d'une session</b> pour
                      voir le détail des participants
                    </li>
                    <li>
                      Cliquer sur le <b>nom d'un plongeur</b> pour modifier ses
                      caractéristiques (nom, niveau, téléphone, date de
                      naissance...)
                    </li>
                    <li>
                      Cliquer sur la <b>case plongée d'un plongeur</b>{' '}
                      l'inscrire ou modifier sa plongée (PA20, baptême, gaz,
                      matériel...)
                    </li>
                  </ul>
                </>
              }
            />
          </AppVisible>
        </>
      )}
    </AppPageBlock>
  );
};
