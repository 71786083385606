import { clubBoatFormatter, dataSorter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks/useDiveCenterResume.hook';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useClubBoatOptions({
  currentClubBoatsIds = [], // permet d'inclure les sites dans la liste si ils ont été désactivés mais qu'ils sont actifs sur ce formulaire
}: {
  currentClubBoatsIds: string[];
}): ValueLabel<string>[] {
  const diveCenterResume = useDiveCenterResume();

  const boatsOptions = useMemo(() => {
    const boats = diveCenterResume.boats.filter(
      (x) => x.enabled || currentClubBoatsIds.includes(x._id),
    );
    const sortedBoats = dataSorter.sortMultiple(boats, {
      getSortAttributes: (b) => [
        {
          value:
            b.departureType === 'boat'
              ? 1
              : b.departureType === 'border'
              ? 2
              : b.departureType === 'pool'
              ? 3
              : 4,
        },
        {
          value: b.isDefault,
        },
        {
          value: b.name,
        },
        {
          value: b.immatriculation,
        },
        {
          value: b._id,
        },
      ],
    });
    const purchaseOptions = sortedBoats.map((boat) => {
      const option: ValueLabel<string> = {
        value: boat._id,
        label: clubBoatFormatter.formatClubBoat(boat, { format: 'name' }),
      };
      return option;
    });
    return purchaseOptions;
  }, [currentClubBoatsIds, diveCenterResume.boats]);

  return boatsOptions;
}
