/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
  DiveSessionStaffMemberTableModelStaffMember,
} from '@mabadive/app-common-model';
import {
  colorGenerator,
  nameFormatter,
  staffMemberFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { FC, useMemo, useState } from 'react';
import {
  DiveModeBadge,
  StaffLevelBadge,
} from 'src/business/_core/modules/layout';
import { useClubResume, useDiveModeColor } from 'src/business/club/data/hooks';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { AppFormControlV2 } from 'src/lib/form/components/AppFormControl';
import { useFormParticipantsCheckedState } from '../_hooks';
import { DiveSessionEditorMassiveDialogLocalState } from '../useDiveSessionEditorMassiveDialogLocalState.hook';
import { DiveSessionEditorMassiveDiveGroupParticipantInfo } from './DiveSessionEditorMassiveDiveGroupParticipantInfo';

export const DiveSessionEditorMassiveDiveGroup: FC<{
  localState: DiveSessionEditorMassiveDialogLocalState;
  group: DiveSessionResumeGroup;
  participants: DiveSessionResumeParticipant[];
  enableSelection: boolean;
}> = ({ localState, group, participants, enableSelection }) => {
  const { form, data, initialState } = localState;

  const clubResume = useClubResume();

  const {
    staffMembersFull,
    sameStaffSession1,
    sameParticipantsSession1,
    isMultiSessionConfigForStaff,
    isMultiSessionConfigForParticipants,
    diveCenterResume,
  } = data;

  const { clubReference } = diveCenterResume;

  const diveTourGroupSession1DivingInstructorsStaffMemberFull: DiveSessionStaffMemberTableModelStaffMember =
    useMemo(
      () =>
        staffMembersFull.find(
          (x) =>
            x.staffMember?._id ===
            group?.diveTourGroupSession1?.instructor?.staffId,
        ),
      [group?.diveTourGroupSession1?.instructor?.staffId, staffMembersFull],
    );
  const diveTourGroupSession2DivingInstructorsStaffMemberFull: DiveSessionStaffMemberTableModelStaffMember =
    useMemo(
      () =>
        staffMembersFull.find(
          (x) =>
            x.staffMember?._id ===
            group?.diveTourGroupSession2?.instructor?.staffId,
        ),
      [group?.diveTourGroupSession2?.instructor?.staffId, staffMembersFull],
    );

  const hasAnyAutonomous = useMemo(
    () => participants.find((d) => d.diveMode === 'autonomous') !== undefined,
    [participants],
  );

  const diveTourGroupSession1DiveGuideParticipant = useMemo(
    () =>
      participants.find(
        (d) => d._id == group?.diveTourGroupSession1?.diveGuide?.participantId,
      ),
    [group?.diveTourGroupSession1?.diveGuide?.participantId, participants],
  );
  const diveTourGroupSession2DiveGuideParticipant = useMemo(
    () =>
      participants.find(
        (d) => d._id == group?.diveTourGroupSession2?.diveGuide?.participantId,
      ),
    [group?.diveTourGroupSession2?.diveGuide?.participantId, participants],
  );

  const displayAutonomousField =
    clubResume.clubSettings.general.sessions?.displayAutonomousField;

  const diveModeColor = useDiveModeColor(group?.diveMode);

  const participantsCheckState = useFormParticipantsCheckedState({
    form,
    participantsFormIndexesById: data.participantsFormIndexesById,
    participantsIds: participants.map((x) => x._id),
  });
  const [isHover, setIsHover] = useState(false);
  const styleWithHoverSupport: React.CSSProperties = useMemo(() => {
    if (group) {
      return isHover
        ? { borderColor: colorGenerator.darken(diveModeColor, 0.6) }
        : { borderColor: diveModeColor };
    }
  }, [diveModeColor, group, isHover]);
  return (
    <div
      className={clsx(
        'w-full border-2 border-l-4 pl-px p-1 text-gray-600',
        enableSelection && 'cursor-pointer',
        group && 'hover:text-gray-800',
      )}
      style={styleWithHoverSupport}
      onClick={() => {
        enableSelection && participantsCheckState.toggleGroupCheck();
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        className={'w-full flex justify-between gap-2 px-2 py-1 items-center'}
      >
        {enableSelection && (
          <div className="w-64">
            <AppInputBooleanSwitch
              theme="success"
              label="tous"
              value={participantsCheckState.isGroupSelected}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(value) => {
                participantsCheckState.setGroupCheck(value);
              }}
            />
          </div>
        )}
        {group && (
          <div
            className={`w-full ${
              isMultiSessionConfigForStaff
                ? 'flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-2'
                : ''
            } py-1`}
          >
            <div
              className={`flex ${
                isMultiSessionConfigForStaff ? 'flex-col gap-y-2' : 'gap-x-2'
              }`}
            >
              {displayAutonomousField &&
                group?.diveTourGroupSession1?.isAutonomous && (
                  <>
                    <AppFormControlV2
                      className={`${
                        isMultiSessionConfigForStaff ? 'w-full' : ''
                      }`}
                      label={`Autonomes ${
                        isMultiSessionConfigForStaff ? 'plongée N°1' : ''
                      }`}
                      renderComponent={() => (
                        <AppInputBooleanSwitch
                          disabled={true}
                          value={group?.diveTourGroupSession1?.isAutonomous}
                        />
                      )}
                    />
                  </>
                )}

              {diveTourGroupSession1DivingInstructorsStaffMemberFull &&
                !group?.diveTourGroupSession1?.isAutonomous && (
                  <div className="basis-0 flex-grow">
                    <AppFormControlV2
                      className="w-full"
                      label={`Moniteur de plongée ${
                        isMultiSessionConfigForStaff ? '1' : ''
                      }`}
                      renderComponent={() => (
                        <div className="flex gap-1">
                          <StaffLevelBadge
                            className="rounded px-0.5 py-0.5 w-12 lg:w-16 text-xs"
                            staffMember={
                              diveTourGroupSession1DivingInstructorsStaffMemberFull?.staffMember
                            }
                            date={initialState.originalSessionFull.time}
                          />
                          {staffMemberFormatter.formatStaffMember(
                            diveTourGroupSession1DivingInstructorsStaffMemberFull?.staffMember,
                            {
                              format: 'name',
                            },
                          )}
                        </div>
                      )}
                    />
                  </div>
                )}
              {diveTourGroupSession1DiveGuideParticipant &&
                !group?.diveTourGroupSession1?.isAutonomous && (
                  <div className="basis-0 flex-grow">
                    <AppFormControlV2
                      className="w-full"
                      label={`Guide de palanquée ${
                        isMultiSessionConfigForStaff ? '1' : ''
                      }`}
                      renderComponent={() => (
                        <div className="flex gap-1">
                          <DiveModeBadge
                            diveMode={'instructor'}
                            className="rounded px-0.5 py-0.5 w-12 lg:w-16 text-xs"
                            clubReference={clubReference}
                          >
                            <div>GP</div>
                          </DiveModeBadge>
                          {nameFormatter.formatFullName(
                            diveTourGroupSession1DiveGuideParticipant.diver,
                          )}
                        </div>
                      )}
                    />
                  </div>
                )}
            </div>
            {isMultiSessionConfigForStaff && (
              <div
                className={`flex ${
                  isMultiSessionConfigForStaff
                    ? 'flex-col gap-y-2'
                    : 'w-full gap-x-2'
                }`}
              >
                {displayAutonomousField &&
                  group?.diveTourGroupSession2?.isAutonomous && (
                    <>
                      {
                        <AppFormControlV2
                          className={`${
                            isMultiSessionConfigForStaff ? 'w-full' : ''
                          }`}
                          label={'Autonomes plongée N°2'}
                          renderComponent={() => (
                            <AppInputBooleanSwitch
                              disabled={true}
                              value={group?.diveTourGroupSession2?.isAutonomous}
                            />
                          )}
                        />
                      }
                    </>
                  )}
                {diveTourGroupSession2DivingInstructorsStaffMemberFull &&
                  !group?.diveTourGroupSession2?.isAutonomous && (
                    <div className="basis-0 flex-grow">
                      <AppFormControlV2
                        className="w-full"
                        label={'Moniteur de plongée 2'}
                        renderComponent={() => (
                          <div className="flex gap-1">
                            <StaffLevelBadge
                              className="rounded px-0.5 py-0.5 w-12 lg:w-16 text-xs"
                              staffMember={
                                diveTourGroupSession1DivingInstructorsStaffMemberFull?.staffMember
                              }
                              date={initialState.originalSessionFull.time}
                            />
                            {staffMemberFormatter.formatStaffMember(
                              diveTourGroupSession1DivingInstructorsStaffMemberFull?.staffMember,
                              {
                                format: 'name',
                              },
                            )}
                          </div>
                        )}
                      />
                    </div>
                  )}
                {diveTourGroupSession2DiveGuideParticipant &&
                  !group?.diveTourGroupSession2?.isAutonomous && (
                    <div className="basis-0 flex-grow">
                      <AppFormControlV2
                        className="w-full"
                        label={'Guide de palanquée 2'}
                        renderComponent={() => (
                          <div className="flex gap-1">
                            <DiveModeBadge
                              diveMode={'instructor'}
                              className="rounded px-0.5 py-0.5 w-12 lg:w-16 text-xs"
                              clubReference={clubReference}
                            >
                              <div>GP</div>
                            </DiveModeBadge>
                            {nameFormatter.formatFullName(
                              diveTourGroupSession1DiveGuideParticipant.diver,
                            )}
                          </div>
                        )}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
      </div>
      <div className={'px-2'}>
        {participants.map((participant, participantIndex) => (
          <div
            key={participant._id}
            className={'select-none flex items-start gap-2 text-gray-600 py-1'}
          >
            <DiveSessionEditorMassiveDiveGroupParticipantInfo
              localState={localState}
              participant={participant}
              enableSelection={enableSelection}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
