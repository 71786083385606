import {
  BookingParticipantEditorFormModel,
  ClubParticipant,
} from '@mabadive/app-common-model';
import React, { FC, useMemo } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { BookingParticipantEditorDialogStateMode } from '../../../models';

export const BookingParticipantEditorQuickEditActions: FC<{
  mode?: BookingParticipantEditorDialogStateMode;
  participant: ClubParticipant;
  onClickDelete: () => void;
  onClickMove?: () => void;
  setValue: UseFormSetValue<Partial<BookingParticipantEditorFormModel>>;
}> = ({ mode, participant, onClickDelete, setValue, onClickMove }) => {
  const allowConfirm = useMemo(
    () =>
      mode === 'edit-participant' &&
      participant?.bookingState?.value === 'pending',
    [mode, participant?.bookingState?.value],
  );
  const allowCancel = useMemo(
    () =>
      mode === 'edit-participant' &&
      participant?.bookingState?.value !== 'cancelled',
    [mode, participant?.bookingState?.value],
  );
  const allowDelete = useMemo(() => mode === 'edit-participant', [mode]);

  const allowMove = useMemo(
    () => mode === 'edit-participant' && !!onClickMove,
    [mode, onClickMove],
  );

  return !participant ? null : (
    <>
      {allowConfirm && (
        <div
          className={
            'hidden sm:flex cursor-pointer group text-green-600 hover:text-green-800 flex flex-col items-center'
          }
          onClick={(e) => {
            e.stopPropagation();
            setValue('clubParticipant.bookingStatus', 'confirmed', {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
          }}
        >
          <AppHeroIcons.actionConfirm
            className={
              'rounded-full bg-gray-100 group-hover:bg-gray-200 w-12 h-12 p-2'
            }
          />
          <span className="uppercase font-bold text-xs">Confirmer</span>
        </div>
      )}
      {allowCancel && (
        <div
          className={
            'hidden sm:flex cursor-pointer group text-gray-600 hover:text-gray-800 flex flex-col items-center'
          }
          onClick={(e) => {
            e.stopPropagation();
            setValue('clubParticipant.bookingStatus', 'cancelled-diver', {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
            setValue('clubParticipant.diveSessionGroupId', null, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
          }}
        >
          <AppHeroIcons.actionCancel
            className={
              'rounded-full bg-gray-100 group-hover:bg-gray-200 w-12 h-12 p-2'
            }
          />
          <span className="uppercase font-bold text-xs">Annuler</span>
        </div>
      )}
      {allowMove && (
        <div
          className={
            'hidden xs:flex cursor-pointer group text-app-primary hover:text-app-primary-dark flex flex-col items-center'
          }
          onClick={(e) => {
            e.stopPropagation();
            onClickMove();
          }}
        >
          <AppHeroIcons.actionMoveSwitch
            className={
              'rounded-full bg-gray-100 group-hover:bg-gray-200 w-12 h-12 p-2'
            }
          />
          <span className="uppercase font-bold text-xs">Déplacer</span>
        </div>
      )}
      {allowDelete && (
        <div
          className={
            'cursor-pointer group text-red-400 hover:text-red-600 flex flex-col items-center'
          }
          onClick={(e) => {
            e.stopPropagation();
            onClickDelete();
          }}
        >
          <AppHeroIcons.actionDelete
            className={
              'rounded-full bg-gray-100 group-hover:bg-gray-200 w-12 h-12 p-2'
            }
          />
          <span className="uppercase font-bold text-xs">Supprimer</span>
        </div>
      )}
    </>
  );
};
