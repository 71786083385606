import { useEffect, useMemo, useState } from 'react';

export function useProgressiveListRendering<T>(
  items: T[],
  {
    initialSize = 5,
    step = 10,
    stepFrequencyInMs = 10,
  }: {
    initialSize?: number;
    step?: number;
    stepFrequencyInMs?: number;
  } = {},
  // deps: DependencyList = [],
): T[] {
  // NOTE: maxItems n'est pas réinitialisé par défaut, afin d'éviter de faire un refresh complet en cas de mise à jour des données
  const [maxItems, setMaxItems] = useState(initialSize);

  // useEffect(() => {
  // ça c'est buggé, et on n'en a pas besoin pour le moment
  //   console.log('xxx deps updated', deps);
  //   // NOTE: maxItems n'est pas réinitialisé par défaut, afin d'éviter de faire un refresh complet en cas de mise à jour des données
  //   if (deps && initialSize !== maxItems) {
  //     // donc pour forcer le refresh, on utilise les dépendances (utile pour le planning quand on change de jour)
  //     setMaxItems(initialSize);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [...deps]);

  const itemsToRender = useMemo(() => {
    if (items?.length > 0) {
      if (maxItems < items?.length) {
        return items.slice(0, maxItems);
      } else {
        return items;
      }
    }
    return [];
  }, [maxItems, items]);

  useEffect(() => {
    // on affiche progressivement la page, pour ne pas saturer le DOM
    if (maxItems < items?.length) {
      const handle = setTimeout(() => {
        setMaxItems(maxItems + step);
      }, stepFrequencyInMs);
      return () => {
        clearTimeout(handle);
      };
    }
  }, [maxItems, items, step, stepFrequencyInMs]);

  return itemsToRender;
}
