/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingDeposit,
  PRO_AppDocResume,
  ProMultiOperationPayloadActionStep,
} from '@mabadive/app-common-model';
import {
  changeDescriptorAggregator,
  changeDescriptorManager,
  commonDiveSessionReferenceParser,
  dateService,
} from '@mabadive/app-common-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { appWebTracker } from 'src/business/_core/data/app-user-tracking';
import {
  useAppTrackingClient,
  useWindowUnloadAlert,
} from 'src/business/_core/modules/layout';
import { useSideMenu } from 'src/business/_core/modules/layout/components/SideMenu/useSideMenu.hook';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { appWebLogger } from 'src/lib/browser';
import {
  ClubDialogsState,
  ClubDialogsStateOld,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { GenericNavigationContext } from '../../ClubParticipantNavigationContext';
import { bookingUpdateStateBuilder } from './bookingUpdateStateBuilder.service';
import { useOpenCreatePurchasePaymentDialog } from './components/DiverPurchasePaymentEditorDialog';
import {
  AggregatedBookingDepositWithPayments,
  DiverBookingPageTriggerAction,
  DiverBookingPageUpdateState,
} from './models';
import {
  BillingTabModelBuilderFilterCriteria,
  DiverBookingPageAdditionalDataToLoad,
  PaymentTabModelBuilderFilterCriteria,
  SessionsHistoryTabModelBuilderFilterCriteria,
  buildBookingDepositsWithPayments,
  diverBookingPageAggregatedBookingResumeBuilder,
  diverBookingPageClientUpdator,
  useDiverBookingPageClubDiverLinkedData,
  useDiverBookingPageLoadedContent,
} from './services';

export type DiverBookingPageSetUpdateStateFnOptions = {
  action: string;
  meta?: any;
  resetSteps?: boolean;
};

export type DiverBookingPageSetUpdateStateFn = (
  state: DiverBookingPageUpdateState,
  { action, meta }: DiverBookingPageSetUpdateStateFnOptions,
) => void;

export function useDiverBookingPageGlobalState({
  navigationContext,
  dialogsOLD,
}: {
  navigationContext: GenericNavigationContext;
  dialogsOLD: ClubDialogsStateOld;
}) {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const { diverId: mainDiverId, diveSessionReference } = navigationContext;

  const [includeArchivedBookings, setIncludeArchivedBookings] = useState(false);
  const [includeArchivedPurchases, setIncludeArchivedPurchases] =
    useState(true); // pour le moment, ce paramètre n'est pas modifiable, donc on charge tout

  const [additionnalDataToLoad, setAdditionnalDataToLoad] =
    useState<DiverBookingPageAdditionalDataToLoad>({
      bookingIds: [],
      diverIds: [],
      newBookingIds: [],
    });

  const openCreatePurchasePaymentDialog = useOpenCreatePurchasePaymentDialog({
    setPaymentEditorDialogState: dialogsOLD.setPaymentEditorDialogState,
  });

  const {
    linkedData,
    mainDiverFull,
    focusDiveSession,
    addDiversIds,
    markNewDiversAsPersisted,
    refetchLinkedData,
  } = useDiverBookingPageClubDiverLinkedData({
    mainDiverId,
    focusDiveSessionReference: diveSessionReference,
    includeArchivedBookings,
    includeArchivedPurchases,
  });

  // initial content, loaded once at page load
  // NOTE: pour le moment, on charge quand même les résas archivées, même si includeArchivedBookings=false, puis on masque simplement ces réservations (sinon impact non maitrisé sur facturations et paiements)
  const {
    content: { content: loadedContent, ...loadableContent },
    refetchMessages,
    refetchAdditional,
    refetchMain,
  } = useDiverBookingPageLoadedContent({
    mainDiverFull,
    focusDiveSession,
    linkedData,
    additionnalDataToLoad,
  });

  const trackingClient = useAppTrackingClient();

  useEffect(() => {
    if (
      loadableContent.lastActionStatus === 'success' &&
      loadableContent.contentState === 'full'
    ) {
      appWebTracker.trackDataLoading(
        {
          ...loadableContent,
          data: loadedContent,
          pageName: 'DiverBookingPage',
          dataLabel: 'diver booking',
          context: {
            navigationContext,
          },
          limitToRandomPercent: 5, // on ne reporte que 5% du temps
          clubReference,
        },
        { trackingClient },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadableContent.lastActionStatus, loadableContent.contentState]);

  const [isPersistInProgress, setIsPersistInProgress] = useState(false);

  // changes made to bookings models, not persisted yet
  const emptyUpdateState = useMemo(
    () =>
      bookingUpdateStateBuilder.createEmptyUpdateState({
        hasChanges: false,
      }),
    [],
  );
  const [updateState, setUpdateStateInner] =
    useState<DiverBookingPageUpdateState>(emptyUpdateState);

  const [openPurchaseRefs, setOpenPurchaseRefs] = useState<string[]>([]);
  const [openPaymentIds, setOpenPaymentIds] = useState<string[]>([]);
  const [bookingOpeningStates, setBookingOpeningStates] = useState<
    {
      bookingId: string;
      isOpen: boolean;
      isOpenMembers: boolean;
    }[]
  >([]);

  const setUpdateState: DiverBookingPageSetUpdateStateFn = useCallback(
    (
      state: DiverBookingPageUpdateState,
      { action, meta, resetSteps }: DiverBookingPageSetUpdateStateFnOptions,
    ) => {
      const updatedState: DiverBookingPageUpdateState = {
        ...state,
        hasChanges: true,
      };
      if (action) {
        if (!updatedState.actionSteps) {
          updatedState.actionSteps = []; // ça ne devrait pas arriver, mais au cas où
        }
        const step: ProMultiOperationPayloadActionStep = {
          date: new Date(),
          label: action,
          meta,
        };
        if (resetSteps) {
          updateState.actionSteps = [step];
        } else {
          updateState.actionSteps.push(step);
        }
      }

      setUpdateStateInner(updatedState);
    },
    [updateState],
  );

  // loaded bookings + changes applied
  const { aggregatedData, missingData } = useMemo(
    () =>
      diverBookingPageAggregatedBookingResumeBuilder.aggregateAll({
        loadedContent,
        updateState,
        isLoaded: loadableContent.contentState === 'full',
        diveCenterId,
      }),
    [diveCenterId, loadableContent.contentState, loadedContent, updateState],
  );

  useMemo(() => {
    if (missingData.bookingIds.length > 0 || missingData.diverIds.length > 0) {
      const bookingIdsToAdd = missingData.bookingIds.filter(
        (x) => !additionnalDataToLoad.bookingIds.includes(x),
      );
      const diverIdsToAdd = missingData.diverIds.filter(
        (x) => !additionnalDataToLoad.diverIds.includes(x),
      );
      if (bookingIdsToAdd.length || diverIdsToAdd.length) {
        const updatedDataToLoad: DiverBookingPageAdditionalDataToLoad = {
          ...additionnalDataToLoad,
        };
        if (bookingIdsToAdd.length) {
          updatedDataToLoad.bookingIds = bookingIdsToAdd;
        }
        if (diverIdsToAdd.length) {
          updatedDataToLoad.diverIds = diverIdsToAdd;
        }
        appLogger.info('Missing data updated', { updatedDataToLoad });

        setAdditionnalDataToLoad(updatedDataToLoad);
      } else {
        appLogger.info('No missing data');
      }
      // set;
    }
  }, [additionnalDataToLoad, missingData]);

  const [triggerAction, setTriggerAction] =
    useState<DiverBookingPageTriggerAction>();

  useEffect(() => {
    const newBookings = aggregatedData.bookingResumesLoaded.filter(
      (b) =>
        bookingOpeningStates.find((s) => s?.bookingId === b.booking?._id) ===
        undefined,
    );
    if (newBookings.length) {
      const newBookingOpeningStates = bookingOpeningStates.concat(
        newBookings.map((b) => ({
          bookingId: b.booking?._id,
          isOpen: b.booking?.active,
          isOpenMembers: b.bookingMembers?.length < 6,
        })),
      );
      setBookingOpeningStates(newBookingOpeningStates);
    }
  }, [aggregatedData.bookingResumesLoaded, bookingOpeningStates]);

  const [selectedDiveSessionReference, setSelectedDiveSessionReference] =
    useState<string>(navigationContext?.diveSessionReference);

  const [billingTabFilterCriteria, setBillingTabFilterCriteria] =
    useState<BillingTabModelBuilderFilterCriteria>({
      ignoreFutureNotPurchasedParticipants: false,
      ignoreVeryOldNotPurchasedParticipants: true,
      ignoreCancelledParticipants: true,
      ignoreOtherDiveCentersPurchasePackages: true,
      ignoreOtherDiveCentersNotPurchasedParticipants: true,
    });

  const [paymentTabFilterCriteria, setPaymentTabFilterCriteria] =
    useState<PaymentTabModelBuilderFilterCriteria>({
      ignoreOtherDiveCenters: true,
    });

  const [
    sessionsHistoryTabFilterCriteria,
    setSessionsHistoryTabFilterCriteria,
  ] = useState<SessionsHistoryTabModelBuilderFilterCriteria>({
    ignoreOtherDiveCenters: false,
    ignoreCancelledParticipants: true,
  });

  const focus = loadedContent?.focus;

  const defaultSessionSelectorDefaultFocusDate: Date = useMemo(() => {
    const date = commonDiveSessionReferenceParser.parseSessionReferenceToDate(
      navigationContext?.diveSessionReference,
    );
    if (dateService.isBefore(new Date(), date)) {
      // on n'utilise la date par défaut que si c'est dans le futur (sinon, risque d'inscrire quelqu'un dans le passé en pensant que c'est le présent)
      return date;
    }
  }, [navigationContext?.diveSessionReference]);

  const [sessionSelectorDefaultFocusDate, setSessionSelectorDefaultFocusDate] =
    useState<Date>(defaultSessionSelectorDefaultFocusDate);

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    createMessageToCustomers: {
      onUpdate: () => {
        refetchMessages();
      },
    },
    appDocEdit: {
      onCreateAndPersisted: ({ appDoc }: { appDoc: PRO_AppDocResume }) => {
        const docResumesChanges = changeDescriptorManager.addOneOriginal(
          appDoc,
          {
            changeDescriptors: updateState.docResumesChanges,
          },
        );
        setUpdateState(
          {
            ...updateState,
            docResumesChanges,
          },
          {
            action: 'appDocEdit.onCreateAndPersisted',
            meta: {
              appDocId: appDoc._id,
            },
          },
        );
      },
      onUpdateNotPersisted: ({
        patches,
      }: {
        patches: AppEntityUpdatePatch[];
      }) => {
        const docResumesChanges = changeDescriptorManager.updateMany(patches, {
          optimizePatches: false,
          changeDescriptors: updateState.docResumesChanges,
        });
        setUpdateState(
          {
            ...updateState,
            docResumesChanges,
          },
          {
            action: 'appDocEdit.onUpdateNotPersisted',
            meta: {},
          },
        );
      },
      onDeleteNotPersisted: ({ appDocIds }: { appDocIds: string[] }) => {
        // TODO
        const docResumesChanges = changeDescriptorManager.deleteMany(
          appDocIds,
          {
            changeDescriptors: updateState.docResumesChanges,
          },
        );
        setUpdateState(
          {
            ...updateState,
            docResumesChanges,
          },
          {
            action: 'appDocEdit.onDeleteNotPersisted',
            meta: {},
          },
        );
      },
    },
    bookingDepositEdit: {
      onCreate: ({ bookingDeposit }) => {
        const bookingDepositsChanges = changeDescriptorManager.createOne(
          bookingDeposit,
          {
            changeDescriptors: updateState.bookingDepositsChanges,
          },
        );

        setUpdateState(
          {
            ...updateState,
            bookingDepositsChanges,
          },
          {
            action: 'bookingDepositEdit.onCreate',
            meta: {},
          },
        );
        // on met à jour les données agrégées avec le nouvel acompte avant d'ouvrir la fenêtre de paiement
        const diver = (aggregatedData.divers ?? []).find(
          (d) => d._id === bookingDeposit.diverId,
        );
        const bookingDeposits: BookingDeposit[] =
          changeDescriptorAggregator.aggregateMany(bookingDepositsChanges, {
            pk: '_id',
            initials: loadedContent.bookingDeposits,
            ignoreErrors: true,
            appLogger,
            logPrefix: 'bookingDeposits',
          });
        const bookingDepositsWithPayments: AggregatedBookingDepositWithPayments[] =
          buildBookingDepositsWithPayments({
            bookingDeposits: bookingDeposits,
            paymentsBookingDepositsDetails:
              aggregatedData.paymentsBookingDepositsDetails,
            purchasePayments: aggregatedData.purchasePayments,
            bookingResumesLoaded: aggregatedData.bookingResumesLoaded,
            diversLoaded: aggregatedData.diversLoaded,
          });
        openCreatePurchasePaymentDialog({
          defaultDiverId: diver?._id,
          defaultPackagesIds: [],
          defaultBookingDepositsIds: [bookingDeposit._id],
          aggregatedData,
          // ICI, on met à jour 'bookingDepositsWithPayments' avant d'ouvrir le dialog, donc on ne le prend pas toujours dans aggregatedData
          bookingDepositsWithPayments,
        });
      },
      onUpdate: ({ bookingDeposit, patch }) => {
        const bookingDepositsChanges = changeDescriptorManager.updateOne(
          patch,
          {
            changeDescriptors: updateState.bookingDepositsChanges,
          },
        );

        setUpdateState(
          {
            ...updateState,
            bookingDepositsChanges,
          },
          {
            action: 'bookingDepositEdit.onUpdate',
            meta: {},
          },
        );
      },
    },
    bookingCreditNoteEdit: {
      onCreate: ({ creditNote }) => {
        const creditNotesChanges = changeDescriptorManager.createOne(
          creditNote,
          {
            changeDescriptors: updateState.creditNotesChanges,
          },
        );

        setUpdateState(
          {
            ...updateState,
            creditNotesChanges,
          },
          {
            action: 'bookingCreditNoteEdit.onCreate',
            meta: {},
          },
        );
      },
      onUpdate: ({ creditNote, patch }) => {
        const creditNotesChanges = changeDescriptorManager.updateOne(patch, {
          changeDescriptors: updateState.creditNotesChanges,
        });

        setUpdateState(
          {
            ...updateState,
            creditNotesChanges,
          },
          {
            action: 'bookingCreditNoteEdit.onUpdate',
            meta: {},
          },
        );
      },
    },
  });
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  useWindowUnloadAlert(updateState.hasChanges);

  const { disableMenu } = useSideMenu();

  useEffect(() => {
    disableMenu(updateState.hasChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState.hasChanges]);

  const refetchAll = useCallback(async () => {
    await refetchLinkedData();
    await refetchMain();
    await refetchAdditional();
  }, [refetchAdditional, refetchMain, refetchLinkedData]);

  const persistChanges = useCallback(
    async (logContext: string): Promise<boolean> => {
      if (!updateState.hasChanges) {
        return true;
      } else if (isPersistInProgress) {
        return false;
      } else {
        setIsPersistInProgress(true);

        const bookingIdsToClean = aggregatedData.bookings
          .map((x) => x._id)
          .filter((x) => !!x);

        try {
          await diverBookingPageClientUpdator.persistChanges(updateState, {
            logContext,
            bookingIdsToClean,
          });
          const emptyUpdateState =
            bookingUpdateStateBuilder.createEmptyUpdateState({
              hasChanges: false,
            });
          setUpdateState(emptyUpdateState, {
            action: 'Booking page: reset after persist (2)',
            meta: {},
            resetSteps: true,
          });
          markNewDiversAsPersisted();
          refetchAll();
          return true;
        } catch (err) {
          setIsPersistInProgress(false);
          appWebLogger.captureMessage(
            'Error while trying to persist booking changes',
            {
              logContext: 'DiverBookingPage',
              clubReference,
              extra: {
                err,
                updateState,
                bookingIdsToClean,
              },
            },
          );
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
          });
          return false;
        } finally {
          setIsPersistInProgress(false);
        }
      }
    },
    [
      aggregatedData.bookings,
      clubReference,
      isPersistInProgress,
      markNewDiversAsPersisted,
      refetchAll,
      setUpdateState,
      updateState,
    ],
  );

  return {
    _dialog_hack: {
      openCreatePurchasePaymentDialog,
    },
    state: {
      // TODO structurer le state
      additionnalDataToLoad,
      setAdditionnalDataToLoad,
    },
    data: {},
    actions: {
      persistChanges,
      refetch: {
        all: refetchAll,
        messages: refetchMessages,
      },
    },
    loadableContent,
    loadedContent,
    dialogsState,
    aggregatedData,
    updateState,
    setUpdateStateInner,
    setUpdateState,
    includeArchivedBookings,
    setIncludeArchivedBookings,
    includeArchivedPurchases,
    setIncludeArchivedPurchases,
    isPersistInProgress,
    setIsPersistInProgress,
    clubReference,
    diveCenterId,
    focus,
    selectedDiveSessionReference,
    setSelectedDiveSessionReference,
    navigationContext,
    openPurchaseRefs,
    setOpenPurchaseRefs,
    openPaymentIds,
    setOpenPaymentIds,
    setBookingOpeningStates,
    bookingOpeningStates,
    sessionSelectorDefaultFocusDate,
    setSessionSelectorDefaultFocusDate,
    triggerAction,
    setTriggerAction,
    billingTabFilterCriteria,
    setBillingTabFilterCriteria,
    sessionsHistoryTabFilterCriteria,
    setSessionsHistoryTabFilterCriteria,
    paymentTabFilterCriteria,
    setPaymentTabFilterCriteria,
    linkedData,
    addDiversIds,
  };
}

export type DiverBookingPageGlobalState = ReturnType<
  typeof useDiverBookingPageGlobalState
>;
