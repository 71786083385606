import React, { useCallback, useMemo, useState } from 'react';
import { currentDiveCenterResumeStore } from 'src/business/_core/data/store-repository';
import { uiStore } from 'src/business/_core/store';
import {
  authenticationClient,
  useAppSecurityIsAuthenticated,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { DiveCenterProBadge } from '../DiveCenterProBadge';

export function DiveCenterSelect({
  onSelect,
  className,
}: {
  onSelect?: (value: string) => void;
  className?: string;
}) {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const diveCentersOptions: ValueLabel<string, React.ReactNode>[] = useMemo(
    () =>
      (clubResume?.diveCenters ?? []).map((dc) => ({
        value: dc._id,
        label: <DiveCenterProBadge diveCenter={dc} />,
      })),
    [clubResume?.diveCenters],
  );

  const isAuthenticated = useAppSecurityIsAuthenticated();
  const isClub = useAppSecurityUserHasRole('club');

  const [operationPending, setOperationPending] = useState(false);

  const currentDiveCenterResumeId =
    currentDiveCenterResumeStore.currentDiveCenterResumeId;

  const onClickSwitch = useCallback(
    async ({ diveCenterId }: { diveCenterId: string }) => {
      setOperationPending(true);

      try {
        await authenticationClient
          .switchClubDiveCenter({
            clubReference,
            diveCenterId,
          })
          .toPromise();

        uiStore.snackbarMessage.set({
          type: 'success',
          content: 'Connexion réussie!',
        });
      } catch (err) {
      } finally {
        setOperationPending(false);
      }
    },
    [clubReference],
  );

  return !(
    isAuthenticated &&
    isClub &&
    diveCentersOptions.length > 1
  ) ? null : (
    <AppSingleSelect2HeadlessUI
      className={className}
      theme="tailwind"
      color="theme"
      disableClearButton={true}
      options={diveCentersOptions}
      value={currentDiveCenterResumeId.getSnapshot() ?? ''}
      onChange={async (diveCenterId) => {
        if (diveCenterId) {
          currentDiveCenterResumeId.set(diveCenterId);
          onSelect(diveCenterId);
          await onClickSwitch({ diveCenterId });
        }
      }}
    />
  );
}
