import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MQueryDescription, graphqlClient } from 'src/_common-browser';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { AdminAppOperationsGraphqlQueryResult } from '../_model';

type AdminAppOperationsGraphqlFetcherParams = {
  limit?: number;
  includeSuperAdmin: boolean;
  includeSuccess: boolean;
  includeError: boolean;
};

export const adminAppOperationsGraphqlFetcher = {
  fetchAll,
};

function fetchAll(
  params: AdminAppOperationsGraphqlFetcherParams,
): Observable<AdminAppOperationsGraphqlQueryResult[]> {
  // group all graphql queries into one fetch

  const query: MQueryDescription<AdminAppOperationsGraphqlQueryResult> =
    buildAdminAppOperationsGraphqlQueryResultManyGraphqlQuery(params);

  return graphqlClient.query
    .runOne<AdminAppOperationsGraphqlQueryResult[]>(query, {
      type: 'query',
      name: 'adminAppOperationsGraphqlFetcher',
      headers: {
        'x-hasura-role': 'admin', // select SUPER ADMIN hasura role
      },
    })
    .pipe(
      tap((res) =>
        appLogger.debug(
          '[adminAppOperationsGraphqlFetcher.fetchAll]:',
          res.length,
        ),
      ),
    );
}

export function buildAdminAppOperationsGraphqlQueryResultManyGraphqlQuery({
  limit,
  includeSuperAdmin,
  includeSuccess,
  includeError,
}: AdminAppOperationsGraphqlFetcherParams): MQueryDescription<AdminAppOperationsGraphqlQueryResult> {
  const whereClauses: string[] = [];
  if (includeError && !includeSuccess) {
    whereClauses.push('success: {_eq: false}');
  }
  if (!includeError && includeSuccess) {
    whereClauses.push('success: {_eq: true}');
  }
  if (!includeSuperAdmin) {
    whereClauses.push('authUser: {profile: {_neq: "super-admin"}}');
  }

  const where = `{${whereClauses.join(',')}}`;

  const queryDescription: MQueryDescription<AdminAppOperationsGraphqlQueryResult> =
    {
      // returnName: 'adminAppOperationsGraphqlQueryResult',
      queryName: 'app_operation_log',
      returnType: 'all',
      limit,
      where,
      orderBy: '{endDate: desc}',
      returnAttributes: ` 
        _id
        startDate
        durationInMs
        result
        operation
        club {
          reference
          name
        }
        authUser {
          login
          profile
          clubProfile {
            label
          }
        }
        client
      `,
    };

  return queryDescription;
}
