import clsx from 'clsx';
import React from 'react';
import { AppIcons } from 'src/business/_core/modules/layout/icons';

export function AppMultilineCommentIcons({
  showPrivate,
  showpublic,
  className,
}: {
  showPrivate?: boolean;
  showpublic?: boolean;
  className?: string;
}) {
  return showPrivate || showpublic ? (
    <div className={className}>
      <div className={clsx('flex gap-2 text-gray-700 font-normal')}>
        <div className="flex gap-1">
          <AppIcons.ui.comment className="h-6 w-6" />
          {showPrivate && <span>🚫</span>}
          {showpublic && <span>👩‍🦰</span>}
        </div>
      </div>
    </div>
  ) : null;
}
