import {
  AppCompanyMessageTemplate,
  AppCurrency,
  AppEntityUpdatePatch,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  AppCompanyMessageTemplateEditDialogProps,
  AppCompanyMessageTemplateEditFormModel,
} from './model';

export type AppCompanyMessageTemplateEditDialogActionPersistProps = {
  onCreate: ({
    messageTemplate,
  }: {
    messageTemplate: AppCompanyMessageTemplate;
  }) => any;
  onUpdate: (props: {
    messageTemplate: AppCompanyMessageTemplate;
    patch: AppEntityUpdatePatch;
  }) => any;
};

export function useAppCompanyMessageTemplateEditDialogActionPersist(
  props: AppCompanyMessageTemplateEditDialogActionPersistProps,
): AppCompanyMessageTemplateEditDialogProps {
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  const appCurrency: AppCurrency = useAppCurrencyMain({ generalSettings });

  const action: AppCompanyMessageTemplateEditDialogProps = {
    async onConfirm({ formValue: formValueInput }, initialState) {
      const { mode, defaultValue } = initialState;

      // fix form value
      const formValue: AppCompanyMessageTemplateEditFormModel =
        fixFormValue(formValueInput);

      if (mode === 'create') {
        const messageTemplate: AppCompanyMessageTemplate =
          commonEntityBuilder.buildEntity<AppCompanyMessageTemplate>({
            clubReference,
            diveCenterId,
            ...formValue.messageTemplate,
          });

        // on persiste immédiatement
        const payload: ProMultiOperationPayload =
          clubMassiveUpdatorClient.createEmptyPayload({
            logContext: 'save-message-template',
            bookingIdsToClean: [],
          });
        const clubSettingsPatch: AppEntityUpdatePatch = buildClubSettingsPatch(
          defaultValue,
          formValue,
        );
        if (clubSettingsPatch) {
          payload.updatedClubSettings = clubSettingsPatch;
        }
        payload.appCompanyMessageTemplates = {
          created: [messageTemplate],
        };

        await clubMassiveUpdatorClient.update(payload);
        if (props?.onCreate) {
          // on appelle le callback si il est défini dans l'appel de useClubDialogsActionsPersist()
          props?.onCreate({ messageTemplate });
        }
      } else if (mode === 'edit') {
        const templatePatch: AppEntityUpdatePatch = buildTemplatePatch(
          defaultValue,
          formValue,
        );
        const clubSettingsPatch: AppEntityUpdatePatch = buildClubSettingsPatch(
          defaultValue,
          formValue,
        );

        if (templatePatch || clubSettingsPatch) {
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'save-credit-note',
              bookingIdsToClean: [],
            });
          if (templatePatch) {
            if (!payload.appCompanyMessageTemplates) {
              payload.appCompanyMessageTemplates = {
                created: [],
                deletedIds: [],
                updated: [],
              };
            }
            payload.appCompanyMessageTemplates.updated.push(templatePatch);
          }
          if (clubSettingsPatch) {
            payload.updatedClubSettings = clubSettingsPatch;
          }
          await clubMassiveUpdatorClient.update(payload);
          if (props?.onUpdate) {
            // on appelle le callback si il est défini dans l'appel de useClubDialogsActionsPersist()
            props?.onUpdate({
              messageTemplate:
                formValue.messageTemplate as AppCompanyMessageTemplate,
              patch: templatePatch,
            });
          } else {
          }
        }
      }
    },
  };
  return action;
}
function fixFormValue(
  formValueInput: AppCompanyMessageTemplateEditFormModel,
): AppCompanyMessageTemplateEditFormModel {
  return {
    ...formValueInput,
    messageTemplate: {
      ...formValueInput.messageTemplate,
      // fix sms language code
      smsContent: formValueInput.messageTemplate.smsContent
        ? {
            ...formValueInput.messageTemplate.smsContent,
            message: {
              ...formValueInput.messageTemplate.smsContent.message,
              languageCode: formValueInput?.messageTemplate?.sourceLanguageCode,
            },
          }
        : undefined,
      // fix email language code
      emailContent: formValueInput.messageTemplate.emailContent
        ? {
            ...formValueInput.messageTemplate.emailContent,
            message: {
              ...formValueInput.messageTemplate.emailContent.message,
              languageCode: formValueInput?.messageTemplate?.sourceLanguageCode,
            },
          }
        : undefined,
      // whatsapp now
      whatsAppContent: formValueInput.messageTemplate.whatsAppContent
        ? {
            ...formValueInput.messageTemplate.whatsAppContent,
            message: {
              ...formValueInput.messageTemplate.whatsAppContent.message,
              languageCode: formValueInput?.messageTemplate?.sourceLanguageCode,
            },
          }
        : undefined,
    },
  };
}

function buildTemplatePatch(
  defaultValue: AppCompanyMessageTemplateEditFormModel,
  formValue: AppCompanyMessageTemplateEditFormModel,
) {
  const patchOperations = jsonPatcher.compareObjects(
    defaultValue.messageTemplate,
    {
      ...formValue.messageTemplate,
    },
    {
      // else, value won't be deleted by typeorm
      // https://github.com/typeorm/typeorm/issues/2934
      replaceDeleteByNullValue: true,
    },
  );

  if (patchOperations.length) {
    const patch: AppEntityUpdatePatch = {
      pk: defaultValue.messageTemplate._id,
      patchOperations: patchOperations,
    };
    return patch;
  }
}

function buildClubSettingsPatch(
  defaultValue: AppCompanyMessageTemplateEditFormModel,
  formValue: AppCompanyMessageTemplateEditFormModel,
) {
  const patchOperations = jsonPatcher.compareObjects(
    defaultValue.clubSettings,
    {
      ...formValue.clubSettings,
    },
    {
      // else, value won't be deleted by typeorm
      // https://github.com/typeorm/typeorm/issues/2934
      replaceDeleteByNullValue: true,
    },
  );

  if (patchOperations.length) {
    const patch: AppEntityUpdatePatch = {
      pk: defaultValue.clubSettings._id,
      patchOperations: patchOperations,
    };
    return patch;
  }
}
