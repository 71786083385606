/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  ClubSettings,
} from '@mabadive/app-common-model';
import {
  StaffMemberSortByAttribute,
  staffMembersSorter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useGlobalClasses } from 'src/AppTheme';

import {
  CollectionOrder,
  useCollectionOrder,
} from 'src/business/_core/modules/layout/components/PageableTable';
import useRouter from 'use-react-router';
import { StaffMembersTableRow } from './StaffMembersTableRow';

export const StaffMembersTable = ({
  staffMembers,
  clubSettings,
  defaultOrder,
  onOrderChanged,
  onClickStaffMember,
}: {
  staffMembers: ClubResumeStaffMember[];
  clubSettings: ClubSettings;
  defaultOrder?: CollectionOrder<StaffMemberSortByAttribute>;
  onOrderChanged?: (order: CollectionOrder<StaffMemberSortByAttribute>) => void;
  onClickStaffMember?: (staffMember: ClubResumeStaffMember) => void;
}) => {
  const globalClasses = useGlobalClasses();

  const { match } = useRouter();

  const column =
    defaultOrder && defaultOrder.column !== undefined
      ? defaultOrder.column
      : 'lastName';

  const initialOrderOptions = {
    column: column,
    asc:
      defaultOrder && defaultOrder.asc !== undefined
        ? defaultOrder.asc
        : column === 'endDate'
        ? false
        : true,
  };

  const [order, sortByColumn] = useCollectionOrder<StaffMemberSortByAttribute>(
    initialOrderOptions,
    {
      reverseColumns: ['endDate'],
      onOrderChanged,
    },
  );

  const orderedStaffMembers = useMemo(() => {
    return staffMembersSorter.sortStaffMembersBy(staffMembers, {
      attributeName: order.column,
      asc: order.asc,
      activeStaffFirst: true,
    });
  }, [order.asc, order.column, staffMembers]);

  function clickStaffMember(staffMember: ClubResumeStaffMember) {
    if (onClickStaffMember) {
      onClickStaffMember(staffMember);
    }
  }

  return (
    <div>
      <table className={'w-full divide-y divide-gray-200 text-gray-600'}>
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              colSpan={3}
              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Nom
            </th>
            <th
              scope="col"
              className="hidden xs:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Niveau
            </th>

            <th
              scope="col"
              className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              DP
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <div className="text-xs">
                Moniteur
                <br />
                plongée
              </div>
            </th>
            {clubSettings.freeDive.enabled ? (
              <th
                scope="col"
                className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Moniteur
                <br />
                apnée
              </th>
            ) : null}
            <th
              scope="col"
              className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Sécu
            </th>

            <th
              scope="col"
              className="hidden lg:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Date début
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Date fin
            </th>
            <th
              scope="col"
              className="hidden lg:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Emploi
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Dispo
              <br />
              hebdo
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {orderedStaffMembers.map((staffMember, i) => (
            <StaffMembersTableRow
              key={`${i}`}
              staffMember={staffMember}
              clubSettings={clubSettings}
              onClick={() => clickStaffMember(staffMember)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
