import clsx from 'clsx';
import React from 'react';
import {
  AppHeroIcons,
  AppIconsAction,
  DiveModeIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import {
  DashboardMetricTitleWithValue,
  DashboardMetricValueCard,
} from 'src/pages/DA-dashboard/_core';

import {
  DiveTrainingReference,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { DashboardReportPageTrainingParticipantsTable } from './DashboardReportPageTrainingParticipantsTable';

export type DashboardReportPageTrainingParticipantsComparisonState = {
  selectedTrainingReference?: DiveTrainingReference;
  hoverTrainingReference?: DiveTrainingReference;
};

export const DashboardReportPageTrainingParticipants = ({
  trainingParticipantsOverPeriod: results,
  comparisonDetails,
  title,
}: {
  trainingParticipantsOverPeriod: ProStatsFetchResults['trainingParticipantsOverPeriod'];
  comparisonDetails: DashboardReportComparisonDetails<DashboardReportPageTrainingParticipantsComparisonState>;
  title: string;
}) => {
  const data = results?.data;
  const {
    localState,
    comparisonFetchResult,
    showPeriodRangeComparison,
    // sharedState: [sharedState, setSharedState],
  } = comparisonDetails;

  return !data?.byTrainingReference ? null : (
    <>
      <div
        className={clsx(
          'w-full app-card app-p-content flex flex-col justify-between gap-2',
        )}
      >
        <div className="flex flex-col gap-2">
          <DashboardMetricTitleWithValue
            label={title}
            description="Plongées de formation réalisées sur cette période"
            valueClassName="bg-gray-600 text-white"
            icon={EntitiesIcons.participants}
            value={data?.totalCount}
          />

          <div
            className={clsx(
              'mt-1 grid grid-cols-2 sm:grid-cols-3 gap-x-2 gap-y-1',
              showPeriodRangeComparison
                ? '2xl:grid-cols-5 '
                : 'md:grid-cols-5 ',
            )}
          >
            <DashboardMetricValueCard
              iconClassName="text-dive-mode-training"
              label={'Plongeurs'}
              value={data?.diversCount}
              unitIcon={EntitiesIcons.diver}
            />
            <DashboardMetricValueCard
              iconClassName="text-app-secondary"
              label={'Plongées'}
              value={data?.participantsCount}
              unitIcon={DiveModeIcons.training}
            >
              {/* {averageDivesPerDiver > 0 ? (
              <div className="text-sm font-normal text-gray-600">
                (~ {numberFormatter.toFixedIntegerString(averageDivesPerDiver, 1)} /
                plongeur)
              </div>
            ) : undefined} */}
            </DashboardMetricValueCard>{' '}
            <DashboardMetricValueCard
              iconClassName="text-app-secondary"
              label={'Imputées'}
              value={data?.participantsCountPurchased}
              unitIcon={AppIconsAction.impute}
            >
              {/* {averageDivesPerDiver > 0 ? (
              <div className="text-sm font-normal text-gray-600">
                (~ {numberFormatter.toFixedIntegerString(averageDivesPerDiver, 1)} /
                plongeur)
              </div>
            ) : undefined} */}
            </DashboardMetricValueCard>
            <DashboardMetricValueCard
              label={'Annulations club'}
              value={data?.cancelCountClub}
              unitIcon={AppHeroIcons.actionCancel2}
            />
            <DashboardMetricValueCard
              label={'Annul. plongeur'}
              value={data?.cancelCountDiver}
              unitIcon={AppHeroIcons.actionCancel}
            />
          </div>
        </div>
        <DashboardReportPageTrainingParticipantsTable
          // className="self-start"
          comparisonDetails={comparisonDetails}
          trainingParticipantsOverPeriod={results}
        />
      </div>
    </>
  );
};
