import {
  DIVE_SESSION_THEMES,
  DiveSessionTheme,
} from '@mabadive/app-common-model';
import { diveSessionThemeFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useDiveSessionThemesOptions({
  currentTheme,
  scope,
}: {
  currentTheme?: DiveSessionTheme;
  scope: 'all' | 'session-settings';
}): ValueLabel<DiveSessionTheme, string>[] {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const themes = useMemo(
    () =>
      scope === 'all'
        ? DIVE_SESSION_THEMES
        : DIVE_SESSION_THEMES.filter(
            (x) =>
              x === currentTheme ||
              clubSettings.general.sessions.themes.includes(x),
          ),
    [clubSettings.general.sessions.themes, currentTheme, scope],
  );

  const options = useMemo(
    () =>
      themes.map((x) => {
        const option: ValueLabel<DiveSessionTheme, string> = {
          value: x,
          label: diveSessionThemeFormatter.formatTheme(x),
        };
        return option;
      }),
    [themes],
  );
  return options;
}
