import React, { useMemo } from 'react';

import clsx from 'clsx';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AppFormControlV2,
  AppInputBooleanSwitch,
  AppToogleButtonGroup,
} from 'src/lib/form';
import {
  DashboardReportPagePeriodComparisonSync,
  DashboardReportPageViewParameters,
} from './model';
import { DashboardReportPageLocalState } from './useDashboardReportPageLocalState.hook';

export const DashboardReportPageCompareActionsBar = ({
  localState,
  className,
}: {
  localState: DashboardReportPageLocalState;
  className?: string;
}) => {
  const { state, actions } = localState;
  const { viewParameters, setViewParameters } = state;

  const dashboardReportPagePeriodComparisonSyncOptions: ValueLabel<
    DashboardReportPagePeriodComparisonSync,
    string
  >[] = useMemo(() => {
    const options: ValueLabel<
      DashboardReportPagePeriodComparisonSync,
      string
    >[] = [];
    if (viewParameters.periodRange?.period === 'month') {
      options.push({
        value: 'previous-year',
        label: 'ANNÉE -1',
      });
      options.push({
        value: 'previous-month',
        label: 'MOIS -1',
      });
      if (viewParameters.showPeriodRangeComparison) {
        options.push({
          value: 'none',
          label: 'AUTRE',
        });
      }
    } else if (
      viewParameters.periodRange?.period === 'year' ||
      viewParameters.periodRange?.period === 'year-fiscal'
    ) {
      options.push({
        value: 'previous-year',
        label: 'ANNÉE -1',
      });
      if (viewParameters.showPeriodRangeComparison) {
        options.push({
          value: 'none',
          label: 'AUTRE',
        });
      }
    }

    return options;
  }, [
    viewParameters.periodRange?.period,
    viewParameters.showPeriodRangeComparison,
  ]);

  return (
    <div className={clsx('flex gap-x-2 gap-y-1 flex-wrap', className)}>
      <AppFormControlV2
        label={'Comparer'}
        renderComponent={() => (
          <AppInputBooleanSwitch
            value={viewParameters.showPeriodRangeComparison}
            onChange={(showPeriodRangeComparison) => {
              const newViewParameters: DashboardReportPageViewParameters =
                showPeriodRangeComparison
                  ? {
                      ...viewParameters,
                      showPeriodRangeComparison,
                    }
                  : {
                      ...viewParameters,
                      showPeriodRangeComparison: false,
                      ajustPeriodRangeToCurrentDate: false,
                      autoSyncPeriod: 'none',
                    };

              const periodRange = actions.ajustPeriodRange(newViewParameters);

              const periodRangeComparison = actions.buildPeriodRangeComparison({
                ...newViewParameters,
                periodRange,
              });

              setViewParameters({
                ...viewParameters,
                showPeriodRangeComparison,
                periodRangeComparison,
              });
            }}
          />
        )}
      />
      <AppToogleButtonGroup
        selected={viewParameters.autoSyncPeriod}
        onChanges={(autoSyncPeriod) => {
          const ajustPeriodRangeToCurrentDate = false;
          // autoSyncPeriod === 'previous-month' ||
          // autoSyncPeriod === 'previous-year';

          const newViewParameters: DashboardReportPageViewParameters = {
            ...viewParameters,
            ajustPeriodRangeToCurrentDate,
            showPeriodRangeComparison: true,
            autoSyncPeriod,
          };

          const periodRange = actions.ajustPeriodRange(newViewParameters);

          const periodRangeComparison =
            actions.buildPeriodRangeComparison(newViewParameters);

          setViewParameters({
            ...newViewParameters,
            periodRange,
            periodRangeComparison,
          });
        }}
        items={dashboardReportPagePeriodComparisonSyncOptions}
        color={'green'}
        theme={'strong'}
      />
      {viewParameters.showPeriodRangeComparison &&
        viewParameters.autoSyncPeriod &&
        (viewParameters.autoSyncPeriod === 'previous-month' ||
          viewParameters.autoSyncPeriod === 'previous-year') && (
          <AppFormControlV2
            label={
              viewParameters.periodRange?.period === 'month'
                ? 'Début du mois'
                : 'Début de l\'année'
            }
            helpDescription={
              viewParameters.periodRange?.period === 'month'
                ? 'Comparer la progression du mois en cours par rapport au mois précédent à la même période'
                : 'Comparer la progression de l\'année en cours par rapport à l\'année précédente à la même période'
            }
            renderComponent={() => (
              <AppInputBooleanSwitch
                value={viewParameters.ajustPeriodRangeToCurrentDate}
                onChange={(ajustPeriodRangeToCurrentDate) => {
                  const newViewParameters: DashboardReportPageViewParameters = {
                    ...viewParameters,
                    ajustPeriodRangeToCurrentDate,
                  };

                  const periodRange =
                    actions.ajustPeriodRange(newViewParameters);

                  const periodRangeComparison =
                    actions.buildPeriodRangeComparison({
                      ...newViewParameters,
                      periodRange,
                    });

                  setViewParameters({
                    ...newViewParameters,
                    ajustPeriodRangeToCurrentDate,
                    periodRange,
                    periodRangeComparison,
                  });
                }}
              />
            )}
          />
        )}
    </div>
  );
};
