import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  DIVE_MODE_BEGINNER,
  DIVE_MODE_WITH_CERTIFICATION,
  DiveMode,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
  AppMultiCheckboxesRHF,
} from 'src/lib/form';
import { CustomerSpaceConfigFormModel } from '../model';

// const CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES_OPTIONS =
//   CLUB_SETTINGS_UI_PLANNING_SHOW_PASS_MODES.map((value) => {
//     const option: ValueLabel<ClubSettingsUIPlanningShowPassMode> = {
//       label: (value === 'consumed'
//         ? 'consommé (8/10)'
//         : 'restant (2)'
//       ).toUpperCase(),
//       value,
//     };
//     return option;
//   });

export function CustomerSpaceConfigFormCustomerUpdateExpectedDive({
  form,
}: {
  form: UseFormReturn<CustomerSpaceConfigFormModel>;
}) {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const formPrefix = 'clubSettings.publicSettings.customerSpace' as const;
  const clubSettings = useClubSettings();

  const diveModesOptionsBeginner: ValueLabel<DiveMode>[] = useMemo(
    () =>
      DIVE_MODE_BEGINNER.map((diveMode) => ({
        value: diveMode,
        label: diveModeFormatter.formatDiveMode(diveMode, {
          format: 'long-label',
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
      })),
    [clubSettings?.ui?.diveMode],
  );

  const diveModesOptionsWithCertification: ValueLabel<DiveMode>[] = useMemo(
    () =>
      DIVE_MODE_WITH_CERTIFICATION.map((diveMode) => ({
        value: diveMode,
        label: diveModeFormatter.formatDiveMode(diveMode, {
          format: 'long-label',
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
      })),
    [clubSettings?.ui?.diveMode],
  );

  const [
    diversExpectedDiveInfo,
    expectedDiveModesBeginner,
    expectedDiveModesWithCertification,
  ] = useWatch({
    control,
    name: [
      `${formPrefix}.defaultBookingConfig.pendingList.diversExpectedDiveInfo`,
      `${formPrefix}.displayFields.diversExpectedDiveInfo.expectedDiveModesBeginner`,
      `${formPrefix}.displayFields.diversExpectedDiveInfo.expectedDiveModesWithCertification`,
    ],
  });

  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <div className={clsx(open && 'bg-gray-100')}>
          <Disclosure.Button
            className={clsx(
              'flex w-full justify-between rounded-lg text-left text-sm font-medium',
              open &&
                'hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75',
            )}
          >
            <div onClick={(e) => e.stopPropagation()}>
              <AppFormControlRHF_Deprecated
                className="my-1 w-full xs:col-span-6 sm:col-span-6"
                control={control}
                name={`${formPrefix}.defaultBookingConfig.pendingList.diversExpectedDiveInfo`}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF
                    className="w-full"
                    {...props}
                    label={'prestations souhaitées'}
                    label2={'Baptềme, explo...'}
                    onChange={(value) => {
                      if (value) {
                        setValue(
                          `${formPrefix}.defaultBookingConfig.pendingList.diversDivingInfo`,
                          true,
                        );
                        setValue(
                          `${formPrefix}.defaultBookingConfig.customerUi.enableCustomerUpdate`,
                          true,
                        );
                        setValue(
                          `${formPrefix}.displayFields.diversExpectedDiveInfo.comment.edit`,
                          true,
                        );
                        if (!expectedDiveModesBeginner?.length) {
                          setValue(
                            `${formPrefix}.displayFields.diversExpectedDiveInfo.expectedDiveModesBeginner`,
                            DIVE_MODE_BEGINNER,
                          );
                        }
                        if (!expectedDiveModesWithCertification?.length) {
                          setValue(
                            `${formPrefix}.displayFields.diversExpectedDiveInfo.expectedDiveModesWithCertification`,
                            DIVE_MODE_WITH_CERTIFICATION,
                          );
                        }
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className="flex gap-2 text-gray-600 ">
              <span>Configuration avancée</span>
              <ChevronUpIcon
                className={`${
                  open ? 'rotate-180 transform' : ''
                } h-5 w-5 text-gray-500`}
              />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            {
              <div className="mb-3 flex flex-col gap-1">
                <AppFormControlRHF_Deprecated
                  className="w-full xs:col-span-6 sm:col-span-6"
                  control={control}
                  label="Prestations souhaitées débutant"
                  name={`${formPrefix}.displayFields.diversExpectedDiveInfo.expectedDiveModesBeginner`}
                  renderComponent={(props) => (
                    <AppMultiCheckboxesRHF
                      {...props}
                      options={diveModesOptionsBeginner}
                    />
                  )}
                />
                <AppFormControlRHF_Deprecated
                  className="w-full xs:col-span-6 sm:col-span-6"
                  control={control}
                  label="Prestations souhaitées diplômés"
                  name={`${formPrefix}.displayFields.diversExpectedDiveInfo.expectedDiveModesWithCertification`}
                  renderComponent={(props) => (
                    <AppMultiCheckboxesRHF
                      {...props}
                      options={diveModesOptionsWithCertification}
                    />
                  )}
                />
                <AppFormControlRHF_Deprecated
                  className="w-full xs:col-span-6 sm:col-span-6"
                  control={control}
                  label={'Commentaire'}
                  name={`${formPrefix}.displayFields.diversExpectedDiveInfo.comment.edit`}
                  renderComponent={(props) => (
                    <AppInputBooleanRHF
                      type="checkbox"
                      className="-ml-2 scale-125 block mx-auto mt-2.5"
                      {...props}
                    />
                  )}
                />
              </div>
            }
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
