/* eslint-disable jsx-a11y/alt-text */
import {
  AppBookletPageGql_Company,
  EcommerceCategoryGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useAppTheme } from '../../../../../../../business/club/data/hooks';
import { AppRichTextH1 } from '../../../../../../../lib/form/components/AppRichText/rendering/elements';
import { BookletEditorDisplayWidth } from '../../../ClubSettingsOnlineBookingConfigPage/tabs/02.products/view/BookletEditor_EditablePreview';

export const EcommerceCategoryPreviewCover = ({
  displayWidth,
  category,
  appBookletPage,
  productsLength,
  children,
  className,
}: {
  displayWidth: BookletEditorDisplayWidth;
  category: Pick<EcommerceCategoryGql_Company, 'name' | 'details'>;
  appBookletPage: Partial<AppBookletPageGql_Company>;
  productsLength: number;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}) => {
  const theme = useAppTheme();

  const cover = appBookletPage?.mainContent?.cover;

  return (
    category && (
      <div className={clsx('w-full grid overflow-y-auto', className)}>
        {cover ? (
          <>
            {cover.image && (
              <img
                className="relative object-center w-full rounded-t-xl"
                src={cover.image.publicUrl}
              />
            )}
            <div className="py-2 px-4">
              <div
                className={clsx(
                  'text-base font-bold w-full text-left',
                  // displayWidth === 'mobile'
                  //   ? 'text-md'
                  //   : 'text-md lg:text-lg',
                )}
                style={{ color: theme.title1.textColor }}
              >
                {cover?.title?.toUpperCase()}
              </div>
              {cover?.subTitle && (
                <div
                  className={clsx('text-sm font-medium w-full text-left')}
                  style={{ color: theme.title2.textColor }}
                >
                  {cover?.subTitle}
                </div>
              )}
              <div className="my-4 text-sm font-normal text-app-secondary">
                <b>{productsLength} activités</b>
                {category.details.minAge > 0 && (
                  <span> à partir de {category.details.minAge} ans</span>
                )}
              </div>
              {children && <div className="my-4">{children}</div>}
            </div>
          </>
        ) : (
          <>
            <AppRichTextH1 theme={theme}>{category.name}</AppRichTextH1>
            {children && <div className="my-4">{children}</div>}
          </>
        )}
      </div>
    )
  );
};
