import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { AppTabTailwind } from '../../../../../../../business/_core/modules/layout';

import {
  EcommerceCategoryEditorFormTabId,
  ecommerceCategoryEditorFormTabLabelFormatter,
} from '../EcommerceCategoryEditor/services';
import {
  EcommerceProductArticleEditorFormContentWithPreview,
  EcommerceProductArticleEditorFormSettings,
} from './form';
import { EcommerceProductArticleEditorLocalState } from './useEcommerceProductArticleEditorPanelLocalState.hook';

export const EcommerceProductArticleEditorPanelEditForm = ({
  localState,
}: {
  localState: EcommerceProductArticleEditorLocalState;
}) => {
  const { state, actions } = localState;

  const [appBookletPage, productArticleName] = useWatch({
    control: state.form.control,
    name: ['appBookletPage', 'productArticle.name'],
  });

  const tabs = useMemo(() => {
    const tabsIds: EcommerceCategoryEditorFormTabId[] = [
      'config',
      'cover',
      'content',
    ];

    const tabs: AppTabTailwind<EcommerceCategoryEditorFormTabId>[] =
      tabsIds.map((tabId) => ({
        id: tabId,
        label: ecommerceCategoryEditorFormTabLabelFormatter.format(tabId),
      }));
    return tabs;
  }, []);

  const [selectedTab, setSelectedTab] =
    React.useState<EcommerceCategoryEditorFormTabId>('config');

  return (
    <div className="grid gap-4 max-w-6xl">
      {/* <AppTabsTailwind
        className="w-full"
        tabs={tabs}
        selected={selectedTab}
        theme="underline"
        breakpoint="sm"
        onChange={(tabId) => setSelectedTab(tabId)}
      /> */}
      {/* {selectedTab === 'cover' && (
        <EcommerceProductArticleEditorFormCoverWithPreview
          localState={localState}
        />
      )} */}
      {/* {selectedTab === 'content' && ( */}
      <EcommerceProductArticleEditorFormContentWithPreview
        localState={localState}
      />
      <EcommerceProductArticleEditorFormSettings localState={localState} />
    </div>
  );
};
