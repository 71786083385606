import React, { FC } from 'react';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export const BookingParticipantViewDialogFixedButtonsBar: FC<{
  onSubmit: () => void;
  onClose: () => void;
  hasChanges: boolean;
  isValid: boolean;
  isPersistInProgress: boolean;
  className?: string;
}> = ({
  onSubmit,
  onClose,
  hasChanges,
  isValid,
  isPersistInProgress,
  className,
}) => {
  const baseClasses = `w-full bg-white py-2 ${className}`;

  return hasChanges ? (
    <div className={`${baseClasses} flex gap-5 justify-between`}>
      <AppButton
        className="uppercase"
        fullWidth={true}
        size="normal"
        icon={AppHeroIcons.actionCancel}
        color={'danger-outline-light'}
        tabIndex={500}
        onClick={() => {
          onClose();
        }}
      >
        {hasChanges ? 'Annuler les changements' : 'Retour'}
      </AppButton>

      <AppButton
        className="uppercase"
        fullWidth={true}
        size="normal"
        disabled={!isValid || isPersistInProgress}
        icon={AppHeroIcons.check}
        color="primary-bg"
        onClick={() => {
          isValid && onSubmit();
        }}
      >
        Confirmer
      </AppButton>
    </div>
  ) : (
    <div className={`${baseClasses} flex justify-center`}>
      <AppButton
        className="uppercase max-w-xs"
        fullWidth={true}
        size="normal"
        disabled={isPersistInProgress}
        icon={AppHeroIcons.actionClose}
        color="gray-outline-light"
        tabIndex={500}
        onClick={() => {
          onClose();
        }}
      >
        Fermer
      </AppButton>
    </div>
  );
};
