/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubDiveSessionHeaderCard } from 'src/business/club/modules/club-dive-session/components';
import { AppFormControlRHF_Deprecated, AppInputBooleanRHF } from 'src/lib/form';
import {
  useAppCurrencyMain,
  useBookingAgenciesOptions,
} from 'src/pages/_components/options';
import { DiveSessionEditorMassiveDiveGroup } from '../_components';
import { DiveSessionEditorMassiveDialogLocalState } from '../useDiveSessionEditorMassiveDialogLocalState.hook';

export const DiveSessionEditorMassiveResumeWithOptionsPanel = ({
  localState,
  className,
}: {
  localState: DiveSessionEditorMassiveDialogLocalState;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [actionId, selectedSessionDef] = useWatch({
    control,
    name: ['actionId', 'selectedSessionDef'],
  });

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  const agenciesOptions = useBookingAgenciesOptions();

  return (
    <div className={clsx('grid gap-8', className)}>
      <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
        Confirmation
      </h3>
      <div className="grid gap-4">
        <div className="w-full flex justify-between gap-x-8 gap-y-2 flex-wrap text-base text-app-primary font-medium uppercase">
          <div className="flex-grow">
            {actionId === 'move-participants'
              ? 'Déplacer les participants'
              : 'Copier les participants sur une autre session'}{' '}
            :{' '}
          </div>
        </div>
        <div className="grid grid-cols-1 md:flex gap-4">
          <AppFormControlRHF_Deprecated
            label={'Conserver les palanquées'}
            control={control}
            name="cloneParticipantsGroups"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description="conserver les mêmes palanquées"
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            label={'Conserver les moniteurs et guides de palanquées'}
            control={control}
            name="cloneGuidesAndInstructors"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description="conserver les mêmes moniteurs"
              />
            )}
          />
        </div>
      </div>
      <div className="grid gap-4">
        <div className="w-full flex justify-between gap-x-8 gap-y-2 flex-wrap text-base text-app-primary font-medium uppercase">
          <div className="flex-grow">Session de destination : </div>
        </div>
        <ClubDiveSessionHeaderCard
          className="flex-grow bg-white"
          diveSession={selectedSessionDef}
          widthMode="large"
        />
      </div>
      <div className="grid gap-4">
        <div className="w-full flex justify-between gap-x-8 gap-y-2 flex-wrap text-base text-app-primary font-medium uppercase">
          <div className="flex-grow">
            Plongeurs à{' '}
            {actionId === 'move-participants' ? 'déplacer' : 'copier'}{' '}
            <b>
              ({data.participantsCheckState.participantsCheckedCount}/
              {initialState.originalParticipants.length})
            </b>{' '}
            :
          </div>
        </div>
        <div>
          {data.participantsCheckFull.length !== 0 && (
            <DiveSessionEditorMassiveDiveGroup
              participants={data.participantsCheckFull}
              localState={localState}
              group={undefined}
              enableSelection={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};
