/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { EcommerceCategoryGql_Company } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppDocumentationLink,
  AppLoadableContentContainer,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING } from '../../../../SETTINGS_CUSTOMERS.const';
import {
  EcommerceCustomerCurrentPageLink,
  ProProductsPage_CategoryProductContentCard,
} from '../../components';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../../useClubSettingsOnlineBookingProductsPageLocalState.hook';

export const ProProductsPage_ProductViewPanel = ({
  localState,
}: {
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const { state, data, dialogs, actions } = localState;

  const category: EcommerceCategoryGql_Company = useMemo(() => {
    return data.categories.find((x) => x._id === state.viewState?.categoryId);
  }, [data.categories, state.viewState?.categoryId]);

  return (
    <>
      {/* {!!operationInProgressMessage && (
        <div className={'app-card app-p-content--lg'}>
          <LoadingProgressBar>{operationInProgressMessage}</LoadingProgressBar>
        </div>
      )} */}
      <AppPageContainer className={'bg-white'}>
        <AppBreadcrumbBar
          color={AppIconsSettings.customers.color}
          items={[
            {
              icon: AppIconsSettings.general,
              label: 'Paramètres',
              url: '/club/settings',
            },
            {
              icon: AppIconsSettings.customers.onlineBooking,
              label: 'Vente en ligne',
              url: SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING.url,
            },
            {
              label: 'Catégories',
              url: SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING.url,
            },
          ]}
        >
          <AppDocumentationLink
            className="block h-9 w-9 md:h-11 md:w-11"
            url="https://docs.mabadive.com/docs/vente-en-ligne/presentation_vente-en-ligne"
          />
        </AppBreadcrumbBar>
        <AppPageContentContainer
          paddingBottom={false}
          className="bg-gray-50 app-p-content"
        >
          <AppLoadableContentContainer {...state.loadableContent}>
            <a
              onClick={() => {
                actions.selectCategory(category._id);
              }}
              className="text-sm uppercase text-gray-600 py-4 cursor-pointer hover:underline"
            >
              ← Retour à la catégorie "{category.name}"
            </a>
            <div className="mt-4 app-p-content grid gap-4 sm:gap-8 lg:gap-16">
              <ProProductsPage_CategoryProductContentCard
                className="app-card app-p-content rounded-t-xl app-card-highlight max-w-5xl"
                categoryId={state.viewState?.categoryId}
                productId={state.viewState?.productId}
                localState={localState}
              />
              <div className="app-card app-p-content grid gap-4">
                <EcommerceCustomerCurrentPageLink localState={localState} />
              </div>
            </div>
          </AppLoadableContentContainer>
        </AppPageContentContainer>
      </AppPageContainer>
    </>
  );
};
