import { DiveMode } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { CSSProperties, FC } from 'react';
import { useDiveModeColor } from '../../../../../club/data/hooks';

export const DiveModeBadge: FC<{
  diveMode: DiveMode;
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
  clubReference: string;
  children?: React.ReactNode | React.ReactNode[];
}> = ({
  diveMode,
  clubReference,
  className,
  style = {},
  onClick,
  children,
}) => {
  const diveModeColor = useDiveModeColor(diveMode);
  return (
    <div
      style={{ ...style, backgroundColor: diveModeColor }}
      className={clsx(
        'text-center px-0.5 py-0.5 rounded text-white app-text-shadow-sm shadow-gray-500',
        // `bg-dive-mode-${diveModeTailwindClass}`,
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
