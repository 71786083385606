import { AppCompanyMessageContext } from '@mabadive/app-common-model';
import { useClubSettings } from './useClubSettings.hook';

export function useClubSettingsMessageTargetsCustomer({
  context,
}: {
  context: Extract<AppCompanyMessageContext, 'customer'>;
}): {
  email: {
    buttonEnabled: boolean;
    channelEnabled: boolean;
  };
  sms: {
    channelEnabled: boolean;
  };
  phone: {
    buttonEnabled: boolean;
    channelEnabled: boolean;
  };
  whatsApp: {
    buttonEnabled: boolean;
    channelEnabled: boolean;
  };
} {
  const clubSettings = useClubSettings();

  const [
    channelEmailEnabled,
    channelSmsEnabled,
    channelPhoneEnabled,
    channelWhatsAppEnabled,
    customerEmailButton,
    customerPhoneButton,
    customerWhatsAppButton,
  ] = [
    clubSettings?.communication?.channels?.email?.enabled,
    clubSettings?.communication?.channels?.sms?.enabled,
    clubSettings?.communication?.channels?.phone?.enabled,
    clubSettings?.communication?.channels?.whatsApp?.enabled,
    clubSettings?.communication?.booking?.email?.enabled,
    clubSettings?.communication?.booking?.sms?.enabled,
    clubSettings?.communication?.booking?.whatsApp?.enabled,
    clubSettings?.communication?.session?.email?.enabled,
    clubSettings?.communication?.session?.sms?.enabled,
    clubSettings?.communication?.session?.whatsApp?.enabled,
    clubSettings?.communication?.customer?.emailButton?.enabled,
    clubSettings?.communication?.customer?.phoneButton?.enabled,
    clubSettings?.communication?.customer?.whatsAppButton?.enabled,
  ];

  return {
    email: {
      channelEnabled: channelEmailEnabled,
      buttonEnabled: customerEmailButton,
    },
    phone: {
      channelEnabled: channelPhoneEnabled,
      buttonEnabled: customerPhoneButton,
    },
    sms: {
      channelEnabled: channelSmsEnabled,
    },
    whatsApp: {
      channelEnabled: channelWhatsAppEnabled,
      buttonEnabled: customerWhatsAppButton,
    },
  };
}
