import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppLoader,
  AppPageContainer,
  AppPageContentContainer,
  AppTabsBar,
  AppTabsBarTab,
} from 'src/business/_core/modules/layout';
import {
  DASHBOARD_SPACE_TABS,
  DashboardTabId,
} from '../_core/components/DashboardTab';

import { ClubDialogsProvider } from 'src/pages/_dialogs';
import { dashboardUrlBuilder } from '../_core/services';
import { DashboardHomePanel } from './DashboardHomePanel';
import {
  DashboardHomePageLocalState,
  useDashboardHomePageLocalState,
} from './useDashboardHomePageLocalState';

export const DashboardHomePage = () => {
  const localState: DashboardHomePageLocalState =
    useDashboardHomePageLocalState();

  const { state, data } = localState;
  const { loadingInProgress, dialogsState } = state;
  const { fetchResult } = data;

  // const [exportToken, setExportToken] = useState<string>();
  const redirectTo = useRedirect();

  return (
    <>
      <AppPageContainer className={'bg-white '}>
        <ClubDialogsProvider dialogsState={dialogsState}>
          <AppTabsBar
            tabs={DASHBOARD_SPACE_TABS}
            value="home"
            onChange={(tab) => selectTab(tab)}
          />

          <AppPageContentContainer className="bg-gray-50 app-px-content">
            {loadingInProgress && !fetchResult ? (
              <AppLoader className="my-4" />
            ) : (
              <DashboardHomePanel localState={localState} />
            )}
          </AppPageContentContainer>
        </ClubDialogsProvider>
      </AppPageContainer>
    </>
  );
  function selectTab(tab: AppTabsBarTab<DashboardTabId>) {
    return redirectTo(
      dashboardUrlBuilder.buildTabUrl({
        tabId: tab.id,
      }),
    );
  }
};
