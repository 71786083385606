import { ClubBaseDataLoadingState } from '@mabadive/app-common-model';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { accountStore, clubStore } from 'src/business/_core/store';
import { authenticationStore } from 'src/business/auth/services';
import {
  clubDiverStore,
  clubEventStore,
  currentClubResumeStore,
  currentDiveCenterResumeStore,
  dailyConfigStore,
} from '../../store-repository';
import { clubDiveSessionStore } from '../../store-repository/dive-sessions/store';
import { ClubBaseFetchResult } from './ClubBaseFetchResult.type';

export const clubBaseDataStore = {
  setData,
  clearData,
};

function clearData(): void {
  clubStore.initialDataLoaded.set(false);

  accountStore.authUser.unload();
  accountStore.profile.unload();
  clubStore.trackingSession.unload();
  clubStore.loadingParams.unload();
  clubDiverStore.resetStore();
  clubDiveSessionStore.resetStore();
  currentClubResumeStore.resetStore();
  currentDiveCenterResumeStore.resetStore();
  clubEventStore.resetStore();
  dailyConfigStore.resetStore();
}

function setData(
  fetchResult: ClubBaseFetchResult,
  {
    loadedWeekTimestamps,
  }: {
    loadedWeekTimestamps: number[];
  },
): void {
  logFetResultSizes(fetchResult);
  // currentClubResumeStore.currentClubResume.set(fetchResult.clubResume);

  const user = authenticationStore.auth.getSnapshot()?.user;
  const diveCenterId = user?.diveCenterIdFocus;
  currentDiveCenterResumeStore.currentDiveCenterResumeId.set(diveCenterId);

  // const diveCenter = fetchResult.clubResume?.diveCenters.find(
  //   (x) =>
  //     // si c'est un user sans dive center, on prend le premier de la liste
  //     !diveCenterId || x._id === diveCenterId,
  // );

  accountStore.profile.set(fetchResult.accountProfile);
  accountStore.authUser.set(fetchResult.authUser);

  const diversTotalCount = fetchResult.diversCount.aggregate.count;
  const diversLoadedCount = 0; // fetchResult.clubDiversFull.length;
  const loadingState: ClubBaseDataLoadingState = {
    minDate: undefined,
    divers: {
      partial: diversLoadedCount !== diversTotalCount,
      loadedCount: diversLoadedCount,
      totalCount: diversTotalCount,
    },
  };
  clubDiverStore.setInitialData({
    clubDiversFull: [], // fetchResult.clubDiversFull,
    totalCount: diversTotalCount,
  });
  clubDiveSessionStore.setInitialData({
    loadedWeekTimestamps,
    clubDiversResumes: [], // fetchResult.diveSessionResumes, (24/11/2022: on désactive le chargement des données au lancement)
  });
  clubEventStore.setInitialData({
    loadedWeekTimestamps,
    clubEvents: [], // fetchResult.clubEvents,
  });
  dailyConfigStore.setInitialData({
    loadedWeekTimestamps,
    dailyConfigs: [],
  });

  clubStore.loadingState.set(loadingState);
  clubStore.initialDataLoaded.set(true);
}
function logFetResultSizes(fetchResult: ClubBaseFetchResult) {
  if (appWebConfig.envId === 'dev') {
    Object.keys(fetchResult).forEach((k) => {
      const arr = (fetchResult as any)[k] as unknown as any[];
      if (arr.length) {
        appLogger.warn(
          `FETCHED: ${k} array(${arr.length}) - size: ${
            JSON.stringify(arr).length
          }`,
        );
      } else {
        appLogger.warn(
          `FETCHED: ${k} object - size: ${JSON.stringify(arr).length}`,
        );
      }
    });
  }
}
