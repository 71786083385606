/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';

import {
  CLUB_STAFF_MEMBER_JOB_TYPES,
  ClubStaffMemberJobType,
  ClubStaffMemberRole,
  DIVE_SERVICE_ORGANIZATION_REFERENCES,
  DiveServiceOrganizationReference,
  SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES,
  SCUBA_DIVING_INSTRUCTOR_LEVELS,
  STAFF_ROLE_TAGS,
  ScubaDivingInstructorDegreeName,
  ScubaDivingInstructorLevel,
} from '@mabadive/app-common-model';
import {
  arrayBuilder,
  clubStaffMemberJobTypeFormatter,
  equipmentFormatter,
} from '@mabadive/app-common-services';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AppFormControlRHF,
  AppInputBooleanRHF,
  AppInputRHF,
  AppMultiCheckboxesRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { useWeekIsoDaysOptions } from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { StaffMemberEditDialogLocalState } from '../useStaffMemberEditDialogLocalState.hook';
import { StaffMemberEditFormAvailabilitiesCard } from './StaffMemberEditFormAvailabilities/StaffMemberEditFormAvailabilitiesCard';
import { StaffMemberEquipmentFormGroup } from './StaffMemberEquipmentFormGroup';

const DIVE_ORGANIZATION_OPTIONS = DIVE_SERVICE_ORGANIZATION_REFERENCES.map(
  (reference) => {
    const option: ValueLabel<DiveServiceOrganizationReference> = {
      label: reference.toUpperCase(),
      value: reference,
    };
    return option;
  },
);

const SCUBA_DIVING_INSTRUCTOR_LEVEL_OPTIONS =
  SCUBA_DIVING_INSTRUCTOR_LEVELS.map((level) => {
    const option: ValueLabel<ScubaDivingInstructorLevel> = {
      label: level,
      value: level,
    };
    return option;
  });

export const StaffMemberEditForm = ({
  localState,
  className,
}: {
  localState: StaffMemberEditDialogLocalState;
  className?: string;
}) => {
  const {
    state: { form },
  } = localState;
  const { control, setValue } = form;

  const diveCenterResume = useDiveCenterResume();

  const hasAnyStaffEquipmentEnabled =
    diveCenterResume.privateSettings?.equipment?.equipments?.find(
      (x) => x.enableStaffEquipment,
    ) !== undefined;

  const weekIsoDaysOptions = useWeekIsoDaysOptions();

  const STAFF_ROLE_OPTIONS = useMemo(
    () =>
      STAFF_ROLE_TAGS.map((roleTag) => {
        const option: ValueLabel<ClubStaffMemberRole> = {
          label: roleTag.content.label,
          value: roleTag.reference,
        };
        return option;
      }),
    [],
  );

  const STAFF_MEMBER_JOB_TYPES_OPTIONS = useMemo(
    () =>
      CLUB_STAFF_MEMBER_JOB_TYPES.map((jobType) => {
        const option: ValueLabel<ClubStaffMemberJobType> = {
          label: clubStaffMemberJobTypeFormatter.formatJobType(jobType),
          value: jobType,
        };
        return option;
      }),
    [],
  );

  const {
    fields: availabilitiesFields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
  } = useFieldArray({
    control,
    name: 'staffMember.availabilities',
  });

  const [
    birthdate,
    scubaDivingInstructorDegreeLevel,
    scubaDivingInstructorDegreeName,
  ] = useWatch({
    control,
    name: [
      'staffMember.profile.birthdate',
      'staffMember.scubaDivingInstructor.degree.level',
      'staffMember.scubaDivingInstructor.degree.name',
    ],
  });

  const SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATE_OPTIONS = useMemo(() => {
    const templates = SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES.filter(
      (x) =>
        !scubaDivingInstructorDegreeLevel ||
        scubaDivingInstructorDegreeLevel === x.level,
    ).map((x) => x.name);

    return arrayBuilder.filterDuplicated(templates).map((degreeName) => {
      const option: ValueLabel<ScubaDivingInstructorDegreeName> = {
        label: degreeName,
        value: degreeName,
      };
      return option;
    });
  }, [scubaDivingInstructorDegreeLevel]);

  return (
    <div className={className}>
      <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Profil
      </h2>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4  gap-4'
        }
      >
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Nom"
          control={control}
          required={false}
          name={'staffMember.profile.lastName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Prénom"
          control={control}
          name={'staffMember.profile.firstName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Téléphone"
          control={control}
          name={'staffMember.profile.phoneNumber'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Adresse e-mail"
          control={control}
          validation={{
            email: true,
          }}
          name={'staffMember.profile.emailAddress'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Date de naissance"
          required={false}
          control={control}
          name={'staffMember.profile.birthdate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              required={false}
              className="w-full"
              initialView={birthdate ? 'weeks' : 'years'}
              maxYear={new Date().getFullYear()}
            />
          )}
        />
        <AppFormControlRHF
          className={'md:col-span-2 lg:col-span-6 w-full'}
          label="Permis bateau"
          control={control}
          name={'staffMember.profile.boatingLicence'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>
      <div className={'w-full'}>
        <AppFormControlRHF
          className={'w-full'}
          label="Commentaire général"
          control={control}
          name={'staffMember.profile.comment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
      <div className={'w-full'}>
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label={'Fonctions'}
          control={control}
          name={'staffMember.profile.roles'}
          renderComponent={(props) => (
            <AppMultiCheckboxesRHF
              {...props}
              className="flex flex-wrap w-full text-gray-600"
              options={STAFF_ROLE_OPTIONS}
            />
          )}
        />
      </div>
      <StaffMemberEditFormAvailabilitiesCard
        className="my-4"
        localState={localState}
      />
      {hasAnyStaffEquipmentEnabled && (
        <>
          <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
            Équipement
          </h2>
          <StaffMemberEquipmentFormGroup
            className={'w-60'}
            classNameIfModel2={'sm:col-span-2'}
            type="tank"
            label={equipmentFormatter.formatEquipmentType('tank')}
            control={control}
            name="staffMember.equipment.tank"
            // onChange={(tankEquipment) => {
            //   const tank = tankEquipment?.selection?.model?.tank;
            // }}
          />
        </>
      )}
      <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Instructeur de plongée
      </h2>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Niveau instructeur"
          control={control}
          name={'staffMember.scubaDivingInstructor.degree.level'}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              className="w-full text-app-xxs"
              disableClearButton={false}
              theme={'material-ui'}
              // value={diveSession.diveTourSession1?.divingDirector?.staffId}
              options={SCUBA_DIVING_INSTRUCTOR_LEVEL_OPTIONS}
            />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Diplôme"
          control={control}
          name={'staffMember.scubaDivingInstructor.degree.name'}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              className="w-full"
              options={SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATE_OPTIONS}
              onChange={(degreeName) => {
                if (degreeName) {
                  let template = SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES.find(
                    (x) =>
                      x.name === degreeName &&
                      (!scubaDivingInstructorDegreeLevel ||
                        x.level === scubaDivingInstructorDegreeLevel),
                  );
                  if (!template) {
                    // incompatible template: find first compatible
                    template = SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES.find(
                      (x) => x.name === degreeName,
                    );
                  }
                  if (template.level !== scubaDivingInstructorDegreeLevel) {
                    setValue(
                      'staffMember.scubaDivingInstructor.degree.level',
                      template?.level,
                    );
                  }
                }
              }}
            />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          // label={'Stagiaire'}
          control={control}
          name={'staffMember.scubaDivingInstructor.degree.isTrainee'}
          renderComponent={(props) => (
            <AppInputBooleanRHF
              {...props}
              type="checkbox"
              description={'Stagiaire'}
            />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Date diplôme"
          control={control}
          required={false}
          name={'staffMember.scubaDivingInstructor.degree.date'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="years"
              className="w-full"
            />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Organisme"
          control={control}
          name={
            'staffMember.scubaDivingInstructor.degree.organizationReference'
          }
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              className="w-full"
              options={DIVE_ORGANIZATION_OPTIONS}
            />
          )}
        />
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Numéro de licence"
          control={control}
          name={
            'staffMember.scubaDivingInstructor.degree.organizationLicenceNumber'
          }
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>
      <div className={'w-full flex flex-col md:flex-row gap-4'}>
        <AppFormControlRHF
          className={'w-full'}
          label="Commentaire diplôme"
          control={control}
          name={'staffMember.scubaDivingInstructor.degree.comment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
        <AppFormControlRHF
          className={'w-full'}
          label="Autres qualifications"
          control={control}
          name={'staffMember.scubaDivingInstructor.otherDegrees'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="N° carte professionelle"
          control={control}
          name={'staffMember.scubaDivingInstructor.businessCardNumber'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Date carte professionelle"
          control={control}
          required={false}
          name={'staffMember.scubaDivingInstructor.businessCardDate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="weeks"
              className="w-full"
            />
          )}
        />
        <AppFormControlRHF
          className="md:col-span-2 w-full"
          label="Certificat médical"
          control={control}
          required={false}
          name={'staffMember.scubaDivingInstructor.medicalCertificateDate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="weeks"
              className="w-full"
            />
          )}
        />
      </div>
    </div>
  );
};
