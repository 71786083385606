/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingProduct, ClubParticipant } from '@mabadive/app-common-model';
import { commonParticipantBuilder } from '@mabadive/app-common-services';
export const bookingProductChangesBuilder = {
  updateBookingProductFromParticipant,
};

function updateBookingProductFromParticipant({
  initialBookingProduct,
  finalParticipant,
}: {
  initialBookingProduct: BookingProduct;
  finalParticipant: ClubParticipant;
}): BookingProduct {
  return {
    ...initialBookingProduct,
    attributes:
      commonParticipantBuilder.buildProductAttributesFromParticipant(
        finalParticipant,
      ),
    bookingProductStatus: finalParticipant.bookingState?.value,
    bookingProductState: finalParticipant.bookingState,
    bookingProductHistory: finalParticipant.bookingHistory,
  };
}
