/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ClubPlanningParticipantsConfigDialog } from '../ClubPlanningParticipantsConfigDialog';
import { ClubPlanningLightPanelViewPeriodMonthState } from './useClubPlanningLightPanelViewPeriodMonthState.hook';

export const ClubPlanningLightPanelViewPeriodMonthLegend = ({
  localState,
  className,
}: {
  localState: ClubPlanningLightPanelViewPeriodMonthState;
  className?: string;
}) => {
  return (
    <AppMessageLight className={className} type="info" hideIcon={true}>
      <div className="flex flex-col gap-2 text-sm">
        <div
          className={
            'flex gap-x-4 gap-y-2 justify-between items-start flex-wrap'
          }
        >
          <h3 className="font-bold text-base text-gray-800 uppercase flex gap-2 items-center">
            <AppHeroIcons.info className="w-6 h-6 text-blue-400" />
            <div>VUE MENSUELLE</div>
            <span className="bg-status-warn px-1 text-white text-xs font-bold hidden md:block">
              NOUVEAU
            </span>
          </h3>
          <ClubPlanningParticipantsConfigDialog
            label="CONFIGURATION"
            planningConfig={localState.planningConfig}
            setPlanningConfig={localState.setPlanningConfig}
          />
        </div>
        <div className={'flex flex-col gap-1'}>
          <div className={''}>
            Visualisez d'un coup d'oeil les palanquées du mois.
          </div>
          <div
            className={
              'pl-1 border-l-2 sm:border-l-4 border-red-400 sm:border-red-200'
            }
          >
            Les plongeurs hors palanquées sont regroupés par prestation, et
            marqué d'un petit trait vertical rouge.
          </div>
        </div>
      </div>
    </AppMessageLight>
  );
};
