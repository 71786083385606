import {
  OnlineBookingOrderArticle,
  OnlineBookingResume,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { EntitiesIcons } from '../../../../../../../_core/modules/layout/icons';
import { useAppTheme } from '../../../../../../data/hooks';
import { BookingTabModel } from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingCardOnlineBookingArticle } from './DiverBookingCardOnlineBookingArticle';

export function DiverBookingCardOnlineBooking({
  onlineBooking,
  globalState,
  dialogs,
  actions,
  bookingTabModel,
  className,
}: {
  onlineBooking: OnlineBookingResume;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  className?: string;
}) {
  const appUiTheme = useAppTheme();
  const orderArticles: OnlineBookingOrderArticle[] =
    onlineBooking.order?.articles ?? [];

  return (
    <div
      className={clsx(
        'app-card app-p-content w-full flex flex-col gap-4',
        className,
      )}
    >
      <h2
        className="text-lg font-bold leading-4 uppercase"
        style={{
          color: appUiTheme.title1.textColor,
        }}
      >
        <EntitiesIcons.onlineBooking className="inline-block w-6 h-6 mr-2" />
        Réservation en ligne
      </h2>
      <div>
        <ul className="divide-y divide-gray-200">
          {orderArticles.map((orderArticle, i) => (
            <DiverBookingCardOnlineBookingArticle
              key={i}
              orderArticle={orderArticle}
              globalState={globalState}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
