import { ProMultiOperationPayload } from '@mabadive/app-common-model';
import React, { FC, useCallback } from 'react';
import { useFormState } from 'react-hook-form';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppPageBlock,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppDialogModalDeprecated } from 'src/business/_core/modules/layout/components/_tailwind';
import { uiStore } from 'src/business/_core/store';
import { useClubResume } from 'src/business/club/data/hooks';
import { appWebLogger } from 'src/lib/browser';
import { BookingParticipantEditorDialogHeader } from '../_common';
import { BookingParticipantViewDialogFixedButtonsBar } from './BookingParticipantViewDialogFixedButtonsBar';
import { BookingParticipantViewDialogFormPanel } from './BookingParticipantViewDialogFormPanel';
import { BookingParticipantViewDialogTabBar } from './tab';
import { BookingParticipantViewDialogLocalState } from './useBookingParticipantViewDialogLocalState.hook';
export const BookingParticipantViewDialogPanel: FC<{
  localState: BookingParticipantViewDialogLocalState;
}> = ({ localState }) => {
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const {
    form,
    aggregatedData,
    changes,
    isPersistInProgress,
    setIsPersistInProgress,
    expandedPanel,
    isConfirmDialogOpen,
    setIsOpen,
    mode,
    inputState,
  } = localState;
  const { participantData } = aggregatedData;

  const { register, handleSubmit, watch, control, setValue } = form;

  const hasChanges = aggregatedData.hasChanges;

  const formState = useFormState({
    control,
  });
  const isValid = formState.isValid;

  const persistChanges = useCallback(() => {
    if (!aggregatedData.hasChanges) {
      setIsOpen(false);
    } else {
      setIsPersistInProgress(true);

      const payload: ProMultiOperationPayload = {
        bookingIdsToClean: [aggregatedData.participantData.bookingId].filter(
          (x) => !!x,
        ),
        ...changes,
      };
      clubMassiveUpdatorClient.update(payload).then(
        () => {
          setIsPersistInProgress(false);
          setIsOpen(false);
        },
        (err) => {
          setIsPersistInProgress(false);
          appWebLogger.captureMessage(
            'Error while trying to persist booking changes',
            {
              logContext: 'BookingParticipantViewDialog',
              clubReference,
              extra: {
                payload,
              },
            },
          );

          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
          });
        },
      );
    }
  }, [
    aggregatedData,
    changes,
    clubReference,
    setIsOpen,
    setIsPersistInProgress,
  ]);

  const confirmPersistChangesBeforeLeave = useCallback(async () => {
    const confirm = await confirmDialog.confirmPromise({
      title: 'Données modifiées',
      message: 'Voulez-vous sauvegarder les changements?',
      type: 'cancelChangesOrSave',
    });

    if (confirm) {
      await persistChanges();
    }

    return true;
  }, [persistChanges]);

  return !inputState.isOpen ? null : (
    <>
      <div className={`${inputState.isOpen ? '' : 'hidden'}`}>
        <AppDialogModalDeprecated
          fullWidth={false}
          className="bg-white"
          autoClose={!expandedPanel && !hasChanges && !isConfirmDialogOpen}
          open={inputState.isOpen}
          onClickClose={async () => {
            if (hasChanges) {
              await confirmPersistChangesBeforeLeave();
              setIsOpen(false);
            } else {
              setIsOpen(false);
            }
          }}
        >
          <AppPageContentContainer className="app-px-content">
            <AppPageBlock className="flex-grow pb-20">
              <div className="py-4 sm:mr-7">
                <BookingParticipantEditorDialogHeader
                  diver={participantData.diver}
                />
              </div>
              <BookingParticipantViewDialogTabBar
                localState={localState}
                confirmPersistChangesBeforeLeave={
                  confirmPersistChangesBeforeLeave
                }
              />
              <BookingParticipantViewDialogFormPanel localState={localState} />
            </AppPageBlock>
            <div className="flex-grow"></div>
            <BookingParticipantViewDialogFixedButtonsBar
              className="sticky bottom-0 right-0 left-0"
              hasChanges={hasChanges || mode === 'edit'}
              isValid={isValid}
              isPersistInProgress={isPersistInProgress}
              onSubmit={() => persistChanges()}
              onClose={() => {
                setIsOpen(false);
              }}
            />
          </AppPageContentContainer>
        </AppDialogModalDeprecated>
      </div>
    </>
  );
};
