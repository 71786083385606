/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { useClubOfferState } from '../../club-offer/ClubOfferViewPage/useClubOfferState.hook';
import { ClubPlanningLightPanelViewPeriodDay } from '../../club-planning-light/ClubPlanningLightPanelViewPeriodDay';
import { ClubPlanningLightPanelViewPeriodMonth } from '../../club-planning-light/ClubPlanningLightPanelViewPeriodMonth/ClubPlanningLightPanelViewPeriodMonth';
import { ClubPlanningLightPanelViewPeriodSession } from '../../club-planning-light/ClubPlanningLightPanelViewPeriodSession';
import { ClubPlanningLightPanelViewPeriodWeek } from '../../club-planning-light/ClubPlanningLightPanelViewPeriodWeek';
import { ClubPlanningDisplayConfigurationBarContext } from '../../club-planning-light/club-planning-navigation';
import {
  ClubPlanningDayFull,
  ClubPlanningPanelSessionDisplayConfig,
} from '../../club-planning-light/model';
import { ClubPlanningAction } from '../../club-planning-light/useClubPlanningActions.hook';
import { useClubPlanningDayFull } from '../../club-planning-light/useClubPlanningDayFull';
import { ClubPlanningParticipantsConfigBar } from './ClubPlanningParticipantsConfigBar';
import { PlanningParticipantsLocalState } from './usePlanningParticipantsLocalState.hook';

export const ClubPlanningParticipantsPanel = ({
  localState,
  clubPlanningActions,
  navigationBarDisplay,
  sessionsDisplayConfigs = [],
  context,
  isAnyDialogOpen,
  className,
}: {
  localState: PlanningParticipantsLocalState;
  clubPlanningActions: ClubPlanningAction;
  navigationBarDisplay: {
    view?: boolean;
    mode?: boolean;
    actions?: boolean;
  };
  context: ClubPlanningDisplayConfigurationBarContext;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  isAnyDialogOpen: boolean;
  className?: string;
}) => {
  const {
    planningConfig,
    setPlanningConfig,
    weekPlanning,
    diveSessionResumes,
    clubEvents,
    dailyConfigs,
    loadableContent,
    dialogsOld,
  } = localState;

  const {
    sessionCardMenuActions,
    clickActions,
    dialogsState, // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  } = clubPlanningActions;

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const days: ClubPlanningDayFull[] = useClubPlanningDayFull({
    diveSessionResumes,
    weekPlanning,
    diveCenterResume,
    showVirtualSessions: true,
    clubEvents,
    dailyConfigs,
    planningConfig,
  });

  const hasRoleClubSettingsGlobal = useAppSecurityUserHasRole(
    'club-edit-settings-global',
  );
  const clubOfferState = useClubOfferState(clubResume?.offer);

  const { isPaymentDeadlineOver, isPaymentDeadlineSoon } = clubOfferState;
  const showMabadiveOfferWarning =
    hasRoleClubSettingsGlobal &&
    (isPaymentDeadlineOver || isPaymentDeadlineSoon);

  return clubResume && days ? (
    <div
      className={`h-full flex flex-col bg-white py-2 z-10 ${className ?? ''}`}
    >
      <ClubPlanningParticipantsConfigBar
        planningConfig={planningConfig}
        display={navigationBarDisplay}
        setPlanningConfig={(planningConfig, options) => {
          setPlanningConfig(planningConfig, options);
        }}
      />
      {planningConfig.viewPeriod === 'month' && (
        <ClubPlanningLightPanelViewPeriodMonth
          // key={'week'} // on ne regénére pas le composant si les paramètres changent
          className={'flex-grow mt-2 mx-1 sm:mx-2'}
          context={context}
          sessionsDisplayConfigs={sessionsDisplayConfigs}
          localState={localState}
          clubPlanningActions={clubPlanningActions}
        />
      )}
      {planningConfig.viewPeriod === 'week' && (
        <ClubPlanningLightPanelViewPeriodWeek
          // key={'week'} // on ne regénére pas le composant si les paramètres changent
          className={'flex-grow mt-2 mx-1 sm:mx-2'}
          days={days}
          context={context}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
          clickActions={clickActions}
          sessionsDisplayConfigs={sessionsDisplayConfigs}
          isAnyDialogOpen={isAnyDialogOpen}
          planningParticipantsLocalState={localState} // permet d'afficher le menu de la session
          dialogsState={dialogsState}
        />
      )}
      {planningConfig.viewPeriod === 'day' && (
        <ClubPlanningLightPanelViewPeriodDay
          className={'flex-grow mt-2 mx-1 sm:mx-2'}
          context={context}
          days={days}
          clickActions={clickActions}
          sessionsDisplayConfigs={sessionsDisplayConfigs}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
          sessionCardMenuActions={sessionCardMenuActions}
          dialogsState={dialogsState} // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
          planningParticipantsLocalState={localState} // permet d'afficher le menu de la session
        />
      )}
      {planningConfig.viewPeriod === 'session' && (
        <ClubPlanningLightPanelViewPeriodSession
          className={'flex-grow mt-2 mx-1 sm:mx-2'}
          days={days}
          clickActions={clickActions}
          sessionsDisplayConfigs={sessionsDisplayConfigs}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
          sessionCardMenuActions={sessionCardMenuActions}
        />
      )}
      {/* {showMabadiveOfferWarning && (
        <div className="hidden lg:block p-2 mb-4">
          <AppMessageLight type="danger">
            <ClubOfferCard offer={clubResume?.offer} />
          </AppMessageLight>
        </div>
      )} */}
    </div>
  ) : null;
};
