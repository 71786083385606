import React from 'react';
import { AppButton, AppButtonsBar } from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { EcommerceProductArticleEditorLocalState } from './useEcommerceProductArticleEditorPanelLocalState.hook';

export const EcommerceProductArticleEditorPanelActionBar = ({
  localState,
  isFixedBottom,
  className,
}: {
  localState: EcommerceProductArticleEditorLocalState;
  isFixedBottom?: boolean;
  className?: string;
}) => {
  const { actions, state } = localState;

  return (
    <AppButtonsBar
      noBackground={!isFixedBottom}
      hasChanges={true}
      className={className}
      classNameJustify={isFixedBottom ? 'justify-between' : 'justify-start'}
    >
      <AppButton
        icon={AppIconsAction.cancel}
        color="gray-outline-light"
        onClick={() => {
          actions.cancel();
        }}
      >
        Annuler
      </AppButton>
      {state.mode === 'edit' && (
        <AppButton
          icon={AppIconsAction.delete}
          color="danger-outline-light"
          onClick={() => actions.promptDeleteConfirm()}
        >
          Supprimer
        </AppButton>
      )}
      <AppButton
        icon={AppIconsAction.save}
        color={!isFixedBottom ? 'primary-bg' : 'primary-outline-light'}
        onClick={() => actions.submit()}
      >
        Confirmer
      </AppButton>
    </AppButtonsBar>
  );
};
