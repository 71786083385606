import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { dataSorter, search } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useOutsideClick } from 'rooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppComboBoxMultipleProps } from './AppComboBoxMultipleProps.type';

/**
 * Statut expérimental : 25/05/2023
 *  => pas mal, mais plusieurs défaut importants sur le comportement par défaut:
 * - quand on prend le focus, la liste ne s'ouvre pas toute seule
 * - quand on referme la combo, puis ré-ouvre, la liste est toujours filtrée
 * - pas très pratique si on veut sélectionner une seule option
 * => donc on gère l'ouverture à la main, et on referme la fenêtre à la sélection (= comportement d'un single select)
 */

export function AppComboBoxMultiple<T extends string | number>({
  name,
  options: optionsInput,
  value: selectedValuesInput,
  onChange,
}: AppComboBoxMultipleProps<T>) {
  const [options, setOptions] = useState(optionsInput);

  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const selectedValues: T[] = useMemo(
    () => selectedValuesInput ?? [],
    [selectedValuesInput],
  );

  const selectedOptions: ValueLabel<T, string, string>[] = useMemo(
    () => optionsInput.filter((x) => selectedValues.includes(x.value)),
    [optionsInput, selectedValues],
  );

  // const [selectedOptions, setSelectedOptions] = useState<T[]>([]);

  const openOptionsPanel = useCallback(() => {
    setOptions(
      dataSorter.sortMultiple(optionsInput, {
        getSortAttributes: (o, i) => [
          {
            // selected options first
            value: !selectedValues.includes(o.value),
          },
          {
            value: i,
          },
        ],
      }),
    );
    setIsOpen(true);
  }, [optionsInput, selectedValues]);

  const closeOptionsPanel = useCallback(() => {
    setIsOpen(false);
    setSearchText('');
  }, []);

  const filteredOptions =
    searchText?.trim() === ''
      ? options
      : search.filter(options, {
          searchText,
          getAttributes: (option) => [option?.label, option?.description],
          maxResults: 50,
        });

  const ref = useRef();

  useOutsideClick(ref, () => {
    closeOptionsPanel();
  });
  return (
    <Combobox
      ref={ref}
      as="div"
      by="value"
      value={selectedOptions}
      onChange={(newOptions: ValueLabel<T, string, string>[]) => {
        if (
          newOptions.length === 0 || // on a désélectionné le dernier élément
          (newOptions.length === 1 &&
            newOptions.length > selectedOptions.length) // on a sélectionné le premier élément
        ) {
          closeOptionsPanel();
          // NOTE: si plus d'un élément sélectionné, on ne referme pas
        }
        onChange(newOptions.map((x) => x.value));
      }}
      name={name}
      multiple
      onKeyDown={(e: KeyboardEvent) => {
        // if escape
        if (e.key === 'Escape') {
          closeOptionsPanel();
        }
        const key = e.key;
      }}
    >
      {/* <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        Assigned to
      </Combobox.Label> */}
      <div className="relative">
        <Combobox.Input
          className={clsx(
            'w-full rounded-md border-0 bg-white py-1.5 pl-2 pr-7',
            'shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-app-primary ',
            'text-sm text-gray-600 ',
          )}
          onChange={(event) => setSearchText(event.target.value)}
          displayValue={(values: ValueLabel<T, string, string>[]) => {
            if (values.length === 1) {
              return values[0].label;
            } else if (values.length === 2) {
              return values.map((x) => x.label).join(', ');
            } else if (values.length > 2) {
              return `${values[0].label} + ${values.length - 1}`;
            }
            return '';
          }}
          onClick={() => {
            openOptionsPanel();
          }}
        />
        <Combobox.Button
          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
          onClick={() => {
            openOptionsPanel();
          }}
        >
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {isOpen && filteredOptions.length > 0 && (
          <Combobox.Options
            static
            className={clsx(
              'absolute z-10 mt-1 max-h-60 w-full overflow-y-auto overflow-x-hidden rounded-md bg-white py-1',
              'shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
              'text-xs sm:text-sm',
            )}
          >
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option.value}
                value={option}
                className={({ active }) =>
                  clsx(
                    'w-full relative cursor-default select-none py-1.5 px-2',
                    active ? 'bg-app-primary text-white' : 'text-gray-600',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="w-full flex gap-1">
                      <div className="flex-grow">
                        <div
                          className={clsx(
                            'truncate',
                            selected && 'font-semibold',
                          )}
                        >
                          {option.label}
                        </div>
                        {/* {option.description &&  */}
                        <div
                          className={clsx(
                            'text-xs overflow-hidden text-gray-500',
                            active ? 'text-blue-200' : 'text-gray-500',
                          )}
                        >
                          {option.description}
                        </div>
                      </div>

                      {selected && (
                        <div
                          className={clsx(
                            'inset-y-0 right-0 flex items-center',
                            active ? 'text-white' : 'text-app-primary',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
