/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferView,
  DIVE_SERVICE_TRAINING_COMMERCIAL_CATEGORIES_SORT_ORDER,
  DIVE_TRAININGS_REFERENCE_MAP,
  DiveServiceOfferSupervision,
  DiveServiceOrganizationReference,
  DiveServiceTrainingCommercialCategoryId,
  DiveTraining,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import { useMemo } from 'react';

export type ClubSettingsProductOfferTagsTrainingCommercialCategory = {
  commercialCategoryId: DiveServiceTrainingCommercialCategoryId;
  quantity: number;
};
export type ClubSettingsProductOfferTagsSupervisionType = {
  supervision: DiveServiceOfferSupervision;
  quantity: number;
};
export type ClubSettingsProductOfferTagsOrganization = {
  orgRef: DiveServiceOrganizationReference;
  quantity: number;
};
export type ClubSettingsProductOfferTagsDivesCount = {
  divesCount: number;
  quantity: number;
};

export type ClubSettingsProductOfferTags = {
  trainingCategories: ClubSettingsProductOfferTagsTrainingCommercialCategory[];
  organizations: ClubSettingsProductOfferTagsOrganization[];
  supervisions: ClubSettingsProductOfferTagsSupervisionType[];
  divesCounts: ClubSettingsProductOfferTagsDivesCount[];
};

export function useClubSettingsProductOffersTags({
  offers,
}: {
  offers: ClubProductPackageOfferView[];
}): ClubSettingsProductOfferTags {
  return useMemo(() => {
    const categories = offers.reduce(
      (acc, o) => {
        {
          const diveTrainingReference =
            o.productPackage.trainingAttributes?.diveTrainingReference;
          const diveTraining: DiveTraining =
            DIVE_TRAININGS_REFERENCE_MAP[diveTrainingReference];

          const commercialCategoryId = diveTraining?.commercialCategoryId;
          if (commercialCategoryId) {
            const x = acc.trainingCategories[commercialCategoryId];
            if (!x) {
              acc.trainingCategories[commercialCategoryId] = {
                commercialCategoryId,
                quantity: 0,
              };
            }
            acc.trainingCategories[commercialCategoryId].quantity++;
          }
        }
        {
          const orgRef =
            o.productPackage.productAttributes?.defaultOrganizationReference;
          if (orgRef) {
            const x = acc.organizations[orgRef];
            if (!x) {
              acc.organizations[orgRef] = {
                orgRef,
                quantity: 0,
              };
            }
            acc.organizations[orgRef].quantity++;
          }
        }
        const diveMode = o.productPackage?.diveAttributes?.diveMode;
        if (
          o.productPackage?.type === 'dive' &&
          (diveMode === 'autoSupervised' ||
            diveMode === 'supervised' ||
            diveMode === 'autonomous' ||
            diveMode === 'instructor')
        ) {
          {
            const divesCount = o.productPackage.diveAttributes?.divesCount;
            if (divesCount > 0) {
              const x = acc.divesCounts[divesCount];
              if (!x) {
                acc.divesCounts[divesCount] = {
                  divesCount,
                  quantity: 0,
                };
              }
              acc.divesCounts[divesCount].quantity++;
            }
          }
          {
            const supervision = o.productPackage.diveAttributes?.supervision;
            if (supervision) {
              const x = acc.supervisions[supervision];
              if (!x) {
                acc.supervisions[supervision] = {
                  supervision,
                  quantity: 0,
                };
              }
              acc.supervisions[supervision].quantity++;
            }
          }
        }

        return acc;
      },
      {
        trainingCategories: {},
        organizations: {},
        divesCounts: {},
        supervisions: {},
      } as {
        trainingCategories: {
          [key in DiveServiceTrainingCommercialCategoryId]: ClubSettingsProductOfferTagsTrainingCommercialCategory;
        };
        organizations: {
          [key in DiveServiceOrganizationReference]: ClubSettingsProductOfferTagsOrganization;
        };
        divesCounts: {
          [key in number]: ClubSettingsProductOfferTagsDivesCount;
        };
        supervisions: {
          [key in DiveServiceOfferSupervision]: ClubSettingsProductOfferTagsSupervisionType;
        };
      },
    );
    const tags: ClubSettingsProductOfferTags = {
      trainingCategories: dataSorter.sortMultiple(
        Object.values(categories.trainingCategories),
        {
          getSortAttributes: (x) => [
            {
              value:
                DIVE_SERVICE_TRAINING_COMMERCIAL_CATEGORIES_SORT_ORDER.indexOf(
                  x.commercialCategoryId,
                ),
              asc: true,
            },
            {
              value: x.quantity,
              asc: false,
            },
            {
              value: x.commercialCategoryId,
            },
          ],
        },
      ),
      organizations: dataSorter.sortMultiple(
        Object.values(categories.organizations),
        {
          getSortAttributes: (x) => [
            {
              value: x.orgRef,
            },
            {
              value: x.quantity,
              asc: false,
            },
          ],
        },
      ),
      supervisions: dataSorter.sortMultiple(
        Object.values(categories.supervisions),
        {
          getSortAttributes: (x) => [
            {
              value: x.supervision,
            },
            {
              value: x.quantity,
              asc: false,
            },
          ],
        },
      ),
      divesCounts: dataSorter.sortMultiple(
        Object.values(categories.divesCounts),
        {
          getSortAttributes: (x) => [
            {
              value: x.divesCount,
              asc: true,
            },
            {
              value: x.quantity,
              asc: false,
            },
          ],
        },
      ),
    };
    return tags;
  }, [offers]);
}
