/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSessionStaffMembersTableContext } from '@mabadive/app-common-model';
import React from 'react';
const COLS_BASE = 3;
const COLS_HEADERS = 4;

export function DiveSessionStaffMembersTableRowFree({
  showTimesColumn,
  label,
  context,
  className = '',
  children,
}: {
  label: React.ReactNode | React.ReactNode[];
  context: DiveSessionStaffMembersTableContext;
  showTimesColumn: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}) {
  const extraColumnsCount =
    context === 'staff-edit-roles'
      ? 2 + 1 + (showTimesColumn ? 1 : 0)
      : context === 'security-sheet'
      ? 1
      : 0;
  return (
    <tbody className={className}>
      <tr className={''}>
        {context === 'staff-edit-roles' && (
          <>
            <th
              scope="col"
              className="pr-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            ></th>
          </>
        )}
        <td
          colSpan={1 + extraColumnsCount}
          className={'px-2 py-1 whitespace-nowrap text-sm'}
        >
          {label}
        </td>
        <td
          className={'px-2 py-1 whitespace-nowrap text-sm hidden sm:table-cell'}
        >
          {children}
        </td>
      </tr>
      <tr className={'sm:hidden'}>
        <td
          colSpan={COLS_HEADERS + COLS_BASE + extraColumnsCount}
          className={'px-2 py-1 whitespace-nowrap text-sm'}
        >
          {children}
        </td>
      </tr>
    </tbody>
  );
}
