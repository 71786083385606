/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingParticipantEditorFormModel } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ClubDiverSimilarSearchCandidatesState } from 'src/business/club/modules/club-diver-participant/ClubDiverSimilarSearch';
import { BookingMemberGeneralEditFormFieldsAll } from './BookingMemberGeneralEditFormFieldsAll';
import { BookingMemberGeneralEditFormFieldsFrequentOnly } from './BookingMemberGeneralEditFormFieldsFrequentOnly';

export type BookingMemberGeneralEditFormRHFFieldsToDisplay =
  | 'frequent-only'
  | 'all';

export const BookingMemberGeneralEditFormRHF = ({
  form,
  fieldsToDisplay = 'all',
  similarSearchCandidatesState,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  fieldsToDisplay?: BookingMemberGeneralEditFormRHFFieldsToDisplay;
  similarSearchCandidatesState?: ClubDiverSimilarSearchCandidatesState;
  className?: string;
}) => {
  return (
    <div className={className}>
      {fieldsToDisplay === 'all' ? (
        <BookingMemberGeneralEditFormFieldsAll form={form} />
      ) : (
        <BookingMemberGeneralEditFormFieldsFrequentOnly
          form={form}
          similarSearchCandidatesState={similarSearchCandidatesState}
        />
      )}
    </div>
  );
};
