import {
  CANCEL_CLUB_TAG,
  CANCEL_DIVER_TAG,
  Club,
  ClubDiver,
  ClubParticipant,
  ClubParticipantPurchasedPackageInfo,
  ClubParticipantSpecialDiveType,
  ClubPurchasePackage,
  ClubPurchasePackagePaymentStatus,
  ClubSettingsUIPlanningPayment,
  ColoredTag,
  DiveSession,
  MultipleDiveSessionNumber,
  PARTICIPANT_BIRTHDAY_TAG,
  PARTICIPANT_COMMENT_TAG,
  ParticipantActionDescription,
  ParticipantBookingStatus,
  WAITING_INFO_CLUB_TAG,
  WAITING_INFO_DIVER_TAG,
} from '@mabadive/app-common-model';
import {
  dateService,
  participantTagPaymentBuilder,
} from '@mabadive/app-common-services';
import {
  AppHeroIcons,
  AppIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';

export type ParticipantTagContext = 'planning' | 'session-security-sheet';

export const participantTagsBuilder = {
  getParticipantTags,
  getParticipantBookingStateTag,
  getParticipantSpecialDiveTypeTag,
  getParticipantPaymentTag,
  getSpecialDiveTypeTag,
};

function getParticipantTags({
  club,
  participant,
  diver,
  diveSession,
  includeParticipantSessionNumber,
  includeComment,
  includeBookingState,
  includeSpecialDiveType,
  includePayment,
  settingsUIPlanning,
}: {
  club: Pick<Club, 'participantTags'>;
  participant: Pick<
    ClubParticipant,
    | 'tags'
    | 'comment'
    | 'bookingState'
    | 'details'
    | 'activePurchasedPackage'
    | 'activePurchasedTraining'
    | 'diveMode'
    | 'divesCount'
  > &
    Partial<
      Pick<
        ClubPurchasePackage,
        'purchasePaymentStatus' | 'purchasePaymentPending'
      >
    >;
  diver: Pick<ClubDiver, 'birthdate' | 'bookingDeposits'>;
  diveSession: Pick<DiveSession, 'time' | 'sessionsCount' | 'details'>;
  includeParticipantSessionNumber?: boolean;
  includeComment: boolean;
  includeBookingState: boolean;
  includeSpecialDiveType: boolean;
  includePayment?: boolean;
  settingsUIPlanning?: ClubSettingsUIPlanningPayment;
}): ColoredTag[] {
  if (!participant || !club || !diveSession) {
    return [];
  }

  const tags = club.participantTags.filter(
    (t) =>
      participant.tags.find((t2Ref) => t.reference === t2Ref) !== undefined,
  );

  if (includeBookingState && participant.bookingState) {
    const bookingStateTag = getParticipantBookingStateTag({
      bookingState: participant.bookingState,
    });
    if (bookingStateTag) {
      tags.push(bookingStateTag);
    }
  }

  if (includeSpecialDiveType && participant?.details?.specialDiveType) {
    const tag = getParticipantSpecialDiveTypeTag({
      participant,
    });
    if (tag) {
      tags.push(tag);
    }
  }

  if (diver && diver.birthdate) {
    if (dateService.isBirthday(diver.birthdate, diveSession.time)) {
      tags.push(
        buildTagIcon(PARTICIPANT_BIRTHDAY_TAG, {
          icon: AppHeroIcons.birthday,
          reverseColors: true,
        }),
      );
    }
  }

  if (includeComment && participant.comment) {
    tags.push(
      buildTagIcon(PARTICIPANT_COMMENT_TAG, {
        icon: AppHeroIcons.comment as (props: any) => JSX.Element,
        reverseColors: true,
        tooltip: participant.comment,
      }),
    );
  }
  if (includePayment && settingsUIPlanning) {
    const paymentTag = getParticipantPaymentTag({
      settingsUIPlanning,
      purchasePaymentPending: participant.purchasePaymentPending,
      purchasePaymentStatus: participant.purchasePaymentStatus,
      activePurchasedPackage: participant?.activePurchasedPackage,
      isPast: dateService.isPastUTC(diveSession.time),
      diver,
    });
    if (paymentTag) {
      if (paymentTag.reference === 'booking-deposit') {
        paymentTag.widthAuto = true;
        const tagIcon = buildTagIcon(paymentTag, {
          icon: EntitiesIcons.deposit as any,
          reverseColors: true,
        });
        tagIcon.buildWidthClass = () => {
          return 'h-4 w-6';
        };
        tags.push(tagIcon);
      } else {
        tags.push(paymentTag);
      }
    }
  }
  if (
    includeParticipantSessionNumber &&
    diveSession.sessionsCount > 1 &&
    participant.divesCount !== diveSession.sessionsCount
  ) {
    const sessionNumberTag = getParticipantSessionNumberTag({
      divesCount: participant.divesCount,
      sessionsCount: diveSession.sessionsCount,
      multiSessionsPresenceNumbers:
        participant.details.multiSessionsPresenceNumbers,
    });
    if (sessionNumberTag) {
      tags.push(sessionNumberTag);
    }
  }

  return tags;
}
function getParticipantPaymentTag({
  settingsUIPlanning,
  purchasePaymentStatus,
  purchasePaymentPending,
  activePurchasedPackage,
  isPast,
  diver,
}: {
  settingsUIPlanning: ClubSettingsUIPlanningPayment;
  purchasePaymentStatus: ClubPurchasePackagePaymentStatus;
  purchasePaymentPending: boolean;
  activePurchasedPackage: ClubParticipantPurchasedPackageInfo;
  isPast: boolean;
  diver?: Pick<ClubDiver, 'birthdate' | 'bookingDeposits'>;
}) {
  const tag = participantTagPaymentBuilder.buildTagParticipantPayment({
    settingsUIPlanning,
    purchasePaymentStatus,
    purchasePaymentPending,
    activePurchasedPackage,
    isPast,
    diver,
  });

  if (tag && purchasePaymentPending) {
    tag.avatar.icon = AppIcons.ui.statusPendingSolid;
    tag.avatar.color = '#ffffff';
    tag.avatar.background = '#b52206';
  }

  return tag;
}

function getParticipantSpecialDiveTypeTag({
  participant,
}: {
  participant: Pick<ClubParticipant, 'details'>;
}): ColoredTag {
  const specialDiveType = participant?.details?.specialDiveType;
  return getSpecialDiveTypeTag(specialDiveType);
}

function getSpecialDiveTypeTag(
  specialDiveType: ClubParticipantSpecialDiveType,
): ColoredTag {
  if (specialDiveType) {
    switch (specialDiveType) {
      case 'rehabilitation': {
        return {
          reference: 'rehabilitation',
          avatar: {
            label: 'R',
            color: '#fff',
            background: '#ee1111',
            appIcon: 'rehabilitation',
          },
          content: {
            label: 'Réadaptation',
            color: '#fff',
            background: '#ee1111',
          },
          // tooltip:'Plongée de réadaptation'
        };
      }
      case 'technical': {
        return {
          reference: 'technical',
          avatar: {
            label: 'T',
            color: '#fff',
            background: '#ee1111',
            appIcon: 'special-dive',
          },
          content: {
            label: 'Technique',
            color: '#fff',
            background: '#ee1111',
          },
        };
      }
    }
  }
}

function buildTagIcon(
  tag: ColoredTag,
  {
    icon,
    reverseColors = false,
    tooltip,
  }: {
    icon: (props: any) => JSX.Element;
    reverseColors?: boolean;
    tooltip?: React.ReactNode;
  },
): ColoredTag {
  return {
    ...tag,
    avatar: {
      ...tag.avatar,
      icon,
      background: reverseColors ? tag.avatar.color : tag.avatar.background,
      color: reverseColors ? tag.avatar.background : tag.avatar.color,
    },
    tooltip,
  };
}

export function getParticipantBookingStateTag({
  bookingState,
}: {
  bookingState: ParticipantActionDescription<ParticipantBookingStatus>;
}): ColoredTag {
  if (!bookingState) {
    return;
  }

  const tooltip = bookingState.comment?.trim()?.length
    ? bookingState.comment
    : undefined;

  if (bookingState.value === 'pending') {
    if (bookingState.user === 'club') {
      return buildTagIcon(WAITING_INFO_CLUB_TAG, {
        icon: AppHeroIcons.info,
        reverseColors: true,
        tooltip,
      });
    } else {
      return buildTagIcon(WAITING_INFO_DIVER_TAG, {
        icon: AppHeroIcons.info,
        reverseColors: true,
        tooltip,
      });
    }
  } else if (bookingState.value === 'cancelled') {
    if (bookingState.user === 'club') {
      return {
        ...CANCEL_CLUB_TAG,
        tooltip,
      };
    } else {
      return {
        ...CANCEL_DIVER_TAG,
        tooltip,
      };
    }
  }

  return;
}

export function getParticipantSessionNumberTag({
  divesCount,
  sessionsCount,
  multiSessionsPresenceNumbers,
}: {
  divesCount: number;
  sessionsCount: number;
  multiSessionsPresenceNumbers: MultipleDiveSessionNumber[];
}): ColoredTag {
  if (!divesCount) {
    return;
  }

  const participantSessionNumber =
    multiSessionsPresenceNumbers?.length === 1
      ? multiSessionsPresenceNumbers[0]
      : undefined;
  if (!participantSessionNumber) {
    return;
  }

  const tooltip = `Seulement la plongée N°${participantSessionNumber} / ${sessionsCount}`;

  return {
    tooltip,
    reference: 'dive-number',
    shape: 'square',
    widthAuto: true,
    avatar: {
      label: `N°${participantSessionNumber}`,
      color: '#333',
      background: '#f3fa1e',
      appIcon: 'special-dive',
    },
    content: {
      label: `N°${participantSessionNumber}`,
      color: '#333',
      background: '#f3fa1e',
    },
  };
}
