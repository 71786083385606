/* eslint-disable @typescript-eslint/no-unused-vars */
import { search, staffMembersSorter } from '@mabadive/app-common-services';
import { Checkbox } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppPageBlock,
  AppPageContainer,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import {
  ClubDialogsProvider,
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import useRouter from 'use-react-router';
import { StaffMembersTable } from '../StaffMembersTable';

export const StaffMembersListPage = (props: {}) => {
  const autoFocus = useAutoFocus();
  const { match } = useRouter();

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const [searchText, setSearchText] = useState('');
  const [showOldStaff, setShowOldStaff] = useState(true);

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const clubSettings = clubResume.clubSettings;
  const staffMembers = useMemo(() => {
    let filteredStaffMembers = search.filter(diveCenterResume?.staffMembers, {
      // attributesNames: ['firstName', 'lastName'],
      getAttributes: (staffMember) => [
        staffMember?.profile?.firstName,
        staffMember?.profile?.lastName,
      ],
      searchText,
      sortResultsByBestMatch: false,
    });
    if (!showOldStaff) {
      const now = new Date();
      filteredStaffMembers = filteredStaffMembers.filter((staff) =>
        staffMembersSorter.isActiveStaff(staff, now),
      );
    }
    return filteredStaffMembers;
  }, [diveCenterResume?.staffMembers, searchText, showOldStaff]);

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({});
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  return (
    <AppPageContainer className={'bg-gray-50'}>
      <ClubDialogsProvider dialogsState={dialogsState}>
        <AppBreadcrumbBar
          color={AppIconsSettings.diveCenter.color}
          items={[
            {
              icon: AppIconsSettings.general,
              label: 'Paramètres',
              url: '/club/settings',
            },
            {
              icon: AppIconsSettings.diveCenter.main,
              label: 'Centre de plongée',
              url: '/club/settings',
            },
            {
              label: 'Staff, moniteurs',
            },
          ]}
        >
          {isMultiDiveCenters && (
            <div className="app-px-content">
              <DiveCenterProBadge diveCenter={diveCenterResume} />
            </div>
          )}
        </AppBreadcrumbBar>

        <AppPageContentContainer className="bg-gray-50 app-px-content">
          <AppPageBlock className="app-card p-4 ">
            <div className="my-1 mx-0.5 flex flex-row gap-2">
              <input
                autoFocus={autoFocus}
                autoComplete="new-password"
                placeholder="Recherche par nom, prénom..."
                type="text"
                className="flex-grow w-max hover:border-app-blue rounded px-2"
                defaultValue={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />

              <AppButton
                color="primary-outline-light"
                size="normal"
                icon={AppHeroIcons.actionAdd}
                onClick={() =>
                  dialogsState.staffMemberEditDialog.openDialog({
                    mode: 'create',
                    defaultValue: { staffMember: {} },
                  })
                }
              >
                Ajouter
              </AppButton>
            </div>
            <div className="flex items-center text-gray-600">
              <Checkbox
                checked={showOldStaff}
                onChange={(e) => setShowOldStaff(e.target.checked)}
              />
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => setShowOldStaff(!showOldStaff)}
              >
                anciens moniteurs
              </span>
            </div>
          </AppPageBlock>

          <div className="app-card p-4 ">
            <StaffMembersTable
              clubSettings={clubSettings}
              staffMembers={staffMembers}
              onClickStaffMember={(staffMember) =>
                dialogsState.staffMemberEditDialog.openDialog({
                  mode: 'edit',
                  defaultValue: { staffMember },
                })
              }
            />
          </div>
        </AppPageContentContainer>
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
