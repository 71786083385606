/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  bookingGroupTypeFormatter,
  bookingStatusFormatter,
  dateService,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { AgencyLabel } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { ClubBookingsListPageLocalState } from '../useClubBookingsListPageLocalState.hook';

export const ClubBookingsListPageConfigBarFiltersResume = ({
  localState,
  className,
}: {
  localState: ClubBookingsListPageLocalState;
  className?: string;
}) => {
  const { filters } = localState;

  const {
    searchText,
    bookingStatuses,
    bookingGroupTypes,
    arrivalDate,
    departureDate,
    bookingAgencyId,
  } = filters;

  return bookingStatuses?.length > 0 ||
    bookingGroupTypes?.length > 0 ||
    arrivalDate ||
    departureDate ||
    bookingAgencyId ||
    searchText?.trim()?.length !== 0 ? (
    <div
      className={clsx(
        'flex justify-start flex-wrap gap-x-4 text-xs text-gray-400 uppercase',
        className,
      )}
    >
      {searchText?.trim()?.length !== 0 && (
        <div className={clsx('flex xs:hidden gap-1')}>
          <div className="font-bold whitespace-nowrap">Recherche:</div>
          <div className="whitespace-nowrap">{searchText?.trim()}</div>
        </div>
      )}
      {bookingStatuses?.length > 0 && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Statut:</div>
          {bookingStatuses
            .map((bookingStatus) =>
              bookingStatusFormatter.formatStatus(bookingStatus).toUpperCase(),
            )
            .join(', ')}
        </div>
      )}
      {bookingGroupTypes?.length > 0 && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Type:</div>
          {bookingGroupTypes
            .map((bookingGroupType) =>
              bookingGroupTypeFormatter
                .format(bookingGroupType, {
                  format: 'long',
                })
                .toUpperCase(),
            )
            .join(', ')}
        </div>
      )}
      {arrivalDate && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Arrivée:</div>
          {dateService.formatUTC(arrivalDate, 'DD/MM/YYYY')}
        </div>
      )}
      {departureDate && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Départ:</div>
          {dateService.formatUTC(departureDate, 'DD/MM/YYYY')}
        </div>
      )}
      {bookingAgencyId && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Agence:</div>
          <AgencyLabel agencyId={bookingAgencyId} />
        </div>
      )}
    </div>
  ) : null;
};
