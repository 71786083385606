/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubSettingsProductOffersDialogInitialState,
  ClubSettingsProductOffersFormModel,
} from './_model';
import {
  ClubSettingsProductOffersFiltersState,
  useClubSettingsProductOffersFiltersState,
} from './useClubSettingsProductOffersFiltersState.hook';

export function useClubSettingsProductOffersDialogLocalState({
  initialState,
}: {
  initialState: ClubSettingsProductOffersDialogInitialState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const [isAdminMode, setIsAdminMode] = useState<boolean>(false);

  const form = useForm<ClubSettingsProductOffersFormModel>({
    defaultValues: initialState.defaultValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const filtersState: ClubSettingsProductOffersFiltersState =
    useClubSettingsProductOffersFiltersState({
      contextMode: initialState.contextMode,
      offers: initialState.defaultValue.offers,
      initialFilters: initialState.initialFilters,
    });

  const {
    data: { offersFiltered },
    state: {
      filters,
      organizationsOptions,
      setFilters,
      trainingCommercialCategoriesOptions,
    },
  } = filtersState;

  const offersFilteredReferences = useMemo(
    () => offersFiltered.map((x) => x.reference),
    [offersFiltered],
  );

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  return {
    state: {
      isCreateMode: initialState.mode === 'create',
      isEditMode: initialState.mode === 'edit',
      isAdminMode,
      setIsAdminMode,
      filtersState,
      form,
      hasFormChanges,
      initialState,
      // filters,
      // setFilters,
      // trainingCommercialCategoriesOptions,
      // organizationsOptions,
    },
    data: {
      offersFiltered,
      offersFilteredReferences,
    },
  };
}

export type ClubSettingsProductOffersDialogLocalState = ReturnType<
  typeof useClubSettingsProductOffersDialogLocalState
>;
