/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveCenterEquipmentModel,
  ExportDailyConfigSheet,
  TEMPLATE_EXPORT_SHEET_DIVES,
  TEMPLATE_EXPORT_SHEET_EQUIPMENT,
  TEMPLATE_EXPORT_SHEET_RESUME,
} from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppMessageLight,
  AppTabTailwind,
  AppTabsTailwind,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppFormControl } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { DailyExportConfigFormModel } from '../_model';
import { DailyExportConfigDialogLocalState } from '../useDailyExportConfigDialogLocalState.hook';
import { DailyExportConfigOptionsPanelFormGeneralSettings } from './DailyExportConfigOptionsPanelFormGeneralSettings';
import { DailyExportConfigOptionsPanelSheet } from './DailyExportConfigOptionsPanelSheet';

const SHEET_TEMPLATES_OPTIONS: (ValueLabel<number> & {
  template: ExportDailyConfigSheet;
})[] = [
  {
    value: 1,
    label: TEMPLATE_EXPORT_SHEET_RESUME.content.title,
    template: TEMPLATE_EXPORT_SHEET_RESUME,
  },
  {
    value: 2,
    label: TEMPLATE_EXPORT_SHEET_EQUIPMENT.content.title,
    template: TEMPLATE_EXPORT_SHEET_EQUIPMENT,
  },
  {
    value: 3,
    label: TEMPLATE_EXPORT_SHEET_DIVES.content.title,
    template: TEMPLATE_EXPORT_SHEET_DIVES,
  },
];

export const DailyExportConfigOptionsPanel = ({
  localState,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  className?: string;
}) => {
  const { form } = localState;

  const { control, getValues } = form;

  const dailyResumeIndex = 0; // pour l'instant, un seul export
  const baseFormName = `dailyResumes.${dailyResumeIndex}` as const;

  const sheetsFieldArray = useFieldArray({
    control,
    name: `${baseFormName}.exportConfig.sheets`,
    keyName: '_ref',
  });

  const redirectTo = useRedirect();

  const [modelAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - modelAnimations?.update?.getTime() < 1000;

  const sheetTabs: AppTabTailwind<string>[] = useMemo(
    () =>
      sheetsFieldArray.fields.map((sheet, sheetIndex) => {
        return {
          id: sheet._ref,
          label: `Onglet N°${sheetIndex + 1}`,
        };
      }),
    [sheetsFieldArray],
  );

  const [currentSheetTabId, setCurrentSheetTabId] = useState<string>(
    sheetTabs?.[0].id,
  );

  const onClickTemplate = useCallback(
    (
      field: FieldArrayWithId<
        DailyExportConfigFormModel,
        'dailyResumes.0.exportConfig.sheets',
        '_ref'
      >,
      sheetIndex: number,
      action: string,
    ) => {
      const from = sheetIndex;
      const update = new Date();
      switch (action) {
        case 'move-down': {
          sheetsFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          sheetsFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'delete': {
          setModelAnimations({ update, remove: from });
          sheetsFieldArray.remove(from);
          // setTimeout(() => sheetsFieldArray.remove(from), 100);
          setCurrentSheetTabId(
            sheetTabs.filter((x, i) => i !== sheetIndex)[0]?.id,
          );
          break;
        }
        case 'clone': {
          if (field) {
            const original = getValues(
              `${baseFormName}.exportConfig.sheets.${sheetIndex}`,
            );
            const clone: DiveCenterEquipmentModel = {
              ...JSON.parse(JSON.stringify(original)),
              uuid: uuidGenerator.random(),
            };
            sheetsFieldArray.insert(from + 1, clone as any);
            setModelAnimations({ update, clone: from });
            break;
          }
        }
      }
    },
    [sheetsFieldArray, sheetTabs, getValues, baseFormName],
  );

  return (
    <div className={clsx('grid gap-8', className)}>
      <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
        Configuration de l'export Excel
      </h3>
      <AppMessageLight>
        <div className="grid gap-4">
          <div className="grid gap-2">
            <p>
              Cet écran permet de configurer précisément chaque onglet de
              l'export
            </p>
            <p>
              N'hésitez pas à me contacter pour toute question ou pour obtenir
              de l'aide dans la personnalisation de votre fiche de sécurité.
            </p>
          </div>
          <div className="text-left">
            <AppButton
              color="primary-outline-light"
              icon={AppHeroIcons.contact}
              onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
            >
              Contactez-nous
            </AppButton>
          </div>
        </div>
      </AppMessageLight>
      <DailyExportConfigOptionsPanelFormGeneralSettings
        localState={localState}
      />
      <AppTabsTailwind
        className="w-full"
        tabs={sheetTabs}
        selected={currentSheetTabId}
        theme="full-bar-todo"
        breakpoint="sm"
        onChange={(tabId) => setCurrentSheetTabId(tabId)}
      />
      {sheetsFieldArray.fields.map((sheet, sheetIndex) => {
        return (
          <DailyExportConfigOptionsPanelSheet
            key={sheet._ref}
            localState={localState}
            dailyResumeIndex={dailyResumeIndex}
            sheetIndex={sheetIndex}
            sheetsFieldArray={sheetsFieldArray}
            onClick={(action) => onClickTemplate(sheet, sheetIndex, action)}
            className={clsx(
              sheet._ref !== currentSheetTabId && 'hidden',
              isRecentAnimationUpdate &&
                modelAnimations?.up === sheetIndex &&
                'animate-move-up',
              isRecentAnimationUpdate &&
                modelAnimations?.down === sheetIndex &&
                'animate-move-down',
              isRecentAnimationUpdate &&
                modelAnimations?.clone === sheetIndex &&
                'animate-create',
              isRecentAnimationUpdate &&
                modelAnimations?.remove === sheetIndex &&
                'animate-remove',
            )}
          />
        );
      })}
      {SHEET_TEMPLATES_OPTIONS.length > 0 && (
        <div className="my-4 w-60">
          <AppFormControl
            label="Ajouter un onglet"
            renderComponent={() => (
              <div className="my-2">
                <AppSingleSelect2HeadlessUI
                  value={0}
                  required={false}
                  options={SHEET_TEMPLATES_OPTIONS}
                  theme={'tailwind'}
                  disableClearButton={true}
                  onChange={(value) => {
                    if (value) {
                      const option = SHEET_TEMPLATES_OPTIONS.find(
                        (x) => x.value === value,
                      );
                      sheetsFieldArray.append(option.template);
                    }
                  }}
                />
              </div>
            )}
          />
          {/* <AppButton
                size="normal"
                icon={AppHeroIcons.actionAdd}
                color="primary-outline-light"
                onClick={() => {
                  sheetsFieldArray.append({
                    ref: '',
                    label: '',
                  } as any);
                }}
              >
                Ajouter
              </AppButton> */}
        </div>
      )}
    </div>
  );
};
