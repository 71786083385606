import {
  CANCEL_DIVER_TAG,
  ClubPublicSettings,
  ClubSettings,
} from '@mabadive/app-common-model';
import {
  dateService,
  diveSessionThemeAttributesFormatter,
  diveSessionThemeFormatter,
  participantSpecialDiveTypeFormatter,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { ColoredTagAvatarCard } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume, useDiveModeColor } from 'src/business/club/data/hooks';
import { participantTagsBuilder } from 'src/business/club/modules/club-planning/_components';
import { AppEmojiIcon } from 'src/lib/form';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { BillingTabParticipantPurchase } from '../../models';
import { AgencyLabel } from '../BookingLabelsComponents';
export function BillingParticipantPurchaseResumePurchasePackageLabel({
  showDiveCenterBadge,
  participantPurchase,
  opacity,
  className,
}: {
  showDiveCenterBadge: boolean;
  participantPurchase: BillingTabParticipantPurchase;
  opacity?: 60;
  className?: string;
}) {
  const redirectTo = useRedirect();

  const { diveMode } = participantPurchase;
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = clubResume.clubSettings;
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const purchasePackageDiveCenter = useMemo(
    () =>
      clubResume.diveCenters.find(
        (dc) => dc._id === participantPurchase.purchasePackage?.diveCenterId,
      ),
    [clubResume.diveCenters, participantPurchase.purchasePackage?.diveCenterId],
  );

  const groupAutonomousAndSupervised =
    clubResume?.clubSettings?.general?.billing?.explorations?.groupsCriteria
      ?.groupAutonomousAndSupervised;

  const clubReference = clubResume?.reference;

  const diveModeColor = useDiveModeColor(diveMode);
  const specialDiveType = participantPurchase.specialDiveType;
  const specialDiveTypeTag =
    participantTagsBuilder.getParticipantSpecialDiveTypeTag({
      participant: {
        details: {
          specialDiveType,
        },
      },
    });

  const diveSessionTheme = participantPurchase.diveSessionTheme;

  const { emojiId: diveSessionThemeEmojiId } = useMemo(
    () =>
      diveSessionThemeAttributesFormatter.getDefaultAttributes(
        diveSessionTheme,
      ),
    [diveSessionTheme],
  );
  const divesCounts = participantPurchase.divesCounts;

  const isOnlyCancelled = useMemo(
    () =>
      divesCounts.assigned +
        divesCounts.toAssign +
        divesCounts.consumedExternalCount +
        divesCounts.future ===
        0 && divesCounts.cancelled > 0,
    [
      divesCounts.assigned,
      divesCounts.cancelled,
      divesCounts.consumedExternalCount,
      divesCounts.future,
      divesCounts.toAssign,
    ],
  );

  const cancelledTag = useMemo(
    () => (isOnlyCancelled ? CANCEL_DIVER_TAG : undefined),
    [isOnlyCancelled],
  );

  return (
    <div className={className}>
      <div className="w-full flex flex-col items-start">
        <div className="w-full flex flex-row items-center">
          <div
            className={`flex items-center gap-x-2 flex-wrap font-bold ${
              opacity === 60 ? 'text-opacity-60' : ''
            }`}
            style={{ color: diveModeColor }}
          >
            {specialDiveType && (
              <ColoredTagAvatarCard tag={specialDiveTypeTag} size={'normal'} />
            )}
            <div className="flex items-center gap-x-0.5">
              {diveSessionTheme && diveSessionThemeEmojiId && (
                <AppEmojiIcon id={diveSessionThemeEmojiId} size={18} />
              )}
              {formatPurchase(
                participantPurchase,
                groupAutonomousAndSupervised,
                publicSettings,
                clubSettings,
              )}
            </div>
            {specialDiveType && (
              <div className="hidden xl:block text-gray-400">
                (
                {participantSpecialDiveTypeFormatter
                  .formatSpecialDiveType(specialDiveType, {
                    format: 'short',
                  })
                  .toLowerCase()}
                )
              </div>
            )}
            {diveSessionTheme && participantPurchase.isVirtual && (
              <div className="hidden xl:block text-gray-500">
                {diveSessionThemeFormatter.formatTheme(diveSessionTheme)}
              </div>
            )}
            {participantPurchase.purchasePackage?.comment?.trim()?.length >
              0 && (
              <Tippy
                delay={[100, 100]}
                placement="left"
                offset={[50, -150]}
                content={participantPurchase.purchasePackage.comment}
              >
                <AppHeroIcons.comment className="inline w-3 h-3 sm:w-4 sm:h-4 text-gray-500" />
              </Tippy>
            )}
            {cancelledTag && (
              <div className="flex items-center gap-x-0.5">
                <ColoredTagAvatarCard tag={cancelledTag} size={'normal'} />

                <div className="uppercase hidden xl:block text-gray-400">
                  annulé
                </div>
              </div>
            )}
            {showDiveCenterBadge && isMultiDiveCenters && (
              <DiveCenterProBadge
                className="my-px"
                diveCenter={purchasePackageDiveCenter}
              />
            )}
          </div>
        </div>
      </div>
      {/* <div
        className={`pt-0.5 whitespace-nowrap font-bold text-gray-600 text-xs ${
          opacity === 60 ? 'text-opacity-60' : ''
        }`}
      >
        {participantPurchase.purchasePackage?.purchaseDate &&
          dateService.formatUTC(
            participantPurchase.purchasePackage?.purchaseDate,
            'DD/MM/YYYY',
          )}
      </div> */}
      <div
        className={`pt-0.5 whitespace-nowrap font-bold text-gray-600 text-xs ${
          opacity === 60 ? 'text-opacity-60' : ''
        }`}
      >
        <div className="flex gap-2">
          {participantPurchase?.purchasePackage?.billedByBookingAgencyId && (
            <AgencyLabel
              agencyId={
                participantPurchase?.purchasePackage?.billedByBookingAgencyId
              }
              className="text-xs font-bold text-gray-600"
            />
          )}

          {participantPurchase.beginDate && (
            <div className="">
              {dateService.formatUTC(
                participantPurchase.beginDate,
                'DD/MM/YYYY',
              )}
              {participantPurchase.beginDate.getTime() !==
                participantPurchase.endDate.getTime() && (
                <>
                  {' '}
                  -{' '}
                  {dateService.formatUTC(
                    participantPurchase.endDate,
                    'DD/MM/YYYY',
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function formatPurchase(
  participantPurchase: BillingTabParticipantPurchase,
  groupAutonomousAndSupervised: boolean,
  publicSettings: ClubPublicSettings,
  clubSettings: ClubSettings,
): string {
  const {
    diveMode,
    purchasePackage,
    diveTrainingReference,
    sessionsBillingResumes,
    divesCounts,
    successiveDivesCount,
    minDistance,
    maxDistance,
    firstDiveReference,
  } = participantPurchase;
  if (purchasePackage?.productPackageOffer?.productPackage) {
    return productPackageFormatter.formatNameString(
      purchasePackage?.productPackageOffer?.productPackage,
      {
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );
  } else {
    const isSingleDive =
      divesCounts.total === 1 ||
      sessionsBillingResumes.sameTypeSameDiver.length === 1;

    return productPackageFormatter.formatNameStringVirtualOffer({
      diveMode,
      isSingleDive,
      diveTrainingReference,
      firstDiveReference,
      successiveDivesCount,
      minDistance,
      maxDistance,
      groupAutonomousAndSupervised,
      publicSettings,
      diveModesConf: clubSettings?.ui?.diveMode,
    });
  }
}
