/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppMessage } from 'src/business/_core/modules/layout/components/_tailwind/AppMessage/AppMessage';

export const ClubDiverSelectorDialogAppMessageInstructions = ({
  multiSelectionModeAllowed,
}: {
  multiSelectionModeAllowed: boolean;
}) => {
  return (
    <AppMessage
      className="mb-5"
      title={'INSTRUCTIONS'}
      message={
        <>
          <ul className="ml-4 list-disc">
            <li>recherchez un plongeur existant par nom ou prénom</li>
            <li>ou bien créez une nouvelle fiche plongeur</li>
            {multiSelectionModeAllowed && (
              <li>
                cochez la case "ajout de plusieurs plongeurs" si vous avez
                plusieurs personnes à ajouter
              </li>
            )}
          </ul>
        </>
      }
    />
  );
};
