import {
  ClubDiver,
  ClubParticipantSpecialDiveType,
  ClubProductPackageAttributesDiveMode,
  ClubResume,
  ClubSettings,
  DiveCenterResume,
  DiveMode,
  DiveServiceOfferEquipment,
  DiveServiceOfferSupervision,
  DiveSessionTheme,
  DiveTrainingReference,
  FirstDiveTrainingReference,
} from '@mabadive/app-common-model';
import {
  clubProductPackageOfferMatcher,
  dateService,
  equipmentDescriptionHelper,
} from '@mabadive/app-common-services';
import {
  BillingTabDiveSessionBillingResume,
  BillingTabDiveSessionBillingResumePurchaseParticipant,
  BillingTabModelBuildContext,
  BillingTabParticipantPurchase,
  BillingTabParticipantPurchaseDivesCounts,
  BillingTabParticipantPurchaseSessionsBillingResumes,
  BookingResumeParticipantForSession,
  DiverBookingPageLoadedContentFocus,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
  SessionsCountByDiver,
} from '../../../../models';
import { bookingPagePackageConsumedCounter } from '../../../02.update-state/services';
import { BillingTabVirtualPackageGroup } from '../BillingTabVirtualPackageGroup.type';
import { billingTabModelCountsBuilder } from '../billingTabModelCountsBuilder';
import { billingTabModelSorter } from '../billingTabModelSorter.service';
import { virtualParticipantPurchaseKeyBuilder } from './services';

export const virtualParticipantPurchasesBuilder = { buildAll };

function buildAll({
  buildContext,
  focus,
  realParticipantPurchases,
  diveCenterResume,
  sessionsCountsByDiver,
}: {
  buildContext: BillingTabModelBuildContext;
  focus: DiverBookingPageLoadedContentFocus;
  realParticipantPurchases: BillingTabParticipantPurchase[];
  diveCenterResume: DiveCenterResume;
  sessionsCountsByDiver: {
    [diverId: string]: SessionsCountByDiver;
  };
}): BillingTabParticipantPurchase[] {
  const clubResume = diveCenterResume.clubResume;
  const clubSettings = clubResume?.clubSettings;
  const explorationsGroupsCriteria =
    clubResume.clubSettings.general?.billing?.explorations?.groupsCriteria;

  let exploDistanceGroups: {
    minDistance?: number;
    maxDistance?: number;
  }[] = buildExploDistanceGroup({ clubResume });

  // find all participants not associated to real purchase
  const remainingParticipants = buildContext.remainingParticipants.filter(
    (p) => {
      const { diveSessionParticipant, bookingSessionParticipant } = p;
      const diveMode = diveSessionParticipant.diveMode;
      return (
        (
          [
            'first-dive',
            'training',
            'supervised',
            'autonomous',
            'observer',
            'free-dive',
            'snorkeling',
            'snorkelingSupervised',
            'watchingTour',
            'autoSupervised',
            'instructor',
          ] as DiveMode[]
        ).includes(diveMode) ||
        // NOTE: jusqu'en 12/2021, les formations théoriques n'avaient pas de trainingReference, donc on les ignore
        (diveMode === 'theoretical-training' &&
          !!diveSessionParticipant.trainingReference)
      );
    },
  );

  // group by type and diver (and additional criteria depending of club configuration: successive dives, multi-sessions)
  const remainingParticipantsMap: {
    [key: string]: BillingTabVirtualPackageGroup;
  } = buildParticipantsMap({
    remainingParticipants,
    exploDistanceGroups,
    diveCenterResume,
    buildContext,
  });

  const virtualParticipantPurchases: BillingTabParticipantPurchase[] =
    buildVirtualParticipantPurchases({
      remainingParticipantsMap,
      focus,
      clubSettings,
      sessionsCountsByDiver,
    });

  const allSessionBillingResumes: BillingTabDiveSessionBillingResume[] =
    virtualParticipantPurchases.reduce(
      (acc, p) => acc.concat(p.sessionsBillingResumes.sameTypeSameDiver),
      [] as BillingTabDiveSessionBillingResume[],
    );

  for (const p of virtualParticipantPurchases) {
    const billingResumes = p.sessionsBillingResumes;
    const existingBookingProductIds = billingResumes.sameTypeSameDiver.map(
      (x) => x.purchaseParticipant.bookingProduct._id,
    );

    allSessionBillingResumes.forEach((x) => {
      if (
        x.purchaseParticipant.participant.bookingMemberFull.diver._id ===
        p.diver._id
      ) {
        // same diver
        if (
          !existingBookingProductIds.includes(
            x.purchaseParticipant.bookingProduct._id,
          )
        ) {
          billingResumes.otherTypeSameDiver.push(x);
        }
      } else {
        // other diver

        // TODO essayer de pré-calculer ça au départ, pas pour chaque presta!
        const { specialDiveType, diveSessionTheme } = getSuffixesIfOfferMatches(
          {
            p: x.purchaseParticipant.participant.bookingParticipantFull,
            successiveDivesCount: 1, // TODO
            diveMode:
              x.purchaseParticipant.participant.bookingParticipantFull
                ?.diveSessionParticipant?.diveMode,
            clubResume,
          },
        );

        const groupBaseKey =
          virtualParticipantPurchaseKeyBuilder.buildVirtualGroupBaseKey({
            p: x.purchaseParticipant.participant.bookingParticipantFull,
            exploDistanceGroups,
            diveCenterResume,
            specialDiveType,
            diveSessionTheme,
          });
        if (p.groupBaseKey === groupBaseKey) {
          billingResumes.sameTypeOtherDiver.push(x);
        } else {
          billingResumes.otherTypeOtherDiver.push(x);
        }
      }
      billingResumes.all = billingResumes.otherTypeOtherDiver
        .concat(billingResumes.sameTypeSameDiver)
        .concat(billingResumes.otherTypeSameDiver)
        .concat(billingResumes.sameTypeOtherDiver);
    });
  }

  return virtualParticipantPurchases;
}

function buildParticipantsMap({
  remainingParticipants,
  exploDistanceGroups,
  diveCenterResume,
  buildContext,
}: {
  remainingParticipants: PRO_BookingParticipantFull[];
  exploDistanceGroups: { minDistance?: number; maxDistance?: number }[];
  diveCenterResume: DiveCenterResume;
  buildContext: BillingTabModelBuildContext;
}): {
  [key: string]: BillingTabVirtualPackageGroup;
} {
  return remainingParticipants.reduce(
    (acc, p) => {
      const key = buildParticipant({
        p,
        exploDistanceGroups,
        diveCenterResume,
        acc,
        buildContext,
      });
      acc[key].participants.push(p);
      return acc;
    },
    {} as {
      [key: string]: BillingTabVirtualPackageGroup;
    },
  );
}

function buildParticipant({
  p,
  exploDistanceGroups,
  diveCenterResume,
  acc,
  buildContext,
}: {
  p: PRO_BookingParticipantFull;
  exploDistanceGroups: { minDistance?: number; maxDistance?: number }[];
  diveCenterResume: DiveCenterResume;
  acc: { [key: string]: BillingTabVirtualPackageGroup };
  buildContext: BillingTabModelBuildContext;
}) {
  const explorationsGroupsCriteria =
    diveCenterResume.clubResume.clubSettings.general?.billing?.explorations
      ?.groupsCriteria;

  const diverId = p.bookingMember.diverId;

  const {
    diveSessionParticipant,
    bookingSessionParticipant,
    bookingProductDive,
    diveSession,
  } = p;

  const diveMode = diveSessionParticipant.diveMode;

  const diveTrainingReference =
    diveSessionParticipant.trainingReference as DiveTrainingReference;

  const hasSupervision = bookingProductDive.attributes.hasSupervision;

  const hasClubEquipment =
    equipmentDescriptionHelper.isProductWithClubEquipment(
      bookingProductDive.attributes,
    );

  const planSupervision: DiveServiceOfferSupervision =
    diveMode !== 'training' && diveMode !== 'theoretical-training'
      ? hasSupervision
        ? 'supervised'
        : 'autonomous'
      : undefined;

  const planEquipment: DiveServiceOfferEquipment =
    diveMode !== 'training'
      ? hasClubEquipment
        ? 'not-equipped'
        : 'equipped'
      : undefined;

  const isArchived = bookingProductDive.purchaseStatus === 'archived';

  let successiveDivesCount: number = undefined;
  let minDistance: number = undefined;
  let maxDistance: number = undefined;

  let minDepth: number = undefined;
  let maxDepth: number = undefined;

  if (diveMode !== 'training' && diveMode !== 'theoretical-training') {
    if (explorationsGroupsCriteria?.multiSessions) {
      successiveDivesCount = !!diveSession.diveTourSession2 ? 2 : 1;
    }
    if (
      explorationsGroupsCriteria?.distance &&
      exploDistanceGroups?.length &&
      diveSession.diveSiteId
    ) {
      const diveSite = diveCenterResume?.diveSites.find(
        (s) => s._id === diveSession.diveSiteId,
      );

      if (diveSite?.distance > 0) {
        const group = exploDistanceGroups.find(
          (g) =>
            (!g.minDistance || g.minDistance <= diveSite?.distance) &&
            (!g.maxDistance || g.maxDistance > diveSite?.distance),
        );
        if (group) {
          minDistance = group.minDistance;
          maxDistance = group.maxDistance;
        }
      }
    }
  }

  // TODO essayer de pré-calculer ça au départ, pas pour chaque presta!
  const { specialDiveType, diveSessionTheme } = getSuffixesIfOfferMatches({
    p,
    successiveDivesCount,
    diveMode,
    clubResume: diveCenterResume.clubResume,
  });

  const groupBaseKey =
    virtualParticipantPurchaseKeyBuilder.buildVirtualGroupBaseKey({
      p,
      exploDistanceGroups,
      diveCenterResume,
      specialDiveType,
      diveSessionTheme,
    });
  const key = `${groupBaseKey}-${p.bookingMember.diverId}`;
  // NOTE: on ne groupe pas par type d'équipement, car ça va être le bazard ensuite,
  // mais on stocke quand même l'équipement du premier dans le groupe,
  // TODO: puis on recalculera ça à la fin en fonction du plus représenté (ou si au moins 1 non-équipé?)
  if (!acc[key]) {
    const packageDiveMode =
      diveMode === 'theoretical-training'
        ? 'training'
        : (diveMode as ClubProductPackageAttributesDiveMode);

    const diver = buildContext.divers.find((d) => d._id === diverId);
    acc[key] = {
      groupBaseKey,
      diver,
      diveTrainingReference,
      planSupervision,
      planEquipment,
      isArchived,
      diveMode: packageDiveMode,
      successiveDivesCount,
      minDistance,
      maxDistance,
      minDepth,
      maxDepth,
      specialDiveType,
      diveSessionTheme,
      participants: [],
    };
  }
  return key;
}

function getSuffixesIfOfferMatches({
  p,
  successiveDivesCount,
  diveMode,
  clubResume,
}: {
  p: PRO_BookingParticipantFull;
  successiveDivesCount: number;
  diveMode: string;
  clubResume: ClubResume;
}): {
  specialDiveType: ClubParticipantSpecialDiveType;
  diveSessionTheme: DiveSessionTheme;
} {
  // TODO essayer de pré-calculer ça au départ, pas pour chaque presta!
  const matchingOffers = clubProductPackageOfferMatcher.getMatchingOffers({
    criteria: {
      diver: p.diver,
      successiveDivesCount,
      diveModeGroup:
        diveMode === 'training' || diveMode === 'theoretical-training'
          ? 'training'
          : diveMode === 'first-dive' ||
            diveMode === 'observer' ||
            diveMode === 'snorkeling' ||
            diveMode === 'snorkelingSupervised' ||
            diveMode === 'watchingTour' ||
            diveMode === 'autoSupervised'
          ? diveMode
          : 'recreational-dive',
    },
    clubResume,
    offers: clubResume.productPackageOffers,
  });

  let specialDiveType: ClubParticipantSpecialDiveType = undefined;
  if (
    p.diveSessionParticipant.details?.specialDiveType &&
    matchingOffers.find(
      (x) =>
        x.productPackage.diveAttributes.specialDiveType ===
        p.diveSessionParticipant.details?.specialDiveType,
    )
  ) {
    specialDiveType = p.diveSessionParticipant.details?.specialDiveType;
  }

  let diveSessionTheme: DiveSessionTheme = undefined;
  if (
    p.diveSession.theme &&
    matchingOffers.find(
      (x) =>
        x.productPackage.salesCriteria?.diveSessionTheme ===
        p.diveSession.theme,
    )
  ) {
    diveSessionTheme = p.diveSession.theme;
  }

  return { specialDiveType, diveSessionTheme };
}

function buildExploDistanceGroup({ clubResume }: { clubResume: ClubResume }): {
  minDistance?: number;
  maxDistance?: number;
}[] {
  const explorationsGroupsCriteria =
    clubResume.clubSettings.general?.billing?.explorations?.groupsCriteria;

  let exploDistanceGroups: {
    minDistance?: number;
    maxDistance?: number;
  }[] = [];
  if (explorationsGroupsCriteria?.distance) {
    exploDistanceGroups = clubResume.productPackageOffers
      .filter((x) => {
        return (
          x.productPackage?.type === 'dive' &&
          x.productPackage?.diveAttributes?.diveMode !== 'training' &&
          (x.productPackage?.diveAttributes?.minDistance > 0 ||
            x.productPackage?.diveAttributes?.maxDistance > 0)
        );
      })
      .reduce(
        (acc, offer) => {
          const existingGroup = acc.find(
            (g) =>
              g.minDistance ===
                offer.productPackage?.diveAttributes?.minDistance &&
              g.maxDistance ===
                offer.productPackage?.diveAttributes?.maxDistance,
          );
          if (!existingGroup) {
            acc.push({
              minDistance: offer.productPackage?.diveAttributes?.minDistance,
              maxDistance: offer.productPackage?.diveAttributes?.maxDistance,
            });
          }
          return acc;
        },
        [] as {
          minDistance?: number;
          maxDistance?: number;
        }[],
      );
  }
  return exploDistanceGroups;
}

function buildVirtualParticipantPurchases({
  remainingParticipantsMap,
  focus,
  clubSettings,
  sessionsCountsByDiver,
}: {
  remainingParticipantsMap: {
    [key: string]: BillingTabVirtualPackageGroup;
  };
  focus: DiverBookingPageLoadedContentFocus;
  clubSettings: ClubSettings;
  sessionsCountsByDiver: {
    [diverId: string]: SessionsCountByDiver;
  };
}): BillingTabParticipantPurchase[] {
  return Object.values(remainingParticipantsMap).map(
    ({
      groupBaseKey,
      diver,
      isArchived,
      diveTrainingReference,
      diveMode,
      participants,
      planSupervision,
      planEquipment,
      successiveDivesCount,
      maxDistance,
      minDistance,
      minDepth,
      maxDepth,
      specialDiveType,
      diveSessionTheme,
    }) => {
      const sameTypeSameDiver: BillingTabDiveSessionBillingResume[] =
        buildProductSessionBillingResumes({ participants, focus, diver });

      const otherTypeSameDiver: BillingTabDiveSessionBillingResume[] = [];
      const sameTypeOtherDiver: BillingTabDiveSessionBillingResume[] = [];
      const otherTypeOtherDiver: BillingTabDiveSessionBillingResume[] = [];

      const sessionsBillingResumes: BillingTabParticipantPurchaseSessionsBillingResumes =
        {
          all: otherTypeOtherDiver
            .concat(sameTypeSameDiver)
            .concat(otherTypeSameDiver)
            .concat(sameTypeOtherDiver)
            .concat(otherTypeOtherDiver),
          sameTypeSameDiver,
          otherTypeSameDiver: [],
          sameTypeOtherDiver: [],
          otherTypeOtherDiver: [],
        };

      const allDates = sameTypeSameDiver.map((x) =>
        x.diveSession.time.getTime(),
      );
      const beginDate = dateService.getUTCDateSetTime(
        new Date(Math.min(...allDates)),
      );
      const endDate = dateService.getUTCDateSetTime(
        new Date(Math.max(...allDates)),
      );
      const divesCounts: BillingTabParticipantPurchaseDivesCounts = {
        // divesCounts mis à jour plus tard dans billingTabModelCountsBuilder à partir des participants
        assigned: 0,
        cancelled: 0,
        toAssign: 0,
        future: 0,
        consumedExternalCount: 0,
        total: 0,
      };

      const isOnlyCancelled = false; // isOnlyCancelled mis à jour plus tard dans billingTabModelCountsBuilder

      const isSuccessivePackage = successiveDivesCount > 1;

      const countSuccessiveAsSingle =
        bookingPagePackageConsumedCounter.testIfCountSuccessiveAsSingle({
          clubSettings,
          isSuccessivePackage,
        });
      const diverSessionsCount: SessionsCountByDiver = sessionsCountsByDiver[
        diver?._id
      ] ?? {
        futureSessionsCounts: 0,
        pastSessionsCounts: 0,
        totalSessionsCounts: 0,
        pastBilledExploSessionsCounts: 0,
      };

      let firstDiveReference: FirstDiveTrainingReference = undefined;
      if (diveMode === 'first-dive') {
        // on prend le premier type de baptême trouvé
        firstDiveReference = participants.find(
          (x) =>
            x?.diveSessionParticipant?.diveMode === 'first-dive' &&
            !!x?.diveSessionParticipant?.firstDiveReference,
        )?.diveSessionParticipant
          ?.firstDiveReference as FirstDiveTrainingReference;
      }

      const participantPurchase: BillingTabParticipantPurchase = {
        groupBaseKey,
        ref: sameTypeSameDiver.length
          ? sameTypeSameDiver[0].purchaseParticipant.bookingProduct._id
          : undefined,
        type:
          diveMode === 'training'
            ? 'training'
            : diveMode === 'autonomous' ||
              diveMode === 'supervised' ||
              diveMode === 'instructor'
            ? 'plan'
            : 'other',
        diveMode,
        isVirtual: true,
        successiveDivesCount,
        minDistance,
        maxDistance,
        minDepth,
        maxDepth,
        beginDate,
        endDate,
        isArchived,
        diver,
        divers: [diver],
        purchasePackage: undefined,
        purchasePackageWithPayments: undefined,
        diveTrainingReference,
        firstDiveReference,
        planSupervision,
        planEquipment,
        sessionsBillingResumes,
        divesCounts,
        isOnlyCancelled,
        specialDiveType,
        diveSessionTheme,
        countSuccessiveAsSingle,
        diverSessionsCount,
      };

      billingTabModelCountsBuilder.updateCountsFromParticipants({
        participants,
        participantPurchase,
        clubSettings,
      });

      return participantPurchase;
    },
  );
}
function buildProductSessionBillingResumes({
  participants,
  focus,
  diver,
}: {
  participants: PRO_BookingParticipantFull[];
  focus: DiverBookingPageLoadedContentFocus;
  diver: ClubDiver;
}) {
  const sessionsBillingResumes: BillingTabDiveSessionBillingResume[] =
    participants.map((p) => {
      const isFocusDiver = focus?.clubDiver?._id === diver._id;
      const isFocusSession =
        focus?.diveSession?.reference === p.diveSession.reference;
      const style =
        isFocusDiver && isFocusSession
          ? 'highlight-strong'
          : isFocusDiver || isFocusSession
          ? 'highlight-light'
          : 'normal';

      const bookingMemberFull: PRO_BookingMemberFull_WithDocs = {
        booking: p.booking,
        bookingMember: p.bookingMember,
        diver,
        docResumes: p.docResumes,
      };
      const participant: BookingResumeParticipantForSession = {
        bookingMemberFull,
        bookingParticipantFull: p,
        bookingParticipantFullSameBooking: p,
        bookingParticipantFullAnyBooking: p,
        style,
      };

      const purchaseParticipant: BillingTabDiveSessionBillingResumePurchaseParticipant =
        {
          isAssociatedToPurchase: false,
          bookingProduct: p.bookingProductDive,
          participant,
        };
      const r: BillingTabDiveSessionBillingResume = {
        diveSession: p.diveSession,
        purchaseParticipant,
      };
      return r;
    });

  return billingTabModelSorter.sortBillingTabDiveSessionBillingResume(
    sessionsBillingResumes,
  );
}
