import {
  AccountProfile,
  AdminBaseData,
  AuthUser,
  Club,
} from '@mabadive/app-common-model';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MQueryDescription, graphqlClient } from 'src/_common-browser';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { accountGraphqlQueryBuilder } from '../AccountBaseData';

export const adminBaseDataGraphqlFetcher = {
  fetch,
};

function fetch({ userId }: { userId: string }): Observable<AdminBaseData> {
  // group all graphql queries into one fetch
  const queries: MQueryDescription<any>[] = [
    accountGraphqlQueryBuilder.buildAuthUser({ userId }),
    accountGraphqlQueryBuilder.buildAccountProfile({ userId }),
    {
      returnName: 'accountProfiles',
      queryName: 'account_profile',
      returnType: 'all',
      returnAttributes: {
        _id: true,
        _version: true,
        _updatedAt: true,
        _createdAt: true,
        emailAddress: true,
        firstName: true,
        lastName: true,
        phoneNumber: true,
        staffRole: true,
        clubStaffMemberId: true,
        userId: true,
      },
    } as MQueryDescription<AccountProfile>,
    {
      returnName: 'clubs',
      queryName: 'club',
      returnType: 'all',
      returnAttributes: `
        _id
        _createdAt
        _updatedAt
        _version
        reference
        name
        city
        country
        participantTags
        type
        offer
        webSiteUrl
        facebookPageUrl
        comment
        log  {
          lastLoginDate
          lastLoginAppVersion
          lastUpdate
          lastUpdateAppVersion
          lastParticipantUpdate
          lastDiveSiteUpdate
          lastStaffUpdate
        }
      `,
    } as MQueryDescription<Club>,
    {
      returnName: 'users',
      queryName: 'auth_user',
      returnType: 'all',
      returnAttributes: `
      _id
      _createdAt
      _updatedAt
      _version
      login
      profile
      clubReference
      log  {
        lastLoginDate
        lastLoginAppVersion
        lastUpdate
        lastUpdateAppVersion
      }
    `,
    } as MQueryDescription<AuthUser>,
  ];

  return graphqlClient.query
    .runMany<AdminBaseData>(queries, {
      type: 'query',
      name: 'adminBaseData',
      headers: {
        'x-hasura-role': 'admin', // select SUPER ADMIN hasura role
      },
    })
    .pipe(
      tap((res) =>
        appLogger.debug(
          '[adminBaseDataGraphqlFetcher] ADMIN FETCH RESULT:',
          res,
        ),
      ),
      catchError((err) => {
        appLogger.warn(
          '[adminBaseDataGraphqlFetcher] error fetching data',
          err,
        );
        return throwError(err);
      }),
    );
}
