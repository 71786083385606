/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { ClubDialogsState } from '../../../../../../../_dialogs';
import { ClubSettingsOnlineBookingFormModel } from '../../../_model';
import { ClubSettingsOnlineBookingEmailsForm_01 } from './ClubSettingsOnlineBookingEmailsForm_01';
import { ClubSettingsOnlineBookingEmailsForm_02 } from './ClubSettingsOnlineBookingEmailsForm_02';

export const ClubSettingsOnlineBookingEmailsForm = ({
  form,
  dialogsState,
}: {
  form: UseFormReturn<ClubSettingsOnlineBookingFormModel>;
  dialogsState: ClubDialogsState;
}) => {
  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsOnlineBookingEmailsForm_01
        form={form}
        dialogsState={dialogsState}
      />
      <ClubSettingsOnlineBookingEmailsForm_02
        form={form}
        dialogsState={dialogsState}
      />
    </div>
  );
};
