import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { PlanningViewConfig } from '../../club-planning/_model';
import { clubPlanningLightPageUrlManager } from '../clubPlanningLightPageUrlManager.service';

export function useUpdateURLFromPlanningConfig(
  planningConfig: PlanningViewConfig,
  { enableRouteParams }: { enableRouteParams: boolean },
) {
  const history = useHistory();
  useEffect(() => {
    if (enableRouteParams) {
      // update url params
      const searchParams: string[] =
        clubPlanningLightPageUrlManager.buildSearchParams(planningConfig);

      history.replace({
        pathname: window.location.pathname,
        search: searchParams?.length ? `?${searchParams.join('&')}` : undefined,
      });
    }
  }, [enableRouteParams, history, planningConfig]);
}
