/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import { useAppRouter, useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppDocumentationLink,
  AppLoadableContentContainer,
  AppPageContainer,
  AppPageContentContainer,
  AppTabTailwind,
  AppTabsTailwind,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { useAppSecurityUserHasRole } from '../../../../../../../business/auth/services';
import { SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING } from '../../../../SETTINGS_CUSTOMERS.const';
import { clubSettingsCustomersUrlBuilder } from '../../../../clubSettingsCustomersUrlBuilder.service';
import {
  ClubSettingsOnlineBookingPageTabId,
  clubSettingsOnlineBookingPageTabLabelFormatter,
} from '../../../ClubSettingsOnlineBookingConfigPage/_services';
import {
  EcommerceCustomerCurrentPageLink,
  ProProductsPage_CategoryCoverCard,
} from '../../components';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../../useClubSettingsOnlineBookingProductsPageLocalState.hook';

export const ProProductsPage_CategoryListPanel = ({
  localState,
}: {
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
}) => {
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const { state, data, dialogs, actions } = localState;

  const categories = data.categories ?? [];

  const [selectedTab, setSelectedTab] =
    useState<ClubSettingsOnlineBookingPageTabId>('products');

  const appRouter = useAppRouter();

  const tabs = useMemo(() => {
    const tabs: AppTabTailwind<ClubSettingsOnlineBookingPageTabId>[] = [
      {
        id: 'products',
        label:
          clubSettingsOnlineBookingPageTabLabelFormatter.format('products'),
      },
      {
        id: 'config',
        label: clubSettingsOnlineBookingPageTabLabelFormatter.format('config'),
        onClick: () => {
          appRouter.navigate('/club/settings/customers/online-booking/config', {
            cause: 'click-tab',
          });
        },
      },
    ];
    return tabs;
  }, [appRouter]);

  return (
    <>
      {/* {!!operationInProgressMessage && (
        <div className={'app-card app-p-content--lg'}>
          <LoadingProgressBar>{operationInProgressMessage}</LoadingProgressBar>
        </div>
      )} */}
      <AppPageContainer className={'bg-white'}>
        <AppBreadcrumbBar
          color={AppIconsSettings.customers.color}
          items={[
            {
              icon: AppIconsSettings.general,
              label: 'Paramètres',
              url: '/club/settings',
            },
            {
              icon: AppIconsSettings.customers.onlineBooking,
              label: 'Vente en ligne',
              url: SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING.url,
            },
            {
              label: 'Catégories',
            },
          ]}
        >
          <AppDocumentationLink
            className="block h-9 w-9 md:h-11 md:w-11"
            url="https://docs.mabadive.com/docs/vente-en-ligne/presentation_vente-en-ligne"
          />
        </AppBreadcrumbBar>
        <AppPageContentContainer
          paddingBottom={false}
          className="bg-gray-50 app-p-content"
        >
          <AppLoadableContentContainer {...state.loadableContent}>
            <AppTabsTailwind
              className="w-full"
              tabs={tabs}
              selected={selectedTab}
              theme="underline"
              breakpoint="sm"
              onChange={(tabId) => setSelectedTab(tabId)}
            />
            <div className="mt-4 app-p-content grid gap-4 sm:gap-8 lg:gap-16">
              {categories.length > 0 && (
                <div className="grid xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                  {categories.map((c) => (
                    <ProProductsPage_CategoryCoverCard
                      key={c._id}
                      category={c}
                      localState={localState}
                    />
                  ))}
                </div>
              )}
              <div className="app-card app-p-content grid gap-4">
                <div className="flex flex-col sm:flex-row gap-8 items-stetch sm:items-start justify-start sm:flex-wrap">
                  <AppButton
                    color="primary-bg"
                    size="normal"
                    icon={AppHeroIcons.actionAdd}
                    onClick={() => {
                      dialogs.categoryEditor.openCreateCategory({
                        defaultValue: {
                          // name: 'Nouvelle catégorie',
                        },
                      });
                    }}
                  >
                    Nouvelle catégorie
                  </AppButton>
                  {isSuperAdmin && (
                    <AppButton
                      color="gray-outline-light"
                      size="normal"
                      icon={AppHeroIcons.actionSettings}
                      onClick={() => {
                        redirectTo(
                          clubSettingsCustomersUrlBuilder.onlineBooking.config(),
                        );
                      }}
                    >
                      Configuration générale
                    </AppButton>
                  )}
                </div>
                <div className="app-card app-p-content grid gap-4">
                  <EcommerceCustomerCurrentPageLink localState={localState} />
                </div>
              </div>
            </div>
          </AppLoadableContentContainer>
        </AppPageContentContainer>
      </AppPageContainer>
    </>
  );
};
