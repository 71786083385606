import { BookingGroup } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { BookingGroupTypeLabel } from './BookingGroupTypeLabel';

export const BookingGroupLabel = ({
  bookingGroup,
  baseClassName = 'px-1 py-px text-xs',
  preferLabelOnly,
  maxLabelWidthClassName,
  className,
}: {
  bookingGroup: BookingGroup;
  baseClassName?: string;
  preferLabelOnly?: boolean;
  maxLabelWidthClassName?: string;
  className?: string;
}) => {
  return (
    <BookingGroupTypeLabel
      className={clsx('', baseClassName, className)}
      type={bookingGroup?.type}
      label={bookingGroup?.name?.toUpperCase()}
      preferLabelOnly={preferLabelOnly}
      maxLabelWidthClassName={maxLabelWidthClassName}
    />
  );
};
