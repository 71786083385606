import { from, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeScan } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { LoadableContentPartial } from '../loadable';

const debug = true;

export const appLoaderChain = { loadChain, mergeChainResults };

function loadChain<T>(
  loaders$: Observable<LoadableContentPartial<T>>[],
  options: {
    mergeContent?: (a: T, b: T) => T;
  } = {},
): Observable<LoadableContentPartial<T>> {
  appLogger.debug('[appLoaderChain]: loadChain TEST 1');
  debug && appLogger.debug('[appLoaderChain]: loadChain TEST 2');
  const lastLoaderIndex = loaders$.length - 1;
  return from(loaders$).pipe(
    mergeScan(
      (previousResult, loader$, i) => {
        debug && appLogger.debug(`[appLoaderChain]: loader ${i}`, previousResult);
        if (previousResult && previousResult.contentState === 'full' && previousResult.lastActionStatus === 'success') {
          // skip next loader
          debug && appLogger.debug(`[appLoaderChain]: skip next loader ${i}`, previousResult);
          return of(previousResult);
        }
        return loader$.pipe(map((newResult) => mergeChainResults(previousResult, newResult, options)));
      },
      undefined as LoadableContentPartial<T>, // initial value
      1, // process one by one (no concurrency)
    ),
    catchError((err) => {
      appLogger.warn('[appLoaderChain] error loading data', err);
      return throwError(err);
    }),
    distinctUntilChanged(), // si on skip un opérateur, le résultat précédent est renvoyé, donc il ne faut pas le ré-émettre
  );
}

function mergeChainResults<T>(
  previousResult: LoadableContentPartial<T>,
  newResult: LoadableContentPartial<T>,
  {
    mergeContent,
  }: {
    mergeContent?: (a: T, b: T) => T;
  } = {},
): LoadableContentPartial<T> {
  if (hasContent(newResult)) {
    if (mergeContent && hasContent(previousResult)) {
      // use custom strategy to merge results
      debug && appLogger.debug('[appLoaderChain]: merge results', previousResult, newResult);
      const result: LoadableContentPartial<T> = {
        ...newResult,
        content: mergeContent(previousResult.content, newResult.content),
      };
      return result;
    }
  } else if (hasContent(previousResult)) {
    debug && appLogger.debug('[appLoaderChain]: content missing: return previous results', previousResult, newResult);
    return {
      ...previousResult,
      lastActionStatus: newResult.lastActionStatus,
    };
  }
  return newResult;
}

function hasContent<T>(result: LoadableContentPartial<T>): boolean {
  return result && (result.contentState === 'full' || result.contentState === 'partial');
}
