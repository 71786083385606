/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { cache } from 'browserslist';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { useSideMenu } from 'src/business/_core/modules/layout/components/SideMenu/useSideMenu.hook';
import { uiStore } from 'src/business/_core/store';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { appWebLogger } from 'src/lib/browser';
import { AppDocEditDialogState, AppDocumentCreatorForm } from './_model';

export function useAppDocEditDialogLocalState({
  inputState,
}: {
  inputState: AppDocEditDialogState;
}) {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const { clubReference } = diveCenterResume;

  const {
    initialState: { mode, docResume },
  } = inputState;

  const [persistedAppDocs, setPersistedAppDocs] = useState<PRO_AppDocResume[]>(
    mode === 'create' ? [] : [docResume],
  );

  const onAppDocsUploaded = useCallback(
    (appDoc: PRO_AppDocResume) => {
      setPersistedAppDocs(persistedAppDocs.concat(appDoc));
      inputState.onUpdate({
        create: {
          appDoc,
        },
      });
    },
    [inputState, persistedAppDocs],
  );

  const form = useForm<AppDocumentCreatorForm>({
    defaultValues: {
      items: mode === 'create' ? [] : [{ appDoc: docResume }],
    },
    reValidateMode: 'onChange',
  });
  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const persistChanges = useCallback(async () => {
    return form.handleSubmit(
      async (formValue: AppDocumentCreatorForm) => {
        setOperationInProgressMessage('Enregistrement en cours...');

        try {
          // const payload: ProMultiOperationPayload = {
          //   appDocs: {
          //     updated: [],
          //   },
          // };

          const patches: AppEntityUpdatePatch[] = formValue.items.reduce(
            (acc, formValueAppDoc) => {
              const uploadedDoc = persistedAppDocs.find(
                (appDoc) => appDoc._id === formValueAppDoc.appDoc._id,
              );
              if (uploadedDoc) {
                // build json patch
                const patchOperations = jsonPatcher.compareObjects(
                  uploadedDoc,
                  {
                    ...uploadedDoc,
                    ...formValueAppDoc.appDoc,
                    details: {
                      ...uploadedDoc.details,
                      ...formValueAppDoc.appDoc.details,
                    },
                  },
                  {
                    attributesToReplaceFully: [],
                  },
                );
                if (patchOperations.length) {
                  const patch: AppEntityUpdatePatch = {
                    pk: uploadedDoc._id,
                    patchOperations,
                  };
                  acc.push(patch);
                }
              }
              return acc;
            },
            [] as AppEntityUpdatePatch[],
          );
          if (patches.length > 0) {
            // appelle useAppDocEditDialogActionPersist qui va appeler les callback passés à useClubDialogsActionsPersist
            inputState.onConfirmAndClose({
              update: {
                patches,
              },
            });
          } else {
            inputState.closeDialog();
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          appWebLogger.captureMessage(
            'Error while trying to persist booking changes',
            {
              logContext: 'UseAppDocEditDialogLocalState',
              clubReference,
              extra: {
                err,
                updateState: cache.updateState,
              },
            },
          );
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
          });
        } finally {
          setOperationInProgressMessage(undefined);
        }
      },
      (err) => {
        // invalid form
      },
    )();
  }, [clubReference, form, inputState, persistedAppDocs]);

  const confirmDelete = useCallback(() => {
    confirmDialog
      .confirm({
        title: 'Supprimer le document',
        message: 'Êtes-vous sûr de vouloir supprimer ce document?',
        type: 'remove',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          inputState.onConfirmAndClose({
            _delete: {
              appDocIds: [docResume?._id],
            },
          });
        }
      });
  }, [docResume?._id, inputState]);

  const cancelChanges = useCallback(() => {
    // cache.reset();
    if (mode === 'create' && persistedAppDocs.length > 0) {
      inputState.onConfirmAndClose({
        _delete: {
          appDocIds: persistedAppDocs.map((docResume) => docResume?._id),
        },
      });
    } else {
      inputState.closeDialog();
    }
  }, [inputState, mode, persistedAppDocs]);

  // useWindowUnloadAlert(cache.updateState.hasChanges);

  const { disableMenu } = useSideMenu();

  return {
    state: {
      mode,
      operationInProgressMessage,
      form,
      initialState: inputState.initialState,
    },

    data: {
      clubResume,
      diveCenterResume,
    },
    actions: {
      cancelChanges,
      persistChanges,
      onAppDocsUploaded,
      confirmDelete,
    },
  };
}

export type AppDocEditDialogLocalState = ReturnType<
  typeof useAppDocEditDialogLocalState
>;
