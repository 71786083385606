/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';

import {
  CLUB_STAFF_MEMBER_JOB_TYPES,
  ClubStaffMemberJobType,
  WEEK_DAYS_ISO,
} from '@mabadive/app-common-model';
import {
  clubStaffMemberJobTypeFormatter,
  dateService,
} from '@mabadive/app-common-services';
import { FieldArrayWithId } from 'react-hook-form';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppFormControlRHF, AppFormLabel } from 'src/lib/form';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { StaffMemberEditFormModel } from '../../_model';
import { StaffMemberEditDialogLocalState } from '../../useStaffMemberEditDialogLocalState.hook';
import { StaffMemberEditFormAvailabilyDay } from './StaffMemberEditFormAvailabilyDay';

export const StaffMemberEditFormAvailabily = ({
  localState,
  field,
  fieldIndex,
  className,
}: {
  localState: StaffMemberEditDialogLocalState;
  field: FieldArrayWithId<
    StaffMemberEditFormModel,
    'staffMember.availabilities',
    'id'
  >;
  fieldIndex: number;
  className?: string;
}) => {
  const {
    state: { form },
  } = localState;
  const { control, setValue } = form;

  const STAFF_MEMBER_JOB_TYPES_OPTIONS = useMemo(
    () =>
      CLUB_STAFF_MEMBER_JOB_TYPES.map((jobType) => {
        const option: ValueLabel<ClubStaffMemberJobType> = {
          label: clubStaffMemberJobTypeFormatter.formatJobType(jobType),
          value: jobType,
        };
        return option;
      }),
    [],
  );

  const basePath = `staffMember.availabilities.${fieldIndex}` as const;

  return (
    <div>
      <div className={'w-full flex flex-col sm:flex-row gap-4'}>
        <AppFormControlRHF
          className="w-full"
          label={'Emploi'}
          required={true}
          control={control}
          name={`${basePath}.jobType`}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              theme="material-ui"
              disableClearButton={true}
              className="w-full text-gray-600"
              options={STAFF_MEMBER_JOB_TYPES_OPTIONS}
            />
          )}
        />
        <AppFormControlRHF
          className="w-full"
          label="Date de début"
          control={control}
          required={true}
          name={`${basePath}.beginDate`}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="weeks"
              className="w-full"
            />
          )}
        />
        <AppFormControlRHF
          className="w-full"
          label="Date de fin"
          control={control}
          required={false}
          name={`${basePath}.endDate`}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              initialView="weeks"
              className="w-full"
            />
          )}
        />
      </div>
      <AppFormLabel
        className="mt-4"
        label={'Jours de travail réguliers (matin et après-midi)'}
      />
      <div className="w-[14rem] grid grid-cols-2 gap-2 md:hidden">
        {WEEK_DAYS_ISO.map((isoWeekDay) => (
          <React.Fragment key={isoWeekDay}>
            <div className="text-left uppercase font-bold text-app-primary text-base">
              {dateService.formatIsoDayIso(isoWeekDay, {
                format: 'dddd',
              })}
            </div>
            <StaffMemberEditFormAvailabilyDay
              key={isoWeekDay}
              isoWeekDay={isoWeekDay}
              field={field}
              fieldIndex={fieldIndex}
              localState={localState}
            />
          </React.Fragment>
        ))}
      </div>
      <div className="hidden md:grid md:grid-cols-7 gap-2">
        {WEEK_DAYS_ISO.map((isoWeekDay) => (
          <div
            key={isoWeekDay}
            className="text-center uppercase font-bold text-app-primary text-base"
          >
            {dateService.formatIsoDayIso(isoWeekDay, {
              format: 'dddd',
            })}
          </div>
        ))}
        {WEEK_DAYS_ISO.map((isoWeekDay) => (
          <StaffMemberEditFormAvailabilyDay
            key={isoWeekDay}
            isoWeekDay={isoWeekDay}
            field={field}
            fieldIndex={fieldIndex}
            localState={localState}
          />
        ))}
      </div>
    </div>
  );
};
