/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE,
  ClubResumeStaffMember,
  ClubSettings,
  ClubStaffMemberDailyAvailability,
  StaffMemberDailyAvailabilityResume,
} from '@mabadive/app-common-model';
import {
  clubStaffMemberJobTypeFormatter,
  dateService,
  nameFormatter,
  staffMemberDefaultAvailabilityFinder,
  staffMembersSorter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import useRouter from 'use-react-router';

export const StaffMembersTableRow = ({
  staffMember,
  clubSettings,
  onClick,
}: {
  staffMember: ClubResumeStaffMember;
  clubSettings: ClubSettings;
  onClick: (staffMemberId: string) => any;
}) => {
  const globalClasses = useGlobalClasses();

  const { match } = useRouter();

  const currentAvailability: StaffMemberDailyAvailabilityResume = useMemo(
    () =>
      staffMemberDefaultAvailabilityFinder.getDefaultDailyAvailability(
        staffMember,
        { date: new Date() },
      ),
    [staffMember],
  );

  const workingDaysCount = useMemo(
    () =>
      (
        Object.values(
          currentAvailability?.defaultWorkingDays ?? {},
        ) as ClubStaffMemberDailyAvailability[]
      )?.filter((x) =>
        CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE.includes(
          x.availabilityPeriod,
        ),
      ).length,
    [currentAvailability?.defaultWorkingDays],
  );

  const isOldStaff = useMemo(
    () => !staffMembersSorter.isActiveStaff(staffMember, new Date()),
    [staffMember],
  );

  return (
    <tr
      className={clsx('cursor-pointer', isOldStaff && 'opacity-50')}
      onClick={() => onClick(staffMember._id)}
    >
      <td
        colSpan={3}
        className={'px-2 py-0.5 whitespace-nowrap uppercase font-bold'}
      >
        <div className="font-bold text-gray-600">
          {nameFormatter.formatFullName(staffMember.profile)}
        </div>
        {currentAvailability?.jobType && (
          <div className="text-xs text-gray-500 font-normal lg:hidden">
            {clubStaffMemberJobTypeFormatter.formatJobType(
              currentAvailability?.jobType,
            )}
          </div>
        )}
      </td>
      <td
        className={'hidden xs:table-cell text-xs px-2 py-0.5 whitespace-nowrap'}
      >
        <div className="flex gap-1">
          {staffMember.scubaDivingInstructor?.degree?.level && (
            <span className="font-bold text-app-blue">
              {staffMember.scubaDivingInstructor?.degree?.level}
            </span>
          )}
          {staffMember.scubaDivingInstructor?.degree?.name && (
            <span className="text-app-blue">
              {staffMember.scubaDivingInstructor?.degree?.name}
            </span>
          )}
        </div>
        {staffMember.scubaDivingInstructor?.degree?.isTrainee && (
          <div className="text-app-blue font-bold text-xs uppercase">
            Stagiaire
          </div>
        )}
        {staffMember.scubaDivingInstructor?.degree?.organizationReference && (
          <div className="text-gray-500 text-xs uppercase">
            {staffMember.scubaDivingInstructor?.degree?.organizationReference}
          </div>
        )}
      </td>
      <td className={'hidden sm:table-cell px-2 py-0.5 whitespace-nowrap'}>
        {staffMember.profile.roles.includes('diving-director') ? (
          <AppIconsMaterial.check />
        ) : null}
      </td>
      <td className={'hidden sm:table-cell px-2 py-0.5 whitespace-nowrap'}>
        {staffMember.profile.roles.includes('scuba-diving-instructor') ? (
          <AppIconsMaterial.check />
        ) : null}
      </td>

      {clubSettings.freeDive.enabled ? (
        <td className={'hidden sm:table-cell px-2 py-0.5 whitespace-nowrap'}>
          {staffMember.profile.roles.includes('free-diving-instructor') ? (
            <AppIconsMaterial.check />
          ) : null}
        </td>
      ) : null}
      <td className={'hidden sm:table-cell px-2 py-0.5 whitespace-nowrap'}>
        {staffMember.profile.roles.includes('surface-security') ? (
          <AppIconsMaterial.check />
        ) : null}
      </td>
      <td className={'hidden lg:table-cell px-2 py-0.5 whitespace-nowrap'}>
        {staffMember.beginDate
          ? dateService.formatUTC(staffMember.beginDate, 'DD/MM/YYYY')
          : false}
      </td>
      <td className={'hidden lg:table-cell px-2 py-0.5 whitespace-nowrap'}>
        {staffMember.endDate
          ? dateService.formatUTC(staffMember.endDate, 'DD/MM/YYYY')
          : false}
      </td>
      <td className={'hidden lg:table-cell text-xs text-gray-500 px-2 py-0.5'}>
        {currentAvailability?.jobType &&
          clubStaffMemberJobTypeFormatter.formatJobType(
            currentAvailability?.jobType,
          )}{' '}
        {currentAvailability?.jobType}
      </td>
      <td className={'hidden sm:table-cell px-2 py-0.5 whitespace-nowrap'}>
        {workingDaysCount}
      </td>
    </tr>
  );
};
