import { DiveCenterEquipmentModel } from '@mabadive/app-common-model';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { DiveCenterEquipmentConfigFormModelEquipementModel } from '../ClubSettingsEquipmentEditPageForm/model';

export const clubSettingsUserEquipmentRefBuilder = {
  append__originalRefs,
  append__originalRef,
  strip__originalRefs,
  strip__originalRef,
};

function append__originalRefs(
  models: DiveCenterEquipmentModel[],
): DiveCenterEquipmentConfigFormModelEquipementModel[] {
  if (Array.isArray(models)) {
    return (models ?? []).map((x) => {
      return append__originalRef(x);
    });
  } else {
    if (!!models) {
      console.warn(
        '[clubSettingsUserEquipmentRefBuilder] models devrait être un tableau:',
        models,
        new Error().stack,
      );
      appLogger.error(
        '[clubSettingsUserEquipmentRefBuilder] models devrait être un tableau',
      );
    }
    return [];
  }
}

// {
//   "club": {
//     "models": [],
//     "enabled": true,
//     "visibleOnSession": true
//   },
// "self": {
//   "models": { => problème ici!
//     "models": [],
//     "enabled": false,
//     "visibleOnSession": true
//   },
//   "enabled": false,
//   "autoSetOnGlobal": true,
//   "visibleOnSession": false
// },
//   "type": "fins",
//   "enabled": true
// },

function append__originalRef(x: DiveCenterEquipmentModel) {
  const model: DiveCenterEquipmentConfigFormModelEquipementModel = {
    ...x,
    __originalRef: x.ref,
  };
  return model;
}

function strip__originalRefs(
  models: DiveCenterEquipmentConfigFormModelEquipementModel[],
): DiveCenterEquipmentModel[] {
  return (models ?? []).map((x) => {
    return strip__originalRef(x);
  });
}
function strip__originalRef(
  x: DiveCenterEquipmentConfigFormModelEquipementModel,
) {
  const { __originalRef, ...attrs } = x;
  const model: DiveCenterEquipmentModel = {
    ...attrs,
  };
  return model;
}
