import {
  ClubPublicData,
  ClubPublicSettings,
  DiveCenterPublicData,
  DiveCenterPublicSettings,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppUiThemeTextCard } from 'src/business/_core/modules/layout/components';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { DiveCenterBrandCardContactLinks } from './DiveCenterBrandCardContactLinks';
import { DiveCenterBrandSocialIcons } from './DiveCenterBrandSocialIcons';

export function DiveCenterBrandCard({
  clubPublicData,
  clubPublicSettings,
  diveCenterPublicData,
  diveCenterPublicSettings,
  className = '',
}: {
  clubPublicData: ClubPublicData;
  clubPublicSettings: ClubPublicSettings;
  diveCenterPublicData?: DiveCenterPublicData;
  diveCenterPublicSettings?: DiveCenterPublicSettings;
  className?: string;
}) {
  const diveCenterUiTheme = diveCenterPublicSettings?.diveCenterUiTheme;

  const logoSquareUrl = clubPublicData?.brand?.logoSquareUrl;

  const brand = useMemo(
    () => ({
      name: diveCenterPublicData?.brand?.name ?? clubPublicData?.brand?.name,
      locationResume:
        diveCenterPublicData?.brand?.locationResume ??
        clubPublicData?.brand?.locationResume,
    }),
    [
      clubPublicData?.brand?.locationResume,
      clubPublicData?.brand?.name,
      diveCenterPublicData?.brand?.locationResume,
      diveCenterPublicData?.brand?.name,
    ],
  );

  return !clubPublicData ? null : (
    <div
      className={clsx(
        'w-full flex flex-col xs:flex-wrap xs:flex-row xs:justify-between gap-4 text-gray-800',
        className,
      )}
    >
      <div className="flex gap-3 xs:gap-4">
        <div className={'text-center h-[80px] w-[80px]'}>
          {logoSquareUrl ? (
            <img
              src={logoSquareUrl}
              className={'inline max-h-[80px] max-w-[80px]'}
              alt="logo"
            />
          ) : (
            <AppIcons.ui.emptyPhoto
              className={'inline max-h-[80px] max-w-[80px]'}
              title="Votre logo ici"
            />
          )}
        </div>
        <div>
          <div className={'text-left'}>
            <AppUiThemeTextCard
              className="text-lg font-bold leading-4 uppercase"
              theme={diveCenterUiTheme?.name}
            >
              {brand.name}
            </AppUiThemeTextCard>
            <AppUiThemeTextCard
              className="text-base leading-normal uppercase"
              theme={diveCenterUiTheme?.location}
            >
              {brand.locationResume}
            </AppUiThemeTextCard>
          </div>
          {/* <div
            className={'text-left border-l-4 pl-1'}
            style={{
              borderColor: diveCenterPublicData?.brand?.color ?? '#555',
            }}
          >
            <div
              className="text-lg font-bold leading-4 uppercase "
              style={{
                color: diveCenterPublicData?.brand?.color ?? '#555',
              }}
            >
              {brand.name}
            </div>
            <div className="text-base leading-normal uppercase text-gray-600">
              {brand.locationResume}
            </div>
          </div> */}
          <DiveCenterBrandSocialIcons
            className="mt-1"
            clubPublicData={clubPublicData}
            clubPublicSettings={clubPublicSettings}
            diveCenterPublicData={diveCenterPublicData}
            diveCenterPublicSettings={diveCenterPublicSettings}
          />
        </div>
      </div>
      <div className="grid gap-2">
        <DiveCenterBrandCardContactLinks
          contact={diveCenterPublicData?.contact}
          clubPublicSettings={clubPublicSettings}
        />
        {(diveCenterPublicData.contacts ?? []).map((contact, contactIndex) => (
          <DiveCenterBrandCardContactLinks
            key={contactIndex}
            contact={contact}
            clubPublicSettings={clubPublicSettings}
          />
        ))}
      </div>
    </div>
  );
}
