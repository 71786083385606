/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubEvent,
  ClubPlanningLightSessionDef,
  DiveCenterDailyConfig,
} from '@mabadive/app-common-model';
import {
  arrayBuilder,
  monthPlanningBuilder,
  weekPlanningBuilder,
} from '@mabadive/app-common-services';
import React, { useCallback, useMemo, useState } from 'react';
import { DiveSessionResumeLoadBounds } from 'src/business/_core/data/store-repository/dive-sessions/model';
import { AppLoadableContentContainer } from 'src/business/_core/modules/layout';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { useClubOfferState } from '../../club-offer/ClubOfferViewPage/useClubOfferState.hook';
import {
  useFetchCalendarWeekDiveSessionResumes,
  useWeekPlanningConfigBounds,
} from '../../club-planning/_components';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningLightPanelViewPeriodDay } from '../ClubPlanningLightPanelViewPeriodDay';
import { ClubPlanningLightPanelViewPeriodSession } from '../ClubPlanningLightPanelViewPeriodSession';
import { ClubPlanningLightPanelViewPeriodWeek } from '../ClubPlanningLightPanelViewPeriodWeek';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import {
  PLANNING_CONFIG_CONTEXT_ID_PLANNING_SELECTOR,
  usePlanningConfig,
} from '../hooks';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
  ClubPlanningPanelSessionDisplayStyle,
} from '../model';
import { useClubPlanningDayFull } from '../useClubPlanningDayFull';
import { ClubPlanningSessionSelectorConfigBar } from './ClubPlanningSessionSelectorConfigBar';

const context: ClubPlanningDisplayConfigurationBarContext =
  'session-selector-dialog';
const clubEvents: ClubEvent[] = [];
const dailyConfigs: DiveCenterDailyConfig[] = [];
const navigationBarDisplay = { view: true, mode: true, actions: false };

export const ClubPlanningSessionSelectorPanel = ({
  alreadySelectedSessionsReferences = [],
  areadySelectedDiverIds = [],
  onSelectSessions,
  defaultFocusDate,
  instructionsNode,
}: {
  alreadySelectedSessionsReferences?: string[];
  areadySelectedDiverIds?: string[];
  onSelectSessions: (sessions: ClubPlanningLightSessionDef[]) => void;
  defaultFocusDate?: Date;
  instructionsNode?: ({
    planningConfig,
  }: {
    planningConfig: PlanningViewConfig;
  }) => React.ReactNode;
}) => {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const hasRoleClubSettingsGlobal = useAppSecurityUserHasRole(
    'club-edit-settings-global',
  );
  const clubOfferState = useClubOfferState(clubResume?.offer);

  const { isPaymentDeadlineOver, isPaymentDeadlineSoon } = clubOfferState;
  const showMabadiveOfferWarning =
    hasRoleClubSettingsGlobal &&
    (isPaymentDeadlineOver || isPaymentDeadlineSoon);

  const [planningConfig, setPlanningConfig] = usePlanningConfig({
    enableRouteParams: false,
    context: PLANNING_CONFIG_CONTEXT_ID_PLANNING_SELECTOR,
    defaultFocusDate,
  });

  const weekPlanning = useMemo(
    () =>
      weekPlanningBuilder.buildWeekPlanning({
        focusDate: planningConfig.focusDate,
      }),
    [planningConfig.focusDate],
  );

  const monthPlanning = useMemo(
    () =>
      monthPlanningBuilder.buildMonthPlanning({
        focusDate: planningConfig.focusDate,
        mode: 'local',
      }),
    [planningConfig.focusDate],
  );

  const criteria: DiveSessionResumeLoadBounds = useWeekPlanningConfigBounds({
    planningConfig,
    weekPlanning,
    monthPlanning,
  });
  const { content: diveSessionResumes, ...loadableContent } =
    useFetchCalendarWeekDiveSessionResumes({
      criteria,
      mode: 'force-reload',
    });

  const alreadySelectedDiversSessionsReferences: string[] = useMemo(() => {
    if (diveSessionResumes) {
      return diveSessionResumes
        .filter(
          (ds) =>
            ds.bookingSessionParticipants.find((p) =>
              areadySelectedDiverIds.includes(p.bookingMember?.diverId),
            ) !== undefined,
        )
        .map((ds) => ds.reference);
    }
    return [];
  }, [areadySelectedDiverIds, diveSessionResumes]);

  const [selectedSessions, setSelectedSessions] = useState<
    ClubPlanningLightSessionDef[]
  >([]);

  const disabledSessionsReferences = useMemo(
    () =>
      arrayBuilder.filterDuplicated(
        alreadySelectedSessionsReferences.concat(
          alreadySelectedDiversSessionsReferences,
        ),
      ),
    [
      alreadySelectedDiversSessionsReferences,
      alreadySelectedSessionsReferences,
    ],
  );
  const sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[] =
    useMemo(() => {
      const sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[] =
        alreadySelectedSessionsReferences
          .map((diveSessionReference) => ({
            diveSessionReference,
            disabled: true,
            style: 'red' as ClubPlanningPanelSessionDisplayStyle,
          }))
          .concat(
            selectedSessions.map((diveSession) => ({
              diveSessionReference: diveSession.reference,
              disabled: false, // on peut les désélectionner
              style: 'green' as ClubPlanningPanelSessionDisplayStyle,
            })),
          )
          .concat(
            alreadySelectedDiversSessionsReferences.map(
              (diveSessionReference) => ({
                diveSessionReference,
                disabled: true,
                style: 'gray' as ClubPlanningPanelSessionDisplayStyle,
              }),
            ),
          );
      return sessionsDisplayConfigs;
    }, [
      alreadySelectedDiversSessionsReferences,
      alreadySelectedSessionsReferences,
      selectedSessions,
    ]);

  const onClickSession = useCallback(
    (session: ClubPlanningLightSessionDef) => {
      if (disabledSessionsReferences.includes(session.reference)) {
        return; // ignore
      }

      const selectedIndex = selectedSessions.findIndex(
        (s) => s.reference === session.reference,
      );
      let newSelectedSessions: ClubPlanningLightSessionDef[] = [
        ...selectedSessions,
      ];
      if (selectedIndex >= 0) {
        // unselect
        newSelectedSessions.splice(selectedIndex, 1);
      } else {
        // select
        newSelectedSessions.push(session);
      }
      setSelectedSessions(newSelectedSessions);
      onSelectSessions(newSelectedSessions);
    },
    [disabledSessionsReferences, onSelectSessions, selectedSessions],
  );

  const clickActions: ClubPlanningLightPanelViewClickActions = useMemo(
    () => ({
      onClickSession,
      onClickParticipant: ({ session }) => onClickSession(session),
    }),
    [onClickSession],
  );

  const days: ClubPlanningDayFull[] = useClubPlanningDayFull({
    diveSessionResumes,
    weekPlanning,
    diveCenterResume,
    showVirtualSessions: true,
    clubEvents, // pas renseignés ici
    dailyConfigs, // pas renseignés ici
    planningConfig,
  });

  return (
    <AppLoadableContentContainer {...loadableContent}>
      <>
        {clubResume && days ? (
          <div className={'h-full flex flex-col bg-white py-2 z-10 pt-2'}>
            <ClubPlanningSessionSelectorConfigBar
              planningConfig={planningConfig}
              display={navigationBarDisplay}
              setPlanningConfig={(planningConfig, options) => {
                setPlanningConfig(planningConfig, options);
              }}
            />
            {/* {planningConfig.viewPeriod === 'month' && (
              <ClubPlanningLightPanelViewPeriodMonth
                // key={'week'} // on ne regénére pas le composant si les paramètres changent
                className={'flex-grow mt-2 mx-1 sm:mx-2'}
                context={context}
                sessionsDisplayConfigs={sessionsDisplayConfigs}
                localState={localState}
                clubPlanningActions={clubPlanningActions}
              />
            )} */}
            {planningConfig.viewPeriod === 'week' && (
              <ClubPlanningLightPanelViewPeriodWeek
                className={'flex-grow mt-2 mx-2'}
                days={days}
                context={context}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
                clickActions={clickActions}
                sessionsDisplayConfigs={sessionsDisplayConfigs}
                isAnyDialogOpen={false}
              />
            )}
            {planningConfig.viewPeriod === 'day' && (
              <ClubPlanningLightPanelViewPeriodDay
                className={'flex-grow mt-2 mx-2'}
                context={context}
                days={days}
                clickActions={clickActions}
                sessionsDisplayConfigs={sessionsDisplayConfigs}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
                sessionCardMenuActions={null}
                dialogsState={null} // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
              />
            )}
            {planningConfig.viewPeriod === 'session' && (
              <ClubPlanningLightPanelViewPeriodSession
                className={'flex-grow mt-2 mx-2'}
                days={days}
                clickActions={clickActions}
                sessionsDisplayConfigs={sessionsDisplayConfigs}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
                sessionCardMenuActions={null}
              />
            )}

            {/* {showMabadiveOfferWarning && (
              <div className="hidden lg:block p-2 mb-4">
                <AppMessageLight type="danger">
                  <ClubOfferCard offer={clubResume?.offer} />
                </AppMessageLight>
              </div>
            )} */}
          </div>
        ) : null}
        {instructionsNode && instructionsNode({ planningConfig })}
      </>
    </AppLoadableContentContainer>
  );
};
