/* eslint-disable @typescript-eslint/no-unused-vars */
import { search } from '@mabadive/app-common-services';
import React, { useMemo, useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppPageContainer,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppInputBoolean } from 'src/lib/form';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import useRouter from 'use-react-router';
import { clubSettingsOtherUrlBuilder } from '../../clubSettingsOtherUrlBuilder.service';
import { CustomerAccomodationsTable } from './CustomerAccomodationsTable';

export const CompanyAccomodationsListPage = (props: {}) => {
  const { match } = useRouter();
  const autoFocus = useAutoFocus();

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const [searchText, setSearchText] = useState('');

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const accommodationsAll = useMemo(() => {
    return search.filter(diveCenterResume?.accommodations, {
      attributesNames: ['name'],
      searchText,
      sortResultsByBestMatch: false,
    });
  }, [diveCenterResume?.accommodations, searchText]);

  const hasAnyArchivedBoat = useMemo(
    () => accommodationsAll.findIndex((x) => !x.active) !== -1,
    [accommodationsAll],
  );
  const [displayArchivedBoats, setDisplayArchivedBoats] = useState(false);

  const accommodations = useMemo(
    () =>
      displayArchivedBoats
        ? accommodationsAll
        : accommodationsAll.filter((x) => x.active),
    [displayArchivedBoats, accommodationsAll],
  );

  return (
    <AppPageContainer className={'bg-gray-50'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.other.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.customers.main,
            label: 'Clients',
            url: '/club/settings',
          },
          {
            label: 'Hébergements',
          },
        ]}
      >
        {isMultiDiveCenters && (
          <div className="app-px-content">
            <DiveCenterProBadge diveCenter={diveCenterResume} />
          </div>
        )}
      </AppBreadcrumbBar>

      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <div className="app-card p-4 ">
          <div className="flex flex-col xs:flex-row items-end gap-2">
            <input
              autoFocus={autoFocus}
              autoComplete="new-password"
              placeholder="Recherche"
              type="text"
              className="flex-grow w-max hover:border-app-blue rounded px-2"
              defaultValue={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <AppButton
              color="primary-outline-light"
              size="normal"
              icon={AppHeroIcons.actionAdd}
              onClick={() => redirectToCompanyAccommodationCreatePage()}
            >
              Ajouter
            </AppButton>
          </div>
          {hasAnyArchivedBoat && (
            <div
              className={`flex gap-2 justify-end font-bold cursor-pointer ${
                displayArchivedBoats ? 'text-gray-600' : 'text-gray-400'
              }`}
              onClick={() => setDisplayArchivedBoats(!displayArchivedBoats)}
            >
              <AppInputBoolean
                className="scale-125 block ml-0.5 mt-1 mb-1"
                type="checkbox"
                title="Afficher les sites archivés"
                value={displayArchivedBoats}
              />
              Afficher les hébergementx archivés
            </div>
          )}
        </div>

        <div className="app-my-content app-card p-4 ">
          <CustomerAccomodationsTable
            accommodations={accommodations}
            onClickCompanyAccommodation={(accommodationId) =>
              redirectToCompanyAccommodationEditPage(accommodationId)
            }
          />
        </div>
      </AppPageContentContainer>
    </AppPageContainer>
  );

  function redirectToCompanyAccommodationCreatePage(): void {
    return redirectTo(clubSettingsOtherUrlBuilder.accommodations.create());
  }

  function redirectToCompanyAccommodationEditPage(
    accommodationId: string,
  ): void {
    return redirectTo(
      clubSettingsOtherUrlBuilder.accommodations.edit({
        accommodationId,
      }),
    );
  }
};
