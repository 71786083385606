import {
  BookingGroupType,
  BookingResumeForList,
  ParticipantBookingStatus,
} from '@mabadive/app-common-model';
import { AppDurationParserString } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import {
  BookingGql1CollectionCriteria,
  bookingGql1GraphqlFetcher,
} from 'src/business/_core/data/store-repository';
import {
  AppFetchDataCacheStore,
  appFetchDataCacheStoreBuilder,
} from 'src/business/_core/modules/root/app-cache/AppFetchDataCacheStore';
import { useAppFetchDataListEntitiesWithCache } from 'src/business/_core/modules/root/app-cache/AppFetchDataCacheStore/useAppFetchDataListEntitiesWithCache.hook';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

export const clubBookingsCacheStore: AppFetchDataCacheStore<
  BookingGql1CollectionCriteria,
  BookingResumeForList[]
> = appFetchDataCacheStoreBuilder.buildCache({
  baseKey: 'club-bookings',
  cacheValidity: '2 days',
});

export function useFetchBookingsWithCache(
  criteria: {
    arrivalDate: Date;
    departureDate: Date;
    archivedExclusive: boolean;
    bookingStatuses: ParticipantBookingStatus[];
    bookingGroupTypes: BookingGroupType[];
  },
  options: {
    autoRefetchInterval: AppDurationParserString;
    staleInterval: AppDurationParserString;
  },
) {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const clubReference = diveCenterResume.clubReference;
  const fetchCriteria: BookingGql1CollectionCriteria = useMemo(() => {
    // ces critères vont générer la clé du cache
    return {
      // important: ne pas utiliser ...criteria car ça entraine une mise à jour de l'objet
      clubReference,
      diveCenterId,
      arrivalDate: criteria?.arrivalDate,
      departureDate: criteria?.departureDate,
      archivedExclusive: criteria?.archivedExclusive,
      bookingStatuses: criteria?.bookingStatuses,
      bookingGroupTypes: criteria?.bookingGroupTypes,
    };
  }, [
    clubReference,
    criteria?.arrivalDate,
    criteria?.bookingGroupTypes,
    criteria?.bookingStatuses,
    criteria?.departureDate,
    criteria?.archivedExclusive,
    diveCenterId,
  ]);

  const cacheState = useAppFetchDataListEntitiesWithCache<BookingResumeForList>(
    fetchCriteria,
    {
      store: clubBookingsCacheStore,
      ...options,
      fetch: async (
        fetchCriteria,
        { cacheState, fetchType },
      ): Promise<BookingResumeForList[]> => {
        // NOTE: maxResults ne doit pas être dans les criteria, sinon il va impacter la clé du cache

        return await bookingGql1GraphqlFetcher
          .findMany(
            {
              ...fetchCriteria,
              maxResults: 1000,
              updatedAfter:
                fetchType === 'partial'
                  ? cacheState?.fetchResult?.dataModifiedAt
                  : undefined,
            },
            { type: 'query' },
          )
          .toPromise();
      },

      getUpdateDate: (a: BookingResumeForList) =>
        new Date(
          Math.max(
            a._updatedAt?.getTime(),
            a.bookingLastUpdateDate?.getTime(),
            a?.bookingContact?._updatedAt?.getTime(),
          ),
        ),
    },
  );

  return cacheState;
}
