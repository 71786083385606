import {
  BookingMemberResumeFullGql_Company,
  DiveSessionResumeGql_Company,
} from '@mabadive/app-common-model';
import { clubDiverAttributesBuilder } from '../default-booking/clubDiverAttributesBuilder.service';
import { clubParticipantAttributesBuilder } from './clubParticipantAttributesBuilder.service';
import { editionBookingParticipantResumeAttributesBuilder } from './editionBookingParticipantResumeAttributesBuilder.service';
import { editionBookingProductResumeAttributesBuilder } from './editionBookingProductResumeAttributesBuilder.service';

export const bookingParticipantEditorAttributesBuilder = {
  buildAttributes,
};
/**
 * Build graphql attributes for BookingGql
 */
function buildAttributes() {
  return `
    ${editionBookingParticipantResumeAttributesBuilder.buildAttributes()}
    booking {
      _id
      diveCenterId
      bookingDate
      bookingLastUpdateDate
      details
      bookingStatus
      active
      bookingContactDiverId
      bookingJourney
      details
      bookingInitialPlan
      bookingCustomerConfig
      bookingCustomerSpacePrivate
      isSharedBooking
      bookingGroup
    }
    bookingMember {
      ${BookingMemberResumeFullGql_Company}
    }
    bookingProducts {
      ${editionBookingProductResumeAttributesBuilder.buildAttributes()}
    }
    diveSession {
      ${DiveSessionResumeGql_Company}
    }
    diver {
      ${clubDiverAttributesBuilder.buildAttributes()}
    }
    diveSessionParticipant {
      ${clubParticipantAttributesBuilder.buildAttributes()}
    }
    bookingSession {
      _id
      diveCenterId
      diveSessionReference
      bookingSessionStatus
      diveSession {
        ${DiveSessionResumeGql_Company}
      }
    }
`;
}
