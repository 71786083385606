import { useMemo, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { appLogger } from 'src/business/_core/modules/root/logger';

export function useInputModelAsObservable<T>(
  value: T,
  debugName?: string /* = `${Math.round(Math.random() * 100)}`*/,
): BehaviorSubject<T> {
  const [changeCount, setChangeCount] = useState<number>(0);
  const [initTime, setInitTime] = useState<Date>(new Date());

  const [obs$] = useState<BehaviorSubject<T>>(new BehaviorSubject<T>(value));

  return useMemo(() => {
    // if (debugName) {
    //   appLogger.debug(`[useInputModelAsObservable] [${debugName}]`, value);
    // }
    if (value !== obs$.value) {
      // pas utile avec le useMemo??? par contre une fonction custom de comparaison pourrait être utile pour comparer les objets
      setChangeCount(changeCount + 1);
      // if (debugName === 'ageRecentDurations') {
      //   appLogger.debug(`[useInputModelAsObservable] [${debugName}] update:`, obs$.value, value)
      // }
      if (changeCount && changeCount % 10 === 0) {
        if (
          changeCount % 100 === 0 ||
          new Date().getTime() - initTime.getTime() < 2000
        ) {
          appLogger.warn(
            `[useInputModelAsObservable] [${debugName}] change count: ${changeCount}`,
          );
        } else {
          appLogger.info(
            `[useInputModelAsObservable] [${debugName}] change count: ${changeCount}`,
          );
        }
        setInitTime(new Date());
      }
      obs$.next(value);
    }

    return obs$;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
}
