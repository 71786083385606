/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export const FieldsToDisplayToggleButton: FC<{
  displayAll: boolean;
  onChange: (displayAll: boolean) => void;
  className?: string;
}> = ({ displayAll, onChange, className = '' }) => {
  return (
    <div className={className}>
      <button
        type="button"
        className="py-1 px-1 text-app-primary hover:text-app-primary-dark uppercase font-bold text-sm"
        onClick={() => {
          onChange(!displayAll);
        }}
      >
        {!displayAll ? (
          <div className="flex gap-2 items-center">
            <AppHeroIcons.actionExtend className="h-4 w-4" /> Afficher plus de
            champs
          </div>
        ) : (
          <div className="flex gap-2 items-center">
            <AppHeroIcons.actionReduce className="h-4 w-4" /> Afficher moins de
            champs
          </div>
        )}
      </button>
    </div>
  );
};
