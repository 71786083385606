/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppToogleButtonGroup } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { ValueLabel } from '../../../_common/form';
import {
  PlanningViewConfig,
  PlanningViewPeriodDayOptionsDisplayMode,
} from '../../../club-planning/_model';
import { SetPlanningConfigOptions } from '../../hooks';
import { ClubPlanningDisplayConfigurationBarContext } from '../ClubPlanningDisplayConfigurationBarContext.type';
import { clubPlanningDisplayModesOptionsBuilder } from './clubPlanningDisplayModesOptionsBuilder.service';

export const ClubPlanningDisplayModeToggleButton = ({
  context,
  planningConfig,
  setPlanningConfig,
  className,
}: {
  context: ClubPlanningDisplayConfigurationBarContext;
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  className?: string;
}) => {
  const mediaSize = useMediaSizeTailwind();

  const options: ValueLabel<PlanningViewPeriodDayOptionsDisplayMode, string>[] =
    useMemo(
      () =>
        clubPlanningDisplayModesOptionsBuilder.buildOptions({
          context,
          mediaSize,
          planningConfig,
        }),
      [context, mediaSize, planningConfig],
    );

  const selected = useMemo(() => {
    if (planningConfig.viewPeriod === 'week') {
      return planningConfig?.viewWeekOptions?.displayMode;
    } else {
      return planningConfig?.viewDayOptions?.displayMode;
    }
  }, [
    planningConfig?.viewDayOptions?.displayMode,
    planningConfig.viewPeriod,
    planningConfig?.viewWeekOptions?.displayMode,
  ]);

  const onChange = useCallback(
    (value: PlanningViewPeriodDayOptionsDisplayMode) => {
      if (planningConfig.viewPeriod === 'week') {
        setPlanningConfig(
          {
            ...planningConfig,
            viewWeekOptions: {
              ...planningConfig.viewWeekOptions,
              displayMode: value as any,
            },
          },
          {
            origin: '[ClubPlanningDisplayModeToggleButton] onChange (week)',
          },
        );
      } else {
        setPlanningConfig(
          {
            ...planningConfig,
            viewDayOptions: {
              ...planningConfig.viewDayOptions,
              displayMode: value,
            },
          },
          {
            origin: '[ClubPlanningDisplayModeToggleButton] onChange (not week)',
          },
        );
      }
    },
    [planningConfig, setPlanningConfig],
  );

  return options.length === 0 ? null : (
    <>
      <AppSingleSelect2HeadlessUI
        className={clsx('w-24 xs:w-32 sm:hidden', className)}
        color={context === 'planning-staff-page' ? 'fuchsia' : 'green'}
        theme="tailwind"
        disableClearButton={true}
        showIcons={false}
        options={options}
        value={selected}
        onChange={onChange}
      />
      <AppToogleButtonGroup
        className={clsx('hidden sm:flex', className)}
        color={context === 'planning-staff-page' ? 'fuchsia' : 'green'}
        theme="strong"
        items={options}
        selected={selected}
        onChanges={onChange}
      />
    </>
  );
};
