import { useProEcommerceDataLoadedContentFetch } from './useProEcommerceDataLoadedContentFetch.hook';

export function useProEcommerceDataLoadedContent() {
  const loadableFetchedResult = useProEcommerceDataLoadedContentFetch();

  const {
    updateCache,
    content: loadedContent,
    refetch,
    ...loadableContent
  } = loadableFetchedResult;

  return {
    updateCache,
    loadableContent,
    loadedContent,
    refetch: loadableFetchedResult.refetch,
  };
}
