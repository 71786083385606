/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingInitialPlanParticipantGroup,
  BookingJourneyStep,
} from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';
import queryString from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { clubParticipantUrlBuilder } from 'src/business/club/modules/url-builders';
import {
  ClubDiverSimilarSearchCandidatesState,
  useClubDiverSimilarSearchCandidates,
} from '../../../ClubDiverSimilarSearch';
import { GenericNavigationContext } from '../../../ClubParticipantNavigationContext/GenericNavigationContext.type';
import { bookingJourneyStepBuilder } from '../../../_services';
import { BookingInitialPlanEditFormModel } from '../../DiverBookingPage/forms/BookingInitialPlanEditForm/model';
import { BookingInitialPlanRegistrationMode } from '../../DiverBookingPage/forms/BookingInitialPlanEditForm/model/BookingInitialPlanRegistrationMode.type';
import { createBookingInitialPlanPagePersister } from './createBookingInitialPlanPagePersister.service';
import { useCreateBookingInitialPlanNavigationSteps } from './useCreateBookingInitialPlanNavigationSteps.hook';

export function useCreateBookingInitialPlanPageLocalState({
  navigationContext,
}: {
  navigationContext: GenericNavigationContext;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubResume = useClubResume();
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  const queryParams = queryString.parse(window.location.search) as any;

  const registrationMode: BookingInitialPlanRegistrationMode =
    queryParams.registrationMode ?? 'multiple';

  const initialFormValue: BookingInitialPlanEditFormModel = useMemo(
    () => buildFormInitialValue({ registrationMode, diveCenterId }),
    [diveCenterId, registrationMode],
  );
  const form = useForm<BookingInitialPlanEditFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const navigation = useCreateBookingInitialPlanNavigationSteps({
    registrationMode,
  });

  const { steps, currentStep, setCurrentStep } = navigation;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () =>
      form &&
      (formState.isDirty || formState.isValid) &&
      formValue?.diver &&
      Object.values(formValue.diver).filter((x) => !!x).length !== 0,
    [form, formState.isDirty, formState.isValid, formValue.diver],
  );
  const createBooking = useCallback(() => {
    handleSubmit(
      async (formValue: BookingInitialPlanEditFormModel, event) => {
        setOperationInProgressMessage('Enregistrement en cours');
        try {
          const { success, booking, diver } =
            await createBookingInitialPlanPagePersister.persistChanges({
              formValue,
              registrationMode,
              diveCenterResume,
            });
          if (success) {
            if (registrationMode === 'single') {
              redirectTo(
                clubParticipantUrlBuilder.buildTabUrl({
                  tabId: 'booking',
                  navigationContext: {
                    origin: navigationContext?.origin ?? 'planning',
                    diveSessionReference:
                      navigationContext.diveSessionReference,
                    diverId: diver._id,
                    action: 'create-booking-participant',
                  },
                }),
              );
            } else {
              redirectTo(
                clubParticipantUrlBuilder.buildTabUrl({
                  navigationContext: {
                    ...navigationContext,
                    diverId: diver._id,
                  },
                  tabId: 'booking',
                  queryParams: {
                    action: 'create-booking-initial-plan',
                  },
                }),
              );
            }
          }
        } finally {
          setOperationInProgressMessage(undefined);
        }
      },
      (err) => {
        appLogger.error('Unexpected error', err);
      },
    )();
  }, [
    diveCenterResume,
    handleSubmit,
    navigationContext,
    redirectTo,
    registrationMode,
  ]);

  const [firstName, lastName] = useWatch({
    control,
    name: ['diver.firstName', 'diver.lastName'],
  });

  // const [fieldsToDisplay, setFieldsToDisplay] =
  //   useState<BookingMemberGeneralEditFormRHFFieldsToDisplay>('frequent-only');

  const similarSearchCandidatesState: ClubDiverSimilarSearchCandidatesState =
    useClubDiverSimilarSearchCandidates(
      {
        firstName,
        lastName,
      },
      {
        onConfirmSelectedDuplicatedCandidate: (candidate) => {
          const diver = candidate.diver;
          redirectTo(
            clubParticipantUrlBuilder.buildTabUrl({
              tabId: 'booking',
              navigationContext: {
                origin: navigationContext?.origin ?? 'planning',
                diveSessionReference: navigationContext.diveSessionReference,
                diverId: diver._id,
                // action: 'create-booking-participant',
              },
            }),
          );
        },
      },
    );

  return {
    form,
    navigation,
    hasFormChanges,
    navigationContext,
    registrationMode,
    createBooking,
    operationInProgressMessage,
    similarSearchCandidatesState,
  };
}

export type CreateBookingInitialPlanPageLocalStateLocalState = ReturnType<
  typeof useCreateBookingInitialPlanPageLocalState
>;

function buildFormInitialValue({
  registrationMode,
  diveCenterId,
}: {
  registrationMode: BookingInitialPlanRegistrationMode;
  diveCenterId: string;
}): BookingInitialPlanEditFormModel {
  const g1: BookingInitialPlanParticipantGroup = {
    _id: uuidGenerator.random(),
    certification: {
      certificationReference: 'DEB',
    },
    expectedDive: {
      diveMode: 'first-dive',
      firstDiveReference: 'BPT',
    },
    participantsCount: 0,
  };
  const g2: BookingInitialPlanParticipantGroup = {
    _id: uuidGenerator.random(),
    certification: {},
    expectedDive: {
      diveMode: 'supervised',
    },
    participantsCount: 0,
  };
  const g3: BookingInitialPlanParticipantGroup = {
    _id: uuidGenerator.random(),
    certification: {},
    expectedDive: {
      diveMode: 'autonomous',
    },
    participantsCount: 0,
  };
  const initialJourneyStep: BookingJourneyStep =
    bookingJourneyStepBuilder.buildDefaultJourneyStep({ diveCenterId });
  const model: BookingInitialPlanEditFormModel = {
    diver: {},
    bookingMember: {} as any,
    bookingInitialPlan: {
      totalCount: 0,
      participantsGroups: registrationMode === 'multiple' ? [g1, g2, g3] : [],
      contactParticipantGroupId: '',
    },
    bookingGroup: {
      type: 'individual',
      name: undefined,
    },
    // à terme il ne faudrait plus forcément de différence
    bookingStatus: registrationMode === 'multiple' ? 'pending' : 'confirmed',
    bookingContactDiverId: undefined,
    bookingJourney: {
      steps: [initialJourneyStep],
    },
  };

  return model;
}
