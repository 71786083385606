import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import {
  DiverBookingPageUpdateState,
  DiverPurchasePaymentEditorDialogResult,
} from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
export function useDiverBookingPageCreatePurchasePayment({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    ({
      newPurchasePayment,
      newPurchasePaymentPackagesDetails,
      newPurchasePaymentBookingDepositsDetails,
      updatedClubPurchasePackages,
      updatedBookingDeposits,
    }: Pick<
      DiverPurchasePaymentEditorDialogResult,
      | 'newPurchasePayment'
      | 'newPurchasePaymentPackagesDetails'
      | 'newPurchasePaymentBookingDepositsDetails'
      | 'updatedClubPurchasePackages'
      | 'updatedBookingDeposits'
    >) => {
      if (newPurchasePayment) {
        const purchasePaymentsChanges = changeDescriptorManager.createOne(
          newPurchasePayment,
          {
            changeDescriptors: updateState.purchasePaymentsChanges,
          },
        );
        const paymentsPackagesDetailsChanges =
          changeDescriptorManager.createMany(
            newPurchasePaymentPackagesDetails,
            {
              changeDescriptors: updateState.paymentsPackagesDetailsChanges,
            },
          );
        const paymentsBookingDepositsDetailsChanges =
          changeDescriptorManager.createMany(
            newPurchasePaymentBookingDepositsDetails,
            {
              changeDescriptors:
                updateState.paymentsBookingDepositsDetailsChanges,
            },
          );
        const purchasePackagesChanges = changeDescriptorManager.updateMany(
          updatedClubPurchasePackages,
          {
            changeDescriptors: updateState.purchasePackagesChanges,
          },
        );
        const bookingDepositsChanges = changeDescriptorManager.updateMany(
          updatedBookingDeposits,
          {
            changeDescriptors: updateState.bookingDepositsChanges,
          },
        );
        setUpdateState(
          {
            ...updateState,
            purchasePaymentsChanges,
            purchasePackagesChanges,
            paymentsPackagesDetailsChanges,
            paymentsBookingDepositsDetailsChanges,
            bookingDepositsChanges,
          },
          {
            action: 'DiverBookingPageCreatePurchasePayment',
          },
        );
      }
    },
    [setUpdateState, updateState],
  );
}
