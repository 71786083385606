/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { uiStore } from 'src/business/_core/store';

import { StaffMemberEditDialogState, StaffMemberEditFormModel } from './_model';
import { StaffMemberEditDialogLocalState } from './useStaffMemberEditDialogLocalState.hook';

export function useStaffMemberEditDialogSubmit({
  handleSubmit,
  localState,
  inputState,
}: Pick<UseFormReturn<StaffMemberEditFormModel>, 'handleSubmit'> & {
  localState: StaffMemberEditDialogLocalState;
  inputState: React.PropsWithChildren<StaffMemberEditDialogState>;
}) {
  return useCallback(async () => {
    try {
      return handleSubmit(
        (formValue: StaffMemberEditFormModel) => {
          const { onConfirm, closeDialog } = inputState;
          if (localState.state.hasFormChanges) {
            return onConfirm({
              formValue,
            });
          } else {
            return closeDialog();
          }
        },
        (err) => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
          throw err;
        },
      )();
    } catch (err) {
      // message already displayed
    }
  }, [handleSubmit, inputState, localState]);
}
