/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BOOKING_GROUP_TYPES,
  BOOKING_STATUSES,
  BookingGroupType,
  BookingStatus,
} from '@mabadive/app-common-model';
import {
  bookingGroupTypeFormatter,
  bookingStatusFormatter,
} from '@mabadive/app-common-services';
import { useState } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export type MonthId = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

const BOOKING_STATUS_OPTIONS = BOOKING_STATUSES.map((value) => {
  const option: ValueLabel<BookingStatus> = {
    label: bookingStatusFormatter.formatStatus(value).toUpperCase(),
    value,
  };
  return option;
});

const BOOKING_GROUP_TYPES_OPTIONS = BOOKING_GROUP_TYPES.map((value) => {
  const option: ValueLabel<BookingGroupType> = {
    label: bookingGroupTypeFormatter
      .format(value, {
        format: 'long',
      })
      .toUpperCase(),
    value,
  };
  return option;
});

export function useClubBookingsListPageFilters() {
  const [searchText, setSearchText] = useState('');
  const [bookingStatuses, setBookingStatuses] = useState<BookingStatus[]>([
    'confirmed',
    'pending',
  ]);
  const [archivedExclusive, setIncludeArchived] = useState<boolean>(false);
  const [bookingGroupTypes, setBookingGroupTypes] = useState<
    BookingGroupType[]
  >([]);
  const [arrivalDate, setArrivalDate] = useState<Date>();
  const [departureDate, setDepartureDate] = useState<Date>();
  const [bookingAgencyId, setBookingAgencyId] = useState<string>();
  // dateService.getUTCDateSetTime(new Date()),
  return {
    searchText,
    setSearchText,
    bookingStatuses,
    setBookingStatuses,
    bookingGroupTypes,
    setBookingGroupTypes,
    archivedExclusive,
    setIncludeArchived,
    bookingStatusOptions: BOOKING_STATUS_OPTIONS,
    bookingGroupTypesOptions: BOOKING_GROUP_TYPES_OPTIONS,
    arrivalDate,
    setArrivalDate,
    departureDate,
    setDepartureDate,
    bookingAgencyId,
    setBookingAgencyId,
  };
}
