import { ProMultiOperationResult } from '@mabadive/app-common-model';
import { QueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useProEcommerceDataUpdateCacheAppBooklet } from './useProEcommerceDataUpdateCacheAppBooklet.hook';
import { useProEcommerceDataUpdateCacheAppBookletPage } from './useProEcommerceDataUpdateCacheAppBookletPage.hook';
import { useProEcommerceDataUpdateCacheCategory } from './useProEcommerceDataUpdateCacheCategory.hook';
import { useProEcommerceDataUpdateCacheProduct } from './useProEcommerceDataUpdateCacheProduct.hook';
import { useProEcommerceDataUpdateCacheProductArticle } from './useProEcommerceDataUpdateCacheProductArticle.hook';
import { useProEcommerceDataUpdateCacheProductCategoryConfig } from './useProEcommerceDataUpdateCacheProductCategoryConfig.hook';

export function useProEcommerceDataUpdateCache({
  queryClient,
  queryKey,
}: {
  queryClient: QueryClient;
  queryKey: string[];
}) {
  const updateCacheAppBooklet = useProEcommerceDataUpdateCacheAppBooklet({
    queryClient,
    queryKey,
  });
  const updateCacheAppBookletPage =
    useProEcommerceDataUpdateCacheAppBookletPage({
      queryClient,
      queryKey,
    });
  const updateCacheCategory = useProEcommerceDataUpdateCacheCategory({
    queryClient,
    queryKey,
  });
  const updateCacheProduct = useProEcommerceDataUpdateCacheProduct({
    queryClient,
    queryKey,
  });
  const updateCacheProductArticle =
    useProEcommerceDataUpdateCacheProductArticle({
      queryClient,
      queryKey,
    });
  const updateCacheProductCategoryConfig =
    useProEcommerceDataUpdateCacheProductCategoryConfig({
      queryClient,
      queryKey,
    });

  const updateCacheFromProMultiOperationResult = useCallback(
    (result: ProMultiOperationResult) => {
      if (!result) return;
      const categories = result.ecommerceCategories;
      if (categories) {
        if (categories.created) {
          updateCacheCategory.create(...categories.created);
        }
        if (categories.updated) {
          updateCacheCategory.update(...categories.updated);
        }
        if (categories.created) {
          updateCacheCategory.delete(...categories.deletedIds);
        }
      }
      const products = result.ecommerceProducts;
      if (products) {
        if (products.created) {
          updateCacheProduct.create(...products.created);
        }
        if (products.updated) {
          updateCacheProduct.update(...products.updated);
        }
        if (products.created) {
          updateCacheProduct.delete(...products.deletedIds);
        }
      }
      const productArticles = result.ecommerceProductArticles;
      if (productArticles) {
        if (productArticles.created) {
          updateCacheProductArticle.create(...productArticles.created);
        }
        if (productArticles.updated) {
          updateCacheProductArticle.update(...productArticles.updated);
        }
        if (productArticles.created) {
          updateCacheProductArticle.delete(...productArticles.deletedIds);
        }
      }
      const productCategoryConfigs = result.ecommerceProductCategoryConfigs;
      if (productCategoryConfigs) {
        if (productCategoryConfigs.created) {
          updateCacheProductCategoryConfig.create(
            ...productCategoryConfigs.created,
          );
        }
        if (productCategoryConfigs.updated) {
          updateCacheProductCategoryConfig.update(
            ...productCategoryConfigs.updated,
          );
        }
        if (productCategoryConfigs.created) {
          updateCacheProductCategoryConfig.delete(
            ...productCategoryConfigs.deletedIds,
          );
        }
      }
      const appBooklet = result.appBooklet;
      if (appBooklet) {
        if (appBooklet.created) {
          updateCacheAppBooklet.create(...appBooklet.created);
        }
        if (appBooklet.updated) {
          updateCacheAppBooklet.update(...appBooklet.updated);
        }
        if (appBooklet.created) {
          updateCacheAppBooklet.delete(...appBooklet.deletedIds);
        }
      }
      const appBookletPage = result.appBookletPage;
      if (appBookletPage) {
        if (appBookletPage.created) {
          updateCacheAppBookletPage.create(...appBookletPage.created);
        }
        if (appBookletPage.updated) {
          updateCacheAppBookletPage.update(...appBookletPage.updated);
        }
        if (appBookletPage.created) {
          updateCacheAppBookletPage.delete(...appBookletPage.deletedIds);
        }
      }
    },
    [
      updateCacheAppBooklet,
      updateCacheAppBookletPage,
      updateCacheCategory,
      updateCacheProduct,
      updateCacheProductArticle,
      updateCacheProductCategoryConfig,
    ],
  );
  return {
    updateCacheFromProMultiOperationResult,
    updateCacheCategory,
    updateCacheProduct,
    updateCacheProductArticle,
    updateCacheProductCategoryConfig,
    updateCacheAppBooklet,
    updateCacheAppBookletPage,
  };
}
