import {
  BookingProduct,
  BookingSessionParticipant,
  ClubParticipant,
  MultipleDiveSessionNumber,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  commonParticipantBuilder,
} from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  DiverBookingUpdateProductParticipant,
  PRO_BookingParticipantFull,
} from '../../../models';

// NOTE: voir aussi commonParticipantBuilder dans '@mabadive/app-common-services'
export const diverBookingParticipantCreator = {
  createParticipant,
  createParticipantFromSessionClone,
  createParticipantFromSourceProduct,
};
/**
 *
 * Context d'utilisation:
 * - création via un formulaire
 */
function createParticipant({
  participant,
  clubReference,
  diveCenterId,
  bookingSessionId,
  diveSessionReference,
  bookingMemberId,
  diverId,
  bookingId,
  sessionsCount,
}: {
  participant: Partial<ClubParticipant>;
  clubReference: string;
  diveCenterId: string;
  bookingSessionId: string;
  diveSessionReference: string;
  bookingMemberId: string;
  diverId: string;
  bookingId: string;
  sessionsCount: MultipleDiveSessionNumber;
}): DiverBookingUpdateProductParticipant {
  const now = new Date();

  if (sessionsCount === undefined) {
    appLogger.error('Error createParticipant: sessionsCount not set');
    sessionsCount = 1;
  }
  if (
    participant.details?.multiSessionsPresenceNumbers &&
    sessionsCount === 1
  ) {
    participant.details.multiSessionsPresenceNumbers = undefined;
  }

  const divesCount = (participant.details?.multiSessionsPresenceNumbers
    ?.length ?? sessionsCount) as MultipleDiveSessionNumber;

  const clubParticipantAttrs: Partial<ClubParticipant> = {
    ...participant,
    diverId,
    diveSessionReference,
    clubReference,
    diveCenterId,
    creationMode: 'app',
    divesCount,
  };

  const clubParticipant =
    commonEntityBuilder.buildEntity<ClubParticipant>(clubParticipantAttrs);

  const bookingSessionParticipant =
    commonParticipantBuilder.buildBookingSessionParticipant({
      clubReference,
      diveCenterId,
      bookingId,
      bookingSessionId,
      diveSessionReference,
      bookingMemberId,
      clubParticipant,
      diverId,
      now,
      participantBookingStatus: clubParticipant.bookingState?.value,
    });

  const bookingProduct = commonParticipantBuilder.buildBookingProduct({
    clubReference,
    diveCenterId,
    bookingId,
    bookingMemberId,
    bookingSessionId,
    bookingSessionParticipant,
    clubParticipant,
    now,
  });
  return {
    clubParticipant,
    bookingSessionParticipant,
    bookingProduct,
  };
}

/**
 *
 * Context d'utilisation:
 * - clone de session
 * - inscription avec les mêmes paramètres que la précédente
 */
function createParticipantFromSessionClone({
  sourceClubParticipant,
  targetDiveSessionReference,
  targetSessionsCount,
  bookingSessionId,
  diveCenterId,
  clubReference,
  bookingMemberId,
  diverId,
  bookingId,
  targetDiveSessionGroupId,
  targetDiveSessionGroupDiveGuide,
}: {
  sourceClubParticipant: Pick<
    ClubParticipant,
    | 'firstDiveReference'
    | 'trainingReference'
    | 'certificationReference'
    | 'certificationDeepDiver'
    | 'autoSupervisedDetails'
    | 'diveMode'
    | 'aptitude'
    | 'targetDeep'
    | 'gaz'
    | 'tags'
    | 'details'
  >;
  clubReference: string;
  diveCenterId: string;
  bookingSessionId: string;
  targetDiveSessionReference: string;
  bookingMemberId: string;
  diverId: string;
  bookingId: string;
  targetDiveSessionGroupId: string;
  targetDiveSessionGroupDiveGuide: boolean;
  targetSessionsCount: MultipleDiveSessionNumber;
}): DiverBookingUpdateProductParticipant {
  const now = new Date();

  const clubParticipant =
    commonParticipantBuilder.buildParticipantAttributesFromCurrentParticipantCloneOrMove(
      {
        mode: 'clone',
        sourceClubParticipant,
        targetDiveSessionReference,
        targetSessionsCount,
        targetDiveSessionGroupId,
        targetDiveSessionGroupDiveGuide,
        diveCenterId,
        clubReference,
        diverId,
      },
    );

  const bookingSessionParticipant =
    commonParticipantBuilder.buildBookingSessionParticipant({
      clubReference,
      diveCenterId,
      bookingId,
      bookingSessionId,
      diveSessionReference: targetDiveSessionReference,
      bookingMemberId,
      clubParticipant,
      diverId,
      now,
      participantBookingStatus: clubParticipant.bookingState?.value,
    });

  const bookingProduct = commonParticipantBuilder.buildBookingProduct({
    clubReference,
    diveCenterId,
    bookingId,
    bookingMemberId,
    bookingSessionId,
    bookingSessionParticipant,
    now,
    clubParticipant,
  });
  return {
    clubParticipant,
    bookingSessionParticipant,
    bookingProduct,
  };
}
/**
 *
 * Context d'utilisation:
 * - manipulation de réservation (extraction d'un membre, fusion ou découpage de réservations)
 */
function createParticipantFromSourceProduct({
  participantProductFull,
  bookingSessionId,
  bookingMemberId,
  bookingId,
}: {
  participantProductFull: PRO_BookingParticipantFull;
  bookingSessionId: string;
  bookingMemberId: string;
  bookingId: string;
}): DiverBookingUpdateProductParticipant {
  const now = new Date();

  const sourceProduct = participantProductFull.bookingProductDive;
  const diveCenterId = participantProductFull.bookingSession.diveCenterId; // NOTE: le diveCenterId n'est pas chargé sur le participant, donc on le prend sur la session

  const clubParticipantAttrs: ClubParticipant = {
    ...participantProductFull.diveSessionParticipant,
    diveCenterId,
    _id: undefined,
  };
  // NOTE: ici pas besoin de mettre à jour le participant, c'est exactement le même
  const clubParticipant = commonEntityBuilder.buildEntity<ClubParticipant>(
    clubParticipantAttrs,
    now,
  );
  const sourceBookingSessionParticipant =
    participantProductFull.bookingSessionParticipant;
  const bookingSessionParticipant =
    commonEntityBuilder.buildEntity<BookingSessionParticipant>(
      {
        // new booking ids
        bookingMemberId: bookingMemberId,
        bookingSessionId: bookingSessionId,
        bookingId: bookingId,
        participantId: clubParticipant._id,
        // copy attributes
        _createdAt: sourceBookingSessionParticipant._createdAt,
        _updatedAt: sourceBookingSessionParticipant._updatedAt,
        _version: sourceBookingSessionParticipant._version,
        clubReference: sourceBookingSessionParticipant.clubReference,
        diveCenterId,
        diveSessionReference:
          sourceBookingSessionParticipant.diveSessionReference,
        diverId: sourceBookingSessionParticipant.diverId,
        participantBookingStatus:
          sourceBookingSessionParticipant.participantBookingStatus,
      },
      now,
    );
  const bookingProduct = commonEntityBuilder.buildEntity<BookingProduct>(
    {
      // new booking ids
      bookingMemberId: bookingMemberId,
      bookingSessionId: bookingSessionId,
      bookingId: bookingId,
      bookingSessionParticipantId: bookingSessionParticipant._id,
      diveSessionReference:
        sourceBookingSessionParticipant.diveSessionReference,
      participantId: clubParticipant._id,
      diverId: sourceBookingSessionParticipant.diverId,
      // copy attributes
      _createdAt: sourceProduct._createdAt,
      _updatedAt: sourceProduct._updatedAt,
      _version: sourceProduct._version,
      clubReference: sourceProduct.clubReference,
      diveCenterId,
      bookingDate: sourceProduct.bookingDate,
      bookingLastUpdateDate: sourceProduct.bookingLastUpdateDate,
      type: sourceProduct.type,
      attributes: sourceProduct.attributes,
      purchasePackageId: sourceProduct.purchasePackageId,
      purchasePackage: sourceProduct.purchasePackage,
      bookingProductStatus: sourceProduct.bookingProductStatus,
      bookingProductState: sourceProduct.bookingProductState,
      bookingProductHistory: sourceProduct.bookingProductHistory,
      purchaseStatus: sourceProduct.purchaseStatus,
    },
    now,
  );

  return {
    clubParticipant,
    bookingSessionParticipant,
    bookingProduct,
  };
}
