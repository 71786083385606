/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { DiverBookingPageGlobalState } from './../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageBillingTabState } from './useDiverBookingPageBillingTabState.hook';

export const DiverBookingPageBillingTabFilterCountsBar = ({
  localState,
  globalState,
}: {
  localState: DiverBookingPageBillingTabState;
  globalState: DiverBookingPageGlobalState;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const {
    aggregatedData: aggregatedDataInput,
    updateState,
    focus,
    billingTabFilterCriteria,
    setBillingTabFilterCriteria,
    dialogsState,
  } = globalState;

  const {
    aggregatedData,
    model,
    selectedDiverId,
    setSelectedDiverId,
    diversFilterOptions,
    createPlan,
    createTraining,
    createFirstDive,
  } = localState;

  return model.counts.hiddenPurchasePackages > 0 ||
    model.counts.hiddenParticipants > 0 ||
    model.counts.otherDiveCentersPurchasePackages > 0 ||
    model.counts.otherDiveCentersParticipants > 0 ||
    model.counts.futureNotPurchasedParticipants > 0 ||
    model.counts.veryOldParticipants > 0 ||
    model.counts.cancelledParticipants > 0 ? (
    <div className="my-1 flex justify-between gap-2 flex-wrap">
      <div className="flex gap-2 flex-wrap">
        {model.counts.futureNotPurchasedParticipants > 0 && (
          <AppInputBooleanSwitch
            label={`prestations futures non facturées (${model.counts.futureNotPurchasedParticipants})`}
            value={
              !billingTabFilterCriteria.ignoreFutureNotPurchasedParticipants
            }
            onChange={(value) =>
              setBillingTabFilterCriteria({
                ...billingTabFilterCriteria,
                ignoreFutureNotPurchasedParticipants: !value,
              })
            }
          />
        )}
        {model.counts.veryOldParticipants > 0 && (
          <AppInputBooleanSwitch
            label={`anciennes prestations (${model.counts.veryOldParticipants})`}
            value={
              !billingTabFilterCriteria.ignoreVeryOldNotPurchasedParticipants
            }
            onChange={(value) =>
              setBillingTabFilterCriteria({
                ...billingTabFilterCriteria,
                ignoreVeryOldNotPurchasedParticipants: !value,
              })
            }
          />
        )}
        {model.counts.cancelledParticipants > 0 && (
          <AppInputBooleanSwitch
            label={`prestations annulées (${model.counts.cancelledParticipants})`}
            value={!billingTabFilterCriteria.ignoreCancelledParticipants}
            onChange={(value) =>
              setBillingTabFilterCriteria({
                ...billingTabFilterCriteria,
                ignoreCancelledParticipants: !value,
              })
            }
          />
        )}
        {model.counts.otherDiveCentersPurchasePackages > 0 && (
          <AppInputBooleanSwitch
            theme="danger"
            label={`facturations autres centres (${model.counts.otherDiveCentersPurchasePackages})`}
            value={
              !billingTabFilterCriteria.ignoreOtherDiveCentersPurchasePackages
            }
            onChange={(value) =>
              setBillingTabFilterCriteria({
                ...billingTabFilterCriteria,
                ignoreOtherDiveCentersPurchasePackages: !value,
              })
            }
          />
        )}
        {model.counts.otherDiveCentersParticipants > 0 && (
          <AppInputBooleanSwitch
            theme="danger"
            label={`prestations non facturées autres centres (${model.counts.otherDiveCentersParticipants})`}
            value={
              !billingTabFilterCriteria.ignoreOtherDiveCentersNotPurchasedParticipants
            }
            onChange={(value) =>
              setBillingTabFilterCriteria({
                ...billingTabFilterCriteria,
                ignoreOtherDiveCentersNotPurchasedParticipants: !value,
              })
            }
          />
        )}
      </div>
      {model.counts.hiddenParticipants > 0 && (
        <div className="font-bold text-app-red px-2">
          {model.counts.hiddenParticipants} prestation
          {model.counts.hiddenParticipants === 1 ? '' : 's'} masquée
          {model.counts.hiddenParticipants === 1 ? '' : 's'}
        </div>
      )}
    </div>
  ) : null;
};
