/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveServiceOrganizationReference } from '@mabadive/app-common-model';
import { ReactNode, useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
//import { RegionalTaxLabel } from '../company/RegionalTax/RegionalTaxLabel';
import { ClubSettingsProductOfferTagsOrganization } from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog/useClubSettingsProductOffersTags.hook';
export function useOrganizationsOptions(
  organizations: ClubSettingsProductOfferTagsOrganization[],
): ValueLabel<DiveServiceOrganizationReference, ReactNode>[] {
  const organizationsOptions: ValueLabel<
    DiveServiceOrganizationReference,
    ReactNode
  >[] = useMemo(
    () =>
      organizations.map((x) => ({
        value: x.orgRef,
        label: `${x.orgRef} (${x.quantity})`,
      })),
    [organizations],
  );

  return organizationsOptions;
}
