import {
  ClubStaffMember,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { StaffMemberEditDialogProps, StaffMemberEditFormModel } from './_model';

export function useStaffMemberEditDialogActionPersist(): StaffMemberEditDialogProps {
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;
  const action: StaffMemberEditDialogProps = {
    async onConfirm({ formValue }, initialState) {
      const { defaultValue } = initialState;
      const beginDate = Math.min(
        ...formValue.staffMember.availabilities
          .map((x) => x.beginDate?.getTime())
          .filter((x) => !!x),
      );
      const endDate = Math.max(
        ...formValue.staffMember.availabilities
          .map((x) => x.endDate?.getTime())
          .filter((x) => !!x),
      );
      const newValue: StaffMemberEditFormModel = {
        ...formValue,
        staffMember: {
          ...formValue.staffMember,
          beginDate: beginDate ? new Date(beginDate) : undefined,
          endDate: endDate ? new Date(endDate) : undefined,
        },
      };

      if (initialState.mode === 'edit') {
        const patchOperations = jsonPatcher.compareObjects(
          defaultValue.staffMember,
          newValue.staffMember,
          {
            attributesToReplaceFully: [
              'availabilities',
              'profile',
              'scubaDivingInstructor',
              'equipment',
            ],
          },
        );
        if (patchOperations.length) {
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'edit staff member',
              bookingIdsToClean: [],
            });
          payload.updatedClubStaffMembers = [
            {
              pk: defaultValue.staffMember?._id,
              patchOperations,
            },
          ];
          await clubMassiveUpdatorClient.update(payload);
        } else {
          // nothing to do
        }
      } else if (initialState.mode === 'create') {
        const payload: ProMultiOperationPayload =
          clubMassiveUpdatorClient.createEmptyPayload({
            logContext: 'create staff member',
            bookingIdsToClean: [],
          });
        payload.newClubStaffMembers = [
          commonEntityBuilder.buildEntity<ClubStaffMember>({
            ...newValue.staffMember,
            clubReference,
            diveCenterId,
          }),
        ];
        await clubMassiveUpdatorClient.update(payload);
      }
    },
  };
  return action;
}
