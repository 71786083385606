import {
  BookingMember,
  ClubDiver,
  ClubDiverFull,
  ClubDiverSelectorDialogCreateDiverFormModel,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  diverBookingMemberCreator,
} from '@mabadive/app-common-services';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import { BookingParticipantEditorResult } from '../../models';
import { bookingMemberFormParser } from '../BookingParticipantDialog/BookingParticipantEditorDialog/services/changes-builder';
import { clubDiverFormParser } from '../BookingParticipantDialog/BookingParticipantEditorDialog/services/changes-builder/diver';
import { ClubDiverSelectorDialogUpdateState } from './model';

export const memberCreatorChangeBuilder = {
  updateDiversAndMembers,
  createDiverAndMember,
  addDiverAsMember,
};

function addDiverAsMember({
  updateState,
  clubDiverFull,
  clubReference,
  diveCenterId,
  bookingId,
}: {
  clubDiverFull: ClubDiverFull;
  updateState: ClubDiverSelectorDialogUpdateState;
  clubReference: string;
  diveCenterId: string;
  bookingId: string;
}): ClubDiverSelectorDialogUpdateState {
  let updateStateLocal = updateState;

  const newBookingMember =
    diverBookingMemberCreator.createBookingMemberFromDiver({
      diver: clubDiverFull,
      bookingId,
      clubReference,
      diveCenterId,
    });
  const bookingMembersChanges = changeDescriptorManager.createOne(
    newBookingMember,
    {
      changeDescriptors: updateStateLocal.bookingMembersChanges,
    },
  );

  updateStateLocal = {
    ...updateStateLocal,
    hasChanges: true,
    diversChanges: changeDescriptorManager.addOneOriginal<ClubDiver>(
      clubDiverFull,
      {
        changeDescriptors: updateStateLocal.diversChanges,
      },
    ),
    bookingMembersChanges,
    newBookingMembers: updateStateLocal.newBookingMembers.concat([
      {
        diverId: clubDiverFull._id,
        isNewDiver: false,
      },
    ]),
  };
  return updateStateLocal;
}

function updateDiversAndMembers({
  result,
  updateState,
}: {
  result: BookingParticipantEditorResult;
  updateState: ClubDiverSelectorDialogUpdateState;
}): ClubDiverSelectorDialogUpdateState {
  let updateStateLocal = updateState;

  const { updatedDivers, updatedBookingMembers } = result?.changes;

  if (updatedDivers?.length) {
    const diversChanges = changeDescriptorManager.updateMany(updatedDivers, {
      changeDescriptors: updateStateLocal.diversChanges,
    });
    updateStateLocal = {
      ...updateStateLocal,
      diversChanges,
    };
  }

  if (updatedBookingMembers?.length) {
    const bookingMembersChanges = changeDescriptorManager.updateMany(
      updatedBookingMembers,
      {
        changeDescriptors: updateStateLocal.bookingMembersChanges,
      },
    );
    updateStateLocal = {
      ...updateStateLocal,
      bookingMembersChanges,
    };
  }

  return updateStateLocal;
}

function createDiverAndMember({
  formValue,
  updateState,
  clubReference,
  diveCenterId,
  bookingId,
}: {
  formValue: ClubDiverSelectorDialogCreateDiverFormModel;
  updateState: ClubDiverSelectorDialogUpdateState;
  clubReference: string;
  diveCenterId: string;
  bookingId: string;
}): ClubDiverSelectorDialogUpdateState {
  let updateStateLocal = {
    ...updateState,
    hasChanges: true,
  };

  const massiveCreationEnabled = formValue.massiveCreation?.enabled;

  const massiveCreationQuantity = massiveCreationEnabled
    ? formValue.massiveCreation?.quantity
    : 1;

  for (let i = 0; i < massiveCreationQuantity; i++) {
    const firstNameSuffix = massiveCreationEnabled
      ? ` ${formValue.massiveCreation?.firstNumber + i}`
      : '';

    const diverFormValue = clubDiverFormParser.parseFormValueDiver(formValue);

    const diver = repositoryEntityBuilder.buildEntity<ClubDiver>({
      ...diverFormValue,
      clubReference,
      firstName: firstNameSuffix
        ? `${diverFormValue.firstName}${firstNameSuffix}`
        : diverFormValue.firstName,
    });

    const newBookingMemberAttributes =
      bookingMemberFormParser.parseFormValueBookingMember({
        formValue,
        initialDiver: undefined,
        finalDiver: diver,
      });
    const now = new Date();

    const newBookingMember = repositoryEntityBuilder.buildEntity<BookingMember>(
      {
        clubReference,
        diveCenterId,
        bookingId,
        diverId: diver._id,
        mainCertificationReference: diver.mainCertificationReference,
        ...newBookingMemberAttributes,
      },
      now,
    );
    if (formValue.bookingMember?.details?.expectedDive?.diveMode) {
      diver.defaultDiveConfig = {
        diveMode: formValue.bookingMember?.details?.expectedDive?.diveMode,
      };
    }

    updateStateLocal.diversChanges = changeDescriptorManager.createOne(diver, {
      changeDescriptors: updateStateLocal.diversChanges,
    });
    updateStateLocal.bookingMembersChanges = changeDescriptorManager.createOne(
      newBookingMember,
      {
        changeDescriptors: updateStateLocal.bookingMembersChanges,
      },
    );
    updateStateLocal.newBookingMembers =
      updateStateLocal.newBookingMembers.concat([
        {
          diverId: diver._id,
          isNewDiver: true,
        },
      ]);
  }

  return updateStateLocal;
}
