/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { paymentPlatformClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppDocumentationLink,
  AppPageContainer,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import { uiStore } from 'src/business/_core/store';
import {
  useAppSecurityUserClubAuthorizations,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubSettingsGeneralPageTabId } from 'src/pages/SE-settings/SE-04_other-settings/ClubSettingsGeneral/_services';
import { ClubSettingsGeneralPaymentsViewPanel } from 'src/pages/SE-settings/SE-04_other-settings/ClubSettingsGeneral/tabs';
import {
  ClubDialogsProvider,
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import useRouter from 'use-react-router';
import { ClubSettingsPaymentPlatformCard } from './components';
import { useClubSettingsPaymentPlatformListPageLocalState } from './useClubSettingsPaymentPlatformListPageLocalState';
export const ClubSettingsPaymentPlatformListPage = () => {
  const { match } = useRouter();
  const autoFocus = useAutoFocus();

  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const clubResume = useClubResume();

  const { diveCenterResume, loadableContent, onlinePlatforms } =
    useClubSettingsPaymentPlatformListPageLocalState();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const createStripeAccount = useCallback(async () => {
    if (!operationInProgressMessage) {
      setOperationInProgressMessage('Création du compte de paiement');
      try {
        await paymentPlatformClient.createConnectedAccount();
        uiStore.snackbarMessage.set({
          type: 'success',
          content: 'Compté créé avec succès!',
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        appLogger.error(
          'Erreur innatendue lors de la création du compte Stripe',
        );
        uiStore.snackbarMessage.set({
          type: 'error',
          content: 'Erreur innatendue lors de la création du compte!',
        });
      } finally {
        setOperationInProgressMessage(undefined);
      }
    }
  }, [operationInProgressMessage]);

  const stripeConfig =
    clubResume.clubSettings?.general?.payments?.online?.stripe;
  const isStripeEnabled = stripeConfig?.enabled;

  const az = useAppSecurityUserClubAuthorizations();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const openClubSettingsGeneralDialog = useCallback(
    (tabId: ClubSettingsGeneralPageTabId) => {
      dialogsState.clubSettingsGeneralDialog.openDialog({
        tabId,
        defaultValue: {
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.clubSettingsGeneralDialog],
  );

  return (
    <AppPageContainer className={'bg-gray-50'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.customers.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.customers.main,
            label: 'Clients',
            url: '/club/settings',
          },
          {
            label: 'Paiement en ligne',
          },
        ]}
      >
        <AppDocumentationLink
          className="block h-9 w-9 md:h-11 md:w-11"
          url="https://docs.mabadive.com/docs/paiements/compte-stripe"
        />
      </AppBreadcrumbBar>
      <ClubDialogsProvider dialogsState={dialogsState}>
        <AppPageContentContainer
          className="bg-gray-50 app-p-content"
          {...loadableContent}
        >
          <div className="grid gap-4">
            {isSuperAdmin && isStripeEnabled && (
              <div
                className={clsx(
                  'app-card app-p-content',
                  operationInProgressMessage && 'invisible',
                )}
              >
                <AppButton
                  size="normal"
                  icon={AppHeroIcons.actionAdd}
                  color="primary-outline-light"
                  onClick={() => {
                    createStripeAccount();
                  }}
                >
                  Créer un compte Stripe{' '}
                  <b>{stripeConfig?.env?.toUpperCase()}</b>
                </AppButton>
              </div>
            )}

            <div className="mt-4 flex flex-col gap-4">
              {(onlinePlatforms ?? []).map((onlinePlatform, i) => (
                <ClubSettingsPaymentPlatformCard
                  key={i}
                  onlinePlatform={onlinePlatform}
                  // onClick={() => {
                  //   redirectTo(
                  //     clubSettingsCustomersUrlBuilder.onlinePayments.edit({
                  //       equipmentType: equipment.type,
                  //     }),
                  //   );
                  // }}
                />
              ))}
            </div>
            <div
              className={clsx(
                'app-card app-p-content',
                az?.edit?.settings?.general && 'app-card-highlight',
              )}
              onClick={() => {
                az?.edit?.settings?.general &&
                  openClubSettingsGeneralDialog('payments');
              }}
            >
              <ClubSettingsGeneralPaymentsViewPanel />
              {az?.edit?.settings?.general && (
                <div className="mt-8 mb-2">
                  <AppButton
                    color={'primary-outline-light'}
                    size="normal"
                    icon={AppIcons.action?.edit}
                    onClick={(e) => {
                      e.stopPropagation();
                      openClubSettingsGeneralDialog('payments');
                    }}
                  >
                    Modifier
                  </AppButton>
                </div>
              )}
            </div>
            <div className={globalClasses.pageBlock}>
              {operationInProgressMessage && (
                <LoadingProgressBar>
                  {operationInProgressMessage}
                </LoadingProgressBar>
              )}
            </div>
          </div>
        </AppPageContentContainer>
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
