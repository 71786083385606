/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { AppNavigationStep } from 'src/business/_core/modules/layout';
import { BookingInitialPlanRegistrationMode } from '../../DiverBookingPage/forms/BookingInitialPlanEditForm/model/BookingInitialPlanRegistrationMode.type';

export type CreateBookingInitialPlanNavigationStepId =
  | 'contact'
  | 'certification'
  | 'participants'
  | 'booking';

export function useCreateBookingInitialPlanNavigationSteps({
  registrationMode,
}: {
  registrationMode: BookingInitialPlanRegistrationMode;
}) {
  const [currentStep, setCurrentStep] =
    useState<CreateBookingInitialPlanNavigationStepId>('contact');

  const steps: AppNavigationStep<CreateBookingInitialPlanNavigationStepId>[] =
    registrationMode === 'single'
      ? [
          { id: 'contact', label: 'Coordonnées' },
          { id: 'certification', label: 'Prestation' },
        ]
      : // multiple
        [
          { id: 'contact', label: 'Contact' },
          { id: 'participants', label: 'Participants' },
          { id: 'booking', label: 'Réservation' },
        ];

  // TODO: empêcher de passer à l'onglet suivant tant que le précédent n'est pas valide

  return {
    steps,
    currentStep,
    setCurrentStep,
  };
}
