import {
  BookingResume,
  BookingResumeForListGql_Company,
} from '@mabadive/app-common-model';
import {
  HASURA_DATE_ONLY_JSON_FORMAT,
  HASURA_DATE_TIME_WITH_MS_FORMAT,
  dateService,
} from '@mabadive/app-common-services';
import { MQueryDescription } from 'src/_common-browser';
import { BookingGql1CollectionCriteria } from '../../model';

export function buildBookingGql1CollectionGraphqlQuery({
  clubReference,
  diveCenterId,
  arrivalDate,
  departureDate,
  updatedAfter,
  maxResults = 100,
  archivedExclusive,
  bookingGroupTypes,
  bookingStatuses,
}: BookingGql1CollectionCriteria) {
  const bookingFilters: string[] = [];
  const args: string[] = [];
  bookingFilters.push(`clubReference: {_eq: "${clubReference}"}`);
  bookingFilters.push(
    `_or: [{diveCenterId: {_eq: "${diveCenterId}"}}, {isSharedBooking: {_eq: true}}]`,
  );

  if (departureDate || arrivalDate) {
    const departureDateString = dateService.formatUTC(
      departureDate,
      HASURA_DATE_ONLY_JSON_FORMAT,
    );
    const arrivalDateString = dateService.formatUTC(
      arrivalDate,
      HASURA_DATE_ONLY_JSON_FORMAT,
    );
    if (departureDate && arrivalDate) {
      bookingFilters.push(
        `bookingJourney: {_contains: {departure: {date: "${departureDateString}Z"}, arrival: {date: "${arrivalDateString}Z"}}}`,
      );
    } else if (departureDate) {
      bookingFilters.push(
        `bookingJourney: {_contains: {departure: {date: "${departureDateString}Z"}}}`,
      );
    } else if (arrivalDate) {
      bookingFilters.push(
        `bookingJourney: {_contains: {arrival: {date: "${arrivalDateString}Z"}}}`,
      );
    }
  }

  bookingFilters.push(`active: {_eq: ${archivedExclusive ? false : true}}`);
  if (bookingStatuses?.length > 0) {
    bookingFilters.push(
      `bookingStatus: {_in: [${bookingStatuses
        .map((x) => `"${x}"`)
        .join(',')}]}`,
    );
  }
  if (bookingGroupTypes?.length > 0) {
    bookingFilters.push(` _or: [
      ${bookingGroupTypes
        .map((x) => `{ bookingGroup: { _contains: { type: "${x}" } } }`)
        .join(',')}
    ]`);
  }

  if (updatedAfter) {
    const updatedAfterString = dateService.formatUTC(
      updatedAfter,
      HASURA_DATE_TIME_WITH_MS_FORMAT,
    );

    bookingFilters.push(`_updatedAt: {_gt: "${updatedAfterString}"}`);
  }

  // if (booking === 'only-active') {
  //   bookingFilters.push('active: {_eq: true}');
  //   // bookingFilters.push('bookingStatus: {_neq: "cancelled"}');
  // }
  // bookingFilters.push(
  //   `bookingMembers: {diver: {_id: {_in: [${diversIdsString}]}}}`,
  // );

  // const where = `{${bookingFilters.join(',')}}`;
  const where = `{_and:[${bookingFilters.map((x) => `{${x}}`).join(',')}]}`; // NOTE: obligé d'utiliser un _and:[] pour y inclure potentiellement plusieurs _or

  const queryDescription: MQueryDescription<BookingResume> = {
    // returnName: 'bookingGql1s',
    queryName: 'club_commerce_booking',
    returnType: 'all',
    limit: maxResults,
    where,
    orderBy: '{bookingLastUpdateDate: desc}',
    returnAttributes: BookingResumeForListGql_Company,
  };

  return queryDescription;
}
