/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { AppBreadcrumbBarDeprecated } from 'src/business/_core/modules/layout';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';

export const ClubOfferBreadcrumbBar = ({
  extraContent,
}: {
  extraContent?: ReactNode;
}) => {
  return (
    <AppBreadcrumbBarDeprecated
      extraContent={extraContent}
      hideCloseButton={true}
    >
      <Link color="inherit" to="/club/dive" className="link">
        <AppIconsMaterial.services className="icon" /> Prestations
      </Link>
    </AppBreadcrumbBarDeprecated>
  );
};
