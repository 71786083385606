/* eslint-disable @typescript-eslint/no-unused-vars */
import { ProMultiOperationPayloadActionStep } from '@mabadive/app-common-model';
import JsonView from '@uiw/react-json-view';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppDateTimeLabel } from '../../../../business/club/modules/_common/ui';
import { AdminAppOperationsGraphqlQueryResult } from '../_model';
import {
  AdminErrorDetails,
  adminErrorParser,
} from '../services/adminErrorParser.service';
import { AdminCompaniesListPageLocalState } from '../useAdminAppOperationsListPageLocalState';

export const AdminAppOperationCard = ({
  localState,
  appOperation: op,
  onClick,
  className,
  as = 'div',
}: {
  appOperation: AdminAppOperationsGraphqlQueryResult;
  localState: AdminCompaniesListPageLocalState;
  onClick?: () => any;
  className?: string;
  as?: React.ElementType; // TODO personnaliser le componsant (ici, un "li", mais on pourrait vouloir un "div")
}) => {
  const redirectTo = useRedirect();
  const { filters } = localState;

  const [showDetails, setShowDetails] = useState(false);

  const error: AdminErrorDetails = adminErrorParser.parseErrorString(
    op?.result?.errorMessage,
  );

  const payload = op?.operation?.payload;

  const { actionSteps, ...payloadWithoutActionSteps } = (payload ?? {}) as {
    actionSteps: ProMultiOperationPayloadActionStep[];
  };

  return React.createElement(
    as,
    {
      className: clsx(
        'group flex flex-col divide-y divide-gray-100 app-card-no-padding rounded-l-md',
        showDetails ? 'border-x-8' : 'border-x-4',
        op?.result?.success === false ? 'border-red-600' : 'border-green-600',
        onClick && 'cursor-pointer',
        className,
      ),
      onClick,
      style: {
        // backgroundColor: colorGenerator.rgba(category.color, 0.1),
      },
    },
    <div className={clsx('w-full flex gap-4 overflow-hidden')}>
      <div className="flex-grow app-p-content flex flex-col gap-4">
        <div
          className={clsx('grid md:grid-cols-2 gap-4 text-gray-800')}
          onClick={(e) => {
            e.stopPropagation();
            setShowDetails(!showDetails);
          }}
        >
          <div className="flex gap-4">
            <div className="flex flex-col gap-1">
              <h3 className="text-gray-600 text-xs">Date</h3>
              <div className="text-gray-600 text-xs font-bold">
                <AppDateTimeLabel
                  date={op.startDate}
                  format="date-time"
                  timezone={'local'}
                  steps={[
                    {
                      hours: 1,
                      className: 'text-green-600',
                    },
                    {
                      hours: 48,
                      className: 'text-gray-600',
                    },
                    {
                      className: 'text-red-600',
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <h3 className="text-gray-600 text-xs">Club</h3>
              <div className="text-gray-600 text-xs font-bold">
                {op.club?.name}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <h3 className="text-gray-600 text-xs">Utilisateur</h3>
              <div className="text-gray-600 text-xs font-bold">
                {op.authUser?.login}
              </div>
            </div>
            <div className="md:col-span-2 flex flex-col gap-1">
              <h3 className="text-gray-600 text-xs">Opération</h3>
              <div className="text-gray-600 text-xs font-bold">
                {op?.operation?.id}
              </div>
            </div>
          </div>
          {op?.result?.errorMessage && (
            <div className="flex gap-4">
              {error && (
                <div className="flex gap-2">
                  {error.constraint && (
                    <>
                      <div className="md:col-span-2 flex flex-col gap-1">
                        <h3 className="text-gray-600 text-xs">Contrainte</h3>
                        <div className="text-gray-600 text-xs font-bold">
                          {error.constraint.name ?? error.constraint.type}
                        </div>
                      </div>
                      <div className="md:col-span-2 flex flex-col gap-1">
                        <h3 className="text-gray-600 text-xs">Table</h3>
                        <div className="text-gray-600 text-xs font-bold">
                          {error.constraint.tableName}
                        </div>
                      </div>{' '}
                      <div className="md:col-span-2 flex flex-col gap-1">
                        <h3 className="text-gray-600 text-xs">Colonne</h3>
                        <div className="text-gray-600 text-xs font-bold">
                          {error.constraint.columnName}
                        </div>
                      </div>
                    </>
                  )}
                  {error.operation && (
                    <>
                      {error.operation.name && (
                        <div className="md:col-span-2 flex flex-col gap-1">
                          <h3 className="text-gray-600 text-xs">Update</h3>
                          <div className="text-gray-600 text-xs font-bold">
                            {error.operation.name}
                          </div>
                        </div>
                      )}
                      {error.operation.tableName && (
                        <div className="md:col-span-2 flex flex-col gap-1">
                          <h3 className="text-gray-600 text-xs">Table</h3>
                          <div className="text-gray-600 text-xs font-bold">
                            {error.operation.tableName}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {/* <div
                className={clsx(
                  'mt-2 bg-red-200 p-2',
                  showDetails ? '' : 'truncate',
                )}
              >
                {op?.result?.errorMessage}
              </div> */}
            </div>
          )}
        </div>

        {showDetails && (
          <>
            {actionSteps?.length > 0 && (
              <div>
                <h3 className="text-gray-600 text-xs">Etapes</h3>

                <table className="app-table">
                  <thead>
                    <tr>
                      <th className="px-2">Date</th>
                      <th className="px-2">Action</th>
                      <th className="px-2">Meta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actionSteps.map((actionStep, index) => (
                      <tr key={index}>
                        <td className="px-2">
                          <AppDateTimeLabel
                            date={actionStep.date}
                            format={'date-time-seconds'}
                            timezone={'local'}
                          />
                        </td>
                        <td className="px-2">{actionStep.label}</td>
                        <td className="px-2">
                          {actionStep.meta && (
                            <JsonView title="Meta" value={actionStep.meta} />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {op?.result?.errorMessage && (
              <div className="md:col-span-2 flex flex-col gap-1">
                <h3 className="text-gray-600 text-xs">Erreur</h3>
                <div className={clsx('mt-2 border-4 border-red-200 p-2')}>
                  {op?.result?.errorMessage}
                </div>
              </div>
            )}
            <div className="grid md:grid-cols-2 gap-2">
              {op?.operation && (
                <div className="flex flex-col gap-1">
                  <h3 className="text-gray-600 text-xs">Payload</h3>
                  <JsonView title="Payload" value={payloadWithoutActionSteps} />
                </div>
              )}
              {op?.result && (
                <div className="flex flex-col gap-1">
                  <h3 className="text-gray-600 text-xs">Résultat</h3>
                  <JsonView title="Payload" value={op?.result} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>,
  );
};
