import {
  AppEntityUpdatePatch,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { DiveCenterEditDialogProps } from './model';

export function useDiveCenterEditDialogActionPersist(): DiveCenterEditDialogProps {
  const action: DiveCenterEditDialogProps = {
    async onConfirm({ formValue }, initialState) {
      const { mode, defaultValue } = initialState;
      if (mode === 'edit') {
        const diveCenterPatchOperations = jsonPatcher.compareObjects(
          defaultValue.diveCenter,
          {
            ...formValue.diveCenter,
          },
          {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: true,
          },
        );
        const clubSettingsPatchOperations = jsonPatcher.compareObjects(
          defaultValue.clubSettings,
          {
            ...formValue.clubSettings,
          },
          {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: true,
            attributesToReplaceFully: ['billingData'],
          },
        );
        if (
          diveCenterPatchOperations.length ||
          clubSettingsPatchOperations.length
        ) {
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'edit dive center',
              bookingIdsToClean: [],
            });
          if (diveCenterPatchOperations.length) {
            const patch: AppEntityUpdatePatch = {
              pk: defaultValue.diveCenter._id,
              patchOperations: diveCenterPatchOperations,
            };
            payload.diveCenters.updated.push(patch);
          }
          if (clubSettingsPatchOperations.length) {
            const patch: AppEntityUpdatePatch = {
              pk: defaultValue.clubSettings._id,
              patchOperations: clubSettingsPatchOperations,
            };
            payload.updatedClubSettings = patch;
          }
          return clubMassiveUpdatorClient.update(payload);
        }
      } else if (mode === 'clone') {
        const payload: ProMultiOperationPayload =
          clubMassiveUpdatorClient.createEmptyPayload({
            logContext: 'clone dive center',
            bookingIdsToClean: [],
          });
        payload.diveCenters.duplicated.push({
          originalPk: formValue.diveCenter._id,
          value: {
            ...formValue.diveCenter,
            _id: null,
          },
        });
        return clubMassiveUpdatorClient.update(payload);
      }
    },
  };
  return action;
}
