import { AppCurrency } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppPriceLabel } from './AppPriceLabel';

export const AppCurrencyChangeLabel = ({
  mainCurrency,
  paymentCurrency,
  defaultRate,
  defaultRateReverse,
  className = '',
}: {
  mainCurrency: AppCurrency;
  paymentCurrency: AppCurrency;
  defaultRate: number;
  defaultRateReverse: boolean;
  className?: string;
}) => {
  return (
    <div className={clsx('flex gap-2 text-left', className)}>
      <span>
        <AppPriceLabel
          amount={defaultRateReverse ? defaultRate : 1}
          mainCurrency={mainCurrency}
          centsCountInput={5}
        />
      </span>
      <span>=</span>
      <span>
        <AppPriceLabel
          amount={defaultRateReverse ? 1 : defaultRate}
          mainCurrency={paymentCurrency}
          centsCountInput={5}
        />
      </span>
    </div>
  );
};
