/* eslint-disable @typescript-eslint/no-unused-vars */
import { nameFormatter } from '@mabadive/app-common-services';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { NEVER } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { useGlobalClasses } from 'src/AppTheme';
import { accountProfileRepository } from 'src/business/account/data/repository';
import { authenticationClient } from 'src/business/auth/services';
import { useLoadable, useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBarDeprecated,
  AppFixedButtonsBar,
  AppPageBlock,
  AppPageContainer,
  AppPageContentContainer,
  AppVisible,
} from 'src/business/_core/modules/layout';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import {
  RxjsForm,
  rxjsFormActionManager,
  RxjsFormAppTextField,
  RxjsFormControl,
} from 'src/lib/form/components/rxjs-form';
import { ApiClientHttpError } from 'src/_common-browser';
import { AccountProfileEditPageFormModel } from './AccountProfileEditPageFormModel.type';
import { accountProfileEditPageRepository } from './accountProfileEditPageRepository.service';

export type AccountProfileEditPageContext = {
  mode: 'create' | 'edit';
};

export const AccountProfileEditPage = () => {
  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();
  const [resetPasswordState, setResetPasswordState] =
    useState<'ready' | 'in-progress' | 'success' | 'error'>('ready');

  const { _loaded, _error, form, components, authUser, accountProfile } =
    useLoadable({
      debugName: 'AccountProfileEditPage',
      initialValueFromLoadSnapshot: true,
      load: () => accountProfileEditPageRepository.loadInitialData(),
    });

  useMemo(() => {
    if (_error) {
      appLogger.warn('Error loading data: redirect');
      back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_error]);

  function resetPassword() {
    setResetPasswordState('in-progress');
    return authenticationClient
      .resetPassword()
      .pipe(
        tap(() => {
          setResetPasswordState('success');
        }),
        catchError((err: ApiClientHttpError) => {
          setResetPasswordState('error');
          return NEVER;
        }),
      )
      .subscribe();
  }

  return !_loaded ? null : (
    <AppPageContainer className={'bg-white'}>
      <AppBreadcrumbBarDeprecated hiddenSideMenu={true} onClose={() => back()}>
        <AppVisible role="account">
          <Link color="inherit" to="/admin/settings" className="link">
            <AppIconsMaterial.settings className="icon" /> Profil
          </Link>
        </AppVisible>
        <Typography color="textPrimary" className="end-text">
          {nameFormatter.formatFullName(accountProfile)}
        </Typography>
      </AppBreadcrumbBarDeprecated>

      <AppPageContentContainer className="bg-white app-px-content">
        <AppPageBlock>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <RxjsFormControl
                label="Nom"
                attrState={components['lastName'].state}
                renderComponent={() => (
                  <RxjsFormAppTextField fullWidth {...components['lastName']} />
                )}
              />
              <RxjsFormControl
                label="Prénom"
                attrState={components['firstName'].state}
                renderComponent={() => (
                  <RxjsFormAppTextField
                    fullWidth
                    {...components['firstName']}
                  />
                )}
              />
              <RxjsFormControl
                label="Fonction"
                attrState={components['staffRole'].state}
                renderComponent={() => (
                  <RxjsFormAppTextField
                    fullWidth
                    {...components['staffRole']}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <RxjsFormControl
                label="Adresse e-mail de contact"
                attrState={components['emailAddress'].state}
                renderComponent={() => (
                  <RxjsFormAppTextField
                    fullWidth
                    {...components['emailAddress']}
                  />
                )}
              />
              <RxjsFormControl
                label="Numéro de téléphone"
                attrState={components['phoneNumber'].state}
                renderComponent={() => (
                  <RxjsFormAppTextField
                    fullWidth
                    {...components['phoneNumber']}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box mb={2}>
                Identifiant: <b>{authUser.login}</b>
              </Box>
              {resetPasswordState === 'success' ? (
                <Box mt={2} mb={2}>
                  <Typography>
                    Un email de confirmation a été envoyé à l'adresse
                    <b>"{accountProfile.emailAddress}"</b>. Veuillez consulter
                    votre boite mail.
                  </Typography>
                </Box>
              ) : (
                <>
                  <Button
                    disabled={resetPasswordState === 'in-progress'}
                    startIcon={<AppIconsMaterial.refresh />}
                    variant="contained"
                    className={globalClasses.buttonSave}
                    onClick={() => resetPassword()}
                  >
                    Modifier mon mot de passe
                  </Button>
                  {resetPasswordState === 'error' ? (
                    <Box mt={2}>
                      <Typography>
                        Erreur innatendue. Veuillez vérifier votre connexion
                        Internet et ré-essayer. Si cela persiste, merci de nous
                        contacter.
                      </Typography>
                    </Box>
                  ) : null}
                </>
              )}
            </Grid>
          </Grid>
        </AppPageBlock>
        <AppFixedButtonsBar hasChanges={form.hasChanges}>
          <>
            <Button
              startIcon={<AppIconsMaterial.cancel />}
              variant="outlined"
              tabIndex={500}
              className={globalClasses.buttonCancel}
              onClick={() => back()}
            >
              Annuler
            </Button>
            <Button
              startIcon={<AppIconsMaterial.save />}
              variant="contained"
              className={globalClasses.buttonSave}
              onClick={() => save(form)}
            >
              Enregistrer
            </Button>
          </>
        </AppFixedButtonsBar>
      </AppPageContentContainer>
    </AppPageContainer>
  );

  function back(): void {
    appRouteBuilder.getDefaultRoute().subscribe((defaultRoute) => {
      redirectTo(defaultRoute);
    });
  }

  function save(form: RxjsForm<AccountProfileEditPageFormModel>) {
    rxjsFormActionManager
      .saveChanges({
        form,
        isCreationMode: false,
        attributesToReplaceFully: [],
        createMethod: ({ form }) => NEVER,
        updateMethod: ({ form, patchOperations }) =>
          accountProfileRepository.patch({
            criteria: { _id: accountProfile._id },
            patchOperations,
          }),
      })
      .subscribe(({ success }) => {
        if (success) {
          back();
        }
      });
  }
};
