/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  ClubDiverSimilarSearchEntry,
  ClubDiverSimilarSearchEntryNames,
} from '../model';

export const clubDiverSimilarSearchScoreBuilder = { calculateScore };

function calculateScore({
  candidate,
  formSearchEntry,
}: {
  candidate: ClubDiverSimilarSearchEntry<ClubDiverSimilarSearchEntryNames>;
  formSearchEntry: ClubDiverSimilarSearchEntry<ClubDiverSimilarSearchEntryNames>;
}): number {
  const { firstName, lastName } = candidate.indexesFull;
  if (
    testNamesMatch(candidate.indexesFull, formSearchEntry.indexesFull, {
      invertible: false,
    })
  ) {
    return 99; // full: perfect match
  }
  if (
    testNamesMatch(candidate.indexesFull, formSearchEntry.indexesFull, {
      invertible: true,
    })
  ) {
    return 95; // full: inversion nom/prénom
  }
  if (
    testNamesMatch(candidate.indexesStart[5], formSearchEntry.indexesStart[5], {
      invertible: false,
    })
  ) {
    return 90; // 5 letters: perfect match
  }
  if (
    testNamesMatch(candidate.indexesStart[5], formSearchEntry.indexesStart[5], {
      invertible: true,
    })
  ) {
    return 80; // 5 letters: inversion nom/prénom
  }

  if (
    testNamesMatch(candidate.indexesStart[4], formSearchEntry.indexesStart[4], {
      invertible: false,
    })
  ) {
    return 70; // 4 letters: perfect match
  }
  if (
    testNamesMatch(candidate.indexesStart[4], formSearchEntry.indexesStart[4], {
      invertible: true,
    })
  ) {
    return 65; // 4 letters: inversion nom/prénom
  }

  // if (
  //   testNamesMatch(candidate.indexesStart[3], formSearchEntry.indexesStart[3], {
  //     invertible: false,
  //   })
  // ) {
  //   return 55; // 3 letters: perfect match
  // }
  // if (
  //   testNamesMatch(candidate.indexesStart[3], formSearchEntry.indexesStart[3], {
  //     invertible: true,
  //   })
  // ) {
  //   return 50; // 3 letters: inversion nom/prénom
  // }

  // if (searchName === diverName)
  //   // Exact match
  //   return 50;
  // // Prefix or suffix match (90% match)
  // if (diverName.startsWith(searchName) || diverName.endsWith(searchName))
  //   return 45;
  // // One character off anywhere in the string (80% match)
  // if (
  //   searchName.length === diverName.length &&
  //   searchName.split('').filter((c, i) => c !== diverName[i]).length === 1
  // )
  //   return 40;
  // ... more rules can be added with decreasing score values
  // Default no match
  return 0;
}

function testNamesMatch(
  a: ClubDiverSimilarSearchEntryNames,
  b: ClubDiverSimilarSearchEntryNames,
  {
    invertible,
    onlyOneMatch,
  }: {
    invertible: boolean;
    onlyOneMatch?: boolean;
  },
) {
  if (onlyOneMatch) {
    if (
      a.lastName === b.lastName ||
      a.firstName === b.firstName ||
      (invertible && (a.firstName === b.lastName || a.lastName === b.firstName))
    ) {
      return true;
    }
  } else {
    if (
      (a.lastName === b.lastName && a.firstName === b.firstName) ||
      (invertible && a.firstName === b.lastName && a.lastName === b.firstName)
    ) {
      return true;
    }
  }
}
