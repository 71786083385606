import { AppBookletWithPagesGql_Company } from '@mabadive/app-common-model';
import { ProEcommerceDataFetchedData } from './ProEcommerceDataFetchedData.hook';
import { ProEcommerceDataLoadedContent } from './ProEcommerceDataLoadedContent.hook';

export const proEcommerceDataLoadedContentBuilder = { buildContent };
function buildContent({
  fetchedData,
}: {
  fetchedData: ProEcommerceDataFetchedData;
}): ProEcommerceDataLoadedContent {
  const content: ProEcommerceDataLoadedContent = {
    categories: [], // EcommerceCategoryGql_Company[];
    products: [], // EcommerceProductGql_Company[];
    productArticles: [], // EcommerceProductArticleGql_Company[];
    productCategoryConfigs: [], // EcommerceProductCategoryConfigGql_Company[];
    booklets: [], // AppBookletGql_Company[];
    bookletPages: [], // AppBookletPageGql_Company[];
  };

  if (fetchedData?.categoriesWithBooklet) {
    for (const categoryWithBooklet of fetchedData.categoriesWithBooklet) {
      const { booklet: bookletWithPages, ...category } = categoryWithBooklet;
      content.categories.push(category);
      appendBooklet({ bookletWithPages, content });
    }
  }
  if (fetchedData?.productsFull) {
    for (const productFull of fetchedData.productsFull) {
      const {
        booklet: bookletWithPages,
        categoryConfigs,
        articles: articlesFull,
        ...product
      } = productFull;
      content.products.push(product);
      appendBooklet({ bookletWithPages, content });
      content.productCategoryConfigs.push(...categoryConfigs);
      for (const articleFull of articlesFull) {
        const { booklet: bookletWithPages, ...article } = articleFull;
        content.productArticles.push(article);
        appendBooklet({ bookletWithPages, content });
      }
    }
  }

  return content;
}
function appendBooklet({
  bookletWithPages,
  content,
}: {
  bookletWithPages: AppBookletWithPagesGql_Company;
  content: ProEcommerceDataLoadedContent;
}) {
  if (bookletWithPages) {
    const { pages, ...booklet } = bookletWithPages;
    content.booklets.push(booklet);
    for (const page of pages) {
      content.bookletPages.push(page);
    }
  }
}
