import {
  ClubSettings,
  FIRST_DIVES,
  FirstDiveTrainingReference,
} from '@mabadive/app-common-model';
import { diveServiceFirstDiveFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useFirstDiveTrainingOptions(
  clubSettings: ClubSettings,
  {
    scope,
  }: {
    scope: 'all' | 'enabled';
  },
): ValueLabel<FirstDiveTrainingReference, string>[] {
  const publicSettings = clubSettings.publicSettings;
  return useMemo(
    () =>
      FIRST_DIVES.filter(
        (firstDive) =>
          firstDive.diveMode === 'first-dive' &&
          (scope === 'all' ||
            clubSettings.firstDive.trainingReferences.includes(
              firstDive.reference as FirstDiveTrainingReference,
            )),
      ).map((firstDive) => {
        const label = diveServiceFirstDiveFormatter.formatFirstDive(firstDive, {
          format: 'ref-name',
          publicSettings,
        });

        return {
          value: firstDive.reference,
          label,
        };
      }),
    [clubSettings.firstDive.trainingReferences, publicSettings, scope],
  );
}
