/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubSettingsGeneralDeposits_BookingAssociations } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppSingleSelect2HeadlessUIRHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { ValueLabel } from '../../../../../../../business/club/modules/_common/form';
import { ClubSettingsGeneralFormModel } from '../../../_model';

export const ClubSettingsGeneralPaymentsEditFormDeposit = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    setFocus,
  } = form;

  const [paymentsEnabled, agenciesEnabled, depositsEnabled] = useWatch({
    control,
    name: [
      'clubSettings.general.payments.enabled',
      'clubSettings.general.agencies.enabled',
      'clubSettings.general.deposits.enabled',
    ],
  });

  const bookingAssociationsOptions: ValueLabel<
    ClubSettingsGeneralDeposits_BookingAssociations,
    string
  >[] = [
    {
      value: 'all',
      label: 'Pour toutes les réservations',
    },
    {
      value: 'online-only',
      label: 'Seulement pour les réservations en ligne',
    },
    {
      value: 'never',
      label: 'Jamais',
    },
  ];

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return !paymentsEnabled ? null : (
    <ClubSettingsSection title="Acomptes">
      <div className="mt-2 grid gap-x-4 gap-y-2 sm:flex">
        {isSuperAdmin && (
          <AppFormControlRHF_Deprecated
            label="Activer les acomptes"
            control={control}
            name={'clubSettings.general.deposits.enabled'}
            required={false}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
        )}
        {depositsEnabled && (
          <AppFormControlRHF
            className="min-w-[100px]"
            label="Affichage des icône pour tous pes participants sur le planning"
            helpDescription={
              'Afficher sur le planning l\'icône "acompte" pour tous les participants de la résa'
            }
            control={control}
            name={'clubSettings.general.deposits.bookingAssociations'}
            required={true}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                theme={'tailwind'}
                disableClearButton={true}
                {...props}
                options={bookingAssociationsOptions}
              />
            )}
          />
        )}
        {agenciesEnabled && depositsEnabled && (
          <AppFormControlRHF_Deprecated
            label="Activer les acomptes d'agences"
            control={control}
            name={'clubSettings.general.agencies.agencyDeposits'}
            required={false}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
        )}
      </div>
    </ClubSettingsSection>
  );
};
