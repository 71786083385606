export const browserUrlParser = {
  getUrlQueryParams,
};

function getUrlQueryParams() {
  let match,
    pl = /\+/g,  // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function (s: string) { return decodeURIComponent(s.replace(pl, ' ')); },
    query = window.location.search.substring(1);

  const urlParams = {} as any;
  // eslint-disable-next-line no-cond-assign
  while (match = search.exec(query)) {
    const decodedMatch1 = decode(match[1]);
    urlParams[decodedMatch1] = decode(match[2]);
  }
  return urlParams;
}