import React from 'react';
import { Switch } from 'react-router-dom';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { ClubSettingsAccountDataManagementPage } from './ClubSettingsAccountDataManagementPage';
import { ClubSettingsAccountDisplayPage } from './ClubSettingsAccountDisplay';
import { ClubSettingsGeneralPage } from './ClubSettingsGeneral';
import {
  CompanyAccomodationEditPage,
  CompanyAccomodationsListPage,
} from './accomodation';
import { BookingAgenciesListPage, BookingAgencyEditPage } from './agencies';
import {
  ClubSettingsCustomerFormFieldsEditPage,
  ClubSettingsCustomerFormFieldsViewPage,
} from './customer-form';

export const ClubSettingsOtherRoutes = React.memo(
  function ClubSettingsSecurityRoutesMemo() {
    const { match } = useRouter();
    return (
      <Switch>
        <AppRoute
          path={`${match.url}/company-general`}
          component={ClubSettingsGeneralPage}
          auth={{ requiredRoles: ['club-view-settings'] }}
        />
        <AppRoute
          path={`${match.url}/display`}
          component={ClubSettingsAccountDisplayPage}
          auth={{ requiredRoles: ['club-edit-settings'] }}
        />
        <AppRoute
          path={`${match.url}/data-management`}
          component={ClubSettingsAccountDataManagementPage}
          checkAuth={({ az }) => az?.edit?.settings?.archiveData}
        />
        <AppRoute
          path={`${match.url}/form-fields`}
          exact
          component={ClubSettingsCustomerFormFieldsViewPage}
          auth={{ requiredRoles: ['club-edit-settings'] }}
        />
        <AppRoute
          path={`${match.url}/form-fields/edit`}
          exact
          component={ClubSettingsCustomerFormFieldsEditPage}
          auth={{ requiredRoles: ['club-edit-settings'] }}
        />
        <AppRoute
          path={`${match.url}/accomodations`}
          exact
          component={CompanyAccomodationsListPage}
          auth={{ requiredRoles: ['club-edit-settings'] }}
        />
        <AppRoute
          path={`${match.url}/accomodations/nouveau`}
          exact
          component={CompanyAccomodationEditPage}
          navigationContext={{ mode: 'create' }}
        />
        <AppRoute
          path={`${match.url}/accomodations/:accommodationId/edit`}
          exact
          component={CompanyAccomodationEditPage}
          navigationContext={{ mode: 'edit' }}
        />{' '}
        <AppRoute
          path={`${match.url}/agencies`}
          exact
          component={BookingAgenciesListPage}
          auth={{ requiredRoles: ['club-edit-settings'] }}
        />
        <AppRoute
          path={`${match.url}/agencies/nouveau`}
          exact
          component={BookingAgencyEditPage}
          navigationContext={{ mode: 'create' }}
        />
        <AppRoute
          path={`${match.url}/agencies/:agencyId/edit`}
          exact
          component={BookingAgencyEditPage}
          navigationContext={{ mode: 'edit' }}
        />
        {/* default route */}
        <AppRoute
          path={`${match.url}/`}
          exact={false}
          redirectToUrl="/club/settings"
        />
      </Switch>
    );
  },
);
