import {
  ClubPlanningLightSessionDef,
  DiveCenterDailyConfig,
  DiveSessionResumeFull,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useDailyExportDownloadModal } from '../../../../../../../pages/DA-dashboard/_core/components/DailySessionsResumeCard/DailyExportDownloadModal';
import {
  ClubDialogsState,
  ClubDialogsStateOld,
} from '../../../../../../../pages/_dialogs';
import { confirmDialog } from '../../../../../../_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppHeroIcons,
  AppIconsAction,
} from '../../../../../../_core/modules/layout/icons';
import {
  useAppSecurityUserClubAuthorizations,
  useAppSecurityUserHasRole,
} from '../../../../../../auth/services';
import {
  useClubResume,
  useClubSettings,
  useDiveCenterResume,
} from '../../../../../data/hooks';
import { AppHamburgerMenuItem } from '../../../../_common/ui';
import { securitySheetDownloader } from '../../../../club-dive-session/pages/SessionResumePage';
import { ClubPlanningLightPanelViewClickActions } from '../../../model';
import { diveSessionStatusUpdateManager } from '../session-security-sheet-card';

export function useDiveSessionMenu({
  dialogsState,
  actions,
  diveSession,
  dialogsOld,
  diveSessionResumes,
  dailyConfigs,
}: {
  dialogsState: ClubDialogsState;
  actions: ClubPlanningLightPanelViewClickActions;
  diveSession: ClubPlanningLightSessionDef;
  dialogsOld: ClubDialogsStateOld;
  diveSessionResumes: DiveSessionResumeFull[];
  dailyConfigs: DiveCenterDailyConfig[];
}) {
  const dailyExportModal = useDailyExportDownloadModal({
    dialogsState,
  });

  const az = useAppSecurityUserClubAuthorizations();
  const diveCenterResume = useDiveCenterResume();
  const { settingsPlanning, _id: diveCenterId } = diveCenterResume;

  const hideOldSecuritySheetExport =
    diveCenterResume.privateSettings.exports.hideOldSecuritySheetExport;
  const dailyExportsSettings =
    diveCenterResume.privateSettings.exports.dailyResumes;

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;
  const clubSettings = useClubSettings();

  const isEditSecuritySheetRole = useAppSecurityUserHasRole(
    'club-edit-planning-security-sheet',
  );
  const isEditSessionRole = useAppSecurityUserHasRole(
    'club-edit-planning-session',
  );
  const isEditSettingsDiveCenterRole = useAppSecurityUserHasRole(
    'club-edit-settings-dive-center',
  );

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    hamburgerMenuItems.push({
      title: 'Afficher la session',
      icon: AppHeroIcons.actionOpen,
      severity: 'info',
      onClick: () => actions.onClickSession(diveSession),
    });
    if (isEditSessionRole && diveSession.status !== 'deleted') {
      hamburgerMenuItems.push({
        id: 'configure-session',
        title: 'Configurer la session',
        description: 'Heure, site de plongée, bateau...',
        severity: 'warn',
        icon: AppHeroIcons.actionSettings,
        onClick: () => actions.onClickEditSession(diveSession),
      });
    }

    if (
      isEditSecuritySheetRole &&
      !(diveSession.status === 'deleted' || diveSession.isVirtual)
    ) {
      hamburgerMenuItems.push({
        id: 'configure-groups',
        title: 'Configurer les palanquées',
        description: 'Configurer palanquées et encadrants',
        severity: 'warn',
        icon: AppHeroIcons.group,
        onClick: () => {
          // open session groups editor
          dialogsOld.setSessionEditorDialogState({
            isOpen: true,
            tabId: 'edit-dive-groups',
            mode: 'edit-session',
            originalSessionFull: diveSession,
            diveSessionResumes,
            dailyConfigs,
          });
        },
      });
    }
    if (
      !(
        diveSession.status === 'cancelled' ||
        diveSession.status === 'deleted' ||
        diveSession.isVirtual ||
        hideOldSecuritySheetExport
      )
    ) {
      hamburgerMenuItems.push({
        id: 'print-security-sheet',
        title: 'Imprimer la fiche de sécurité',
        description: 'Exportez la fiche de sécurité au format Excel',
        icon: AppHeroIcons.actionPrint,
        severity: 'info',
        onClick: () => {
          securitySheetDownloader.downloadSecuritySheet({
            diveSessionReference: diveSession.reference,
          });
        },
      });
    }
    if (az.download?.dailyResume && dailyExportsSettings?.length > 0) {
      dailyExportsSettings
        .filter(
          (x) =>
            x.enabled &&
            x.visibility.planningSession &&
            !(
              diveSession.status === 'cancelled' ||
              diveSession.status === 'deleted' ||
              diveSession.isVirtual
            ),
        )
        .forEach((dailyExportSettings) => {
          const menuItem: Partial<AppHamburgerMenuItem> = {
            id: 'export-daily-resume',
            title: hideOldSecuritySheetExport
              ? dailyExportSettings.label
              : `${dailyExportSettings.label} (BETA)`,
            icon: AppHeroIcons.actionPrint,
            description: dailyExportSettings.description,
            severity: 'info',
            onClick: () => {
              const dateUTC = dateService.getUTCDateSetTime(
                diveSession.time,
                0,
              );
              dailyExportModal.openModal({
                dailyExportSettings,
                sessions: [diveSession],
                dateUTC,
              });
            },
          };
          hamburgerMenuItems.push(menuItem);
        });
    }
    if (
      !(
        diveSession.status === 'deleted' || !diveSession.participants?.length
      ) &&
      clubSettings.communication?.session?.enableMessage &&
      az.communication?.action?.sendSessionMessage
    ) {
      hamburgerMenuItems.push({
        id: 'send-session-message',
        title: 'Envoyer un MESSAGE',
        description: 'Envoyer un message aux participants',
        severity: 'info',
        icon: AppHeroIcons.sms,
        onClick: () => {
          dialogsState.createMessageToCustomersDialog.openDialog({
            mode: 'create',
            defaultValue: {
              //
            },
            context: 'session',
            sessionContext: {
              participants: diveSession.participants,
              diveSession: diveSession,
            },
          });
        },
      });
    }
    if (
      isEditSessionRole &&
      !(
        diveSession.status === 'deleted' ||
        diveSession.participants?.length === 0
      )
    ) {
      hamburgerMenuItems.push({
        id: 'massive-session-move-divers',
        title: 'Déplacer des participants',
        description: 'Déplacement rapide de participants ou palanquées...',
        icon: AppHeroIcons.actionEditBulk,
        severity: 'warn',
        onClick: () => {
          // dialogs.setSessionEditorDialogState({
          //   isOpen: true,
          //   mode: 'edit',
          //   originalSessionFull: diveSession,
          //   originalParticipants: diveSession.participants,
          //   originalGroups: diveSession.groups,
          // });
          // TODO: adapter le modèle pour faire passer les mêmes paramètres que le dialog d'édition de session
          dialogsState.diveSessionEditorMassiveDialog.openDialog({
            actionId: 'move-participants',
            originalSessionFull: diveSession,
            originalParticipants: diveSession.participants,
            originalGroups: diveSession.groups,
            originalBookingSessionParticipants:
              diveSession.bookingSessionParticipants,
          });
        },
      });
    }
    if (
      isEditSessionRole &&
      !(
        diveSession.status === 'deleted' ||
        diveSession.participants?.length === 0
      )
    ) {
      hamburgerMenuItems.push({
        id: 'massive-session-copy-divers',
        title: 'Copier des participants',
        description: 'Copie rapide de participants ou palanquées...',
        icon: AppHeroIcons.actionEditBulk,
        severity: 'warn',
        onClick: () => {
          // TODO: adapter le modèle pour faire passer les mêmes paramètres que le dialog d'édition de session
          dialogsState.diveSessionEditorMassiveDialog.openDialog({
            actionId: 'clone-participants',
            originalSessionFull: diveSession,
            originalParticipants: diveSession.participants,
            originalGroups: diveSession.groups,
            originalBookingSessionParticipants:
              diveSession.bookingSessionParticipants,
          });
        },
      });
    }
    if (
      isEditSettingsDiveCenterRole &&
      !(diveSession.status === 'deleted' || diveSession.isVirtual)
    ) {
      hamburgerMenuItems.push({
        id: 'configure-staff-members',
        title: 'Configurer les moniteurs',
        description: 'Configurer les moniteurs et encadrants',
        icon: AppHeroIcons.actionEditAlt,
        severity: 'warn',
        onClick: () => {
          // open session groups editor
          dialogsOld.setSessionEditorDialogState({
            isOpen: true,
            tabId: 'list-staff-members',
            mode: 'edit-session',
            originalSessionFull: diveSession,
            diveSessionResumes,
            dailyConfigs,
          });
        },
      });
    }

    if (
      isEditSessionRole &&
      !(
        diveSession.status === 'deleted' ||
        diveSession.status === 'off' ||
        diveSession.status === 'cancelled'
      )
    ) {
      hamburgerMenuItems.push({
        title: 'Fermer la session',
        description: "Fermer la session si elle n'est pas encore confirmée",
        icon: AppHeroIcons.actionLock,
        severity: 'danger',
        onClick: () => {
          if (diveSession.isVirtual) {
            diveSessionStatusUpdateManager
              .createFromVirtualSession({
                diveSessionReference: diveSession.reference,
                status: 'off',
                clubReference,
                diveCenterId,
                settingsPlanning,
              })
              .catch((err) => {
                console.log('xxx err:', err);
              });
          } else {
            diveSessionStatusUpdateManager
              .closeSession({
                diveSessionResume: diveSessionResumes.find(
                  (dsr) => dsr.reference === diveSession.reference,
                ),
              })
              .catch((err) => {
                console.log('xxx err:', err);
              });
          }
        },
      });
    }
    if (
      isEditSessionRole &&
      !(diveSession.status === 'deleted' || diveSession.status === 'cancelled')
    ) {
      hamburgerMenuItems.push({
        title: 'Annuler la session',
        description: 'Annuler la session et toutes les plongées',
        icon: AppHeroIcons.actionCancel,
        severity: 'danger',
        onClick: () => {
          if (diveSession.isVirtual) {
            diveSessionStatusUpdateManager
              .createFromVirtualSession({
                diveSessionReference: diveSession.reference,
                status: 'cancelled',
                clubReference,
                diveCenterId,
                settingsPlanning,
              })
              .catch((err) => {
                console.log('xxx err:', err);
              });
          } else {
            diveSessionStatusUpdateManager
              .cancelSessionWithConfirmDialog({
                diveSessionResume: diveSessionResumes.find(
                  (dsr) => dsr.reference === diveSession.reference,
                ),
              })
              .catch((err) => {
                console.log('xxx err:', err);
              });
          }
        },
      });
    }
    if (
      isEditSessionRole &&
      !(
        diveSession.status === 'deleted' ||
        diveSession.participants.length !== 0
      )
    ) {
      hamburgerMenuItems.push({
        title: 'Supprimer cette session',
        description: 'Supprimer définitivement cette session',
        icon: AppHeroIcons.actionRemove,
        severity: 'danger',
        onClick: async () => {
          if (diveSession.isVirtual) {
            if (
              await confirmDialog.confirmPromise({
                title: 'Supprimer une session',
                message:
                  'Êtes-vous sûr de vouloir supprimer définitivement cette session?',
                type: 'noYesDanger',
              })
            ) {
              diveSessionStatusUpdateManager
                .createFromVirtualSession({
                  diveSessionReference: diveSession.reference,
                  status: 'deleted',
                  clubReference,
                  diveCenterId,
                  settingsPlanning,
                })
                .catch((err) => {
                  console.log('xxx err:', err);
                });
            }
          } else {
            diveSessionStatusUpdateManager
              .deleteSessionWithConfirmDialog({
                diveSessionResume: diveSessionResumes.find(
                  (dsr) => dsr.reference === diveSession.reference,
                ),
              })
              .catch((err) => {
                console.log('xxx err:', err);
              });
          }
        },
      });
    }
    if (isEditSessionRole && !(diveSession.status === 'on')) {
      hamburgerMenuItems.push({
        title: 'Ouvrir la session',
        description: 'Puis inscrivez-y des participants',
        icon: AppHeroIcons.actionOpen,
        severity: 'warn',
        onClick: () => {
          diveSessionStatusUpdateManager
            .openSessionWithConfirmDialog({
              diveSessionResume: diveSessionResumes.find(
                (dsr) => dsr.reference === diveSession.reference,
              ),
            })
            .catch((err) => {
              console.log('xxx err:', err);
            });
        },
      });
    }
    if (
      isEditSessionRole &&
      !(
        diveSession.isVirtual ||
        diveSession.status !== 'on' ||
        diveSession.sessionsCount === 1
      )
    ) {
      hamburgerMenuItems.push({
        title: 'Diviser en 2 sorties simples',
        description: 'Diviser la double-blocs en 2 sorties simples',
        icon: AppIconsAction.generate,
        severity: 'success',
        onClick: async () => {
          if (
            await confirmDialog.confirmPromise({
              title: 'Diviser la double-bloc',
              message:
                'Êtes-vous sûr de vouloir découper cette sortie double-blocs en 2 sorties simple-bloc?',
              type: 'noYesDanger',
            })
          ) {
            diveSessionStatusUpdateManager
              .splitMultiSession({
                diveSessionId: diveSession._id,
              })
              .catch((err) => {
                console.log('xxx err:', err);
              });
          }
        },
      });
    }
    if (isEditSessionRole && diveSession.status !== 'deleted') {
      hamburgerMenuItems.push({
        id: 'duplicate-session',
        title: 'Dupliquer la session',
        severity: 'success',
        description: 'bateau, site, plongeurs, palanquées...',
        icon: AppHeroIcons.actionClone,
        onClick: () => {
          dialogsOld.setSessionEditorDialogState({
            isOpen: true,
            mode: 'clone-session',
            originalSessionFull: diveSession,
            diveSessionResumes,
            dailyConfigs,
          });
        },
      });
    }
    if (isEditSessionRole) {
      hamburgerMenuItems.push({
        id: 'create-session',
        title: 'Ajouter une session',
        description: 'Créer une nouvelle session',
        severity: 'success',
        icon: AppHeroIcons.actionAdd,
        onClick: () => {
          dialogsOld.setSessionEditorDialogState({
            isOpen: true,
            mode: 'create-session',
            defaultValue: {
              time: diveSession.time,
            },
            diveSessionResumes,
            dailyConfigs,
          });
        },
      });
    }

    return hamburgerMenuItems;
  }, [
    actions,
    az.communication?.action?.sendSessionMessage,
    az.download?.dailyResume,
    clubReference,
    clubSettings.communication?.session?.enableMessage,
    dailyConfigs,
    dailyExportModal,
    dailyExportsSettings,
    dialogsOld,
    dialogsState.createMessageToCustomersDialog,
    dialogsState.diveSessionEditorMassiveDialog,
    diveCenterId,
    diveSession,
    diveSessionResumes,
    hideOldSecuritySheetExport,
    isEditSecuritySheetRole,
    isEditSessionRole,
    isEditSettingsDiveCenterRole,
    settingsPlanning,
  ]);
  return hamburgerMenuItems;
}
