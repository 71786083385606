/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from '@material-ui/core';
import React from 'react';
import { RxjsFormAppTextField, RxjsFormControl } from 'src/lib/form/components/rxjs-form';
import { UserLoginFormComponentsModel } from './UserLoginFormComponentsModel.type';

export const UserLoginForm = ({
  components,
  autoFocus,
}: {
  components: UserLoginFormComponentsModel;
  autoFocus?: boolean;
}) => {

  return <Grid container spacing={2}>

    <Grid item xs={12}>
      <RxjsFormControl label="Identifiant" attrState={components['userAuthEmailLogin'].state} renderComponent={() =>
        <RxjsFormAppTextField fullWidth {...components['userAuthEmailLogin']} autoComplete="username" autoFocus={autoFocus} />
      } />
    </Grid>

    <Grid item xs={12}>
      <RxjsFormControl label="Mot de passe" attrState={components['userAuthPassword'].state} renderComponent={() =>
        <RxjsFormAppTextField fullWidth {...components['userAuthPassword']} autoComplete="current-password" type="password" />
      } />
    </Grid>

  </Grid>;

};