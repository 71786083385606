import {
  DIVE_SERVICE_PURCHASE_STATUSES,
  DiveServicePurchaseStatus,
} from '@mabadive/app-common-model';
import { diveServicePurchaseStatusFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form';

export function useDiveServicePurchaseStatusesOptions() {
  return DIVE_SERVICE_PURCHASE_STATUSES.map(
    (status: DiveServicePurchaseStatus) => {
      const option: ValueLabel<DiveServicePurchaseStatus, React.ReactNode> = {
        label: (
          <div
            className={clsx(
              'inline-block font-bold text-white py-px px-1',
              status === 'active'
                ? 'bg-status-success '
                : status === 'obsolete'
                ? 'bg-status-error'
                : 'bg-status-warn'
            )}
          >
            {diveServicePurchaseStatusFormatter
              .formatStatus(status)
              .toUpperCase()}
          </div>
        ),
        value: status,
      };
      return option;
    },
  );
}
