/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAutoFill } from 'src/business/_core/data/hooks';
import { BookingInitialPlanResumeEditFormRFH } from '../../../DiverBookingPage/forms';
import { BookingInitialPlanEditFormModel } from '../../../DiverBookingPage/forms/BookingInitialPlanEditForm/model';
import { PRO_BookingMemberFull } from '../../../DiverBookingPage/models';

export const CreateBookingInitialPlanResumePanel = ({
  form,
  bookingMembersFull,
}: {
  form: UseFormReturn<BookingInitialPlanEditFormModel, any>;
  bookingMembersFull?: PRO_BookingMemberFull[]; // edition only
}) => {
  const autoFill = useAutoFill();

  return (
    <>
      <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
        Détails réservation
      </h3>
      <div className="mt-5 mx-1 relative">
        <BookingInitialPlanResumeEditFormRFH
          form={form}
          bookingMembersFull={bookingMembersFull}
        />
      </div>
    </>
  );
};
