/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppBookletGql_Company,
  AppBookletPageGql_Company,
  EcommerceProductArticleGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAppTheme, useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import {
  AppIconsAction,
  AppIconsUI,
  EntitiesIcons,
} from '../../../../../../business/_core/modules/layout/icons';
import { AppPriceLabel } from '../../../../../../business/club/modules/_common/ui';
import { useAppCurrencyMain } from '../../../../../_components/options';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import { ProProductsPage_CategoryContentCardProductArticlesTableRowPrice } from './ProProductsPage_CategoryContentCardProductArticlesTableRowPrice';
import { ProProductsPage_ProductArticleParticipantsCount } from './ProProductsPage_ProductArticleParticipantsCount';

export const ProProductsPage_CategoryContentCardProductArticlesTableTbody = ({
  localState,
  product,
  productArticle,
  onClickProductArticle,
  className,
}: {
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
  product: EcommerceProductGql_Company;
  productArticle: EcommerceProductArticleGql_Company;
  onClickProductArticle: ({
    productBookletPage,
    productArticle,
    appBooklet,
    appBookletPage,
  }: {
    productBookletPage: AppBookletPageGql_Company;
    productArticle: EcommerceProductArticleGql_Company;
    appBooklet: AppBookletGql_Company;
    appBookletPage: AppBookletPageGql_Company;
  }) => void;
  className?: string;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();
  const theme = useAppTheme();
  const mainCurrency = useAppCurrencyMain();

  const generalSettings = clubSettings.general;

  const { state, dialogs, data, actions } = localState;

  const appBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === productArticle.bookletId);
  }, [data.booklets, productArticle.bookletId]);

  const productArticleAppBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === appBooklet?.rootPageId);
  }, [appBooklet?.rootPageId, data.bookletPages]);

  const cover = productArticleAppBookletPage?.mainContent?.cover;

  const productBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product.bookletId);
  }, [data.booklets, product.bookletId]);

  const productBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === productBooklet?.rootPageId);
  }, [data.bookletPages, productBooklet?.rootPageId]);

  const appBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === appBooklet?.rootPageId);
  }, [appBooklet?.rootPageId, data.bookletPages]);

  const pricesCount = productArticle.pricing?.prices?.length;

  const showParticipantsCount =
    productArticle?.details?.minParticipantsCount !== 1 ||
    productArticle?.details?.maxParticipantsCount !== 1;

  return (
    <tbody
      className={className}
      onClick={(e) => {
        onClickProductArticle({
          productBookletPage,
          productArticle,
          appBooklet,
          appBookletPage,
        });
      }}
    >
      <tr className={clsx('app-tr-highlight')}>
        <td className="px-2 pt-2 text-center">
          <AppIconsAction.edit className="inline-block w-6 h-6 text-app-primary" />
        </td>
        <td className="px-2 text-left w-full">
          <div
            className={clsx('text-base font-bold w-full text-left')}
            style={{ color: theme.title1.textColor }}
          >
            {cover?.title?.toUpperCase()}
          </div>
          <div
            className={clsx(
              'text-sm font-medium w-full text-left flex gap-2 flex-wrap',
            )}
            style={{ color: theme.title2.textColor }}
          >
            {cover?.subTitle && <div>{cover?.subTitle}</div>}
            <ProProductsPage_ProductArticleParticipantsCount
              className="font-normal text-app-primary"
              productArticle={productArticle}
            />
          </div>
        </td>
        <td className="px-2 pt-2 text-center">
          {productArticle.enabled ? (
            productArticle.details?.onlineBooking?.showButton ? (
              <div className="uppercase text-sm text-status-success-dark">
                <EntitiesIcons.order className="inline-block w-6 h-6 mr-1" />{' '}
                {/* Réservation en ligne */}
              </div>
            ) : (
              <div className="uppercase text-sm text-status-warn-dark">
                <EntitiesIcons.order className="inline-block w-6 h-6 mr-1" />{' '}
                {/* <EntitiesIcons.order className="inline-block w-6 h-6 mr-1" />{' '} */}
                {/* Pas de réservation en ligne */}
              </div>
            )
          ) : (
            <div className="uppercase text-sm text-status-error">
              <AppIconsUI.eyeOff className="inline-block w-6 h-6 mr-1" />{' '}
              {/* Produit désactivé */}
            </div>
          )}
        </td>
        <td className="px-2 text-right font-bold text-gray-800">
          {pricesCount === 0 ? (
            <div className="text-sm font-bold text-app-red text-center uppercase">
              Aucun tarif
            </div>
          ) : pricesCount === 1 ? (
            <AppPriceLabel
              amount={productArticle.pricing?.prices[0].unitPrice}
              mainCurrency={mainCurrency}
            />
          ) : null}
        </td>
      </tr>
      {pricesCount > 1 && (
        <>
          {productArticle.pricing?.prices.map((price, i) => (
            <ProProductsPage_CategoryContentCardProductArticlesTableRowPrice
              key={i}
              localState={localState}
              productArticle={productArticle}
              price={price}
            />
          ))}
        </>
      )}
    </tbody>
  );
};
