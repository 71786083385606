/* eslint-disable @typescript-eslint/no-unused-vars */
import { certificationFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { ClubDiversListPageLocalState } from '../useClubDiversListPageLocalState.hook';

export const ClubDiversListPageConfigBarFiltersResume = ({
  localState,
  className,
}: {
  localState: ClubDiversListPageLocalState;
  className?: string;
}) => {
  const { filters } = localState;

  const {
    searchText,
    mainCertificationReference,
    hasDiveToday,
    setHasDiveToday,
    hasDiveTomorrow,
    setHasDiveTomorrow,
  } = filters;

  return mainCertificationReference ||
    searchText?.trim()?.length !== 0 ||
    hasDiveToday ||
    hasDiveTomorrow ? (
    <div
      className={clsx(
        'flex justify-start flex-wrap gap-x-4 text-xs text-gray-400 uppercase',
        className,
      )}
    >
      {searchText?.trim()?.length !== 0 && (
        <div className={clsx('flex xs:hidden gap-1')}>
          <div className="font-bold whitespace-nowrap">Recherche:</div>
          <div className="whitespace-nowrap">{searchText?.trim()}</div>
        </div>
      )}
      {mainCertificationReference && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Certification:</div>
          {certificationFormatter
            .formatCertificationReference(mainCertificationReference, {
              format: 'name',
            })
            .toUpperCase()}
        </div>
      )}
      {hasDiveToday && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Plonge aujourd'hui</div>
        </div>
      )}
      {hasDiveTomorrow && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Plonge demain</div>
        </div>
      )}
    </div>
  ) : null;
};
