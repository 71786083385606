/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubProductPackageAttributeProductType,
  ClubProductPackageAttributesProduct,
  ClubProductPackageAttributesSalesCriteria,
  ClubProductPackageOfferView,
  PRODUCT_TYPES,
} from '@mabadive/app-common-model';
import {
  ClubProductPackageOfferViewSortByAttribute,
  jsonPatcher,
  productTypeFormatter,
} from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppFixedButtonsBar } from 'src/business/_core/modules/layout/components/AppFixedButtonsBar/AppFixedButtonsBar';
import { CollectionOrder } from 'src/business/_core/modules/layout/components/PageableTable';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { ClubOfferNameDetailsLabel } from 'src/business/club/modules/club-diver-participant/components';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { ClubOfferProductMultiEditorFormModel } from './ClubOfferProductMultiEditorFormModel.type';

export const ClubOfferProductMultiEditor = ({
  productPackageOffers,
  onOrderChanged,
  onCancel,
  onConfirm,
  className,
}: {
  productPackageOffers: ClubProductPackageOfferView[];
  onOrderChanged?: (
    order: CollectionOrder<ClubProductPackageOfferViewSortByAttribute>,
  ) => void;
  onCancel?: () => void;
  onConfirm?: (attrs: {
    updatedProductPackageOffers: AppEntityUpdatePatch[];
  }) => void;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const generalSettings = clubResume.clubSettings.general;

  const initialFormValue = useMemo(() => {
    const model: ClubOfferProductMultiEditorFormModel = {
      offers: productPackageOffers.map((o) => ({
        productType: o?.productPackage.productType,
        price: o.price,
        thirdPartyCollectPrice: o.thirdPartyCollectPrice,
        minAge: o?.productPackage.productAttributes?.minAge,
        maxAge: o?.productPackage.productAttributes?.maxAge,
        favorite: o?.productPackage?.salesCriteria?.favorite,
        comment: o?.productPackage?.comment,
      })),
    };
    return model;
  }, [productPackageOffers]);

  const { register, handleSubmit, watch, formState, control, setValue } =
    useForm<ClubOfferProductMultiEditorFormModel>({
      defaultValues: initialFormValue,
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: ClubOfferProductMultiEditorFormModel, event) => {
        const updatedProductPackageOffers = productPackageOffers.reduce(
          (acc, initialOffer, i) => {
            const finalProductAttributes: ClubProductPackageAttributesProduct =
              {
                ...initialOffer.productPackage.productAttributes,
                // includeDives
                // organizationReference
                // certificationReference
                minAge: formValue.offers[i].minAge,
                maxAge: formValue.offers[i].maxAge,
              };

            const salesCriteria: ClubProductPackageAttributesSalesCriteria = {
              ...initialOffer.productPackage.salesCriteria,
              favorite: formValue.offers[i].favorite,
            };

            const finalOffer: ClubProductPackageOfferView = {
              ...initialOffer,
              price: formValue.offers[i].price,
              thirdPartyCollectPrice:
                formValue.offers[i].thirdPartyCollectPrice,
              productPackage: {
                ...initialOffer.productPackage,
                productAttributes: finalProductAttributes,
                comment: formValue.offers[i].comment,
                salesCriteria,
              },
            };
            const offerPatchOperations = jsonPatcher.compareObjects(
              initialOffer,
              finalOffer,
              {},
            );
            if (offerPatchOperations.length) {
              const patch: AppEntityUpdatePatch = {
                pk: initialOffer._id,
                patchOperations: offerPatchOperations,
              };
              acc.push(patch);
            }
            return acc;
          },
          [] as AppEntityUpdatePatch[],
        );
        onConfirm({
          updatedProductPackageOffers,
        });
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [handleSubmit, onConfirm, productPackageOffers]);
  const user = useAppSecurityUser();
  const redirectTo = useRedirect();

  const productTypeOptions = useMemo(
    () =>
      PRODUCT_TYPES.map((productType) => {
        const option: ValueLabel<ClubProductPackageAttributeProductType> = {
          label: productTypeFormatter.format(productType).toUpperCase(),
          value: productType,
        };
        return option;
      }),
    [],
  );

  return (
    <div>
      <AppMessage
        className="mb-5"
        title={'Instruction'}
        message={
          <>
            <p>
              Sur cette page, vous pouvez modifier les principales
              caractéristiques des produits de vente, location ou services.
            </p>
            <p className="my-2">
              Pour créer de nouveaux produits ou effectuer des modifications
              avancées, veuillez nous contacter.
            </p>

            <AppButton
              className="mt-5"
              color="primary-outline-light"
              icon={AppHeroIcons.contact}
              onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
            >
              Contactez-nous
            </AppButton>
          </>
        }
      />
      <div>
        {productPackageOffers.map((productPackageOffer, i) => (
          <div className="my-4 border border-gray-400 p-4" key={`${i}`}>
            <div>
              <ClubOfferNameDetailsLabel
                className="font-bold text-app-blue"
                productPackage={productPackageOffer.productPackage}
              />
            </div>
            <div className="flex flex-wrap gap-2">
              <AppFormControlRHF_Deprecated
                control={control}
                label="Age mini"
                name={`offers.${i}.minAge` as const}
                renderComponent={(props) => (
                  <AppInputRHF {...props} type="number" />
                )}
              />
              <AppFormControlRHF_Deprecated
                control={control}
                label="Age maxi"
                name={`offers.${i}.maxAge` as const}
                renderComponent={(props) => (
                  <AppInputRHF {...props} type="number" />
                )}
              />
              <AppFormControlRHF_Deprecated
                control={control}
                label="Tarif"
                name={`offers.${i}.price` as const}
                renderComponent={(props) => (
                  <AppInputRHF {...props} type="number" />
                )}
              />
              {generalSettings?.billing?.thirdPartyCollectEnabled && (
                <AppFormControlRHF_Deprecated
                  label="Tarif (part tiers)"
                  control={control}
                  required={false}
                  name={`offers.${i}.thirdPartyCollectPrice` as const}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} type="number" fullWidth />
                  )}
                />
              )}
              <AppFormControlRHF_Deprecated
                control={control}
                label="Favori"
                name={`offers.${i}.favorite` as const}
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    className="scale-125 block ml-0.5 mt-1 mb-1"
                    {...props}
                    type="checkbox"
                  />
                )}
              />
              <AppFormControlRHF_Deprecated
                className="w-full"
                control={control}
                name={`offers.${i}.comment` as const}
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    placeholder="Commentaire"
                    type="text"
                    className="w-full"
                    multiline={true}
                    rowsMin={2}
                  />
                )}
              />
            </div>
          </div>
        ))}
      </div>
      <AppFixedButtonsBar hasChanges={formState.isDirty}>
        <AppButton
          fullWidth={true}
          icon={AppHeroIcons.actionCancel}
          color="gray-outline-light"
          size="normal"
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          Annuler
        </AppButton>
        <AppButton
          fullWidth={true}
          icon={AppHeroIcons.actionSave}
          color="primary-outline-light"
          onClick={() => {
            submitForm();
          }}
        >
          Confirmer
        </AppButton>
      </AppFixedButtonsBar>
    </div>
  );
};
