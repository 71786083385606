/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import {
  AppButton,
  AppFormAutoSubmit,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';

import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { StaffMemberEditDialogActionsBar } from './StaffMemberEditDialogActionsBar';
import { StaffMemberEditDialogState, StaffMemberEditFormModel } from './_model';
import { StaffMemberEditForm } from './form';
import {
  StaffMemberEditDialogLocalState,
  useStaffMemberEditDialogLocalState,
} from './useStaffMemberEditDialogLocalState.hook';
import { useStaffMemberEditDialogSubmit } from './useStaffMemberEditDialogSubmit.hook';

export const StaffMemberEditDialog: FC<StaffMemberEditDialogState> = (
  inputState,
) => {
  const { isOpen, initialState, onCancel, onConfirm, openDialog, closeDialog } =
    inputState;

  const { defaultValue } = initialState;

  const localState: StaffMemberEditDialogLocalState =
    useStaffMemberEditDialogLocalState({
      initialState,
    });

  const {
    state: { form },
  } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue: StaffMemberEditFormModel = watch();

  const submitForm = useStaffMemberEditDialogSubmit({
    handleSubmit,
    localState,
    inputState,
  });

  const hasChanges = form.formState.isDirty;

  return !isOpen ? null : (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={() => (
        <StaffMemberEditDialogActionsBar
          className="sm:hidden"
          onCancel={onCancel}
          submitForm={submitForm}
        />
      )}
    >
      <div className="app-p-content">
        <AppFormAutoSubmit onSubmit={submitForm}>
          <ClubSettingsSection
            className="app-card app-p-content"
            title="Moniteur"
          >
            <StaffMemberEditForm className="" localState={localState} />
          </ClubSettingsSection>
          <div className="hidden sm:block sticky -bottom-8 pt-8 app-card">
            <div className="app-p-content flex gap-4 justify-start">
              <AppButton
                size="normal"
                icon={AppIcons.action.cancel}
                tabIndex={500}
                color="gray-outline-light"
                onClick={() => {
                  onCancel();
                }}
              >
                Annuler
              </AppButton>
              <AppButton
                type="submit"
                size="normal"
                icon={AppIcons.action.save}
                color="primary-bg"
                onClick={() => {
                  submitForm();
                }}
              >
                Confirmer
              </AppButton>
            </div>
          </div>
        </AppFormAutoSubmit>
      </div>
    </AppPageContainerWithFixedBars>
  );
};
