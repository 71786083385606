/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import {
  APP_FORM_CLASSNAME,
  AppFormAutoSubmit,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiveCenterBrandCard } from 'src/pages/_components/company';
import { DiveCenterEditDialogActionsBar } from './DiveCenterEditDialogActionsBar';
import { DiveCenterEditForm } from './form';
import { DiveCenterEditDialogState, DiveCenterEditFormModel } from './model';
import {
  DiveCenterEditDialogLocalState,
  useDiveCenterEditDialogLocalState,
} from './useDiveCenterEditDialogLocalState.hook';
import { useDiveCenterEditDialogSubmit } from './useDiveCenterEditDialogSubmit';

export const DiveCenterEditDialog: FC<DiveCenterEditDialogState> = (
  inputState,
) => {
  const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] = useState(false);
  const globalClasses = useGlobalClasses();

  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const clubReference = clubResume?.reference;

  const { isOpen, initialState, onCancel, onConfirm, openDialog, closeDialog } =
    inputState;

  const { mode, defaultValue } = initialState;

  const localState: DiveCenterEditDialogLocalState =
    useDiveCenterEditDialogLocalState({
      initialState,
    });

  const { form } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue: DiveCenterEditFormModel = watch();

  const submitForm = useDiveCenterEditDialogSubmit({
    handleSubmit,
    localState,
    inputState,
  });

  const hasChanges = form.formState.isDirty;

  return !isOpen ? null : (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={() => (
        <DiveCenterEditDialogActionsBar
          onCancel={onCancel}
          submitForm={submitForm}
        />
      )}
    >
      <div className="app-p-content">
        <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
          {initialState.mode === 'edit'
            ? `Centre de plongée "${initialState.defaultValue?.diveCenter?.publicData?.brand?.name}"`
            : 'Nouveau centre de plongée'}
        </h3>

        <AppFormAutoSubmit onSubmit={submitForm}>
          <div className={clsx(APP_FORM_CLASSNAME, 'my-5')}>
            <DiveCenterEditForm form={form} initialState={initialState} />
          </div>
        </AppFormAutoSubmit>

        <div className={clsx('my-5 app-card p-4 app-card-highlight')}>
          <div className="flex justify-center xs:justify-start">
            <DiveCenterBrandCard
              className=""
              clubPublicData={formValue.clubSettings.publicData}
              clubPublicSettings={formValue.clubSettings.publicSettings}
              diveCenterPublicData={formValue.diveCenter.publicData}
              diveCenterPublicSettings={formValue.diveCenter.publicSettings}
            />
          </div>
        </div>
      </div>
    </AppPageContainerWithFixedBars>
  );
};
