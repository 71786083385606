import { ClubEvent } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { CalendarSessionWidthMode } from '../../club-planning-light/components/ClubPlanningLightSession/session-common';
import { ClubEventCard } from './ClubEventCard';

export const ClubEventCardGroup = function ({
  clubEvents,
  widthMode,
  onClick,
  className,
}: {
  clubEvents: ClubEvent[];
  widthMode: CalendarSessionWidthMode;
  className?: string;
  onClick?: (clubEvent: ClubEvent) => void;
}) {
  return (
    <div
      className={clsx(
        'border border-gray-200 bg-white flex flex-col divide-y divide-gray-200',
        widthMode === 'large' ? 'my-1' : 'mb-1',
        className,
      )}
    >
      {clubEvents.map((clubEvent, i) => (
        <ClubEventCard
          key={clubEvent._id}
          className=""
          widthMode={widthMode}
          clubEvent={clubEvent}
          onClick={onClick}
        />
      ))}
    </div>
  );
};
