import { TailwindBreakpoint } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ValueLabel } from '../../../_common/form/components/ValueLabel.model';
import {
  PlanningViewConfig,
  PlanningViewConfigPeriod,
} from '../../../club-planning/_model';
import { ClubPlanningDisplayConfigurationBarContext } from '../ClubPlanningDisplayConfigurationBarContext.type';

export const clubPlanningViewModesOptionsBuilder = {
  buildOptions,
};

function buildOptions({
  context,
  mediaSize,
  planningConfig,
}: {
  context: ClubPlanningDisplayConfigurationBarContext;
  mediaSize: TailwindBreakpoint;
  planningConfig: PlanningViewConfig;
}): ValueLabel<PlanningViewConfigPeriod, string>[] {
  if (context === 'planning-staff-page') {
    return [
      {
        value: 'week',
        label: 'Semaine',
        icon: AppHeroIcons.viewPeriodIcon,
      },
      {
        value: 'day',
        label: 'Journée',
        icon: AppHeroIcons.viewPeriodIcon,
      },
      // {
      //   value: 'session',
      //   label: 'Session',
      //   icon: AppHeroIcons.viewPeriodIcon,
      // },
    ];
  } else {
    if (context === 'planning-participants-page') {
      return [
        {
          value: 'month',
          label: 'Mois',
          icon: AppHeroIcons.viewPeriodIcon,
        },
        {
          value: 'week',
          label: 'Semaine',
          icon: AppHeroIcons.viewPeriodIcon,
        },
        {
          value: 'day',
          label: 'Journée',
          icon: AppHeroIcons.viewPeriodIcon,
        },
        {
          value: 'session',
          label: 'Session',
          icon: AppHeroIcons.viewPeriodIcon,
        },
      ];
    } else {
      return [
        // planning selector
        // {
        //   value: 'month', (month not supported yet)
        //   label: 'Mois',
        //   icon: AppHeroIcons.viewPeriodIcon,
        // },
        {
          value: 'week',
          label: 'Semaine',
          icon: AppHeroIcons.viewPeriodIcon,
        },
        {
          value: 'day',
          label: 'Journée',
          icon: AppHeroIcons.viewPeriodIcon,
        },
        {
          value: 'session',
          label: 'Session',
          icon: AppHeroIcons.viewPeriodIcon,
        },
      ];
    }
  }
}
