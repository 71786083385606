/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AppInputBooleanRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import { diveModeFormatter } from '@mabadive/app-common-services';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { useClubSettings } from '../../../../../../../business/club/data/hooks';
import { ClubSettingsServicesFormModel } from '../../../_model';

export const ClubSettingsServicesActivitiesFormTraining = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubSettings = useClubSettings();

  return (
    <ClubSettingsSection title="Formations">
      <AppFormControlRHF
        control={control}
        name="clubSettings.training.enabled"
        renderComponent={(props) => (
          <AppInputBooleanRHF
            {...props}
            type="checkbox"
            label={diveModeFormatter.formatDiveMode('training', {
              format: 'long-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
          />
        )}
      />
      <AppFormControlRHF
        control={control}
        name="clubSettings.theoreticalTraining.enabled"
        renderComponent={(props) => (
          <AppInputBooleanRHF
            {...props}
            type="checkbox"
            label={diveModeFormatter.formatDiveMode('theoretical-training', {
              format: 'long-label',
              diveModesConf: clubSettings?.ui?.diveMode,
            })}
          />
        )}
      />
    </ClubSettingsSection>
  );
};
