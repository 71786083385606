import { ClubPurchasePaymentResume } from '@mabadive/app-common-model';
import { AppDurationParserString } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import {
  PurchasePaymentGql1CollectionCriteria,
  purchasePaymentGql1GraphqlFetcher,
} from 'src/business/_core/data/store-repository';
import {
  AppFetchDataCacheStore,
  appFetchDataCacheStoreBuilder,
} from 'src/business/_core/modules/root/app-cache/AppFetchDataCacheStore';
import { useAppFetchDataListEntitiesWithCache } from 'src/business/_core/modules/root/app-cache/AppFetchDataCacheStore/useAppFetchDataListEntitiesWithCache.hook';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { PaymentsListPageFetchCriteria } from './model';

export const clubPaymentsCacheStore: AppFetchDataCacheStore<
  PurchasePaymentGql1CollectionCriteria,
  ClubPurchasePaymentResume[]
> = appFetchDataCacheStoreBuilder.buildCache({
  baseKey: 'club-payments',
  cacheValidity: '2 days',
});

export function useFetchPaymentsWithCache(
  criteria: PaymentsListPageFetchCriteria,
  options: {
    autoRefetchInterval: AppDurationParserString;
    staleInterval: AppDurationParserString;
  },
) {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const clubReference = diveCenterResume.clubReference;

  const fetchCriteria: PurchasePaymentGql1CollectionCriteria = useMemo(() => {
    return {
      ...criteria,
      clubReference,
      diveCenterId,
    };
  }, [clubReference, criteria, diveCenterId]);

  const cacheState =
    useAppFetchDataListEntitiesWithCache<ClubPurchasePaymentResume>(
      fetchCriteria,
      {
        store: clubPaymentsCacheStore,
        ...options,
        fetch: async (
          fetchCriteria,
          { cacheState, fetchType },
        ): Promise<ClubPurchasePaymentResume[]> => {
          // NOTE: maxResults ne doit pas être dans les criteria, sinon il va impacter la clé du cache

          return await purchasePaymentGql1GraphqlFetcher
            .findMany(
              {
                ...fetchCriteria,
                maxResults: 1000,
                updatedAfter:
                  fetchType === 'partial'
                    ? cacheState?.fetchResult?.dataModifiedAt
                    : undefined,
              },
              { type: 'query' },
            )
            .toPromise();
        },
      },
    );

  return cacheState;
}
