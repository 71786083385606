/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import {
  AppComboBoxMultiple,
  AppFormControlV2,
  AppInputBooleanSwitch,
} from 'src/lib/form';
import { AppInputDatePickerMaterial } from 'src/stories/components/04-form';
import { DepositsListPageLocalState } from '../useDepositsListPageLocalState';

export const DepositsListPageConfigBarExtraFilters = ({
  localState,
  className,
}: {
  localState: DepositsListPageLocalState;
  className?: string;
}) => {
  const { viewParameters, setViewParameters, filters } = localState;

  const autoFocus = useAutoFocus();
  const {
    searchText,
    setSearchText,
    depositState,
    setDepositState,
    depositStateOptions,
    purchasePaymentPending,
    setPurchasePaymentPending,
    partiallyUsed,
    setPartiallyUsed,
    depositStates,
    setDepositStates,
  } = filters;
  return (
    <div className="flex flex-col gap-2">
      <input
        className="xs:hidden mx-1 w-full hover:border-app-blue rounded px-2"
        autoFocus={autoFocus}
        autoComplete="new-password"
        placeholder="Recherche par nom, référence, montant"
        type="text"
        defaultValue={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <div
        className={clsx(
          'grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-x-2 gap-y-1',
          className,
        )}
      >
        {/* <AppFormControlV2
          className="w-full xs:col-span-2 sm:col-span-1 bg-white px-2"
          label="Statut"
          renderComponent={() => (
            <AppSingleSelect2HeadlessUI
              className="w-full"
              theme={'material-ui'}
              disableClearButton={false}
              value={depositState}
              options={depositStateOptions}
              onChange={(value) => setDepositState(value)}
            />
          )}
        /> */}

        <AppFormControlV2
          className="w-full sm:col-span-1 bg-white px-2"
          label="Statut"
          renderComponent={() => (
            <AppComboBoxMultiple
              value={depositStates}
              options={depositStateOptions}
              onChange={(value) => setDepositStates(value)}
            />
          )}
        />

        <AppFormControlV2
          className="w-full xs:col-span-2 sm:col-span-1 bg-white px-2"
          label="Paiement en attente"
          renderComponent={() => (
            <AppInputBooleanSwitch
              className="w-full"
              value={purchasePaymentPending}
              onChange={(value) => setPurchasePaymentPending(value)}
            />
          )}
        />
        <AppFormControlV2
          className="w-full xs:col-span-2 sm:col-span-1 bg-white px-2"
          label="Partiellement utilisés"
          renderComponent={() => (
            <AppInputBooleanSwitch
              className="w-full"
              value={partiallyUsed}
              onChange={(value) => setPartiallyUsed(value)}
            />
          )}
        />
        <AppFormControlV2
          className="w-full bg-white px-2"
          label="Date mini"
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-full"
              value={viewParameters.periodRange.value?.beginDate}
              onChange={(beginDate) =>
                setViewParameters({
                  ...viewParameters,
                  periodRange: {
                    ...viewParameters.periodRange,
                    value: {
                      ...viewParameters.periodRange.value,
                      beginDate,
                    },
                  },
                })
              }
            />
          )}
        />
        <AppFormControlV2
          className="w-full bg-white px-2"
          label="Date maxi"
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-full"
              value={viewParameters.periodRange.value?.endDate}
              onChange={(endDate) =>
                setViewParameters({
                  ...viewParameters,
                  periodRange: {
                    ...viewParameters.periodRange,
                    value: {
                      ...viewParameters.periodRange.value,
                      endDate,
                    },
                  },
                })
              }
            />
          )}
        />
      </div>
    </div>
  );
};
