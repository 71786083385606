/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import { AppIconsSocialMedia } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useClubSettingsMessageTargetsCustomer,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

export const ClubSettingsCommunicationMainViewPanel_Customers = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();

  const msg = useClubSettingsMessageTargetsCustomer({
    context: 'customer',
  });

  return (
    <ClubSettingsSection
      title="Fiche client"
      comment="Affiche des
          boutons permettant de contacter rapidement le client depuis sa fiche."
    >
      {clubSettings.communication?.customer?.showMessageButtons ? (
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          {msg.email.channelEnabled && (
            <AppFeatureBadge
              enabled={
                clubSettings.communication?.customer?.emailButton?.enabled
              }
              label="Email"
              label2="(manuel)"
              icon={
                <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
              }
            />
          )}
          {msg.phone.channelEnabled && (
            <AppFeatureBadge
              enabled={
                clubSettings.communication?.customer?.phoneButton?.enabled
              }
              label="Téléphone"
              label2="(appel)"
              icon={
                <AppIconsSocialMedia.phone className="h-6 w-6 text-app-sms" />
              }
            />
          )}
          {msg.whatsApp.channelEnabled && (
            <AppFeatureBadge
              enabled={
                clubSettings.communication?.customer?.whatsAppButton?.enabled
              }
              label="WhatsApp"
              label2="(manuel)"
              icon={
                <AppIconsSocialMedia.whatsapp className="h-5 w-5 text-app-whatsapp" />
              }
            />
          )}
        </div>
      ) : (
        <AppFeatureBadge enabled={false} label="DÉSACTIVÉ" />
      )}
    </ClubSettingsSection>
  );
};
