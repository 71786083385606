/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingStatus,
  ClubParticipant,
  ParticipantActionDescription,
  ParticipantBookingStatus,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  dateService,
  jsonPatcher,
} from '@mabadive/app-common-services';
import React, { PropsWithChildren, useCallback } from 'react';
import { AppPageContainer } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { ClubDialogsProvider, ClubDialogsStateOld } from 'src/pages/_dialogs';
import { ClubPlanningSessionDialog } from '../../../club-planning-light/club-planning-session-dialog/ClubPlanningSessionDialog';
import { ClubPlanningSessionSelectorDialog } from '../../../club-planning-light/club-planning-session-selector-dialog/ClubPlanningSessionSelectorDialog';
import { BookingGroupAndJourneyEditDialog } from '../CreateBookingPages';
import { bookingUpdateStateBuilder } from './bookingUpdateStateBuilder.service';
import { BookingCustomerConfigEditDialog } from './components/BookingCustomerConfigEditDialog';
import { BookingMassiveEditorDialog } from './components/BookingMassiveEditorDialog';
import { BookingParticipantEditorDialog } from './components/BookingParticipantDialog';
import { BookingPageMemberCreatorDialog } from './components/ClubDiverSelectorDialog';
import { ClubDiverSelectorDialogUpdateState } from './components/ClubDiverSelectorDialog/model';
import { DiverPurchaseOtherEditorDialog } from './components/DiverPurchaseOtherEditorDialog';
import { DiverPurchasePaymentEditorDialog } from './components/DiverPurchasePaymentEditorDialog';
import { DiverPurchasePlanEditorDialog } from './components/DiverPurchasePlanEditorDialog';
import { DiverPurchaseTrainingEditorDialog } from './components/DiverPurchaseTrainingEditorDialog';
import {
  BookingParticipantEditorParticipant,
  BookingResumeParticipantForSession,
  DiverBookingPageAggregatedData,
  DiverBookingPageUpdateState,
  DiverPurchaseCommonEditorDialogResult,
} from './models';
import {
  diverBookingPageClientUpdator,
  diverBookingPageUpdateStateManager,
} from './services';
import { DiverBookingPageActions } from './useDiverBookingPageActions.hook';
import {
  DiverBookingPageGlobalState,
  DiverBookingPageSetUpdateStateFn,
} from './useDiverBookingPageGlobalState.hook';

export const DiverBookingPageDialogsProvider = ({
  globalState,
  dialogs: dialogsOld,
  actions,
  children,
}: PropsWithChildren<{
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}>) => {
  const { aggregatedData, updateState, setUpdateState, dialogsState } =
    globalState;

  const {
    clubPlanningSessionSelectorState,
    diverSelectorDialogState,
    isAnyFakeDialogOpen,
    bookingParticipantEditorState,
    planEditorDialogState,
    purchaseOtherEditorDialogState,
    setPurchaseOtherEditorDialogState,
    sessionDialogState,
    setClubPlanningSessionSelectorState,
    setDiverSelectorDialogState,
    setBookingParticipantEditorState,
    setPlanEditorDialogState,
    paymentEditorDialogState,
    setPaymentEditorDialogState,
    setSessionDialogState,
    setTrainingEditorDialogState,
    trainingEditorDialogState,
    sessionEditorDialogState,
    setSessionEditorDialogState,
    bookingGroupAndJourneyEditDialogState,
    setBookingGroupAndJourneyEditDialogState,
    bookingMassiveEditorDialogState,
    setBookingMassiveEditorDialogState,
    bookingCustomerConfigEditDialogState,
    setBookingCustomerConfigEditDialogState,
  } = dialogsOld;

  const {
    addDiversIds,
    createBookingSessions,
    createPurchasePackage,
    updateDiverAndParticipant,
    deletePurchasePackage,
    updateProductPurchase,
    updatePurchasePayment,
    createPurchasePayment,
  } = actions;

  const createOrUpdatePurchasePackage = useCallback(
    ({
      updatedClubPurchasePackage,
      updatedProducts,
      newPurchasePackage,
      deletedPurchasePackageId,
    }: DiverPurchaseCommonEditorDialogResult) => {
      if (newPurchasePackage) {
        createPurchasePackage({ newPurchasePackage, updatedProducts });
      } else if (deletedPurchasePackageId) {
        const paymentsPackagesDetailsToFix =
          globalState.aggregatedData.paymentsPackagesDetails.filter(
            (x) => x.purchasePackageId === deletedPurchasePackageId,
          );

        deletePurchasePackage({
          deletedPurchasePackageId,
          updatedProducts,
          paymentsPackagesDetailsToFix,
        });
      } else {
        updateProductPurchase({
          updatedClubPurchasePackage,
          updatedProducts,
        });
      }
    },
    [
      createPurchasePackage,
      deletePurchasePackage,
      globalState.aggregatedData.paymentsPackagesDetails,
      updateProductPurchase,
    ],
  );

  function deleteParticipant(participant: BookingParticipantEditorParticipant) {
    setUpdateState(
      diverBookingPageUpdateStateManager.deleteParticipantFromState({
        updateState,
        bookingProductId: participant.bookingProduct?._id,
        clubParticipantId: participant.clubParticipant?._id,
        bookingSessionParticipantId:
          participant?.bookingSessionParticipant?._id,
      }),
      {
        action: 'deleteParticipant',
        meta: {
          bookingProductId: participant.bookingProduct?._id,
          clubParticipantId: participant.clubParticipant?._id,
          bookingSessionParticipantId:
            participant?.bookingSessionParticipant?._id,
        },
      },
    );
  }

  return (
    <>
      {dialogsState.isAnyDialogOpen && (
        <ClubDialogsProvider dialogsState={dialogsState}>
          {/* DIALOGS : nouveau (ne pas encapsuler l'un dans l'autre, sinon on a 2 divs imbriqués qui font bugger le dialog de paiement par exemple (il ne scroll plus)) */}
        </ClubDialogsProvider>
      )}
      {/* DIALOGS : anciens système */}
      {bookingGroupAndJourneyEditDialogState.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className="bg-white relative overflow-y-auto"
        >
          <BookingGroupAndJourneyEditDialog
            state={bookingGroupAndJourneyEditDialogState}
            setState={setBookingGroupAndJourneyEditDialogState}
            onConfirm={async (
              patch: AppEntityUpdatePatch,
              specialStatusUpdate: BookingStatus,
            ) => {
              await onConfimEditBookingJourneyAndParticipants({
                updateState,
                patch,
                specialStatusUpdate,
                aggregatedData,
                setUpdateState,
              });
            }}
          />
        </AppPageContainer>
      )}
      {bookingCustomerConfigEditDialogState.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className="bg-white relative overflow-y-auto"
        >
          <BookingCustomerConfigEditDialog
            state={bookingCustomerConfigEditDialogState}
            setState={setBookingCustomerConfigEditDialogState}
            submitChanges={async (
              patch: AppEntityUpdatePatch,
              { persistNow },
            ) => {
              const bookingsChanges = changeDescriptorManager.updateOne(patch, {
                changeDescriptors: updateState.bookingsChanges,
              });
              const localUpdateState = {
                ...updateState,
                bookingsChanges,
              };
              if (persistNow) {
                const bookingIdsToClean = aggregatedData.bookings
                  .map((x) => x._id)
                  .filter((x) => !!x);

                await diverBookingPageClientUpdator.persistChanges(
                  localUpdateState,
                  {
                    logContext: 'edit diver booking 1',
                    bookingIdsToClean,
                  },
                );
                const emptyUpdateState =
                  bookingUpdateStateBuilder.createEmptyUpdateState({
                    hasChanges: false,
                  });
                setUpdateState(emptyUpdateState, {
                  action: 'Booking page: reset after persist (1)',
                  meta: {},
                  resetSteps: true,
                });
              } else {
                setUpdateState(localUpdateState, {
                  action: 'Booking page: BookingCustomerConfigEditDialog',
                  meta: {},
                });
              }
            }}
          />
        </AppPageContainer>
      )}
      {trainingEditorDialogState.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className="bg-white relative overflow-y-auto"
        >
          <DiverPurchaseTrainingEditorDialog
            state={trainingEditorDialogState}
            setState={setTrainingEditorDialogState}
            onConfirm={(result) => {
              createOrUpdatePurchasePackage(result);
              setTrainingEditorDialogState({
                isOpen: false,
              });
            }}
          />
        </AppPageContainer>
      )}
      {planEditorDialogState.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className="bg-white relative overflow-y-auto"
        >
          <DiverPurchasePlanEditorDialog
            state={planEditorDialogState}
            setState={setPlanEditorDialogState}
            onConfirm={(result) => {
              createOrUpdatePurchasePackage(result);
              setPlanEditorDialogState({
                isOpen: false,
              });
            }}
          />
        </AppPageContainer>
      )}
      {purchaseOtherEditorDialogState.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className="bg-white relative overflow-y-auto"
        >
          <DiverPurchaseOtherEditorDialog
            state={purchaseOtherEditorDialogState}
            setState={setPurchaseOtherEditorDialogState}
            onConfirm={(result) => {
              createOrUpdatePurchasePackage(result);
              setPurchaseOtherEditorDialogState({
                isOpen: false,
              });
            }}
          />
        </AppPageContainer>
      )}
      {paymentEditorDialogState.isOpen && (
        // <AppPageContainer id="dialog-scrollable-container" className="bg-white relative overflow-y-auto">
        <DiverPurchasePaymentEditorDialog
          state={paymentEditorDialogState}
          setState={setPaymentEditorDialogState}
          onConfirm={(props) => {
            if (props?.updatedClubPurchasePayment) {
              updatePurchasePayment(props);
            } else if (props?.newPurchasePayment) {
              createPurchasePayment(props);
            } else if (props?.deletedPurchasePaymentId) {
              // TODO not supported yet
              // deletePurchasePackage({ deletedPurchasePackageId });
            }
            setPaymentEditorDialogState({
              isOpen: false,
            });
          }}
        />
      )}
      {bookingParticipantEditorState.isOpen && !dialogsState.isAnyDialogOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className="bg-white relative overflow-y-hidden"
        >
          <BookingParticipantEditorDialog
            dialogsState={dialogsState}
            state={bookingParticipantEditorState}
            onClose={() => setBookingParticipantEditorState({ isOpen: false })}
            onConfirm={(result) => {
              const bookingResume =
                globalState.aggregatedData.bookingResumesLoaded.find(
                  (x) => x.booking?._id === result.bookingId,
                );
              const bookingParticipants: BookingResumeParticipantForSession[] =
                bookingResume.bookingParticipantsFull.map(
                  (bookingParticipantFull) => {
                    const bookingMemberFull =
                      bookingResume.bookingMembersFull.find(
                        (x) =>
                          x.diver?._id === bookingParticipantFull?.diver?._id,
                      );
                    const p: BookingResumeParticipantForSession = {
                      bookingMemberFull,
                      bookingParticipantFull,
                      bookingParticipantFullSameBooking: bookingParticipantFull,
                      bookingParticipantFullAnyBooking: bookingParticipantFull,
                    };
                    return p;
                  },
                );
              updateDiverAndParticipant({
                result,
                bookingParticipants,
                applyDefaultConfigChanges:
                  bookingParticipantEditorState.mode === 'edit-diver', // on n'applique pas les paramètres si on édite directement une plongée
                logContext: `update participant from dialog (mode=${bookingParticipantEditorState.mode}`,
              });
            }}
            onDelete={(participant) => deleteParticipant(participant)}
          />
        </AppPageContainer>
      )}
      {sessionDialogState.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className="bg-white relative overflow-y-auto"
        >
          <ClubPlanningSessionDialog
            state={sessionDialogState}
            setState={setSessionDialogState}
            hasChangesInProgress={updateState.hasChanges}
          />
        </AppPageContainer>
      )}
      {diverSelectorDialogState.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className="bg-white relative overflow-y-hidden"
        >
          <BookingPageMemberCreatorDialog
            dialogsState={dialogsState}
            state={diverSelectorDialogState}
            setState={setDiverSelectorDialogState}
            onConfirm={(
              diverSelectorUpdateState: ClubDiverSelectorDialogUpdateState,
            ) => {
              if (diverSelectorUpdateState.hasChanges) {
                let updateStateLocal = {
                  ...updateState,
                };
                if (diverSelectorUpdateState.diversChanges.length) {
                  updateStateLocal.diversChanges = [
                    ...updateStateLocal.diversChanges,
                    ...diverSelectorUpdateState.diversChanges,
                  ];
                }
                if (diverSelectorUpdateState.bookingMembersChanges.length) {
                  updateStateLocal.bookingMembersChanges = [
                    ...updateStateLocal.bookingMembersChanges,
                    ...diverSelectorUpdateState.bookingMembersChanges,
                  ];
                }
                setUpdateState(updateStateLocal, {
                  action: 'BookingPageMemberCreatorDialog onConfirm',
                  meta: {},
                });
              }

              const newMembersDiversIds: {
                diverId: string;
                isNewDiver: boolean;
              }[] = diverSelectorUpdateState.newBookingMembers.map((x) => ({
                isNewDiver: x.isNewDiver,
                diverId: x.diverId,
              }));

              if (newMembersDiversIds.length) {
                // il peut s'agir d'un plongeur existant ou d'un nouveau plongeur : il faut l'ajouter, sinon ses prestations n'apparaissent pas dans la facturation
                addDiversIds(newMembersDiversIds);
              }
              setDiverSelectorDialogState({
                isOpen: false,
              });
            }}
          />
        </AppPageContainer>
      )}
      {!dialogsState.appDocEditDialog.isOpen &&
        bookingMassiveEditorDialogState.isOpen && (
          <AppPageContainer
            id="dialog-scrollable-container"
            className="bg-white relative overflow-y-hidden"
          >
            <BookingMassiveEditorDialog
              dialogsState={dialogsState}
              state={bookingMassiveEditorDialogState}
              setState={setBookingMassiveEditorDialogState}
              onConfirm={(updateState) => {
                setUpdateState(updateState, {
                  action: 'BookingMassiveEditorDialog confirm',
                  meta: {},
                });
                setBookingMassiveEditorDialogState({
                  isOpen: false,
                });
              }}
            />
          </AppPageContainer>
        )}
      {clubPlanningSessionSelectorState.isOpen && (
        <AppPageContainer
          id="dialog-scrollable-container"
          className="bg-white relative overflow-y-hidden"
        >
          <ClubPlanningSessionSelectorDialog
            defaultFocusDate={
              clubPlanningSessionSelectorState?.context?.defaultFocusDate ??
              globalState.sessionSelectorDefaultFocusDate
            }
            setDefaultFocusDate={globalState.setSessionSelectorDefaultFocusDate}
            state={clubPlanningSessionSelectorState}
            onClose={() =>
              setClubPlanningSessionSelectorState({
                isOpen: false,
              })
            }
            onSelectSessions={(sessions, context) => {
              const { newBookingSessions, updateState } = createBookingSessions(
                sessions,
                context,
              );
              actions.onBookingSessionsCreated({
                bookingId: context.bookingId,
                newBookingSessions,
                updateState,
              });
            }}
          />
        </AppPageContainer>
      )}
      <div
        className={`h-full w-full ${
          isAnyFakeDialogOpen || dialogsState.isAnyDialogOpen ? 'hidden' : ''
        }`}
      >
        {children}
      </div>
    </>
  );
};

async function onConfimEditBookingJourneyAndParticipants({
  updateState,
  patch,
  specialStatusUpdate,
  aggregatedData,
  setUpdateState,
}: {
  updateState: DiverBookingPageUpdateState;
  patch: AppEntityUpdatePatch;
  specialStatusUpdate: string;
  aggregatedData: DiverBookingPageAggregatedData;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
}) {
  const localUpdateState = {
    ...updateState,
  };
  const bookingsChanges = changeDescriptorManager.updateOne(patch, {
    changeDescriptors: localUpdateState.bookingsChanges,
  });
  if (specialStatusUpdate === 'cancelled') {
    // cancel all future participants
    const patches = aggregatedData.clubParticipants
      .filter((p) => {
        if (p.bookingState?.value === 'cancelled') {
          return false;
        }
        const diveSession = aggregatedData.diveSessions.find(
          (x) => x.reference === p.diveSessionReference,
        );
        if (!diveSession) {
          return false;
        }
        return dateService.isBefore(new Date(), diveSession.time);
      })
      .map((p) => {
        const bookingState: ParticipantActionDescription<ParticipantBookingStatus> =
          {
            date: new Date(),
            value: 'cancelled',
            user: 'diver',
            comment: 'réservation annulée',
          };

        const finalParticipant: ClubParticipant = {
          ...p,
          bookingState,
          bookingHistory: p.bookingHistory.concat([bookingState]),
        };
        const patchOperations = jsonPatcher.compareObjects<ClubParticipant>(
          p,
          finalParticipant,
          {
            replaceDeleteByNullValue: true,
          },
        );
        const patch = {
          pk: p._id,
          patchOperations,
        };
        return patch;
      });

    if (patches.length) {
      if (
        await confirmDialog.confirmPromise({
          title: 'Annuler la réservation',
          message:
            'Souhaitez-vous annuler toutes les plongées à venir de cette réservation?',
          type: 'noYesDanger',
        })
      ) {
        localUpdateState.clubParticipantsChanges =
          changeDescriptorManager.updateMany(patches, {
            changeDescriptors: localUpdateState.clubParticipantsChanges,
          });
      }
    }
  } else if (specialStatusUpdate === 'confirmed') {
    // confirm all future participants
    const patches = aggregatedData.clubParticipants
      .filter((p) => {
        if (p.bookingState?.value === 'confirmed') {
          return false;
        }
        const diveSession = aggregatedData.diveSessions.find(
          (x) => x.reference === p.diveSessionReference,
        );
        if (!diveSession) {
          return false;
        }
        return dateService.isBefore(new Date(), diveSession.time);
      })
      .map((p) => {
        const bookingState: ParticipantActionDescription<ParticipantBookingStatus> =
          {
            date: new Date(),
            value: 'confirmed',
            user: 'club',
            comment: 'réservation confirmée',
          };

        const finalParticipant: ClubParticipant = {
          ...p,
          bookingState,
          bookingHistory: p.bookingHistory.concat([bookingState]),
        };
        const patchOperations = jsonPatcher.compareObjects<ClubParticipant>(
          p,
          finalParticipant,
          {
            replaceDeleteByNullValue: true,
          },
        );
        const patch = {
          pk: p._id,
          patchOperations,
        };
        return patch;
      });

    if (patches.length) {
      if (
        await confirmDialog.confirmPromise({
          title: 'Confirmer la réservation',
          message:
            'Souhaitez-vous confirmer toutes les plongées à venir de cette réservation?',
          type: 'noYesDanger',
        })
      ) {
        localUpdateState.clubParticipantsChanges =
          changeDescriptorManager.updateMany(patches, {
            changeDescriptors: localUpdateState.clubParticipantsChanges,
          });
      }
    }
  }
  setUpdateState(
    {
      ...localUpdateState,
      bookingsChanges,
    },
    {
      action: 'onConfimEditBookingJourneyAndParticipants',
      meta: {
        specialStatusUpdate,
      },
    },
  );
}
