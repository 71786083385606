/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export const ClubSettingsSectionTitle = ({
  children,
}: {
  children?: React.ReactNode | React.ReactNode[];
}) => {
  return !children ? null : (
    <h2 className="text-left">
      <div className="uppercase text-base border border-gray-200 font-bold bg-gray-100 text-gray-600 py-0.5 px-1">
        {children}
      </div>
    </h2>
  );
};
