import { ClubDiver, DiveSession } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  BillingTabDiveSessionBillingResumePurchaseParticipant,
  PRO_BookingParticipantFull,
} from '../../models';
import { DiverBillingSessionsTableParticipantCell } from './DiverBillingSessionsTableParticipantCell';
import { DiverBillingSessionsTableParticipantCellEmpty } from './DiverBillingSessionsTableParticipantCellEmpty';
import { DiverBillingSessionsTableSessionHeader } from './DiverBillingSessionsTableSessionHeader';
import { OnClickAssignProductFn } from './OnClickAssignProductFn.type';

export function DiverBillingSessionsTableDay({
  divers,
  displayAssignButton,
  diveSession,
  purchaseParticipants: purchaseParticipantsInput,
  onClickSession,
  onClickParticipant,
  onClickAssignProducts,
  displayLastName,
  className,
}: {
  divers: ClubDiver[];
  displayAssignButton: boolean;
  diveSession: DiveSession;
  purchaseParticipants: BillingTabDiveSessionBillingResumePurchaseParticipant[];
  displayLastName?: boolean;
  onClickSession?: (diveSession: DiveSession) => void;
  onClickParticipant?: (props: {
    bookingParticipantFull: PRO_BookingParticipantFull;
  }) => void;
  onClickAssignProducts: OnClickAssignProductFn;
  className?: string;
}) {
  const isPast = useMemo(
    () =>
      dateService.isPastUTC(diveSession.time) &&
      !dateService.isTodayUTC(diveSession.time),
    [diveSession.time],
  );

  const theme = useMemo(() => (isPast ? 'light' : 'normal'), [isPast]);

  const purchaseParticipants: BillingTabDiveSessionBillingResumePurchaseParticipant[] =
    useMemo(() => {
      return divers.map((diver) => {
        const purchaseParticipant = purchaseParticipantsInput.find(
          (x) => x.participant.bookingMemberFull.diver._id === diver._id,
        );
        return purchaseParticipant;
      });
    }, [divers, purchaseParticipantsInput]);

  return (
    <div className={`w-24 px-1 py-0 ${className ?? ''}`}>
      <DiverBillingSessionsTableSessionHeader
        className="mb-1"
        theme={theme}
        diveSession={diveSession}
        onClick={onClickSession ? () => onClickSession(diveSession) : undefined}
      />
      {purchaseParticipants.map((purchaseParticipant, k) => {
        if (!purchaseParticipant) {
          return (
            <DiverBillingSessionsTableParticipantCellEmpty
              key={k}
              displayName={divers.length > 1}
              displayAssignButton={displayAssignButton}
            />
          );
        }
        const { bookingProduct, isAssociatedToPurchase, participant } =
          purchaseParticipant;
        const { style, bookingMemberFull, bookingParticipantFull } =
          participant;

        return (
          <DiverBillingSessionsTableParticipantCell
            key={k}
            theme={theme}
            onClickAssignProducts={onClickAssignProducts}
            displayAssignButton={displayAssignButton}
            purchaseParticipant={purchaseParticipant}
            displayName={divers.length > 1}
            displayLastName={displayLastName}
            onClick={
              onClickParticipant
                ? () =>
                    onClickParticipant({
                      bookingParticipantFull,
                    })
                : undefined
            }
          />
        );
      })}
    </div>
  );
}
