import { Booking } from '@mabadive/app-common-model';
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AgencyLabel,
  BookingGroupLabel,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import {
  DiverBookingPageAggregatedData,
  PRO_BookingMemberFull,
  PRO_BookingResume,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { proBookingSorter } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/services';
import { DiveCenterProBadge } from '../company';

export function useBookingOptions({
  aggregatedData,
  onlyActive,
  initialBookingId,
  diveCenterId,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  onlyActive: boolean;
  initialBookingId: string; // permet d'ajouter le booking dans la liste, même si ne correspond pas aux critères du filtre
  diveCenterId: string;
}): ValueLabel<string, React.ReactNode>[] {
  const bookingResumesLoaded: PRO_BookingResume[] =
    aggregatedData?.bookingResumesLoaded;

  const bookingVisibleIds = aggregatedData.bookingResumesVisible.map(
    (b) => b.booking._id,
  );

  return useMemo(() => {
    const bookingsFiltered = (bookingResumesLoaded ?? [])?.filter(
      (bookingResume) => {
        const b = bookingResume?.booking;
        if (initialBookingId === b._id) {
          return true; // on conserve toujours la valeur initiale dans la liste
        }
        if (!bookingVisibleIds?.includes(b._id)) {
          return false; // booking invisible
        }
        if (onlyActive && !b.active) {
          return false; // booking inactive
        }
        // const visibleDiversIds = aggregatedData.divers.map((x) => x._id);
        // if (
        //   !b.bookingMembers.find((x) => visibleDiversIds.includes(x.diverId))
        // ) {
        //   return false; // booking invisible
        // }
        return true;
      },
    );
    const sortedBookings = proBookingSorter.sortProBookingResumes(
      bookingsFiltered,
      {
        diveCenterId,
        focusDiverId: aggregatedData.focus?.clubDiver?._id,
      },
    );
    return sortedBookings.map((b) => {
      const booking = b.booking;
      const bookingMembersFull = b.bookingMembersFull;
      const option: ValueLabel<string, React.ReactNode> = {
        label: (
          <BookingOptionCard
            booking={booking}
            bookingMembersFull={bookingMembersFull}
          />
        ),
        value: booking._id,
      };
      return option;
    });
  }, [
    aggregatedData.focus?.clubDiver?._id,
    bookingResumesLoaded,
    bookingVisibleIds,
    diveCenterId,
    initialBookingId,
    onlyActive,
  ]);
}

export const BookingOptionCard = ({
  booking: b,
  bookingMembersFull: bm,
}: {
  booking: Pick<
    Booking,
    | '_id'
    | 'isSharedBooking'
    | 'diveCenterId'
    | 'bookingDate'
    | 'bookingGroup'
    | 'details'
    | 'bookingContactDiverId'
  >;
  bookingMembersFull?: PRO_BookingMemberFull[];
}) => {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const bookingDiveCenter = useMemo(() => {
    return b.isSharedBooking
      ? undefined
      : clubResume.diveCenters.find((dc) => dc._id === b.diveCenterId);
  }, [b, clubResume.diveCenters]);

  const bookingContactMember = (bm ?? []).find(
    (x) => x.diver?._id === b.bookingContactDiverId,
  );

  return (
    // NOTE: pas de bg sur l'élément root, sinon c'est moche dans AppSingleSelect2HeadlessUI
    <div className="flex flex-col gap-x-2 gap-y-1">
      <div className="flex gap-2 flex-nowrap items-start">
        <div>{dateService.formatUTC(b.bookingDate, 'DD/MM/YYYY HH:mm')}</div>

        {bookingContactMember && (
          <div>{nameFormatter.formatFullName(bookingContactMember?.diver)}</div>
        )}
        {bm?.length > 1 && <div>{bm.length} p.</div>}
      </div>
      {(isMultiDiveCenters || b.bookingGroup || b.details?.agencyId) && (
        <div className="flex gap-2 flex-nowrap items-start">
          {isMultiDiveCenters && (
            <DiveCenterProBadge
              className="my-px"
              diveCenter={bookingDiveCenter}
            />
          )}
          <BookingGroupLabel bookingGroup={b.bookingGroup} />

          <AgencyLabel className="text-xs" agencyId={b.details?.agencyId} />
        </div>
      )}
    </div>
  );
};
