/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubProductPackageOfferView } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubOfferNameDetailsLabel } from 'src/business/club/modules/club-diver-participant/components';
import { AppInputBooleanSwitchRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { ClubOfferTrainingMultiEditorFormModel } from './ClubOfferTrainingMultiEditorFormModel.type';

export const ClubOfferTrainingMultiEditorRow = ({
  form,
  offerIndex: i,
  productPackageOffer,
  className,
}: {
  form: UseFormReturn<ClubOfferTrainingMultiEditorFormModel, object>;
  offerIndex: number;
  productPackageOffer: ClubProductPackageOfferView;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const generalSettings = clubResume.clubSettings.general;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const [divesCountInfinite, divesCount] = useWatch({
    control,
    name: [`offers.${i}.divesCountInfinite` as any, `offers.${i}.divesCount`],
  });

  return (
    <div className={className}>
      <div>
        <ClubOfferNameDetailsLabel
          className="font-bold text-app-blue"
          productPackage={productPackageOffer.productPackage}
        />
        {
          productPackageOffer.productPackage.productAttributes
            .defaultOrganizationReference
        }
      </div>
      <div className="flex flex-wrap gap-2">
        <AppFormControlRHF_Deprecated
          className={clsx(divesCountInfinite && 'invisible')}
          control={control}
          label="Nombre de plongées"
          name={`offers.${i}.divesCount` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          className="w-24"
          control={control}
          label="PASS illimité"
          name={`offers.${i}.divesCountInfinite` as any}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        <AppFormControlRHF_Deprecated
          control={control}
          label="Nombre de cours théoriques"
          name={`offers.${i}.theoricalClassesCounts` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          control={control}
          label="Age mini"
          name={`offers.${i}.minAge` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          control={control}
          label="Age maxi"
          name={`offers.${i}.maxAge` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          control={control}
          label="Profondeur mini"
          name={`offers.${i}.minDepth` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          control={control}
          label="Profondeur maxi"
          name={`offers.${i}.maxDepth` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          control={control}
          label="Tarif"
          name={`offers.${i}.price` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        {generalSettings?.billing?.thirdPartyCollectEnabled && (
          <AppFormControlRHF_Deprecated
            control={control}
            label="Tarif (part tiers)"
            name={`offers.${i}.thirdPartyCollectPrice` as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} type="number" />
            )}
          />
        )}
        <AppFormControlRHF_Deprecated
          className="w-24"
          control={control}
          label="Validité (jours)"
          name={`offers.${i}.validityPeriodInDays` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          control={control}
          name={`offers.${i}.comment` as any}
          renderComponent={(props) => (
            <AppInputRHF
              {...props}
              placeholder="Commentaire"
              type="text"
              className="w-full"
              multiline={true}
              rowsMin={2}
            />
          )}
        />
      </div>
    </div>
  );
};
