import {
  BookingDepositResume,
  ClubDiver,
  ClubDiverFull,
  DiveSession,
  EditionBookingResume,
  EditionBookingResumeProduct,
  PurchaseResume,
} from '@mabadive/app-common-model';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { MQueryDescription, graphqlClient } from 'src/_common-browser';
import {
  LoadableContent,
  reactQueryToLoadableConverter,
} from 'src/business/_core/data/app-loading';
import { PurchasePaymentGraphqlQueryResult } from 'src/business/_core/data/store-repository/ecommerce/model/PurchasePaymentGraphqlQueryResult.type';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiverBookingPageLoadedContent } from '../../models';
import { diverBookingPageLoadedContentBuilder } from './diverBookingPageLoadedContentBuilder.service';
import { diverBookingPageLoadedQueryBuilder } from './diverBookingPageLoadedQueryBuilder.service';
import { useDiverBookingPageClubDiverAttachedData } from './useDiverBookingPageClubDiverAttachedData.hook';
import {
  DiverBookingPageAdditionalDataToLoad,
  DiverBookingPageClubDiverLinkedData,
} from './useDiverBookingPageClubDiverLinkedData.hook';
import { useDiverBookingPageLoadedContentMain } from './useDiverBookingPageLoadedContentMain.hook';

export function useDiverBookingPageLoadedContent({
  mainDiverFull,
  focusDiveSession,
  linkedData,
  additionnalDataToLoad,
}: {
  mainDiverFull: ClubDiverFull;
  focusDiveSession?: DiveSession;
  linkedData: DiverBookingPageClubDiverLinkedData;
  additionnalDataToLoad: DiverBookingPageAdditionalDataToLoad;
}): {
  content: LoadableContent<DiverBookingPageLoadedContent>;
  refetchMessages: () => Promise<any>;
  refetchMain: () => Promise<any>;
  refetchAdditional: () => Promise<any>;
} {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume?.clubReference;

  const loadableContentMain = useDiverBookingPageLoadedContentMain({
    linkedData,
    mainDiverId: mainDiverFull?._id,
  });

  const {
    content,
    refetch: refetchMain,
    ...loadableContent
  } = loadableContentMain;
  const bookingResumesMain = content?.bookingResumes;
  const bookingProductsResumes = content?.bookingProductsResumes;
  const purchaseResumes = content?.purchaseResumes;
  const bookingDepositsResumes = content?.bookingDepositsResumes;
  const purchasePayments = content?.purchasePayments;

  const loadableContentAdditional = useLoadAdditional({
    linkedData,
    additionnalDataToLoad,
  });

  const bookingResumes: EditionBookingResume[] = useMemo(
    () =>
      bookingResumesMain
        ? loadableContentAdditional?.content?.bookingResumes?.length > 0
          ? bookingResumesMain.concat(
              loadableContentAdditional?.content?.bookingResumes,
            )
          : bookingResumesMain
        : undefined,
    [bookingResumesMain, loadableContentAdditional?.content?.bookingResumes],
  );

  // loadableContentAdditional.

  const messagesFetchResult = useDiverBookingPageClubDiverAttachedData({
    diversIds: linkedData?.diverIdsActiveLink ?? [],
  });

  const diverAttachedData = messagesFetchResult.data;

  // const { content: diveSessionResume, ...loadableContent1 } =
  //   useLoadableEditionBookingDiveSessionResumeFull({
  //     diveSessionReference,
  //   });

  // chargement des products + participants
  // const { content: bookingProductsResumes, ...loadableContent3 } =
  //   useLoadableBookingEditionBookingProducts({
  //     linkedData,
  //   });

  // chargement des produits facturés
  // const purchaseResumesCriteria: PurchaseResumeCollectionCriteria = useMemo(
  //   () => ({
  //     clubReference,
  //     diversIds: linkedData?.diverIdsAll,
  //     status: {
  //       purchasePackages: linkedData?.includeArchivedPurchases
  //         ? 'all'
  //         : 'only-active',
  //     },
  //   }),
  //   [
  //     clubReference,
  //     linkedData?.diverIdsAll,
  //     linkedData?.includeArchivedPurchases,
  //   ],
  // );
  // const { content: purchaseResumes, ...loadableContent4 } =
  //   useLoadablePurchaseResumes({
  //     criteria: purchaseResumesCriteria,
  //   });

  // const clubPurchasePaymentsCriteria: PurchasePaymentCollectionCriteria =
  //   useMemo(
  //     () => ({
  //       diversIds: linkedData?.diverIdsAll,
  //       // status: {
  //       //   purchasePackages: 'all',
  //       // },
  //     }),
  //     [linkedData?.diverIdsAll],
  //   );
  // const { content: purchasePayments, ...loadableContent5 } =
  //   useLoadablePurchasePayments({
  //     criteria: clubPurchasePaymentsCriteria,
  //   });

  // const loadableContent = useMemo(
  //   () =>
  //     appLoaderMerger.mergeLoadableContents(
  //       // loadableContent1,
  //       loadableContent2,
  //       // loadableContent3,
  //       loadableContent4,
  //       loadableContent5,
  //     ),
  //   [loadableContent2, loadableContent3, loadableContent4, loadableContent5],
  // );

  const clubDiver: ClubDiver = useMemo(() => {
    if (mainDiverFull) {
      const diver = {
        ...mainDiverFull,
        divesMeta: undefined,
        __typename: undefined,
      } as ClubDiver;
      return diver;
    }
  }, [mainDiverFull]);

  return useMemo(() => {
    const content = diverBookingPageLoadedContentBuilder.buildContent({
      linkedData,
      focusDiveSession,
      clubDiver,
      clubResume,
      purchaseResumes,
      bookingDepositsResumes,
      purchasePayments,
      bookingResumes,
      bookingProductsResumes,
      clubReference,
      loadableContent,
      diverAttachedData,
    });
    return {
      content,
      refetchMessages: messagesFetchResult.refetch,
      refetchMain,
      refetchAdditional: loadableContentAdditional.refetch,
    };
  }, [
    linkedData,
    focusDiveSession,
    clubDiver,
    clubResume,
    purchaseResumes,
    bookingDepositsResumes,
    purchasePayments,
    bookingResumes,
    bookingProductsResumes,
    clubReference,
    loadableContent,
    diverAttachedData,
    messagesFetchResult.refetch,
    refetchMain,
    loadableContentAdditional.refetch,
  ]);
}
export type CacheQueryKey = (string | boolean | string[])[];
export type QueryResult = {
  bookingResumes: EditionBookingResume[];
  bookingProductsResumes: EditionBookingResumeProduct[];
  purchaseResumes: PurchaseResume[];
  bookingDepositsResumes: BookingDepositResume[];
  purchasePayments: PurchasePaymentGraphqlQueryResult[];
};
function useLoadAdditional({
  linkedData,
  additionnalDataToLoad,
}: {
  linkedData: DiverBookingPageClubDiverLinkedData;
  additionnalDataToLoad: DiverBookingPageAdditionalDataToLoad;
}) {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume?.clubReference;

  const queryKey = [clubReference, additionnalDataToLoad?.bookingIds?.length];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startDate = useMemo(() => new Date(), queryKey);

  const query: UseQueryResult<
    {
      bookingResumes?: EditionBookingResume[];
    },
    unknown
  > = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const queries: MQueryDescription<any>[] = [];
      if (additionnalDataToLoad?.bookingIds?.length > 0) {
        queries.push(
          diverBookingPageLoadedQueryBuilder.buildGraphqlQueryEditionBookingResumes(
            {
              // CHARGEMENT COMPLET on a besoin de tous les members divers pour construire les objets agrégés, comme PRO_BookingParticipantFull
              bookingIds: additionnalDataToLoad?.bookingIds,
              returnName: 'bookingResumes',
            },
          ),
        );
      }
      if (!queries.length) {
        return {}; // do not update result
      }
      const graphqlQuery$ = graphqlClient.query.runMany(queries, {
        type: 'query',
        name: 'useLoadAdditional',
      });

      return graphqlQuery$?.toPromise();

      // const results = await apiClient
      //   .post<ClubDiverBookingBase>('/pro/booking/diver-booking-base', {
      //     authenticate: true,
      //     json: {
      //       mainDiverId,
      //       focusDiveSessionReference,
      //     },
      //   })
      //   .toPromise();
      // return results;
    },
    enabled: !!additionnalDataToLoad?.bookingIds,
    staleTime: 0, // au chargement, on rafraichi systématiquement les données
    refetchOnWindowFocus: true,
    refetchInterval: 15000,
    retry: 60, // en cas d'erreur, on ré-essaie 60 fois, chaque 2 secondes
    retryDelay: 1000 * 2,
  });

  const loadableContentMulti =
    reactQueryToLoadableConverter.convertReactQueryToLoadableContent({
      query,
      startDate,
    });
  return loadableContentMulti;
}
