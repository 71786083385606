import {
  AppDocUploadContext,
  AppTranslationContentItemType,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import {
  AppFormControlRHF,
  AppInputRHF,
  AppTranslationContentItemsFormPanel,
} from 'src/lib/form';
import { EcommerceProductEditorFormContentMainImages } from '../../EcommerceProductEditor/form';
import { EcommerceCategoryEditorLocalState } from '../useEcommerceCategoryEditorPanelLocalState.hook';

const ITEMS_TYPES: AppTranslationContentItemType[] = [
  'rich-text',
  'app-doc-image',
  // 'button', // on verra plus tard
];

export const EcommerceCategoryEditorFormContent = ({
  localState,
  className,
}: {
  localState: EcommerceCategoryEditorLocalState;
  className?: string;
}) => {
  const {
    state: { form },
  } = localState;
  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    formState,
    setValue,
  } = form;

  const [bookletPage, contentImage, categoryName] = useWatch({
    control,
    name: [
      'appBookletPage',
      'appBookletPage.mainContent.content.image',
      'category.name',
    ],
  });

  const appDocUploadContext: AppDocUploadContext = {
    clubDiverId: undefined,
    bookingId: undefined,
    bookingMemberId: undefined,
    appBookletPageId: bookletPage?._id,
    fileType: 'image',
    scope: 'ecommerce',
    isPublic: true,
  };

  const [showImageSelector, setShowImageSelector] = useState(false);
  return (
    <div className={clsx('grid grid-cols-1 gap-4', className)}>
      {bookletPage && (
        <>
          <EcommerceProductEditorFormContentMainImages
            form={localState.state.form as any}
          />
          <h3 className="mt-4 md:mt-8 mx-1 mb-2 text-sm font-bold text-app-primary uppercase">
            Titre et sous-titre
          </h3>
          <AppFormControlRHF
            label="Titre"
            control={control}
            name="appBookletPage.mainContent.content.title"
            validation={{
              rules: {
                maxLength: 200,
              },
            }}
            required={true}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth={true}
                type="text"
                onChange={(value) => {
                  // recopie auto dans "product.name" et "cover.title"
                  setValue('category.name', value as unknown as string);
                  setValue(
                    'appBookletPage.mainContent.cover.title',
                    value as unknown as string,
                  );
                }}
              />
            )}
          />
          <AppFormControlRHF
            label="Sous-titre"
            control={control}
            name="appBookletPage.mainContent.content.subTitle"
            validation={{
              rules: {
                maxLength: 200,
              },
            }}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth={true}
                type="text"
                onChange={(value) =>
                  // recopie auto dans "cover.subtitle"
                  setValue(
                    'appBookletPage.mainContent.cover.subTitle',
                    value as unknown as string,
                  )
                }
              />
            )}
          />
          <h3 className="mt-4 md:mt-8 mx-1 mb-2 text-sm font-bold text-app-primary uppercase">
            Description détaillée et photo complémentaires
          </h3>
          <AppTranslationContentItemsFormPanel
            form={form}
            name="appBookletPage.mainContent.content.items"
            types={ITEMS_TYPES}
            appDocUploadContext={appDocUploadContext}
          />
        </>
      )}
    </div>
  );
};
