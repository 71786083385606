/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  dataSorter,
  productPackageFormatter,
  search,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { PurchaseListPagePurchaseModel } from './model';
import { useClubPurchasesListPageFilters } from './useClubPurchasesListPageFilters.hook';
import { useFetchPurchasesWithCache } from './useFetchPurchasesWithCache.hook';

export function useClubPurchasesListPageLocalState() {
  const { data: purchasesFetched, loadableContent } =
    useFetchPurchasesWithCache({
      autoRefetchInterval: '5 minutes', // periodic refresh (partial or full)
      staleInterval: '1 minute', // (full refresh if data is older));
    });

  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const publicSettings = clubResume.clubSettings.publicSettings;

  const purchases: PurchaseListPagePurchaseModel[] = useMemo(() => {
    if (purchasesFetched) {
      return purchasesFetched.map((p) => {
        const model: PurchaseListPagePurchaseModel = {
          ...p,
          label: productPackageFormatter.formatNameString(
            p?.productPackageOffer?.productPackage,
            {
              publicSettings,
              diveModesConf: clubSettings?.ui?.diveMode,
            },
          ),
        };
        return model;
      });
    }
  }, [clubSettings?.ui?.diveMode, publicSettings, purchasesFetched]);

  const filters = useClubPurchasesListPageFilters({ purchases });

  const {
    searchTextContactName,
    searchTextOfferName,
    validityStatus,
    paymentStatus,
    purchaseType,
    bookingAgencyId,
  } = filters;
  const sortedPurchases = useMemo(
    () =>
      dataSorter.sortMultiple(purchases ?? [], {
        getSortAttributes: (x, i) => [
          {
            value: new Date(x.purchaseDate),
            type: 'default',
            asc: false,
          },
          {
            value: new Date(x._updatedAt),
            type: 'default',
            asc: false,
          },
          {
            value: new Date(x._id),
            type: 'default',
            asc: false,
          },
        ],
        asc: true,
      }),
    [purchases],
  );

  const filteredPurchases = useMemo(() => {
    if (sortedPurchases) {
      let localPurchases = sortedPurchases;
      if (validityStatus) {
        localPurchases = localPurchases.filter(
          (x) => x.validityStatus === validityStatus,
        );
      }
      if (bookingAgencyId) {
        localPurchases = localPurchases.filter(
          (x) => x.billedByBookingAgencyId === bookingAgencyId,
        );
      }
      if (paymentStatus) {
        if (paymentStatus === 'pending') {
          localPurchases = localPurchases.filter(
            (x) => x.purchasePaymentPending,
          );
        } else {
          localPurchases = localPurchases.filter(
            (x) => x.purchasePaymentStatus === paymentStatus,
          );
        }
      }
      if (purchaseType) {
        if (purchaseType === 'training') {
          localPurchases = localPurchases.filter(
            (x) => x.productPackageType === 'training',
          );
        } else if (purchaseType === 'package') {
          localPurchases = localPurchases.filter(
            (x) =>
              x.productPackageType === 'dive' &&
              x.productPackageOffer?.productPackage?.diveAttributes
                ?.divePriceType === 'package',
          );
        }
      }

      localPurchases = search.filter(localPurchases, {
        searchText: searchTextContactName,
        getAttributes: (x) => [x.contact.lastName, x.contact.firstName],
        // maxResults: 50,
        // sortResultsByBestMatch: true,
      });
      localPurchases = search.filter(localPurchases, {
        searchText: searchTextOfferName,
        getAttributes: (x) => [x.label],
        // maxResults: 50,
        // sortResultsByBestMatch: true,
      });
      return localPurchases;
    }
    return sortedPurchases;
  }, [
    sortedPurchases,
    validityStatus,
    bookingAgencyId,
    paymentStatus,
    purchaseType,
    searchTextContactName,
    searchTextOfferName,
  ]);

  const filteredPurchasesLimited = useMemo(
    () => filteredPurchases.slice(0, 100),
    [filteredPurchases],
  );

  return {
    loadableContent,
    filteredPurchasesLimited,
    sortedPurchases,
    filteredPurchases,
    filters,
  };
}

export type ClubPurchasesListPageLocalState = ReturnType<
  typeof useClubPurchasesListPageLocalState
>;
