/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { AppExpansionPanel } from 'src/business/_core/modules/layout';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { AggregatedBookingPurchasePaymentWithDetails } from '../../models';
import { DiverBookingPageActions } from './../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from './../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPagePaymentTabPaymentsResumeTable } from './DiverBookingPagePaymentTabActivePayments';
import { DiverBookingPagePaymentTabLocalState } from './useDiverBookingPagePaymentTabLocalState';

export const DiverBookingPagePaymentTabArchivedPaymentsYearly = ({
  globalState,
  localState,
  dialogs,
  actions,
  year,
  purchasePaymentsWithDetails,
}: {
  globalState: DiverBookingPageGlobalState;
  localState: DiverBookingPagePaymentTabLocalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  year: number;
  purchasePaymentsWithDetails: AggregatedBookingPurchasePaymentWithDetails[];
}) => {
  const isThisYear = year === new Date().getFullYear();
  const expandedByDefault =
    isThisYear && purchasePaymentsWithDetails.length < 10;
  const [isExpanded, setIsExpanded] = useState(expandedByDefault);

  const totalPriceSum = purchasePaymentsWithDetails.reduce(
    (a, b) => a + b.purchasePayment?.amount ?? 0,
    0,
  );

  return (
    <AppExpansionPanel
      isExpandable={true}
      isExpanded={isExpanded}
      expandPanel={() => {
        return () => setIsExpanded(!isExpanded);
      }}
      panelId="archived-purchasePaymentsWithDetails"
      renderSummaryTitle={() => (
        <div className="flex justify-between">
          <span>
            {year} ({purchasePaymentsWithDetails.length} paiements
            {totalPriceSum > 0 && ` - ${totalPriceSum}€`})
          </span>
          {!isExpanded && (
            <span className="hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR VOIR LE DÉTAIL
            </span>
          )}
        </div>
      )}
      renderSummaryExtra={() => null}
      renderDetails={() => (
        <DiverBookingPagePaymentTabPaymentsResumeTable
          globalState={globalState}
          dialogs={dialogs}
          actions={actions}
          purchasePaymentsWithDetails={purchasePaymentsWithDetails}
        />
      )}
    />
  );
};
