/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
} from '@mabadive/app-common-model';
import React, { FC, useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { DiveSessionEditorDialogLocalState } from '../../../../useDiveSessionEditorDialogLocalState.hook';
import { DiveSessionDialogTab3ParticipantInfo } from '../DiveSessionDialogTab3ParticipantInfo';
import { DiveSessionDialogTab3EditableGroupConfigBar } from './DiveSessionDialogTab3EditableGroupConfigBar';
import { useDiveSessionDialogTab3EditableGroupLocalState } from './useDiveSessionDialogTab3EditableGroupLocalState';

export const DiveSessionDialogTab3EditableGroup: FC<{
  localState: DiveSessionEditorDialogLocalState;
  group: DiveSessionResumeGroup;
  participants: DiveSessionResumeParticipant[];
  onRemoveParticipantsFromGroup: (
    participants: DiveSessionResumeParticipant[],
    group: DiveSessionResumeGroup,
  ) => any;
  onAddSelectedParticipantsToGroup: (group: DiveSessionResumeGroup) => any;
}> = ({
  localState: parentLocalState,
  group,
  participants,
  onRemoveParticipantsFromGroup,
  onAddSelectedParticipantsToGroup,
}) => {
  const {
    aggregatedData: { diveSession },
    isMultiSessionConfigForStaff,
  } = parentLocalState;

  const localState = useDiveSessionDialogTab3EditableGroupLocalState({
    parentLocalState,
    group,
    participants,
  });
  const { data, actions } = localState;

  const selectedMember: ClubResumeStaffMember =
    parentLocalState.data.selectedMember;

  const selectedUngrouppedParticipantsIds =
    parentLocalState.data.selectedUngrouppedParticipantsIds;

  const selectedStaffMembersUngroupped: ClubResumeStaffMember[] =
    useMemo(() => {
      const staffIds = [
        group?.diveTourGroupSession1?.instructor?.staffId,
        group?.diveTourGroupSession2?.instructor?.staffId,
      ].filter((x) => !!x);
      const selectedMembers: ClubResumeStaffMember[] = [];

      if (selectedMember && !staffIds.includes(selectedMember?._id)) {
        selectedMembers.push(selectedMember);
      }
      return selectedMembers;
    }, [group, selectedMember]);

  const isAddToGroupButtonVisible =
    selectedUngrouppedParticipantsIds?.length +
      selectedStaffMembersUngroupped?.length !==
    0;

  return (
    <div
      className={'w-full border-2 border-l-4 pl-px pr-1 pt-1 pb-0.5'}
      style={group ? { borderColor: data.diveModeColor } : undefined}
    >
      <div className={'w-full flex justify-between gap-2 items-start'}>
        <DiveSessionDialogTab3EditableGroupConfigBar
          className="flex-grow"
          parentLocalState={parentLocalState}
          localState={localState}
          group={group}
        />
        <div className="flex justify-end flex-wrap gap-2">
          {
            <div>
              <AppButton
                className={isAddToGroupButtonVisible ? '' : 'invisible'}
                size="normal"
                icon={AppHeroIcons.actionAddUser}
                color={'primary-outline-light'}
                onClick={() => {
                  onAddSelectedParticipantsToGroup(group);
                }}
              >
                {/* <span className="font-bold hidden xl:flex">Ajouter</span> */}
              </AppButton>
            </div>
          }
          <div>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionDelete}
              color={'gray-outline-light'}
              onClick={() => {
                onRemoveParticipantsFromGroup(participants, group);
              }}
            >
              {/* <span className="font-bold hidden xl:flex">Supprimer</span> */}
            </AppButton>
          </div>
        </div>
      </div>
      <div className={'px-1'}>
        {participants.map((participant, participantIndex) => (
          <div
            key={participant._id}
            className={'select-none flex items-start gap-2 text-gray-600 py-1'}
          >
            <AppHeroIcons.actionDelete
              className="w-6 h-6 text-gray-400 hover:text-red-400 cursor-pointer"
              onClick={() => {
                onRemoveParticipantsFromGroup([participant], group);
              }}
            />
            <DiveSessionDialogTab3ParticipantInfo
              localState={parentLocalState}
              participant={participant}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
