import React from 'react';
import { Switch } from 'react-router-dom';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { ClubSettingsDiveCenterPlanningRoutes } from './default-planning/ClubSettingsDiveCenterPlanningRoutes';
import {
  DiveSiteEditPage,
  DiveSiteGroupEditPage,
  DiveSitesListPage,
} from './dive-sites';

import { ClubBoatEditPage, ClubBoatsListPage } from './boats';
import { StaffMembersListPage } from './staff-members';

export const ClubSettingsDiveCenterRoutes = React.memo(
  function ClubSettingsSecurityRoutesMemo() {
    const { match } = useRouter();
    return (
      <Switch>
        <AppRoute
          path={`${match.url}/planning`}
          component={ClubSettingsDiveCenterPlanningRoutes}
        />
        <AppRoute
          path={`${match.url}/staff`}
          exact
          component={StaffMembersListPage}
        />
        <AppRoute
          path={`${match.url}/sites`}
          exact
          component={DiveSitesListPage}
        />
        <AppRoute
          path={`${match.url}/sites/nouveau`}
          exact
          component={DiveSiteEditPage}
          navigationContext={{ mode: 'create' }}
        />
        <AppRoute
          path={`${match.url}/sites/:diveSiteId/edit`}
          exact
          component={DiveSiteEditPage}
          navigationContext={{ mode: 'edit' }}
        />
        <AppRoute
          path={`${match.url}/sites/groups/nouveau`}
          exact
          component={DiveSiteGroupEditPage}
          navigationContext={{ mode: 'create' }}
        />
        <AppRoute
          path={`${match.url}/sites/groups/:diveSiteGroupId/edit`}
          exact
          component={DiveSiteGroupEditPage}
          navigationContext={{ mode: 'edit' }}
        />
        <AppRoute
          path={`${match.url}/boats`}
          exact
          component={ClubBoatsListPage}
        />
        <AppRoute
          path={`${match.url}/boats/nouveau`}
          exact
          component={ClubBoatEditPage}
          navigationContext={{ mode: 'create' }}
        />
        <AppRoute
          path={`${match.url}/boats/:clubBoatId/edit`}
          exact
          component={ClubBoatEditPage}
          navigationContext={{ mode: 'edit' }}
        />
        {/* default route */}
        <AppRoute
          path={`${match.url}/`}
          exact={false}
          redirectToUrl="/club/settings"
        />
      </Switch>
    );
  },
);
