import { nameFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  ParticipantDiveCard,
  ParticipantDiveTags,
  ParticipantDiveType,
  ParticipantDiveTypeExtra,
} from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant';
import { BillingTabDiveSessionBillingResumePurchaseParticipant } from '../../models';
import { OnClickAssignProductFn } from './OnClickAssignProductFn.type';

export function DiverBillingSessionsTableParticipantCell({
  purchaseParticipant,
  displayName = false,
  displayLastName = false,
  displayAssignButton = false,
  theme,
  className,
  onClick,
  onClickAssignProducts,
}: {
  purchaseParticipant: BillingTabDiveSessionBillingResumePurchaseParticipant;
  displayName?: boolean;
  displayLastName?: boolean;
  displayAssignButton: boolean;
  theme: 'light' | 'normal';
  className?: string;
  onClickAssignProducts: OnClickAssignProductFn;
  onClick?: () => void;
}) {
  const { isAssociatedToPurchase, bookingProduct, participant } =
    purchaseParticipant;
  const { bookingParticipantFull, bookingMemberFull, style } = participant;

  const {
    bookingSessionParticipant,
    diveSessionParticipant,
    bookingProductDive,
    diveSession,
    diver,
  } = bookingParticipantFull;

  const hasCustomerEquipment =
    bookingProductDive.attributes?.equipment?.provider === 'customer';

  const diveSessionStatus = useMemo(
    () =>
      diveSessionParticipant &&
      diveSessionParticipant.bookingState.value === 'cancelled'
        ? 'cancelled'
        : diveSession.status,
    [diveSession.status, diveSessionParticipant],
  );
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const diveSite = useMemo(
    () =>
      diveCenterResume?.diveSites.find(
        (x) => x._id === bookingParticipantFull.diveSession.diveSiteId,
      ),
    [
      bookingParticipantFull.diveSession.diveSiteId,
      diveCenterResume?.diveSites,
    ],
  );
  const showDistanceInBilling =
    clubResume.clubSettings.general.billing?.showDistance;
  const showDistance =
    showDistanceInBilling &&
    (diveSessionParticipant.diveMode === 'autonomous' ||
      diveSessionParticipant.diveMode === 'supervised') &&
    diveSite?.distance > 0;

  const showDepthInBilling = clubResume.clubSettings.general.billing?.showDepth;
  const showDepth =
    showDepthInBilling && // uniquement les encadrés
    diveSessionParticipant.diveMode === 'supervised' &&
    diveSessionParticipant?.targetDeep > 0;

  // NOTE: attention, 'purchasePackageId' n'est pas mis à jour quand on clique sur le bouton dans le contexte d'une création/édition de formation ou prestation
  const isPurchased =
    isAssociatedToPurchase ||
    (bookingProductDive.purchaseStatus === 'archived' &&
      !!bookingProductDive.purchasePackageId);

  const { firstName, lastName } = useMemo(
    () => nameFormatter.formatFullNameChunks(diver),
    [diver],
  );

  return (
    <div
      className={`relative  ${className ?? ''}
      `}
    >
      <div
        className={`p-0.5 group ${
          style === 'highlight-light'
            ? 'ring ring-app-blue ring-opacity-20 hover:ring-app-dark-blue'
            : style === 'highlight-strong'
            ? 'ring ring-app-red hover:ring-app-dark-red'
            : 'ring ring-opacity-20 ring-gray-400 hover:ring-opacity-100'
        } 
      ${onClick ? 'cursor-pointer' : ''} 
      `}
      >
        {/* {bookingStateTag && (
          <ColoredTagAvatarCard
            className={`absolute ${
              displayName ? 'top-0.5 left-0.5' : 'top-1.5 left-1.5'
            }`}
            tag={bookingStateTag}
            size="small"
          />
        )} */}
        {displayName && (
          <div
            className={`font-bold text-xs leading-4 sm:leading-4 pb-1 text-center text-gray-500 group-hover:text-gray-600
            ${diveSessionStatus === 'cancelled' ? 'line-through' : ''}
          `}
            onClick={onClick}
          >
            <div className="truncate">
              {firstName}
              &nbsp;
              {displayLastName && lastName}
            </div>
          </div>
        )}
        <ParticipantDiveCard
          className={'block w-full h-10'}
          onClick={onClick}
          diveSession={diveSession}
          participant={diveSessionParticipant}
          theme={theme}
          isCancelledLineThrough={true}
        >
          <div className="flex flex-wrap gap-2">
            <ParticipantDiveType
              className="flex-grow"
              participant={diveSessionParticipant}
              diveSession={diveSession}
            />
            <ParticipantDiveTypeExtra
              participant={diveSessionParticipant}
              diveSession={diveSession}
            />
            <ParticipantDiveTags
              diveSession={diveSession}
              participant={diveSessionParticipant}
              diver={diver}
              includeBookingState={true}
            ></ParticipantDiveTags>
          </div>
          {showDistance && (
            <div className="text-white">{diveSite?.distance} nmi</div>
          )}
          {showDepth && (
            <div className="text-white">
              {diveSessionParticipant?.targetDeep} m
            </div>
          )}
          {hasCustomerEquipment &&
            diveSessionParticipant.diveMode !== 'training' &&
            diveSessionParticipant.diveMode !== 'first-dive' && (
              <div className="text-white text-xs">ÉQUIPÉ</div>
            )}
        </ParticipantDiveCard>
      </div>

      {displayAssignButton && (
        <>
          {/* {bookingProductDive.bookingProductStatus === 'cancelled' ? (
            <>
              <AppSingleSelect2HeadlessUI
                disableClearButton={true}
                theme={'tailwind'}
                className="text-app-xxs"
                value={bookingProductDive.purchaseStatus}
                options={
                  CANCELLED_PARTICIPANT_BOOKING_PRODUCT_PURCHASE_STATUSES_OPTIONS
                }
                onChange={(value) => {
                  if (value === 'purchased') {
                    onClickAssignProducts({
                      bookingProducts: [bookingProductDive],
                      operation: 'assign',
                    });
                  }
                }}
              />
            </>
          ) : ( */}
          <AppButton
            fullWidth={true}
            className="my-2"
            color={isPurchased ? 'primary-outline-light' : 'gray-outline-light'}
            onClick={() => {
              onClickAssignProducts({
                bookingProducts: [bookingProductDive],
                operation: isAssociatedToPurchase ? 'unassign' : 'assign',
              });
            }}
          >
            <div className="flex">
              <input
                type="checkbox"
                className="mt-1 mr-1"
                checked={isPurchased}
                onChange={() => {}}
              />
              {isPurchased ? (
                <span className="">imputé</span>
              ) : (
                <span className="">imputer</span>
              )}
            </div>
          </AppButton>
          {/* )} */}
        </>
      )}
    </div>
  );
}
