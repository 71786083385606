/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppTabTailwind } from 'src/business/_core/modules/layout';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

import useRouter from 'use-react-router';
import { ClubSettingsGeneralPageTabEditMode } from './_model/ClubSettingsGeneralPageTabEditMode.type';
import { ClubSettingsGeneralPageViewState } from './_model/ClubSettingsGeneralPageViewState.type';
import {
  ClubSettingsGeneralPageTabId,
  clubSettingsGeneralPageTabLabelFormatter,
  clubSettingsGeneralPageUrlManager,
} from './_services';
import { useClubSettingsGeneralPageUpdateURL } from './useClubSettingsGeneralPageUpdateURL.hook';

export const useClubSettingsGeneralPageLocalState = () => {
  const { match } = useRouter();

  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const isEditAllowedBase = useAppSecurityUserHasRole(
    'club-edit-settings-general',
  );
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const queryParams = clubSettingsGeneralPageUrlManager.getQueryParameters();

  const [viewState, setViewState] = useState<ClubSettingsGeneralPageViewState>({
    tabId: queryParams.tabId ?? 'main',
    mode: queryParams.mode ?? 'view',
  });

  useClubSettingsGeneralPageUpdateURL(viewState, {
    enableRouteParams: true,
  });

  const selectedTab = viewState.tabId;

  const setSelectedTab = useCallback((tabId: ClubSettingsGeneralPageTabId) => {
    setViewState((prev) => ({
      ...prev,
      tabId,
    }));
  }, []);

  const editMode: ClubSettingsGeneralPageTabEditMode = useMemo(() => {
    if (isEditAllowedBase) {
      if (false && selectedTab === 'sessions') {
        return 'super-admin-only';
      }
    }
    return isEditAllowedBase ? 'allowed' : 'forbidden';
  }, [isEditAllowedBase, selectedTab]);

  const editEnabled =
    editMode === 'allowed' || (editMode === 'super-admin-only' && isSuperAdmin);

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const openClubSettingsGeneralDialog = useCallback(
    (tabId: ClubSettingsGeneralPageTabId) => {
      dialogsState.clubSettingsGeneralDialog.openDialog({
        tabId,
        defaultValue: {
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.clubSettingsGeneralDialog],
  );

  const tabs = useMemo(() => {
    const tabsIds: ClubSettingsGeneralPageTabId[] = ['main', 'sessions'];
    if (clubSettings.general.billing.enabled) {
      tabsIds.push('purchase');
    }
    if (clubSettings.general.payments.enabled) {
      tabsIds.push('payments');
    }
    // if (clubSettings.general.taxes.enabled) {
    //   tabsIds.push('taxes');
    // }

    const tabs: AppTabTailwind<ClubSettingsGeneralPageTabId>[] = tabsIds.map(
      (tabId) => ({
        id: tabId,
        label: clubSettingsGeneralPageTabLabelFormatter.format(tabId),
      }),
    );
    return tabs;
  }, [
    clubSettings.general.billing.enabled,
    clubSettings.general.payments.enabled,
  ]);

  return {
    state: {
      editEnabled,
      editMode,
      tabs: {
        selected: selectedTab,
        setSelected: setSelectedTab,
        data: tabs,
      },
    },
    dialogs: {
      state: dialogsState,
      clubSettingsGeneral: {
        open: openClubSettingsGeneralDialog,
      },
    },
  };
};

export type ClubSettingsGeneralPageLocalState = ReturnType<
  typeof useClubSettingsGeneralPageLocalState
>;
