import {
  SETTINGS_OTHER_DISPLAY,
  SETTINGS_OTHER_GENERAL,
  SETTINGS_OTHER_PAGE_ACCOMODATIONS,
  SETTINGS_OTHER_PAGE_AGENCIES,
  SETTINGS_OTHER_PAGE_FORM_FIELDS,
} from './SETTINGS_OTHER.const';

export const clubSettingsOtherUrlBuilder = {
  display: {
    view: () => SETTINGS_OTHER_DISPLAY.url,
  },
  general: {
    view: () => SETTINGS_OTHER_GENERAL.url,
  },
  formFields: {
    view: () => SETTINGS_OTHER_PAGE_FORM_FIELDS.url,
    edit: () => `${SETTINGS_OTHER_PAGE_FORM_FIELDS.url}/edit`,
  },
  accommodations: {
    list: () => SETTINGS_OTHER_PAGE_ACCOMODATIONS.url,
    create: () => `${SETTINGS_OTHER_PAGE_ACCOMODATIONS.url}/nouveau`,
    edit: ({ accommodationId }: { accommodationId: string }) =>
      `${SETTINGS_OTHER_PAGE_ACCOMODATIONS.url}/${accommodationId}/edit`,
  },
  bookingAgencies: {
    list: () => SETTINGS_OTHER_PAGE_AGENCIES.url,
    create: () => `${SETTINGS_OTHER_PAGE_AGENCIES.url}/nouveau`,
    edit: ({ agencyId }: { agencyId: string }) =>
      `${SETTINGS_OTHER_PAGE_AGENCIES.url}/${agencyId}/edit`,
  },
};
