import { NEVER } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import { apiClient, apiClientStoreProvider } from 'src/_common-browser';

export const securitySheetDownloader = {
  downloadSecuritySheet,
};

function downloadSecuritySheet({
  diveSessionReference,
}: {
  diveSessionReference: string;
}) {
  apiClient
    .post<{ token: string }>('/dive-sessions-export/token', {
      authenticate: true,
      json: {
        diveSessionReference,
      },
    })
    .pipe(
      switchMap((response) => {
        if (response && response.token) {
          const apiClientStore = apiClientStoreProvider.get();
          const baseApiUrl = apiClientStore.baseUrl.getSnapshot();

          const params = (diveSessionReference = new URLSearchParams({
            diveSessionReference,
            'jwt-auth': response.token,
          }).toString());

          window.location.href = `${baseApiUrl}/dive-sessions-export/excel?${params.toString()}`;
          return NEVER;
        }
        // invalid response
        appLogger.warn(
          '[securitySheetDownloader] Invalid response from server',
          response,
        );
        uiStore.snackbarMessage.set({
          type: 'error',
          content:
            'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
        });
        return NEVER;
      }),
      catchError((err) => {
        appLogger.warn('[securitySheetDownloader] Authentication denied', err);
        uiStore.snackbarMessage.set({
          type: 'error',
          content:
            'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
        });
        return NEVER;
      }),
    )
    .subscribe();
}
