import { ProStatsChartConfig } from '@mabadive/app-common-model';
import { jsonParser } from '@mabadive/app-common-services';
import { useCallback, useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppTitleDateNavigatorModel,
  AppTitleDateNavigatorPeriodRange,
} from 'src/pages/_components/title-navigators/model';
import {
  appTitleDateNavigatorDatesBuilder,
  appTitleDateNavigatorPropsBuilder,
} from 'src/pages/_components/title-navigators/services';
import {
  DashboardFetchCriteria,
  DashboardFetchCriteriaPeriod,
  proStatsApiChartsConfigBuilder,
} from '../_core';
import {
  DashboardReportPageUIConfig,
  DashboardReportPageUIConfigSelection,
  DashboardReportPageViewParameters,
} from './model';
import { useDashboardReportFetchStats } from './useDashboardReportFetchStats.hook';
import { useDashboardReportPageViewParameters } from './useDashboardReportPageViewParameters.hook';

export function useDashboardReportPageLocalState() {
  const { viewParameters, setViewParameters } =
    useDashboardReportPageViewParameters();

  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const fiscalStartMonth = clubSettings.general.fiscalStartMonth;

  const { beginDate, endDate } = viewParameters.periodRange.value;
  const pageMode = viewParameters?.uiConfig?.pageMode;

  const fetchCriteria1: DashboardFetchCriteria = useMemo(() => {
    const period: DashboardFetchCriteriaPeriod = {
      beginDate,
      endDate,
    };
    if (pageMode === 'trainings') {
      const charts: ProStatsChartConfig =
        proStatsApiChartsConfigBuilder.buildChartsConfig({
          period,
          chartsIds: [
            'purchasesTrainingsOverPeriod',
            'trainingParticipantsOverPeriod',
          ],
          charts: [
            {
              chartId: 'participantsByPeriod',
              criteria: {
                diveModes: ['training'],
              },
            },
          ],
        });

      return {
        period,
        charts,
      };
    } else if (pageMode === 'first-dives') {
      const charts: ProStatsChartConfig =
        proStatsApiChartsConfigBuilder.buildChartsConfig({
          period,
          chartsIds: [
            'purchasesFirstDivesOverPeriod',
            'firstDiveParticipantsOverPeriod',
          ],
          charts: [
            {
              chartId: 'participantsByPeriod',
              criteria: {
                diveModes: ['first-dive'],
              },
            },
          ],
        });

      return {
        period,
        charts,
      };
    } else if (pageMode === 'explos') {
      const charts: ProStatsChartConfig =
        proStatsApiChartsConfigBuilder.buildChartsConfig({
          period,
          chartsIds: [
            'purchasesExplosOverPeriod',
            'exploParticipantsOverPeriod',
          ],
          charts: [
            {
              chartId: 'participantsByPeriod',
              criteria: {
                diveModes: [
                  'autonomous',
                  'supervised',
                  'instructor',
                  'autoSupervised',
                ],
              },
            },
          ],
        });

      return {
        period,
        charts,
      };
    }
    // general
    const charts: ProStatsChartConfig =
      proStatsApiChartsConfigBuilder.buildChartsConfig({
        period,
        chartsIds: [
          'participantsOverPeriod',
          'participantsByPeriod',
          'bookingsOverPeriod',
          'bookingsByPeriod',
          'paymentsOverPeriod',
          'paymentsByPeriod',
        ],
      });

    return {
      period,
      charts,
    };
  }, [beginDate, endDate, pageMode]);

  const fetchCriteria2: DashboardFetchCriteria = useMemo(() => {
    if (
      viewParameters.showPeriodRangeComparison &&
      viewParameters.periodRangeComparison
    ) {
      const { beginDate, endDate } =
        viewParameters.periodRangeComparison?.value;
      const period: DashboardFetchCriteriaPeriod = {
        beginDate,
        endDate,
      };
      const charts = proStatsApiChartsConfigBuilder.setChartsPeriod(
        fetchCriteria1.charts,
        period,
      );
      return {
        ...fetchCriteria1,
        period,
        charts,
      };
    }
  }, [
    fetchCriteria1,
    viewParameters.periodRangeComparison,
    viewParameters.showPeriodRangeComparison,
  ]);

  const {
    loadingInProgress: loadingInProgress1,
    fetchResult: fetchResult1,
    fetchResultFake: fetchResultFake1,
  } = useDashboardReportFetchStats(fetchCriteria1, {
    pageMode,
  });

  const {
    loadingInProgress: loadingInProgress2,
    fetchResult: fetchResult2,
    fetchResultFake: fetchResultFake2,
  } = useDashboardReportFetchStats(fetchCriteria2, {
    pageMode,
  });

  const { uiConfig } = viewParameters;
  const setUIConfig = useCallback(
    (uiConfig: DashboardReportPageUIConfig) => {
      setViewParameters({
        ...viewParameters,
        uiConfig,
      });
    },
    [setViewParameters, viewParameters],
  );

  const setSelection = useCallback(
    (selection?: DashboardReportPageUIConfigSelection) => {
      setUIConfig({
        ...uiConfig,
        selection,
      });
    },
    [setUIConfig, uiConfig],
  );

  const setHover = useCallback(
    (hover?: DashboardReportPageUIConfigSelection) => {
      setUIConfig({
        ...uiConfig,
        hover,
      });
    },
    [setUIConfig, uiConfig],
  );

  return {
    state: {
      uiConfig,
      setUIConfig,
      viewParameters,
      setViewParameters,
      loadingInProgress: loadingInProgress1 || loadingInProgress2,
      setSelection,
      setHover,
    },
    data: {
      hasAnyResultOrFake:
        !!fetchResult1 ||
        !!fetchResultFake1 ||
        !!fetchResult2 ||
        !!fetchResultFake2,
      main: {
        data: fetchResult1,
        fake: fetchResultFake1 ?? fetchResultFake2,
        dataOrFake: fetchResult1 ?? fetchResultFake1 ?? fetchResultFake2,
      },
      comparison: {
        data: fetchResult2,
        fake: fetchResultFake2 ?? fetchResultFake1,
        dataOrFake: fetchResult2 ?? fetchResultFake2 ?? fetchResultFake1,
      },
    },
    actions: {
      buildPeriodRangeComparison,
      ajustPeriodRange: (viewParameters: DashboardReportPageViewParameters) =>
        ajustPeriodRange(viewParameters, {
          fiscalStartMonth,
        }),
    },
  };
}

export type DashboardReportPageLocalState = ReturnType<
  typeof useDashboardReportPageLocalState
>;

export type DashboardReportComparisonContext = {
  data: DashboardReportPageLocalState['data'];
  localState: DashboardReportPageLocalState;
};

function buildPeriodRangeComparison(
  viewParameters: DashboardReportPageViewParameters,
) {
  const periodRangeComparison =
    jsonParser.parseJSONWithDates<AppTitleDateNavigatorPeriodRange>(
      JSON.stringify(viewParameters.periodRange),
    );

  const {
    autoSyncPeriod,
    ajustPeriodRangeToCurrentDate: ajustPeriodRangeToCurrentDateInput,
  } = viewParameters;

  // la date principale est déjà ajustée, donc ne pas ajuster de nouveau ou bien ça créé un décallage en mode 'year'
  const ajustPeriodRangeToCurrentDate =
    autoSyncPeriod === 'previous-year'
      ? false
      : ajustPeriodRangeToCurrentDateInput;

  // NOTE: y'a encore un bug si on compare 1-31 mars 2024 avec le mois de l'année dernière, ça donne 1-30 mars 2023 (au lieu de 1-31)

  let periodRange2Props: Pick<
    AppTitleDateNavigatorModel,
    'isNow' | 'previousValue' | 'nextValue'
  >;
  if (
    autoSyncPeriod === 'previous-year' &&
    (periodRangeComparison.period === 'month' ||
      periodRangeComparison.period === 'year' ||
      periodRangeComparison.period === 'year-fiscal')
  ) {
    periodRange2Props = appTitleDateNavigatorPropsBuilder.buildCalculatedProps({
      value: periodRangeComparison.value,
      period: 'year',
      ajustPeriodRangeToCurrentDate,
    });
  } else if (
    autoSyncPeriod === 'previous-month' &&
    periodRangeComparison.period === 'month'
  ) {
    periodRange2Props = appTitleDateNavigatorPropsBuilder.buildCalculatedProps({
      value: periodRangeComparison.value,
      period: 'month',
      ajustPeriodRangeToCurrentDate,
    });
  } else if (periodRangeComparison.period === 'dates-range') {
    periodRange2Props = appTitleDateNavigatorPropsBuilder.buildCalculatedProps({
      value: periodRangeComparison.value,
      period: 'year',
      ajustPeriodRangeToCurrentDate,
    });
  } else {
    periodRange2Props = appTitleDateNavigatorPropsBuilder.buildCalculatedProps({
      value: periodRangeComparison.value,
      period: periodRangeComparison.period,
      ajustPeriodRangeToCurrentDate,
    });
  }

  if (periodRange2Props.previousValue) {
    periodRangeComparison.value = {
      ...periodRange2Props.previousValue,
    };
  }
  return periodRangeComparison;
}

function ajustPeriodRange(
  viewParameters: DashboardReportPageViewParameters,
  { fiscalStartMonth }: { fiscalStartMonth: number },
) {
  let periodRange = viewParameters.periodRange;

  const ajustPeriodRangeToCurrentDate =
    viewParameters.showPeriodRangeComparison &&
    (viewParameters.autoSyncPeriod === 'previous-month' ||
      viewParameters.autoSyncPeriod === 'previous-year') &&
    viewParameters.ajustPeriodRangeToCurrentDate;

  if (viewParameters.periodRange?.period === 'month') {
    const value =
      appTitleDateNavigatorDatesBuilder.buildDefaultDatesPeriodMonth({
        selectedDate: viewParameters.periodRange.value.selectedDate,
        ajustPeriodRangeToCurrentDate,
      });
    periodRange = {
      ...periodRange,
      value,
    };
  } else if (viewParameters.periodRange?.period === 'year') {
    const value = appTitleDateNavigatorDatesBuilder.buildDefaultDatesPeriodYear(
      {
        selectedDate: viewParameters.periodRange.value.selectedDate,
        ajustPeriodRangeToCurrentDate,
      },
    );
    periodRange = {
      ...periodRange,
      value,
    };
  } else if (viewParameters.periodRange?.period === 'year-fiscal') {
    const value =
      appTitleDateNavigatorDatesBuilder.buildDefaultDatesPeriodFiscalYear({
        selectedDate: viewParameters.periodRange.value.selectedDate,
        ajustPeriodRangeToCurrentDate,
        startMonth: fiscalStartMonth,
      });
    periodRange = {
      ...periodRange,
      value,
    };
  }

  return periodRange;
}
