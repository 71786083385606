/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRoles } from 'src/business/auth/services';
import {
  AppSimpleMenuButton,
  AppSimpleMenuButtonItemGroup,
} from '../../_common/ui';
import { ClubPlanningParticipantsConfigDialog } from '../../club-planning-light/ClubPlanningParticipantsConfigDialog';
import {
  ClubPlanningDateNavigationBar,
  ClubPlanningDisplayModeToggleButton,
  ClubPlanningTodayButton,
  ClubPlanningViewPeriodToggleButton,
} from '../../club-planning-light/club-planning-navigation';
import { SetPlanningConfigOptions } from '../../club-planning-light/hooks';
import { PlanningViewConfig } from '../../club-planning/_model';
import { clubBookingUrlBuilder } from '../../url-builders';

export const ClubPlanningParticipantsConfigBar = ({
  planningConfig,
  setPlanningConfig,
  display,
  disableNavigation,
  className,
}: {
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  display?: {
    view?: boolean;
    mode?: boolean;
    actions?: boolean;
  };
  disableNavigation?: boolean;
  className?: string;
}) => {
  const redirectTo = useRedirect();

  const isEditParticipantRole = useAppSecurityUserHasRoles(
    'club-edit-participant-diver',
    'club-edit-participant-booking',
  );

  const actionsMenu: AppSimpleMenuButtonItemGroup[] = useMemo(() => {
    const g1: AppSimpleMenuButtonItemGroup = {
      items: [],
    };
    const groups: AppSimpleMenuButtonItemGroup[] = [g1];
    if (isEditParticipantRole) {
      g1.items.push({
        title: 'RÉSERVATION',
        titleShort: 'RÉSA',
        icon: AppHeroIcons.actionAddUser,
        style: 'primary',
        severity: 'info',
        hrefRoute: clubBookingUrlBuilder.buildCreateBookingUrl({
          navigationContext: {
            // diveSessionReference: session.reference,
            origin: 'planning',
            planningFocusDateRef: planningConfig?.focusDateRef,
          },
        }),
      });
    }
    return groups;
  }, [isEditParticipantRole, planningConfig?.focusDateRef]);

  return (
    <>
      <div
        className={clsx(
          'relative flex flex-row justify-between items-center py-1',
          className,
        )}
      >
        <div
          className={clsx(
            'absolute top-0 left-0 flex gap-2',
            disableNavigation && 'invisible',
          )}
        >
          <ClubPlanningTodayButton
            className="flex mx-1.5 my-1"
            planningConfig={planningConfig}
            setPlanningConfig={setPlanningConfig}
          />
        </div>

        <ClubPlanningDateNavigationBar
          className={clsx(disableNavigation && 'invisible')}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
        />
        {planningConfig.viewPeriod !== 'month' && (
          <AppHeroIcons.actionClose
            className={clsx(
              'absolute top-0 right-0 cursor-pointer w-6 h-6',
              'bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white',
            )}
            aria-hidden="true"
            onClick={() => {
              setPlanningConfig(
                {
                  ...planningConfig,
                  viewPeriod:
                    planningConfig.viewPeriod === 'week' ? 'month' : 'week',
                },
                {
                  origin: '[ClubPlanningParticipantsConfigBar] close',
                },
              );
            }}
          />
        )}
      </div>
      <div
        className={clsx(
          'flex items-stretch gap-4 justify-between py-1 px-2',
          className,
        )}
      >
        <div className={'flex flex-row items-stretch gap-4'}>
          <ClubPlanningParticipantsConfigDialog
            planningConfig={planningConfig}
            setPlanningConfig={setPlanningConfig}
          />
          <div
            className={
              'h-full flex flex-row items-stretch gap-4 sm:gap-8 mg:gap-12'
            }
          >
            {display?.view && (
              <ClubPlanningViewPeriodToggleButton
                className="h-full flex uppercase text-xs"
                context={'planning-participants-page'}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
              />
            )}
            {display?.mode && (
              <ClubPlanningDisplayModeToggleButton
                className="h-full flex uppercase text-xs"
                context={'planning-participants-page'}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
              />
            )}
          </div>
        </div>
        <AppSimpleMenuButton menu={actionsMenu} />
      </div>
    </>
  );
};
