/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlV2, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  ClubSettingsGeneralDialogInitialState,
  ClubSettingsGeneralFormModel,
} from '../../../_model';
import { ClubSettingsGeneralMainFormFeatures } from './ClubSettingsGeneralMainFormFeatures';
import { ClubSettingsGeneralMainFormLocalisation } from './ClubSettingsGeneralMainFormLocalisation';

export const ClubSettingsGeneralMainForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
  initialState: ClubSettingsGeneralDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const [customerSpaceEnabled, billingEnabled, paymentsEnabled, taxesEnabled] =
    useWatch({
      control,
      name: [
        'clubSettings.publicSettings.customerSpace.enabled',
        'clubSettings.general.billing.enabled',
        'clubSettings.general.payments.enabled',
        'clubSettings.general.taxes.enabled',
      ],
    });

  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsGeneralMainFormLocalisation form={form} />
      <ClubSettingsGeneralMainFormFeatures form={form} />

      {isSuperAdmin && (
        <ClubSettingsSection title="Informations techniques">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
            <AppFormControlRHF_Deprecated
              label={'Référence publique'}
              control={control}
              required={customerSpaceEnabled}
              name="clubSettings.clubPublicReference"
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
            <AppFormControlV2
              label={'Référence interne'}
              renderComponent={() => (
                <div className="my-1 text-gray-800 text-base">
                  {clubResume.reference}
                </div>
              )}
            />
          </div>

          <AppFormControlRHF_Deprecated
            label={"Lien de partage d'écran"}
            control={control}
            required={false}
            name="clubSettings.general.internal.screenSharingUrl"
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
        </ClubSettingsSection>
      )}
    </div>
  );
};
