/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import { CalendarSessionWidthMode } from '../components/ClubPlanningLightSession/session-common';
import { ClubPlanningPanelSessionDisplayConfig } from '../model';
import { ClubPlanningAction } from '../useClubPlanningActions.hook';

import { ClubPlanningLightPanelViewPeriodMonthDayHeader } from './ClubPlanningLightPanelViewPeriodMonthDayHeader';
import { ClubPlanningLightPanelViewPeriodMonthLegend } from './ClubPlanningLightPanelViewPeriodMonthLegend';
import { ClubPlanningLightPanelViewPeriodMonthWeek } from './ClubPlanningLightPanelViewPeriodMonthWeek';
import {
  ClubPlanningLightPanelViewPeriodMonthState,
  useClubPlanningLightPanelViewPeriodMonthState,
} from './useClubPlanningLightPanelViewPeriodMonthState.hook';

export const ClubPlanningLightPanelViewPeriodMonth = ({
  localState: parentState,
  clubPlanningActions,
  context,
  sessionsDisplayConfigs = [],
  className,
}: {
  localState: PlanningParticipantsLocalState;
  clubPlanningActions: ClubPlanningAction;
  context: ClubPlanningDisplayConfigurationBarContext;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  className?: string;
}) => {
  const localState: ClubPlanningLightPanelViewPeriodMonthState =
    useClubPlanningLightPanelViewPeriodMonthState({
      parentState,
    });

  const mediaSize = useMediaSizeTailwind();
  const { monthPlanning } = localState;
  const widthMode: CalendarSessionWidthMode =
    mediaSize === 'lg' || mediaSize === 'xl' || mediaSize === '2xl'
      ? 'medium'
      : mediaSize === 'xxs'
      ? 'tiny'
      : 'small';

  const days = monthPlanning.weeksPlanning[0].daysPlanning;

  return (
    <div
      className={clsx(
        'flex flex-col',
        'border-y-[1px] border-gray-200',
        className,
      )}
    >
      <div
        className={clsx(
          'w-full sticky top-0 z-10',
          'grid grid-cols-7 gap-px',
          'px-px bg-gray-200',
        )}
      >
        {days.map((day, i) => (
          <ClubPlanningLightPanelViewPeriodMonthDayHeader
            key={i}
            className=""
            date={day.weekDate.date}
          />
        ))}
      </div>

      <div className={clsx('flex-grow flex flex-col gap-8 justify-between')}>
        <div
          className={clsx(
            'max-h-full overflow-y-auto overflow-x-hidden grid',
            // 'px-px bg-gray-200',
          )}
        >
          {monthPlanning.weeksPlanning.map((weekPlanning, iDay) => (
            <ClubPlanningLightPanelViewPeriodMonthWeek
              className="min-h-[100px] border-b-[2px] border-gray-200"
              key={iDay}
              localState={localState}
              weekPlanning={weekPlanning}
              clubPlanningActions={clubPlanningActions}
              context={context}
              sessionsDisplayConfigs={sessionsDisplayConfigs}
              widthMode={widthMode}
            />
          ))}
        </div>
        <ClubPlanningLightPanelViewPeriodMonthLegend localState={localState} />
      </div>
    </div>
  );
};
