/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { ClubPlanningLightPanelStaffDailyCounters } from '../../../club-planning-light/ClubPlanningLightPanelViewPeriodDay';
import { SetPlanningConfigOptions } from '../../../club-planning-light/hooks';
import { PlanningViewConfig } from '../../_model';
import { ClubPlanningStaffWeekStaffPresenceLocalState } from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';

export const ClubPlanningStaffWeekStaffPresenceHeaderTotals = ({
  planningConfig,
  setPlanningConfig,
  localState,
  gridClassName,
  className,
}: {
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  localState: ClubPlanningStaffWeekStaffPresenceLocalState;
  gridClassName?: string;
  className?: string;
}) => {
  const { data, actions, criteria } = localState;

  const days = data.aggregateData.days;

  return (
    <div className={className}>
      <div className={clsx(gridClassName, '')}>
        <div
          className={clsx(
            'lg:col-span-2 py-1 px-2 text-right text-gray-500 font-medium',
            planningConfig.viewPeriod === 'week' && 'hidden lg:block',
          )}
        >
          TOTAL:
        </div>
        {days.map((day, i) => (
          <ClubPlanningLightPanelStaffDailyCounters
            key={i}
            date={day.date}
            showTooltip={false}
            planningConfig={{
              ...planningConfig,
              showStaffCountsPerDay: true,
              showStaffCountsPerDayDP: true,
              showStaffCountsPerDayGP: true,
              showStaffCountsPerDaySS: true,
            }}
            staffMembersResumeSessions={day.staffMembersResumeSessions}
            className={clsx(
              'py-1 text-app-xxs xl:text-xs',
              planningConfig.viewPeriod === 'week'
                ? 'flex flex-col md:flex-row md:justify-end border-l border-gray-200'
                : 'flex',
            )}
          />
        ))}
      </div>
    </div>
  );
};
