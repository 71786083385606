import {
  AppCurrency,
  Customer_ClubSettingsPublicCustomer,
  OnlineBookingDetailsArticleOrderItem,
  OnlineBookingOrderArticle,
} from '@mabadive/app-common-model';
import { nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { useAppCurrencyMain } from '../../../../../../../../pages/_components/options';
import { AppPriceLabel } from '../../../../../_common/ui';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';

export const DiverBookingCardOnlineBookingArticleItem = ({
  globalState,
  orderArticle,
  orderArticleItem: item,
  clubSettings,
  className,
}: {
  globalState: DiverBookingPageGlobalState;
  orderArticle: OnlineBookingOrderArticle;
  orderArticleItem: OnlineBookingDetailsArticleOrderItem;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  className?: string;
}) => {
  const clubPublicSettings = clubSettings?.publicSettings;
  const mainCurrency: AppCurrency = useAppCurrencyMain();
  const articleDetails = orderArticle?.articleDetails;

  const totalPrice = item.price.unitPrice * item.participants.length;

  const diverIds = (item.participants ?? [])?.map((x) => x.diverId);
  const divers = (globalState.aggregatedData.divers ?? []).filter((x) =>
    diverIds.includes(x._id),
  );

  return (
    <div className={clsx('w-full flex flex-col gap-2')}>
      <div className="">
        <div className="w-full flex flex-row gap-4 justify-between items-start">
          <div className="uppercase font-bold text-gray-600">
            <div className="text-xs sm:text-sm text-gray-500">
              {item.price.label}
            </div>
          </div>
          <AppPriceLabel
            className="text-gray-500 font-bold"
            amount={item.price.unitPrice}
            mainCurrency={mainCurrency}
          />
        </div>
        <div className="italic text-gray-500">{item.price.comment}</div>
      </div>
      <div className="text-left text-sm font-medium text-app-primary uppercase flex gap-2">
        {item.quantity !== item.participants?.length ? (
          <>
            <div className="text-app-primary">Quantité: {item.quantity}</div>
            <div className="text-gray-600">
              {item.participants?.length} PARTICIPANTS:
            </div>
          </>
        ) : (
          <>{item.quantity} PARTICIPANTS: </>
        )}
        <span className="text-gray-800 normal-case">
          {' '}
          {divers.length > 0 &&
            divers.map((x) => nameFormatter.formatFullName(x)).join(', ')}
        </span>
      </div>
    </div>
  );
};
