import { NEVER } from 'rxjs';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import { apiClient } from './apiClient.service';
import { apiClientStoreProvider } from './apiClientStoreProvider.service';

export const apiDownloader = {
  downloadDiversSheet,
  downloadPaymentsSheet,
  downloadDailyResumeSheetCash,
  downloadDailyResumeSheetCustom,
};

function downloadDiversSheet() {
  return downloadSheet({
    relativeUrl: '/divers-export',
  });
}

function downloadDailyResumeSheetCash({
  beginDateUTC,
  endDateUTC,
}: {
  beginDateUTC: Date;
  endDateUTC: Date;
}) {
  const searchParams: URLSearchParams = new URLSearchParams({
    beginDateUTC: `${beginDateUTC.getTime()}`,
    endDateUTC: `${endDateUTC.getTime()}`,
  });
  downloadSheet({
    relativeUrl: '/export/daily/cash',
    queryParams: searchParams.toString(),
  });
}
function downloadDailyResumeSheetCustom({
  exportSettingsId,
  beginDateUTC,
  endDateUTC,
  sessionsIds,
}: {
  exportSettingsId: string;
  beginDateUTC: Date;
  endDateUTC: Date;
  sessionsIds?: string[];
}) {
  const searchParams: URLSearchParams = new URLSearchParams({
    beginDateUTC: `${beginDateUTC.getTime()}`,
    endDateUTC: `${endDateUTC.getTime()}`,
    exportSettingsId,
  });
  if (sessionsIds?.length > 0) {
    searchParams.append('sessionsIds', sessionsIds.join(','));
  }
  downloadSheet({
    relativeUrl: '/export/daily/custom',
    queryParams: searchParams.toString(),
  });
}
function downloadPaymentsSheet({
  beginDateUTC,
  endDateUTC,
}: {
  beginDateUTC: Date;
  endDateUTC: Date;
}) {
  downloadSheet({
    relativeUrl: '/payments-export',
    queryParams: `beginDateUTC=${beginDateUTC.getTime()}&endDateUTC=${endDateUTC.getTime()}`,
  });
}

async function downloadSheet({
  relativeUrl,
  queryParams,
}: {
  relativeUrl: string;
  queryParams?: string;
}) {
  try {
    const response = await apiClient
      .post<{ token: string }>(`${relativeUrl}/token`, {
        authenticate: true,
      })
      .toPromise();

    if (response && response.token) {
      appLogger.info('[apiDownloader.downloadSheet] token:', response.token);
      const apiClientStore = apiClientStoreProvider.get();
      const baseApiUrl = apiClientStore.baseUrl.getSnapshot();
      window.location.href = `${baseApiUrl}${relativeUrl}/?jwt-auth=${
        response.token
      }&${queryParams ? queryParams : ''}`;
      return NEVER;
    }
    // invalid response
    appLogger.warn(
      '[apiDownloader.downloadSheet] Invalid response from server',
      response,
    );
    uiStore.snackbarMessage.set({
      type: 'error',
      content:
        'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
    });
    return NEVER;
  } catch (err) {
    appLogger.warn('[apiDownloader.downloadSheet] Authentication denied', err);
    uiStore.snackbarMessage.set({
      type: 'error',
      content:
        'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
    });
  }
}
