import clsx from 'clsx';
import React from 'react';
import { AppInputBoolean } from 'src/lib/form';

export const EvolutionParticipantsByDayBarChartLegendCheckbox = ({
  value,
  onChange,
  className,
  style,
  label,
}: {
  value: boolean;
  onChange: (value: boolean) => any;
  label: string;
  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        'w-40 px-1 py-1 font-bold flex justify-start items-center',
        className,
      )}
      style={style}
      onClick={() => {
        onChange(!value);
      }}
    >
      <AppInputBoolean
        className="-ml-2 text-gray-800 accent-white" // NOTE: "accent-" ne fonctionne pas, donc je n'arrive pas à changer la couleur de la marque (sûrement à cause de tailwind-form)
        type="checkbox"
        value={value}
        onChange={(value) => {
          // onChange(value);
        }}
      />
      <span className="cursor-pointer text-xs">{label}</span>
    </div>
  );
};
