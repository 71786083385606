/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  ClubStaffMemberDailyAvailabilityPeriod,
} from '@mabadive/app-common-model';
import { useCallback } from 'react';
import {
  PlanningStaffWeekPresenceUpdateState,
  planningStaffWeekPresenceCacheUpdator,
} from 'src/business/club/modules/club-planning/ClubPlanningStaffPage/cache';
import { DiveSessionEditorDialogLocalState } from '../../useDiveSessionEditorDialogLocalState.hook';
import { DiveSessionStaffRoleModel } from '../DiveSessionDialogTab3Groups/components/DiveSessionStaffMembersTable';

export type UpdateSessionStaffConfigProps = {
  availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod;
  isAvailableOnSession: boolean;
  toogleRole?: Pick<DiveSessionStaffRoleModel, 'assigned' | 'staffRoleRef'>;
};
export function useOnUpdateSessionStaffConfig({
  localState,
}: {
  localState: DiveSessionEditorDialogLocalState;
}) {
  const {
    aggregatedData: { diveSession, dailyConfigs },
    updateState,
    setUpdateState,
    form,
  } = localState;

  return useCallback(
    (
      staffMember: ClubResumeStaffMember,
      {
        availabilityPeriod,
        isAvailableOnSession,
        toogleRole,
      }: UpdateSessionStaffConfigProps,
    ) => {
      planningStaffWeekPresenceCacheUpdator.updateSessionStaffConfig({
        updateState,
        diveSession,
        staffMember,
        availabilityPeriod,
        isAvailableOnSession,
        toogleRole,
        form,
        dailyConfigs,
        setUpdateState: setUpdateState as (
          updateState: PlanningStaffWeekPresenceUpdateState,
        ) => void,
      });
    },
    [dailyConfigs, diveSession, form, setUpdateState, updateState],
  );
}

export type OnUpdateSessionStaffConfigFn = ReturnType<
  typeof useOnUpdateSessionStaffConfig
>;
