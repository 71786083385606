import {
  BOOKING_COMMENT_TAG,
  Club,
  ClubDiver,
  ClubParticipant,
  PARTICIPANT_COMMENT_TAG,
} from '@mabadive/app-common-model';
import {
  dateService,
  diveSessionServiceFormatter,
  participantAptitudeBuilder,
} from '@mabadive/app-common-services';
import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import {
  ColoredTagAvatarCard,
  ColoredTagCard,
} from 'src/business/_core/modules/layout';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { CommentLabel } from 'src/business/club/modules/_common/form/components';
import { BookingStatusBadge } from 'src/business/club/modules/_common/form/components/BookingStatusBadge';
import {
  ParticipantGaz,
  ParticipantTargetDeep,
} from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant';
import { participantTagsBuilder } from 'src/business/club/modules/club-planning/_components';
import { ClubParticipantCardAttributeEquipment } from './ClubParticipantCardAttributeEquipment';
import { ClubParticipantCardAttributeName } from './model';

const FORMAT = 'short';

export const ClubParticipantCardAttribute = ({
  club,
  diver,
  participant,
  attributeName,
  className = '',
}: {
  club: Pick<Club, 'participantTags'>;
  diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
  participant: ClubParticipant;
  attributeName: ClubParticipantCardAttributeName;
  className?: string;
}) => {
  const globalClasses = useGlobalClasses();

  const hasParticipantBookingComment = useMemo(
    () =>
      participant &&
      (participant.bookingState.comment ||
        participant.bookingHistory.find((bh) => bh.comment) !== null),
    [participant],
  );

  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const aptitude = useMemo(
    () =>
      attributeName === 'aptitude'
        ? participantAptitudeBuilder.formatAptitude(
            {
              diver,
              participant,
            },
            {
              clubReference,
            },
          )
        : '',
    [attributeName, clubReference, diver, participant],
  );

  const clubSettings = useClubSettings();
  const clubPublicSettings = clubSettings?.publicSettings;

  return !participant ? null : renderAttribute(attributeName);

  function renderAttribute(attributeName: ClubParticipantCardAttributeName) {
    switch (attributeName) {
      case 'diveType':
        const specialDiveTypeTag =
          participantTagsBuilder.getParticipantSpecialDiveTypeTag({
            participant,
          });
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Type
            </h3>
            <div className="data-value text-app-blue max-w-full flex items-center gap-1 truncate">
              <span>
                {diveSessionServiceFormatter.formatServiceFromParticipant(
                  { diver, participant },
                  {
                    format: 'short-ref',
                    clubReference,
                    clubPublicSettings,
                    diveModesConf: clubSettings?.ui?.diveMode,
                  },
                )}
              </span>
              {specialDiveTypeTag && (
                <ColoredTagAvatarCard tag={specialDiveTypeTag} size={'small'} />
              )}
              {participant?.equipment?.provider === 'customer' && (
                <span className="text-xs">(ÉQUIPÉ)</span>
              )}
            </div>
          </div>
        );
      case 'aptitude':
        return !aptitude?.length ? null : (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Aptitude
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              {aptitude}
            </div>
          </div>
        );
      case 'targetDeep':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Prof. cible
            </h3>
            <div className="data-value max-w-full truncate">
              <ParticipantTargetDeep targetDeep={participant?.targetDeep} />
            </div>
          </div>
        );
      case 'gaz':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Gaz
            </h3>
            <div className="data-value max-w-full truncate">
              <ParticipantGaz
                className="text-xs"
                gaz={participant.gaz}
                showDetails={true}
                showGazQuantity={true}
                showAir={true}
              />
            </div>
          </div>
        );
      case 'jacket':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.jacket}
            format={FORMAT}
          />
        );
      case 'weighting':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.weighting}
            format={FORMAT}
          />
        );
      case 'wetsuit':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.wetsuit}
            format={FORMAT}
          />
        );
      case 'fins':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.fins}
            format={FORMAT}
          />
        );
      case 'scubaRegulator':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.scubaRegulator}
            format={FORMAT}
          />
        );
      case 'mask':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.mask}
            format={FORMAT}
          />
        );
      case 'flashLight':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.flashLight}
            format={FORMAT}
          />
        );
      case 'computer':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.computer}
            format={FORMAT}
          />
        );
      case 'tank':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.tank}
            format={FORMAT}
          />
        );
      case 'compass':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.compass}
            format={FORMAT}
          />
        );
      case 'rebreather':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.rebreather}
            format={FORMAT}
          />
        );
      case 'underwaterScooter':
        return (
          <ClubParticipantCardAttributeEquipment
            className={`${className} max-w-full`}
            equipmentType={attributeName}
            equipment={participant.equipment?.underwaterScooter}
            format={FORMAT}
          />
        );
      case 'bookingState':
        return (
          <>
            <div
              className={`${className} mt-3 max-w-full`}
              style={{ flex: '100% 0 0' }}
            >
              <h3 className="data-label max-w-full text-sm text-gray-500 font-bold uppercase">
                Historique de réservation
              </h3>
              <table className="my-2">
                <thead>
                  <tr>
                    <th className="pr-2 text-left text-xs uppercase">Date</th>
                    <th className="px-2 text-left text-xs uppercase">Statut</th>
                    {hasParticipantBookingComment ? (
                      <div className="hidden sm:block">
                        <th className="px-2 text-left text-xs uppercase">
                          Infos
                        </th>
                      </div>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {participant.bookingHistory.map((bookingState, i) => (
                    <tr key={`${i}`}>
                      <td className={'pr-2 text-left max-w-full'}>
                        <div className="data-value text-app-blue max-w-full truncate">
                          {dateService.formatUTC(
                            bookingState.date,
                            'DD/MM/YYYY HH:mm',
                          )}
                        </div>
                      </td>
                      <td className={'px-2 text-left max-w-full'}>
                        <div
                          className={`data-value text-app-blue important-${
                            bookingState.value === 'cancelled'
                          }`}
                        >
                          <BookingStatusBadge
                            className="text-xs"
                            mode="status+user"
                            bookingState={bookingState}
                          />
                        </div>
                      </td>
                      {hasParticipantBookingComment ? (
                        <div className="hidden sm:block">
                          <td className={'px-2 text-left max-w-full'}>
                            <CommentLabel
                              tag={BOOKING_COMMENT_TAG}
                              comment={bookingState.comment}
                              chunkClassName="data-value text-app-blue max-w-full truncate"
                            />
                          </td>
                        </div>
                      ) : null}
                    </tr>
                  ))}
                  <tr>
                    <td className={'pr-2 text-left max-w-full'}>
                      <div className="data-value text-app-blue max-w-full truncate">
                        {dateService.formatUTC(
                          participant.bookingState.date,
                          'DD/MM/YYYY HH:mm',
                        )}
                      </div>
                    </td>
                    <td className={'px-2 text-left max-w-full'}>
                      <div
                        className={`data-value text-app-blue important-${
                          participant.bookingState.value === 'cancelled'
                        }`}
                      >
                        <BookingStatusBadge
                          className="text-xs"
                          mode="status+user"
                          bookingState={participant.bookingState}
                        />
                      </div>
                    </td>
                    {hasParticipantBookingComment ? (
                      <div className="hidden sm:block">
                        <td className={'pr-2 text-left max-w-full'}>
                          <CommentLabel
                            tag={BOOKING_COMMENT_TAG}
                            comment={participant.bookingState.comment}
                            chunkClassName="data-value text-app-blue max-w-full truncate"
                          />
                        </td>
                      </div>
                    ) : null}
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        );
      case 'tags':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              &nbsp;
            </h3>
            <Grid container spacing={1}>
              {participant.tags.map((tag, i) => (
                <Grid item key={`${i}`}>
                  <ColoredTagCard
                    tag={club.participantTags.find((t) => t.reference === tag)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        );
      case 'comment':
        return (
          <div className={`${className} max-w-full`}>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Commentaire
            </h3>
            <CommentLabel
              tag={PARTICIPANT_COMMENT_TAG}
              comment={participant.comment}
              chunkClassName="data-value text-app-blue max-w-full truncate"
            />
          </div>
        );
      default:
        return null;
    }
  }
};
