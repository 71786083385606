/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubProductPackageMeta,
  ClubProductPackageOfferView,
  DiveServicePurchaseStatus,
} from '@mabadive/app-common-model';
import {
  MatchingOffersCriteria,
  clubProductPackageMetaReader,
  clubProductPackageOfferMatcherBestTraining,
  nameFormatter,
} from '@mabadive/app-common-services';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  AppButtonsBar,
  AppPageBlock,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppButtonDeprecated } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubPurchasesExtraCostDescription } from 'src/pages/LI-lists/LI-03_purchase/ClubPurchasesTable/ClubPurchasesExtraCostDescription';
import {
  DiverPurchaseCommonEditorDialogResult,
  DiverPurchaseTrainingEditorDialogState,
} from '../../models';
import { bookingPagePackageConsumedCounter } from '../../services/02.update-state/services';
import { DiverBillingSessionsTable } from '../DiverBillingSessionsTable';
import {
  creditsCountBuilder,
  initialBookingProductBuilder,
  productPurchaseStatusUpdator,
  purchaseCommonSessionsBillingResumesBuilder,
  useDiverPurchaseCommonEditorDialogInitialAssociatedBookingProductIds,
  useDiverPurchaseCommonEditorDialogOnClickAssignProducts,
} from '../DiverPurchaseCommonEditorDialog';
import { DiverPurchaseEditorParticipantsConfig } from '../DiverPurchaseCommonEditorDialog/model';
import {
  DiverPurchaseTrainingFormInfoBox,
  DiverPurchaseTrainingFormModel,
  DiverPurchaseTrainingFormRHF,
} from './components';
import { useDiverPurchaseTrainingPackageOffers } from './components/DiverPurchaseTrainingForm/useDiverPurchaseTrainingPackageOffers.hook';
import { diverPurchaseTrainingEditorDialogUpdateBuilder } from './diverPurchaseTrainingEditorDialogUpdateBuilder.service';
import { useDiverPurchaseTrainingEditorDialogInitialValue } from './useDiverPurchaseTrainingEditorDialogInitialValue.hook';

export const DiverPurchaseTrainingEditorDialog: FC<{
  state: DiverPurchaseTrainingEditorDialogState;
  setState: React.Dispatch<
    React.SetStateAction<DiverPurchaseTrainingEditorDialogState>
  >;
  onConfirm?: (result: DiverPurchaseCommonEditorDialogResult) => void;
}> = ({ onConfirm, state, setState }) => {
  // eslint-disable-next-line no-console
  console.log('[OPEN xxx] DiverPurchaseTrainingEditorDialog');

  const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] = useState(false);
  const globalClasses = useGlobalClasses();

  const {
    diver,
    purchasePackage,
    createContext,
    mode,
    sessionsBillingResumes: initialSessionsBillingResumes,
    aggregatedData,
  } = state;

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setState({
        ...state,
        isOpen,
      });
    },
    [setState, state],
  );

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const initialAssociatedBookingProductIds =
    useDiverPurchaseCommonEditorDialogInitialAssociatedBookingProductIds({
      initialSessionsBillingResumes,
      mode,
    });

  const productPackageOffersCriteria: MatchingOffersCriteria = useMemo(
    () => ({
      diver,
      diveModeGroup: 'training',
    }),
    [diver],
  );

  const productPackageOffers = useDiverPurchaseTrainingPackageOffers(
    productPackageOffersCriteria,
  );

  const defaultProductPackageOffer = useMemo(() => {
    if (createContext) {
      const offer =
        clubProductPackageOfferMatcherBestTraining.findBestMatchingOfferTraining(
          {
            criteria: createContext,
            productPackageOffers,
          },
        );
      return offer;
    }
  }, [createContext, productPackageOffers]);

  const initialFormValue = useDiverPurchaseTrainingEditorDialogInitialValue({
    purchasePackage, // undefined if 'create' mode
    defaultProductPackageOffer,
    mode,
    initialAssociatedBookingProductIds,
    clubSettings,
    billingResumes: initialSessionsBillingResumes.all,
    aggregatedData,
    diveCenterId,
  });

  const form = useForm<DiverPurchaseTrainingFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const [
    validityStatus,
    creditsInitialCount,
    productPackageOfferReference,
    extraCosts,
  ] = useWatch({
    control,
    name: [
      'validityStatus',
      'creditsInitialCount',
      'productPackageOfferReference',
      'extraCosts',
    ],
  });

  const selectedOffer: ClubProductPackageOfferView = useMemo(
    () =>
      productPackageOffers.find(
        (o) => o.reference === productPackageOfferReference,
      ),
    [productPackageOfferReference, productPackageOffers],
  );

  const selectedOfferProductPackageMeta: ClubProductPackageMeta =
    useMemo(() => {
      if (selectedOffer) {
        const meta = clubProductPackageMetaReader.readMeta(
          selectedOffer.productPackage,
        );
        return meta;
      }
    }, [selectedOffer]);

  const isUnitOffer =
    selectedOfferProductPackageMeta?.diveAttributes?.divesCount === 1;

  const creditsAdditionalCount: number = useWatch({
    control,
    name: 'creditsAdditionalCount',
  });

  const consumedExternalCount: number = useWatch({
    control,
    name: 'consumedExternalCount',
  });

  const [updatedAssociatedBookingProductIds, setAssociatedBookingProductIds] =
    useState(initialAssociatedBookingProductIds);

  const includeOtherDiversDefaultValue = useMemo(() => {
    // ATTENTION: "sameTypeSameDiver" contient aussi les prestations des autres divers
    // qui sont assignées à ce forfait
    if (
      !!initialSessionsBillingResumes.sameTypeSameDiver.find(
        (x) =>
          x.purchaseParticipant.participant.bookingMemberFull.diver._id !==
          diver._id,
      )
    ) {
      // le pass est déjà associé à d'autres plongeurs, donc on coche l'option de partage par défaut
      return true;
    }
    return false;
  }, [diver._id, initialSessionsBillingResumes]);

  const [participantsConfig, setParticipantsConfig] =
    useState<DiverPurchaseEditorParticipantsConfig>({
      includeOtherDivers: includeOtherDiversDefaultValue,
      includeOtherTypes: false,
    });

  // FIXME: pas de memo ici? mais si on l'ajoute, y'a des effets infinis...
  const updatedSessionsBillingResumes =
    purchaseCommonSessionsBillingResumesBuilder.updatedSessionsBillingResumes({
      initialSessionsBillingResumes,
      participantsConfig,
      updatedAssociatedBookingProductIds,
    });

  const totalConsumedCount = useMemo(
    () =>
      creditsCountBuilder.buildTotalConsumedCountFromBillingResumes({
        sessionsBillingResumes: updatedSessionsBillingResumes,
        consumedExternalCount,
        clubSettings,
        countSuccessiveAsSingle: false,
      }),
    [consumedExternalCount, updatedSessionsBillingResumes, clubSettings],
  );

  const totalCreditsCount = useMemo(
    () =>
      isUnitOffer
        ? creditsInitialCount * 1 + creditsAdditionalCount
        : creditsInitialCount + creditsAdditionalCount,
    [creditsAdditionalCount, creditsInitialCount, isUnitOffer],
  );

  const [validityStatusManuallyDefined, setValidityStatusManuallyDefined] =
    useState(false);

  const updateValidityStatusIfNecessary = useCallback(
    ({
      validityStatus,
      consumedTotalCount,
      totalCreditsCount,
    }: {
      validityStatus: DiveServicePurchaseStatus;
      consumedTotalCount: number;
      totalCreditsCount: number;
    }) => {
      if (!validityStatusManuallyDefined) {
        let updatedValidityStatus = validityStatus;
        if (
          validityStatus === 'active' &&
          consumedTotalCount >= totalCreditsCount
        ) {
          updatedValidityStatus = 'completed';
        } else if (
          validityStatus === 'completed' &&
          consumedTotalCount < totalCreditsCount
        ) {
          updatedValidityStatus = 'active';
        }
        if (validityStatus !== updatedValidityStatus) {
          setValue('validityStatus', updatedValidityStatus);
        }
      }
    },
    [setValue, validityStatusManuallyDefined],
  );

  const { onClickAssignProducts } =
    useDiverPurchaseCommonEditorDialogOnClickAssignProducts({
      updatedAssociatedBookingProductIds,
      setAssociatedBookingProductIds,
    });

  const unitPrice: number = useWatch({
    control,
    name: 'unitPrice',
  });

  const securityUser = useAppSecurityUser();

  const isPaymentEnabled = useMemo(
    () => securityUser?.roles.includes('club-edit-participant-payment'),
    [securityUser.roles],
  );

  useEffect(() => {
    if (selectedOffer) {
      const { diveAttributes, productAttributes, trainingAttributes } =
        selectedOffer.productPackage;

      // if (
      //   selectedOffer.reference !== productPackageOfferReference
      // ) {
      //   // pour l'instant, un seul tarif supporté
      //   setValue('productPackageOfferReference', selectedOffer.productPackageOfferReference, { shouldValidate: true });
      // }
      if (mode === 'create') {
        // on desactive ça pour l'instant en cas de modification, car c'est exécuté aussi lors de l'arrivée sur la page, donc ça écrase les valeurs
        if (diveAttributes?.divesCount !== creditsInitialCount) {
          setValue('creditsInitialCount', diveAttributes?.divesCount, {
            shouldValidate: true,
          });
        }
      }
      if (securityUser?.roles.includes('club-edit-participant-payment')) {
        if (mode === 'create') {
          // on desactive ça pour l'instant en cas de modification, car c'est exécuté aussi lors de l'arrivée sur la page, donc ça écrase les valeurs
          if (
            selectedOffer.price !== null &&
            selectedOffer.price !== unitPrice
          ) {
            // pour l'instant, un seul tarif supporté
            setValue('unitPrice', selectedOffer.price, {
              shouldValidate: true,
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOffer, // only if selected offer changes
  ]);
  useEffect(() => {
    if (selectedOffer) {
      const updatedSessionsBillingResumes =
        purchaseCommonSessionsBillingResumesBuilder.updatedSessionsBillingResumes(
          {
            initialSessionsBillingResumes,
            participantsConfig,
            updatedAssociatedBookingProductIds,
          },
        );

      const isSuccessivePackage =
        selectedOffer?.productPackage?.diveAttributes?.divePriceType ===
        'successive';
      const countSuccessiveAsSingle =
        bookingPagePackageConsumedCounter.testIfCountSuccessiveAsSingle({
          clubSettings,
          isSuccessivePackage,
        });
      const totalConsumedCount =
        creditsCountBuilder.buildTotalConsumedCountFromBillingResumes({
          sessionsBillingResumes: updatedSessionsBillingResumes,
          consumedExternalCount,
          clubSettings,
          countSuccessiveAsSingle,
        });
      updateValidityStatusIfNecessary({
        consumedTotalCount: totalConsumedCount, // updatedAssociatedBookingProductIds.length,
        totalCreditsCount,
        validityStatus,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mode,
    selectedOffer,
    totalCreditsCount,
    updateValidityStatusIfNecessary,
    updatedAssociatedBookingProductIds.length,
    validityStatus,
  ]);

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: DiverPurchaseTrainingFormModel, event) => {
        if (!!selectedOffer) {
          const initialDiveBookingProducts =
            initialBookingProductBuilder.buildInitialDiveBookingProducts(
              initialSessionsBillingResumes,
            );

          const { result, hasChanges } =
            diverPurchaseTrainingEditorDialogUpdateBuilder.buildUpdateResult({
              diverId: diver._id,
              diveCenterId,
              clubReference,
              initialPurchasePackage: purchasePackage,
              selectedOffer,
              formValue,
              isDirty: formState.isDirty, // Note: il semble que formState n'est pas immuable, donc il faut avoir un observer sur formState.isDirty et pas juste sur formState
              state,
              updatedAssociatedBookingProductIds,
              initialDiveBookingProducts,
              isPaymentEnabled,
              billingResumes: initialSessionsBillingResumes.all,
              clubSettings,
            });

          if (!hasChanges) {
            // no changes
            setIsOpen(false);
            return;
          } else {
            onConfirm(result);
          }
        } else {
          appLogger.warn(
            '[DiverPurchaseTrainingEditorDialog] selectedOffer not set',
          );
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [
    handleSubmit,
    selectedOffer,
    initialSessionsBillingResumes,
    diver._id,
    diveCenterId,
    clubReference,
    purchasePackage,
    formState.isDirty,
    state,
    updatedAssociatedBookingProductIds,
    isPaymentEnabled,
    clubSettings,
    setIsOpen,
    onConfirm,
  ]);

  return !state.isOpen ? null : (
    <>
      <AppPageContainerWithFixedBars
        className={state.isOpen ? '' : 'hidden'}
        contentClassName="bg-white"
        footerBar={() => (
          <AppButtonsBar className="" hasChanges={true}>
            <>
              <AppButtonDeprecated
                icon={AppHeroIcons.actionCancel}
                buttonStyle="outline-transparent"
                size="normal"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Annuler
              </AppButtonDeprecated>
              {purchasePackage && (
                <AppButtonDeprecated
                  icon={AppHeroIcons.actionRemove}
                  buttonStyle="danger"
                  className="mx-5"
                  onClick={async () => {
                    const confirm = await confirmDialog.confirmPromise({
                      title: 'Supprimer cette formation',
                      message:
                        'Êtes-vous sûr de vouloir supprimer cette formation?',
                      type: 'remove',
                    });
                    if (confirm) {
                      const initialDiveBookingProducts =
                        initialBookingProductBuilder.buildInitialDiveBookingProducts(
                          initialSessionsBillingResumes,
                        );

                      const updatedProducts: AppEntityUpdatePatch[] =
                        productPurchaseStatusUpdator.removeProductsPurchasedStatus(
                          {
                            initialDiveBookingProducts,
                          },
                        );
                      onConfirm({
                        updatedProducts,
                        deletedPurchasePackageId: purchasePackage._id,
                      });
                    }
                  }}
                >
                  Supprimer
                </AppButtonDeprecated>
              )}
              <AppButtonDeprecated
                icon={AppHeroIcons.actionSave}
                buttonStyle="outline-transparent-primary"
                onClick={() => {
                  submitForm();
                }}
              >
                Confirmer
              </AppButtonDeprecated>
            </>
          </AppButtonsBar>
        )}
      >
        <AppPageBlock className="px-2">
          {diver && (
            <h2 className="text-center text-lg leading-6 p-2 font-medium text-app-blue">
              {nameFormatter.formatFullName(diver, {
                format: 'firstName-first',
              })}
            </h2>
          )}
          <div className={globalClasses.pageBlock}>
            {state.isOpen && initialFormValue && (
              <>
                <DiverPurchaseTrainingFormRHF
                  productPackageOffers={productPackageOffers}
                  diver={diver}
                  initialBookingId={initialFormValue?.bookingId}
                  form={form}
                  openExtraCostsFormPanel={openExtraCostsFormPanel}
                  setOpenExtraCostsFormPanel={setOpenExtraCostsFormPanel}
                  setFormValue={setValue}
                  isCreation={mode === 'create'}
                  setValidityStatusManuallyDefined={
                    setValidityStatusManuallyDefined
                  }
                  aggregatedData={state?.aggregatedData}
                />
              </>
            )}
            {initialSessionsBillingResumes.all.length > 0 && (
              <DiverBillingSessionsTable
                participantsConfig={participantsConfig}
                setParticipantsConfig={setParticipantsConfig}
                sessionsBillingResumesOtherDiversCount={
                  initialSessionsBillingResumes.otherTypeOtherDiver.length +
                  initialSessionsBillingResumes.sameTypeOtherDiver.length
                }
                sessionsBillingResumesOtherTypeCount={
                  initialSessionsBillingResumes.otherTypeOtherDiver.length +
                  initialSessionsBillingResumes.otherTypeSameDiver.length
                }
                totalConsumedCount={totalConsumedCount}
                totalCreditsCount={totalCreditsCount}
                className={'mb-2 sm:mb-5'}
                onClickAssignProducts={onClickAssignProducts}
                displayAssignButton={true}
                sessionsBillingResumes={updatedSessionsBillingResumes}
                onClickSession={undefined}
                onClickParticipant={undefined}
              />
            )}
            {extraCosts?.length > 0 && (
              <div className="my-2">
                <h2 className="text-left text-lg leading-6 p-2 font-medium text-app-primary">
                  Suppléments
                </h2>
                <ClubPurchasesExtraCostDescription
                  extraCosts={extraCosts}
                  className="pl-5 text-base"
                />
              </div>
            )}
            <DiverPurchaseTrainingFormInfoBox />
          </div>
        </AppPageBlock>
      </AppPageContainerWithFixedBars>
    </>
  );
};
