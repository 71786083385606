import {
  AppRichTextElementTextAlign,
  AppRichTextElementType,
  AppRichTextLeaf,
} from '@mabadive/app-common-model';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { AppRichTextSelectionFormat, AppSlateEditor } from '../model';

// const LIST_TYPES: AppRichTextElementType[] = ['numbered-list', 'bulleted-list'];

export const appRichTextEditorActionBarHelper = {
  isBlockTextAlignActive,
  isBlockTypeActive,
  toggleBlockType,
  setBlockAttribute,
  isMarkActive,
  toggleMarkFormat,
  setMarkValue,
};

function isBlockTextAlignActive({
  editor,
  textAlign,
  isDefault,
}: {
  editor: AppSlateEditor;
  textAlign?: AppRichTextElementTextAlign;
  isDefault?: boolean;
}) {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      !!textAlign &&
      (n.textAlign === textAlign || (isDefault && !n.textAlign)),
  });

  return !!match;
}

function isBlockTypeActive({
  editor,
  type,
}: {
  editor: AppSlateEditor;
  type?: AppRichTextElementType;
}) {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type,
  });

  return !!match;
}

function toggleBlockType({
  editor,
  type,
}: {
  editor: AppSlateEditor;
  type: AppRichTextElementType;
}) {
  const isActive = isBlockTypeActive({ editor, type });
  // const isList = LIST_TYPES.includes(format);

  // Transforms.unwrapNodes(editor, {
  //   match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && false,
  //   // LIST_TYPES.includes(n.type),
  //   split: true,
  // });
  // const newProperties: Partial<SlateElement> = {
  //   type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  // };
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'p' : type, // défault to 'p'
  };
  Transforms.setNodes(editor, newProperties);

  // if (!isActive /* && isList*/) {
  //   const block: AppRichTextElement = { type, children: [] };
  //   Transforms.wrapNodes(editor, block);
  // }
}

function setBlockAttribute({
  editor,
  textAlign,
}: {
  editor: AppSlateEditor;
  textAlign?: AppRichTextElementTextAlign;
}) {
  const newProperties: Partial<SlateElement> = {
    textAlign,
  };
  Transforms.setNodes(editor, newProperties);

  //  const block: AppRichTextElement = { type, children: [] };
  // Transforms.wrapNodes(editor, block);
}

function isMarkActive({
  marks,
  format,
  markValue,
}: {
  marks: Omit<AppRichTextLeaf, 'text'>;
  format: AppRichTextSelectionFormat;
  markValue: any;
}) {
  const value = marks ? (marks as any)[format] : undefined;
  if (marks) {
    if (markValue) {
      return value === markValue;
    } else {
      return value !== undefined;
    }
  }
  return false;
}

function toggleMarkFormat({
  editor,
  marks,
  format,
  markValue,
}: {
  editor: AppSlateEditor;
  marks: Omit<AppRichTextLeaf, 'text'>;
  format: AppRichTextSelectionFormat;
  markValue: any;
}) {
  const isActive = isMarkActive({ marks, format, markValue });

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, markValue);
  }
}

function setMarkValue({
  editor,
  format,
  marks,
  markValue,
}: {
  editor: AppSlateEditor;
  format: AppRichTextSelectionFormat;
  marks: Omit<AppRichTextLeaf, 'text'>;
  markValue: any;
}) {
  if (markValue !== undefined) {
    Editor.removeMark(editor, format);
    Editor.addMark(editor, format, markValue);
  } else {
    toggleMarkFormat({ editor, format, marks, markValue });
  }
}
