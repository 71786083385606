/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';

import {
  ClubProductPackageNameDetailsFormatProps,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ClubSettingsProductOffersFormModel } from '../_model';

export function useClubSettingsProductOffersFormOfferNameDetails({
  form,
  offerField,
  offersFieldArray,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
}) {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offersFieldArray.fields.findIndex(
    (x) => x.reference === offerField.reference,
  );

  const showDetails = offersFieldArray.fields.length === 1;

  const [
    label,
    firstDiveTrainingReference,
    divesCountInfinite,
    divesCount,
    namingConfiguration,
  ] = useWatch({
    control,
    name: [
      `offers.${i}.productPackage.label`,
      `offers.${i}.productPackage.diveAttributes.firstDiveTrainingReference`,
      `offers.${i}.productPackage.diveAttributes.divesCountInfinite`,
      `offers.${i}.productPackage.diveAttributes.divesCount`,
      `offers.${i}.productPackage.namingConfiguration`,
    ],
  });

  const nameDetails = useMemo(() => {
    if (offerField.productPackage.type === 'product') {
      // supplément
      const nameDetails = {
        prefix: '',
        name: offerField.productPackage.label,
      };
      return nameDetails;
    }

    const productPackage: ClubProductPackageNameDetailsFormatProps = {
      type: offerField.productPackage.type,
      label,
      namingConfiguration,
      diveAttributes: {
        ...offerField.productPackage?.diveAttributes,
        firstDiveTrainingReference,
        divesCountInfinite,
        divesCount,
      },
      trainingAttributes: offerField.productPackage?.trainingAttributes,
    };

    const publicSettings = clubResume.clubSettings.publicSettings;

    const nameDetails = productPackageFormatter.formatNameDetails(
      productPackage,
      { publicSettings, diveModesConf: clubSettings?.ui?.diveMode },
    );
    return nameDetails;
  }, [
    clubResume.clubSettings.publicSettings,
    clubSettings?.ui?.diveMode,
    divesCount,
    divesCountInfinite,
    firstDiveTrainingReference,
    label,
    namingConfiguration,
    offerField.productPackage?.diveAttributes,
    offerField.productPackage.label,
    offerField.productPackage?.trainingAttributes,
    offerField.productPackage.type,
  ]);

  return nameDetails;
}
