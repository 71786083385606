import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';

export function DateRangeLabel({
  beginDate,
  endDate,
  className,
}: {
  beginDate: Date;
  endDate: Date;
  className?: string;
}) {
  return beginDate && endDate ? (
    <div className={clsx('whitespace-nowrap', className)}>
      {dateService.formatUTC(beginDate, 'DD/MM/YYYY')}
      {' - '}
      {dateService.formatUTC(endDate, 'DD/MM/YYYY')}
    </div>
  ) : beginDate ? (
    <div className="">
      {' > '}
      {dateService.formatUTC(beginDate, 'DD/MM/YYYY')}
    </div>
  ) : endDate ? (
    <div className="y">
      {' < '}
      {dateService.formatUTC(endDate, 'DD/MM/YYYY')}
    </div>
  ) : null;
}
