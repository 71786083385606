/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveMode } from '@mabadive/app-common-model';
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';

export const ClubSettingsServicesActivitiesViewPanelDiveMode = ({
  diveMode,
}: {
  diveMode: DiveMode;
}) => {
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  return (
    <div
      className="w-4 h-4"
      style={{
        backgroundColor: clubSettings.ui?.diveMode?.[diveMode]?.color,
      }}
    ></div>
  );
};
