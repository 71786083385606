import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { graphqlClient, MQueryDescription } from 'src/_common-browser';
import { buildClubDiverCountGraphqlQuery } from '../../store-repository/divers';
import { accountGraphqlQueryBuilder } from '../AccountBaseData';
import { ClubBaseFetchResult } from './ClubBaseFetchResult.type';

export const clubBaseDataGraphqlFetcher = {
  fetch,
};

function fetch({
  userId,
  clubReference,
}: // minDate,
// minDateInclusive,
// maxDateExclusive,
{
  userId: string;
  clubReference: string;
  // minDate: Date;
  // minDateInclusive: Date;
  // maxDateExclusive: Date;
}): Observable<ClubBaseFetchResult> {
  // const minDateString = dateService.formatUTC(minDate, HASURA_DATE_ONLY_FORMAT);

  // group all graphql queries into one fetch

  // const maxDivers = 5;

  const queries: MQueryDescription<any>[] = [
    // buildClubGraphqlQuery({ userId, clubReference, minDateString }),
    // buildClubEventCollectionGraphqlQuery({
    //   clubReference,
    //   minDateInclusive,
    //   maxDateExclusive,
    // }),
    accountGraphqlQueryBuilder.buildAuthUser({ userId }),
    accountGraphqlQueryBuilder.buildAccountProfile({ userId }),
    buildClubDiverCountGraphqlQuery({ clubReference }),
    // buildClubDiverFullCollectionGraphqlQuery({ clubReference, maxDivers }),
    // TODO: ne plus pré-charger de sessions, ça ne sert à rien sauf à ralentir le démarrage
    // buildDiveSessionResumeFullCollectionGraphqlQuery({
    //   clubReference,
    //   diveCenterId,
    //   minDateInclusive,
    //   maxDateExclusive,
    // }),
    // buildClubResumeOneGraphqlQuery({
    //   clubReference,
    // }),
  ];

  return graphqlClient.query
    .runMany<ClubBaseFetchResult>(queries, {
      type: 'query',
      name: 'clubBaseData',
    })
    .pipe(
      map((res) => ({
        ...res,
        // minDate,
        // minDateInclusive,
        // maxDateExclusive,
      })),
      tap((res) => {
        if (appWebConfig.envId === 'dev') {
          appLogger.warn(
            '[clubBaseDataGraphqlFetcher] CLUB FETCH RESULT:',
            res,
          );
        }
      }),
      catchError((err) => {
        appLogger.warn('[clubBaseDataGraphqlFetcher] error fetching data', err);
        return throwError(err);
      }),
    );
}
