import { ClubParticipant, DiveSession } from '@mabadive/app-common-model';
import {
  diveModeColorClassBuilder,
  diveSessionServiceFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';

export const ParticipantDiveTypeExtra = ({
  participant,
  diveSession,
  className,
}: {
  participant: Pick<
    ClubParticipant,
    'bookingState' | 'diveMode' | 'autoSupervisedDetails'
  >;
  diveSession: Pick<DiveSession, 'status' | 'time' | 'diveTourSession2'>;
  isCancelledLineThrough?: boolean;
  className?: string;
}) => {
  const diveSessionStatus = useMemo(
    () =>
      participant && participant.bookingState.value === 'cancelled'
        ? 'cancelled'
        : diveSession?.status,
    [diveSession?.status, participant],
  );

  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;
  const clubPublicSettings = useClubSettings()?.publicSettings;

  const serviceExtraLabel = useMemo(() => {
    if (diveSessionStatus !== 'cancelled') {
      return diveSessionServiceFormatter.formatServiceExtraFromParticipant(
        { diver: undefined, participant },
        { clubReference, clubPublicSettings },
      );
    }
  }, [clubPublicSettings, clubReference, diveSessionStatus, participant]);

  const clubSettings = useClubSettings();
  const serviceExtraDiveModeColor = useMemo(() => {
    if (participant?.diveMode === 'autoSupervised') {
      return diveModeColorClassBuilder.getDiveModeColor(
        participant.autoSupervisedDetails?.diveMode,
        {
          clubSettings,
        },
      );
    }
  }, [
    clubSettings,
    participant.autoSupervisedDetails?.diveMode,
    participant?.diveMode,
  ]);
  return !serviceExtraLabel ? null : (
    <div
      className={clsx(
        'mt-0.5 mx-0.5 border border-white text-center px-0.5 py-0.5 text-white',
        className,
      )}
      style={
        serviceExtraDiveModeColor
          ? { backgroundColor: serviceExtraDiveModeColor }
          : undefined
      }
    >
      {serviceExtraLabel}
    </div>
  );
};
