/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferView,
  ClubPurchasePackage,
  ClubPurchasePackageMeta,
  ClubSettings,
  DiveMode,
} from '@mabadive/app-common-model';
import {
  DiverPurchaseOtherEditorDialogMatchingOfferCriteria,
  clubProductPackageMetaReader,
  clubProductPackageOfferMatcherBestOther,
  dateService,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  BillingTabDiveSessionBillingResume,
  DiverBookingPageAggregatedData,
} from '../../models';
import { defaultBookingBuilder } from '../../services';
import { bookingPagePackageConsumedCounter } from '../../services/02.update-state/services';
import { DiverPurchaseOtherFormModel } from './components';

export function useDiverPurchaseOtherEditorDialogInitialValue({
  diveCenterId,
  aggregatedData,
  initialAssociatedBookingProductIds,
  initialPurchasePackage,
  initialPurchasePackageMeta,
  divesNumber,
  createContext,
  productPackageOffers,
  mode,
  clubSettings,
  billingResumes,
}: {
  diveCenterId: string;
  aggregatedData: DiverBookingPageAggregatedData;
  initialAssociatedBookingProductIds: string[];
  initialPurchasePackage?: ClubPurchasePackage;
  initialPurchasePackageMeta: ClubPurchasePackageMeta;
  divesNumber: number;
  createContext: DiverPurchaseOtherEditorDialogMatchingOfferCriteria;
  productPackageOffers: ClubProductPackageOfferView[];
  mode: 'create' | 'edit';
  billingResumes: BillingTabDiveSessionBillingResume[];
  clubSettings: ClubSettings;
}): DiverPurchaseOtherFormModel {
  const clubResume = useClubResume();

  const initialFormValue: DiverPurchaseOtherFormModel = useMemo(() => {
    if (mode === 'create') {
      const defaultProductPackageOffer =
        clubProductPackageOfferMatcherBestOther.findBestMatchingOfferOther({
          criteria: createContext,
          productPackageOffers,
        });
      const meta = clubProductPackageMetaReader.readMeta(
        defaultProductPackageOffer?.productPackage,
      );

      const totalCreditsCount = divesNumber;

      const { consumedAppCount } =
        bookingPagePackageConsumedCounter.buildConsumedAppCountOnBuildUpdateResume(
          {
            clubSettings,
            billingResumes,
            assignedBookingProductsIds: initialAssociatedBookingProductIds,
            diverId: initialPurchasePackage?.diverId,
            countSuccessiveAsSingle: false,
          },
        );
      const consumedTotalCount = consumedAppCount;

      const initialBookingId = defaultBookingBuilder.getDefaultBookingId(
        aggregatedData.bookingResumesVisible,
        {
          diveCenterId,
          focusDiverId: aggregatedData.focus?.clubDiver._id,
        },
      );

      const iv: DiverPurchaseOtherFormModel = {
        productPackageOfferReference: defaultProductPackageOffer?.reference,
        unitPrice: defaultProductPackageOffer?.price,
        totalPriceThirdPartyCollect:
          defaultProductPackageOffer?.thirdPartyCollectPrice > 0 &&
          divesNumber > 0
            ? defaultProductPackageOffer?.thirdPartyCollectPrice * divesNumber
            : undefined,
        creditsInitialCount: meta?.productDefaultCreditsCount,
        unitQuantity: divesNumber,
        extraCosts: [],
        purchaseDate: dateService.getUTCDateWithoutTimeFromLocalTime(
          new Date(),
        ),
        validityStatus:
          consumedTotalCount >= totalCreditsCount ? 'completed' : 'active',
        creditsAdditionalCount: 0,
        consumedExternalCount: 0,
        bookingId: initialBookingId,
        billedByBookingAgencyId: undefined,
      };
      return iv;
    } else {
      const purchasePackage = initialPurchasePackage;
      const meta = initialPurchasePackageMeta;
      if (purchasePackage) {
        const credits = purchasePackage.credits;

        let productPackageOfferReference =
          purchasePackage.productPackageOffer.reference;

        if (!productPackageOfferReference) {
          // produit créé sans offre associée (par exemple lors de la migration v2, mais il y aura peut-être d'autres cas dans le futur?)
          productPackageOfferReference =
            clubProductPackageOfferMatcherBestOther.findBestMatchingOfferOther({
              criteria: {
                diveMode: meta?.diveMode as Extract<
                  DiveMode,
                  'observer' | 'first-dive'
                >,
                divesNumber: 1,
                diveSessionTheme:
                  purchasePackage.productPackageOffer?.productPackage
                    ?.salesCriteria?.diveSessionTheme,
                firstDiveTrainingReference:
                  purchasePackage.productPackageOffer?.productPackage
                    ?.diveAttributes?.firstDiveTrainingReference,
              },
              productPackageOffers,
            })?.reference;
        }

        const iv: DiverPurchaseOtherFormModel = {
          productPackageOfferReference,
          purchaseDate: purchasePackage.purchaseDate,
          unitPrice: purchasePackage.unitPrice,
          totalPriceThirdPartyCollect:
            purchasePackage.totalPriceThirdPartyCollect,
          extraCosts: purchasePackage.extraCosts ?? [],
          discountAmount: purchasePackage.discountAmount,
          unitQuantity: purchasePackage.unitQuantity,
          comment: purchasePackage.comment,
          validityStatus: purchasePackage.validityStatus,
          creditsAdditionalCount: credits.creditsAdditionalCount,
          creditsInitialCount: credits.creditsInitialCount,
          consumedExternalCount: credits.consumedExternalCount,
          bookingId: purchasePackage.bookingId,
          billedByBookingAgencyId: purchasePackage.billedByBookingAgencyId,
        };
        return iv;
      }
    }
  }, [
    mode,
    createContext,
    productPackageOffers,
    divesNumber,
    clubSettings,
    billingResumes,
    initialAssociatedBookingProductIds,
    initialPurchasePackage,
    aggregatedData.bookingResumesVisible,
    aggregatedData.focus?.clubDiver._id,
    diveCenterId,
    initialPurchasePackageMeta,
  ]);

  return initialFormValue;
}
