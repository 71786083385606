/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppRouter, useRedirect } from 'src/business/_core/data/hooks';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { AppButton } from '../../../../../../../../business/_core/modules/layout';
import { ClubSettingsSection } from '../../../../../../_core';
import { ClubSettingsOnlineBookingConfigPageLocalState } from '../../../useClubSettingsOnlineBookingConfigPageLocalState.hook';
import { ClubSettingsOnlineBookingPageBasePanel } from '../../_common';
import { ClubSettingsOnlineBookingEmailsViewPanel_01 } from './ClubSettingsOnlineBookingEmailsViewPanel_01';
import { ClubSettingsOnlineBookingEmailsViewPanel_02 } from './ClubSettingsOnlineBookingEmailsViewPanel_02';

export const ClubSettingsOnlineBookingEmailsViewPanel = ({
  localState,
}: {
  localState: ClubSettingsOnlineBookingConfigPageLocalState;
}) => {
  // document.title = 'Vente en ligne - configuration';
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const clubResume = useClubResume();
  const redirectTo = useRedirect();

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  const onlineBookingEnabled = clubSettings?.general?.onlineBooking?.enabled;

  const directAutoEnabled =
    clubSettings?.communication?.channels?.email?.directMode?.directAutoEnabled;

  const appRouter = useAppRouter();

  return (
    <ClubSettingsOnlineBookingPageBasePanel
      localState={localState}
      onClick={() => {
        state.editEnabled && dialogs.communication.open(selectedTab);
      }}
    >
      <div className="grid gap-4 md:gap-6">
        <ClubSettingsSection
          title="Communication par e-mail"
          comment="Informez vos clients de l'évolution de leur réservation"
        >
          {!directAutoEnabled ? (
            <div>
              <AppButton
                onClick={() => {
                  appRouter.navigate(
                    '/club/settings/customers/communication?m=view&t=email-auto',
                    {
                      cause: 'click-from-online-booking',
                    },
                  );
                }}
                color={'primary-outline-light'}
              >
                Activer l'envoi automatique 🚀
              </AppButton>
            </div>
          ) : (
            <>
              <ClubSettingsOnlineBookingEmailsViewPanel_01 />
              <ClubSettingsOnlineBookingEmailsViewPanel_02 />
            </>
          )}
        </ClubSettingsSection>
      </div>
    </ClubSettingsOnlineBookingPageBasePanel>
  );
};
