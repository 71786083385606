import {
  ClubPublicSettings,
  DiveCenterContact,
} from '@mabadive/app-common-model';
import {
  emailLinkBuilder,
  phoneLinkBuilder,
  smsLinkBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppButtonFreeColor } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export function DiveCenterBrandCardContactLinks({
  contact,
  clubPublicSettings,
  className = '',
}: {
  contact?: DiveCenterContact;
  clubPublicSettings: ClubPublicSettings;
  className?: string;
}) {
  const { appUiTheme } = clubPublicSettings;
  const { phoneNumber, phoneNumberLink } = useMemo(() => {
    const phoneNumber = smsLinkBuilder.cleanPhoneNumber(contact?.phoneNumber);
    const phoneNumberLink = phoneLinkBuilder.buildLink({
      phoneNumber,
    });
    return { phoneNumber, phoneNumberLink };
  }, [contact?.phoneNumber]);

  const { emailAddress, emailAddressLink } = useMemo(() => {
    const emailAddress = emailLinkBuilder.cleanEmailAddress(
      contact?.emailAddress,
    );
    const emailAddressLink = emailLinkBuilder.buildLink({
      to: [emailAddress],
    });
    return { emailAddress, emailAddressLink };
  }, [contact?.emailAddress]);

  return !contact ? null : (
    <div className={clsx(className)}>
      {contact.label && (
        <p className="font-medium text-gray-500">{contact.label}</p>
      )}
      <div className="grid gap-2">
        {phoneNumberLink && (
          <AppButtonFreeColor
            className="text-sm whitespace-nowrap"
            icon={AppHeroIcons.phone}
            href={phoneNumberLink}
            onClick={(e) => e.stopPropagation()}
            {...appUiTheme.buttonPrimary}
          >
            <span className="">
              {phoneLinkBuilder.formatPhoneNumber(phoneNumber)}
            </span>
          </AppButtonFreeColor>
        )}
        {emailAddressLink && (
          <AppButtonFreeColor
            className="text-sm whitespace-nowrap"
            icon={AppHeroIcons.mail}
            href={emailAddressLink}
            onClick={(e) => e.stopPropagation()}
            {...appUiTheme.buttonPrimary}
          >
            <span className="">{emailAddress}</span>
          </AppButtonFreeColor>
        )}
      </div>
    </div>
  );
}
