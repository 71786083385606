import clsx from 'clsx';
import React from 'react';
import { useAppBookingViewAuth } from 'src/business/auth/services';
import { BookingTabModel } from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { BookingMassiveEditorSessionsTableRegisterAddMemberButton } from '../BookingMassiveEditorDialog/BookingMassiveEditorSessionsTable/BookingMassiveEditorSessionsTableRegisterAddMemberButton';
import { BookingMassiveEditorSessionsTableRegisterAddSessionButton } from '../BookingMassiveEditorDialog/BookingMassiveEditorSessionsTable/BookingMassiveEditorSessionsTableRegisterAddSessionButton';

export function DiverBookingCardGlobalAddButtons({
  actions,
  bookingTabModel,
  isReverseTheme,
  className,
}: {
  bookingTabModel: BookingTabModel;
  actions: DiverBookingPageActions;
  isReverseTheme: boolean;
  className?: string;
}) {
  const booking = bookingTabModel.aggregatedBooking.booking;
  const { isUniqueMember, isSameLastName, futureSessionsCount } =
    bookingTabModel.meta;

  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: bookingTabModel.bookingDiveCenterId,
    isSharedBooking: bookingTabModel.isSharedBooking,
  });

  const { openSessionSelector } = actions;

  return bookingViewAuth.edit ? (
    <div
      className={clsx(
        'flex gap-4 items-center',
        isUniqueMember ? 'mb-2' : 'mb-4',
        className,
      )}
    >
      <BookingMassiveEditorSessionsTableRegisterAddSessionButton
        className="flex-grow max-w-[20rem]"
        isReverseTheme={isReverseTheme}
        onClickAddSession={() =>
          openSessionSelector({
            bookingId: booking._id,
          })
        }
      />
      <BookingMassiveEditorSessionsTableRegisterAddMemberButton
        className="flex-grow max-w-[20rem]"
        isReverseTheme={isReverseTheme}
        onClickAddMember={() =>
          actions.openDiverSelector({
            bookingId: bookingTabModel.bookingId,
          })
        }
      />
    </div>
  ) : null;
}
