import { ClubPurchasePayment } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { Fragment, useCallback, useState } from 'react';
import { paymentPlatformClient } from 'src/business/_core/data/app-operation';
import { AppButton, AppLoader } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { uiStore } from 'src/business/_core/store';
import {
  useAppSecurityUserClubAuthorizations,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { useAppCurrency } from 'src/pages/_components/options';

export function PurchasePaymentOnlineDetails({
  purchasePayment,
  className,
}: {
  purchasePayment: Pick<
    ClubPurchasePayment,
    | 'amount'
    | 'currencyIsoCode'
    | 'usedCurrencyAmount'
    | 'usedCurrencyIsoCode'
    | 'paymentState'
    | 'onlinePlatformDetails'
  >;
  className?: string;
}) {
  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();
  const details = purchasePayment.onlinePlatformDetails;
  const paymentIntents = details?.paymentIntents;
  const billingCurrency = useAppCurrency(purchasePayment.currencyIsoCode);
  const usedCurrency = useAppCurrency(purchasePayment.usedCurrencyIsoCode);
  const az = useAppSecurityUserClubAuthorizations();
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const buildStripeDashboardUrl = useCallback(
    ({ nativePaymentIntentId }: { nativePaymentIntentId: string }) => {
      if (details?.platformEnv === 'live') {
        return `https://dashboard.stripe.com/payments/${nativePaymentIntentId}`;
      } else {
        return `https://dashboard.stripe.com/test/payments/${nativePaymentIntentId}`;
      }
    },
    [details?.platformEnv],
  );

  return details ? (
    <div className={clsx('grid 4', className)}>
      <div className="flex gap-4 flex-wrap">
        <div>
          <h3 className="data-label text-gray-600 font-bold max-w-full">
            Paiement en ligne Stripe
          </h3>
          <div className="data-value text-app-blue max-w-full truncate font-bold">
            Stripe
          </div>
        </div>

        {paymentIntents?.totalAmountReceived > 0 && (
          <div>
            <h3 className="data-label text-gray-600 font-bold max-w-full">
              Montant
            </h3>
            <div className="data-value text-app-blue max-w-full truncate">
              <AppPriceLabel
                amount={paymentIntents?.totalAmountReceived}
                mainCurrency={usedCurrency}
              />
            </div>
          </div>
        )}
        {purchasePayment.paymentState === 'authorized' &&
          paymentIntents?.totalAmountCapturable > 0 && (
            <>
              <div>
                <h3 className="data-label text-gray-600 font-bold max-w-full">
                  Montant à accepter
                </h3>
                <div className="data-value text-app-blue max-w-full truncate">
                  <AppPriceLabel
                    amount={paymentIntents?.totalAmountCapturable}
                    mainCurrency={usedCurrency}
                  />
                </div>
              </div>
              {paymentIntents.nextCaptureExpirationDate && (
                <div>
                  <h3 className="data-label text-gray-600 font-bold max-w-full">
                    Date d'expiration
                  </h3>
                  <div className="data-value text-app-blue max-w-full truncate">
                    {dateService.formatUTC(
                      paymentIntents.nextCaptureExpirationDate,
                      'DD/MM/YYYY HH:mm',
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        {(details.paymentIntents.items ?? []).map((item, i) => (
          <Fragment key={i}>
            {item.nativePaymentIntentId && (
              <div>
                <h3 className="data-label text-gray-600 font-bold max-w-full">
                  Tableau de bord
                </h3>
                <div className="data-value text-app-blue max-w-full truncate">
                  <a
                    className="underline"
                    href={buildStripeDashboardUrl({
                      nativePaymentIntentId: item.nativePaymentIntentId,
                    })}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <AppIconsAction.linkExternal className="inline h-4 w-4" />{' '}
                    Stripe
                  </a>
                </div>
              </div>
            )}
            {item.receiptUrl && (
              <div>
                <h3 className="data-label text-gray-600 font-bold max-w-full">
                  Reçu
                </h3>
                <div className="data-value text-app-blue max-w-full truncate">
                  <a
                    className="underline"
                    href={item.receiptUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppIconsAction.linkExternal className="inline h-4 w-4" />{' '}
                    Stripe
                  </a>
                </div>
              </div>
            )}
            <div className="flex-grow"></div>
            {isSuperAdmin && (
              <AppButton
                size="normal"
                icon={AppIconsAction.refresh}
                color="primary-outline-light"
                disabled={!!operationInProgressMessage}
                onClick={async (e) => {
                  e.stopPropagation();
                  setOperationInProgressMessage('Rafraichissement en cours...');
                  try {
                    await paymentPlatformClient.refreshPaymentIntent({
                      extPaymentIntentId: item._id,
                    });
                    uiStore.snackbarMessage.set({
                      type: 'success',
                      content: 'Paiement actualisé!',
                    });
                  } catch (err) {
                    uiStore.snackbarMessage.set({
                      type: 'error',
                      content:
                        'Erreur innatendue lors du rafraichissement du paiement!',
                    });
                  } finally {
                    setOperationInProgressMessage(undefined);
                  }
                }}
              >
                Actualiser
              </AppButton>
            )}
            {az?.edit?.participant?.paymentOnlineCapture &&
              purchasePayment.paymentState === 'authorized' &&
              item.amountCapturable > 0 && (
                <AppButton
                  size="normal"
                  icon={AppIconsAction.confirm}
                  color="danger-outline-light"
                  disabled={!!operationInProgressMessage}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (
                      await confirmDialog.confirmPromise({
                        title: 'Accepter le paiement',
                        message:
                          'Êtes-vous sûr de vouloir accepter ce paiement?',
                        type: 'noYesDanger',
                      })
                    ) {
                      setOperationInProgressMessage(
                        'Acceptation du paiement en cours...',
                      );
                      try {
                        await paymentPlatformClient.capturePaymentIntent({
                          extPaymentIntentId: item._id,
                        });
                        uiStore.snackbarMessage.set({
                          type: 'success',
                          content: 'Paiement accepté!',
                        });
                      } catch (err) {
                        uiStore.snackbarMessage.set({
                          type: 'error',
                          content:
                            "Erreur innatendue lors de l'acceptation du paiement!",
                        });
                      } finally {
                        setOperationInProgressMessage(undefined);
                      }
                    }
                  }}
                >
                  Accepter
                </AppButton>
              )}
          </Fragment>
        ))}
      </div>
      {operationInProgressMessage && (
        <AppLoader type="loading" message={operationInProgressMessage} />
      )}
    </div>
  ) : null;
}
