/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubBoat, ProMultiOperationPayload } from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import {
  AppButtonsBar,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import useRouter from 'use-react-router';
import { ClubBoatEditForm } from './ClubBoatEditForm/ClubBoatEditForm';
import { clubBoatFormResultBuilder } from './ClubBoatEditForm/clubBoatFormResultBuilder.service';

import { clubSettingsDiveCenterUrlBuilder } from 'src/pages/SE-settings/SE-01_dive-center/clubSettingsDiveCenterUrlBuilder.service';
import {
  ClubBoatEditPageContext,
  ClubBoatFormLocalState,
  ClubResumeClubBoatFormModel,
} from './model';
import { useClubBoatFormLocalState } from './useClubBoatFormLocalState.hook';

export const ClubBoatEditPage = ({
  navigationContext,
}: {
  navigationContext: ClubBoatEditPageContext;
}) => {
  const { match, history } =
    useRouter<{
      clubBoatId: string;
    }>();

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const params = new URLSearchParams(window.location.search);

  const defaultClubBoatGroupId = params.get('defaultClubBoatGroupId') as string;

  const clubBoatId = match.params.clubBoatId;

  const localState: ClubBoatFormLocalState = useClubBoatFormLocalState({
    mode: navigationContext.mode,
    clubBoatId,
  });

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const { mode, clubBoat, form, initialFormValue, showIsDefault } = localState;
  const { handleSubmit } = form;

  const back = useCallback(() => {
    return redirectTo(clubSettingsDiveCenterUrlBuilder.boats.list());
  }, [redirectTo]);

  useMemo(() => {
    if (mode === 'edit' && !clubBoat) {
      appLogger.warn('Invalid clubBoatId: redirect');
      back();
    }
  }, [back, mode, clubBoat]);

  const persistChanges = useCallback(() => {
    handleSubmit((formValue: ClubResumeClubBoatFormModel, event) => {
      if (mode === 'edit') {
        const value = clubBoatFormResultBuilder.buildResult(formValue);
        const patchOperations = jsonPatcher.compareObjects(
          initialFormValue,
          value,
          {
            attributesToReplaceFully: [],
            replaceDeleteByNullValue: true,
          },
        );
        if (patchOperations.length) {
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'edit boatd',
              bookingIdsToClean: [],
            });
          payload.updatedClubBoats = [
            {
              pk: clubBoat?._id,
              patchOperations,
            },
          ];
          clubMassiveUpdatorClient.update(payload).then(() => {
            back();
          });
        } else {
          back();
        }
      } else if (mode === 'create') {
        const value = clubBoatFormResultBuilder.buildResult(formValue);
        const payload: ProMultiOperationPayload =
          clubMassiveUpdatorClient.createEmptyPayload({
            logContext: 'create boat',
            bookingIdsToClean: [],
          });
        payload.newClubBoats = [
          repositoryEntityBuilder.buildEntity<ClubBoat>({
            ...value,
            clubReference,
            diveCenterId,
          }),
        ];
        clubMassiveUpdatorClient.update(payload).then(() => {
          back();
        });
      }
    })();
  }, [
    handleSubmit,
    mode,
    initialFormValue,
    clubBoat?._id,
    back,
    clubReference,
    diveCenterId,
  ]);

  return (
    <form
      className="w-full"
      onSubmit={(e) => {
        persistChanges();
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-white"
        footerBar={() => (
          <AppButtonsBar className="" hasChanges={form.formState.isDirty}>
            <>
              <AppButton
                className="my-2 w-full uppercase"
                fullWidth={true}
                color={'primary-outline-light'}
                size="normal"
                icon={AppHeroIcons.actionCancel}
                onClick={() => back()}
              >
                Annuler
              </AppButton>
              <AppButton
                type="submit"
                className="my-2 w-full uppercase"
                fullWidth={true}
                color={'primary-outline-light'}
                size="normal"
                icon={AppHeroIcons.actionSave}
              >
                Enregistrer
              </AppButton>
            </>
          </AppButtonsBar>
        )}
      >
        <div className="app-card p-4 ">
          <ClubBoatEditForm localState={localState} />
        </div>
      </AppPageContainerWithFixedBars>
    </form>
  );
};
