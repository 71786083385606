import {
  ClubProductPackageMeta,
  ClubProductPackageOfferView,
} from '@mabadive/app-common-model';
import { clubProductPackageMetaReader } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { DiverPurchasePlanEditorDialogState } from '../../models';
import { bookingPagePackageConsumedCounter } from '../../services/02.update-state/services';
import {
  creditsCountBuilder,
  diverPurchasePackageBuilder,
  purchaseCommonSessionsBillingResumesBuilder,
} from '../DiverPurchaseCommonEditorDialog';
import { DiverPurchasePriceDetails } from '../DiverPurchaseCommonEditorDialog/model';
import { DiverPurchasePlanDialogLocalStateContext } from './useDiverPurchasePlanDialogLocalStateContext.service';

import { DiverPurchasePlanDialogLocalStateFormContext } from './useDiverPurchasePlanDialogLocalStateFormContext.service';
import { DiverPurchasePlanDialogLocalStateInitialData } from './useDiverPurchasePlanDialogLocalStateInitialData.service';
import { DiverPurchasePlanDialogLocalStateUseStates } from './useDiverPurchasePlanDialogLocalStateUseStates.service';

export function useDiverPurchasePlanDialogLocalStateData({
  useStates,
  initialData,
  localContext,
  formContext,
  inputState,
}: {
  useStates: DiverPurchasePlanDialogLocalStateUseStates;
  initialData: DiverPurchasePlanDialogLocalStateInitialData;
  localContext: DiverPurchasePlanDialogLocalStateContext;
  formContext: DiverPurchasePlanDialogLocalStateFormContext;
  inputState: DiverPurchasePlanEditorDialogState;
}) {
  const {
    states,
    updatedAssociatedBookingProductIds,
    setAssociatedBookingProductIds,
    selectedPlanGroupData,
    setSelectedPlanGroupData,
    participantsConfig,
    setParticipantsConfig,
    pageLoaded,
    setPageLoaded,
    openExtraCostsFormPanel,
    setOpenExtraCostsFormPanel,
  } = useStates;

  const {
    successiveDivesCount,
    productPackageOffersCriteria,
    productPackageOffers,
    planGroups,
    defaultProductPackageOffer,
    initialAssociatedBookingProductIds,
    initialFormValue,
    aggregatedData,
  } = initialData;

  const {
    clubResume,
    diveCenterResume,
    securityUser,
    clubSettings,
    diveCenterId,
    clubReference,
    isPaymentEnabled,
  } = localContext;

  const {
    diver,
    purchasePackage,
    createContext,
    mode,
    sessionsBillingResumes: initialSessionsBillingResumes,
  } = inputState;

  const { form, formWatch } = formContext;

  const {
    unitPrice,
    creditsInitialCount,
    validityStatus,
    unitQuantity,
    productPackageOfferReference,
    creditsAdditionalCount,
    consumedExternalCount,
    discountAmount,
    extraCosts,
  } = formWatch;

  const selectedOffer: ClubProductPackageOfferView = useMemo(
    () =>
      productPackageOfferReference
        ? productPackageOffers.find(
            (o) => o.reference === productPackageOfferReference,
          )
        : undefined,
    [productPackageOfferReference, productPackageOffers],
  );

  const selectedOfferProductPackageMeta: ClubProductPackageMeta =
    useMemo(() => {
      if (selectedOffer) {
        const meta = clubProductPackageMetaReader.readMeta(
          selectedOffer.productPackage,
        );
        return meta;
      }
    }, [selectedOffer]);
  const isUnitOffer =
    selectedOfferProductPackageMeta?.diveAttributes?.divesCount === 1;

  const includeOtherDiversDefaultValue = useMemo(() => {
    // ATTENTION: "sameTypeSameDiver" contient aussi les prestations des autres divers
    // qui sont assignées à ce forfait
    if (
      !!initialSessionsBillingResumes.sameTypeSameDiver.find(
        (x) =>
          x.purchaseParticipant.participant.bookingMemberFull.diver._id !==
          diver._id,
      )
    ) {
      // le pass est déjà associé à d'autres plongeurs, donc on coche l'option de partage par défaut
      return true;
    }
    return false;
  }, [diver._id, initialSessionsBillingResumes]);

  // FIXME: pas de memo ici? mais si on l'ajoute, y'a des effets infinis...
  const updatedSessionsBillingResumes =
    purchaseCommonSessionsBillingResumesBuilder.updatedSessionsBillingResumes({
      initialSessionsBillingResumes,
      participantsConfig,
      updatedAssociatedBookingProductIds,
    });

  const totalConsumedCount = useMemo(() => {
    const isSuccessivePackage =
      selectedOffer?.productPackage?.diveAttributes?.divePriceType ===
      'successive';
    const countSuccessiveAsSingle =
      bookingPagePackageConsumedCounter.testIfCountSuccessiveAsSingle({
        clubSettings,
        isSuccessivePackage,
      });
    return creditsCountBuilder.buildTotalConsumedCountFromBillingResumes({
      sessionsBillingResumes: updatedSessionsBillingResumes,
      consumedExternalCount,
      clubSettings,
      countSuccessiveAsSingle,
    });
  }, [
    selectedOffer?.productPackage?.diveAttributes?.divePriceType,
    clubSettings,
    updatedSessionsBillingResumes,
    consumedExternalCount,
  ]);

  const totalCreditsCount = useMemo(
    () =>
      isUnitOffer
        ? creditsInitialCount * unitQuantity + creditsAdditionalCount
        : creditsInitialCount + creditsAdditionalCount,
    [creditsAdditionalCount, creditsInitialCount, isUnitOffer, unitQuantity],
  );

  const { filteredProductPackageOffers, selectedPlanGroup } =
    selectedPlanGroupData;

  const extraCostUnitDiveDefaultMultiplier = useMemo(() => {
    if (unitQuantity > 0) {
      return unitQuantity;
    }
    return 1;
  }, [unitQuantity]);

  const priceDetails: DiverPurchasePriceDetails = useMemo(() => {
    if (openExtraCostsFormPanel || !openExtraCostsFormPanel) {
      // on utilise openExtraCostsFormPanel pour être sûr de mettre à jour le total à la fermeture du dialog (sinon, y'a un coup de retard quand on édite!)
      return diverPurchasePackageBuilder.buildTotalPrice({
        isPaymentEnabled,
        isUnitOffer,
        unitPrice,
        unitQuantity,
        discountAmount,
        extraCosts,
      });
    }
  }, [
    discountAmount,
    extraCosts,
    isPaymentEnabled,
    isUnitOffer,
    openExtraCostsFormPanel,
    unitPrice,
    unitQuantity,
  ]);
  const divesCountExtensionMode =
    selectedOfferProductPackageMeta?.diveAttributes?.divePackageAttributes
      ?.divesCountExtensionMode;

  // TODO: prochaine étape du refactoring: structurer les données
  const data = {
    ...initialData,
    purchasePackage,
    diver,
    priceDetails,
    initialFormValue,
    filteredProductPackageOffers,
    planGroups,
    selectedOffer,
    selectedOfferProductPackageMeta,
    participantsConfig,
    initialSessionsBillingResumes,
    totalConsumedCount,
    totalCreditsCount,
    updatedSessionsBillingResumes,
    isUnitOffer,
    extraCostUnitDiveDefaultMultiplier,
    divesCountExtensionMode,
    aggregatedData,
  };

  return data;
}

export type DiverPurchasePlanDialogLocalStateData = ReturnType<
  typeof useDiverPurchasePlanDialogLocalStateData
>;
