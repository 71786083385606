import React from 'react';
import { Switch } from 'react-router-dom';
import { of } from 'rxjs';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import { AppAuth } from 'src/business/auth/services';
import useRouter from 'use-react-router';
import { ClubSettingsPlanningAssistantPage } from './ClubSettingsPlanningAssistant';
import { ClubSettingsPlanningEditPage } from './ClubSettingsPlanningEditPage';
import { ClubSettingsPlanningEditPageNavigationContext } from './ClubSettingsPlanningEditPage/model';
import { ClubSettingsPlanningViewPage } from './ClubSettingsPlanningViewPage';

export const ClubSettingsDiveCenterPlanningRoutes = React.memo(
  function ClubSettingsSecurityRoutesMemo() {
    const { match } = useRouter();
    return (
      <Switch>
        <AppRoute
          path={`${match.url}`}
          exact
          component={ClubSettingsPlanningViewPage}
        />
        <AppRoute
          path={`${match.url}/create`}
          exact
          navigationContext={
            { mode: 'create' } as ClubSettingsPlanningEditPageNavigationContext
          }
          component={ClubSettingsPlanningEditPage}
        />
        <AppRoute
          path={`${match.url}/edit-session-booking/:periodConfigId`}
          exact
          navigationContext={
            {
              mode: 'edit-session-booking',
            } as ClubSettingsPlanningEditPageNavigationContext
          }
          component={ClubSettingsPlanningEditPage}
        />
        <AppRoute
          path={`${match.url}/edit/:periodConfigId`}
          exact
          navigationContext={
            { mode: 'edit' } as ClubSettingsPlanningEditPageNavigationContext
          }
          component={ClubSettingsPlanningEditPage}
        />
        <AppRoute
          path={`${match.url}/clone/:periodConfigId`}
          exact
          navigationContext={
            { mode: 'clone' } as ClubSettingsPlanningEditPageNavigationContext
          }
          component={ClubSettingsPlanningEditPage}
        />
        <AppRoute
          path={`${match.url}/assistant/:periodConfigId`}
          exact
          component={ClubSettingsPlanningAssistantPage}
        />
        {/* default route */}
        <AppRoute
          path={`${match.url}/`}
          redirectTo={({ appAuth }) =>
            of(redirectToDefaultRoute({ appAuth, matchUrl: match.url }))
          }
        />
      </Switch>
    );
  },
);

function redirectToDefaultRoute({
  appAuth,
  matchUrl,
}: {
  appAuth: AppAuth;
  matchUrl: string;
}): string {
  if (appAuth) {
    return `${matchUrl}/planning`;
  }
  return '/';
}
