import gql from 'graphql-tag';
import { libLogger } from '../../libLogger.service';
import { GraphqlClientQueryType } from '../apollo/GraphqlClientQueryType.type';
import { GraphqlQuery } from './GraphqlQuery.model';

export const graphqlClientQueryBuilder = {
  buildOne,
  buildMany,
};

export type QueryDescription<P> = {
  queryName: string;
  where?: string;
  args?: string;
  orderBy?: string;
  returnAttributes: { [K in keyof P]?: boolean } | string;
  returnName?: string;
  limit?: number;
  returnType: 'first' | 'all';
  buildResult?: <P>(x: any, query: MQueryDescription<any>) => P;
};

export type MQueryDescription<P> = QueryDescription<P> & {
  returnName?: string;
};

function buildMany<T>(
  queries: MQueryDescription<any>[],
  {
    type,
  }: {
    type: 'query'; // can not run multiple subscriptions (single root element required: https://spec.graphql.org/draft/#sec-Single-root-field)
  } = {
    type: 'query',
  },
): GraphqlQuery<T> {
  const multipleQueriesString: string[] = queries.map((query) =>
    buildSingleQueryString(query),
  );

  const queryString = `{
    ${multipleQueriesString.join('\n')}
  }`;

  // libLogger.warn(
  //   '[graphql][graphqlClientQueryBuilder.buildMany] ===> queryString (type="%s") :',
  //   type,
  //   queryString,
  // );

  return {
    queryString: gql`
      ${queryString}
    `,
    mapResult: (x) =>
      queries.reduce((acc, query) => {
        const result = query.buildResult
          ? query.buildResult(x, query)
          : buildResult<T>(x, query);

        (acc as any)[query.returnName] = result;
        return acc;
      }, {} as T),
  };
}

function buildResult<T>(x: any, query: MQueryDescription<any>): T {
  // libLogger.warn('[graphql][graphqlClientQueryBuilder.buildResult] x :', x);

  const res = x[query.returnName];
  let result;
  if (query.returnType === 'first') {
    if (res && (res as unknown as T[]).length) {
      result = (res as unknown as T[])[0];
    }
  } else {
    result = res;
  }
  return result;
}

function buildOne<T, P>(
  query: QueryDescription<P>,
  {
    type,
  }: {
    type: GraphqlClientQueryType;
  } = {
    type: 'query',
  },
): GraphqlQuery<T> {
  const singleQueryString = buildSingleQueryString(query);

  const queryString = `${type === 'subscription' ? type : ''} {
    ${singleQueryString}
  }`;

  libLogger.debug(
    '[graphql][graphqlClientQueryBuilder.buildOne]===> queryString (type="%s") :',
    type,
    queryString,
  );
  try {
    return {
      queryString: gql`
        ${queryString}
      `,
      mapResult: (x) => buildResult<T>(x, query),
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.info('queryString:', queryString);
    // eslint-disable-next-line no-console
    console.error(err);
    throw err;
  }
}

function buildSingleQueryString(query: QueryDescription<any>): string {
  if (!query.returnName) {
    query.returnName = query.queryName;
  }

  const queryParametersGroups = [];
  if (query.args) {
    queryParametersGroups.push(`args: ${query.args}`);
  }
  if (query.where) {
    queryParametersGroups.push(`where: ${query.where}`);
  }
  if (query.orderBy) {
    queryParametersGroups.push(`order_by: ${query.orderBy}`);
  }
  if (query.returnType === 'first') {
    query.limit = 1;
  }
  if (query.limit) {
    queryParametersGroups.push(`limit: ${query.limit}`);
  }
  const queryParameters = queryParametersGroups.length
    ? `(${queryParametersGroups.join(', ')})`
    : '';
  // const queryReturnAttributes = typeof (query.returnAttributes) === 'string' ? query.returnAttributes as string : Object.keys(query.returnAttributes).join(' ');
  const queryReturnAttributes =
    typeof query.returnAttributes === 'string'
      ? (query.returnAttributes as string)
      : buildQueryReturnAttributes(query.returnAttributes);

  const singleQueryString = `${query.returnName}:${query.queryName}${queryParameters} {
    ${queryReturnAttributes}
  }`;
  return singleQueryString;
}

function buildQueryReturnAttributes<P>(
  returnAttributes: {
    [K in keyof P]?: boolean;
  },
): string {
  return Object.keys(returnAttributes)
    .filter((key) => (returnAttributes as any)[key])
    .join(' ');
}
