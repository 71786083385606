import {
  AppEntityUpdatePatch,
  DiveServicePurchaseStatus,
} from '@mabadive/app-common-model';
import {
  ClubProductPackageOfferViewPlanGroup,
  DiverPurchasePlanEditorDialogMatchingOfferCriteria,
  clubProductPackageOfferGroupMatcher,
  clubProductPackageOfferMatcherBestPlan,
} from '@mabadive/app-common-services';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  DiverPurchaseCommonEditorDialogResult,
  DiverPurchasePlanEditorDialogState,
} from '../../models';
import {
  initialBookingProductBuilder,
  productPurchaseStatusUpdator,
  useDiverPurchaseCommonEditorDialogOnClickAssignProducts,
} from '../DiverPurchaseCommonEditorDialog';
import { DiverPurchaseCommonFormModel } from '../DiverPurchaseCommonEditorDialog/model';
import {
  DiverPurchaseCommonLocalStateActions,
  useDiverPurchaseCommonLocalStateActions,
} from '../DiverPurchaseCommonEditorDialog/useDiverPurchaseCommonLocalStateActions.service';
import { DiverPurchasePlanFormModel } from './components';
import { diverPurchasePlanEditorDialogUpdateBuilder } from './diverPurchasePlanEditorDialogUpdateBuilder.service';
import { DiverPurchasePlanDialogLocalStateContext } from './useDiverPurchasePlanDialogLocalStateContext.service';
import { DiverPurchasePlanDialogLocalStateData } from './useDiverPurchasePlanDialogLocalStateData.service';

import { appLogger } from 'src/business/_core/modules/root/logger';
import { DiverPurchasePlanDialogLocalStateFormContext } from './useDiverPurchasePlanDialogLocalStateFormContext.service';
import { DiverPurchasePlanDialogLocalStateInitialData } from './useDiverPurchasePlanDialogLocalStateInitialData.service';
import { DiverPurchasePlanDialogLocalStateUseStates } from './useDiverPurchasePlanDialogLocalStateUseStates.service';

export function useDiverPurchasePlanDialogLocalStateActions({
  initialData,
  data,
  localContext,
  formContext,
  useStates,
  inputState,
  setInputState,
  onConfirm,
}: {
  initialData: DiverPurchasePlanDialogLocalStateInitialData;
  data: DiverPurchasePlanDialogLocalStateData;
  localContext: DiverPurchasePlanDialogLocalStateContext;
  formContext: DiverPurchasePlanDialogLocalStateFormContext;
  inputState: DiverPurchasePlanEditorDialogState;
  useStates: DiverPurchasePlanDialogLocalStateUseStates;
  setInputState: React.Dispatch<
    React.SetStateAction<DiverPurchasePlanEditorDialogState>
  >;
  onConfirm?: (result: DiverPurchaseCommonEditorDialogResult) => void;
}) {
  const {
    states,
    updatedAssociatedBookingProductIds,
    setAssociatedBookingProductIds,
    selectedPlanGroupData,
    setSelectedPlanGroupData,
    participantsConfig,
    setParticipantsConfig,
    pageLoaded,
    setPageLoaded,
    openExtraCostsFormPanel,
    setOpenExtraCostsFormPanel,
  } = useStates;

  const {
    clubResume,
    diveCenterResume,
    securityUser,
    clubSettings,
    diveCenterId,
    clubReference,
    isPaymentEnabled,
  } = localContext;

  const {
    diver,
    purchasePackage,
    createContext,
    mode,
    sessionsBillingResumes: initialSessionsBillingResumes,
  } = inputState;

  const { form, formWatch } = formContext;

  const { handleSubmit, setValue, formState } = form;

  const {
    unitPrice,
    creditsInitialCount,
    validityStatus,
    unitQuantity,
    productPackageOfferReference,
    creditsAdditionalCount,
    consumedExternalCount,
    discountAmount,
    extraCosts,
  } = formWatch;

  const {
    productPackageOffers,
    priceDetails,
    selectedOffer,
    selectedOfferProductPackageMeta,
    isUnitOffer,
  } = data;

  const closeDialog = useCallback(() => {
    setInputState({
      ...inputState,
      isOpen: false,
    });
  }, [setInputState, inputState]);

  const commonActions: DiverPurchaseCommonLocalStateActions =
    useDiverPurchaseCommonLocalStateActions({
      form: form as unknown as UseFormReturn<DiverPurchaseCommonFormModel, any>,
      priceDetails,
    });

  const [validityStatusManuallyDefined, setValidityStatusManuallyDefined] =
    useState(false);

  const updateValidityStatusIfNecessary = useCallback(
    ({
      validityStatus,
      consumedTotalCount,
      totalCreditsCount,
    }: {
      validityStatus: DiveServicePurchaseStatus;
      consumedTotalCount: number;
      totalCreditsCount: number;
    }) => {
      if (!validityStatusManuallyDefined) {
        let updatedValidityStatus = validityStatus;
        if (
          validityStatus === 'active' &&
          consumedTotalCount >= totalCreditsCount
        ) {
          updatedValidityStatus = 'completed';
        } else if (
          validityStatus === 'completed' &&
          consumedTotalCount < totalCreditsCount
        ) {
          updatedValidityStatus = 'active';
        }
        if (validityStatus !== updatedValidityStatus) {
          setValue('validityStatus', updatedValidityStatus);
        }
      }
    },
    [validityStatusManuallyDefined, setValue],
  );

  const { onClickAssignProducts } =
    useDiverPurchaseCommonEditorDialogOnClickAssignProducts({
      updatedAssociatedBookingProductIds,
      setAssociatedBookingProductIds,
    });

  const onChangePlanGroup = useCallback(
    ({
      planGroup: selectedPlanGroup,
    }: {
      planGroup: ClubProductPackageOfferViewPlanGroup;
    }) => {
      const filteredProductPackageOffers =
        clubProductPackageOfferGroupMatcher.getMatchingOffersPlan({
          offers: productPackageOffers,
          group: selectedPlanGroup,
        });
      setSelectedPlanGroupData({
        selectedPlanGroup,
        filteredProductPackageOffers,
      });
      const criteria: DiverPurchasePlanEditorDialogMatchingOfferCriteria = {
        divesCount: selectedPlanGroup?.divesCount,
        successiveDivesCount: selectedPlanGroup?.successiveDivesCount,
        isInstructor: createContext?.isInstructor,
        specialDiveType: createContext?.specialDiveType,
        diveSessionTheme: createContext?.diveSessionTheme,
      };
      if (createContext) {
        // TODO: prendre en compte l'âge même quand on est en édition
        criteria.age = createContext?.age;
      }
      if (selectedOffer) {
        const diveAttributes = selectedOffer.productPackage.diveAttributes;
        criteria.equipment = diveAttributes.equipment;
        criteria.supervision = diveAttributes.supervision;
      }
      const offer =
        clubProductPackageOfferMatcherBestPlan.findBestMatchingOfferPlan({
          criteria,
          productPackageOffers: filteredProductPackageOffers,
        });
      setValue('productPackageOfferReference', offer?.reference, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      if (
        selectedPlanGroup?.minDivesCount > 1 &&
        selectedPlanGroup?.minDivesCount > unitQuantity
      ) {
        setValue('unitQuantity', selectedPlanGroup?.minDivesCount, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    },
    [
      createContext,
      productPackageOffers,
      selectedOffer,
      setSelectedPlanGroupData,
      setValue,
      unitQuantity,
    ],
  );

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: DiverPurchasePlanFormModel, event) => {
        if (!!selectedOffer) {
          const initialDiveBookingProducts =
            initialBookingProductBuilder.buildInitialDiveBookingProducts(
              initialSessionsBillingResumes,
            );

          const { result, hasChanges } =
            diverPurchasePlanEditorDialogUpdateBuilder.buildUpdateResult({
              diverId: diver._id,
              clubReference,
              diveCenterId,
              initialPurchasePackage: purchasePackage,
              selectedOffer,
              selectedOfferProductPackageMeta,
              formValue,
              isDirty: formState.isDirty, // Note: il semble que formState n'est pas immuable, donc il faut avoir un observer sur formState.isDirty et pas juste sur formState
              state: inputState,
              updatedAssociatedBookingProductIds,
              initialDiveBookingProducts,
              isPaymentEnabled,
              billingResumes: initialSessionsBillingResumes.all,
              clubSettings,
            });

          if (!hasChanges) {
            // no changes
            closeDialog();
            return;
          } else {
            onConfirm(result);
          }
        } else {
          appLogger.warn(
            '[useDiverPurchasePlanDialogLocalStateActions] Offer not selected',
          );
          form.setError('productPackageOfferReference', {
            message: 'Veuillez sélectionner une option',
            type: 'required',
          });
        }
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.log('xxx submitForm error', err);
      },
    )();
  }, [
    handleSubmit,
    selectedOffer,
    initialSessionsBillingResumes,
    diver._id,
    clubReference,
    diveCenterId,
    purchasePackage,
    selectedOfferProductPackageMeta,
    formState.isDirty,
    inputState,
    updatedAssociatedBookingProductIds,
    isPaymentEnabled,
    clubSettings,
    closeDialog,
    onConfirm,
    form,
  ]);

  const confirmDeletePackage = useCallback(async () => {
    const confirm = await confirmDialog.confirmPromise({
      title: 'Supprimer cette prestation',
      message: 'Êtes-vous sûr de vouloir supprimer cette prestation?',
      type: 'remove',
    });
    if (confirm) {
      // supprimer l'état "facturé" sur tous les produits liés
      const initialDiveBookingProducts =
        initialBookingProductBuilder.buildInitialDiveBookingProducts(
          initialSessionsBillingResumes,
        );

      const updatedProducts: AppEntityUpdatePatch[] =
        productPurchaseStatusUpdator.removeProductsPurchasedStatus({
          initialDiveBookingProducts,
        });
      onConfirm({
        updatedProducts,
        deletedPurchasePackageId: purchasePackage?._id,
      });
    }
  }, [initialSessionsBillingResumes, onConfirm, purchasePackage?._id]);

  const divesCountExtensionMode =
    selectedOfferProductPackageMeta?.diveAttributes?.divePackageAttributes
      ?.divesCountExtensionMode;

  const onCreditsInitialCountChanges = useCallback(
    (value: number | string) => {
      const count = parseInt(value as string, 10);
      if (
        count > 0 &&
        !isUnitOffer &&
        divesCountExtensionMode === 'ratio-rounded' &&
        selectedOffer
      ) {
        const unitPrice = Math.round(
          (selectedOffer?.price /
            selectedOfferProductPackageMeta?.diveAttributes?.divesCount) *
            count,
        );
        setValue('unitPrice', unitPrice);
      }
    },
    [
      divesCountExtensionMode,
      isUnitOffer,
      selectedOffer,
      selectedOfferProductPackageMeta?.diveAttributes?.divesCount,
      setValue,
    ],
  );

  const actions = {
    ...commonActions,
    confirmDeletePackage,
    closeDialog,
    submitForm,
    onChangePlanGroup,
    setParticipantsConfig,
    onClickAssignProducts,
    onCreditsInitialCountChanges,
    updateValidityStatusIfNecessary,
    setValidityStatusManuallyDefined,
  };

  return actions;
}

export type DiverPurchasePlanDialogLocalStateActions = ReturnType<
  typeof useDiverPurchasePlanDialogLocalStateActions
>;
