/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingStatus,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import React, { FC, useCallback } from 'react';
import {
  AppFixedButtonsBarDeprecatedOVER,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppButtonDeprecated } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { bookingDetailsFormBuilder } from '../../../_services';
import { CreateBookingInitialPlanResumePanel } from '../BO-03_CreateBookingInitialPlanPage/panels';
import {
  BookingGroupAndJourneyEditDialogFormModel,
  BookingGroupAndJourneyEditDialogInputState,
} from './model';
import { useBookingGroupAndJourneyEditDialogLocalState } from './useBookingGroupAndJourneyEditDialogLocalState.hook';

export const BookingGroupAndJourneyEditDialog: FC<{
  state: BookingGroupAndJourneyEditDialogInputState;
  setState: React.Dispatch<
    React.SetStateAction<BookingGroupAndJourneyEditDialogInputState>
  >;
  onConfirm?: (
    patch: AppEntityUpdatePatch,
    specialStatusUpdate: BookingStatus,
  ) => void;
}> = ({ onConfirm, state: inputState, setState: setInputState }) => {
  const setIsOpen = useCallback(
    (isOpen:boolean) => {
      setInputState({
        ...inputState,
        isOpen,
      });
    },
    [setInputState, inputState],
  );

  const clubResume = useClubResume();

  const localState = useBookingGroupAndJourneyEditDialogLocalState({
    initialBooking: inputState.booking,
  });

  const { form } = localState;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValueInput: BookingGroupAndJourneyEditDialogFormModel, event) => {
        const formValue = bookingDetailsFormBuilder.fixForm(formValueInput);
        if (localState.hasFormChanges) {
          const patchOperations = jsonPatcher.compareObjects(
            inputState.booking,
            {
              ...inputState.booking,
              ...formValue,
            },
            {
              // else, value won't be deleted by typeorm
              // https://github.com/typeorm/typeorm/issues/2934
              replaceDeleteByNullValue: true,
            },
          );
          if (patchOperations.length) {
            const patch: AppEntityUpdatePatch = {
              pk: inputState.booking._id,
              patchOperations,
            };
            let specialStatusUpdate: BookingStatus;
            if (
              inputState.booking &&
              formValue.bookingStatus !== inputState.booking.bookingStatus
            ) {
              if (
                formValue.bookingStatus === 'cancelled' ||
                formValue.bookingStatus === 'confirmed'
              ) {
                specialStatusUpdate = formValue.bookingStatus;
              }
            }
            onConfirm(patch, specialStatusUpdate);
          }
          setIsOpen(false);
        } else {
          // no changes
          setIsOpen(false);
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [
    handleSubmit,
    inputState.booking,
    localState.hasFormChanges,
    onConfirm,
    setIsOpen,
  ]);

  return !inputState.isOpen ? null : (
    <>
      <div>
        <AppPageContentContainer className="bg-white app-px-content">
          <CreateBookingInitialPlanResumePanel
            form={form as any}
            bookingMembersFull={inputState.bookingMembersFull}
          />
          <AppFixedButtonsBarDeprecatedOVER hasChanges={true}>
            <>
              <AppButtonDeprecated
                icon={AppHeroIcons.actionCancel}
                buttonStyle="outline-transparent"
                size="normal"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Annuler
              </AppButtonDeprecated>
              <AppButtonDeprecated
                icon={AppHeroIcons.actionSave}
                buttonStyle="outline-transparent-primary"
                onClick={() => {
                  submitForm();
                }}
              >
                Confirmer
              </AppButtonDeprecated>
            </>
          </AppFixedButtonsBarDeprecatedOVER>
        </AppPageContentContainer>
      </div>
    </>
  );
};
