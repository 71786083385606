import { ProStatsFetchResults } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';
import { DashboardMetricTitleWithValue } from '../../DashboardMetricTitleWithValue';
import { DashboardMetricValueCard } from '../../DashboardMetricValueCard';

export const DashboardReportPageGlobalActivityBookings = ({
  bookingsOverPeriod: results,
  title,
}: {
  bookingsOverPeriod: ProStatsFetchResults['bookingsOverPeriod'];

  title: string;
}) => {
  const bookingsOverPeriod = results?.data;
  return !bookingsOverPeriod ? null : (
    <>
      <div className={clsx('max-w-xxx-disabled', 'app-card app-p-content')}>
        <DashboardMetricTitleWithValue
          label={title}
          description="Nouvelles réservations"
          valueClassName="bg-gray-600 text-white"
          icon={EntitiesIcons.booking}
          value={bookingsOverPeriod.bookingParticipantsCount}
        />
        <div className="mt-5 grid grid-cols-2 gap-x-2 gap-y-1">
          <DashboardMetricValueCard
            label={'Réservations'}
            value={bookingsOverPeriod.bookingsCount}
            unitIcon={EntitiesIcons.booking}
          />

          <DashboardMetricValueCard
            label={'Participants'}
            value={bookingsOverPeriod.bookingsMembersCount}
            unitIcon={EntitiesIcons.divers}
          />
          <DashboardMetricValueCard
            label={'Prestations'}
            value={bookingsOverPeriod.bookingParticipantsCount}
            valueExtraLabel={
              bookingsOverPeriod.bookingParticipantsCancelledCount > 0
                ? `+ ${bookingsOverPeriod.bookingParticipantsCancelledCount}`
                : undefined
            }
            valueExtraLabelClassName="text-red-400 text-xs"
            unitIcon={EntitiesIcons.participants}
          />
        </div>
      </div>
    </>
  );
};
