import { PRO_AppDocResume } from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  dateService,
} from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { useClubSettings } from '../../../../../../../data/hooks';
import {
  BookingParticipantEditorResult,
  BookingResumeParticipantForSession,
  DiverBookingPageUpdateState,
} from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
import { defaultParametersUpdator } from '../state-updators';
export function useDiverBookingPageUpdateDiverAndParticipant({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  const clubSettings = useClubSettings();
  return useCallback(
    async ({
      result,
      bookingParticipants,
      optimizePatches = false,
      applyDefaultConfigChanges,
      logContext,
    }: {
      result: BookingParticipantEditorResult;
      bookingParticipants: BookingResumeParticipantForSession[];
      optimizePatches?: boolean;
      applyDefaultConfigChanges: boolean;
      logContext: string;
    }) => {
      const { bookingId, changes } = result;

      const {
        updatedDivers,
        updatedBookingMembers,
        newBookingProducts,
        updatedBookingProducts,
        newBookingSessionParticipants,
        updatedBookingSessionParticipants,
        newClubParticipants,
        updatedClubParticipants,
        appDocs,
      } = changes;

      let updateStateLocal = updateState;

      if (appDocs) {
        if (appDocs.created?.length) {
          const docResumesChanges = changeDescriptorManager.createMany(
            appDocs.created as PRO_AppDocResume[],
            {
              changeDescriptors: updateStateLocal.docResumesChanges,
            },
          );
          updateStateLocal = {
            ...updateStateLocal,
            docResumesChanges,
          };
        }
        if (appDocs.updated?.length) {
          const docResumesChanges = changeDescriptorManager.updateMany(
            appDocs.updated,
            {
              changeDescriptors: updateStateLocal.docResumesChanges,
              optimizePatches,
            },
          );
          updateStateLocal = {
            ...updateStateLocal,
            docResumesChanges,
          };
        }
        if (appDocs.deletedIds?.length) {
          const docResumesChanges = changeDescriptorManager.deleteMany(
            appDocs.deletedIds,
            {
              changeDescriptors: updateStateLocal.docResumesChanges,
            },
          );
          updateStateLocal = {
            ...updateStateLocal,
            docResumesChanges,
          };
        }
      }
      if (updatedDivers?.length) {
        const diversChanges = changeDescriptorManager.updateMany(
          updatedDivers,
          {
            changeDescriptors: updateStateLocal.diversChanges,
            optimizePatches,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          diversChanges,
        };
      }

      if (updatedBookingMembers?.length) {
        const bookingMembersChanges = changeDescriptorManager.updateMany(
          updatedBookingMembers,
          {
            changeDescriptors: updateStateLocal.bookingMembersChanges,
            optimizePatches,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          bookingMembersChanges,
        };
      }
      if (newBookingProducts?.length) {
        const bookingProductsChanges = changeDescriptorManager.createMany(
          newBookingProducts,
          {
            changeDescriptors: updateStateLocal.bookingProductsChanges,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          bookingProductsChanges,
        };
      }
      if (updatedBookingProducts?.length) {
        const bookingProductsChanges = changeDescriptorManager.updateMany(
          updatedBookingProducts,
          {
            changeDescriptors: updateStateLocal.bookingProductsChanges,
            optimizePatches,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          bookingProductsChanges,
        };
      }
      if (newBookingSessionParticipants?.length) {
        const bookingSessionParticipantsChanges =
          changeDescriptorManager.createMany(newBookingSessionParticipants, {
            changeDescriptors:
              updateStateLocal.bookingSessionParticipantsChanges,
          });
        updateStateLocal = {
          ...updateStateLocal,
          bookingSessionParticipantsChanges,
        };
      }
      if (updatedBookingSessionParticipants?.length) {
        const bookingSessionParticipantsChanges =
          changeDescriptorManager.updateMany(
            updatedBookingSessionParticipants,
            {
              changeDescriptors:
                updateStateLocal.bookingSessionParticipantsChanges,
              optimizePatches,
            },
          );
        updateStateLocal = {
          ...updateStateLocal,
          bookingSessionParticipantsChanges,
        };
      }
      if (newClubParticipants?.length) {
        const clubParticipantsChanges = changeDescriptorManager.createMany(
          newClubParticipants,
          {
            changeDescriptors: updateStateLocal.clubParticipantsChanges,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          clubParticipantsChanges,
        };
      }
      if (updatedClubParticipants?.length) {
        const clubParticipantsChanges = changeDescriptorManager.updateMany(
          updatedClubParticipants,
          {
            changeDescriptors: updateStateLocal.clubParticipantsChanges,
            optimizePatches,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          clubParticipantsChanges,
        };
      }

      setUpdateState(updateStateLocal, {
        action: logContext,
      });

      const today = dateService.getUTCDateWithoutTime(new Date());

      const { bookingParticipantsPast, bookingParticipantsFuture } = (
        bookingParticipants ?? []
      )
        .filter((x) => !!x.bookingParticipantFull)
        .reduce(
          (acc, x) => {
            if (
              dateService.isBefore(
                today,
                x.bookingParticipantFull?.diveSession?.time,
              )
            ) {
              acc.bookingParticipantsFuture.push(x);
            } else {
              acc.bookingParticipantsPast.push(x);
            }
            return acc;
          },
          {
            bookingParticipantsPast: [],
            bookingParticipantsFuture: [],
          } as {
            bookingParticipantsPast: BookingResumeParticipantForSession[];
            bookingParticipantsFuture: BookingResumeParticipantForSession[];
          },
        );

      if (applyDefaultConfigChanges && bookingParticipants) {
        const defaultDiveMode =
          result.aggregated.finalDiver.defaultDiveConfig?.diveMode;

        await defaultParametersUpdator.applyDefaultDiveConfigChanges({
          defaultDiveMode,
          initialDiver: result.initialDiver,
          finalDiver: result.aggregated.finalDiver,
          updateStateLocal,
          bookingMemberId: result.bookingMemberId,
          bookingParticipants: bookingParticipantsFuture,
          setUpdateState,
          dontConfirmToApplyChanges: false,
          clubSettings,
        });
      }
      // if (updatedParticipant && updatedDiver) {
      //   const { bookingProduct, bookingSessionParticipant, clubParticipant } = updatedParticipant;
      //   const bookingId = bookingProduct.bookingId;
      //   if (mode === 'create-participant') {
      //     setUpdateState(
      //       diverBookingPageUpdateStateManager.addNewParticipantAndUpdateDiverToState({
      //         updateState: updateStateLocal,
      //         bookingId,
      //         newBookingProductParticipant: updatedParticipant,
      //         updatedDiver,
      //         updatedBookingMember,
      //       }),
      //     );
      //   } else {
      //     setUpdateState(
      //       diverBookingPageUpdateStateManager.updateParticipantAndDiverToState({
      //         updateState: updateStateLocal,
      //         bookingId,
      //         updatedParticipant,
      //         updatedDiver,
      //         updatedBookingMember,
      //       }),
      //     );
      //   }
      // }
    },
    [clubSettings, setUpdateState, updateState],
  );
}
