import { useCallback, useState } from 'react';
import { EcommerceProductArticleEditorInputProps } from './useEcommerceProductArticleEditorPanelLocalState.hook';

export function useEcommerceProductArticleEditorDialogClient() {
  const [state, setState] = useState<{
    isOpen: boolean;
    props?: EcommerceProductArticleEditorInputProps;
  }>({
    isOpen: false,
  });

  const openCreateProductArticle = useCallback(
    (
      input: Pick<
        EcommerceProductArticleEditorInputProps,
        'defaultValue' | 'product' | 'productBookletPage'
      >,
    ) => {
      const props: EcommerceProductArticleEditorInputProps = {
        ...input,
        mode: 'create',
      };
      setState({
        isOpen: true,
        props,
      });
    },
    [],
  );
  const openEditProductArticle = useCallback(
    (
      input: Pick<
        EcommerceProductArticleEditorInputProps,
        'editValue' | 'product' | 'productBookletPage'
      >,
    ) => {
      const props: EcommerceProductArticleEditorInputProps = {
        ...input,
        mode: 'edit',
      };
      setState({
        isOpen: true,
        props,
      });
    },
    [],
  );
  const closeDialog = useCallback(() => {
    setState({
      isOpen: false,
      props: undefined,
    });
  }, []);

  return {
    ...state,
    openCreateProductArticle,
    openEditProductArticle,
    closeDialog,
  };
}

export type EcommerceProductArticleEditorDialogClient = ReturnType<
  typeof useEcommerceProductArticleEditorDialogClient
>;
