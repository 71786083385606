import React from 'react';
import { dateServiceCore } from 'src/stories/services';
import { AppHackableCalendarVM } from '../model';
import { AppHackableCalendarYearsNavPanel } from './AppHackableCalendarYearsNavPanel';
import {
  AppHackableCalendarYearsPanelVM,
  AppHackableCalendarYearsPanelVMYear,
} from './model';
import { useAppHackableCalendarYearsPanelVM } from './useAppHackableCalendarYearsPanelVM.hook';

import clsx from 'clsx';

export const AppHackableCalendarYearsPanel = (props: AppHackableCalendarVM) => {
  const vm: AppHackableCalendarYearsPanelVM =
    useAppHackableCalendarYearsPanelVM(props);

  const selectYear = (y: AppHackableCalendarYearsPanelVMYear) => {
    props.setViewDate(y.date);
    props.setView('weeks');
  };

  return (
    <div>
      <AppHackableCalendarYearsNavPanel
        {...props}
        onClickBack={() => {
          vm.setLastYear(dateServiceCore.addYear(vm.lastYear, -30));
        }}
        onClickForward={() => {
          vm.setLastYear(dateServiceCore.addYear(vm.lastYear, 30));
        }}
      />
      <div className="isolate mt-16 grid grid-cols-5 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {vm.years.map((y, yearIdx) => (
          <button
            disabled={!y.isSelectableYear}
            onClick={() => {
              if (y.isSelectableYear) {
                selectYear(y);
              }
            }}
            key={y.year}
            type="button"
            className={clsx(
              'py-0.5 p-1 focus:z-10 font-semibold',
              y.isSelectableYear
                ? 'cursor-pointer hover:bg-gray-200'
                : 'cursor-not-allowed',
              yearIdx === 0 && 'rounded-tl-lg',
              yearIdx === 6 && 'rounded-tr-lg',
              yearIdx === vm.years.length - 7 && 'rounded-bl-lg',
              yearIdx === vm.years.length - 1 && 'rounded-br-lg',
              y.isCurrentTodayYear
                ? y.isSelectableYear
                  ? 'text-sky-500'
                  : 'text-sky-300'
                : y.isSelectableYear
                ? 'text-gray-500'
                : 'text-gray-300',
              y.isCurrentViewYear
                ? 'bg-white border-2 border-sky-500'
                : 'bg-gray-50',
            )}
          >
            <time
              dateTime={`${y.year}`}
              className={clsx(
                'mx-auto flex  h-8 w-8 items-center justify-center rounded-full',
              )}
            >
              {y.year}
            </time>
          </button>
        ))}
      </div>
    </div>
  );
};
