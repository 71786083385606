/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';
import { ClubSettingsServicesTrainingViewPanelTrainingOrgs } from './ClubSettingsServicesTrainingViewPanelTrainingOrgs';
import { ClubSettingsServicesPageBasePanel } from '../../../_common';
import { ClubSettingsServicesViewPanelOffersPrices } from '../../../_common/offers-prices/view';

export const ClubSettingsServicesTrainingViewPanel = ({
  localState,
}: {
  localState: ClubSettingsServicesPageLocalState;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const mainCurrency = useAppCurrencyMain();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const generalSettings = clubSettings.general;

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  return (
    <ClubSettingsServicesPageBasePanel localState={localState}>
      <div className="grid gap-4 md:gap-6">
        <ClubSettingsServicesTrainingViewPanelTrainingOrgs
          localState={localState}
        />
        <ClubSettingsServicesViewPanelOffersPrices
          localState={localState}
          contextMode="training"
        />
      </div>
    </ClubSettingsServicesPageBasePanel>
  );
};
