/* eslint-disable @typescript-eslint/no-unused-vars */
import { colorGenerator } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AppIconsUI } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { SettingsCategory, SettingsCategoryPage } from '../../_core';
import { ClubSettingsCategoryCardPageBadge } from './ClubSettingsCategoryCardPageBadge';

export const ClubSettingsCategoryCardPageButton = ({
  page,
  category,
  as = 'div',
  className,
}: {
  page: SettingsCategoryPage;
  category: SettingsCategory;
  as?: React.ElementType;
  className?: string;
}) => {
  const Icon = page.icon ?? category.icon;

  const categoryColorLight = useMemo(
    () => colorGenerator.lighten(category.color, 0.5),
    [category.color],
  );
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  return React.createElement(
    as,
    {
      className: clsx('group/page cursor-pointer', className),
    },
    <>
      <Link to={page.url} className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex min-w-0 flex-1 items-center truncate">
            <div className="flex-shrink-0">
              <Icon
                className={clsx(
                  'h-8 w-8  md:h-9 md:w-9 lg:h-10 lg:w-10 2xl:h-12 2xl:w-12 opacity-80 group-hover/page:opacity-100',
                )}
                style={{
                  color: category.color,
                }}
              />
            </div>
            <div className="flex-grow px-4 flex gap-2 justify-between text-left">
              <div>
                <p
                  className="truncate text-base font-bold uppercase opacity-80 group-hover/page:opacity-100"
                  style={{
                    color: category.color,
                  }}
                >
                  {page.label}
                </p>
                {page?.description && (
                  <p
                    className="mt-2 flex items-center text-sm"
                    style={{
                      color: category.color,
                    }}
                  >
                    {/* <AppHeroIcons.comment
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    /> */}
                    <span className="truncate">{page?.description}</span>
                  </p>
                )}
              </div>
              <div className="hidden md:block">
                <div>
                  {isMultiDiveCenters && page?.isDiveCenterSpecificConfig && (
                    <DiveCenterProBadge
                      className=""
                      diveCenter={diveCenterResume}
                    />
                  )}
                  {page.badge && (
                    <ClubSettingsCategoryCardPageBadge
                      category={category}
                      page={page}
                      badge={page.badge}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <AppIconsUI.navRight
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </>,
  );
};
