/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubSettingsPlanningPeriodConfigDailyTour } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  AppInputTimeHoursMinutesRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl';
import { useDiveSitesOptions } from 'src/pages/_components/options/useDiveSitesOptions.hook';
import { ClubSettingsPlanningAssistantFormModel } from '../model';

export const ClubSettingsPlanningAssistantDailyTourForm = ({
  formDailyTour,
  diveTourDefIndex,
  dailyToursFieldIndex,
  form,
  className = '',
  onClickDelete,
}: {
  diveTourDefIndex: number;
  dailyToursFieldIndex: number;
  formDailyTour: ClubSettingsPlanningPeriodConfigDailyTour;
  form: UseFormReturn<ClubSettingsPlanningAssistantFormModel, any>;
  className?: string;
  onClickDelete: () => void;
}) => {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const dailyTourControlBaseName =
    `assistant.diveToursDef.${diveTourDefIndex}.dates.dailyTours.${dailyToursFieldIndex}` as const;

  const [diveSiteId] = useWatch({
    control,
    name: [`${dailyTourControlBaseName}.session1.diveSiteId`],
  });

  const diveSiteOptions = useDiveSitesOptions({
    currentDiveSitesIds: [diveSiteId],
  });

  return (
    <div className={clsx(' ', className)}>
      <h3 className="text-xs font-bold text-gray-500 uppercase">
        Sortie N°{dailyToursFieldIndex + 1}
      </h3>
      <div className={clsx('flex gap-2 sm:flex-col sm:gap-0')}>
        <AppFormControlRHF_Deprecated
          className=""
          label={'Heure'}
          control={control}
          name={`${dailyTourControlBaseName}.session1.dayTime` as const}
          required={false}
          renderComponent={(props) => (
            <AppInputTimeHoursMinutesRHF {...props} />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Site de plongée"
          control={control}
          name={`${dailyTourControlBaseName}.session1.diveSiteId` as const}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF {...props} options={diveSiteOptions} />
          )}
        />
      </div>
    </div>
  );
};
