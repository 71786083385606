import React, { useCallback } from 'react';
import { DiverPurchasePlanEditorDialogState } from '../../models';

export function useOpenCreatePlanDialog({
  setPlanEditorDialogState,
}: {
  setPlanEditorDialogState: React.Dispatch<
    React.SetStateAction<DiverPurchasePlanEditorDialogState>
  >;
}) {
  return useCallback(
    ({
      diver,
      createContext,
      sessionsBillingResumes,
      aggregatedData,
    }: Pick<
      DiverPurchasePlanEditorDialogState,
      'diver' | 'createContext' | 'sessionsBillingResumes' | 'aggregatedData'
    >) =>
      setPlanEditorDialogState({
        isOpen: true,
        mode: 'create',
        diver,
        createContext,
        sessionsBillingResumes,
        aggregatedData,
      }),
    [setPlanEditorDialogState],
  );
}
