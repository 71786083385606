/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubDiver,
  ClubProductPackageOfferView,
  DiveServiceOrganizationReference,
  DiveTrainingReference,
} from '@mabadive/app-common-model';
import {
  clubDiveServiceOfferViewTransformer,
  diveServiceTrainingFormatter,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import { Box, FormControl, FormLabel } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { UseFormReturn, UseFormSetValue, useWatch } from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useAppCurrencyMain,
  useBookingAgenciesOptions,
  useDiveServicePurchaseStatusesOptions,
} from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { AppPackageExtraCostFormDialog } from 'src/stories/components/05-form-panel/AppPackageExtraCostFormPanel/AppPackageExtraCostFormDialog';
import { AppPackageExtraCostFormModel } from 'src/stories/components/05-form-panel/AppPackageExtraCostFormPanel/model';
import { DiverBookingPageAggregatedData } from '../../../../models';
import { diverPurchasePackageBuilder } from '../../../DiverPurchaseCommonEditorDialog';
import { DiverPurchaseCommonFormBookingAndAgency } from '../../../DiverPurchaseCommonEditorDialog/components';
import { DiverPurchasePriceDetails } from '../../../DiverPurchaseCommonEditorDialog/model';
import {
  DiverPurchaseCommonLocalStateActions,
  useDiverPurchaseCommonLocalStateActions,
} from '../../../DiverPurchaseCommonEditorDialog/useDiverPurchaseCommonLocalStateActions.service';
import { DiverPurchaseTrainingFormModel } from './DiverPurchaseTrainingFormModel.type';

export const DiverPurchaseTrainingFormRHF = ({
  isCreation,
  aggregatedData,
  diver,
  form,
  setFormValue,
  productPackageOffers,
  openExtraCostsFormPanel,
  setOpenExtraCostsFormPanel,
  setValidityStatusManuallyDefined,
  initialBookingId,
}: {
  isCreation: boolean;
  aggregatedData: DiverBookingPageAggregatedData;
  diver: Pick<ClubDiver, 'residentType'>;
  form: UseFormReturn<DiverPurchaseTrainingFormModel>;
  setFormValue: UseFormSetValue<DiverPurchaseTrainingFormModel>;
  productPackageOffers: ClubProductPackageOfferView[];
  openExtraCostsFormPanel: boolean;
  setOpenExtraCostsFormPanel: (open: boolean) => any;
  setValidityStatusManuallyDefined: (value: boolean) => void;
  initialBookingId: string;
}) => {
  const mainCurrency = useAppCurrencyMain();
  const securityUser = useAppSecurityUser();
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;

  const clubSettings = clubResume.clubSettings;
  const agenciesOptions = useBookingAgenciesOptions();
  const generalSettings = clubResume.clubSettings.general;

  const { control } = form;

  const trainingReferencesOptions: ValueLabel<DiveTrainingReference>[] =
    useMemo(
      () => buildTrainingReferencesOptions({ productPackageOffers }),
      [productPackageOffers],
    );

  const isUnitOffer = false;

  const [unitPrice, discountAmount, diveTrainingReference, extraCosts] =
    useWatch({
      control,
      name: [
        'unitPrice',
        'discountAmount',
        'diveTrainingReference',
        'extraCosts',
      ],
    });

  const unitQuantity = 1;

  const diveServicePurchaseStatusesOptions =
    useDiveServicePurchaseStatusesOptions();

  const offersOptions = useMemo(() => {
    const options = productPackageOffers
      .filter(
        (offer) =>
          offer.productPackage.trainingAttributes.diveTrainingReference ===
          diveTrainingReference,
      )
      .map((offer) => {
        const option: ValueLabel<string> = {
          label: productPackageFormatter.formatNameString(
            offer?.productPackage,
            {
              publicSettings,
              options: {
                showResidentType: !diver.residentType, // on affiche le type de résidence seulement si il n'est pas précisé pour le plongeur
              },
              diveModesConf: clubSettings?.ui?.diveMode,
            },
          ),
          value: offer.reference,
        };
        return option;
      });
    return options;
  }, [
    clubSettings?.ui?.diveMode,
    diveTrainingReference,
    diver.residentType,
    productPackageOffers,
    publicSettings,
  ]);

  const isPaymentEnabled = useMemo(
    () => securityUser?.roles.includes('club-edit-participant-payment'),
    [securityUser.roles],
  );

  const priceDetails: DiverPurchasePriceDetails = useMemo(() => {
    if (openExtraCostsFormPanel || !openExtraCostsFormPanel) {
      // on utilise openExtraCostsFormPanel pour être sûr de mettre à jour le total à la fermeture du dialog (sinon, y'a un coup de retard quand on édite!)
      return diverPurchasePackageBuilder.buildTotalPrice({
        isPaymentEnabled,
        isUnitOffer,
        unitPrice,
        unitQuantity,
        discountAmount,
        extraCosts,
      });
    }
  }, [
    discountAmount,
    extraCosts,
    isPaymentEnabled,
    isUnitOffer,
    openExtraCostsFormPanel,
    unitPrice,
    unitQuantity,
  ]);
  const { totalPrice, totalPriceExtraCosts } = priceDetails;

  const extraCostUnitDiveDefaultMultiplier = useMemo(() => {
    if (unitQuantity > 0) {
      return unitQuantity;
    }
    return 1;
  }, [unitQuantity]);

  const onProductPackageOfferReferenceChange = useCallback(
    (productPackageOfferReference: string) => {
      const offer = productPackageOffers.find(
        (o) => o.reference === productPackageOfferReference,
      );
      if (offer) {
        if (isCreation) {
          setFormValue('unitPrice', offer.price);
          setFormValue(
            'creditsInitialCount',
            offer.productPackage.diveAttributes.divesCount,
          );
        }
      }
    },
    [isCreation, productPackageOffers, setFormValue],
  );

  const services = clubResume.clubSettings.services;

  const defaultOrganizationReferences = useMemo(() => {
    const refs: DiveServiceOrganizationReference[] = [];
    if (services.french.enabled) {
      refs.push(services.french.defaultOrganizationReference);
    }
    if (services.international.enabled) {
      refs.push(services.international.defaultOrganizationReference);
    }
    return refs;
  }, [
    services.french.defaultOrganizationReference,
    services.french.enabled,
    services.international.defaultOrganizationReference,
    services.international.enabled,
  ]);

  const onDiveTrainingReferenceChange = useCallback(
    (diveTrainingReference: DiveTrainingReference) => {
      const candidatesOffers = productPackageOffers.filter(
        (offer) =>
          offer.productPackage.trainingAttributes.diveTrainingReference ===
          diveTrainingReference,
      );
      let newProductPackageOfferReference: string;
      if (candidatesOffers.length > 0) {
        if (candidatesOffers.length === 1) {
          newProductPackageOfferReference = candidatesOffers[0].reference;
        } else {
          const defaultOrganisationOffers = candidatesOffers.filter((o) =>
            defaultOrganizationReferences.includes(
              o.productPackage.productAttributes.defaultOrganizationReference,
            ),
          );
          if (defaultOrganisationOffers?.length > 0) {
            newProductPackageOfferReference =
              defaultOrganisationOffers[0].reference;
          } else {
            newProductPackageOfferReference = candidatesOffers[0].reference;
          }
        }
      }
      if (newProductPackageOfferReference) {
        setFormValue(
          'productPackageOfferReference',
          newProductPackageOfferReference,
        );
        onProductPackageOfferReferenceChange(newProductPackageOfferReference);
      }
    },
    [
      defaultOrganizationReferences,
      onProductPackageOfferReferenceChange,
      productPackageOffers,
      setFormValue,
    ],
  );

  const commonActions: DiverPurchaseCommonLocalStateActions =
    useDiverPurchaseCommonLocalStateActions({
      form,
      priceDetails,
    });

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Formation"
          control={control}
          name="diveTrainingReference"
          required={true}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={trainingReferencesOptions}
              onChange={(value) => onDiveTrainingReferenceChange(value)}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label={`Offre (${offersOptions.length})`}
          control={control}
          name="productPackageOfferReference"
          required={true}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={offersOptions}
              onChange={(value) => onProductPackageOfferReferenceChange(value)}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Date de souscription"
          control={control}
          name="purchaseDate"
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF className="w-full" {...props} />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Statut"
          control={control}
          name="validityStatus"
          required={true}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              theme="material-ui"
              {...props}
              options={diveServicePurchaseStatusesOptions}
              onChange={() => setValidityStatusManuallyDefined(true)}
            />
          )}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Plongées mini"
          control={control}
          name="creditsInitialCount"
          required={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Plongées supp."
          control={control}
          name="creditsAdditionalCount"
          required={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />

        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Arriéré"
          control={control}
          name="consumedExternalCount"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <AppFormControlRHF_Deprecated
          className="w-full"
          label={`Prix formation (${mainCurrency.sign})`}
          control={control}
          name="unitPrice"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />

        {generalSettings?.billing?.thirdPartyCollectEnabled && (
          <AppFormControlRHF_Deprecated
            control={control}
            label="Total tiers"
            name={'totalPriceThirdPartyCollect' as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} type="number" />
            )}
          />
        )}
        <div className="grid grid-cols-2 gap-2">
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={`Remise (${mainCurrency.sign})`}
            control={control}
            name="discountAmount"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="number"
                onChange={commonActions.onChangeDiscountAmount}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={'Remise (%)'}
            control={control}
            name="discountPercentage"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="number"
                onChange={commonActions.onChangeDiscountPercentage}
              />
            )}
          />
        </div>
        <AppPackageExtraCostFormDialog
          context={'training'}
          open={openExtraCostsFormPanel}
          form={form as UseFormReturn<AppPackageExtraCostFormModel, any>}
          setOpen={setOpenExtraCostsFormPanel}
          extraCostUnitDiveDefaultMultiplier={
            extraCostUnitDiveDefaultMultiplier
          }
        >
          <AppButton
            className={'w-full sm:w-auto text-xs font-bold uppercase'}
            style={{ minWidth: '6rem' }}
            color={'primary-outline-light'}
            icon={AppHeroIcons.actionEdit}
            onClick={() => setOpenExtraCostsFormPanel(!openExtraCostsFormPanel)}
          >
            Suppléments{' '}
            {totalPriceExtraCosts > 0 && (
              <AppPriceLabel
                amount={totalPriceExtraCosts}
                mainCurrency={mainCurrency}
              />
            )}
          </AppButton>
        </AppPackageExtraCostFormDialog>
        <div className={'flex flex-col w-full'}>
          <FormControl>
            <FormLabel className="form-label pl-1" component="legend">
              <span className="font-bold text-app-primary uppercase text-xs leading-3">
                Prix total
              </span>
            </FormLabel>
            <Box className="form-input">
              {totalPrice > 0 && (
                <span className="font-bold text-app-primary text-xl">
                  <AppPriceLabel
                    amount={totalPrice}
                    mainCurrency={mainCurrency}
                  />
                </span>
              )}
            </Box>
          </FormControl>
        </div>
      </div>
      <DiverPurchaseCommonFormBookingAndAgency
        isCreation={isCreation}
        form={form}
        initialBookingId={initialBookingId}
        aggregatedData={aggregatedData}
      />
      <AppFormControlRHF_Deprecated
        className="flex-grow w-full"
        label="Commentaire"
        control={control}
        name="comment"
        renderComponent={(props) => (
          <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
        )}
      />
    </>
  );
};

function buildTrainingReferencesOptions({
  productPackageOffers,
}: {
  productPackageOffers: ClubProductPackageOfferView[];
}): ValueLabel<DiveTrainingReference>[] {
  const diveTrainingReferences =
    clubDiveServiceOfferViewTransformer.buildDiveTrainingReferencesFromOffers(
      productPackageOffers,
    );
  const options = diveTrainingReferences.map((diveTrainingReference) => {
    const option: ValueLabel<DiveTrainingReference> = {
      label:
        diveServiceTrainingFormatter.formatDiveServiceTrainingCertificationLike(
          diveTrainingReference,
        ),
      value: diveTrainingReference,
    };
    return option;
  });
  return options;
}
