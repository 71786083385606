import {
  ClubPaymentPlatformEnv,
  CLUB_PAYMENT_PLATFORM_ENVS,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useAppPaymentPlatformEnvOptions(): ValueLabel<ClubPaymentPlatformEnv>[] {
  const options: ValueLabel<ClubPaymentPlatformEnv>[] = useMemo(
    () =>
      CLUB_PAYMENT_PLATFORM_ENVS.map((value) => ({
        value: value,
        label: value,
      })),
    [],
  );
  return options;
}
