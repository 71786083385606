/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppInputRHF, AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useAppCurrencyMain,
  useBookingAgenciesOptions,
  useBookingOptions,
  useDiversOptions,
} from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import {
  BookingDepositEditDialogInitialState,
  BookingDepositEditFormModel,
} from '../model';

export const BookingDepositEditForm = ({
  form,
  initialState,
  className,
}: {
  form: UseFormReturn<BookingDepositEditFormModel>;
  initialState: BookingDepositEditDialogInitialState;
  className?: string;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const { mode } = initialState;

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;

  const aggregatedData = initialState?.aggregatedData;

  const { bookings, divers, linkedData } = aggregatedData || {};

  const bookingOptions: ValueLabel<string, React.ReactNode>[] =
    useBookingOptions({
      aggregatedData: initialState?.aggregatedData,
      onlyActive: mode === 'create',
      initialBookingId: initialState?.defaultValue?.bookingDeposit?.bookingId,
      diveCenterId,
    });
  const diversOptions: ValueLabel<string>[] = useDiversOptions(divers);

  const [bookingAgencyId, creditInitial] = useWatch({
    control,
    name: ['bookingDeposit.bookingAgencyId', 'bookingDeposit.creditInitial'],
  });

  const agenciesOptions = useBookingAgenciesOptions();

  return (
    <div className={clsx('grid gap-4', className)}>
      <h3 className="mt-5 mb-2 uppercase text-base sm:text-lg text-app-blue font-bold">
        Acompte
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2">
        <AppFormControlRHF_Deprecated
          className="flex-grow"
          label={`Montant en ${mainCurrency.label}`}
          control={control}
          name="bookingDeposit.creditInitial"
          required={true}
          disabled={mode === 'edit'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        {creditInitial > 0 && (
          <>
            {clubSettings.general.agencies?.agencyDeposits && (
              <AppFormControlRHF_Deprecated
                className="w-full"
                label="Agence"
                control={control}
                name="bookingDeposit.bookingAgencyId"
                required={false}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={agenciesOptions}
                    onChange={(bookingAgencyId) => {
                      if (bookingAgencyId) {
                        const agency = clubResume.bookingAgencies.find(
                          (x) => x._id === bookingAgencyId,
                        );
                        setValue(
                          'bookingDeposit.details.bookingAgencyCommissionRate',
                          agency?.details?.defaultCommissionRate,
                        );
                      } else {
                        setValue(
                          'bookingDeposit.details.bookingAgencyCommissionRate',
                          undefined,
                        );
                      }
                    }}
                  />
                )}
              />
            )}
            {bookingAgencyId && (
              <AppFormControlRHF_Deprecated
                className="flex-grow"
                label={'Acompte agence en %'}
                control={control}
                name="bookingDeposit.details.bookingAgencyCommissionRate"
                required={!!bookingAgencyId}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
            )}
            <AppFormControlRHF_Deprecated
              className="w-full"
              label="Date"
              control={control}
              name="bookingDeposit.depositDate"
              required={true}
              renderComponent={(props) => (
                <AppInputDatePickerMaterialRHF className="w-full" {...props} />
              )}
            />
            <AppFormControlRHF_Deprecated
              label={'Référence'}
              control={control}
              name="bookingDeposit.reference"
              required={false}
              renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
            />
            {bookingOptions.length > 1 && (
              <AppFormControlRHF_Deprecated
                className="w-full"
                label="Réservation"
                control={control}
                name="bookingDeposit.bookingId"
                required={true}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    theme={'material-ui'}
                    {...props}
                    options={bookingOptions}
                  />
                )}
              />
            )}
            {diversOptions.length > 1 && (
              <AppFormControlRHF_Deprecated
                className="w-full"
                label="Client"
                control={control}
                name="bookingDeposit.diverId"
                required={true}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={diversOptions}
                  />
                )}
              />
            )}
          </>
        )}
      </div>
      {creditInitial > 0 && (
        <>
          <div className="grid gap-4 lg:grid-cols-2">
            <AppFormControlRHF_Deprecated
              className="w-full"
              label="Commentaire privé 🚫"
              control={control}
              name="bookingDeposit.details.privateComment"
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />{' '}
            <AppFormControlRHF_Deprecated
              className="w-full"
              label="Commentaire client 👨‍🦰👩‍🦰"
              control={control}
              name="bookingDeposit.details.customerComment"
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};
