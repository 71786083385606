/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveCertificationReference } from '@mabadive/app-common-model';
import { certificationFormatter } from '@mabadive/app-common-services';
import { useState } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

import { DIVE_CERTIFICATIONS_WITHOUT_BPT_NITROX } from './DIVE_CERTIFICATIONS_WITHOUT_BPT_NITROX.const';

const DIVE_CERTIFICATIONS_OPTIONS = DIVE_CERTIFICATIONS_WITHOUT_BPT_NITROX.map(
  (diveCertification) => {
    const option: ValueLabel<DiveCertificationReference> = {
      value: diveCertification.reference,
      label: certificationFormatter.formatCertification(diveCertification, {
        format: 'ref-name',
      }),
    };
    return option;
  },
);

export function useClubDiversListPageFilters() {
  const [hasDiveToday, setHasDiveToday] = useState(false);
  const [hasDiveTomorrow, setHasDiveTomorrow] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [mainCertificationReference, setMainCertificationReference] =
    useState(undefined);

  return {
    hasDiveToday,
    setHasDiveToday,
    hasDiveTomorrow,
    setHasDiveTomorrow,
    searchText,
    setSearchText,
    mainCertificationReference,
    setMainCertificationReference,
    diveCertificationsOptions: DIVE_CERTIFICATIONS_OPTIONS,
  };
}
