import { ClubDiverFull } from '@mabadive/app-common-model';
import { arrayBuilder } from '@mabadive/app-common-services';
import React, { useCallback } from 'react';
import {
  DiverBookingPageAggregatedData,
  PRO_BookingResume,
} from '../../models';
import { ClubDiverSelectorDialogInputState } from './model';

export function useDiverBookingPageOpenDiverSelector({
  aggregatedData,
  setDiverSelectorDialogState,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  setDiverSelectorDialogState: React.Dispatch<
    React.SetStateAction<ClubDiverSelectorDialogInputState>
  >;
}) {
  return useCallback(
    ({ bookingId }: { bookingId: string }) => {
      const aggregatedBookingResume: PRO_BookingResume =
        aggregatedData.bookingResumesLoaded.find(
          (bookingResume) => bookingResume.booking._id === bookingId,
        );

      const alreadyExistingDiversIds =
        aggregatedBookingResume.bookingMembersFull.map((x) => x.diver?._id);

      const diversToSuggestWithDuplicated = aggregatedData.divers
        .filter((x) => !alreadyExistingDiversIds.includes(x._id))
        .map((x) => {
          const r: ClubDiverFull = {
            ...x,
            divesMeta: {
              clubReference: x.clubReference,
              diverId: x._id,
              lastDiveTime: undefined,
              lastUpdatedDiveTime: undefined,
              nextDiveTime: undefined,
              nearestTime: undefined,
              todayFirstDiveTime: undefined,
              tomorrowFirstDiveTime: undefined,
            },
          };
          return r;
        });

      const diversToSuggest = arrayBuilder.filterDuplicated(
        diversToSuggestWithDuplicated,
        {
          compare: (diver1, diver2) => diver1._id === diver2._id,
        },
      );

      const alreadyExistingDiversNames =
        aggregatedBookingResume.bookingMembersFull
          .map((x) => x.diver?.lastName?.toUpperCase()?.trim())
          .filter((x) => !!x);

      const searchSuggestions = arrayBuilder.filterDuplicated(
        alreadyExistingDiversNames,
      );

      setDiverSelectorDialogState({
        isOpen: true,
        booking: aggregatedBookingResume.booking,
        existingBookingMembersFull: aggregatedBookingResume.bookingMembersFull,
        diversIdsToIgnore: alreadyExistingDiversIds,
        diversToSuggest,
        searchSuggestions,
      });
    },
    [
      aggregatedData.bookingResumesLoaded,
      aggregatedData.divers,
      setDiverSelectorDialogState,
    ],
  );
}
