/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_COLORS } from '@mabadive/app-common-model';
import { makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import { useCacheableClasses } from 'src/business/_core/modules/root/theme';

export const AppForm: FC<{
  bordered: boolean;
  hasChanges?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}> = ({ bordered, hasChanges, children }) => {
  const classes = useCacheableClasses({
    cacheKey: 'AppForm',
    buildStyles: (theme) => buildStyles(theme),
  });

  return (
    <div
      className={`${classes.root} bordered-${bordered} has-changes-${hasChanges}`}
    >
      {children}
    </div>
  );
};

function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      '&.bordered-true': {
        padding: '8px',
        border: '2px dashed #ccc',
        '&.has-changes-true': {
          borderColor: APP_COLORS.update,
        },
      },
    },
  });
}
