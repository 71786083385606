/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettingsCriteria } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { RegionalTaxLabel } from 'src/pages/_components/company';
import {
  ClubSettingsSection,
  ClubSettingsViewActivableFeature,
} from 'src/pages/SE-settings/_core';

export const ClubSettingsGeneralPurchaseViewPanelTaxes = () => {
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const taxes = clubSettings.general?.taxes;

  const regionalSettingsCriteria: RegionalSettingsCriteria = useMemo(
    () => ({
      effectiveDate: new Date(),
    }),
    [],
  );

  return (
    <ClubSettingsSection title="Taxes">
      <ClubSettingsViewActivableFeature
        isActive={taxes?.enabled}
        inactiveLabel="Taxes désactivées"
      >
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-4 gap-y-2">
          <AppFeatureBadge
            label={'Taxes plongée'}
            enabled={taxes.taxesReferencesByProduct?.diving?.length > 0}
          >
            <ul>
              {taxes.taxesReferencesByProduct?.diving?.map((taxReference) => (
                <li key={taxReference}>
                  <RegionalTaxLabel
                    taxReference={taxReference}
                    regionalSettingsCriteria={regionalSettingsCriteria}
                  />
                </li>
              ))}
            </ul>
          </AppFeatureBadge>

          <AppFeatureBadge
            label={'Taxes boutique'}
            enabled={taxes.taxesReferencesByProduct?.shop?.length > 0}
          >
            <ul>
              {taxes.taxesReferencesByProduct?.shop?.map((taxReference) => (
                <li key={taxReference}>
                  <RegionalTaxLabel
                    taxReference={taxReference}
                    regionalSettingsCriteria={regionalSettingsCriteria}
                  />
                </li>
              ))}
            </ul>
          </AppFeatureBadge>

          <AppFeatureBadge
            label={'Taxes location'}
            enabled={taxes.taxesReferencesByProduct?.rental?.length > 0}
          >
            <ul>
              {taxes.taxesReferencesByProduct?.rental?.map((taxReference) => (
                <li key={taxReference}>
                  <RegionalTaxLabel
                    taxReference={taxReference}
                    regionalSettingsCriteria={regionalSettingsCriteria}
                  />
                </li>
              ))}
            </ul>
          </AppFeatureBadge>
          <AppFeatureBadge
            label={'Taxes repas'}
            enabled={taxes.taxesReferencesByProduct?.food?.length > 0}
          >
            <ul>
              {taxes.taxesReferencesByProduct?.food?.map((taxReference) => (
                <li key={taxReference}>
                  <RegionalTaxLabel
                    taxReference={taxReference}
                    regionalSettingsCriteria={regionalSettingsCriteria}
                  />
                </li>
              ))}
            </ul>
          </AppFeatureBadge>
          <AppFeatureBadge
            label={'Taxes logement'}
            enabled={taxes.taxesReferencesByProduct?.accommodation?.length > 0}
          >
            <ul>
              {taxes.taxesReferencesByProduct?.accommodation?.map(
                (taxReference) => (
                  <li key={taxReference}>
                    <RegionalTaxLabel
                      taxReference={taxReference}
                      regionalSettingsCriteria={regionalSettingsCriteria}
                    />
                  </li>
                ),
              )}
            </ul>
          </AppFeatureBadge>
        </div>
      </ClubSettingsViewActivableFeature>
    </ClubSettingsSection>
  );
};
