/* eslint-disable @typescript-eslint/no-unused-vars */
import { SAFETY_CERTIFICATIONS } from '@mabadive/app-common-model';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export const SAFETY_CERTIFICATION_OPTIONS = SAFETY_CERTIFICATIONS.map(
  (cert) => {
    const option: ValueLabel<string> = {
      label: cert.label,
      value: cert.reference,
      description: cert.description,
    };
    return option;
  },
);
