import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export function BookingMassiveEditorSessionsTableRegisterAddMemberButton({
  onClickAddMember,
  isReverseTheme,
  className,
}: {
  onClickAddMember: () => void;
  isReverseTheme: boolean;
  className?: string;
}) {
  return (
    <Tippy
      delay={[300, 100]}
      placement="top"
      content="Ajouter un plongeur à la réservation"
    >
      <div
        className={clsx(
          'h-full group px-1 py-1.5 ring-opacity-30 ring-gray-400 rounded-lg hover:ring-2 cursor-pointer',
          isReverseTheme
            ? 'bg-app-secondary text-white hover:text-white-dark border border-white hover:bg-app-secondary-dark hover:border-app-secondary'
            : 'bg-white text-app-secondary hover:text-app-secondary-dark border border-app-secondary hover:border-app-secondary-dark',
          'flex flex-col justify-center items-center',
          // bookingTabModel.meta.isUniqueMember
          //   ? 'justify-center'
          //   : 'justify-top pt-5',
          className,
        )}
        onClick={onClickAddMember}
        // style={{
        //   height: bookingTabModel.meta.isUniqueMember ? '4.0rem' : '5.4rem',
        // }}
      >
        <div
          className={clsx(
            'inline-block w-5 h-5 rounded-xl',
            isReverseTheme
              ? 'bg-white text-app-secondary group-hover:bg-gray-50 group-hover:text-app-secondary-dark'
              : 'bg-app-secondary text-white group-hover:bg-app-secondary-dark',
          )}
        >
          <AppHeroIcons.actionAdd className="w-full h-full fill-current" />
        </div>
        <div className="mt-1 text-center font-bold text-xs">PLONGEUR</div>
      </div>
    </Tippy>
  );
}
