import {
  ClubMassiveUpdateOperationPayloadSessionMassiveActionId,
  ClubPlanningLightSessionDef,
  DiveSession,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { commonEntityBuilder } from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { appWebLogger } from 'src/lib/browser';
import { diveSessionResumeToDiveSessionConvertor } from '../../../../services/02.update-state/entity-convertors';

export const sessionMassiveActionClient = {
  moveParticipant,
  persistSessionMassiveAction,
};

async function moveParticipant({
  sourceParticipantId,
  sourceSession,
  targetSession,
  bookingId,
  clubReference,
  diveCenterId,
}: {
  sourceParticipantId: string;
  sourceSession: Pick<ClubPlanningLightSessionDef, 'reference'>;
  targetSession: ClubPlanningLightSessionDef;
  bookingId: string;
  clubReference: string;
  diveCenterId: string;
}) {
  return persistSessionMassiveAction({
    actionId: 'move-participants',
    sourceParticipantsIds: [sourceParticipantId],
    sourceSessionGroupsIds: [],
    cloneGroups: false,
    cloneGuidesAndInstructors: false,
    sourceSession,
    targetSession,
    bookingsIds: [bookingId],
    clubReference,
    diveCenterId,
    logContext: 'move 1 participant',
  });
}

async function persistSessionMassiveAction({
  actionId,
  logContext,
  sourceParticipantsIds,
  sourceSessionGroupsIds,
  cloneGroups,
  cloneGuidesAndInstructors,
  sourceSession,
  targetSession,
  bookingsIds,
  clubReference,
  diveCenterId,
}: {
  actionId: ClubMassiveUpdateOperationPayloadSessionMassiveActionId;
  logContext: string;
  sourceParticipantsIds: string[];
  sourceSessionGroupsIds: string[];
  cloneGroups: boolean;
  cloneGuidesAndInstructors: boolean;
  sourceSession: Pick<ClubPlanningLightSessionDef, 'reference'>;
  targetSession: ClubPlanningLightSessionDef;
  bookingsIds: string[];
  clubReference: string;
  diveCenterId: string;
}) {
  if (sourceSession?.reference !== targetSession.reference) {
    const payload: ProMultiOperationPayload = {
      logContext,
      bookingIdsToClean: bookingsIds.filter((x) => !!x),
      // deletedBookingProductsIds: [participantData?.bookingProduct?._id],
      // deletedBookingSessionParticipantsIds: [
      //   participantData?.bookingSessionParticipant?._id,
      // ],
      // deletedClubParticipantsIds: [participantData?.clubParticipant?._id],
      newDiveSessions: [],
      sessionMassiveAction: {
        actionId,
        sourceParticipantsIds: sourceParticipantsIds.filter((x) => !!x),
        sourceSessionReference: sourceSession.reference,
        targetSessionReference: targetSession.reference,
        sourceSessionGroupsIds,
        cloneGroups,
        cloneGuidesAndInstructors,
        newDiveSessions: [],
      },
    };
    let targetDiveSession: DiveSession =
      diveSessionResumeToDiveSessionConvertor.convert(targetSession, {
        clubReference,
      });
    if (targetDiveSession.isVirtual) {
      targetDiveSession = commonEntityBuilder.buildEntity<DiveSession>({
        ...targetDiveSession,
        clubReference,
        diveCenterId,
        virtualDiveSessionReference:
          targetDiveSession.virtualDiveSessionReference,
        virtualDiveTourReference: targetDiveSession.virtualDiveTourReference,
        isVirtual: false,
        // details: {},
        // staffConfig: {},
        // diveTourSession1: {},
      });
      payload.sessionMassiveAction.newDiveSessions.push(targetDiveSession);
      payload.sessionMassiveAction.targetSessionReference =
        targetDiveSession.reference;
    }

    return await clubMassiveUpdatorClient.update(payload).catch((err) => {
      appLogger.error(err);
      appWebLogger.captureMessage('Error while trying to move participants', {
        logContext: 'sessionMassiveActionClient',
        clubReference,
        extra: {
          payload,
        },
      });
      throw err;
    });
  }
}
