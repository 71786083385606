import React from 'react';
import { Control, Path, useController } from 'react-hook-form';
import { AppToggleButtonGroupProps } from './AppToggleButtonGroupProps.type';
import { AppToogleButtonGroupTailwind } from './AppToogleButtonGroupTailwind';

export function AppToogleButtonGroupRHF<
  F,
  T extends string | number | boolean,
>({
  control,
  name,
  onChanges,
  items,
  className,
  customClassName,
  color,
  theme,
  size = 'lg',
  ...extraProps
}: Omit<AppToggleButtonGroupProps<T>, 'selected'> & {
  control: Control<F>;
  name: Path<F>;
}) {
  const {
    field: { ref, onChange: onChangeRHF, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<F>({
    name,
    control,
    rules: {
      required: extraProps.required,
    },
  });

  return (
    <AppToogleButtonGroupTailwind
      selected={value as unknown as T}
      onChanges={(value) => {
        onChangeRHF(value);
        onChanges && onChanges(value as unknown as T);
      }}
      items={items}
      color={color}
      theme={theme}
      size={size}
      className={className}
      customClassName={customClassName}
    />
  );
}
