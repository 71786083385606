import { DiveSession } from '@mabadive/app-common-model';
import React from 'react';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppDisclosure } from 'src/business/_core/modules/layout/components/_tailwind';
import { diveSessionStyleBuilder } from '../../../club-planning-light/components/ClubPlanningLightSession/diveSessionStyleBuilder.service';
import { CalendarSessionWidthMode } from '../../../club-planning-light/components/ClubPlanningLightSession/session-common';
import { ClubPlanningPanelSessionDisplayStyle } from '../../../club-planning-light/model';
import {
  ClubDiveSessionHeaderSessionDate,
  ClubDiveSessionHeaderStatus,
  ClubDiveSessionHeaderTimeAndDiveSite,
  ClubDiveSessionHeaderTitle,
  ClubDiveSessionHeaderTitleWithPrefix,
} from './components';

export const ClubDiveSessionHeaderCard = function <
  S extends Pick<
    DiveSession,
    | 'name'
    | 'status'
    | 'special'
    | 'time'
    | 'diveSiteId'
    | 'boatsIds'
    | 'diveTourSession2'
    | 'emojiId'
    | 'details'
  >,
>({
  diveSession: session,
  selectionColor,
  widthMode,
  className,
  onClick,
}: {
  diveSession: S;
  widthMode: CalendarSessionWidthMode;
  selectionColor?: ClubPlanningPanelSessionDisplayStyle;
  className?: string;
  onClick?: (diveSession: S) => void;
}) {
  const isMultiSession = !!session.diveTourSession2;
  const groupDiveSite1AndTitle = !isMultiSession && !session.diveSiteId;

  const sessionBorderStyle = diveSessionStyleBuilder.buildBorderStyleFromTime({
    isVirtual: false,
    time: session.time,
  });

  const mediaSize = useMediaSizeTailwind();

  const defaultExpanded =
    mediaSize === 'xxs' || (mediaSize !== 'xs' && mediaSize !== 'sm');

  return (
    <div
      className={`text-sm text-gray-600 border-2 border-gray-600 ${className}`}
    >
      <AppDisclosure
        defaultExpanded={defaultExpanded}
        className="w-full flex justify-between px-1 bg-gray-600 text-white"
        title={
          <ClubDiveSessionHeaderSessionDate
            className="py-0.5"
            diveSession={session}
          />
        }
      >
        {groupDiveSite1AndTitle ? (
          <ClubDiveSessionHeaderTitleWithPrefix
            className={`w-full pr-0.5 border-t ${sessionBorderStyle}`}
            diveSession={session}
            selectionColor={selectionColor}
            sessionIndex={1}
          />
        ) : (
          <>
            <ClubDiveSessionHeaderTimeAndDiveSite
              className={`border-t ${sessionBorderStyle}`}
              diveSiteId={session.diveSiteId}
              time={session.time}
              isMultiSession={isMultiSession}
              widthMode={widthMode}
              selectionColor={selectionColor}
              sessionIndex={1}
            />
            {isMultiSession && (
              <ClubDiveSessionHeaderTimeAndDiveSite
                className={`border-t ${sessionBorderStyle}`}
                diveSiteId={session.diveTourSession2?.diveSiteId}
                time={session.diveTourSession2?.time}
                isMultiSession={isMultiSession}
                sessionIndex={2}
                widthMode={widthMode}
                selectionColor={selectionColor}
              />
            )}
            <ClubDiveSessionHeaderTitle
              diveSession={session}
              className={`py-0.5 border-t ${sessionBorderStyle}`}
            />
          </>
        )}

        <ClubDiveSessionHeaderStatus className="py-0.5" diveSession={session} />
      </AppDisclosure>
    </div>
  );
};
