import { DiveSession } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSession,
  BookingResumeParticipantsBySession,
  PRO_BookingParticipantFull,
} from '../../models';

export const nearestParticipantFinder = {
  findNearestParticipantFull,
  findNearestSessionResume,
};
function findNearestParticipantFull({
  bookingParticipantsFull,
  diverId,
  diveSession,
}: {
  bookingParticipantsFull: PRO_BookingParticipantFull[];
  diverId: string;
  diveSession: Pick<DiveSession, 'time'>;
}): PRO_BookingParticipantFull {
  if (!bookingParticipantsFull) {
    return undefined;
  }
  const diverExistingParticipants = bookingParticipantsFull.filter(
    (x) => x.diver._id === diverId,
  );
  const nearParticipants = diverExistingParticipants.reduce(
    (acc, p) => {
      const isBefore = dateService.isBefore(
        p.diveSession.time,
        diveSession.time,
      );
      if (isBefore) {
        if (
          !acc.previous ||
          dateService.isBefore(
            acc.previous.diveSession.time,
            p.diveSession.time,
          )
        ) {
          acc.previous = p;
        }
      } else {
        if (
          !acc.next ||
          dateService.isBefore(p.diveSession.time, acc.next.diveSession.time)
        ) {
          acc.next = p;
        }
      }
      return acc;
    },
    {} as {
      previous?: PRO_BookingParticipantFull;
      next?: PRO_BookingParticipantFull;
    },
  );
  const nearestParticipant = nearParticipants.previous ?? nearParticipants.next;
  return nearestParticipant;
}

export function findNearestSessionResume({
  participantsBySessions,
  diveSessionReference,
  diverId,
}: {
  participantsBySessions: BookingResumeParticipantsBySession[];
  diveSessionReference: string;
  diverId: string;
}): {
  bookingSessionFull?: AggregatedBookingSessionFull;
  participant?: BookingResumeParticipantForSession;
} {
  const sessionsAndParticipantFiltered = participantsBySessions.filter(
    (x) =>
      x.bookingSessionFull.diveSession.reference === diveSessionReference || // target session
      x.participants.find(
        (p) =>
          !!p.bookingParticipantFull &&
          p.bookingMemberFull.diver._id === diverId,
      ) !== undefined, // sessions with this participant active,
  );
  const sessionsAndParticipantIndex = sessionsAndParticipantFiltered.findIndex(
    (x) => x.bookingSessionFull.diveSession.reference === diveSessionReference,
  );
  const nearest: BookingResumeParticipantsBySession =
    sessionsAndParticipantIndex > 0
      ? sessionsAndParticipantFiltered[sessionsAndParticipantIndex - 1]
      : sessionsAndParticipantIndex < sessionsAndParticipantFiltered.length
      ? sessionsAndParticipantFiltered[sessionsAndParticipantIndex + 1]
      : undefined;
  const bookingSessionFull: AggregatedBookingSessionFull =
    nearest?.bookingSessionFull;
  const participant: BookingResumeParticipantForSession = (
    nearest?.participants ?? []
  ).find(
    (p) =>
      !!p.bookingParticipantFull && p.bookingMemberFull.diver._id === diverId,
  );

  return {
    bookingSessionFull,
    participant,
  };
}
