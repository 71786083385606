import {
  DiveSessionResumeFull,
  DiveSessionResumeFullGql_Company,
} from '@mabadive/app-common-model';
import { MQueryDescription } from 'src/_common-browser';

export function buildDiveSessionResumeFullOneGraphqlQuery({
  diveSessionReference,
}: {
  diveSessionReference: string;
}) {
  const queryDescription: MQueryDescription<DiveSessionResumeFull> = {
    queryName: 'dive_session',
    returnType: 'first',
    where: `{reference: {_eq: "${diveSessionReference}"}}`,
    returnAttributes: DiveSessionResumeFullGql_Company,
  };

  return queryDescription;
}
