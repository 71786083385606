import clsx from 'clsx';
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';

export const ClubUserProfilesAuthorizationTableHeader = ({
  className,
}: {
  className?: string;
}) => {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  return (
    <div
      className={clsx(
        'grid gap-x-1 md:gap-x-2 justify-between text-app-xxs md:text-xs text-gray-400 font-bold uppercase',
        isMultiDiveCenters ? 'grid-cols-9' : 'grid-cols-8',
        className,
      )}
    >
      <span className="">Planning</span>
      <span className="">Feuille sécu</span>
      <span className="">Réservation</span>
      <span className="">Listes</span>
      <span className="">Caisse</span>
      <span className="">Stats</span>
      <span className="">Config centre</span>
      <span className="">Config générale</span>
      {isMultiDiveCenters && <span className="">Multi centres</span>}
    </div>
  );
};
