import { ColoredTag } from '@mabadive/app-common-model';
import { Box, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import {
  ColoredTagAvatarCard,
  ColoredTagAvatarCardSize,
} from 'src/business/_core/modules/layout';
import { useCacheableClasses } from 'src/business/_core/modules/root/theme';

export const CommentLabel = ({
  comment,
  className,
  chunkClassName,
  tag,
  tagSize,
}: {
  comment: string;
  className?: string;
  chunkClassName?: string;
  tag?: ColoredTag;
  tagSize?: ColoredTagAvatarCardSize;
}) => {
  const classes = useCacheableClasses({
    cacheKey: 'CommentLabel',
    buildStyles,
  });

  if (!tagSize) {
    tagSize = 'normal';
  }

  return !comment ? null : (
    <Box
      className={`${className ?? ''} ${classes.root}`}
      display="flex"
      flexDirection="row"
    >
      {!tag ? null : (
        <Box mr={0.5}>
          <ColoredTagAvatarCard size={tagSize} tag={tag} />
        </Box>
      )}
      <Box display="flex" flexDirection="column">
        {renderContent(comment, chunkClassName)}
      </Box>
    </Box>
  );
};

function renderContent(
  comment: string,
  chunkClassName: string,
): React.ReactNode {
  return comment.split('\n').map((value, i) => (
    <div key={i} className={chunkClassName}>
      {value}
    </div>
  ));
}

function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      whiteSpace: 'normal',
    },
  });
}
