import {
  DashboardBookingsOverPeriodCleanAnalysis,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useState } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { AppButton } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppIconsAction,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import { DashboardMetricValueCard } from 'src/pages/DA-dashboard/_core';
import { DataManagementFetchCriteria } from '../../model';
import { DataManagementBookingsCleanContext } from './DataManagementBookingsCleanContext.type';

export const DataManagementBookingsCleanAnalysisPurchasePackages = ({
  fetchCriteria,
  bookingsCleanAnalysisOverPeriod: data,
  reload,
  context,
}: {
  fetchCriteria: DataManagementFetchCriteria;
  bookingsCleanAnalysisOverPeriod: DashboardBookingsOverPeriodCleanAnalysis;
  reload: () => any;
  context: DataManagementBookingsCleanContext;
}) => {
  const purchasePackagesValidityStatusActiveCount =
    data?.purchasePackagesValidityStatusActiveCount;
  const purchasePackagesUnpayedCount = data?.purchasePackagesUnpayedCount;

  const { beginDate, endDate } = fetchCriteria;

  const [isPersistInProgress, setIsPersistInProgress] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <DashboardMetricValueCard
        label={'Ventes'}
        value={data.purchasePackagesCount}
        unitIcon={EntitiesIcons.payment}
      />
      {data.purchasePackagesCount > 0 &&
        purchasePackagesValidityStatusActiveCount +
          purchasePackagesUnpayedCount ===
          0 && (
          <div className="block text-left text-green-700 text-xs font-medium uppercase">
            <AppIconsAction.archive className="inline h-4 w-4 mr-1 text-green-600" />{' '}
            archivées
          </div>
        )}
      {context.enableArchiveAction &&
        purchasePackagesValidityStatusActiveCount +
          purchasePackagesUnpayedCount >
          0 && (
          <div className="flex-grow flex flex-col gap-2 justify-between">
            <div
              className={clsx(
                'flex flex-col gap-2 text-left text-xs font-medium uppercase',
              )}
            >
              {purchasePackagesValidityStatusActiveCount > 0 && (
                <div className={context.toArchiveStyle.className}>
                  <context.toArchiveStyle.icon
                    className={clsx(
                      context.toArchiveStyle.iconClassName,
                      'inline h-5 w-5',
                    )}
                  />{' '}
                  {purchasePackagesValidityStatusActiveCount} en cours
                </div>
              )}
              {purchasePackagesUnpayedCount > 0 && (
                <div className={context.toArchiveStyle.className}>
                  <context.toArchiveStyle.icon
                    className={clsx(
                      context.toArchiveStyle.iconClassName,
                      'inline h-5 w-5',
                    )}
                  />{' '}
                  {purchasePackagesUnpayedCount} non-payés
                </div>
              )}
            </div>
            <AppButton
              className="my-2 w-full"
              color="gray-outline-light"
              icon={AppIconsAction.archive}
              disabled={isPersistInProgress}
              onClick={async () => {
                if (
                  await confirmDialog.confirmPromise({
                    title: 'Archiver toutes les ventes',
                    message:
                      'Êtes-vous sûr de vouloir archiver toutes les ventes de cette période?',
                    type: 'noYesDanger',
                  })
                ) {
                  const payload: ProMultiOperationPayload = {
                    logContext: 'dashboard clean analysis:  payments',
                    archiveOverPeriod: {
                      beginDate,
                      endDate,
                      purchasePackages: {
                        purchasePaymentStatus: purchasePackagesUnpayedCount > 0,
                        validityStatus:
                          purchasePackagesValidityStatusActiveCount > 0,
                      },
                    },
                  };
                  setIsPersistInProgress(true);
                  try {
                    await clubMassiveUpdatorClient.update(payload);
                    await reload();
                  } finally {
                    setIsPersistInProgress(false);
                  }
                }
              }}
            >
              Archiver
            </AppButton>
          </div>
        )}
    </div>
  );
};
