export const clubSettingsGeneralPageTabLabelFormatter = {
  format,
};

export type ClubSettingsGeneralPageTabId =
  | 'main'
  | 'sessions'
  | 'payments'
  | 'purchase';

function format(tabId: ClubSettingsGeneralPageTabId): string {
  switch (tabId) {
    case 'main':
      return 'Généralités';
    case 'sessions':
      return 'Sessions';
    case 'purchase':
      return 'Facturation';
    case 'payments':
      return 'Paiements';
  }
}
