import { BookingResumeForList } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useProgressiveListRendering } from 'src/business/_core/data/hooks';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { useBookingAgenciesOptions } from '../../../_components/options';
import { ClubBookingsTableRow } from './ClubBookingsTableRow';

export function ClubBookingsTable({
  bookings: bookingsInput,
  className,
}: {
  bookings: BookingResumeForList[];
  className?: string;
}) {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const customerSettings = diveCenterResume?.clubResume?.clubSettings?.customer;
  const agenciesOptions = useBookingAgenciesOptions();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const bookingsToRender = useProgressiveListRendering(bookingsInput, {
    initialSize: 50,
    step: 100,
  });

  return (
    <table className={clsx('app-table min-w-full', className)}>
      <thead>
        <tr>
          {isMultiDiveCenters && (
            <th
              scope="col"
              className="px-2 py-1  uppercase tracking-wider hidden xs:table-cell"
            >
              Centre
            </th>
          )}
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden lg:table-cell"
          >
            Création
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden xs:table-cell"
          >
            Mise à jour
          </th>
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Contact
          </th>
          <th
            scope="col"
            className="px-2 py-1 uppercase tracking-wider hidden sm:table-cell"
          >
            Groupe
            {generalSettings?.agencies?.enabled &&
              customerSettings?.booking?.agency &&
              ' / Agence'}
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden lg:table-cell"
          >
            Période plongées
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden md:table-cell"
          >
            Plongeurs
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden md:table-cell"
          >
            Sessions
          </th>
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Total plongées
          </th>
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Statut
          </th>
        </tr>
      </thead>
      <tbody>
        {bookingsToRender.map((booking) => (
          <ClubBookingsTableRow key={booking._id} booking={booking} />
        ))}
      </tbody>
    </table>
  );
}
