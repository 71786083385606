import { ClubAuthUserAuthorizations } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import {
  AppHeroIcons,
  AppIconsAction,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';

export const ClubUserProfilesAuthorizationTableBody = ({
  authorizations,
  className,
}: {
  authorizations: ClubAuthUserAuthorizations;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { view, edit, download, multiDiveCenters } = authorizations;

  const iconBaseClassName =
    'inline w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 font-bold';

  return (
    <div
      className={clsx(
        'mt-2 py-1 grid gap-x-1 md:gap-x-2',
        isMultiDiveCenters ? 'grid-cols-9' : 'grid-cols-8',
        className,
      )}
    >
      <span className="flex gap-1 lg:gap-2 2xl:gap-4 ">
        {/* Planning */}
        {view?.planning?.participant ? (
          <>
            <AppHeroIcons.eye
              className={`${iconBaseClassName} text-status-success`}
            />
            {edit?.planning?.session && (
              <AppHeroIcons.actionEdit
                className={`${iconBaseClassName} text-status-success`}
              />
            )}
          </>
        ) : (
          <AppHeroIcons.disabled
            className={`${iconBaseClassName} text-status-error`}
          />
        )}
      </span>
      <span className="flex gap-1 lg:gap-2 2xl:gap-4 ">
        {/* Fiche de sécurité */}
        {download?.securitySheet || edit?.planning?.securitySheet ? (
          <>
            {download?.securitySheet && (
              <AppHeroIcons.actionDownload
                className={`${iconBaseClassName} text-status-success`}
              />
            )}{' '}
            {edit?.planning?.securitySheet && (
              <AppHeroIcons.actionEdit
                className={`${iconBaseClassName} text-status-success`}
              />
            )}
          </>
        ) : (
          <AppHeroIcons.disabled
            className={`${iconBaseClassName} text-status-error`}
          />
        )}
      </span>
      <span className="flex gap-1 lg:gap-2 2xl:gap-4 ">
        {/* Réservation */}
        {view?.participant?.booking ? (
          <>
            <AppHeroIcons.eye
              className={`${iconBaseClassName} text-status-success`}
            />
            {edit?.participant?.booking && (
              <AppHeroIcons.actionEdit
                className={`${iconBaseClassName} text-status-success`}
              />
            )}
          </>
        ) : (
          <AppHeroIcons.disabled
            className={`${iconBaseClassName} text-status-error`}
          />
        )}
      </span>
      <span className="flex gap-1 lg:gap-2 2xl:gap-4 ">
        {/* Listes */}
        {view?.lists?.participant ? (
          <>
            <AppHeroIcons.eye
              className={`${iconBaseClassName} text-status-success`}
            />
            {download?.bookings && (
              <AppHeroIcons.actionDownload
                className={`${iconBaseClassName} text-status-success`}
              />
            )}
          </>
        ) : (
          <AppHeroIcons.disabled
            className={`${iconBaseClassName} text-status-error`}
          />
        )}
      </span>
      <span className="flex gap-1 lg:gap-2 2xl:gap-4 ">
        {/* Caisse */}{' '}
        {view?.dashboard?.dailyCash ? (
          <>
            <AppHeroIcons.eye
              className={`${iconBaseClassName} text-status-success`}
            />
            {download?.dailyCash && (
              <AppHeroIcons.actionDownload
                className={`${iconBaseClassName} text-status-success`}
              />
            )}
          </>
        ) : (
          <AppHeroIcons.disabled
            className={`${iconBaseClassName} text-status-error`}
          />
        )}
      </span>
      <span className="flex gap-1 lg:gap-2 2xl:gap-4 ">
        {/* Statistiques */}{' '}
        {view?.dashboard?.stats ? (
          <>
            <AppHeroIcons.eye
              className={`${iconBaseClassName} text-status-success`}
            />
          </>
        ) : (
          <AppHeroIcons.disabled
            className={`${iconBaseClassName} text-status-error`}
          />
        )}
      </span>
      <span className="flex gap-1 lg:gap-2 2xl:gap-4 ">
        {/* Configuration */}{' '}
        {view?.settings?.diveCenter ? (
          <>
            <AppHeroIcons.eye
              className={`${iconBaseClassName} text-status-success`}
            />
            {edit?.settings?.diveCenter && (
              <AppHeroIcons.actionEdit
                className={`${iconBaseClassName} text-status-success`}
              />
            )}
          </>
        ) : (
          <AppHeroIcons.disabled
            className={`${iconBaseClassName} text-status-error`}
          />
        )}
      </span>
      <span className="flex gap-1 lg:gap-2 2xl:gap-4 ">
        {/* Configuration */}{' '}
        {view?.settings?.global ? (
          <>
            <AppHeroIcons.eye
              className={`${iconBaseClassName} text-status-success`}
            />
            {edit?.settings?.global && (
              <AppHeroIcons.actionEdit
                className={`${iconBaseClassName} text-status-success`}
              />
            )}
            {edit?.settings?.userAccounts && (
              <AppHeroIcons.userCircle
                className={`${iconBaseClassName} text-status-success`}
              />
            )}
            {edit?.settings?.accountProfilesImpersonate && (
              <AppIconsAction.logout
                className={`${iconBaseClassName} text-status-success`}
              />
            )}
            {edit?.settings?.archiveData && (
              <AppIconsAction.logout
                className={`${iconBaseClassName} text-status-success`}
              />
            )}
          </>
        ) : (
          <AppHeroIcons.disabled
            className={`${iconBaseClassName} text-status-error`}
          />
        )}
      </span>
      {isMultiDiveCenters && (
        <span className="flex gap-1 lg:gap-2 2xl:gap-4 ">
          {/* Multi-centres */}{' '}
          {multiDiveCenters?.switchCenter ? (
            <>
              <AppHeroIcons.eye
                className={`${iconBaseClassName} text-status-success`}
              />
            </>
          ) : (
            <AppHeroIcons.disabled
              className={`${iconBaseClassName} text-status-error`}
            />
          )}
          {multiDiveCenters?.edit?.participant && (
            <AppHeroIcons.actionEdit
              className={`${iconBaseClassName} text-status-success`}
            />
          )}
          {multiDiveCenters?.edit?.settings && (
            <AppIconsSettings.home
              className={`${iconBaseClassName} text-status-success`}
            />
          )}
        </span>
      )}
    </div>
  );
};
