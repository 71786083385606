import {
  Booking,
  CURRENT_JWT_TOKEN_VERSION,
  CustomerSpaceBookingMemberWithDiver,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { dataObjectCompare } from '@mabadive/app-common-services';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import {
  apiClient,
  apiClientStoreProvider,
  browserCache,
  graphqlClientAuth,
} from 'src/_common-browser';
import {
  appDataLoader,
  clubBaseDataStore,
} from 'src/business/_core/data/loaders';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { appWebLogger } from 'src/lib/browser';
import { AuthenticationClientError } from './AuthenticationClientError.type';
import { JWT_TOKEN_BROWSER_STORAGE_ID } from './JWT_TOKEN_BROWSER_STORAGE_ID.const';
import { authenticationStore } from './authenticationStore.service';
import { AppAuth, AppSecurityUser } from './model';
import { tokenParser } from './tokenParser.service';

export const authenticationClient = {
  refreshToken,
  authenticateByToken,
  authenticateAsDemo,
  authenticateByLoginPassword,
  impersonateClubProfile,
  switchClubDiveCenter,
  impersonateBack,
  createCustomerSpaceBookingMemberKeys,
  createCustomerSpaceNewCustomerAccountLink,
  expireCustomerSpaceNewCustomerAccountLink,
  logout,
  removeBrowserJwtToken,
  setAppAuth,
  clearAppAuth,
  appAuthEquals,
  createTrialClubAccount,
  resetPassword,
  choosePassword,
  resetPasswordQueryPromise,
};

function appAuthEquals(a: AppAuth, b: AppAuth): boolean {
  return dataObjectCompare.objectsEquals(a, b, {
    attributes: (appAuth: AppAuth) => [
      appAuth.isAuthenticated,
      appAuth.user ? appAuth.user.userId : undefined,
      appAuth.user ? appAuth.user.roles : undefined,
    ],
  });
}

function refreshToken() {
  return apiClient
    .put<{ token: string }>('/auth', {
      authenticate: true,
    })
    .pipe(
      switchMap((response) => {
        if (response && response.token) {
          return setAppAuthToken(response.token).pipe(
            switchMap(
              () =>
                browserCache.set(JWT_TOKEN_BROWSER_STORAGE_ID, response.token, {
                  ignoreError: true,
                }),
              (securityUser) => securityUser,
            ),
            catchError((err) => {
              const authenticationClientError: AuthenticationClientError = {
                status: undefined,
                message: 'Invalid token',
                originalMessage: undefined,
              };
              return throwError(authenticationClientError);
            }),
          );
        }
        const authenticationClientError: AuthenticationClientError = {
          status: undefined,
          message: 'Invalid response from server',
          originalMessage: undefined,
        };
        return throwError(authenticationClientError);
      }),
      catchError((err: any) => {
        appLogger.warn(
          '[authenticationClient.refreshToken] Authentication denied',
          err,
        );
        return of(undefined); // ignore error (401 error is catched by errorCallback)
      }),
    );
}

function authenticateByToken(token: string): Observable<AppSecurityUser> {
  // appLogger.info('[authenticationClient.authenticateByToken] token', token);
  if (token) {
    return setAppAuthToken(token).pipe(
      switchMap((user) => {
        return authenticationClient.refreshToken().pipe(
          catchError((err: AuthenticationClientError) => {
            if (err && err.status === 401) {
              appLogger.warn(
                '[refreshTokenManager.authenticateByToken] security error: logout',
              );
              authenticationStore.logoutRequired.set(true);
              return NEVER;
            }
            return of(user);
          }),
          mapTo(user),
        );
      }),
    );
  }
  return throwError(new Error('Invalid token'));
}

function authenticateByLoginPassword({
  login,
  password,
}: {
  login: string;
  password: string;
}): Observable<AppSecurityUser> {
  return apiClient
    .post<{ token: string }>('/auth', {
      json: {
        login,
        password,
      },
    })
    .pipe(
      switchMap((response) => {
        if (response && response.token) {
          return setAppAuthToken(response.token).pipe(
            switchMap(
              () =>
                browserCache.set(JWT_TOKEN_BROWSER_STORAGE_ID, response.token, {
                  ignoreError: true,
                }),
              (securityUser) => securityUser,
            ),
          );
        }
        // invalid response
        appLogger.warn(
          '[authenticationClient.authenticateByLoginPassword] Invalid response from server',
          response,
        );
        return throwError(new Error('Invalid response from server'));
      }),
      catchError((err) => {
        appLogger.warn(
          '[authenticationClient.authenticateByLoginPassword] Authentication denied',
          err,
        );
        return throwError(err);
      }),
    );
}

function choosePassword({
  login,
  password,
}: {
  login: string;
  password: string;
}): Observable<AppSecurityUser> {
  return apiClient
    .put<{ token: string }>('/auth/password', {
      json: {
        login,
        password,
      },
      authenticate: true,
    })
    .pipe(
      switchMap((response) => {
        if (response && response.token) {
          return setAppAuthToken(response.token).pipe(
            switchMap(
              () =>
                browserCache.set(JWT_TOKEN_BROWSER_STORAGE_ID, response.token, {
                  ignoreError: true,
                }),
              (securityUser) => securityUser,
            ),
          );
        }
        // invalid response
        appLogger.warn(
          '[authenticationClient.authenticateByLoginPassword] Invalid response from server',
          response,
        );
        return throwError(new Error('Invalid response from server'));
      }),
      catchError((err) => {
        appLogger.warn(
          '[authenticationClient.authenticateByLoginPassword] Authentication denied',
          err,
        );
        return throwError(err);
      }),
    );
}

function resetPassword(): Observable<{ success: boolean }> {
  return apiClient
    .post<{ success: boolean }>('/auth/reset-password', {
      authenticate: true,
    })
    .pipe(
      switchMap((response) => {
        if (response && response.success) {
          return of({ success: true });
        }
        // invalid response
        appLogger.warn(
          '[authenticationClient.resetPassword] Invalid response from server',
          response,
        );
        return throwError(new Error('Invalid response from server'));
      }),
      catchError((err) => {
        appLogger.warn(
          '[authenticationClient.resetPassword] Authentication denied',
          err,
        );
        return throwError(err);
      }),
    );
}

function createTrialClubAccount({
  body,
}: {
  body: any;
}): Observable<AppSecurityUser> {
  return apiClient
    .post<{ token: string }>('/auth/create-trial-club', {
      json: body,
    })
    .pipe(
      switchMap((response) => {
        if (response && response.token) {
          return setAppAuthToken(response.token).pipe(
            switchMap(
              () =>
                browserCache.set(JWT_TOKEN_BROWSER_STORAGE_ID, response.token, {
                  ignoreError: true,
                }),
              (securityUser) => securityUser,
            ),
          );
        }
        // invalid response
        appLogger.warn(
          '[authenticationClient.createTrialClubAccount] Invalid response from server',
          response,
        );
        return throwError(new Error('Invalid response from server'));
      }),
      catchError((err) => {
        appLogger.warn(
          '[authenticationClient.createTrialClubAccount] Authentication denied',
          err,
        );
        return throwError(err);
      }),
    );
}

function resetPasswordQueryPromise({ login }: { login: string }) {
  return apiClient
    .post<void>('/auth/reset-password-from-login-page', {
      authenticate: false,
      json: { login },
    })
    .toPromise();
}

function authenticateAsDemo(): Observable<AppSecurityUser> {
  return apiClient.post<{ token: string }>('/demo').pipe(
    switchMap((response) => {
      if (response && response.token) {
        return setAppAuthToken(response.token).pipe(
          switchMap(
            () =>
              browserCache.set(JWT_TOKEN_BROWSER_STORAGE_ID, response.token, {
                ignoreError: true,
              }),
            (securityUser) => securityUser,
          ),
        );
      }
      // invalid response
      appLogger.warn(
        '[authenticationClient.authenticateAsDemo] Invalid response from server',
        response,
      );
      return throwError(new Error('Invalid response from server'));
    }),
    catchError((err) => {
      appLogger.warn(
        '[authenticationClient.authenticateAsDemo] Authentication denied',
        err,
      );
      return throwError(new Error('Authentication denied'));
    }),
  );
}

function impersonateBack(): Observable<AppSecurityUser> {
  return apiClient
    .post<{ token: string }>('/auth/impersonate-back', {
      authenticate: true,
    })
    .pipe(
      switchMap((response) => {
        if (response && response.token) {
          return setAppAuthToken(response.token)
            .pipe(
              switchMap(
                () =>
                  browserCache.set(
                    JWT_TOKEN_BROWSER_STORAGE_ID,
                    response.token,
                    {
                      ignoreError: true,
                    },
                  ),
                (securityUser) => securityUser,
              ),
            )
            .pipe(
              tap(() => {
                appWebLogger.logout();
                clubBaseDataStore.clearData();
              }),
            );
        }
        // invalid response
        appLogger.warn(
          '[authenticationClient.impersonateBack] Invalid response from server',
          response,
        );
        return throwError(new Error('Invalid response from server'));
      }),
      catchError((err) => {
        appLogger.warn(
          '[authenticationClient.impersonateBack] Authentication denied',
          err,
        );
        return throwError(new Error('Authentication denied'));
      }),
    );
}

function impersonateClubProfile({
  clubReference,
  diveCenterId,
  clubProfileId,
}: {
  clubReference: string;
  diveCenterId?: string; // obligatoire sauf si super admin
  clubProfileId?: string; // obligatoire sauf si super admin
}): Observable<AppSecurityUser> {
  return apiClient
    .post<{ token: string }>('/auth/impersonate-club-profile', {
      authenticate: true,
      json: { clubReference, diveCenterId, clubProfileId },
    })
    .pipe(
      switchMap((response) => {
        if (response && response.token) {
          return setAppAuthToken(response.token).pipe(
            switchMap(
              () =>
                browserCache.set(JWT_TOKEN_BROWSER_STORAGE_ID, response.token, {
                  ignoreError: true,
                }),
              (securityUser) => securityUser,
            ),
          );
        }
        // invalid response
        appLogger.warn(
          '[authenticationClient.impersonateClubProfile] Invalid response from server',
          response,
        );
        return throwError(new Error('Invalid response from server'));
      }),
      catchError((err) => {
        appLogger.warn(
          '[authenticationClient.impersonateClubProfile] Authentication denied',
          err,
        );
        return throwError(new Error('Authentication denied'));
      }),
    );
}

function switchClubDiveCenter({
  clubReference,
  diveCenterId,
}: {
  clubReference: string;
  diveCenterId: string;
}): Observable<AppSecurityUser> {
  return apiClient
    .post<{ token: string }>('/auth/switch-dive-center', {
      authenticate: true,
      json: { clubReference, diveCenterId },
    })
    .pipe(
      switchMap((response) => {
        if (response && response.token) {
          appDataLoader.clear();
          return setAppAuthToken(response.token).pipe(
            switchMap(
              () =>
                browserCache.set(JWT_TOKEN_BROWSER_STORAGE_ID, response.token, {
                  ignoreError: true,
                }),
              (securityUser) => securityUser,
            ),
          );
        }
        // invalid response
        appLogger.warn(
          '[authenticationClient.impersonateClubProfile] Invalid response from server',
          response,
        );
        return throwError(new Error('Invalid response from server'));
      }),
      catchError((err) => {
        appLogger.warn(
          '[authenticationClient.impersonateClubProfile] Authentication denied',
          err,
        );
        return throwError(new Error('Authentication denied'));
      }),
    );
}

async function createCustomerSpaceBookingMemberKeys({
  bookingId,
}: {
  bookingId: string;
}): Promise<{
  booking: Booking;
  bookingMembers: CustomerSpaceBookingMemberWithDiver[];
}> {
  const response = await apiClient
    .post<any>(`/auth/create-booking-members-keys/${bookingId}`, {
      authenticate: true,
      json: {},
    })
    .toPromise();
  return response;
}

async function createCustomerSpaceNewCustomerAccountLink({
  payload,
}: {
  payload: ProMultiOperationPayload;
}): Promise<string> {
  const response = await apiClient
    .post<any>('/auth/create-new-customer-account-link', {
      authenticate: true,
      json: { payload },
    })
    .toPromise();
  return response;
}
async function expireCustomerSpaceNewCustomerAccountLink(): Promise<string> {
  const response = await apiClient
    .post<any>('/auth/expire-new-customer-account-link', {
      authenticate: true,
      json: {},
    })
    .toPromise();
  return response;
}

function removeBrowserJwtToken() {
  return browserCache.remove(JWT_TOKEN_BROWSER_STORAGE_ID, {
    ignoreError: true,
  });
}
function logout() {
  appLogger.info('[authenticationClient.logout]');
  return removeBrowserJwtToken().pipe(switchMap(() => clearAppAuth()));
}

function clearAppAuth(): Observable<any> {
  return setAppAuth({
    user: undefined,
    jwtToken: undefined,
  }).pipe(
    tap(() => {
      appWebLogger.logout();
      clubBaseDataStore.clearData();
    }),
  );
}

function setAppAuthToken(token: string): Observable<AppSecurityUser> {
  // appLogger.info('[authenticationClient.setAppAuthToken] token: ', token);
  const securityUser = tokenParser.parse(token);

  appLogger.debug(
    '[authenticationClient.setAppAuthToken] securityUser: ',
    securityUser,
  );
  if (
    !securityUser.tokenInfo ||
    securityUser.tokenInfo?.version !== CURRENT_JWT_TOKEN_VERSION
  ) {
    appLogger.warn(
      `[authenticationClient.setAppAuthToken] invalid token version (${securityUser.tokenInfo?.version}  !== ${CURRENT_JWT_TOKEN_VERSION}): `,
      securityUser,
    );
    return clearAppAuth().pipe(
      switchMap(() => throwError(new Error('Invalid token version'))),
    );
  }
  if (securityUser.tokenInfo?.expirationDate.getTime() < new Date().getTime()) {
    appLogger.warn(
      '[authenticationClient.setAppAuthToken] token expired: ',
      securityUser,
    );
    return clearAppAuth().pipe(
      switchMap(() => throwError(new Error('Expired token'))),
    );
  }

  if (securityUser?.roles.includes('club') && !securityUser.clubReference) {
    appLogger.warn(
      '[authenticationClient.setAppAuthToken] clubReference missing in token: ',
      securityUser,
    );
    return clearAppAuth().pipe(
      switchMap(() => throwError(new Error('Token without clubReference'))),
    );
  }

  return setAppAuth(
    {
      user: securityUser,
      jwtToken: token,
    },
    'authenticate',
  ).pipe(mapTo(securityUser));
}

function setAppAuth(
  {
    user,
    jwtToken,
  }: {
    user: AppSecurityUser;
    jwtToken: string;
  },
  actionId = 'setAppAuth',
): Observable<any> {
  appLogger.debug('[authenticationClient.setAppAuth] user: ', user);

  const apiClientStore = apiClientStoreProvider.get();

  // store token to graphql & api client BEFORE to put it in store (to be sure all request depending of auth store will be authenticated)

  return (
    appWebConfig.data.graphql.enabled && user && user.hasuraRoles
      ? graphqlClientAuth.login(jwtToken)
      : of(undefined)
  ).pipe(
    tap(() => {
      appLogger.debug('[authenticationClient.setAppAuth] login done', user);
      apiClientStore.authenticationToken.set(jwtToken, actionId);

      appWebLogger.login(user);

      if (user) {
        authenticationStore.auth.set(
          {
            isAuthenticated: true,
            user,
          },
          actionId,
        );
        authenticationStore.logoutRequired.set(false);
      } else {
        authenticationStore.auth.set(
          {
            isAuthenticated: false,
          },
          actionId,
        );
        authenticationStore.logoutRequired.set(false);
      }
    }),
    catchError((err) => {
      appLogger.error('[authenticationClient.setAppAuth] error', err);
      return throwError(err);
    }),
  );
}
