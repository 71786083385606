/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettingsUIPlanning,
  DiveCenterDailyConfig,
  DiveSessionResumeFull,
  DiveSessionResumeParticipantsByGroup,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  diveSessionGroupBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useMediaSizeMaterialUI } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { diveSessionParticipantStatsBuilder } from 'src/business/club/data';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { AppHamburgerMenu } from '../../_common/ui';
import {
  ClubDiveSessionHeaderComments,
  ClubDiveSessionHeaderParticipants,
  ClubDiveSessionHeaderSecurityStaff,
  ClubDiveSessionHeaderSessionDate,
  ClubDiveSessionHeaderStatus,
  ClubDiveSessionHeaderTimeAndDiveSite,
  ClubDiveSessionHeaderTitle,
  ClubDiveSessionHeaderTitleWithPrefix,
} from '../../club-dive-session/components';
import {
  DiveSessionSecuritySheetCardMenuActionContext,
  DiveSessionSecuritySheetCardMenuActionsDef,
  DiveSessionSimpleCardParticipantsGroupFakePanel,
  DiveSessionSimpleCardParticipantsGroupPanel,
  useDiveSessionSecuritySheetCardMenu,
} from '../components';
import { diveSessionStyleBuilder } from '../components/ClubPlanningLightSession/diveSessionStyleBuilder.service';

/**
 *
 * Cet écran est affiché uniquement quand, sur une résa, on clique sur le titre d'une session
 */
export const ClubPlanningSessionDialogPanel = ({
  diveSessionResumeFull: session,
  dailyConfigs,
  settingsUIPlanning,
  menuActions,
  className,
}: {
  diveSessionResumeFull: DiveSessionResumeFull;
  dailyConfigs: DiveCenterDailyConfig[];
  settingsUIPlanning: ClubSettingsUIPlanning;
  menuActions?: DiveSessionSecuritySheetCardMenuActionsDef;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const staffMembers = diveCenterResume?.staffMembers;

  const mediaSize = useMediaSizeMaterialUI();

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );

  const { bookingSessionParticipants, participants, groups } = session;

  const diveSessionParticipantsByGroup: DiveSessionResumeParticipantsByGroup =
    useMemo(
      () =>
        diveSessionGroupBuilder.buildDiveSessionParticipantsGroups({
          diveSession: session,
          groups,
          participants,
          staffMembers,
          ignoreCancelledParticipants: true,
          ignoreGroupsWithoutParticipants: false,
        }),
      [session, groups, participants, staffMembers],
    );

  const diveSessionParticipantsStats = useMemo(() => {
    if (!diveSessionParticipantsByGroup) {
      return undefined;
    }
    return diveSessionParticipantStatsBuilder.buildDiveSessionParticipantsStats(
      {
        clubReference,
        session,
        participants: diveSessionParticipantsByGroup.all,
        groups: diveSessionParticipantsByGroup.allGroups,
        staffMembers: diveCenterResume?.staffMembers,
        dailyConfigs,
        clubSettings: diveCenterResume?.clubResume?.clubSettings,
      },
    );
  }, [
    diveSessionParticipantsByGroup,
    clubReference,
    session,
    diveCenterResume?.staffMembers,
    diveCenterResume?.clubResume?.clubSettings,
    dailyConfigs,
  ]);

  const menuContext = useMemo(() => {
    const context: DiveSessionSecuritySheetCardMenuActionContext = {
      diveSession: {
        ...session,
      },
    };
    return context;
  }, [session]);

  const hamburgerMenuItems = useDiveSessionSecuritySheetCardMenu({
    menuActions,
    menuContext,
  });
  const isMultiSession = !!session.diveTourSession2;
  const groupDiveSite1AndTitle = !isMultiSession && !session.diveSiteId;

  const sessionBorderStyle = diveSessionStyleBuilder.buildBorderStyleFromTime({
    isVirtual: false,
    time: session.time,
  });
  return (
    <div className={`text-sm text-gray-600 ${className}`}>
      {/* <h2 className="text-base sm:text-lg leading-6 pt-0 mt-2 text-center">
        <span className="font-medium text-gray-800">{dateLabel}</span>
      </h2> */}
      {/* <DiveSessionSimpleCardTime
        className="mt-2"
        // displaySessionIndex={display.sessionIndex}
        session={session}
      /> */}
      <div className={`relative border ${sessionBorderStyle}`}>
        <ClubDiveSessionHeaderSessionDate
          className="px-1 bg-gray-600 py-0.5"
          diveSession={session}
        />
        {groupDiveSite1AndTitle ? (
          <ClubDiveSessionHeaderTitleWithPrefix
            className={`border-t-0 ${sessionBorderStyle}`}
            diveSession={session}
            sessionIndex={1}
          />
        ) : (
          <>
            <ClubDiveSessionHeaderTimeAndDiveSite
              className={`border-t-0 ${sessionBorderStyle}`}
              diveSiteId={session.diveSiteId}
              time={session.time}
              isMultiSession={isMultiSession}
              sessionIndex={1}
              widthMode={'large'}
            />
            {isMultiSession && (
              <ClubDiveSessionHeaderTimeAndDiveSite
                className={`border-t ${sessionBorderStyle}`}
                diveSiteId={session.diveTourSession2?.diveSiteId}
                time={session.diveTourSession2?.time}
                isMultiSession={isMultiSession}
                sessionIndex={2}
                widthMode={'large'}
              />
            )}
            <ClubDiveSessionHeaderTitle
              diveSession={session}
              className={`py-0.5 border-t ${sessionBorderStyle}`}
            />
          </>
        )}
        <ClubDiveSessionHeaderStatus className="py-0.5" diveSession={session} />
        <ClubDiveSessionHeaderComments
          className={`py-0.5 border-t ${sessionBorderStyle}`}
          session={session}
          display={{
            comment: true,
            tide: true,
            weather: true,
          }}
          disableBgColor={true}
        />
        <div
          className={`px-0.5 flex justify-between border-t ${sessionBorderStyle}`}
        >
          <div className=" py-0.5 ">
            <ClubDiveSessionHeaderParticipants
              className={'py-0.5 font-bold text-xs items-start'}
              diveSessionParticipantsStats={diveSessionParticipantsStats}
            />
            <ClubDiveSessionHeaderSecurityStaff
              className={'justify-end text-xs'}
              diveSession={session}
              staffFirstNameBefore={settingsUIPlanning.staffFirstNameBefore}
            />
          </div>
        </div>
      </div>
      <div className={`mt-2 border ${sessionBorderStyle}`}>
        <div className="flex flex-col gap-4">
          {diveSessionParticipantsByGroup.byGroupExtended.map(
            ({ group, participants, type }, groupIndex) => (
              <DiveSessionSimpleCardParticipantsGroupPanel
                key={`group-${group?._id ?? 'fake'}-${groupIndex}`}
                diveSession={session}
                isPast={false}
                group={group}
                groupOdd={groupIndex % 2 === 0}
                settingsUIPlanning={settingsUIPlanning}
                participants={participants}
                widthMode={'large'}
                onClickParticipant={undefined}
                onClickGroupDetails={undefined}
                anchorLinkParticipant={undefined}
              />
            ),
          )}
          {diveSessionParticipantsByGroup.assignedFreeInstructors?.length >
            0 && (
            <div className="grid gap-1">
              {diveSessionParticipantsByGroup.assignedFreeInstructors.map(
                (staffMember, staffMemberIndex) => (
                  <DiveSessionSimpleCardParticipantsGroupFakePanel
                    key={staffMember._id}
                    staffMember={staffMember}
                    diveSession={session}
                    isPast={false}
                    settingsUIPlanning={settingsUIPlanning}
                    widthMode={'large'}
                  />
                ),
              )}
            </div>
          )}
        </div>
      </div>
      <div className="mt-2 w-full flex flex-col gap-2">
        <AppHamburgerMenu
          className="w-full"
          buttonIcon={AppHeroIcons.actionMenu}
          buttonClassName={`w-full py-2 bg-day-period-${timeDayPeriod}-dark hover:bg-day-period-${timeDayPeriod}-dark2 text-gray-200 hover:text-white`}
          items={hamburgerMenuItems}
          position={'auto'}
          buttonLabel={'Menu'}
          buttonLabelClasses="uppercase"
        />
      </div>
    </div>
  );
};
