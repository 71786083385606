import {
  ProStatsChartConfig,
  ProStatsFetchCriteria,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { proStatsApiClient } from '../_core/fetch/proStatsApiClient.service';
import { DashboardFetchCriteriaPeriod } from '../_core/model/fetch/DashboardFetchCriteria.type';
import { DashboardReportPageMode } from './model';

export function useDashboardReportFetchStats(
  criteria: {
    period: DashboardFetchCriteriaPeriod;
    charts: ProStatsChartConfig;
  },
  {
    pageMode,
  }: {
    pageMode: DashboardReportPageMode;
  },
): {
  loadingInProgress: boolean;
  fetchResult: ProStatsFetchResults;
  fetchResultFake: ProStatsFetchResults;
} {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const clubReference = diveCenterResume.clubReference;

  const period = criteria?.period; // le second graph n'est pas forcément activé
  const charts = criteria?.charts;

  const fetchCriteria: ProStatsFetchCriteria = useMemo(() => {
    const apiFetchCriteria: ProStatsFetchCriteria = {
      beginDate: period?.beginDate,
      endDate: period?.endDate,
      clubReference,
      diveCenterId,
      charts,
    };
    return apiFetchCriteria;
  }, [charts, clubReference, diveCenterId, period?.beginDate, period?.endDate]);

  const query: UseQueryResult<ProStatsFetchResults, unknown> = useQuery({
    queryKey: ['pro-stats-report', fetchCriteria],
    queryFn: async () => {
      return proStatsApiClient.fetch(fetchCriteria);
    },
    refetchInterval: 1000 * 60 * 60, // refresh every 60 minutes
    enabled: !!period && !!charts,
  });

  const { isLoading: loadingInProgress, data: fetchResult } = query;

  const [fetchResultsFakes, setFetchResultsFakes] = useState<
    Partial<
      {
        [pageMode in DashboardReportPageMode]: ProStatsFetchResults;
      }
    >
  >({});

  const fetchResultFake = fetchResultsFakes[pageMode];

  useEffect(() => {
    if (!!fetchResult && fetchResult !== fetchResultFake) {
      // on mémorise le dernier résultat, même si il ne correspond pas aux criteria, afin d'afficher le composant en "flou" plutôt que de le masquer
      // on ne renvoit par contre qu'un résultat qui correspond au pageMode
      const updatedfetchResultsFakes: Partial<
        {
          [pageMode in DashboardReportPageMode]: ProStatsFetchResults;
        }
      > = {
        ...fetchResultsFakes,
      };
      updatedfetchResultsFakes[pageMode] = fetchResult;
      setFetchResultsFakes(updatedfetchResultsFakes);
    }
  }, [fetchResult, fetchResultFake, fetchResultsFakes, pageMode]);

  return {
    loadingInProgress,
    fetchResult,
    fetchResultFake,
  };
}
