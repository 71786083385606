import {
  AppCurrency,
  AppEntityUpdatePatch,
  CreditNote,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { BookingCreditNoteEditDialogProps } from './model';

export type BookingCreditNoteEditDialogActionPersistProps = {
  onCreate: ({ creditNote }: { creditNote: CreditNote }) => any;
  onUpdate: (props: {
    creditNote: CreditNote;
    patch: AppEntityUpdatePatch;
  }) => any;
};

/**
* Exécution:
* - 1) dans le dialog, on appelle inputState.onConfirm()
* - 2) inputState a été passé par ClubDialogsProvider, qui a récupéré ça de useClubDialogs, et useClubDialogsActionsPersist
* - 3) les callback passées en paramètre à useClubDialogsActionsPersist sont alors appelés à l'intérieur de useBookingCreditNoteEditDialogActionPersist
* 
*
 const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
  createMessageToCustomers: {
    onUpdate: () => {
      console.log('[useClubDialogsActionsPersist] onUpdate called!');
    },
  },
});
*/
export function useBookingCreditNoteEditDialogActionPersist(
  props: BookingCreditNoteEditDialogActionPersistProps,
): BookingCreditNoteEditDialogProps {
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  const appCurrency: AppCurrency = useAppCurrencyMain({ generalSettings });

  const action: BookingCreditNoteEditDialogProps = {
    async onConfirm({ formValue }, initialState) {
      const { mode, defaultValue } = initialState;
      if (mode === 'create') {
        const creditNote: CreditNote =
          commonEntityBuilder.buildEntity<CreditNote>({
            clubReference,
            diveCenterId,
            ...formValue.creditNote,
            currencyIsoCode: appCurrency.isoCode,
          });
        if (props?.onCreate) {
          props?.onCreate({ creditNote });
        }
      } else if (mode === 'edit') {
        const creditNotePatchOperations = jsonPatcher.compareObjects(
          defaultValue.creditNote,
          {
            ...formValue.creditNote,
          },
          {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: true,
          },
        );

        if (creditNotePatchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: defaultValue.creditNote._id,
            patchOperations: creditNotePatchOperations,
          };
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'save-credit-note',
              bookingIdsToClean: [],
            });
          if (!payload.creditNotes) {
            payload.creditNotes = {
              created: [],
              deletedIds: [],
              updated: [],
            };
          }

          payload.creditNotes.updated.push(patch);
          if (props?.onUpdate) {
            props?.onUpdate({
              creditNote: formValue.creditNote as CreditNote,
              patch,
            });
          } else {
          }
        }
      }
    },
  };
  return action;
}
