/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppDocUploadContext,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import Tippy from '@tippyjs/react';
import React, { useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';
import { AppButton, AppMessageLight } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppDocResumeImageUploader,
  AppFormControlRHF,
  AppInputColorRHF,
  AppInputRHF,
} from 'src/lib/form';
import {
  DiveCenterEditDialogInitialState,
  DiveCenterEditFormModel,
} from '../model';
import { ClubSettingsForm_BillingDetails } from './ClubSettingsForm_BillingDetails';
import { ClubSettingsForm_LegalAddress } from './ClubSettingsForm_LegalAddress';
import { ClubSettingsForm_LegalInformation } from './ClubSettingsForm_LegalInformation';
import { ClubSettingsForm_SocialNetworks } from './ClubSettingsForm_SocialNetworks';

export const DiveCenterEditForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<DiveCenterEditFormModel>;
  initialState: DiveCenterEditDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const { mode } = initialState;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  console.log('xxx isSuperAdmin:', isSuperAdmin);

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const [diveCenterName, clubName] = useWatch({
    control,
    name: [
      'diveCenter.publicData.brand.name',
      'clubSettings.publicData.brand.name',
    ],
  });

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const contact = useMemo(
    () => ({
      email: `${appWebConfig.c.e[0]}@${appWebConfig.c.e[1].join('.')}`,
    }),
    [],
  );

  const contactsFieldArray = useFieldArray({
    control,
    name: 'diveCenter.publicData.contacts',
  });

  const appDocUploadContext: AppDocUploadContext = {
    clubDiverId: undefined,
    bookingId: undefined,
    bookingMemberId: undefined,
    appBookletPageId: undefined,
    fileType: 'image',
    scope: 'company',
    isPublic: true,
  };
  const [logoSquareUrl] = useWatch({
    control,
    name: ['clubSettings.publicData.brand.logoSquareUrl'],
  });
  const logoInitialySet = useMemo(() => !!logoSquareUrl, [logoSquareUrl]);
  const [showImageSelector, setShowImageSelector] = useState(false);

  return (
    <>
      <h3 className="mt-5 mb-2 uppercase text-base sm:text-lg text-app-blue font-bold">
        Centre de plongée "{diveCenterName}"
      </h3>
      {showImageSelector && (
        <AppDocResumeImageUploader
          uploadUrl="/pro/docs/upload"
          expectedImage={{
            width: 600,
            height: 600,
            variantsRatio: [1 / 2],
          }}
          uploadPayload={{ context: appDocUploadContext }}
          onSuccess={(appDoc: PRO_AppDocResume) => {
            // const coverImage: AppTranslationContentItemDoc = {
            //   _id: appDoc._id,
            //   fileType: appDoc.fileType,
            //   publicUrl: appDoc.publicUrl,
            // };
            setValue(
              'clubSettings.publicData.brand.logoSquareUrl',
              appDoc.publicUrl,
            );
            setShowImageSelector(false);
          }}
          onCancel={() => {
            setShowImageSelector(false);
          }}
        />
      )}
      <h4 className="mt-4 mb-2 font-bold text-app-secondary uppercase">
        Coordonnées et contact public
      </h4>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
        <div className="lg:col-span-2 grid sm:grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-2">
          <AppFormControlRHF
            className="col-span-2"
            label="Nom complet"
            control={control}
            name="diveCenter.publicData.brand.name"
            required={true}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                onChange={(value) => {
                  if (!isMultiDiveCenters) {
                    setValue('diveCenter.privateData.proName', value as string);
                    setValue(
                      'clubSettings.publicData.brand.name',
                      value as string,
                    );
                  }
                }}
              />
            )}
          />
          <AppFormControlRHF
            label="Ville, Région / Pays"
            control={control}
            name="diveCenter.publicData.brand.locationResume"
            required={true}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          <AppFormControlRHF
            label="Logo"
            control={control}
            name="clubSettings.publicData.brand.logoSquareUrl"
            // validation={{
            //   rules: {
            //     maxLength: 200,
            //   },
            // }}
            required={logoInitialySet} // obligatoire si déjà existant
            renderComponent={() => (
              <div
                className={'cursor-pointer flex gap-8 items-end'}
                onClick={() => setShowImageSelector(true)}
              >
                {logoSquareUrl && (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img className="h-[40px]" src={logoSquareUrl} />
                )}
                <AppButton
                  icon={AppIconsAction.actionAddPhoto}
                  color={'primary-outline-light'}
                >
                  {logoSquareUrl ? 'Remplacer' : 'Image'}
                </AppButton>
              </div>
            )}
          />{' '}
        </div>
        <div className="lg:col-span-2 grid sm:grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-2">
          <AppFormControlRHF
            className="col-span-2"
            label="Nom contact principal (optionnel)"
            control={control}
            name={'diveCenter.publicData.contact.label' as const}
            required={false}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          <AppFormControlRHF
            label="Numéro de téléphone"
            control={control}
            name="diveCenter.publicData.contact.phoneNumber"
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          <AppFormControlRHF
            label="Adresse e-mail"
            control={control}
            name="diveCenter.publicData.contact.emailAddress"
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        </div>

        {(contactsFieldArray.fields ?? []).map((field, fieldIndex) => (
          <div
            key={field.id}
            className="lg:col-span-2 grid sm:grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-2"
          >
            <div className="col-span-2 flex gap-2">
              <AppFormControlRHF
                className="flex-grow"
                label={`Nom contact ${fieldIndex + 1} (optionnel)`}
                control={control}
                name={
                  `diveCenter.publicData.contacts.${fieldIndex}.label` as const
                }
                required={false}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth />
                )}
              />
              <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
                <AppIconsAction.delete
                  className="p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer"
                  onClick={() => {
                    contactsFieldArray.remove(fieldIndex);
                  }}
                />
              </Tippy>
            </div>
            <AppFormControlRHF
              label="Numéro de téléphone"
              control={control}
              name={
                `diveCenter.publicData.contacts.${fieldIndex}.phoneNumber` as const
              }
              renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
            />
            <AppFormControlRHF
              label="Adresse e-mail"
              control={control}
              name={
                `diveCenter.publicData.contacts.${fieldIndex}.emailAddress` as const
              }
              renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
            />
          </div>
        ))}

        {isMultiDiveCenters && (
          <AppFormControlRHF
            className="flex-grow"
            label="Nom sur interface PRO"
            control={control}
            name="diveCenter.privateData.proName"
            required={true}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        )}
      </div>
      <AppButton
        className="my-4"
        size="normal"
        icon={AppHeroIcons.actionAdd}
        color="primary-outline-light"
        onClick={() => {
          contactsFieldArray.append({
            label: '',
          } as any);
        }}
      >
        Ajouter un contact
      </AppButton>
      {mode === 'edit' && (
        <>
          {isMultiDiveCenters && (
            <h3 className="mt-5 mb-2 uppercase text-base sm:text-lg text-app-blue font-bold">
              Groupe "{clubName}"
            </h3>
          )}
          {isMultiDiveCenters && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
              <AppFormControlRHF
                label={'Nom'}
                control={control}
                required={true}
                name="clubSettings.publicData.brand.name"
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="text" />
                )}
              />
              <AppFormControlRHF
                label={'Ville, Région / Pays'}
                control={control}
                required={true}
                name="clubSettings.publicData.brand.locationResume"
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="text" />
                )}
              />
              <AppFormControlRHF
                label={'Numéro de téléphone'}
                control={control}
                name="clubSettings.publicData.contact.phoneNumber"
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="text" />
                )}
              />
              <AppFormControlRHF
                label={'Adresse e-mail'}
                control={control}
                name="clubSettings.publicData.contact.emailAddress"
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="text" />
                )}
              />
            </div>
          )}

          <div className="grid gap-8">
            <ClubSettingsForm_SocialNetworks form={form} />
            <ClubSettingsForm_LegalInformation form={form} />
            <ClubSettingsForm_LegalAddress form={form} />
            {isSuperAdmin && <ClubSettingsForm_BillingDetails form={form} />}
          </div>
          {isSuperAdmin && (
            <>
              {' '}
              <h4 className="mt-4 mb-2 font-bold text-app-secondary uppercase">
                Complément d'information (admin)
              </h4>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
                <AppFormControlRHF
                  label={'Logo carré 300x300'}
                  control={control}
                  name="clubSettings.publicData.brand.logoSquareUrl"
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth type="text" />
                  )}
                />
                <AppFormControlRHF
                  className="flex-grow"
                  label="Nom entreprise"
                  control={control}
                  name="clubSettings.publicData.brand.name"
                  required={true}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} fullWidth />
                  )}
                />
              </div>
            </>
          )}
        </>
      )}
      {isSuperAdmin && (
        <>
          <h4 className="mt-4 mb-2 font-bold text-app-secondary uppercase">
            Personnalisation des couleurs de l'interface client
          </h4>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2">
            <AppFormControlRHF
              className="flex-grow"
              label="Nom (fond)"
              control={control}
              name="diveCenter.publicSettings.diveCenterUiTheme.name.bgColor"
              required={true}
              renderComponent={(props) => <AppInputColorRHF {...props} />}
            />
            <AppFormControlRHF
              className="flex-grow"
              label="Nom (texte)"
              control={control}
              name="diveCenter.publicSettings.diveCenterUiTheme.name.textColor"
              required={true}
              renderComponent={(props) => <AppInputColorRHF {...props} />}
            />
            <AppFormControlRHF
              className="flex-grow"
              label="Ville, Région / Pays (texte)"
              control={control}
              name="diveCenter.publicSettings.diveCenterUiTheme.location.textColor"
              required={true}
              renderComponent={(props) => <AppInputColorRHF {...props} />}
            />
            <AppFormControlRHF
              className="flex-grow"
              label="Nom sur interface PRO (fond)"
              control={control}
              name="diveCenter.publicSettings.diveCenterUiTheme.proName.bgColor"
              required={true}
              renderComponent={(props) => <AppInputColorRHF {...props} />}
            />
          </div>
          {mode === 'edit' && (
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2">
              <AppFormControlRHF
                className="flex-grow"
                label="Bouton principal (couleur)"
                control={control}
                name="clubSettings.publicSettings.appUiTheme.buttonPrimary.bgColor"
                required={true}
                renderComponent={(props) => <AppInputColorRHF {...props} />}
              />
              <AppFormControlRHF
                className="flex-grow"
                label="Bouton principal (inverse)"
                control={control}
                name="clubSettings.publicSettings.appUiTheme.buttonPrimary.textColor"
                required={true}
                renderComponent={(props) => <AppInputColorRHF {...props} />}
              />
              <AppFormControlRHF
                className="flex-grow"
                label="Bouton annuler (fond)"
                control={control}
                name="clubSettings.publicSettings.appUiTheme.buttonCancel.bgColor"
                required={true}
                renderComponent={(props) => <AppInputColorRHF {...props} />}
              />
              <AppFormControlRHF
                className="flex-grow"
                label="Bouton annuler (texte)"
                control={control}
                name="clubSettings.publicSettings.appUiTheme.buttonCancel.textColor"
                required={true}
                renderComponent={(props) => <AppInputColorRHF {...props} />}
              />
            </div>
          )}
        </>
      )}
      <AppMessageLight className="my-4">
        <p className="my-2">
          Ces informations sont les coordonnées publiques du centre. <br />
          Elles sont affichées sur l'espace client si il est activé.
        </p>
        <p className="my-2">
          Pour modifier votre logo, veuillez me l'
          <a
            className="underline text-app-blue"
            href={`mailto:${contact.email}`}
          >
            envoyer par mail
          </a>
          , si possible au format carré 300x300 (sinon je ferai la
          transformation moi-même).
        </p>
      </AppMessageLight>
    </>
  );
};
