import clsx from 'clsx';
import React from 'react';

export const AppPageTitle: React.FC<{
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}> = ({ children, className }) => {
  return (
    <h2
      className={clsx(
        'text-lg leading-6 p-2 font-medium text-gray-800',
        className,
      )}
    >
      {children}
    </h2>
  );
};
