import { AppCompanyMessageTemplate } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export function MessageTemplateLabel({
  messageTemplate: mt,
}: {
  messageTemplate: AppCompanyMessageTemplate;
}) {
  const Icon =
    mt.style === 'success'
      ? AppHeroIcons.actionConfirm
      : mt.style === 'warning'
      ? AppHeroIcons.warn
      : AppHeroIcons.actionCancel;
  return (
    <p className="truncate text-sm font-medium uppercase">
      <Icon
        className={clsx(
          'inline-block mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400',
          mt.style === 'success'
            ? 'text-status-success'
            : mt.style === 'warning'
            ? 'text-status-warn'
            : 'text-status-error',
        )}
      />
      {mt.name}
    </p>
  );
}
