import React from 'react';
import { AppTabsBarTab } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { DiveSessionEditorTabId } from './DiveSessionEditorTabId.type';

export const DIVE_SESSION_EDITOR_TABS: AppTabsBarTab<DiveSessionEditorTabId>[] =
  [
    {
      id: 'edit-session',
      label: 'Configuration',
      labelShort: 'Config',
      icon: <AppHeroIcons.actionEdit className="w-6 h-6" />,
      urlSuffix: '',
      auth: { requiredRoles: ['club-edit-planning-session'] },
    },
    {
      id: 'list-staff-members',
      label: 'Moniteurs',
      labelShort: 'Monit.',
      icon: <AppHeroIcons.staff className="w-6 h-6" />,
      urlSuffix: 'list-staff-members',
      auth: { requiredRoles: ['club-edit-settings-dive-center'] },
    },
    {
      id: 'edit-dive-groups',
      label: 'Palanquées',
      labelShort: 'Palanq.',
      icon: <AppHeroIcons.group className="w-6 h-6" />,
      urlSuffix: 'edit-dive-groups',
      auth: { requiredRoles: ['club-edit-planning-security-sheet'] },
    },
  ];
