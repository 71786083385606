import {
  ClubResumeStaffMember,
  DiveSession,
  DiveSessionGroup,
  DiveSessionGroupDiveMode,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
  DiveSessionResumeParticipantsByGroup,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  commonEntityBuilder,
  diveSessionGroupDiveModeBuilder,
  jsonParser,
  jsonPatcher,
  participantGroupsBuilder,
} from '@mabadive/app-common-services';
import { DiveSessionEditorUpdateState } from '../../model';

export const diveSessionDialogTab3GroupsStateUpdator = {
  autoGroupDivers,
  groupParticipants,
  removeParticipants,
  updateGroupDiveMode,
};

function autoGroupDivers({
  participants,
  updateState,
  onlyGroupsDiveMode,
  diveSession,
  clubReference,
}: {
  participants: DiveSessionResumeParticipant[];
  updateState: DiveSessionEditorUpdateState;
  onlyGroupsDiveMode: string;
  diveSession: DiveSession;
  clubReference: string;
}): DiveSessionEditorUpdateState {
  const participantsGroups = participantGroupsBuilder.autoGroupParticipants(
    participants /*, 'auto-group'*/,
    { clubReference },
  );

  let localUpdateState = {
    ...updateState,
  };
  for (const participantsGroup of participantsGroups) {
    if (
      !onlyGroupsDiveMode ||
      participantsGroup.diveMode === onlyGroupsDiveMode
    ) {
      localUpdateState =
        diveSessionDialogTab3GroupsStateUpdator.groupParticipants({
          existingGroup: undefined,
          updateState: localUpdateState,
          diveSession,
          diveMode: participantsGroup.diveMode,
          clubReference,
          participants: participantsGroup.participants,
        });
    }
  }
  return localUpdateState;
}

function updateGroupDiveMode({
  _id,
  newDiveMode,
  previousDiveMode,
  updateState,
}: {
  _id: string;
  newDiveMode: DiveSessionGroupDiveMode;
  previousDiveMode: DiveSessionGroupDiveMode;
  updateState: DiveSessionEditorUpdateState;
}): DiveSessionEditorUpdateState {
  let localUpdateState = {
    ...updateState,
  };

  const patchOperations = jsonPatcher.compareObjects<DiveSessionGroup>(
    {
      _id,
      diveMode: previousDiveMode,
    },
    {
      _id,
      diveMode: newDiveMode,
    },
    {
      replaceDeleteByNullValue: true,
    },
  );

  if (patchOperations.length) {
    localUpdateState.diveSessionGroupsChanges =
      changeDescriptorManager.updateOne(
        {
          pk: _id,
          patchOperations,
        },
        {
          changeDescriptors: localUpdateState.diveSessionGroupsChanges,
        },
      );
  }
  return localUpdateState;
}

function groupParticipants({
  updateState,
  existingGroup,
  diveSession,
  diveMode,
  clubReference,
  participants,
  staffMember,
}: {
  updateState: DiveSessionEditorUpdateState;
  existingGroup: Partial<DiveSessionGroup>;
  diveSession: DiveSession;
  diveMode: DiveSessionGroupDiveMode;
  clubReference: string;
  participants: DiveSessionResumeParticipant[];
  staffMember?: ClubResumeStaffMember;
}): DiveSessionEditorUpdateState {
  const localUpdateState = {
    ...updateState,
    hasChanges: true,
  };
  // NOTE: isVirtualGroup n'existe pas dans ce contexte (il s'agit de faux groupes, par exemple les non groupés, justement)

  let group: Partial<DiveSessionGroup> = existingGroup;

  if (!group) {
    // nouveau groupe
    group = commonEntityBuilder.buildEntity<DiveSessionGroup>({
      diveSessionReference: diveSession.reference,
      diveCenterId: diveSession.diveCenterId,
      diveMode,
      clubReference,
    });
    if (staffMember) {
      group.diveTourGroupSession1 = {
        instructor: {
          staffId: staffMember?._id,
          degree: staffMember?.scubaDivingInstructor?.degree,
        },
      };
      if (diveSession.diveTourSession2?.sameStaffSession1 === false) {
        group.diveTourGroupSession2 = {
          instructor: {
            staffId: staffMember?._id,
            degree: staffMember?.scubaDivingInstructor?.degree,
          },
        };
      }
    }
    localUpdateState.diveSessionGroupsChanges =
      changeDescriptorManager.createOne(group as DiveSessionGroup, {
        changeDescriptors: localUpdateState.diveSessionGroupsChanges,
      });
  } else {
    // groupe existant, on assigne le staff
    const updatedDiveSessionGroup: DiveSessionGroup =
      jsonParser.parseJSONWithDates(JSON.stringify(group));
    if (staffMember) {
      if (!group.diveTourGroupSession1?.instructor) {
        // instructeur principal
        updatedDiveSessionGroup.diveTourGroupSession1 = {
          instructor: {
            staffId: staffMember?._id,
            degree: staffMember?.scubaDivingInstructor?.degree,
          },
        };
        if (diveSession.diveTourSession2?.sameStaffSession1 === false) {
          updatedDiveSessionGroup.diveTourGroupSession2 = {
            instructor: {
              staffId: staffMember?._id,
              degree: staffMember?.scubaDivingInstructor?.degree,
            },
          };
        }
      } else {
        // instructeur supplémentaire (plongeur)
        if (!updatedDiveSessionGroup.diveTourGroupSession1.divingInstructors) {
          updatedDiveSessionGroup.diveTourGroupSession1.divingInstructors = [];
        }
        if (
          updatedDiveSessionGroup.diveTourGroupSession1.divingInstructors.find(
            (i) => i.staffId === staffMember?._id,
          ) === undefined
        ) {
          // ajout de l'instructeur comme plongeur supplémentaire
          updatedDiveSessionGroup.diveTourGroupSession1.divingInstructors.push({
            staffId: staffMember?._id,
            degree: staffMember?.scubaDivingInstructor?.degree,
          });
        }
        if (diveSession.diveTourSession2?.sameStaffSession1 === false) {
          if (
            updatedDiveSessionGroup.diveTourGroupSession2.divingInstructors.find(
              (i) => i.staffId === staffMember?._id,
            ) === undefined
          ) {
            // ajout de l'instructeur comme plongeur supplémentaire également sur le tour 2
            updatedDiveSessionGroup.diveTourGroupSession2.divingInstructors.push(
              {
                staffId: staffMember?._id,
                degree: staffMember?.scubaDivingInstructor?.degree,
              },
            );
          }
        }
      }
      const patchOperations = jsonPatcher.compareObjects(
        group,
        updatedDiveSessionGroup,
        {
          replaceDeleteByNullValue: true,
          attributesToReplaceFully: [
            'diveTourGroupSession1',
            'diveTourGroupSession2',
          ],
        },
      );
      if (patchOperations.length) {
        localUpdateState.diveSessionGroupsChanges =
          changeDescriptorManager.updateOne(
            {
              pk: group?._id,
              patchOperations,
            },
            {
              changeDescriptors: localUpdateState.diveSessionGroupsChanges,
            },
          );
      }
    }
  }
  // on ajoute maintenant les participants
  participants.forEach((p) => {
    const patchOperations = jsonPatcher.compareObjects(
      p,
      {
        ...p,
        diveSessionGroupId: group?._id,
      },
      { replaceDeleteByNullValue: true },
    );
    if (patchOperations.length) {
      localUpdateState.clubParticipantsChanges =
        changeDescriptorManager.updateOne(
          {
            pk: p._id,
            patchOperations,
          },
          {
            changeDescriptors: localUpdateState.clubParticipantsChanges,
          },
        );
    }
  });
  return localUpdateState;
}

function removeParticipants({
  updateState,
  participants: participantsToRemove,
  diveSessionParticipantsByGroup,
  group,
}: {
  updateState: DiveSessionEditorUpdateState;
  participants: DiveSessionResumeParticipant[];
  diveSessionParticipantsByGroup: DiveSessionResumeParticipantsByGroup;
  group: DiveSessionResumeGroup;
}): DiveSessionEditorUpdateState {
  const localUpdateState = {
    ...updateState,
    hasChanges: true,
  };
  participantsToRemove.forEach((participantToRemove) => {
    const patchOperations = jsonPatcher.compareObjects(
      participantToRemove,
      {
        ...participantToRemove,
        diveSessionGroupId: null,
        diveSessionGroupDiveGuide: null,
      },
      { replaceDeleteByNullValue: true },
    );
    if (patchOperations.length) {
      localUpdateState.clubParticipantsChanges =
        changeDescriptorManager.updateOne(
          {
            pk: participantToRemove._id,
            patchOperations,
          },
          {
            changeDescriptors: localUpdateState.clubParticipantsChanges,
          },
        );
    }
  });
  const participantsToRemoveIds = participantsToRemove.map((p) => p._id);
  const remainingParticipants = diveSessionParticipantsByGroup.groupped.filter(
    (x) =>
      x.diveSessionGroupId === group._id &&
      !participantsToRemoveIds.includes(x._id),
  );

  const hasAnyRemainingParticipant = remainingParticipants.length !== 0;
  if (!hasAnyRemainingParticipant) {
    localUpdateState.diveSessionGroupsChanges =
      changeDescriptorManager.deleteOne(group._id, {
        changeDescriptors: localUpdateState.diveSessionGroupsChanges,
      });
  } else {
    // update diveMode
    const diveMode =
      diveSessionGroupDiveModeBuilder.buildFromParticipantsDiveModes({
        diveModes: remainingParticipants.map((p) => p.diveMode),
        hasDiveGuide: !!group.diveTourGroupSession1?.diveGuide,
        hasInstructor: !!group.diveTourGroupSession1?.instructor,
        debugContext: '3 removeParticipants',
      });
    // remove diveGuide if removed from group
    const finalGroup = {
      ...group,
      diveTourGroupSession1: group.diveTourGroupSession1
        ? {
            ...group.diveTourGroupSession1,
          }
        : undefined,
      diveTourGroupSession2: group.diveTourGroupSession2
        ? {
            ...group.diveTourGroupSession2,
          }
        : undefined,
      diveMode,
    };
    if (
      participantsToRemoveIds.includes(
        group.diveTourGroupSession1?.diveGuide?.participantId,
      )
    ) {
      finalGroup.diveTourGroupSession1 = {
        ...group.diveTourGroupSession1,
        diveGuide: null,
      };
    }
    if (
      participantsToRemoveIds.includes(
        group.diveTourGroupSession2?.diveGuide?.participantId,
      )
    ) {
      finalGroup.diveTourGroupSession2 = {
        ...group.diveTourGroupSession2,
        diveGuide: null,
      };
    }

    const patchOperations = jsonPatcher.compareObjects(group, finalGroup, {
      replaceDeleteByNullValue: true,
    });

    if (patchOperations.length) {
      localUpdateState.diveSessionGroupsChanges =
        changeDescriptorManager.updateOne(
          {
            pk: group._id,
            patchOperations,
          },
          {
            changeDescriptors: localUpdateState.diveSessionGroupsChanges,
          },
        );
    }
  }
  return localUpdateState;
}
