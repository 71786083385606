/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageAttributesDive,
  ClubProductPackageAttributesProduct,
  ClubProductPackageAttributesTraining,
  ClubProductPackageOffer,
  ClubProductPackageOfferView,
  DIVE_TRAININGS,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import { ClubOfferTrainingEditorContext } from './ClubOfferTrainingEditorContext.type';
import { ClubOfferTrainingEditorFormModel } from './ClubOfferTrainingEditorFormModel.type';
import { ClubOfferTrainingEditorResult } from './ClubOfferTrainingEditorResult.type';

export const clubOfferTrainingEditorResultBuilder = { buildResult };

function buildResult({
  formValue,
  context,
  clubReference,
}: {
  formValue: ClubOfferTrainingEditorFormModel;
  context: ClubOfferTrainingEditorContext;
  clubReference: string;
}): {
  hasChanges: boolean;
  result?: ClubOfferTrainingEditorResult;
} {
  const training = DIVE_TRAININGS.find(
    (x) => x?.reference === formValue.diveTrainingReference,
  );
  if (!training) {
    return;
  }

  const defaultOrganizationReference = formValue.defaultOrganizationReference;

  const productAttributes: ClubProductPackageAttributesProduct = {
    includeDives: true,
    defaultOrganizationReference,
    organizationReferences: defaultOrganizationReference
      ? [defaultOrganizationReference]
      : undefined,
    certificationReference: training.targetCertification?.reference,
    minAge: formValue.minAge,
    maxAge: formValue.maxAge,
    validityPeriodInDays: formValue.validityPeriodInDays,
  };
  const diveAttributes: ClubProductPackageAttributesDive = {
    diveMode: 'training',
    diveType: formValue.diveType,
    extraDiveModes: formValue.extraDiveModes,
    equipment: 'not-equipped',
    supervision: 'supervised',
    divesCount: formValue.divesCount,
    divesCountInfinite: formValue.divesCountInfinite,
    minDepth: formValue.minDepth,
    maxDepth: formValue.maxDepth,
  };

  const trainingAttributes: ClubProductPackageAttributesTraining = {
    trainingType: formValue.trainingType,
    diveTrainingReference: formValue.diveTrainingReference,
    theoricalClassesCounts: formValue.theoricalClassesCounts,
  };
  const newReference = `${clubReference};training;${
    formValue.diveTrainingReference
  };${defaultOrganizationReference};${
    formValue.trainingType
  };${new Date().getTime()}`;
  
  if (context.mode === 'create' || context.mode === 'duplicate') {
    const reference = newReference;

    const newOffer =
      repositoryEntityBuilder.buildEntity<ClubProductPackageOffer>({
        reference,
        clubReference: clubReference,
        price: formValue.price,
        thirdPartyCollectPrice: formValue.thirdPartyCollectPrice,
        productPackage: {
          reference,
          type: 'training',
          productAttributes,
          diveAttributes,
          salesCriteria: formValue.salesCriteria,
          trainingAttributes,
          namingConfiguration: formValue.namingConfiguration,
          comment: formValue.comment,
          label: formValue.label,
        },
      });
    const result: ClubOfferTrainingEditorResult = {
      newOffer,
    };
    return {
      hasChanges: true,
      result,
    };
  } else if (context.mode === 'edit') {
    const initialOffer = context.offer;

    const isReferenceObsolete =
      formValue.diveTrainingReference !==
        initialOffer.productPackage.trainingAttributes.diveTrainingReference ||
      defaultOrganizationReference !==
        initialOffer.productPackage.productAttributes
          .defaultOrganizationReference ||
      formValue.trainingType !==
        initialOffer.productPackage.trainingAttributes.trainingType;

    // rebuild reference if main attributes changes
    const reference = isReferenceObsolete
      ? newReference
      : initialOffer.reference;

    const updatedOffer: ClubProductPackageOfferView = {
      ...context.offer,
      reference,
      price: formValue.price,
      thirdPartyCollectPrice: formValue.thirdPartyCollectPrice,
      productPackage: {
        reference,
        type: 'training',
        productAttributes,
        diveAttributes,
        salesCriteria: formValue.salesCriteria,
        trainingAttributes,
        namingConfiguration: formValue.namingConfiguration,
        comment: formValue.comment,
        label: formValue.label,
      },
    };

    const productPatchOperations = jsonPatcher.compareObjects(
      initialOffer,
      updatedOffer,
      {
        attributesToReplaceFully: ['productPackage'],
      },
    );
    if (productPatchOperations.length) {
      const updatedOffer = {
        pk: initialOffer._id,
        patchOperations: productPatchOperations,
      };
      const result: ClubOfferTrainingEditorResult = {
        updatedOffer,
      };
      return {
        hasChanges: true,
        result,
      };
    }
  }
  return {
    hasChanges: false,
  };
}
