"use strict";
exports.__esModule = true;
exports.CLUB_DEFAULT_UI_DIVE_MODE = void 0;
var _core_1 = require("../_core");
exports.CLUB_DEFAULT_UI_DIVE_MODE = {
    observer: {
        ref: 'ACC',
        labelShort: 'Accompagnateur',
        labelLong: 'Accompagnateur',
        color: _core_1.APP_COLORS.diveModeObserver
    },
    snorkeling: {
        ref: 'PMT',
        labelShort: 'PMT / Snorkeling',
        labelLong: 'PMT / Snorkeling',
        color: _core_1.APP_COLORS.diveModeSnorkeling
    },
    snorkelingSupervised: {
        ref: 'RP',
        labelShort: 'Randonnée palmée',
        labelLong: 'Randonnée palmée',
        color: _core_1.APP_COLORS.diveModeSnorkelingSupervised
    },
    watchingTour: {
        ref: 'EXC',
        labelShort: 'Excursion',
        labelLong: "Excursion d'observation",
        color: _core_1.APP_COLORS.diveModeWatchingTour
    },
    'first-dive': {
        ref: 'BPT',
        labelShort: 'Baptême',
        labelLong: 'Baptême de plongée',
        color: _core_1.APP_COLORS.diveModeFirstDive
    },
    training: {
        ref: 'FN',
        labelShort: 'Formation',
        labelLong: 'Formation de plongée',
        color: _core_1.APP_COLORS.diveModeTraining
    },
    'theoretical-training': {
        ref: 'FT',
        labelShort: 'Théorie',
        labelLong: 'Formation théorique',
        color: _core_1.APP_COLORS.diveModeTheoreticalTraining
    },
    supervised: {
        ref: 'PE',
        labelShort: 'Encadré',
        labelLong: 'Plongeur Encadré',
        color: _core_1.APP_COLORS.diveModeSupervised
    },
    autonomous: {
        ref: 'PA',
        labelShort: 'Autonome',
        labelLong: 'Plongeur Autonome',
        color: _core_1.APP_COLORS.diveModeAutonomous
    },
    autoSupervised: {
        ref: 'AE',
        labelShort: 'Auto-encadré',
        labelLong: 'Plongeur Auto-encadré',
        color: _core_1.APP_COLORS.diveModeAutoSupervised
    },
    'free-dive': {
        ref: 'APN',
        labelShort: 'Apnée',
        labelLong: 'Apnée',
        color: _core_1.APP_COLORS.diveModeFreeDive
    },
    instructor: {
        ref: 'GP',
        labelShort: 'Guide de palanquée',
        labelLong: 'Guide de palanquée',
        color: _core_1.APP_COLORS.diveModeInstructor
    },
    unknown: {
        ref: '???',
        labelShort: 'Inconnu',
        labelLong: 'Inconnu',
        color: _core_1.APP_COLORS.diveModeUnknown
    }
};
