/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingDeposit,
  ClubPurchasePackage,
  ClubPurchasePayment,
  ClubPurchasePaymentBookingDepositDetails,
  ClubPurchasePaymentOnlinePlatformDetails,
  ClubPurchasePaymentPackage,
  ClubPurchasePaymentPackageDetails,
  DiveCenterResume,
  JsonPatchOperation,
  PurchasePaymentState,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  dateService,
  jsonPatcher,
  uuidGenerator,
} from '@mabadive/app-common-services';
import {
  AggregatedBookingDepositWithPayments,
  AggregatedBookingPurchasePackageWithPayments,
  DiverPurchasePaymentEditorDialogResult,
  DiverPurchasePaymentEditorDialogState,
} from '../../models';
import { DiverPurchasePaymentEditorPaymentChangesModel } from './DiverPurchasePaymentEditorPaymentChangesModel.type';
import { DiverPurchasePaymentEditorFormModel } from './components';

export const diverDiverPurchasePaymentEditorDialogUpdateBuilder = {
  buildUpdateResult,
};

function buildUpdateResult({
  diveCenterResume,
  paymentChangesModel,
  initialPurchasePayment,
  formValue,
  isDirty,
  state,
}: {
  diveCenterResume: DiveCenterResume;
  paymentChangesModel: DiverPurchasePaymentEditorPaymentChangesModel;
  initialPurchasePayment: ClubPurchasePayment;
  formValue: DiverPurchasePaymentEditorFormModel;
  isDirty: boolean;
  state: DiverPurchasePaymentEditorDialogState;
}): {
  hasChanges: boolean;
  result?: DiverPurchasePaymentEditorDialogResult;
} {
  const clubReference = diveCenterResume.clubReference;
  const diveCenterId = diveCenterResume._id;
  const clubResume = diveCenterResume.clubResume;

  const generalSettings = clubResume.clubSettings.general;

  if (state.mode === 'create') {
    const updatedClubPurchasePackages: AppEntityUpdatePatch[] = [];

    const newPurchasePaymentPackages: ClubPurchasePaymentPackage[] =
      buildCreateUpdatedPurhasePaymentPackages({
        paymentChangesModel,
        formValue,
        updatedClubPurchasePackages,
      });

    const paymentDate: Date =
      formValue.paymentState === 'pending'
        ? null
        : formValue.paymentDate ??
          dateService.getUTCDateWithoutTimeFromLocalTime(new Date());
    const dueDate: Date = formValue.dueDate;

    const paymentPlatform = clubResume.paymentPlatforms.find(
      (x) => x._id === formValue.onlinePlatformId,
    );

    const onlinePlatformDetails: ClubPurchasePaymentOnlinePlatformDetails =
      paymentPlatform
        ? {
            platformId: paymentPlatform._id,
            platformEnv: paymentPlatform.platformEnv,
            platformRef: paymentPlatform.platformRef,
            checkoutSessions: { items: [] },
            paymentIntents: {
              items: [],
              totalAmountCapturable: 0,
              totalAmountReceived: 0,
            },
          }
        : undefined;
    const purchasePaymentId = uuidGenerator.random();
    const { updatedBookingDeposits, newPurchasePaymentBookingDepositsDetails } =
      buildCreatePaymentBookingDepositDetails({
        clubReference,
        diveCenterId,
        formValue,
        paymentChangesModel,
        purchasePaymentId,
      });
    const amount = newPurchasePaymentPackages.reduce(
      (acc, ppp) => ppp.amount + acc,
      newPurchasePaymentBookingDepositsDetails.reduce(
        (acc, x) => x.amount + acc,
        0,
      ),
    );
    const newPurchasePaymentPackagesDetails: ClubPurchasePaymentPackageDetails[] =
      newPurchasePaymentPackages.map((ppp) =>
        commonEntityBuilder.buildEntity<ClubPurchasePaymentPackageDetails>({
          clubReference,
          diveCenterId,
          purchasePaymentId,
          purchasePackageId: ppp.purchasePackage._id,
          amount,
          purchasePackageCopy: ppp.purchasePackage,
          purchasePackageOfferCopy: ppp.purchasePackageOffer,
          purchaseProductPackageCopy: ppp.purchaseProductPackage,
        }),
      );

    const newPurchasePayment: ClubPurchasePayment =
      commonEntityBuilder.buildEntity<ClubPurchasePayment>({
        _id: purchasePaymentId,
        clubReference,
        diveCenterId,
        diverId: formValue.diverId,
        bookingId: formValue.bookingId,
        payedToBookingAgencyId: formValue.payedToBookingAgencyId,
        paymentMethod: formValue.paymentMethod,
        onlinePlatformId: formValue.onlinePlatformId,
        onlinePlatformDetails,
        amount,
        paymentDate,
        dueDate,
        paymentState: formValue.paymentState ?? 'validated',
        paymentReference: formValue.paymentReference,
        comment: formValue.comment?.trim(),
        customerComment: formValue.customerComment?.trim(),
        purchasePaymentPackages: newPurchasePaymentPackages,
        currencyIsoCode: generalSettings.currencyIsoCode,
        usedCurrencyAmount:
          generalSettings.currencyIsoCode === formValue.usedCurrencyIsoCode
            ? amount
            : formValue.usedCurrencyAmount,
        usedCurrencyIsoCode: formValue.usedCurrencyIsoCode,
        usedCurrencyRate:
          generalSettings.currencyIsoCode === formValue.usedCurrencyIsoCode
            ? 1
            : formValue.usedCurrencyRate,
        usedCurrencyRateReverse: formValue.usedCurrencyRateReverse,
      });
    newPurchasePayment.creationDate = newPurchasePayment._createdAt;

    // const newPurchasePaymentBookingDepositsDetails: ClubPurchasePaymentBookingDepositDetails[];
    // newPurchasePaymentPackages.map((ppp) =>
    //   commonEntityBuilder.buildEntity<ClubPurchasePaymentPackageDetails>({
    //     clubReference,
    //     diveCenterId,
    //     purchasePaymentId: newPurchasePayment._id,
    //     purchasePackageId: ppp.purchasePackage._id,
    //     amount,
    //     purchasePackageCopy: ppp.purchasePackage,
    //     purchasePackageOfferCopy: ppp.purchasePackageOffer,
    //     purchaseProductPackageCopy: ppp.purchaseProductPackage,
    //   }),
    // );

    const result: DiverPurchasePaymentEditorDialogResult = {
      newPurchasePayment,
      newPurchasePaymentPackagesDetails,
      newPurchasePaymentBookingDepositsDetails,
      updatedClubPurchasePackages,
      updatedBookingDeposits,
    };

    return {
      hasChanges: true,
      result,
    };
  } else if (state.mode === 'edit') {
    if (!isDirty) {
      return {
        hasChanges: false,
      };
    }

    const patchOperations = buildUpdatePatchesPayment({
      formValue,
      initialPurchasePayment,
    });

    if (patchOperations.length) {
      const relatedPackagesWithPayments =
        state.aggregatedData.purchasePackagesWithPayments.filter((x) =>
          x.paymentsDetails.find(
            (pd) => pd.purchasePaymentId === initialPurchasePayment._id,
          ),
        );

      const updatedClubPurchasePackages: AppEntityUpdatePatch[] =
        relatedPackagesWithPayments
          .map((initialPurchasePackageWithPayments) => {
            const patchOperations: JsonPatchOperation[] =
              buildUpdatePatchesPackage({
                paymentId: initialPurchasePayment._id,
                paymentState: formValue.paymentState,
                initialPurchasePackageWithPayments,
              });
            if (patchOperations.length) {
              const patch: AppEntityUpdatePatch = {
                pk: initialPurchasePackageWithPayments.purchasePackage._id,
                patchOperations,
              };
              return patch;
            }
          })
          .filter((x) => !!x);

      // const relatedBookingDepositsIds = state.paymentsBookingDepositsDetails
      //   .filter((bd) => bd.purchasePaymentId === initialPurchasePayment._id)
      //   .map((x) => x.bookingDepositId);

      // const relatedBookingDeposits = state.bookingDeposits.filter((bd) =>
      //   relatedBookingDepositsIds.includes(bd._id),
      // );

      const relatedBookingDepositsWithPayments =
        state.bookingDepositsWithPayments.filter((x) =>
          x.paymentsDetails.find(
            (pd) => pd.purchasePaymentId === initialPurchasePayment._id,
          ),
        );

      const updatedBookingDeposits: AppEntityUpdatePatch[] =
        relatedBookingDepositsWithPayments
          .map((initialBookingDepositWithPayments) => {
            const patchOperations: JsonPatchOperation[] =
              buildUpdatePatchesBookingDeposit({
                paymentId: initialPurchasePayment._id,
                paymentState: formValue.paymentState,
                initialBookingDepositWithPayments,
              });
            if (patchOperations.length) {
              const patch: AppEntityUpdatePatch = {
                pk: initialBookingDepositWithPayments.bookingDeposit._id,
                patchOperations,
              };
              return patch;
            }
          })
          .filter((x) => !!x);

      return {
        hasChanges: true,
        result: {
          updatedClubPurchasePayment: {
            pk: initialPurchasePayment._id,
            patchOperations,
          },
          updatedClubPurchasePackages,
          updatedBookingDeposits,
        },
      };
    }
  }

  return {
    hasChanges: false,
  };
}

function buildCreateUpdatedPurhasePaymentPackages({
  paymentChangesModel,
  formValue,
  updatedClubPurchasePackages,
}: {
  paymentChangesModel: DiverPurchasePaymentEditorPaymentChangesModel;
  formValue: DiverPurchasePaymentEditorFormModel;
  updatedClubPurchasePackages: AppEntityUpdatePatch[];
}): ClubPurchasePaymentPackage[] {
  return paymentChangesModel.packages
    .filter((paymentPackageChangesModel) => {
      // const packageFormValue = formValue.packages.find(
      //   (x) =>
      //     x.purchasePackageId ===
      //     purchasePaymentPackage.updatedPurchasePackage._id,
      // );
      return (
        paymentPackageChangesModel.selected &&
        paymentPackageChangesModel.updatedAmountForPackage > 0
      );
    })
    .map((paymentPackageChangesModel) => {
      const {
        updatedPurchasePackage,
        initialPurchasePackage,
        diver,
        updatedAmountForPackage,
        initialAmountForPackage,
      } = paymentPackageChangesModel;

      if (formValue.paymentState === 'pending') {
        updatedPurchasePackage.purchasePaymentPending = true;
      }

      const patchOperations = jsonPatcher.compareObjects(
        initialPurchasePackage,
        updatedPurchasePackage,
        {
          // else, value won't be deleted by typeorm
          // https://github.com/typeorm/typeorm/issues/2934
          replaceDeleteByNullValue: true,
          attributesToReplaceFully: [],
        },
      );
      if (patchOperations.length) {
        updatedClubPurchasePackages.push({
          pk: updatedPurchasePackage._id,
          patchOperations,
        });
      }

      const offer = updatedPurchasePackage.productPackageOffer;
      const updatedPurchasePaymentPackage: ClubPurchasePaymentPackage = {
        purchasePackage: {
          _id: updatedPurchasePackage._id,
          price: updatedPurchasePackage.price,
          purchaseDate: updatedPurchasePackage.purchaseDate,
          diverId: updatedPurchasePackage.diverId,
        },
        purchasePackageOffer: offer
          ? {
              _id: offer._id,
              price: offer.price,
              reference: offer.reference,
            }
          : null,
        purchaseProductPackage: offer?.productPackage,
        amount: updatedAmountForPackage,
      };
      return updatedPurchasePaymentPackage;
    });
}

function buildCreatePaymentBookingDepositDetails({
  paymentChangesModel,
  formValue,
  clubReference,
  diveCenterId,
  purchasePaymentId,
}: {
  paymentChangesModel: DiverPurchasePaymentEditorPaymentChangesModel;
  formValue: DiverPurchasePaymentEditorFormModel;
  clubReference: string;
  diveCenterId: string;
  purchasePaymentId: string;
}): {
  newPurchasePaymentBookingDepositsDetails: ClubPurchasePaymentBookingDepositDetails[];
  updatedBookingDeposits: AppEntityUpdatePatch[];
} {
  const updatedBookingDeposits: AppEntityUpdatePatch[] = [];

  if (
    formValue.bookingDepositId &&
    paymentChangesModel.paymentWithBookingDeposit
  ) {
    // paiement par acompte
    const newBookingDeposit: BookingDeposit = JSON.parse(
      JSON.stringify(paymentChangesModel.paymentWithBookingDeposit),
    );
    newBookingDeposit.creditRemaining -= paymentChangesModel.totalToPay;
    if (newBookingDeposit.creditRemaining <= 0) {
      newBookingDeposit.depositState = 'used';
    }
    const patchOperations = jsonPatcher.compareObjects(
      paymentChangesModel.paymentWithBookingDeposit,
      newBookingDeposit,
      {
        // else, value won't be deleted by typeorm
        // https://github.com/typeorm/typeorm/issues/2934
        replaceDeleteByNullValue: true,
        attributesToReplaceFully: [],
      },
    );
    if (patchOperations.length) {
      updatedBookingDeposits.push({
        pk: newBookingDeposit._id,
        patchOperations,
      });
    }
  }

  const newPurchasePaymentBookingDepositsDetails =
    paymentChangesModel.bookingDeposits
      .filter((bookingDepositChangesModel) => {
        // const packageFormValue = formValue.packages.find(
        //   (x) =>
        //     x.purchasePackageId ===
        //     purchasePaymentPackage.updatedPurchasePackage._id,
        // );
        return (
          bookingDepositChangesModel.selected &&
          bookingDepositChangesModel.updatedAmountForBookingDeposit > 0
        );
      })
      .map((paymentPackageChangesModel) => {
        const {
          updatedBookingDeposit,
          initialBookingDeposit,
          diver,
          updatedAmountForBookingDeposit,
          initialAmountForBookingDeposit,
        } = paymentPackageChangesModel;

        if (formValue.paymentState === 'pending') {
          updatedBookingDeposit.purchasePaymentPending = true;
          if (updatedBookingDeposit.depositState === 'active') {
            updatedBookingDeposit.depositState = 'draft';
          }
        } else if (
          formValue.paymentState === 'validated' &&
          updatedBookingDeposit.depositState === 'draft' &&
          updatedBookingDeposit.purchasePaymentStatus === 'done'
        ) {
          updatedBookingDeposit.depositState = 'active';
        }

        const patchOperations = jsonPatcher.compareObjects(
          initialBookingDeposit,
          updatedBookingDeposit,
          {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: true,
            attributesToReplaceFully: [],
          },
        );
        if (patchOperations.length) {
          updatedBookingDeposits.push({
            pk: updatedBookingDeposit._id,
            patchOperations,
          });
        }

        const updatedPurchasePaymentBookingDepositDetails: ClubPurchasePaymentBookingDepositDetails =
          {
            clubReference,
            diveCenterId,
            purchasePaymentId,
            amount: updatedAmountForBookingDeposit,
            bookingDepositId: updatedBookingDeposit._id,
          };
        return commonEntityBuilder.buildEntity(
          updatedPurchasePaymentBookingDepositDetails,
        );
      });

  return {
    updatedBookingDeposits,
    newPurchasePaymentBookingDepositsDetails,
  };
}

function buildUpdatePatchesPayment({
  initialPurchasePayment,
  formValue,
}: {
  initialPurchasePayment: ClubPurchasePayment;
  formValue: DiverPurchasePaymentEditorFormModel;
}): JsonPatchOperation[] {
  const initialValue: Partial<ClubPurchasePayment> = {
    ...initialPurchasePayment,
    purchasePaymentPackages: [], // va être remplacé par json patch seulement si il y a une mise à jour
  };

  const finalValue: Partial<ClubPurchasePayment> = {
    ...initialValue,
    diverId: formValue.diverId,
    bookingId: formValue.bookingId,
    payedToBookingAgencyId: formValue.payedToBookingAgencyId,
    paymentMethod: formValue.paymentMethod,
    // onlinePlatformId: formValue.onlinePlatformId, // pas de modif de la plateforme de paiement
    // amount: totalAmount, // pas de modif des montants pour le moment
    dueDate: formValue.dueDate,
    paymentDate: formValue.paymentDate,
    paymentState: formValue.paymentState,
    paymentReference: formValue.paymentReference,
    comment: formValue.comment,
    customerComment: formValue.customerComment,
  };

  const patchOperations = jsonPatcher.compareObjects(initialValue, finalValue, {
    // else, value won't be deleted by typeorm
    // https://github.com/typeorm/typeorm/issues/2934
    replaceDeleteByNullValue: true,
    attributesToReplaceFully: ['purchasePaymentPackages'],
  });

  return patchOperations;
}
function buildUpdatePatchesPackage({
  paymentId,
  paymentState,
  initialPurchasePackageWithPayments,
}: {
  initialPurchasePackageWithPayments: AggregatedBookingPurchasePackageWithPayments;
  paymentId: string;
  paymentState: PurchasePaymentState;
}): JsonPatchOperation[] {
  const initialValue: Partial<ClubPurchasePackage> = {
    ...initialPurchasePackageWithPayments.purchasePackage,
  };

  const purchasePaymentPending =
    initialPurchasePackageWithPayments.paymentsDetails.find((x) => {
      if (x.purchasePaymentId === paymentId) {
        return paymentState === 'pending'; // currently updated payment
      }
      return x.purchasePayment.paymentState === 'pending';
    }) !== undefined;

  const finalValue: Partial<ClubPurchasePackage> = {
    ...initialValue,
    purchasePaymentPending: purchasePaymentPending ? true : undefined,
  };

  if (
    initialValue.purchasePaymentPending !== finalValue.purchasePaymentPending
  ) {
    const patchOperations = jsonPatcher.compareObjects(
      initialValue,
      finalValue,
      {
        // else, value won't be deleted by typeorm
        // https://github.com/typeorm/typeorm/issues/2934
        replaceDeleteByNullValue: true,
      },
    );

    return patchOperations;
  }

  return [];
}
function buildUpdatePatchesBookingDeposit({
  paymentId,
  paymentState,
  initialBookingDepositWithPayments,
}: {
  paymentId: string;
  paymentState: PurchasePaymentState;
  initialBookingDepositWithPayments: AggregatedBookingDepositWithPayments;
}): JsonPatchOperation[] {
  const initialValue: Partial<BookingDeposit> = {
    ...initialBookingDepositWithPayments.bookingDeposit,
  };

  const purchasePaymentPending =
    initialBookingDepositWithPayments.paymentsDetails.find((x) => {
      if (x.purchasePaymentId === paymentId) {
        return paymentState === 'pending'; // currently updated payment
      }
      return x.purchasePayment.paymentState === 'pending';
    }) !== undefined;

  const finalValue: Partial<BookingDeposit> = {
    ...initialValue,
    purchasePaymentPending: purchasePaymentPending ? true : undefined,
  };

  if (!purchasePaymentPending && finalValue.purchasePaymentStatus === 'done') {
    if (
      finalValue.depositState === 'draft' &&
      finalValue.purchasePaymentStatus === 'done'
    ) {
      finalValue.depositState = 'active';
    }
  } else if (finalValue.depositState === 'active') {
    finalValue.depositState = 'draft';
  }

  if (
    initialValue.purchasePaymentPending !== finalValue.purchasePaymentPending
  ) {
    const patchOperations = jsonPatcher.compareObjects(
      initialValue,
      finalValue,
      {
        // else, value won't be deleted by typeorm
        // https://github.com/typeorm/typeorm/issues/2934
        replaceDeleteByNullValue: true,
      },
    );

    return patchOperations;
  }

  return [];
}
