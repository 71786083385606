import {
  DiveCenterResume,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  ClubSettingsPlanningViewDayDiveTourModel,
  ClubSettingsPlanningViewDayDiveTourSessionModel,
} from '../../../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek';
import {
  ClubSettingsPlanningViewWeekDayDiveTourFormModel,
  ClubSettingsPlanningViewWeekDayDiveTourSessionFormModel,
  ClubSettingsPlanningViewWeekFormModel,
} from '../../model';

export function usePlanningSettingsDiveTourModel({
  form,
  timeDayPeriod,
  formTour,
  diveTourControlBaseName,
  time,
  diveCenterResume,
}: {
  form: UseFormReturn<ClubSettingsPlanningViewWeekFormModel, any>;
  timeDayPeriod: DiveSessionTimeDayPeriod;
  formTour: ClubSettingsPlanningViewWeekDayDiveTourFormModel;
  diveTourControlBaseName: `days.${number}.diveTours.${number}`;
  time: Date;
  diveCenterResume: DiveCenterResume;
}): ClubSettingsPlanningViewDayDiveTourModel {
  const { control } = form;
  const [
    multiSessionsEnabled,
    session1Name,
    session1Time,
    session1Boats,
    session1Special,
    session1DiveSiteId,
    session1EmojiId,
  ] = useWatch({
    control,
    name: [
      `${diveTourControlBaseName}.multiSessionsEnabled`,
      `${diveTourControlBaseName}.session1.name`,
      `${diveTourControlBaseName}.session1.time`,
      `${diveTourControlBaseName}.session1.boatsIds`,
      `${diveTourControlBaseName}.session1.special`,
      `${diveTourControlBaseName}.session1.diveSiteId`,
      `${diveTourControlBaseName}.session1.emojiId`,
    ],
  });

  return useMemo(() => {
    const diveTour: ClubSettingsPlanningViewDayDiveTourModel = {
      ...formTour,
      session1: buildSessionModel({
        session: {
          time,
          name: session1Name,
          boatsIds: session1Boats,
          special: session1Special,
          diveSiteId: session1DiveSiteId,
          emojiId: session1EmojiId,
        },
        diveCenterResume,
        timeDayPeriod,
      }),
      session2: buildSessionModel({
        session: formTour.session2,
        diveCenterResume,
        timeDayPeriod,
      }),
    };

    return diveTour;
  }, [
    diveCenterResume,
    formTour,
    session1Boats,
    session1DiveSiteId,
    session1EmojiId,
    session1Name,
    session1Special,
    time,
    timeDayPeriod,
  ]);
}
function buildSessionModel({
  session,
  diveCenterResume,
  timeDayPeriod,
}: {
  session: ClubSettingsPlanningViewWeekDayDiveTourSessionFormModel;
  diveCenterResume: DiveCenterResume;
  timeDayPeriod: DiveSessionTimeDayPeriod;
}): ClubSettingsPlanningViewDayDiveTourSessionModel {
  if (!session) {
    return;
  }

  const diveSite = diveCenterResume?.diveSites.find(
    (x) => x._id === session.diveSiteId,
  );
  const boats = diveCenterResume?.boats.filter((x) =>
    (session.boatsIds ?? []).includes(x._id),
  );
  const model: ClubSettingsPlanningViewDayDiveTourSessionModel = {
    name: session.name,
    special: session.special,
    emojiId: session.emojiId,
    time: session.time,
    timeDayPeriod,
    diveSite,
    boats,
  };
  return model;
}
