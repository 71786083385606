import { BookingParticipantEditorFormModel } from '@mabadive/app-common-model';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AppButton,
  AppFixedButtonsBar,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubDiverCardAttributes } from 'src/business/club/modules/club-divers/components/ClubDiverCard';
import { ClubDialogsState } from 'src/pages/_dialogs';
import {
  BookingMemberDiverEditFormRHF,
  BookingMemberGeneralEditFormRHF,
} from '../../forms';
import { BookingParticipantDefaultParamsEditFormRHF } from '../../forms/BookingParticipantDefaultParamsEditForm';
import {
  BookingParticipantEditorDiverMember,
  BookingParticipantEditorResult,
  PRO_BookingMemberFull_WithDocs,
  ParticipantFormExpansionPanelId,
} from '../../models';
import {
  BookingParticipantEditorDialogEditPanel1,
  BookingParticipantEditorDialogEditPanel2,
} from '../BookingParticipantDialog/BookingParticipantEditorDialog/panels';
import { BookingParticipantEditorDialogEditPanel4 } from '../BookingParticipantDialog/BookingParticipantEditorDialog/panels/BookingParticipantEditorDialogEditPanel4';
import { bookingParticipantEditorDialogChangesBuilderEditDiver } from '../BookingParticipantDialog/BookingParticipantEditorDialog/services/changes-builder';
import { BookingParticipantEditorDialogHeader } from '../BookingParticipantDialog/_common';
import { useDefaultDiveConfigCardAttributes } from '../BookingParticipantDialog/_common/DefaultDiveConfigCard';

export const BookingMassiveEditorDialogEditMemberPanel: FC<{
  dialogsState: ClubDialogsState;
  bookingMemberFull?: PRO_BookingMemberFull_WithDocs;
  onConfirm: (args: {
    hasChanges: boolean;
    result?: BookingParticipantEditorResult;
  }) => void;
  onCancel: () => void;
}> = ({ dialogsState, bookingMemberFull, onCancel, onConfirm }) => {
  const diver = bookingMemberFull?.diver;
  const bookingMember = bookingMemberFull?.bookingMember;

  const { generalAttributes, diverAttributes } = useClubDiverCardAttributes({
    diver,
    bookingMember,
  });
  const defaultDiveConfigCardDiveAttributes =
    useDefaultDiveConfigCardAttributes({
      defaultDiveConfig: diver?.defaultDiveConfig,
    });
  const [expandedPanel, setExpandedPanel] =
    useState<ParticipantFormExpansionPanelId>('panel4');

  const expandPanelToogle = useCallback(
    (panelId: ParticipantFormExpansionPanelId) => {
      setExpandedPanel(expandedPanel === panelId ? undefined : panelId);
    },
    [expandedPanel],
  );

  const initialFormValue: BookingParticipantEditorFormModel = useMemo(
    () => ({
      diver,
      bookingMember,
    }),
    [bookingMember, diver],
  );

  const form = useForm<BookingParticipantEditorFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  // const { primary: participantCardDiveAttributes } =
  //   useClubParticipantCardAttributes({
  //     participant: bookingMemberFull.clubParticipant,
  //     displayMode: 'edit-participant-form-dive-parameters',
  //   });

  // const { primary: participantCardMoreAttributes } =
  //   useClubParticipantCardAttributes({
  //     participant: bookingMemberFull.clubParticipant,
  //     displayMode: 'edit-participant-form-more-parameters',
  //   });

  const submitForm = useCallback(() => {
    handleSubmit(
      async (formValue: BookingParticipantEditorFormModel, event) => {
        const participantData: BookingParticipantEditorDiverMember = {
          bookingId: bookingMember.bookingId,
          diver,
          bookingMember,
          docResumes: bookingMemberFull.docResumes,
          // bookingSessionId: bookingSessionFull.bookingSession._id,
          // diveSession: bookingSessionFull.diveSession,
        };
        const { hasChanges, result } =
          bookingParticipantEditorDialogChangesBuilderEditDiver.buildChangesResult(
            {
              participantData,
              formValue,
              hasFormChanges: formState.isDirty,
              initialFormValue,
            },
          );
        onConfirm({ hasChanges, result });
      },
    )();
  }, [
    bookingMember,
    bookingMemberFull.docResumes,
    diver,
    formState.isDirty,
    handleSubmit,
    initialFormValue,
    onConfirm,
  ]);

  return (
    <form className={'h-full'} onSubmit={submitForm}>
      <AppPageContentContainer className="bg-white mx-1 app-px-content">
        <div className="py-4 sm:mr-7">
          <BookingParticipantEditorDialogHeader diver={diver} />
        </div>
        {/* <h3 className="mt-2 lg:text-left px-4 text-center text-sm sm:text-base md:text-lg lg:text-xl font-extrabold bg-gray-600 text-white uppercase">
            Inscription rapide des participants
          </h3>{' '} */}
        <BookingParticipantEditorDialogEditPanel1
          className="mt-1"
          diver={diver}
          bookingMember={bookingMember}
          diverCardAttributes={generalAttributes}
          expandedPanel={expandedPanel}
          expandPanelToogle={expandPanelToogle}
          renderDetails={() => <BookingMemberGeneralEditFormRHF form={form} />}
        />
        <BookingParticipantEditorDialogEditPanel2
          className="mt-1"
          diver={diver}
          bookingMember={bookingMember}
          diverCardAttributes={diverAttributes}
          expandedPanel={expandedPanel}
          expandPanelToogle={expandPanelToogle}
          renderDetails={() => (
            <BookingMemberDiverEditFormRHF
              mode="edit"
              isDiverWithMember={true}
              className="pb-28"
              form={form}
            />
          )}
        />
        <BookingParticipantEditorDialogEditPanel4
          className="mt-1"
          diver={diver}
          defaultDiveConfigCardDiveAttributes={
            defaultDiveConfigCardDiveAttributes
          }
          expandedPanel={expandedPanel}
          expandPanelToogle={expandPanelToogle}
          renderDetails={() => (
            <BookingParticipantDefaultParamsEditFormRHF
              maxWidthMd={false}
              className="pb-28"
              form={form}
            />
          )}
        />
      </AppPageContentContainer>
      <div className="flex-grow" />
      <AppFixedButtonsBar hasChanges={true}>
        <>
          <AppButton
            size="normal"
            icon={AppHeroIcons.actionCancel}
            tabIndex={500}
            color="gray-outline-light"
            onClick={() => {
              onCancel();
            }}
          >
            Annuler
          </AppButton>
          <AppButton
            type="submit"
            size="normal"
            icon={AppHeroIcons.actionSave}
            color="primary-outline-light"
          >
            Ok
          </AppButton>
        </>
      </AppFixedButtonsBar>
    </form>
  );
};
