import { ClubAuthUserProfile } from '@mabadive/app-common-model';
import { Observable } from 'rxjs';
import { graphqlClient, MQueryDescription } from 'src/_common-browser';
import { SettingsUserAccount } from '../model';

export type ClubSettingsUserAccountsManyGraphqlQueryResult = {
  userAccounts: SettingsUserAccount[];
  clubProfiles: ClubAuthUserProfile[];
};
export type ClubSettingsUserAccountOneGraphqlQueryResult = {
  userAccount: SettingsUserAccount;
  clubProfiles: ClubAuthUserProfile[];
};
export type ClubSettingsUserProfileOneGraphqlQueryResult = {
  userAccounts: SettingsUserAccount[];
  clubProfile: ClubAuthUserProfile;
};
export const clubSettingsUserAccoutsPageGraphqlFetcher = {
  fetchUserAccount,
  fetchUserProfile,
  fetchAll,
};
function fetchUserAccount({
  clubReference,
  diveCenterId,
  userAccountId,
}: {
  clubReference: string;
  diveCenterId: string;
  userAccountId: string;
}): Observable<ClubSettingsUserAccountOneGraphqlQueryResult> {
  const q1: MQueryDescription<any> = buildQueryUserProfiles({ clubReference });

  const queries = [q1];
  if (userAccountId) {
    // édition
    const q2: MQueryDescription<any> = buildQueryUserAccountById({
      userAccountId,
      diveCenterId,
      clubReference,
    });
    queries.push(q2);
  }

  return graphqlClient.query.runMany<ClubSettingsUserAccountOneGraphqlQueryResult>(
    queries,
    {
      name: 'clubSettingsUserAccoutsPageGraphqlFetcher.fetchUserAccount',
      type: 'query',
      headers: {
        'x-hasura-role': 'club-admin', // select hasura role
      },
    },
  );
}
function fetchUserProfile({
  clubReference,
  clubProfileId,
  diveCenterId,
}: {
  clubReference: string;
  clubProfileId: string;
  diveCenterId: string;
}): Observable<ClubSettingsUserProfileOneGraphqlQueryResult> {
  const q1: MQueryDescription<any> = buildQueryUserProfileById({
    clubProfileId,
    clubReference,
  });
  const q2: MQueryDescription<any> = buildQueryUserAccounts({
    diveCenterId,
    clubReference,
    clubProfileId,
  });
  const queries = [q1, q2];

  return graphqlClient.query.runMany<ClubSettingsUserProfileOneGraphqlQueryResult>(
    queries,
    {
      name: 'clubSettingsUserAccoutsPageGraphqlFetcher.fetchUserProfile',
      type: 'query',
      headers: {
        'x-hasura-role': 'club-admin', // select hasura role
      },
    },
  );
}
function fetchAll({
  clubReference,
  diveCenterId,
}: {
  clubReference: string;
  diveCenterId: string;
}): Observable<ClubSettingsUserAccountsManyGraphqlQueryResult> {
  const q1: MQueryDescription<any> = buildQueryUserAccounts({
    diveCenterId,
    clubReference,
  });
  const q2: MQueryDescription<any> = buildQueryUserProfiles({ clubReference });

  const queries = [q1, q2];
  return graphqlClient.query.runMany<ClubSettingsUserAccountsManyGraphqlQueryResult>(
    queries,
    {
      name: 'clubSettingsUserAccoutsPageGraphqlFetcher.fetchAll',
      type: 'query',
      headers: {
        'x-hasura-role': 'club-admin', // select hasura role
      },
    },
  );
}

function buildQueryUserProfiles({
  clubReference,
}: {
  clubReference: string;
}): MQueryDescription<any> {
  return {
    returnName: 'clubProfiles',
    queryName: 'club_auth_user_profile',
    returnType: 'all',
    where: `{clubReference: {_eq: "${clubReference}"}}`,
    returnAttributes: `
        _id
        label
        description
        authorizations
      `,
  };
}

function buildQueryUserAccounts({
  diveCenterId,
  clubReference,
  clubProfileId,
}: {
  diveCenterId: string;
  clubReference: string;
  clubProfileId?: string;
}): MQueryDescription<any> {
  const authUserWhereClauses = [
    `{diveCenterId: {_eq: "${diveCenterId}"}}`,
    `{clubReference: {_eq: "${clubReference}"}}`,
  ];
  if (clubProfileId) {
    authUserWhereClauses.push(`{clubProfileId: {_eq: "${clubProfileId}"}}`);
  }
  return {
    returnName: 'userAccounts',
    queryName: 'account_profile',
    returnType: 'all',
    where: `{authUser: {_and: [${authUserWhereClauses.join(', ')}]}}`,
    returnAttributes: `
        _id
        _createdAt
        emailAddress
        firstName
        lastName
        phoneNumber
        staffRole
        clubStaffMemberId
        authUser {
          _id
          clubProfileId
          login
          log {
            lastLoginDate
            lastUpdate
          }
        }
      `,
  };
}

function buildQueryUserAccountById({
  userAccountId,
  diveCenterId,
  clubReference,
}: {
  userAccountId: string;
  diveCenterId: string;
  clubReference: string;
}): MQueryDescription<any> {
  return {
    returnName: 'userAccount',
    queryName: 'account_profile',
    returnType: 'first',
    where: `{authUser: {_and: [{_id: {_eq: "${userAccountId}"}}, {diveCenterId: {_eq: "${diveCenterId}"}}, {clubReference: {_eq: "${clubReference}"}}]}}`,
    returnAttributes: `
        _id
        _createdAt
        emailAddress
        firstName
        lastName
        phoneNumber
        staffRole
        clubStaffMemberId
        authUser {
          _id
          clubProfileId
          login
          log {
            lastLoginDate
            lastUpdate
          }
        }
      `,
  };
}

function buildQueryUserProfileById({
  clubProfileId,
  clubReference,
}: {
  clubProfileId: string;
  clubReference: string;
}): MQueryDescription<any> {
  return {
    returnName: 'clubProfile',
    queryName: 'club_auth_user_profile',
    returnType: 'first',
    where: `{_and: [{_id: {_eq: "${clubProfileId}"}}, {clubReference: {_eq: "${clubReference}"}}]}`,
    returnAttributes: `
        _id
        label
        description
        authorizations
      `,
  };
}
