/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveCenterDailyConfig,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDialogsState } from '../../../../../pages/_dialogs';
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import {
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
  DiveSessionSimpleCardDisplayOptions,
} from '../model';
import { DiveSessionSimpleCard } from './ClubPlanningLightSession';

export const ClubPlanningLightViewDaySession = ({
  dailyConfig,
  dialogsState,
  context,
  session,
  sessionIndex,
  sessionsDisplayConfigs = [],
  clickActions,
  planningConfig,
  planningParticipantsLocalState,
}: {
  planningParticipantsLocalState?: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
  dialogsState: ClubDialogsState; // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  dailyConfig: DiveCenterDailyConfig;
  context: ClubPlanningDisplayConfigurationBarContext;
  session: ClubPlanningLightSessionDef;
  sessionIndex?: { index: number; total: number };
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  clickActions: ClubPlanningLightPanelViewClickActions;
  planningConfig: PlanningViewConfig;
}) => {
  const displayMode = planningConfig.viewDayOptions.displayMode;
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const {
    onClickSession,
    onClickSessionOpenButton,
    onClickAddParticipant,
    onClickParticipant,
    anchorLinkParticipant,
    onClickCreateNewSession,
    onClickCreateNewEvent,
    onClickEvent,
  } = clickActions;

  const displayOptions: DiveSessionSimpleCardDisplayOptions = useMemo(
    () => ({
      boats: diveCenterResume?.boats.length > 1,
      tide: true,
      weather: true,
      comment: true, // displayMode === 'compact',
      addParticipantButton1:
        displayMode === 'compact' && onClickAddParticipant !== undefined,
      addParticipantButton2: onClickAddParticipant !== undefined,
      openSessionButton: onClickSessionOpenButton !== undefined,
      isExpandable: displayMode === 'compact' && !session.isVirtual, // not virtual
      showDiveGroups: context !== 'planning-staff-page',
      showStaffRoles: context === 'planning-staff-page',
    }),
    [
      diveCenterResume?.boats.length,
      displayMode,
      onClickAddParticipant,
      onClickSessionOpenButton,
      session.isVirtual,
      context,
    ],
  );
  return (
    <DiveSessionSimpleCard
      planningParticipantsLocalState={planningParticipantsLocalState}
      dialogsState={dialogsState}
      dailyConfig={dailyConfig}
      widthMode="medium"
      flexGrow={
        displayMode === 'full' ||
        displayMode === 'compact' ||
        context === 'planning-staff-page'
      }
      planningConfig={planningConfig}
      session={session}
      display={{
        ...displayOptions,
        sessionIndex,
      }}
      // NOTE: il faudrait revoir un peu tout ça en mode `full`, pour que les participants soient tout le temps visible, mais tout en ayant la possibilité d'"ouvrir" les détails, avec :
      // - le bouton "add" toujours visible
      // - le commentaire et le champ "open" seulement visible si on ouvre la session
      expandByDefault={
        displayMode === 'full' || context === 'planning-staff-page'
      }
      selectionColor={
        sessionsDisplayConfigs.find(
          (x) => x.diveSessionReference === session.reference,
        )?.style
      }
      clickActions={clickActions}
    />
  );
};
