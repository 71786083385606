/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { CreateOfferPayload } from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import { ClubSettingsServicesOffersPricesContextMode } from '../_model';
import { ClubSettingsServicesViewPanelOffersPricesActionsBar_AdditionalProducts } from './ClubSettingsServicesViewPanelOffersPricesActionsBar_AdditionalProducts';
import { ClubSettingsServicesViewPanelOffersPricesActionsBar_Explo } from './ClubSettingsServicesViewPanelOffersPricesActionsBar_Explo';
import { ClubSettingsServicesViewPanelOffersPricesActionsBar_FirstDive } from './ClubSettingsServicesViewPanelOffersPricesActionsBar_FirstDive';
import { ClubSettingsServicesViewPanelOffersPricesActionsBar_PMT_ACC } from './ClubSettingsServicesViewPanelOffersPricesActionsBar_PMT_ACC';
import { ClubSettingsServicesViewPanelOffersPricesActionsBar_Training } from './ClubSettingsServicesViewPanelOffersPricesActionsBar_Training';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export const ClubSettingsServicesViewPanelOffersPricesActionsBar = ({
  localState,
  contextMode,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
  contextMode: ClubSettingsServicesOffersPricesContextMode;
}) => {
  const { data, state, actions } = localState;
  const publicSettings = data.publicSettings;
  const clubReference = data.clubReference;
  const clubSettings = data.clubSettings;

  return state.editEnabled ? (
    <div className="flex gap-4 flex-wrap">
      {contextMode === 'first-dive' && (
        <ClubSettingsServicesViewPanelOffersPricesActionsBar_FirstDive
          localState={localState}
        />
      )}
      {contextMode === 'training' && (
        <ClubSettingsServicesViewPanelOffersPricesActionsBar_Training
          localState={localState}
        />
      )}
      {contextMode === 'explo' && (
        <ClubSettingsServicesViewPanelOffersPricesActionsBar_Explo
          localState={localState}
        />
      )}
      {contextMode === 'others' && (
        <ClubSettingsServicesViewPanelOffersPricesActionsBar_PMT_ACC
          localState={localState}
        />
      )}
      {contextMode === 'extras' && (
        <ClubSettingsServicesViewPanelOffersPricesActionsBar_AdditionalProducts
          localState={localState}
        />
      )}
      {data.offersAll.length > 0 && (
        <AppButton
          className="whitespace-nowrap"
          color={'primary-outline-light'}
          size="normal"
          icon={AppIcons.action?.edit}
          onClick={(e) => {
            const offersPaylads: CreateOfferPayload[] = data.offersAll.map(
              (offer) => {
                const payload: CreateOfferPayload = {
                  offer,
                };
                return payload;
              },
            );
            e.stopPropagation();
            actions.openOfferEditDialog({
              mode: 'edit',
              offers: offersPaylads,
            });
          }}
        >
          Modifier tout
        </AppButton>
      )}
    </div>
  ) : null;
};
