import { DiveCenterResume } from '@mabadive/app-common-model';
import { commonParticipantBuilder } from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantsBySession,
  DiverBookingPageUpdateState,
  DiverBookingUpdateProductParticipant,
  PRO_BookingMemberFull,
} from '../../../models';
import {
  diverBookingPageUpdateStateManager,
  nearestParticipantFinder,
} from '../../02.update-state';
import { diverBookingParticipantCreator } from '../entity-creators';

export const quickBookingParticipantCreator = {
  createParticipantsIfPossible,
  createParticipantFromDefaultDiveConfig,
};
function createParticipantsIfPossible({
  newParticipants,
  context,
}: {
  newParticipants: {
    bookingSessionFull: AggregatedBookingSessionFull;
    bookingResumeMembers: Pick<
      PRO_BookingMemberFull,
      'diver' | 'bookingMember'
    >[];
  }[];
  context: {
    diveCenterResume: DiveCenterResume;
    updateState: DiverBookingPageUpdateState;
    participantsBySessions: BookingResumeParticipantsBySession[];
  };
}): DiverBookingPageUpdateState {
  const { diveCenterResume, updateState } = context;

  let updateStateLocal = updateState;

  for (const newParticipantsOne of newParticipants) {
    const { bookingResumeMembers, bookingSessionFull } = newParticipantsOne;

    const bookingId = bookingSessionFull.booking._id;

    for (const bookingResumeMember of bookingResumeMembers) {
      const { updateState, success } = createParticipantFromDefaultDiveConfig({
        bookingResumeMember,
        diveCenterResume,
        bookingSessionFull,
        bookingId,
        updateState: updateStateLocal,
      });
      updateStateLocal = updateState;

      if (!success) {
        const { updateState } = createParticipantsFromNearest({
          bookingSessionFull,
          bookingResumeMember,
          context: {
            clubReference: diveCenterResume.clubReference,
            diveCenterId: diveCenterResume._id,
            participantsBySessions: context.participantsBySessions,
          },
          updateState: updateStateLocal,
        });
        updateStateLocal = updateState;
      }
    }
  }
  return updateStateLocal;
}

function createParticipantFromDefaultDiveConfig({
  bookingResumeMember,
  diveCenterResume,
  bookingSessionFull,
  bookingId,
  updateState,
}: {
  bookingResumeMember: Pick<PRO_BookingMemberFull, 'diver' | 'bookingMember'>;
  diveCenterResume: DiveCenterResume;
  bookingSessionFull: AggregatedBookingSessionFull;
  bookingId: string;
  updateState: DiverBookingPageUpdateState;
}): {
  updateState: DiverBookingPageUpdateState;
  success: boolean;
} {
  let updateStateLocal = { ...updateState };

  const { bookingMember, diver } = bookingResumeMember;
  const defaultDiveConfig = diver?.defaultDiveConfig;
  if (defaultDiveConfig?.diveMode) {
    const newBookingProductParticipant: DiverBookingUpdateProductParticipant =
      commonParticipantBuilder.createParticipantFromDefaultDiveConfig({
        bookingMember,
        diver,
        diveCenterResume,
        bookingSessionId: bookingSessionFull.bookingSession._id,
        diveSessionReference: bookingSessionFull.diveSession.reference,
        bookingId,
        defaultDiveMode:
          bookingSessionFull.diveSession.details?.defaultDiveMode,
        sessionsCount: bookingSessionFull?.diveSession?.sessionsCount,
      });

    updateStateLocal =
      diverBookingPageUpdateStateManager.addNewParticipantToState({
        updateState: updateStateLocal,
        newBookingProductParticipant,
      });
    return { updateState: updateStateLocal, success: true };
  } else {
    // skip
    appLogger.warn('Information missing to create participant');
  }
  return { updateState: updateStateLocal, success: false };
}

function createParticipantsFromNearest({
  updateState,
  bookingSessionFull,
  bookingResumeMember,
  context,
}: {
  updateState: DiverBookingPageUpdateState;
  bookingSessionFull: AggregatedBookingSessionFull;
  bookingResumeMember: Pick<PRO_BookingMemberFull, 'diver' | 'bookingMember'>;
  context: {
    clubReference: string;
    diveCenterId: string;
    participantsBySessions: BookingResumeParticipantsBySession[];
  };
}): {
  updateState: DiverBookingPageUpdateState;
  success: boolean;
} {
  const { diveCenterId, clubReference, participantsBySessions } = context;

  let updateStateLocal = { ...updateState };

  const bookingId = bookingSessionFull.booking._id;

  const diverId = bookingResumeMember.bookingMember.diverId;

  // filter out empty products (but keep new session entry)
  const { participant: nearestParticipant } =
    nearestParticipantFinder.findNearestSessionResume({
      participantsBySessions,
      diveSessionReference: bookingSessionFull.diveSession.reference,
      diverId,
    });

  if (nearestParticipant) {
    // create participant from nearest session
    const newBookingProductParticipant: DiverBookingUpdateProductParticipant =
      diverBookingParticipantCreator.createParticipantFromSessionClone({
        sourceClubParticipant:
          nearestParticipant.bookingParticipantFull.diveSessionParticipant,
        clubReference,
        diveCenterId,
        bookingSessionId: bookingSessionFull.bookingSession._id,
        targetDiveSessionReference: bookingSessionFull.diveSession.reference,
        bookingMemberId: bookingResumeMember.bookingMember._id,
        diverId: bookingResumeMember.bookingMember.diverId,
        bookingId,
        targetSessionsCount: bookingSessionFull?.diveSession?.sessionsCount,
        targetDiveSessionGroupDiveGuide: null,
        targetDiveSessionGroupId: null,
      });
    updateStateLocal =
      diverBookingPageUpdateStateManager.addNewParticipantToState({
        updateState: updateStateLocal,
        newBookingProductParticipant,
      });
    return { updateState: updateStateLocal, success: true };
  }
  return { updateState: updateStateLocal, success: false };
}
