/* eslint-disable @typescript-eslint/no-unused-vars */
import { whatzappLinkBuilder } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import {
  AppPageContainer,
  AppPageContentContainer,
  useAppDevice,
} from 'src/business/_core/modules/layout';
import {
  AppButton,
  AppMessageLight,
} from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { useClubResume } from 'src/business/club/data/hooks';
import { useClubOfferState } from '../ClubOfferViewPage/useClubOfferState.hook';

export const ContactPage = () => {
  const contact = useMemo(
    () => ({
      email: `${appWebConfig.c.e[0]}@${appWebConfig.c.e[1].join('.')}`,
      phone: {
        label: `${appWebConfig.c.p.join(' ')}`,
        number: `${appWebConfig.c.p.join('')}`,
      },
    }),
    [],
  );
  const { isMobile } = useAppDevice();

  const whatzappLink = useMemo(
    () =>
      contact.phone.number &&
      whatzappLinkBuilder.buildLink({
        message: 'Bonjour Nicolas, ',
        phoneNumber: contact.phone.number,
        isMobile,
      }),
    [contact.phone.number, isMobile],
  );
  const clubResume = useClubResume();
  const offer = clubResume?.offer;
  const clubOfferState = useClubOfferState(offer);

  return (
    <AppPageContainer className={''}>
      <AppPageContentContainer className="bg-gray-50 app-px-content py-8">
        <div className="app-card app-p-content text-gray-600 grid gap-8">
          <div className="grid gap-4">
            {(offer.state === 'draft' || offer.state === 'demo') && (
              <>
                <p className="text-base">
                  Pour exploiter pleinement les possibilités de Mabadive,{' '}
                  <b>votre compte doit être configuré</b> afin de s'adapter à
                  votre organisation et à vos prestations.
                </p>
                <p className="text-base">
                  Vous êtes nouveau sur mabadive? <br />
                  <b>Demandez une démo</b> afin de découvrir toutes les
                  fonctionnalités et d'obtenir des réponses à vos questions,
                  c'est gratuit!
                </p>
                <div className="mt-3 text-base font-bold ">
                  Contactez Nicolas, le développeur de mabadive, n'hésitez pas!
                </div>
              </>
            )}
            {(offer.state === 'active' || offer.state === 'trial') && (
              <>
                <p className="text-base">
                  Vous avez des questions, des besoins, un bug ou envie de faire
                  le point? <br />
                </p>
                <div className="mt-3 text-base font-bold ">
                  N'hésitez pas à nous contacter, nous sommes là pour ça!
                </div>
              </>
            )}
            {offer.state === 'archived' && (
              <>
                <p className="text-base text-app-red">
                  Votre compte est expiré.
                </p>
                <div className="mt-3 text-base font-bold ">
                  <b>Pour l'activer</b>, contactez-nous!
                </div>
              </>
            )}
          </div>

          <div className="flex gap-6">
            <AppButton
              className="text-xl"
              color="mail-outline"
              icon={AppHeroIcons.contact}
              href={`mailto:${contact.email}`}
              target="_blank"
            >
              {contact.email}
            </AppButton>
            <AppButton
              className="text-xl whitespace-nowrap"
              color="phone-outline"
              icon={AppHeroIcons.phone}
              href={`tel:${contact.phone.number}`}
              target="_blank"
            >
              {contact.phone.number}
            </AppButton>

            {whatzappLink && (
              <AppButton
                className="text-xl"
                icon={() => <IoLogoWhatsapp />}
                color="whatsapp-outline"
                href={whatzappLink}
                target="_blank"
              >
                WhatsApp
              </AppButton>
            )}
            <AppButton
              className="text-xl whitespace-nowrap"
              color="phone-outline"
              icon={AppHeroIcons.sms}
              href={`sms:${contact.phone.number}`}
              target="_blank"
            >
              SMS
            </AppButton>
          </div>
          {clubResume?.clubSettings?.general?.internal?.screenSharingUrl && (
            <AppMessageLight>
              <div className="grid gap-4">
                <div className="grid gap-2">
                  <h2 className="mt-3 text-base font-bold">
                    Lien de partage d'écran
                  </h2>
                  <div>
                    Lors de nos échanges téléphoniques, il est pratique de
                    partager notre écran avec vous.
                  </div>
                </div>
                <div className="grid gap-2">
                  <b>Pour y accéder:</b>
                  <ol className="ml-8 list-decimal">
                    <li>
                      <b>cliquez</b> sur le lien suivant :{' '}
                      <a
                        className="text-app-primary underline"
                        href={
                          clubResume?.clubSettings?.general?.internal
                            ?.screenSharingUrl
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {
                          clubResume?.clubSettings?.general?.internal
                            ?.screenSharingUrl
                        }
                      </a>
                    </li>
                    <li>
                      <b>refuser</b> le partage de micro ou de caméra
                    </li>
                    <li>
                      <b>cliquez</b> sur le bouton <b>"Rejoindre la réunion"</b>
                    </li>
                  </ol>
                </div>
                <AppButton
                  className="text-xl"
                  fullWidth={true}
                  color="primary-outline-light"
                  icon={AppIconsAction.screenSharing}
                  href={
                    clubResume?.clubSettings?.general?.internal
                      ?.screenSharingUrl
                  }
                  target="_blank"
                >
                  {
                    clubResume?.clubSettings?.general?.internal
                      ?.screenSharingUrl
                  }
                </AppButton>
              </div>
            </AppMessageLight>
          )}
        </div>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
