import TTLCache from '@isaacs/ttlcache';
import {
  AppDurationParserString,
  durationParser,
} from '@mabadive/app-common-services';
import { AppCacheStore } from '../AppCacheStore.type';
import { AppCacheTTLStore } from '../AppCacheTTL';
import { AppInnerCacheState } from './useAppFetchDataWithCache.hook';

export const appFetchDataCacheStoreBuilder = {
  buildCache,
};

export type AppFetchDataCacheStoreOptions = {
  baseKey: string;
  cacheValidity?: AppDurationParserString;
};

function buildCache<K extends Object, R>(
  options: AppFetchDataCacheStoreOptions,
) {
  const _cacheStore: AppCacheStore<
    AppInnerCacheState<any>,
    TTLCache<any, any>
  > = AppCacheTTLStore({
    ttl: durationParser.parseTimestamp(options.cacheValidity ?? '7 days'),
    maxItems: 1000,
  });

  async function get(criteria: K): Promise<AppInnerCacheState<R>> {
    const key = generateUniqueKey(criteria, {
      baseKey: options.baseKey,
    });
    return await _cacheStore.get(key);
  }

  async function set(criteria: K, value: AppInnerCacheState<R>): Promise<void> {
    const key = generateUniqueKey(criteria, {
      baseKey: options.baseKey,
    });
    await _cacheStore.set(key, value);
  }

  return {
    get,
    set,
    options,
    _cacheStore,
  };
}

// export type AppFetchDataCacheStore<K extends Object, R> = ReturnType<typeof buildCache<K,R>>; // ok dans vscode, mais erreur de compil avec babel (à mettre à jour?)
export type AppFetchDataCacheStore<K extends Object, R> = ReturnType<
  typeof buildCache
>;

function generateUniqueKey(
  obj: Object,
  {
    baseKey,
  }: {
    baseKey: string;
  },
): string {
  // Convert the object to a JSON string
  const jsonString = `${baseKey}_@_${JSON.stringify(obj)}`;

  // Generate a hash from the JSON string
  const hash = hashCode(jsonString);

  // Convert the hash to a hexadecimal string
  const hexKey = hash.toString(16);

  return hexKey;
}

// Hashing function (you can use a library like crypto-js for better hashing)
function hashCode(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
}
