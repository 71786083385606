/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';

export const ClubSettingsServicesExplosViewPanel_Settings = ({
  localState,
}: {
  localState: ClubSettingsServicesPageLocalState;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();

  const { state, dialogs } = localState;

  const switchToEditMode = () => dialogs.clubSettingsServices.open('explo');

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const editEnabled = isSuperAdmin;
  return (
    <>
      {(isSuperAdmin ||
        clubSettings.ui?.billing?.displayBilledExploSessionsCounts) && (
        <ClubSettingsSection
          title={'Configuration'}
          // comment="Définissez les types de formations que vous proposez"
          onClick={editEnabled ? switchToEditMode : undefined}
          actions={
            editEnabled ? (
              <>
                {state.editEnabled && (
                  <div className="">
                    <AppButton
                      color={'primary-outline-light'}
                      size="normal"
                      icon={AppIcons.action?.edit}
                      onClick={() => {
                        switchToEditMode();
                      }}
                    >
                      Modifier
                    </AppButton>
                  </div>
                )}
              </>
            ) : null
          }
        >
          <AppFeatureBadge
            enabled={clubSettings.ui?.billing?.displayBilledExploSessionsCounts}
            label="Afficher le nombre d'explos facturées des plongeurs sur l'onglet de facturation"
            visibility="super-admin"
          />
        </ClubSettingsSection>
      )}
    </>
  );
};
