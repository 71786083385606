/* eslint-disable @typescript-eslint/no-unused-vars */
import { WeekDate } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React, { useCallback, useMemo, useState } from 'react';
import { useMediaSizeMaterialUI } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsUI,
} from 'src/business/_core/modules/layout/icons';
import { AppHackableCalendarDialog } from 'src/stories/components';
import { appDateNavigatorHelper } from './appDateNavigatorHelper.service';

export const AppDateNavigationBar = ({
  viewPeriod,
  date,
  setDate,
  className,
}: {
  viewPeriod: 'day' | 'week' | 'month';
  date: Date;
  setDate: (date: Date) => void;
  className?: string;
}) => {
  const mediaSize = useMediaSizeMaterialUI();

  const [showDatepicker, setShowDatePicker] = useState(false);

  const focusDate = useMemo(() => date ?? new Date(), [date]);

  const setFocusDate = useCallback(
    (focusDate: Date) => {
      setShowDatePicker(false);
      setDate(focusDate);
    },
    [setDate],
  );

  function increaseFocusDate(
    focusDate: Date,
    options: {
      value: number;
      unit: 'day' | 'week' | 'month' | 'year';
    },
  ) {
    setFocusDate(
      dateService.add(
        dateService.getUTCDateSetTime(focusDate, 12),
        options.value,
        options.unit,
      ),
    );
  }

  const weekDates: WeekDate[] = useMemo(
    () => dateService.getWeekDates(focusDate),
    [focusDate],
  );

  const dateLabel = useMemo(() => {
    const yearFormat =
      weekDates[0].date.getUTCFullYear() === new Date().getUTCFullYear() &&
      weekDates[0].date.getUTCFullYear() ===
        weekDates[weekDates.length - 1].date.getUTCFullYear()
        ? ''
        : 'YYYY';

    if (viewPeriod === 'month') {
      return dateService
        .formatUTC(focusDate, `MMM ${yearFormat}`)
        .toUpperCase();
    }
    if (viewPeriod === 'week') {
      if (
        weekDates[0].date.getUTCMonth() !==
        weekDates[weekDates.length - 1].date.getUTCMonth()
      ) {
        // la semaine s'étale sur 2 mois: on affiche les 2
        return `${dateService.formatUTC(
          weekDates[0].date,
          'MMM',
        )}/${dateService.formatUTC(
          weekDates[weekDates.length - 1].date,
          `MMM ${yearFormat}`,
        )}`.toUpperCase();
      } else {
        return dateService
          .formatUTC(weekDates[0].date, `MMM ${yearFormat}`)
          .toUpperCase();
      }
    } else {
      return dateService
        .formatUTC(
          focusDate,
          mediaSize === 'xs'
            ? `ddd D MMM ${yearFormat}`
            : `dddd D MMM ${yearFormat}`,
        )
        .toUpperCase();
    }
  }, [focusDate, mediaSize, viewPeriod, weekDates]);

  const isNow = useMemo(
    () =>
      appDateNavigatorHelper.isPlanningNow({
        viewPeriod,
        focusDate,
        weekDates,
      }),
    [focusDate, viewPeriod, weekDates],
  );

  return (
    <div
      className={`flex-grow flex justify-center items-center px-2$ ${className}`}
    >
      <button
        className={
          'text-app-blue border border-blue-200 hover:bg-gray-100 cursor-pointer py-1 px-2 sm:px-5 mx-2'
        }
        onClick={() => {
          increaseFocusDate(focusDate, {
            value: -1,
            unit:
              viewPeriod === 'month'
                ? 'year'
                : viewPeriod === 'week'
                ? 'month'
                : 'week',
          });
        }}
      >
        <AppIconsUI.navLeftFast className="w-4 h-4" />
      </button>
      <button
        className="text-app-blue border border-blue-200 hover:bg-gray-100 cursor-pointer py-1 px-1 sm:px-5"
        onClick={() => {
          increaseFocusDate(focusDate, {
            value: -1,
            unit:
              viewPeriod === 'month'
                ? 'month'
                : viewPeriod === 'week'
                ? 'week'
                : 'day',
          });
        }}
      >
        <AppIconsUI.navLeft className="w-4 h-4" />
      </button>
      <div>
        <AppHackableCalendarDialog
          open={showDatepicker}
          setOpen={setShowDatePicker}
          selectedDate={focusDate}
          onSelectDate={(d) => {
            setFocusDate(dateService.getUTCDateSetTime(d, 12));
          }}
        >
          <span
            className={`mx-2 flex justify-center items-center px-2 whitespace-nowrap text-xs sm:text-sm md:text-base text-gray-600 font-bold hover:text-gray-700  cursor-pointer hover:bg-gray-100 ${
              isNow ? 'text-app-orange' : ''
            }`}
            style={{
              minWidth:
                mediaSize !== 'xs' && mediaSize !== 'sm'
                  ? mediaSize === 'md'
                    ? '180px'
                    : '220px'
                  : undefined,
            }}
            onClick={() => {
              setShowDatePicker(true);
            }}
          >
            {/* <AppHeroIcons.actionEdit className="h-3 w-3 mr-1" />  */}
            <AppHeroIcons.calendar
              className={'hidden text sm:flex mr-2 w-6 h-6 cursor-pointer'}
            />
            {dateLabel}
          </span>
        </AppHackableCalendarDialog>
      </div>

      <button
        className="text-app-blue border border-blue-200 hover:bg-gray-100 cursor-pointer py-1 px-2 sm:px-5"
        onClick={() => {
          increaseFocusDate(focusDate, {
            value: 1,
            unit:
              viewPeriod === 'month'
                ? 'month'
                : viewPeriod === 'week'
                ? 'week'
                : 'day',
          });
        }}
      >
        <AppIconsUI.navRight className="w-4 h-4" />
      </button>

      <button
        className={
          'text-app-blue border border-blue-200 hover:bg-gray-100 cursor-pointer py-1 px-1 sm:px-5 mx-2'
        }
        onClick={() => {
          increaseFocusDate(focusDate, {
            value: 1,
            unit:
              viewPeriod === 'month'
                ? 'year'
                : viewPeriod === 'week'
                ? 'month'
                : 'week',
          });
        }}
      >
        <AppIconsUI.navRightFast className="w-4 h-4" />
      </button>
    </div>
  );
};
