/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppFormControlV2, AppSingleAutocomplete2 } from 'src/lib/form';
import { AppButton } from '../../../../../_core/modules/layout';
import { AppHeroIcons } from '../../../../../_core/modules/layout/icons';
import { ClubPlanningStaffEditModeToggleButton } from '../../../club-planning-light/club-planning-navigation/ClubPlanningStaffEditModeToggleButton';
import { SetPlanningConfigOptions } from '../../../club-planning-light/hooks';
import { ClubPlanningLightPanelViewClickActions } from '../../../club-planning-light/model';
import { PlanningViewConfig } from '../../_model';
import { ClubPlanningStaffPanelLocalState } from '../ClubPlanningStaffPanel';
import { ClubPlanningStaffWeekStaffPresenceFooterLegend } from './ClubPlanningStaffWeekStaffPresenceFooterLegend';
import { ClubPlanningStaffWeekStaffPresenceHeaderDays } from './ClubPlanningStaffWeekStaffPresenceHeaderDays';
import { ClubPlanningStaffWeekStaffPresenceHeaderEvents } from './ClubPlanningStaffWeekStaffPresenceHeaderEvents';
import { ClubPlanningStaffWeekStaffPresenceHeaderSessions } from './ClubPlanningStaffWeekStaffPresenceHeaderSessions';
import { ClubPlanningStaffWeekStaffPresenceHeaderTotals } from './ClubPlanningStaffWeekStaffPresenceHeaderTotals';
import { ClubPlanningStaffWeekStaffPresenceMembersEdit } from './ClubPlanningStaffWeekStaffPresenceMembersEdit';
import {
  ClubPlanningStaffWeekStaffPresenceLocalState,
  useClubPlanningStaffWeekStaffPresenceLocalState,
} from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';

export const ClubPlanningStaffWeekStaffPresence = ({
  parentState,
  planningConfig,
  setPlanningConfig,
  showHeaderDays,
  clickActions,
  className,
}: {
  parentState: ClubPlanningStaffPanelLocalState;
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  showHeaderDays?: boolean;
  clickActions: ClubPlanningLightPanelViewClickActions;
  className?: string;
}) => {
  const { days } = parentState.data;
  const localState: ClubPlanningStaffWeekStaffPresenceLocalState =
    useClubPlanningStaffWeekStaffPresenceLocalState({
      parentState,
      planningConfig,
      setPlanningConfig,
      clickActions,
    });

  const { state, data, actions, criteria } = localState;
  const { dialogsState } = state;

  const GRID_CLASS_NAME = useMemo(() => {
    return planningConfig.viewPeriod === 'week'
      ? 'grid grid-cols-7 lg:grid-cols-9 gap-0.5 sm:gap-2'
      : 'grid grid-cols-2 lg:grid-cols-3 gap-0.5 sm:gap-2';
  }, [planningConfig.viewPeriod]);

  return (
    <div className={clsx('flex flex-col gap-4', className)}>
      {planningConfig.viewPeriod === 'week' && (
        <div className="flex lg:hidden justify-between gap-4 items-end">
          <ClubPlanningStaffEditModeToggleButton
            planningConfig={planningConfig}
            setPlanningConfig={setPlanningConfig}
          />
          <AppFormControlV2
            className="w-80 max-w-full text-sm"
            label={'Filtrer par moniteur'}
            renderComponent={() => (
              <AppSingleAutocomplete2
                optionClassName="text-xs sm:text-sm"
                value={criteria.filterStaffMemberId}
                options={criteria.staffMemberOptions}
                // customRenderOption => TODO utiliser cette méthode pour afficher un rendu personnalié, comme via staffMemberOptionsBuilder.buildStaffMemberOptions
                onChange={(staffMemberId) => {
                  actions.updateStaffMemberFilter({
                    staffMemberId,
                  });
                }}
              />
            )}
          />
        </div>
      )}
      <div className="flex flex-col gap-1">
        {showHeaderDays && (
          <ClubPlanningStaffWeekStaffPresenceHeaderDays
            gridClassName={GRID_CLASS_NAME}
            days={days}
            planningConfig={planningConfig}
            setPlanningConfig={setPlanningConfig}
            localState={localState}
          />
        )}
        <ClubPlanningStaffWeekStaffPresenceHeaderSessions
          gridClassName={GRID_CLASS_NAME}
          days={days}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
          localState={localState}
        />
        <ClubPlanningStaffWeekStaffPresenceHeaderEvents
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
          gridClassName={GRID_CLASS_NAME}
          days={days}
          localState={localState}
          onClickEvent={clickActions.onClickEvent}
        />
        <ClubPlanningStaffWeekStaffPresenceHeaderTotals
          gridClassName={GRID_CLASS_NAME}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
          localState={localState}
        />
        <ClubPlanningStaffWeekStaffPresenceMembersEdit
          gridClassName={GRID_CLASS_NAME}
          days={days}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
          localState={localState}
        />
      </div>

      <AppButton
        color="primary-outline-light"
        size="normal"
        icon={AppHeroIcons.actionAdd}
        onClick={() =>
          dialogsState.staffMemberEditDialog.openDialog({
            mode: 'create',
            defaultValue: { staffMember: {} },
          })
        }
      >
        Nouveau moniteur
      </AppButton>

      <ClubPlanningStaffWeekStaffPresenceFooterLegend
        className="my-4"
        localState={localState}
      />
    </div>
  );
};
