import { BaseEntity } from '@mabadive/app-common-model';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ResourceNotFoundError } from '../../../errors';
import { localEntityRxjs } from '../../localEntityRxjs.service';
import { CollectionReadableStoreAdapter } from '../adapter';
import { CollectionReadableRepository } from './CollectionReadableRepository.type';

export const collectionRepositoryReaderFactory = {
  create,
};

function create<T extends BaseEntity>({
  mutationEntity,
  storeReadAdapter,
}: {
  mutationEntity: string;
  storeReadAdapter: CollectionReadableStoreAdapter<T>;
}): CollectionReadableRepository<T> {
  const repositoryName = `${mutationEntity}Repository`;

  return {
    getOne,
    getOneById,
    getMany,
  } as CollectionReadableRepository<T>;

  function getOneById(id: string) {
    const criteria = { _id: id } as Partial<T>;
    return getOne({ criteria });
  }

  function getOne(
    options?: ({ criteria: Partial<T> } | { filter?: (x: T) => boolean }) & {
      allowNotFound?: boolean;
    },
  ): Observable<T> {
    const allowNotFound = options ? options.allowNotFound : undefined;

    return storeReadAdapter.getOne(options).pipe(
      switchMap((item) => {
        if (item === undefined && allowNotFound !== true) {
          return throwError(
            new ResourceNotFoundError(
              `[CollectionRepository][${repositoryName}] ERROR: item not found`,
            ),
          );
        }
        return of(item);
      }),
      localEntityRxjs.distinctUntilEntityChanged<T>(),
    );
  }

  function getMany(
    options?: { criteria: Partial<T> } | { filter?: (x: T) => boolean },
  ): Observable<T[]> {
    return storeReadAdapter
      .getMany(options)
      .pipe(localEntityRxjs.distinctUntilEntityCollectionChanged<T>());
  }
}
