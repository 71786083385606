import { ClubSettingsUIPlanning } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { CalendarParticipantCellParticipant } from './model';

export function useParticipantExtraBadgeLabel<
  T extends CalendarParticipantCellParticipant,
>({
  participant,
  settingsUIPlanning,
  multipleSessionsGroup,
  multipleSessionNumber,
}: {
  participant: T;
  settingsUIPlanning: ClubSettingsUIPlanning;
  multipleSessionsGroup: boolean;
  multipleSessionNumber: number;
}) {
  return useMemo(() => {
    const t = participant?.activePurchasedTraining;

    if (
      t &&
      settingsUIPlanning.showPaymentFlagTraining &&
      participant.diveMode === 'training' &&
      participant.bookingState?.value !== 'cancelled'
    ) {
      const showTotal = t.creditsTotalCount > 0;
      const diveCountOffset =
        !multipleSessionsGroup &&
        multipleSessionNumber === 2 &&
        participant.divesCount === 2
          ? 2
          : 1;
      const currentDiveCount = t?.totalBeforeCount + diveCountOffset;
      let currentDiveCountLabel = `${currentDiveCount}`;
      if (multipleSessionsGroup && participant.divesCount === 2) {
        currentDiveCountLabel += `+${currentDiveCount + 1}`;
      }
      if (showTotal) {
        return `${currentDiveCountLabel}/${t?.creditsTotalCount}`;
      } else {
        return `N°${currentDiveCountLabel}`;
      }
    }
  }, [
    multipleSessionNumber,
    multipleSessionsGroup,
    participant?.activePurchasedTraining,
    participant.bookingState?.value,
    participant.diveMode,
    participant.divesCount,
    settingsUIPlanning.showPaymentFlagTraining,
  ]);
}
