import { useMemo } from 'react';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { SETTINGS_DIVE_CENTER } from '../SE-01_dive-center/SETTINGS_DIVE_CENTER.const';
import { SETTINGS_ACCOUNT } from '../SE-02_account/SETTINGS_ACCOUNT.const';
import { SETTINGS_CUSTOMERS } from '../SE-03-customers/SETTINGS_CUSTOMERS.const';
import { SETTINGS_OTHER } from '../SE-04_other-settings/SETTINGS_OTHER.const';
import { SettingsCategory, SettingsCategoryPageBadge } from '../_core';

export function useSettingsCategories() {
  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();

  return useMemo(() => {
    // TODO 2: on pourrait avoir cette page accessible à tous, mais avec un contenu différent:
    // => prendre en compte les droits utilisateurs et les fonctionnalités actives
    const categories: SettingsCategory[] = [
      SETTINGS_DIVE_CENTER,
      // SETTINGS_MODULES,
      SETTINGS_ACCOUNT,
      SETTINGS_CUSTOMERS,
      SETTINGS_OTHER,
    ]
      .map((category) => ({
        ...category,
        pages: category.pages
          .filter((p) => {
            // on filtre les pages non autorisées
            const checkRoles = !(p.roles ?? []).find(
              (r) => !(securityUser.roles ?? []).includes(r),
            );
            if (!checkRoles) {
              return false;
            }
            if (p.checkAuth) {
              const clubSettings = clubResume.clubSettings;
              return p.checkAuth({
                auth: securityUser,
                az: securityUser?.club?.authorizations,
                // clubResume,
                clubSettings,
              });
            }
            return true;
          })
          .filter((p) => (p.filter ? p.filter({ clubResume }) : true))
          .map((p) => {
            let badge: SettingsCategoryPageBadge;
            // if (category.id === 'dive-center') {
            //   if (p.id === 'staff') {
            //     badge = {
            //       label: '5',
            //     };
            //   } else if (p.id === 'dive-site') {
            //     badge = {
            //       label: 22,
            //     };
            //   } else if (p.id === 'boat') {
            //     badge = {
            //       label: 3,
            //     };
            //   }
            // } else if (category.id === 'account') {
            //   if (p.id === 'subscription') {
            //     badge = {
            //       icon: AppHeroIcons.badgeCheck,
            //     };
            //   }
            // } else if (category.id === 'modules') {
            //   if (p.id === 'billing') {
            //     badge = {
            //       icon: AppHeroIcons.disabled,
            //       iconColor: '#999',
            //     };
            //   }
            // }
            return {
              ...p,
              badge,
            };
          }),
      }))
      .filter((c) => c.pages.length > 0);
    return categories;
  }, [securityUser, clubResume]);
}
