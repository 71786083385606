import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { diverPurchasePackageBuilder } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiverPurchaseCommonEditorDialog';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui/AppPriceLabel';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { AppPackageExtraCostFormModel } from './model';

export const AppPackageExtraCostFormListExtraCostItem = ({
  form,
  extraCostIndex,
  className,
  onClick,
}: {
  form: UseFormReturn<AppPackageExtraCostFormModel, any>;
  extraCostIndex: number;
  className?: string;
  onClick: () => any;
}) => {
  const { control, register, handleSubmit, reset, watch, trigger, formState } =
    form;
  const mainCurrency = useAppCurrencyMain();

  const extraCostControlBaseName = `extraCosts[${extraCostIndex}]`;

  const [
    label,
    multiplier,
    unitPrice,
    unitQuantity,
    extraCostUnitDive,
    comment,
  ] = useWatch({
    control,
    name: [
      `${extraCostControlBaseName}.label`,
      `${extraCostControlBaseName}.multiplier`,
      `${extraCostControlBaseName}.unitPrice`,
      `${extraCostControlBaseName}.unitQuantity`,
      `${extraCostControlBaseName}.extraCostUnitDive`,
      `${extraCostControlBaseName}.comment`,
    ] as any[],
  });

  const { unitTotalPrice, totalPrice } = useMemo(
    () =>
      diverPurchasePackageBuilder.buildExtraCostTotals({
        multiplier,
        unitPrice,
        unitQuantity,
      }),
    [multiplier, unitPrice, unitQuantity],
  );

  return (
    <div
      className={`group hover:bg-gray-50 px-1 flex gap-2 items-start justify-between text-xs sm:text-base cursor-pointer ${className}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <div className={'flex gap-2 items-start'}>
        <AppHeroIcons.actionEditAlt className="w-6 h-6 text-gray-400 group-hover:text-gray-600" />
        <div>
          <h3 className="font-bold text-app-primary group-hover:text-app-primary-dark text-sm sm:text-base uppercase">
            {label}
          </h3>
          <div className="flex gap-1 text-gray-500 group-hover:text-gray-600">
            <AppPriceLabel
              className=""
              amount={unitPrice ?? 0}
              mainCurrency={mainCurrency}
            />
            {unitQuantity > 1 && <span>x {unitQuantity}</span>}
            {extraCostUnitDive && multiplier > 0 && (
              <span className="">x {multiplier} plongée(s)</span>
            )}
          </div>
          {comment && (
            <p className="text-xs sm:text-sm text-gray-400 italic">{comment}</p>
          )}
        </div>
      </div>
      <AppPriceLabel
        className="font-bold text-gray-600"
        amount={totalPrice}
        mainCurrency={mainCurrency}
      />
    </div>
  );
};
