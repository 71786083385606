/* eslint-disable @typescript-eslint/no-unused-vars */
import { CLUB_RESSOURCES_TYPES, search } from '@mabadive/app-common-services';
import React, { useMemo, useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppMessageLight,
  AppPageContainer,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppInputBoolean } from 'src/lib/form';
import {
  ClubBoatLabel,
  DiveCenterProBadge,
} from 'src/pages/_components/company';
import { clubSettingsDiveCenterUrlBuilder } from 'src/pages/SE-settings/SE-01_dive-center/clubSettingsDiveCenterUrlBuilder.service';
import useRouter from 'use-react-router';
import { ClubBoatsTable } from '../ClubBoatsTable';

export const ClubBoatsListPage = (props: {}) => {
  const { match } = useRouter();
  const autoFocus = useAutoFocus();

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const [searchText, setSearchText] = useState('');

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const boatsAll = useMemo(() => {
    return search.filter(diveCenterResume?.boats, {
      attributesNames: ['name', 'immatriculation', 'description'],
      searchText,
      sortResultsByBestMatch: false,
    });
  }, [diveCenterResume?.boats, searchText]);

  const hasAnyArchivedBoat = useMemo(
    () => boatsAll.findIndex((x) => !x.enabled) !== -1,
    [boatsAll],
  );
  const [displayArchivedBoats, setDisplayArchivedBoats] = useState(false);

  const boats = useMemo(
    () => (displayArchivedBoats ? boatsAll : boatsAll.filter((x) => x.enabled)),
    [displayArchivedBoats, boatsAll],
  );

  return (
    <AppPageContainer className={'bg-gray-50'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.diveCenter.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.diveCenter.main,
            label: 'Centre de plongée',
            url: '/club/settings',
          },
          {
            label: 'Ressources',
          },
        ]}
      >
        {isMultiDiveCenters && (
          <div className="app-px-content">
            <DiveCenterProBadge diveCenter={diveCenterResume} />
          </div>
        )}
      </AppBreadcrumbBar>

      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <div className="app-card p-4 ">
          <div className="flex flex-col xs:flex-row items-end gap-2">
            <input
              autoFocus={autoFocus}
              autoComplete="new-password"
              placeholder="Recherche"
              type="text"
              className="flex-grow w-max hover:border-app-blue rounded px-2"
              defaultValue={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <AppButton
              color="primary-outline-light"
              size="normal"
              icon={AppHeroIcons.actionAdd}
              onClick={() => redirectToClubBoatCreatePage()}
            >
              Ajouter
            </AppButton>
          </div>
          {hasAnyArchivedBoat && (
            <div
              className={`flex gap-2 justify-end font-bold cursor-pointer ${
                displayArchivedBoats ? 'text-gray-600' : 'text-gray-400'
              }`}
              onClick={() => setDisplayArchivedBoats(!displayArchivedBoats)}
            >
              <AppInputBoolean
                className="scale-125 block ml-0.5 mt-1 mb-1"
                type="checkbox"
                title="Afficher les sites archivés"
                value={displayArchivedBoats}
              />
              Afficher les bateaux archivés
            </div>
          )}
        </div>

        <div className="app-my-content app-card p-4 ">
          <ClubBoatsTable
            clubBoats={boats}
            onClickClubBoat={(clubBoatId) =>
              redirectToClubBoatEditPage(clubBoatId)
            }
          />
          <AppMessageLight className="mt-8">
            <h2 className="block font-bold uppercase">Types de ressources</h2>
            <div className="hidden md:block">
              Vous pouvez désormais créer différents types de ressources, pour
              coller au mieux à votre organisation:
            </div>
            <ul className="mt-2">
              {CLUB_RESSOURCES_TYPES.map((x) => (
                <li className="my-2" key={x.departureType}>
                  <ClubBoatLabel
                    className="text-xs"
                    iconSize="w-4 h-4"
                    clubBoat={{
                      departureType: x.departureType,
                      name: x.defaultLabel,
                      color: x.defaultColor,
                      isSpecial: true,
                      details: {
                        shortName: x.defaultLabelShort,
                      },
                    }}
                  />
                  <div className="hidden sm:inline">{x.legendDescription}</div>
                </li>
              ))}
            </ul>
          </AppMessageLight>
        </div>
      </AppPageContentContainer>
    </AppPageContainer>
  );

  function redirectToClubBoatCreatePage(): void {
    return redirectTo(clubSettingsDiveCenterUrlBuilder.boats.create());
  }

  function redirectToClubBoatEditPage(clubBoatId: string): void {
    return redirectTo(
      clubSettingsDiveCenterUrlBuilder.boats.edit({
        clubBoatId,
      }),
    );
  }
};
