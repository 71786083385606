/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Input,
  InputAdornment,
  InputProps,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { useCacheableClasses } from 'src/business/_core/modules/root/theme';
import { RxjsFormAttributeActions } from '../rxjs-form-attribute/RxjsFormAttributeActions.model';
import { RxjsFormAttributeState } from '../rxjs-form-attribute/RxjsFormAttributeState.model';

export function RxjsFormAppTextField<T extends string | number = string>({
  state,
  actions,
  clearable,
  ...extraTextFieldProps
}: {
  state: RxjsFormAttributeState<T>;
  actions: RxjsFormAttributeActions<T>;
  clearable?: boolean;
} & InputProps) {
  const value = useMemo(() => {
    return convertUndefinedToEmpty(state.value);
  }, [state.value]);

  const isEmptyValue = useMemo(
    () => !value || ((value as string).trim && (value as string).trim() === ''),
    [value],
  );

  const classes = useCacheableClasses({
    cacheKey: 'RxjsFormAppTextField',
    buildStyles,
  });
  return (
    <Input
      className={`${classes.root} ${isEmptyValue ? 'empty' : ''}`}
      name={state.def.name}
      required={state.def.required}
      value={value}
      onChange={(e) => onChange(e)}
      onBlur={() => onBlur()}
      error={state.showError}
      autoComplete="off"
      {...extraTextFieldProps}
      startAdornment={
        !clearable ? null : (
          <InputAdornment position="start">
            <AppIconsMaterial.cancel
              className="input-icon"
              onClick={() => updateValueFromComponent('')}
            />
          </InputAdornment>
        )
      }
    />
  );
  function onBlur(): void {
    return actions.touchComponent();
  }

  function onChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void {
    return updateValueFromComponent(e.target.value);
  }
  function updateValueFromComponent(value: string): void {
    return actions.updateValue(
      convertEmptyToUndefined<T>(value, extraTextFieldProps.type),
      'component',
    );
  }
}
function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      '& .input-icon': {
        color: '#999',
        '&:hover': {
          cursor: 'pointer',
          color: '#555',
        },
      },

      '&.empty, &.empty:hover': {
        '& .input-icon': {
          color: '#ccc',
          cursor: 'default',
        },
      },
    },
  });
}

function convertEmptyToUndefined<T>(value: string, type: string): T {
  if (type === 'number') {
    if (value == null) {
      return undefined;
    }
    const number = parseFloat(value);
    if (isNaN(number)) {
      return undefined;
    }
    return number as unknown as T;
  }
  const str = value && value.length ? value : undefined;
  return str as unknown as T;
}

function convertUndefinedToEmpty<T>(value: T) {
  return value != null ? value : '';
}
