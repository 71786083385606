/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveCenterEquipmentConfig } from '@mabadive/app-common-model';
import { equipmentFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIconsDiveEquipmentType } from 'src/business/_core/modules/layout/icons';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';

export const ClubSettingsEquipmentCard = ({
  equipment,
  onClick,
  className,
}: {
  equipment: DiveCenterEquipmentConfig;
  onClick?: () => any;
  className?: string;
}) => {
  const Icon = AppIconsDiveEquipmentType[equipment.type];

  const equipmentTypeLabel = useMemo(
    () => equipmentFormatter.formatEquipmentType(equipment.type),
    [equipment.type],
  );
  return (
    <div
      onClick={onClick}
      className={clsx(
        'my-2 app-card p-4 md:divide-y divide-gray-200',
        onClick ? 'cursor-pointer app-card-highlight' : '',
        className,
      )}
    >
      <div
        className={clsx(
          'py-2 flex items-center gap-4 text-xl font-bold uppercase',
          equipment.enabled ? 'text-gray-600' : 'text-gray-400',
        )}
      >
        {Icon && <Icon className="h-6 w-6" />}
        {equipmentTypeLabel}
      </div>
      <div className="py-2 grid gap-x-4 gap-y-2 lg:flex">
        <AppFeatureBadge
          label={'ACTIVÉ'}
          labelDisabled={'DÉSACTIVÉ'}
          enabled={equipment.enabled}
          description={equipment.enabled ? 'CLUB ou PERSO' : undefined}
        />
        <div className="flex-grow md:col-span-2 grid gap-y-2">
          {equipment.enabled && (
            <AppFeatureBadge
              label={'Choisir la taille ou le modèle'}
              description={
                'Permet d\'attribuer une taille ou un modèle à chaque plongeur'
              }
              enabled={equipment.club?.enabled}
            ></AppFeatureBadge>
          )}
          {equipment.enabled &&
            equipment.club?.enabled &&
            equipment.club?.models?.length > 0 && (
              <div className="ml-8 font-normal text-sm text-gray-400">
                <span className="font-medium text-gray-500">MODÈLES CLUB:</span>{' '}
                {(equipment.club?.models ?? [])
                  .map((model) => model.ref)
                  .join(', ')}
              </div>
            )}
          {equipment.enabled &&
            equipment.self?.enabled &&
            equipment.self?.models?.length > 0 && (
              <div className="ml-8 font-normal text-sm text-gray-400">
                <span className="font-medium text-gray-500">
                  MODÈLES PERSO:
                </span>{' '}
                {(equipment.self?.models ?? [])
                  .map((model) => model.ref)
                  .join(', ')}
              </div>
            )}
        </div>
        {equipment.enabled &&
          (equipment.club?.enabled || equipment.self?.enabled) &&
          equipment.altEnabled && (
            <AppFeatureBadge
              className=""
              label={'Taille ou modèle alternatif'}
              description={'Permet de choisir une seconde taille ou modèle'}
              enabled={equipment.altEnabled}
            ></AppFeatureBadge>
          )}
      </div>
    </div>
  );
};
