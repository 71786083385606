import React from 'react';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import {
  AppButton,
  AppButtonProps,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppButtonStyleColor } from './AppButtonStyleColor.type';

export type AppActionButtonProps = {
  action: AppHamburgerMenuItem;
  color?: AppButtonStyleColor;
} & Omit<AppButtonProps, 'color' | 'size' | 'id' | 'icon' | 'onClick'>;

export function AppActionButton({ action, ...props }: AppActionButtonProps) {
  return !action ? null : (
    <AppButton
      {...props}
      color={props.color ?? 'primary-outline-light'}
      size="normal"
      id={`action${action.id ? `-${action.id}` : ''}`}
      icon={action?.icon}
      onClick={action?.onClick}
    >
      {action?.titleShort ?? action?.title}
    </AppButton>
  );
}
