/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  AppComboBoxMultipleRHF,
  AppFormControlRHF,
} from '../../../../lib/form';
import { AppInputDatePickerMaterial } from '../../../../stories/components/04-form';
import { useOnlineBookingStatesOptions } from '../../../_components/options';
import { ClubOrdersListPageLocalState } from '../useClubOrdersListPageLocalState.hook';

export const ClubOrdersListPageConfigBarExtraFilters = ({
  localState,
  className,
}: {
  localState: ClubOrdersListPageLocalState;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const customerSettings = diveCenterResume?.clubResume?.clubSettings?.customer;

  const { filters } = localState;

  const autoFocus = useAutoFocus();
  const { searchText, setSearchText, form } = filters;
  const { control } = form;

  const onlineBookingStatesOptions = useOnlineBookingStatesOptions();
  return (
    <div className="flex flex-col gap-2">
      <input
        className="xs:hidden mx-1 w-full hover:border-app-blue rounded px-2"
        autoFocus={autoFocus}
        autoComplete="new-password"
        placeholder="Recherche par nom, prénom..."
        type="text"
        defaultValue={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <div
        className={clsx(
          'grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-x-2 gap-y-1',
          className,
        )}
      >
        {/* <AppFormControlV2
          className="w-full sm:col-span-1 bg-white px-2"
          label="Archivées"
          renderComponent={() => (
            <AppInputBooleanSwitch
              value={archivedExclusive}
              onChange={(value) => setIncludeArchived(value)}
            />
          )}
        /> */}
        <AppFormControlRHF
          label="Statut"
          control={control}
          name={'onlineBookingStates'}
          required={false}
          renderComponent={(props) => (
            <AppComboBoxMultipleRHF
              options={onlineBookingStatesOptions}
              {...props}
            />
            // <AppSingleSelect2HeadlessUIRHF
            //   className={'w-full'}
            //   theme="material-ui"
            //   color="theme"
            //   {...props}
            //   options={SAFETY_CERTIFICATION_OPTIONS}
            // />
          )}
        />
        <AppFormControlRHF
          className="w-full bg-white px-2"
          label="Date de la résa"
          control={control}
          name={'bookingDate'}
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-full"
            />
          )}
        />
        {/* <AppFormControlV2
          className="w-full bg-white px-2"
          label="Début de séjour"
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-full"
              value={arrivalDate}
              onChange={(arrivalDate) => setArrivalDate(arrivalDate)}
            />
          )}
        />
        <AppFormControlV2
          className="w-full bg-white px-2"
          label="Fin de séjour"
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-full"
              value={departureDate}
              onChange={(departureDate) => setDepartureDate(departureDate)}
            />
          )}
        /> */}
      </div>
    </div>
  );
};
