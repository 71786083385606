/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubStaffMemberRole,
  DiveSessionStaffMemberTableModelStaffMember,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { StaffRoleBadge } from './StaffRoleBadge';
import { DiveSessionStaffRoleModel } from './_model';
import { useDiveSessionStaffMemberRoles } from './useDiveSessionStaffMemberRoles.hook';

export function DiveSessionStaffMembersTableRowStaffRoles({
  isMultiSessionConfigForStaff,
  staffMemberFull,
  availableRoles,
  onClick,
  className = '',
}: {
  isMultiSessionConfigForStaff: boolean;
  staffMemberFull: DiveSessionStaffMemberTableModelStaffMember;
  availableRoles: ClubStaffMemberRole[];
  onClick?: ({ role }: { role: DiveSessionStaffRoleModel }) => any;
  className?: string;
}) {
  const roles: DiveSessionStaffRoleModel[] = useDiveSessionStaffMemberRoles({
    availableRoles,
    isMultiSessionConfigForStaff,
    staffMemberFull,
  });

  return (
    <div
      className={clsx('flex gap-1', className)}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
        }
      }}
    >
      {roles.map((role, i) => (
        <StaffRoleBadge
          key={i}
          className="px-2 text-xs "
          role={role}
          onClick={
            onClick
              ? (e) =>
                  onClick({
                    role,
                  })
              : undefined
          }
        />
      ))}
    </div>
  );
}
