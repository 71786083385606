import { UseQueryResult } from '@tanstack/react-query';
import { LoadableContent } from 'src/business/_core/data/app-loading';

export const reactQueryToLoadableConverter = {
  convertReactQueryToLoadableContent,
};

function convertReactQueryToLoadableContent<T>({
  query,
  startDate,
}: {
  query: UseQueryResult<T, unknown>;
  startDate: Date;
}): LoadableContent<T> {
  const loadableContent: LoadableContent<T> = {
    content: query.data,
    contentState: query.isFetched
      ? 'full'
      : !!query.data && query.data !== ({} as unknown as T)
      ? 'full'
      : 'none',
    lastActionStatus: query.isError
      ? 'error'
      : query.isFetched
      ? 'success'
      : 'in-progress',
    startDate,
    endDate: new Date(query.dataUpdatedAt),
    durationInMs: new Date(query.dataUpdatedAt).getTime() - startDate.getTime(),
    refetch: query.refetch,
  };
  return loadableContent;
}
