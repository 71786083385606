import { ClubResume } from '@mabadive/app-common-model';
import { loadableAttributeStoreFactory } from '@mabadive/app-common-services';
import { getBaseStore } from 'src/business/_core/store/baseStore';

const currentClubResume = loadableAttributeStoreFactory.create<ClubResume>(
  getBaseStore(),
  'c_club_resume',
);

export const currentClubResumeStore = {
  currentClubResume,
  setInitialData,
  resetStore,
  updateStore,
};

function setInitialData({ clubResume }: { clubResume: ClubResume }) {
  currentClubResume.set(clubResume);
}

function resetStore() {
  currentClubResume.unload();
}

function updateStore({ clubResume }: { clubResume: ClubResume }) {
  currentClubResume.set(clubResume);
}
