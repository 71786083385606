import { combineLatest } from 'rxjs';
import { rxjsFormBuilder, rxjsFormEmailValidator, rxjsFormTextLengthValidator } from 'src/lib/form/components/rxjs-form';
import { CreateClubAccountFormComponentsModel } from './CreateClubAccountFormComponentsModel.type';
import { CreateClubAccountFormModel } from './CreateClubAccountFormModel.type';

export const createClubAccountRepository = {
  loadInitialData,
}

function loadInitialData() {

  const { components$: createClubAccountComponents$, form$: createClubAccountForm$ } = buildForm();

  return combineLatest([
    createClubAccountForm$,
    createClubAccountComponents$
  ], (createClubAccountForm, createClubAccountComponents) => ({ createClubAccountForm, createClubAccountComponents }));

}

function buildForm() {

  return rxjsFormBuilder.buildForm<CreateClubAccountFormModel, CreateClubAccountFormComponentsModel>([{
    name: 'clubName',
    required: true,
  }, {
    name: 'clubCity',
    required: true,
  }, {
    name: 'userAuthEmailLogin',
    required: true,
    validators: {
      'email': rxjsFormEmailValidator,
    },
  }, {
    name: 'userAuthPassword',
    required: true,
    validators: {
      'minLength': rxjsFormTextLengthValidator(8),
    },
  }]);
}
