import { Booking } from '@mabadive/app-common-model';

export const bookingResumeToBookingConvertor = {
  convert,
};

function convert(
  bookingResume: Pick<
    Booking,
    | '_id'
    | 'diveCenterId'
    | 'bookingDate'
    | 'bookingLastUpdateDate'
    | 'active'
    | 'bookingStatus'
    | 'bookingContactDiverId'
    | 'bookingJourney'
    | 'bookingInitialPlan'
    | 'bookingCustomerConfig'
    | 'bookingCustomerSpacePrivate'
    | 'bookingGroup'
    | 'details'
    | 'isSharedBooking'
  >,
  {
    clubReference,
  }: {
    clubReference: string;
  },
): Booking {
  const booking: Booking = {
    _id: bookingResume._id,
    diveCenterId: bookingResume.diveCenterId,
    clubReference: clubReference,
    bookingDate: bookingResume.bookingDate,
    bookingLastUpdateDate: bookingResume.bookingLastUpdateDate,
    active: bookingResume.active,
    bookingContactDiverId: bookingResume.bookingContactDiverId,
    bookingStatus: bookingResume.bookingStatus,
    bookingJourney: bookingResume.bookingJourney,
    bookingInitialPlan: bookingResume.bookingInitialPlan,
    bookingCustomerConfig: bookingResume.bookingCustomerConfig,
    bookingCustomerSpacePrivate: bookingResume.bookingCustomerSpacePrivate,
    bookingGroup: bookingResume.bookingGroup,
    details: bookingResume.details,
    isSharedBooking: bookingResume.isSharedBooking,
  };

  return booking;
}
