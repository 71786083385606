import {
  ClubSettingsOnlineBookingProductsPageMode,
  ClubSettingsOnlineBookingProductsPageViewState,
} from '../_model';

export type ClubSettingsOnlineBookingProductsPageUrlParameters = Pick<
  ClubSettingsOnlineBookingProductsPageViewState,
  'mode' | 'categoryId' | 'productId'
>;

export const clubSettingsOnlineBookingProductsPageUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const QUERY_PARAMS = {
  mode: 'm',
  categoryId: 'c',
  productId: 'p',
};

function getQueryParameters(): ClubSettingsOnlineBookingProductsPageUrlParameters {
  const params = new URLSearchParams(window.location.search);

  const mode = params.get(
    QUERY_PARAMS.mode,
  ) as ClubSettingsOnlineBookingProductsPageMode;

  const categoryId = params.get(QUERY_PARAMS.categoryId);
  const productId = params.get(QUERY_PARAMS.productId);

  const config: ClubSettingsOnlineBookingProductsPageUrlParameters = {
    mode,
    categoryId,
    productId,
  };
  return config;
}

function buildSearchParams(
  viewConfig: Partial<ClubSettingsOnlineBookingProductsPageUrlParameters>,
): string[] {
  if (!viewConfig) {
    return [];
  }
  const { mode, categoryId, productId } = viewConfig;

  const searchParams: string[] = [];

  if (mode) {
    searchParams.push(`${QUERY_PARAMS.mode}=${mode}`);
  }
  if (categoryId) {
    searchParams.push(`${QUERY_PARAMS.categoryId}=${categoryId}`);
  }
  if (productId) {
    searchParams.push(`${QUERY_PARAMS.productId}=${productId}`);
  }

  return searchParams;
}
