/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubDiveSiteGroup,
  ClubResumeDiveSite,
} from '@mabadive/app-common-model';
import { dataSorter, search } from '@mabadive/app-common-services';
import React, { useMemo, useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppActionButton,
  AppBreadcrumbBar,
  AppPageContainer,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { AppInputBoolean } from 'src/lib/form';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import useRouter from 'use-react-router';
import { clubSettingsDiveCenterUrlBuilder } from '../../clubSettingsDiveCenterUrlBuilder.service';
import { DiveSitesListPageGroup } from './DiveSitesListPageGroup';

export const DiveSitesListPage = (props: {}) => {
  const { match } = useRouter();
  const autoFocus = useAutoFocus();

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const [searchText, setSearchText] = useState('');

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const diveSitesAll = useMemo(() => {
    return search.filter(diveCenterResume?.diveSites, {
      attributesNames: ['name', 'ref', 'description'],
      searchText,
      sortResultsByBestMatch: false,
    });
  }, [diveCenterResume?.diveSites, searchText]);

  const hasAnyArchivedSite = useMemo(
    () => diveSitesAll.findIndex((x) => !x.enabled) !== -1,
    [diveSitesAll],
  );
  const [displayArchivedSites, setDisplayArchivedSites] = useState(false);

  const diveSites = useMemo(
    () =>
      displayArchivedSites
        ? diveSitesAll
        : diveSitesAll.filter((x) => x.enabled),
    [displayArchivedSites, diveSitesAll],
  );

  const diveSitesByGroup = useMemo(() => {
    const filtered = displayArchivedSites
      ? diveCenterResume?.diveSiteGroups
      : diveCenterResume?.diveSiteGroups.filter((x) => x.enabled);
    const diveSiteGroups = dataSorter.sortMultiple(filtered, {
      getSortAttributes: (g) => [
        {
          value: g.name,
        },
        {
          value: g._id,
        },
      ],
      asc: true,
    });

    const diveSitesByGroup: {
      diveSiteGroup: ClubDiveSiteGroup;
      diveSites: ClubResumeDiveSite[];
    }[] = diveSiteGroups.map((diveSiteGroup) => ({
      diveSiteGroup,
      diveSites: [],
    }));

    return diveSites.reduce((acc, diveSite) => {
      const g = acc.find((x) => x.diveSiteGroup._id === diveSite.groupId);
      g.diveSites.push(diveSite);
      return acc;
    }, diveSitesByGroup);
  }, [diveCenterResume?.diveSiteGroups, displayArchivedSites, diveSites]);

  const hamburgerMenuItems = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    hamburgerMenuItems.push({
      id: 'create-group',
      title: 'Nouveau groupe',
      description: 'Ajouter un groupe',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        redirectTo(clubSettingsDiveCenterUrlBuilder.diveSitesGroups.create());
      },
    });
    hamburgerMenuItems.push({
      id: 'create-site',
      title: 'Nouveau site',
      description: 'Ajouter un site',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        redirectTo(clubSettingsDiveCenterUrlBuilder.diveSites.create());
      },
    });
    return hamburgerMenuItems;
  }, [redirectTo]);

  const quickAction: AppHamburgerMenuItem = useMemo(() => {
    const action = hamburgerMenuItems.find((x) => x.id === 'create-group');
    if (action && !action.hidden) {
      return action;
    }
  }, [hamburgerMenuItems]);

  return (
    <AppPageContainer className={'bg-gray-50'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.diveCenter.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.diveCenter.main,
            label: 'Centre de plongée',
            url: '/club/settings',
          },
          {
            label: 'Sites de plongée',
          },
        ]}
      >
        {isMultiDiveCenters && (
          <div className="app-px-content">
            <DiveCenterProBadge diveCenter={diveCenterResume} />
          </div>
        )}
      </AppBreadcrumbBar>

      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <div className="app-card p-4 flex flex-col xs:flex-row items-end gap-2">
          <input
            autoFocus={autoFocus}
            autoComplete="new-password"
            placeholder="Recherche"
            type="text"
            className="flex-grow w-max hover:border-app-blue rounded px-2"
            defaultValue={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          {hasAnyArchivedSite && (
            <div
              className={`flex gap-2 font-bold cursor-pointer ${
                displayArchivedSites ? 'text-gray-600' : 'text-gray-400'
              }`}
              onClick={() => setDisplayArchivedSites(!displayArchivedSites)}
            >
              <AppInputBoolean
                className="scale-125 block ml-0.5 mt-1 mb-1"
                type="checkbox"
                title="Afficher les sites archivés"
                value={displayArchivedSites}
              />
              Afficher les sites archivés
            </div>
          )}
        </div>
        {diveSitesByGroup.map(({ diveSiteGroup, diveSites }) => (
          <DiveSitesListPageGroup
            className="app-my-content app-card p-4 "
            key={diveSiteGroup._id}
            diveSiteGroup={diveSiteGroup}
            diveSites={diveSites}
          />
        ))}
        {quickAction && (
          <div>
            <AppActionButton className="w-full my-2" action={quickAction} />
          </div>
        )}
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
