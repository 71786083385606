/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import { diveModeFormatter } from '@mabadive/app-common-services';
import { AppInputBooleanSwitchRHF, AppInputRHF } from 'src/lib/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { AppMessageLight } from '../../../../../../../../business/_core/modules/layout';
import { AppInputDatePickerMaterialRHF } from '../../../../../../../../stories/components/04-form';
import {
  ClubSettingsOnlineBookingDialogInitialState,
  ClubSettingsOnlineBookingFormModel,
} from '../../../_model';

export const ClubSettingsOnlineBookingMainForm_SessionConfig = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsOnlineBookingFormModel>;
  initialState: ClubSettingsOnlineBookingDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const [
    sessionConfigEnabled,
    sessionConfig,
    firstDiveEnabled,
    snorkelingSupervisedEnabled,
    firstDiveMinDaysBefore,
    snorkelingSupervisedMinDaysBefore,
  ] = useWatch({
    control,
    name: [
      'clubSettings.general.onlineBooking.sessionConfig.enabled',
      'clubSettings.general.onlineBooking.sessionConfig',
      'clubSettings.general.onlineBooking.sessionConfig.firstDive.enabled',
      'clubSettings.general.onlineBooking.sessionConfig.snorkelingSupervised.enabled',
      'clubSettings.general.onlineBooking.sessionConfig.firstDive.minDaysBefore',
      'clubSettings.general.onlineBooking.sessionConfig.snorkelingSupervised.minDaysBefore',
    ],
  });

  const onEnableSessionConfig = useCallback(() => {
    if (!sessionConfig) {
      setValue('clubSettings.general.onlineBooking.sessionConfig.firstDive', {
        enabled: true,
        defaultMaxParticipants: 4,
        minDaysBefore: 1,
        maxDaysBefore: 365,
      });
      setValue(
        'clubSettings.general.onlineBooking.sessionConfig.snorkelingSupervised',
        {
          enabled: false,
          defaultMaxParticipants: 8,
          minDaysBefore: 1,
          maxDaysBefore: 365,
        },
      );
    }
  }, [sessionConfig, setValue]);

  return (
    <ClubSettingsSection
      title="Réservation automatique des sessions"
      comment="Permettez à vos clients de réserver directement certaines prestations sur le planning"
    >
      <div className="grid gap-x-4 gap-y-4">
        <AppFormControlRHF
          className=""
          label={'Réservation automatique des sessions'}
          disabled={!isSuperAdmin}
          control={control}
          name={'clubSettings.general.onlineBooking.sessionConfig.enabled'}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              label={'Activé'}
              theme="success"
              onChange={(enabled) => {
                if (enabled) {
                  onEnableSessionConfig();
                } else {
                  setValue(
                    'clubSettings.general.onlineBooking.sessionConfig',
                    undefined,
                  );
                }
              }}
            />
          )}
        />
        {sessionConfigEnabled && (
          <>
            <div className="grid gap-x-4 gap-y-4 overflow-hidden">
              <div className="flex gap-4 flex-wrap">
                <AppFormControlRHF
                  className="w-32"
                  label={'Baptêmes'}
                  disabled={!isSuperAdmin}
                  control={control}
                  name={
                    'clubSettings.general.onlineBooking.sessionConfig.firstDive.enabled'
                  }
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF
                      {...props}
                      label={'Activé'}
                      onChange={(enabled) => {
                        if (enabled) {
                          onEnableSessionConfig();
                        }
                      }}
                    />
                  )}
                />
                {firstDiveEnabled && (
                  <>
                    <AppFormControlRHF
                      className="w-32"
                      label={'Nb de places'}
                      helpDescription="Vous pourrez changer cette valeur dans la configuration du planning."
                      name={
                        'clubSettings.general.onlineBooking.sessionConfig.firstDive.defaultMaxParticipants'
                      }
                      validation={{
                        rules: {
                          min: 0,
                        },
                      }}
                      control={control}
                      renderComponent={(props) => (
                        <AppInputRHF {...props} type="number" />
                      )}
                    />
                    <AppFormControlRHF
                      label={'Tous participants?'}
                      helpDescription={
                        'Nombre de places partagé avec tous les participants'
                      }
                      control={control}
                      required={false}
                      name={
                        'clubSettings.general.onlineBooking.sessionConfig.firstDive.includeOtherParticipantsDiveModes'
                      }
                      renderComponent={(props) => (
                        <AppInputBooleanSwitchRHF {...props} />
                      )}
                    />
                    <div className="grid grid-cols-2 md:flex gap-4">
                      <AppFormControlRHF
                        className="w-32"
                        label={'Délai MIN'}
                        helpDescription={
                          'Résa possible MIN jours avant la sortie'
                        }
                        control={control}
                        required={true}
                        validation={{
                          rules: {
                            min: 0,
                          },
                        }}
                        name={
                          'clubSettings.general.onlineBooking.sessionConfig.firstDive.minDaysBefore'
                        }
                        renderComponent={(props) => (
                          <AppInputRHF {...props} fullWidth type="number" />
                        )}
                      />
                      <AppFormControlRHF
                        className="w-32"
                        label={'Délai MAX'}
                        helpDescription={
                          'Résa possible MAX jours avant la sortie'
                        }
                        control={control}
                        required={true}
                        validation={{
                          rules: {
                            min: firstDiveMinDaysBefore,
                          },
                        }}
                        name={
                          'clubSettings.general.onlineBooking.sessionConfig.firstDive.maxDaysBefore'
                        }
                        renderComponent={(props) => (
                          <AppInputRHF {...props} fullWidth type="number" />
                        )}
                      />
                    </div>
                    <div className="grid grid-cols-2 md:flex gap-4">
                      <AppFormControlRHF
                        className="w-48"
                        label={'Début des activités'}
                        helpDescription={
                          'Date de début de la période réservable'
                        }
                        control={control}
                        required={false}
                        name={
                          'clubSettings.general.onlineBooking.sessionConfig.firstDive.minDate'
                        }
                        renderComponent={(props) => (
                          <AppInputDatePickerMaterialRHF
                            className="w-full"
                            {...props}
                          />
                        )}
                      />
                      <AppFormControlRHF
                        className="w-48"
                        label={'Fin des activités'}
                        helpDescription={'Date de fin de la période réservable'}
                        control={control}
                        required={false}
                        name={
                          'clubSettings.general.onlineBooking.sessionConfig.firstDive.maxDate'
                        }
                        renderComponent={(props) => (
                          <AppInputDatePickerMaterialRHF
                            className="w-full"
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="flex gap-4 flex-wrap">
                <AppFormControlRHF
                  className="w-32"
                  label={diveModeFormatter.formatDiveMode(
                    'snorkelingSupervised',
                    {
                      format: 'long-label',
                      diveModesConf: clubSettings?.ui?.diveMode,
                    },
                  )}
                  disabled={!isSuperAdmin}
                  control={control}
                  name={
                    'clubSettings.general.onlineBooking.sessionConfig.snorkelingSupervised.enabled'
                  }
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF
                      {...props}
                      label={'Activé'}
                      onChange={(enabled) => {
                        if (enabled) {
                          onEnableSessionConfig();
                        }
                      }}
                    />
                  )}
                />
                {snorkelingSupervisedEnabled && (
                  <>
                    <AppFormControlRHF
                      className="w-32"
                      label={'Nb de places'}
                      helpDescription="Vous pourrez changer cette valeur dans la configuration du planning."
                      name={
                        'clubSettings.general.onlineBooking.sessionConfig.snorkelingSupervised.defaultMaxParticipants'
                      }
                      validation={{
                        rules: {
                          min: 0,
                        },
                      }}
                      control={control}
                      renderComponent={(props) => (
                        <AppInputRHF {...props} type="number" />
                      )}
                    />
                    <AppFormControlRHF
                      label={'Tous participants?'}
                      helpDescription={
                        'Nombre de places partagé avec tous les participants'
                      }
                      control={control}
                      required={false}
                      name={
                        'clubSettings.general.onlineBooking.sessionConfig.snorkelingSupervised.includeOtherParticipantsDiveModes'
                      }
                      renderComponent={(props) => (
                        <AppInputBooleanSwitchRHF {...props} />
                      )}
                    />
                    <div className="grid grid-cols-2 md:flex gap-4">
                      <AppFormControlRHF
                        className="w-32"
                        label={'Délai MIN'}
                        helpDescription={
                          'Résa possible MIN jours avant la sortie'
                        }
                        control={control}
                        required={true}
                        validation={{
                          rules: {
                            min: 0,
                          },
                        }}
                        name={
                          'clubSettings.general.onlineBooking.sessionConfig.snorkelingSupervised.minDaysBefore'
                        }
                        renderComponent={(props) => (
                          <AppInputRHF {...props} fullWidth type="number" />
                        )}
                      />
                      <AppFormControlRHF
                        className="w-32"
                        label={'Délai MAX'}
                        helpDescription={
                          'Résa possible MAX jours avant la sortie'
                        }
                        control={control}
                        required={true}
                        validation={{
                          rules: {
                            min: snorkelingSupervisedMinDaysBefore,
                          },
                        }}
                        name={
                          'clubSettings.general.onlineBooking.sessionConfig.snorkelingSupervised.maxDaysBefore'
                        }
                        renderComponent={(props) => (
                          <AppInputRHF {...props} fullWidth type="number" />
                        )}
                      />
                    </div>
                    <div className="grid grid-cols-2 md:flex gap-4">
                      <AppFormControlRHF
                        className="w-48"
                        label={'Début des activités'}
                        helpDescription={
                          'Date de début de la période réservable'
                        }
                        control={control}
                        required={false}
                        name={
                          'clubSettings.general.onlineBooking.sessionConfig.snorkelingSupervised.minDate'
                        }
                        renderComponent={(props) => (
                          <AppInputDatePickerMaterialRHF
                            className="w-full"
                            {...props}
                          />
                        )}
                      />
                      <AppFormControlRHF
                        className="w-48"
                        label={'Fin des activités'}
                        helpDescription={'Date de fin de la période réservable'}
                        control={control}
                        required={false}
                        name={
                          'clubSettings.general.onlineBooking.sessionConfig.snorkelingSupervised.maxDate'
                        }
                        renderComponent={(props) => (
                          <AppInputDatePickerMaterialRHF
                            className="w-full"
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </>
                )}
              </div>

              <AppMessageLight>
                Rendez-vous ensuite sur la configuration du planning pour
                définir les sorties avec réservation en ligne.
              </AppMessageLight>
            </div>
          </>
        )}
      </div>
    </ClubSettingsSection>
  );
};
