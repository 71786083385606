import {
  AppCompanyMessageContext,
  AppCompanyMessageTemplate,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { MessageTemplateLabel } from './MessageTemplateLabel';

export function useAppCompanyMessageTemplatesOptions({
  messageContext,
  scope = 'visible',
}: {
  messageContext: AppCompanyMessageContext;
  scope?: 'visible' | 'all';
}): {
  messageTemplatesOptions: ValueLabel<string, React.ReactNode>[];
  messageTemplates: AppCompanyMessageTemplate[];
} {
  const clubResume = useClubResume();

  const messageTemplates = useMemo(() => {
    const filteredMessageTemplates = (
      clubResume?.messageTemplates ?? []
    ).filter(
      (x) =>
        x.context === messageContext &&
        (x.enabled || scope === 'all') &&
        !x.archived &&
        (x.emailContent?.enabled ||
          x.smsContent?.enabled ||
          x.whatsAppContent?.enabled),
    );

    return dataSorter.sortMultiple(filteredMessageTemplates, {
      getSortAttributes: (mt) => {
        return [
          {
            value: mt.name,
            asc: true,
          },
          {
            value: mt.style === 'success' ? 1 : mt.style === 'warning' ? 2 : 3,
            asc: true,
          },
          {
            value: mt._createdAt,
            asc: true,
          },
        ];
      },
    });
  }, [clubResume?.messageTemplates, messageContext, scope]);

  const messageTemplatesOptions = useMemo(
    () =>
      messageTemplates.map((mt: AppCompanyMessageTemplate) => {
        const Icon =
          mt.style === 'success'
            ? AppHeroIcons.actionConfirm
            : mt.style === 'warning'
            ? AppHeroIcons.warn
            : AppHeroIcons.actionCancel;

        const option: ValueLabel<string, React.ReactNode> = {
          value: mt._id,
          label: MessageTemplateLabel({ messageTemplate: mt }),
        };
        return option;
      }),
    [messageTemplates],
  );
  return {
    messageTemplates,
    messageTemplatesOptions,
  };
}
