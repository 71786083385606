import { APP_COLORS } from '@mabadive/app-common-model';
import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { DiveCenterSelect } from 'src/pages/_components/company';
import useRouter from 'use-react-router';
import { appStylesHelper, useCacheableClasses } from '../../../root/theme';
import { AppIconsMaterial } from '../../icons';
import { responsiveAttributesValues } from '../../services';
import { SideMenuEntry } from './SideMenuEntry.model';
import { SideMenuExtraButton } from './SideMenuExtraButton.model';
import { useSideMenu } from './useSideMenu.hook';
export const SideMenu = function SideMenuMemo({
  entries,
  extraButtons,
}: {
  entries: SideMenuEntry[];
  extraButtons?: SideMenuExtraButton[];
}) {
  const { location } = useRouter();

  const classes = useCacheableClasses({
    cacheKey: 'SideMenu',
    buildStyles,
  });

  const {
    isMenuOpen,
    isMenuDisabled,
    isMenuTemporary,
    isMenuCollapsed,
    closeMenu,
    setCollapsed,
  } = useSideMenu();

  const az = useAppSecurityUserClubAuthorizations();

  return (
    <Drawer
      className={`${classes.root} collapsed-${isMenuCollapsed}`}
      variant={isMenuTemporary ? 'temporary' : 'permanent'}
      open={isMenuTemporary ? isMenuOpen : false}
      anchor="left"
      onClose={() => closeMenu()}
      onClick={() => closeMenu()}
      classes={{
        paper: `${classes.drawerPaper} collapsed-${isMenuCollapsed}`,
      }}
    >
      {/* {!isMenuTemporary ? (
        !isMenuCollapsed ? (
          <Button
            startIcon={<AppIconsMaterial.collapseLeft />}
            className="collapse-button"
            onClick={() => setCollapsed(true)}
          >
            &nbsp;
          </Button>
        ) : (
          <Button
            startIcon={<AppIconsMaterial.collapseRight />}
            className="collapse-button"
            onClick={() => setCollapsed(false)}
          >
            &nbsp;
          </Button>
        )
      ) : null} */}
      <div
        className="h-10 flex justify-center items-center"
        onClick={(e) => e.stopPropagation()}
      >
        {az?.multiDiveCenters?.switchCenter && (
          <DiveCenterSelect
            className="w-full mx-2"
            onSelect={() => closeMenu()}
          />
        )}
      </div>
      {entries.length !== 0 && !isMenuDisabled ? (
        <List>
          {entries.map((entry, i) => {
            const notificationCount = entry.notificationCount ?? 0;

            return (
              <Link className={classes.link} to={entry.link} key={i}>
                <ListItem
                  color="secondary"
                  button
                  key="planning"
                  selected={
                    entry.exact
                      ? location.pathname === entry.link
                      : location.pathname.startsWith(entry.link)
                  }
                >
                  <div className="flex items-center">
                    {notificationCount > 0 ? (
                      <span
                        className={clsx(
                          'w-6 h-6 mr-2.5 text-center text-white rounded-full',
                          'flex justify-center items-center  overflow-hidden font-bold',
                          entry.notificationStatus === 'info'
                            ? 'bg-status-info'
                            : entry.notificationStatus === 'warn'
                            ? 'bg-status-warn'
                            : entry.notificationStatus === 'danger'
                            ? 'bg-status-error'
                            : '',
                          notificationCount < 10
                            ? 'text-md'
                            : notificationCount < 100
                            ? 'text-sm'
                            : 'text-xs',
                        )}
                      >
                        {notificationCount}
                      </span>
                    ) : entry.icon ? (
                      <ListItemIcon className={classes.linkIcon}>
                        {React.createElement(entry.icon)}
                      </ListItemIcon>
                    ) : entry.heroIcon ? (
                      <ListItemIcon
                        className={`
                      ${classes.linkIcon ?? ''}
                      
                  `}
                      >
                        {React.createElement(entry.heroIcon as unknown as any, {
                          className: clsx(
                            'w-6 h-6',
                            entry.notificationStatus === 'info'
                              ? 'bg-status-info'
                              : entry.notificationStatus === 'warn'
                              ? 'text-status-warn bg-white'
                              : entry.notificationStatus === 'danger'
                              ? 'text-status-error bg-white'
                              : '',
                          ),
                        })}
                      </ListItemIcon>
                    ) : null}
                    {!isMenuCollapsed ? (
                      <ListItemText
                        className={classes.linkText}
                        primary={entry.label}
                      />
                    ) : null}
                  </div>
                </ListItem>
              </Link>
            );
          })}
        </List>
      ) : null}
      {extraButtons && extraButtons.length ? (
        <>
          <Divider />
          <div>
            {extraButtons.map((extraButton, i) => (
              <Button
                key={i}
                startIcon={<extraButton.icon />}
                onClick={() => extraButton.onClick()}
              >
                {!isMenuCollapsed ? (
                  <ListItemText
                    className={classes.linkText}
                    primary={extraButton.label}
                  />
                ) : (
                  <>&nbsp;</>
                )}
              </Button>
            ))}
          </div>
        </>
      ) : null}

      <div className={classes.grow} />
      <Button
        startIcon={<AppIconsMaterial.refresh />}
        className="refresh-button"
        tabIndex={500}
        onClick={() => refreshPage()}
      >
        {!isMenuCollapsed ? (
          <span className="text-xs uppercase">Rafraîchir</span>
        ) : (
          <>&nbsp;</>
        )}
      </Button>
    </Drawer>
  );
};

function refreshPage() {
  window.location.reload();
}

function buildStyles(theme: Theme) {
  return makeStyles({
    grow: {
      flexGrow: 1,
    },
    root: {
      '& .collapse-button.MuiButton-root': {
        color: 'white',
        // marginTop: 50,
        marginBottom: 5,
      },
      '& .refresh-button.MuiButton-root': {
        color: 'white',
        marginBottom: 35, // sur android, il faut laisser la place pour la barre de navigation d'android
      },
      '&.collapsed-false': {
        ...responsiveAttributesValues(theme, {
          width: appStylesHelper.getSideMenuWidthResponsive(),
        }),
      },
      '&.collapsed-true': {
        width: appStylesHelper.getSideMenuCollapsedWidth(),
      },
      flexShrink: 0,
    },
    drawerPaper: {
      '&.collapsed-false': {
        ...responsiveAttributesValues(theme, {
          width: appStylesHelper.getSideMenuWidthResponsive(),
        }),
      },
      '&.collapsed-true': {
        width: appStylesHelper.getSideMenuCollapsedWidth(),
      },
      zIndex: theme.zIndex.drawer,
      background: APP_COLORS.menuSide,
    },
    link: {
      color: '#ddd',
      textDecoration: 'none',
      '& .Mui-selected': {
        backgroundColor: '#004562',
      },
      '& .Mui-selected .MuiSvgIcon-root, & .Mui-selected .MuiListItemText-root':
        {
          color: '#eee',
        },
    },
    linkIcon: {
      color: '#ccc',
      '&.MuiListItemIcon-root': {
        minWidth: '35px',
      },
      // '.MuiTypography-root': {
      //   fontWeight: 'bold',
      // },
    },
    linkText: {
      color: '#ccc',
      fontWeight: 'bold',
      '& .MuiTypography-root': {
        fontWeight: 'bold',
      },
    },
  });
}
