import {
  DiveSession,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import { clubDiveSessionThemeBuilder } from '@mabadive/app-common-services';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { DiverBillingSessionsTableDayHeader } from './DiverBillingSessionsTableDayHeader';
import { DiverBillingSessionsTableSessionHeaderTime } from './DiverBillingSessionsTableSessionHeaderTime';

export function DiverBillingSessionsTableSessionHeader({
  diveSession,
  onClick,
  style,
  theme,
  className,
}: {
  diveSession: DiveSession;
  onClick?: () => void;
  style?: CSSProperties;
  theme: 'light' | 'normal';
  className?: string;
}) {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(diveSession.time),
    [diveSession.time],
  );

  const diveSessionDiveCenter = useMemo(
    () =>
      clubResume.diveCenters.find((dc) => dc._id === diveSession.diveCenterId),
    [clubResume.diveCenters, diveSession.diveCenterId],
  );

  return (
    <div
      className={`font-medium uppercase align-top tracking-wider
      ring-opacity-30 ring-day-period-${timeDayPeriod}-dark 
       ${theme === 'light' ? 'text-gray-400' : 'text-gray-500'} 
       ${onClick ? 'group rounded-t-lg hover:ring-2 cursor-pointer' : ''} 
       ${diveSession.status === 'cancelled' ? 'line-through' : ''}
       ${className ?? ''}`}
      style={style as unknown as React.CSSProperties}
      onClick={onClick}
    >
      <DiverBillingSessionsTableDayHeader date={diveSession.time} />
      {isMultiDiveCenters && (
        <DiveCenterProBadge
          className="my-px"
          diveCenter={diveSessionDiveCenter}
        />
      )}
      <div className="flex gap-1">
        <DiverBillingSessionsTableSessionHeaderTime
          theme={theme}
          className="flex-grow text-center text-sm"
          special={diveSession.special}
          time={diveSession.time}
          diveSiteId={diveSession.diveSiteId}
          isMultiSession={!!diveSession.diveTourSession2}
        />
        {diveSession.diveTourSession2 && (
          <DiverBillingSessionsTableSessionHeaderTime
            theme={theme}
            className="flex-grow text-center text-sm"
            special={diveSession.special}
            time={diveSession.diveTourSession2.time}
            diveSiteId={diveSession.diveTourSession2.diveSiteId}
            isMultiSession={!!diveSession.diveTourSession2}
          />
        )}
      </div>

      {/* <DiveSessionSimpleCardHeader
        className="py-1 h-12 text-app-xxs leading-4"
        session={diveSession}
        display={{
          boats: false,
          truncateText: true,
        }}
      /> */}
    </div>
  );
}
