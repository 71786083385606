// https://heroicons.com/ ?

import {
  AdjustmentsHorizontalIcon,
  ArrowLongRightIcon,
  ArrowRightOnRectangleIcon,
  ArrowsPointingOutIcon,
  ArrowsRightLeftIcon,
  ArrowTopRightOnSquareIcon,
  Bars3Icon,
  BoltIcon,
  BookmarkIcon,
  BuildingLibraryIcon,
  CalendarIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckBadgeIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  ClipboardDocumentIcon,
  ClipboardIcon,
  CogIcon,
  CreditCardIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EyeSlashIcon,
  FolderOpenIcon,
  IdentificationIcon,
  LinkIcon,
  LockClosedIcon,
  NoSymbolIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  PlusIcon,
  PrinterIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  Square2StackIcon,
  Squares2X2Icon,
  SunIcon,
  TableCellsIcon,
  TrashIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  ViewColumnsIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ArchiveBoxIcon,
  ArrowDownTrayIcon,
  BriefcaseIcon,
  ChartBarSquareIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckIcon as CheckIconSolid,
  CubeIcon,
  EnvelopeIcon,
  EyeIcon,
  HomeIcon,
  MapPinIcon,
  MinusCircleIcon as MinusCircleIconSolid,
  PhoneIcon,
  PlusCircleIcon as PlusCircleIconSolid,
  QuestionMarkCircleIcon,
  StarIcon,
  UserPlusIcon,
  XCircleIcon as XCircleIconSolid,
} from '@heroicons/react/24/solid';

import { FaPlane } from 'react-icons/fa';
import { IoMdBoat } from 'react-icons/io';
import { MdCake, MdNotInterested } from 'react-icons/md';
import { RiInformationFill } from 'react-icons/ri';
export const AppHeroIcons = {
  // @deprecated use AppIconsAction
  staffRole: BriefcaseIcon,
  admin: BuildingLibraryIcon,
  actionAdd: PlusIcon,
  actionAddCircle: PlusCircleIcon,
  actionAddUser: UserPlusIcon,
  actionArchive: ArchiveBoxIcon,
  // @deprecated use AppIconsAction
  actionCancel: XCircleIcon,
  actionCancel2: XCircleIconSolid,
  actionClone: Square2StackIcon,
  actionClose: XMarkIcon,
  actionConfirm: CheckIcon,
  actionDecrement: PlusCircleIconSolid,
  actionDelete: TrashIcon,
  actionDownload: ArrowDownTrayIcon,
  // @deprecated use AppIconsAction
  actionEdit: PencilIcon,
  actionEditAlt: PencilSquareIcon,
  actionEditBulk: TableCellsIcon,
  actionExpand: ArrowsPointingOutIcon,
  actionExtend: ChevronDownIcon,
  actionExtract: ArrowRightOnRectangleIcon,
  actionImpute: CheckBadgeIcon,
  actionIncrement: MinusCircleIconSolid,
  actionLock: LockClosedIcon,
  actionMenu: Bars3Icon,
  actionMove: ChevronUpDownIcon,
  actionMoveSwitch: ArrowsRightLeftIcon,
  // @deprecated use AppIconsAction
  actionOpen: FolderOpenIcon,
  actionPrint: PrinterIcon,
  actionReduce: ChevronUpIcon,
  actionRemove: TrashIcon,
  actionSave: CheckIcon,
  actionSettings: CogIcon,
  // @deprecated use AppIconsAction
  actionSettingsDisplay: AdjustmentsHorizontalIcon,
  active: BoltIcon,
  autoFill: BoltIcon,
  badgeCheck: CheckBadgeIcon,
  billing: CreditCardIcon,
  birthday: MdCake,
  boat: IoMdBoat,
  brand: IdentificationIcon,
  calendar: CalendarIcon,
  check: CheckIcon,
  help: QuestionMarkCircleIcon,
  checkSolid: CheckIconSolid,
  comment: ChatBubbleBottomCenterTextIcon,
  contact: EnvelopeIcon,
  copyClipboard: ClipboardDocumentIcon,
  cross: XMarkIcon,
  disabled: NoSymbolIcon,
  displayModeIcon: Squares2X2Icon,
  diveSite: MapPinIcon,
  error: ExclamationTriangleIcon,
  eye: EyeIcon,
  eyeOff: EyeSlashIcon,
  forbidden: MdNotInterested, // NOTE: après la montée en version, on pourra utiliser NoSymbolIcon
  group: UserGroupIcon,
  userCircle: UserCircleIcon,
  history: ClipboardIcon,
  home: HomeIcon,
  info: RiInformationFill,
  infoArrow: ArrowLongRightIcon,
  link: LinkIcon,
  linkExternal: ArrowTopRightOnSquareIcon,
  mabadiveOffer: BookmarkIcon,
  mail: EnvelopeIcon,
  menu: Bars3Icon,
  meteo: SunIcon,
  offerOther: CubeIcon,
  offerProducts: ShoppingBagIcon,
  phone: PhoneIcon,
  planning: CalendarIcon,
  purchase: TableCellsIcon,
  residentTypeHolidays: FaPlane,
  residentTypeLocal: HomeIcon,
  sms: ChatBubbleLeftEllipsisIcon,
  staff: UsersIcon,
  star: StarIcon,
  stats: ChartBarSquareIcon,
  success: ShieldCheckIcon,
  training: BuildingLibraryIcon,
  viewPeriodIcon: ViewColumnsIcon,
  warn: ExclamationCircleIcon,
};
