/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubEcommerceProductArticlePrice,
  EcommerceProductArticleGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAppTheme, useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { AppPriceLabel } from '../../../../../../business/club/modules/_common/ui';
import { useAppCurrencyMain } from '../../../../../_components/options';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';

export const ProProductsPage_CategoryContentCardProductArticlesTableRowPrice =
  ({
    localState,
    productArticle,
    price,
    className,
  }: {
    localState: ClubSettingsOnlineBookingProductsPageLocalState;
    productArticle: EcommerceProductArticleGql_Company;
    price: ClubEcommerceProductArticlePrice;
    className?: string;
  }) => {
    const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
    const clubSettings = useClubSettings();
    const redirectTo = useRedirect();
    const theme = useAppTheme();
    const mainCurrency = useAppCurrencyMain();

    const generalSettings = clubSettings.general;

    const { state, dialogs, data, actions } = localState;

    return (
      <tr className={clsx('app-tr-highlight', className)}>
        <td></td>
        <th
          colSpan={2}
          className="px-2 text-left font-medium text-gray-500 uppercase"
        >
          {price.label}
        </th>
        <td className="px-2 text-right font-bold text-gray-800">
          <AppPriceLabel amount={price.unitPrice} mainCurrency={mainCurrency} />
        </td>
      </tr>
    );
  };
