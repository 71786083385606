import {
  AppEntityUpdatePatch,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubSettingsServicesDialogProps,
  ClubSettingsServicesFormModel,
} from '../_model';

export function useClubSettingsServicesDialogActionPersist(): ClubSettingsServicesDialogProps {
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;
  const action: ClubSettingsServicesDialogProps = {
    async onConfirm({ formValue }, initialState) {
      const { defaultValue } = initialState;

      const clubSettingsPatch: AppEntityUpdatePatch = buildClubSettingsPatch({
        defaultValue,
        formValue,
      });

      if (clubSettingsPatch) {
        const payload: ProMultiOperationPayload =
          clubMassiveUpdatorClient.createEmptyPayload({
            logContext: 'edit club general settings',
            bookingIdsToClean: [],
          });

        if (clubSettingsPatch) {
          payload.updatedClubSettings = clubSettingsPatch;
        }

        return clubMassiveUpdatorClient.update(payload);
      }
    },
  };
  return action;
}
function buildClubSettingsPatch({
  defaultValue,
  formValue,
}: {
  defaultValue: ClubSettingsServicesFormModel;
  formValue: ClubSettingsServicesFormModel;
}): AppEntityUpdatePatch {
  const initialValue = defaultValue.clubSettings;
  const finalValue = {
    ...formValue.clubSettings,
  };

  const mainCurrencyIsoCode = finalValue.general?.currencyIsoCode;
  const currencies = finalValue.general?.payments?.currencies;

  // update currencyConversionEnabled from config
  finalValue.general.payments.currencyConversionEnabled =
    currencies.length > 1 ||
    (currencies.length === 1 &&
      currencies[0].currencyIsoCode !== mainCurrencyIsoCode);

  const clubSettingsPatchOperations = jsonPatcher.compareObjects(
    initialValue,
    finalValue,
    {
      // else, value won't be deleted by typeorm
      // https://github.com/typeorm/typeorm/issues/2934
      replaceDeleteByNullValue: true,
      attributesToReplaceFully: [
        'firstDive',
        'general',
        'services',
        'publicSettings',
      ],
    },
  );
  if (clubSettingsPatchOperations.length) {
    const patch: AppEntityUpdatePatch = {
      pk: defaultValue.clubSettings._id,
      patchOperations: clubSettingsPatchOperations,
    };
    return patch;
  }
}
