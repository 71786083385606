import { Club, DiveSession } from '@mabadive/app-common-model';
import {
  participantAptitudeBuilder,
  participantTrainingMainLevelFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  ParticipantGaz,
  ParticipantRebreather,
  ParticipantTargetDeep,
  ParticipantUnderwaterScooter,
} from '../../participant';
import { CalendarSessionWidthMode } from './CalendarSessionWidthMode.type';
import {
  CalendarParticipantCellDiver,
  CalendarParticipantCellParticipant,
} from './model';

export function CalendarParticipantCellAptitudeGaz<
  T extends CalendarParticipantCellParticipant,
>({
  club,
  diver,
  participant,
  diveSession,
  isPast,
  widthMode,
  className,
}: {
  club: Pick<Club, 'participantTags' | 'reference'>;
  diver: CalendarParticipantCellDiver;
  participant?: T;
  diveSession: Pick<DiveSession, 'time' | 'status'>;
  isPast?: boolean;
  widthMode: CalendarSessionWidthMode;
  className?: string;
}) {
  if (isPast === undefined) {
    isPast = false;
  }

  const clubReference = club.reference;

  const aptitude = useMemo(
    () =>
      participantAptitudeBuilder.formatAptitude(
        {
          diver,
          participant,
        },
        {
          clubReference,
        },
      ),
    [clubReference, diver, participant],
  );

  const clubSettings = useClubSettings();
  const mainLevelForTraining = useMemo(
    () =>
      participantTrainingMainLevelFormatter.formatMainLevelForTraining({
        diver,
        participant,
        clubSettings,
      }),
    [clubSettings, diver, participant],
  );

  const hasGazInfo =
    participant.gaz?.gazType ||
    participant.gaz?.gazQuantity ||
    participant.gaz?.gazDescription;

  const hasRebreatherInfo = !!participant.equipment?.rebreather;
  const hasUnderwaterScooterInfo = !!participant.equipment?.underwaterScooter;

  return !(
    widthMode === 'large' &&
    (aptitude?.length !== 0 ||
      !!mainLevelForTraining ||
      participant.targetDeep > 0 ||
      hasGazInfo ||
      hasRebreatherInfo ||
      hasUnderwaterScooterInfo)
  ) ? null : (
    <div className={clsx('flex flex-col', className)}>
      <div
        className={clsx(
          'm-px flex  flex-wrap gap-1 justify-start',
          'items-start text-xs leading-none',
          !(
            aptitude?.length !== 0 ||
            participant.targetDeep > 0 ||
            !!mainLevelForTraining
          ) && 'invisible',
        )}
      >
        {aptitude?.length !== 0 && <div className="font-bold">{aptitude}</div>}
        {mainLevelForTraining?.length !== 0 && (
          <div className="font-bold">{mainLevelForTraining}</div>
        )}
        {participant.targetDeep > 0 && (
          <ParticipantTargetDeep
            className=""
            targetDeep={participant?.targetDeep}
          />
        )}
      </div>

      <div
        className={clsx(
          'm-px text-xs leading-none',
          !hasGazInfo &&
            !hasRebreatherInfo &&
            !hasUnderwaterScooterInfo &&
            'invisible',
        )}
      >
        {/* <div className="flex gap-2 flex-gap"> */}
        {hasRebreatherInfo && (
          <ParticipantRebreather
            className="h-full flex gap-x-1 flex-wrap justify-start items-center"
            rebreather={participant.equipment?.rebreather}
          />
        )}
        {hasUnderwaterScooterInfo && (
          <ParticipantUnderwaterScooter
            className="h-full flex gap-x-1 flex-wrap justify-start items-center"
            underwaterScooter={participant.equipment?.underwaterScooter}
          />
        )}
        {hasGazInfo && (
          <ParticipantGaz
            className="h-full flex gap-x-1 flex-wrap justify-start items-center"
            gaz={participant.gaz}
            showDetails={true}
            showGazQuantity={true}
            showAir={true}
          />
        )}
        {/* </div> */}
      </div>
    </div>
  );
}
