/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import { Button } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { apiClient, apiDownloader } from 'src/_common-browser';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppDropzone,
  AppLoader,
  AppMessage,
  AppPageContainer,
  AppPageContentContainer,
  AppTabsBar,
  AppTabsBarTab,
  AppVisible,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsMaterial,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { clubPlanningUrlBuilder } from 'src/business/club/modules/url-builders';
import { AppTitleDateNavigatorWithPeriodSelector } from 'src/pages/_components/title-navigators';
import { ClubDialogsProvider } from 'src/pages/_dialogs';
import { dashboardUrlBuilder } from '../_core';
import {
  DASHBOARD_SPACE_TABS,
  DashboardTabId,
} from '../_core/components/DashboardTab';
import {
  DashboardExportPageLocalState,
  useDashboardExportPageLocalState,
} from './useDashboardExportPageLocalState';

export const DashboardExportPage = () => {
  const localState: DashboardExportPageLocalState =
    useDashboardExportPageLocalState();

  const { state, data } = localState;
  const { viewParameters, setViewParameters, loadingInProgress, dialogsState } =
    state;
  const { fetchResult } = data;

  const diveCenterResume = useDiveCenterResume();

  const dailyExportsSettings =
    diveCenterResume.privateSettings.exports.dailyResumes;

  const redirectTo = useRedirect();

  const az = useAppSecurityUserClubAuthorizations();

  useEffect(() => {
    if (
      !(
        az.download?.bookings ||
        az.download?.dailyCash ||
        az.download?.dailyResume ||
        az.download?.divers
      )
    ) {
      redirectTo(clubPlanningUrlBuilder.buildPlanningUrl());
    }
  }, [
    az.download?.bookings,
    az.download?.dailyCash,
    az.download?.dailyResume,
    az.download?.divers,
    az.download?.securitySheet,
    redirectTo,
  ]);

  const beginDate = localState?.data?.fetchResult?.criteria?.beginDate;
  const endDate = localState?.data?.fetchResult?.criteria?.endDate;

  const periodLabel = useMemo(() => {
    if (beginDate?.getTime() === endDate?.getTime()) {
      return dateService.formatUTC(beginDate, 'DD/MM/YYYY').toUpperCase();
    } else if (beginDate && endDate) {
      const beginLabel = dateService
        .formatUTC(beginDate, 'DD/MM/YYYY')
        .toUpperCase();
      const endLabel = dateService
        .formatUTC(endDate, 'DD/MM/YYYY')
        .toUpperCase();
      return `du ${beginLabel} au ${endLabel}`;
    }
  }, [beginDate, endDate]);

  const uniqueDayLabel = useMemo(() => {
    const beginLabel = dateService
      .formatUTC(beginDate, 'DD/MM/YYYY')
      .toUpperCase();
    return `le ${beginLabel}`;
  }, [beginDate]);

  return (
    <AppPageContainer className="bg-gray-50">
      <ClubDialogsProvider dialogsState={dialogsState}>
        <AppTabsBar
          tabs={DASHBOARD_SPACE_TABS}
          value="export"
          onChange={(tab) => selectTab(tab)}
        />
        <div className="sticky top-0  px-2 py-2 z-[10] bg-gray-50 flex gap-5 justify-between">
          <AppTitleDateNavigatorWithPeriodSelector
            className="w-full"
            periodRange={viewParameters.periodRange}
            onChange={(periodRange) => {
              setViewParameters({
                ...viewParameters,
                periodRange,
              });
            }}
          />
        </div>
        <AppPageContentContainer className="bg-gray-50 app-px-content">
          {loadingInProgress && !fetchResult ? (
            <AppLoader className="my-4" />
          ) : (
            fetchResult && (
              <div className="w-full px-2 mt-4 pb-8 grid gap-4 text-gray-600">
                {az.download?.bookings && (
                  <div
                    className="app-card app-card-highlight app-p-content"
                    onClick={() =>
                      apiDownloader.downloadPaymentsSheet({
                        beginDateUTC: beginDate,
                        endDateUTC: endDate,
                      })
                    }
                  >
                    <div className="flex gap-4 flex-wrap">
                      <div className="flex-grow">
                        <div className={'whitespace-nowrap text-sm'}>
                          <div className="flex gap-1">
                            <div className="uppercase font-bold text-gray-800">
                              Ventes
                            </div>
                            <div className={'text-sm hidden sm:flex'}>
                              {periodLabel}
                            </div>
                          </div>
                          <div className="my-2">
                            Ventes, paiements et caisse sur 3 onglets
                          </div>
                        </div>
                        <div className={'text-sm hidden xs:flex'}>
                          <ul className="mt-1 ml-6 list-disc text-sm">
                            <li>le résumé de caisse</li>
                            <li>les paiements effectués</li>
                            <li>les ventes effectués</li>
                            <li>les réservations</li>
                          </ul>
                        </div>
                      </div>

                      <div
                        className={
                          'mx-4 w-28 whitespace-nowrap text-sm flex flex-col gap-4'
                        }
                      >
                        <AppButton
                          color="primary-outline-light"
                          className={''}
                          icon={AppHeroIcons.actionDownload}
                        >
                          Télécharger
                        </AppButton>
                      </div>
                    </div>
                  </div>
                )}
                {az.download?.dailyCash && (
                  <div
                    className="app-card app-card-highlight app-p-content"
                    onClick={() => {
                      const beginDateUTC = beginDate;
                      const endDateUTC = endDate;
                      apiDownloader.downloadDailyResumeSheetCash({
                        beginDateUTC,
                        endDateUTC,
                      });
                    }}
                  >
                    <div className="flex gap-4 flex-wrap">
                      <div className="flex-grow">
                        <div className={'whitespace-nowrap text-sm'}>
                          <div className="flex gap-1">
                            <div className="uppercase font-bold text-gray-800">
                              Caisse du jour simplifiée{' '}
                            </div>
                            <div className={'text-sm hidden sm:flex'}>
                              {uniqueDayLabel}
                            </div>
                            {/* <span className="text-xs text-status-info">
                              (NOUVEAU)
                            </span> */}
                          </div>
                          <div className="my-2">
                            Paiements + caisse regroupés sur une seule page A4
                          </div>
                        </div>
                        <div className={'text-sm hidden xs:flex'}>
                          <ul className="mt-1 ml-4 list-disc text-sm">
                            <li>le résumé de caisse</li>
                            <li>les paiements effectués (sans les détails)</li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={
                          'mx-4 w-28 whitespace-nowrap text-sm flex flex-col gap-4'
                        }
                      >
                        <AppButton
                          color="primary-outline-light"
                          className={''}
                          icon={AppHeroIcons.actionDownload}
                        >
                          Télécharger
                        </AppButton>
                      </div>
                    </div>
                  </div>
                )}
                {az.download?.dailyResume &&
                  dailyExportsSettings.map((dailyExportSettings) => (
                    <div
                      key={dailyExportSettings._id}
                      className="app-card app-card-highlight app-p-content"
                      onClick={() => {
                        const beginDateUTC = beginDate;
                        const endDateUTC = beginDate; // 1 seule journée
                        apiDownloader.downloadDailyResumeSheetCustom({
                          exportSettingsId: dailyExportSettings._id,
                          beginDateUTC,
                          endDateUTC,
                        });
                      }}
                    >
                      <div className="flex gap-4 flex-wrap">
                        <div className="flex-grow">
                          <div className={'whitespace-nowrap text-sm'}>
                            <div className="flex gap-1">
                              <div className="uppercase font-bold text-gray-800">
                                {dailyExportSettings.label}
                              </div>
                              <div className={'text-sm hidden sm:flex'}>
                                {uniqueDayLabel}
                              </div>
                              {/* <span className="text-xs text-status-warn">
                                (BETA)
                              </span> */}
                            </div>
                            <div className="my-2">
                              {dailyExportSettings.description}
                            </div>
                          </div>
                          <div className={'text-sm hidden xs:flex'}>
                            <ul className="mt-1 ml-4 list-disc text-sm">
                              {dailyExportSettings.exportConfig.sheets.map(
                                (sheet) => (
                                  <li key={sheet._id}>
                                    <b className="uppercase">
                                      {sheet.content.title}
                                    </b>
                                    :{' '}
                                    <span className="lowercase text-gray-500">
                                      {sheet.tab.comment}
                                    </span>
                                  </li>
                                ),
                              )}
                            </ul>
                          </div>
                        </div>
                        <div
                          className={
                            'mx-4 w-28 whitespace-nowrap text-sm flex flex-col gap-4'
                          }
                        >
                          <AppButton
                            color="primary-outline-light"
                            className={''}
                            icon={AppHeroIcons.actionDownload}
                          >
                            Télécharger
                          </AppButton>
                          {az.edit.settings.exportDaily && (
                            <AppButton
                              type="button"
                              color="gray-outline-light"
                              className={''}
                              icon={AppHeroIcons.actionSettings}
                              onClick={(e) => {
                                e.stopPropagation();
                                dialogsState.dailyExportConfigDialog.openDialog(
                                  {
                                    exportRef: 'daily-export-activity',
                                  },
                                );
                              }}
                            >
                              Configurer
                            </AppButton>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                {az.download?.divers && (
                  <div
                    className="app-card app-card-highlight app-p-content"
                    onClick={() => apiDownloader.downloadDiversSheet()}
                  >
                    <div className="flex gap-4 flex-wrap">
                      <div className="flex-grow">
                        <div className={'whitespace-nowrap text-sm'}>
                          <div className="flex gap-1">
                            <div className="uppercase font-bold">Plongeurs</div>
                          </div>
                          <div className="my-2">
                            La liste de tous vos plongeurs
                          </div>
                          <div className={'text-sm hidden xs:flex'}>
                            <ul className="mt-1 ml-4 list-disc text-sm">
                              <li>tous les plongeurs</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          'mx-4 w-28 whitespace-nowrap text-sm flex flex-col gap-4'
                        }
                      >
                        <AppButton
                          color="primary-outline-light"
                          className={''}
                          icon={AppHeroIcons.actionDownload}
                        >
                          Télécharger
                        </AppButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          )}

          <AppVisible role="club-import-divers">
            <AppMessage
              isExpanded={false}
              className="mt-10 mb-2"
              type="info"
              title="Import des plongeurs"
              message={
                <>
                  <div>
                    Pour importer des nouveaux plongeurs:
                    <ul className="ml-4 list-disc">
                      <li>Télécharger le modèle de fichier excel</li>
                      <li>
                        Renseigner les plongeurs dans le fichier sans modifier
                        les entêtes des colonnes
                      </li>
                      <li>Importer le fichier</li>
                    </ul>
                  </div>
                  <div>
                    Pour importer des modifications de plongeurs existant:
                    <ul className="ml-4 list-disc">
                      <li>Exporter la liste des plongeurs:</li>
                      <li>
                        Effectuer les modifications dans le fichier exporté sans
                        modifier les entêtes des colonnes ni le contenu de la
                        colonne "identifiant mabadive".
                      </li>
                      <li>Importer le fichier</li>
                    </ul>
                  </div>
                  <div className="my-2 text-center">
                    <Button
                      startIcon={<AppIconsMaterial.download />}
                      variant="contained"
                      color="primary"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/fichiers/import/modele_import_plongeurs.xlsx"
                    >
                      Télécharger
                      <div className="hidden sm:block"> le modèle</div>
                    </Button>
                  </div>
                  <AppDropzone
                    className="mt-[50px]"
                    maxFileSizeLabel="10 Mo"
                    acceptFileExtensions={['.xlsx']}
                    label="Importer un fichier client"
                    onFileLoaded={(args) => {
                      const file = args.file;
                      const url = '/divers-import';

                      let formData: FormData = new FormData();
                      formData.append('file', file, file.name);
                      apiClient
                        .post(url, {
                          body: formData,
                          authenticate: true,
                        })
                        .subscribe(
                          (res) => {
                            // console.log('res:', res);
                          },
                          (err) => {
                            console.log('err:', err);
                          },
                        );
                    }}
                  />
                </>
              }
            />
          </AppVisible>
        </AppPageContentContainer>
      </ClubDialogsProvider>
    </AppPageContainer>
  );
  function selectTab(tab: AppTabsBarTab<DashboardTabId>) {
    return redirectTo(
      dashboardUrlBuilder.buildTabUrl({
        tabId: tab.id,
      }),
    );
  }
};
