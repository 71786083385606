import React from 'react';
import {
  DashboardReportPageEvolutionParticipantsByDay,
  DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriod,
  DashboardReportPageTrainingParticipants,
} from '../_core';

import clsx from 'clsx';
import { DashboardReportComparison } from './DashboardReportComparison';
import {
  DashboardReportComparisonContext,
  DashboardReportPageLocalState,
} from './useDashboardReportPageLocalState.hook';

export const DashboardReportPanelTraining = ({
  chartWidth,
  totalColsCount,
  localState,
  comparisonContext,
  className,
}: {
  chartWidth: number;
  totalColsCount: number;
  localState: DashboardReportPageLocalState;
  comparisonContext: DashboardReportComparisonContext;
  className?: string;
}) => {
  const showPeriodRangeComparison =
    localState.state.viewParameters.showPeriodRangeComparison;

  return (
    <div className="w-full flex flex-col gap-5">
      <div
        className={clsx(
          'w-full flex flex-col gap-5',
          showPeriodRangeComparison
            ? ''
            : '2xl:grid 2xl:grid-cols-2 items-stretch',
        )}
      >
        <DashboardReportComparison
          comparisonContext={comparisonContext}
          render={(fetchResult, comparisonDetails) =>
            fetchResult.trainingParticipantsOverPeriod && (
              <DashboardReportPageTrainingParticipants
                // key={JSON.stringify(comparisonDetails?.sharedState)}
                title={'Plongées de formation'}
                trainingParticipantsOverPeriod={
                  fetchResult.trainingParticipantsOverPeriod
                }
                comparisonDetails={comparisonDetails}
              />
            )
          }
        />
        <DashboardReportComparison
          comparisonContext={comparisonContext}
          render={(fetchResult, comparisonDetails) =>
            fetchResult.purchasesTrainingsOverPeriod && (
              <DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriod
                title="Formations facturées"
                purchasesTrainingsOverPeriod={
                  fetchResult.purchasesTrainingsOverPeriod
                }
                comparisonDetails={comparisonDetails}
              />
            )
          }
        />
      </div>

      <DashboardReportComparison
        comparisonContext={comparisonContext}
        render={(fetchResult, comparisonDetails) =>
          fetchResult.participantsByPeriod && (
            <DashboardReportPageEvolutionParticipantsByDay
              participantsByPeriod={fetchResult.participantsByPeriod}
              chartWidth={chartWidth}
              pageMode="trainings"
              comparisonDetails={comparisonDetails}
            />
          )
        }
      />
    </div>
  );
};
