import {
  BookingSession,
  BookingStatus,
  ClubPlanningLightSessionDef,
  DiveSession,
} from '@mabadive/app-common-model';
import { commonEntityBuilder } from '@mabadive/app-common-services';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import { DiverBookingNewBookingSession } from '../../../models';
import { diveSessionResumeToDiveSessionConvertor } from '../entity-convertors';
export const diverBookingPageSessionCreator = {
  createSessions,
  createBookingSession,
  createBookingSessionFromSourceSession,
  createDiverBookingNewBookingSession,
};

function createSessions({
  sessions,
  bookingId,
  clubReference,
  diveCenterId,
  bookingSessionStatus = 'confirmed',
}: {
  sessions: ClubPlanningLightSessionDef[];
  bookingId: string;
  clubReference: string;
  diveCenterId: string;
  bookingSessionStatus?: BookingStatus;
}): {
  newBookingSessions: DiverBookingNewBookingSession[];
  newDiveSessions: DiveSession[];
  originalDiveSessions: DiveSession[];
} {
  return sessions.reduce(
    (acc, session: ClubPlanningLightSessionDef) => {
      const diveSession: DiveSession =
        diveSessionResumeToDiveSessionConvertor.convert(session, {
          clubReference,
        });

      if (diveSession.isVirtual) {
        acc.newDiveSessions.push(
          commonEntityBuilder.buildEntity<DiveSession>({
            ...diveSession,
            virtualDiveSessionReference:
              diveSession.virtualDiveSessionReference,
            virtualDiveTourReference: diveSession.virtualDiveTourReference,
            clubReference,
            isVirtual: false,
          }),
        );
      } else {
        acc.originalDiveSessions.push(diveSession);
      }
      const newBookingSession: DiverBookingNewBookingSession =
        createDiverBookingNewBookingSession({
          session,
          bookingId,
          diveCenterId,
          clubReference,
          bookingSessionStatus,
        });
      acc.newBookingSessions.push(newBookingSession);

      return acc;
    },
    {
      newBookingSessions: [],
      newDiveSessions: [],
      originalDiveSessions: [],
    } as {
      newBookingSessions: DiverBookingNewBookingSession[];
      newDiveSessions: DiveSession[];
      originalDiveSessions: DiveSession[];
    },
  );
}

function createDiverBookingNewBookingSession({
  session,
  bookingId,
  clubReference,
  diveCenterId,
  bookingSessionStatus = 'confirmed',
}: {
  session: ClubPlanningLightSessionDef;
  bookingId: string;
  clubReference: string;
  diveCenterId: string;
  bookingSessionStatus: BookingStatus;
}): DiverBookingNewBookingSession {
  const diveSessionResume: ClubPlanningLightSessionDef = {
    ...session,
    timeDayPeriod: undefined,
    participants: undefined,
    groups: undefined,
  };
  const diveSessionReference = diveSessionResume.reference;
  const bookingSession: BookingSession = createBookingSession({
    bookingId,
    diveCenterId,
    clubReference,
    diveSessionReference,
    bookingSessionStatus,
  });
  const newBookingSession: DiverBookingNewBookingSession = {
    diveSessionResume,
    bookingSession,
  };
  return newBookingSession;
}

function createBookingSession({
  bookingId,
  clubReference,
  diveCenterId,
  diveSessionReference,
  bookingSessionStatus,
}: {
  bookingId: string;
  clubReference: string;
  diveCenterId: string;
  diveSessionReference: string;
  bookingSessionStatus: BookingStatus;
}): BookingSession {
  return repositoryEntityBuilder.buildEntity<BookingSession>({
    bookingSessionStatus,
    bookingId,
    clubReference,
    diveCenterId,
    diveSessionReference,
  });
}
function createBookingSessionFromSourceSession({
  bookingId,
  clubReference,
  sourceSession,
}: {
  bookingId: string;
  clubReference: string;
  sourceSession: Pick<
    BookingSession,
    'bookingSessionStatus' | 'diveSessionReference' | 'diveCenterId'
  >;
}): BookingSession {
  return repositoryEntityBuilder.buildEntity<BookingSession>({
    bookingId,
    clubReference,
    diveCenterId: sourceSession.diveCenterId,
    bookingSessionStatus: sourceSession.bookingSessionStatus,
    diveSessionReference: sourceSession.diveSessionReference,
  });
}
