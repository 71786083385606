/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubProductPackageAttributesDive,
  ClubProductPackageAttributesProduct,
  ClubProductPackageAttributesTraining,
  ClubProductPackageOfferView,
} from '@mabadive/app-common-model';
import {
  ClubProductPackageOfferViewSortByAttribute,
  jsonPatcher,
} from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useGlobalClasses } from 'src/AppTheme';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppFixedButtonsBar } from 'src/business/_core/modules/layout/components/AppFixedButtonsBar/AppFixedButtonsBar';
import { CollectionOrder } from 'src/business/_core/modules/layout/components/PageableTable';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { ClubOfferTrainingMultiEditorFormModel } from './ClubOfferTrainingMultiEditorFormModel.type';
import { ClubOfferTrainingMultiEditorRow } from './ClubOfferTrainingMultiEditorRow';

export const ClubOfferTrainingMultiEditor = ({
  productPackageOffers,
  onOrderChanged,
  onCancel,
  onConfirm,
  className,
}: {
  productPackageOffers: ClubProductPackageOfferView[];
  onOrderChanged?: (
    order: CollectionOrder<ClubProductPackageOfferViewSortByAttribute>,
  ) => void;
  onCancel?: () => void;
  onConfirm?: (attrs: {
    updatedProductPackageOffers: AppEntityUpdatePatch[];
  }) => void;
  className?: string;
}) => {
  const globalClasses = useGlobalClasses();

  const initialFormValue = useMemo(() => {
    const model: ClubOfferTrainingMultiEditorFormModel = {
      offers: productPackageOffers.map((o) => ({
        price: o.price,
        thirdPartyCollectPrice: o.thirdPartyCollectPrice,
        divesCount: o?.productPackage.diveAttributes?.divesCount,
        divesCountInfinite:
          o?.productPackage.diveAttributes?.divesCountInfinite,
        validityPeriodInDays:
          o?.productPackage.productAttributes?.validityPeriodInDays,
        theoricalClassesCounts:
          o?.productPackage.trainingAttributes?.theoricalClassesCounts,
        minAge: o?.productPackage.productAttributes?.minAge,
        maxAge: o?.productPackage.productAttributes?.maxAge,
        minDepth: o?.productPackage.diveAttributes?.minDepth,
        maxDepth: o?.productPackage.diveAttributes?.maxDepth,
        comment: o?.productPackage?.comment,
      })),
    };
    return model;
  }, [productPackageOffers]);

  const form = useForm<ClubOfferTrainingMultiEditorFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: ClubOfferTrainingMultiEditorFormModel, event) => {
        const updatedProductPackageOffers = productPackageOffers.reduce(
          (acc, initialOffer, i) => {
            const finalProductAttributes: ClubProductPackageAttributesProduct =
              {
                ...initialOffer.productPackage.productAttributes,
                // includeDives
                // organizationReference
                // certificationReference
                minAge: formValue.offers[i].minAge,
                maxAge: formValue.offers[i].maxAge,
                validityPeriodInDays: formValue.offers[i].validityPeriodInDays,
              };
            const finalProductAttributesDive: ClubProductPackageAttributesDive =
              {
                ...initialOffer.productPackage.diveAttributes,
                // diveMode
                // diveType
                // equipment
                // supervision
                divesCount: formValue.offers[i].divesCountInfinite
                  ? 9999
                  : formValue.offers[i].divesCount,
                divesCountInfinite: formValue.offers[i].divesCountInfinite,
                minDepth: formValue.offers[i].minDepth,
                maxDepth: formValue.offers[i].maxDepth,
              };
            const finalProductAttributesTraining: ClubProductPackageAttributesTraining =
              {
                ...initialOffer.productPackage.trainingAttributes,
                theoricalClassesCounts:
                  formValue.offers[i].theoricalClassesCounts,
                // trainingType
                // diveTrainingReference
                // theoricalClassesCounts
              };
            const finalOffer: ClubProductPackageOfferView = {
              ...initialOffer,
              price: formValue.offers[i].price,
              thirdPartyCollectPrice:
                formValue.offers[i].thirdPartyCollectPrice,
              productPackage: {
                ...initialOffer.productPackage,
                productAttributes: finalProductAttributes,
                diveAttributes: finalProductAttributesDive,
                trainingAttributes: finalProductAttributesTraining,
                comment: formValue.offers[i].comment,
              },
            };
            const offerPatchOperations = jsonPatcher.compareObjects(
              initialOffer,
              finalOffer,
              {},
            );
            if (offerPatchOperations.length) {
              const patch: AppEntityUpdatePatch = {
                pk: initialOffer._id,
                patchOperations: offerPatchOperations,
              };
              acc.push(patch);
            }
            return acc;
          },
          [] as AppEntityUpdatePatch[],
        );
        onConfirm({
          updatedProductPackageOffers,
        });
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [handleSubmit, onConfirm, productPackageOffers]);
  const user = useAppSecurityUser();
  const redirectTo = useRedirect();

  const firstColumnColSpan = 2;
  return (
    <div>
      <AppMessage
        className="mb-5"
        title={'Instruction'}
        message={
          <>
            <p>
              Sur cette page, vous pouvez modifier les principales
              caractéristiques des formations.
            </p>
            <p className="my-2">
              Pour créer de nouvelle formations ou effectuer des modifications
              avancées, veuillez nous contacter.
            </p>

            <AppButton
              className="mt-5"
              color="primary-outline-light"
              icon={AppHeroIcons.contact}
              onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
            >
              Contactez-nous
            </AppButton>
          </>
        }
      />
      <div>
        {productPackageOffers.map((productPackageOffer, i) => (
          <ClubOfferTrainingMultiEditorRow
            className="my-4 border border-gray-400 p-4"
            key={`${i}`}
            form={form}
            offerIndex={i}
            productPackageOffer={productPackageOffer}
          />
        ))}
      </div>
      <AppFixedButtonsBar hasChanges={formState.isDirty}>
        <AppButton
          fullWidth={true}
          icon={AppHeroIcons.actionCancel}
          color="gray-outline-light"
          size="normal"
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          Annuler
        </AppButton>
        <AppButton
          fullWidth={true}
          icon={AppHeroIcons.actionSave}
          color="primary-outline-light"
          onClick={() => {
            submitForm();
          }}
        >
          Confirmer
        </AppButton>
      </AppFixedButtonsBar>
    </div>
  );
};
