import clsx from 'clsx';
import React, { useMemo } from 'react';

import { DashboardReportPanelHome } from './DashboardReportPanel01_Home';
import { DashboardReportPanelFirstDives } from './DashboardReportPanel02_FirstDives';
import { DashboardReportPanelExplos } from './DashboardReportPanel03_Explos';
import { DashboardReportPanelTraining } from './DashboardReportPanel04_Training';
import {
  DashboardReportComparisonContext,
  DashboardReportPageLocalState,
} from './useDashboardReportPageLocalState.hook';

export const DashboardReportPanel = ({
  chartWidth,
  totalColsCount,
  localState,
  className,
}: {
  chartWidth: number;
  totalColsCount: number;
  localState: DashboardReportPageLocalState;
  className?: string;
}) => {
  const { uiConfig, setUIConfig } = localState?.state;

  const comparisonContext: DashboardReportComparisonContext = useMemo(
    () => ({
      showPeriodRangeComparison:
        localState.state.viewParameters.showPeriodRangeComparison,
      data: localState.data,
      localState,
    }),
    [localState],
  );

  return (
    <>
      {localState.data.hasAnyResultOrFake && (
        <div className={clsx('', className)}>
          {uiConfig?.pageMode === 'home' && (
            <DashboardReportPanelHome
              chartWidth={chartWidth}
              totalColsCount={totalColsCount}
              localState={localState}
              comparisonContext={comparisonContext}
            />
          )}
          {uiConfig?.pageMode === 'first-dives' && (
            <DashboardReportPanelFirstDives
              chartWidth={chartWidth}
              totalColsCount={totalColsCount}
              localState={localState}
              comparisonContext={comparisonContext}
            />
          )}
          {uiConfig?.pageMode === 'explos' && (
            <DashboardReportPanelExplos
              chartWidth={chartWidth}
              totalColsCount={totalColsCount}
              localState={localState}
              comparisonContext={comparisonContext}
            />
          )}
          {uiConfig?.pageMode === 'trainings' && (
            <DashboardReportPanelTraining
              chartWidth={chartWidth}
              totalColsCount={totalColsCount}
              localState={localState}
              comparisonContext={comparisonContext}
            />
          )}
        </div>
      )}
    </>
  );
};
