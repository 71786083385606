import { BookingStatus } from '@mabadive/app-common-model';
import { CalendarSessionWidthMode } from './CalendarSessionWidthMode.type';

export const bookingStatusBorderStyleBuilder = {
  buildStyle,
};
function buildStyle(
  bookingStatus: BookingStatus,
  {
    widthMode,
    confirmStatus = 'none',
    position = 'right',
  }: {
    widthMode: CalendarSessionWidthMode;
    confirmStatus?: 'none' | 'transparent';
    position?: 'right' | 'left';
  },
) {
  if (
    bookingStatus === 'pending' ||
    bookingStatus === 'cancelled' ||
    confirmStatus !== 'none'
  ) {
    let classes =
      widthMode === 'tiny'
        ? position === 'right'
          ? 'border-r-2 border-solid'
          : 'border-l-2 border-solid'
        : widthMode === 'small'
        ? position === 'right'
          ? 'border-r-[4px] border-solid'
          : 'border-l-[4px] border-solid'
        : widthMode === 'medium'
        ? position === 'right'
          ? 'border-r-[4px] border-solid'
          : 'border-l-[4px] border-solid'
        : position === 'right'
        ? 'border-r-[5px] border-solid'
        : 'border-l-[5px] border-solid';

    if (bookingStatus === 'pending') {
      classes += ' border-orange-400';
    } else if (bookingStatus === 'cancelled') {
      classes += ' border-red-600';
    } else {
      classes += ' border-transparent';
    }
    return classes;
  }
}
