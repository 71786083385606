import { AuthUserRole } from '@mabadive/app-common-model';
import React, { FC, useMemo } from 'react';
import {
  AppSecurityUser,
  authenticationChecker,
  useAppSecurityUser,
} from 'src/business/auth/services';

export const AppVisible: FC<{
  role?: AuthUserRole;
  roles?: AuthUserRole[];
  notRole?: AuthUserRole;
  show?: () => boolean;
  children?: React.ReactNode | React.ReactNode[];
}> = ({ notRole, children, role, roles, show }) => {
  const user = useAppSecurityUser();

  const showContent = useMemo(
    () => isVisible({ notRole, role, roles, user, show }),
    [notRole, role, roles, user, show],
  );

  return showContent ? <>{children}</> : null;
};

function isVisible({
  notRole,
  role,
  roles,
  user,
  show,
}: {
  notRole: AuthUserRole;
  role: AuthUserRole;
  roles: AuthUserRole[];
  user: AppSecurityUser;
  show?: () => boolean;
}) {
  if (role && !authenticationChecker.hasRequiredRole(user, role)) {
    return false;
  }
  if (notRole && authenticationChecker.hasRequiredRole(user, notRole)) {
    return false;
  }
  if (roles && !authenticationChecker.hasRequiredRoles(user, roles)) {
    return false;
  }
  if (show && !show()) {
    return false;
  }
  return true;
}
