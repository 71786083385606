/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { NEVER } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { ApiClientHttpError } from 'src/_common-browser';
import { useLoadable, useRedirect } from 'src/business/_core/data/hooks';
import { responsiveAttributesValues } from 'src/business/_core/modules/layout';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import {
  appStylesHelper,
  useCacheableClasses,
  useGlobalClasses,
} from 'src/business/_core/modules/root/theme';
import { uiStore } from 'src/business/_core/store';
import {
  authenticationClient,
  authenticationStore,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { appWebLogger } from 'src/lib/browser';
import { RxjsForm, rxjsFormActionManager } from 'src/lib/form';
import { AppForm } from 'src/lib/form/components/AppForm';
import { ChoosePasswordForm } from './ChoosePasswordForm/ChoosePasswordForm';
import { ChoosePasswordFormModel } from './ChoosePasswordForm/ChoosePasswordFormModel.type';
import { choosePasswordRepository } from './ChoosePasswordForm/choosePasswordRepository.service';

export const AccountChoosePasswordPage = () => {
  const redirectTo = useRedirect();

  // const defaultAuthLogin = browserUrlParser.getUrlQueryParams()['login'];
  const params = new URLSearchParams(window.location.search);
  const defaultAuthLogin = params.get('login');

  const globalClasses = useGlobalClasses();

  const classes = useCacheableClasses({
    cacheKey: 'AccountChoosePasswordPage',
    buildStyles,
  });

  const { _loaded, choosePasswordForm, choosePasswordComponents } = useLoadable(
    {
      debugName: 'AccountChoosePasswordPage',
      initialValueFromLoadSnapshot: true,
      load: () =>
        choosePasswordRepository.loadInitialData({
          defaultAuthLogin,
        }),
    },
  );

  const [authenticationInProgress, setAuthenticationInProgress] =
    useState(false);

  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  return _loaded ? (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >
      <Box flex="500px 0 1" height="auto" display="flex" flexDirection="column">
        <Box component={Paper} p={2} mt={5}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                className={classes.formTitle}
              >
                Veuillez choisir un nouveau mot de passe
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AppForm
                bordered={true}
                hasChanges={choosePasswordForm.hasChanges}
              >
                <form
                  noValidate
                  autoComplete="on"
                  onSubmit={(event) => {
                    event.preventDefault();
                    submitChoosePasswordForm(choosePasswordForm);
                    return false;
                  }}
                >
                  <ChoosePasswordForm components={choosePasswordComponents} />
                  <Box
                    className={globalClasses.buttonsBar}
                    textAlign="center"
                    mt={2}
                  >
                    <Button
                      startIcon={<AppIconsMaterial.login />}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Se connecter
                    </Button>
                  </Box>
                </form>
              </AppForm>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  ) : null;

  function submitChoosePasswordForm(form: RxjsForm<ChoosePasswordFormModel>) {
    if (form.valid) {
      if (authenticationInProgress) {
        return;
      }
      setAuthenticationInProgress(true);

      rxjsFormActionManager
        .saveChanges({
          form,
          isCreationMode: true,
          attributesToReplaceFully: [],
          createMethod: ({ form }) => {
            return authenticationClient.choosePassword({
              login: form.value.userAuthEmailLogin,
              password: form.value.userAuthPassword,
            });
          },
          updateMethod: ({ form, patchOperations }) => NEVER,
        })
        .pipe(
          switchMap(({ success }) => {
            uiStore.snackbarMessage.set({
              type: 'success',
              content: 'Changement de mot de passe réussi!',
            });
            return authenticationStore.auth.get().pipe(
              first(),
              map((appAuth) =>
                appRouteBuilder.getDefaultRouteFromAuth({ appAuth }),
              ),
              tap((defaultRoute) => {
                redirectTo(defaultRoute);
              }),
              catchError(() => {
                setAuthenticationInProgress(false);
                return NEVER;
              }),
            );
          }),
          catchError((err: ApiClientHttpError) => {
            appWebLogger.captureMessage(
              'Error while trying to change password',
              {
                logContext: 'AccountChoosePasswordPage',
                clubReference,
                extra: {
                  err,
                },
              },
            );
            uiStore.snackbarMessage.set({
              type: 'error',
              content:
                'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
            });
            setAuthenticationInProgress(false);
            return NEVER;
          }),
        )
        .subscribe();
    } else {
      form.actions.touchComponents();
    }
  }
};

function buildStyles(theme: Theme) {
  return makeStyles({
    formTitle: {
      ...responsiveAttributesValues(theme, {
        fontSize: appStylesHelper.getFontSizeResponsive('lg'),
      }),
    },
  });
}
