/* eslint-disable @typescript-eslint/no-unused-vars */
import { exportGroupDiverColumnFormatter } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { DailyExportConfigFormModel } from '../../_model';

export type DECOP_SessionItemDive_GroupDiverColumnModelOnClickAction =
  | 'move-down'
  | 'move-up'
  | 'delete';

export const DECOP_SessionItemDive_GroupDiverColumnModel = ({
  className,
  form,
  fieldArray,
  field,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  sessionItemIndex,
  sessionItemDiveIndex,
  fieldIndex,
  onClick,
}: {
  className?: string;
  form: UseFormReturn<DailyExportConfigFormModel>;
  fieldArray: UseFieldArrayReturn<
    DailyExportConfigFormModel,
    `dailyResumes.${number}.exportConfig.sheets.${number}.content.items.${number}.items.${number}.items.${number}.diverColumns`,
    'id'
  >;
  field: FieldArrayWithId<DailyExportConfigFormModel, any, 'id'>;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  sessionItemIndex: number;
  sessionItemDiveIndex: number;
  fieldIndex: number;
  onClick: (
    action: DECOP_SessionItemDive_GroupDiverColumnModelOnClickAction,
  ) => void;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    getValues,
  } = form;

  const securityUser = useAppSecurityUser();
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const { fields, append, prepend, remove, swap, move, insert } = fieldArray;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}.items.${sessionItemIndex}.items.${sessionItemDiveIndex}.diverColumns.${fieldIndex}` as const;

  const isMoveDownAllowed =
    fieldArray.fields.length > 1 && fieldIndex + 1 < fieldArray.fields.length;
  const isMoveUpAllowed = fieldArray.fields.length > 1 && fieldIndex !== 0;

  const [columnId] = useWatch({
    control,
    name: [`${baseFormName}.id`],
  });

  const columnOptionLabel = useMemo(
    () =>
      exportGroupDiverColumnFormatter.formatColumnName(columnId, {
        format: 'option-label',
      }),
    [columnId],
  );
  const columnExcelLabel = useMemo(
    () =>
      exportGroupDiverColumnFormatter.formatColumnName(columnId, {
        format: 'excel',
      }),
    [columnId],
  );

  return (
    <div
      className={clsx(
        'border-l-4 border-gray-600 pl-2 grid md:flex gap-2 lg:gap-4 items-start',
        className,
      )}
    >
      <div
        className={clsx(
          'h-full flex md:flex-col md:justify-center gap-2',
          fieldArray.fields.length === 1 && 'invisible',
        )}
      >
        <Tippy
          delay={[1000, 100]}
          placement="top"
          content={'Déplacer au dessus'}
        >
          <AppIconsAction.up
            className={clsx(
              'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
              isMoveUpAllowed
                ? 'hover:bg-gray-600 cursor-pointer'
                : 'opacity-50 cursor-not-allowed',
            )}
            onClick={() => isMoveUpAllowed && onClick('move-up')}
          />
        </Tippy>
        <Tippy
          delay={[1000, 100]}
          placement="top"
          content={'Déplacer en dessous'}
        >
          <AppIconsAction.down
            className={clsx(
              'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
              isMoveDownAllowed
                ? 'hover:bg-gray-600 cursor-pointer'
                : 'opacity-50 cursor-not-allowed',
            )}
            onClick={() => isMoveDownAllowed && onClick('move-down')}
          />
        </Tippy>
      </div>
      <div>
        <div className="flex flex-row flex-wrap gap-2">
          <div>
            <AppFormControlRHF_Deprecated
              className={'w-full'}
              label={columnOptionLabel}
              control={control}
              name={`${baseFormName}.label`}
              required={false}
              renderComponent={(props) => (
                <AppInputRHF {...props} placeholder={columnExcelLabel} />
              )}
            />
            {isSuperAdmin && (
              <div className="flex flex-row flex-wrap gap-2">
                <AppFormControlRHF_Deprecated
                  className="w-40"
                  label={'Font titre'}
                  control={control}
                  name={`${baseFormName}.relativeSizeHeader`}
                  required={false}
                  renderComponent={(props) => (
                    <AppInputRHF
                      {...props}
                      placeholder="xxs, xs,sm, md, lg, xl"
                    />
                  )}
                />
                <AppFormControlRHF_Deprecated
                  className="w-40"
                  label={'Font texte'}
                  control={control}
                  name={`${baseFormName}.relativeSizeBody`}
                  required={false}
                  renderComponent={(props) => (
                    <AppInputRHF
                      {...props}
                      placeholder="xxs, xs,sm, md, lg, xl"
                    />
                  )}
                />
                {/* <AppFormControlRHF_Deprecated FIXME: désactivé car ça ne fonctionne pas (buggé)
                  className="w-40"
                  label={'Taille col'}
                  control={control}
                  name={`${baseFormName}.widthRatio`}
                  required={false}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} placeholder="3" type="number" />
                  )}
                /> */}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-2 md:gap-6">
        <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
          <AppIconsAction.delete
            className="p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer"
            onClick={() => {
              onClick('delete');
            }}
          />
        </Tippy>
      </div>
    </div>
  );
};
