import React, { useCallback } from 'react';
import { DiverPurchasePaymentEditorDialogState } from '../../models';

export function useOpenCreatePurchasePaymentDialog({
  setPaymentEditorDialogState,
}: {
  setPaymentEditorDialogState: React.Dispatch<
    React.SetStateAction<DiverPurchasePaymentEditorDialogState>
  >;
}) {
  return useCallback(
    (attrs: Omit<DiverPurchasePaymentEditorDialogState, 'isOpen' | 'mode'>) =>
      setPaymentEditorDialogState({
        ...attrs,
        isOpen: true,
        mode: 'create',
      }),
    [setPaymentEditorDialogState],
  );
}
