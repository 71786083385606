import { ClubSettings } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import {
  BillingTabParticipantPurchase,
  BillingTabParticipantPurchaseDivesCounts,
  PRO_BookingParticipantFull,
} from '../../../models';

export const billingTabModelCountsBuilder = {
  updateCountsFromParticipants,
  buildIsOnlyCancelledFromDivesCounts,
};

function updateCountsFromParticipants({
  clubSettings,
  participants,
  participantPurchase,
}: {
  clubSettings: ClubSettings;
  participants: PRO_BookingParticipantFull[];
  participantPurchase: BillingTabParticipantPurchase;
}) {
  const counts = participantPurchase.divesCounts;
  participants.forEach((participant) => {
    const countSuccessiveAsSingle = participantPurchase.countSuccessiveAsSingle;
    const divesCount = countSuccessiveAsSingle
      ? 1
      : participant?.diveSessionParticipant?.divesCount;

    if (participant.bookingProductDive.purchasePackageId) {
      counts.assigned += divesCount;
    } else if (
      participant.bookingProductDive.bookingProductStatus === 'cancelled'
    ) {
      counts.cancelled += divesCount;
    } else if (dateService.isBefore(participant.diveSession.time)) {
      counts.toAssign += divesCount;
    } else {
      counts.future += divesCount;
    }
  });
  // mise à jour du statut "isOnlyCancelled"
  participantPurchase.isOnlyCancelled =
    buildIsOnlyCancelledFromDivesCounts(counts);
}
function buildIsOnlyCancelledFromDivesCounts(
  divesCounts: BillingTabParticipantPurchaseDivesCounts,
) {
  return (
    divesCounts.assigned +
      divesCounts.toAssign +
      divesCounts.consumedExternalCount +
      divesCounts.future ===
      0 && divesCounts.cancelled > 0
  );
}
