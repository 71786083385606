import {
  AppEntityUpdatePatch,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import {
  CustomerSpaceConfigDialogProps,
  CustomerSpaceConfigFormModel,
} from './model';

export function useCustomerSpaceConfigDialogActionPersist(): CustomerSpaceConfigDialogProps {
  const action: CustomerSpaceConfigDialogProps = {
    async onConfirm({ formValue }, initialState) {
      const { defaultValue } = initialState;
      const payload: ProMultiOperationPayload =
        buildCustomerSpaceConfigPersistPayload({
          formValue,
          initialValue: defaultValue,
        });

      if (payload) {
        return clubMassiveUpdatorClient.update(payload);
      }
    },
  };
  return action;
}

export function buildCustomerSpaceConfigPersistPayload({
  initialValue,
  formValue,
}: {
  initialValue: CustomerSpaceConfigFormModel;
  formValue: CustomerSpaceConfigFormModel;
}): ProMultiOperationPayload {
  const clubSettingsPatchOperations = jsonPatcher.compareObjects(
    initialValue?.clubSettings,
    formValue?.clubSettings,
    {
      // else, value won't be deleted by typeorm
      // https://github.com/typeorm/typeorm/issues/2934
      replaceDeleteByNullValue: true,
    },
  );
  if (clubSettingsPatchOperations.length) {
    const payload: ProMultiOperationPayload =
      clubMassiveUpdatorClient.createEmptyPayload({
        logContext: 'edit customer space config',
        bookingIdsToClean: [],
      });

    if (clubSettingsPatchOperations.length) {
      const patch: AppEntityUpdatePatch = {
        pk: initialValue.clubSettings._id,
        patchOperations: clubSettingsPatchOperations,
      };
      payload.updatedClubSettings = patch;
    }
    return payload;
  }
}
