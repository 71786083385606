/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  clubProductPackageOfferMatcher,
  clubProductPackageOfferViewSorter,
  MatchingOffersCriteria,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';

export function useDiverPurchaseTrainingPackageOffers(
  criteria: MatchingOffersCriteria,
) {
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = useClubSettings();
  return useMemo(() => {
    const productPackageOffers = clubProductPackageOfferViewSorter.sortOffersBy(
      clubProductPackageOfferMatcher.getMatchingOffers({
        criteria,
        clubResume,
        offers: clubResume.productPackageOffers,
      }),
      {
        attributeName: 'training',
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );

    return productPackageOffers;
  }, [clubResume, clubSettings?.ui?.diveMode, criteria, publicSettings]);
}
