/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  ClubStaffMemberRole,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import { arrayBuilder, dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { PlanningViewConfig } from '../../../club-planning/_model';
import { ClubPlanningLightPanelViewClickActions } from '../../model';
import { ClubPlanningLightPanelViewPeriodDayStaffMember } from './ClubPlanningLightPanelViewPeriodDayStaffMember';
import { ClubPlanningLightPanelViewPeriodDayStaffMembersListSession } from './ClubPlanningLightPanelViewPeriodDayStaffMembersListSession';

export const ClubPlanningLightPanelViewPeriodDayStaffMembersList: FC<{
  showDate: boolean;
  showStaffMember: boolean;
  planningConfig: PlanningViewConfig;
  dailySessions: ClubPlanningLightSessionDef[];
  staffMembersDailySessions: StaffMemberResumeSessionsModel[];
  clickActions: ClubPlanningLightPanelViewClickActions;
  className?: string;
}> = ({
  showDate,
  showStaffMember,
  staffMembersDailySessions,
  planningConfig,
  clickActions,
  className = '',
}) => {
  const gridClasses =
    'grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10 gap-x-2 gap-y-1 items-stretch';

  const availableRoles: ClubStaffMemberRole[] = useMemo(
    () =>
      arrayBuilder.filterDuplicated(
        staffMembersDailySessions.reduce(
          (acc, x) => acc.concat(x.staffMember.profile.roles),
          [] as ClubStaffMemberRole[],
        ),
      ),
    [staffMembersDailySessions],
  );

  return (
    <div className={clsx('grid gap-4', className)}>
      {staffMembersDailySessions.map(
        (
          { staffMember, days, availableOnAnySession, activeOnAnySession },
          staffMemberIndex,
        ) => (
          <div className="grid gap-2" key={staffMember._id}>
            {showStaffMember && (
              <ClubPlanningLightPanelViewPeriodDayStaffMember
                // staffMemberNameClassName="w-48"
                staffFirstNameBefore={planningConfig.staffFirstNameBefore}
                staffMember={staffMember}
                onClickEdit={undefined}
                date={days[0].date}
                activeToday={activeOnAnySession}
                availableOrActiveToday={availableOnAnySession}
              />
            )}
            <div
              key={staffMemberIndex}
              className={clsx(
                'w-full select-none text-gray-700 text-sm leading-4 grid gap-2',
              )}
            >
              {days.map(
                ({
                  date,
                  dayReference,
                  sessionsDetails,
                  activeToday,
                  availableOrActiveToday,
                  dayTotalSessionsCount,
                  dailyConfig,
                }) =>
                  sessionsDetails.length === 0 ? null : (
                    <div
                      key={dayReference}
                      className={clsx(
                        'w-full select-none text-gray-700 text-sm leading-4 grid gap-2',
                      )}
                    >
                      {showDate && (
                        <div className="font-medium text-app-primary">
                          {dateService
                            .formatUTC(date, 'dddd DD MMMM YYYY')
                            ?.toUpperCase()}
                        </div>
                      )}
                      <div className={clsx('', gridClasses)}>
                        {sessionsDetails.map(
                          (sessionDetails, sessionDetailsIndex) => (
                            <ClubPlanningLightPanelViewPeriodDayStaffMembersListSession
                              className="h-full"
                              key={sessionDetailsIndex}
                              dayTotalSessionsCount={dayTotalSessionsCount}
                              sessionDetails={sessionDetails}
                              sessionDetailsIndex={sessionDetailsIndex}
                              clickActions={clickActions}
                              availableRoles={availableRoles}
                              dailyConfig={dailyConfig}
                            />
                          ),
                        )}
                      </div>
                    </div>
                  ),
              )}
            </div>
          </div>
        ),
      )}
    </div>
  );
};
