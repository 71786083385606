import {
  ONLINE_BOOKING_STATES,
  OnlineBookingState,
} from '@mabadive/app-common-model';
import { onlineBookingStateFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form';

export function useOnlineBookingStatesOptions(): ValueLabel<
  OnlineBookingState,
  string
>[] {
  return useMemo(() => {
    return ONLINE_BOOKING_STATES.map((bookingState) => {
      const option: ValueLabel<OnlineBookingState, string> = {
        value: bookingState,
        label: onlineBookingStateFormatter
          .formatState(bookingState)
          ?.toUpperCase(),
      };
      return option;
    });
  }, []);
}
