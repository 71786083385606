import { ProStatsFetchResults } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import {
  DashboardReportComparisonContext,
  DashboardReportPageLocalState,
} from './useDashboardReportPageLocalState.hook';

export type DashboardReportComparisonDetails<S extends {} = {}> = {
  showPeriodRangeComparison: boolean;
  comparisonFetchResult: ProStatsFetchResults;
  sharedState?: [S, React.Dispatch<React.SetStateAction<S>>];
  localState: DashboardReportPageLocalState;
};

export type DashboardReportComparisonProps<S extends {} = {}> = {
  initialState?: S;
  comparisonContext: DashboardReportComparisonContext;
  render: (
    fetchResult: ProStatsFetchResults,
    comparisonDetails?: DashboardReportComparisonDetails<S>,
  ) => React.ReactElement;
  className?: string;
};

export const DashboardReportComparison = function <S extends {} = {}>({
  comparisonContext,
  initialState,
  render,
  className,
}: DashboardReportComparisonProps<S>) {
  const { localState, data } = comparisonContext;
  const showPeriodRangeComparison =
    localState.state.viewParameters.showPeriodRangeComparison;

  // state shared between 2 comparison components
  const sharedState = useState<S>(initialState ?? ({} as unknown as S));

  // const updateState = useCallback((s: S) => {
  //   //
  //   setState(s);
  //   console.log('xxx update state', s);
  // }, []);

  const resultsDetails: DashboardReportComparisonDetails<S> = useMemo(() => {
    return {
      showPeriodRangeComparison,
      comparisonFetchResult: data?.comparison?.data,
      sharedState,
      localState,
    };
  }, [
    showPeriodRangeComparison,
    data?.comparison?.data,
    sharedState,
    localState,
  ]);

  const comparisonResultsDetails: DashboardReportComparisonDetails<S> =
    useMemo(() => {
      return {
        showPeriodRangeComparison,
        comparisonFetchResult: data?.main?.data,
        sharedState,
        localState,
      };
    }, [showPeriodRangeComparison, data?.main?.data, sharedState, localState]);

  return (
    <div
      className={clsx(
        'w-full h-full justify-stretch items-stretch gap-4',
        showPeriodRangeComparison ? 'grid grid-cols-2' : 'flex',
        className,
      )}
    >
      {showPeriodRangeComparison && data?.comparison?.dataOrFake && (
        <div className={clsx('w-full', !data?.comparison.data && 'blur-sm')}>
          {render(data?.comparison?.dataOrFake, comparisonResultsDetails)}
        </div>
      )}
      {data?.main?.dataOrFake && (
        <div className={clsx('w-full', !data?.main.data && 'blur-sm')}>
          {render(data?.main?.dataOrFake, resultsDetails)}
        </div>
      )}
    </div>
  );
};
