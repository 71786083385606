/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { uiStore } from 'src/business/_core/store';
import {
  AppCompanyMessageTemplateEditDialogState,
  AppCompanyMessageTemplateEditFormModel,
} from './model';
import { AppCompanyMessageTemplateEditDialogLocalState } from './useAppCompanyMessageTemplateEditDialogLocalState.hook';

export function useAppCompanyMessageTemplateEditDialogSubmit({
  handleSubmit,
  localState,
  inputState,
}: Pick<
  UseFormReturn<AppCompanyMessageTemplateEditFormModel>,
  'handleSubmit'
> & {
  localState: AppCompanyMessageTemplateEditDialogLocalState;
  inputState: React.PropsWithChildren<AppCompanyMessageTemplateEditDialogState>;
}) {
  return useCallback(async () => {
    try {
      return handleSubmit(
        (formValue: AppCompanyMessageTemplateEditFormModel) => {
          
          const { onConfirm, closeDialog } = inputState;
          if (localState.hasFormChanges) {
            return onConfirm({
              formValue,
            });
          } else {
            return closeDialog();
          }
        },
        (err) => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
          throw err;
        },
      )();
    } catch (err) {
      // message already displayed
    }
  }, [handleSubmit, inputState, localState]);
}
