import {
  ClubParticipant,
  DiveCenterPrivateSettingsEquipment,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { useParticipantEquipmentsToDisplay } from './useParticipantEquipmentsToDisplay.hook';

export function CalendarParticipantCellEquipment({
  participant,
  className,
}: {
  participant: Pick<ClubParticipant, 'equipment'>;
  className?: string;
}) {
  const clubResume = useClubResume();
  const customerSettings = clubResume.clubSettings?.customer;

  const diveCenterResume = useDiveCenterResume();

  const equipmentsSettings: DiveCenterPrivateSettingsEquipment =
    diveCenterResume?.privateSettings?.equipment;

  const equipmentsToDisplay = useParticipantEquipmentsToDisplay({
    participant,
  });
  const { all, self, club } = equipmentsToDisplay;

  return !all.length ? null : (
    <div className={clsx('text-left flex gap-2', className)}>
      {((equipmentsSettings?.provider &&
        participant?.equipment?.provider === 'customer') ||
        self.length !== 0) && (
        <div className=" flex gap-0.5 text-xs text-slate-600 font-bold">
          {self.length === 0 || self.length === 3 ? (
            <AppIcons.diveEquipment.all className="w-4 h-4 text-slate-600" />
          ) : (
            self.map((equipment) => (
              <equipment.icon
                key={equipment.type}
                className="w-4 h-4 text-slate-600"
              />
            ))
          )}

          <div>
            <span>ÉQUIPÉ</span>
          </div>
        </div>
      )}
      {club.map((equipment) => (
        <div
          key={equipment.type}
          className=" flex gap-0.5 text-xs text-slate-600 font-bold"
        >
          {equipment.icon && (
            <equipment.icon className="w-4 h-4 text-slate-600" />
          )}
          <div>
            <span>{equipment.label}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
