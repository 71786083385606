/* eslint-disable @typescript-eslint/no-unused-vars */
import { WeekPlanning } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import { CalendarSessionWidthMode } from '../components/ClubPlanningLightSession/session-common';
import {
  ClubPlanningDayFull,
  ClubPlanningPanelSessionDisplayConfig,
} from '../model';
import { ClubPlanningAction } from '../useClubPlanningActions.hook';
import { useClubPlanningDayFull } from '../useClubPlanningDayFull';
import { ClubPlanningLightPanelViewPeriodMonthWeekDayResume } from './ClubPlanningLightPanelViewPeriodMonthWeekDayResume';
import { ClubPlanningLightPanelViewPeriodMonthState } from './useClubPlanningLightPanelViewPeriodMonthState.hook';

export const ClubPlanningLightPanelViewPeriodMonthWeek = ({
  localState,
  clubPlanningActions,
  context,
  sessionsDisplayConfigs = [],
  widthMode,
  weekPlanning,
  className,
}: {
  localState: ClubPlanningLightPanelViewPeriodMonthState;
  clubPlanningActions: ClubPlanningAction;
  context: ClubPlanningDisplayConfigurationBarContext;
  sessionsDisplayConfigs?: ClubPlanningPanelSessionDisplayConfig[];
  widthMode: CalendarSessionWidthMode;
  weekPlanning: WeekPlanning;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const {
    planningConfig,
    setPlanningConfig,
    diveSessionResumes,
    clubEvents,
    dailyConfigs,
    loadableContent,
    dialogsOld,
  } = localState;

  const days: ClubPlanningDayFull[] = useClubPlanningDayFull({
    diveSessionResumes,
    weekPlanning,
    diveCenterResume,
    showVirtualSessions: true,
    clubEvents,
    dailyConfigs,
    planningConfig,
  });

  return (
    <div
      className={clsx(
        'grid grid-cols-7 gap-px',
        'px-px bg-gray-200',
        className,
      )}
    >
      {days.map((day, iDay) => (
        <ClubPlanningLightPanelViewPeriodMonthWeekDayResume
          className="bg-white pb-4 lg:pb-8"
          key={iDay}
          day={day}
          localState={localState}
          clubPlanningActions={clubPlanningActions}
          widthMode={widthMode}
        />
      ))}
    </div>
  );
};
