import { Booking } from '@mabadive/app-common-model';
import { bookingStatusFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';

export const BookingStatusLabel = ({
  booking,
  fontSizeClass = 'text-xs',
}: {
  booking: Pick<Booking, 'active' | 'bookingStatus'>;
  fontSizeClass?: string;
}) => {
  return (
    <span
      className={clsx(
        'mr-2 sm:mr-4 md:mr-5 px-1 font-bold',
        fontSizeClass,
        booking.active
          ? booking.bookingStatus === 'confirmed'
            ? 'bg-app-blue text-white'
            : booking.bookingStatus === 'pending'
            ? 'bg-status-warn text-white'
            : 'bg-status-error text-white'
          : 'bg-gray-600 text-white',
      )}
    >
      {booking.active
        ? bookingStatusFormatter
            .formatStatus(booking.bookingStatus)
            ?.toUpperCase()
        : 'ARCHIVÉE'}
    </span>
  );
};
