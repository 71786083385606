/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubSettingsInquiryEditPagePanelLocalState } from '../ClubSettingsInquiryEditPagePanel/useClubSettingsInquiryEditPagePanelLocalState.hook';
import { ClubSettingsInquiryEditPageFormGeneral } from './ClubSettingsInquiryEditPageFormGeneral';
import { ClubSettingsInquiryEditPageFormSections } from './ClubSettingsInquiryEditPageFormSections';

export const ClubSettingsInquiryEditPageForm = ({
  localState,
  className,
}: {
  localState: ClubSettingsInquiryEditPagePanelLocalState;
  className?: string;
}) => {
  const { data } = localState;

  const form = data.form;

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [inquiryType, inquiryName] = useWatch({
    control,
    name: ['type', 'name'],
  });

  return (
    <div className={clsx('grid gap-y-8', className)}>
      <ClubSettingsInquiryEditPageFormGeneral
        localState={localState}
        form={form}
      />
      {inquiryType && (
        <ClubSettingsInquiryEditPageFormSections
          localState={localState}
          form={form}
        />
      )}
    </div>
  );
};
