/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppPageContainer,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import useRouter from 'use-react-router';
import { clubSettingsAccountUrlBuilder } from '../../clubSettingsAccountUrlBuilder.service';
import { ClubSettingsUserProfileCard } from './components';
import { useClubSettingsUserAccountsListPageLocalState } from './useClubSettingsUserAccountsListPageLocalState';
export const ClubSettingsUserAccountsListPage = () => {
  const { match } = useRouter();
  const autoFocus = useAutoFocus();

  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const clubResume = useClubResume();

  const {
    searchText,
    setSearchText,
    loadableContent,
    clubProfiles,
    userAccounts,
    filteredProfilesWithAccounts,
    refetchData,
  } = useClubSettingsUserAccountsListPageLocalState();

  const hasRoleEditUserAccount = useAppSecurityUserHasRole(
    'club-edit-settings-user-accounts',
  );
  const hasRoleEditUserProfile = useAppSecurityUserHasRole(
    'club-edit-settings-user-profiles',
  );
  return (
    <AppPageContainer className={'bg-gray-50'} {...loadableContent}>
      <AppBreadcrumbBar
        color={AppIconsSettings.account.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.account.main,
            label: 'Mon compte',
            url: '/club/settings',
          },
          {
            label: 'Comptes utilisateurs',
          },
        ]}
      />
      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <div className="flex flex-row gap-2">
          <input
            autoFocus={autoFocus}
            autoComplete="new-password"
            placeholder="Recherche par nom, prénom..."
            type="text"
            className="flex-grow w-max hover:border-app-blue rounded px-2"
            defaultValue={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          {hasRoleEditUserAccount && (
            <AppButton
              color="primary-outline-light"
              size="normal"
              icon={AppHeroIcons.actionAdd}
              onClick={() =>
                redirectTo(
                  clubSettingsAccountUrlBuilder.userAccounts.createUserAccount(),
                )
              }
            >
              UTILISATEUR
            </AppButton>
          )}
          {hasRoleEditUserProfile && (
            <AppButton
              color="primary-outline-light"
              size="normal"
              icon={AppHeroIcons.actionAdd}
              onClick={() =>
                redirectTo(
                  clubSettingsAccountUrlBuilder.userAccounts.createUserProfile(),
                )
              }
            >
              PROFIL
            </AppButton>
          )}
        </div>
        <ul className="mt-4 flex flex-col gap-4">
          {filteredProfilesWithAccounts.map((clubProfileWithAccounts, i) => (
            <ClubSettingsUserProfileCard
              key={clubProfileWithAccounts.clubProfile._id}
              as="li"
              clubProfileWithAccounts={clubProfileWithAccounts}
            />
          ))}
        </ul>
        <div className={globalClasses.pageBlock}>
          {operationInProgressMessage && (
            <LoadingProgressBar>
              {operationInProgressMessage}
            </LoadingProgressBar>
          )}
        </div>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
