import { DashboardPaymentsByPeriod } from '@mabadive/app-common-model';
import {
  numberFormatter,
  paymentMethodFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { ClubDashboardGraphqlQueryByIntervalCriteriaStepInterval } from 'src/pages/DA-dashboard/_core/model';
import { chartHelper } from 'src/pages/DA-dashboard/_core/services';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { useDashboardPaymentsByMethod } from '../../useDashboardPaymentsByMethod.hook';
import { EvolutionActivityPaymentsByDayBarChartAttribute } from '../model';

type ChartAttribute = EvolutionActivityPaymentsByDayBarChartAttribute;

export const evolutionActivityPaymentsByDayBarChartTooltipBuilder = { build };

function build({
  colors,
  stepInterval,
  className,
}: {
  colors: { [attr in ChartAttribute]: string };
  stepInterval: ClubDashboardGraphqlQueryByIntervalCriteriaStepInterval;
  className?: string;
}) {
  return ({
    active,
    payload: data,
    label,
  }: {
    active?: boolean;
    payload?: any[];
    label?: any;
  }) => {
    if (active && data && data.length) {
      const payload: DashboardPaymentsByPeriod = data[0].payload;
      const { paymentsValidatedTotalSum, paymentsPendingTotalSum } = payload;

      const mainCurrency = useAppCurrencyMain();

      const paymentsByMethod = useDashboardPaymentsByMethod({
        paymentsByMethod: payload?.paymentsByMethod,
      });

      return (
        <div
          className={clsx(
            'overflow-hidden rounded-lg bg-white text-gray-600 p-2 shadow px-4',
            className,
          )}
        >
          <p className="text-xl font-bold">
            {chartHelper.formatDatePeriod(payload.beginDate, {
              stepInterval,
              format: 'long',
            })}
          </p>
          <table className="mt-2 uppercase bg-gray-50 min-w-full border-2 border-gray-600 divide-y-2 divide-x-2 divide-gray-600 text-left">
            <thead>
              <tr>
                <th></th>
                <th className="px-3 font-bold bg-status-info text-white">
                  Validés
                </th>
                {paymentsPendingTotalSum > 0 && (
                  <th className="px-3 font-bold bg-status-warn text-white">
                    En attente
                  </th>
                )}
                <th className="px-3 font-bold bg-gray-400 text-white">Ratio</th>
              </tr>
            </thead>
            <tbody>
              {paymentsByMethod.map((paymentByMethod) => (
                <tr key={paymentByMethod.method}>
                  <th className="px-3 font-bold bg-gray-400 text-white">
                    {paymentMethodFormatter.formatPaymentMethod(
                      paymentByMethod.method,
                      {
                        format: 'long',
                      },
                    )}
                  </th>
                  <td className="px-3 font-bold text-gray-400 text-right">
                    <AppPriceLabel
                      className={
                        paymentByMethod.amountValidated === 0
                          ? 'text-gray-400 font-normal'
                          : ''
                      }
                      addSpacesToLargeNumbers={true}
                      amount={paymentByMethod.amountValidated}
                      mainCurrency={mainCurrency}
                    />
                  </td>
                  {paymentsPendingTotalSum > 0 && (
                    <td className="px-3 font-bold text-status-WARN text-right">
                      <AppPriceLabel
                        className={
                          paymentByMethod.amountPending === 0
                            ? 'text-gray-400 font-normal'
                            : 'text-status-warn'
                        }
                        addSpacesToLargeNumbers={true}
                        amount={paymentByMethod.amountPending}
                        mainCurrency={mainCurrency}
                      />
                    </td>
                  )}
                  <td className="px-3 font-bold text-gray-400 text-right">
                    {numberFormatter.percent(
                      paymentByMethod.amountPending +
                        paymentByMethod.amountValidated,
                      paymentsPendingTotalSum + paymentsValidatedTotalSum,
                    )}
                    %
                  </td>
                </tr>
              ))}

              <tr>
                <th className="px-3 font-bold bg-gray-600 text-white">
                  TOTAL:
                </th>
                <td className="px-3 font-bold text-gray-600 text-right">
                  <span
                    className="font-bold"
                    style={{ color: colors['paymentsValidatedTotalSum'] }}
                  >
                    <AppPriceLabel
                      className={
                        paymentsValidatedTotalSum === 0
                          ? 'text-gray-400 font-normal'
                          : ''
                      }
                      addSpacesToLargeNumbers={true}
                      amount={paymentsValidatedTotalSum}
                      mainCurrency={mainCurrency}
                    />
                  </span>
                </td>
                {paymentsPendingTotalSum > 0 && (
                  <td className="px-3 font-bold text-gray-600 text-right">
                    <span
                      className="font-bold"
                      style={{ color: colors['paymentsValidatedTotalSum'] }}
                    >
                      <AppPriceLabel
                        className={
                          paymentsPendingTotalSum === 0
                            ? 'text-gray-400 font-normal'
                            : 'text-status-warn'
                        }
                        addSpacesToLargeNumbers={true}
                        amount={paymentsPendingTotalSum}
                        mainCurrency={mainCurrency}
                      />
                    </span>
                  </td>
                )}
                <td className="px-3 font-bold text-gray-600 text-right"></td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };
}
