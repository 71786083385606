import React, { ComponentType } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { Observable } from 'rxjs';
import { AppAuth } from 'src/business/auth/services';
import { SettingsCategoryPageCheckAuthArgs } from 'src/pages/SE-settings/_core';
import { useAppRoute } from './AppRoute.facade';
import { AppRouteArgsAuth } from './AppRouteArgs.model';

export function AppRoute({
  path,
  exact,
  sensitive,
  strict,
  component,
  auth,
  checkAuth,
  redirectTo,
  redirectToUrl,
  navigationContext,
}: {
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
  component?: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  /** @deprecated use checkAuth */
  auth?: AppRouteArgsAuth;
  checkAuth?: (args: SettingsCategoryPageCheckAuthArgs) => boolean;
  redirectTo?: ({ appAuth }: { appAuth: AppAuth }) => Observable<string>;
  redirectToUrl?: string;
  navigationContext?: any;
}) {
  const { loaded, redirectUrl } = useAppRoute({
    auth,
    checkAuth,
    redirectTo,
    redirectToUrl,
  });

  return !loaded ? null : (
    <Route
      path={path}
      exact={exact}
      sensitive={sensitive}
      strict={strict}
      render={(props) =>
        redirectUrl ? (
          <Redirect
            to={{
              pathname: redirectUrl,
              // state: { from: props.location }
            }}
          />
        ) : (
          React.createElement(component, {
            navigationContext,
            ...props,
          })
        )
      }
    />
  );
}
