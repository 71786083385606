/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingDeposit } from '@mabadive/app-common-model';
import { UseFormSetValue } from 'react-hook-form';
import { DiverPurchasePaymentEditorPaymentChangesModel } from '../DiverPurchasePaymentEditorPaymentChangesModel.type';
import { DiverPurchasePaymentEditorFormModel } from '../components';

export const editPaymentSelectPurchasesPanelAutoFixer = {
  autoFixBookingDepositPayments,
};

function autoFixBookingDepositPayments({
  bookingDeposit,
  paymentChangesModel,
  setValue,
}: {
  bookingDeposit: BookingDeposit;
  paymentChangesModel: DiverPurchasePaymentEditorPaymentChangesModel;
  setValue: UseFormSetValue<DiverPurchasePaymentEditorFormModel>;
}) {
  let updatedTotalToPay = paymentChangesModel.totalToPay;

  updatedTotalToPay = autoFixBookingDepositPayments_forPackages(
    paymentChangesModel,
    updatedTotalToPay,
    bookingDeposit,
    setValue,
  );
  updatedTotalToPay = autoFixBookingDepositPayments_forDeposits(
    paymentChangesModel,
    updatedTotalToPay,
    bookingDeposit,
    setValue,
  );
  // setValue('amount', bookingDeposit.creditRemaining);
}
function autoFixBookingDepositPayments_forPackages(
  paymentChangesModel: DiverPurchasePaymentEditorPaymentChangesModel,
  updatedTotalToPay: number,
  bookingDeposit: BookingDeposit,
  setValue: UseFormSetValue<DiverPurchasePaymentEditorFormModel>,
) {
  for (
    let packageIndex = paymentChangesModel.packages.length - 1;
    packageIndex >= 0;
    packageIndex--
  ) {
    // on prend les packages dans l'ordre inverse (on retire l'éxédent par le bas)
    const pack = paymentChangesModel.packages[packageIndex];

    if (pack.selected && pack.updatedAmountForPackage > 0) {
      const overPrice = updatedTotalToPay - bookingDeposit.creditRemaining;
      if (overPrice >= 0) {
        if (
          updatedTotalToPay - pack.updatedAmountForPackage >
          bookingDeposit.creditRemaining
        ) {
          // unselect

          updatedTotalToPay -= pack.updatedAmountForPackage;
          setValue(`packages.${packageIndex}.selected`, false);
        } else {
          // update amount
          const newAmount = pack.updatedAmountForPackage - overPrice;
          updatedTotalToPay -= overPrice;

          setValue(`packages.${packageIndex}.amount`, newAmount, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }
      }
    }
    // updatedTotalToPay -= pack.selected
  }
  return updatedTotalToPay;
}
function autoFixBookingDepositPayments_forDeposits(
  paymentChangesModel: DiverPurchasePaymentEditorPaymentChangesModel,
  updatedTotalToPay: number,
  bookingDeposit: BookingDeposit,
  setValue: UseFormSetValue<DiverPurchasePaymentEditorFormModel>,
) {
  for (
    let bookingDepositIndex = paymentChangesModel.bookingDeposits.length - 1;
    bookingDepositIndex >= 0;
    bookingDepositIndex--
  ) {
    // on prend les bookingDeposits dans l'ordre inverse (on retire l'éxédent par le bas)
    const pack = paymentChangesModel.bookingDeposits[bookingDepositIndex];

    if (pack.selected && pack.updatedAmountForBookingDeposit > 0) {
      const overPrice = updatedTotalToPay - bookingDeposit.creditRemaining;
      if (overPrice >= 0) {
        if (
          updatedTotalToPay - pack.updatedAmountForBookingDeposit >
          bookingDeposit.creditRemaining
        ) {
          // unselect

          updatedTotalToPay -= pack.updatedAmountForBookingDeposit;
          setValue(`bookingDeposits.${bookingDepositIndex}.selected`, false);
        } else {
          // update amount
          const newAmount = pack.updatedAmountForBookingDeposit - overPrice;
          updatedTotalToPay -= overPrice;

          setValue(`bookingDeposits.${bookingDepositIndex}.amount`, newAmount, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }
      }
    }
    // updatedTotalToPay -= pack.selected
  }
  return updatedTotalToPay;
}
