/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveCenterDailyConfig,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
  DiveSessionResumeParticipantsByGroup,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
  diveSessionGroupBuilder,
  weekPlanningBuilder,
} from '@mabadive/app-common-services';
import { PopperPlacementType } from '@material-ui/core';
import React, { useMemo } from 'react';
import { DiveSessionResumeLoadBounds } from 'src/business/_core/data/store-repository/dive-sessions/model';
import { AppActionButton } from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIconsMaterial,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { diveSessionParticipantStatsBuilder } from 'src/business/club/data';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  AppHamburgerMenu,
  AppHamburgerMenuItem,
} from 'src/business/club/modules/_common/ui';
import {
  useFetchCalendarWeekDiveSessionResumes,
  useWeekPlanningBounds,
} from 'src/business/club/modules/club-planning/_components';
import { PlanningViewConfig } from 'src/business/club/modules/club-planning/_model';
import { ClubPlanningPanelSessionDisplayStyle } from '../../../model';
import {
  DiveSessionSimpleCardParticipantsGroupFakePanel,
  DiveSessionSimpleCardParticipantsGroupPanel,
} from '../session-simple-card';
import { DiveSessionSecuritySheetCardHeader } from './DiveSessionSecuritySheetCardHeader';
import { DiveSessionSecuritySheetCardStaffMembers } from './DiveSessionSecuritySheetCardStaffMembers';
import {
  DiveSessionSecuritySheetCardMenuActionContext,
  DiveSessionSecuritySheetCardMenuActionsDef,
  useDiveSessionSecuritySheetCardMenu,
} from './menu';

export const DiveSessionSecuritySheetCard = ({
  diveSession,
  dailyConfig,
  sessionCardMenuActions,
  display: displayConfig,
  selectionColor,
  onClickSession,
  onClickGroupDetails,
  onClickSessionOpenButton,
  onClickEditSessionStaffMembers,
  onClickParticipant,
  anchorLinkParticipant,
  onClickAddParticipant,
  planningConfig,
  hamburgerMenuPosition,
}: {
  diveSession: ClubPlanningLightSessionDef;
  dailyConfig: DiveCenterDailyConfig;
  sessionCardMenuActions?: DiveSessionSecuritySheetCardMenuActionsDef;
  display: {
    boats: boolean;
    tide: boolean;
    weather: boolean;
    comment: boolean;
    addParticipantButton1: boolean;
    addParticipantButton2: boolean;
    openSessionButton: boolean;
    sessionIndex?: { index: number; total: number };
    isExpandable: boolean;
    date?: boolean;
  };
  selectionColor?: ClubPlanningPanelSessionDisplayStyle;
  onClickSession?: (diveSession: ClubPlanningLightSessionDef) => void;
  onClickSessionOpenButton?: (diveSession: ClubPlanningLightSessionDef) => void;
  onClickEditSessionStaffMembers?: (
    diveSession: ClubPlanningLightSessionDef,
  ) => any;
  onClickParticipant?: (attrs: {
    participant: DiveSessionResumeParticipant;
    diveSession: ClubPlanningLightSessionDef;
  }) => void;
  anchorLinkParticipant: (participant: DiveSessionResumeParticipant) => string;
  onClickAddParticipant?: (diveSession: ClubPlanningLightSessionDef) => void;
  onClickGroupDetails?: (args: {
    group?: DiveSessionResumeGroup;
    diveSession: ClubPlanningLightSessionDef;
    participants: DiveSessionResumeParticipant[];
  }) => void;
  planningConfig: PlanningViewConfig;

  hamburgerMenuPosition: PopperPlacementType;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume?.clubReference;

  const staffMembers = diveCenterResume?.staffMembers;

  const { bookingSessionParticipants, participants, groups } = diveSession;

  const diveSessionParticipantsByGroup: DiveSessionResumeParticipantsByGroup =
    useMemo(
      () =>
        diveSessionGroupBuilder.buildDiveSessionParticipantsGroups({
          diveSession,
          groups,
          participants,
          staffMembers,
          ignoreCancelledParticipants: !planningConfig.showInactiveParticipants,
          ignoreGroupsWithoutParticipants: false,
        }),
      [
        diveSession,
        groups,
        participants,
        planningConfig.showInactiveParticipants,
        staffMembers,
      ],
    );

  const diveSessionParticipantsStats = useMemo(
    () =>
      diveSessionParticipantStatsBuilder.buildDiveSessionParticipantsStats({
        clubReference,
        session: diveSession,
        participants: diveSessionParticipantsByGroup.all,
        groups: diveSessionParticipantsByGroup.allGroups,
        staffMembers: diveCenterResume?.staffMembers,
        dailyConfigs: dailyConfig ? [dailyConfig] : [],
        clubSettings: diveCenterResume?.clubResume?.clubSettings,
      }),
    [
      clubReference,
      diveSession,
      diveSessionParticipantsByGroup.all,
      diveSessionParticipantsByGroup.allGroups,
      diveCenterResume?.staffMembers,
      diveCenterResume?.clubResume?.clubSettings,
      dailyConfig,
    ],
  );

  const display = useMemo(() => {
    const d: {
      boats: boolean;
      tide: boolean;
      weather: boolean;
      comment: boolean;
      addParticipantButton1: boolean;
      addParticipantButton2: boolean;
      openSessionButton: boolean;
      truncateText: boolean;
      sessionIndex: { index: number; total: number };
      isExpandable: boolean;
      date: boolean;
    } = {
      ...displayConfig,
      addParticipantButton1:
        onClickAddParticipant !== undefined
          ? displayConfig.addParticipantButton1
          : false,
      addParticipantButton2:
        onClickAddParticipant !== undefined
          ? displayConfig.addParticipantButton2
          : false,
      truncateText: false,
      sessionIndex: displayConfig.sessionIndex,
      isExpandable: displayConfig.isExpandable,
      date: displayConfig.date,
    };
    return d;
  }, [displayConfig, onClickAddParticipant]);

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(diveSession.time),
    [diveSession.time],
  );

  const weekPlanning = useMemo(
    () =>
      weekPlanningBuilder.buildWeekPlanning({
        focusDate: planningConfig.focusDate,
      }),
    [planningConfig.focusDate],
  );
  const criteria: DiveSessionResumeLoadBounds = useWeekPlanningBounds({
    weekPlanning,
  });
  const { content: diveSessionResumes, ...loadableContent } =
    useFetchCalendarWeekDiveSessionResumes({
      criteria,
      mode: 'local-only',
    });

  const menuContext = useMemo(() => {
    const context: DiveSessionSecuritySheetCardMenuActionContext = {
      diveSession: {
        ...diveSession,
      },
    };
    return context;
  }, [diveSession]);

  const hamburgerMenuItems = useDiveSessionSecuritySheetCardMenu({
    menuActions: sessionCardMenuActions,
    menuContext,
  });

  const bottomActions: AppHamburgerMenuItem[] = useMemo(() => {
    const actions: AppHamburgerMenuItem[] = hamburgerMenuItems.filter(
      (action) =>
        action &&
        !action.hidden &&
        [
          'configure-groups',
          'print-security-sheet',
          'export-daily-resume',
          'send-session-message',
          'configure-session',
          'massive-session-move-divers',
          'massive-session-copy-divers',
        ].includes(action.id),
    );

    return actions;
  }, [hamburgerMenuItems]);
  const isPast = useMemo(
    () =>
      !dateService.isTodayUTC(diveSession.time) &&
      dateService.isPastUTC(diveSession.time),
    [diveSession.time],
  );
  const isEditParticipantRole = useAppSecurityUserHasRole(
    'club-edit-participant',
  );

  return (
    <div
      className={`bg-white border mb-8
      ${diveSession.isVirtual ? 'border-dashed' : ''}
      ${
        selectionColor
          ? `border-app-${selectionColor} ring-1 ring-app-dark-${selectionColor}`
          : `border-day-period-${diveSession.timeDayPeriod}-dark`
      } 
      text-left`}
    >
      <DiveSessionSecuritySheetCardHeader
        planningConfig={planningConfig}
        session={diveSession}
        display={display}
        diveSessionParticipantsStats={diveSessionParticipantsStats}
        hamburgerMenuPosition={hamburgerMenuPosition}
        onClickSession={onClickSession}
        hamburgerMenuItems={hamburgerMenuItems}
        selectionColor={selectionColor}
      />
      {display.addParticipantButton1 &&
        isEditParticipantRole &&
        diveSessionParticipantsStats.groups.supervisedAll !== 0 && (
          <div
            className={
              'mb-1 mx-1 py-1 text-center border-2 border-blue-100 bg-blue-50 text-blue-200 hover:border-app-blue hover:bg-gray-100 hover:text-app-blue cursor-pointer'
            }
            onClick={() => onClickAddParticipant(diveSession)}
          >
            <AppIconsMaterial.add />
          </div>
        )}
      <div className="flex flex-col gap-4">
        {diveSessionParticipantsByGroup.byGroupExtended.map(
          ({ group, participants, type }, groupIndex) => (
            <DiveSessionSimpleCardParticipantsGroupPanel
              key={`group-${groupIndex}`}
              diveSession={diveSession}
              isPast={isPast}
              group={group}
              groupOdd={groupIndex % 2 === 0}
              settingsUIPlanning={planningConfig}
              participants={participants}
              onClickParticipant={(participant) =>
                onClickParticipant &&
                onClickParticipant({
                  participant,
                  diveSession,
                })
              }
              onClickGroupDetails={() =>
                !onClickGroupDetails
                  ? undefined
                  : onClickGroupDetails({
                      group,
                      participants,
                      diveSession,
                    })
              }
              anchorLinkParticipant={anchorLinkParticipant}
              widthMode={'large'}
            />
          ),
        )}
        {diveSessionParticipantsByGroup.assignedFreeInstructors?.length > 0 && (
          <>
            {' '}
            {diveSessionParticipantsByGroup.assignedFreeInstructors.map(
              (staffMember, staffMemberIndex) => (
                <DiveSessionSimpleCardParticipantsGroupFakePanel
                  key={staffMember._id}
                  staffMember={staffMember}
                  diveSession={diveSession}
                  isPast={isPast}
                  settingsUIPlanning={planningConfig}
                  widthMode={'large'}
                />
              ),
            )}
          </>
        )}
      </div>
      {(display.openSessionButton || display.addParticipantButton2) && (
        <div className="mt-2 w-full grid gap-2 p-2">
          {display.addParticipantButton2 && onClickAddParticipant && (
            <div
              className={
                'sm:col-span-2 lg:col-span-3 2xl:col-span-4 py-1 text-center border-2 border-blue-100 bg-blue-50 text-app-primary-light hover:border-app-blue hover:bg-gray-100 hover:text-app-primary-dark cursor-pointer'
              }
              onClick={() =>
                onClickAddParticipant && onClickAddParticipant(diveSession)
              }
            >
              <AppIconsMaterial.add />
              <span className="ml-1 font-bold uppercase">
                Plongeur{' '}
                {isPast ? (
                  <span className="ml-1 text-red-400 font-normal">
                    (plongée passée !)
                  </span>
                ) : diveSession.details?.isFull ? (
                  <span className="ml-1 text-red-400 font-normal uppercase">
                    (complet)
                  </span>
                ) : null}
              </span>
            </div>
          )}
          {display.openSessionButton && (
            <div
              className={
                'py-0.5 text-center border-2 border-red-100 bg-red-50 text-red-200 hover:border-red-500 hover:bg-gray-100 hover:text-red-500 cursor-pointer flex justify-center'
              }
              onClick={() =>
                onClickSessionOpenButton &&
                onClickSessionOpenButton(diveSession)
              }
            >
              <AppHeroIcons.actionOpen className="w-6" />
              <span className="text-blue-400 font-normal">
                Editer la session
              </span>
            </div>
          )}
          {bottomActions.map((action, i) => (
            <AppActionButton
              key={i}
              fullWidth={true}
              className=""
              color={'primary-outline-light'}
              action={action}
            />
          ))}
          {bottomActions.length < hamburgerMenuItems.length && (
            <AppHamburgerMenu
              className="w-full"
              buttonIcon={AppHeroIcons.actionMenu}
              buttonClassName={`w-full py-2 bg-day-period-${timeDayPeriod}-dark hover:bg-day-period-${timeDayPeriod}-dark2 text-gray-200 hover:text-white`}
              items={hamburgerMenuItems}
              // position={hamburgerMenuPosition}
              buttonLabel={'Menu'}
              buttonLabelClasses="uppercase"
            />
          )}
        </div>
      )}
      <DiveSessionSecuritySheetCardStaffMembers
        onClick={
          onClickEditSessionStaffMembers
            ? () => {
                onClickEditSessionStaffMembers(diveSession);
              }
            : undefined
        }
        diveSession={diveSession}
        dailyConfig={dailyConfig}
        staffFirstNameBefore={planningConfig.staffFirstNameBefore}
      />
    </div>
  );
};
