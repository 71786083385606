import {
  AppIconsDiveEquipment,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { SettingsCategory, SettingsCategoryPage } from '../_core';

export const SETTINGS_SECURITY_PAGE_USER_ACCOUNTS: SettingsCategoryPage = {
  id: 'user-account',
  label: 'Comptes utilisateurs',
  url: '/club/settings/account/user-accounts',
  keywords: [
    'droit',
    'accès',
    'fonctionnalité',
    'sécurité',
    'rôle',
    'profil',
    'moniteur',
  ],
  checkAuth: ({ az }) => az?.view?.settings?.userAccounts,
};

export const SETTINGS_ACCOUNT_PAGE_COMPANY_INFO: SettingsCategoryPage = {
  id: 'company-info',
  label: 'Coordonnées du centre',
  icon: AppIconsSettings.account.companyInfo,
  url: '/club/settings/account/company-info',
  roles: ['club-view-settings-global'],
};
export const SETTINGS_ACCOUNT_PAGE_SERVICES: SettingsCategoryPage = {
  id: 'services',
  label: 'Prestations et tarifs',
  icon: AppIconsSettings.account.services,
  url: '/club/settings/account/company-services',
  checkAuth: ({ az }) => az?.view?.settings?.offers,
};

// export const SETTINGS_SECURITY_PAGE_BILLING: SettingsCategoryPage = {
//   id: 'mabadive-offer',
//   label: 'Forfait mabadive',
//   url: '/club/settings/account/mabadive-offer',
//   keywords: ['forfait', 'factures', 'crédits'],
//   checkAuth: ({ az }) => az?.view?.settings?.mabadiveBilling,
//   icon: AppIconsSettings.account.mabadiveBilling,
// };
export const SETTINGS_DIVE_CENTER_PAGE_EQUIPMENTS: SettingsCategoryPage = {
  id: 'equipments',
  label: 'Équipements',
  icon: AppIconsDiveEquipment.all,
  url: '/club/settings/account/equipments',
  checkAuth: ({ az }) => az?.edit?.settings?.equipment,
};

export const SETTINGS_ACCOUNT: SettingsCategory = {
  id: 'security',
  label: 'Mon compte',
  color: AppIconsSettings.account.color,
  icon: AppIconsSettings.account.main,
  pages: [
    SETTINGS_ACCOUNT_PAGE_COMPANY_INFO,
    SETTINGS_ACCOUNT_PAGE_SERVICES,
    SETTINGS_SECURITY_PAGE_USER_ACCOUNTS,
    SETTINGS_DIVE_CENTER_PAGE_EQUIPMENTS,
  ],
};
