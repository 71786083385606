/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppCompanyMessageContext,
  AppMessageParamsRecipientType,
} from '@mabadive/app-common-model';
import React from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { useAppMessageParamsLegend } from 'src/pages/_components';

export const AppCompanyMessageTemplateParamsLegend = ({
  context,
  recipientType,
}: {
  context: AppCompanyMessageContext | 'none';
  recipientType: AppMessageParamsRecipientType;
}) => {
  const params = useAppMessageParamsLegend({
    context,
    recipientType,
  });

  return (
    <AppMessageLight className="bg-white">
      <div className="grid gap-2">
        Les paramètres suivants seront automatiquement remplacés au moment de
        l'envoi:
        <ul className="ml-8 list-disc">
          {Object.keys(params).map((x) => (
            <li key={x}>
              <b>{x}</b>: {params[x]}
            </li>
          ))}
        </ul>
      </div>
    </AppMessageLight>
  );
};
