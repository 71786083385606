import React, { useMemo } from 'react';

import { useWatch } from 'react-hook-form';
import {
  EcommerceProductEditorFormContent,
  EcommerceProductEditorFormSettings,
} from './form';
import { EcommerceProductEditorLocalState } from './useEcommerceProductEditorPanelLocalState.hook';

export const EcommerceProductEditorPanelCreateForm = ({
  localState,
}: {
  localState: EcommerceProductEditorLocalState;
}) => {
  const { state, actions } = localState;

  const [appBookletPage, productName] = useWatch({
    control: state.form.control,
    name: ['appBookletPage', 'product.name'],
  });
  const content = appBookletPage?.mainContent?.content;
  const product = useMemo(
    () => ({
      name: productName,
    }),
    [productName],
  );

  return (
    <div className={'flex flex-col gap-4 md:gap-6'}>
      <EcommerceProductEditorFormContent
        className="flex-grow"
        localState={localState}
      />
      <EcommerceProductEditorFormSettings localState={localState} />
      {/* <AppPhonePanel className="hidden md:flex" label={'APPERÇU'}>
        <div className="h-[600px] max-h-[90lvh bg-gray-100">
          <div className="m-4 app-card rounded-t-xl flex flex-col gap-4">
            <EcommerceProductPreviewContent
              displayWidth={'mobile'}
              product={product}
              appBookletPage={appBookletPage}
            >
              <div className="max-w-sm">
                {(content?.items ?? []).map((item, i) => (
                  <AppTranslationContentPanel key={i} item={item} />
                ))}
              </div>
            </EcommerceProductPreviewContent>
          </div>
        </div>
      </AppPhonePanel> */}
    </div>
  );
};
