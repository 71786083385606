import { DiveCenterResume } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import {
  AggregatedBookingSessionFull,
  BillingTabModel,
  BillingTabModelBuildContext,
  BillingTabModelCounts,
  BillingTabParticipantPurchase,
  BookingResumeParticipantForSession,
  DiverBookingPageAggregatedData,
  DiverBookingPageLoadedContentFocus,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
  SessionsCountByDiver,
} from '../../../models';
import { DiverBookingPageClubDiverLinkedData } from '../../01.loaded-content';
import { SessionsHistoryTabModelBuilderFilterCriteria } from '../sessions-history-tab';
import { billingTabModelBuilderFilter } from './billingTabModelBuilderFilter.service';
import { billingTabModelSorter } from './billingTabModelSorter.service';
import { realParticipantPurchasesBuilder } from './real';
import { virtualParticipantPurchasesBuilder } from './virtual';

export const billingTabModelBuilder = { buildBillingTabModel };

export type BillingTabModelBuilderFilterCriteria = {
  ignoreFutureNotPurchasedParticipants: boolean;
  ignoreVeryOldNotPurchasedParticipants: boolean;
  ignoreCancelledParticipants: boolean;
  ignoreOtherDiveCentersPurchasePackages: boolean;
  ignoreOtherDiveCentersNotPurchasedParticipants: boolean;
};

function buildBillingTabModel({
  diveCenterResume,
  aggregatedData,
  focus,
  criteria,
  linkedData,
}: {
  diveCenterResume: DiveCenterResume;
  aggregatedData: DiverBookingPageAggregatedData;
  focus: DiverBookingPageLoadedContentFocus;
  criteria: BillingTabModelBuilderFilterCriteria & {
    filteredDiversIds: string[];
    currentDiveCenterId: string;
  };
  linkedData: DiverBookingPageClubDiverLinkedData;
}): BillingTabModel {
  const { counts, participantPurchases } = buildParticipantPurchases({
    aggregatedData,
    focus,
    diveCenterResume,
    criteria,
    linkedData,
  });

  const { activeParticipantPurchases, inactiveParticipantPurchases } =
    participantPurchases.reduce(
      (acc, x) => {
        if (x.isArchived) {
          acc.inactiveParticipantPurchases.push(x);
        } else {
          acc.activeParticipantPurchases.push(x);
        }
        return acc;
      },
      {
        activeParticipantPurchases: [],
        inactiveParticipantPurchases: [],
      } as {
        activeParticipantPurchases: BillingTabParticipantPurchase[];
        inactiveParticipantPurchases: BillingTabParticipantPurchase[];
      },
    );

  const model: BillingTabModel = {
    counts,
    activeParticipantPurchases,
    inactiveParticipantPurchases,
    meta: {
      isUniqueMember: aggregatedData.divers?.length === 1,
      isSameLastName:
        new Set(aggregatedData.divers?.map((x) => x.lastName?.toLowerCase()))
          .size <= 1,
    },
  };

  return model;
}

function buildParticipantPurchases({
  aggregatedData,
  focus,
  diveCenterResume,
  criteria,
  linkedData,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  focus: DiverBookingPageLoadedContentFocus;
  diveCenterResume: DiveCenterResume;
  criteria: BillingTabModelBuilderFilterCriteria & {
    filteredDiversIds: string[];
    currentDiveCenterId: string;
  };
  linkedData: DiverBookingPageClubDiverLinkedData;
}): {
  counts: BillingTabModelCounts;
  participantPurchases: BillingTabParticipantPurchase[];
} {
  const counts: BillingTabModelCounts = {
    hiddenParticipants: 0,
    futureNotPurchasedParticipants: 0,
    veryOldParticipants: 0,
    cancelledParticipants: 0,
    otherDiveCentersParticipants: 0,
    hiddenPurchasePackages: 0,
    otherDiveCentersPurchasePackages: 0,
  };

  const sessionsCountsByDiver: {
    [diverId: string]: SessionsCountByDiver;
  } = buildSessionsCountsByParticipant({
    aggregatedData,
    visibleParticipants: aggregatedData.bookingParticipantsFull,
    criteria: { ignoreOtherDiveCenters: false },
  });

  if (aggregatedData?.purchasePackagesWithPayments && !!focus) {
    const realPurchasePackagesWithPayments =
      billingTabModelBuilderFilter.filterPurchasePackages({
        purchasePackagesWithPayments:
          aggregatedData.purchasePackagesWithPayments,
        criteria,
        counts,
        linkedData,
      });
    const bookingResumesLoaded = aggregatedData.bookingResumesLoaded;
    const bookingParticipantsFull = aggregatedData.bookingParticipantsFull;

    const remainingParticipants =
      billingTabModelBuilderFilter.filterParticipants({
        realPurchasePackages: realPurchasePackagesWithPayments.map(
          ({ purchasePackage }) => purchasePackage,
        ),
        bookingParticipantsFull,
        criteria,
        bookingResumesLoaded,
        counts,
        linkedData,
      });

    const buildContext: BillingTabModelBuildContext = {
      remainingParticipants,
      divers: aggregatedData.divers,
      realPurchasePackagesWithPayments,
    };

    const realParticipantPurchases: BillingTabParticipantPurchase[] =
      realParticipantPurchasesBuilder.buildAll({
        diveCenterResume,
        buildContext,
        focus,
        sessionsCountsByDiver,
      });

    const virtualParticipantPurchases =
      virtualParticipantPurchasesBuilder.buildAll({
        buildContext,
        realParticipantPurchases,
        focus,
        diveCenterResume,
        sessionsCountsByDiver,
      });

    const participantPurchases: BillingTabParticipantPurchase[] =
      realParticipantPurchases.concat(virtualParticipantPurchases);
    const sortedParticipantPurchases =
      billingTabModelSorter.sortParticipantPurchases(participantPurchases);

    let previous: BillingTabParticipantPurchase;
    for (const current of sortedParticipantPurchases) {
      if (!previous || previous.diver?._id !== current.diver?._id) {
        current.isLastDiverPurchase = true;
      }
      previous = current;
    }
    return {
      counts,
      participantPurchases: sortedParticipantPurchases,
    };
  }
  return {
    counts,
    participantPurchases: [],
  };
}

function buildSessionsCountsByParticipant({
  visibleParticipants,
  aggregatedData,
  criteria,
}: {
  visibleParticipants: PRO_BookingParticipantFull[];
  aggregatedData: DiverBookingPageAggregatedData;
  criteria: Pick<
    SessionsHistoryTabModelBuilderFilterCriteria,
    'ignoreOtherDiveCenters'
  >;
}): { [diverId: string]: SessionsCountByDiver } {
  // const { bookingMembersFull, bookingParticipantsFull, bookingSessionsFull } =
  //   bookingResume;

  const bookingSessionsFull = aggregatedData.bookingResumesLoaded.reduce(
    (acc, bookingResume) => acc.concat(bookingResume.bookingSessionsFull),
    [] as AggregatedBookingSessionFull[],
  );

  const bookingParticipantsFull = visibleParticipants;

  const bookingMembersFull = aggregatedData.bookingResumesLoaded.reduce(
    (acc, bookingResume) => acc.concat(bookingResume.bookingMembersFull),
    [] as PRO_BookingMemberFull_WithDocs[],
  );

  const today = dateService.getUTCDateWithoutTime(new Date());

  const bookingSessionsFullFiltered = bookingSessionsFull.filter((bs) => {
    if (criteria.ignoreOtherDiveCenters) {
      if (
        dateService.isBefore(bs.diveSession.time, today) ||
        dateService.isTodayUTC(bs.diveSession.time)
      ) {
        // past or present
        return true;
      }
      // future
      return false;
    }
    // else if (period === 'future') {
    //   if (!dateService.isBefore(bs.diveSession.time, today)) {
    //     return true;
    //   }
    // }

    return true;
  });

  const sessionsCountByParticipant = bookingSessionsFullFiltered.reduce(
    (acc, bookingSessionFull) => {
      const participants: BookingResumeParticipantForSession[] =
        bookingParticipantsFull
          .filter((p) => {
            return (
              p.bookingSession._id === bookingSessionFull.bookingSession._id
            );
          })
          .map((p) => {
            const bookingMemberFull = bookingMembersFull.find((m) => {
              return p.bookingMember._id === m.bookingMember._id;
            });
            const participant: BookingResumeParticipantForSession = {
              bookingMemberFull,
              bookingParticipantFull: p,
              bookingParticipantFullSameBooking: p,
              bookingParticipantFullAnyBooking: p,
              style: 'normal',
            };
            return participant;
          })
          .filter((x) => x.bookingMemberFull);

      if (participants.length > 0) {
        for (const p of participants) {
          if (
            p?.bookingParticipantFull?.bookingSessionParticipant
              ?.participantBookingStatus !== 'cancelled'
          ) {
            // on ignore les plongées annulées
            const diverId = p.bookingMemberFull?.diver?._id;
            let sessionsCountByDiver: SessionsCountByDiver = acc[diverId];
            if (!sessionsCountByDiver) {
              sessionsCountByDiver = {
                pastBilledExploSessionsCounts: 0,
                pastSessionsCounts: 0,
                futureSessionsCounts: 0,
                totalSessionsCounts: 0,
              };
              acc[diverId] = sessionsCountByDiver;
            }
            if (
              dateService.isBefore(
                bookingSessionFull.diveSession.time,
                today,
              ) ||
              dateService.isTodayUTC(bookingSessionFull.diveSession.time)
            ) {
              sessionsCountByDiver.pastSessionsCounts++;
              const diveMode =
                p?.bookingParticipantFull?.diveSessionParticipant?.diveMode;
              if (
                diveMode === 'autonomous' ||
                diveMode === 'supervised' ||
                diveMode === 'instructor' ||
                diveMode === 'autoSupervised'
              ) {
                // explo
                if (
                  p?.bookingParticipantFull?.bookingProductDive
                    ?.purchaseStatus === 'purchased'
                ) {
                  sessionsCountByDiver.pastBilledExploSessionsCounts++;
                }
              }
            } else {
              sessionsCountByDiver.futureSessionsCounts++;
            }
            sessionsCountByDiver.totalSessionsCounts++;
          }
        }
      }

      return acc;
    },
    {} as { [sessionsCountByDiver: string]: SessionsCountByDiver },
  );
  return sessionsCountByParticipant;
}
