/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubSettingsPlanning,
  ClubSettingsPlanningPeriodConfig,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import { dateServiceCore } from 'src/stories/services';
import { clubSettingsDiveCenterUrlBuilder } from '../../clubSettingsDiveCenterUrlBuilder.service';
import { clubSettingsPlanningAssistantFormInitialValueBuilder } from './ClubSettingsPlanningAssistantForm';
import { clubSettingsPlanningAssistantFormResultBuilder } from './clubSettingsPlanningAssistantFormResultBuilder.service';
import { ClubSettingsPlanningAssistantFormModel } from './model';

export function useClubSettingsPlanningAssistantLocalState({
  periodConfigId,
}: {
  periodConfigId: string;
}) {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;
  const redirectTo = useRedirect();

  const back = useCallback(() => {
    redirectTo(clubSettingsDiveCenterUrlBuilder.planning.view());
  }, [redirectTo]);

  const clubSettingsPlanning = diveCenterResume.settingsPlanning;

  const initialPeriodConfig: ClubSettingsPlanningPeriodConfig = useMemo(
    () =>
      clubSettingsPlanning.periodConfigs.find((x) => x._id === periodConfigId),
    [clubSettingsPlanning.periodConfigs, periodConfigId],
  );

  const initialFormValue = useMemo(
    () =>
      clubSettingsPlanningAssistantFormInitialValueBuilder.buildInitialFormValue(
        {
          diveCenterResume,
          initialPeriodConfig,
          mode: 'add-sessions',
          originalPeriodConfigId: undefined,
        },
      ),
    [diveCenterResume, initialPeriodConfig],
  );

  const form = useForm<ClubSettingsPlanningAssistantFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const submitForm = useCallback(() => {
    handleSubmit(
      async (formValue: ClubSettingsPlanningAssistantFormModel, event) => {
        console.log('xxx formValue:', formValue);

        const clubSettingsPlanning = diveCenterResume.settingsPlanning;

        const diveToursDef = formValue.assistant.diveToursDef
          .map((x) => ({
            ...x,
            dates: {
              ...x.dates,
              dailyTours: x.dates.dailyTours.filter((dt) =>
                dateServiceCore.isValidHoursMinutes(dt.session1.dayTime),
              ),
            },
          }))
          .filter(
            (x) =>
              x.dates.dailyTours.length > 0 && x.dates.isoWeekDays.length > 0,
          );
        console.log('xxx diveToursDef:', diveToursDef);

        if (diveToursDef.length > 0) {
          const updatedPeriodConfig: ClubSettingsPlanningPeriodConfig =
            clubSettingsPlanningAssistantFormResultBuilder.buildUpdatedPlanningPeriodConfig(
              {
                initialPeriodConfig,
                formValue: {
                  ...formValue,
                  // assistant: {
                  //   ...formValue.assistant,
                  //   diveToursDef,
                  // },
                },
              },
            );
          const updatedClubSettingsPlanning: ClubSettingsPlanning = {
            ...diveCenterResume.settingsPlanning,
            periodConfigs: diveCenterResume.settingsPlanning.periodConfigs.map(
              (c) =>
                c._id === updatedPeriodConfig._id ? updatedPeriodConfig : c,
            ),
          };
          const clubSettingsPlanningPatchOperations =
            jsonPatcher.compareObjects(
              diveCenterResume.settingsPlanning,
              updatedClubSettingsPlanning,
              {
                replaceDeleteByNullValue: true,
                attributesToReplaceFully: ['periodConfigs'],
              },
            );
          console.log(
            'xxx clubSettingsPlanningPatchOperations:',
            clubSettingsPlanningPatchOperations,
          );
          if (clubSettingsPlanningPatchOperations.length) {
            const clubSettingsPlanningPatch: AppEntityUpdatePatch = {
              pk: updatedClubSettingsPlanning._id,
              patchOperations: clubSettingsPlanningPatchOperations,
            };
            await clubMassiveUpdatorClient.update({
              clubSettingsPlanning: { updated: clubSettingsPlanningPatch },
            });
            back();
          } else {
            back();
          }
        } else {
          back();
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [
    back,
    diveCenterResume.settingsPlanning,
    handleSubmit,
    initialPeriodConfig,
  ]);

  return {
    submitForm,
    back,
    form,
    initialPeriodConfig,
  };
}

export type ClubSettingsPlanningAssistantLocalState = ReturnType<
  typeof useClubSettingsPlanningAssistantLocalState
>;
