/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';

export const ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroupRow = ({
  label,
  participantsCount,
  showWarning,
  diveModeColor,
}: {
  label: string;
  participantsCount: number;
  showWarning: boolean;
  diveModeColor: string;
}) => {
  return (
    <div className="flex gap-px">
      <div
        className={clsx(
          'flex justify-between items-center text-white',
          // " min-w-[20px] sm:w-[40px] md:w-[60px]",
          'min-w-[50%] md:min-w-[50px]',
          'px-px sm:px-1',
          !diveModeColor && 'bg-gray-400',
          label?.length > 3
            ? 'text-app-xxs2 sm:text-xs md:text-sm'
            : label?.length > 2
            ? 'text-app-xxs sm:text-xs md:text-sm'
            : '',
        )}
        style={diveModeColor ? { backgroundColor: diveModeColor } : undefined}
      >
        <span className="">{label ?? '?'}</span>
      </div>
      <div
        className={clsx(
          'flex-grow px-px sm:pr-1 text-center md:text-left font-medium text-gray-600 border-l-2 sm:border-l-4',
          showWarning
            ? 'border-red-400 sm:border-red-200'
            : 'border-transparent',
        )}
      >
        <span>
          x<span className="">{participantsCount}</span>
        </span>
      </div>
    </div>
  );
};
