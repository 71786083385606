/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { AppLoaderSpinner } from 'src/business/_core/modules/layout';
import { AppMessageLight } from 'src/business/_core/modules/layout/components/_tailwind';
import { ClubPlanningParticipantsConfigDialogForm } from 'src/business/club/modules/club-planning-light/ClubPlanningParticipantsConfigDialog/ClubPlanningParticipantsConfigDialogForm';
import { ClubPlanningParticipantsConfigDialogMonthViewForm } from 'src/business/club/modules/club-planning-light/ClubPlanningParticipantsConfigDialog/ClubPlanningParticipantsConfigDialogMonthViewForm';
import { ClubSettingsAccountDisplayPageLocalState } from './useClubSettingsAccountDisplayPageLocalState.hook';

export const ClubSettingsAccountDisplayEditCardPlanning = ({
  localState,
  className,
}: {
  localState: ClubSettingsAccountDisplayPageLocalState;
  className?: string;
}) => {
  const {
    planningConfig,
    setPlanningConfig,
    settingsUiPlanning,
    setSettingsUiPlanning,
    operationInProgressUiPlanning,
    operationInProgressFullScreen,
    persistSettingsUiPlanning,
    persistSettingsUiFullScreenOnTouchDevices,
    fullScreenOnTouchDevices,
    setFullScreenOnTouchDevices,
  } = localState;
  return (
    <div className={clsx('app-card app-p-content', className)}>
      <h2 className="text-left uppercase text-base md:text-lg leading-6 mt-4 font-medium text-gray-800 flex justify-between">
        <>Configuration de l'affichage du planning </>
        {operationInProgressUiPlanning && (
          <AppLoaderSpinner className="ml-2 inline-block w-6 h-6" />
        )}
      </h2>
      <AppMessageLight className={'my-4 hidden md:flex'} type="info">
        <p>
          Ces paramètres impactent la configuration par défaut de tous les
          utilisateurs.
        </p>
      </AppMessageLight>
      <ClubPlanningParticipantsConfigDialogForm
        context="club-settings"
        settingsUiPlanning={settingsUiPlanning}
        setSettingsUiPlanning={async (settingsUiPlanning) => {
          setSettingsUiPlanning(settingsUiPlanning);
          setPlanningConfig(
            {
              ...planningConfig,
              ...settingsUiPlanning,
            },
            {
              origin:
                '[ClubSettingsAccountDisplayEditCardPlanning] setSettingsUiPlanning',
            },
          );
          await persistSettingsUiPlanning(settingsUiPlanning);
        }}
      />
      <ClubPlanningParticipantsConfigDialogMonthViewForm
        context="device-settings"
        settingsUiPlanning={planningConfig}
        setSettingsUiPlanning={setPlanningConfig}
      />
    </div>
  );
};
