/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { FieldArrayWithId, UseFormReturn, useWatch } from 'react-hook-form';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import clsx from 'clsx';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { ClubSettingsProductOffersFormModel } from '../../_model';

export const ClubSettingsProductOffersFormOffer_Explo_Dives_Count = ({
  form,
  offerField,
  offerFieldIndex,
  showDetails,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
  showDetails: boolean;
}) => {
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const [divePriceType, divesCountInfinite, divesCountMax] = useWatch({
    control,
    name: [
      `offers.${i}.productPackage.diveAttributes.divePriceType`,
      `offers.${i}.productPackage.diveAttributes.divesCountInfinite`,
      `offers.${i}.productPackage.diveAttributes.divesCountMax`,
    ],
  });

  return (
    <>
      <AppFormControlRHF_Deprecated
        className={clsx('', divesCountInfinite && 'invisible')}
        control={control}
        label={'Quantité'}
        required={true}
        disabled={divePriceType !== 'package'}
        name={`offers.${i}.productPackage.diveAttributes.divesCount`}
        renderComponent={(props) => (
          <AppInputRHF {...props} type="number" fullWidth />
        )}
      />
      {(divesCountMax > 0 ||
        (divePriceType === 'package' && showDetails && isSuperAdmin)) && (
        <AppFormControlRHF_Deprecated
          visibility="super-admin"
          className={clsx('w-full', divesCountInfinite && 'invisible')}
          control={control}
          label={'Quantité max'}
          name={`offers.${i}.productPackage.diveAttributes.divesCountMax`}
          renderComponent={(props) => (
            <AppInputRHF {...props} type="number" fullWidth />
          )}
        />
      )}
    </>
  );
};
