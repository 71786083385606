import { LoadableContentActionStatus, LoadableContentState } from '../loadable';

export const appLoaderMerger = {
  mergeLoadableContents,
  mergeLastActionStatus,
  mergeContentState,
};

function mergeLoadableContents(
  ...states: {
    contentState?: LoadableContentState;
    lastActionStatus?: LoadableContentActionStatus;
    startDate?: Date;
    endDate?: Date;
  }[]
): {
  contentState?: LoadableContentState;
  lastActionStatus?: LoadableContentActionStatus;
  startDate?: Date;
  endDate?: Date;
  durationInMs?: number;
} {
  const contentState = mergeContentState(...states.map((x) => x.contentState));
  const lastActionStatus = mergeLastActionStatus(
    ...states.map((x) => x.lastActionStatus),
  );

  const startDate = Math.min(
    ...states.map((x) => x.startDate?.getTime()).filter((x) => !!x),
  );
  const endDate = Math.max(
    ...states.map((x) => x.endDate?.getTime()).filter((x) => !!x),
  );
  const durationInMs = startDate && endDate ? endDate - startDate : undefined;
  return {
    contentState,
    lastActionStatus,
    startDate: startDate ? new Date(startDate) : undefined,
    endDate: endDate ? new Date(endDate) : undefined,
    durationInMs,
  };
}

function mergeLastActionStatus(
  ...actions: LoadableContentActionStatus[]
): LoadableContentActionStatus {
  return actions.reduce((a, b) => {
    return a === 'error' || b === 'error'
      ? 'error'
      : a === 'in-progress' || b === 'in-progress'
      ? 'in-progress'
      : 'success';
  }, 'success' as LoadableContentActionStatus);
}

function mergeContentState(
  ...states: LoadableContentState[]
): LoadableContentState {
  return states.reduce((a, b) => {
    return a === 'none' || b === 'none'
      ? 'none'
      : a === 'partial' || b === 'partial'
      ? 'partial'
      : 'full';
  }, 'full' as LoadableContentState);
}
