import {
  bookingMemberFullSorter,
  dataSorter,
  dateService,
} from '@mabadive/app-common-services';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSession,
  BookingResumeParticipantsBySession,
  BookingTabModel,
  BookingTabModelMeta,
  DiverBookingPageAggregatedData,
  DiverBookingPageLoadedContent,
  DiverBookingPageLoadedContentFocus,
  PRO_BookingParticipantFull,
  PRO_BookingResume,
} from '../../../models';
import { proBookingSorter } from '../../04.aggregated-state/bookingSorter.service';

export const bookingTabModelBuilder = { buildBookingTabModel };

function buildBookingTabModel({
  loadedContent,
  aggregatedData,
  focus,
  diveCenterId,
  includeArchivedBookings,
}: {
  loadedContent: DiverBookingPageLoadedContent;
  aggregatedData: DiverBookingPageAggregatedData;
  focus: DiverBookingPageLoadedContentFocus;
  diveCenterId: string;
  includeArchivedBookings: boolean;
}): BookingTabModel[] {
  if (!aggregatedData?.bookingResumesVisible || !focus) {
    return [];
  }

  const tabModels: BookingTabModel[] = aggregatedData?.bookingResumesVisible
    .filter((x) => includeArchivedBookings || x.booking?.active)
    .map((aggregatedBooking) => {
      return buildOne({
        aggregatedBooking,
        bookingParticipantsFullAllBookings:
          aggregatedData.bookingParticipantsFull,
        loadedContent,
        focus,
      });
    });

  // NOTE: tri cohérent avec les autres tris
  return proBookingSorter
    .sortBookingTabModels(tabModels, {
      diveCenterId,
      focusDiverId: loadedContent.focus?.clubDiver?._id,
    })
    .map((tabModel, bookingIndex) => ({
      ...tabModel,
      bookingNumber: tabModels.length - bookingIndex,
    }));
}
function buildOne({
  loadedContent,
  aggregatedBooking,
  bookingParticipantsFullAllBookings,
  focus,
}: {
  loadedContent: DiverBookingPageLoadedContent;
  bookingParticipantsFullAllBookings: PRO_BookingParticipantFull[];
  aggregatedBooking: PRO_BookingResume;
  focus: DiverBookingPageLoadedContentFocus;
}): BookingTabModel {
  if (!aggregatedBooking) {
    return undefined;
  }

  const datesRange: { minDate?: Date; maxDate?: Date } =
    aggregatedBooking.bookingSessionsFull.reduce((acc, bs) => {
      const date = dateService.getUTCDateSetTime(bs.diveSession.time);
      if (!acc.minDate || dateService.isBefore(date, acc.minDate)) {
        acc.minDate = date;
      }
      if (!acc.maxDate || dateService.isBefore(acc.maxDate, date)) {
        acc.maxDate = date;
      }
      return acc;
    }, {} as { minDate?: Date; maxDate?: Date });

  const isUniqueMember = aggregatedBooking.bookingMembersFull?.length === 1;
  const isSameLastName =
    new Set(
      aggregatedBooking.bookingMembersFull?.map((x) =>
        x.diver.lastName?.toLowerCase(),
      ),
    ).size <= 1;

  const participantsBySessions = buildBookingResumeParticipantsBySessions({
    loadedContent,
    aggregatedBooking,
    bookingParticipantsFullAllBookings,
    focus,
    futureOnly: false,
    sortAsc: false,
  });

  const today = dateService.getUTCDateWithoutTime(new Date());

  const { participantsBySessionsPast, participantsBySessionsFuture } =
    participantsBySessions.reduce(
      (acc, x) => {
        if (
          dateService.isBefore(today, x.bookingSessionFull.diveSession.time)
        ) {
          acc.participantsBySessionsFuture.push(x);
        } else {
          acc.participantsBySessionsPast.push(x);
        }
        return acc;
      },
      {
        participantsBySessionsPast: [],
        participantsBySessionsFuture: [],
      } as {
        participantsBySessionsPast: BookingResumeParticipantsBySession[];
        participantsBySessionsFuture: BookingResumeParticipantsBySession[];
      },
    );

  const futureSessionsCount = participantsBySessionsFuture.length;

  const meta: BookingTabModelMeta = {
    isUniqueMember,
    isSameLastName,
    datesRange,
    futureSessionsCount,
  };

  const bookingTabModel: BookingTabModel = {
    bookingNumber: 0, // temporary until bookingTabModels are sorted
    bookingId: aggregatedBooking.booking._id, // shortcut
    bookingDiveCenterId: aggregatedBooking.booking.diveCenterId, // shortcut
    isSharedBooking: aggregatedBooking.booking.isSharedBooking, // shortcut
    aggregatedBooking,
    participantsBySessions,
    participantsBySessionsPast,
    participantsBySessionsFuture,
    meta,
  };

  return {
    ...bookingTabModel,
  };
}
function sortSessions(
  all: AggregatedBookingSessionFull[],
  { asc }: { asc: boolean },
): AggregatedBookingSessionFull[] {
  return dataSorter.sortMultiple(all, {
    getSortAttributes: (x) => [
      {
        value: x?.diveSession?.time,
        type: 'default',
        asc: true,
      },
      {
        value: x?.bookingSession?._id,
        type: 'full-text',
      },
    ],
    asc,
  });
}

export function buildBookingResumeParticipantsBySessions({
  loadedContent,
  aggregatedBooking,
  bookingParticipantsFullAllBookings,
  futureOnly,
  bookingSessionsIds,
  focus,
  sortAsc,
}: {
  loadedContent: DiverBookingPageLoadedContent;
  bookingParticipantsFullAllBookings: PRO_BookingParticipantFull[];
  aggregatedBooking: Pick<
    PRO_BookingResume,
    | 'bookingMembersFull'
    | 'bookingParticipantsFull'
    | 'bookingSessionsFull'
    | 'booking'
  >;
  futureOnly: boolean;
  bookingSessionsIds?: string[];
  focus?: DiverBookingPageLoadedContentFocus;
  sortAsc: boolean;
}): BookingResumeParticipantsBySession[] {
  const {
    booking,
    bookingMembersFull: bookingMembersFullNotSorted,
    bookingParticipantsFull,
    bookingSessionsFull,
  } = aggregatedBooking;

  const today = dateService.getUTCDateWithoutTime(new Date());

  const bookingSessionsFullFiltered = bookingSessionsFull.filter((bs) => {
    if (futureOnly) {
      if (!dateService.isBefore(today, bs.diveSession.time)) {
        return false;
      }
    }
    if (bookingSessionsIds) {
      if (!bookingSessionsIds.includes(bs.bookingSession._id)) {
        return false;
      }
    }
    return true;
  });

  const bookingSessionsFullSorted = sortSessions(bookingSessionsFullFiltered, {
    asc: sortAsc,
  });

  const bookingMembersFullSortedTmp = bookingMemberFullSorter.sort(
    bookingMembersFullNotSorted,
    {
      bookingContactDiverId: booking?.bookingContactDiverId,
    },
  );

  const bookingMembersFull = dataSorter.sortMultiple(
    bookingMembersFullSortedTmp,
    {
      getSortAttributes: (bm, initialSortIndex) => {
        // bookingSessionsFullSorted
        // TODO, à refaire en se basant juste sur les données chargées, sinon ça fout le bazard quand on clic
        // const nextSessionIndex = bookingSessionsFullSorted.findIndex(
        //   (bookingSessionFull) => {
        //     const bookingParticipantFull = bookingParticipantsFull.find(
        //       (p) =>
        //         p.bookingSession._id ===
        //           bookingSessionFull.bookingSession._id &&
        //         p.bookingMember._id === bm.bookingMember._id,
        //     );
        //     return !!bookingParticipantFull;
        //   },
        // );
        return [
          // {
          //   // on classe les participants par date de prochaine plongée
          //   value:
          //     bm.diver._id === booking.bookingContactDiverId
          //       ? 0 // sauf le responsable, que l'on garde toujours en premier
          //       : nextSessionIndex === -1
          //       ? bookingSessionsFullSorted.length
          //       : nextSessionIndex,
          // },
          {
            value: initialSortIndex, // on préserve l'ordre initial en second choix
          },
        ];
      },
    },
  );

  const bookingResumeParticipantsBySessions: BookingResumeParticipantsBySession[] =
    bookingSessionsFullSorted.map((bookingSessionFull) => {
      const participants: BookingResumeParticipantForSession[] =
        bookingMembersFull.map((bookingMemberFull) => {
          const bookingParticipantFullSameBooking =
            bookingParticipantsFull.find(
              (p) =>
                p.bookingSession._id ===
                  bookingSessionFull.bookingSession._id &&
                p.bookingMember._id === bookingMemberFull.bookingMember._id,
            );
          const isFocusDiver =
            focus?.clubDiver?._id === bookingMemberFull.diver._id;
          const isFocusSession =
            focus?.diveSession?.reference ===
            bookingSessionFull.diveSession.reference;
          const style =
            isFocusDiver && isFocusSession
              ? 'highlight-strong'
              : isFocusDiver || isFocusSession
              ? 'highlight-light'
              : 'normal';
          const bookingParticipantFullAnyBooking =
            bookingParticipantFullSameBooking ??
            bookingParticipantsFullAllBookings.find(
              (x) =>
                x.diver._id === bookingMemberFull.diver._id &&
                x.diveSession.reference ===
                  bookingSessionFull.diveSession.reference,
            );
          const participant: BookingResumeParticipantForSession = {
            bookingMemberFull,
            bookingParticipantFull: bookingParticipantFullSameBooking,
            bookingParticipantFullSameBooking,
            bookingParticipantFullAnyBooking,
            style,
          };
          return participant;
        });

      const bookingResumeParticipantsBySession: BookingResumeParticipantsBySession =
        {
          bookingSessionFull,
          participants,
        };

      return bookingResumeParticipantsBySession;
    });
  return bookingResumeParticipantsBySessions;
}
