/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubProductPackageOfferView } from '@mabadive/app-common-model';
import {
  ClubProductPackageNameDetailsFormatProps,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ClubOfferNameDetailsLabel } from 'src/business/club/modules/club-diver-participant/components';
import { useResidentTypesOptions } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms';
import { AppInputBooleanSwitchRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { ClubOfferCustomNameForm } from '../ClubOfferDiveEditor/ClubOfferCustomNameForm';
import { ClubOfferDiveMultiEditorFormModel } from './ClubOfferDiveMultiEditorFormModel.type';

export const ClubOfferDiveMultiEditorRow = ({
  form,
  offerIndex: i,
  productPackageOffer,
  className,
}: {
  form: UseFormReturn<ClubOfferDiveMultiEditorFormModel, object>;
  offerIndex: number;
  productPackageOffer: ClubProductPackageOfferView;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = useClubSettings();

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const [divesCountInfinite, divesCount, namingConfiguration] = useWatch({
    control,
    name: [
      `offers.${i}.divesCountInfinite` as any,
      `offers.${i}.divesCount`,
      `offers.${i}.namingConfiguration`,
    ],
  });

  const residentTypesOptions = useResidentTypesOptions();

  const nameDetails = useMemo(() => {
    const productPackage: ClubProductPackageNameDetailsFormatProps = {
      label: productPackageOffer.productPackage?.label,
      namingConfiguration,
      diveAttributes: productPackageOffer.productPackage?.diveAttributes,
      trainingAttributes:
        productPackageOffer.productPackage?.trainingAttributes,
      type: 'dive',
    };

    const nameDetails = productPackageFormatter.formatNameDetails(
      productPackage,
      {
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );
    return nameDetails;
  }, [
    clubSettings?.ui?.diveMode,
    namingConfiguration,
    productPackageOffer.productPackage?.diveAttributes,
    productPackageOffer.productPackage?.label,
    productPackageOffer.productPackage?.trainingAttributes,
    publicSettings,
  ]);

  return (
    <div className={className}>
      <div>
        <ClubOfferNameDetailsLabel
          className="font-bold text-app-blue"
          nameDetails={nameDetails}
        />
        {
          productPackageOffer.productPackage.productAttributes
            .defaultOrganizationReference
        }
      </div>
      <div className="flex flex-wrap gap-2">
        <AppFormControlRHF_Deprecated
          className="w-24"
          control={control}
          label="Tarif"
          name={`offers.${i}.price` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        {generalSettings?.billing?.thirdPartyCollectEnabled && (
          <AppFormControlRHF_Deprecated
            className="w-24"
            control={control}
            label="Tarif (part tiers)"
            name={`offers.${i}.thirdPartyCollectPrice` as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} type="number" />
            )}
          />
        )}
        <AppFormControlRHF_Deprecated
          className="w-24"
          control={control}
          label="Age mini"
          name={`offers.${i}.minAge` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          className="w-24"
          control={control}
          label="Age maxi"
          name={`offers.${i}.maxAge` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        {generalSettings.billing?.showDepth && (
          <>
            <AppFormControlRHF_Deprecated
              className="w-24"
              control={control}
              label="Prof. mini (>)"
              name={`offers.${i}.minDepth` as any}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" />
              )}
            />
            <AppFormControlRHF_Deprecated
              className="w-24"
              control={control}
              label="Prof. maxi (<=)"
              name={`offers.${i}.maxDepth` as any}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" />
              )}
            />
          </>
        )}
        {generalSettings.billing?.showDistance && (
          <>
            <AppFormControlRHF_Deprecated
              className="w-24"
              control={control}
              label="Distance mini"
              name={`offers.${i}.minDistance` as any}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" />
              )}
            />
            <AppFormControlRHF_Deprecated
              className="w-24"
              control={control}
              label="Distance maxi"
              name={`offers.${i}.maxDistance` as any}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" />
              )}
            />
          </>
        )}
        <AppFormControlRHF_Deprecated
          className="w-24"
          control={control}
          label="Min pl. tarif"
          name={`offers.${i}.minDivesCount` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          className="w-24"
          control={control}
          label="Max pl. tarif"
          name={`offers.${i}.maxDivesCount` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          className="w-24"
          control={control}
          label="Validité (jours)"
          name={`offers.${i}.validityPeriodInDays` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          className={clsx('w-24', divesCountInfinite && 'invisible')}
          control={control}
          label="Nb plongées"
          name={`offers.${i}.divesCount` as any}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
        <AppFormControlRHF_Deprecated
          className="w-28"
          label="Résidence"
          control={control}
          name={`offers.${i}.salesCriteria.residentType`}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              className={'w-full'}
              theme="material-ui"
              color="theme"
              {...props}
              options={residentTypesOptions}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-24"
          control={control}
          label="PASS illimité"
          name={`offers.${i}.divesCountInfinite` as any}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        {(divesCountInfinite || divesCount > 1) && (
          <AppFormControlRHF_Deprecated
            className="w-24"
            control={control}
            label="Partagé (nb pers)"
            name={`offers.${i}.sharedOfferMaxPersonsCount` as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} type="number" />
            )}
          />
        )}
      </div>
      <div className="">
        <AppFormControlRHF_Deprecated
          className="w-full"
          control={control}
          name={`offers.${i}.comment` as any}
          renderComponent={(props) => (
            <AppInputRHF
              {...props}
              placeholder="Commentaire"
              type="text"
              className="w-full"
              multiline={true}
              rowsMin={2}
            />
          )}
        />
      </div>
      <ClubOfferCustomNameForm
        formPathPrefix={`offers.${i}.`}
        form={form as any}
        nameDetails={nameDetails}
      />
    </div>
  );
};
