/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import {
  useAppCurrency,
  useAppCurrencyMain,
} from 'src/pages/_components/options';
import { DiverPurchasePaymentEditorPaymentChangesModel } from '../DiverPurchasePaymentEditorPaymentChangesModel.type';
import { DiverPurchasePaymentEditorFormModel } from './DiverPurchasePaymentEditorForm';

export const DiverPurchasePaymentEditorTotalToPay: FC<{
  form: UseFormReturn<DiverPurchasePaymentEditorFormModel>;
  paymentChangesModel: DiverPurchasePaymentEditorPaymentChangesModel;
}> = ({ form, paymentChangesModel }) => {
  const mainCurrency = useAppCurrencyMain();

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const [usedCurrencyIsoCode, usedCurrencyAmount] = useWatch({
    control,
    name: ['usedCurrencyIsoCode', 'usedCurrencyAmount'],
  });

  const billingCurrency = useAppCurrencyMain();
  const usedCurrency = useAppCurrency(usedCurrencyIsoCode);

  return (
    <div className="bg-gray-100 flex gap-2 items-center">
      <h2 className="text-left text-lg leading-6 font-medium text-gray-800">
        TOTAL À PAYER:
      </h2>
      <h2 className="px-2 py-1 sm:px-4 sm:py-2 text-left text-lg leading-6 font-medium text-status-active">
        <AppPriceLabel
          amount={paymentChangesModel.totalToPay}
          mainCurrency={mainCurrency}
        />
        {usedCurrency?.isoCode &&
          billingCurrency?.isoCode !== usedCurrency?.isoCode && (
            <>
              <span className="text-gray-600">{' ➔ '}</span>
              <AppPriceLabel
                className="text-app-primary"
                amount={usedCurrencyAmount}
                mainCurrency={usedCurrency}
              />
            </>
          )}
      </h2>
    </div>
  );
};
