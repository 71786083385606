import { AppCompanyMessageTarget } from '@mabadive/app-common-model';
import { diverLinkParametersToReplaceRenderer } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { AppMessagePreviewEmail } from './AppMessagePreviewEmail';
import { AppMessagePreviewHeaderBar } from './AppMessagePreviewHeaderBar';
import { AppMessagePreviewSMS } from './AppMessagePreviewSMS';
import { AppMessagePreviewWhatsapp } from './AppMessagePreviewWhatsapp';

export function AppMessagePreview({
  title: titleInput,
  body: bodyInput,
  signature: signatureInput,
  paramsToReplace = {},
  target,
  showHeaderBar,
  showMessageIndexNavigationBar,
  currentIndex,
  totalIndexes,
  setCurrentIndex,
  children,
  onClick,
}: {
  title?: string;
  body?: string;
  signature?: string;
  paramsToReplace?: { [key: string]: string };
  target: AppCompanyMessageTarget;
  showHeaderBar?: boolean;
  showMessageIndexNavigationBar?: boolean;
  currentIndex?: number;
  totalIndexes?: number;
  setCurrentIndex?: (index: number) => void;
  children?: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
}) {
  const title = useMemo(
    () =>
      diverLinkParametersToReplaceRenderer.renderTemplate({
        template: titleInput,
        paramsToReplace,
      }),
    [paramsToReplace, titleInput],
  );
  const body = useMemo(
    () =>
      diverLinkParametersToReplaceRenderer.renderTemplate({
        template: bodyInput,
        paramsToReplace,
      }),
    [bodyInput, paramsToReplace],
  );

  const signature = useMemo(
    () =>
      diverLinkParametersToReplaceRenderer.renderTemplate({
        template: signatureInput,
        paramsToReplace,
      }),
    [paramsToReplace, signatureInput],
  );

  return signature || title || body ? (
    <div
      className={clsx(
        'border-2 rounded-2xl text-base',

        target === 'sms'
          ? 'border-blue-500 text-blue-900'
          : target === 'whatsapp'
          ? 'border-green-500 text-green-900'
          : 'border-red-500 text-red-900',
        onClick && 'cursor-pointer hover:bg-gray-50',
      )}
      onClick={onClick}
    >
      {showHeaderBar && <AppMessagePreviewHeaderBar target={target} />}

      {target === 'email' ? (
        <AppMessagePreviewEmail
          className="px-4 py-2"
          title={title}
          body={body}
          signature={signature}
        />
      ) : target === 'whatsapp' ? (
        <AppMessagePreviewWhatsapp
          className="px-4 py-2"
          title={title}
          body={body}
          signature={signature}
        />
      ) : (
        <AppMessagePreviewSMS
          className="px-4 py-2"
          title={title}
          body={body}
          signature={signature}
        />
      )}
      {children && children}
      {showMessageIndexNavigationBar && (
        <div
          className={clsx(
            'mt-6 border-2 border-white rounded-b-2xl text-white px-2 text-center flex gap-4 justify-between items-stretch',
            target === 'sms'
              ? 'bg-app-sms-dark'
              : target === 'whatsapp'
              ? 'bg-app-whatsapp-dark'
              : 'bg-app-email-dark',
          )}
        >
          <div
            className="py-1 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setCurrentIndex((totalIndexes + currentIndex - 1) % totalIndexes);
            }}
          >
            <AppIconsAction.prev className="w-8 h-8" />
          </div>
          <div
            className={clsx(
              'flex-grow uppercase font-bold align-middle text-center flex justify-center items-center',
              target === 'sms'
                ? 'bg-app-sms'
                : target === 'whatsapp'
                ? 'bg-app-whatsapp'
                : 'bg-app-email',
            )}
            onClick={(e) => {
              e.stopPropagation();
              setCurrentIndex((currentIndex + 1) % totalIndexes);
            }}
          >
            Message {currentIndex + 1}/{totalIndexes}
          </div>
          <div
            className="py-1 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setCurrentIndex((currentIndex + 1) % totalIndexes);
            }}
          >
            <AppIconsAction.next className="w-8 h-8" />
          </div>
        </div>
      )}
    </div>
  ) : null;
}
