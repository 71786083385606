/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveAptitude,
  RegionalSettingsCriteria,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useRegionalSettings } from './useRegionalSettings.hook';

export function useDiveAptitudeOptions(
  criteria: RegionalSettingsCriteria,
): ValueLabel<DiveAptitude>[] {
  const regionalSettings = useRegionalSettings(criteria);

  return useMemo(() => {
    const aptitudes = regionalSettings?.divingLegislation?.aptitudes ?? [];

    const diveAptitudesOptions: ValueLabel<DiveAptitude>[] = aptitudes.map(
      (aptitude) => ({
        value: aptitude,
        label: aptitude,
      }),
    );

    return diveAptitudesOptions;
  }, [regionalSettings.divingLegislation.aptitudes]);
}
