import { dataSorter } from '@mabadive/app-common-services';
import React, { useMemo, useState } from 'react';
import { AppTransition } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { PRO_BookingMemberFull_WithDocs } from '../../../models';
import { SelectedDiversTableMemberRow } from './SelectedDiversTableMemberRow';

export function SelectedDiversTable({
  existingBookingMembersFull,
  newBookingMembers: newBookingMembersInput,
  onClickMember,
  className,
}: {
  existingBookingMembersFull: PRO_BookingMemberFull_WithDocs[];
  newBookingMembers: (PRO_BookingMemberFull_WithDocs & {
    isNewDiver: boolean;
  })[];
  onClickMember?: (bookingMemberFull: PRO_BookingMemberFull_WithDocs) => void;
  className?: string;
}) {
  const clubResume = useClubResume();
  const customerSettings = clubResume.clubSettings?.customer;
  const [isExpanded, setIsExpanded] = useState(true);

  const newBookingMembers = useMemo(
    () =>
      dataSorter.sortMultiple(newBookingMembersInput, {
        getSortAttributes: (
          x: PRO_BookingMemberFull_WithDocs & {
            isNewDiver: boolean;
          },
        ) => [
          {
            value: x.bookingMember._createdAt,
            asc: false,
          },
          {
            value: x.diver._createdAt,
            asc: false,
          },
        ],
        asc: true,
      }),
    [newBookingMembersInput],
  );

  const existingMembersCount = existingBookingMembersFull?.length ?? 0;
  const totalMembersCount = newBookingMembers.length + existingMembersCount;

  const isUnique = totalMembersCount === 1;

  return (
    <table className={`min-w-full divide-y divide-gray-200 ${className ?? ''}`}>
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="w-9 py-1 text-right cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <AppHeroIcons.actionReduce className="cursor-pointer mr-1 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
            ) : (
              <AppHeroIcons.actionExtend className="cursor-pointer mr-1 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
            )}
            <span className="sr-only">Actions</span>
          </th>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <div className="flex items-center">
              {isUnique ? 'Plongeur' : 'Plongeurs'}
              {!isUnique && (
                <>
                  {existingMembersCount !== 0 &&
                  newBookingMembers.length !== 0 ? (
                    <span className="ml-1">
                      ({existingMembersCount} + {newBookingMembers.length})
                    </span>
                  ) : existingMembersCount ? (
                    <span className="ml-1">({existingMembersCount})</span>
                  ) : (
                    <span className="ml-1">({newBookingMembers.length})</span>
                  )}
                </>
              )}
            </div>
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            Niveau
          </th>
          {customerSettings?.diving?.medicalCertificateDate && (
            <th
              scope="col"
              className="hidden md:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              Certificat médical
            </th>
          )}
          <th
            scope="col"
            className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            Contact
          </th>
        </tr>
      </thead>
      {/* {totalMembersCount > 5 && (
        <tbody>
          <tr>
            <td colSpan={5}>
              {isExpanded && (
                <AppButton
                  fullWidth={true}
                  color="primary-outline-light"
                  onClick={() => setIsExpanded(!isExpanded)}
                  icon={AppHeroIcons.eyeOff}
                >
                  Masquer les participants
                </AppButton>
              )}
            </td>
          </tr>
        </tbody>
      )} */}
      <AppTransition
        type="scale"
        show={isExpanded}
        as="tbody"
        className="bg-white divide-y divide-gray-200"
      >
        {(newBookingMembers ?? []).map((bookingMemberFull) => (
          <SelectedDiversTableMemberRow
            key={bookingMemberFull.bookingMember._id}
            isNewMember={true}
            isNewDiver={bookingMemberFull.isNewDiver}
            bookingMemberFull={bookingMemberFull}
            onClick={() =>
              onClickMember ? onClickMember(bookingMemberFull) : undefined
            }
          />
        ))}
        {(existingBookingMembersFull ?? []).map((bookingMemberFull) => (
          <SelectedDiversTableMemberRow
            key={bookingMemberFull.bookingMember._id}
            isNewMember={false}
            isNewDiver={false}
            bookingMemberFull={bookingMemberFull}
            onClick={() =>
              onClickMember ? onClickMember(bookingMemberFull) : undefined
            }
          />
        ))}
      </AppTransition>
      {/* {totalMembersCount > 5 && (
        <tbody>
          <tr>
            <td colSpan={5}>
              {!isExpanded && (
                <AppButton
                  fullWidth={true}
                  color="primary-outline-light"
                  onClick={() => setIsExpanded(!isExpanded)}
                  icon={AppHeroIcons.eye}
                >
                  Afficher les {totalMembersCount} autres participants
                </AppButton>
              )}
            </td>
          </tr>
        </tbody>
      )} */}
    </table>
  );
}
