import { ClubPurchasePackage } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import {
  AppPriceLabel,
  AppPriceLabelRatio,
} from 'src/business/club/modules/_common/ui';
import { useAppCurrencyMain } from 'src/pages/_components/options';

export function BillingParticipantPurchaseResumePurchasePaymentState({
  purchasePackage,
  opacity,
  className,
}: {
  purchasePackage: Pick<
    ClubPurchasePackage,
    | 'price'
    | 'purchasePaymentStatus'
    | 'payedAmount'
    | 'purchasePaymentPending'
    | 'purchasePaymentWithCreditNote'
  >;
  opacity?: 60;
  className?: string;
}) {
  const badgeStatus: AppGenericStatus = useMemo(() => {
    // if (purchasePackage?.purchasePaymentPending) {
    //   return 'warn';
    // }
    if (purchasePackage?.purchasePaymentStatus) {
      switch (purchasePackage?.purchasePaymentStatus) {
        case 'done':
          return 'info';
        case 'todo':
          return 'error';
        case 'partial':
          return 'warn';
      }
    }
  }, [purchasePackage?.purchasePaymentStatus]);

  const badgeStatusClass: string = useMemo(() => {
    // if (purchasePackage?.purchasePaymentPending) {
    //   return 'text-fuchsia-600';
    // }
    return appGenericStatusStyleBuilder.build(badgeStatus);
  }, [badgeStatus]);

  const mainCurrency = useAppCurrencyMain();

  return (
    <div className={`font-bold text-xs md:text-sm ${className}`}>
      {purchasePackage && (
        <>
          <div
            className={`${badgeStatusClass} flex gap-1 ${
              opacity === 60 ? 'text-opacity-60' : ''
            }`}
          >
            <span className="flex-grow flex gap-1 ">
              {purchasePackage.price ? (
                <span
                  className={`flex gap-1 ${
                    purchasePackage.purchasePaymentStatus !== 'done'
                      ? 'font-medium'
                      : ''
                  }`}
                >
                  {purchasePackage.payedAmount ? (
                    <AppPriceLabelRatio
                      className="text-xs md:text-sm"
                      amount={purchasePackage.payedAmount}
                      total={purchasePackage.price}
                      mainCurrency={mainCurrency}
                      hideTotalIfSameAmount={true}
                    />
                  ) : (
                    <AppPriceLabel
                      amount={purchasePackage.price}
                      mainCurrency={mainCurrency}
                    />
                  )}
                </span>
              ) : purchasePackage.payedAmount > 0 ? (
                <AppPriceLabel
                  amount={purchasePackage.payedAmount}
                  mainCurrency={mainCurrency}
                />
              ) : purchasePackage.purchasePaymentStatus === 'done' ? (
                <span className="whitespace-normal">OK</span>
              ) : (
                <span className="text-status-error whitespace-normal"></span>
              )}
              {purchasePackage?.purchasePaymentPending && (
                <AppIcons.ui.statusPendingSolid className="w-4 h-4 bg-app-red text-white" />
              )}
              {purchasePackage?.purchasePaymentWithCreditNote && (
                <EntitiesIcons.creditNote className="w-6 h-6 p-1 text-status-success" />
              )}
            </span>

            {/* {
              <AppGenericStatusIcon
                status={badgeStatus}
                className={'inline w-4 h-4'}
              />
            } */}
          </div>
          {/* <div
            className={`w-full pt-0.5 uppercase whitespace-nowrap font-bold text-gray-600 text-xs ${
              opacity === 60 ? 'text-opacity-60' : ''
            }`}
          >
            {purchasePackage.purchasePaymentStatus && (
              <div className="text-gray-500 text-xs">
                {clubPurchasePackagePaymentStatusFormatter.formatStatus(
                  purchasePackage.purchasePaymentStatus,
                )}
              </div>
            )}
          </div> */}
        </>
      )}
    </div>
  );
}
