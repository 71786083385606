/* eslint-disable @typescript-eslint/no-unused-vars */
import { EcommerceCategoryGql_Company } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import { EcommerceCategoryPreviewCover } from './EcommerceCategoryPreview';

export const ProProductsPage_CategoryCoverCard = ({
  category,
  localState,
}: {
  category: EcommerceCategoryGql_Company;
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const { state, dialogs, data, actions } = localState;

  const categoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.categoryId === category._id,
    );
  }, [category._id, data.productCategoryConfigs]);

  const products = useMemo(() => {
    return data.products.filter((p) =>
      categoryConfigs.some((cf) => cf.productId === p._id),
    );
  }, [categoryConfigs, data.products]);

  const appBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === category.bookletId);
  }, [data.booklets, category.bookletId]);

  const appBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === appBooklet?.rootPageId);
  }, [appBooklet?.rootPageId, data.bookletPages]);

  const nextIndex = useMemo(() => {
    if (products.length > 0) {
      return Math.max(...categoryConfigs.map((p) => p.sortIndex)) + 1;
    }
    return 1;
  }, [categoryConfigs, products.length]);

  return (
    <div
      className="app-card rounded-t-xl app-card-highlight grid gap-4 max-w-3xl"
      onClick={(e) => {
        actions.selectCategory(category._id);
      }}
    >
      <EcommerceCategoryPreviewCover
        displayWidth="screen"
        category={category}
        appBookletPage={appBookletPage}
        productsLength={products?.length || 0}
      >
        <div className="mt-8 flex-grow flex flex-col gap-4">
          <AppButton
            className=""
            color="primary-bg"
            size="normal"
            icon={AppIconsAction.open}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   actions.selectCategory(category._id);
            // }}
          >
            Ouvrir la catégorie
          </AppButton>
          <AppButton
            className=""
            color="gray-outline-light"
            size="normal"
            icon={AppHeroIcons.actionEdit}
            onClick={(e) => {
              e.stopPropagation();
              dialogs.categoryEditor.openEditCategory({
                editValue: { category, appBooklet, appBookletPage },
              });
            }}
          >
            Modifier la description
          </AppButton>
        </div>
      </EcommerceCategoryPreviewCover>
    </div>
  );
};
