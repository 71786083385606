import { ClubParticipantGaz } from '@mabadive/app-common-model';
import { diveGazFormatter } from '@mabadive/app-common-services';
import React from 'react';

export const ParticipantGaz = ({
  gaz,
  mode = 'default',
  showAir = false,
  showDetails = false,
  showGazQuantity = false,
  className,
}: {
  gaz: ClubParticipantGaz;
  mode?: 'default' | 'badge';
  showAir?: boolean;
  showDetails?: boolean;
  showGazQuantity?: boolean;
  className?: string;
}) => {
  return !!gaz?.gazType && (showAir || gaz?.gazType !== 'air') ? (
    <div className={`flex gap-x-1 ${className}`}>
      <div
        className={`font-bold ${
          mode === 'default'
            ? gaz?.gazType === 'air'
              ? 'text-blue-400'
              : 'text-red-500'
            : gaz?.gazType === 'air'
            ? 'text-white app-text-shadow-sm shadow-gray-600'
            : 'text-red-200 app-text-shadow-sm shadow-red-600'
        }`}
      >
        {diveGazFormatter.formatGaz(gaz, {
          format: 'short',
          appendDescription: showDetails,
        })}
      </div>

      {showGazQuantity && gaz?.gazQuantity && (
        <div className="text-center font-bold text-gray-600">{`${gaz?.gazQuantity}L`}</div>
      )}
    </div>
  ) : null;
};
