import {
  BookingMember,
  BookingSession,
  ClubPublicSettings,
  JsonPatchOperation,
} from '@mabadive/app-common-model';
import {
  arrayBuilder,
  changeDescriptorManager,
  diverBookingCreator,
  diverBookingMemberCreator,
  jsonPatcher,
} from '@mabadive/app-common-services';
import {
  BookingTabModel,
  BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk,
  DiverBookingPageAggregatedDataCore,
  DiverBookingPageUpdateState,
  DiverBookingUpdateProductParticipant,
} from '../../../models';
import { diverBookingPageUpdateStateManager } from '../../02.update-state';
import {
  diverBookingPageSessionCreator,
  diverBookingParticipantCreator,
} from '../entity-creators';

export const bookingPageUpdateStateBookingSplitter = { splitBookings };

function splitBookings({
  bookingTabModel,
  bookingsChunks,
  clubReference,
  diveCenterId,
  aggregatedData,
  updateState,
  publicSettings,
}: {
  bookingTabModel: BookingTabModel;
  bookingsChunks: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk[];
  clubReference: string;
  diveCenterId: string;
  aggregatedData: DiverBookingPageAggregatedDataCore;
  updateState: DiverBookingPageUpdateState;
  publicSettings: ClubPublicSettings;
}) {
  const bookingSource = bookingTabModel;

  let updateStateLocal = updateState;

  const bookingsChunkExistingBooking = bookingsChunks[0]; // le premier "chunk" correspond au "booking" existant
  const bookingsChunksNewBooking = bookingsChunks.slice(1); // les "chunks" suivants sont les bookings à créer

  for (const chunk of bookingsChunksNewBooking) {
    // create booking

    const bookingContactDiverId = // keep same contact if still exists in booking
      chunk.bookingMembersFull.find(
        (m) =>
          m.bookingMember.diverId ===
          bookingSource.aggregatedBooking.booking.bookingContactDiverId,
      )?.diver?._id ??
      // else take last member
      chunk.bookingMembersFull[chunk.bookingMembersFull?.length - 1]?.diver
        ?._id;

    const newBooking = diverBookingCreator.createBooking({
      clubReference,
      diveCenterId,
      bookingContactDiverId,
      active: chunk.active,
      publicSettings,
    });
    const bookingsChanges = changeDescriptorManager.createOne(newBooking, {
      changeDescriptors: updateStateLocal.bookingsChanges,
    });
    updateStateLocal = {
      ...updateStateLocal,
      bookingsChanges,
    };

    const diveSessionReferencesToCreate = chunk.participantsBySessions.map(
      (pbs) => pbs.bookingSessionFull.diveSession.reference,
    );

    // create booking sessions
    const sourceBookingSessionsToMigrate =
      bookingSource.aggregatedBooking.bookingSessions.filter((bookingSession) =>
        diveSessionReferencesToCreate.includes(
          bookingSession.diveSessionReference,
        ),
      );

    const newBookingSessions: BookingSession[] = [];

    for (const sourceBookingSession of sourceBookingSessionsToMigrate) {
      const newBookingSession =
        diverBookingPageSessionCreator.createBookingSessionFromSourceSession({
          bookingId: newBooking._id,
          clubReference,
          sourceSession: sourceBookingSession,
        });
      newBookingSessions.push(newBookingSession);
      {
        const bookingSessionsChanges = changeDescriptorManager.createOne(
          newBookingSession,
          {
            changeDescriptors: updateStateLocal.bookingSessionsChanges,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          bookingSessionsChanges,
        };
      }
      {
        const bookingSessionsIds = arrayBuilder.filterDuplicated(
          sourceBookingSessionsToMigrate.map((x) => x._id),
        );

        const bookingSessionsChanges = changeDescriptorManager.deleteMany(
          bookingSessionsIds,
          {
            changeDescriptors: updateStateLocal.bookingSessionsChanges,
          },
        );
        updateStateLocal = {
          ...updateStateLocal,
          bookingSessionsChanges,
        };
      }
    }

    // create booking members
    for (const sourceMemberFull of chunk.bookingMembersFull) {
      const sourceMember = sourceMemberFull.bookingMember;

      const productsToCreate =
        bookingSource.aggregatedBooking.bookingParticipantsFull.filter(
          (sourceParticipantFullProducts) =>
            sourceParticipantFullProducts.bookingMember._id ===
              sourceMember._id &&
            diveSessionReferencesToCreate.includes(
              sourceParticipantFullProducts.diveSession.reference,
            ),
        );
      if (productsToCreate.length) {
        // create member

        const newBookingMember =
          diverBookingMemberCreator.createBookingMemberFromSourceMember({
            sourceMember,
            bookingId: newBooking._id,
            clubReference,
            diveCenterId: newBooking.diveCenterId,
          });

        const diver = aggregatedData.divers.find(
          (d) => d._id === sourceMember.diverId,
        );
        updateStateLocal =
          diverBookingPageUpdateStateManager.addNewBookingMemberToState({
            updateState: updateStateLocal,
            newBookingMember,
            diver,
          });

        // create products
        for (const sourceParticipantFullProduct of productsToCreate) {
          const diverId = sourceParticipantFullProduct.bookingMember.diverId;

          const diveSessionReference =
            sourceParticipantFullProduct.bookingSession.diveSessionReference;

          // create new product
          const newBookingSession = newBookingSessions.find(
            (m) => m.diveSessionReference === diveSessionReference,
          );
          const updatedParticipant: DiverBookingUpdateProductParticipant =
            diverBookingParticipantCreator.createParticipantFromSourceProduct({
              participantProductFull: sourceParticipantFullProduct,
              bookingId: newBooking._id,
              bookingMemberId: newBookingMember._id,
              bookingSessionId: newBookingSession._id,
            });
          updateStateLocal =
            diverBookingPageUpdateStateManager.addNewParticipantToState({
              updateState: updateStateLocal,
              newBookingProductParticipant: updatedParticipant,
            });
          // delete old product
          updateStateLocal =
            diverBookingPageUpdateStateManager.deleteParticipantFromState({
              updateState: updateStateLocal,
              bookingProductId:
                sourceParticipantFullProduct.bookingProductDive._id,
              clubParticipantId:
                sourceParticipantFullProduct.bookingSessionParticipant
                  .participantId,
              bookingSessionParticipantId:
                sourceParticipantFullProduct.bookingSessionParticipant?._id,
            });
        }
      }
    }
  }

  const bookingMembersDiversIdsToKeep =
    bookingsChunkExistingBooking.bookingMembersFull.map((bm) => bm.diver._id);
  const bookingMembersToRemove =
    bookingSource.aggregatedBooking.bookingMembers.filter(
      (bm) => !bookingMembersDiversIdsToKeep.includes(bm.diverId),
    );

  // update original booking
  updateStateLocal = updateOriginalBooking({
    bookingMembersToRemove,
    updateStateLocal,
    bookingMembersDiversIdsToKeep,
    bookingSource,
    bookingsChunkExistingBooking,
  });

  return updateStateLocal;
}
function updateOriginalBooking({
  bookingMembersToRemove,
  updateStateLocal,
  bookingMembersDiversIdsToKeep,
  bookingSource,
  bookingsChunkExistingBooking,
}: {
  bookingMembersToRemove: BookingMember[];
  updateStateLocal: DiverBookingPageUpdateState;
  bookingMembersDiversIdsToKeep: string[];
  bookingSource: BookingTabModel;
  bookingsChunkExistingBooking: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
}) {
  let bookingPatchOperations: JsonPatchOperation[] = [];

  // remove obsolete members
  for (const bookingMemberToRemove of bookingMembersToRemove) {
    const bookingMembersChanges = changeDescriptorManager.deleteOne(
      bookingMemberToRemove._id,
      {
        changeDescriptors: updateStateLocal.bookingMembersChanges,
      },
    );
    updateStateLocal = {
      ...updateStateLocal,
      bookingMembersChanges,
    };
  }

  if (
    !bookingMembersDiversIdsToKeep.includes(
      bookingSource.aggregatedBooking.booking?.bookingContactDiverId,
    )
  ) {
    // update contact as member was removed
    const patchOperations = jsonPatcher.compareObjects(
      {
        bookingId: bookingSource.bookingId,
        bookingContactDiverId: null,
      },
      {
        bookingId: bookingSource.bookingId,
        bookingContactDiverId:
          bookingMembersDiversIdsToKeep[
            bookingMembersDiversIdsToKeep.length - 1
          ],
      },
      {},
    );
    bookingPatchOperations = bookingPatchOperations.concat(patchOperations);
  }

  // update active
  if (
    bookingSource.aggregatedBooking.booking.active !==
    bookingsChunkExistingBooking.active
  ) {
    const patchOperations = jsonPatcher.compareObjects(
      {
        bookingId: bookingSource.bookingId,
        active: bookingSource.aggregatedBooking.booking.active,
      },
      {
        bookingId: bookingSource.bookingId,
        active: bookingsChunkExistingBooking.active,
      },
      {},
    );
    bookingPatchOperations = bookingPatchOperations.concat(patchOperations);
  }

  if (bookingPatchOperations.length) {
    const bookingsChanges = changeDescriptorManager.updateOne(
      {
        pk: bookingSource.bookingId,
        patchOperations: bookingPatchOperations,
      },
      {
        changeDescriptors: updateStateLocal.bookingsChanges,
      },
    );
    updateStateLocal = {
      ...updateStateLocal,
      bookingsChanges,
    };
  }
  return updateStateLocal;
}
