import {
  ClubResumeDiveSite,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { CalendarSessionWidthMode } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/session-common';
import { ClubPlanningPanelSessionDisplayStyle } from 'src/business/club/modules/club-planning-light/model';
import { ClubDiveSessionHeaderDiveSite } from './ClubDiveSessionHeaderDiveSite';

export const ClubDiveSessionHeaderTimeAndDiveSite = function ({
  diveSiteId,
  sessionIndex,
  time,
  isMultiSession,
  isHistoryPage,
  widthMode,
  selectionColor,
  context = 'planning',
  className,
}: {
  diveSiteId: string;
  sessionIndex: number;
  time: Date;
  isMultiSession: boolean;
  widthMode: CalendarSessionWidthMode;
  selectionColor?: ClubPlanningPanelSessionDisplayStyle;
  context?: 'planning' | 'daily-resume';
  isHistoryPage?: boolean;
  className?: string;
}) {
  const clubResume = useClubResume();

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(time),
    [time],
  );

  const diveSite: ClubResumeDiveSite = useMemo(() => {
    if (diveSiteId) {
      return clubResume.diveSites.find((ds) => ds._id === diveSiteId);
    }
  }, [diveSiteId, clubResume.diveSites]);

  if (diveSiteId && !diveSite) {
    // eslint-disable-next-line no-console
    console.error(
      `[ClubDiveSessionHeaderTimeAndDiveSite] diveSite ${diveSiteId} NOT Found`,
      clubResume.diveSites,
    );
  }

  return (
    <div
      className={clsx(
        'max-w-full font-bold',
        context === 'daily-resume' ? 'flex flex-col' : 'flex gap-1',
        context === 'planning' && '',
        selectionColor
          ? `bg-app-${selectionColor} text-white`
          : context === 'planning' &&
              `bg-day-period-${timeDayPeriod}-light text-gray-600`,
        className,
      )}
    >
      <div
        className={clsx(
          context === 'daily-resume' && `text-day-period-${timeDayPeriod}-dark`,
          context === 'planning' && ' text-white py-0.5 px-1',
          selectionColor
            ? `bg-app-${selectionColor}`
            : context === 'planning' && `bg-day-period-${timeDayPeriod}-dark`,
          isHistoryPage && 'min-w-[70px] text-center',
        )}
      >
        {dateService.formatUTC(time, 'HH:mm')}
      </div>

      <ClubDiveSessionHeaderDiveSite
        className={context === 'planning' && 'py-0.5'}
        context={context}
        diveSite={diveSite}
        sessionIndex={sessionIndex}
        time={time}
        isMultiSession={isMultiSession}
        widthMode={widthMode}
      />
    </div>
  );
};
