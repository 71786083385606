import { colorGenerator } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { FC, useMemo, useState } from 'react';
import { AppIcon } from '../../../icons/AppIcon.type';

export type AppIconButtonFreeColorProps = {
  icon: AppIcon;
  bgColor: string;
  textColor: string;
  className?: string;
  href: string;
  title?: string;
  externalLink?: boolean;
  children?: React.ReactNode | React.ReactNode[];
};

export const AppIconButtonFreeColor: FC<AppIconButtonFreeColorProps> = ({
  className = 'rounded w-9 h-9',
  icon,
  bgColor,
  textColor,
  href,
  title,
  externalLink,
  children,
  ...props
}) => {
  const { style, hoverStyle } = useMemo(() => {
    // const isLightBgColor = colorGenerator.isLight(bgColor);

    const style: React.CSSProperties = {
      backgroundColor: textColor,
      borderColor: colorGenerator.darken(bgColor, 0.5),
      color: bgColor,
      fill: textColor,
    };
    const hoverStyle: React.CSSProperties = {
      backgroundColor: colorGenerator.lighten(textColor, 0.3),
      borderColor: colorGenerator.darken(bgColor, 0.8),
      color: colorGenerator.darken(bgColor, 0.5),
      fill: textColor,
    };

    return {
      style,
      hoverStyle,
    };
  }, [bgColor, textColor]);

  const [isHover, setIsHover] = useState(false);

  const styleWithHoverSupport = useMemo(() => {
    if (style) {
      return isHover && hoverStyle
        ? {
            ...style,
            ...hoverStyle,
          }
        : style;
    } else {
      return isHover ? hoverStyle : undefined;
    }
  }, [hoverStyle, isHover, style]);

  const AppButtonIcon = icon;

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      href={href}
      title={title}
      target={externalLink ? '_blank' : undefined}
      rel={externalLink ? 'noopener noreferrer' : undefined}
      onClick={(e) => e.stopPropagation()}
    >
      <AppButtonIcon
        className={clsx('border', className)}
        style={styleWithHoverSupport}
      />
    </a>
  );
};
