import { dateService } from '@mabadive/app-common-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppTitleDateNavigatorInputState,
  AppTitleDateNavigatorModel,
  AppTitleDateNavigatorModelValue,
  AppTitleDateNavigatorPeriod,
} from './model';
import {
  appTitleDateNavigatorDatesBuilder,
  appTitleDateNavigatorPropsBuilder,
} from './services';

export const useAppTitleDateNavigatorLocalState = ({
  inputState,
}: {
  inputState: AppTitleDateNavigatorInputState;
}) => {
  const {
    period: initialPeriod = 'day',
    value: {
      selectedDate: initialSelelectedDate = dateService.getUTCDateSetTime(
        new Date(),
      ),
      beginDate: initialBeginDate,
      endDate: initialEndDate,
    },
    onChange,
    minDate,
    maxDate: maxDateInclusive,
    fiscalStartMonth,
    ajustPeriodRangeToCurrentDate,
  } = inputState;

  const maxDateExclusive = useMemo(
    () =>
      maxDateInclusive
        ? dateService.add(maxDateInclusive, 1, 'day')
        : undefined,
    [maxDateInclusive],
  );

  const isDateInValidRange = useCallback(
    (date: Date) => {
      // TODO supporter si on a juste min ou max
      if (minDate && maxDateExclusive) {
        return dateService.isDateWithinRange(date, {
          minDate,
          maxDateExclusive,
        });
      }
      return true;
    },
    [maxDateExclusive, minDate],
  );

  const switchPeriod = useCallback(
    ({
      selectedDate,
      period,
      currentBeginDate,
      currentEndDate,
    }: {
      selectedDate: Date;
      period: AppTitleDateNavigatorPeriod;
      currentBeginDate: Date;
      currentEndDate: Date;
    }): AppTitleDateNavigatorModelValue => {
      return appTitleDateNavigatorDatesBuilder.buildDefaultDates({
        selectedDate,
        period,
        ajustPeriodRangeToCurrentDate,
        fiscalStartMonth,
        currentBeginDate,
        currentEndDate,
      });
    },
    [ajustPeriodRangeToCurrentDate, fiscalStartMonth],
  );

  const buildCalculatedProps = useCallback(
    ({
      value,
      period,
    }: {
      value: AppTitleDateNavigatorModelValue;
      period: AppTitleDateNavigatorPeriod;
    }): Pick<
      AppTitleDateNavigatorModel,
      'isNow' | 'previousValue' | 'nextValue'
    > =>
      appTitleDateNavigatorPropsBuilder.buildCalculatedProps({
        value,
        period,
        minDate,
        maxDateInclusive,
        ajustPeriodRangeToCurrentDate,
      }),
    [ajustPeriodRangeToCurrentDate, maxDateInclusive, minDate],
  );

  const initialModel: AppTitleDateNavigatorModel = useMemo(() => {
    let value: AppTitleDateNavigatorModelValue;
    if (initialBeginDate && initialEndDate) {
      value = {
        selectedDate: initialSelelectedDate,
        beginDate: initialBeginDate,
        endDate: initialEndDate,
      };
    } else {
      value = switchPeriod({
        period: initialPeriod,
        selectedDate: initialSelelectedDate,
        currentBeginDate: undefined, // not used
        currentEndDate: undefined, // not used
      });
    }

    const model: AppTitleDateNavigatorModel = {
      period: initialPeriod,
      value,
      ...buildCalculatedProps({
        value,
        period: initialPeriod,
      }),
    };

    return model;
  }, [
    buildCalculatedProps,
    initialBeginDate,
    initialEndDate,
    initialPeriod,
    initialSelelectedDate,
    switchPeriod,
  ]);

  const [model, setModelInner] =
    useState<AppTitleDateNavigatorModel>(initialModel);

  const setModel = useCallback((model: AppTitleDateNavigatorModel) => {
    setModelInner(model);
  }, []);

  useEffect(() => {
    if (
      model.value?.beginDate !== initialModel?.value?.beginDate ||
      model.value?.endDate !== initialModel?.value?.endDate
    ) {
      setModelInner(initialModel);
    }
  }, [initialModel, model.value?.beginDate, model.value?.endDate]);

  const onSwitchPeriod = useCallback(
    (period: AppTitleDateNavigatorPeriod) => {
      const value: AppTitleDateNavigatorModelValue = switchPeriod({
        period,
        selectedDate: initialSelelectedDate,
        currentBeginDate: model?.value?.beginDate,
        currentEndDate: model?.value.endDate,
      });

      const updatedModel: AppTitleDateNavigatorModel = {
        period,
        value,
        ...buildCalculatedProps({
          value,
          period,
        }),
      };

      setModel(updatedModel);
      onChange(updatedModel);
    },
    [
      buildCalculatedProps,
      initialSelelectedDate,
      model?.value?.beginDate,
      model?.value.endDate,
      onChange,
      setModel,
      switchPeriod,
    ],
  );

  const onUpdateValue = useCallback(
    (value: AppTitleDateNavigatorModelValue) => {
      const updatedModel: AppTitleDateNavigatorModel = {
        period: model.period,
        value,
        ...buildCalculatedProps({
          value,
          period: model.period,
        }),
      };

      setModel(updatedModel);
      onChange(updatedModel);
    },
    [buildCalculatedProps, model.period, onChange, setModel],
  );

  const onUpdateSelectedDate = useCallback(
    (newSelectedDayDate: Date) => {
      if (isDateInValidRange(newSelectedDayDate)) {
        const value = switchPeriod({
          period: model.period,
          selectedDate: newSelectedDayDate,
          currentBeginDate: newSelectedDayDate,
          currentEndDate: model?.value?.endDate,
        });
        onUpdateValue(value);
      }
    },
    [
      isDateInValidRange,
      model.period,
      model?.value?.endDate,
      onUpdateValue,
      switchPeriod,
    ],
  );

  const onUpdateEndDate = useCallback(
    (newSelectedDayDate: Date) => {
      if (isDateInValidRange(newSelectedDayDate)) {
        const value = switchPeriod({
          period: model.period,
          selectedDate: model?.value?.selectedDate,
          currentBeginDate: model?.value?.beginDate,
          currentEndDate: newSelectedDayDate,
        });
        onUpdateValue(value);
      }
    },
    [
      isDateInValidRange,
      model.period,
      model?.value?.beginDate,
      model?.value?.selectedDate,
      onUpdateValue,
      switchPeriod,
    ],
  );

  return {
    model,
    onUpdateValue,
    onSwitchPeriod,
    onUpdateSelectedDate,
    onUpdateEndDate,
  };
};
