import { BookingResumeForList } from '@mabadive/app-common-model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MQueryDescription, graphqlClient } from 'src/_common-browser';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { BookingGql1CollectionCriteria } from '../model';
import { buildBookingGql1CollectionGraphqlQuery } from './query-builder';

export const bookingGql1GraphqlFetcher = {
  findMany,
};

function findMany(
  criteria: BookingGql1CollectionCriteria,
  {
    type,
    name = 'findManyBookingGql1s',
  }: {
    type: 'subscription' | 'query';
    name?: string;
  },
): Observable<BookingResumeForList[]> {
  const query: MQueryDescription<any> =
    buildBookingGql1CollectionGraphqlQuery(criteria);

  return graphqlClient.query
    .runOne<BookingResumeForList[]>(query, {
      type,
      name,
    })
    .pipe(
      // tap((res) => {
      //   appLogger.info(
      //     '[bookingBookingGql1GraphqlFetcher.findMany] BookingGql1s:',
      //     res.length,
      //   );
      // }),
      catchError((err) => {
        appLogger.warn(
          '[bookingBookingGql1GraphqlFetcher] error fetching data',
          err,
        );
        return throwError(err);
      }),
    );
}
