/* eslint-disable @typescript-eslint/no-unused-vars */
import { appMonthIndexFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  ClubSettingsSection,
  ClubSettingsViewActivableFeature,
  ClubSettingsViewAttribute,
} from 'src/pages/SE-settings/_core';

export const ClubSettingsGeneralPurchaseViewPanelMain = () => {
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const mainCurrency = useAppCurrencyMain();
  const billing = clubSettings.general.billing;

  return (
    <ClubSettingsSection title="Facturation">
      <div className="flex gap-4 md:gap-x-8 flex-wrap">
        <ClubSettingsViewActivableFeature
          isActive={billing?.enabled}
          inactiveLabel="Facturation désactivée"
        >
          {mainCurrency && (
            <ClubSettingsViewAttribute label={'Devise de facturation'}>
              {mainCurrency.label}{' '}
              <span className="text-sm">({mainCurrency.sign})</span>
            </ClubSettingsViewAttribute>
          )}
          <ClubSettingsViewAttribute label={'Début de l\'année fiscale'}>
            {clubSettings.general.fiscalStartMonth !== undefined
              ? appMonthIndexFormatter.format(
                  clubSettings.general.fiscalStartMonth,
                )
              : '?'}
          </ClubSettingsViewAttribute>
        </ClubSettingsViewActivableFeature>
      </div>
    </ClubSettingsSection>
  );
};
