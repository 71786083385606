/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettings,
  DiveCenter,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import {
  ClubSettingsCustomerEditorFormModel,
  ClubSettingsCustomerEditorInputState,
} from './model';

export const clubSettingsCustomerEditorResultBuilder = { buildResult };

function buildResult({
  formValue,
  initialValue,
}: {
  formValue: ClubSettingsCustomerEditorFormModel;
  initialValue: ClubSettingsCustomerEditorInputState;
}): {
  hasChanges: boolean;
  payload: ProMultiOperationPayload;
} {
  let hasChanges = false;
  const payload: ProMultiOperationPayload = {
    logContext: 'update club settings customer',
  };
  {
    const finalValueClubSettings: ClubSettings = {
      ...initialValue.clubSettings,
      customer: {
        ...initialValue.clubSettings.customer,
        ...formValue.clubSettings.customer,
      },
    };

    // s'assurer que le label n'est pas une chaine vide
    if (finalValueClubSettings?.customer?.general?.externalRefLabel) {
      finalValueClubSettings.customer.general.externalRefLabel =
        emptyStringOrNull(
          finalValueClubSettings?.customer?.general?.externalRefLabel,
        );
    }
    if (finalValueClubSettings?.customer?.general?.customField1Label) {
      finalValueClubSettings.customer.general.customField1Label =
        emptyStringOrNull(
          finalValueClubSettings?.customer?.general?.customField1Label,
        );
    }
    if (finalValueClubSettings?.customer?.general?.customField2Label) {
      finalValueClubSettings.customer.general.customField2Label =
        emptyStringOrNull(
          finalValueClubSettings?.customer?.general?.customField2Label,
        );
    }
    if (finalValueClubSettings?.customer?.general?.customField3Label) {
      finalValueClubSettings.customer.general.customField3Label =
        emptyStringOrNull(
          finalValueClubSettings?.customer?.general?.customField3Label,
        );
    }
    if (finalValueClubSettings?.customer?.booking?.externalRefLabel) {
      finalValueClubSettings.customer.booking.externalRefLabel =
        emptyStringOrNull(
          finalValueClubSettings?.customer?.booking?.externalRefLabel,
        );
    }
    if (finalValueClubSettings?.customer?.booking?.customField1Label) {
      finalValueClubSettings.customer.booking.customField1Label =
        emptyStringOrNull(
          finalValueClubSettings?.customer?.booking?.customField1Label,
        );
    }
    if (finalValueClubSettings?.customer?.booking?.customField2Label) {
      finalValueClubSettings.customer.booking.customField2Label =
        emptyStringOrNull(
          finalValueClubSettings?.customer?.booking?.customField2Label,
        );
    }
    if (finalValueClubSettings?.customer?.booking?.customField3Label) {
      finalValueClubSettings.customer.booking.customField3Label =
        emptyStringOrNull(
          finalValueClubSettings?.customer?.booking?.customField3Label,
        );
    }

    const patchOperations = jsonPatcher.compareObjects(
      initialValue.clubSettings,
      finalValueClubSettings,
      {
        attributesToReplaceFully: ['customer'],
      },
    );
    if (patchOperations.length) {
      hasChanges = true;
      payload.updatedClubSettings = {
        pk: initialValue.clubSettings._id,
        patchOperations: patchOperations,
      };
    }
  }
  {
    const finalValueDiveCenter: DiveCenter = {
      ...initialValue.diveCenter,
      publicSettings: {
        ...initialValue.diveCenter.publicSettings,
        ...formValue.diveCenter.publicSettings,
      },
    };

    const patchOperations = jsonPatcher.compareObjects(
      initialValue.diveCenter,
      finalValueDiveCenter,
      {
        // attributesToReplaceFully: ['publicSettings'],
      },
    );
    if (patchOperations.length) {
      hasChanges = true;
      payload.diveCenters = {
        updated: [
          {
            pk: initialValue.diveCenter._id,
            patchOperations: patchOperations,
          },
        ],
      };
    }
  }
  return {
    hasChanges,
    payload,
  };
}

function emptyStringOrNull(str: string): string | null {
  return str && str.trim().length !== 0 ? str.trim() : null;
}
