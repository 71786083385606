import {
  AppUiTheme,
  ClubSettings,
  DEFAULT_APP_UI_THEME,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useClubSettings } from 'src/business/club/data/hooks/useClubSettings.hook';

export function useAppTheme(inputTheme?: AppUiTheme) {
  const clubSettings: ClubSettings = useClubSettings();

  const theme: AppUiTheme = useMemo(
    () =>
      mergeThemes(
        inputTheme,
        clubSettings?.publicSettings?.appUiTheme,
        DEFAULT_APP_UI_THEME,
      ),
    [clubSettings?.publicSettings?.appUiTheme, inputTheme],
  );

  return theme;
}

function mergeThemes(
  t1?: AppUiTheme,
  t2?: AppUiTheme,
  t3?: AppUiTheme,
): AppUiTheme {
  const mergedTheme: AppUiTheme = {
    title1: t1?.title1 ?? t2?.title1 ?? t3?.title1,
    title2: t1?.title2 ?? t2?.title2 ?? t3?.title2,
    buttonPrimary: t1?.buttonPrimary ?? t2?.buttonPrimary ?? t3?.buttonPrimary,
    buttonCancel: t1?.buttonCancel ?? t2?.buttonCancel ?? t3?.buttonCancel,
    text: t1?.text ?? t2?.text ?? t3?.text,
  };

  return mergedTheme;
}
