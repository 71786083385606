import {
  AppBooklet,
  AppBookletPage,
  ClubEcommerceCategory,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  uuidGenerator,
} from '@mabadive/app-common-services';
import {
  EcommerceCategoryEditorFormModel,
  EcommerceCategoryEditorFormModelDefaultValue,
} from '../model';

const defaultBooklet: Partial<AppBookletPage> = {
  mainContent: {
    languageCode: 'fr',
    content: {
      title: '',
      items: [],
    },
    cover: {},
  },
};

export const ecommerceCategoryEditorformBuilder = {
  buildInitialFormValue: buildInitialNewCategory,
};

function buildInitialNewCategory({
  defaultValue,
}: {
  defaultValue: EcommerceCategoryEditorFormModelDefaultValue;
}): Partial<EcommerceCategoryEditorFormModel> {
  const bookletId = uuidGenerator.random();
  const appBookletPage = commonEntityBuilder.buildEntity<AppBookletPage>({
    ...defaultBooklet,
    bookletId,
    bookletType: 'ecommerce-category',
    level: 1,
    sortIndex: 1,
    visible: true,
  });
  const appBooklet = commonEntityBuilder.buildEntity<AppBooklet>({
    _id: bookletId,
    rootPageId: appBookletPage._id,
    bookletType: 'ecommerce-category',
  });
  const category = commonEntityBuilder.buildEntity<ClubEcommerceCategory>({
    name: '',
    enabled: true,
    sortIndex: 1,
    bookletId,
    // ...defaultValue,
  });
  const value: Partial<EcommerceCategoryEditorFormModel> = {
    category,
    appBookletPage,
    appBooklet,
  };
  return value;
}
