/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';

export const DiverBillingSessionsTableDayHeader = ({
  date,
  theme,
}: {
  date: Date;
  theme?: 'default' | 'today';
}) => {
  const builtTheme = useMemo(
    () => theme ?? (dateService.isTodayUTC(date) ? 'today' : 'default'),
    [date, theme],
  );

  const isThisYear = useMemo(
    () => date.getFullYear() === new Date().getFullYear(),
    [date],
  );
  const isPast = useMemo(
    () => !dateService.isTodayUTC(date) && dateService.isPastUTC(date),
    [date],
  );
  return (
    <div
      className={`rounded-t-lg py-0.5 px-0.5 border border-gray-300 ${
        builtTheme === 'today'
          ? 'bg-app-orange3 text-white'
          : isPast
          ? 'bg-white text-gray-400'
          : 'bg-white text-gray-600'
      } text-center flex flex-row justify-between items-center`}
    >
      <div className={'text-app-xxs font-bold uppercase truncate'}>
        <div className="">{dateService.formatUTC(date, 'ddd')}</div>
        {/* <div className="hidden lg:block">
          {dateService.formatUTC(date, 'dddd')}
        </div> */}
      </div>
      <div className="font-bold text-lg">
        {dateService.formatUTC(date, 'D')}
      </div>
      <div className="text-app-xxs truncate">
        <div>{dateService.formatUTC(date, 'MMM')}</div>
        {!isThisYear && <div>{dateService.formatUTC(date, 'YYYY')}</div>}
      </div>
    </div>
  );
};
