/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppMonthIndex } from '@mabadive/app-common-model';
import { appMonthIndexFormatter } from '@mabadive/app-common-services';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

const MONTHS_INDEXES: AppMonthIndex[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export function useAppMonthIndexOptions(): ValueLabel<AppMonthIndex>[] {
  return MONTHS_INDEXES.map((monthIndex) => {
    const option: ValueLabel<AppMonthIndex> = {
      label: appMonthIndexFormatter.format(monthIndex),
      value: monthIndex,
    };
    return option;
  });
}
