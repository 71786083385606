import {
  DiveCenterResume,
  DiveSession,
  DiveSessionResumeFull,
} from '@mabadive/app-common-model';
import { virtualDiveSessionBuilder } from '@mabadive/app-common-services';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import { GraphqlClientQueryType } from 'src/_common-browser/graphql-client/apollo';
import {
  LoadableContentLoadingMode,
  LoadableContentPartial,
  appLoader,
  appLoaderChain,
} from '../../app-loading';
import { diveSessionResumeFullGraphqlFetcher } from './graphql';
import { DiveSessionResumeLoadCriteria } from './model';
import { clubDiveSessionStore } from './store';

export const diveSessionResumeFullRepository = {
  findManyWithSubscription,
  findOne,
  findOneOrCreateVirtual,
  getIfExists,
};

function getIfExists({
  diveSessionReference,
}: {
  diveSessionReference: string;
}): Observable<DiveSessionResumeFull> {
  return findOne(
    { diveSessionReference },
    {
      forceReload: false,
    },
  ).pipe(
    first((x) => x.lastActionStatus !== 'in-progress'),
    map((x) => x.content),
  );
}

function findOne(
  { diveSessionReference }: { diveSessionReference: string },
  {
    forceReload,
    queryType = 'query',
  }: {
    forceReload: boolean;
    queryType?: GraphqlClientQueryType;
  },
): Observable<LoadableContentPartial<DiveSessionResumeFull>> {
  const localLoader$ = appLoader.load(_findOneLocal({ diveSessionReference }), {
    type: forceReload ? 'partial' : 'full',
  });
  const remoteLoader$ = appLoader.load(
    findOneRemote({ diveSessionReference, queryType }),
    { type: 'full' },
  );

  return appLoaderChain.loadChain([localLoader$, remoteLoader$]);
}

function findOneOrCreateVirtual(
  {
    diveSessionReference,
    diveCenterResume,
  }: {
    diveSessionReference: string;
    diveCenterResume: DiveCenterResume;
  },
  {
    forceReload,
    createVirtualIfNotExists,
    queryType = 'query',
  }: {
    forceReload: boolean;
    createVirtualIfNotExists: boolean;
    queryType?: GraphqlClientQueryType;
  },
): Observable<LoadableContentPartial<DiveSessionResumeFull>> {
  if (!diveSessionReference) {
    return of({
      contentState: 'full',
      lastActionStatus: 'success',
      content: undefined,
    });
  }
  const localLoader$ = appLoader.load(_findOneLocal({ diveSessionReference }), {
    type: forceReload ? 'partial' : 'full',
  });
  const remoteLoader$ = appLoader.load(
    findOneRemote({ diveSessionReference, queryType }),
    { type: 'full' },
  );

  const {
    settingsPlanning,
    clubReference,
    _id: diveCenterId,
  } = diveCenterResume;

  return appLoaderChain.loadChain([localLoader$, remoteLoader$]).pipe(
    map((x) => {
      if (
        x.contentState === 'none' &&
        x.lastActionStatus === 'success' &&
        createVirtualIfNotExists
      ) {
        // create virtual if not exists (but not if error)
        const virtualSession: DiveSession =
          virtualDiveSessionBuilder.getByReference({
            diveSessionReference,
            clubReference,
            diveCenterId,
            settingsPlanning,
          });
        const virtualSessionFull: DiveSessionResumeFull =
          virtualDiveSessionBuilder.convertToDiveSessionResumeFull(
            virtualSession,
          );
        return {
          contentState: 'full',
          lastActionStatus: 'success',
          content: virtualSessionFull,
        };
      }
      return x;
    }),
  );
}

function findOneRemote({
  diveSessionReference,
  queryType,
}: {
  diveSessionReference: string;
  queryType?: GraphqlClientQueryType;
}): Observable<DiveSessionResumeFull> {
  return diveSessionResumeFullGraphqlFetcher
    .findOne({ diveSessionReference, queryType })
    .pipe(
      tap((diveSessionResume) => {
        if (diveSessionResume) {
          clubDiveSessionStore.addOneToStore(diveSessionResume);
        }
      }),
    );
}

function _findOneLocal({
  diveSessionReference,
}: {
  diveSessionReference: string;
}) {
  return clubDiveSessionStore.diveSessionResumeFullCollection.getOne({
    criteria: {
      reference: diveSessionReference,
    },
  });
}
function findManyWithSubscription(
  criteria: DiveSessionResumeLoadCriteria,
  {
    mode,
  }: {
    mode: LoadableContentLoadingMode;
  },
): Observable<LoadableContentPartial<DiveSessionResumeFull[]>> {
  if (!criteria) {
    const loadableContent: LoadableContentPartial<DiveSessionResumeFull[]> = {
      contentState: 'partial',
      lastActionStatus: 'in-progress',
      content: [],
    };
    return of(loadableContent);
  }

  const localLoader$ = appLoader.load(_findManyLocal(criteria), {
    type: mode === 'force-reload' ? 'partial' : 'full',
    defaultValue: [],
  });
  const remoteLoader$ = appLoader.load(_findManyRemote(criteria), {
    type: 'full',
    defaultValue: [],
    isSubscription: true,
  });

  if (mode === 'local-only') {
    return localLoader$;
  }
  if (mode === 'remote-only') {
    return remoteLoader$;
  }

  return localLoader$.pipe(
    switchMap((localResults) =>
      remoteLoader$.pipe(
        filter(
          (remoteResults) => remoteResults.lastActionStatus !== 'in-progress',
        ),
        startWith(localResults),
      ),
    ),
  );
}

function _findManyLocal({
  diveCenterId,
  minDateInclusive,
  maxDateExclusive,
}: DiveSessionResumeLoadCriteria) {
  return combineLatest([
    clubDiveSessionStore.loadingState.get(),
    clubDiveSessionStore.diveSessionResumeFullCollection.getAll(),
  ]).pipe(
    first(),
    map(([loadingState, data]) => {
      if (
        loadingState.loaded.loadedWeekTimestamps.includes(
          minDateInclusive.getTime(),
        )
      ) {
        // console.log('xxx data is in cache', data);
        // data is already in cache
        const content = data.filter((x) => {
          if (x.diveCenterId !== diveCenterId) {
            return false;
          }
          const timeAsDate = new Date(x.time);
          return (
            minDateInclusive <= timeAsDate && timeAsDate < maxDateExclusive
          );
        });
        return content;
      } else {
        return undefined;
      }
    }),
  );
}

function _findManyRemote({
  clubReference,
  diveCenterId,
  minDateInclusive,
  maxDateExclusive,
}: DiveSessionResumeLoadCriteria) {
  return diveSessionResumeFullGraphqlFetcher
    .findMany(
      {
        clubReference,
        diveCenterId,
        minDateInclusive,
        maxDateExclusive,
      },
      { type: 'subscription' },
    )
    .pipe(
      tap((diveSessionResumes) => {
        clubDiveSessionStore.addManyToStore({
          minDateInclusive,
          diveSessionResumes,
        });
      }),
    );
}
