import { ClubDiveSite } from '@mabadive/app-common-model';
import {
  clubDiveSitesSorter,
  diveSiteFormatter,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks/useDiveCenterResume.hook';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useDiveSitesOptions({
  currentDiveSitesIds, // permet d'inclure les sites dans la liste si ils ont été désactivés mais qu'ils sont actifs sur ce formulaire
}: {
  currentDiveSitesIds: string[];
}): ValueLabel<string>[] {
  const diveCenterResume = useDiveCenterResume();

  return useMemo(
    () =>
      buildDiveSiteOptions(diveCenterResume?.diveSites, {
        currentDiveSitesIds,
      }),
    [diveCenterResume?.diveSites, currentDiveSitesIds],
  );
}

function buildDiveSiteOptions(
  diveSites: Pick<
    ClubDiveSite,
    '_id' | 'ref' | 'name' | 'emojiId' | 'enabled'
  >[],
  { currentDiveSitesIds }: { currentDiveSitesIds: string[] },
) {
  const sortedDiveSites = clubDiveSitesSorter.sortDiveSitesBy(
    diveSites.filter((x) => x.enabled || currentDiveSitesIds.includes(x._id)),
    {
      attributeName: 'ref',
      asc: true,
    },
  );
  const purchaseOptions = sortedDiveSites.map((diveSite) => {
    const option: ValueLabel<string, string> = {
      value: diveSite._id,
      label: diveSiteFormatter.formatDiveSite(diveSite, {
        showDistance: false,
      }),
    };
    return option;
  });
  return purchaseOptions;
}
