import { AppInquirySection } from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';
import { inqueryQuestionBuilder } from './inqueryQuestionBuilder.service';

export const inquerySectionBuilder = {
  buildSection: ({
    sortIndex,
    sectionTitle,
  }: {
    sortIndex?: number;
    sectionTitle?: string;
  }) => {
    const question = inqueryQuestionBuilder.buildQuestion({
      sortIndex: 1,
      questionTitle: 'Avez-vous ... ?',
    });
    const section: AppInquirySection = {
      sectionId: uuidGenerator.random(),
      sortIndex: sortIndex,
      description: {
        main: {
          title: sectionTitle,
          subTitle: undefined,
          items: [],
          languageCode: 'fr',
        },
        translations: [],
      },
      questions: [question],
    };
    return section;
  },
};
