import { BookingResumeForList } from '@mabadive/app-common-model';
import {
  bookingJourneyStepTransformer,
  commonDiveSessionReferenceParser,
  dateService,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppDateTimeLabel } from 'src/business/club/modules/_common/ui';
import { BillingParticipantPaymentResumeTableDiverName } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BillingParticipantPaymentResumeTable/BillingParticipantPaymentResumeTableDiverName';
import {
  AgencyLabel,
  BookingGroupLabel,
  BookingStatusLabel,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { PeriodDatesLabel } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiverBookingCard/DiverBookingCardHeaderAutoFix/PeriodDatesLabel';
import { clubParticipantUrlBuilder } from 'src/business/club/modules/url-builders';
import { AppLinkStopPropagation } from 'src/lib/browser';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { AppIcons } from '../../../../business/_core/modules/layout/icons';
import { useBookingAgenciesOptions } from '../../../_components/options';

export function ClubBookingsTableRow({
  booking,
}: {
  booking: BookingResumeForList;
}) {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const customerSettings = diveCenterResume?.clubResume?.clubSettings?.customer;
  const agenciesOptions = useBookingAgenciesOptions();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const bookingDiveCenter = useMemo(
    () => clubResume.diveCenters.find((dc) => dc._id === booking.diveCenterId),
    [booking.diveCenterId, clubResume.diveCenters],
  );

  const url = useMemo(() => {
    const url = clubParticipantUrlBuilder.buildTabUrl({
      navigationContext: {
        origin: 'bookings-list',
        diverId: booking.bookingContact._id,
      },
      tabId: 'booking',
    });
    return url;
  }, [booking.bookingContact._id]);

  const beginDate = useMemo(() => {
    const minDiveSessionReference =
      booking.bookingSessionsMeta.aggregate.minDiveSession
        ?.diveSessionReference;
    if (minDiveSessionReference) {
      const { dayReference } =
        commonDiveSessionReferenceParser.parseSessionReference(
          minDiveSessionReference,
        );
      return commonDiveSessionReferenceParser.parseDayReference(dayReference);
    }
  }, [
    booking.bookingSessionsMeta.aggregate.minDiveSession?.diveSessionReference,
  ]);

  const endDate = useMemo(() => {
    const maxDiveSessionReference =
      booking.bookingSessionsMeta.aggregate.maxDiveSession
        ?.diveSessionReference;
    if (maxDiveSessionReference) {
      const { dayReference } =
        commonDiveSessionReferenceParser.parseSessionReference(
          maxDiveSessionReference,
        );
      return commonDiveSessionReferenceParser.parseDayReference(dayReference);
    }
  }, [
    booking.bookingSessionsMeta.aggregate.maxDiveSession?.diveSessionReference,
  ]);

  const bookingDateColor = useMemo(() => {
    const ageInHours = dateService.getDiffInHours(
      booking.bookingDate,
      new Date(),
    );
    if (ageInHours <= 12) {
      return 'text-green-600';
    }
    if (ageInHours <= 48) {
      return 'text-orange-600';
    }
    return 'text-gray-600';
  }, [booking.bookingDate]);

  const isBookingLastUpdateDateColor = useMemo(() => {
    const ageInHours = dateService.getDiffInHours(
      booking.bookingLastUpdateDate,
      new Date(),
    );
    if (ageInHours <= 12) {
      return 'text-green-600';
    }
    if (ageInHours <= 48) {
      return 'text-orange-600';
    }
    return 'text-gray-600';
  }, [booking.bookingLastUpdateDate]);

  const redirectTo = useRedirect();
  const journeyDates = useMemo(
    () => bookingJourneyStepTransformer.getJourneyDates(booking),
    [booking],
  );

  return (
    <tr
      className={'app-tr-highlight'}
      onClick={() => {
        // si on clique sur la ligne, mais en dehors d'un lien, on est quand même redirigé
        redirectTo(url);
      }}
    >
      {isMultiDiveCenters && (
        <td
          className={`whitespace-nowrap text-sm text-gray-600${isBookingLastUpdateDateColor} hidden xs:table-cell`}
        >
          <AppLinkStopPropagation className="block px-2 py-1" to={url}>
            <DiveCenterProBadge
              className="my-px"
              diveCenter={booking.isSharedBooking ? null : bookingDiveCenter}
            />
          </AppLinkStopPropagation>
        </td>
      )}
      <td
        className={`whitespace-nowrap text-sm ${bookingDateColor} hidden lg:table-cell`}
      >
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            date={booking.bookingDate}
            timezone="local"
            format="date-time"
          />
        </AppLinkStopPropagation>
      </td>
      <td
        className={`whitespace-nowrap text-sm text-gray-600${isBookingLastUpdateDateColor} hidden xs:table-cell`}
      >
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="utc"
            date={booking.bookingLastUpdateDate}
            format="date-time"
          />
        </AppLinkStopPropagation>
      </td>
      <td className="text-sm text-gray-600 truncate">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <div className="flex gap-2 justify-between">
            <div className="flex flex-col gap-1 items-start">
              <div className="flex flex-wrap items-start gap-2 xl:gap-4">
                <BillingParticipantPaymentResumeTableDiverName
                  diver={booking.bookingContact}
                  className={'flex-shrink'}
                />
                {booking.bookingGroup && (
                  <BookingGroupLabel
                    className="sm:hidden"
                    bookingGroup={booking.bookingGroup}
                  />
                )}
              </div>
              {(booking.details?.agencyId || booking.details?.externalRef) && (
                <div className="flex gap-2 items-center sm:hidden">
                  <AgencyLabel
                    className="text-xs"
                    agencyId={booking.details?.agencyId}
                  />
                  {booking.details?.externalRef?.trim()?.length > 0 && (
                    <div className="text-xs text-app-dark-orange font-bold">
                      #{booking.details?.externalRef}
                    </div>
                  )}
                </div>
              )}
            </div>
            {booking.bookingCustomerUpdates.length > 0 && (
              <div>
                <AppIcons.ui.notification className="flex-0 w-6 h-6 px-0.5 sm:w-7 sm:h-7 sm:p-1 bg-status-error text-white rounded-full" />
              </div>
            )}
          </div>
        </AppLinkStopPropagation>
      </td>
      <td className="text-sm text-gray-600 truncate hidden sm:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {(booking.details?.agencyId || booking.details?.externalRef) && (
            <div className="grid gap-1">
              {booking.bookingGroup && (
                <BookingGroupLabel
                  maxLabelWidthClassName="max-w-[200px]"
                  bookingGroup={booking.bookingGroup}
                />
              )}
              <AgencyLabel
                className="text-xs"
                agencyId={booking.details?.agencyId}
              />
              {booking.details?.externalRef?.trim()?.length > 0 && (
                <div className="text-xs text-app-dark-orange font-bold">
                  #{booking.details?.externalRef}
                </div>
              )}
            </div>
          )}
        </AppLinkStopPropagation>
      </td>

      <td className="whitespace-nowrap text-sm hidden lg:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {beginDate ? (
            <div className="flex gap-2 text-gray-600 font-bold">
              <span>{dateService.formatLocal(beginDate, 'DD/MM/YYYY')}</span>
              {endDate && endDate.getTime() !== beginDate.getTime() && (
                <>
                  <span>-</span>
                  <span>{dateService.formatLocal(endDate, 'DD/MM/YYYY')}</span>
                </>
              )}
            </div>
          ) : (
            <PeriodDatesLabel
              className="text-gray-600 font-bold"
              prefix="séjour "
              beginDate={journeyDates.arrival}
              endDate={journeyDates.departure}
            />
          )}
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm font-bold text-gray-600 hidden md:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {booking.bookingMembersMeta.aggregate.count}
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-600 hidden md:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {booking.bookingSessionsMeta.aggregate.count}
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-600">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {booking.bookingSessionParticipantsMeta.aggregate.count}
        </AppLinkStopPropagation>
      </td>
      <td className="">
        <BookingStatusLabel
          fontSizeClass="text-app-xxs md:text-xs"
          booking={booking}
        />
      </td>
    </tr>
  );
}
