/**
Styles
  - dive-mode-observer
  - dive-mode-snorkeling
  - dive-mode-first-dive
  - dive-mode-training
  - dive-mode-theoretical-training
  - dive-mode-supervised
  - dive-mode-autonomous
  - dive-mode-free-dive
  - dive-mode-unknwon
  - dive-mode-instructor

Elements
  - bg
  - text
  - border
  - ring
  
Variants:
  - group-hover
  - hover
  - focus
 */

export const TAILWIND_COLORS_DIVE_MODE = `
  bg-dive-mode-observer bg-dive-mode-observer/10 hover:bg-dive-mode-observer group-hover:bg-dive-mode-observer focus:bg-dive-mode-observer text-dive-mode-observer hover:text-dive-mode-observer group-hover:text-dive-mode-observer focus:text-dive-mode-observer border-dive-mode-observer hover:border-dive-mode-observer group-hover:border-dive-mode-observer focus:border-dive-mode-observer ring-dive-mode-observer hover:ring-dive-mode-observer group-hover:ring-dive-mode-observer focus:ring-dive-mode-observer
  bg-dive-mode-observer-light bg-dive-mode-observer-light/10 hover:bg-dive-mode-observer-light group-hover:bg-dive-mode-observer-light focus:bg-dive-mode-observer-light text-dive-mode-observer-light hover:text-dive-mode-observer-light group-hover:text-dive-mode-observer-light focus:text-dive-mode-observer-light border-dive-mode-observer-light hover:border-dive-mode-observer-light group-hover:border-dive-mode-observer-light focus:border-dive-mode-observer-light ring-dive-mode-observer-light hover:ring-dive-mode-observer-light group-hover:ring-dive-mode-observer-light focus:ring-dive-mode-observer-light
  bg-dive-mode-observer-dark bg-dive-mode-observer-dark/10 hover:bg-dive-mode-observer-dark group-hover:bg-dive-mode-observer-dark focus:bg-dive-mode-observer-dark text-dive-mode-observer-dark hover:text-dive-mode-observer-dark group-hover:text-dive-mode-observer-dark focus:text-dive-mode-observer-dark border-dive-mode-observer-dark hover:border-dive-mode-observer-dark group-hover:border-dive-mode-observer-dark focus:border-dive-mode-observer-dark ring-dive-mode-observer-dark hover:ring-dive-mode-observer-dark group-hover:ring-dive-mode-observer-dark focus:ring-dive-mode-observer-dark
  bg-dive-mode-snorkeling bg-dive-mode-snorkeling/10 hover:bg-dive-mode-snorkeling group-hover:bg-dive-mode-snorkeling focus:bg-dive-mode-snorkeling text-dive-mode-snorkeling hover:text-dive-mode-snorkeling group-hover:text-dive-mode-snorkeling focus:text-dive-mode-snorkeling border-dive-mode-snorkeling hover:border-dive-mode-snorkeling group-hover:border-dive-mode-snorkeling focus:border-dive-mode-snorkeling ring-dive-mode-snorkeling hover:ring-dive-mode-snorkeling group-hover:ring-dive-mode-snorkeling focus:ring-dive-mode-snorkeling
  bg-dive-mode-snorkeling-light bg-dive-mode-snorkeling-light/10 hover:bg-dive-mode-snorkeling-light group-hover:bg-dive-mode-snorkeling-light focus:bg-dive-mode-snorkeling-light text-dive-mode-snorkeling-light hover:text-dive-mode-snorkeling-light group-hover:text-dive-mode-snorkeling-light focus:text-dive-mode-snorkeling-light border-dive-mode-snorkeling-light hover:border-dive-mode-snorkeling-light group-hover:border-dive-mode-snorkeling-light focus:border-dive-mode-snorkeling-light ring-dive-mode-snorkeling-light hover:ring-dive-mode-snorkeling-light group-hover:ring-dive-mode-snorkeling-light focus:ring-dive-mode-snorkeling-light
  bg-dive-mode-snorkeling-dark bg-dive-mode-snorkeling-dark/10 hover:bg-dive-mode-snorkeling-dark group-hover:bg-dive-mode-snorkeling-dark focus:bg-dive-mode-snorkeling-dark text-dive-mode-snorkeling-dark hover:text-dive-mode-snorkeling-dark group-hover:text-dive-mode-snorkeling-dark focus:text-dive-mode-snorkeling-dark border-dive-mode-snorkeling-dark hover:border-dive-mode-snorkeling-dark group-hover:border-dive-mode-snorkeling-dark focus:border-dive-mode-snorkeling-dark ring-dive-mode-snorkeling-dark hover:ring-dive-mode-snorkeling-dark group-hover:ring-dive-mode-snorkeling-dark focus:ring-dive-mode-snorkeling-dark
  bg-dive-mode-snorkelingSupervised hover:bg-dive-mode-snorkelingSupervised group-hover:bg-dive-mode-snorkelingSupervised focus:bg-dive-mode-snorkelingSupervised text-dive-mode-snorkelingSupervised hover:text-dive-mode-snorkelingSupervised group-hover:text-dive-mode-snorkelingSupervised focus:text-dive-mode-snorkelingSupervised border-dive-mode-snorkelingSupervised hover:border-dive-mode-snorkelingSupervised group-hover:border-dive-mode-snorkelingSupervised focus:border-dive-mode-snorkelingSupervised ring-dive-mode-snorkelingSupervised hover:ring-dive-mode-snorkelingSupervised group-hover:ring-dive-mode-snorkelingSupervised focus:ring-dive-mode-snorkelingSupervised
  bg-dive-mode-snorkelingSupervised-light hover:bg-dive-mode-snorkelingSupervised-light group-hover:bg-dive-mode-snorkelingSupervised-light focus:bg-dive-mode-snorkelingSupervised-light text-dive-mode-snorkelingSupervised-light hover:text-dive-mode-snorkelingSupervised-light group-hover:text-dive-mode-snorkelingSupervised-light focus:text-dive-mode-snorkelingSupervised-light border-dive-mode-snorkelingSupervised-light hover:border-dive-mode-snorkelingSupervised-light group-hover:border-dive-mode-snorkelingSupervised-light focus:border-dive-mode-snorkelingSupervised-light ring-dive-mode-snorkelingSupervised-light hover:ring-dive-mode-snorkelingSupervised-light group-hover:ring-dive-mode-snorkelingSupervised-light focus:ring-dive-mode-snorkelingSupervised-light
  bg-dive-mode-snorkelingSupervised-dark hover:bg-dive-mode-snorkelingSupervised-dark group-hover:bg-dive-mode-snorkelingSupervised-dark focus:bg-dive-mode-snorkelingSupervised-dark text-dive-mode-snorkelingSupervised-dark hover:text-dive-mode-snorkelingSupervised-dark group-hover:text-dive-mode-snorkelingSupervised-dark focus:text-dive-mode-snorkelingSupervised-dark border-dive-mode-snorkelingSupervised-dark hover:border-dive-mode-snorkelingSupervised-dark group-hover:border-dive-mode-snorkelingSupervised-dark focus:border-dive-mode-snorkelingSupervised-dark ring-dive-mode-snorkelingSupervised-dark hover:ring-dive-mode-snorkelingSupervised-dark group-hover:ring-dive-mode-snorkelingSupervised-dark focus:ring-dive-mode-snorkelingSupervised-dark
  bg-dive-mode-first-dive bg-dive-mode-first-dive/10 hover:bg-dive-mode-first-dive group-hover:bg-dive-mode-first-dive focus:bg-dive-mode-first-dive text-dive-mode-first-dive hover:text-dive-mode-first-dive group-hover:text-dive-mode-first-dive focus:text-dive-mode-first-dive border-dive-mode-first-dive hover:border-dive-mode-first-dive group-hover:border-dive-mode-first-dive focus:border-dive-mode-first-dive ring-dive-mode-first-dive hover:ring-dive-mode-first-dive group-hover:ring-dive-mode-first-dive focus:ring-dive-mode-first-dive
  bg-dive-mode-first-dive-light bg-dive-mode-first-dive-light/10 hover:bg-dive-mode-first-dive-light group-hover:bg-dive-mode-first-dive-light focus:bg-dive-mode-first-dive-light text-dive-mode-first-dive-light hover:text-dive-mode-first-dive-light group-hover:text-dive-mode-first-dive-light focus:text-dive-mode-first-dive-light border-dive-mode-first-dive-light hover:border-dive-mode-first-dive-light group-hover:border-dive-mode-first-dive-light focus:border-dive-mode-first-dive-light ring-dive-mode-first-dive-light hover:ring-dive-mode-first-dive-light group-hover:ring-dive-mode-first-dive-light focus:ring-dive-mode-first-dive-light
  bg-dive-mode-first-dive-dark bg-dive-mode-first-dive-dark/10 hover:bg-dive-mode-first-dive-dark group-hover:bg-dive-mode-first-dive-dark focus:bg-dive-mode-first-dive-dark text-dive-mode-first-dive-dark hover:text-dive-mode-first-dive-dark group-hover:text-dive-mode-first-dive-dark focus:text-dive-mode-first-dive-dark border-dive-mode-first-dive-dark hover:border-dive-mode-first-dive-dark group-hover:border-dive-mode-first-dive-dark focus:border-dive-mode-first-dive-dark ring-dive-mode-first-dive-dark hover:ring-dive-mode-first-dive-dark group-hover:ring-dive-mode-first-dive-dark focus:ring-dive-mode-first-dive-dark
  bg-dive-mode-first-dive-alt bg-dive-mode-first-dive-alt/10 hover:bg-dive-mode-first-dive-alt group-hover:bg-dive-mode-first-dive-alt focus:bg-dive-mode-first-dive-alt text-dive-mode-first-dive-alt hover:text-dive-mode-first-dive-alt group-hover:text-dive-mode-first-dive-alt focus:text-dive-mode-first-dive-alt border-dive-mode-first-dive-alt hover:border-dive-mode-first-dive-alt group-hover:border-dive-mode-first-dive-alt focus:border-dive-mode-first-dive-alt ring-dive-mode-first-dive-alt hover:ring-dive-mode-first-dive-alt group-hover:ring-dive-mode-first-dive-alt focus:ring-dive-mode-first-dive-alt
  bg-dive-mode-first-dive-alt-light bg-dive-mode-first-dive-alt-light/10 hover:bg-dive-mode-first-dive-alt-light group-hover:bg-dive-mode-first-dive-alt-light focus:bg-dive-mode-first-dive-alt-light text-dive-mode-first-dive-alt-light hover:text-dive-mode-first-dive-alt-light group-hover:text-dive-mode-first-dive-alt-light focus:text-dive-mode-first-dive-alt-light border-dive-mode-first-dive-alt-light hover:border-dive-mode-first-dive-alt-light group-hover:border-dive-mode-first-dive-alt-light focus:border-dive-mode-first-dive-alt-light ring-dive-mode-first-dive-alt-light hover:ring-dive-mode-first-dive-alt-light group-hover:ring-dive-mode-first-dive-alt-light focus:ring-dive-mode-first-dive-alt-light
  bg-dive-mode-first-dive-alt-dark bg-dive-mode-first-dive-alt-dark/10 hover:bg-dive-mode-first-dive-alt-dark group-hover:bg-dive-mode-first-dive-alt-dark focus:bg-dive-mode-first-dive-alt-dark text-dive-mode-first-dive-alt-dark hover:text-dive-mode-first-dive-alt-dark group-hover:text-dive-mode-first-dive-alt-dark focus:text-dive-mode-first-dive-alt-dark border-dive-mode-first-dive-alt-dark hover:border-dive-mode-first-dive-alt-dark group-hover:border-dive-mode-first-dive-alt-dark focus:border-dive-mode-first-dive-alt-dark ring-dive-mode-first-dive-alt-dark hover:ring-dive-mode-first-dive-alt-dark group-hover:ring-dive-mode-first-dive-alt-dark focus:ring-dive-mode-first-dive-alt-dark
  bg-dive-mode-training bg-dive-mode-training/10 hover:bg-dive-mode-training group-hover:bg-dive-mode-training focus:bg-dive-mode-training text-dive-mode-training hover:text-dive-mode-training group-hover:text-dive-mode-training focus:text-dive-mode-training border-dive-mode-training hover:border-dive-mode-training group-hover:border-dive-mode-training focus:border-dive-mode-training ring-dive-mode-training hover:ring-dive-mode-training group-hover:ring-dive-mode-training focus:ring-dive-mode-training
  bg-dive-mode-training-light bg-dive-mode-training-light/10 hover:bg-dive-mode-training-light group-hover:bg-dive-mode-training-light focus:bg-dive-mode-training-light text-dive-mode-training-light hover:text-dive-mode-training-light group-hover:text-dive-mode-training-light focus:text-dive-mode-training-light border-dive-mode-training-light hover:border-dive-mode-training-light group-hover:border-dive-mode-training-light focus:border-dive-mode-training-light ring-dive-mode-training-light hover:ring-dive-mode-training-light group-hover:ring-dive-mode-training-light focus:ring-dive-mode-training-light
  bg-dive-mode-training-dark bg-dive-mode-training-dark/10 hover:bg-dive-mode-training-dark group-hover:bg-dive-mode-training-dark focus:bg-dive-mode-training-dark text-dive-mode-training-dark hover:text-dive-mode-training-dark group-hover:text-dive-mode-training-dark focus:text-dive-mode-training-dark border-dive-mode-training-dark hover:border-dive-mode-training-dark group-hover:border-dive-mode-training-dark focus:border-dive-mode-training-dark ring-dive-mode-training-dark hover:ring-dive-mode-training-dark group-hover:ring-dive-mode-training-dark focus:ring-dive-mode-training-dark
  bg-dive-mode-theoretical-training bg-dive-mode-theoretical-training/10 hover:bg-dive-mode-theoretical-training group-hover:bg-dive-mode-theoretical-training focus:bg-dive-mode-theoretical-training text-dive-mode-theoretical-training hover:text-dive-mode-theoretical-training group-hover:text-dive-mode-theoretical-training focus:text-dive-mode-theoretical-training border-dive-mode-theoretical-training hover:border-dive-mode-theoretical-training group-hover:border-dive-mode-theoretical-training focus:border-dive-mode-theoretical-training ring-dive-mode-theoretical-training hover:ring-dive-mode-theoretical-training group-hover:ring-dive-mode-theoretical-training focus:ring-dive-mode-theoretical-training
  bg-dive-mode-theoretical-training-light bg-dive-mode-theoretical-training-light/10 hover:bg-dive-mode-theoretical-training-light group-hover:bg-dive-mode-theoretical-training-light focus:bg-dive-mode-theoretical-training-light text-dive-mode-theoretical-training-light hover:text-dive-mode-theoretical-training-light group-hover:text-dive-mode-theoretical-training-light focus:text-dive-mode-theoretical-training-light border-dive-mode-theoretical-training-light hover:border-dive-mode-theoretical-training-light group-hover:border-dive-mode-theoretical-training-light focus:border-dive-mode-theoretical-training-light ring-dive-mode-theoretical-training-light hover:ring-dive-mode-theoretical-training-light group-hover:ring-dive-mode-theoretical-training-light focus:ring-dive-mode-theoretical-training-light
  bg-dive-mode-theoretical-training-dark bg-dive-mode-theoretical-training-dark/10 hover:bg-dive-mode-theoretical-training-dark group-hover:bg-dive-mode-theoretical-training-dark focus:bg-dive-mode-theoretical-training-dark text-dive-mode-theoretical-training-dark hover:text-dive-mode-theoretical-training-dark group-hover:text-dive-mode-theoretical-training-dark focus:text-dive-mode-theoretical-training-dark border-dive-mode-theoretical-training-dark hover:border-dive-mode-theoretical-training-dark group-hover:border-dive-mode-theoretical-training-dark focus:border-dive-mode-theoretical-training-dark ring-dive-mode-theoretical-training-dark hover:ring-dive-mode-theoretical-training-dark group-hover:ring-dive-mode-theoretical-training-dark focus:ring-dive-mode-theoretical-training-dark
  bg-dive-mode-supervised bg-dive-mode-supervised/10 hover:bg-dive-mode-supervised group-hover:bg-dive-mode-supervised focus:bg-dive-mode-supervised text-dive-mode-supervised hover:text-dive-mode-supervised group-hover:text-dive-mode-supervised focus:text-dive-mode-supervised border-dive-mode-supervised hover:border-dive-mode-supervised group-hover:border-dive-mode-supervised focus:border-dive-mode-supervised ring-dive-mode-supervised hover:ring-dive-mode-supervised group-hover:ring-dive-mode-supervised focus:ring-dive-mode-supervised
  bg-dive-mode-supervised-light bg-dive-mode-supervised-light/10 hover:bg-dive-mode-supervised-light group-hover:bg-dive-mode-supervised-light focus:bg-dive-mode-supervised-light text-dive-mode-supervised-light hover:text-dive-mode-supervised-light group-hover:text-dive-mode-supervised-light focus:text-dive-mode-supervised-light border-dive-mode-supervised-light hover:border-dive-mode-supervised-light group-hover:border-dive-mode-supervised-light focus:border-dive-mode-supervised-light ring-dive-mode-supervised-light hover:ring-dive-mode-supervised-light group-hover:ring-dive-mode-supervised-light focus:ring-dive-mode-supervised-light
  bg-dive-mode-supervised-dark bg-dive-mode-supervised-dark/10 hover:bg-dive-mode-supervised-dark group-hover:bg-dive-mode-supervised-dark focus:bg-dive-mode-supervised-dark text-dive-mode-supervised-dark hover:text-dive-mode-supervised-dark group-hover:text-dive-mode-supervised-dark focus:text-dive-mode-supervised-dark border-dive-mode-supervised-dark hover:border-dive-mode-supervised-dark group-hover:border-dive-mode-supervised-dark focus:border-dive-mode-supervised-dark ring-dive-mode-supervised-dark hover:ring-dive-mode-supervised-dark group-hover:ring-dive-mode-supervised-dark focus:ring-dive-mode-supervised-dark
  bg-dive-mode-autonomous bg-dive-mode-autonomous/10 hover:bg-dive-mode-autonomous group-hover:bg-dive-mode-autonomous focus:bg-dive-mode-autonomous text-dive-mode-autonomous hover:text-dive-mode-autonomous group-hover:text-dive-mode-autonomous focus:text-dive-mode-autonomous border-dive-mode-autonomous hover:border-dive-mode-autonomous group-hover:border-dive-mode-autonomous focus:border-dive-mode-autonomous ring-dive-mode-autonomous hover:ring-dive-mode-autonomous group-hover:ring-dive-mode-autonomous focus:ring-dive-mode-autonomous
  bg-dive-mode-autonomous-light bg-dive-mode-autonomous-light/10 hover:bg-dive-mode-autonomous-light group-hover:bg-dive-mode-autonomous-light focus:bg-dive-mode-autonomous-light text-dive-mode-autonomous-light hover:text-dive-mode-autonomous-light group-hover:text-dive-mode-autonomous-light focus:text-dive-mode-autonomous-light border-dive-mode-autonomous-light hover:border-dive-mode-autonomous-light group-hover:border-dive-mode-autonomous-light focus:border-dive-mode-autonomous-light ring-dive-mode-autonomous-light hover:ring-dive-mode-autonomous-light group-hover:ring-dive-mode-autonomous-light focus:ring-dive-mode-autonomous-light
  bg-dive-mode-autonomous-dark bg-dive-mode-autonomous-dark/10 hover:bg-dive-mode-autonomous-dark group-hover:bg-dive-mode-autonomous-dark focus:bg-dive-mode-autonomous-dark text-dive-mode-autonomous-dark hover:text-dive-mode-autonomous-dark group-hover:text-dive-mode-autonomous-dark focus:text-dive-mode-autonomous-dark border-dive-mode-autonomous-dark hover:border-dive-mode-autonomous-dark group-hover:border-dive-mode-autonomous-dark focus:border-dive-mode-autonomous-dark ring-dive-mode-autonomous-dark hover:ring-dive-mode-autonomous-dark group-hover:ring-dive-mode-autonomous-dark focus:ring-dive-mode-autonomous-dark
  bg-dive-mode-autoSupervised hover:bg-dive-mode-autoSupervised group-hover:bg-dive-mode-autoSupervised focus:bg-dive-mode-autoSupervised text-dive-mode-autoSupervised hover:text-dive-mode-autoSupervised group-hover:text-dive-mode-autoSupervised focus:text-dive-mode-autoSupervised border-dive-mode-autoSupervised hover:border-dive-mode-autoSupervised group-hover:border-dive-mode-autoSupervised focus:border-dive-mode-autoSupervised ring-dive-mode-autoSupervised hover:ring-dive-mode-autoSupervised group-hover:ring-dive-mode-autoSupervised focus:ring-dive-mode-autoSupervised
  bg-dive-mode-autoSupervised-light hover:bg-dive-mode-autoSupervised-light group-hover:bg-dive-mode-autoSupervised-light focus:bg-dive-mode-autoSupervised-light text-dive-mode-autoSupervised-light hover:text-dive-mode-autoSupervised-light group-hover:text-dive-mode-autoSupervised-light focus:text-dive-mode-autoSupervised-light border-dive-mode-autoSupervised-light hover:border-dive-mode-autoSupervised-light group-hover:border-dive-mode-autoSupervised-light focus:border-dive-mode-autoSupervised-light ring-dive-mode-autoSupervised-light hover:ring-dive-mode-autoSupervised-light group-hover:ring-dive-mode-autoSupervised-light focus:ring-dive-mode-autoSupervised-light
  bg-dive-mode-autoSupervised-dark hover:bg-dive-mode-autoSupervised-dark group-hover:bg-dive-mode-autoSupervised-dark focus:bg-dive-mode-autoSupervised-dark text-dive-mode-autoSupervised-dark hover:text-dive-mode-autoSupervised-dark group-hover:text-dive-mode-autoSupervised-dark focus:text-dive-mode-autoSupervised-dark border-dive-mode-autoSupervised-dark hover:border-dive-mode-autoSupervised-dark group-hover:border-dive-mode-autoSupervised-dark focus:border-dive-mode-autoSupervised-dark ring-dive-mode-autoSupervised-dark hover:ring-dive-mode-autoSupervised-dark group-hover:ring-dive-mode-autoSupervised-dark focus:ring-dive-mode-autoSupervised-dark
  bg-dive-mode-free-dive bg-dive-mode-free-dive/10 hover:bg-dive-mode-free-dive group-hover:bg-dive-mode-free-dive focus:bg-dive-mode-free-dive text-dive-mode-free-dive hover:text-dive-mode-free-dive group-hover:text-dive-mode-free-dive focus:text-dive-mode-free-dive border-dive-mode-free-dive hover:border-dive-mode-free-dive group-hover:border-dive-mode-free-dive focus:border-dive-mode-free-dive ring-dive-mode-free-dive hover:ring-dive-mode-free-dive group-hover:ring-dive-mode-free-dive focus:ring-dive-mode-free-dive
  bg-dive-mode-free-dive-light bg-dive-mode-free-dive-light/10 hover:bg-dive-mode-free-dive-light group-hover:bg-dive-mode-free-dive-light focus:bg-dive-mode-free-dive-light text-dive-mode-free-dive-light hover:text-dive-mode-free-dive-light group-hover:text-dive-mode-free-dive-light focus:text-dive-mode-free-dive-light border-dive-mode-free-dive-light hover:border-dive-mode-free-dive-light group-hover:border-dive-mode-free-dive-light focus:border-dive-mode-free-dive-light ring-dive-mode-free-dive-light hover:ring-dive-mode-free-dive-light group-hover:ring-dive-mode-free-dive-light focus:ring-dive-mode-free-dive-light
  bg-dive-mode-free-dive-dark bg-dive-mode-free-dive-dark/10 hover:bg-dive-mode-free-dive-dark group-hover:bg-dive-mode-free-dive-dark focus:bg-dive-mode-free-dive-dark text-dive-mode-free-dive-dark hover:text-dive-mode-free-dive-dark group-hover:text-dive-mode-free-dive-dark focus:text-dive-mode-free-dive-dark border-dive-mode-free-dive-dark hover:border-dive-mode-free-dive-dark group-hover:border-dive-mode-free-dive-dark focus:border-dive-mode-free-dive-dark ring-dive-mode-free-dive-dark hover:ring-dive-mode-free-dive-dark group-hover:ring-dive-mode-free-dive-dark focus:ring-dive-mode-free-dive-dark
  bg-dive-mode-unknwon bg-dive-mode-unknwon/10 hover:bg-dive-mode-unknwon group-hover:bg-dive-mode-unknwon focus:bg-dive-mode-unknwon text-dive-mode-unknwon hover:text-dive-mode-unknwon group-hover:text-dive-mode-unknwon focus:text-dive-mode-unknwon border-dive-mode-unknwon hover:border-dive-mode-unknwon group-hover:border-dive-mode-unknwon focus:border-dive-mode-unknwon ring-dive-mode-unknwon hover:ring-dive-mode-unknwon group-hover:ring-dive-mode-unknwon focus:ring-dive-mode-unknwon
  bg-dive-mode-unknwon-light bg-dive-mode-unknwon-light/10 hover:bg-dive-mode-unknwon-light group-hover:bg-dive-mode-unknwon-light focus:bg-dive-mode-unknwon-light text-dive-mode-unknwon-light hover:text-dive-mode-unknwon-light group-hover:text-dive-mode-unknwon-light focus:text-dive-mode-unknwon-light border-dive-mode-unknwon-light hover:border-dive-mode-unknwon-light group-hover:border-dive-mode-unknwon-light focus:border-dive-mode-unknwon-light ring-dive-mode-unknwon-light hover:ring-dive-mode-unknwon-light group-hover:ring-dive-mode-unknwon-light focus:ring-dive-mode-unknwon-light
  bg-dive-mode-unknwon-dark bg-dive-mode-unknwon-dark/10 hover:bg-dive-mode-unknwon-dark group-hover:bg-dive-mode-unknwon-dark focus:bg-dive-mode-unknwon-dark text-dive-mode-unknwon-dark hover:text-dive-mode-unknwon-dark group-hover:text-dive-mode-unknwon-dark focus:text-dive-mode-unknwon-dark border-dive-mode-unknwon-dark hover:border-dive-mode-unknwon-dark group-hover:border-dive-mode-unknwon-dark focus:border-dive-mode-unknwon-dark ring-dive-mode-unknwon-dark hover:ring-dive-mode-unknwon-dark group-hover:ring-dive-mode-unknwon-dark focus:ring-dive-mode-unknwon-dark
  bg-dive-mode-instructor bg-dive-mode-instructor/10 hover:bg-dive-mode-instructor group-hover:bg-dive-mode-instructor focus:bg-dive-mode-instructor text-dive-mode-instructor hover:text-dive-mode-instructor group-hover:text-dive-mode-instructor focus:text-dive-mode-instructor border-dive-mode-instructor hover:border-dive-mode-instructor group-hover:border-dive-mode-instructor focus:border-dive-mode-instructor ring-dive-mode-instructor hover:ring-dive-mode-instructor group-hover:ring-dive-mode-instructor focus:ring-dive-mode-instructor
  bg-dive-mode-instructor-light bg-dive-mode-instructor-light/10 hover:bg-dive-mode-instructor-light group-hover:bg-dive-mode-instructor-light focus:bg-dive-mode-instructor-light text-dive-mode-instructor-light hover:text-dive-mode-instructor-light group-hover:text-dive-mode-instructor-light focus:text-dive-mode-instructor-light border-dive-mode-instructor-light hover:border-dive-mode-instructor-light group-hover:border-dive-mode-instructor-light focus:border-dive-mode-instructor-light ring-dive-mode-instructor-light hover:ring-dive-mode-instructor-light group-hover:ring-dive-mode-instructor-light focus:ring-dive-mode-instructor-light
  bg-dive-mode-instructor-dark bg-dive-mode-instructor-dark/10 hover:bg-dive-mode-instructor-dark group-hover:bg-dive-mode-instructor-dark focus:bg-dive-mode-instructor-dark text-dive-mode-instructor-dark hover:text-dive-mode-instructor-dark group-hover:text-dive-mode-instructor-dark focus:text-dive-mode-instructor-dark border-dive-mode-instructor-dark hover:border-dive-mode-instructor-dark group-hover:border-dive-mode-instructor-dark focus:border-dive-mode-instructor-dark ring-dive-mode-instructor-dark hover:ring-dive-mode-instructor-dark group-hover:ring-dive-mode-instructor-dark focus:ring-dive-mode-instructor-dark
`;
