import { ClubParticipantNavigationOrigin } from '../../../../ClubParticipantNavigationContext';
import { DiverBookingPageTabId } from '../../components/DiverBookingPageTab';
import { DiverBookingPageConfig } from '../../models';

export const diverBookingPageUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const DIVER_BOOKING_PAGE_QUERY_PARAMS = {
  tab: 't',
  origin: 'origin',
  diveSessionReference: 'diveSessionReference',
  action: 'action',
};

function getQueryParameters(): Pick<
  DiverBookingPageConfig,
  'tab' | 'origin' | 'diveSessionReference' | 'action'
> {
  const params = new URLSearchParams(window.location.search);

  const tab = params.get(
    DIVER_BOOKING_PAGE_QUERY_PARAMS.tab,
  ) as DiverBookingPageTabId;

  const origin = params.get(
    DIVER_BOOKING_PAGE_QUERY_PARAMS.origin,
  ) as ClubParticipantNavigationOrigin;

  const diveSessionReference = params.get(
    DIVER_BOOKING_PAGE_QUERY_PARAMS.diveSessionReference,
  ) as string;

  const action = params.get(DIVER_BOOKING_PAGE_QUERY_PARAMS.action) as string;

  const config: Pick<
    DiverBookingPageConfig,
    'tab' | 'origin' | 'diveSessionReference' | 'action'
  > = {
    tab,
    origin,
    diveSessionReference,
    action,
  };
  return config;
}

function buildSearchParams(
  viewConfig: Partial<DiverBookingPageConfig>,
): string[] {
  if (!viewConfig) {
    return [];
  }
  const { tab, origin, diveSessionReference, action } = viewConfig;

  const searchParams: string[] = [];

  if (tab) {
    searchParams.push(`${DIVER_BOOKING_PAGE_QUERY_PARAMS.tab}=${tab}`);
  }
  if (origin) {
    searchParams.push(`${DIVER_BOOKING_PAGE_QUERY_PARAMS.origin}=${origin}`);
  }
  if (diveSessionReference) {
    searchParams.push(
      `${DIVER_BOOKING_PAGE_QUERY_PARAMS.diveSessionReference}=${diveSessionReference}`,
    );
  }
  if (action) {
    searchParams.push(`${DIVER_BOOKING_PAGE_QUERY_PARAMS.action}=${action}`);
  }

  return searchParams;
}
