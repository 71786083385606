/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { defaultDiverBuilder } from '../../services';
import { defaultBookingBuilder } from '../../services/04.aggregated-state/defaultBookingBuilder.service';
import {
  BookingCreditNoteEditDialogInitialState,
  BookingCreditNoteEditFormModel,
} from './model';
export function useBookingCreditNoteEditDialogLocalState({
  initialState,
}: {
  initialState: BookingCreditNoteEditDialogInitialState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;

  const aggregatedData = initialState.aggregatedData;
  const bookings = aggregatedData?.bookings;
  const divers = aggregatedData?.divers;
  const filteredDiverId = aggregatedData?.focus?.filteredDiverId;

  const purchasePayment =
    initialState.purchasePaymentWithDetails?.purchasePayment;

  const defaultValues: BookingCreditNoteEditFormModel = useMemo(() => {
    if (initialState.mode === 'create') {
      const defaultBookingId = defaultBookingBuilder.getDefaultBookingId(
        aggregatedData.bookingResumesVisible,
        {
          diveCenterId,
          focusDiverId: aggregatedData.focus?.clubDiver._id,
        },
      );
      const defaultDiverId =
        defaultDiverBuilder.getDefaultDiverId(aggregatedData);
      const defaultValues: BookingCreditNoteEditFormModel = {
        creditNote: {
          creationDate: new Date(),
          bookingId: defaultBookingId,
          purchasePaymentId: purchasePayment._id,
          diverId: defaultDiverId,
          amount: purchasePayment.amount,
          currencyIsoCode: purchasePayment.currencyIsoCode,
          details: {},
        },
      };
      return defaultValues;
    } else {
      return initialState.defaultValue;
    }
  }, [
    aggregatedData,
    diveCenterId,
    initialState.defaultValue,
    initialState.mode,
    purchasePayment._id,
    purchasePayment.amount,
    purchasePayment.currencyIsoCode,
  ]);

  const form = useForm<BookingCreditNoteEditFormModel>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  return {
    data: {
      diveCenterResume,
      diveCenterId,
      aggregatedData,
      bookings,
      divers,
      filteredDiverId,
    },
    form,
    hasFormChanges,
    initialState,
  };
}

export type BookingCreditNoteEditDialogLocalState = ReturnType<
  typeof useBookingCreditNoteEditDialogLocalState
>;
