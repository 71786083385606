/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService, diveSessionsSorter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { useDailyExportDownloadModal } from 'src/pages/DA-dashboard/_core/components/DailySessionsResumeCard/DailyExportDownloadModal';
import { ClubDialogsState } from 'src/pages/_dialogs';
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import {
  CreateSessionButton,
  DiveSessionSecuritySheetCardMenuActionsDef,
} from '../components';
import { CreateEventButton } from '../components/CreateEventButton';
import { SetPlanningConfigOptions } from '../hooks';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
} from '../model';
import { ClubPlanningLightPanelStaffDailyCounters } from './ClubPlanningLightPanelStaffDailyCounters';
import { ClubPlanningLightPanelViewPeriodDayBeforeDive } from './ClubPlanningLightPanelViewPeriodDayBeforeDive';
import { ClubPlanningLightPanelViewPeriodDayHorizontal } from './ClubPlanningLightPanelViewPeriodDayHorizontal';
import { ClubPlanningLightPanelViewPeriodDayStaff } from './ClubPlanningLightPanelViewPeriodDayStaff';
export const ClubPlanningLightPanelViewPeriodDay = ({
  planningParticipantsLocalState,
  context,
  days,
  planningConfig,
  setPlanningConfig,
  clickActions,
  sessionsDisplayConfigs = [],
  sessionCardMenuActions,
  dialogsState, // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  className,
}: {
  planningParticipantsLocalState?: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
  context: ClubPlanningDisplayConfigurationBarContext;
  days: ClubPlanningDayFull[];
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  clickActions: ClubPlanningLightPanelViewClickActions;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  sessionCardMenuActions?: DiveSessionSecuritySheetCardMenuActionsDef;
  dialogsState: ClubDialogsState; // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume?.clubReference;

  const dailyExportsSettings =
    diveCenterResume.privateSettings.exports.dailyResumes;
  const az = useAppSecurityUserClubAuthorizations();

  const day = useMemo(() => {
    if (days) {
      return days.find((d) =>
        dateService.isSameDayUTC(planningConfig.focusDate, d.date),
      );
    }
  }, [days, planningConfig.focusDate]);

  const dailyExportModal = useDailyExportDownloadModal({
    dialogsState, // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  });

  const { onClickCreateNewSession, onClickCreateNewEvent, onClickEvent } =
    clickActions;

  const filterVirtualSessionsBeforeDive = false;
  const filterSessionsWithoutParticipantsBeforeDive = false; // TODO il faudrait configure ça sur l'écran, sinon l'utilisateur ne va pas comprendre pourquoi la session n'apparait pas!

  const dailySessions = useMemo(() => {
    const dailySessions = diveSessionsSorter.sortDiveSessionsBy(day.sessions, {
      attributeName: 'time',
      asc: true,
    });
    return dailySessions;
  }, [day.sessions]);

  const filteredSessionsWithIndex = useMemo(() => {
    return dailySessions
      .map((session, sessionIndex) => ({ session, sessionIndex }))
      .filter(
        ({ session }) =>
          (!filterVirtualSessionsBeforeDive || !session.isVirtual) &&
          (!filterSessionsWithoutParticipantsBeforeDive ||
            session.participants?.length),
      );
  }, [
    dailySessions,
    filterSessionsWithoutParticipantsBeforeDive,
    filterVirtualSessionsBeforeDive,
  ]);

  const dailySessionsWithParticipants = useMemo(
    () =>
      filteredSessionsWithIndex
        .map((x) => x.session)
        .filter((x) => !x.isVirtual && x.participants.length > 0),
    [filteredSessionsWithIndex],
  );

  return day ? (
    <div
      className={`max-h-full overflow-y-auto overflow-x-hidden flex flex-col justify-start ${
        className ?? ''
      }`}
    >
      {planningConfig.showStaffCountsPerDay && (
        <div className="my-1">
          <ClubPlanningLightPanelStaffDailyCounters
            planningConfig={planningConfig}
            date={day.date}
            showTooltip={true}
            staffMembersResumeSessions={day.staffMembersResumeSessions}
            className="inline-flex text-app-xxs md:text-xs"
            onClick={() => {
              clickActions.onClickStaffDailyCounters &&
                clickActions.onClickStaffDailyCounters({
                  clubReference,
                  day,
                });
            }}
          />
        </div>
      )}
      {planningConfig.viewDayOptions.displayMode === 'before-dive' ? (
        <ClubPlanningLightPanelViewPeriodDayBeforeDive
          day={day}
          planningConfig={planningConfig}
          clickActions={clickActions}
          sessionsDisplayConfigs={sessionsDisplayConfigs}
          sessionCardMenuActions={sessionCardMenuActions}
        />
      ) : planningConfig.viewDayOptions.displayMode === 'staff-roles' ||
        planningConfig.viewDayOptions.displayMode === 'staff-sessions' ||
        planningConfig.viewDayOptions.displayMode === 'staff-presence' ? (
        <ClubPlanningLightPanelViewPeriodDayStaff
          context={context}
          day={day}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
          clickActions={clickActions}
          sessionsDisplayConfigs={sessionsDisplayConfigs}
        />
      ) : (
        <ClubPlanningLightPanelViewPeriodDayHorizontal
          context={context}
          day={day}
          planningConfig={planningConfig}
          clickActions={clickActions}
          sessionsDisplayConfigs={sessionsDisplayConfigs}
          planningParticipantsLocalState={planningParticipantsLocalState}
          dialogsState={dialogsState}
        />
      )}

      <div className="flex-grow"></div>
      <div className="mx-auto flex justify-center items-start gap-2 md:gap-6 lg:gap-12">
        <CreateSessionButton
          className="px-2"
          mode="large"
          day={day}
          onClickCreateNewSession={onClickCreateNewSession}
        />
        <CreateEventButton
          className="px-2"
          mode="large"
          day={day}
          onClickCreateNewEvent={onClickCreateNewEvent}
        />
        {dailyExportModal &&
          dailySessionsWithParticipants.length > 0 &&
          az.download?.dailyResume &&
          dailyExportsSettings
            .filter((x) => x.enabled && x.visibility.planningDaily)
            .map((dailyExportSettings) => (
              <div
                key={dailyExportSettings._id}
                className="flex justify-end items-end"
              >
                <AppButton
                  color="primary-outline-light"
                  className={''}
                  icon={AppHeroIcons.actionDownload}
                  onClick={() => {
                    const dateUTC = dateService.getUTCDateSetTime(
                      dailySessionsWithParticipants[0].time,
                      0,
                    );
                    dailyExportModal.openModal({
                      dailyExportSettings,
                      sessions: dailySessionsWithParticipants,
                      dateUTC,
                    });
                  }}
                >
                  <div className="whitespace-nowrap">
                    {dailyExportSettings.label}
                  </div>
                </AppButton>
              </div>
            ))}
      </div>
    </div>
  ) : null;
};
