import { ClubParticipant } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { ClubParticipantCardAttributeName } from './model';

export type ClubParticipantCardAttributesDisplayMode =
  | 'all'
  | 'edit-participant-form-dive-parameters'
  | 'edit-participant-form-more-parameters';

const ALL_ATTRIBUTES: ClubParticipantCardAttributeName[] = [
  'diveType',
  'aptitude',
  'gaz',
  'targetDeep',
  'jacket',
  'wetsuit',
  'fins',
  'scubaRegulator',
  'mask',
  'flashLight',
  'computer',
  'tank',
  'compass',
  'weighting',
  'rebreather',
  'underwaterScooter',
  'tags',
  'bookingState',
  'paymentStatus',
  'paymentComment',
  'comment',
];

export function useClubParticipantCardAttributes({
  participant,
  displayMode,
  attributesToExclude,
}: {
  participant: ClubParticipant;
  displayMode?: ClubParticipantCardAttributesDisplayMode;
  attributesToExclude?: ClubParticipantCardAttributeName[];
}): {
  primary: ClubParticipantCardAttributeName[];
  secondary: ClubParticipantCardAttributeName[];
} {
  if (!displayMode) {
    displayMode = 'all';
  }

  if (!attributesToExclude) {
    attributesToExclude = [];
  }

  return useMemo(
    () =>
      buildVisibleAttributes({
        participant,
        displayMode,
        allAttributes: ALL_ATTRIBUTES,
        attributesToExclude,
      }),
    [attributesToExclude, displayMode, participant],
  );
}

function buildVisiblePrimaryAttributes({
  participant,
  displayMode,
  allAttributes,
  attributesToExclude,
}: {
  participant: ClubParticipant;
  displayMode: ClubParticipantCardAttributesDisplayMode;
  allAttributes: ClubParticipantCardAttributeName[];
  attributesToExclude: ClubParticipantCardAttributeName[];
}) {
  let primary: ClubParticipantCardAttributeName[];
  switch (displayMode) {
    case 'edit-participant-form-dive-parameters': {
      primary = [
        'diveType',
        'aptitude',
        'targetDeep',
        'gaz',
        'jacket',
        'wetsuit',
        'fins',
        'scubaRegulator',
        'mask',
        'flashLight',
        'computer',
        'tank',
        'compass',
        'weighting',
        'rebreather',
        'underwaterScooter',
        'tags',
        'comment',
      ];
      break;
    }

    case 'edit-participant-form-more-parameters': {
      primary = ['paymentStatus', 'paymentComment', 'bookingState'];
      break;
    }
    case 'all': {
      primary = allAttributes;
      break;
    }
  }
  return primary.filter(
    (attr) =>
      isNotEmptyClubParticipantCardAttribute(participant, attr) &&
      attributesToExclude.indexOf(attr) === -1,
  );
}

function buildVisibleSecondaryAttributes({
  participant,
  allAttributes,
  attributesToExclude,
}: {
  participant: ClubParticipant;
  allAttributes: ClubParticipantCardAttributeName[];
  attributesToExclude: ClubParticipantCardAttributeName[];
}) {
  return allAttributes.filter(
    (attr) =>
      isNotEmptyClubParticipantCardAttribute(participant, attr) &&
      attributesToExclude.indexOf(attr) === -1,
  );
}
function buildVisibleAttributes({
  participant,
  displayMode,
  allAttributes,
  attributesToExclude,
}: {
  participant: ClubParticipant;
  displayMode: ClubParticipantCardAttributesDisplayMode;
  allAttributes: ClubParticipantCardAttributeName[];
  attributesToExclude: ClubParticipantCardAttributeName[];
}) {
  let primary: ClubParticipantCardAttributeName[] =
    buildVisiblePrimaryAttributes({
      participant,
      displayMode,
      allAttributes,
      attributesToExclude,
    });

  let secondary: ClubParticipantCardAttributeName[] =
    buildVisibleSecondaryAttributes({
      participant,
      allAttributes,
      attributesToExclude: allAttributes.concat(primary),
    });

  if (secondary.length === 1 || primary.length < 3) {
    primary = primary.concat(secondary);
    secondary = [];
  }

  return { primary, secondary };
}

function isNotEmptyClubParticipantCardAttribute(
  participant: ClubParticipant,
  attributeName: ClubParticipantCardAttributeName,
) {
  if (!participant) {
    return false;
  }
  if (attributeName === 'diveType') {
    return !!participant.diveMode;
  }
  if (attributeName === 'jacket') {
    return !!participant.equipment?.jacket;
  }
  if (attributeName === 'wetsuit') {
    return !!participant.equipment?.wetsuit;
  }
  if (attributeName === 'fins') {
    return !!participant.equipment?.fins;
  }
  if (attributeName === 'scubaRegulator') {
    return !!participant.equipment?.scubaRegulator;
  }
  if (attributeName === 'mask') {
    return !!participant.equipment?.mask;
  }
  if (attributeName === 'flashLight') {
    return !!participant.equipment?.flashLight;
  }
  if (attributeName === 'rebreather') {
    return !!participant.equipment?.rebreather;
  }
  if (attributeName === 'underwaterScooter') {
    return !!participant.equipment?.underwaterScooter;
  }
  if (attributeName === 'computer') {
    return !!participant.equipment?.computer;
  }
  if (attributeName === 'tank') {
    return !!participant.equipment?.tank;
  }
  if (attributeName === 'compass') {
    return !!participant.equipment?.compass;
  }
  if (attributeName === 'weighting') {
    return !!participant.equipment?.weighting;
  }
  if (attributeName === 'gaz') {
    return (
      !!participant.gaz?.gazDescription ||
      (!!participant.gaz?.gazType && participant.gaz?.gazType !== 'air') ||
      !!participant.gaz?.gazQuantity
    );
  }
  if (attributeName === 'aptitude') {
    return !!participant.diveMode;
  }
  return !!participant[attributeName];
}
