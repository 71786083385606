/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppPageContainerWithFixedBars } from 'src/business/_core/modules/layout';
import { AppDocEditDialogActionsBar } from './AppDocEditDialogActionsBar';

import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import { AppDocEditDialogInitialState, AppDocEditDialogState } from './_model';
import { AppDocumentCreatorPanelForm } from './form';
import {
  AppDocEditDialogLocalState,
  useAppDocEditDialogLocalState,
} from './useAppDocEditDialogLocalState';

export function AppDocEditDialogPanel({
  inputState,
}: {
  inputState: AppDocEditDialogState;
}) {
  const initialState: AppDocEditDialogInitialState = inputState.initialState;

  const localState: AppDocEditDialogLocalState = useAppDocEditDialogLocalState({
    inputState,
  });

  return (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={
        localState.state.operationInProgressMessage
          ? () => (
              <LoadingProgressBar>
                {localState.state.operationInProgressMessage}
              </LoadingProgressBar>
            )
          : () => (
              <AppDocEditDialogActionsBar
                localState={localState}
                closeDialog={inputState.closeDialog}
              />
            )
      }
    >
      <div className="app-p-content">
        <AppDocumentCreatorPanelForm localState={localState} />
      </div>
    </AppPageContainerWithFixedBars>
  );
}
