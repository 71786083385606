/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as React } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { clubSettingsOtherUrlBuilder } from 'src/pages/SE-settings/SE-04_other-settings/clubSettingsOtherUrlBuilder.service';
import { ClubSettingsCustomerViewCard } from './ClubSettingsCustomerViewCard';

export const ClubSettingsCustomerFormFieldsViewPage = () => {
  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  return (
    <AppPageContainer className={'bg-gray-50'}>
      {/* <ClubOfferBreadcrumbBar /> */}

      <AppBreadcrumbBar
        color={AppIconsSettings.other.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.customers.main,
            label: 'Clients',
            url: '/club/settings',
          },
          {
            label: 'Champs de formulaire',
          },
        ]}
      />

      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <div className="app-card p-4 ">
          <div className="flex gap-4">
            <h2 className="w-full text-left uppercase text-lg leading-6 font-medium text-app-blue">
              Champs à afficher
            </h2>
            <AppButton
              className=""
              color="primary-outline-light"
              icon={AppHeroIcons.actionEdit}
              onClick={() =>
                redirectTo(clubSettingsOtherUrlBuilder.formFields.edit())
              }
            >
              Modifier
            </AppButton>
          </div>

          <>
            <p className="my-2 text-gray-600">
              Configuration des champs visibles sur les formulaires
            </p>
            <ClubSettingsCustomerViewCard
              clubSettings={clubSettings}
              diveCenterPublicSettings={diveCenterResume.publicSettings}
            />
            <div className={globalClasses.pageBlock}>
              <AppMessage
                className="mb-5"
                title={'Instruction'}
                message={
                  <>
                    <p>
                      Cliquez sur le bouton "Modifier" pour n'afficher sur les
                      formulaires que les champs dont vous avez besoin.
                    </p>
                  </>
                }
              />
            </div>
          </>
        </div>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
