import { ClubBoat } from '@mabadive/app-common-model';
import { ClubResumeClubBoatFormModel } from '../model';

export const clubBoatFormResultBuilder = { buildResult };

function buildResult(
  formValue: ClubResumeClubBoatFormModel,
): Partial<ClubBoat> {
  const value: Partial<ClubBoat> = {
    ...formValue,
  };
  return value;
}
