import clsx from 'clsx';
import React from 'react';
import { useAutomaticMailConfigForTemplate } from './useAutomaticMailConfigForTemplate.hook';

export function AutomaticMailConfigForTemplateList({
  messageTemplateId,
  className,
}: {
  messageTemplateId: string;
  className?: string;
}) {
  const {
    enabledCount,
    onCustomerSubmitBookingWithPendingDeposit,
    onCustomerSubmitBookingWithoutPendingDeposit,
  } = useAutomaticMailConfigForTemplate(messageTemplateId);

  return enabledCount > 0 ? (
    <div className={clsx('grid gap-1', className)}>
      <h3 className="font-bold uppercase text-status-error">
        Envoi automatique
      </h3>
      <ul className="list-disc ml-4 text-gray-600 uppercase">
        {onCustomerSubmitBookingWithPendingDeposit && (
          <li>Réservation en ligne sans demande d'acompte 🚀</li>
        )}
        {onCustomerSubmitBookingWithoutPendingDeposit && (
          <li>Réservation en ligne avec demande d'acompte 🚀</li>
        )}
      </ul>
    </div>
  ) : null;
}
