import {
  ClubSettingsUIPlanning,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { Fragment, useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubDiveSessionHeaderSessionDate } from 'src/business/club/modules/club-dive-session/components';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSession,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { CalendarParticipantCell } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/session-common';
import {
  PLANNING_CONFIG_CONTEXT_ID_PLANNING_PARTICIPANTS_VIEW,
  usePlanningConfig,
} from 'src/business/club/modules/club-planning-light/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { DiverSessionHistoryCardHeader } from './DiverSessionHistoryCardHeader';

export const DiverSessionHistoryCard = ({
  bookingSessionFull,
  participants,
  onClickParticipant,
  onClickSession,
}: {
  bookingSessionFull: AggregatedBookingSessionFull;
  participants: BookingResumeParticipantForSession[];
  onClickParticipant: (participant: BookingResumeParticipantForSession) => void;
  onClickSession: (bookingSessionFull: AggregatedBookingSessionFull) => void;
}) => {
  const diveSession = bookingSessionFull.diveSession;
  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(diveSession.time),
    [diveSession.time],
  );
  const isFuture = useMemo(
    () => dateService.isFutureDayUTC(bookingSessionFull?.diveSession.time),
    [bookingSessionFull?.diveSession.time],
  );
  const clubResume = useClubResume();
  const diveSessionReference = diveSession.reference;

  // const settingsUIPlanning = useDefaultSettingsUiPlanning({});

  const [planningConfig] = usePlanningConfig({
    enableRouteParams: false,
    context: PLANNING_CONFIG_CONTEXT_ID_PLANNING_PARTICIPANTS_VIEW, // on utilise la configuration du planning
    defaultFocusDate: undefined,
  });

  const localSettingsUIPlanning: ClubSettingsUIPlanning = useMemo(() => {
    const showPayment = planningConfig.showPaymentFlag;
    return {
      ...planningConfig,
      showStaff: true,
      showPaymentFlagPastOnly: false,
      showPaymentFlagNotPurchased: showPayment,
      showPaymentFlagNotPurchasedPass: showPayment,
      showPaymentFlagTraining: showPayment,
      showPaymentFlagPayed: showPayment,
      showPaymentFlagNotPayed: showPayment,
    };
  }, [planningConfig]);

  const includePayment = useMemo(() => {
    if (planningConfig.showPaymentFlagPastOnly) {
      const time = diveSession.time;
      const isFutureDay = dateService.isFutureDayUTC(time);
      return !isFutureDay;
    }
    return true;
  }, [planningConfig.showPaymentFlagPastOnly, diveSession.time]);

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const sessionDiveCenter = useMemo(
    () =>
      clubResume.diveCenters.find(
        (dc) => dc._id === bookingSessionFull.diveSession.diveCenterId,
      ),
    [bookingSessionFull.diveSession.diveCenterId, clubResume.diveCenters],
  );

  return (
    <div
      className={`w-full bg-white border
      ${diveSession.isVirtual ? 'border-dashed' : ''}
      ${`border-day-period-${timeDayPeriod}-dark`} 
      text-left`}
    >
      <div
        className={`cursor-pointer group ring-opacity-30 ring-day-period-${timeDayPeriod}-dark hover:ring-2`}
        onClick={() => onClickSession(bookingSessionFull)}
      >
        <div
          className={clsx(
            'px-1 py-0.5 flex gap-1 justify-between',
            isFuture ? 'bg-gray-400' : 'bg-gray-600',
          )}
        >
          <ClubDiveSessionHeaderSessionDate
            className="flex-grow"
            diveSession={diveSession}
          />
          {isMultiDiveCenters && (
            <div>
              <DiveCenterProBadge
                className="my-px"
                diveCenter={sessionDiveCenter}
              />
            </div>
          )}
        </div>
        <DiverSessionHistoryCardHeader
          bookingSessionFull={bookingSessionFull}
        />
      </div>

      {participants.map((participantResume, participantIndex) => (
        <Fragment key={`gr-p${participantIndex}-s${diveSessionReference}`}>
          <div className={'mt-px cursor-pointer'}>
            <CalendarParticipantCell
              multipleSessionsGroup={true}
              key={`gr-p${participantIndex}-s${diveSessionReference}`}
              className={clsx(
                'participant-cell',
                `is-first-${participantIndex === 0}`,
                `is-last-${participantIndex + 1 === participants.length}`,
              )}
              settingsUIPlanning={localSettingsUIPlanning}
              includeComment={false}
              isPast={false}
              club={clubResume}
              diver={participantResume.bookingParticipantFull?.diver}
              participant={
                participantResume.bookingParticipantFull?.diveSessionParticipant
              }
              diveSession={diveSession}
              includePayment={includePayment}
              onClick={() => onClickParticipant(participantResume)}
              anchorLink={undefined}
              widthMode={'large'}
            />
          </div>
        </Fragment>
      ))}
    </div>
  );
};
