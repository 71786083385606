import {
  DiveCenterExportSettingsDaily,
  DiveSessionResumeFull,
} from '@mabadive/app-common-model';
import React, { useCallback } from 'react';
import { useAppDialogModal } from 'src/business/_core/modules/layout';
import { ClubDialogsState } from 'src/pages/_dialogs';
import { DailyExportDownloadModalContent } from './DailyExportDownloadModalContent';

export function useDailyExportDownloadModal({
  dialogsState,
}: {
  dialogsState: ClubDialogsState;
}) {
  const appDialogModal = useAppDialogModal();

  const openModal = useCallback(
    ({
      dailyExportSettings,
      sessions,
      dateUTC,
    }: {
      dailyExportSettings: DiveCenterExportSettingsDaily;
      sessions: DiveSessionResumeFull[];
      dateUTC: Date;
    }) => {
      if (dialogsState) {
        appDialogModal.openDialog(({ close }) => ({
          content: (
            <div className="app-p-content">
              <DailyExportDownloadModalContent
                dailyExportSettings={dailyExportSettings}
                dateUTC={dateUTC}
                sessions={sessions}
                close={close}
                dialogsState={dialogsState}
              />
            </div>
          ),
        }));
      }
    },
    [appDialogModal, dialogsState],
  );

  return {
    openModal,
  };
}
