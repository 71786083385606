/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@material-ui/core';
import React from 'react';
import { AppMessage } from 'src/business/_core/modules/layout/components/_tailwind';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';

export const DiverPurchaseOtherFormInfoBox = () => {
  const globalClasses = useGlobalClasses();

  return (
    <Box className={globalClasses.pageBlock} marginTop={10}>
      <AppMessage
        type="info"
        title="Note"
        message={
          <>
            <div>
              Le champ <b>"STATUT"</b> est{' '}
              <b className="text-status-active">EN COURS</b> si les plongées de
              la prestation ne sont pas toutes imputées. Une fois les plongées
              imputées, il passe à <b className="text-status-info">IMPUTÉ</b>.
            </div>
          </>
        }
      />
    </Box>
  );
};
