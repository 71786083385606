import React from 'react';
import { Switch } from 'react-router-dom';
import { of } from 'rxjs';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import { AppAuth } from 'src/business/auth/services';
import useRouter from 'use-react-router';
import { ClubDiversListPage } from './LI-01_divers';
import { ClubBookingsListPage } from './LI-02_bookings';
import { ClubPurchasesListPage } from './LI-03_purchase';
import { PaymentsListPage } from './LI-04-payments';
import { DashboardCashPage } from './LI-05-daily-cash';
import { ClubOrdersListPage } from './LI-06_orders';
import { DepositsListPage } from './LI-07-deposits';

export const ClubListsRoutes = React.memo(function ClubRootSpaceMemo() {
  const { match } = useRouter();
  return (
    <Switch>
      <AppRoute
        path={`${match.url}/divers`}
        exact
        component={ClubDiversListPage}
        auth={{ requiredRoles: ['club-view-lists-diver'] }}
      />
      <AppRoute
        path={`${match.url}/bookings`}
        exact
        component={ClubBookingsListPage}
        auth={{ requiredRoles: ['club-view-lists-participant'] }}
      />
      <AppRoute
        path={`${match.url}/orders`}
        exact
        component={ClubOrdersListPage}
        checkAuth={({ az, clubSettings }) =>
          clubSettings?.general?.onlineBooking?.enabled &&
          clubSettings?.general?.onlineBooking?.enableProOrdersList &&
          az?.orders?.view?.ordersList
        }
      />
      <AppRoute
        path={`${match.url}/cash`}
        exact
        component={DashboardCashPage}
        auth={{
          requiredRoles: ['club-view-lists', 'club-view-dashboard-daily-cash'],
        }}
      />
      <AppRoute
        path={`${match.url}/billing`}
        exact
        component={ClubPurchasesListPage}
        auth={{ requiredRoles: ['club-view-lists-billing'] }}
      />
      <AppRoute
        path={`${match.url}/payments`}
        exact
        component={PaymentsListPage}
        auth={{ requiredRoles: ['club-view-lists-payment'] }}
      />
      <AppRoute
        path={`${match.url}/deposits`}
        exact
        component={DepositsListPage}
        auth={{ requiredRoles: ['club-view-lists-payment'] }}
      />
      {/* default route */}
      <AppRoute
        path={`${match.url}/`}
        redirectTo={({ appAuth }) =>
          of(redirectToDefaultRoute({ appAuth, matchUrl: match.url }))
        }
      />
    </Switch>
  );
});

function redirectToDefaultRoute({
  appAuth,
  matchUrl,
}: {
  appAuth: AppAuth;
  matchUrl: string;
}): string {
  if (appAuth) {
    // TODO afficher le bon onglet en fonction des droits
    if (appAuth.user.roles.includes('club-view-lists-diver')) {
      return `${matchUrl}/divers`;
    }
    if (appAuth.user.roles.includes('club-view-lists-participant')) {
      return `${matchUrl}/participants`;
    }
    if (appAuth.user.roles.includes('club-view-lists-billing')) {
      return `${matchUrl}/billings`;
    }
    if (appAuth.user.roles.includes('club-view-lists-payment')) {
      return `${matchUrl}/payments`;
    }
    if (appAuth.user.roles.includes('club-view-dashboard-daily-cash')) {
      return `${matchUrl}/cash`;
    }
  }
  return '/';
}
