/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubSettingsSection } from '../../../../../../_core';
import { ClubSettingsOnlineBookingEmailsViewPanel_EmailOption } from './ClubSettingsOnlineBookingEmailsViewPanel_EmailOption';

export const ClubSettingsOnlineBookingEmailsViewPanel_01 = () => {
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const emailsSettings = clubSettings?.general?.onlineBooking?.emails;

  return (
    <>
      <ClubSettingsSection
        title="Mail 1 - à la réservation par le CLIENT"
        comment="Accusez réception des demandes de réservation"
      >
        <div className="grid gap-4">
          <ClubSettingsOnlineBookingEmailsViewPanel_EmailOption
            label="Réservation en ligne sans demande d'acompte 🚀"
            emailOption={
              emailsSettings?.onCustomerSubmitBookingWithoutPendingDeposit
            }
          />
          <ClubSettingsOnlineBookingEmailsViewPanel_EmailOption
            label="Réservation en ligne avec demande d'acompte 🚀"
            emailOption={
              emailsSettings?.onCustomerSubmitBookingWithPendingDeposit
            }
          />
          {/* <ClubSettingsOnlineBookingEmailsViewPanel_EmailOption => TODO pas urgent
          label="À la réception d'un paiement d'acompte"
          emailOption={emailsSettings?.onCustomerSubmitPayment}
        /> */}
        </div>
      </ClubSettingsSection>
    </>
  );
};
