/* eslint-disable @typescript-eslint/no-unused-vars */

import { arrayBuilder, dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubSettingsPlanningViewDayModel } from '../../../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek';
import {
  ClubSettingsPlanningViewWeekDayDiveTourFormModel,
  ClubSettingsPlanningViewWeekFormModel,
} from '../../model';
import { ClubSettingsPlanningViewWeekDayGeneralForm_Tour } from './ClubSettingsPlanningViewWeekDayGeneralForm_Tour';

export const ClubSettingsPlanningViewWeekDayGeneralForm = ({
  form,
  day,
  dayIndex,
  className,
}: {
  form: UseFormReturn<ClubSettingsPlanningViewWeekFormModel, any>;
  day: ClubSettingsPlanningViewDayModel;
  dayIndex: number;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const dayControlBaseName = `days.${dayIndex}` as const;

  const diveToursFieldArray = useFieldArray({
    control,
    name: `${dayControlBaseName}.diveTours`,
  });

  const createDiveTour = useCallback(
    (day: ClubSettingsPlanningViewDayModel) => {
      // const previousTime = formTours.length
      //   ? formTours[formTours.length - 1].session1.time
      //   : undefined;

      // const hours =
      //   previousTime && previousTime.getUTCHours() < 22
      //     ? previousTime.getUTCHours() + 2
      //     : 16;

      const time = dateService.getUTCDateSetTime(new Date(day.dayDate), 0, 0);
      const diveTour: ClubSettingsPlanningViewWeekDayDiveTourFormModel = {
        reference: undefined, // will be generated just before persist
        multiSessionsEnabled: false,
        isOpen: true,
        type: 'dive',
        theme: undefined,
        defaultDiveMode: undefined,
        session1: {
          time,
          name: undefined,
          boatsIds: diveCenterResume?.boats
            .filter((x) => x.isDefault)
            .map((x) => x._id),
        },
        session2: undefined,
      };

      diveToursFieldArray.prepend(diveTour, {
        shouldFocus: true,
      });
    },
    [diveCenterResume?.boats, diveToursFieldArray],
  );

  return (
    <div
      className={`flex flex-col justify-between gap-2 ${className ?? ''}`}
      key={dayIndex}
    >
      <div className={'flex flex-col gap-2'}>
        <div
          className={clsx(
            'flex flex-col gap-1 items-stretch',
            'rounded-t-lg p-1 bg-white border border-gray-300',
            'py-1 text-center text-xs md:text-sm',
            'leading-4 sm:leading-4 md:justify-around md:items-center',
          )}
        >
          <div className={'py-3 text-app-xxs md:text-xs font-bold uppercase'}>
            <div className="hidden sm:block lg:hidden">
              {dateService.formatUTC(day.dayDate, 'ddd')}
            </div>
            <div className="sm:hidden lg:block">
              {dateService.formatUTC(day.dayDate, 'dddd')}
            </div>
          </div>
          <div>
            <AppButton
              className={clsx(
                'font-bold uppercase',
                diveToursFieldArray.fields.length === 0 && 'invisible',
              )}
              size="small"
              fontSize="text-xs"
              style={{ minWidth: '6rem' }}
              color={'danger-outline-light'}
              icon={AppHeroIcons.actionDelete}
              tabIndex={500}
              disabled={diveToursFieldArray.fields.length === 0}
              onClick={async () => {
                // if (
                //   await confirmDialog.confirmPromise({
                //     title: `Supprimer toutes les sessions du ${dateService
                //       .formatUTC(day.dayDate, 'dddd')
                //       .toUpperCase()} ?`,
                //     message: `Êtes-vous sûr de vouloir supprimer toutes les sessions du ${dateService
                //       .formatUTC(day.dayDate, 'dddd')
                //       .toUpperCase()} ?`,
                //     type: 'noYesDanger',
                //   })
                // ) {
                // ok
                // delete all daily tours
                diveToursFieldArray.remove(
                  arrayBuilder
                    .bySize(diveToursFieldArray.fields.length)
                    .map((i) => i),
                );
                // }
              }}
            >
              <span className="whitespace-nowrap truncate">Supprimer</span>
            </AppButton>
          </div>
        </div>

        <div className="flex flex-col gap-4 justify-between lg:justify-center my-4">
          <AppButton
            className={'font-bold uppercase'}
            size="small"
            fontSize="text-xs"
            style={{ minWidth: '6rem' }}
            color={'primary-bg'}
            icon={AppHeroIcons.actionAdd}
            tabIndex={500}
            onClick={() => createDiveTour(day)}
          >
            <span className="whitespace-nowrap truncate">Ajouter session</span>
          </AppButton>
        </div>

        <div
          className={
            'flex flex-col gap-x-2 sm:flex-row sm:items-start sm:flex-wrap lg:flex-col text-app-xxs md:text-xs font-bold uppercase'
          }
        >
          {diveToursFieldArray.fields.map((field, diveTourIndex) => {
            return (
              <ClubSettingsPlanningViewWeekDayGeneralForm_Tour
                key={`${field.reference}-${field.id}-${diveTourIndex}`}
                className="w-full sm:max-w-[31%] lg:max-w-full"
                dayIndex={dayIndex}
                diveTourIndex={diveTourIndex}
                day={day}
                formTour={field as any}
                form={form}
                onClickDelete={() => diveToursFieldArray.remove(diveTourIndex)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
