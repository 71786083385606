/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveSessionGroupDiveMode,
  DiveSessionResumeParticipant,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { FC, useCallback, useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiveSessionEditorDialogLocalState } from '../../useDiveSessionEditorDialogLocalState.hook';
import { DiveSessionDialogTab3ParticipantInfo } from './components';
import { diveSessionDialogTab3GroupsStateUpdator } from './diveSessionDialogTab3GroupsStateUpdator.service';

export const DiveSessionDialogTab3GroupsPanel1Participants: FC<{
  localState: DiveSessionEditorDialogLocalState;
  className?: string;
}> = ({ localState, className }) => {
  const {
    clubReference,
    aggregatedData: {
      groups,
      clubParticipants,
      diveSession,
      divers,
      staffMembers,
    },
    data,
    actions,
    updateState,
    setUpdateState,
    isMultiSessionConfigForParticipants,
  } = localState;

  const clubResume = useClubResume();

  const onParticipantCheckChanges = useCallback(
    (participant: DiveSessionResumeParticipant) => {
      // ungroupped
      if (!data.selectedUngrouppedParticipantsIds.includes(participant._id)) {
        actions.setSelectedUngrouppedParticipantsIds(
          data.selectedUngrouppedParticipantsIds.concat([participant._id]),
        );
      } else {
        actions.setSelectedUngrouppedParticipantsIds(
          data.selectedUngrouppedParticipantsIds.filter(
            (pId) => pId !== participant._id,
          ),
        );
      }
    },
    [actions, data.selectedUngrouppedParticipantsIds],
  );

  const isChecked = useCallback(
    (participant: DiveSessionResumeParticipant) => {
      // ungroupped
      return data.selectedUngrouppedParticipantsIds.includes(participant._id);
    },
    [data.selectedUngrouppedParticipantsIds],
  );

  const ungrouppedParticipants = useMemo(
    () =>
      data.diveSessionParticipantsByGroup.ungroupped.filter(
        (participant) =>
          participant.bookingState &&
          ['pending', 'confirmed'].includes(participant.bookingState.value) &&
          participant.diveMode !== 'observer',
      ),
    [data.diveSessionParticipantsByGroup.ungroupped],
  );

  const isAllUngrouppedSelected =
    data.selectedUngrouppedParticipantsIds.length ===
    ungrouppedParticipants.length;

  const selectedOrAllUngrouppedParticipants = useMemo(
    () =>
      ungrouppedParticipants.filter((x) =>
        data.selectedUngrouppedParticipantsIds.length
          ? data.selectedUngrouppedParticipantsIds.includes(x._id)
          : true,
      ),
    [data.selectedUngrouppedParticipantsIds, ungrouppedParticipants],
  );

  const selectedOrAllUngrouppedParticipantsFirstDive = useMemo(
    () =>
      selectedOrAllUngrouppedParticipants.filter(
        (p) => p.diveMode === 'first-dive',
      ),
    [selectedOrAllUngrouppedParticipants],
  );

  const autoGroupDivers = useCallback(
    ({
      onlyGroupsDiveMode,
    }: { onlyGroupsDiveMode?: DiveSessionGroupDiveMode } = {}) => {
      if (selectedOrAllUngrouppedParticipants.length) {
        let localUpdateState =
          diveSessionDialogTab3GroupsStateUpdator.autoGroupDivers({
            participants: selectedOrAllUngrouppedParticipants,
            updateState,
            onlyGroupsDiveMode,
            diveSession,
            clubReference,
          });
        setUpdateState(localUpdateState);
      }
      actions.setSelectedUngrouppedParticipantsIds([]);
      actions.toogleSelectMember(data.selectedMember?._id);
    },
    [
      actions,
      clubReference,
      data.selectedMember?._id,
      diveSession,
      selectedOrAllUngrouppedParticipants,
      setUpdateState,
      updateState,
    ],
  );

  const useMultiParticipantsColumn =
    false && // désactivé car suivant les infos affichées, ça rend illisible (par exemple si c'est un groupe avec un nom de groupe)
    ungrouppedParticipants.length > 10 &&
    !isMultiSessionConfigForParticipants;
  return (
    <div className={className}>
      {ungrouppedParticipants.length > 0 && (
        <>
          <h2 className="text-center text-sm sm:text-lg leading-6 p-2 font-medium text-app-blue uppercase">
            Participants
          </h2>
          <div className="mb-2 flex gap-2 justify-between">
            <div
              className="p-1.5"
              onClick={() => {
                if (isAllUngrouppedSelected) {
                  actions.setSelectedUngrouppedParticipantsIds([]);
                } else {
                  actions.setSelectedUngrouppedParticipantsIds(
                    ungrouppedParticipants.map((x) => x._id),
                  );
                }
              }}
            >
              <input
                className="ml-0.5 mr-2"
                type="checkbox"
                readOnly={true}
                checked={isAllUngrouppedSelected}
              />
              {/* {isAllUngrouppedSelected
                      ? 'désélectionner tout'
                      : 'sélectionner tout'} */}
            </div>
            <AppButton
              className={
                data.selectedUngrouppedParticipantsIds.length === 0 &&
                !data.selectedMember
                  ? 'invisible'
                  : ''
              }
              size="normal"
              icon={AppHeroIcons.group}
              color={'primary-outline-light'}
              onClick={() => {
                actions.groupSelectedDivers({
                  staffMember: data.selectedMember,
                });
              }}
            >
              <span className="font-bold uppercase text-xs">
                Nouvelle palanquée
              </span>
            </AppButton>
            {selectedOrAllUngrouppedParticipantsFirstDive.length > 0 && (
              <AppButton
                size="normal"
                icon={AppHeroIcons.autoFill}
                color={'primary-outline-light'}
                onClick={() => {
                  autoGroupDivers({
                    onlyGroupsDiveMode: 'first-dive',
                  });
                }}
              >
                <span className="font-bold uppercase text-xs">
                  Baptêmes Auto
                </span>
              </AppButton>
            )}

            <AppButton
              size="normal"
              icon={AppHeroIcons.autoFill}
              color={'primary-outline-light'}
              onClick={() => {
                autoGroupDivers();
              }}
            >
              <span className="font-bold uppercase text-xs">Auto</span>
            </AppButton>
          </div>
          <div
            className={clsx(
              'border-2 border-dotted border-gray-400 pl-px p-1',
              useMultiParticipantsColumn && 'grid gap-x-4 2xl:grid-cols-2',
            )}
          >
            {(ungrouppedParticipants ?? []).map(
              (participant, participantIndex) => {
                const isSelected = isChecked(participant);
                return (
                  <div
                    key={participant._id}
                    className={`cursor-pointer flex items-start gap-2 text-gray-600 py-1 px-1 ${
                      isSelected
                        ? 'bg-gray-100 border-y border-gray-200'
                        : 'border-y border-transparent hover:border-gray-200'
                    }`}
                    onClick={() => onParticipantCheckChanges(participant)}
                  >
                    <input
                      type="checkbox"
                      readOnly={true}
                      checked={isSelected}
                    />
                    <DiveSessionDialogTab3ParticipantInfo
                      localState={localState}
                      participant={participant}
                    />
                  </div>
                );
              },
            )}
          </div>
        </>
      )}
    </div>
  );
};
