import clsx from 'clsx';
import React from 'react';
import { AppIconsUI } from 'src/business/_core/modules/layout/icons';
import { AppTitleDateNavigatorModelValue } from '../model';

export const AppTitleDateNavigatorNavLeft = ({
  previousValue,
  onChange,
  className,
}: {
  previousValue: AppTitleDateNavigatorModelValue;
  onChange: (value: AppTitleDateNavigatorModelValue) => void;
  className?: string;
}) => {
  // const { model, onUpdateValue } = localState;

  return (
    <AppIconsUI.navLeft
      className={clsx(
        'mx-2 cursor-pointer w-6 h-6 sm:w-10 sm:h-10 rounded-full text-white',
        previousValue ? 'bg-app-primary' : 'bg-gray-300',
        className,
      )}
      aria-hidden="true"
      onClick={() => {
        if (previousValue) {
          onChange(previousValue);
        }
      }}
    />
  );
};
