import { DiveCenterResume } from '@mabadive/app-common-model';
import { loadableAttributeStoreFactory } from '@mabadive/app-common-services';
import { getBaseStore } from 'src/business/_core/store/baseStore';

const currentDiveCenterResume =
  loadableAttributeStoreFactory.create<DiveCenterResume>(
    getBaseStore(),
    'c_dive_center_resume',
  );

const currentDiveCenterResumeId = loadableAttributeStoreFactory.create<string>(
  getBaseStore(),
  'c_dive_center_resume_id',
);

export const currentDiveCenterResumeStore = {
  currentDiveCenterResume,
  currentDiveCenterResumeId,
  setInitialData,
  resetStore,
  updateStore,
};

function setInitialData({
  diveCenterResume,
}: {
  diveCenterResume: DiveCenterResume;
}) {
  updateStore({ diveCenterResume });
}

function resetStore() {
  // currentDiveCenterResume.set(null);
  // currentDiveCenterResumeId.set(null); // sans ça, dans AppRoot, le diveCenterResume n'est pas fetché au logout/re-login (edit: corrigé direct dans le edit, maintenant)
  currentDiveCenterResume.unload();
  currentDiveCenterResumeId.unload();
}

function updateStore({
  diveCenterResume,
}: {
  diveCenterResume: DiveCenterResume;
}) {
  currentDiveCenterResume.set(diveCenterResume);
  if (currentDiveCenterResumeId.getSnapshot() !== diveCenterResume?._id) {
    currentDiveCenterResumeId.set(diveCenterResume?._id);
  }
}
