import {
  AppBooklet,
  AppBookletPage,
  AppEntityUpdatePatch,
  ClubEcommerceCategory,
  ProMultiOperationPayloadAction,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { EcommerceCategoryEditorFormModel } from './model';
import { EcommerceCategoryEditorFormModelEditValue } from './model/EcommerceCategoryEditorFormModelEditValue.type';

export type EcommerceCategoryEditorPayload = Pick<
  ProMultiOperationPayloadAction,
  'ecommerceCategory' | 'appBooklet' | 'appBookletPage'
> & {
  hasChanges: boolean;
  actionContext: string;
};

export const ecommerceCategoryEditorResultBuilder = {
  buildCreatePayload,
  buildUpdatePayload,
};

function buildCreatePayload({
  formValue,
}: {
  formValue: EcommerceCategoryEditorFormModel;
}): EcommerceCategoryEditorPayload {
  const ecommerceCategory: Partial<ClubEcommerceCategory> =
    commonEntityBuilder.buildEntity(formValue.category);
  const appBookletPage: Partial<AppBookletPage> =
    commonEntityBuilder.buildEntity(formValue.appBookletPage);
  const appBooklet: Partial<AppBooklet> = commonEntityBuilder.buildEntity(
    formValue.appBooklet,
  );

  const result: EcommerceCategoryEditorPayload = {
    actionContext: 'create category',
    hasChanges: true,
    ecommerceCategory: {
      created: [ecommerceCategory],
    },
    appBooklet: {
      created: [appBooklet],
    },
    appBookletPage: {
      created: [appBookletPage],
    },
  };

  return result;
}
function buildUpdatePayload({
  editValue,
  formValue,
}: {
  editValue: EcommerceCategoryEditorFormModelEditValue;
  formValue: EcommerceCategoryEditorFormModel;
}): EcommerceCategoryEditorPayload {
  const result: EcommerceCategoryEditorPayload = {
    actionContext: 'update category',
    hasChanges: false,
    ecommerceCategory: {},
    appBooklet: {},
    appBookletPage: {},
  };

  {
    const patchOperations = jsonPatcher.compareObjects(
      editValue.category,
      {
        ...editValue.category,
        ...formValue.category,
      },
      {
        attributesToReplaceFully: [],
      },
    );
    if (patchOperations.length) {
      let patch: AppEntityUpdatePatch = {
        pk: editValue.category._id,
        patchOperations,
      };
      result.ecommerceCategory.updated = [patch];
      result.hasChanges = true;
    }
  }

  {
    const patchOperations = jsonPatcher.compareObjects(
      editValue.appBooklet,
      {
        ...editValue.appBooklet,
        ...formValue.appBooklet,
      },
      {
        attributesToReplaceFully: [],
      },
    );
    if (patchOperations.length) {
      let patch: AppEntityUpdatePatch = {
        pk: editValue.appBooklet._id,
        patchOperations,
      };
      result.appBooklet.updated = [patch];
      result.hasChanges = true;
    }
  }
  {
    const patchOperations = jsonPatcher.compareObjects(
      editValue.appBookletPage,
      {
        ...editValue.appBookletPage,
        ...formValue.appBookletPage,
      },
      {
        attributesToReplaceFully: [],
      },
    );
    if (patchOperations.length) {
      let patch: AppEntityUpdatePatch = {
        pk: editValue.appBookletPage._id,
        patchOperations,
      };
      result.appBookletPage.updated = [patch];
      result.hasChanges = true;
    }
  }

  return result;
}
