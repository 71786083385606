import { apiClient, graphqlClientProvider } from 'src/_common-browser';
import { clubAppOperationUpdateClientSender } from 'src/business/_core/data/app-operation';
import { baseStore } from 'src/business/_core/store/baseStore';
import { appWebConfig } from '../config/appWebConfig';
import { appLogger } from '../logger';

const logger = appLogger.child({
  module: 'bootstrap',
  filename: 'apiConfigurer.service',
});

export const apiConfigurer = {
  configureApiAndGraphqlClients,
};

function configureApiAndGraphqlClients({
  appVersion,
  appClientId,
}: {
  appVersion: string;
  appClientId: string;
}): string {
  logger.info(
    '[apiConfigurer] configure apiClient',
    appWebConfig.data.api.baseUrl,
  );

  // configure apiClient
  configureApi(appClientId, appVersion);

  if (appWebConfig.data.graphql.enabled) {
    const graphqlHostname = appWebConfig.data.graphql.hostname
      ? appWebConfig.data.graphql.hostname
      : window.location.hostname;
    const baseHttpUrl = `${appWebConfig.data.graphql.httpProtocol}://${graphqlHostname}/${appWebConfig.data.graphql.context}`;
    const baseWsUrl = `${appWebConfig.data.graphql.wsProtocol}://${graphqlHostname}/${appWebConfig.data.graphql.context}`;

    logger.info('[apiConfigurer] graphqlHostname:', graphqlHostname);
    logger.info('[apiConfigurer] baseHttpUrl:', baseHttpUrl);
    logger.info('[apiConfigurer] baseWsUrl:', baseWsUrl);

    logger.info('[apiConfigurer] graphqlClientProvider init');
    // configure graphql
    graphqlClientProvider.init({
      baseStore: baseStore,
      baseHttpUrl: baseHttpUrl,
      baseWsUrl: baseWsUrl,
    });
  }

  logger.info('[apiConfigurer] clubAppOperationUpdateClientSender init');
  // configure clubAppOperationUpdateClientSender client
  clubAppOperationUpdateClientSender
    .init({
      baseStore: baseStore,
    })
    .subscribe();

  logger.info('[apiConfigurer] end');
  return appClientId;
}

function configureApi(appClientId: string, appVersion: string) {
  try {
    apiClient.init({
      appClientId,
      appVersion,
      baseStore: baseStore,
      baseUrl: appWebConfig.data.api.baseUrl,
      errorCallback: ({
        httpStatus,
        err,
      }: {
        httpStatus: number;
        err: Error;
      }) => {
        // default callback
        if (httpStatus === 401) {
          appLogger.error('Authentication error', { err });
          // authenticationStore.logoutRequired.set(true);
          window.location.href = '/logout';
        }
      },
    });
  } catch (err) {
    appLogger.error(
      '[appBootstrap] Error initializing configureApiAndGraphqlClients',
      { err: err as Error },
    );
  }
}
