import { APP_COLORS, DiveMode } from '@mabadive/app-common-model';
import { ResponsiveAttributeValuesPropsAttr } from '../../../layout';
export const SIDE_MENU_WIDTH_OPEN = 150;
export const SIDE_MENU_WIDTH_COLLAPSED = 64;

export type AppResponsiveFontSize =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl';

export const appStylesHelper = {
  getFontSizeResponsive,
  getSideMenuWidthResponsive,
  getSideMenuCollapsedWidth,
  getColorsFromDiveMode,
};

function getFontSizeResponsive(
  size: AppResponsiveFontSize,
): ResponsiveAttributeValuesPropsAttr {
  switch (size) {
    case 'xxs':
      return { values: [8, 9, 10, 11, 12], suffix: 'px' };
    case 'xs':
      return { values: [10, 11, 12, 13, 14], suffix: 'px' };
    case 'sm':
      return { values: [12, 13, 14, 15, 16], suffix: 'px' };
    case 'md':
      return { values: [14, 16, 18, 20, 22], suffix: 'px' };
    case 'lg':
      return { values: [16, 18, 20, 22, 24], suffix: 'px' };
    case 'xl':
      return { values: [20, 22, 24, 26, 38], suffix: 'px' };
    case 'xxl':
      return { values: [22, 24, 26, 28, 30], suffix: 'px' };
  }
}

function getSideMenuWidthResponsive(
  offset: number = 0,
): ResponsiveAttributeValuesPropsAttr {
  return {
    values: [
      SIDE_MENU_WIDTH_OPEN + offset,
      SIDE_MENU_WIDTH_OPEN + 50 + offset,
      SIDE_MENU_WIDTH_OPEN + offset,
      SIDE_MENU_WIDTH_OPEN + offset,
      SIDE_MENU_WIDTH_OPEN + offset,
    ],
    suffix: 'px',
  };
}
function getSideMenuCollapsedWidth(offset: number = 0) {
  return `${SIDE_MENU_WIDTH_COLLAPSED + offset}px`;
}

function getColorsFromDiveMode({ diveMode }: { diveMode?: DiveMode }): {
  colorContrast: string;
  colorMain: string;
} {
  switch (diveMode) {
    case 'snorkeling':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeSnorkeling,
      };
    case 'snorkelingSupervised':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeSnorkelingSupervised,
      };
    case 'watchingTour':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeWatchingTour,
      };
    case 'observer':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeObserver,
      };
    case 'first-dive':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeFirstDive,
      };
    case 'training':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeTraining,
      };
    case 'theoretical-training':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeTheoreticalTraining,
      };
    case 'supervised':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeSupervised,
      };
    case 'autonomous':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeAutonomous,
      };
    case 'autoSupervised':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.autoSupervised,
      };
    case 'free-dive':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeFreeDive,
      };
    case 'unknown':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeUnknown,
      };

    case 'instructor':
      return {
        colorContrast: '#ffffff',
        colorMain: APP_COLORS.diveModeInstructor,
      };

    default:
      return {
        colorContrast: '#ffffff',
        colorMain: '#333333',
      };
  }
}
