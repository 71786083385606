import clsx from 'clsx';
import { default as React } from 'react';
import { AppLoadableContentContainer } from '../AppLoadableContentContainer';
import { AppPageContainerProps } from './AppPageContainerProps.type';

export function AppPageContainer({
  id,
  children,
  retry,
  lastActionStatus = 'success',
  contentState = 'full',
  maxScreenClassName = 'max-w-screen-2xl',
  style,
  className,
}: AppPageContainerProps) {
  return (
    <div
      id={id}
      style={style}
      className={clsx(
        'w-full h-full flex flex-col',
        maxScreenClassName,
        className,
      )}
    >
      <AppLoadableContentContainer
        contentState={contentState}
        lastActionStatus={lastActionStatus}
        retry={retry}
      >
        {contentState !== 'none' && <>{children}</>}
      </AppLoadableContentContainer>
    </div>
  );
}
