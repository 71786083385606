/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubPurchasePayment } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiverBookingPageAggregatedData } from '../../models';
import { defaultBookingBuilder, defaultDiverBuilder } from '../../services';
import { DiverPurchasePaymentEditorBookingDepositInitialModel } from './DiverPurchasePaymentEditorBookingDepositModel.type';
import { DiverPurchasePaymentEditorPackageInitialModel } from './DiverPurchasePaymentEditorPackageInitialModel.type';
import { DiverPurchasePaymentEditorFormModel } from './components';
import { DiverPurchasePaymentEditorFormModelBookingDeposit } from './components/DiverPurchasePaymentEditorForm/DiverPurchasePaymentEditorFormModelBookingDeposit.type';
import { DiverPurchasePaymentEditorFormModelPackage } from './components/DiverPurchasePaymentEditorForm/DiverPurchasePaymentEditorFormModelPackage.type';

export function useDiverPurchasePaymentEditorDialogInitialValue({
  aggregatedData,
  diveCenterId,
  defaultDiverId,
  purchasePayment,
  initialPurchasePaymentPackages,
  initialPurchasePaymentBookingDeposits,
  defaultPackagesIds,
  defaultBookingDepositsIds,
  mode,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  diveCenterId: string;
  defaultDiverId: string;
  purchasePayment?: ClubPurchasePayment;
  initialPurchasePaymentPackages: DiverPurchasePaymentEditorPackageInitialModel[];
  initialPurchasePaymentBookingDeposits: DiverPurchasePaymentEditorBookingDepositInitialModel[];
  defaultPackagesIds: string[];
  defaultBookingDepositsIds: string[];
  mode: 'create' | 'edit';
}) {
  const clubResume = useClubResume();
  const generalSettings = clubResume.clubSettings.general;

  const initialFormValue: DiverPurchasePaymentEditorFormModel = useMemo(() => {
    const packages: DiverPurchasePaymentEditorFormModelPackage[] =
      initialPurchasePaymentPackages.map((ppp) => {
        const purchasePackageId = ppp.updatedPurchasePackage._id;
        const packageModel: DiverPurchasePaymentEditorFormModelPackage = {
          selected:
            !defaultPackagesIds ||
            defaultPackagesIds.includes(purchasePackageId),
          amount: ppp.updatedAmountForPackage,
          purchasePackageId,
        };
        return packageModel;
      });

    const bookingDeposits: DiverPurchasePaymentEditorFormModelBookingDeposit[] =
      initialPurchasePaymentBookingDeposits.map((x) => {
        const bookingDepositId = x.updatedBookingDeposit._id;
        const packageModel: DiverPurchasePaymentEditorFormModelBookingDeposit =
          {
            selected:
              !defaultBookingDepositsIds ||
              defaultBookingDepositsIds.includes(bookingDepositId),
            amount: x.updatedAmountForBookingDeposit,
            bookingDepositId,
          };
        return packageModel;
      });

    const today = dateService.getUTCDateWithoutTimeFromLocalTime(new Date());
    if (mode === 'create') {
      const defaultBookingId = defaultBookingBuilder.getDefaultBookingId(
        aggregatedData.bookingResumesVisible,
        {
          diveCenterId,
          focusDiverId: aggregatedData.focus?.clubDiver._id,
        },
      );
      const diverId =
        defaultDiverId ?? defaultDiverBuilder.getDefaultDiverId(aggregatedData);

      const model: DiverPurchasePaymentEditorFormModel = {
        diverId,
        bookingId: defaultBookingId,
        paymentState: 'validated',
        paymentDate: today,
        packages,
        bookingDeposits,
        usedCurrencyIsoCode: generalSettings.currencyIsoCode,
      };
      return model;
    } else {
      if (purchasePayment) {
        const paymentDate = purchasePayment.paymentDate ?? today;
        const model: DiverPurchasePaymentEditorFormModel = {
          diverId: purchasePayment.diverId,
          bookingId: purchasePayment.bookingId,
          payedToBookingAgencyId: purchasePayment.payedToBookingAgencyId,
          paymentMethod: purchasePayment.paymentMethod,
          onlinePlatformId: purchasePayment.onlinePlatformId,
          paymentState: purchasePayment.paymentState,
          paymentDate,
          paymentReference: purchasePayment.paymentReference,
          comment: purchasePayment.comment,
          customerComment: purchasePayment.customerComment,
          packages,
          bookingDeposits,
          usedCurrencyIsoCode: purchasePayment.usedCurrencyIsoCode,
          usedCurrencyAmount: purchasePayment.usedCurrencyAmount,
        };
        return model;
      }
    }
  }, [
    aggregatedData,
    defaultBookingDepositsIds,
    defaultDiverId,
    defaultPackagesIds,
    diveCenterId,
    generalSettings.currencyIsoCode,
    initialPurchasePaymentBookingDeposits,
    initialPurchasePaymentPackages,
    mode,
    purchasePayment,
  ]);

  return initialFormValue;
}
