/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';

export const ClubPlanningLightPanelViewPeriodMonthDayHeader = ({
  date,
  className,
}: {
  date: Date;
  className?: string;
}) => {
  const isThisYear = useMemo(
    () => date.getFullYear() === new Date().getFullYear(),
    [date],
  );
  return (
    <div className={clsx('bg-white', className)}>
      <div
        className={clsx(
          'px-1 bg-white text-gray-600',
          'py-1 text-center flex flex-col md:flex-row text-xs md:text-sm leading-4 sm:leading-4 md:justify-around md:items-center',
        )}
      >
        <div className={'text-xs md:text-xs font-bold uppercase'}>
          <div className="lg:hidden">{dateService.formatUTC(date, 'ddd')}</div>
          <div className="hidden lg:block">
            {dateService.formatUTC(date, 'dddd')}
          </div>
        </div>
      </div>
    </div>
  );
};
