/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  CompanyAccommodation,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppHeroIcons,
  AppIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';

export const CustomerAccomodationsTableRow = ({
  accommodation,
  onClick,
}: {
  accommodation: CompanyAccommodation;
  onClick?: () => void;
}) => {
  const clubResume = useClubResume();
  const addressString = useMemo(
    () =>
      [accommodation.address?.line1, accommodation.address?.city]
        .filter((x) => x?.trim()?.length > 0)
        .join(', '),
    [accommodation.address?.city, accommodation.address?.line1],
  );
  return (
    <tr className={'cursor-pointer'} onClick={onClick}>
      <td
        className={`px-2 py-0.5 whitespace-nowrap uppercase font-bold ${
          accommodation.active ? '' : 'text-red-400'
        }`}
      >
        <div className="flex gap-2 items-center">
          <AppIcons.settings.customers.accomodation
            className="w-6 h-4 border border-gray-600 py-px px-pt"
            style={{
              background: accommodation.color,
              color: accommodation.colorText,
            }}
          />
          <span>{accommodation.name}</span>
        </div>
        {addressString?.length > 0 && (
          <div className="text-xs text-gray-500 font-normal">
            {addressString}
          </div>
        )}
      </td>
      <td
        className={
          'hidden xs:table-cell px-2 py-0.5 whitespace-nowrap text-center text-sm text-gray-600'
        }
      >
        {accommodation.pickup?.enabled ? (
          <div className="flex gap-2 items-center">
            <AppIconsSettings.customers.pickup
              className="w-6 h-4 border border-gray-600 py-px px-pt"
              style={{
                background: accommodation.color,
                color: accommodation.colorText,
              }}
            />
            {accommodation.pickup?.offsetInMinutes}
            <span className="sm:hidden">mn</span>
            <span className="hidden sm:inline">minutes avant la session</span>
          </div>
        ) : null}
      </td>
      <td
        className={
          'hidden lg:table-cell px-2 py-0.5 whitespace-nowrap uppercase text-center'
        }
      >
        {accommodation.comment}
      </td>
      <td className={'px-2 py-0.5 text-right'}>
        {accommodation.active ? (
          <button
            type="button"
            title="Supprimer cet hébergement"
            className="p-1 bg-gray-200 hover:bg-red-400 text-white rounded-full"
            onClick={async (e) => {
              e.stopPropagation();
              if (
                await confirmDialog.confirmPromise({
                  title: 'Supprimer cet hébergement',
                  message:
                    'Êtes-vous sûr de vouloir supprimer cet hébergement?',
                  type: 'noYesDanger',
                })
              ) {
                await clubMassiveUpdatorClient.update({
                  companyAccommodations: { deletedIds: [accommodation._id] },
                });
              }
            }}
          >
            <AppHeroIcons.actionDelete className="h-6 w-6 " />
          </button>
        ) : (
          <button
            type="button"
            title="Ré-activer cet hébergement"
            className="p-1 bg-gray-400 hover:bg-green-400 text-white rounded-full"
            onClick={async (e) => {
              e.stopPropagation();
              if (
                await confirmDialog.confirmPromise({
                  title: 'Ré-activer cet hébergement',
                  message:
                    'Êtes-vous sûr de vouloir activer cet hébergement archivé?',
                  type: 'noYesDanger',
                })
              ) {
                const patchOperations = jsonPatcher.compareObjects(
                  accommodation,
                  {
                    ...accommodation,
                    active: true,
                  },
                  {},
                );
                if (patchOperations.length) {
                  const patch: AppEntityUpdatePatch = {
                    pk: accommodation._id,
                    patchOperations,
                  };
                  // await clubMassiveUpdatorClient.update({
                  //   updatedCompanyAccommodations: [patch],
                  // });
                }
              }
            }}
          >
            <AppHeroIcons.actionConfirm className="h-6 w-6 " />
          </button>
        )}
      </td>
    </tr>
  );
};
