import {
  DiveCenterDailyConfig,
  DiveCenterDailyConfigGql_Company,
} from '@mabadive/app-common-model';
import {
  HASURA_DATE_ONLY_FORMAT,
  dateService,
} from '@mabadive/app-common-services';
import { MQueryDescription } from 'src/_common-browser';

export function buildDailyConfigCollectionGraphqlQuery({
  clubReference,
  diveCenterId,
  minDateInclusive,
  maxDateExclusive,
}: {
  clubReference: string;
  diveCenterId: string;
  minDateInclusive: Date;
  maxDateExclusive: Date;
}) {
  const minDateInclusiveString = dateService.formatUTC(
    minDateInclusive,
    HASURA_DATE_ONLY_FORMAT,
  );
  const maxDateExclusiveString = dateService.formatUTC(
    maxDateExclusive,
    HASURA_DATE_ONLY_FORMAT,
  );

  const queryDescription: MQueryDescription<DiveCenterDailyConfig> = {
    returnName: 'dailyConfigs',
    queryName: 'dive_center_daily_config',
    returnType: 'all',
    where: `{_and: [{clubReference: {_eq: "${clubReference}"}}, {diveCenterId: {_eq: "${diveCenterId}"}}, {date: {_gte: "${minDateInclusiveString}"}}, {date: {_lt: "${maxDateExclusiveString}"}}]}`,
    orderBy: '{date: asc}',
    returnAttributes: DiveCenterDailyConfigGql_Company,
  };

  return queryDescription;
}
