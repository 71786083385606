import {
  AppEntityUpdatePatch,
  ClubProductPackageOffer,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useClubResume } from 'src/business/club/data/hooks';
import { clubOfferDiveEditorResultBuilder } from 'src/business/club/modules/club-settings/club-settings-offers/club-settings-offer-dive/ClubOfferDiveEditor/clubOfferDiveEditorResultBuilder.service';
import { ClubSettingsProductOffersDialogProps } from './_model';

export function useClubSettingsProductOffersDialogActionPersist(): ClubSettingsProductOffersDialogProps {
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;
  const action: ClubSettingsProductOffersDialogProps = {
    async onConfirm({ formValue }, initialState) {
      const { defaultValue } = initialState;
      if (initialState.mode === 'create') {
        const newOffers = formValue.offers
          ?.filter((x) => {
            // création en masse: on ignore ceux dont le prix n'est pas renseigné
            return formValue.offers.length === 1 || x.price > 0;
          })
          .map((formOffer) => {
            return commonEntityBuilder.buildEntity<ClubProductPackageOffer>({
              ...formOffer,
              reference: clubOfferDiveEditorResultBuilder.rebuildReference({
                clubReference,
                type: formOffer.productPackage.type,
                productType: formOffer.productPackage.productType,
                diveAttributes: formOffer.productPackage.diveAttributes,
                trainingAttributes: formOffer.productPackage.trainingAttributes,
                productAttributes: formOffer.productPackage.productAttributes,
              }),
            });
          });

        if (newOffers.length) {
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'edit club general settings',
              bookingIdsToClean: [],
            });

          payload.newProductPackageOffers = newOffers;

          return await clubMassiveUpdatorClient.update(payload);
        }
      } else if (initialState.mode === 'edit') {
        const deletedOffersIds = defaultValue.offers
          .filter((defaultOffer) => {
            return (
              formValue.offers.find(
                (formOffer) => formOffer.reference === defaultOffer.reference,
              ) === undefined
            );
          })
          .map((x) => x._id);

        const { updatedOffers } = formValue.offers.reduce(
          (acc, formOffer) => {
            const defaultOffer = defaultValue.offers.find(
              (defaultOffer) => formOffer.reference === defaultOffer.reference,
            );

            if (defaultOffer) {
              // check if any change
              const isReferenceObsolete =
                clubOfferDiveEditorResultBuilder.testIfReferenceObsolete({
                  initial: {
                    diveAttributes: defaultOffer.productPackage.diveAttributes,
                    productAttributes:
                      defaultOffer.productPackage.productAttributes,
                    trainingAttributes:
                      defaultOffer.productPackage.trainingAttributes,
                  },
                  final: {
                    diveAttributes: formOffer.productPackage.diveAttributes,
                    productAttributes:
                      formOffer.productPackage.productAttributes,
                    trainingAttributes:
                      formOffer.productPackage.trainingAttributes,
                  },
                });

              // rebuild reference if main attributes changes
              formOffer.reference = isReferenceObsolete
                ? clubOfferDiveEditorResultBuilder.rebuildReference({
                    clubReference,
                    type: formOffer.productPackage.type,
                    productType: formOffer.productPackage.productType,
                    diveAttributes: formOffer.productPackage.diveAttributes,
                    trainingAttributes:
                      formOffer.productPackage.trainingAttributes,
                    productAttributes:
                      formOffer.productPackage.productAttributes,
                  })
                : defaultOffer.reference;

              const offerPatchOperations = jsonPatcher.compareObjects(
                defaultOffer,
                formOffer,
                {
                  replaceDeleteByNullValue: true,
                },
              );
              if (offerPatchOperations.length) {
                const patch: AppEntityUpdatePatch = {
                  pk: defaultOffer._id,
                  patchOperations: offerPatchOperations,
                };
                acc.updatedOffers.push(patch);
              }
            }
            return acc;
          },
          { newOffers: [], updatedOffers: [] } as {
            updatedOffers: AppEntityUpdatePatch[];
          },
        );

        if (deletedOffersIds.length || updatedOffers.length) {
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'edit club general settings',
              bookingIdsToClean: [],
            });

          if (deletedOffersIds.length) {
            payload.deletedProductPackageOfferIds = deletedOffersIds;
          }
          if (updatedOffers.length) {
            payload.updatedProductPackageOffers = updatedOffers;
          }
          return await clubMassiveUpdatorClient.update(payload);
        }
      }
    },
  };
  return action;
}
