/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';

import {
  AppFormControlRHF,
  AppInputBooleanRHF,
  AppInputTimeHoursMinutesRHF,
  AppMultiCheckboxesRHF,
} from 'src/lib/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useDiveSessionThemesOptions,
  useDiveSessionTypesOptions,
} from 'src/pages/_components/options';
import {
  ClubSettingsGeneralDialogInitialState,
  ClubSettingsGeneralFormModel,
} from '../../../_model';

export const ClubSettingsGeneralSessionsForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
  initialState: ClubSettingsGeneralDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const diveSessionTypesOptions = useDiveSessionTypesOptions({
    scope: 'all',
  });

  const diveSessionThemesOptions = useDiveSessionThemesOptions({
    scope: 'all',
  });

  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsSection title="Sessions / sorties">
        <AppFormControlRHF
          className="w-full"
          label={'Types de sortie'}
          helpDescription="Permet de créer des sessions dédiées à une activité"
          control={control}
          name={'clubSettings.general.sessions.types'}
          renderComponent={(props) => (
            <AppMultiCheckboxesRHF
              {...props}
              options={diveSessionTypesOptions}
            />
          )}
        />
        <AppFormControlRHF
          className="w-full"
          label={'Thèmes de sortie'}
          helpDescription="Permet de configurer rapidement le nom et l'icone d'une session à thème"
          control={control}
          name={'clubSettings.general.sessions.themes'}
          renderComponent={(props) => (
            <AppMultiCheckboxesRHF
              {...props}
              options={diveSessionThemesOptions}
            />
          )}
        />
      </ClubSettingsSection>
      <ClubSettingsSection title="Options">
        <AppFormControlRHF
          className="mt-2"
          control={control}
          name="clubSettings.general.sessions.multiSessions.enabled"
          renderComponent={(props) => (
            <AppInputBooleanRHF
              {...props}
              margin="mr-2.5"
              type="checkbox"
              description={'Plongées 2-tank (double-bloc)'}
              onChange={(enabled) => {
                if (enabled) {
                  setValue(
                    'clubSettings.general.sessions.multiSessions.maxSessions',
                    2,
                  );
                } else {
                  setValue(
                    'clubSettings.general.sessions.multiSessions.maxSessions',
                    null,
                  );
                }
              }}
            />
          )}
        />
        <AppFormControlRHF
          className="mt-2"
          control={control}
          name="clubSettings.general.sessions.displayAutonomousField"
          label={'Palanquées autonomes'}
          helpDescription="Pour chaque plongée, confirmer les palanquées autonomes"
          renderComponent={(props) => (
            <AppInputBooleanRHF
              {...props}
              margin="mr-2.5"
              type="checkbox"
              description={'Valider manuellement sur la fiche de sécurité'}
            />
          )}
        />
      </ClubSettingsSection>
      {isSuperAdmin && (
        <ClubSettingsSection title="Demi-journées moniteur">
          <div className="grid gap-4">
            <div className="grid gap-1">
              <p className="font-medium text-gray-600">
                Heures de dispo des moniteurs du matin
              </p>
              <div className="flex gap-4">
                <AppFormControlRHF
                  className="mt-2"
                  control={control}
                  name="clubSettings.ui.planning.staffPeriods.am.start"
                  helpDescription=""
                  label={'Matin (début)'}
                  renderComponent={(props) => (
                    <AppInputTimeHoursMinutesRHF {...props} />
                  )}
                />
                <AppFormControlRHF
                  className="mt-2"
                  control={control}
                  name="clubSettings.ui.planning.staffPeriods.am.end"
                  label={'Matin (fin)'}
                  renderComponent={(props) => (
                    <AppInputTimeHoursMinutesRHF {...props} />
                  )}
                />
              </div>
            </div>
            <div className="grid gap-1">
              <p className="font-medium text-gray-600">
                Heures de dispo des moniteurs de l'après-midi
              </p>
              <div className="flex gap-4">
                <AppFormControlRHF
                  className="mt-2"
                  control={control}
                  name="clubSettings.ui.planning.staffPeriods.pm.start"
                  label={'Après-midi (début)'}
                  renderComponent={(props) => (
                    <AppInputTimeHoursMinutesRHF {...props} />
                  )}
                />
                <AppFormControlRHF
                  className="mt-2"
                  control={control}
                  name="clubSettings.ui.planning.staffPeriods.pm.end"
                  label={'Après-midi (fin)'}
                  renderComponent={(props) => (
                    <AppInputTimeHoursMinutesRHF {...props} />
                  )}
                />
              </div>
            </div>
          </div>
        </ClubSettingsSection>
      )}
    </div>
  );
};
