import { ClubPurchasePaymentResume } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useProgressiveListRendering } from 'src/business/_core/data/hooks';
import { useClubResume } from '../../../../business/club/data/hooks';
import { PaymentsListTableRow } from './PaymentsListTableRow';

export function PaymentsListTable({
  payments: paymentsInput,
  className,
}: {
  payments: ClubPurchasePaymentResume[];
  className?: string;
}) {
  const paymentsToRender = useProgressiveListRendering(paymentsInput, {
    initialSize: 50,
    step: 100,
  });
  const clubResume = useClubResume();
  const generalSettings = clubResume?.clubSettings?.general;

  return (
    <table className={clsx('app-table min-w-full', className)}>
      <thead>
        <tr>
          {/* <th scope="col" className="w-9 py-1 text-right">
            <span className="sr-only">Actions</span>
          </th> */}
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Date
          </th>
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Montant
          </th>
          <th scope="col" className="px-2 py-1  uppercase tracking-wider ">
            <div className="flex">Plongeur</div>
          </th>{' '}
          <th
            scope="col"
            className="hidden md:table-cell px-2 py-1  uppercase tracking-wider "
          >
            <div className="flex">Groupe</div>
          </th>
          {generalSettings?.agencies?.enabled &&
            generalSettings.agencies?.agencyPayment && (
              <th
                scope="col"
                className="px-2 py-1 uppercase tracking-wider hidden sm:table-cell"
              >
                Agence
              </th>
            )}
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden sm:table-cell"
          >
            Moyen de paiement
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden xs:table-cell"
          >
            Statut
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden md:table-cell"
          >
            Référence
          </th>
        </tr>
      </thead>
      <tbody>
        {paymentsToRender.map((purchasePayment, i) => (
          <PaymentsListTableRow
            key={purchasePayment._id}
            purchasePayment={purchasePayment}
          />
        ))}
      </tbody>
    </table>
  );
}
