/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import {
  AppButton,
  AppButtonMultiActions,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import {
  CreateOfferPayload,
  clubSettingsProductOfferBuilder,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export const ClubSettingsServicesViewPanelOffersPricesActionsBar_Explo = ({
  localState,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
}) => {
  const { data, state, actions } = localState;
  const publicSettings = data.publicSettings;
  const clubReference = data.clubReference;
  const clubSettings = data.clubSettings;

  const forfaitsExploMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    const divesCounts = [
      10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      30, 40, 50, 100,
    ];

    for (let i = 0; i < divesCounts.length; i++) {
      const divesCount = divesCounts[i];
      hamburgerMenuItems.push({
        title: `Explo x${divesCount}`,
        icon: AppHeroIcons.actionAdd,
        onClick: () => {
          const newOffers: CreateOfferPayload[] =
            clubSettingsProductOfferBuilder.buildNewOffersExplo({
              clubReference,
              publicSettings,
              divesCount,
            });
          actions.openOfferEditDialog({
            mode: 'create',
            offers: newOffers,
          });
        },
      });
    }
    return hamburgerMenuItems;
  }, [actions, clubReference, publicSettings]);

  return (
    <>
      <AppButton
        className="whitespace-nowrap"
        color="primary-outline-light"
        size="normal"
        icon={AppHeroIcons.actionAdd}
        onClick={() => {
          const newOffers: CreateOfferPayload[] =
            clubSettingsProductOfferBuilder.buildNewOffersExplo({
              clubReference,
              publicSettings,
              divesCount: 1,
            });
          actions.openOfferEditDialog({
            mode: 'create',
            offers: newOffers,
          });
        }}
      >
        Explo x1
      </AppButton>
      <AppButtonMultiActions items={forfaitsExploMenuItems} />
      <AppButton
        className="whitespace-nowrap"
        color="primary-outline-light"
        size="normal"
        icon={AppHeroIcons.actionAdd}
        onClick={() => {
          const newOffers: CreateOfferPayload[] =
            clubSettingsProductOfferBuilder.buildNewOffersExploSpecial({
              clubReference,
              publicSettings,
              divesCount: 1,
            });
          actions.openOfferEditDialog({
            mode: 'create',
            offers: newOffers,
          });
        }}
      >
        Plongée spéciale
      </AppButton>
    </>
  );
};
