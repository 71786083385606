import {
  BookingDeposit,
  ClubPurchasePackagePaymentStatus,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { diverPurchasePackageBuilder } from '../DiverPurchaseCommonEditorDialog';
import { DiverPurchasePaymentEditorBookingDepositChangesModel } from './DiverPurchasePaymentEditorBookingDepositChangesModel.type';
import { DiverPurchasePaymentEditorBookingDepositInitialModel } from './DiverPurchasePaymentEditorBookingDepositModel.type';
import { DiverPurchasePaymentEditorPackageChangesModel } from './DiverPurchasePaymentEditorPackageChangesModel.type';
import { DiverPurchasePaymentEditorPackageInitialModel } from './DiverPurchasePaymentEditorPackageInitialModel.type';
import { DiverPurchasePaymentEditorPaymentChangesModel } from './DiverPurchasePaymentEditorPaymentChangesModel.type';
import { DiverPurchasePaymentEditorFormModelBookingDeposit } from './components/DiverPurchasePaymentEditorForm/DiverPurchasePaymentEditorFormModelBookingDeposit.type';
import { DiverPurchasePaymentEditorFormModelPackage } from './components/DiverPurchasePaymentEditorForm/DiverPurchasePaymentEditorFormModelPackage.type';

export function useAggregatePaymentChangesModel({
  initialPurchasePaymentPackages,
  initialPurchasePaymentBookingDeposits,
  formPackages,
  bookingDeposits,
  paymentWithBookingDeposit,
}: {
  initialPurchasePaymentPackages: DiverPurchasePaymentEditorPackageInitialModel[];
  initialPurchasePaymentBookingDeposits: DiverPurchasePaymentEditorBookingDepositInitialModel[];
  formPackages: DiverPurchasePaymentEditorFormModelPackage[];
  bookingDeposits: DiverPurchasePaymentEditorFormModelBookingDeposit[];
  paymentWithBookingDeposit: BookingDeposit;
}): DiverPurchasePaymentEditorPaymentChangesModel {
  return useMemo(() => {
    const packagesModels: DiverPurchasePaymentEditorPackageChangesModel[] =
      buildPackagesModels({ initialPurchasePaymentPackages, formPackages });
    const bookingDepositsModels: DiverPurchasePaymentEditorBookingDepositChangesModel[] =
      buildBookingDepositChangesModels({
        initialPurchasePaymentBookingDeposits,
        bookingDeposits,
      });
    const totalToPay = packagesModels.reduce(
      (acc, ppp) => {
        if (ppp.selected) {
          return ppp.updatedAmountForPackage + acc;
        }
        return acc;
      },
      bookingDepositsModels.reduce((acc, x) => {
        if (x.selected) {
          return x.updatedAmountForBookingDeposit + acc;
        }
        return acc;
      }, 0),
    );

    const model: DiverPurchasePaymentEditorPaymentChangesModel = {
      totalToPay,
      paymentWithBookingDeposit,
      packages: packagesModels,
      bookingDeposits: bookingDepositsModels,
    };
    return model;
  }, [
    bookingDeposits,
    formPackages,
    initialPurchasePaymentBookingDeposits,
    initialPurchasePaymentPackages,
    paymentWithBookingDeposit,
  ]);
}
function buildPackagesModels({
  initialPurchasePaymentPackages,
  formPackages,
}: {
  initialPurchasePaymentPackages: DiverPurchasePaymentEditorPackageInitialModel[];
  formPackages: DiverPurchasePaymentEditorFormModelPackage[];
}): DiverPurchasePaymentEditorPackageChangesModel[] {
  return initialPurchasePaymentPackages.map((ppp, i) => {
    const selected = formPackages[i].selected;
    if (selected) {
      const updatedAmountForPackage = formPackages[i].amount;
      let purchasePaymentStatus: ClubPurchasePackagePaymentStatus =
        ppp.initialPurchasePackage.purchasePaymentStatus;

      const payedAmount =
        (ppp.initialPurchasePackage.payedAmount ?? 0) -
        ppp.initialAmountForPackage +
        updatedAmountForPackage;

      const remainingAmount = Math.max(
        0,
        ppp.initialPurchasePackage.price - payedAmount,
      );
      if (ppp.initialAmountForPackage !== updatedAmountForPackage) {
        const price = ppp.initialPurchasePackage.price;

        purchasePaymentStatus = diverPurchasePackageBuilder.buildPaymentStatus({
          price,
          payedAmount,
        });
      }
      const result: DiverPurchasePaymentEditorPackageChangesModel = {
        ...ppp,
        selected: true,
        updatedAmountForPackage,
        updatedPurchasePackage: {
          ...ppp.updatedPurchasePackage,
          purchasePaymentStatus,
          payedAmount,
        },
        remainingAmount,
      };
      return result;
    }
    // not selected: keep original value
    const result: DiverPurchasePaymentEditorPackageChangesModel = {
      ...ppp,
      selected: false,
      remainingAmount: Math.max(
        0,
        ppp.updatedPurchasePackage.price -
          ppp.updatedPurchasePackage.payedAmount,
      ),
    };
    return result;
  });
}
function buildBookingDepositChangesModels({
  initialPurchasePaymentBookingDeposits,
  bookingDeposits,
}: {
  initialPurchasePaymentBookingDeposits: DiverPurchasePaymentEditorBookingDepositInitialModel[];
  bookingDeposits: DiverPurchasePaymentEditorFormModelBookingDeposit[];
}): DiverPurchasePaymentEditorBookingDepositChangesModel[] {
  return initialPurchasePaymentBookingDeposits.map((x, i) => {
    const selected = bookingDeposits[i].selected;
    if (selected) {
      const updatedAmountForBookingDeposit = bookingDeposits[i].amount;
      let purchasePaymentStatus: ClubPurchasePackagePaymentStatus =
        x.initialBookingDeposit.purchasePaymentStatus;

      const payedAmount =
        (x.initialBookingDeposit.payedAmount ?? 0) -
        x.initialAmountForBookingDeposit +
        updatedAmountForBookingDeposit;

      const remainingAmount = Math.max(
        0,
        x.initialBookingDeposit.price - payedAmount,
      );
      if (x.initialAmountForBookingDeposit !== updatedAmountForBookingDeposit) {
        const price = x.initialBookingDeposit.price;

        purchasePaymentStatus = diverPurchasePackageBuilder.buildPaymentStatus({
          price,
          payedAmount,
        });
      }
      const result: DiverPurchasePaymentEditorBookingDepositChangesModel = {
        ...x,
        selected: true,
        updatedAmountForBookingDeposit,
        updatedBookingDeposit: {
          ...x.updatedBookingDeposit,
          purchasePaymentStatus,
          payedAmount,
        },
        remainingAmount,
      };
      return result;
    }
    // not selected: keep original value
    const result: DiverPurchasePaymentEditorBookingDepositChangesModel = {
      ...x,
      selected: false,
      remainingAmount: Math.max(
        0,
        x.updatedBookingDeposit.price - x.updatedBookingDeposit.payedAmount,
      ),
    };
    return result;
  });
}
