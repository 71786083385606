/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  ClubParticipantAutoSupervisedDiveMode,
  DiveMode,
} from '@mabadive/app-common-model';
import {
  AutoSupervisedDiveModeChangeImpacts,
  DiveModeChangeImpacts,
  diveModeAnalyser,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { BookingParticipantDefaultParamsEditFormRHFGroup1 } from './BookingParticipantDefaultParamsEditFormRHFGroup1';
import { BookingParticipantDefaultParamsEditFormRHFGroup2 } from './BookingParticipantDefaultParamsEditFormRHFGroup2';
import { BookingParticipantDefaultParamsEditFormRHFGroup3 } from './BookingParticipantDefaultParamsEditFormRHFGroup3';

export const BookingParticipantDefaultParamsEditFormRHF = ({
  form,
  maxWidthMd,
  className = '',
}: {
  form: UseFormReturn<Pick<BookingParticipantEditorFormModel, 'diver'>>;
  maxWidthMd: boolean;

  className?: string;
}) => {
  const onDiveModeChange = useOnDiveModeChangesContextDiver({ form });
  const onAutoSupervisedDiveModeChange =
    useOnAutoSupervisedDiveModeChangesContextDiver({
      form,
    });

  return (
    <div className={clsx('w-full flex flex-col gap-2', className)}>
      {/* type de plongée, niveau/formation, plongée spéciale */}
      <BookingParticipantDefaultParamsEditFormRHFGroup1
        maxWidthMd={maxWidthMd}
        form={form}
        onDiveModeChange={onDiveModeChange}
        onAutoSupervisedDiveModeChange={onAutoSupervisedDiveModeChange}
      />
      {/* aptitude, profondeur, gaz */}
      <BookingParticipantDefaultParamsEditFormRHFGroup3
        maxWidthMd={maxWidthMd}
        form={form}
      />
      {/* matériel */}
      <BookingParticipantDefaultParamsEditFormRHFGroup2
        maxWidthMd={maxWidthMd}
        form={form}
      />
    </div>
  );
};
// ATTENTION: dans BookingParticipantEditForm, fonction quasi similaire pour l'édition des participants
export function useOnDiveModeChangesContextDiver({
  form,
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
}) {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;

  const { control, setValue } = form;

  const [
    previousDiveMode,
    firstDiveReference,
    mainCertificationReference1,
    mainCertificationReference2,
    gazType,
    participantCertificationReference,
    trainingReference,
  ] = useWatch({
    control,
    name: [
      'diver.defaultDiveConfig.diveMode',
      'diver.defaultDiveConfig.firstDiveReference',
      'diver.divingCertification1.mainCertificationReference',
      'diver.divingCertification2.mainCertificationReference',
      'diver.defaultDiveConfig.gaz.gazType',
      'diver.defaultDiveConfig.certificationReference',
      'diver.defaultDiveConfig.trainingReference',
    ],
  });

  const diverCertificationReference =
    mainCertificationReference1 ?? mainCertificationReference2;

  // ATTENTION: dans BookingParticipantEditForm, fonction quasi similaire pour l'édition des participants
  const onDiveModeChange = useCallback(
    (diveMode: DiveMode) => {
      const diveModeChangeImpacts: DiveModeChangeImpacts =
        diveModeAnalyser.buildDiveModeChangeImpacts(diveMode, {
          clubSettings,
          diveCenterResume,
          gazType,
          firstDiveReference,
          participantCertificationReference,
          diverCertificationReference,
          trainingReference,
          previousDiveMode,
        });
      // NOTE: details.specialDiveType n'existe pas dans ce context
      if (
        diveModeChangeImpacts.diveConfig.autoSupervisedDetails !== undefined
      ) {
        setValue(
          'diver.defaultDiveConfig.autoSupervisedDetails',
          diveModeChangeImpacts.diveConfig.autoSupervisedDetails,
        );
      }
      if (diveModeChangeImpacts.diveConfig.gaz !== undefined) {
        setValue(
          'diver.defaultDiveConfig.gaz',
          diveModeChangeImpacts.diveConfig.gaz,
        );
      }

      if (diveModeChangeImpacts.diveConfig.firstDiveReference !== undefined) {
        setValue(
          'diver.defaultDiveConfig.firstDiveReference',
          diveModeChangeImpacts.diveConfig.firstDiveReference ?? undefined, // on remplace null par undefined
        );
      }
      if (diveModeChangeImpacts.diveConfig.trainingReference !== undefined) {
        setValue(
          'diver.defaultDiveConfig.trainingReference',
          diveModeChangeImpacts.diveConfig.trainingReference ?? undefined, // on remplace null par undefined
        );
      }
      if (
        diveModeChangeImpacts.diveConfig.certificationReference !== undefined
      ) {
        setValue(
          'diver.defaultDiveConfig.certificationReference',
          diveModeChangeImpacts.diveConfig.certificationReference ?? undefined, // on remplace null par undefined
        );
      }
    },
    [
      clubSettings,
      diveCenterResume,
      gazType,
      firstDiveReference,
      participantCertificationReference,
      diverCertificationReference,
      trainingReference,
      previousDiveMode,
      setValue,
    ],
  );
  return onDiveModeChange;
}

export function useOnAutoSupervisedDiveModeChangesContextDiver({
  form,
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
}) {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;

  const { control, setValue } = form;

  const [
    mainCertificationReference1,
    mainCertificationReference2,
    autoSupervisedCertificationReference,
  ] = useWatch({
    control,
    name: [
      'diver.divingCertification1.mainCertificationReference',
      'diver.divingCertification2.mainCertificationReference',
      'diver.defaultDiveConfig.autoSupervisedDetails.certificationReference',
    ],
  });

  const diverCertificationReference =
    mainCertificationReference1 ?? mainCertificationReference2;

  // ATTENTION: dans BookingParticipantEditForm, fonction quasi similaire pour l'édition des participants
  const onAutoSupervisedDiveModeChange = useCallback(
    (autoSupervisedDiveMode: ClubParticipantAutoSupervisedDiveMode) => {
      const diveModeChangeImpacts: AutoSupervisedDiveModeChangeImpacts =
        diveModeAnalyser.buildAutoSupervisedDiveModeChangeImpacts(
          autoSupervisedDiveMode,
          {
            clubSettings,
            diveCenterResume,
            diverCertificationReference,
            autoSupervisedCertificationReference,
          },
        );

      if (diveModeChangeImpacts.diveConfig.trainingReference !== undefined) {
        setValue(
          'diver.defaultDiveConfig.autoSupervisedDetails.trainingReference',
          diveModeChangeImpacts.diveConfig.trainingReference ?? undefined, // on remplace null par undefined
        );
      }
      if (
        diveModeChangeImpacts.diveConfig.certificationReference !== undefined
      ) {
        setValue(
          'diver.defaultDiveConfig.autoSupervisedDetails.certificationReference',
          diveModeChangeImpacts.diveConfig.certificationReference ?? undefined, // on remplace null par undefined
        );
      }
    },
    [
      autoSupervisedCertificationReference,
      clubSettings,
      diveCenterResume,
      diverCertificationReference,
      setValue,
    ],
  );
  return onAutoSupervisedDiveModeChange;
}
