/* eslint-disable @typescript-eslint/no-unused-vars */
import { bookingDepositStateFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { DateRangeLabel } from 'src/pages/_components';
import { DepositsListPageLocalState } from '../useDepositsListPageLocalState';

export const DepositsListPageConfigBarFiltersResume = ({
  localState,
  className,
}: {
  localState: DepositsListPageLocalState;
  className?: string;
}) => {
  const { viewParameters, setViewParameters, filters } = localState;

  const { searchText, depositState, purchasePaymentPending, partiallyUsed } =
    filters;
  const { beginDate, endDate } = viewParameters.periodRange.value;

  return beginDate ||
    endDate ||
    depositState ||
    purchasePaymentPending ||
    searchText?.trim()?.length !== 0 ? (
    <div
      className={clsx(
        'flex justify-start flex-wrap gap-x-4 text-xs text-gray-400 uppercase',
        className,
      )}
    >
      {searchText?.trim()?.length !== 0 && (
        <div className={clsx('flex xs:hidden gap-1')}>
          <div className="font-bold whitespace-nowrap">Recherche:</div>
          <div className="whitespace-nowrap">{searchText?.trim()}</div>
        </div>
      )}
      {(beginDate || endDate) && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Date:</div>
          <DateRangeLabel beginDate={beginDate} endDate={endDate} />
        </div>
      )}
      {purchasePaymentPending && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Paiement en attente</div>
        </div>
      )}
      {partiallyUsed && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">
            Partiellement utilisés
          </div>
        </div>
      )}
      {depositState && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Statut:</div>
          {bookingDepositStateFormatter.formatDepositState(depositState, {
            isPaymentPending: false,
          })}
        </div>
      )}
    </div>
  ) : null;
};
