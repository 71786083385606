/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButtonsBar } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { useCreateBookingInitialPlanNavigation } from './useCreateBookingInitialPlanNavigation.hook';
import { CreateBookingInitialPlanPageLocalStateLocalState } from './useCreateBookingInitialPlanPageLocalState.hook';
export const CreateBookingInitialPlanPageFooterActionsBar = ({
  localState,
}: {
  localState: CreateBookingInitialPlanPageLocalStateLocalState;
}) => {
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const {
    form,
    hasFormChanges,
    navigation: { currentStep, setCurrentStep, steps },
    navigationContext,
    operationInProgressMessage,
  } = localState;

  const { goBack, goNext } = useCreateBookingInitialPlanNavigation({
    localState,
  });

  const { register, handleSubmit, watch, control, formState, setValue, reset } =
    form;

  return (
    <AppButtonsBar hasChanges={true}>
      <>
        <AppButton
          size="normal"
          icon={AppHeroIcons.actionCancel}
          tabIndex={500}
          color="gray-outline-light"
          onClick={goBack}
        >
          {currentStep === 'contact'
            ? hasFormChanges
              ? 'Annuler'
              : 'Fermer'
            : 'Retour'}
        </AppButton>
        <AppButton
          className="uppercase font-bold"
          disabled={!formState.isValid || !!operationInProgressMessage}
          size="normal"
          icon={AppHeroIcons.actionConfirm}
          color="primary-outline-light"
          onClick={goNext}
        >
          {currentStep === 'booking' ? 'Terminer' : 'Confirmer'}
        </AppButton>
      </>
    </AppButtonsBar>
  );
};
