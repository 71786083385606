/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { PlanningViewConfig } from '../../../club-planning/_model';
import { ClubPlanningDayFull } from '../../model/ClubPlanningDayFull.type';
import { ClubPlanningLightPanelViewPeriodDaySessionHeader } from './ClubPlanningLightPanelViewPeriodDaySessionHeader';

export const ClubPlanningLightPanelViewPeriodDaySessionsList: FC<{
  day: ClubPlanningDayFull;
  planningConfig: PlanningViewConfig;
  dailySessions: ClubPlanningLightSessionDef[];
  staffMembersDailySessions: StaffMemberResumeSessionsModel[];
  className?: string;
}> = ({
  day,
  planningConfig,
  dailySessions,
  staffMembersDailySessions,
  className = '',
}) => {
  const diveCenterResume = useDiveCenterResume();

  const gridClasses =
    'grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10 gap-x-2 gap-y-1';

  return (
    <div className={clsx('', gridClasses, className)}>
      {/* sessions resume */}
      {dailySessions.map((session, i) => (
        <div className={clsx('select-none text-gray-700 text-sm leading-4 ')}>
          <ClubPlanningLightPanelViewPeriodDaySessionHeader
            key={i}
            session={session}
            sessionIndex={{ index: i + 1, total: day.sessions.length }}
            onClick={() => {
              // TODO
            }}
            widthMode={'small'}
          />
        </div>
      ))}
    </div>
  );
};
