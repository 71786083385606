import { BookingMember, ClubDiver } from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  diverBookingMemberCreator,
} from '@mabadive/app-common-services';
import {
  DiverBookingPageUpdateState,
  PRO_BookingResume,
} from '../../../models';

export const quickBookingMemberCreator = {
  selectOrCreateMember,
};

function selectOrCreateMember({
  aggregatedBooking,
  diver,
  bookingId,
  clubReference,
  diveCenterId,
  updateStateLocal,
}: {
  aggregatedBooking: PRO_BookingResume;
  diver: ClubDiver;
  bookingId: string;
  clubReference: string;
  diveCenterId: string;
  updateStateLocal: DiverBookingPageUpdateState;
}): {
  bookingMember: BookingMember;
} {
  let member: BookingMember = (aggregatedBooking?.bookingMembers ?? []).find(
    (m) => m.diverId === diver._id,
  );
  let bookingMember = member
    ? ({
        ...member,
        diver: undefined,
        __typename: undefined,
      } as BookingMember)
    : undefined;

  if (!member) {
    // create member
    bookingMember = diverBookingMemberCreator.createBookingMemberFromDiver({
      diver,
      bookingId,
      clubReference,
      diveCenterId,
    });
    updateStateLocal.bookingMembersChanges = changeDescriptorManager.createOne(
      bookingMember,
      {
        changeDescriptors: updateStateLocal.bookingMembersChanges,
      },
    );

    updateStateLocal.diversChanges = changeDescriptorManager.addOneOriginal(
      diver,
      {
        changeDescriptors: updateStateLocal.diversChanges,
      },
    );
  }
  return { bookingMember };
}
