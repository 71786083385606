/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubDialogsState } from '../../../../../pages/_dialogs';
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import { CalendarSessionWidthMode } from '../components/ClubPlanningLightSession/session-common';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
  DiveSessionSimpleCardDisplayOptions,
} from '../model';
import { ClubPlanningLightPanelViewPeriodWeekSessionsModeCompactDay } from './ClubPlanningLightPanelViewPeriodWeekSessionsModeCompactDay';

export const ClubPlanningLightPanelViewPeriodWeekSessionsModeCompact = ({
  planningParticipantsLocalState,
  dialogsState,
  context,
  planningConfig,
  days,
  clickActions,
  sessionsDisplayConfigs = [],
  widthMode,
  className,
}: {
  planningParticipantsLocalState?: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
  dialogsState: ClubDialogsState; // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  context: ClubPlanningDisplayConfigurationBarContext;
  planningConfig: PlanningViewConfig;
  days: ClubPlanningDayFull[];
  clickActions: ClubPlanningLightPanelViewClickActions;
  sessionsDisplayConfigs?: ClubPlanningPanelSessionDisplayConfig[];
  widthMode: CalendarSessionWidthMode;
  className?: string;
}) => {
  const mediaSize = useMediaSizeTailwind();

  const {
    onClickSession,
    onClickSessionOpenButton,
    onClickAddParticipant,
    onClickParticipant,
    anchorLinkParticipant,
    onClickCreateNewSession,
    onClickCreateNewEvent,
    onClickEvent,
  } = clickActions;

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const displayMode = planningConfig.viewWeekOptions.displayMode;

  const displayOptions: DiveSessionSimpleCardDisplayOptions = useMemo(
    () => ({
      boats: diveCenterResume?.boats.length > 1,
      tide: true,
      weather: true,
      comment: true,
      addParticipantButton1:
        context !== 'planning-staff-page' &&
        onClickAddParticipant !== undefined,
      addParticipantButton2: onClickAddParticipant !== undefined,
      openSessionButton: onClickSessionOpenButton !== undefined,
      showDiveGroups: context !== 'planning-staff-page',
      showStaffRoles: context === 'planning-staff-page',
    }),
    [
      diveCenterResume?.boats.length,
      onClickAddParticipant,
      onClickSessionOpenButton,
      context,
    ],
  );

  const hasAnyEvent: boolean = useMemo(
    () => days.find((day) => day.clubEvents.length > 0) !== undefined,
    [days],
  );

  return (
    <div
      className={`max-h-full
            grid grid-cols-7 gap-0.5 sm:gap-2
            ${className ?? ''}`}
    >
      {days.map((day, iDay) => (
        <ClubPlanningLightPanelViewPeriodWeekSessionsModeCompactDay
          key={iDay}
          day={day}
          context={context}
          planningConfig={planningConfig}
          clickActions={clickActions}
          sessionsDisplayConfigs={sessionsDisplayConfigs}
          widthMode={widthMode}
          hasAnyEvent={hasAnyEvent}
          displayOptions={displayOptions}
          planningParticipantsLocalState={planningParticipantsLocalState}
          dialogsState={dialogsState}
        />
      ))}
    </div>
  );
};
