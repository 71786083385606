import { dataObjectCompare } from '@mabadive/app-common-services';
import {
  BehaviorSubject,
  from,
  NEVER,
  Observable,
  of,
  throwError,
  timer,
} from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { authenticationStore } from 'src/business/auth/services';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { clubAppOperationApiClient } from './clubAppOperationApiClient.service';
import { clubAppOperationUpdateClientStoreProvider } from './clubAppOperationUpdateClientStoreProvider.service';
import { ClubAppOperationUpdateClientPendingPayload } from './model/ClubAppOperationUpdateClientPendingPayload.type';

const logger = appLogger.child({
  module: 'payload',
  filename: 'clubAppOperationUpdateClientConsumer.service',
});

export const clubAppOperationUpdateClientConsumer = {
  startPayloadsConsumer,
  stopPayloadsConsumer,
};

const CONSUME_PERIOD_MS = appWebConfig.envId === 'dev' ? 1000 : 5000;

const config$ = new BehaviorSubject<{
  enabled: boolean;
  userId?: string;
}>({
  enabled: false,
});

config$
  .pipe(
    distinctUntilChanged((a, b) =>
      dataObjectCompare.objectsEquals(a, b, {
        attributes: (item) => [item.enabled, item.userId],
      }),
    ),
    switchMap(({ enabled, userId }) =>
      !enabled
        ? NEVER
        : clubAppOperationUpdateClientStoreProvider
            .loadInitialPayloadsFromBrowserCache({ userId })
            .pipe(
              switchMap(
                () => timer(0, CONSUME_PERIOD_MS),
                (processId) => processId,
              ),
              tap((processId) =>
                logger.debug(
                  '[clubAppOperationUpdateClientConsumer.startPayloadsConsumer] START CHECK (processId=%d)',
                  processId,
                ),
              ),
              exhaustMap(() =>
                clubAppOperationUpdateClientStoreProvider.getAll().pipe(
                  tap((payloads) =>
                    logger.debug(
                      '[clubAppOperationUpdateClientConsumer.startPayloadsConsumer] %d payloads to process (before debounce)',
                      payloads.length,
                    ),
                  ),
                  debounceTime(1000),
                  first(),
                  exhaustMap((pendingPayloads) =>
                    processNextPendingPayloads({ userId, pendingPayloads }),
                  ),
                ),
              ),
            ),
    ),
    catchError((err) => {
      // c'est toujours une erreur 401 ou 403, mais on pourrait avoir un traitement différent si besoin:
      // if (err.response && (err.response.status === 401 || err.response.status === 403)){
      appLogger.warn(
        '[clubAppOperationUpdateClientConsumer] 401 or 403 ERROR: redirect to login page',
        err,
      );
      stopPayloadsConsumer();
      authenticationStore.logoutRequired.set(true);
      return of(undefined);
    }),
  )
  .subscribe();

function startPayloadsConsumer({ userId }: { userId: string }) {
  appLogger.debug('[clubAppOperationUpdateClientConsumer] START');
  config$.next({
    enabled: true,
    userId,
  });
}

function stopPayloadsConsumer() {
  appLogger.debug('[clubAppOperationUpdateClientConsumer] STOP');
  config$.next({
    enabled: false,
  });
}

function processNextPendingPayloads({
  userId,
  pendingPayloads,
}: {
  userId: string;
  pendingPayloads: ClubAppOperationUpdateClientPendingPayload[];
}) {
  if (pendingPayloads && pendingPayloads.length !== 0) {
    return of(pendingPayloads).pipe(
      tap((pendingPayloads) =>
        logger.debug(
          '[clubAppOperationUpdateClientConsumer.startPayloadsConsumer] %d payloads to process',
          pendingPayloads.length,
        ),
      ),
      concatMap((payloads) => persistPayloads({ userId, pendingPayloads })),
      tap((payloads) => {
        if (payloads.length) {
          logger.info(
            '[clubAppOperationUpdateClientConsumer.startPayloadsConsumer] payloads consumed',
            payloads.length,
          );
        }
      }),
    );
  }
  logger.debug(
    '[clubAppOperationUpdateClientConsumer.startPayloadsConsumer] no payloads to consume',
  );

  return of([]);
}

function persistPayloads({
  userId,
  pendingPayloads,
}: {
  userId: string;
  pendingPayloads: ClubAppOperationUpdateClientPendingPayload[];
}): Observable<void[]> {
  return of(pendingPayloads).pipe(
    switchMap((pendingPayloads) => {
      logger.debug(
        '[clubAppOperationUpdateClientConsumer.processPayloads] %d payloads to process',
        pendingPayloads.length,
      );
      const operations = pendingPayloads.map((pp) => pp.operation);
      return from(clubAppOperationApiClient.updateMany(operations));
    }),
    map(() => {
      // SUCCESS
      logger.debug(
        '[clubAppOperationUpdateClientConsumer.processPayloads] payloads send:',
      );
      clubAppOperationUpdateClientStoreProvider.removeMany({
        userId,
        pendingPayloads,
        actionId: 'consumePayloads',
      });
      return [];
    }),
    catchError((err) => {
      const toRemove = pendingPayloads.filter((x) => x.errorMode === 'skip');
      if (toRemove.length) {
        clubAppOperationUpdateClientStoreProvider.removeMany({
          userId,
          pendingPayloads: toRemove,
          actionId: 'skipPayloadOnError',
        });
      }
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      ) {
        logger.debug(
          '[clubAppOperationUpdateClientConsumer.processPayloads] security error:',
          err,
        );
        return throwError(err);
      }
      return of([]);
    }),
  );
}
