import {
  ClubDiverFull,
  DiveCertificationReference,
} from '@mabadive/app-common-model';
import { arrayBuilder, search } from '@mabadive/app-common-services';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  LoadableContentPartial,
  appLoader,
  appLoaderChain,
} from '../../app-loading';
import { ClubDiverFullSearchCriteria } from './ClubDiverFullSearchCriteria.type';
import { clubDiverFullGraphqlFetcher } from './graphql/clubDiverFullGraphqlFetcher.service';
import { clubDiverStore } from './store/clubDiverStore';

export const clubDiverFullSearchRepository = {
  findByFilters,
};

function findByFilters(
  criteria: ClubDiverFullSearchCriteria,
): Observable<LoadableContentPartial<ClubDiverFull[]>> {
  const localLoader$ = _findByFiltersLocal(criteria);
  const remoteLoader$ = _findByFiltersRemote(criteria);

  return appLoaderChain.loadChain([localLoader$, remoteLoader$], {
    mergeContent: (a, b) => mergeById(a, b),
  });
}

function _findByFiltersLocal({
  fullText,
  mainCertificationReference,
  searchAttributes,
  maxReturnResults,
}: ClubDiverFullSearchCriteria) {
  return appLoader.load(
    clubDiverStore.clubDiverFullCollection.getAll().pipe(
      first(),
      map((divers) =>
        _filterLocally(divers, {
          fullText,
          mainCertificationReference,
          searchAttributes,
          maxReturnResults,
        }),
      ),
    ),
    {
      type: 'partial', // 'partial': force to always run a remote search as well
      defaultValue: [],
    },
  );
}

function _findByFiltersRemote({
  fullText,
  minLettersForRemoteSearch,
  clubReference,
  mainCertificationReference,
  searchAttributes,
  maxLastUpdateRemoteResultsIfRemoteSearchIsSkipped,
}: ClubDiverFullSearchCriteria) {
  const skipRemoteSearch =
    !fullText || fullText.trim().length < minLettersForRemoteSearch;

  if (skipRemoteSearch) {
    // on fait quand même une recherche pour récupérer les dernières mises à jour
    return appLoader.load(
      clubDiverFullGraphqlFetcher
        .findLastUpdated({
          maxDivers: maxLastUpdateRemoteResultsIfRemoteSearchIsSkipped,
          clubReference,
          mainCertificationReference,
        })
        .pipe(
          map((data) => {
            updateLocalStore(data);
            const content = _filterLocally(data, {
              fullText,
              mainCertificationReference,
              searchAttributes,
            });
            return content;
          }),
        ),
      { type: 'full', defaultValue: [] },
    );
    // return of({
    //   content: [],
    //   contentState: 'none',
    //   lastActionStatus: 'success',
    // } as LoadableContentPartial<ClubDiverFull[]>);
  }
  return appLoader.load(
    clubDiverFullGraphqlFetcher.findFullText({ fullText, clubReference }).pipe(
      map((data) => {
        updateLocalStore(data);
        const content = _filterLocally(data, {
          fullText,
          mainCertificationReference,
          searchAttributes,
        });
        return content;
      }),
    ),
    { type: 'full', defaultValue: [] },
  );
}

function updateLocalStore(data: ClubDiverFull[]) {
  clubDiverStore.clubDiverFullCollection.addOrUpdateMany({
    identify: (a, b) => a?._id === b?._id,
    actionId: 'findLastUpdated',
    values: data,
  });
}

function mergeById<T extends { _id?: any }>(a: T[], b: T[]): T[] {
  return arrayBuilder.mergeArrays(a, b, { compare: (x, y) => x._id === y._id });
}

function _filterLocally(
  divers: ClubDiverFull[],
  {
    fullText,
    mainCertificationReference,
    searchAttributes,
    maxReturnResults,
  }: {
    fullText: string;
    mainCertificationReference: DiveCertificationReference;
    searchAttributes: (keyof ClubDiverFull)[];
    maxReturnResults?: number;
  },
): ClubDiverFull[] {
  let filteredDivers = search.filter(divers, {
    attributesNames: searchAttributes,
    searchText: fullText,
    sortResultsByBestMatch: false,
    maxResults: maxReturnResults,
  });
  if (mainCertificationReference) {
    filteredDivers = filteredDivers.filter(
      (d) => d.mainCertificationReference === mainCertificationReference,
    );
  }
  return filteredDivers;
}
