/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferView,
  ClubPurchasePackage,
  ClubSettings,
} from '@mabadive/app-common-model';
import {
  ClubProductPackageOfferViewPlanGroup,
  clubProductPackageMetaReader,
  clubProductPackageOfferGroupMatcher,
  clubProductPackageOfferMatcherBestPlan,
  clubPurchasePackageMetaReader,
  dateService,
  numberFormatter,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  BillingTabDiveSessionBillingResume,
  DiverBookingPageAggregatedData,
} from '../../models';
import { defaultBookingBuilder } from '../../services';
import { bookingPagePackageConsumedCounter } from '../../services/02.update-state/services';
import { DiverPurchasePlanFormModel } from './components';

export function useDiverPurchasePlanEditorDialogInitialValue({
  purchasePackage,
  divesCount,
  defaultProductPackageOffer,
  productPackageOffers,
  planGroups,
  mode,
  initialAssociatedBookingProductIds,
  clubSettings,
  billingResumes,
  aggregatedData,
  diveCenterId,
}: {
  diveCenterId: string;
  purchasePackage?: ClubPurchasePackage;
  divesCount: number;
  defaultProductPackageOffer?: ClubProductPackageOfferView;
  productPackageOffers: ClubProductPackageOfferView[];
  planGroups: ClubProductPackageOfferViewPlanGroup[];
  mode: 'create' | 'edit';
  initialAssociatedBookingProductIds: string[];
  clubSettings: ClubSettings;
  billingResumes: BillingTabDiveSessionBillingResume[];
  aggregatedData: DiverBookingPageAggregatedData;
}): DiverPurchasePlanFormModel {
  const clubResume = useClubResume();

  // const defaultProductPackageOffer = defaultProductPackageOfferReference
  //   ? clubResume.productPackageOffers.find(
  //       (o) => o.reference === defaultProductPackageOfferReference,
  //     )
  //   : undefined;

  const initialFormValue: DiverPurchasePlanFormModel = useMemo(() => {
    if (mode === 'create') {
      const meta = clubProductPackageMetaReader.readMeta(
        defaultProductPackageOffer?.productPackage,
      );

      const { consumedAppCount } =
        bookingPagePackageConsumedCounter.buildConsumedAppCountOnBuildUpdateResume(
          {
            clubSettings,
            billingResumes,
            assignedBookingProductsIds: initialAssociatedBookingProductIds,
            diverId: purchasePackage?.diverId,
            countSuccessiveAsSingle: false, // pas pertinent pour un autre produit
          },
        );
      const consumedTotalCount = consumedAppCount;

      const creditsInitialCount = meta?.productDefaultCreditsCount;

      const unitQuantity =
        meta?.diveAttributes?.divesCount === 1 ? divesCount : 1;
      const totalCreditsCount = unitQuantity * creditsInitialCount;

      const planGroupIndex =
        clubProductPackageOfferGroupMatcher.findOfferPlanGroupIndex({
          planGroups,
          offer: defaultProductPackageOffer,
        });
      planGroups.findIndex(
        (x) =>
          (x.divesCount === meta?.diveAttributes?.divesCount &&
            !(meta?.diveAttributes?.successiveDivesCount > 1)) ||
          x.successiveDivesCount === meta?.diveAttributes?.successiveDivesCount,
      );
      const initialBookingId = defaultBookingBuilder.getDefaultBookingId(
        aggregatedData.bookingResumesVisible,
        {
          diveCenterId,
          focusDiverId: aggregatedData.focus?.clubDiver._id,
        },
      );

      const iv: DiverPurchasePlanFormModel = {
        productPackageOfferReference: defaultProductPackageOffer?.reference,
        unitPrice: defaultProductPackageOffer?.price,
        totalPriceThirdPartyCollect:
          defaultProductPackageOffer?.thirdPartyCollectPrice > 0 &&
          unitQuantity > 0
            ? defaultProductPackageOffer?.thirdPartyCollectPrice * unitQuantity
            : undefined,
        creditsInitialCount,
        unitQuantity,
        extraCosts: [],
        purchaseDate: dateService.getUTCDateWithoutTimeFromLocalTime(
          new Date(),
        ),
        validityStatus:
          consumedTotalCount >= totalCreditsCount ? 'completed' : 'active',
        creditsAdditionalCount: 0,
        consumedExternalCount: 0,
        planGroupIndex,
        bookingId: initialBookingId,
        billedByBookingAgencyId: undefined,
      };
      return iv;
    } else {
      // edit
      if (purchasePackage) {
        const meta = clubPurchasePackageMetaReader.readMeta(purchasePackage);
        const initialCredits = purchasePackage.credits;
        const diveAttributes =
          purchasePackage.productPackageOffer.productPackage.diveAttributes;
        const salesCriteria =
          purchasePackage.productPackageOffer.productPackage.salesCriteria;
        let productPackageOfferReference =
          purchasePackage.productPackageOffer.reference;

        if (!productPackageOfferReference) {
          // produit créé sans offre associée (par exemple lors de la migration v2, mais il y aura peut-être d'autres cas dans le futur?)
          productPackageOfferReference =
            clubProductPackageOfferMatcherBestPlan.findBestMatchingOfferPlan({
              criteria: {
                divesCount: meta.productDefaultCreditsCount,
                successiveDivesCount: diveAttributes.successiveDivesCount,
                supervision: diveAttributes?.supervision,
                equipment: diveAttributes?.equipment,
                isInstructor: diveAttributes?.diveMode === 'instructor',
                specialDiveType: diveAttributes?.specialDiveType,
                diveSessionTheme: salesCriteria?.diveSessionTheme,
              },
              productPackageOffers,
            })?.reference;
        }

        const planGroupIndex = planGroups.findIndex(
          (x) =>
            (x.divesCount === meta?.diveAttributes?.divesCount &&
              !(meta?.diveAttributes?.successiveDivesCount > 1)) ||
            x.successiveDivesCount ===
              meta?.diveAttributes?.successiveDivesCount,
        );

        const discountPercentage =
          purchasePackage.totalPriceBeforeDiscount > 0
            ? numberFormatter.roundWithCents(
                (purchasePackage.discountAmount /
                  purchasePackage.totalPriceBeforeDiscount) *
                  100,
                2,
              )
            : 0;

        const iv: DiverPurchasePlanFormModel = {
          productPackageOfferReference,
          purchaseDate: purchasePackage.purchaseDate,
          unitPrice: purchasePackage.unitPrice,
          totalPriceThirdPartyCollect:
            purchasePackage.totalPriceThirdPartyCollect,
          extraCosts: purchasePackage.extraCosts ?? [],
          discountAmount: purchasePackage.discountAmount,
          discountPercentage,
          unitQuantity: purchasePackage.unitQuantity,
          comment: purchasePackage.comment,
          validityStatus: purchasePackage.validityStatus,
          creditsAdditionalCount: initialCredits.creditsAdditionalCount,
          creditsInitialCount: initialCredits.creditsInitialCount,
          consumedExternalCount: initialCredits.consumedExternalCount,
          planGroupIndex,
          bookingId: purchasePackage.bookingId,
          billedByBookingAgencyId: purchasePackage.billedByBookingAgencyId,
        };
        return iv;
      }
    }
  }, [
    aggregatedData.bookingResumesVisible,
    aggregatedData.focus?.clubDiver._id,
    billingResumes,
    clubSettings,
    defaultProductPackageOffer,
    diveCenterId,
    divesCount,
    initialAssociatedBookingProductIds,
    mode,
    planGroups,
    productPackageOffers,
    purchasePackage,
  ]);

  return initialFormValue;
}
