/* eslint-disable @typescript-eslint/no-unused-vars */
import { AuthUser } from '@mabadive/app-common-model';
import { singleRepositoryFactory } from 'src/business/_core/data/repository/single/singleRepositoryFactory.service';
import { accountStore } from 'src/business/_core/store';

// NOTE: mutation référencée dans SYNCHRONIZABLE_SINGLE_REPOSITORIES

const store = accountStore.authUser;

export const authUserRepository = {
  ...singleRepositoryFactory.create<AuthUser>({
    repositoryName: 'authUserRepository',
    store,
    mutationEntity: 'authUser',
  }),
};
