import { AppUserTrackingClient } from '@mabadive/app-common-model';
import { NEVER, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  AppAuth,
  authenticationClient,
  authenticationStore,
} from 'src/business/auth/services';
import { clubAppOperationUpdateClientConsumer } from 'src/business/_core/data/app-operation';
import { appDataLoader } from 'src/business/_core/data/loaders';
import { processMonitor } from 'src/business/_core/process';
import { uiStore } from 'src/business/_core/store';
import { appLogger } from '../logger/appLogger.service';

export const appDataLoaderManager = {
  manageDataLoading,
};

function manageDataLoading({
  appClientId,
  trackingClient,
}: {
  appClientId: string;
  trackingClient: AppUserTrackingClient;
}) {
  return authenticationStore.auth.get().pipe(
    // tap(appAuth => appLogger.info('[appDataLoaderManager.synchronizeStore] appAuth 1:', appAuth)),
    distinctUntilChanged(authenticationClient.appAuthEquals),
    switchMap((appAuth: AppAuth) => {
      // appLogger.info('[appDataLoaderManager.synchronizeStore] appAuth 2 (distinct):', appAuth);
      clearStore();
      if (appAuth.isAuthenticated) {
        return processMonitor
          .run(
            loadInitialDataAndSynchronizeStore(
              appClientId,
              appAuth,
              trackingClient,
            ),
            {
              name: 'load-initial-data',
            },
          )
          .pipe(
            tap((process) => {
              uiStore.runningProcessLoadInitialData.set(process);
            }),
          );
      }
      return NEVER;
    }),
    catchError((err) => {
      clearStore();
      appLogger.warn('[appDataLoaderManager.manageDataLoading] error 2', err);
      authenticationStore.logoutRequired.set(true);
      return throwError(err);
    }),
  );
}

function loadInitialDataAndSynchronizeStore(
  appClientId: string,
  appAuth: AppAuth,
  trackingClient: AppUserTrackingClient,
) {
  appLogger.info(
    '[appDataLoaderManager.loadInitialDataAndSynchronizeStore]',
    appAuth,
  );

  return appDataLoader
    .loadAppInitialData({
      appAuth,
      appClientId,
      options: {
        initialFetchMethod: 'graphql-api',
        clubReference: appAuth.user.clubReference,
        userId: appAuth.user.userId,
        trackingClient,
      },
    })
    .pipe(
      tap(() => {
        if (appAuth && appAuth.user) {
          appLogger.info(
            '[appDataLoaderManager.loadInitialDataAndSynchronizeStore] consumers',
          );

          clubAppOperationUpdateClientConsumer.startPayloadsConsumer({
            userId: appAuth.user.userId,
          });
        }
      }),
      catchError((err) => {
        appLogger.warn(
          '[appDataLoaderManager.loadInitialDataAndSynchronizeStore] error',
          err,
        );
        clubAppOperationUpdateClientConsumer.stopPayloadsConsumer();
        return throwError(err);
      }),
    );
}

function clearStore() {
  appLogger.info('[appDataLoaderManager.clearStore]');

  clubAppOperationUpdateClientConsumer.stopPayloadsConsumer();
  appDataLoader.clear();
}
