import { CreditNote } from '@mabadive/app-common-model';
import { useCallback, useMemo } from 'react';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { AggregatedBookingPurchasePaymentWithDetails } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { PurchasePaymentCardCreditNoteActions } from './model';
export function usePurchasePaymentCardCreditNoteMenuItems({
  purchasePaymentWithDetails,
  creditNote,
  actions,
}: {
  purchasePaymentWithDetails: AggregatedBookingPurchasePaymentWithDetails;
  creditNote: CreditNote;
  actions: PurchasePaymentCardCreditNoteActions;
}) {
  const az = useAppSecurityUserClubAuthorizations();

  const confirmCreditNote = useCallback(async () => {
    if (
      await confirmDialog.confirmPromise({
        title: 'Supprimer un avoir',
        message: 'Êtes-vous sûr de vouloir supprimer cet avoir?',
        type: 'remove',
      })
    ) {
      actions.deleteCreditNote({
        creditNote,
      });
    }
  }, [actions, creditNote]);

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    if (az?.creditNote.action.edit && actions.editCreditNote) {
      hamburgerMenuItems.push({
        id: 'edit-credit-note',
        titleShort: 'Modifier',
        title: "Modifier l'avoir",
        description: "Modifier les détails de l'avoir",
        icon: AppHeroIcons.actionEdit,
        onClick: () => {
          actions.editCreditNote({
            creditNote,
            purchasePaymentWithDetails,
          });
        },
      });
    }

    if (az?.creditNote.action.delete && actions.deleteCreditNote) {
      hamburgerMenuItems.push({
        id: 'delete-credit-note',
        titleShort: 'Supprimer',
        title: "Supprimer l'avoir",
        description: "Supprimer définitivement le l'avoir",
        icon: AppHeroIcons.actionDelete,
        severity: 'danger',
        onClick: () => {
          confirmCreditNote();
        },
      });
    }
    return hamburgerMenuItems;
  }, [
    actions,
    az?.creditNote.action.delete,
    az?.creditNote.action.edit,
    confirmCreditNote,
    creditNote,
    purchasePaymentWithDetails,
  ]);

  return hamburgerMenuItems;
}
