import { dateService } from '@mabadive/app-common-services';
import {
  AppTitleDateNavigatorModelValue,
  AppTitleDateNavigatorPeriod,
} from '../model';

export const appTitleDateNavigatorDatesBuilder = {
  buildDefaultDates,
  buildDefaultDatesPeriodWeek,
  buildDefaultDatesPeriodDay,
  buildDefaultDatesPeriodMonth,
  buildDefaultDatesPeriodYear,
  buildDefaultDatesPeriodFiscalYear,
};

function buildDefaultDates({
  selectedDate: selectedDateInput,
  period,
  ajustPeriodRangeToCurrentDate,
  fiscalStartMonth,
  currentBeginDate,
  currentEndDate,
}: {
  selectedDate: Date;
  period: AppTitleDateNavigatorPeriod;
  ajustPeriodRangeToCurrentDate: boolean;
  fiscalStartMonth: number;
  currentBeginDate: Date;
  currentEndDate: Date;
}) {
  const selectedDate = new Date(selectedDateInput);

  let beginDate: Date;
  let endDate: Date;
  let value: AppTitleDateNavigatorModelValue;
  if (period === 'day') {
    value = buildDefaultDatesPeriodDay({
      selectedDate,
    });
  } else if (period === 'week') {
    value = buildDefaultDatesPeriodWeek({
      selectedDate,
    });
  } else if (period === 'month') {
    value = buildDefaultDatesPeriodMonth({
      selectedDate,
      ajustPeriodRangeToCurrentDate,
    });
  } else if (period === 'year') {
    value = buildDefaultDatesPeriodYear({
      selectedDate,
      ajustPeriodRangeToCurrentDate,
    });
  } else if (period === 'year-fiscal') {
    value = buildDefaultDatesPeriodFiscalYear({
      selectedDate,
      ajustPeriodRangeToCurrentDate,
      startMonth: fiscalStartMonth,
    });
  } else if (period === 'dates-range') {
    // keep current dates
    beginDate = currentBeginDate;
    endDate = currentEndDate;
    value = {
      selectedDate,
      beginDate,
      endDate,
    };
  }
  return value;
}

function buildDefaultDatesPeriodWeek({
  selectedDate: selectedDateInput,
}: {
  selectedDate: Date;
}) {
  const selectedDate = new Date(selectedDateInput);
  const firstDayOfWeek = dateService.getFirstWeekDay({
    refDate: selectedDate,
    firstIsoWeekDay: 1, // lundi
  });
  const beginDate = new Date(firstDayOfWeek);
  const endDate = dateService.add(firstDayOfWeek, 6, 'day');
  const value: AppTitleDateNavigatorModelValue = {
    selectedDate,
    beginDate,
    endDate,
  };
  return value;
}

function buildDefaultDatesPeriodDay({
  selectedDate: selectedDateInput,
}: {
  selectedDate: Date;
}) {
  const selectedDate = new Date(selectedDateInput);

  const beginDate = new Date(selectedDate);
  const endDate = new Date(selectedDate);
  const value: AppTitleDateNavigatorModelValue = {
    selectedDate,
    beginDate,
    endDate,
  };
  return value;
}

function buildDefaultDatesPeriodMonth({
  selectedDate: selectedDateInput,
  ajustPeriodRangeToCurrentDate,
}: {
  selectedDate: Date;
  ajustPeriodRangeToCurrentDate: boolean;
}) {
  const selectedDate = new Date(selectedDateInput);
  const now = new Date();

  const [beginDate, endDate] = dateService.getMonthBoundsUTC(selectedDate);
  if (ajustPeriodRangeToCurrentDate) {
    endDate.setUTCDate(now.getUTCDate());
  }
  const value: AppTitleDateNavigatorModelValue = {
    selectedDate,
    beginDate,
    endDate,
  };
  return value;
}

function buildDefaultDatesPeriodYear({
  selectedDate: selectedDateInput,
  ajustPeriodRangeToCurrentDate,
}: {
  selectedDate: Date;
  ajustPeriodRangeToCurrentDate: boolean;
}) {
  const selectedDate = new Date(selectedDateInput);
  const now = new Date();

  const [beginDate, endDate] = dateService.getYearBoundsUTC(selectedDate);
  if (ajustPeriodRangeToCurrentDate) {
    endDate.setUTCMonth(now.getUTCMonth());
    endDate.setUTCDate(now.getUTCDate());
  }
  const value: AppTitleDateNavigatorModelValue = {
    selectedDate,
    beginDate,
    endDate,
  };
  return value;
}
function buildDefaultDatesPeriodFiscalYear({
  selectedDate: selectedDateInput,
  ajustPeriodRangeToCurrentDate,
  startMonth,
}: {
  selectedDate: Date;
  ajustPeriodRangeToCurrentDate: boolean;
  startMonth: number;
}) {
  const selectedDate = new Date(selectedDateInput);
  const now = new Date();

  const [beginDate, endDate] = dateService.getFiscalYearBounds(selectedDate, {
    startMonth,
  });

  if (ajustPeriodRangeToCurrentDate) {
    // FIXME: ça n'est pas bon pour le mois, il faut
    if (now.getUTCMonth() > endDate.getUTCMonth()) {
      // le mois actuel est après la fin
      endDate.setUTCFullYear(endDate.getUTCFullYear() - 1);
      endDate.setUTCMonth(now.getUTCMonth());
      endDate.setUTCDate(now.getUTCDate());
    } else {
      endDate.setUTCMonth(now.getUTCMonth());
      endDate.setUTCDate(now.getUTCDate());
    }
  }
  const value: AppTitleDateNavigatorModelValue = {
    selectedDate,
    beginDate,
    endDate,
  };
  return value;
}
