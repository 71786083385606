import { BookingDepositState } from '@mabadive/app-common-model';
import { useMemo } from 'react';

export type BookingDepositStatusColors = {
  textLight: string;
  text: string;
  textDark: string;
};

export function useBookingDepositStatusColors({
  depositState,
}: {
  depositState: BookingDepositState;
}): {
  textLight: string;
  text: string;
  textDark: string;
} {
  return useMemo(() => {
    if (depositState === 'draft') {
      return {
        textLight: 'text-status-warn-light',
        text: 'text-status-warn',
        textDark: 'text-status-warn-dark',
      };
    } else if (depositState === 'used') {
      return {
        textLight: 'text-status-info-light',
        text: 'text-status-info',
        textDark: 'text-status-info-dark',
      };
    } else if (depositState === 'active') {
      return {
        textLight: 'text-status-success-light',
        text: 'text-status-success',
        textDark: 'text-status-success-dark',
      };
    } else {
      return {
        textLight: 'text-gray-400',
        text: 'text-gray-500',
        textDark: 'text-gray-600',
      };
    }
  }, [depositState]);
}
