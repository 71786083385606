/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubPlanningLightSessionDef } from '@mabadive/app-common-model';
import { commonDiveSessionReferenceParser } from '@mabadive/app-common-services';
import { Button } from '@material-ui/core';
import React, { FC, useState } from 'react';
import {
  AppButtonsBar,
  AppPageBlock,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { AppDialogModalDeprecated } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppMessage } from 'src/business/_core/modules/layout/components/_tailwind/AppMessage/AppMessage';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { ClubPlanningSessionSelectorPanel } from './ClubPlanningSessionSelectorPanel';

export type ClubPlanningSessionSelectorDialogStateContext = {
  bookingId: string;
  focusSessionReference?: string;
  defaultFocusDate?: Date;
};
export type ClubPlanningSessionSelectorDialogState = {
  isOpen: boolean;
  mode?: 'single' | 'multiple';
  alreadySelectedSessionsReferences?: string[];
  areadySelectedDiverIds?: string[];
  context?: ClubPlanningSessionSelectorDialogStateContext;
};

export const ClubPlanningSessionSelectorDialog: FC<{
  defaultFocusDate?: Date;
  setDefaultFocusDate?: (d: Date) => any;
  state: ClubPlanningSessionSelectorDialogState;
  onClose: () => void;
  onSelectSessions: (
    sessions: ClubPlanningLightSessionDef[],
    context: ClubPlanningSessionSelectorDialogStateContext,
  ) => void;
}> = ({
  state,
  onClose,
  onSelectSessions,
  defaultFocusDate,
  setDefaultFocusDate,
}) => {
  const { isOpen, alreadySelectedSessionsReferences, areadySelectedDiverIds } =
    state;
  const [selectedSessions, setSelectedSessions] =
    useState<ClubPlanningLightSessionDef[]>();

  const globalClasses = useGlobalClasses();

  return (
    <>
      <AppDialogModalDeprecated
        fullWidth={true}
        zIndex={1500}
        className="bg-white"
        open={isOpen}
        onClickClose={() => onClose()}
        showCloseButton={false}
      >
        <AppPageContainerWithFixedBars
          className="md:px-4"
          contentClassName="bg-white"
          overflow="overflow-y-auto"
          footerBar={() => (
            <AppButtonsBar hasChanges={true}>
              <>
                <Button
                  startIcon={<AppIconsMaterial.cancel />}
                  variant="outlined"
                  tabIndex={500}
                  className={globalClasses.buttonCancel}
                  onClick={() => {
                    onClose();
                    onSelectSessions([], state.context);
                  }}
                >
                  Annuler
                </Button>
                {state?.mode !== 'single' && (
                  <Button
                    startIcon={<AppIconsMaterial.save />}
                    variant="contained"
                    className={globalClasses.buttonSave}
                    onClick={() => {
                      onClose();
                      onSelectSessions(selectedSessions, state.context);
                    }}
                  >
                    Confirmer
                  </Button>
                )}
              </>
            </AppButtonsBar>
          )}
        >
          <h3 className="mt-2 text-center lg:text-left px-4 text-sm sm:text-base md:text-lg lg:text-xl font-extrabold bg-gray-600 text-white uppercase">
            {state?.mode === 'single'
              ? 'Sélectionner une sortie'
              : 'Sélectionner une ou plusieurs sorties'}
          </h3>
          <ClubPlanningSessionSelectorPanel
            defaultFocusDate={defaultFocusDate}
            onSelectSessions={(sessions) => {
              if (sessions.length > 0 && setDefaultFocusDate) {
                setDefaultFocusDate(
                  commonDiveSessionReferenceParser.parseSessionReferenceToDate(
                    sessions[0].reference,
                  ),
                );
              }
              setSelectedSessions(sessions);
              if (state?.mode === 'single') {
                onClose();
                onSelectSessions(sessions, state.context);
              }
            }}
            alreadySelectedSessionsReferences={
              alreadySelectedSessionsReferences
            }
            areadySelectedDiverIds={areadySelectedDiverIds}
            instructionsNode={({ planningConfig }) => {
              return (
                <AppPageBlock className="mb-10">
                  <AppMessage
                    className="mb-20"
                    title={'INSTRUCTIONS'}
                    message={
                      <ul className="ml-4 list-disc">
                        <li>
                          cliquer sur l'heure ou le titre d'une session pour la
                          sélectionner.
                        </li>
                        {((planningConfig.viewPeriod === 'week' &&
                          planningConfig.viewWeekOptions.displayMode ===
                            'compact') ||
                          (planningConfig.viewPeriod === 'day' &&
                            planningConfig.viewDayOptions.displayMode ===
                              'compact')) && (
                          <li>
                            cliquer sur le nombre de participants pour voir le
                            détail.
                          </li>
                        )}
                      </ul>
                    }
                  />
                </AppPageBlock>
              );
            }}
          />
        </AppPageContainerWithFixedBars>
      </AppDialogModalDeprecated>
    </>
  );
};
