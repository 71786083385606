/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingParticipantEditorFormModel } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputRHF, YES_NO_OPTIONS } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';

export const BookingMemberGeneralEditFormInsurance = ({
  form,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const securityUser = useAppSecurityUser();

  const { control, setValue } = form;

  const [hasInsurance] = useWatch({
    control,
    name: ['bookingMember.details.insurance.hasInsurance'],
  });

  return (
    <div
      className={`w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4 ${className}`}
    >
      <div className={'md:col-span-2 w-full flex gap-2'}>
        <AppFormControlRHF_Deprecated
          label="Assurance"
          control={control}
          name={'bookingMember.details.insurance.hasInsurance'}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              disableClearButton={false}
              theme={'material-ui'}
              options={YES_NO_OPTIONS}
            />
          )}
        />
        {hasInsurance && (
          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Nom/numéro assurance"
            control={control}
            name={'bookingMember.details.insurance.number'}
            renderComponent={(props) => (
              <AppInputRHF className="w-full" type="text" {...props} />
            )}
          />
        )}
      </div>

      {hasInsurance && (
        <AppFormControlRHF_Deprecated
          className="md:col-span-2 w-full"
          label="Date d'expiration"
          control={control}
          name={'bookingMember.details.insurance.expirationDate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF className="w-full" {...props} />
          )}
        />
      )}
    </div>
  );
};
