import { CalendarIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React from 'react';
import { AppInputDatePicker } from 'src/stories/components/04-form';
import { AppTitleDateNavigatorDayLabel } from '../AppTitleDateNavigatorDayLabel';
import { AppTitleDateNavigatorModel } from '../model';

export const AppTitleDateNavigatorDateRangePicker = ({
  model,
  onUpdateSelectedDate,
  onUpdateEndDate,
}: {
  model: AppTitleDateNavigatorModel;
  onUpdateSelectedDate: (newSelectedDayDate: Date) => void;
  onUpdateEndDate: (newSelectedDayDate: Date) => void;
}) => {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center gap-x-4">
      <div className="flex items-center gap-x-4">
        <div className={clsx('uppercase text-gray-600 font-bold', 'text-xl')}>
          du
        </div>
        <AppInputDatePicker
          // value={value}
          // minDate={minBeginDate}
          // maxDate={maxBeginDate}
          value={model.value.selectedDate}
          onChange={(newSelectedDayDate) => {
            if (newSelectedDayDate) {
              onUpdateSelectedDate(newSelectedDayDate);
            }
          }}
        >
          <div className="group flex gap-2 justify-center items-center py-2 px-5 border-2 bg-gray-50 border-gray-200 hover:bg-white cursor-pointer">
            <CalendarIcon
              className={
                'w-6 h-6 sm:w-10 sm:h-10 text-gray-600 group-hover:text-gray-700'
              }
            />
            <AppTitleDateNavigatorDayLabel
              selectedDay={model.value.beginDate}
              isToday={model.isNow}
            />
          </div>
        </AppInputDatePicker>
      </div>
      <div className="flex items-center gap-x-4">
        <div className={clsx('uppercase text-gray-600 font-bold', 'text-xl')}>
          au
        </div>
        <AppInputDatePicker
          // value={value}
          // minDate={minBeginDate}
          // maxDate={maxBeginDate}
          value={model.value.endDate}
          onChange={(newSelectedDayDate) => {
            if (newSelectedDayDate) {
              onUpdateEndDate(newSelectedDayDate);
            }
          }}
        >
          <div className="group flex gap-2 justify-center items-center py-2 px-5 border-2 bg-gray-50 border-gray-200 hover:bg-white cursor-pointer">
            <CalendarIcon
              className={
                'w-6 h-6 sm:w-10 sm:h-10 text-gray-600 group-hover:text-gray-700'
              }
            />
            <AppTitleDateNavigatorDayLabel
              selectedDay={model.value.endDate}
              isToday={model.isNow}
            />
          </div>
        </AppInputDatePicker>
      </div>
    </div>
  );
};
