import { ClubDiverDivingDetailsOrgCertification } from '@mabadive/app-common-model';
import { certificationFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ClubDiverCardDiver } from './ClubDiverCardAttribute';

export const ClubDiverCardAttributeCertification = ({
  diver,
  className = '',
}: {
  diver: ClubDiverCardDiver;
  className?: string;
}) => {
  const mainCertificationLabel = useMemo(() => {
    return [
      formatDivingCertificationMain(diver.divingCertification1),
      formatDivingCertificationMain(diver.divingCertification2),
    ]
      .filter((x) => !!x)
      .join(' / ');
  }, [diver.divingCertification1, diver.divingCertification2]);

  const gazCertificationLabel = useMemo(() => {
    return [
      formatDivingCertificationGaz(diver.divingCertification1),
      formatDivingCertificationGaz(diver.divingCertification2),
    ]
      .filter((x) => !!x)
      .join(' / ');
  }, [diver.divingCertification1, diver.divingCertification2]);

  return (
    <div className={`flex gap-2 ${className}`}>
      {mainCertificationLabel}
      {gazCertificationLabel && (
        <span className="text-orange-500">({gazCertificationLabel})</span>
      )}
      {diver.divingCertification1?.mainCertificationReference &&
        diver.divingCertification1?.checkedByClub && (
          <AppHeroIcons.badgeCheck className="inline ml-1 mb-2 h-4 w-4 text-status-success" />
        )}
    </div>
  );
};

function formatDivingCertificationMain(
  divingCertification: ClubDiverDivingDetailsOrgCertification,
) {
  if (divingCertification) {
    return certificationFormatter.formatCertificationReference(
      divingCertification?.mainCertificationReference,
      {
        format: 'ref',
        deepDiver: divingCertification?.specialties?.deepDiver,
      },
    );
  }
}

function formatDivingCertificationGaz(
  divingCertification: ClubDiverDivingDetailsOrgCertification,
) {
  if (divingCertification) {
    return divingCertification.gazCertificationReference;
  }
}
