import { RxjsFormAttributeValidator } from './RxjsFormAttributeValidator.type';

export function rxjsFormTextLengthValidator(minLength: number) {

  const validator: RxjsFormAttributeValidator<string> = ({ value }: { value: string }) => {
    return {
      valid: value && value.trim().length >= minLength,
      context: {
        minLength,
      },
    };
  };

  return validator;

}