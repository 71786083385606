import { BookingDeposit } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppIcons } from '../../../../business/_core/modules/layout/icons';
import { AppPriceLabel } from '../../../../business/club/modules/_common/ui';
import {
  BookingDepositStatusColors,
  useBookingDepositStatusColors,
} from '../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingDepositCard/useBookingDepositStatusColors.hook';
import { useAppCurrency } from '../../../_components/options';

export function DepositCreditDetails({
  bookingDeposit,
  className,
}: {
  bookingDeposit: Pick<
    BookingDeposit,
    | 'currencyIsoCode'
    | 'creditInitial'
    | 'creditRemaining'
    | 'depositState'
    | 'purchasePaymentPending'
  >;
  className?: string;
}) {
  const {
    currencyIsoCode,
    creditInitial,
    creditRemaining,
    depositState,
    purchasePaymentPending,
  } = bookingDeposit;
  const statusColors: BookingDepositStatusColors =
    useBookingDepositStatusColors({ depositState });

  const currency = useAppCurrency(currencyIsoCode);

  return (
    <div className={clsx(className)}>
      <div className="flex gap-1">
        <AppPriceLabel
          className={statusColors.textLight}
          amount={creditRemaining !== 0 ? creditRemaining : creditInitial}
          mainCurrency={currency}
        />

        {creditRemaining !== creditInitial && creditRemaining !== 0 && (
          <>
            /
            <AppPriceLabel
              className={statusColors.textLight}
              amount={creditInitial}
              mainCurrency={currency}
            />
          </>
        )}
      </div>

      {purchasePaymentPending && (
        <div className="flex-shrink-0 text-center">
          <AppIcons.ui.statusPendingSolid className="p-px w-4 h-4 bg-app-red text-white" />
        </div>
      )}
    </div>
  );
}
