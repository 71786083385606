import clsx from 'clsx';
import React, { useMemo } from 'react';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';

import {
  DashboardPurchasesExplosOverPeriod,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import {
  dataSorter,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  DashboardPurchasesExplosOverPeriodWithLabel,
  PurchasesExplosOverPeriodTotal,
} from './DashboardReportPageGlobalActivityPurchasesExplosOverPeriod';
import { DashboardReportPageGlobalActivityPurchasesExplosOverPeriodTableRow } from './DashboardReportPageGlobalActivityPurchasesExplosOverPeriodTableRow';

export const DashboardReportPageGlobalActivityPurchasesExplosOverPeriodTable =
  ({
    purchasesExplosOverPeriod: results,
    total,
    comparisonDetails,
    className,
  }: {
    purchasesExplosOverPeriod: ProStatsFetchResults['purchasesExplosOverPeriod'];
    total: PurchasesExplosOverPeriodTotal;
    comparisonDetails: DashboardReportComparisonDetails<any>;
    className?: string;
  }) => {
    const mainCurrency = useAppCurrencyMain();

    const data = results?.data;

    const clubResume = useClubResume();
    const publicSettings = clubResume.clubSettings.publicSettings;

    const {
      localState,
      comparisonFetchResult,
      showPeriodRangeComparison,
      // sharedState: [sharedState, setSharedState],
    } = comparisonDetails;

    const comparisonData =
      comparisonFetchResult?.purchasesExplosOverPeriod?.data;

    const clubSettings = useClubSettings();

    const trainingsDetails = useMemo(() => {
      const baseExplos = data ?? [];
      const comparisonExplos = comparisonData ?? [];

      const missingExplos = comparisonExplos.filter(
        (itemB) =>
          !baseExplos.some(
            (itemA) =>
              // itemA.diveExploReference === itemB.diveExploReference &&
              itemA.productPackageOffer?.reference ===
              itemB.productPackageOffer?.reference,
          ),
      );

      const updatedExplos: DashboardPurchasesExplosOverPeriodWithLabel[] = [
        ...baseExplos,
        ...missingExplos.map((missingExplo) => {
          const x: DashboardPurchasesExplosOverPeriod = {
            ...missingExplo,
            purchasePackageCount: 0,
            purchasePackagePriceSum: 0,
            purchasePackagePriceAvg: 0,
            purchasePackagePayedAmount: 0,
            purchasePackageTotalPriceThirdPartyCollect: 0,
          };
          return x;
        }),
      ].map((explo) => ({
        ...explo,
        label: productPackageFormatter.formatNameString(
          explo?.productPackageOffer?.productPackage,
          {
            publicSettings,
            options: {
              showResidentType: true,
              showDepthInBilling: true,
            },
            diveModesConf: clubSettings?.ui?.diveMode,
          },
        ),
      }));

      return dataSorter.sortMultiple(updatedExplos, {
        getSortAttributes: (explo) => {
          // const trainingIndex = DIVE_TRAININGS.map(x=>x.reference).indexOf(training.trainingReference)
          return [
            {
              value: explo.productPackageOffer?.price,
              asc: true,
            },
            {
              value:
                explo.productPackageOffer?.productPackage?.diveAttributes
                  ?.divesCount,
              asc: true,
            },
            {
              value: explo.productPackageOffer?.reference,
            },
            {
              value: explo.label,
            },
            {
              value: explo.purchasePackageCount,
              asc: false,
            },
          ];
        },
      });
    }, [clubSettings?.ui?.diveMode, comparisonData, data, publicSettings]);

    return !trainingsDetails?.length ? (
      <div>NO DATA</div>
    ) : (
      <table className={clsx('app-table text-gray-600', className)}>
        <thead>
          <tr className="uppercase">
            <th className="text-left text-base 2xl:text-lg px-2 leading-3">
              Forfait
            </th>
            <th className="text-center text-base 2xl:text-lg px-2">Qté</th>
            <th
              className={clsx(
                'text-center text-base px-2 leading-3 truncate',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              Prix moyen
            </th>
            <th className="text-center text-base 2xl:text-lg px-2">
              {/* <span className="hidden lg:inline">Montant </span> */}
              facturé
            </th>
            <th
              className={clsx(
                'text-center text-base 2xl:text-lg px-2',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              {/* <span className="hidden lg:inline">Montant </span> */}
              encaissé
            </th>
          </tr>
        </thead>
        <tbody>
          {trainingsDetails.map((explo) => (
            <DashboardReportPageGlobalActivityPurchasesExplosOverPeriodTableRow
              key={[explo.productPackageOffer?.reference].join('-')}
              explo={explo}
              comparisonDetails={comparisonDetails}
            />
          ))}
        </tbody>
        <tbody>
          <tr className="text-left">
            <th
              className={clsx(
                'text-base 2xl:text-lg bg-gray-500 text-white text-left whitespace-nowrap px-2',
              )}
            >
              TOTAL
            </th>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackageCount === 0 ? 'opacity-30' : '',
              )}
            >
              {total.purchasePackageCount}{' '}
              <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
            </td>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackagePriceAvg === 0 ? 'opacity-30' : '',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              <AppPriceLabel
                amount={total.purchasePackagePriceAvg}
                mainCurrency={mainCurrency}
                signColorClassName="text-white"
              />
            </td>{' '}
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackagePriceSum === 0 ? 'opacity-30' : '',
              )}
            >
              <AppPriceLabel
                amount={total.purchasePackagePriceSum}
                mainCurrency={mainCurrency}
                signColorClassName="text-white"
              />
            </td>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackagePayedAmount === 0 ? 'opacity-30' : '',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              <AppPriceLabel
                amount={total.purchasePackagePayedAmount}
                mainCurrency={mainCurrency}
                signColorClassName="text-white"
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
