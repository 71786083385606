import { search } from '@mabadive/app-common-services';
import { Box } from '@material-ui/core';
import React from 'react';


export function appSingleAutocompleteChunkify(content: string, searchText: string): JSX.Element[] {
  const chunks = search.contentChunks(content, {
    searchText,
  });
  return chunks.map((chunk, i) => <Box component={chunk.match ? 'b' : 'span'} key={i}>{chunk.value}</Box>);
}
