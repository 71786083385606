/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubParticipant } from '@mabadive/app-common-model';
import { participantGroupsBuilder } from '@mabadive/app-common-services';
import { DiveSessionParticipantsGroupsStats } from 'src/business/club/modules/club-dive-session/model';

export const diveSessionVirtualGroupsStatsBuilder = {
  buildVirtualGroupsStats,
  countAutonomousGroups,
  countSupervisedGroups,
  countTrainingGroups,
};

function buildVirtualGroupsStats({
  clubReference,
  activeDivers,
  autonomousCount,
  assignedFreeInstructorsCount,
}: {
  clubReference: string;
  activeDivers: Pick<
    ClubParticipant,
    'diveMode' | 'targetDeep' | 'trainingReference' | 'certificationReference'
  >[];
  autonomousCount: number;
  assignedFreeInstructorsCount: number;
}): DiveSessionParticipantsGroupsStats {
  const groupsStats: DiveSessionParticipantsGroupsStats = {
    type: 'virtual',
    firstDive: countFirstDiveGroups(activeDivers, { clubReference }),
    training: countTrainingGroups(activeDivers, { clubReference }),
    supervised: countSupervisedGroups(activeDivers, { clubReference }),
    supervisedAll: 0, // supervised + firstDive + training
    ...countAutonomousGroups({ autonomousCount }),
    assignedFreeInstructors: assignedFreeInstructorsCount,
  };

  groupsStats.supervisedAll =
    groupsStats.supervised +
    groupsStats.training +
    groupsStats.assignedFreeInstructors; // do not count firstDive here

  return groupsStats;
}

function countAutonomousGroups({
  autonomousCount,
}: {
  autonomousCount: number;
}) {
  if (autonomousCount === 1) {
    return {
      autonomous: 1,
      autonomousWarning: true,
    };
  } else {
    return {
      autonomous: Math.floor(autonomousCount / 2),
      autonomousWarning: false,
    };
  }
}

function countFirstDiveGroups(
  activeDivers: Pick<
    ClubParticipant,
    'diveMode' | 'targetDeep' | 'trainingReference' | 'certificationReference'
  >[],
  { clubReference }: { clubReference: string },
) {
  const firstDiveParticipants = activeDivers.filter(
    (p) => p.diveMode === 'first-dive',
  );

  const firstDiveGroups = participantGroupsBuilder.autoGroupParticipants(
    firstDiveParticipants,
    { clubReference },
  );

  // reste à faire les comptes: groupage par 4
  const firstDiveGroupsCount = firstDiveGroups.reduce((groupsCount, group) => {
    return groupsCount + Math.ceil(group.participants.length / 4);
  }, 0);

  return firstDiveGroupsCount;
}

function countSupervisedGroups(
  activeDivers: Pick<
    ClubParticipant,
    'diveMode' | 'targetDeep' | 'trainingReference' | 'certificationReference'
  >[],
  { clubReference }: { clubReference: string },
) {
  const supervisedParticipants = activeDivers.filter(
    (p) => p.diveMode === 'supervised',
  );

  const supervisedGroups = participantGroupsBuilder.autoGroupParticipants(
    supervisedParticipants,
    { clubReference },
  );

  // reste à faire les comptes:
  const supervisedGroupsCount = supervisedGroups.reduce(
    (groupsCount, group) => {
      return groupsCount + Math.ceil(group.participants.length / 4);
    },
    0,
  );

  return supervisedGroupsCount;
}

function countTrainingGroups(
  activeDivers: Pick<
    ClubParticipant,
    'diveMode' | 'targetDeep' | 'trainingReference' | 'certificationReference'
  >[],
  { clubReference }: { clubReference: string },
) {
  const trainingParticipants = activeDivers.filter(
    (p) => p.diveMode === 'training',
  );

  const trainingGroups = participantGroupsBuilder.autoGroupParticipants(
    trainingParticipants,
    { clubReference },
  );

  // reste à faire les comptes:
  const trainingGroupsCount = trainingGroups.reduce((groupsCount, group) => {
    return groupsCount + Math.ceil(group.participants.length / 4);
  }, 0);

  return trainingGroupsCount;
}
