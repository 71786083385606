/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  AppDocEditDialogActionPersistProps,
  useAppDocEditDialogActionPersist,
} from 'src/business/club/modules/app-doc';
import {
  BookingCreditNoteEditDialogActionPersistProps,
  useBookingCreditNoteEditDialogActionPersist,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingCreditNoteEditDialog';
import {
  BookingDepositEditDialogActionPersistProps,
  useBookingDepositEditDialogActionPersist,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingDepositEditDialog';
import {
  DiveSessionEditorMassiveDialogActionPersistProps,
  useDiveSessionEditorMassiveDialogActionPersist,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorMassiveDialog';
import {
  CreateMessageToCustomersDialogActionPersistProps,
  useCreateMessageToCustomersDialogActionPersist,
} from 'src/business/club/modules/club-messaging/components/CreateMessageToCustomersDialog/useCreateMessageToCustomersDialogActionPersist.hook';
import { useStaffMemberEditDialogActionPersist } from 'src/pages/SE-settings/SE-01_dive-center/staff-members/StaffMemberEditDialog';
import { useDiveCenterEditDialogActionPersist } from 'src/pages/SE-settings/SE-02_account/ClubSettingsAccountCompanyInfoPage/DiveCenterEditDialog';
import { useClubSettingsProductOffersDialogActionPersist } from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import { useClubSettingsServicesDialogActionPersist } from 'src/pages/SE-settings/SE-02_account/ClubSettingsServices/dialog';
import { useClubSettingsCommunicationDialogActionPersist } from 'src/pages/SE-settings/SE-03-customers/communication/dialog';
import {
  AppCompanyMessageTemplateEditDialogActionPersistProps,
  useAppCompanyMessageTemplateEditDialogActionPersist,
} from 'src/pages/SE-settings/SE-03-customers/communication/tabs/02.message-templates/AppCompanyMessageTemplateEditDialog';
import { useCustomerSpaceConfigDialogActionPersist } from 'src/pages/SE-settings/SE-03-customers/customer-space/CustomerSpaceConfigDialog';
import { useClubSettingsGeneralDialogActionPersist } from 'src/pages/SE-settings/SE-04_other-settings/ClubSettingsGeneral/dialog';
import {
  DailyExportConfigDialogActionPersistProps,
  useDailyExportConfigDialogActionPersist,
} from 'src/pages/SE-settings/SE-07_exports';
import { useClubSettingsOnlineBookingDialogActionPersist } from '../../SE-settings/SE-03-customers/online-booking/ClubSettingsOnlineBookingConfigPage/dialog';
import { UseClubDialogsProps } from './model/UseClubDialogsProps.type';

export type ClubDialogsActionsPersistProps = {
  bookingCreditNoteEdit?: BookingCreditNoteEditDialogActionPersistProps;
  bookingDepositEdit?: BookingDepositEditDialogActionPersistProps;
  diveSessionEditorMassive?: DiveSessionEditorMassiveDialogActionPersistProps;
  dailyExportConfig?: DailyExportConfigDialogActionPersistProps;
  appCompanyMessageTemplateEdit?: AppCompanyMessageTemplateEditDialogActionPersistProps;
  appDocEdit?: AppDocEditDialogActionPersistProps;
  createMessageToCustomers?: CreateMessageToCustomersDialogActionPersistProps;
};

export function useClubDialogsActionsPersist(
  props?: ClubDialogsActionsPersistProps,
): UseClubDialogsProps {
  const diveCenterEdit = useDiveCenterEditDialogActionPersist();
  const customerSpaceConfig = useCustomerSpaceConfigDialogActionPersist();
  const bookingCreditNoteEdit = useBookingCreditNoteEditDialogActionPersist(
    props?.bookingCreditNoteEdit,
  );
  const appCompanyMessageTemplateEdit =
    useAppCompanyMessageTemplateEditDialogActionPersist(
      props?.appCompanyMessageTemplateEdit,
    );
  const appDocEdit = useAppDocEditDialogActionPersist(props?.appDocEdit);

  const createMessageToCustomers =
    useCreateMessageToCustomersDialogActionPersist(
      props?.createMessageToCustomers,
    );

  const communicationConfigDialog =
    useClubSettingsCommunicationDialogActionPersist();

  const onlineBookingConfigDialog =
    useClubSettingsOnlineBookingDialogActionPersist();
  const clubSettingsGeneral = useClubSettingsGeneralDialogActionPersist();
  const clubSettingsServices = useClubSettingsServicesDialogActionPersist();
  const clubSettingsProductOffers =
    useClubSettingsProductOffersDialogActionPersist();
  const staffMemberEdit = useStaffMemberEditDialogActionPersist();
  const bookingDepositEdit = useBookingDepositEditDialogActionPersist(
    props?.bookingDepositEdit,
  );

  const diveSessionEditorMassive =
    useDiveSessionEditorMassiveDialogActionPersist(
      props?.diveSessionEditorMassive,
    );
  const dailyExportConfig = useDailyExportConfigDialogActionPersist(
    props?.dailyExportConfig,
  );

  const actions: UseClubDialogsProps = {
    diveCenterEdit,
    customerSpaceConfig,
    communicationConfigDialog,
    onlineBookingConfigDialog,
    clubSettingsGeneral,
    clubSettingsServices,
    clubSettingsProductOffers,
    staffMemberEdit,
    bookingDepositEdit,
    diveSessionEditorMassive,
    dailyExportConfig,
    bookingCreditNoteEdit,
    appCompanyMessageTemplateEdit,
    appDocEdit,
    createMessageToCustomers,
  };
  return actions;
}
