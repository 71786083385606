/* eslint-disable @typescript-eslint/no-unused-vars */
import { numberFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';

export const ClubSettingsGeneralPurchaseViewPanelOptions = () => {
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const mainCurrency = useAppCurrencyMain();
  const billing = clubSettings.general.billing;

  return (
    <ClubSettingsSection title="Options">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
        <AppFeatureBadge
          enabled={clubSettings.general.billing?.showResidentType}
          label="Afficher le statut de résident ou vacancier sur l'onglet de facturation"
        />
        <AppFeatureBadge
          hideIfDisabled={true}
          enabled={clubSettings.general.billing?.showDepth}
          label="Afficher la profondeur de plongée des encadrés sur l'onglet de facturation"
        />
        <AppFeatureBadge
          hideIfDisabled={true}
          enabled={clubSettings.general.billing?.showDistance}
          label="Afficher les distance des sites de plongées sur l'onglet de facturation"
        />
        <AppFeatureBadge
          hideIfDisabled={true}
          enabled={
            clubSettings.general.billing?.explorations?.groupsCriteria?.distance
          }
          label="Grouper les explorations par distance du site de plongée"
        />
        <AppFeatureBadge
          hideIfDisabled={true}
          enabled={
            clubSettings.general.billing?.explorations?.groupsCriteria
              ?.multiSessions
          }
          label="Grouper les explorations par plongée simple ou double-bloc"
        />
        <AppFeatureBadge
          hideIfDisabled={true}
          enabled={
            clubSettings.general.billing?.explorations?.groupsCriteria
              ?.groupAutonomousAndSupervised
          }
          label="Grouper les autonomes et encadrés"
        />
        {clubSettings.general.billing?.explorations?.pricing
          ?.session2FixedPrice > 0 && (
          <AppFeatureBadge
            enabled={true}
            label={`Tarif fixe plongée successive N°2: ${numberFormatter.toFixedIntegerString(
              clubSettings.general.billing?.explorations?.pricing
                ?.session2FixedPrice,
              mainCurrency.cents,
            )}${mainCurrency?.sign}`}
          />
        )}
        <AppFeatureBadge
          hideIfDisabled={true}
          label={`Associer à une réservation${
            clubSettings.general.billing?.booking?.enabled &&
            clubSettings.general.billing?.booking?.required
              ? ' (obligatoire)'
              : ''
          }`}
          enabled={clubSettings.general.billing?.booking?.enabled}
        />
        <AppFeatureBadge
          hideIfDisabled={true}
          label={'Facturation par une agence'}
          enabled={
            clubSettings.general.agencies?.enabled &&
            clubSettings.general.agencies?.agencyPurchase
          }
        />
        <AppFeatureBadge
          hideIfDisabled={true}
          label={'Paiement à une agence'}
          enabled={
            clubSettings.general.agencies?.enabled &&
            clubSettings.general.agencies?.agencyPayment
          }
        />
        <AppFeatureBadge
          hideIfDisabled={true}
          label={'Encaissement pour le compte d\'un tiers'}
          enabled={billing.thirdPartyCollectEnabled}
        />
      </div>
    </ClubSettingsSection>
  );
};
