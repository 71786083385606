/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppLoader } from '../../../../../business/_core/modules/layout';
import { ClubSettingsInquiryEditPagePanel } from './ClubSettingsInquiryEditPagePanel/ClubSettingsInquiryEditPagePanel';
import {
  ClubSettingsInquiryEditPageLocalState,
  useClubSettingsInquiryEditPageLocalState,
} from './useClubSettingsInquiryEditPageLocalState.hook';

export const ClubSettingsInquiryEditPage = ({
  navigationContext,
}: {
  navigationContext: {
    mode: 'create' | 'edit';
  };
}) => {
  const localState: ClubSettingsInquiryEditPageLocalState =
    useClubSettingsInquiryEditPageLocalState({ mode: navigationContext.mode });

  const { data, state, actions } = localState;

  return state.operationPending || state.fetchStatus === 'pending' ? (
    <AppLoader />
  ) : (
    <ClubSettingsInquiryEditPagePanel parentState={localState} />
  );
};
