/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppCompanyMessageSchedule,
  AppCompanyMessageScheduleTargetMessageRecipient,
  AppLanguageCode,
  languageFlagConvertor,
} from '@mabadive/app-common-model';
import {
  AppRenderMessageParts,
  arrayBuilder,
  dateService,
  nameFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIcons,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  AppMessagePreviewEmail,
  AppMessagePreviewSMS,
  AppMessagePreviewWhatsapp,
} from 'src/pages/_components';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { useAppMessageParts } from './useAppMessageParts.hook';
export const DiverBookingPageMessagingTabOutboxMessage = ({
  messageSchedule,
  globalState,
  dialogs,
  actions,
}: {
  messageSchedule: AppCompanyMessageSchedule;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const messageParts: AppRenderMessageParts = useAppMessageParts({
    messageSchedule,
  });

  const { linksButtons, target, title, body, signature } = messageParts;

  const isMultiDiversContext = globalState.aggregatedData?.divers?.length > 1;

  const recipients = useMemo(
    () =>
      messageSchedule.mainTarget?.localizedMessages
        ?.reduce(
          (acc, m) => acc.concat(m.recipients),
          [] as AppCompanyMessageScheduleTargetMessageRecipient[],
        )
        .map((x) => {
          const diver = (globalState.aggregatedData?.divers ?? []).find(
            (d) => d._id === x.diver?._id,
          );
          if (diver) {
            return diver;
          }
          return x.diver;
        }),
    [
      globalState.aggregatedData.divers,
      messageSchedule.mainTarget?.localizedMessages,
    ],
  );

  const languageCodes = useMemo(
    () =>
      arrayBuilder.filterDuplicated(
        (messageSchedule.mainTarget?.localizedMessages ?? [])?.reduce(
          (acc, m) => acc.concat(m.languageCode),
          [] as AppLanguageCode[],
        ),
      ),
    [messageSchedule.mainTarget?.localizedMessages],
  );

  const [showDetails, setShowDetails] = React.useState(false);

  return (
    <div className="overflow-hidden">
      <div
        className={clsx(
          'text-gray-800 border-l-4 py-2 pl-2 cursor-pointer',
          target === 'sms'
            ? 'border-l-blue-600'
            : target === 'whatsapp'
            ? 'border-l-green-600'
            : 'border-l-red-600',
        )}
        onClick={() => setShowDetails(!showDetails)}
      >
        <div className="flex gap-2 justify-between">
          <div>
            <div className={clsx('font-bold flex gap-2 text-sm md:text-base')}>
              <div className="flex flex-col items-center">
                {target === 'sms' ? (
                  <AppIcons.socialMedia.sms
                    className={'text-blue-600 w-6 h-6 lg:w-7 lg:h-7'}
                  />
                ) : target === 'whatsapp' ? (
                  <AppIcons.socialMedia.whatsapp
                    className={'text-green-600 w-6 h-6 lg:w-7 lg:h-7'}
                  />
                ) : (
                  <AppIcons.socialMedia.mail
                    className={'text-red-600 w-6 h-6 lg:w-7 lg:h-7'}
                  />
                )}

                {messageSchedule?.mainTarget?.targetMode === 'auto' && <>⚡</>}
              </div>
              <div>
                <div>{title}</div>
                <div className="flex gap-2 flex-wrap">
                  {(languageCodes.length > 1 || languageCodes[0] !== 'fr') && (
                    <div className="mb-2 font-medium text-xs text-app-primary">
                      {languageCodes
                        .map((x) => languageFlagConvertor.getFlag(x))
                        .join(', ')}
                    </div>
                  )}
                  {isMultiDiversContext && (
                    <div className="mb-2 font-medium text-xs text-app-primary">
                      {recipients
                        .map((r) => nameFormatter.formatFullName(r))
                        .join(', ')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-sm text-gray-600">
            <div className="font-bold">
              {dateService.formatLocal(
                messageSchedule._createdAt,
                'YYYY-MM-DD',
              )}
            </div>
            <div className="text-right">
              {dateService.formatLocal(messageSchedule._createdAt, 'HH:mm')}
            </div>
          </div>
        </div>
        <div className="ml-8 text-gray-600">
          {showDetails ? (
            <>
              {target === 'email' ? (
                <AppMessagePreviewEmail body={body} signature={signature} />
              ) : target === 'whatsapp' ? (
                <AppMessagePreviewWhatsapp body={body} signature={signature} />
              ) : (
                <AppMessagePreviewSMS body={body} signature={signature} />
              )}
            </>
          ) : (
            <div className="truncate">{body}</div>
          )}
          {showDetails && (
            <div className="mt-4 mb-2 flex justify-between gap-4 flex-wrap">
              <AppButton
                color="danger-bg"
                icon={AppHeroIcons.actionRemove}
                onClick={async () =>
                  actions.deleteMessageSchedule(messageSchedule)
                }
              >
                Supprimer
              </AppButton>
              {linksButtons?.length > 0 && (
                <div
                  className="flex justify-end gap-4 flex-wrap"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {linksButtons.map((b) => (
                    <AppButton
                      fullWidth={false}
                      color="primary-outline-light"
                      icon={AppHeroIcons.linkExternal}
                      href={b.url}
                      target="mabadive_diver_app"
                    >
                      {b.label}
                    </AppButton>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>

        {/* <AppMessagePreview
        target={messageSchedule.mainTarget?.target}
        title={messageSchedule.mainTarget?.originalMessage?.title}
        body={messageSchedule.mainTarget?.originalMessage?.body}
        signature={messageSchedule.mainTarget?.originalMessage?.signature}
        paramsToReplace={paramsToReplace}
        showHeaderBar={true}
      /> */}
      </div>
    </div>
  );
};
