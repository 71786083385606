import React from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppToogleButtonGroup } from 'src/lib/form';
import { AppTitleDateNavigatorPeriod } from '../model';

export const AppTitleDateNavigatorPeriodSelector = ({
  value,
  onChanges,
  items,
}: {
  value: AppTitleDateNavigatorPeriod;
  onChanges?: (value: AppTitleDateNavigatorPeriod) => void;
  items: ValueLabel<AppTitleDateNavigatorPeriod>[];
}) => {
  return (
    <AppToogleButtonGroup
      selected={value}
      onChanges={(period) => {
        onChanges(period);
      }}
      items={items}
      color={'blue'}
      theme={'strong'}
    />
  );
};
