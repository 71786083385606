import clsx from 'clsx';
import React from 'react';
import {
  DiveModeIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';

import {
  FIRST_DIVES_REFERENCE_MAP,
  OrgDataFirstDiveExt,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import { diveServiceFirstDiveFormatter } from '@mabadive/app-common-services';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { useClubSettings } from '../../../../../../../business/club/data/hooks';
import { DashboardReportPageFirstDiveParticipantsComparisonState } from './DashboardReportPageFirstDiveParticipants';

export const DashboardReportPageFirstDiveParticipantsTableRow = ({
  firstDive,
  comparisonDetails,
  className,
}: {
  firstDive: ProStatsFetchResults['firstDiveParticipantsOverPeriod']['data']['byFirstDiveReference'][0];
  comparisonDetails: DashboardReportComparisonDetails<DashboardReportPageFirstDiveParticipantsComparisonState>;
  className?: string;
}) => {
  const { localState, showPeriodRangeComparison } = comparisonDetails;
  const publicSettings = useClubSettings()?.publicSettings;

  const selectedFirstDive = localState.state.uiConfig.selection?.firstDive;
  const hoverFirstDive = localState.state.uiConfig.hover?.firstDive;

  const isSelected =
    selectedFirstDive?.firstDiveReference === firstDive.firstDiveReference;
  const isHover =
    hoverFirstDive?.firstDiveReference === firstDive.firstDiveReference;

  const diveFirstDive: OrgDataFirstDiveExt =
    FIRST_DIVES_REFERENCE_MAP[firstDive.firstDiveReference];

  const mainCurrency = useAppCurrencyMain();

  return (
    <tr
      className={clsx(
        'overflow-hidden',
        isSelected ? 'app-tr-selected' : isHover && 'app-tr-highlighted',
        className,
      )}
      onMouseEnter={() =>
        localState.state.setHover({
          firstDive: {
            firstDiveReference: firstDive.firstDiveReference,
          },
        })
      }
      onMouseLeave={() => localState.state.setHover()}
      onClick={() =>
        localState.state.setSelection({
          firstDive: {
            firstDiveReference: firstDive.firstDiveReference,
          },
        })
      }
    >
      <th
        className={clsx(
          'text-left whitespace-nowrap px-2 w-[50%]',
          firstDive.diversCount === 0 ? 'text-gray-400' : '',
        )}
      >
        <div className="flex flex-wrap items-end gap-x-1 xl:gap-x-1.5 text-xs md:text-sm pt-1">
          <span className="md:min-w-[40px] font-bold text-gray-800">
            {diveServiceFirstDiveFormatter.format(
              firstDive.firstDiveReference,
              {
                format: 'ref',
                publicSettings,
              },
            )}
          </span>
          <span
            className={clsx(
              'text-xs font-medium truncate',
              showPeriodRangeComparison
                ? 'hidden xl:inline'
                : 'hidden md:inline',
            )}
          >
            {diveServiceFirstDiveFormatter.format(
              firstDive.firstDiveReference,
              {
                format: 'name',
                publicSettings,
              },
            )}
          </span>
        </div>
      </th>
      <td
        className={clsx(
          'text-app-primary text-right text-lg px-4 whitespace-nowrap font-bold',
          firstDive.diversCount === 0 ? 'opacity-30' : '',
        )}
      >
        {firstDive.diversCount}{' '}
        <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
      </td>
      <td
        className={clsx(
          'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
          firstDive.participantsCount === 0 ? 'opacity-30' : '',
        )}
      >
        <span
          className={clsx(
            firstDive.participantsCountPurchased === firstDive.participantsCount
              ? 'font-bold'
              : 'font-normal',
          )}
        >
          {firstDive.participantsCountPurchased}
        </span>{' '}
        / {firstDive.participantsCount}{' '}
        <DiveModeIcons.firstDive className={clsx('inline w-6 h-6')} />
      </td>
      <td
        className={clsx(
          'text-right text-lg px-4 text-gray-500 font-normal whitespace-nowrap',
          showPeriodRangeComparison
            ? 'hidden xl:table-cell'
            : 'hidden lg:table-cell',
        )}
      >
        {firstDive.diversCount !== 0
          ? (
              Math.round(
                (10 * firstDive.participantsCount) / firstDive.diversCount,
              ) / 10
            ).toFixed(1)
          : null}
      </td>
      <td
        className={clsx(
          'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
          firstDive.participantsCount === 0 ? 'opacity-30' : '',
        )}
      >
        <AppPriceLabel
          amount={firstDive.priceAvg}
          mainCurrency={mainCurrency}
        />
      </td>
    </tr>
  );
};
