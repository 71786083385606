/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubSettings } from 'src/business/club/data/hooks';
import { AppInputBooleanSwitchRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { AppMessagePreview } from 'src/pages/_components';
import { useAppMessageParamsFake } from 'src/pages/_components/_core/message/useAppMessageParamsFake.hook';
import {
  AppCompanyMessageTemplateEditDialogInitialState,
  AppCompanyMessageTemplateEditFormModel,
} from '../model';

export const AppCompanyMessageTemplateEditFormEmail = ({
  form,
  initialState,
  className,
}: {
  form: UseFormReturn<AppCompanyMessageTemplateEditFormModel>;
  initialState: AppCompanyMessageTemplateEditDialogInitialState;
  className?: string;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubSettings = useClubSettings();

  const [enabled, appendSignature, title, body, context, signatureContent] =
    useWatch({
      control,
      name: [
        'messageTemplate.emailContent.enabled',
        'messageTemplate.emailContent.appendSignature',
        'messageTemplate.emailContent.message.title',
        'messageTemplate.emailContent.message.body',
        'messageTemplate.context',
        'clubSettings.communication.templates.emailSignature.content',
      ],
    });

  const paramsToReplace = useAppMessageParamsFake({
    context,
    recipientType: 'personal',
  });

  const signature = appendSignature ? signatureContent : undefined;

  return (
    <ClubSettingsSection title="E-MAIL">
      <AppFormControlRHF_Deprecated
        control={control}
        label={'Activé'}
        name="messageTemplate.emailContent.enabled"
        renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
      />

      {enabled && (
        <div className="grid lg:grid-cols-2 gap-4 items-start">
          <div className="grid gap-2">
            <AppFormControlRHF_Deprecated
              control={control}
              label={'Titre du message'}
              name="messageTemplate.emailContent.message.title"
              renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label={'Message'}
              name="messageTemplate.emailContent.message.body"
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label={'Insérer la signature'}
              name="messageTemplate.emailContent.appendSignature"
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
            {appendSignature && (
              <AppFormControlRHF_Deprecated
                className="w-full"
                label="Signature (partagée entre tous les E-MAIL)"
                control={control}
                name={
                  'clubSettings.communication.templates.emailSignature.content'
                }
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    fullWidth
                    multiline
                    rowsMin={3}
                    rowsMax={15}
                    rows={2}
                  />
                )}
              />
            )}
          </div>
          <AppMessagePreview
            target="email"
            title={title}
            body={body}
            signature={signature}
            paramsToReplace={paramsToReplace}
            showHeaderBar={true}
          />
        </div>
      )}
    </ClubSettingsSection>
  );
};
