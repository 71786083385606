import { PurchaseResume } from '@mabadive/app-common-model';
import { MQueryDescription } from 'src/_common-browser';
import { PurchaseResumeCollectionCriteria } from '../../model';
import { bookingDepositResumeAttributesBuilder } from './purchase';

export function buildBookingDepositResumeCollectionGraphqlQuery({
  clubReference,
  diversIds,
  status: { purchasePackages },
  maxResults = 100,
  returnName,
}: PurchaseResumeCollectionCriteria) {
  const purchasePackagesFilters: string[] = [];
  purchasePackagesFilters.push(`clubReference: {_eq: "${clubReference}"}`);
  if (purchasePackages === 'only-active') {
    purchasePackagesFilters.push(
      '_or: [{validityStatus: {_eq: "active"}}, {purchasePaymentStatus: {_neq: "done"}}]',
    );
  }
  if (diversIds) {
    const diversIdsString = diversIds.map((x) => `"${x}"`).join(',');
    purchasePackagesFilters.push(`diverId: {_in: [${diversIdsString}]}`);
  }

  const where = `{${purchasePackagesFilters.join(',')}}`;

  const returnAttributes =
    bookingDepositResumeAttributesBuilder.buildAttributes();

  const queryDescription: MQueryDescription<PurchaseResume> = {
    returnName: returnName ?? 'bookingDepositsResumes',
    queryName: 'club_commerce_booking_deposit',
    returnType: 'all',
    where,
    orderBy: '{depositDate: desc}',
    returnAttributes,
    limit: maxResults,
  };

  return queryDescription;
}
