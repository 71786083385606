/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { Control, UseFormReturn, useWatch } from 'react-hook-form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import {
  CLUB_DEFAULT_UI_DIVE_MODE,
  DiveMode,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { AppButton } from '../../../../../../../business/_core/modules/layout';
import { AppIcons } from '../../../../../../../business/_core/modules/layout/icons';
import { AppInputColorRHF, AppInputRHF } from '../../../../../../../lib/form';
import { ClubSettingsServicesFormModel } from '../../../_model';

function ClubSettingsServicesActivitiesFormColors_DiveModeSection({
  control,
  diveMode,
}: {
  control: Control<ClubSettingsServicesFormModel, any>;
  diveMode: DiveMode;
}) {
  const label = diveModeFormatter.formatDiveMode(diveMode, {
    format: 'long-label',
    // diveModesConf: clubSettings?.ui?.diveMode, NOTE: ici on ne surcharge pas le label, on garde celui par défaut
  });
  const colorName = `clubSettings.ui.diveMode.${diveMode}.color` as const;
  const refName = `clubSettings.ui.diveMode.${diveMode}.ref` as const;
  const labelShortName =
    `clubSettings.ui.diveMode.${diveMode}.labelShort` as const;
  const labelLongName =
    `clubSettings.ui.diveMode.${diveMode}.labelLong` as const;

  return (
    <div className="flex gap-4">
      <AppFormControlRHF
        className="w-40"
        control={control}
        label={label}
        name={colorName}
        required={true}
        renderComponent={(props) => <AppInputColorRHF {...props} />}
      />
      <AppFormControlRHF
        className="w-20"
        control={control}
        label="Diminutif"
        name={refName}
        validation={{
          rules: {
            maxLength: 3,
          },
        }}
        required={true}
        renderComponent={(props) => <AppInputRHF {...props} />}
      />{' '}
      <AppFormControlRHF
        className="w-40"
        control={control}
        label="Nom court"
        name={labelShortName}
        validation={{
          rules: {
            maxLength: 20,
          },
        }}
        required={true}
        renderComponent={(props) => <AppInputRHF {...props} />}
      />{' '}
      <AppFormControlRHF
        className="w-60"
        control={control}
        label="Nom complet"
        name={labelLongName}
        validation={{
          rules: {
            maxLength: 30,
          },
        }}
        required={true}
        renderComponent={(props) => <AppInputRHF {...props} />}
      />
    </div>
  );
}

export const ClubSettingsServicesActivitiesFormColors = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [
    firstDiveEnabled,
    supervisedEnabled,
    autonomousEnabled,
    instructorEnabled,
    autoSupervisedEnabled,
    trainingEnabled,
    theoreticalTrainingEnabled,
    observerEnabled,
    snorkelingEnabled,
    snorkelingSupervisedEnabled,
    watchingTourEnabled,
    freeDiveEnabled,
  ] = useWatch({
    control,
    name: [
      'clubSettings.firstDive.enabled',
      'clubSettings.supervised.enabled',
      'clubSettings.autonomous.enabled',
      'clubSettings.instructor.enabled',
      'clubSettings.autoSupervised.enabled',
      'clubSettings.training.enabled',
      'clubSettings.theoreticalTraining.enabled',
      'clubSettings.observer.enabled',
      'clubSettings.snorkeling.enabled',
      'clubSettings.snorkelingSupervised.enabled',
      'clubSettings.watchingTour.enabled',
      'clubSettings.freeDive.enabled',
    ],
  });

  const resetCustomization = useCallback(() => {
    setValue('clubSettings.ui.diveMode', CLUB_DEFAULT_UI_DIVE_MODE);
  }, [setValue]);

  return (
    <ClubSettingsSection title="Personnalisation des couleurs et libellés">
      <div className="grid 2xl:grid-cols-2 gap-4">
        {firstDiveEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="first-dive"
          />
        )}

        {supervisedEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="supervised"
          />
        )}
        {autonomousEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="autonomous"
          />
        )}
        {instructorEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="instructor"
          />
        )}
        {autoSupervisedEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="autoSupervised"
          />
        )}
        {trainingEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="training"
          />
        )}
        {theoreticalTrainingEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="theoretical-training"
          />
        )}
        {observerEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="observer"
          />
        )}
        {snorkelingEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="snorkeling"
          />
        )}
        {snorkelingSupervisedEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="snorkelingSupervised"
          />
        )}
        {watchingTourEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="watchingTour"
          />
        )}
        {freeDiveEnabled && (
          <ClubSettingsServicesActivitiesFormColors_DiveModeSection
            control={control}
            diveMode="free-dive"
          />
        )}
      </div>
      <AppButton
        size="normal"
        icon={AppIcons.action.cancel}
        tabIndex={500}
        color="gray-outline-light"
        onClick={() => {
          resetCustomization();
        }}
      >
        Ré-initialiser les paramètres par défaut
      </AppButton>
    </ClubSettingsSection>
  );
};
