/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubResumeBoat } from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';

import React, { useMemo } from 'react';
import { ClubBoatsTableRow } from './ClubBoatsTableRow';

export const ClubBoatsTable = ({
  clubBoats,
  onClickClubBoat,
}: {
  clubBoats: ClubResumeBoat[];
  onClickClubBoat?: (clubBoatId: string) => void;
}) => {
  const orderedClubBoats = useMemo(
    () =>
      dataSorter.sortByAttributes(
        clubBoats,
        [
          {
            name: 'name',
          },
          {
            name: 'immatriculation',
          },
          {
            name: 'model',
          },
          {
            name: '_id',
          },
        ],
        {
          asc: true,
        },
      ),
    [clubBoats],
  );

  function clickClubBoat(clubBoatId: string) {
    if (onClickClubBoat) {
      onClickClubBoat(clubBoatId);
    }
  }

  return (
    <table className={'w-full divide-y divide-gray-200 text-gray-600'}>
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Nom
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Capacité
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Défaut
          </th>
          <th
            scope="col"
            className="hidden lg:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Commentaire
          </th>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          ></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {orderedClubBoats.map((clubBoat, i) => (
          <ClubBoatsTableRow
            key={i}
            clubBoat={clubBoat}
            onClick={() => clickClubBoat(clubBoat._id)}
          />
        ))}
      </tbody>
    </table>
  );
};
