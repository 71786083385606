/* eslint-disable @typescript-eslint/no-unused-vars */
import { appUrlBuilderCustomerSpace } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { useClubSettings } from 'src/business/club/data/hooks';
import { AppHeroIcons } from '../../../../../../../../business/_core/modules/layout/icons';
import { appWebConfig } from '../../../../../../../../business/_core/modules/root/config';
import { appClipboardCopier } from '../../../../../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingCustomerConfigEditDialog/_services';

export const EcommerceCustomerVisibilityConfigPanel = ({
  className,
}: {
  className?: string;
}) => {
  const clubSettings = useClubSettings();
  const clubPublicReference = clubSettings?.clubPublicReference;

  const customerSpaceOnlineBookingRootPageURL = useMemo(() => {
    return appUrlBuilderCustomerSpace.buildCustomerOnlineBookingURL({
      diverWebUrl: appWebConfig.applications.diverWebUrl,
      clubPublicReference,
    });
  }, [clubPublicReference]);
  const customerSpaceOnlineBookingRootPageIframeURL = useMemo(() => {
    return appUrlBuilderCustomerSpace.buildCustomerOnlineBookingURL({
      diverWebUrl: appWebConfig.applications.diverWebUrl,
      clubPublicReference,
      context: 'iframe',
    });
  }, [clubPublicReference]);

  const iframeCode = useMemo(() => {
    return `<style>
  #mabadive-order-iframe-container {width: 100% !important; max-width: 100% !important; min-height: 400px; height: 60vh !important; position: relative; overflow: hidden; padding-top: 0;}
  #mabadive-order-iframe-container > iframe.mabadive-order-iframe {position: absolute; width: 100%; height: 100%; top: 0; left: 0; bottom: 0; right: 0;}
</style>
<div id="mabadive-order-iframe-container">
    <iframe class="mabadive-order-iframe" src="${customerSpaceOnlineBookingRootPageIframeURL}">
    </iframe>
</div>`;
  }, [customerSpaceOnlineBookingRootPageIframeURL]);

  const enableCatalog =
    clubSettings?.publicSettings?.customerSpace?.onlineBooking?.enableCatalog;

  return (
    <div className={className}>
      <div className="grid gap-2">
        {enableCatalog ? (
          <p className="text-status-success font-bold">
            Catalogue visible sur l'espace client.
          </p>
        ) : (
          <p className="text-status-error font-bold">
            Catalogue désactivé sur l'espace client.
          </p>
        )}
        <div className="grid gap-2">
          <div
            className={
              'my-2 flex gap-2 items-end justify-between sm:justify-start flex-wrap'
            }
          >
            <div className="">
              <label
                className="relative text-sm font-medium text-gray-600"
                // style={{ top: '-16px', bottom: '-16px' }}
              >
                Lien vers le catalogue sur l'espace client.
              </label>
              <div className="border border-gray-200 bg-gray-50 p-1 whitespace-nowrap overflow-x-auto">
                <a
                  className="w-full text-app-xxs sm:text-xs text-app-blue underline"
                  href={customerSpaceOnlineBookingRootPageURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {customerSpaceOnlineBookingRootPageURL}
                </a>
              </div>
            </div>
            <div className="flex gap-2">
              {navigator?.clipboard && (
                <AppButton
                  fullWidth={false}
                  color="primary-outline-light"
                  icon={AppHeroIcons.copyClipboard}
                  onClick={async () => {
                    appClipboardCopier.copyToClipboard(
                      customerSpaceOnlineBookingRootPageURL,
                    );
                  }}
                >
                  <span className="hidden sm:inline">copier</span>
                </AppButton>
              )}
              <AppButton
                fullWidth={false}
                color="primary-outline-light"
                icon={AppHeroIcons.linkExternal}
                href={customerSpaceOnlineBookingRootPageURL}
                target="mabadive_diver_app"
              >
                <span className="hidden sm:inline">ouvrir</span>
              </AppButton>
            </div>
          </div>
          <div
            className={
              'max-w-full overflow-hidden my-2 flex gap-2 items-end justify-between sm:justify-start flex-wrap'
            }
          >
            <div className="">
              <label
                className="relative text-sm font-medium text-gray-600"
                // style={{ top: '-16px', bottom: '-16px' }}
              >
                Code pour intégrer la boutique MABADIVE dans une page de votre
                site
              </label>
            </div>
            {navigator?.clipboard && (
              <AppButton
                fullWidth={false}
                color="primary-outline-light"
                icon={AppHeroIcons.copyClipboard}
                onClick={async () => {
                  appClipboardCopier.copyToClipboard(iframeCode);
                }}
              >
                <span className="hidden sm:inline">copier</span>
              </AppButton>
            )}
            <div>
              <div>
                Sur Wordpress, ajouter un élément de contenu "Bloc de code" avec
                le code suivant :
              </div>
              <div className="border border-gray-200 bg-gray-50 p-1 whitespace-nowrap overflow-x-auto">
                <pre className="max-w-full overflow-y-auto">{iframeCode}</pre>
              </div>
              <div>
                Note: si cet élément est seul sur la page, définissez la hauteur
                de la section à "Pas de hauteur minimum"
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
