/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppInputBoolean } from 'src/lib/form';
import { ClubDiverSelectorDialogMode } from '../model';

export const ClubDiverSelectorDialogMultiDiversCheckbox = ({
  mode,
  setMode,
}: {
  mode: ClubDiverSelectorDialogMode;
  setMode: (mode: ClubDiverSelectorDialogMode) => void;
}) => {
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        setMode(mode === 'single-divers' ? 'multi-divers' : 'single-divers');
      }}
    >
      <AppInputBoolean type="checkbox" value={mode === 'multi-divers'} />
      <span
        className={`ml-1 text-app-blue ${
          mode === 'multi-divers' ? 'font-bold' : ''
        }`}
      >
        ajout de plusieurs plongeurs
      </span>
    </div>
  );
};
