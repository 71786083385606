import { BookingDeposit, ClubDiver } from '@mabadive/app-common-model';
import {
  bookingDepositStateFormatter,
  dataSorter,
  dateService,
  nameFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import {
  BookingDepositStatusColors,
  useBookingDepositStatusColors,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingDepositCard/useBookingDepositStatusColors.hook';
import { AgencyLabel } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { DiveCenterProBadge } from '../company';
import { useAppCurrency } from './useAppCurrency.hook';

export function useBookingDepositOptions({
  bookingDeposits,
  divers,
}: {
  bookingDeposits: BookingDeposit[];
  divers: ClubDiver[];
}): ValueLabel<string, React.ReactNode>[] {
  return useMemo(() => {
    const sortedBookingDeposits = dataSorter.sortMultiple(
      bookingDeposits ?? [],
      {
        getSortAttributes: (item) => [
          {
            value: item.creditRemaining,
            asc: false,
          },
          {
            value: item.depositDate,
            asc: false,
          },
          {
            value: item._createdAt,
            asc: false,
          },
        ],
      },
    );
    return sortedBookingDeposits.map((b) => {
      const option: ValueLabel<string, React.ReactNode> = {
        label: <BookingDepositOptionCard bookingDeposit={b} divers={divers} />,
        value: b._id,
      };
      return option;
    });
  }, [bookingDeposits, divers]);
}

export const BookingDepositOptionCard = ({
  bookingDeposit: bd,
  divers,
}: {
  bookingDeposit: BookingDeposit;
  divers: ClubDiver[];
}) => {
  const clubResume = useClubResume();
  const bookingAgencies = clubResume?.bookingAgencies;
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const bookingDepositDiveCenter = useMemo(() => {
    return clubResume.diveCenters.find((dc) => dc._id === bd.diveCenterId);
  }, [bd, clubResume.diveCenters]);

  const statusColors: BookingDepositStatusColors =
    useBookingDepositStatusColors(bd);

  const currency = useAppCurrency(bd.currencyIsoCode);

  const diver = useMemo(
    () => (divers ?? []).find((d) => d._id === bd.diverId),
    [bd, divers],
  );

  return (
    <div className="bg-white py-0.5 px-1 text-gray-600 flex gap-2 items-start">
      <div className="hidden md:block">
        {bd.depositState === 'draft' ||
          (bd.depositState === 'active' &&
            bd.purchasePaymentStatus !== 'done' && (
              <AppIcons.ui.statusPending
                className={`h-10 w-10 ${statusColors.textLight}`}
              />
            ))}
        {bd.depositState === 'active' && (
          <AppIcons.ui.statusAuthorized
            className={`h-10 w-10 ${statusColors.textLight}`}
          />
        )}
        {bd.depositState === 'used' && (
          <AppIcons.ui.statusValidated
            className={`h-10 w-10 ${statusColors.textLight}`}
          />
        )}
        {bd.depositState === 'archived' && (
          <AppIcons.ui.statusArchived
            className={`h-10 w-10 ${statusColors.textLight}`}
          />
        )}
      </div>
      <div className="text-sm md:text-base font-medium">
        <div className="flex gap-2 items-end">
          <AppPriceLabel
            className={statusColors.textLight}
            amount={bd.creditRemaining}
            mainCurrency={currency}
          />
          {bd.creditRemaining !== bd.creditInitial && (
            <>
              /
              <AppPriceLabel
                className={statusColors.textLight}
                amount={bd.creditInitial}
                mainCurrency={currency}
              />
            </>
          )}
        </div>

        <div className="text-gray-500 uppercase text-xs">
          <div className={statusColors.textDark}>
            {bookingDepositStateFormatter.formatDepositState(bd.depositState, {
              isPaymentPending: bd.purchasePaymentPending,
            })}
          </div>
        </div>
      </div>
      <div>
        <div className="flex gap-2 flex-nowrap items-start">
          {isMultiDiveCenters && (
            <DiveCenterProBadge
              className="my-px"
              diveCenter={bookingDepositDiveCenter}
            />
          )}
          <div>
            <div>
              {dateService.formatUTC(bd.depositDate, 'DD/MM/YYYY HH:mm')}
            </div>
            {diver ? (
              <div className="text-xs text-app-primary">
                {nameFormatter.formatFullName(diver)}
              </div>
            ) : (
              <div className="text-gray-100">{bd.diverId}</div>
            )}
          </div>
        </div>
        <div className="flex gap-2 flex-nowrap items-start">
          {bd.reference && (
            <span className="italic text-gray-400">{bd.reference}</span>
          )}
          {bd.bookingAgencyId && (
            <AgencyLabel className="text-xs" agencyId={bd.bookingAgencyId} />
          )}
        </div>
      </div>
    </div>
  );
};
