/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { useMediaSizeMaterialUI } from 'src/business/_core/modules/layout';
import { AppDateNavigationBar } from 'src/business/_core/modules/layout/components';
import { PlanningViewConfig } from '../../club-planning/_model';
import { clubPlanningUrlBuilder } from '../../url-builders';
import { SetPlanningConfigOptions } from '../hooks';

export const ClubPlanningDateNavigationBar = ({
  planningConfig,
  setPlanningConfig,
  className,
}: {
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  className?: string;
}) => {
  const mediaSize = useMediaSizeMaterialUI();
  const { focusDate } = planningConfig;

  const setFocusDate = useCallback(
    (focusDate: Date) => {
      const focusDateRef =
        clubPlanningUrlBuilder.buildFocusDateRefFromDate(focusDate);

      setPlanningConfig(
        {
          ...planningConfig,
          focusDate,
          focusDateRef,
        },
        {
          origin: '[ClubPlanningDateNavigationBar] setFocusDate',
        },
      );
    },
    [planningConfig, setPlanningConfig],
  );

  return (
    <AppDateNavigationBar
      viewPeriod={
        planningConfig?.viewPeriod === 'week'
          ? 'week'
          : planningConfig?.viewPeriod === 'month'
          ? 'month'
          : 'day'
      }
      date={planningConfig?.focusDate}
      setDate={(date) => setFocusDate(date)}
      className={className}
    />
  );
};
