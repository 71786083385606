import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export function BookingMassiveEditorSessionsTableRegisterAddSessionButton({
  onClickAddSession,
  isReverseTheme,
  className,
}: {
  onClickAddSession: () => void;
  isReverseTheme: boolean;
  className?: string;
}) {
  return (
    <Tippy
      delay={[300, 100]}
      placement="top"
      content="Ajouter une session de plongée"
    >
      <div
        className={clsx(
          'group px-1 py-1.5 ring-opacity-30 ring-gray-400 rounded-lg hover:ring-2 cursor-pointer',
          isReverseTheme
            ? 'bg-app-primary text-white hover:text-white-dark border border-white hover:bg-app-primary-dark hover:border-app-primary'
            : 'bg-white text-app-primary hover:text-app-primary-dark border border-app-primary hover:border-app-primary-dark',
          'flex flex-col justify-center items-center',
          className,
        )}
        onClick={onClickAddSession}
        // style={{
        //   height: bookingTabModel.meta.isUniqueMember ? '4.0rem' : '5.4rem',
        // }}
      >
        <div
          className={clsx(
            'inline-block w-5 h-5 rounded-xl',
            isReverseTheme
              ? 'bg-white text-app-primary group-hover:bg-gray-50 group-hover:text-app-primary-dark'
              : 'bg-app-primary text-white group-hover:bg-app-primary-dark',
          )}
        >
          <AppHeroIcons.actionAdd className="w-full h-full fill-current" />
        </div>
        <div className="mt-1 text-center font-bold text-xs">SESSION</div>
      </div>
    </Tippy>
  );
}
