import { useAppSecurityUser } from './useAppSecurityUser.hook';
import { useAppSecurityUserClubAuthorizations } from './useAppSecurityUserClubAuthorizations.hook';

export type AppBookingSessionAuth = {
  edit: boolean;
};

export function useAppBookingSessionAuth({
  bookingSessionDiveCenterId,
}: {
  bookingSessionDiveCenterId: string;
}): AppBookingSessionAuth {
  const securityUser = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();

  const isCurrentUserDiveCenter =
    securityUser?.diveCenterIdFocus === bookingSessionDiveCenterId;

  return {
    edit: az?.edit?.participant?.booking && isCurrentUserDiveCenter,
  };
}
