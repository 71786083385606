import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  AppGenericStatusIcon,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { ClubDiverCardDiver } from './ClubDiverCardAttribute';

export const ClubDiverCardAttributeMedicalCertificateStatus = ({
  diver,
  className = '',
}: {
  diver: ClubDiverCardDiver;
  className?: string;
}) => {
  const medicalCertificateDateBadgeStatus: AppGenericStatus = useMemo(() => {
    if (!diver.medicalCertificateDate) {
      if (diver.medicalCertificateChecked) {
        return 'success';
      }
      return 'warn';
    }
    if (dateService.getAge(diver.medicalCertificateDate) < 1) {
      return 'success';
    }
    return 'error';
  }, [diver.medicalCertificateChecked, diver.medicalCertificateDate]);

  const medicalCertificateDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(medicalCertificateDateBadgeStatus),
    [medicalCertificateDateBadgeStatus],
  );
  return (
    <div className={`${className} ${medicalCertificateDateBadgeStatusClass}`}>
      <div className="flex gap-1 items-center">
        <AppGenericStatusIcon
          status={medicalCertificateDateBadgeStatus}
          className={'w-4 h-4 '}
        />
        {diver.medicalCertificateDate ? (
          <div className="grow">
            {dateService.formatUTC(diver.medicalCertificateDate, 'DD/MM/YYYY')}
          </div>
        ) : diver.medicalCertificateChecked === true ? (
          <div className="text-xs">OUI</div>
        ) : null}
      </div>
    </div>
  );
};
