import { CompanyAccommodation } from '@mabadive/app-common-model';

export const accommodationFormResultBuilder = { buildResult };

function buildResult(
  formValue: CompanyAccommodation,
): Partial<CompanyAccommodation> {
  const value: Partial<CompanyAccommodation> = {
    ...formValue,
  };
  return value;
}
