import { DiveCenter } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppUiThemeTextCard } from 'src/business/_core/modules/layout';

export function DiveCenterProBadge({
  diveCenter,
  className,
}: {
  diveCenter: DiveCenter;
  className?: string;
}) {
  // const clubResume = useClubResume();
  // const brand = clubResume?.clubSettings?.publicData?.brand;
  return (
    <AppUiThemeTextCard
      border={true}
      className={clsx(
        'border text-center text-app-xxs leading-3 uppercase font-bold whitespace-nowrap truncate rounded-sm',
        className,
      )}
      theme={
        diveCenter?.publicSettings?.diveCenterUiTheme?.proName ?? {
          bgColor: '#1a7498',
          textColor: '#ffffff',
        }
      }
    >
      {diveCenter?.privateData?.proName ?? 'MULTI centres'}
    </AppUiThemeTextCard>
  );
}
