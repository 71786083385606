import { ClubPurchasePackageExtraCost } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { useAppCurrencyMain } from 'src/pages/_components/options';

export function ClubPurchasesExtraCostDescription({
  extraCosts: extraCostsInput,
  className = 'text-xs',
  centsClassName = 'text-app-xxs',
  signClassName = 'text-app-xxs',
}: {
  extraCosts: ClubPurchasePackageExtraCost[];
  className?: string;
  centsClassName?: string;
  signClassName?: string;
}) {
  const mainCurrency = useAppCurrencyMain();
  const extraCosts = useMemo(
    () => (extraCostsInput ?? []).filter((x) => !!x), // TODO: voir pourquoi certains extraCost sont "null"
    [extraCostsInput],
  );
  return extraCosts?.length > 0 ? (
    <div className="">
      <h2 className="text-xs uppercase text-gray-600 font-bold">Suppléments</h2>
      <ul className={clsx('ml-3 list-disc', className)}>
        {extraCosts.map((x, i) => (
          <li className="whitespace-normal" key={i}>
            {x.unitQuantity > 1 && `x${x.unitQuantity} `}
            {x.label} {x.comment}
            {x.unitPrice > 0 && (
              <span className="whitespace-nowrap">
                {' ('}
                <AppPriceLabel
                  amount={x.multiplier * x.unitQuantity * x.unitPrice}
                  mainCurrency={mainCurrency}
                  centsClassName={centsClassName}
                  signClassName={signClassName}
                />
                {')'}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}
