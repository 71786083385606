import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { AppRichTextEditor, AppRichTextEditorProps } from './AppRichTextEditor';

export const AppRichTextEditorRHFControl = ({
  control,
  label,
  name,
  className,
  ...otherProps
}: {
  control: Control<any>;
  label: string;
  name: string;
  className?: string;
} & Exclude<AppRichTextEditorProps, 'onChange'>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        const classNameInput = `shadow-sm ${
          error
            ? 'focus:ring-app-danger focus:border-app-danger focus-visible:ring-app-danger focus-visible:border-app-danger text-status-error'
            : 'focus:ring-primary focus:border-primary app-theme-anim-live-primary'
        } block w-full px-2 py-0.5 border border-gray-300 rounded-md`;

        return (
          <div className={`w-full ${className ?? ''}`} ref={ref}>
            {label && (
              <label className="text-gray-400 font-bold text-xs block text-left mb-1">
                {label}
              </label>
            )}
            <AppRichTextEditor
              classNameInput={classNameInput}
              // onBlur={onBlur}
              value={value}
              // name={name}
              // ref={ref}
              onChange={(value) => {
                onChange(value);
              }}
              {...otherProps}
            />
          </div>
        );
      }}
    />
  );
};
