import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import {
  AppAuth,
  AppSecurityUser,
  authenticationStore,
} from 'src/business/auth/services';
import { clubPlanningUrlBuilder } from 'src/business/club/modules/url-builders';
import { appLogger } from '../../logger';

export const appRouteBuilder = {
  getContactRoute,
  getDefaultRoute,
  getDefaultRouteFromAuth,
  getDefaultRouteFromSecurityUser,
  getResetPasswordQueryUrl,
  getLoginUrl,
  getSigningUrl,
};

function getContactRoute() {
  return '/club/contact';
}
function getResetPasswordQueryUrl() {
  return '/auth/reset/password/query';
}
function getLoginUrl() {
  return '/auth/login';
}
function getSigningUrl() {
  return '/';
}

function getDefaultRouteFromAuth({ appAuth }: { appAuth: AppAuth }): string {
  return getDefaultRouteFromSecurityUser({ securityUser: appAuth?.user });
}
function getDefaultRouteFromSecurityUser({
  securityUser,
}: {
  securityUser: AppSecurityUser;
}): string {
  if (securityUser) {
    if (securityUser.roles.includes('club-view-planning')) {
      return clubPlanningUrlBuilder.buildPlanningUrl();
    }
    if (securityUser.roles.includes('club-view-dashboard')) {
      return '/club/dashboard';
    }
    if (securityUser.roles.includes('club-view-settings')) {
      return '/club/settings';
    }
    if (securityUser.roles.includes('club-view-lists')) {
      return '/club/lists';
    }
    if (securityUser.roles.includes('club')) {
      return '/club/contact';
    }
    if (securityUser.roles.includes('super-admin')) {
      return '/admin/companies';
    }
    if (securityUser.roles.includes('account-reset-password')) {
      return '/account/choose-password';
    }
    appLogger.info(
      '[appRouteBuilder] getDefaultRouteFromSecurityUser:',
      securityUser,
    );
  }
  return '/auth/login';
}

function getDefaultRoute(): Observable<string> {
  return authenticationStore.auth.get().pipe(
    first(),
    switchMap((appAuth) => getDefaultRouteFromAuth({ appAuth })),
  );
}
