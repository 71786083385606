import { AppInquiry } from '@mabadive/app-common-model';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { useFetchInquiries } from '../_services';

export function useClubSettingsInquiriesListPageLocalState() {
  const diveCenterResume = useDiveCenterResume();

  // const diveCenterSettingsInquiry
  // diveCenterResume.clubResume?.clubSettings?.general?.inquiry;
  // TODO fetch

  const { status, fetchedData } = useFetchInquiries();

  const inquiries: AppInquiry[] = fetchedData?.inquiries ?? [];

  return {
    diveCenterResume,
    inquiries,
  };
}
