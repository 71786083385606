/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdateDescriptor,
  BookingParticipantEditorFormModel,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  BookingParticipantEditorDialogStateMode,
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResult,
} from '../../../../models';
import {
  aggregateDocs,
  bookingParticipantEditorDialogChangesBuilderCreateParticipant,
  bookingParticipantEditorDialogChangesBuilderEditDiver,
  bookingParticipantEditorDialogChangesBuilderEditParticipant,
} from './changes-builder';

export const bookingParticipantEditorDialogChangesBuilder = {
  buildChangesResult,
};
function buildChangesResult(attributes: {
  participantData?: BookingParticipantEditorParticipant;
  mode?: BookingParticipantEditorDialogStateMode;
  formValue: BookingParticipantEditorFormModel;
  initialFormValue: BookingParticipantEditorFormModel;
  hasFormChanges: boolean;
  clubReference: string;
  diveCenterId: string;
  docResumesChanges: AppEntityUpdateDescriptor<PRO_AppDocResume>[];
}): {
  hasChanges: boolean;
  result?: BookingParticipantEditorResult;
} {
  const {
    participantData,
    mode,
    formValue,
    initialFormValue,
    hasFormChanges,
    clubReference,
    diveCenterId,
    docResumesChanges,
  } = attributes;
  const { hasChanges: hasChangesInput, result } =
    buildChangesResultCore(attributes);

  const { appDocs, docResumes } = aggregateDocs({
    participantDataInitialFull: participantData,
    docResumesChanges,
  });
  let hasChanges = hasChangesInput;
  if (
    appDocs?.created?.length > 0 ||
    appDocs?.deletedIds?.length > 0 ||
    appDocs?.updated?.length > 0
  ) {
    if (!result.changes) {
      result.changes = {};
    }

    hasChanges = true;
    result.changes.appDocs = appDocs;
  }

  return { hasChanges, result };
}
function buildChangesResultCore(attributes: {
  participantData?: BookingParticipantEditorParticipant;
  mode?: BookingParticipantEditorDialogStateMode;
  formValue: BookingParticipantEditorFormModel;
  initialFormValue: BookingParticipantEditorFormModel;
  hasFormChanges: boolean;
  clubReference: string;
  diveCenterId: string;
}): {
  hasChanges: boolean;
  result?: BookingParticipantEditorResult;
} {
  const {
    participantData,
    mode,
    formValue,
    initialFormValue,
    hasFormChanges,
    clubReference,
    diveCenterId,
  } = attributes;
  if (mode === 'edit-participant') {
    return bookingParticipantEditorDialogChangesBuilderEditParticipant.buildChangesResult(
      {
        logContext: `bookingParticipantEditorDialogChangesBuilder (mode=${mode})`,
        participantData,
        formValue,
        hasFormChanges,
        clubReference,
        initialFormValue,
      },
    );
  } else if (mode === 'create-participant') {
    return bookingParticipantEditorDialogChangesBuilderCreateParticipant.buildChangesResult(
      {
        participantData,
        formValue,
        clubReference,
        diveCenterId,
        initialFormValue,
      },
    );
  } else if (mode === 'edit-diver') {
    return bookingParticipantEditorDialogChangesBuilderEditDiver.buildChangesResult(
      {
        participantData,
        formValue,
        hasFormChanges,
        initialFormValue,
      },
    );
  } else {
    appLogger.error(
      '[bookingParticipantEditorDialogChangesBuilder] Invalid mode:',
      mode,
    );
    throw new Error('Invalid mode');
  }
}
