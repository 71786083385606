/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useWatch } from 'react-hook-form';
import { AppButton, AppMessageLight } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanSwitchRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { CustomerSpaceConfigDialogLocalState } from '../useCustomerSpaceConfigDialogLocalState.hook';
import { CustomerConfigCreateCustomerAccountLink } from './CustomerConfigCreateCustomerAccountLink';

export const CustomerSpaceConfigFormTabCreateAccount = ({
  localState,
}: {
  localState: CustomerSpaceConfigDialogLocalState;
}) => {
  const { form } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const formPrefix = 'clubSettings.publicSettings.customerSpace' as const;

  const [customerSpaceEnabled, newAccountsEnabled, publicAccoutCreationLink] =
    useWatch({
      control,
      name: [
        `${formPrefix}.enabled`,
        `${formPrefix}.newAccounts.enabled`,
        'clubSettings.customer.publicAccoutCreationLink',
      ],
    });

  return (
    <div className="flex flex-wrap gap-4 lg:gap-x-8 justify-between lg:justify-start">
      {customerSpaceEnabled && (
        <AppFormControlRHF_Deprecated
          label={'Permettre au clients de créer eux-même leur compte'}
          control={control}
          name={`${formPrefix}.newAccounts.enabled`}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF {...props} label={'Activé'} />
          )}
        />
      )}
      {newAccountsEnabled && (
        <>
          {publicAccoutCreationLink ? (
            <div>
              <CustomerConfigCreateCustomerAccountLink
                publicAccoutCreationLink={publicAccoutCreationLink}
                localState={localState}
              />
            </div>
          ) : (
            <div className="flex gap-8">
              <AppButton
                type="button"
                fullWidth={true}
                className="xs:max-w-[18rem]"
                color="primary-outline-light"
                icon={AppHeroIcons.eye}
                disabled={localState.data.linkGenerationInProgress}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await localState.actions.generateNewCustomerAccountLink();
                }}
              >
                Afficher le lien d'inscription
              </AppButton>
            </div>
          )}
          <AppFormControlRHF_Deprecated
            className="my-2 w-full xs:col-span-12"
            label="Message à afficher au client:"
            control={control}
            name={`${formPrefix}.newAccounts.messageToDisplay`}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                multiline
                rowsMin={3}
                rowsMax={15}
                rows={2}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="my-2 w-full xs:col-span-12"
            label="Lien à afficher au client:"
            control={control}
            name={`${formPrefix}.newAccounts.linkToDisplay`}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        </>
      )}
      <AppMessageLight>
        <div className="grid gap-2">
          <h3 className="font-bold text-base text-app-primary">
            Lien d'inscription client
          </h3>
          <p>
            Cette fonctionnalité vous permet de communiquer à vos clients un
            lien afin de leur permettre de créer directement leur compte
            mabadive.
          </p>
          <p>
            Cela est utile par exemple pour ceux qui arrivent directement sur
            place.
          </p>
          <p>
            Pour vous faciliter la vie, vous pouvez générer un QR Code à partir
            de ce lien, et l'imprimer sur une feuille à afficher sur votre
            comptoir.
          </p>
        </div>
      </AppMessageLight>
    </div>
  );
};
