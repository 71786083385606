/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  DiveSessionSimpleCardHeader,
  DiveSessionSimpleCardTime,
} from '../../components';
import { diveSessionStyleBuilder } from '../../components/ClubPlanningLightSession/diveSessionStyleBuilder.service';
import { CalendarSessionWidthMode } from '../../components/ClubPlanningLightSession/session-common';
import { ClubPlanningPanelSessionDisplayStyle } from '../../model';

export const ClubPlanningLightPanelViewPeriodDaySessionHeader = ({
  session,
  sessionIndex,
  selectionColor,
  onClick,
  widthMode,
  className,
}: {
  session: ClubPlanningLightSessionDef;
  sessionIndex: { index: number; total: number };
  selectionColor?: ClubPlanningPanelSessionDisplayStyle;
  onClick: (session: ClubPlanningLightSessionDef) => void;
  widthMode: CalendarSessionWidthMode;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const isPast = useMemo(
    () =>
      !dateService.isTodayUTC(session.time) &&
      dateService.isPastUTC(session.time),
    [session.time],
  );

  const display = useMemo(() => {
    const d: {
      boats: boolean;
      tide: boolean;
      weather: boolean;
      comment: boolean;
      addParticipantButton1: boolean;
      addParticipantButton2: boolean;
      openSessionButton: boolean;
      truncateText: boolean;
      sessionIndex: { index: number; total: number };
      isExpandable: boolean;
    } = {
      boats: true,
      tide: false,
      weather: false,
      comment: false,
      addParticipantButton1: false,
      addParticipantButton2: false,
      openSessionButton: false,
      truncateText: true,
      isExpandable: false,
      sessionIndex,
    };
    return d;
  }, [sessionIndex]);

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );

  const sessionBorderStyle = diveSessionStyleBuilder.buildBorderStyle({
    isPast,
    isVirtual: session.isVirtual,
    timeDayPeriod,
    selectionColor,
  });

  return (
    <div
      className={`cursor-pointer ring-opacity-30 ring-day-period-${timeDayPeriod}-dark hover:ring-2 bg-white border ${sessionBorderStyle} ${
        className ?? ''
      } text-left flex flex-col`}
      onClick={() => onClick(session)}
    >
      <DiveSessionSimpleCardTime
        displaySessionIndex={display.sessionIndex}
        session={session}
        selectionColor={selectionColor}
        widthMode={widthMode}
      />
      <DiveSessionSimpleCardHeader
        className="flex-grow py-1 text-app-xxs leading-4"
        session={session}
        display={display}
        widthMode={widthMode}
      />
    </div>
  );
};
