/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { AggregatedBookingPurchasePaymentWithDetails } from '../../models';
import { DiverBookingPageActions } from './../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from './../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPagePaymentTabPaymentsResumeTable } from './DiverBookingPagePaymentTabActivePayments';
import { DiverBookingPagePaymentTabArchivedPaymentsYearly } from './DiverBookingPagePaymentTabArchivedPaymentsYearly';
import { DiverBookingPagePaymentTabLocalState } from './useDiverBookingPagePaymentTabLocalState';

export const DiverBookingPagePaymentTabArchivedPayments = ({
  globalState,
  localState,
  dialogs,
  actions,
  purchasePaymentsWithDetails,
}: {
  globalState: DiverBookingPageGlobalState;
  localState: DiverBookingPagePaymentTabLocalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  purchasePaymentsWithDetails: AggregatedBookingPurchasePaymentWithDetails[];
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const [isExpanded, setIsExpanded] = useState(false);

  const inactiveParticipantPurchasePaymentsByYear = useMemo(() => {
    const map = new Map<
      number,
      AggregatedBookingPurchasePaymentWithDetails[]
    >();
    purchasePaymentsWithDetails.forEach((purchasepayment) => {
      const year = new Date(
        purchasepayment.purchasePayment?.paymentDate ??
          purchasepayment.purchasePayment?.creationDate,
      ).getFullYear();
      const purchasePayment = map.get(year) || [];
      purchasePayment.push(purchasepayment);
      map.set(year, purchasePayment);
    });
    return map;
  }, [purchasePaymentsWithDetails]);
  const inactiveParticipantPurchasePaymentsByYearDesc = new Map(
    [...inactiveParticipantPurchasePaymentsByYear.entries()].sort(
      (a, b) => b[0] - a[0],
    ),
  );

  // test if only one year, and if this year

  const onlyThisYear = useMemo(() => {
    const keysArray = Array.from(
      inactiveParticipantPurchasePaymentsByYear.keys(),
    );
    if (keysArray.length === 1) {
      const isThisYear =
        keysArray.length === 1 && keysArray[0] === new Date().getFullYear();
      return isThisYear;
    }
    return false;
  }, [inactiveParticipantPurchasePaymentsByYear]);

  return (
    <div className="mt-8 mb-5 block">
      <h2 className="my-2 text-base sm:text-lg font-medium text-gray-800 leading-6 pt-0 mt-0 flex items-center">
        Paiements archivés ({purchasePaymentsWithDetails.length})
      </h2>
      <p className="mb-2 text-gray-500 flex items-center">
        <AppHeroIcons.info className="w-4 h-4 mr-1" /> Paiements terminés depuis
        + d'un mois
      </p>

      {onlyThisYear ? (
        <DiverBookingPagePaymentTabPaymentsResumeTable
          globalState={globalState}
          dialogs={dialogs}
          actions={actions}
          purchasePaymentsWithDetails={purchasePaymentsWithDetails}
        />
      ) : (
        Array.from(inactiveParticipantPurchasePaymentsByYearDesc.entries()).map(
          ([year, purchasePaymentsWithDetails]) => (
            <DiverBookingPagePaymentTabArchivedPaymentsYearly
              key={year}
              year={year}
              purchasePaymentsWithDetails={purchasePaymentsWithDetails}
              globalState={globalState}
              localState={localState}
              dialogs={dialogs}
              actions={actions}
            />
          ),
        )
      )}
    </div>
  );
};
