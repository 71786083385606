import { DiveCenterResume } from '@mabadive/app-common-model';
import { bookingJourneyStepTransformer } from '@mabadive/app-common-services';
import {
  DiverBookingPageAggregatedData,
  DiverBookingPageTriggerAction,
  DiverBookingPageUpdateState,
} from '../../../models';
import {
  openSessionSelectDialog,
  quickBookingCreator,
} from '../../02.update-state';
import { quickBookingDiveSessionCreator } from './quickBookingDiveSessionCreator.service';
import { quickBookingSessionCreator } from './quickBookingSessionCreator.service';

export function updateStateWithInitialActionCreateBookingInitialPlan({
  diverId,
  diveSessionReference,
  updateState,
  aggregatedData,
  diveCenterResume,
}: {
  diverId: string;
  diveSessionReference: string;
  updateState: DiverBookingPageUpdateState;
  aggregatedData: DiverBookingPageAggregatedData;
  diveCenterResume: DiveCenterResume;
}): {
  updatedState?: DiverBookingPageUpdateState;
  triggerNextAction?: DiverBookingPageTriggerAction;
} {
  // TODO traitement à mutualiser avec updateStateWithInitialActionCreateBookingParticipant.service.ts

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  let updateStateLocal = updateState;

  // 1) SELECT OR CREATE BOOKING
  const { aggregatedBooking } = quickBookingCreator.selectOrCreateBooking({
    aggregatedData,
    clubReference,
    diveCenterId,
    diverId,
    updateStateLocal,
    publicSettings: diveCenterResume?.clubResume.clubSettings.publicSettings,
  });

  const bookingId = aggregatedBooking?.booking._id;

  if (diveSessionReference) {
    // 4) LOAD DIVE SESSION
    const { diveSessionResume } =
      quickBookingDiveSessionCreator.selectOrCreateDiveSession({
        aggregatedData,
        diveCenterResume,
        diveSessionReference,
        updateStateLocal,
      });
    if (!diveSessionResume) {
      // pas de diveSessionResume trouvée
      // 4.B) OPEN SELECT SESSION DIALOG
      return openSessionSelectDialog({ updateStateLocal, bookingId });
    }

    // 5) SELECT OR CREATE BOOKING SESSION
    const { bookingSession } =
      quickBookingSessionCreator.selectOrCreateBookingSession({
        updateStateLocal,
        aggregatedBooking,
        clubReference,
        diveCenterId,
        diveSessionResume,
      });

    return {
      updatedState: updateStateLocal,
      triggerNextAction: {
        id: 'open-massive-editor-dialog',
        bookingId,
        bookingSessionsIds: [bookingSession._id], // limit to theses sessions
      },
    };
  } else {
    // pas de diveSessionReference de spécifiée
    // 7.B) OPEN SELECT SESSION DIALOG
    const defaultFocusDate =
      bookingJourneyStepTransformer.getDepartureDateTime(
        aggregatedBooking.booking,
      ) ??
      bookingJourneyStepTransformer.getArrivalDateTime(
        aggregatedBooking.booking,
      );
    return {
      updatedState: updateStateLocal,
      triggerNextAction: {
        id: 'open-session-selector-dialog',
        bookingId,
        defaultFocusDate,
      },
    };
  }
}
