import {
  DashboardBookingsOverPeriodCleanAnalysis,
  DashboardBookingsOverPeriodCleanPeriod,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { AppButton, AppMessageLight } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppHeroIcons,
  AppIconsAction,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUserClubAuthorizations,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { DashboardMetricTitleWithValue } from 'src/pages/DA-dashboard/_core';
import { DataManagementFetchCriteria } from '../../model';
import { DataManagementBookingsCleanAnalysisBookingProducts } from './DataManagementBookingsCleanAnalysisBookingProducts';
import { DataManagementBookingsCleanAnalysisBookings } from './DataManagementBookingsCleanAnalysisBookings';
import { DataManagementBookingsCleanAnalysisBookingsOrphans } from './DataManagementBookingsCleanAnalysisBookingsOrphans';
import { DataManagementBookingsCleanAnalysisPurchasePackages } from './DataManagementBookingsCleanAnalysisPurchasePackages';
import { DataManagementBookingsCleanAnalysisPurchasePackagesOrphan } from './DataManagementBookingsCleanAnalysisPurchasePackagesOrphan';
import { DataManagementBookingsCleanContext } from './DataManagementBookingsCleanContext.type';
import { DataManagementBookingsCleanPeriodAge } from './DataManagementBookingsCleanPeriodAge.type';

export const DataManagementBookingsCleanAnalysis = ({
  fetchCriteria,
  bookingsCleanAnalysisOverPeriod: data,
  bookingsCleanPeriodOverPeriod: dataPeriod,
  title,
  reload,
}: {
  fetchCriteria: DataManagementFetchCriteria;
  bookingsCleanAnalysisOverPeriod: DashboardBookingsOverPeriodCleanAnalysis;
  bookingsCleanPeriodOverPeriod: DashboardBookingsOverPeriodCleanPeriod;
  title: string;
  reload: () => any;
}) => {
  const periodAge: DataManagementBookingsCleanPeriodAge = useMemo(() => {
    // on prend plutôt la date de la dernière donnée, ce qui permet de supprimer l'année précédente si on est au mois de janvier, mais pas de plongée depuis 3 mois
    const refDate = dataPeriod?.maxDiveSessionTime ?? fetchCriteria.endDate;
    if (dateService.isFutureDayUTC(refDate)) {
      return 'future';
    } else if (dateService.getDiffInMonths(refDate, new Date()) > 3) {
      if (dateService.getDiffInMonths(refDate, new Date()) > 12) {
        return 'very-old';
      }
      return 'old';
    }
    return 'recent';
  }, [dataPeriod?.maxDiveSessionTime, fetchCriteria.endDate]);

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const az = useAppSecurityUserClubAuthorizations();

  const context: DataManagementBookingsCleanContext = useMemo(() => {
    const x: DataManagementBookingsCleanContext = {
      periodAge,
      enableArchiveAction:
        periodAge !== 'future' &&
        (isSuperAdmin ||
          (az?.edit?.settings?.archiveData && periodAge === 'old')),
      toArchiveStyle: {
        className:
          periodAge === 'very-old'
            ? 'bg-red-700 text-white px-1'
            : periodAge === 'old'
            ? 'bg-orange-400 text-white px-1'
            : periodAge === 'recent'
            ? 'bg-app-primary text-white px-1'
            : '',
        iconClassName: undefined,
        icon:
          periodAge === 'very-old' || periodAge === 'old'
            ? AppHeroIcons.error
            : AppHeroIcons.info,
      },
    };
    return x;
  }, [az?.edit?.settings?.archiveData, isSuperAdmin, periodAge]);

  const bookingUnarchivedCounts =
    data?.bookingCounts - data?.bookingArchivedCounts;
  const bookingProductsNotPurchasedCount =
    data?.bookingProductsNotPurchasedCount;
  const purchasePackagesValidityStatusActiveCount =
    data?.purchasePackagesValidityStatusActiveCount;
  const purchasePackagesUnpayedCount = data?.purchasePackagesUnpayedCount;

  const orphanPurchasePackagesValidityStatusActiveCount =
    data?.orphanPurchasePackagesValidityStatusActiveCount;
  const orphanPurchasePackagesUnpayedCount =
    data?.orphanPurchasePackagesUnpayedCount;

  const enableArchiveAll = useMemo(() => {
    if (context.enableArchiveAction) {
      const actionsCount = [
        bookingUnarchivedCounts > 0,
        bookingProductsNotPurchasedCount > 0,
        purchasePackagesUnpayedCount > 0,
        purchasePackagesValidityStatusActiveCount > 0,
        orphanPurchasePackagesUnpayedCount > 0,
        orphanPurchasePackagesValidityStatusActiveCount > 0,
      ].filter((x) => !!x).length;

      return actionsCount > 1;
    }
    return false;
  }, [
    context.enableArchiveAction,
    bookingProductsNotPurchasedCount,
    bookingUnarchivedCounts,
    orphanPurchasePackagesUnpayedCount,
    orphanPurchasePackagesValidityStatusActiveCount,
    purchasePackagesUnpayedCount,
    purchasePackagesValidityStatusActiveCount,
  ]);

  const { beginDate, endDate } = fetchCriteria;
  const [isPersistInProgress, setIsPersistInProgress] = useState(false);

  return !data ? null : (
    <>
      <div
        className={clsx(
          'max-w-xxx-disabled',
          'app-card app-p-content grid gap-4',
        )}
      >
        <DashboardMetricTitleWithValue
          label={title}
          description="État des réservations dont la dernière prestation correspond à cette période"
          valueClassName="bg-gray-600 text-white"
          icon={EntitiesIcons.booking}
          value={data.bookingCounts}
        />

        <div className="mt-5 flex flex-wrap gap-x-8 gap-y-4">
          <DataManagementBookingsCleanAnalysisBookingsOrphans
            context={context}
            fetchCriteria={fetchCriteria}
            bookingsCleanAnalysisOverPeriod={data}
            reload={reload}
          />{' '}
          <DataManagementBookingsCleanAnalysisBookings
            context={context}
            fetchCriteria={fetchCriteria}
            bookingsCleanAnalysisOverPeriod={data}
            reload={reload}
          />
          <DataManagementBookingsCleanAnalysisBookingProducts
            context={context}
            fetchCriteria={fetchCriteria}
            bookingsCleanAnalysisOverPeriod={data}
            reload={reload}
          />
          <DataManagementBookingsCleanAnalysisPurchasePackages
            context={context}
            fetchCriteria={fetchCriteria}
            bookingsCleanAnalysisOverPeriod={data}
            reload={reload}
          />
          <DataManagementBookingsCleanAnalysisPurchasePackagesOrphan
            context={context}
            fetchCriteria={fetchCriteria}
            bookingsCleanAnalysisOverPeriod={data}
            reload={reload}
          />
        </div>

        {enableArchiveAll ? (
          <AppMessageLight type="danger">
            <div className="grid gap-4">
              <p>
                Les données à "archiver" correspondent à des réservations dont
                la dernière plongée est antérieure au{' '}
                <b>{dateService.formatUTC(endDate, 'DD/MM/YYYY')}</b>.
              </p>
              <p>
                Cela vous permet de faire du ménage dans les prestations
                passées:
                <ul className="ml-3 list-disc">
                  {bookingUnarchivedCounts > 0 && (
                    <li>
                      <b>{bookingUnarchivedCounts} réservations en cours</b> :
                      ne seront plus chargés par défaut sur la fiche du client
                    </li>
                  )}
                  {bookingProductsNotPurchasedCount > 0 && (
                    <li>
                      <b>
                        {bookingProductsNotPurchasedCount} prestations
                        non-facturés
                      </b>
                      : seront déplacées dans la section "Prestations archivées"
                    </li>
                  )}
                  {purchasePackagesValidityStatusActiveCount > 0 && (
                    <li>
                      <b>
                        {purchasePackagesValidityStatusActiveCount} ventes
                        (facturations) en cours
                      </b>
                      : seront déplacées dans la section "Prestations archivées"
                    </li>
                  )}
                  {purchasePackagesUnpayedCount > 0 && (
                    <li>
                      <b>
                        {purchasePackagesUnpayedCount} ventes (facturations)
                        non-payés
                      </b>
                      : seront déplacées dans la section "Prestations archivées"
                    </li>
                  )}
                  {orphanPurchasePackagesValidityStatusActiveCount > 0 && (
                    <li>
                      <b>
                        {orphanPurchasePackagesValidityStatusActiveCount} ventes
                        détâchées (sans plongée imputée) en cours
                      </b>
                      : seront déplacées dans la section "Prestations archivées"
                    </li>
                  )}
                  {orphanPurchasePackagesUnpayedCount > 0 && (
                    <li>
                      <b>
                        {orphanPurchasePackagesUnpayedCount} ventes détâchées
                        (sans plongée imputée) non-payés
                      </b>
                      : seront déplacées dans la section "Prestations archivées"
                    </li>
                  )}
                </ul>
              </p>
              <p>
                <b className="text-status-error">ATTENTION: </b> :
                <b>opération irréversible</b> (sauf en repasssant sur toutes les
                fiches manuellement).
              </p>
              <p>Ne pas hésiter à nous contacter en cas de doute.</p>
              <div className="flex justify-center">
                {enableArchiveAll && (
                  <div className="flex flex-col-reverse">
                    <AppButton
                      className="my-2 w-full"
                      color="danger-outline-light"
                      icon={AppIconsAction.archive}
                      disabled={isPersistInProgress}
                      onClick={async () => {
                        //sur?
                        if (
                          await confirmDialog.confirmPromise({
                            title: 'Archiver toutes les réservations',
                            message:
                              'Êtes-vous sûr de vouloir archiver toutes les réservations de cette période?',
                            type: 'noYesDanger',
                          })
                        ) {
                          const payload: ProMultiOperationPayload = {
                            logContext: 'dashboard clean analysis:  all',
                            archiveOverPeriod: {
                              beginDate,
                              endDate,
                              bookings: {
                                active: bookingUnarchivedCounts > 0,
                              },
                              bookingProducts: {
                                purchaseStatus:
                                  bookingProductsNotPurchasedCount > 0,
                              },
                              purchasePackages: {
                                purchasePaymentStatus:
                                  purchasePackagesUnpayedCount > 0,
                                validityStatus:
                                  purchasePackagesValidityStatusActiveCount > 0,
                              },
                              purchasePackagesOrphan: {
                                purchasePaymentStatus:
                                  orphanPurchasePackagesUnpayedCount > 0,
                                validityStatus:
                                  orphanPurchasePackagesValidityStatusActiveCount >
                                  0,
                              },
                            },
                          };
                          setIsPersistInProgress(true);
                          try {
                            await clubMassiveUpdatorClient.update(payload);
                            await reload();
                          } finally {
                            setIsPersistInProgress(false);
                          }
                        }
                      }}
                    >
                      Archiver tout
                    </AppButton>
                  </div>
                )}
              </div>
            </div>
          </AppMessageLight>
        ) : (
          <AppMessageLight type="info">
            <div className="grid gap-4">
              <p>
                Aucune donnée à "archiver" correspondant à des réservations dont
                la dernière plongée est antérieure au{' '}
                <b>{dateService.formatUTC(endDate, 'DD/MM/YYYY')}</b>.
              </p>
              <p>Modifiez les dates de recherche pour affiner les résultats.</p>
            </div>
          </AppMessageLight>
        )}
      </div>
    </>
  );
};
