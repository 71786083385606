import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { uiStore } from 'src/business/_core/store';
import {
  authenticationClient,
  authenticationStore,
  useAppSecurityUser,
  useAppSecurityUserClubAuthorizations,
} from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { clubSettingsAccountUrlBuilder } from '../../../clubSettingsAccountUrlBuilder.service';
import { SettingsClubProfileWithAccounts } from '../model';
import { ClubSettingsUserAccountCard } from './ClubSettingsUserAccountCard';
import {
  ClubUserProfilesAuthorizationTableBody,
  ClubUserProfilesAuthorizationTableHeader,
} from './ClubUserProfilesAuthorizationTable';

export const ClubSettingsUserProfileCard = ({
  clubProfileWithAccounts,
  onClick,
  className,
  as = 'div',
}: {
  clubProfileWithAccounts: SettingsClubProfileWithAccounts;
  onClick?: () => any;
  className?: string;
  as?: React.ElementType; // TODO personnaliser le componsant (ici, un "li", mais on pourrait vouloir un "div")
}) => {
  const securityUser = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();
  const azEditSettings = az.edit.settings;
  const hasRoleEditUserProfile = azEditSettings.accountProfiles;
  const hasRoleEditUserAccount = azEditSettings.userAccounts;
  const hasRoleImpersonateUserAccount =
    azEditSettings.accountProfilesImpersonate &&
    !securityUser.impersonate?.previous?.club; // on n'autorise pas à aller à 2 niveau, car ça plante à cause de la taille du header: voir bug "Double impersonate" dans TODO_P4_LATER.md

  const clubProfileId = clubProfileWithAccounts.clubProfile._id;

  const { editProfileHref, cloneProfileHref } = useMemo(() => {
    const editProfileHref =
      clubSettingsAccountUrlBuilder.userAccounts.editUserProfile({
        clubProfileId,
      });
    const cloneProfileHref =
      clubSettingsAccountUrlBuilder.userAccounts.createUserProfile({
        cloneFromClubProfileId: clubProfileId,
      });
    return { editProfileHref, cloneProfileHref };
  }, [clubProfileId]);

  const EditProfilConditionalLink: any = hasRoleEditUserProfile
    ? Link
    : ({
        className,
        children,
      }: {
        className?: string;
        children: React.ReactNode;
      }) => <div className={className}>{children}</div>;

  const redirectTo = useRedirect();

  const { clubProfile, userAccounts } = clubProfileWithAccounts;

  const diveCenterResume = useDiveCenterResume();

  const [operationPending, setOperationPending] = useState(false);

  const onClickImpersonate = useCallback(async () => {
    setOperationPending(true);

    try {
      await authenticationClient
        .impersonateClubProfile({
          clubReference: diveCenterResume.clubReference,
          diveCenterId: diveCenterResume._id,
          clubProfileId,
        })
        .toPromise();

      uiStore.snackbarMessage.set({
        type: 'success',
        content: 'Connexion réussie!',
      });
      const appAuth = authenticationStore.auth.getSnapshot();
      const defaultRoute = appRouteBuilder.getDefaultRouteFromAuth({ appAuth });
      redirectTo(defaultRoute);
    } catch (err) {
    } finally {
      setOperationPending(false);
    }
    // redirectTo(
    //   clubSettingsAccountUrlBuilder.userAccounts.createUserAccount({
    //     clubProfileId,
    //   }),
    // )
  }, [
    clubProfileId,
    diveCenterResume._id,
    diveCenterResume.clubReference,
    redirectTo,
  ]);

  return React.createElement(
    as,
    {
      className: clsx(
        'app-card p-4 group flex flex-col divide-y divide-gray-100 text-gray-800',
        onClick && 'cursor-pointer',
        className,
      ),
      onClick,
      style: {
        // backgroundColor: colorGenerator.rgba(category.color, 0.1),
      },
    },
    <div className={'flex flex-col gap-2'}>
      <div className="flex gap-2 items-center p-1 md:p-2 px-5 md:px-6">
        <EditProfilConditionalLink
          to={editProfileHref}
          className={clsx(
            'flex-grow group/profile-title flex gap-2 items-center',
            hasRoleEditUserProfile && 'cursor-pointer',
          )}
        >
          {hasRoleEditUserProfile && (
            <div className="flex-shrink-0">
              <AppHeroIcons.actionEditAlt className="text-gray-200 group-hover/profile-title:text-gray-400 h-10 w-10" />
            </div>
          )}
          <div>
            <h3
              className={clsx(
                'text-lg font-bold uppercase text-app-primary',
                hasRoleEditUserProfile &&
                  'group-hover/profile-title:text-app-primary-dark',
              )}
            >
              {clubProfile.label}
            </h3>
            <p className={clsx('my-1 text-base text-gray-500')}>
              {clubProfile.description}
            </p>
          </div>
        </EditProfilConditionalLink>
        {hasRoleEditUserProfile && (
          <Link to={cloneProfileHref} className="flex-shrink-0">
            <AppHeroIcons.actionClone className="text-gray-200 hover:text-gray-600 h-6 w-6 hover:scale-125" />
          </Link>
        )}
      </div>
      <div className={'p-1 md:p-2 flex flex-col gap-4'}>
        <div
          className={clsx(
            'px-4 hidden sm:block divide-y divide-gray-200 text-left',
          )}
        >
          <ClubUserProfilesAuthorizationTableHeader />
          <ClubUserProfilesAuthorizationTableBody
            authorizations={clubProfile.authorizations}
          />
        </div>
      </div>

      {userAccounts.length > 0 && (
        <ul className="border-t border-gray-100 divide-y divide-gray-200">
          {userAccounts.map((userAccount, i) => (
            <ClubSettingsUserAccountCard
              key={userAccount._id}
              as="li"
              userAccount={userAccount}
            />
          ))}
        </ul>
      )}
      <div className="app-px-content flex gap-4 justify-between">
        {hasRoleEditUserAccount && (
          <div className="my-2 text-center">
            <AppButton
              color="primary-outline-light"
              size="normal"
              icon={AppHeroIcons.actionAdd}
              onClick={() =>
                redirectTo(
                  clubSettingsAccountUrlBuilder.userAccounts.createUserAccount({
                    clubProfileId,
                  }),
                )
              }
            >
              Ajouter un utilisateur
            </AppButton>
          </div>
        )}
        {hasRoleImpersonateUserAccount && (
          <div className="my-2 text-center">
            <AppButton
              color="primary-outline-light"
              size="normal"
              icon={AppHeroIcons.userCircle}
              onClick={() => onClickImpersonate()}
            >
              Tester le profil
            </AppButton>
          </div>
        )}
      </div>
    </div>,
  );
};
