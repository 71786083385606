import React, { useCallback } from 'react';
import { DiverPurchaseOtherEditorDialogState } from '../../models';

export function useOpenEditPurchaseOtherDialog({
  setPurchaseOtherEditorDialogState,
}: {
  setPurchaseOtherEditorDialogState: React.Dispatch<
    React.SetStateAction<DiverPurchaseOtherEditorDialogState>
  >;
}) {
  return useCallback(
    ({
      diver,
      purchasePackage,
      sessionsBillingResumes,
      aggregatedData,
    }: Pick<
      DiverPurchaseOtherEditorDialogState,
      'diver' | 'purchasePackage' | 'sessionsBillingResumes' | 'aggregatedData'
    >) =>
      setPurchaseOtherEditorDialogState({
        isOpen: true,
        mode: 'edit',
        diver,
        purchasePackage,
        sessionsBillingResumes,
        aggregatedData,
      }),
    [setPurchaseOtherEditorDialogState],
  );
}
