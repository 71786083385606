/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppVisible } from 'src/business/_core/modules/layout';
import { AppMessage } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { clubSettingsDiveCenterUrlBuilder } from 'src/pages/SE-settings/SE-01_dive-center/clubSettingsDiveCenterUrlBuilder.service';

export const DiveSessionDialogTab1EditInfoBox = () => {
  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  function nextEditStaff() {
    redirectTo(clubSettingsDiveCenterUrlBuilder.staff.list());
  }

  function nextEditDiveSites() {
    redirectTo(clubSettingsDiveCenterUrlBuilder.diveSites.list());
  }

  function nextEditBoats() {
    redirectTo(clubSettingsDiveCenterUrlBuilder.boats.list());
  }

  return (
    <Box className={globalClasses.pageBlock} marginTop={10}>
      <AppMessage
        type="info"
        title="Note"
        message={
          <>
            <AppVisible role="club-edit-planning-security-sheet">
              <p>
                Toutes les informations apparaissent sur la{' '}
                <b>fiche de sécurité.</b>
              </p>
            </AppVisible>
            <AppVisible role="club-edit-settings-dive-center">
              <p>
                Vous pouvez gérer les sites de plongée, les moniteurs et les
                bateaux dans l'espace "Paramètres".
              </p>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Button
                  startIcon={<AppIconsMaterial.settings />}
                  size="small"
                  color="primary"
                  onClick={() => nextEditDiveSites()}
                >
                  Configurer les sites de plongée
                </Button>
                <Button
                  startIcon={<AppIconsMaterial.settings />}
                  size="small"
                  color="primary"
                  onClick={() => nextEditStaff()}
                >
                  Configurer les moniteurs
                </Button>
                <Button
                  startIcon={<AppIconsMaterial.settings />}
                  size="small"
                  color="primary"
                  onClick={() => nextEditBoats()}
                >
                  Configurer les bateaux
                </Button>
              </Box>
            </AppVisible>
          </>
        }
      />
    </Box>
  );
};
