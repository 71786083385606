// https://heroicons.com/ ?

import {
  AdjustmentsHorizontalIcon,
  ArrowLeftIcon,
  ArrowPathIcon,
  ArrowRightIcon,
  ArrowRightOnRectangleIcon,
  ArrowsPointingOutIcon,
  ArrowsRightLeftIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUpTrayIcon,
  ArrowUturnLeftIcon,
  Bars3Icon,
  BoltIcon,
  CheckBadgeIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  CogIcon,
  FolderOpenIcon,
  ForwardIcon,
  LinkIcon,
  LockClosedIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  PlusIcon,
  PresentationChartLineIcon,
  PrinterIcon,
  Square2StackIcon,
  TableCellsIcon,
  TrashIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ArchiveBoxIcon,
  ArrowDownIcon,
  ArrowDownTrayIcon,
  ArrowUpIcon,
  LanguageIcon,
  MinusCircleIcon as MinusCircleIconSolid,
  PlusCircleIcon as PlusCircleIconSolid,
  PowerIcon,
  UserPlusIcon,
  XCircleIcon as XCircleIconSolid,
} from '@heroicons/react/24/solid';
import { MdAddAPhoto } from 'react-icons/md';

export const AppIconsAction = {
  screenSharing: PresentationChartLineIcon,
  skip: ForwardIcon,
  up: ArrowUpIcon,
  back: ArrowUturnLeftIcon,
  prev: ArrowLeftIcon,
  next: ArrowRightIcon,
  down: ArrowDownIcon,
  translate: LanguageIcon,
  add: PlusIcon,
  addCircle: PlusCircleIcon,
  addUser: UserPlusIcon,
  refresh: ArrowPathIcon,
  archive: ArchiveBoxIcon,
  cancel: XCircleIcon,
  logout: PowerIcon,
  cancel2: XCircleIconSolid,
  clone: Square2StackIcon,
  close: XMarkIcon,
  confirm: CheckIcon,
  decrement: PlusCircleIconSolid,
  delete: TrashIcon,
  download: ArrowDownTrayIcon,
  edit: PencilIcon,
  editAlt: PencilSquareIcon,
  editBulk: TableCellsIcon,
  expand: ArrowsPointingOutIcon,
  extend: ChevronDownIcon,
  extract: ArrowRightOnRectangleIcon,
  impute: CheckBadgeIcon,
  increment: MinusCircleIconSolid,
  lock: LockClosedIcon,
  menu: Bars3Icon,
  move: ChevronUpDownIcon,
  moveSwitch: ArrowsRightLeftIcon,
  open: FolderOpenIcon,
  print: PrinterIcon,
  reduce: ChevronUpIcon,
  remove: TrashIcon,
  save: CheckIcon,
  upload: ArrowUpTrayIcon,
  settings: CogIcon,
  settingsDisplay: AdjustmentsHorizontalIcon,
  generate: BoltIcon,
  link: LinkIcon,
  linkExternal: ArrowTopRightOnSquareIcon,
  actionAddPhoto: MdAddAPhoto,
};
