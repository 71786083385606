import { ClubResumeStaffMember, DiveSession } from '@mabadive/app-common-model';
import { nameFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

export const ClubDiveSessionHeaderSecurityStaff = function ({
  diveSession: session,
  staffFirstNameBefore,
  className = '',
}: {
  diveSession: Pick<DiveSession, 'diveTourSession1' | 'diveTourSession2'>;
  staffFirstNameBefore: boolean;
  className?: string;
}) {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const divingDirectorStaffMembers: ClubResumeStaffMember[] = useMemo(() => {
    const members: ClubResumeStaffMember[] = [];
    if (session.diveTourSession1?.divingDirector?.staffId) {
      const member = diveCenterResume?.staffMembers.find(
        (p) => p._id === session.diveTourSession1?.divingDirector?.staffId,
      );
      if (member) {
        members.push(member);
      }
    }
    if (
      !session.diveTourSession2?.sameStaffSession1 &&
      session.diveTourSession1?.divingDirector?.staffId !==
        session.diveTourSession2?.divingDirector?.staffId
    ) {
      const member = diveCenterResume?.staffMembers.find(
        (p) => p._id === session.diveTourSession2?.divingDirector?.staffId,
      );
      if (member) {
        members.push(member);
      }
    }
    return members;
  }, [
    session.diveTourSession1?.divingDirector?.staffId,
    session.diveTourSession2?.sameStaffSession1,
    session.diveTourSession2?.divingDirector?.staffId,
    diveCenterResume?.staffMembers,
  ]);

  const surfaceSecurityStaffMembers: ClubResumeStaffMember[] = useMemo(() => {
    const members: ClubResumeStaffMember[] = [];
    if (session.diveTourSession1?.surfaceSecurityStaffId) {
      const member = diveCenterResume?.staffMembers.find(
        (p) => p._id === session.diveTourSession1?.surfaceSecurityStaffId,
      );
      if (member) {
        members.push(member);
      }
    }
    if (
      !session.diveTourSession2?.sameStaffSession1 &&
      session.diveTourSession1?.surfaceSecurityStaffId !==
        session.diveTourSession2?.surfaceSecurityStaffId
    ) {
      const member = diveCenterResume?.staffMembers.find(
        (p) => p._id === session.diveTourSession2?.surfaceSecurityStaffId,
      );
      if (member) {
        members.push(member);
      }
    }
    return members;
  }, [
    session.diveTourSession1?.surfaceSecurityStaffId,
    session.diveTourSession2?.sameStaffSession1,
    session.diveTourSession2?.surfaceSecurityStaffId,
    diveCenterResume?.staffMembers,
  ]);

  return !(
    divingDirectorStaffMembers.length !== 0 ||
    surfaceSecurityStaffMembers.length !== 0
  ) ? null : (
    <div className={`px-1 ${className}`}>
      {divingDirectorStaffMembers.length !== 0 && (
        <div className="flex flex-wrap gap-1">
          <span className="font-bold text-gray-500 uppercase">DP:</span>
          {divingDirectorStaffMembers.map((divingDirectorStaffMember, i) => (
            <span key={i} className="font-bold">
              {nameFormatter.formatFullName(divingDirectorStaffMember.profile, {
                format: staffFirstNameBefore
                  ? 'firstName-first'
                  : 'lastName-first',
              })}
              {divingDirectorStaffMember?.scubaDivingInstructor?.degree
                ?.level &&
                ` (${divingDirectorStaffMember.scubaDivingInstructor?.degree?.level})`}
            </span>
          ))}
        </div>
      )}
      {surfaceSecurityStaffMembers.length !== 0 && (
        <div className="flex flex-wrap gap-1">
          <span className="font-bold text-gray-500 uppercase">SS:</span>
          {surfaceSecurityStaffMembers.map((surfaceSecurityStaffMember, i) => (
            <span key={i} className="font-bold">
              {nameFormatter.formatFullName(
                surfaceSecurityStaffMember.profile,
                {
                  format: staffFirstNameBefore
                    ? 'firstName-first'
                    : 'lastName-first',
                },
              )}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
