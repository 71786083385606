import { dataSorter, search } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubBookingsListPageFilters } from './useClubBookingsListPageFilters.hook';
import { useFetchBookingsWithCache } from './useFetchBookingsWithCache.hook';

export function useClubBookingsListPageLocalState() {
  const filters = useClubBookingsListPageFilters();
  const {
    searchText,
    bookingStatuses,
    bookingGroupTypes,
    arrivalDate,
    departureDate,
    bookingAgencyId,
    archivedExclusive,
  } = filters;

  const { data: bookings, loadableContent } = useFetchBookingsWithCache(
    {
      arrivalDate,
      departureDate,
      archivedExclusive,
      bookingGroupTypes,
      bookingStatuses,
    },
    {
      autoRefetchInterval: '5 minutes', // periodic refresh (partial or full)
      staleInterval: '1 minute', // (full refresh if data is older));
    },
  );

  const bookingsSorted = useMemo(
    () =>
      dataSorter.sortMultiple(bookings ?? [], {
        getSortAttributes: (x, i) => [
          {
            value: x.bookingCustomerUpdates.length,
            type: 'default',
            asc: false,
          },
          {
            value: new Date(x.bookingLastUpdateDate),
            type: 'default',
            asc: false,
          },
        ],
        asc: true,
      }),
    [bookings],
  );

  const filteredBookings = useMemo(() => {
    if (bookingsSorted) {
      let localBookings = bookingsSorted;
      if (bookingStatuses?.length) {
        localBookings = localBookings.filter((x) =>
          bookingStatuses.includes(x.bookingStatus),
        );
      }
      if (bookingAgencyId) {
        localBookings = localBookings.filter(
          (x) => x.details?.agencyId === bookingAgencyId,
        );
      }
      localBookings = localBookings.filter(
        (x) => x.active === !archivedExclusive,
      );
      if (bookingGroupTypes?.length) {
        localBookings = localBookings.filter((x) =>
          bookingGroupTypes.includes(x.bookingGroup?.type),
        );
      }
      return search.filter(localBookings, {
        searchText,
        getAttributes: (x) => [
          x.bookingContact.lastName,
          x.bookingContact.firstName,
          x.bookingGroup?.name,
        ],
        // maxResults: 50,
        // sortResultsByBestMatch: true,
      });
    }
    return bookingsSorted;
  }, [
    bookingAgencyId,
    bookingGroupTypes,
    bookingStatuses,
    bookingsSorted,
    archivedExclusive,
    searchText,
  ]);

  const filteredBookingsLimited = useMemo(
    () => filteredBookings.slice(0, 100),
    [filteredBookings],
  );

  return {
    filteredBookings,
    filteredBookingsLimited,
    filters,
    loadableContent,
  };
}

export type ClubBookingsListPageLocalState = ReturnType<
  typeof useClubBookingsListPageLocalState
>;
