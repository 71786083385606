/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppPageContainerWithFixedBars } from 'src/business/_core/modules/layout';
import { ClubPlanningDailyStaffDialogActionsBar } from './ClubPlanningDailyStaffDialogActionsBar';

import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import { SetPlanningConfigOptions } from 'src/business/club/modules/club-planning-light/hooks';
import { useClubPlanningActions } from 'src/business/club/modules/club-planning-light/useClubPlanningActions.hook';
import {
  ClubPlanningStaffPanelLocalState,
  useClubPlanningStaffPanelLocalState,
} from 'src/business/club/modules/club-planning/ClubPlanningStaffPage/ClubPlanningStaffPanel';
import { ClubPlanningStaffWeekStaffPresence } from 'src/business/club/modules/club-planning/ClubPlanningStaffPage/ClubPlanningStaffWeekStaffPresence';
import { PlanningStaffLocalState } from 'src/business/club/modules/club-planning/ClubPlanningStaffPage/usePlanningStaffLocalState.hook';
import { PlanningViewConfig } from 'src/business/club/modules/club-planning/_model';
import {
  ClubPlanningDailyStaffDialogInitialState,
  ClubPlanningDailyStaffDialogState,
} from './_model';
import { ClubDialogsState } from 'src/pages/_dialogs';

export function ClubPlanningDailyStaffDialogPanel({
  parentState,
  inputState,
  planningConfig,
  setPlanningConfig,
}: {
  parentState: PlanningStaffLocalState;
  inputState: ClubPlanningDailyStaffDialogState;
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
}) {
  const initialState: ClubPlanningDailyStaffDialogInitialState =
    inputState.initialState;

    const { clickActions, dialogsState } = useClubPlanningActions(parentState);

  const localState: ClubPlanningStaffPanelLocalState =
    useClubPlanningStaffPanelLocalState({
      parentState,
      dialogsState
    });


  return (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={
        localState.data.operationInProgressMessage
          ? () => (
              <LoadingProgressBar>
                {localState.data.operationInProgressMessage}
              </LoadingProgressBar>
            )
          : () => (
              <ClubPlanningDailyStaffDialogActionsBar
                localState={localState}
                closeDialog={inputState.closeDialog}
              />
            )
      }
    >
      <div className="app-p-content">
        <ClubPlanningStaffWeekStaffPresence
          className="flex-grow mt-2 mx-2"
          parentState={localState}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
          clickActions={clickActions}
          showHeaderDays={true}
        />
      </div>
    </AppPageContainerWithFixedBars>
  );
}
