/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubPurchasePackagePaymentStatus } from '@mabadive/app-common-model';
import { clubPurchasePackagePaymentStatusFormatter } from '@mabadive/app-common-services';
import React, { FC, useMemo } from 'react';

export const DiverPurchasePaymentStatusLabel: FC<{
  purchasePaymentPending?: boolean;
  purchasePaymentStatus: ClubPurchasePackagePaymentStatus;
  className?: string;
}> = ({ purchasePaymentPending, purchasePaymentStatus, className }) => {
  const color = useMemo(
    () =>
      purchasePaymentPending
        ? 'text-fuchsia-600'
        : purchasePaymentStatus === 'done'
        ? 'text-status-success'
        : purchasePaymentStatus === 'partial'
        ? 'text-status-info'
        : 'text-gray-500',
    [purchasePaymentPending, purchasePaymentStatus],
  );

  return !!purchasePaymentStatus ? (
    <div className={`text-xs font-bold uppercase ${color} ${className}`}>
      {clubPurchasePackagePaymentStatusFormatter.formatStatus(
        purchasePaymentStatus,
        purchasePaymentPending,
      )}
    </div>
  ) : null;
};
