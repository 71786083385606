/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { appDateNavigatorHelper } from 'src/business/_core/modules/layout/components';
import { PlanningViewConfig } from '../../club-planning/_model';
import { clubPlanningUrlBuilder } from '../../url-builders';
import { SetPlanningConfigOptions } from '../hooks';

export const ClubPlanningTodayButton = ({
  planningConfig,
  setPlanningConfig,
  className,
}: {
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  className?: string;
}) => {
  const { focusDate } = planningConfig;

  const setFocusDate = useCallback(
    (focusDate: Date) => {
      const focusDateRef =
        clubPlanningUrlBuilder.buildFocusDateRefFromDate(focusDate);

      setPlanningConfig(
        {
          ...planningConfig,
          focusDate,
          focusDateRef,
        },
        {
          origin: '[ClubPlanningTodayButton] setFocusDate',
        },
      );
    },
    [planningConfig, setPlanningConfig],
  );
  const isNow = useMemo(
    () =>
      appDateNavigatorHelper.isPlanningNow({
        viewPeriod: planningConfig?.viewPeriod,
        focusDate,
      }),
    [focusDate, planningConfig],
  );

  return (
    !isNow && (
      <button
        className={`border rounded py-1 px-2 text-xs 
          border-gray-300 text-gray-500 hover:border-gray-500 hover:text-gray-700
          text-xs uppercase cursor-pointer 
          ${className}
      `}
        onClick={() =>
          setFocusDate(
            dateService.getUTCDateWithoutTimeFromLocalTime(new Date()),
          )
        }
      >
        <span className="hidden lg:inline">Aujourd'hui</span>
        <span className="hidden sm:inline lg:hidden">Auj.</span>
        <span className="sm:hidden">Aj.</span>
      </button>
    )
  );
};
