import { useCallback, useState } from 'react';
import { EcommerceProductEditorInputProps } from './useEcommerceProductEditorPanelLocalState.hook';

export function useEcommerceProductEditorDialogClient() {
  const [state, setState] = useState<{
    isOpen: boolean;
    props?: EcommerceProductEditorInputProps;
  }>({
    isOpen: false,
  });

  const openCreateProduct = useCallback(
    (
      input: Pick<
        EcommerceProductEditorInputProps,
        'defaultValue' | 'categoryBookletPage'
      >,
    ) => {
      const props: EcommerceProductEditorInputProps = {
        ...input,
        mode: 'create',
      };
      setState({
        isOpen: true,
        props,
      });
    },
    [],
  );
  const openEditProduct = useCallback(
    (
      input: Pick<
        EcommerceProductEditorInputProps,
        'editValue' | 'categoryBookletPage'
      >,
    ) => {
      const props: EcommerceProductEditorInputProps = {
        ...input,
        mode: 'edit',
      };
      setState({
        isOpen: true,
        props,
      });
    },
    [],
  );
  const closeDialog = useCallback(() => {
    setState({
      isOpen: false,
      props: undefined,
    });
  }, []);

  return {
    ...state,
    openCreateProduct,
    openEditProduct,
    closeDialog,
  };
}

export type EcommerceProductEditorDialogClient = ReturnType<
  typeof useEcommerceProductEditorDialogClient
>;
