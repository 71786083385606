import { APP_COLORS } from '@mabadive/app-common-model';
import { createMuiTheme, responsiveFontSizes, Theme } from '@material-ui/core';
import { responsiveAttributeRange } from '../../layout';
export const globalTheme = extendGlobalTheme(
  responsiveFontSizes(
    createMuiTheme({
      typography: {
        h4: {
          textTransform: 'uppercase',
        },
      },
      palette: {
        primary: {
          main: APP_COLORS.blue,
        },
        secondary: {
          main: APP_COLORS.orange2,
        },
        background: {
          // default: '#143d54',
          // paper: '#143d54',
        },
        text: {
          primary: '#333',
        },
      },
    }),
  ),
);

function extendGlobalTheme(theme: Theme): Theme {
  return {
    ...theme,
    typography: {
      ...theme.typography,
      h4: {
        ...theme.typography.h4,
        ...responsiveAttributeRange(theme, {
          name: 'fontSize',
          min: 20,
          max: 40,
          suffix: 'px',
        }),
      },
    },
  };
}
