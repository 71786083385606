import {
  ClubResumeDiveSite,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import { clubDiveSessionThemeBuilder } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { CalendarSessionWidthMode } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/session-common';
import { AppEmojiIcon } from 'src/lib/form/components/AppEmojiPicker';
import { DiveSiteIconDef, useDiveSiteIcon } from '../hooks';

export const ClubDiveSessionHeaderDiveSite = function ({
  diveSite,
  sessionIndex,
  time,
  isMultiSession,
  widthMode,
  context = 'planning',
  className,
}: {
  diveSite: ClubResumeDiveSite;
  sessionIndex: number;
  time: Date;
  isMultiSession: boolean;
  widthMode: CalendarSessionWidthMode;
  context?: 'planning' | 'daily-resume';
  className?: string;
}) {
  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(time),
    [time],
  );
  const diveSiteIcon: DiveSiteIconDef = useDiveSiteIcon(diveSite?._id);
  return (
    <div
      className={clsx(
        'w-full flex justify-between sm:justify-start gap-0.5 sm:gap-1 items-center overflow-hidden',
        className,
      )}
    >
      <div className="flex-grow sm:flex-grow-0 flex justify-start gap-0.5 sm:gap-1 items-center">
        {widthMode !== 'tiny' && isMultiSession && (
          <AppHeroIcons.diveSite className={'w-4 h-4'} />
        )}
        {isMultiSession && <span>N°{sessionIndex} </span>}

        {diveSite?.emojiId ? (
          <div
            className={clsx(
              `rounded-full bg-white border border-day-period-${timeDayPeriod}-dark`,
              (isMultiSession ||
                (widthMode !== 'tiny' && context === 'planning')) &&
                'ml-1 sm:ml-2 px-0.5 ',
            )}
          >
            <AppEmojiIcon id={diveSite.emojiId} size={14} />
          </div>
        ) : (
          diveSiteIcon && (
            <diveSiteIcon.icon
              className={clsx('w-5 h-5', diveSiteIcon.colorClass)}
            />
          )
        )}
        {diveSite?.name && (
          <div className={'truncate text-ellipsis'}>
            {diveSite.name.toUpperCase()}
          </div>
        )}
      </div>
    </div>
  );
};
