import { AppEmojiData } from '@mabadive/app-common-model';
import React from 'react';
import { Control, Path, useController, useWatch } from 'react-hook-form';
import { AppEmojiPickerProps } from './AppEmojiPicker';
import { AppEmojiPickerPopover } from './AppEmojiPickerPopover';

export function AppEmojiPickerPopoverRHF<T>({
  control,
  name,
  className,
  ...extraProps
}: {
  control: Control<T>;
  name: Path<T>;
  required?: boolean;
  className?: string;
} & AppEmojiPickerProps) {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<T>({
    name,
    control,
    rules: {
      required: extraProps.required,
    },
  });

  const updatedValue: string = useWatch({
    control,
    name,
  }) as unknown as string;

  return (
    <AppEmojiPickerPopover
      {...extraProps}
      {...inputProps}
      value={updatedValue}
      onChange={(emojiId: string, emoji: AppEmojiData) => {
        console.log('xxx onchange emoji:', emoji);
        console.log('xxx onchange emoji id:', emoji?.id);
        onChange(emojiId);
        extraProps?.onChange && extraProps?.onChange(emoji?.id, emoji);
      }}
      className={className}
      // error={invalid}
    />
  );
}
