/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { uiStore } from 'src/business/_core/store';
import { DiverBookingPageConfig } from '../../models';
import { diverBookingPageUrlManager } from './diverBookingPageUrlManager.service';

export function useInitialDiverBookingPageConfig({
  enableRouteParams,
  enableStoreParams,
}: {
  enableRouteParams: boolean;
  enableStoreParams: boolean;
}) {
  const initialConfig = useMemo(() => {
    const queryParams = enableRouteParams
      ? diverBookingPageUrlManager.getQueryParameters()
      : undefined;

    const storeParams = enableStoreParams
      ? uiStore.diverBookingPageConfig.getSnapshot()
      : undefined;

    const tab = queryParams?.tab ?? storeParams?.tab ?? 'booking';
    const origin = queryParams?.origin ?? storeParams?.origin ?? undefined;
    const diveSessionReference =
      queryParams?.diveSessionReference ??
      storeParams?.diveSessionReference ??
      undefined;
    const action = queryParams?.action ?? storeParams?.action ?? undefined;

    const pageConfig: DiverBookingPageConfig = {
      tab,
      origin,
      diveSessionReference,
      action,
    };

    return pageConfig;
  }, [enableRouteParams, enableStoreParams]);
  return initialConfig;
}
