/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  ClubStaffMemberRole,
  DiveSession,
  DiveSessionStaffConfigMember,
  JsonPatchOperation,
  ScubaDivingInstructor,
} from '@mabadive/app-common-model';
import { jsonParser } from '@mabadive/app-common-services';
import { UseFormReturn } from 'react-hook-form';
import { DiveSessionEditorFormModel } from '../../../../forms/DiveSessionEditForm/model';
import { DiveSessionStaffRoleModel } from '../DiveSessionDialogTab3Groups/components/DiveSessionStaffMembersTable';
import { sessionUpdateBuilder } from './sessionUpdateBuilder.service';

export const sessionStaffConfigRoleUpdateBuilder = {
  buildPatchOperationsCreateOrUpdateSession,
};
function buildPatchOperationsCreateOrUpdateSession({
  diveSession,
  staffMember,
  isAvailableOnSession,
  toogleRole,
  form,
}: {
  diveSession: DiveSession;
  staffMember: ClubResumeStaffMember;
  isAvailableOnSession: boolean;
  toogleRole: Pick<DiveSessionStaffRoleModel, 'assigned' | 'staffRoleRef'>;
  form?: UseFormReturn<DiveSessionEditorFormModel, any, undefined>;
}): {
  diveSessionsPatchOperations: JsonPatchOperation[];
  createdDiveSession?: DiveSession;
} {
  const updatedDiveSession = buildUpdatedDiveSession({
    diveSession,
    staffMember,
    isAvailableOnSession,
    toogleRole,
    form,
  });

  if (updatedDiveSession.isVirtual) {
    return sessionUpdateBuilder.buildCreateSession(updatedDiveSession);
  } else {
    return sessionUpdateBuilder.buildUpdateSession({
      originalDiveSession: diveSession,
      updatedDiveSession,
    });
  }
}

function buildUpdatedDiveSession({
  diveSession,
  staffMember,
  isAvailableOnSession,
  toogleRole,
  form,
}: {
  diveSession: DiveSession;
  staffMember: ClubResumeStaffMember;
  isAvailableOnSession: boolean;
  toogleRole: Pick<DiveSessionStaffRoleModel, 'assigned' | 'staffRoleRef'>;
  form: UseFormReturn<DiveSessionEditorFormModel, any, undefined>;
}) {
  // deep copy
  const updatedDiveSession: DiveSession =
    jsonParser.cloneJSONWithDates(diveSession);

  const scubaDivingInstructor = staffMember?.scubaDivingInstructor;
  const staffId = staffMember?._id;
  const staffMemberId = staffMember._id;

  if (!updatedDiveSession.staffConfig) {
    updatedDiveSession.staffConfig = {
      staffMembers: [],
    };
  }
  if (!updatedDiveSession.staffConfig.staffMembers) {
    updatedDiveSession.staffConfig.staffMembers = [];
  }

  let staffMemberConfig: DiveSessionStaffConfigMember =
    updatedDiveSession.staffConfig.staffMembers.find(
      (x) => x.staffMemberId === staffMemberId,
    );
  if (!staffMemberConfig) {
    staffMemberConfig = {
      assigned: isAvailableOnSession,
      staffMemberId,
      assignedRoles: [],
    };
    updatedDiveSession.staffConfig.staffMembers.push(staffMemberConfig);
  }
  if (isAvailableOnSession || toogleRole) {
    // assign member
    if (toogleRole) {
      // TODO gérer le comportement relatif à role.active
      if (toogleRole.assigned) {
        // remove role
        staffMemberConfig.assignedRoles =
          staffMemberConfig.assignedRoles.filter(
            (x) => x !== toogleRole.staffRoleRef,
          );
        if (toogleRole.staffRoleRef === 'diving-director') {
          removeSessionDivingDirector({
            newDiveSession: updatedDiveSession,
            staffId,
            form,
          });
        }

        if (toogleRole.staffRoleRef === 'surface-security') {
          removeSessionSurfaceSecurity({
            newDiveSession: updatedDiveSession,
            staffId,
            form,
          });
        }
      } else {
        // add role
        staffMemberConfig.assignedRoles.push(toogleRole.staffRoleRef);
        // DP
        if (toogleRole.staffRoleRef === 'diving-director') {
          addSessionDivingDirector({
            newDiveSession: updatedDiveSession,
            scubaDivingInstructor,
            staffId,
            form,
          });
        }
        // sécu
        if (toogleRole.staffRoleRef === 'surface-security') {
          addSessionSurfaceSecurity({
            newDiveSession: updatedDiveSession,
            staffId,
            form,
          });
        }
      }
    } else {
      // utilisation du bouton toggle "Dispo"
      if (staffMemberConfig.assignedRoles.length === 0) {
        // on assigne le role par défaut
        const rolesToAssignFirstByPriority: ClubStaffMemberRole[] = [
          'scuba-diving-instructor',
          'surface-security',
          'free-diving-instructor',
          'diving-director',
        ];
        const roleToAssign = rolesToAssignFirstByPriority.filter((x) =>
          staffMember.profile.roles.includes(x),
        )?.[0];
        if (roleToAssign) {
          staffMemberConfig.assignedRoles.push(roleToAssign);
        }
      }
    }
  } else {
    // unassign member
    staffMemberConfig.assignedRoles = [];
    // dp
    removeSessionDivingDirector({
      newDiveSession: updatedDiveSession,
      staffId,
      form,
    });
    // sécu
    removeSessionSurfaceSecurity({
      newDiveSession: updatedDiveSession,
      staffId,
      form,
    });
  }

  if (staffMemberConfig.assignedRoles.length === 0) {
    // unassign member
    staffMemberConfig.assigned = false;
  } else {
    staffMemberConfig.assigned = true;
  }

  return updatedDiveSession;
}

function addSessionSurfaceSecurity({
  newDiveSession,
  staffId,
  form,
}: {
  newDiveSession: Pick<DiveSession, 'diveTourSession1' | 'diveTourSession2'>;
  staffId: string;
  form?: UseFormReturn<DiveSessionEditorFormModel, any, undefined>;
}) {
  if (!newDiveSession?.diveTourSession1.surfaceSecurityStaffId) {
    // pas de sécu sur la session: on le met
    if (!newDiveSession.diveTourSession1) {
      newDiveSession.diveTourSession1 = {};
    }
    newDiveSession.diveTourSession1.surfaceSecurityStaffId = staffId;
    form?.setValue(
      'diveSession.diveTourSession1.surfaceSecurityStaffId',
      staffId,
    );
  }
  if (
    newDiveSession.diveTourSession2 &&
    !newDiveSession.diveTourSession2.surfaceSecurityStaffId &&
    !newDiveSession.diveTourSession2.sameStaffSession1
  ) {
    // pas de sécu sur la session: on le met
    newDiveSession.diveTourSession2.surfaceSecurityStaffId = staffId;
    form?.setValue(
      'diveSession.diveTourSession2.surfaceSecurityStaffId',
      staffId,
    );
  }
}

function addSessionDivingDirector({
  newDiveSession,
  scubaDivingInstructor,
  staffId,
  form,
}: {
  newDiveSession: Pick<DiveSession, 'diveTourSession1' | 'diveTourSession2'>;
  scubaDivingInstructor: ScubaDivingInstructor;
  staffId: string;
  form?: UseFormReturn<DiveSessionEditorFormModel, any, undefined>;
}) {
  if (!newDiveSession?.diveTourSession1?.divingDirector) {
    // pas de DP sur la session: on le met
    if (!newDiveSession.diveTourSession1) {
      newDiveSession.diveTourSession1 = {};
    }
    newDiveSession.diveTourSession1.divingDirector = {
      degree: scubaDivingInstructor?.degree,
      staffId,
    };
    form?.setValue(
      'diveSession.diveTourSession1.divingDirector.staffId',
      staffId,
    );
    form?.setValue(
      'diveSession.diveTourSession1.divingDirector.degree.level',
      scubaDivingInstructor?.degree?.level,
    );
    form?.setValue(
      'diveSession.diveTourSession1.divingDirector.degree.name',
      scubaDivingInstructor?.degree?.name,
    );
  }
  if (
    newDiveSession.diveTourSession2 &&
    !newDiveSession.diveTourSession2.divingDirector &&
    !newDiveSession.diveTourSession2.sameStaffSession1
  ) {
    // pas de sécu sur la session: on le met
    newDiveSession.diveTourSession2.divingDirector = {
      degree: scubaDivingInstructor?.degree,
      staffId,
    };
    form?.setValue(
      'diveSession.diveTourSession2.divingDirector.staffId',
      staffId,
    );
    form?.setValue(
      'diveSession.diveTourSession2.divingDirector.degree.level',
      scubaDivingInstructor?.degree?.level,
    );
    form?.setValue(
      'diveSession.diveTourSession2.divingDirector.degree.name',
      scubaDivingInstructor?.degree?.name,
    );
  }
}

function removeSessionSurfaceSecurity({
  newDiveSession,
  staffId,
  form,
}: {
  newDiveSession: Pick<DiveSession, 'diveTourSession1' | 'diveTourSession2'>;
  staffId: string;
  form?: UseFormReturn<DiveSessionEditorFormModel, any, undefined>;
}) {
  if (
    // il était sécu sur la session N°1: on le retire
    newDiveSession.diveTourSession1?.surfaceSecurityStaffId === staffId
  ) {
    if (!newDiveSession.diveTourSession1) {
      newDiveSession.diveTourSession1 = {};
    }
    newDiveSession.diveTourSession1.surfaceSecurityStaffId = null;
    form?.setValue('diveSession.diveTourSession1.surfaceSecurityStaffId', null);
  }
  if (
    // il était sécu sur la session N°2: on le retire
    newDiveSession.diveTourSession2?.surfaceSecurityStaffId === staffId
  ) {
    newDiveSession.diveTourSession2.surfaceSecurityStaffId = null;
    form?.setValue('diveSession.diveTourSession2.surfaceSecurityStaffId', null);
  }
}

function removeSessionDivingDirector({
  newDiveSession,
  staffId,
  form,
}: {
  newDiveSession: Pick<DiveSession, 'diveTourSession1' | 'diveTourSession2'>;
  staffId: string;
  form?: UseFormReturn<DiveSessionEditorFormModel, any, undefined>;
}) {
  if (
    // il était DP sur la session N°1: on le retire
    newDiveSession.diveTourSession1?.divingDirector?.staffId === staffId
  ) {
    if (!newDiveSession.diveTourSession1) {
      newDiveSession.diveTourSession1 = {};
    }
    newDiveSession.diveTourSession1.divingDirector = null;
    form?.setValue('diveSession.diveTourSession1.divingDirector.staffId', null);
    form?.setValue(
      'diveSession.diveTourSession1.divingDirector.degree.level',
      null,
    );
    form?.setValue(
      'diveSession.diveTourSession1.divingDirector.degree.name',
      null,
    );
  }
  if (
    // il était DP sur la session N°2: on le retire
    newDiveSession.diveTourSession2?.divingDirector?.staffId === staffId
  ) {
    newDiveSession.diveTourSession2.divingDirector = null;
    form?.setValue('diveSession.diveTourSession2.divingDirector.staffId', null);
    form?.setValue(
      'diveSession.diveTourSession2.divingDirector.degree.level',
      null,
    );
    form?.setValue(
      'diveSession.diveTourSession2.divingDirector.degree.name',
      null,
    );
  }
}
