/* eslint-disable @typescript-eslint/no-unused-vars */
import { dataSorter, dateService } from '@mabadive/app-common-services';
import { useMemo, useState } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { useDiversOptions } from 'src/pages/_components/options';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  AggregatedBookingPurchasePaymentWithDetails,
  PaymentTabModel,
} from '../../models';
import { paymentTabModelBuilder } from '../../services';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';

export function useDiverBookingPagePaymentTabLocalState({
  globalState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) {
  const diveCenterResume = useDiveCenterResume();

  const { aggregatedData, focus, dialogsState, paymentTabFilterCriteria } =
    globalState;

  const paymentTabModel: PaymentTabModel = useMemo(() => {
    return paymentTabModelBuilder.buildPaymentTabModel({
      aggregatedData,
      focus,
      criteria: {
        ...paymentTabFilterCriteria,
        filteredDiverId: globalState.updateState.filteredDiverId,
        currentDiveCenterId: globalState.diveCenterId,
      },
    });
  }, [
    aggregatedData,
    focus,
    globalState.diveCenterId,
    globalState.updateState.filteredDiverId,
    paymentTabFilterCriteria,
  ]);

  const diversFilterOptions: ValueLabel<string>[] = useDiversOptions(
    aggregatedData.divers,
  );
  const paymentsSettings =
    diveCenterResume.clubResume.clubSettings.general.payments;
  const depositsSettings =
    diveCenterResume.clubResume.clubSettings.general.deposits;

  const lastBookingId = useMemo(
    () => aggregatedData.bookingResumesVisible?.[0]?.booking?._id,
    [aggregatedData.bookingResumesVisible],
  );

  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const purchasePaymentsWithDetails: AggregatedBookingPurchasePaymentWithDetails[] =
    useMemo(() => {
      const x = paymentTabModel.participantPayments.reduce(
        (acc, participantPayment, i) =>
          acc.concat(participantPayment.purchasePaymentsWithDetails),
        [] as AggregatedBookingPurchasePaymentWithDetails[],
      );
      return dataSorter.sortMultiple(x, {
        getSortAttributes: (x) => {
          const paymentState = x.purchasePayment?.paymentState;

          return [
            {
              value:
                paymentState === 'authorized'
                  ? 1
                  : paymentState === 'pending'
                  ? 2
                  : 3,
              asc: true,
            },
            {
              value: x.purchasePayment?.paymentDate,
              asc: false,
            },
            {
              value: x.bookingResume?.datesRange?.minDate,
              asc: false,
            },
          ];
        },
      });
    }, [paymentTabModel.participantPayments]);

  const {
    activePurchasePaymentsWithDetails,
    inactivePurchasePaymentsWithDetails,
  } = useMemo(() => {
    return purchasePaymentsWithDetails.reduce(
      (acc, x) => {
        const paymentState = x.purchasePayment?.paymentState;
        if (
          paymentState === 'authorized' ||
          paymentState === 'pending' ||
          dateService.getAgeInMonths(x.purchasePayment?.paymentDate) < 1
        ) {
          acc.activePurchasePaymentsWithDetails.push(x);
        } else {
          acc.inactivePurchasePaymentsWithDetails.push(x);
        }
        return acc;
      },
      {
        activePurchasePaymentsWithDetails: [],
        inactivePurchasePaymentsWithDetails: [],
      } as {
        activePurchasePaymentsWithDetails: AggregatedBookingPurchasePaymentWithDetails[];
        inactivePurchasePaymentsWithDetails: AggregatedBookingPurchasePaymentWithDetails[];
      },
    );
  }, [purchasePaymentsWithDetails]);

  return {
    globalState,
    paymentTabModel,
    diversFilterOptions,
    paymentsSettings,
    depositsSettings,
    lastBookingId,
    operationInProgressMessage,
    setOperationInProgressMessage,
    purchasePaymentsWithDetails,
    activePurchasePaymentsWithDetails,
    inactivePurchasePaymentsWithDetails,
  };
}
export type DiverBookingPagePaymentTabLocalState = ReturnType<
  typeof useDiverBookingPagePaymentTabLocalState
>;
