/* eslint-disable @typescript-eslint/no-unused-vars */
import { CreditNote } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React, { useCallback, useMemo, useState } from 'react';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { PurchasePaymentCard } from 'src/pages/_components/company';
import { PurchasePaymentCardCreditNoteActions } from 'src/pages/_components/company/PurchasePayment/PurchasePaymentCard/model';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { AggregatedBookingPurchasePaymentWithDetails } from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { useDiverBookingPagePaymentTabMenuItems } from './useDiverBookingPagePaymentTabMenuItems.hook';

export const DiverBookingPagePaymentTabCard = ({
  purchasePaymentWithDetails,
  globalState,
  dialogs,
  actions,
  index,
  total,
  className,
}: {
  purchasePaymentWithDetails: AggregatedBookingPurchasePaymentWithDetails;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  index: number;
  total: number;
  className?: string;
}) => {
  const loadedDivers = globalState.aggregatedData.divers;

  const purchasePayment = purchasePaymentWithDetails.purchasePayment;
  const creditNotes = purchasePaymentWithDetails.creditNotes;
  const diver = purchasePaymentWithDetails?.diver;

  const isExpandedByDefault = useMemo(() => {
    return (
      purchasePayment.paymentState === 'authorized' ||
      (index < 3 &&
        dateService.getAgeInMonths(
          purchasePayment.paymentDate ?? purchasePayment.creationDate,
        ) < 3)
    );
    // if (mediaSize === 'xxs' || mediaSize === 'xs') {
    //   return false;
    // } else if (mediaSize === 'sm' || mediaSize === 'md') {
    //   return paymentPackages.length <= 1;
    // } else {
    //   return paymentPackages.length <= 3;
    // }
    // return false;
  }, [
    index,
    purchasePayment.creationDate,
    purchasePayment.paymentDate,
    purchasePayment.paymentState,
  ]);
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
  const hamburgerMenuItems: AppHamburgerMenuItem[] =
    useDiverBookingPagePaymentTabMenuItems({
      actions,
      dialogs,
      globalState,
      isExpanded,
      diver,
      purchasePaymentWithDetails,
      setIsExpanded,
    });

  const deleteCreditNote: PurchasePaymentCardCreditNoteActions['deleteCreditNote'] =
    useCallback(
      ({ creditNote }: { creditNote: CreditNote }) => {
        actions.deleteCreditNote({
          deletedCreditNoteId: creditNote._id,
        });
      },
      [actions],
    );

  const editCreditNote: PurchasePaymentCardCreditNoteActions['editCreditNote'] =
    useCallback(
      ({
        purchasePaymentWithDetails,
        creditNote,
      }: {
        purchasePaymentWithDetails: AggregatedBookingPurchasePaymentWithDetails;
        creditNote: CreditNote;
      }) => {
        globalState.dialogsState.bookingCreditNoteEditDialog.openDialog({
          mode: 'edit',
          defaultValue: {
            creditNote,
          },
          purchasePaymentWithDetails,
          aggregatedData: globalState.aggregatedData,
        });
      },
      [
        globalState.aggregatedData,
        globalState.dialogsState.bookingCreditNoteEditDialog,
      ],
    );
  return (
    <PurchasePaymentCard
      className={className}
      purchasePaymentWithDetails={purchasePaymentWithDetails}
      creditNotes={creditNotes}
      loadedDivers={globalState.aggregatedData.divers}
      hamburgerMenuItems={hamburgerMenuItems}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      creditNoteActions={{ deleteCreditNote, editCreditNote }}
      bookingDepositsPaymentDetails={
        purchasePaymentWithDetails.bookingDepositsPaymentDetails
      }
    />
  );
};
