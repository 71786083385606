import { ClubDiver } from '@mabadive/app-common-model';
import React, { FC, useCallback } from 'react';
import {
  AppExpansionPanel,
  AppExpansionPanelProps,
  AppExpansionPanelState,
} from 'src/business/_core/modules/layout/components/AppExpansionPanel';
import { useAppSecurityUserHasRoles } from 'src/business/auth/services';
import { ParticipantFormExpansionPanelId } from '../../../../models';
import {
  DefaultDiveConfigCardAttribute,
  DefaultDiveConfigCardAttributeName,
} from '../../_common/DefaultDiveConfigCard';

const panelId = 'panel4';

export const BookingParticipantEditorDialogEditPanel4: FC<{
  diver: ClubDiver;
  defaultDiveConfigCardDiveAttributes: DefaultDiveConfigCardAttributeName[];
  expandedPanel: ParticipantFormExpansionPanelId;
  expandPanelToogle: (panelId: ParticipantFormExpansionPanelId) => void;
  renderDetails: (state?: AppExpansionPanelState) => JSX.Element;
  className?: string;
}> = ({
  diver,
  defaultDiveConfigCardDiveAttributes,
  expandedPanel,
  expandPanelToogle,
  renderDetails,
  className = '',
}) => {
  const isEditParticipantRole = useAppSecurityUserHasRoles(
    'club-edit-participant-diver',
    'club-edit-participant-booking',
  );
  const isExpanded = expandedPanel === panelId;

  const expandPanel = useCallback(
    () => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      expandPanelToogle(panelId);
    },
    [expandPanelToogle],
  );
  const appExpansionPanelProps: Pick<
    AppExpansionPanelProps,
    'panelId' | 'isExpanded' | 'expandPanel'
  > = {
    panelId,
    isExpanded,
    expandPanel,
  };

  const renderSummaryExtra = () => (
    <div className="w-full flex flex-wrap gap-4">
      {defaultDiveConfigCardDiveAttributes.map((attr) => (
        <DefaultDiveConfigCardAttribute
          key={attr}
          defaultDiveConfig={diver.defaultDiveConfig}
          attributeName={attr}
        />
      ))}
    </div>
  );
  const renderSummaryTitle = () => (
    <div className="flex justify-between">
      <span>Paramètres par défaut des plongées</span>
      {isEditParticipantRole && !isExpanded && (
        <span className="hidden sm:flex text-xs text-gray-400">
          CLIQUER POUR MODIFIER
        </span>
      )}
    </div>
  );
  return (
    <AppExpansionPanel
      isExpandable={isEditParticipantRole}
      className={className}
      {...appExpansionPanelProps}
      renderSummaryTitle={renderSummaryTitle}
      renderSummaryExtra={renderSummaryExtra}
      renderDetails={renderDetails}
    />
  );
};
