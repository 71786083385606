import {
  DiveTrainingReference,
  FirstDiveTrainingReference,
} from '@mabadive/app-common-model';

export type DashboardReportPageMode =
  | 'home'
  | 'first-dives'
  | 'explos'
  | 'trainings';
export const DASHBOARD_REPORT_PAGE_MODES: DashboardReportPageMode[] = [
  'home',
  'first-dives',
  'explos',
  'trainings',
];

// selection or hover
export type DashboardReportPageUIConfigSelection = {
  firstDive?: {
    firstDiveReference?: FirstDiveTrainingReference;
    offerName?: string;
  };
  training?: {
    diveTrainingReference: DiveTrainingReference;
    offerName?: string;
  };
  explo?: {
    certificationReference?: string;
    productPackageOfferReference?: string;
  };
};

export type DashboardReportPageUIConfig = {
  pageMode: DashboardReportPageMode;
  selection?: DashboardReportPageUIConfigSelection;
  hover?: DashboardReportPageUIConfigSelection;
};
