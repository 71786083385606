/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import {
  responsiveAttributesValues,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import {
  appStylesHelper,
  useCacheableClasses,
} from 'src/business/_core/modules/root/theme';
import { CreateClubAccountArea } from './CreateClubAccount';
import { UserLoginArea } from './UserLogin';

export const LoginPage = () => {
  const autoFocus = useAutoFocus();

  const classes = useCacheableClasses({
    cacheKey: 'LoginPage',
    buildStyles,
  });

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >
      <Box flex="500px 0 1" height="auto" display="flex" flexDirection="column">
        <Box component={Paper} p={2} mt={5}>
          <UserLoginArea autoFocus={autoFocus} />
        </Box>
        <Box component={Paper} p={2} mt={5}>
          <CreateClubAccountArea />
        </Box>

        <Box component={Paper} p={2} mt={5}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="primary"
                className={classes.formTitle}
              >
                Ouvrir la fiche
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                Pour connaitre les fonctionnalités de Mabadive ou nous
                contacter:
              </div>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center" mt={2}>
                <Button
                  startIcon={<AppIconsMaterial.features />}
                  variant="contained"
                  color="primary"
                  href={`${appWebConfig.applications.publicWebUrl}/?v-${appWebConfig.appVersion}`}
                >
                  Infos Mabadive
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

function buildStyles(theme: Theme) {
  return makeStyles({
    formTitle: {
      ...responsiveAttributesValues(theme, {
        fontSize: appStylesHelper.getFontSizeResponsive('lg'),
      }),
    },
  });
}
