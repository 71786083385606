import {
  ClubSettingsGeneral,
  RegionalSettings,
  RegionalSettingsCriteria,
} from '@mabadive/app-common-model';
import { regionalSettingsBuilder } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks/useClubResume.hook';

export function useRegionalSettings(
  criteria: RegionalSettingsCriteria & {
    generalSettings?: ClubSettingsGeneral;
  },
): RegionalSettings {
  const clubResume = useClubResume();
  const generalSettings =
    criteria?.generalSettings ?? clubResume?.clubSettings?.general;

  return useMemo(() => {
    if (!criteria) {
      return null;
    }
    const { effectiveDate, countryIsoCode } = criteria;
    const regionalSettings = regionalSettingsBuilder.buildByCountryCode({
      effectiveDate,
      countryIsoCode: countryIsoCode ?? generalSettings?.countryIsoCode,
    });

    return regionalSettings;
  }, [generalSettings?.countryIsoCode, criteria]);
}
