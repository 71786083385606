import { combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  mapTo,
  switchMap,
  tap,
} from 'rxjs/operators';
import { useLoadable } from 'src/business/_core/data/hooks';
import {
  AppProcessesStats,
  processStatsBuilder,
} from 'src/business/_core/process';
import { uiStore } from 'src/business/_core/store';
import {
  authenticationClient,
  authenticationStore,
} from 'src/business/auth/services';
import { useAppTrackingClient } from '../../../layout';
import { appBootstrap, dataBootstrap } from '../../bootstrap';

export function useAppRoot() {
  const trackingClient = useAppTrackingClient();

  return useLoadable({
    debugName: 'useAppRoot',
    // NOTE: ne pas utiliser initialValueFromLoadBootstrap, or it will subscribe twice
    initialValue: {
      loadingStats: {
        processes: 0,
        values: 0,
        running: 0,
        success: 0,
        error: 0,
      },
    },
    load: () => {
      const bootstrap$ = appBootstrap
        .init()
        .pipe(switchMap(() => dataBootstrap.init({ trackingClient })));

      const loadingStats$: Observable<AppProcessesStats> =
        uiStore.runningProcessLoadInitialData
          .get()
          .pipe(
            map((process) =>
              processStatsBuilder.build({ processes: [process] }),
            ),
          );

      return combineLatest(
        [loadingStats$, manageLogoutRequired(), bootstrap$],
        (loadingStats) => ({ loadingStats }),
      );
    },
  });
}

function manageLogoutRequired() {
  return authenticationStore.logoutRequired.get().pipe(
    tap((authenticationRequired) => {
      if (authenticationRequired) {
        authenticationStore.logoutRequired.set(false);
        authenticationClient.logout().subscribe();
      }
    }),
    mapTo(true),
    distinctUntilChanged(),
  );
}
