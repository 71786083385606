/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  ClubStaffMemberRole,
  StaffMemberDailyAvailabilityResume,
} from '@mabadive/app-common-model';
import {
  nameFormatter,
  staffMemberDefaultAvailabilityFinder,
  staffMemberFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { StaffLevelBadge } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export const ClubPlanningLightPanelViewPeriodDayStaffMember: FC<{
  staffFirstNameBefore: boolean;
  staffMember: ClubResumeStaffMember;
  activeToday: boolean;
  availableOrActiveToday: boolean;
  onClickEdit?: () => any;
  date: Date;
  isSelected?: boolean;
  toogleSelect?: () => void;
  staffMemberNameClassName?: string;
  className?: string;
}> = ({
  staffMemberNameClassName,
  staffFirstNameBefore,
  staffMember,
  onClickEdit,
  date,
  isSelected,
  toogleSelect,
  activeToday,
  availableOrActiveToday,
  className = '',
}) => {
  const availability: StaffMemberDailyAvailabilityResume = useMemo(
    () =>
      staffMemberDefaultAvailabilityFinder.getDefaultDailyAvailability(
        staffMember,
        {
          date,
        },
      ),
    [date, staffMember],
  );

  const staffRolesToDisplay: ClubStaffMemberRole[] = useMemo(() => {
    const roles: ClubStaffMemberRole[] = [];
    if (availability?.availableRoles.includes('surface-security')) {
      roles.push('surface-security');
    }
    if (availability?.availableRoles.includes('diving-director')) {
      roles.push('diving-director');
    }
    if (availability?.availableRoles.includes('scuba-diving-instructor')) {
      roles.push('scuba-diving-instructor');
    }
    return roles;
  }, [availability?.availableRoles]);

  return (
    <div
      className={clsx(
        activeToday
          ? 'text-app-blue'
          : availableOrActiveToday
          ? 'text-gray-600'
          : 'text-gray-400',
        (onClickEdit || toogleSelect) && 'cursor-pointer',
        className,
      )}
      onClick={toogleSelect ?? onClickEdit}
    >
      <div className="flex-grow flex gap-x-4 gap-y-1 flex-wrap leading-3">
        <div className="flex gap-x-2">
          {toogleSelect && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                toogleSelect();
              }}
            >
              <input type="checkbox" readOnly={true} checked={isSelected} />
            </div>
          )}
          {onClickEdit && (
            <div>
              <AppHeroIcons.actionEditAlt className="w-8 h-6" />
            </div>
          )}
          <div
            className={clsx(
              'text-sm flex whitespace-nowrap items-start gap-1',
              staffMemberNameClassName,
            )}
          >
            <StaffLevelBadge
              className="mt-0.5 rounded px-0.5 w-9 text-xs flex-shrink-0"
              staffMember={staffMember}
              date={date}
            />
            <div>
              <div className={'truncate font-bold flex-shrink'}>
                {nameFormatter.formatFullName(staffMember.profile, {
                  format: staffFirstNameBefore
                    ? 'firstName-first'
                    : 'lastName-first',
                })}
              </div>
              {staffRolesToDisplay.length > 0 && (
                <div
                  className={clsx(
                    'mt-0.5 text-gray-500 text-app-xxs leading-3 flex gap-1',
                  )}
                >
                  {staffRolesToDisplay.map((staffRoleRef, i) => (
                    <span
                      key={staffRoleRef}
                      className={clsx(
                        'border uppercase rounded text-center px-0.5 py-px sm:py-0.5',
                        'font-bol border-gray-300',
                        // staffRoleRef === 'diving-director'
                        //   ? 'border-red-600'
                        //   : staffRoleRef === 'surface-security'
                        //   ? 'border-green-600'
                        //   : 'border-app-primary',
                        staffRoleRef === 'diving-director'
                          ? 'text-red-600'
                          : staffRoleRef === 'surface-security'
                          ? 'text-green-600'
                          : 'text-app-primary',
                        // staffRoleRef === 'diving-director'
                        //   ? 'border-red-600 text-red-600'
                        //   : staffRoleRef === 'surface-security'
                        //   ? 'border-green-600 text-green-600'
                        //   : 'border-app-primary text-app-primary',
                      )}
                    >
                      {staffMemberFormatter.formatStaffRoleShort(staffRoleRef)}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* {onClickEdit && (
        <div
          className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          onClick={(e) => {
            e.stopPropagation();
            onClickEdit();
          }}
        >
          <AppHeroIcons.actionEditAlt className="w-8 h-6" />
        </div>
      )} */}
    </div>
  );
};
