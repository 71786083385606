import { AppIconsAction } from './AppIconsAction.const';
import { AppIconsDiveEquipment } from './AppIconsDiveEquipment.const';
import { AppIconsSettings } from './AppIconsSettings.const';
import { AppIconsSocialMedia } from './AppIconsSocialMedia.const';
import { AppIconsStatus } from './AppIconsStatus.const';
import { AppIconsUI } from './AppIconsUI.const';

export const AppIcons = {
  socialMedia: AppIconsSocialMedia,
  diveEquipment: AppIconsDiveEquipment,
  ui: AppIconsUI,
  action: AppIconsAction,
  settings: AppIconsSettings,
  status: AppIconsStatus,
};
