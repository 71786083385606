import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppMultiCheckboxesProps } from './AppMultiCheckboxesProps.type';

export function AppMultiCheckboxes<
  T extends string | number,
  L extends string | React.ReactNode = string,
>({
  defaultValue,
  onChange,
  options,
  buildLabel,
  className,
}: AppMultiCheckboxesProps<T, L> & {
  buildLabel?: (label: L) => React.ReactNode;
}) {
  const [state, setState] = React.useState(defaultValue ? defaultValue : []);
  useEffect(() => {
    if (defaultValue) {
      setState(defaultValue); // mise à jour si le formValue à changé via la méthode reset()
    }
  }, [defaultValue]);

  const handleChange =
    (option: ValueLabel<T, L>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const index = state.indexOf(option.value);
      if (index === -1) {
        const newState = state.concat([option.value]).filter((x) => !!x);
        newState.sort();
        setState(newState);
        onChange(newState);
      } else {
        const newState = state
          .filter((value) => value !== option.value)
          .filter((x) => !!x);
        newState.sort();
        setState(newState);
        onChange(newState);
      }
    };

  function isChecked(option: ValueLabel<T, L>) {
    return state.indexOf(option.value) !== -1;
  }

  return (
    <div className={className}>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          control={
            <Checkbox
              checked={isChecked(option)}
              onChange={handleChange(option)}
              value={option.value}
            />
          }
          label={buildLabel ? buildLabel(option.label) : option.label}
        />
      ))}
    </div>
  );
}
