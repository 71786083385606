import React, { useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanSwitchRHF,
} from 'src/lib/form';
import { CustomerSpaceConfigFormModel } from '../model';
import { CustomerSpaceConfigFormCustomerUpdateExpectedDive } from './BookingCustomerConfigEditFormCustomerUpdateExpectedDive';

// note, copié/collé de BookingCustomerConfigEditFormCustomerUpdate
export const CustomerSpaceConfigFormCustomerUpdate = ({
  form,
}: {
  form: UseFormReturn<CustomerSpaceConfigFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isDiversPostalAddressInfoEnabledByDefault =
    clubReference === 'la-bulle-d-her-noirmoutier-27455';

  const formPrefix =
    'clubSettings.publicSettings.customerSpace.defaultBookingConfig' as const;

  const [
    customerUiEnableCustomerUpdate,
    customerUiPendingListDiversContactInfo,
    customerUiPendingListDiversDivingInfo,
    customerUiPendingListDiversDiversPostalAddressInfo,
    customerUiPendingListDiversExpectedDiveInfo,
  ] = useWatch({
    control,
    name: [
      `${formPrefix}.customerUi.enableCustomerUpdate`,
      `${formPrefix}.pendingList.diversContactInfo`,
      `${formPrefix}.pendingList.diversDivingInfo`,
      `${formPrefix}.pendingList.diversPostalAddressInfo`,
      `${formPrefix}.pendingList.diversExpectedDiveInfo`,
    ],
  });

  const customerUpdateRequested =
    customerUiPendingListDiversContactInfo ||
    customerUiPendingListDiversDivingInfo ||
    customerUiPendingListDiversDiversPostalAddressInfo ||
    customerUiPendingListDiversExpectedDiveInfo;
  useEffect(() => {
    if (!customerUpdateRequested && customerUiEnableCustomerUpdate) {
      setValue(`${formPrefix}.customerUi.enableCustomerUpdate`, null);
    }
  });

  return (
    <>
      <AppFormControlRHF_Deprecated
        className={'w-full'}
        label="Informations clients"
        helpDescription="Demandez à vos clients de renseigner eux-même les informations dont vous avez besoin."
        control={control}
        name={`${formPrefix}.customerUi.enableCustomerUpdate`}
        renderComponent={(props) => (
          <AppInputBooleanSwitchRHF
            {...props}
            theme="danger"
            label="à renseigner depuis l'espace client"
            onChange={(value) => {
              if (value && !customerUpdateRequested) {
                setValue(`${formPrefix}.pendingList.diversContactInfo`, true);
                setValue(`${formPrefix}.pendingList.diversDivingInfo`, true);
                setValue(
                  `${formPrefix}.pendingList.diversPostalAddressInfo`,
                  isDiversPostalAddressInfoEnabledByDefault,
                );
                setValue(
                  `${formPrefix}.pendingList.diversExpectedDiveInfo`,
                  false,
                );
              }
            }}
          />
        )}
      />
      {customerUiEnableCustomerUpdate && (
        <div>
          <p className="mt-5 mb-2 text-gray-500 text-xs uppercase">
            Informations à renseigner par le client:
          </p>
          <AppFormControlRHF_Deprecated
            className="my-1 w-full xs:col-span-6 sm:col-span-6"
            control={control}
            name={`${formPrefix}.pendingList.diversContactInfo`}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                className="w-full"
                {...props}
                label={'coordonnees des participants'}
                label2={'Nom, prénom, téléphone...'}
                onChange={(value) => {
                  if (value) {
                    setValue(
                      `${formPrefix}.customerUi.enableCustomerUpdate`,
                      value,
                    );
                  }
                }}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="my-1 w-full xs:col-span-6 sm:col-span-6"
            control={control}
            name={`${formPrefix}.pendingList.diversDivingInfo`}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                className="w-full"
                {...props}
                label={'caractéristiques des plongeurs'}
                label2={'Niveau, dernière plongée, certificat médical...'}
                onChange={(value) => {
                  if (value) {
                    setValue(
                      `${formPrefix}.customerUi.enableCustomerUpdate`,
                      value,
                    );
                  } else {
                    setValue(
                      `${formPrefix}.pendingList.diversExpectedDiveInfo`,
                      false,
                    );
                  }
                }}
              />
            )}
          />
          {customerSettings?.general?.homeAddress && (
            <AppFormControlRHF_Deprecated
              className="my-1 w-full xs:col-span-6 sm:col-span-6"
              control={control}
              name={`${formPrefix}.pendingList.diversPostalAddressInfo`}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF
                  className="w-full"
                  {...props}
                  label={'adresse postale'}
                  // label2={`Baptềme, explo...`}
                  onChange={(value) => {
                    if (value) {
                      setValue(
                        `${formPrefix}.customerUi.enableCustomerUpdate`,
                        value,
                      );
                    }
                  }}
                />
              )}
            />
          )}
          <CustomerSpaceConfigFormCustomerUpdateExpectedDive form={form} />
        </div>
      )}
    </>
  );
};
