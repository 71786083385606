import {
  DiveSessionAccommodationPickupsTimes,
  JsonPatchOperation,
  SessionPickup,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { AppButton, AppLoaderSpinner } from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { AccommodationPickupCard } from './AccommodationPickupCard';
import {
  AccommodationPickupForm,
  AccommodationPickupFormModel,
} from './AccommodationPickupForm';

export const SessionsPickupCard = ({
  sessionPickup,
  mode: defaultMode,
  className,
}: {
  sessionPickup: SessionPickup;
  mode: 'list' | 'resume';
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const [mode, setMode] = useState<'list' | 'resume'>(defaultMode);
  const [editMode, setEditMode] = useState<'view' | 'edit-times'>('view');
  const [operationInProgress, setOperationInProgress] =
    useState<boolean>(false);

  const { accommodationsPickups, sessionTime } = sessionPickup;

  const isResaFlottante =
    sessionTime.getUTCHours() === 0 && sessionTime.getUTCMinutes() === 0;

  const az = useAppSecurityUserClubAuthorizations();

  const persistChanges = useCallback(
    async ({
      formValue,
      defaultValues,
    }: {
      formValue: AccommodationPickupFormModel;
      defaultValues: AccommodationPickupFormModel;
    }) => {
      const accommodationPickupsTimes: DiveSessionAccommodationPickupsTimes =
        {};

      for (const accommodationsPickup of formValue.accommodationsPickups) {
        const {
          pickupTimeHoursMinutes: { hours, minutes },
          accommodationId,
        } = accommodationsPickup;
        const pickupTime = dateService.getUTCDateSetTime(
          sessionTime,
          hours,
          minutes,
        );
        accommodationPickupsTimes[accommodationId] = pickupTime;
      }

      const patchOperation: JsonPatchOperation = {
        op: 'replace',
        path: '/details/accommodationPickupsTimes',
        value: accommodationPickupsTimes,
      };

      try {
        setOperationInProgress(true);
        const sessionsIds = sessionPickup.sessionsIds;
        await clubMassiveUpdatorClient.update({
          updatedDiveSessions: sessionsIds.map((pk) => ({
            pk,
            patchOperations: [patchOperation],
          })),
        });
      } finally {
        setOperationInProgress(null);
      }
    },
    [sessionPickup.sessionsIds, sessionTime],
  );

  return (
    <div
      className={clsx('flex flex-col gap-0.5 cursor-pointer', className)}
      onClick={() => setMode(mode === 'list' ? 'resume' : 'list')}
    >
      <div className="my-1 font-bold">
        Pickup
        {!isResaFlottante &&
          ` de ${dateService.formatUTC(sessionTime, 'HH:mm')}`}
      </div>
      {editMode === 'view' && (
        <div className={'flex flex-col gap-0.5'}>
          {accommodationsPickups.map((accommodationPickup, j) => (
            <AccommodationPickupCard
              key={j}
              mode={mode}
              accommodationPickup={accommodationPickup}
              isResaFlottante={isResaFlottante}
            />
          ))}
        </div>
      )}{' '}
      {editMode === 'edit-times' && (
        <AccommodationPickupForm
          sessionPickup={sessionPickup}
          onCancel={() => {
            setEditMode('view');
          }}
          onConfirm={({
            formValue,
            defaultValues,
          }: {
            formValue: AccommodationPickupFormModel;
            defaultValues: AccommodationPickupFormModel;
          }) => {
            setEditMode('view');
            persistChanges({
              formValue,
              defaultValues,
            });
          }}
        />
      )}
      {operationInProgress && (
        <AppLoaderSpinner className="ml-2 inline-block w-6 h-6" />
      )}
      {!operationInProgress &&
        editMode === 'view' &&
        mode === 'list' &&
        az?.edit?.planning?.session && (
          <AppButton
            type="button"
            fullWidth={true}
            className=" xs:max-w-[12rem]"
            color="primary-outline-light"
            icon={AppIconsAction.edit}
            // iconPosition="right"
            onClick={() => {
              setEditMode('edit-times');
            }}
          >
            Modifier les horaires
          </AppButton>
        )}
    </div>
  );
};
