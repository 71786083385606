/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import {
  AppButton,
  AppFormAutoSubmit,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubSettingsOnlineBookingDialogActionsBar } from './ClubSettingsOnlineBookingDialogActionsBar';

import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { ClubDialogsState } from '../../../../../_dialogs';
import {
  ClubSettingsOnlineBookingDialogState,
  ClubSettingsOnlineBookingFormModel,
} from '../_model';
import { ClubSettingsOnlineBookingDialogForm } from './ClubSettingsOnlineBookingDialogForm';
import {
  ClubSettingsOnlineBookingDialogLocalState,
  useClubSettingsOnlineBookingDialogLocalState,
} from './useClubSettingsOnlineBookingDialogLocalState.hook';
import { useClubSettingsOnlineBookingDialogSubmit } from './useClubSettingsOnlineBookingDialogSubmit';

export const ClubSettingsOnlineBookingDialog: FC<
  ClubSettingsOnlineBookingDialogState & {
    dialogsState: ClubDialogsState;
  }
> = (inputState) => {
  const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] = useState(false);
  const globalClasses = useGlobalClasses();

  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const clubReference = clubResume?.reference;

  const {
    isOpen,
    initialState,
    dialogsState,
    onCancel,
    onConfirm,
    openDialog,
    closeDialog,
  } = inputState;

  const { defaultValue } = initialState;

  const localState: ClubSettingsOnlineBookingDialogLocalState =
    useClubSettingsOnlineBookingDialogLocalState({
      initialState,
      dialogsState,
    });

  const { form } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue: ClubSettingsOnlineBookingFormModel = watch();

  const submitForm = useClubSettingsOnlineBookingDialogSubmit({
    handleSubmit,
    localState,
    inputState,
  });

  const hasChanges = form.formState.isDirty;

  return !isOpen ? null : (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={() => (
        <ClubSettingsOnlineBookingDialogActionsBar
          className="sm:hidden"
          onCancel={onCancel}
          submitForm={submitForm}
        />
      )}
    >
      <div className=" app-p-content">
        {/* <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
          Espace client
        </h3> */}

        <AppFormAutoSubmit onSubmit={submitForm}>
          <ClubSettingsOnlineBookingDialogForm localState={localState} />
          <div className="hidden sm:block sticky -bottom-8 pt-8 app-card">
            <div className="app-p-content flex gap-4 justify-start">
              <AppButton
                size="normal"
                icon={AppIcons.action.cancel}
                tabIndex={500}
                color="gray-outline-light"
                onClick={() => {
                  onCancel();
                }}
              >
                Annuler
              </AppButton>
              <AppButton
                type="submit"
                size="normal"
                icon={AppIcons.action.save}
                color="primary-bg"
                onClick={() => {
                  submitForm();
                }}
              >
                Confirmer
              </AppButton>
            </div>
          </div>
        </AppFormAutoSubmit>
      </div>
    </AppPageContainerWithFixedBars>
  );
};
