/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubDataPurgeOperation,
  ClubDataPurgePayload,
  MassiveFakeDataGeneratorConfig,
} from '@mabadive/app-common-model';
import { useCallback, useMemo, useState } from 'react';
import { fakerClient } from 'src/business/_core/data/app-operation';
import { uiStore } from 'src/business/_core/store';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

import { apiClient } from 'src/_common-browser';

import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { DataManagementFetchCriteria } from './model';
import { useDataManagementFetchStats } from './useDataManagementFetchStats.hook';
import { useDataManagementPageViewParameters } from './useDataManagementPageViewParameters';

export function useDataManagementPageLocalState() {
  const { viewParameters, setViewParameters } =
    useDataManagementPageViewParameters();

  const { beginDate, endDate } = viewParameters.periodRange.value;

  const [lastFetchTriggerDate, setLastFetchTriggerDate] = useState<Date>(
    new Date(),
  );

  const fetchCriteria: DataManagementFetchCriteria = useMemo(
    () => ({
      beginDate,
      endDate,
      lastFetchTriggerDate,
    }),
    [beginDate, endDate, lastFetchTriggerDate],
  );

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  // const query: UseQueryResult<DataManagementFetchResults, unknown> = useQuery({
  //   queryKey: [
  //     'data-management',
  //     { clubReference, diveCenterId, fetchCriteria },
  //   ],
  //   queryFn: async ({ queryKey }) => {
  //     const q1 = buildDashboardBookingsOverPeriodCleanAnalysisFilterDatesQuery({
  //       clubReference,
  //       diveCenterId,
  //       fetchCriteria,
  //     });
  //     const q2 = buildDashboardBookingsOverPeriodCleanPeriodFilterDatesQuery({
  //       clubReference,
  //       diveCenterId,
  //       fetchCriteria,
  //     });
  //     const results: DataManagementFetchResults = await graphqlClient.query
  //       .runMany<any>([q1, q2], {
  //         type: 'query',
  //         name: 'dataManagement',
  //       })
  //       .pipe(
  //         map((res) => {
  //           const x: DataManagementFetchResults = {
  //             ...res,
  //             criteria: fetchCriteria,
  //           };
  //           return x;
  //         }),
  //       )
  //       .toPromise();

  //     return results;
  //   },
  // });
  // const { isLoading: loadingInProgress, data: fetchedResults } = query;

  const { loadingInProgress: loadingInProgressStats, fetchResult } =
    useDataManagementFetchStats({
      period: { beginDate, endDate },
    });

  // const {
  //   criteria,
  //   bookingsCleanAnalysisOverPeriod,
  //   bookingsCleanPeriodOverPeriod,
  // } = fetchedResults;

  const reload = useCallback(() => {
    setLastFetchTriggerDate(new Date());
  }, []);

  const [mode, setMode] =
    useState<'default' | 'confirm-delete-data'>('default');

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const [isPersistInProgress, setIsPersistInProgress] = useState(false);
  const [purgeConfig, setPurgeConfig] = useState<ClubDataPurgePayload>({
    deleteParticipants: false,
    deleteDiveSessions: false,
    deleteDivers: false,
  });

  const massiveDataGeneratorConfig: MassiveFakeDataGeneratorConfig = {
    bookingsCount: 25,
    beginDate: {
      minDayOffset: -14,
      maxDayOffset: 14,
    },
    sessions: {
      minWeeks: 1,
      maxWeeks: 5,
      percentageVirtualSessionCreation: 70,
      minCount: 4,
      maxCount: 20,
      minCountPerBooking: 1,
      maxCountPerBooking: 10,
    },
    divers: {
      minCount: 5,
      maxCount: 10,
    },
  };

  const securityUser = useAppSecurityUser();

  const isDataPurgeEnabled = useMemo(() => {
    if (securityUser?.roles.includes('club-data-purge')) {
      // const cleanName = referenceBuilder.buildCleanReference(clubResume.name);
      // const isRecent =
      //   dateService.add(clubResume._createdAt, -1, 'week').getTime() >
      //   new Date().getTime();
      // if (
      //   isRecent ||
      //   cleanName.includes('demo') ||
      //   cleanName.includes('paradise') ||
      //   cleanName.includes('zzz')
      // ) {
      //   return true;
      // }
      return true;
    }
  }, [securityUser?.roles]);

  const isDataGenerationEnabled = useMemo(() => {
    if (securityUser?.roles.includes('super-admin')) {
      return true;
      // const cleanName = referenceBuilder.buildCleanReference(clubResume.name);
      // const isRecent =
      //   dateService.add(clubResume._createdAt, -1, 'week').getTime() >
      //   new Date().getTime();
      // if (
      //   isRecent ||
      //   cleanName.includes('demo') ||
      //   cleanName.includes('paradise') ||
      //   cleanName.includes('zzz')
      // ) {
      //   return true;
      // }
    }
  }, [securityUser?.roles]);

  async function purgeData() {
    setIsPersistInProgress(true);
    try {
      const operation: ClubDataPurgeOperation = {
        id: 'club.data.purge',
        payload: purgeConfig,
      };

      await apiClient
        .post<any>('/operations', {
          authenticate: true,
          json: {
            operations: [operation],
          },
        })
        .toPromise();
      uiStore.snackbarMessage.set({
        type: 'success',
        content: 'Donnée purgée!',
      });
      setMode('default');
    } catch (err) {
      uiStore.snackbarMessage.set({
        type: 'error',
        content: 'Erreur innatendue.',
      });
    } finally {
      setIsPersistInProgress(false);
      await reload();
    }
  }

  async function generateData() {
    setIsPersistInProgress(true);
    try {
      await fakerClient.generateMassiveData(massiveDataGeneratorConfig);
      uiStore.snackbarMessage.set({
        type: 'success',
        content: 'Donnée générée!',
      });
      setMode('default');
    } catch (err) {
      uiStore.snackbarMessage.set({
        type: 'error',
        content: 'Erreur innatendue.',
      });
    } finally {
      setIsPersistInProgress(false);

      await reload();
    }
  }
  return {
    dialogsState,
    state: {
      mode,
      setMode,
      isPersistInProgress,
      setIsPersistInProgress,
      isDataGenerationEnabled,
      isDataPurgeEnabled,
      fetchCriteria,
      viewParameters,
      setViewParameters,
      loadingInProgress: loadingInProgressStats,
      purgeConfig,
      setPurgeConfig,
    },
    data: {
      fetchResult,
    },
    actions: {
      purgeData,
      generateData,
      reload,
    },
  };
}

export type DataManagementPageLocalState = ReturnType<
  typeof useDataManagementPageLocalState
>;
