import {
  AppEntityUpdatePatch,
  ClubAuthUserProfile,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';

export const clubSettingsUserProfilePersister = {
  createUserProfile,
  updateUsertProfile,
  deleteUserProfile,
};

async function createUserProfile({
  formValue,
}: {
  formValue: ClubAuthUserProfile;
}) {
  const payload: ProMultiOperationPayload = {
    logContext: 'create user profile',
    clubAuthUserProfiles: {
      created: [formValue],
    },
  };
  await clubMassiveUpdatorClient.update(payload);
}

async function deleteUserProfile({ clubProfileId }: { clubProfileId: string }) {
  const payload: ProMultiOperationPayload = {
    logContext: 'delete user profile',
    clubAuthUserProfiles: {
      deletedIds: [clubProfileId],
    },
  };
  await clubMassiveUpdatorClient.update(payload);
}

async function updateUsertProfile({
  initialValue,
  formValue,
}: {
  initialValue: ClubAuthUserProfile;
  formValue: ClubAuthUserProfile;
}) {
  // edit
  const patchOperations = jsonPatcher.compareObjects(
    initialValue,
    formValue,
    {},
  );
  if (patchOperations.length) {
    const patch: AppEntityUpdatePatch = {
      pk: initialValue._id,
      patchOperations,
    };
    const payload: ProMultiOperationPayload = {
      logContext: 'update user profile',
      clubAuthUserProfiles: {
        updated: [patch],
      },
    };
    await clubMassiveUpdatorClient.update(payload);
  }
}
