import {
  phoneLinkBuilder,
  smsLinkBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  PRO_BookingMemberFull,
  PRO_BookingResume,
} from '../../DiverBookingPage/models';
import { DiveCenterBookingMemberNameLabel } from './DiveCenterBookingMemberNameLabel';
import { DiveCenterBookingMembersListDiffItemCardDetails } from './DiveCenterBookingMembersListDiffItemCardDetails';

export function DiveCenterBookingMembersListDiffItemCard({
  aggregatedBookingResume,
  bookingMemberFull,
  className = '',
}: {
  aggregatedBookingResume: PRO_BookingResume;
  bookingMemberFull: PRO_BookingMemberFull;
  className?: string;
}) {
  const diver = bookingMemberFull?.diver;

  const phoneNumberClean = useMemo(
    () => smsLinkBuilder.cleanPhoneNumber(diver?.phoneNumber),
    [diver?.phoneNumber],
  );
  const emailAddress = useMemo(
    () => diver?.emailAddress?.trim(),
    [diver?.emailAddress],
  );

  return !bookingMemberFull ? null : (
    <div className={className}>
      <div className="w-full flex gap-x-2 justify-between">
        <div className="w-full flex gap-y-1 gap-x-2 justify-between">
          <div className={'flex flex-col gap-x-2 flex-wrap'}>
            <DiveCenterBookingMemberNameLabel
              bookingMemberFull={bookingMemberFull}
              isUniqueMember={
                aggregatedBookingResume.bookingMembers.length === 1
              }
            />
          </div>

          <div>
            <div className={'flex flex-col gap-1 items-stretch'}>
              {phoneNumberClean && (
                <AppButton
                  fullWidth={true}
                  className="text-xs whitespace-nowrap"
                  icon={AppHeroIcons.phone}
                  color={'phone-outline'}
                  href={`tel:${phoneNumberClean}`}
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span className="">
                    {phoneLinkBuilder.formatPhoneNumber(phoneNumberClean)}
                  </span>
                </AppButton>
              )}
              {emailAddress && (
                <AppButton
                  className="text-xs whitespace-nowrap"
                  color={'mail-outline'}
                  icon={AppHeroIcons.contact}
                  href={`mailto:${emailAddress}`}
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                  target="mabadive_mail_client"
                >
                  <span className="">{emailAddress}</span>
                </AppButton>
              )}
            </div>
          </div>
          <DiveCenterBookingMembersListDiffItemCardDetails
            className="mt-2"
            aggregatedBookingResume={aggregatedBookingResume}
            bookingMemberFull={bookingMemberFull}
          />
        </div>
      </div>
    </div>
  );
}
