import { EquipmentDescription } from '@mabadive/app-common-model';
import Tippy from '@tippyjs/react';
import React from 'react';
import { AppIconsDiveEquipment } from 'src/business/_core/modules/layout/icons';

export const ParticipantRebreather = ({
  rebreather,
  mode = 'default',
  iconClass,
  className,
}: {
  rebreather?: EquipmentDescription;
  mode?: 'default' | 'badge';
  iconClass?: string;
  className?: string;
}) => {
  return !!rebreather ? (
    <div className={`flex gap-x-1 ${className}`}>
      <div
        className={`font-bold ${
          mode === 'default'
            ? rebreather.selfEquipped
              ? 'text-green-600'
              : 'text-app-primary'
            : rebreather.selfEquipped
            ? 'text-green-200 app-text-shadow-sm shadow-red-600'
            : 'text-blue-200 app-text-shadow-sm shadow-red-600'
        }`}
      >
        <Tippy
          placement="top"
          content={`Recycleur ${
            rebreather.selfEquipped ? 'PERSO' : rebreather.model1?.ref ?? 'CLUB'
          }`}
        >
          <AppIconsDiveEquipment.rebreather
            className={iconClass ?? 'h-5 w-5'}
          />
        </Tippy>
      </div>
    </div>
  ) : null;
};
