import {
  EquipmentDescription,
  EquipmentType,
} from '@mabadive/app-common-model';
import {
  equipmentDescriptionFormatter,
  equipmentFormatter,
} from '@mabadive/app-common-services';
import React from 'react';

export const ClubParticipantCardAttributeEquipment = ({
  equipment,
  equipmentType,
  format,
  className = '',
}: {
  equipment: EquipmentDescription;
  equipmentType: EquipmentType;
  format: 'short' | 'long';
  className?: string;
}) => {
  return (
    <div className={className}>
      <h3 className="data-label text-gray-600 font-bold max-w-full">
        {equipmentFormatter.formatEquipmentType(equipmentType)}
      </h3>
      <div className="data-value text-app-blue max-w-full truncate">
        {equipmentDescriptionFormatter.formatEquipmentDescriptions({
          equipment,
          format: format === 'short' ? 'short' : 'long',
        })}
      </div>
    </div>
  );
};
