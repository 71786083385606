import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  ClubSettingsUIPlanning,
  DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { StaffRoleBadge } from '../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components';

export function ClubPlanningParticipantsConfigDialogFormStaffCounters<
  T extends ClubSettingsUIPlanning,
>({
  settingsUiPlanning,
  setSettingsUiPlanning,
}: {
  settingsUiPlanning: T;
  setSettingsUiPlanning: (settingsUiPlanning: T) => void;
}) {
  const onToogleShowStaffCountsPerDay = useCallback(() => {
    if (settingsUiPlanning.showStaffCountsPerDay) {
      // disable all
      setSettingsUiPlanning({
        ...settingsUiPlanning,
        showStaffCountsPerDay: false,
        showStaffCountsPerDayDP: false,
        showStaffCountsPerDayGP: false,
        showStaffCountsPerDaySS: false,
      });
    } else {
      // enable default
      setSettingsUiPlanning({
        ...settingsUiPlanning,
        showStaffCountsPerDay: true,
        // à l'activation, on définit les paramètres par défaut d'un nouveau club
        showStaffCountsPerDayDP:
          DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT.showStaffCountsPerDayDP,
        showStaffCountsPerDayGP:
          DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT.showStaffCountsPerDayGP,
        showStaffCountsPerDaySS:
          DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT.showStaffCountsPerDaySS,
      });
    }
  }, [setSettingsUiPlanning, settingsUiPlanning]);

  const enabledCount = useMemo(() => {
    let count = 0;

    if (settingsUiPlanning.showStaffCountsPerDayDP) {
      count++;
    }
    if (settingsUiPlanning.showStaffCountsPerDayGP) {
      count++;
    }
    if (settingsUiPlanning.showStaffCountsPerDaySS) {
      count++;
    }

    return count;
  }, [
    settingsUiPlanning.showStaffCountsPerDayDP,
    settingsUiPlanning.showStaffCountsPerDayGP,
    settingsUiPlanning.showStaffCountsPerDaySS,
  ]);

  return (
    <Disclosure defaultOpen={settingsUiPlanning.showStaffCountsPerDay}>
      {({ open }) => (
        <div className={clsx(open && 'bg-gray-100')}>
          <Disclosure.Button
            className={clsx(
              'flex w-full justify-between rounded-lg text-left text-sm font-medium',
              open &&
                'hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75',
            )}
          >
            <div onClick={(e) => e.stopPropagation()}>
              <AppInputBooleanSwitch
                value={settingsUiPlanning.showStaffCountsPerDay}
                label={
                  <div className="flex gap-2 justify-center items-center">
                    <StaffRoleBadge
                      className="px-0.5 md:px-1 text-xs "
                      role={{
                        enabled: true,
                        label: '5 GP',
                        staffRoleRef: 'scuba-diving-instructor',
                        active: true,
                        assigned: true,
                      }}
                    />
                    <div>
                      Compteur quotidien{' '}
                      {settingsUiPlanning.showStaffCountsPerDay && (
                        <span className="text-gray-400">
                          ({enabledCount}/3)
                        </span>
                      )}
                    </div>
                  </div>
                }
                onChange={onToogleShowStaffCountsPerDay}
              />
            </div>
            {settingsUiPlanning.showStaffCountsPerDay && (
              <div className="flex gap-2 text-gray-600 ">
                <span>Configuration avancée</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-gray-500`}
                />
              </div>
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            {settingsUiPlanning.showStaffCountsPerDay && (
              <div className="mb-3 flex flex-col gap-2">
                <AppInputBooleanSwitch
                  value={settingsUiPlanning.showStaffCountsPerDaySS}
                  label={
                    <div className="flex gap-2 justify-center items-center">
                      <StaffRoleBadge
                        className="px-0.5 md:px-1 text-xs "
                        role={{
                          enabled: true,
                          label: '2 SS',
                          staffRoleRef: 'surface-security',
                          active: true,
                          assigned: true,
                        }}
                      />
                      <div>Sécurité surface</div>
                    </div>
                  }
                  onChange={() => {
                    setSettingsUiPlanning({
                      ...settingsUiPlanning,
                      showStaffCountsPerDaySS:
                        !settingsUiPlanning.showStaffCountsPerDaySS,
                    });
                  }}
                />
                <AppInputBooleanSwitch
                  value={settingsUiPlanning.showStaffCountsPerDayDP}
                  label={
                    <div className="flex gap-2 justify-center items-center">
                      <StaffRoleBadge
                        className="px-0.5 md:px-1 text-xs "
                        role={{
                          enabled: true,
                          label: '3 DP',
                          staffRoleRef: 'diving-director',
                          active: true,
                          assigned: true,
                        }}
                      />
                      <div>Directeur de plongée</div>
                    </div>
                  }
                  onChange={() => {
                    setSettingsUiPlanning({
                      ...settingsUiPlanning,
                      showStaffCountsPerDayDP:
                        !settingsUiPlanning.showStaffCountsPerDayDP,
                    });
                  }}
                />
                <AppInputBooleanSwitch
                  value={settingsUiPlanning.showStaffCountsPerDayGP}
                  label={
                    <div className="flex gap-2 justify-center items-center">
                      <StaffRoleBadge
                        className="px-0.5 md:px-1 text-xs "
                        role={{
                          enabled: true,
                          label: '5 GP',
                          staffRoleRef: 'scuba-diving-instructor',
                          active: true,
                          assigned: true,
                        }}
                      />
                      <div>Guide de palanquée</div>
                    </div>
                  }
                  onChange={() => {
                    setSettingsUiPlanning({
                      ...settingsUiPlanning,
                      showStaffCountsPerDayGP:
                        !settingsUiPlanning.showStaffCountsPerDayGP,
                    });
                  }}
                />
              </div>
            )}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
