/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import { FieldArrayWithId, UseFormReturn, useWatch } from 'react-hook-form';
import { AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import {
  ClubProductPackageAttributesDiveType,
  DIVE_MODES,
  DiveMode,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { ClubSettingsProductOffersFormModel } from '../../_model';

const ALLOWED_DIVE_MODES: DiveMode[] = [
  // 'first-dive',
  'supervised',
  'autonomous',
  // 'free-dive',
  // 'snorkeling',
  // 'snorkelingSupervised',
  'autoSupervised',
  // 'observer',
  'instructor',
];

export const ClubSettingsProductOffersFormOfferExplo_DiveMode = ({
  form,
  offerField,
  offerFieldIndex,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const [divePriceType, divesCountInfinite, divesCount] = useWatch({
    control,
    name: [
      `offers.${i}.productPackage.diveAttributes.divePriceType`,
      `offers.${i}.productPackage.diveAttributes.divesCountInfinite`,
      `offers.${i}.productPackage.diveAttributes.divesCount`,
    ],
  });

  // TODO plus tard : on pourrait améliorer ça en filtrant par clubResume.serviceTypes,
  // mais il faudrait que celui-ci soit éditable depuis la même page, pour ce qui concerne cette page
  const diveModeOptions = useMemo(
    () =>
      DIVE_MODES.filter((x) => ALLOWED_DIVE_MODES.includes(x)).map(
        (diveMode) => {
          const option: ValueLabel<DiveMode> = {
            label: diveModeFormatter
              .formatDiveMode(diveMode, { format: 'short-ref-label' })
              .toUpperCase(),
            value: diveMode,
          };
          return option;
        },
      ),
    [],
  );

  const onDiveModeChanges = useCallback(
    (diveMode: DiveMode) => {
      if (divePriceType === 'single' && divesCount !== 1) {
        setValue(`offers.${i}.productPackage.diveAttributes.divesCount`, 1, {
          shouldDirty: true,
          shouldTouch: true,
        });
      }
      if (divePriceType === 'single' && divesCountInfinite) {
        setValue(
          `offers.${i}.productPackage.diveAttributes.divesCountInfinite`,
          false,
          {
            shouldDirty: true,
            shouldTouch: true,
          },
        );
      }

      switch (diveMode) {
        case 'instructor': {
          setValue(
            `offers.${i}.productPackage.diveAttributes.diveType`,
            'scuba' as ClubProductPackageAttributesDiveType,
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );
          if (!divesCount) {
            setValue(
              `offers.${i}.productPackage.diveAttributes.divesCount`,
              1,
              {
                shouldDirty: true,
                shouldTouch: true,
              },
            );
            setValue(
              `offers.${i}.productPackage.diveAttributes.divesCountInfinite`,
              false,
              {
                shouldDirty: true,
                shouldTouch: true,
              },
            );
          }
          setValue(
            `offers.${i}.productPackage.diveAttributes.equipment`,
            'any-equipment',
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );
          setValue(
            `offers.${i}.productPackage.diveAttributes.supervision`,
            'autonomous',
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );
          break;
        }
        case 'autoSupervised': {
          setValue(
            `offers.${i}.productPackage.diveAttributes.diveType`,
            'scuba' as ClubProductPackageAttributesDiveType,
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );
          if (!divesCount) {
            setValue(
              `offers.${i}.productPackage.diveAttributes.divesCount`,
              1,
              {
                shouldDirty: true,
                shouldTouch: true,
              },
            );
            setValue(
              `offers.${i}.productPackage.diveAttributes.divesCountInfinite`,
              false,
              {
                shouldDirty: true,
                shouldTouch: true,
              },
            );
          }
          setValue(
            `offers.${i}.productPackage.diveAttributes.equipment`,
            'any-equipment',
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );
          // setValue(`offers.${i}.productPackage.diveAttributes.supervision`, 'auto', {
          //   shouldDirty: true,
          //   shouldTouch: true,
          // });
          break;
        }
        case 'autonomous':
        case 'supervised': {
          // set
          setValue(
            `offers.${i}.productPackage.diveAttributes.diveType`,
            'scuba' as ClubProductPackageAttributesDiveType,
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );

          setValue(
            `offers.${i}.productPackage.diveAttributes.supervision`,
            diveMode === 'supervised' ? 'supervised' : 'autonomous',
            {
              shouldDirty: true,
              shouldTouch: true,
            },
          );

          if (!divesCount) {
            setValue(
              `offers.${i}.productPackage.diveAttributes.divesCount`,
              1,
              {
                shouldDirty: true,
                shouldTouch: true,
              },
            );
            setValue(
              `offers.${i}.productPackage.diveAttributes.divesCountInfinite`,
              false,
              {
                shouldDirty: true,
                shouldTouch: true,
              },
            );
          }
          break;
        }
      }
    },
    [divePriceType, divesCount, divesCountInfinite, i, setValue],
  );

  return (
    <AppFormControlRHF_Deprecated
      label="Prestation"
      className={'w-full col-span-2'}
      control={control}
      name={`offers.${i}.productPackage.diveAttributes.diveMode`}
      required={true}
      renderComponent={(props) => (
        <AppSingleAutocomplete2RHF
          {...props}
          options={diveModeOptions}
          onChange={(diveMode) => onDiveModeChanges(diveMode)}
        />
      )}
    />
  );
};
