import { BookingDepositState } from '@mabadive/app-common-model';
import { bookingDepositStateFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { AppIcons } from '../../../../business/_core/modules/layout/icons';
import {
  BookingDepositStatusColors,
  useBookingDepositStatusColors,
} from '../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingDepositCard/useBookingDepositStatusColors.hook';

export function DepositStateIconBadge({
  depositState,
  purchasePaymentPending,
  showLabel,
  className,
  iconClassName,
}: {
  depositState: BookingDepositState;
  purchasePaymentPending?: boolean;
  showLabel?: boolean;
  className?: string;
  iconClassName?: string;
}) {
  const statusColors: BookingDepositStatusColors =
    useBookingDepositStatusColors({ depositState });

  const Icon =
    depositState === 'draft'
      ? AppIcons.ui.statusPending
      : depositState === 'active'
      ? AppIcons.ui.statusAuthorized
      : depositState === 'used'
      ? AppIcons.ui.statusValidated
      : depositState === 'archived'
      ? AppIcons.ui.statusArchived
      : null;

  return (
    <div className={clsx(statusColors.textLight, className)}>
      {Icon ? <Icon className={clsx('inline-block', iconClassName)} /> : null}
      {showLabel &&
        bookingDepositStateFormatter.formatDepositState(depositState, {
          isPaymentPending: purchasePaymentPending,
        })}
    </div>
  );
}
