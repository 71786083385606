import React from 'react';
import { Control, Path, useController } from 'react-hook-form';
import { AppComboBoxMultiple } from './AppComboBoxMultiple';
import { AppComboBoxMultipleProps } from './AppComboBoxMultipleProps.type';

export function AppComboBoxMultipleRHF<T extends string | number, F>({
  control,
  name,
  ...extraProps
}: Omit<AppComboBoxMultipleProps<T>, 'value' | 'onChange'> & {
  control: Control<F>;
  name: Path<F>;
  className?: string;
}) {
  const {
    field: { ref, onChange: onChangeRHF, value: valueRHF, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<F>({
    name,
    control,
    // rules: {
    // required: extraProps.required,
    // },
  });

  return (
    <AppComboBoxMultiple<T>
      {...extraProps}
      value={valueRHF as any}
      onChange={(value) => {
        onChangeRHF(value);
      }}
    />
  );
}
