import { CommerceBookingDepositForListGql_Company } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppDateTimeLabel } from 'src/business/club/modules/_common/ui';
import { BillingParticipantPaymentResumeTableDiverName } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BillingParticipantPaymentResumeTable/BillingParticipantPaymentResumeTableDiverName';
import { clubParticipantUrlBuilder } from 'src/business/club/modules/url-builders';
import { AppLinkStopPropagation } from 'src/lib/browser';
import { AppMultilineComment } from '../../../../business/club/modules/_common/form';
import { useAppCurrency } from '../../../_components/options';
import { DepositCreditDetails } from './DepositCreditDetails';
import { DepositStateIconBadge } from './DepositStateIconBadge';

export type DepositsListTableOnClickMemberAction =
  | 'add'
  | 'edit'
  | 'set-bookingDeposit-contact'
  | 'extract-from-bookingDeposit'
  | 'delete-member-with-participants';

export function DepositsListTableRow({
  bookingDeposit,
  className,
}: {
  bookingDeposit: CommerceBookingDepositForListGql_Company;
  className?: string;
}) {
  const url = useMemo(() => {
    const url = clubParticipantUrlBuilder.buildTabUrl({
      navigationContext: {
        origin: 'deposits-list',
        diverId: bookingDeposit.diver._id,
        purchasePaymentId: bookingDeposit._id,
        action: 'open-bookingDeposit-edit-dialog',
      },
      tabId: 'payment',
    });
    return url;
  }, [bookingDeposit._id, bookingDeposit.diver._id]);

  const redirectTo = useRedirect();

  const mainCurrency = useAppCurrency(bookingDeposit.currencyIsoCode);

  return (
    <tr
      className={clsx('app-tr-highlight', className)}
      onClick={() => {
        // si on clique sur la ligne, mais en dehors d'un lien, on est quand même redirigé
        redirectTo(url);
      }}
    >
      <td className="whitespace-nowrap text-sm text-gray-600">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="utc"
            date={bookingDeposit.depositDate ?? bookingDeposit._createdAt}
            format="date"
          />
        </AppLinkStopPropagation>
      </td>
      <td className="w-20 whitespace-nowrap text-sm font-bold text-gray-600">
        <AppLinkStopPropagation className="block px-2 py-1 text-right" to={url}>
          <DepositCreditDetails
            bookingDeposit={bookingDeposit}
            className="flex flex-row-reverse gap-2 items-center"
          />
        </AppLinkStopPropagation>
      </td>
      <td className="text-sm text-gray-600 truncate">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <BillingParticipantPaymentResumeTableDiverName
            diver={bookingDeposit.diver}
            className={'flex-shrink'}
          />
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap  hidden lg:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="local"
            date={bookingDeposit.diverDivesMeta.lastDiveTime}
            format="date"
          />
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap  hidden lg:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="local"
            date={bookingDeposit.diverDivesMeta?.nextDiveTime}
            format="date"
          />
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-600 hidden xs:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1 uppercase" to={url}>
          {/* <PurchasePaymentStatusDetails bookingDeposit={bookingDeposit} /> */}
          <DepositStateIconBadge
            className="flex gap-1 items-center text-xs font-bold"
            iconClassName="h-4 w-4"
            depositState={bookingDeposit.depositState}
            purchasePaymentPending={bookingDeposit.purchasePaymentPending}
            showLabel={true}
          />
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-600 hidden md:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {bookingDeposit.reference}
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-600 hidden sm:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppMultilineComment
            type="private"
            comment={bookingDeposit.details?.privateComment}
          />
        </AppLinkStopPropagation>
      </td>
    </tr>
  );
}
// created
// receipt_url
