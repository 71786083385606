import React from 'react';
import { Control, Path, useController } from 'react-hook-form';
import { AppMultiCheckboxesProps } from './AppMultiCheckboxesProps.type';
import { AppMultiCheckboxesTailwind } from './AppMultiCheckboxesTailwind';

export function AppMultiCheckboxesTailwindRHF<
  S extends string | number,
  T,
  L extends string | React.ReactNode = string,
>({
  control,
  name,
  className,
  buildLabel,
  ...extraProps
}: {
  control: Control<T>;
  name: Path<T>;
  className?: string;
} & Omit<AppMultiCheckboxesProps<S, L>, 'defaultValue' | 'onChange'> & {
    buildLabel?: (label: L) => React.ReactNode;
  }) {
  const {
    field: { ref, onChange: onChangeRHF, value: valueRHF, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<T>({
    name,
    control,
    // rules: {
    // required: extraProps.required,
    // },
  });

  // FIXME: y'a un bug, className n'est pas passé aux paramètres: mais avant de corriger, il faut vérifier partout quel est l'impact (notamment sur l'alignement horizontal ou vertical des checkbox)!!!

  return (
    <AppMultiCheckboxesTailwind<S, L>
      {...extraProps}
      defaultValue={valueRHF as any}
      onChange={(value) => {
        onChangeRHF(value);
      }}
      buildLabel={buildLabel}
    />
  );
}
