/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC, useCallback, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  AppPriceLabel,
  AppPriceLabelRatio,
} from 'src/business/club/modules/_common/ui';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DiverPurchasePaymentEditorDialogStateMode } from '../../../models';
import { BillingParticipantPurchaseResumeTableDiverName } from '../../BillingParticipantPurchaseResumeTable/BillingParticipantPurchaseResumeTableDiverName';
import { DiverPurchasePaymentEditorPackageChangesModel } from '../DiverPurchasePaymentEditorPackageChangesModel.type';
import { DiverPurchasePaymentStatusLabel } from '../DiverPurchasePaymentStatusLabel';
import { DiverPurchasePaymentEditorFormModel } from './DiverPurchasePaymentEditorForm';
import { DiverPurchasePaymentEditorFormModelPackage } from './DiverPurchasePaymentEditorForm/DiverPurchasePaymentEditorFormModelPackage.type';
import { DiverPurchasePaymentEditorPurchasePackageLabel } from './DiverPurchasePaymentEditorPurchasePackageLabel';

export const DiverPurchasePaymentEditorPackagesTableRow_Package: FC<{
  isEditEnabled: boolean;
  mode: DiverPurchasePaymentEditorDialogStateMode;
  form: UseFormReturn<DiverPurchasePaymentEditorFormModel>;
  purchasePaymentPackage: DiverPurchasePaymentEditorPackageChangesModel;
  rowIndex: number;
  showCheckboxCells: boolean;
  formPackages: DiverPurchasePaymentEditorFormModelPackage[];
}> = ({
  isEditEnabled,
  mode,
  form,
  purchasePaymentPackage,
  rowIndex,
  showCheckboxCells,
  formPackages,
}) => {
  const mainCurrency = useAppCurrencyMain();

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const isSelectable = useMemo(
    () => isEditEnabled && mode === 'create',
    [isEditEnabled, mode],
  );

  const toggleSelection = useCallback(() => {
    if (isSelectable) {
      setValue(
        `packages.${rowIndex}.selected`,
        !formPackages[rowIndex].selected,
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        },
      );
    }
  }, [formPackages, rowIndex, setValue, isSelectable]);

  return (
    <tr>
      <td
        className={`px-2 py-1 sm:px-4 sm:py-2 ${
          showCheckboxCells ? '' : 'hidden'
        }`}
      >
        <AppFormControlRHF_Deprecated
          className="w-full"
          control={control}
          name={`packages.${rowIndex}.selected` as any}
          renderComponent={(props) => (
            <AppInputBooleanRHF {...props} type="checkbox" />
          )}
        />
      </td>

      <td
        className={`px-2 py-1 sm:pr-4 sm:py-2 ${
          isSelectable ? 'cursor-pointer' : ''
        }`}
        onClick={toggleSelection}
      >
        <BillingParticipantPurchaseResumeTableDiverName
          showResidentType={true}
          diver={purchasePaymentPackage.diver}
          opacity={formPackages[rowIndex].selected ? undefined : 60}
          className={'flex-shrink text-xs md:text-sm'}
        />
        <DiverPurchasePaymentEditorPurchasePackageLabel
          className="lg:hidden"
          opacity={formPackages[rowIndex].selected ? undefined : 60}
          purchasePackage={purchasePaymentPackage.updatedPurchasePackage}
        />
      </td>

      <td
        className={`hidden lg:table-cell px-2 py-1 sm:pr-4 sm:py-2 ${
          isSelectable ? 'cursor-pointer' : ''
        }`}
        onClick={toggleSelection}
      >
        <DiverPurchasePaymentEditorPurchasePackageLabel
          opacity={formPackages[rowIndex].selected ? undefined : 60}
          purchasePackage={purchasePaymentPackage.updatedPurchasePackage}
        />
      </td>

      <td
        className={`hidden md:table-cell px-2 py-1 sm:pr-4 sm:py-2 ${
          isSelectable ? 'cursor-pointer' : ''
        }`}
        onClick={toggleSelection}
      >
        {
          <div
            className={`${formPackages[rowIndex].selected ? '' : 'opacity-50'}`}
          >
            <>
              <AppPriceLabel
                amount={purchasePaymentPackage.updatedPurchasePackage.price}
                mainCurrency={mainCurrency}
              />
            </>
          </div>
        }
      </td>
      <td
        className={`hidden sm:table-cell px-2 py-1 sm:pr-4 sm:py-2 ${
          isSelectable ? 'cursor-pointer' : ''
        }`}
        onClick={toggleSelection}
      >
        <AppPriceLabel
          amount={
            purchasePaymentPackage.updatedPurchasePackage.payedAmount -
            (purchasePaymentPackage.updatedAmountForPackage ?? 0)
          }
          mainCurrency={mainCurrency}
          className={`${formPackages[rowIndex].selected ? '' : 'opacity-50'}`}
        />
      </td>
      <td
        className={clsx(
          'px-2 py-1 sm:pr-4 sm:py-2',
          isSelectable && 'cursor-pointer',
          mode === 'create' ? 'hidden lg:table-cell' : 'hidden',
        )}
        onClick={toggleSelection}
      >
        <DiverPurchasePaymentStatusLabel
          className={`${formPackages[rowIndex].selected ? '' : 'opacity-50'}`}
          purchasePaymentStatus={
            purchasePaymentPackage.initialPurchasePackage.purchasePaymentStatus
          }
          purchasePaymentPending={
            purchasePaymentPackage.initialPurchasePackage.purchasePaymentPending
          }
        />
      </td>
      <td className="px-2 py-1 sm:px-4 sm:py-2">
        {formPackages[rowIndex].selected && (
          <>
            {isEditEnabled && mode === 'create' ? (
              <AppFormControlRHF_Deprecated
                className="w-full"
                control={control}
                name={`packages.${rowIndex}.amount` as any}
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    type="number"
                    className="w-24 text-center font-bold"
                  />
                )}
              />
            ) : (
              <span className="text-status-active">
                <AppPriceLabel
                  amount={formPackages[rowIndex].amount}
                  mainCurrency={mainCurrency}
                />
              </span>
            )}
          </>
        )}
      </td>
      <td className={'hidden sm:table-cell px-2 py-1 sm:px-4 sm:py-2'}>
        {formPackages[rowIndex].selected && (
          <AppPriceLabelRatio
            amount={purchasePaymentPackage.updatedPurchasePackage.payedAmount}
            total={purchasePaymentPackage.updatedPurchasePackage.price}
            mainCurrency={mainCurrency}
            hideTotalIfSameAmount={true}
          />
        )}
      </td>
      <td className={'px-2 py-1 sm:px-4 sm:py-2'}>
        {formPackages[rowIndex].selected && (
          <AppPriceLabel
            amount={purchasePaymentPackage.remainingAmount}
            mainCurrency={mainCurrency}
          />
        )}
      </td>
      <td className="hidden md:table-cell px-2 py-1 sm:px-4 sm:py-2">
        {formPackages[rowIndex].selected && (
          <DiverPurchasePaymentStatusLabel
            purchasePaymentStatus={
              purchasePaymentPackage.updatedPurchasePackage
                .purchasePaymentStatus
            }
            purchasePaymentPending={
              purchasePaymentPackage.updatedPurchasePackage
                .purchasePaymentPending
            }
          />
        )}
      </td>
    </tr>
  );
};
