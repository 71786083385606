/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { ClubDialogsState } from '../../../../../../../_dialogs';
import { ClubSettingsOnlineBookingFormModel } from '../../../_model';
import { ClubSettingsOnlineBookingEmailsForm_EmailOption } from './ClubSettingsOnlineBookingEmailsForm_EmailOption';

export const ClubSettingsOnlineBookingEmailsForm_02 = ({
  form,
  dialogsState,
}: {
  form: UseFormReturn<ClubSettingsOnlineBookingFormModel>;
  dialogsState: ClubDialogsState;
}) => {
  return (
    <ClubSettingsSection
      title="Mail 2 - à la confirmation de commande par le PRO"
      comment="Prévenez vos clients que leur réservation est confirmée"
    >
      <div className="grid gap-4">
        <ClubSettingsOnlineBookingEmailsForm_EmailOption
          dialogsState={dialogsState}
          form={form}
          optionName="onProConfirmBooking"
          label="Lorsque vous confirmez une réservation en ligne"
        />
      </div>
    </ClubSettingsSection>
  );
};
