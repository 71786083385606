/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AdminMassiveUpdatePayload,
  COUNTRIES_LABELS_BY_CODE,
  ClubResumeAdmin,
} from '@mabadive/app-common-model';
import { dataSorter, search } from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import {
  appLoader,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { adminMassiveUpdatorClient } from '../../../business/_core/data/app-operation';
import { confirmDialog } from '../../../business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { appLogger } from '../../../business/_core/modules/root/logger';
import { uiStore } from '../../../business/_core/store';
import { clubResumeAdminsPageGraphqlFetcher } from './services/clubResumeAdminsPageGraphqlFetcher.service';

const discretMode = appWebConfig.envId !== 'dev';

export function useAdminCompaniesListPageLocalState() {
  const [filters, setFilters] = useState<{
    searchText: string;
    showDetails: boolean;
    showActiveClubs: boolean;
    showTrialClubs: boolean;
    showDraftClubs: boolean;
    showDisabledClubs: boolean;
  }>({
    searchText: '',
    showDetails: !discretMode,
    showActiveClubs: !discretMode,
    showTrialClubs: !discretMode,
    showDraftClubs: !discretMode,
    showDisabledClubs: false,
  });
  const [reloadDate, setReloadDate] = useState(new Date());
  const { content: clubResumeAdminsLoaded, ...loadableContent } =
    useLoadableContent(
      () =>
        appLoader.load(
          clubResumeAdminsPageGraphqlFetcher.fetchAll({
            orderBy: '{ _updatedAt: desc }',
            limit: 100,
          }),
          {
            type: 'full',
            defaultValue: undefined,
            isSubscription: true,
          },
        ),
      [],
      {
        initialValue: undefined,
        defaultValue: undefined,
        useSnapshot: false,
        debugName: 'AdminCompaniesListPage',
        reloadDate, // on ajoute la date pour forcer le rechargement
      },
    );

  const clubResumeAdmins = useMemo(() => {
    let items = clubResumeAdminsLoaded ?? [];
    const {
      searchText,
      showDisabledClubs,
      showDraftClubs,
      showActiveClubs,
      showTrialClubs,
    } = filters;
    if (search.clean(searchText)?.length) {
      items = search.filter(items, {
        searchText,
        getAttributes: (x) => {
          const clubBrand = x.clubSettings.publicData.brand;
          const generalSettings = x.clubSettings.general;
          const countryLabel =
            COUNTRIES_LABELS_BY_CODE[generalSettings?.countryIsoCode];
          // const clubBrand = x.clubSettings.publicData.brand;
          const labels: string[] = [
            x.name,
            clubBrand?.name,
            clubBrand?.locationResume,
            x?.reference,
            countryLabel,
            generalSettings?.timeZoneName,
          ];
          x.diveCenters.forEach((diveCenter) => {
            const dcBrand = diveCenter.publicData.brand;
            const contact = diveCenter.publicData.contact;
            labels.push(dcBrand?.name);
            labels.push(dcBrand?.locationResume);
            labels.push(contact?.emailAddress);
            labels.push(contact?.phoneNumber);
          });
          return labels;
        },
      });
    }
    if (items.length <= 3) {
      // si moins de 3 résultats, on les retourne sans appliquer les filtres
      return items;
    }
    if (!showDraftClubs) {
      items = items.filter((x) => x.offer.state !== 'draft');
    }
    if (!showDisabledClubs) {
      items = items.filter((x) => x.offer.state !== 'archived');
    }
    if (!showActiveClubs) {
      items = items.filter((x) => x.offer.state !== 'active');
    }
    if (!showTrialClubs) {
      items = items.filter((x) => x.offer.state !== 'trial');
    }

    return dataSorter.sortMultiple(items, {
      getSortAttributes: (x, index) => {
        const dates = [
          x.clubLogs?.lastLoginDate,
          x.clubLogs?.lastUpdate,
          x?.lastAppUrlAlias?._updatedAt,
          x._createdAt,
          x._updatedAt,
        ]
          .filter((x) => !!x)
          .map((x) => x.getTime());
        const lastDate = Math.max(...dates);
        return [
          {
            value: lastDate,
            asc: false,
          },
        ];
      },
    });
  }, [clubResumeAdminsLoaded, filters]);

  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();
  const deleteClub = useCallback(
    async ({ clubId, clubName }: { clubId: string; clubName: string }) => {
      if (!operationInProgressMessage) {
        confirmDialog
          .confirm({
            isTailwind: true,
            title: `Supprimer ${clubName}`,
            message: 'Êtes-vous sûr de vouloir supprimer ce compte?',
            type: 'remove',
          })
          .subscribe(async (confirmed) => {
            if (confirmed) {
              setOperationInProgressMessage('Suppression du compte');
              try {
                const payload: AdminMassiveUpdatePayload = {
                  clubWithAllData: {
                    deletedIds: [clubId],
                  },
                };
                await adminMassiveUpdatorClient.updateMany(payload);
                setReloadDate(new Date());
                uiStore.snackbarMessage.set({
                  type: 'success',
                  content: 'Compté supprimé avec succès!',
                });
              } catch (err) {
                setReloadDate(new Date());
                // eslint-disable-next-line no-console
                console.error(err);
                appLogger.error(
                  'Erreur innatendue lors de la suppression du compte',
                );
                uiStore.snackbarMessage.set({
                  type: 'error',
                  content:
                    'Erreur innatendue lors de la suppression du compte!',
                });
              } finally {
                setOperationInProgressMessage(undefined);
              }
            }
          });
      }
    },
    [operationInProgressMessage],
  );
  const archiveClub = useCallback(
    async (club: ClubResumeAdmin) => {
      if (!operationInProgressMessage) {
        confirmDialog
          .confirm({
            isTailwind: true,
            title: `Archiver ${club?.name}`,
            message: 'Êtes-vous sûr de vouloir archiver ce compte?',
            type: 'noYesDanger',
          })
          .subscribe(async (confirmed) => {
            if (confirmed) {
              setOperationInProgressMessage('Archivage du compte');
              try {
                const payload: AdminMassiveUpdatePayload = {
                  updatedClubs: [
                    {
                      pk: club?._id,
                      patchOperations: [
                        {
                          op: 'replace',
                          path: '/offer',
                          value: {
                            ...club?.offer,
                            state: 'archived',
                          },
                        },
                      ],
                    },
                  ],
                };
                await adminMassiveUpdatorClient.updateMany(payload);
                setReloadDate(new Date());
                uiStore.snackbarMessage.set({
                  type: 'success',
                  content: 'Compté archivé avec succès!',
                });
              } catch (err) {
                setReloadDate(new Date());
                // eslint-disable-next-line no-console
                console.error(err);
                appLogger.error(
                  "Erreur innatendue lors de l'archivage du compte",
                );
                uiStore.snackbarMessage.set({
                  type: 'error',
                  content: "Erreur innatendue lors de l'archivage du compte!",
                });
              } finally {
                setOperationInProgressMessage(undefined);
              }
            }
          });
      }
    },
    [operationInProgressMessage],
  );

  return {
    filters,
    setFilters,
    clubResumeAdmins,
    loadableContent,
    state: {
      operationInProgressMessage,
    },
    actions: {
      deleteClub,
      archiveClub,
    },
  };
}

export type AdminCompaniesListPageLocalState = ReturnType<
  typeof useAdminCompaniesListPageLocalState
>;
