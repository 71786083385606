/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  AppBreadcrumbBar,
  AppFixedButtonsBar,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { clubSettingsCustomersUrlBuilder } from '../../../clubSettingsCustomersUrlBuilder.service';
import { ClubSettingsInquiryEditPageForm } from '../ClubSettingsInquiryEditPageForm';
import { ClubSettingsInquiryEditPageLocalState } from '../useClubSettingsInquiryEditPageLocalState.hook';
import {
  ClubSettingsInquiryEditPagePanelLocalState,
  useClubSettingsInquiryEditPagePanelLocalState,
} from './useClubSettingsInquiryEditPagePanelLocalState.hook';

export const ClubSettingsInquiryEditPagePanel = ({
  parentState,
}: {
  parentState: ClubSettingsInquiryEditPageLocalState;
}) => {
  const localState: ClubSettingsInquiryEditPagePanelLocalState =
    useClubSettingsInquiryEditPagePanelLocalState({ parentState });

  const { data, state, actions } = localState;

  const form = data.form;
  const { control, handleSubmit } = form;

  return (
    <AppPageContainerWithFixedBars
      paddingBottom={false}
      className={'bg-gray-50'}
      footerBar={() => (
        <AppFixedButtonsBar hasChanges={data.form.formState.isDirty}>
          <>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionCancel}
              tabIndex={500}
              color="gray-outline-light"
              onClick={() => actions.back()}
            >
              Annuler
            </AppButton>
            {state.mode === 'edit' && (
              <AppButton
                type="button"
                size="normal"
                icon={AppIcons.action.delete}
                color="danger-outline-light"
                onClick={async () => {
                  await localState.actions.confirmDelete();
                }}
              >
                Supprimer
              </AppButton>
            )}
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionSave}
              color="primary-outline-light"
              onClick={() => actions.submitForm()}
            >
              Enregistrer
            </AppButton>
          </>
        </AppFixedButtonsBar>
      )}
    >
      <AppBreadcrumbBar
        color={AppIconsSettings.account.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.account.main,
            label: 'Mon compte',
            url: '/club/settings',
          },
          {
            label: 'Questionnaires',
            url: clubSettingsCustomersUrlBuilder.inquiries.list(),
          },
        ]}
      />
      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <ClubSettingsInquiryEditPageForm
          className="my-2"
          localState={localState}
        />
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
