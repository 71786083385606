/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsCommunicationPageLocalState } from '../../../useClubSettingsCommunicationPageLocalState.hook';
import { ClubSettingsCommunicationPageBasePanel } from '../../_common';
import { ClubSettingsCommunicationEmailAutoViewPanel_General } from './ClubSettingsCommunicationEmailAutoViewPanel_General';

export const ClubSettingsCommunicationEmailAutoViewPanel = ({
  localState,
}: {
  localState: ClubSettingsCommunicationPageLocalState;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  const generalSettings = clubSettings.general;
  return (
    <ClubSettingsCommunicationPageBasePanel
      localState={localState}
      onClick={() => {
        state.editEnabled && dialogs.communication.open(selectedTab);
      }}
    >
      <div className="grid gap-4 md:gap-6">
        <ClubSettingsCommunicationEmailAutoViewPanel_General />
      </div>
    </ClubSettingsCommunicationPageBasePanel>
  );
};
