import { CreditNote } from '@mabadive/app-common-model';
import {
  dateService,
  nameFormatter,
  paymentMethodFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppButtonMultiActions } from 'src/business/_core/modules/layout';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppMultilineComment } from 'src/business/club/modules/_common/form';
import {
  AppHamburgerMenuItem,
  AppPriceLabel,
} from 'src/business/club/modules/_common/ui';
import { AggregatedBookingPurchasePaymentWithDetails } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { useAppCurrency } from 'src/pages/_components/options';
import { DiveCenterProBadge } from '../../DiveCenterProBadge';
import { PurchasePaymentOnlineDetails } from './PurchasePaymentOnlineDetails';
import { PurchasePaymentCardCreditNoteActions } from './model';
import { usePurchasePaymentCardCreditNoteMenuItems } from './usePurchasePaymentCardCreditNoteMenuItems.hook';

export const PurchasePaymentCardCreditNote = ({
  purchasePaymentWithDetails,
  creditNote,
  actions,
  className,
}: {
  purchasePaymentWithDetails: AggregatedBookingPurchasePaymentWithDetails;
  creditNote: CreditNote;
  actions: PurchasePaymentCardCreditNoteActions;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume?.clubResume?.clubSettings;

  const diver = purchasePaymentWithDetails?.diver;
  const bookingResume = purchasePaymentWithDetails?.bookingResume;

  const paymentCurrency = useAppCurrency(creditNote.currencyIsoCode);

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const creditNoteDiveCenter = useMemo(
    () =>
      clubResume.diveCenters.find((dc) => dc._id === creditNote?.diveCenterId),
    [clubResume.diveCenters, creditNote?.diveCenterId],
  );

  const hamburgerMenuItems: AppHamburgerMenuItem[] =
    usePurchasePaymentCardCreditNoteMenuItems({
      purchasePaymentWithDetails,
      creditNote,
      actions,
    });

  return (
    <div className={clsx('grid gap-2 bg-gray-50', className)}>
      <div className={clsx('flex gap-2 items-center')}>
        <div className="hidden md:block">
          <EntitiesIcons.creditNote className={'h-8 w-10 text-app-primary'} />
        </div>
        <div className="flex-grow grid gap-2">
          <div className="w-full flex gap-4 justify-between items-center text-app-primary">
            <div className="w-full flex gap-4 flex-wrap justify-start">
              <div className="flex flex-wrap gap-x-4 min-w-[50%] lg:min-w-[40%]">
                <div className="text-xs md:text-sm font-medium">
                  <div className="font-bold text-xs bg-app-primary text-white text-center px-1">
                    AVOIR
                  </div>
                  <AppPriceLabel
                    amount={-creditNote.amount}
                    mainCurrency={paymentCurrency}
                  />
                </div>
                <div
                  className={clsx(
                    'text-xs md:text-sm font-normal  text-gray-500',
                  )}
                >
                  <div className="grid gap-1 text-xs ">
                    <div className="font-medium uppercase">
                      {paymentMethodFormatter.formatPaymentMethod(
                        creditNote.paymentMethod,
                      )}
                      <span className="font-normal italic">
                        {' '}
                        {creditNote.reference}
                      </span>
                    </div>
                    <div className={clsx('text-gray-500')}>
                      {dateService.formatUTC(
                        creditNote.creationDate,
                        'DD/MM/YYYY',
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-xs md:text-sm font-medium text-gray-500">
                {diver && (
                  <div className="">
                    {nameFormatter.formatFullName(diver, {
                      format: 'firstName-first',
                    })}
                  </div>
                )}
                {/* {bookingResume && (
                  <DiverBookingTitle
                    className="text-xs xl:text-sm font-normal text-gray-500"
                    datesRange={bookingResume.datesRange}
                    bookingGroup={bookingResume.booking?.bookingGroup}
                  ></DiverBookingTitle>
                )} */}
                {isMultiDiveCenters && (
                  <div className="flex gap-2 items-center">
                    {isMultiDiveCenters && (
                      <DiveCenterProBadge
                        className="inline-block"
                        diveCenter={creditNoteDiveCenter}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid gap-2">
            {(creditNote.details?.privateComment?.trim()?.length > 0 ||
              creditNote.details?.customerComment?.trim()?.length > 0) && (
              <div className="flex gap-2 md:flex-row md:flex-wrap md:gap-4">
                <AppMultilineComment
                  type="private"
                  comment={creditNote.details?.privateComment}
                />
                <AppMultilineComment
                  type="public"
                  comment={creditNote.details?.customerComment}
                />
              </div>
            )}
            <PurchasePaymentOnlineDetails
              className="border-t border-gray-100 pt-2 "
              purchasePayment={purchasePaymentWithDetails?.purchasePayment}
            />
          </div>
        </div>
        <div className="text-gray-600">
          <AppButtonMultiActions items={hamburgerMenuItems} />
        </div>
      </div>
    </div>
  );
};
