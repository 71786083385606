/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import {
  APP_FORM_CLASSNAME,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubDiveSessionHeaderCard } from 'src/business/club/modules/club-dive-session/components';
import { ClubPlanningSessionSelectorDialog } from 'src/business/club/modules/club-planning-light/club-planning-session-selector-dialog/ClubPlanningSessionSelectorDialog';
import { DiveSessionEditorMassiveDialogActionsBar } from './DiveSessionEditorMassiveDialogActionsBar';
import { DiveSessionEditorMassiveDialogState } from './_model';
import {
  DiveSessionEditorMassiveResumeWithOptionsPanel,
  DiveSessionEditorMassiveSelectDiversPanel,
} from './panels';
import {
  DiveSessionEditorMassiveDialogLocalState,
  useDiveSessionEditorMassiveDialogLocalState,
} from './useDiveSessionEditorMassiveDialogLocalState.hook';

export const DiveSessionEditorMassiveDialog: FC<DiveSessionEditorMassiveDialogState> =
  (inputState) => {
    const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] =
      useState(false);
    const globalClasses = useGlobalClasses();

    const clubResume = useClubResume();
    const clubSettings = clubResume.clubSettings;
    const clubReference = clubResume?.reference;

    const { isOpen, initialState } = inputState;

    const localState: DiveSessionEditorMassiveDialogLocalState =
      useDiveSessionEditorMassiveDialogLocalState({
        inputState,
      });

    const { data, form } = localState;
    const { pageState, setPageState, sessionSelectorDialogInputState } = data;

    return !isOpen ? null : (
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-gray-50"
        footerBar={() => (
          <DiveSessionEditorMassiveDialogActionsBar localState={localState} />
        )}
      >
        <div className="app-p-content">
          {pageState.step !== 'select-session' &&
            inputState.initialState?.originalSessionFull && (
              <ClubDiveSessionHeaderCard
                className="flex-grow bg-white"
                diveSession={inputState.initialState?.originalSessionFull}
                widthMode="large"
              />
            )}
          {pageState.step === 'select-divers' && (
            <div className={clsx(APP_FORM_CLASSNAME, 'my-5')}>
              <DiveSessionEditorMassiveSelectDiversPanel
                localState={localState}
              />
            </div>
          )}
          {pageState.step === 'resume-with-options' && (
            <div className={clsx(APP_FORM_CLASSNAME, 'my-5')}>
              <DiveSessionEditorMassiveResumeWithOptionsPanel
                localState={localState}
              />
            </div>
          )}
          {pageState.step === 'select-session' && (
            <ClubPlanningSessionSelectorDialog
              state={sessionSelectorDialogInputState}
              defaultFocusDate={
                inputState?.initialState?.originalSessionFull?.time
              }
              onClose={() => {
                setPageState({
                  ...pageState,
                  step: 'select-divers',
                });
              }}
              onSelectSessions={(sessions, context) => {
                if (sessions.length === 1) {
                  const session = sessions[0];
                  form.setValue('selectedSessionDef', session);
                  setPageState({
                    ...pageState,
                    step: 'resume-with-options',
                  });
                }
              }}
            />
          )}
        </div>
      </AppPageContainerWithFixedBars>
    );
  };
