import { SingleEquipmentStats } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { DailySessionsResumeEquipmentStatsTableRow } from './DailySessionsResumeEquipmentStatsTableRow';

export function DailySessionsResumeEquipmentStatsTable({
  equipmentsStats,
  className,
}: {
  equipmentsStats: SingleEquipmentStats[];
  className?: string;
}) {
  return (
    <table className={clsx('app-table', className)}>
      <thead className="">
        <tr>
          <th scope="col" className="px-2 sm:py-1  uppercase tracking-wider">
            Équipement
          </th>
          <th
            scope="col"
            className="px-2 sm:py-1  uppercase tracking-wider text-center"
          >
            <span className="hidden md:inline">Équipé </span>club
          </th>
          <th
            scope="col"
            className="px-2 sm:py-1  uppercase tracking-wider hidden xs:table-cell"
          >
            Détail éq. club
          </th>
          <th
            scope="col"
            className="px-2 sm:py-1  uppercase tracking-wider text-center"
          >
            <span className="hidden md:inline">Équipé </span>perso
          </th>
          <th
            scope="col"
            className="px-2 sm:py-1  uppercase tracking-wider hidden xs:table-cell"
          >
            Détail éq. perso
          </th>
          <th
            scope="col"
            className="px-2 sm:py-1  uppercase tracking-wider text-center"
          >
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {equipmentsStats.map((singleEquipmentStats) => (
          <DailySessionsResumeEquipmentStatsTableRow
            key={singleEquipmentStats.equipmentType}
            singleEquipmentStats={singleEquipmentStats}
          />
        ))}
      </tbody>
    </table>
  );
}
