/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EcommerceCategoryGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppButton } from '../../../../../../business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from '../../../../../../business/_core/modules/layout/icons';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import { EcommerceProductPreviewCoverHorizontal } from './EcommerceProductPreview/EcommerceProductPreviewCoverHorizontal';

export const ProProductsPage_CategoryProductCoverCard = ({
  category,
  product,
  localState,
  className,
}: {
  category: EcommerceCategoryGql_Company;
  product: EcommerceProductGql_Company;
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
  className?: string;
}) => {
  const { state, dialogs, data, actions } = localState;

  const productCategoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.productId === product._id,
    );
  }, [data.productCategoryConfigs, product._id]);

  const appBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product.bookletId);
  }, [data.booklets, product.bookletId]);

  const appBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === appBooklet?.rootPageId);
  }, [appBooklet?.rootPageId, data.bookletPages]);

  const categoryBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === category.bookletId);
  }, [category.bookletId, data.booklets]);

  const categoryBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === categoryBooklet?.rootPageId);
  }, [categoryBooklet?.rootPageId, data.bookletPages]);
  const productBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product.bookletId);
  }, [data.booklets, product.bookletId]);

  const productBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === productBooklet?.rootPageId);
  }, [data.bookletPages, productBooklet?.rootPageId]);
  return (
    <div
      className={clsx('', className)}
      onClick={(e) => {
        e.stopPropagation();
        actions.selectCategoryProduct({
          categoryId: category._id,
          productId: product._id,
        });
      }}
    >
      {/* <h2 className="text-left text-sm font-bold uppercase p-2 text-app-secondary">
        {product.name}
      </h2> */}
      <div className="flex gap-4">
        <EcommerceProductPreviewCoverHorizontal
          className="app-card border-t-xl border-app-primary w-[240px]"
          product={product}
          appBookletPage={appBookletPage}
        />
        <div className="app-card border-t-xl border-app-primary w-[240px] flex flex-col justify-center gap-4 px-4 py-2">
          <AppButton
            className=""
            color="primary-bg"
            size="normal"
            icon={AppHeroIcons.actionEdit}
            onClick={(e) => {
              e.stopPropagation();
              dialogs.productEditor.openEditProduct({
                categoryBookletPage,
                editValue: {
                  product,
                  categoryConfigs: productCategoryConfigs,
                  appBooklet,
                  appBookletPage,
                  productArticlesFull: data.productArticlesFull.filter(
                    (x) => x.productId === product._id,
                  ),
                },
              });
            }}
          >
            Modifier la fiche
          </AppButton>
          <AppButton
            className=""
            color="gray-outline-light"
            size="normal"
            icon={AppIconsAction.open}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   actions.selectCategory(category._id);
            // }}
          >
            Voir la fiche
          </AppButton>
        </div>
      </div>
    </div>
  );
};
