/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  ClubParticipantAutoSupervisedDiveMode,
  DiveAptitude,
  DiveMode,
} from '@mabadive/app-common-model';
import { diveModeAnalyser } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppFormControlRHF_Deprecated,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { useDiveAptitudeOptions } from 'src/pages/_components/options';
import { BookingParticipantAutoSupervisedForm } from '../BookingParticipantEditForm/BookingParticipantAutoSupervisedForm';
import { BookingParticipantDiveModeSpecificReference } from '../BookingParticipantEditForm/BookingParticipantDiveModeSpecificReference';

export const BookingParticipantDefaultParamsEditFormRHFGroup1 = ({
  form,
  onDiveModeChange,
  onAutoSupervisedDiveModeChange,
  maxWidthMd,
  className = '',
}: {
  form: UseFormReturn<Pick<BookingParticipantEditorFormModel, 'diver'>>;
  onDiveModeChange: (diveMode: DiveMode) => void;
  onAutoSupervisedDiveModeChange: (
    diveMode: ClubParticipantAutoSupervisedDiveMode,
  ) => void;
  maxWidthMd: boolean;
  className?: string;
}) => {
  const { control, setValue } = form;

  const [diveMode] = useWatch({
    control,
    name: ['diver.defaultDiveConfig.diveMode'],
  });

  const diveAtitudesOptions: ValueLabel<DiveAptitude>[] =
    useDiveAptitudeOptions({
      effectiveDate: new Date(),
    });

  return (
    <div
      className={clsx(
        'w-full flex flex-col sm:grid sm:grid-cols-4 gap-4',
        !maxWidthMd && 'lg:grid-cols-8',
        className,
      )}
    >
      <BookingParticipantDiveModeSpecificReference
        className={clsx('sm:col-span-2')}
        form={form}
        context={'clubDiver'}
        mode={'edit'}
        onDiveModeChange={onDiveModeChange}
      />

      <BookingParticipantAutoSupervisedForm
        classNameEveryField={clsx('sm:col-span-2')}
        form={form}
        context={'clubDiver'}
        onAutoSupervisedDiveModeChange={onAutoSupervisedDiveModeChange}
      />
      {diveModeAnalyser.hasScubaDivingSecurityParams(diveMode) && (
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Aptitude"
          control={control}
          name={'diver.defaultDiveConfig.aptitude'}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={diveAtitudesOptions}
            />
          )}
        />
      )}
    </div>
  );
};
