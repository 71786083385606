/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
} from '@mabadive/app-common-model';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import {
  useDiveCenterResume,
  useDiveModeColor,
} from 'src/business/club/data/hooks';
import { DiveSessionSimpleCardParticipantsGroupPanel } from '../components';
import { ClubPlanningAction } from '../useClubPlanningActions.hook';
import { ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroupRow } from './ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroupRow';
import { ClubPlanningLightPanelViewPeriodMonthState } from './useClubPlanningLightPanelViewPeriodMonthState.hook';
import { useParticipantsLevels } from './useParticipantsLevels.hook';

export const ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroup = ({
  localState,
  clubPlanningActions,
  session,
  virtualGroup,
  group,
  participants,
  className,
}: {
  localState: ClubPlanningLightPanelViewPeriodMonthState;
  clubPlanningActions: ClubPlanningAction;
  session: ClubPlanningLightSessionDef;
  group: DiveSessionResumeGroup;
  virtualGroup: boolean;
  participants: DiveSessionResumeParticipant[];
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume?.clubReference;

  const diveMode = group?.diveMode;

  const diveModeColor = useDiveModeColor(diveMode);

  const planningConfig = localState.state.planningConfig;

  const { levelsMap, commonLevel } = useParticipantsLevels({
    diveMode,
    participants,
  });

  return (
    <Tippy
      delay={[300, 100]}
      placement="left-start"
      className="border border-gray-600 bg-white text-gray-600 p-1"
      content={
        <div>
          <DiveSessionSimpleCardParticipantsGroupPanel
            diveSession={session}
            group={group}
            participants={participants}
            settingsUIPlanning={planningConfig}
            widthMode={'medium'}
            isPast={false}
            groupOdd={false}
            onClickParticipant={() => {
              //
            }}
            onClickGroupDetails={() => {
              //
            }}
            anchorLinkParticipant={() => {
              //
              return undefined;
            }}
          />
        </div>
      }
    >
      <div
        className={clsx(
          'flex flex-col bg-white',
          'font-medium text-xs md:text-sm whitespace-nowrap',
          'border border-px',
          !diveModeColor && 'border-gray-400',
          className,
        )}
        style={diveModeColor ? { borderColor: diveModeColor } : undefined}
      >
        {planningConfig.displayGroupLevelDetails ? (
          levelsMap.map(({ level, participants }, i) => (
            <ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroupRow
              key={i}
              label={level}
              participantsCount={participants.length}
              showWarning={
                virtualGroup && group.diveMode !== 'theoretical-training'
              }
              diveModeColor={diveModeColor}
            />
          ))
        ) : (
          <ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroupRow
            label={commonLevel}
            participantsCount={participants.length}
            showWarning={
              virtualGroup && group.diveMode !== 'theoretical-training'
            }
            diveModeColor={diveModeColor}
          />
        )}
      </div>
    </Tippy>
  );
};

export function buildLevelsMap(
  map: Record<string, DiveSessionResumeParticipant[]>,
  diveModeLabel: string,
): {
  commonLevel: string;
  levelsMap: {
    level: string;
    participants: DiveSessionResumeParticipant[];
  }[];
} {
  // order the levels
  const levels = Object.keys(map).sort();
  const levelsMap = levels.map((level) => ({
    level: level ?? diveModeLabel,
    participants: map[level],
  }));
  if (levelsMap.length === 1) {
    const level = levelsMap?.[0]?.level;
    if (level) {
      return { commonLevel: level, levelsMap };
    }
  }
  return { commonLevel: diveModeLabel, levelsMap };
}
