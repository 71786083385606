import { ClubDiverFull } from '@mabadive/app-common-model';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { graphqlClient } from 'src/_common-browser';
import {
  buildClubDiverFullCollectionGraphqlQuery,
  buildClubDiverFullOneGraphqlQuery,
  buildClubDiverFullSearchGraphqlQuery,
} from './query-builder';

export const clubDiverFullGraphqlFetcher = {
  findOne,
  findFullText,
  findLastUpdated,
};

function findOne({ diverId }: { diverId: string }): Observable<ClubDiverFull> {
  const queryDescription = buildClubDiverFullOneGraphqlQuery({ diverId });

  return graphqlClient.query.runOne<ClubDiverFull>(queryDescription).pipe(
    catchError((err) => {
      appLogger.warn('[clubDiverFullGraphqlFetcher] error fetching data', err);
      return throwError(err);
    }),
  );
}

function findFullText({
  clubReference,
  fullText,
}: {
  clubReference: string;
  fullText: string;
}): Observable<ClubDiverFull[]> {
  const queryDescription = buildClubDiverFullSearchGraphqlQuery({
    clubReference,
    fullText,
  });

  return graphqlClient.query.runOne<ClubDiverFull[]>(queryDescription).pipe(
    tap((remoteDivers) => {
      appLogger.info(
        '[clubDiverFullGraphqlFetcher] remoteDivers:',
        remoteDivers.length,
      );
    }),

    catchError((err) => {
      appLogger.warn('[clubDiverFullGraphqlFetcher] error fetching data', err);
      return throwError(err);
    }),
  );
}

function findLastUpdated({
  clubReference,
  maxDivers,
  mainCertificationReference,
}: {
  clubReference: string;
  maxDivers: number;
  mainCertificationReference?: string;
}): Observable<ClubDiverFull[]> {
  const queryDescription = buildClubDiverFullCollectionGraphqlQuery({
    clubReference,
    maxDivers,
    mainCertificationReference,
  });

  return graphqlClient.query.runOne<ClubDiverFull[]>(queryDescription).pipe(
    tap((remoteDivers) => {
      appLogger.info(
        '[clubDiverFullGraphqlFetcher] findLastUpdated:',
        remoteDivers.length,
      );
    }),

    catchError((err) => {
      appLogger.warn(
        '[clubDiverFullGraphqlFetcher] error fetching findLastUpdated data',
        err,
      );
      return throwError(err);
    }),
  );
}
