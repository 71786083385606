/* eslint-disable @typescript-eslint/no-unused-vars */

import { search } from '@mabadive/app-common-services';
import {
  ClubDiverSimilarSearchEntry,
  ClubDiverSimilarSearchEntryNames,
} from '../model';

export const clubDiverSimilarSearchEntryBuilder = { buildEntries, buildEntry };

function buildEntries<T_DiverNames extends ClubDiverSimilarSearchEntryNames>(
  divers: T_DiverNames[],
): ClubDiverSimilarSearchEntry<T_DiverNames>[] {
  if (divers?.length) {
    const searchEntries: ClubDiverSimilarSearchEntry<T_DiverNames>[] =
      divers.map((diver) => buildEntry(diver));
    return searchEntries;
  }
  return [];
}
function buildEntry<T_DiverNames extends ClubDiverSimilarSearchEntryNames>(
  diver: T_DiverNames,
): ClubDiverSimilarSearchEntry<T_DiverNames> {
  const indexesFull = buildSearchEntryNames(diver);
  const indexesMax3 = buildSearchEntryNamesStart(indexesFull, {
    maxLength: 3,
  });
  const indexesMax4 = buildSearchEntryNamesStart(indexesFull, {
    maxLength: 4,
  });
  const indexesMax5 = buildSearchEntryNamesStart(indexesFull, {
    maxLength: 5,
  });
  const firstLetters: string[] = buildFirstLetters(indexesFull);
  const searchEntry: ClubDiverSimilarSearchEntry<T_DiverNames> = {
    diver,
    indexesFull,
    indexesStart: {
      3: indexesMax3,
      4: indexesMax4,
      5: indexesMax5,
    },
    firstLetters,
  };
  return searchEntry;
}
function buildFirstLetters(indexesFull: ClubDiverSimilarSearchEntryNames) {
  const firstLetters: string[] = [];
  {
    const lastNameFirstLetter = indexesFull?.lastName?.[0];
    if (lastNameFirstLetter) {
      firstLetters.push(lastNameFirstLetter);
    }
  }
  {
    const firstNameFirstLetter = indexesFull?.firstName?.[0];
    if (firstNameFirstLetter && !firstLetters.includes(firstNameFirstLetter)) {
      firstLetters.push(firstNameFirstLetter);
    }
  }
  return firstLetters;
}

function buildSearchEntryNames(
  names: ClubDiverSimilarSearchEntryNames,
): ClubDiverSimilarSearchEntryNames {
  return {
    lastName: search.clean(names.lastName, '')?.replace(/[^A-Za-z]/g, ''),
    firstName: search.clean(names.firstName, '')?.replace(/[^A-Za-z]/g, ''),
  };
}
function buildSearchEntryNamesStart(
  cleanNames: ClubDiverSimilarSearchEntryNames,
  {
    maxLength,
  }: {
    maxLength?: number;
  } = {},
): ClubDiverSimilarSearchEntryNames {
  return {
    lastName: cleanNames.lastName?.substring(0, maxLength),
    firstName: cleanNames.firstName?.substring(0, maxLength),
  };
}
