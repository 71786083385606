import {
  BookingMember,
  ClubDiver,
  DiveSession,
} from '@mabadive/app-common-model';
import {
  arrayBuilder,
  changeDescriptorManager,
} from '@mabadive/app-common-services';
import {
  BookingTabModel,
  DiverBookingPageUpdateState,
  DiverBookingUpdateProductParticipant,
} from '../../../models';
export const diverBookingPageUpdateStateManager = {
  addNewBookingMemberToState,
  addNewDiveSessionsToState,
  buildDiveSessionsChanges,
  addNewParticipantToState,
  deleteBookingFromState,
  deleteParticipantFromState,
  deleteParticipantsFromState,
};

function addNewBookingMemberToState({
  updateState,
  newBookingMember,
  diver,
}: {
  updateState: DiverBookingPageUpdateState;
  newBookingMember: BookingMember;
  diver: ClubDiver;
}): DiverBookingPageUpdateState {
  let updateStateLocal = updateState;

  const bookingMembersChanges = changeDescriptorManager.createOne(
    newBookingMember,
    {
      changeDescriptors: updateStateLocal.bookingMembersChanges,
    },
  );

  const diversChanges = changeDescriptorManager.addOneOriginal(diver, {
    changeDescriptors: updateStateLocal.diversChanges,
  });

  updateStateLocal = {
    ...updateStateLocal,
    diversChanges,
    bookingMembersChanges,
  };
  return updateStateLocal;
}

function addNewDiveSessionsToState({
  updateState,
  newDiveSessions,
  originalDiveSessions,
}: {
  updateState: DiverBookingPageUpdateState;
  newDiveSessions: DiveSession[];
  originalDiveSessions: DiveSession[];
}): DiverBookingPageUpdateState {
  const diveSessionsChanges = buildDiveSessionsChanges({
    newDiveSessions,
    updateState,
    originalDiveSessions,
  });
  updateState = {
    ...updateState,
    diveSessionsChanges,
  };

  return updateState;
}

function buildDiveSessionsChanges({
  newDiveSessions,
  updateState,
  originalDiveSessions,
}: {
  newDiveSessions: DiveSession[];
  updateState: DiverBookingPageUpdateState;
  originalDiveSessions: DiveSession[];
}) {
  let diveSessionsChanges = changeDescriptorManager.createMany(
    newDiveSessions.map((x) => {
      if (x.isVirtual) {
        return {
          ...x,
          isVirtual: false,
          virtualDiveSessionReference: x.virtualDiveSessionReference,
          virtualDiveTourReference: x.virtualDiveTourReference,
        };
      }
      return x;
    }),
    {
      changeDescriptors: updateState.diveSessionsChanges,
    },
  );
  diveSessionsChanges = changeDescriptorManager.addManyOriginals(
    originalDiveSessions,
    {
      changeDescriptors: diveSessionsChanges,
    },
  );
  return diveSessionsChanges;
}

function addNewParticipantToState<
  S extends Pick<
    DiverBookingPageUpdateState,
    | 'bookingProductsChanges'
    | 'bookingSessionParticipantsChanges'
    | 'clubParticipantsChanges'
  >,
>({
  updateState,
  newBookingProductParticipant,
}: {
  updateState: S;
  newBookingProductParticipant: DiverBookingUpdateProductParticipant;
}): S {
  let updateStateLocal = updateState;

  const bookingProductsChanges = changeDescriptorManager.createOne(
    newBookingProductParticipant.bookingProduct,
    {
      changeDescriptors: updateStateLocal.bookingProductsChanges,
    },
  );
  updateStateLocal = {
    ...updateStateLocal,
    bookingProductsChanges,
  };

  const bookingSessionParticipantsChanges = changeDescriptorManager.createOne(
    newBookingProductParticipant.bookingSessionParticipant,
    {
      changeDescriptors: updateStateLocal.bookingSessionParticipantsChanges,
    },
  );

  const clubParticipantsChanges = changeDescriptorManager.createOne(
    newBookingProductParticipant.clubParticipant,
    {
      changeDescriptors: updateStateLocal.clubParticipantsChanges,
    },
  );
  updateStateLocal = {
    ...updateStateLocal,
    bookingSessionParticipantsChanges,
    clubParticipantsChanges,
  };

  return updateStateLocal;
}

function deleteParticipantFromState({
  updateState,
  bookingProductId,
  clubParticipantId,
  bookingSessionParticipantId,
}: {
  updateState: DiverBookingPageUpdateState;
  bookingProductId: string;
  clubParticipantId: string;
  bookingSessionParticipantId: string;
}): DiverBookingPageUpdateState {
  return deleteParticipantsFromState({
    updateState,
    participants: [
      { bookingProductId, clubParticipantId, bookingSessionParticipantId },
    ],
  });
}

function deleteParticipantsFromState({
  updateState,
  participants,
}: {
  updateState: DiverBookingPageUpdateState;
  participants: {
    bookingProductId: string;
    clubParticipantId: string;
    bookingSessionParticipantId: string;
  }[];
}): DiverBookingPageUpdateState {
  let updateStateLocal = { ...updateState };

  const bookingProductsChanges = changeDescriptorManager.deleteMany(
    participants.map((x) => x.bookingProductId),
    {
      changeDescriptors: updateStateLocal.bookingProductsChanges,
    },
  );

  const clubParticipantsChanges = changeDescriptorManager.deleteMany(
    participants.map((x) => x.clubParticipantId),
    {
      changeDescriptors: updateStateLocal.clubParticipantsChanges,
    },
  );
  const bookingSessionParticipantsChanges = changeDescriptorManager.deleteMany(
    participants.map((x) => x.bookingSessionParticipantId),
    {
      changeDescriptors: updateStateLocal.bookingSessionParticipantsChanges,
    },
  );

  updateStateLocal = {
    ...updateStateLocal,
    clubParticipantsChanges,
    bookingSessionParticipantsChanges,
    bookingProductsChanges,
  };

  return updateStateLocal;
}

function deleteBookingFromState({
  updateState,
  bookingTabModel,
}: {
  updateState: DiverBookingPageUpdateState;
  bookingTabModel: BookingTabModel;
}): DiverBookingPageUpdateState {
  let updateStateLocal = updateState;

  const bookingId = bookingTabModel.bookingId;

  for (const participantProductFull of bookingTabModel.aggregatedBooking
    .bookingParticipantsFull) {
    const clubParticipantsChanges = changeDescriptorManager.deleteOne(
      participantProductFull.diveSessionParticipant._id,
      {
        changeDescriptors: updateStateLocal.clubParticipantsChanges,
      },
    );
    const bookingSessionParticipantsChanges = changeDescriptorManager.deleteOne(
      participantProductFull.bookingSessionParticipant._id,
      {
        changeDescriptors: updateStateLocal.bookingSessionParticipantsChanges,
      },
    );
    const bookingProductsChanges = changeDescriptorManager.deleteOne(
      participantProductFull.bookingProductDive._id,
      {
        changeDescriptors: updateStateLocal.bookingProductsChanges,
      },
    );

    updateStateLocal = {
      ...updateStateLocal,
      clubParticipantsChanges,
      bookingSessionParticipantsChanges,
      bookingProductsChanges,
    };
  }

  const bookingSessionsIds = arrayBuilder.filterDuplicated(
    bookingTabModel.aggregatedBooking.bookingSessions.map((x) => x._id),
  );

  const bookingSessionsChanges = changeDescriptorManager.deleteMany(
    bookingSessionsIds,
    {
      changeDescriptors: updateStateLocal.bookingSessionsChanges,
    },
  );
  updateStateLocal = {
    ...updateStateLocal,
    bookingSessionsChanges,
  };
  const bookingResumeMemberIds = arrayBuilder.filterDuplicated(
    bookingTabModel.aggregatedBooking.bookingMembers.map((x) => x._id),
  );
  const bookingMembersChanges = changeDescriptorManager.deleteMany(
    bookingResumeMemberIds,
    {
      changeDescriptors: updateStateLocal.bookingMembersChanges,
    },
  );
  updateStateLocal = {
    ...updateStateLocal,
    bookingMembersChanges,
  };

  const bookingsChanges = changeDescriptorManager.deleteOne(bookingId, {
    changeDescriptors: updateStateLocal.bookingsChanges,
  });
  updateStateLocal = {
    ...updateStateLocal,
    bookingsChanges,
  };

  const onlineBookingsIds =
    bookingTabModel.aggregatedBooking.onlineBookings.map((x) => x._id);
  if (onlineBookingsIds.length) {
    const onlineBookingsChanges = changeDescriptorManager.deleteMany(
      onlineBookingsIds,
      {
        changeDescriptors: updateStateLocal.onlineBookingsChanges,
      },
    );
    updateStateLocal = {
      ...updateStateLocal,
      onlineBookingsChanges,
    };
  }
  return updateStateLocal;
}
