/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveCenterResume,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppPageContainer,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { AppFormLabel } from 'src/lib/form/components/AppFormLabel';
import { clubSettingsDiveCenterUrlBuilder } from 'src/pages/SE-settings/SE-01_dive-center/clubSettingsDiveCenterUrlBuilder.service';
import useRouter from 'use-react-router';
import { ClubSettingsEquipmentCard } from './components';
import { useClubSettingsEquipmentsListPageLocalState } from './useClubSettingsEquipmentsListPageLocalState';
export const ClubSettingsEquipmentsListPage = () => {
  const { match } = useRouter();
  const autoFocus = useAutoFocus();

  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const clubResume = useClubResume();

  const { diveCenterResume, diveCenterSettingsEquipment, equipments } =
    useClubSettingsEquipmentsListPageLocalState();

  const hasRoleEditUserAccount = useAppSecurityUserHasRole(
    'club-edit-settings-user-accounts',
  );
  const hasRoleEditUserProfile = useAppSecurityUserHasRole(
    'club-edit-settings-user-profiles',
  );
  return (
    <AppPageContainer className={'bg-gray-50'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.account.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.account.main,
            label: 'Mon compte',
            url: '/club/settings',
          },
          {
            label: 'Équipements',
          },
        ]}
      />
      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <div
          className={clsx(
            'app-card app-p-content',
            operationInProgressMessage && 'invisible',
          )}
        >
          <AppFormLabel label="Équipement PERSO" />
          <AppInputBooleanSwitch
            className={clsx('w-full')}
            label={'Permettre d\'indiquer un plongeur comme "équipé PERSO"'}
            // label2={'PERSO'}
            disabled={!!operationInProgressMessage}
            value={diveCenterSettingsEquipment.provider}
            onChange={async (value) => {
              const dcr = diveCenterResume;
              const updatedDsr: DiveCenterResume = {
                ...dcr,
                privateSettings: {
                  ...dcr.privateSettings,
                  equipment: {
                    ...dcr.privateSettings.equipment,
                    provider: value,
                  },
                },
              };
              const patchOperations = jsonPatcher.compareObjects(
                dcr,
                updatedDsr,
                {
                  // attributesToReplaceFully: 'publicSe'
                },
              );
              if (patchOperations.length) {
                try {
                  const payload: ProMultiOperationPayload = {
                    logContext: 'update dc equipments from list',
                    diveCenters: {
                      updated: [
                        {
                          pk: dcr._id,
                          patchOperations,
                        },
                      ],
                    },
                  };
                  setOperationInProgressMessage('Enregistrement en cours');
                  await clubMassiveUpdatorClient.update(payload);
                } finally {
                  setOperationInProgressMessage(undefined);
                }
              }
            }}
          />
        </div>
        <div className="mt-4 flex flex-col gap-4">
          {equipments.map((equipment, i) => (
            <ClubSettingsEquipmentCard
              key={i}
              equipment={equipment}
              onClick={() => {
                redirectTo(
                  clubSettingsDiveCenterUrlBuilder.equipments.edit({
                    equipmentType: equipment.type,
                  }),
                );
              }}
            />
          ))}
        </div>
        <div className={globalClasses.pageBlock}>
          {operationInProgressMessage && (
            <LoadingProgressBar>
              {operationInProgressMessage}
            </LoadingProgressBar>
          )}
        </div>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
