/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppPageContainer,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import useRouter from 'use-react-router';
import { ValueLabel } from '../../../business/club/modules/_common/form';
import {
  AppFormControlV2,
  AppInputBooleanSwitch,
  AppSingleSelect2HeadlessUI,
} from '../../../lib/form';
import { AdminPageTabsPanel } from '../AdminPagesTabs';
import { AdminAppOperationCard } from './components';
import {
  AdminCompaniesListPageLocalState,
  useAdminAppOperationsListPageLocalState,
} from './useAdminAppOperationsListPageLocalState';

export const AdminAppOperationsListPage = (props: {}) => {
  const { match } = useRouter();

  const redirectTo = useRedirect();

  const autoFocus = useAutoFocus();

  const localState: AdminCompaniesListPageLocalState =
    useAdminAppOperationsListPageLocalState();

  const { filters, setFilters, appOperations, loadableContent } = localState;

  const displayLimitOptions = useMemo(() => {
    const options: ValueLabel<number>[] = [
      {
        value: 10,
        label: '10',
      },
      {
        value: 20,
        label: '20',
      },
      {
        value: 30,
        label: '30',
      },
      {
        value: 50,
        label: '50',
      },
      {
        value: 100,
        label: '100',
      },
    ];
    return options;
  }, []);
  const fetchedLimitOptions = useMemo(() => {
    const options: ValueLabel<number>[] = [
      {
        value: 100,
        label: '100',
      },
      {
        value: 500,
        label: '500',
      },
      {
        value: 1000,
        label: '1000',
      },
      {
        value: 5000,
        label: '10000',
      },
      {
        value: 5000,
        label: '10000',
      },
    ];
    return options;
  }, []);

  return (
    <AppPageContainer
      className={'bg-gray-50'}
      // lastActionStatus={loadableContent.lastActionStatus}
      // contentState={loadableContent.contentState}
      {...loadableContent}
    >
      <AdminPageTabsPanel currentTabId="operations" />
      <AppBreadcrumbBar
        color={'#333'}
        items={[
          {
            icon: AppHeroIcons.admin,
            label: 'Admin',
            url: '/admin',
          },
          {
            // icon: AppHeroIcons.home,
            label: 'Opérations',
          },
        ]}
      />

      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <div className="app-card p-4 flex gap-2 flex-wrap">
          <input
            autoFocus={autoFocus}
            autoComplete="new-password"
            placeholder="Recherche par nom, utilisateur..."
            type="text"
            className="flex-shrink flex-grow w-max hover:border-app-blue rounded px-2"
            defaultValue={filters.searchText}
            onChange={(e) => {
              setFilters({
                ...filters,
                searchText: e.target.value,
              });
            }}
          />
          <div className="flex gap-2 flex-wrap">
            <AppFormControlV2
              label="Max display"
              className="w-24"
              renderComponent={() => (
                <AppSingleSelect2HeadlessUI
                  theme={'tailwind'}
                  className="w-24"
                  disableClearButton={true}
                  value={filters.displayLimit}
                  options={displayLimitOptions}
                  onChange={(value) =>
                    setFilters({ ...filters, displayLimit: value })
                  }
                />
              )}
            />
            <AppFormControlV2
              label="Max fetch"
              className="w-24"
              renderComponent={() => (
                <AppSingleSelect2HeadlessUI
                  className="w-24"
                  theme={'tailwind'}
                  disableClearButton={true}
                  value={filters.fetchLimit}
                  options={fetchedLimitOptions}
                  onChange={(value) =>
                    setFilters({ ...filters, fetchLimit: value })
                  }
                />
              )}
            />
            <AppInputBooleanSwitch
              label={'OK'}
              value={filters.includeSuccess}
              onChange={(includeSuccess) =>
                setFilters({
                  ...filters,
                  includeSuccess,
                })
              }
            />
            <AppInputBooleanSwitch
              label={'KO'}
              value={filters.includeError}
              onChange={(includeError) =>
                setFilters({
                  ...filters,
                  includeError,
                })
              }
            />
            <AppInputBooleanSwitch
              label={'Super-admin'}
              value={filters.includeSuperAdmin}
              onChange={(includeSuperAdmin) =>
                setFilters({
                  ...filters,
                  includeSuperAdmin,
                })
              }
            />
          </div>
        </div>
        <ul className="my-4 grid grid-cols-1 gap-2">
          {appOperations.map((appOperation) => (
            <AdminAppOperationCard
              key={appOperation._id}
              localState={localState}
              as="li"
              className="col-span-1"
              appOperation={appOperation}
              onClick={() => {
                // const clubReference = appOperation.reference;
                // const diveCenters = dataSorter.sortMultiple(
                //   appOperation.diveCenters,
                //   {
                //     getSortAttributes: (item) => [
                //       {
                //         value: item._createdAt,
                //       },
                //       {
                //         value: item._updatedAt,
                //       },
                //       {
                //         value: item._id,
                //       },
                //     ],
                //   },
                // );
                // const diveCenterId = diveCenters[0]?._id;
                // impersonateClubProfile({ clubReference, diveCenterId });
              }}
            />
          ))}
        </ul>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
