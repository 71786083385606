import {
  BookingAgency,
  BookingDeposit,
  ClubDiver,
} from '@mabadive/app-common-model';
import {
  bookingAgencyFormatter,
  bookingDepositStateFormatter,
  dateService,
  nameFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  AppButtonMultiActions,
  AppTransition,
} from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { AppMultilineComment } from 'src/business/club/modules/_common/form';
import { AppMultilineCommentIcons } from 'src/business/club/modules/_common/form/components/AppMultilineCommentIcons';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { useAppCurrency } from 'src/pages/_components/options';
import { DepositCreditDetails } from '../../../../../../../../pages/LI-lists/LI-07-deposits/DepositsListTable/DepositCreditDetails';
import { DepositStateIconBadge } from '../../../../../../../../pages/LI-lists/LI-07-deposits/DepositsListTable/DepositStateIconBadge';
import {
  BookingDepositStatusColors,
  useBookingDepositStatusColors,
} from './useBookingDepositStatusColors.hook';

export const BookingDepositCard = ({
  hamburgerMenuItems,
  bookingDeposit,
  loadedDivers,
  isExpanded,
  setIsExpanded,
  className,
}: {
  hamburgerMenuItems: AppHamburgerMenuItem[];
  bookingDeposit: BookingDeposit;
  loadedDivers: ClubDiver[];
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume?.clubResume?.clubSettings;
  const bookingAgencies = diveCenterResume?.clubResume?.bookingAgencies;

  const diver = useMemo(
    () => (loadedDivers ?? []).find((d) => d._id === bookingDeposit.diverId),
    [loadedDivers, bookingDeposit.diverId],
  );
  const agency: BookingAgency = useMemo(
    () =>
      (bookingAgencies ?? []).find(
        (d) => d._id === bookingDeposit.bookingAgencyId,
      ),
    [bookingAgencies, bookingDeposit.bookingAgencyId],
  );

  const currency = useAppCurrency(bookingDeposit.currencyIsoCode);

  const depositDate = bookingDeposit.depositDate;

  const statusColors: BookingDepositStatusColors =
    useBookingDepositStatusColors(bookingDeposit);

  const editAction: AppHamburgerMenuItem = useMemo(() => {
    const action = hamburgerMenuItems.find((x) => x && !x.hidden);
    return action;
  }, [hamburgerMenuItems]);

  return (
    <div
      className={clsx('cursor-pointer text-gray-600', className)}
      onClick={() => {
        if (!isExpanded) {
          setIsExpanded(!isExpanded);
        } else {
          editAction && editAction.onClick();
        }
      }}
    >
      <div className="grid gap-2">
        <div className="flex gap-2">
          <div className="hidden md:block">
            <DepositStateIconBadge
              iconClassName="h-10 w-10"
              depositState={bookingDeposit.depositState}
            />
          </div>
          <div className="w-full flex gap-2 justify-between">
            <div className="w-full flex gap-2 flex-wrap justify-between sm:grid sm:grid-cols-2">
              <div className="flex flex-wrap gap-x-4">
                <div className="text-sm md:text-base font-medium">
                  <DepositCreditDetails
                    bookingDeposit={bookingDeposit}
                    className="flex gap-2 items-center"
                  />
                  <div className="text-gray-500 uppercase text-xs">
                    <div className={statusColors.textDark}>
                      {bookingDepositStateFormatter.formatDepositState(
                        bookingDeposit.depositState,
                        {
                          isPaymentPending:
                            bookingDeposit.purchasePaymentPending,
                        },
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={clsx(
                    'text-sm md:text-base font-normal  text-gray-500',
                    // !isExpanded && 'hidden xs:block ',
                  )}
                >
                  <div className="flex gap-1">
                    {/* <div className="font-medium uppercase">
                    {paymentMethodFormatter.formatPaymentMethod(
                      purchasePayment.paymentMethod,
                    )}
                  </div> */}
                    <div
                      className={clsx(
                        'text-gray-500',
                        !isExpanded && 'hidden xs:block ',
                      )}
                    >
                      {dateService.formatUTC(depositDate, 'DD/MM/YYYY')}
                    </div>
                  </div>
                </div>
                {!isExpanded && (
                  <div className="hidden ml-2 lg:flex gap-1 items-center text-gray-400 uppercase text-xs">
                    {isExpanded ? (
                      <>
                        <AppIcons.action.extend className="cursor-pointer mr-1 h-5 w-5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
                      </>
                    ) : (
                      <>
                        <AppIcons.action.reduce className="cursor-pointer mr-1 h-5 w-5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
                      </>
                    )}
                    détails
                  </div>
                )}
              </div>
              <div className="text-sm md:text-base font-medium text-gray-500">
                <div className="flex gap-2">
                  {diver && (
                    <span>
                      {nameFormatter.formatFullName(diver, {
                        format: 'firstName-first',
                      })}
                    </span>
                  )}
                  {bookingDeposit.reference && (
                    <span className="italic text-gray-400">
                      {bookingDeposit.reference}
                    </span>
                  )}
                </div>
                {agency && (
                  <div>
                    {agency.name}{' '}
                    {bookingDeposit.details?.bookingAgencyCommissionRate && (
                      <span className="font-normal text-sm">{` ${bookingDeposit.details.bookingAgencyCommissionRate}% `}</span>
                    )}
                    {agency.details.locationType && (
                      <span className="font-normal text-sm text-gray-400">
                        (
                        {bookingAgencyFormatter.formatLocationType(
                          agency.details.locationType,
                        )}
                        )
                      </span>
                    )}
                  </div>
                )}
                {!isExpanded && (
                  <AppMultilineCommentIcons
                    className="block opacity-50"
                    showPrivate={
                      bookingDeposit.details?.privateComment?.trim().length > 0
                    }
                    showpublic={
                      bookingDeposit.details.customerComment?.trim().length > 0
                    }
                  />
                )}
              </div>
            </div>
            <div className="text-gray-600">
              <AppButtonMultiActions items={hamburgerMenuItems} />
            </div>
          </div>
        </div>
        <AppTransition type="scale" show={isExpanded}>
          <div className="grid gap-2">
            {(bookingDeposit.details?.privateComment?.trim()?.length > 0 ||
              bookingDeposit.details?.customerComment?.trim()?.length > 0) && (
              <div className="border-t border-gray-100 pt-2 flex gap-2 md:flex-row md:flex-wrap md:gap-4">
                <AppMultilineComment
                  type="private"
                  comment={bookingDeposit.details?.privateComment}
                />
                <AppMultilineComment
                  type="public"
                  comment={bookingDeposit.details?.customerComment}
                />
              </div>
            )}
          </div>
        </AppTransition>
      </div>
    </div>
  );
};
