/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_CURRENCIES } from '@mabadive/app-common-model';
import React from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppCurrencyChangeLabel } from 'src/business/club/modules/_common/ui';
import { AppInputBooleanSwitchRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import {
  useAppCurrency,
  useAppCurrencyOptions,
} from 'src/pages/_components/options';
import { ClubSettingsGeneralFormModel } from '../../../_model';

export const ClubSettingsGeneralPaymentsEditFormCurrency = ({
  form,
  fieldArray,
  field,
  fieldIndex,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
  fieldArray: UseFieldArrayReturn<
    ClubSettingsGeneralFormModel,
    'clubSettings.general.payments.currencies',
    'reference'
  >;
  field: FieldArrayWithId<
    ClubSettingsGeneralFormModel,
    'clubSettings.general.payments.currencies',
    'reference'
  >;
  fieldIndex: number;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const { fields, append, prepend, remove, swap, move, insert } = fieldArray;

  const formAttributeBaseName =
    `clubSettings.general.payments.currencies.${fieldIndex}` as const;

  const [
    paymentCurrencyIsoCode,
    defaultRate,
    defaultRateReverse,
    mainCurrencyIsoCode,
  ] = useWatch({
    control,
    name: [
      `${formAttributeBaseName}.currencyIsoCode`,
      `${formAttributeBaseName}.defaultRate`,
      `${formAttributeBaseName}.defaultRateReverse`,
      'clubSettings.general.currencyIsoCode',
    ],
  });

  const currencyOptions = useAppCurrencyOptions(APP_CURRENCIES);

  const mainCurrency = useAppCurrency(mainCurrencyIsoCode);
  const paymentCurrency = useAppCurrency(paymentCurrencyIsoCode);

  return (
    <div className={'border-l-4 border-gray-600 pl-2 grid sm:flex gap-4'}>
      <div className="flex justify-between items-end gap-4">
        <div className="flex flex-wrap gap-4">
          <AppFormControlRHF_Deprecated
            className="w-[16rem]"
            label="Devise"
            control={control}
            name={`${formAttributeBaseName}.currencyIsoCode`}
            required={true}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF {...props} options={currencyOptions} />
            )}
          />
          {paymentCurrencyIsoCode !== mainCurrencyIsoCode && (
            <>
              <AppFormControlRHF_Deprecated
                className="w-[10rem]"
                label="Taux par défaut"
                control={control}
                name={`${formAttributeBaseName}.defaultRate`}
                required={true}
                renderComponent={(props) => (
                  <AppInputRHF {...props} type="number" />
                )}
              />
              <AppFormControlRHF_Deprecated
                // className="w-[10rem]"
                label="Sens du change"
                control={control}
                name={`${formAttributeBaseName}.defaultRateReverse`}
                required={false}
                renderComponent={(props) => (
                  <div className="flex gap-2 flex-wrap">
                    <AppInputBooleanSwitchRHF {...props} />{' '}
                    <AppCurrencyChangeLabel
                      className="font-medium text-app-primary self-end"
                      mainCurrency={mainCurrency}
                      paymentCurrency={paymentCurrency}
                      defaultRate={defaultRate}
                      defaultRateReverse={defaultRateReverse}
                    />
                  </div>
                )}
              />
            </>
          )}
        </div>
        {paymentCurrencyIsoCode !== mainCurrencyIsoCode && fields.length > 1 && (
          <AppIconsAction.delete
            className="p-1 w-8 h-8 bg-gray-400 hover:bg-gray-600 rounded-full text-white cursor-pointer"
            onClick={() => {
              remove(fieldIndex);
            }}
          />
        )}
      </div>
    </div>
  );
};
