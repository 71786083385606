import { DiveSessionResumeFull } from '@mabadive/app-common-model';
import { diveSessionResumeFullGqlTransformer } from '@mabadive/app-common-services';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { graphqlClient } from 'src/_common-browser';
import { GraphqlClientQueryType } from 'src/_common-browser/graphql-client/apollo';
import {
  buildDiveSessionResumeFullCollectionGraphqlQuery,
  buildDiveSessionResumeFullOneGraphqlQuery,
} from './query-builder';

export const diveSessionResumeFullGraphqlFetcher = {
  findOne,
  findMany,
};

function findOne({
  diveSessionReference,
  queryType = 'query',
}: {
  diveSessionReference: string;
  queryType?: GraphqlClientQueryType;
}): Observable<DiveSessionResumeFull> {
  const queryDescription = buildDiveSessionResumeFullOneGraphqlQuery({
    diveSessionReference,
  });

  return graphqlClient.query
    .runOne<DiveSessionResumeFull>({ ...queryDescription }, { type: queryType })
    .pipe(
      tap((diveSessionResume) => {
        if (diveSessionResume) {
          // on renseigne participant.booking + bookingJourney à partir de bookingSessionParticipant
          // TODO: revoir le fetch et le model pour n'avoir qu'un objet participant qui contient tout
          diveSessionResumeFullGqlTransformer.transformResult(
            diveSessionResume,
          );
        }
      }),
      catchError((err) => {
        appLogger.warn(
          '[diveSessionResumeFullGraphqlFetcher.findOne] error fetching data',
          err,
        );
        return throwError(err);
      }),
    );
}

function findMany(
  {
    clubReference,
    diveCenterId,
    minDateInclusive,
    maxDateExclusive,
  }: {
    clubReference: string;
    diveCenterId: string;
    minDateInclusive: Date;
    maxDateExclusive: Date;
  },
  {
    type,
  }: {
    type: 'subscription' | 'query';
  },
): Observable<DiveSessionResumeFull[]> {
  const queryDescription = buildDiveSessionResumeFullCollectionGraphqlQuery({
    clubReference,
    diveCenterId,
    minDateInclusive,
    maxDateExclusive,
  });

  return graphqlClient.query
    .runOne<DiveSessionResumeFull[]>(queryDescription, {
      type,
    })
    .pipe(
      tap((diveSessionResumes) => {
        // appLogger.info(
        //   '[diveSessionResumeFullGraphqlFetcher.findMany] diveSessionResumes:',
        //   diveSessionResumes.length,
        // );
        if (diveSessionResumes) {
          // on renseigne participant.booking + bookingJourney à partir de bookingSessionParticipant
          // TODO: revoir le fetch et le model pour n'avoir qu'un objet participant qui contient tout
          diveSessionResumes.forEach((d, i) => {
            diveSessionResumeFullGqlTransformer.transformResult(d);
          });
        }
      }),
      catchError((err) => {
        appLogger.warn(
          '[diveSessionResumeFullGraphqlFetcher] error fetching data',
          err,
        );
        return throwError(err);
      }),
    );
}
