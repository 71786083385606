import { BaseEntity } from '@mabadive/app-common-model';
import { CollectionRepository } from './CollectionRepository.type';
import { CollectionRepositoryStoreAdapters } from './model';
import { collectionRepositoryReaderFactory } from './read';
import { collectionRepositoryWriterFactory, CollectionWritableRepositoryHooks } from './write';

export const collectionRepositoryFactory = {
  create,
};

function create<T extends BaseEntity>({
  mutationEntity,
  storeAdapters,
  synchronous,
  hooks,
}: {
  mutationEntity: string;
  synchronous: boolean;
  storeAdapters: CollectionRepositoryStoreAdapters<T>;
  hooks?: {
    write: CollectionWritableRepositoryHooks<T>;
  }
}): CollectionRepository<T> {

  return {
    meta: {
      mutationEntity,
      synchronous,
    },
    ...collectionRepositoryReaderFactory.create({
      mutationEntity,
      storeReadAdapter: storeAdapters.read,
    }),
    ...collectionRepositoryWriterFactory.create({
      mutationEntity,
      storeAdapters,
      synchronous,
      hooks: hooks ? hooks.write : undefined,
    }),
  } as CollectionRepository<T>;

}