/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import {
  AppFormControlV2,
  AppSingleAutocomplete2,
} from '../../../../../../lib/form';
import { AppMessageLight } from '../../../../../_core/modules/layout';
import { AppHeroIcons } from '../../../../../_core/modules/layout/icons';
import { ClubPlanningStaffEditModeToggleButton } from '../../../club-planning-light/club-planning-navigation';
import { SetPlanningConfigOptions } from '../../../club-planning-light/hooks';
import { ClubPlanningDayFull } from '../../../club-planning-light/model';
import { PlanningViewConfig } from '../../_model';
import { ClubPlanningStaffWeekStaffPresenceHeaderSessionsDay } from './ClubPlanningStaffWeekStaffPresenceHeaderSessionsDay';
import { ClubPlanningStaffWeekStaffPresenceLocalState } from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';

export const ClubPlanningStaffWeekStaffPresenceHeaderSessions = ({
  days,
  planningConfig,
  setPlanningConfig,
  localState,
  gridClassName,
  className,
}: {
  days: ClubPlanningDayFull[];
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  localState: ClubPlanningStaffWeekStaffPresenceLocalState;
  gridClassName?: string;
  className?: string;
}) => {
  const { data, actions, criteria } = localState;

  return (
    <div className={className}>
      <div className={gridClassName}>
        <div
          className={clsx(
            'lg:col-span-2 py-1 px-2 text-right text-gray-500 font-medium',
            planningConfig.viewPeriod === 'week' && 'hidden lg:block',
          )}
        >
          <div className="grid gap-4 text-left">
            {planningConfig.viewPeriod !== 'week' && (
              <AppFormControlV2
                className="w-80 max-w-full text-sm"
                label={'Filtrer par moniteur'}
                renderComponent={() => (
                  <AppSingleAutocomplete2
                    optionClassName="text-xs sm:text-sm"
                    value={criteria.filterStaffMemberId}
                    options={criteria.staffMemberOptions}
                    // customRenderOption => TODO utiliser cette méthode pour afficher un rendu personnalié, comme via staffMemberOptionsBuilder.buildStaffMemberOptions
                    onChange={(staffMemberId) => {
                      actions.updateStaffMemberFilter({
                        staffMemberId,
                      });
                    }}
                  />
                )}
              />
            )}
            <ClubPlanningStaffEditModeToggleButton
              planningConfig={planningConfig}
              setPlanningConfig={setPlanningConfig}
            />
            <AppMessageLight hideIcon className="hidden lg:block ">
              {planningConfig?.displayModeStaffPresenceOptions?.editMode ===
                'presence' && (
                <div className="text-gray-600">
                  <AppHeroIcons.info className="inline-block w-6 h-6 mr-1 text-blue-600" />{' '}
                  Assignez les jours de présence de chaque moniteur
                </div>
              )}
              {planningConfig?.displayModeStaffPresenceOptions?.editMode ===
                'times-roles' && (
                <div className="text-gray-600">
                  <AppHeroIcons.info className="inline-block w-6 h-6 mr-1 text-blue-600" />{' '}
                  Cliquez sur une horaire pour affecter rapidement un moniteur.
                </div>
              )}
            </AppMessageLight>
          </div>
        </div>
        {days.map((day, i) => (
          <ClubPlanningStaffWeekStaffPresenceHeaderSessionsDay
            key={i}
            day={day}
            localState={localState}
            className={clsx(
              'w-full py-1 text-app-xxs xl:text-xs',
              planningConfig.viewPeriod === 'week'
                ? 'border-l border-gray-200'
                : 'flex',
            )}
          />
        ))}
      </div>
    </div>
  );
};
