import { ClubSettings } from '@mabadive/app-common-model';
import { AppBar, Box, IconButton, Tab, Tabs, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { useLoadable } from 'src/business/_core/data/hooks';
import {
  AppAuth,
  authenticationChecker,
  authenticationClient,
  authenticationStore,
} from 'src/business/auth/services';
import { useClubSettings } from '../../../../../club/data/hooks';
import { appStylesHelper, useCacheableClasses } from '../../../root/theme';
import { AppIconsMaterial } from '../../icons';
import { useMediaSizeMaterialUI, useMediaSizeTailwind } from '../../services';
import { responsiveAttributesValues } from '../../services/responsiveAttributesValues.service';
import { AppTabBarTheme } from './AppTabBarTheme.type';
import { AppTabsBarTab } from './AppTabsBarTab.type';

export const AppTabsBar = <T extends string, C = any>({
  tabs,
  disableNavigation,
  context,
  onChange,
  value,
  theme,
  closeButton,
  children,
  className,
}: {
  tabs: AppTabsBarTab<T, C>[];
  disableNavigation?: boolean;
  context?: C;
  onChange?: (tab: AppTabsBarTab<T, C>) => void;
  value?: T;
  theme?: AppTabBarTheme;
  closeButton?: {
    enabled?: boolean;
    onClick?: () => void;
  };
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  const clubSettings = useClubSettings();
  const classes = useCacheableClasses({
    cacheKey: 'AppTabsBarTab',
    buildStyles,
  });

  function selectTab(tabId: T) {
    const tab = tabs.find((t) => t.id === tabId);
    if (onChange && tab && !disableNavigation) {
      onChange(tab);
    }
  }

  const { _loaded, filteredTabs } = useLoadable({
    debugName: 'AppTabsBarTab',
    initialValueFromLoadSnapshot: true,
    load: () =>
      authenticationStore.auth.get().pipe(
        distinctUntilChanged(authenticationClient.appAuthEquals),
        map((auth) => ({
          filteredTabs: filterTabs<T, C>({ tabs, auth, context, clubSettings }),
        })),
      ),
  });

  const mediaSizeTailwind = useMediaSizeTailwind();
  const mediaSizeMaterial = useMediaSizeMaterialUI();

  return !_loaded || filteredTabs.length <= 1 ? null : (
    <AppBar
      position="static"
      className={`${classes.root} theme-${theme} ${className ?? ''}`}
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="no-wrap"
        whiteSpace="nowrap"
      >
        <Tabs
          value={value}
          onChange={(e, tabId) => selectTab(tabId)}
          style={{
            gap: '0',
          }}
        >
          {filteredTabs.map((tab) => (
            <Tab
              disabled={disableNavigation}
              key={tab.id}
              value={tab.id}
              label={mediaSizeMaterial === 'xs' ? '' : tab.label}
              icon={tab.icon}
              style={
                mediaSizeTailwind === 'xxs'
                  ? {
                      minWidth: '50px',
                    }
                  : mediaSizeMaterial === 'xs'
                  ? {
                      minWidth: '70px',
                    }
                  : mediaSizeMaterial === 'sm'
                  ? {
                      fontSize: '10px',
                      minWidth: '75px',
                    }
                  : mediaSizeMaterial === 'md'
                  ? {
                      fontSize: '12px',
                      minWidth: '100px',
                    }
                  : mediaSizeMaterial === 'lg'
                  ? {
                      fontSize: '15px',
                      minWidth: '140px',
                    }
                  : {
                      fontSize: '15px',
                      minWidth: '150px',
                    }
              }
            />
          ))}
        </Tabs>
        {closeButton && closeButton.enabled !== false ? (
          <div className={classes.grow} />
        ) : null}
        {closeButton && closeButton.enabled !== false ? (
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() =>
              closeButton && closeButton.onClick
                ? closeButton.onClick()
                : undefined
            }
            className="close-button"
          >
            <AppIconsMaterial.close />
          </IconButton>
        ) : null}
        {children && children}
      </Box>
    </AppBar>
  );
};

function filterTabs<T extends string, C>({
  tabs,
  auth,
  clubSettings,
  context,
}: {
  tabs: AppTabsBarTab<T, C>[];
  auth: AppAuth;
  clubSettings: ClubSettings;
  context: C;
}): AppTabsBarTab<T, C>[] {
  return tabs.filter((tab) => {
    if (tab.auth && tab.auth.authenticationRequired) {
      if (!auth.isAuthenticated) {
        return false;
      }
    }
    if (tab.auth && tab.auth.requiredRoles) {
      if (
        !authenticationChecker.hasRequiredRoles(
          auth.user,
          tab.auth.requiredRoles,
        )
      ) {
        return false;
      }
    }
    if (tab.auth && tab.auth.requiredRolesOneOf) {
      if (
        !authenticationChecker.hasRequiredRolesOneOf(
          auth.user,
          tab.auth.requiredRolesOneOf,
        )
      ) {
        return false;
      }
    }
    if (tab.filter && !tab.filter(context)) {
      return false;
    }
    if (
      tab.checkAuth &&
      !tab.checkAuth({
        auth: auth?.user,
        az: auth?.user?.club?.authorizations,
        clubSettings,
      })
    ) {
      return false;
    }
    return true;
  });
}

function buildStyles(theme: Theme) {
  return makeStyles({
    grow: {
      flexGrow: 1,
    },
    root: {
      '&.MuiAppBar-root': {
        zIndex: 800,
        '&.theme-participant': {
          background: 'repeating-linear-gradient(45deg, #444, #666)',
        },
        '&.theme-session': {
          // background: APP_COLORS.session,
        },
        '&.theme-settings': {
          background: 'repeating-linear-gradient(45deg, #40194f, #4f3131)',
        },
      },
      '& .MuiTab-root': {
        ...responsiveAttributesValues(theme, {
          fontSize: appStylesHelper.getFontSizeResponsive('sm'),
          minWidth: { values: [60, 110, 130, 140, 160], suffix: 'px' },
        }),
      },
      '& .close-button': {
        width: '50px',
        height: '50px',
        color: 'rgba(0, 0, 0, 0.54)',
        backgroundColor: 'rgb(227, 227, 227)',
        '&:hover': {
          backgroundColor: '#ffffff',
        },
        marginTop: '10px',
        marginRight: '10px',
        [theme.breakpoints.only('xs')]: {
          width: '45px',
          height: '45px',
          marginTop: '2px',
          marginRight: '2px',
        },
      },
    },
  });
}
