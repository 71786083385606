/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubStaffMemberRole,
  DiveSessionStaffMemberTableModel,
} from '@mabadive/app-common-model';
import {
  arrayBuilder,
  diveModeColorClassBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { DiveSessionStaffMembersTableRowFree } from '../../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components';
import { DiveSessionSecuritySheetCardStaffMembersTableRowStaff } from './DiveSessionSecuritySheetCardStaffMembersTableRowStaff';

export function DiveSessionSecuritySheetCardStaffMembersTable({
  isMultiSessionConfigForStaff,
  staffFirstNameBefore,
  staffMembersTableModel,
  date,
  className = '',
}: {
  isMultiSessionConfigForStaff: boolean;
  staffFirstNameBefore: boolean;
  staffMembersTableModel: DiveSessionStaffMemberTableModel;
  date: Date;
  className?: string;
}) {
  const { groupsWithoutMonitors, rowsCount, staffMembersFull } =
    staffMembersTableModel;

  const availableRoles: ClubStaffMemberRole[] = useMemo(
    () =>
      arrayBuilder.filterDuplicated(
        staffMembersFull.reduce(
          (acc, x) => acc.concat(x.staffMember.profile.roles),
          [] as ClubStaffMemberRole[],
        ),
      ),
    [staffMembersFull],
  );

  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const clubSettings = useClubSettings();

  return (
    <table
      className={`w-full sm:w-auto divide-y divide-gray-200 text-gray-600 ${className}`}
    >
      <thead className="">
        <tr>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Nom
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell px-4 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Sessions
          </th>

          <th
            scope="col"
            className="px-4 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
          >
            {isMultiSessionConfigForStaff ? 'Plongée N°1' : 'Palanquées'}
          </th>
          {isMultiSessionConfigForStaff && (
            <th
              scope="col"
              className="px-4 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
            >
              Plongée N°2
            </th>
          )}
        </tr>
      </thead>
      {groupsWithoutMonitors.notAutonomous.length !== 0 && (
        <DiveSessionStaffMembersTableRowFree
          className=" bg-gray-100"
          context={'security-sheet'}
          showTimesColumn={false}
          label={
            <div className={'font-bold uppercase text-red-400'}>
              Palanquées à encadrer
            </div>
          }
        >
          <div className="flex flex-wrap gap-2">
            {groupsWithoutMonitors.notAutonomous.map((group) => (
              <div
                className={
                  'text-center px-2 py-0.5 rounded text-white text-xs font-bold'
                }
                style={{
                  backgroundColor: diveModeColorClassBuilder.getDiveModeColor(
                    group.diveMode,
                    { clubSettings },
                  ),
                }}
                key={group._id}
              >
                x{group.participantsCount}
              </div>
            ))}
          </div>
        </DiveSessionStaffMembersTableRowFree>
      )}
      {groupsWithoutMonitors.autonomous.length !== 0 && (
        <DiveSessionStaffMembersTableRowFree
          showTimesColumn={false}
          className=" bg-gray-100 text-xs"
          context={'security-sheet'}
          label={
            <div className={'font-bold uppercase text-gray-800'}>
              Palanquées en autonomie
            </div>
          }
        >
          <div className="flex flex-wrap gap-2">
            {groupsWithoutMonitors.autonomous.map((group) => (
              <div
                className={
                  'text-center px-2 py-0.5 rounded text-white text-xs font-bold'
                }
                style={{
                  backgroundColor: diveModeColorClassBuilder.getDiveModeColor(
                    group.diveMode,
                    { clubSettings },
                  ),
                }}
                key={group._id}
              >
                x{group.participantsCount}
              </div>
            ))}
          </div>
        </DiveSessionStaffMembersTableRowFree>
      )}
      {staffMembersFull.map((staffMemberFull, i) => (
        <DiveSessionSecuritySheetCardStaffMembersTableRowStaff
          key={i}
          date={date}
          staffMemberFull={staffMemberFull}
          isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
          availableRoles={availableRoles}
          staffFirstNameBefore={staffFirstNameBefore}
        />
      ))}
    </table>
  );
}
