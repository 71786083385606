import { AccountProfile, AuthUser } from '@mabadive/app-common-model';
import { MQueryDescription } from 'src/_common-browser';

export type AccountGraphqlQueryBuilderArgs = {
  userId: string;
};

export const accountGraphqlQueryBuilder = {
  buildAuthUser,
  buildAccountProfile,
};

function buildAuthUser({ userId }: AccountGraphqlQueryBuilderArgs) {
  const queryDescription: MQueryDescription<AuthUser> = {
    returnName: 'authUser',
    queryName: 'auth_user',
    returnType: 'first',
    where: `{_id: {_eq: "${userId}"}}`,
    returnAttributes: {
      _id: true,
      login: true,
      profile: true,
      clubReference: true,
      diveCenterId: true,
    },
  };

  return queryDescription;
}

function buildAccountProfile({ userId }: AccountGraphqlQueryBuilderArgs) {
  const queryDescription: MQueryDescription<AccountProfile> = {
    returnName: 'accountProfile',
    queryName: 'account_profile',
    returnType: 'first',
    where: `{userId: {_eq: "${userId}"}}`,
    returnAttributes: {
      _id: true,
      _version: true,
      _updatedAt: true,
      _createdAt: true,
      emailAddress: true,
      firstName: true,
      lastName: true,
      phoneNumber: true,
      staffRole: true,
      clubStaffMemberId: true,
      userId: true,
    },
  };

  return queryDescription;
}
