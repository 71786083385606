/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService, uuidGenerator } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { default as React, useCallback, useState } from 'react';
import {
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlRHF, AppFormLabel, AppInputRHF } from 'src/lib/form';
import { ClubMabadiveInvoice } from '../../../../../../../../../mabadive-libs/app-common-libs/app-common-model/src/entities/company/settings/club-settings/ClubMabadiveInvoice.type';
import { AppIconsAction } from '../../../../../../business/_core/modules/layout/icons';
import { DiveCenterEditFormModel } from '../model';
import {
  ClubSettingsForm_BillingDetails_Invoice,
  ClubSettingsForm_BillingDetails_Invoice_OnClickAction,
} from './ClubSettingsForm_BillingDetails_Invoice';

export const ClubSettingsForm_BillingDetails = ({
  form,
  className = '',
}: {
  form: UseFormReturn<DiveCenterEditFormModel>;
  className?: string;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();

  const [isIndividualContractor] = useWatch({
    control,
    name: ['clubSettings.publicData.legalInformation.isIndividualContractor'],
  });

  const invoicesFieldArray = useFieldArray({
    control,
    name: 'clubSettings.billingData.invoices',
    keyName: '_id',
  });

  const [modelAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();
  const isRecentAnimationUpdate =
    new Date().getTime() - modelAnimations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      field: FieldArrayWithId<
        DiveCenterEditFormModel,
        'clubSettings.billingData.invoices',
        '_id'
      >,
      fieldIndex: number,
      action: ClubSettingsForm_BillingDetails_Invoice_OnClickAction,
    ) => {
      const from = fieldIndex ?? invoicesFieldArray.fields.length - 1;
      console.log('xxx from:', from);
      const update = new Date();
      switch (action) {
        case 'move-down': {
          invoicesFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          invoicesFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'create': {
          const previousStep = invoicesFieldArray.fields[from];
          const invoiceId = uuidGenerator.random();
          const invoice: ClubMabadiveInvoice = previousStep
            ? {
                _id: invoiceId,
                year: previousStep.year + 1,
                periodBeginDate: dateService.add(
                  previousStep.periodBeginDate,
                  1,
                  'year',
                ),
                dueDate: dateService.add(previousStep.dueDate, 1, 'year'),
                taxRate: previousStep.taxRate,
                amountWithoutTax: previousStep.amountWithoutTax,
              }
            : {
                _id: invoiceId,
                year: new Date().getFullYear(),
              };
          invoicesFieldArray.insert(from + 1, invoice);
          break;
        }
        case 'delete': {
          // setModelAnimations({ update, remove: from });
          invoicesFieldArray.remove(from);
          // setTimeout(() => invoicesFieldArray.remove(from), 100);
          break;
        }
      }
    },
    [invoicesFieldArray],
  );
  return (
    <div className={clsx('grid gap-2', className)}>
      <h4 className="font-bold text-app-secondary uppercase">
        Informations de facturation
      </h4>
      <>
        <div className="grid gap-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2"></div>
          <AppFormControlRHF
            label={'Mention à ajouter sur facture'}
            control={control}
            name="clubSettings.billingData.billingDetails.billingMentions"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="text"
                multiline
                rows={2}
              />
            )}
          />
          <AppFormControlRHF
            label={'Commentaire'}
            control={control}
            name="clubSettings.billingData.billingDetails.comment"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="text"
                multiline
                rows={2}
              />
            )}
          />
        </div>
        <div className={clsx('w-full grid gap-2', className)}>
          <div className="flex justify-between gap-4 flex-wrap">
            <AppFormLabel label="Factures" />

            <Tippy
              delay={[1000, 100]}
              placement="top"
              content={'Ajouter une facture'}
            >
              <AppIconsAction.add
                className="p-1 w-8 h-8 bg-app-primary-light hover:bg-app-primary-dark rounded-full text-white cursor-pointer"
                onClick={() => {
                  onClick(undefined, undefined, 'create');
                }}
              />
            </Tippy>
          </div>
          <div className="w-full grid gap-4">
            {invoicesFieldArray.fields.map((field, fieldIndex) => {
              return (
                <ClubSettingsForm_BillingDetails_Invoice
                  key={`invoice_${fieldIndex}`}
                  form={form}
                  fieldArray={invoicesFieldArray}
                  fieldIndex={fieldIndex}
                  className={clsx(
                    invoicesFieldArray.fields.length > 1 &&
                      'border-l-4 border-gray-600 pl-2',
                    isRecentAnimationUpdate &&
                      modelAnimations?.up === fieldIndex &&
                      'animate-move-up',
                    isRecentAnimationUpdate &&
                      modelAnimations?.down === fieldIndex &&
                      'animate-move-down',
                    isRecentAnimationUpdate &&
                      modelAnimations?.clone === fieldIndex &&
                      'animate-create',
                    isRecentAnimationUpdate &&
                      modelAnimations?.remove === fieldIndex &&
                      'animate-remove',
                  )}
                  onClick={(action) => onClick(field, fieldIndex, action)}
                />
              );
            })}
          </div>
        </div>
      </>
    </div>
  );
};
