import 'fontsource-roboto/latin.css';
import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import App from './App';
import './index.scss';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// NOTE: pas mal de problèmes avec les service workers (cache, téléchargement du fichier excel, etc...)
// serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: registration => {
//     // alert('New version available!  Ready to update?');
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//     }
//     window.location.reload();
//   },
// });

WebFont.load({
  google: {
    families: ['Rock+Salt:400,700', 'cursive'],
  },
});
