/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';

import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ClubOfferNameDetailsLabel } from 'src/business/club/modules/club-diver-participant/components';
import { ClubSettingsProductOffersFormModel } from '../_model';
import { ClubSettingsProductOffersDialogLocalState } from '../useClubSettingsProductOffersDialogLocalState.hook';
import { ClubSettingsProductOffersFormOffer_AdditionalProduct } from './ClubSettingsProductOffersFormOffer_AdditionalProduct';
import { ClubSettingsProductOffersFormOffer_Explo } from './ClubSettingsProductOffersFormOffer_Explo';
import { ClubSettingsProductOffersFormOffer_FirstDive } from './ClubSettingsProductOffersFormOffer_FirstDive';
import { ClubSettingsProductOffersFormOffer_Other_PMT_ACC } from './ClubSettingsProductOffersFormOffer_Other_PMT_ACC';
import { ClubSettingsProductOffersFormOffer_Training } from './ClubSettingsProductOffersFormOffer_Training';
import { useClubSettingsProductOffersFormOfferNameDetails } from './useClubSettingsProductOffersFormOfferNameDetails.hook';

// inspiré de ClubOfferDiveMultiEditorRow

export const ClubSettingsProductOffersFormOffer = ({
  form,
  offerField,
  offersFieldArray,
  localState,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  localState: ClubSettingsProductOffersDialogLocalState;
}) => {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const isProduct = offerField.productPackage.type === 'product';

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const offerFieldIndex = useMemo(
    () =>
      offersFieldArray.fields.findIndex(
        (x) => x.reference === offerField.reference,
      ),
    [offerField.reference, offersFieldArray.fields],
  );

  const i = offerFieldIndex;

  const showDetails = offersFieldArray.fields.length === 1;

  const nameDetails = useClubSettingsProductOffersFormOfferNameDetails({
    form,
    offerField,
    offersFieldArray,
  });

  const diveMode = offerField.productPackage.diveAttributes?.diveMode;

  const isExplo =
    offerField.productPackage.type === 'dive' &&
    (diveMode === 'autoSupervised' ||
      diveMode === 'supervised' ||
      diveMode === 'autonomous' ||
      diveMode === 'instructor');

  const createOfferPayload = useMemo(
    () =>
      (localState.state.initialState.createOffersPayloads ?? []).find(
        (x) => x.offer.reference === offerField.reference,
      ),
    [localState.state.initialState.createOffersPayloads, offerField.reference],
  );

  return (
    <div className={clsx('grid gap-2')}>
      <div className="flex gap-4 justify-between">
        <div className="">
          <ClubOfferNameDetailsLabel
            className="font-bold text-app-blue"
            nameDetails={nameDetails}
          />
          {createOfferPayload?.meta?.createDisplayComment && (
            <div className="font-bold text-gray-600">
              {createOfferPayload.meta.createDisplayComment}
            </div>
          )}
        </div>
        <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
          <AppIconsAction.delete
            className={clsx(
              'p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer',
            )}
            onClick={() => {
              offersFieldArray.remove(i);
            }}
          />
        </Tippy>
      </div>
      {offerField.productPackage.type === 'training' ? (
        <ClubSettingsProductOffersFormOffer_Training
          form={form}
          offerField={offerField}
          offerFieldIndex={offerFieldIndex}
          offersFieldArray={offersFieldArray}
          localState={localState}
        />
      ) : diveMode === 'first-dive' ? (
        <ClubSettingsProductOffersFormOffer_FirstDive
          form={form}
          offerField={offerField}
          offerFieldIndex={offerFieldIndex}
          offersFieldArray={offersFieldArray}
        />
      ) : isExplo ? (
        <ClubSettingsProductOffersFormOffer_Explo
          form={form}
          offerField={offerField}
          offerFieldIndex={offerFieldIndex}
          offersFieldArray={offersFieldArray}
          localState={localState}
        />
      ) : isProduct ? (
        <ClubSettingsProductOffersFormOffer_AdditionalProduct
          form={form}
          offerField={offerField}
          offerFieldIndex={offerFieldIndex}
          offersFieldArray={offersFieldArray}
        />
      ) : (
        <ClubSettingsProductOffersFormOffer_Other_PMT_ACC
          form={form}
          offerField={offerField}
          offerFieldIndex={offerFieldIndex}
          offersFieldArray={offersFieldArray}
        />
      )}
    </div>
  );
};
