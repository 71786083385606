/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppPaymentMethod,
  PURCHASE_PAYMENT_STATES,
  PurchasePaymentState,
} from '@mabadive/app-common-model';
import { purchasePaymentStateFormatter } from '@mabadive/app-common-services';
import { useMemo, useState } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useAppPaymentMethodsOptions } from 'src/pages/_components/options';

export type MonthId = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

const PURCHASE_PAYMENT_STATES_OPTIONS = PURCHASE_PAYMENT_STATES.map((value) => {
  const option: ValueLabel<PurchasePaymentState> = {
    label: purchasePaymentStateFormatter
      .formatPaymentState(value)
      .toUpperCase(),
    value,
  };
  return option;
});

export function usePaymentsListPageFilters() {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const isStripeEnabled =
    clubSettings.general.payments?.online?.stripe?.enabled;

  const [searchText, setSearchText] = useState('');
  const [paymentState, setPaymentState] = useState<PurchasePaymentState>();
  const [paymentMethod, setPaymentMethod] = useState<AppPaymentMethod>();
  const [bookingAgencyId, setBookingAgencyId] = useState<string>();

  const paymentStateOptions = useMemo(() => {
    if (isStripeEnabled) {
      return PURCHASE_PAYMENT_STATES_OPTIONS;
    } else {
      return PURCHASE_PAYMENT_STATES_OPTIONS.filter(
        (x) => x.value !== 'authorized',
      );
    }
  }, [isStripeEnabled]);

  const paymentMethodOptions = useAppPaymentMethodsOptions('club+online');

  return {
    searchText,
    setSearchText,
    paymentState,
    setPaymentState,
    paymentMethod,
    setPaymentMethod,
    paymentStateOptions,
    paymentMethodOptions,
    bookingAgencyId,
    setBookingAgencyId,
  };
}

export type PaymentsListPageFilters = ReturnType<
  typeof usePaymentsListPageFilters
>;
