import {
  BookingProduct,
  DiveSessionResumeFull,
  DiveSessionStatus,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from '../club-massive-update';
import { participantBookingStateUpdator } from './participantBookingStateUpdator.service';

export const clubDiveSessionClientUpdator = {
  updateDiveSessionStatus,
  deleteDiveSession,
};

async function updateDiveSessionStatus({
  diveSessionResume,
  updateParticipants,
  status,
}: {
  diveSessionResume: DiveSessionResumeFull;
  updateParticipants: boolean;
  status: DiveSessionStatus;
}): Promise<unknown> {
  const patchOperations = jsonPatcher.compareObjects(
    diveSessionResume,
    {
      ...diveSessionResume,
      status,
    },
    {},
  );
  const updatedDiveSessions = [
    {
      pk: diveSessionResume._id,
      patchOperations,
    },
  ];

  const payload: ProMultiOperationPayload = {
    logContext: 'update session status',
    updatedDiveSessions,
  };
  if (updateParticipants) {
    // update participants booking state

    const participants = diveSessionResume.bookingSessionParticipants.map(
      (bookingSessionParticipant) => {
        const clubParticipant = diveSessionResume.participants.find(
          (p) => p._id === bookingSessionParticipant.participantId,
        );

        const bookingProduct: Pick<
          BookingProduct,
          | '_id'
          | 'bookingProductStatus'
          | 'bookingProductState'
          | 'bookingProductHistory'
        > = bookingSessionParticipant.bookingProducts.find(
          (x) => x.type === 'dive',
        );

        return { clubParticipant, bookingProduct, bookingSessionParticipant };
      },
    );

    const {
      updatedClubParticipants,
      updatedBookingProducts,
      updatedBookingSessionParticipants,
    } = participantBookingStateUpdator.generateBookingStatusUpdatePatchs({
      participants,
      status,
      user: 'club',
      comment: undefined,
    });

    payload.updatedClubParticipants = updatedClubParticipants;
    payload.updatedBookingProducts = updatedBookingProducts;
    payload.updatedBookingSessionParticipants =
      updatedBookingSessionParticipants;
  }

  return clubMassiveUpdatorClient.update(payload);
}

async function deleteDiveSession({
  diveSessionId,
}: {
  diveSessionId: string;
}): Promise<unknown> {
  const payload: ProMultiOperationPayload = {
    logContext: 'delete session',
    deletedDiveSessionsIds: [diveSessionId],
  };

  return clubMassiveUpdatorClient.update(payload);
}
