import { ClubResume } from '@mabadive/app-common-model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { useLoadable } from 'src/business/_core/data/hooks';
import { currentClubResumeStore } from 'src/business/_core/data/store-repository';

export function useClubResume(): ClubResume {
  const { clubResume } = useLoadable({
    debugName: 'useClubResume',
    initialValueFromLoadSnapshot: true,
    load: () =>
      currentClubResumeStore.currentClubResume.get().pipe(
        // startWith('auto' as PlanningDisplayMode),
        distinctUntilChanged(),
        map((clubResume) => ({ clubResume })),
      ),
  });

  return clubResume;
}
