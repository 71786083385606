/* eslint-disable @typescript-eslint/no-unused-vars */
import { OnlineBookingState } from '@mabadive/app-common-model';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export type MonthId = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type ClubOrdersListPageFiltersFormModel = {
  bookingDate?: Date;
  onlineBookingStates: OnlineBookingState[];
};

export function useClubOrdersListPageFilters() {
  const [searchText, setSearchText] = useState('');

  const form = useForm<ClubOrdersListPageFiltersFormModel>({
    defaultValues: {
      bookingDate: undefined,
      onlineBookingStates: [
        'draft', // en attente de paiement
        'new', // en attente de validation par le club
        'pending-club', // en attente de validation par le club
        'pending-customer', // en attente de retour client
        'confirmed', // confirmé
      ],
    },
  });
  // dateService.getUTCDateSetTime(new Date()),
  return {
    form,
    searchText,
    setSearchText,
  };
}
