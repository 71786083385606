import {
  Booking,
  BookingMember,
  BookingSession,
  BookingSessionParticipant,
  ClubDiver,
} from '@mabadive/app-common-model';
import { changeDescriptorAggregator } from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { DiverBookingPageAggregatedDataCore } from '../../../../DiverBookingPage/models';
import { DiveCenterBookingPageUpdateState } from '../../model';

export const diveCenterBookingPageAggregatedBookingResumeBuilderCore = {
  aggregateDataCore,
};

function aggregateDataCore({
  updateState,
  entities,
}: {
  updateState: DiveCenterBookingPageUpdateState;
  entities: DiverBookingPageAggregatedDataCore; // MODIF par rapport à l'appli plongeur ########### DiveCenterBookingPageUpdateState;
}): DiverBookingPageAggregatedDataCore {
  const bookings: Booking[] = changeDescriptorAggregator.aggregateMany(
    updateState.bookingsChanges,
    {
      pk: '_id',
      initials: entities.bookings,
      ignoreErrors: true,
      appLogger,
      logPrefix: 'bookings',
    },
  );
  const bookingSessions: BookingSession[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.bookingSessionsChanges,

      {
        pk: '_id',
        initials: entities.bookingSessions,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'bookingSessions',
      },
    );
  const bookingMembers: BookingMember[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.bookingMembersChanges,
      {
        pk: '_id',
        initials: entities.bookingMembers,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'bookingMembers',
      },
    );

  const bookingSessionParticipants: BookingSessionParticipant[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.bookingSessionParticipantsChanges,

      {
        pk: '_id',
        initials: entities.bookingSessionParticipants,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'bookingSessionParticipants',
      },
    );

  // const bookingProducts: BookingProduct[] =
  //   changeDescriptorAggregator.aggregateMany(
  //     updateState.bookingProductsChanges,
  //     {
  //       pk: '_id',
  //       initials: entities.bookingProducts,
  //       ignoreErrors: true,
  //       appLogger,
  //       logPrefix: 'bookingProducts',
  //     },
  //   );

  const clubParticipants = changeDescriptorAggregator.aggregateMany(
    updateState.clubParticipantsChanges,
    {
      pk: '_id',
      initials: entities.clubParticipants,
      ignoreErrors: true,
      appLogger,
      logPrefix: 'clubParticipants',
    },
  );

  const divers: ClubDiver[] = changeDescriptorAggregator.aggregateMany(
    updateState.diversChanges,

    {
      pk: '_id',
      initials: entities.divers,
      ignoreErrors: true,
      appLogger,
      logPrefix: 'divers',
    },
  );

  const aggregatedDataCore: DiverBookingPageAggregatedDataCore = {
    ...entities,
    divers,
    bookings,
    bookingSessions,
    // bookingProducts,
    bookingMembers,
    bookingSessionParticipants,
    clubParticipants,
  };
  return aggregatedDataCore;
}
