import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  MQueryDescription,
  graphqlClient,
} from '../../../../../../../_common-browser';
import { BookingGql1CollectionCriteria } from '../../../../../../_core/data/store-repository';
import { appLogger } from '../../../../../../_core/modules/root/logger';

export const onlineBookingNotificationsFetcher = { fetchNotificationsCount };

function fetchNotificationsCount(criteria: {
  clubReference: string;
  diveCenterId: string;
}): Observable<number> {
  const query: MQueryDescription<any> = buildGraphqlQuery(criteria);

  return graphqlClient.query
    .runOne<any>(query, {
      type: 'subscription',
      name: 'onlineBookingNotificationsFetcher',
    })
    .pipe(
      map((res) => res?.aggregate?.onlineBookingSubmittedCount),
      catchError((err) => {
        appLogger.warn(
          '[onlineBookingNotificationsFetcher] error fetching data',
          err,
        );
        return throwError(err);
      }),
    );
}

function buildGraphqlQuery({
  clubReference,
  diveCenterId,
}: BookingGql1CollectionCriteria) {
  const bookingFilters: string[] = [];
  bookingFilters.push(`clubReference: {_eq: "${clubReference}"}`);
  bookingFilters.push(
    `_or: [{diveCenterId: {_eq: "${diveCenterId}"}}, {isSharedBooking: {_eq: true}}]`,
  );
  bookingFilters.push('state: {_eq: "new"}');

  const where = `{_and:[${bookingFilters.map((x) => `{${x}}`).join(',')}]}`; // NOTE: obligé d'utiliser un _and:[] pour y inclure potentiellement plusieurs _or

  const queryDescription: MQueryDescription<any> = {
    returnName: 'club_ecommerce_online_booking_aggregate',
    queryName: 'club_ecommerce_online_booking_aggregate',
    returnType: 'all',
    where,
    returnAttributes: `
      aggregate {
        onlineBookingSubmittedCount: count 
      } 
    `,
  };

  return queryDescription;
}
