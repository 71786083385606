export type DateServiceIsoWeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const dateServiceCore = {
  getUTCWeekDayIso,
  add,
  addDays,
  addMonth,
  addYear,
  toISOStringDate,
  getUTCDateSetTime,
  truncateTimeUTC,
  utcDateWithoutTime,
  isValidHoursMinutes,
};

function isValidHoursMinutes(time: {
  hours: number;
  minutes: number;
}): boolean {
  return (
    time?.hours >= 0 &&
    time?.hours <= 23 &&
    time?.minutes >= 0 &&
    time?.minutes <= 59
  );
}

function utcDateWithoutTime(date?: Date): Date {
  date = date ? new Date(date) : new Date();
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
}
function getUTCDateSetTime(
  date?: Date,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
) {
  if (!date) {
    date = new Date();
  } else if (!date.getUTCFullYear) {
    date = new Date(date);
  }
  const timestamp = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    hours,
    minutes,
    seconds,
    milliseconds,
  );

  const utcString = new Date(timestamp).toUTCString();

  const utcDate = new Date(utcString);

  return utcDate;
}

function truncateTimeUTC(date: Date): Date {
  // on considère que la date entrée est une date locale, mais on la converti en UTC
  // ex: 13/11/2022 22:36 (GMT-8) => 13/11/2022 00:00 (UTC)

  if (!date) {
    return date;
  }
  const timestamp = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0,
  );

  const utcString = new Date(timestamp).toUTCString();

  const utcDate = new Date(utcString);

  return utcDate;
}
function getUTCWeekDayIso(date: Date): DateServiceIsoWeekDay {
  return (((date.getUTCDay() + 6) % 7) + 1) as DateServiceIsoWeekDay;
}
function add(date: Date, nb: number, unit: 'day' | 'month' | 'year'): Date {
  if (unit === 'day') {
    return addDays(date, nb);
  }
  if (unit === 'month') {
    return addMonth(date, nb);
  }
  if (unit === 'year') {
    return addYear(date, nb);
  }
}
function addDays(date: Date, nb: number): Date {
  date = new Date(date);
  date.setUTCDate(date.getUTCDate() + nb);
  return date;
}
function addMonth(date: Date, nb: number): Date {
  date = new Date(date);
  date.setUTCMonth(date.getUTCMonth() + nb);
  return date;
}
function addYear(date: Date, nb: number): Date {
  date = new Date(date);
  date.setFullYear(date.getFullYear() + nb);
  return date;
}
function toISOStringDate(date: Date): string {
  return date.toISOString().substring(0, 10);
}
