import { DiveCenterResume } from '@mabadive/app-common-model';
import { commonParticipantBuilder } from '@mabadive/app-common-services';
import React, { useCallback } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AggregatedBookingSessionFull,
  BookingParticipantEditorDialogState,
  BookingParticipantEditorDiverMember,
  BookingTabModel,
  DiverBookingPageUpdateState,
  DiverBookingUpdateProductParticipant,
  PRO_BookingMemberFull_WithDocs,
} from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
import { diverBookingPageUpdateStateManager } from '../../02.update-state';

export function useDiverBookingPageCreateParticipantQuick({
  diveCenterResume,
  setUpdateState,
  updateState,
  setBookingParticipantEditorState,
}: {
  diveCenterResume: DiveCenterResume;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
  setBookingParticipantEditorState: React.Dispatch<
    React.SetStateAction<BookingParticipantEditorDialogState>
  >;
}) {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;

  return useCallback(
    ({
      bookingTabModel,
      bookingSessionFull,
      bookingMemberFull,
    }: {
      bookingTabModel: BookingTabModel;
      bookingSessionFull: AggregatedBookingSessionFull;
      bookingMemberFull: PRO_BookingMemberFull_WithDocs;
    }) => {
      const bookingId = bookingTabModel.bookingId;
      const diver = bookingMemberFull.diver;
      const bookingMember = bookingMemberFull.bookingMember;

      if (bookingMemberFull.diver?.defaultDiveConfig?.diveMode) {
        const newBookingProductParticipant: DiverBookingUpdateProductParticipant =
          commonParticipantBuilder.createParticipantFromDefaultDiveConfig({
            bookingMember,
            diver,
            diveCenterResume,
            bookingSessionId: bookingSessionFull.bookingSession._id,
            diveSessionReference: bookingSessionFull.diveSession.reference,
            bookingId,
            defaultDiveMode:
              bookingSessionFull.diveSession.details?.defaultDiveMode,
            sessionsCount: bookingSessionFull.diveSession.sessionsCount,
          });

        setUpdateState(
          diverBookingPageUpdateStateManager.addNewParticipantToState({
            updateState,
            newBookingProductParticipant,
          }),
          {
            action: 'DiverBookingPageCreateParticipantQuick',
          },
        );
      } else {
        // open "create participant" dialog
        const diver = bookingMemberFull.diver;
        const bookingMember = bookingMemberFull.bookingMember;

        const participant: BookingParticipantEditorDiverMember = {
          bookingId,
          diver,
          bookingMember,
          bookingSessionId: bookingSessionFull.bookingSession._id,
          diveSession: bookingSessionFull.diveSession,
          docResumes: bookingMemberFull.docResumes,
        };
        setBookingParticipantEditorState({
          isOpen: true,
          mode: 'create-participant',
          participant,
        });
      }
    },
    [
      diveCenterResume,
      setBookingParticipantEditorState,
      setUpdateState,
      updateState,
    ],
  );
}
