import { useWindowEventListener } from 'rooks';

export function useWindowUnloadAlert(enabled: boolean) {
  return useWindowEventListener('beforeunload', (e) => {
    if (enabled) {
      // fermeture de la fenête avec modifications en cours!
      const confirmationMessage =
        'Vous avez des modifications en cours, êtes-vous sûr de vouloir quitter la page?';
      ((e || window.event) as BeforeUnloadEvent).returnValue =
        confirmationMessage;
      // Note: le message n'est pas toujours affiché suivant les browsers, parfois c'est un message générique: https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent
      return confirmationMessage;
    }
  });
}
