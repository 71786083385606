/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC, useCallback, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  AppPriceLabel,
  AppPriceLabelRatio,
} from 'src/business/club/modules/_common/ui';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DiverPurchasePaymentEditorDialogStateMode } from '../../../models';
import { BillingParticipantPurchaseResumeTableDiverName } from '../../BillingParticipantPurchaseResumeTable/BillingParticipantPurchaseResumeTableDiverName';
import { DiverPurchasePaymentEditorBookingDepositChangesModel } from '../DiverPurchasePaymentEditorBookingDepositChangesModel.type';
import { DiverPurchasePaymentStatusLabel } from '../DiverPurchasePaymentStatusLabel';
import { DiverPurchasePaymentEditorFormModel } from './DiverPurchasePaymentEditorForm';
import { DiverPurchasePaymentEditorFormModelBookingDeposit } from './DiverPurchasePaymentEditorForm/DiverPurchasePaymentEditorFormModelBookingDeposit.type';

export const DiverPurchasePaymentEditorPackagesTableRow_BookingDeposit: FC<{
  isEditEnabled: boolean;
  mode: DiverPurchasePaymentEditorDialogStateMode;
  form: UseFormReturn<DiverPurchasePaymentEditorFormModel>;
  bookingDepositChanges: DiverPurchasePaymentEditorBookingDepositChangesModel;
  rowIndex: number;
  showCheckboxCells: boolean;
  bookingDeposits: DiverPurchasePaymentEditorFormModelBookingDeposit[];
}> = ({
  isEditEnabled,
  mode,
  form,
  bookingDepositChanges,
  rowIndex,
  showCheckboxCells,
  bookingDeposits,
}) => {
  const mainCurrency = useAppCurrencyMain();

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const isSelectable = useMemo(
    () => isEditEnabled && mode === 'create',
    [isEditEnabled, mode],
  );

  const toggleSelection = useCallback(() => {
    if (isSelectable) {
      setValue(
        `bookingDeposits.${rowIndex}.selected`,
        !bookingDeposits[rowIndex].selected,
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        },
      );
    }
  }, [bookingDeposits, rowIndex, setValue, isSelectable]);

  return (
    <tr>
      <td
        className={`px-2 py-1 sm:px-4 sm:py-2 ${
          showCheckboxCells ? '' : 'hidden'
        }`}
      >
        <AppFormControlRHF_Deprecated
          className="w-full"
          control={control}
          name={`bookingDeposits.${rowIndex}.selected` as any}
          renderComponent={(props) => (
            <AppInputBooleanRHF {...props} type="checkbox" />
          )}
        />
      </td>

      <td
        className={`px-2 py-1 sm:pr-4 sm:py-2 ${
          isSelectable ? 'cursor-pointer' : ''
        }`}
        onClick={toggleSelection}
      >
        <BillingParticipantPurchaseResumeTableDiverName
          showResidentType={true}
          diver={bookingDepositChanges.diver}
          opacity={bookingDeposits[rowIndex].selected ? undefined : 60}
          className={'flex-shrink text-xs md:text-sm'}
        />
        {/* <DiverPurchasePaymentEditorPurchasePackageLabel
          className="lg:hidden"
          opacity={bookingDeposits[rowIndex].selected ? undefined : 60}
          purchasePackage={bookingDepositChanges.updatedBookingDeposit}
        /> */}
        Acompte
      </td>

      <td
        className={`hidden lg:table-cell px-2 py-1 sm:pr-4 sm:py-2 ${
          isSelectable ? 'cursor-pointer' : ''
        }`}
        onClick={toggleSelection}
      >
        {/* <DiverPurchasePaymentEditorPurchasePackageLabel
          opacity={bookingDeposits[rowIndex].selected ? undefined : 60}
          purchasePackage={bookingDepositChanges.updatedBookingDeposit}
        /> */}
        Acompte
      </td>

      <td
        className={`hidden md:table-cell px-2 py-1 sm:pr-4 sm:py-2 ${
          isSelectable ? 'cursor-pointer' : ''
        }`}
        onClick={toggleSelection}
      >
        {
          <div
            className={`${
              bookingDeposits[rowIndex].selected ? '' : 'opacity-50'
            }`}
          >
            <>
              <AppPriceLabel
                amount={bookingDepositChanges.updatedBookingDeposit.price}
                mainCurrency={mainCurrency}
              />
            </>
          </div>
        }
      </td>
      <td
        className={`hidden sm:table-cell px-2 py-1 sm:pr-4 sm:py-2 ${
          isSelectable ? 'cursor-pointer' : ''
        }`}
        onClick={toggleSelection}
      >
        <AppPriceLabel
          amount={
            (bookingDepositChanges.updatedBookingDeposit.payedAmount ?? 0) -
            (bookingDepositChanges.updatedAmountForBookingDeposit ?? 0)
          }
          mainCurrency={mainCurrency}
          className={`${
            bookingDeposits[rowIndex].selected ? '' : 'opacity-50'
          }`}
        />
      </td>
      <td
        className={clsx(
          'px-2 py-1 sm:pr-4 sm:py-2',
          isSelectable && 'cursor-pointer',
          mode === 'create' ? 'hidden lg:table-cell' : 'hidden',
        )}
        onClick={toggleSelection}
      >
        <DiverPurchasePaymentStatusLabel
          className={`${
            bookingDeposits[rowIndex].selected ? '' : 'opacity-50'
          }`}
          purchasePaymentStatus={
            bookingDepositChanges.initialBookingDeposit.purchasePaymentStatus
          }
          purchasePaymentPending={
            bookingDepositChanges.initialBookingDeposit.purchasePaymentPending
          }
        />
      </td>
      <td className="px-2 py-1 sm:px-4 sm:py-2">
        {bookingDeposits[rowIndex].selected && (
          <>
            {isEditEnabled && mode === 'create' ? (
              <AppFormControlRHF_Deprecated
                className="w-full"
                control={control}
                name={`bookingDeposits.${rowIndex}.amount` as any}
                renderComponent={(props) => (
                  <AppInputRHF
                    {...props}
                    type="number"
                    className="w-24 text-center font-bold"
                  />
                )}
              />
            ) : (
              <span className="text-status-active">
                <AppPriceLabel
                  amount={bookingDeposits[rowIndex].amount}
                  mainCurrency={mainCurrency}
                />
              </span>
            )}
          </>
        )}
      </td>
      <td className={'hidden sm:table-cell px-2 py-1 sm:px-4 sm:py-2'}>
        {bookingDeposits[rowIndex].selected && (
          <AppPriceLabelRatio
            amount={bookingDepositChanges.updatedBookingDeposit.payedAmount}
            total={bookingDepositChanges.updatedBookingDeposit.price}
            mainCurrency={mainCurrency}
            hideTotalIfSameAmount={true}
          />
        )}
      </td>
      <td className={'px-2 py-1 sm:px-4 sm:py-2'}>
        {bookingDeposits[rowIndex].selected && (
          <AppPriceLabel
            amount={bookingDepositChanges.remainingAmount}
            mainCurrency={mainCurrency}
          />
        )}
      </td>
      <td className="hidden md:table-cell px-2 py-1 sm:px-4 sm:py-2">
        {bookingDeposits[rowIndex].selected && (
          <DiverPurchasePaymentStatusLabel
            purchasePaymentStatus={
              bookingDepositChanges.updatedBookingDeposit.purchasePaymentStatus
            }
            purchasePaymentPending={
              bookingDepositChanges.updatedBookingDeposit.purchasePaymentPending
            }
          />
        )}
      </td>
    </tr>
  );
};
