/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubStaffMemberDailyAvailabilities,
  ClubStaffMemberDailyAvailability,
  ClubStaffMemberProfile,
  WEEK_DAYS_ISO,
} from '@mabadive/app-common-model';
import { dateService, uuidGenerator } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  StaffMemberEditDialogInitialState,
  StaffMemberEditFormModel,
} from './_model';

export function useStaffMemberEditDialogLocalState({
  initialState,
}: {
  initialState: StaffMemberEditDialogInitialState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const initialFormValue: Partial<StaffMemberEditFormModel> = useMemo(() => {
    if (initialState.mode === 'create') {
      const createInitialValue: Partial<StaffMemberEditFormModel> = {
        staffMember: {
          profile: {
            roles: [
              'diving-director',
              'scuba-diving-instructor',
              'surface-security',
            ],
          } as ClubStaffMemberProfile,
          availabilities: [
            {
              _id: uuidGenerator.random(),
              beginDate: dateService.add(
                dateService.getUTCDateSetTime(new Date(), 0),
                -1,
                'week',
              ),
              defaultWorkingDays: WEEK_DAYS_ISO.reduce((acc, isoWeekDay) => {
                const av: ClubStaffMemberDailyAvailability = {
                  availabilityPeriod: 'day',
                };
                acc[isoWeekDay] = av;
                return acc;
              }, {} as ClubStaffMemberDailyAvailabilities),
              jobType: 'regular',
              endDate: undefined,
            },
          ],
        },
      };
      return createInitialValue;
    } else {
      return initialState.defaultValue;
    }
  }, [initialState.defaultValue, initialState.mode]);

  const form = useForm<StaffMemberEditFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  return {
    data: {},
    state: {
      form,
      hasFormChanges,
      initialState,
      // filters,
      // setFilters,
      // trainingCommercialCategoriesOptions,
      // organizationsOptions,
    },
  };
}

export type StaffMemberEditDialogLocalState = ReturnType<
  typeof useStaffMemberEditDialogLocalState
>;
