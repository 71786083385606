/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  APP_PAYMENT_METHODS_ALL,
  AppPaymentMethod,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks/useClubResume.hook';

export type AppPaymentMethodsListScope =
  | 'all'
  | 'club'
  | 'club+online'
  | 'credit-note+online';

export function useAppPaymentMethods(
  scope: AppPaymentMethodsListScope = 'club',
): AppPaymentMethod[] {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const clubReference = clubResume.reference;

  const forceStripe =
    scope === ('club+online' || 'credit-note+online') &&
    clubSettings.general?.payments?.online?.platformsEnabled &&
    clubSettings.general?.payments?.online?.stripe?.enabled;

  return useMemo(() => {
    if (scope === 'all') {
      return APP_PAYMENT_METHODS_ALL;
    }
    if (scope === 'credit-note+online') {
      const paymentMethods: AppPaymentMethod[] =
        clubSettings.general?.creditNote?.paymentMethods ?? [];
      return APP_PAYMENT_METHODS_ALL.filter(
        (x) => paymentMethods.includes(x) || (forceStripe && x === 'stripe'),
      );
    }
    const paymentMethods: AppPaymentMethod[] =
      clubSettings.general?.payments?.methods ?? [];
    return APP_PAYMENT_METHODS_ALL.filter(
      (x) => paymentMethods.includes(x) || (forceStripe && x === 'stripe'),
    );
  }, [
    clubSettings.general?.creditNote?.paymentMethods,
    clubSettings.general?.payments?.methods,
    forceStripe,
    scope,
  ]);
}
