import { AppInquiryQuestion } from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';

export const inqueryQuestionBuilder = {
  buildQuestion: ({
    sortIndex,
    questionTitle,
  }: {
    sortIndex: number;
    questionTitle?: string;
  }) => {
    const q: AppInquiryQuestion = {
      questionId: uuidGenerator.random(),
      sortIndex,
      description: {
        main: {
          title: questionTitle,
          subTitle: undefined,
          items: [],
          languageCode: 'fr',
        },
        translations: [],
      },
      answerDetails: {
        format: 'yes-no',
        required: true,
      },
    };
    return q;
  },
};
