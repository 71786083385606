/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { usePageWidth } from 'src/business/_core/modules/layout/services/usePageWidth.hook';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BookingTabMultiBookingsAutoFixSuggestions } from '../../../models';
import { DiverBookingPageActions } from '../../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageBookingTabHeaderAutoFixDatesOverlap } from './DiverBookingPageBookingTabHeaderAutoFixDatesOverlap';

export const DiverBookingPageBookingTabHeaderAutoFix = ({
  globalState,
  dialogs,
  actions,
  bookingTabMultiBookingsAutoFixSuggestions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabMultiBookingsAutoFixSuggestions: BookingTabMultiBookingsAutoFixSuggestions;
}) => {
  const pageWidth = usePageWidth({ ignoreMenu: false });

  const {
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
  } = globalState;

  const {
    openDiverSelector,
    openSessionDialog,
    openSessionSelector,
    createNewBooking,
    deleteDiverOnly,
  } = actions;

  const { datesOverlap } = bookingTabMultiBookingsAutoFixSuggestions;

  const disabled = false;

  return disabled ? null : (
    <>
      <DiverBookingPageBookingTabHeaderAutoFixDatesOverlap
        globalState={globalState}
        dialogs={dialogs}
        actions={actions}
        datesOverlap={datesOverlap}
      />
    </>
  );
};
