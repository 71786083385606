import {
  BookingMemberResumeFullGql_Company,
  ClubCommerceBookingDepositGql_Company,
  ClubCommerceBookingOnlineBookingResumeGql_Company,
  DiveSessionResumeGql_Company,
} from '@mabadive/app-common-model';

export const editionBookingResumeAttributesBuilder = {
  buildAttributes,
};

/**
 * Build graphql attributes for EditionBookingResume
 */
function buildAttributes() {
  return `
  _id
  diveCenterId
  bookingDate
  bookingLastUpdateDate
  details
  bookingStatus
  active
  bookingContactDiverId
  bookingJourney
  details
  bookingInitialPlan
  bookingCustomerConfig
  bookingCustomerSpacePrivate
  isSharedBooking
  bookingGroup
  bookingContact {
    _id
    lastName
    firstName
    fakeName
    phoneNumber
    emailAddress
    birthdate
    age
    mainCertificationReference
    comment
    divingComment
    divingCertification1
    bookingDeposits
  }
  bookingResumeMembers: bookingMembers {
    ${BookingMemberResumeFullGql_Company}
  }
  bookingSessions {
    _id
    diveCenterId
    diveSessionReference
    bookingSessionStatus
    diveSession {
      ${DiveSessionResumeGql_Company}
    }
  }
  bookingCustomerUpdates (where: {status: {_eq: "to-validate"}}, order_by: {_createdAt: asc}) {
    _id
    clubReference
    bookingId
    authorDiverId
    status
    submitDate
    reviewDate
    payload
  }
  bookingDeposits {
    ${ClubCommerceBookingDepositGql_Company}
  }
  creditNotes {
    _id
    _createdAt
    _updatedAt
    _version
    clubReference
    diveCenterId
    bookingId
    diverId
    purchasePaymentId
    creationDate
    amount
    currencyIsoCode
    reference
    paymentMethod
    details
  }
  onlineBookings {
    ${ClubCommerceBookingOnlineBookingResumeGql_Company}
  }
`;
}
