/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { appClipboardCopier } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingCustomerConfigEditDialog/_services';
import { CustomerSpaceConfigDialogLocalState } from '../useCustomerSpaceConfigDialogLocalState.hook';
export const CustomerConfigCreateCustomerAccountLink = ({
  localState,
  publicAccoutCreationLink,
  className,
}: {
  localState: CustomerSpaceConfigDialogLocalState;
  publicAccoutCreationLink: string;
  className?: string;
}) => {
  return publicAccoutCreationLink ? (
    <div className={className}>
      <div className={'w-full'}>
        <div
          className={
            'flex gap-2 items-end justify-between sm:justify-start flex-wrap'
          }
        >
          <div className="">
            <label
              className="relative text-sm font-medium text-gray-600"
              // style={{ top: '-16px', bottom: '-16px' }}
            >
              Lien d'inscription à communiquer aux clients
            </label>
            <div className="border border-gray-200 bg-gray-50 p-1 whitespace-nowrap overflow-x-auto">
              <a
                className="w-full text-app-xxs sm:text-xs text-app-blue underline"
                href={publicAccoutCreationLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {publicAccoutCreationLink}
              </a>
            </div>
          </div>
          <div className="flex gap-2 items-start">
            {navigator?.clipboard && (
              <AppButton
                fullWidth={false}
                color="primary-outline-light"
                icon={AppHeroIcons.copyClipboard}
                onClick={async () => {
                  appClipboardCopier.copyToClipboard(publicAccoutCreationLink);
                  await localState.actions.persistChanges();
                }}
              >
                <span className="hidden sm:inline">copier</span>
              </AppButton>
            )}
            <AppButton
              fullWidth={false}
              color="primary-outline-light"
              icon={AppHeroIcons.linkExternal}
              href={publicAccoutCreationLink}
              target="mabadive_diver_app"
              onClick={async () => {
                await localState.actions.persistChanges();
              }}
            >
              <span className="hidden sm:inline">ouvrir</span>
            </AppButton>
            <AppButton
              className="whitespace-nowrap"
              fullWidth={false}
              color="primary-outline-light"
              icon={AppIconsAction.generate}
              href={'https://www.qrcode-monkey.com/'}
              target="mabadive_qr_code"
            >
              QR Code
            </AppButton>
            <AppButton
              type="button"
              fullWidth={true}
              className="xs:max-w-[18rem]"
              color="danger-outline-light"
              icon={AppIconsAction.delete}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await localState.actions.expireNewCustomerAccountLink();
              }}
            >
              <span className="hidden sm:inline">Supprimer</span>
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
