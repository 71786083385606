/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSession } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { Button } from '@material-ui/core';
import React, { FC, useCallback, useMemo } from 'react';
import { appLoaderMerger } from 'src/business/_core/data/app-loading/loader/appLoaderMerger.service';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useLoadableDiveSessionResumeFull } from 'src/business/_core/data/store-repository';
import { DiveSessionResumeLoadBounds } from 'src/business/_core/data/store-repository/dive-sessions/model';
import {
  AppButtonsBar,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { AppDialogModalDeprecated } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { DiverBookingPageAggregatedData } from '../../club-diver-participant/pages/DiverBookingPage/models';
import { useCalendarWeekDailyConfigs } from '../../club-planning/_components';
import { useDefaultSettingsUiPlanning } from '../hooks';
import { ClubPlanningSessionDialogPanel } from './ClubPlanningSessionDialogPanel';
export type ClubPlanningSessionDialogState = {
  isOpen: boolean;
  diveSession?: DiveSession;
  aggregatedData?: DiverBookingPageAggregatedData;
};

export const ClubPlanningSessionDialog: FC<{
  state: ClubPlanningSessionDialogState;
  setState: React.Dispatch<
    React.SetStateAction<ClubPlanningSessionDialogState>
  >;
  hasChangesInProgress?: boolean;
}> = ({ hasChangesInProgress, state, setState }) => {
  const diveCenterResume = useDiveCenterResume();

  const settingsUIPlanning = useDefaultSettingsUiPlanning({});

  const { isOpen, diveSession, aggregatedData } = state;

  const { content: session, ...loadableContent1 } =
    useLoadableDiveSessionResumeFull({
      diveSessionReference: diveSession?.reference,
      createVirtualIfNotExists: true,
    });

  const loadCriteria: DiveSessionResumeLoadBounds = useMemo(() => {
    if (state?.diveSession?.time) {
      const minDateInclusive = dateService.getUTCDateSetTime(
        state?.diveSession?.time,
      );
      const maxDateExclusive = dateService.add(minDateInclusive, 1, 'day');
      return {
        clubReference: diveCenterResume.clubReference,
        diveCenterId: diveCenterResume._id,
        minDateInclusive: minDateInclusive,
        maxDateExclusive: maxDateExclusive,
      };
    }
  }, [
    state?.diveSession?.time,
    diveCenterResume.clubReference,
    diveCenterResume._id,
  ]);

  const { content: dailyConfigs, ...loadableContent2 } =
    useCalendarWeekDailyConfigs({
      criteria: loadCriteria,
      mode: 'normal',
    });

  const loadableContent = useMemo(
    () =>
      appLoaderMerger.mergeLoadableContents(loadableContent1, loadableContent2),
    [loadableContent1, loadableContent2],
  );

  // TODO utiliser aggregatedData pour mettre à jour la session

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setState({
        ...state,
        isOpen,
      });
    },
    [setState, state],
  );

  const redirectTo = useRedirect();

  const disableMessage: string = useMemo(
    () =>
      hasChangesInProgress
        ? 'Sauvegardez d\'abord vos modifications en cours'
        : undefined,
    [hasChangesInProgress],
  );

  // const sessionCardMenuActions = useCallback(
  //   ({ diveSession }: DiveSessionSecuritySheetCardMenuActionContext) => {
  //     const actions: {
  //       [key in DiveSessionSecuritySheetCardMenuAction]?: Partial<AppHamburgerMenuItem>;
  //     } = {
  //       'edit-session': {
  //         onClick: () => {
  //           const url = diveSessionUrlBuilder.buildEditSessionUrl({
  //             navigationContext: {
  //               diveSessionReference: diveSession.reference,
  //               origin: 'planning',
  //             },
  //           });
  //           redirectTo(url);
  //         },
  //         disableMessage,
  //       },
  //     };

  //     return actions;
  //   },
  //   [redirectTo, disableMessage],
  // );

  const globalClasses = useGlobalClasses();

  return (
    <>
      <AppDialogModalDeprecated
        zIndex={10000}
        className="bg-white"
        open={isOpen}
        onClickClose={() => setIsOpen(false)}
      >
        <AppPageContainerWithFixedBars
          className="px-4"
          contentClassName="bg-white"
          footerBar={() => (
            <AppButtonsBar hasChanges={true}>
              <Button
                startIcon={<AppIconsMaterial.check />}
                variant="contained"
                className={globalClasses.buttonSave}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Ok
              </Button>
            </AppButtonsBar>
          )}
        >
          {/* <AppHeroIcons.actionClose
            className={
              'fixed cursor-pointer p-2 top-0 right-0 z-50 w-10 h-10 bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white'
            }
            aria-hidden="true"
            onClick={() => {
              setIsOpen(false);
            }}
          /> */}
          {isOpen && loadableContent.contentState === 'full' && session && (
            <ClubPlanningSessionDialogPanel
              className="mt-5 mb-5"
              diveSessionResumeFull={session}
              // menuActions={[]} // sessionCardMenuActions
              settingsUIPlanning={settingsUIPlanning}
              dailyConfigs={dailyConfigs}
            />
          )}
          {/* <div className="flex-grow"></div> */}
        </AppPageContainerWithFixedBars>
      </AppDialogModalDeprecated>
    </>
  );
};
