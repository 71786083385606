/* eslint-disable @typescript-eslint/no-unused-vars */
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';

export function useClubPlanningLightPanelViewPeriodMonthState({
  parentState,
}: {
  parentState: PlanningParticipantsLocalState;
}) {
  return {
    ...parentState,
    state: {
      planningConfig: parentState.planningConfig,
      setPlanningConfig: parentState.setPlanningConfig,
    },
  };
}

export type ClubPlanningLightPanelViewPeriodMonthState = ReturnType<
  typeof useClubPlanningLightPanelViewPeriodMonthState
>;
