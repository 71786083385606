import { AppEntityUpdatePatch } from '@mabadive/app-common-model';
import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
export function useDiverBookingPageUpdateBookingProducts({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    ({
      updatedBookingProductsPatchs,
    }: {
      updatedBookingProductsPatchs: AppEntityUpdatePatch[];
    }) => {
      const bookingProductsChanges = changeDescriptorManager.updateMany(
        updatedBookingProductsPatchs,
        {
          changeDescriptors: updateState.bookingProductsChanges,
        },
      );
      setUpdateState(
        {
          ...updateState,
          bookingProductsChanges,
        },
        {
          action: 'DiverBookingPageUpdateBookingProducts',
        },
      );
    },
    [setUpdateState, updateState],
  );
}
