/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveSession,
  MultipleDiveSessionNumber,
} from '@mabadive/app-common-model';
import { arrayBuilder } from '@mabadive/app-common-services';
import React, { ReactNode, useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useMultipleDiveSessionNumbers } from './useMultipleDiveSessionNumbers.hook';

export function useMultipleDiveSessionNumbersOptions({
  diveSession,
}: {
  diveSession: Pick<DiveSession, 'sessionsCount'>;
}): ValueLabel<MultipleDiveSessionNumber, ReactNode>[] {
  const sessionNumbers = useMultipleDiveSessionNumbers({
    diveSession,
  });

  const sessionNumbers2: MultipleDiveSessionNumber[] = arrayBuilder.buildSeries(
    {
      count: 2,
      first: 1,
    },
  );
  const sessionNumber3 = useMultipleDiveSessionNumbers({
    diveSession,
  });

  return useMemo(() => {
    const multiSessionsPresenceNumbersOptions: ValueLabel<
      MultipleDiveSessionNumber,
      React.ReactNode
    >[] = sessionNumbers.map((n) => ({
      value: n,
      label: <span className="pl-1 pr-3">N°{n}</span>,
    }));

    return multiSessionsPresenceNumbersOptions;
  }, [sessionNumbers]);
}
