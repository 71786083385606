/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import { AppIconsSocialMedia } from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanRHF, AppInputBooleanSwitchRHF } from 'src/lib/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  ClubSettingsCommunicationDialogInitialState,
  ClubSettingsCommunicationFormModel,
} from '../../../_model';

export const ClubSettingsCommunicationMainForm_Customer = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsCommunicationFormModel>;
  initialState: ClubSettingsCommunicationDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [
    showMessageButtons,
    channelEmailEnabled,
    channelSmsEnabled,
    channelPhoneEnabled,
    channelWhatsAppEnabled,
  ] = useWatch({
    control,
    name: [
      'clubSettings.communication.customer.showMessageButtons',
      'clubSettings.communication.channels.email.enabled',
      'clubSettings.communication.channels.sms.enabled',
      'clubSettings.communication.channels.phone.enabled',
      'clubSettings.communication.channels.whatsApp.enabled',
    ],
  });

  return (
    <ClubSettingsSection
      title="Fiche client"
      comment="Affiche des
          boutons permettant de contacter rapidement le client depuis sa fiche."
    >
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        <AppFormControlRHF_Deprecated
          control={control}
          name={'clubSettings.communication.customer.showMessageButtons'}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF {...props} label={'ACTIVÉ'} />
          )}
        />
        {showMessageButtons && (
          <div className="flex gap-x-4 gap-y-2">
            {channelEmailEnabled && (
              <AppFormControlRHF_Deprecated
                control={control}
                // label="Bouton Email"
                name={'clubSettings.communication.customer.emailButton.enabled'}
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    label={'Email'}
                    label2={'manuel'}
                    margin="ml-4 mr-2.5"
                    scale="lg"
                    icon={
                      <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
                    }
                  />
                )}
              />
            )}
            {channelPhoneEnabled && (
              <AppFormControlRHF_Deprecated
                control={control}
                name={'clubSettings.communication.customer.phoneButton.enabled'}
                // label="Bouton Téléphone"
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    label={'Téléphone'}
                    label2={'manuel'}
                    margin="ml-4 mr-2.5"
                    scale="lg"
                    icon={
                      <AppIconsSocialMedia.phone className="h-6 w-6 text-app-sms" />
                    }
                  />
                )}
              />
            )}
            {channelWhatsAppEnabled && (
              <AppFormControlRHF_Deprecated
                control={control}
                // label="Bouton WhatsApp"
                name={
                  'clubSettings.communication.customer.whatsAppButton.enabled'
                }
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    label="WhatsApp"
                    label2={'manuel'}
                    margin="ml-4 mr-2.5"
                    scale="lg"
                    icon={
                      <AppIconsSocialMedia.whatsapp className="h-5 w-5 text-app-whatsapp" />
                    }
                  />
                )}
              />
            )}
          </div>
        )}
      </div>
    </ClubSettingsSection>
  );
};
