import React from 'react';
import { useWatch } from 'react-hook-form';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { BookingCustomerConfigEditDialogInputState } from '../_model';
import { BookingCustomerConfigEditDialogLocalState } from '../useBookingCustomerConfigEditDialogLocalState.hook';
import { BookingCustomerConfigEditFormCustomerUpdate } from './components';
import { BookingCustomerConfigEditForm_Inquiries } from './components/BookingCustomerConfigEditForm_Inquiries';

export const BookingCustomerConfigEditDialogPanel1Config = ({
  localState,
  inputState,
  className,
}: {
  localState: BookingCustomerConfigEditDialogLocalState;
  inputState: BookingCustomerConfigEditDialogInputState;
  className?: string;
}) => {
  const isNewBooking = inputState?.isNewBooking;
  const bookingId = inputState?.booking?._id;
  const bookingContact = inputState?.bookingContact;
  const bookingMembersFull = inputState?.bookingMembersFull;

  const { state } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    state.form;

  const [customerUiEnableCustomerUpdate] = useWatch({
    control,
    name: ['bookingCustomerConfig.customerUi.enableCustomerUpdate'],
  });

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const az = useAppSecurityUserClubAuthorizations();

  const DISABLE_INQUIRIES = true;

  return (
    <div className={className}>
      <div className="grid xl:grid-cols-2 gap-4">
        <BookingCustomerConfigEditFormCustomerUpdate form={state.form} />
        {!DISABLE_INQUIRIES && (
          <BookingCustomerConfigEditForm_Inquiries localState={localState} />
        )}
      </div>

      {
        <>
          <h3 className="mt-5 uppercase text-base sm:text-lg text-app-blue font-bold">
            Afficher un message sur l'espace client
          </h3>
          <AppFormControlRHF
            className="my-2 w-full xs:col-span-12"
            label="Message complémentaire à afficher au client:"
            control={control}
            name="bookingCustomerConfig.customerUi.messageToDisplay"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                multiline
                rowsMax={15}
                rowsMin={3}
                rows={2}
              />
            )}
          />
          <AppFormControlRHF
            className="my-2 w-full xs:col-span-12"
            label="Lien à afficher au client:"
            control={control}
            name="bookingCustomerConfig.customerUi.linkToDisplay"
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        </>
      }
    </div>
  );
};
