/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  AppBreadcrumbBar,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { ClubSettingsAccountDisplayEditCardGlobal } from './ClubSettingsAccountDisplayEditCardGlobal';
import { ClubSettingsAccountDisplayEditCardPlanning } from './ClubSettingsAccountDisplayEditCardPlanning';
import {
  ClubSettingsAccountDisplayPageLocalState,
  useClubSettingsAccountDisplayPageLocalState,
} from './useClubSettingsAccountDisplayPageLocalState.hook';

export const ClubSettingsAccountDisplayPage = (props: {}) => {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterResume = useDiveCenterResume();
  const localState: ClubSettingsAccountDisplayPageLocalState =
    useClubSettingsAccountDisplayPageLocalState();

  return (
    <AppPageContainer className={'bg-gray-50'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.other.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.other.main,
            label: 'Configuration',
            url: '/club/settings',
          },
          {
            label: 'Affichage',
          },
        ]}
      >
        {isMultiDiveCenters && (
          <div className="app-px-content">
            <DiveCenterProBadge diveCenter={diveCenterResume} />
          </div>
        )}
      </AppBreadcrumbBar>
      <AppPageContentContainer
        paddingBottom={false}
        className="bg-gray-50 app-p-content"
      >
        <div className="grid gap-4">
          <ClubSettingsAccountDisplayEditCardGlobal localState={localState} />
          <ClubSettingsAccountDisplayEditCardPlanning localState={localState} />
        </div>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
