import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppActionButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  AppHamburgerMenu,
  AppHamburgerMenuItem,
} from 'src/business/club/modules/_common/ui';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BillingTabParticipantPurchase } from '../../models';
import { DiverBookingPageBillingTabState } from '../../tabs/DiverBookingPageBillingTab/useDiverBookingPageBillingTabState.hook';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { BillingParticipantPurchaseResumePurchasePackageLabel } from './BillingParticipantPurchaseResumePurchasePackageLabel';
import { BillingParticipantPurchaseResumePurchasePackageState } from './BillingParticipantPurchaseResumePurchasePackageState';
import { BillingParticipantPurchaseResumePurchasePaymentState } from './BillingParticipantPurchaseResumePurchasePaymentState';
import { BillingParticipantPurchaseResumeTableDiverName } from './BillingParticipantPurchaseResumeTableDiverName';
import { BillingParticipantPurchaseResumeTableDivesCounts } from './BillingParticipantPurchaseResumeTableDivesCounts';
import { useBillingParticipantPurchaseResumeTableHamburgerMenuItems } from './useBillingParticipantPurchaseResumeTableHamburgerMenuItems.hook';

export function BillingParticipantPurchaseResumeTableRow1({
  participantPurchase,
  billingTabLocalState,
  globalState,
  dialogs,
  actions,
  isExpanded,
  setIsExpanded,
  className,
}: {
  participantPurchase: BillingTabParticipantPurchase;
  billingTabLocalState: DiverBookingPageBillingTabState;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;

  className?: string;
}) {
  const redirectTo = useRedirect();
  const user = useAppSecurityUser();

  const {
    aggregatedData,
    model,
    selectedDiverId,
    setSelectedDiverId,
    diversFilterOptions,
    createPlan,
    createTraining,
    createFirstDive,
  } = billingTabLocalState;

  const {
    diveMode,
    isVirtual,
    diver,
    divers,
    purchasePackage,
    diveTrainingReference,
    sessionsBillingResumes,
    divesCounts,
    beginDate,
    endDate,
    isArchived,
    diverSessionsCount,
  } = participantPurchase;

  const showDiveCenterBadge =
    !globalState.billingTabFilterCriteria
      .ignoreOtherDiveCentersPurchasePackages;

  const { openCreatePurchasePaymentDialog } = actions;

  const isOldPeriod = useMemo(() => {
    const dayDiff =
      new Date().getTime() / 1000 / 3600 / 24 -
      endDate.getTime() / 1000 / 3600 / 24;
    return dayDiff > 6 * 30; // 6 months
  }, [endDate]);

  const opacity = isOldPeriod ? 60 : undefined;

  const purchaseEnabled = useMemo(
    () =>
      user &&
      (participantPurchase.type === 'plan' ||
        participantPurchase.type === 'other' ||
        participantPurchase.type === 'training') &&
      user.roles.includes('club-edit-participant-billing'),
    [participantPurchase.type, user],
  );

  const isPurchasable = // TODO: il faudrait plutôt regarder si on a une offre correspondant, et sinon, suggérer de configurer ses offres
    purchaseEnabled &&
    (diveMode === 'autonomous' ||
      diveMode === 'supervised' ||
      diveMode === 'training' ||
      diveMode === 'first-dive' ||
      diveMode === 'observer' ||
      diveMode === 'free-dive' ||
      diveMode === 'snorkeling' ||
      diveMode === 'snorkelingSupervised' ||
      diveMode === 'watchingTour' ||
      diveMode === 'autoSupervised' ||
      diveMode === 'instructor');

  const hamburgerMenuItems: AppHamburgerMenuItem[] =
    useBillingParticipantPurchaseResumeTableHamburgerMenuItems({
      actions,
      dialogs,
      globalState,
      isExpanded,
      participantPurchase,
      setIsExpanded,
    });

  const mediaSize = useMediaSizeTailwind('xs');

  const purchaseQuickAction: AppHamburgerMenuItem = useMemo(() => {
    const hideLabel = mediaSize === 'xxs' || mediaSize === 'xs';
    if (isVirtual) {
      if (isPurchasable) {
        {
          const action = hamburgerMenuItems.find(
            (x) => x.id === 'create-or-edit-purchase',
          );
          if (action && !action.hidden) {
            return hideLabel
              ? {
                  ...action,
                  titleShort: 'F',
                }
              : action;
          }
        }
        {
          const action = hamburgerMenuItems.find(
            (x) => x.id === 'archive-virtual-past-non-purchased',
          );
          if (action && !action.hidden) {
            return hideLabel
              ? {
                  ...action,
                  titleShort: 'A',
                }
              : action;
          }
        }
      }
    } else {
      // real
      // {
      //   const action = hamburgerMenuItems.find(
      //     (x) => x.id === 'auto-impute-past-non-purchased',
      //   );
      //   if (action && !action.hidden) {
      //     return action;
      //   }
      // }
      const action = hamburgerMenuItems.find(
        (x) => x.id === 'create-or-edit-purchase',
      );
      if (action && !action.hidden) {
        return hideLabel
          ? {
              ...action,
              titleShort: 'M',
            }
          : action;
      }
    }
  }, [hamburgerMenuItems, isPurchasable, isVirtual, mediaSize]);

  const paymentQuickAction: AppHamburgerMenuItem = useMemo(() => {
    const hideLabel = mediaSize === 'xxs' || mediaSize === 'xs';
    // eslint-disable-next-line no-lone-blocks
    {
      const action = hamburgerMenuItems.find((x) => x.id === 'pay');
      if (action && !action.hidden) {
        return hideLabel
          ? {
              ...action,
              titleShort: 'P',
            }
          : action;
      }
    }
    if (participantPurchase.isOnlyCancelled) {
      // que des plongées annulées: on propose de les archiver
      const action = hamburgerMenuItems.find(
        (x) => x.id === 'archive-cancelled-non-purchased',
      );
      if (action && !action.hidden) {
        return hideLabel
          ? {
              ...action,
              titleShort: 'A',
            }
          : action;
      }
    }
  }, [hamburgerMenuItems, mediaSize, participantPurchase.isOnlyCancelled]);

  const otherAssociatedDivers = useMemo(
    () => divers.filter((x) => x._id !== diver?._id),
    [diver?._id, divers],
  );

  return (
    <tr
      className={`cursor-pointer ${
        className ?? ''
      } text-sm md:text-base align-top`}
    >
      <td
        className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/3 pr-2 py-1 whitespace-nowrap"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex">
          {isExpanded ? (
            <>
              <AppHeroIcons.actionReduce className="cursor-pointer mr-1 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
            </>
          ) : (
            <>
              <AppHeroIcons.actionExtend className="cursor-pointer mr-1 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
            </>
          )}
          <div>
            <BillingParticipantPurchaseResumeTableDiverName
              showResidentType={true}
              diver={diver}
              diverSessionsCount={diverSessionsCount}
              opacity={opacity}
              className={'flex-shrink text-xs md:text-sm'}
            />
            {otherAssociatedDivers.map((diver) => (
              <BillingParticipantPurchaseResumeTableDiverName
                showResidentType={false}
                key={diver?._id}
                diver={diver}
                opacity={opacity}
                className={'flex-shrink text-xs md:text-sm'}
              />
            ))}
            <BillingParticipantPurchaseResumePurchasePackageLabel
              showDiveCenterBadge={showDiveCenterBadge}
              className="text-xs md:text-sm lg:hidden"
              participantPurchase={participantPurchase}
              opacity={opacity}
            />
          </div>
        </div>
      </td>
      <td
        className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/3 hidden lg:table-cell px-2 py-1 whitespace-nowrap"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <BillingParticipantPurchaseResumePurchasePackageLabel
          showDiveCenterBadge={showDiveCenterBadge}
          className="text-xs md:text-sm"
          participantPurchase={participantPurchase}
          opacity={opacity}
        />
      </td>
      <td
        className="hidden md:table-cell px-2 py-1 w-24 whitespace-nowrap text-gray-600"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <BillingParticipantPurchaseResumeTableDivesCounts
          divesCounts={divesCounts}
          opacity={opacity}
          isVirtual={isVirtual}
        />
      </td>
      <td
        className="px-2 py-1 whitespace-nowrap"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="hidden md:block">
          <BillingParticipantPurchaseResumePurchasePackageState
            participantPurchase={{
              ...participantPurchase,
              validityStatus:
                participantPurchase.purchasePackage?.validityStatus,
            }}
            opacity={opacity}
          />
        </div>
        <div className="md:hidden">
          {!participantPurchase.isVirtual ? (
            <BillingParticipantPurchaseResumePurchasePackageState
              className="text-xs md:text-sm"
              participantPurchase={{
                ...participantPurchase,
                validityStatus:
                  participantPurchase.purchasePackage?.validityStatus,
              }}
              opacity={opacity}
            />
          ) : (
            <BillingParticipantPurchaseResumeTableDivesCounts
              className="text-xs md:hidden"
              divesCounts={divesCounts}
              opacity={opacity}
              isVirtual={isVirtual}
            />
          )}
          <BillingParticipantPurchaseResumePurchasePaymentState
            className="text-xs sm:hidden"
            purchasePackage={participantPurchase.purchasePackage}
            opacity={opacity}
          />
        </div>
      </td>
      <td className="lg:table-cell px-2 py-1 whitespace-nowrap">
        {!isArchived && purchaseQuickAction && (
          <AppActionButton
            color={
              purchaseQuickAction.id === 'create-or-edit-purchase' &&
              participantPurchase.isVirtual
                ? 'primary-bg'
                : undefined
            }
            action={purchaseQuickAction}
          />
        )}
      </td>
      <td className="hidden sm:table-cell px-2 py-1 whitespace-nowrap text-gray-600">
        {
          <BillingParticipantPurchaseResumePurchasePaymentState
            purchasePackage={participantPurchase.purchasePackage}
            opacity={opacity}
          />
        }
      </td>
      <td className="px-2 py-1 whitespace-nowrap text-gray-600 ">
        {!isArchived && paymentQuickAction && (
          <AppActionButton color="primary-bg" action={paymentQuickAction} />
        )}
      </td>
      <td className="py-1 whitespace-nowrap text-gray-600 text-right">
        <AppHamburgerMenu
          buttonClassName="bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white"
          position="left-start"
          items={hamburgerMenuItems}
          buttonIcon={AppHeroIcons.actionMenu}
          // buttonLabel="Prestation"
        >
          <h2 className="text-center text-lg leading-6 p-2 font-medium text-gray-800">
            Prestation
          </h2>
        </AppHamburgerMenu>
      </td>
    </tr>
  );
}
