import {
  attributeStoreFactory,
  loadableAttributeStoreFactory,
} from '@mabadive/app-common-services';
import { DiverBookingPageConfig } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { PlanningViewStoreConfig } from 'src/business/club/modules/club-planning/_model';
import { AppSnackbarMessage } from '../modules/layout/components/AppSnackBar';
import { AppProcessMonitorState } from '../process';
import { baseStore } from './baseStore';

// application store (local store, derived data...)
export const uiStore = {
  help: attributeStoreFactory.create<{
    demo: {
      welcomeMessage?: boolean;
      ClubPlanningPage: {
        clickRowMessage?: boolean;
      };
    };
  }>(baseStore, 'io_help', {
    demo: { ClubPlanningPage: {} },
  }),
  searchText: attributeStoreFactory.create<string>(baseStore, 'io_searchText'),
  snackbarMessage: loadableAttributeStoreFactory.create<AppSnackbarMessage>(
    baseStore,
    'snackbar',
  ),
  isMenuOpen: attributeStoreFactory.create<boolean>(baseStore, 'isMenuOpen'),
  isMenuDisabled: attributeStoreFactory.create<boolean>(
    baseStore,
    'isMenuDisabled',
    false,
  ),
  isMenuCollapsed: attributeStoreFactory.create<boolean>(
    baseStore,
    'isMenuCollapsed',
  ),
  isMenuEmpty: attributeStoreFactory.create<boolean>(
    baseStore,
    'isMenuEmpty',
    true,
  ),
  app: loadableAttributeStoreFactory.create<{
    version: string;
    clientId: string;
  }>(baseStore, 'app'),
  runningProcessLoadInitialData: loadableAttributeStoreFactory.create<
    AppProcessMonitorState<any>
  >(baseStore, 'running_processes'),
  planningViewParticipantsConfig: loadableAttributeStoreFactory.create<
    Partial<PlanningViewStoreConfig>
  >(baseStore, 'planning_view_config'),
  planningViewStaffConfig: loadableAttributeStoreFactory.create<
    Partial<PlanningViewStoreConfig>
  >(baseStore, 'planning_staff_config'),
  planningSelectorConfig: loadableAttributeStoreFactory.create<
    Partial<PlanningViewStoreConfig>
  >(baseStore, 'planning_selector_config'),
  diverBookingPageConfig: loadableAttributeStoreFactory.create<
    Partial<DiverBookingPageConfig>
  >(baseStore, 'diver_booking_page_config'),
  // navigateToPlanningSessionWhenLoaded: attributeStoreFactory.create<
  //   Partial<PlanningViewConfig>
  // >(baseStore, 'navigate_to_planning_session_when_loaded'),
};
