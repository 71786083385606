import React, { useCallback } from 'react';
import {
  ClubPlanningSessionSelectorDialogState,
  ClubPlanningSessionSelectorDialogStateContext,
} from 'src/business/club/modules/club-planning-light/club-planning-session-selector-dialog/ClubPlanningSessionSelectorDialog';
import {
  DiverBookingPageAggregatedData,
  PRO_BookingResume,
} from '../../models';
export function useDiverBookingPageOpenSessionSelector({
  aggregatedData,
  setClubPlanningSessionSelectorState,
  clubPlanningSessionSelectorState,
  focusSessionReference,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  setClubPlanningSessionSelectorState: React.Dispatch<
    React.SetStateAction<ClubPlanningSessionSelectorDialogState>
  >;
  clubPlanningSessionSelectorState: ClubPlanningSessionSelectorDialogState;
  focusSessionReference?: string;
}) {
  return useCallback(
    ({
      bookingId,
      defaultFocusDate,
    }: Omit<
      ClubPlanningSessionSelectorDialogStateContext,
      'focusSessionReference'
    >) => {
      const context: ClubPlanningSessionSelectorDialogStateContext = {
        bookingId,
        focusSessionReference,
        defaultFocusDate,
      };

      const aggregatedBooking: PRO_BookingResume =
        aggregatedData.bookingResumesLoaded.find(
          (b) => b.booking._id === context.bookingId,
        );
      if (aggregatedBooking) {
        const alreadySelectedSessionsReferences =
          aggregatedBooking.bookingSessionsFull.map(
            (s) => s.diveSession.reference,
          );

        setClubPlanningSessionSelectorState({
          ...clubPlanningSessionSelectorState,
          isOpen: true,
          alreadySelectedSessionsReferences,
          context,
        });
      }
    },
    [
      aggregatedData.bookingResumesLoaded,
      clubPlanningSessionSelectorState,
      focusSessionReference,
      setClubPlanningSessionSelectorState,
    ],
  );
}
