import { commonDiveSessionReferenceParser } from '@mabadive/app-common-services';
import { clubPlanningLightPageUrlManager } from '../club-planning-light/clubPlanningLightPageUrlManager.service';
import { PlanningViewConfig } from '../club-planning/_model';

export const clubPlanningUrlBuilder = {
  buildPlanningUrl,
  buildPlanningBackUrlFromDiveSessionReference,
  buildFocusDateRefFromDate,
};

function buildPlanningUrl(planningConfig?: Partial<PlanningViewConfig>) {
  const searchParams =
    clubPlanningLightPageUrlManager.buildSearchParams(planningConfig);
  const search = searchParams?.length ? `?${searchParams.join('&')}` : '';
  return `/club/planning${search}`;
}

function buildFocusDateRefFromDate(focusDate: Date) {
  return buildFocusDateRefFromDateChunks({
    year: focusDate.getUTCFullYear(),
    month: focusDate.getUTCMonth() + 1,
    day: focusDate.getUTCDate(),
  });
}

function buildFocusDateRefFromDateChunks({
  year,
  month,
  day,
}: {
  year: number;
  month: number;
  day: number;
}) {
  return `${year}-${month < 10 ? '0' : ''}${month}-${day}`;
}

function buildPlanningBackUrlFromDiveSessionReference(
  diveSessionReference: string,
) {
  if (diveSessionReference) {
    const { dayReference } =
      commonDiveSessionReferenceParser.parseSessionReference(
        diveSessionReference,
      );

    const focusDate =
      commonDiveSessionReferenceParser.parseDayReference(dayReference);

    return buildPlanningUrl({
      focusDate,
      focusSessionReference: diveSessionReference,
      // viewPeriod: 'session',
      // viewSessionOptions: {
      //   displayMode: 'before-dive',
      // },
    });
  } else {
    return buildPlanningUrl();
  }
}
