/* eslint-disable @typescript-eslint/no-unused-vars */
import { Club } from '@mabadive/app-common-model';
import { collectionRepositoryFactory, collectionSimpleStoreAdaptersFactory } from 'src/business/_core/data/repository';
import { adminStore } from 'src/business/_core/store';

const store = adminStore.clubs;

const storeAdapters = collectionSimpleStoreAdaptersFactory.createAdapters(store);

export const adminClubsRepository = {
  ...collectionRepositoryFactory.create<Club>({
    mutationEntity: 'club',
    synchronous: false,
    storeAdapters,
  }),
};
