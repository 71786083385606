/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingProduct,
  ClubParticipant,
  JsonPatchOperation,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  jsonPatcher,
  jsonPatcherSmart,
} from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  BookingResumeParticipantForSession,
  DiverBookingPageUpdateState,
} from '../../../../../models';
import { bookingProductChangesBuilder } from './booking-product';

export const bookingParticipantEditorDialogChangesBuilderEditDiveMode = {
  updateParticipantsAndProductsByPatchOperations,
};

function updateParticipantsAndProductsByPatchOperations({
  participantsFull,
  updateState,
  patchOperations,
}: {
  participantsFull: BookingResumeParticipantForSession[];
  updateState: DiverBookingPageUpdateState;
  patchOperations: JsonPatchOperation[];
}): { hasChanges: boolean; updateState: DiverBookingPageUpdateState } {
  let updateStateLocal: DiverBookingPageUpdateState = {
    ...updateState,
  };

  // dans ce contexte, on essaie d'appliquer des patchs créé depuis la config par défaut, sur les participants existant, ce qui peut poser problème
  // des attributs manquants font planquer l'application du patch, donc on doit ajuster les patchs si besoin

  const commonMissingPrefixes =
    jsonPatcherSmart.buildPotentialMissingPrefixes<ClubParticipant>({
      patchOperations,
      missingCandidates: [
        'equipment',
        'gaz',
        'details',
        'autoSupervisedDetails',
      ],
    });

  const {
    clubParticipantsChangesPatches,
    bookingProductsChangesPatchesPatches,
  } = participantsFull.reduce(
    (acc, participantFull) => {
      const p =
        participantFull.bookingParticipantFullAnyBooking
          ?.diveSessionParticipant;

      // on ajoute les patchs manquant si nécessaire
      patchOperations = jsonPatcherSmart.createOperationsForMissingPrefixes(
        commonMissingPrefixes,
        p,
        patchOperations,
      );

      const finalParticipant: ClubParticipant =
        jsonPatcherSmart.applySmartPatchOperations(p, {
          patchOperations,
          ignoreErrors: true,
          logPrefix: 'updateParticipantsAndProductsByPatchOperations',
          appLogger,
        }).output;

      {
        const patchOperations = jsonPatcher.compareObjects<ClubParticipant>(
          p,
          finalParticipant,
          {
            replaceDeleteByNullValue: true,
            attributesToReplaceFully: ['details', 'autoSupervisedDetails'],
          },
        );

        if (patchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: p._id,
            patchOperations,
          };

          acc.clubParticipantsChangesPatches.push(patch);
        }
      }
      {
        const initialBookingProduct =
          participantFull.bookingParticipantFullAnyBooking.bookingProductDive;
        const finalDiveBookingProduct: BookingProduct =
          bookingProductChangesBuilder.updateBookingProductFromParticipant({
            initialBookingProduct,
            finalParticipant,
          });
        const patchOperations = jsonPatcher.compareObjects(
          initialBookingProduct,
          finalDiveBookingProduct,
          {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: true,
          },
        );

        if (patchOperations.length) {
          acc.bookingProductsChangesPatchesPatches.push({
            pk: initialBookingProduct._id,
            patchOperations,
          });
        }
      }
      return acc;
    },
    {
      clubParticipantsChangesPatches: [] as AppEntityUpdatePatch[],
      bookingProductsChangesPatchesPatches: [] as AppEntityUpdatePatch[],
    },
  );
  if (
    clubParticipantsChangesPatches.length ||
    bookingProductsChangesPatchesPatches.length
  ) {
    const clubParticipantsChanges = changeDescriptorManager.updateMany(
      clubParticipantsChangesPatches,
      {
        changeDescriptors: updateStateLocal.clubParticipantsChanges,
      },
    );
    const bookingProductsChanges = changeDescriptorManager.updateMany(
      bookingProductsChangesPatchesPatches,
      {
        changeDescriptors: updateStateLocal.bookingProductsChanges,
      },
    );

    updateStateLocal = {
      ...updateStateLocal,
      clubParticipantsChanges,
      bookingProductsChanges,
    };
    return { updateState: updateStateLocal, hasChanges: true };
  }
  return { updateState: updateStateLocal, hasChanges: false };
}
