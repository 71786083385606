import { ClubDiverFull } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useProgressiveListRendering } from 'src/business/_core/data/hooks';
import { ClubDiversListTableRow } from './ClubDiversListTableRow';

export function ClubDiversListTable({
  divers: diversInput,
  className,
}: {
  divers: ClubDiverFull[];
  className?: string;
}) {
  const diversToRender = useProgressiveListRendering(diversInput, {
    initialSize: 50,
    step: 100,
  });

  return (
    <table className={clsx('app-table min-w-full', className)}>
      <thead>
        <tr>
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Nom
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden md:table-cell"
          >
            Niveau
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden md:table-cell"
          >
            Date de naissance
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-widerdiver hidden lg:table-cell"
          >
            Dernière plongée
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-widerdiver hidden lg:table-cell"
          >
            Prochaine plongée
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden xs:table-cell"
          >
            Adresse e-mail
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden xs:table-cell"
          >
            Numéro de téléphone
          </th>
          {/* <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden lg:table-cell"
          >
            Création
          </th> */}
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden lg:table-cell"
          >
            Mise à jour
          </th>
        </tr>
      </thead>
      <tbody>
        {diversToRender.map((diver) => (
          <ClubDiversListTableRow key={diver._id} diver={diver} />
        ))}
      </tbody>
    </table>
  );
}
