import { ClubDiverFull } from '@mabadive/app-common-model';
import { MQueryDescription } from 'src/_common-browser';
import { CLUB_DIVER_FULL_GRAPHQL_RETURN_ATTRIBUTES } from './CLUB_DIVER_FULL_GRAPHQL_RETURN_ATTRIBUTES.const';

export function buildClubDiverFullCollectionGraphqlQuery({
  clubReference,
  maxDivers,
  mainCertificationReference,
}: {
  clubReference: string;
  maxDivers: number;
  mainCertificationReference?: string;
}) {
  const queryDescription: MQueryDescription<ClubDiverFull> = {
    returnName: 'clubDiversFull',
    queryName: 'club_diver',
    returnType: 'all',
    where: `{clubReference: {_eq: "${clubReference}"}${
      mainCertificationReference
        ? `, mainCertificationReference: {_eq: "${mainCertificationReference}"}`
        : ''
    }}`,
    orderBy: '{divesMeta: {nearestTime: desc_nulls_last}}',
    limit: maxDivers,
    returnAttributes: CLUB_DIVER_FULL_GRAPHQL_RETURN_ATTRIBUTES,
  };

  return queryDescription;
}
