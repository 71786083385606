import { DiveSession } from '@mabadive/app-common-model';
import React, { useCallback } from 'react';
import { ClubPlanningSessionDialogState } from 'src/business/club/modules/club-planning-light/club-planning-session-dialog';
import { DiverBookingPageAggregatedData } from '../../models';
// sessionDialogState, setSessionDialogState
export function useDiverBookingPageOpenSessionDialog({
  sessionDialogState,
  setSessionDialogState,
  aggregatedData,
}: {
  sessionDialogState: ClubPlanningSessionDialogState;
  setSessionDialogState: React.Dispatch<
    React.SetStateAction<ClubPlanningSessionDialogState>
  >;
  aggregatedData: DiverBookingPageAggregatedData;
}) {
  return useCallback(
    ({ diveSession }: { diveSession: DiveSession }) => {
      setSessionDialogState({
        ...sessionDialogState,
        isOpen: true,
        diveSession,
        aggregatedData,
      });
    },
    [sessionDialogState, setSessionDialogState, aggregatedData],
  );
}
