import {
  ClubDiverSelectorDialogCreateDiverFormModel,
  MassiveFakeDataGeneratorConfig,
} from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';

export const fakerClient = {
  generateMassiveData,
  generateDiver,
};

function generateMassiveData(
  config: MassiveFakeDataGeneratorConfig,
): Promise<void> {
  // send remote operation
  return apiClient
    .post<any>('/faker', {
      authenticate: true,
      json: {
        fakeId: 'generate-massive-data',
        config,
      },
      timeout: 60000,
    })
    .toPromise();
}
function generateDiver({
  lastName,
}: {
  lastName?: string;
}): Promise<ClubDiverSelectorDialogCreateDiverFormModel> {
  // send remote operation
  return apiClient
    .post<any>('/faker', {
      authenticate: true,
      json: {
        fakeId: 'generate-diver',
        data: {
          lastName,
        },
      },
    })
    .toPromise();
}
