import {
  AdminMassiveUpdateOperation,
  AdminMassiveUpdatePayload,
} from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';

export const adminMassiveUpdatorClient = {
  updateMany,
};

function updateMany(payload: AdminMassiveUpdatePayload): Promise<void> {
  const operation: AdminMassiveUpdateOperation = {
    id: 'admin.massive.update',
    payload,
  };

  // send remote operation
  return apiClient
    .post<any>('/operations', {
      authenticate: true,
      json: {
        operations: [operation],
      },
    })
    .toPromise();
}
