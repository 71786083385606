import React, { FC } from 'react';

export const AppPageContentContainer: FC<{
  id?: string;
  className?: string;
  paddingBottom?: boolean;
  overflow?: 'overflow-y-auto' | 'overflow-y-hidden';
  children?: React.ReactNode | React.ReactNode[];
}> = ({
  id,
  className,
  paddingBottom = true,
  overflow = 'overflow-y-auto',
  children,
}) => {
  return (
    <div
      id={id}
      className={`${overflow} h-full flex flex-col ${
        paddingBottom ? 'pb-8' : ''
      }
      ${className ?? ''}
    `}
    >
      {children}
    </div>
  );
};
