/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveSessionResumeParticipant,
  MultipleDiveSessionNumber,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  diveModeColorClassBuilder,
  diveSessionServiceFormatter,
  jsonPatcher,
  participantAptitudeBuilder,
} from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ParticipantCommentCard } from 'src/business/club/modules/_common/form';
import {
  ParticipantDiveCard,
  ParticipantDiverNameLabel,
  ParticipantGaz,
  ParticipantRebreather,
  ParticipantTargetDeep,
  ParticipantUnderwaterScooter,
} from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant';
import { useParticipantExtraBadgeLabel } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/session-common/CalendarParticipantCell/useParticipantExtraBadgeLabel.hook';
import { useDefaultSettingsUiPlanning } from 'src/business/club/modules/club-planning-light/hooks';

import clsx from 'clsx';
import { ParticipantDiveAptitudeBadge } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant/ParticipantDiveAptitudeBadge';
import {
  ColoredTagCardBar,
  participantTagsBuilder,
} from 'src/business/club/modules/club-planning/_components';
import { AppMultiCheckboxesTailwind } from 'src/lib/form/components/AppMultiCheckboxes/AppMultiCheckboxesTailwind';
import {
  useMultipleDiveSessionNumbers,
  useMultipleDiveSessionNumbersOptions,
} from 'src/pages/_components/options';
import { BookingGroupSizeLabel } from '../../../../BookingLabelsComponents';
import { DiveSessionEditorDialogLocalState } from '../../../useDiveSessionEditorDialogLocalState.hook';

export const DiveSessionDialogTab3ParticipantInfo: React.FC<{
  participant: DiveSessionResumeParticipant;
  localState: DiveSessionEditorDialogLocalState;
}> = ({ localState, participant }) => {
  const {
    form,
    clubReference,

    aggregatedData: {
      groups,
      clubParticipants,
      diveSession,
      divers,
      staffMembers,
    },
    updateState,
    setUpdateState,
    participantFirstNameBefore,
    isMultiSessionConfigForParticipants,
  } = localState;

  const { control } = form;

  const diver = participant.diver;

  const clubResume = useClubResume();
  const aptitude = useMemo(
    () =>
      participantAptitudeBuilder.formatAptitude(
        {
          diver: participant.diver,
          participant,
        },
        {
          clubReference,
        },
      ),
    [clubReference, participant],
  );

  const tags = useMemo(
    () =>
      !participant
        ? []
        : participantTagsBuilder.getParticipantTags({
            club: clubResume,
            participant,
            diver,
            diveSession,
            includeComment: false,
            includeBookingState: false,
            includeSpecialDiveType: true,
            includePayment: false,
          }),
    [clubResume, diveSession, diver, participant],
  );

  const multipleDiveSessionNumbersOptions =
    useMultipleDiveSessionNumbersOptions({
      diveSession,
    });

  const diveSessionNumbers = useMultipleDiveSessionNumbers({
    diveSession,
  });
  const onUpdateParticipantMultiSessionsPresenceNumbers = useCallback(
    ({
      multiSessionsPresenceNumbers,
    }: {
      multiSessionsPresenceNumbers: MultipleDiveSessionNumber[];
    }) => {
      // mise à jour de la configuration des db-bloc du plongeur
      const patchOperations = jsonPatcher.compareObjects(
        participant,
        {
          ...participant,
          details: {
            ...(participant.details ?? {}),
            multiSessionsPresenceNumbers,
          },
          divesCount: (multiSessionsPresenceNumbers.length ??
            diveSessionNumbers.length) as MultipleDiveSessionNumber,
        },
        {
          // replaceDeleteByNullValue: true,
          attributesToReplaceFully: ['details'],
        },
      );
      if (patchOperations.length) {
        const patch = {
          pk: participant._id,
          patchOperations,
        };
        const localUpdateState = {
          ...updateState,
        };
        localUpdateState.clubParticipantsChanges =
          changeDescriptorManager.updateOne(patch, {
            changeDescriptors: localUpdateState.clubParticipantsChanges,
          });
        setUpdateState(localUpdateState);
      }
    },
    [diveSessionNumbers.length, participant, setUpdateState, updateState],
  );

  const settingsUIPlanning = useDefaultSettingsUiPlanning({});

  const extraBadgeLabel = useParticipantExtraBadgeLabel({
    participant,
    settingsUIPlanning,
    multipleSessionsGroup: true,
    multipleSessionNumber: undefined,
  });
  const clubSettings = useClubSettings();
  const clubPublicSettings = clubSettings?.publicSettings;

  const serviceLabel = useMemo(
    () =>
      diveSessionServiceFormatter.formatServiceFromParticipant(
        { diver, participant },
        {
          format: 'short-ref',
          addSuffix: false,
          clubReference,
          clubPublicSettings,
          diveModesConf: clubSettings?.ui?.diveMode,
        },
      ),
    [
      clubPublicSettings,
      clubReference,
      clubSettings?.ui?.diveMode,
      diver,
      participant,
    ],
  );
  const serviceExtraLabel = useMemo(() => {
    return diveSessionServiceFormatter.formatServiceExtraFromParticipant(
      { diver, participant },
      { clubReference, clubPublicSettings },
    );
  }, [clubPublicSettings, clubReference, diver, participant]);

  const serviceExtraDiveModeColor = useMemo(() => {
    if (participant?.diveMode === 'autoSupervised') {
      return diveModeColorClassBuilder.getDiveModeColor(
        participant.autoSupervisedDetails?.diveMode,
        {
          clubSettings,
        },
      );
    }
  }, [
    clubSettings,
    participant.autoSupervisedDetails?.diveMode,
    participant?.diveMode,
  ]);
  return (
    <div className={'w-full text-gray-600'}>
      <div className={'flex items-start justify-start gap-2'}>
        <div>
          <ParticipantDiveCard
            className="text-center w-12"
            diveSession={diveSession}
            participant={participant}
          >
            <div
              className={clsx(
                'flex gap-2 justify-start',
                extraBadgeLabel && 'leading-3',
              )}
            >
              <div>{serviceLabel}</div>
              {/* {serviceExtraLabel && <div>{serviceExtraLabel}</div>} */}
            </div>

            {extraBadgeLabel && (
              <div className="leading-3">{extraBadgeLabel}</div>
            )}
          </ParticipantDiveCard>
        </div>
        {serviceExtraLabel && (
          <div
            className={clsx(
              'text-center w-12 px-0.5 py-0.5 rounded text-white app-text-shadow-sm shadow-gray-500',
              'text-xs font-medium',
            )}
            style={
              serviceExtraDiveModeColor
                ? { backgroundColor: serviceExtraDiveModeColor }
                : undefined
            }
          >
            {serviceExtraLabel}
          </div>
        )}
        {/* <ParticipantDiveTypeExtra
          participant={participant}
          diveSession={diveSession}
        /> */}
        <div className="w-10">
          <ParticipantDiveAptitudeBadge participant={participant} />
          {participant.targetDeep && (
            <ParticipantTargetDeep
              className="text-xs"
              targetDeep={participant?.targetDeep}
            />
          )}
        </div>
        <div
          className={`w-14 text-xs text-center font-bold h-full flex flex-col justify-between
          ${
            participant.gaz?.gazType && participant.gaz?.gazType !== 'air'
              ? 'text-red-400'
              : 'text-gray-400'
          }
        `}
        >
          <div className="px-0.5 py-0.5 ">
            <ParticipantRebreather
              className="text-xs whitespace-nowrap"
              rebreather={participant.equipment?.rebreather}
            />
            <ParticipantUnderwaterScooter
              className="text-xs whitespace-nowrap"
              underwaterScooter={participant.equipment?.underwaterScooter}
            />
            <ParticipantGaz
              className="text-xs whitespace-nowrap"
              gaz={participant.gaz}
              showDetails={true}
              showGazQuantity={true}
              showAir={true}
            />
          </div>
          {/* {participant.gaz?.gazQuantity && (
            <div className="mt-0.5 text-center text-xs font-bold text-gray-600">{`${participant.gaz?.gazQuantity}L`}</div>
          )} */}
        </div>
        <div className="w-full text-xs sm:text-base">
          <div className="w-full flex gap-2 justify-between">
            <div className="flex-grow flex gap-2">
              <ParticipantDiverNameLabel
                participantFirstNameBefore={participantFirstNameBefore}
                participant={participant}
                diveSession={diveSession}
                diver={participant.diver}
                displayAgeIfMinor={true}
              />
              {tags.length !== 0 && (
                <ColoredTagCardBar
                  className="extra-infos-tags"
                  key="coloredTagAvatarCardBar"
                  tags={tags}
                  avatarSize="small"
                  avatarOnly={true}
                />
              )}
            </div>
            <BookingGroupSizeLabel
              className="place-self-start hidden sm:inline-block px-1 py-px text-app-xxs xl:text-xs"
              booking={participant.booking}
            />
            {isMultiSessionConfigForParticipants && (
              <AppMultiCheckboxesTailwind
                labelPosition="right"
                required={true}
                options={multipleDiveSessionNumbersOptions}
                defaultValue={
                  participant.details?.multiSessionsPresenceNumbers ??
                  diveSessionNumbers
                }
                onChange={(
                  multiSessionsPresenceNumbers: MultipleDiveSessionNumber[],
                ) => {
                  onUpdateParticipantMultiSessionsPresenceNumbers({
                    multiSessionsPresenceNumbers,
                  });
                }}
              />
            )}
          </div>
          <ParticipantCommentCard
            className="hidden sm:block"
            participant={participant}
            diver={diver}
          />
        </div>
      </div>
      <ParticipantCommentCard
        className="sm:hidden"
        participant={participant}
        diver={diver}
      />
    </div>
  );
};
