import { Transition } from '@headlessui/react';
import React, { ElementType, FC } from 'react';

export const AppTransition: FC<{
  show: boolean;
  type: 'scale';
  className?: string;
  as?: ElementType<any>;
  children?: React.ReactNode | React.ReactNode[];
}> = ({ children, show, as, className }) => {
  return (
    <Transition
      as={as}
      show={show}
      enter="transition duration-150 ease-out"
      enterFrom="transform opacity-50"
      enterTo="transform opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform opacity-100"
      leaveTo="transform opacity-0"
      className={className}
    >
      {children}
    </Transition>
  );
};
