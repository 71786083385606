/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { fakerClient } from 'src/business/_core/data/app-operation';
import { useAutoFill } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

import { ClubDiverSimilarSearchCandidatesState } from '../../../../ClubDiverSimilarSearch';
import { ClubDiverSimilarSearchCandidatePreview } from '../../../../ClubDiverSimilarSearch/components';
import { FieldsToDisplayToggleButton } from '../../../DiverBookingPage/components/ClubDiverSelectorDialog/components';
import {
  BookingMemberGeneralEditFormRHF,
  BookingMemberGeneralEditFormRHFFieldsToDisplay,
} from '../../../DiverBookingPage/forms';
import {
  BookingInitialPlanEditFormModel,
  BookingInitialPlanRegistrationMode,
} from '../../../DiverBookingPage/forms/BookingInitialPlanEditForm/model';

export const CreateBookingInitialPlanContactPanel = ({
  form,
  registrationMode,
  similarSearchCandidatesState,
}: {
  form: UseFormReturn<BookingInitialPlanEditFormModel, any>;
  registrationMode: BookingInitialPlanRegistrationMode;
  similarSearchCandidatesState: ClubDiverSimilarSearchCandidatesState;
}) => {
  const autoFill = useAutoFill();

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    getValues,
    setValue,
    reset,
  } = form;

  const [firstName, lastName] = useWatch({
    control,
    name: ['diver.firstName', 'diver.lastName'],
  });

  const formValue = {
    firstName,
    lastName,
  };

  const [fieldsToDisplay, setFieldsToDisplay] =
    useState<BookingMemberGeneralEditFormRHFFieldsToDisplay>('frequent-only');

  return (
    <>
      <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
        Contact réservation
      </h3>
      <div className="max-w-7xl mt-5 mx-1 relative">
        {similarSearchCandidatesState?.selectedDuplicatedCandidate ? (
          <ClubDiverSimilarSearchCandidatePreview
            similarSearchCandidatesState={similarSearchCandidatesState}
          />
        ) : (
          <>
            {autoFill && (
              <div className="absolute -right-1 p-1 z-50">
                <AppButton
                  className="z-50"
                  color={'primary-outline-light'}
                  size="normal"
                  icon={AppHeroIcons.autoFill}
                  onClick={async () => {
                    // setShowAutoFillCount(true);
                    const { diver } = await fakerClient.generateDiver({
                      lastName: undefined,
                    });
                    const model: BookingInitialPlanEditFormModel = {
                      ...getValues(),
                      diver,
                    };

                    reset(model, {
                      keepDirty: true,
                    });
                    // onSubmit();
                  }}
                ></AppButton>
              </div>
            )}
            <BookingMemberGeneralEditFormRHF
              form={form as any}
              fieldsToDisplay={fieldsToDisplay}
              similarSearchCandidatesState={similarSearchCandidatesState}
            />
            <FieldsToDisplayToggleButton
              className="mt-5 text-right"
              displayAll={fieldsToDisplay === 'all'}
              onChange={(displayAll) => {
                if (displayAll) {
                  setFieldsToDisplay('all');
                } else {
                  setFieldsToDisplay('frequent-only');
                }
              }}
            />
            {registrationMode === 'single' ? (
              <AppMessage
                title="Instructions" // TODO: désactiver ce message après quelques semaines d'utilisation
                message={
                  <div className="flex flex-col gap-2">
                    <p className="">
                      Renseignez sur cette page{' '}
                      <b>les coordonnées du plongeur</b>.
                    </p>
                    <p className="">
                      Vous indiquerez son niveau et la prestation souhaitée{' '}
                      <b>à l'étape suivante</b>.
                    </p>
                    <p className="mt-4 hidden lg:block">
                      <b>ASTUCE</b>: passez à l'étape suivante en tapant sur la
                      touche <span className="app-keyboard-key">ENTER</span>
                    </p>
                  </div>
                }
              />
            ) : (
              <AppMessage
                className="hidden lg:block"
                title="Instructions"
                message={
                  <div className="flex flex-col gap-2">
                    <p className="">
                      <b>ASTUCE</b>: passez à l'étape suivante en tapant sur la
                      touche
                      <span className="app-keyboard-key">ENTER</span>
                    </p>
                  </div>
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
