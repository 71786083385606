import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { BillingTabParticipantPurchaseSessionsBillingResumes } from '../../models';

export function useDiverPurchaseCommonEditorDialogInitialAssociatedBookingProductIds({
  initialSessionsBillingResumes,
  mode,
}: {
  initialSessionsBillingResumes: BillingTabParticipantPurchaseSessionsBillingResumes;
  mode: 'create' | 'edit';
}): string[] {
  const initialAssociatedBookingProductIds = useMemo(() => {
    if (mode === 'create') {
      const allInitialSessionsBillingResumes =
        initialSessionsBillingResumes.sameTypeSameDiver;
      const nonCancelledInitialSessionsBillingResumes =
        allInitialSessionsBillingResumes.filter(
          (x) =>
            x.purchaseParticipant.bookingProduct.bookingProductStatus !==
            'cancelled',
        );

      const selected =
        nonCancelledInitialSessionsBillingResumes.length === 0 // si aucune plongée non annulée: on les sélectionne toutesnonCancelledInitialSessionsBillingResumes
          ? allInitialSessionsBillingResumes
          : nonCancelledInitialSessionsBillingResumes.filter(
              (x) =>
                nonCancelledInitialSessionsBillingResumes.length === 1 || // si une seule plongée, on la sélectionne même si dans le futur
                dateService.isPastUTC(x.diveSession.time),
            );
      return selected.map((x) => x.purchaseParticipant.bookingProduct._id);
    }
    return initialSessionsBillingResumes.sameTypeSameDiver
      .filter((x) => !!x.purchaseParticipant.bookingProduct.purchasePackageId)
      .map((x) => x.purchaseParticipant.bookingProduct._id);
  }, [initialSessionsBillingResumes.sameTypeSameDiver, mode]);

  return initialAssociatedBookingProductIds;
}
