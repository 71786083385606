import { dateService, nameFormatter } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppBookingSessionAuth } from 'src/business/auth/services';
import {
  AggregatedBookingSessionFull,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
} from '../../models';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { ParticipantCellBadge } from './ParticipantCellBadge';

export function DiverBookingCardPastSessionsTableDayParticipantCell({
  globalState,
  bookingParticipantFull: bookingParticipantFullSameBooking,
  bookingMemberFull,
  bookingSessionFull,
  displayName = false,
  displayLastName = false,
  bookingSessionAuth,
  theme,
  style,
  className,
  onClick,
  children,
}: {
  globalState: DiverBookingPageGlobalState;
  bookingParticipantFull?: PRO_BookingParticipantFull;
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  bookingSessionFull: AggregatedBookingSessionFull;
  displayName?: boolean;
  displayLastName?: boolean;
  bookingSessionAuth: AppBookingSessionAuth;
  theme: 'light' | 'normal';
  style?: 'normal' | 'highlight-light' | 'highlight-strong';
  children?: React.ReactNode | React.ReactNode[];

  className?: string;
  onClick?: (attrs: {
    action: 'create' | 'edit-on-current-booking' | 'edit-on-other-booking'; // ATTENTION: il peut s'agir du participant d'un autre booking, voir code ci-dessous
    bookingParticipantFull?: PRO_BookingParticipantFull;
    bookingSessionFull: AggregatedBookingSessionFull;
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  }) => void;
}) {
  const bookingParticipantFull: PRO_BookingParticipantFull = useMemo(() => {
    if (bookingParticipantFullSameBooking) {
      return bookingParticipantFullSameBooking;
    }

    // on regarde si le participant n'existe pas sur une autre résa
    const otherBookingParticipantFull = (
      globalState.aggregatedData.bookingParticipantsFull ?? []
    ).find(
      (x) =>
        x.diver?._id === bookingMemberFull?.diver?._id &&
        x.diveSession?.reference === bookingSessionFull?.diveSession?.reference,
    );

    return otherBookingParticipantFull;
  }, [
    bookingMemberFull?.diver?._id,
    bookingParticipantFullSameBooking,
    bookingSessionFull?.diveSession?.reference,
    globalState.aggregatedData.bookingParticipantsFull,
  ]);

  const onClickCell = useCallback(() => {
    if (bookingParticipantFullSameBooking) {
      onClick({
        action: 'edit-on-current-booking',
        bookingParticipantFull: bookingParticipantFullSameBooking,
        bookingSessionFull,
        bookingMemberFull,
      });
    } else if (bookingParticipantFull) {
      // hack: on a affiché le booking d'une autre session, donc il faut l'éditer proprement!
      const otherBookingResume =
        globalState.aggregatedData.bookingResumesLoaded.find(
          (x) => x.booking._id === bookingParticipantFull.booking._id,
        );
      const otherBookingSessionFull =
        otherBookingResume.bookingSessionsFull.find(
          (x) =>
            x.diveSession.reference ===
            bookingParticipantFull.diveSession.reference,
        );

      const otherBookingMemberFull = otherBookingResume.bookingMembersFull.find(
        (x) => x.diver._id === bookingParticipantFull.diver._id,
      );
      onClick({
        action: 'edit-on-other-booking',
        bookingParticipantFull,
        bookingSessionFull: otherBookingSessionFull,
        bookingMemberFull: otherBookingMemberFull,
      });
    } else {
      onClick({
        action: 'create',
        bookingSessionFull,
        bookingMemberFull,
      });
    }
  }, [
    bookingMemberFull,
    bookingParticipantFull,
    bookingParticipantFullSameBooking,
    bookingSessionFull,
    globalState.aggregatedData.bookingResumesLoaded,
    onClick,
  ]);

  const participant = bookingParticipantFull?.diveSessionParticipant;

  const diveSessionStatus = useMemo(
    () =>
      participant && participant.bookingState.value === 'cancelled'
        ? 'cancelled'
        : bookingSessionFull.diveSession.status,
    [bookingSessionFull.diveSession.status, participant],
  );

  const isPast = useMemo(
    () =>
      dateService.isPastUTC(bookingSessionFull.diveSession.time) &&
      !dateService.isTodayUTC(bookingSessionFull.diveSession.time),
    [bookingSessionFull.diveSession.time],
  );

  return (
    <Tippy
      delay={[1000, 100]}
      placement="top"
      content={nameFormatter.formatFullName(bookingMemberFull.diver, {
        format: 'firstName-first',
      })}
    >
      <div
        className={clsx(
          'p-0.5 relative group',
          bookingParticipantFull &&
            !bookingParticipantFullSameBooking &&
            'opacity-50',
          !bookingSessionAuth.edit
            ? 'ring ring-gray-400 ring-opacity-20'
            : bookingParticipantFull?.entityState === 'created'
            ? 'ring ring-status-success hover:ring-status-success-dark'
            : !bookingParticipantFull &&
              bookingSessionFull.entityState === 'created'
            ? 'ring ring-status-info hover:ring-status-info-dark'
            : style === 'highlight-light'
            ? 'ring ring-status-info hover:ring-status-info-dark ring-opacity-30'
            : style === 'highlight-strong'
            ? 'ring ring-status-info hover:ring-status-info-dark'
            : 'ring ring-gray-400 hover:ring-opacity-100 ring-opacity-20',
          bookingSessionAuth.edit && onClick && 'cursor-pointer',

          className,
        )}
      >
        {displayName && (
          <div
            onClick={onClickCell}
            className={clsx(
              'font-bold text-xs leading-4 sm:leading-4 pb-1 text-center ',
              bookingSessionAuth.edit && 'group-hover:text-gray-600',
              // !bookingParticipantFull &&
              //   !bookingSessionAuth.edit &&
              //   'invisible',
              bookingParticipantFull ? 'text-gray-500' : 'text-gray-400',
              diveSessionStatus === 'cancelled' && 'line-through',
            )}
          >
            <div className="truncate">
              {nameFormatter.formatFirstName(bookingMemberFull.diver)}
              &nbsp;
              {displayLastName &&
                nameFormatter.formatLastName(bookingMemberFull.diver)}
            </div>
          </div>
        )}
        <div className={'flex items-end gap-1 relative'}>
          {/* {bookingStateTag && (
            <ColoredTagAvatarCard
              onClick={onClickCell}
              className={`absolute ${
                displayName ? 'top-0.5 left-0.5' : 'top-1 left-1'
              }`}
              tag={bookingStateTag}
              size="small"
            />
          )} */}
          {bookingParticipantFull ? (
            <ParticipantCellBadge
              participant={participant}
              diver={bookingParticipantFull.diver}
              diveSession={bookingSessionFull.diveSession}
              onClick={onClickCell}
              theme={theme}
              includeBookingStateTag={true}
              includeParticipantSessionNumber={true}
            />
          ) : (
            <div
              className={clsx(
                'w-full h-8 flex justify-center items-center rounded ',

                `border ${
                  isPast
                    ? 'border-gray-300'
                    : `border-dive-mode-${
                        bookingMemberFull.diver.defaultDiveConfig?.diveMode ??
                        'observer'
                      }`
                } 
              `,
                !bookingSessionAuth.edit && 'invisible',
              )}
              onClick={!bookingSessionAuth.edit ? undefined : onClickCell}
            >
              <div
                className={`inline-block w-5 h-5 rounded-xl 
                ${
                  isPast
                    ? 'bg-gray-300 group-hover:bg-app-blue'
                    : bookingSessionFull.entityState === 'created'
                    ? 'bg-status-info-light opacity-50 group-hover:opacity-100 group-hover:bg-status-info-dark'
                    : 'bg-gray-400 group-hover:bg-app-blue'
                }
              `}
              >
                <AppHeroIcons.actionAdd className="w-full h-full fill-current text-white" />
              </div>
            </div>
          )}
          {children && children}
        </div>
      </div>
    </Tippy>
  );
}
