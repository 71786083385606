import {
  AppCompanyMessageContext,
  AppCompanyMessageTemplateDefDefault,
  DEFAULT_MESSAGES_TEMPLATES_BOOKING,
  DEFAULT_MESSAGES_TEMPLATES_SESSION,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';

export function useAppCompanyMessageTemplatesDefaultOptions({
  messageContext,
}: {
  messageContext: AppCompanyMessageContext;
}): {
  messageTemplatesOptions: ValueLabel<string, React.ReactNode>[];
  messageTemplates: AppCompanyMessageTemplateDefDefault[];
} {
  const clubResume = useClubResume();

  const messageTemplates: AppCompanyMessageTemplateDefDefault[] = useMemo(
    () =>
      messageContext === 'session'
        ? DEFAULT_MESSAGES_TEMPLATES_SESSION
        : messageContext === 'booking'
        ? DEFAULT_MESSAGES_TEMPLATES_BOOKING
        : [],
    [messageContext],
  );

  const messageTemplatesOptions = useMemo(
    () =>
      messageTemplates.map((mt: AppCompanyMessageTemplateDefDefault) => {
        const Icon =
          mt.style === 'success'
            ? AppHeroIcons.actionConfirm
            : mt.style === 'warning'
            ? AppHeroIcons.warn
            : AppHeroIcons.actionCancel;

        const option: ValueLabel<string, React.ReactNode> = {
          value: mt.name,
          label: (
            <p className="truncate text-sm font-medium uppercase">
              <Icon
                className={clsx(
                  'inline-block mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400',
                  mt.style === 'success'
                    ? 'text-status-success'
                    : mt.style === 'warning'
                    ? 'text-status-warn'
                    : 'text-status-error',
                )}
              />
              {mt.name}
            </p>
          ),
        };
        return option;
      }),
    [messageTemplates],
  );
  return {
    messageTemplates,
    messageTemplatesOptions,
  };
}
