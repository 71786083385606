/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  SettingsCategory,
  SettingsCategoryPage,
  SettingsCategoryPageBadge,
} from '../../_core';

export const ClubSettingsCategoryCardPageBadge = ({
  page,
  category,
  badge,
}: {
  page: SettingsCategoryPage;
  category: SettingsCategory;
  badge: SettingsCategoryPageBadge;
}) => {
  return badge.label ? (
    <span
      className="pl-2 inline-block px-2 text-white rounded-md opacity-80 group-hover/page:opacity-100"
      style={{
        color: category.color,
      }}
    >
      {badge.label && badge.label}
    </span>
  ) : badge.icon ? (
    <span
      className="pl-2 inline-block px-2 text-white rounded-md opacity-80 group-hover/page:opacity-100"
      style={{
        color: category.color,
      }}
    >
      {badge.icon && (
        <badge.icon
          className="w-7 h-7 bg-white"
          style={{ color: badge.iconColor }}
        />
      )}
    </span>
  ) : null;
};
