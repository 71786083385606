/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@material-ui/core';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';

export const DiverPurchaseTrainingFormInfoBox = () => {
  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();
  return (
    <Box className={globalClasses.pageBlock} marginTop={10}>
      <AppMessage
        type="info"
        title="Note"
        message={
          <>
            <div>
              Le champ <b>"Plongées supp."</b> permet d'ajouter des plongées
              supplémentaires à la formation de base.
            </div>
            <div>
              Le champ <b>"Arriéré"</b> permet de déduire des plongées qui
              auraient été effectuées sur cette formation en dehors de Mabadive
              (par exemple, les forfaits pré-existants).
            </div>
            <div>La plupart du temps, il faut laisser ces valeurs à "0".</div>
            <div className="mt-4">
              <p>
                <b>IMPORTANT</b>: pour fonctionner correctement, ce module doit
                être configuré en fonction de vos prestations et tarifs.
              </p>
              <p>Merci de nous contacter pour la configuration.</p>
            </div>
            <AppButton
              className="mt-5"
              color="primary-outline-light"
              icon={AppHeroIcons.contact}
              onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
            >
              Contactez-nous
            </AppButton>
          </>
        }
      />
    </Box>
  );
};
