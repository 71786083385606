import { numberFormatter } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  DiverPurchaseCommonFormModel,
  DiverPurchasePriceDetails,
} from './model';

export function useDiverPurchaseCommonLocalStateActions({
  form,
  priceDetails,
}: {
  form: UseFormReturn<DiverPurchaseCommonFormModel, any>;
  priceDetails: DiverPurchasePriceDetails;
}) {
  const { control, setValue } = form;
  const { totalPriceBeforeDiscount } = priceDetails;
  const mainCurrency = useAppCurrencyMain();

  const onChangeDiscountPercentage = useCallback(
    (discountPercentage: string | number) => {
      const discountAmount = numberFormatter.roundWithCents(
        (totalPriceBeforeDiscount * (discountPercentage as number)) / 100,
        mainCurrency.cents,
      );
      if (!isNaN(discountAmount)) {
        setValue('discountAmount', discountAmount);
      }
    },
    [mainCurrency.cents, setValue, totalPriceBeforeDiscount],
  );
  const onChangeDiscountAmount = useCallback(
    (discountAmount: string | number) => {
      if (totalPriceBeforeDiscount > 0) {
        const discountPercentage = numberFormatter.roundWithCents(
          ((discountAmount as number) / totalPriceBeforeDiscount) * 100,
          mainCurrency.cents,
        );
        if (!isNaN(discountPercentage)) {
          setValue('discountPercentage', discountPercentage);
        }
      }
    },
    [mainCurrency.cents, setValue, totalPriceBeforeDiscount],
  );
  return {
    onChangeDiscountPercentage,
    onChangeDiscountAmount,
  };
}

export type DiverPurchaseCommonLocalStateActions = ReturnType<
  typeof useDiverPurchaseCommonLocalStateActions
>;
