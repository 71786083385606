import clsx from 'clsx';
import React from 'react';
import { DashboardMetricTitleWithValueProps } from './model';

export const DashboardMetricTitleWithValue = ({
  icon: Icon,
  iconClassName = 'text-gray-600',
  label,
  description,
  value,
  valueClassName = 'bg-gray-600 text-white',
  className = 'text-gray-600',
}: DashboardMetricTitleWithValueProps) => {
  return (
    <div className={clsx(className)}>
      <div className={clsx('flex gap-2 justify-between items-center')}>
        <div>
          <div className={'flex gap-2 justify-start items-center rounded-t-lg'}>
            <div className={clsx('rounded-md p-1', iconClassName)}>
              <Icon className={clsx('h-6 w-6')} aria-hidden="true" />
            </div>
            <div>
              <div className="uppercase text-xl leading-5 font-bold">
                {label}
              </div>
            </div>
          </div>
          <div className="text-base text-gray-400">{description}</div>
        </div>
        {value !== undefined && (
          <div className="text-center">
            <div
              className={clsx(
                'py-1 px-1 rounded-md  flex justify-center items-center text-3xl font-bold',
                'border border-gray-200 ',
                valueClassName,
                value === 0 && 'text-gray-200',
              )}
            >
              {value}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
