import React from 'react';
import { AppToggleButtonGroupProps } from './AppToggleButtonGroupProps.type';
import { AppToogleButton } from './AppToogleButton';

// TODO se baser sur import { RadioGroup } from '@headlessui/react' (voir https://tailwindui.com/components/application-ui/forms/radio-groups)

export function AppToogleButtonGroup<T extends string | number>({
  selected,
  onChanges,
  items,
  className,
  color,
  theme,
}: AppToggleButtonGroupProps<T>) {
  return (
    <div className={`flex ${className ?? ''}`}>
      {items.map((item, i) => (
        <AppToogleButton
          key={i}
          color={color}
          theme={theme}
          selected={selected === item.value}
          onClick={() => {
            onChanges(item.value);
          }}
          className={`py-2 px-1 sm:px-2 ${i !== 0 ? 'ml-1' : ''}`}
        >
          {/* <AppHeroIcons.viewWeekIcon className="h-5 w-5 " /> */}
          {item.icon && <item.icon className="hidden md:inline h-3 w-3 mr-1" />}
          <span className="whitespace-nowrap">{item.label}</span>
        </AppToogleButton>
      ))}
    </div>
  );
}
