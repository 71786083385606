import { OnlineBookingParticipant } from '@mabadive/app-common-model';
import { dataSorter, dateService, search } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { OnlineBookingGqlCollectionCriteriaUI } from './fetch';
import { useClubOrdersListPageFilters } from './useClubOrdersListPageFilters.hook';
import { useFetchOrdersWithCache } from './useFetchOrdersWithCache.hook';

export function useClubOrdersListPageLocalState() {
  const filters = useClubOrdersListPageFilters();
  const { searchText, form } = filters;

  const [onlineBookingStates, bookingDate] = useWatch({
    control: form.control,
    name: ['onlineBookingStates', 'bookingDate'],
  });

  const criteriaUI: OnlineBookingGqlCollectionCriteriaUI = useMemo(() => {
    return {
      onlineBookingStates,
    };
  }, [onlineBookingStates]);

  const { data: orders, loadableContent } = useFetchOrdersWithCache(
    criteriaUI,
    {
      autoRefetchInterval: '5 minutes', // periodic refresh (partial or full)
      staleInterval: '1 minute', // (full refresh if data is older);
    },
  );

  const ordersSorted = useMemo(
    () =>
      dataSorter.sortMultiple(orders ?? [], {
        getSortAttributes: (x, i) => [
          {
            value:
              x.state === 'new'
                ? 1
                : x.state === 'pending-club'
                ? 2
                : x.state === 'pending-customer'
                ? 3
                : x.state === 'confirmed'
                ? 4
                : x.state === 'draft'
                ? 5
                : 6, // cancelled
            type: 'default',
            asc: true,
          },
          {
            value: new Date(x.onlineBookingLastUpdateDate),
            type: 'default',
            asc: false,
          },
        ],
        asc: true,
      }),
    [orders],
  );

  const filteredOrders = useMemo(() => {
    if (ordersSorted) {
      let localOrders = ordersSorted;

      if (searchText?.trim()?.length > 0) {
        return search.filter(localOrders, {
          searchText,
          getAttributes: (x) => {
            const bookingParticipant: OnlineBookingParticipant =
              x.details.bookingParticipants.find(
                (bp) => bp.diver._id === x.details.bookingContact.diverId,
              );
            return [
              bookingParticipant?.diver?.lastName,
              bookingParticipant?.diver?.firstName,
              x.details?.bookingGroup?.name,
            ];
          },

          // maxResults: 50,
          // sortResultsByBestMatch: true,
        });
      } else {
        if (bookingDate) {
          return localOrders.filter((x) =>
            dateService.isSameDayUTC(x.booking?.bookingDate, bookingDate),
          );
        }
        return localOrders;
      }
    }
    return ordersSorted;
  }, [bookingDate, ordersSorted, searchText]);

  const filteredOrdersLimited = useMemo(
    () => filteredOrders.slice(0, 100),
    [filteredOrders],
  );

  return {
    filteredOrders,
    filteredOrdersLimited,
    filters,
    loadableContent,
  };
}

export type ClubOrdersListPageLocalState = ReturnType<
  typeof useClubOrdersListPageLocalState
>;
