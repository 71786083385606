import React from 'react';
import { PRO_BookingResume } from '../../DiverBookingPage/models';
import { DiveCenterBookingMembersListDiffItemCard } from './DiveCenterBookingMembersListDiffItemCard';
import { DiveCenterBookingMemberDiff } from './model';
import { BookingResumeOnClickMemberAction } from './model/BookingResumeOnClickMemberAction.type';

export function DiveCenterBookingMembersListDiffItem({
  enableCustomerUpdate,
  aggregatedBookingResume,
  memberDiff,
  onClick,
}: {
  enableCustomerUpdate: boolean;
  aggregatedBookingResume: PRO_BookingResume;
  memberDiff: DiveCenterBookingMemberDiff;
  onClick?: (
    memberDiff: DiveCenterBookingMemberDiff,
    action: BookingResumeOnClickMemberAction,
  ) => void;
}) {
  const { status, original, final, changes } = memberDiff;

  return (
    <li
      className="text-xs"
      onClick={() => enableCustomerUpdate && onClick(memberDiff, 'edit')}
    >
      <button
        className={`w-full block ${
          onClick && enableCustomerUpdate
            ? 'cursor-pointer hover:bg-gray-50'
            : 'cursor-default select-text'
        }`}
      >
        <div className={'w-full px-2 py-2 flex gap-2'}>
          <div className="flex-grow flex flex-col items-stretch gap-y-1  md:flex-row gap-x-2">
            <DiveCenterBookingMembersListDiffItemCard
              aggregatedBookingResume={aggregatedBookingResume}
              bookingMemberFull={memberDiff.original}
              className={
                'md:w-1/2 grayscale opacity-80 border-2 p-1 border-gray-300'
              }
            />

            {status === 'updated' && (
              <DiveCenterBookingMembersListDiffItemCard
                aggregatedBookingResume={aggregatedBookingResume}
                bookingMemberFull={memberDiff.final}
                className={'md:w-1/2 border-2 p-1 border-app-blue'}
              />
            )}
          </div>
        </div>
      </button>
    </li>
  );
}
