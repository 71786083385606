/* eslint-disable @typescript-eslint/no-unused-vars */

import 'dayjs/locale/fr';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppPageContainer } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubDialogsProvider } from 'src/pages/_dialogs';
import { ClubPlanningLightPageDialogsProvider } from '../../club-planning-light/ClubPlanningLightPageDialogsProvider';
import {
  PLANNING_CONFIG_CONTEXT_ID_PLANNING_STAFF_VIEW,
  usePlanningConfig,
} from '../../club-planning-light/hooks';
import { useClubPlanningActions } from '../../club-planning-light/useClubPlanningActions.hook';
import { ClubPlanningStaffPanel } from './ClubPlanningStaffPanel';
import {
  PlanningStaffLocalState,
  usePlanningStaffLocalState,
} from './usePlanningStaffLocalState.hook';

export const ClubPlanningStaffPage = () => {
  const redirectTo = useRedirect();

  const [planningConfig, setPlanningConfig] = usePlanningConfig({
    enableRouteParams: true,
    context: PLANNING_CONFIG_CONTEXT_ID_PLANNING_STAFF_VIEW,
  });

  const localState: PlanningStaffLocalState = usePlanningStaffLocalState({
    planningConfig,
    setPlanningConfig,
  });

  const clubResume = useClubResume();

  const {
    weekPlanning,
    diveSessionResumes,
    clubEvents,
    loadableContent,
    dialogsOld,
    dailyConfigs,
  } = localState;

  const { sessionCardMenuActions, clickActions, dialogsState } =
    useClubPlanningActions(localState);

  const isAnyDialogOpen =
    dialogsOld?.isAnyFakeDialogOpen ||
    dialogsOld?.isAnyRealDialogOpen ||
    dialogsState?.isAnyDialogOpen;

  return (
    <AppPageContainer
      maxScreenClassName="max-w-[110rem]"
      className="h-full bg-white overflow-y-auto"
      {...loadableContent}
    >
      <ClubDialogsProvider dialogsState={dialogsState}>
        <ClubPlanningLightPageDialogsProvider
          className="h-full"
          dialogs={dialogsOld}
          localState={localState}
          dialogsState={dialogsState}
        >
          <ClubPlanningStaffPanel
            key={`${planningConfig.viewPeriod}`}
            context={'planning-staff-page'}
            isAnyDialogOpen={isAnyDialogOpen}
            localState={localState}
            sessionCardMenuActions={sessionCardMenuActions}
            sessionsDisplayConfigs={[]}
            clickActions={clickActions}
            dialogsState={dialogsState}
            navigationBarDisplay={{ view: true, mode: true, actions: true }}
          />
        </ClubPlanningLightPageDialogsProvider>
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
