import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ClubSettingsGeneralPageViewState } from './_model';
import { clubSettingsGeneralPageUrlManager } from './_services';

export function useClubSettingsGeneralPageUpdateURL(
  viewState: ClubSettingsGeneralPageViewState,
  { enableRouteParams }: { enableRouteParams: boolean },
) {
  const history = useHistory();
  useEffect(() => {
    if (enableRouteParams) {
      // update url params
      const searchParams: string[] =
        clubSettingsGeneralPageUrlManager.buildSearchParams(viewState);

      history.replace({
        pathname: window.location.pathname,
        search: searchParams?.length ? `?${searchParams.join('&')}` : undefined,
      });
    }
  }, [enableRouteParams, history, viewState]);
}
