import { ClubPurchasePayment } from '@mabadive/app-common-model';
import React from 'react';
import { AppDateTimeLabel } from 'src/business/club/modules/_common/ui';

export function PurchasePaymentDueDate({
  purchasePayment,
  className,
}: {
  purchasePayment: ClubPurchasePayment;
  className?: string;
}) {
  return purchasePayment.paymentState === 'pending' &&
    purchasePayment?.dueDate ? (
    <div className={className}>
      échéance:{' '}
      <AppDateTimeLabel
        className={''}
        timezone="utc"
        date={purchasePayment?.dueDate}
        format="date"
        steps={[
          {
            hours: 24 * 1,
            className: 'text-status-error',
          },
          {
            hours: 24 * 3,
            className: 'text-status-warn',
          },
          {
            className: 'text-gray-400',
          },
        ]}
      />
    </div>
  ) : null;
}
