/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubPlanningDayFull } from '../model';
export const CreateSessionButton = ({
  day,
  onClickCreateNewSession,
  mode,
  className,
}: {
  day: ClubPlanningDayFull;
  mode: 'tiny' | 'large';
  onClickCreateNewSession?: ({
    clubReference,
    day,
  }: {
    clubReference: string;
    day: ClubPlanningDayFull;
  }) => void;
  className?: string;
}) => {
  const redirectTo = useRedirect();
  const globalClasses = useGlobalClasses();
  const clubResume = useClubResume();
  const isEditSessionRole = useAppSecurityUserHasRole(
    'club-edit-planning-session',
  );
  return !onClickCreateNewSession || !isEditSessionRole ? null : (
    <AppButton
      size="small"
      color={'primary-outline-light'}
      className={clsx('w-full', className)}
      onClick={() => {
        if (onClickCreateNewSession) {
          onClickCreateNewSession({
            clubReference: clubResume.reference,
            day,
          });
        }
      }}
    >
      <div
        className={clsx(
          'px-0.5 overflow-hidden flex items-center gap-1 md:gap-2 uppercase',

          mode === 'tiny' && 'flex-col sm:flex-row ',
          mode === 'tiny'
            ? 'text-app-xxs sm:text-xs md:text-sm'
            : 'text-xs sm:text-sm md:text-base',
        )}
      >
        <AppHeroIcons.actionAddCircle className="w-6 h-6" />
        <div className="">session</div>
      </div>
    </AppButton>
  );
};
