import {
  DiveSessionResumeFull,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { apiDownloader } from 'src/_common-browser';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import {
  ClubDiveSessionBoats,
  ClubDiveSessionHeaderTitleDbBlock,
  ClubDiveSessionNameLight,
} from 'src/business/club/modules/club-dive-session/components';
import { diveSessionStyleBuilder } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/diveSessionStyleBuilder.service';
import { AppFormControlRHF_Deprecated, AppInputBooleanRHF } from 'src/lib/form';
import { DailyExportDownloadModalFormModel } from './model';

export function DailyExportDownloadModalContentSelectableSession({
  exportSettingsId,
  form,
  dateUTC,
  session,
  sessionIndex,
  sessionsTotalCount,
  className,
}: {
  exportSettingsId: string;
  form: UseFormReturn<DailyExportDownloadModalFormModel, any, undefined>;
  dateUTC: Date;
  session: DiveSessionResumeFull;
  sessionIndex: number;
  sessionsTotalCount: number;
  className?: string;
}) {
  const { control, setValue } = form;
  const sessionCheckFormName = `sessions.${sessionIndex}.selected` as const;
  const [sessionCheckValue] = useWatch({
    control,
    name: [sessionCheckFormName],
  });
  const isMultiSession = !!session.diveTourSession2;

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );

  const sessionBorderStyle = diveSessionStyleBuilder.buildBorderStyleFromTime({
    isVirtual: false,
    time: session.time,
  });
  const sessionsCount = session.diveTourSession2 ? 2 : 1;

  const isSelectable = sessionsTotalCount > 1;

  const showDownloadButton = sessionsTotalCount > 1;

  const download = useCallback(() => {
    const beginDateUTC = dateUTC;
    const endDateUTC = dateUTC;
    apiDownloader.downloadDailyResumeSheetCustom({
      exportSettingsId,
      beginDateUTC,
      endDateUTC,
      sessionsIds: [session._id],
    });
  }, [exportSettingsId, dateUTC, session._id]);

  return (
    <div
      className={clsx(
        'flex items-start gap-2 overflow-hidden border-l-2',
        !sessionCheckValue && 'grayscale opacity-50',
        sessionBorderStyle,
        'cursor-pointer hover:bg-gray-50',
        !isSelectable && 'pl-2',
        className,
      )}
      onClick={() => {
        if (isSelectable) {
          setValue(sessionCheckFormName, !sessionCheckValue);
        } else {
          download();
        }
      }}
    >
      {isSelectable && (
        <AppFormControlRHF_Deprecated
          control={control}
          name={sessionCheckFormName}
          renderComponent={(props) => (
            <AppInputBooleanRHF
              {...props}
              type="checkbox"
              scale={'lg'}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          )}
        />
      )}
      <div className="flex-grow font-bold text-sm text-gray-500 grid gap-1">
        <div className={'flex gap-2 items-start'}>
          <div className={`text-day-period-${timeDayPeriod}-dark`}>
            N°{sessionIndex + 1}
          </div>
          <div>{dateService.formatUTC(session.time, 'HH:mm')}</div>
          {isMultiSession && session.diveTourSession2?.time && (
            <div>
              + {dateService.formatUTC(session.diveTourSession2?.time, 'HH:mm')}
            </div>
          )}
          <ClubDiveSessionHeaderTitleDbBlock
            format="short"
            sessionsCount={sessionsCount}
            timeDayPeriod={timeDayPeriod}
          />
          <ClubDiveSessionNameLight
            className={'font-bold'}
            diveSession={session}
            truncateText={true}
          />
        </div>
        <div>
          <ClubDiveSessionBoats diveSession={session} />
        </div>
      </div>
      {showDownloadButton && (
        <div>
          <AppButton
            color="primary-bg"
            className={''}
            icon={AppIconsAction.download}
            onClick={(e) => {
              e.stopPropagation();
              download();
            }}
          >
            Télécharger
          </AppButton>
        </div>
      )}
    </div>
  );
}
