/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveCenterDailyConfig,
  DiveSessionResumeParticipantsByGroup,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
  diveSessionGroupBuilder,
} from '@mabadive/app-common-services';
import { useEffect, useMemo, useState } from 'react';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { diveSessionParticipantStatsBuilder } from 'src/business/club/data';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { DiveSessionParticipantsStats } from 'src/business/club/modules/club-dive-session/model';
import { PlanningViewConfig } from 'src/business/club/modules/club-planning/_model';
import {
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayStyle,
  DiveSessionSimpleCardDisplayOptions,
} from '../../../model';
import { diveSessionStyleBuilder } from '..//diveSessionStyleBuilder.service';
import { CalendarSessionWidthMode } from '../session-common';

export function useDiveSessionSimpleCardLocalState({
  session,
  dailyConfig,
  planningConfig,
  display: displayConfig,
  selectionColor,
  expandByDefault = false,
  emptyParticipantsCount = 0,
  flexGrow,
  clickActions,
  widthMode,
}: {
  session: ClubPlanningLightSessionDef;
  dailyConfig: DiveCenterDailyConfig;
  planningConfig: PlanningViewConfig;
  display: DiveSessionSimpleCardDisplayOptions;
  selectionColor?: ClubPlanningPanelSessionDisplayStyle;
  expandByDefault?: boolean;
  emptyParticipantsCount?: number;
  flexGrow: boolean;
  clickActions: ClubPlanningLightPanelViewClickActions;
  widthMode: CalendarSessionWidthMode;
}) {
  const [isExpanded, setIsExpanded] = useState(expandByDefault);
  useEffect(() => {
    if (session?.reference) {
      // reset on session changes
      setIsExpanded(expandByDefault);
    }
  }, [expandByDefault, session?.reference]);

  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume?.clubReference;

  const staffMembers = diveCenterResume?.staffMembers;

  const dailyConfigs: DiveCenterDailyConfig[] = useMemo(
    () => (dailyConfig ? [dailyConfig] : []),
    [dailyConfig],
  );

  const { bookingSessionParticipants, participants, groups } = session;

  const diveSessionParticipantsByGroup: DiveSessionResumeParticipantsByGroup =
    useMemo(
      () =>
        diveSessionGroupBuilder.buildDiveSessionParticipantsGroups({
          diveSession: session,
          groups,
          participants,
          staffMembers,
          ignoreCancelledParticipants: !planningConfig.showInactiveParticipants,
          ignoreGroupsWithoutParticipants: false,
          diveModesToAutoGroup: ['autoSupervised', 'theoretical-training'],
        }),
      [
        session,
        groups,
        participants,
        staffMembers,
        planningConfig.showInactiveParticipants,
      ],
    );

  const diveSessionParticipantsStats: DiveSessionParticipantsStats = useMemo(
    () =>
      diveSessionParticipantStatsBuilder.buildDiveSessionParticipantsStats({
        clubReference,
        session,
        participants: diveSessionParticipantsByGroup.all,
        groups: diveSessionParticipantsByGroup.allGroups,
        staffMembers: diveCenterResume?.staffMembers,
        dailyConfigs,
        clubSettings: diveCenterResume?.clubResume?.clubSettings,
      }),
    [
      clubReference,
      dailyConfigs,
      diveCenterResume?.clubResume?.clubSettings,
      diveCenterResume?.staffMembers,
      diveSessionParticipantsByGroup.all,
      diveSessionParticipantsByGroup.allGroups,
      session,
    ],
  );

  const isPast = useMemo(
    () =>
      !dateService.isTodayUTC(session.time) &&
      dateService.isPastUTC(session.time),
    [session.time],
  );

  const display = useMemo(() => {
    const d: DiveSessionSimpleCardDisplayOptions = {
      ...displayConfig,
      tide: isExpanded ? displayConfig.tide : false,
      weather: isExpanded ? displayConfig.weather : false,
      comment: isExpanded ? displayConfig.comment : false,
      addParticipantButton1:
        isExpanded && session.status === 'on'
          ? displayConfig.addParticipantButton1
          : false,
      addParticipantButton2:
        isExpanded && session.status === 'on'
          ? displayConfig.addParticipantButton2 && !isPast
          : false,
      openSessionButton: isExpanded ? displayConfig.openSessionButton : false,
      truncateText: isExpanded ? true : true,
      sessionIndex: displayConfig.sessionIndex,
      isExpandable: displayConfig.isExpandable,
    };
    return d;
  }, [displayConfig, isExpanded, isPast, session.status]);

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );

  const sessionBorderStyle = useMemo(
    () =>
      diveSessionStyleBuilder.buildBorderStyle({
        isPast,
        isVirtual: session.isVirtual,
        timeDayPeriod,
        selectionColor,
      }),
    [isPast, selectionColor, session.isVirtual, timeDayPeriod],
  );

  const surfaceSecurityStaffMember = useMemo(
    () =>
      session.diveTourSession1?.surfaceSecurityStaffId
        ? diveCenterResume?.staffMembers.find(
            (p) => p._id === session.diveTourSession1?.surfaceSecurityStaffId,
          )
        : undefined,
    [
      session.diveTourSession1?.surfaceSecurityStaffId,
      diveCenterResume?.staffMembers,
    ],
  );

  const isEditParticipantRole = useAppSecurityUserHasRole(
    'club-edit-participant',
  );

  return {
    state: {
      isExpanded,
      setIsExpanded,
      display,
      sessionBorderStyle,
      isEditParticipantRole,
      dailyConfig,
      planningConfig,
      flexGrow,
      widthMode,
      timeDayPeriod,
    },
    data: {
      isPast,
      session,
      selectionColor,
      emptyParticipantsCount,
      diveSessionParticipantsByGroup,
      diveSessionParticipantsStats,
      surfaceSecurityStaffMember,
    },
    actions: {
      ...clickActions,
    },
  };
}

export type UseDiveSessionSimpleCardLocalState = ReturnType<
  typeof useDiveSessionSimpleCardLocalState
>;
