import { BookingGroup } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { BookingTabModelDatesRange } from '../../models';
import { BookingGroupTypeLabel } from '../BookingLabelsComponents';

export function DiverBookingTitle({
  bookingNumber,
  datesRange,
  datesRangeClassName,
  bookingGroup,
  className,
}: {
  bookingNumber?: number;
  datesRange: BookingTabModelDatesRange;
  datesRangeClassName?: string;
  bookingGroup?: BookingGroup;
  className?: string;
}) {
  const bookingNumberLabel =
    bookingNumber === undefined ? '' : `N°${bookingNumber}`;
  return (
    <span className={clsx('inline-flex gap-2 items-start', className)}>
      {!datesRange.minDate ? (
        `Réservation ${bookingNumberLabel} (nouvelle)`
      ) : datesRange.minDate.getTime() === datesRange.maxDate.getTime() ? (
        <span>
          Réservation {bookingNumberLabel}{' '}
          <span className={datesRangeClassName}>
            du <b>{dateService.formatUTC(datesRange.minDate, 'DD/MM/YYYY')}</b>
          </span>
        </span>
      ) : (
        <span>
          Réservation {bookingNumberLabel}{' '}
          <span className={datesRangeClassName}>
            du <b>{dateService.formatUTC(datesRange.minDate, 'DD/MM/YYYY')}</b>{' '}
            au <b>{dateService.formatUTC(datesRange.maxDate, 'DD/MM/YYYY')}</b>
          </span>
        </span>
      )}
      {bookingGroup && (
        <BookingGroupTypeLabel
          className={clsx('px-1 text-xs')}
          type={bookingGroup?.type}
          label={bookingGroup?.name?.toUpperCase()}
          preferLabelOnly={false}
        />
      )}
    </span>
  );
}
