/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  DailyExportConfigDialogState,
  DailyExportConfigFormModel,
} from './_model';
export function useDailyExportConfigDialogLocalState({
  inputState,
}: {
  inputState: DailyExportConfigDialogState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const { initialState } = inputState;

  const { exportRef } = initialState;

  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const [pageState, setPageState] = useState<{
    step: 'edit-daily-export-activity';
  }>({ step: 'edit-daily-export-activity' });

  const defaultValues: DailyExportConfigFormModel = useMemo(() => {
    return {
      hideOldSecuritySheetExport:
        diveCenterResume?.privateSettings?.exports?.hideOldSecuritySheetExport,
      dailyResumes: diveCenterResume?.privateSettings?.exports?.dailyResumes,
    };
  }, [
    diveCenterResume?.privateSettings?.exports?.dailyResumes,
    diveCenterResume?.privateSettings?.exports?.hideOldSecuritySheetExport,
  ]);

  const form = useForm<DailyExportConfigFormModel>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && formState.isDirty,
    [form, formState.isDirty],
  );

  return {
    form,
    hasFormChanges,
    initialState,
    inputState,
    data: {
      diveCenterResume,
      pageState,
      setPageState,
      operationInProgressMessage,
      setOperationInProgressMessage,
    },
  };
}

export type DailyExportConfigDialogLocalState = ReturnType<
  typeof useDailyExportConfigDialogLocalState
>;
