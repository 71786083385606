/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from '@material-ui/core';
import React, { CSSProperties, ReactNode } from 'react';
import { RxjsFormAttributeState } from '../rxjs-form-attribute/RxjsFormAttributeState.model';

export const RxjsFormControl = ({
  label,
  attrState,
  renderComponent,
  renderError,
  style,
}: {
  label: string;
  attrState?: RxjsFormAttributeState<any>;
  renderComponent: () => ReactNode;
  renderError?: () => ReactNode;
  style?: CSSProperties;
}) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" style={style}>
      <FormControl
        required={attrState && attrState.def.required}
        error={attrState && attrState.showError}
      >
        <FormLabel className="form-label pl-1" component="legend">
          <Typography variant="overline">{label}</Typography>
        </FormLabel>
        <Box className="form-input">{renderComponent()}</Box>
        <FormHelperText error={true}>
          {attrState && attrState.showError
            ? renderErrorMessage(attrState)
            : null}
        </FormHelperText>
      </FormControl>
    </Box>
  );

  function renderErrorMessage(
    attrState: RxjsFormAttributeState<any, string>,
  ): React.ReactNode {
    const msg = renderError ? renderError() : null;
    if (msg) {
      return msg;
    }
    return renderGenericMessages(attrState);
  }
};

function renderGenericMessages(
  attrState: RxjsFormAttributeState<any, string>,
): React.ReactNode {
  switch (attrState.validation.errorKey) {
    case 'required':
      return 'Champ obligatoire';
    case 'email':
      return 'Adresse e-mail invalide';
    case 'minLength':
      if (
        attrState.validation.errorContext &&
        (attrState.validation.errorContext as any).minLength
      ) {
        return `Longueur minimale: ${
          (attrState.validation.errorContext as any).minLength
        } caractères`;
      }
      return 'Longeur trop courte';
  }
}
