import { dateService } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  AggregatedBookingSessionFull,
  BookingTabModel,
  DiverBookingPageUpdateState,
  PRO_BookingMemberFull,
} from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
import { quickBookingParticipantCreator } from '../state-updators';

export function useDiverBookingPageCreateParticipantsQuickIfPossible(
  setUpdateState: DiverBookingPageSetUpdateStateFn,
  updateState: DiverBookingPageUpdateState,
) {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = clubResume.clubSettings;
  return useCallback(
    ({
      bookingResumeMembers,
      bookingTabModel,
      bookingSessionFull,
    }: {
      bookingResumeMembers: PRO_BookingMemberFull[];
      bookingTabModel: BookingTabModel;
      bookingSessionFull: AggregatedBookingSessionFull;
    }) => {
      const updateStateLocal =
        quickBookingParticipantCreator.createParticipantsIfPossible({
          context: {
            diveCenterResume,
            participantsBySessions: bookingTabModel.participantsBySessions,
            updateState,
          },
          newParticipants: [
            {
              bookingSessionFull,
              bookingResumeMembers,
            },
          ],
        });

      setUpdateState(updateStateLocal, {
        action: 'create multiple participants quick',
        meta: {
          session: dateService.formatUTC(
            bookingSessionFull?.diveSession?.time,
            'DD/MM/YYYY HH:mm',
          ),
          members: bookingResumeMembers?.map((x) => x.bookingMember?._id),
          divers: bookingResumeMembers?.map((x) => x.diver?._id),
        },
      });
    },
    [diveCenterResume, setUpdateState, updateState],
  );
}
