import { ClubPublicSettings } from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  dateService,
  diverBookingCreator,
} from '@mabadive/app-common-services';
import {
  DiverBookingPageAggregatedData,
  DiverBookingPageUpdateState,
  PRO_BookingResume,
} from '../../../models';

export const quickBookingCreator = {
  selectOrCreateBooking,
};

function selectOrCreateBooking({
  aggregatedData,
  diveCenterId,
  clubReference,
  diverId,
  updateStateLocal,
  publicSettings,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  diveCenterId: string;
  clubReference: string;
  diverId: string;
  updateStateLocal: DiverBookingPageUpdateState;
  publicSettings: ClubPublicSettings;
}): {
  aggregatedBooking: PRO_BookingResume;
} {
  // on sélectionne un booking actif avec ce participant et qui a au moins une session future ou dans les 3 derniers mois

  const personalBookings: PRO_BookingResume[] =
    aggregatedData.bookingResumesVisible.filter(
      (b) =>
        b.booking.diveCenterId === diveCenterId &&
        // b.bookingMembers.find((x) => x.diverId === diverId) &&
        b.booking.active && // active
        b.bookingMembers.find((x) => x.diverId === diverId),
    );
  const recentPersonalBookings: PRO_BookingResume[] = personalBookings.filter(
    (b) =>
      b.bookingSessionsFull.length === 0 || // vide, ou avec session récente (< 3 mois)
      !!b.bookingSessionsFull.find(
        (bs) => dateService.getAgeInMonths(bs.diveSession.time) < 3,
      ),
  );

  let aggregatedBooking: PRO_BookingResume = recentPersonalBookings?.[0]; // on prend le premier

  if (!aggregatedBooking) {
    // pas de booking récent, on en créé un
    const newBooking = diverBookingCreator.createBooking({
      clubReference,
      diveCenterId,
      bookingContactDiverId: diverId,
      publicSettings,
      details: {},
    });
    updateStateLocal.bookingsChanges = changeDescriptorManager.createOne(
      newBooking,
      {
        changeDescriptors: updateStateLocal.bookingsChanges,
      },
    );
    aggregatedBooking = {
      isNewBooking: true,
      booking: newBooking,
      datesRange: {
        minDate: undefined,
        maxDate: undefined,
      },
      clubParticipants: [],
      bookingMembers: [],
      bookingSessions: [],
      bookingSessionsFull: [],
      bookingMembersFull: [],
      bookingParticipantsFull: [],
      bookingCustomerUpdates: [],
      bookingContact: null,
      bookingDeposits: [],
      creditNotes: [],
      onlineBookings: [],
    };
  }
  return { aggregatedBooking };
}
