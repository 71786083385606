import React from 'react';
import { AppTabsBarTab } from 'src/business/_core/modules/layout/components';
import {
  AppHeroIcons,
  AppIconsMaterial,
} from 'src/business/_core/modules/layout/icons';
import { ClubOfferTabId } from './ClubOfferTabId.type';

export const CLUB_OFFER_SPACE_TABS: AppTabsBarTab<ClubOfferTabId>[] = [
  {
    id: 'offers-dive',
    label: 'Explorations et sorties',
    labelShort: 'Explo.',
    icon: <AppIconsMaterial.plan className="icon" />,
    urlSuffix: 'dive',
    auth: { requiredRoles: ['club-view-settings-offers'] },
  },
  {
    id: 'offers-training',
    label: 'Formations',
    labelShort: 'Form.',
    icon: <AppIconsMaterial.training className="icon" />,
    urlSuffix: 'training',
    auth: { requiredRoles: ['club-view-settings-offers'] },
  },
  {
    id: 'offers-product',
    label: 'Autres produits',
    labelShort: 'Prod.',
    icon: <AppHeroIcons.offerProducts className="w-6 h-6" />,
    urlSuffix: 'products',
    auth: { requiredRoles: ['club-view-settings-offers'] },
  },
];
