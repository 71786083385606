import {
  ClubSettingsGeneralPageMode,
  ClubSettingsGeneralPageViewState,
} from '../_model';
import { ClubSettingsGeneralPageTabId } from './clubSettingsGeneralPageTabLabelFormatter.service';

export type ClubSettingsGeneralPagegUrlParameters = Pick<
  ClubSettingsGeneralPageViewState,
  'mode' | 'tabId'
>;

export const clubSettingsGeneralPageUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const QUERY_PARAMS = {
  mode: 'm',
  tabId: 't',
};

function getQueryParameters(): ClubSettingsGeneralPagegUrlParameters {
  const params = new URLSearchParams(window.location.search);

  const mode = params.get(QUERY_PARAMS.mode) as ClubSettingsGeneralPageMode;

  const tabId = params.get(QUERY_PARAMS.tabId) as ClubSettingsGeneralPageTabId;

  const config: ClubSettingsGeneralPagegUrlParameters = {
    mode,
    tabId,
  };
  return config;
}

function buildSearchParams(
  viewConfig: Partial<ClubSettingsGeneralPagegUrlParameters>,
): string[] {
  if (!viewConfig) {
    return [];
  }
  const { mode, tabId } = viewConfig;

  const searchParams: string[] = [];

  if (mode) {
    searchParams.push(`${QUERY_PARAMS.mode}=${mode}`);
  }
  if (tabId) {
    searchParams.push(`${QUERY_PARAMS.tabId}=${tabId}`);
  }

  return searchParams;
}
