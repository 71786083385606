/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveGazType, DIVE_GAZ_TYPES } from '@mabadive/app-common-model';
import { diveGazFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useGazTypeOptions(): ValueLabel<DiveGazType>[] {
  const diveGazTypeOptions: ValueLabel<DiveGazType>[] = useMemo(
    () =>
      DIVE_GAZ_TYPES.map((gazType) => ({
        value: gazType,
        label: diveGazFormatter.formatGazType(gazType, { format: 'long' }),
      })),
    [],
  );

  return diveGazTypeOptions;
}
