import { dataSorter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { useDiveCenterResume } from 'src/business/club/data/hooks/useDiveCenterResume.hook';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export const useAccommodationsOptions = ({
  diveCenterId,
}: { diveCenterId?: string } = {}) => {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const accommodationsOptions: ValueLabel<string>[] = useMemo(() => {
    const accommodations = diveCenterId
      ? (clubResume?.accommodations ?? []).filter(
          (x) => x.diveCenterId === diveCenterId,
        )
      : diveCenterResume?.accommodations ?? [];

    return dataSorter
      .sortMultiple(accommodations ?? [], {
        getSortAttributes(a, index) {
          return [
            {
              value: a.name,
            },
            {
              value: a._id,
            },
          ];
        },
      })
      .map((a) => ({
        value: a._id,
        label: a.name,
      }));
  }, [
    clubResume?.accommodations,
    diveCenterId,
    diveCenterResume?.accommodations,
  ]);
  return accommodationsOptions;
};
