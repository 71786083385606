import { ClubDiver } from '@mabadive/app-common-model';
import { dataSorter, nameFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

// TODO faire comme pour useBookingOptions
export const useDiversOptions = (
  divers: Pick<ClubDiver, '_id' | 'firstName' | 'lastName'>[],
): ValueLabel<string>[] => {
  const diversFilterOptions: ValueLabel<string>[] = useMemo(
    () =>
      dataSorter
        .sortMultiple(divers ?? [], {
          getSortAttributes(d, index) {
            return [
              {
                value: d.firstName,
              },
              {
                value: d.lastName,
              },
              {
                value: d._id,
              },
            ];
          },
        })
        .map((d) => {
          const option: ValueLabel<string> = {
            value: d._id,
            label: nameFormatter.formatFullName(d, {
              format: 'firstName-first',
            }),
          };
          return option;
        }),
    [divers],
  );
  return diversFilterOptions;
};
