/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { CalendarSessionWidthMode } from '../session-common';

export const DiveSessionSimpleCardParticipantsGroupNoParticipant = ({
  widthMode,
  className,
}: {
  widthMode: CalendarSessionWidthMode;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        'text-center font-medium text-gray-500 uppercase text-xs border border-gray-300 bg-gray-50',
        widthMode === 'tiny' && 'hidden',
        widthMode === 'small' && 'hidden',
        widthMode === 'medium' &&
          'py-px px-0.5 text-app-xxs text-center leading-3',
        widthMode === 'large' && 'inline-block ml-[70px] px-1 mr-2 my-1 py-px',
        className,
      )}
    >
      {/* Aucun participant */}- - - - -
    </div>
  );
};
