import {
  ClubDiverFull,
  ClubMassiveUpdateOperation,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcherSmart } from '@mabadive/app-common-services';
import { apiClient } from 'src/_common-browser';
import { appCacheTTLStoreEntitiesBuilder } from 'src/business/_core/modules/root/app-cache';
import { clubBookingsCacheStore } from 'src/pages/LI-lists/LI-02_bookings/useFetchBookingsWithCache.hook';
import { clubPurchasesCacheStore } from 'src/pages/LI-lists/LI-03_purchase/useFetchPurchasesWithCache.hook';
import { clubPaymentsCacheStore } from 'src/pages/LI-lists/LI-04-payments/useFetchPaymentsWithCache.hook';
import { clubDiverStore } from '../../store-repository';
import { proMultiMutationsApiClient } from './proMultiMutationsApiClient.service';

/**
 * @deprecated use proMultiMutationsApiClient
 */
export const clubMassiveUpdatorClient = {
  update,
  updateMany,
  createEmptyPayload: proMultiMutationsApiClient.createEmptyPayload,
  cleanPayload,
};

async function update(payload: ProMultiOperationPayload): Promise<void> {
  const operation: ClubMassiveUpdateOperation = {
    id: 'club.massive.update',
    payload: cleanPayload(payload),
  };

  await updateLocalStores([payload]);
  // send remote operation
  return await apiClient
    .post<any>('/operations', {
      authenticate: true,
      json: {
        operations: [operation],
      },
    })
    .toPromise()
    .catch((err) => {
      // invalidate cache
      clubDiverStore.clubDiverFullCollection.removeAll();
      throw err;
    });
}

async function updateMany(payloads: ProMultiOperationPayload[]): Promise<void> {
  const operations: ClubMassiveUpdateOperation[] = payloads.map((payload) => ({
    id: 'club.massive.update',
    payload: cleanPayload(payload),
  }));

  await updateLocalStores(payloads);

  // send remote operation
  return await apiClient
    .post<any>('/operations', {
      authenticate: true,
      json: {
        operations,
      },
    })
    .toPromise()
    .catch((err) => {
      // invalidate cache
      clubDiverStore.clubDiverFullCollection.removeAll();
      throw err;
    });
}

async function updateLocalStores(payloads: ProMultiOperationPayload[]) {
  for (const payload of payloads) {
    if (payload.updatedDivers) {
      const idsToUpdate = payload.updatedDivers.map((x) => x.pk);
      clubDiverStore.clubDiverFullCollection.reduceAll((items) => {
        const initial: {
          toUpdate: ClubDiverFull[];
          toKeep: ClubDiverFull[];
        } = {
          toUpdate: [],
          toKeep: [],
        };

        const { toUpdate, toKeep } = items.reduce((acc2, item) => {
          if (idsToUpdate.includes(item._id)) {
            acc2.toUpdate.push(item);
          } else {
            acc2.toKeep.push(item);
          }
          return acc2;
        }, initial);

        for (const updatedDiver of payload.updatedDivers) {
          let item = toUpdate.find((x) => x._id === updatedDiver.pk);
          if (item) {
            item = jsonPatcherSmart.applySmartPatchOperations(item, {
              patchOperations: updatedDiver.patchOperations,
              logPrefix: 'clubMassiveUpdatorClient',
              ignoreErrors: true,
              appLogger: undefined,
            }).output;
            toKeep.push(item);
          }
        }
        return toKeep;
      }, 'clubMassiveUpdatorClient/updatedBookingMembers');
    }
    // if (payload.newDivers) {
    //   clubDiverStore.clubDiverFullCollection.addMany({
    //     actionId: 'clubMassiveUpdatorClient/newDivers',
    //     values: payload.newDivers,
    //   });
    // }
    if (payload.deletedDiversIds) {
      clubDiverStore.clubDiverFullCollection.removeMany({
        identify: (x) => payload.deletedDiversIds.includes(x._id),
        actionId: 'clubMassiveUpdatorClient/deletedDiversIds',
      });
    }
    if (payload.deletedBookingsIds) {
      await appCacheTTLStoreEntitiesBuilder.clearResultsIds(
        clubBookingsCacheStore._cacheStore.__native,
        payload.deletedBookingsIds,
      );
    }
    if (payload.deletedPurchasePaymentIds) {
      await appCacheTTLStoreEntitiesBuilder.clearResultsIds(
        clubPaymentsCacheStore._cacheStore.__native,
        payload.deletedPurchasePaymentIds,
      );
    }
    if (payload.deletedPurchasePackageIds) {
      await appCacheTTLStoreEntitiesBuilder.clearResultsIds(
        clubPurchasesCacheStore._cacheStore.__native,
        payload.deletedPurchasePackageIds,
      );
    }
  }
}

function cleanPayload(
  payload: ProMultiOperationPayload,
): ProMultiOperationPayload {
  // NOTE: 09/05/2023 : c'est une bonne idée, mais ça fait planter le déplacement, la duplication de tarif de formation, etc...
  return payload;

  // // on nettoie le payload pour réduire sa taille et le rendre plus lisible
  // return removeEmptyPayloadItem({
  //   ...payload,
  //   bookingDeposits: removeEmptyPayloadItem(payload.bookingDeposits),
  //   diveCenters: removeEmptyPayloadItem(payload.diveCenters),
  // });
}
function removeEmptyPayloadItem<T>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, v]) => v != null && v?.length !== 0 && Object.keys(v)?.length !== 0,
    ),
  ) as T;
}
