import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useMemo, useRef } from 'react';
import { graphqlClient } from 'src/_common-browser';
import {
  buildPurchasePaymentCollectionGraphqlQuery,
  buildPurchaseResumeCollectionGraphqlQuery,
} from 'src/business/_core/data/store-repository';
import { buildBookingDepositResumeCollectionGraphqlQuery } from 'src/business/_core/data/store-repository/ecommerce/graphql/query-builder/buildBookingDepositResumeCollectionGraphqlQuery.fn';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { reactQueryToLoadableConverter } from '../../../../../../../_core/data/app-loading';
import { diverBookingPageLoadedQueryBuilder } from './diverBookingPageLoadedQueryBuilder.service';
import { DiverBookingPageClubDiverLinkedData } from './useDiverBookingPageClubDiverLinkedData.hook';
import {
  CacheQueryKey,
  QueryResult,
} from './useDiverBookingPageLoadedContent.hook';

export function useDiverBookingPageLoadedContentMain({
  linkedData,
  mainDiverId,
}: {
  linkedData: DiverBookingPageClubDiverLinkedData;
  mainDiverId: string;
}) {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume?.clubReference;

  const queryKey: CacheQueryKey = [
    linkedData?.bookingLoadedIds,
    linkedData?.diverIdsActiveLink,
    clubReference,
    linkedData?.diverIdsAll,
    linkedData?.includeArchivedPurchases,
    mainDiverId,
  ];

  // map of queryKey by mainDiverId:
  const queryKeysMap = useRef(new Map<string, QueryResult>());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startDate = useMemo(() => new Date(), queryKey);

  const query: UseQueryResult<QueryResult, unknown> = useQuery({
    queryKey,
    queryFn: async () => {
      // bookings
      const q1 =
        diverBookingPageLoadedQueryBuilder.buildGraphqlQueryEditionBookingResumes(
          {
            // CHARGEMENT COMPLET on a besoin de tous les members divers pour construire les objets agrégés, comme PRO_BookingParticipantFull
            bookingIds: linkedData?.bookingLoadedIds, // mais ensuite il faut filtrer les bookings avec bookingIdsActive
            returnName: 'bookingResumes',
          },
        );

      // products
      const q2 =
        diverBookingPageLoadedQueryBuilder.buildGraphqlQueryEditionBookingResumeProduct(
          {
            bookingIds: linkedData?.bookingLoadedIds,
            diverIds: linkedData?.diverIdsActiveLink,
            returnName: 'bookingProductsResumes',
          },
        );

      // purchases
      const q3 = buildPurchaseResumeCollectionGraphqlQuery({
        clubReference,
        diversIds: linkedData?.diverIdsAll,
        status: {
          purchasePackages: linkedData?.includeArchivedPurchases
            ? 'all'
            : 'only-active',
        },
        maxResults: 100,
        returnName: 'purchaseResumes',
        type: 'all',
      });

      // booking deposits
      const q4 = buildBookingDepositResumeCollectionGraphqlQuery({
        clubReference,
        diversIds: linkedData?.diverIdsAll,
        status: {
          purchasePackages: linkedData?.includeArchivedPurchases
            ? 'all'
            : 'only-active',
        },
        maxResults: 100,
        returnName: 'bookingDepositsResumes',
        type: 'all',
      });

      // payments
      const q5 = buildPurchasePaymentCollectionGraphqlQuery({
        diversIds: linkedData?.diverIdsAll,
        returnName: 'purchasePayments',
      });

      const graphqlQuery$ = graphqlClient.query.runMany<QueryResult>(
        [q1, q2, q3, q4, q5],
        {
          type: 'query',
          name: 'useLoadMain',
        },
      );

      const result: QueryResult = await graphqlQuery$?.toPromise();
      queryKeysMap.current.set(mainDiverId, result);
      return result;
    },
    enabled: !!linkedData?.bookingLoadedIds,
    gcTime: 1000 * 60 * 60 * 24 * 2, // on garde ça en cache 2 jours
    staleTime: 0, // mais au chargement, on rafraichi systématiquement les données
    refetchOnWindowFocus: true, // ainsi que si la fenêtre reprend le focus
    refetchInterval: 5 * 60 * 1000, // et toutes les 5 minutes
    placeholderData: queryKeysMap.current.get(mainDiverId),
    retry: 60, // en cas d'erreur, on ré-essaie 60 fois, chaque 2 secondes
    retryDelay: 1000 * 2,
  });

  const loadableContentMulti =
    reactQueryToLoadableConverter.convertReactQueryToLoadableContent({
      query,
      startDate,
    });
  return loadableContentMulti;
}
