import { AppUserTrackingClient } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { of, throwError } from 'rxjs';
import { catchError, delay, retry, switchMap } from 'rxjs/operators';
import { AppAuth } from 'src/business/auth/services';
import { appLogger } from '../../modules/root/logger';
import { clubStore } from '../../store';
import { adminBaseDataLoader } from './AdminBaseData';
import { AppDataLoaderFetchMethod } from './AppDataLoaderFetchMethod.type';
import { clubBaseDataLoader } from './ClubBaseData';

export const appDataLoader = {
  loadAppInitialData,
  clear,
};

export interface BootstrapOptions {
  initialFetchMethod: AppDataLoaderFetchMethod;
  clubReference: string;
  userId: string;
  trackingClient: AppUserTrackingClient;
}

function clear() {
  // clear data stores
  clubBaseDataLoader.clear();
  adminBaseDataLoader.clear();
}

function loadAppInitialData({
  appAuth,
  appClientId,
  options,
}: {
  appAuth: AppAuth;
  appClientId: string;
  options: BootstrapOptions;
}) {
  return loadAndSyncData({
    appAuth,
    appClientId,
    options,
  }).pipe(
    catchError((err) => {
      appLogger.warn('[appDataLoader] error loadAndSync', err);
      return of(undefined).pipe(
        delay(10000),
        switchMap(() => throwError(err)),
      );
    }),
    retry(100),
  );
}

function loadAndSyncData({
  appAuth,
  appClientId,
  options,
}: {
  appAuth: AppAuth;
  appClientId: string;
  options: BootstrapOptions;
}) {
  if (appAuth.user?.roles.includes('club')) {
    const initialDataLength =
      options.clubReference ===
      'torra-plongee-belvedere-campomoro-corse-du-sud-france-3108'
        ? 4
        : 52;

    clubStore.loadingParams.set({
      isInitialLoading: true,
      minDate: dateService.buildDateWeeksAgoFromBeginOfWeek({
        base: new Date(),
        weeksAgo: initialDataLength,
      }),
    });
    return clubBaseDataLoader.loadClubInitialData({ options });
  } else if (appAuth.user?.roles.includes('super-admin')) {
    return adminBaseDataLoader.loadAdminInitialData({ options, appClientId });
  } else {
    // no data to load
    return of(undefined);
  }
}
