/* eslint-disable @typescript-eslint/no-unused-vars */
import { InputAdornment, makeStyles, Theme } from '@material-ui/core';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import React, { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useCacheableClasses } from 'src/business/_core/modules/root/theme';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export type AppSingleAutocomplete2Props<T> = {
  options: ValueLabel<T>[];
  value?: T;
  optionClassName?: string;
  onChange?: (value: T) => void;
  disableClearable?: boolean;
  customRenderOption?: (
    option: ValueLabel<T>,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
} & Partial<Omit<TextFieldProps, 'onChange'>>;

export function AppSingleAutocomplete2<
  T,
  L extends string | React.ReactNode = string,
>({
  options,
  value,
  onChange,
  disableClearable,
  customRenderOption,
  optionClassName = '',
  className,
  ...extraTextFieldProps
}: AppSingleAutocomplete2Props<T>) {
  function select(valueLabel: ValueLabel<T>) {
    if (onChange) {
      const value = valueLabel ? valueLabel.value : undefined;
      onChange(value ?? null);
    }
  }

  function clear() {
    onChange(null);
  }

  const classes = useCacheableClasses({
    cacheKey: 'RxjsFormAppAutocomplete',
    buildStyles,
  });

  const selectedOption = useMemo(() => {
    return getSelectedOption({
      options,
      value,
    });
  }, [options, value]);

  return (
    <Autocomplete
      className={className}
      disabled={extraTextFieldProps.disabled}
      multiple={false}
      options={options}
      value={selectedOption}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      disableClearable={true}
      getOptionLabel={(option) => (option ? option.label : '')}
      renderOption={
        customRenderOption
          ? customRenderOption
          : (selected: ValueLabel<T>) => {
              if (selected) {
                return (
                  <div className={optionClassName}>
                    <span>{selected.label}</span>
                  </div>
                );
              }
            }
      }
      noOptionsText="Aucun résultat"
      onChange={(event: any, valueLabel: string | ValueLabel<T>) =>
        select(valueLabel as unknown as ValueLabel<T>)
      }
      renderInput={(params) => {
        params.InputProps.startAdornment = (
          <InputAdornment position="start">
            <AppHeroIcons.actionCancel2
              className={`${
                value !== null && value !== undefined && !disableClearable
                  ? 'flex'
                  : 'hidden'
              } h-6 w-6 cursor-pointer text-gray-300 hover:text-gray-600`}
              // className={`${classes.clearIcon} ${value ? 'active' : ''}`}
              onClick={() => {
                clear();
              }}
            />
          </InputAdornment>
        );
        return (
          <TextField
            {...params}
            {...extraTextFieldProps}
            variant="standard"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        );
      }}
    />
  );
}

function getSelectedOption<T>({
  options,
  value,
}: {
  options: ValueLabel<T>[];
  value: T;
}) {
  if (options && value !== null && value !== undefined) {
    const selectedOption = options.find((o) => o.value === value);
    if (selectedOption) {
      return selectedOption;
    }
  }
  return null;
}
function buildStyles(theme: Theme) {
  return makeStyles({
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  });
}
