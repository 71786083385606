/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  ClubDiverSimilarSearchCandidatesState,
  ClubDiverSimilarSearchEntry,
  useClubDiverSimilarSearchCandidatesOptions,
} from 'src/business/club/modules/club-diver-participant/ClubDiverSimilarSearch';
import { AppFormControlV2 } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { AppSingleSelect2HeadlessUIOptionContent } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIOptionContent';

export const ClubDiverSimilarSearchCandidateFormOptions = ({
  similarSearchCandidatesState,
  open,
  className = '',
}: {
  similarSearchCandidatesState: ClubDiverSimilarSearchCandidatesState;
  open: (selectedCandidate: ClubDiverSimilarSearchEntry) => void;
  className?: string;
}) => {
  const { possibleDuplicated, hideCandidates, setHideCandidates } =
    similarSearchCandidatesState;

  const possibleDuplicatedOptions: ValueLabel<string, React.ReactNode>[] =
    useClubDiverSimilarSearchCandidatesOptions(possibleDuplicated);

  const firstCandidateDiverId = possibleDuplicated?.[0]?.candidate?.diver?._id;
  const [selectedDiverId, setSelectedDiverId] = useState<string>(
    firstCandidateDiverId, // select first candidate by default
  );
  useEffect(() => {
    if (!selectedDiverId) {
      setSelectedDiverId(firstCandidateDiverId);
    }
  }, [firstCandidateDiverId, selectedDiverId]);

  const openCandidateByDiverId = useCallback(
    (diverId: string) => {
      const selectedCandidateWithScore = possibleDuplicated.find(
        (c) => c.candidate.diver._id === diverId,
      );
      if (selectedCandidateWithScore) {
        open(selectedCandidateWithScore.candidate);
      }
    },
    [open, possibleDuplicated],
  );

  return possibleDuplicatedOptions?.length > 0 && !hideCandidates ? (
    <div className={clsx('w-full flex gap-4 items-end flex-wrap', className)}>
      <AppFormControlV2
        label="Contact existant ?"
        theme={'warn'}
        helpDescription="Vérifiez que le contact n'existe pas déjà avant de créer une nouvelle fiche!"
        renderComponent={() =>
          possibleDuplicatedOptions?.length === 1 ? (
            <div className="font-bold uppercase cursor-pointer select-none relative py-2 pr-4 text-gray-500">
              <AppSingleSelect2HeadlessUIOptionContent
                className="w-full"
                option={possibleDuplicatedOptions?.[0]}
                selected={false}
                active={true}
                color={'gray'}
              />
            </div>
          ) : (
            <AppSingleSelect2HeadlessUI
              className={'w-[300px]'}
              theme="material-ui"
              color="theme"
              value={selectedDiverId}
              options={possibleDuplicatedOptions}
              disableClearButton={true}
              onChange={(diverId) => {
                setSelectedDiverId(diverId);
                openCandidateByDiverId(diverId);
              }}
            />
          )
        }
      />
      <div className={clsx('flex gap-4')}>
        <AppButton
          className={!selectedDiverId ? 'invisible' : ''}
          color={'primary-outline-light'}
          size="normal"
          icon={AppIconsAction.open}
          onClick={() => {
            openCandidateByDiverId(selectedDiverId);
          }}
        >
          Ouvrir
        </AppButton>
        <AppButton
          color={'gray-outline-light'}
          size="normal"
          icon={AppIconsAction.close}
          onClick={() => {
            setHideCandidates(true);
          }}
        >
          Ignorer
        </AppButton>
      </div>
    </div>
  ) : null;
};
