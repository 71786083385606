/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  appLoader,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import { clubSettingsPaymentPlatformListPageGraphqlFetcher } from './services';

export function useClubSettingsPaymentPlatformListPageLocalState() {
  const diveCenterResume = useDiveCenterResume();

  const { content: onlinePlatforms, ...loadableContent } = useLoadableContent(
    () => {
      return appLoader.load(
        clubSettingsPaymentPlatformListPageGraphqlFetcher.fetchAll({
          clubReference: diveCenterResume.clubReference,
        }),
        {
          type: 'full',
          defaultValue: undefined,
          isSubscription: true,
        },
      );
    },
    [diveCenterResume.clubReference],
    {
      initialValue: undefined,
      defaultValue: undefined,
      useSnapshot: false,
      debugName: 'useClubSettingsUserAccountsListPageLocalState',
    },
  );

  return {
    diveCenterResume,
    onlinePlatforms,
    loadableContent,
  };
}
