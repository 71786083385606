import {
  ClubDiver,
  ClubPurchasePackage,
  ClubPurchasePayment,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { DiverPurchasePaymentEditorPackageInitialModel } from './DiverPurchasePaymentEditorPackageInitialModel.type';
export function useInitialPurchasePaymentPackages(
  mode: string,
  purchasePayment: ClubPurchasePayment,
  divers: ClubDiver[],
  purchasePackages: ClubPurchasePackage[],
) {
  const initialPurchasePaymentPackages: DiverPurchasePaymentEditorPackageInitialModel[] =
    useMemo(() => {
      if (mode === 'edit') {
        return (purchasePayment?.purchasePaymentPackages ?? [])
          .map((ppp) => {
            const diver = divers.find(
              (d) => d._id === ppp.purchasePackage.diverId,
            );
            const purchasePackage = purchasePackages.find(
              (upp) => upp._id === ppp.purchasePackage._id,
            );
            if (purchasePackage) {
              const uppp: DiverPurchasePaymentEditorPackageInitialModel = {
                initialAmountForPackage: ppp.amount,
                updatedAmountForPackage: ppp.amount,
                initialPurchasePackage: purchasePackage,
                updatedPurchasePackage: {
                  ...purchasePackage,
                },
                diver,
              };
              return uppp;
            }
          })
          .filter((x) => !!x);
      } else {
        // create
        return purchasePackages
          .filter((pp) => pp.purchasePaymentStatus !== 'done' && pp.price > 0)
          .map((purchasePackage) => {
            const diver = divers.find((d) => d._id === purchasePackage.diverId);
            const { price, payedAmount } = purchasePackage;

            const remainingAmount = Math.max(0, price - payedAmount);

            const uppp: DiverPurchasePaymentEditorPackageInitialModel = {
              diver,
              initialAmountForPackage: 0,
              updatedAmountForPackage: remainingAmount,
              initialPurchasePackage: purchasePackage,
              updatedPurchasePackage: {
                ...purchasePackage,
                payedAmount: payedAmount + remainingAmount,
                purchasePaymentStatus:
                  remainingAmount > 0
                    ? 'done'
                    : purchasePackage.purchasePaymentStatus,
              },
            };
            return uppp;
          });
      }
    }, [
      divers,
      mode,
      purchasePackages,
      purchasePayment?.purchasePaymentPackages,
    ]);

  const purchasePaymentPackages = useMemo(
    () =>
      dataSorter.sortMultiple(initialPurchasePaymentPackages, {
        getSortAttributes: (x) => {
          return [
            {
              value: x.diver.birthdate,
              type: 'default',
            },
            {
              value: x.diver.lastName,
              type: 'full-text',
            },
            {
              value: x.diver.firstName,
              type: 'full-text',
            },
            {
              value: x.diver._id,
              type: 'default',
            },
            {
              value: x.updatedPurchasePackage.purchaseDate,
              type: 'default',
            },
            {
              value: x.updatedPurchasePackage._id,
              type: 'default',
            },
          ];
        },
        asc: true,
      }),
    [initialPurchasePaymentPackages],
  );
  return purchasePaymentPackages;
}
