import { DiveMode } from '@mabadive/app-common-model';
import {
  diveModeFormatter,
  DiveModeStringFormat,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useClubSettings } from '../../../business/club/data/hooks';
import { useDiveModes } from './useDiveModes.hook';

export function useDiveModesOptions({
  currentDiveMode,
  format,
  type,
  diveModes: diveModesInput,
}: {
  currentDiveMode?: string;
  format: DiveModeStringFormat;
  type?: 'all' | 'all-included-disabled' | 'autosupervised';
  diveModes?: DiveMode[];
}): ValueLabel<DiveMode, string>[] {
  const diveModesBuilt = useDiveModes({
    currentDiveMode,
    type,
  });
  const clubSettings = useClubSettings();
  return useMemo(
    () =>
      (diveModesInput ?? diveModesBuilt).map((diveMode) => ({
        value: diveMode,
        label: diveModeFormatter.formatDiveMode(diveMode, {
          format,
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
      })),
    [clubSettings?.ui?.diveMode, diveModesBuilt, diveModesInput, format],
  );
}
