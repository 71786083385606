import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PurchaseResumeCollectionCriteria } from 'src/business/_core/data/store-repository';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { graphqlClient, MQueryDescription } from 'src/_common-browser';
import { PurchaseListPagePurchaseFetchedModel } from '../model';
import { purchasesListPageGraphqlQueryBuilder } from './purchasesListPageGraphqlQuery.fn';

export const purchasesListPageGraphqlFetcher = {
  findMany,
};

function findMany(
  criteria: PurchaseResumeCollectionCriteria,
  {
    type,
    name = 'bookingPurchaseResume',
  }: {
    type: 'subscription' | 'query';
    name?: string;
  },
): Observable<PurchaseListPagePurchaseFetchedModel[]> {
  const query: MQueryDescription<any> =
    purchasesListPageGraphqlQueryBuilder.buildQuery(criteria);

  const graphqlQuery$ = graphqlClient.query.runOne<
    PurchaseListPagePurchaseFetchedModel[]
  >(query, {
    type,
    name,
  });

  return graphqlQuery$.pipe(
    tap((res) => {
      appLogger.info(
        '[purchasesListPageGraphqlFetcher.findMany] purchaseResumes:',
        res.length,
      );
    }),

    catchError((err) => {
      appLogger.warn(
        '[purchasesListPageGraphqlFetcher] error fetching data',
        err,
      );
      return throwError(err);
    }),
  );
}
