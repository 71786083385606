/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppExpansionPanel } from '../../../../../business/_core/modules/layout';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm_Booking = ({
  localState,
  className,
}: {
  localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
  className?: string;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const clubResume = useClubResume();

  const [
    bookingExternalRef,
    bookingExternalRefLabel,
    bookingCustomField1,
    bookingCustomField2,
    bookingCustomField3,
  ] = useWatch({
    control,
    name: [
      'clubSettings.customer.booking.externalRef',
      'clubSettings.customer.booking.externalRefLabel',
      'clubSettings.customer.booking.customField1',
      'clubSettings.customer.booking.customField2',
      'clubSettings.customer.booking.customField3',
    ],
  });
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <AppExpansionPanel
      className={className}
      isExpandable={true}
      isExpanded={isExpanded}
      expandPanel={() => {
        return () => setIsExpanded(!isExpanded);
      }}
      panelId="archived-purchases"
      renderSummaryTitle={() => (
        <div className="flex justify-between items-center">
          <h2 className="text-left uppercase text-lg leading-6 font-medium text-gray-800">
            Réservation
          </h2>
          {!isExpanded && (
            <span className="hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR CONFIGURER
            </span>
          )}
        </div>
      )}
      renderSummaryExtra={() => null}
      renderDetails={() => (
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-4 flex-wrap">
            <AppFormControlRHF
              control={control}
              name="clubSettings.customer.booking.externalRef"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  description={'ID perso'}
                  onChange={(value) => {
                    if (value && !bookingExternalRefLabel?.trim()?.length) {
                      setValue(
                        'clubSettings.customer.booking.externalRefLabel',
                        'N° résa',
                      );
                    }
                  }}
                />
              )}
            />
            {bookingExternalRef && (
              <AppFormControlRHF
                className="max-x-40"
                control={control}
                name="clubSettings.customer.booking.externalRefLabel"
                label="Nom du champ"
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
            )}
          </div>
          <AppFormControlRHF
            control={control}
            name="clubSettings.customer.booking.agency"
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                description={'Agence'}
              />
            )}
          />
          <div className="flex gap-4 flex-wrap">
            <AppFormControlRHF
              control={control}
              name="clubSettings.customer.booking.customField1"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  description={'Champ personnalisé N°1'}
                />
              )}
            />
            {bookingCustomField1 && (
              <AppFormControlRHF
                className="max-x-40"
                control={control}
                name="clubSettings.customer.booking.customField1Label"
                label="Nom du champ"
                required={true}
                renderComponent={(props) => <AppInputRHF {...props} />}
              />
            )}
          </div>
          {(bookingCustomField1 || bookingCustomField2) && (
            <div className="flex gap-4 flex-wrap">
              <AppFormControlRHF
                control={control}
                name="clubSettings.customer.booking.customField2"
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    description={'Champ personnalisé N°2'}
                  />
                )}
              />
              {bookingCustomField2 && (
                <AppFormControlRHF
                  className="max-x-40"
                  control={control}
                  name="clubSettings.customer.booking.customField2Label"
                  label="Nom du champ"
                  required={true}
                  renderComponent={(props) => <AppInputRHF {...props} />}
                />
              )}
            </div>
          )}
          {((bookingCustomField1 && bookingCustomField2) ||
            bookingCustomField3) && (
            <div className="flex gap-4 flex-wrap">
              <AppFormControlRHF
                control={control}
                name="clubSettings.customer.booking.customField3"
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    description={'Champ personnalisé N°3'}
                  />
                )}
              />
              {bookingCustomField3 && (
                <AppFormControlRHF
                  className="max-x-40"
                  control={control}
                  name="clubSettings.customer.booking.customField3Label"
                  label="Nom du champ"
                  required={true}
                  renderComponent={(props) => <AppInputRHF {...props} />}
                />
              )}
            </div>
          )}
        </div>
      )}
    />
  );
};
