import React from 'react';
import { DiveSessionParticipantsStats } from 'src/business/club/modules/club-dive-session/model';

export const DiveSessionSimpleCardTotalCounters = ({
  diveSessionParticipantsStats: stats,
  className,
}: {
  diveSessionParticipantsStats: DiveSessionParticipantsStats;
  className?: string;
}) => {
  return (
    <div
      className={` relative whitespace-nowrap flex justify-around items-center gap-1 ${className}`}
    >
      <span
        className={`${stats.total === 0 ? 'text-gray-400' : 'text-app-orange'}`}
      >
        {stats.total}
      </span>
      {stats.supervisorsAll > 0 ? (
        <span className="text-gray-700">+ {stats.supervisorsAll}</span>
      ) : null}
    </div>
  );
};
