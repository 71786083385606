import {
  AppEntityUpdatePatch,
  ClubResumeStaffMember,
  ClubStaffMemberDailyAvailabilityPeriod,
  DiveCenterDailyConfig,
  DiveCenterDailyConfigStaffMember,
  DiveSession,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  commonDiveSessionReferenceParser,
  commonEntityBuilder,
  jsonParser,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { UseFormReturn } from 'react-hook-form';
import { sessionStaffConfigRoleUpdateBuilder } from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab2StaffMembers/sessionStaffConfigRoleUpdateBuilder.service';
import { DiveSessionStaffRoleModel } from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components';
import { DiveSessionEditorFormModel } from '../../../club-diver-participant/pages/DiverBookingPage/forms/DiveSessionEditForm/model';
import { ClubPlanningStaffPageCacheUpdateState } from '../ClubPlanningStaffWeekStaffPresence/_model';

export const planningStaffWeekPresenceCacheUpdator = {
  updateStaffMemberPresence,
  updateSessionStaffConfig,
};

export type PlanningStaffWeekPresenceUpdateState = Pick<
  ClubPlanningStaffPageCacheUpdateState,
  'hasChanges' | 'dailyConfigsChanges' | 'diveSessionsChanges'
>;

function updateSessionStaffConfig<
  UpdateState extends PlanningStaffWeekPresenceUpdateState,
>({
  updateState,
  diveSession,
  staffMember,
  availabilityPeriod,
  isAvailableOnSession,
  toogleRole,
  form,
  dailyConfigs,
  setUpdateState,
}: {
  updateState: UpdateState;
  diveSession: DiveSession;
  staffMember: ClubResumeStaffMember;
  availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod;
  isAvailableOnSession: boolean;
  toogleRole: Pick<DiveSessionStaffRoleModel, 'assigned' | 'staffRoleRef'>;
  form: UseFormReturn<DiveSessionEditorFormModel, any, undefined>;
  dailyConfigs: DiveCenterDailyConfig[];
  setUpdateState: (updateState: UpdateState) => void;
}) {
  let localUpdateState = {
    ...updateState,
  };
  const { diveSessionsPatchOperations, createdDiveSession } =
    sessionStaffConfigRoleUpdateBuilder.buildPatchOperationsCreateOrUpdateSession(
      {
        diveSession,
        staffMember,
        isAvailableOnSession,
        toogleRole,
        form,
      },
    );

  if (diveSessionsPatchOperations.length) {
    localUpdateState.diveSessionsChanges = changeDescriptorManager.updateOne(
      {
        pk: diveSession._id,
        patchOperations: diveSessionsPatchOperations,
      },
      {
        changeDescriptors: localUpdateState.diveSessionsChanges,
      },
    );
  }

  if (createdDiveSession) {
    localUpdateState.diveSessionsChanges = changeDescriptorManager.createOne(
      createdDiveSession,
      {
        changeDescriptors: localUpdateState.diveSessionsChanges,
      },
    );
  }
  // TODO
  const date = commonDiveSessionReferenceParser.parseDayReference(
    diveSession.dayReference,
  );
  const dailyConfig = dailyConfigs.find(
    (x) => x.dateReference === diveSession.dayReference,
  );
  localUpdateState =
    planningStaffWeekPresenceCacheUpdator.updateStaffMemberPresence(
      {
        availabilityPeriod,
        date,
        dayReference: diveSession.dayReference,
        staffMember,
        dailyConfig,
      },
      {
        clubReference: diveSession.clubReference,
        diveCenterId: diveSession.diveCenterId,
        updateState: localUpdateState,
      },
    );

  localUpdateState.hasChanges = true;
  setUpdateState(localUpdateState);
}

function updateStaffMemberPresence<
  UpdateState extends PlanningStaffWeekPresenceUpdateState,
>(
  {
    staffMember,
    availabilityPeriod,
    dayReference,
    date,
    dailyConfig,
  }: {
    staffMember: ClubResumeStaffMember;
    availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod;
    dayReference: string;
    date: Date;
    dailyConfig?: DiveCenterDailyConfig;
  },
  context: {
    updateState: UpdateState;
    clubReference: string;
    diveCenterId: string;
  },
): UpdateState {
  const {
    updateState: updateStateInput,
    clubReference,
    diveCenterId,
  } = context;

  const updateStateLocal: UpdateState = jsonParser.parseJSONWithDates(
    JSON.stringify(updateStateInput),
  );

  const existingDailyConfig: DiveCenterDailyConfig = dailyConfig
    ? jsonParser.parseJSONWithDates(JSON.stringify(dailyConfig))
    : undefined;
  //  ??
  // createdDailyConfigsCache.find((x) => x.dateReference === dayReference);
  if (!existingDailyConfig) {
    const staffMemberConfig: DiveCenterDailyConfigStaffMember = {
      staffMemberId: staffMember._id,
      availabilityPeriod,
      // comment,
    };
    const newDailyConfig: DiveCenterDailyConfig =
      commonEntityBuilder.buildEntity<DiveCenterDailyConfig>({
        clubReference,
        diveCenterId,
        date,
        dateReference: dayReference,
        staffMembers: [staffMemberConfig],
      });
    // store in cache
    // setCreatedDailyConfigsCache([
    //   ...createdDailyConfigsCache,
    //   newDailyConfig,
    // ]);
    // const operation: ProMultiOperationPayload = {
    //   diveCenterDailyConfigs: {
    //     created: [newDailyConfig],
    //   },
    // };

    updateStateLocal.hasChanges = true;
    updateStateLocal.dailyConfigsChanges = changeDescriptorManager.createOne(
      newDailyConfig,
      {
        changeDescriptors: updateStateLocal.dailyConfigsChanges,
      },
    );
    // TODO: utiliser le cache pour mettre à jour l'affichage immédiatement
    // persist
    // await clubMassiveUpdatorClient.update(operation);
    // setCache({ pendingOperations: [...cache.pendingOperations, operation] });
  } else {
    const updatedStaffMembers: DiveCenterDailyConfigStaffMember[] =
      existingDailyConfig.staffMembers.find(
        (x) => x.staffMemberId === staffMember._id,
      ) !== undefined
        ? // update
          existingDailyConfig.staffMembers.map((x) => {
            if (x.staffMemberId === staffMember._id) {
              return {
                ...x,
                availabilityPeriod,
              };
            }
            return x;
          })
        : // append
          existingDailyConfig.staffMembers.concat([
            {
              staffMemberId: staffMember._id,
              availabilityPeriod,
              // comment,
            },
          ]);
    const patchOperations = jsonPatcher.compareObjects(
      existingDailyConfig,
      {
        ...existingDailyConfig,
        staffMembers: updatedStaffMembers,
      },
      {
        // else, value won't be deleted by typeorm
        // https://github.com/typeorm/typeorm/issues/2934
        replaceDeleteByNullValue: false,
        attributesToReplaceFully: ['staffMembers'],
      },
    );
    if (patchOperations.length) {
      const patch: AppEntityUpdatePatch = {
        pk: existingDailyConfig._id,
        patchOperations,
      };

      updateStateLocal.hasChanges = true;
      updateStateLocal.dailyConfigsChanges = changeDescriptorManager.updateOne(
        patch,
        {
          changeDescriptors: updateStateLocal.dailyConfigsChanges,
        },
      );
      //   const operation: ProMultiOperationPayload
      //     diveCenterDailyConfigs: {
      //       updated: [patch],
      //     },
      //   };
      // await clubMassiveUpdatorClient.update(operation);
      //   setCache({
      //     pendingOperations: [...cache.pendingOperations, operation],
      //   });
    }
  }
  return updateStateLocal;
}
