/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CompanyAccommodation,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useGlobalClasses } from 'src/AppTheme';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButtonsBar,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import useRouter from 'use-react-router';
import { clubSettingsOtherUrlBuilder } from '../../clubSettingsOtherUrlBuilder.service';
import { accommodationFormResultBuilder } from './accommodationFormResultBuilder.service';
import { CompanyAccommodationEditForm } from './form';
import {
  CompanyAccomodationEditPageLocalState,
  useAccomodationsEditPageLocalState,
} from './useAccomodationsEditPageLocalState.hook';

export const CompanyAccomodationEditPage = ({
  navigationContext: { mode },
}: {
  navigationContext: { mode: 'create' | 'edit' };
}) => {
  const { match, history } =
    useRouter<{
      accommodationId: string;
    }>();

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const params = new URLSearchParams(window.location.search);

  const accommodationId = match.params.accommodationId;

  const localState: CompanyAccomodationEditPageLocalState =
    useAccomodationsEditPageLocalState({
      mode,
      accommodationId,
    });

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const { accommodation, form, initialFormValue } = localState;
  const { handleSubmit } = form;

  const back = useCallback(() => {
    return redirectTo(clubSettingsOtherUrlBuilder.accommodations.list());
  }, [redirectTo]);

  useMemo(() => {
    if (mode === 'edit' && !accommodation) {
      appLogger.warn('Invalid accommodationId: redirect');
      back();
    }
  }, [back, mode, accommodation]);

  const persistChanges = useCallback(() => {
    handleSubmit((formValue: CompanyAccommodation, event) => {
      if (mode === 'edit') {
        const value = accommodationFormResultBuilder.buildResult(formValue);
        const patchOperations = jsonPatcher.compareObjects(
          initialFormValue,
          value,
          {
            attributesToReplaceFully: [],
            replaceDeleteByNullValue: true,
          },
        );
        if (patchOperations.length) {
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'edit accommodation',
              bookingIdsToClean: [],
            });
          payload.companyAccommodations = {
            updated: [
              {
                pk: accommodation?._id,
                patchOperations,
              },
            ],
          };
          clubMassiveUpdatorClient.update(payload).then(() => {
            back();
          });
        } else {
          back();
        }
      } else if (mode === 'create') {
        const value = accommodationFormResultBuilder.buildResult(formValue);
        const payload: ProMultiOperationPayload =
          clubMassiveUpdatorClient.createEmptyPayload({
            logContext: 'create accommodation',
            bookingIdsToClean: [],
          });
        payload.companyAccommodations = {
          created: [
            commonEntityBuilder.buildEntity<CompanyAccommodation>({
              ...value,
              clubReference,
              diveCenterId,
            }),
          ],
        };
        clubMassiveUpdatorClient.update(payload).then(() => {
          back();
        });
      }
    })();
  }, [
    handleSubmit,
    mode,
    initialFormValue,
    accommodation?._id,
    back,
    clubReference,
    diveCenterId,
  ]);

  const { control } = form;
  const [name, pickupEnabled] = useWatch({
    control,
    name: ['name', 'pickup.enabled'],
  });

  return (
    <form
      className="w-full"
      onSubmit={(e) => {
        persistChanges();
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-gray-50"
        footerBar={() => (
          <AppButtonsBar className="" hasChanges={form.formState.isDirty}>
            <>
              <AppButton
                className="my-2 w-full uppercase"
                fullWidth={true}
                color={'primary-outline-light'}
                size="normal"
                icon={AppHeroIcons.actionCancel}
                onClick={() => back()}
              >
                Annuler
              </AppButton>
              <AppButton
                type="submit"
                className="my-2 w-full uppercase"
                fullWidth={true}
                color={'primary-outline-light'}
                size="normal"
                icon={AppHeroIcons.actionSave}
              >
                Enregistrer
              </AppButton>
            </>
          </AppButtonsBar>
        )}
      >
        <AppPageContentContainer className="app-p-content bg-gray-50">
          <CompanyAccommodationEditForm localState={localState} />
          {pickupEnabled && (
            <AppMessage
              className="mt-5"
              type="info"
              title="PICKUP"
              message={
                <>
                  <div>
                    L'heure de pickup est calculée automatiquement à partir de
                    l'heure de la session, et du délai configuré ici.
                  </div>
                  <div className="mt-2">
                    Exemple: session à 8h, et délai de 60mn, le pickup est
                    planifié pour 7h.
                  </div>
                </>
              }
            />
          )}
        </AppPageContentContainer>
      </AppPageContainerWithFixedBars>
    </form>
  );
};
