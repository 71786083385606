/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubSettings } from '@mabadive/app-common-model';
import clsx from 'clsx';
import { default as React } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';

export type ClubSettingsForm_LegalInformation_FormType = {
  clubSettings: Pick<ClubSettings, '_id' | 'publicData' | 'publicSettings'>;
};

export const ClubSettingsForm_LegalInformation = <
  FormModel extends ClubSettingsForm_LegalInformation_FormType,
>({
  form: formInput,
  className = '',
}: {
  form: UseFormReturn<FormModel>;
  className?: string;
}) => {
  const form =
    formInput as unknown as UseFormReturn<ClubSettingsForm_LegalInformation_FormType>;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();

  const [legalInfoURL1, legalInfoURL2, isIndividualContractor] = useWatch({
    control,
    name: [
      'clubSettings.publicData.legalInformation.legalInfoURL1',
      'clubSettings.publicData.legalInformation.legalInfoURL2',
      'clubSettings.publicData.legalInformation.isIndividualContractor',
    ],
  });

  return (
    <div className={clsx('grid gap-2', className)}>
      <h4 className="font-bold text-app-secondary uppercase">
        Informations légales
      </h4>

      {isSuperAdmin && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
          <AppFormControlRHF
            label={"Fiche d'information de l'entreprise N°1"}
            helpDescription="Fiche de l'entreprise qui regroupe les informations détaillées"
            control={control}
            name="clubSettings.publicData.legalInformation.legalInfoURL1"
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF
            label={"Fiche d'information de l'entreprise N°2"}
            helpDescription="Fiche de l'entreprise qui regroupe les informations détaillées"
            control={control}
            name="clubSettings.publicData.legalInformation.legalInfoURL2"
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
        </div>
      )}
      {isSuperAdmin && (
        <div>
          Liens utiles:
          <ul className="list-disc list-inside">
            {legalInfoURL1?.trim() && (
              <li>
                <a
                  className="underline text-app-primary"
                  href={legalInfoURL1}
                  target="_blank"
                  rel="noreferrer"
                >
                  {legalInfoURL1}
                </a>
              </li>
            )}
            {legalInfoURL2?.trim() && (
              <li>
                <a
                  className="underline text-app-primary"
                  href={legalInfoURL2}
                  target="_blank"
                  rel="noreferrer"
                >
                  {legalInfoURL2}
                </a>
              </li>
            )}
            <li>
              <a
                className="underline text-app-primary"
                href="https://annuaire-entreprises.data.gouv.fr/rechercher"
                target="_blank"
                rel="noreferrer"
              >
                France: annuaire des entreprises
              </a>
            </li>
            <li>
              <a
                className="underline text-app-primary"
                href="https://data.inpi.fr"
                target="_blank"
                rel="noreferrer"
              >
                France: https://data.inpi.fr
              </a>
            </li>
            <li>
              <a
                className="underline text-app-primary"
                href="https://www.rmc.es"
                target="_blank"
                rel="noreferrer"
              >
                Espagne:
                https://www.rmc.es/estadoDenominaciones/EstadoDenominaciones.aspx
              </a>
            </li>
            <li>
              <a
                className="underline text-app-primary"
                href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation"
                target="_blank"
                rel="noreferrer"
              >
                Europe: vérifier un numéro de TVA (système VIES)
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2">
        <AppFormControlRHF
          label={'Forme juridique'}
          control={control}
          name="clubSettings.publicData.legalInformation.legalForm"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          label={'Entrepreneur individuel?'}
          control={control}
          name="clubSettings.publicData.legalInformation.isIndividualContractor"
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        {isIndividualContractor ? (
          <>
            <AppFormControlRHF
              key={
                'clubSettings.publicData.legalInformation.individualContractorDetails.lastName'
              }
              label={'NOM'}
              control={control}
              name="clubSettings.publicData.legalInformation.individualContractorDetails.lastName"
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
            <AppFormControlRHF
              key={
                'clubSettings.publicData.legalInformation.individualContractorDetails.firstName'
              }
              label={'Prénom'}
              control={control}
              name="clubSettings.publicData.legalInformation.individualContractorDetails.firstName"
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
          </>
        ) : (
          <>
            <AppFormControlRHF
              key={
                'clubSettings.publicData.legalInformation.individualContractorDetails.legalName'
              }
              label={'Dénomination sociale'}
              control={control}
              name="clubSettings.publicData.legalInformation.companyDetails.legalName"
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
            <AppFormControlRHF
              key={
                'clubSettings.publicData.legalInformation.individualContractorDetails.shareCapitalAmount'
              }
              label={'Montant du capital social'}
              control={control}
              name="clubSettings.publicData.legalInformation.companyDetails.shareCapitalAmount"
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
          </>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2">
        <AppFormControlRHF
          label={'N° de SIRET ou CIF/NIF'}
          control={control}
          name="clubSettings.publicData.legalInformation.companySiretNumber"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          label={"N° d'immatriculation au RCS"}
          control={control}
          name="clubSettings.publicData.legalInformation.companyRcsNumber"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          label={'N° de TVA'}
          control={control}
          name="clubSettings.publicData.legalInformation.companyVatNumber"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          label={'Code activité NAF/APE'}
          control={control}
          name="clubSettings.publicData.legalInformation.activityCategoryCode"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>
    </div>
  );
};
