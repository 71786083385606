import {
  dateService,
  HASURA_DATE_TIME_WITH_MS_FORMAT,
} from '@mabadive/app-common-services';
import { MQueryDescription } from 'src/_common-browser';
import { PurchaseResumeCollectionCriteria } from 'src/business/_core/data/store-repository';
import { PurchaseListPagePurchaseFetchedModel } from '../model';

export const purchasesListPageGraphqlQueryBuilder = {
  buildQuery,
};
function buildQuery({
  clubReference,
  diveCenterId,
  status: { purchasePackages },
  maxResults = 100,
  updatedAfter,
}: PurchaseResumeCollectionCriteria) {
  const purchasePackagesFilters: string[] = [];

  purchasePackagesFilters.push(`clubReference: {_eq: "${clubReference}"}`);
  if (diveCenterId) {
    // utilisé sur la liste, mais pas sur l'onglet des paiements de la résa
    purchasePackagesFilters.push(`diveCenterId: {_eq: "${diveCenterId}"}`);
  }

  if (purchasePackages === 'only-active') {
    purchasePackagesFilters.push(
      '_or: [{validityStatus: {_eq: "active"}}, {purchasePaymentStatus: {_neq: "done"}}, {purchasePaymentPending: {_eq: "true"}}]',
    );
  }
  if (updatedAfter) {
    const updatedAfterString = dateService.formatUTC(
      updatedAfter,
      HASURA_DATE_TIME_WITH_MS_FORMAT,
    );

    purchasePackagesFilters.push(`_updatedAt: {_gt: "${updatedAfterString}"}`);
  }

  const where = `{${purchasePackagesFilters.join(',')}}`;

  const returnAttributes = buildAttributes();

  const queryDescription: MQueryDescription<PurchaseListPagePurchaseFetchedModel> =
    {
      returnName: 'purchaseResumes',
      queryName: 'club_commerce_purchase_package',
      returnType: 'all',
      where,
      orderBy: '{purchaseDate: desc}',
      returnAttributes,
      limit: maxResults,
    };

  return queryDescription;
}

function buildAttributes() {
  return `
    _id
    _updatedAt
    productPackageType
    purchaseDate
    validityStatus
    extraCosts
    purchasePaymentStatus
    purchasePaymentPending
    price
    payedAmount
    productPackageOffer
    credits
    comment
    contact: diver {
      _id
      firstName
      lastName
      phoneNumber
      emailAddress
      birthdate
      age
    }
    isUnitOffer
    unitPrice
    unitQuantity
    discountAmount
    totalPriceExtraCosts
    totalPriceBeforeDiscount
    totalPriceThirdPartyCollect
    billedByBookingAgencyId
`;
}
