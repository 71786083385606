/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { AppInputRHF, AppMultiCheckboxesRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import { DiveMode } from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { ClubSettingsProductOffersFormModel } from '../../_model';
import { ClubSettingsProductOffersDialogLocalState } from '../../useClubSettingsProductOffersDialogLocalState.hook';
import { ClubSettingsProductOffersFormOfferCustomName } from '../ClubSettingsProductOffersFormOfferCustomName';
import {
  ClubSettingsProductOffersFormOfferCommon_Others_TO_REFACTOR,
  ClubSettingsProductOffersFormOfferCommon_Tarif,
} from '../ClubSettingsProductOffersFormOffer_Common';
import { useClubSettingsProductOffersFormOfferNameDetails } from '../useClubSettingsProductOffersFormOfferNameDetails.hook';
import { ClubSettingsProductOffersFormOffer_Explo_Common_Special_Dive } from './ClubSettingsProductOffersFormOffer_Explo_Common_Special_Dive';
import { ClubSettingsProductOffersFormOfferExplo_DiveMode } from './ClubSettingsProductOffersFormOffer_Explo_DiveMode';
import { ClubSettingsProductOffersFormOffer_Explo_Dives_Count } from './ClubSettingsProductOffersFormOffer_Explo_Dives_Count';
import { ClubSettingsProductOffersFormOffer_Explo_Equipment } from './ClubSettingsProductOffersFormOffer_Explo_Equipment';
import { ClubSettingsProductOffersFormOffer_Explo_PriceType_SA } from './ClubSettingsProductOffersFormOffer_Explo_PriceType';
import { ClubSettingsProductOffersFormOffer_Explo_Supervision } from './ClubSettingsProductOffersFormOffer_Explo_Supervision';

const ALLOWED_DIVE_MODES_EXTRA: DiveMode[] = [
  'first-dive',
  'supervised',
  'autonomous',
  'free-dive',
  'snorkeling',
  'snorkelingSupervised',
  'watchingTour',
  'autoSupervised',
  'observer',
  'instructor',
  'training',
];

export const ClubSettingsProductOffersFormOffer_Explo = ({
  form,
  offerField,
  offerFieldIndex,
  offersFieldArray,
  localState,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  localState: ClubSettingsProductOffersDialogLocalState;
}) => {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isAdminMode = localState.state.isAdminMode;
  const isCreateMode = localState.state.isCreateMode;
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const showDetails = offersFieldArray.fields.length === 1;

  const nameDetails = useClubSettingsProductOffersFormOfferNameDetails({
    form,
    offerField,
    offersFieldArray,
  });

  const diveMode = offerField.productPackage.diveAttributes?.diveMode;

  const ALLOWED_DIVE_MODES_EXTRA_OPTIONS = ALLOWED_DIVE_MODES_EXTRA.map(
    (diveMode) => {
      const option: ValueLabel<DiveMode> = {
        label: diveModeFormatter
          .formatDiveMode(diveMode, { format: 'short-ref-label' })
          .toUpperCase(),
        value: diveMode,
      };
      return option;
    },
  );

  return isCreateMode ? (
    <div className="grid gap-2 grid-cols-2 sm:grid-cols-4 2xl:grid-cols-8">
      <ClubSettingsProductOffersFormOfferExplo_DiveMode
        form={form}
        offerField={offerField}
        offerFieldIndex={offerFieldIndex}
      />
      <ClubSettingsProductOffersFormOffer_Explo_Equipment
        form={form}
        offerField={offerField}
        offerFieldIndex={offerFieldIndex}
      />
      <ClubSettingsProductOffersFormOfferCommon_Tarif
        form={form}
        offerFieldIndex={offerFieldIndex}
      />
      <ClubSettingsProductOffersFormOffer_Explo_Common_Special_Dive
        form={form}
        offerField={offerField}
        offerFieldIndex={offerFieldIndex}
      />
    </div>
  ) : (
    <div className="grid gap-2 ">
      <div className="grid gap-2 grid-cols-2 sm:grid-cols-4 2xl:grid-cols-8">
        {showDetails && (
          <>
            <ClubSettingsProductOffersFormOfferExplo_DiveMode
              form={form}
              offerField={offerField}
              offerFieldIndex={offerFieldIndex}
            />
            <ClubSettingsProductOffersFormOffer_Explo_Equipment
              form={form}
              offerField={offerField}
              offerFieldIndex={offerFieldIndex}
            />
            <ClubSettingsProductOffersFormOffer_Explo_Supervision
              form={form}
              offerField={offerField}
              offerFieldIndex={offerFieldIndex}
            />
            <ClubSettingsProductOffersFormOffer_Explo_Common_Special_Dive
              form={form}
              offerField={offerField}
              offerFieldIndex={offerFieldIndex}
            />
            <ClubSettingsProductOffersFormOffer_Explo_PriceType_SA
              form={form}
              offerField={offerField}
              offerFieldIndex={offerFieldIndex}
            />
          </>
        )}

        <ClubSettingsProductOffersFormOffer_Explo_Dives_Count
          form={form}
          offerField={offerField}
          offerFieldIndex={offerFieldIndex}
          showDetails={showDetails}
        />

        <ClubSettingsProductOffersFormOfferCommon_Tarif
          form={form}
          offerFieldIndex={offerFieldIndex}
        />
        <ClubSettingsProductOffersFormOfferCommon_Others_TO_REFACTOR
          form={form}
          offerField={offerField}
          offerFieldIndex={offerFieldIndex}
          offersFieldArray={offersFieldArray}
        />
      </div>

      {showDetails && (
        <ClubSettingsProductOffersFormOfferCustomName
          form={form}
          nameDetails={nameDetails}
          i={i}
        />
      )}
      <div className="">
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Commentaire"
          control={control}
          name={`offers.${i}.productPackage.comment`}
          renderComponent={(props) => (
            <AppInputRHF
              className="w-full"
              {...props}
              placeholder="Commentaire"
              type="text"
              multiline={true}
              rowsMin={2}
            />
          )}
        />
      </div>
      {showDetails && isSuperAdmin && (
        <AppFormControlRHF_Deprecated
          visibility="super-admin"
          className={'w-full'}
          label="Prestations associées"
          control={control}
          name={`offers.${i}.productPackage.diveAttributes.extraDiveModes`}
          renderComponent={(props) => (
            <AppMultiCheckboxesRHF
              className="flex flex-wrap text-xs"
              {...props}
              options={ALLOWED_DIVE_MODES_EXTRA_OPTIONS}
            />
          )}
        />
      )}
    </div>
  );
};
