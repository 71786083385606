import { ClubEvent } from '@mabadive/app-common-model';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  TailwindBreakpoint,
  useMediaSizeTailwind,
} from 'src/business/_core/modules/layout';
import { useAppSecurityUser } from 'src/business/auth/services';
import { CalendarSessionWidthMode } from '../../club-planning-light/components/ClubPlanningLightSession/session-common';
import { ClubEventCardHeader } from './ClubEventCardHeader';

export const ClubEventCard = function ({
  clubEvent,
  widthMode,
  onClick: onClickInput,
  className,
}: {
  clubEvent: ClubEvent;
  widthMode: CalendarSessionWidthMode;
  className?: string;
  onClick?: (clubEvent: ClubEvent) => void;
}) {
  const mediaSize: TailwindBreakpoint = useMediaSizeTailwind();

  const themeClasses = useMemo(() => {
    return 'bg-gray-50 text-gray-600';
    // return 'bg-gray-50 text-app-primary border border-gray-200';
    // return 'bg-app-primary-light text-white border border-app-primary';
  }, []);

  const comment = useMemo(
    () => clubEvent.description.comment?.trim(),
    [clubEvent.description.comment],
  );

  const showDetails = useMemo(
    () => !!comment?.length && widthMode === 'large',
    [comment?.length, widthMode],
  );

  const user = useAppSecurityUser();

  const onClick = user.roles.includes('club-edit') ? onClickInput : undefined;

  return (
    <Tippy
      disabled={showDetails || !comment?.length}
      delay={[100, 100]}
      placement="top"
      content={comment}
    >
      <div
        className={clsx(
          'flex gap-1 flex-wrap',
          widthMode === 'large'
            ? 'py-0.5 px-1 text-xs sm:text-sm'
            : 'text-app-xxs2 md:text-app-xxs overflow-hidden',
          onClick && 'cursor-pointer hover:bg-gray-200',
          themeClasses,
          className,
        )}
        onClick={() => onClick && onClick(clubEvent)}
      >
        <ClubEventCardHeader clubEvent={clubEvent} widthMode={widthMode} />

        {showDetails && <div>{comment}</div>}
      </div>
    </Tippy>
  );
};
