/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppDocRef,
  AppDocUploadContext,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import { appDocRefFormatter } from '@mabadive/app-common-services';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FieldArrayWithId, UseFormReturn, useWatch } from 'react-hook-form';
import { useAppInputFileUpload } from 'src/_common-browser';
import {
  AppButton,
  AppDocFileTypeIcon,
  AppLoaderSpinner,
} from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import {
  AppFormControlRHF_Deprecated,
  AppInputRHF,
  AppSingleSelect2HeadlessUIRHF,
} from 'src/lib/form';
import {
  useAppDocRefOptions,
  useAppDocStatusOptions,
} from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import {
  AppDocEditDialogInitialState,
  AppDocumentCreatorForm,
} from '../_model';
import { AppDocEditDialogLocalState } from '../useAppDocEditDialogLocalState';
import { useFetchAppDocUrl } from '../useFetchAppDocUrl.hook';

export const AppDocumentCreatorPanelFormItem = ({
  item,
  itemIndex,
  localState,
}: {
  item: FieldArrayWithId<AppDocumentCreatorForm, 'items', 'id'>;
  itemIndex: number;
  localState: AppDocEditDialogLocalState;
}) => {
  const form: UseFormReturn<AppDocumentCreatorForm, any, undefined> =
    localState.state.form;
  const initialState: AppDocEditDialogInitialState =
    localState.state.initialState;
  const { control, setValue } = form;

  const appDocRefOptions = useAppDocRefOptions();
  const appDocStatusOptions = useAppDocStatusOptions();

  const [docRef, uploadStatus] = useWatch({
    control,
    name: [
      `items.${itemIndex}.appDoc.docRef`,
      `items.${itemIndex}.uploadStatus`,
    ],
  });

  const { bookingId, bookingMemberId, clubDiverId } = initialState;

  const { isFileLoading, uploadFile: uploadFileCore } =
    useAppInputFileUpload<PRO_AppDocResume>({
      onSuccess: (appDoc, event) => {
        setValue(`items.${itemIndex}.uploadStatus`, 'success');
        setValue(`items.${itemIndex}.appDoc._id`, appDoc._id);
        localState.actions.onAppDocsUploaded(appDoc);
      },
      onError: (err) => {
        setValue(`items.${itemIndex}.uploadStatus`, 'error');
      },
    });

  const uploadFile = useCallback(() => {
    const appDocUploadContext: AppDocUploadContext = {
      clubDiverId,
      bookingId,
      bookingMemberId,
      fileType: item.appDoc.fileType,
      scope: 'diver',
      isPublic: false,
    };
    setValue(`items.${itemIndex}.uploadStatus`, 'uploading');
    uploadFileCore(item?.file, {
      url: '/pro/docs/upload',
      json: {
        payload: { context: appDocUploadContext },
        imageDimensions: undefined,
        imageVariants: undefined,
      },
    });
  }, [
    bookingId,
    bookingMemberId,
    clubDiverId,
    item.appDoc.fileType,
    item?.file,
    itemIndex,
    setValue,
    uploadFileCore,
  ]);

  useEffect(() => {
    if (uploadStatus === 'pending') {
      uploadFile();
    }
  }, [item?.file, itemIndex, setValue, uploadFile, uploadStatus]);

  const { data: appDocUrlData } = useFetchAppDocUrl({
    appDocId: item?.appDoc?._id,
    enabled: localState.state.mode === 'edit' && !!item?.appDoc?._id,
  });

  const fileName = useMemo(() => {
    if (!appDocUrlData?.url) return undefined;
    const parsedUrl = new URL(appDocUrlData?.url);

    // Get the pathname from the parsed URL
    const pathname = parsedUrl.pathname;

    // Extract the filename by taking the substring after the last '/'
    const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
    return filename;
  }, [appDocUrlData]);

  return (
    <div className="app-card py-2 px-4 grid gap-y-4">
      <div className="overflow-hidden flex flex-wrap items-center gap-x-4 gap-y-2">
        <div className="flex-shrink truncate flex gap-2 items-center text-app-primary">
          {isFileLoading ? (
            <AppLoaderSpinner className="flex-shrink-0 w-6 h-6 md:w-8 md:h-8 bg-app-primary" />
          ) : uploadStatus === 'error' ? (
            <AppIcons.status.error className="flex-shrink-0 w-6 h-6 md:w-8 md:h-8 text-status-error" />
          ) : (
            <AppIcons.status.success className="flex-shrink-0 w-6 h-6 md:w-8 md:h-8 text-status-success" />
          )}
          <AppDocFileTypeIcon
            fileType={item.appDoc.fileType}
            className="flex-shrink-0 w-6 h-6 md:w-8 md:h-8"
          />
          {localState.state.mode === 'create' && (
            <div className="flex-shrink">{item?.file?.name}</div>
          )}
        </div>
        {uploadStatus === 'error' && (
          <div className="flex gap-2 items-center">
            <div className="text-sm text-status-error font-bold uppercase">
              Erreur lors de l'enregistrement
            </div>
            <AppButton
              color="primary-outline-light"
              onClick={() => {
                // retry
                uploadFile();
              }}
            >
              Ré-essayer
            </AppButton>
          </div>
        )}
      </div>
      <div className="grid sm:grid-cols-2 md:flex lg:grid lg:grid-cols-4 gap-x-4 gap-y-4">
        <AppFormControlRHF_Deprecated
          className="min-w-[60px]"
          label="Nature du document"
          control={control}
          name={`items.${itemIndex}.appDoc.docRef`}
          required={true}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              theme={'tailwind'}
              disableClearButton={true}
              {...props}
              options={appDocRefOptions}
              onChange={(docRef: AppDocRef) => {
                if (docRef !== 'other') {
                  setValue(
                    `items.${itemIndex}.appDoc.label`,
                    appDocRefFormatter.formatAppDocRef(docRef),
                  );
                }
                if (
                  docRef === 'medical-certificate' ||
                  docRef === 'insurance-proof' ||
                  docRef === 'medical-form'
                ) {
                  setValue(
                    `items.${itemIndex}.appDoc.details.docValidityInYears`,
                    1,
                  );
                }
              }}
            />
          )}
        />
        {docRef && (
          <>
            <AppFormControlRHF_Deprecated
              label="Date du document"
              control={control}
              name={`items.${itemIndex}.appDoc.details.docDate`}
              required={false}
              renderComponent={(props) => (
                <AppInputDatePickerMaterialRHF {...props} />
              )}
            />
            <AppFormControlRHF_Deprecated
              label="Statut"
              control={control}
              name={`items.${itemIndex}.appDoc.status`}
              required={false}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  theme={'tailwind'}
                  disableClearButton={true}
                  {...props}
                  options={appDocStatusOptions}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              label="Validité (en année)"
              control={control}
              name={`items.${itemIndex}.appDoc.details.docValidityInYears`}
              required={false}
              renderComponent={(props) => (
                <AppInputRHF type="number" {...props} />
              )}
            />
          </>
        )}
      </div>
      {docRef && (
        <div className="flex flex-wrap gap-x-8 gap-y-4">
          <AppFormControlRHF_Deprecated
            label="Nom du document"
            control={control}
            name={`items.${itemIndex}.appDoc.label`}
            required={false}
            renderComponent={(props) => <AppInputRHF type="text" {...props} />}
          />
          <AppFormControlRHF_Deprecated
            className="flex-grow"
            label="Commentaire"
            control={control}
            name={`items.${itemIndex}.appDoc.details.comment`}
            required={false}
            renderComponent={(props) => (
              <AppInputRHF
                className="w-full"
                multiline
                rowsMax={3}
                {...props}
              />
            )}
          />
        </div>
      )}
      {appDocUrlData?.url && (
        // download button
        <div className="flex flex-col gap-4 sm:max-w-xs">
          {item?.appDoc?.fileType === 'image' && (
            <a
              className="cursor-pointer"
              href={appDocUrlData.url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={appDocUrlData.url}
                className="w-full"
                alt={item?.appDoc?.label}
              />
            </a>
          )}
          <AppButton
            color="primary-outline-light"
            href={appDocUrlData.url}
            target="_blank"
            className="w-full"
            download={fileName}
          >
            Télécharger
          </AppButton>
        </div>
      )}
    </div>
  );
};
