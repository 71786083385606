/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { clubListsUrlBuilder } from 'src/pages/LI-lists/clubListsUrlBuilder.service';
import { GenericNavigationContext } from '../club-diver-participant/ClubParticipantNavigationContext/GenericNavigationContext.type';
import { clubPlanningUrlBuilder } from './clubPlanningUrlBuilder.service';

export const useParticipantBackUrl = ({
  navigationContext,
}: {
  navigationContext: GenericNavigationContext;
}) => {
  const { origin, diveSessionReference, planningFocusDateRef } =
    navigationContext;

  const backUrl = useMemo(() => {
    switch (origin) {
      case 'divers': {
        return clubListsUrlBuilder.buildDiversListUrl();
      }
      case 'payments-list': {
        return clubListsUrlBuilder.buildPaymentsListUrl();
      }
      case 'bookings-list': {
        return clubListsUrlBuilder.buildBookingsListUrl();
      }
      case 'orders-list': {
        return clubListsUrlBuilder.buildOrdersListUrl();
      }
      case 'deposits-list': {
        return clubListsUrlBuilder.buildDepositsListUrl();
      }
      case 'planning':
      default: {
        return clubPlanningUrlBuilder.buildPlanningBackUrlFromDiveSessionReference(
          diveSessionReference,
        );
      }
    }
  }, [diveSessionReference, origin]);

  return backUrl;
};
