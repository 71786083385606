/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButtonsBar } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { ClubPlanningStaffPanelLocalState } from 'src/business/club/modules/club-planning/ClubPlanningStaffPage/ClubPlanningStaffPanel';

export const ClubPlanningDailyStaffDialogActionsBar = ({
  localState,
  closeDialog,
}: {
  localState: ClubPlanningStaffPanelLocalState;
  closeDialog: () => void;
}) => {
  return (
    <AppButtonsBar hasChanges={true}>
      <>
        <AppButton
          size="normal"
          icon={AppIcons.action.cancel}
          tabIndex={500}
          color="gray-outline-light"
          onClick={() => {
            localState.actions.cancelChanges();
            closeDialog();
          }}
        >
          Annuler
        </AppButton>
        <AppButton
          type="submit"
          size="normal"
          icon={AppIcons.action.save}
          color="primary-outline-light"
          // disabled={data.participantsCheckState.participantsCheckedCount === 0}
          onClick={() => {
            localState.actions.persistChanges();
            closeDialog();
          }}
        >
          Confirmer
        </AppButton>
      </>
    </AppButtonsBar>
  );
};
