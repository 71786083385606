/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  AppLoader,
  AppNavigationStepsBar,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { GenericNavigationContext } from '../../../ClubParticipantNavigationContext/GenericNavigationContext.type';
import { CreateBookingInitialPlanPageFooterActionsBar } from './CreateBookingInitialPlanPageFooterActionsBar';
import {
  CreateBookingInitialPlanCertificationPanel,
  CreateBookingInitialPlanContactPanel,
  CreateBookingInitialPlanParticipantGroupsPanel,
  CreateBookingInitialPlanResumePanel,
} from './panels';
import { useCreateBookingInitialPlanNavigation } from './useCreateBookingInitialPlanNavigation.hook';
import {
  CreateBookingInitialPlanPageLocalStateLocalState,
  useCreateBookingInitialPlanPageLocalState,
} from './useCreateBookingInitialPlanPageLocalState.hook';

export const CreateBookingInitialPlanPage = ({
  navigationContext,
}: {
  navigationContext: GenericNavigationContext;
}) => {
  const localState: CreateBookingInitialPlanPageLocalStateLocalState =
    useCreateBookingInitialPlanPageLocalState({
      navigationContext,
    });

  const {
    form,
    hasFormChanges,
    navigation: { currentStep, setCurrentStep, steps },
    registrationMode,
    operationInProgressMessage,
  } = localState;

  const { register, handleSubmit, watch, control, formState, setValue, reset } =
    form;
  const fieldArray = useFieldArray({
    control,
    name: 'bookingInitialPlan.participantsGroups',
    shouldUnregister: true,
  });

  const { goBack, goNext } = useCreateBookingInitialPlanNavigation({
    localState,
  });

  return (
    <form
      className="w-full"
      noValidate
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        if (formState.isValid) {
          goNext();
        }
      }}
    >
      <input className="hidden" type="submit" />
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-white app-p-content"
        headerBar={() => (
          <div className="bg-white app-p-content">
            {!!operationInProgressMessage && (
              <AppNavigationStepsBar
                steps={steps}
                value={currentStep}
                onChange={(step) => {
                  setCurrentStep(step.id);
                }}
                disableNavigation={!formState.isValid}
              />
            )}
          </div>
        )}
        footerBar={() => (
          <>
            <CreateBookingInitialPlanPageFooterActionsBar
              localState={localState}
            />
          </>
        )}
      >
        {!!operationInProgressMessage ? (
          <AppLoader type="persist" message={operationInProgressMessage} />
        ) : (
          <>
            {currentStep === 'contact' && (
              <>
                <CreateBookingInitialPlanContactPanel
                  form={form}
                  registrationMode={registrationMode}
                  similarSearchCandidatesState={
                    localState.similarSearchCandidatesState
                  }
                />
              </>
            )}
            {currentStep === 'certification' && (
              <CreateBookingInitialPlanCertificationPanel form={form} />
            )}
            {currentStep === 'participants' && (
              <CreateBookingInitialPlanParticipantGroupsPanel
                form={form}
                fieldArray={fieldArray}
              />
            )}
            {currentStep === 'booking' && (
              <CreateBookingInitialPlanResumePanel form={form} />
            )}
          </>
        )}
      </AppPageContainerWithFixedBars>
    </form>
  );
};
