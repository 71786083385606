/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ClubSettingsPlanningBookingConfigInfo } from './ClubSettingsPlanningBookingConfigInfo';
import { ClubSettingsPlanningViewWeekDiveTourHeader } from './ClubSettingsPlanningViewWeekDiveTourHeader';
import { ClubSettingsPlanningViewWeekDiveTourSessionTime } from './ClubSettingsPlanningViewWeekDiveTourSessionTime';
import {
  ClubSettingsPlanningViewDayDiveTourModel,
  ClubSettingsPlanningViewDayModel,
} from './model';

export const ClubSettingsPlanningViewWeekDiveTour = ({
  day,
  diveTour,
  className = '',
  onClick,
}: {
  day: ClubSettingsPlanningViewDayModel;
  diveTour: ClubSettingsPlanningViewDayDiveTourModel;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className={`bg-white border border-dashed
      ${onClick ? 'cursor-pointer' : ''}
      ${className ?? ''}  
        border-day-period-${diveTour.session1.timeDayPeriod}-dark
        text-left flex flex-col`}
      onClick={onClick}
    >
      <ClubSettingsPlanningViewWeekDiveTourSessionTime diveTour={diveTour} />
      <ClubSettingsPlanningViewWeekDiveTourHeader
        className="h-12"
        diveTour={diveTour}
      />
      {(diveTour.bookingConfigs ?? [])?.map((bookingConfig) => (
        <ClubSettingsPlanningBookingConfigInfo
          className="my-1 text-sm"
          key={bookingConfig.reference}
          bookingConfig={bookingConfig.conf}
        />
      ))}
    </div>
  );
};
