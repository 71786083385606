/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';

export const ClubSettingsCommunicationMain_EmailAutoMessage = () => {
  return (
    <AppMessageLight icon={AppIcons.socialMedia.mail}>
      <div className="grid gap-2">
        <p>
          <b>Envoi direct ⚡</b> : les mails sont envoyés par mabadive, ce qui
          permet d'activer les options avancées
        </p>
        <p>
          <b>Envoi manuel ❌</b> : mabadive ouvre votre client de messagerie, et
          c'est vous qui finalisez l'envoi du message
        </p>
      </div>
    </AppMessageLight>
  );
};
