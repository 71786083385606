
import { LoadableAttributeStore, loadableAttributeStoreFactory, SimpleStore } from '@mabadive/app-common-services';

export type ApiClientStore = {
  authenticationToken: LoadableAttributeStore<string>;
  baseUrl: LoadableAttributeStore<string>;
  appClientId: LoadableAttributeStore<string>;
  appVersion: LoadableAttributeStore<string>;
};

export const apiClientStoreProvider = {
  get,
  init,
};

let store: ApiClientStore;

function get(): ApiClientStore {
  if (!store) {
    throw new Error('Call init method first to initialize ApiClientStore.');
  }
  return store;
}

function init(baseStore: SimpleStore<any>): ApiClientStore {
  store = {
    authenticationToken: loadableAttributeStoreFactory.create<string>(baseStore, ' api_authenticationToken'),
    baseUrl: loadableAttributeStoreFactory.create<string>(baseStore, ' api_baseUrl'),
    appClientId: loadableAttributeStoreFactory.create<string>(baseStore, ' api_appClientId'),
    appVersion: loadableAttributeStoreFactory.create<string>(baseStore, ' api_appVersion'),
  };
  return get();
}

