/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppCompanyMessageTarget } from '@mabadive/app-common-model';
import {
  emailLinkBuilder,
  jsonPatcher,
  smsLinkBuilder,
  whatzappLinkBuilder,
} from '@mabadive/app-common-services';

import {
  CreateMessageToCustomersFormModel,
  CreateMessageToCustomersResult,
  CreateMessageToCustomersResultChanges,
} from '../model';
import {
  CreateMessageToCustomersAggregatedRecipient,
  CreateMessageToCustomersAggregatedRecipientContacts,
} from '../model/CreateMessageToCustomersAggregatedRecipient.type';

export const createMessageToCustomersChangesBuilder = {
  buildChangesResult,
  buildTargetValidContact,
};

function buildChangesResult({
  initialFormValue,
  formValue,
}: {
  initialFormValue: CreateMessageToCustomersFormModel;
  formValue: CreateMessageToCustomersFormModel;
}): CreateMessageToCustomersResult {
  const changes: CreateMessageToCustomersResultChanges = {
    updatedDivers: [],
  };
  formValue.recipients.forEach((formRecipient) => {
    const initialFormRecipient = initialFormValue.recipients.find(
      (x) => x.diver?._id === formRecipient.diver?._id,
    );
    const initialDiver = initialFormRecipient.diver;
    const finalDiver = formRecipient.diver;
    const patchOperations = jsonPatcher.compareObjects(
      initialDiver,
      finalDiver,
      {
        // else, value won't be deleted by typeorm
        // https://github.com/typeorm/typeorm/issues/2934
        replaceDeleteByNullValue: true,
      },
    );

    if (patchOperations.length) {
      changes.updatedDivers.push({
        pk: initialDiver._id,
        patchOperations,
      });
    }
  });

  const allRecipients: CreateMessageToCustomersAggregatedRecipient[] =
    formValue.recipients.map((formRecipient) => {
      const validContacts: CreateMessageToCustomersAggregatedRecipientContacts =
        {
          emailAddress: emailLinkBuilder.cleanEmailAddress(
            formRecipient.diver?.emailAddress,
          ),
          sms: smsLinkBuilder.cleanPhoneNumber(
            formRecipient.diver?.phoneNumber,
          ),
          whatsapp: whatzappLinkBuilder.cleanPhoneNumber(
            formRecipient.diver?.phoneNumber,
          ),
        };
      const targetValidContact: string = buildTargetValidContact({
        messageTarget: formValue.messageTarget,
        validContacts,
      }); // target not selected

      const recipient: CreateMessageToCustomersAggregatedRecipient = {
        formRecipient,
        validContacts,
        targetValidContact,
      };
      return recipient;
    });

  const {
    validRecipients,
    validSelectedRecipients,
    validSelectedUniqueContacts,
  } = getValidSelectedRecipients(allRecipients);

  const result: CreateMessageToCustomersResult = {
    hasChanges: changes.updatedDivers.length > 0,
    changes,
    formValue,
    aggregated: {
      allRecipients,
      validRecipients,
      validSelectedRecipients,
      validSelectedUniqueContacts,
    },
  };

  return result;
}

function buildTargetValidContact({
  messageTarget,
  validContacts,
}: {
  messageTarget?: AppCompanyMessageTarget;
  validContacts: CreateMessageToCustomersAggregatedRecipientContacts;
}): string {
  return messageTarget === 'email'
    ? validContacts.emailAddress
    : messageTarget === 'sms'
    ? validContacts.sms
    : messageTarget === 'whatsapp'
    ? validContacts.whatsapp
    : undefined;
}

function getValidSelectedRecipients(
  allRecipients: CreateMessageToCustomersAggregatedRecipient[],
): {
  validRecipients: CreateMessageToCustomersAggregatedRecipient[];
  validSelectedRecipients: CreateMessageToCustomersAggregatedRecipient[];
  validSelectedUniqueContacts: string[];
} {
  const validRecipients = allRecipients.filter((x) => !!x.targetValidContact);
  const validSelectedRecipients = validRecipients.filter(
    (x) => x.formRecipient.isSelected,
  );

  return {
    validRecipients,
    validSelectedRecipients,
    validSelectedUniqueContacts: [
      ...new Set(validSelectedRecipients.map((x) => x.targetValidContact)), // unique
    ],
  };
}
