/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubStaffMemberRole,
  DiveSessionStaffMemberTableModelStaffMember,
} from '@mabadive/app-common-model';
import { arrayBuilder } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiveSessionStaffRoleModel } from './_model';

export const useDiveSessionStaffMemberRoles = ({
  isMultiSessionConfigForStaff,
  staffMemberFull,
  availableRoles,
}: {
  isMultiSessionConfigForStaff: boolean;
  staffMemberFull: DiveSessionStaffMemberTableModelStaffMember;
  availableRoles: ClubStaffMemberRole[];
}): DiveSessionStaffRoleModel[] => {
  const { session1, session2, meta } = staffMemberFull;

  const clubResume = useClubResume();

  const enabledRoles = useMemo(
    () =>
      arrayBuilder.filterDuplicated(
        staffMemberFull.sessionAssigmentResume.activeOrAssignedRoles.concat(
          staffMemberFull.sessionAssigmentResume.availableRoles,
        ),
      ),
    [
      staffMemberFull.sessionAssigmentResume.activeOrAssignedRoles,
      staffMemberFull.sessionAssigmentResume.availableRoles,
    ],
  );

  const roles = useMemo(() => {
    const roles: DiveSessionStaffRoleModel[] = [];
    if (availableRoles.includes('surface-security')) {
      roles.push({
        staffRoleRef: 'surface-security',
        label: 'SS',
        enabled: enabledRoles.includes('surface-security'),
        active:
          session1.isSurfaceSecurity ||
          (isMultiSessionConfigForStaff && session2?.isSurfaceSecurity) ||
          staffMemberFull.sessionAssigmentResume.activeRoles.includes(
            'surface-security',
          ),
        assigned:
          staffMemberFull.sessionAssigmentResume.assignedRoles.includes(
            'surface-security',
          ),
      });
    }

    if (availableRoles.includes('diving-director')) {
      roles.push({
        staffRoleRef: 'diving-director',
        label: 'DP',
        enabled: enabledRoles.includes('diving-director'),
        active:
          session1.isDivingDirector ||
          (isMultiSessionConfigForStaff && session2?.isDivingDirector) ||
          staffMemberFull.sessionAssigmentResume.activeRoles.includes(
            'diving-director',
          ),
        assigned:
          staffMemberFull.sessionAssigmentResume.assignedRoles.includes(
            'diving-director',
          ),
      });
    }
    if (availableRoles.includes('scuba-diving-instructor')) {
      roles.push({
        staffRoleRef: 'scuba-diving-instructor',
        label: 'GP',
        enabled: enabledRoles.includes('scuba-diving-instructor'),
        active:
          session1.isInstructor ||
          (isMultiSessionConfigForStaff && session2?.isInstructor) ||
          staffMemberFull.sessionAssigmentResume.activeRoles.includes(
            'scuba-diving-instructor',
          ),
        assigned: staffMemberFull.sessionAssigmentResume.assignedRoles.includes(
          'scuba-diving-instructor',
        ),
      });
    }
    if (
      false && // disabled
      clubResume.clubSettings.freeDive.enabled &&
      availableRoles.includes('free-diving-instructor')
    ) {
      roles.push({
        staffRoleRef: 'free-diving-instructor',
        label: 'GP apnée',
        enabled:
          staffMemberFull.sessionAssigmentResume.activeOrAssignedRoles.includes(
            'free-diving-instructor',
          ),
        assigned: staffMemberFull.sessionAssigmentResume.assignedRoles.includes(
          'free-diving-instructor',
        ),
      });
    }
    return roles;
  }, [
    availableRoles,
    clubResume.clubSettings.freeDive.enabled,
    enabledRoles,
    session1.isSurfaceSecurity,
    session1.isDivingDirector,
    session1.isInstructor,
    isMultiSessionConfigForStaff,
    session2?.isSurfaceSecurity,
    session2?.isDivingDirector,
    session2?.isInstructor,
    staffMemberFull.sessionAssigmentResume.assignedRoles,
    staffMemberFull.sessionAssigmentResume.activeRoles,
    staffMemberFull.sessionAssigmentResume.activeOrAssignedRoles,
  ]);

  return roles;
};
