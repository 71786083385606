/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_DOC_REFS, AppDocRef } from '@mabadive/app-common-model';
import { ReactNode, useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
//import { RegionalTaxLabel } from '../company/RegionalTax/RegionalTaxLabel';
import { appDocRefFormatter } from '@mabadive/app-common-services';
export function useAppDocRefOptions(): ValueLabel<AppDocRef, ReactNode>[] {
  return useMemo(() => {
    const options: ValueLabel<AppDocRef, ReactNode>[] = APP_DOC_REFS.map(
      (appDocRef) => ({
        value: appDocRef,
        label: appDocRefFormatter.formatAppDocRef(appDocRef),
        // icon:
        //   (
        //   <AppDocRefLabel
        //     appDocRef={appDocRef}
        //     className="text-app-blue hover:text-white"
        //   />
        // ),
      }),
    );

    return options;
  }, []);
}
