/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_COLORS } from '@mabadive/app-common-model';
import { Drawer, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { useSideMenu } from 'src/business/_core/modules/layout/components/SideMenu/useSideMenu.hook';
import { appStylesHelper, useCacheableClasses } from '../../../root/theme';
import { responsiveAttributesValues } from '../../services';

const SIDE_MARGIN = 10;

export const AppFixedButtonsBarDeprecatedOVER: FC<{
  hasChanges?: boolean;
  children?: React.ReactNode;
}> = ({ hasChanges, children }) => {
  const classes = useCacheableClasses({
    cacheKey: 'AppFixedButtonsBarDeprecatedOVER',
    buildStyles,
  });

  const { isMenuTemporary, isMenuCollapsed } = useSideMenu();

  const globalClasses = useGlobalClasses();

  return (
    <Drawer
      className={`${
        classes.root
      } has-changes-${hasChanges} with-permanent-menu-${!isMenuTemporary} with-menu-collapsed-${isMenuCollapsed}`}
      variant="permanent"
      open={true}
      anchor="bottom"
    >
      <div className={globalClasses.buttonsBar}>{children}</div>
    </Drawer>
  );
};

function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      '& .MuiDrawer-paperAnchorDockedBottom': {
        zIndex: theme.zIndex.drawer - 1 + 1,
        background: APP_COLORS.menuBottom,
        padding: '5px 20px',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 10px 15px 8px rgba(60,60,60,0.71)',
      },
      '&.has-changes-true': {
        '& .MuiDrawer-paperAnchorDockedBottom': {
          background: APP_COLORS.update,
        },
      },
      '&.with-permanent-menu-true': {
        '& .MuiDrawer-paperAnchorDockedBottom': {
          marginRight: `${SIDE_MARGIN}px`,
        },
      },
      '&.with-permanent-menu-true.with-menu-collapsed-false': {
        '& .MuiDrawer-paperAnchorDockedBottom': {
          ...responsiveAttributesValues(theme, {
            marginLeft: appStylesHelper.getSideMenuWidthResponsive(SIDE_MARGIN),
          }),
        },
      },
      '&.with-permanent-menu-true.with-menu-collapsed-true': {
        '& .MuiDrawer-paperAnchorDockedBottom': {
          marginLeft: appStylesHelper.getSideMenuCollapsedWidth(SIDE_MARGIN),
        },
      },
    },
  });
}
