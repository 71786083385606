/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingAgency,
} from '@mabadive/app-common-model';
import {
  bookingAgencyFormatter,
  jsonPatcher,
} from '@mabadive/app-common-services';
import React from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppHeroIcons,
  AppIcons,
} from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';

export const BookingAgenciesTableRow = ({
  agency,
  onClick,
}: {
  agency: BookingAgency;
  onClick?: () => void;
}) => {
  const clubResume = useClubResume();

  return (
    <tr className={'cursor-pointer'} onClick={onClick}>
      <td
        className={`px-2 py-0.5 whitespace-nowrap uppercase font-bold ${
          agency.active ? '' : 'text-red-400'
        }`}
      >
        <div className="flex gap-2 items-start">
          <AppIcons.settings.customers.agencies
            className="my-1 w-6 h-4 border border-gray-600 py-px px-pt"
            style={{
              background: agency.details?.color,
              color: agency.details?.colorText,
            }}
          />
          <div>
            <div>
              {agency.name}{' '}
              {agency.details.locationType && (
                <span className="font-normal text-sm text-gray-400">
                  (
                  {bookingAgencyFormatter.formatLocationType(
                    agency.details.locationType,
                  )}
                  )
                </span>
              )}
            </div>
            {agency.details?.links?.webSite?.url && (
              <div className="font-normal text-sm text-gray-500">
                {agency.details?.links?.webSite?.url}
              </div>
            )}
          </div>
        </div>
      </td>
      <td
        className={
          'hidden xs:table-cell px-2 py-0.5 whitespace-nowrap text-right text-sm text-gray-600'
        }
      >
        {agency.details?.defaultCommissionRate ? (
          <div className="font-bold">
            {agency.details?.defaultCommissionRate}%
          </div>
        ) : null}
      </td>
      <td
        className={
          'hidden lg:table-cell px-2 py-0.5 whitespace-nowrap text-gray-500 text-left'
        }
      >
        {agency.details?.comment && <div>{agency.details?.comment}</div>}
      </td>
      <td className={'px-2 py-0.5 text-right'}>
        {agency.active ? (
          <button
            type="button"
            title="Supprimer cette agence"
            className="p-1 bg-gray-200 hover:bg-red-400 text-white rounded-full"
            onClick={async (e) => {
              e.stopPropagation();
              if (
                await confirmDialog.confirmPromise({
                  title: 'Supprimer cette agence',
                  message: 'Êtes-vous sûr de vouloir supprimer cette agence?',
                  type: 'noYesDanger',
                })
              ) {
                await clubMassiveUpdatorClient.update({
                  bookingAgencies: { deletedIds: [agency._id] },
                });
              }
            }}
          >
            <AppHeroIcons.actionDelete className="h-6 w-6 " />
          </button>
        ) : (
          <button
            type="button"
            title="Ré-activer cette agence"
            className="p-1 bg-gray-400 hover:bg-green-400 text-white rounded-full"
            onClick={async (e) => {
              e.stopPropagation();
              if (
                await confirmDialog.confirmPromise({
                  title: 'Ré-activer cette agence',
                  message:
                    'Êtes-vous sûr de vouloir activer cette agence archivé?',
                  type: 'noYesDanger',
                })
              ) {
                const patchOperations = jsonPatcher.compareObjects(
                  agency,
                  {
                    ...agency,
                    active: true,
                  },
                  {},
                );
                if (patchOperations.length) {
                  const patch: AppEntityUpdatePatch = {
                    pk: agency._id,
                    patchOperations,
                  };
                  // await clubMassiveUpdatorClient.update({
                  //   updatedBookingAgencies: [patch],
                  // });
                }
              }
            }}
          >
            <AppHeroIcons.actionConfirm className="h-6 w-6 " />
          </button>
        )}
      </td>
    </tr>
  );
};
