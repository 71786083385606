import { ClubParticipant, ClubSettings } from '@mabadive/app-common-model';
import { BillingTabDiveSessionBillingResumeMultiDivers } from '../../../models';
import { bookingPagePackageConsumedCounter } from '../../../services/02.update-state/services';

export const creditsCountBuilder = {
  updateCounts,
  buildTotalConsumedCountFromBillingResumes,
};

function buildTotalConsumedCountFromBillingResumes({
  sessionsBillingResumes,
  consumedExternalCount,
  clubSettings,
  countSuccessiveAsSingle,
}: {
  sessionsBillingResumes: BillingTabDiveSessionBillingResumeMultiDivers[];
  consumedExternalCount: number;
  clubSettings: ClubSettings;
  countSuccessiveAsSingle: boolean;
}): number {
  // return sessionsBillingResumes.reduce(
  //   (acc, x) =>
  //     acc +
  //     x.purchaseParticipants.filter((p) => p.isAssociatedToPurchase).length,
  //   consumedExternalCount,
  // );
  const associatedDiveSessionParticipants = sessionsBillingResumes.reduce(
    (acc, x) => {
      x.purchaseParticipants.forEach((p) => {
        if (p.isAssociatedToPurchase) {
          acc.push(
            p.participant?.bookingParticipantFull?.diveSessionParticipant,
          );
        }
      });
      return acc;
    },
    [] as ClubParticipant[],
  );
  const consumedAppCount =
    bookingPagePackageConsumedCounter.buildConsumedAppCount({
      diveSessionParticipants: associatedDiveSessionParticipants,
      clubSettings,
      countSuccessiveAsSingle,
    });

  return consumedAppCount + (consumedExternalCount ?? 0);
}

function updateCounts({
  consumedAppCount,
  creditsInitialCount,
  creditsAdditionalCount,
  consumedExternalCount,
  unitQuantityAvailableInPackage: unitQuantity = 1, // ça c'est le nombre de plongées total dans le forfait, pas forcément le nombre imputé!
}: {
  consumedAppCount: number;
  creditsInitialCount: number;
  creditsAdditionalCount: number;
  consumedExternalCount: number;
  unitQuantityAvailableInPackage: number;
}): {
  creditsTotalCount: number;
  consumedTotalCount: number;
  creditsRemainingCount: number;
} {
  // ATTENTION: creditsTotalCount ne multiplie pas par unitQuantity: il faudrait revoir un peu le modèle

  // Exemple 1 : forfait 8 plongées (3/8)
  // INPUT
  // - creditsInitialCount : 1
  // - creditsAdditionalCount : 0
  // - consumedAppCount : 3
  // - consumedExternalCount : 0
  // - consumedTotalCount : 3
  // - unitQuantity : 8
  // OUTPUT:
  // - creditsTotalCount : 1
  // - consumedTotalCount : 2
  // - creditsRemainingCount: 6

  // Exemple 2 : forfait 4 plongées 2-tank (3/4) => tant qu'on n'impute que des 2-tank, pas de soucis
  // INPUT
  // - creditsInitialCount : 1
  // - creditsAdditionalCount : 0
  // - consumedAppCount : 3
  // - consumedExternalCount : 0
  // - consumedTotalCount : 3
  // - unitQuantity : 4
  // OUTPUT:
  // - creditsTotalCount : 1
  // - consumedTotalCount : 2
  // - creditsRemainingCount: 1

  const creditsTotalCount = creditsInitialCount + creditsAdditionalCount; // total: creditsInitialCount + creditsAdditionalCount
  const consumedTotalCount = consumedAppCount + (consumedExternalCount ?? 0); // total: consumedAppCount + consumedExternalCount

  // NOTE: 15/02/2024 : ne pas multiplier consumedTotalCount pr unitQuantity
  // ça a été fait il y a 2 mois, il y a peut-être un cas particulier qui est mal traité
  // FIXME: il y a un problème, si on a des forfaits 2-tank, c'est que si on assigne des plongées simples dessus, ça les comptes comme si on avait des doubles
  // mais c'est un cas particulier et rare, donc pas urgent
  const creditsRemainingCount =
    creditsTotalCount * (unitQuantity ?? 1) - consumedTotalCount; // différence: creditsTotalCount - consumedTotalCount

  return {
    creditsTotalCount,
    consumedTotalCount,
    creditsRemainingCount,
  };
}
