/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CLUB_OFFER_STATES,
  CLUB_TYPES,
  ClubOfferState,
  ClubType,
} from '@mabadive/app-common-model';
import { clubOfferStateFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppFormControlRHF_Deprecated, AppInputRHF } from 'src/lib/form';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { AdminClubFormModel } from './AdminClubFormModel.type';

const CLUB_TYPES_OPTIONS = CLUB_TYPES.map((clubType) => {
  const option: ValueLabel<ClubType> = {
    label: clubType,
    value: clubType,
  };
  return option;
});
const CLUB_OFFER_STATES_OPTIONS = CLUB_OFFER_STATES.map((state) => {
  const option: ValueLabel<ClubOfferState> = {
    label: clubOfferStateFormatter.formatClubOfferState(state),
    value: state,
  };
  return option;
});

export const AdminCompanyEditForm = ({
  form,
}: {
  form: UseFormReturn<AdminClubFormModel>;
}) => {
  const { control, setValue } = form;

  return (
    <>
      <div className={'my-2 w-full flex flex-col md:grid md:grid-cols-4 gap-4'}>
        <AppFormControlRHF_Deprecated
          className={'md:col-span-1 w-full'}
          label="Nom"
          control={control}
          name={'name'}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-1 w-full'}
          label="Type"
          control={control}
          name={'type'}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              disableClearButton={false}
              theme={'material-ui'}
              options={CLUB_TYPES_OPTIONS}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-1 w-full'}
          label="Ville"
          control={control}
          name={'city'}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-1 w-full'}
          label="Région, Pays"
          control={control}
          name={'country'}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
      </div>
      <div className={'my-2 w-full flex flex-col md:grid md:grid-cols-4 gap-4'}>
        <AppFormControlRHF_Deprecated
          className={'md:col-span-1 w-full'}
          label="Site Web"
          control={control}
          name={'webSiteUrl'}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-1 w-full'}
          label="Page Facebook"
          control={control}
          name={'facebookPageUrl'}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <AppFormControlRHF_Deprecated
          className={'md:col-span-1 w-full'}
          label="Réference"
          control={control}
          name={'reference'}
          disabled={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} readOnly={true} fullWidth />
          )}
        />
      </div>
      <div className={'my-2 w-full flex flex-col md:grid md:grid-cols-4 gap-4'}>
        <AppFormControlRHF_Deprecated
          className={'md:col-span-1 w-full'}
          label="Offre en cours"
          control={control}
          name={'offer.state'}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              disableClearButton={false}
              theme={'material-ui'}
              options={CLUB_OFFER_STATES_OPTIONS}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Début"
          control={control}
          name="offer.currentOfferStart"
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF className="w-full" {...props} />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Fin"
          control={control}
          name="offer.currentOfferEnd"
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF className="w-full" {...props} />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Date limite paiement (SI NON PAYÉ)"
          control={control}
          name="offer.paymentDeadline"
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF className="w-full" {...props} />
          )}
        />
      </div>
      {/* <AppFormControlRHF_Deprecated
        className={'md:col-span-1 w-full'}
        label="Fonctionalités"
        control={control}
        name={'features'}
        renderComponent={(props) => (
          <AppMultiCheckboxesRHF {...props} options={CLUB_FEATURES_OPTIONS} />
        )}
      /> */}

      <AppFormControlRHF_Deprecated
        className={'md:col-span-1 w-full'}
        label="Commentaire"
        control={control}
        name={'comment'}
        renderComponent={(props) => (
          <AppInputRHF {...props} multiline rowsMax={15} fullWidth />
        )}
      />
    </>
  );
};
