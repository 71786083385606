import React from 'react';
import {
  DashboardReportPageEvolutionParticipantsByDay,
  DashboardReportPageFirstDiveParticipants,
  DashboardReportPageGlobalActivityPurchasesFirstDivesOverPeriod,
} from '../_core';

import clsx from 'clsx';
import { DashboardReportComparison } from './DashboardReportComparison';
import {
  DashboardReportComparisonContext,
  DashboardReportPageLocalState,
} from './useDashboardReportPageLocalState.hook';

export const DashboardReportPanelFirstDives = ({
  chartWidth,
  totalColsCount,
  localState,
  comparisonContext,
  className,
}: {
  chartWidth: number;
  totalColsCount: number;
  localState: DashboardReportPageLocalState;
  comparisonContext: DashboardReportComparisonContext;
  className?: string;
}) => {
  const showPeriodRangeComparison =
    localState.state.viewParameters.showPeriodRangeComparison;

  return (
    <div className="w-full flex flex-col gap-5">
      <div
        className={clsx(
          'flex flex-col gap-5',
          showPeriodRangeComparison ? '' : '2xl:grid 2xl:grid-cols-2',
        )}
      >
        <DashboardReportComparison
          comparisonContext={comparisonContext}
          render={(fetchResult, comparisonDetails) =>
            fetchResult.firstDiveParticipantsOverPeriod && (
              <DashboardReportPageFirstDiveParticipants
                // key={JSON.stringify(comparisonDetails?.sharedState)}
                title={'Baptêmes'}
                firstDiveParticipantsOverPeriod={
                  fetchResult.firstDiveParticipantsOverPeriod
                }
                comparisonDetails={comparisonDetails}
              />
            )
          }
        />
        <DashboardReportComparison
          comparisonContext={comparisonContext}
          render={(fetchResult, comparisonDetails) =>
            fetchResult.purchasesFirstDivesOverPeriod && (
              <DashboardReportPageGlobalActivityPurchasesFirstDivesOverPeriod
                title="Baptêmes facturés"
                purchasesFirstDivesOverPeriod={
                  fetchResult.purchasesFirstDivesOverPeriod
                }
                comparisonDetails={comparisonDetails}
              />
            )
          }
        />
      </div>

      <DashboardReportComparison
        comparisonContext={comparisonContext}
        render={(fetchResult, comparisonDetails) =>
          fetchResult.participantsByPeriod && (
            <DashboardReportPageEvolutionParticipantsByDay
              participantsByPeriod={fetchResult.participantsByPeriod}
              chartWidth={chartWidth}
              pageMode="first-dives"
              comparisonDetails={comparisonDetails}
            />
          )
        }
      />
    </div>
  );
};
