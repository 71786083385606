/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import {
  AppBreadcrumbBar,
  AppButton,
  AppPageContentContainer,
  AppTabsTailwind,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';

import { useRedirect } from 'src/business/_core/data/hooks';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { useClubSettings } from 'src/business/club/data/hooks';
import { ClubSettingsServicesPageLocalState } from '../useClubSettingsServicesPageLocalState.hook';

export const ClubSettingsServicesPageBasePanel = ({
  localState,
  children,
  onClick,
}: {
  localState: ClubSettingsServicesPageLocalState;
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
}) => {
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  return (
    <AppPageContentContainer
      paddingBottom={false}
      className="bg-gray-50 relative"
    >
      <AppBreadcrumbBar
        className="sticky z-50 top-0"
        color={AppIconsSettings.account.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.account.main,
            label: 'Mon compte',
            url: '/club/settings',
          },
          {
            label: 'Prestations et tarifs',
            url: '/club/settings',
          },
        ]}
      />
      <div className="app-p-content">
        <AppTabsTailwind
          className="w-full"
          tabs={state.tabs.data}
          selected={selectedTab}
          theme="underline"
          breakpoint="sm"
          onChange={(tabId) => state.tabs.setSelected(tabId)}
        />
        {/* NOTE : ce app-card ne s'étend pas au contenu, il faudrait revoir ça (mais pas urgent) */}
        {/* Peut-être que la solution, c'est que ça soit cette card qui scroll, et non pas le conteneur du dessus */}
        <div
          className={clsx('mt-4 app-card p-4', onClick && 'app-card-highlight')}
          onClick={onClick}
        >
          {children}

          {state.editMode === 'super-admin-only' ? (
            <div className="my-8 text-gray-500 flex md:flex-col flex-wrap gap-4 justify-between items-start">
              <p>Pour modifier la configuration, veuillez nous contacter:</p>

              <AppButton
                className=""
                color="primary-outline-light"
                icon={AppHeroIcons.contact}
                onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
              >
                Contactez-nous
              </AppButton>
            </div>
          ) : state.editEnabled && onClick ? (
            <div className="mt-8 mb-2">
              <AppButton
                color={'primary-outline-light'}
                size="normal"
                icon={AppIcons.action?.edit}
              >
                Modifier
              </AppButton>
            </div>
          ) : null}
        </div>
      </div>
    </AppPageContentContainer>
  );
};
