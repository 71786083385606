import { CommerceBookingDepositForListGql_Company } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useProgressiveListRendering } from 'src/business/_core/data/hooks';
import { DepositsListTableRow } from './DepositsListTableRow';

export function DepositsListTable({
  deposits: depositsInput,
  className,
}: {
  deposits: CommerceBookingDepositForListGql_Company[];
  className?: string;
}) {
  const depositsToRender = useProgressiveListRendering(depositsInput, {
    initialSize: 50,
    step: 100,
  });

  return (
    <table className={clsx('app-table min-w-full', className)}>
      <thead>
        <tr>
          {/* <th scope="col" className="w-9 py-1 text-right">
            <span className="sr-only">Actions</span>
          </th> */}
          <th scope="col" className="px-2 py-1  uppercase tracking-wider">
            Date
          </th>
          <th scope="col" className="w-20 px-2 py-1  uppercase tracking-wider">
            Montant
          </th>
          <th scope="col" className="px-2 py-1  uppercase tracking-wider ">
            <div className="flex">Plongeur</div>
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-widerdiver hidden lg:table-cell"
          >
            Dernière plongée
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-widerdiver hidden lg:table-cell"
          >
            Prochaine plongée
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden xs:table-cell"
          >
            Statut
          </th>
          <th
            scope="col"
            className="px-2 py-1  uppercase tracking-wider hidden md:table-cell"
          >
            Référence
          </th>
          <th
            scope="col"
            className="hidden md:table-cell px-2 py-1  uppercase tracking-wider "
          >
            <div className="flex">Commentaire</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {depositsToRender.map((bookingDeposit, i) => (
          <DepositsListTableRow
            key={bookingDeposit._id}
            bookingDeposit={bookingDeposit}
          />
        ))}
      </tbody>
    </table>
  );
}
