import {
  GenerateOffersDivePlanOptions,
  GenerateOffersOptions,
  GenerateOffersTrainingOptions,
} from '../model';

export const getOffersDivePlanGenericGeneratorOptions =
  (): GenerateOffersOptions => {
    const divePlan: GenerateOffersDivePlanOptions = {
      snorkelings: {
        enabled: true,
        offers: [
          {
            price: 30,
            label: 'Sortie PMT',
            namingConfiguration: {
              includeDefaultName: false,
              includeEquipment: false,
              includeSupervision: false,
            },
          },
        ],
      },
      firstDives: {
        enabled: true,
        offers: [
          {
            price: 70,
          },
        ],
      },
      scubaDives: {
        enabled: true,
        offers: [
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'equipped',
              supervision: 'autonomous',
              divesCount: 1,
            },
            price: 45,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'not-equipped',
              supervision: 'autonomous',
              divesCount: 1,
            },
            price: 50,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'equipped',
              supervision: 'supervised',
              divesCount: 1,
            },
            price: 55,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'not-equipped',
              supervision: 'supervised',
              divesCount: 1,
            },
            price: 60,
          },

          // forfaits
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'equipped',
              supervision: 'autonomous',
              divesCount: 5,
            },
            price: (45 - 3) * 5,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'not-equipped',
              supervision: 'autonomous',
              divesCount: 5,
            },
            price: (50 - 3) * 5,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'equipped',
              supervision: 'supervised',
              divesCount: 5,
            },
            price: (55 - 3) * 5,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'not-equipped',
              supervision: 'supervised',
              divesCount: 5,
            },
            price: (60 - 3) * 5,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'equipped',
              supervision: 'autonomous',
              divesCount: 10,
            },
            price: (45 - 5) * 10,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'not-equipped',
              supervision: 'autonomous',
              divesCount: 10,
            },
            price: (50 - 5) * 10,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'equipped',
              supervision: 'supervised',
              divesCount: 10,
            },
            price: (55 - 5) * 10,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'not-equipped',
              supervision: 'supervised',
              divesCount: 10,
            },
            price: (60 - 5) * 10,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'equipped',
              supervision: 'autonomous',
              divesCount: 20,
            },
            price: (45 - 8) * 20,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'not-equipped',
              supervision: 'autonomous',
              divesCount: 20,
            },
            price: (50 - 8) * 20,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'equipped',
              supervision: 'supervised',
              divesCount: 20,
            },
            price: (55 - 8) * 20,
          },
          {
            diveAttributes: {
              successiveDivesCount: undefined,
              equipment: 'not-equipped',
              supervision: 'supervised',
              divesCount: 20,
            },
            price: (60 - 8) * 20,
          },
        ],
      },
    };
    const training: GenerateOffersTrainingOptions = {
      enabled: false,
      offers: [],
    };
    const options: GenerateOffersOptions = {
      divePlan,
      training,
    };
    return options;
  };
