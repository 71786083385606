/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { usePageWidth } from 'src/business/_core/modules/layout/services/usePageWidth.hook';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppInputBoolean } from 'src/lib/form';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  BookingTabModel,
  BookingTabSingleBookingAutoFixSuggestionsSplitBooking,
  BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk,
} from '../../../models';
import { bookingPageUpdateStateBookingSplitter } from '../../../services/02.update-state/services';
import { DiverBookingPageActions } from '../../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../../useDiverBookingPageGlobalState.hook';

const showComment = appWebConfig.envId === 'dev' && false;

export const DiverBookingPageBookingTabHeaderAutoFixSplitBookings = ({
  globalState,
  dialogs,
  actions,
  splitBookings: autoSplitBooking,
  bookingTabModel,
  setIsExpanded,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  splitBookings: BookingTabSingleBookingAutoFixSuggestionsSplitBooking;
  bookingTabModel: BookingTabModel;
  setIsExpanded: (isOpen: boolean) => void;
}) => {
  const pageWidth = usePageWidth({ ignoreMenu: false });

  const {
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
  } = globalState;

  const {
    openDiverSelector,
    openSessionDialog,
    openSessionSelector,
    createNewBooking,
    deleteDiverOnly,
  } = actions;

  const [splitBookings, setSplitBookings] = useState(autoSplitBooking);

  const toggleArchive = useCallback(
    ({ chunkIndex }: { chunkIndex: number }) => {
      const bookingsChunks: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk[] =
        splitBookings.bookingsChunks.map((sb, i) => {
          if (i === chunkIndex) {
            return {
              ...sb,
              active: !sb.active,
            };
          }
          return sb;
        });
      setSplitBookings({
        ...splitBookings,
        bookingsChunks,
      });
    },
    [splitBookings],
  );

  useMemo(() => {
    // reset local changes
    setSplitBookings(autoSplitBooking);
  }, [autoSplitBooking]);

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;

  const applyAutoFixNow = useCallback(() => {
    let updateStateLocal = updateState;
    // split booking
    updateStateLocal = bookingPageUpdateStateBookingSplitter.splitBookings({
      clubReference,
      diveCenterId,
      aggregatedData,
      updateState: updateStateLocal,
      bookingTabModel,
      bookingsChunks: splitBookings?.bookingsChunks,
      publicSettings: diveCenterResume?.clubResume.clubSettings.publicSettings,
    });
    setIsExpanded(false); // on ferme la réservation initiale
    setUpdateState(updateStateLocal, {
      action: 'auto-fix split bookings',
      meta: {
        diveCenterId,
        bookingsChunks: splitBookings?.bookingsChunks?.length,
      },
    });
  }, [
    aggregatedData,
    bookingTabModel,
    clubReference,
    diveCenterId,
    diveCenterResume?.clubResume.clubSettings.publicSettings,
    setIsExpanded,
    setUpdateState,
    splitBookings?.bookingsChunks,
    updateState,
  ]);

  return !!splitBookings ? (
    <AppMessage
      className="my-5"
      type="info"
      isExpanded={false}
      title={'Grand nombre de plongées pour cette réservation'}
      message={
        <h3 className="text-sm sm:text-base">
          Mabadive peut automatiquement la découper ainsi:
        </h3>
      }
    >
      <table
        className={
          'w-full max-w-screen-sm divide-y divide-gray-200 border-2 border-gray-400'
        }
      >
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Période
            </th>
            <th
              scope="col"
              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Plongées
            </th>
            {bookingTabModel.aggregatedBooking.bookingMembers.length > 1 && (
              <th
                scope="col"
                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Plongeurs
              </th>
            )}
            {showComment && (
              <th
                scope="col"
                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Commentaire
              </th>
            )}
            <th
              scope="col"
              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Archivé
            </th>
          </tr>
        </thead>
        <tbody className="bg-gray-100 divide-y divide-gray-200">
          {splitBookings.bookingsChunks.map(
            (
              {
                minDate,
                maxDate,
                bookingMembersFull,
                participantsBySessions,
                active,
                comment,
              },
              chunkIndex,
            ) => (
              <tr key={chunkIndex}>
                <td className="px-2 py-1 whitespace-nowrap text-sm font-medium">
                  {dateService.formatUTC(minDate, 'DD/MM/YYYY')} -{' '}
                  {dateService.formatUTC(maxDate, 'DD/MM/YYYY')}
                </td>
                <td className="px-2 py-1 whitespace-nowrap text-sm font-medium">
                  {participantsBySessions.length}
                </td>
                {bookingTabModel.aggregatedBooking.bookingMembers.length >
                  1 && (
                  <td className="px-2 py-1 whitespace-nowrap text-sm font-medium">
                    <span className="font-bold">
                      {bookingMembersFull.length} plongeurs
                    </span>
                    <span className="hidden md:inline text-xs">
                      {': '}
                      {bookingMembersFull
                        .map(
                          (bm, memberIndex) =>
                            nameFormatter.formatFirstName(bm.diver) ??
                            `n°${memberIndex + 1}`,
                        )
                        .join(', ')}
                    </span>
                  </td>
                )}
                {showComment && (
                  <td className="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-400">
                    {comment}
                  </td>
                )}
                <td className="px-2 py-1 whitespace-nowrap text-sm font-medium">
                  <AppInputBoolean
                    type="checkbox"
                    value={!active}
                    onChange={(value) => {
                      toggleArchive({
                        chunkIndex,
                      });
                    }}
                  />
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
      <div className="mt-5 text-left">
        <AppButton
          className="font-bold"
          style={{ minWidth: '6rem' }}
          size="normal"
          color={'primary-bg'}
          icon={AppHeroIcons.actionAdd}
          onClick={() => applyAutoFixNow()}
        >
          Découper la réservation
        </AppButton>
      </div>
    </AppMessage>
  ) : null;
};
