/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingParticipantEditorFormModel } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { COUNTRY_OPTIONS } from '../COUNTRY_OPTIONS.const';

export const BookingMemberGeneralEditFormAddress = ({
  form,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;

  const { control, setValue } = form;

  return !customerSettings?.general?.homeAddress ? null : (
    <div
      className={`w-full flex flex-col sm:col-span-2 md:col-span-4 sm:grid sm:grid-cols-4 gap-4 ${className}`}
    >
      <AppFormControlRHF_Deprecated
        className={'sm:col-span-4 w-full'}
        label="Adresse postale"
        control={control}
        name={'diver.homeAddress.line1' as const}
        renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
      />
      <div className={'sm:col-span-2 w-full flex flex-row gap-4'}>
        <AppFormControlRHF_Deprecated
          label="Code postal"
          control={control}
          name={'diver.homeAddress.postalCode' as const}
          renderComponent={(props) => (
            <AppInputRHF {...props} className={'w-24'} />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Ville"
          control={control}
          name={'diver.homeAddress.city' as const}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
      </div>
      <div className={'sm:col-span-2 w-full flex flex-row gap-4'}>
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="État"
          control={control}
          name={'diver.homeAddress.state' as const}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Pays"
          control={control}
          name={'diver.homeAddress.country' as const}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={COUNTRY_OPTIONS}
              fullWidth
            />
          )}
        />
      </div>
    </div>
  );
};
