/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import {
  useClubResume,
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesOffersPricesContextMode } from '../_model';
import { ClubSettingsServicesViewPanelOffersPricesListItem } from './ClubSettingsServicesViewPanelOffersPricesListItem';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export const ClubSettingsServicesViewPanelOffersPricesList = ({
  localState,
  contextMode,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
  contextMode: ClubSettingsServicesOffersPricesContextMode;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const publicSettings = useClubSettings()?.publicSettings;
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();

  const offers = localState.data.offersFiltered;

  return offers.length === 0 ? null : (
    <div className="bg-gray-50 p-1 max-h-[70vh] grid gap-y-1 gap-x-1 lg:grid-cols-2">
      {offers.map((offer) => (
        <ClubSettingsServicesViewPanelOffersPricesListItem
          key={offer.reference}
          offer={offer}
          localState={localState}
          contextMode={contextMode}
        />
      ))}
    </div>
  );
};
