import { DiveCenterDailyConfig } from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { map } from 'rxjs/operators';
import {
  LoadableContent,
  LoadableContentActions,
  LoadableContentLoadingMode,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import { dailyConfigRepository } from 'src/business/_core/data/store-repository';
import {
  DiveSessionResumeLoadBounds,
  DiveSessionResumeLoadCriteria,
} from 'src/business/_core/data/store-repository/dive-sessions/model';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
export function useCalendarWeekDailyConfigs({
  criteria,
  mode,
}: {
  criteria: DiveSessionResumeLoadBounds;
  mode: LoadableContentLoadingMode;
}): LoadableContent<DiveCenterDailyConfig[]> & LoadableContentActions {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const loadCriteria: DiveSessionResumeLoadCriteria = useMemo(() => {
    if (clubReference && diveCenterId && criteria) {
      return {
        ...criteria,
        clubReference,
        diveCenterId,
      };
    }
  }, [clubReference, criteria, diveCenterId]);
  return useLoadableContent(
    () =>
      dailyConfigRepository
        .findManyWithSubscription(loadCriteria, {
          mode,
        })
        .pipe(
          map(({ content: dailyConfigs, ...x }) => {
            const sortedDailyConfigs = dataSorter.sortMultiple(dailyConfigs, {
              getSortAttributes: (x) => [
                {
                  value: x.date,
                },
              ],
            });

            return {
              ...x,
              content: sortedDailyConfigs,
            };
          }),
        ),
    [loadCriteria, mode],
    {
      debugName: 'useCalendarWeekClubEvents',
      useSnapshot: false,
    },
  );
}
