import {
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE,
  ClubStaffMemberDailyAvailabilityPeriod,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import {
  AppIconElement,
  AppIconsUI,
} from '../../../../../../../../../../../_core/modules/layout/icons';

export function AvailabilityPeriodLabel({
  period,
  icon: Icon,
  colorizeText = true,
  iconClassName: iconClassNameInput,
  className,
}: {
  period: ClubStaffMemberDailyAvailabilityPeriod;
  icon?: AppIconElement;
  colorizeText?: boolean;
  iconClassName?: string;
  className?: string;
}) {
  const textColorClassName =
    period === 'day'
      ? 'text-status-success bg-white'
      : period === 'am'
      ? 'text-app-primary bg-white'
      : period === 'pm'
      ? 'text-app-secondary bg-white'
      : 'text-status-error bg-white';

  const isAvailablePeriod =
    CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE.includes(period);

  const iconClassName =
    iconClassNameInput ??
    clsx('inline-block h-4 w-4 bg-white', textColorClassName);

  return isAvailablePeriod ? (
    <div className={clsx(colorizeText && textColorClassName, className)}>
      {Icon ? (
        <Icon className={iconClassName} />
      ) : (
        <AppIconsUI.statusValidated className={iconClassName} />
      )}{' '}
      {period === 'day' ? (
        <>Dispo</>
      ) : period === 'am' ? (
        <>
          <span className="inline 2xl:hidden">AM</span>
          <span className="hidden 2xl:inline">Matin</span>
        </>
      ) : period === 'pm' ? (
        <>
          <span className="inline 2xl:hidden">PM</span>
          <span className="hidden 2xl:inline">Après-midi</span>
        </>
      ) : null}
    </div>
  ) : period === 'none' ? (
    <div className={clsx(colorizeText && textColorClassName, className)}>
      {Icon ? (
        <Icon className={iconClassName} />
      ) : (
        <AppIconsUI.statusArchived className={iconClassName} />
      )}{' '}
      Absent
    </div>
  ) : null;
}
