import { AppCompanyMessageTarget } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';

export function AppMessagePreviewWhatsapp({
  title,
  body,
  signature,
  className,
}: {
  title?: string;
  body?: string;
  signature?: string;
  className?: string;
}) {
  const target: AppCompanyMessageTarget = 'whatsapp';

  const bodyLines = makeLines({ target, content: body });

  const signatureLines = makeLines({ target, content: signature });

  return title || bodyLines?.length > 0 || signatureLines?.length > 0 ? (
    <div className={clsx('grid gap-4', className)}>
      {title && <div>{title}</div>}
      {bodyLines.length > 0 && (
        <div>
          {bodyLines.map((x, i) => (
            <div key={i}>{x} &nbsp;</div>
          ))}
        </div>
      )}
      {signatureLines.length > 0 && (
        <div>
          {signatureLines.map((x, i) => (
            <div key={i}>{x} &nbsp;</div>
          ))}
        </div>
      )}
    </div>
  ) : null;
}
function makeLines({
  target,
  content,
}: {
  target: AppCompanyMessageTarget;
  content: string;
}) {
  return target === 'sms'
    ? content
      ? [content]
      : []
    : content?.split('\n') ?? [];
}
