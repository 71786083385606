import { DashboardBookingsByPeriod } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { ClubDashboardGraphqlQueryByIntervalCriteriaStepInterval } from 'src/pages/DA-dashboard/_core/model';
import { chartHelper } from 'src/pages/DA-dashboard/_core/services';
import { EvolutionActivityBookingsByDayBarChartAttribute } from '../model';

type ChartAttribute = EvolutionActivityBookingsByDayBarChartAttribute;

export const evolutionActivityBookingsByDayBarChartTooltipBuilder = { build };

function build({
  colors,
  stepInterval,
  className,
}: {
  colors: { [attr in ChartAttribute]: string };
  stepInterval: ClubDashboardGraphqlQueryByIntervalCriteriaStepInterval;
  className?: string;
}) {
  return ({
    active,
    payload: data,
    label,
  }: {
    active?: boolean;
    payload?: any[];
    label?: any;
  }) => {
    if (active && data && data.length) {
      const payload: DashboardBookingsByPeriod = data[0].payload;
      const bookingParticipantsCount = payload.bookingParticipantsCount;
      const bookingParticipantsCancelledCount =
        payload.bookingParticipantsCancelledCount;
      const bookingParticipantsTotalCount =
        bookingParticipantsCount + bookingParticipantsCancelledCount;
      const averageCancel =
        bookingParticipantsTotalCount > 0
          ? Math.round(
              (100 * bookingParticipantsCancelledCount) /
                bookingParticipantsTotalCount,
            )
          : 0;
      return (
        <div
          className={clsx(
            'overflow-hidden rounded-lg bg-white text-gray-600 p-2 shadow',
            className,
          )}
        >
          <p className="text-xl font-bold">
            {chartHelper.formatDatePeriod(payload.beginDate, {
              stepInterval,
              format: 'long',
            })}
          </p>
          <div className="mt-2">
            <b>Réservations: </b> :{' '}
            <span
              className="font-bold"
              style={{ color: colors['bookingParticipantsCount'] }}
            >
              {bookingParticipantsTotalCount} prestations
            </span>{' '}
            {bookingParticipantsCancelledCount > 0 && (
              <div className="mt-1 ">
                Dont{' '}
                <span
                  className="font-bold"
                  style={{
                    color: colors['bookingParticipantsCancelledCount'],
                  }}
                >
                  {bookingParticipantsCancelledCount}{' '}
                  {bookingParticipantsCancelledCount === 1
                    ? 'annulation'
                    : 'annulations'}{' '}
                </span>{' '}
                par la suite (
                <span
                  className="font-bold"
                  style={{
                    color: colors['bookingParticipantsCancelledCount'],
                  }}
                >
                  {averageCancel}%
                </span>
                )
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };
}
