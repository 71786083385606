/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveSessionResumeParticipantsByGroup,
} from '@mabadive/app-common-model';
import {
  dateService,
  diveSessionGroupBuilder,
  diveSessionStatusFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { IoPeople } from 'react-icons/io5';
import { diveSessionParticipantStatsBuilder } from 'src/business/club/data';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubBoatLabelShort } from '../../../../../../pages/_components/company/ClubBoat/ClubBoatLabelShort';
import { DiveSessionParticipantsStats } from '../../../club-dive-session/model';
import { DiveSessionSimpleCardTotalCounters } from '../../../club-planning-light/components';
import { ClubPlanningStaffWeekStaffPresenceLocalState } from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';

export const ClubPlanningStaffWeekStaffPresenceHeaderSessionsDaySession = ({
  localState,
  session,
  sessionIndex,
  className,
}: {
  localState: ClubPlanningStaffWeekStaffPresenceLocalState;
  session: ClubPlanningLightSessionDef;
  sessionIndex?: {
    index: number;
    total: number;
  };
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const staffMembers = diveCenterResume?.staffMembers;
  const { bookingSessionParticipants, participants, groups } = session;

  const diveSessionParticipantsByGroup: DiveSessionResumeParticipantsByGroup =
    useMemo(
      () =>
        diveSessionGroupBuilder.buildDiveSessionParticipantsGroups({
          diveSession: session,
          groups,
          participants,
          staffMembers,
          ignoreCancelledParticipants: true,
          ignoreGroupsWithoutParticipants: true,
          diveModesToAutoGroup: ['autoSupervised', 'theoretical-training'],
        }),
      [session, groups, participants, staffMembers],
    );

  const stats: DiveSessionParticipantsStats = useMemo(
    () =>
      diveSessionParticipantStatsBuilder.buildDiveSessionParticipantsStats({
        clubReference,
        session,
        participants: diveSessionParticipantsByGroup.all,
        groups: diveSessionParticipantsByGroup.allGroups,
        staffMembers: diveCenterResume?.staffMembers,
        dailyConfigs: localState.data.dailyConfigs,
        clubSettings: diveCenterResume?.clubResume?.clubSettings,
      }),
    [
      clubReference,
      diveCenterResume?.clubResume?.clubSettings,
      diveCenterResume?.staffMembers,
      diveSessionParticipantsByGroup.all,
      diveSessionParticipantsByGroup.allGroups,
      localState.data.dailyConfigs,
      session,
    ],
  );

  const isPast = useMemo(
    () =>
      !dateService.isTodayUTC(session.time) &&
      dateService.isPastUTC(session.time),
    [session.time],
  );

  const { timeDayPeriod, sessionsCount } = stats;

  const onClickSession =
    localState.actions.clickActions.onClickEditSessionDiveGroups;

  const clubResume = useClubResume();

  const boats = useMemo(() => {
    return session.boatsIds
      .map((boatId) => clubResume?.boats.find((b) => b._id === boatId))
      .filter((x) => !!x);
  }, [clubResume?.boats, session.boatsIds]);

  const clubBoat = boats.length > 0 ? boats[0] : null; // on affiche juste le premier

  return (
    <div
      className={clsx(
        'relative select-none text-gray-500 pl-px',
        `border-l-4 border-day-period-${timeDayPeriod}-dark`,
        'grid gap-px',
        'cursor-pointer',
        className,
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClickSession(session);
      }}
    >
      <div
        className={clsx(
          'px-px sm:px-0.5 flex gap-1 justify-between items-center',
          `bg-day-period-${timeDayPeriod}-dark text-white`,
          isPast && 'opacity-80',
        )}
      >
        <div className="flex-grow flex gap-1 justify-around items-center">
          <div
            className={clsx(
              'md:text-xs font-medium',
              sessionsCount > 1 ? 'text-app-xxs sm:text-xs ' : 'text-xs',
            )}
          >
            {dateService.formatUTC(session.time, 'HH:mm')}
          </div>
          {sessionsCount > 1 && (
            <div
              className={clsx(
                'text-app-xxs sm:text-xs font-bold',
                ' right-0 px-px bg-gray-500 text-white',
                'sm:absolute',
              )}
            >
              <span>x{sessionsCount}</span>
            </div>
          )}
        </div>

        <ClubBoatLabelShort
          className="mt-1 hidden sm:flex"
          iconClassName="hidden md:block w-4 h-4"
          clubBoat={clubBoat}
        />
      </div>
      <div className="bg-white text-xs font-bold px-1 flex gap-0.5 sm:gap-1 justify-start items-center text-center border-r-[1px] border-r-gray-400">
        <IoPeople className="hidden sm:flex w-3 h-3 text-gray-500" />
        <DiveSessionSimpleCardTotalCounters
          diveSessionParticipantsStats={stats}
        />
        {(session.status === 'cancelled' || session.status === 'off') && (
          <div
            className={clsx(
              'ml-auto font-bold uppercase',
              session.status === 'off' && 'text-gray-800',
              session.status === 'cancelled' && 'text-status-error',
            )}
          >
            {diveSessionStatusFormatter.formatStatus(session.status)}
          </div>
        )}
      </div>
    </div>
  );
};
