/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingDeposit } from '@mabadive/app-common-model';
import React, { useMemo, useState } from 'react';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BookingDepositCard } from '../../components/BookingDepositCard';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { useDiverBookingPageBillingTabDepositCardMenuItems } from './useDiverBookingPageBillingTabDepositCardMenuItems.hook';

export const DiverBookingPageBillingTabDepositCard = ({
  bookingDeposit,
  globalState,
  dialogs,
  actions,
  index,
  total,
  className,
}: {
  bookingDeposit: BookingDeposit;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  index: number;
  total: number;
  className?: string;
}) => {
  const loadedDivers = globalState.aggregatedData.divers;

  const diver = useMemo(
    () => (loadedDivers ?? []).find((d) => d._id === bookingDeposit.diverId),
    [loadedDivers, bookingDeposit.diverId],
  );
  const isExpandedByDefault = useMemo(() => {
    return (
      index < 3 ||
      bookingDeposit.depositState === 'draft' ||
      bookingDeposit.depositState === 'active'
    );
    // if (mediaSize === 'xxs' || mediaSize === 'xs') {
    //   return false;
    // } else if (mediaSize === 'sm' || mediaSize === 'md') {
    //   return paymentPackages.length <= 1;
    // } else {
    //   return paymentPackages.length <= 3;
    // }
    // return false;
  }, [index, bookingDeposit.depositState]);

  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
  const hamburgerMenuItems: AppHamburgerMenuItem[] =
    useDiverBookingPageBillingTabDepositCardMenuItems({
      actions,
      dialogs,
      globalState,
      isExpanded,
      diver,
      bookingDeposit,
      setIsExpanded,
    });
  return (
    <BookingDepositCard
      className={className}
      bookingDeposit={bookingDeposit}
      loadedDivers={globalState.aggregatedData.divers}
      hamburgerMenuItems={hamburgerMenuItems}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    />
  );
};
