/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppMessageSingleLine } from 'src/business/_core/modules/layout';

export const ClubSettingsSectionComment = ({
  children,
}: {
  children?: React.ReactNode | React.ReactNode[];
}) => {
  return !children ? null : (
    <AppMessageSingleLine type="info">{children}</AppMessageSingleLine>
  );
};
