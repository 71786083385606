/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  DIVE_CERTIFICATIONS,
  DIVE_CERTIFICATIONS_REFERENCE_MAP,
  DIVE_SERVICE_ORGANIZATIONS,
  DiveCertificationReference,
  DiveServiceOrganizationReference,
  GAZ_DIVE_CERTIFICATIONS_REFERENCES,
  GazDiveCertificationReference,
} from '@mabadive/app-common-model';
import { certificationFormatter } from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppComboBoxMultipleRHF, AppInputBooleanRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { DIVE_SERVICE_ORGANIZATIONS_OPTIONS } from './DIVE_SERVICE_ORGANIZATIONS_OPTIONS.const';
import { SAFETY_CERTIFICATION_OPTIONS } from './SAFETY_CERTIFICATION_OPTIONS.const';
import { mainCertificationOptionBuilder } from './mainCertificationOptionBuilder.service';

export const BookingMemberDiverEditFormOrgCertification = ({
  form,
  baseControlName,
  certificationIndex,
  certificationsCount,
  className = '',
  onClickAdd,
  onClickRemove,
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  baseControlName: 'diver.divingCertification1' | 'diver.divingCertification2';
  certificationIndex: number;
  certificationsCount: number;
  onClickAdd?: () => void;
  onClickRemove?: () => void;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const customerSettings = clubResume.clubSettings?.customer;

  const { control, setValue } = form;

  const mainCertificationReference = useWatch({
    control,
    name: `${baseControlName}.mainCertificationReference`,
  });

  const orgGroup = useWatch({
    control,
    name: `${baseControlName}.orgGroup`,
  });

  const mediaSize = useMediaSizeTailwind();

  const nitroxCertificationOptions = useMemo(() => {
    const nitroxCertificationsRefs: GazDiveCertificationReference[] = [
      ...GAZ_DIVE_CERTIFICATIONS_REFERENCES,
    ];

    if (
      clubResume.reference === 'topdive-polynesie-24580' ||
      clubResume.reference === 'activiseas-tahiti-62021'
    ) {
      nitroxCertificationsRefs.push('NXS-TD');
    }

    return nitroxCertificationsRefs.map((reference) => {
      const option: ValueLabel<DiveCertificationReference> = {
        label: certificationFormatter.formatCertificationReference(reference, {
          format: 'ref-name',
        }),
        value: reference,
      };
      return option;
    });
  }, [clubResume.reference]);

  const onChangeOrgReference = useCallback(
    (orgReference: DiveServiceOrganizationReference) => {
      if (orgReference) {
        const org = DIVE_SERVICE_ORGANIZATIONS.find(
          (o) => o.reference === orgReference,
        );
        if (org && orgGroup !== org.orgGroupReferencev2) {
          setValue(`${baseControlName}.orgGroup`, org.orgGroupReferencev2);
          if (orgGroup !== 'international') {
            setValue(`${baseControlName}.specialties.deepDiver`, null);
          }
          if (mainCertificationReference) {
            const mainCertification = DIVE_CERTIFICATIONS.find(
              (mc) => mc.reference === mainCertificationReference,
            );
            if (
              mainCertification?.organizationv2?.orgGroupReferencev2 !==
              org.orgGroupReferencev2
            ) {
              setValue(`${baseControlName}.mainCertificationReference`, null);
            }
          }
        }
      } else {
        if (!mainCertificationReference) {
          setValue(`${baseControlName}.orgGroup`, null);
          setValue(`${baseControlName}.specialties.deepDiver`, null);
        }
      }
    },
    [baseControlName, mainCertificationReference, orgGroup, setValue],
  );

  const onChangeCertification = useCallback(
    (mainCertificationReference: DiveCertificationReference) => {
      if (!orgGroup && mainCertificationReference) {
        const mainCertification =
          DIVE_CERTIFICATIONS_REFERENCE_MAP[mainCertificationReference];
        if (mainCertification) {
          setValue(
            `${baseControlName}.orgGroup`,
            mainCertification.organizationv2?.orgGroupReferencev2,
          );
          if (orgGroup !== 'international') {
            setValue(`${baseControlName}.specialties.deepDiver`, null);
          }
        }
      }
    },
    [baseControlName, orgGroup, setValue],
  );

  const mainCertificationOptions = useMemo(
    () =>
      mainCertificationOptionBuilder.buildOptions({
        orgGroupReferencev2: orgGroup,
      }),
    [orgGroup],
  );

  return (
    <div
      className={`pr-2 border-r-2 pt-2 border-t-0 border-dotted border-blue-300 flex flex-col sm:flex-row gap-2 relative ${className}`}
    >
      {onClickAdd && (
        <AppHeroIcons.actionAdd
          onClick={onClickAdd}
          className="absolute -top-3 -right-3 p-1 w-6 h-6 bg-gray-400 hover:bg-gray-600 rounded-full text-white cursor-pointer"
        />
      )}
      {onClickRemove && (
        <AppHeroIcons.actionRemove
          onClick={onClickRemove}
          className="absolute -top-3 -right-3 p-1 w-6 h-6 bg-gray-400 hover:bg-gray-600 rounded-full text-white cursor-pointer"
        />
      )}
      <AppFormControlRHF_Deprecated
        className={'sm:hidden w-full'}
        label={`Organisme${
          certificationsCount > 1 ? ` N°${certificationIndex}` : ''
        }`}
        control={control}
        name={`${baseControlName}.orgReference`}
        renderComponent={(props) => (
          <AppSingleSelect2HeadlessUIRHF
            className={'w-full'}
            theme="material-ui"
            color="theme"
            {...props}
            options={DIVE_SERVICE_ORGANIZATIONS_OPTIONS}
            onChange={(e) => onChangeOrgReference(e)}
          />
        )}
      />
      <div className="sm:w-1/2 flex gap-2">
        <AppFormControlRHF_Deprecated
          className={'hidden sm:flex w-28'}
          label={`Organisme${
            certificationsCount > 1 ? ` N°${certificationIndex}` : ''
          }`}
          control={control}
          name={`${baseControlName}.orgReference`}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              className={'w-full'}
              theme="material-ui"
              color="theme"
              {...props}
              options={DIVE_SERVICE_ORGANIZATIONS_OPTIONS}
              onChange={(e) => onChangeOrgReference(e)}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="flex-grow w-72"
          label={`Niveau / certification${
            certificationsCount > 1 ? ` N°${certificationIndex}` : ''
          }`}
          control={control}
          name={`${baseControlName}.mainCertificationReference`}
          required={false}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              optionClassName="text-xs sm:text-sm"
              {...props}
              options={mainCertificationOptions}
              onChange={(value: DiveCertificationReference) => {
                onChangeCertification(value);
              }}
            />
          )}
        />
        {orgGroup === 'international' && (
          <AppFormControlRHF_Deprecated
            label={
              mediaSize === 'xxs' ||
              mediaSize === 'xs' ||
              mediaSize === 'sm' ||
              mediaSize === 'md'
                ? 'Deep D.'
                : 'Deep Diver'
            }
            // disabled={!gazCertificationReference}
            control={control}
            name={`${baseControlName}.specialties.deepDiver`}
            renderComponent={(props) => (
              <AppInputBooleanRHF
                className="scale-125 block mx-auto mt-2"
                {...props}
                type="checkbox"
              />
            )}
          />
        )}
      </div>
      <div className="sm:w-1/2 md:col-span-2 flex gap-2">
        {customerSettings?.diving?.nitrox && (
          <AppFormControlRHF_Deprecated
            className="flex-grow w-60 xl:max-w-xs"
            label="Nitrox"
            control={control}
            name={`${baseControlName}.gazCertificationReference`}
            required={false}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                className={'w-full'}
                theme="material-ui"
                color="theme"
                {...props}
                options={nitroxCertificationOptions}
                // onChange={(value: DiveCertificationReference) => {
                //   if (!value && !!nitroxCertificationChecked) {
                //     // force to uncheck
                //     // setValue(
                //     //   'diver.divingDetails.nitroxCertification.checked',
                //     //   false,
                //     // );
                //   }
                // }}
              />
            )}
          />
        )}
        {customerSettings?.diving?.safety && (
          <AppFormControlRHF_Deprecated
            className="flex-grow w-60 xl:max-w-xs"
            label="Secourisme"
            control={control}
            name={`${baseControlName}.safetyCertificationReferences`}
            required={false}
            renderComponent={(props) => (
              <AppComboBoxMultipleRHF
                options={SAFETY_CERTIFICATION_OPTIONS}
                {...props}
              />
              // <AppSingleSelect2HeadlessUIRHF
              //   className={'w-full'}
              //   theme="material-ui"
              //   color="theme"
              //   {...props}
              //   options={SAFETY_CERTIFICATION_OPTIONS}
              // />
            )}
          />
        )}

        <AppFormControlRHF_Deprecated
          label="Vérifié"
          control={control}
          disabled={!mainCertificationReference}
          name={`${baseControlName}.checkedByClub` as any}
          renderComponent={(props) => (
            <AppInputBooleanRHF
              className="scale-125 block mx-auto mt-2"
              {...props}
              type="checkbox"
            />
          )}
        />
      </div>
    </div>
  );
};
