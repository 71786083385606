import { arrayBuilder, dateService } from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  BookingResumeParticipantsBySession,
  BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk,
  PRO_BookingMemberFull,
} from '../../../../models';

export const bookingChunkSplitter = {
  splitChunkBySessionIndex,
};

function splitChunkBySessionIndex({
  chunk,
  chunk2FirstSessionIndex,
  bookingMembersFull,
  comment,
}: {
  chunk: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
  chunk2FirstSessionIndex: number;
  bookingMembersFull: PRO_BookingMemberFull[];
  comment: string;
}): {
  chunk1: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
  chunk2: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
} {
  const chunk1: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk =
    splitChunk({
      chunk,
      bookingMembersFull,
      startIndex: 0,
      endIndex: chunk2FirstSessionIndex,
      comment,
    });

  const chunk2: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk =
    splitChunk({
      chunk,
      bookingMembersFull,
      startIndex: chunk2FirstSessionIndex,
      endIndex: chunk.participantsBySessions.length,
      comment,
    });

  if (chunk1 && chunk2) {
    return { chunk1, chunk2 };
  }
  return { chunk1: null, chunk2: null };
}
function splitChunk({
  chunk,
  bookingMembersFull,
  startIndex,
  endIndex,
  comment,
}: {
  chunk: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
  bookingMembersFull: PRO_BookingMemberFull[];
  startIndex: number;
  endIndex: number;
  comment: string;
}): BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk {
  const participantsBySessions = chunk.participantsBySessions.slice(
    startIndex,
    endIndex,
  );
  if (participantsBySessions.length === 0) {
    appLogger.error(
      `Empty chunk: (${startIndex}-${endIndex} of ${chunk.participantsBySessions.length} sessions)`,
    );
    return null;
  }
  const chunk2BookingMembers = filterMemberForNewChunk({
    participantsBySessions: participantsBySessions,
    bookingMembersFull,
  });
  const firstSession = participantsBySessions[0];
  const lastSession = participantsBySessions[participantsBySessions.length - 1];

  const minDate = firstSession.bookingSessionFull.diveSession.time;
  const maxDate = lastSession.bookingSessionFull.diveSession.time;
  const newChunk: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk = {
    minDate,
    maxDate,
    bookingMembersFull: chunk2BookingMembers,
    participantsBySessions: participantsBySessions,
    active: chunk.active && dateService.getAgeInMonths(maxDate, new Date()) < 1,
    comment,
  };
  return newChunk;
}

function filterMemberForNewChunk({
  participantsBySessions,
  bookingMembersFull,
}: {
  participantsBySessions: BookingResumeParticipantsBySession[];
  bookingMembersFull: PRO_BookingMemberFull[];
}): PRO_BookingMemberFull[] {
  const bookingMembersIds = arrayBuilder.filterDuplicated(
    participantsBySessions.reduce(
      (acc, x) =>
        acc.concat(
          x.participants
            .filter((x) => !!x.bookingParticipantFull)
            .map((y) => y.bookingMemberFull.bookingMember._id),
        ),
      [] as string[],
    ),
  );
  return bookingMembersFull.filter((b) =>
    bookingMembersIds.includes(b.bookingMember._id),
  );
}
