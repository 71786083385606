/* eslint-disable @typescript-eslint/no-unused-vars */
import { Booking, BookingJourneyStep } from '@mabadive/app-common-model';
import { jsonParser } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { bookingJourneyStepBuilder } from '../../../_services';
import { BookingGroupAndJourneyEditDialogFormModel } from './model';
export function useBookingGroupAndJourneyEditDialogLocalState({
  initialBooking,
}: {
  initialBooking: Pick<
    Booking,
    '_id' | 'bookingJourney' | 'bookingGroup' | 'bookingStatus' | 'details'
  >;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const initialValue = useMemo(() => {
    const initialValue: BookingGroupAndJourneyEditDialogFormModel =
      jsonParser.parseJSONWithDates(JSON.stringify(initialBooking)); // deep copy
    if (!initialValue.bookingJourney) {
      initialValue.bookingJourney = {
        steps: [],
      };
    }
    if (!initialValue.bookingJourney.steps) {
      initialValue.bookingJourney.steps = [];
    }
    if (initialValue.bookingJourney.steps.length === 0) {
      const initialJourneyStep: BookingJourneyStep =
        bookingJourneyStepBuilder.buildDefaultJourneyStep({ diveCenterId });
      initialValue.bookingJourney.steps.push(initialJourneyStep);
    }

    return initialValue;
  }, [diveCenterId, initialBooking]);

  const form = useForm<BookingGroupAndJourneyEditDialogFormModel>({
    defaultValues: initialValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  return { form, hasFormChanges, initialBooking };
}

export type BookingGroupAndJourneyEditDialogLocalState = ReturnType<
  typeof useBookingGroupAndJourneyEditDialogLocalState
>;
