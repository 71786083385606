/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubStaffMember,
  DiveSessionResumeParticipant,
  DiveSessionStaffMemberTableModelStaffMember,
} from '@mabadive/app-common-model';
import {
  nameFormatter,
  staffMemberFormatter,
} from '@mabadive/app-common-services';
import React from 'react';
import { StaffLevelBadge } from 'src/business/_core/modules/layout';
import { DiveGuideLevelBadge } from 'src/business/_core/modules/layout/components/DiveModeBadge/DiveGuideLevelBadge';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export const staffMemberOptionsBuilder = {
  buildGroupGuidesOptions,
  buildDivingInstructorsStaffOptions,
  buildStaffMemberOptions,
  buildStaffMemberOptionsString,
};

function buildGroupGuidesOptions(
  groupGuides: DiveSessionResumeParticipant[],
): ValueLabel<string, React.ReactNode>[] {
  return groupGuides.map((participant) => {
    const option: ValueLabel<string, React.ReactNode> = {
      value: participant._id,
      label: (
        <div className="flex gap-1">
          <DiveGuideLevelBadge
            className="px-0.5 py-0.5 w-12 text-xs"
            participant={participant}
          />
          {nameFormatter.formatFullName(participant.diver)}
        </div>
      ),
    };
    return option;
  });
}

function buildDivingInstructorsStaffOptions({
  staffMembersFull,
  date,
}: {
  staffMembersFull: DiveSessionStaffMemberTableModelStaffMember[];
  date: Date;
}): ValueLabel<string, React.ReactNode>[] {
  return buildStaffMemberOptions({
    staffMembers: staffMembersFull
      .filter(
        (x) =>
          x.meta.available &&
          x.meta.availableRoles.includes('scuba-diving-instructor'),
      )
      .map((x) => x.staffMember),
    date,
  });
}

function buildStaffMemberOptions({
  staffMembers,
  date,
}: {
  staffMembers: Pick<
    ClubStaffMember,
    '_id' | 'scubaDivingInstructor' | 'profile' | 'availabilities'
  >[];
  date: Date;
}): ValueLabel<string, React.ReactNode>[] {
  return staffMembers.map((staffMember) => {
    const option: ValueLabel<string, React.ReactNode> = {
      value: staffMember._id,
      label: (
        <div className="flex gap-1">
          <StaffLevelBadge
            className="rounded px-0.5 py-0.5 w-12 text-xs"
            staffMember={staffMember}
            date={date}
          />
          {staffMemberFormatter.formatStaffMember(staffMember, {
            format: 'name',
          })}
        </div>
      ),
    };
    return option;
  });
}

function buildStaffMemberOptionsString({
  staffMembers,
}: {
  staffMembers: Pick<
    ClubStaffMember,
    '_id' | 'scubaDivingInstructor' | 'profile' | 'availabilities'
  >[];
}): ValueLabel<string, string>[] {
  return staffMembers.map((staffMember) => {
    const option: ValueLabel<string, string> = {
      value: staffMember._id,
      label: staffMemberFormatter.formatStaffMember(staffMember, {
        format: 'name-diving-level',
      }),
    };
    return option;
  });
}
