import { ClubProductPackageOfferView } from '@mabadive/app-common-model';
import { productPackageFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  useClubResume,
  useClubSettings,
} from '../../../business/club/data/hooks';

export function useProductPackageOffersOptions(
  offers: ClubProductPackageOfferView[],
): ValueLabel<string, string>[] {
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = useClubSettings();
  return useMemo(
    () =>
      offers.map((offer) => {
        const nameDetails = productPackageFormatter.formatNameDetails(
          offer.productPackage,
          { publicSettings, diveModesConf: clubSettings?.ui?.diveMode },
        );
        const label = [
          nameDetails.prefix,
          nameDetails.name,
          nameDetails.label,
          nameDetails.suffix,
        ]
          .filter(Boolean)
          .join(' - ');
        return {
          value: offer._id,
          label,
        };
      }),
    [clubSettings?.ui?.diveMode, offers, publicSettings],
  );
}
