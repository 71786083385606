import {
  AppBooklet,
  AppBookletPage,
  ClubEcommerceProductArticle,
  ClubEcommerceProductArticleDetails,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  uuidGenerator,
} from '@mabadive/app-common-services';
import {
  EcommerceProductArticleEditorFormModel,
  EcommerceProductArticleEditorFormModelDefaultValue,
} from '../model';

const defaultBooklet: Partial<AppBookletPage> = {
  mainContent: {
    languageCode: 'fr',
    content: {
      title: '',
      items: [],
    },
    cover: {},
  },
};

export const ecommerceProductArticleEditorformBuilder = {
  buildInitialFormValue: buildInitialNewProductArticle,
};

function buildInitialNewProductArticle({
  defaultValue,
}: {
  defaultValue: EcommerceProductArticleEditorFormModelDefaultValue;
}): Partial<EcommerceProductArticleEditorFormModel> {
  const bookletId = uuidGenerator.random();
  const appBookletPage = commonEntityBuilder.buildEntity<AppBookletPage>({
    ...defaultBooklet,
    bookletId,
    bookletType: 'ecommerce-product-article',
    level: 1,
    sortIndex: 1,
    visible: true,
  });
  const appBooklet = commonEntityBuilder.buildEntity<AppBooklet>({
    _id: bookletId,
    rootPageId: appBookletPage._id,
    bookletType: 'ecommerce-product-article',
  });
  const productArticle =
    commonEntityBuilder.buildEntity<ClubEcommerceProductArticle>({
      name: '',
      enabled: true,
      bookletId,
      ...(defaultValue.productArticle ?? {}),
      details: {
        minParticipantsCount: 1,
        maxParticipantsCount: 1,
        onlineBooking: {
          showButton: true,
        },
        ...(defaultValue.productArticle?.details ??
          ({} as ClubEcommerceProductArticleDetails)),
      },
    });
  console.log('xxx defaultValue:', defaultValue);
  console.log('xxx initial productArticle:', productArticle);
  const value: Partial<EcommerceProductArticleEditorFormModel> = {
    productArticle,
    appBookletPage,
    appBooklet,
  };
  return value;
}
