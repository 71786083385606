import { ClubParticipant, DiveSession } from '@mabadive/app-common-model';
import React, { FC, useMemo } from 'react';
import { DiveModeBadge } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';

export const ParticipantDiveCard: FC<{
  participant: Pick<
    ClubParticipant,
    | 'tags'
    | 'comment'
    | 'bookingState'
    | 'diveMode'
    | 'diveSessionGroupDiveGuide'
  >;
  diveSession: Pick<DiveSession, 'status' | 'time'>;
  theme?: 'normal' | 'light';
  isCancelledLineThrough?: boolean;
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode | React.ReactNode[];
}> = ({
  participant,
  diveSession,
  theme = 'normal',
  isCancelledLineThrough = false,
  className,
  onClick,
  children,
}) => {
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const diveSessionStatus = useMemo(
    () =>
      participant && participant.bookingState.value === 'cancelled'
        ? 'cancelled'
        : diveSession.status,
    [diveSession.status, participant],
  );

  return (
    <DiveModeBadge
      diveMode={
        diveSessionStatus !== 'cancelled'
          ? participant.diveSessionGroupDiveGuide
            ? 'instructor'
            : participant.diveMode
          : undefined
      }
      className={`text-xs font-medium 
        ${theme === 'light' ? 'bg-opacity-75' : ''}
        ${diveSessionStatus === 'cancelled' ? 'bg-gray-400' : ''}
        ${className ?? ''}
      `}
      clubReference={clubReference}
      onClick={onClick}
    >
      {children}
    </DiveModeBadge>
  );
};
