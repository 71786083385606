/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSession } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { GenericNavigationContext } from '../../../ClubParticipantNavigationContext/GenericNavigationContext.type';
import { DiverBookingPageAggregatedDataCore } from '../models';
import { DiverBookingPageLoadedContentFocus } from '../models/01.loaded-content';
import { DiverBookingPageUpdateState } from '../models/02.update-state';
import { BookingTabModel } from '../models/05.view-state';
import { DiverBookingPageActions } from '../useDiverBookingPageActions.hook';
import {
  DiverBookingPageGlobalState,
  DiverBookingPageSetUpdateStateFn,
} from '../useDiverBookingPageGlobalState.hook';
import { DiverBookingCard } from './DiverBookingCard';
import {
  DiverBookingPageBookingCardLocalState,
  useDiverBookingPageBookingCardLocalState,
} from './useDiverBookingPageBookingCardLocalState.hook';

export const DiverBookingPageBookingCard = ({
  enableAutoFix,
  globalState,
  dialogs,
  actions,
  bookingTabModel,
  aggregatedData,
  bookingTabModels,
  focus,
  updateState,
  setUpdateState,
  openSessionSelector,
  openSessionDialog,
  openDiverSelector,
  navigationContext,
}: {
  enableAutoFix: boolean;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  aggregatedData: DiverBookingPageAggregatedDataCore;
  bookingTabModels: BookingTabModel[];
  focus: DiverBookingPageLoadedContentFocus;
  updateState: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  openSessionSelector: (context: { bookingId: string }) => void;
  openSessionDialog: ({ diveSession }: { diveSession: DiveSession }) => void;
  openDiverSelector: ({ bookingId }: { bookingId: string }) => void;
  navigationContext: GenericNavigationContext;
}) => {
  const localState: DiverBookingPageBookingCardLocalState =
    useDiverBookingPageBookingCardLocalState({
      globalState,
      dialogs,
      bookingTabModel,
      aggregatedData,
      bookingTabModels,
      updateState,
      setUpdateState,
      openSessionDialog,
      openDiverSelector,
    });

  const { state, actions: localActions } = localState;

  return (
    <DiverBookingCard
      enableAutoFix={enableAutoFix}
      className={clsx(
        'mb-6 pb-6 border-b-4 border-dotted border-gray-300',
        state.isHidden && 'hidden',
      )}
      focus={focus}
      bookingTabModel={bookingTabModel}
      globalState={globalState}
      dialogs={dialogs}
      actions={actions}
      hasChanges={updateState.hasChanges}
      onClickParticipant={(x) =>
        localActions.onClickParticipant({
          ...x,
          bookingTabModel,
        })
      }
      onClickMember={(x) => localActions.onClickMember(x)}
      onClickBooking={(bookingTabModel, action) =>
        localActions.onClickBooking({
          bookingTabModel,
          action,
        })
      }
      onClickSession={(x) => localActions.onClickSession(x)}
      extraMenuItems={state.extraMenuItems}
    />
  );
};
