import { Popover, Transition } from '@headlessui/react';
import { Placement } from '@popperjs/core';
import React, { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppIcon } from 'src/business/_core/modules/layout/icons/AppIcon.type';
import { AppHamburgerMenuItem } from './model';

export function AppHamburgerMenu({
  header,
  children,
  childrenPosition = 'top',
  buttonLabel,
  buttonLabelClasses = 'hidden sm:inline-block',
  items = [],
  position,
  className = '',
  buttonClassName,
  ...otherProps
}: {
  header?: React.ReactNode;
  children?: React.ReactNode;
  childrenPosition?: 'top' | 'bottom';
  items: AppHamburgerMenuItem[];
  buttonLabel?: string;
  buttonLabelClasses?: string;
  position?: Placement;
  className?: string;
  buttonClassName?: string;
  buttonIcon?: AppIcon;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null);

  const mediaSize = useMediaSizeTailwind();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: mediaSize === 'xxs' || mediaSize === 'xs' ? 'auto' : position,
  });

  const filteredItems = useMemo(() => items.filter((x) => !x.hidden), [items]);

  //   const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  // const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  // const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)

  return !filteredItems?.length ? null : (
    <Popover as="nav" className={`inline-block ${className}`}>
      {({ open }) => (
        <>
          {/* // TODO use AppButton with "hamburger style" */}
          <button
            ref={setReferenceElement}
            onClick={(e) => {
              setIsOpen(!isOpen);
              e.stopPropagation();
            }}
            className={`px-1 inline-flex gap-1 items-center justify-center focus:outline-none
                  ${
                    buttonClassName ??
                    'bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white'
                  }
               `}
          >
            {isOpen ? (
              <AppHeroIcons.actionClose
                className="block h-6 w-6"
                aria-hidden="true"
              />
            ) : otherProps.buttonIcon ? (
              <otherProps.buttonIcon
                className="block h-6 w-6"
                aria-hidden="true"
              />
            ) : (
              <AppHeroIcons.menu className="block h-6 w-6" aria-hidden="true" />
            )}
            {buttonLabel && (
              <span className={buttonLabelClasses}>{buttonLabel}</span>
            )}
            <span className="sr-only">Ouvrir le menu</span>
          </button>

          <Transition show={isOpen}>
            <Popover.Overlay
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
              }}
              style={{
                zIndex: 1500, // > .makeStyles-drawerPaper-33 (left menu material-ui)
              }}
              className={`${
                isOpen
                  ? 'opacity-30 fixed top-0 bottom-0 left-0 right-0'
                  : 'opacity-0'
              } bg-black`}
            >
              <div
                className="fixed top-0 bottom-0 left-0 right-0"
                onClick={(e) => {
                  setIsOpen(false);
                  e.stopPropagation();
                }}
              ></div>
            </Popover.Overlay>
            <Popover.Panel
              static
              // tabIndex={-1}
              ref={setPopperElement}
              style={{
                ...(styles.popper ?? {}),
                zIndex: 1500, // > .makeStyles-drawerPaper-33 (left menu material-ui)
              }}
              {...(attributes.popper ?? {})}
              className={`
                max-w-screen-sm
                ${attributes.popper?.className ?? ''}
                px-1 sm:px-0
              `}
            >
              <AppHeroIcons.actionClose
                className={
                  'absolute cursor-pointer mr-1 sm:mr-0 p-2 top-0 right-0 z-50 w-8 h-8 sm:w-10 sm:h-10 bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white'
                }
                aria-hidden="true"
                onClick={(e) => {
                  setIsOpen(false);
                  e.stopPropagation();
                }}
              />
              <div
                className={
                  'min-w-[80vw] sm:min-w-[500px] text-left border border-gray-400 shadow-lg ring-1 ring-gray-600 ring-opacity-20 overflow-x-hidden overflow-y-auto bg-white'
                }
              >
                {children && childrenPosition === 'top' && children}
                {header && header}
                <ul className="relative grid p-2 gap-5 sm:gap-4 sm:p-4 md:gap-8 md:p-8">
                  {filteredItems.map((item, i) => (
                    <li
                      key={i}
                      className={`-m-1 p-1 sm:-m-3 sm:p-1 md:p-1 flex items-start rounded-lg transition ease-in-out duration-150 
                        ${
                          item.disableMessage
                            ? 'cursor-not-allowed hover:bg-gray-50'
                            : 'cursor-pointer hover:bg-blue-50'
                        }`}
                      id={`menu-${i}${item.id ? `-${item.id}` : ''}`}
                      onClick={(e) => {
                        if (!item.disableMessage) {
                          setIsOpen(false);
                          e.stopPropagation();
                          item.onClick && item.onClick();
                        }
                      }}
                    >
                      {item.icon && (
                        <item.icon
                          className={`flex-shrink-0 h-6 w-6 
                            ${
                              item.disableMessage
                                ? 'text-gray-400'
                                : item.severity === 'danger'
                                ? 'text-app-red'
                                : item.severity === 'warn'
                                ? 'text-app-orange'
                                : item.severity === 'success'
                                ? 'text-status-success'
                                : 'text-app-blue'
                            }
                          `}
                          aria-hidden="true"
                        />
                      )}
                      <div className="ml-2 sm:ml-4">
                        {item.title && (
                          <p
                            className={`font-medium text-xs sm:text-sm md:text-base
                          ${
                            item.disableMessage
                              ? 'text-gray-400'
                              : 'text-gray-900'
                          }
                        `}
                          >
                            {item.title}
                          </p>
                        )}
                        {item.disableMessage ? (
                          <p
                            className={
                              'hidden sm:flex mt-1 text-xs md:text-sm text-gray-400'
                            }
                          >
                            <AppHeroIcons.warn className="h-5 w-5 text-red-400 mr-2" />
                            {item.disableMessage}
                          </p>
                        ) : (
                          item.description && (
                            <p
                              className={
                                'hidden sm:block mt-1 text-xs md:text-sm text-gray-500'
                              }
                            >
                              {item.description}
                            </p>
                          )
                        )}
                        {item.details && <item.details />}
                      </div>
                    </li>
                  ))}
                </ul>
                {children && childrenPosition === 'bottom' && children}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
