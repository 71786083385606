/* eslint-disable @typescript-eslint/no-unused-vars */
import { PRO_AppDocResume } from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDialogsState } from 'src/pages/_dialogs';
import { AppDocViewPanelDoc } from './AppDocViewPanelDoc';

export const AppDocViewPanelDiver = ({
  bookingId,
  bookingMemberId,
  clubDiverId,
  dialogsState,
  docResumes: docResumesInput,
  className,
}: {
  bookingId: string;
  bookingMemberId: string;
  clubDiverId: string;
  dialogsState: ClubDialogsState;
  docResumes: PRO_AppDocResume[];
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;
  const clubSettings = diveCenterResume?.clubResume?.clubSettings;

  const openCreateAppDocDialog = useCallback(() => {
    dialogsState.appDocEditDialog.openDialog({
      bookingId,
      bookingMemberId,
      clubDiverId,
      mode: 'create',
    });
  }, [bookingId, bookingMemberId, clubDiverId, dialogsState.appDocEditDialog]);

  const openEditAppDocDialog = useCallback(
    (docResume: PRO_AppDocResume) => {
      dialogsState.appDocEditDialog.openDialog({
        bookingId,
        bookingMemberId,
        clubDiverId,
        mode: 'edit',
        docResume,
      });
    },
    [bookingId, bookingMemberId, clubDiverId, dialogsState.appDocEditDialog],
  );

  const docResumes = useMemo(
    () =>
      dataSorter.sortMultiple(docResumesInput, {
        getSortAttributes: (doc) => [
          {
            value: doc.uploadDate,
            asc: false,
          },
          {
            value: doc.label,
            asc: true,
          },
          {
            value: doc._id,
            asc: true,
          },
        ],
      }),
    [docResumesInput],
  );

  return (
    <div className={clsx('grid gap-4', className)}>
      <div className="my-2 text-base font-bold text-gray-600 uppercase">
        Documents {docResumes.length > 0 && <span>({docResumes.length})</span>}
      </div>
      {docResumes.length > 0 && (
        <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-4">
          {docResumes.map((doc) => (
            <AppDocViewPanelDoc
              key={doc._id}
              doc={doc}
              onClick={() => {
                openEditAppDocDialog(doc);
              }}
            />
          ))}
        </div>
      )}
      <div className="flex justify-start">
        <AppButton
          className="mb-5"
          icon={AppIconsAction.add}
          color="primary-outline-light"
          onClick={() => {
            openCreateAppDocDialog();
          }}
        >
          ajouter un document
        </AppButton>
      </div>
    </div>
  );
};
