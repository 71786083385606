/* eslint-disable @typescript-eslint/no-unused-vars */
import { dataSorter } from '@mabadive/app-common-services';
import {
  BillingTabDiveSessionBillingResume,
  BillingTabDiveSessionBillingResumeMultiDivers,
  BillingTabParticipantPurchaseSessionsBillingResumes,
} from '../../../models';
import { DiverPurchaseEditorParticipantsConfig } from '../model';
import { billingResumeMultiDiversBuilder } from './billingResumeMultiDiversBuilder.service';

export const purchaseCommonSessionsBillingResumesBuilder = {
  updatedSessionsBillingResumes,
};

function updatedSessionsBillingResumes({
  initialSessionsBillingResumes,
  updatedAssociatedBookingProductIds,
  participantsConfig,
}: {
  initialSessionsBillingResumes: BillingTabParticipantPurchaseSessionsBillingResumes;
  updatedAssociatedBookingProductIds: string[];
  participantsConfig: DiverPurchaseEditorParticipantsConfig;
}): BillingTabDiveSessionBillingResumeMultiDivers[] {
  // initial participants
  const billingResumes: BillingTabDiveSessionBillingResumeMultiDivers[] =
    billingResumeMultiDiversBuilder.buildMultiDivers(
      initialSessionsBillingResumes.sameTypeSameDiver,
      { updatedAssociatedBookingProductIds },
    );

  if (initialSessionsBillingResumes.otherTypeSameDiver) {
    for (const br of initialSessionsBillingResumes.otherTypeSameDiver) {
      if (
        participantsConfig.includeOtherTypes ||
        updatedAssociatedBookingProductIds.includes(
          br.purchaseParticipant.bookingProduct._id,
        )
      ) {
        addToList({ br, updatedAssociatedBookingProductIds, billingResumes });
      }
    }
  }

  if (initialSessionsBillingResumes.sameTypeOtherDiver) {
    for (const br of initialSessionsBillingResumes.sameTypeOtherDiver) {
      if (
        participantsConfig.includeOtherDivers ||
        updatedAssociatedBookingProductIds.includes(
          br.purchaseParticipant.bookingProduct._id,
        )
      ) {
        addToList({ br, updatedAssociatedBookingProductIds, billingResumes });
      }
    }
  }

  if (initialSessionsBillingResumes.otherTypeOtherDiver) {
    for (const br of initialSessionsBillingResumes.otherTypeOtherDiver) {
      if (
        (participantsConfig.includeOtherDivers &&
          participantsConfig.includeOtherTypes) ||
        updatedAssociatedBookingProductIds.includes(
          br.purchaseParticipant.bookingProduct._id,
        )
      ) {
        addToList({ br, updatedAssociatedBookingProductIds, billingResumes });
      }
    }
  }

  return dataSorter.sortMultiple(billingResumes, {
    getSortAttributes: (x: BillingTabDiveSessionBillingResumeMultiDivers) => [
      {
        value: x.diveSession.time,
        type: 'full-text',
      },
      {
        value: x.diveSession.reference,
        type: 'full-text',
      },
    ],
    asc: false,
  });
}

function addToList({
  br,
  updatedAssociatedBookingProductIds,
  billingResumes,
}: {
  br: BillingTabDiveSessionBillingResume;
  updatedAssociatedBookingProductIds: string[];
  billingResumes: BillingTabDiveSessionBillingResumeMultiDivers[];
}) {
  const purchaseParticipant =
    billingResumeMultiDiversBuilder.updateParticipantStatus({
      purchaseParticipant: br.purchaseParticipant,
      updatedAssociatedBookingProductIds,
    });

  const existing = billingResumes.find(
    (eBr) => eBr.diveSession?.reference === br.diveSession.reference,
  );
  if (existing) {
    existing.purchaseParticipants.push(purchaseParticipant);
  } else {
    const x: BillingTabDiveSessionBillingResumeMultiDivers = {
      diveSession: br.diveSession,
      purchaseParticipants: [purchaseParticipant],
    };
    billingResumes.push(x);
  }
}
