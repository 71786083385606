/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

export const ClubSettingsOnlineBookingMainViewPanel_ConfigGroups = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return (
    <ClubSettingsSection
      title="Configuration des autres réservations"
      comment="Configurer la façon dont vos clients vont effectuer les demandes de réservations non automatiques"
    >
      <AppFeatureBadge
        enabled={
          clubSettings?.general?.onlineBooking?.defaultConfig?.preBook
            ?.prompBeginDateEnabled
        }
        label="Demander la date souhaitée de la prestation"
      />
    </ClubSettingsSection>
  );
};
