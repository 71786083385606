/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppLanguageCode } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { uiStore } from 'src/business/_core/store';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubSettingsMessageTargets } from 'src/business/club/data/hooks';
import { translateApiClient } from 'src/business/club/modules/club-messaging/services';
import {
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useAppCompanyMessageContextesOptions,
  useAppCompanyMessageStylesOptions,
  useLanguagesOptions,
} from 'src/pages/_components/options';
import {
  AppCompanyMessageTemplateEditDialogInitialState,
  AppCompanyMessageTemplateEditFormModel,
} from '../model';
import { AppCompanyMessageTemplateEditFormEmail } from './AppCompanyMessageTemplateEditFormEmail';
import { AppCompanyMessageTemplateEditFormSMS } from './AppCompanyMessageTemplateEditFormSMS';
import { AppCompanyMessageTemplateEditFormWhatsApp } from './AppCompanyMessageTemplateEditFormWhatsApp';
import { AutomaticMailConfigForTemplateList } from './AutomaticMailConfigForTemplateList';

export const AppCompanyMessageTemplateEditForm = ({
  form,
  initialState,
  className,
}: {
  form: UseFormReturn<AppCompanyMessageTemplateEditFormModel>;
  initialState: AppCompanyMessageTemplateEditDialogInitialState;
  className?: string;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const messageStylesOptions = useAppCompanyMessageStylesOptions();

  const messageContextesOptions = useAppCompanyMessageContextesOptions();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const languagesOptions = useLanguagesOptions({
    scope: 'all',
  });

  const [isPersistInProgress, setIsPersistInProgress] =
    useState<boolean>(false);

  const [
    messageTemplateId,
    context,
    sourceLanguageCode,
    emailContentTitle,
    emailContentBody,
    smsContentTitle,
    smsContentBody,
    whatsAppContentTitle,
    whatsAppContentBody,
  ] = useWatch({
    control,
    name: [
      'messageTemplate._id',
      'messageTemplate.context',
      'messageTemplate.sourceLanguageCode',
      'messageTemplate.emailContent.message.title',
      'messageTemplate.emailContent.message.body',
      'messageTemplate.smsContent.message.title',
      'messageTemplate.smsContent.message.body',
      'messageTemplate.whatsAppContent.message.title',
      'messageTemplate.whatsAppContent.message.body',
    ],
  });

  const msg = useClubSettingsMessageTargets({
    context: context as 'booking' | 'session',
  });

  const translateMessages = useCallback(
    async ({ toLanguageCode }: { toLanguageCode: AppLanguageCode }) => {
      if (isPersistInProgress) {
        return;
      }
      setIsPersistInProgress(true);
      try {
        const { translatedMessages } =
          await translateApiClient.translateMessages(
            [
              {
                title: emailContentTitle,
                body: emailContentBody,
              },
              {
                title: smsContentTitle,
                body: smsContentBody,
              },
              {
                title: whatsAppContentTitle,
                body: whatsAppContentBody,
              },
            ],
            {
              fromLanguageCode: sourceLanguageCode,
              toLanguageCode,
            },
          );
        if (translatedMessages) {
          setValue(
            'messageTemplate.emailContent.message.title',
            translatedMessages[0].title,
          );
          setValue(
            'messageTemplate.emailContent.message.body',
            translatedMessages[0].body,
          );
          setValue(
            'messageTemplate.smsContent.message.title',
            translatedMessages[1].title,
          );
          setValue(
            'messageTemplate.smsContent.message.body',
            translatedMessages[1].body,
          );
          setValue(
            'messageTemplate.whatsAppContent.message.title',
            translatedMessages[2].title,
          );
          setValue(
            'messageTemplate.whatsAppContent.message.body',
            translatedMessages[2].body,
          );
        }
      } catch (err) {
        uiStore.snackbarMessage.set({
          type: 'error',
          content:
            'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
        });
      } finally {
        setIsPersistInProgress(false);
      }
    },
    [
      emailContentBody,
      emailContentTitle,
      isPersistInProgress,
      setValue,
      smsContentBody,
      smsContentTitle,
      sourceLanguageCode,
      whatsAppContentBody,
      whatsAppContentTitle,
    ],
  );

  return (
    <div className={clsx('grid gap-4', className)}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2">
        <AppFormControlRHF
          className="flex-grow"
          label={'Nom du message'}
          control={control}
          name="messageTemplate.name"
          required={true}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <AppFormControlRHF
          className="w-full"
          label={'Style'}
          control={control}
          name="messageTemplate.style"
          required={true}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              theme="material-ui"
              {...props}
              options={messageStylesOptions}
            />
          )}
        />
        <AppFormControlRHF
          control={control}
          label={'Langue'}
          name="messageTemplate.sourceLanguageCode"
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={languagesOptions}
              fullWidth
              disableClearable={true}
              onChange={async (toLanguageCode) => {
                const confirm = await confirmDialog.confirmPromise({
                  title: 'Langue modifiée',
                  message: 'Voulez-vous traduire automatiquement les messages?',
                  type: 'noYesInfo',
                });

                if (confirm) {
                  await translateMessages({ toLanguageCode });
                }
              }}
            />
          )}
        />
      </div>
      <div className="flex gap-4">
        <AppFormControlRHF
          control={control}
          label={'Envoi manuel'}
          name="messageTemplate.enabled"
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              label="Visible"
              labelToggleFalse="Masqué"
            />
          )}
        />
        {isSuperAdmin && (
          <AppFormControlRHF
            className="w-full sm:max-w-xs"
            label={"Contexte d'utilisation"}
            helpDescription="Suivant le contexte d'utilisation, les paramètres disponibles dans le message ne sont pas les mêmes"
            control={control}
            name="messageTemplate.context"
            required={true}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                theme="material-ui"
                {...props}
                options={messageContextesOptions}
              />
            )}
          />
        )}
      </div>
      <AutomaticMailConfigForTemplateList
        messageTemplateId={messageTemplateId}
      />
      {msg.email.enabled && (
        <AppCompanyMessageTemplateEditFormEmail
          form={form}
          initialState={initialState}
        />
      )}
      {msg.sms.enabled && (
        <AppCompanyMessageTemplateEditFormSMS
          form={form}
          initialState={initialState}
        />
      )}
      {msg.whatsApp.enabled && (
        <AppCompanyMessageTemplateEditFormWhatsApp
          form={form}
          initialState={initialState}
        />
      )}
    </div>
  );
};
