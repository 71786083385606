import clsx from 'clsx';
import { FC, default as React, useCallback, useMemo, useState } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppDialogModalFixedBar,
  AppMessage,
  AppMessageLight,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import {
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFormControlV2, AppInputBooleanSwitch } from 'src/lib/form';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';
import { CreateMessageToCustomersFormStep2_RecipientsTable } from './CreateMessageToCustomersFormStep2_RecipientsTable';

export const CreateMessageToCustomersFormStep2_Recipients: FC<{
  localState: CreateMessageToCustomersLocalState;
  className?: string;
}> = ({ localState, className }) => {
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume.clubResume;
  const clubReference = clubResume?.reference;

  const {
    state,
    data: { form, result, refDate, isConnexionLinkFountInMessage },
    actions,
  } = localState;

  const {
    aggregated: { allRecipients, validRecipients, validSelectedRecipients },
    changes,
  } = result;

  const context = localState.state.context;
  const { register, handleSubmit, watch, control, setValue } = form;

  const [messageTarget, recipients] = useWatch({
    control,
    name: ['messageTarget', 'recipients'],
  });

  const formState = useFormState({
    control,
  });

  const clubSettings = useClubSettings();

  const [filters, setFilter] = useState<{
    bookingContactOnly: boolean;
  }>({
    bookingContactOnly:
      context === 'booking' &&
      clubSettings.communication?.booking?.recipients?.contactOnlyAsDefault,
  });

  const recipientsFiltered = useMemo(() => {
    if (filters?.bookingContactOnly) {
      return allRecipients?.filter((x) => x.formRecipient?.isBookingContact);
    }
    return allRecipients;
  }, [allRecipients, filters?.bookingContactOnly]);

  const goToStep3 = useCallback(async () => {
    actions.persistParticipantsChangesIfAny();
    if (validSelectedRecipients?.length !== 0) {
      actions.autoSetRecipientType({
        nextStep: 'step3-content',
      });
      state.setStep('step3-content');
    }
  }, [actions, state, validSelectedRecipients?.length]);

  return (
    <div className={clsx('mb-12 flex flex-col gap-8', className)}>
      <div className="grid gap-4">
        {context === 'booking' && allRecipients.length > 0 && (
          <>
            <div className="flex flex-wrap gap-x-4 gap-y-2">
              <AppFormControlV2
                className="sm:col-span-1 bg-white px-2"
                label={'Seulement le responsable (contact résa)'}
                renderComponent={() => (
                  <AppInputBooleanSwitch
                    value={filters.bookingContactOnly}
                    onChange={(bookingContactOnly) => {
                      if (bookingContactOnly) {
                        allRecipients.forEach((recipient, diverIndex) => {
                          setValue(
                            `recipients.${diverIndex}.isSelected`,
                            recipient.formRecipient.isBookingContact,
                          );
                        });
                      } else {
                        allRecipients.forEach((recipient, diverIndex) => {
                          setValue(
                            `recipients.${diverIndex}.isSelected`,
                            !!recipient.targetValidContact,
                          );
                        });
                      }
                      setFilter({
                        ...filters,
                        bookingContactOnly,
                      });
                    }}
                  />
                )}
              />
            </div>
          </>
        )}
        <CreateMessageToCustomersFormStep2_RecipientsTable
          refDate={refDate}
          recipients={recipientsFiltered}
          form={form}
          includeCancelledParticipants={state.includeCancelledParticipants}
          setIncludeCancelledParticipants={
            state.setIncludeCancelledParticipants
          }
          cancelledParticipantsCount={state.cancelledParticipantsCount}
        />
        {validSelectedRecipients?.length === 0 ? (
          <AppMessage
            className="text-justify sm:text-left"
            type="warn"
            title={'Aucun destinataire'}
            isExpandable={false}
            message={'Veuillez sélectionner au moins 1 destinataire valide.'}
          />
        ) : (
          <p className={'my-2 font-bold text-green-600 rounded inline2 '}>
            <AppHeroIcons.check className="inline w-6 h-6 mr-1" />{' '}
            <span className="font-bold">{validSelectedRecipients?.length}</span>
            {validSelectedRecipients?.length === 1
              ? ' destinataire sélectionné'
              : ' destinataires sélectionnés'}
          </p>
        )}
      </div>
      <div className="flex-grow"></div>
      {messageTarget === 'whatsapp' && (
        <AppMessageLight type="warn">
          <div className="">
            <div>
              Whatsapp ne fonctionne que si le numéro de téléphone commence par
              le signe "+".
            </div>
            <div>
              Les numéros de 10 chiffres commençant par "06" et "07" sont
              préfixés automatiquement par "+33"
            </div>
          </div>
        </AppMessageLight>
      )}
      <AppDialogModalFixedBar>
        <div className="flex gap-4 justify-between md:justify-end">
          <AppButton
            className="w-full uppercase sm:max-w-xs"
            fullWidth={true}
            size="normal"
            icon={AppIconsAction.back}
            color={'gray-outline-light'}
            tabIndex={500}
            onClick={() => {
              state.setStep('step1-message');
            }}
          >
            Retour
          </AppButton>
          <AppButton
            className="w-full uppercase sm:max-w-xs"
            fullWidth={true}
            size="normal"
            icon={AppIconsAction.confirm}
            color={'primary-bg'}
            tabIndex={500}
            disabled={validSelectedRecipients?.length === 0}
            onClick={async () => {
              goToStep3();
            }}
          >
            Confirmer
          </AppButton>
        </div>
      </AppDialogModalFixedBar>
    </div>
  );
};
