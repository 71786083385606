import {
  AppCurrency,
  AppEntityUpdatePatch,
  BookingDeposit,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { BookingDepositEditDialogProps } from './model';

export type BookingDepositEditDialogActionPersistProps = {
  onCreate: ({ bookingDeposit }: { bookingDeposit: BookingDeposit }) => any;
  onUpdate: (props: {
    bookingDeposit: BookingDeposit;
    patch: AppEntityUpdatePatch;
  }) => any;
};

export function useBookingDepositEditDialogActionPersist(
  props: BookingDepositEditDialogActionPersistProps,
): BookingDepositEditDialogProps {
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  const appCurrency: AppCurrency = useAppCurrencyMain({ generalSettings });

  const action: BookingDepositEditDialogProps = {
    async onConfirm({ formValue }, initialState) {
      const { mode, defaultValue } = initialState;
      if (mode === 'create') {
        const bookingDeposit: BookingDeposit =
          commonEntityBuilder.buildEntity<BookingDeposit>({
            clubReference,
            diveCenterId,
            ...formValue.bookingDeposit,
            currencyIsoCode: appCurrency.isoCode,
            creditRemaining: formValue.bookingDeposit.creditInitial,
            price: formValue.bookingDeposit.creditInitial,
            payedAmount: 0,
            depositState: 'draft',
            purchasePaymentStatus: 'todo',
          });
        if (props?.onCreate) {
          props?.onCreate({ bookingDeposit });
        }
      } else if (mode === 'edit') {
        const bookingDepositPatchOperations = jsonPatcher.compareObjects(
          defaultValue.bookingDeposit,
          {
            ...formValue.bookingDeposit,

            creditRemaining:
              formValue.bookingDeposit.creditInitial -
              formValue.bookingDeposit.creditUsed,
          },
          {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: true,
          },
        );

        if (bookingDepositPatchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: defaultValue.bookingDeposit._id,
            patchOperations: bookingDepositPatchOperations,
          };
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'save-booking-deposit',
              bookingIdsToClean: [],
            });

          payload.bookingDeposits.updated.push(patch);
          if (props?.onUpdate) {
            props?.onUpdate({
              bookingDeposit: formValue.bookingDeposit as BookingDeposit,
              patch,
            });
          } else {
          }
        }
      }
    },
  };
  return action;
}
