import React, { useEffect } from 'react';
import {
  DiverPurchaseCommonEditorDialogResult,
  DiverPurchasePlanEditorDialogState,
} from '../../models';

import { bookingPagePackageConsumedCounter } from '../../services/02.update-state/services';
import {
  creditsCountBuilder,
  purchaseCommonSessionsBillingResumesBuilder,
} from '../DiverPurchaseCommonEditorDialog';
import {
  DiverPurchasePlanDialogLocalStateActions,
  useDiverPurchasePlanDialogLocalStateActions,
} from './useDiverPurchasePlanDialogLocalStateActions.service';
import {
  DiverPurchasePlanDialogLocalStateContext,
  useDiverPurchasePlanDialogLocalStateContext,
} from './useDiverPurchasePlanDialogLocalStateContext.service';
import {
  DiverPurchasePlanDialogLocalStateData,
  useDiverPurchasePlanDialogLocalStateData,
} from './useDiverPurchasePlanDialogLocalStateData.service';
import {
  DiverPurchasePlanDialogLocalStateFormContext,
  useDiverPurchasePlanDialogLocalStateFormContext,
} from './useDiverPurchasePlanDialogLocalStateFormContext.service';
import {
  DiverPurchasePlanDialogLocalStateInitialData,
  useDiverPurchasePlanDialogLocalStateInitialData,
} from './useDiverPurchasePlanDialogLocalStateInitialData.service';
import {
  DiverPurchasePlanDialogLocalStateUseStates,
  useDiverPurchasePlanDialogLocalStateUseStates,
} from './useDiverPurchasePlanDialogLocalStateUseStates.service';

export function useDiverPurchasePlanDialogLocalState({
  onConfirm,
  inputState,
  setInputState,
}: {
  inputState: DiverPurchasePlanEditorDialogState;
  setInputState: React.Dispatch<
    React.SetStateAction<DiverPurchasePlanEditorDialogState>
  >;
  onConfirm?: (result: DiverPurchaseCommonEditorDialogResult) => void;
}) {
  const { mode, sessionsBillingResumes: initialSessionsBillingResumes } =
    inputState;

  // const diveCenterResume = useDiveCenterResume();
  // const clubResume = useClubResume();
  // const clubSettings = clubResume?.clubSettings;
  // const diveCenterId = diveCenterResume?._id;
  // const clubReference = diveCenterResume?.clubReference;

  // const securityUser = useAppSecurityUser();

  // const isPaymentEnabled = useMemo(
  //   () => securityUser?.roles.includes('club-edit-participant-payment'),
  //   [securityUser.roles],
  // );

  const localContext: DiverPurchasePlanDialogLocalStateContext =
    useDiverPurchasePlanDialogLocalStateContext();

  const {
    clubResume,
    diveCenterResume,
    securityUser,
    clubSettings,
    diveCenterId,
    clubReference,
    isPaymentEnabled,
  } = localContext;

  const initialData: DiverPurchasePlanDialogLocalStateInitialData =
    useDiverPurchasePlanDialogLocalStateInitialData({
      inputState,
      localContext,
    });

  const {
    successiveDivesCount,
    productPackageOffersCriteria,
    productPackageOffers,
    planGroups,
    defaultProductPackageOffer,
    initialAssociatedBookingProductIds,
    initialFormValue,
    includeOtherDiversDefaultValue,
  } = initialData;
  const formContext: DiverPurchasePlanDialogLocalStateFormContext =
    useDiverPurchasePlanDialogLocalStateFormContext({
      localContext,
      initialData,
    });
  const { form, formWatch } = formContext;
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const {
    unitPrice,
    totalPriceThirdPartyCollect,
    creditsInitialCount,
    validityStatus,
    unitQuantity,
    productPackageOfferReference,
    creditsAdditionalCount,
    consumedExternalCount,
    discountAmount,
    extraCosts,
  } = formWatch;

  const useStates: DiverPurchasePlanDialogLocalStateUseStates =
    useDiverPurchasePlanDialogLocalStateUseStates({
      initialData,
    });
  const data: DiverPurchasePlanDialogLocalStateData =
    useDiverPurchasePlanDialogLocalStateData({
      localContext,
      initialData,
      formContext,
      inputState,
      useStates,
    });

  const { totalCreditsCount, selectedOffer } = data;

  const {
    states,
    updatedAssociatedBookingProductIds,
    setAssociatedBookingProductIds,
    selectedPlanGroupData,
    setSelectedPlanGroupData,
    participantsConfig,
    setParticipantsConfig,
    pageLoaded,
    setPageLoaded,
    openExtraCostsFormPanel,
    setOpenExtraCostsFormPanel,
  } = useStates;

  const actions: DiverPurchasePlanDialogLocalStateActions =
    useDiverPurchasePlanDialogLocalStateActions({
      data,
      formContext,
      initialData,
      inputState,
      localContext,
      setInputState,
      useStates,
      onConfirm,
    });

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true); // don't trigger on first page load
    } else {
      if (selectedOffer) {
        const diveAttributes = selectedOffer.productPackage.diveAttributes;
        if (
          diveAttributes?.divesCount !== null &&
          diveAttributes?.divesCount !== creditsInitialCount
        ) {
          setValue('creditsInitialCount', diveAttributes?.divesCount, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }
        if (securityUser?.roles.includes('club-edit-participant-payment')) {
          if (
            selectedOffer.price !== null &&
            selectedOffer.price !== unitPrice
          ) {
            // pour l'instant, un seul tarif supporté
            setValue('unitPrice', selectedOffer.price, {
              shouldValidate: true,
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOffer, // only if selected offer changes
  ]);

  useEffect(() => {
    if (pageLoaded) {
      if (selectedOffer) {
        if (securityUser?.roles.includes('club-edit-participant-payment')) {
          if (
            selectedOffer.thirdPartyCollectPrice > 0 &&
            unitQuantity > 0 &&
            selectedOffer.thirdPartyCollectPrice * unitQuantity !==
              totalPriceThirdPartyCollect
          ) {
            setValue(
              'totalPriceThirdPartyCollect',
              selectedOffer.thirdPartyCollectPrice * unitQuantity,
              {
                shouldValidate: true,
              },
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageLoaded,
    unitQuantity,
    selectedOffer, // only if selected offer or quantity changes
  ]);
  useEffect(() => {
    if (selectedOffer) {
      const updatedSessionsBillingResumes =
        purchaseCommonSessionsBillingResumesBuilder.updatedSessionsBillingResumes(
          {
            initialSessionsBillingResumes,
            participantsConfig,
            updatedAssociatedBookingProductIds,
          },
        );

      const isSuccessivePackage =
        selectedOffer?.productPackage?.diveAttributes?.divePriceType ===
        'successive';
      const countSuccessiveAsSingle =
        bookingPagePackageConsumedCounter.testIfCountSuccessiveAsSingle({
          clubSettings,
          isSuccessivePackage,
        });
      const totalConsumedCount =
        creditsCountBuilder.buildTotalConsumedCountFromBillingResumes({
          sessionsBillingResumes: updatedSessionsBillingResumes,
          consumedExternalCount,
          clubSettings,
          countSuccessiveAsSingle,
        });
      actions.updateValidityStatusIfNecessary({
        consumedTotalCount: totalConsumedCount, // updatedAssociatedBookingProductIds.length,
        totalCreditsCount,
        validityStatus,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actions,
    mode,
    selectedOffer,
    totalCreditsCount,
    updatedAssociatedBookingProductIds.length,
    validityStatus,
  ]);

  return {
    data,
    formContext,
    localContext,
    actions,
    states,
    inputState,
    useStates,
  };
}

export type DiverPurchasePlanDialogLocalState = ReturnType<
  typeof useDiverPurchasePlanDialogLocalState
>;
