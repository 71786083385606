import {
  EcommerceCategoryWithBookletGql_Company,
  EcommerceProductFullGql_Company,
} from '@mabadive/app-common-model';
import {
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { MQueryDescription, graphqlClient } from 'src/_common-browser';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { reactQueryToLoadableConverter } from '../../../../../../../business/_core/data/app-loading';
import { ProEcommerceDataFetchedData } from './ProEcommerceDataFetchedData.hook';
import { ProEcommerceDataLoadedContent } from './ProEcommerceDataLoadedContent.hook';
import { proEcommerceDataLoadedContentBuilder } from './proEcommerceDataLoadedContentBuilder.service';
import { useProEcommerceDataUpdateCache } from './useProEcommerceDataUpdateCache.hook';

export function useProEcommerceDataLoadedContentFetch() {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const queryClient = useQueryClient();
  const clubReference = diveCenterResume?.clubReference;

  const queryKey = [clubReference];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startDate = useMemo(() => new Date(), queryKey);

  const query: UseQueryResult<ProEcommerceDataLoadedContent, unknown> =
    useQuery({
      queryKey,
      queryFn: async () => {
        const q1: MQueryDescription<EcommerceCategoryWithBookletGql_Company> = {
          returnName: 'categoriesWithBooklet',
          queryName: 'club_ecommerce_category', // : ClubEcommerceCategory[];
          returnType: 'all',
          where: '{}',
          orderBy: '{sortIndex: asc}',
          returnAttributes: EcommerceCategoryWithBookletGql_Company,
        };

        const q2: MQueryDescription<EcommerceProductFullGql_Company> = {
          returnName: 'productsFull',
          queryName: 'club_ecommerce_product', // : ClubEcommerceCategory[];
          returnType: 'all',
          where: '{}',
          // orderBy: '{sortIndex: asc}',
          returnAttributes: EcommerceProductFullGql_Company,
        };

        const graphqlQuery$ =
          graphqlClient.query.runMany<ProEcommerceDataFetchedData>([q1, q2], {
            type: 'query',
            name: 'useProEcommerceDataLoadedContentFetch',
          });

        const fetchedData: ProEcommerceDataFetchedData =
          await graphqlQuery$?.toPromise();
        // on converti les données dans un format simple, plus facile à exploiter
        // pour la mise à jour du cache et l'aggrégation des modifications locales
        const loadedContent: ProEcommerceDataLoadedContent =
          proEcommerceDataLoadedContentBuilder.buildContent({
            fetchedData,
          });
        return loadedContent;
      },
      enabled: true,
      gcTime: 1000 * 60 * 60 * 24 * 2, // on garde ça en cache 2 jours
      staleTime: 0, // mais au chargement, on rafraichi systématiquement les données
      refetchOnWindowFocus: true, // ainsi que si la fenêtre reprend le focus
      refetchInterval: 5 * 60 * 1000, // et toutes les 5 minutes
      // placeholderData: queryKeysMap.current.get(mainDiverId),
    });

  const loadableContentMulti =
    reactQueryToLoadableConverter.convertReactQueryToLoadableContent({
      query,
      startDate,
    });

  const { updateCacheFromProMultiOperationResult } =
    useProEcommerceDataUpdateCache({ queryClient, queryKey });

  return {
    ...loadableContentMulti,
    updateCache: {
      multiOperationResult: updateCacheFromProMultiOperationResult,
    },
  };
}
