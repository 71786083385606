/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@material-ui/core';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
  AppTabsBar,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { useAppSecurityUser } from 'src/business/auth/services';
import { CLUB_LISTS_TABS } from '../_common';
import { clubListsUrlBuilder } from '../clubListsUrlBuilder.service';
import { PaymentsListPageConfigBar } from './PaymentsListPageConfigBar';
import { PaymentsListTable } from './PaymentsListTable/PaymentsListTable';
import {
  PaymentsListPageLocalState,
  usePaymentsListPageLocalState,
} from './usePaymentsListPageLocalState';

export const PaymentsListPage = () => {
  const localState: PaymentsListPageLocalState =
    usePaymentsListPageLocalState();

  const {
    viewParameters,
    setViewParameters,
    filters,
    filteredPaymentsLimited,
    filteredPayments,
    fetchCriteria,
  } = localState;

  const redirectTo = useRedirect();
  const user = useAppSecurityUser();

  const autoFocus = useAutoFocus();

  return (
    <AppPageContainerWithFixedBars
      marginBottom={false}
      paddingBottom={false}
      contentClassName="bg-gray-50 app-p-content"
      headerBar={() => (
        <>
          <AppTabsBar
            theme="primary"
            tabs={CLUB_LISTS_TABS}
            context={{}}
            value={'payment'}
            onChange={(tab) => {
              const url = clubListsUrlBuilder.buildClubListsTabUrl(tab);
              redirectTo(url);
            }}
            // disableNavigation={globalState?.updateState.hasChanges}
          />
          <div className="bg-gray-50 app-p-content">
            <PaymentsListPageConfigBar localState={localState} />
          </div>
        </>
      )}
    >
      <AppPageContentContainer
        className="app-card-no-padding"
        paddingBottom={false}
      >
        <PaymentsListTable payments={filteredPaymentsLimited} />
        {filteredPayments?.length === 0 ? (
          <div className="mx-2 my-3">
            <Typography variant="subtitle2">
              Aucun paiement disponible pour cette période
            </Typography>
          </div>
        ) : null}
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
