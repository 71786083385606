/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageAttributeProductType,
  DIVE_SERVICE_ORGANIZATIONS,
  DiveServiceOrganizationReference,
  PRODUCT_TYPES,
} from '@mabadive/app-common-model';
import {
  ClubProductPackageNameDetailsFormatProps,
  diveServiceOrganizationFilter,
  productPackageFormatter,
  productTypeFormatter,
} from '@mabadive/app-common-services';
import { Box, FormControl, FormLabel } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { AppFixedButtonsBar } from 'src/business/_core/modules/layout/components/AppFixedButtonsBar/AppFixedButtonsBar';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { ClubOfferNameDetailsLabel } from 'src/business/club/modules/club-diver-participant/components';
import { useResidentTypesOptions } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useDiveSessionThemesOptions,
  useDiveSessionTypesOptions,
} from 'src/pages/_components/options';
import { ClubOfferProductEditorContext } from './ClubOfferProductEditorContext.type';
import { ClubOfferProductEditorFormModel } from './ClubOfferProductEditorFormModel.type';
import { ClubOfferProductEditorResult } from './ClubOfferProductEditorResult.type';
import { clubOfferProductEditorResultBuilder } from './clubOfferProductEditorResultBuilder.service';
import { useClubOfferProductEditorFormValue } from './useClubOfferProductEditorFormValue.hook';

export const ClubOfferProductEditor = ({
  context,
  onCancel,
  onConfirm,
  className,
}: {
  context: ClubOfferProductEditorContext;
  onCancel?: () => void;
  onConfirm?: (result: ClubOfferProductEditorResult) => void;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const services = clubResume.clubSettings.services;

  const diveSessionThemesOptions = useDiveSessionThemesOptions({
    scope: 'session-settings',
  });
  const diveSessionTypesOptions = useDiveSessionTypesOptions({
    scope: 'session-settings',
  });
  const productTypeOptions = useMemo(
    () =>
      PRODUCT_TYPES.map((productType) => {
        const option: ValueLabel<ClubProductPackageAttributeProductType> = {
          label: productTypeFormatter.format(productType).toUpperCase(),
          value: productType,
        };
        return option;
      }),
    [],
  );

  const residentTypesOptions = useResidentTypesOptions();

  const initialFormValue = useMemo(() => {
    if (context.mode === 'create') {
      const model: ClubOfferProductEditorFormModel = {
        productType: 'sale',
        price: null,
        thirdPartyCollectPrice: null,
        comment: null,
        minAge: null,
        maxAge: null,
        namingConfiguration: {
          includeEquipment: false,
          includeSupervision: false,
          includeDefaultName: false,
        },
        defaultOrganizationReference: null,
      };
      return model;
    } else {
      const o = context.offer;
      const productAttributes = o.productPackage.productAttributes;
      const model: ClubOfferProductEditorFormModel = {
        price: o.price,
        thirdPartyCollectPrice: o.thirdPartyCollectPrice,
        comment: o.productPackage.comment,
        label: o.productPackage.label,
        namingConfiguration: o.productPackage.namingConfiguration,
        defaultOrganizationReference:
          productAttributes.defaultOrganizationReference,
        minAge: productAttributes.minAge,
        maxAge: productAttributes.maxAge,
        productType: o.productPackage.productType,
        salesCriteria: o.productPackage.salesCriteria,
      };
      return model;
    }
  }, [context.mode, context.offer]);

  const { register, handleSubmit, watch, formState, control, setValue } =
    useForm<ClubOfferProductEditorFormModel>({
      defaultValues: initialFormValue,
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  const formValue = useClubOfferProductEditorFormValue({ control });
  const {
    productType,
    defaultOrganizationReference,
    label,
    namingConfiguration,
  } = formValue;

  const selectedOrganization = useMemo(
    () =>
      DIVE_SERVICE_ORGANIZATIONS.find(
        (x) => x.reference === defaultOrganizationReference,
      ),
    [defaultOrganizationReference],
  );

  const organizationsOptions = useMemo(
    () =>
      DIVE_SERVICE_ORGANIZATIONS.filter(
        (org) =>
          org?.reference === initialFormValue.defaultOrganizationReference ||
          diveServiceOrganizationFilter.isOrgEnabled(org?.reference, {
            services,
          }),
      ).map((org) => {
        const option: ValueLabel<DiveServiceOrganizationReference> = {
          label: org.label.toUpperCase(),
          value: org?.reference,
        };
        return option;
      }),
    [services, initialFormValue.defaultOrganizationReference],
  );

  const user = useAppSecurityUser();

  const nameDetails = useMemo(() => {
    const productAttributes = {
      defaultOrganizationReference: formValue.defaultOrganizationReference,
    };

    const productPackage: ClubProductPackageNameDetailsFormatProps = {
      label,
      namingConfiguration,
      productAttributes,
      productType,
      type: 'dive',
    };

    const nameDetails = productPackageFormatter.formatNameDetails(
      productPackage,
      {
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );
    return nameDetails;
  }, [
    clubSettings?.ui?.diveMode,
    formValue.defaultOrganizationReference,
    label,
    namingConfiguration,
    productType,
    publicSettings,
  ]);

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: ClubOfferProductEditorFormModel, event) => {
        const { result, hasChanges } =
          clubOfferProductEditorResultBuilder.buildResult({
            clubReference: clubResume.reference,
            context,
            formValue,
          });

        if (!hasChanges) {
          // no changes
          onCancel();
          return;
        } else {
          onConfirm(result);
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [clubResume.reference, context, handleSubmit, onCancel, onConfirm]);

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <AppFormControlRHF_Deprecated
          label="Type de produit"
          control={control}
          name="productType"
          required={true}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={productTypeOptions}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          label="Libellé"
          control={control}
          name="label"
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        {organizationsOptions.length > 1 && (
          <AppFormControlRHF_Deprecated
            label="Organisme"
            control={control}
            name="defaultOrganizationReference"
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={organizationsOptions}
              />
            )}
          />
        )}
        <FormControl>
          <FormLabel className="form-label pl-1" component="legend">
            <span className="text-xs uppercase">Libellé affiché</span>
          </FormLabel>
          <Box className="form-input">
            <ClubOfferNameDetailsLabel
              className="font-bold text-app-blue"
              nameDetails={nameDetails}
            />
          </Box>
        </FormControl>
      </div>
      {productType && (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <AppFormControlRHF_Deprecated
              control={control}
              label="Age mini (inclu)"
              name={'minAge'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              control={control}
              label="Age maxi (exclu)"
              name={'maxAge'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            <AppFormControlRHF_Deprecated
              label="Tarif"
              control={control}
              required={false}
              name={'price'}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" fullWidth />
              )}
            />
            {generalSettings?.billing?.thirdPartyCollectEnabled && (
              <AppFormControlRHF_Deprecated
                label="Tarif (part tiers)"
                control={control}
                required={false}
                name={'thirdPartyCollectPrice'}
                renderComponent={(props) => (
                  <AppInputRHF {...props} type="number" fullWidth />
                )}
              />
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <AppFormControlRHF_Deprecated
              className={'w-full'}
              label="Résidence"
              control={control}
              name={'salesCriteria.residentType'}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  className={'w-full'}
                  theme="material-ui"
                  color="theme"
                  {...props}
                  options={residentTypesOptions}
                />
              )}
            />
            {diveSessionTypesOptions.length > 0 && (
              <AppFormControlRHF_Deprecated
                className="w-full"
                label={'Type de sortie'}
                control={control}
                name={'salesCriteria.diveSessionType'}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    className={'w-full'}
                    theme="material-ui"
                    {...props}
                    options={diveSessionTypesOptions}
                    // onChange={(value) => onChangeDiveSessionType(value)}
                  />
                )}
              />
            )}
            {diveSessionThemesOptions.length > 0 && (
              <AppFormControlRHF_Deprecated
                className="w-full"
                label={'Thème de la sortie'}
                control={control}
                name={'salesCriteria.diveSessionTheme'}
                renderComponent={(props) => (
                  <AppSingleSelect2HeadlessUIRHF
                    className={'w-full'}
                    theme="material-ui"
                    {...props}
                    options={diveSessionThemesOptions}
                    // onChange={(value) => onChangeDiveSessionTheme(value)}
                  />
                )}
              />
            )}
            <AppFormControlRHF_Deprecated
              className="mb-2"
              label={'Offre par défaut'}
              control={control}
              name="salesCriteria.dontSelectByDefault"
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  description={'ne pas pré-selectionner'}
                />
              )}
            />
          </div>
          <div className="flex gap-4 flex-wrap">
            <AppFormControlRHF_Deprecated
              className={''}
              label="Boutique"
              control={control}
              name={'salesCriteria.standalone'}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              className={''}
              label="Favori"
              control={control}
              name={'salesCriteria.favorite'}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              className={''}
              label="Supp. formation"
              control={control}
              name={'salesCriteria.extraCostTraining'}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              className={''}
              label="Supp. baptême"
              control={control}
              name={'salesCriteria.extraCostFirstDive'}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              className={''}
              label="Supp. explo"
              control={control}
              name={'salesCriteria.extraCostDivePlan'}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />{' '}
            <AppFormControlRHF_Deprecated
              className={''}
              label="Par plongée"
              control={control}
              name={'salesCriteria.extraCostUnitDive'}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              className={''}
              label="Supp. session"
              control={control}
              name={'salesCriteria.extraCostSession'}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />
          </div>
          <div className="">
            <AppFormControlRHF_Deprecated
              label="Commentaire"
              control={control}
              name="comment"
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
          </div>
        </>
      )}

      <AppFixedButtonsBar hasChanges={formState.isDirty}>
        <AppButton
          fullWidth
          icon={AppHeroIcons.actionCancel}
          color="gray-outline-light"
          size="normal"
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          Annuler
        </AppButton>
        <AppButton
          fullWidth
          icon={AppHeroIcons.actionSave}
          color="primary-outline-light"
          onClick={() => {
            submitForm();
          }}
        >
          Confirmer
        </AppButton>
      </AppFixedButtonsBar>
    </div>
  );
};
