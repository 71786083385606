import { BookingMember } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import {
  AppGenericStatus,
  AppGenericStatusIcon,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';

export const ClubDiverCardAttributeInsurance = ({
  bookingMember,
  className = '',
}: {
  bookingMember: Pick<BookingMember, 'details'>;
  className?: string;
}) => {
  const globalClasses = useGlobalClasses();

  const insuranceDateBadgeStatus: AppGenericStatus = useMemo(() => {
    const maxDateOrToday = new Date();
    if (!bookingMember?.details?.insurance?.expirationDate) {
      return 'warn';
    }
    if (
      !dateService.isBefore(
        bookingMember?.details?.insurance?.expirationDate,
        maxDateOrToday,
      )
    ) {
      return 'success';
    }
    return 'error';
  }, [bookingMember?.details?.insurance?.expirationDate]);

  const insuranceDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(insuranceDateBadgeStatus),
    [insuranceDateBadgeStatus],
  );
  return (
    <div className={`${className} ${insuranceDateBadgeStatusClass}`}>
      {bookingMember?.details?.insurance?.hasInsurance === true ? (
        <div className="flex flex-col gap-0.5 items-start">
          {bookingMember?.details?.insurance?.number
            ? null // <div>{bookingMember?.details?.insurance?.number}</div>
            : !bookingMember?.details?.insurance?.expirationDate && (
                <div className="text-xs text-status-success">OUI</div>
              )}
          <div className="flex items-center">
            {bookingMember?.details?.insurance?.expirationDate && (
              <>
                <AppGenericStatusIcon
                  status={insuranceDateBadgeStatus}
                  className={'w-4 h-4 '}
                />
                <div className="grow">
                  <span className="ml-1">
                    {dateService.formatUTC(
                      bookingMember?.details?.insurance?.expirationDate,
                      'DD/MM/YYYY',
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="text-xs text-status-error">NON</div>
      )}
    </div>
  );
};
