/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  AppBreadcrumbBar,
  AppDocumentationLink,
  AppPageContainer,
} from 'src/business/_core/modules/layout';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import { ClubDialogsProvider } from 'src/pages/_dialogs';

import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import { ClubSettingsOnlineBookingMainViewPanel } from './tabs';
import { ClubSettingsOnlineBookingEmailsViewPanel } from './tabs/02.emails';
import { ClubSettingsOnlineBookingLinksViewPanel } from './tabs/05.links';
import { ClubSettingsOnlineBookingLegalInformationViewPanel } from './tabs/06.legal-information';
import {
  ClubSettingsOnlineBookingConfigPageLocalState,
  useClubSettingsOnlineBookingConfigPageLocalState,
} from './useClubSettingsOnlineBookingConfigPageLocalState.hook';

export const ClubSettingsOnlineBookingConfigPage = () => {
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const localState: ClubSettingsOnlineBookingConfigPageLocalState =
    useClubSettingsOnlineBookingConfigPageLocalState();

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  return (
    <AppPageContainer className={'bg-white'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.customers.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.customers.onlineBooking,
            label: 'Vente en ligne',
            url: 'customers/online-booking/config',
          },
          {
            label: 'Configuration',
          },
        ]}
      >
        <AppDocumentationLink
          className="block h-9 w-9 md:h-11 md:w-11"
          url="https://docs.mabadive.com/docs/vente-en-ligne/presentation_vente-en-ligne"
        />
      </AppBreadcrumbBar>
      <ClubDialogsProvider dialogsState={dialogs.state}>
        {selectedTab === 'config' ? (
          <ClubSettingsOnlineBookingMainViewPanel localState={localState} />
        ) : selectedTab === 'emails' ? (
          <ClubSettingsOnlineBookingEmailsViewPanel localState={localState} />
        ) : selectedTab === 'links' ? (
          <ClubSettingsOnlineBookingLinksViewPanel localState={localState} />
        ) : selectedTab === 'legal-information' ? (
          <ClubSettingsOnlineBookingLegalInformationViewPanel
            localState={localState}
          />
        ) : null}
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
