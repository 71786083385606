import {
  DashboardBookingsByPeriod,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { chartHelper } from 'src/pages/DA-dashboard/_core/services';
import { EvolutionActivityBookingsByDayBarChartAttribute } from '../model';
import { evolutionActivityBookingsByDayBarChartTooltipBuilder } from './evolutionActivityBookingsByDayBarChartTooltipBuilder.service';

const COLORS: { [attr in ChartAttribute]: string } = {
  bookingParticipantsCount: '#197297', // app-blue (tailwind)
  bookingParticipantsCancelledCount: '#b52206', // app-red (tailwind)
};

type ChartAttribute = EvolutionActivityBookingsByDayBarChartAttribute;

export const EvolutionActivityBookingsByDayBarChart = ({
  bookingsByPeriod,
  comparisonData,
  chartWidth,
  className,
}: {
  bookingsByPeriod: ProStatsFetchResults['bookingsByPeriod'];
  comparisonData?: DashboardBookingsByPeriod[];
  chartWidth: number;
  className?: string;
}) => {
  const data = useMemo(
    () =>
      bookingsByPeriod.data.map((x, i) => {
        return {
          ...x,
          // bookingParticipantsCountAverage: chartHelper.buildAverageValue(
          //   bookingsByPeriod,
          //   {
          //     attribute: 'bookingParticipantsCount',
          //     averageSize: 3,
          //     index: i,
          //   },
          // ),
          // bookingParticipantsCancelledCountAverage:
          //   chartHelper.buildAverageValue(bookingsByPeriod, {
          //     attribute: 'bookingParticipantsCancelledCount',
          //     averageSize: 3,
          //     index: i,
          //   }),
        };
      }),
    [bookingsByPeriod],
  );

  const maxYValue: number = useMemo(() => {
    if (comparisonData) {
      const allMaxValues = bookingsByPeriod.data.map((x) => {
        return sumStackValues(x);
      });
      const allMaxValuesComparison = comparisonData.map((x) => {
        return sumStackValues(x);
      });
      return Math.max(...allMaxValues, ...allMaxValuesComparison);
    }
  }, [bookingsByPeriod.data, comparisonData]);

  const stepInterval = bookingsByPeriod.criteria.stepInterval;

  const tooltip = useMemo(
    () =>
      evolutionActivityBookingsByDayBarChartTooltipBuilder.build({
        stepInterval,
        colors: COLORS,
      }),
    [stepInterval],
  );

  return chartWidth > 0 ? (
    <div>
      <BarChart
        className={className}
        width={chartWidth}
        height={200}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="beginDate"
          tickFormatter={(value) => {
            return chartHelper.formatDatePeriod(value, {
              stepInterval,
              format: 'short',
            });
          }}
        />
        <YAxis
          tickFormatter={(value) => {
            return value;
          }}
          domain={
            maxYValue ? [0, maxYValue] : undefined // auto
          }
        />

        <Tooltip content={tooltip} />
        {/* <Tooltip

        formatter={(
          value: any,
          attributeName: ChartAttribute,
          chartItem,
          index,
          payload,
        ) => {
          const color = COLORS[attributeName];
          return [
            <span key="value" style={{ color }}>
              {value}
            </span>,
            attributeName === 'bookingParticipantsCount'
              ? 'Participants quotidients inscrits'
              : 'Participants quotidients inscrits (mais annulés ensuite)',
          ];
        }}
        labelFormatter={(value, payload) => {
          return dateService.formatUTC(value, 'DD/MM/YYYY');
        }}
      /> */}

        {/* <ReferenceLine x={today.getTime()} stroke="red" /> */}
        <Bar
          maxBarSize={30}
          stackId="1"
          dataKey={'bookingParticipantsCancelledCount'}
          type="monotone"
          stroke={COLORS['bookingParticipantsCancelledCount']}
          fill={COLORS['bookingParticipantsCancelledCount']}
        />
        <Bar
          maxBarSize={30}
          stackId="1"
          dataKey={'bookingParticipantsCount'}
          type="monotone"
          stroke={COLORS['bookingParticipantsCount']}
          fill={COLORS['bookingParticipantsCount']}
        />
      </BarChart>
      <p className="my-5 text-gray-400">
        <b className=" text-gray-500">NOTE:</b> les participants sont groupés
        par date de réservation, quelque soit la date de la prestation.
      </p>
    </div>
  ) : null;
};

function sumStackValues(x: DashboardBookingsByPeriod) {
  const stackValues: number[] = [];
  stackValues.push(x.bookingParticipantsCancelledCount);
  stackValues.push(x.bookingParticipantsCount);

  return stackValues.reduce((total, num) => total + num, 0);
}
