import { AppBookletPageGql_Company } from '@mabadive/app-common-model';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import { ecommerceFormDefaultImageBuilder } from '../EcommerceProductArticleEditor/services';
import {
  EcommerceProductEditorPayload,
  ecommerceProductEditorResultBuilder,
} from './ecommerceProductEditorResultBuilder.service';
import {
  EcommerceProductEditorEditMode,
  EcommerceProductEditorFormModel,
  EcommerceProductEditorFormModelDefaultValue,
  EcommerceProductEditorFormModelEditValue,
} from './model';
import { ecommerceProductEditorformBuilder } from './services';

export type EcommerceProductEditorInputProps = {
  mode: EcommerceProductEditorEditMode;
  categoryBookletPage: AppBookletPageGql_Company;
  editValue?: EcommerceProductEditorFormModelEditValue;
  defaultValue?: EcommerceProductEditorFormModelDefaultValue;
};
export type EcommerceProductEditorCallbackProps = {
  onCancel: () => any;
  onConfirm: (result: EcommerceProductEditorPayload) => any;
};

export type EcommerceProductEditorProps = EcommerceProductEditorInputProps & {
  callback: EcommerceProductEditorCallbackProps;
};
export function useEcommerceProductEditorPanelLocalState(
  inputProps: EcommerceProductEditorProps,
) {
  const { categoryBookletPage, defaultValue, editValue, mode, callback } =
    inputProps;

  const initialFormValueBase = useMemo<
    Partial<EcommerceProductEditorFormModel>
  >(() => {
    if (mode === 'edit' || mode === 'duplicate') {
      // on supprime tous les ids (en cas de clonage)
      const model: Partial<EcommerceProductEditorFormModel> = {
        product: {
          _id: null,
          ...editValue?.product,
        },
        categoryConfigs: (editValue?.categoryConfigs || []).map((item) => {
          return {
            _id: null,
            ...item,
          };
        }),
        appBooklet: {
          _id: null,
          ...editValue?.appBooklet,
        },
        appBookletPage: {
          _id: null,
          ...editValue?.appBookletPage,
        },
      };
      return model;
    }
    // create
    return ecommerceProductEditorformBuilder.buildInitialFormValue({
      defaultValue,
    });
  }, [defaultValue, editValue, mode]);

  const initialFormValue = useMemo(
    () =>
      ecommerceFormDefaultImageBuilder.setDefaultImagesFromParent({
        initialFormValueBase,
        parentBookletPage: categoryBookletPage,
      }),
    [categoryBookletPage, initialFormValueBase],
  );

  const form = useForm<EcommerceProductEditorFormModel>({
    defaultValues: initialFormValue,
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    formState,
    setValue,
  } = form;
  const [persistInProgress, setPersistInProgress] = useState(false);

  const promptDeleteConfirm = useCallback(() => {
    confirmDialog
      .confirm({
        title: 'Supprimer le produit',
        message: 'Êtes-vous sûr de vouloir supprimer ce produit ?',
        type: 'remove',
      })
      .subscribe(async (confirmed) => {
        if (confirmed) {
          setPersistInProgress(true);
          const productArticlesIds = (editValue?.productArticlesFull ?? []).map(
            (x) => x._id,
          );
          const bookletsIdsToDelete = (
            editValue?.productArticlesFull ?? []
          ).map((x) => x.bookletId);
          if (editValue?.appBooklet?._id) {
            bookletsIdsToDelete.push(editValue?.appBooklet?._id);
          }
          const bookletsPagesIdsToDelete = (
            editValue?.productArticlesFull ?? []
          ).reduce(
            (acc, x) => acc.concat(x.booklet.pages.map((x) => x._id)),
            [] as string[],
          );
          if (editValue?.appBookletPage?._id) {
            bookletsPagesIdsToDelete.push(editValue?.appBookletPage?._id);
          }
          try {
            const payload: EcommerceProductEditorPayload = {
              actionContext: 'delete product',
              hasChanges: true,
              appBooklet: {
                deletedIds: bookletsIdsToDelete,
              },
              appBookletPage: {
                deletedIds: bookletsPagesIdsToDelete,
              },
              ecommerceProduct: {
                deletedIds: editValue?.product?._id
                  ? [editValue?.product?._id]
                  : [],
              },
              ecommerceProductCategoryConfig: {
                deletedIds: editValue?.categoryConfigs?.length
                  ? editValue?.categoryConfigs.map((x) => x._id)
                  : [],
              },
              ecommerceProductArticle: {
                deletedIds: productArticlesIds,
              },
            };
            await callback.onConfirm(payload);
          } catch (err) {
            appLogger.error(
              '[EcommerceProductEditor] Error saving changes',
              err,
            );
            uiStore.snackbarMessage.set({
              type: 'error',
              content:
                'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
            });
          } finally {
            setPersistInProgress(false);
          }
        }
      });
  }, [
    callback,
    editValue?.appBooklet?._id,
    editValue?.appBookletPage?._id,
    editValue?.categoryConfigs,
    editValue?.product?._id,
    editValue?.productArticlesFull,
  ]);

  async function submit() {
    return handleSubmit(async (formValue: EcommerceProductEditorFormModel) => {
      try {
        if (persistInProgress) {
          return;
        }
        setPersistInProgress(true);
        if (mode === 'create' || mode === 'duplicate') {
          const payload: EcommerceProductEditorPayload =
            await ecommerceProductEditorResultBuilder.buildCreatePayload({
              formValue,
            });
          await callback.onConfirm(payload);
        } else {
          const payload: EcommerceProductEditorPayload =
            await ecommerceProductEditorResultBuilder.buildUpdatePayload({
              formValue,
              editValue,
            });
          if (payload.hasChanges) {
            await callback.onConfirm(payload);
          } else {
            callback.onCancel();
          }
        }
      } catch (err) {
        appLogger.error('[EcommerceProductEditor] Error saving changes', err);
        uiStore.snackbarMessage.set({
          type: 'error',
          content:
            'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
        });
      } finally {
        setPersistInProgress(false);
      }
    })();
  }

  return {
    data: {
      productArticlesFull: editValue?.productArticlesFull || [],
    },
    state: {
      mode,
      persistInProgress,
      form,
    },
    actions: {
      promptDeleteConfirm,
      submit,
      cancel: callback.onCancel,
    },
  };
}

export type EcommerceProductEditorLocalState = ReturnType<
  typeof useEcommerceProductEditorPanelLocalState
>;
