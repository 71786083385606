/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import {
  CreateOfferPayload,
  clubSettingsProductOfferBuilder,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export const ClubSettingsServicesViewPanelOffersPricesActionsBar_Training = ({
  localState,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
}) => {
  const { data, state, actions } = localState;
  const publicSettings = data.publicSettings;
  const clubReference = data.clubReference;
  const clubSettings = data.clubSettings;

  return data.trainingOrgsRefs?.length > 0 ? (
    <>
      {data.trainingOrgsRefs.map((orgReference, i) => (
        <AppButton
          className="whitespace-nowrap"
          key={orgReference}
          color={'primary-outline-light'}
          size="normal"
          icon={AppIcons.action?.add}
          onClick={(e) => {
            e.stopPropagation();
            const newOffers: CreateOfferPayload[] =
              clubSettingsProductOfferBuilder.buildNewOffersFromTrainingOrg(
                orgReference,
                {
                  clubReference,
                  publicSettings,
                  clubSettings,
                },
              );
            actions.openOfferEditDialog({
              mode: 'create',
              offers: newOffers,
            });
          }}
        >
          {orgReference.toUpperCase()}
        </AppButton>
      ))}
    </>
  ) : null;
};
