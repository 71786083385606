/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettings,
  ClubSettingsUIPlanning,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { useCallback, useState } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  PLANNING_CONFIG_CONTEXT_ID_PLANNING_PARTICIPANTS_VIEW,
  useDefaultSettingsUiPlanning,
  usePlanningConfig,
} from 'src/business/club/modules/club-planning-light/hooks';

export function useClubSettingsAccountDisplayPageLocalState() {
  const clubResume = useClubResume();

  const [fullScreenOnTouchDevices, setFullScreenOnTouchDevices] = useState(
    clubResume?.clubSettings?.ui?.fullScreenOnTouchDevices,
  );

  const [planningConfig, setPlanningConfig] = usePlanningConfig({
    enableRouteParams: false,
    context: PLANNING_CONFIG_CONTEXT_ID_PLANNING_PARTICIPANTS_VIEW,
    defaultFocusDate: new Date(),
  });

  const initialSettingsUiPlanning: ClubSettingsUIPlanning =
    useDefaultSettingsUiPlanning({
      planningConfig,
    });

  const [settingsUiPlanning, setSettingsUiPlanning] = useState(
    initialSettingsUiPlanning,
  );

  const [operationInProgressUiPlanning, setOperationInProgressUiPlanning] =
    useState<boolean>(false);

  const persistSettingsUiPlanning = useCallback(
    async (updated: ClubSettingsUIPlanning) => {
      const initialValue: ClubSettings = clubResume.clubSettings;
      const finalValue: ClubSettings = {
        ...initialValue,
        ui: {
          ...(initialValue.ui ?? ({} as any)),
          planning: updated,
        },
      };

      const patchOperations = jsonPatcher.compareObjects(
        initialValue,
        finalValue,
        {
          attributesToReplaceFully: [],
        },
      );
      if (patchOperations.length) {
        const updatedClubSettings = {
          pk: initialValue._id,
          patchOperations,
        };
        try {
          setOperationInProgressUiPlanning(true);
          await clubMassiveUpdatorClient.update({
            updatedClubSettings,
          });
        } finally {
          setOperationInProgressUiPlanning(null);
        }
      }
    },
    [clubResume.clubSettings],
  );

  const [operationInProgressFullScreen, setOperationInProgressFullScreen] =
    useState<boolean>(false);
  const persistSettingsUiFullScreenOnTouchDevices = useCallback(
    async (updated: boolean) => {
      const initialValue: ClubSettings = clubResume.clubSettings;
      const finalValue: ClubSettings = {
        ...initialValue,
        ui: {
          ...(initialValue.ui ?? ({} as any)),
          fullScreenOnTouchDevices: updated,
        },
      };

      const patchOperations = jsonPatcher.compareObjects(
        initialValue,
        finalValue,
        {
          attributesToReplaceFully: [],
        },
      );
      if (patchOperations.length) {
        const updatedClubSettings = {
          pk: initialValue._id,
          patchOperations,
        };
        try {
          setOperationInProgressFullScreen(true);
          await clubMassiveUpdatorClient.update({
            updatedClubSettings,
          });
        } finally {
          setOperationInProgressFullScreen(null);
        }
      }
    },
    [clubResume.clubSettings],
  );
  return {
    planningConfig,
    setPlanningConfig,
    initialSettingsUiPlanning,
    settingsUiPlanning,
    setSettingsUiPlanning,
    operationInProgressUiPlanning,
    operationInProgressFullScreen,
    persistSettingsUiPlanning,
    fullScreenOnTouchDevices,
    setFullScreenOnTouchDevices,
    persistSettingsUiFullScreenOnTouchDevices,
  };
}

export type ClubSettingsAccountDisplayPageLocalState = ReturnType<
  typeof useClubSettingsAccountDisplayPageLocalState
>;
