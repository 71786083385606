import {
  participantTagPaymentBuilder,
  ParticipantTagPaymentBuilderProps,
} from '@mabadive/app-common-services';
import React from 'react';
import {
  ColoredTagAvatarCard,
  ColoredTagAvatarCardProps,
} from './ColoredTagAvatarCard';

export const ColoredTagAvatarCardParticipantPayment = ({
  purchaseStatus,
  purchasePaymentStatus,
  avatarLabel,
  passStatus,
  isPast,
  ...tagProps
}: Omit<ColoredTagAvatarCardProps, 'tag'> &
  ParticipantTagPaymentBuilderProps) => {
  const tag = participantTagPaymentBuilder.buildTagPayment({
    purchaseStatus,
    purchasePaymentStatus,
    passStatus,
    avatarLabel,
    isPast,
  });

  return !tag ? null : <ColoredTagAvatarCard {...tagProps} tag={tag} />;
};
