/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubEventDialogInitialValueModel,
  ClubEventDialogStateDefaultValue,
  ClubEventDialogStateMode,
  ClubEventEditorFormModel,
} from './model';

export function useClubEventDialogInitialValue({
  originalEvent,
  defaultValue = {},
  mode,
}: {
  originalEvent?: ClubEventDialogInitialValueModel;
  defaultValue?: Partial<ClubEventDialogStateDefaultValue>;
  mode: ClubEventDialogStateMode;
}): ClubEventEditorFormModel {
  const clubResume = useClubResume();

  const initialFormValue: ClubEventEditorFormModel = useMemo(() => {
    if (mode === 'create') {
      const model: ClubEventEditorFormModel = {
        clubEvent: defaultValue,
      };
      return model;
    } else if (mode === 'edit') {
      // const date = dateService.getUTCDateWithoutTime(originalEvent?.time);
      const model: ClubEventEditorFormModel = {
        clubEvent: originalEvent,
      };
      return model;
    } else if (mode === 'clone') {
      const model: ClubEventEditorFormModel = {
        clubEvent: {
          ...originalEvent,
          _id: null,
          _createdAt: null,
          _updatedAt: null,
          _version: null,
        },
      };
      return model;
    }
  }, [defaultValue, originalEvent, mode]);

  return initialFormValue;
}
