/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  useClubDialogs,
  useClubDialogsActionsPersist,
  UseClubDialogsProps,
} from 'src/pages/_dialogs';
import {
  ClubSettingsSection,
  ClubSettingsViewAttribute,
} from 'src/pages/SE-settings/_core';

export const ClubSettingsGeneralMainViewPanelLocalisation = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();

  return (
    <ClubSettingsSection title="Localisation">
      <div className="flex gap-4 md:gap-x-8 flex-wrap">
        <ClubSettingsViewAttribute label={'Région / Pays'}>
          {regionalSettings?.countryLabel}
        </ClubSettingsViewAttribute>
        <ClubSettingsViewAttribute label={'Fuseau horaire'}>
          {clubSettings.general?.timeZoneName}
        </ClubSettingsViewAttribute>
      </div>
    </ClubSettingsSection>
  );
};
