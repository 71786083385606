import {
  ClubDiver,
  DiveMode,
  JsonPatchOperation,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  diveModeFormatter,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { BookingMassiveEditorInnerLocalState } from '../../../../components/BookingMassiveEditorDialog/useBookingMassiveEditorInnerLocalState.hook';
import {
  bookingParticipantEditorDialogChangesBuilderEditDiveMode,
  clubDiverChangesBuilder,
} from '../../../../components/BookingParticipantDialog/BookingParticipantEditorDialog/services/changes-builder';
import {
  DiverBookingPageUpdateState,
  PRO_BookingMemberFull,
} from '../../../../models';

export const defaultParametersDiveModeUpdator = {
  updateDefaultDiveMode,
};

async function updateDefaultDiveMode({
  bookingMemberFull,
  diveMode,
  localState,
}: {
  bookingMemberFull: PRO_BookingMemberFull;
  diveMode: DiveMode;
  localState: BookingMassiveEditorInnerLocalState;
}): Promise<void> {
  const {
    updateState,
    setUpdateState,
    participantsBySessions,
    bookingParticipantsWithSessionFull,
    data: { clubSettings },
  } = localState;

  const initialDiver = bookingMemberFull.diver;
  const finalDiver: ClubDiver = {
    ...initialDiver,
    defaultDiveConfig: initialDiver.defaultDiveConfig
      ? {
          ...initialDiver.defaultDiveConfig,
          diveMode,
        }
      : {
          diveMode,
        },
  };
  const patchOperations = jsonPatcher.compareObjects(initialDiver, finalDiver, {
    // else, value won't be deleted by typeorm
    // https://github.com/typeorm/typeorm/issues/2934
    replaceDeleteByNullValue: true,
  });

  if (patchOperations.length) {
    let updateStateLocal: DiverBookingPageUpdateState = {
      ...updateState,
      diversChanges: changeDescriptorManager.updateOne(
        {
          pk: initialDiver._id,
          patchOperations,
        },
        {
          changeDescriptors: updateState.diversChanges,
        },
      ),
    };

    const defaultDiveConfigJsonPatchOperations: JsonPatchOperation[] =
      clubDiverChangesBuilder.buildDefaultDiveConfigDiff({
        initialDiver,
        finalDiver,
      });

    if (defaultDiveConfigJsonPatchOperations.length) {
      const existingParticipantsFullDifferentDiveMode =
        bookingParticipantsWithSessionFull.filter(
          (x) =>
            x.bookingMemberFull.bookingMember._id ===
              bookingMemberFull.bookingMember._id &&
            x.bookingParticipantFull?.diveSessionParticipant &&
            x.bookingParticipantFull?.diveSessionParticipant?.diveMode !==
              diveMode,
        );

      if (existingParticipantsFullDifferentDiveMode.length) {
        const dontAskIfOnlyOneSession =
          participantsBySessions.length === 1 &&
          existingParticipantsFullDifferentDiveMode.length === 1;

        if (
          dontAskIfOnlyOneSession ||
          (await confirmDialog.confirmPromise({
            title: 'Vous avez modifié la prestation par défaut',
            message: `Souhaitez-vous appliquer le mode "${diveModeFormatter.formatDiveMode(
              diveMode,
              {
                format: 'long-label',
                diveModesConf: clubSettings?.ui?.diveMode,
              },
            )}" aux ${
              existingParticipantsFullDifferentDiveMode.length
            } inscriptions existantes?`,
            type: 'noYesInfo',
          }))
        ) {
          // TODO: il faudrait faire la même chose que dans onDiveModeChange (mais méthode déjà dupliquée à 2 endroits, donc il faudrait trouver un moyen de factoriser)

          const { updateState, hasChanges } =
            bookingParticipantEditorDialogChangesBuilderEditDiveMode.updateParticipantsAndProductsByPatchOperations(
              {
                participantsFull: existingParticipantsFullDifferentDiveMode,
                updateState: updateStateLocal,
                patchOperations: defaultDiveConfigJsonPatchOperations,
              },
            );

          if (hasChanges) {
            updateStateLocal = updateState;
          }
        }

        setUpdateState(updateStateLocal, {
          action: 'updateDefaultDiveMode',
        });
      } else {
        // pas d'autres prestations à impacter, mais il faut quand même enregistrer les paramètres par défaut
        setUpdateState(updateStateLocal, {
          action: 'updateDefaultDiveMode',
        });
      }
    }
  }
}
