import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
// supprime un plongeur qui n'a aucune réservation
export function useDiverBookingPageDeleteDiverOnly({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    ({ diverId }: { diverId: string }) => {
      const diversChanges = changeDescriptorManager.deleteOne(diverId, {
        changeDescriptors: updateState.diversChanges,
      });

      setUpdateState(
        {
          ...updateState,
          diversChanges,
        },
        {
          action: 'DiverBookingPageDeleteDiverOnly',
        },
      );
    },
    [setUpdateState, updateState],
  );
}
