import clsx from 'clsx';
import React, { FC } from 'react';
import { AppHeroIcons, AppIconElement } from '../../../icons';

export const AppMessageLight: FC<{
  type?: 'info' | 'success' | 'warn' | 'danger';
  hideBorder?: boolean;
  hideIcon?: boolean;
  icon?: AppIconElement;
  textClassName?: string;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}> = ({
  type = 'info',
  hideBorder,
  hideIcon,
  icon: iconInput,
  textClassName = ' text-xs sm:text-base md:text-base',
  className,
  children,
}) => {
  const MessageIcon: AppIconElement = hideIcon
    ? undefined
    : iconInput ?? AppHeroIcons.info;
  return (
    <div
      className={clsx(
        'app-py-content text-gray-500 flex gap-2 items-center',
        !hideBorder && 'px-2 border border-dashed ',
        !hideBorder &&
          (type === 'danger'
            ? 'border-status-error'
            : type === 'success'
            ? 'border-status-success'
            : type === 'warn'
            ? 'border-status-warn'
            : 'border-blue-400'),
        textClassName,
        className,
      )}
    >
      {MessageIcon && (
        <MessageIcon
          className={clsx(
            'flex-shrink-0 w-10 h-10',
            type === 'danger'
              ? 'text-status-error'
              : type === 'success'
              ? 'text-status-success'
              : type === 'warn'
              ? 'text-status-warn'
              : 'text-blue-400',
          )}
        />
      )}
      <div className="flex-grow">{children}</div>
    </div>
  );
};
