import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Loadable, useLoadable } from 'src/business/_core/data/hooks';
import { AuthRootSpaceState } from './AuthRootSpaceState.model';

export function useAuthRootSpace(): AuthRootSpaceState & Loadable {

  return useLoadable({
    debugName: 'useAuthRootSpace',
    load: () => {

      const stateArgs$ = combineLatest([
        of(undefined)
      ], (datLoader) => ({

      }));

      return stateArgs$.pipe(
        map(() => ({
          // empty model for now
        })),
      );
    },
  });
}