import { UrlBuilderQueryParams } from './UrlBuilderQueryParams.type';

export const urlBuilder = {
  build,
};

function build(
  url: string,
  {
    chunksToRemove,
    newChunk,
    queryParams,
  }: {
    chunksToRemove?: number;
    newChunk?: string;
    queryParams?: UrlBuilderQueryParams;
  },
) {
  const cleanUrl =
    chunksToRemove && chunksToRemove > 0
      ? removeLastChunks(url, chunksToRemove)
      : url;
  const newUrl = newChunk ? `${cleanUrl}/${newChunk}` : cleanUrl;
  const queryParamsKeys = Object.keys(queryParams).filter(
    (x) => queryParams[x] !== undefined && queryParams[x] !== null,
  );
  const queryParamsString =
    queryParams && queryParamsKeys.length
      ? `?${queryParamsKeys
          .map((name) => `${name}=${queryParams[name]}`)
          .join('&')}`
      : '';
  return newUrl + queryParamsString;
}

function removeLastChunks(url: string, nbToRemove = 1) {
  const chunks = url.split('/');
  if (chunks[chunks.length - 1].length === 0) {
    // url ends with '/'
    nbToRemove++;
  }
  return chunks.slice(0, chunks.length - nbToRemove).join('/');
}
