import { DiveServicePurchaseStatus } from '@mabadive/app-common-model';
import { diveServicePurchaseStatusFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  AppGenericStatusIcon,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { BillingTabParticipantPurchase } from '../../models';

export function BillingParticipantPurchaseResumePurchasePackageState({
  participantPurchase,
  opacity,
  className,
}: {
  participantPurchase: Pick<
    BillingTabParticipantPurchase,
    'isVirtual' | 'divesCounts' | 'isArchived'
  > & {
    validityStatus: DiveServicePurchaseStatus;
  };
  opacity?: 60;
  className?: string;
}) {
  const { isVirtual, divesCounts, isArchived } = participantPurchase;

  const totalConsumedCount = useMemo(
    () =>
      divesCounts.assigned +
      // divesCounts.toAssign +
      divesCounts.consumedExternalCount,
    [divesCounts.assigned, divesCounts.consumedExternalCount, ,],
  );
  const badgeStatus: AppGenericStatus = useMemo(() => {
    if (totalConsumedCount === divesCounts.total) {
      return 'info';
    }
    if (totalConsumedCount > divesCounts.total) {
      return 'error';
    }
    return 'active';
  }, [divesCounts.total, totalConsumedCount]);

  const badgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(badgeStatus),
    [badgeStatus],
  );

  return (
    <div className={className}>
      <div className="w-full flex flex-col items-start">
        <div
          className={`${badgeStatusClass} ${
            opacity === 60 ? 'text-opacity-60' : ''
          }`}
        >
          {!!divesCounts.total && (
            <span className={'font-bold'}>
              {totalConsumedCount}/{divesCounts.total}
            </span>
          )}
          {!isVirtual && (
            <AppGenericStatusIcon
              status={badgeStatus}
              className={'ml-1 inline w-4 h-4'}
            />
          )}
        </div>
      </div>
      <div
        className={`w-full pt-0.5 uppercase whitespace-nowrap font-bold text-gray-600 text-xs ${
          opacity === 60 ? 'text-opacity-60' : ''
        }`}
      >
        {participantPurchase.validityStatus ? (
          <div className="text-gray-500 text-xs">
            {diveServicePurchaseStatusFormatter.formatStatus(
              participantPurchase.validityStatus,
            )}
          </div>
        ) : (
          isArchived && <div className="text-gray-500 text-xs">ARCHIVÉ</div>
        )}
      </div>
    </div>
  );
}
