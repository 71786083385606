import { ProStatsFetchResults } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { DashboardReportPageMode } from 'src/pages/DA-dashboard/DA-02-dashboard-report/model';
import { DashboardMetricTitleWithValue } from 'src/pages/DA-dashboard/_core';
import { EvolutionParticipantsByDayBarChart } from './chart';

export const DashboardReportPageEvolutionParticipantsByDay = ({
  participantsByPeriod,
  chartWidth,
  pageMode,
  comparisonDetails,
}: {
  participantsByPeriod: ProStatsFetchResults['participantsByPeriod'];
  chartWidth: number;
  pageMode: DashboardReportPageMode;
  comparisonDetails: DashboardReportComparisonDetails<{}>;
}) => {
  const { comparisonFetchResult } = comparisonDetails;
  const comparisonData = comparisonFetchResult?.participantsByPeriod?.data;

  const label = useMemo(() => {
    if (pageMode === 'explos') {
      return participantsByPeriod.criteria.stepInterval === '1 day'
        ? 'Explorations quotidiennes'
        : participantsByPeriod.criteria.stepInterval === '1 week'
        ? 'Explorations hebdomadaires'
        : 'Explorations mensuelles';
    } else if (pageMode === 'trainings') {
      return participantsByPeriod.criteria.stepInterval === '1 day'
        ? 'Plongées de formation quotidiennes'
        : participantsByPeriod.criteria.stepInterval === '1 week'
        ? 'Plongées de formation hebdomadaires'
        : 'Plongées de formation mensuelles';
    } else if (pageMode === 'first-dives') {
      return participantsByPeriod.criteria.stepInterval === '1 day'
        ? 'Bâptêmes quotidiens'
        : participantsByPeriod.criteria.stepInterval === '1 week'
        ? 'Bâptêmes hebdomadaires'
        : 'Bâptêmes mensuels';
    }
    return participantsByPeriod.criteria.stepInterval === '1 day'
      ? 'Prestations quotidiennes'
      : participantsByPeriod.criteria.stepInterval === '1 week'
      ? 'Prestations hebdomadaires'
      : 'Prestations mensuelles';
  }, [pageMode, participantsByPeriod.criteria.stepInterval]);

  return (participantsByPeriod?.data?.length ?? 0) <= 1 ? null : (
    <>
      <div className={'app-card app-p-content'}>
        <DashboardMetricTitleWithValue
          label={label}
          description={`
            Du ${dateService.formatUTC(
              participantsByPeriod?.data[0].beginDate,
              'DD/MM/YYYY',
            )}
            au ${dateService.formatUTC(
              participantsByPeriod.data[participantsByPeriod.data.length - 1]
                .endDate,
              'DD/MM/YYYY',
            )}
          `}
          icon={EntitiesIcons.participants}
        />

        <EvolutionParticipantsByDayBarChart
          className="mt-5"
          chartWidth={chartWidth}
          participantsByPeriod={participantsByPeriod}
          showLegend={pageMode === 'home'}
          pageMode={pageMode}
          comparisonData={comparisonData}
        />
      </div>
    </>
  );
};
