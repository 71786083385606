/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import React, { FC } from 'react';
import { DiveSessionEditFormRHF } from '../../../../forms';
import { DiveSessionEditorDialogLocalState } from '../../useDiveSessionEditorDialogLocalState.hook';
import { DiveSessionDialogTab1EditInfoBox } from './DiveSessionDialogTab1EditInfoBox';

export const DiveSessionDialogTab1Edit: FC<{
  localState: DiveSessionEditorDialogLocalState;
}> = ({ localState }) => {
  const { form, mode, isOpen } = localState;
  const initialValue = localState.initialValue;
  const time = initialValue?.initialDiveSession?.time;

  return (
    <div className="px-2">
      <h2 className="text-center text-sm sm:text-lg leading-6 p-2 font-medium text-app-blue uppercase">
        {mode === 'create-session'
          ? 'Nouvelle session'
          : mode === 'edit-session'
          ? 'Modifier la session'
          : `Dupliquer la session du ${dateService.formatUTC(
              time,
              'dddd DD/MM/YYYY',
            )} à ${dateService.formatUTC(time, 'HH:mm')}`}
      </h2>
      <div>
        {isOpen && (
          <>
            <DiveSessionEditFormRHF
              form={form}
              mode={mode}
              cloneParticipantsCount={
                initialValue?.initialParticipants?.filter(
                  (x) => x.bookingState?.value !== 'cancelled',
                )?.length ?? 0
              }
            />
          </>
        )}
        <DiveSessionDialogTab1EditInfoBox />
      </div>
    </div>
  );
};
