/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPagePaymentTabLocalState } from './useDiverBookingPagePaymentTabLocalState';

export const DiverBookingPagePaymentTabFilterCountsBar = ({
  localState,
  globalState,
}: {
  localState: DiverBookingPagePaymentTabLocalState;
  globalState: DiverBookingPageGlobalState;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const {
    aggregatedData: aggregatedDataInput,
    updateState,
    focus,
    paymentTabFilterCriteria,
    setPaymentTabFilterCriteria,
    dialogsState,
  } = globalState;

  const { paymentTabModel: model } = localState;

  const totalHiddenItems =
    model.counts.hiddenPayments + model.counts.hiddenBookingDeposits;

  const totalOtherDiveCentersItems =
    model.counts.otherDiveCentersPayments +
    model.counts.otherDiveCentersBookingDeposit;

  return totalHiddenItems > 0 || totalOtherDiveCentersItems > 0 ? (
    <div className="my-1 flex justify-between gap-2 flex-wrap">
      <div className="flex gap-2 flex-wrap">
        {totalOtherDiveCentersItems > 0 && (
          <AppInputBooleanSwitch
            theme="danger"
            label={`paiements autres centres (${totalOtherDiveCentersItems})`}
            value={!paymentTabFilterCriteria.ignoreOtherDiveCenters}
            onChange={(value) =>
              setPaymentTabFilterCriteria({
                ...paymentTabFilterCriteria,
                ignoreOtherDiveCenters: !value,
              })
            }
          />
        )}
      </div>
      {model.counts.hiddenPayments > 0 && (
        <div className="font-bold text-app-red px-2">
          {model.counts.hiddenPayments} paiement
          {model.counts.hiddenPayments === 1 ? '' : 's'} masqué
          {model.counts.hiddenPayments === 1 ? '' : 's'}
        </div>
      )}{' '}
      {model.counts.hiddenBookingDeposits > 0 && (
        <div className="font-bold text-app-red px-2">
          {model.counts.hiddenBookingDeposits} acompte
          {model.counts.hiddenBookingDeposits === 1 ? '' : 's'} masqué
          {model.counts.hiddenBookingDeposits === 1 ? '' : 's'}
        </div>
      )}
    </div>
  ) : null;
};
