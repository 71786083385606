import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { retry } from 'rxjs/operators';
import { useGlobalClasses } from 'src/AppTheme';
import {
  LoadableContentActionStatus,
  LoadableContentState,
} from 'src/business/_core/data/app-loading';
import { useCacheableClasses } from '../../../root/theme';
import { useMediaSizeMaterialUI } from '../../services';
import { AppLoadableContentContainer } from '../AppLoadableContentContainer';

export const AppPageBlock: FC<{
  shrinkAndScroll?: boolean;
  contentState?: LoadableContentState;
  lastActionStatus?: LoadableContentActionStatus;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}> = ({
  children,
  shrinkAndScroll,
  className,
  contentState,
  lastActionStatus,
}) => {
  if (!shrinkAndScroll) {
    shrinkAndScroll = false;
  }

  const mediaSize = useMediaSizeMaterialUI();

  if (mediaSize === 'xs') {
    shrinkAndScroll = false;
  }

  const globalClasses = useGlobalClasses();

  const classes = useCacheableClasses({
    cacheKey: 'AppPageBlock',
    buildStyles: (theme) => buildStyles(theme),
  });

  return (
    <div
      className={`${globalClasses.pageBlock} ${
        classes.root
      } shrink-${shrinkAndScroll} scroll-${shrinkAndScroll} ${className ?? ''}`}
    >
      <AppLoadableContentContainer
        contentState={contentState}
        lastActionStatus={lastActionStatus}
        retry={retry}
      >
        {contentState !== 'none' && <>{children}</>}
      </AppLoadableContentContainer>
    </div>
  );
};

function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      maxWidth: 1920,
      '&.shrink-true': {
        flexShrink: 1,
      },
      '&.scroll-true': {
        overflowX: 'hidden',
        overflowY: 'auto',
      },
    },
  });
}
