/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BillingParticipantPurchaseResumeTable } from '../../components/BillingParticipantPurchaseResumeTable';
import { BillingTabParticipantPurchase } from '../../models';
import { DiverBookingPageActions } from './../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from './../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageBillingTabArchivedPurchasesYearly } from './DiverBookingPageBillingTabArchivedPurchasesYearly';
import { DiverBookingPageBillingTabState } from './useDiverBookingPageBillingTabState.hook';

export const DiverBookingPageBillingTabArchivedPurchases = ({
  globalState,
  localState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  localState: DiverBookingPageBillingTabState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const { aggregatedData, model } = localState;

  const [isExpanded, setIsExpanded] = useState(false);

  const inactiveParticipantPurchasesByYear = useMemo(() => {
    const map = new Map<number, BillingTabParticipantPurchase[]>();
    model.inactiveParticipantPurchases.forEach((purchase) => {
      const year = new Date(purchase.endDate).getFullYear();
      const purchases = map.get(year) || [];
      purchases.push(purchase);
      map.set(year, purchases);
    });
    return map;
  }, [model.inactiveParticipantPurchases]);
  const inactiveParticipantPurchasesByYearDesc = new Map(
    [...inactiveParticipantPurchasesByYear.entries()].sort(
      (a, b) => b[0] - a[0],
    ),
  );

  // test if only one year, and if this year

  const onlyThisYear = useMemo(() => {
    const keysArray = Array.from(inactiveParticipantPurchasesByYear.keys());
    if (keysArray.length === 1) {
      const isThisYear =
        keysArray.length === 1 && keysArray[0] === new Date().getFullYear();
      return isThisYear;
    }
    return false;
  }, [inactiveParticipantPurchasesByYear]);

  return (
    <div className="mt-8 mb-5 block">
      <h2 className="my-2 text-base sm:text-lg font-medium text-gray-800 leading-6 pt-0 mt-0 flex items-center">
        Prestations archivées ({model.inactiveParticipantPurchases.length})
      </h2>
      <p className="mb-2 text-gray-500 flex items-center">
        <AppHeroIcons.info className="w-4 h-4 mr-1" /> Prestations terminées et
        payées
      </p>

      {onlyThisYear ? (
        <BillingParticipantPurchaseResumeTable
          className="mb-1"
          billingTabLocalState={localState}
          participantPurchases={model.inactiveParticipantPurchases}
          globalState={globalState}
          dialogs={dialogs}
          actions={actions}
        />
      ) : (
        Array.from(inactiveParticipantPurchasesByYearDesc.entries()).map(
          ([year, purchases]) => (
            <DiverBookingPageBillingTabArchivedPurchasesYearly
              key={year}
              year={year}
              purchases={purchases}
              globalState={globalState}
              localState={localState}
              dialogs={dialogs}
              actions={actions}
            />
          ),
        )
      )}
    </div>
  );
};
