/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveCenterDailyConfig,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import { staffMemberResumeSessionsBuilder } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { AppFormControlV2, AppSingleAutocomplete2 } from 'src/lib/form';
import { staffMemberOptionsBuilder } from '../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/services';
import { ClubPlanningLightPanelViewPeriodDayStaffMembersList } from '../../club-planning-light/ClubPlanningLightPanelViewPeriodDay/ClubPlanningLightPanelViewPeriodDayStaff';
import { SetPlanningConfigOptions } from '../../club-planning-light/hooks';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
} from '../../club-planning-light/model';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningStaffPanelLocalState } from './ClubPlanningStaffPanel';

export const ClubPlanningStaffWeekStaffRoles = ({
  parentState,
  days,
  planningConfig,
  setPlanningConfig,
  clickActions,
  className,
}: {
  parentState: ClubPlanningStaffPanelLocalState;
  days: ClubPlanningDayFull[];
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  clickActions: ClubPlanningLightPanelViewClickActions;
  className?: string;
}) => {
  const weekSessions = useMemo(
    () =>
      days.reduce(
        (acc, d) => acc.concat(d.sessions),
        [] as ClubPlanningLightSessionDef[],
      ),
    [days],
  );

  const dailyConfigs = useMemo(
    () =>
      days.reduce((acc, d) => {
        if (d.dailyConfig) {
          acc.push(d.dailyConfig);
        }
        return acc;
      }, [] as DiveCenterDailyConfig[]),
    [days],
  );

  const diveCenterResume = useDiveCenterResume();

  const {
    diveSessions: weekDiveSessions,
    staffMembersResumeSessions,
  }: {
    diveSessions: ClubPlanningLightSessionDef[];
    staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
  } = useMemo(
    () =>
      staffMemberResumeSessionsBuilder.buildStaffMemberResumeSessions({
        diveCenterResume,
        sessions: weekSessions,
        staffFirstNameBefore: planningConfig.staffFirstNameBefore,
        staffScope: 'all-with-active-working-period',
        dailyConfigs,
        days,
      }),
    [
      dailyConfigs,
      days,
      diveCenterResume,
      planningConfig.staffFirstNameBefore,
      weekSessions,
    ],
  );

  const [localFilterStaffMemberId, setLocalFilterStaffMemberId] =
    useState<string>(
      parentState.criteria.filterStaffMemberId ??
        (staffMembersResumeSessions.length > 0
          ? staffMembersResumeSessions[0]?.staffMember._id
          : undefined),
    );

  const updateStaffMemberFilter = useCallback(
    ({ staffMemberId }: { staffMemberId?: string }) => {
      if (staffMemberId) {
        setLocalFilterStaffMemberId(staffMemberId);
      }
      if (parentState.criteria.filterStaffMemberId) {
        // on ne met à jour le filtre parent que si il existait déjà
        parentState.actions.updateStaffMemberFilter({ staffMemberId });
      }
    },
    [parentState.actions, parentState.criteria.filterStaffMemberId],
  );

  const staffMemberOptions = useMemo(
    () =>
      staffMemberOptionsBuilder.buildStaffMemberOptionsString({
        staffMembers: staffMembersResumeSessions.map((x) => x.staffMember),
        // date:
        //   weekDiveSessions.length > 0 ? weekDiveSessions[0].time : new Date(),
      }),
    [staffMembersResumeSessions],
  );

  const staffMembersResumeSessionsFiltered = useMemo(() => {
    return staffMembersResumeSessions
      .filter((x) => x.staffMember._id === localFilterStaffMemberId)
      .slice(0, 1);
  }, [localFilterStaffMemberId, staffMembersResumeSessions]);

  return (
    <div className={clsx('flex flex-col gap-4', className)}>
      <div className="flex justify-start">
        <AppFormControlV2
          className="w-80 max-w-full text-sm"
          label={'Filtrer par moniteur'}
          renderComponent={() => (
            <AppSingleAutocomplete2
              optionClassName="text-xs sm:text-sm"
              value={localFilterStaffMemberId}
              options={staffMemberOptions}
              disableClearable={true}
              // customRenderOption => TODO utiliser cette méthode pour afficher un rendu personnalié, comme via staffMemberOptionsBuilder.buildStaffMemberOptions
              onChange={(value) => {
                updateStaffMemberFilter({ staffMemberId: value });
              }}
            />
          )}
        />
      </div>
      {staffMembersResumeSessions.length > 0 ? (
        <ClubPlanningLightPanelViewPeriodDayStaffMembersList
          showStaffMember={true}
          showDate={true}
          planningConfig={planningConfig}
          dailySessions={weekDiveSessions}
          staffMembersDailySessions={staffMembersResumeSessionsFiltered}
          clickActions={clickActions}
        />
      ) : (
        <AppMessageLight>Aucun moniteur</AppMessageLight>
      )}
    </div>
  );
};
