import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { useDashboardExportFetchStats } from './useDashboardExportFetchStats.hook';
import { useDashboardExportPageViewParameters } from './useDashboardExportPageViewParameters';

export function useDashboardExportPageLocalState() {
  const { viewParameters, setViewParameters } =
    useDashboardExportPageViewParameters();

  const { beginDate, endDate } = viewParameters.periodRange.value;

  const { loadingInProgress, fetchResult } = useDashboardExportFetchStats({
    period: { beginDate, endDate },
  });

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    dailyExportConfig: {}, // ici on peut récupérer un callback si besoin
  });
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  return {
    state: {
      viewParameters,
      setViewParameters,
      loadingInProgress,
      dialogsState,
    },
    data: {
      fetchResult,
    },
  };
}

export type DashboardExportPageLocalState = ReturnType<
  typeof useDashboardExportPageLocalState
>;
