import { AppRichTextLeaf, AppUiTheme } from '@mabadive/app-common-model';
import React from 'react';
import {
  MdFormatAlignCenter,
  MdFormatAlignJustify,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatBold,
  MdFormatItalic,
} from 'react-icons/md';
import { useAppTheme } from 'src/business/club/data/hooks';
import { AppSlateEditor } from '../model';
import { AppRichTextEditorActionsBarButton } from './AppRichTextEditorActionsBarButton';
import { appRichTextEditorActionBarHelper } from './appRichTextEditorActionBarHelper.service';

export const AppRichTextEditorActionsBar = ({
  editor,
  marks,
}: {
  editor: AppSlateEditor;
  marks: Omit<AppRichTextLeaf, 'text'>;
}) => {
  const theme: AppUiTheme = useAppTheme();
  return (
    <div className="mb-1 bg-gray-800 p-1 flex gap-3">
      <div className="flex gap-1">
        <AppRichTextEditorActionsBarButton
          icon={MdFormatBold}
          title="Texte en gras <CTRL+B>"
          isActive={appRichTextEditorActionBarHelper.isMarkActive({
            marks,
            format: 'bold',
            markValue: true,
          })}
          onClick={() => {
            appRichTextEditorActionBarHelper.toggleMarkFormat({
              editor,
              marks,
              format: 'bold',
              markValue: true,
            });
          }}
        />
        <AppRichTextEditorActionsBarButton
          icon={MdFormatItalic}
          title="Texte en italique <CTRL+I>"
          isActive={appRichTextEditorActionBarHelper.isMarkActive({
            marks,
            format: 'italic',
            markValue: true,
          })}
          onClick={() => {
            appRichTextEditorActionBarHelper.toggleMarkFormat({
              editor,
              marks,
              format: 'italic',
              markValue: true,
            });
          }}
        />
      </div>
      <div className="flex gap-1">
        <AppRichTextEditorActionsBarButton
          icon={() => (
            <span
              className="px-1 font-bold text-lg mm:text-xl"
              style={{
                color: appRichTextEditorActionBarHelper.isBlockTypeActive({
                  editor,
                  type: 'heading-one',
                })
                  ? theme.title1?.textColor
                  : undefined,
              }}
            >
              Titre
            </span>
          )}
          isActive={appRichTextEditorActionBarHelper.isBlockTypeActive({
            editor,
            type: 'heading-one',
          })}
          onClick={() => {
            appRichTextEditorActionBarHelper.toggleBlockType({
              editor,
              type: 'heading-one',
            });
          }}
        />
        <AppRichTextEditorActionsBarButton
          icon={() => (
            <span
              className={'px-1 font-bold text-base mm:text-lg'}
              style={{
                color: appRichTextEditorActionBarHelper.isBlockTypeActive({
                  editor,
                  type: 'heading-two',
                })
                  ? theme.title2?.textColor
                  : undefined,
              }}
            >
              Sous-titre
            </span>
          )}
          isActive={appRichTextEditorActionBarHelper.isBlockTypeActive({
            editor,
            type: 'heading-two',
          })}
          onClick={() => {
            appRichTextEditorActionBarHelper.toggleBlockType({
              editor,
              type: 'heading-two',
            });
          }}
        />
      </div>
      <div className="flex gap-1">
        <AppRichTextEditorActionsBarButton
          icon={MdFormatAlignLeft}
          isActive={appRichTextEditorActionBarHelper.isBlockTextAlignActive({
            editor,
            textAlign: 'left',
            isDefault: true,
          })}
          onClick={() => {
            appRichTextEditorActionBarHelper.setBlockAttribute({
              editor,
              textAlign: 'left',
            });
          }}
        />
        <AppRichTextEditorActionsBarButton
          icon={MdFormatAlignCenter}
          isActive={appRichTextEditorActionBarHelper.isBlockTextAlignActive({
            editor,
            textAlign: 'center',
          })}
          onClick={() => {
            appRichTextEditorActionBarHelper.setBlockAttribute({
              editor,
              textAlign: 'center',
            });
          }}
        />
        <AppRichTextEditorActionsBarButton
          icon={MdFormatAlignRight}
          isActive={appRichTextEditorActionBarHelper.isBlockTextAlignActive({
            editor,
            textAlign: 'right',
          })}
          onClick={() => {
            appRichTextEditorActionBarHelper.setBlockAttribute({
              editor,
              textAlign: 'right',
            });
          }}
        />
        <AppRichTextEditorActionsBarButton
          icon={MdFormatAlignJustify}
          isActive={appRichTextEditorActionBarHelper.isBlockTextAlignActive({
            editor,
            textAlign: 'justify',
          })}
          onClick={() => {
            appRichTextEditorActionBarHelper.setBlockAttribute({
              editor,
              textAlign: 'justify',
            });
          }}
        />
        {/* <AppRichTextEditorActionsBarButton
          icon={MdPhotoSizeSelectLarge}
          isActive={appRichTextEditorActionBarHelper.isBlockTextAlignActive({
            editor,
            textAlign: 'right',
          })}
          onClick={() => {
            appRichTextEditorActionBarHelper.setBlockAttribute({
              editor,
              textAlign: 'right',
            });
          }}
        /> */}
        {/* <AppInputColor
          // color={value}
          onChange={(color) => {
            // onChange(color);
            // setIsOpen(false);
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          currentColor={''}
        /> */}
      </div>
      {/* <MarkButton format="underline" icon="format_underlined" />
          <MarkButton format="code" icon="code" />
          <AppRichTextEditorActionsBarButton  editor={editor} marks={marks} format="heading-two" icon="looks_two" />
          <AppRichTextEditorActionsBarButton  editor={editor} marks={marks} format="block-quote" icon="format_quote" />
          <AppRichTextEditorActionsBarButton  editor={editor} marks={marks} format="numbered-list" icon="format_list_numbered" />
          <AppRichTextEditorActionsBarButton  editor={editor} marks={marks} format="bulleted-list" icon="format_list_bulleted" /> */}
    </div>
  );
};
