/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EXPORT_SHEET_SETTINGS_ORIENTATION,
  ExportSheetSettingsOrientation,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
//import { RegionalTaxLabel } from '../company/RegionalTax/RegionalTaxLabel';
import { exportSheetSettingsOrientationFormatter } from '@mabadive/app-common-services';
export function useExportSheetSettingsOrientationOptions(): ValueLabel<
  ExportSheetSettingsOrientation,
  string
>[] {
  return useMemo(() => {
    const taxesOptions: ValueLabel<ExportSheetSettingsOrientation, string>[] =
      EXPORT_SHEET_SETTINGS_ORIENTATION.map((orientation) => ({
        value: orientation,
        label:
          exportSheetSettingsOrientationFormatter.formatOrientation(
            orientation,
          ),
      }));

    return taxesOptions;
  }, []);
}
