import {
  Booking,
  ClubDiver,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import {
  bookingMemberFullSorter,
  dataSorter,
  dateService,
} from '@mabadive/app-common-services';
import {
  AggregatedBookingSessionFull,
  DiverBookingPageAggregatedDataCore,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
  PRO_BookingResume,
} from '../../models';

export const diverBookingPageAggregatedBookingResumeBuilderBookingFull = {
  buildBookingFull,
};

function buildBookingFull({
  clubReference,
  aggregatedDataCore,
  booking,
  bookingParticipantsFull: participantFullProductsAll,
}: {
  clubReference: string;
  aggregatedDataCore: DiverBookingPageAggregatedDataCore;
  booking: Booking;
  bookingParticipantsFull: PRO_BookingParticipantFull[];
}): PRO_BookingResume {
  if (!booking) {
    return undefined;
  }

  const bookingParticipantsFull =
    sortEditionBookingResumeMembersByBirthDateDesc(
      participantFullProductsAll.filter((x) => x.booking?._id === booking._id),
    );

  const bookingSessionParticipants =
    aggregatedDataCore.bookingSessionParticipants.filter(
      (x) => x.bookingId === booking._id,
    );
  const participantIds = bookingSessionParticipants.map((x) => x.participantId);

  const onlineBookings = aggregatedDataCore.onlineBookings.filter(
    (x) => x.bookingId === booking._id,
  );
  const bookingSessions = aggregatedDataCore.bookingSessions.filter(
    (x) => x.bookingId === booking._id,
  );
  const bookingMembers = aggregatedDataCore.bookingMembers.filter(
    (x) => x.bookingId === booking._id,
  );

  const bookingDeposits = aggregatedDataCore.bookingDeposits.filter(
    (x) => x.bookingId === booking._id,
  );
  const creditNotes = aggregatedDataCore.creditNotes.filter(
    (x) => x.bookingId === booking._id,
  );

  const bookingCustomerUpdates =
    aggregatedDataCore.bookingCustomerUpdates.filter(
      (x) => x.bookingId === booking._id,
    );
  const bookingMembersFullNotSorted: PRO_BookingMemberFull_WithDocs[] =
    bookingMembers.map((bookingMember) => {
      const diver = aggregatedDataCore.divers.find(
        (x) => x._id === bookingMember.diverId,
      );

      const docResumes: PRO_AppDocResume[] =
        aggregatedDataCore.docResumes.filter(
          (x) => x.clubDiverId === diver._id,
        );

      const memberFull: PRO_BookingMemberFull_WithDocs = {
        booking,
        bookingMember,
        diver,
        docResumes,
      };
      return memberFull;
    });

  const bookingMembersFull = bookingMemberFullSorter.sort(
    bookingMembersFullNotSorted,
    {
      bookingContactDiverId: booking?.bookingContactDiverId,
    },
  );

  const bookingSessionsFull: AggregatedBookingSessionFull[] = bookingSessions
    .map((bookingSession) => {
      const diveSession = aggregatedDataCore.diveSessions.find(
        (x) => x.reference === bookingSession.diveSessionReference,
      );
      const isCreatedSession =
        aggregatedDataCore.created.bookingSessionsIds.includes(
          bookingSession._id,
        );
      const bookingSessionFull: AggregatedBookingSessionFull = {
        booking,
        diveSession,
        bookingSession,
        entityState: isCreatedSession ? 'created' : 'original', // TODO prendre en compte si besoin l'état 'updated'
      };
      return bookingSessionFull;
    })
    .filter(
      (bs) => !!bs.diveSession, // HACK: lors de la sauvegarde massive, il peut arriver que diveSession n'ait pas été trouvée, juste après le refresh post-enregistrement, donc on ignore la session pour ne pas tout casser
    );
  const clubParticipants = aggregatedDataCore.clubParticipants.filter((x) =>
    participantIds.includes(x._id),
  );

  const isNewBooking =
    aggregatedDataCore.created.bookingIds.find(
      (bookingId) => bookingId === booking._id,
    ) !== undefined;

  const datesRange: { minDate?: Date; maxDate?: Date } =
    bookingSessionsFull.reduce((acc, bs) => {
      const date = dateService.getUTCDateSetTime(bs.diveSession.time);
      if (!acc.minDate || dateService.isBefore(date, acc.minDate)) {
        acc.minDate = date;
      }
      if (!acc.maxDate || dateService.isBefore(acc.maxDate, date)) {
        acc.maxDate = date;
      }
      return acc;
    }, {} as { minDate?: Date; maxDate?: Date });

  const bookingContact: ClubDiver = bookingMembersFull.find(
    (x) => x.diver._id === booking.bookingContactDiverId,
  )?.diver;

  const aggregated: PRO_BookingResume = {
    isNewBooking,
    booking: {
      _id: booking._id,
      diveCenterId: booking.diveCenterId,
      bookingDate: booking.bookingDate,
      bookingLastUpdateDate: booking.bookingLastUpdateDate,
      active: booking.active,
      bookingStatus: booking.bookingStatus,
      bookingContactDiverId: booking.bookingContactDiverId,
      bookingJourney: booking.bookingJourney,
      bookingInitialPlan: booking.bookingInitialPlan,
      bookingCustomerConfig: booking.bookingCustomerConfig,
      bookingCustomerSpacePrivate: booking.bookingCustomerSpacePrivate,
      bookingGroup: booking.bookingGroup,
      details: booking.details,
      isSharedBooking: booking.isSharedBooking,
      clubReference,
    },
    onlineBookings,
    datesRange,
    bookingParticipantsFull,
    clubParticipants,
    bookingSessions,
    bookingMembers,
    bookingMembersFull,
    bookingSessionsFull,
    bookingCustomerUpdates,
    bookingContact,
    bookingDeposits,
    creditNotes,
  };
  // const bookingSessionParticipants =
  //   aggregatedDataCore.bookingSessionParticipants.filter(
  //     (x) => x.bookingId === booking._id,
  //   );
  // const participantIds = bookingSessionParticipants.map((x) => x.participantId);

  // bookingProducts.map((p) => p.bookingSessionParticipantId);

  // aggregated.clubParticipants = aggregatedDataCore.clubParticipants.filter(
  //   (x) => participantIds.includes(x._id),
  // );

  // aggregated.bookingResumeSessions = bookingResumeSessionsAggregator.aggregate({
  //   aggregatedDataCore,
  //   aggregatedBookingSessions: aggregated.bookingSessions,
  // });

  // aggregated.bookingResumeMembers =
  //   editionBookingResumeMemberAggregator.aggregate({
  //     updatedBooking,
  //     booking,
  //     aggregatedDataCore,
  //   });

  // const contactMember = aggregated.bookingResumeMembers.find(
  //   (m) => m.diverId === booking.bookingContactDiverId,
  // );

  // if (contactMember) {
  //   const bookingResumeContact: BookingResumeContact = {
  //     ...contactMember.diver,
  //   };
  //   aggregated.bookingResumeContact = bookingResumeContact;
  // }

  // const bookingParticipantsFull: PRO_BookingParticipantFull[] =
  //   [];

  // // sort members from younger to older
  // aggregated.bookingParticipantsFull =
  //   sortEditionBookingResumeMembersByBirthDateDesc(bookingParticipantsFull);

  // // aggregated.editionBookingResumeSessionParticipants =
  // //   editionBookingResumeSessionParticipantsAggregator.aggregate({
  // //     updatedBooking,
  // //     booking,
  // //     aggregated,
  // //   });

  // // aggregated.editionBookingResumeProducts =
  // //   editionBookingResumeProductsAggregator.aggregate({
  // //     aggregated,
  // //     bookingProducts,
  // //   });

  return aggregated;
}

function sortEditionBookingResumeMembersByBirthDateDesc(
  items: PRO_BookingParticipantFull[],
): PRO_BookingParticipantFull[] {
  return dataSorter.sortMultiple(items, {
    // eslint-disable-next-line no-sparse-arrays
    getSortAttributes: (x: PRO_BookingParticipantFull) => [
      {
        value: x?.diver?.birthdate,
        asc: false,
      },
      {
        value: x?.diver?.lastName,
        type: 'full-text',
        asc: true,
      },
      ,
      {
        value: x?.diver?.firstName,
        type: 'full-text',
        asc: true,
      },
    ],
    asc: true,
  });
}
