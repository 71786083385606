import { Breadcrumbs, IconButton, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, ReactNode } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { responsiveAttributesValues } from 'src/business/_core/modules/layout';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { appStylesHelper, useCacheableClasses } from '../../../root/theme';
import { useSideMenu } from '../SideMenu/useSideMenu.hook';
/**
 * @deprecated
 */
export const AppBreadcrumbBarDeprecated: FC<{
  hideCloseButton?: boolean;
  onClose?: () => void;
  extraContent?: ReactNode;
  hiddenSideMenu?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}> = ({ hideCloseButton, onClose, children, extraContent, hiddenSideMenu }) => {
  const globalClasses = useGlobalClasses();

  const classes = useCacheableClasses({
    cacheKey: 'AppBreadcrumbBar',
    buildStyles,
  });

  const { isMenuTemporary, isMenuCollapsed } = useSideMenu({
    hidden: hiddenSideMenu,
  });

  return (
    <>
      <Paper
        className={`max-w-screen-2xl ${classes.root} ${
          globalClasses.paper
        } fixed with-permanent-menu-${!isMenuTemporary} with-menu-collapsed-${isMenuCollapsed}`}
      >
        {renderContent()}
      </Paper>
      <div
        className={`${classes.root} ${globalClasses.paper} ${
          classes.fillHeight
        } with-permanent-menu-${!isMenuTemporary} with-menu-collapsed-${isMenuCollapsed}`}
      >
        {renderContent()}
      </div>
    </>
  );

  function renderContent() {
    return (
      <>
        <Breadcrumbs separator="›" className={classes.breakcrumbs}>
          {children}
        </Breadcrumbs>
        {extraContent ? (
          <div className={classes.extraContent}>{extraContent}</div>
        ) : null}
        {hideCloseButton ? null : (
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => (onClose ? onClose() : undefined)}
            className={classes.closeButton}
          >
            <AppIconsMaterial.close />
          </IconButton>
        )}
      </>
    );
  }
};

function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      background: 'white',
      '&.MuiPaper-rounded': {
        borderRadius: '0',
      },
      '&.with-permanent-menu-true.with-menu-collapsed-false': {
        ...responsiveAttributesValues(theme, {
          left: appStylesHelper.getSideMenuWidthResponsive(),
        }),
      },
      '&.with-permanent-menu-true.with-menu-collapsed-true': {
        left: appStylesHelper.getSideMenuCollapsedWidth(),
      },
      '&.fixed': {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 50,
        zIndex: 1000,
      },
    },
    fillHeight: {
      width: '100%',
      visibility: 'hidden',
      marginBottom: '0px',
    },
    closeButton: {
      width: '50px',
      height: '50px',
      color: 'rgba(0, 0, 0, 0.54)',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
      marginTop: '-5px',
      marginBottom: '-20px',
      marginLeft: '10px',
    },
    extraContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    breakcrumbs: {
      flexGrow: 1,
      padding: theme.spacing(1),
      '& .link': {
        display: 'flex',
        textDecoration: 'none',
        fontSize: theme.typography.body1.fontSize,
        '@media (min-width:600px)': {
          fontSize: '1rem',
        },
        // lighten(theme.palette.primary.main, 0.2),
        color: theme.palette.primary.light,
        fontWeight: 600,
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      '& .end-text': {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 600,
        '@media (min-width:600px)': {
          fontSize: '1rem',
        },
        color: theme.palette.text.secondary,
      },
      '& .icon': {
        marginTop: 3,
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
      },
    },
  });
}
