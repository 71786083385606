import {
  DiveSession,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubPlanningPanelSessionDisplayStyle } from 'src/business/club/modules/club-planning-light/model';
import { diveSessionTitleBuilder } from '../../../services';
import { ClubDiveSessionBoats } from '../components/ClubDiveSessionBoats';
import { DiveSiteIconDef, useDiveSiteIcon } from '../hooks';
import { ClubDiveSessionNameLight } from './ClubDiveSessionNameLight';

export const ClubDiveSessionHeaderTitleWithPrefix = function ({
  sessionIndex,
  diveSession: session,
  selectionColor,
  isHistoryPage,
  className,
}: {
  sessionIndex: number;
  isHistoryPage?: boolean;
  selectionColor?: ClubPlanningPanelSessionDisplayStyle;
  diveSession: Pick<
    DiveSession,
    | 'name'
    | 'status'
    | 'special'
    | 'time'
    | 'diveSiteId'
    | 'boatsIds'
    | 'diveTourSession2'
    | 'emojiId'
  >;
  className?: string;
}) {
  const clubResume = useClubResume();

  const time = session.time;

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(time),
    [time],
  );
  const isPast = useMemo(
    () => !dateService.isTodayUTC(time) && dateService.isPastUTC(time),
    [time],
  );
  const sessionTitle = useMemo(
    () => diveSessionTitleBuilder.build(session),
    [session],
  );
  const diveSiteIcon: DiveSiteIconDef = useDiveSiteIcon(
    session?.diveSiteId,
    session.diveTourSession2?.diveSiteId,
  );

  const hasAnySessionName: boolean =
    session.status !== 'on' ||
    !!diveSiteIcon ||
    !!session?.emojiId ||
    !!sessionTitle ||
    !!session.diveTourSession2;
  return (
    <div
      className={
        (clsx(
          'max-w-full flex gap-1 font-bold ',
          selectionColor
            ? `bg-app-${selectionColor} text-white`
            : `bg-day-period-${timeDayPeriod}-light text-gray-600`,
        ),
        className)
      }
    >
      <div
        className={clsx(
          selectionColor
            ? `bg-app-${selectionColor}`
            : `bg-day-period-${timeDayPeriod}-dark`,
          ' text-white py-0.5 px-1',
          isHistoryPage && 'min-w-[70px] text-center',
        )}
      >
        {dateService.formatUTC(time, 'HH:mm')}
      </div>

      <div className={'flex gap-1 items-center overflow-hidden py-0.5'}>
        {(!isHistoryPage || !hasAnySessionName) && (
          <ClubDiveSessionBoats
            diveSession={session}
            className="ml-0.5 sm:ml-1"
          />
        )}

        {hasAnySessionName && (
          <ClubDiveSessionNameLight
            className={'font-bold'}
            diveSession={session}
            truncateText={true}
          />
        )}
      </div>
    </div>
  );
};
