/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsCommunicationPageLocalState } from '../../../useClubSettingsCommunicationPageLocalState.hook';
import { ClubSettingsCommunicationPageBasePanel } from '../../_common';
import { ClubSettingsCommunicationMain_InfoMessage } from '../_common';
import { ClubSettingsCommunicationMainViewPanel_Booking } from './ClubSettingsCommunicationMainViewPanel_Booking';
import { ClubSettingsCommunicationMainViewPanel_Channels } from './ClubSettingsCommunicationMainViewPanel_Channels';
import { ClubSettingsCommunicationMainViewPanel_Customers } from './ClubSettingsCommunicationMainViewPanel_Customers';
import { ClubSettingsCommunicationMainViewPanel_GeneralOptions } from './ClubSettingsCommunicationMainViewPanel_GeneralOptions';
import { ClubSettingsCommunicationMainViewPanel_Session } from './ClubSettingsCommunicationMainViewPanel_Session';

export const ClubSettingsCommunicationMainViewPanel = ({
  localState,
}: {
  localState: ClubSettingsCommunicationPageLocalState;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  const generalSettings = clubSettings.general;
  return (
    <ClubSettingsCommunicationPageBasePanel
      localState={localState}
      onClick={() => {
        state.editEnabled && dialogs.communication.open(selectedTab);
      }}
    >
      <div className="grid gap-4 md:gap-6">
        <ClubSettingsCommunicationMainViewPanel_Channels />
        <ClubSettingsCommunicationMainViewPanel_Booking />
        <ClubSettingsCommunicationMainViewPanel_Session />
        <ClubSettingsCommunicationMainViewPanel_Customers />
        <ClubSettingsCommunicationMainViewPanel_GeneralOptions />

        <ClubSettingsCommunicationMain_InfoMessage />
      </div>
    </ClubSettingsCommunicationPageBasePanel>
  );
};
