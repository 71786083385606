/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import React, { useCallback } from 'react';
import { MdCallMerge } from 'react-icons/md';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { usePageWidth } from 'src/business/_core/modules/layout/services/usePageWidth.hook';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BookingTabMultiBookingsAutoFixSuggestionsDatesOverlap } from '../../../models';
import { bookingPageUpdateStateBookingMerger } from '../../../services/02.update-state/services';
import { DiverBookingPageActions } from '../../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../../useDiverBookingPageGlobalState.hook';

export const DiverBookingPageBookingTabHeaderAutoFixDatesOverlap = ({
  globalState,
  dialogs,
  actions,
  datesOverlap,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  datesOverlap: BookingTabMultiBookingsAutoFixSuggestionsDatesOverlap[];
}) => {
  const pageWidth = usePageWidth({ ignoreMenu: false });

  const {
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
  } = globalState;

  const {
    openDiverSelector,
    openSessionDialog,
    openSessionSelector,
    createNewBooking,
    deleteDiverOnly,
  } = actions;

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const applyAutoFixNow = useCallback(() => {
    let updateStateLocal = updateState;
    updateStateLocal = datesOverlap.reduce((updateState, { tabModels }) => {
      let updateStateLocal = updateState;
      const bookingSources = tabModels.slice(0, tabModels.length - 1);
      const bookingTarget = tabModels[tabModels.length - 1];
      // merge bookings
      updateStateLocal = bookingPageUpdateStateBookingMerger.mergeBookings({
        clubReference,
        aggregatedData,
        updateState: updateStateLocal,
        bookingSources,
        bookingTarget,
      });
      return updateStateLocal;
    }, updateStateLocal);
    setUpdateState(updateStateLocal, {
      action: 'DiverBookingPageBookingTabHeaderAutoFixDatesOverlap',
    });
  }, [
    aggregatedData,
    clubReference,
    setUpdateState,
    datesOverlap,
    updateState,
  ]);

  // useEffect(() => {
  //   if (datesOverlap.length !== 0) {
  //     appLogger.warn(
  //       '[DiverBookingPageBookingTabHeaderAutoFixDatesOverlap] apply auto-fix',
  //     );
  //     // on applique automatiquement le patch (car visiblement, les clients ne le font pas forcément)
  //     applyAutoFixNow(); => NOTE: non, car si il y a un bug, ça fait péter la résa
  //   }
  // }, [applyAutoFixNow, datesOverlap.length]);

  return datesOverlap.length !== 0 ? (
    <AppMessage
      className="mb-5"
      type="info"
      title={'Les dates des réservations se chevauchent'}
      message={
        <h3 className="text-sm sm:text-base">
          Mabadive a détecté des réservations dont les dates se chevauchent:
        </h3>
      }
    >
      <div className="">
        <ul className="font-normal text-gray-600">
          {datesOverlap.map(({ minDate, maxDate, tabModels }, i) => (
            <li key={i} className="text-sm sm:text-base">
              <div>
                <span className="text-gray-600 font-bold">
                  Du {dateService.formatUTC(minDate, 'DD/MM/YYYY')} au{' '}
                  {dateService.formatUTC(maxDate, 'DD/MM/YYYY')}
                </span>
                : <span>{tabModels.length} réservations </span>
              </div>
              <table
                className={
                  'my-2 w-full max-w-screen-sm divide-y divide-gray-200 border-2 border-gray-400'
                }
              >
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Période
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Plongées
                    </th>

                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Plongeurs
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-100 divide-y divide-gray-200">
                  {tabModels.map((tabModel, bookingIndex) => (
                    <tr key={bookingIndex}>
                      <td className="px-2 py-1 whitespace-nowrap text-sm font-bold">
                        N°{tabModel.bookingNumber}
                      </td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm font-medium">
                        {dateService.formatUTC(
                          tabModel.meta.datesRange.minDate,
                          'DD/MM/YYYY',
                        )}{' '}
                        -{' '}
                        {dateService.formatUTC(
                          tabModel.meta.datesRange.maxDate,
                          'DD/MM/YYYY',
                        )}
                      </td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm font-medium">
                        {tabModel.participantsBySessions.length}
                      </td>
                      {
                        <td className="px-2 py-1 whitespace-nowrap text-sm font-medium">
                          <span className="font-bold">
                            {tabModel.aggregatedBooking.bookingMembers.length}{' '}
                            plongeur(s)
                          </span>
                          <span className="hidden md:inline text-xs">
                            {': '}
                            {tabModel.aggregatedBooking.bookingMembersFull
                              .map(
                                (bm, memberIndex) =>
                                  nameFormatter.formatFirstName(bm.diver) ??
                                  `n°${memberIndex + 1}`,
                              )
                              .join(', ')}
                          </span>
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-5 text-left">
        <AppButton
          className="font-bold"
          style={{ minWidth: '6rem' }}
          size="normal"
          color={'primary-bg'}
          icon={MdCallMerge}
          onClick={() => applyAutoFixNow()}
        >
          Fusionner ces réservations
        </AppButton>
      </div>
    </AppMessage>
  ) : null;
};
