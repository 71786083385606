export class ApiClientHttpError extends Error {
  status: number;
  response: Response;
  cause?: Error;

  constructor({ response, cause }: { response: Response; cause?: Error }) {
    super(`ApiClientHttpError status "${response.status}"`);
    this.response = response;
    this.status = response ? response.status : undefined;
    this.cause = cause;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiClientHttpError.prototype);
  }
}
