import clsx from 'clsx';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  DashboardReportPageEvolutionActivityBookingsByDay,
  DashboardReportPageEvolutionActivityPaymentsByDay,
  DashboardReportPageEvolutionParticipantsByDay,
  DashboardReportPageGlobalActivityBookings,
  DashboardReportPageGlobalActivityParticipants,
  DashboardReportPageGlobalActivityPayments,
} from '../_core';

import { DashboardReportComparison } from './DashboardReportComparison';
import {
  DashboardReportComparisonContext,
  DashboardReportPageLocalState,
} from './useDashboardReportPageLocalState.hook';

export const DashboardReportPanelHome = ({
  chartWidth,
  totalColsCount,
  localState,
  comparisonContext,
  className,
}: {
  chartWidth: number;
  totalColsCount: number;
  localState: DashboardReportPageLocalState;
  comparisonContext: DashboardReportComparisonContext;
  className?: string;
}) => {
  const { uiConfig, setUIConfig } = localState?.state;

  // const [exportToken, setExportToken] = useState<string>();
  const redirectTo = useRedirect();

  return (
    <div className="w-full flex flex-col gap-5">
      <DashboardReportComparison
        comparisonContext={comparisonContext}
        render={(fetchResult) => (
          <div
            className={clsx(
              'w-full flex flex-col gap-5 ',
              totalColsCount === 1
                ? ' sm:grid sm:grid-cols-2'
                : ' 2xl:grid 2xl:grid-cols-2',
              totalColsCount === 1 && '2xl:grid-cols-4',
            )}
          >
            <DashboardReportPageGlobalActivityParticipants
              title="Activités"
              participantsOverPeriod={fetchResult.participantsOverPeriod}
            />
            <DashboardReportPageGlobalActivityBookings
              title="Réservations"
              bookingsOverPeriod={fetchResult.bookingsOverPeriod}
            />
            <DashboardReportPageGlobalActivityPayments
              title="Paiements"
              className="sm:col-span-2"
              paymentsOverPeriod={fetchResult.paymentsOverPeriod}
            />
          </div>
        )}
      />

      <DashboardReportComparison
        comparisonContext={comparisonContext}
        render={(fetchResult, comparisonDetails) => (
          <DashboardReportPageEvolutionParticipantsByDay
            chartWidth={chartWidth}
            participantsByPeriod={fetchResult.participantsByPeriod}
            comparisonDetails={comparisonDetails}
            pageMode="home"
          />
        )}
      />
      <DashboardReportComparison
        comparisonContext={comparisonContext}
        render={(fetchResult, comparisonDetails) => (
          <DashboardReportPageEvolutionActivityBookingsByDay
            chartWidth={chartWidth}
            bookingsByPeriod={fetchResult.bookingsByPeriod}
            comparisonDetails={comparisonDetails}
          />
        )}
      />
      <DashboardReportComparison
        comparisonContext={comparisonContext}
        render={(fetchResult, comparisonDetails) => (
          <DashboardReportPageEvolutionActivityPaymentsByDay
            chartWidth={chartWidth}
            paymentsByPeriod={fetchResult?.paymentsByPeriod}
            comparisonDetails={comparisonDetails}
          />
        )}
      />
    </div>
  );
};
