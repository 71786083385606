import React, { useMemo } from 'react';
import { useAppRouter } from '../../../business/_core/data/hooks/useAppRouter';
import {
  AppTabTailwind,
  AppTabsTailwind,
} from '../../../business/_core/modules/layout';

export type AdminPageTabId = 'companies' | 'operations';

export function AdminPageTabsPanel({
  currentTabId,
}: {
  currentTabId: AdminPageTabId;
}) {
  const appRouter = useAppRouter();

  //   const [tab, setTab] = useState<AdminPageTabId>('companies');
  const tabs = useMemo(() => {
    const tabs: AppTabTailwind<AdminPageTabId>[] = [
      {
        id: 'companies',
        label: 'Comptes',
      },

      {
        id: 'operations',
        label: 'Opérations',
      },
    ];
    return tabs;
  }, []);

  return (
    <AppTabsTailwind
      className="w-full"
      tabs={tabs}
      selected={currentTabId}
      theme="pills"
      breakpoint="sm"
      onChange={(tabId) =>
        appRouter.navigate(`/admin/${tabId}`, {
          cause: 'tab nav',
        })
      }
    />
  );
}
