/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS,
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE,
  ClubPlanningLightSessionDef,
  ClubResumeStaffMember,
  DiveSession,
  StaffMemberDailyAvailabilityResume,
  StaffMemberResumeSessionsDayModel,
  StaffMemberResumeSessionsDayModelSessionDetails,
} from '@mabadive/app-common-model';
import {
  dateService,
  staffMemberAvailabilityFinder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import {
  AppInputBooleanSwitch,
  AppSingleSelect2HeadlessUI,
} from 'src/lib/form';
import { useDailyAvailabilityPeriodOptions } from '../../../../../../pages/_components/options';
import {
  DiveSessionStaffMemberSessionTimes,
  DiveSessionStaffRoleModel,
} from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components';
import { StaffRoleBadge } from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components/DiveSessionStaffMembersTable/StaffRoleBadge';
import { ClubPlanningStaffWeekStaffPresenceLocalState } from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';
import { useDayStaffMemberRoles } from './useDayStaffMemberRoles.hook';

export const ClubPlanningStaffWeekStaffPresenceMembersEditDay = ({
  staffMember,
  staffMemberResumeSessionsDay,
  localState,
  className,
}: {
  staffMember: ClubResumeStaffMember;
  staffMemberResumeSessionsDay: StaffMemberResumeSessionsDayModel;
  localState: ClubPlanningStaffWeekStaffPresenceLocalState;
  className?: string;
}) => {
  const {
    data,
    actions,
    criteria,
    state: { showDayWithoutSessionsDetails },
  } = localState;

  const { dailyConfig, sessionsDetails } = staffMemberResumeSessionsDay;

  const roles: DiveSessionStaffRoleModel[] = useDayStaffMemberRoles({
    staffMemberResumeSessionsDay,
    availableRoles: data.aggregateData.availableRoles,
  });

  const day = useMemo(
    () =>
      localState.data.days.find(
        (d) => d.dayReference === staffMemberResumeSessionsDay.dayReference,
      ),
    [localState.data.days, staffMemberResumeSessionsDay.dayReference],
  );
  const showDetails =
    day.diveSessionsNotCanceled.length > 0 || showDayWithoutSessionsDetails;

  // const roles: DiveSessionStaffRoleModel[] = useMemo(
  //   () => rolesAll.filter((x) => x.active || x.assigned),
  //   [rolesAll],
  // );

  const isActiveOrAssigned = useMemo(
    () =>
      staffMemberResumeSessionsDay.dailyActiveRoles.length +
        staffMemberResumeSessionsDay.dailyAssignedRoles.length >
        0 ||
      staffMemberResumeSessionsDay.sessionsDetails.find(
        (x) =>
          x.staffMemberFull?.sessionAssigmentResume?.staffMemberConfig
            ?.assigned === true,
      ) !== undefined,
    [
      staffMemberResumeSessionsDay.dailyActiveRoles.length,
      staffMemberResumeSessionsDay.dailyAssignedRoles.length,
      staffMemberResumeSessionsDay.sessionsDetails,
    ],
  );

  const showTimes =
    (isActiveOrAssigned || criteria.showOffTimesToggleButtons) &&
    staffMemberResumeSessionsDay.availableOrActiveToday;
  const showRoles =
    isActiveOrAssigned &&
    staffMemberResumeSessionsDay.availableOrActiveToday &&
    criteria.displayRoles;

  const showToogleDay =
    !isActiveOrAssigned &&
    !(
      criteria.showOffTimesToggleButtons &&
      staffMemberResumeSessionsDay.availableOrActiveToday
    );

  const onClickSession = useCallback(
    async (
      session: ClubPlanningLightSessionDef,
      e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    ) => {
      e.stopPropagation();
      if (criteria.showOffTimesToggleButtons) {
        const sessionDetails = sessionsDetails.find(
          (x) =>
            x.session.reference === session.reference &&
            x.staffMemberFull.staffMember._id === staffMember._id,
        );
        const hasActiveRoles =
          sessionDetails.staffMemberFull.sessionAssigmentResume.activeRoles
            .length > 0;

        if (hasActiveRoles) {
          // assigné à une palanquée, on ne peut pas le supprimer: ouverture du détail de la session
          if (data.cache.updateState.hasChanges) {
            // on sauvegarde les changements avant d'ouvrir la session
            await actions.persistChanges();
          }

          return localState.actions.clickActions.onClickEditSessionDiveGroups(
            session,
          );
        } else {
          // affectation rapide

          const staffMemberFull = sessionDetails.staffMemberFull;

          const diveSession = sessionDetails.session as unknown as DiveSession;
          // const session = sessionDetails.session

          const dailyAvailabilityResume: StaffMemberDailyAvailabilityResume =
            staffMemberAvailabilityFinder.getDailyAvailabilityResume({
              dailyConfig,
              date: day.date,
              staffMember,
            });
          const staffRoleRef = 'scuba-diving-instructor';
          const assigned =
            staffMemberFull.sessionAssigmentResume.assignedRoles.includes(
              staffRoleRef,
            );
          actions.updateSessionStaffConfig(staffMember, {
            diveSession,
            availabilityPeriod: dailyAvailabilityResume.availabilityPeriod,
            isAvailableOnSession: !staffMemberFull?.meta?.available,
            toogleRole:
              assigned === undefined
                ? undefined
                : {
                    assigned,
                    staffRoleRef,
                  },
          });
        }
      } else {
        // ouverture du détail de la session
        return localState.actions.clickActions.onClickEditSessionDiveGroups(
          session,
        );
      }
    },
    [
      actions,
      criteria.showOffTimesToggleButtons,
      dailyConfig,
      data.cache.updateState.hasChanges,
      day.date,
      localState.actions.clickActions,
      sessionsDetails,
      staffMember,
    ],
  );

  const isPast =
    !dateService.isTodayUTC(day.date) && dateService.isPastUTC(day.date);

  const availableOrActiveToday =
    staffMemberResumeSessionsDay.availableOrActiveToday;

  const availabilityPeriodOptions = useDailyAvailabilityPeriodOptions({
    periods: staffMemberResumeSessionsDay.activeToday
      ? CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE
      : CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS,
  });

  return (
    <div
      className={clsx('text-gray-600 border-l border-gray-200 pl-1', className)}
      onClick={(e) => {
        if (
          criteria.pageMode === 'edit' &&
          !isActiveOrAssigned &&
          !availableOrActiveToday
        ) {
          actions.updateDayConfig({
            staffMemberResumeSessionsDay,
            availabilityPeriod: 'day',
            staffMember: staffMember,
          });
        }
      }}
    >
      <div
        className={clsx(
          // 'max-w-[150px] mx-auto',
          !showDetails && 'bg-gray-200 opacity-60 grayscale-[60%]',
        )}
      >
        {showTimes && (
          <DiveSessionStaffMemberSessionTimes
            className={'w-full'}
            fontSize={'text-sm'}
            sessionsDetails={staffMemberResumeSessionsDay.sessionsDetails}
            timesToDisplay={
              criteria.showOffTimesToggleButtons ? 'all' : 'assigned'
            }
            onClickSession={(
              sessionDetails: StaffMemberResumeSessionsDayModelSessionDetails,
              e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
            ) => onClickSession(sessionDetails.session, e)}
            filterCancelled={true}
            filterPastAndEmpty={true}
            focusActiveRoles={true}
            isPast={isPast}
          />
        )}
        {showToogleDay &&
          (!staffMemberResumeSessionsDay.availableOrActiveToday ? (
            <AppInputBooleanSwitch
              theme={'info'}
              value={staffMemberResumeSessionsDay.availableOrActiveToday}
              label={
                <div className="hidden md:inline text-app-xxs xl:text-xs text-gray-600">
                  Assigné
                </div>
              }
              labelToggleFalse={
                <div className="hidden md:inline text-app-xxs xl:text-xs text-gray-400">
                  Absent
                </div>
              }
            />
          ) : (
            <div onClick={(e) => e.stopPropagation()}>
              <AppSingleSelect2HeadlessUI
                theme={'tailwind'}
                value={staffMemberResumeSessionsDay?.availabilityPeriod}
                options={availabilityPeriodOptions}
                disableClearButton={true}
                optionsStyle={{}}
                className="text-app-primary-light"
                onChange={(availabilityPeriod) => {
                  actions.updateDayConfig({
                    staffMemberResumeSessionsDay,
                    availabilityPeriod,
                    staffMember: staffMember,
                  });
                }}
              />
            </div>

            // <div className="grid grid-cols-2">
            //   <AppFormControlV2
            //     label="AM"
            //     // helpDescription="Matin"
            //     renderComponent={() => (
            //       <AppInputBooleanSwitch
            //         theme={'success'}
            //         value={staffMemberResumeSessionsDay.availableOrActiveToday}
            //         disabled={criteria.pageMode !== 'edit'}
            //         // label={
            //         //   <div className="hidden md:inline text-app-xxs xl:text-xs text-gray-600">
            //         //     Dispo
            //         //   </div>
            //         // }
            //         // labelToggleFalse={
            //         //   <div className="hidden md:inline text-app-xxs xl:text-xs text-gray-400">
            //         //     Absent
            //         //   </div>
            //         // }
            //       />
            //     )}
            //   />
            //   <AppFormControlV2
            //     label="PM"
            //     // helpDescription="Après-midi"
            //     renderComponent={() => (
            //       <AppInputBooleanSwitch
            //         theme={'success'}
            //         value={staffMemberResumeSessionsDay.availableOrActiveToday}
            //         disabled={criteria.pageMode !== 'edit'}
            //       />
            //     )}
            //   />
            // </div>
          ))}

        {showRoles && (
          <div
            className={clsx(
              'mt-1 sm:mt-2 w-full flex flex-col sm:flex-row gap-px sm:gap-1 justify-between',
            )}
            onClick={(e) => {
              // if (onClick) {
              //   e.stopPropagation();
              // }
            }}
          >
            {roles.map((role, i) => (
              <StaffRoleBadge
                key={i}
                className={clsx(
                  'px-0.5 md:px-1 text-xs ',
                  !role.enabled && 'hidden sm:inline',
                )}
                role={role}
                // onClick={(e) => {
                //   if (onClick) {
                //     onClick({
                //       role,
                //     });
                //   }
                // }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
