import {
  clubProductPackageOfferGroupMatcher,
  clubProductPackageOfferMatcherBestPlan,
  ClubProductPackageOfferViewPlanGroup,
  MatchingOffersCriteria,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { DiverPurchasePlanEditorDialogState } from '../../models';

import { useDiverPurchaseCommonEditorDialogInitialAssociatedBookingProductIds } from '../DiverPurchaseCommonEditorDialog';
import { useDiverPurchasePlanPackageOffers } from './components/DiverPurchasePlanForm/useDiverPurchasePlanPackageOffers.hook';
import { DiverPurchasePlanDialogLocalStateContext } from './useDiverPurchasePlanDialogLocalStateContext.service';
import { useDiverPurchasePlanEditorDialogInitialValue } from './useDiverPurchasePlanEditorDialogInitialValue.hook';

export function useDiverPurchasePlanDialogLocalStateInitialData({
  inputState,
  localContext,
}: {
  inputState: DiverPurchasePlanEditorDialogState;
  localContext: DiverPurchasePlanDialogLocalStateContext;
}) {
  const {
    diver,
    purchasePackage,
    createContext,
    mode,
    sessionsBillingResumes: initialSessionsBillingResumes,
    aggregatedData,
  } = inputState;

  const {
    clubResume,
    diveCenterResume,
    securityUser,
    clubSettings,
    diveCenterId,
    clubReference,
    isPaymentEnabled,
  } = localContext;

  const successiveDivesCount = useMemo(() => {
    if (mode === 'create') {
      return createContext?.successiveDivesCount;
    } else {
      return purchasePackage?.productPackageOffer?.productPackage
        ?.diveAttributes?.successiveDivesCount;
    }
  }, [
    createContext?.successiveDivesCount,
    mode,
    purchasePackage?.productPackageOffer?.productPackage?.diveAttributes
      ?.successiveDivesCount,
  ]);

  const productPackageOffersCriteria: MatchingOffersCriteria = useMemo(
    () => ({
      diver,
      successiveDivesCount,
      diveModeGroup: 'recreational-dive',
    }),
    [diver, successiveDivesCount],
  );

  const productPackageOffers = useDiverPurchasePlanPackageOffers(
    productPackageOffersCriteria,
  );

  const planGroups: ClubProductPackageOfferViewPlanGroup[] = useMemo(
    () =>
      clubProductPackageOfferGroupMatcher.buildPlanGroupsFromOffers(
        productPackageOffers,
      ),
    [productPackageOffers],
  );

  const defaultProductPackageOffer = useMemo(() => {
    if (createContext) {
      const offer =
        clubProductPackageOfferMatcherBestPlan.findBestMatchingOfferPlan({
          criteria: createContext,
          productPackageOffers,
        });
      return offer;
    }
  }, [createContext, productPackageOffers]);

  const initialAssociatedBookingProductIds =
    useDiverPurchaseCommonEditorDialogInitialAssociatedBookingProductIds({
      initialSessionsBillingResumes,
      mode,
    });

  const initialFormValue = useDiverPurchasePlanEditorDialogInitialValue({
    purchasePackage, // undefined if 'create' mode
    defaultProductPackageOffer,
    planGroups,
    divesCount: createContext?.divesCount,
    productPackageOffers,
    mode,
    initialAssociatedBookingProductIds,
    clubSettings,
    billingResumes: initialSessionsBillingResumes.all,
    aggregatedData,
    diveCenterId,
  });
  const includeOtherDiversDefaultValue = useMemo(() => {
    // ATTENTION: "sameTypeSameDiver" contient aussi les prestations des autres divers
    // qui sont assignées à ce forfait
    if (
      !!initialSessionsBillingResumes.sameTypeSameDiver.find(
        (x) =>
          x.purchaseParticipant.participant.bookingMemberFull.diver._id !==
          diver._id,
      )
    ) {
      // le pass est déjà associé à d'autres plongeurs, donc on coche l'option de partage par défaut
      return true;
    }
    return false;
  }, [diver._id, initialSessionsBillingResumes]);
  return {
    aggregatedData,
    successiveDivesCount,
    productPackageOffersCriteria,
    productPackageOffers,
    planGroups,
    defaultProductPackageOffer,
    initialAssociatedBookingProductIds,
    initialFormValue,
    includeOtherDiversDefaultValue,
  };
}

export type DiverPurchasePlanDialogLocalStateInitialData = ReturnType<
  typeof useDiverPurchasePlanDialogLocalStateInitialData
>;
