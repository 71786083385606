import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import {
  EcommerceCategoryEditorPayload,
  ecommerceCategoryEditorResultBuilder,
} from './ecommerceCategoryEditorResultBuilder.service';
import {
  EcommerceCategoryEditorEditMode,
  EcommerceCategoryEditorFormModel,
  EcommerceCategoryEditorFormModelDefaultValue,
} from './model';
import { EcommerceCategoryEditorFormModelEditValue } from './model/EcommerceCategoryEditorFormModelEditValue.type';
import { ecommerceCategoryEditorformBuilder } from './services';

export type EcommerceCategoryEditorInputProps = {
  mode: EcommerceCategoryEditorEditMode;
  editValue?: EcommerceCategoryEditorFormModelEditValue;
  defaultValue?: EcommerceCategoryEditorFormModelDefaultValue;
};
export type EcommerceCategoryEditorCallbackProps = {
  onCancel: () => any;
  onConfirm: (result: EcommerceCategoryEditorPayload) => any;
};

export type EcommerceCategoryEditorProps = EcommerceCategoryEditorInputProps & {
  callback: EcommerceCategoryEditorCallbackProps;
};
export function useEcommerceCategoryEditorPanelLocalState(
  inputProps: EcommerceCategoryEditorProps,
) {
  const { defaultValue, editValue, mode, callback } = inputProps;

  const initialFormValue = useMemo<
    Partial<EcommerceCategoryEditorFormModel>
  >(() => {
    if (mode === 'edit' || mode === 'duplicate') {
      // on supprime tous les ids (en cas de clonage)
      const model: Partial<EcommerceCategoryEditorFormModel> = {
        category: {
          _id: null,
          ...editValue?.category,
        },
        appBooklet: {
          _id: null,
          ...editValue?.appBooklet,
        },
        appBookletPage: {
          _id: null,
          ...editValue?.appBookletPage,
        },
      };
      return model;
    }
    // create
    return ecommerceCategoryEditorformBuilder.buildInitialFormValue({
      defaultValue,
    });
  }, [defaultValue, editValue, mode]);

  const form = useForm<EcommerceCategoryEditorFormModel>({
    defaultValues: initialFormValue,
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    formState,
    setValue,
  } = form;
  const [persistInProgress, setPersistInProgress] = useState(false);

  const promptDeleteCategoryConfirm = useCallback(() => {
    confirmDialog
      .confirm({
        title: 'Supprimer la catégorie',
        message: 'Êtes-vous sûr de vouloir supprimer cette catégorie ?',
        type: 'remove',
      })
      .subscribe(async (confirmed) => {
        if (confirmed) {
          setPersistInProgress(true);
          try {
            const payload: EcommerceCategoryEditorPayload = {
              actionContext: 'delete category',
              hasChanges: true,
              appBooklet: {
                deletedIds: editValue?.appBooklet?._id
                  ? [editValue?.appBooklet?._id]
                  : [],
              },
              appBookletPage: {
                deletedIds: editValue?.appBookletPage?._id
                  ? [editValue?.appBookletPage?._id]
                  : [],
              },
              ecommerceCategory: {
                deletedIds: editValue?.category?._id
                  ? [editValue?.category?._id]
                  : [],
              },
            };
            await callback.onConfirm(payload);
          } catch (err) {
            appLogger.error(
              '[EcommerceCategoryEditor] Error saving changes',
              err,
            );
            uiStore.snackbarMessage.set({
              type: 'error',
              content:
                'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
            });
          } finally {
            setPersistInProgress(false);
          }
        }
      });
  }, [
    callback,
    editValue?.appBooklet?._id,
    editValue?.appBookletPage?._id,
    editValue?.category?._id,
  ]);

  async function submit() {
    return handleSubmit(async (formValue: EcommerceCategoryEditorFormModel) => {
      try {
        if (persistInProgress) {
          return;
        }
        setPersistInProgress(true);
        if (mode === 'create' || mode === 'duplicate') {
          const payload: EcommerceCategoryEditorPayload =
            await ecommerceCategoryEditorResultBuilder.buildCreatePayload({
              formValue,
            });
          await callback.onConfirm(payload);
        } else {
          const payload: EcommerceCategoryEditorPayload =
            await ecommerceCategoryEditorResultBuilder.buildUpdatePayload({
              formValue,
              editValue,
            });
          if (payload.hasChanges) {
            await callback.onConfirm(payload);
          } else {
            callback.onCancel();
          }
        }
      } catch (err) {
        appLogger.error('[EcommerceCategoryEditor] Error saving changes', err);
        uiStore.snackbarMessage.set({
          type: 'error',
          content:
            'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
        });
      } finally {
        setPersistInProgress(false);
      }
    })();
  }

  return {
    state: {
      mode,
      persistInProgress,
      form,
    },
    actions: {
      promptDeleteCategoryConfirm,
      submit,
      cancel: callback.onCancel,
    },
  };
}

export type EcommerceCategoryEditorLocalState = ReturnType<
  typeof useEcommerceCategoryEditorPanelLocalState
>;
