import { urlBuilder, UrlBuilderQueryParams } from 'src/_common-browser';
import { GenericNavigationContext } from '../club-diver-participant/ClubParticipantNavigationContext/GenericNavigationContext.type';
import { DiverBookingPageTabId } from '../club-diver-participant/pages/DiverBookingPage/components/DiverBookingPageTab';

export const clubParticipantUrlBuilder = {
  buildTabUrl,
};

function buildTabUrl({
  navigationContext,
  tabId,
  queryParams = {},
  logContext = '',
}: {
  tabId?: DiverBookingPageTabId;
  navigationContext: GenericNavigationContext;
  queryParams?: UrlBuilderQueryParams;
  logContext?: string;
}) {
  if (tabId) {
    queryParams['t'] = tabId;
  }
  if (navigationContext) {
    if (navigationContext.origin) {
      queryParams['origin'] = navigationContext.origin;
    }
    if (navigationContext.diveSessionReference) {
      queryParams['diveSessionReference'] =
        navigationContext.diveSessionReference;
    }
    if (navigationContext.purchasePaymentId) {
      queryParams['purchasePaymentId'] = navigationContext.purchasePaymentId;
    }
    if (navigationContext.action) {
      queryParams['action'] = navigationContext.action;
    }
  }

  const participantBaseUrl = `/club/diver-${navigationContext.diverId}`;

  const newChunk = 'booking';
  const url = urlBuilder.build(participantBaseUrl, {
    newChunk,
    queryParams,
  });
  return url;
}
