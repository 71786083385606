/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_DOC_STATUSES, AppDocStatus } from '@mabadive/app-common-model';
import { ReactNode, useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
//import { RegionalTaxLabel } from '../company/RegionalTax/RegionalTaxLabel';
import { appDocStatusFormatter } from '@mabadive/app-common-services';
export function useAppDocStatusOptions(): ValueLabel<
  AppDocStatus,
  ReactNode
>[] {
  return useMemo(() => {
    const options: ValueLabel<AppDocStatus, ReactNode>[] = APP_DOC_STATUSES.map(
      (appDocStatus) => ({
        value: appDocStatus,
        label: appDocStatusFormatter.formatAppDocStatus(appDocStatus),
        // icon:
        //   (
        //   <AppDocStatusLabel
        //     appDocStatus={appDocStatus}
        //     className="text-app-blue hover:text-white"
        //   />
        // ),
      }),
    );

    return options;
  }, []);
}
