/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as React } from 'react';
import {
  AppBreadcrumbBar,
  AppButton,
  AppButtonsBar,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { ClubSettingsCustomerFormFieldsEditForm } from './ClubSettingsCustomerFormFieldsEditForm';
import {
  ClubSettingsCustomerFormFieldsEditPageLocalState,
  useClubSettingsCustomerFormFieldsEditPageLocalState,
} from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditPage = () => {
  const localState: ClubSettingsCustomerFormFieldsEditPageLocalState =
    useClubSettingsCustomerFormFieldsEditPageLocalState();

  const { submitForm, back, clubSettings, form } = localState;
  const { formState } = form;

  return (
    <AppPageContainerWithFixedBars
      className={'bg-gray-50'}
      footerBar={() => (
        <AppButtonsBar hasChanges={formState.isDirty}>
          <AppButton
            fullWidth
            icon={AppHeroIcons.actionCancel}
            color="gray-outline-light"
            size="normal"
            onClick={() => {
              back && back();
            }}
          >
            Annuler
          </AppButton>
          <AppButton
            fullWidth
            icon={AppHeroIcons.actionSave}
            color="primary-outline-light"
            onClick={() => {
              submitForm();
            }}
          >
            Confirmer
          </AppButton>
        </AppButtonsBar>
      )}
    >
      {/* <ClubOfferBreadcrumbBar /> */}

      <AppBreadcrumbBar
        color={AppIconsSettings.other.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.customers.main,
            label: 'Clients',
            url: '/club/settings',
          },
          {
            label: 'Champs de formulaire',
          },
        ]}
      />

      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <div className="flex flex-col gap-4">
          <h2 className="w-full text-left uppercase text-lg leading-6 font-medium text-app-blue">
            Configuration des champs à afficher
          </h2>
          <ClubSettingsCustomerFormFieldsEditForm localState={localState} />
        </div>
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
