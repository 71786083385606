/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppBookletGql_Company,
  AppBookletPageGql_Company,
  EcommerceProductArticleGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import { ProProductsPage_CategoryContentCardProductArticlesTableTbody } from './ProProductsPage_CategoryContentCardProductArticlesTableTbody';

export const ProProductsPage_CategoryContentCardProductArticlesTable = ({
  localState,
  product,
  onClickProductArticle,
  className,
}: {
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
  product: EcommerceProductGql_Company;
  onClickProductArticle: ({
    productBookletPage,
    productArticle,
    appBooklet,
    appBookletPage,
  }: {
    productBookletPage: AppBookletPageGql_Company;
    productArticle: EcommerceProductArticleGql_Company;
    appBooklet: AppBookletGql_Company;
    appBookletPage: AppBookletPageGql_Company;
  }) => void;
  className?: string;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const { state, dialogs, data, actions } = localState;

  const productArticles: EcommerceProductArticleGql_Company[] = useMemo(() => {
    return data.productArticles.filter((a) => a.productId === product._id);
  }, [data.productArticles, product._id]);

  return (
    <table className={clsx('app-table', className)}>
      {/* <thead></thead> */}
      {productArticles.map((productArticle) => (
        <ProProductsPage_CategoryContentCardProductArticlesTableTbody
          key={productArticle._id}
          localState={localState}
          product={product}
          productArticle={productArticle}
          onClickProductArticle={onClickProductArticle}
        />
      ))}
    </table>
  );
};
