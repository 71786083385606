/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppButtonsBar,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DateRangeLabel } from 'src/pages/_components';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { clubSettingsDiveCenterUrlBuilder } from '../../clubSettingsDiveCenterUrlBuilder.service';
import { ClubSettingsPlanningViewWeekForm } from './ClubSettingsPlanningViewWeekForm';
import { ClubSettingsPlanningEditPageNavigationContext } from './model';
import {
  ClubSettingsPlanningEditPageLocalState,
  useClubSettingsPlanningEditPageLocalState,
} from './useClubSettingsPlanningEditPageLocalState.hook';

export const ClubSettingsPlanningEditPage = ({
  navigationContext,
}: {
  navigationContext: ClubSettingsPlanningEditPageNavigationContext;
}) => {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterResume = useDiveCenterResume();
  const redirectTo = useRedirect();

  const localState: ClubSettingsPlanningEditPageLocalState =
    useClubSettingsPlanningEditPageLocalState({
      navigationContext,
    });
  const { mode, submitForm, back, form, weekDays, initialPeriodConfig } =
    localState;

  const [name, beginDate, endDate] = useWatch({
    control: form.control,
    name: ['name', 'beginDate', 'endDate'],
  });

  useEffect(() => {
    if (!initialPeriodConfig) {
      redirectTo(clubSettingsDiveCenterUrlBuilder.planning.view());
    }
  }, [initialPeriodConfig, redirectTo]);

  const originalName = initialPeriodConfig?.name;

  return !initialPeriodConfig && mode !== 'create' ? null : (
    <AppPageContainerWithFixedBars
      className={'bg-gray-50'}
      footerBar={() => (
        <AppButtonsBar hasChanges={true}>
          <AppButton
            fullWidth={true}
            icon={AppHeroIcons.actionCancel}
            color="gray-outline-light"
            size="normal"
            onClick={() => {
              back();
            }}
          >
            Annuler
          </AppButton>
          <AppButton
            fullWidth={true}
            icon={AppHeroIcons.actionSave}
            color="primary-outline-light"
            onClick={() => {
              submitForm();
            }}
          >
            Confirmer
          </AppButton>
        </AppButtonsBar>
      )}
    >
      <AppBreadcrumbBar
        color={AppIconsSettings.diveCenter.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.diveCenter.main,
            label: 'Centre de plongée',
            url: '/club/settings',
          },
          {
            label: 'Planning',
          },
        ]}
      >
        {isMultiDiveCenters && (
          <div className="app-px-content">
            <DiveCenterProBadge diveCenter={diveCenterResume} />
          </div>
        )}
      </AppBreadcrumbBar>
      <AppPageContentContainer
        paddingBottom={false}
        className="bg-gray-50 app-px-content"
      >
        <div className={clsx('app-card p-4 app-my-content')}>
          <h2 className="my-2 flex-grow text-left uppercase text-base md:text-lg leading-6 mt-4 font-normal text-gray-600">
            <div className="font-bold text-app-primary-light">
              {name}{' '}
              {mode === 'clone' && (
                <span className="text-sm text-gray-600">
                  (dupliqué depuis "{originalName}")
                </span>
              )}
            </div>
            <DateRangeLabel
              className="text-sm"
              beginDate={beginDate}
              endDate={endDate}
            />
          </h2>

          <ClubSettingsPlanningViewWeekForm
            className="app-my-content"
            mode={mode}
            form={form}
            weekDays={weekDays}
          />
        </div>
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
