import { ProStatsFetchResults } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  AppHeroIcons,
  DiveModeIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUserClubAuthorizations,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DashboardMetricTitleWithValue } from '../../../DashboardMetricTitleWithValue';
import { DashboardMetricValueCard } from '../../../DashboardMetricValueCard';
import { DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriodTable } from './DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriodTable';

export type PurchasesTrainingsOverPeriodTotal = {
  purchasePackageCount: number;
  purchasePackagePriceAvg: number;
  purchasePackagePriceSum: number;
  purchasePackagePayedAmount: number;
};

export const DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriod = ({
  purchasesTrainingsOverPeriod: results,
  comparisonDetails,
  title,
}: {
  purchasesTrainingsOverPeriod: ProStatsFetchResults['purchasesTrainingsOverPeriod'];
  comparisonDetails: DashboardReportComparisonDetails<any>;
  title: string;
}) => {
  const totalCount = useMemo(
    () => (results?.data ?? []).reduce((a, b) => a + b.purchasePackageCount, 0),
    [results?.data],
  );

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const az = useAppSecurityUserClubAuthorizations();

  const total: PurchasesTrainingsOverPeriodTotal = useMemo(
    () =>
      (results?.data ?? []).reduce(
        (acc, training) => {
          return {
            purchasePackageCount:
              acc.purchasePackageCount + training.purchasePackageCount,
            purchasePackagePriceAvg:
              acc.purchasePackagePriceAvg + training.purchasePackagePriceAvg,
            purchasePackagePriceSum:
              acc.purchasePackagePriceSum + training.purchasePackagePriceSum,
            purchasePackagePayedAmount:
              acc.purchasePackagePayedAmount +
              training.purchasePackagePayedAmount,
          };
        },
        {
          purchasePackageCount: 0,
          purchasePackagePriceSum: 0,
          purchasePackagePriceAvg: 0,
          purchasePackagePayedAmount: 0,
        } as {
          purchasePackageCount: number;
          purchasePackagePriceSum: number;
          purchasePackagePriceAvg: number;
          purchasePackagePayedAmount: number;
        },
      ),
    [results?.data],
  );

  const mainCurrency = useAppCurrencyMain();
  const globalUnit =
    total?.purchasePackagePriceSum === 0 && totalCount > 0 ? 'count' : 'amount';

  const signClassName = clsx(
    ' ml-0.5 text-bold',
    mainCurrency.sign.length > 1 ? 'text-xs' : 'text-base',
  );

  return !results?.data ? null : (
    <>
      <div
        className={clsx(
          'h-full w-full app-card app-p-content flex flex-col justify-between gap-2',
        )}
      >
        <div className="flex flex-col gap-2">
          <DashboardMetricTitleWithValue
            label={title}
            description="Formations facturées dont la première plongée est dans cette période"
            valueClassName="bg-gray-600 text-white"
            icon={
              globalUnit === 'count'
                ? EntitiesIcons.booking
                : EntitiesIcons.payment
            }
            value={
              globalUnit === 'count' ? (
                totalCount
              ) : (
                <AppPriceLabel
                  signClassName={signClassName}
                  addSpacesToLargeNumbers={true}
                  amount={total.purchasePackagePriceSum}
                  mainCurrency={mainCurrency}
                  centsClassName="text-base"
                />
              )
            }
          />
          <div className="mt-1 grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-1">
            <DashboardMetricValueCard
              iconClassName="text-dive-mode-training"
              label={'Formations'}
              value={total?.purchasePackageCount}
              unitIcon={DiveModeIcons.training}
            />
            <DashboardMetricValueCard
              label={'Facturé'}
              value={total?.purchasePackagePriceSum}
              type="amount"
              unitIcon={AppHeroIcons.purchase}
            />
            <DashboardMetricValueCard
              label={'Encaissé'}
              value={total?.purchasePackagePayedAmount}
              type="amount"
              unitIcon={EntitiesIcons.payment}
            />
          </div>
        </div>

        <DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriodTable
          // className="self-start"
          comparisonDetails={comparisonDetails}
          purchasesTrainingsOverPeriod={results}
          total={total}
        />
      </div>
    </>
  );
};
