import { useClubSettings } from './useClubSettings.hook';

export function useClubSettingsMessageChannels(): {
  email: {
    channelEnabled: boolean;
  };
  sms: {
    channelEnabled: boolean;
  };
  phone: {
    channelEnabled: boolean;
  };
  whatsApp: {
    channelEnabled: boolean;
  };
} {
  const clubSettings = useClubSettings();

  const [
    channelEmailEnabled,
    channelSmsEnabled,
    channelPhoneEnabled,
    channelWhatsAppEnabled,
  ] = [
    clubSettings?.communication?.channels?.email?.enabled,
    clubSettings?.communication?.channels?.sms?.enabled,
    clubSettings?.communication?.channels?.phone?.enabled,
    clubSettings?.communication?.channels?.whatsApp?.enabled,
  ];

  return {
    email: {
      channelEnabled: channelEmailEnabled,
    },
    sms: {
      channelEnabled: channelSmsEnabled,
    },
    phone: {
      channelEnabled: channelPhoneEnabled,
    },
    whatsApp: {
      channelEnabled: channelWhatsAppEnabled,
    },
  };
}
