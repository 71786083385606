import { combineLatest } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { browserCache } from 'src/_common-browser';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import {
  JWT_PLANNING_CONFIG_PLANNING_PAGE_STORAGE_ID,
  JWT_PLANNING_CONFIG_SESSION_SELECTOR_DIALOG_STORAGE_ID,
} from 'src/business/club/modules/club-planning-light/hooks';
import { PlanningViewConfig } from 'src/business/club/modules/club-planning/_model';

export const bootstrapPlanningConfig = () => {
  return combineLatest([
    browserCache
      .get<Partial<PlanningViewConfig>>(
        JWT_PLANNING_CONFIG_SESSION_SELECTOR_DIALOG_STORAGE_ID,
        {
          ignoreError: true,
        },
      )
      .pipe(
        first(),
        tap((planningConfig) => {
          if (planningConfig) {
            appLogger.info(
              '[bootstrapPlanningConfig] planningSelectorConfig loaded from cache',
              planningConfig,
            );
            uiStore.planningSelectorConfig.set(
              planningConfig,
              'restore-from-cache',
            );
          }
        }),
      ),
    browserCache
      .get<Partial<PlanningViewConfig>>(
        JWT_PLANNING_CONFIG_PLANNING_PAGE_STORAGE_ID,
        {
          ignoreError: true,
        },
      )
      .pipe(
        first(),
        tap((planningConfig) => {
          if (planningConfig) {
            appLogger.info(
              '[bootstrapPlanningConfig] planningViewParticipantsConfig loaded from cache',
              planningConfig,
            );
            uiStore.planningViewParticipantsConfig.set(
              planningConfig,
              'restore-from-cache',
            );
          }
        }),
      ),
  ]);
};
