import { DiveCenterResume } from '@mabadive/app-common-model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { useLoadable } from 'src/business/_core/data/hooks/useLoadable';
import { currentDiveCenterResumeStore } from 'src/business/_core/data/store-repository/company/dive-center-resume/store/currentDiveCenterResumeStore';

export function useDiveCenterResume(): DiveCenterResume {
  const { clubResume } = useLoadable({
    debugName: 'useDiveCenterResume',
    initialValueFromLoadSnapshot: true,
    load: () =>
      currentDiveCenterResumeStore.currentDiveCenterResume.get().pipe(
        // startWith('auto' as PlanningDisplayMode),
        distinctUntilChanged(),
        map((clubResume) => ({ clubResume })),
      ),
  });

  return clubResume;
}
