/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';

import 'dayjs/locale/fr';
import React from 'react';
import { appWebTracker } from 'src/business/_core/data/app-user-tracking';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppPageContainer,
  useAppTrackingClient,
} from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubDialogsProvider } from 'src/pages/_dialogs';
import { ClubPlanningLightPageDialogsProvider } from '../../club-planning-light/ClubPlanningLightPageDialogsProvider';
import {
  ClubPlanningAction,
  useClubPlanningActions,
} from '../../club-planning-light/useClubPlanningActions.hook';
import { ClubPlanningParticipantsPanel } from './ClubPlanningParticipantsPanel';
import {
  PlanningParticipantsLocalState,
  usePlanningParticipantsLocalState,
} from './usePlanningParticipantsLocalState.hook';

export const ClubPlanningParticipantsPage = () => {
  const redirectTo = useRedirect();

  const localState: PlanningParticipantsLocalState =
    usePlanningParticipantsLocalState();

  const clubResume = useClubResume();

  const {
    planningConfig,
    setPlanningConfig,
    weekPlanning,
    diveSessionResumes,
    clubEvents,
    dailyConfigs,
    loadableContent,
    dialogsOld,
  } = localState;

  const trackingClient = useAppTrackingClient();

  useEffect(() => {
    if (
      loadableContent.lastActionStatus === 'success' &&
      loadableContent.contentState === 'full'
    ) {
      appWebTracker.trackDataLoading(
        {
          ...loadableContent,
          data: diveSessionResumes,
          pageName: 'ClubPlanningParticipantsPage',
          dataLabel: 'week planning',
          context: {
            planningConfig,
          },
          limitToRandomPercent: 5, // on ne reporte que 5% du temps
          clubReference: clubResume?.reference,
        },
        { trackingClient },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clubResume?.reference,
    loadableContent.lastActionStatus,
    loadableContent.contentState,
  ]);

  const clubPlanningActions: ClubPlanningAction =
    useClubPlanningActions(localState);

  const {
    dialogsState, // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  } = clubPlanningActions;

  const isAnyDialogOpen =
    dialogsOld?.isAnyFakeDialogOpen ||
    dialogsOld?.isAnyRealDialogOpen ||
    dialogsState?.isAnyDialogOpen;

  return (
    <AppPageContainer
      className="h-full bg-white overflow-y-auto"
      {...loadableContent}
    >
      <ClubDialogsProvider dialogsState={dialogsState}>
        <ClubPlanningLightPageDialogsProvider
          className="h-full"
          dialogs={dialogsOld}
          localState={localState}
          dialogsState={dialogsState}
        >
          <ClubPlanningParticipantsPanel
            key={`${planningConfig.viewPeriod}`}
            context={'planning-participants-page'}
            isAnyDialogOpen={isAnyDialogOpen}
            localState={localState}
            sessionsDisplayConfigs={[]}
            navigationBarDisplay={{ view: true, mode: true, actions: true }}
            clubPlanningActions={clubPlanningActions}
          />
        </ClubPlanningLightPageDialogsProvider>
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
