import { ClubDiveSite } from '@mabadive/app-common-model';
import { ClubResumeDiveSiteFormModel } from '../model';

export const diveSiteFormResultBuilder = { buildResult };

function buildResult(
  formValue: ClubResumeDiveSiteFormModel,
): Partial<ClubDiveSite> {
  const value: Partial<ClubDiveSite> = {
    ...formValue,
  };
  return value;
}
