import {
  AppTitleDateNavigatorPeriod,
  AppTitleDateNavigatorPeriodRange,
} from 'src/pages/_components/title-navigators/model';

import { DiveTrainingReference } from '@mabadive/app-common-model';
import {
  DashboardReportPageMode,
  DashboardReportPagePeriodComparisonSync,
  DashboardReportPageUIConfig,
  DashboardReportPageUIConfigSelection,
  DashboardReportPageViewParameters,
} from '../model';

export const dashboardStatsPageUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const QUERY_PARAMS = {
  period: 'pr-per',
  selectedDate: 'pr-sd',
  beginDate: 'pr-bd',
  endDate: 'pr-ed',

  periodComparison: 'c-pr-per',
  selectedDateComparison: 'c-pr-sd',
  beginDateComparison: 'c-pr-bd',
  endDateComparison: 'c-pr-ed',

  autoSyncPeriod: 'comp-auto-sync',
  showPeriodRangeComparison: 'comp-enabled',
  ajustPeriodRangeToCurrentDate: 'ajust-now',

  pageMode: 'p-mode',
  selectionTrainingReference: 's-t-ref',
  selectionTrainingOfferName: 's-t-oname',
};

function getQueryParameters(): DashboardReportPageViewParameters {
  const params = new URLSearchParams(window.location.search);
  const periodRange: AppTitleDateNavigatorPeriodRange =
    getPeriodRangeMain(params);

  const periodRangeComparison: AppTitleDateNavigatorPeriodRange =
    getPeriodRangeComparison(params);

  const autoSyncPeriod = params.get(
    QUERY_PARAMS.autoSyncPeriod,
  ) as DashboardReportPagePeriodComparisonSync;

  const showPeriodRangeComparison = getBoolean(
    params.get(QUERY_PARAMS.showPeriodRangeComparison),
  );
  const ajustPeriodRangeToCurrentDate = getBoolean(
    params.get(QUERY_PARAMS.ajustPeriodRangeToCurrentDate),
  );

  const selectionTrainingReference = params.get(
    QUERY_PARAMS.selectionTrainingReference,
  ) as DiveTrainingReference;
  const selectionTrainingOfferName = params.get(
    QUERY_PARAMS.selectionTrainingOfferName,
  ) as DiveTrainingReference;

  const selection: DashboardReportPageUIConfigSelection = {
    training:
      selectionTrainingReference || selectionTrainingOfferName
        ? {
            diveTrainingReference: selectionTrainingReference,
            offerName: selectionTrainingOfferName,
          }
        : undefined,
  };

  const uiConfig: DashboardReportPageUIConfig = {
    pageMode: params.get(QUERY_PARAMS.pageMode) as DashboardReportPageMode,
    selection,
  };

  return {
    periodRange,
    uiConfig,
    autoSyncPeriod,
    showPeriodRangeComparison,
    ajustPeriodRangeToCurrentDate,
    periodRangeComparison,
  };
}

function getPeriodRangeMain(params: URLSearchParams) {
  const period = params.get(QUERY_PARAMS.period) as AppTitleDateNavigatorPeriod;
  const selectedDateString = params.get(QUERY_PARAMS.selectedDate);
  const beginDateString = params.get(QUERY_PARAMS.beginDate);
  const endDateString = params.get(QUERY_PARAMS.endDate);
  const selectedDate = selectedDateString
    ? new Date(parseInt(selectedDateString, 10))
    : undefined;
  const beginDate = beginDateString
    ? new Date(parseInt(beginDateString, 10))
    : undefined;
  const endDate = endDateString
    ? new Date(parseInt(endDateString, 10))
    : undefined;

  const periodRange: AppTitleDateNavigatorPeriodRange = {
    period,
    value: {
      beginDate,
      endDate,
      selectedDate,
    },
  };
  return periodRange;
}

function getPeriodRangeComparison(params: URLSearchParams) {
  const period = params.get(
    QUERY_PARAMS.periodComparison,
  ) as AppTitleDateNavigatorPeriod;
  const selectedDateString = params.get(QUERY_PARAMS.selectedDateComparison);
  const beginDateString = params.get(QUERY_PARAMS.beginDateComparison);
  const endDateString = params.get(QUERY_PARAMS.endDateComparison);
  const selectedDate = selectedDateString
    ? new Date(parseInt(selectedDateString, 10))
    : undefined;
  const beginDate = beginDateString
    ? new Date(parseInt(beginDateString, 10))
    : undefined;
  const endDate = endDateString
    ? new Date(parseInt(endDateString, 10))
    : undefined;

  const periodRangeComparison: AppTitleDateNavigatorPeriodRange = {
    period,
    value: {
      beginDate,
      endDate,
      selectedDate,
    },
  };
  return periodRangeComparison;
}

function buildSearchParams(
  viewParameters: Partial<DashboardReportPageViewParameters>,
) {
  const uiConfig = viewParameters?.uiConfig;
  const searchParams: string[] = [];

  buildSearchParamsPeriodRangeMain(viewParameters, searchParams);

  if (uiConfig?.pageMode) {
    searchParams.push(`${QUERY_PARAMS.pageMode}=${uiConfig.pageMode}`);
  }
  if (uiConfig?.selection?.training?.diveTrainingReference) {
    searchParams.push(
      `${QUERY_PARAMS.selectionTrainingReference}=${uiConfig.selection.training.diveTrainingReference}`,
    );
  }
  if (uiConfig?.selection?.training?.offerName) {
    searchParams.push(
      `${QUERY_PARAMS.selectionTrainingOfferName}=${uiConfig.selection.training.offerName}`,
    );
  }

  if (viewParameters?.showPeriodRangeComparison) {
    searchParams.push(
      `${QUERY_PARAMS.showPeriodRangeComparison}=${
        viewParameters.showPeriodRangeComparison ? 1 : 0
      }`,
    );
    if (viewParameters?.autoSyncPeriod) {
      searchParams.push(
        `${QUERY_PARAMS.autoSyncPeriod}=${viewParameters.autoSyncPeriod}`,
      );
    }
    if (viewParameters?.ajustPeriodRangeToCurrentDate) {
      searchParams.push(
        `${QUERY_PARAMS.ajustPeriodRangeToCurrentDate}=${
          viewParameters.ajustPeriodRangeToCurrentDate ? 1 : 0
        }`,
      );
    }
    buildSearchParamsPeriodRangeComparison(viewParameters, searchParams);
  }
  return searchParams;
}

function buildSearchParamsPeriodRangeMain(
  viewParameters: Partial<DashboardReportPageViewParameters>,
  searchParams: string[],
) {
  if (viewParameters.periodRange) {
    if (viewParameters.periodRange.value.selectedDate) {
      searchParams.push(
        `${
          QUERY_PARAMS.selectedDate
        }=${viewParameters.periodRange.value.selectedDate.getTime()}`,
      );
    }
    if (viewParameters.periodRange.value.beginDate) {
      searchParams.push(
        `${
          QUERY_PARAMS.beginDate
        }=${viewParameters.periodRange.value.beginDate.getTime()}`,
      );
    }
    if (viewParameters.periodRange.value.endDate) {
      searchParams.push(
        `${
          QUERY_PARAMS.endDate
        }=${viewParameters.periodRange.value.endDate.getTime()}`,
      );
    }
    if (viewParameters.periodRange.period) {
      searchParams.push(
        `${QUERY_PARAMS.period}=${viewParameters.periodRange.period}`,
      );
    }
  }
}
function buildSearchParamsPeriodRangeComparison(
  viewParameters: Partial<DashboardReportPageViewParameters>,
  searchParams: string[],
) {
  if (viewParameters.periodRangeComparison) {
    if (viewParameters.periodRangeComparison.value.selectedDate) {
      searchParams.push(
        `${
          QUERY_PARAMS.selectedDateComparison
        }=${viewParameters.periodRangeComparison.value.selectedDate.getTime()}`,
      );
    }
    if (viewParameters.periodRangeComparison.value.beginDate) {
      searchParams.push(
        `${
          QUERY_PARAMS.beginDateComparison
        }=${viewParameters.periodRangeComparison.value.beginDate.getTime()}`,
      );
    }
    if (viewParameters.periodRangeComparison.value.endDate) {
      searchParams.push(
        `${
          QUERY_PARAMS.endDateComparison
        }=${viewParameters.periodRangeComparison.value.endDate.getTime()}`,
      );
    }
    if (viewParameters.periodRangeComparison.period) {
      searchParams.push(
        `${QUERY_PARAMS.periodComparison}=${viewParameters.periodRangeComparison.period}`,
      );
    }
  }
}

function getBoolean(value: string): boolean {
  if (value === '1') {
    return true;
  }
  if (value === '0') {
    return false;
  }
  return undefined;
}
