/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, FormControl, FormLabel, Typography } from '@material-ui/core';
import React, { CSSProperties, ReactNode } from 'react';
import { SimpleFormAttributeState } from './SimpleFormAttributeState.model';

export const AppFormControl = ({
  label,
  attrState,
  renderComponent,
  style,
  className,
}: {
  label?: string;
  attrState?: SimpleFormAttributeState<any>;
  renderComponent: () => ReactNode;
  style?: CSSProperties;
  className?: string;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={style}
      className={className}
    >
      <FormControl
        required={attrState && attrState.def.required}
        error={attrState && attrState.showError}
      >
        <FormLabel className="form-label" component="legend">
          <Typography variant="overline">{label}</Typography>
        </FormLabel>
        <Box className="form-input">{renderComponent()}</Box>
      </FormControl>
    </Box>
  );
};
