/* eslint-disable @typescript-eslint/no-unused-vars */
import { StaffMemberResumeSessionsModel } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { ClubPlanningLightPanelViewPeriodDayStaffMember } from '../../../club-planning-light/ClubPlanningLightPanelViewPeriodDay/ClubPlanningLightPanelViewPeriodDayStaff';
import { SetPlanningConfigOptions } from '../../../club-planning-light/hooks';
import { ClubPlanningDayFull } from '../../../club-planning-light/model';
import { PlanningViewConfig } from '../../_model';
import { ClubPlanningStaffWeekStaffPresenceMembersEditDay } from './ClubPlanningStaffWeekStaffPresenceMembersEditDay';
import { ClubPlanningStaffWeekStaffPresenceLocalState } from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';

export const ClubPlanningStaffWeekStaffPresenceMembersEditRow = ({
  staffMemberResumeSessions,
  days,
  planningConfig,
  setPlanningConfig,
  localState,
  gridClassName,
  className,
}: {
  staffMemberResumeSessions: StaffMemberResumeSessionsModel;
  days: ClubPlanningDayFull[];
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  localState: ClubPlanningStaffWeekStaffPresenceLocalState;
  gridClassName?: string;
  className?: string;
}) => {
  const { actions, state } = localState;

  const hasChanges = localState.data.cache.updateState.hasChanges;

  const onClickEdit = useCallback(() => {
    if (!hasChanges) {
      // open edit staff dialog
      state.dialogsState.staffMemberEditDialog.openDialog({
        mode: 'edit',
        defaultValue: {
          staffMember: staffMemberResumeSessions.staffMember,
        },
      });
    }
  }, [
    hasChanges,
    staffMemberResumeSessions.staffMember,
    state.dialogsState.staffMemberEditDialog,
  ]);

  return (
    <div className={clsx(gridClassName, 'py-1 px-1', className)}>
      <div
        className={clsx(
          'px-2 lg:col-span-2 hover:bg-gray-100 cursor-pointer',
          planningConfig.viewPeriod === 'week' && 'hidden lg:block',
        )}
        onClick={(e) => {
          // toogle member filter
          if (!onClickEdit) {
            actions.updateStaffMemberFilter({
              staffMemberId: staffMemberResumeSessions.staffMember._id,
            });
          }
        }}
      >
        <ClubPlanningLightPanelViewPeriodDayStaffMember
          className=" "
          staffFirstNameBefore={planningConfig.staffFirstNameBefore}
          staffMember={staffMemberResumeSessions.staffMember}
          onClickEdit={hasChanges ? undefined : onClickEdit}
          date={days[0].date}
          activeToday={staffMemberResumeSessions.activeOnAnySession}
          availableOrActiveToday={staffMemberResumeSessions.availableInPeriod}
        />
      </div>
      {planningConfig.viewPeriod === 'week' && (
        <div
          className="lg:hidden col-span-7 flex justify-start bg-gray-100 hover:bg-gray-200 cursor-pointer rounded-t border border-gray-200 px-1"
          onClick={() => {
            // toogle member filter
            actions.updateStaffMemberFilter({
              staffMemberId: staffMemberResumeSessions.staffMember._id,
            });
          }}
        >
          <ClubPlanningLightPanelViewPeriodDayStaffMember
            staffFirstNameBefore={planningConfig.staffFirstNameBefore}
            staffMember={staffMemberResumeSessions.staffMember}
            onClickEdit={hasChanges ? undefined : onClickEdit}
            date={days[0].date}
            activeToday={staffMemberResumeSessions.activeOnAnySession}
            availableOrActiveToday={staffMemberResumeSessions.availableInPeriod}
          />
        </div>
      )}
      {staffMemberResumeSessions.days.map((staffMemberResumeSessionsDay, i) => (
        <ClubPlanningStaffWeekStaffPresenceMembersEditDay
          className=""
          key={i}
          staffMember={staffMemberResumeSessions.staffMember}
          staffMemberResumeSessionsDay={staffMemberResumeSessionsDay}
          localState={localState}
        />
      ))}
    </div>
  );
};
