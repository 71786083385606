/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingInitialPlanParticipantGroup,
  DiveMode,
} from '@mabadive/app-common-model';
import {
  diveModeFormatter,
  nameFormatter,
  uuidGenerator,
} from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { UseFieldArrayReturn, UseFormReturn, useWatch } from 'react-hook-form';
import { useAutoFill } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { useDiveModes } from 'src/pages/_components/options';
import { useClubSettings } from '../../../../../../data/hooks';
import { BookingInitialPlanParticipantGroupsEditFormRFH } from '../../../DiverBookingPage/forms';
import { BookingInitialPlanEditFormModel } from '../../../DiverBookingPage/forms/BookingInitialPlanEditForm/model';

export const CreateBookingInitialPlanParticipantGroupsPanel = ({
  form,
  fieldArray,
}: {
  form: UseFormReturn<BookingInitialPlanEditFormModel, any>;
  fieldArray: UseFieldArrayReturn<
    BookingInitialPlanEditFormModel,
    'bookingInitialPlan.participantsGroups',
    'id'
  >;
}) => {
  const autoFill = useAutoFill();

  const { control, register, handleSubmit, reset, watch, trigger, formState } =
    form;

  const {
    fields: participantsGroupsFields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
  } = fieldArray;

  const diveModes = useDiveModes({});
  const groupsButtons1 = useGroupsButtons1({ diveModes });
  const groupsButtons2 = useGroupsButtons2({ diveModes });

  const createGroup = useCallback(
    (group: BookingInitialPlanParticipantGroup) => {
      append(
        { ...group, _id: uuidGenerator.random(), participantsCount: 1 },
        {
          shouldFocus: true,
          // focusIndex: 2,
          focusName:
            'bookingInitialPlan.participantsGroups[0].participantsCount',
        },
      );
    },
    [append],
  );

  const participantsGroups = useWatch({
    control,
    name: 'bookingInitialPlan.participantsGroups',
  });

  const totalParticipants = useMemo(
    () =>
      participantsGroups.reduce((count, p) => {
        if (p?.participantsCount > 0) {
          return count + p?.participantsCount;
        }
        return count;
      }, 0),
    [participantsGroups],
  );

  const [firstName, lastName] = useWatch({
    control,
    name: ['diver.firstName', 'diver.lastName'],
  });

  const contactName = useMemo(
    () => nameFormatter.formatFullName({ firstName, lastName }),
    [firstName, lastName],
  );

  const clubSettings = useClubSettings();
  const contactGroupIdOptions: ValueLabel<string>[] = useMemo(
    () =>
      [].concat(
        participantsGroups
          .filter((x) => x?.participantsCount > 0)
          .map((x, fieldIndex) => {
            const diveMode = diveModeFormatter.formatDiveMode(
              x.expectedDive?.diveMode,
              {
                format: 'short-ref-label',
                diveModesConf: clubSettings?.ui?.diveMode,
              },
            );
            const mainCert = x.certification?.certificationReference;
            const label = `${`${mainCert ?? ''} ${diveMode ?? ''}`.trim()} (x${
              x.participantsCount
            })`;
            return {
              value: x._id,
              label,
            };
          }),
      ),
    [clubSettings?.ui?.diveMode, participantsGroups],
  );
  return (
    <>
      <h3 className="mt-2 lg:text-left px-4 text-center lg:text-left px-4 text-sm sm:text-base md:text-lg lg:text-xl font-extrabold bg-gray-600 text-white uppercase">
        Participants ({totalParticipants})
      </h3>
      <div className="mt-5 mx-1 relative">
        <BookingInitialPlanParticipantGroupsEditFormRFH
          form={form}
          fieldArray={fieldArray}
        />
        {contactGroupIdOptions?.length > 0 && (
          <>
            <h4 className="mt-4 font-bold text-status-info">
              Le contact {contactName} participe-t-il aux plongées?
            </h4>
            <AppFormControlRHF_Deprecated
              className="w-full"
              label={'Groupe du contact'}
              control={control}
              name={'bookingInitialPlan.contactParticipantGroupId'}
              required={false}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  {...props}
                  disableClearButton={false}
                  theme={'material-ui'}
                  options={contactGroupIdOptions}
                />
              )}
            />
          </>
        )}
        <h4 className="mt-4 font-bold text-status-info">
          Ajouter un groupe de plongeurs:
        </h4>
        <div className="mt-4 grid grid-cols-2 sm:flex sm:flex-wrap sm:justify-start gap-4">
          {groupsButtons1.map((groupButton, i) => (
            <AppButton
              key={i}
              className={'sm:w-auto text-xs font-bold uppercase'}
              color={'gray-outline-light'}
              icon={AppHeroIcons.actionAdd}
              tabIndex={500}
              onClick={() => {
                createGroup(groupButton.group);
              }}
            >
              {groupButton.label}
            </AppButton>
          ))}
        </div>
        <div className="mt-4 flex flex-wrap justify-start gap-2">
          {groupsButtons2.map((groupButton, i) => (
            <AppButton
              key={i}
              className={
                'w-auto sm:w-auto text-xs font-bold uppercase min-w-[3rem]'
              }
              color={'primary-outline-light'}
              // icon={AppHeroIcons.actionAdd}
              tabIndex={500}
              onClick={() => {
                createGroup(groupButton.group);
              }}
            >
              {groupButton.label}
            </AppButton>
          ))}
        </div>
        <AppMessage
          className="my-8"
          title={'INSTRUCTIONS'}
          message={
            <>
              <p>
                Cette fonctionnalité permet de créer rapidement des groupes de
                plongeurs, sans forcément préciser les détails de chaque
                plongeur pour le moment.
              </p>
              <p className="mt-2">
                Créez une seule ligne par niveau et prestation souhaitée, puis
                indiquez le nombre de plongeur.
              </p>
              <p className="mt-2 underline">Exemple:</p>
              <ul className="ml-3 list-disc">
                <li>BPT x3</li>
                <li>PE x5</li>
                <li>PA N2 x4</li>
                <li>PA N3 x2</li>
              </ul>
            </>
          }
        />
      </div>
    </>
  );
};
function useGroupsButtons1({ diveModes }: { diveModes: DiveMode[] }) {
  const clubSettings = useClubSettings();
  return useMemo(() => {
    const groups: {
      group: BookingInitialPlanParticipantGroup;
      label: string;
    }[] = [
      {
        label: 'BPT',
        group: {
          _id: undefined, // will be generated on click
          certification: {
            certificationReference: 'DEB',
          },
          expectedDive: {
            diveMode: 'first-dive',
            firstDiveReference: 'BPT',
          },
          participantsCount: 0,
        },
      },
      {
        label: 'Plongée encadrée',
        group: {
          _id: undefined,
          certification: {
            certificationReference: undefined,
          },
          expectedDive: {
            diveMode: 'supervised',
          },
          participantsCount: 0,
        },
      },
      {
        label: 'Plongée autonome',
        group: {
          _id: undefined,
          certification: {
            certificationReference: undefined,
          },
          expectedDive: {
            diveMode: 'autonomous',
          },
          participantsCount: 0,
        },
      },
    ];
    if (diveModes.includes('snorkeling')) {
      groups.push({
        label: diveModeFormatter.formatDiveMode('snorkeling', {
          format: 'short-label',
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
        group: {
          _id: undefined,
          certification: {
            certificationReference: undefined,
          },
          expectedDive: {
            diveMode: 'snorkeling',
          },
          participantsCount: 0,
        },
      });
    }
    if (diveModes.includes('snorkelingSupervised')) {
      groups.push({
        label: diveModeFormatter.formatDiveMode('snorkelingSupervised', {
          format: 'short-label',
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
        group: {
          _id: undefined,
          certification: {
            certificationReference: undefined,
          },
          expectedDive: {
            diveMode: 'snorkelingSupervised',
          },
          participantsCount: 0,
        },
      });
    }
    if (diveModes.includes('watchingTour')) {
      groups.push({
        label: diveModeFormatter.formatDiveMode('watchingTour', {
          format: 'short-label',
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
        group: {
          _id: undefined,
          certification: {
            certificationReference: undefined,
          },
          expectedDive: {
            diveMode: 'watchingTour',
          },
          participantsCount: 0,
        },
      });
    }
    if (diveModes.includes('observer')) {
      groups.push({
        label: diveModeFormatter.formatDiveMode('observer', {
          format: 'short-label',
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
        group: {
          _id: undefined,
          certification: {
            certificationReference: undefined,
          },
          expectedDive: {
            diveMode: 'observer',
          },
          participantsCount: 0,
        },
      });
    }
    if (diveModes.includes('free-dive')) {
      groups.push({
        label: diveModeFormatter.formatDiveMode('free-dive', {
          format: 'short-label',
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
        group: {
          _id: undefined,
          certification: {
            certificationReference: undefined,
          },
          expectedDive: {
            diveMode: 'free-dive',
          },
          participantsCount: 0,
        },
      });
    }
    if (diveModes.includes('autoSupervised')) {
      groups.push({
        label: diveModeFormatter.formatDiveMode('autoSupervised', {
          format: 'short-label',
          diveModesConf: clubSettings?.ui?.diveMode,
        }),
        group: {
          _id: undefined,
          certification: {
            certificationReference: undefined,
          },
          expectedDive: {
            diveMode: 'autoSupervised',
          },
          participantsCount: 0,
        },
      });
    }
    groups.push({
      label: 'Autre',
      group: {
        _id: undefined,
        certification: {},
        expectedDive: {},
        participantsCount: 0,
      },
    });
    return groups;
  }, [clubSettings?.ui?.diveMode, diveModes]);
}

function useGroupsButtons2({ diveModes }: { diveModes: DiveMode[] }) {
  return useMemo(() => {
    const groups: {
      group: BookingInitialPlanParticipantGroup;
      label: string;
    }[] = [
      {
        label: 'N1',
        group: {
          _id: undefined,
          certification: {
            certificationReference: 'N1',
          },
          expectedDive: {
            diveMode: 'supervised',
          },
          participantsCount: 0,
        },
      },
      {
        label: 'N2',
        group: {
          _id: undefined,
          certification: {
            certificationReference: 'N2',
          },
          expectedDive: {
            diveMode: 'supervised',
          },
          participantsCount: 0,
        },
      },
      {
        label: 'N3',
        group: {
          _id: undefined,
          certification: {
            certificationReference: 'N3',
          },
          expectedDive: {
            diveMode: 'autonomous',
          },
          participantsCount: 0,
        },
      },
      {
        label: 'N4',
        group: {
          _id: undefined,
          certification: {
            certificationReference: 'N4',
          },
          expectedDive: {
            diveMode: 'autonomous',
          },
          participantsCount: 0,
        },
      },
      {
        label: 'OW',
        group: {
          _id: undefined,
          certification: {
            certificationReference: 'OW',
          },
          expectedDive: {
            diveMode: 'supervised',
          },
          participantsCount: 0,
        },
      },
      {
        label: 'ADV',
        group: {
          _id: undefined,
          certification: {
            certificationReference: 'ADV',
          },
          expectedDive: {
            diveMode: 'supervised',
          },
          participantsCount: 0,
        },
      },
    ];
    groups.push({
      label: 'Autre',
      group: {
        _id: undefined,
        certification: {},
        expectedDive: {},
        participantsCount: 0,
      },
    });
    return groups;
  }, []);
}
