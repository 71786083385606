/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import {
  AppInputBooleanSwitchRHF,
  AppInputColorRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl';
import { CompanyAccomodationEditPageLocalState } from '../useAccomodationsEditPageLocalState.hook';
import { CompanyAccommodationEditFormAddress } from './CompanyAccommodationEditFormAddress';

export const CompanyAccommodationEditForm = ({
  localState,
}: {
  localState: CompanyAccomodationEditPageLocalState;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const [name, pickupEnabled, color, colorText] = useWatch({
    control,
    name: ['name', 'pickup.enabled', 'color', 'colorText'],
  });

  return (
    <div className="px-5">
      <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Hébergement{' '}
        <span className="font-bold text-app-orange">
          {name?.trim()?.length > 0 && `"${name.toUpperCase()}"`}
        </span>
        <AppIconsSettings.customers.accomodation
          className="ml-2 inline w-6 h-4 border border-gray-600 py-px px-pt"
          style={{
            background: color,
            color: colorText,
          }}
        />
      </h2>
      <div className="w-full flex flex-col gap-4">
        <div
          className={
            'w-full flex flex-col sm:col-span-2 md:col-span-4 sm:grid sm:grid-cols-4 gap-4'
          }
        >
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Nom"
            control={control}
            name={'name'}
            required={true}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <div className="flex gap-1">
            <AppFormControlRHF_Deprecated
              className={'w-full'}
              label={'Couleur Fond'}
              required={true}
              control={control}
              name={'color'}
              renderComponent={(props) => (
                <AppInputColorRHF
                  {...props}
                  required={false}
                  className={'my-2 text-center text-xl font-bold'}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              className={'w-full'}
              label={'Couleur icône'}
              required={true}
              control={control}
              name={'colorText'}
              renderComponent={(props) => (
                <AppInputColorRHF
                  {...props}
                  required={false}
                  className={'my-2 text-center text-xl font-bold'}
                />
              )}
            />
          </div>
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            control={control}
            label="Pickup?"
            name="pickup.enabled"
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                className="w-full"
                {...props}
                label={
                  pickupEnabled && (
                    <AppIconsSettings.customers.pickup
                      className="ml-2 inline w-6 h-4 border border-gray-600 py-px px-pt"
                      style={{
                        background: color,
                        color: colorText,
                      }}
                    />
                  )
                }
                // label2={'Baptême, explo...'}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={clsx('w-full', !pickupEnabled && 'invisible')}
            label="Délai pickup (mn)"
            required={pickupEnabled}
            control={control}
            name={'pickup.offsetInMinutes'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
        </div>
        <CompanyAccommodationEditFormAddress form={form} />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Commentaire"
          control={control}
          name={'comment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
    </div>
  );
};
