/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubResumeDiveSite } from '@mabadive/app-common-model';
import {
  ClubDiveSiteSortByAttribute,
  clubDiveSitesSorter,
} from '@mabadive/app-common-services';
import { TableSortLabel } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import {
  CollectionOrder,
  useCollectionOrder,
} from 'src/business/_core/modules/layout/components/PageableTable';
import { DiveSitesTableRow } from './DiveSitesTableRow';

export const DiveSitesTable = ({
  diveSites,
  defaultOrder,
  onOrderChanged,
  onClickDiveSite,
}: {
  diveSites: ClubResumeDiveSite[];
  defaultOrder?: CollectionOrder<ClubDiveSiteSortByAttribute>;
  onOrderChanged?: (
    order: CollectionOrder<ClubDiveSiteSortByAttribute>,
  ) => void;
  onClickDiveSite?: (diveSiteId: string) => void;
}) => {
  const globalClasses = useGlobalClasses();

  const column: ClubDiveSiteSortByAttribute =
    defaultOrder && defaultOrder.column !== undefined
      ? defaultOrder.column
      : 'ref';

  const initialOrderOptions = {
    column: column,
    asc:
      defaultOrder && defaultOrder.asc !== undefined ? defaultOrder.asc : true,
  };

  const [order, sortByColumn] = useCollectionOrder<ClubDiveSiteSortByAttribute>(
    initialOrderOptions,
    {
      reverseColumns: [],
      onOrderChanged,
    },
  );

  const orderedDiveSites = useMemo(
    () =>
      clubDiveSitesSorter.sortDiveSitesBy(diveSites, {
        attributeName: order.column,
        asc: order.asc,
      }),
    [diveSites, order.asc, order.column],
  );

  function clickDiveSite(diveSiteId: string) {
    if (onClickDiveSite) {
      onClickDiveSite(diveSiteId);
    }
  }

  return (
    <table className={'w-full divide-y divide-gray-200 text-gray-600'}>
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            <TableSortLabel
              active={order.column === 'name'}
              direction={order.asc ? 'asc' : 'desc'}
              onClick={() => sortByColumn('name')}
            >
              Nom
            </TableSortLabel>
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            <TableSortLabel
              active={order.column === 'ref'}
              direction={order.asc ? 'asc' : 'desc'}
              onClick={() => sortByColumn('ref')}
            >
              Numéro
            </TableSortLabel>
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            <TableSortLabel
              active={order.column === 'distance'}
              direction={order.asc ? 'asc' : 'desc'}
              onClick={() => sortByColumn('distance')}
            >
              Distance
            </TableSortLabel>
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            <TableSortLabel
              active={order.column === 'distance'}
              direction={order.asc ? 'asc' : 'desc'}
              onClick={() => sortByColumn('distance')}
            >
              Profondeur
            </TableSortLabel>
          </th>
          <th
            scope="col"
            className="hidden lg:table-cell px-2 py-1 text-left text-xs font-normal text-gray-500 uppercase tracking-wider"
          >
            Commentaire
          </th>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          ></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {orderedDiveSites.map((diveSite, i) => (
          <DiveSitesTableRow
            key={i}
            diveSite={diveSite}
            onClick={() => clickDiveSite(diveSite._id)}
          />
        ))}
      </tbody>
    </table>
  );
};
