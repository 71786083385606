import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { DiverBookingPageConfig } from '../../models';
import { diverBookingPageUrlManager } from './diverBookingPageUrlManager.service';

export function useUpdateURLFromDiverBookingPageConfig(
  pageConfig: DiverBookingPageConfig,
  { enableRouteParams }: { enableRouteParams: boolean },
) {
  const history = useHistory();
  useEffect(() => {
    if (enableRouteParams) {
      // update url params
      const searchParams: string[] =
        diverBookingPageUrlManager.buildSearchParams(pageConfig);

      history.replace({
        pathname: window.location.pathname,
        search: searchParams?.length ? `?${searchParams.join('&')}` : undefined,
      });
    }
  }, [enableRouteParams, history, pageConfig]);
}
