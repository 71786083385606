import { urlBuilder, UrlBuilderQueryParams } from 'src/_common-browser';
import {
  DashboardTabId,
  DASHBOARD_SPACE_TABS,
} from '../components/DashboardTab';

export const dashboardUrlBuilder = {
  buildTabUrl,
};
function buildTabUrl({
  tabId,
  newChunk,
}: {
  tabId?: DashboardTabId;
  newChunk?: string;
} = {}) {
  const queryParams: UrlBuilderQueryParams = {};

  const tab = tabId
    ? DASHBOARD_SPACE_TABS.find((tab) => tab.id === tabId)
    : undefined;

  const baseUrl = `/club/dashboard${tab ? `/${tab.urlSuffix}` : ''}`;

  return urlBuilder.build(baseUrl, {
    newChunk,
    queryParams,
  });
}
