/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubAuthUserProfile } from '@mabadive/app-common-model';
import clsx from 'clsx';
import { default as React } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlRHF_Deprecated, AppInputRHF } from 'src/lib/form';
import {
  ClubSettingsUserProfileEditFormAuthCreditNote,
  ClubSettingsUserProfileEditFormAuthDashboard,
  ClubSettingsUserProfileEditFormAuthDownload,
  ClubSettingsUserProfileEditFormAuthList,
  ClubSettingsUserProfileEditFormAuthMultiCenter,
  ClubSettingsUserProfileEditFormAuthParticipantBooking,
  ClubSettingsUserProfileEditFormAuthParticipantCommunication,
  ClubSettingsUserProfileEditFormAuthParticipantPayment,
  ClubSettingsUserProfileEditFormAuthParticipantPurchase,
  ClubSettingsUserProfileEditFormAuthPlanning,
  ClubSettingsUserProfileEditFormAuthSettings,
} from './components';
import { ClubSettingsUserProfileEditFormAuthOnlineBooking } from './components/ClubSettingsUserProfileEditFormAuthOnlineBooking';
import { ClubSettingsUserProfileEditFormAuthStaff } from './components/ClubSettingsUserProfileEditFormAuthStaff';

export const ClubSettingsUserProfileEditForm = ({
  form,
  className,
}: {
  form: UseFormReturn<ClubAuthUserProfile, object>;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  return (
    <div className={className}>
      <div className={clsx('w-full')}>
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Nom du profil"
          control={control}
          required={true}
          name={'label'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>
      <div className={clsx('w-full')}>
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Description"
          control={control}
          required={false}
          name={'description'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>
      <h3 className="text-lg font-bold uppercase text-app-primary">
        TABLEAU DE BORD
      </h3>
      <ClubSettingsUserProfileEditFormAuthDashboard form={form} />
      <div className={'my-4 w-full flex flex-col gap-4'}>
        <h3 className="text-lg font-bold uppercase text-app-primary">
          PLANNING
        </h3>
        <ClubSettingsUserProfileEditFormAuthPlanning form={form} />{' '}
        <h3 className="text-lg font-bold uppercase text-app-primary">STAFF</h3>
        <ClubSettingsUserProfileEditFormAuthStaff form={form} />
        {/* <div className="p-1">
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.view.planning"
            label={'Planning'}
          />
        </div> */}
        <h3 className="text-lg font-bold uppercase text-app-primary">
          PARTICIPANTS - COMMUNICATION
        </h3>
        <ClubSettingsUserProfileEditFormAuthParticipantCommunication
          form={form}
        />
        <h3 className="text-lg font-bold uppercase text-app-primary">
          PARTICIPANTS - RÉSERVATIONS
        </h3>
        <ClubSettingsUserProfileEditFormAuthParticipantBooking form={form} />{' '}
        <h3 className="text-lg font-bold uppercase text-app-primary">
          PARTICIPANTS - FACTURATION
        </h3>
        <ClubSettingsUserProfileEditFormAuthParticipantPurchase form={form} />
        <h3 className="text-lg font-bold uppercase text-app-primary">
          PARTICIPANTS - PAIEMENT
        </h3>
        <ClubSettingsUserProfileEditFormAuthParticipantPayment form={form} />
        <h3 className="text-lg font-bold uppercase text-app-primary">
          AVOIRS (remboursements)
        </h3>
        <ClubSettingsUserProfileEditFormAuthCreditNote form={form} />{' '}
        <h3 className="text-lg font-bold uppercase text-app-primary">
          VENTE EN LIGNE
        </h3>
        <ClubSettingsUserProfileEditFormAuthOnlineBooking form={form} />
        <h3 className="text-lg font-bold uppercase text-app-primary">LISTES</h3>
        <ClubSettingsUserProfileEditFormAuthList form={form} />
        <h3 className="text-lg font-bold uppercase text-app-primary">
          PARAMÈTRES
        </h3>
        <ClubSettingsUserProfileEditFormAuthSettings form={form} />{' '}
        <h3 className="text-lg font-bold uppercase text-app-primary">
          EXPORT / TÉLÉCHARGEMENT
        </h3>
        <ClubSettingsUserProfileEditFormAuthDownload form={form} />
        {isMultiDiveCenters && (
          <>
            <h3 className="text-lg font-bold uppercase text-app-red">
              ACCÈS AUX AUTRES CENTRES
            </h3>
            <ClubSettingsUserProfileEditFormAuthMultiCenter form={form} />
          </>
        )}
      </div>
    </div>
  );
};
