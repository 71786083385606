/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  clubPurchasePackagePaymentStatusFormatter,
  diveServicePurchaseStatusFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { AgencyLabel } from '../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { ClubPurchasesListPageLocalState } from '../useClubPurchasesListPageLocalState.hook';

export const ClubPurchasesListPageConfigBarFiltersResume = ({
  localState,
  className,
}: {
  localState: ClubPurchasesListPageLocalState;
  className?: string;
}) => {
  const { filters } = localState;

  const {
    searchTextContactName,
    setSearchTextContactName,
    searchTextOfferName,
    setSearchTextOfferName,
    validityStatus,
    setValidityStatus,
    validityStatusOptions,
    paymentStatus,
    setPaymentStatus,
    paymentStatusOptions,
    bookingAgencyId,
  } = filters;

  return validityStatus ||
    paymentStatus ||
    bookingAgencyId ||
    searchTextContactName?.trim()?.length !== 0 ||
    searchTextOfferName?.trim()?.length !== 0 ? (
    <div
      className={clsx(
        'flex justify-start flex-wrap gap-x-4 text-xs text-gray-400 uppercase',
        className,
      )}
    >
      {searchTextContactName?.trim()?.length !== 0 && (
        <div className={clsx('flex xs:hidden gap-1')}>
          <div className="font-bold whitespace-nowrap">Nom:</div>
          <div className="whitespace-nowrap">
            {searchTextContactName?.trim()}
          </div>
        </div>
      )}
      {searchTextOfferName?.trim()?.length !== 0 && (
        <div className={clsx('flex md:hidden gap-1')}>
          <div className="font-bold whitespace-nowrap">Prestation:</div>
          <div className="whitespace-nowrap">{searchTextOfferName?.trim()}</div>
        </div>
      )}
      {validityStatus && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Validité:</div>
          {diveServicePurchaseStatusFormatter
            .formatStatus(validityStatus)
            .toUpperCase()}
        </div>
      )}
      {paymentStatus && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Paiement:</div>
          {paymentStatus === 'pending'
            ? clubPurchasePackagePaymentStatusFormatter
                .formatStatus('done', true)
                .toUpperCase()
            : clubPurchasePackagePaymentStatusFormatter
                .formatStatus(paymentStatus, undefined)
                .toUpperCase()}
        </div>
      )}
      {bookingAgencyId && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Agence:</div>
          <AgencyLabel agencyId={bookingAgencyId} />
        </div>
      )}
    </div>
  ) : null;
};
