/* eslint-disable @typescript-eslint/no-unused-vars */
import { jsonParser } from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  BookingParticipantEditorParticipant,
  DiverBookingPageUpdateState,
  PRO_BookingMemberFull_WithDocs,
} from '../../models';
import {
  BookingMassiveEditorDialogInputState,
  BookingMassiveEditorViewState,
} from './model';
import { BookingMassiveEditorEventsTriggers } from './useBookingMassiveEditorDialogActions.hook';
import {
  BookingMassiveEditorInnerLocalState,
  useBookingMassiveEditorInnerLocalState,
} from './useBookingMassiveEditorInnerLocalState.hook';

export function useBookingMassiveEditorDialogLocalState({
  inputState,
  setInputState,
}: {
  inputState: BookingMassiveEditorDialogInputState;
  setInputState: React.Dispatch<
    React.SetStateAction<BookingMassiveEditorDialogInputState>
  >;
}) {
  const { bookingId, bookingSessionsIds, loadedContent } = inputState;

  const inputUpdateStateCopy: DiverBookingPageUpdateState = useMemo(() => {
    if (inputState.isOpen && inputState.updateState) {
      return jsonParser.parseJSONWithDates(
        JSON.stringify(inputState.updateState),
      );
    }
    return {} as any;
  }, [inputState.isOpen, inputState.updateState]);

  const [viewState, setViewState] = useState<BookingMassiveEditorViewState>({
    mode: 'edit-massive-registration',
  });

  const [updateState, setUpdateStateInner] =
    useState<DiverBookingPageUpdateState>(inputUpdateStateCopy);

  // useEffect(() => {
  //   if (inputState.isOpen) {
  //     setUpdateStateInner(inputUpdateStateCopy);
  //   }
  // }, [inputState.isOpen, inputUpdateStateCopy]);

  const setUpdateState = useCallback((state: DiverBookingPageUpdateState) => {
    setUpdateStateInner({
      ...state,
      hasChanges: true,
    });
  }, []);

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const innerState: BookingMassiveEditorInnerLocalState =
    useBookingMassiveEditorInnerLocalState({
      setUpdateState,
      updateState,
      bookingId,
      bookingSessionsIds,
      loadedContent,
    });

  const eventsTriggers: BookingMassiveEditorEventsTriggers = useMemo(() => {
    const onClickCreateParticipant = (
      participant: BookingParticipantEditorParticipant,
    ) =>
      setViewState({
        mode: 'create-participant',
        participant,
      });
    const onClickEditParticipant = (
      participant: BookingParticipantEditorParticipant,
    ) =>
      setViewState({
        mode: 'edit-participant',
        participant,
      });

    const onClickEditMember = (
      bookingMemberFull: PRO_BookingMemberFull_WithDocs,
    ) =>
      setViewState({
        mode: 'edit-member',
        bookingMemberFull,
      });
    const eventsTriggers: BookingMassiveEditorEventsTriggers = {
      onClickCreateParticipant,
      onClickEditParticipant,
      onClickEditMember,
    };
    return eventsTriggers;
  }, []);

  return {
    innerState,
    clubReference,
    diveCenterId,
    // participantsBySessions,
    // bookingParticipantsWithSessionFull,
    // isUniqueMember,
    // isSameLastName,
    // aggregatedData,
    inputState,
    setInputState,
    updateState,
    setUpdateStateInner,
    setUpdateState,
    viewState,
    setViewState,
    eventsTriggers,
  };
}
