import { dataSorter } from '@mabadive/app-common-services';
import {
  AggregatedBookingPurchasePaymentWithDetails,
  PaymentTabParticipantPayments,
} from '../../../models';

export const paymentTabModelSorter = {
  sortPaymentTabParticipantPayments,
  sortClubPurchasePayments,
};

function sortPaymentTabParticipantPayments(
  all: PaymentTabParticipantPayments[],
): PaymentTabParticipantPayments[] {
  return dataSorter.sortMultiple(all, {
    getSortAttributes: (x) => [
      {
        value: x?.lastPaymentDate,
        type: 'default',
        asc: false,
      },
      {
        value: x?.diver._id,
        type: 'default',
      },
    ],
    asc: true,
  });
}

function sortClubPurchasePayments(
  all: AggregatedBookingPurchasePaymentWithDetails[],
): AggregatedBookingPurchasePaymentWithDetails[] {
  return dataSorter.sortMultiple(all, {
    getSortAttributes: (x) => {
      return [
        {
          value:
            x?.purchasePayment?.paymentDate ??
            x?.purchasePayment?.creationDate ??
            x?.purchasePayment?.dueDate,
          type: 'default',
          asc: false,
        },
        {
          value: x?.purchasePayment?.paymentDate,
          type: 'default',
          asc: false,
        },
        {
          value: x?.purchasePayment?.dueDate,
          type: 'default',
          asc: false,
        },
        {
          value: x?.purchasePayment?.creationDate,
          type: 'default',
          asc: false,
        },
        {
          value: x?.purchasePayment?._id,
          type: 'default',
        },
      ];
    },
    asc: true,
  });
}
