/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';

export const ClubSettingsCommunicationMainViewPanel_GeneralOptions = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubSettings = clubResume?.clubSettings;

  return (
    <ClubSettingsSection title="Options générales">
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        <AppFeatureBadge
          enabled={clubSettings.communication?.translation?.enabledManual}
          label="Afficher le bouton de traduction automatique"
        />
      </div>
    </ClubSettingsSection>
  );
};
