import {
  AppEntityUpdatePatch,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import {
  AppDocEditDialogInitialState,
  AppDocEditDialogProps,
  AppDocEditDialogSuccessResult,
} from './_model';

export type AppDocEditDialogActionPersistProps = {
  // voir aussi AppDocEditDialogSuccessResult
  onCreateAndPersisted: ({ appDoc }: { appDoc: PRO_AppDocResume }) => any;
  onUpdateNotPersisted: (props: { patches: AppEntityUpdatePatch[] }) => any;
  onDeleteNotPersisted: (props: { appDocIds: string[] }) => any;
};

export function useAppDocEditDialogActionPersist(
  props: AppDocEditDialogActionPersistProps,
): AppDocEditDialogProps {
  const action: AppDocEditDialogProps = {
    onConfirm,
    onUpdate: onConfirm,
  };
  return action;

  async function onConfirm(
    { create, update, _delete }: AppDocEditDialogSuccessResult,
    initialState: AppDocEditDialogInitialState,
  ) {
    if (create && props?.onCreateAndPersisted) {
      // ATTENTION: subtilité!
      // ici, l'objet a déja été envoyé au serveur, et sauvegardé, ce qui n'est pas le cas pour les autres
      props.onCreateAndPersisted(create);
    }
    if (update && props?.onUpdateNotPersisted) {
      props.onUpdateNotPersisted(update);
    }
    if (_delete && props?.onDeleteNotPersisted) {
      props.onDeleteNotPersisted(_delete);
    }
  }
}
