import { urlBuilder, UrlBuilderQueryParams } from 'src/_common-browser';
import { GenericNavigationContext } from '../club-diver-participant/ClubParticipantNavigationContext/GenericNavigationContext.type';

export const clubBookingUrlBuilder = {
  buildCreateBookingUrl,
  buildCreateBookingSimpleUrl,
  buildCreateBookingInitialPlanUrl,
};

function buildCreateBookingUrl({
  navigationContext,
  queryParams = {},
}: {
  navigationContext?: GenericNavigationContext;
  queryParams?: UrlBuilderQueryParams;
}) {
  if (navigationContext) {
    if (navigationContext.origin) {
      queryParams['origin'] = navigationContext.origin;
    }
    if (navigationContext.diveSessionReference) {
      queryParams['diveSessionReference'] =
        navigationContext.diveSessionReference;
    }
    if (navigationContext.planningFocusDateRef) {
      queryParams['planningFocusDateRef'] =
        navigationContext.planningFocusDateRef;
    }
  }
  return urlBuilder.build('/club/booking/create', {
    queryParams,
  });
}

function buildCreateBookingSimpleUrl({
  navigationContext,
  queryParams = {},
}: {
  navigationContext?: GenericNavigationContext;
  queryParams?: UrlBuilderQueryParams;
}) {
  if (navigationContext) {
    if (navigationContext.origin) {
      queryParams['origin'] = navigationContext.origin;
    }
    if (navigationContext.diveSessionReference) {
      queryParams['diveSessionReference'] =
        navigationContext.diveSessionReference;
    }
    if (navigationContext.planningFocusDateRef) {
      queryParams['planningFocusDateRef'] =
        navigationContext.planningFocusDateRef;
    }
  }
  return urlBuilder.build('/club/booking/create/simple', {
    queryParams,
  });
}
function buildCreateBookingInitialPlanUrl({
  navigationContext,
  queryParams = {},
}: {
  navigationContext?: GenericNavigationContext;
  queryParams?: UrlBuilderQueryParams;
}) {
  if (navigationContext) {
    if (navigationContext.origin) {
      queryParams['origin'] = navigationContext.origin;
    }
    if (navigationContext.diveSessionReference) {
      queryParams['diveSessionReference'] =
        navigationContext.diveSessionReference;
    }
    if (navigationContext.planningFocusDateRef) {
      queryParams['planningFocusDateRef'] =
        navigationContext.planningFocusDateRef;
    }
  }
  return urlBuilder.build('/club/booking/create/initial-plan', {
    queryParams,
  });
}
