/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import { AppInputBooleanSwitchRHF, AppInputRHF } from 'src/lib/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { AppInputDatePickerMaterialRHF } from '../../../../../../../../stories/components/04-form';
import {
  ClubSettingsOnlineBookingDialogInitialState,
  ClubSettingsOnlineBookingFormModel,
} from '../../../_model';

export const ClubSettingsOnlineBookingMainForm_ConfigGroups = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsOnlineBookingFormModel>;
  initialState: ClubSettingsOnlineBookingDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const [prompBeginDateEnabled, minDaysBefore] = useWatch({
    control,
    name: [
      'clubSettings.general.onlineBooking.defaultConfig.preBook.prompBeginDateEnabled',
      'clubSettings.general.onlineBooking.defaultConfig.preBook.prompBeginDateConstraints.minDaysBefore',
    ],
  });

  return (
    <ClubSettingsSection
      // title="Configuration avancée"
      title="Configuration des autres réservations"
      comment="Configurer la façon dont vos clients vont effectuer les demandes de réservations non automatiques"
    >
      <div className="grid gap-x-4 gap-y-4">
        <AppFormControlRHF
          className=""
          label={'Demander la date souhaitée de la prestation'}
          disabled={!isSuperAdmin}
          control={control}
          name={
            'clubSettings.general.onlineBooking.defaultConfig.preBook.prompBeginDateEnabled'
          }
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              label={'Activé'}
              theme="success"
            />
          )}
        />
      </div>
      {prompBeginDateEnabled && (
        <>
          <div className="grid gap-x-4 gap-y-4 overflow-hidden">
            <div className="grid grid-cols-2 md:flex gap-4">
              <AppFormControlRHF
                className="w-32"
                label={'Délai MIN'}
                helpDescription={'Résa possible MIN jours avant la sortie'}
                control={control}
                required={true}
                validation={{
                  rules: {
                    min: 0,
                  },
                }}
                name={
                  'clubSettings.general.onlineBooking.defaultConfig.preBook.prompBeginDateConstraints.minDaysBefore'
                }
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
              <AppFormControlRHF
                className="w-32"
                label={'Délai MAX'}
                helpDescription={'Résa possible MAX jours avant la sortie'}
                control={control}
                required={true}
                validation={{
                  rules: {
                    min: minDaysBefore,
                  },
                }}
                name={
                  'clubSettings.general.onlineBooking.defaultConfig.preBook.prompBeginDateConstraints.maxDaysBefore'
                }
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
              <AppFormControlRHF
                className="w-48"
                label={'Début des activités'}
                helpDescription={'Date de début de la période réservable'}
                control={control}
                required={false}
                name={
                  'clubSettings.general.onlineBooking.defaultConfig.preBook.prompBeginDateConstraints.minDate'
                }
                renderComponent={(props) => (
                  <AppInputDatePickerMaterialRHF
                    className="w-full"
                    {...props}
                  />
                )}
              />
              <AppFormControlRHF
                className="w-48"
                label={'Fin des activités'}
                helpDescription={'Date de fin de la période réservable'}
                control={control}
                required={false}
                name={
                  'clubSettings.general.onlineBooking.defaultConfig.preBook.prompBeginDateConstraints.maxDate'
                }
                renderComponent={(props) => (
                  <AppInputDatePickerMaterialRHF
                    className="w-full"
                    {...props}
                  />
                )}
              />
            </div>
          </div>
        </>
      )}
    </ClubSettingsSection>
  );
};
