import { Box, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { useLoadable } from 'src/business/_core/data/hooks';
import { responsiveAttributesValues } from 'src/business/_core/modules/layout';
import { useCacheableClasses } from 'src/business/_core/modules/root/theme';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import { adminStore, uiStore } from 'src/business/_core/store';
import { AppAuth, useAppSecurityUser } from 'src/business/auth/services';
import useRouter from 'use-react-router';
import { AdminAppOperationsListPage } from './AdminAppOperationsListPage';
import { AdminCompaniesListPage } from './AdminCompaniesListPage';
import { AdminCompanyEditPage } from './AdminCompanyEditPage';

export const AdminRootSpace = React.memo(function AdminRootSpaceMemo() {
  const classes = useCacheableClasses({
    cacheKey: 'AdminRootSpace',
    buildStyles,
  });

  const { _loaded, initialDataLoaded } = useLoadable({
    debugName: 'AdminRootSpace.bootstrap',
    initialValueFromLoadSnapshot: true,
    load: () =>
      adminStore.initialDataLoaded
        .get()
        .pipe(
          filter((x) => !!x),
          first(),
        )
        .pipe(map((initialDataLoaded) => ({ initialDataLoaded }))),
  });

  const { match } = useRouter();

  const user = useAppSecurityUser();

  useEffect(() => {
    uiStore.isMenuEmpty.set(false);
    return () => {
      uiStore.isMenuEmpty.set(true);
    };
  });

  return _loaded && initialDataLoaded ? (
    <>
      <Box component="main" className={classes.root}>
        <Switch>
          <AppRoute
            path={`${match.url}/companies`}
            exact
            component={AdminCompaniesListPage}
          />
          <AppRoute
            path={`${match.url}/operations`}
            exact
            component={AdminAppOperationsListPage}
          />
          <AppRoute
            path={`${match.url}/companies/:clubReference/edit`}
            exact
            component={AdminCompanyEditPage}
          />
          {/* default route */}
          <AppRoute
            path={`${match.url}/`}
            redirectTo={({ appAuth }) =>
              of(redirectToDefaultRoute({ appAuth, matchUrl: match.url }))
            }
          />
        </Switch>
      </Box>
    </>
  ) : null;
});

function redirectToDefaultRoute({
  appAuth,
  matchUrl,
}: {
  appAuth: AppAuth;
  matchUrl: string;
}): string {
  if (appAuth) {
    return `${matchUrl}/companies`;
  }
  return '/';
}

function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      display: 'flex',
      ...responsiveAttributesValues(theme, {
        // padding: { values: [0, 0, 8, 8, 8], suffix: 'px' },
        paddingTop: { values: [50, 50, 50, 50, 50], suffix: 'px' },
        height: {
          values: [50, 50, 50, 50, 50],
          prefix: 'calc(100vh - ',
          suffix: 'px)',
        },
      }),
      width: '100vw',
      overflowX: 'hidden',
      // overflowY: 'hidden',
    },
  });
}
