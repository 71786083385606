import { ProStatsFetchResults } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React from 'react';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { DashboardMetricTitleWithValue } from 'src/pages/DA-dashboard/_core';
import { EvolutionActivityBookingsByDayBarChart } from './chart';

export const DashboardReportPageEvolutionActivityBookingsByDay = ({
  bookingsByPeriod,
  chartWidth,
  comparisonDetails,
}: {
  bookingsByPeriod: ProStatsFetchResults['bookingsByPeriod'];
  chartWidth: number;
  comparisonDetails: DashboardReportComparisonDetails<{}>;
}) => {
  const { comparisonFetchResult } = comparisonDetails;
  const comparisonData = comparisonFetchResult?.bookingsByPeriod?.data;

  return (bookingsByPeriod?.data?.length ?? 0) <= 1 ? null : (
    <>
      <div className={'app-card p-4 app-card-highlight'}>
        <DashboardMetricTitleWithValue
          label={
            bookingsByPeriod.criteria.stepInterval === '1 day'
              ? 'Réservations quotidiennes'
              : bookingsByPeriod.criteria.stepInterval === '1 week'
              ? 'Réservations hebdomadaires'
              : 'Réservations mensuelles'
          }
          description={`
            Du ${dateService.formatUTC(
              bookingsByPeriod?.data[0].beginDate,
              'DD/MM/YYYY',
            )}
            au ${dateService.formatUTC(
              bookingsByPeriod.data[bookingsByPeriod.data.length - 1].endDate,
              'DD/MM/YYYY',
            )}
          `}
          icon={EntitiesIcons.booking}
        />

        <EvolutionActivityBookingsByDayBarChart
          className="mt-5"
          chartWidth={chartWidth}
          bookingsByPeriod={bookingsByPeriod}
          comparisonData={comparisonData}
        />
      </div>
    </>
  );
};
