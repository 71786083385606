import { BookingAgency } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

export function useBookingAgencyEditPageLocalState({
  mode,
  agencyId,
}: {
  mode: 'create' | 'edit';
  agencyId?: string;
}) {
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const agency: BookingAgency = useMemo(
    () =>
      mode === 'edit'
        ? diveCenterResume?.clubResume?.bookingAgencies.find(
            (x) => x._id === agencyId,
          )
        : undefined,
    [mode, diveCenterResume?.clubResume?.bookingAgencies, agencyId],
  );

  const initialFormValue: Partial<BookingAgency> = useMemo(() => {
    if (mode === 'create') {
      const createInitialValue: Partial<BookingAgency> = {
        details: {
          colorText: '#555',
          color: '#fff',
        },
      };
      return createInitialValue;
    } else if (agency) {
      const editInitialValue: Partial<BookingAgency> = {
        ...agency,
      };
      return editInitialValue;
    }
  }, [mode, agency]);

  const form = useForm<BookingAgency>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  return {
    mode,
    agency,
    form,
    initialFormValue,
  };
}

export type BookingAgencyEditPageLocalState = ReturnType<
  typeof useBookingAgencyEditPageLocalState
>;
