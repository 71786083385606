/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EcommerceCategoryGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { AppTranslationContentPanel } from '../../../../../../lib/form/components/AppBooklet/view/AppTranslationContentPanel';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import { EcommerceProductPreviewContent } from './EcommerceProductPreview';
import { ProProductsPage_CategoryContentCardProductArticlesArea } from './ProProductsPage_CategoryContentCardProductArticlesArea';

export const ProProductsPage_CategoryProductContentCard = ({
  categoryId,
  productId,
  localState,
  className,
}: {
  categoryId: string;
  productId: string;
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
  className?: string;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const generalSettings = clubSettings.general;

  const { state, dialogs, data, actions } = localState;

  const category: EcommerceCategoryGql_Company = useMemo(() => {
    return data.categories.find((x) => x._id === categoryId);
  }, [categoryId, data.categories]);

  const product: EcommerceProductGql_Company = useMemo(() => {
    return data.products.find((x) => x._id === productId);
  }, [data.products, productId]);

  const productBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product?.bookletId);
  }, [data.booklets, product?.bookletId]);

  const productBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === productBooklet?.rootPageId);
  }, [data.bookletPages, productBooklet?.rootPageId]);
  const categoryBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === category.bookletId);
  }, [category.bookletId, data.booklets]);

  const categoryBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === categoryBooklet?.rootPageId);
  }, [categoryBooklet?.rootPageId, data.bookletPages]);

  const categoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.categoryId === categoryId,
    );
  }, [categoryId, data.productCategoryConfigs]);

  const productCategoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.productId === product?._id,
    );
  }, [data.productCategoryConfigs, product?._id]);

  const similarProducts: EcommerceProductGql_Company[] = useMemo(() => {
    return data.products.filter(
      (p) =>
        p._id !== product?._id &&
        categoryConfigs.some((cf) => cf.productId === p._id),
    );
  }, [categoryConfigs, data.products, product?._id]);

  const appBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product?.bookletId);
  }, [data.booklets, product?.bookletId]);

  const appBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === appBooklet?.rootPageId);
  }, [appBooklet?.rootPageId, data.bookletPages]);

  const content = appBookletPage?.mainContent?.content;

  return !product ? null : (
    <div className={clsx('grid gap-4 relative', className)}>
      <EcommerceProductPreviewContent
        displayWidth="screen"
        product={product}
        appBookletPage={appBookletPage}
      >
        <div className="h-full flex flex-col gap-8">
          <div className="flex-grow">
            {(content?.items ?? []).map((item, i) => (
              <AppTranslationContentPanel key={i} item={item} />
            ))}
          </div>
          <div className="flex gap-4 flex-wrap">
            <AppButton
              className=""
              color="gray-outline-light"
              size="normal"
              icon={AppHeroIcons.actionEdit}
              onClick={(e) => {
                e.stopPropagation();
                dialogs.productEditor.openEditProduct({
                  categoryBookletPage,
                  editValue: {
                    product,
                    categoryConfigs: productCategoryConfigs,
                    appBooklet,
                    appBookletPage,
                    productArticlesFull: data.productArticlesFull.filter(
                      (x) => x.productId === product?._id,
                    ),
                  },
                });
              }}
            >
              Modifier la description du produit
            </AppButton>
          </div>
        </div>
      </EcommerceProductPreviewContent>

      <ProProductsPage_CategoryContentCardProductArticlesArea
        product={product}
        localState={localState}
      />
    </div>
  );
};
