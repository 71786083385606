import {
  ClubSettingsCommunicationPageMode,
  ClubSettingsCommunicationPageViewState,
} from '../_model';
import { ClubSettingsCommunicationPageTabId } from './clubSettingsCommunicationPageTabLabelFormatter.service';

export type ClubSettingsCommunicationPagegUrlParameters = Pick<
  ClubSettingsCommunicationPageViewState,
  'mode' | 'tabId'
>;

export const clubSettingsCommunicationPageUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const QUERY_PARAMS = {
  mode: 'm',
  tabId: 't',
};

function getQueryParameters(): ClubSettingsCommunicationPagegUrlParameters {
  const params = new URLSearchParams(window.location.search);

  const mode = params.get(
    QUERY_PARAMS.mode,
  ) as ClubSettingsCommunicationPageMode;

  const tabId = params.get(
    QUERY_PARAMS.tabId,
  ) as ClubSettingsCommunicationPageTabId;

  const config: ClubSettingsCommunicationPagegUrlParameters = {
    mode,
    tabId,
  };
  return config;
}

function buildSearchParams(
  viewConfig: Partial<ClubSettingsCommunicationPagegUrlParameters>,
): string[] {
  if (!viewConfig) {
    return [];
  }
  const { mode, tabId } = viewConfig;

  const searchParams: string[] = [];

  if (mode) {
    searchParams.push(`${QUERY_PARAMS.mode}=${mode}`);
  }
  if (tabId) {
    searchParams.push(`${QUERY_PARAMS.tabId}=${tabId}`);
  }

  return searchParams;
}
