/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import { AppIconsSocialMedia } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useClubSettingsMessageTargets,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

export const ClubSettingsCommunicationMainViewPanel_Session = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();

  const msg = useClubSettingsMessageTargets({
    context: 'session',
  });

  return (
    <ClubSettingsSection
      title="Participants d'une session"
      comment="Envoyer un message prédéfini aux participants d'une session"
    >
      {clubSettings.communication?.session?.enableMessage ? (
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          {msg.email.channelEnabled && (
            <AppFeatureBadge
              enabled={msg.email.enabled}
              label="Email"
              label2={
                clubSettings.communication?.session?.email?.mode === 'auto'
                  ? '(auto ⚡)'
                  : '(manuel ❌)'
              }
              icon={
                <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
              }
            />
          )}
          {msg.sms.channelEnabled && (
            <AppFeatureBadge
              enabled={msg.sms.enabled}
              label="SMS"
              label2="(manuel)"
              icon={
                <AppIconsSocialMedia.sms className="h-6 w-6 text-app-sms" />
              }
            />
          )}
          {msg.whatsApp.channelEnabled && (
            <AppFeatureBadge
              enabled={msg.whatsApp.enabled}
              label="WhatsApp"
              label2="(manuel)"
              icon={
                <AppIconsSocialMedia.whatsapp className="h-5 w-5 text-app-whatsapp" />
              }
            />
          )}
        </div>
      ) : (
        <AppFeatureBadge enabled={false} label="DÉSACTIVÉ" />
      )}
    </ClubSettingsSection>
  );
};
