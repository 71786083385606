/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AssignedInstructorStaff,
  ClubDiver,
  ClubParticipant,
  ClubStaffMember,
  DiveCenterDailyConfig,
  DiveCenterResume,
  DiveSession,
  DiveTourSession1,
  DiveTourSession2,
} from '@mabadive/app-common-model';
import {
  commonDiveSessionBuilder,
  dateService,
  jsonParser,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { DiveSessionEditorFormModel } from '../../forms/DiveSessionEditForm/model';

import {
  changeDescriptorAggregator,
  changeDescriptorManager,
} from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { diveSessionEditorDialogParticipantsCloner } from './diveSessionEditorDialogParticipantsCloner';
import {
  DiveSessionEditorAggregatedData,
  DiveSessionEditorInitialValue,
  DiveSessionEditorUpdateState,
} from './model';

export const diveSessionEditorDialogChangesAggregator = {
  aggregateChanges,
};

function aggregateChanges({
  diveCenterResume,
  initialDailyConfigs,
  formValue,
  isDirty,
  // inputState,
  updateState: inputUpdateState,
  initialValue,
}: {
  diveCenterResume: DiveCenterResume;
  initialDailyConfigs: DiveCenterDailyConfig[];
  formValue: DiveSessionEditorFormModel;
  isDirty: boolean;
  // inputState: DiveSessionEditorDialogInputState;
  updateState: DiveSessionEditorUpdateState;
  initialValue: DiveSessionEditorInitialValue;
}): {
  updateState: DiveSessionEditorUpdateState;
  aggregatedData: DiveSessionEditorAggregatedData;
} {
  // 1) apply form changes to updateState

  const diveSessionFromInputUpdateState: DiveSession =
    buildUpdatedDiveSessionFromState(inputUpdateState, initialValue);

  const localUpdateState = updateStateFromFormChanges({
    diveCenterResume,
    formValue,
    isDirty,
    initialValue,
    updateState: inputUpdateState,
    diveSessionFromInputUpdateState,
  });

  // 2) aggregate data

  const groups = changeDescriptorAggregator.aggregateMany(
    localUpdateState.diveSessionGroupsChanges,
    {
      pk: '_id',
      initials: initialValue.createSession
        ? []
        : initialValue.initialGroups
            ?.filter((x) => !x.isVirtualGroup)
            ?.map(({ booking, isVirtualGroup, ...groupAttributes }) => ({
              ...groupAttributes,
            })),
    },
  );

  // FIXME (@see hack SESSION_MULTIPLE_CREATE_REF): en cas de création, la référence de session peut être modifiée plusieurs fois
  // ce qui casse les palanquées crées (uniquement si la session est virtuelle, donc il s'agit de palanquées sans plongeurs, juste avec des moniteurs)

  const clubParticipants = changeDescriptorAggregator.aggregateMany(
    localUpdateState.clubParticipantsChanges,
    {
      pk: '_id',
      initials: initialValue.createSession
        ? []
        : (initialValue.initialParticipants as unknown[] as ClubParticipant[]),
    },
  );
  const divers: ClubDiver[] = changeDescriptorAggregator.aggregateMany(
    localUpdateState.diversChanges,
    {
      pk: '_id',
      initials: initialValue.createSession
        ? []
        : initialValue.initialParticipants.map((p) => p.diver as ClubDiver),
    },
  );

  const staffMembers: ClubStaffMember[] =
    changeDescriptorAggregator.aggregateMany(
      localUpdateState.staffMembersChanges,
      {
        pk: '_id',
        initials: (diveCenterResume.staffMembers ?? []) as ClubStaffMember[],
      },
    );

  const diveSession: DiveSession = buildUpdatedDiveSessionFromState(
    localUpdateState,
    initialValue,
  );

  const dailyConfigs = changeDescriptorAggregator.aggregateMany(
    localUpdateState.dailyConfigsChanges,
    {
      pk: '_id',
      initials: initialDailyConfigs ?? [], // NOTE: faut-il passer dailyConfigsFetched ici alors que c'est déjà ajouté au chargement via addManyOriginals ?
      ignoreErrors: true,
      appLogger,
      logPrefix: 'dailyConfigs',
    },
  );

  const aggregatedData: DiveSessionEditorAggregatedData = {
    clubParticipants,
    diveSession,
    divers,
    groups,
    staffMembers,
    dailyConfigs,
  };
  return {
    updateState: localUpdateState,
    aggregatedData,
  };
}

function updateStateFromFormChanges({
  diveCenterResume,
  formValue,
  isDirty,
  initialValue,
  updateState: inputUpdateState,
  diveSessionFromInputUpdateState,
}: {
  diveCenterResume: DiveCenterResume;
  formValue: DiveSessionEditorFormModel;
  isDirty: boolean;
  initialValue: DiveSessionEditorInitialValue;
  updateState: DiveSessionEditorUpdateState;
  diveSessionFromInputUpdateState: DiveSession;
}): DiveSessionEditorUpdateState {
  const { clubReference } = diveCenterResume;

  let localUpdateState = {
    ...inputUpdateState,
  };

  const formDiveSession: DiveSession = buildDiveSessionFromForm(formValue);

  const targetReferences = initialValue.createSession
    ? buildDiveSessionReferenceFromForm({ formValue, clubReference })
    : {
        dayReference: initialValue.initialDiveSession?.dayReference,
        diveSessionReference: initialValue.initialDiveSession?.reference,
      };

  formDiveSession.reference = targetReferences.diveSessionReference;
  formDiveSession.dayReference = targetReferences.dayReference;

  if (initialValue.createSession) {
    // create new session
    localUpdateState.hasChanges = true;
    localUpdateState.diveSessionsChanges = changeDescriptorManager.createOne(
      formDiveSession,
      {
        changeDescriptors: localUpdateState.diveSessionsChanges,
      },
    );
  }

  if (
    formValue.cloneParticipants &&
    initialValue.initialParticipants?.length > 0
  ) {
    // 1) pour chaque participant:
    // - on récupère le bookingId source (TODO plus tard il faut avoir ça directement dans le participant)
    // - on récupère le bookingMember source

    const cloneGroups =
      formValue.cloneParticipantsGroups &&
      initialValue.initialGroups?.length > 0;

    const cloneGuidesAndInstructors =
      cloneGroups && formValue.cloneGuidesAndInstructors;

    localUpdateState =
      diveSessionEditorDialogParticipantsCloner.cloneParticipants({
        diveCenterResume,
        initialValue,
        updateState: localUpdateState,
        targetDiveSessionReference: targetReferences.diveSessionReference,
        targetSessionsCount: formDiveSession.sessionsCount,
        cloneGroups,
        cloneGuidesAndInstructors,
      });
  }

  if (!isDirty) {
    return localUpdateState;
  }

  if (!initialValue.createSession) {
    // edit session
    const patchOperations = jsonPatcher.compareObjects(
      {
        ...diveSessionFromInputUpdateState,
        staffConfig: null, // Note: sinon y'a un bug, il est effacé
      },
      {
        ...diveSessionFromInputUpdateState,
        ...formDiveSession,
        staffConfig: null, // Note: sinon y'a un bug, il est effacé
      },
      {
        // else, value won't be deleted by typeorm
        // https://github.com/typeorm/typeorm/issues/2934
        replaceDeleteByNullValue: true,
        attributesToReplaceFully: ['boatsIds'],
      },
    );

    if (patchOperations.length) {
      localUpdateState.diveSessionsChanges = changeDescriptorManager.updateOne(
        {
          pk: initialValue.initialDiveSession?._id,
          patchOperations,
        },
        {
          changeDescriptors: localUpdateState.diveSessionsChanges,
        },
      );
    }
  }
  return localUpdateState;
}

function buildDiveSessionReferenceFromForm({
  formValue,
  clubReference,
}: {
  formValue: DiveSessionEditorFormModel;
  clubReference: string;
}): { dayReference: string; diveSessionReference: string } {
  const formValueSession: DiveSession = formValue.diveSession;

  const time = dateService.getUTCDateSetTime(
    formValue.date,
    formValueSession.time?.getUTCHours(),
    formValueSession.time?.getUTCMinutes(),
  );
  const dayReference = commonDiveSessionBuilder.buildDayReference(time);

  const suffix = commonDiveSessionBuilder.buildSessionReferenceSuffix({
    dayTime: {
      hours: time.getUTCHours(),
      minutes: time.getUTCMinutes(),
    },
  });

  const diveSessionReference =
    commonDiveSessionBuilder.buildDiveSessionReference({
      clubReference,
      dayReference,
      suffix,
    });
  // FIXME (@see hack SESSION_MULTIPLE_CREATE_REF): cette fonction est appelée plusieurs fois, donc la référence change (ce qui peut casser des FK, donc on a un hack sur les palanquées de moniteurs sans plongeur crées)
  // il faut reprendre tout cet écran, et en cas de création/clone, il faut juste d'abord demander les paramètres importants pour créer la référence (et ensuite, on passe toujours en mode édition):
  // - date de la session
  // - heure de la session

  return { dayReference, diveSessionReference };
}

function buildDiveTourSession1FromForm(
  formValue: DiveSessionEditorFormModel,
): DiveTourSession1 {
  const formValueDiveTourSession1: DiveTourSession1 =
    formValue.diveSession?.diveTourSession1;
  return {
    surfaceSecurityStaffId: formValueDiveTourSession1?.surfaceSecurityStaffId,
    divingDirector: buildDivingDirector(
      formValueDiveTourSession1?.divingDirector,
    ),
  };
}
function buildDiveTourSession2FromForm(
  formValue: DiveSessionEditorFormModel,
): DiveTourSession2 {
  const formValueDiveTourSession1: DiveTourSession1 =
    formValue.diveSession?.diveTourSession1;
  const formValueDiveTourSession2: DiveTourSession2 =
    formValue.diveSession?.diveTourSession2;
  const sameStaffSession1 = formValueDiveTourSession2?.sameStaffSession1;

  return formValue.multiSessionsEnabled
    ? {
        time: formValueDiveTourSession2?.time ?? formValue.diveSession?.time,
        diveSiteId: formValueDiveTourSession2?.diveSiteId,
        sameStaffSession1,
        divingDirector: sameStaffSession1
          ? null
          : buildDivingDirector(formValueDiveTourSession2?.divingDirector),
        surfaceSecurityStaffId: sameStaffSession1
          ? null
          : formValueDiveTourSession2?.surfaceSecurityStaffId,
      }
    : null;
}

function buildDivingDirector(
  divingDirector?: AssignedInstructorStaff,
): AssignedInstructorStaff {
  if (divingDirector?.staffId) {
    return divingDirector;
  }
}

function buildUpdatedDiveSessionFromState(
  localUpdateState: DiveSessionEditorUpdateState,
  // inputState: DiveSessionEditorDialogInputState,
  initialValue: DiveSessionEditorInitialValue,
): DiveSession {
  const diveSessions: DiveSession[] = changeDescriptorAggregator.aggregateMany(
    localUpdateState.diveSessionsChanges,
    {
      pk: '_id',
      initials: [initialValue.initialDiveSession],
    },
  );
  if (diveSessions.length > 1) {
    appLogger.error(
      `[buildUpdatedDiveSessionFromState] ERROR: ${diveSessions.length} diveSessions:`,
      diveSessions,
    );
  }
  const diveSession: DiveSession = diveSessions.length
    ? jsonParser.parseJSONWithDates(JSON.stringify(diveSessions[0]))
    : undefined;

  return diveSession;
}

function buildDiveSessionFromForm(
  formValue: DiveSessionEditorFormModel,
): DiveSession {
  const formValueSession: DiveSession = formValue.diveSession;

  const time = dateService.getUTCDateSetTime(
    formValue.date,
    formValueSession.time?.getUTCHours(),
    formValueSession.time?.getUTCMinutes(),
  );

  const diveSession: DiveSession = {
    ...formValueSession,
    name: emptyStringOrNull(formValueSession.name),
    comment: emptyStringOrNull(formValueSession.comment),
    importantNotes: emptyStringOrNull(formValueSession.importantNotes),
    weather: emptyStringOrNull(formValueSession.weather),
    tide: emptyStringOrNull(formValueSession.tide),
    time,
    diveTourSession1: buildDiveTourSession1FromForm(formValue),
    diveTourSession2: buildDiveTourSession2FromForm(formValue),
    sessionsCount: formValue.multiSessionsEnabled ? 2 : 1,
  };
  diveSession.sessionsCount = diveSession.diveTourSession2 ? 2 : 1;
  delete (diveSession as any)['date'];
  delete (diveSession as any)['divingDirectorStaffDivingLevel'];
  delete (diveSession as any)['divingDirectorInstructorDegreeName'];
  delete (diveSession as any)['participants'];
  delete (diveSession as any)['bookingSessionParticipants'];
  delete (diveSession as any)['staffConfig'];

  return diveSession;
}

function emptyStringOrNull(str: string): string | null {
  return str && str.trim().length !== 0 ? str.trim() : null;
}
