import { DateIsoWeekDay, WEEK_DAYS_ISO } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useWeekIsoDaysOptions() {
  const DEFAULT_WORKING_ISO_DAYS_OPTIONS = useMemo(
    () =>
      // NOTE: ne pas sortir du hook, car dayjs doit être initialisé
      WEEK_DAYS_ISO.map((weekDayIso) => {
        const option: ValueLabel<DateIsoWeekDay> = {
          label: dateService.formatIsoDayIso(weekDayIso),
          value: weekDayIso,
        };
        return option;
      }),
    [],
  );
  return DEFAULT_WORKING_ISO_DAYS_OPTIONS;
}
