/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeStaffMember,
  ClubSettingsUIPlanning,
  DiveSession,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { CalendarSessionWidthMode } from '../session-common';
import { CalendarStaffInstructorCell } from '../session-common/CalendarParticipantCell/CalendarStaffInstructorCell';
import { DiveSessionSimpleCardParticipantsGroupNoParticipant } from './DiveSessionSimpleCardParticipantsGroupNoParticipant';

export const DiveSessionSimpleCardParticipantsGroupFakePanel = ({
  settingsUIPlanning,
  diveSession,
  isPast,
  staffMember,
  widthMode,
  className,
}: {
  settingsUIPlanning: ClubSettingsUIPlanning;
  diveSession: Pick<
    DiveSession,
    'reference' | 'time' | 'status' | 'diveTourSession2'
  >;
  isPast: boolean;
  staffMember: ClubResumeStaffMember;
  widthMode: CalendarSessionWidthMode;
  className?: string;
}) => {
  const localSettingsUIPlanning: ClubSettingsUIPlanning = useMemo(() => {
    if (widthMode === 'large') {
      const showPayment = settingsUIPlanning.showPaymentFlag;
      return {
        ...settingsUIPlanning,
        showStaff: true,
        showPaymentFlagPastOnly: false,
        showPaymentFlagNotPurchased: showPayment,
        showPaymentFlagNotPurchasedPass: showPayment,
        showPaymentFlagTraining: settingsUIPlanning.showPaymentFlagTraining,
        showPaymentFlagPayed: showPayment,
        showPaymentFlagNotPayed: showPayment,
      };
    }
    return settingsUIPlanning;
  }, [settingsUIPlanning, widthMode]);

  const sessionsCount = diveSession.diveTourSession2 ? 2 : 1;

  const showDetailsSession2 = widthMode === 'large' && sessionsCount > 1;

  return (
    <div
      className={clsx(
        'bg-white mx-0 pl-px ',
        widthMode === 'large' ? 'border-2 border-l-8' : 'border-l-4',
        className,
      )}
    >
      <div className={''}>
        <div className={clsx('w-full flex gap-4')}>
          {widthMode === 'large' ? (
            <div className="w-full flex gap-4">
              <div className={'w-full'}>
                <CalendarStaffInstructorCell
                  staffFirstNameBefore={settingsUIPlanning.staffFirstNameBefore}
                  staffMember={staffMember}
                  diveSession={diveSession}
                  isPast={isPast}
                  widthMode={widthMode}
                />
                {settingsUIPlanning.showParticipants !== false && (
                  <DiveSessionSimpleCardParticipantsGroupNoParticipant
                    widthMode={widthMode}
                  />
                )}
              </div>
              {showDetailsSession2 && (
                <div className={'w-full'}>
                  <CalendarStaffInstructorCell
                    className={'w-full'}
                    staffFirstNameBefore={
                      settingsUIPlanning.staffFirstNameBefore
                    }
                    staffMember={staffMember}
                    diveSession={diveSession}
                    isPast={isPast}
                    widthMode={widthMode}
                  />
                  {settingsUIPlanning.showParticipants !== false && (
                    <DiveSessionSimpleCardParticipantsGroupNoParticipant
                      widthMode={widthMode}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className={'w-full'}>
              {localSettingsUIPlanning.showStaff && staffMember && (
                <CalendarStaffInstructorCell
                  className={'w-full mt-px'}
                  staffFirstNameBefore={settingsUIPlanning.staffFirstNameBefore}
                  staffMember={staffMember}
                  diveSession={diveSession}
                  isPast={isPast}
                  widthMode={widthMode}
                />
              )}
              {settingsUIPlanning.showParticipants !== false && (
                <DiveSessionSimpleCardParticipantsGroupNoParticipant
                  widthMode={widthMode}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
