/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from '@material-ui/core';
import React from 'react';
import {
  RxjsFormAppTextField,
  RxjsFormControl,
} from 'src/lib/form/components/rxjs-form';
import { ChoosePasswordFormComponentsModel } from './ChoosePasswordFormComponentsModel.type';

export const ChoosePasswordForm = ({
  components,
  autoFocus,
}: {
  components: ChoosePasswordFormComponentsModel;
  autoFocus?: boolean;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RxjsFormControl
          label="Identifiant"
          attrState={components['userAuthEmailLogin'].state}
          renderComponent={() => (
            <RxjsFormAppTextField
              fullWidth
              {...components['userAuthEmailLogin']}
              autoComplete="username"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <RxjsFormControl
          label="Choisissez votre nouveau mot de passe"
          attrState={components['userAuthPassword'].state}
          renderComponent={() => (
            <RxjsFormAppTextField
              autoFocus={true}
              fullWidth
              {...components['userAuthPassword']}
              autoComplete="new-password"
              type="password"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
