/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import {
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import {
  DIVE_SERVICE_ORGANIZATIONS,
  DiveServiceOrganizationReference,
  PACKAGE_ATTRIBUTES_DIVES_COUNT_EXTENSION_MODES,
  PackageAttributeDivesCountExtensionMode,
} from '@mabadive/app-common-model';
import {
  diveServiceOrganizationFilter,
  packageAttributeDivesCountExtensionModeFormatter,
} from '@mabadive/app-common-services';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { useResidentTypesOptions } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useDiveSessionThemesOptions,
  useDiveSessionTypesOptions,
} from 'src/pages/_components/options';
import { ClubSettingsProductOffersFormModel } from '../../_model';
import { ClubSettingsProductOffersFormOfferCommon_DivesCount } from './ClubSettingsProductOffersFormOfferCommon_DivesCount';

export const ClubSettingsProductOffersFormOfferCommon_Others_TO_REFACTOR = ({
  form,
  offerField,
  offerFieldIndex: i,
  offersFieldArray,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
}) => {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const showDetails = offersFieldArray.fields.length === 1;

  const [divesCountInfinite, divesCount, divePriceType] = useWatch({
    control,
    name: [
      `offers.${i}.productPackage.diveAttributes.divesCountInfinite`,
      `offers.${i}.productPackage.diveAttributes.divesCount`,
      `offers.${i}.productPackage.diveAttributes.divePriceType`,
    ],
  });

  const residentTypesOptions = useResidentTypesOptions();

  const diveMode = offerField.productPackage.diveAttributes?.diveMode;
  const isProduct = offerField.productPackage.type === 'product';
  const organizationsOptions = useMemo(
    () =>
      DIVE_SERVICE_ORGANIZATIONS.filter(
        (org) =>
          org?.reference ===
            offerField.productPackage?.productAttributes
              ?.defaultOrganizationReference ||
          diveServiceOrganizationFilter.isOrgEnabled(org?.reference, {
            services,
          }),
      ).map((org) => {
        const option: ValueLabel<DiveServiceOrganizationReference> = {
          label: org.label.toUpperCase(),
          value: org?.reference,
        };
        return option;
      }),
    [
      offerField.productPackage?.productAttributes
        ?.defaultOrganizationReference,
      services,
    ],
  );

  const divesCountExtensionModesOptions: ValueLabel<
    PackageAttributeDivesCountExtensionMode,
    React.ReactNode
  >[] = useMemo(
    () =>
      PACKAGE_ATTRIBUTES_DIVES_COUNT_EXTENSION_MODES.map((mode) => {
        return {
          value: mode,
          label:
            packageAttributeDivesCountExtensionModeFormatter.formatDivesCountExtensionMode(
              mode,
            ),
        };
      }),
    [],
  );

  const isTraining = diveMode === 'training';
  const isExplo =
    diveMode === 'autonomous' ||
    diveMode === 'autoSupervised' ||
    diveMode === 'supervised' ||
    diveMode === 'instructor';

  const diveSessionThemesOptions = useDiveSessionThemesOptions({
    scope: 'session-settings',
  });
  const diveSessionTypesOptions = useDiveSessionTypesOptions({
    scope: 'session-settings',
  });

  return (
    <>
      {(offerField.productPackage.type === 'dive' ||
        offerField.productPackage.type === 'training') &&
        !isExplo && (
          <>
            <ClubSettingsProductOffersFormOfferCommon_DivesCount
              form={form}
              offerField={offerField}
              offerFieldIndex={i}
              offersFieldArray={offersFieldArray}
            />
          </>
        )}
      {showDetails &&
        (offerField.productPackage.type === 'dive' ||
          offerField.productPackage.type === 'training') && (
          <>
            <AppFormControlRHF_Deprecated
              className="w-full"
              control={control}
              label="PASS illimité"
              name={`offers.${i}.productPackage.diveAttributes.divesCountInfinite`}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
            {!isTraining && (divesCountInfinite || divesCount > 1) && (
              <AppFormControlRHF_Deprecated
                className="w-full"
                control={control}
                label="Partagé (nb pers)"
                name={`offers.${i}.productPackage.productAttributes.sharedOfferMaxPersonsCount`}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" {...props} type="number" />
                )}
              />
            )}
          </>
        )}
      {showDetails && (
        <>
          <AppFormControlRHF_Deprecated
            className="w-full"
            control={control}
            label="Age mini"
            name={`offers.${i}.productPackage.productAttributes.minAge`}
            renderComponent={(props) => (
              <AppInputRHF className="w-full" {...props} type="number" />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            control={control}
            label="Age maxi"
            name={`offers.${i}.productPackage.productAttributes.maxAge`}
            renderComponent={(props) => (
              <AppInputRHF className="w-full" {...props} type="number" />
            )}
          />
          {offerField.productPackage.type === 'dive' &&
            diveMode !== 'observer' &&
            diveMode !== 'snorkeling' &&
            diveMode !== 'snorkelingSupervised' &&
            diveMode !== 'watchingTour' && (
              <>
                <AppFormControlRHF_Deprecated
                  control={control}
                  label="Profondeur mini"
                  name={`offers.${i}.productPackage.diveAttributes.minDepth`}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} type="number" fullWidth />
                  )}
                />
                <AppFormControlRHF_Deprecated
                  control={control}
                  label="Profondeur maxi"
                  name={`offers.${i}.productPackage.diveAttributes.maxDepth`}
                  renderComponent={(props) => (
                    <AppInputRHF {...props} type="number" fullWidth />
                  )}
                />
                {isSuperAdmin && (
                  <>
                    <AppFormControlRHF_Deprecated
                      visibility="super-admin"
                      control={control}
                      label="Distance mini (nmi) INCLUSIF"
                      name={`offers.${i}.productPackage.diveAttributes.minDistance`}
                      renderComponent={(props) => (
                        <AppInputRHF {...props} type="number" fullWidth />
                      )}
                    />
                    <AppFormControlRHF_Deprecated
                      visibility="super-admin"
                      control={control}
                      label="Distance maxi (nmi) EXCLUSIF"
                      name={`offers.${i}.productPackage.diveAttributes.maxDistance`}
                      renderComponent={(props) => (
                        <AppInputRHF {...props} type="number" fullWidth />
                      )}
                    />
                  </>
                )}
                {divePriceType === 'single' && (
                  <>
                    <AppFormControlRHF_Deprecated
                      control={control}
                      label="Min pl. tarif"
                      name={`offers.${i}.productPackage.diveAttributes.diveSingleAttributes.minDivesCount`}
                      renderComponent={(props) => (
                        <AppInputRHF {...props} type="number" fullWidth />
                      )}
                    />
                    <AppFormControlRHF_Deprecated
                      control={control}
                      label="Max pl. tarif"
                      name={`offers.${i}.productPackage.diveAttributes.diveSingleAttributes.maxDivesCount`}
                      renderComponent={(props) => (
                        <AppInputRHF {...props} type="number" fullWidth />
                      )}
                    />
                  </>
                )}
                {isSuperAdmin && divePriceType === 'package' && (
                  <>
                    <AppFormControlRHF_Deprecated
                      visibility="super-admin"
                      className="w-full col-span-2"
                      label={'Tarif augmentation quantité'}
                      control={control}
                      name={`offers.${i}.productPackage.diveAttributes.divePackageAttributes.divesCountExtensionMode`}
                      renderComponent={(props) => (
                        <AppSingleSelect2HeadlessUIRHF
                          className={'w-full'}
                          theme="material-ui"
                          {...props}
                          options={divesCountExtensionModesOptions}
                          // onChange={(value) => onChangeDiveSessionType(value)}
                        />
                      )}
                    />
                  </>
                )}
              </>
            )}
        </>
      )}

      {(offerField.productPackage.type === 'dive' ||
        offerField.productPackage.type === 'training') && (
        <>
          {generalSettings.billing?.showDepth && (
            <>
              <AppFormControlRHF_Deprecated
                className="w-full"
                control={control}
                label="Prof. mini (>)"
                name={`offers.${i}.productPackage.diveAttributes.minDepth`}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" {...props} type="number" />
                )}
              />
              <AppFormControlRHF_Deprecated
                className="w-full"
                control={control}
                label="Prof. maxi (<=)"
                name={`offers.${i}.productPackage.diveAttributes.maxDepth`}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" {...props} type="number" />
                )}
              />
            </>
          )}
          {generalSettings.billing?.showDistance && (
            <>
              <AppFormControlRHF_Deprecated
                className="w-full"
                control={control}
                label="Distance mini"
                name={`offers.${i}.productPackage.diveAttributes.minDistance`}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" {...props} type="number" />
                )}
              />
              <AppFormControlRHF_Deprecated
                className="w-full"
                control={control}
                label="Distance maxi"
                name={`offers.${i}.productPackage.diveAttributes.maxDistance`}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" {...props} type="number" />
                )}
              />
            </>
          )}
          {showDetails && isExplo && (
            <>
              <AppFormControlRHF_Deprecated
                className="w-full"
                control={control}
                label="Validité (jours)"
                name={`offers.${i}.productPackage.productAttributes.validityPeriodInDays`}
                renderComponent={(props) => (
                  <AppInputRHF className="w-full" {...props} type="number" />
                )}
              />
            </>
          )}
        </>
      )}

      <AppFormControlRHF_Deprecated
        className="w-full"
        label="Résidence"
        control={control}
        name={`offers.${i}.productPackage.salesCriteria.residentType`}
        renderComponent={(props) => (
          <AppSingleSelect2HeadlessUIRHF
            className={'w-full'}
            theme="material-ui"
            color="theme"
            {...props}
            options={residentTypesOptions}
          />
        )}
      />
      {showDetails &&
        (isTraining || isExplo || isProduct) &&
        organizationsOptions.length > 1 && (
          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Organisme"
            control={control}
            name={`offers.${i}.productPackage.productAttributes.defaultOrganizationReference`}
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={organizationsOptions}
              />
            )}
          />
        )}

      {showDetails && (
        <>
          {diveSessionTypesOptions.length > 0 && (
            <AppFormControlRHF_Deprecated
              className="w-full"
              label={'Type de sortie'}
              control={control}
              name={`offers.${i}.productPackage.salesCriteria.diveSessionType`}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  className={'w-full'}
                  theme="material-ui"
                  {...props}
                  options={diveSessionTypesOptions}
                  // onChange={(value) => onChangeDiveSessionType(value)}
                />
              )}
            />
          )}
          {diveSessionThemesOptions.length > 0 && (
            <AppFormControlRHF_Deprecated
              className="w-full"
              label={'Thème de la sortie'}
              control={control}
              name={`offers.${i}.productPackage.salesCriteria.diveSessionTheme`}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  className={'w-full'}
                  theme="material-ui"
                  {...props}
                  options={diveSessionThemesOptions}
                  // onChange={(value) => onChangeDiveSessionTheme(value)}
                />
              )}
            />
          )}
          {isSuperAdmin && (
            <AppFormControlRHF_Deprecated
              visibility="super-admin"
              label={'Offre par défaut'}
              control={control}
              name={`offers.${i}.productPackage.salesCriteria.dontSelectByDefault`}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  description={'ne pas pré-selectionner'}
                />
              )}
            />
          )}
        </>
      )}
    </>
  );
};
