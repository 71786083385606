/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppMessageLight,
  AppPageContainer,
  AppPageContentContainer,
  AppVisible,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { clubSettingsDiveCenterUrlBuilder } from '../../clubSettingsDiveCenterUrlBuilder.service';
import { ClubSettingsPlanningDefaultViewCard } from './ClubSettingsPlanningDefaultViewCard';
import {
  ClubSettingsPlanningViewPageLocalState,
  useClubSettingsPlanningViewPageLocalState,
} from './useClubSettingsPlanningViewPageLocalState.hook';

export const ClubSettingsPlanningViewPage = () => {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterResume = useDiveCenterResume();
  const localState: ClubSettingsPlanningViewPageLocalState =
    useClubSettingsPlanningViewPageLocalState();
  const clubSettingsPlanning = diveCenterResume.settingsPlanning;
  const periodConfigs = clubSettingsPlanning.periodConfigs;

  const redirectTo = useRedirect();

  return (
    <AppPageContainer className={'bg-gray-50'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.diveCenter.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.diveCenter.main,
            label: 'Centre de plongée',
            url: '/club/settings',
          },
          {
            label: 'Planning',
          },
        ]}
      >
        {isMultiDiveCenters && (
          <div className="app-px-content">
            <DiveCenterProBadge diveCenter={diveCenterResume} />
          </div>
        )}
      </AppBreadcrumbBar>
      <AppPageContentContainer
        paddingBottom={false}
        className="bg-gray-50 app-px-content"
      >
        <div className="flex gap-2 flex-wrap justify-between items-end">
          <h2 className="text-left uppercase text-base md:text-lg leading-6 mt-4 font-medium text-gray-800">
            Planning par défaut
          </h2>
          <div>
            <AppButton
              color="primary-outline-light"
              icon={AppIconsAction.add}
              href={clubSettingsDiveCenterUrlBuilder.planning.create()}
            >
              Créer une nouvelle période
            </AppButton>
          </div>
        </div>
        <div className="grid gap-2">
          {periodConfigs.map((periodConfig) => (
            <ClubSettingsPlanningDefaultViewCard
              key={periodConfig._id}
              periodConfig={periodConfig}
              localState={localState}
              className="app-my-content"
            />
          ))}
        </div>
        <AppMessageLight type="info">
          <p>
            Ceci est votre planning par défaut. Il s'applique chaque semaine
            pour la période concernée.
          </p>
          <p>
            Pour modifier une semaine en particulier, faites-le directement
            depuis le planning de la semaine concernée.
          </p>
          <p>
            Vous pouvez configurer autant de sorties que vous voulez chaque
            jour, pour chaque bateau.
          </p>
          <p>
            Ajoutez autant de périodes de configuration que vous avez de
            plannings saisonniers. La configuration avec les dates les plus
            proches s'appliquera automatiquement chaque jour.
          </p>
          <p>
            Vous pouvez aussi ajouter une période vide pour indiquer une période
            de fermeture.
          </p>
          <AppVisible role="club-view-planning">
            <div className="flex gap-4">
              <AppButton
                className="mt-5"
                color="primary-outline-light"
                icon={AppHeroIcons.planning}
                onClick={() => redirectTo('/club/planning')}
              >
                Consulter le planning de la semaine
              </AppButton>
            </div>
          </AppVisible>
        </AppMessageLight>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
