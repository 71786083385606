/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubLegalInformationCard } from '../../../../../../SE-02_account/ClubSettingsAccountCompanyInfoPage/DiveCenterEditDialog';
import { ClubSettingsSection } from '../../../../../../_core';
import { ClubSettingsOnlineBookingConfigPageLocalState } from '../../../useClubSettingsOnlineBookingConfigPageLocalState.hook';
import { ClubSettingsOnlineBookingPageBasePanel } from '../../_common';

export const ClubSettingsOnlineBookingLegalInformationViewPanel = ({
  localState,
}: {
  localState: ClubSettingsOnlineBookingConfigPageLocalState;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  const generalSettings = clubSettings.general;
  return (
    <ClubSettingsOnlineBookingPageBasePanel
      localState={localState}
      onClick={() => {
        state.editEnabled && dialogs.communication.open(selectedTab);
      }}
    >
      <div className="grid gap-4 md:gap-6">
        <ClubSettingsSection
          title="Mentions légales"
          comment={
            'Renseignez les informations légales à destination des clients de la vente en ligne'
          }
        >
          <ClubLegalInformationCard
            className="mt-4"
            legalInformation={clubSettings.publicData.legalInformation}
          />
        </ClubSettingsSection>
      </div>
    </ClubSettingsOnlineBookingPageBasePanel>
  );
};
