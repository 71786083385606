import {
  AppUrlAlias,
  ClubResumeAdmin,
  ClubResumeGql_pro,
} from '@mabadive/app-common-model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MQueryDescription, graphqlClient } from 'src/_common-browser';
import { appLogger } from 'src/business/_core/modules/root/logger';

type ClubResumeAdminGraphqlQueryResult = Exclude<
  ClubResumeAdmin,
  'lastAppUrlAlias'
> & {
  appUrlAliases: AppUrlAlias[];
};
export const clubResumeAdminsPageGraphqlFetcher = {
  fetchAll,
};

function fetchAll(options?: {
  where?: string;
  orderBy?: string;
  limit?: number;
}): Observable<ClubResumeAdmin[]> {
  // group all graphql queries into one fetch

  const query: MQueryDescription<ClubResumeAdminGraphqlQueryResult> =
    buildClubResumeAdminManyGraphqlQuery();

  return graphqlClient.query
    .runOne<ClubResumeAdminGraphqlQueryResult[]>(query, {
      type: 'query',
      name: 'clubResumeAdminsPageGraphqlFetcher',
      headers: {
        'x-hasura-role': 'admin', // select SUPER ADMIN hasura role
      },
    })
    .pipe(
      tap((res) =>
        appLogger.debug(
          '[clubResumeAdminsPageGraphqlFetcher.fetchAll]:',
          res.length,
        ),
      ),
      map((items) =>
        !items
          ? undefined
          : items.map((res) => {
              const finalRes: ClubResumeAdmin = {
                ...res,
                lastAppUrlAlias: res.appUrlAliases.length
                  ? res.appUrlAliases[0]
                  : undefined,
              };
              return finalRes;
            }),
      ),
    );
}

export function buildClubResumeAdminManyGraphqlQuery() {
  const queryDescription: MQueryDescription<ClubResumeAdminGraphqlQueryResult> =
    {
      // returnName: 'clubResumeAdmin',
      queryName: 'club',
      returnType: 'all',
      // where: `{reference: {_eq: "${clubReference}"}}`,
      returnAttributes: `
      ${ClubResumeGql_pro}
       clubLogs {
        _id
        _createdAt
        _updatedAt
        _version
        clubReference
        lastDiveSiteUpdate
        lastLoginAppVersion
        lastLoginDate
        lastParticipantUpdate
        lastStaffUpdate
        lastUpdate
        lastUpdateAppVersion
      }
      appUrlAliases(limit: 1, order_by: {_updatedAt: desc}) {
        _updatedAt
      }
      `,
    };

  return queryDescription;
}
