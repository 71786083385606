import {
  DashboardParticipantsByPeriod,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import React, { useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { DashboardReportPageMode } from 'src/pages/DA-dashboard/DA-02-dashboard-report/model';
import { chartHelper } from 'src/pages/DA-dashboard/_core/services';
import { EvolutionParticipantsByDayBarChartAttribute } from '../model';
import { EvolutionParticipantsByDayBarChartLegend } from './EvolutionParticipantsByDayBarChartLegend';
import { evolutionParticipantsByDayBarChartTooltipBuilder } from './evolutionParticipantsByDayBarChartTooltipBuilder.service';
import {
  DashboardStatsDiveModeColors,
  useDashboardStatsDiveModeColors,
} from './useDashboardStatsDiveModeColors.hook';

type ChartAttribute = EvolutionParticipantsByDayBarChartAttribute;

export type EvolutionParticipantsByDayBarChartConfig = {
  style: 'stacked-bar' | 'line';
  mode: 'total' | 'detail';
  visibility: EvolutionParticipantsByDayBarChartConfigVisibility;
};
export type EvolutionParticipantsByDayBarChartConfigVisibility = {
  firstDiveCount: boolean;
  trainingCount: boolean;
  recreationalAutonomousCount: boolean;
  recreationalSupervisedCount: boolean;
  recreationalInstructorsCount: boolean;
  autoSupervisedCount: boolean;
  watchingTourCount: boolean;
  otherCount: boolean;
  cancelCount: boolean;
};

export const EvolutionParticipantsByDayBarChart = ({
  participantsByPeriod,
  comparisonData,
  pageMode,
  chartWidth,
  showLegend = true,
  className,
}: {
  participantsByPeriod: ProStatsFetchResults['participantsByPeriod'];
  comparisonData?: DashboardParticipantsByPeriod[];
  pageMode: DashboardReportPageMode;
  chartWidth: number;
  showLegend: boolean;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const clubSettings = useClubSettings();

  const [config, setConfig] =
    useState<EvolutionParticipantsByDayBarChartConfig>({
      style: 'stacked-bar',
      mode: 'detail',
      visibility:
        pageMode === 'explos'
          ? {
              firstDiveCount: false,
              trainingCount: false,
              recreationalAutonomousCount: clubSettings.autonomous?.enabled,
              recreationalSupervisedCount: clubSettings.supervised?.enabled,
              recreationalInstructorsCount: clubSettings.instructor?.enabled,
              autoSupervisedCount: clubSettings.autoSupervised?.enabled,
              watchingTourCount: clubSettings.watchingTour?.enabled,
              otherCount: false,
              cancelCount: true,
            }
          : pageMode === 'trainings'
          ? {
              firstDiveCount: false,
              trainingCount: clubSettings.training?.enabled,
              recreationalAutonomousCount: false,
              recreationalSupervisedCount: false,
              recreationalInstructorsCount: false,
              autoSupervisedCount: false,
              watchingTourCount: false,
              otherCount: false,
              cancelCount: true,
            }
          : pageMode === 'first-dives'
          ? {
              firstDiveCount: clubSettings.firstDive?.enabled,
              trainingCount: false,
              recreationalAutonomousCount: false,
              recreationalSupervisedCount: false,
              recreationalInstructorsCount: false,
              autoSupervisedCount: false,
              watchingTourCount: false,
              otherCount: false,
              cancelCount: true,
            }
          : {
              firstDiveCount: clubSettings.firstDive?.enabled,
              trainingCount: clubSettings.training?.enabled,
              recreationalAutonomousCount: clubSettings.autonomous?.enabled,
              recreationalSupervisedCount: clubSettings.supervised?.enabled,
              recreationalInstructorsCount: clubSettings.instructor?.enabled,
              autoSupervisedCount: clubSettings.autoSupervised?.enabled,
              watchingTourCount: clubSettings.watchingTour?.enabled,
              otherCount: true,
              cancelCount: false,
            },
    });

  const maxYValue: number = useMemo(() => {
    if (comparisonData) {
      const allMaxValues = participantsByPeriod.data.map((x) => {
        return sumStackValues(config, x);
      });
      const allMaxValuesComparison = comparisonData.map((x) => {
        return sumStackValues(config, x);
      });
      return Math.max(...allMaxValues, ...allMaxValuesComparison);
    }
  }, [comparisonData, config, participantsByPeriod.data]);

  const statsDiveModeColors: DashboardStatsDiveModeColors =
    useDashboardStatsDiveModeColors(clubSettings);

  const data = useMemo(
    () =>
      participantsByPeriod.data.map((x, i) => {
        return {
          ...x,
          // bookingParticipantsCountAverage: chartHelper.buildAverageValue(
          //   participantsByPeriod,
          //   {
          //     attribute: 'bookingParticipantsCount',
          //     averageSize: 3,
          //     index: i,
          //   },
          // ),
          // bookingParticipantsCancelledCountAverage:
          //   chartHelper.buildAverageValue(participantsByPeriod, {
          //     attribute: 'bookingParticipantsCancelledCount',
          //     averageSize: 3,
          //     index: i,
          //   }),
        };
      }),
    [participantsByPeriod],
  );

  const stepInterval = participantsByPeriod.criteria.stepInterval;

  const tooltip = useMemo(
    () =>
      evolutionParticipantsByDayBarChartTooltipBuilder.build({
        stepInterval,
        colors: statsDiveModeColors,
        visibility: config.visibility,
      }),
    [statsDiveModeColors, config.visibility, stepInterval],
  );

  const maxBarSize = comparisonData ? 15 : 30;

  return chartWidth > 0 ? (
    <div>
      <BarChart
        className={className}
        width={chartWidth}
        height={200}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="beginDate"
          tickFormatter={(value) => {
            // TODO: on pourrait afficher le total dessous
            // const totalCount = participantsByPeriod.data.find(
            //   (x) => x.beginDate.getTime() === value.getTime(),
            // )?.totalCount;
            return chartHelper.formatDatePeriod(value, {
              stepInterval,
              format: 'short',
            }); // + ` (${totalCount})`
          }}
        />
        <YAxis
          tickFormatter={(value) => {
            return value;
          }}
          domain={
            maxYValue ? [0, maxYValue] : undefined // auto
          }
        />
        <Tooltip content={tooltip} />
        {/* <Tooltip
          formatter={(
            value: any,
            attributeName: ChartAttribute,
            chartItem,
            index,
            payload,
          ) => {
            const color = COLORS[attributeName];
            const label = LABELS[attributeName];

            return [
              <span key="value" style={{ color }}>
                {value}
              </span>,
              label,
            ];
          }}
          labelFormatter={(value, payload) => {
            return dateService.formatUTC(value, 'DD/MM/YYYY');
          }}
        /> */}
        {/* <ReferenceLine x={today.getTime()} stroke="red" /> */}
        {/* <Bar maxBarSize={maxBarSize}
          // stackId="1"
          dataKey={'totalCount'}
          type="monotone"
          stroke={COLORS['totalCount']}
          fill={COLORS['totalCount']}
        /> */}
        <Bar
          maxBarSize={maxBarSize}
          stackId="1"
          hide={!config.visibility.firstDiveCount}
          dataKey={'firstDiveCount'}
          type="monotone"
          stroke={statsDiveModeColors['firstDiveCount']}
          fill={statsDiveModeColors['firstDiveCount']}
        />
        <Bar
          maxBarSize={maxBarSize}
          stackId="1"
          hide={!config.visibility.trainingCount}
          dataKey={'trainingCount'}
          // type="monotone"
          stroke={statsDiveModeColors['trainingCount']}
          fill={statsDiveModeColors['trainingCount']}
        />
        <Bar
          maxBarSize={maxBarSize}
          stackId="1"
          hide={!config.visibility.recreationalSupervisedCount}
          dataKey={'recreationalAutonomousCount'}
          type="monotone"
          stroke={statsDiveModeColors['recreationalAutonomousCount']}
          fill={statsDiveModeColors['recreationalAutonomousCount']}
        />
        <Bar
          maxBarSize={maxBarSize}
          stackId="1"
          hide={!config.visibility.recreationalSupervisedCount}
          dataKey={'recreationalSupervisedCount'}
          type="monotone"
          stroke={statsDiveModeColors['recreationalSupervisedCount']}
          fill={statsDiveModeColors['recreationalSupervisedCount']}
        />
        <Bar
          maxBarSize={maxBarSize}
          stackId="1"
          hide={!config.visibility.recreationalInstructorsCount}
          dataKey={'recreationalInstructorsCount'}
          type="monotone"
          stroke={statsDiveModeColors['recreationalInstructorsCount']}
          fill={statsDiveModeColors['recreationalInstructorsCount']}
        />
        <Bar
          maxBarSize={maxBarSize}
          stackId="1"
          hide={!config.visibility.autoSupervisedCount}
          dataKey={'autoSupervisedCount'}
          type="monotone"
          stroke={statsDiveModeColors['autoSupervisedCount']}
          fill={statsDiveModeColors['autoSupervisedCount']}
        />
        <Bar
          maxBarSize={maxBarSize}
          stackId="1"
          hide={!config.visibility.watchingTourCount}
          dataKey={'watchingTourCount'}
          type="monotone"
          stroke={statsDiveModeColors['watchingTourCount']}
          fill={statsDiveModeColors['watchingTourCount']}
        />
        <Bar
          maxBarSize={maxBarSize}
          stackId="1"
          hide={!config.visibility.otherCount}
          dataKey={'otherCount'}
          // type="monotone"
          stroke={statsDiveModeColors['otherCount']}
          fill={statsDiveModeColors['otherCount']}
        />
        <Bar
          maxBarSize={maxBarSize}
          stackId="1"
          hide={!config.visibility.cancelCount}
          dataKey={'cancelCountDiver'}
          // type="monotone"
          stroke={statsDiveModeColors['cancelCountDiver']}
          fill={statsDiveModeColors['cancelCountDiver']}
        />
        <Bar
          maxBarSize={maxBarSize}
          stackId="1"
          hide={!config.visibility.cancelCount}
          dataKey={'cancelCountClub'}
          // type="monotone"
          stroke={statsDiveModeColors['cancelCountClub']}
          fill={statsDiveModeColors['cancelCountClub']}
        />
      </BarChart>
      {showLegend && (
        <EvolutionParticipantsByDayBarChartLegend
          colors={statsDiveModeColors}
          configVisibility={config.visibility}
          setConfigVisibility={(visibility) =>
            setConfig({
              ...config,
              visibility,
            })
          }
        />
      )}
      {/* <p className="my-5 text-gray-400">
        <b className=" text-gray-500">NOTE:</b> les participants sont groupés
        par date de réservation, quelque soit la date de la prestation.
      </p> */}
    </div>
  ) : null;
};
function sumStackValues(
  config: EvolutionParticipantsByDayBarChartConfig,
  x: DashboardParticipantsByPeriod,
) {
  const stackValues: number[] = [];
  if (config.visibility.firstDiveCount) {
    stackValues.push(x.firstDiveCount);
  }
  if (config.visibility.trainingCount) {
    stackValues.push(x.trainingCount);
  }
  if (config.visibility.recreationalAutonomousCount) {
    stackValues.push(x.recreationalAutonomousCount);
  }
  if (config.visibility.recreationalSupervisedCount) {
    stackValues.push(x.recreationalSupervisedCount);
  }
  if (config.visibility.recreationalInstructorsCount) {
    stackValues.push(x.recreationalInstructorsCount);
  }
  if (config.visibility.otherCount) {
    stackValues.push(x.otherCount);
  }
  if (config.visibility.cancelCount) {
    stackValues.push(x.cancelCountClub);
    stackValues.push(x.cancelCountDiver);
  }
  return stackValues.reduce((total, num) => total + num, 0);
}
