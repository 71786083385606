/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ClubEventCardGroup } from '../../club-events/ClubEventCard';
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import { ClubPlanningLightViewDaySessions } from '../components';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
} from '../model';
import { ClubDialogsState } from '../../../../../pages/_dialogs';
export const ClubPlanningLightPanelViewPeriodDayHorizontal = ({
  context,
  day,
  planningConfig,
  clickActions,
  sessionsDisplayConfigs = [],
  className,
  planningParticipantsLocalState,
  dialogsState,
}: {
  planningParticipantsLocalState?: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
  dialogsState: ClubDialogsState; // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  context: ClubPlanningDisplayConfigurationBarContext;
  day: ClubPlanningDayFull;
  planningConfig: PlanningViewConfig;
  clickActions: ClubPlanningLightPanelViewClickActions;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  className?: string;
}) => {
  const { onClickEvent } = clickActions;

  return (
    <div className={className}>
      {day.clubEvents?.length > 0 && (
        <div className="mb-2 lg:mb-5">
          <ClubEventCardGroup
            clubEvents={day.clubEvents}
            widthMode={'large'}
            onClick={onClickEvent}
          />
        </div>
      )}
      <ClubPlanningLightViewDaySessions
        planningParticipantsLocalState={planningParticipantsLocalState}
        context={context}
        day={day}
        clickActions={clickActions}
        planningConfig={planningConfig}
        sessionsDisplayConfigs={sessionsDisplayConfigs}
        dialogsState={dialogsState}
      />
    </div>
  );
};
