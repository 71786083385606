import { AdminBaseData } from '@mabadive/app-common-model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { BootstrapOptions } from '../appDataLoader.service';
import { adminBaseDataGraphqlFetcher } from './adminBaseDataGraphqlFetcher.service';
import { adminBaseDataStore } from './adminBaseDataStore.service';

export const adminBaseDataLoader = {
  clear,
  loadAdminInitialData,
};

function clear() {
  // clear store
  adminBaseDataStore.clearData('adminBaseDataLoader_clear');
}

function loadAdminInitialData({
  options,
  appClientId,
}: {
  options: BootstrapOptions;
  appClientId: string;
}): Observable<any> {
  return loadAdminBaseData(options).pipe(
    first(),
    switchMap((adminBaseData) => {
      if (adminBaseData.clubs) {
        adminBaseDataStore.setData(
          adminBaseData,
          'adminBaseDataLoader_adminBaseData',
        );
      } else {
        appLogger.warn('[adminBaseDataLoader] invalid data:', adminBaseData);
        adminBaseDataStore.clearData();
        return throwError(new Error('Error loading data'));
      }

      return of(undefined);
    }),
  );
}

function loadAdminBaseData(
  options: BootstrapOptions,
): Observable<AdminBaseData> {
  if (options.initialFetchMethod === 'graphql-api') {
    return adminBaseDataGraphqlFetcher.fetch({ userId: options.userId }).pipe(
      catchError((err) => {
        appLogger.warn('[adminBaseDataLoader] error loading GRAPHQL data', err);
        return throwError(err);
      }),
    );
  }
  return throwError(
    new Error(`Unsupported initial fetch mode "${options.initialFetchMethod}"`),
  );
}
