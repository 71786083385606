import { Booking } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { DiverBookingCardBookingAction } from '../../../_from-diver-app';
import { BookingTabModel, PRO_BookingMemberFull } from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';

export function useDiverBookingCardHamburgerMenu({
  booking,
  bookingMembersFull,
  actions,
  dialogs,
  onClickBooking,
  bookingTabModel,
  setIsExpanded,
  extraMenuItems,
}: {
  booking: Booking;
  bookingMembersFull?: PRO_BookingMemberFull[]; // edition only
  actions: DiverBookingPageActions;
  dialogs: ClubDialogsStateOld;
  onClickBooking: (
    bookingTabModel: BookingTabModel,
    action: DiverBookingCardBookingAction,
  ) => void;
  bookingTabModel: BookingTabModel;
  setIsExpanded: (value: boolean) => void;
  extraMenuItems: AppHamburgerMenuItem[];
}) {
  const { openDiverSelector, openSessionSelector, openBookingMassiveEditor } =
    actions;
  const az = useAppSecurityUserClubAuthorizations();

  const { setBookingGroupAndJourneyEditDialogState } = dialogs;

  return useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    if (az.edit.participant.booking) {
      hamburgerMenuItems.push({
        icon: AppHeroIcons.actionEdit,
        severity: 'info',
        title: 'Modifier',
        description: 'Dates de séjours, club...',
        onClick: () => {
          setBookingGroupAndJourneyEditDialogState({
            isOpen: true,
            booking,
            bookingMembersFull,
          });
        },
      });
      if (booking.active) {
        if (booking.bookingStatus === 'pending') {
          hamburgerMenuItems.push({
            icon: AppHeroIcons.check,
            severity: 'success',
            title: 'Confirmer la réservation',
            // description: 'Vous ne pourrez plus ajouter de plongeur',
            onClick: () => {
              onClickBooking(bookingTabModel, 'confirm-booking');
            },
          });
        }
      }
      const { futureSessionsCount } = bookingTabModel.meta;

      const showMassiveDialogButton =
        (bookingMembersFull.length > 1 && futureSessionsCount > 0) ||
        (bookingMembersFull.length > 0 && futureSessionsCount > 1);

      if (showMassiveDialogButton) {
        hamburgerMenuItems.push({
          icon: AppHeroIcons.actionEditBulk,
          severity: 'info',
          title: 'Inscription en masse',
          onClick: () => {
            openBookingMassiveEditor({
              bookingId: booking._id,
            });
          },
        });
      }
    }

    if (booking.active) {
      if (az.edit.participant.booking) {
        hamburgerMenuItems.push({
          title: 'Ajouter un plongeur',
          description:
            'Créez un nouveau plongeur et ajoutez-le à cette réservation',
          icon: AppHeroIcons.actionAdd,
          onClick: () => {
            openDiverSelector({ bookingId: booking._id });
          },
        });
        hamburgerMenuItems.push({
          title: 'Ajouter une session',
          description:
            'Ajouter une session de plongée à cette réservation et inscrivez-y les plongeurs',
          icon: AppHeroIcons.actionAdd,
          onClick: () => {
            openSessionSelector({ bookingId: booking._id });
          },
        });
      }
    }

    if (az.edit.participant.booking) {
      hamburgerMenuItems.push({
        icon: AppHeroIcons.actionClone,
        severity: 'info',
        title: 'Dupliquer la réservation',
        description: 'Créer une nouvelle réservation avec les mêmes plongeurs',
        onClick: () => {
          onClickBooking(bookingTabModel, 'duplicate-booking');
        },
      });
      if (booking.active) {
        hamburgerMenuItems.push({
          icon: AppHeroIcons.actionArchive,
          severity: 'warn',
          title: 'Archiver la réservation',
          description: 'Vous ne pourrez plus ajouter de plongeur',
          onClick: () => {
            onClickBooking(bookingTabModel, 'archive-booking');
            setIsExpanded(false);
          },
        });
      } else {
        hamburgerMenuItems.push({
          icon: AppHeroIcons.actionArchive,
          severity: 'info',
          title: 'Ré-activer la réservation',
          description: 'Vous pourrez de nouveau ajouter des plongeurs',
          onClick: () => {
            onClickBooking(bookingTabModel, 'unarchive-booking');
            setIsExpanded(true);
          },
        });
      }
      hamburgerMenuItems.push({
        icon: AppHeroIcons.actionRemove,
        severity: 'danger',
        title: 'Supprimer cette réservation et ses plongées',
        description: 'ATTENTION: la suppression est définitive',
        onClick: () => {
          onClickBooking(bookingTabModel, 'delete-booking');
        },
      });
    }

    return hamburgerMenuItems.concat(extraMenuItems);
  }, [
    az.edit.participant.booking,
    booking,
    extraMenuItems,
    bookingTabModel,
    bookingMembersFull,
    setBookingGroupAndJourneyEditDialogState,
    onClickBooking,
    openBookingMassiveEditor,
    openDiverSelector,
    openSessionSelector,
    setIsExpanded,
  ]);
}
