import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton, AppMessage } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { useAppSecurityUser } from 'src/business/auth/services';
import { AppTitleDateNavigatorWithPeriodSelector } from 'src/pages/_components/title-navigators';
import {
  DailySessionsResumeCard,
  DashboardReportPageGlobalActivityBookings,
  DashboardReportPageGlobalActivityParticipants,
  DashboardReportPageGlobalActivityPayments,
} from '../_core';

import { DashboardHomePageLocalState } from './useDashboardHomePageLocalState';

export const DashboardHomePanel = ({
  localState,
}: {
  localState: DashboardHomePageLocalState;
}) => {
  const { state, data } = localState;
  const { viewParameters, setViewParameters } = state;
  const { fetchResult } = data;

  // const [exportToken, setExportToken] = useState<string>();
  const redirectTo = useRedirect();

  const dateUTC = useMemo(
    () => localState?.data?.fetchResult?.criteria?.beginDate,
    [localState?.data?.fetchResult?.criteria?.beginDate],
  );
  const dateLabel = useMemo(
    () => dateService.formatUTC(dateUTC, 'dddd DD/MM/YYYY'),
    [dateUTC],
  );
  const user = useAppSecurityUser();

  const limitDates = useMemo(() => !user.roles.includes('super-admin'), [user]);

  const { minDate, maxDate } = useMemo(
    () => ({
      minDate: limitDates ? dateService.add(new Date(), -7, 'day') : undefined,
      maxDate: limitDates ? dateService.add(new Date(), 7, 'day') : undefined,
    }),
    [limitDates],
  );

  const title = useMemo(() => {
    return `Caisse du jour - ${dateLabel}`;
  }, [dateLabel]);

  return (
    <>
      {fetchResult && (
        <div className="px-2 mt-4 pb-8 w-full">
          <div className="sticky top-0  px-2 py-2 z-[10] bg-gray-50 flex gap-5 justify-between">
            <AppTitleDateNavigatorWithPeriodSelector
              className="w-full"
              hidePeriodSelector={true}
              periodRange={viewParameters.periodRange}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(periodRange) => {
                setViewParameters({
                  ...viewParameters,
                  periodRange,
                });
              }}
            />
          </div>
          <div className="w-full flex flex-col gap-5">
            {user.roles.includes('club-view-dashboard-daily-planning-resume') &&
              data.diveSessionResumes && (
                <DailySessionsResumeCard
                  dateUTC={dateUTC}
                  dateLabel={dateLabel}
                  diveSessionResumes={data.diveSessionResumes}
                  clubEvents={data.clubEvents}
                  dailyConfigs={data.dailyConfigs}
                  dialogsState={state.dialogsState}
                />
              )}
            <div className="w-full flex flex-col gap-5 sm:grid sm:grid-cols-2 2xl:grid-cols-4">
              {user.roles.includes('club-view-dashboard-daily-cash') && (
                <DashboardReportPageGlobalActivityPayments
                  title={title}
                  className="sm:col-span-2"
                  paymentsOverPeriod={data.fetchResult.paymentsOverPeriod}
                />
              )}
              {user.roles.includes('club-view-dashboard-daily-stats') && (
                <>
                  <DashboardReportPageGlobalActivityParticipants
                    title="Activité du jour"
                    participantsOverPeriod={
                      data.fetchResult.participantsOverPeriod
                    }
                  />
                  <DashboardReportPageGlobalActivityBookings
                    title="Réservations du jour"
                    bookingsOverPeriod={data.fetchResult.bookingsOverPeriod}
                  />
                </>
              )}
            </div>
          </div>
          <AppMessage
            className="my-5"
            title={'Que manque-t-il sur cette page?'}
            message={
              <>
                <p>
                  Vous souhaiteriez voir un nouvel indicateur sur cette page?
                  N'hésitez pas à le demander!
                </p>

                <AppButton
                  className="mt-5"
                  color="primary-outline-light"
                  icon={AppHeroIcons.contact}
                  onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
                >
                  Contactez-nous
                </AppButton>
              </>
            }
          />
        </div>
      )}
    </>
  );
};
