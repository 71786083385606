/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { AppFormControlRHF_Deprecated, AppInputRHF } from 'src/lib/form';
import { CustomerSpaceConfigDialogLocalState } from '../../useCustomerSpaceConfigDialogLocalState.hook';

export const CustomerSpaceConfigFormTabHomePage = ({
  localState,
}: {
  localState: CustomerSpaceConfigDialogLocalState;
}) => {
  const { form } = localState;
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formPrefix = 'clubSettings.publicSettings.customerSpace' as const;

  return (
    <div className="grid gap-8">
      <div className="grid gap-2">
        <h3 className="font-bold text-base text-app-primary-light">
          Page d'accueil sur l'espace client
        </h3>
        <AppFormControlRHF_Deprecated
          className="my-2 w-full xs:col-span-12"
          label="Message par défaut:"
          control={control}
          name={`${formPrefix}.defaultBookingConfig.customerUi.messageToDisplay`}
          renderComponent={(props) => (
            <AppInputRHF
              {...props}
              fullWidth
              multiline
              rowsMin={3}
              rowsMax={15}
              rows={2}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="my-2 w-full xs:col-span-12"
          label="Lien par défaut:"
          control={control}
          name={`${formPrefix}.defaultBookingConfig.customerUi.linkToDisplay`}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <AppMessageLight>
          <div className="grid gap-4">
            <div className="">
              Configurez ici le message par défaut que vous souhaitez afficher
              sur la page d'accueil de l'espace client.
            </div>
            <div className="">
              Le message est modifiable sur la page "Espace client" de chaque
              réservation.
            </div>
            <div className="">
              Vous pouvez également afficher un lien sous le message.
            </div>
          </div>
        </AppMessageLight>
      </div>
    </div>
  );
};
