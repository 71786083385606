/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  paymentMethodFormatter,
  purchasePaymentStateFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { DateRangeLabel } from 'src/pages/_components';
import { AgencyLabel } from '../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { PaymentsListPageLocalState } from '../usePaymentsListPageLocalState';

export const PaymentsListPageConfigBarFiltersResume = ({
  localState,
  className,
}: {
  localState: PaymentsListPageLocalState;
  className?: string;
}) => {
  const { viewParameters, setViewParameters, filters } = localState;

  const { searchText, paymentState, paymentMethod, bookingAgencyId } = filters;

  const { beginDate, endDate } = viewParameters.periodRange.value;

  return beginDate ||
    endDate ||
    paymentState ||
    paymentMethod ||
    bookingAgencyId ||
    searchText?.trim()?.length !== 0 ? (
    <div
      className={clsx(
        'flex justify-start flex-wrap gap-x-4 text-xs text-gray-400 uppercase',
        className,
      )}
    >
      {searchText?.trim()?.length !== 0 && (
        <div className={clsx('flex xs:hidden gap-1')}>
          <div className="font-bold whitespace-nowrap">Recherche:</div>
          <div className="whitespace-nowrap">{searchText?.trim()}</div>
        </div>
      )}
      {(beginDate || endDate) && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Date de paiement:</div>
          <DateRangeLabel beginDate={beginDate} endDate={endDate} />
        </div>
      )}
      {paymentMethod && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Moyen de paiement:</div>
          {paymentMethodFormatter.formatPaymentMethod(paymentMethod, {
            format: 'long',
          })}
        </div>
      )}{' '}
      {paymentState && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Statut:</div>
          {purchasePaymentStateFormatter.formatPaymentState(paymentState)}
        </div>
      )}
      {bookingAgencyId && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Agence:</div>
          <AgencyLabel agencyId={bookingAgencyId} />
        </div>
      )}
    </div>
  ) : null;
};
