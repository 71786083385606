/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { ClubSettingsSectionComment } from './ClubSettingsSectionComment';
import { ClubSettingsSectionTitle } from './ClubSettingsSectionTitle';

export const ClubSettingsSection = ({
  title,
  header,
  comment,
  children,
  search,
  actions,
  onClick,
  className,
}: {
  title: React.ReactNode | React.ReactNode[];
  header?: React.ReactNode | React.ReactNode[];
  comment?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
  search?: React.ReactNode | React.ReactNode[];
  actions?: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
  className?: string;
}) => {
  return !children ? null : (
    <div
      className={clsx(
        'flex flex-col gap-y-4',
        onClick && 'cursor-pointer',
        className,
      )}
      onClick={onClick}
    >
      <div className="">
        <div className="flex flex-col gap-y-2">
          {title && (
            <ClubSettingsSectionTitle>{title}</ClubSettingsSectionTitle>
          )}
          <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-between gap-x-2 gap-y-2">
            {comment ? (
              <ClubSettingsSectionComment>{comment}</ClubSettingsSectionComment>
            ) : !search ? (
              <div></div>
            ) : null}
            {search && <div className="flex-grow">{search}</div>}
            {actions && <div className="flex justify-end">{actions}</div>}
          </div>
        </div>
        {header && header}
      </div>
      {children && <div className="flex flex-col gap-y-2">{children}</div>}
    </div>
  );
};
