import {
  ClubCommercePurchasePaymentGql_Company,
  ClubDiverGql_Company,
  ClubPurchasePaymentResume,
} from '@mabadive/app-common-model';
import {
  HASURA_DATE_ONLY_FORMAT,
  HASURA_DATE_TIME_WITH_MS_FORMAT,
  dateService,
} from '@mabadive/app-common-services';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MQueryDescription, graphqlClient } from 'src/_common-browser';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { PurchasePaymentGql1CollectionCriteria } from '../model';

export const purchasePaymentGql1GraphqlFetcher = {
  findMany,
};

function findMany(
  criteria: PurchasePaymentGql1CollectionCriteria,
  {
    type,
    name = 'findManyPurchasePaymentGql1s',
  }: {
    type: 'subscription' | 'query';
    name?: string;
  },
): Observable<ClubPurchasePaymentResume[]> {
  const query: MQueryDescription<any> =
    buildPurchasePaymentGql1CollectionGraphqlQuery(criteria);

  return graphqlClient.query
    .runOne<ClubPurchasePaymentResume[]>(query, {
      type,
      name,
    })
    .pipe(
      tap((res) => {
        appLogger.info(
          '[bookingPurchasePaymentGql1GraphqlFetcher.findMany] PurchasePaymentGql1s:',
          res.length,
        );
      }),
      catchError((err) => {
        appLogger.warn(
          '[bookingPurchasePaymentGql1GraphqlFetcher] error fetching data',
          err,
        );
        return throwError(err);
      }),
    );
}

export function buildPurchasePaymentGql1CollectionGraphqlQuery({
  clubReference,
  diveCenterId,
  beginDate,
  endDate,
  paymentState,
  paymentMethod,
  updatedAfter,
  maxResults,
}: PurchasePaymentGql1CollectionCriteria) {
  const whereAndClauses: string[] = [];

  whereAndClauses.push(`clubReference: {_eq: "${clubReference}"}`);
  if (diveCenterId) {
    // utilisé sur la liste, mais pas sur l'onglet des paiements de la résa
    whereAndClauses.push(`diveCenterId: {_eq: "${diveCenterId}"}`);
  }

  if (beginDate || endDate) {
    const paymentDateWhereClauses: string[] = [];

    if (beginDate) {
      const beginDateString = dateService.formatUTC(
        beginDate,
        HASURA_DATE_ONLY_FORMAT,
      );
      paymentDateWhereClauses.push(`_gte: "${beginDateString}"`);
    }
    if (endDate) {
      const endDateString = dateService.formatUTC(
        endDate,
        HASURA_DATE_ONLY_FORMAT,
      );
      paymentDateWhereClauses.push(`_lte: "${endDateString}"`);
    }
    whereAndClauses.push(
      `paymentDate: {
        ${paymentDateWhereClauses.join(',')}
      }`,
    );
  }
  if (updatedAfter) {
    const updatedAfterString = dateService.formatUTC(
      updatedAfter,
      HASURA_DATE_TIME_WITH_MS_FORMAT,
    );

    whereAndClauses.push(`_updatedAt: {_gt: "${updatedAfterString}"}`);
  }

  if (paymentState) {
    whereAndClauses.push(`paymentState: {_eq: "${paymentState}"}`);
  }
  if (paymentMethod) {
    whereAndClauses.push(`paymentMethod: {_eq: "${paymentMethod}"}`);
  }

  const returnAttributes = `
      ${ClubCommercePurchasePaymentGql_Company}
      paymentPackagesDetails {
          _id
          amount
          purchasePaymentId
          purchasePackageId
      }
      diver {
        ${ClubDiverGql_Company}
      }
      booking{
        _id
        bookingDate
        bookingGroup
      }
  `;

  const where = `{_and: {
    ${whereAndClauses.join(',')}
  }}`;

  const queryDescription: MQueryDescription<ClubPurchasePaymentResume> = {
    returnName: 'ClubPurchasePayments',
    queryName: 'club_commerce_purchase_payment',
    returnType: 'all',
    // where: undefined, // TODO filtre par période
    where,
    orderBy: '{paymentDate: desc, dueDate: desc, creationDate: desc}',
    returnAttributes,
    limit: maxResults,
  };

  return queryDescription;
}
