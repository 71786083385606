import {
  diverLinkParametersToReplaceRenderer,
  emailLinkBuilder,
  smsLinkBuilder,
  whatzappLinkBuilder,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useAppDevice } from 'src/business/_core/modules/layout';

import { CreateMessageToCustomersAggregatedRecipient } from '../model';
import { useCreateMessageParamsToReplace } from '../services';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';

export function useCreateMessageSendActionLink({
  localState,
  recipient,
}: {
  localState: CreateMessageToCustomersLocalState;
  recipient?: CreateMessageToCustomersAggregatedRecipient;
}) {
  const {
    data: { form, result },
  } = localState;

  const { control } = form;

  const [messageTarget, title, body, signature, recipientType] = useWatch({
    control,
    name: [
      'messageTarget',
      'messageContent.title',
      'messageContent.body',
      'messageContent.signature',
      'recipientType',
    ],
  });

  const { isMobile, deviceOS } = useAppDevice();

  const paramsToReplace = useCreateMessageParamsToReplace({
    localState,
    recipient,
  });

  const context = localState?.data?.initialState?.context;

  const sendActionLink = useMemo(() => {
    const validSelectedUniqueContacts =
      result.aggregated.validSelectedUniqueContacts;
    if (recipientType === 'personal' && !recipient) {
      return null; // aller sur la page 4
    }
    if (messageTarget === 'email') {
      // TODO: on pourra avoir un mode group + autres en copie
      const emailAddresses =
        recipientType === 'personal'
          ? [emailLinkBuilder.cleanEmailAddress(recipient.targetValidContact)]
          : emailLinkBuilder.cleanEmailAddresss(validSelectedUniqueContacts);
      const titleWithParams =
        diverLinkParametersToReplaceRenderer.renderTemplate({
          template: title,
          paramsToReplace,
        });
      const bodyWithParams =
        diverLinkParametersToReplaceRenderer.renderTemplate({
          template: body,
          paramsToReplace,
        });
      const signatureWithParams =
        diverLinkParametersToReplaceRenderer.renderTemplate({
          template: signature,
          paramsToReplace,
        });
      const useBcc = context === 'session' && emailAddresses.length > 1;
      return emailLinkBuilder.buildLink({
        to: !useBcc ? emailAddresses : undefined,
        bcc: useBcc ? emailAddresses : undefined,
        subject: titleWithParams,
        body: bodyWithParams,
        signature: signatureWithParams,
      });
    } else if (messageTarget === 'sms') {
      const template = smsLinkBuilder.buildMessageFromChunks({
        title,
        body,
        signature,
      });
      const message = diverLinkParametersToReplaceRenderer.renderTemplate({
        template,
        paramsToReplace,
      });
      const phoneNumbers =
        recipientType === 'personal'
          ? [smsLinkBuilder.cleanPhoneNumber(recipient.targetValidContact)]
          : smsLinkBuilder.cleanPhoneNumbers(validSelectedUniqueContacts);
      return smsLinkBuilder.buildLinkSms({
        phoneNumbers,
        message,
        deviceOS,
      });
    } else if (messageTarget === 'whatsapp') {
      if (!recipient) {
        return null; // pas possible d'envoyer un message whatsapp de groupe
      }
      const template = whatzappLinkBuilder.buildMessageFromChunks({
        title,
        body,
        signature,
      });
      const message = diverLinkParametersToReplaceRenderer.renderTemplate({
        template,
        paramsToReplace,
      });

      return whatzappLinkBuilder.buildLink({
        phoneNumber: recipient.targetValidContact,
        message,
        isMobile,
        skipCleanPhoneNumber: true,
      });
    }
  }, [
    body,
    context,
    deviceOS,
    isMobile,
    messageTarget,
    paramsToReplace,
    recipient,
    recipientType,
    result.aggregated.validSelectedUniqueContacts,
    signature,
    title,
  ]);

  return sendActionLink;
}
