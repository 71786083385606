/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubStaffMemberRole,
  DiveSessionStaffMembersTableContext,
  DiveSessionStaffMemberTableModelStaffMember,
  StaffMemberResumeSessionsDayModel,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import {
  commonDiveSessionBuilder,
  dateService,
  nameFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { StaffLevelBadge } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { OnUpdateSessionStaffConfigFn } from '../../../DiveSessionDialogTab2StaffMembers/useOnUpdateSessionStaffConfig.hook';
import {
  DiveSessionStaffMemberSessionTimes,
  DiveSessionStaffMembersTableSession,
} from '../DiveSessionStaffMembersTable';
import { DiveSessionStaffMembersTableRowStaffRoles } from '../DiveSessionStaffMembersTable/DiveSessionStaffMembersTableRowStaffRoles';

export function DiveSessionStaffMembersCardStaff({
  diveSessionReference,
  staffMembersResumeSessions,
  isMultiSessionConfigForStaff,
  staffFirstNameBefore,
  context,
  staffMemberFull,
  availableRoles,
  onClickEdit,
  onUpdateSessionStaffConfig,
  date,
  isSelected,
  toogleSelect,
  className = '',
}: {
  diveSessionReference: string;
  staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
  isMultiSessionConfigForStaff: boolean;
  staffFirstNameBefore: boolean;
  context: DiveSessionStaffMembersTableContext;
  staffMemberFull: DiveSessionStaffMemberTableModelStaffMember;
  availableRoles: ClubStaffMemberRole[];
  onClickEdit?: () => any;
  onUpdateSessionStaffConfig?: OnUpdateSessionStaffConfigFn;
  date: Date;
  isSelected?: boolean;
  toogleSelect?: () => void;
  className?: string;
}) {
  const {
    staffMember,
    session1,
    session2,
    meta,
    dailyAvailabilityResume,
    sessionAssigmentResume,
  } = staffMemberFull;

  const staffMemberResumeSessions: StaffMemberResumeSessionsModel =
    useMemo(() => {
      return staffMembersResumeSessions.find(
        (x) => x.staffMember._id === staffMember._id,
      );
    }, [staffMember._id, staffMembersResumeSessions]);

  const staffMemberResumeSessionsDay: StaffMemberResumeSessionsDayModel =
    useMemo(() => {
      if (staffMemberResumeSessions) {
        const dayReference = commonDiveSessionBuilder.buildDayReference(date);
        return staffMemberResumeSessions.days.find(
          (x) => x.dayReference === dayReference,
        );
      }
    }, [date, staffMemberResumeSessions]);
  const isPast = !dateService.isTodayUTC(date) && dateService.isPastUTC(date);

  return (
    <div
      className={clsx(
        'flex gap-2',
        meta.active
          ? 'text-app-blue'
          : meta.available
          ? 'text-gray-600'
          : 'text-gray-400',
        (onClickEdit || toogleSelect) && 'cursor-pointer',
        className,
      )}
      onClick={toogleSelect ?? onClickEdit}
    >
      <div className="flex-grow flex gap-x-4 gap-y-1 flex-wrap">
        <div className="flex gap-x-2">
          {onClickEdit && context === 'staff-edit-roles' && (
            <div
              className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              onClick={(e) => {
                e.stopPropagation();
                onClickEdit();
              }}
            >
              <AppHeroIcons.actionEditAlt className="w-8 h-6" />
            </div>
          )}
          {context === 'groups-editor' && toogleSelect && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                toogleSelect();
              }}
            >
              <input type="checkbox" readOnly={true} checked={isSelected} />
            </div>
          )}
          <div>
            <div
              className={
                'w-48 text-sm flex whitespace-nowrap items-center gap-1'
              }
            >
              <StaffLevelBadge
                className="rounded px-0.5 w-9 text-xs flex-shrink-0"
                staffMember={staffMember}
                date={date}
              />
              <span className={'truncate font-bold flex-shrink'}>
                {nameFormatter.formatFullName(staffMember.profile, {
                  format: staffFirstNameBefore
                    ? 'firstName-first'
                    : 'lastName-first',
                })}
              </span>
            </div>
            <DiveSessionStaffMemberSessionTimes
              className={''}
              fontSize={'text-xs'}
              sessionsDetails={staffMemberResumeSessionsDay?.sessionsDetails}
              availabilityPeriod={
                staffMemberResumeSessionsDay?.availabilityPeriod
              }
              showAvailabilityPeriods={['am', 'pm']}
              focusSessionReference={diveSessionReference}
              timesToDisplay={'assigned'}
              // TODO vérifier dans le contexte si besoin de filtrer (dans le doute, on ne filtre pas au moment de l'introduction de ce paramètre)
              filterCancelled={false}
              filterPastAndEmpty={false}
              isPast={isPast}
            />
          </div>
        </div>
        <div className="flex gap-4">
          {context === 'staff-edit-roles' && (
            <div
              className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              onClick={(e) => {
                e.stopPropagation();
                if (!meta.active) {
                  onUpdateSessionStaffConfig(staffMember, {
                    availabilityPeriod:
                      dailyAvailabilityResume.availabilityPeriod, // pas de changement
                    isAvailableOnSession: !meta.available,
                    toogleRole: undefined,
                  });
                }
              }}
            >
              <AppInputBooleanSwitch
                disabled={meta.active}
                value={meta.available}
                // readOnly={true}
                onChange={undefined}
              />
            </div>
          )}
          {(context === 'staff-edit-roles' ||
            context === 'groups-editor' ||
            context === 'security-sheet') && (
            <>
              <div
                className={'whitespace-nowrap text-sm text-center'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <DiveSessionStaffMembersTableRowStaffRoles
                  className="w-full"
                  isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
                  staffMemberFull={staffMemberFull}
                  availableRoles={availableRoles}
                  onClick={
                    onUpdateSessionStaffConfig
                      ? ({ role }) => {
                          if (onUpdateSessionStaffConfig) {
                            onUpdateSessionStaffConfig(staffMember, {
                              availabilityPeriod:
                                meta.available ||
                                !role.assigned ||
                                dailyAvailabilityResume.isAvailableToday
                                  ? 'day'
                                  : 'none',
                              isAvailableOnSession:
                                meta.available || !role.assigned,
                              toogleRole: role,
                            });
                          }
                        }
                      : undefined
                  }
                />
              </div>
            </>
          )}
          <div className="flex-grow flex gap-4 justify-between">
            <div className={'whitespace-nowrap text-xs'}>
              <DiveSessionStaffMembersTableSession
                showRoles={false}
                session={session1}
              />
            </div>
            {isMultiSessionConfigForStaff && (
              <div className={'whitespace-nowrap text-xs'}>
                <DiveSessionStaffMembersTableSession
                  showRoles={false}
                  session={session2}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {onClickEdit && context === 'groups-editor' && (
        <div
          className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          onClick={(e) => {
            e.stopPropagation();
            onClickEdit();
          }}
        >
          <AppHeroIcons.actionEditAlt className="w-8 h-6" />
        </div>
      )}
      {context === 'groups-editor' && (
        <div
          className={clsx(
            'text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
            meta.active && 'invisible',
          )}
          onClick={(e) => {
            e.stopPropagation();
            if (!meta.active) {
              onUpdateSessionStaffConfig(staffMember, {
                availabilityPeriod: dailyAvailabilityResume.availabilityPeriod, // pas de changement
                isAvailableOnSession: false,
                toogleRole: undefined,
              });
            }
          }}
        >
          <AppIconsAction.delete className="h-6 w-6 text-status-error hover:text-status-error-dark" />
        </div>
      )}
    </div>
  );
}
