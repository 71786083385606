/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubPlanningLightSessionDef } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { PlanningViewConfig } from '../../club-planning/_model';
import { CalendarSessionWidthMode } from '../components/ClubPlanningLightSession/session-common';
import { ClubPlanningDayFull } from '../model';
import { ClubPlanningAction } from '../useClubPlanningActions.hook';
import { ClubPlanningLightPanelViewPeriodMonthWeekDayResumeTitle } from './ClubPlanningLightPanelViewPeriodMonthWeekDayResumeTitle';
import { ClubPlanningLightPanelViewPeriodMonthWeekDaySession } from './ClubPlanningLightPanelViewPeriodMonthWeekDaySession';
import { ClubPlanningLightPanelViewPeriodMonthState } from './useClubPlanningLightPanelViewPeriodMonthState.hook';

export const ClubPlanningLightPanelViewPeriodMonthWeekDayResume = ({
  localState,
  clubPlanningActions,
  day,
  widthMode,
  className,
}: {
  localState: ClubPlanningLightPanelViewPeriodMonthState;
  clubPlanningActions: ClubPlanningAction;
  day: ClubPlanningDayFull;
  widthMode: CalendarSessionWidthMode;
  className?: string;
}) => {
  const {
    planningConfig,
    setPlanningConfig,
    diveSessionResumes,
    clubEvents,
    dailyConfigs,
    loadableContent,
    dialogsOld,
  } = localState;

  const {
    sessionCardMenuActions,
    clickActions,
    dialogsState, // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  } = clubPlanningActions;

  const { onClickEvent } = clickActions;

  const diveCenterResume = useDiveCenterResume();

  const clubReference = diveCenterResume?.clubReference;

  const isPast = useMemo(
    () => !dateService.isTodayUTC(day.date) && dateService.isPastUTC(day.date),
    [day.date],
  );

  const dailySessionsToRender = useMemo(() => {
    const sessions = filterSessions(day.sessions, {
      planningConfig,
      isPast,
    });
    return sessions;
  }, [day.sessions, planningConfig, isPast]);

  const isCurrentMonth = localState.monthPlanning.isCurrentMonth;

  const showDisplayPastSessionsButton =
    isCurrentMonth &&
    isPast &&
    !planningConfig.displayPastSessions &&
    dailySessionsToRender.length > 0;
  return (
    <div
      className={clsx(
        'flex flex-col gap-1 justify-start cursor-pointer',
        isPast && 'opacity-90',
        className,
      )}
      onClick={(e) => {
        e.stopPropagation();

        // zoom to week
        const focusDate = day.date;

        setPlanningConfig(
          {
            ...planningConfig,
            viewPeriod: 'day',
            focusDate: focusDate,
          },
          {
            origin:
              '[ClubPlanningLightPanelViewPeriodMonthWeekDayResume] click day',
          },
        );
      }}
    >
      <>
        <ClubPlanningLightPanelViewPeriodMonthWeekDayResumeTitle
          date={day.date}
          viewPeriod={planningConfig.viewPeriod}
          theme={day.theme}
        />

        {showDisplayPastSessionsButton ? (
          <div
            className="mx-auto rounded-lg p-1 sm:p-2 md:px-4 bg-gray-50 hover:bg-gray-100 text-center text-app-xxs sm:text-sm text-gray-500 leading-3"
            onClick={(e) => {
              e.stopPropagation();
              localState.state.setPlanningConfig(
                {
                  ...planningConfig,
                  displayPastSessions: true,
                },
                {
                  origin: 'ClubPlanningLightPanelViewPeriodMonthWeekDayResume',
                },
              );
            }}
          >
            <div>AFFICHER</div>
            <div>({dailySessionsToRender.length})</div>
          </div>
        ) : (
          <div className={'grid gap-2 md:gap-y-4 xl:gap-y-6'}>
            {dailySessionsToRender.map((session, iDailySession) => {
              return (
                <div key={iDailySession} className={''}>
                  <>
                    <ClubPlanningLightPanelViewPeriodMonthWeekDaySession
                      localState={localState}
                      clubPlanningActions={clubPlanningActions}
                      session={session}
                      sessionIndex={{
                        index: iDailySession + 1,
                        total: day.sessions.length,
                      }}
                    />
                  </>
                </div>
              );
            })}
          </div>
        )}
      </>
    </div>
  );
};

function filterEmptySessions(
  sessions: ClubPlanningLightSessionDef[],
  {
    planningConfig,
    isPast,
  }: {
    planningConfig: PlanningViewConfig;
    isPast: boolean;
  },
): ClubPlanningLightSessionDef[] {
  const displayEmptySessions =
    (isPast && planningConfig.displayEmptySessionsPast) ||
    (!isPast && planningConfig.displayEmptySessionsFuture);
  if (displayEmptySessions) {
    return sessions;
  }
  return sessions.filter(
    (session) =>
      session.status !== 'cancelled' &&
      session.participants.filter((x) => x.bookingState?.value !== 'cancelled')
        .length > 0,
  );
}

function filterSessions(
  sessions: ClubPlanningLightSessionDef[],
  {
    planningConfig,
    isPast,
  }: {
    planningConfig: PlanningViewConfig;
    isPast: boolean;
  },
): ClubPlanningLightSessionDef[] {
  // sessions = filterPastSessions(sessions, {
  //   planningConfig,
  //   isPast,
  // });
  return filterEmptySessions(sessions, {
    planningConfig,
    isPast,
  });
}
