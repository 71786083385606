/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { NEVER } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { useGlobalClasses } from 'src/AppTheme';
import { useLoadable, useRedirect } from 'src/business/_core/data/hooks';
import { responsiveAttributesValues } from 'src/business/_core/modules/layout';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import {
  appStylesHelper,
  useCacheableClasses,
} from 'src/business/_core/modules/root/theme';
import { uiStore } from 'src/business/_core/store';
import { appWebLogger } from 'src/lib/browser';
import { AppForm } from 'src/lib/form/components/AppForm';
import {
  RxjsForm,
  rxjsFormActionManager,
} from 'src/lib/form/components/rxjs-form';
import { ApiClientHttpError } from 'src/_common-browser';
import { authenticationClient, authenticationStore } from '../../../services';
import { UserLoginForm } from './UserLoginForm';
import { UserLoginFormModel } from './UserLoginFormModel.type';
import { userLoginRepository } from './userLoginRepository.service';

export const UserLoginArea = ({ autoFocus }: { autoFocus?: boolean }) => {
  const redirectTo = useRedirect();

  const globalClasses = useGlobalClasses();

  const classes = useCacheableClasses({
    cacheKey: 'UserLoginArea',
    buildStyles,
  });

  const { _loaded, userLoginForm, userLoginComponents } = useLoadable({
    debugName: 'UserLoginArea',
    initialValueFromLoadSnapshot: true,
    load: () => userLoginRepository.loadInitialData(),
  });

  const [authenticationInProgress, setAuthenticationInProgress] =
    useState(false);

  return _loaded ? (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" className={classes.formTitle}>
          Déjà un compte? Se connecter
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AppForm bordered={true} hasChanges={userLoginForm.hasChanges}>
          <form
            noValidate
            autoComplete="on"
            onSubmit={(event) => {
              event.preventDefault();
              submitCreateAccountForm(userLoginForm);
              return false;
            }}
          >
            <UserLoginForm
              components={userLoginComponents}
              autoFocus={autoFocus}
            />
            <Box className={globalClasses.buttonsBar} textAlign="center" mt={2}>
              <Button
                startIcon={<AppIconsMaterial.login />}
                type="submit"
                variant="contained"
                color="primary"
              >
                Se connecter
              </Button>
            </Box>
          </form>
        </AppForm>
        <div className="flex items-center justify-end">
          <div className="mt-4 mb-2">
            <button
              type="button"
              className="py-2 px-4 text-sm cursor-pointer font-medium text-app-blue hover:bg-gray-100 hover:text-app-blue3"
              onClick={() => {
                redirectTo(appRouteBuilder.getResetPasswordQueryUrl());
              }}
            >
              Mot de passe oublié?
            </button>
          </div>
        </div>
      </Grid>
    </Grid>
  ) : null;

  function submitCreateAccountForm(form: RxjsForm<UserLoginFormModel>) {
    if (form.valid) {
      if (authenticationInProgress) {
        return;
      }
      setAuthenticationInProgress(true);

      rxjsFormActionManager
        .saveChanges({
          form,
          isCreationMode: true,
          attributesToReplaceFully: [],
          createMethod: ({ form }) => {
            return authenticationClient.authenticateByLoginPassword({
              login: form.value.userAuthEmailLogin,
              password: form.value.userAuthPassword,
            });
          },
          updateMethod: ({ form, patchOperations }) => NEVER,
        })
        .pipe(
          switchMap(({ success }) => {
            uiStore.snackbarMessage.set({
              type: 'success',
              content: 'Connexion réussie!',
            });
            return authenticationStore.auth.get().pipe(
              first(),
              map((appAuth) =>
                appRouteBuilder.getDefaultRouteFromAuth({ appAuth }),
              ),
              tap((defaultRoute) => {
                redirectTo(defaultRoute);
              }),
              catchError(() => {
                setAuthenticationInProgress(false);
                return NEVER;
              }),
            );
          }),
          catchError((err: ApiClientHttpError) => {
            if (err.status === 401) {
              // conflict
              appLogger.warn(
                '401 UserLoginArea error while trying to authenticate',
                err,
              );
              uiStore.snackbarMessage.set({
                type: 'error',
                content:
                  'Identifiant ou mot de passe invalide. Veuillez ré-essayer',
              });
            } else {
              appWebLogger.captureMessage(
                'Error while trying to authenticate',
                {
                  logContext: 'UserLoginArea',
                  clubReference: undefined,
                  extra: {
                    userAuthEmailLogin: form.value.userAuthEmailLogin,
                    err,
                  },
                },
              );
              uiStore.snackbarMessage.set({
                type: 'error',
                content:
                  'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
              });
            }
            setAuthenticationInProgress(false);
            return NEVER;
          }),
        )
        .subscribe();
    } else {
      form.actions.touchComponents();
    }
  }
};

function buildStyles(theme: Theme) {
  return makeStyles({
    formTitle: {
      ...responsiveAttributesValues(theme, {
        fontSize: appStylesHelper.getFontSizeResponsive('lg'),
      }),
    },
  });
}
