/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';
import { DECOP_SessionItemComments } from './DECOP_SessionItemComments';
import { DECOP_SessionItemDives } from './DECOP_SessionItemDives';
import { DECOP_SessionItemEquipement } from './DECOP_SessionItemEquipement';
import { DECOP_SessionItemParticipantsCount } from './DECOP_SessionItemParticipantsCount';
import { DECOP_SessionItemStaffGroups } from './DECOP_SessionItemStaffGroups';
import { DECOP_SessionItemTitle } from './DECOP_SessionItemTitle';

export const DECOP_SessionItem = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  sessionItemIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  sessionItemIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}.items.${sessionItemIndex}` as const;

  const [sessionItemId] = useWatch({
    control,
    name: [`${baseFormName}.id`],
  });

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  return (
    <div className="border-l-2 border-gray-200 pl-4">
      {sessionItemId === 'title' ? (
        <DECOP_SessionItemTitle
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
          contentItemIndex={contentItemIndex}
          sessionItemIndex={sessionItemIndex}
        />
      ) : sessionItemId === 'participants-count' ? (
        <DECOP_SessionItemParticipantsCount
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
          contentItemIndex={contentItemIndex}
          sessionItemIndex={sessionItemIndex}
        />
      ) : sessionItemId === 'comments' ? (
        <DECOP_SessionItemComments
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
          contentItemIndex={contentItemIndex}
          sessionItemIndex={sessionItemIndex}
        />
      ) : sessionItemId === 'equipement' ? (
        <DECOP_SessionItemEquipement
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
          contentItemIndex={contentItemIndex}
          sessionItemIndex={sessionItemIndex}
        />
      ) : sessionItemId === 'staff-groups' ? (
        <DECOP_SessionItemStaffGroups
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
          contentItemIndex={contentItemIndex}
          sessionItemIndex={sessionItemIndex}
        />
      ) : sessionItemId === 'dives' ? (
        <DECOP_SessionItemDives
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
          contentItemIndex={contentItemIndex}
          sessionItemIndex={sessionItemIndex}
        />
      ) : (
        <div className="my-2">{` ID: ${sessionItemId}`}</div>
      )}
    </div>
  );
};
