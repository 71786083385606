/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  ClubSettingsServicesDialogInitialState,
  ClubSettingsServicesFormModel,
} from '../../../_model';
import { ClubSettingsServicesPageTabFirstDiveEditMode } from '../../../_services';
import { ClubSettingsServicesFirstDiveFormNames } from './ClubSettingsServicesFirstDiveFormNames';

export const ClubSettingsServicesFirstDiveForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsServicesFormModel>;
  initialState: ClubSettingsServicesDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;
  const [tabFirstDiveEditMode, setTabFirstDiveEditMode] =
    useState<ClubSettingsServicesPageTabFirstDiveEditMode>(
      initialState.tabFirstDiveEditMode ?? 'custom-names',
    );

  return (
    <div className="grid gap-4 md:gap-6">
      {tabFirstDiveEditMode === 'custom-names' && (
        <ClubSettingsServicesFirstDiveFormNames form={form} />
      )}
    </div>
  );
};
