
import { AccountProfile, AuthUser, Club } from '@mabadive/app-common-model';
import { loadableAttributeCollectionStoreFactory, loadableAttributeStoreFactory } from '@mabadive/app-common-services';
import { getBaseStore } from './baseStore';


export const adminStore = {
  clubs: loadableAttributeCollectionStoreFactory.create<Club>(getBaseStore(), 'adm_clubs'),
  users: loadableAttributeCollectionStoreFactory.create<AuthUser>(getBaseStore(), 'adm_users'),
  accountProfiles: loadableAttributeCollectionStoreFactory.create<AccountProfile>(getBaseStore(), 'adm_accountProfiles'),
  initialDataLoaded: loadableAttributeStoreFactory.create<boolean>(getBaseStore(), 'adm_initialDataLoaded'),
};
