/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_CURRENCIES } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  AppInputBooleanSwitchRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyOptions,
  useAppMonthIndexOptions,
} from 'src/pages/_components/options';
import { ClubSettingsGeneralFormModel } from '../../../_model';

export const ClubSettingsGeneralPurchaseEditFormBilling = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const currencyOptions = useAppCurrencyOptions(APP_CURRENCIES);
  const appMonthIndexOptions = useAppMonthIndexOptions();

  const [billingEnabled] = useWatch({
    control,
    name: ['clubSettings.general.billing.enabled'],
  });

  return (
    <ClubSettingsSection title="Facturation">
      {isSuperAdmin && (
        <div className="flex gap-4">
          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Activer la facturation"
            control={control}
            name={'clubSettings.general.billing.enabled'}
            required={false}
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
        </div>
      )}

      {billingEnabled && (
        <>
          <div className="flex gap-4">
            <AppFormControlRHF_Deprecated
              className="w-full"
              label="Devise de facturation"
              control={control}
              name={'clubSettings.general.currencyIsoCode'}
              required={true}
              renderComponent={(props) => (
                <AppSingleAutocomplete2RHF
                  {...props}
                  options={currencyOptions}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              className="w-full"
              label="Début de l'année fiscale"
              control={control}
              name={'clubSettings.general.fiscalStartMonth'}
              required={false}
              renderComponent={(props) => (
                <AppSingleAutocomplete2RHF
                  {...props}
                  options={appMonthIndexOptions}
                />
              )}
            />
          </div>
        </>
      )}
    </ClubSettingsSection>
  );
};
