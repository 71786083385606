/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSessionResumeParticipant } from '@mabadive/app-common-model';
import {
  diveSessionServiceFormatter,
  participantAptitudeBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ParticipantCommentCard } from 'src/business/club/modules/_common/form';
import {
  ParticipantDiveCard,
  ParticipantDiverNameLabel,
  ParticipantGaz,
  ParticipantRebreather,
  ParticipantTargetDeep,
  ParticipantUnderwaterScooter,
} from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant';
import { useParticipantExtraBadgeLabel } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/session-common/CalendarParticipantCell/useParticipantExtraBadgeLabel.hook';
import { useDefaultSettingsUiPlanning } from 'src/business/club/modules/club-planning-light/hooks';

import {
  ColoredTagCardBar,
  participantTagsBuilder,
} from 'src/business/club/modules/club-planning/_components';
import { AppFormControlRHF_Deprecated, AppInputBooleanRHF } from 'src/lib/form';
import {
  useMultipleDiveSessionNumbers,
  useMultipleDiveSessionNumbersOptions,
} from 'src/pages/_components/options';
import { BookingGroupSizeLabel } from '../../BookingLabelsComponents';
import { DiveSessionEditorMassiveDialogLocalState } from '../useDiveSessionEditorMassiveDialogLocalState.hook';

export const DiveSessionEditorMassiveDiveGroupParticipantInfo: React.FC<{
  participant: DiveSessionResumeParticipant;
  localState: DiveSessionEditorMassiveDialogLocalState;
  enableSelection: boolean;
}> = ({ localState, participant, enableSelection }) => {
  const { form, data, initialState } = localState;

  const {
    staffMembersFull,
    sameStaffSession1,
    sameParticipantsSession1,
    isMultiSessionConfigForStaff,
    isMultiSessionConfigForParticipants,
    diveCenterResume,
    participantFirstNameBefore,
  } = data;

  const { clubReference } = diveCenterResume;

  const { control, setValue } = form;

  const diver = participant.diver;

  const clubResume = useClubResume();
  const aptitude = useMemo(
    () =>
      participantAptitudeBuilder.formatAptitude(
        {
          diver: participant.diver,
          participant,
        },
        {
          clubReference,
        },
      ),
    [clubReference, participant],
  );

  const { originalSessionFull } = initialState;

  const diveSession = originalSessionFull;

  const tags = useMemo(
    () =>
      !participant
        ? []
        : participantTagsBuilder.getParticipantTags({
            club: clubResume,
            participant,
            diver,
            diveSession,
            includeComment: false,
            includeBookingState: false,
            includeSpecialDiveType: true,
            includePayment: false,
          }),
    [clubResume, diver, diveSession, participant],
  );

  const multipleDiveSessionNumbersOptions =
    useMultipleDiveSessionNumbersOptions({
      diveSession,
    });

  const diveSessionNumbers = useMultipleDiveSessionNumbers({
    diveSession,
  });

  const settingsUIPlanning = useDefaultSettingsUiPlanning({});

  const extraBadgeLabel = useParticipantExtraBadgeLabel({
    participant,
    settingsUIPlanning,
    multipleSessionsGroup: true,
    multipleSessionNumber: undefined,
  });
  const participantFormIndex =
    data.participantsFormIndexesById[participant._id];

  const participantCheckFormName =
    `participants.${participantFormIndex}.selected` as const;

  const [participantChecked] = useWatch({
    control,
    name: [participantCheckFormName],
  });
  const clubSettings = useClubSettings();
  const clubPublicSettings = useClubSettings()?.publicSettings;

  return (
    <div
      className={clsx(
        'w-full text-gray-600',
        enableSelection && 'cursor-pointer hover:bg-gray-50',
      )}
      onClick={(e) => {
        e.stopPropagation();
        enableSelection &&
          setValue(participantCheckFormName, !participantChecked);
      }}
    >
      <div className={'flex items-center gap-2'}>
        {enableSelection && (
          <AppFormControlRHF_Deprecated
            className="w-12"
            control={control}
            name={participantCheckFormName}
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                scale={'lg'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            )}
          />
        )}
        <div>
          <ParticipantDiveCard
            className="text-center w-12 lg:w-16"
            diveSession={diveSession}
            participant={participant}
          >
            <div className={extraBadgeLabel && 'leading-3'}>
              {diveSessionServiceFormatter.formatServiceFromParticipant(
                { diver, participant },
                {
                  format: 'short-ref',
                  addSuffix: false,
                  clubReference,
                  clubPublicSettings,
                  diveModesConf: clubSettings?.ui?.diveMode,
                },
              )}
            </div>
            {extraBadgeLabel && (
              <div className="leading-3">{extraBadgeLabel}</div>
            )}
          </ParticipantDiveCard>
        </div>
        <div className="w-10">
          {aptitude?.length !== 0 && (
            <div className="text-center px-0.5 py-0.5 rounded border border-gray-600 text-xs font-bold">
              {aptitude}
            </div>
          )}
          {participant.targetDeep && (
            <ParticipantTargetDeep
              className="text-xs"
              targetDeep={participant?.targetDeep}
            />
          )}
        </div>
        <div
          className={`w-14 text-xs text-center font-bold h-full flex flex-col justify-between
          ${
            participant.gaz?.gazType && participant.gaz?.gazType !== 'air'
              ? 'text-red-400'
              : 'text-gray-400'
          }
        `}
        >
          <div className="px-0.5 py-0.5 ">
            <ParticipantRebreather
              className="text-xs whitespace-nowrap"
              rebreather={participant.equipment?.rebreather}
            />
            <ParticipantUnderwaterScooter
              className="text-xs whitespace-nowrap"
              underwaterScooter={participant.equipment?.underwaterScooter}
            />
            <ParticipantGaz
              className="text-xs whitespace-nowrap"
              gaz={participant.gaz}
              showDetails={true}
              showGazQuantity={true}
              showAir={true}
            />
          </div>
          {/* {participant.gaz?.gazQuantity && (
            <div className="mt-0.5 text-center text-xs font-bold text-gray-600">{`${participant.gaz?.gazQuantity}L`}</div>
          )} */}
        </div>
        <div className="w-full text-xs sm:text-base">
          <div className="w-full flex gap-2 justify-between">
            <div className="flex-grow flex gap-2">
              <ParticipantDiverNameLabel
                participantFirstNameBefore={participantFirstNameBefore}
                participant={participant}
                diveSession={diveSession}
                diver={participant.diver}
                displayAgeIfMinor={true}
              />
              {tags.length !== 0 && (
                <ColoredTagCardBar
                  className="extra-infos-tags"
                  key="coloredTagAvatarCardBar"
                  tags={tags}
                  avatarSize="small"
                  avatarOnly={true}
                />
              )}
            </div>
            <BookingGroupSizeLabel
              className="place-self-start hidden sm:inline-block px-1 py-px text-app-xxs xl:text-xs"
              booking={participant.booking}
            />
            {isMultiSessionConfigForParticipants && (
              <div className="w-12 lg:w-16 text-xs font-bold text-gray-400">
                N°
                {(
                  participant.details?.multiSessionsPresenceNumbers ??
                  diveSessionNumbers
                ).join(' + ')}
              </div>
            )}
            {/* {isMultiSessionConfigForParticipants && (
              <AppMultiCheckboxesTailwind
                labelPosition="right"
                required={true}
                options={multipleDiveSessionNumbersOptions}
                defaultValue={
                  participant.details?.multiSessionsPresenceNumbers ??
                  diveSessionNumbers
                }
                onChange={(
                  multiSessionsPresenceNumbers: MultipleDiveSessionNumber[],
                ) => {
                  // onUpdateParticipantMultiSessionsPresenceNumbers({
                  //   multiSessionsPresenceNumbers,
                  // });
                }}
              />
            )} */}
          </div>
          <ParticipantCommentCard
            className="hidden sm:block"
            participant={participant}
            diver={diver}
          />
        </div>
      </div>
      <ParticipantCommentCard
        className="sm:hidden"
        participant={participant}
        diver={diver}
      />
    </div>
  );
};
