/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettingsPlanningPeriodConfigAssistantDiveTourDef,
  DiveMode,
  DiveSessionTheme,
  DiveSessionType,
} from '@mabadive/app-common-model';
import React, { useCallback, useEffect } from 'react';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppInputBooleanRHF,
  AppInputRHF,
  AppMultiCheckboxesRHF,
} from 'src/lib/form';
import { AppEmojiPickerPopoverRHF } from 'src/lib/form/components/AppEmojiPicker';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { ClubBoatLabel } from 'src/pages/_components/company';
import {
  useDiveModesOptions,
  useDiveSessionThemesOptions,
  useDiveSessionTypesOptions,
  useWeekIsoDaysOptions,
} from 'src/pages/_components/options';
import { dateServiceCore } from 'src/stories/services';
import { ClubSettingsPlanningAssistantFormModel } from '../model';
import { ClubSettingsPlanningAssistantDailyTourForm } from './ClubSettingsPlanningAssistantDailyTourForm';

export const ClubSettingsPlanningAssistantSimpleDiveTourForm = ({
  formDiveTourDef,
  diveTourDefIndex,
  form,
  className = '',
  onClickDelete,
}: {
  diveTourDefIndex: number;
  formDiveTourDef: ClubSettingsPlanningPeriodConfigAssistantDiveTourDef;
  form: UseFormReturn<ClubSettingsPlanningAssistantFormModel, any>;
  className?: string;
  onClickDelete: () => void;
}) => {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const diveTourControlBaseName =
    `assistant.diveToursDef.${diveTourDefIndex}` as const;

  // const multiSessionsEnabled: boolean = useWatch({
  //   control,
  //   name: `${diveTourControlBaseName}.multiSessionsEnabled` as const,
  // });

  const diveSessionThemesOptions = useDiveSessionThemesOptions({
    scope: 'session-settings',
  });
  const diveSessionTypesOptions = useDiveSessionTypesOptions({
    scope: 'session-settings',
  });

  const diveModesOptions: ValueLabel<DiveMode>[] = useDiveModesOptions({
    format: 'short-ref-label',
  });
  // const diveTour: ClubSettingsPlanningViewDayDiveTourModel = useMemo(() => {
  //   const diveTour: ClubSettingsPlanningViewDayDiveTourModel = {
  //     ...formDiveTourDef,
  //     session1: buildSessionModel({
  //       session: formDiveTourDef.session1,
  //       diveCenterResume,
  //       timeDayPeriod,
  //     }),
  //     session2: buildSessionModel({
  //       session: formDiveTourDef.session2,
  //       diveCenterResume,
  //       timeDayPeriod,
  //     }),
  //   };

  //   return diveTour;
  // }, [diveCenterResume, formDiveTourDef, timeDayPeriod]);

  const onChangeDiveSessionType = useCallback((value: DiveSessionType) => {
    // NOTE: fonction similaire dans DiveSessionEditFormRHF
    // switch (value) {
    //   case 'first-dive': {
    //     setValue(
    //       `${diveTourControlBaseName}.defaultDiveMode` as const,
    //       'first-dive',
    //     );
    //     setValue(
    //       `${diveTourControlBaseName}.session1.emojiId` as const,
    //       'whale',
    //     );
    //     break;
    //   }
    //   case 'snorkeling': {
    //     setValue(
    //       `${diveTourControlBaseName}.defaultDiveMode` as const,
    //       'first-dive',
    //     );
    //     setValue(
    //       `${diveTourControlBaseName}.session1.emojiId` as const,
    //       'diving_mask',
    //     );
    //     break;
    //   }
    //   case 'theoretical-training': {
    //     setValue(
    //       `${diveTourControlBaseName}.defaultDiveMode` as const,
    //       'theoretical-training',
    //     );
    //     setValue(
    //       `${diveTourControlBaseName}.session1.emojiId` as const,
    //       'open_book',
    //     );
    //     break;
    //   }
    // }
  }, []);

  const onChangeDiveSessionTheme = useCallback((value: DiveSessionTheme) => {
    // NOTE: fonction similaire dans DiveSessionEditFormRHF
    // switch (value) {
    //   case 'night': {
    //     setValue(
    //       `${diveTourControlBaseName}.session1.emojiId` as const,
    //       'crescent_moon',
    //     );
    //     break;
    //   }
    //   case 'shark': {
    //     setValue(
    //       `${diveTourControlBaseName}.defaultDiveMode` as const,
    //       'snorkeling',
    //     );
    //     setValue(
    //       `${diveTourControlBaseName}.session1.emojiId` as const,
    //       'shark',
    //     );
    //     break;
    //   }
    //   case 'whale': {
    //     setValue(
    //       `${diveTourControlBaseName}.defaultDiveMode` as const,
    //       'snorkeling',
    //     );
    //     setValue(
    //       `${diveTourControlBaseName}.session1.emojiId` as const,
    //       'whale',
    //     );
    //     break;
    //   }
    //   case 'turtle': {
    //     setValue(
    //       `${diveTourControlBaseName}.defaultDiveMode` as const,
    //       'snorkeling',
    //     );
    //     setValue(
    //       `${diveTourControlBaseName}.session1.emojiId` as const,
    //       'turtle',
    //     );
    //     break;
    //   }
    //   case 'dolphin': {
    //     setValue(
    //       `${diveTourControlBaseName}.defaultDiveMode` as const,
    //       'snorkeling',
    //     );
    //     setValue(
    //       `${diveTourControlBaseName}.session1.emojiId` as const,
    //       'dolphin',
    //     );
    //     break;
    //   }
    // }
  }, []);

  const dailyToursFieldArray = useFieldArray({
    control,
    name: `${diveTourControlBaseName}.dates.dailyTours` as const,
  });

  const dailyTours = useWatch({
    control,
    name: `${diveTourControlBaseName}.dates.dailyTours` as const,
  });

  const weekIsoDaysOptions = useWeekIsoDaysOptions();

  useEffect(() => {
    if (dailyTours.length) {
      const lastDayTime = dailyTours[dailyTours.length - 1].session1?.dayTime;
      if (dateServiceCore.isValidHoursMinutes(lastDayTime)) {
        // last time is valid: append one
        dailyToursFieldArray.append({
          session1: {
            dayTime: null,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyTours]);

  return (
    <div className={`${className ?? ''}`}>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <div>
            <ClubBoatLabel clubBoatId={formDiveTourDef.attributes.boatId} />
          </div>
          <div
            className={
              'my-2 cursor-pointer group text-red-400 hover:text-red-600 flex flex-col items-center'
            }
            onClick={(e) => {
              e.stopPropagation();
              onClickDelete();
            }}
          >
            <AppHeroIcons.actionDelete
              className={
                'rounded-full border border-red-600 bg-white group-hover:bg-red-400 hover:text-white w-8 h-8 p-2'
              }
            />
            {/* <span className="uppercase font-bold text-xs">Supprimer</span> */}
          </div>
        </div>
        <h3 className="text-sm font-bold text-app-primary uppercase">
          Jours et horaires
        </h3>
        <div
          className={
            'flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-4 gap-x-8'
          }
        >
          {dailyToursFieldArray.fields.map((field, dailyToursFieldIndex) => {
            return (
              <ClubSettingsPlanningAssistantDailyTourForm
                className="w-full"
                diveTourDefIndex={diveTourDefIndex}
                dailyToursFieldIndex={dailyToursFieldIndex}
                key={dailyToursFieldIndex}
                formDailyTour={field as any}
                form={form}
                onClickDelete={() =>
                  dailyToursFieldArray.remove(dailyToursFieldIndex)
                }
              />
            );
          })}
        </div>
        <AppFormControlRHF_Deprecated
          className="w-full"
          // label={'Jours de la semaine'}
          control={control}
          name={`${diveTourControlBaseName}.dates.isoWeekDays` as const}
          renderComponent={(props) => (
            <AppMultiCheckboxesRHF
              className="flex flex-wrap w-full text-gray-600"
              {...props}
              options={weekIsoDaysOptions}
            />
          )}
        />
        <h3 className="text-sm font-bold text-app-primary uppercase">
          Détails complémentaires
        </h3>
        <div className="flex flex-col md:grid md:grid-cols-3 lg:grid-cols-6 gap-2">
          {diveSessionTypesOptions.length > 0 && (
            <AppFormControlRHF_Deprecated
              className="w-full"
              label={'Type de sortie'}
              control={control}
              name={`${diveTourControlBaseName}.attributes.type` as const}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  theme="material-ui"
                  {...props}
                  options={diveSessionTypesOptions}
                  onChange={(value) => onChangeDiveSessionType(value)}
                />
              )}
            />
          )}
          {diveSessionThemesOptions.length > 0 && (
            <AppFormControlRHF_Deprecated
              className="w-full"
              label={'Thème de la sortie'}
              control={control}
              name={`${diveTourControlBaseName}.attributes.theme` as const}
              renderComponent={(props) => (
                <AppSingleSelect2HeadlessUIRHF
                  theme="material-ui"
                  {...props}
                  options={diveSessionThemesOptions}
                  onChange={(value) => onChangeDiveSessionTheme(value)}
                />
              )}
            />
          )}
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={'Prestation forcée'}
            helpDescription="Si vous remplissez ce champ, tous les participants seront inscrit par défaut avec cette prestation."
            control={control}
            name={
              `${diveTourControlBaseName}.attributes.details.defaultDiveMode` as const
            }
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                theme="material-ui"
                {...props}
                options={diveModesOptions}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label={'Nom'}
            control={control}
            name={`${diveTourControlBaseName}.attributes.name` as const}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />

          <AppFormControlRHF_Deprecated
            className="w-full "
            label={'Spéciale?'}
            control={control}
            name={`${diveTourControlBaseName}.attributes.special` as const}
            renderComponent={(props) => (
              <AppInputBooleanRHF {...props} type="checkbox" />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={'Icône Emoji'}
            control={control}
            name={`${diveTourControlBaseName}.attributes.emojiId` as const}
            renderComponent={(props) => <AppEmojiPickerPopoverRHF {...props} />}
          />
        </div>
      </div>
    </div>
  );
};
