/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButtonMultiActions } from 'src/business/_core/modules/layout';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';
import { useOffersPricesActionsBar_AdditionalProducts_MenuItems } from './useOffersPricesActionsBar_AdditionalProducts_MenuItems.hook';

export const ClubSettingsServicesViewPanelOffersPricesActionsBar_AdditionalProducts =
  ({
    localState,
  }: {
    localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
  }) => {
    const { data, state, actions } = localState;
    const publicSettings = data.publicSettings;
    const clubReference = data.clubReference;
    const clubSettings = data.clubSettings;

    const extraMenuItems =
      useOffersPricesActionsBar_AdditionalProducts_MenuItems({
        localState,
      });

    return (
      <>
        <AppButtonMultiActions items={extraMenuItems} />
      </>
    );
  };
