/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppCompanyMessageTemplateEditDialogInitialState,
  AppCompanyMessageTemplateEditFormModel,
} from './model';
export function useAppCompanyMessageTemplateEditDialogLocalState({
  initialState,
}: {
  initialState: AppCompanyMessageTemplateEditDialogInitialState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const defaultValues: AppCompanyMessageTemplateEditFormModel = useMemo(() => {
    if (initialState.mode === 'create') {
      const defaultValues: AppCompanyMessageTemplateEditFormModel = {
        messageTemplate: initialState.defaultValue?.messageTemplate ?? {},
        clubSettings: clubResume.clubSettings,
      };
      return defaultValues;
    } else {
      return initialState.defaultValue;
    }
  }, [clubResume.clubSettings, initialState.defaultValue, initialState.mode]);

  const form = useForm<AppCompanyMessageTemplateEditFormModel>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  return { form, hasFormChanges, initialState };
}

export type AppCompanyMessageTemplateEditDialogLocalState = ReturnType<
  typeof useAppCompanyMessageTemplateEditDialogLocalState
>;
