import {
  DiveCenterExportSettingsDaily,
  DiveSessionResumeFull,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { apiDownloader } from 'src/_common-browser';
import { AppButton, AppMessageLight } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { ClubDialogsState } from 'src/pages/_dialogs';
import { DailyExportDownloadModalContentSelectableSession } from './DailyExportDownloadModalContentSelectableSession';
import { DailyExportDownloadModalFormModel } from './model';

export function DailyExportDownloadModalContent({
  dialogsState,
  dailyExportSettings,
  dateUTC,
  sessions,
  close,
}: {
  dialogsState: ClubDialogsState;
  dailyExportSettings: DiveCenterExportSettingsDaily;
  dateUTC: Date;
  sessions: DiveSessionResumeFull[];
  close: () => any;
}) {
  const az = useAppSecurityUserClubAuthorizations();
  const form = useForm<DailyExportDownloadModalFormModel>({
    defaultValues: {
      sessions: sessions.map((s) => ({
        _id: s._id,
        selected: true,
      })),
    },
  });

  const { control } = form;

  const [sessionsFormValue] = useWatch({
    control,
    name: ['sessions'],
  });

  const selectedSessions = useMemo(() => {
    const selectedSessionsIndexes = sessionsFormValue
      .filter((x) => x.selected)
      .map((x) => x._id);
    return sessions.filter((x) => selectedSessionsIndexes.includes(x._id));
  }, [sessions, sessionsFormValue]);

  return (
    <div className="grid gap-4">
      <h2 className="text-xl font-bold text-gray-700">
        {dailyExportSettings.label}
      </h2>

      {sessions.length > 0 && (
        <>
          <div className="grid gap-2 divide-y divide-gray-300">
            {sessions.map((session, sessionIndex) => (
              <DailyExportDownloadModalContentSelectableSession
                key={session._id}
                className="py-2"
                exportSettingsId={dailyExportSettings._id}
                form={form}
                dateUTC={dateUTC}
                session={session}
                sessionIndex={sessionIndex}
                sessionsTotalCount={sessions.length}
              />
            ))}
          </div>
          {sessions.length > 1 && (
            <AppMessageLight type="info">
              Téléchargez les sessions individuellement ou regroupées dans le
              même fichier.
            </AppMessageLight>
          )}
        </>
      )}
      {az.edit.settings.exportDaily && (
        <AppButton
          type="button"
          color="gray-outline-light"
          className={''}
          fullWidth={true}
          icon={AppHeroIcons.actionSettings}
          onClick={(e) => {
            e.stopPropagation();
            close(); // on ferme la popup, sinon elle reste au dessus du dialog
            dialogsState.dailyExportConfigDialog.openDialog({
              exportRef: 'daily-export-activity',
            });
          }}
        >
          Configurer le format de l'export
        </AppButton>
      )}
      <div className={''}>
        <div className="mt-8 mb-2 flex-grow flex gap-2 justify-around">
          <AppButton
            className={'text-base font-bold uppercase'}
            style={{ minWidth: '6rem' }}
            color={'gray-outline-light'}
            icon={() => <AppIconsAction.close className="w-6 h-6 mr-2" />}
            tabIndex={500}
            onClick={(e) => {
              close();
              // e.preventDefault();
              // e.stopPropagation();
              // showProgressAndClose(false);
            }}
          >
            Fermer
          </AppButton>

          <AppButton
            className="text-base font-bold uppercase"
            style={{ minWidth: '6rem' }}
            color={'primary-bg'}
            icon={() => <AppIconsAction.confirm className="w-6 h-6 mr-2" />}
            disabled={selectedSessions.length === 0}
            onClick={(e) => {
              close();
              const beginDateUTC = dateUTC;
              const endDateUTC = dateUTC;
              apiDownloader.downloadDailyResumeSheetCustom({
                exportSettingsId: dailyExportSettings._id,
                beginDateUTC,
                endDateUTC,
                sessionsIds: selectedSessions.map((s) => s._id),
              });
            }}
          >
            Télécharger{' '}
            {selectedSessions.length > 1
              ? `(${selectedSessions.length})`
              : null}
          </AppButton>
        </div>
      </div>
    </div>
  );
}
