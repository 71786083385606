/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubDiverSelectorDialogCreateDiverFormModel } from '@mabadive/app-common-model';
import { arrayBuilder, nameFormatter } from '@mabadive/app-common-services';
import React, { FC, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { fakerClient } from 'src/business/_core/data/app-operation';
import { useAutoFill } from 'src/business/_core/data/hooks';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import {
  AppButton,
  AppMessage,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { BookingMemberDiverEditFormRHFFieldsToDisplay } from '../../forms';
import { mainCertificationOptionBuilder } from '../../forms/BookingMemberDiverEditForm/mainCertificationOptionBuilder.service';

export const MAIN_CERTIFICATION_OPTIONS =
  mainCertificationOptionBuilder.buildOptions();

export const ClubDiverSelectorDialogCreateManyDiversMemberForm: FC<{
  autoFillLastName: string;
  form: UseFormReturn<ClubDiverSelectorDialogCreateDiverFormModel>;
  onSubmit: () => void;
  className?: string;
}> = ({ autoFillLastName, form, onSubmit, className = '' }) => {
  const { register, handleSubmit, watch, formState, control, reset, setValue } =
    form;

  const [firstName, lastName] = useWatch({
    control,
    name: ['diver.firstName', 'diver.lastName'],
  });
  const isFormFirstNameOrLastNameSet =
    !!firstName?.trim().length || !!lastName?.trim().length;

  const formValue = useWatch({
    control,
  });

  const massiveCreationQuantity = useWatch({
    control,
    name: 'massiveCreation.quantity',
  });
  const massiveCreationFirstNumber = useWatch({
    control,
    name: 'massiveCreation.firstNumber',
  });

  const generatedNames: string[] = useMemo(() => {
    if (
      firstName &&
      massiveCreationQuantity >= 1 &&
      massiveCreationFirstNumber >= 1
    ) {
      return arrayBuilder
        .bySize(Math.min(massiveCreationQuantity, 3))
        .map((x, i) => {
          return nameFormatter.formatFullName({
            firstName: `${firstName} ${i + massiveCreationFirstNumber}`,
            lastName,
          });
        });
    }
    return [];
  }, [
    firstName,
    lastName,
    massiveCreationFirstNumber,
    massiveCreationQuantity,
  ]);

  const hasFormChanges = useMemo(
    () =>
      form &&
      formState.isDirty &&
      formValue?.diver &&
      Object.values(formValue.diver).filter((x) => !!x).length !== 0,
    [form, formState.isDirty, formValue.diver],
  );
  const clubResume = useClubResume();

  const mediaSize = useMediaSizeTailwind();

  const fieldsToDisplay: BookingMemberDiverEditFormRHFFieldsToDisplay =
    isFormFirstNameOrLastNameSet ? 'all' : 'name-only';

  const autoFill = useAutoFill();
  // const [autoFillCount, setAutoFillCount] = useState(1);
  // const [showAutoFillCount, setShowAutoFillCount] = useState(false);

  return (
    <div
      className={`relative border border-gray-300 hover:border-gray-800 focus-within:border-2 focus-within:border-app-blue hover:focus-within:border-app-blue rounded ${className}`}
    >
      {autoFill && (
        <div className="absolute top-0 right-0 p-1">
          <AppButton
            className="z-50"
            color={'primary-outline-light'}
            size="normal"
            icon={AppHeroIcons.autoFill}
            onClick={async () => {
              // setShowAutoFillCount(true);
              const model: ClubDiverSelectorDialogCreateDiverFormModel =
                await fakerClient.generateDiver({
                  lastName: autoFillLastName,
                });
              reset(model, {
                keepDirty: true,
              });
              // onSubmit();
            }}
          >
            {/* {showAutoFillCount && (
              <input
                className="w-24"
                type="number"
                value={autoFillCount}
                onChange={(e) => {
                  const count = parseInt(e.target.value, 10);
                  if (count >= 1) {
                    setAutoFillCount(count);
                  }
                }}
              />
            )} */}
          </AppButton>
        </div>
      )}
      <label
        className="z-1 ml-2 px-1.5 relative bg-white text-xs text-app-blue"
        style={{ top: '-16px', bottom: '-16px' }}
      >
        Création en masse
      </label>
      <form
        className="px-3 mb-5"
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className={`w-full flex flex-col sm:flex-row gap-4 ${className}`}>
          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Niveau des plongeurs"
            control={control}
            name={'diver.divingCertification1.mainCertificationReference'}
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={MAIN_CERTIFICATION_OPTIONS}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'md:col-span-2 w-full'}
            label="Préfixe"
            control={control}
            required={true}
            name={'diver.firstName' as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'sm:col-span-2 w-full'}
            label="Nb de plongeurs (1-50)"
            control={control}
            required={true}
            validation={{
              rules: {
                min: 1,
                max: 50,
              },
            }}
            name={'massiveCreation.quantity' as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'sm:col-span-2 w-full'}
            label="Numéro premier"
            control={control}
            required={true}
            validation={{
              rules: {
                min: 1,
              },
            }}
            name={'massiveCreation.firstNumber' as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
        </div>
        {fieldsToDisplay === 'all' && (
          <AppMessage
            className="my-2"
            title={'INSTRUCTIONS'}
            message={
              <>
                <p>
                  Cette fonctionnalité permet de créer rapidement un groupe de
                  plongeurs (ex: un club ou un groupe d'amis), sans forcément
                  avoir la liste précise des plongeurs.
                </p>
                <p className="my-3 font-bold">Instructions:</p>
                <ul className="ml-3 list-disc">
                  <li>Indiquez un préfixe (comme "P" ou "Plongeur")</li>
                  <li>
                    Le préfixe sera complété par un numéro incrémenté
                    automatiquement pour former le prénom du plongeur (
                    {generatedNames?.length
                      ? generatedNames.join(', ')
                      : 'P1, P2, P3'}
                    ...)
                  </li>
                </ul>
              </>
            }
          >
            <div className="w-full flex flex-col md:grid md:grid-cols-4 lg:grid-cols-8 gap-4"></div>
          </AppMessage>
        )}

        <input type="submit" className="hidden" />
        {formState.isValid && (
          <div className="my-5 flex justify-around">
            <AppButton
              type="button"
              disabled={!formState.isValid}
              size="normal"
              icon={AppHeroIcons.actionAddUser}
              color="primary-outline-light"
              onClick={() => {
                onSubmit();
              }}
            >
              Créer les plongeurs
            </AppButton>
          </div>
        )}
      </form>
    </div>
  );
};
