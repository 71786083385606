/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import {
  AppFormControlV2,
  AppSingleAutocomplete2,
} from '../../../../../../lib/form';
import { ClubPlanningLightDayHeader } from '../../../club-planning-light/components';
import { SetPlanningConfigOptions } from '../../../club-planning-light/hooks';
import { ClubPlanningDayFull } from '../../../club-planning-light/model';
import { PlanningViewConfig } from '../../_model';
import { ClubPlanningStaffWeekStaffPresenceLocalState } from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';

export const ClubPlanningStaffWeekStaffPresenceHeaderDays = ({
  days,
  planningConfig,
  setPlanningConfig,
  localState,
  gridClassName,
  className,
}: {
  days: ClubPlanningDayFull[];
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  localState: ClubPlanningStaffWeekStaffPresenceLocalState;
  gridClassName?: string;
  className?: string;
}) => {
  const { data, actions, criteria } = localState;

  return (
    <div className={className}>
      <div className={gridClassName}>
        <div
          className={clsx(
            'py-1 px-2 lg:col-span-2 text-left text-gray-500 font-medium',
            planningConfig.viewPeriod === 'week' && 'hidden lg:block',
          )}
        >
          <AppFormControlV2
            className="w-full text-sm"
            label={'Filtrer par moniteur'}
            renderComponent={() => (
              <AppSingleAutocomplete2
                optionClassName="text-xs sm:text-sm"
                value={criteria.filterStaffMemberId}
                options={criteria.staffMemberOptions}
                // customRenderOption => TODO utiliser cette méthode pour afficher un rendu personnalié, comme via staffMemberOptionsBuilder.buildStaffMemberOptions
                onChange={(staffMemberId) => {
                  actions.updateStaffMemberFilter({
                    staffMemberId,
                  });
                }}
              />
            )}
          />
        </div>

        {days.map((day, i) => (
          <ClubPlanningLightDayHeader
            key={i}
            className="w-full"
            viewPeriod={planningConfig.viewPeriod}
            date={day.date}
            theme={day.theme}
            onClick={() => {
              if (planningConfig.viewPeriod === 'day') {
                // switch to week
                setPlanningConfig(
                  {
                    ...planningConfig,
                    viewPeriod: 'week',
                    focusDate: day.date,
                    viewWeekOptions: {
                      ...planningConfig.viewWeekOptions,
                      displayMode: 'staff-presence',
                    },
                  },
                  {
                    origin:
                      '[ClubPlanningLightPanelViewPeriodWeek] ClubPlanningLightDayHeader.click',
                  },
                );
              } else {
                // switch to day
                setPlanningConfig(
                  {
                    ...planningConfig,
                    viewPeriod: 'day',
                    focusDate: day.date,
                    viewDayOptions: {
                      ...planningConfig.viewDayOptions,
                      displayMode: 'staff-presence',
                    },
                  },
                  {
                    origin:
                      '[ClubPlanningLightPanelViewPeriodWeek] ClubPlanningLightDayHeader.click',
                  },
                );
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};
