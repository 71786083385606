// https://heroicons.com/ ?

import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';

export const AppIconsStatus = {
  error: ExclamationTriangleIcon,
  danger: ExclamationTriangleIcon,
  warn: ExclamationCircleIcon,
  success: ShieldCheckIcon,
};
