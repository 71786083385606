import { DiveSessionResumeFullBookingSessionParticipantBooking } from '@mabadive/app-common-model';
import { nameFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { BookingGroupTypeLabel } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';

export function BookingGroupSizeLabel({
  booking,
  className,
}: {
  booking?: DiveSessionResumeFullBookingSessionParticipantBooking;
  className?: string;
}) {
  const bookingGroupLabel = useMemo(() => {
    if (booking) {
      if (booking?.membersCount > 1) {
        const groupName = (
          booking?.bookingGroup?.name?.toUpperCase() ??
          nameFormatter.formatFullName(booking?.bookingContact, {
            format: 'lastName-first',
          })
        )
          ?.trim()
          .substring(0, 8);
        return `x${booking?.membersCount} ${groupName}`;
      }
      if (booking?.membersCount === 1) {
        const groupName = booking?.bookingGroup?.name
          ?.toUpperCase()
          ?.trim()
          .substring(0, 8 + 3);
        if (groupName) {
          return groupName;
        }
      }
    }
  }, [booking]);

  return bookingGroupLabel ? (
    <BookingGroupTypeLabel
      className={className}
      type={booking?.bookingGroup?.type}
      label={bookingGroupLabel}
    />
  ) : null;
}
