import React from 'react';

export const ParticipantTargetDeep = ({
  targetDeep,
  mode = 'default',
  className,
}: {
  targetDeep?: number;
  mode?: 'default' | 'badge';
  className?: string;
}) => {
  return targetDeep > 0 ? (
    <div
      className={`font-bold ${
        mode === 'default'
          ? 'text-red-500'
          : 'text-red-200 app-text-shadow-sm shadow-red-600'
      }  ${className}`}
    >
      {targetDeep}m
    </div>
  ) : null;
};
