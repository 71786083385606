import {
  ClubProductPackage,
  ClubProductPackageOffer,
} from '@mabadive/app-common-model';
import {
  GenerateOffersDivePlanFirstDiveOptions,
  GenerateOffersDivePlanOptions,
  GenerateOffersDivePlanScubaDiveOptions,
  GenerateOffersDivePlanSnorkelingOptions,
} from '../model';

export const offersDivePlanGenerator = {
  generateDivePlansOffers,
};

function generateDivePlansOffers({
  clubReference,
  options,
}: {
  clubReference: string;
  options: GenerateOffersDivePlanOptions;
}): ClubProductPackageOffer[] {
  const newProductPackageOffers: ClubProductPackageOffer[] = [];

  if (options.snorkelings.enabled) {
    options.snorkelings.offers.forEach((offer) => {
      newProductPackageOffers.push(generateOfferSnorkeling1(offer));
    });
  }
  if (options.firstDives.enabled) {
    options.firstDives.offers.forEach((offer) => {
      newProductPackageOffers.push(generateOfferFirstDive1(offer));
    });
  }
  if (options.scubaDives.enabled) {
    options.scubaDives.offers.forEach((offer) => {
      newProductPackageOffers.push(generateOfferScubaDive(offer));
    });
  }
  // {
  //   // double-block
  //   const successiveDivesCount: 2 | 3 = 2;
  //   newProductPackageOffers.push(
  //     generateOfferScubaDive({
  //       diveAttributes: {
  //         successiveDivesCount,
  //         equipment: 'not-equipped',
  //         supervision: 'autonomous',
  //       },
  //     }),
  //   );
  //   newProductPackageOffers.push(
  //     generateOfferScubaDive({
  //       diveAttributes: {
  //         successiveDivesCount,
  //         equipment: 'equipped',
  //         supervision: 'autonomous',
  //       },
  //     }),
  //   );
  //   newProductPackageOffers.push(
  //     generateOfferScubaDive({
  //       diveAttributes: {
  //         successiveDivesCount,
  //         equipment: 'not-equipped',
  //         supervision: 'supervised',
  //       },
  //     }),
  //   );
  //   newProductPackageOffers.push(
  //     generateOfferScubaDive({
  //       diveAttributes: {
  //         successiveDivesCount,
  //         equipment: 'equipped',
  //         supervision: 'supervised',
  //       },
  //     }),
  //   );
  // }

  return newProductPackageOffers;

  function generateOfferSnorkeling1(
    options: GenerateOffersDivePlanSnorkelingOptions,
  ) {
    const { price, label, namingConfiguration, productAttributes } = options;

    const { minAge, maxAge } = productAttributes ?? {};
    const reference = `${clubReference};dive;x1;snorkeling;null;${new Date().getTime()}-${Math.floor(
      Math.random() * 100000,
    )}`;
    const productPackage: ClubProductPackage = {
      // name: 'Randonnée palmée',
      // nameXS: 'PMT',
      label,
      type: 'dive',
      comment: null,
      reference,
      diveAttributes: {
        diveMode: 'snorkeling',
        diveType: 'free',
        minDepth: null,
        maxDepth: null,
        equipment: null,
        divesCount: 1,
        maxDistance: null,
        minDistance: null,
        supervision: null,
        divePriceType: 'single',
      },
      productAttributes: {
        maxAge,
        minAge,
        includeDives: true,
      },
      namingConfiguration: namingConfiguration ?? {
        includeEquipment: true,
        includeDefaultName: true,
        includeSupervision: true,
      },
    };
    const offer: ClubProductPackageOffer = {
      reference,
      clubReference,
      productPackage,
      price,
    };
    return offer;
  }

  function generateOfferFirstDive1(
    options: GenerateOffersDivePlanFirstDiveOptions,
  ) {
    const { price, label, namingConfiguration, productAttributes } = options;

    const { minAge, maxAge } = productAttributes ?? {};
    const reference = `${clubReference};dive;x1;first-dive;null;${new Date().getTime()}-${Math.floor(
      Math.random() * 100000,
    )}`;
    const productPackage: ClubProductPackage = {
      // name: 'Discover Scuba Diving',
      // nameXS: 'BPT',
      label,
      type: 'dive',
      comment: null,
      reference,
      diveAttributes: {
        diveMode: 'first-dive',
        diveType: 'scuba',
        maxDepth: 6,
        equipment: 'not-equipped',
        divesCount: 1,
        supervision: 'supervised',
        divePriceType: 'single',
      },
      productAttributes: { maxAge, minAge, includeDives: true },
      namingConfiguration: namingConfiguration ?? {
        includeEquipment: true,
        includeDefaultName: true,
        includeSupervision: true,
      },
    };
    const offer: ClubProductPackageOffer = {
      reference,
      clubReference,
      productPackage,
      price,
    };
    return offer;
  }

  function generateOfferScubaDive(
    options: GenerateOffersDivePlanScubaDiveOptions,
  ) {
    const {
      price,
      label,
      namingConfiguration,
      diveAttributes,
      productAttributes,
    } = options;

    const {
      supervision,
      equipment,
      divesCount,
      successiveDivesCount,
      diveSingleAttributes,
    } = diveAttributes;

    const { minAge, maxAge } = productAttributes ?? {};

    const reference = `${clubReference};dive;x${divesCount};${supervision};${equipment};null;${new Date().getTime()}-${Math.floor(
      Math.random() * 100000,
    )}`;
    const productPackage: ClubProductPackage = {
      label,
      type: 'dive',
      comment: null,
      reference,
      diveAttributes: {
        successiveDivesCount,
        equipment,
        supervision,
        divesCount,
        diveMode: supervision,
        diveType: 'scuba',
        maxDepth: null,
        maxDistance: null,
        minDistance: null,
        diveSingleAttributes,
        divePriceType: successiveDivesCount > 1 ? 'successive' : 'single',
      },
      productAttributes: { maxAge, minAge, includeDives: true },
      namingConfiguration: namingConfiguration ?? {
        includeEquipment: false,
        includeDefaultName: true,
        includeSupervision: true,
      },
    };
    const offer: ClubProductPackageOffer = {
      reference,
      clubReference,
      productPackage,
      price,
    };
    return offer;
  }
}
