/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_PAYMENT_METHODS_CREDIT_NOTE } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppInputBooleanSwitchRHF, AppMultiCheckboxesRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { useAppPaymentMethodsOptions } from 'src/pages/_components/options';
import { ClubSettingsGeneralFormModel } from '../../../_model';

export const ClubSettingsGeneralPaymentsEditFormCreditNote = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    setFocus,
  } = form;

  const [paymentsEnabled, creditNoteEnabled, creditNotePaymentMethods] =
    useWatch({
      control,
      name: [
        'clubSettings.general.payments.enabled',
        'clubSettings.general.creditNote.enabled',
        'clubSettings.general.creditNote.paymentMethods',
      ],
    });

  const customerPaymentTypeOptions = useAppPaymentMethodsOptions('all');

  return !paymentsEnabled ? null : (
    <ClubSettingsSection title="Avoirs (remboursements)">
      <AppFormControlRHF_Deprecated
        label="Activer les avoirs"
        control={control}
        name={'clubSettings.general.creditNote.enabled'}
        required={false}
        renderComponent={(props) => (
          <AppInputBooleanSwitchRHF
            {...props}
            onChange={(enabled) => {
              if (
                (enabled && !creditNotePaymentMethods) ||
                creditNotePaymentMethods?.length === 0
              ) {
                setValue(
                  'clubSettings.general.creditNote.paymentMethods',
                  APP_PAYMENT_METHODS_CREDIT_NOTE,
                );
              }
            }}
          />
        )}
      />
      {creditNoteEnabled && (
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Moyens de paiement des avoirs"
          control={control}
          name={'clubSettings.general.creditNote.paymentMethods'}
          required={false}
          renderComponent={(props) => (
            <AppMultiCheckboxesRHF
              {...props}
              options={customerPaymentTypeOptions}
            />
          )}
        />
      )}
    </ClubSettingsSection>
  );
};
