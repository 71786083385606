import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import { SettingsCategory, SettingsCategoryPage } from '../_core';
export const SETTINGS_DIVE_CENTER_PAGE_PLANNING: SettingsCategoryPage = {
  id: 'planning',
  label: 'Planning',
  description: 'Planning par défaut',
  icon: AppIconsSettings.diveCenter.planning,
  url: '/club/settings/dive-center/planning',
  roles: ['club-view-settings-dive-center'],
  // isDiveCenterSpecificConfig: true,
  // TODO @link: voir aussi: configuration de l'affichage (dans modules/planning)
};
export const SETTINGS_DIVE_CENTER_PAGE_STAFF: SettingsCategoryPage = {
  id: 'staff',
  label: 'Staff, moniteurs',
  icon: AppIconsSettings.diveCenter.staff,
  url: '/club/settings/dive-center/staff',
  roles: ['club-view-settings-dive-center'],
  keywords: ['staff', 'moniteur', 'pilote', 'sécurité', 'surface', 'planning'],
  // isDiveCenterSpecificConfig: true,
};
export const SETTINGS_DIVE_CENTER_PAGE_DIVE_SITES: SettingsCategoryPage = {
  id: 'dive-site',
  label: 'Sites de plongée',
  icon: AppIconsSettings.diveCenter.diveSite,
  url: '/club/settings/dive-center/sites',
  roles: ['club-view-settings-dive-center'],
  // isDiveCenterSpecificConfig: true,
};
export const SETTINGS_DIVE_CENTER_PAGE_BOATS: SettingsCategoryPage = {
  id: 'boat',
  label: 'Ressources',
  description: 'Bateaux, piscines, salles, départ du bord...',
  icon: AppIconsSettings.diveCenter.boat,
  url: '/club/settings/dive-center/boats',
  roles: ['club-view-settings-dive-center'],
  // isDiveCenterSpecificConfig: true,
};
export const SETTINGS_DIVE_CENTER: SettingsCategory = {
  id: 'dive-center',
  label: 'Centre de plongée',
  color: AppIconsSettings.diveCenter.color,
  icon: AppIconsSettings.diveCenter.main,
  isDiveCenterSpecificConfig: true,
  pages: [
    SETTINGS_DIVE_CENTER_PAGE_BOATS,
    SETTINGS_DIVE_CENTER_PAGE_DIVE_SITES,
    SETTINGS_DIVE_CENTER_PAGE_STAFF,
    SETTINGS_DIVE_CENTER_PAGE_PLANNING,
  ],
};
