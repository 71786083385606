/* eslint-disable @typescript-eslint/no-unused-vars */
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFormControlRHF, AppInputRHF } from '../../../../../../lib/form';
import { AppInputDatePickerMaterialRHF } from '../../../../../../stories/components/04-form';
import { DiveCenterEditFormModel } from '../model';

export type ClubSettingsForm_BillingDetails_Invoice_OnClickAction =
  | 'move-down'
  | 'move-up'
  | 'delete'
  | 'create';

export const ClubSettingsForm_BillingDetails_Invoice = ({
  className,
  form,
  fieldArray,
  fieldIndex,
  onClick,
}: {
  className?: string;
  form: UseFormReturn<DiveCenterEditFormModel>;
  fieldArray: UseFieldArrayReturn<
    DiveCenterEditFormModel,
    'clubSettings.billingData.invoices',
    '_id'
  >;
  fieldIndex: number;
  onClick: (
    action: ClubSettingsForm_BillingDetails_Invoice_OnClickAction,
  ) => void;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    getValues,
  } = form;

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const clubReference = clubResume.reference;
  const customerSettings = clubResume.clubSettings?.customer;
  const generalSettings = clubResume.clubSettings?.general;

  const formAttributeBaseName =
    `clubSettings.billingData.invoices.${fieldIndex}` as const;

  const isMoveDownAllowed =
    fieldArray.fields.length > 1 && fieldIndex + 1 < fieldArray.fields.length;
  const isMoveUpAllowed = fieldArray.fields.length > 1 && fieldIndex !== 0;

  const az = useAppSecurityUserClubAuthorizations();
  return (
    <div
      className={clsx(
        'w-full flex gap-2 items-start flex-row gap-x-4',
        className,
      )}
    >
      {fieldArray.fields.length > 1 && (
        <div
          className={clsx('h-full flex md:flex-col md:justify-center gap-2')}
        >
          <Tippy
            delay={[1000, 100]}
            placement="top"
            content={'Déplacer au dessus'}
          >
            <AppIconsAction.up
              className={clsx(
                'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
                isMoveUpAllowed
                  ? 'hover:bg-gray-600 cursor-pointer'
                  : 'opacity-50 cursor-not-allowed',
              )}
              onClick={() => isMoveUpAllowed && onClick('move-up')}
            />
          </Tippy>
          <Tippy
            delay={[1000, 100]}
            placement="top"
            content={'Déplacer en dessous'}
          >
            <AppIconsAction.down
              className={clsx(
                'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
                isMoveDownAllowed
                  ? 'hover:bg-gray-600 cursor-pointer'
                  : 'opacity-50 cursor-not-allowed',
              )}
              onClick={() => isMoveDownAllowed && onClick('move-down')}
            />
          </Tippy>
        </div>
      )}
      <div className="w-full flex flex-col gap-2 items-start xs:flex-row xs:gap-x-4 xs:items-center xs:flex-wrap">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-2">
          <AppFormControlRHF
            label={'Année'}
            control={control}
            name={`${formAttributeBaseName}.year`}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF
            label={'Numéro de facture'}
            control={control}
            name={`${formAttributeBaseName}.invoiceNumber`}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          <AppFormControlRHF
            label={'Montant HT (avant remise)'}
            control={control}
            name={`${formAttributeBaseName}.amountWithoutTax`}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF
            label={'Remise HT'}
            control={control}
            name={`${formAttributeBaseName}.discountWithoutTax`}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF
            label={'Taux TVA'}
            control={control}
            name={`${formAttributeBaseName}.taxRate`}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          {/* TODO afficher total HT et avec TVA */}
          <AppFormControlRHF
            label={'Début forfait'}
            control={control}
            name={`${formAttributeBaseName}.periodBeginDate`}
            renderComponent={(props) => (
              <AppInputDatePickerMaterialRHF {...props} />
            )}
          />
          <AppFormControlRHF
            label={'Date limite paiement'}
            control={control}
            name={`${formAttributeBaseName}.dueDate`}
            renderComponent={(props) => (
              <AppInputDatePickerMaterialRHF {...props} />
            )}
          />
          <AppFormControlRHF
            label={'Date de paiement'}
            control={control}
            name={`${formAttributeBaseName}.paymentDate`}
            renderComponent={(props) => (
              <AppInputDatePickerMaterialRHF {...props} />
            )}
          />
        </div>

        <div className="flex-grow"></div>
        <div className="w-full sm:w-auto flex-no-shrink flex gap-2">
          <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
            <AppIconsAction.delete
              className={clsx(
                'p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer',
              )}
              onClick={() => {
                onClick('delete');
              }}
            />
          </Tippy>
        </div>
      </div>
    </div>
  );
};
