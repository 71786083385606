import clsx from 'clsx';
import React from 'react';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';

import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DashboardPurchasesExplosOverPeriodWithLabel } from './DashboardReportPageGlobalActivityPurchasesExplosOverPeriod';

export const DashboardReportPageGlobalActivityPurchasesExplosOverPeriodTableRow =
  ({
    explo,
    comparisonDetails,
    className,
  }: {
    explo: DashboardPurchasesExplosOverPeriodWithLabel;
    comparisonDetails: DashboardReportComparisonDetails<any>;
    className?: string;
  }) => {
    const mainCurrency = useAppCurrencyMain();

    const { localState, showPeriodRangeComparison } = comparisonDetails;

    const selectedExplo = localState.state.uiConfig.selection?.explo;
    const hoverExplo = localState.state.uiConfig.hover?.explo;

    const isSelected =
      selectedExplo?.productPackageOfferReference ===
      explo.productPackageOffer?.reference;

    const isHover =
      hoverExplo?.productPackageOfferReference ===
      explo.productPackageOffer?.reference;

    return (
      <tr
        className={clsx(
          isSelected ? 'app-tr-selected' : isHover && 'app-tr-highlighted',
          className,
        )}
        onMouseEnter={() =>
          localState.state.setHover({
            // training: {
            // diveExploReference: training.diveExploReference,
            // offerName: training.offerName,
            // },
          })
        }
        onMouseLeave={() => localState.state.setHover()}
        onClick={() =>
          localState.state.setSelection({
            // training: {
            // diveExploReference: training.diveExploReference,
            // offerName: training.offerName,
            // },
          })
        }
      >
        <th
          className={clsx(
            'truncate text-left whitespace-nowrap px-2 w-[50%]',
            explo.purchasePackageCount === 0 ? 'text-gray-400' : '',
          )}
        >
          <div className="flex flex-wrap items-end gap-x-1 xl:gap-x-1.5 text-xs md:text-sm pt-1">
            <span
              className={clsx(
                'font-medium uppercase',
                showPeriodRangeComparison ? 'text-app-xxs md:text-xs' : '',
              )}
            >
              {explo.label ?? '?'}
            </span>
          </div>
        </th>
        <td
          className={clsx(
            'text-app-primary text-right text-lg px-4 whitespace-nowrap font-bold',
            explo.purchasePackageCount === 0 ? 'opacity-30' : '',
          )}
        >
          {explo.purchasePackageCount}{' '}
          <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
        </td>
        <td
          className={clsx(
            'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
            explo.purchasePackagePayedAmount === 0 ? 'opacity-30' : '',
            showPeriodRangeComparison
              ? 'hidden xl:table-cell'
              : 'hidden lg:table-cell',
          )}
        >
          <AppPriceLabel
            amount={explo.purchasePackagePriceAvg}
            mainCurrency={mainCurrency}
          />
        </td>
        <td
          className={clsx(
            'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
            explo.purchasePackagePriceSum === 0 ? 'opacity-30' : '',
          )}
        >
          <AppPriceLabel
            amount={explo.purchasePackagePriceSum}
            mainCurrency={mainCurrency}
          />
        </td>
        <td
          className={clsx(
            'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
            explo.purchasePackagePayedAmount === 0 ? 'opacity-30' : '',
            showPeriodRangeComparison
              ? 'hidden xl:table-cell'
              : 'hidden lg:table-cell',
          )}
        >
          <AppPriceLabel
            amount={explo.purchasePackagePayedAmount}
            mainCurrency={mainCurrency}
          />
        </td>
      </tr>
    );
  };
