/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingParticipantEditorFormModel } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
export const BookingMemberDiverEditFormRHFMedicalCertificate = ({
  form,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const customerSettings = clubResume.clubSettings?.customer;

  const { control, setValue } = form;

  const [medicalCertificateChecked] = useWatch({
    control,
    name: ['diver.medicalCertificateChecked'],
  });
  const showMedicalCertificate =
    customerSettings?.diving?.medicalCertificateDate ||
    customerSettings?.diving?.medicalCertificateChecked;

  return showMedicalCertificate ? (
    <div className={clsx('w-full  flex gap-2 justify-between', className)}>
      {customerSettings?.diving?.medicalCertificateDate && (
        <AppFormControlRHF_Deprecated
          className={clsx('w-full')}
          label="Certificat médical"
          control={control}
          name={'diver.medicalCertificateDate'}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              {...props}
              className="w-full"
              onChange={(date) => {
                if (date && !medicalCertificateChecked) {
                  setValue('diver.medicalCertificateChecked', true);
                }
              }}
            />
          )}
        />
      )}
      {customerSettings?.diving?.medicalCertificateChecked && (
        <AppFormControlRHF_Deprecated
          label={
            customerSettings?.diving?.medicalCertificateDate
              ? 'Vérifié'
              : 'Certificat médical'
          }
          control={control}
          name={'diver.medicalCertificateChecked'}
          renderComponent={(props) => (
            <div
              className={clsx(
                'w-full flex',
                customerSettings?.diving?.medicalCertificateDate &&
                  'justify-center',
              )}
            >
              <AppInputBooleanRHF
                className="mt-2"
                {...props}
                scale="lg"
                type="checkbox"
              />
            </div>
          )}
        />
      )}
    </div>
  ) : null;
};
