import React, { FC, useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppPageContainer } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubPlanningSessionSelectorDialog,
  ClubPlanningSessionSelectorDialogState,
} from 'src/business/club/modules/club-planning-light/club-planning-session-selector-dialog/ClubPlanningSessionSelectorDialog';
import { ClubDialogsState } from 'src/pages/_dialogs';
import { BookingParticipantViewDialogInputState } from '../../../models';
import { BookingParticipantViewDialogPanel } from './BookingParticipantViewDialogPanel';
import {
  BookingParticipantViewDialogLocalState,
  useBookingParticipantViewDialogLocalState,
} from './useBookingParticipantViewDialogLocalState.hook';
export const BookingParticipantViewDialog: FC<{
  dialogsState: ClubDialogsState;
  state: BookingParticipantViewDialogInputState;
  setState: React.Dispatch<
    React.SetStateAction<BookingParticipantViewDialogInputState>
  >;
}> = ({ dialogsState, state: inputState, setState: setInputState }) => {
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const localState: BookingParticipantViewDialogLocalState =
    useBookingParticipantViewDialogLocalState({
      inputState,
      setInputState,
      dialogsState,
    });

  const { viewState, setViewState, onConfirmMoveParticipant, aggregatedData } =
    localState;
  const { participantData } = aggregatedData;

  const sessionSelectorDialogInputState: ClubPlanningSessionSelectorDialogState =
    useMemo(() => {
      if (viewState.mode === 'move-participant-select-dialog') {
        const state: ClubPlanningSessionSelectorDialogState = {
          isOpen: true,
          mode: 'single',
          alreadySelectedSessionsReferences: [
            participantData.diveSession?.reference,
          ],
          areadySelectedDiverIds: [participantData.diver?._id],
        };
        return state;
      } else {
        return {
          isOpen: false,
        };
      }
    }, [
      participantData.diveSession?.reference,
      participantData.diver?._id,
      viewState.mode,
    ]);

  return (
    <>
      {viewState.mode === 'view-participant' && (
        <BookingParticipantViewDialogPanel localState={localState} />
      )}
      {viewState.mode === 'move-participant-select-dialog' && (
        <AppPageContainer className="bg-white relative overflow-y-hidden">
          <ClubPlanningSessionSelectorDialog
            state={sessionSelectorDialogInputState}
            defaultFocusDate={
              sessionSelectorDialogInputState?.context?.defaultFocusDate ??
              participantData?.diveSession?.time
            }
            onClose={() => {
              setViewState({
                mode: 'view-participant',
              });
            }}
            onSelectSessions={(sessions, context) => {
              if (sessions.length === 1) {
                const session = sessions[0];
                onConfirmMoveParticipant(session);
              }
            }}
          />
        </AppPageContainer>
      )}
    </>
  );
};
