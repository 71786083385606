/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubDiveSite,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import {
  AppBreadcrumbBar,
  AppButtonsBar,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import useRouter from 'use-react-router';
import { clubSettingsDiveCenterUrlBuilder } from '../../clubSettingsDiveCenterUrlBuilder.service';
import {
  DiveSiteEditForm,
  diveSiteFormResultBuilder,
} from './DiveSiteEditForm';
import {
  ClubResumeDiveSiteFormModel,
  DiveSiteEditPageContext,
  DiveSiteFormLocalState,
} from './model';
import { useDiveSiteFormLocalState } from './useDiveSiteFormLocalState.hook';

export const DiveSiteEditPage = ({
  navigationContext,
}: {
  navigationContext: DiveSiteEditPageContext;
}) => {
  const { match, history } =
    useRouter<{
      diveSiteId: string;
    }>();

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const params = new URLSearchParams(window.location.search);

  const defaultDiveSiteGroupId = params.get('defaultDiveSiteGroupId') as string;

  const diveSiteId = match.params.diveSiteId;

  const localState: DiveSiteFormLocalState = useDiveSiteFormLocalState({
    mode: navigationContext.mode,
    diveSiteId,
    defaultDiveSiteGroupId,
  });

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const { mode, diveSite, form, initialFormValue } = localState;
  const { handleSubmit } = form;

  const back = useCallback(() => {
    return redirectTo(clubSettingsDiveCenterUrlBuilder.diveSites.list());
  }, [redirectTo]);

  useMemo(() => {
    if (mode === 'edit' && !diveSite) {
      appLogger.warn('Invalid diveSiteId: redirect');
      back();
    }
  }, [back, mode, diveSite]);

  const persistChanges = useCallback(() => {
    handleSubmit((formValue: ClubResumeDiveSiteFormModel, event) => {
      if (mode === 'edit') {
        const value = diveSiteFormResultBuilder.buildResult(formValue);
        const patchOperations = jsonPatcher.compareObjects(
          initialFormValue,
          value,
          {
            attributesToReplaceFully: [],
            replaceDeleteByNullValue: true,
          },
        );
        if (patchOperations.length) {
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'update dive site',
              bookingIdsToClean: [],
            });
          payload.updatedDiveSites = [
            {
              pk: diveSite?._id,
              patchOperations,
            },
          ];
          clubMassiveUpdatorClient.update(payload).then(() => {
            back();
          });
        } else {
          back();
        }
      } else if (mode === 'create') {
        const value = diveSiteFormResultBuilder.buildResult(formValue);
        const payload: ProMultiOperationPayload =
          clubMassiveUpdatorClient.createEmptyPayload({
            logContext: 'create dive site',
            bookingIdsToClean: [],
          });
        payload.newDiveSites = [
          repositoryEntityBuilder.buildEntity<ClubDiveSite>({
            ...value,
            clubReference,
            diveCenterId,
          }),
        ];
        clubMassiveUpdatorClient.update(payload).then(() => {
          back();
        });
      }
    })();
  }, [
    handleSubmit,
    mode,
    initialFormValue,
    diveSite?._id,
    back,
    clubReference,
    diveCenterId,
  ]);

  return (
    <form
      className="w-full"
      onSubmit={(e) => {
        persistChanges();
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-gray-50"
        headerBar={() => (
          <AppBreadcrumbBar
            color={AppIconsSettings.diveCenter.color}
            items={[
              {
                icon: AppIconsSettings.general,
                label: 'Paramètres',
                url: '/club/settings',
              },
              {
                icon: AppIconsSettings.diveCenter.main,
                label: 'Centre de plongée',
                url: '/club/settings',
              },
              {
                label: 'Sites de plongée',
              },
            ]}
          >
            {isMultiDiveCenters && (
              <div className="app-px-content">
                <DiveCenterProBadge diveCenter={diveCenterResume} />
              </div>
            )}
          </AppBreadcrumbBar>
        )}
        footerBar={() => (
          <AppButtonsBar className="" hasChanges={form.formState.isDirty}>
            <>
              <AppButton
                className="my-2 w-full uppercase"
                fullWidth={true}
                color={'primary-outline-light'}
                size="normal"
                icon={AppHeroIcons.actionCancel}
                onClick={() => back()}
              >
                Annuler
              </AppButton>
              <AppButton
                type="submit"
                className="my-2 w-full uppercase"
                fullWidth={true}
                color={'primary-outline-light'}
                size="normal"
                icon={AppHeroIcons.actionSave}
              >
                Enregistrer
              </AppButton>
            </>
          </AppButtonsBar>
        )}
      >
        <AppPageContentContainer className="bg-gray-50 app-p-content">
          <DiveSiteEditForm localState={localState} />
        </AppPageContentContainer>
      </AppPageContainerWithFixedBars>
    </form>
  );
};
