/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppCompanyMessageLocalized,
  AppCompanyMessageSchedule,
  AppCompanyMessageScheduleContextBooking,
  AppCompanyMessageScheduleContextSession,
  AppCompanyMessageScheduleTarget,
  AppCompanyMessageScheduleTargetMessage,
  AppCompanyMessageScheduleTargetMessageRecipient,
  AppCompanyMessageScheduleTargetMessageRecipientMeta,
  AppCompanyMessageTarget,
  AppCompanyMessageTemplate,
  AppEntityUpdatePatch,
  AppMessageParamsRecipientType,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  companyMessageBuilder,
  jsonPatcher,
  uuidGenerator,
} from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { uiStore } from 'src/business/_core/store';
import {
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { appWebLogger } from 'src/lib/browser';
import { useAppCompanyMessageTemplatesOptions } from 'src/pages/_components/options';
import {
  CreateMessageToCustomersAggregatedRecipient,
  CreateMessageToCustomersBaseData,
  CreateMessageToCustomersDialogState,
  CreateMessageToCustomersFormModel,
  CreateMessageToCustomersStep,
} from './model';
import { CreateMessageToCustomersFormRecipient } from './model/CreateMessageToCustomersFormRecipient.type';
import {
  buildAppCompanyMessageScheduleTargetMessageRecipientMeta,
  createMessageToCustomersChangesBuilder,
} from './services';

export function useCreateMessageToCustomersLocalState(
  inputState: CreateMessageToCustomersDialogState,
) {
  const {
    isOpen,
    initialState,
    onCancel: onCancelInput,
    onConfirm,
    openDialog,
    closeDialog,
  } = inputState;

  // const [participants, setInitialParticipants] = useState(participantsInput);

  const diveCenterResume = useDiveCenterResume();
  const [isPersistInProgress, setIsPersistInProgress] = useState(false);

  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  const context = initialState.context;

  const refDate = useMemo(
    () =>
      context === 'session'
        ? initialState.sessionContext?.diveSession?.time ?? new Date()
        : new Date(),
    [context, initialState.sessionContext?.diveSession?.time],
  );

  const [step, setStep] =
    useState<CreateMessageToCustomersStep>('step1-message');

  const { messageTemplatesOptions, messageTemplates } =
    useAppCompanyMessageTemplatesOptions({
      messageContext: context,
    });

  const initialmessageTemplate = useMemo(() => {
    return messageTemplates?.length > 0 ? messageTemplates[0] : undefined;
  }, [messageTemplates]);

  const clubSettings = useClubSettings();

  const initialBaseData: CreateMessageToCustomersBaseData = useMemo(() => {
    if (context === 'session') {
      const baseData: CreateMessageToCustomersBaseData = {
        recipients: initialState.sessionContext?.participants
          .filter(
            (x) =>
              x.bookingState?.value === 'confirmed' ||
              x.bookingState?.value === 'pending' ||
              x.bookingState?.value === 'cancelled',
          )
          .map((p) => ({
            diver: p.diver,
            bookingStatus: p.bookingState?.value,
          })),
      };
      return baseData;
    } else if (context === 'booking') {
      const baseData: CreateMessageToCustomersBaseData = {
        recipients: initialState.bookingContext?.bookingMembersFull.map(
          (p) => ({
            diver: p.diver,
            bookingStatus: 'confirmed', // pas utile dans ce contexte
            bookingMember: p.bookingMember,
            isBookingContact:
              p.diver._id ===
              initialState.bookingContext?.booking.bookingContactDiverId,
          }),
        ),
      };
      return baseData;
    }
  }, [
    context,
    initialState.sessionContext?.participants,
    initialState.bookingContext?.bookingMembersFull,
    initialState.bookingContext?.booking.bookingContactDiverId,
  ]);

  const confirmedParticipantsCount = useMemo(
    () =>
      initialBaseData.recipients.filter(
        (x) => x.bookingStatus === 'confirmed' || x.bookingStatus === 'pending',
      ).length,
    [initialBaseData.recipients],
  );
  const cancelledParticipantsCount = useMemo(
    () =>
      initialBaseData.recipients.filter((x) => x.bookingStatus === 'cancelled')
        .length,
    [initialBaseData.recipients],
  );

  const [includeCancelledParticipants, setIncludeCancelledParticipants] =
    useState(
      confirmedParticipantsCount === 0 && cancelledParticipantsCount > 0
        ? true
        : false,
    );

  const [baseData, setBaseData] =
    useState<CreateMessageToCustomersBaseData>(initialBaseData);

  const initialFormValue: CreateMessageToCustomersFormModel = useMemo(() => {
    const targetModeEmail =
      context === 'booking'
        ? clubSettings.communication?.booking?.email?.mode
        : clubSettings.communication?.session?.email?.mode;

    const model: CreateMessageToCustomersFormModel = {
      targetModeEmail,
      recipients: baseData.recipients.map(
        (
          { diver, isBookingContact, bookingMember, bookingStatus },
          formDiverIndex,
        ) => {
          const recipient: CreateMessageToCustomersFormRecipient = {
            formDiverIndex,
            diver,
            bookingMember,
            isBookingContact,
            isSelected: false,
            bookingStatus,
          };
          return recipient;
        },
      ),
      messageContent: undefined,
      // createMessageToCustomersChangesBuilder.buildMessageContentFromTemplate(
      //   initialmessageTemplate?.emailContent,
      //   {
      //     clubSettings,
      //   },
      // ),
      messageTemplateId: initialmessageTemplate._id,
    };
    return model;
  }, [
    baseData.recipients,
    clubSettings.communication?.booking?.email?.mode,
    clubSettings.communication?.session?.email?.mode,
    context,
    initialmessageTemplate._id,
  ]);

  const form = useForm<CreateMessageToCustomersFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const hasChanges = formState.isDirty;

  const formValue = useWatch({
    control,
  }) as CreateMessageToCustomersFormModel;

  const [
    targetModeEmail,
    selectedMessageTemplateId,
    title,
    body,
    signature,
    messageTarget,
  ] = useWatch({
    control,
    name: [
      'targetModeEmail',
      'messageTemplateId',
      'messageContent.title',
      'messageContent.body',
      'messageContent.signature',
      'messageTarget',
    ],
  });
  const targetMode = messageTarget === 'email' ? targetModeEmail : 'manual';
  const isConnexionLinkFountInMessage = useMemo(() => {
    return [title, body, signature].some((x) =>
      x?.includes('ESPACE_CLIENT_LIEN_RESA'),
    );
  }, [body, signature, title]);

  const selectedMessageTemplate = useMemo(
    () =>
      (messageTemplates ?? []).find((x) => x._id === selectedMessageTemplateId),
    [messageTemplates, selectedMessageTemplateId],
  );

  const result = useMemo(
    () =>
      createMessageToCustomersChangesBuilder.buildChangesResult({
        initialFormValue,
        formValue,
      }),
    [formValue, initialFormValue],
  );

  const persistParticipantsChangesIfAny = useCallback(async () => {
    const initialDivers = initialFormValue.recipients.map((x) => x.diver);
    const finalDivers = formValue.recipients.map((x) => x.diver);

    const updatedDiversPatches = initialDivers
      .map((initialDiver, index) => {
        const finalDiver = finalDivers[index];
        const patchOperations = jsonPatcher.compareObjects(
          initialDiver,
          finalDiver,
          {},
        );
        if (patchOperations.length > 0) {
          const patch: AppEntityUpdatePatch = {
            pk: initialDiver._id,
            patchOperations,
          };
          return patch;
        }
      })
      .filter((x) => !!x);

    if (updatedDiversPatches.length === 0) {
      return;
    } else {
      setIsPersistInProgress(true);

      const payload: ProMultiOperationPayload = {
        logContext: 'create session message',
        updatedDivers: updatedDiversPatches,
      };
      return await clubMassiveUpdatorClient.update(payload).then(
        () => {
          setIsPersistInProgress(false);
          setBaseData({
            ...baseData,
            recipients: finalDivers.map((diver) => {
              const recipient = initialFormValue.recipients.find(
                (x) => x.diver?._id === diver._id,
              );
              return {
                diver,
                isBookingContact: recipient?.isBookingContact,
                bookingStatus: recipient?.bookingStatus,
              };
            }),
          });
        },
        (err) => {
          setIsPersistInProgress(false);
          appWebLogger.captureMessage(
            'Error while trying to persist phone number changes',
            {
              logContext: 'CreateMessageToCustomers',
              clubReference,
              extra: {
                payload,
              },
            },
          );

          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
          });
        },
      );
    }
  }, [
    baseData,
    clubReference,
    formValue.recipients,
    initialFormValue.recipients,
  ]);

  // const confirmPersistChangesBeforeLeave = useCallback(async () => {
  //   const confirm = await confirmDialog.confirmPromise({
  //     title: 'Modifications en cours',
  //     message: 'Voulez-vous sauvegarder les changements?',
  //     type: 'cancelChangesOrSave',
  //   });

  //   if (confirm) {
  //     await persistChanges();
  //   }

  //   return true;
  // }, [persistChanges]);

  const onCancel = useCallback(async () => {
    if (hasChanges) {
      // await confirmPersistChangesBeforeLeave();
      // pas nécessaire?
      onCancelInput(hasChanges);
    } else {
      onCancelInput(hasChanges);
    }
  }, [hasChanges, onCancelInput]);

  const autoSetRecipientType = useCallback(
    ({ nextStep }: { nextStep: CreateMessageToCustomersStep }) => {
      const validSelectedRecipients = result.aggregated.validSelectedRecipients;
      if (nextStep === 'step2-recipients') {
        const recipientType: AppMessageParamsRecipientType =
          messageTarget === 'whatsapp' ? 'personal' : 'group';
        setValue('recipientType', recipientType);
      } else if (nextStep === 'step3-content') {
        if (validSelectedRecipients?.length !== 0) {
          if (
            isConnexionLinkFountInMessage &&
            clubSettings.communication?.customerSpace?.individualLinksEnabled
          ) {
            setValue('recipientType', 'personal');
          } else {
            const recipientType: AppMessageParamsRecipientType =
              messageTarget === 'whatsapp' ? 'personal' : 'group';
            setValue('recipientType', recipientType);
          }
        }
      }
    },
    [
      clubSettings.communication?.customerSpace?.individualLinksEnabled,
      isConnexionLinkFountInMessage,
      messageTarget,
      result.aggregated.validSelectedRecipients,
      setValue,
    ],
  );

  const persistMessageSchedule = useCallback(
    async (uniqueRecipient?: CreateMessageToCustomersAggregatedRecipient) => {
      // TODO persist

      const formValue: CreateMessageToCustomersFormModel = result.formValue;
      const validSelectedRecipients = result.aggregated.validSelectedRecipients;

      const messageTemplate: AppCompanyMessageTemplate = messageTemplates.find(
        (x) => x._id === formValue.messageTemplateId,
      );

      const messageContent: AppCompanyMessageLocalized =
        formValue.messageContent;
      const messageTarget: AppCompanyMessageTarget = formValue?.messageTarget;

      const recipients: AppCompanyMessageScheduleTargetMessageRecipient[] =
        validSelectedRecipients
          .filter(
            (x) =>
              // TODO: si c'est un mode destinataire unique (whatsapp par exemple), et qu'on clique sur plusieurs destinataires, on pourrait rajouter les destinataires 2 et 3 au premier schedule créés, plutôt que d'en créer plusieurs
              !uniqueRecipient ||
              x.formRecipient?.diver?._id ===
                uniqueRecipient?.formRecipient?.diver?._id,
          )
          .map((x) => {
            const meta: AppCompanyMessageScheduleTargetMessageRecipientMeta =
              buildAppCompanyMessageScheduleTargetMessageRecipientMeta({
                context,
                recipientType: formValue.recipientType,
                previewRecipient: result.aggregated.allRecipients.find(
                  (y) =>
                    y.formRecipient.diver?._id === x.formRecipient?.diver?._id,
                ),
                validSelectedRecipients,
                initialState,
              });

            const recipient: AppCompanyMessageScheduleTargetMessageRecipient = {
              diver: {
                _id: x.formRecipient?.diver?._id,
                firstName: x.formRecipient?.diver?.firstName,
                lastName: x.formRecipient?.diver?.lastName,
              },
              contactDetails: x.targetValidContact,
              meta,
            };
            return recipient;
          });

      const localizedMessage: AppCompanyMessageScheduleTargetMessage =
        companyMessageBuilder.buildLocalizedMessage(messageContent, recipients);

      const messageScheduleId = uuidGenerator.random();

      const sendDateExpected = new Date();

      const mainTarget: AppCompanyMessageScheduleTarget =
        companyMessageBuilder.buildMainTarget({
          clubReference,
          diveCenterId,
          messageScheduleId,
          messageTarget,
          targetMode,
          messageContent,
          localizedMessage,
          sendDateExpected,
        });

      const sessionContext: AppCompanyMessageScheduleContextSession =
        context === 'session'
          ? companyMessageBuilder.buildMessageContextSession(
              initialState.sessionContext?.diveSession,
            )
          : undefined;
      const bookingContext: AppCompanyMessageScheduleContextBooking =
        context === 'booking'
          ? companyMessageBuilder.buildMessageContextBooking(
              initialState.bookingContext?.booking,
            )
          : undefined;

      const messageSchedule: AppCompanyMessageSchedule =
        companyMessageBuilder.buildMessageSchedule({
          messageScheduleId,
          clubReference,
          diveCenterId,
          messageTemplateId: formValue.messageTemplateId,
          messageTemplate,
          mainTarget,
          bookingContext,
          sessionContext,
          recipients,
          targetMode,
          sendDateExpected,
        });

      const payload: ProMultiOperationPayload =
        clubMassiveUpdatorClient.createEmptyPayload({
          logContext: 'create-message-schedule',
          bookingIdsToClean: [],
        });
      payload.appCompanyMessageSchedules = {
        created: [messageSchedule],
      };
      await clubMassiveUpdatorClient.update(payload);

      const { onUpdate, closeDialog } = inputState;
      onUpdate &&
        (await onUpdate({
          // callback: on appelle useCreateMessageToCustomersDialogActionPersist
          formValue,
        }));
    },
    [
      clubReference,
      context,
      diveCenterId,
      initialState,
      inputState,
      messageTemplates,
      result.aggregated.allRecipients,
      result.aggregated.validSelectedRecipients,
      result.formValue,
      targetMode,
    ],
  );

  const persistMessageScheduleAndClose = useCallback(async () => {
    await persistMessageSchedule();

    if (targetMode === 'auto') {
      uiStore.snackbarMessage.set({
        type: 'success',
        content: 'Message envoyé!',
      });
    }

    setTimeout(() => {
      closeDialog();
    }, 100);
  }, [closeDialog, persistMessageSchedule, targetMode]);

  return {
    data: {
      result,
      clubReference,
      messageTemplatesOptions,
      messageTemplates,
      selectedMessageTemplate,
      refDate,
      form,
      initialState,
      isConnexionLinkFountInMessage,
    },
    state: {
      context,
      step,
      setStep,
      isPersistInProgress,
      setIsPersistInProgress,
      selectedMessageTemplate,
      cancelledParticipantsCount,
      includeCancelledParticipants,
      setIncludeCancelledParticipants,
    },
    actions: {
      onCancel,
      closeDialog,
      persistParticipantsChangesIfAny,
      autoSetRecipientType,
      persistMessageSchedule,
      persistMessageScheduleAndClose,
    },
  };
}

export type CreateMessageToCustomersLocalState = ReturnType<
  typeof useCreateMessageToCustomersLocalState
>;
