/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { DiveSessionStaffRoleModel } from './_model';

export function StaffRoleBadge({
  role,
  onClick,
  className = '',
}: {
  role: DiveSessionStaffRoleModel;
  onClick?: ({ role }: { role: DiveSessionStaffRoleModel }) => any;
  className?: string;
}) {
  return (
    <span
      className={clsx(
        'border uppercase rounded text-center py-px sm:py-0.5 whitespace-nowrap',
        !role.enabled && 'invisible',
        onClick && 'hover:bg-gray-50 hover:font-bold cursor-pointer',
        onClick &&
          (role.staffRoleRef === 'diving-director'
            ? 'hover:border-red-800 hover:text-red-800'
            : role.staffRoleRef === 'surface-security'
            ? 'hover:border-green-800 hover:text-green-800'
            : 'hover:border-app-primary-light hover:text-app-primary-light'),
        role.active || role.assigned
          ? clsx(
              'font-bold',
              role.staffRoleRef === 'diving-director'
                ? 'border-red-600 text-red-600'
                : role.staffRoleRef === 'surface-security'
                ? 'border-green-600 text-green-600'
                : 'border-app-primary text-app-primary',
            )
          : `border-gray-400 text-gray-400 ${
              onClick && 'hover:border-gray-800 hover:text-gray-80'
            }' `,
        className,
      )}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick({
            role,
          });
        }
      }}
    >
      {role.label}
    </span>
  );
}
