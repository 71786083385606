/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';

export const DailyExportConfigOptionsPanelSheetContentItemPickups = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}` as const;

  // const [selectedSessionDef] = useWatch({
  //   control,
  //   name: ['selectedSessionDef'],
  // });

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  return (
    <div className={clsx('', className)}>
      <h4 className="text-left text-base">
        <span className="font-medium text-gray-800">Pick-ups:</span>
        {'  '}
        <span className="text-gray-500">
          détail des pickups (heure, lieu, participants)
        </span>
      </h4>
    </div>
  );
};
