/* eslint-disable @typescript-eslint/no-unused-vars */
import { dataSorter, search } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DepositsListPageFetchCriteria } from './model';
import {
  DepositsListPageFilters,
  useDepositsListPageFilters,
} from './useDepositsListPageFilters.hook';
import { useDepositsListPageViewParameters } from './useDepositsListPageViewParameters';
import { useFetchDepositsWithCache } from './useFetchDepositsWithCache.hook';

export function useDepositsListPageLocalState() {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;

  const { viewParameters, setViewParameters } =
    useDepositsListPageViewParameters();

  const { beginDate, endDate } = viewParameters.periodRange.value;

  const filters: DepositsListPageFilters = useDepositsListPageFilters();

  const {
    searchText,
    depositState,
    depositStates,
    partiallyUsed,
    purchasePaymentPending,
  } = filters;

  const fetchCriteria: DepositsListPageFetchCriteria = useMemo(
    () => ({
      beginDate,
      endDate,
      depositStates,
      purchasePaymentPending,
    }),
    [beginDate, endDate, depositStates, purchasePaymentPending],
  );

  const { data: deposits, loadableContent } = useFetchDepositsWithCache(
    fetchCriteria,
    {
      autoRefetchInterval: '5 minutes', // periodic refresh (partial or full)
      staleInterval: '1 minute', // (full refresh if data is older));
    },
  );

  const loadingInProgress = loadableContent.lastActionStatus === 'in-progress';

  const depositsSorted = useMemo(
    () =>
      dataSorter.sortMultiple(deposits ?? [], {
        getSortAttributes: (x, i) => [
          {
            value: new Date(
              Math.max(
                new Date(x.depositDate)?.getTime(),
                new Date(x._createdAt)?.getTime(),
              ),
            ),
            type: 'default',
            asc: false,
          },
        ],
        asc: true,
      }),
    [deposits],
  );

  const filteredDeposits = useMemo(() => {
    if (depositsSorted) {
      let localDeposits = depositsSorted;
      if (depositState) {
        localDeposits = localDeposits.filter(
          (x) => x.depositState === depositState,
        );
      }
      if (depositStates.length > 0) {
        localDeposits = localDeposits.filter((x) =>
          depositStates.includes(x.depositState),
        );
      }
      if (purchasePaymentPending === true) {
        localDeposits = localDeposits.filter(
          (x) => x.purchasePaymentPending === purchasePaymentPending,
        );
      }

      if (partiallyUsed === true) {
        localDeposits = localDeposits.filter(
          (x) =>
            x.depositState === 'active' &&
            x.creditRemaining !== x.creditInitial &&
            x.creditRemaining !== 0,
        );
      }

      return search.filter(localDeposits, {
        searchText,
        getAttributes: (x) => [
          x.reference,
          `${x.price}`,
          x.diver.lastName,
          x.diver.firstName,
        ],
        // maxResults: 50,
        // sortResultsByBestMatch: true,
      });
    }
    return depositsSorted;
  }, [
    depositsSorted,
    depositState,
    depositStates,
    purchasePaymentPending,
    partiallyUsed,
    searchText,
  ]);

  const filteredDepositsLimited = useMemo(
    () => filteredDeposits.slice(0, 100),
    [filteredDeposits],
  );

  return {
    depositsSorted,
    filteredDeposits,
    filteredDepositsLimited,
    filters,
    loadingInProgress,
    viewParameters,
    setViewParameters,
    fetchCriteria,
  };
}

export type DepositsListPageLocalState = ReturnType<
  typeof useDepositsListPageLocalState
>;
