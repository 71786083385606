import { Booking, BookingMember } from '@mabadive/app-common-model';
import { SortableAttribute, dataSorter } from '@mabadive/app-common-services';
import {
  AggregatedBookingSessionFull,
  BookingTabModel,
  PRO_BookingResume,
} from '../../models';

export const proBookingSorter = {
  sortProBookingResumes,
  sortBookingTabModels,
};

// le bon tri des réservations est très important, pour afficher ou auto-sélectionner la bonne réservation suivant le contexte
// NOTE: voir aussi le tri de bookingTabModelBuilder (réchercher: REF_BOOKING_SORT)

function sortProBookingResumes(
  proBookingResumes: PRO_BookingResume[],
  {
    diveCenterId,
    focusDiverId,
  }: {
    diveCenterId: string;
    focusDiverId: string;
  },
) {
  const proBookingResumesSorted: PRO_BookingResume[] = dataSorter.sortMultiple(
    proBookingResumes,
    {
      getSortAttributes: (br: PRO_BookingResume) => {
        return getSortAttributesCore({
          diveCenterId,
          focusDiverId,
          booking: br?.booking,
          bookingMembers: br?.bookingMembers,
          bookingSessionsFull: br?.bookingSessionsFull,
        });
      },
      asc: true,
    },
  );

  return proBookingResumesSorted;
}

function sortBookingTabModels(
  tabModels: BookingTabModel[],
  {
    diveCenterId,
    focusDiverId,
  }: {
    diveCenterId: string;
    focusDiverId: string;
  },
) {
  return dataSorter.sortMultiple(tabModels, {
    getSortAttributes: (bookingTabModel: BookingTabModel) => {
      const ab = bookingTabModel?.aggregatedBooking;
      return getSortAttributesCore({
        diveCenterId,
        focusDiverId,
        booking: ab.booking,
        bookingMembers: ab.bookingMembers,
        bookingSessionsFull: ab.bookingSessionsFull,
      });
    },
    asc: true,
  });
}

function getSortAttributesCore({
  diveCenterId,
  focusDiverId,
  booking,
  bookingMembers,
  bookingSessionsFull,
}: {
  booking?: Booking;
  diveCenterId: string;
  focusDiverId: string;
  bookingMembers: BookingMember[];
  bookingSessionsFull: AggregatedBookingSessionFull[];
}): SortableAttribute[] {
  return [
    {
      // d'abord les résas actives (non archivées)
      value: booking?.active,
      asc: false, // 'true' avant 'false'
    },
    {
      // puis celles du centre de plongée actif
      value: booking.diveCenterId === diveCenterId || booking.isSharedBooking,
      asc: false,
    },
    {
      // puis celles qui contiennent le diver focus
      value:
        bookingMembers?.find((x) => x.diverId === focusDiverId) !== undefined,
      asc: false,
    },
    {
      // puis par date de plongée
      value: !bookingSessionsFull?.length
        ? null
        : // on met en premier les sessions les plus récentes (ou les plus loins dans le futur)
          Math.max(
            ...bookingSessionsFull.map((x) => x.diveSession?.time.getTime()),
          ),
      asc: false,
      //   nullFirst: false,
    },
    {
      // puis par date de résa
      value: booking?.bookingDate,
      type: 'full-text',
      asc: false,
    },
  ];
}
