/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import clsx from 'clsx';
import { AppToogleButtonGroupTailwind } from 'src/lib/form';
import { ClubSettingsProductOffersFiltersState } from '../useClubSettingsProductOffersFiltersState.hook';

export const ClubSettingsProductOffersFiltersArea = ({
  filtersState,
  className,
}: {
  filtersState: ClubSettingsProductOffersFiltersState;
  className?: string;
}) => {
  const { state } = filtersState;

  return state.divesCountsOptions.length > 1 ||
    state.supervisionOptions.length > 1 ||
    state.trainingCommercialCategoriesOptions.length > 1 ||
    state.organizationsOptions.length > 1 ? (
    <div
      className={clsx('grid gap-x-2', className)}
      onClick={(e) => e.stopPropagation()}
    >
      <div>
        {state.divesCountsOptions.length > 1 && (
          <AppToogleButtonGroupTailwind
            fullWidthMobile={false}
            customClassName="flex gap-x-4 flex-wrap"
            optionClassName="md:max-w-[200px] whitespace-nowrap"
            // className="hidden md:block lg:max-w-[50%]"
            // customClassName="flex flex-col sm:grid sm:grid-cols-4"
            selected={state.filters?.divesCount}
            onChanges={(divesCount) => {
              state.setFilters({
                ...state.filters,
                divesCount,
              });
            }}
            items={state.divesCountsOptions}
            color={'green'}
            theme={'light'}
            size={'sm'}
            allowUnset={true}
          />
        )}
      </div>
      <div>
        {state.trainingCommercialCategoriesOptions.length > 1 && (
          <AppToogleButtonGroupTailwind
            fullWidthMobile={false}
            customClassName="flex gap-x-4 flex-wrap"
            optionClassName="md:max-w-[200px] whitespace-nowrap"
            // className="hidden md:block lg:max-w-[50%]"
            // customClassName="flex flex-col sm:grid sm:grid-cols-4"
            selected={state.filters?.commercialCategoryId}
            onChanges={(commercialCategoryId) => {
              state.setFilters({
                ...state.filters,
                commercialCategoryId,
              });
            }}
            items={state.trainingCommercialCategoriesOptions}
            color={'green'}
            theme={'light'}
            size={'sm'}
            allowUnset={true}
          />
        )}
      </div>
      <div>
        {state.organizationsOptions.length > 1 && (
          <AppToogleButtonGroupTailwind
            fullWidthMobile={false}
            customClassName="flex gap-x-4 flex-wrap"
            optionClassName="md:max-w-[200px] whitespace-nowrap"
            // className="hidden md:block lg:max-w-[50%]"
            // customClassName="flex flex-col sm:grid sm:grid-cols-4"
            selected={state.filters?.orgRef}
            onChanges={(orgRef) => {
              state.setFilters({
                ...state.filters,
                orgRef,
              });
            }}
            items={state.organizationsOptions}
            color={'blue'}
            theme={'light'}
            size={'sm'}
            allowUnset={true}
          />
        )}
      </div>
      <div>
        {state.supervisionOptions.length > 1 && (
          <AppToogleButtonGroupTailwind
            fullWidthMobile={false}
            customClassName="flex gap-x-4 flex-wrap"
            optionClassName="md:max-w-[200px] whitespace-nowrap"
            // className="hidden md:block lg:max-w-[50%]"
            // customClassName="flex flex-col sm:grid sm:grid-cols-4"
            selected={state.filters?.supervision}
            onChanges={(supervision) => {
              state.setFilters({
                ...state.filters,
                supervision,
              });
            }}
            items={state.supervisionOptions}
            color={'blue'}
            theme={'light'}
            size={'sm'}
            allowUnset={true}
          />
        )}
      </div>
    </div>
  ) : null;
};
