import { JsonPatchOperation } from '@mabadive/app-common-model';
import { jsonPatcher, LoadableAttributeCollectionStore } from '@mabadive/app-common-services';
import { repositoryEntityBuilder } from '../../../repositoryEntityBuilder.service';
import { CollectionWritableStoreAdapter } from '../model';

export const collectionSimpleStoreWritableAdapterFactory = {
  createAdapter,
};

function createAdapter<T>(store: LoadableAttributeCollectionStore<T>): CollectionWritableStoreAdapter<T> {

  return {
    addOne,
    addMany,
    removeOne,
    patchOne,
    patchMany,
  };

  function addOne({ value, actionId }: {
    value: T;
    actionId?: string;
  }) {
    store.addOne({
      value,
      actionId,
    });
  }

  function addMany({ values, actionId }: {
    values: T[];
    actionId?: string;
  }) {
    store.addMany({
      values,
      actionId,
    });
  }

  function removeOne({ criteria, actionId }: {
    criteria: Partial<T>;
    actionId?: string;
  }) {

    return store.removeOne({
      identify: (item: T) => identifyByCriteria(criteria, item),
      actionId,
    });
  }

  function patchOne({
    patch,
    source,
    actionId,
  }: {
    patch: {
      criteria: Partial<T>;
      patchOperations: JsonPatchOperation[];
    };
    source: string;
    actionId: string;
  }) {
    const result: { original: T, patched: T } = {
      original: undefined,
      patched: undefined,
    };

    result.patched = store.reduceOne({
      identify: (item: T) => identifyByCriteria(patch.criteria, item),
      reduceFn: (item: T) => {
        result.original = item;
        return repositoryEntityBuilder.buildOneUpdate<T>({
          source,
          item: jsonPatcher.applyPatchOperations({
            ...item,
          }, patch.patchOperations),
        });
      },
      actionId,
    });

    return result;
  }

  function patchMany({
    patches,
    source,
    actionId,
  }: {
    patches: {
      criteria: Partial<T>;
      patchOperations: JsonPatchOperation[];
    }[];
    source: string;
    actionId: string;
  }): { original: T, patched: T }[] {

    const results: { original: T, patched: T }[] = [];

    store.reduceAll((items: T[]) => {
      return items.map(item => {
        const patch = patches.find(patch => identifyByCriteria(patch.criteria, item));
        if (patch) {
          const patched = repositoryEntityBuilder.buildOneUpdate<T>({
            source,
            item: jsonPatcher.applyPatchOperations({
              ...item,
            }, patch.patchOperations),
          });
          results.push({
            original: item,
            patched,
          })
          return patched;
        }
        return item;
      }, [] as T[]);
    }, actionId);

    return results;
  }

  function identifyByCriteria(criteria: Partial<T>, item: T): boolean {

    return Object.keys(criteria).reduce((match, attributeName) => {
      if (match) {
        const a1 = (item as any)[attributeName] as any;
        const a2 = (criteria as any)[attributeName] as any;
        return a1 === a2;
      }
      return match;
    }, true as boolean);
  }

}

