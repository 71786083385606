import {
  Club,
  ClubSettingsUIPlanning,
  DiveSession,
} from '@mabadive/app-common-model';
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ColoredTagAvatarCard } from 'src/business/_core/modules/layout';
import { participantTagsBuilder } from 'src/business/club/modules/club-planning/_components';
import {
  ParticipantGaz,
  ParticipantRebreather,
  ParticipantTargetDeep,
  ParticipantUnderwaterScooter,
} from '../../participant';
import { CalendarSessionWidthMode } from './CalendarSessionWidthMode.type';
import {
  CalendarParticipantCellDiver,
  CalendarParticipantCellParticipant,
} from './model';

export function CalendarParticipantCellName({
  settingsUIPlanning,
  club,
  diver,
  participant,
  diveSession,
  isPast,
  includePayment,
  widthMode,
  className,
}: {
  settingsUIPlanning: ClubSettingsUIPlanning;
  club: Pick<Club, 'participantTags' | 'reference'>;
  diver: CalendarParticipantCellDiver;
  participant: CalendarParticipantCellParticipant;
  diveSession: Pick<DiveSession, 'time' | 'status'>;
  isPast?: boolean;
  widthMode: CalendarSessionWidthMode;
  // context: 'planning-column'|'security-sheet'
  includePayment: boolean;
  className?: string;
}) {
  if (isPast === undefined) {
    isPast = false;
  }

  const clubReference = club.reference;

  const age = useMemo(
    () =>
      diver && diver.birthdate
        ? dateService.getAge(diver.birthdate, diveSession.time)
        : diver.age,
    [diveSession.time, diver],
  );

  const tags = useMemo(
    () =>
      !participant
        ? []
        : participantTagsBuilder.getParticipantTags({
            club,
            participant,
            diver,
            diveSession,
            includeComment: widthMode !== 'large',
            includeBookingState: true,
            includeSpecialDiveType: false,
            includeParticipantSessionNumber: true,
            includePayment,
            settingsUIPlanning,
          }),
    [
      club,
      diveSession,
      diver,
      includePayment,
      participant,
      settingsUIPlanning,
      widthMode,
    ],
  );

  const diveTypeTag = useMemo(() => {
    if (participant) {
      return participantTagsBuilder.getParticipantSpecialDiveTypeTag({
        participant,
      });
    }
  }, [participant]);

  const diveSessionStatus =
    participant && participant.bookingState.value === 'cancelled'
      ? 'cancelled'
      : diveSession.status;

  const isOpen = diveSessionStatus === 'on';

  const { firstName, lastName } = useMemo(
    () => nameFormatter.formatFullNameChunks(diver),
    [diver],
  );

  const namesLength = (firstName?.length ?? 0) + (lastName?.length ?? 0);

  const showGaz =
    participant.gaz?.gazType &&
    participant.gaz?.gazType !== 'air' &&
    widthMode !== 'large';

  const showRebreather =
    participant.equipment?.rebreather && widthMode !== 'large';

  const showUnderwaterScooter =
    participant.equipment?.underwaterScooter && widthMode !== 'large';

  const showTargetDeep = participant.targetDeep && widthMode !== 'large';
  const showAge = age > 0 && age < 18;
  const showTags = tags.length > 0 && widthMode !== 'tiny';

  const hasAnyExtraElements =
    showGaz ||
    showRebreather ||
    showUnderwaterScooter ||
    showTargetDeep ||
    showAge ||
    showTags;

  return (
    <div
      className={clsx(
        'truncate overflow-hidden',
        widthMode === 'tiny' || widthMode === 'small' ? 'pb-px' : 'pt-px',
        widthMode === 'tiny'
          ? 'text-app-xxs'
          : widthMode === 'large'
          ? ''
          : 'px-0.5',
        widthMode === 'large' && 'flex gap-1 flex-row-reverse justify-end',
        className,
      )}
    >
      {diver && (firstName || lastName) ? (
        <div
          className={clsx(
            'flex gap-x-0.5 truncate overflow-hidden',
            !hasAnyExtraElements && 'flex-wrap',
            namesLength > 18
              ? ' text-[.65rem] lg:text-[.7rem] xl:text-[.75rem] 2xl:text-[.8rem]'
              : ' text-[.7rem] lg:text-[.75rem] xl:text-[.8rem] 2xl:text-[.85rem]',
            !isOpen ? 'grayscale bg-gray-100 text-gray-500' : null,
          )}
        >
          {settingsUIPlanning.participantFirstNameBefore &&
            firstName?.length > 0 && (
              <span className={'whitespace-nowrap font-bold'}>{firstName}</span>
            )}
          {lastName?.length > 0 && (
            <span className={'whitespace-nowrap font-bold'}>{lastName}</span>
          )}
          {!settingsUIPlanning.participantFirstNameBefore &&
            firstName?.length > 0 && (
              <span className={'whitespace-nowrap font-bold'}>{firstName}</span>
            )}
        </div>
      ) : (
        <span className="diver-name hidden">x</span>
      )}

      {hasAnyExtraElements && (
        <div
          className={clsx(
            'flex md:-mt-0.5 gap-1 text-app-xxs lg:text-[.675rem] xl:text-[.7rem] 2xl:text-[.75rem]',
            widthMode === 'large' ? '' : 'justify-end',
          )}
        >
          {(showAge ||
            showGaz ||
            showRebreather ||
            showUnderwaterScooter ||
            showTargetDeep) && (
            <div
              className={`mt-px flex gap-x-1 ${
                !isOpen ? 'grayscale bg-gray-100 text-gray-500' : ''
              }`}
            >
              {showAge && (
                <span className=" text-app-blue font-bold">{`${age} ans`}</span>
              )}
              {showRebreather && (
                <ParticipantRebreather
                  rebreather={participant.equipment?.rebreather}
                  iconClass="w-4 h-4"
                />
              )}
              {showUnderwaterScooter && (
                <ParticipantUnderwaterScooter
                  underwaterScooter={participant.equipment?.underwaterScooter}
                  iconClass="w-4 h-4"
                />
              )}
              {showGaz && (
                <ParticipantGaz
                  gaz={participant.gaz}
                  mode="default"
                  showDetails={false}
                />
              )}
              {showTargetDeep && (
                <ParticipantTargetDeep
                  targetDeep={participant?.targetDeep}
                  mode="default"
                />
              )}
            </div>
          )}
          {(showTags || (diveTypeTag && widthMode === 'tiny')) && (
            <div
              className={clsx(
                'flex gap-0.5',
                // widthMode === 'large' ? 'gap-2' : 'gap-1',
              )}
            >
              {showTags &&
                tags.map((tag, i) => (
                  <ColoredTagAvatarCard
                    className={widthMode !== 'large' && 'hidden sm:flex'}
                    key={i}
                    tag={tag}
                    size={'small'}
                  />
                ))}
              {diveTypeTag && widthMode === 'tiny' && (
                <ColoredTagAvatarCard tag={diveTypeTag} size={'small'} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
