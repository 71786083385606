import {
  DIVE_SESSION_TYPES,
  DiveSessionType,
} from '@mabadive/app-common-model';
import { diveSessionTypeFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useDiveSessionTypesOptions({
  currentType,
  scope,
}: {
  currentType?: DiveSessionType;
  scope: 'all' | 'session-settings';
}): ValueLabel<DiveSessionType, string>[] {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const types = useMemo(
    () =>
      scope === 'all'
        ? DIVE_SESSION_TYPES
        : DIVE_SESSION_TYPES.filter(
            (x) =>
              x === currentType ||
              clubSettings.general.sessions.types.includes(x),
          ),
    [clubSettings.general.sessions.types, currentType, scope],
  );

  const options = useMemo(
    () =>
      types.map((x) => {
        const option: ValueLabel<DiveSessionType, string> = {
          value: x,
          label: diveSessionTypeFormatter.formatType(x, {
            diveModesConf: clubSettings?.ui?.diveMode,
          }),
        };
        return option;
      }),
    [clubSettings?.ui?.diveMode, types],
  );
  return options;
}
