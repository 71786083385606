/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  DIVE_CERTIFICATIONS_REFERENCE_MAP,
  DiveCertification,
  DiveCertificationReference,
  DiveMode,
  DiveTrainingReference,
  FirstDiveTrainingReference,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppFormControlRHF, AppInputBooleanRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import {
  useAutonomousCertificationOptions,
  useDiveModesOptions,
  useFirstDiveTrainingOptions,
  useInstructorCertificationOptions,
  useSupervisedCertificationOptions,
  useTrainingOptions,
} from 'src/pages/_components/options';

export const BookingParticipantDiveModeSpecificReference = ({
  form: inputForm,
  mode,
  context,
  onDiveModeChange,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  mode: 'create' | 'edit';
  context: 'clubParticipant' | 'clubDiver';
  onDiveModeChange?: (diveMode: DiveMode) => void;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const services = clubResume.clubSettings.services;

  // IMPORTANT: composant utilisé dans le basePathe d'un participant, ou bien d'un bookingMember
  // Pour que les types de react-form fonctionne, on force typescript à considérer
  // que c'est toujours "form:BookingParticipantEditorFormModel" et "basePath:'clubParticipant'""
  const form = inputForm as UseFormReturn<BookingParticipantEditorFormModel>;
  const basePath =
    context === 'clubParticipant'
      ? 'clubParticipant'
      : 'diver.defaultDiveConfig';
  const { control, setValue } = form;

  const [diveMode, autoSupervisedDiveMode] = useWatch({
    control,
    name: [
      `${basePath}.diveMode`,
      `${basePath}.autoSupervisedDetails.diveMode`,
    ],
  });

  const certificationReference = useWatch({
    control,
    name: `${basePath}.certificationReference`,
  });
  const mediaSize = useMediaSizeTailwind();

  const certification: DiveCertification = useMemo(() => {
    if (certificationReference) {
      return DIVE_CERTIFICATIONS_REFERENCE_MAP[certificationReference];
    }
  }, [certificationReference]);

  const diveModesOptions: ValueLabel<DiveMode>[] = useDiveModesOptions({
    currentDiveMode: diveMode,
    format: 'short-ref-label',
  });

  const firstDiveTrainingOptions: ValueLabel<FirstDiveTrainingReference>[] =
    useFirstDiveTrainingOptions(clubSettings, {
      scope: 'enabled',
    });

  const trainingOptions: ValueLabel<DiveTrainingReference>[] =
    useTrainingOptions(services);

  const supervisedCertificationOptions: ValueLabel<DiveCertificationReference>[] =
    useSupervisedCertificationOptions();

  const autonomousCertificationOptions: ValueLabel<DiveCertificationReference>[] =
    useAutonomousCertificationOptions();

  const instructorCertificationOptions: ValueLabel<DiveCertificationReference>[] =
    useInstructorCertificationOptions();

  return (
    <>
      <AppFormControlRHF
        className={clsx('w-full', className)}
        label={
          context === 'clubParticipant'
            ? 'Prestation'
            : mode === 'create'
            ? 'Prestation souhaitée'
            : 'Prestation par défaut'
        }
        control={control}
        name={`${basePath}.diveMode`}
        required={context === 'clubParticipant'}
        renderComponent={(props) => (
          <AppSingleAutocomplete2RHF
            {...props}
            options={diveModesOptions}
            onChange={(diveMode: DiveMode) => {
              onDiveModeChange && onDiveModeChange(diveMode);
            }}
          />
        )}
      />
      {diveMode === 'first-dive' && firstDiveTrainingOptions.length > 1 && (
        <AppFormControlRHF_Deprecated
          className={clsx('w-full', className)}
          label="Type de baptême"
          control={control}
          name={`${basePath}.firstDiveReference`}
          // required={diveMode === 'first-dive'}
          required={false}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={firstDiveTrainingOptions}
            />
          )}
        />
      )}
      {(diveMode === 'training' || diveMode === 'theoretical-training') && (
        <AppFormControlRHF_Deprecated
          className={clsx('w-full', className)}
          label="Formation"
          control={control}
          name={`${basePath}.trainingReference`}
          required={false} // 17/05/2022 : le niveau n'est plus obligatoire
          // required={
          //   diveMode === 'training' || diveMode === 'theoretical-training'
          // }
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF {...props} options={trainingOptions} />
          )}
        />
      )}
      {diveMode === 'supervised' && (
        <div className={clsx('w-full flex gap-2 items-center', className)}>
          <AppFormControlRHF_Deprecated
            className={' w-full'}
            label="Niveau"
            control={control}
            name={`${basePath}.certificationReference`}
            required={false} // 17/05/2022 : le niveau n'est plus obligatoire
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={supervisedCertificationOptions}
              />
            )}
          />
          {certification?.organizationv2?.orgGroupReferencev2 ===
            'international' && (
            <AppFormControlRHF_Deprecated
              label={
                mediaSize === 'xxs' ||
                mediaSize === 'xs' ||
                mediaSize === 'sm' ||
                mediaSize === 'md'
                  ? 'Deep D.'
                  : 'Deep Diver'
              }
              control={control}
              name={`${basePath}.certificationDeepDiver`}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />
          )}
        </div>
      )}
      {diveMode === 'autonomous' && (
        <div className={clsx('w-full flex gap-2 items-center', className)}>
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Niveau"
            control={control}
            name={`${basePath}.certificationReference`}
            required={false} // 17/05/2022 : le niveau n'est plus obligatoire
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={autonomousCertificationOptions}
              />
            )}
          />
          {certification?.organizationv2?.orgGroupReferencev2 ===
            'international' && (
            <AppFormControlRHF_Deprecated
              label={
                mediaSize === 'xxs' ||
                mediaSize === 'xs' ||
                mediaSize === 'sm' ||
                mediaSize === 'md'
                  ? 'Deep D.'
                  : 'Deep Diver'
              }
              control={control}
              name={`${basePath}.certificationDeepDiver`}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />
          )}
        </div>
      )}
      {diveMode === 'instructor' && (
        <div className={clsx('w-full flex gap-2 items-center', className)}>
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Niveau"
            control={control}
            name={`${basePath}.certificationReference`}
            required={false} // 17/05/2022 : le niveau n'est plus obligatoire
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={instructorCertificationOptions}
              />
            )}
          />
          {certification?.organizationv2?.orgGroupReferencev2 ===
            'international' && (
            <AppFormControlRHF_Deprecated
              label={
                mediaSize === 'xxs' ||
                mediaSize === 'xs' ||
                mediaSize === 'sm' ||
                mediaSize === 'md'
                  ? 'Deep D.'
                  : 'Deep Diver'
              }
              control={control}
              name={`${basePath}.certificationDeepDiver`}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  className="scale-125 block ml-0.5 mt-1 mb-1"
                  {...props}
                  type="checkbox"
                />
              )}
            />
          )}
        </div>
      )}
    </>
  );
};
