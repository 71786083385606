import React from 'react';
import { Control, Path, useController } from 'react-hook-form';
import { AppInputDatePickerProps } from './AppInputDatePicker';
import { AppInputDatePickerMaterial } from './AppInputDatePickerMaterial';

export function AppInputDatePickerMaterialRHF<T>({
  control,
  name,
  required,
  className,
  onChange: onChangeTrigger,
  ...extraProps
}: AppInputDatePickerProps & {
  control: Control<T>;
  name: Path<T>;
  required?: boolean;
}) {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<T>({
    name,
    control,
    rules: {
      required,
    },
  });

  return (
    <AppInputDatePickerMaterial
      {...extraProps}
      {...inputProps}
      // required={required}
      onChange={(newValue) => {
        if ((value as Date)?.getTime() !== newValue?.getTime()) {
          onChange(newValue);
          onChangeTrigger && onChangeTrigger(newValue);
        }
      }}
    />
  );
}
