/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettingsUIPlanning,
  ClubSettingsUIPlanningShowPassMode,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { uiStore } from 'src/business/_core/store';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  PlanningViewConfig,
  PlanningViewConfigContextId,
  PlanningViewConfigPeriod,
  PlanningViewPeriodDayOptions,
  PlanningViewPeriodMonthOptions,
  PlanningViewPeriodWeekOptions,
  PlanningViewSessionOptions,
} from '../../club-planning/_model';
import { clubPlanningUrlBuilder } from '../../url-builders';
import { clubPlanningLightPageUrlManager } from '../clubPlanningLightPageUrlManager.service';
import { useDefaultSettingsUiPlanning } from './useDefaultSettingsUiPlanning.hook';

export function useInitialPlanningConfig({
  enableRouteParams,
  context,
  defaultFocusDate,
}: {
  enableRouteParams: boolean;
  context: PlanningViewConfigContextId;
  defaultFocusDate?: Date;
}) {
  const mediaSize = useMediaSizeTailwind();

  const isMobile = mediaSize === 'xxs' || mediaSize === 'xs';

  const clubResume = useClubResume();

  const defaultSettings: ClubSettingsUIPlanning = useDefaultSettingsUiPlanning(
    {},
  );

  const clubDefaultOptions: {
    viewDayOptions: Pick<PlanningViewPeriodDayOptions, 'displayMode'>;
    viewWeekOptions: Pick<PlanningViewPeriodWeekOptions, 'displayMode'>;
  } = useMemo(() => {
    return {
      viewWeekOptions: {
        displayMode:
          context === 'planning-staff'
            ? 'staff-presence'
            : context === 'planning-view'
            ? 'full'
            : 'compact',
      },
      viewDayOptions: {
        displayMode:
          context === 'planning-staff' ? 'staff-presence' : 'before-dive',
      },
    };
  }, [context]);

  const initialPlanningConfig = useMemo(() => {
    const queryParams = enableRouteParams
      ? clubPlanningLightPageUrlManager.getQueryParameters()
      : undefined;

    const storeParams =
      context === 'planning-view'
        ? uiStore.planningViewParticipantsConfig.getSnapshot()
        : context === 'planning-staff'
        ? uiStore.planningViewStaffConfig.getSnapshot()
        : context === 'planning-selector'
        ? uiStore.planningSelectorConfig.getSnapshot()
        : undefined;

    const focusDate =
      queryParams?.focusDate ??
      defaultFocusDate ??
      dateService.getUTCDateSetTime(new Date());

    const focusSessionReference =
      queryParams?.focusSessionReference ?? storeParams?.focusSessionReference;

    const focusDateRef =
      clubPlanningUrlBuilder.buildFocusDateRefFromDate(focusDate);

    const viewPeriod: PlanningViewConfigPeriod =
      queryParams?.viewPeriod ??
      storeParams?.viewPeriod ??
      (isMobile ? 'day' : 'week');

    const viewDayOptions: PlanningViewPeriodDayOptions = {
      displayMode:
        queryParams?.viewDayOptions?.displayMode ??
        storeParams?.viewDayOptions?.displayMode ??
        (isMobile ? 'compact' : clubDefaultOptions.viewDayOptions.displayMode),
    };

    const viewWeekOptions: PlanningViewPeriodWeekOptions = {
      displayMode:
        queryParams?.viewWeekOptions?.displayMode ??
        storeParams?.viewWeekOptions?.displayMode ??
        (isMobile ? 'compact' : clubDefaultOptions.viewWeekOptions.displayMode),
    };

    const viewMonthOptions: PlanningViewPeriodMonthOptions = {};

    const viewSessionOptions: PlanningViewSessionOptions = {
      displayMode:
        queryParams?.viewSessionOptions?.displayMode ??
        storeParams?.viewSessionOptions?.displayMode ??
        'before-dive',
    };

    const showInactiveSessions: boolean =
      storeParams?.showInactiveSessions ?? defaultSettings.showInactiveSessions;

    const showFullSessions: boolean = storeParams?.showFullSessions ?? true;

    const showInactiveParticipants: boolean =
      storeParams?.showInactiveParticipants ??
      defaultSettings.showInactiveParticipants;

    const showPaymentFlag: boolean =
      storeParams?.showPaymentFlag ?? defaultSettings.showPaymentFlag;

    const showPaymentFlagPastOnly: boolean =
      storeParams?.showPaymentFlagPastOnly ??
      defaultSettings.showPaymentFlagPastOnly;

    const showPaymentFlagNotPayed: boolean =
      storeParams?.showPaymentFlagNotPayed ??
      defaultSettings.showPaymentFlagNotPayed;

    const showPaymentFlagNotPurchased: boolean =
      storeParams?.showPaymentFlagNotPurchased ??
      defaultSettings.showPaymentFlagNotPurchased;

    const showPaymentFlagNotPurchasedPass: boolean =
      storeParams?.showPaymentFlagNotPurchasedPass ??
      defaultSettings.showPaymentFlagNotPurchasedPass;
    const showPaymentFlagNotPurchasedPassMode: ClubSettingsUIPlanningShowPassMode =
      storeParams?.showPaymentFlagNotPurchasedPassMode ??
      defaultSettings.showPaymentFlagNotPurchasedPassMode;

    const showPaymentFlagTraining: boolean =
      storeParams?.showPaymentFlagTraining ??
      defaultSettings.showPaymentFlagTraining;

    const showPaymentFlagPayed: boolean =
      storeParams?.showPaymentFlagPayed ?? defaultSettings.showPaymentFlagPayed;

    const showStaff: boolean =
      storeParams?.showStaff ?? defaultSettings.showStaff;

    const showStaffCounts: boolean =
      storeParams?.showStaffCounts ?? defaultSettings.showStaffCounts;

    const showStaffCountsPerDay: boolean =
      storeParams?.showStaffCountsPerDay ??
      defaultSettings.showStaffCountsPerDay;

    const showStaffCountsPerDayDP: boolean =
      storeParams?.showStaffCountsPerDayDP ??
      defaultSettings.showStaffCountsPerDayDP;
    const showStaffCountsPerDayGP: boolean =
      storeParams?.showStaffCountsPerDayGP ??
      defaultSettings.showStaffCountsPerDayGP;
    const showStaffCountsPerDaySS: boolean =
      storeParams?.showStaffCountsPerDaySS ??
      defaultSettings.showStaffCountsPerDaySS;

    const displayGroupLevelDetails: boolean =
      storeParams?.displayGroupLevelDetails ??
      defaultSettings.displayGroupLevelDetails;
    const displayPastSessions: boolean =
      storeParams?.displayPastSessions ?? defaultSettings.displayPastSessions;
    const displayEmptySessionsPast: boolean =
      storeParams?.displayEmptySessionsPast ??
      defaultSettings.displayEmptySessionsPast;
    const displayEmptySessionsFuture: boolean =
      storeParams?.displayEmptySessionsFuture ??
      defaultSettings.displayEmptySessionsFuture;

    const participantFirstNameBefore: boolean =
      defaultSettings.participantFirstNameBefore;

    const staffFirstNameBefore: boolean = defaultSettings.staffFirstNameBefore;
    const staffPeriods = defaultSettings.staffPeriods;

    const planningConfig: PlanningViewConfig = {
      focusDate,
      focusDateRef,
      focusSessionReference,
      viewPeriod,
      viewDayOptions,
      viewWeekOptions,
      viewSessionOptions,
      viewMonthOptions,
      showInactiveSessions,
      showFullSessions,
      showInactiveParticipants,
      showStaff,
      showStaffCounts,
      showStaffCountsPerDay,
      showStaffCountsPerDayDP,
      showStaffCountsPerDayGP,
      showStaffCountsPerDaySS,
      participantFirstNameBefore,
      staffFirstNameBefore,
      showPaymentFlag,
      showPaymentFlagPastOnly,
      showPaymentFlagNotPayed,
      showPaymentFlagNotPurchased,
      showPaymentFlagNotPurchasedPass,
      showPaymentFlagNotPurchasedPassMode,
      showPaymentFlagTraining,
      showPaymentFlagPayed,
      displayGroupLevelDetails,
      displayPastSessions,
      displayEmptySessionsPast,
      displayEmptySessionsFuture,
      staffPeriods,
    };
    return planningConfig;
  }, [
    enableRouteParams,
    context,
    defaultFocusDate,
    isMobile,
    clubDefaultOptions.viewDayOptions.displayMode,
    clubDefaultOptions.viewWeekOptions.displayMode,
    defaultSettings.showInactiveSessions,
    defaultSettings.showInactiveParticipants,
    defaultSettings.showPaymentFlag,
    defaultSettings.showPaymentFlagPastOnly,
    defaultSettings.showPaymentFlagNotPayed,
    defaultSettings.showPaymentFlagNotPurchased,
    defaultSettings.showPaymentFlagNotPurchasedPass,
    defaultSettings.showPaymentFlagNotPurchasedPassMode,
    defaultSettings.showPaymentFlagTraining,
    defaultSettings.showPaymentFlagPayed,
    defaultSettings.showStaff,
    defaultSettings.showStaffCounts,
    defaultSettings.showStaffCountsPerDay,
    defaultSettings.showStaffCountsPerDayDP,
    defaultSettings.showStaffCountsPerDayGP,
    defaultSettings.showStaffCountsPerDaySS,
    defaultSettings.displayGroupLevelDetails,
    defaultSettings.displayPastSessions,
    defaultSettings.displayEmptySessionsPast,
    defaultSettings.displayEmptySessionsFuture,
    defaultSettings.participantFirstNameBefore,
    defaultSettings.staffFirstNameBefore,
    defaultSettings.staffPeriods,
  ]);
  return initialPlanningConfig;
}
