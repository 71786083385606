import { dateService } from '@mabadive/app-common-services';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { AppTitleDateNavigatorPeriodRange } from 'src/pages/_components/title-navigators/model';
import {
  DashboardReportPageUIConfig,
  DashboardReportPageViewParameters,
} from './model';
import { dashboardStatsPageUrlManager } from './services';

export function useDashboardReportPageViewParameters() {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;

  const query: DashboardReportPageViewParameters = useMemo(
    () => dashboardStatsPageUrlManager.getQueryParameters(),
    [],
  );

  const initialPeriodRange: AppTitleDateNavigatorPeriodRange = useMemo(() => {
    if (query.periodRange?.value?.selectedDate) {
      return query.periodRange;
    }
    // on ne récupère pas les données du cache, ce qui permet de toujours revenir à la date du jour par défaut
    // if (cache?.periodRange?.value?.selectedDate) {
    //   return cache?.periodRange;
    // }
    const selectedDate = dateService.getUTCDateWithoutTimeFromLocalTime(
      new Date(),
    );
    const [beginDate, endDate] = dateService.getMonthBoundsUTC(selectedDate);
    return {
      period: 'month',
      value: {
        selectedDate: selectedDate,
        beginDate,
        endDate,
      },
    };
  }, [query.periodRange]);

  const initialViewParameters: DashboardReportPageViewParameters =
    useMemo(() => {
      const uiConfig: DashboardReportPageUIConfig = {
        pageMode: query?.uiConfig?.pageMode ?? 'home',
        selection: query?.uiConfig?.selection,
      };
      const viewParameters: DashboardReportPageViewParameters = {
        periodRange: initialPeriodRange,
        uiConfig,
        showPeriodRangeComparison:
          (!!query?.showPeriodRangeComparison &&
            !!query?.periodRangeComparison?.value &&
            !!query?.periodRangeComparison?.period) ??
          false,
        ajustPeriodRangeToCurrentDate:
          query?.ajustPeriodRangeToCurrentDate ?? false,
        autoSyncPeriod: query?.autoSyncPeriod ?? 'none',
        periodRangeComparison: query?.periodRangeComparison,
      };
      return viewParameters;
    }, [
      query?.uiConfig?.pageMode,
      query?.uiConfig?.selection,
      query?.showPeriodRangeComparison,
      query?.periodRangeComparison,
      query?.ajustPeriodRangeToCurrentDate,
      query?.autoSyncPeriod,
      initialPeriodRange,
    ]);

  const [viewParameters, setViewParameters] = useState(initialViewParameters);

  const history = useHistory();
  useEffect(() => {
    // update url if criteria changes
    const searchParams: string[] =
      dashboardStatsPageUrlManager.buildSearchParams(viewParameters);

    history.replace({
      pathname: window.location.pathname,
      search: searchParams?.length ? `?${searchParams.join('&')}` : undefined,
    });
  }, [history, viewParameters]);

  return {
    viewParameters,
    setViewParameters,
  };
}
