import React from 'react';
import { AppTabsBarTab } from 'src/business/_core/modules/layout/components';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { DashboardTabId } from './DashboardTabId.type';

export const DASHBOARD_SPACE_TABS: AppTabsBarTab<DashboardTabId>[] = [
  {
    id: 'home',
    label: 'Accueil',
    labelShort: 'Accueil',
    icon: <AppHeroIcons.home className="h-6 w-6" />,
    urlSuffix: '',
    auth: { requiredRoles: ['club-view-dashboard-home'] },
  },
  {
    id: 'report',
    label: 'Statistiques',
    labelShort: 'Stats',
    urlSuffix: 'report',
    icon: <AppHeroIcons.stats className="h-6 w-6" />,
    auth: { requiredRoles: ['club-view-dashboard-stats'] },
  },
  {
    id: 'export',
    label: 'Export',
    labelShort: 'Export',
    urlSuffix: 'export',
    icon: <AppHeroIcons.actionDownload className="h-6 w-6" />,
    auth: {
      requiredRoles: ['club-view-dashboard-stats', 'club-download-bookings'],
    },
  },
];
