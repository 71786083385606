import { BookingJourney } from '@mabadive/app-common-model';
import { BookingGroupAndJourneyEditDialogFormModel } from '../pages/CreateBookingPages';

export const bookingDetailsFormBuilder = {
  fixForm,
};
function fixForm(
  formValue: BookingGroupAndJourneyEditDialogFormModel,
): BookingGroupAndJourneyEditDialogFormModel {
  const steps = (formValue.bookingJourney?.steps ?? []).filter(
    (x) =>
      !!x?.arrival?.date ||
      !!x?.departure?.date ||
      !!x?.accommodationId ||
      !!x?.pickup?.active,
  );

  const bookingJourney: BookingJourney = steps.length ? { steps } : undefined;
  const fixedForm: BookingGroupAndJourneyEditDialogFormModel = {
    ...formValue,
    bookingJourney,
  };
  return fixedForm;
}
