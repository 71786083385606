import { useForm, useWatch } from 'react-hook-form';
import { DiverPurchasePlanFormModel } from './components';
import { DiverPurchasePlanDialogLocalStateContext } from './useDiverPurchasePlanDialogLocalStateContext.service';
import { DiverPurchasePlanDialogLocalStateInitialData } from './useDiverPurchasePlanDialogLocalStateInitialData.service';

export function useDiverPurchasePlanDialogLocalStateFormContext({
  initialData,
  localContext,
}: {
  initialData: DiverPurchasePlanDialogLocalStateInitialData;
  localContext: DiverPurchasePlanDialogLocalStateContext;
}) {
  const { initialFormValue } = initialData;
  const form = useForm<DiverPurchasePlanFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const [
    unitPrice,
    totalPriceThirdPartyCollect,
    creditsInitialCount,
    validityStatus,
    unitQuantity,
    productPackageOfferReference,
    creditsAdditionalCount,
    consumedExternalCount,
    discountAmount,
    extraCosts,
  ] = useWatch({
    control,
    name: [
      'unitPrice',
      'totalPriceThirdPartyCollect',
      'creditsInitialCount',
      'validityStatus',
      'unitQuantity',
      'productPackageOfferReference',
      'creditsAdditionalCount',
      'consumedExternalCount',
      'discountAmount',
      'extraCosts',
    ],
  });

  const formWatch = {
    unitPrice,
    totalPriceThirdPartyCollect,
    creditsInitialCount,
    validityStatus,
    unitQuantity,
    productPackageOfferReference,
    creditsAdditionalCount,
    consumedExternalCount,
    discountAmount,
    extraCosts,
  };

  return { form, formWatch };
}

export type DiverPurchasePlanDialogLocalStateFormContext = ReturnType<
  typeof useDiverPurchasePlanDialogLocalStateFormContext
>;
