/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubProductPackageOfferView,
} from '@mabadive/app-common-model';
import React, { useCallback, useMemo, useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppPageContainer,
  AppPageContentContainer,
  AppTabsBar,
  AppTabsBarTab,
  SearchInput,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { AppActionButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import useRouter from 'use-react-router';
import { AppHamburgerMenu, AppHamburgerMenuItem } from '../../../_common/ui';
import { clubSettingsOffersUrlBuilder } from '../../../url-builders';
import { CLUB_OFFER_SPACE_TABS, ClubOfferTabId } from '../_common';
import { useOfferListPageLoadData } from '../_common/hooks';
import {
  ClubOfferProductEditor,
  ClubOfferProductEditorResult,
} from './ClubOfferProductEditor';
import { ClubOfferProductEditorFormModel } from './ClubOfferProductEditor/ClubOfferProductEditorFormModel.type';
import { clubOfferProductEditorResultBuilder } from './ClubOfferProductEditor/clubOfferProductEditorResultBuilder.service';
import { ClubOfferProductListPageState } from './ClubOfferProductListPageState.type';
import { ClubOfferProductMultiEditor } from './ClubOfferProductMultiEditor';
import { ClubOfferProductTable } from './ClubOfferProductTable';
export const ClubOfferProductListPage = (props: {}) => {
  const autoFocus = useAutoFocus();
  const { match } = useRouter();

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const [searchText, setSearchText] = useState('');

  const [state, setState] = useState<ClubOfferProductListPageState>({
    mode: 'view',
  });

  const isAdvancedEditEnabled = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const productPackageOffers = useOfferListPageLoadData({
    type: 'product',
    searchText,
    sortBy: 'dive',
  });
  const onCreateOrUpdateOffer = useCallback(
    async (result: ClubOfferProductEditorResult) => {
      setState({
        mode: 'view',
      });
      if (result) {
        await clubMassiveUpdatorClient.update({
          newProductPackageOffers: result.newOffer
            ? [result.newOffer]
            : undefined,
          updatedProductPackageOffers: result.updatedOffer
            ? [result.updatedOffer]
            : undefined,
        });
      }
    },
    [],
  );

  const hamburgerMenuItems = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    function addQuickCreateOfferMenu(
      formValue: ClubOfferProductEditorFormModel,
    ) {
      if (
        !productPackageOffers.find(
          (x) => x.productPackage.label === formValue.label,
        )
      ) {
        hamburgerMenuItems.push({
          title: formValue.label,
          description: `Ajouter ${formValue.label}`,
          icon: AppHeroIcons.actionAdd,
          onClick: () => {
            const offers: ClubOfferProductEditorResult =
              clubOfferProductEditorResultBuilder.buildNewOffer({
                clubReference,
                formValue,
              });
            onCreateOrUpdateOffer(offers);
          },
        });
      }
    }
    if (state.mode === 'view') {
      if (isAdvancedEditEnabled) {
        hamburgerMenuItems.push({
          id: 'create-offer',
          title: 'Ajouter',
          description: 'Ajouter une prestation',
          icon: AppHeroIcons.actionAdd,
          onClick: () => {
            setState({
              mode: 'create',
            });
          },
        });
      }
      hamburgerMenuItems.push({
        id: 'edit-offers',
        title: 'Modifier',
        description: 'Modifier les tarifs des prestations visibles',
        icon: AppHeroIcons.actionEdit,
        onClick: () => {
          setState({
            mode: 'edit-multi',
          });
        },
      });
      if (isAdvancedEditEnabled) {
        addQuickCreateOfferMenu({
          defaultOrganizationReference: 'ffessm',
          label: 'Licence FFESSM',
          productType: 'sale',
          salesCriteria: {
            favorite: true,
            standalone: true,
            extraCostTraining: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Plongée de nuit',
          productType: 'fee',
          salesCriteria: {
            favorite: true,
            extraCostSession: true,
            extraCostDivePlan: true,
            extraCostUnitDive: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Équipement',
          productType: 'rental',
          salesCriteria: {
            favorite: true,
            extraCostDivePlan: true,
            extraCostUnitDive: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Assurance',
          productType: 'sale',
          salesCriteria: {
            favorite: true,
            extraCostDivePlan: true,
            extraCostUnitDive: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Boutique',
          productType: 'sale',
          salesCriteria: {
            standalone: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Carnet de plongée',
          productType: 'sale',
          salesCriteria: {
            standalone: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Encadrement',
          productType: 'fee',
          salesCriteria: {
            favorite: true,
            extraCostDivePlan: true,
            extraCostUnitDive: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Nitrox',
          productType: 'fee',
          salesCriteria: {
            favorite: true,
            extraCostDivePlan: true,
            extraCostUnitDive: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Hébergement',
          productType: 'fee',
          salesCriteria: {
            standalone: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Gonflage',
          productType: 'fee',
          salesCriteria: {
            standalone: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Carte de niveau',
          productType: 'sale',
          salesCriteria: {
            favorite: true,
            extraCostTraining: true,
          },
          namingConfiguration: {},
        });
        addQuickCreateOfferMenu({
          label: 'Carte cadeau',
          productType: 'sale',
          salesCriteria: {
            standalone: true,
          },
          namingConfiguration: {},
        });
      }
    }
    return hamburgerMenuItems;
  }, [
    clubReference,
    isAdvancedEditEnabled,
    onCreateOrUpdateOffer,
    productPackageOffers,
    state.mode,
  ]);

  const quickAction: AppHamburgerMenuItem = useMemo(() => {
    {
      const action = hamburgerMenuItems.find((x) => x.id === 'create-offer');
      if (action && !action.hidden) {
        return action;
      }
    }
    {
      const action = hamburgerMenuItems.find((x) => x.id === 'edit-offers');
      if (action && !action.hidden) {
        return action;
      }
    }
  }, [hamburgerMenuItems]);

  const onDeleteOffer = useCallback(
    async (offer: ClubProductPackageOfferView) => {
      if (offer) {
        await clubMassiveUpdatorClient.update({
          deletedProductPackageOfferIds: [offer._id],
        });
      }
    },
    [],
  );
  const onConfirmOffersChanges = useCallback(
    async ({
      updatedProductPackageOffers,
    }: {
      updatedProductPackageOffers: AppEntityUpdatePatch[];
    }) => {
      setState({
        mode: 'view',
      });
      if (updatedProductPackageOffers.length) {
        await clubMassiveUpdatorClient.update({
          updatedProductPackageOffers,
        });
      }
    },
    [],
  );
  return (
    <AppPageContainer className={'bg-white'}>
      {/* <ClubOfferBreadcrumbBar /> */}

      <AppTabsBar
        theme="settings"
        tabs={CLUB_OFFER_SPACE_TABS}
        value="offers-product"
        onChange={(tab) => selectTab(tab)}
      />

      <AppPageContentContainer className="bg-white app-px-content">
        {state.mode === 'view' && (
          <>
            <div className={`${globalClasses.pageBlock} flex`}>
              <SearchInput
                className="flex-grow"
                initialValue={searchText}
                autoFocus={autoFocus}
                onSearchChange={(text) => setSearchText(text)}
                placeholder="Recherche"
              />
              {quickAction && (
                <AppActionButton className="ml-2" action={quickAction} />
              )}
              {(!quickAction || hamburgerMenuItems.length !== 1) && (
                <AppHamburgerMenu
                  buttonClassName="ml-2 py-3 bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white"
                  position="left-start"
                  items={hamburgerMenuItems}
                  buttonIcon={AppHeroIcons.actionMenu}
                  buttonLabel={'Prestations'}
                />
              )}
            </div>

            <div className={globalClasses.pageBlock}>
              <ClubOfferProductTable
                productPackageOffers={productPackageOffers}
                setState={setState}
                onDelete={onDeleteOffer}
              />
            </div>
          </>
        )}
        {(state.mode === 'create' ||
          state.mode === 'edit-single' ||
          state.mode === 'duplicate-single') && (
          <>
            <div className={globalClasses.pageBlock}>
              <ClubOfferProductEditor
                onCancel={() =>
                  setState({
                    mode: 'view',
                  })
                }
                context={{
                  mode:
                    state.mode === 'create'
                      ? 'create'
                      : state.mode === 'duplicate-single'
                      ? 'duplicate'
                      : 'edit',
                  offer: state.selectedOffer,
                }}
                onConfirm={(offer) => onCreateOrUpdateOffer(offer)}
              />
            </div>
          </>
        )}
        {state.mode === 'edit-multi' && (
          <>
            <div className={globalClasses.pageBlock}>
              <ClubOfferProductMultiEditor
                productPackageOffers={productPackageOffers}
                onCancel={() =>
                  setState({
                    mode: 'view',
                  })
                }
                onConfirm={({ updatedProductPackageOffers }) =>
                  onConfirmOffersChanges({ updatedProductPackageOffers })
                }
              />
            </div>
          </>
        )}
      </AppPageContentContainer>
    </AppPageContainer>
  );

  function selectTab(tab: AppTabsBarTab<ClubOfferTabId>) {
    return redirectTo(
      clubSettingsOffersUrlBuilder.buildTabUrl({
        tabId: tab.id,
      }),
    );
  }
};
