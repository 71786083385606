import { AppEmojiData } from '@mabadive/app-common-model';
import { EmojiSet, Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import React, { useState } from 'react';
import { DEFAULT_EMOJI_SET } from './DEFAULT_EMOJI_SET.const';

const FAVOURITES = [
  // poissons & animaux
  'fish',
  'blowfish',
  'shark',
  'dolphin',
  'tropical_fish',
  'whale',
  'whale2',
  'octopus',
  'shell',
  'snail',
  'bug',
  'turtle',
  'crocodile',
  // activités

  'man-swimming',
  'woman-swimming',
  // bateaux
  'boat',
  'speedboat',
  'canoe',
  'ferry',
  'motor_boat',
  'man-rowing-boat',
  'woman-rowing-boat',
  'passenger_ship',
  // objets
  'diving_mask',
  'anchor',
  // météo
  'crescent_moon',
  'new_moon_with_face',
  'sunny',
  'rain_cloud',
  'tornado',
  'cyclone',
  'ocean',
  // autres
  'ok_hand',
  'heart',
  'blue_heart',
  'orange_heart',
  'yellow_heart',
  'purple_heart',
  'birthday',
  'cake',
];

export type AppEmojiPickerProps = {
  value?: string;
  onChange?: (emojiId: string, emoji: AppEmojiData) => void;
  set?: EmojiSet;
  className?: string;
};

export const AppEmojiPicker = ({
  value = '',
  onChange,
  set = DEFAULT_EMOJI_SET,
  className,
}: AppEmojiPickerProps) => {
  const [favourites, setFavourites] = useState(FAVOURITES);
  return (
    <div className={className}>
      <Picker
        title=""
        // native={true}
        set={set}
        i18n={{
          search: 'Recherche',
          categories: { search: 'Résultats de recherche', recent: 'Favoris' },
        }}
        emoji={value ?? ''}
        recent={favourites}
        onSelect={(emojiData) => {
          setFavourites(favourites.concat([emojiData?.id]));
          onChange && onChange(emojiData?.id, emojiData);
        }}
      />
    </div>
  );
};
