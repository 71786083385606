/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubStaffMemberRole,
  DiveSessionStaffMemberTableModelStaffMember,
} from '@mabadive/app-common-model';
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { StaffLevelBadge } from 'src/business/_core/modules/layout';
import {
  DiveSessionStaffMembersTableRowStaffRoles,
  DiveSessionStaffMembersTableSession,
} from '../../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components';

export function DiveSessionSecuritySheetCardStaffMembersTableRowStaff({
  isMultiSessionConfigForStaff,
  staffFirstNameBefore,
  staffMemberFull,
  availableRoles,
  date,
  className = '',
}: {
  isMultiSessionConfigForStaff: boolean;
  staffFirstNameBefore: boolean;
  staffMemberFull: DiveSessionStaffMemberTableModelStaffMember;
  availableRoles: ClubStaffMemberRole[];
  date: Date;
  className?: string;
}) {
  const { staffMember, session1, session2, meta, dailyAvailabilityResume } =
    staffMemberFull;

  const isPast = !dateService.isTodayUTC(date) && dateService.isPastUTC(date);

  return (
    <tbody className={`cursor-pointer ${className}`}>
      <tr
        className={`${
          meta.active
            ? 'text-app-blue'
            : meta.available
            ? 'text-gray-600'
            : 'text-gray-400'
        }`}
      >
        <td className={'px-2 py-1 whitespace-nowrap text-sm'}>
          <div
            className={'flex whitespace-nowrap flex-wrap items-center gap-1'}
          >
            <StaffLevelBadge
              className="rounded px-0.5 w-9 text-xs flex-shrink-0"
              staffMember={staffMember}
              date={date}
            />
            <span className={'truncate font-bold flex-shrink'}>
              {nameFormatter.formatFullName(staffMember.profile, {
                format: staffFirstNameBefore
                  ? 'firstName-first'
                  : 'lastName-first',
              })}
            </span>
          </div>
        </td>
        <td
          className={
            'px-4 py-1 whitespace-nowrap text-sm hidden sm:table-cell text-center'
          }
        >
          <DiveSessionStaffMembersTableRowStaffRoles
            className="w-full"
            isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
            staffMemberFull={staffMemberFull}
            availableRoles={availableRoles}
          />
        </td>
        <td
          className={'px-4 py-1 whitespace-nowrap hidden sm:table-cell text-xs'}
        >
          <DiveSessionStaffMembersTableSession
            showRoles={false}
            session={session1}
          />
        </td>
        {isMultiSessionConfigForStaff && (
          <td
            className={
              'px-4 py-1 whitespace-nowrap hidden sm:table-cell text-xs'
            }
          >
            <DiveSessionStaffMembersTableSession
              showRoles={false}
              session={session2}
            />
          </td>
        )}
      </tr>
      <tr className={'sm:hidden'}>
        <td colSpan={5} className={'px-2 py-1 whitespace-nowraptext-xs'}>
          <div className="flex gap-2">
            <DiveSessionStaffMembersTableRowStaffRoles
              className="w-full"
              isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
              staffMemberFull={staffMemberFull}
              availableRoles={availableRoles}
            />
            <div className="flex justify-between">
              <DiveSessionStaffMembersTableSession
                showRoles={false}
                session={session1}
              />
              {isMultiSessionConfigForStaff && (
                <DiveSessionStaffMembersTableSession
                  showRoles={false}
                  session={session2}
                />
              )}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
}
