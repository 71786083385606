import { AppInquiryType } from '@mabadive/app-common-model';
import { inquiryFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIconsInquiry } from '../../../../../../business/_core/modules/layout/icons';

export const AppInquiryTypeLabel = ({
  type,
  label,
  displayType = true,
  displayLabel = true,
  className,
}: {
  type: AppInquiryType;
  label?: string;
  displayType?: boolean;
  displayLabel?: boolean;
  className?: string;
}) => {
  const hasLabel = useMemo(() => label?.trim()?.length > 0, [label]);

  const InquiryIcon = AppIconsInquiry[type];

  return (displayType && type) || (displayLabel && hasLabel) ? (
    <div
      className={clsx(
        'font-bold flex gap-1 items-center bg-white',
        type === 'medical-form'
          ? 'text-green-600'
          : type === 'parental-consent'
          ? 'text-blue-600'
          : 'text-gray-600',
        className,
      )}
    >
      {InquiryIcon && <InquiryIcon className={'shrink-0 w-5 h-5'} />}
      {displayType && type && (
        <div className="">
          {inquiryFormatter.formatInquiryType(type).toUpperCase()}
        </div>
      )}
      <div className="max-w-[80px] truncate">{displayLabel && label}</div>
    </div>
  ) : null;
};
