/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubResumeBoat,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ClubBoatLabel } from 'src/pages/_components/company';

export const ClubBoatsTableRow = ({
  clubBoat,
  onClick,
}: {
  clubBoat: ClubResumeBoat;
  onClick?: () => void;
}) => {
  const clubResume = useClubResume();

  return (
    <tr className={'cursor-pointer'} onClick={onClick}>
      <td
        className={`px-2 py-0.5 whitespace-nowrap uppercase font-bold ${
          clubBoat.enabled ? '' : 'text-red-400'
        }`}
      >
        <ClubBoatLabel clubBoat={clubBoat} />
        <div className="text-xs text-gray-500 font-normal lg:hidden">
          {clubBoat.type} {clubBoat.type && clubBoat.immatriculation && '/'}{' '}
          {clubBoat.immatriculation}
        </div>
        <div className="text-xs text-gray-500 font-normal lg:hidden">
          {clubBoat.description}
        </div>
      </td>
      <td className={'hidden sm:table-cell px-2 py-0.5 whitespace-nowrap'}>
        {clubBoat.maxPassengers}{' '}
        {(clubBoat.maxPassengers === 0 || clubBoat.maxPassengers > 0) && (
          <span className="text-xs font-gray-400">pers.</span>
        )}{' '}
        {clubBoat.maxTanks}{' '}
        {(clubBoat.maxTanks === 0 || clubBoat.maxTanks > 0) && (
          <span className="text-xs font-gray-400">blocs</span>
        )}
      </td>
      <td
        className={
          'hidden sm:table-cell px-2 py-0.5 whitespace-nowrap uppercase text-center'
        }
      >
        {clubBoat.isDefault ? (
          <AppHeroIcons.check className="w-6 h-6 text-status-success" />
        ) : null}
      </td>
      <td
        className={
          'hidden lg:table-cell px-2 py-0.5 text-xs font-normal text-gray-500'
        }
      >
        <div>
          {clubBoat.type} {clubBoat.type && clubBoat.immatriculation && '/'}{' '}
          {clubBoat.immatriculation}
        </div>
        <div>{clubBoat.description}</div>
      </td>
      <td className={'px-2 py-0.5 text-right'}>
        {clubBoat.enabled ? (
          <button
            type="button"
            title="Supprimer ce bateau"
            className="p-1 bg-gray-200 hover:bg-red-400 text-white rounded-full"
            onClick={async (e) => {
              e.stopPropagation();
              if (
                await confirmDialog.confirmPromise({
                  title: 'Supprimer ce bateau',
                  message: 'Êtes-vous sûr de vouloir supprimer ce bateau?',
                  type: 'noYesDanger',
                })
              ) {
                await clubMassiveUpdatorClient.update({
                  deletedClubBoatsIds: [clubBoat._id],
                });
              }
            }}
          >
            <AppHeroIcons.actionDelete className="h-6 w-6 " />
          </button>
        ) : (
          <button
            type="button"
            title="Ré-activer ce bateau"
            className="p-1 bg-gray-400 hover:bg-green-400 text-white rounded-full"
            onClick={async (e) => {
              e.stopPropagation();
              if (
                await confirmDialog.confirmPromise({
                  title: 'Ré-activer ce bateau',
                  message:
                    'Êtes-vous sûr de vouloir activer ce bateau archivé?',
                  type: 'noYesDanger',
                })
              ) {
                const patchOperations = jsonPatcher.compareObjects(
                  clubBoat,
                  {
                    ...clubBoat,
                    enabled: true,
                  },
                  {},
                );
                if (patchOperations.length) {
                  const patch: AppEntityUpdatePatch = {
                    pk: clubBoat._id,
                    patchOperations,
                  };
                  await clubMassiveUpdatorClient.update({
                    updatedClubBoats: [patch],
                  });
                }
              }
            }}
          >
            <AppHeroIcons.actionConfirm className="h-6 w-6 " />
          </button>
        )}
      </td>
    </tr>
  );
};
