import {
  ClubParticipantSpecialDiveType,
  ClubProductPackageOfferView,
  ClubProductPackageType,
  DIVE_SERVICE_TRAINING_COMMERCIAL_CATEGORIES_SORT_ORDER,
  DIVE_SESSION_THEMES,
  DIVE_TRAININGS_REFERENCE_MAP,
  DiveMode,
  DiveServiceOfferEquipment,
  DiveServiceOfferSupervision,
  DiveServiceTrainingType,
  DiveSessionTheme,
  DiveTraining,
  PARTICIPANT_SPECIAL_DIVE_TYPES,
} from '@mabadive/app-common-model';
import { SortableAttribute } from '../../../data';
import { diveServiceOrganizationReferenceSorter } from '../../offer/diveServiceOrganizationReferenceSorter.service';

export const clubProductPackageOfferViewSortAttributesBuilder = {
  getSortAttributes,
};

function getSortAttributes(
  offer: ClubProductPackageOfferView,
  {
    mode,
  }: {
    mode: 'dive' | 'training';
  },
): SortableAttribute[] {
  const {
    diveAttributes,
    productAttributes,
    salesCriteria,
    trainingAttributes,
  } = offer.productPackage;
  let attributes: SortableAttribute[] = [
    {
      value: buildClubProductPackageTypeSortReference(
        offer.productPackage.type,
      ),
    },
    {
      value: buildDiveModeGroupSortReference(diveAttributes?.diveMode, {
        groupRecreationalDives: true,
      }),
    },
  ];

  if (mode === 'training') {
    const diveTrainingReference = trainingAttributes?.diveTrainingReference;
    const diveTraining: DiveTraining =
      DIVE_TRAININGS_REFERENCE_MAP[diveTrainingReference];

    attributes = attributes.concat([
      {
        value: DIVE_SERVICE_TRAINING_COMMERCIAL_CATEGORIES_SORT_ORDER.indexOf(
          diveTraining?.commercialCategoryId,
        ),
      },
      {
        value: buildTrainingTypeSortReference(trainingAttributes?.trainingType),
      },
      // {
      //   value: diveServiceOrganizationReferenceSorter.buildOrgSortReference(
      //     productAttributes?.defaultOrganizationReference,
      //   ),
      // },
      {
        value: trainingAttributes?.diveTrainingReference,
      },
    ]);
  } else {
    attributes = attributes.concat([
      {
        value: diveAttributes?.successiveDivesCount ?? 1,
      },
      {
        value: diveAttributes?.divesCount ?? 1,
      },
    ]);
  }

  attributes = attributes.concat([
    {
      value: buildDiveSessionThemeSortReference(
        salesCriteria?.diveSessionTheme,
      ),
    },
    {
      value: buildSpecialDiveTypeSortReference(diveAttributes?.specialDiveType),
    },
    {
      value: buildSupervisionSortReference(diveAttributes?.supervision),
    },
    {
      value: buildEquipmentSortReference(diveAttributes?.equipment),
    },
    {
      value: diveAttributes?.minDistance ?? 0,
    },
    {
      value: diveAttributes?.maxDistance ?? 0,
    },
    {
      // on a déjà un tri par buildDiveModeGroupSortReference auparavant
      value: buildDiveModeSortReference(diveAttributes?.diveMode),
    },
    {
      value: productAttributes?.minAge ?? 0,
    },
    {
      value: productAttributes?.maxAge ?? 0,
    },
    {
      value: diveAttributes?.diveSingleAttributes?.minDivesCount ?? 0,
    },
    {
      value: diveAttributes?.diveSingleAttributes?.maxDivesCount ?? 0,
    },
    {
      value: diveAttributes?.minDepth ?? 0,
    },
    {
      value: diveAttributes?.maxDepth ?? 0,
    },
    {
      value: diveServiceOrganizationReferenceSorter.buildOrgSortReference(
        productAttributes?.defaultOrganizationReference,
      ),
    },
    {
      value: productAttributes?.certificationReference,
    },
    {
      value: diveAttributes?.successiveDivesCount ?? 1,
    },
    {
      value: diveAttributes?.divesCount ?? 1,
    },
    {
      value: productAttributes?.sharedOfferMaxPersonsCount ?? 1,
    },
    {
      value: offer.price,
    },
    {
      value: offer.reference,
      type: 'full-text',
    },
  ]);
  return attributes;
}

function buildTrainingTypeSortReference(trainingType: DiveServiceTrainingType) {
  switch (trainingType) {
    case 'child':
      return '1';
    case 'main-certification':
      return '2';
    case 'gaz-certification':
      return '3';
    case 'security-certification':
      return '4';
    case 'equipment-certification':
      return '5';
  }
}

function buildClubProductPackageTypeSortReference(
  type: ClubProductPackageType,
) {
  switch (type) {
    case 'dive':
      return '1';
    case 'training':
      return '2';
  }
}

function buildEquipmentSortReference(equipment: DiveServiceOfferEquipment) {
  switch (equipment) {
    case 'any-equipment':
      return '1';
    case 'not-equipped':
      return '2';
    case 'partially-equipped':
      return '3';
    case 'equipped':
      return '4';
  }
}

function buildSupervisionSortReference(
  supervision: DiveServiceOfferSupervision,
) {
  switch (supervision) {
    case 'any-supervision':
      return '1';
    case 'supervised':
      return '2';
    case 'autonomous':
      return '3';
  }
}

function buildDiveModeSortReference(diveMode: DiveMode) {
  switch (diveMode) {
    case 'observer':
      return '01';
    case 'snorkeling':
      return '02';
    case 'snorkelingSupervised':
      return '03';
    case 'watchingTour':
      return '04';
    case 'free-dive':
      return '05';
    case 'first-dive':
      return '06';
    case 'training':
      return '07';
    case 'supervised':
      return '08';
    case 'autonomous':
      return '09';
    case 'instructor':
      return '10';
    case 'theoretical-training':
      return '11';
    case 'autoSupervised':
      return '12';
    case 'unknown':
      return '13';
  }
}
function buildDiveSessionThemeSortReference(theme: DiveSessionTheme) {
  if (theme) {
    return `${DIVE_SESSION_THEMES.indexOf(theme) + 1}`;
  }
  return '0';
}
function buildSpecialDiveTypeSortReference(
  specialDiveType: ClubParticipantSpecialDiveType,
) {
  if (specialDiveType) {
    return `${PARTICIPANT_SPECIAL_DIVE_TYPES.indexOf(specialDiveType) + 1}`;
  }
  return '0';
}
function buildDiveModeGroupSortReference(
  diveMode: DiveMode,
  { groupRecreationalDives }: { groupRecreationalDives: boolean },
) {
  switch (diveMode) {
    case 'observer':
      return '01';
    case 'snorkeling':
      return '02';
    case 'snorkelingSupervised':
      return '03';
    case 'watchingTour':
      return '04';
    case 'free-dive':
      return '05';
    case 'first-dive':
      return '06';
    case 'training':
    case 'supervised': {
      if (!groupRecreationalDives) {
        return '07';
      }
      return '09';
    }
    case 'autonomous': {
      if (!groupRecreationalDives) {
        return '08';
      }
      return '09';
    }
    case 'instructor':
      return '09';
    case 'theoretical-training':
      return '10';
    case 'autoSupervised':
      return '11';
    case 'unknown':
      return '12';
  }
}
