/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubSettingsSection } from '../../../../../../_core';
import { ClubSettingsOnlineBookingEmailsViewPanel_EmailOption } from './ClubSettingsOnlineBookingEmailsViewPanel_EmailOption';

export const ClubSettingsOnlineBookingEmailsViewPanel_02 = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const emailsSettings = clubSettings?.general?.onlineBooking?.emails;
  return (
    <>
      <ClubSettingsSection
        title="Mail 2 - à la confirmation de commande par le PRO"
        comment="Prévenez vos clients que leur réservation est confirmée"
      >
        <div className="grid gap-4">
          <ClubSettingsOnlineBookingEmailsViewPanel_EmailOption
            label="Lorsque vous confirmez une réservation en ligne"
            emailOption={emailsSettings?.onProConfirmBooking}
          />
        </div>
      </ClubSettingsSection>
    </>
  );
};
