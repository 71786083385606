import { DashboardParticipantsByPeriod } from '@mabadive/app-common-model';
import {
  diveModeFormatter,
  numberFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { ClubDashboardGraphqlQueryByIntervalCriteriaStepInterval } from 'src/pages/DA-dashboard/_core/model';
import { chartHelper } from 'src/pages/DA-dashboard/_core/services';
import { useClubSettings } from '../../../../../../../../business/club/data/hooks';
import { EvolutionParticipantsByDayBarChartAttribute } from '../model';
import { EvolutionParticipantsByDayBarChartConfigVisibility } from './EvolutionParticipantsByDayBarChart';
import {
  DashboardStatsDiveModeColors,
  useDashboardStatsDiveModeColors,
} from './useDashboardStatsDiveModeColors.hook';

type ChartAttribute = EvolutionParticipantsByDayBarChartAttribute;

export const evolutionParticipantsByDayBarChartTooltipBuilder = { build };

function build({
  colors,
  stepInterval,
  visibility,
  className,
}: {
  colors: DashboardStatsDiveModeColors;
  stepInterval: ClubDashboardGraphqlQueryByIntervalCriteriaStepInterval;
  visibility: EvolutionParticipantsByDayBarChartConfigVisibility;
  className?: string;
}) {
  return ({
    active,
    payload: data,
    label,
  }: {
    active?: boolean;
    payload?: any[];
    label?: any;
  }) => {
    if (active && data && data.length) {
      const payload: DashboardParticipantsByPeriod = data[0].payload;

      const {
        totalCount,
        cancelCountDiver,
        cancelCountClub,
        firstDiveCount,
        trainingCount,
        recreationalAutonomousCount,
        recreationalSupervisedCount,
        recreationalInstructorsCount,
        autoSupervisedCount,
        watchingTourCount,
        otherCount,
      } = payload;

      const recreationalCount =
        recreationalAutonomousCount +
        recreationalSupervisedCount +
        recreationalInstructorsCount;

      const visibilityRecreationalCount =
        visibility.recreationalAutonomousCount ||
        visibility.recreationalSupervisedCount ||
        visibility.recreationalInstructorsCount;

      const clubSettings = useClubSettings();

      const statsDiveModeColors: DashboardStatsDiveModeColors =
        useDashboardStatsDiveModeColors(clubSettings);

      return (
        <div
          className={clsx(
            'overflow-hidden rounded-lg bg-white text-gray-600 p-2 shadow px-4',
            className,
          )}
        >
          <p className="text-xl font-bold">
            {chartHelper.formatDatePeriod(payload.beginDate, {
              stepInterval,
              format: 'long',
            })}
          </p>
          <table className="mt-2 uppercase bg-gray-50 min-w-full border-2 border-gray-600 divide-y-2 divide-x-2 divide-gray-600 text-left">
            <tbody>
              {visibility.firstDiveCount && (
                <tr>
                  <th
                    className="px-3 font-bold text-white"
                    style={{ backgroundColor: colors.firstDiveCount }}
                  >
                    Baptêmes:
                  </th>

                  <td
                    className="px-3 font-bold text-right"
                    style={{ color: colors.firstDiveCount }}
                  >
                    {firstDiveCount}
                  </td>
                  <td
                    className="px-3 font-bold text-right"
                    style={{ color: colors.firstDiveCount }}
                  >
                    {numberFormatter.percent(firstDiveCount, totalCount)}%
                  </td>
                </tr>
              )}
              {visibility.trainingCount && (
                <tr>
                  <th
                    className="px-3 font-bold text-white"
                    style={{ backgroundColor: colors.trainingCount }}
                  >
                    Formations:
                  </th>
                  <td
                    className="px-3 font-bold text-right"
                    style={{ color: colors.trainingCount }}
                  >
                    {trainingCount}
                  </td>
                  <td
                    className="px-3 font-bold text-right"
                    style={{ color: colors.trainingCount }}
                  >
                    {numberFormatter.percent(trainingCount, totalCount)}%
                  </td>
                </tr>
              )}
              {visibility.recreationalSupervisedCount && (
                <tr>
                  <th
                    className="px-3 font-bold text-white"
                    style={{
                      backgroundColor: colors.recreationalSupervisedCount,
                    }}
                  >
                    Encadrés:
                  </th>
                  <td
                    className="px-3 font-bold text-right"
                    style={{ color: colors.recreationalSupervisedCount }}
                  >
                    <div className="text-right">
                      {recreationalSupervisedCount}
                    </div>
                  </td>
                  <td
                    className="px-3 font-bold text-right"
                    style={{ color: colors.recreationalSupervisedCount }}
                  >
                    {numberFormatter.percent(
                      recreationalSupervisedCount,
                      totalCount,
                    )}
                    %
                  </td>
                </tr>
              )}
              {visibility.recreationalAutonomousCount && (
                <tr>
                  <th
                    className="px-3 font-bold text-white"
                    style={{
                      backgroundColor: colors.recreationalAutonomousCount,
                    }}
                  >
                    Autonomes:
                  </th>
                  <td
                    className="px-3 font-bold text-right"
                    style={{ color: colors.recreationalAutonomousCount }}
                  >
                    <div className="text-right">
                      {recreationalAutonomousCount}
                    </div>
                  </td>
                  <td
                    className="px-3 font-bold text-right"
                    style={{ color: colors.recreationalAutonomousCount }}
                  >
                    {numberFormatter.percent(
                      recreationalAutonomousCount,
                      totalCount,
                    )}
                    %
                  </td>
                </tr>
              )}
              {visibility.recreationalInstructorsCount &&
                recreationalInstructorsCount > 0 && (
                  <tr>
                    <th
                      className="px-3 font-bold text-white"
                      style={{
                        backgroundColor: colors.recreationalInstructorsCount,
                      }}
                    >
                      Guides de palanquée:
                    </th>
                    <td
                      className="px-3 font-bold text-right"
                      style={{ color: colors.recreationalInstructorsCount }}
                    >
                      <div className="text-right">
                        {recreationalInstructorsCount}
                      </div>
                    </td>
                    <td
                      className="px-3 font-bold text-right"
                      style={{ color: colors.recreationalInstructorsCount }}
                    >
                      {numberFormatter.percent(
                        recreationalInstructorsCount,
                        totalCount,
                      )}
                      %
                    </td>
                  </tr>
                )}
              {visibilityRecreationalCount && (
                <tr>
                  <th
                    className="px-3 font-bold text-white"
                    style={{
                      backgroundColor: colors.recreationalTotalCount,
                    }}
                  >
                    TOTAL explos:
                  </th>
                  <td
                    className="px-3 font-bold text-right"
                    style={{
                      color: colors.recreationalTotalCount,
                    }}
                  >
                    <div className="text-right">{recreationalCount}</div>
                  </td>
                  <td
                    className="px-3 font-bold text-right"
                    style={{
                      color: colors.recreationalTotalCount,
                    }}
                  >
                    {numberFormatter.percent(recreationalCount, totalCount)}%
                  </td>
                </tr>
              )}
              {visibility.autoSupervisedCount && (
                <tr>
                  <th
                    className="px-3 font-bold text-white"
                    style={{
                      backgroundColor: colors.autoSupervisedCount,
                    }}
                  >
                    Auto-encadré:
                  </th>
                  <td
                    className="px-3 font-bold text-right"
                    style={{
                      color: colors.autoSupervisedCount,
                    }}
                  >
                    <div className="text-right">{autoSupervisedCount}</div>
                  </td>
                  <td
                    className="px-3 font-bold text-right"
                    style={{
                      color: colors.autoSupervisedCount,
                    }}
                  >
                    {numberFormatter.percent(autoSupervisedCount, totalCount)}%
                  </td>
                </tr>
              )}
              {visibility.watchingTourCount && (
                <tr>
                  <th
                    className="px-3 font-bold text-white"
                    style={{
                      backgroundColor: colors.watchingTourCount,
                    }}
                  >
                    {diveModeFormatter.formatDiveMode('watchingTour', {
                      format: 'long-label',
                      diveModesConf: clubSettings?.ui?.diveMode,
                    })}
                    :
                  </th>
                  <td
                    className="px-3 font-bold text-right"
                    style={{
                      color: colors.watchingTourCount,
                    }}
                  >
                    <div className="text-right">{watchingTourCount}</div>
                  </td>
                  <td
                    className="px-3 font-bold text-right"
                    style={{
                      color: colors.watchingTourCount,
                    }}
                  >
                    {numberFormatter.percent(watchingTourCount, totalCount)}%
                  </td>
                </tr>
              )}
              {visibility.otherCount && (
                <tr>
                  <th
                    className="px-3 font-bold text-white"
                    style={{
                      backgroundColor: colors.otherCount,
                    }}
                  >
                    Autres:
                  </th>
                  <td
                    className="px-3 font-bold text-right"
                    style={{
                      color: colors.otherCount,
                    }}
                  >
                    <div className="text-right">{otherCount}</div>
                  </td>
                  <td
                    className="px-3 font-bold text-right"
                    style={{
                      color: colors.otherCount,
                    }}
                  >
                    {numberFormatter.percent(otherCount, totalCount)}%
                  </td>
                </tr>
              )}
              <tr>
                <th className="px-3 font-bold bg-gray-800 text-white">
                  TOTAL:
                </th>
                <td className="px-3 font-bold bg-gray-800 text-white text-right">
                  <span className="font-bold">{totalCount}</span>
                </td>
                <td className="px-3 font-bold bg-gray-800 text-white text-right"></td>
              </tr>
              {cancelCountDiver > 0 && (
                <tr>
                  <th className="px-3 font-bold bg-status-error text-white">
                    ANNULATIONS PLONGEUR:
                  </th>
                  <td className="px-3 font-bold text-status-error text-right">
                    <span
                      className="font-bold"
                      style={{ color: colors['cancelCountDiver'] }}
                    >
                      {cancelCountDiver}
                    </span>
                  </td>
                  <td className="px-3 font-bold text-status-error text-right">
                    {numberFormatter.percent(
                      cancelCountDiver,
                      cancelCountDiver + cancelCountClub + totalCount,
                    )}
                    %
                  </td>
                </tr>
              )}
              {cancelCountClub > 0 && (
                <tr>
                  <th className="px-3 font-bold bg-[#610505] text-white">
                    ANNULATIONS CLUB:
                  </th>
                  <td className="px-3 font-bold text-[#610505] text-right">
                    <span
                      className="font-bold"
                      style={{ color: colors['cancelCountClub'] }}
                    >
                      {cancelCountClub}
                    </span>
                  </td>
                  <td className="px-3 font-bold text-[#610505] text-right">
                    {numberFormatter.percent(
                      cancelCountClub,
                      cancelCountDiver + cancelCountClub + totalCount,
                    )}
                    %
                  </td>
                </tr>
              )}
              {cancelCountDiver > 0 && cancelCountClub > 0 && (
                <tr>
                  <th className="px-3 font-bold bg-status-error text-white ">
                    TOTAL ANNULATIONS:
                  </th>
                  <td className="px-3 font-bold bg-status-error text-white text-right">
                    <span className="font-bold">
                      {cancelCountDiver + cancelCountClub}
                    </span>
                  </td>
                  <td className="px-3 font-bold bg-status-error text-white text-right">
                    {numberFormatter.percent(
                      cancelCountDiver + cancelCountClub,
                      cancelCountDiver + cancelCountClub + totalCount,
                    )}
                    %
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };
}
