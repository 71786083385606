import { ClubDiverFull } from '@mabadive/app-common-model';
import { search } from '@mabadive/app-common-services';
import { MQueryDescription } from 'src/_common-browser';
import { CLUB_DIVER_FULL_GRAPHQL_RETURN_ATTRIBUTES } from './CLUB_DIVER_FULL_GRAPHQL_RETURN_ATTRIBUTES.const';

export function buildClubDiverFullSearchGraphqlQuery({
  clubReference,
  fullText,
}: {
  clubReference: string;
  fullText: string;
}) {
  const pgSearchString = search.buildPostgresSearchString(fullText, {
    requireAllWords: true,
  });

  const queryDescription: MQueryDescription<ClubDiverFull> = {
    queryName: 'search_diver',
    returnType: 'all',
    args: `{club: "${clubReference}", search:"${pgSearchString}"}`,
    returnAttributes: CLUB_DIVER_FULL_GRAPHQL_RETURN_ATTRIBUTES,
  };

  return queryDescription;
}
