/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { PlanningViewConfigPeriod } from '../../club-planning/_model';

export const ClubPlanningLightDayHeader = ({
  date,
  viewPeriod,
  theme,
  className,
  onClick,
}: {
  date: Date;
  viewPeriod: PlanningViewConfigPeriod;
  theme?: 'default' | 'today';
  className?: string;
  onClick?: () => void;
}) => {
  const builtTheme = useMemo(
    () => theme ?? (dateService.isTodayUTC(date) ? 'today' : 'default'),
    [date, theme],
  );

  const isThisYear = useMemo(
    () => date.getFullYear() === new Date().getFullYear(),
    [date],
  );
  const isPast = useMemo(
    () => !dateService.isTodayUTC(date) && dateService.isPastUTC(date),
    [date],
  );
  return (
    <div
      className={`rounded-t-lg p-1 border border-gray-300
      ${onClick ? 'cursor-pointer' : ''}
      ${
        builtTheme === 'today'
          ? 'bg-app-orange3 text-white'
          : isPast
          ? 'bg-white text-gray-400'
          : 'bg-white text-gray-600'
      } py-1 text-center flex flex-col md:flex-row text-xs md:text-sm leading-4 sm:leading-4 md:justify-around md:items-center ${
        className ?? ''
      }
      ${viewPeriod === 'day' ? 'max-w-xs m-auto' : ''}
      `}
      onClick={onClick}
    >
      <div className={'text-app-xxs md:text-xs font-bold uppercase'}>
        <div className="lg:hidden">{dateService.formatUTC(date, 'ddd')}</div>
        <div className="hidden lg:block">
          {dateService.formatUTC(date, 'dddd')}
        </div>
      </div>
      <div>
        <div className="font-bold md:text-lg md:leading-5">
          {dateService.formatUTC(date, 'D')}
        </div>
        <div className="">
          {dateService.formatUTC(date, isThisYear ? 'MMM' : 'MMM YYYY')}
        </div>
      </div>
    </div>
  );
};
