/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';

import clsx from 'clsx';
import { DataManagementPageLocalState } from './useDataManagementPageLocalState.hook';

export const DataManagementDangerPanel = ({
  localState,
}: {
  localState: DataManagementPageLocalState;
}) => {
  const { state, data, actions } = localState;

  return state.isDataPurgeEnabled || state.isDataGenerationEnabled ? (
    <div className="app-card app-p-content">
      <div>
        <div className={'flex gap-2 justify-start items-center rounded-t-lg'}>
          <div className={clsx('rounded-md p-1 text-status-info')}>
            <AppIconsAction.generate
              className={clsx('h-6 w-6')}
              aria-hidden="true"
            />
          </div>
          <div>
            <div className="uppercase text-xl leading-5 font-bold text-status-info">
              Génération de données aléatoire & purge de la base de données
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 mb-4 flex justify-around">
        {state.isDataGenerationEnabled && (
          <AppButton
            size="normal"
            icon={AppHeroIcons.autoFill}
            color={'primary-outline-light'}
            tabIndex={500}
            onClick={async () => {
              await actions.generateData();
            }}
          >
            <span className="font-bold">Générer des données</span>
          </AppButton>
        )}
        {state.isDataPurgeEnabled && (
          <AppButton
            size="normal"
            icon={AppHeroIcons.actionDelete}
            color={'danger-outline-light'}
            tabIndex={500}
            onClick={async () => {
              state.setMode('confirm-delete-data');
            }}
          >
            <span className="font-bold">Purger les données</span>
          </AppButton>
        )}
      </div>
    </div>
  ) : null;
};
