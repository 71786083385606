import { ClubParticipantEquipment } from '@mabadive/app-common-model';
import React from 'react';

export const ParticipantEquipment = ({
  equipment,
  mode = 'default',
  className,
}: {
  equipment?: ClubParticipantEquipment;
  mode?: 'default' | 'badge';
  className?: string;
}) => {
  return !!equipment?.jacket?.model1?.ref ||
    !!equipment?.jacket?.model2?.ref ||
    !!equipment?.wetsuit?.model1?.ref ||
    !!equipment?.wetsuit?.model2?.ref ? (
    <div
      className={`flex gap-0.5  ${
        mode === 'default'
          ? 'text-gray-500'
          : 'text-white app-text-shadow-sm shadow-gray-500'
      } ${className}`}
    >
      <span>
        {equipment?.jacket?.model1?.ref ?? equipment?.jacket?.model2?.ref}
      </span>
      {(equipment?.jacket?.model1?.ref ?? equipment?.jacket?.model2?.ref) &&
        (equipment?.wetsuit?.model1?.ref ?? equipment?.wetsuit?.model2?.ref) &&
        '/'}
      <span>
        {equipment?.wetsuit?.model1?.ref ?? equipment?.wetsuit?.model2?.ref}
      </span>
    </div>
  ) : equipment?.provider === 'customer' ? (
    <div
      className={`flex gap-0.5 text-xs ${
        mode === 'default'
          ? 'text-gray-500'
          : 'text-white app-text-shadow-sm shadow-gray-500'
      } ${className}`}
    >
      EQUIPÉ
    </div>
  ) : null;
};
