/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSessionBookingConfig } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  useClubSettings,
  useDiveModeColor,
} from '../../../../../../business/club/data/hooks';
import { DiveSessionParticipantsStats } from '../../../../../../business/club/modules/club-dive-session/model';

export const ClubSettingsPlanningBookingConfigInfo = ({
  bookingConfig,
  diveSessionParticipantsStats,
  className,
}: {
  bookingConfig: DiveSessionBookingConfig;
  diveSessionParticipantsStats?: DiveSessionParticipantsStats;
  className?: string;
}) => {
  const firstDiveColor = useDiveModeColor('first-dive');
  const snorkelingSupervisedColor = useDiveModeColor('snorkelingSupervised');

  const clubSettings = useClubSettings();

  const remainingFirstDive = useMemo(() => {
    let remaining = bookingConfig?.firstDive?.enabled
      ? bookingConfig?.firstDive?.maxParticipants
      : 0;

    const globalConfig =
      clubSettings?.general?.onlineBooking?.sessionConfig?.firstDive;
    const existingParticipantsCount =
      globalConfig?.includeOtherParticipantsDiveModes
        ? diveSessionParticipantsStats?.total
        : diveSessionParticipantsStats?.firstDive;
    if (existingParticipantsCount) {
      remaining -= existingParticipantsCount;
    }
    return Math.max(remaining, 0);
  }, [
    bookingConfig?.firstDive?.enabled,
    bookingConfig?.firstDive?.maxParticipants,
    clubSettings?.general?.onlineBooking?.sessionConfig?.firstDive,
    diveSessionParticipantsStats?.total,
    diveSessionParticipantsStats?.firstDive,
  ]);

  const remainingSnorkelingSupervised = useMemo(() => {
    let remaining = bookingConfig?.snorkelingSupervised?.enabled
      ? bookingConfig?.snorkelingSupervised?.maxParticipants
      : 0;
    const globalConfig =
      clubSettings?.general?.onlineBooking?.sessionConfig?.snorkelingSupervised;
    const existingParticipantsCount =
      globalConfig?.includeOtherParticipantsDiveModes
        ? diveSessionParticipantsStats?.total
        : diveSessionParticipantsStats?.snorkelingSupervised;
    if (existingParticipantsCount) {
      remaining -= existingParticipantsCount;
    }
    return Math.max(remaining, 0);
  }, [
    bookingConfig?.snorkelingSupervised?.enabled,
    bookingConfig?.snorkelingSupervised?.maxParticipants,
    clubSettings?.general?.onlineBooking?.sessionConfig?.snorkelingSupervised,
    diveSessionParticipantsStats?.snorkelingSupervised,
    diveSessionParticipantsStats?.total,
  ]);

  const showFirstDive = remainingFirstDive > 0;
  const showSnorkelingSupervised = remainingSnorkelingSupervised > 0;
  return showFirstDive || showSnorkelingSupervised ? (
    <div
      className={clsx(className, 'text-gray-600 px-1 normal-case grid gap-1')}
    >
      {showFirstDive && (
        <div className="">
          <span
            className="font-medium uppercase text-white px-1 py-px"
            style={{
              backgroundColor: firstDiveColor,
            }}
          >
            BPT
          </span>{' '}
          :{' '}
          <span className="inline-block font-bold rounded-lg text-gray-600">
            {remainingFirstDive}
          </span>
        </div>
      )}
      {showSnorkelingSupervised && (
        <div className="">
          <span
            className="font-medium uppercase text-white px-1 py-px"
            style={{
              backgroundColor: snorkelingSupervisedColor,
            }}
          >
            RP
          </span>{' '}
          :{' '}
          <span className="inline-block font-bold rounded-lg text-gray-600">
            {remainingSnorkelingSupervised}
          </span>
        </div>
      )}
    </div>
  ) : null;
};
