import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';

export const AppTitleDateNavigatorDayLabel = ({
  selectedDay,
  isToday,
  className,
}: {
  selectedDay: Date;
  isToday: boolean;
  className?: string;
}) => {
  const journeWeekDayText = useMemo<string>(() => {
    const weekDay = dateService.formatUTC(selectedDay, 'dddd')?.toUpperCase();
    return weekDay;
  }, [selectedDay]);

  const journeyDateText = useMemo<string>(() => {
    const date = dateService.formatUTC(selectedDay, 'DD/MM/YYYY');
    return date;
  }, [selectedDay]);

  return (
    <div
      className={`font-bold text-center ${
        isToday
          ? 'app-theme-anim-live-primary group-hover:app-theme-anim-live-primary-dark'
          : 'text-gray-600 group-hover:text-gray-700'
      } ${className}`}
      // onClick={() => {
      //   if (!selectedDay?.isToday) {
      //     const newDay = buildSelectedDay(
      //       dateTransformer.getUTCDateSetTime(new Date())
      //     );
      //     onChange(newDay?.date);
      //   }
      // }}
    >
      <div
        className="text-xl mm:text-2xl leading-4"
        style={{ minWidth: '150px' }}
      >
        {journeWeekDayText}
      </div>
      <div className="text-base leading-5">{journeyDateText}</div>
    </div>
  );
};
