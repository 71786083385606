import { dataSorter } from '@mabadive/app-common-services';
import {
  BillingTabDiveSessionBillingResume,
  BillingTabParticipantPurchase,
} from '../../../models';

export const billingTabModelSorter = {
  sortBillingTabDiveSessionBillingResume,
  sortParticipantPurchases,
};

function sortBillingTabDiveSessionBillingResume(
  all: BillingTabDiveSessionBillingResume[],
): BillingTabDiveSessionBillingResume[] {
  return dataSorter.sortMultiple(all, {
    getSortAttributes: (x) => [
      {
        value: x?.diveSession.time,
        type: 'default',
        asc: true,
      },
      {
        value: x?.diveSession._id,
        type: 'full-text',
      },
    ],
    asc: false,
  });
}

function sortParticipantPurchases(
  all: BillingTabParticipantPurchase[],
): BillingTabParticipantPurchase[] {
  return dataSorter.sortMultiple(all, {
    getSortAttributes: (x, i) => [
      {
        value: x.diver?.birthdate,
        type: 'default',
        asc: false,
      },
      {
        value: x.diver?.lastName,
        type: 'full-text',
      },
      {
        value: x.diver?.firstName,
        type: 'full-text',
      },
      {
        value: x.diver?._id,
        type: 'default',
      },
      {
        value: x.purchasePackage?.purchaseDate,
        type: 'default',
        asc: false,
      },
      {
        value: x.purchasePackage?._createdAt,
        type: 'default',
        asc: false,
      },
      {
        value: x.purchasePackage?._id,
        type: 'default',
      },
      {
        value: Math.max(
          ...x?.sessionsBillingResumes.all.map((r) =>
            r.diveSession.time.getTime(),
          ),
        ),
        type: 'default',
        asc: false,
      },
      {
        value: i,
        type: 'default',
      },
    ],
    asc: true,
  });
}
