/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSessionStaffMemberTableModelStaffMember } from '@mabadive/app-common-model';
import React, { FC, useMemo } from 'react';

export const DiveSessionStaffMembersCountLabel: FC<{
  staffMembersFull: DiveSessionStaffMemberTableModelStaffMember[];
}> = ({ staffMembersFull }) => {
  const availableOrActiveToday = useMemo(
    () => staffMembersFull.filter((x) => x.meta.available),
    [staffMembersFull],
  );

  const availableScubaInstructorsCount = useMemo(
    () =>
      availableOrActiveToday.filter((x) =>
        x.meta.availableRoles.includes('scuba-diving-instructor'),
      ).length,
    [availableOrActiveToday],
  );

  const remainingCount =
    availableOrActiveToday.length - availableScubaInstructorsCount;

  return (
    <>
      ({availableScubaInstructorsCount}
      {remainingCount > 0 && ` + ${remainingCount}`})
    </>
  );
};
