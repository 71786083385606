import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { AppTabTailwind } from '../../../../../../../business/_core/modules/layout';

import {
  EcommerceCategoryEditorFormTabId,
  ecommerceCategoryEditorFormTabLabelFormatter,
} from '../EcommerceCategoryEditor/services';
import {
  EcommerceProductEditorFormContentWithPreview,
  EcommerceProductEditorFormSettings,
} from './form';
import { EcommerceProductEditorLocalState } from './useEcommerceProductEditorPanelLocalState.hook';

export const EcommerceProductEditorPanelEditForm = ({
  localState,
}: {
  localState: EcommerceProductEditorLocalState;
}) => {
  const { state, actions } = localState;

  const [appBookletPage, productName] = useWatch({
    control: state.form.control,
    name: ['appBookletPage', 'product.name'],
  });

  const tabs = useMemo(() => {
    const tabsIds: EcommerceCategoryEditorFormTabId[] = [
      'content',
      'cover',
      'config',
    ];

    const tabs: AppTabTailwind<EcommerceCategoryEditorFormTabId>[] =
      tabsIds.map((tabId) => ({
        id: tabId,
        label: ecommerceCategoryEditorFormTabLabelFormatter.format(tabId),
      }));
    return tabs;
  }, []);

  const [selectedTab, setSelectedTab] =
    React.useState<EcommerceCategoryEditorFormTabId>('content');

  return (
    <div className="grid gap-4 max-w-6xl">
      {/* <AppTabsTailwind
        className="w-full"
        tabs={tabs}
        selected={selectedTab}
        theme="underline"
        breakpoint="sm"
        onChange={(tabId) => setSelectedTab(tabId)}
      /> */}
      {/* {selectedTab === 'cover' && (
        <EcommerceProductEditorFormCoverWithPreview localState={localState} />
      )} */}
      {/* {selectedTab === 'content' && ( */}
      <EcommerceProductEditorFormContentWithPreview localState={localState} />
      {/* )} */}
      {/* {selectedTab === 'config' && ( */}
      <EcommerceProductEditorFormSettings localState={localState} />
      {/* )} */}
    </div>
  );
};
