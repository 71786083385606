import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { AppToggleButtonGroupPropsV2 } from './AppToggleButtonGroupPropsV2.type';

export function AppToogleButtonGroupTailwindV2Option<
  T extends string | number | boolean,
>({
  selected,
  buildOptionStyle,
  size = 'lg',
  option,
}: Pick<
  AppToggleButtonGroupPropsV2<T>,
  'selected' | 'size' | 'buildOptionStyle'
> & {
  option: ValueLabel<T, React.ReactNode, React.ReactNode>;
}) {
  const buildStyle = useCallback(
    ({ active, checked }: { active: boolean; checked: boolean }) => {
      const x = buildOptionStyle(option, {
        isActive: active,
        isSelected: option.value === selected,
        isChecked: checked,
        isDisabled: option.disabled,
      });
      // console.log('xxx x: ', {
      //   option,
      //   x,
      //   selected,
      //   isSelected: option.value === selected,
      // });
      return x;
    },
    [buildOptionStyle, option, selected],
  );
  return (
    <RadioGroup.Option
    onChange={value=>{
       console.log('xxx value: ', value);
    }}
      value={option}
      className={({ active, checked }) =>
        clsx(
          'border rounded-md  flex items-center justify-center font-medium uppercase sm:flex-1',
          size === 'lg' ? 'py-3 px-3 text-sm' : 'py-2 px-1 sm:px-2 text-xs',
          buildStyle({ active, checked })?.className,
        )
      }
      disabled={option.disabled}
      style={
        option.color && {
          borderColor: option.color,
        }
      }
    >
      <RadioGroup.Label
        as="div"
        className={'w-full flex justify-center gap-2 items-center'}
      >
        {option.icon && (
          <option.icon className={size === 'lg' ? 'h-5 w-5' : 'h-3 w-3'} />
        )}
        <span className="flex-grow">
          {option.color ? (
            <span
              className="px-1 font-bold truncate uppercase flex items-center"
              style={{
                color: option.color,
              }}
            >
              {option.label}
            </span>
          ) : (
            option.label
          )}
        </span>
      </RadioGroup.Label>
    </RadioGroup.Option>
  );
}
