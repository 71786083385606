import {
  ClubSettings,
  ClubSettingsGeneralOnlineBookingEmailOption,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useClubSettings } from 'src/business/club/data/hooks';

export function useAutomaticMailConfigForTemplate(messageTemplateId: string) {
  const clubSettings = useClubSettings();
  return useMemo(() => {
    return getAutomaticMailConfigForTemplate({
      messageTemplateId,
      clubSettings,
    });
  }, [clubSettings, messageTemplateId]);
}

export function getAutomaticMailConfigForTemplate({
  messageTemplateId,
  clubSettings,
}: {
  messageTemplateId: string;
  clubSettings: ClubSettings;
}) {
  const conf = {
    onCustomerSubmitBookingWithPendingDeposit: isEnabled({
      messageTemplateId,
      conf: clubSettings?.general?.onlineBooking?.emails
        ?.onCustomerSubmitBookingWithPendingDeposit,
    }),
    onCustomerSubmitBookingWithoutPendingDeposit: isEnabled({
      messageTemplateId,
      conf: clubSettings?.general?.onlineBooking?.emails
        ?.onCustomerSubmitBookingWithoutPendingDeposit,
    }),
  };
  return {
    ...conf,
    enabledCount: Object.values(conf).filter((v) => v).length,
  };
}

function isEnabled({
  conf,
  messageTemplateId,
}: {
  conf: ClubSettingsGeneralOnlineBookingEmailOption;
  messageTemplateId: string;
}) {
  if (conf?.enabled && conf?.messageTemplateId === messageTemplateId) {
    return true;
  }
  return false;
}
