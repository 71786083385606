import {
  AppEntityUpdatePatch,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { SettingsUserAccount } from '../../ClubSettingsUserAccountsListPage/model';

export const clubSettingsUserAccountPersister = {
  createUserAccount,
  updateUserAccount,
};

async function createUserAccount({
  formValue,
}: {
  formValue: SettingsUserAccount;
}) {
  const { authUser, ...accountProfile } = formValue;
  const payload: ProMultiOperationPayload = {
    logContext: 'create user account',
    clubAuthUserAccounts: {
      created: [
        {
          authUser: {
            ...authUser,
            login: authUser.login.trim().toLowerCase(),
          },
          accountProfile,
        },
      ],
    },
  };
  await clubMassiveUpdatorClient.update(payload);
}

async function updateUserAccount({
  initialValue,
  formValue,
}: {
  initialValue: SettingsUserAccount;
  formValue: SettingsUserAccount;
}) {
  const { authUser, ...accountProfile } = formValue;

  // edit
  const { authUser: authUserInitial, ...accountProfileInitial } = initialValue;
  const authUserPatches: AppEntityUpdatePatch[] = [];
  const accountProfilePatches: AppEntityUpdatePatch[] = [];
  {
    const patchOperations = jsonPatcher.compareObjects(
      authUserInitial,
      authUser,
      {},
    );
    if (patchOperations.length) {
      const patch: AppEntityUpdatePatch = {
        pk: authUserInitial._id,
        patchOperations,
      };
      authUserPatches.push(patch);
    }
  }
  {
    const patchOperations = jsonPatcher.compareObjects(
      accountProfileInitial,
      {
        ...accountProfile,
        emailAddress: accountProfile.emailAddress?.trim().toLowerCase(),
      },
      {},
    );
    if (patchOperations.length) {
      const patch: AppEntityUpdatePatch = {
        pk: accountProfileInitial._id,
        patchOperations,
      };
      accountProfilePatches.push(patch);
    }
  }
  if (authUserPatches.length || accountProfilePatches.length) {
    const payload: ProMultiOperationPayload = {
      logContext: 'update user account',
      clubAuthUserAccounts: {
        updated: {
          authUser: authUserPatches,
          accountProfile: accountProfilePatches,
        },
      },
    };
    await clubMassiveUpdatorClient.update(payload);
  }
}
