import clsx from 'clsx';
import React from 'react';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DashboardMetricValueItem } from './model';

export const DashboardMetricValueCard = (item: DashboardMetricValueItem) => {
  const {
    iconClassName = 'text-gray-400',
    labelClassName = 'text-gray-500',
    valueExtraLabelClassName,
    valueExtraLabel,
    type,
    children,
  } = item;
  const mainCurrency = useAppCurrencyMain();
  return (
    <div
      className={clsx('min-w-[4rem] max-w-[12rem] relative', item.className)}
    >
      <dt>
        {item.icon && (
          <div className={clsx('absolute rounded-md p-3', iconClassName)}>
            <item.icon className="h-6 w-6 " aria-hidden="true" />
          </div>
        )}
        <p
          className={clsx(
            'truncate text-sm font-medium uppercase text-center',
            labelClassName,
          )}
        >
          {item.label}
        </p>
      </dt>
      <dd className="flex items-baseline pb-4 sm:pb-3">
        <div className="w-full relative text-2xl font-semibold text-gray-600 text-center">
          <div className="absolute left-1 top-1">
            {item.unitIcon && (
              <item.unitIcon
                className={clsx('h-6 w-6', iconClassName)}
                aria-hidden="true"
              />
            )}
          </div>
          <div className="px-8">
            {type === 'amount' ? (
              <AppPriceLabel
                className={clsx(
                  item.value === 0 ? 'text-gray-400 font-normal' : '',
                  'text-lg',
                )}
                centsClassName="text-xs text-gray-600"
                addSpacesToLargeNumbers={true}
                amount={item.value}
                mainCurrency={mainCurrency}
              />
            ) : (
              item.value
            )}
            {valueExtraLabel && (
              <span className={clsx('ml-1', valueExtraLabelClassName)}>
                {valueExtraLabel}
              </span>
            )}
            {item.unit && ` ${item.unit}`}
            {children && children}
          </div>
        </div>
      </dd>
    </div>
  );
};
