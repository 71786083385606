import { SingleEquipmentStats } from '@mabadive/app-common-model';
import { equipmentFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';

export function DailySessionsResumeEquipmentStatsTableRow({
  singleEquipmentStats,
}: {
  singleEquipmentStats: SingleEquipmentStats;
}) {
  const redirectTo = useRedirect();

  const {
    clubEquipmentDescriptions,
    selfEquipmentDescriptions,
    clubEquipmentDescriptionsSum,
    selfEquipmentDescriptionsSum,
  } = singleEquipmentStats;

  return (
    <tr className={''}>
      <td
        className={
          'py-0.5 px-2 whitespace-nowrap text-xs md:text-sm font-bold uppercase'
        }
      >
        {/* Équipement */}
        {equipmentFormatter.formatEquipmentType(
          singleEquipmentStats.equipmentType,
        )}
      </td>
      <td
        className={
          'py-0.5 px-2 whitespace-nowrap text-xs md:text-sm font-bold text-center'
        }
      >
        {/* Équipé club */}
        {singleEquipmentStats.divesCounts?.isSeparateDiveCount ? (
          <div>
            {[
              singleEquipmentStats.divesCounts?.[1]?.club +
                singleEquipmentStats.divesCounts?.[1]?.clubInstructor,
              singleEquipmentStats.divesCounts?.[2]?.club +
                singleEquipmentStats.divesCounts?.[2]?.clubInstructor,
            ].join(' + ')}
          </div>
        ) : (
          <div>
            {singleEquipmentStats.totalClubCount}
            {singleEquipmentStats.totalClubInstructorCount > 0 && (
              <span> + {singleEquipmentStats.totalClubInstructorCount}</span>
            )}
          </div>
        )}
      </td>
      <td
        className={'py-0.5 px-2 whitespace-nowrap text-xs hidden xs:table-cell'}
      >
        {/* Détail éq. club */}
        <div className="flex gap-2 flex-wrap">
          {clubEquipmentDescriptions.map(({ total, label }, i) => (
            <span className="border text-white flex items-end gap-px" key={i}>
              <span className="text-xs font-bold bg-gray-500 px-0.5 md:px-1">
                {label}
              </span>
              <span className="text-xs font-bold text-gray-500 px-0.5">
                <span className="text-app-xxs">x</span>
                {total}
              </span>
            </span>
          ))}
          {clubEquipmentDescriptions.length > 0 &&
            clubEquipmentDescriptionsSum !==
              singleEquipmentStats.totalClubCount +
                singleEquipmentStats.totalClubInstructorCount && (
              <span className="border text-white flex items-end gap-px">
                <span className="text-xs font-bold bg-app-blue px-0.5 md:px-1">
                  {'?'}
                </span>
                <span className="text-xs font-bold text-gray-500 px-0.5">
                  <span className="text-app-xxs">x</span>
                  {singleEquipmentStats.totalClubCount +
                    singleEquipmentStats.totalClubInstructorCount -
                    clubEquipmentDescriptionsSum}
                </span>
              </span>
            )}
        </div>
      </td>
      <td
        className={
          'py-0.5 px-2 whitespace-nowrap text-xs md:text-sm font-bold text-center'
        }
      >
        {/* Équipé perso */}
        {singleEquipmentStats.divesCounts?.isSeparateDiveCount ? (
          <div>
            {[
              singleEquipmentStats.divesCounts?.[1]?.self +
                singleEquipmentStats.divesCounts?.[1]?.selfInstructor,
              singleEquipmentStats.divesCounts?.[2]?.self +
                singleEquipmentStats.divesCounts?.[2]?.selfInstructor,
            ].join(' + ')}
          </div>
        ) : (
          <div>
            {singleEquipmentStats.totalSelfCount}
            {singleEquipmentStats.totalSelfInstructorCount > 0 && (
              <span> + {singleEquipmentStats.totalSelfInstructorCount}</span>
            )}
          </div>
        )}
      </td>
      <td
        className={'py-0.5 px-2 whitespace-nowrap text-xs hidden xs:table-cell'}
      >
        {/* Détail éq. perso */}
        <div className="flex gap-2 flex-wrap">
          {selfEquipmentDescriptions.map(({ total, label }, i) => (
            <span className="border text-white flex items-end gap-px" key={i}>
              <span className="text-xs font-bold bg-gray-500 px-0.5 md:px-1">
                {label}
              </span>
              <span className="text-xs font-bold text-gray-500 px-0.5">
                <span className="text-app-xxs">x</span>
                {total}
              </span>
            </span>
          ))}
          {selfEquipmentDescriptions.length > 0 &&
            selfEquipmentDescriptionsSum !==
              singleEquipmentStats.totalSelfCount +
                singleEquipmentStats.totalSelfInstructorCount && (
              <span className="border text-white flex items-end gap-px">
                <span className="text-xs font-bold bg-app-blue px-0.5 md:px-1">
                  {'?'}
                </span>
                <span className="text-xs font-bold text-gray-500 px-0.5">
                  <span className="text-app-xxs">x</span>
                  {singleEquipmentStats.totalSelfCount +
                    singleEquipmentStats.totalSelfInstructorCount -
                    selfEquipmentDescriptionsSum}
                </span>
              </span>
            )}
        </div>
      </td>
      <td
        className={
          'py-0.5 px-2 whitespace-nowrap text-xs md:text-sm font-bold text-center'
        }
      >
        {/* Total */}
        {singleEquipmentStats.divesCounts?.isSeparateDiveCount ? (
          <div>
            {[
              (singleEquipmentStats.divesCounts?.[1]?.self ?? 0) +
                (singleEquipmentStats.divesCounts?.[1]?.club ?? 0) +
                (singleEquipmentStats.divesCounts?.[1]?.selfInstructor ?? 0) +
                (singleEquipmentStats.divesCounts?.[1]?.clubInstructor ?? 0),
              (singleEquipmentStats.divesCounts?.[2]?.self ?? 0) +
                (singleEquipmentStats.divesCounts?.[2]?.club ?? 0) +
                (singleEquipmentStats.divesCounts?.[2]?.selfInstructor ?? 0) +
                (singleEquipmentStats.divesCounts?.[2]?.clubInstructor ?? 0),
            ].join(' + ')}
          </div>
        ) : (
          <div>{singleEquipmentStats.totalCount}</div>
        )}
      </td>
    </tr>
  );
}
