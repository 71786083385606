import { OnlineBooking } from '@mabadive/app-common-model';
import React, { useCallback, useMemo } from 'react';
import { AppTransition } from 'src/business/_core/modules/layout/components/_tailwind';
import { useAppBookingViewAuth } from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { diveSessionTitleBuilder } from 'src/business/club/modules/club-dive-session/services';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  BookingResumeOnClickMemberAction,
  DiverBookingCardBookingAction,
} from '../../../_from-diver-app';
import {
  AggregatedBookingSessionFull,
  BookingTabModel,
  DiverBookingPageLoadedContentFocus,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
} from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingCardPastSessionsTable } from '../DiverBookingSessionsTable';
import { BookingResumeMembersList } from './BookingResumeMembersTable';
import { DiverBookingCardFutureSessionsTable } from './DiverBookingCardFutureSessionsTable';
import { DiverBookingCardGlobalAddButtons } from './DiverBookingCardGlobalAddButtons';
import { DiverBookingCardHeader } from './DiverBookingCardHeader';
import {
  DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates,
  DiverBookingPageBookingTabHeaderAutoFixSplitBookings,
  useDiverBookingCardHeaderAutoFix,
} from './DiverBookingCardHeaderAutoFix';
import { DiverBookingCardMainButtonsBar } from './DiverBookingCardMainButtonsBar';
import { DiverBookingCardOnlineBooking } from './DiverBookingCardOnlineBooking';

export function DiverBookingCard({
  enableAutoFix,
  globalState,
  dialogs,
  actions,
  bookingTabModel,
  focus,
  hasChanges,
  onClickParticipant,
  onClickMember,
  onClickSession,
  onClickBooking,
  extraMenuItems = [],
  className,
}: {
  enableAutoFix: boolean;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  focus: DiverBookingPageLoadedContentFocus;
  hasChanges: boolean;
  onClickParticipant: (props: {
    bookingSessionFull: AggregatedBookingSessionFull;
    bookingParticipantFull: PRO_BookingParticipantFull;
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  }) => void;

  onClickSession: (bookingSessionFull: AggregatedBookingSessionFull) => void;
  onClickBooking: (
    bookingTabModel: BookingTabModel,
    action: DiverBookingCardBookingAction,
  ) => void;
  onClickMember: (attrs: {
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
    action: BookingResumeOnClickMemberAction;
  }) => void;
  extraMenuItems?: AppHamburgerMenuItem[];
  className?: string;
}) {
  const { openSessionSelector } = actions;

  const booking = bookingTabModel.aggregatedBooking.booking;

  const { bookingMembersFull, bookingParticipantsFull, bookingSessionsFull } =
    bookingTabModel.aggregatedBooking;

  const { isUniqueMember, isSameLastName, futureSessionsCount } =
    bookingTabModel.meta;

  const displaySessionName = useMemo(
    () =>
      bookingTabModel.aggregatedBooking.bookingSessionsFull.find(
        (x) => diveSessionTitleBuilder.build(x.diveSession) !== undefined,
      ) !== undefined,
    [bookingTabModel.aggregatedBooking.bookingSessionsFull],
  );
  const displayDiveSite = useMemo(
    () =>
      bookingTabModel.aggregatedBooking.bookingSessionsFull.find(
        (x) =>
          !!x.diveSession.diveSiteId ||
          !!x.diveSession.diveTourSession2?.diveSiteId,
      ) !== undefined,
    [bookingTabModel.aggregatedBooking.bookingSessionsFull],
  );

  const { bookingOpeningStates, setBookingOpeningStates } = globalState;

  const autoFixes = useDiverBookingCardHeaderAutoFix({
    bookingTabModel,
    enableAutoFix,
  });

  const isExpanded = useMemo(
    () =>
      bookingOpeningStates.find(
        (s) => s?.bookingId === bookingTabModel.bookingId,
      )?.isOpen === true || autoFixes.bookingCustomerUpdates?.length > 0, // on force à afficher la résa si il y a une mise à jour
    [
      autoFixes.bookingCustomerUpdates?.length,
      bookingOpeningStates,
      bookingTabModel.bookingId,
    ],
  );

  const setIsExpanded = useCallback(
    (isOpen: boolean) => {
      const state = bookingOpeningStates.find(
        (s) => s.bookingId === bookingTabModel.bookingId,
      );
      if (state) {
        setBookingOpeningStates(
          bookingOpeningStates.map((s) => {
            if (s.bookingId === bookingTabModel.bookingId) {
              return {
                ...s,
                isOpen,
              };
            }
            return s;
          }),
        );
      }
    },
    [bookingOpeningStates, bookingTabModel.bookingId, setBookingOpeningStates],
  );

  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: bookingTabModel.bookingDiveCenterId,
    isSharedBooking: bookingTabModel.isSharedBooking,
  });

  const onlineBookings: OnlineBooking[] =
    bookingTabModel?.aggregatedBooking?.onlineBookings ?? [];

  return (
    <div className={`bg-white ${className ?? ''}`}>
      <DiverBookingCardHeader
        focus={focus}
        bookingTabModel={bookingTabModel}
        onClickMember={onClickMember}
        onClickBooking={onClickBooking}
        extraMenuItems={extraMenuItems}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        actions={actions}
        dialogs={dialogs}
      />
      <AppTransition type="scale" show={isExpanded}>
        <>
          <DiverBookingCardMainButtonsBar
            globalState={globalState}
            actions={actions}
            bookingTabModel={bookingTabModel}
            onClickBooking={onClickBooking}
            setIsExpanded={setIsExpanded}
          />
          {autoFixes.bookingCustomerUpdates?.length > 0 && (
            <DiverBookingPageBookingTabHeaderAutoFixCustomerUpdates
              bookingTabModel={bookingTabModel}
              globalState={globalState}
              dialogs={dialogs}
              actions={actions}
              bookingCustomerUpdates={autoFixes.bookingCustomerUpdates}
              setIsExpanded={setIsExpanded}
            />
          )}
          <BookingResumeMembersList
            className="mt-2 sm:mt-5"
            actions={actions}
            booking={booking}
            aggregatedBookingResume={bookingTabModel.aggregatedBooking}
            bookingMembersFull={bookingMembersFull}
            bookingParticipantsFull={bookingParticipantsFull}
            futureSessionsCount={futureSessionsCount}
            globalState={globalState}
            onClickMember={(bookingMemberFull, action) => {
              onClickMember({ bookingMemberFull, action });
            }}
          />
          <DiverBookingCardFutureSessionsTable
            className="mt-4"
            globalState={globalState}
            dialogs={dialogs}
            actions={actions}
            bookingTabModel={bookingTabModel}
            onClickMember={onClickMember}
            onClickSession={onClickSession}
          />
          {onlineBookings.length > 0 && (
            <div className="mt-4 grid gap-2">
              {onlineBookings.map((onlineBooking) => (
                <DiverBookingCardOnlineBooking
                  key={onlineBooking._id}
                  onlineBooking={onlineBooking}
                  globalState={globalState}
                  dialogs={dialogs}
                  actions={actions}
                  bookingTabModel={bookingTabModel}
                />
              ))}
            </div>
          )}

          {bookingTabModel.participantsBySessionsPast?.length > 0 ? (
            <div>
              <h3 className="mx-2 mt-4 sm:mt-8 mb-4 text-base uppercase font-bold text-gray-500">
                Inscriptions passées:
              </h3>
              <DiverBookingCardPastSessionsTable
                className={'mt-2'}
                // table={table}
                participantsBySessions={
                  bookingTabModel.participantsBySessionsPast
                }
                onClickSession={onClickSession}
                onClickParticipant={onClickParticipant}
                globalState={globalState}
                dialogs={dialogs}
                actions={actions}
                bookingTabModel={bookingTabModel}
                futureSessionsCount={futureSessionsCount}
                displayName={!isUniqueMember}
                displayLastName={!isSameLastName}
                displaySessionName={displaySessionName}
                displayDiveSite={displayDiveSite}
              />
            </div>
          ) : null}
          {bookingViewAuth?.edit &&
            (bookingTabModel.participantsBySessionsPast.length !== 0 ||
              bookingTabModel.participantsBySessionsFuture.length !== 0) && (
              <DiverBookingCardGlobalAddButtons
                className="mt-4 sm:mt-8 mb-4"
                isReverseTheme={true}
                bookingTabModel={bookingTabModel}
                actions={actions}
              />
            )}
          {autoFixes.splitBookings && (
            <DiverBookingPageBookingTabHeaderAutoFixSplitBookings
              bookingTabModel={bookingTabModel}
              globalState={globalState}
              dialogs={dialogs}
              actions={actions}
              splitBookings={autoFixes.splitBookings}
              setIsExpanded={setIsExpanded}
            />
          )}
        </>
      </AppTransition>
    </div>
  );
}
