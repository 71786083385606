import {
  ClubSettingsUIPlanning,
  DEFAULT_CLUB_SETTINGS_UI_PLANNING,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { PlanningViewConfig } from '../../club-planning/_model';

export function useDefaultSettingsUiPlanning({
  planningConfig,
}: {
  planningConfig?: PlanningViewConfig;
}): ClubSettingsUIPlanning {
  const clubResume = useClubResume();

  const defaultSettingsUiPlanning = useMemo(() => {
    if (clubResume.clubSettings.ui?.planning) {
      return {
        ...DEFAULT_CLUB_SETTINGS_UI_PLANNING,
        ...clubResume.clubSettings.ui?.planning,
      };
    }
    if (!planningConfig) {
      return DEFAULT_CLUB_SETTINGS_UI_PLANNING;
    }
    // si pas encore de config, on se base sur celle du store local
    const x: ClubSettingsUIPlanning = {
      showStaff: planningConfig.showStaff,
      showStaffCounts: planningConfig.showStaffCounts,
      showStaffCountsPerDay: planningConfig.showStaffCountsPerDay,
      showStaffCountsPerDayDP: planningConfig.showStaffCountsPerDayDP,
      showStaffCountsPerDayGP: planningConfig.showStaffCountsPerDayGP,
      showStaffCountsPerDaySS: planningConfig.showStaffCountsPerDaySS,
      showInactiveSessions: planningConfig.showInactiveSessions,
      showInactiveParticipants: planningConfig.showInactiveParticipants,
      staffFirstNameBefore: planningConfig.staffFirstNameBefore,
      participantFirstNameBefore: planningConfig.participantFirstNameBefore,
      showPaymentFlag: planningConfig.showPaymentFlag,
      showPaymentFlagPastOnly: planningConfig.showPaymentFlagPastOnly,
      showPaymentFlagNotPurchased: planningConfig.showPaymentFlagNotPurchased,
      showPaymentFlagNotPurchasedPass:
        planningConfig.showPaymentFlagNotPurchasedPass,
      showPaymentFlagNotPurchasedPassMode:
        planningConfig.showPaymentFlagNotPurchasedPassMode,
      showPaymentFlagPayed: planningConfig.showPaymentFlagPayed,
      showPaymentFlagNotPayed: planningConfig.showPaymentFlagNotPayed,
      showPaymentFlagTraining: planningConfig.showPaymentFlagTraining,
      displayGroupLevelDetails: planningConfig.displayGroupLevelDetails,
      displayPastSessions: planningConfig.displayPastSessions,
      displayEmptySessionsPast: planningConfig.displayEmptySessionsPast,
      displayEmptySessionsFuture: planningConfig.displayEmptySessionsFuture,
      staffPeriods: planningConfig.staffPeriods,
    };
    return {
      ...DEFAULT_CLUB_SETTINGS_UI_PLANNING,
      ...x,
    };
  }, [clubResume.clubSettings.ui?.planning, planningConfig]);

  return defaultSettingsUiPlanning;
}
