/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react';
import { AppTabTailwind } from 'src/business/_core/modules/layout';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

import { DiveServiceOrganizationReference } from '@mabadive/app-common-model';
import {
  ClubSettingsProductOffersDialogInitialState,
  ClubSettingsProductOffersDialogInitialStateMode,
  ClubSettingsProductOffersFilters,
  CreateOfferPayload,
} from '../ClubSettingsProductOffersDialog';
import { ClubSettingsServicesOffersPricesContextMode } from './_common/offers-prices/_model';
import {
  ClubSettingsServicesDialogInitialState,
  ClubSettingsServicesPageTabEditMode,
  ClubSettingsServicesPageViewState,
} from './_model';
import {
  ClubSettingsServicesPageTabFirstDiveEditMode,
  ClubSettingsServicesPageTabId,
  clubSettingsServicesPageTabLabelFormatter,
  clubSettingsServicesPageUrlManager,
} from './_services';
import { useClubSettingsServicesPageUpdateURL } from './useClubSettingsServicesPageUpdateURL.hook';

export const useClubSettingsServicesPageLocalState = () => {
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const isEditAllowedBase = useAppSecurityUserHasRole(
    'club-edit-settings-general',
  );
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const queryParams = clubSettingsServicesPageUrlManager.getQueryParameters();

  const [viewState, setViewState] = useState<ClubSettingsServicesPageViewState>(
    {
      tabId: queryParams.tabId ?? 'activities',
      mode: queryParams.mode ?? 'view',
    },
  );

  useClubSettingsServicesPageUpdateURL(viewState, {
    enableRouteParams: true,
  });

  const selectedTab = viewState.tabId;

  const setSelectedTab = useCallback((tabId: ClubSettingsServicesPageTabId) => {
    setViewState((prev) => ({
      ...prev,
      tabId,
    }));
  }, []);

  const editMode: ClubSettingsServicesPageTabEditMode = useMemo(() => {
    const tmp = false;
    if (tmp) {
      return 'super-admin-only';
    }
    // if (isEditAllowedBase) {
    //   if (selectedTab === 'pricing') {
    //     return 'forbidden';
    //   }
    // }
    return isEditAllowedBase ? 'allowed' : 'forbidden';
  }, [isEditAllowedBase]);

  const editEnabled =
    editMode ===
    'allowed'; /*|| (editMode === 'super-admin-only' && isSuperAdmin)*/

  const productPackageOffers = clubResume.productPackageOffers;

  const openClubSettingsServicesDialog = useCallback(
    (
      tabId: ClubSettingsServicesPageTabId,
      {
        tabFirstDiveEditMode,
        tabFirstDiveContextMode,
        createTrainingsFromOrg,
      }: {
        tabFirstDiveEditMode?: ClubSettingsServicesPageTabFirstDiveEditMode;
        tabFirstDiveContextMode?: ClubSettingsServicesOffersPricesContextMode;
        createTrainingsFromOrg?: DiveServiceOrganizationReference;
      } = {},
    ) => {
      const inititialState: ClubSettingsServicesDialogInitialState = {
        tabId,
        defaultValue: {
          clubSettings,
        },
        tabFirstDiveEditMode,
        tabFirstDiveContextMode,
        createTrainingsFromOrg,
      };
      dialogsState.clubSettingsServicesDialog.openDialog(inititialState);
    },
    [clubSettings, dialogsState.clubSettingsServicesDialog],
  );
  const openClubSettingsOffersEditDialog = useCallback(
    ({
      mode,
      contextMode,
      offers,
      initialFilters,
    }: {
      mode: ClubSettingsProductOffersDialogInitialStateMode;
      contextMode: ClubSettingsServicesOffersPricesContextMode;
      offers: CreateOfferPayload[];
      initialFilters?: ClubSettingsProductOffersFilters;
    }) => {
      const inititialState: ClubSettingsProductOffersDialogInitialState = {
        contextMode,
        mode,
        createOffersPayloads: offers,
        defaultValue: {
          offers: offers?.map((o) => o.offer),
        },
        initialFilters,
        // tabId,
        // defaultValue: {
        //   clubSettings,
        //   offers: productPackageOffers,
        // },
        // tabFirstDiveEditMode,
        // tabFirstDiveContextMode,
        // createTrainingsFromOrg,
      };
      dialogsState.clubSettingsProductOffersDialog.openDialog(inititialState);
    },
    [dialogsState.clubSettingsProductOffersDialog],
  );

  const tabs = useMemo(() => {
    const tabsIds: ClubSettingsServicesPageTabId[] = [
      'activities',
      // 'explo',
      // 'training',
      // 'other',
    ];

    if (clubSettings.firstDive?.enabled) {
      tabsIds.push('first-dive');
    }
    if (clubSettings.training?.enabled) {
      tabsIds.push('training');
    }
    if (
      clubSettings.supervised?.enabled ||
      clubSettings.autonomous?.enabled ||
      clubSettings.autoSupervised?.enabled ||
      clubSettings.instructor?.enabled
    ) {
      tabsIds.push('explo');
    }
    if (
      clubSettings.freeDive?.enabled ||
      clubSettings.observer?.enabled ||
      clubSettings.snorkeling?.enabled ||
      clubSettings.snorkelingSupervised?.enabled ||
      clubSettings.watchingTour?.enabled
    ) {
      tabsIds.push('other');
    }

    tabsIds.push('extra');

    const tabs: AppTabTailwind<ClubSettingsServicesPageTabId>[] = tabsIds.map(
      (tabId) => ({
        id: tabId,
        label: clubSettingsServicesPageTabLabelFormatter.format(tabId),
      }),
    );
    return tabs;
  }, [
    clubSettings.autoSupervised?.enabled,
    clubSettings.autonomous?.enabled,
    clubSettings.firstDive?.enabled,
    clubSettings.freeDive?.enabled,
    clubSettings.instructor?.enabled,
    clubSettings.observer?.enabled,
    clubSettings.snorkeling?.enabled,
    clubSettings.snorkelingSupervised?.enabled,
    clubSettings.watchingTour?.enabled,
    clubSettings.supervised?.enabled,
    clubSettings.training?.enabled,
  ]);

  return {
    state: {
      editEnabled,
      editMode,
      tabs: {
        selected: selectedTab,
        setSelected: setSelectedTab,
        data: tabs,
      },
    },
    data: {
      productPackageOffers,
    },
    dialogs: {
      state: dialogsState,
      clubSettingsOffers: {
        openEdit: openClubSettingsOffersEditDialog,
      },
      clubSettingsServices: {
        open: openClubSettingsServicesDialog,
      },
    },
  };
};

export type ClubSettingsServicesPageLocalState = ReturnType<
  typeof useClubSettingsServicesPageLocalState
>;
