/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import { AppInputBooleanSwitchRHF } from 'src/lib/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  ClubSettingsCommunicationDialogInitialState,
  ClubSettingsCommunicationFormModel,
} from '../../../_model';

export const ClubSettingsCommunicationMainForm_GeneralOptions = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsCommunicationFormModel>;
  initialState: ClubSettingsCommunicationDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();

  return (
    <ClubSettingsSection title="Options générales">
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        <AppFormControlRHF_Deprecated
          control={control}
          name={'clubSettings.communication.translation.enabledManual'}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              label={'Afficher le bouton de traduction automatique'}
            />
          )}
        />
      </div>
    </ClubSettingsSection>
  );
};
