/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  ClubParticipantAutoSupervisedDiveMode,
  DiveMode,
} from '@mabadive/app-common-model';
import {
  AutoSupervisedDiveModeChangeImpacts,
  DiveModeChangeImpacts,
  diveModeAnalyser,
} from '@mabadive/app-common-services';
import React, { useCallback } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { BookingParticipantEditFormRHFGroup1 } from './BookingParticipantEditFormRHFGroup1';
import { BookingParticipantEditFormRHFGroup2 } from './BookingParticipantEditFormRHFGroup2';
import { BookingParticipantEditFormRHFGroup3 } from './BookingParticipantEditFormRHFGroup3';
import { BookingParticipantEditFormRHFGroup4 } from './BookingParticipantEditFormRHFGroup4';

export const BookingParticipantEditFormRHF = ({
  form,
  maxWidthMd,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  maxWidthMd: boolean;
  className?: string;
}) => {
  const onDiveModeChange = useOnDiveModeChangesContextParticipant({ form });
  const onAutoSupervisedDiveModeChange =
    useOnAutoSupervisedDiveModeChangesContextParticipant({
      form,
    });

  return (
    <div className={`w-full flex flex-col gap-2 ${className}`}>
      {/* type de plongée, niveau/formation, plongée spéciale */}
      <BookingParticipantEditFormRHFGroup1
        maxWidthMd={maxWidthMd}
        form={form}
        onDiveModeChange={onDiveModeChange}
        onAutoSupervisedDiveModeChange={onAutoSupervisedDiveModeChange}
      />
      {/* aptitude, profondeur, gaz */}
      <BookingParticipantEditFormRHFGroup3
        maxWidthMd={maxWidthMd}
        form={form}
      />
      {/* matériel */}
      <BookingParticipantEditFormRHFGroup2
        maxWidthMd={maxWidthMd}
        form={form}
      />
      {/* remarques, tags, résa */}
      <BookingParticipantEditFormRHFGroup4
        maxWidthMd={maxWidthMd}
        form={form}
      />
    </div>
  );
};
// ATTENTION: dans BookingParticipantDefaultParamsEditFormRHF, fonction quasi similaire dupliquée pour l'édition des paramètres par défaut d'une plongée
function useOnDiveModeChangesContextParticipant({
  form,
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
}) {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;

  const { control, setValue } = form;

  const [
    previousDiveMode,
    firstDiveReference,
    mainCertificationReference1,
    mainCertificationReference2,
    gazType,
    participantCertificationReference,
    trainingReference,
  ] = useWatch({
    control,
    name: [
      'clubParticipant.diveMode',
      'clubParticipant.firstDiveReference',
      'diver.divingCertification1.mainCertificationReference',
      'diver.divingCertification2.mainCertificationReference',
      'clubParticipant.gaz.gazType',
      'clubParticipant.certificationReference',
      'clubParticipant.trainingReference',
    ],
  });

  const diverCertificationReference =
    mainCertificationReference1 ?? mainCertificationReference2;

  const onDiveModeChange = useCallback(
    (diveMode: DiveMode) => {
      // ATTENTION: dans BookingParticipantDefaultParamsEditFormRHF, fonction quasi similaire dupliquée pour l'édition des paramètres par défaut d'une plongée
      const diveModeChangeImpacts: DiveModeChangeImpacts =
        diveModeAnalyser.buildDiveModeChangeImpacts(diveMode, {
          clubSettings,
          diveCenterResume,
          gazType,
          firstDiveReference,
          participantCertificationReference,
          diverCertificationReference,
          trainingReference,
          previousDiveMode,
        });
      if (
        diveModeChangeImpacts.diveConfig.autoSupervisedDetails !== undefined
      ) {
        setValue(
          'clubParticipant.autoSupervisedDetails',
          diveModeChangeImpacts.diveConfig.autoSupervisedDetails,
        );
      }
      if (diveModeChangeImpacts.details.specialDiveType !== undefined) {
        setValue(
          'clubParticipant.details.specialDiveType',
          diveModeChangeImpacts.details.specialDiveType ?? undefined, // on remplace null par undefined
        );
      }
      if (diveModeChangeImpacts.diveConfig.gaz !== undefined) {
        setValue(
          'clubParticipant.gaz',
          diveModeChangeImpacts.diveConfig.gaz ?? undefined, // on remplace null par undefined
        );
      }

      if (diveModeChangeImpacts.diveConfig.firstDiveReference !== undefined) {
        setValue(
          'clubParticipant.firstDiveReference',
          diveModeChangeImpacts.diveConfig.firstDiveReference ?? undefined, // on remplace null par undefined
        );
      }
      if (diveModeChangeImpacts.diveConfig.trainingReference !== undefined) {
        setValue(
          'clubParticipant.trainingReference',
          diveModeChangeImpacts.diveConfig.trainingReference ?? undefined, // on remplace null par undefined
        );
      }
      if (
        diveModeChangeImpacts.diveConfig.certificationReference !== undefined
      ) {
        setValue(
          'clubParticipant.certificationReference',
          diveModeChangeImpacts.diveConfig.certificationReference ?? undefined, // on remplace null par undefined
        );
      }
    },
    [
      clubSettings,
      diveCenterResume,
      diverCertificationReference,
      firstDiveReference,
      gazType,
      participantCertificationReference,
      previousDiveMode,
      setValue,
      trainingReference,
    ],
  );
  return onDiveModeChange;
}

function useOnAutoSupervisedDiveModeChangesContextParticipant({
  form,
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
}) {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;

  const { control, setValue } = form;

  const [
    mainCertificationReference1,
    mainCertificationReference2,
    autoSupervisedCertificationReference,
  ] = useWatch({
    control,
    name: [
      'diver.divingCertification1.mainCertificationReference',
      'diver.divingCertification2.mainCertificationReference',
      'clubParticipant.autoSupervisedDetails.certificationReference',
    ],
  });

  const diverCertificationReference =
    mainCertificationReference1 ?? mainCertificationReference2;

  const onAutoSupervisedDiveModeChange = useCallback(
    (autoSupervisedDiveMode: ClubParticipantAutoSupervisedDiveMode) => {
      // ATTENTION: dans BookingParticipantDefaultParamsEditFormRHF, fonction quasi similaire dupliquée pour l'édition des paramètres par défaut d'une plongée
      const diveModeChangeImpacts: AutoSupervisedDiveModeChangeImpacts =
        diveModeAnalyser.buildAutoSupervisedDiveModeChangeImpacts(
          autoSupervisedDiveMode,
          {
            clubSettings,
            diveCenterResume,
            diverCertificationReference,
            autoSupervisedCertificationReference,
          },
        );

      if (diveModeChangeImpacts.diveConfig.trainingReference !== undefined) {
        setValue(
          'clubParticipant.autoSupervisedDetails.trainingReference',
          diveModeChangeImpacts.diveConfig.trainingReference ?? undefined, // on remplace null par undefined
        );
      }
      if (
        diveModeChangeImpacts.diveConfig.certificationReference !== undefined
      ) {
        setValue(
          'clubParticipant.autoSupervisedDetails.certificationReference',
          diveModeChangeImpacts.diveConfig.certificationReference ?? undefined, // on remplace null par undefined
        );
      }
    },
    [
      autoSupervisedCertificationReference,
      clubSettings,
      diveCenterResume,
      diverCertificationReference,
      setValue,
    ],
  );
  return onAutoSupervisedDiveModeChange;
}
