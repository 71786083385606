/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { ClubDiverSimilarSearchCandidatesState } from 'src/business/club/modules/club-diver-participant/ClubDiverSimilarSearch';
import {
  BookingMemberCardAttributeName,
  ClubDiverCardAttribute,
  ClubDiverCardAttributeName,
  useClubDiverCardAttributes,
} from 'src/business/club/modules/club-divers';

export const ClubDiverSimilarSearchCandidatePreview = ({
  similarSearchCandidatesState,
  className = '',
}: {
  similarSearchCandidatesState?: ClubDiverSimilarSearchCandidatesState;
  className?: string;
}) => {
  const { selectedDuplicatedCandidate } = similarSearchCandidatesState;
  const diver = selectedDuplicatedCandidate.diver;

  const { generalAttributes, diverAttributes } = useClubDiverCardAttributes({
    diver,
    additionalClubDiverAttributes: ['fullName', 'phoneNumber', 'emailAddress'],
  });
  const attributesToDisplay: (
    | ClubDiverCardAttributeName
    | BookingMemberCardAttributeName
  )[] = generalAttributes.concat(diverAttributes);
  // const attributesToDisplay: (
  //   | ClubDiverCardAttributeName
  //   | BookingMemberCardAttributeName
  // )[] = ['fullName'];

  return selectedDuplicatedCandidate ? (
    <div className={className}>
      <div className="w-full flex flex-wrap gap-4">
        {attributesToDisplay.map((attr) => (
          <ClubDiverCardAttribute
            key={attr}
            diver={diver}
            attributeName={attr}
          />
        ))}
      </div>
      <div className={clsx('my-8 flex gap-4')}>
        <AppButton
          className={''}
          color={'primary-bg'}
          size="normal"
          icon={AppIconsAction.confirm}
          onClick={() => {
            similarSearchCandidatesState.onConfirmSelectedDuplicatedCandidate(
              selectedDuplicatedCandidate,
            );
          }}
        >
          Choisir ce contact
        </AppButton>
        <AppButton
          color={'gray-outline-light'}
          size="normal"
          icon={AppIconsAction.close}
          onClick={() => {
            similarSearchCandidatesState.setSelectedDuplicatedCandidate(null);
          }}
        >
          Retour au formulaire
        </AppButton>
      </div>
    </div>
  ) : null;
};
