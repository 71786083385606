/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveCenterDailyConfig,
  DiveSessionStaffMemberTableModel,
} from '@mabadive/app-common-model';
import {
  diveSessionMultipleBuilder,
  diveSessionStaffMembersTableModelBuilder,
} from '@mabadive/app-common-services';
import React, { FC, useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { DiveSessionStaffMembersCountLabel } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components/DiveSessionStaffMembersTable/DiveSessionStaffMembersCountLabel';
import { DiveSessionSecuritySheetCardStaffMembersTable } from './DiveSessionSecuritySheetCardStaffMembersTable';

export const DiveSessionSecuritySheetCardStaffMembers: FC<{
  dailyConfig: DiveCenterDailyConfig;
  diveSession: ClubPlanningLightSessionDef;
  staffFirstNameBefore: boolean;
  onClick: () => any;
  className?: string;
}> = ({
  dailyConfig,
  diveSession,
  staffFirstNameBefore,
  onClick,
  className = '',
}) => {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const isMultiSessionConfigForStaff =
    diveSessionMultipleBuilder.isMultiSessionConfigForStaff(
      diveSession.diveTourSession2,
    );

  const staffMembersTableModel: DiveSessionStaffMemberTableModel = useMemo(
    () =>
      diveSessionStaffMembersTableModelBuilder.buildTableModel({
        clubParticipants: diveSession.participants,
        groups: diveSession.groups,
        isMultiSessionConfigForStaff,
        staffMembersFull:
          diveSessionStaffMembersTableModelBuilder.buildStaffMembersFull({
            staffMembers: diveCenterResume?.staffMembers,
            filterMode: 'active-today',
            clubParticipants: diveSession.participants,
            diveSession,
            groups: diveSession.groups,
            staffFirstNameBefore: true,
            dailyConfigs: dailyConfig ? [dailyConfig] : [],
            clubSettings: diveCenterResume?.clubResume?.clubSettings,
          }),
      }),
    [
      dailyConfig,
      diveCenterResume?.clubResume?.clubSettings,
      diveCenterResume?.staffMembers,
      diveSession,
      isMultiSessionConfigForStaff,
    ],
  );

  return staffMembersTableModel.rowsCount === 0 ? null : (
    <div
      className={`m-2 border-2 border-gray-600 flex flex-col gap-1 text-gray-600 ${
        onClick ? 'cursor-pointer' : ''
      } ${className}`}
      onClick={onClick}
    >
      <h2 className="text-center text-sm sm:text-lg leading-6 p-1 font-medium text-app-blue uppercase">
        Moniteurs{' '}
        <DiveSessionStaffMembersCountLabel
          staffMembersFull={staffMembersTableModel.staffMembersFull}
        />
      </h2>
      <DiveSessionSecuritySheetCardStaffMembersTable
        // className={className}
        date={diveSession.time}
        staffMembersTableModel={staffMembersTableModel}
        isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
        staffFirstNameBefore={staffFirstNameBefore}
      />
    </div>
  );
};
