/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@material-ui/core';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppLoadableContentContainer,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
  AppTabsBar,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { CLUB_LISTS_TABS } from '../_common';
import { clubListsUrlBuilder } from '../clubListsUrlBuilder.service';
import { ClubBookingsListPageConfigBar } from './ClubBookingsListPageConfigBar';
import { ClubBookingsTable } from './ClubBookingsTable/ClubBookingsTable';
import {
  ClubBookingsListPageLocalState,
  useClubBookingsListPageLocalState,
} from './useClubBookingsListPageLocalState.hook';

export const ClubBookingsListPage = () => {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const localState: ClubBookingsListPageLocalState =
    useClubBookingsListPageLocalState();

  const { filteredBookingsLimited, loadableContent } = localState;

  return (
    <AppPageContainerWithFixedBars
      marginBottom={false}
      paddingBottom={false}
      contentClassName="bg-gray-50 app-p-content"
      headerBar={() => (
        <>
          <AppTabsBar
            theme="primary"
            tabs={CLUB_LISTS_TABS}
            context={{}}
            value={'booking'}
            onChange={(tab) => {
              const url = clubListsUrlBuilder.buildClubListsTabUrl(tab);
              redirectTo(url);
            }}
            // disableNavigation={globalState?.updateState.hasChanges}
          />
          <div className="bg-gray-50 app-p-content">
            <ClubBookingsListPageConfigBar localState={localState} />
          </div>
        </>
      )}
    >
      <AppPageContentContainer
        className="app-card-no-padding"
        paddingBottom={false}
      >
        <AppLoadableContentContainer {...loadableContent}>
          <ClubBookingsTable bookings={filteredBookingsLimited} />
          {filteredBookingsLimited?.length === 0 ? (
            <div className="mx-2 my-3">
              <Typography variant="subtitle2">
                Aucune réservation disponible pour cette période
              </Typography>
            </div>
          ) : null}
        </AppLoadableContentContainer>
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
