/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { fakerClient } from 'src/business/_core/data/app-operation';
import { useAutoFill } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { FieldsToDisplayToggleButton } from '../../../DiverBookingPage/components/ClubDiverSelectorDialog/components';
import {
  BookingMemberDiverEditFormRHF,
  BookingMemberGeneralEditFormRHFFieldsToDisplay,
} from '../../../DiverBookingPage/forms';

import { BookingInitialPlanEditFormModel } from '../../../DiverBookingPage/forms/BookingInitialPlanEditForm/model';

export const CreateBookingInitialPlanCertificationPanel = ({
  form,
}: {
  form: UseFormReturn<BookingInitialPlanEditFormModel, any>;
}) => {
  const autoFill = useAutoFill();

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const [fieldsToDisplay, setFieldsToDisplay] =
    useState<BookingMemberGeneralEditFormRHFFieldsToDisplay>('frequent-only');

  return (
    <>
      <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
        Niveau et prestation souhaitée
      </h3>
      <div className="max-w-7xl mt-5 mx-1 relative">
        {autoFill && (
          <div className="absolute top-14 -right-1 p-1">
            <AppButton
              className="z-50"
              color={'primary-outline-light'}
              size="normal"
              icon={AppHeroIcons.autoFill}
              onClick={async () => {
                // setShowAutoFillCount(true);
                const { diver } = await fakerClient.generateDiver({
                  lastName: undefined,
                });
                const model: BookingInitialPlanEditFormModel = {
                  ...(formValue as BookingInitialPlanEditFormModel),
                  diver,
                };

                reset(model, {
                  keepDirty: true,
                });
                // onSubmit();
              }}
            ></AppButton>
          </div>
        )}
        <BookingMemberDiverEditFormRHF
          form={form as any}
          mode="create"
          fieldsToDisplay={fieldsToDisplay}
          isDiverWithMember={true}
        />
        <FieldsToDisplayToggleButton
          className="mt-5 text-right"
          displayAll={fieldsToDisplay === 'all'}
          onChange={(displayAll) => {
            if (displayAll) {
              setFieldsToDisplay('all');
            } else {
              setFieldsToDisplay('frequent-only');
            }
          }}
        />
      </div>
    </>
  );
};
