import {
  AggregatedBookingPurchasePaymentWithDetails,
  PaymentTabModelCounts,
} from '../../../models';
import { DiverBookingPageClubDiverLinkedData } from '../../01.loaded-content';
import { PaymentTabModelBuilderFilterCriteria } from './paymentTabModelBuilder.service';

export const billingTabModelBuilderPaymentsFilter = {
  filterPayments,
};

function filterPayments({
  purchasePaymentsWithDetails,
  criteria,
  counts,
  linkedData,
}: {
  purchasePaymentsWithDetails: AggregatedBookingPurchasePaymentWithDetails[];
  criteria: PaymentTabModelBuilderFilterCriteria & {
    filteredDiverId: string;
    currentDiveCenterId: string;
  };
  counts: PaymentTabModelCounts;
  linkedData: DiverBookingPageClubDiverLinkedData;
}) {
  return purchasePaymentsWithDetails.filter((paymentWithDetails) => {
    const purchasePayment = paymentWithDetails.purchasePayment;
    if (!linkedData?.diverIdsBookingLoaded.includes(purchasePayment.diverId)) {
      return false;
    }
    if (
      criteria.filteredDiverId &&
      criteria.filteredDiverId !== purchasePayment.diverId
    ) {
      counts.otherParticipants++;
      if (criteria.filteredDiverId) {
        counts.hiddenParticipants++;
        return false;
      }
    }
    if (
      criteria.currentDiveCenterId &&
      criteria.currentDiveCenterId !== purchasePayment.diveCenterId
    ) {
      counts.otherDiveCentersPayments++;
      if (criteria.ignoreOtherDiveCenters) {
        counts.hiddenPayments++;
        return false;
      }
    }

    return true;
  });
}
