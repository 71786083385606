import {
  AppCurrency,
  AppCurrencyIsoCode,
  APP_CURRENCIES,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';

export function useAppCurrencies(
  ...currencyIsoCodes: AppCurrencyIsoCode[]
): AppCurrency[] {
  const mainCurrency = useMemo(() => {
    return APP_CURRENCIES.filter((x) => currencyIsoCodes.includes(x.isoCode));
  }, [currencyIsoCodes]);

  return mainCurrency;
}
