import {
  DashboardPaymentsByPeriod,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { chartHelper } from 'src/pages/DA-dashboard/_core/services';
import { EvolutionActivityPaymentsByDayBarChartAttribute } from '../model';
import { evolutionActivityPaymentsByDayBarChartTooltipBuilder } from './evolutionActivityPaymentsByDayBarChartTooltipBuilder.service';

const COLORS: { [attr in ChartAttribute]: string } = {
  paymentsValidatedTotalSum: '#197297', // app-blue (tailwind)
  paymentsPendingTotalSum: '#e0831b', // app-orange (tailwind)
  // paymentsByMethod: '#555', // TODO
};

type ChartAttribute = EvolutionActivityPaymentsByDayBarChartAttribute;

export const EvolutionActivityPaymentsByDayBarChart = ({
  paymentsByPeriod,
  comparisonData,
  chartWidth,
  className,
}: {
  paymentsByPeriod: ProStatsFetchResults['paymentsByPeriod'];
  comparisonData: DashboardPaymentsByPeriod[];
  chartWidth: number;
  className?: string;
}) => {
  const data = useMemo(() => paymentsByPeriod.data, [paymentsByPeriod]);

  const stepInterval = paymentsByPeriod.criteria.stepInterval;

  const tooltip = useMemo(
    () =>
      evolutionActivityPaymentsByDayBarChartTooltipBuilder.build({
        stepInterval,
        colors: COLORS,
      }),
    [stepInterval],
  );
  const maxYValue: number = useMemo(() => {
    if (comparisonData) {
      const allMaxValues = paymentsByPeriod.data.map((x) => {
        return sumStackValues(x);
      });
      const allMaxValuesComparison = comparisonData.map((x) => {
        return sumStackValues(x);
      });
      return Math.max(...allMaxValues, ...allMaxValuesComparison);
    }
  }, [comparisonData, paymentsByPeriod.data]);

  return chartWidth > 0 ? (
    <div>
      <AreaChart
        className={className}
        width={chartWidth}
        height={200}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="beginDate"
          tickFormatter={(value) => {
            return chartHelper.formatDatePeriod(value, {
              stepInterval,
              format: 'short',
            });
          }}
        />
        <YAxis
          tickFormatter={(value) => {
            return value;
          }}
          domain={
            maxYValue ? [0, maxYValue] : undefined // auto
          }
        />

        <Tooltip content={tooltip} />
        {/* <Tooltip

        formatter={(
          value: any,
          attributeName: ChartAttribute,
          chartItem,
          index,
          payload,
        ) => {
          const color = COLORS[attributeName];
          return [
            <span key="value" style={{ color }}>
              {value}
            </span>,
            attributeName === 'paymentsValidatedSum'
              ? 'Participants quotidients inscrits'
              : 'Participants quotidients inscrits (mais annulés ensuite)',
          ];
        }}
        labelFormatter={(value, payload) => {
          return dateService.formatUTC(value, 'DD/MM/YYYY');
        }}
      /> */}

        {/* <ReferenceLine x={today.getTime()} stroke="red" /> */}
        <Area
          // maxBarSize={30}
          stackId="1"
          dataKey={'paymentsPendingTotalSum'}
          type="monotone"
          stroke={COLORS['paymentsPendingTotalSum']}
          fill={COLORS['paymentsPendingTotalSum']}
        />
        <Area
          // maxBarSize={30}
          stackId="1"
          dataKey={'paymentsValidatedTotalSum'}
          type="monotone"
          stroke={COLORS['paymentsValidatedTotalSum']}
          fill={COLORS['paymentsValidatedTotalSum']}
        />
      </AreaChart>
      {/* <p className="my-5 text-gray-400">
        <b className=" text-gray-500">NOTE:</b> les participants sont groupés
        par date de réservation, quelque soit la date de la prestation.
      </p> */}
    </div>
  ) : null;
};

function sumStackValues(x: DashboardPaymentsByPeriod) {
  const stackValues: number[] = [];
  stackValues.push(x.paymentsPendingTotalSum);
  stackValues.push(x.paymentsValidatedTotalSum);

  return stackValues.reduce((total, num) => total + num, 0);
}
