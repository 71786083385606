/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import { AppFormControlRHF_Deprecated, AppInputRHF } from 'src/lib/form';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';
import { DECOP_SessionItemDive_GroupDiverColumns } from './DECOP_SessionItemDive_GroupDiverColumns';
import { DECOP_SessionItemDive_GroupParamColumns } from './DECOP_SessionItemDive_GroupParamColumns';

export const DECOP_SessionItemDive_Group = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  sessionItemIndex,
  sessionItemDiveIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  sessionItemIndex: number;
  sessionItemDiveIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;
  const { control } = form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}.items.${sessionItemIndex}.items.${sessionItemDiveIndex}` as const;

  return (
    <div className={clsx('grid gap-2', className)}>
      <div className={clsx('grid md:grid-cols-2 md:items-start gap-2')}>
        <DECOP_SessionItemDive_GroupDiverColumns
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
          contentItemIndex={contentItemIndex}
          sessionItemIndex={sessionItemIndex}
          sessionItemDiveIndex={sessionItemDiveIndex}
        />
        <DECOP_SessionItemDive_GroupParamColumns
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
          contentItemIndex={contentItemIndex}
          sessionItemIndex={sessionItemIndex}
          sessionItemDiveIndex={sessionItemDiveIndex}
        />
      </div>
      <AppMessageLight>
        Pour modifier le titre par défaut d'une colonne, renseigner le champ
        correspondant ci-dessus
      </AppMessageLight>
      <h4 className="text-left text-base font-medium text-gray-600">
        Masquer ou limiter les plongeurs et instructeurs sur l'export Excel
      </h4>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
        <AppFormControlRHF_Deprecated
          label={'Nb max de GP par palanquée'}
          control={control}
          name={`${baseFormName}.groupMaxDiveGuides`}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF_Deprecated
          label={'Nb max de participants par palanquée'}
          control={control}
          name={`${baseFormName}.groupMaxParticipants`}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
      </div>
      <h4 className="text-left text-base font-medium text-gray-600">
        Ajout de lignes vides sous les participants
      </h4>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
        <AppFormControlRHF_Deprecated
          label={'Max de lignes à ajouter '}
          control={control}
          name={`${baseFormName}.addEmptyRows.maxEmptyRows`}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF_Deprecated
          label={'Max lignes par palanquée'}
          control={control}
          name={`${baseFormName}.addEmptyRows.maxTotalRows`}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF_Deprecated
          label={'Max lignes par palanquée de BPT'}
          control={control}
          name={`${baseFormName}.addEmptyRows.maxTotalRowsForFirstDive`}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
      </div>
      <h4 className="text-left text-base font-medium text-gray-600">
        Ajout de palanquées vides dans chaque session
      </h4>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
        <AppFormControlRHF_Deprecated
          label={'Max de palanquées à ajouter '}
          control={control}
          name={`${baseFormName}.addEmptyGroups.maxEmptyGroups`}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF_Deprecated
          label={'Max palanquées par session'}
          control={control}
          name={`${baseFormName}.addEmptyGroups.maxTotalGroups`}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />{' '}
        <AppFormControlRHF_Deprecated
          label={'Nombre de ligne par palanquée'}
          control={control}
          name={`${baseFormName}.addEmptyGroups.emptyRowsPerGroup`}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
      </div>
    </div>
  );
};
