import {
  ClubDiver,
  OnlineBookingParticipant,
  OnlineBookingResumeForList,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppDateTimeLabel,
  AppPriceLabel,
} from 'src/business/club/modules/_common/ui';

import { clubParticipantUrlBuilder } from 'src/business/club/modules/url-builders';
import { AppLinkStopPropagation } from 'src/lib/browser';
import { ColoredTagAvatarCard } from '../../../../business/_core/modules/layout';
import {
  AppIcons,
  EntitiesIcons,
} from '../../../../business/_core/modules/layout/icons';
import { BillingParticipantPaymentResumeTableDiverName } from '../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BillingParticipantPaymentResumeTable';
import {
  BookingGroupLabel,
  OrderStatusLabel,
} from '../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { useAppCurrencyMain } from '../../../_components/options';

export function ClubOrdersTableRow({
  onlineBooking,
}: {
  onlineBooking: OnlineBookingResumeForList;
}) {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const orderDiveCenter = useMemo(
    () =>
      clubResume.diveCenters.find(
        (dc) => dc._id === onlineBooking.diveCenterId,
      ),
    [onlineBooking.diveCenterId, clubResume.diveCenters],
  );

  const mainCurrency = useAppCurrencyMain();

  const url = useMemo(() => {
    const url = clubParticipantUrlBuilder.buildTabUrl({
      navigationContext: {
        origin: 'orders-list',
        diverId: onlineBooking.booking?.bookingContact?._id,
        bookingId: onlineBooking.bookingId,
      },
      tabId: 'booking',
    });
    return url;
  }, [onlineBooking.booking?.bookingContact?._id, onlineBooking.bookingId]);
  const activityDates = useMemo(
    () =>
      (onlineBooking.order?.articles ?? [])
        ?.map((x) => x.activityDate?.getTime())
        .filter((x) => !!x),
    [onlineBooking.order?.articles],
  );
  const beginDate = useMemo(() => {
    if (activityDates.length) {
      return new Date(Math.min(...activityDates));
    }
  }, [activityDates]);

  const endDate = useMemo(() => {
    if (activityDates.length) {
      return new Date(Math.max(...activityDates));
    }
  }, [activityDates]);

  const orderDateColor = useMemo(() => {
    const ageInHours = dateService.getDiffInHours(
      onlineBooking.onlineBookingDate,
      new Date(),
    );
    if (ageInHours <= 12) {
      return 'text-green-600';
    }
    if (ageInHours <= 48) {
      return 'text-orange-600';
    }
    return 'text-gray-600';
  }, [onlineBooking.onlineBookingDate]);

  const isOrderLastUpdateDateColor = useMemo(() => {
    const ageInHours = dateService.getDiffInHours(
      onlineBooking.onlineBookingLastUpdateDate,
      new Date(),
    );
    if (ageInHours <= 12) {
      return 'text-green-600';
    }
    if (ageInHours <= 48) {
      return 'text-orange-600';
    }
    return 'text-gray-600';
  }, [onlineBooking.onlineBookingLastUpdateDate]);

  const redirectTo = useRedirect();
  // const journeyDates = useMemo(
  //   () => orderJourneyStepTransformer.getJourneyDates(onlineBooking),
  //   [onlineBooking],
  // );

  const bookingParticipant: OnlineBookingParticipant = (
    onlineBooking.details?.bookingParticipants ?? []
  ).find(
    (bp) => bp.diver._id === onlineBooking.details?.bookingContact?.diverId,
  );

  return (
    <tr
      className={'app-tr-highlight'}
      onClick={() => {
        // si on clique sur la ligne, mais en dehors d'un lien, on est quand même redirigé
        redirectTo(url);
      }}
    >
      <td
        className={`whitespace-nowrap text-sm ${orderDateColor} hidden lg:table-cell`}
      >
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            date={onlineBooking.onlineBookingDate}
            timezone="local"
            format="date-time"
          />
        </AppLinkStopPropagation>
      </td>
      <td
        className={`whitespace-nowrap text-sm text-gray-600${isOrderLastUpdateDateColor} hidden xs:table-cell`}
      >
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="utc"
            date={onlineBooking.onlineBookingLastUpdateDate}
            format="date-time"
          />
        </AppLinkStopPropagation>
      </td>
      <td className="text-sm text-gray-600 truncate">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <div className="flex gap-2 justify-between">
            <div className="flex flex-col gap-1 items-start">
              <div className="flex flex-wrap items-start gap-2 xl:gap-4">
                <BillingParticipantPaymentResumeTableDiverName
                  diver={bookingParticipant?.diver as ClubDiver}
                  className={'flex-shrink'}
                />
                {onlineBooking.details?.bookingGroup && (
                  <BookingGroupLabel
                    bookingGroup={onlineBooking.details?.bookingGroup}
                  />
                )}
              </div>
            </div>
            {onlineBooking.state === 'new' && (
              <div>
                <AppIcons.ui.notification className="flex-0 w-6 h-6 px-0.5 sm:w-7 sm:h-7 sm:p-1 bg-status-error text-white rounded-full" />
              </div>
            )}
          </div>
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm hidden lg:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {
            beginDate ? (
              <div className="flex gap-2 text-gray-600 font-bold">
                <span>{dateService.formatLocal(beginDate, 'DD/MM/YYYY')}</span>
                {endDate && endDate.getTime() !== beginDate.getTime() && (
                  <>
                    <span>-</span>
                    <span>
                      {dateService.formatLocal(endDate, 'DD/MM/YYYY')}
                    </span>
                  </>
                )}
              </div>
            ) : null
            // <PeriodDatesLabel
            //   className="text-gray-600 font-bold"
            //   prefix="séjour "
            //   beginDate={journeyDates.arrival}
            //   endDate={journeyDates.departure}
            // />
          }
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm font-bold text-gray-600 hidden md:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {onlineBooking.totalParticipants}
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-600 hidden md:table-cell">
        <AppLinkStopPropagation
          className="flex gap-1 items-center px-2 py-1"
          to={url}
        >
          {(onlineBooking.deposits ?? []).map((d) => (
            <ColoredTagAvatarCard
              key={d._id}
              tag={{
                shape: 'square',
                reference: 'booking-deposit',
                widthAuto: false,
                avatar: {
                  label: '*',
                  background: 'transparent',
                  color:
                    d.purchasePaymentStatus === 'done' ? '#008000' : '#e0831b',
                  icon: EntitiesIcons.deposit as any,
                },
                content: {
                  label: 'Acompte',
                  background: 'transparent',
                  color:
                    d.purchasePaymentStatus === 'done' ? '#008000' : '#e0831b',
                  icon: EntitiesIcons.deposit as any,
                },
              }}
              size={'small'}
            />
          ))}
          <AppPriceLabel
            amount={onlineBooking.totalPrice}
            mainCurrency={mainCurrency}
          />
        </AppLinkStopPropagation>
      </td>
      <td className="">
        {/* {onlineBooking?.state==='draft' && (
          
        )} */}
        <OrderStatusLabel
          fontSizeClass="text-app-xxs md:text-xs"
          state={onlineBooking?.state}
        />
      </td>
    </tr>
  );
}
