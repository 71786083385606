/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettingsPlanningDiveTour,
  ClubSettingsPlanningPeriodConfig,
  ClubSettingsPlanningPeriodConfigAssistantDiveTourDef,
  ClubSettingsPlanningPeriodConfigDay,
} from '@mabadive/app-common-model';
import { virtualDiveTourReferenceBuilder } from '../ClubSettingsPlanningEditPage/virtualDiveTourReferenceBuilder.service';
import { ClubSettingsPlanningAssistantFormModel } from './model';

export const clubSettingsPlanningAssistantFormResultBuilder = {
  buildUpdatedPlanningPeriodConfig,
};

function buildUpdatedPlanningPeriodConfig({
  initialPeriodConfig,
  formValue,
}: {
  initialPeriodConfig: ClubSettingsPlanningPeriodConfig;
  formValue: ClubSettingsPlanningAssistantFormModel;
}): ClubSettingsPlanningPeriodConfig {
  const diveToursDef: ClubSettingsPlanningPeriodConfigAssistantDiveTourDef[] =
    formValue.assistant.diveToursDef;

  console.log('xxx initialPeriodConfig:', initialPeriodConfig);

  console.log('xxx diveToursDef:', diveToursDef);

  return {
    ...initialPeriodConfig,
    assistant: formValue.assistant,
    weekDays: initialPeriodConfig.weekDays.map((initialWeekDay, dayIndex) => {
      const newDiveTours: ClubSettingsPlanningDiveTour[] = [];

      diveToursDef.forEach((diveTourDef) => {
        console.log(
          'xxx initialWeekDay.isoWeekDay:',
          initialWeekDay.isoWeekDay,
        );
        console.log(
          'xxx diveTourDef.dates.isoWeekDays:',
          diveTourDef.dates.isoWeekDays,
        );
        if (diveTourDef.dates.isoWeekDays.includes(initialWeekDay.isoWeekDay)) {
          diveTourDef.dates.dailyTours
            .filter((x) => x.session1.dayTime)
            .forEach((dailyTour) => {
              // create tour

              const diveTour: ClubSettingsPlanningDiveTour = {
                reference: null,
                isOpen: true,
                type: diveTourDef.attributes.type,
                theme: diveTourDef.attributes.theme,
                defaultDiveMode: diveTourDef.attributes.details.defaultDiveMode,
                session1: {
                  dayTime: dailyTour.session1.dayTime,
                  diveSiteId: dailyTour.session1.diveSiteId,
                  name: diveTourDef.attributes.name,
                  special: diveTourDef.attributes.special,
                  emojiId: diveTourDef.attributes.emojiId,
                  boatsIds: diveTourDef.attributes.boatId
                    ? [diveTourDef.attributes.boatId]
                    : [],
                },
                session2: null, // not supported yet
              };

              // generate unique reference from criteria
              diveTour.reference =
                virtualDiveTourReferenceBuilder.generateUniqueDiveTourReference(
                  {
                    diveTour,
                    diveTours: initialWeekDay.diveTours,
                  },
                );
              newDiveTours.push(diveTour);
            });
        }
      });

      console.log('xxx newDiveTours:', newDiveTours);

      const weekDay: ClubSettingsPlanningPeriodConfigDay = {
        ...initialWeekDay,
        // isOpen: formValueDay.isOpen, // TODO
        diveTours: initialWeekDay.diveTours.concat(newDiveTours),
      };
      return weekDay;
    }),
  };
}
