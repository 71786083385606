import {
  BookingParticipantEditorFormModel,
  ClubParticipant,
  DiveCenterResume,
  DiveMode,
} from '@mabadive/app-common-model';
import { commonParticipantBuilder } from '@mabadive/app-common-services';
import {
  BookingParticipantEditorDialogStateMode,
  BookingParticipantEditorParticipant,
} from '../../../../models';

export const participantEditorFormInitialValueBuilder = {
  buildInitialFormValue,
};

function buildInitialFormValue({
  mode,
  participantData,
  diveCenterResume,
}: {
  participantData: BookingParticipantEditorParticipant;
  mode?: BookingParticipantEditorDialogStateMode;
  diveCenterResume: DiveCenterResume;
}): BookingParticipantEditorFormModel {
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  if (!participantData) {
    return {};
  }

  const diver = participantData.diver;
  const bookingMember = participantData.bookingMember;
  const diveSession = participantData.diveSession;

  const initialFormValue: BookingParticipantEditorFormModel = {
    diver,
    bookingMember,
    diveSession,
  };

  const participant: ClubParticipant = participantData.clubParticipant;

  if (participant) {
    initialFormValue.clubParticipant =
      commonParticipantBuilder.buildParticipantAttributesFromCurrentParticipant(
        {
          participant,
          sessionsCount: diveSession?.sessionsCount,
        },
      );
    initialFormValue.diveSession = diveSession;
  } else if (mode !== 'edit-diver') {
    const defaultDiveMode: DiveMode = diveSession?.details?.defaultDiveMode;

    initialFormValue.clubParticipant =
      commonParticipantBuilder.buildParticipantAttributesFromDefaultDiveConfig({
        diver,
        defaultDiveMode,
        diveCenterResume,
        sessionsCount: diveSession?.sessionsCount,
      });
  }

  return initialFormValue;
}
