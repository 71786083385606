/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { NEVER } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { useGlobalClasses } from 'src/AppTheme';
import {
  useAutoFill,
  useLoadable,
  useRedirect,
} from 'src/business/_core/data/hooks';
import { responsiveAttributesValues } from 'src/business/_core/modules/layout';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import {
  appStylesHelper,
  useCacheableClasses,
} from 'src/business/_core/modules/root/theme';
import { uiStore } from 'src/business/_core/store';
import { appWebLogger } from 'src/lib/browser';
import { AppForm } from 'src/lib/form/components/AppForm';
import {
  RxjsForm,
  rxjsFormActionManager,
} from 'src/lib/form/components/rxjs-form';
import { ApiClientHttpError } from 'src/_common-browser';
import { authenticationClient, authenticationStore } from '../../../services';
import { CreateClubAccountForm } from './CreateClubAccountForm';
import { CreateClubAccountFormModel } from './CreateClubAccountFormModel.type';
import { createClubAccountRepository } from './createClubAccountRepository.service';

export const CreateClubAccountArea = ({
  autoFocus,
}: {
  autoFocus?: boolean;
}) => {
  const redirectTo = useRedirect();

  const globalClasses = useGlobalClasses();

  const classes = useCacheableClasses({
    cacheKey: 'CreateClubAccountArea',
    buildStyles,
  });

  const { _loaded, createClubAccountForm, createClubAccountComponents } =
    useLoadable({
      debugName: 'CreateClubAccountArea',
      initialValueFromLoadSnapshot: true,
      load: () => createClubAccountRepository.loadInitialData(),
    });

  const [creationInProgress, setCreationInProgress] = useState(false);

  const autoFill = useAutoFill();

  return _loaded ? (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" className={classes.formTitle}>
          Créer mon compte d'essai gratuit
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AppForm bordered={true} hasChanges={createClubAccountForm.hasChanges}>
          <form
            noValidate
            autoComplete="off"
            onSubmit={(event) => {
              event.preventDefault();
              submitCreateAccountForm(createClubAccountForm);
              return false;
            }}
          >
            <CreateClubAccountForm
              components={createClubAccountComponents}
              autoFocus={autoFocus}
            />
            <Box className={globalClasses.buttonsBar} textAlign="center" mt={2}>
              {autoFill ? (
                <Button
                  startIcon={<AppIconsMaterial.auto />}
                  variant="contained"
                  className={globalClasses.buttonSave}
                  onClick={() =>
                    autoFillCreateAccountForm(createClubAccountForm)
                  }
                >
                  Auto-fill
                </Button>
              ) : null}
              <Button
                startIcon={<AppIconsMaterial.add />}
                type="submit"
                variant="contained"
                color="primary"
              >
                Créer mon compte gratuit
              </Button>
            </Box>
          </form>
        </AppForm>
      </Grid>
    </Grid>
  ) : null;

  function autoFillCreateAccountForm(
    form: RxjsForm<CreateClubAccountFormModel>,
  ) {
    form.actions.updateInitialValue({
      clubName: 'Club test',
      clubCity: 'Paris',
      userAuthEmailLogin: 'peter.smith@test.com',
      userAuthPassword: 'peter.smith@test.com',
    });
  }

  function submitCreateAccountForm(form: RxjsForm<CreateClubAccountFormModel>) {
    if (form.valid) {
      if (creationInProgress) {
        return;
      }
      setCreationInProgress(true);

      rxjsFormActionManager
        .saveChanges({
          form,
          isCreationMode: true,
          attributesToReplaceFully: [],
          createMethod: ({ form }) => {
            return authenticationClient.createTrialClubAccount({
              body: form.value,
            });
          },
          updateMethod: ({ form, patchOperations }) => NEVER,
        })
        .pipe(
          switchMap(({ success }) => {
            uiStore.snackbarMessage.set({
              type: 'success',
              content: 'Compté créé avec succès!',
            });
            return authenticationStore.auth.get().pipe(
              first(),
              map((appAuth) =>
                appRouteBuilder.getDefaultRouteFromAuth({ appAuth }),
              ),
              tap((defaultRoute) => {
                redirectTo(defaultRoute);
              }),
              catchError(() => {
                setCreationInProgress(false);
                return NEVER;
              }),
            );
          }),
          catchError((err: ApiClientHttpError) => {
            if (err.status === 409) {
              // conflict
              appLogger.error(
                'Conflict error while trying to create trial account',
                err,
              );
              uiStore.snackbarMessage.set({
                type: 'error',
                content:
                  'Cette adresse e-mail est déjà utilisée. Veuillez vous connecter en utilisant le formulaire ci-dessus.',
              });
            } else {
              appWebLogger.captureMessage(
                'Error while trying to create trial account',
                {
                  logContext: 'CreateClubAccountArea',
                  clubReference: undefined,
                  extra: {
                    form: form.value,
                    err,
                  },
                },
              );

              uiStore.snackbarMessage.set({
                type: 'error',
                content:
                  'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
              });
            }
            setCreationInProgress(false);
            return NEVER;
          }),
        )
        .subscribe();
    } else {
      form.actions.touchComponents();
    }
  }
};

function buildStyles(theme: Theme) {
  return makeStyles({
    formTitle: {
      ...responsiveAttributesValues(theme, {
        fontSize: appStylesHelper.getFontSizeResponsive('lg'),
      }),
    },
  });
}
