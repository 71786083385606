/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { AppFormControlV2, AppInputBooleanSwitch } from 'src/lib/form';

import clsx from 'clsx';
import { DataManagementPageLocalState } from './useDataManagementPageLocalState.hook';

export const DataManagementConfirmDeleteDataPanel = ({
  localState,
}: {
  localState: DataManagementPageLocalState;
}) => {
  const { state, actions } = localState;

  const { purgeConfig, setPurgeConfig } = state;

  return (
    <div className="app-card app-p-content ">
      <div>
        <div className={'flex gap-2 justify-start items-center rounded-t-lg'}>
          <div className={clsx('rounded-md p-1 text-status-error')}>
            <AppIconsAction.delete
              className={clsx('h-6 w-6')}
              aria-hidden="true"
            />
          </div>
          <div>
            <div className="uppercase text-xl leading-5 font-bold text-status-error">
              Suppression définitive des données
            </div>
          </div>
        </div>
        <div className="text-base text-gray-600">
          {'Les données seront définitivement perdues'}
        </div>
      </div>
      <div className="app-my-content flex flex-col gap-2">
        <AppFormControlV2
          label="Participants"
          renderComponent={() => (
            <AppInputBooleanSwitch
              value={purgeConfig.deleteParticipants}
              onChange={(deleteParticipants) => {
                setPurgeConfig({
                  ...purgeConfig,
                  deleteParticipants,
                });
              }}
              label="Supprimer tous les participants de la base de données"
            />
          )}
        />
        <AppFormControlV2
          label="Plongeurs"
          renderComponent={() => (
            <AppInputBooleanSwitch
              value={purgeConfig.deleteDivers}
              onChange={(deleteDivers) => {
                setPurgeConfig({
                  ...purgeConfig,
                  deleteDivers,
                });
              }}
              label="Supprimer tous les plongeurs de la base de données"
            />
          )}
        />
        <AppFormControlV2
          label="Sessions"
          renderComponent={() => (
            <AppInputBooleanSwitch
              value={purgeConfig.deleteDiveSessions}
              onChange={(deleteDiveSessions) => {
                setPurgeConfig({
                  ...purgeConfig,
                  deleteDiveSessions,
                });
              }}
              label="Supprimer toutes les sessions de la base de données"
            />
          )}
        />
      </div>
      <div className="mt-12 mb-4 flex justify-around">
        {state.isDataPurgeEnabled && (
          <>
            <AppButton
              color={'gray-outline-light'}
              icon={() => <AppIconsAction.cancel className="w-6 h-6 mr-2" />}
              tabIndex={500}
              onClick={(e) => {
                state.setMode('default');
              }}
            >
              Annuler
            </AppButton>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionDelete}
              color={'danger-outline-light'}
              tabIndex={500}
              onClick={async () => {
                await actions.purgeData();

                // if (
                //   (await confirmDialog.confirmPromise({
                //     title: 'Purger les données de démo',
                //     message:
                //     'Êtes-vous sûr de vouloir supprimer toutes les données de test?',
                //     type: 'noYesDanger',
                //   }))
                //   ) {
                //     const result = (await prompt('pass?'));
                //     if (result === 'clean') {
                //     await purgeData();
                //   }
                // }
              }}
            >
              <span className="font-bold">Purger les données maintenant</span>
            </AppButton>
          </>
        )}
      </div>
    </div>
  );
};
