import clsx from 'clsx';

import { XYCoord } from 'dnd-core';
import React, { FC, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
export const ItemTypes = {
  CARD: 'card',
};

const style = {
  border: '1px dashed #ccc',
  padding: '0.5rem 0.5rem',
  marginBottom: '.5rem',
  // backgroundColor: 'white',
  cursor: 'move',
};

export interface CardProps {
  id: any;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  className?: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const AppDragableCard: FC<React.PropsWithChildren<CardProps>> = ({
  id,
  children,
  index,
  moveCard,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<any, any, any>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      style={{ ...style, opacity }}
      className={clsx('border border-blue-400', className)}
    >
      <div
        className="flex justify-between items-center text-gray-500 font-bold uppercase text-xs"
        role="Handle"
        ref={ref}
        data-handler-id={handlerId}
      >
        <div className="flex justify-start items-center">
          <AppIcons.action.move className="h-3 w-3 mr-3" aria-hidden="true" />{' '}
          déplacer
        </div>
        <AppIcons.action.move className="h-3 w-3 mr-3" aria-hidden="true" />
      </div>
      <div className="cursor-default">{children}</div>
    </div>
  );
};
