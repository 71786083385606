import React, { useCallback, useMemo, useState } from 'react';
import { AppButton, AppMessage } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { buildParticipantEditorEditOpenInputState } from '../../components/useDiverBookingPageBookingCardLocalState.hook';
import {
  AggregatedBookingSessionFull,
  BookingParticipantEditorParticipant,
  BookingResumeParticipantForSession,
  SessionsHistoryTabModel,
} from '../../models';
import { sessionsHistoryTabModelBuilder } from '../../services';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverSessionHistoryCard } from './DiverSessionHistoryCard';

export function DiverBookingPageSessionsHistoryTab({
  globalState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) {
  const {
    aggregatedData,
    updateState,
    sessionsHistoryTabFilterCriteria,
    setSessionsHistoryTabFilterCriteria,
    diveCenterId,
    linkedData,
  } = globalState;

  const { setBookingParticipantEditorState } = dialogs;
  const { openSessionDialog } = actions;

  const model: SessionsHistoryTabModel = useMemo(
    () =>
      sessionsHistoryTabModelBuilder.buildSessionsHistoryTabModel({
        aggregatedData,
        criteria: {
          ...sessionsHistoryTabFilterCriteria,
          filteredDiversIds: updateState.filteredDiverId
            ? [updateState.filteredDiverId]
            : linkedData?.diverIdsBookingLoaded,
          currentDiveCenterId: diveCenterId,
        },
        linkedData,
      }),
    [
      aggregatedData,
      diveCenterId,
      linkedData,
      sessionsHistoryTabFilterCriteria,
      updateState.filteredDiverId,
    ],
  );
  const onClickParticipant = useCallback(
    ({ bookingParticipantFull }: BookingResumeParticipantForSession) => {
      const participant: BookingParticipantEditorParticipant =
        buildParticipantEditorEditOpenInputState({
          bookingParticipantFull,
        });
      setBookingParticipantEditorState({
        isOpen: true,
        mode: 'edit-participant',
        participant,
      });
    },
    [setBookingParticipantEditorState],
  );
  const onClickSession = useCallback(
    ({ diveSession }: AggregatedBookingSessionFull) => {
      openSessionDialog({
        diveSession,
      });
    },
    [openSessionDialog],
  );

  const [pastSessionsMaxLength, setPastSessionsMaxLength] = useState(10);

  const pastSessionsToDisplay = useMemo(
    () => model.pastSessions.slice(0, pastSessionsMaxLength),
    [model.pastSessions, pastSessionsMaxLength],
  );

  return model ? (
    <div className="app-p-content w-full sm:max-w-2xl md:max-w-4xl xl:max-w-5xl">
      {(model.counts.hiddenParticipants > 0 ||
        model.counts.otherDiveCentersParticipants > 0 ||
        model.counts.cancelledParticipants > 0) && (
        <div className="my-2 flex justify-between gap-2 flex-wrap">
          <div className="flex gap-2 flex-wrap">
            {model.counts.otherDiveCentersParticipants > 0 && (
              <AppInputBooleanSwitch
                label={`autres centres (${model.counts.otherDiveCentersParticipants})`}
                value={!sessionsHistoryTabFilterCriteria.ignoreOtherDiveCenters}
                onChange={(value) =>
                  setSessionsHistoryTabFilterCriteria({
                    ...sessionsHistoryTabFilterCriteria,
                    ignoreOtherDiveCenters: !value,
                  })
                }
              />
            )}
            {model.counts.cancelledParticipants > 0 && (
              <AppInputBooleanSwitch
                label={`prestations annulées (${model.counts.cancelledParticipants})`}
                value={
                  !sessionsHistoryTabFilterCriteria.ignoreCancelledParticipants
                }
                onChange={(value) =>
                  setSessionsHistoryTabFilterCriteria({
                    ...sessionsHistoryTabFilterCriteria,
                    ignoreCancelledParticipants: !value,
                  })
                }
              />
            )}
          </div>
          {model.counts.hiddenParticipants > 0 && (
            <div className="font-bold text-app-red px-2">
              {model.counts.hiddenParticipants} prestation
              {model.counts.hiddenParticipants === 1 ? '' : 's'} masquée
              {model.counts.hiddenParticipants === 1 ? '' : 's'}
            </div>
          )}
        </div>
      )}
      <div className="w-full flex flex-col gap-y-4 sm:flex-row gap-x-4">
        <div className="w-full sm:w-1/2">
          <div className="my-2 font-bold uppercase">
            Plongées passées (
            {pastSessionsMaxLength < model.pastSessions?.length
              ? `${pastSessionsMaxLength}/${model.pastSessions?.length}`
              : model.pastSessions?.length}
            )
          </div>{' '}
          {pastSessionsToDisplay?.length ? (
            <div
              className={'mt-2 w-full sm:max-w-sm grid gap-y-4 items-stretch'}
            >
              {pastSessionsToDisplay.map(
                ({ bookingSessionFull, participants }, j) => (
                  <DiverSessionHistoryCard
                    key={j}
                    bookingSessionFull={bookingSessionFull}
                    participants={participants}
                    onClickParticipant={onClickParticipant}
                    onClickSession={onClickSession}
                  />
                ),
              )}
              {pastSessionsToDisplay.length !== model.pastSessions.length && (
                <AppButton
                  icon={AppHeroIcons.eye}
                  color="gray-outline-light"
                  onClick={() => {
                    setPastSessionsMaxLength(pastSessionsMaxLength * 2);
                  }}
                >
                  Afficher davantage
                </AppButton>
              )}
            </div>
          ) : (
            <AppMessage
              title="Aucune plongée passée"
              message={
                <div>
                  <p>Aucune plongée passée.</p>
                </div>
              }
            />
          )}
        </div>
        <div className="w-full sm:w-1/2">
          <div className="my-2 font-bold uppercase">
            Plongées à venir ({model.futureSessions?.length})
          </div>{' '}
          {model.futureSessions?.length ? (
            <div className={'mt-2 w-full max-w-sm grid gap-y-4'}>
              {model.futureSessions.map(
                ({ bookingSessionFull, participants }, j) => (
                  <div key={j}>
                    <DiverSessionHistoryCard
                      bookingSessionFull={bookingSessionFull}
                      participants={participants}
                      onClickParticipant={onClickParticipant}
                      onClickSession={onClickSession}
                    />
                  </div>
                ),
              )}
            </div>
          ) : (
            <AppMessage
              title="Aucune plongée à venir"
              message={
                <div>
                  <p>Aucune plongée de planifiée.</p>
                </div>
              }
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
}
