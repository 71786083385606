import { ClubCommercePurchasePackageGql_Company } from '@mabadive/app-common-model';
import { bookingProductResumeAttributesBuilder } from '../default-booking/bookingProductResumeAttributesBuilder.service';

export const purchaseResumeAttributesBuilder = {
  buildAttributes,
};

function buildAttributes() {
  return `
    ${ClubCommercePurchasePackageGql_Company}
    paymentsPackageDetails {
        _id
        amount
        purchasePaymentId
        purchasePackageId
    }
    contact: diver {
      _id
      firstName
      lastName
      phoneNumber
      emailAddress
      birthdate
      age
    }
`;
// 11/12/2023 => supprimé les produits, qui n'était plus utilisés, donc chargés pour rien!
// bookingProducts {
//   ${bookingProductResumeAttributesBuilder.buildAttributes()}
// }
}
