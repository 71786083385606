/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback } from 'react';
import {
  AppButtonsBar,
  AppLoader,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
  AppTabsBar,
} from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubDiveSessionHeaderCard } from 'src/business/club/modules/club-dive-session/components';
import { SetPlanningConfigOptions } from 'src/business/club/modules/club-planning-light/hooks';
import { PlanningViewConfig } from 'src/business/club/modules/club-planning/_model';
import {
  ClubResumeStaffMemberFormModel,
  StaffMemberFormLocalState,
} from 'src/pages/SE-settings/SE-01_dive-center/staff-members/StaffMemberEditPage/model';
import { useStaffMemberFormLocalState } from 'src/pages/SE-settings/SE-01_dive-center/staff-members/StaffMemberEditPage/useStaffMemberFormLocalState.hook';
import { bookingUpdateStateBuilder } from '../../bookingUpdateStateBuilder.service';
import { DiveSessionEditorFormModel } from '../../forms/DiveSessionEditForm/model';
import {
  DiveSessionEditorDialogInputState,
  DiverBookingPageUpdateState,
} from '../../models';
import { diverBookingPageClientUpdator } from '../../services';
import { DIVE_SESSION_EDITOR_TABS } from './DiveSessionEditorTab';
import { DiveSessionDialogTab1Edit, DiveSessionDialogTab3Groups } from './tabs';
import {
  DiveSessionDialogTab2StaffMemberEdit,
  DiveSessionDialogTab2StaffMembersList,
  diveSessionDialogTab2StaffMemberEditStateUpdator,
} from './tabs/DiveSessionDialogTab2StaffMembers';
import { useDiveSessionEditorDialogLocalState } from './useDiveSessionEditorDialogLocalState.hook';

export const DiveSessionEditorDialog: FC<{
  state: DiveSessionEditorDialogInputState;
  setState: (s: DiveSessionEditorDialogInputState) => void;
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
}> = ({
  state: inputState,
  setState: setInputState,
  planningConfig,
  setPlanningConfig,
}) => {
  const globalClasses = useGlobalClasses();

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setInputState({
        ...inputState,
        isOpen,
      });
    },
    [inputState, setInputState],
  );

  const clubResume = useClubResume();

  const localState = useDiveSessionEditorDialogLocalState({
    inputState,
  });

  const {
    form,
    aggregatedData,
    clubReference,
    isPersistInProgress,
    setIsPersistInProgress,
    updateState,
    setUpdateState,
    tabId,
    setTabId,
    staffMemberIdToEdit,
    initialValue,
    data,
  } = localState;

  const diveSession = aggregatedData.diveSession;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const submitForm = useCallback(() => {
    handleSubmit(
      async (formValue: DiveSessionEditorFormModel, event) => {
        setIsPersistInProgress(true);
        try {
          const newState: Partial<DiverBookingPageUpdateState> =
            bookingUpdateStateBuilder.createEmptyUpdateState({
              ...updateState,
              hasChanges: true,
            });
          await diverBookingPageClientUpdator.persistChanges(newState, {
            logContext: 'edit dive session',
            bookingIdsToClean: [],
          });

          if (newState.diveSessionsChanges.length) {
            const newDiveSession = newState.diveSessionsChanges[0].created;

            if (
              newDiveSession &&
              localState &&
              planningConfig.viewPeriod !== 'week'
            ) {
              setPlanningConfig(
                {
                  ...planningConfig,
                  // focusSessionReference: newDiveSession.reference,
                  focusDate: newDiveSession.time,
                  viewPeriod: 'day', // la session n'est pas encore chargée, il faudrait gérer ça, mais en attendant le plus simple est d'afficher la vue journée
                },
                {
                  origin: 'session editor dialog',
                },
              );
              // uiStore.navigateToPlanningSessionWhenLoaded.set({
              //   ...planningConfig,
              //   focusSessionReference: newDiveSession.reference,
              //   focusDate: newDiveSession.time,
              // })
              // setTimeout(
              //   () =>
              //     // hack: ajout d'un timeout le temps que la nouvelle session soit chargée
              //     // TODO: gérer un aggregatedState comme sur la page booking afin de pouvoir rafraichir instantanément la page
              //     setPlanningConfig(),
              //   1000,
              // );
            }
          }
          setInputState({
            isOpen: false,
          });
        } finally {
          setIsPersistInProgress(false);
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [
    handleSubmit,
    localState,
    planningConfig,
    setInputState,
    setIsPersistInProgress,
    setPlanningConfig,
    updateState,
  ]);

  const staffMemberFormLocalState: StaffMemberFormLocalState =
    useStaffMemberFormLocalState({
      mode: 'edit',
      staffMemberId:
        tabId === 'edit-staff-members' ? staffMemberIdToEdit : undefined,
      staffMembers: aggregatedData.staffMembers,
    });

  return !inputState.isOpen ? null : (
    <AppPageContainerWithFixedBars
      id="dialog-scrollable-container"
      className="max-w-screen-2xl"
      contentClassName="bg-white"
      headerBar={() => (
        <div>
          {diveSession && (
            <ClubDiveSessionHeaderCard
              className="flex-grow bg-white"
              diveSession={diveSession}
              widthMode="large"
            />
          )}
          {inputState.mode === 'edit-session' &&
            (tabId === 'edit-session' ||
              tabId === 'edit-dive-groups' ||
              tabId === 'list-staff-members') && (
              <AppTabsBar
                theme="session"
                tabs={DIVE_SESSION_EDITOR_TABS}
                context={{
                  diveSessionReference:
                    initialValue.initialDiveSession?.reference,
                }}
                value={tabId}
                onChange={(tab) => setTabId(tab.id)}
                // disableNavigation={globalState?.updateState.hasChanges}
              />
            )}
        </div>
      )}
      footerBar={() => (
        <AppButtonsBar className="" hasChanges={formState.isDirty}>
          <>
            <AppButton
              fullWidth={true}
              icon={AppHeroIcons.actionCancel}
              color="gray-outline-light"
              size="normal"
              onClick={() => {
                if (tabId === 'edit-staff-members') {
                  setTabId(data.previousTabId);
                } else {
                  setIsOpen(false);
                }
              }}
            >
              Annuler
            </AppButton>
            <AppButton
              disabled={isPersistInProgress}
              fullWidth={true}
              icon={AppHeroIcons.actionSave}
              color="primary-outline-light"
              onClick={() => {
                if (tabId === 'edit-staff-members') {
                  staffMemberFormLocalState.form.handleSubmit(
                    (formValue: ClubResumeStaffMemberFormModel) => {
                      const localUpdateState =
                        diveSessionDialogTab2StaffMemberEditStateUpdator.updateStaffMember(
                          {
                            updateState,
                            staffMemberFormLocalState,
                            formValue,
                          },
                        );
                      setUpdateState(localUpdateState);
                      setTabId(data.previousTabId);
                    },
                  )();
                } else {
                  submitForm();
                }
              }}
            >
              {tabId === 'edit-staff-members' ? 'Confirmer' : 'Enregistrer'}
            </AppButton>
          </>
        </AppButtonsBar>
      )}
    >
      {isPersistInProgress ? (
        <AppPageContentContainer className="bg-gray-50 app-p-content">
          <AppLoader className="my-4" type="persist" />
        </AppPageContentContainer>
      ) : (
        <>
          {tabId === 'edit-session' && (
            <DiveSessionDialogTab1Edit localState={localState} />
          )}
          {diveSession &&
            inputState.mode === 'edit-session' &&
            tabId === 'edit-dive-groups' && (
              <DiveSessionDialogTab3Groups localState={localState} />
            )}
          {diveSession &&
            inputState.mode === 'edit-session' &&
            tabId === 'list-staff-members' && (
              <DiveSessionDialogTab2StaffMembersList localState={localState} />
            )}
          {diveSession &&
            inputState.mode === 'edit-session' &&
            tabId === 'edit-staff-members' &&
            staffMemberIdToEdit && (
              <DiveSessionDialogTab2StaffMemberEdit
                localState={localState}
                staffMemberFormLocalState={staffMemberFormLocalState}
              />
            )}
        </>
      )}
    </AppPageContainerWithFixedBars>
  );
};
