import React from 'react';
import { BillingTabParticipantPurchaseDivesCounts } from '../../models';

export function BillingParticipantPurchaseResumeTableDivesCounts({
  divesCounts,
  isVirtual,
  opacity,
  className,
}: {
  divesCounts: BillingTabParticipantPurchaseDivesCounts;
  isVirtual: boolean;
  opacity?: 60;
  className?: string;
}) {
  return (
    <div className={className}>
      <span
        className={`text-app-blue ${
          opacity === 60 ? 'text-opacity-60' : ''
        } font-bold`}
      >
        {isVirtual
          ? divesCounts.assigned + divesCounts.toAssign
          : divesCounts.assigned}
      </span>
      {!isVirtual && divesCounts.toAssign > 0 && (
        <>
          <span
            className={`text-app-blue ${
              opacity === 60 ? 'text-opacity-60' : ''
            } font-bold mx-1`}
          >
            +
          </span>
          <span
            className={`text-app-blue ${
              opacity === 60 ? 'text-opacity-60' : ''
            } font-bold`}
          >
            {divesCounts.toAssign}
          </span>
        </>
      )}
      {divesCounts.consumedExternalCount > 0 && (
        <>
          <span
            className={`text-app-orange ${
              opacity === 60 ? 'text-opacity-60' : ''
            } font-bold mx-1`}
          >
            +
          </span>
          <span
            className={`text-app-orange ${
              opacity === 60 ? 'text-opacity-60' : ''
            } font-bold`}
          >
            {divesCounts.consumedExternalCount}
          </span>
        </>
      )}
      {divesCounts.consumedExternalCount < 0 && (
        <>
          <span
            className={`text-app-orange ${
              opacity === 60 ? 'text-opacity-60' : ''
            } font-bold mx-1`}
          >
            -
          </span>
          <span
            className={`text-app-orange ${
              opacity === 60 ? 'text-opacity-60' : ''
            } font-bold`}
          >
            {-divesCounts.consumedExternalCount}
          </span>
        </>
      )}
      {!!divesCounts.future && (
        <>
          <span
            className={`text-gray-400 ${
              opacity === 60 ? 'text-opacity-60' : ''
            } font-bold mx-1`}
          >
            +
          </span>
          <span
            className={`text-gray-400 ${
              opacity === 60 ? 'text-opacity-60' : ''
            } font-bold`}
          >
            {divesCounts.future}
          </span>
        </>
      )}
      {!!divesCounts.cancelled && (
        <>
          <span
            className={`ml-2 text-gray-400 ${
              opacity === 60 ? 'text-opacity-60' : ''
            } text-xs`}
          >
            (
            <span
              className={`text-app-red ${
                opacity === 60 ? 'text-opacity-60' : ''
              }`}
            >
              + {divesCounts.cancelled}
            </span>
            )
          </span>
        </>
      )}
    </div>
  );
}
