/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubDiveSiteType } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppEmojiPickerPopoverRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { useDiveSitesTypesOptions } from 'src/pages/_components/options';
import { DiveSiteFormLocalState } from '../model';

export const DiveSiteEditForm = ({
  localState,
}: {
  localState: DiveSiteFormLocalState;
}) => {
  const { form, diveSiteGroups } = localState;
  const { control, setValue } = form;

  const diveSitesGroupsOptions = useMemo(
    () =>
      diveSiteGroups.map((g) => {
        const option: ValueLabel<string> = {
          label: g.name,
          value: g._id,
        };
        return option;
      }),
    [diveSiteGroups],
  );

  const diveSitesTypesOptions: ValueLabel<ClubDiveSiteType>[] =
    useDiveSitesTypesOptions();

  const [name, emojiId] = useWatch({
    control,
    name: ['name', 'emojiId'],
  });
  return (
    <div className="px-5">
      <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Site de plongée{' '}
        <span className="font-bold text-app-orange">
          {name?.trim()?.length > 0 && `"${name.toUpperCase()}"`}
        </span>
      </h2>
      <div className={'w-full flex flex-col xs:grid xs:grid-cols-6 gap-4'}>
        <AppFormControlRHF_Deprecated
          className={'col-span-4 w-full'}
          label="Nom"
          control={control}
          name={'name'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />

        <AppFormControlRHF_Deprecated
          className={'col-span-2 w-full'}
          label={'Icône Emoji'}
          control={control}
          name={'emojiId'}
          renderComponent={(props) => <AppEmojiPickerPopoverRHF {...props} />}
        />
        <AppFormControlRHF_Deprecated
          className={'col-span-4 w-full'}
          label={'Groupe'}
          required={true}
          control={control}
          name={'groupId'}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              theme="material-ui"
              disableClearButton={true}
              className="w-full text-gray-600"
              options={diveSitesGroupsOptions}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'col-span-2 w-full'}
          label={'Type'}
          required={false}
          control={control}
          name={'type'}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              // theme="material-ui"
              // disableClearButton={true}
              className="w-full text-gray-600"
              options={diveSitesTypesOptions}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'col-span-2 w-full'}
          label="Tombant"
          control={control}
          name={'wall'}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        <AppFormControlRHF_Deprecated
          className={'col-span-2 w-full'}
          label="Épave"
          control={control}
          name={'wreck'}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        <AppFormControlRHF_Deprecated
          className={'col-span-2 w-full'}
          label="Artificiel"
          control={control}
          name={'manMade'}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />

        <AppFormControlRHF_Deprecated
          className={'col-span-2 w-full'}
          label="Numéro"
          control={control}
          name={'ref'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'col-span-2 w-full'}
          label="Distance (nmi)"
          control={control}
          name={'distance'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'col-span-2 w-full'}
          label="Profondeur (m)"
          control={control}
          name={'depth'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'col-span-6 w-full'}
          label="Description"
          control={control}
          name={'description'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth rows={3} />
          )}
        />
      </div>
    </div>
  );
};
