/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { ClubPlanningDayFull } from '../../../club-planning-light/model';
import { ClubPlanningStaffWeekStaffPresenceHeaderSessionsDaySession } from './ClubPlanningStaffWeekStaffPresenceHeaderSessionsDaySession';
import { ClubPlanningStaffWeekStaffPresenceLocalState } from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';

export const ClubPlanningStaffWeekStaffPresenceHeaderSessionsDay = ({
  day,
  localState,
  className,
}: {
  day: ClubPlanningDayFull;
  localState: ClubPlanningStaffWeekStaffPresenceLocalState;
  className?: string;
}) => {
  const {
    data,
    actions,
    criteria,
    state: { showDayWithoutSessionsDetails },
  } = localState;

  const showDetails =
    day.diveSessionsNotCanceled.length > 0 || showDayWithoutSessionsDetails;

  // TODO gérer les journées fermées (grace à la table inutilée club_settings_day) */
  const [isDayOff, setIsDayOff] = useState(false);

  return (
    <div className={className}>
      <div className={'w-full grid'}>
        {day.diveSessionsNotCanceled.length === 0 && (
          <div>
            <div
              className={
                'py-1 px-2 text-center text-gray-500 hidden sm:block text-app-xxs md:text-xs font-medium uppercase'
              }
            >
              Aucune session
            </div>
            {/* // TODO gérer les journées fermées (grace à la table inutilée club_settings_day) */}
            {/* {!showDetails && (
              <div className="w-full flex justify-center">
                <AppInputBooleanSwitch
                  theme={'danger'}
                  value={isDayOff}
                  label={'Fermé'}
                  onChange={(value) => setIsDayOff(value)}
                />
              </div>
            )} */}
          </div>
        )}
        {day.diveSessionsNotCanceled.map((session, iDailySession) => {
          return (
            <div key={iDailySession} className={''}>
              <>
                <ClubPlanningStaffWeekStaffPresenceHeaderSessionsDaySession
                  localState={localState}
                  session={session}
                  sessionIndex={{
                    index: iDailySession + 1,
                    total: day.sessions.length,
                  }}
                />
              </>
            </div>
          );
        })}
      </div>
    </div>
  );
};
