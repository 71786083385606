/* eslint-disable @typescript-eslint/no-unused-vars */
import { languageFlagConvertor } from '@mabadive/app-common-model';
import { nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { ParticipantDiverAgeLabel } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant';
import { AppInputBooleanRHF, AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { CreateMessageToCustomersFormModel } from '../model';
import { CreateMessageToCustomersAggregatedRecipient } from '../model/CreateMessageToCustomersAggregatedRecipient.type';

export const CreateMessageToCustomersFormStep2_RecipientsTableRow = ({
  refDate,
  recipient,
  formDiverIndex,
  form,
  className = '',
}: {
  refDate: Date;
  recipient: CreateMessageToCustomersAggregatedRecipient;
  formDiverIndex: number;
  form: UseFormReturn<CreateMessageToCustomersFormModel>;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const bookingStatus = recipient?.formRecipient?.bookingStatus;
  const diver = recipient?.formRecipient?.diver;
  const isBookingContact = recipient?.formRecipient?.isBookingContact;

  const { control, setValue } = form;

  const [messageTarget, isSelected] = useWatch({
    control,
    name: ['messageTarget', `recipients.${formDiverIndex}.isSelected`],
  });

  const toggleSelected = useCallback(() => {
    // if (p?.isValidContactInfo) {
    setValue(`recipients.${formDiverIndex}.isSelected`, !isSelected);
    // }
  }, [formDiverIndex, isSelected, setValue]);

  return (
    <tr className={clsx(className)}>
      <td className="pr-1 md:pr-3 whitespace-nowrap text-left text-sm font-medium cursor-pointer">
        <AppFormControlRHF
          className="-mt-1
                  "
          // label="Vérifié?"
          control={control}
          name={`recipients.${formDiverIndex}.isSelected`}
          renderComponent={(props) => (
            <AppInputBooleanRHF
              className="scale-125 block mx-auto mt-2"
              {...props}
              type="checkbox"
              color={!!recipient?.targetValidContact ? 'blue' : 'red'}
            />
          )}
        />
      </td>
      <td
        className="select-none py-1 px-1 whitespace-nowrap text-left text-sm cursor-pointer"
        onClick={() => toggleSelected()}
      >
        {/* {nameFormatter.formatFullName(participant.diver)} */}
        <div
          className={clsx(
            'min-w-[35vw] sm:min-w-[12rem] flex whitespace-nowrap flex-wrap',
            bookingStatus === 'cancelled' && 'line-through text-red-500',
          )}
        >
          {diver.lastName ? (
            <span className={'truncate font-bold mr-1'}>
              {nameFormatter.formatLastName(diver)}
            </span>
          ) : null}
          <span className={'truncate flex-shrink'}>
            {nameFormatter.formatFirstName(diver)}
          </span>
        </div>
        <div className="flex gap-2">
          {diver.languageCode && (
            <>{languageFlagConvertor.getFlag(diver.languageCode)}</>
          )}
          <ParticipantDiverAgeLabel
            refDate={refDate}
            diver={recipient.formRecipient.diver}
            filter="none"
          />
          {isBookingContact && (
            <span className="uppercase px-0.5 text-app-orange font-medium text-app-xxs">
              contact résa
            </span>
          )}
        </div>
      </td>
      <td
        className={clsx(
          'py-1 px-1 whitespace-nowrap text-right text-sm font-medium',
          messageTarget !== 'email' && 'hidden lg:table-cell',
        )}
      >
        <AppFormControlRHF
          key={formDiverIndex}
          className={'min-w-48 w-full'}
          // label="Téléphone"
          control={control}
          name={`recipients.${formDiverIndex}.diver.emailAddress`}
          validation={{
            email: true,
          }}
          renderComponent={(props) => (
            <AppInputRHF
              {...props}
              fullWidth
              type="text"
              onChange={(value) => {
                if (messageTarget === 'email') {
                  setValue(`recipients.${formDiverIndex}.isSelected`, !!value);
                }
              }}
            />
          )}
        />
      </td>
      <td
        className={clsx(
          'py-1 px-1 whitespace-nowrap text-right text-sm font-medium',
          messageTarget === 'email' && 'hidden lg:table-cell',
        )}
      >
        <AppFormControlRHF
          key={formDiverIndex}
          className={'min-w-48 w-full'}
          // label="Téléphone"
          control={control}
          name={`recipients.${formDiverIndex}.diver.phoneNumber`}
          renderComponent={(props) => (
            <AppInputRHF
              {...props}
              fullWidth
              type="text"
              onChange={(value) => {
                if (messageTarget !== 'email') {
                  setValue(`recipients.${formDiverIndex}.isSelected`, !!value);
                }
              }}
            />
          )}
        />
      </td>
    </tr>
  );
};
