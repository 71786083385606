import {
  APP_COMPANY_MESSAGE_TARGET_MODES,
  AppCompanyMessageTargetMode,
} from '@mabadive/app-common-model';
import { appCompanyMessageTargetModeFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form';

export function useAppCompanyMessageTargetModesOptions() {
  return APP_COMPANY_MESSAGE_TARGET_MODES.map(
    (mode: AppCompanyMessageTargetMode) => {
      const option: ValueLabel<AppCompanyMessageTargetMode, React.ReactNode> = {
        value: mode,
        label: appCompanyMessageTargetModeFormatter.formatMode(mode),
      };
      return option;
    },
  );
}
