export const ecommerceCategoryEditorFormTabLabelFormatter = {
  format,
};

export type EcommerceCategoryEditorFormTabId = 'cover' | 'content' | 'config';

function format(tabId: EcommerceCategoryEditorFormTabId): string {
  switch (tabId) {
    case 'cover':
      return 'Couverture';
    case 'content':
      return 'Description';
    case 'config':
      return 'Paramètres';
  }
}
