import { dateService } from '@mabadive/app-common-services';
import { ClubDashboardGraphqlQueryByIntervalCriteriaStepInterval } from '../model';

export const chartHelper = { buildAverageValue, formatDatePeriod };

function buildAverageValue<T, Key extends keyof T>(
  arr: T[],
  {
    index,
    attribute,
    averageSize,
  }: {
    index: number;
    attribute: Key;
    averageSize: number;
  },
): number {
  const value = (arr[index] as any)[attribute];
  const values = [value];
  for (let i = 0; i < averageSize; i++) {
    const iBefore = i - 1;
    if (iBefore >= 0) {
      values.push(parseInt((arr[iBefore] as any)[attribute], 10));
    }
    const iAfter = i + 1;
    if (iAfter < arr.length) {
      values.push(parseInt((arr[iAfter] as any)[attribute], 10));
    }
  }
  return Math.round(values.reduce((acc, x) => acc + x) / values.length);
}

function formatDatePeriod(
  date: Date,
  {
    stepInterval,
    format,
  }: {
    stepInterval: ClubDashboardGraphqlQueryByIntervalCriteriaStepInterval;
    format: 'short' | 'long';
  },
) {
  switch (stepInterval) {
    case '1 month':
      return dateService
        .formatUTC(date, format === 'short' ? 'MMM' : 'MMMM YYYY')
        .toUpperCase();
    case '1 week':
      return `${
        format === 'short' ? '' : 'Semaine '
      }${dateService.getWeekOfYear(date)}`;
    case '1 day':
    default:
      return dateService
        .formatUTC(date, format === 'short' ? 'DD/MM' : 'dddd DD/MM/YYYY')
        .toUpperCase();
  }
}
