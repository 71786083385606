/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingMember,
  BookingMemberDetails,
  BookingMemberInsurance,
  BookingParticipantEditorFormModel,
  ClubDiver,
} from '@mabadive/app-common-model';

export const bookingMemberFormParser = {
  parseFormValueBookingMember,
};

function parseFormValueBookingMember({
  formValue,
  initialDiver,
  finalDiver,
}: {
  formValue: BookingParticipantEditorFormModel;
  initialDiver: ClubDiver;
  finalDiver: Partial<ClubDiver>;
}): Partial<BookingMember> {
  const formValueBookingMember: BookingMember = formValue.bookingMember;

  const formInsurance = formValueBookingMember?.details?.insurance;
  const insurance: BookingMemberInsurance =
    formInsurance?.hasInsurance === true
      ? formInsurance
      : formInsurance?.hasInsurance === false
      ? {
          hasInsurance: false,
        }
      : null;

  const hasCertification =
    !!finalDiver.divingCertification1 || !!finalDiver.divingCertification2
      ? true // certification définie
      : formValueBookingMember?.details?.hasCertification === true
      ? undefined // la valeur précédente était "true", dans le doute on passe à undefined
      : false; // c'était déjà false: on conserve

  const details: BookingMemberDetails = formValueBookingMember?.details
    ? {
        ...formValueBookingMember?.details,
        insurance,
        hasCertification,
      }
    : {
        isParticipant: true,
        creationStatus: 'ok',
        insurance,
      };

  const finalBookingMember: Partial<BookingMember> = {
    ...formValueBookingMember,
    mainCertificationReference: finalDiver?.mainCertificationReference,
    details,
  };

  if (
    finalBookingMember?.details?.creationStatus === 'draft' &&
    (finalDiver?.firstName !== initialDiver?.firstName ||
      finalDiver?.lastName !== initialDiver?.lastName)
  ) {
    finalBookingMember.details.creationStatus = 'ok';
  }

  return finalBookingMember;
}

function trimOrNull(str: string): string {
  str = str?.trim();
  if (str) {
    return str;
  }
  return null; // c'est important de retourner null, sinon le jsonPatch sur des champs vide va envoyer des replace+null inutiles
}
