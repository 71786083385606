import { dateService } from '@mabadive/app-common-services';
import React from 'react';

export function PeriodDatesLabel({
  beginDate,
  endDate,
  prefix,
  className,
  dateClassName,
}: {
  beginDate: Date;
  endDate: Date;
  prefix?: string;
  className?: string;
  dateClassName?: string;
}) {
  const hasDates = !!beginDate || !!endDate;

  return hasDates ? (
    <span className={className}>
      {prefix && prefix}
      {beginDate ? (
        !endDate ? (
          <>
            à partir du{' '}
            <span className={dateClassName}>
              {dateService.formatUTC(beginDate, 'DD/MM/YYYY')}
            </span>
          </>
        ) : (
          <>
            du{' '}
            <span className={dateClassName}>
              {dateService.formatUTC(beginDate, 'DD/MM/YYYY')}
            </span>{' '}
            au{' '}
            <span className={dateClassName}>
              {dateService.formatUTC(endDate, 'DD/MM/YYYY')}
            </span>
          </>
        )
      ) : (
        <>
          jusqu'au{' '}
          <span className={dateClassName}>
            {dateService.formatUTC(endDate, 'DD/MM/YYYY')}
          </span>
        </>
      )}
    </span>
  ) : null;
}
