/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from '@material-ui/core';
import React from 'react';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import {
  RxjsFormAppTextField,
  RxjsFormControl,
} from 'src/lib/form/components/rxjs-form';
import { DiveSiteGroupFormComponentsModel } from './DiveSiteGroupFormComponentsModel.type';

export const DiveSiteGroupForm = ({
  components,
}: {
  components: DiveSiteGroupFormComponentsModel;
}) => {
  const autoFocus = useAutoFocus();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5} md={4}>
        <RxjsFormControl
          label="Nom"
          attrState={components['name'].state}
          renderComponent={() => (
            <RxjsFormAppTextField
              fullWidth
              {...components['name']}
              autoFocus={autoFocus}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
