/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AdminMassiveUpdatePayload,
  AppEntityUpdatePatch,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { dateService, jsonPatcher } from '@mabadive/app-common-services';
import clsx from 'clsx';
import { default as React, useCallback, useState } from 'react';
import {
  adminMassiveUpdatorClient,
  clubMassiveUpdatorClient,
  paymentPlatformClient,
} from 'src/business/_core/data/app-operation';
import { AppButton, AppDisclosure } from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import { uiStore } from 'src/business/_core/store';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { ClubPaymentPlatformResume } from '../_model';
import { usePaymentPlatformOpenActivationDialog } from './usePaymentPlatformOpenActivationDialog';

export const ClubSettingsPaymentPlatformCard = ({
  onlinePlatform,
  onClick,
  className,
}: {
  onlinePlatform: ClubPaymentPlatformResume;
  onClick?: () => any;
  className?: string;
}) => {
  const stripeAccount = onlinePlatform.extAccount?.stripeAccount;
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const showOnboardingDialog = usePaymentPlatformOpenActivationDialog();
  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const updateActivation = async ({
    onlinePlatform,
    enabled,
  }: {
    onlinePlatform: ClubPaymentPlatformResume;
    enabled: boolean;
  }): Promise<void> => {
    setOperationInProgressMessage('Mise à jour en cours');
    try {
      const patchOperations = jsonPatcher.compareObjects(
        onlinePlatform,
        {
          ...onlinePlatform,
          enabled,
        },
        {
          // else, value won't be deleted by typeorm
          // https://github.com/typeorm/typeorm/issues/2934
          replaceDeleteByNullValue: true,
        },
      );
      if (patchOperations.length) {
        const patch: AppEntityUpdatePatch = {
          pk: onlinePlatform._id,
          patchOperations,
        };
        const payload: ProMultiOperationPayload = {
          logContext: 'update club payment platforms',
          clubPaymentPlatforms: {
            updated: [patch],
          },
        };

        return clubMassiveUpdatorClient.update(payload);
      }
    } finally {
      setOperationInProgressMessage(undefined);
    }
  };
  const deleteStripeAccount = useCallback(
    async (onlinePlatform: ClubPaymentPlatformResume) => {
      if (!operationInProgressMessage) {
        confirmDialog
          .confirm({
            isTailwind: true,
            title: 'Supprimer un compte de paiement',
            message:
              'Êtes-vous sûr de vouloir supprimer ce compte de paiement?',
            type: 'remove',
          })
          .subscribe(async (confirmed) => {
            if (confirmed) {
              setOperationInProgressMessage(
                'Suppression du compte de paiement',
              );
              try {
                const payload: AdminMassiveUpdatePayload = {
                  clubPaymentPlatform: {
                    deletedIds: [onlinePlatform._id],
                  },
                  clubPaymentPlatformExtAccount: {
                    deletedIds: [],
                  },
                };
                if (onlinePlatform.extAccount?._id) {
                  payload.clubPaymentPlatformExtAccount.deletedIds.push(
                    onlinePlatform.extAccount._id,
                  );
                }
                await adminMassiveUpdatorClient.updateMany(payload);
                uiStore.snackbarMessage.set({
                  type: 'success',
                  content: 'Compté supprimé avec succès!',
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                appLogger.error(
                  'Erreur innatendue lors de la suppression du compte Stripe',
                );
                uiStore.snackbarMessage.set({
                  type: 'error',
                  content:
                    'Erreur innatendue lors de la suppression du compte!',
                });
              } finally {
                setOperationInProgressMessage(undefined);
              }
            }
          });
      }
    },
    [operationInProgressMessage],
  );

  return (
    <div
      onClick={onClick}
      className={clsx(
        'my-2 app-card p-4 md:divide-y divide-gray-200',
        onClick ? 'cursor-pointer app-card-highlight' : '',
        className,
      )}
    >
      <div
        className={clsx(
          'py-2 flex items-center gap-4 text-xl font-bold uppercase',
          onlinePlatform.enabled ? 'text-gray-600' : 'text-gray-400',
        )}
      >
        {/* accountId: {onlinePlatform.stripe.account.accountId} */}
        <div className="w-full flex gap-2 justify-between">
          <div className="flex gap-2">
            {isSuperAdmin && (
              <AppInputBooleanSwitch
                label={'activé'}
                labelToggleFalse="désactivé"
                value={onlinePlatform.enabled}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={() => {
                  updateActivation({
                    onlinePlatform,
                    enabled: !onlinePlatform.enabled,
                  });
                }}
              />
            )}
            Compte {onlinePlatform.platformRef}{' '}
            <span className={clsx('text-base text-gray-400')}>
              (
              <span
                className={clsx(
                  onlinePlatform.platformEnv === 'live' && 'text-status-warn',
                )}
              >
                {onlinePlatform.platformEnv}
              </span>
              {' - '}
              <span>
                {dateService.formatLocal(
                  onlinePlatform._createdAt,
                  'DD/MM/YYYY HH:mm',
                )}
              </span>
              )
            </span>
          </div>
          <div className="flex gap-2 flex-wrap">
            {isSuperAdmin && (
              <AppButton
                size="normal"
                icon={AppIconsAction.refresh}
                color="primary-outline-light"
                onClick={() => {
                  paymentPlatformClient.refreshConnectedAccount({
                    paymentPlatformId: onlinePlatform._id,
                  });
                }}
              >
                Actualiser
              </AppButton>
            )}
            {isSuperAdmin && !onlinePlatform.enabled && (
              <div className={clsx(operationInProgressMessage && 'invisible')}>
                <AppButton
                  size="normal"
                  icon={AppHeroIcons.actionDelete}
                  color="danger-outline-light"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteStripeAccount(onlinePlatform);
                  }}
                >
                  Supprimer
                </AppButton>
              </div>
            )}
          </div>
        </div>
      </div>
      {stripeAccount && (
        <>
          <div
            className={clsx(
              'py-2 flex justify-between items-center gap-4 text-gray-600',
            )}
          >
            <div className={clsx('flex gap-4')}>
              <AppFeatureBadge
                description={'Profil Stripe'}
                label={'COMPLÉTÉ'}
                labelDisabled={'NON COMPLÉTÉ'}
                enabled={stripeAccount.detailsSubmitted}
              />
              {onlinePlatform.enabled && (
                <>
                  <AppFeatureBadge
                    description={'Paiements'}
                    label={'ACTIVÉ'}
                    labelDisabled={'DÉSACTIVÉ'}
                    enabled={stripeAccount.payoutsEnabled}
                  />
                  {/* {isSuperAdmin && (
                    <AppFeatureBadge
                      description={'Frais'}
                      label={'ACTIVÉ'}
                      labelDisabled={'DÉSACTIVÉ'}
                      enabled={stripeAccount.chargesEnabled}
                    />
                  )} */}
                </>
              )}
            </div>

            {!stripeAccount.detailsSubmitted && (
              <AppButton
                size="normal"
                icon={AppIconsAction.linkExternal}
                color="primary-bg"
                onClick={() => {
                  showOnboardingDialog({
                    paymentPlatformId: onlinePlatform._id,
                  });
                }}
              >
                Activer mon compte Stripe
              </AppButton>
            )}
          </div>
          {operationInProgressMessage && (
            <LoadingProgressBar>
              {operationInProgressMessage}
            </LoadingProgressBar>
          )}
          {isSuperAdmin && (
            <AppDisclosure
              defaultExpanded={false}
              className="w-full px-1 py-2 flex gap-2 text-gray-600"
              title={
                <div className="text-left text-sm text-gray-400 uppercase">
                  Afficher les détails
                </div>
              }
            >
              <div className="px-1 py-2 grid gap-2 text-gray-600">
                {stripeAccount.requirements?.disabled_reason?.length > 0 && (
                  <div className="grid gap-2">
                    <span className="font-bold">disabled_reason:</span>{' '}
                    {/* // If the account is disabled, this string describes why. Can
                  be `requirements.past_due`,
                  `requirements.pending_verification`, `listed`,
                  `platform_paused`, `rejected.fraud`, `rejected.listed`,
                  `rejected.terms_of_service`, `rejected.other`, `under_review`,
                  or `other`. */}
                    <span className="text-gray-400">
                      {stripeAccount.requirements.disabled_reason}
                    </span>
                  </div>
                )}
                {stripeAccount.requirements?.errors?.length > 0 && (
                  <div className="grid gap-2">
                    <div className="font-bold">errors:</div>{' '}
                    {/* // Fields that are `currently_due` and need to be collected
                  again because validation or verification failed. */}
                    <ul className="ml-3 list-disc text-gray-400">
                      {stripeAccount.requirements.errors.map((x, i) => (
                        <li className="inline" key={i}>
                          {i !== 0 && ', '}
                          {JSON.stringify(x)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {stripeAccount.requirements?.past_due?.length > 0 && (
                  <div className="grid gap-2">
                    <div className="font-bold">past_due:</div>{' '}
                    {/* // Fields that weren't collected by `current_deadline`. These
                  fields need to be collected to enable the account. */}
                    <ul className="ml-3 list-disc text-gray-400">
                      {stripeAccount.requirements.past_due.map((x, i) => (
                        <li className="inline" key={i}>
                          {i !== 0 && ', '}
                          {JSON.stringify(x)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {stripeAccount.requirements?.current_deadline > 0 && (
                  <div className="grid gap-2">
                    <span className="font-bold">current_deadline:</span>{' '}
                    {/* // Date by which the fields in `currently_due` must be
                  collected to keep the account enabled. These fields may
                  disable the account sooner if the next threshold is reached
                  before they are collected. */}
                    <span className="text-gray-400">
                      {stripeAccount.requirements.current_deadline}
                    </span>
                  </div>
                )}
                {stripeAccount.requirements?.alternatives?.length > 0 && (
                  <div className="grid gap-2">
                    <span className="font-bold">alternatives:</span>{' '}
                    {/* // Fields that are due and can be satisfied by providing the
                  corresponding alternative fields instead. */}
                    <ul className="ml-3 list-disc">
                      {stripeAccount.requirements.alternatives.map((x, i) => (
                        <li className="inline" key={i}>
                          {i !== 0 && ', '}
                          {JSON.stringify(x)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {stripeAccount.requirements?.currently_due?.length > 0 && (
                  <div className="grid gap-2">
                    <div className="font-bold">currently_due:</div>{' '}
                    {/* // Fields that need to be collected to keep the account
                  enabled. If not collected by `current_deadline`, these fields
                  appear in `past_due` as well, and the account is disabled. */}
                    <ul className="ml-3 list-disc text-gray-400">
                      {stripeAccount.requirements.currently_due.map((x, i) => (
                        <li className="inline" key={i}>
                          {i !== 0 && ', '}
                          {JSON.stringify(x)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {stripeAccount.requirements?.eventually_due?.length > 0 && (
                  <div className="grid gap-2">
                    <div className="font-bold">eventually_due:</div>{' '}
                    {/* // Fields that need to be collected assuming all volume
                  thresholds are reached. As they become required, they appear
                  in `currently_due` as well, and `current_deadline` becomes
                  set. */}
                    <ul className="ml-3 list-disc text-gray-400">
                      {stripeAccount.requirements.eventually_due.map((x, i) => (
                        <li className="inline" key={i}>
                          {i !== 0 && ', '}
                          {JSON.stringify(x)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {stripeAccount.requirements?.pending_verification?.length >
                  0 && (
                  <div className="grid gap-2">
                    <div className="font-bold">pending_verification:</div>{' '}
                    {/* // Fields that may become required depending on the results of
                  verification or review. Will be an empty array unless an
                  asynchronous verification is pending. If verification fails,
                  these fields move to `eventually_due`, `currently_due`, or
                  `past_due`. */}
                    <ul className="ml-3 list-disc text-gray-400">
                      {stripeAccount.requirements.pending_verification.map(
                        (x, i) => (
                          <li className="inline" key={i}>
                            {i !== 0 && ', '}
                            {JSON.stringify(x)}
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </AppDisclosure>
          )}
        </>
      )}
    </div>
  );
};
