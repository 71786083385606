import { BaseEntity } from '@mabadive/app-common-model';
import { ObjectMap } from '@mabadive/app-common-services';
import { distinctUntilChanged } from 'rxjs/operators';

export const localEntityRxjs = {
  distinctUntilEntityCollectionChanged,
  distinctUntilEntityMapChanged,
  distinctUntilEntityChanged,
  _hasChange,
}

function distinctUntilEntityCollectionChanged<T extends BaseEntity>() {

  return distinctUntilChanged<T[]>((p1Array: T[], p2Array: T[]) => {
    return !arrayHasChange<T>(p1Array, p2Array);
  });
}

function arrayHasChange<T extends BaseEntity>(p1Array: T[], p2Array: T[]) {
  if (p1Array.length !== p2Array.length) {
    return true;
  }
  const firstDiff = p1Array.find((p1, i) => _hasChange(p1, p2Array[i]));
  return firstDiff !== undefined;
}

function distinctUntilEntityMapChanged<T extends BaseEntity>() {

  return distinctUntilChanged<ObjectMap<T>>((p1Map: ObjectMap<T>, p2Map: ObjectMap<T>) => {
    const p1Array = Object.values(p1Map);
    const p2Array = Object.values(p2Map);
    return !arrayHasChange<T>(p1Array, p2Array);
  });
}
function distinctUntilEntityChanged<T extends BaseEntity>() {

  return distinctUntilChanged<T>((p1: T, p2: T) => {
    return !_hasChange<T>(p1, p2);
  });
}

function _hasChange<T extends BaseEntity>(p1: T, p2: T): boolean {

  // console.log('hasChange: p1,p2', p1, p2)

  if (!p1 || !p2) {
    if ((p1 && !p2) || (!p1 && p2)) {
      return true;
    }
    return false;
  }
  // console.log('hasChange: p1._version !== p2._version', p1._version !== p2._version)
  return p1._id !== p2._id || p1._version !== p2._version || p1._updatedAt !== p2._updatedAt;
}
