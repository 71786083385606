import { DiveSessionTimeDayPeriod } from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import {
  AppHamburgerMenu,
  AppHamburgerMenuItem,
} from 'src/business/club/modules/_common/ui';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSession,
  BookingTabModel,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
} from '../../models';
import { diverBookingPageUpdateStateManager } from '../../services';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingSessionsTableSessionHeader } from './DiverBookingSessionsTableSessionHeader';

export function DiverBookingSessionsTableDaySessionMenu({
  globalState,
  dialogs,
  actions,
  bookingTabModel,
  bookingSessionFull,
  participants,
  onClickSession,
  onClickParticipant,
  displayName,
  displayLastName,
  className,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  bookingSessionFull: AggregatedBookingSessionFull;
  participants: BookingResumeParticipantForSession[];
  displayName?: boolean;
  displayLastName?: boolean;
  onClickSession: (bookingSessionFull: AggregatedBookingSessionFull) => void;
  onClickParticipant: (props: {
    bookingSessionFull: AggregatedBookingSessionFull;
    bookingParticipantFull: PRO_BookingParticipantFull;
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  }) => void;
  className?: string;
}) {
  const isPast = useMemo(
    () =>
      dateService.isPastUTC(bookingSessionFull.diveSession.time) &&
      !dateService.isTodayUTC(bookingSessionFull.diveSession.time),
    [bookingSessionFull.diveSession.time],
  );

  const theme = useMemo(() => (isPast ? 'light' : 'normal'), [isPast]);

  const registeredParticipants = useMemo(
    () =>
      participants.filter(
        ({ bookingParticipantFull }) => !!bookingParticipantFull,
      ),
    [participants],
  );
  const totalParticipantsCount = participants.length;

  const { createParticipantsQuickIfPossible } = actions;

  const { setUpdateState, updateState } = globalState;

  const deleteParticipants = useCallback(
    (registeredParticipants: BookingResumeParticipantForSession[]) => {
      const participantsIdsToDelete = registeredParticipants.map((p) => ({
        bookingProductId: p.bookingParticipantFull.bookingProductDive._id,
        clubParticipantId:
          p.bookingParticipantFull.bookingSessionParticipant.participantId,
        bookingSessionParticipantId:
          p.bookingParticipantFull.bookingSessionParticipant._id,
      }));
      setUpdateState(
        diverBookingPageUpdateStateManager.deleteParticipantsFromState({
          updateState,
          participants: participantsIdsToDelete,
        }),
        {
          action: 'delete participants',
          meta: {
            participantsIdsToDelete,
          },
        },
      );
    },
    [setUpdateState, updateState],
  );
  const az = useAppSecurityUserClubAuthorizations();

  const sessionHamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    hamburgerMenuItems.push({
      title: 'Session détaillée',
      description: 'Détails de la session',
      icon: AppHeroIcons.actionOpen,
      onClick: () => onClickSession(bookingSessionFull),
    });
    if (
      totalParticipantsCount > 1 &&
      registeredParticipants.length < totalParticipantsCount &&
      az.edit.participant.booking
    ) {
      // Note: il faut au moins 1 participant enregistré dans la réservation pour pouvoir utiliser cette fonctionnalité
      const isUnique =
        totalParticipantsCount - registeredParticipants.length === 1;
      hamburgerMenuItems.push({
        title: isUnique
          ? 'Inscrire'
          : `Inscrire tous (${
              participants.length - registeredParticipants.length
            })`,
        description: isUnique
          ? 'Inscrire le participant restant'
          : 'Inscrire tous les participants',
        icon: AppHeroIcons.actionAdd,
        onClick: () => {
          const unregisteredParticipants = participants
            .filter(({ bookingParticipantFull }) => !bookingParticipantFull)
            .map(({ bookingMemberFull }) => bookingMemberFull);

          if (unregisteredParticipants.length) {
            createParticipantsQuickIfPossible({
              bookingResumeMembers: unregisteredParticipants,
              bookingTabModel,
              bookingSessionFull,
            });
          }
        },
      });
    }
    if (registeredParticipants.length !== 0 && az.edit.participant.booking) {
      hamburgerMenuItems.push({
        title:
          registeredParticipants.length === 1
            ? 'Supprimer'
            : `Supprimer tous (${registeredParticipants.length})`,
        description:
          registeredParticipants.length === 1
            ? 'Supprimer le participant inscrit'
            : 'Supprimer tous les participants inscrits',
        icon: AppHeroIcons.actionAdd,
        onClick: () => {
          confirmDialog
            .confirm({
              isTailwind: true,
              title:
                registeredParticipants.length === 1
                  ? 'Supprimer le participant'
                  : `Supprimer tous les participants inscrits (${registeredParticipants.length})`,
              message: 'Êtes-vous sûr de vouloir continuer?',
              type: 'noYesDanger',
            })
            .subscribe((confirmed) => {
              if (confirmed) {
                deleteParticipants(registeredParticipants);
              }
            });
        },
      });
    }

    return hamburgerMenuItems;
  }, [
    az.edit.participant.booking,
    bookingSessionFull,
    bookingTabModel,
    createParticipantsQuickIfPossible,
    deleteParticipants,
    onClickSession,
    participants,
    registeredParticipants,
    totalParticipantsCount,
  ]);

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () =>
      clubDiveSessionThemeBuilder.buildTimeDayPeriod(
        bookingSessionFull.diveSession.time,
      ),
    [bookingSessionFull.diveSession.time],
  );

  return (
    <AppHamburgerMenu
      className={clsx('w-full', className)}
      buttonClassName={`w-full border border-gray-300 rounded font-bold text-sm
            bg-gray-100 text-gray-400
            hover:bg-opacity-80 hover:bg-day-period-${timeDayPeriod}-light2 hover:text-white
          `}
      items={sessionHamburgerMenuItems}
      buttonIcon={AppHeroIcons.actionSettings}
      header={
        <div className="my-4 ml-4 mr-12">
          <DiverBookingSessionsTableSessionHeader
            displaySessionName={false}
            displayDiveSite={false}
            className="w-full"
            theme={theme}
            bookingSessionFull={bookingSessionFull}
            onClick={() => onClickSession(bookingSessionFull)}
          />
        </div>
      }
    ></AppHamburgerMenu>
  );
}
