import clsx from 'clsx';
import React from 'react';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { AppMultilineText } from './AppMultilineText';

export function AppMultilineComment({
  comment,
  enableStyle,
  type,
  className,
  lineClassName,
}: {
  comment: string;
  enableStyle?: boolean;
  type?: 'private' | 'public';
  className?: string;
  lineClassName?: string;
}) {
  return comment?.trim()?.length > 0 ? (
    <div
      className={clsx(
        className,
        'flex gap-2 text-gray-700 font-normal',
        enableStyle &&
          type === 'private' &&
          'border border-gray-200 bg-gray-50 rounded-xl rounded-tl-none p-2',
        enableStyle &&
          type === 'public' &&
          'border border-blue-100 bg-blue-50 rounded-xl rounded-tl-none p-2',
      )}
    >
      <div className="flex gap-1">
        <AppIcons.ui.comment className="h-6 w-6" />
        {type === 'private' && <span>🚫</span>}
        {type === 'public' && <span>👩‍🦰</span>}
      </div>
      <AppMultilineText text={comment} lineClassName={lineClassName} />
    </div>
  ) : null;
}
