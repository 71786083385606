/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import {
  AppBookingViewAuth,
  useAppBookingViewAuth,
} from 'src/business/auth/services';
import {
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  BookingResumeParticipantForSessionWithSession,
  BookingResumeParticipantsBySessionWithSession,
  DiverBookingPageLoadedContent,
  DiverBookingPageUpdateState,
} from '../../models';
import { buildBookingResumeParticipantsBySessions } from '../../services';
import { DiverBookingPageSetUpdateStateFn } from '../../useDiverBookingPageGlobalState.hook';
import { BookingMassiveEditorDialogAggregatedData } from './model';
import { useBookingMassiveEditorDialogAggregatedDataBuilder } from './useBookingMassiveEditorDialogAggregatedDataBuilder.service';

export type BookingMassiveEditorInnerLocalState = ReturnType<
  typeof useBookingMassiveEditorInnerLocalState
>;

export type BookingMassiveEditorInnerLocalStateAttrs = {
  bookingId?: string;
  loadedContent?: DiverBookingPageLoadedContent;
  bookingSessionsIds?: string[]; // si non spécifié, on prend uniquement les sessions futures
  updateState: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
};

export function useBookingMassiveEditorInnerLocalState({
  bookingId,
  loadedContent,
  bookingSessionsIds,
  updateState,
  setUpdateState,
}: BookingMassiveEditorInnerLocalStateAttrs) {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  // loaded bookings + changes applied
  const aggregatedData: BookingMassiveEditorDialogAggregatedData = useMemo(
    () =>
      useBookingMassiveEditorDialogAggregatedDataBuilder.aggregate({
        bookingId,
        updateState,
        loadedContent,
      }),
    [bookingId, loadedContent, updateState],
  );

  const participantsBySessions: BookingResumeParticipantsBySessionWithSession[] =
    useMemo(
      () =>
        buildBookingResumeParticipantsBySessions({
          loadedContent,
          aggregatedBooking: aggregatedData,
          bookingParticipantsFullAllBookings:
            aggregatedData.bookingParticipantsFullAllBookings,
          futureOnly: bookingSessionsIds === undefined,
          bookingSessionsIds,
          sortAsc: true,
        }).map((x) => {
          const result: BookingResumeParticipantsBySessionWithSession = {
            ...x,
            participants: x.participants.map((p) => {
              const pr: BookingResumeParticipantForSessionWithSession = {
                ...p,
                bookingSessionFull: x.bookingSessionFull,
              };
              return pr;
            }),
          };
          return result;
        }),
      [aggregatedData, bookingSessionsIds, loadedContent],
    );

  const bookingParticipantsWithSessionFull: BookingResumeParticipantForSessionWithSession[] =
    useMemo(
      () =>
        participantsBySessions.reduce((acc, x) => {
          return acc.concat(x.participants);
        }, [] as BookingResumeParticipantForSessionWithSession[]),
      [participantsBySessions],
    );

  const isUniqueMember = aggregatedData.bookingMembersFull?.length === 1;
  const isSameLastName = useMemo(
    () =>
      new Set(
        aggregatedData.bookingMembersFull?.map((x) =>
          x.diver.lastName?.toLowerCase(),
        ),
      ).size <= 1,
    [aggregatedData.bookingMembersFull],
  );

  const bookingViewAuth: AppBookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: aggregatedData.booking?.diveCenterId,
    isSharedBooking: aggregatedData.booking?.isSharedBooking,
  });

  const clubSettings = useClubSettings();
  return {
    state: {
      updateState,
      setUpdateState,
      bookingViewAuth,
    },
    data: {
      clubReference,
      isSameLastName,
      clubSettings,
      diveCenterId,
      bookingParticipantsWithSessionFull,
      participantsBySessions,
      aggregatedData,
      isUniqueMember,
    },
    /**
     * @deprecated use data.clubReference
     */
    clubReference,
    /**
     * @deprecated use data.diveCenterId
     */
    diveCenterId,
    /**
     * @deprecated use data.participantsBySessions
     */
    participantsBySessions,
    /**
     * @deprecated use data.bookingParticipantsWithSessionFull
     */
    bookingParticipantsWithSessionFull,
    /**
     * @deprecated use data.isUniqueMember
     */
    isUniqueMember,
    /**
     * @deprecated use data.isSameLastName
     */
    isSameLastName,
    /**
     * @deprecated use data.aggregatedData
     */
    aggregatedData,
    /**
     * @deprecated use state.updateState
     */
    updateState,
    /**
     * @deprecated use state.setUpdateState
     */
    setUpdateState,
  };
}
