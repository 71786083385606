import {
  AppDocUploadContext,
  AppTranslationContentItemDoc,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import React, { useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  AppDocResumeImageUploader,
  AppFormControlRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppButton } from '../../../../../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../../../../../business/_core/modules/layout/icons';
import { EcommerceCategoryEditorFormModel } from '../../EcommerceCategoryEditor/model';
import { EcommerceProductArticleEditorFormModel } from '../../EcommerceProductArticleEditor/model';
import { EcommerceProductEditorFormModel } from '../model';

export const EcommerceProductEditorFormContentImageRHF = ({
  label,
  image,
  width,
  height,
  form,
  className,
}: {
  label: string;
  image: 'image' | 'image2';
  width: 1000;
  height: 1000 | 400;
  form: UseFormReturn<
    | EcommerceProductEditorFormModel
    | EcommerceCategoryEditorFormModel
    | EcommerceProductArticleEditorFormModel,
    any,
    undefined
  >;
  className?: string;
}) => {
  const { control, setValue } = form;

  const contentImagePath =
    `appBookletPage.mainContent.content.${image}` as const;
  const coverImagePath = `appBookletPage.mainContent.cover.${image}` as const;
  // const imagePathOther = `appBookletPage.mainContent.content.${
  //   image === 'image' ? 'image2' : 'image'
  // }` as const;

  const [bookletPage, contentImage] = useWatch({
    control,
    name: ['appBookletPage', contentImagePath],
  });
  const appDocUploadContext: AppDocUploadContext = {
    clubDiverId: undefined,
    bookingId: undefined,
    bookingMemberId: undefined,
    appBookletPageId: bookletPage?._id,
    fileType: 'image',
    scope: 'ecommerce',
    isPublic: true,
  };

  const [showImageSelector, setShowImageSelector] = useState(false);

  return (
    <>
      <AppFormControlRHF
        label={label}
        className={className}
        control={control}
        name={`${contentImagePath}._id`}
        // validation={{
        //   rules: {
        //     maxLength: 200,
        //   },
        // }}
        required={true}
        renderComponent={(props) => (
          <>
            <div className="hidden">
              <AppInputRHF {...props} />
            </div>
            <div
              className={'cursor-pointer flex gap-8 items-end'}
              onClick={() => setShowImageSelector(true)}
            >
              {contentImage?._id && (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  className="h-[80px] lg:h-[160px]"
                  src={contentImage.publicUrl}
                />
              )}
              <AppButton
                icon={AppIconsAction.actionAddPhoto}
                color={'primary-outline-light'}
              >
                {contentImage?._id ? 'Remplacer' : 'Image'}
              </AppButton>
            </div>
          </>
        )}
      />

      {showImageSelector && (
        <AppDocResumeImageUploader
          uploadUrl="/pro/docs/upload"
          expectedImage={{
            width,
            height,
            variantsRatio: [1 / 2],
          }}
          uploadPayload={{ context: appDocUploadContext }}
          onSuccess={(appDoc: PRO_AppDocResume) => {
            setShowImageSelector(false);
            const contentImage: AppTranslationContentItemDoc = {
              _id: appDoc._id,
              fileType: appDoc.fileType,
              publicUrl: appDoc.publicUrl,
            };
            setValue(contentImagePath, contentImage);

            const coverImage: AppTranslationContentItemDoc = {
              _id: appDoc._id,
              fileType: appDoc.fileType,
              publicUrl: appDoc.variantsPublicUrls?.[0],
            };
            setValue(coverImagePath, coverImage);
          }}
          onCancel={() => {
            setShowImageSelector(false);
          }}
        />
      )}
    </>
  );
};
