/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubDiveSiteGroup,
  ClubResumeDiveSite,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import React from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { clubSettingsDiveCenterUrlBuilder } from '../../clubSettingsDiveCenterUrlBuilder.service';
import { DiveSitesTable } from '../DiveSitesTable';

export const DiveSitesListPageGroup = ({
  diveSiteGroup,
  diveSites,
  className = '',
}: {
  diveSiteGroup: ClubDiveSiteGroup;
  diveSites: ClubResumeDiveSite[];
  className?: string;
}) => {
  const redirectTo = useRedirect();

  return (
    <div key={diveSiteGroup._id} className={className}>
      <h2
        className={`text-left text-lg font-bold uppercase p-2 ${
          diveSiteGroup.enabled ? 'text-app-blue' : 'text-red-400'
        }`}
      >
        {diveSiteGroup.name}
      </h2>
      <DiveSitesTable
        diveSites={diveSites}
        onClickDiveSite={(diveSiteId) => {
          redirectTo(
            clubSettingsDiveCenterUrlBuilder.diveSites.edit({ diveSiteId }),
          );
        }}
      />
      <div className="my-5 flex flex-col sm:flex-row gap-y-2 gap-x-4">
        <AppButton
          color={'primary-outline-light'}
          size="normal"
          icon={AppHeroIcons.actionAdd}
          onClick={() => {
            redirectTo(
              clubSettingsDiveCenterUrlBuilder.diveSites.create({
                defaultDiveSiteGroupId: diveSiteGroup._id,
              }),
            );
          }}
        >
          Nouveau site
        </AppButton>
        <AppButton
          color={'primary-outline-light'}
          size="normal"
          icon={AppHeroIcons.actionEdit}
          onClick={() => {
            redirectTo(
              clubSettingsDiveCenterUrlBuilder.diveSitesGroups.edit({
                diveSiteGroupId: diveSiteGroup._id,
              }),
            );
          }}
        >
          Renommer le groupe
        </AppButton>
        {diveSites.length === 0 && diveSiteGroup.enabled && (
          <AppButton
            color={'danger-outline-light'}
            size="normal"
            icon={AppHeroIcons.actionDelete}
            onClick={async () => {
              if (
                await confirmDialog.confirmPromise({
                  title: 'Supprimer ce groupe',
                  message:
                    'Êtes-vous sûr de vouloir supprimer ce groupe de sites de plongée?',
                  type: 'noYesDanger',
                })
              ) {
                await clubMassiveUpdatorClient.update({
                  deletedDiveSiteGroupsIds: [diveSiteGroup._id],
                });
              }
            }}
          >
            Supprimer le groupe
          </AppButton>
        )}
        {!diveSiteGroup.enabled && (
          <AppButton
            color={'danger-outline-light'}
            size="normal"
            icon={AppHeroIcons.actionConfirm}
            onClick={async (e) => {
              e.stopPropagation();
              if (
                await confirmDialog.confirmPromise({
                  title: 'Ré-activer ce groupe',
                  message:
                    'Êtes-vous sûr de vouloir activer ce groupe archivé?',
                  type: 'noYesDanger',
                })
              ) {
                const patchOperations = jsonPatcher.compareObjects(
                  diveSiteGroup,
                  {
                    ...diveSiteGroup,
                    enabled: true,
                  },
                  {},
                );
                if (patchOperations.length) {
                  const patch: AppEntityUpdatePatch = {
                    pk: diveSiteGroup._id,
                    patchOperations,
                  };
                  await clubMassiveUpdatorClient.update({
                    updatedDiveSitesGroups: [patch],
                  });
                }
              }
            }}
          >
            Restaurer le groupe
          </AppButton>
        )}
      </div>
    </div>
  );
};
