/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanSwitchRHF,
} from 'src/lib/form';
import { ClubSettingsEquipmentEditPageLocalState } from '../useClubSettingsEquipmentEditPageLocalState.hook';
import { ClubSettingsEquipmentEditPageFormModelsOptions } from './ClubSettingsEquipmentEditPageFormModelsOptions';

export const ClubSettingsEquipmentEditPageForm = ({
  localState,
  className,
}: {
  localState: ClubSettingsEquipmentEditPageLocalState;
  className?: string;
}) => {
  const { data } = localState;

  const form = data.form;
  const equipmentDefaultAppConfig = data.equipmentDefaultAppConfig;

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [enabled, clubEnabled, selfEnabled] = useWatch({
    control,
    name: ['enabled', 'club.enabled', 'self.enabled'],
  });

  return (
    <div className={clsx('grid gap-x-4 gap-y-8', className)}>
      <div className={clsx('w-full grid sm:grid-cols-2 md:grid-cols-4 gap-4')}>
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="CLUB ou PERSO"
          control={control}
          name={'enabled'}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              className="mt-1"
              label={'Activé'}
              labelToggleFalse="Désactivé"
            />
          )}
        />
        {enabled && (clubEnabled || selfEnabled) && (
          <AppFormControlRHF_Deprecated
            label="Taille ou modèle N°2?"
            helpDescription="Permet d'indiquer une taille ou modèle alternative, si le premier n'est pas disponible par exemple"
            control={control}
            name={'altEnabled'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                className="mt-1"
                label={'Oui'}
                labelToggleFalse="Non"
              />
            )}
          />
        )}{' '}
        {enabled && localState.data.equipmentType === 'tank' && (
          <AppFormControlRHF_Deprecated
            label="Activer l'équipement des moniteurs"
            helpDescription="A configurer sur les fiches des moniteurs, pour prise en compte sur le dashboard et la fiche de sécurité"
            control={control}
            name={'enableStaffEquipment'}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                className="mt-1"
                label={'Oui'}
                labelToggleFalse="Non"
              />
            )}
          />
        )}
      </div>
      {enabled && (
        <div className="grid 2xl:grid-cols-2 gap-4 items-start">
          <ClubSettingsEquipmentEditPageFormModelsOptions
            form={form}
            modelsGroupName={'club'}
            equipmentDefaultAppConfig={equipmentDefaultAppConfig}
          />
          <ClubSettingsEquipmentEditPageFormModelsOptions
            form={form}
            modelsGroupName={'self'}
            equipmentDefaultAppConfig={equipmentDefaultAppConfig}
          />
        </div>
      )}
    </div>
  );
};
