import {
  AppDurationParserString,
  arrayBuilder,
  durationParser,
} from '@mabadive/app-common-services';
import { AppFetchDataCacheStore } from './appFetchDataCacheStoreBuilder.service';
import {
  AppFetchResult,
  AppFetchType,
  AppFetchedDataState,
  AppInnerCacheState,
  UseAppFetchDataWithCacheOptions,
  useAppFetchDataWithCache,
} from './useAppFetchDataWithCache.hook';

export type UseAppFetchDataListWithCacheOptions<FC, I> = {
  store: AppFetchDataCacheStore<FC, I[]>;
  autoRefetchInterval: AppDurationParserString;
  staleInterval: AppDurationParserString;
  fetch: (
    criteria: FC,
    options?: {
      fetchType: Exclude<AppFetchType, 'not-updated'>;
      cacheState?: AppInnerCacheState<I[]>;
    },
  ) => Promise<I[]>;
  compareResults: (item1: I, item2: I) => boolean;
  getUpdateDate: (item: I) => Date;
};

// NOTE: le cache est filtré en cas de suppression dans "clubMassiveUpdatorClient.service"

export function useAppFetchDataListWithCache<FC, I>(
  fetchCriteria: FC,
  options: UseAppFetchDataListWithCacheOptions<FC, I>,
): AppFetchedDataState<I[]> {
  const rootOptions: UseAppFetchDataWithCacheOptions<FC, I[]> = {
    ...options,
    fetch: async (criteria, cacheState) => {
      const fetchType = buildFetchType<FC, I>(cacheState, options);

      const fetchedResults = await options.fetch(criteria, {
        fetchType,
        cacheState,
      });

      if (fetchType === 'partial') {
        if (fetchedResults?.length) {
          // partial update
          const updatedData = arrayBuilder.mergeArraysByKey(
            cacheState?.fetchResult?.data,
            fetchedResults,
            options.compareResults,
          );
          const dataModifiedAt = new Date(
            Math.max(
              ...updatedData.map((x) => options.getUpdateDate(x).getTime()),
            ),
          );
          const fetchResults: AppFetchResult<I[]> = {
            ...fetchedResults,
            data: updatedData,
            dataModifiedAt,
            _fetchType: 'partial',
          };
          return fetchResults;
        } else {
          // not updated
          const fetchResults: AppFetchResult<I[]> = {
            _fetchType: 'not-updated',
            data: undefined,
            dataModifiedAt: undefined,
          };
          return fetchResults;
        }
      } else {
        // full fetch
        const dataModifiedAt = new Date(
          Math.max(
            ...fetchedResults.map((x) => options.getUpdateDate(x).getTime()),
          ),
        );
        const fetchResults: AppFetchResult<I[]> = {
          data: fetchedResults,
          dataModifiedAt,
          _fetchType: 'full',
        };
        return fetchResults;
      }
    },
  };

  return useAppFetchDataWithCache(fetchCriteria, rootOptions);
}
function buildFetchType<FC, I>(
  cacheState: AppInnerCacheState<I[]>,
  options: UseAppFetchDataListWithCacheOptions<FC, I>,
) {
  const dataModifiedAt = cacheState?.fetchResult?.dataModifiedAt;
  const lastFullFetch = cacheState?.meta?.lastFullFetch;
  if (!dataModifiedAt || !lastFullFetch) {
    return 'full';
  }
  const isPartialUpdateEnabled =
    !!dataModifiedAt && // au moins 1 donnée dans le cache
    !!cacheState.meta?.lastFullFetch &&
    // cache non expiré
    new Date().getTime() - cacheState.meta?.lastFullFetch?.getTime() <
      durationParser.parseTimestamp(options.staleInterval);

  const fetchType: Exclude<AppFetchType, 'not-updated'> = isPartialUpdateEnabled
    ? 'partial'
    : 'full';
  return fetchType;
}
