/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC } from 'react';
import { ClubSettingsCommunicationMainForm } from '../tabs';
import { ClubSettingsCommunicationMessageTemplateSignatureForm } from '../tabs/02.message-templates/form-signature';
import { ClubSettingsCommunicationEmailAutoForm } from '../tabs/03.email-auto';
import { ClubSettingsCommunicationDialogLocalState } from './useClubSettingsCommunicationDialogLocalState.hook';

export const ClubSettingsCommunicationDialogForm: FC<{
  localState: ClubSettingsCommunicationDialogLocalState;
}> = ({ localState }) => {
  const { form, initialState } = localState;
  const { tabId } = initialState;

  return (
    <div className={clsx('app-card app-p-content')}>
      {tabId === 'main' ? (
        <ClubSettingsCommunicationMainForm
          form={form}
          initialState={initialState}
        />
      ) : tabId === 'templates' ? (
        <ClubSettingsCommunicationMessageTemplateSignatureForm
          form={form}
          initialState={initialState}
        />
      ) : tabId === 'email-auto' ? (
        <ClubSettingsCommunicationEmailAutoForm
          form={form}
          initialState={initialState}
        />
      ) : null}
    </div>
  );
};
