/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { AppInputBooleanRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ClubSettingsProductOffersFormModel } from '../_model';

export const ClubSettingsProductOffersFormOfferProductDetails = ({
  form,
  offerField,
  offersFieldArray,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
}) => {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offersFieldArray.fields.findIndex(
    (x) => x.reference === offerField.reference,
  );

  const showDetails = offersFieldArray.fields.length === 1;

  return (
    <div className="flex gap-4 flex-wrap">
      <AppFormControlRHF_Deprecated
        className={''}
        label="Boutique"
        control={control}
        name={`offers.${i}.productPackage.salesCriteria.standalone`}
        renderComponent={(props) => (
          <AppInputBooleanRHF
            className="scale-125 block ml-0.5 mt-1 mb-1"
            {...props}
            type="checkbox"
          />
        )}
      />
      <AppFormControlRHF_Deprecated
        className={''}
        label="Favori"
        control={control}
        name={`offers.${i}.productPackage.salesCriteria.favorite`}
        renderComponent={(props) => (
          <AppInputBooleanRHF
            className="scale-125 block ml-0.5 mt-1 mb-1"
            {...props}
            type="checkbox"
          />
        )}
      />
      <AppFormControlRHF_Deprecated
        className={''}
        label="Supp. formation"
        control={control}
        name={`offers.${i}.productPackage.salesCriteria.extraCostTraining`}
        renderComponent={(props) => (
          <AppInputBooleanRHF
            className="scale-125 block ml-0.5 mt-1 mb-1"
            {...props}
            type="checkbox"
          />
        )}
      />
      <AppFormControlRHF_Deprecated
        className={''}
        label="Supp. baptême"
        control={control}
        name={`offers.${i}.productPackage.salesCriteria.extraCostFirstDive`}
        renderComponent={(props) => (
          <AppInputBooleanRHF
            className="scale-125 block ml-0.5 mt-1 mb-1"
            {...props}
            type="checkbox"
          />
        )}
      />
      <AppFormControlRHF_Deprecated
        className={''}
        label="Supp. explo"
        control={control}
        name={`offers.${i}.productPackage.salesCriteria.extraCostDivePlan`}
        renderComponent={(props) => (
          <AppInputBooleanRHF
            className="scale-125 block ml-0.5 mt-1 mb-1"
            {...props}
            type="checkbox"
          />
        )}
      />{' '}
      <AppFormControlRHF_Deprecated
        className={''}
        label="Par plongée"
        control={control}
        name={`offers.${i}.productPackage.salesCriteria.extraCostUnitDive`}
        renderComponent={(props) => (
          <AppInputBooleanRHF
            className="scale-125 block ml-0.5 mt-1 mb-1"
            {...props}
            type="checkbox"
          />
        )}
      />
      <AppFormControlRHF_Deprecated
        className={''}
        label="Supp. session"
        control={control}
        name={`offers.${i}.productPackage.salesCriteria.extraCostSession`}
        renderComponent={(props) => (
          <AppInputBooleanRHF
            className="scale-125 block ml-0.5 mt-1 mb-1"
            {...props}
            type="checkbox"
          />
        )}
      />
    </div>
  );
};
