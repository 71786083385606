/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import { AppIconsSocialMedia } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useClubSettingsMessageTargets,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';

export const ClubSettingsCommunicationMainViewPanel_Booking = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const msg = useClubSettingsMessageTargets({
    context: 'booking',
  });

  return (
    <ClubSettingsSection
      title="Réservations"
      comment="Envoyer un message prédéfini aux clients d'une réservations"
    >
      {clubSettings.communication?.booking?.enableMessage ? (
        <>
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            {msg.email.channelEnabled && (
              <AppFeatureBadge
                enabled={msg.email.enabled}
                label="Email"
                label2={
                  clubSettings.communication?.booking?.email?.mode === 'auto'
                    ? '(auto ⚡)'
                    : '(manuel ❌)'
                }
                icon={
                  <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
                }
              />
            )}
            {msg.sms.channelEnabled && (
              <AppFeatureBadge
                enabled={msg.sms.enabled}
                label="SMS"
                label2="(manuel)"
                icon={
                  <AppIconsSocialMedia.sms className="h-6 w-6 text-app-sms" />
                }
              />
            )}
            {msg.whatsApp.channelEnabled && (
              <AppFeatureBadge
                enabled={msg.whatsApp.enabled}
                label="WhatsApp"
                label2="(manuel)"
                icon={
                  <AppIconsSocialMedia.whatsapp className="h-5 w-5 text-app-whatsapp" />
                }
              />
            )}
          </div>
          <div className="grid gap-2">
            {(isSuperAdmin ||
              clubSettings.communication?.booking?.recipients
                ?.contactOnlyAsDefault) && (
              <AppFeatureBadge
                enabled={
                  clubSettings.communication?.booking?.recipients
                    ?.contactOnlyAsDefault
                }
                label="Contacter uniquement le responsable (par défaut)"
              />
            )}

            {(isSuperAdmin ||
              clubSettings.communication?.booking?.recipients
                ?.allowToContactAllRecipients) && (
              <AppFeatureBadge
                visibility="super-admin"
                enabled={
                  clubSettings.communication?.booking?.recipients
                    ?.allowToContactAllRecipients
                }
                label="Permettre de contacter les autres participants"
              />
            )}
            {(isSuperAdmin ||
              clubSettings.communication?.booking?.recipients
                ?.personalMessageSwitchEnabled) && (
              <AppFeatureBadge
                visibility="super-admin"
                enabled={
                  clubSettings.communication?.booking?.recipients
                    ?.personalMessageSwitchEnabled
                }
                label="Permettre de forcer l'envoi de messages individuels"
              />
            )}
            {(isSuperAdmin ||
              clubSettings.communication?.customerSpace
                ?.individualLinksEnabled) && (
              <AppFeatureBadge
                visibility="super-admin"
                enabled={
                  clubSettings.communication?.customerSpace
                    ?.individualLinksEnabled
                }
                label="Chaque participant d'une réservation reçoit un lien de connexion individuel"
              />
            )}
          </div>
        </>
      ) : (
        <AppFeatureBadge enabled={false} label="DÉSACTIVÉ" />
      )}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
        {/* <AppFeatureBadge
          enabled={clubSettings.communication?.booking?.confirmation?.manual}
          label="Confirmation manuelle par e-mail"
        /> */}
        {/* <AppFeatureBadge
          enabled={
            clubSettings.communication?.booking?.confirmation?.autoOnCreate
          }
          label="Confirmation automatique par e-mail (à la création)"
        />
        <AppFeatureBadge
          enabled={clubSettings.communication?.booking?.reminder?.auto}
          label={`Rappel automatique par e-mail (${clubSettings.communication?.booking?.reminder.delayInHours}h avant la plongée)`}
        /> */}
      </div>
    </ClubSettingsSection>
  );
};
