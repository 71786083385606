/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppCurrency, AppCurrencyIsoCode } from '@mabadive/app-common-model';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useAppCurrencyOptions(
  currencies: AppCurrency[],
): ValueLabel<AppCurrencyIsoCode>[] {
  return currencies.map((currency) => {
    const option: ValueLabel<AppCurrencyIsoCode> = {
      label: `${currency.label} (${currency.sign})`,
      value: currency.isoCode,
    };
    return option;
  });
}
