import { ClubStaffMember } from '@mabadive/app-common-model';
import {
  staffMemberDefaultAvailabilityFinder,
  staffMemberFormatter,
} from '@mabadive/app-common-services';
import React, { CSSProperties, FC, useMemo } from 'react';

export const StaffLevelBadge: FC<{
  staffMember: Pick<
    ClubStaffMember,
    'profile' | 'availabilities' | 'scubaDivingInstructor'
  >;
  date: Date;
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
}> = ({ staffMember, date, className, style, onClick }) => {
  const currentAvailability = useMemo(
    () =>
      staffMemberDefaultAvailabilityFinder.getDefaultDailyAvailability(
        staffMember,
        {
          date,
        },
      ),
    [date, staffMember],
  );

  const label = useMemo(
    () =>
      staffMemberFormatter.formatStaffLevel({
        staffMember,
      }),
    [staffMember],
  );

  return (
    <div
      style={style}
      className={`text-center text-white 
      ${
        currentAvailability?.jobType === 'casual'
          ? 'bg-pink-400'
          : 'bg-violet-400'
      }
      ${className ?? ''}
    `}
      onClick={onClick}
    >
      {label}
    </div>
  );
};
