import { BillingTabParticipantPurchaseSessionsBillingResumes } from '../../../models';

export const initialBookingProductBuilder = {
  buildInitialDiveBookingProducts,
};

function buildInitialDiveBookingProducts(
  initialSessionsBillingResumes: BillingTabParticipantPurchaseSessionsBillingResumes,
) {
  return initialSessionsBillingResumes.all.map(
    (p) => p.purchaseParticipant.bookingProduct,
  );
}
