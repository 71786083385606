import { Booking } from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { BookingTabModel, DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
export function useDiverBookingPageUpdateBooking({
  updateState,
  setUpdateState,
}: {
  updateState: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
}) {
  return useCallback(
    ({
      booking,
      attributesToUpdate,
    }: {
      booking: BookingTabModel;
      attributesToUpdate: Partial<Booking>;
    }) => {
      // const newBooking = diverBookingCreator.createBooking({
      //   clubReference,
      //   bookingContactDiverId: diverId,
      // });
      let updateStateLocal = updateState;

      const patchOperations = jsonPatcher.compareObjects(
        booking,
        {
          ...booking,
          ...attributesToUpdate,
        },
        {
          // else, value won't be deleted by typeorm
          // https://github.com/typeorm/typeorm/issues/2934
          replaceDeleteByNullValue: true,
        },
      );
      if (patchOperations.length) {
        const bookingsChanges = changeDescriptorManager.updateOne(
          {
            pk: booking.bookingId,
            patchOperations,
          },
          {
            changeDescriptors: updateStateLocal.bookingsChanges,
          },
        );
        if (attributesToUpdate?.bookingStatus === 'confirmed') {
          // on essaie de confirmer aussi les online bookings
          for (const onlineBooking of booking.aggregatedBooking
            .onlineBookings ?? []) {
            if (
              onlineBooking.state === 'new' ||
              onlineBooking.state === 'pending-club' ||
              onlineBooking.state === 'pending-customer'
            ) {
              const patchOperations = jsonPatcher.compareObjects(
                onlineBooking,
                {
                  ...onlineBooking,
                  state: 'confirmed',
                },
                {
                  // else, value won't be deleted by typeorm
                  // https://github.com/typeorm/typeorm/issues/2934
                  replaceDeleteByNullValue: true,
                },
              );
              const onlineBookingChanges = changeDescriptorManager.updateOne(
                {
                  pk: onlineBooking._id,
                  patchOperations,
                },
                {
                  changeDescriptors: updateStateLocal.onlineBookingsChanges,
                },
              );
              updateStateLocal = {
                ...updateStateLocal,
                onlineBookingsChanges: onlineBookingChanges,
              };
            }
          }
        }

        updateStateLocal = {
          ...updateStateLocal,
          bookingsChanges,
        };
        setUpdateState(updateStateLocal, {
          action: 'DiverBookingPageUpdateBooking',
        });
      }
    },
    [setUpdateState, updateState],
  );
}
