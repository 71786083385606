import { urlBuilder, UrlBuilderQueryParams } from 'src/_common-browser';
import {
  CLUB_OFFER_SPACE_TABS,
  ClubOfferTabId,
} from '../club-settings/club-settings-offers';

export const clubSettingsOffersUrlBuilder = {
  buildTabUrl,
};

function buildTabUrl({
  tabId,
  newChunk,
}: {
  tabId?: ClubOfferTabId;
  newChunk?: string;
}) {
  const queryParams: UrlBuilderQueryParams = {};

  const tab = tabId
    ? CLUB_OFFER_SPACE_TABS.find((tab) => tab.id === tabId)
    : undefined;

  const settingsBaseUrl = `/club/services${tab ? `/${tab.urlSuffix}` : ''}`;

  return urlBuilder.build(settingsBaseUrl, {
    newChunk,
    queryParams,
  });
}
