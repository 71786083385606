import { Booking } from '@mabadive/app-common-model';
import {
  dateService,
  DiverPurchaseOtherEditorDialogMatchingOfferCriteria,
  DiverPurchasePlanEditorDialogMatchingOfferCriteria,
  DiverPurchaseTrainingEditorDialogMatchingOfferCriteria,
} from '@mabadive/app-common-services';
import { useCallback, useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  BillingTabParticipantPurchase,
  DiverBookingPageAggregatedData,
} from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';

export function useOnClickCreateEditPurchase({
  participantPurchase,
  actions,
  aggregatedData,
}: {
  participantPurchase: BillingTabParticipantPurchase;
  actions: DiverBookingPageActions;
  aggregatedData: DiverBookingPageAggregatedData;
}) {
  const {
    diveMode,
    isVirtual,
    diver,
    purchasePackage,
    diveTrainingReference,
    sessionsBillingResumes,
    divesCounts,
    beginDate,
    endDate,
    planSupervision,
    planEquipment,
    firstDiveReference,
  } = participantPurchase;

  const bookings: Booking[] = aggregatedData.bookings;

  const {
    openEditTrainingDialog,
    openCreateTrainingDialog,
    openEditPlanDialog,
    openCreatePlanDialog,
    openEditPurchaseOtherDialog,
    openCreatePurchaseOtherDialog,
  } = actions;

  const clubResume = useClubResume();

  const totalConsumedCount = useMemo(
    () =>
      divesCounts.assigned +
      divesCounts.toAssign +
      divesCounts.consumedExternalCount,
    [
      divesCounts.assigned,
      divesCounts.consumedExternalCount,
      divesCounts.toAssign,
    ],
  );

  const onClickCreateEditPurchase = useCallback(() => {
    const type = participantPurchase.type;
    if (type === 'training' && isVirtual) {
      const age = diver.birthdate
        ? dateService.getAge(diver.birthdate)
        : diver.age;

      const createContext: DiverPurchaseTrainingEditorDialogMatchingOfferCriteria =
        {
          defaultTrainingReference: participantPurchase.diveTrainingReference,
          age,
        };
      // // CREATE TRAINING
      openCreateTrainingDialog({
        diver,
        createContext,
        sessionsBillingResumes,
        aggregatedData,
      });
    } else if (type === 'training' && !isVirtual) {
      // // EDIT TRAINING
      openEditTrainingDialog({
        purchasePackage: participantPurchase.purchasePackage,
        diver: participantPurchase.diver,
        sessionsBillingResumes,
        aggregatedData,
      });
    } else if (type === 'plan' && isVirtual) {
      // CREATE PLAN
      const age = diver.birthdate
        ? dateService.getAge(diver.birthdate)
        : diver.age;
      let divesCount = totalConsumedCount + divesCounts.future;
      if (divesCount === 0) {
        divesCount = divesCounts.cancelled; // si il n'y a que des plongées annulées, on les impute par défaut
      }
      const createContext: DiverPurchasePlanEditorDialogMatchingOfferCriteria =
        {
          divesCount,
          successiveDivesCount: participantPurchase.successiveDivesCount,
          supervision: planSupervision,
          equipment: planEquipment,
          age,
          isInstructor: participantPurchase.diveMode === 'instructor',
          specialDiveType: participantPurchase.specialDiveType,
          diveSessionTheme: participantPurchase.diveSessionTheme,
        };
      const showDistanceInBilling =
        clubResume.clubSettings.general.billing?.showDistance;
      if (
        showDistanceInBilling &&
        (participantPurchase.diveMode === 'autonomous' ||
          participantPurchase.diveMode === 'supervised')
      ) {
        createContext.divesDistanceMin = participantPurchase.minDistance;
        createContext.divesDistanceMax = participantPurchase.maxDistance;
      }

      const showDepthInBilling =
        clubResume.clubSettings.general.billing?.showDepth; // juste pour les encadrés
      if (showDepthInBilling && participantPurchase.diveMode === 'supervised') {
        createContext.divesDepthMin = participantPurchase.minDepth;
        createContext.divesDepthMax = participantPurchase.maxDepth;
      }

      openCreatePlanDialog({
        diver,
        createContext,
        sessionsBillingResumes,
        aggregatedData,
      });
    } else if (type === 'plan' && !isVirtual) {
      // // EDIT PLAN
      openEditPlanDialog({
        purchasePackage: participantPurchase.purchasePackage,
        diver: participantPurchase.diver,
        sessionsBillingResumes,
        aggregatedData,
      });
    } else if (type === 'other' && isVirtual) {
      const age = diver.birthdate
        ? dateService.getAge(diver.birthdate)
        : diver.age;
      const createContext: DiverPurchaseOtherEditorDialogMatchingOfferCriteria =
        {
          diveMode: participantPurchase.diveMode as any,
          divesNumber: totalConsumedCount + divesCounts.future,
          supervision: planSupervision,
          equipment: planEquipment,
          age,
          diveSessionTheme: participantPurchase.diveSessionTheme,
          firstDiveTrainingReference: firstDiveReference,
        };

      // // CREATE OTHER
      openCreatePurchaseOtherDialog({
        diver,
        createContext,
        sessionsBillingResumes,
        aggregatedData,
      });
    } else if (type === 'other' && !isVirtual) {
      // // EDIT OTHER
      openEditPurchaseOtherDialog({
        purchasePackage: participantPurchase.purchasePackage,
        diver: participantPurchase.diver,
        sessionsBillingResumes,
        aggregatedData,
      });
    }
  }, [
    participantPurchase,
    isVirtual,
    diver,
    openCreateTrainingDialog,
    sessionsBillingResumes,
    aggregatedData,
    openEditTrainingDialog,
    totalConsumedCount,
    divesCounts.future,
    divesCounts.cancelled,
    planSupervision,
    planEquipment,
    clubResume.clubSettings.general.billing?.showDistance,
    clubResume.clubSettings.general.billing?.showDepth,
    openCreatePlanDialog,
    openEditPlanDialog,
    firstDiveReference,
    openCreatePurchaseOtherDialog,
    openEditPurchaseOtherDialog,
  ]);

  return onClickCreateEditPurchase;
}
