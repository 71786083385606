import { ClubResumeDiveSite } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useDiveCenterResume } from './useDiveCenterResume.hook';

export function useDiveCenterDiveSites(
  diveSiteIds: string[],
): ClubResumeDiveSite[] {
  const diveCenterResume = useDiveCenterResume();

  const diveSites: ClubResumeDiveSite[] = useMemo(() => {
    if (diveSiteIds) {
      return diveSiteIds.map((diveSiteId) =>
        diveCenterResume?.diveSites.find((ds) => ds._id === diveSiteId),
      );
    }
  }, [diveCenterResume?.diveSites, diveSiteIds]);

  return diveSites;
}
