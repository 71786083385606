/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useFieldArray } from 'react-hook-form';

import { AppMessageLight } from 'src/business/_core/modules/layout';
import { useClubResume } from 'src/business/club/data/hooks';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';

import { ClubSettingsProductOffersDialogLocalState } from '../useClubSettingsProductOffersDialogLocalState.hook';
import { ClubSettingsProductOffersFiltersArea } from './ClubSettingsProductOffersFiltersArea';
import { ClubSettingsProductOffersFormOffer } from './ClubSettingsProductOffersFormOffer';

export const ClubSettingsProductOffersForm = ({
  localState,
  className,
}: {
  localState: ClubSettingsProductOffersDialogLocalState;
  className?: string;
}) => {
  const {
    state,
    data: { offersFilteredReferences },
  } = localState;

  const { form, initialState } = state;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;
  const publicSettings = clubResume.clubSettings.publicSettings;

  const offersFieldArray = useFieldArray({
    control,
    name: 'offers',
  });

  const { mode, defaultValue } = initialState;

  return (
    <ClubSettingsSection className={className} title="Tarifs">
      <ClubSettingsProductOffersFiltersArea
        className="my-2"
        filtersState={localState.state.filtersState}
      />
      {mode === 'create' && offersFieldArray.fields.length > 1 && (
        <AppMessageLight type="warn">
          Seuls les tarifs avec un prix renseigné seront créés.
        </AppMessageLight>
      )}
      <div className="grid gap-2">
        <div>
          {offersFieldArray.fields
            .filter((x) => offersFilteredReferences.includes(x.reference))
            .map((offerField) => (
              <ClubSettingsProductOffersFormOffer
                key={offerField.reference}
                form={form}
                offerField={offerField}
                offersFieldArray={offersFieldArray}
                localState={localState}
              />
            ))}
        </div>
      </div>
      {/* {!createTrainingsFromOrg &&
        contextDiveModesToCreate.map((diveMode) => (
          <AppButton
            color="primary-outline-light"
            size="normal"
            icon={AppHeroIcons.actionAdd}
            onClick={() => addNewOffer(diveMode)}
          >
            Ajouter un tarif
          </AppButton>
        ))} */}
    </ClubSettingsSection>
  );
};
