/* eslint-disable @typescript-eslint/no-unused-vars */
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import { UseFieldArrayReturn, useWatch } from 'react-hook-form';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DailyExportConfigFormModel } from '../../_model';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';
import { DailyExportConfigOptionsPanelSheetContentItemEvents } from './DailyExportConfigOptionsPanelSheetContentItemEvents';
import { DailyExportConfigOptionsPanelSheetContentItemPickups } from './DailyExportConfigOptionsPanelSheetContentItemPickups';
import { DailyExportConfigOptionsPanelSheetContentItemSessions } from './DailyExportConfigOptionsPanelSheetContentItemSessions';

export type DailyExportConfigOptionsPanelSheetContentItemOnClickAction =
  | 'move-down'
  | 'move-up'
  | 'delete';

export const DailyExportConfigOptionsPanelSheetContentItem = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  fieldArray,
  onClick,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  onClick: (
    action: DailyExportConfigOptionsPanelSheetContentItemOnClickAction,
  ) => void;
  fieldArray: UseFieldArrayReturn<
    DailyExportConfigFormModel,
    `dailyResumes.${number}.exportConfig.sheets.${number}.content.items`,
    'id'
  >;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}` as const;

  const [contentItemId] = useWatch({
    control,
    name: [`${baseFormName}.id`],
  });

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  const { fields, append, prepend, remove, swap, move, insert } = fieldArray;

  const isMoveDownAllowed =
    fieldArray.fields.length > 1 &&
    contentItemIndex + 1 < fieldArray.fields.length;
  const isMoveUpAllowed =
    fieldArray.fields.length > 1 && contentItemIndex !== 0;

  return (
    <div
      className={clsx(
        'border-l-4 border-gray-600 pl-2 grid md:flex gap-2 lg:gap-4 items-start',
        className,
      )}
    >
      <div
        className={clsx(
          'h-full flex md:flex-col md:justify-center gap-2',
          fieldArray.fields.length === 1 && 'invisible',
        )}
      >
        <Tippy
          delay={[1000, 100]}
          placement="top"
          content={'Déplacer au dessus'}
        >
          <AppIconsAction.up
            className={clsx(
              'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
              isMoveUpAllowed
                ? 'hover:bg-gray-600 cursor-pointer'
                : 'opacity-50 cursor-not-allowed',
            )}
            onClick={() => isMoveUpAllowed && onClick('move-up')}
          />
        </Tippy>
        <Tippy
          delay={[1000, 100]}
          placement="top"
          content={'Déplacer en dessous'}
        >
          <AppIconsAction.down
            className={clsx(
              'p-1 w-8 h-8 bg-gray-200 rounded-full text-white',
              isMoveDownAllowed
                ? 'hover:bg-gray-600 cursor-pointer'
                : 'opacity-50 cursor-not-allowed',
            )}
            onClick={() => isMoveDownAllowed && onClick('move-down')}
          />
        </Tippy>
      </div>
      <div className="flex-grow border-l-4 border-gray-200 pl-4">
        {contentItemId === 'events' ? (
          <DailyExportConfigOptionsPanelSheetContentItemEvents
            localState={localState}
            dailyResumeIndex={dailyResumeIndex}
            sheetIndex={sheetIndex}
            contentItemIndex={contentItemIndex}
          />
        ) : contentItemId === 'pickup' ? (
          <DailyExportConfigOptionsPanelSheetContentItemPickups
            localState={localState}
            dailyResumeIndex={dailyResumeIndex}
            sheetIndex={sheetIndex}
            contentItemIndex={contentItemIndex}
          />
        ) : contentItemId === 'sessions' ? (
          <DailyExportConfigOptionsPanelSheetContentItemSessions
            localState={localState}
            dailyResumeIndex={dailyResumeIndex}
            sheetIndex={sheetIndex}
            contentItemIndex={contentItemIndex}
          />
        ) : (
          <div className="my-2">{` ID: ${contentItemId}`}</div>
        )}
      </div>
      <div className="flex gap-2 md:gap-6">
        <Tippy delay={[1000, 100]} placement="top" content={'Supprimer'}>
          <AppIconsAction.delete
            className="p-1 w-8 h-8 bg-red-200 hover:bg-red-600 rounded-full text-white cursor-pointer"
            onClick={() => {
              onClick('delete');
            }}
          />
        </Tippy>
      </div>
    </div>
  );
};
