import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';

export const AppTitleDateNavigatorPeriodLabel = ({
  beginDate,
  endDate,
  isCurrentPeriod,
  className,
}: {
  beginDate: Date;
  endDate: Date;
  isCurrentPeriod: boolean;
  className?: string;
}) => {
  const beginDateText = useMemo<string>(() => {
    const date = dateService.formatUTC(beginDate, 'DD/MM/YYYY');
    return date;
  }, [beginDate]);

  const endDateText = useMemo<string>(() => {
    const date = dateService.formatUTC(endDate, 'DD/MM/YYYY');
    return date;
  }, [endDate]);

  return (
    <div
      className={`font-bold text-center ${
        isCurrentPeriod
          ? 'app-theme-anim-live-primary group-hover:app-theme-anim-live-primary-dark'
          : 'text-gray-600 group-hover:text-gray-700'
      } ${className}`}
      // onClick={() => {
      //   if (!selectedDay?.isToday) {
      //     const newDay = buildSelectedDay(
      //       dateTransformer.getUTCDateSetTime(new Date())
      //     );
      //     onChange(newDay?.date);
      //   }
      // }}
    >
      <div
        className="text-base leading-5 uppercase"
        style={{ minWidth: '150px' }}
      >
        <span className="text-xs">du</span> {beginDateText}
      </div>
      <div className="text-base leading-5 uppercase">
        <span className="text-xs">au</span> {endDateText}
      </div>
    </div>
  );
};
