/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubDiveSiteGroup,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { Button, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalClasses } from 'src/AppTheme';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useLoadable, useRedirect } from 'src/business/_core/data/hooks';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import {
  AppBreadcrumbBarDeprecated,
  AppFixedButtonsBar,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppIconsMaterial } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { RxjsForm } from 'src/lib/form/components/rxjs-form';
import useRouter from 'use-react-router';
import { clubSettingsDiveCenterUrlBuilder } from '../../clubSettingsDiveCenterUrlBuilder.service';
import { diveSiteGroupEditPageRepository } from './diveSiteGroupEditPageRepository.service';
import { DiveSiteGroupForm, DiveSiteGroupFormModel } from './DiveSiteGroupForm';

export type DiveSiteGroupEditPageContext = {
  mode: 'create' | 'edit';
};

export const DiveSiteGroupEditPage = ({
  navigationContext,
}: {
  navigationContext: DiveSiteGroupEditPageContext;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const { match } =
    useRouter<{
      diveSiteGroupId: string;
    }>();

  const diveSiteGroupId = match.params.diveSiteGroupId;

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const { _error, _loaded, form, components, diveSiteGroup } = useLoadable({
    debugName: 'DiveSiteGroupEditPage',
    initialValueFromLoadSnapshot: true,
    load: () =>
      diveSiteGroupEditPageRepository.loadInitialData({
        navigationContext,
        diveCenterResume,
        diveSiteGroupId,
      }),
  });

  useMemo(() => {
    if (_error) {
      appLogger.warn('Error loading DiveSiteGroupEditPage: redirect');
      back();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_error]);

  return !_loaded ? null : (
    <AppPageContainer className={'bg-white'}>
      <AppBreadcrumbBarDeprecated onClose={() => back()}>
        <Link color="inherit" to="/club/setting" className="link">
          <AppIconsMaterial.settings className="icon" /> Paramètres
        </Link>
        <Link color="inherit" to="/club/settings/sites" className="link">
          <AppIconsMaterial.divers className="icon" /> Sites
        </Link>
        {navigationContext.mode === 'create' ? (
          <Typography color="textPrimary" className="end-text">
            Nouveau
          </Typography>
        ) : (
          <Typography color="textPrimary" className="end-text">
            {diveSiteGroup.name}
          </Typography>
        )}
      </AppBreadcrumbBarDeprecated>

      <AppPageContentContainer className="bg-white app-px-content">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            save(form, {
              clubReference,
              diveCenterId,
              diveSiteGroupId,
            });
          }}
        >
          <DiveSiteGroupForm components={components} />
        </form>
      </AppPageContentContainer>
      <AppFixedButtonsBar hasChanges={form.hasChanges}>
        <>
          <Button
            startIcon={<AppIconsMaterial.cancel />}
            variant="outlined"
            tabIndex={500}
            className={globalClasses.buttonCancel}
            onClick={() => back()}
          >
            Annuler
          </Button>
          <Button
            type="submit"
            startIcon={<AppIconsMaterial.save />}
            variant="contained"
            className={globalClasses.buttonSave}
            onClick={() =>
              save(form, {
                clubReference,
                diveCenterId,
                diveSiteGroupId,
              })
            }
          >
            Enregistrer
          </Button>
        </>
      </AppFixedButtonsBar>
    </AppPageContainer>
  );

  function back(): void {
    return redirectTo(clubSettingsDiveCenterUrlBuilder.diveSites.list());
  }

  async function save(
    form: RxjsForm<DiveSiteGroupFormModel>,
    {
      clubReference,
      diveCenterId,
      diveSiteGroupId,
    }: {
      clubReference: string;
      diveCenterId: string;
      diveSiteGroupId?: string;
    },
  ) {
    if (navigationContext.mode === 'create') {
      const diveSiteGroup =
        repositoryEntityBuilder.buildEntity<ClubDiveSiteGroup>({
          clubReference,
          diveCenterId,
          ...form.value,
        });
      await clubMassiveUpdatorClient.update({
        newDiveSitesGroups: [diveSiteGroup],
      });
    } else {
      const patchOperations = jsonPatcher.compareObjects(
        form.initialValue,
        form.value,
        {},
      );
      if (patchOperations.length) {
        const patch: AppEntityUpdatePatch = {
          pk: diveSiteGroupId,
          patchOperations,
        };
        await clubMassiveUpdatorClient.update({
          updatedDiveSitesGroups: [patch],
        });
      }
    }
    back();
  }
};
