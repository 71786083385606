// https://heroicons.com/ ?

import {
  CogIcon,
  CurrencyEuroIcon,
  GiftIcon,
  IdentificationIcon,
  ReceiptPercentIcon,
  ShoppingBagIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { ReactComponent as accomodation } from 'src/assets/icons/customer/hotel.svg';
import { ReactComponent as pickup } from 'src/assets/icons/customer/mini-bus.svg';
import { ReactComponent as travelAgency } from 'src/assets/icons/customer/travel-agency.svg';
import { ReactComponent as diveCenter } from 'src/assets/icons/scuba-1399350.svg';
import { ReactComponent as customers } from 'src/assets/icons/settings/friends-group.svg';
import { ReactComponent as modules } from 'src/assets/icons/settings/modules.svg';
import { ReactComponent as display } from 'src/assets/icons/settings/option.svg';
import { ReactComponent as tax } from 'src/assets/icons/settings/tax.svg';
import { ReactComponent as companyInfo } from 'src/assets/icons/settings/visiting-card.svg';
// https://heroicons.com/ ?

import {
  CalendarIcon,
  EnvelopeIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { CircleStackIcon, MapPinIcon } from '@heroicons/react/24/solid';

import { IoMdBoat } from 'react-icons/io';
export const AppIconsSettings = {
  general: CogIcon,
  home: CogIcon,
  diveCenter: {
    color: '#4eb6ca',
    main: diveCenter,
    planning: CalendarIcon,
    staff: UsersIcon,
    diveSite: MapPinIcon,
    boat: IoMdBoat,
  },
  account: {
    color: '#94C973',
    // main: LockClosedIcon,
    main: UserCircleIcon,
    mabadiveBilling: ReceiptPercentIcon,
    companyInfo: companyInfo,
    services: GiftIcon,
    pricing: CurrencyEuroIcon,
  },
  customers: {
    color: '#CB7BD4',
    main: customers,
    formFields: IdentificationIcon,
    customerSpace: UserCircleIcon,
    onlineBooking: ShoppingBagIcon,
    pickup,
    accomodation,
    communication: EnvelopeIcon,
    payment: CurrencyEuroIcon,
    agencies: travelAgency,
  },
  other: {
    color: '#523A28',
    main: CogIcon,
    // main: UserCircleIcon,
    general: CogIcon,
    data: CircleStackIcon,
    display,
    tax,
  },
  modules: {
    color: '#FFA384',
    main: modules,
  },
};
