import { AppCurrency } from '@mabadive/app-common-model';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DailyExportConfigDialogProps } from './_model';

export type DailyExportConfigDialogActionPersistProps = {
  // onCreate: ({ bookingDeposit }: { bookingDeposit: BookingDeposit }) => any;
  // onUpdate: (props: {
  //   bookingDeposit: BookingDeposit;
  //   patch: AppEntityUpdatePatch;
  // }) => any;
};

export function useDailyExportConfigDialogActionPersist(
  props: DailyExportConfigDialogActionPersistProps,
): DailyExportConfigDialogProps {
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  const appCurrency: AppCurrency = useAppCurrencyMain({ generalSettings });

  const action: DailyExportConfigDialogProps = {
    async onConfirm({ formValue }, initialState) {
      
    },
  };
  return action;
}
