import { AppBookletPageGql_Company } from '@mabadive/app-common-model';
import { QueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ProEcommerceDataLoadedContent } from './ProEcommerceDataLoadedContent.hook';

export function useProEcommerceDataUpdateCacheAppBookletPage({
  queryClient,
  queryKey,
}: {
  queryClient: QueryClient;
  queryKey: (string | number)[];
}) {
  return useMemo(
    () => ({
      delete: (...itemIds: string[]) => {
        const data: ProEcommerceDataLoadedContent =
          queryClient.getQueryData(queryKey);
        if (data && data?.bookletPages) {
          const updatedItems = data?.bookletPages.filter(
            (x) => !itemIds.includes(x?._id),
          );
          const updatedData: ProEcommerceDataLoadedContent = {
            ...data,
            bookletPages: updatedItems,
          };
          queryClient.setQueryData(queryKey, updatedData);
        }
      },
      update: (...items: AppBookletPageGql_Company[]) => {
        const data: ProEcommerceDataLoadedContent =
          queryClient.getQueryData(queryKey);
        if (data && data?.bookletPages) {
          const itemsIds = items.map((x) => x?._id);
          const updatedItems = data?.bookletPages.map((x) => {
            if (itemsIds.includes(x?._id)) {
              return items.find((y) => y?._id === x?._id);
            }
            return x;
          });
          const updatedData: ProEcommerceDataLoadedContent = {
            ...data,
            bookletPages: updatedItems,
          };
          queryClient.setQueryData(queryKey, updatedData);
        }
      },
      create: (...items: AppBookletPageGql_Company[]) => {
        const data: ProEcommerceDataLoadedContent =
          queryClient.getQueryData(queryKey);
        if (data && data?.bookletPages) {
          const updatedItems = data?.bookletPages.concat(items);

          const updatedData: ProEcommerceDataLoadedContent = {
            ...data,
            bookletPages: updatedItems,
          };
          queryClient.setQueryData(queryKey, updatedData);
        }
      },
    }),
    [queryKey, queryClient],
  );
}
