import {
  ClubDiver,
  ClubParticipant,
  ClubSettingsUIPlanningPayment,
  DiveSession,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';

import { ColoredTagAvatarCard } from 'src/business/_core/modules/layout';
import { 
  participantTagsBuilder,
} from 'src/business/club/modules/club-planning/_components';

export function ParticipantDiveTags({
  participant,
  diver,
  diveSession,
  includeComment = true,
  includeBookingState = true,
  includeParticipantSessionNumber = false,
  includePayment = false,
  settingsUIPlanning,
  className,
  onClick,
}: {
  participant: Pick<
    ClubParticipant,
    'tags' | 'comment' | 'bookingState' | 'diveMode' | 'divesCount'
  >;
  diver: Pick<ClubDiver, 'birthdate'>;
  diveSession: Pick<DiveSession, 'time'>;
  includeComment?: boolean;
  includeBookingState?: boolean;
  includePayment?: boolean; // "includePayment" fonctionne seulement si "settingsUIPlanning" est renseigné
  includeParticipantSessionNumber?: boolean;
  settingsUIPlanning?: ClubSettingsUIPlanningPayment;
  className?: string;
  onClick?: () => void;
}) {
  const clubResume = useClubResume();

  const tags = useMemo(
    () =>
      !participant
        ? []
        : participantTagsBuilder.getParticipantTags({
            club: clubResume,
            participant,
            diver,
            diveSession,
            includeComment,
            includeBookingState,
            includePayment,
            includeSpecialDiveType: true,
            includeParticipantSessionNumber,
            settingsUIPlanning,
          }),
    [
      clubResume,
      diveSession,
      diver,
      includeBookingState,
      includeComment,
      includeParticipantSessionNumber,
      includePayment,
      participant,
      settingsUIPlanning,
    ],
  );

  return tags.length !== 0 ? (
    <div
      className={`flex justify-end gap-x-0.5 ${
        onClick ? 'cursor-pointer' : ''
      } ${className ?? ''}`}
      onClick={onClick}
    >
      {tags.map((tag, i) => (
        <ColoredTagAvatarCard key={i} tag={tag} size="small" />
      ))}
    </div>
  ) : null;
}
