/* eslint-disable @typescript-eslint/no-unused-vars */
import { search } from '@mabadive/app-common-services';
import { useMemo, useState } from 'react';
import {
  appLoader,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { adminAppOperationsGraphqlFetcher } from './services';

const isDev = appWebConfig.envId === 'dev';

export function useAdminAppOperationsListPageLocalState() {
  const [filters, setFilters] = useState<{
    searchText: string;
    displayLimit: number;
    fetchLimit: number;
    includeSuperAdmin: boolean;
    includeSuccess: boolean;
    includeError: boolean;
  }>({
    displayLimit: 10,
    fetchLimit: 1000,
    searchText: '',
    includeSuperAdmin: isDev,
    includeSuccess: false,
    includeError: true,
  });
  const { content: appOperationsLoaded, ...loadableContent } =
    useLoadableContent(
      () =>
        appLoader.load(
          adminAppOperationsGraphqlFetcher.fetchAll({
            limit: filters.fetchLimit,
            includeSuperAdmin: filters.includeSuperAdmin,
            includeSuccess: filters.includeSuccess,
            includeError: filters.includeError,
          }),
          {
            type: 'full',
            defaultValue: undefined,
            isSubscription: true,
          },
        ),
      [
        filters.fetchLimit,
        filters.includeError,
        filters.includeSuccess,
        filters.includeSuperAdmin,
      ],
      {
        initialValue: undefined,
        defaultValue: undefined,
        useSnapshot: false,
        debugName: 'AdminAppOperationsListPage',
      },
    );

  const appOperations = useMemo(() => {
    let items = appOperationsLoaded ?? [];
    const { searchText } = filters;
    if (search.clean(searchText)?.length) {
      items = search.filter(items, {
        searchText,
        getAttributes: (x) => {
          const labels: string[] = [
            x.club?.reference,
            x.club?.name,
            x.authUser?.login,
          ];
          return labels;
        },
      });
    }
    if (items.length <= 3) {
      // si moins de 3 résultats, on les retourne sans appliquer les filtres
      return items;
    }
    // if (!showDraftClubs) {
    //   items = items.filter((x) => x.offer.state !== 'draft');
    // }
    // if (!showDisabledClubs) {
    //   items = items.filter((x) => x.offer.state !== 'archived');
    // }
    // if (!showActiveClubs) {
    //   items = items.filter((x) => x.offer.state !== 'active');
    // }
    // if (!showTrialClubs) {
    //   items = items.filter((x) => x.offer.state !== 'trial');
    // }

    return items;
  }, [appOperationsLoaded, filters]);

  return {
    filters,
    setFilters,
    appOperations: appOperations.slice(0, filters.displayLimit),
    loadableContent,
  };
}

export type AdminCompaniesListPageLocalState = ReturnType<
  typeof useAdminAppOperationsListPageLocalState
>;
