import {
  ProMultiOperationPayload,
  ProMultiOperationPayloadAction,
  ProMultiOperationResult,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { proMultiMutationsApiClient } from 'src/business/_core/data/app-operation';
import { EcommerceProductArticleEditorPayload } from './ecommerceProductArticleEditorResultBuilder.service';
import { EcommerceProductArticleEditorCallbackProps } from './useEcommerceProductArticleEditorPanelLocalState.hook';

export function useEcommerceProductArticleEditorCallbackPersist({
  onSuccess,
  closeDialog,
}: {
  onSuccess: (result: ProMultiOperationResult) => void;
  closeDialog: () => void;
}): EcommerceProductArticleEditorCallbackProps {
  return useMemo(
    () => ({
      onConfirm: async (formPayload: EcommerceProductArticleEditorPayload) => {
        // NOTE: progress + try/catch déjà gérés dans le dialog
        const { hasChanges, ...payloadAttributes } = formPayload;
        if (hasChanges) {
          const actions: ProMultiOperationPayloadAction[] = [
            { ...payloadAttributes },
          ];
          const payload: ProMultiOperationPayload = {
            logContext: 'update ecommerce productArticle',
            actions,
          };
          const result = await proMultiMutationsApiClient.update(payload);

          if (result) {
            // mise à jour du cache
            onSuccess(result);
          }

          closeDialog();
        }
      },
      onCancel: () => {
        closeDialog();
      },
    }),
    [closeDialog, onSuccess],
  );
}
