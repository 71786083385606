/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubOffer } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { useClubOfferState } from './useClubOfferState.hook';

export const ClubOfferCard = ({ offer }: { offer: ClubOffer }) => {
  const redirectTo = useRedirect();

  const yesterday = useMemo(() => dateService.add(new Date(), -1, 'day'), []);

  const clubOfferState = useClubOfferState(offer);

  const { isCurrentOfferOver, isPaymentDeadlineOver, isPaymentDeadlineSoon } =
    clubOfferState;

  return !offer ? null : (
    <>
      {offer?.state === 'demo' && (
        <>
          <p className="mt-2 text-base">Compte de démonstration</p>
        </>
      )}
      {offer?.state === 'draft' && (
        <>
          <p className="mt-2 text-base">
            Mabadive est un produit innovant réservé aux clubs professionnels et
            en constante évolution.
          </p>
          <p className="mt-5 text-lg font-bold text-app-blue">
            Pour obtenir une démo gratuite, et configurer votre compte ou
            importer vos données, veuillez nous contacter
          </p>
        </>
      )}
      {offer?.state === 'trial' && (
        <>
          <p className="mt-2 text-base">
            Mabadive est un produit innovant réservé aux clubs professionnels et
            en constante évolution.
          </p>
          {isCurrentOfferOver ? (
            <>
              <p className="mt-2 text-base">
                Votre <b>offre d'essai gratuite</b>{' '}
                <b className="font-bold text-status-error">est expirée</b>{' '}
                depuis le{' '}
                <b>
                  {dateService.formatUTC(offer?.currentOfferEnd, 'DD/MM/YYYY')}
                </b>
              </p>
              <p className="mt-2 text-base">
                Pour passer au forfait PRO, veuillez nous contacter.
              </p>
            </>
          ) : (
            <>
              <p className="mt-2 text-base">
                Vous bénéficiez de l'<b>offre d'essai gratuite</b> et sans
                engagement valable jusqu'au{' '}
                <b>
                  {dateService.formatUTC(offer?.currentOfferEnd, 'DD/MM/YYYY')}
                </b>
              </p>
              <p className="mt-2 text-base">
                Une question, un problème ou un nouveau besoin?
              </p>
              <div className="mt-5 text-lg font-bold text-app-blue">
                N'hésitez pas à nous contacter!
              </div>
            </>
          )}
        </>
      )}
      {offer?.state === 'active' && (
        <>
          <p className="mt-2 text-base">
            Votre forfait PRO est désormais activé.
          </p>
          {isCurrentOfferOver ? (
            <>
              <p className="mt-2 text-base">
                Votre <b>forfait PRO</b>{' '}
                <b className="font-bold text-status-error">est expiré</b> depuis
                le{' '}
                <b>
                  {dateService.formatUTC(offer?.currentOfferEnd, 'DD/MM/YYYY')}
                </b>
              </p>
              <p className="mt-2 text-base">
                Pour le renouveller, veuillez nous contacter.
              </p>
            </>
          ) : isPaymentDeadlineOver ? (
            <>
              {' '}
              <p className="mt-2 text-lg font-bold text-app-blue">
                Votre règlement est attendu depuis le{' '}
                <b className="text-status-error">
                  {dateService.formatUTC(offer?.paymentDeadline, 'DD/MM/YYYY')}
                </b>
              </p>
              <p className="mt-2 text-base">
                Pour continuer de bénéficier de Mabadive, merci de faire le
                nécessaire rapidement.
              </p>
            </>
          ) : offer?.paymentDeadline ? (
            <>
              <p className={'mt-5 text-lg font-bold text-app-blue'}>
                {isPaymentDeadlineSoon ? (
                  <AppHeroIcons.warn className="inline mr-1 w-6 h-6" />
                ) : (
                  <AppHeroIcons.info className="inline mr-1 w-6 h-6" />
                )}{' '}
                Votre règlement est attendu avant le{' '}
                <b
                  className={
                    isPaymentDeadlineSoon ? 'text-status-warn' : 'text-app-blue'
                  }
                >
                  {dateService.formatUTC(offer?.paymentDeadline, 'DD/MM/YYYY')}
                </b>
              </p>
              <p className="mt-2 text-base">
                Pour continuer de bénéficier de Mabadive, merci de faire le
                nécessaire avant la date limite de paiement.
              </p>
            </>
          ) : (
            <>
              <p className="mt-2 text-base">
                Vous bénéficiez du forfait <b>Mabadive PRO</b> valable jusqu'au{' '}
                <b>
                  {dateService.formatUTC(offer?.currentOfferEnd, 'DD/MM/YYYY')}
                </b>
              </p>
              <p className="mt-2 text-base">
                Une question, un problème ou un nouveau besoin?
              </p>
              <div className="mt-5 text-lg font-bold text-app-blue">
                N'hésitez pas à nous contacter!
              </div>
            </>
          )}
        </>
      )}
      {offer?.state === 'archived' && (
        <p className="mt-2 text-base">
          Votre compte est expiré. Pour l'activer, le configurer et/ou obtenir
          une démo, veuillez nous contacter.
        </p>
      )}
      <div className="mt-5 flex gap-6">
        <AppButton
          className="mt-5"
          color="primary-outline-light"
          icon={AppHeroIcons.contact}
          onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
        >
          Contactez-nous
        </AppButton>
      </div>
    </>
  );
};
