import { BaseEntity } from '@mabadive/app-common-model';
import {
  UseAppFetchDataListWithCacheOptions,
  useAppFetchDataListWithCache,
} from './useAppFetchDataListWithCache.hook';
import { AppFetchedDataState } from './useAppFetchDataWithCache.hook';

export type UseAppFetchDataListEntitiesWithCacheOptions<FC, I> = Omit<
  UseAppFetchDataListWithCacheOptions<FC, I>,
  'compareResults' | 'getUpdateDate'
> &
  Partial<
    Pick<
      UseAppFetchDataListWithCacheOptions<FC, I>,
      'compareResults' | 'getUpdateDate'
    >
  >;

// NOTE: le cache est filtré en cas de suppression dans "clubMassiveUpdatorClient.service"

export function useAppFetchDataListEntitiesWithCache<
  I extends BaseEntity,
  FC = any,
>(
  fetchCriteria: FC,
  options: UseAppFetchDataListEntitiesWithCacheOptions<FC, I>,
): AppFetchedDataState<I[]> {
  const rootOptions: UseAppFetchDataListWithCacheOptions<FC, I> = {
    compareResults: (a: I, b: I) => a._id === b._id,
    getUpdateDate: (a: I) => a._updatedAt,
    ...options,
  };

  return useAppFetchDataListWithCache(fetchCriteria, rootOptions);
}
