/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import React from 'react';
import { AppIconsSocialMedia } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  useAppCurrencyMain,
  useRegionalSettings,
} from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsCommunicationMain_EmailAutoMessage } from '../_common';

export const ClubSettingsCommunicationMainViewPanel_Channels = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const mainCurrency = useAppCurrencyMain();
  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const replyToAddress =
    clubSettings.communication?.channels?.email?.directMode?.replyToAddress;
  const copyToAddress =
    clubSettings.communication?.channels?.email?.directMode?.copyToAddress;

  return (
    <ClubSettingsSection
      title="Moyens de communication"
      comment="De quelle manière souhaitez-vous communiquer avec vos clients"
    >
      <div className="grid gap-y-4">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <AppFeatureBadge
            enabled={clubSettings.communication?.channels?.email?.enabled}
            label="Email"
            icon={
              <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
            }
          />
          <AppFeatureBadge
            enabled={clubSettings.communication?.channels?.sms?.enabled}
            label="SMS"
            icon={<AppIconsSocialMedia.sms className="h-6 w-6 text-app-sms" />}
          />
          <AppFeatureBadge
            enabled={clubSettings.communication?.channels?.phone?.enabled}
            label="Téléphone"
            label2="(appel)"
            icon={
              <AppIconsSocialMedia.phone className="h-6 w-6 text-app-sms" />
            }
          />
          <AppFeatureBadge
            enabled={clubSettings.communication?.channels?.whatsApp?.enabled}
            label="WhatsApp"
            icon={
              <AppIconsSocialMedia.whatsapp className="h-5 w-5 text-app-whatsapp" />
            }
          />
        </div>
        <ClubSettingsCommunicationMain_EmailAutoMessage />
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <AppFeatureBadge
            enabled={
              clubSettings.communication?.channels?.email?.directMode
                ?.directManualEnabled
            }
            label={
              clubSettings.communication?.channels?.email?.directMode
                ?.directManualEnabled
                ? `Envoi direct ⚡ (${clubSettings.communication?.channels?.email?.directMode?.fromName} <${replyToAddress}>)`
                : 'Envoi direct ⚡'
            }
            extraBadgeContent={
              copyToAddress?.trim()?.length > 0 ? (
                <div className="text-xs uppercase text-gray-500">
                  Copie à {copyToAddress}
                </div>
              ) : undefined
            }
            icon={
              <AppIconsSocialMedia.mail className="h-6 w-6 text-app-mail" />
            }
          />
        </div>
      </div>
    </ClubSettingsSection>
  );
};
