import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { graphqlClient, MQueryDescription } from 'src/_common-browser';
import {
  BookingParticipantEditorFetcherCriteria,
  BookingParticipantEditorGraphqlQueryReturnType,
} from '../model';
import { buildBookingParticipantEditorGraphqlQuery } from './query-builder';

export const bookingParticipantEditorFetcher = {
  findOne,
};

function findOne(
  criteria: BookingParticipantEditorFetcherCriteria,
  {
    type,
  }: {
    type: 'subscription' | 'query';
  },
): Observable<BookingParticipantEditorGraphqlQueryReturnType> {
  const query: MQueryDescription<any> =
    buildBookingParticipantEditorGraphqlQuery(criteria);

  return graphqlClient.query
    .runOne<BookingParticipantEditorGraphqlQueryReturnType>(query, {
      type,
      name: 'bookingParticipantEditorFetcher',
    })
    .pipe(
      catchError((err) => {
        appLogger.warn(
          '[bookingParticipantEditorFetcher] error fetching data',
          err,
        );
        return throwError(err);
      }),
    );
}
