/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CLUB_EVENT_PRIORITIES,
  CLUB_EVENT_STATUSES,
  ClubEventPriority,
  ClubEventStatus,
} from '@mabadive/app-common-model';
import {
  clubEventPriorityFormatter,
  clubEventStatusFormatter,
} from '@mabadive/app-common-services';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppInputRHF, AppInputTimeRHF } from 'src/lib/form';
import { AppEmojiPickerPopoverRHF } from 'src/lib/form/components/AppEmojiPicker';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import {
  ClubEventDialogStateMode,
  ClubEventEditorFormModel,
} from '../../model';

const PRIORITY_OPTIONS = CLUB_EVENT_PRIORITIES.map((x) => {
  const option: ValueLabel<ClubEventPriority, string> = {
    value: x,
    label: clubEventPriorityFormatter.formatClubEventPriority(x),
  };
  return option;
});
const STATUS_OPTIONS = CLUB_EVENT_STATUSES.map((x) => {
  const option: ValueLabel<ClubEventStatus, string> = {
    value: x,
    label: clubEventStatusFormatter.formatClubEventStatus(x),
  };
  return option;
});

export const ClubEventEditFormRHF = ({
  mode,
  form,
  cloneParticipantsCount,
}: {
  mode: ClubEventDialogStateMode;
  form: UseFormReturn<ClubEventEditorFormModel>;
  cloneParticipantsCount?: number;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const hasMultiSessionsEnabledRole =
    clubResume.clubSettings.general?.sessions?.multiSessions?.enabled;

  const [beginTime] = useWatch({
    control,
    name: ['clubEvent.schedule.beginTime'],
  });

  const showDate = mode === 'create' || mode === 'clone';

  return (
    <>
      <div
        className={
          'flex flex-col gap-y-1 gap-x-2 md:grid md:grid-cols-2 md:gap-x-4 p-2'
        }
      >
        <div
          className={
            'w-full flex flex-col xs:flex-row gap-y-2 gap-x-1 xs:justify-between'
          }
        >
          <AppFormControlRHF_Deprecated
            className={`w-full ${showDate ? 'col-span-4' : 'col-span-6'}`}
            label={'Nom'}
            required
            control={control}
            name={'clubEvent.description.title'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={''}
            label={'Icône Emoji'}
            control={control}
            name={'clubEvent.description.emojiId'}
            renderComponent={(props) => <AppEmojiPickerPopoverRHF {...props} />}
          />
        </div>
        <AppFormControlRHF_Deprecated
          className="w-full col-span-2"
          label="Description"
          control={control}
          name={'clubEvent.description.comment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
        <div className={'flex gap-2'}>
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={'Date début'}
            required
            control={control}
            name="clubEvent.beginDate"
            renderComponent={(props) => (
              <AppInputDatePickerMaterialRHF
                className="w-full"
                {...props}
                onChange={(beginDate) => {
                  // sync endDate on change
                  setValue('clubEvent.endDate', beginDate);
                }}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={'Date fin'}
            control={control}
            name="clubEvent.endDate"
            renderComponent={(props) => (
              <AppInputDatePickerMaterialRHF className="w-full" {...props} />
            )}
          />
        </div>
        <div className={'flex gap-2'}>
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={'Heure début'}
            control={control}
            name={'clubEvent.schedule.beginTime'}
            renderComponent={(props) => <AppInputTimeRHF {...props} />}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={'Heure fin'}
            control={control}
            name={'clubEvent.schedule.endTime'}
            renderComponent={(props) => <AppInputTimeRHF {...props} />}
          />
        </div>
        <div className={'flex gap-2'}>
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={'Importance'}
            control={control}
            name={'clubEvent.priority'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                {...props}
                theme={'tailwind'}
                options={PRIORITY_OPTIONS}
                disableClearButton={true}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={'Statut'}
            control={control}
            name={'clubEvent.status'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                theme={'tailwind'}
                options={STATUS_OPTIONS}
                disableClearButton={true}
                {...props}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};
