import { MonthPlanning, WeekPlanning } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { DiveSessionResumeLoadBounds } from 'src/business/_core/data/store-repository/dive-sessions/model';
import { PlanningViewConfig } from '../../_model';

export function useWeekPlanningConfigBounds({
  planningConfig,
  weekPlanning,
  monthPlanning,
}: {
  planningConfig: PlanningViewConfig;
  weekPlanning: WeekPlanning;
  monthPlanning: MonthPlanning;
}): DiveSessionResumeLoadBounds {
  const bounds = useMemo(() => {
    if (planningConfig.viewPeriod === 'month') {
      const minDateInclusive =
        monthPlanning.weeksPlanning[0].daysPlanning[0].weekDate.date;
      const lastWeek =
        monthPlanning.weeksPlanning[monthPlanning.weeksPlanning.length - 1];
      const maxDateExclusive = dateService.add(
        lastWeek.daysPlanning[lastWeek.daysPlanning.length - 1].weekDate.date,
        1,
        'day',
      );
      return {
        minDateInclusive: minDateInclusive,
        maxDateExclusive: maxDateExclusive,
      };
    } else {
      if (weekPlanning?.daysPlanning) {
        const minDateInclusive = weekPlanning.daysPlanning[0].weekDate.date;
        const maxDateExclusive = dateService.add(
          weekPlanning.daysPlanning[weekPlanning.daysPlanning.length - 1]
            .weekDate.date,
          1,
          'day',
        );
        return {
          minDateInclusive: minDateInclusive,
          maxDateExclusive: maxDateExclusive,
        };
      }
    }
    return {
      minDateInclusive: null,
      maxDateExclusive: null,
    };
  }, [
    monthPlanning.weeksPlanning,
    planningConfig.viewPeriod,
    weekPlanning.daysPlanning,
  ]);

  return bounds;
}
