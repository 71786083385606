/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  DiveGazType,
} from '@mabadive/app-common-model';
import { diveModeAnalyser } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { useGazTypeOptions } from 'src/pages/_components/options';

export const BookingParticipantEditFormRHFGroup3 = ({
  form,
  maxWidthMd,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  maxWidthMd: boolean;
  className?: string;
}) => {
  const clubResume = useClubResume();

  const { control, setValue } = form;

  const [diveMode] = useWatch({
    control,
    name: ['clubParticipant.diveMode'],
  });

  const diveGazTypeOptions: ValueLabel<DiveGazType>[] = useGazTypeOptions();

  return (
    <>
      {diveModeAnalyser.hasScubaDivingSecurityParams(diveMode) && (
        <div
          className={clsx(
            'w-full flex flex-col sm:grid sm:grid-cols-4 gap-4',
            !maxWidthMd && 'lg:grid-cols-8',
            className,
          )}
        >
          <AppFormControlRHF_Deprecated
            className={'lg:col-span-2 w-full'}
            label="Gaz"
            control={control}
            name={'clubParticipant.gaz.gazType'}
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={diveGazTypeOptions}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Contenance (L)"
            validation={{ rules: { min: 0, max: 50 } }}
            control={control}
            name={'clubParticipant.gaz.gazQuantity' as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Mélange (32%...)"
            control={control}
            name={'clubParticipant.gaz.gazDescription' as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'lg:col-span-1 w-full'}
            label="Prof. cible (m)"
            validation={{ rules: { min: 0, max: 300 } }}
            control={control}
            name={'clubParticipant.targetDeep'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
        </div>
      )}
    </>
  );
};
