import { DiveMode } from '@mabadive/app-common-model';
import { diveModeColorClassBuilder } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubSettings } from './useClubSettings.hook';

export function useDiveModeColor(diveMode: DiveMode): string {
  const clubSettings = useClubSettings();
  const diveModeColor = useMemo(
    () =>
      diveModeColorClassBuilder.getDiveModeColor(diveMode, {
        clubSettings,
      }),
    [clubSettings, diveMode],
  );
  return diveModeColor;
}
