import {
  BookingMember,
  ClubDiver,
  ClubResume,
} from '@mabadive/app-common-model';
import { changeDescriptorAggregator } from '@mabadive/app-common-services';
import { PRO_BookingMemberFull_WithDocs } from '../../../models';
import {
  ClubDiverSelectorDialogAggregatedData,
  ClubDiverSelectorDialogInputState,
  ClubDiverSelectorDialogUpdateState,
} from '../model';

export const clubDiverSelectorDialogAggregatedDataBuilder = {
  aggregate,
};

function aggregate({
  inputState,
  updateState,
  clubResume,
}: {
  inputState: ClubDiverSelectorDialogInputState;
  updateState: ClubDiverSelectorDialogUpdateState;
  clubResume: ClubResume;
}): ClubDiverSelectorDialogAggregatedData {
  const initialBookingMembers = (
    inputState.existingBookingMembersFull ?? []
  ).map((b) => b.bookingMember);
  const initialDivers = (inputState.existingBookingMembersFull ?? []).map(
    (b) => b.diver,
  );

  const bookingMembers: BookingMember[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.bookingMembersChanges,
      {
        pk: '_id',
        initials: initialBookingMembers,
      },
    );
  const divers: ClubDiver[] = changeDescriptorAggregator.aggregateMany(
    updateState.diversChanges,
    { pk: '_id', initials: initialDivers },
  );

  const existingBookingMembersFull: PRO_BookingMemberFull_WithDocs[] = (
    inputState.existingBookingMembersFull ?? []
  ).map((bookingMemberFull) => ({
    ...bookingMemberFull,
    bookingMember: bookingMembers.find(
      (x) => x._id === bookingMemberFull.bookingMember._id,
    ),
    diver: divers.find((x) => x._id === bookingMemberFull.diver._id),
    docResumes: [], // pas visible ici
  }));

  const newBookingMembers: (PRO_BookingMemberFull_WithDocs & {
    isNewDiver: boolean;
  })[] = updateState.newBookingMembers.map(({ isNewDiver, diverId }) => ({
    isNewDiver,
    booking: inputState.booking,
    bookingMember: bookingMembers.find((x) => x.diverId === diverId),
    diver: divers.find((x) => x._id === diverId),
    docResumes: [], // pas utile ici
  }));

  const data: ClubDiverSelectorDialogAggregatedData = {
    existingBookingMembersFull,
    newBookingMembers,
  };
  return data;
}
