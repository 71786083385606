import { Observable, Observer } from 'rxjs';
import { confirmDialogStore } from './confirmDialogStore.service';
import { ConfirmDialogType } from './ConfirmDialogType.model';

export const confirmDialog = {
  confirm,
  confirmPromise,
};

function confirmPromise(args: {
  title?: string;
  message: string;
  type: ConfirmDialogType;
}): Promise<boolean> {
  return confirm(args).toPromise();
}

function confirm({
  title,
  message,
  type,
  isTailwind,
}: {
  title?: string;
  message: string;
  type: ConfirmDialogType;
  isTailwind?: boolean;
}): Observable<boolean> {
  if (!title) {
    title = 'Demande de confirmation';
  }

  return Observable.create((observer: Observer<boolean>) => {
    confirmDialogStore.state.set({
      mode: 'open',
      title,
      message,
      observer,
      type,
      isTailwind,
    });
  });
}
