import {
  AppEntityUpdatePatch,
  BookingMemberConfig,
  CustomerSpaceDiverLink,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  appUrlBuilderCore,
  diverBookingBuilder,
  jsonPatcher,
  jsonPatcherSmart,
} from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppTabTailwind,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import {
  PRO_BookingMemberFull,
  PRO_BookingParticipantFull,
} from '../../models';
import {
  BookingCustomerConfigEditDialogInputState,
  BookingCustomerConfigEditFormModel,
  BookingCustomerConfigTabId,
} from './_model';

export function useBookingCustomerConfigEditDialogLocalState({
  inputState,
  setInputState,
  submitChanges,
}: {
  inputState: BookingCustomerConfigEditDialogInputState;
  setInputState: React.Dispatch<
    React.SetStateAction<BookingCustomerConfigEditDialogInputState>
  >;
  submitChanges?: (
    patch: AppEntityUpdatePatch,
    { persistNow }: { persistNow: boolean },
  ) => Promise<void>;
}) {
  const isNewBooking = inputState?.isNewBooking;
  const bookingId = inputState?.booking?._id;
  const bookingContact = inputState?.bookingContact;

  const bookingMembersFull: PRO_BookingMemberFull[] =
    inputState.bookingMembersFull;
  const bookingParticipantsFull: PRO_BookingParticipantFull[] =
    inputState.bookingParticipantsFull;

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setInputState({
        ...inputState,
        isOpen,
      });
    },
    [setInputState, inputState],
  );

  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const [tab, setTab] = useState<BookingCustomerConfigTabId>('tab-config');
  const [initialBooking, setInitialBooking] = useState(inputState.booking);
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const defaultBookingCustomerSpace =
    diverBookingBuilder.createDefaultBookingCustomerSpace({
      publicSettings: clubResume.clubSettings.publicSettings,
    });

  const enabledInquiries = useMemo(
    () =>
      clubResume.clubSettings?.general?.inquiry?.enabled
        ? (clubResume.inquiries ?? []).filter(
            (inquiry) => inquiry.settings?.enabled,
          )
        : [],
    [clubResume.clubSettings?.general?.inquiry?.enabled, clubResume.inquiries],
  );

  const initialBookingMembersConfigs = useMemo(() => {
    const bookingMembersConfigs: {
      bookingMemberFull: PRO_BookingMemberFull;
      config: BookingMemberConfig;
    }[] = bookingMembersFull.map((bookingMemberFull) => {
      const bmConfigInquiry = bookingMemberFull.bookingMember.config?.inquiry;
      const formBookingMemberConfig: BookingMemberConfig = {
        inquiry: {
          enabled: true, // TODO se baser sur la config globale
          inquiries: enabledInquiries.map((x) => {
            return {
              appInquiryId: x._id,
              enabled: true,
            };
          }),
        },
      };
      return {
        bookingMemberFull,
        config: formBookingMemberConfig,
      };
    });

    return bookingMembersConfigs;
  }, [bookingMembersFull, enabledInquiries]);

  const initialFormValue = useMemo<BookingCustomerConfigEditFormModel>(() => {
    return {
      ...initialBooking,
      bookingCustomerConfig:
        initialBooking.bookingCustomerConfig ??
        defaultBookingCustomerSpace.bookingCustomerConfig,
      bookingCustomerSpacePrivate:
        initialBooking.bookingCustomerSpacePrivate ??
        defaultBookingCustomerSpace.bookingCustomerSpacePrivate,
      bookingMembersConfigs: initialBookingMembersConfigs,
    };
  }, [
    defaultBookingCustomerSpace.bookingCustomerConfig,
    defaultBookingCustomerSpace.bookingCustomerSpacePrivate,
    initialBooking,
    initialBookingMembersConfigs,
  ]);

  const form = useForm<BookingCustomerConfigEditFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [generateIndividualLinks] = useWatch({
    control,
    name: ['bookingCustomerSpacePrivate.generateIndividualLinks'],
  });
  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  const confirmChanges = useCallback(
    (onConfirm: (patch?: AppEntityUpdatePatch) => void) => {
      return handleSubmit(
        (formValue: BookingCustomerConfigEditFormModel, event) => {
          if (hasFormChanges) {
            const patchOperations = jsonPatcher.compareObjects(
              initialBooking,
              {
                ...initialBooking,
                ...formValue,
              },
              {
                // else, value won't be deleted by typeorm
                // https://github.com/typeorm/typeorm/issues/2934
                replaceDeleteByNullValue: true,
              },
            );
            if (patchOperations.length) {
              const patch: AppEntityUpdatePatch = {
                pk: initialBooking._id,
                patchOperations,
              };
              onConfirm(patch);
            }
          } else {
            // no changes
            onConfirm(undefined);
          }
        },
        (err) => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
          throw err;
        },
      )();
    },
    [handleSubmit, hasFormChanges, initialBooking],
  );

  const submitForm = useCallback(async () => {
    try {
      await confirmChanges((patch?: AppEntityUpdatePatch) => {
        if (patch) {
          submitChanges(patch, { persistNow: false });
        }
      });
      setIsOpen(false);
    } catch (err) {
      // message already displayed
    }
  }, [confirmChanges, setIsOpen, submitChanges]);

  const resetWithLocalChanges = useCallback(
    (patch: AppEntityUpdatePatch) => {
      if (patch) {
        const patchedInitialBooking =
          jsonPatcherSmart.applySmartPatchOperations(initialBooking, {
            patchOperations: patch.patchOperations,
            logPrefix: 'BookingCustomerConfigEditDialog',
            ignoreErrors: true,
            appLogger,
          }).output;
        setInitialBooking(patchedInitialBooking);
        reset(patchedInitialBooking, {});
      }
    },
    [initialBooking, reset],
  );

  const [
    customerUiEnableCustomerUpdate,
    customerUiPendingListDiversContactInfo,
    customerUiPendingListDiversDivingInfo,
    customerUiPendingListDiversDiversPostalAddressInfo,
    customerUiPendingListDiversExpectedDiveInfo,
  ] = useWatch({
    control,
    name: [
      'bookingCustomerConfig.customerUi.enableCustomerUpdate',
      'bookingCustomerConfig.pendingList.diversContactInfo',
      'bookingCustomerConfig.pendingList.diversDivingInfo',
      'bookingCustomerConfig.pendingList.diversPostalAddressInfo',
      'bookingCustomerConfig.pendingList.diversExpectedDiveInfo',
    ],
  });

  const customerUpdateRequested =
    customerUiEnableCustomerUpdate &&
    (customerUiPendingListDiversContactInfo ||
      customerUiPendingListDiversDivingInfo ||
      customerUiPendingListDiversDiversPostalAddressInfo ||
      customerUiPendingListDiversExpectedDiveInfo);

  const [linkGenerationInProgress, setLinkGenerationInProgress] =
    useState(false);

  const diverLinks: CustomerSpaceDiverLink[] = useMemo(() => {
    const diverLinks = inputState.bookingMembersFull
      .map(({ booking, bookingMember, diver }) => {
        if (bookingMember.bookingAliasKey) {
          const url = appUrlBuilderCore.buildShortUrlLinkOnDiverSite({
            aliasKey: bookingMember.bookingAliasKey,
            diverWebUrl: appWebConfig.applications.diverWebUrl,
          });
          const customerSpaceDiverLink: CustomerSpaceDiverLink = {
            isBookingContact: booking.bookingContactDiverId === diver._id,
            bookingId,
            diverId: diver._id,
            firstName: diver.firstName,
            lastName: diver.lastName,
            emailAddress: diver.emailAddress,
            phoneNumber: diver.phoneNumber,
            url,
          };
          return customerSpaceDiverLink;
        }
      })
      .filter((x) => !!x);
    return diverLinks;
  }, [bookingId, inputState.bookingMembersFull]);

  const persistChanges = useCallback(async () => {
    try {
      if (inputState?.isNewBooking) {
        await confirmChanges((patch?: AppEntityUpdatePatch) => {
          if (patch) {
            submitChanges(patch, { persistNow: true });
            resetWithLocalChanges(patch);
          }
        });
      } else {
        // only persist local changes
        await confirmChanges(async (patch) => {
          if (patch) {
            const payload: ProMultiOperationPayload = {
              logContext: 'edit customer config',
              updatedBookings: [patch],
            };
            await clubMassiveUpdatorClient.update(payload);
            resetWithLocalChanges(patch);
          }
        });
      }
    } catch (err) {}
  }, [
    confirmChanges,
    inputState?.isNewBooking,
    resetWithLocalChanges,
    submitChanges,
  ]);

  // const generateBookingLink = useCallback(
  //   async ({
  //     generateIndividualLinks,
  //   }: {
  //     generateIndividualLinks: boolean;
  //   }) => {
  //     if (!linkGenerationInProgress) {
  //       setLinkGenerationInProgress(true);
  //       try {
  //         await persistChanges();
  //         const diverLinks: CustomerSpaceDiverLink[] =
  //           await authenticationClient.createCustomerSpaceDiverLinks({
  //             bookingId,
  //             contactMemberOnly: !generateIndividualLinks,
  //           });
  //         setDiverLinks(diverLinks);
  //         return diverLinks;
  //       } finally {
  //         setLinkGenerationInProgress(false);
  //       }
  //     }
  //   },
  //   [bookingId, linkGenerationInProgress, persistChanges],
  // );

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    createMessageToCustomers: {
      onUpdate: () => {
        console.log('[useClubDialogsActionsPersist] onUpdate called!');
      },
    },
  });
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const openCustomerSpaceConfigDialog = useCallback(() => {
    dialogsState.customerSpaceConfigDialog.openDialog({
      defaultValue: {
        clubSettings: clubResume?.clubSettings,
      },
    });
  }, [clubResume?.clubSettings, dialogsState.customerSpaceConfigDialog]);

  const tabs = useMemo(() => {
    const tabs: AppTabTailwind<BookingCustomerConfigTabId>[] = [];
    if (diverLinks?.length > 0) {
      tabs.push({
        id: 'tab-config',
        label: 'Configuration',
      });
      tabs.push({
        id: 'tab-links',
        label: 'Lien de connexion',
      });
      // tabs.push({
      //   id: 'tab-messages',
      //   label: 'Envoyer un message',
      // });
    }
    return tabs;
  }, [diverLinks?.length]);

  return {
    data: {
      clubReference,
      enabledInquiries,
      initialBooking,
      bookingMembersFull,
      bookingParticipantsFull,
      diverLinks,
    },
    state: {
      form,
      hasFormChanges,
      dialogsState,
      customerUpdateRequested,
      linkGenerationInProgress,
      tabs,
      tab,
      setTab,
    },
    actions: {
      setIsOpen,
      submitForm,
      confirmChanges,
      openCustomerSpaceConfigDialog,
      resetWithLocalChanges,
      persistChanges,
      generateIndividualLinks,
    },
  };
}

export type BookingCustomerConfigEditDialogLocalState = ReturnType<
  typeof useBookingCustomerConfigEditDialogLocalState
>;
