/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ClubSettingsGeneralPurchaseViewPanelMain } from './ClubSettingsGeneralPurchaseViewPanelMain';
import { ClubSettingsGeneralPurchaseViewPanelOptions } from './ClubSettingsGeneralPurchaseViewPanelOptions';
import { ClubSettingsGeneralPurchaseViewPanelTaxes } from './ClubSettingsGeneralPurchaseViewPanelTaxes';

export const ClubSettingsGeneralPurchaseViewPanel = () => {
  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsGeneralPurchaseViewPanelMain />
      <ClubSettingsGeneralPurchaseViewPanelOptions />
      <ClubSettingsGeneralPurchaseViewPanelTaxes />
    </div>
  );
};
