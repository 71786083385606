import { ClubEvent } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { map } from 'rxjs/operators';
import { clubEventsSorter } from 'src/business/club/data';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  LoadableContent,
  LoadableContentActions,
  LoadableContentLoadingMode,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import { clubEventRepository } from 'src/business/_core/data/store-repository/club-event';
import {
  DiveSessionResumeLoadBounds,
  DiveSessionResumeLoadCriteria,
} from 'src/business/_core/data/store-repository/dive-sessions/model';
export function useCalendarWeekClubEvents({
  criteria,
  mode,
}: {
  criteria: DiveSessionResumeLoadBounds;
  mode: LoadableContentLoadingMode;
}): LoadableContent<ClubEvent[]> & LoadableContentActions {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const loadCriteria: DiveSessionResumeLoadCriteria = useMemo(() => {
    if (clubReference && diveCenterId && criteria) {
      return {
        ...criteria,
        clubReference,
        diveCenterId,
      };
    }
  }, [clubReference, criteria, diveCenterId]);
  return useLoadableContent(
    () =>
      clubEventRepository
        .findManyWithSubscription(loadCriteria, {
          mode,
        })
        .pipe(
          map(({ content: clubEvents, ...x }) => {
            const sortedClubEvents = clubEventsSorter.sort(clubEvents);

            return {
              ...x,
              content: sortedClubEvents,
            };
          }),
        ),
    [loadCriteria, mode],
    {
      debugName: 'useCalendarWeekClubEvents',
      useSnapshot: false,
    },
  );
}
