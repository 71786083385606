/* eslint-disable @typescript-eslint/no-unused-vars */
import { nameFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useAutoFill } from 'src/business/_core/data/hooks';
import {
  DiverPurchasePaymentEditorFormRHF,
  DiverPurchasePaymentEditorPackagesTable,
  DiverPurchasePaymentEditorTotalToPay,
} from '../components';
import { DiverPurchasePaymentEditorDialogLocalState } from '../useDiverPurchasePaymentEditorDialogLocalState';

export const EditPaymentManualPaymentDetailsPanel = ({
  localState,
}: {
  localState: DiverPurchasePaymentEditorDialogLocalState;
}) => {
  const autoFill = useAutoFill();

  const { form, inputState, paymentChangesModel, initialFormValue } =
    localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });

  return (
    <>
      <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
        <span>Détail du paiement</span>
        <span className="hidden md-inline">
          {' '}
          -{' '}
          {nameFormatter.formatFullName(localState.data.selectedDiver, {
            format: 'firstName-first',
          })}
        </span>
      </h3>
      <div className="grid gap-4">
        <div>
          <div className="hidden md:table max-h-[35vh] md:max-h-[50vh] overflow-y-auto">
            <DiverPurchasePaymentEditorPackagesTable
              isEditEnabled={false}
              mode={inputState.mode}
              form={form}
              paymentChangesModel={paymentChangesModel}
            />
          </div>
          <DiverPurchasePaymentEditorTotalToPay
            form={form}
            paymentChangesModel={paymentChangesModel}
          />
        </div>
        {inputState.isOpen && initialFormValue && (
          <>
            <DiverPurchasePaymentEditorFormRHF localState={localState} />
          </>
        )}
      </div>
    </>
  );
};
