import { ClubPlanningLightSessionDef } from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  dateService,
} from '@mabadive/app-common-services';
import { useCallback } from 'react';
import {
  DiverBookingNewBookingSession,
  DiverBookingPageAggregatedData,
  DiverBookingPageUpdateState,
  PRO_BookingResume,
} from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
import { diverBookingPageUpdateStateManager } from '../_core';
import { diverBookingPageSessionCreator } from '../entity-creators';
export function useDiverBookingPageAddSessions({
  aggregatedData,
  clubReference,
  diveCenterId,
  setUpdateState,
  updateState,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  clubReference: string;
  diveCenterId: string;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    (
      sessions: ClubPlanningLightSessionDef[],
      { bookingId }: { bookingId: string },
    ): {
      newBookingSessions: DiverBookingNewBookingSession[];
      updateState: DiverBookingPageUpdateState;
    } => {
      let updateStateLocal = updateState;
      if (bookingId && sessions?.length) {
        const aggregatedBooking: PRO_BookingResume =
          aggregatedData.bookingResumesLoaded.find(
            (b) => b.booking._id === bookingId,
          );

        if (aggregatedBooking) {
          const alreadySelectedSessionsReferences =
            aggregatedBooking.bookingSessionsFull.map(
              (s) => s.diveSession.reference,
            );
          const newSessions = sessions.filter(
            (session) =>
              !alreadySelectedSessionsReferences.includes(session._id),
          );
          const { newBookingSessions, newDiveSessions, originalDiveSessions } =
            diverBookingPageSessionCreator.createSessions({
              sessions: newSessions,
              bookingId,
              clubReference,
              diveCenterId,
            });

          if (newDiveSessions.length || originalDiveSessions.length) {
            // add or create (if virtual) dive sessions
            updateStateLocal =
              diverBookingPageUpdateStateManager.addNewDiveSessionsToState({
                updateState: updateStateLocal,
                newDiveSessions,
                originalDiveSessions,
              });
          }
          // create bookings sessions
          const bookingSessionsChanges = changeDescriptorManager.createMany(
            newBookingSessions.map((x) => x.bookingSession),
            {
              changeDescriptors: updateStateLocal.bookingSessionsChanges,
            },
          );
          updateStateLocal = {
            ...updateStateLocal,
            bookingSessionsChanges,
          };

          setUpdateState(updateStateLocal, {
            action: 'add bookings sessions',
            meta: {
              bookingId,
              sessions: newBookingSessions.map((x) =>
                dateService.formatUTC(
                  x.diveSessionResume?.time,
                  'DD/MM/YYYY HH:mm',
                ),
              ),
            },
          });

          return {
            newBookingSessions,
            updateState: updateStateLocal,
          };
        }
      }
      return {
        newBookingSessions: [] as DiverBookingNewBookingSession[],
        updateState,
      };
    },
    [
      aggregatedData.bookingResumesLoaded,
      clubReference,
      diveCenterId,
      setUpdateState,
      updateState,
    ],
  );
}
