/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubEventCardGroup } from '../../club-events';
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningLightPanelStaffDailyCounters } from '../ClubPlanningLightPanelViewPeriodDay';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import { DiveSessionSimpleCard } from '../components';
import { CalendarSessionWidthMode } from '../components/ClubPlanningLightSession/session-common';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
  DiveSessionSimpleCardDisplayOptions,
} from '../model';
import { ClubDialogsState } from '../../../../../pages/_dialogs';

export const ClubPlanningLightPanelViewPeriodWeekSessionsModeCompactDay = ({
  planningParticipantsLocalState,
  dialogsState,
  context,
  planningConfig,
  day,
  clickActions,
  sessionsDisplayConfigs = [],
  widthMode,
  displayOptions,
  hasAnyEvent,
  className,
}: {
  planningParticipantsLocalState?: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
  dialogsState: ClubDialogsState; // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  context: ClubPlanningDisplayConfigurationBarContext;
  planningConfig: PlanningViewConfig;
  day: ClubPlanningDayFull;
  clickActions: ClubPlanningLightPanelViewClickActions;
  sessionsDisplayConfigs?: ClubPlanningPanelSessionDisplayConfig[];
  widthMode: CalendarSessionWidthMode;
  displayOptions: DiveSessionSimpleCardDisplayOptions;
  hasAnyEvent: boolean;
  className?: string;
}) => {
  const { onClickEvent } = clickActions;

  const diveCenterResume = useDiveCenterResume();

  const clubReference = diveCenterResume?.clubReference;

  const dailySessionsToRender = day.sessions;
  // const dailySessionsToRender = useProgressiveListRendering(day.sessions, {
  //   initialSize: 5,
  //   step: 5,
  // });

  return (
    <div
      className={clsx(
        'flex flex-col justify-start bg-gray-50 border-l border-r border-gray-200',
        className,
      )}
    >
      <>
        {planningConfig.showStaffCountsPerDay && (
          <ClubPlanningLightPanelStaffDailyCounters
            planningConfig={planningConfig}
            date={day.date}
            showTooltip={true}
            staffMembersResumeSessions={day.staffMembersResumeSessions}
            className="flex my-1 text-app-xxs md:text-xs"
            onClick={() => {
              clickActions.onClickStaffDailyCounters &&
                clickActions.onClickStaffDailyCounters({
                  clubReference,
                  day,
                });
            }}
          />
        )}
        {hasAnyEvent && (
          <ClubEventCardGroup
            className="min-h-[20px]"
            clubEvents={day.clubEvents}
            widthMode={widthMode}
            onClick={onClickEvent}
          />
        )}
        {dailySessionsToRender.map((session, iDailySession) => {
          return (
            <div
              key={iDailySession}
              className={
                'select-none text-gray-700 text-sm leading-4 flex flex-col justify-start'
              }
            >
              <>
                <DiveSessionSimpleCard
                  planningParticipantsLocalState={
                    planningParticipantsLocalState
                  }
                  dialogsState={dialogsState}
                  dailyConfig={day.dailyConfig}
                  flexGrow={false}
                  widthMode={widthMode}
                  session={session}
                  planningConfig={planningConfig}
                  display={{
                    ...displayOptions,
                    sessionIndex: {
                      index: iDailySession + 1,
                      total: day.sessions.length,
                    },
                    isExpandable: !session.isVirtual, // not virtual
                  }}
                  // NOTE: il faudrait revoir un peu tout ça en mode `full`, pour que les participants soient tout le temps visible, mais tout en ayant la possibilité d'"ouvrir" les détails, avec :
                  // - le bouton "add" toujours visible
                  // - le commentaire et le champ "open" seulement visible si on ouvre la session
                  expandByDefault={context === 'planning-staff-page'}
                  selectionColor={
                    sessionsDisplayConfigs.find(
                      (x) => x.diveSessionReference === session.reference,
                    )?.style
                  }
                  clickActions={clickActions}
                  emptyParticipantsCount={
                    0
                    // maxParticipantsCountByDaySessionIndex[session.daySessionIndex] -
                    // session.participants.length
                  }
                />
              </>
            </div>
          );
        })}
      </>
    </div>
  );
};
