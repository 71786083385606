/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppInputBooleanSwitchRHF, AppMultiCheckboxesRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { useTaxesOptions } from 'src/pages/_components/options';
import { ClubSettingsGeneralFormModel } from '../../../_model';

export const ClubSettingsGeneralPurchaseEditFormTaxes = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [countryIsoCode, taxesEnabled] = useWatch({
    control,
    name: [
      'clubSettings.general.countryIsoCode',
      'clubSettings.general.taxes.enabled',
    ],
  });

  const taxesOptions = useTaxesOptions({
    countryIsoCode,
    effectiveDate: new Date(),
  });

  return (
    <ClubSettingsSection title="Taxes">
      <AppFormControlRHF_Deprecated
        className="w-full"
        label="Activer les taxes"
        control={control}
        name={'clubSettings.general.taxes.enabled'}
        required={false}
        renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
      />

      {taxesEnabled && taxesOptions.length > 0 && (
        <>
          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Taxes plongée"
            control={control}
            name={'clubSettings.general.taxes.taxesReferencesByProduct.diving'}
            required={false}
            renderComponent={(props) => (
              <AppMultiCheckboxesRHF {...props} options={taxesOptions} />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Taxes boutique"
            control={control}
            name={'clubSettings.general.taxes.taxesReferencesByProduct.shop'}
            required={false}
            renderComponent={(props) => (
              <AppMultiCheckboxesRHF {...props} options={taxesOptions} />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Taxes location"
            control={control}
            name={'clubSettings.general.taxes.taxesReferencesByProduct.rental'}
            required={false}
            renderComponent={(props) => (
              <AppMultiCheckboxesRHF {...props} options={taxesOptions} />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Taxes repas"
            control={control}
            name={'clubSettings.general.taxes.taxesReferencesByProduct.food'}
            required={false}
            renderComponent={(props) => (
              <AppMultiCheckboxesRHF {...props} options={taxesOptions} />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Taxes logement"
            control={control}
            name={
              'clubSettings.general.taxes.taxesReferencesByProduct.accommodation'
            }
            required={false}
            renderComponent={(props) => (
              <AppMultiCheckboxesRHF {...props} options={taxesOptions} />
            )}
          />
        </>
      )}
    </ClubSettingsSection>
  );
};
