import { ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'dayjs/locale/fr';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import 'tippy.js/dist/tippy.css';
import { globalTheme } from './AppTheme';
import { TAILWIND_COLORS_DAY_PERIOD } from './TAILWIND_COLORS_DAY_PERIOD.const';
import { TAILWIND_COLORS_DIVE_MODE } from './TAILWIND_COLORS_DIVE_MODE.const';
import { TAILWIND_COLORS_GENERIC_STATUS } from './TAILWIND_COLORS_GENERIC_STATUS.const';
import { TAILWIND_COLORS_SESSION_DISPLAY_STYLE } from './TAILWIND_COLORS_SESSION_DISPLAY_STYLE.const';
import { AppScrollToTop } from './business/_core/modules/layout/components/AppScrollToTop';
import { ErrorFallback } from './business/_core/modules/layout/components/ErrorFallbackMessage/ErrorFallbackMessage';
import { AppDialogModalProvider } from './business/_core/modules/layout/components/_tailwind/AppDialog';
import { AppRoot } from './business/_core/modules/root/pages';

const params = new URLSearchParams(window.location.search);
if (params.get('d')) {
  // eslint-disable-next-line no-console
  console.log('Remove "d" parameter from URL'); // to avoid loading a past date on planning page
  params.delete('d');
  window.history.replaceState(
    {},
    '',
    decodeURIComponent(`${window.location.pathname}?${params}`),
  );
}

// react query
const queryClient = new QueryClient();

function App() {
  return appWebConfig.debug.showErrorStackTrace ? (
    <>{renderApp()}</>
  ) : (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {renderApp()}
    </ErrorBoundary>
  );
}

function renderApp() {
  const theme = globalTheme;

  return (
    <div id="app-full-screen-container">
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider theme={theme}>
            {/*      tailwind dynamically used classes */}
            <i className={`hidden ${TAILWIND_COLORS_DIVE_MODE}`}></i>
            <i className={`hidden ${TAILWIND_COLORS_GENERIC_STATUS}`}></i>
            <i
              className={`hidden ${TAILWIND_COLORS_SESSION_DISPLAY_STYLE}`}
            ></i>
            <i className={`hidden ${TAILWIND_COLORS_DAY_PERIOD}`}></i>
            <BrowserRouter>
              <AppDialogModalProvider>
                <AppScrollToTop />
                <AppRoot />
              </AppDialogModalProvider>
            </BrowserRouter>
          </ThemeProvider>
        </DndProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
