/* eslint-disable @typescript-eslint/no-unused-vars */
import { dataSorter } from '@mabadive/app-common-services';
import React from 'react';
import { NEVER } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppPageContainer,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { uiStore } from 'src/business/_core/store';
import {
  authenticationClient,
  authenticationStore,
} from 'src/business/auth/services';
import { AppInputBooleanSwitch } from 'src/lib/form';
import useRouter from 'use-react-router';
import { AdminPageTabsPanel } from '../AdminPagesTabs';
import { ClubResumeAdminCard } from './components';
import {
  AdminCompaniesListPageLocalState,
  useAdminCompaniesListPageLocalState,
} from './useAdminCompaniesListPageLocalState';

export const AdminCompaniesListPage = (props: {}) => {
  const { match } = useRouter();

  const redirectTo = useRedirect();

  const autoFocus = useAutoFocus();

  const localState: AdminCompaniesListPageLocalState =
    useAdminCompaniesListPageLocalState();

  const { filters, setFilters, clubResumeAdmins, loadableContent } = localState;

  return (
    <AppPageContainer
      className={'bg-gray-50'}
      // lastActionStatus={loadableContent.lastActionStatus}
      // contentState={loadableContent.contentState}
      {...loadableContent}
    >
      <AdminPageTabsPanel currentTabId="companies" />
      <AppBreadcrumbBar
        color={'#333'}
        items={[
          {
            icon: AppHeroIcons.admin,
            label: 'Admin',
            url: '/admin',
          },
          {
            // icon: AppHeroIcons.home,
            label: 'Comptes',
          },
        ]}
      />

      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <div className="app-card p-4 flex gap-2 flex-wrap">
          <input
            autoFocus={autoFocus}
            autoComplete="new-password"
            placeholder="Recherche par nom, référence, localisation..."
            type="text"
            className="flex-grow w-max hover:border-app-blue rounded px-2"
            defaultValue={filters.searchText}
            onChange={(e) => {
              setFilters({
                ...filters,
                searchText: e.target.value,
              });
            }}
          />
          <div className="flex gap-2 flex-wrap">
            <AppInputBooleanSwitch
              label={'actifs'}
              value={filters.showActiveClubs}
              onChange={(showActiveClubs) =>
                setFilters({
                  ...filters,
                  showActiveClubs,
                })
              }
            />
            <AppInputBooleanSwitch
              label={'essai'}
              value={filters.showTrialClubs}
              onChange={(showTrialClubs) =>
                setFilters({
                  ...filters,
                  showTrialClubs,
                })
              }
            />
            <AppInputBooleanSwitch
              label={'nouveaux'}
              value={filters.showDraftClubs}
              onChange={(showDraftClubs) =>
                setFilters({
                  ...filters,
                  showDraftClubs,
                })
              }
            />
            <AppInputBooleanSwitch
              label={'archivés'}
              value={filters.showDisabledClubs}
              onChange={(showDisabledClubs) =>
                setFilters({
                  ...filters,
                  showDisabledClubs,
                })
              }
            />
            <AppInputBooleanSwitch
              label={'détails'}
              value={filters.showDetails}
              onChange={(showDetails) =>
                setFilters({
                  ...filters,
                  showDetails,
                })
              }
            />
          </div>
        </div>
        <ul className="my-4 grid grid-cols-1 gap-2">
          {clubResumeAdmins.map((clubResumeAdmin) => (
            <ClubResumeAdminCard
              localState={localState}
              key={clubResumeAdmin._id}
              as="li"
              className="col-span-1"
              clubResumeAdmin={clubResumeAdmin}
              onClick={() => {
                const clubReference = clubResumeAdmin.reference;
                const diveCenters = dataSorter.sortMultiple(
                  clubResumeAdmin.diveCenters,
                  {
                    getSortAttributes: (item) => [
                      {
                        value: item._createdAt,
                      },
                      {
                        value: item._updatedAt,
                      },
                      {
                        value: item._id,
                      },
                    ],
                  },
                );
                const diveCenterId = diveCenters[0]?._id;
                impersonateClubProfile({ clubReference, diveCenterId });
              }}
            />
          ))}
        </ul>
      </AppPageContentContainer>
    </AppPageContainer>
  );

  function impersonateClubProfile({
    clubReference,
    diveCenterId,
  }: {
    clubReference: string;
    diveCenterId: string;
  }) {
    authenticationClient
      .impersonateClubProfile({
        clubReference,
        diveCenterId,
      })
      .pipe(
        first(),
        switchMap(() => {
          uiStore.snackbarMessage.set({
            type: 'success',
            content: 'Connexion réussie!',
          });
          return authenticationStore.auth.get().pipe(
            first(),
            map((appAuth) =>
              appRouteBuilder.getDefaultRouteFromAuth({ appAuth }),
            ),
            tap((defaultRoute) => {
              redirectTo(defaultRoute);
            }),
          );
        }),
        catchError(() => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content: 'Identifiant ou mot de passe invalide.',
          });
          return NEVER;
        }),
      )
      .subscribe();
  }
};
