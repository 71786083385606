/* eslint-disable @typescript-eslint/no-unused-vars */
import { CustomerSpaceDiverLink } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { DiveCenterBookingMemberNameLabel } from '../../../../../_from-diver-app';
import { BookingCustomerConfigEditDialogInputState } from '../../_model';
import { appClipboardCopier } from '../../_services';

export const BookingCustomerConfigCustomerSpaceDiverLink = ({
  inputState,
  persistChanges,
  customerUpdateRequested,
  diverLink,
  className,
}: {
  inputState: BookingCustomerConfigEditDialogInputState;
  diverLink: CustomerSpaceDiverLink;
  persistChanges: () => Promise<void>;
  customerUpdateRequested: boolean;
  className?: string;
}) => {
  const bookingMemberFull = useMemo(
    () =>
      inputState.bookingMembersFull.find(
        (member) => member.bookingMember.diverId === diverLink.diverId,
      ),
    [diverLink.diverId, inputState.bookingMembersFull],
  );

  return diverLink?.url ? (
    <div className={className}>
      <div className={'w-full'}>
        <DiveCenterBookingMemberNameLabel
          bookingMemberFull={bookingMemberFull}
          isUniqueMember={inputState.bookingMembersFull.length === 1}
          className={'sm:min-w-[15rem]'}
        />
        <div
          className={
            'my-2 flex gap-2 items-end justify-between sm:justify-start flex-wrap'
          }
        >
          <div className="">
            <label
              className="relative text-sm font-medium text-gray-600"
              // style={{ top: '-16px', bottom: '-16px' }}
            >
              Lien de connexion à communiquer au client
            </label>
            <div className="border border-gray-200 bg-gray-50 p-1 whitespace-nowrap overflow-x-auto">
              <a
                className="w-full text-app-xxs sm:text-xs text-app-blue underline"
                href={diverLink.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {diverLink.url}
              </a>
            </div>
          </div>
          <div className="flex gap-2">
            {navigator?.clipboard && (
              <AppButton
                fullWidth={false}
                color="primary-outline-light"
                icon={AppHeroIcons.copyClipboard}
                onClick={async () => {
                  appClipboardCopier.copyToClipboard(diverLink?.url);
                  await persistChanges();
                }}
              >
                <span className="hidden sm:inline">copier</span>
              </AppButton>
            )}
            <AppButton
              fullWidth={false}
              color="primary-outline-light"
              icon={AppHeroIcons.linkExternal}
              href={diverLink.url}
              target="mabadive_diver_app"
              onClick={async () => {
                await persistChanges();
              }}
            >
              <span className="hidden sm:inline">ouvrir</span>
            </AppButton>
          </div>
        </div>
        {/* <div className="mt-4 mb-8 flex flex-wrap items-end gap-2">
          {emailLink && (
            <AppButton
              fullWidth={true}
              className="xs:max-w-fit"
              color="mail-outline"
              icon={AppHeroIcons.mail}
              href={emailLink}
              target="mabadive_mail_client"
            >
              Envoyer via mon client de messagerie
            </AppButton>
          )}
          {smsLink && (
            <AppButton
              fullWidth={true}
              className="xs:max-w-fit"
              color="phone-outline"
              icon={AppHeroIcons.sms}
              href={smsLink}
              target="_blank"
            >
              Envoyer par sms via mon téléphone
            </AppButton>
          )}
          {whatzappLink && (
            <AppButton
              fullWidth={true}
              className="xs:max-w-fit"
              color="whatsapp-outline"
              icon={() => <IoLogoWhatsapp />}
              href={whatzappLink}
              target="_blank"
            >
              Envoyer via WhatsApp
            </AppButton>
          )}
        </div> */}
      </div>
    </div>
  ) : null;
};
