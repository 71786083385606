import TTLCache from '@isaacs/ttlcache';
import { BaseEntity } from '@mabadive/app-common-model';
import { AppCacheStore } from '../AppCacheStore.type';
import { AppInnerCacheState } from '../AppFetchDataCacheStore';
import { AppCacheTTLOptions } from './AppCacheTTLOptions.type';

export function AppCacheTTLStore<T>(
  options: AppCacheTTLOptions,
): AppCacheStore<T, TTLCache<any, any>> {
  // https://www.npmjs.com/package/@isaacs/ttlcache
  const lruOptions: TTLCache.Options<any, any> = {
    max: options.maxItems,
    // how long to live in ms
    ttl: options.ttl, // 1000 * 60 * 5,
    checkAgeOnGet: false, // si false, des objets périmés peuvent être retournés
  };
  const lruCache = new TTLCache(lruOptions);

  const cache: AppCacheStore<T, TTLCache<any, any>> = {
    get: async (key: string): Promise<T> => {
      return lruCache.get(key);
    },
    set: async (key: string, value: T): Promise<void> => {
      lruCache.set(key, value);
    },

    remove: async (key: string): Promise<void> => {
      lruCache.delete(key);
    },

    clear: async (): Promise<void> => {
      lruCache.clear();
    },

    __native: lruCache,
  };

  return cache;
}

export const appCacheTTLStoreEntitiesBuilder = {
  clearResultsIds,
};
async function clearResultsIds<I extends BaseEntity>(
  lruCache: TTLCache<any, any>,
  ids: string[],
) {
  if (ids?.length) {
    const entries = lruCache.entries();
    for (const [key, value] of entries) {
      const cacheState = value as AppInnerCacheState<I[]>;
      const items: I[] = cacheState?.fetchResult?.data;
      if (items?.length) {
        const updatedItems: I[] = items.filter(
          (item) => !ids.includes(item._id),
        );
        if (items.length !== updatedItems.length) {
          // console.log(
          //   `xxx update cache: ${items.length}  !== ${updatedItems.length}`,
          // );
          await lruCache.set(key, {
            ...cacheState,
            fetchResult: {
              ...cacheState.fetchResult,
              data: updatedItems,
            },
          });
        }
      }
    }
  }
}
