/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import React, { FC } from 'react';
import { ClubEventDialogLocalState } from '../../useClubEventDialogLocalState.hook';
import { ClubEventEditFormRHF } from './ClubEventEditFormRHF';

export const ClubEventDialogTab1Edit: FC<{
  localState: ClubEventDialogLocalState;
}> = ({ localState }) => {
  const {
    form,
    aggregatedData,
    clubReference,
    isPersistInProgress,
    setIsPersistInProgress,
    mode,
    isOpen,
    defaultValue,
    inputState,
  } = localState;

  return (
    <div className="px-2">
      <h2 className="text-center text-sm sm:text-lg leading-6 p-2 font-medium text-app-blue uppercase">
        {mode === 'create'
          ? 'Nouvelle note'
          : mode === 'edit'
          ? 'Modifier la note'
          : `Dupliquer la note du ${dateService.formatUTC(
              inputState.originalEvent.beginDate,
              'dddd DD/MM/YYYY',
            )}`}
      </h2>
      <div>
        {isOpen && (
          <>
            <ClubEventEditFormRHF form={form} mode={mode} />
          </>
        )}
      </div>
    </div>
  );
};
