import clsx from 'clsx';
import React, { useMemo } from 'react';

import {
  DIVE_TRAININGS_REFERENCE_MAP,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import {
  DiveModeIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DashboardReportPageTrainingParticipantsComparisonState } from './DashboardReportPageTrainingParticipants';
import { DashboardReportPageTrainingParticipantsTableRow } from './DashboardReportPageTrainingParticipantsTableRow';

export const DashboardReportPageTrainingParticipantsTable = ({
  trainingParticipantsOverPeriod: results,
  comparisonDetails,
  className,
}: {
  trainingParticipantsOverPeriod: ProStatsFetchResults['trainingParticipantsOverPeriod'];
  comparisonDetails: DashboardReportComparisonDetails<DashboardReportPageTrainingParticipantsComparisonState>;
  className?: string;
}) => {
  const data = results?.data;

  const {
    localState,
    comparisonFetchResult,
    showPeriodRangeComparison,
    // sharedState: [sharedState, setSharedState],
  } = comparisonDetails;

  const comparisonData =
    comparisonFetchResult?.trainingParticipantsOverPeriod?.data;

  const trainingsDetails: ProStatsFetchResults['trainingParticipantsOverPeriod']['data']['byTrainingReference'] =
    useMemo(() => {
      const baseTrainings = data?.byTrainingReference ?? [];
      const comparisonTrainings = comparisonData?.byTrainingReference ?? [];

      const missingTrainingReferences = comparisonTrainings
        .map((itemB) => itemB.trainingReference)
        .filter(
          (refB) =>
            !baseTrainings.some((itemA) => itemA.trainingReference === refB),
        );

      const updatedTrainings: ProStatsFetchResults['trainingParticipantsOverPeriod']['data']['byTrainingReference'] =
        [
          ...baseTrainings,
          ...missingTrainingReferences.map((missingRef) => {
            const x = {
              trainingReference: missingRef,
              diversCount: 0,
              participantsCount: 0,
              participantsCountPurchased: 0,
              priceAvg: 0,
            };
            return x;
          }),
        ];

      return dataSorter.sortMultiple(updatedTrainings, {
        getSortAttributes: (training) => {
          // const trainingIndex = DIVE_TRAININGS.map(x=>x.reference).indexOf(training.trainingReference)
          return [
            {
              value:
                DIVE_TRAININGS_REFERENCE_MAP[training.trainingReference]
                  ?.sortReference,
            },
            {
              value: training.trainingReference,
            },
          ];
        },
      });
    }, [comparisonData?.byTrainingReference, data?.byTrainingReference]);

  const mainCurrency = useAppCurrencyMain();

  return !trainingsDetails?.length ? null : (
    <table
      className={clsx('app-table text-gray-600 overflow-hidden', className)}
    >
      <thead>
        <tr className="uppercase overflow-hidden">
          <th className="text-left text-base px-1 leading-4">Formation</th>
          <th className="text-left text-base px-1 leading-4">Pers</th>
          <th className="text-left text-base px-1 leading-4">
            Plongées imputées
          </th>
          <th
            className={clsx(
              'text-left text-base px-1 leading-4',
              showPeriodRangeComparison
                ? 'hidden xl:table-cell'
                : 'hidden lg:table-cell',
            )}
          >
            Plong Moy
          </th>{' '}
          <th className={clsx('text-left text-base px-1')}>Prix Moy</th>
        </tr>
      </thead>
      <tbody>
        {trainingsDetails.map((training) => (
          <DashboardReportPageTrainingParticipantsTableRow
            key={training.trainingReference}
            training={training}
            comparisonDetails={comparisonDetails}
          />
        ))}
      </tbody>
      <tbody>
        <tr className="text-left overflow-hidden">
          <th
            className={clsx(
              'text-base bg-gray-500 text-white text-left whitespace-nowrap px-1',
            )}
          >
            TOTAL
          </th>
          <td
            className={clsx(
              'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
              data.diversCount === 0 ? 'opacity-30' : '',
            )}
          >
            {data.diversCount}{' '}
            <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
          </td>
          <td
            className={clsx(
              'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
              data.participantsCount === 0 ? 'opacity-30' : '',
              showPeriodRangeComparison
                ? 'hidden xl:table-cell'
                : 'hidden lg:table-cell',
            )}
          >
            <span
              className={clsx(
                data.participantsCountPurchased === data.participantsCount
                  ? 'font-bold'
                  : 'font-normal',
              )}
            >
              {data.participantsCountPurchased}
            </span>{' '}
            / {data.participantsCount}{' '}
            <DiveModeIcons.training className={clsx('inline w-6 h-6')} />
          </td>
          <td className="bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold">
            {data.diversCount > 0 && (
              <>
                {Math.round((10 * data.participantsCount) / data.diversCount) /
                  10}
              </>
            )}
          </td>
          <td className="bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold">
            <AppPriceLabel
              amount={data.priceAvg}
              mainCurrency={mainCurrency}
              signColorClassName="text-white"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
