import {
  Club,
  ClubParticipant,
  DIVE_CERTIFICATIONS_REFERENCE_MAP,
  DIVE_TRAININGS,
  DIVE_TRAININGS_REFERENCE_MAP,
  DiveCertification,
  DiveSessionResumeParticipant,
  DiveTraining,
  FIRST_DIVES,
  FIRST_DIVES_REFERENCE_MAP,
  OrgDataFirstDiveExt,
  ParticipantExt,
} from '@mabadive/app-common-model';
import { dataSorter } from '../../data';

export const clubParticipantExtSorter = {
  sortSessionParticipants,
  sortSessionParticipantsDeprecated,
};

function sortSessionParticipants(
  participants: DiveSessionResumeParticipant[],
  {
    asc,
  }: {
    asc?: boolean;
  },
): DiveSessionResumeParticipant[] {
  return dataSorter.sortMultiple(participants, {
    getSortAttributes: (x: DiveSessionResumeParticipant) => {
      return [
        {
          value: getSortByDiveModeAttribute(x),
        },
        {
          value: getSortByMaxDepth(x),
        },
        {
          value: x.diver ? x.diver.lastName : undefined,
          type: 'full-text',
        },
        {
          value: x.diver ? x.diver.firstName : undefined,
          type: 'full-text',
        },
      ];
    },
    asc,
  });
}

function sortSessionParticipantsDeprecated(
  club: Club,
  participants: ParticipantExt[],
  {
    asc,
  }: {
    asc?: boolean;
  },
): ParticipantExt[] {
  return dataSorter.sortMultiple(participants, {
    getSortAttributes: (x: ParticipantExt) => {
      return [
        {
          value: getSortByDiveModeAttribute(x.participant),
        },
        {
          value: getSortByMaxDepth(x.participant),
        },
        {
          value: x.diver ? x.diver.lastName : undefined,
          type: 'full-text',
        },
        {
          value: x.diver ? x.diver.firstName : undefined,
          type: 'full-text',
        },
      ];
    },
    asc,
  });
}

function getSortByDiveModeAttribute(x: Pick<ClubParticipant, 'diveMode'>): any {
  switch (x.diveMode) {
    case 'unknown':
      return 0;
    case 'observer':
      return 1;
    case 'snorkeling':
      return 2;
    case 'snorkelingSupervised':
      return 3;
    case 'watchingTour':
      return 4;
    case 'first-dive':
      return 5;
    case 'training':
      return 6;
    case 'supervised':
      return 7;
    case 'autonomous':
      return 8;
    case 'free-dive':
      return 9;
    case 'theoretical-training':
      return 10;
    case 'instructor':
      return 11;
    case 'autoSupervised':
      return 12;
  }
}

function getSortByMaxDepth(
  x: Pick<
    ClubParticipant,
    | 'diveMode'
    | 'trainingReference'
    | 'certificationReference'
    | 'firstDiveReference'
  >,
): any {
  if (x.diveMode === 'training') {
    if (!x.trainingReference) {
      return undefined;
    }

    const training: DiveTraining =
      DIVE_TRAININGS_REFERENCE_MAP[x.trainingReference];

    if (!training) {
      console.error('training not found:', x.trainingReference, DIVE_TRAININGS);
    }

    return training?.sortReference;
  }
  if (x.diveMode === 'first-dive') {
    if (!x.firstDiveReference) {
      return undefined;
    }

    const firstDive: OrgDataFirstDiveExt =
      FIRST_DIVES_REFERENCE_MAP[x.firstDiveReference];

    if (!firstDive) {
      console.error('firstDive not found:', x.firstDiveReference, FIRST_DIVES);
    }

    return firstDive?.sortReference;
  }
  if (!x.certificationReference) {
    return undefined;
  }

  const certification: DiveCertification =
    DIVE_CERTIFICATIONS_REFERENCE_MAP[x.certificationReference];

  if (!certification) {
    console.error('certification not found:', x.certificationReference);
    return undefined;
  }

  return certification?.sortReference;
}
