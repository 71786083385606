import { ProStatsFetchResults } from '@mabadive/app-common-model';
import { paymentMethodFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { apiDownloader } from 'src/_common-browser';
import { AppButton } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import {
  DashboardMetricTitleWithValue,
  DashboardMetricValueCard,
} from 'src/pages/DA-dashboard/_core';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { useDashboardPaymentsByMethod } from './useDashboardPaymentsByMethod.hook';

export const DashboardReportPageGlobalActivityPayments = ({
  paymentsOverPeriod: results,
  title,
  className,
}: {
  paymentsOverPeriod: ProStatsFetchResults['paymentsOverPeriod'];
  title: string;
  className?: string;
}) => {
  const paymentsOverPeriod = results?.data;
  const mainCurrency = useAppCurrencyMain();
  const signClassName = clsx(
    ' ml-0.5 text-bold',
    mainCurrency.sign.length > 1 ? 'text-xs' : 'text-base',
  );

  const paymentsByMethod = useDashboardPaymentsByMethod({
    paymentsByMethod: paymentsOverPeriod?.paymentsByMethod,
  });

  const az = useAppSecurityUserClubAuthorizations();

  return !paymentsOverPeriod ? null : (
    <>
      <div
        className={clsx(
          'max-w-xxx-disabled',
          'app-card app-p-content',
          className,
        )}
      >
        <DashboardMetricTitleWithValue
          label={title}
          description="Paiements encaissés"
          valueClassName="bg-gray-600 text-white"
          icon={EntitiesIcons.payment}
          value={
            <AppPriceLabel
              signClassName={signClassName}
              addSpacesToLargeNumbers={true}
              amount={paymentsOverPeriod.paymentsValidatedTotalSum}
              mainCurrency={mainCurrency}
              centsClassName="text-base"
            />
          }
        />
        <div className="mt-5 grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-1">
          <DashboardMetricValueCard
            label={'Paiements validés'}
            value={paymentsOverPeriod.paymentsValidatedTotalSum}
            type="amount"
            unitIcon={AppHeroIcons.check}
          />
          <DashboardMetricValueCard
            label={'Paiements en attente'}
            value={paymentsOverPeriod.paymentsPendingTotalSum}
            type="amount"
            unitIcon={AppHeroIcons.active}
          />
        </div>
        <div className="mt-2 grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-1">
          {paymentsByMethod.map((paymentByMethod) => (
            <DashboardMetricValueCard
              key={paymentByMethod.method}
              label={paymentMethodFormatter.formatPaymentMethod(
                paymentByMethod.method,
                {
                  format: 'long',
                },
              )}
              value={paymentByMethod.amountValidated}
              type="amount"
              unitIcon={EntitiesIcons.payment}
              valueExtraLabelClassName="text-orange-400 text-xs"
              valueExtraLabel={
                paymentByMethod.amountPending > 0 ? (
                  <div className="-mt-1 whitespace-nowrap">
                    +{' '}
                    <AppPriceLabel
                      className={clsx('text-sm')}
                      centsClassName="text-app-xxs"
                      addSpacesToLargeNumbers={true}
                      amount={paymentByMethod.amountPending}
                      mainCurrency={mainCurrency}
                    />
                  </div>
                ) : // `+ ${paymentMethodFormatter.formatPaymentMethod(
                //   paymentByMethod.method,
                //   {
                //     format: 'long',
                //   },
                // )}`
                undefined
              }
            />
          ))}
        </div>
        <div className="flex gap-2 justify-end items-end">
          {az.download?.dailyCash && (
            <AppButton
              color="primary-outline-light"
              className={''}
              icon={AppHeroIcons.actionDownload}
              onClick={() => {
                const beginDateUTC = results.criteria.minDate;
                const endDateUTC = results.criteria.maxDate;
                apiDownloader.downloadDailyResumeSheetCash({
                  beginDateUTC,
                  endDateUTC,
                });
              }}
            >
              Caisse simplifiée
            </AppButton>
          )}
          {(az.download?.dailyCash || az.download?.bookings) && (
            <AppButton
              color="primary-outline-light"
              className={''}
              icon={AppHeroIcons.actionDownload}
              onClick={() => {
                const beginDateUTC = results.criteria.minDate;
                const endDateUTC = results.criteria.maxDate;
                apiDownloader.downloadPaymentsSheet({
                  beginDateUTC,
                  endDateUTC,
                });
              }}
            >
              Ventes
            </AppButton>
          )}
        </div>
      </div>
    </>
  );
};
