/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import {
  AppBreadcrumbBar,
  AppFixedButtonsBar,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { clubSettingsDiveCenterUrlBuilder } from 'src/pages/SE-settings/SE-01_dive-center/clubSettingsDiveCenterUrlBuilder.service';
import { ClubSettingsEquipmentEditPageForm } from './ClubSettingsEquipmentEditPageForm';
import {
  ClubSettingsEquipmentEditPageLocalState,
  useClubSettingsEquipmentEditPageLocalState,
} from './useClubSettingsEquipmentEditPageLocalState.hook';

export const ClubSettingsEquipmentEditPage = () => {
  const localState: ClubSettingsEquipmentEditPageLocalState =
    useClubSettingsEquipmentEditPageLocalState();

  const { data, state, actions } = localState;

  return (
    <AppPageContainerWithFixedBars
      paddingBottom={false}
      className={'bg-gray-50'}
      footerBar={() => (
        <AppFixedButtonsBar hasChanges={data.form.formState.isDirty}>
          <>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionCancel}
              tabIndex={500}
              color="gray-outline-light"
              onClick={() => actions.back()}
            >
              Annuler
            </AppButton>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionSave}
              color="primary-outline-light"
              onClick={() => actions.submitForm()}
            >
              Enregistrer
            </AppButton>
          </>
        </AppFixedButtonsBar>
      )}
    >
      <AppBreadcrumbBar
        color={AppIconsSettings.account.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.account.main,
            label: 'Mon compte',
            url: '/club/settings',
          },
          {
            label: 'Équipements',
            url: clubSettingsDiveCenterUrlBuilder.equipments.list(),
          },
        ]}
      />
      <AppPageContentContainer className="bg-gray-50 app-p-content">
        <div className="app-card p-4 ">
          <div
            className={clsx(
              'py-2 flex items-center gap-4 text-xl font-bold uppercase',
              'text-gray-600',
            )}
          >
            {Icon && <Icon className="h-6 w-6" />}
            {data.equipmentTypeLabel}
          </div>
          <ClubSettingsEquipmentEditPageForm
            className="my-2"
            localState={localState}
          />
        </div>
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
