/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppPageContainer,
  AppPageContentContainer,
  AppTabsTailwind,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubDialogsProvider } from 'src/pages/_dialogs';

import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import useRouter from 'use-react-router';
import {
  ClubSettingsGeneralMainViewPanel,
  ClubSettingsGeneralPaymentsViewPanel,
  ClubSettingsGeneralPurchaseViewPanel,
  ClubSettingsGeneralSessionsViewPanel,
} from './tabs';
import {
  ClubSettingsGeneralPageLocalState,
  useClubSettingsGeneralPageLocalState,
} from './useClubSettingsGeneralPageLocalState.hook';

export const ClubSettingsGeneralPage = () => {
  const { match } = useRouter();

  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;
  const localState: ClubSettingsGeneralPageLocalState =
    useClubSettingsGeneralPageLocalState();
  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  return (
    <AppPageContainer className={'bg-white'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.other.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.other.main,
            label: 'Configuration',
            url: '/club/settings',
          },
          {
            label: 'Configuration générale',
            url: '/club/settings/other/company-general',
          },
        ]}
      />

      <ClubDialogsProvider dialogsState={localState.dialogs.state}>
        <AppPageContentContainer
          paddingBottom={false}
          className="bg-gray-50 app-p-content"
        >
          <AppTabsTailwind
            className="w-full"
            tabs={state.tabs.data}
            selected={selectedTab}
            theme="underline"
            breakpoint="sm"
            onChange={(tabId) => state.tabs.setSelected(tabId)}
          />
          <div
            className={clsx(
              'mt-4 app-card p-4',
              state.editEnabled && 'app-card-highlight',
            )}
            onClick={() => {
              state.editEnabled &&
                dialogs.clubSettingsGeneral.open(selectedTab);
            }}
          >
            {selectedTab === 'main' ? (
              <ClubSettingsGeneralMainViewPanel />
            ) : selectedTab === 'purchase' ? (
              <ClubSettingsGeneralPurchaseViewPanel />
            ) : selectedTab === 'payments' ? (
              <ClubSettingsGeneralPaymentsViewPanel />
            ) : selectedTab === 'sessions' ? (
              <ClubSettingsGeneralSessionsViewPanel />
            ) : null}

            {state.editMode === 'super-admin-only' ? (
              <div className="my-8 text-gray-500 flex md:flex-col flex-wrap gap-4 justify-between items-start">
                <p>Pour modifier la configuration, veuillez nous contacter:</p>

                <AppButton
                  className=""
                  color="primary-outline-light"
                  icon={AppHeroIcons.contact}
                  onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
                >
                  Contactez-nous
                </AppButton>
              </div>
            ) : state.editEnabled ? (
              <div className="mt-8 mb-2">
                <AppButton
                  color={'primary-outline-light'}
                  size="normal"
                  icon={AppIcons.action?.edit}
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  // }}
                >
                  Modifier
                </AppButton>
              </div>
            ) : null}
          </div>
        </AppPageContentContainer>
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
