import {
  changeDescriptorManager,
  diverBookingCreator,
  diverBookingMemberCreator,
} from '@mabadive/app-common-services';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { BookingGroupAndJourneyEditDialogInputState } from '../../../../CreateBookingPages';
import {
  DiverBookingPageLoadedContentFocus,
  DiverBookingPageUpdateState,
} from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
import { diverBookingPageUpdateStateManager } from '../../02.update-state';

export function useDiverBookingPageCreateBooking({
  clubReference,
  diveCenterId,
  diverId,
  focus,
  updateState,
  setUpdateState,
  setBookingGroupAndJourneyEditDialogState,
}: {
  clubReference: string;
  diveCenterId: string;
  diverId: string;
  focus: DiverBookingPageLoadedContentFocus;
  updateState: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  setBookingGroupAndJourneyEditDialogState: Dispatch<
    SetStateAction<BookingGroupAndJourneyEditDialogInputState>
  >;
}) {
  const diveCenterResume = useDiveCenterResume();

  return useCallback(() => {
    let updateStateLocal = updateState;

    const newBooking = diverBookingCreator.createBooking({
      clubReference,
      diveCenterId,
      bookingContactDiverId: diverId,
      publicSettings: diveCenterResume?.clubResume.clubSettings.publicSettings,
    });
    const bookingsChanges = changeDescriptorManager.createOne(newBooking, {
      changeDescriptors: updateStateLocal.bookingsChanges,
    });
    updateStateLocal = {
      ...updateStateLocal,
      bookingsChanges,
    };
    const newBookingMember =
      diverBookingMemberCreator.createBookingMemberFromDiver({
        diver: focus.clubDiver,
        bookingId: newBooking._id,
        clubReference,
        diveCenterId,
      });
    updateStateLocal =
      diverBookingPageUpdateStateManager.addNewBookingMemberToState({
        updateState: updateStateLocal,
        newBookingMember,
        diver: focus.clubDiver,
      });

    setUpdateState(updateStateLocal, {
      action: 'DiverBookingPageCreateBooking',
    });

    setBookingGroupAndJourneyEditDialogState({
      isOpen: true,
      booking: newBooking,
      bookingMembersFull: [],
    });
  }, [
    clubReference,
    diveCenterId,
    diveCenterResume?.clubResume.clubSettings.publicSettings,
    diverId,
    focus.clubDiver,
    setBookingGroupAndJourneyEditDialogState,
    setUpdateState,
    updateState,
  ]);
}
