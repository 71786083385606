import { ClubResumeDiveSite } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubResumeDiveSiteFormModel } from './model';

export function useDiveSiteFormLocalState({
  mode,
  diveSiteId,
  defaultDiveSiteGroupId,
}: {
  mode: 'create' | 'edit';
  diveSiteId?: string;
  defaultDiveSiteGroupId?: string;
}) {
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const diveSite: ClubResumeDiveSite = useMemo(
    () =>
      mode === 'edit'
        ? diveCenterResume?.diveSites.find((x) => x._id === diveSiteId)
        : undefined,
    [mode, diveCenterResume?.diveSites, diveSiteId],
  );

  const initialFormValue: Partial<ClubResumeDiveSiteFormModel> = useMemo(() => {
    if (mode === 'create') {
      const createInitialValue: Partial<ClubResumeDiveSiteFormModel> = {
        groupId: defaultDiveSiteGroupId,
      };
      return createInitialValue;
    } else if (diveSite) {
      const editInitialValue: Partial<ClubResumeDiveSiteFormModel> = {
        ...diveSite,
      };
      return editInitialValue;
    }
  }, [mode, diveSite, defaultDiveSiteGroupId]);

  const form = useForm<ClubResumeDiveSiteFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  return {
    mode,
    diveSite,
    diveSiteGroups: diveCenterResume?.diveSiteGroups,
    form,
    initialFormValue,
  };
}
