import clsx from 'clsx';
import React, { useMemo } from 'react';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';

import {
  DIVE_TRAININGS_REFERENCE_MAP,
  DashboardPurchasesTrainingsOverPeriod,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { PurchasesTrainingsOverPeriodTotal } from './DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriod';
import { DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriodTableRow } from './DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriodTableRow';

export const DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriodTable =
  ({
    purchasesTrainingsOverPeriod: results,
    total,
    comparisonDetails,
    className,
  }: {
    purchasesTrainingsOverPeriod: ProStatsFetchResults['purchasesTrainingsOverPeriod'];
    total: PurchasesTrainingsOverPeriodTotal;
    comparisonDetails: DashboardReportComparisonDetails<any>;
    className?: string;
  }) => {
    const mainCurrency = useAppCurrencyMain();

    const data = results?.data;

    const {
      localState,
      comparisonFetchResult,
      showPeriodRangeComparison,
      // sharedState: [sharedState, setSharedState],
    } = comparisonDetails;

    const comparisonData =
      comparisonFetchResult?.purchasesTrainingsOverPeriod?.data;

    const trainingsDetails = useMemo(() => {
      const baseTrainings = data ?? [];
      const comparisonTrainings = comparisonData ?? [];

      const missingTrainings = comparisonTrainings.filter(
        (itemB) =>
          !baseTrainings.some(
            (itemA) =>
              itemA.diveTrainingReference === itemB.diveTrainingReference &&
              itemA.offerName === itemB.offerName,
          ),
      );

      const updatedTrainings: DashboardPurchasesTrainingsOverPeriod[] = [
        ...baseTrainings,
        ...missingTrainings.map((missingTraining) => {
          const x: DashboardPurchasesTrainingsOverPeriod = {
            ...missingTraining,
            purchasePackageCount: 0,
            purchasePackagePriceSum: 0,
            purchasePackagePriceAvg: 0,
            purchasePackagePayedAmount: 0,
            purchasePackageTotalPriceThirdPartyCollect: 0,
          };
          return x;
        }),
      ];

      return dataSorter.sortMultiple(updatedTrainings, {
        getSortAttributes: (training) => {
          // const trainingIndex = DIVE_TRAININGS.map(x=>x.reference).indexOf(training.trainingReference)
          return [
            {
              value:
                DIVE_TRAININGS_REFERENCE_MAP[training.diveTrainingReference]
                  ?.sortReference,
            },
            {
              value: training.offerName,
            },
            {
              value: training.diveTrainingReference,
            },
            {
              value: training.purchasePackageCount,
              asc: false,
            },
          ];
        },
      });
    }, [comparisonData, data]);

    return !trainingsDetails?.length ? null : (
      <table className={clsx('app-table text-gray-600', className)}>
        <thead>
          <tr className="uppercase">
            <th className="text-left text-base px-1 leading-3">Formation</th>
            <th className="text-center text-base px-1">Qté</th>
            <th
              className={clsx(
                'text-center text-base px-1 leading-3 truncate',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              Prix moyen
            </th>
            <th className="text-center text-base px-1">
              {/* <span className="hidden lg:inline">Montant </span> */}
              facturé
            </th>
            <th
              className={clsx(
                'text-center text-base px-1',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              {/* <span className="hidden lg:inline">Montant </span> */}
              encaissé
            </th>
          </tr>
        </thead>
        <tbody>
          {trainingsDetails.map((training) => (
            <DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriodTableRow
              key={[training.diveTrainingReference, training.offerName].join(
                '-',
              )}
              training={training}
              comparisonDetails={comparisonDetails}
            />
          ))}
        </tbody>
        <tbody>
          <tr className="text-left">
            <th
              className={clsx(
                'text-base bg-gray-500 text-white text-left whitespace-nowrap px-1',
              )}
            >
              TOTAL
            </th>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackageCount === 0 ? 'opacity-30' : '',
              )}
            >
              {total.purchasePackageCount}{' '}
              <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
            </td>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackagePriceAvg === 0 ? 'opacity-30' : '',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              <AppPriceLabel
                amount={total.purchasePackagePriceAvg}
                mainCurrency={mainCurrency}
                signColorClassName="text-white"
              />
            </td>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackagePriceSum === 0 ? 'opacity-30' : '',
              )}
            >
              <AppPriceLabel
                amount={total.purchasePackagePriceSum}
                mainCurrency={mainCurrency}
                signColorClassName="text-white"
              />
            </td>
            <td
              className={clsx(
                'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
                total.purchasePackagePayedAmount === 0 ? 'opacity-30' : '',
                showPeriodRangeComparison
                  ? 'hidden xl:table-cell'
                  : 'hidden lg:table-cell',
              )}
            >
              <AppPriceLabel
                amount={total.purchasePackagePayedAmount}
                mainCurrency={mainCurrency}
                signColorClassName="text-white"
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
