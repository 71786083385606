import clsx from 'clsx';
import React from 'react';
import { AppBreadcrumbBarItem } from './AppBreadcrumbBarItem';
import { AppBreadcrumbItem } from './model';

export function AppBreadcrumbBar({
  items,
  color,
  className,
  firstItemClassName = 'ml-4',
  children,
}: {
  items: AppBreadcrumbItem[];
  color?: string;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  firstItemClassName?: string;
}) {
  return (
    <nav
      className={clsx('w-full flex items-center leading-3', className)}
      aria-label="Breadcrumb"
      style={{ backgroundColor: color }}
    >
      <ol className="w-full flex items-center space-x-2 md:space-x-4 rounded-md shadow uppercase">
        {items.map((item, i) => (
          <AppBreadcrumbBarItem
            key={i}
            item={item}
            isFirst={i === 0}
            isActive={i === items.length - 1}
            firstItemClassName={firstItemClassName}
          />
        ))}
      </ol>
      {children && children}
    </nav>
  );
}
