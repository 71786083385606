import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppMessage } from '../../../../../../../../business/_core/modules/layout';
import { EcommerceCategoryEditorFormModel } from '../../EcommerceCategoryEditor/model';
import { EcommerceProductArticleEditorFormModel } from '../../EcommerceProductArticleEditor/model';
import { EcommerceProductEditorFormModel } from '../model';
import { EcommerceProductEditorFormContentImageRHF } from './EcommerceProductEditorFormContentImageRHF';

export const EcommerceProductEditorFormContentMainImages = ({
  form,
  className,
}: {
  form: UseFormReturn<
    | EcommerceProductEditorFormModel
    | EcommerceCategoryEditorFormModel
    | EcommerceProductArticleEditorFormModel,
    any,
    undefined
  >;
  className?: string;
}) => {
  const { control, setValue } = form;

  const [bookletPage, contentImage] = useWatch({
    control,
    name: ['appBookletPage', 'appBookletPage.mainContent.content.image'],
  });

  return !bookletPage ? null : (
    <div className={clsx('flex flex-col gap-4 md:gap-6', className)}>
      {/* <h3 className="mt-4 md:mt-8 mx-1 mb-2 text-sm font-bold text-app-primary uppercase">
        Photo de présentation
      </h3> */}
      <AppMessage
        isExpanded={false}
        title="Photos de présentation"
        expandLabel="Instructions"
      >
        <p className="inline-block">
          Suivant le contexte et la taille de l'écran, on affiche soit:
          <ul className="ml-4 list-disc">
            <li className="font-bold">une photo carrée</li>
            <li className="font-bold">une photo horizontale</li>
          </ul>
        </p>
        <p className="mt-2">
          <b>Conseil:</b> choisissez si possible la même photo, mais avec un
          cadrage différent
        </p>
      </AppMessage>
      <div className="grid grid-cols-2 gap-4">
        <EcommerceProductEditorFormContentImageRHF
          label="Photo carrée"
          image="image2"
          height={1000}
          width={1000}
          form={form}
        />
        <EcommerceProductEditorFormContentImageRHF
          label="Photo horizontale"
          image="image"
          height={400}
          width={1000}
          form={form}
        />
      </div>
    </div>
  );
};
