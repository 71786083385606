/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  dateService,
  DiverPurchaseOtherEditorDialogMatchingOfferCriteria,
  DiverPurchasePlanEditorDialogMatchingOfferCriteria,
} from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useDiversOptions } from 'src/pages/_components/options';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BillingTabModel } from './../../models';
import { billingTabModelBuilder } from './../../services/05-view-state';
import { DiverBookingPageActions } from './../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from './../../useDiverBookingPageGlobalState.hook';

export function useDiverBookingPageBillingTabState({
  globalState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const {
    aggregatedData,
    updateState,
    focus,
    billingTabFilterCriteria,
    linkedData,
  } = globalState;

  const {
    openCreatePlanDialog,
    openCreateTrainingDialog,
    openCreatePurchaseOtherDialog,
  } = actions;

  // view model
  const model: BillingTabModel = useMemo(
    () =>
      billingTabModelBuilder.buildBillingTabModel({
        diveCenterResume,
        aggregatedData,
        focus,
        criteria: {
          ...billingTabFilterCriteria,
          currentDiveCenterId: globalState.diveCenterId,
          filteredDiversIds: updateState.filteredDiverId
            ? [updateState.filteredDiverId]
            : linkedData?.diverIdsBookingLoaded,
        },
        linkedData,
      }),
    [
      aggregatedData,
      billingTabFilterCriteria,
      diveCenterResume,
      focus,
      globalState.diveCenterId,
      linkedData,
      updateState.filteredDiverId,
    ],
  );
  const initialSelectedDiverId: string = useMemo(() => {
    if (updateState.filteredDiverId) {
      return updateState.filteredDiverId;
    }
    if (focus.clubDiver?._id) {
      return focus.clubDiver?._id;
    }
    if (aggregatedData.divers.length) {
      return aggregatedData.divers[0]._id;
    }
  }, [
    aggregatedData.divers,
    focus.clubDiver?._id,
    updateState.filteredDiverId,
  ]);

  const [selectedDiverId, setSelectedDiverId] = useState<string>(
    initialSelectedDiverId,
  );

  const diversFilterOptions: ValueLabel<string>[] = useDiversOptions(
    aggregatedData.diversLoaded,
  );
  const createPlan = useCallback(() => {
    // CREATE PLAN
    const diver = aggregatedData.divers.find((d) => d._id === selectedDiverId);
    if (diver) {
      const age = diver?.birthdate
        ? dateService.getAge(diver?.birthdate)
        : diver?.age;
      const createContext: DiverPurchasePlanEditorDialogMatchingOfferCriteria =
        {
          // par défaut, on créé un forfait x10
          divesCount: 10,
          successiveDivesCount: 1,
          supervision: undefined,
          equipment: undefined,
          age,
          isInstructor: false,
          specialDiveType: undefined,
          diveSessionTheme: undefined,
        };
      openCreatePlanDialog({
        diver,
        createContext,
        sessionsBillingResumes: {
          all: [],
          otherTypeOtherDiver: [],
          otherTypeSameDiver: [],
          sameTypeOtherDiver: [],
          sameTypeSameDiver: [],
        },
        aggregatedData,
      });
    }
  }, [aggregatedData, openCreatePlanDialog, selectedDiverId]);

  const createTraining = useCallback(() => {
    // CREATE TRAINING
    const diver = aggregatedData.divers.find((d) => d._id === selectedDiverId);
    if (diver) {
      openCreateTrainingDialog({
        diver,
        createContext: {
          defaultTrainingReference: undefined,
        },
        sessionsBillingResumes: {
          all: [],
          otherTypeOtherDiver: [],
          otherTypeSameDiver: [],
          sameTypeOtherDiver: [],
          sameTypeSameDiver: [],
        },
        aggregatedData,
      });
    }
  }, [aggregatedData, openCreateTrainingDialog, selectedDiverId]);

  const createFirstDive = useCallback(() => {
    // CREATE BPT
    const diver = aggregatedData.divers.find((d) => d._id === selectedDiverId);
    if (diver) {
      const age = diver?.birthdate
        ? dateService.getAge(diver?.birthdate)
        : diver?.age;
      const createContext: DiverPurchaseOtherEditorDialogMatchingOfferCriteria =
        {
          diveMode: 'first-dive',
          divesNumber: 1,
          supervision: 'any-supervision',
          equipment: 'any-equipment',
          age,
          diveSessionTheme: undefined,
          firstDiveTrainingReference: undefined, // il faudrait prendre celui par défaut?
        };
      openCreatePurchaseOtherDialog({
        diver,
        createContext,
        sessionsBillingResumes: {
          all: [],
          otherTypeOtherDiver: [],
          otherTypeSameDiver: [],
          sameTypeOtherDiver: [],
          sameTypeSameDiver: [],
        },
        aggregatedData,
      });
    }
  }, [aggregatedData, openCreatePurchaseOtherDialog, selectedDiverId]);

  return {
    aggregatedData,
    model,
    selectedDiverId,
    setSelectedDiverId,
    diversFilterOptions,
    createPlan,
    createTraining,
    createFirstDive,
  };
}

export type DiverBookingPageBillingTabState = ReturnType<
  typeof useDiverBookingPageBillingTabState
>;
