import { Popover, Transition } from '@headlessui/react';
import { ClubSettingsUIPlanning } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';
import {
  AppButton,
  AppMessage,
  useMediaSizeTailwind,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { clubSettingsOtherUrlBuilder } from 'src/pages/SE-settings/SE-04_other-settings/clubSettingsOtherUrlBuilder.service';
import { PlanningViewConfig } from '../../club-planning/_model';
import {
  SetPlanningConfigOptions,
  useDefaultSettingsUiPlanning,
} from '../hooks';
import { ClubPlanningParticipantsConfigDialogForm } from './ClubPlanningParticipantsConfigDialogForm';
import { ClubPlanningParticipantsConfigDialogMonthViewForm } from './ClubPlanningParticipantsConfigDialogMonthViewForm';

export function ClubPlanningParticipantsConfigDialog({
  label,
  planningConfig,
  setPlanningConfig,
  className,
}: {
  label?: string;
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  className?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null);

  const mediaSize = useMediaSizeTailwind();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'auto',
  });

  const hasRoleClubSettings = useAppSecurityUserHasRole(
    'club-edit-settings-dive-center',
  );
  const settingsPlanningUrl = useMemo(
    () => clubSettingsOtherUrlBuilder.display.view(),
    [],
  );

  const defaultSettingsUiPlanning: ClubSettingsUIPlanning =
    useDefaultSettingsUiPlanning({
      planningConfig,
    });

  return !planningConfig ? null : (
    <Popover as="nav" className={`h-full inline-block ${className}`}>
      {({ open }) => (
        <>
          {/* // TODO use AppButton with "hamburger style" */}
          <button
            ref={setReferenceElement}
            onClick={(e) => {
              setIsOpen(!isOpen);
              e.stopPropagation();
            }}
            className={clsx(
              'sm:h-full py-1 px-2 flex gap-1 items-center justify-center focus:outline-none',
              'border border-gray-300 hover:border-gray-400 rounded',
              'bg-white hover:bg-gray-100 text-gray-800 hover:text-gray-900',
            )}
          >
            {isOpen ? (
              <AppHeroIcons.actionClose
                className="block h-6 w-6"
                aria-hidden="true"
              />
            ) : (
              <AppHeroIcons.actionSettingsDisplay
                className="block h-6 w-6"
                aria-hidden="true"
              />
            )}
            <span className="sr-only">Configuration de l'affichage</span>
            {label && (
              <span className="font-medium text-gray-600">{label}</span>
            )}
          </button>
          {isOpen && (
            <Transition show={isOpen}>
              <Popover.Overlay
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                }}
                style={{
                  zIndex: 1500, // > .makeStyles-drawerPaper-33 (left menu material-ui)
                }}
                className={`${
                  isOpen
                    ? 'opacity-30 fixed top-0 bottom-0 left-0 right-0'
                    : 'opacity-0'
                } bg-black`}
              >
                <div
                  className="fixed top-0 bottom-0 left-0 right-0"
                  onClick={(e) => {
                    setIsOpen(false);
                    e.stopPropagation();
                  }}
                ></div>
              </Popover.Overlay>
              <Popover.Panel
                static
                // tabIndex={-1}
                ref={setPopperElement}
                style={{
                  ...(styles.popper ?? {}),
                  zIndex: 1500, // > .makeStyles-drawerPaper-33 (left menu material-ui)
                  transform:
                    mediaSize === 'xxs' || mediaSize === 'xs'
                      ? null // évite le décallage à droite de 50px si on est sur un petit écran
                      : styles.popper?.transform,
                }}
                {...(attributes.popper ?? {})}
                className={`
                max-w-screen-sm
                ${attributes.popper?.className ?? ''}
                px-1 sm:px-0
              `}
              >
                <AppHeroIcons.actionClose
                  className={
                    'absolute cursor-pointer mr-1 sm:mr-0 p-2 top-0 right-0 z-50 w-8 h-8 sm:w-10 sm:h-10 bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white'
                  }
                  aria-hidden="true"
                  onClick={(e) => {
                    setIsOpen(false);
                    e.stopPropagation();
                  }}
                />
                <div
                  className={
                    'min-w-[100vw] sm:min-w-[500px] text-left border border-gray-400 shadow-lg ring-1 ring-gray-600 ring-opacity-20 overflow-x-hidden overflow-y-auto bg-white'
                  }
                >
                  <div className="p-4">
                    <h2 className="text-left uppercase text-base md:text-lg leading-6 mt-2 mb-3 py-2 font-medium text-gray-800">
                      Configuration de l'affichage
                    </h2>
                    {planningConfig.viewPeriod === 'month' ? (
                      <ClubPlanningParticipantsConfigDialogMonthViewForm
                        context="device-settings"
                        settingsUiPlanning={planningConfig}
                        setSettingsUiPlanning={setPlanningConfig}
                      />
                    ) : (
                      <ClubPlanningParticipantsConfigDialogForm
                        context="device-settings"
                        settingsUiPlanning={planningConfig}
                        setSettingsUiPlanning={setPlanningConfig}
                      />
                    )}
                    <div className="my-4">
                      <AppButton
                        size="normal"
                        icon={AppHeroIcons.check}
                        color="primary-outline-light"
                        onClick={() => {
                          setPlanningConfig(
                            {
                              ...planningConfig,
                              ...defaultSettingsUiPlanning,
                            },
                            {
                              origin:
                                '[ClubPlanningParticipantsConfigDialog] reset to default',
                            },
                          );
                        }}
                      >
                        <span className="font-bold">
                          Réinitialiser les valeurs par défaut
                        </span>
                      </AppButton>
                    </div>
                    <AppMessage
                      className="w-full"
                      type="info"
                      title="Note"
                      message={
                        <>
                          <p className={'my-2'}>
                            Ces paramètres ne concernent que cet appareil.
                          </p>
                          {hasRoleClubSettings && (
                            <p className={'my-2'}>
                              Pour modifier la configuration par défaut de tous
                              les utilisateurs, rendez-vous sur{' '}
                              <Link
                                to={settingsPlanningUrl}
                                className="font-bold text-app-blue underline"
                              >
                                l'espace de configuration du planning
                              </Link>
                              .
                            </p>
                          )}
                        </>
                      }
                    />
                  </div>
                  <div className="my-8 text-center">
                    <AppButton
                      size="normal"
                      icon={AppHeroIcons.check}
                      color="primary-outline-light"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <span className="font-bold">OK</span>
                    </AppButton>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          )}
        </>
      )}
    </Popover>
  );
}
