import { useDashboardCashFetchStats } from './useDashboardCashFetchStats.hook';
import { useDashboardCashPageViewParameters } from './useDashboardCashPageViewParameters';

export function useDashboardCashPageLocalState() {
  const { viewParameters, setViewParameters } =
    useDashboardCashPageViewParameters();

  const { beginDate, endDate } = viewParameters.periodRange.value;

  const { loadingInProgress, fetchResult } = useDashboardCashFetchStats({
    period: { beginDate, endDate },
  });

  return {
    state: {
      viewParameters,
      setViewParameters,
      loadingInProgress,
    },
    data: {
      fetchResult,
    },
  };
}

export type DashboardCashPageLocalState = ReturnType<
  typeof useDashboardCashPageLocalState
>;
