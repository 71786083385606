/* eslint-disable @typescript-eslint/no-unused-vars */
import { RegionalSettings } from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import React from 'react';
import {
  useClubResume,
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { useRegionalSettings } from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsServicesActivitiesViewPanelDiveMode } from './ClubSettingsServicesActivitiesViewPanelDiveMode';

export const ClubSettingsServicesActivitiesViewPanelScuba = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const regionalSettings: RegionalSettings = useRegionalSettings({
    effectiveDate: new Date(),
  });

  const publicSettings = useClubSettings()?.publicSettings;

  return (
    <ClubSettingsSection title={'Plongée scaphandre'}>
      <div className="relative grid gap-2 font-bold text-gray-600">
        <AppFeatureBadge
          enabled={clubSettings.firstDive.enabled}
          label={diveModeFormatter.formatDiveMode('first-dive', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="first-dive" />
          }
        />
        <AppFeatureBadge
          enabled={clubSettings.supervised.enabled}
          label={diveModeFormatter.formatDiveMode('supervised', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="supervised" />
          }
        />
        <AppFeatureBadge
          enabled={clubSettings.autonomous.enabled}
          label={diveModeFormatter.formatDiveMode('autonomous', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="autonomous" />
          }
        />
        <AppFeatureBadge
          enabled={clubSettings.instructor.enabled}
          label={diveModeFormatter.formatDiveMode('instructor', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="instructor" />
          }
        />
        <AppFeatureBadge
          enabled={clubSettings.autoSupervised.enabled}
          label={diveModeFormatter.formatDiveMode('autoSupervised', {
            format: 'short-ref-label',
            diveModesConf: clubSettings?.ui?.diveMode,
          })}
          icon={
            <ClubSettingsServicesActivitiesViewPanelDiveMode diveMode="autoSupervised" />
          }
        />
      </div>
    </ClubSettingsSection>
  );
};
