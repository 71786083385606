/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubEcommerceProductCategoryConfig,
  EcommerceCategoryGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { AppTranslationContentPanel } from '../../../../../../lib/form/components/AppBooklet/view/AppTranslationContentPanel';
import { ClubSettingsOnlineBookingProductsPageLocalState } from '../useClubSettingsOnlineBookingProductsPageLocalState.hook';
import { EcommerceCategoryPreviewContent } from './EcommerceCategoryPreview';
import { ProProductsPage_CategoryContentCardProduct } from './ProProductsPage_CategoryContentCardProduct';

export const ProProductsPage_CategoryContentCard = ({
  categoryId,
  localState,
  className,
}: {
  categoryId: string;
  localState: ClubSettingsOnlineBookingProductsPageLocalState;
  className?: string;
}) => {
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const enableCatalog =
    clubSettings?.publicSettings?.customerSpace?.onlineBooking?.enableCatalog;

  const { state, dialogs, data, actions } = localState;

  const category: EcommerceCategoryGql_Company = useMemo(() => {
    return data.categories.find((x) => x._id === categoryId);
  }, [categoryId, data.categories]);
  const categoryBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === category.bookletId);
  }, [category.bookletId, data.booklets]);

  const categoryBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === categoryBooklet?.rootPageId);
  }, [categoryBooklet?.rootPageId, data.bookletPages]);
  const categoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.categoryId === categoryId,
    );
  }, [categoryId, data.productCategoryConfigs]);

  const products: EcommerceProductGql_Company[] = useMemo(() => {
    return data.products.filter((p) =>
      categoryConfigs.some((cf) => cf.productId === p._id),
    );
  }, [categoryConfigs, data.products]);

  const appBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === category?.bookletId);
  }, [data.booklets, category?.bookletId]);

  const appBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === appBooklet?.rootPageId);
  }, [appBooklet?.rootPageId, data.bookletPages]);

  const nextIndex = useMemo(() => {
    if (products.length > 0) {
      return Math.max(...categoryConfigs.map((p) => p.sortIndex)) + 1;
    }
    return 1;
  }, [categoryConfigs, products.length]);
  const content = appBookletPage?.mainContent?.content;

  const customerSpaceCurrentPageURL = state.customerSpaceCurrentPageURL;

  return (
    <div
      className={clsx(
        'app-card rounded-t-xl app-card-highlight grid gap-4 relative',
        className,
      )}
      // onClick={(e) => {
      //   actions.selectCategory(category?._id);
      // }}
    >
      <div className="absolute z-10 top-2 left-2 flex-grow flex flex-col gap-4">
        <AppButton
          className=""
          color="gray-outline-light"
          size="normal"
          icon={AppHeroIcons.actionEdit}
          onClick={(e) => {
            e.stopPropagation();
            dialogs.categoryEditor.openEditCategory({
              editValue: { category, appBooklet, appBookletPage },
            });
          }}
        >
          Modifier la catégorie
        </AppButton>
      </div>
      <EcommerceCategoryPreviewContent
        displayWidth="screen"
        category={category}
        appBookletPage={appBookletPage}
      >
        <div className="flex flex-col gap-8">
          <div className="flex-grow max-w-md">
            {(content?.items ?? []).map((item, i) => (
              <AppTranslationContentPanel key={i} item={item} />
            ))}
          </div>
          <div className="flex flex-col gap-4 md:gap-6">
            <h3 className="text-app-primary text-lg font-bold leading-4 uppercase">
              Produits
            </h3>

            <div className="flex flex-col gap-4">
              <AppButton
                className="self-start"
                color="primary-outline-light"
                size="normal"
                icon={AppHeroIcons.actionAdd}
                onClick={(e) => {
                  e.stopPropagation();
                  const categoryConfig: Partial<ClubEcommerceProductCategoryConfig> =
                    {
                      categoryId: category._id,
                      sortIndex: nextIndex,
                    };
                  dialogs.productEditor.openCreateProduct({
                    categoryBookletPage,
                    defaultValue: {
                      product: {
                        enabled: true,
                      },
                      categoryConfigs: [categoryConfig],
                    },
                  });
                }}
              >
                Ajouter un produit
              </AppButton>
              {products.map((product) => (
                <ProProductsPage_CategoryContentCardProduct
                  key={product._id}
                  category={category}
                  product={product}
                  localState={localState}
                />
              ))}

              {enableCatalog && (
                <div className="my-8 text-center">
                  <AppButton
                    fullWidth={false}
                    color="danger-bg"
                    icon={AppHeroIcons.linkExternal}
                    href={customerSpaceCurrentPageURL}
                    target="mabadive_diver_app"
                  >
                    <span className="">Visualiser sur l'espace client</span>
                  </AppButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </EcommerceCategoryPreviewContent>
    </div>
  );
};
