import {
  BookingProduct,
  ClubDiver,
  DiveSession,
} from '@mabadive/app-common-model';
import { bookingMemberFullSorter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';
import {
  BillingTabDiveSessionBillingResumeMultiDivers,
  PRO_BookingMemberFull,
  PRO_BookingParticipantFull,
} from '../../models';
import { DiverPurchaseEditorParticipantsConfig } from '../DiverPurchaseCommonEditorDialog/model';
import { DiverBillingSessionsTableDay } from './DiverBillingSessionsTableDay';
import { OnClickAssignProductFn } from './OnClickAssignProductFn.type';

export function DiverBillingSessionsTable({
  participantsConfig,
  setParticipantsConfig,
  sessionsBillingResumesOtherDiversCount,
  sessionsBillingResumesOtherTypeCount,
  totalConsumedCount,
  totalCreditsCount,
  displayAssignButton,
  sessionsBillingResumes,
  onClickSession,
  onClickParticipant,
  displayLastName,
  onClickAssignProducts,
  className,
}: {
  participantsConfig: DiverPurchaseEditorParticipantsConfig;
  setParticipantsConfig: React.Dispatch<
    React.SetStateAction<DiverPurchaseEditorParticipantsConfig>
  >;
  sessionsBillingResumesOtherDiversCount: number;
  sessionsBillingResumesOtherTypeCount: number;
  totalConsumedCount: number;
  totalCreditsCount: number;
  displayLastName?: boolean;
  displayAssignButton: boolean;
  sessionsBillingResumes: BillingTabDiveSessionBillingResumeMultiDivers[];
  onClickSession?: (diveSession: DiveSession) => void;
  onClickParticipant?: (props: {
    bookingParticipantFull: PRO_BookingParticipantFull;
  }) => void;
  onClickAssignProducts: OnClickAssignProductFn;
  className?: string;
}) {
  const badgeStatus: AppGenericStatus = useMemo(() => {
    if (totalConsumedCount === totalCreditsCount) {
      return 'info';
    }
    if (totalConsumedCount > totalCreditsCount) {
      return 'error';
    }
    return 'active';
  }, [totalCreditsCount, totalConsumedCount]);
  const badgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(badgeStatus),
    [badgeStatus],
  );

  const bookingProducts: BookingProduct[] = useMemo(
    () =>
      sessionsBillingResumes.reduce(
        (acc, x) =>
          acc.concat(x.purchaseParticipants.map((p) => p.bookingProduct)),
        [] as BookingProduct[],
      ),
    [sessionsBillingResumes],
  );

  const divers: ClubDiver[] = useMemo(() => {
    const bmList: PRO_BookingMemberFull[] = [];

    for (const sbr of sessionsBillingResumes) {
      for (const p of sbr.purchaseParticipants) {
        const bookingMemberFull = p.participant.bookingMemberFull;

        if (
          bmList.findIndex(
            (x) => x.diver._id === bookingMemberFull.diver._id,
          ) === -1
        ) {
          bmList.push(bookingMemberFull);
        }
      }
    }
    // return bmList.map((x) => x.diver);
    return bookingMemberFullSorter
      .sort(bmList, { bookingContactDiverId: undefined })
      .map((x) => x.diver);
  }, [sessionsBillingResumes]);

  const { assignedBookingProducts, unAssignedBookingProductsNotCancelled } =
    useMemo(() => {
      const assignedBookingProducts: BookingProduct[] = [];
      const unAssignedBookingProductsNotCancelled: BookingProduct[] = [];
      bookingProducts.forEach((bookingProduct) => {
        if (bookingProduct.purchaseStatus === 'purchased') {
          assignedBookingProducts.push(bookingProduct);
        } else if (
          bookingProduct.purchaseStatus !== 'archived' &&
          bookingProduct.bookingProductStatus !== 'cancelled'
        ) {
          unAssignedBookingProductsNotCancelled.push(bookingProduct);
        }
      });
      return {
        assignedBookingProducts,
        unAssignedBookingProductsNotCancelled,
      };
    }, [bookingProducts]);

  return (
    <>
      <div className="mt-2 flex flex-col gap-2 sm:flex-row sm:justify-between md:justify-start">
        <h2 className="text-left text-lg leading-6 p-2 font-medium text-app-primary">
          Plongées associées:
          {totalCreditsCount > 0 && (
            <span className={`ml-2 font-bold ${badgeStatusClass}`}>
              {totalConsumedCount}/{totalCreditsCount}
            </span>
          )}
        </h2>
        {displayAssignButton && (
          <div className="mb-3 sm:mb-1 flex justify-between sm:justify-start flex-wrap sm:gap-5">
            {sessionsBillingResumesOtherTypeCount > 0 && (
              <div
                className="flex items-center uppercase text-xs text-app-primary hover:text-app-primary-dark font-bold cursor-pointer"
                onClick={() => {
                  setParticipantsConfig({
                    ...participantsConfig,
                    includeOtherTypes: !participantsConfig.includeOtherTypes,
                  });
                }}
              >
                <input
                  type="checkbox"
                  className="bg-white mt-0.5 mr-1"
                  checked={participantsConfig.includeOtherTypes}
                  onChange={() => {}}
                />
                autres prestations
              </div>
            )}
            {sessionsBillingResumesOtherDiversCount > 0 && (
              <div
                className="flex items-center uppercase text-xs text-app-primary hover:text-app-primary-dark font-bold cursor-pointer"
                onClick={() => {
                  setParticipantsConfig({
                    ...participantsConfig,
                    includeOtherDivers: !participantsConfig.includeOtherDivers,
                  });
                }}
              >
                <input
                  type="checkbox"
                  className="bg-white mt-0.5 mr-1"
                  checked={participantsConfig.includeOtherDivers}
                  onChange={() => {}}
                />
                autres plongeurs
              </div>
            )}
            {(sessionsBillingResumes.length > 1 ||
              participantsConfig.includeOtherTypes ||
              participantsConfig.includeOtherDivers) && (
              <div
                className="flex items-center uppercase text-xs text-app-primary hover:text-app-primary-dark font-bold cursor-pointer"
                style={{ minWidth: '6rem' }}
                onClick={() => {
                  onClickAssignProducts({
                    bookingProducts:
                      unAssignedBookingProductsNotCancelled.length
                        ? unAssignedBookingProductsNotCancelled
                        : assignedBookingProducts,
                    operation: unAssignedBookingProductsNotCancelled.length
                      ? 'assign'
                      : 'unassign',
                  });
                }}
              >
                <input
                  type="checkbox"
                  className="bg-white mt-0.5 mr-1"
                  checked={unAssignedBookingProductsNotCancelled.length === 0}
                  onChange={() => {}}
                />
                {unAssignedBookingProductsNotCancelled.length
                  ? 'Tout imputer'
                  : 'Ne rien imputer'}
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={`flex flex-row-reverse flex-wrap justify-end items-start justify-items-start max-w-full divide-y divide-gray-100 ${
          className ?? ''
        }`}
      >
        {sessionsBillingResumes.map(
          ({ diveSession, purchaseParticipants }, j) => (
            <DiverBillingSessionsTableDay
              key={j}
              onClickAssignProducts={onClickAssignProducts}
              displayAssignButton={displayAssignButton}
              diveSession={diveSession}
              purchaseParticipants={purchaseParticipants}
              onClickSession={onClickSession}
              onClickParticipant={onClickParticipant}
              displayLastName={displayLastName}
              divers={divers}
            />
          ),
        )}
      </div>
    </>
  );
}
