/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppButtonsBar,
  AppMessageLight,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DateRangeLabel } from 'src/pages/_components';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import useRouter from 'use-react-router';
import { clubSettingsDiveCenterUrlBuilder } from '../../clubSettingsDiveCenterUrlBuilder.service';
import { ClubSettingsPlanningAssistantForm } from './ClubSettingsPlanningAssistantForm';
import {
  ClubSettingsPlanningAssistantLocalState,
  useClubSettingsPlanningAssistantLocalState,
} from './useClubSettingsPlanningAssistantLocalState.hook';

export const ClubSettingsPlanningAssistantPage = () => {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const diveCenterResume = useDiveCenterResume();
  const redirectTo = useRedirect();
  const { match, history } =
    useRouter<{
      periodConfigId: string;
    }>();

  const localState: ClubSettingsPlanningAssistantLocalState =
    useClubSettingsPlanningAssistantLocalState({
      periodConfigId: match.params.periodConfigId,
    });

  const { submitForm, back, form, initialPeriodConfig } = localState;

  const createBoatRessourceHref = useMemo(
    () => clubSettingsDiveCenterUrlBuilder.boats.create(),
    [],
  );

  return (
    <AppPageContainerWithFixedBars
      paddingBottom={false}
      className={'bg-gray-50'}
      footerBar={() => (
        <AppButtonsBar hasChanges={true}>
          <AppButton
            fullWidth={true}
            icon={AppHeroIcons.actionCancel}
            color="gray-outline-light"
            size="normal"
            onClick={() => {
              back();
            }}
          >
            Annuler
          </AppButton>
          <AppButton
            fullWidth={true}
            icon={AppHeroIcons.actionSave}
            color="primary-outline-light"
            onClick={() => {
              submitForm();
            }}
          >
            Confirmer
          </AppButton>
        </AppButtonsBar>
      )}
    >
      <AppBreadcrumbBar
        color={AppIconsSettings.diveCenter.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.diveCenter.main,
            label: 'Centre de plongée',
            url: '/club/settings',
          },
          {
            label: 'Planning',
          },
        ]}
      >
        {isMultiDiveCenters && (
          <div className="app-px-content">
            <DiveCenterProBadge diveCenter={diveCenterResume} />
          </div>
        )}
      </AppBreadcrumbBar>
      <AppPageContentContainer
        paddingBottom={false}
        className="bg-gray-50 app-px-content"
      >
        <div className={clsx('')}>
          <h2 className="my-2 flex-grow text-left uppercase text-base md:text-lg leading-6 mt-4 font-normal text-gray-600">
            <div className="font-bold text-app-primary-light">
              {initialPeriodConfig?.name}{' '}
            </div>
            <DateRangeLabel
              className="text-sm"
              beginDate={initialPeriodConfig?.beginDate}
              endDate={initialPeriodConfig?.endDate}
            />
          </h2>
          <h3 className="my-2 flex-grow text-left uppercase text-base md:text-lg leading-6 mt-4 font-medium text-gray-800">
            Ajout rapide de sessions
          </h3>
          <AppMessageLight className="my-4">
            <div>
              Cet assistant permet de générer rapidement le planning de la
              semaine.
            </div>
            <div>
              Pour créer des sessions, indiquer l'heure des sorties pour chaque
              ressource (sinon laisser le champ vide).
            </div>
            <div className="my-2">
              <AppButton
                className="hidden md:flex mr-2 font-bold"
                style={{ minWidth: '6rem' }}
                size="normal"
                color={'primary-outline-light'}
                icon={AppHeroIcons.actionAdd}
                type="button"
                href={createBoatRessourceHref}
              >
                Créer une ressource
              </AppButton>
            </div>
          </AppMessageLight>
          <ClubSettingsPlanningAssistantForm
            className="app-my-content"
            form={form}
          />
        </div>
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
