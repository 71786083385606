import {
  Club,
  ClubSettingsUIPlanning,
  DiveCenterPrivateSettingsEquipment,
  DiveSession,
  DiveSessionResumeFullBookingSessionParticipantBooking,
  MultipleDiveSessionNumber,
} from '@mabadive/app-common-model';
import { equipmentVisibilityBuilder } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  useClubResume,
  useDiveCenterResume,
  useDiveModeColor,
} from 'src/business/club/data/hooks';
import { ParticipantCommentCard } from 'src/business/club/modules/_common/form';
import { BookingGroupSizeLabel } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { CalendarParticipantCellAptitudeGaz } from './CalendarParticipantCellAptitudeGaz';
import { CalendarParticipantCellDiveModeBadge } from './CalendarParticipantCellDiveModeBadge';
import { CalendarParticipantCellEquipment } from './CalendarParticipantCellEquipment';
import { CalendarParticipantCellName } from './CalendarParticipantCellName';
import { CalendarSessionWidthMode } from './CalendarSessionWidthMode.type';
import {
  CalendarParticipantCellDiver,
  CalendarParticipantCellParticipant,
} from './model';

const AGE_RECENT_1_DURATION_S = 2;

export function CalendarParticipantCell<
  T extends CalendarParticipantCellParticipant,
>({
  booking,
  multipleSessionsGroup,
  multipleSessionNumber,
  settingsUIPlanning,
  club,
  diver,
  participant,
  diveSession,
  isPast,
  onClick,
  anchorLink,
  includePayment,
  includeComment,
  widthMode,
  className,
}: {
  booking?: DiveSessionResumeFullBookingSessionParticipantBooking;
  multipleSessionsGroup: boolean;
  multipleSessionNumber?: MultipleDiveSessionNumber; //  if multiplemultipleSessionsGrouppedSessionsTogether === false
  settingsUIPlanning: ClubSettingsUIPlanning;
  club: Pick<Club, 'participantTags' | 'reference'>;
  diver: CalendarParticipantCellDiver;
  participant?: T;
  diveSession: Pick<DiveSession, 'time' | 'status'>;
  isPast?: boolean;
  hoverable?: boolean;
  onClick: (participant: T) => void;
  anchorLink: (participant: T) => string;
  widthMode: CalendarSessionWidthMode;
  includePayment: boolean;
  includeComment: boolean;
  className?: string;
}) {
  if (isPast === undefined) {
    isPast = false;
  }
  const diveCenterResume = useDiveCenterResume();

  const equipmentsSettings: DiveCenterPrivateSettingsEquipment =
    diveCenterResume?.privateSettings?.equipment;

  const hoverable = !!onClick;

  const clubReference = club.reference;

  const lastChange = useMemo(() => {
    const lastChange = Math.max(
      ...[
        participant?._updatedAt?.getTime(),
        diver?._updatedAt?.getTime(),
      ].filter((x) => !!x),
    );
    if (lastChange) {
      return new Date(lastChange);
    }
  }, [diver?._updatedAt, participant?._updatedAt]);

  const recentUpdate = useMemo(() => isRecentUpdate(lastChange), [lastChange]);

  const diveSessionStatus =
    participant && participant.bookingState.value === 'cancelled'
      ? 'cancelled'
      : diveSession.status;

  const isSessionOpen = diveSessionStatus === 'on';

  const diveModeColor = useDiveModeColor(participant.diveMode);

  const href = useMemo(() => {
    if (anchorLink) {
      return anchorLink(participant);
    }
  }, [anchorLink, participant]);

  const clubResume = useClubResume();

  const customerSettings = clubResume.clubSettings?.customer;

  const includeEquipment =
    equipmentsSettings?.provider ||
    equipmentVisibilityBuilder.isAnyEquipmentVisibleOnSession({
      equipmentsSettings,
      equipment: participant?.equipment,
    });

  return (
    <a
      href={href}
      className={clsx(
        'w-full group flex leading-3 text-xs overflow-hidden',
        recentUpdate && 'animate-flash-red-fast',
        (widthMode === 'tiny' || widthMode === 'small') && 'flex-col',
        widthMode === 'tiny' || widthMode === 'small'
          ? ''
          : widthMode === 'large'
          ? ''
          : 'h-[28px]',
        isPast && 'opacity-[75%]',
        widthMode === 'tiny' || widthMode === 'small'
          ? 'border-b border-l border-r'
          : 'border-b border-t border-r ',
        hoverable &&
          'hover:bg-gray-50 hover:border-app-orange3 hover:text-gray-800',
        className,
      )}
      style={{ borderColor: diveModeColor }}
      onClick={
        !onClick
          ? undefined
          : (e) => {
              e.preventDefault();
              onClick(participant);
            }
      }
    >
      <CalendarParticipantCellDiveModeBadge
        settingsUIPlanning={settingsUIPlanning}
        club={club}
        participant={participant}
        diveSession={diveSession}
        isPast={isPast}
        hoverable={hoverable}
        widthMode={widthMode}
        recentUpdate={recentUpdate}
        multipleSessionsGroup={multipleSessionsGroup}
        multipleSessionNumber={multipleSessionNumber}
      />
      <div
        className={clsx(
          'overflow-hidden flex-grow justify-between mx-0.5 flex gap-x-0.5 md:gap-x-2 lg:gap-x-2 ',
          widthMode === 'large' && 'py-0.5',
        )}
      >
        <CalendarParticipantCellAptitudeGaz
          club={club}
          diver={diver}
          participant={participant}
          diveSession={diveSession}
          isPast={isPast}
          widthMode={widthMode}
        />
        {/* <div className="flex-grow flex justify-between gap-x-0.5 items-center"> */}
        <div className="overflow-hidden flex-grow">
          <CalendarParticipantCellName
            settingsUIPlanning={settingsUIPlanning}
            club={club}
            diver={diver}
            participant={participant}
            diveSession={diveSession}
            isPast={isPast}
            includePayment={includePayment}
            widthMode={widthMode}
          />
          {widthMode === 'large' &&
            includeEquipment &&
            isSessionOpen &&
            participant?.bookingState?.value !== 'cancelled' && (
              <CalendarParticipantCellEquipment
                className="mt-0.5"
                participant={participant}
              />
            )}
          {includeComment && widthMode === 'large' && (
            <ParticipantCommentCard
              className="mt-0.5"
              participant={participant}
              diver={diver}
            />
          )}
        </div>
        {widthMode === 'large' && (
          <BookingGroupSizeLabel
            className="place-self-start px-1 py-px text-app-xxs xl:text-xs"
            booking={booking}
          />
        )}
        {/* </div> */}
      </div>
    </a>
  );
}

function getUpdateAge(lastUpdate: Date) {
  const now = new Date();
  return now.getTime() - lastUpdate.getTime();
}

function isRecentUpdate(lastUpdate: Date): boolean {
  if (lastUpdate === undefined) {
    return null;
  }

  const ageMs = getUpdateAge(lastUpdate);
  const maxAgeMs = AGE_RECENT_1_DURATION_S * 1000;

  return ageMs > 0 && ageMs < maxAgeMs; // si update dans le futur, on ignore (en principe ça n'arrive pas, sauf si on trafique l'heure du PC)
}
