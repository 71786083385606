import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Placement } from '@popperjs/core';
import clsx from 'clsx';
import React, { Fragment, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import {
  AppButton,
  useMediaSizeTailwind,
} from 'src/business/_core/modules/layout';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { AppIcon } from '../../../icons/AppIcon.type';

interface AppButtonMultiActionsProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
  childrenPosition?: 'top' | 'bottom';
  items: AppHamburgerMenuItem[];
  buttonLabel?: string;
  buttonLabelClasses?: string;
  mainButtonMode?: 'primary-item' | 'open-menu';
  mainButtonClassName?: string;
  openButtonClassName?: string;
  position?: Placement;
  className?: string;
  buttonClassName?: string;
  buttonIcon?: AppIcon;
}

export function AppButtonMultiActions({
  header,
  children,
  childrenPosition = 'top',
  buttonLabel,
  buttonLabelClasses,
  openButtonClassName,
  mainButtonMode = 'primary-item',
  items: itemsInput = [],
  position,
  mainButtonClassName,
  className,
  buttonClassName,
  ...otherProps
}: AppButtonMultiActionsProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null);

  const mediaSize = useMediaSizeTailwind();

  const placement: Placement =
    mediaSize === 'xxs' || mediaSize === 'xs' ? 'auto' : position;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    // add offset of 5px to the left:
    // modifiers:
    //   placement === 'right-start'
    //     ? [
    //         {
    //           name: 'offset',
    //           options: {
    //             offset: [0, 5],
    //           },
    //         },
    //       ]
    //     : placement === 'bottom-end'
    //     ? [
    //         {
    //           name: 'offset',
    //           options: {
    //             offset: [0, 5],
    //           },
    //         },
    //       ]
    //     : undefined,
  });
  const items = useMemo(
    () => (itemsInput ?? []).filter((x) => !x.disableMessage),
    [itemsInput],
  );

  const filteredItems = useMemo(() => items.filter((x) => !x.hidden), [items]);

  const primaryItem = filteredItems?.[0];

  const secondaryItems = filteredItems; // .slice(1, items.length);

  return !filteredItems?.length ? null : (
    <div
      className={clsx(
        'rounded-md shadow-sm',
        mainButtonMode === 'primary-item' ? 'inline-flex' : 'flex',
        className,
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {mainButtonMode === 'primary-item' && (
        <AppButton
          className={mainButtonClassName}
          color={
            primaryItem.severity === 'danger'
              ? 'danger-outline-light'
              : primaryItem.severity === 'warn'
              ? 'primary-outline-light'
              : primaryItem.severity === 'success'
              ? 'primary-outline-light'
              : 'primary-outline-light'
          }
          disabled={!!primaryItem.disableMessage}
          size="normal"
          id={`primaryItem${primaryItem.id ? `-${primaryItem.id}` : ''}`}
          icon={primaryItem?.icon}
          onClick={primaryItem?.onClick}
        >
          <div className="truncate">
            {buttonLabel ?? primaryItem?.titleShort ?? primaryItem?.title}
          </div>
        </AppButton>
      )}

      {/* <button
        type="button"
        onClick={primaryItem.onClick}
        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-app-primary ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        {primaryItem?.titleShort}
      </button> */}
      {secondaryItems.length > 0 && (
        <Menu
          as="div"
          className={clsx(
            'relative block',
            mainButtonMode === 'primary-item' ? '-ml-px' : 'w-full',
          )}
        >
          <Menu.Button
            ref={setReferenceElement}
            className={clsx(
              'items-center focus:z-10 justify-between',

              openButtonClassName
                ? openButtonClassName
                : clsx(
                    'px-2 py-2 ',
                    'bg-white text-app-primary border border-app-primary hover:border-app-primary-dark hover:bg-gray-100',
                    mainButtonMode === 'open-menu'
                      ? 'flex w-full rounded-md'
                      : 'inline-flex rounded-r-md',
                  ),
            )}
          >
            <span className="sr-only">Ouvrir le menu</span>
            {mainButtonMode === 'open-menu' && buttonLabel && (
              <span className={buttonLabelClasses}>{buttonLabel}</span>
            )}
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-10 origin-top-right rounded-md bg-white px-1 border border-gray-400 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="w-full py-1">
                {secondaryItems.map((item, i) => (
                  <Menu.Item key={i}>
                    {({ active }) => (
                      // <AppButton
                      //   color={'gray-outline-light'}
                      //   size="normal"
                      //   id={`primaryItem${item.id ? `-${item.id}` : ''}`}
                      //   icon={item?.icon}
                      //   onClick={item?.onClick}
                      // >
                      //   {item?.titleShort ?? item?.title}
                      // </AppButton>
                      <button
                        onClick={!item.disableMessage && item.onClick}
                        className={clsx(
                          active
                            ? 'bg-gray-200 text-gray-900'
                            : 'bg-white text-gray-700 hover:bg-gray-200',
                          'w-full text-left font-medium px-2 py-2 text-sm truncate flex items-center gap-2',
                        )}
                      >
                        {item.icon && (
                          <item.icon
                            className={clsx(
                              'flex-shrink-0 h-4 w-4',
                              item.disableMessage
                                ? 'text-gray-400'
                                : item.severity === 'danger'
                                ? 'text-app-red'
                                : item.severity === 'warn'
                                ? 'text-app-orange'
                                : item.severity === 'success'
                                ? 'text-status-success'
                                : 'text-app-blue',
                            )}
                            aria-hidden="true"
                          />
                        )}
                        {item.title}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
}
