import { BaseEntity, JsonPatchOperation } from '@mabadive/app-common-model';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { repositoryEntityBuilder } from '../../repositoryEntityBuilder.service';
import { CollectionRepository } from '../CollectionRepository.type';
import { CollectionRepositoryStoreAdapters } from '../model';
import { CollectionWritableRepository } from './CollectionWritableRepository.type';
import { CollectionWritableRepositoryHooks } from './CollectionWritableRepositoryHooks.type';

export type RepositoryOperationSource = 'local' | 'remote';

export const collectionRepositoryWriterFactory = {
  create,
};

function create<T extends BaseEntity>({
  mutationEntity,
  storeAdapters,
  synchronous,
  hooks,
}: {
  mutationEntity: string;
  storeAdapters: CollectionRepositoryStoreAdapters<T>;
  synchronous: boolean;
  hooks?: CollectionWritableRepositoryHooks<T>;
}): CollectionWritableRepository<T> {
  const repositoryName = `${mutationEntity}Repository`;

  return {
    removeOneById,
    removeOne,
    createOne,
    createMany,
    patchOneById,
    patchOne,
    patchMany,
    // updateOne,
  } as CollectionRepository<T>;

  function createOne(
    item: Partial<T>,
    {
      source,
    }: {
      source: RepositoryOperationSource;
    } = {
      source: 'local',
    },
  ): Observable<T> {
    const startDate = new Date();

    return of(item).pipe(
      switchMap((item) => {
        const created = repositoryEntityBuilder.buildOneCreate<T>({
          source,
          item,
          now: startDate,
        });

        // appLogger.debug(`[collectionRepositoryFactory][${repositoryName}] createOne`, new)

        const actionId = `${repositoryName}_createOne`;

        storeAdapters.write.addOne({
          value: created,
          actionId,
        });

        return of(created);
      }),
    );
  }

  function createMany(
    items: Partial<T>[],
    {
      source,
    }: {
      source: RepositoryOperationSource;
    } = {
      source: 'local',
    },
  ): Observable<T[]> {
    return of(items).pipe(
      switchMap((items) => {
        const startDate = new Date();

        const news = items.map((item) =>
          repositoryEntityBuilder.buildOneCreate<T>({
            source,
            item,
            now: startDate,
          }),
        );

        const actionId = `${repositoryName}_createMany`;

        storeAdapters.write.addMany({
          values: news,
          actionId,
        });

        return of(news);
      }),
    );
  }

  function patchOneById(
    id: string,
    {
      patchOperations,
      source,
    }: {
      patchOperations: JsonPatchOperation[];
      source?: RepositoryOperationSource;
    },
  ): Observable<T> {
    return patchOne({
      criteria: { _id: id } as Partial<T>,
      patchOperations,
      source,
    });
  }

  function patchOne({
    criteria,
    patchOperations,
    source,
  }: {
    criteria: Partial<T>;
    patchOperations: JsonPatchOperation[];
    source?: RepositoryOperationSource;
  }): Observable<T> {
    if (!source) {
      source = 'local';
    }

    return _patchOneInner({
      criteria,
      patchOperations,
      source,
    }).pipe(
      switchMap(
        ({ original, patched }) =>
          hooks && hooks.afterPatchOne && source === 'local'
            ? hooks.afterPatchOne({ source, storeAdapters, original, patched })
            : of(undefined),
        ({ original, patched }) => patched,
      ),
    );
  }

  function _patchOneInner({
    criteria,
    patchOperations,
    source,
  }: {
    criteria: Partial<T>;
    patchOperations: JsonPatchOperation[];
    source: RepositoryOperationSource;
  }): Observable<{ original: T; patched: T }> {
    return of(criteria).pipe(
      switchMap((criteria) => {
        const actionId = `${repositoryName}_patchOne`;

        const { original, patched } = storeAdapters.write.patchOne({
          patch: {
            criteria,
            patchOperations,
          },
          source,
          actionId,
        });

        return of({ original, patched });
      }),
    );
  }

  function patchMany({
    patches,
    source,
  }: {
    patches: {
      criteria: Partial<T>;
      patchOperations: JsonPatchOperation[];
    }[];
    source?: RepositoryOperationSource;
  }): Observable<T[]> {
    if (!source) {
      source = 'local';
    }

    return _patchManyInner({ patches, source }).pipe(
      switchMap(
        (items) =>
          hooks && hooks.afterPatchMany && source === 'local'
            ? hooks.afterPatchMany({ source, storeAdapters, items })
            : of(undefined),
        (items) => items,
      ),
      map((items) => items.map((item) => item.original)),
    );
  }

  function _patchManyInner({
    patches,
    source,
  }: {
    patches: {
      criteria: Partial<T>;
      patchOperations: JsonPatchOperation[];
    }[];
    source: string;
  }): Observable<{ original: T; patched: T }[]> {
    return of(patches).pipe(
      switchMap(() => {
        const actionId = `${repositoryName}_patchMany`;

        const results = storeAdapters.write.patchMany({
          patches,
          source,
          actionId,
        });

        return of(results);
      }),
    );
  }

  function removeOneById(
    id: string,
    {
      source,
    }: {
      source: RepositoryOperationSource;
    } = {
      source: 'local',
    },
  ): Observable<T> {
    return removeOne({
      criteria: { _id: id } as Partial<T>,
      source,
    });
  }

  function removeOne({
    criteria,
    source,
  }: {
    criteria: Partial<T>;
    source?: RepositoryOperationSource;
  }): Observable<T> {
    if (!source) {
      source = 'local';
    }

    return _removeInner({
      criteria,
      source,
    }).pipe(
      switchMap(
        (removed) =>
          hooks && hooks.afterRemoveOne && source === 'local'
            ? hooks.afterRemoveOne({ criteria, source, storeAdapters, removed })
            : of(undefined),
        (removed) => removed,
      ),
    );
  }

  function _removeInner({
    criteria,
    source,
  }: {
    criteria: Partial<T>;
    source: RepositoryOperationSource;
  }) {
    const actionId = `${repositoryName}_removeOne`;
    const item = storeAdapters.write.removeOne({
      criteria,
      actionId,
    });

    return of(item);
  }
}
