import {
  ClubParticipant,
  DiveCenterPrivateSettingsEquipment,
  EquipmentDescription,
  EquipmentType,
} from '@mabadive/app-common-model';
import {
  equipmentDescriptionFormatter,
  equipmentVisibilityBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppIconsDiveEquipmentType } from 'src/business/_core/modules/layout/icons';
import { AppIcon } from 'src/business/_core/modules/layout/icons/AppIcon.type';

export type ParticipantEquipmentToDisplay = {
  type: EquipmentType;
  icon: AppIcon;
  description: EquipmentDescription;
  label: string;
};

export function useParticipantEquipmentsToDisplay({
  participant,
}: {
  participant: Pick<ClubParticipant, 'equipment'>;
}): {
  all: ParticipantEquipmentToDisplay[];
  self: ParticipantEquipmentToDisplay[];
  club: ParticipantEquipmentToDisplay[];
} {
  const clubResume = useClubResume();
  const customerSettings = clubResume.clubSettings?.customer;

  const diveCenterResume = useDiveCenterResume();

  const equipmentsSettings: DiveCenterPrivateSettingsEquipment =
    diveCenterResume?.privateSettings?.equipment;

  const all = useMemo(
    () =>
      equipmentsSettings.equipments
        .filter((equipmentConfig) =>
          equipmentVisibilityBuilder.isEquipmentVisibleOnSession({
            equipment: participant?.equipment,
            equipmentConfig,
          }),
        )
        .map((x) => {
          const description = participant?.equipment?.[x.type];
          const equipment: ParticipantEquipmentToDisplay = {
            type: x.type,
            icon: AppIconsDiveEquipmentType[x.type],
            description,
            label: equipmentDescriptionFormatter.formatEquipmentDescription(
              description,
              {
                format: 'long',
              },
            ),
          };
          return equipment;
        }),
    [equipmentsSettings.equipments, participant?.equipment],
  );

  const self = useMemo(
    () => all.filter((e) => e.description.selfEquipped),
    [all],
  );

  const club = useMemo(
    () => all.filter((e) => e.description.clubEquipped),
    [all],
  );

  return {
    all,
    self,
    club,
  };
}
