import { useState } from 'react';
import { CollectionOrder } from './CollectionOrder.model';

export function useCollectionOrder<T>(
  initialValue: CollectionOrder<T>,
  {
    reverseColumns,
    onOrderChanged,
  }: {
    reverseColumns?: T[];
    onOrderChanged?: (order: CollectionOrder<T>) => void;
  } = {
      reverseColumns: [],
    },
): [CollectionOrder<T>, (column: T) => void] {

  const initialColumn: T = initialValue && !!initialValue.column ? initialValue.column : undefined;
  const initialAsc: boolean = initialValue && initialValue.asc !== undefined ? initialValue.asc : getDefaultAsc(initialColumn);

  const [order, setOrder] = useState<CollectionOrder<T>>({
    column: initialColumn,
    asc: initialAsc,
  });

  function getDefaultAsc(column: T): boolean {
    return reverseColumns && reverseColumns.indexOf(column) !== -1 ? false : true;
  }

  function sortByColumn(column: T) {
    const newOrder = {
      column,
      asc: order.column === column ? !order.asc : getDefaultAsc(column),
    };
    setOrder(newOrder);
    if (onOrderChanged) {
      onOrderChanged(newOrder);
    }
  }

  return [order, sortByColumn];
}