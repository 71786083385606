/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DIVE_SERVICE_OFFER_SUPERVISIONS,
  DiveMode,
  DiveServiceOfferSupervision,
} from '@mabadive/app-common-model';
import { offerSupervisionFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
export function useSupervisionOptions(
  diveMode: DiveMode,
): ValueLabel<DiveServiceOfferSupervision, string>[] {
  const supervisionOptions = useMemo(
    () =>
      DIVE_SERVICE_OFFER_SUPERVISIONS.filter((x) => {
        if (diveMode === 'first-dive') {
          return x === 'supervised';
        }
        if (diveMode === 'supervised') {
          return x === 'supervised';
        }
        if (diveMode === 'autonomous' || diveMode === 'instructor') {
          return x === 'autonomous';
        }
        return false;
      }).map((supervision) => {
        const option: ValueLabel<DiveServiceOfferSupervision> = {
          label: offerSupervisionFormatter
            .formatOfferSupervision(supervision, { format: 'full' })
            .toUpperCase(),
          value: supervision,
        };
        return option;
      }),
    [diveMode],
  );
  return supervisionOptions;
}
