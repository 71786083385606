/* eslint-disable @typescript-eslint/no-unused-vars */
import { ProMultiOperationPayload } from '@mabadive/app-common-model';
import React, { FC, useCallback } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import {
  AppButtonsBar,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';
import { PlanningViewConfig } from 'src/business/club/modules/club-planning/_model';
import { ClubEventCard } from '../ClubEventCard';
import {
  ClubEventDialogInputState,
  ClubEventDialogUpdateState,
  ClubEventEditorFormModel,
} from './model';
import { ClubEventDialogTab1Edit } from './tabs';
import { ClubEventDialogTab1EditInfoBox } from './tabs/ClubEventDialogTab1Edit/ClubEventDialogTab1EditInfoBox';
import { useClubEventDialogInitialValue } from './useClubEventDialogInitialValue.hook';
import { useClubEventDialogLocalState } from './useClubEventDialogLocalState.hook';

export const ClubEventDialog: FC<{
  state: ClubEventDialogInputState;
  setState: React.Dispatch<React.SetStateAction<ClubEventDialogInputState>>;
  onConfirm?: (updateState: ClubEventDialogUpdateState) => void;
  planningConfig: PlanningViewConfig;
}> = ({
  onConfirm,
  state: inputState,
  setState: setInputState,
  planningConfig,
}) => {
  const globalClasses = useGlobalClasses();

  const { defaultValue, originalEvent, mode } = inputState;

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setInputState({
        ...inputState,
        isOpen,
      });
    },
    [inputState, setInputState],
  );

  const clubResume = useClubResume();

  const initialFormValue = useClubEventDialogInitialValue({
    originalEvent,
    defaultValue,
    mode,
  });

  const localState = useClubEventDialogLocalState({
    initialFormValue,
    inputState,
  });
  const {
    form,
    aggregatedData,
    clubReference,
    isPersistInProgress,
    setIsPersistInProgress,
    updateState,
    setUpdateState,
  } = localState;

  const clubEvent = aggregatedData.clubEvent;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: ClubEventEditorFormModel, event) => {
        // if (!hasChanges) {
        //   // no changes
        //   setIsOpen(false);
        //   return;
        // } else {
        onConfirm(updateState);
        // }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [handleSubmit, onConfirm, updateState]);

  const deleteEventWithConfirmDialog = useCallback(async () => {
    if (clubEvent) {
      if (
        await confirmDialog.confirmPromise({
          title: 'Supprimer une note',
          message:
            'Êtes-vous sûr de vouloir supprimer définitivement cette note?',
          type: 'noYesDanger',
        })
      ) {
        const payload: ProMultiOperationPayload = {
          logContext: 'delete club event',
          deletedClubEventIds: [clubEvent._id],
        };

        await clubMassiveUpdatorClient.update(payload);
        setIsOpen(false);
      }
    }
  }, [clubEvent, setIsOpen]);

  return !inputState.isOpen ? null : (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-white"
      headerBar={() => (
        <div>
          {clubEvent && (
            <ClubEventCard
              widthMode={'large'}
              className="flex-grow bg-white"
              clubEvent={clubEvent}
              // widthMode="large"
            />
          )}
        </div>
      )}
      footerBar={() => (
        <AppButtonsBar className="" hasChanges={formState.isDirty}>
          <>
            <AppButton
              fullWidth={true}
              icon={AppHeroIcons.actionCancel}
              color="gray-outline-light"
              size="normal"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Annuler
            </AppButton>
            <AppButton
              fullWidth={true}
              icon={AppHeroIcons.actionSave}
              color="primary-outline-light"
              onClick={() => {
                submitForm();
              }}
            >
              {'Enregistrer'}
            </AppButton>
          </>
        </AppButtonsBar>
      )}
    >
      <>
        <ClubEventDialogTab1Edit localState={localState} />
        <div className="my-4 px-2 flex gap-4">
          {mode === 'edit' && (
            <AppButton
              disabled={updateState.hasChanges}
              // className={updateState.hasChanges ? 'opacity-50' : ''}
              fullWidth={true}
              icon={AppHeroIcons.actionClone}
              color={'primary-outline-light'}
              size="normal"
              onClick={() => {
                setInputState({
                  isOpen: true,
                  mode: 'clone',
                  originalEvent: {
                    ...clubEvent,
                    _id: null,
                  },
                });
              }}
            >
              Dupliquer la note
            </AppButton>
          )}
          {mode !== 'create' && (
            <AppButton
              fullWidth={true}
              icon={AppHeroIcons.actionDelete}
              color={'danger-outline-light'}
              size="normal"
              onClick={() => {
                deleteEventWithConfirmDialog();
              }}
            >
              Supprimer
            </AppButton>
          )}
        </div>
        <ClubEventDialogTab1EditInfoBox />
      </>
    </AppPageContainerWithFixedBars>
  );
};
