import { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserClubAuthorizations,
} from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import {
  CreateOfferPayload,
  clubSettingsProductOfferBuilder,
} from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog';
import { ClubSettingsServicesViewPanelOffersPricesLocalState } from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export function useOffersPricesActionsBar_AdditionalProducts_MenuItems({
  localState,
}: {
  localState: ClubSettingsServicesViewPanelOffersPricesLocalState;
}) {
  const user = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();

  const { data, state, actions } = localState;
  const clubReference = data.clubReference;

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    hamburgerMenuItems.push({
      title: 'Licence FFESSM',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            defaultOrganizationReference: 'ffessm',
            label: 'Licence FFESSM',
            productType: 'sale',
            salesCriteria: {
              favorite: true,
              standalone: true,
              extraCostTraining: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });

    hamburgerMenuItems.push({
      title: 'Plongée de nuit',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Plongée de nuit',
            productType: 'fee',
            salesCriteria: {
              favorite: true,
              extraCostSession: true,
              extraCostDivePlan: true,
              extraCostUnitDive: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Équipement',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Équipement',
            productType: 'rental',
            salesCriteria: {
              favorite: true,
              extraCostDivePlan: true,
              extraCostUnitDive: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Assurance',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Assurance',
            productType: 'sale',
            salesCriteria: {
              favorite: true,
              extraCostDivePlan: true,
              extraCostUnitDive: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Boutique',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Boutique',
            productType: 'sale',
            salesCriteria: {
              standalone: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Carnet de plongée',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Carnet de plongée',
            productType: 'sale',
            salesCriteria: {
              standalone: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Encadrement',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Encadrement',
            productType: 'fee',
            salesCriteria: {
              favorite: true,
              extraCostDivePlan: true,
              extraCostUnitDive: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Nitrox',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Nitrox',
            productType: 'fee',
            salesCriteria: {
              favorite: true,
              extraCostDivePlan: true,
              extraCostUnitDive: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Hébergement',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Hébergement',
            productType: 'fee',
            salesCriteria: {
              standalone: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Gonflage',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Gonflage',
            productType: 'fee',
            salesCriteria: {
              standalone: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Location bateau',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Location bateau',
            productType: 'rental',
            salesCriteria: {
              standalone: true,
              favorite: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Carte de niveau',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Carte de niveau',
            productType: 'sale',
            salesCriteria: {
              favorite: true,
              extraCostTraining: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });
    hamburgerMenuItems.push({
      title: 'Carte cadeau',
      icon: AppHeroIcons.actionAdd,
      onClick: () => {
        const newOffer: CreateOfferPayload =
          clubSettingsProductOfferBuilder.buildNewOfferProductExtra({
            clubReference,
            label: 'Carte cadeau',
            productType: 'sale',
            salesCriteria: {
              standalone: true,
            },
          });
        actions.openOfferEditDialog({
          mode: 'create',
          offers: [newOffer],
        });
      },
    });

    return hamburgerMenuItems;
  }, [actions, clubReference]);

  return hamburgerMenuItems;
}
