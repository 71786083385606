/* eslint-disable @typescript-eslint/no-unused-vars */
import { clubOfferStateFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppPageBlock,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppMessage } from 'src/business/_core/modules/layout/components/_tailwind';
import { ClubOfferCard } from './ClubOfferCard';
export const ClubOfferViewPage = () => {
  const clubResume = useClubResume();

  const redirectTo = useRedirect();

  const offer = clubResume?.offer;

  return (
    <AppPageContainer className={'bg-white'}>
      <AppPageContentContainer className="bg-white app-px-content">
        <AppPageBlock>
          <div className={''}>
            <AppMessage
              isExpandable={false}
              className=""
              title={clubOfferStateFormatter
                .formatClubOfferState(offer?.state)
                .toUpperCase()}
              message={<ClubOfferCard offer={offer} />}
            />
          </div>
        </AppPageBlock>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
