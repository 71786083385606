import { ReactComponent as snorkeling } from 'src/assets/icons/equipment/scuba-mask-2905978.svg';
import { ReactComponent as firstDive } from 'src/assets/icons/fish-2905979.svg';
import { ReactComponent as autonomous } from 'src/assets/icons/scuba-1399350.svg';
import { ReactComponent as training } from 'src/assets/icons/scuba-diver-1464056.svg';

export const DiveModeIcons = {
  firstDive,
  watchingTour: firstDive,
  autonomous,
  training,
  snorkeling,
};
