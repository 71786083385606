/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppCompanyMessageSchedule } from '@mabadive/app-common-model';
import { commonParticipantBuilder } from '@mabadive/app-common-services';
import { useCallback, useMemo } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { authenticationClient } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { useDiverBookingPageOpenDiverSelector } from './components/ClubDiverSelectorDialog';
import {
  useDiverBookingPageOpenSessionDialog,
  useDiverBookingPageOpenSessionSelector,
} from './components/DiverBookingPageOpenSessionDialog';
import {
  useOpenCreatePurchaseOtherDialog,
  useOpenEditPurchaseOtherDialog,
} from './components/DiverPurchaseOtherEditorDialog';
import {
  useOpenCreatePlanDialog,
  useOpenEditPlanDialog,
} from './components/DiverPurchasePlanEditorDialog';
import {
  useOpenCreateTrainingDialog,
  useOpenEditTrainingDialog,
} from './components/DiverPurchaseTrainingEditorDialog';
import {
  BookingTabModel,
  DiverBookingNewBookingSession,
  DiverBookingPageUpdateState,
  DiverBookingUpdateProductParticipant,
} from './models';
import {
  diverBookingPageUpdateStateManager,
  useAssignProductsToPurchasePackage,
  useDiverBookingPageAddSessions,
  useDiverBookingPageCreateBooking,
  useDiverBookingPageCreateParticipantsQuickIfPossible,
  useDiverBookingPageCreatePurchasePackage,
  useDiverBookingPageCreatePurchasePayment,
  useDiverBookingPageDeleteBookingDeposit,
  useDiverBookingPageDeleteCreditNote,
  useDiverBookingPageDeleteDiverOnly,
  useDiverBookingPageDeleteMember,
  useDiverBookingPageDeletePurchasePackage,
  useDiverBookingPageDeletePurchasePayment,
  useDiverBookingPageUpdateBookingProducts,
  useDiverBookingPageUpdateDiverAndParticipant,
  useDiverBookingPageUpdatePurchasePackage,
  useDiverBookingPageUpdatePurchasePayment,
} from './services';
import { DiverBookingPageGlobalState } from './useDiverBookingPageGlobalState.hook';

export function useDiverBookingPageActions({
  globalState,
  dialogs: dialogs_old,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
}) {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;

  const {
    aggregatedData,
    updateState,
    setUpdateState,
    clubReference,
    diveCenterId,
    focus,
    navigationContext,
    setTriggerAction,
    addDiversIds,
    dialogsState,
  } = globalState;

  // const {} = dialogsState

  const {
    sessionDialogState,
    setSessionDialogState,
    setDiverSelectorDialogState,
    clubPlanningSessionSelectorState,
    setClubPlanningSessionSelectorState,
    setTrainingEditorDialogState,
    setPlanEditorDialogState,
    setPaymentEditorDialogState,
    setPurchaseOtherEditorDialogState,
    setBookingGroupAndJourneyEditDialogState,
  } = dialogs_old;

  const openDiverSelector = useDiverBookingPageOpenDiverSelector({
    aggregatedData,
    setDiverSelectorDialogState,
  });

  const openSessionDialog = useDiverBookingPageOpenSessionDialog({
    sessionDialogState,
    setSessionDialogState,
    aggregatedData,
  });

  const openEditTrainingDialog = useOpenEditTrainingDialog({
    setTrainingEditorDialogState,
  });
  const openCreateTrainingDialog = useOpenCreateTrainingDialog({
    setTrainingEditorDialogState,
  });

  const openEditPlanDialog = useOpenEditPlanDialog({
    setPlanEditorDialogState,
  });
  const openCreatePlanDialog = useOpenCreatePlanDialog({
    setPlanEditorDialogState,
  });

  // hack, les traitements sont trop entremélés!
  const openCreatePurchasePaymentDialog =
    globalState._dialog_hack.openCreatePurchasePaymentDialog;

  const openEditPurchaseOtherDialog = useOpenEditPurchaseOtherDialog({
    setPurchaseOtherEditorDialogState,
  });
  const openCreatePurchaseOtherDialog = useOpenCreatePurchaseOtherDialog({
    setPurchaseOtherEditorDialogState,
  });
  const openSessionSelector = useDiverBookingPageOpenSessionSelector({
    aggregatedData,
    setClubPlanningSessionSelectorState,
    clubPlanningSessionSelectorState,
    focusSessionReference: focus.diveSessionReference,
  });

  const openBookingMassiveEditor = useCallback(
    ({ bookingId }: { bookingId: string }) => {
      dialogs_old.setBookingMassiveEditorDialogState({
        isOpen: true,
        bookingId,
        loadedContent: globalState.loadedContent,
        updateState: globalState.updateState,
      });
    },
    [dialogs_old, globalState.loadedContent, globalState.updateState],
  );

  const createBookingSessions = useDiverBookingPageAddSessions({
    aggregatedData,
    clubReference,
    diveCenterId,
    setUpdateState,
    updateState,
  });

  const createPurchasePackage = useDiverBookingPageCreatePurchasePackage({
    setUpdateState,
    updateState,
  });

  const createPurchasePayment = useDiverBookingPageCreatePurchasePayment({
    setUpdateState,
    updateState,
  });
  const deletePurchasePayment = useDiverBookingPageDeletePurchasePayment({
    setUpdateState,
    updateState,
  });
  const deleteBookingDeposit = useDiverBookingPageDeleteBookingDeposit({
    setUpdateState,
    updateState,
  });
  const deleteCreditNote = useDiverBookingPageDeleteCreditNote({
    setUpdateState,
    updateState,
  });
  const assignProductsToPurchasePackage = useAssignProductsToPurchasePackage({
    aggregatedData,
    setUpdateState,
    updateState,
  });

  const updateDiverAndParticipant =
    useDiverBookingPageUpdateDiverAndParticipant({
      setUpdateState,
      updateState,
    });

  const updateProductPurchase = useDiverBookingPageUpdatePurchasePackage({
    setUpdateState,
    updateState,
  });
  const updateBookingProducts = useDiverBookingPageUpdateBookingProducts({
    setUpdateState,
    updateState,
  });
  const updatePurchasePayment = useDiverBookingPageUpdatePurchasePayment({
    setUpdateState,
    updateState,
  });

  const deletePurchasePackage = useDiverBookingPageDeletePurchasePackage({
    setUpdateState,
    updateState,
  });

  const createNewBooking = useDiverBookingPageCreateBooking({
    clubReference,
    diveCenterId,
    diverId: navigationContext?.diverId,
    focus,
    updateState,
    setUpdateState,
    setBookingGroupAndJourneyEditDialogState,
  });

  const deleteDiverOnly = useDiverBookingPageDeleteDiverOnly({
    updateState,
    setUpdateState,
  });
  const deleteMember = useDiverBookingPageDeleteMember({
    updateState,
    setUpdateState,
  });
  const createParticipantsQuickIfPossible =
    useDiverBookingPageCreateParticipantsQuickIfPossible(
      setUpdateState,
      updateState,
    );

  const onBookingSessionsCreated = useCallback(
    ({
      bookingId,
      newBookingSessions,
      updateState,
    }: {
      bookingId: string;
      newBookingSessions: DiverBookingNewBookingSession[];
      // IMPORTANT: on passe l'updateState en paramètre car c'est appelé immédiatement à la fermeture du dialog de choix de sessions
      updateState: DiverBookingPageUpdateState;
    }) => {
      let updateStateLocal = { ...updateState };

      const aggregatedBooking = aggregatedData.bookingResumesLoaded.find(
        (x) => x.booking._id === bookingId,
      );

      if (!aggregatedBooking) {
        appLogger.error('Booking not found');
        return;
      }
      if (newBookingSessions.length !== 0) {
        // au moins une booking session créée

        const selectedMemberFull =
          aggregatedBooking.bookingMembersFull.length === 1
            ? aggregatedBooking.bookingMembersFull[0]
            : undefined;
        if (selectedMemberFull?.diver?.defaultDiveConfig?.diveMode) {
          // si 1 seul plongeur (et que defaut diveMode est défini): ajouter immédiatement en mode "quick"

          const { diver, bookingMember } = selectedMemberFull;

          newBookingSessions.forEach((newBookingSession) => {
            const newBookingProductParticipant: DiverBookingUpdateProductParticipant =
              commonParticipantBuilder.createParticipantFromDefaultDiveConfig({
                bookingMember,
                diver,
                diveCenterResume,
                bookingSessionId: newBookingSession.bookingSession._id,
                diveSessionReference:
                  newBookingSession.diveSessionResume.reference,
                bookingId,
                defaultDiveMode:
                  newBookingSession.diveSessionResume.details?.defaultDiveMode,
                sessionsCount:
                  newBookingSession?.diveSessionResume?.sessionsCount,
              });
            updateStateLocal =
              diverBookingPageUpdateStateManager.addNewParticipantToState({
                updateState: updateStateLocal,
                newBookingProductParticipant,
              });
          });
          setUpdateState(updateStateLocal, {
            action: 'DiverBookingPageCreateParticipantsQuickIfPossible',
          });
        }
        if (
          aggregatedBooking.bookingMembersFull.length > 1 ||
          newBookingSessions.length > 1
        ) {
          // si au moins 2 membres ou 2 sessions: ouverture de l'édition en masse
          setTriggerAction({
            id: 'open-massive-editor-dialog',
            bookingId,
            bookingSessionsIds: newBookingSessions.map(
              (x) => x.bookingSession._id,
            ),
          });
        }
      }
    },
    [
      aggregatedData.bookingResumesLoaded,
      setUpdateState,
      diveCenterResume,
      setTriggerAction,
    ],
  );
  const persistAndGenerateBookingMemberKeys = useCallback(
    async (bookingTabModel: BookingTabModel) => {
      const { booking, bookingContact } = bookingTabModel.aggregatedBooking;

      const isSuccess = await globalState.actions.persistChanges(
        'persistAndGenerateBookingMemberKeys',
      );
      if (isSuccess) {
        let bookingMembersFull =
          bookingTabModel.aggregatedBooking.bookingMembersFull;
        let bookingLocal = booking;

        if (
          bookingMembersFull.find((x) => !x.bookingMember.bookingAliasKey) !==
          undefined
        ) {
          // on doit générer les alias manquant
          // TODO, il faut optimiser pour ne pas faire de requête supplémentaire

          const updatedData =
            await authenticationClient.createCustomerSpaceBookingMemberKeys({
              bookingId: booking._id,
            });
          bookingLocal = updatedData.booking;
          bookingMembersFull = bookingMembersFull.map((bm) => ({
            ...bm,
            bookingMember: {
              ...bm.bookingMember,
              ...updatedData.bookingMembers.find(
                (x) => x._id === bm.bookingMember._id,
              ),
            },
          }));
        }
        return {
          isSuccess,
          updated: {
            bookingMembersFull,
            booking: bookingLocal,
          },
        };
      }
    },
    [globalState.actions],
  );
  const persistAndOpenCustomerSpace = useCallback(
    async (bookingTabModel: BookingTabModel) => {
      const { booking, bookingContact } = bookingTabModel.aggregatedBooking;
      const { isSuccess, updated } = await persistAndGenerateBookingMemberKeys(
        bookingTabModel,
      );
      if (isSuccess) {
        let bookingMembersFull = updated.bookingMembersFull;
        let booking = updated.booking;

        dialogs_old.setBookingCustomerConfigEditDialogState({
          isOpen: true,
          isNewBooking: bookingTabModel.aggregatedBooking.isNewBooking,
          bookingMembersFull,
          bookingParticipantsFull:
            bookingTabModel.aggregatedBooking.bookingParticipantsFull,
          booking,
          bookingContact,
        });
      }
    },
    [persistAndGenerateBookingMemberKeys, dialogs_old],
  );
  const deleteMessageSchedule = useCallback(
    async (messageSchedule: AppCompanyMessageSchedule) => {
      const confirm = await confirmDialog.confirmPromise({
        title: 'Supprimer ce message',
        message: 'Êtes-vous sûr de vouloir supprimer ce message?',
        type: 'remove',
      });
      if (confirm) {
        await clubMassiveUpdatorClient.update({
          appCompanyMessageSchedules: {
            deletedIds: [messageSchedule._id],
          },
        });
        globalState.actions.refetch.messages();
      }
    },
    [globalState.actions],
  );

  const persistAndOpenCreateMessage = useCallback(
    async (bookingTabModel: BookingTabModel) => {
      const { isSuccess, updated } = await persistAndGenerateBookingMemberKeys(
        bookingTabModel,
      );
      if (isSuccess) {
        let bookingMembersFull = updated.bookingMembersFull;
        let booking = updated.booking;

        dialogsState.createMessageToCustomersDialog.openDialog({
          mode: 'create',
          defaultValue: {
            //
          },
          context: 'booking',
          bookingContext: {
            booking,
            bookingMembersFull,
          },
        });
      }
    },
    [
      persistAndGenerateBookingMemberKeys,
      dialogsState.createMessageToCustomersDialog,
    ],
  );
  const actions = useMemo(() => {
    return {
      onBookingSessionsCreated,
      addDiversIds,
      createBookingSessions,
      createNewBooking,
      createPurchasePackage,
      createPurchasePayment,
      deletePurchasePayment,
      deleteBookingDeposit,
      deleteCreditNote,
      deleteDiverOnly,
      deleteMember,
      deletePurchasePackage,
      openCreatePlanDialog,
      openEditPurchaseOtherDialog,
      openCreatePurchaseOtherDialog,
      openCreateTrainingDialog,
      openDiverSelector,
      openEditPlanDialog,
      openEditTrainingDialog,
      openSessionDialog,
      openSessionSelector,
      openBookingMassiveEditor,
      updateDiverAndParticipant,
      updateProductPurchase,
      updateBookingProducts,
      updatePurchasePayment,
      assignProductsToPurchasePackage,
      openCreatePurchasePaymentDialog,
      createParticipantsQuickIfPossible,
      dialogs: dialogs_old,
      persistAndOpenCreateMessage,
      persistAndGenerateBookingMemberKeys,
      persistAndOpenCustomerSpace,
      deleteMessageSchedule,
    };
  }, [
    onBookingSessionsCreated,
    addDiversIds,
    createBookingSessions,
    createNewBooking,
    createPurchasePackage,
    createPurchasePayment,
    deletePurchasePayment,
    deleteBookingDeposit,
    deleteCreditNote,
    deleteDiverOnly,
    deleteMember,
    deletePurchasePackage,
    openCreatePlanDialog,
    openEditPurchaseOtherDialog,
    openCreatePurchaseOtherDialog,
    openCreateTrainingDialog,
    openDiverSelector,
    openEditPlanDialog,
    openEditTrainingDialog,
    openSessionDialog,
    openSessionSelector,
    openBookingMassiveEditor,
    updateDiverAndParticipant,
    updateProductPurchase,
    updateBookingProducts,
    updatePurchasePayment,
    assignProductsToPurchasePackage,
    openCreatePurchasePaymentDialog,
    createParticipantsQuickIfPossible,
    dialogs_old,
    persistAndOpenCreateMessage,
    persistAndGenerateBookingMemberKeys,
    persistAndOpenCustomerSpace,
    deleteMessageSchedule,
  ]);
  return actions;
}

export type DiverBookingPageActions = ReturnType<
  typeof useDiverBookingPageActions
>;
