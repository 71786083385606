/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  changeDescriptorManager,
  jsonPatcher,
  staffMemberFormatter,
} from '@mabadive/app-common-services';
import React, { FC, useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppInputBooleanRHF, AppInputTimeRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { useDiveSitesOptions } from 'src/pages/_components/options/useDiveSitesOptions.hook';
import { DiveSessionEditorDialogLocalState } from '../../useDiveSessionEditorDialogLocalState.hook';

export const DiveSessionDialogTab3GroupsPanel4StaffSecurity: FC<{
  localState: DiveSessionEditorDialogLocalState;
  className?: string;
}> = ({ localState, className = '' }) => {
  const {
    form,
    aggregatedData: { groups, clubParticipants, diveSession, divers },
    updateState,
    setUpdateState,
    staffMembersFull,
  } = localState;

  const { control, setValue } = form;

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const currentDiveSitesIds = useWatch({
    control,
    name: ['diveSession.diveSiteId', 'diveSession.diveTourSession2.diveSiteId'],
  });

  const diveSiteOptions = useDiveSitesOptions({ currentDiveSitesIds });

  const divingDirectorStaffOptions = useMemo(
    () =>
      staffMembersFull
        .filter(
          (x) =>
            x.meta.available &&
            x.meta.availableRoles.includes('diving-director'),
        )
        .map((x) => {
          const { staffMember } = x;
          const option: ValueLabel<string> = {
            value: staffMember._id,
            label: staffMemberFormatter.formatStaffMember(staffMember, {
              format: 'name-diving-level',
            }),
          };
          return option;
        }),
    [staffMembersFull],
  );

  const surfaceSecurityStaffOptions = useMemo(
    () =>
      staffMembersFull
        .filter(
          (x) =>
            x.meta.available &&
            x.meta.availableRoles.includes('surface-security'),
        )
        .map((x) => {
          const { staffMember } = x;
          const option: ValueLabel<string> = {
            value: staffMember._id,
            label: staffMemberFormatter.formatStaffMember(staffMember, {
              format: 'name',
            }),
          };
          return option;
        }),
    [staffMembersFull],
  );

  const isMultiSession = !!diveSession.diveTourSession2;

  const [
    session1DivingDirectorStaffId,
    session1SurfaceSecurityStaffId,
    session2DivingDirectorStaffId,
    session2SurfaceSecurityStaffId,
    session2SameStaffSession1,
  ] = useWatch({
    control,
    name: [
      'diveSession.diveTourSession1.divingDirector.staffId',
      'diveSession.diveTourSession1.surfaceSecurityStaffId',
      'diveSession.diveTourSession2.divingDirector.staffId',
      'diveSession.diveTourSession2.surfaceSecurityStaffId',
      'diveSession.diveTourSession2.sameStaffSession1',
      'sameParticipantsSession1',
    ],
  });

  const onUpdateSameStaffSession1 = useCallback(
    ({ sameStaffSession1 }: { sameStaffSession1: boolean }) => {
      const patches = groups
        .map((group) => {
          const patchOperations = jsonPatcher.compareObjects(
            group,
            {
              ...group,
              // mise à jour de la configuration des moniteurs de la seconde session pour chaque palanquée
              diveTourGroupSession2: sameStaffSession1
                ? undefined
                : group.diveTourGroupSession1
                ? {
                    ...group.diveTourGroupSession1,
                    instructor: group.diveTourGroupSession1.instructor
                      ? {
                          ...group.diveTourGroupSession1.instructor,
                        }
                      : undefined,
                    diveGuide: group.diveTourGroupSession1.diveGuide
                      ? {
                          ...group.diveTourGroupSession1.diveGuide,
                        }
                      : undefined,
                  }
                : undefined,
            },
            {
              // replaceDeleteByNullValue: true,
              attributesToReplaceFully: ['diveTourGroupSession2'],
            },
          );
          if (patchOperations.length) {
            return {
              pk: group._id,
              patchOperations,
            };
          }
        })
        .filter((x) => !!x);

      if (patches.length) {
        const localUpdateState = {
          ...updateState,
        };
        localUpdateState.diveSessionGroupsChanges =
          changeDescriptorManager.updateMany(patches, {
            changeDescriptors: localUpdateState.diveSessionGroupsChanges,
          });
        setUpdateState(localUpdateState);
      }
    },
    [groups, setUpdateState, updateState],
  );
  const onUpdateSameParticipantsSession1 = useCallback(
    ({ sameParticipantsSession1 }: { sameParticipantsSession1: boolean }) => {
      // mise à jour de la configuration pour chaque plongeur de chaque palanquée
      const patches = clubParticipants
        .map((clubParticipant) => {
          const patchOperations = jsonPatcher.compareObjects(
            clubParticipant,
            {
              ...clubParticipant,
              details: {
                ...(clubParticipant.details ?? {}),
                multiSessionsPresenceNumbers: sameParticipantsSession1
                  ? undefined
                  : [1, 2],
              },
            },
            {
              // replaceDeleteByNullValue: true,
              attributesToReplaceFully: ['details'],
            },
          );
          if (patchOperations.length) {
            return {
              pk: clubParticipant._id,
              patchOperations,
            };
          }
        })
        .filter((x) => !!x);
      if (patches.length) {
        const localUpdateState = {
          ...updateState,
        };
        localUpdateState.clubParticipantsChanges =
          changeDescriptorManager.updateMany(patches, {
            changeDescriptors: localUpdateState.clubParticipantsChanges,
          });
        setUpdateState(localUpdateState);
      }
    },
    [clubParticipants, setUpdateState, updateState],
  );

  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <h2 className="text-center text-sm sm:text-lg leading-6 p-2 font-medium text-app-blue uppercase">
        {isMultiSession ? 'Plongée N°1' : 'Plongée'}
      </h2>
      <div className="border-2 border-dotted border-gray-400 p-2">
        <div className={'flex justify-between gap-4'}>
          <AppFormControlRHF_Deprecated
            label={'Heure'}
            control={control}
            name={'diveSession.time'}
            required={true}
            renderComponent={(props) => (
              <AppInputTimeRHF
                {...props}
                onChange={(value) => {
                  if (value) {
                    setValue('diveSession.diveTourSession2.time', value, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            )}
          />

          <AppFormControlRHF_Deprecated
            className="w-full"
            label="Site de plongée"
            control={control}
            name={'diveSession.diveSiteId'}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF {...props} options={diveSiteOptions} />
            )}
          />
        </div>
        <div className={'flex justify-between gap-4'}>
          <AppFormControlRHF_Deprecated
            className="w-1/2"
            label={'Directeur de plongée'}
            control={control}
            name={'diveSession.diveTourSession1.divingDirector.staffId'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                {...props}
                disableClearButton={false}
                theme={'material-ui'}
                className="text-app-xxs"
                value={diveSession.diveTourSession1?.divingDirector?.staffId}
                options={divingDirectorStaffOptions}
                onChange={(divingDirectorStaffId) => {
                  const divingDirectorsStaffMember = staffMembersFull
                    .map((x) => x.staffMember)
                    .find((x) => x._id === divingDirectorStaffId);
                  const divingDirectorInstructorLevel =
                    divingDirectorsStaffMember?.scubaDivingInstructor?.degree
                      ?.level;
                  const divingDirectorInstructorDegreeName =
                    divingDirectorsStaffMember?.scubaDivingInstructor?.degree
                      ?.name;
                  setValue(
                    'diveSession.diveTourSession1.divingDirector.degree.level',
                    divingDirectorInstructorLevel,
                  );
                  setValue(
                    'diveSession.diveTourSession1.divingDirector.degree.name',
                    divingDirectorInstructorDegreeName,
                  );
                  if (
                    !session2DivingDirectorStaffId &&
                    !session2SameStaffSession1
                  ) {
                    setValue(
                      'diveSession.diveTourSession2.divingDirector.staffId',
                      divingDirectorStaffId,
                    );
                    setValue(
                      'diveSession.diveTourSession2.divingDirector.degree.level',
                      divingDirectorInstructorLevel,
                    );
                    setValue(
                      'diveSession.diveTourSession2.divingDirector.degree.name',
                      divingDirectorInstructorDegreeName,
                    );
                  }
                }}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-1/2"
            label={'Sécurité surface'}
            control={control}
            name={'diveSession.diveTourSession1.surfaceSecurityStaffId'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                {...props}
                disableClearButton={false}
                theme={'material-ui'}
                className="text-app-xxs"
                value={diveSession.diveTourSession1?.surfaceSecurityStaffId}
                options={surfaceSecurityStaffOptions}
                onChange={(surfaceSecurityStaffId) => {
                  if (
                    !session2SurfaceSecurityStaffId &&
                    !session2SameStaffSession1
                  ) {
                    setValue(
                      'diveSession.diveTourSession2.surfaceSecurityStaffId',
                      surfaceSecurityStaffId,
                    );
                  }
                }}
              />
            )}
          />
        </div>
      </div>
      {isMultiSession && (
        <>
          <h2 className="text-center text-sm sm:text-lg leading-6 p-2 font-medium text-app-blue uppercase">
            Plongée N°2
          </h2>
          <div className="border-2 border-dotted border-gray-400 p-2">
            <div className={'flex justify-between gap-4'}>
              <AppFormControlRHF_Deprecated
                label={'Heure'}
                control={control}
                name={'diveSession.diveTourSession2.time'}
                required={true}
                renderComponent={(props) => (
                  <AppInputTimeRHF
                    {...props}
                    onChange={(value) => {
                      if (value) {
                        setValue('diveSession.diveTourSession2.time', value, {
                          shouldValidate: true,
                        });
                      }
                    }}
                  />
                )}
              />

              <AppFormControlRHF_Deprecated
                className="w-full"
                label="Site de plongée"
                control={control}
                name={'diveSession.diveTourSession2.diveSiteId'}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={diveSiteOptions}
                  />
                )}
              />
            </div>
            <div className="flex flex-col gap-2 md:flex-row">
              <AppFormControlRHF_Deprecated
                className={'my-1'}
                control={control}
                name={'sameParticipantsSession1'}
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    className="inline-block"
                    {...props}
                    type="checkbox"
                    description={'Plongeurs identiques à la session 1'}
                    onChange={(sameParticipantsSession1) => {
                      onUpdateSameParticipantsSession1({
                        sameParticipantsSession1,
                      });
                    }}
                  />
                )}
              />
              <AppFormControlRHF_Deprecated
                className={'my-1'}
                control={control}
                name={'diveSession.diveTourSession2.sameStaffSession1'}
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    className="inline-block"
                    {...props}
                    type="checkbox"
                    description={'Moniteurs identiques à la session 1'}
                    onChange={(sameStaffSession1) => {
                      if (!sameStaffSession1) {
                        setValue(
                          'diveSession.diveTourSession2.divingDirector.staffId',
                          session1DivingDirectorStaffId,
                        );
                        setValue(
                          'diveSession.diveTourSession2.surfaceSecurityStaffId',
                          session1SurfaceSecurityStaffId,
                        );
                      }
                      onUpdateSameStaffSession1({ sameStaffSession1 });
                    }}
                  />
                )}
              />
            </div>
            {!session2SameStaffSession1 && (
              <div className={'flex justify-between gap-4'}>
                <AppFormControlRHF_Deprecated
                  className="w-1/2"
                  label={'Directeur de plongée'}
                  control={control}
                  name={'diveSession.diveTourSession2.divingDirector.staffId'}
                  renderComponent={(props) => (
                    <AppSingleSelect2HeadlessUIRHF
                      {...props}
                      disableClearButton={false}
                      theme={'material-ui'}
                      className="text-app-xxs"
                      value={
                        diveSession.diveTourSession2?.divingDirector?.staffId
                      }
                      options={divingDirectorStaffOptions}
                      onChange={(divingDirectorStaffId) => {
                        const divingDirectorsStaffMember = staffMembersFull
                          .map((x) => x.staffMember)
                          .find((x) => x._id === divingDirectorStaffId);
                        const divingDirectorInstructorLevel =
                          divingDirectorsStaffMember?.scubaDivingInstructor
                            ?.degree?.level;
                        const divingDirectorInstructorDegreeName =
                          divingDirectorsStaffMember?.scubaDivingInstructor
                            ?.degree?.name;
                        setValue(
                          'diveSession.diveTourSession2.divingDirector.degree.level',
                          divingDirectorInstructorLevel,
                        );
                        setValue(
                          'diveSession.diveTourSession2.divingDirector.degree.name',
                          divingDirectorInstructorDegreeName,
                        );
                      }}
                    />
                  )}
                />
                <AppFormControlRHF_Deprecated
                  className="w-1/2"
                  label={'Sécurité surface'}
                  control={control}
                  name={'diveSession.diveTourSession2.surfaceSecurityStaffId'}
                  renderComponent={(props) => (
                    <AppSingleSelect2HeadlessUIRHF
                      {...props}
                      disableClearButton={false}
                      theme={'material-ui'}
                      className="text-app-xxs"
                      value={
                        diveSession.diveTourSession2?.surfaceSecurityStaffId
                      }
                      options={surfaceSecurityStaffOptions}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
