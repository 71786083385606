/* eslint-disable @typescript-eslint/no-unused-vars */
import { diveSessionStaffMembersTableModelBuilder } from '@mabadive/app-common-services';
import React, { FC, useMemo } from 'react';
import { AppButton, AppMessageLight } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiveSessionEditorDialogLocalState } from '../../useDiveSessionEditorDialogLocalState.hook';
import { useOnUpdateSessionStaffConfig } from '../DiveSessionDialogTab2StaffMembers/useOnUpdateSessionStaffConfig.hook';
import { DiveSessionStaffMembersCard } from './components/DiveSessionStaffMembersCard';
import { DiveSessionStaffMembersCountLabel } from './components/DiveSessionStaffMembersTable/DiveSessionStaffMembersCountLabel';

export const DiveSessionDialogTab3GroupsPanel3StaffMembers: FC<{
  localState: DiveSessionEditorDialogLocalState;
  className?: string;
}> = ({ localState, className = '' }) => {
  const {
    form,
    aggregatedData: { groups, clubParticipants, diveSession },
    editStaffMember,
    isMultiSessionConfigForStaff,
    staffMembersFull,
    staffFirstNameBefore,
    setTabId,
    data,
    actions,
  } = localState;

  const { control } = form;

  const clubResume = useClubResume();

  const staffMembersTableModel = useMemo(
    () =>
      diveSessionStaffMembersTableModelBuilder.buildTableModel({
        clubParticipants,
        groups,
        isMultiSessionConfigForStaff,
        staffMembersFull:
          diveSessionStaffMembersTableModelBuilder.filterStaffMembersFull({
            staffMembersFull,
            filterMode: 'session-available',
          }),
      }),
    [clubParticipants, groups, isMultiSessionConfigForStaff, staffMembersFull],
  );
  const onUpdateSessionStaffConfig = useOnUpdateSessionStaffConfig({
    localState,
  });

  return staffMembersTableModel.rowsCount === 0 ? null : (
    <div className={`flex flex-col gap-1 text-gray-600 ${className}`}>
      <h2 className="text-center text-sm sm:text-lg leading-6 p-2 font-medium text-app-blue uppercase">
        Moniteurs{' '}
        <DiveSessionStaffMembersCountLabel
          staffMembersFull={staffMembersTableModel.staffMembersFull}
        />
      </h2>
      <div className="mb-2 flex justify-center">
        <AppButton
          className={
            data.selectedUngrouppedParticipantsIds.length === 0 &&
            !data.selectedMember
              ? 'invisible'
              : ''
          }
          size="normal"
          icon={AppHeroIcons.group}
          color={'primary-outline-light'}
          onClick={() => {
            actions.groupSelectedDivers({
              staffMember: data.selectedMember,
            });
          }}
        >
          <span className="font-bold uppercase text-xs">
            Nouvelle palanquée
          </span>
        </AppButton>
      </div>
      <div className="border-2 border-dotted border-gray-400 p-2">
        <DiveSessionStaffMembersCard
          diveSessionReference={diveSession.reference}
          date={diveSession.time}
          staffMembersResumeSessions={data.staffMembersResumeSessions}
          staffMembersTableModel={{
            ...staffMembersTableModel,
          }}
          isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
          context={'groups-editor'}
          staffFirstNameBefore={staffFirstNameBefore}
          onUpdateSessionStaffConfig={onUpdateSessionStaffConfig}
          onClickEdit={(x) => {
            editStaffMember(x);
          }}
          selectedMemberId={data.selectedMember?._id}
          toogleSelectMember={(memberId) => {
            actions.toogleSelectMember(memberId);
          }}
        />
        <div className="mt-8 mb-2 text-right">
          <button
            type="button"
            className="inline text-app-primary uppercase font-medium hover:underline cursor-pointer"
            onClick={() => {
              setTabId('list-staff-members');
            }}
          >
            Autres moniteurs
          </button>
        </div>
        <div className="mt-2 mb-2">
          <AppMessageLight>
            <div className="grid gap-2">
              <p>
                <b>ASTUCE:</b> cliquer sur un rôle pour l'assigner
              </p>
              <p>
                Si vous assignez le rôle <b>"Guide de Palanquée" (GP)</b> à un
                moniteur qui n'a pas encore de palanquée, une palanquée vide lui
                sera automatiquement créée.
              </p>
            </div>
          </AppMessageLight>
        </div>
      </div>
    </div>
  );
};
