import {
  APP_COMPANY_MESSAGE_TARGETS,
  AppCompanyMessageTarget,
} from '@mabadive/app-common-model';
import React from 'react';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { ValueLabel } from 'src/business/club/modules/_common/form';

// NOTE: voir aussi AppMessagePreviewHeaderBar

export function useAppCompanyMessageTargetOptions(
  targets: AppCompanyMessageTarget[] = APP_COMPANY_MESSAGE_TARGETS,
) {
  return targets.map((target: AppCompanyMessageTarget) => {
    const icon =
      target === 'sms'
        ? AppIcons.socialMedia.sms
        : target === 'whatsapp'
        ? AppIcons.socialMedia.whatsapp
        : AppIcons.socialMedia.mail;

    const option: ValueLabel<AppCompanyMessageTarget, React.ReactNode> = {
      value: target,
      icon,
      label:
        target === 'sms' ? 'SMS' : target === 'whatsapp' ? 'Whatsapp' : 'Email',
      // label: (
      //   <div
      //     className={clsx(
      //       'inline-block font-bold text-white py-px px-1',
      //       target === 'sms'
      //         ? 'bg-app-sms'
      //         : target === 'whatsapp'
      //         ? 'bg-app-whatsapp'
      //         : 'bg-app-email',
      //     )}
      //   >
      //     {target === 'sms' ? (
      //       <AppIcons.socialMedia.sms className={'w-6 h-6 lg:w-7 lg:h-7'} />
      //     ) : target === 'whatsapp' ? (
      //       <AppIcons.socialMedia.whatsapp
      //         className={'w-6 h-6 lg:w-7 lg:h-7'}
      //       />
      //     ) : (
      //       <AppIcons.socialMedia.mail className={'w-6 h-6 lg:w-7 lg:h-7'} />
      //     )}
      //     <div className="text-base uppercase font-bold">
      //       {target === 'sms'
      //         ? 'SMS'
      //         : target === 'whatsapp'
      //         ? 'Whatsapp'
      //         : 'Email'}
      //     </div>
      //   </div>
      // ),
    };
    return option;
  });
}
