import { DiveSession } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubBoatLabel } from 'src/pages/_components/company';

export const ClubDiveSessionBoats = function <
  S extends Pick<DiveSession, 'boatsIds'>,
>({ diveSession: session, className }: { diveSession: S; className?: string }) {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume?.clubResume;

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const boats = useMemo(() => {
    return (session.boatsIds ?? [])
      .map((boatId) => clubResume?.boats.find((b) => b._id === boatId))
      .filter((x) => !!x);
  }, [clubResume?.boats, session.boatsIds]);

  return boats.length > 0 ? (
    <div
      className={clsx(
        'flex items-center gap-1 sm:gap-2 overflow-hidden',
        className,
      )}
    >
      {boats.map((boat) => (
        <div key={boat._id} className={'flex items-center gap-0.5 sm:gap-1'}>
          <ClubBoatLabel className="" clubBoat={boat} iconSize="w-3 h-3" />
        </div>
      ))}
    </div>
  ) : null;
};
