import { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { BookingResumeOnClickMemberAction } from '../../../../_from-diver-app';
import { PRO_BookingMemberFull_WithDocs } from '../../../models';

export function useBookingResumeMemberHamburgerMenuItems({
  onClick,
  bookingMemberFull,
  bookingMembersCount,
}: {
  onClick: (
    bookingMemberFull: PRO_BookingMemberFull_WithDocs,
    action: BookingResumeOnClickMemberAction,
  ) => void;
  bookingMemberFull: PRO_BookingMemberFull_WithDocs;
  bookingMembersCount: number;
}): AppHamburgerMenuItem[] {
  const az = useAppSecurityUserClubAuthorizations();

  return useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    if (onClick) {
      // const disableMessageIfHasChanges = hasChanges // certaines acitons ne fonctionnent que si aucune modification n'est en cours (backend-only)
      //   ? `Sauvegardez vos modification en cours pour activer cette action`
      //   : undefined;
      if (az.edit.participant.booking) {
        hamburgerMenuItems.push({
          title: 'Modifier',
          description: 'Nom, date de naissance, coordonnées, niveau de plongée',
          icon: AppHeroIcons.actionEdit,
          onClick: () => {
            onClick(bookingMemberFull, 'edit');
          },
        });

        // hamburgerMenuItems.push({
        //   title: 'Ajouter un plongeur',
        //   description: 'Ajouter un autre plongeur à cette réservation',
        //   icon: AppHeroIcons.actionAdd,
        //   onClick: () => {
        //     onClick(bookingMemberFull, 'add');
        //   },
        // });

        if (bookingMembersCount > 1) {
          // impossible si il n'y a plus qu'un seul plongeur dans cette réservation
          hamburgerMenuItems.push({
            icon: AppHeroIcons.actionExtract,
            title: 'Extraire le plongeur de cette réservation',
            description:
              'Ses plongées seront déplacées dans une nouvelle réservation',
            onClick: () => {
              onClick(bookingMemberFull, 'extract-from-booking');
            },
          });
        }
        hamburgerMenuItems.push({
          icon: AppHeroIcons.actionRemove,
          severity: 'danger',
          title: 'Supprimer le plongeur et ses plongées',
          description:
            'ATTENTION: les plongées seront définitivement supprimées',
          onClick: () => {
            onClick(bookingMemberFull, 'delete-member-with-participants');
          },
        });
      }
      // if (
      //   bookingMemberFull.diver.emailAddress ||
      //   bookingMemberFull.diver.phoneNumber
      // ) {
      //   hamburgerMenuItems.push({
      //     title: 'Détails',
      //     details: () => (
      //       <div className="mt-2 flex gap-4 flex-col sm:flex-row">
      //         {bookingMemberFull.diver.emailAddress && (
      //           <>
      //             <AppButton
      //               className="text-xs"
      //               color="mail-outline"
      //               icon={AppHeroIcons.contact}
      //               href={`mailto:${bookingMemberFull.diver.emailAddress?.trim()}`}
      //               size="normal"
      //               target="mabadive_mail_client"
      //             >
      //               <span className="">
      //                 {bookingMemberFull.diver.emailAddress}
      //               </span>
      //             </AppButton>
      //           </>
      //         )}
      //         {phoneNumberClean && (
      //           <AppButton
      //             fullWidth={true}
      //             className="text-xs whitespace-nowrap"
      //             icon={AppHeroIcons.phone}
      //             color="phone-outline"
      //             href={`tel:${phoneNumberClean}`}
      //             size="small"
      //             onClick={(e) => e.stopPropagation()}
      //           >
      //             <span className="">
      //               {bookingMemberFull.diver.phoneNumber}
      //             </span>
      //           </AppButton>
      //         )}
      //       </div>
      //     ),
      //   });
      // }
    }
    return hamburgerMenuItems;
  }, [
    az.edit.participant.booking,
    bookingMemberFull,
    bookingMembersCount,
    onClick,
  ]);
}
