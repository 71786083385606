/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  BookingParticipantEditorFormModelClubDiver,
  ClubDiver,
} from '@mabadive/app-common-model';
import { numberFormatter } from '@mabadive/app-common-services';

export const clubDiverFormParser = {
  parseFormValueDiver,
};

function parseFormValueDiver(
  formValue: BookingParticipantEditorFormModel,
): Partial<ClubDiver> {
  const formValueDiver: BookingParticipantEditorFormModelClubDiver =
    formValue.diver;

  const firstName = formValueDiver.firstName?.trim();
  const lastName = formValueDiver.lastName?.trim();

  const clubDiver: Partial<ClubDiver> = {
    ...formValueDiver,
    size: numberFormatter.convertFloatToInteger(formValueDiver.size, {
      multiplierIfDecimal: 100,
    }),
    weight: numberFormatter.convertFloatToInteger(formValueDiver.weight),
    firstName,
    lastName,
    emailAddress: trimOrNull(formValueDiver.emailAddress),
    phoneNumber: trimOrNull(formValueDiver.phoneNumber),
    emergencyContact: trimOrNull(formValueDiver.emergencyContact),
    defaultDiveConfig: formValueDiver.defaultDiveConfig,
  };
  clubDiver.divingCertification1 =
    clubDiver.divingCertification1?.gazCertificationReference ||
    clubDiver.divingCertification1?.mainCertificationReference ||
    clubDiver.divingCertification1?.safetyCertificationReferences?.length
      ? clubDiver.divingCertification1
      : null;

  clubDiver.divingCertification2 =
    clubDiver.divingCertification2?.gazCertificationReference ||
    clubDiver.divingCertification2?.mainCertificationReference ||
    clubDiver.divingCertification2?.safetyCertificationReferences?.length
      ? clubDiver.divingCertification2
      : null;

  clubDiver.mainCertificationReference =
    clubDiver.divingCertification1?.mainCertificationReference ??
    clubDiver.divingCertification2?.mainCertificationReference;

  clubDiver.homeAddress =
    [
      trimOrNull(clubDiver.homeAddress?.line1),
      trimOrNull(clubDiver.homeAddress?.postalCode),
      trimOrNull(clubDiver.homeAddress?.city),
      trimOrNull(clubDiver.homeAddress?.state),
      trimOrNull(clubDiver.homeAddress?.country),
    ].filter((x) => x?.length).length !== 0
      ? clubDiver.homeAddress
      : null;

  clubDiver.orgsMembers = (formValueDiver.orgsMembers ?? [])
    .filter((x) => !!x)
    .map((orgMember) => {
      if (orgMember.orgDiverId?.trim()?.length && orgMember.orgReference) {
        if (orgMember.orgReference === 'ffessm') {
          // replace ffessm spaces by '-'
          return {
            ...orgMember,
            orgDiverId: orgMember.orgDiverId
              .trim()
              .toUpperCase()
              .replaceAll(' ', '-'),
          };
        }

        return {
          ...orgMember,
          orgDiverId: orgMember.orgDiverId.trim(),
        };
      }
    })
    .filter((x) => !!x);

  return clubDiver;
}

function trimOrNull(str: string): string {
  str = str?.trim();
  if (str) {
    return str;
  }
  return null; // c'est important de retourner null, sinon le jsonPatch sur des champs vide va envoyer des replace+null inutiles
}
