import { useMemo } from 'react';

import { useAppSecurityUser } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';

export function useDiverPurchasePlanDialogLocalStateContext() {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const securityUser = useAppSecurityUser();
  const clubSettings = clubResume?.clubSettings;

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const isPaymentEnabled = useMemo(
    () => securityUser?.roles.includes('club-edit-participant-payment'),
    [securityUser.roles],
  );
  const context = {
    clubResume,
    diveCenterResume,
    securityUser,
    clubSettings,
    diveCenterId,
    clubReference,
    isPaymentEnabled,
  };

  return context;
}

export type DiverPurchasePlanDialogLocalStateContext = ReturnType<
  typeof useDiverPurchasePlanDialogLocalStateContext
>;
