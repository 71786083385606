import { AppBookletPageGql_Company } from '@mabadive/app-common-model';
import { jsonParser } from '@mabadive/app-common-services';
import { EcommerceProductEditorFormModel } from '../../EcommerceProductEditor/model';
import { EcommerceProductArticleEditorFormModel } from '../model';

export const ecommerceFormDefaultImageBuilder = {
  setDefaultImagesFromParent,
};

function setDefaultImagesFromParent<
  T extends
    | EcommerceProductArticleEditorFormModel
    | EcommerceProductEditorFormModel,
>({
  initialFormValueBase,
  parentBookletPage,
}: {
  initialFormValueBase: Partial<T>;
  parentBookletPage: AppBookletPageGql_Company;
}) {
  const defaultFormValue: Partial<T> = jsonParser.parseJSONWithDates(
    JSON.stringify(initialFormValueBase),
  );
  {
    // default content images
    const parentContent = parentBookletPage.mainContent.content;
    const currentContent = defaultFormValue.appBookletPage.mainContent.content;
    if (!currentContent.image && parentContent.image) {
      currentContent.image = parentContent.image;
    }
    if (!currentContent.image2 && parentContent.image2) {
      currentContent.image2 = parentContent.image2;
    }
  }
  {
    // default cover images
    const parentCover = parentBookletPage.mainContent.cover;
    const currentCover = defaultFormValue.appBookletPage.mainContent.cover;
    if (!currentCover.image && parentCover.image) {
      currentCover.image = parentCover.image;
    }
    if (!currentCover.image2 && parentCover.image2) {
      currentCover.image2 = parentCover.image2;
    }
  }
  return defaultFormValue;
}
