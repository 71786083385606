import {
  AppBooklet,
  AppBookletPage,
  AppEntityUpdatePatch,
  ClubEcommerceProduct,
  ClubEcommerceProductCategoryConfig,
  ProMultiOperationPayloadAction,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import {
  EcommerceProductEditorFormModel,
  EcommerceProductEditorFormModelEditValue,
} from './model';

export type EcommerceProductEditorPayload = Pick<
  ProMultiOperationPayloadAction,
  | 'ecommerceProduct'
  | 'ecommerceProductArticle'
  | 'ecommerceProductCategoryConfig'
  | 'appBooklet'
  | 'appBookletPage'
> & {
  hasChanges: boolean;
  actionContext: string;
};

export const ecommerceProductEditorResultBuilder = {
  buildCreatePayload,
  buildUpdatePayload,
};

function buildCreatePayload({
  formValue,
}: {
  formValue: EcommerceProductEditorFormModel;
}): EcommerceProductEditorPayload {
  const ecommerceProduct = commonEntityBuilder.buildEntity(
    buildEcommerceProductFromForm({
      formValue,
    }),
  );
  const categoryConfigs = formValue.categoryConfigs.map((categoryConfig) => {
    return commonEntityBuilder.buildEntity<ClubEcommerceProductCategoryConfig>({
      ...categoryConfig,
      productId: ecommerceProduct._id,
    });
  });
  const appBookletPage: Partial<AppBookletPage> =
    commonEntityBuilder.buildEntity(formValue.appBookletPage);

  const appBooklet: Partial<AppBooklet> = commonEntityBuilder.buildEntity(
    formValue.appBooklet,
  );

  const result: EcommerceProductEditorPayload = {
    hasChanges: true,
    actionContext: 'create product',
    ecommerceProduct: {
      created: [ecommerceProduct],
    },
    ecommerceProductCategoryConfig: {
      created: categoryConfigs,
    },
    appBooklet: {
      created: [appBooklet],
    },
    appBookletPage: {
      created: [appBookletPage],
    },
  };

  return result;
}
function buildUpdatePayload({
  editValue,
  formValue,
}: {
  editValue: EcommerceProductEditorFormModelEditValue;
  formValue: EcommerceProductEditorFormModel;
}): EcommerceProductEditorPayload {
  const result: EcommerceProductEditorPayload = {
    actionContext: 'update product',
    hasChanges: false,
    ecommerceProduct: {},
    ecommerceProductArticle: {},
    appBooklet: {},
    appBookletPage: {},
  };

  {
    let productPatch: AppEntityUpdatePatch = buildProductPatch({
      editValue,
      formValue,
    });
    if (productPatch) {
      result.ecommerceProduct.updated = [productPatch];
      result.hasChanges = true;
    }
  }

  {
    const patchOperations = jsonPatcher.compareObjects(
      editValue.appBooklet,
      {
        ...editValue.appBooklet,
        ...formValue.appBooklet,
      },
      {
        attributesToReplaceFully: [],
      },
    );
    if (patchOperations.length) {
      let patch: AppEntityUpdatePatch = {
        pk: editValue.appBooklet._id,
        patchOperations,
      };
      result.appBooklet.updated = [patch];
      result.hasChanges = true;
    }
  }
  {
    const patchOperations = jsonPatcher.compareObjects(
      editValue.appBookletPage,
      {
        ...editValue.appBookletPage,
        ...formValue.appBookletPage,
      },
      {
        attributesToReplaceFully: [],
      },
    );
    if (patchOperations.length) {
      let patch: AppEntityUpdatePatch = {
        pk: editValue.appBookletPage._id,
        patchOperations,
      };
      result.appBookletPage.updated = [patch];
      result.hasChanges = true;
    }
  }

  // NOTE: pour l'instant pas d'édition de categoryConfigPatches
  return result;
}

function buildProductPatch({
  editValue,
  formValue,
}: {
  editValue: EcommerceProductEditorFormModelEditValue;
  formValue: EcommerceProductEditorFormModel;
}) {
  let productPatch: AppEntityUpdatePatch;

  const productInitialValue: Partial<ClubEcommerceProduct> = {
    ...editValue.product,
  };

  const productFinalValue: Partial<ClubEcommerceProduct> = {
    ...productInitialValue,
    ...buildEcommerceProductFromForm({
      formValue,
    }),
  };

  const productPatchOperations = jsonPatcher.compareObjects(
    productInitialValue,
    productFinalValue,
    {
      attributesToReplaceFully: [],
    },
  );
  if (productPatchOperations.length) {
    productPatch = {
      pk: productInitialValue._id,
      patchOperations: productPatchOperations,
    };
  }
  return productPatch;
}

function buildEcommerceProductFromForm({
  formValue,
}: {
  formValue: EcommerceProductEditorFormModel;
}): Partial<ClubEcommerceProduct> {
  // const formEvent = formValue.ecommerceProduct;
  // const formSchedule = formEvent.schedule;
  // const singleOccurrenceDef: ClubEcommerceProductScheduleSingleOccurrenceDef =
  //   formSchedule.type === 'single' ? formSchedule.singleOccurrenceDef : null;
  // const weeklyRecurrenceDef: ClubEcommerceProductScheduleWeeklyRecurrenceDef =
  //   formSchedule.type === 'weekly-recurrence'
  //     ? formSchedule.weeklyRecurrenceDef
  //     : null;
  // const firstDate =
  //   formSchedule.type === 'single'
  //     ? singleOccurrenceDef.date
  //     : weeklyRecurrenceDef.beginDate;
  // const lastDate =
  //   formSchedule.type === 'single'
  //     ? singleOccurrenceDef.date
  //     : weeklyRecurrenceDef.endDate;
  // const timeSlot =
  //   formSchedule.timeMode === 'time-slot' ? formSchedule.timeSlot : undefined;
  // if (timeSlot) {
  //   // fix time if necessary
  //   if (timeSlot.beginTime?.hours < 0 || timeSlot.beginTime?.hours > 23) {
  //     timeSlot.beginTime.hours = 0;
  //   }
  //   if (timeSlot.beginTime.minutes < 0 || timeSlot.beginTime.minutes > 60) {
  //     timeSlot.beginTime.minutes = 0;
  //   }
  //   if (timeSlot.endTime?.hours < 0 || timeSlot.endTime?.hours > 23) {
  //     timeSlot.endTime.hours = 0;
  //   }
  //   if (timeSlot.endTime.minutes < 0 || timeSlot.endTime.minutes > 60) {
  //     timeSlot.endTime.minutes = 0;
  //   }
  // }
  // const schedule: ClubEcommerceProductSchedule = {
  //   ...formSchedule,
  //   timeSlot,
  //   singleOccurrenceDef,
  //   weeklyRecurrenceDef,
  // };
  const finalEcommerceProduct: Partial<ClubEcommerceProduct> = {
    ...formValue.product,
  };
  return finalEcommerceProduct;
}
