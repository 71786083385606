/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  BookingParticipantEditorFormModelClubParticipantBookingStatus,
  ParticipantActionUser,
  ParticipantBookingStatus,
} from '@mabadive/app-common-model';
import { participantBookingStatusFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { ColoredTagCard } from 'src/business/_core/modules/layout';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppMultiCheckboxesRHF } from 'src/lib/form/components/AppMultiCheckboxes';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';

export const PARTICIPANT_BOOKING_STATUS_OPTIONS: {
  id: BookingParticipantEditorFormModelClubParticipantBookingStatus;
  status: {
    value: ParticipantBookingStatus;
    user?: ParticipantActionUser;
  };
}[] = [
  {
    id: 'confirmed',
    status: {
      value: 'confirmed' as ParticipantBookingStatus,
    },
  },
  {
    id: 'pending-diver',
    status: {
      value: 'pending' as ParticipantBookingStatus,
      user: 'diver',
    },
  },
  {
    id: 'pending-club',
    status: {
      value: 'pending' as ParticipantBookingStatus,
      user: 'club',
    },
  },
  {
    id: 'cancelled-diver',
    status: {
      value: 'cancelled' as ParticipantBookingStatus,
      user: 'diver',
    },
  },
  {
    id: 'cancelled-club',
    status: {
      value: 'cancelled' as ParticipantBookingStatus,
      user: 'club',
    },
  },
];

export const BookingParticipantEditFormRHFGroup4 = ({
  form,
  maxWidthMd,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  maxWidthMd: boolean;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const services = clubResume.clubSettings.services;

  const { control, setValue } = form;

  const [diveMode, sessionsCount, diveTourSession2] = useWatch({
    control,
    name: [
      'clubParticipant.diveMode',
      'diveSession.sessionsCount',
      'diveSession.diveTourSession2',
    ],
  });

  const tagsOptions: ValueLabel<string, React.ReactNode>[] = useMemo(
    () =>
      clubResume.participantTags.map((tag) => ({
        value: tag.reference,
        label: <ColoredTagCard className="cursor-pointer" tag={tag} />,
      })),
    [clubResume.participantTags],
  );

  const bookingStatusOptions = useMemo(
    () =>
      PARTICIPANT_BOOKING_STATUS_OPTIONS.map((option) => ({
        value: option.id,
        label: participantBookingStatusFormatter.formatStatus(option.status),
      })),
    [],
  );

  return (
    <>
      {diveMode !== undefined && (
        <AppFormControlRHF_Deprecated
          className={' w-full'}
          label="Remarques sur cette plongée"
          control={control}
          name={'clubParticipant.comment' as any}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      )}
      {diveMode !== undefined && tagsOptions.length !== 0 && (
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label={'Tags'}
          control={control}
          name={'clubParticipant.tags' as any}
          renderComponent={(props) => (
            <AppMultiCheckboxesRHF {...props} options={tagsOptions} />
          )}
        />
      )}
      {diveMode !== undefined && (
        <div
          className={`sm:col-span-2 w-full flex flex-col sm:grid sm:grid-cols-2 gap-4 ${className}`}
        >
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label={'Infos réservation'}
            control={control}
            name={'clubParticipant.bookingStatus' as any}
            required={true}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={bookingStatusOptions}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Commentaire réservation"
            control={control}
            name={'clubParticipant.bookingComment' as any}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        </div>
      )}
    </>
  );
};
