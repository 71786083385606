import React, { useCallback } from 'react';
import { DiverPurchaseTrainingEditorDialogState } from '../../models';

export function useOpenCreateTrainingDialog({
  setTrainingEditorDialogState,
}: {
  setTrainingEditorDialogState: React.Dispatch<
    React.SetStateAction<DiverPurchaseTrainingEditorDialogState>
  >;
}) {
  return useCallback(
    ({
      diver,
      createContext,
      sessionsBillingResumes,
      aggregatedData,
    }: Pick<
      DiverPurchaseTrainingEditorDialogState,
      'diver' | 'createContext' | 'sessionsBillingResumes' | 'aggregatedData'
    >) => {
      return setTrainingEditorDialogState({
        isOpen: true,
        mode: 'create',
        diver,
        createContext,
        sessionsBillingResumes,
        aggregatedData,
      });
    },
    [setTrainingEditorDialogState],
  );
}
