/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  BookingProduct,
  ClubProductPackageMeta,
  ClubProductPackageOffer,
  ClubProductPackageOfferView,
  ClubPurchasePackage,
  ClubPurchasePackageCredits,
  ClubSettings,
} from '@mabadive/app-common-model';
import { repositoryEntityBuilder } from 'src/business/_core/data/repository/repositoryEntityBuilder.service';
import {
  BillingTabDiveSessionBillingResume,
  DiverPurchaseCommonEditorDialogResult,
  DiverPurchasePlanEditorDialogState,
} from '../../models';
import { bookingPagePackageConsumedCounter } from '../../services/02.update-state/services';
import {
  creditsCountBuilder,
  diverPurchasePackageBuilder,
} from '../DiverPurchaseCommonEditorDialog';
import { DiverPurchasePlanFormModel } from './components';
import { diverPurchasePlanEditorDialogUpdator } from './diverPurchasePlanEditorDialogUpdator.service';

export const diverPurchasePlanEditorDialogUpdateBuilder = {
  buildUpdateResult,
};

function buildUpdateResult({
  clubReference,
  diveCenterId,
  diverId,
  initialPurchasePackage,
  selectedOffer,
  selectedOfferProductPackageMeta,
  formValue,
  isDirty,
  isPaymentEnabled,
  state,
  updatedAssociatedBookingProductIds,
  initialDiveBookingProducts,
  clubSettings,
  billingResumes,
}: {
  clubReference: string;
  diveCenterId: string;
  diverId: string;
  initialPurchasePackage: ClubPurchasePackage;
  selectedOffer: ClubProductPackageOfferView;
  selectedOfferProductPackageMeta: ClubProductPackageMeta;
  formValue: DiverPurchasePlanFormModel;
  isDirty: boolean;
  isPaymentEnabled: boolean;
  state: DiverPurchasePlanEditorDialogState;
  updatedAssociatedBookingProductIds: string[];
  initialDiveBookingProducts: BookingProduct[];
  clubSettings: ClubSettings;
  billingResumes: BillingTabDiveSessionBillingResume[];
}): {
  hasChanges: boolean;
  result?: DiverPurchaseCommonEditorDialogResult;
} {
  const { creditsInitialCount, creditsAdditionalCount, consumedExternalCount } =
    formValue;
  // const consumedAppCount = updatedAssociatedBookingProductIds.length;

  const isSuccessivePackage =
    selectedOfferProductPackageMeta?.diveAttributes?.divePriceType ===
    'successive';
  const countSuccessiveAsSingle =
    bookingPagePackageConsumedCounter.testIfCountSuccessiveAsSingle({
      clubSettings,
      isSuccessivePackage,
    });

  const { associatedDiversIds, consumedAppCount } =
    bookingPagePackageConsumedCounter.buildConsumedAppCountOnBuildUpdateResume({
      clubSettings,
      billingResumes,
      assignedBookingProductsIds: updatedAssociatedBookingProductIds,
      diverId,
      countSuccessiveAsSingle,
    });

  const isUnitOffer =
    selectedOfferProductPackageMeta?.diveAttributes?.divesCount === 1;
  const unitQuantity = isUnitOffer ? formValue.unitQuantity : 1;
  const { consumedTotalCount, creditsRemainingCount, creditsTotalCount } =
    creditsCountBuilder.updateCounts({
      creditsInitialCount,
      creditsAdditionalCount,
      consumedExternalCount,
      consumedAppCount,
      unitQuantityAvailableInPackage: unitQuantity,
    });
  const credits: ClubPurchasePackageCredits = {
    consumedAppCount,
    creditsInitialCount,
    creditsAdditionalCount,
    consumedExternalCount: consumedExternalCount ?? 0,
    creditsTotalCount,
    consumedTotalCount,
    creditsRemainingCount,
  };

  if (state.mode === 'create') {
    const {
      totalPrice,
      unitPrice,
      unitQuantity,
      discountAmount,
      totalPriceBeforeDiscount,
      totalPriceExtraCosts,
      totalPriceThirdPartyCollect,
    } = diverPurchasePackageBuilder.buildTotalPrice({
      isPaymentEnabled,
      isUnitOffer,
      unitPrice: formValue.unitPrice,
      totalPriceThirdPartyCollect: formValue.totalPriceThirdPartyCollect,
      unitQuantity: formValue.unitQuantity,
      discountAmount: formValue.discountAmount,
      extraCosts: formValue.extraCosts,
    });

    const productPackageOffer: ClubProductPackageOffer = {
      reference: selectedOffer.reference,
      clubReference,
      price: isPaymentEnabled ? selectedOffer.price : undefined,
      productPackage: selectedOffer.productPackage,
      thirdPartyCollectPrice: selectedOffer.thirdPartyCollectPrice,
    };

    const newPurchasePackage: ClubPurchasePackage = {
      ...repositoryEntityBuilder.buildDefaultAttributes(),
      clubReference,
      diveCenterId,
      diverId,
      diverIds: associatedDiversIds,
      productPackageOffer,
      productPackageType: 'dive',
      extraCosts: formValue.extraCosts,
      credits,
      purchaseDate: formValue.purchaseDate,
      validityStatus: formValue.validityStatus,
      comment: formValue.comment,
      payedAmount: 0,
      purchasePaymentStatus: 'todo',
      isUnitOffer,
      price: totalPrice,
      unitPrice,
      totalPriceThirdPartyCollect,
      unitQuantity,
      discountAmount,
      totalPriceBeforeDiscount,
      totalPriceExtraCosts,
      countSuccessiveAsSingle,
      billedByBookingAgencyId: formValue.billedByBookingAgencyId,
      bookingId: formValue.bookingId,
    };
    const updatedProducts: AppEntityUpdatePatch[] =
      diverPurchasePlanEditorDialogUpdator.buildUpdatedProductPatches({
        initialDiveBookingProducts,
        updatedAssociatedBookingProductIds,
        purchasePackageId: newPurchasePackage._id,
      });
    return {
      hasChanges: true,
      result: {
        newPurchasePackage,
        updatedProducts,
      },
    };
  } else if (state.mode === 'edit') {
    // if (!isDirty) {
    // NOTE: on ne peut plus faire ça, car les updatedProducts ne sont pas gérés par le formulaire! TODO FIXME
    //   return {
    //     hasChanges: false,
    //   };
    // }
    const patchOperations =
      diverPurchasePlanEditorDialogUpdator.buildUpdatePatches({
        clubReference,
        formValue,
        initialPurchasePackage,
        selectedOffer,
        selectedOfferProductPackageMeta,
        isPaymentEnabled,
        credits,
        associatedDiversIds,
      });
    let updatedClubPurchasePackage: AppEntityUpdatePatch;
    if (patchOperations.length) {
      updatedClubPurchasePackage = {
        pk: initialPurchasePackage._id,
        patchOperations,
      };
    }

    const updatedProducts: AppEntityUpdatePatch[] =
      diverPurchasePlanEditorDialogUpdator.buildUpdatedProductPatches({
        initialDiveBookingProducts,
        updatedAssociatedBookingProductIds,
        purchasePackageId: initialPurchasePackage._id,
      });
    if (updatedClubPurchasePackage || updatedProducts.length) {
      return {
        hasChanges: true,
        result: {
          updatedClubPurchasePackage,
          updatedProducts,
        },
      };
    }
  }

  return {
    hasChanges: false,
  };
}
