import { useMemo } from 'react';
import { useSideMenu } from '../components/SideMenu/useSideMenu.hook';
import { useWindowSize } from './useWindowSize.hook';

export function usePageWidth({
  ignoreMenu = false,
  maxWidth,
}: {
  ignoreMenu: boolean;
  maxWidth?: number;
}) {
  const sideMenu = useSideMenu();
  const windowsSize = useWindowSize();

  const pageWidth = useMemo(() => {
    const w = ignoreMenu
      ? windowsSize.width
      : windowsSize.width - sideMenu.menuWidth;
    if (maxWidth > 0 && maxWidth < w) {
      return maxWidth;
    }
    return w;
  }, [ignoreMenu, maxWidth, sideMenu.menuWidth, windowsSize.width]);

  return pageWidth;
}
