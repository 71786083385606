/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { useParticipantBackUrl } from 'src/business/club/modules/url-builders';
import { useRedirect } from 'src/business/_core/data/hooks';
import { CreateBookingInitialPlanPageLocalStateLocalState } from './useCreateBookingInitialPlanPageLocalState.hook';
export function useCreateBookingInitialPlanNavigation({
  localState,
}: {
  localState: CreateBookingInitialPlanPageLocalStateLocalState;
}): {
  goBack: () => void;
  goNext: () => void;
} {
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const {
    form,
    hasFormChanges,
    navigation: { currentStep, setCurrentStep, steps },
    navigationContext,
    createBooking,
    registrationMode,
  } = localState;

  const { register, handleSubmit, watch, control, formState, setValue, reset } =
    form;

  const backUrl = useParticipantBackUrl({
    navigationContext,
  });

  const back = useCallback(() => {
    redirectTo(backUrl);
  }, [backUrl, redirectTo]);

  const goBack = useCallback(() => {
    // pas besoin que ça soit valide pour aller en arrière
    if (currentStep === 'contact') {
      back();
    } else if (currentStep === 'certification') {
      setCurrentStep('contact');
    } else if (currentStep === 'participants') {
      setCurrentStep('contact');
    } else {
      setCurrentStep('participants');
    }
  }, [back, currentStep, setCurrentStep]);

  const goNext = useCallback(() => {
    if (formState.isValid) {
      if (currentStep === 'contact') {
        if (registrationMode === 'single') {
          setCurrentStep('certification');
        } else {
          // multiple
          setCurrentStep('participants');
        }
      } else if (currentStep === 'participants') {
        setCurrentStep('booking');
      } else {
        // single/certification or multiple/booking
        createBooking();
      }
    }
  }, [
    createBooking,
    currentStep,
    formState.isValid,
    registrationMode,
    setCurrentStep,
  ]);

  return {
    goBack,
    goNext,
  };
}
