/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveServiceTrainingCommercialCategoryId } from '@mabadive/app-common-model';
import { ReactNode, useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
//import { RegionalTaxLabel } from '../company/RegionalTax/RegionalTaxLabel';
import { trainingCommercialCategoryFormatter } from '@mabadive/app-common-services';
import { ClubSettingsProductOfferTagsTrainingCommercialCategory } from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog/useClubSettingsProductOffersTags.hook';

export function useTrainingCommercialCategoriesOptions(
  trainingCategories: ClubSettingsProductOfferTagsTrainingCommercialCategory[],
): ValueLabel<DiveServiceTrainingCommercialCategoryId, ReactNode>[] {
  const trainingCommercialCategoriesOptions: ValueLabel<
    DiveServiceTrainingCommercialCategoryId,
    ReactNode
  >[] = useMemo(
    () =>
      trainingCategories.map((x) => ({
        value: x.commercialCategoryId,
        label: `${trainingCommercialCategoryFormatter.formatCommercialCategory(
          x.commercialCategoryId,
        )} (${x.quantity})`,
      })),
    [trainingCategories],
  );

  return trainingCommercialCategoriesOptions;
}
