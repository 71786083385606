/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveSession,
  MultipleDiveSessionNumber,
} from '@mabadive/app-common-model';
import { arrayBuilder } from '@mabadive/app-common-services';
import { useMemo } from 'react';

export function useMultipleDiveSessionNumbers({
  diveSession,
}: {
  diveSession: Pick<DiveSession, 'sessionsCount'>;
}): MultipleDiveSessionNumber[] {
  return useMemo(() => {
    const sessionNumbers: MultipleDiveSessionNumber[] =
      arrayBuilder.buildSeries({
        count: diveSession?.sessionsCount,
        first: 1,
      });

    return sessionNumbers;
  }, [diveSession?.sessionsCount]);
}
