/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppToogleButtonGroup } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { ValueLabel } from '../../../_common/form';
import {
  PlanningViewConfig,
  PlanningViewConfigPeriod,
} from '../../../club-planning/_model';
import { SetPlanningConfigOptions } from '../../hooks';
import { ClubPlanningDisplayConfigurationBarContext } from '../ClubPlanningDisplayConfigurationBarContext.type';
import { clubPlanningViewModesOptionsBuilder } from './clubPlanningViewModesOptionsBuilder.service';

export const ClubPlanningViewPeriodToggleButton = ({
  context,
  planningConfig,
  setPlanningConfig,
  className = '',
}: {
  context: ClubPlanningDisplayConfigurationBarContext;
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  className?: string;
}) => {
  const mediaSize = useMediaSizeTailwind();

  const options: ValueLabel<PlanningViewConfigPeriod, string>[] = useMemo(
    () =>
      clubPlanningViewModesOptionsBuilder.buildOptions({
        context,
        mediaSize,
        planningConfig,
      }),
    [context, mediaSize, planningConfig],
  );

  const onChangeViewPeriod = useCallback(
    ({
      viewPeriod: newViewPeriod,
    }: {
      viewPeriod: PlanningViewConfigPeriod;
    }) => {
      const newPlanningConfig: PlanningViewConfig = {
        ...planningConfig,
        viewPeriod: newViewPeriod,
      };
      if (planningConfig.viewPeriod === 'week' && newViewPeriod === 'day') {
        // switch from week to day
        switch (planningConfig.viewWeekOptions.displayMode) {
          case 'staff-presence':
          case 'staff-roles':
          case 'staff-sessions': {
            // keep config
            newPlanningConfig.viewDayOptions = {
              ...planningConfig.viewDayOptions,
              displayMode: planningConfig.viewWeekOptions.displayMode,
            };
            break;
          }
        }
      } else if (
        planningConfig.viewPeriod === 'day' &&
        newViewPeriod === 'week'
      ) {
        // switch from day to week
        switch (planningConfig.viewDayOptions.displayMode) {
          case 'staff-presence':
          case 'staff-roles':
          case 'staff-sessions': {
            // keep config
            newPlanningConfig.viewWeekOptions = {
              ...planningConfig.viewWeekOptions,
              displayMode: planningConfig.viewDayOptions.displayMode,
            };
            break;
          }
        }
      }
      setPlanningConfig(newPlanningConfig, {
        origin: '[ClubPlanningViewPeriodToggleButton] viewPeriod.onChange',
      });
    },
    [planningConfig, setPlanningConfig],
  );

  return (
    <>
      <AppSingleSelect2HeadlessUI
        className={clsx(
          'sm:hidden',
          context === 'planning-staff-page' ? 'w-32' : 'w-24 sm:w-32 ',
          className,
        )}
        color="blue"
        theme="tailwind"
        disableClearButton={true}
        showIcons={false}
        options={options}
        value={planningConfig?.viewPeriod}
        onChange={(viewPeriod) => {
          onChangeViewPeriod({ viewPeriod });
        }}
      />
      <AppToogleButtonGroup
        className={clsx('hidden sm:flex', className)}
        color="blue"
        theme="strong"
        items={options}
        selected={planningConfig?.viewPeriod}
        onChanges={(viewPeriod) => {
          onChangeViewPeriod({ viewPeriod });
        }}
      />
    </>
  );
};
