import {
  ClubParticipant,
  ClubParticipantSpecialDiveType,
  DiveCenterResume,
  DiveMode,
  DiveServiceOfferSupervision,
  DiveSessionTheme,
  DiveTrainingReference,
} from '@mabadive/app-common-model';
import { PRO_BookingParticipantFull } from '../../../../../models';

export const virtualParticipantPurchaseKeyBuilder = {
  buildVirtualGroupBaseKey,
};

function buildVirtualGroupBaseKey({
  p,
  exploDistanceGroups,
  specialDiveType,
  diveSessionTheme,
  diveCenterResume,
}: {
  p: PRO_BookingParticipantFull;
  exploDistanceGroups: { minDistance?: number; maxDistance?: number }[];
  specialDiveType: ClubParticipantSpecialDiveType;
  diveSessionTheme: DiveSessionTheme;
  diveCenterResume: DiveCenterResume;
}): string {
  const explorationsGroupsCriteria =
    diveCenterResume.clubResume.clubSettings.general?.billing?.explorations
      ?.groupsCriteria;

  const groupAutonomousAndSupervised =
    explorationsGroupsCriteria?.groupAutonomousAndSupervised;

  const {
    diveSessionParticipant,
    bookingSessionParticipant,
    bookingProductDive,
    diveSession,
  } = p;

  const diveMode = diveSessionParticipant.diveMode;

  const diveTypeId = buildDiveTypeId({
    groupAutonomousAndSupervised,
    diveMode,
    diveSessionParticipant,
  });

  const hasSupervision = bookingProductDive.attributes.hasSupervision;

  let planSupervision: DiveServiceOfferSupervision;
  if (
    groupAutonomousAndSupervised &&
    (diveMode === 'autonomous' ||
      diveMode === 'supervised' ||
      diveMode === 'instructor')
  ) {
    planSupervision = 'any-supervision';
  } else {
    planSupervision =
      diveMode !== 'training' && diveMode !== 'theoretical-training'
        ? hasSupervision
          ? 'supervised'
          : 'autonomous'
        : undefined;
  }

  const isArchived = bookingProductDive.purchaseStatus === 'archived';

  let successiveDivesCount: number = undefined;
  let minDistance: number = undefined;
  let maxDistance: number = undefined;

  if (diveMode !== 'training' && diveMode !== 'theoretical-training') {
    if (explorationsGroupsCriteria?.multiSessions) {
      successiveDivesCount = !!diveSession.diveTourSession2 ? 2 : 1;
    }
    if (
      explorationsGroupsCriteria?.distance &&
      exploDistanceGroups?.length &&
      diveSession.diveSiteId
    ) {
      const diveSite = diveCenterResume?.diveSites.find(
        (s) => s._id === diveSession.diveSiteId,
      );

      if (diveSite?.distance > 0) {
        const group = exploDistanceGroups.find(
          (g) =>
            (!g.minDistance || g.minDistance <= diveSite?.distance) &&
            (!g.maxDistance || g.maxDistance > diveSite?.distance),
        );
        if (group) {
          minDistance = group.minDistance;
          maxDistance = group.maxDistance;
        }
      }
    }
  }

  const key =
    diveMode === 'training' || diveMode === 'theoretical-training'
      ? `${diveTypeId}-${isArchived}`
      : // : `${diveTypeId}-${diverId}-${hasSupervision}-${hasEquipment}`
        `${diveTypeId}-${isArchived}-${planSupervision}-${successiveDivesCount}-${minDistance}-${successiveDivesCount}-${
          specialDiveType ?? 'no-special-type'
        }-${diveSessionTheme ?? 'no-theme'}`;

  return key;
}

function buildDiveTypeId({
  groupAutonomousAndSupervised,
  diveMode,
  diveSessionParticipant,
}: {
  groupAutonomousAndSupervised: boolean;
  diveMode: DiveMode;
  diveSessionParticipant: ClubParticipant;
}) {
  if (
    groupAutonomousAndSupervised &&
    (diveMode === 'autonomous' || diveMode === 'instructor')
  ) {
    return 'supervised' as DiveMode; // on traite les autonomes comme des supervisés
  }
  const diveTypeId =
    diveMode === 'training' || diveMode === 'theoretical-training'
      ? (diveSessionParticipant.trainingReference as DiveTrainingReference)
      : diveMode;
  return diveTypeId;
}
