import { AppCurrency } from '@mabadive/app-common-model';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DiveSessionEditorMassiveDialogProps } from './_model';

export type DiveSessionEditorMassiveDialogActionPersistProps = {
  // onCreate: ({ bookingDeposit }: { bookingDeposit: BookingDeposit }) => any;
  // onUpdate: (props: {
  //   bookingDeposit: BookingDeposit;
  //   patch: AppEntityUpdatePatch;
  // }) => any;
};

export function useDiveSessionEditorMassiveDialogActionPersist(
  props: DiveSessionEditorMassiveDialogActionPersistProps,
): DiveSessionEditorMassiveDialogProps {
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  const appCurrency: AppCurrency = useAppCurrencyMain({ generalSettings });

  const action: DiveSessionEditorMassiveDialogProps = {
    async onConfirm({ formValue }, initialState) {
      //   const { mode, defaultValue } = initialState;
      //   if (mode === 'create') {
      //     const bookingDeposit: BookingDeposit =
      //       commonEntityBuilder.buildEntity<BookingDeposit>({
      //         clubReference,
      //         diveCenterId,
      //         ...formValue.bookingDeposit,
      //         currencyIsoCode: appCurrency.isoCode,
      //         creditRemaining: formValue.bookingDeposit.creditInitial,
      //       });
      //     if (props?.onCreate) {
      //       props?.onCreate({ bookingDeposit });
      //     }
      //   } else if (mode === 'edit') {
      //     const bookingDepositPatchOperations = jsonPatcher.compareObjects(
      //       defaultValue.bookingDeposit,
      //       {
      //         ...formValue.bookingDeposit,
      //         creditRemaining:
      //           formValue.bookingDeposit.creditInitial -
      //           formValue.bookingDeposit.creditUsed,
      //       },
      //       {
      //         // else, value won't be deleted by typeorm
      //         // https://github.com/typeorm/typeorm/issues/2934
      //         replaceDeleteByNullValue: true,
      //       },
      //     );
      //     if (bookingDepositPatchOperations.length) {
      //       const patch: AppEntityUpdatePatch = {
      //         pk: defaultValue.bookingDeposit._id,
      //         patchOperations: bookingDepositPatchOperations,
      //       };
      //       const payload: ProMultiOperationPayload =
      //         clubMassiveUpdatorClient.createEmptyPayload({
      //           bookingIdsToClean: [],
      //         });
      //       payload.bookingDeposits.updated.push(patch);
      //       if (props?.onUpdate) {
      //         props?.onUpdate({
      //           bookingDeposit: formValue.bookingDeposit as BookingDeposit,
      //           patch,
      //         });
      //       } else {
      //       }
      //     }
      //   }
    },
  };
  return action;
}
