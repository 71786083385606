import { ClubBoat } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIconsBoatDeparture } from 'src/business/_core/modules/layout/icons';
import { AppIcon } from 'src/business/_core/modules/layout/icons/AppIcon.type';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

export type ClubBoatLabelBoat = Pick<
  ClubBoat,
  'color' | 'name' | 'isSpecial' | 'departureType' | 'details'
>;

export const ClubBoatLabel = ({
  clubBoatId,
  clubBoat: clubBoatInput,
  iconSize = 'w-6 h-6',
  showIcon = true,
  format = 'long',
  className,
}: {
  clubBoatId?: string;
  iconSize?: string;
  showIcon?: boolean;
  clubBoat?: ClubBoatLabelBoat;
  format?: 'short' | 'long';
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const clubBoat: ClubBoatLabelBoat = useMemo(
    () =>
      clubBoatId
        ? diveCenterResume.boats.find((x) => x._id === clubBoatId)
        : clubBoatInput,
    [clubBoatId, clubBoatInput, diveCenterResume.boats],
  );

  const Icon: AppIcon = AppIconsBoatDeparture[clubBoat?.departureType];
  return !clubBoat ? null : (
    <div className={clsx('flex gap-1 items-stretch', className)}>
      {showIcon && (
        <span
          className=" border flex items-center py-px px-pt"
          style={{
            background: '#fff',
            color: clubBoat.color,
            borderColor: clubBoat.color,
          }}
        >
          <Icon className={iconSize} />
        </span>
      )}
      <span
        className="px-1 font-bold truncate uppercase flex items-center"
        style={
          clubBoat.isSpecial
            ? {
                background: clubBoat.color,
                color: '#fff',
              }
            : {
                color: '#555',
              }
        }
      >
        {format === 'long' ? clubBoat.name : clubBoat.details?.shortName}
      </span>
    </div>
  );
};
