import { ColoredTag } from '@mabadive/app-common-model';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React from 'react';
import { AppIconsMaterial } from '../../icons';

export type ColoredTagAvatarCardSize = 'micro' | 'small' | 'normal';

export type ColoredTagAvatarCardProps = {
  tag: ColoredTag;
  size: Pick<ColoredTagAvatarCardSize, 'small'>; // NOTE: tailles et classes à adapter lors du remplacement progressif de ColoredTagAvatarCardDeprecated
  className?: string;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'className'
>;

export const ColoredTagAvatarCard = ({
  tag,
  size = 'normal',
  className,
  ...otherAttributes
}: ColoredTagAvatarCardProps) => {
  const NewIcon = tag.avatar.icon;
  const OldIcon = NewIcon
    ? undefined
    : AppIconsMaterial[tag.avatar.appIcon as keyof typeof AppIconsMaterial];

  if (!size) {
    size = 'normal';
  }

  return !tag ? null : (
    <Tippy
      disabled={!tag.tooltip}
      delay={[100, 100]}
      placement="top"
      content={tag.tooltip}
    >
      <div>
        {/* il faut un div parent sinon erreur: https://github.com/atomiks/tippyjs-react/issues/49 */}
        {NewIcon ? (
          <NewIcon
            {...otherAttributes}
            className={clsx(
              tag.buildWidthClass
                ? tag.buildWidthClass()
                : size === 'micro'
                ? clsx('h-2', !tag.widthAuto && 'w-2')
                : size === 'small'
                ? clsx('h-3.5', !tag.widthAuto && 'w-3.5')
                : clsx('h-5', !tag.widthAuto && 'w-5'),
              className,
            )}
            style={{
              color: tag.avatar.color,
              background: tag.avatar.background,
            }}
            // title={tag.content.label}
          />
        ) : (
          <div
            {...otherAttributes}
            className={clsx(
              'flex items-center justify-center',
              (tag.shape ?? 'round') === 'round' && 'rounded-xl',
              tag.buildWidthClass
                ? tag.buildWidthClass()
                : clsx(
                    size === 'small' &&
                      clsx(
                        'font-bold mt-0.5 text-app-xxs h-3',
                        !tag.widthAuto && 'w-3',
                      ),
                    size === 'normal' &&
                      clsx(
                        'font-bold text-xs h-3.5',
                        !tag.widthAuto && 'w-3.5',
                      ),
                  ),
              tag.widthAuto && 'px-0.5',
              className,
            )}
            style={{
              color: tag.avatar.color,
              background: tag.avatar.background,
            }}
            // title={tag.content.label}
          >
            {size !== 'micro' ? OldIcon ? <OldIcon /> : tag.avatar.label : null}
          </div>
        )}
      </div>
    </Tippy>
  );
};
