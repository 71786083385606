// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import { ClubDiverFull } from '@mabadive/app-common-model';
import {
  dateService,
  nameFormatter,
  search,
} from '@mabadive/app-common-services';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import React, { useCallback, useMemo } from 'react';
import { useLoadableContent } from 'src/business/_core/data/app-loading';
import { clubDiverFullSearchRepository } from 'src/business/_core/data/store-repository';
import { useClubResume } from 'src/business/club/data/hooks';
import { appSingleAutocompleteChunkify } from 'src/lib/form/components/AppSingleAutocomplete';

export const SelectDiverComponent = ({
  placeholder,
  autoFocus,
  onChange,
  diversIdsToIgnore = [],
  diversToSuggest = [],
  searchSuggestions = [],
  className = '',
}: {
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: (diver: ClubDiverFull) => void;
  diversIdsToIgnore?: string[];
  diversToSuggest?: ClubDiverFull[];
  searchSuggestions?: string[];
  className?: string;
}) => {
  const [open, setOpen] = React.useState(false);
  const [fullText, setFullText] = React.useState('');

  const clubResume = useClubResume();

  const { content: loadableDivers, ...loadableContent } = useLoadableContent(
    () =>
      clubDiverFullSearchRepository.findByFilters({
        clubReference: clubResume.reference,
        fullText,
        mainCertificationReference: undefined,
        searchAttributes: [
          'firstName',
          'lastName',
          'phoneNumber',
          'emailAddress',
        ],
        minLettersForRemoteSearch: 2,
        maxLastUpdateRemoteResultsIfRemoteSearchIsSkipped: 50,
        maxReturnResults: 50,
      }),
    [clubResume.reference, fullText],
    {
      initialValue: {
        content: [],
        contentState: 'none',
        lastActionStatus: 'in-progress',
      },
    },
  );

  const filteredDivers: ClubDiverFull[] = useMemo(() => {
    const f = loadableDivers.filter((d) => !diversIdsToIgnore.includes(d._id));
    if (fullText?.trim().length === 0) {
      return diversToSuggest.concat(f);
    }
    return f;
  }, [diversIdsToIgnore, diversToSuggest, fullText, loadableDivers]);

  const loading = open && loadableContent?.contentState !== 'full';

  const filterOptions = useCallback(
    (options: ClubDiverFull[], state: FilterOptionsState<ClubDiverFull>) => {
      return options.slice(0, 10);
    },
    [],
  );

  return (
    <div className={`flex gap-5 ${className}`}>
      <Autocomplete
        key={diversIdsToIgnore?.length}
        className="flex-grow"
        clearText="Effacer"
        loadingText="Recherche en cours"
        noOptionsText={
          <p className="text-sm uppercase text-app-blue font-bold">
            Aucun plongeur trouvé
            <span className="hidden sm:inline text-xs text-gray-600">
              {' '}
              (appuyez sur la touche TAB pour créer le plongeur)
            </span>
          </p>
        }
        openText="Ouvrir"
        closeText="Fermer"
        filterOptions={filterOptions}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(e, diver) => {
          if (diver) {
            onChange(diver as unknown as ClubDiverFull);
            // setFullText('');
          }
        }}
        getOptionSelected={(option, value) => option._id === value?._id}
        getOptionLabel={(diver) => nameFormatter.formatFullName(diver)}
        renderOption={(diver) => (
          <div className="flex justify-between w-full">
            <div className="flex-grow block">
              <Typography color="primary">
                {appSingleAutocompleteChunkify(
                  nameFormatter.formatFullName(diver),
                  fullText,
                )}
                {diver.phoneNumber && (
                  <span className="ml-2 text-gray-400">
                    {diver.phoneNumber}
                  </span>
                )}
                {diver.birthdate && (
                  <span className="ml-2 text-gray-400">
                    né le {dateService.formatUTC(diver.birthdate, 'DD/MM/YYYY')}
                  </span>
                )}
                {diver._createdAt && (
                  <span className="ml-2 text-gray-400">
                    fiche créée le &nbsp;
                    {dateService.formatUTC(diver._createdAt, 'DD/MM/YYYY')}
                  </span>
                )}
              </Typography>
              {/* <div className="flex">
              {diver.divesMeta.lastDiveTime || diver.divesMeta.nextDiveTime ? (
                <>
                  (
                  {diver.divesMeta.lastDiveTime && (
                    <Typography color="textSecondary">
                      dernière plongée au club:
                      <b>
                        {dateService.formatUTC(
                          diver.divesMeta.lastDiveTime,
                          'DD MMMM YYYY',
                        )}
                      </b>
                    </Typography>
                  )}
                  {diver.divesMeta.nextDiveTime && (
                    <Typography color="textSecondary">
                      prochaine plongée au club:
                      <b>
                        {dateService.formatUTC(
                          diver.divesMeta.nextDiveTime,
                          'DD MMMM YYYY',
                        )}
                      </b>
                    </Typography>
                  )}
                  )
                </>
              ) : (
                <Typography color="textSecondary">
                  N'a jamais plongé au club
                </Typography>
              )}
            </div> */}
            </div>
          </div>
        )}
        options={filteredDivers}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            autoFocus={autoFocus}
            variant="outlined"
            onChange={(event) => {
              setFullText(event.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {searchSuggestions.length !== 0 && (
        <div className="hidden sm:block">
          <div className="text-gray-600 text-xs">
            RECHERCHE RAPIDE ({searchSuggestions.length})
          </div>
          <select
            id="tabs"
            name="tabs"
            tabIndex={500}
            className="block w-full focus:ring-app-blue focus:border-app-blue border-gray-300 rounded-xs text-sm"
          >
            <option></option>
            {searchSuggestions.map((s, i) => (
              <option
                key={i}
                value={i}
                onClick={() => {
                  setFullText(s);
                  setOpen(true);
                }}
              >
                {s}
              </option>
            ))}
          </select>
          {/* {searchSuggestions.map((s, i) => (
            <div
              key={i}
              className="mx-2 p-1 border border-app-blue text-app-blue cursor-pointer"
              onClick={() => {
                setFullText(s);
                setOpen(true);
              }}
            >
              {s}
            </div>
          ))} */}
        </div>
      )}
      {/* {diversToSuggest.length !== 0 && (
        <div className="hidden sm:block">
          <div className="text-gray-600 text-xs">
            SUGGESTIONS ({diversToSuggest.length})
          </div>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-app-blue focus:border-app-blue border-gray-300 rounded-xs text-sm"
          >
            <option></option>
            {diversToSuggest.map((diver, i) => (
              <option
                key={i}
                value={i}
                onClick={() => {
                  onChange(diver);
                  setOpen(false);
                }}
              >
                {nameFormatter.formatFullName(diver, {
                  format: 'firstName-first',
                })}
              </option>
            ))}
          </select>
        </div>
      )} */}
    </div>
  );
};

function filterSuggestions<S>(
  searchSuggestions: S[],
  {
    searchText,
    filterAttributes,
    filterMaxResults,
  }: {
    searchText: string;
    filterAttributes: (keyof S)[];
    filterMaxResults?: number;
  },
): S[] {
  const maxResults =
    filterMaxResults && filterMaxResults > 0 ? filterMaxResults : 50;

  if (!searchText || !searchText.trim().length) {
    return searchSuggestions.slice(0, maxResults);
  }

  return search.filter(searchSuggestions, {
    searchText,
    attributesNames: filterAttributes,
    maxResults: maxResults,
    sortResultsByBestMatch: true,
  });
}
