/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubAuthUserProfile } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { staffMemberOptionsBuilder } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/services';
import {
  AppFormControlRHF_Deprecated,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { SettingsUserAccount } from '../ClubSettingsUserAccountsListPage/model';

export const ClubSettingsUserAccountEditForm = ({
  clubProfiles,
  form,
  isMyself,
  mode,
  className,
}: {
  clubProfiles: ClubAuthUserProfile[];
  form: UseFormReturn<SettingsUserAccount, object>;
  isMyself: boolean;
  mode: 'create' | 'edit';
  className?: string;
}) => {
  const clubResume = useClubResume();

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubProfilesOptions = clubProfiles.map((clubProfile) => {
    const option: ValueLabel<string> = {
      label: clubProfile.label.toUpperCase(),
      value: clubProfile._id,
    };
    return option;
  });

  const [clubStaffMemberId] = useWatch({
    control,
    name: ['clubStaffMemberId'],
  });

  const staffMembers = useMemo(
    () =>
      (clubResume?.staffMembers ?? []).filter(
        (x) =>
          !x.endDate ||
          new Date() < new Date(x.endDate) ||
          x._id === clubStaffMemberId,
      ),
    [clubResume?.staffMembers, clubStaffMemberId],
  );

  const staffMemberOptions = useMemo(
    () =>
      staffMemberOptionsBuilder.buildStaffMemberOptionsString({
        staffMembers,
      }),
    [staffMembers],
  );

  return (
    <div className={clsx('flex flex-col gap-4', className)}>
      <div
        className={clsx(
          'w-full flex flex-col sm:grid sm:grid-cols-2 lg:grid-cols-4 gap-4',
        )}
      >
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Prénom"
          control={control}
          required={true}
          name={'firstName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Nom"
          control={control}
          name={'lastName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Profil utilisateur"
          required={true}
          disabled={isMyself}
          control={control}
          name={'authUser.clubProfileId'}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={clubProfilesOptions}
              fullWidth
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Staff / moniteur correspondant"
          helpDescription="Associez ce compte utilisateur à un moniteur"
          required={false}
          disabled={isMyself}
          control={control}
          name={'clubStaffMemberId'}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={staffMemberOptions}
              fullWidth
            />
          )}
        />
      </div>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 lg:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Adresse e-mail"
          required={true}
          control={control}
          validation={{
            email: true,
          }}
          name={'emailAddress'}
          renderComponent={(props) => (
            <AppInputRHF
              {...props}
              fullWidth
              type="text"
              onChange={(value) => {
                if (mode === 'create') {
                  setValue('authUser.login', value as string);
                }
              }}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Téléphone"
          control={control}
          name={'phoneNumber'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Identifiant"
          required={true}
          disabled={mode !== 'create'}
          control={control}
          validation={{
            rules: {
              minLength: 4,
            },
          }}
          name={'authUser.login'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Fonction / poste"
          control={control}
          name={'staffRole'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>
    </div>
  );
};
