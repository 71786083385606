import { useCallback, useEffect } from 'react';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { useRedirect } from 'src/business/_core/data/hooks';
import { authenticationClient } from 'src/business/auth/services';
import useRouter from 'use-react-router';
import { shortLinkResolverClient } from './shortLinkResolverClient.service';

export const ShortLinkResolverComponent = () => {
  const { match } =
    useRouter<{
      aliasKey: string;
    }>();

  const redirectTo = useRedirect();

  const resolveShortLinkAndRedirect = useCallback(async () => {
    const aliasKey = match.params.aliasKey;

    if (aliasKey) {
      const result = await shortLinkResolverClient.resolveKey(aliasKey);

      // if (result?.targetDomain === appWebConfig.applications.diverWebUrl) {
      const urlString = `${result?.targetDomain}${result.targetRelativeUrl}`;

      const url = new URL(urlString);
      const token = url.searchParams.get('auth-token');

      if (token) {
        // try to authenticate
        await authenticationClient
          .authenticateByToken(token)
          .pipe(
            catchError((err) => {
              // invalid token: ignore it
              return of(undefined);
            }),
          )
          .toPromise();
        url.searchParams.delete('auth-token');
      }
      const redirectUrlSting = `${url.pathname}?${url.searchParams.toString()}`;
      redirectTo(redirectUrlSting, { replace: true });
    }
    // }
    // only execute once when loading component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resolveShortLinkAndRedirect();
  }, [resolveShortLinkAndRedirect]);

  return null as React.ReactElement;
};
