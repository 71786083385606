import { APP_COLORS } from '@mabadive/app-common-model';
import { AppBar, Button, Theme, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { default as React, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFullscreen } from 'rooks';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  authenticationClient,
  useAppSecurityIsAuthenticated,
  useAppSecurityUser,
  useAppSecurityUserClubAuthorizations,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { appWebConfig } from '../../../root/config';
import { appStylesHelper, useCacheableClasses } from '../../../root/theme';
import { AppHeroIcons, AppIcons, AppIconsMaterial } from '../../icons';
import { useIsTouchDevice, useMediaSizeTailwind } from '../../services';
import { confirmDialog } from '../ConfirmDialog/confirmDialog.service';

import { useSideMenu } from '../SideMenu/useSideMenu.hook';

export const SIDE_MENU_WIDTH_OPEN = 150;
export const SIDE_MENU_WIDTH_COLLAPSED = 64;

export const TopBar = () => {
  const fullScreen = useFullscreen({
    target: {
      current: document.body,
    },
  });
  const redirectTo = useRedirect();

  const [fullScreenAsked, setFullScreenAsked] = useState<boolean>(false);

  const isTouchDevice = useIsTouchDevice();

  const clubResume = useClubResume();
  const clubPublicData = clubResume?.clubSettings?.publicData;

  const logoSquareUrl =
    clubPublicData?.brand?.logoSquareUrl ??
    '/images/brand/logo-mabadive_80x80.png';
  useEffect(() => {
    if (
      fullScreen?.isFullscreenAvailable &&
      !fullScreen?.isFullscreenEnabled &&
      !fullScreenAsked &&
      isTouchDevice &&
      clubResume?.clubSettings?.ui?.fullScreenOnTouchDevices &&
      appWebConfig.envId !== 'dev'
    ) {
      (async () => {
        setFullScreenAsked(true);
        const confirm = await confirmDialog.confirmPromise({
          title: 'Passer en plein écran ?',
          message: "Optimisez l'affichage en passant en mode plein écran!",
          type: 'noYesInfo',
        });

        if (confirm) {
          fullScreen.enableFullscreen();
        }
      })();
    }
  }, [
    clubResume?.clubSettings?.ui?.fullScreenOnTouchDevices,
    fullScreen,
    fullScreenAsked,
    isTouchDevice,
  ]);

  const classes = useCacheableClasses({
    cacheKey: 'TopBar',
    buildStyles,
  });

  const user = useAppSecurityUser();

  const mediaSize = useMediaSizeTailwind();

  const diveCenterResume = useDiveCenterResume();

  const {
    isMenuTemporary,
    isMenuCollapsed,
    isMenuDisabled,
    isMenuEmpty,
    toggleMenu,
  } = useSideMenu();

  const sideMenuWidth: number = useMemo(() => {
    if (isMenuCollapsed || isMenuTemporary) {
      return 0;
    } else {
      const x = appStylesHelper.getSideMenuWidthResponsive()?.values;
      if (x) {
        if (mediaSize === 'sm') {
          return SIDE_MENU_WIDTH_OPEN + 50;
        }
        return SIDE_MENU_WIDTH_OPEN;
      }
      return SIDE_MENU_WIDTH_OPEN;
    }
  }, [isMenuCollapsed, isMenuTemporary, mediaSize]);

  const isAuthenticated = useAppSecurityIsAuthenticated();
  const az = useAppSecurityUserClubAuthorizations();
  const isProfileEnabled = useAppSecurityUserHasRole('account-profile');
  const isImpersonate = useAppSecurityUserHasRole('impersonate-back');

  // const showDemoWarning = appWebConfig.debug.showDemoWarning;

  const theme = appWebConfig?.debug.isEnvDemoOrTest ? 'test' : 'prod';

  return (
    <AppBar
      className={`${classes.root} bg-gradient-to-b ${
        theme === 'test'
          ? 'from-[#2292bf] to-[#1d81bf]'
          : 'from-[#1a7498] to-[#19618e]'
      } over-drawer`}
      position="fixed"
    >
      <Toolbar
        className={`max-w-screen-2xl ${classes.toolBar}`}
        style={{
          marginLeft: sideMenuWidth,
        }}
      >
        {isMenuTemporary && !isMenuEmpty ? (
          <div className={`flex mr-2 ${isMenuDisabled ? 'invisible' : ''}`}>
            <button
              type="button"
              className="px-1 sm:px-2 text-gray-200 hover:text-white font-bold cursor-pointer"
              onClick={() => toggleMenu()}
            >
              <div className="block text-center gap-1">
                <AppIconsMaterial.menu />
                <span className="hidden sm:inline-block text-xs truncate uppercase">
                  MENU
                </span>
              </div>
            </button>
            {isAuthenticated &&
              (az?.view?.planning?.participant ||
                az?.view?.planning?.event) && (
                <Link
                  className="px-1 sm:px-2 text-gray-200 hover:text-white font-bold cursor-pointer"
                  to={'/club/planning'}
                >
                  <div className="block text-center gap-1">
                    <AppHeroIcons.planning className="inline w-6 h-6" />
                    <span className="hidden sm:inline-block text-xs truncate uppercase">
                      PLANNING
                    </span>
                  </div>
                </Link>
              )}
            {isAuthenticated &&
              (az?.view?.dashboard?.dailyCash ||
                az?.view?.dashboard?.stats) && (
                <Link
                  className="px-1 sm:px-2 text-gray-200 hover:text-white font-bold cursor-pointer"
                  to={'/club/dashboard'}
                >
                  <div className="block text-center gap-1">
                    <AppHeroIcons.stats className="inline w-6 h-6" />
                    <span className="hidden sm:inline-block text-xs truncate uppercase">
                      DASHBOARD
                    </span>
                  </div>
                </Link>
              )}
          </div>
        ) : (
          <a className={'mr-2'} href={appWebConfig.applications.publicWebUrl}>
            <img
              className={'rounded bg-white w-[40px] h-[40px] max-w-none'}
              alt=""
              src={logoSquareUrl}
            />
          </a>
        )}

        {(theme === 'test' ||
          appWebConfig.debug.showEnvId ||
          appWebConfig.debug.showAppVersion ||
          appWebConfig.debug.showMediaSize) && (
          <div className="">
            {theme === 'test' && (
              <div
                className={`mb-1 text-center text-app-xxs font-bold ${
                  appWebConfig.envId !== 'dev' ? 'bg-red-600' : 'bg-red-600'
                } text-white py-0.5 px-1 rounded uppercase`}
              >
                {appWebConfig.envId !== 'dev' ? 'Démo' : 'Local'}
              </div>
            )}
            {appWebConfig.debug.showEnvId ||
            appWebConfig.debug.showAppVersion ||
            appWebConfig.debug.showMediaSize ? (
              <div className="flex gap-1 sm:gap-2 font-bold text-app-xxs text-white">
                {appWebConfig.debug.showEnvId && appWebConfig.envId ? (
                  <span className="bg-red-600 px-1">
                    {appWebConfig.envId.toUpperCase()}
                  </span>
                ) : null}
                {appWebConfig.debug.showAppVersion &&
                appWebConfig.appVersion ? (
                  <span className="bg-gray-600 px-1">
                    {appWebConfig.appVersion.toLowerCase()}
                  </span>
                ) : null}
                {appWebConfig.debug.showMediaSize && mediaSize ? (
                  <span className="bg-gray-600 px-1">
                    {mediaSize.toUpperCase()}
                  </span>
                ) : null}
              </div>
            ) : null}
          </div>
        )}
        <div className="w-full"></div>
        {diveCenterResume && (
          <div className="mx-2 sm:mx-4 hidden sm:block">
            <DiveCenterProBadge diveCenter={diveCenterResume} />
            <div className="flex gap-2 item-center">
              <div className="flex gap-1 text-sm items-end font-bold">
                MABADIVE <span className="text-xs">PRO</span>
              </div>
            </div>
          </div>
        )}

        <div className="w-full"></div>

        <div className="flex-grow flex gap-1 sm:gap-2 mr-2">
          {!isAuthenticated ? (
            <>
              {/* append app version to be sure browser history cache is not outdated */}
              <Button
                className={classes.logoutButton}
                href={`${appWebConfig.applications.publicWebUrl}/?v-${appWebConfig.appVersion}`}
              >
                <AppIconsMaterial.home />
                <span className="hidden sm:flex truncate">
                  &nbsp; Infos, tarifs
                </span>
              </Button>
            </>
          ) : null}

          {isAuthenticated && isProfileEnabled && !isImpersonate ? (
            <button
              type="button"
              className="px-1 sm:px-2 text-gray-200 hover:text-white font-bold cursor-pointer"
              onClick={() => redirectTo('/account')}
            >
              <div className="block text-center gap-1">
                <AppIconsMaterial.account />
                <span className="hidden sm:inline-block text-xs truncate uppercase">
                  Profil
                </span>
              </div>
            </button>
          ) : null}
          {isAuthenticated && fullScreen?.isFullscreenAvailable && (
            <button
              type="button"
              className="px-1 sm:px-2 text-gray-200 hover:text-white font-bold cursor-pointer"
              onClick={() => {
                fullScreen.toggleFullscreen();
              }}
            >
              <div className="block text-center gap-1">
                {fullScreen.isFullscreenEnabled ? (
                  <AppIcons.ui.fullscreenExit className="inline text-2xl hover:text-orange-400" />
                ) : (
                  <AppIcons.ui.fullscreen className="inline text-2xl hover:text-orange-400" />
                )}
                <span className="hidden sm:inline-block text-xs truncate uppercase">
                  Plein écran
                </span>
              </div>
            </button>
          )}
          {isAuthenticated && (
            <Link
              to="/logout"
              className="px-1 sm:px-2 text-gray-200 hover:text-white font-bold cursor-pointer"
              onClick={async () => {
                await authenticationClient.removeBrowserJwtToken().toPromise();
              }}
            >
              <div className="block text-center gap-1">
                <AppIconsMaterial.logout />
                <span className="hidden sm:inline-block text-xs truncate uppercase">
                  {isImpersonate ? 'Retour' : 'Se déconnecter'}
                </span>
              </div>
            </Link>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );

  function buildStyles(theme: Theme) {
    return makeStyles({
      root: {
        '&.over-drawer': {
          zIndex: 0,
          // zIndex: theme.zIndex.drawer + 1,
        },
        '& .MuiToolbar-root.MuiToolbar-gutters': {
          padding: '0 10px',
        },
        '& .menu-button.MuiButton-root': {
          color: 'white',
          '&:hover': {
            color: APP_COLORS.orange,
          },
        },
        // background: APP_COLORS.topBar,
      },
      toolBar: {
        '&.MuiToolbar-regular': {
          minHeight: '50px',
        },
      },
      logoutButton: {
        color: theme.palette.common.white,
        '&:hover': {
          background: 'transparent',
          color: APP_COLORS.orange,
        },
      },
      grow: {
        flexGrow: 1,
      },
    });
  }
};
