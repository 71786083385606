/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  ClubStaffMemberDailyAvailabilityPeriod,
  ClubStaffMemberRole,
  DiveCenterDailyConfig,
  DiveSessionStaffMemberTableModelStaffMember,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  commonDiveSessionReferenceParser,
  diveSessionMultipleBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { FC, useCallback } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { sessionStaffConfigRoleUpdateBuilder } from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab2StaffMembers/sessionStaffConfigRoleUpdateBuilder.service';
import {
  DiveSessionStaffMembersTableSession,
  DiveSessionStaffRoleModel,
} from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components';
import { DiveSessionStaffMembersTableRowStaffRoles } from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components/DiveSessionStaffMembersTable/DiveSessionStaffMembersTableRowStaffRoles';
import { diveSessionResumeToDiveSessionConvertor } from '../../../club-diver-participant/pages/DiverBookingPage/services/02.update-state/entity-convertors';
import { planningStaffWeekPresenceCacheUpdator } from '../../../club-planning/ClubPlanningStaffPage/cache';
import { ClubPlanningLightPanelViewClickActions } from '../../model';
import { ClubPlanningLightPanelViewPeriodDaySessionHeader } from './ClubPlanningLightPanelViewPeriodDaySessionHeader';

export const ClubPlanningLightPanelViewPeriodDayStaffMembersListSession: FC<{
  dayTotalSessionsCount: number;
  sessionDetails: {
    session: ClubPlanningLightSessionDef;
    staffMemberFull: DiveSessionStaffMemberTableModelStaffMember;
  };
  dailyConfig?: DiveCenterDailyConfig;
  availableRoles: ClubStaffMemberRole[];
  sessionDetailsIndex: number;
  clickActions: ClubPlanningLightPanelViewClickActions;
  className?: string;
}> = ({
  dailyConfig,
  dayTotalSessionsCount,
  sessionDetailsIndex,
  sessionDetails,
  availableRoles,
  clickActions,
  className = '',
}) => {
  const diveCenterResume = useDiveCenterResume();
  const { clubReference } = diveCenterResume;

  const { session, staffMemberFull } = sessionDetails;

  const isMultiSessionConfigForStaff =
    diveSessionMultipleBuilder.isMultiSessionConfigForStaff(
      session.diveTourSession2,
    );

  const onUpdateSessionStaffConfig = useCallback(
    async ({
      availabilityPeriod,
      isAvailableOnSession,
      toogleRole,
    }: {
      availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod;
      isAvailableOnSession: boolean;
      toogleRole?: Pick<DiveSessionStaffRoleModel, 'assigned' | 'staffRoleRef'>;
    }) => {
      const { diveSessionsPatchOperations, createdDiveSession } =
        sessionStaffConfigRoleUpdateBuilder.buildPatchOperationsCreateOrUpdateSession(
          {
            diveSession: diveSessionResumeToDiveSessionConvertor.convert(
              session,
              { clubReference },
            ),
            staffMember: staffMemberFull.staffMember,
            isAvailableOnSession,
            toogleRole,
            form: undefined,
          },
        );

      const date = commonDiveSessionReferenceParser.parseDayReference(
        session.dayReference,
      );

      const localUpdateState =
        planningStaffWeekPresenceCacheUpdator.updateStaffMemberPresence(
          {
            availabilityPeriod,
            date,
            dayReference: session.dayReference,
            staffMember: staffMemberFull.staffMember,
            dailyConfig,
          },
          {
            clubReference,
            diveCenterId: session.diveCenterId,
            updateState: {
              hasChanges: false,
              dailyConfigsChanges: [],
              diveSessionsChanges: [],
            },
          },
        );

      const payload: ProMultiOperationPayload = {};

      const dailyConfigsChanges = localUpdateState.dailyConfigsChanges ?? [];
      payload.diveCenterDailyConfigs = {
        created: dailyConfigsChanges.map((d) => d.created).filter((x) => !!x),
        updated: dailyConfigsChanges.map((d) => d.updated).filter((x) => !!x),
      };
      if (diveSessionsPatchOperations.length > 0) {
        payload.updatedDiveSessions = [
          {
            pk: session._id,
            patchOperations: diveSessionsPatchOperations,
          },
        ];
      }
      if (createdDiveSession) {
        payload.newDiveSessions = (payload.newDiveSessions ?? []).concat([
          createdDiveSession,
        ]);
      }
      if (
        payload.newDiveSessions?.length > 0 ||
        payload.updatedDiveSessions?.length > 0 ||
        payload.diveCenterDailyConfigs?.created?.length > 0 ||
        payload.diveCenterDailyConfigs?.updated?.length > 0
      ) {
        await clubMassiveUpdatorClient.update(payload);
      }
    },
    [clubReference, dailyConfig, session, staffMemberFull.staffMember],
  );

  return (
    <div
      key={sessionDetailsIndex}
      className={clsx(
        'flex flex-col justify-between select-none text-gray-700 text-sm leading-4 ',
        staffMemberFull.meta.active ? '' : 'hidden md:flex',
        className,
      )}
    >
      <ClubPlanningLightPanelViewPeriodDaySessionHeader
        className={clsx(
          'flex-grow',
          !staffMemberFull.meta.active && 'opacity-60 grayscale-[50%]',
        )}
        session={session}
        sessionIndex={{
          index: sessionDetailsIndex + 1,
          total: dayTotalSessionsCount,
        }}
        onClick={
          clickActions
            ? () => {
                clickActions.onClickEditSessionDiveGroups(session);
              }
            : undefined
        }
        widthMode={'small'}
      />

      <div className="border border-gray-300 bg-gray-50 py-1 mb-1 px-1 flex flex-col gap-2 justify-start items-stretch">
        <div
          className={'w-full whitespace-nowrap text-sm text-center'}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DiveSessionStaffMembersTableRowStaffRoles
            className="w-full flex justify-between flex-wrap"
            isMultiSessionConfigForStaff={isMultiSessionConfigForStaff}
            staffMemberFull={staffMemberFull}
            availableRoles={availableRoles}
            onClick={({ role }) => {
              if (onUpdateSessionStaffConfig) {
                onUpdateSessionStaffConfig({
                  availabilityPeriod:
                    staffMemberFull.dailyAvailabilityResume.availabilityPeriod,
                  isAvailableOnSession: staffMemberFull.meta.available,
                  toogleRole: role,
                });
              }
            }}
          />
        </div>
        <div className={'whitespace-nowrap text-xs'}>
          <DiveSessionStaffMembersTableSession
            showRoles={false}
            session={staffMemberFull.session1}
          />
        </div>
        {isMultiSessionConfigForStaff && (
          <div className={'whitespace-nowrap text-xs'}>
            <DiveSessionStaffMembersTableSession
              showRoles={false}
              session={staffMemberFull.session2}
            />
          </div>
        )}
      </div>
    </div>
  );
};
