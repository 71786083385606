import {
  AppEntityUpdatePatch,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { ClubSettingsCommunicationDialogProps } from '../_model';

export function useClubSettingsCommunicationDialogActionPersist(): ClubSettingsCommunicationDialogProps {
  const action: ClubSettingsCommunicationDialogProps = {
    async onConfirm({ formValue }, initialState) {
      const { defaultValue } = initialState;
      const clubSettingsPatchOperations = jsonPatcher.compareObjects(
        defaultValue.clubSettings,
        {
          ...formValue.clubSettings,
        },
        {
          // else, value won't be deleted by typeorm
          // https://github.com/typeorm/typeorm/issues/2934
          replaceDeleteByNullValue: true,
          // attributesToReplaceFully: ['communication'],
        },
      );

      if (clubSettingsPatchOperations.length) {
        const payload: ProMultiOperationPayload =
          clubMassiveUpdatorClient.createEmptyPayload({
            logContext: 'edit communication config',
            bookingIdsToClean: [],
          });

        if (clubSettingsPatchOperations.length) {
          const patch: AppEntityUpdatePatch = {
            pk: defaultValue.clubSettings._id,
            patchOperations: clubSettingsPatchOperations,
          };
          payload.updatedClubSettings = patch;
        }
        return clubMassiveUpdatorClient.update(payload);
      }
    },
  };
  return action;
}
