import { ClubDiverCertificationLabels } from '@mabadive/app-common-model';
import React from 'react';

export const DiveCenterBookingMemberCertification = ({
  certificationLabels,
  className = '',
}: {
  certificationLabels: ClubDiverCertificationLabels;
  className?: string;
}) => {
  const { orgReference, mainCertificationLabel, gazCertificationLabel } =
    certificationLabels;

  return (
    <span className={`inline-flex gap-1 ${className}`}>
      {mainCertificationLabel}{' '}
      {gazCertificationLabel && (
        <span className="text-orange-500">{gazCertificationLabel}</span>
      )}
      {orgReference && (
        <span className="font-normal text-gray-600 text-app-xxs">
          {orgReference?.toUpperCase()}
        </span>
      )}
    </span>
  );
};
