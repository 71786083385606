/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DEFAULT_EQUIPMENT_SETTINGS,
  DiveCenterEquipmentConfig,
  DiveCenterResume,
  EquipmentType,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { equipmentFormatter, jsonPatcher } from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppIconsDiveEquipmentType } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { clubSettingsDiveCenterUrlBuilder } from 'src/pages/SE-settings/SE-01_dive-center/clubSettingsDiveCenterUrlBuilder.service';
import useRouter from 'use-react-router';
import {
  DiveCenterEquipmentConfigFormModel,
  DiveCenterEquipmentConfigFormModelEquipementModel,
} from './ClubSettingsEquipmentEditPageForm/model';
import { clubSettingsUserEquipmentRefBuilder } from './services';

export function useClubSettingsEquipmentEditPageLocalState() {
  const { match } =
    useRouter<{
      equipmentType: EquipmentType;
    }>();
  const [operationPending, setOperationPending] = useState(false);

  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();

  const equipmentType = match.params.equipmentType;

  const Icon = AppIconsDiveEquipmentType[equipmentType];

  const equipmentTypeLabel = useMemo(
    () => equipmentFormatter.formatEquipmentType(equipmentType),
    [equipmentType],
  );

  const initialValueIfExists: DiveCenterEquipmentConfig = useMemo(() => {
    const initialValue: DiveCenterEquipmentConfig = (
      diveCenterResume?.privateSettings?.equipment?.equipments ?? []
    ).find((x) => x.type === equipmentType);

    return initialValue;
  }, [diveCenterResume?.privateSettings?.equipment?.equipments, equipmentType]);

  const equipmentDefaultAppConfig: DiveCenterEquipmentConfig = useMemo(
    () => DEFAULT_EQUIPMENT_SETTINGS.find((x) => x.type === equipmentType),
    [equipmentType],
  );

  const initialFormValue: DiveCenterEquipmentConfigFormModel = useMemo(() => {
    if (initialValueIfExists) {
      const initialFormValue: DiveCenterEquipmentConfigFormModel = {
        ...initialValueIfExists,
        club: {
          ...initialValueIfExists.club,
          models: clubSettingsUserEquipmentRefBuilder.append__originalRefs(
            initialValueIfExists.club.models,
          ),
        },
        self: {
          ...initialValueIfExists.self,
          models: clubSettingsUserEquipmentRefBuilder.append__originalRefs(
            initialValueIfExists.self.models,
          ),
        },
      };

      return initialFormValue;
    } else {
      // ici on n'ajoute pas les référence, car c'est une création, donc pas de mise à jour à faire
      return {
        ...equipmentDefaultAppConfig,
        enabled: false,
      } as DiveCenterEquipmentConfigFormModel;
    }
  }, [equipmentDefaultAppConfig, initialValueIfExists]);

  const form = useForm<DiveCenterEquipmentConfigFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    setError,
  } = form;

  const back = useCallback(() => {
    return redirectTo(clubSettingsDiveCenterUrlBuilder.equipments.list());
  }, [redirectTo]);

  const submitForm = useCallback(() => {
    handleSubmit(
      async (formValue: DiveCenterEquipmentConfigFormModel, event) => {
        setOperationPending(true);

        const dcr = diveCenterResume;

        const newConfig: DiveCenterEquipmentConfig = {
          ...formValue,
          club: {
            ...formValue.club,
            models: clubSettingsUserEquipmentRefBuilder.strip__originalRefs(
              formValue.club.models,
            ),
          },
          self: {
            ...formValue.self,
            models: clubSettingsUserEquipmentRefBuilder.strip__originalRefs(
              formValue.self.models,
            ),
          },
        };

        let equipments: DiveCenterEquipmentConfig[];
        if (initialValueIfExists) {
          // mise à jour
          equipments =
            diveCenterResume?.privateSettings?.equipment?.equipments.map(
              (x) => {
                if (x.type === initialValueIfExists.type) {
                  return newConfig;
                } else {
                  return x;
                }
              },
            );
        } else {
          // ajout
          equipments =
            diveCenterResume?.privateSettings?.equipment?.equipments.concat(
              newConfig,
            );
        }

        const updatedDsr: DiveCenterResume = {
          ...dcr,
          privateSettings: {
            ...dcr.privateSettings,
            equipment: {
              ...dcr.privateSettings.equipment,
              equipments,
            },
          },
        };
        try {
          const patchOperations = jsonPatcher.compareObjects(dcr, updatedDsr, {
            attributesToReplaceFully: ['privateSettings'],
          });

          if (patchOperations.length) {
            // détection des références qui ont changé
            const clubModelsWithUpdatedReference = filterUpdatedReferences(
              formValue.club.models,
            );
            const selfModelsWithUpdatedReference = filterUpdatedReferences(
              formValue.self.models,
            );
            const payload: ProMultiOperationPayload = {
              logContext: 'update dc equipments',
              diveCenters: {
                updated: [
                  {
                    pk: dcr._id,
                    patchOperations,
                  },
                ],
              },
              diveCenterEquipments: {
                updatedRefsClub: clubModelsWithUpdatedReference.map((x) => {
                  return {
                    originalRef: x.__originalRef,
                    newRef: x.ref,
                    type: equipmentType,
                  };
                }),
                updatedRefsSelf: selfModelsWithUpdatedReference.map((x) => {
                  return {
                    originalRef: x.__originalRef,
                    newRef: x.ref,
                    type: equipmentType,
                  };
                }),
              },
            };
            await clubMassiveUpdatorClient.update(payload);
          }
          back();
        } catch (err) {
          appLogger.error('Unexpecter error persisting profile', err);
        } finally {
          setOperationPending(false);
        }
      },
    )();
  }, [
    back,
    diveCenterResume,
    equipmentType,
    handleSubmit,
    initialValueIfExists,
  ]);

  return {
    state: {
      operationPending,
    },
    data: {
      Icon,
      equipmentType,
      equipmentTypeLabel,
      form,
      equipmentDefaultAppConfig,
    },
    actions: {
      back,
      submitForm,
    },
  };
}

export type ClubSettingsEquipmentEditPageLocalState = ReturnType<
  typeof useClubSettingsEquipmentEditPageLocalState
>;
function filterUpdatedReferences(
  models: DiveCenterEquipmentConfigFormModelEquipementModel[],
): DiveCenterEquipmentConfigFormModelEquipementModel[] {
  return (models ?? []).filter((x) => {
    const { __originalRef, ref } = x;
    return __originalRef && __originalRef !== ref;
  });
}
