import {
  BookingResume,
  EditionBookingResumeProduct,
} from '@mabadive/app-common-model';
import { MQueryDescription } from 'src/_common-browser';
import { editionBookingResumeAttributesBuilder } from 'src/business/_core/data/store-repository';
import { editionBookingProductResumeAttributesBuilder } from 'src/business/_core/data/store-repository/ecommerce/graphql/query-builder/edition-booking/editionBookingProductResumeAttributesBuilder.service';

export const diverBookingPageLoadedQueryBuilder = {
  buildGraphqlQueryEditionBookingResumes,
  buildGraphqlQueryEditionBookingResumeProduct,
};
function buildGraphqlQueryEditionBookingResumes({
  bookingIds,
  returnName,
}: {
  bookingIds: string[];
  returnName: string;
}) {
  const bookingIdsString = bookingIds.map((x) => `"${x}"`).join(',');

  const where = `{_id: {_in: [${bookingIdsString}]}}`;

  const returnAttributes =
    editionBookingResumeAttributesBuilder.buildAttributes();

  const queryDescription: MQueryDescription<BookingResume> = {
    returnName,
    queryName: 'club_commerce_booking',
    returnType: 'all',
    where,
    orderBy: '{bookingDate: asc}',
    returnAttributes,
  };

  return queryDescription;
}

function buildGraphqlQueryEditionBookingResumeProduct({
  bookingIds,
  diverIds,
  returnName,
}: {
  bookingIds: string[];
  diverIds: string[];
  returnName: string;
}) {
  // pour l'instant, on charge TOUS les produits
  const bookingIdsString = bookingIds.map((x) => `"${x}"`).join(',');

  const diverIdsString = diverIds.map((x) => `"${x}"`).join(',');

  // on charge:
  // - les produits des réservations chargées
  // - les produits des plongeurs chargés (pour l'historique)
  // - les produits associs à des facturations chargées (pour l'imputation)
  const where = `{
    _or: [
      {
        bookingId: {_in: [${bookingIdsString}]},
      },
      {
        diverId: {_in: [${diverIdsString}]}, 
      },
      {
        purchasePackage: {diverId: {_in: [${diverIdsString}]}}
      }
    ]
  }`;

  const returnAttributes =
    editionBookingProductResumeAttributesBuilder.buildAttributes();

  const queryDescription: MQueryDescription<EditionBookingResumeProduct> = {
    returnName,
    queryName: 'club_commerce_booking_product',
    returnType: 'all',
    where,
    returnAttributes,
  };

  return queryDescription;
}
