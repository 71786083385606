import {
  ClubCommercePaymentPlatformExtAccountGql_Company,
  ClubCommercePaymentPlatformGql_Company,
} from '@mabadive/app-common-model';
import { Observable } from 'rxjs';
import { graphqlClient, MQueryDescription } from 'src/_common-browser';
import { ClubPaymentPlatformResume } from '../_model';

export const clubSettingsPaymentPlatformListPageGraphqlFetcher = {
  fetchAll,
};
function fetchAll({
  clubReference,
}: {
  clubReference: string;
}): Observable<ClubPaymentPlatformResume[]> {
  const query: MQueryDescription<any> = {
    returnName: 'paymentPlatforms',
    returnType: 'all',
    queryName: 'club_commerce_payment_platform',
    where: `{clubReference: {_eq: "${clubReference}"}}`,
    orderBy: '{_createdAt: asc}',
    returnAttributes: `
         ${ClubCommercePaymentPlatformGql_Company}
         extAccount {
          ${ClubCommercePaymentPlatformExtAccountGql_Company}
         }
        `,
  };

  return graphqlClient.query.runOne<any>(query, {
    name: 'ClubSettingsPaymentPlatformListPageGraphqlFetcher.fetchAll',
    type: 'subscription',
    headers: {
      'x-hasura-role': 'club-admin', // select hasura role (NOTE: on dirait que ça n'est pas pris en compte sur les subscriptions)
    },
  });
}
