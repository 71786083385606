import { ClubSettingsUIPlanning } from '@mabadive/app-common-model';
import React from 'react';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { SetPlanningConfigOptions } from '../hooks';

export function ClubPlanningParticipantsConfigDialogMonthViewForm<
  T extends ClubSettingsUIPlanning,
>({
  context,
  settingsUiPlanning,
  setSettingsUiPlanning,
  className,
}: {
  context: 'club-settings' | 'device-settings';
  settingsUiPlanning: T;
  setSettingsUiPlanning: (
    settingsUiPlanning: T,
    options?: SetPlanningConfigOptions,
  ) => void;
  className?: string;
}) {
  return (
    <div className={className}>
      <h3 className="mt-2 mb-1 font-bold uppercase text-sm text-app-blue">
        Vue "mensuelle"
      </h3>
      <div className="mb-3 flex flex-col gap-2">
        <AppInputBooleanSwitch
          value={!settingsUiPlanning.displayPastSessions}
          label={
            <div className="flex gap-1 md:gap-1.5 justify-center items-center">
              Masquer les sessions passées du mois en cours
            </div>
          }
          onChange={() => {
            setSettingsUiPlanning({
              ...settingsUiPlanning,
              displayPastSessions: !settingsUiPlanning.displayPastSessions,
            });
          }}
        />
        <AppInputBooleanSwitch
          value={!settingsUiPlanning.displayEmptySessionsPast}
          label={
            <div className="flex gap-1 md:gap-1.5 justify-center items-center">
              Masquer les sessions passées sans inscrits
            </div>
          }
          onChange={() => {
            setSettingsUiPlanning({
              ...settingsUiPlanning,
              displayEmptySessionsPast:
                !settingsUiPlanning.displayEmptySessionsPast,
            });
          }}
        />
        <AppInputBooleanSwitch
          value={!settingsUiPlanning.displayEmptySessionsFuture}
          label={
            <div className="flex gap-1 md:gap-1.5 justify-center items-center">
              Masquer les sessions futures sans inscrits
            </div>
          }
          onChange={() => {
            setSettingsUiPlanning({
              ...settingsUiPlanning,
              displayEmptySessionsFuture:
                !settingsUiPlanning.displayEmptySessionsFuture,
            });
          }}
        />
        <AppInputBooleanSwitch
          value={settingsUiPlanning.displayGroupLevelDetails}
          label={
            <div className="flex gap-1 md:gap-1.5 justify-center items-center">
              Afficher le détails des niveaux de chaque palanquée
            </div>
          }
          onChange={() => {
            setSettingsUiPlanning({
              ...settingsUiPlanning,
              displayGroupLevelDetails:
                !settingsUiPlanning.displayGroupLevelDetails,
            });
          }}
        />
      </div>
    </div>
  );
}
