/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';

import { AppInputBooleanSwitchRHF } from 'src/lib/form';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import {
  ClubSettingsOnlineBookingDialogInitialState,
  ClubSettingsOnlineBookingFormModel,
} from '../../../_model';

export const ClubSettingsOnlineBookingMainForm_General = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsOnlineBookingFormModel>;
  initialState: ClubSettingsOnlineBookingDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const [
    customerSpaceEnabled,
    onlineBookingEnabled,
    onlineBookingEnableCatalog,
  ] = useWatch({
    control,
    name: [
      'clubSettings.publicSettings.customerSpace.enabled',
      'clubSettings.general.onlineBooking.enabled',
      'clubSettings.publicSettings.customerSpace.onlineBooking.enableCatalog',
    ],
  });

  return (
    <ClubSettingsSection
      title="Configuration générale"
      comment="Ajouter un bouton sur votre site ou affichez un QR Code dans vos locaux"
    >
      <div className="grid md:grid-cols-2 gap-x-4 gap-y-4">
        <AppFormControlRHF
          className=""
          label={'Espace client'}
          control={control}
          name={'clubSettings.publicSettings.customerSpace.enabled'}
          disabled={!isSuperAdmin}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              label={'Activé'}
              theme="success"
              onChange={(enabled) => {
                setValue('clubSettings.general.onlineBooking.enabled', enabled);
                if (!enabled) {
                  setValue(
                    'clubSettings.publicSettings.customerSpace.onlineBooking.enableCatalog',
                    enabled,
                  );
                }
              }}
            />
          )}
        />
        {customerSpaceEnabled && (
          <>
            <AppFormControlRHF
              className=""
              label={'Vente / réservation en ligne'}
              control={control}
              name={'clubSettings.general.onlineBooking.enabled'}
              disabled={!isSuperAdmin}
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF
                  {...props}
                  label={'Activé'}
                  theme="success"
                  onChange={(enabled) => {
                    if (!enabled) {
                      // on désactive aussi le catalogue de l'espace client
                      setValue(
                        'clubSettings.publicSettings.customerSpace.onlineBooking.enableCatalog',
                        enabled,
                      );
                    }
                  }}
                />
              )}
            />
            {onlineBookingEnabled && (
              <>
                <AppFormControlRHF
                  className=""
                  label={'Afficher le catalogue des activités'}
                  disabled={!isSuperAdmin}
                  control={control}
                  name={
                    'clubSettings.publicSettings.customerSpace.onlineBooking.enableCatalog'
                  }
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF
                      {...props}
                      theme="success"
                      label={'Activé'}
                    />
                  )}
                />
                <AppFormControlRHF
                  className=""
                  label={'Afficher bouton "Découvrir nos activités"'}
                  disabled={!isSuperAdmin}
                  control={control}
                  name={
                    'clubSettings.publicSettings.customerSpace.onlineBooking.showCatalogButtonFromHome'
                  }
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF
                      {...props}
                      theme="success"
                      label={'Activé'}
                    />
                  )}
                />
                <AppFormControlRHF
                  className=""
                  label={'Afficher le bouton "Réserver" sur les fiches produit'}
                  disabled={!isSuperAdmin}
                  control={control}
                  name={
                    'clubSettings.publicSettings.customerSpace.onlineBooking.enableBooking'
                  }
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF
                      {...props}
                      theme="success"
                      label={'Activé'}
                    />
                  )}
                />
                <AppFormControlRHF
                  label={
                    "Afficher la liste des commandes en ligne sur l'espace PRO"
                  }
                  control={control}
                  name={
                    'clubSettings.general.onlineBooking.enableProOrdersList'
                  }
                  renderComponent={(props) => (
                    <AppInputBooleanSwitchRHF
                      {...props}
                      theme="success"
                      label={'Activé'}
                    />
                  )}
                />
              </>
            )}
            {isSuperAdmin && (
              <AppFormControlRHF
                label="Acomptes"
                control={control}
                name={'clubSettings.general.deposits.enabled'}
                required={false}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF
                    {...props}
                    theme="success"
                    label={'Activé'}
                  />
                )}
              />
            )}
          </>
        )}
      </div>
    </ClubSettingsSection>
  );
};
