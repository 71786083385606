import { DiveSessionType } from '@mabadive/app-common-model';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ClubSettingsPlanningViewWeekFormModel } from '../../model';

export function usePlanningSettingsOnChangeDiveSessionType(
  form: UseFormReturn<ClubSettingsPlanningViewWeekFormModel, any>,
  diveTourControlBaseName: `days.${number}.diveTours.${number}`,
) {
  const { setValue } = form;
  return useCallback(
    (value: DiveSessionType) => {
      // NOTE: fonction similaire dans DiveSessionEditFormRHF
      switch (value) {
        case 'first-dive': {
          setValue(
            `${diveTourControlBaseName}.defaultDiveMode` as const,
            'first-dive',
          );
          setValue(
            `${diveTourControlBaseName}.session1.emojiId` as const,
            'whale',
          );
          break;
        }
        case 'snorkeling': {
          setValue(
            `${diveTourControlBaseName}.defaultDiveMode` as const,
            'snorkeling',
          );
          setValue(
            `${diveTourControlBaseName}.session1.emojiId` as const,
            'diving_mask',
          );
          break;
        }
        case 'snorkeling-tour': {
          setValue(
            `${diveTourControlBaseName}.defaultDiveMode` as const,
            'snorkelingSupervised',
          );
          setValue(
            `${diveTourControlBaseName}.session1.emojiId` as const,
            'diving_mask',
          );
          break;
        }
        case 'watching-tour': {
          setValue(
            `${diveTourControlBaseName}.defaultDiveMode` as const,
            'watchingTour',
          );
          setValue(
            `${diveTourControlBaseName}.session1.emojiId` as const,
            'whale',
          );
          break;
        }
        case 'dive-auto-supervised': {
          setValue(
            `${diveTourControlBaseName}.defaultDiveMode` as const,
            'autoSupervised',
          );
          // setValue(
          //   `${diveTourControlBaseName}.session1.emojiId` as const,
          //   'diving_mask',
          // ); TODO 🔄
          break;
        }
        case 'theoretical-training': {
          setValue(
            `${diveTourControlBaseName}.defaultDiveMode` as const,
            'theoretical-training',
          );
          setValue(
            `${diveTourControlBaseName}.session1.emojiId` as const,
            'open_book',
          );

          break;
        }
      }
    },
    [diveTourControlBaseName, setValue],
  );
}
