/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import {
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS,
  ClubStaffMemberDailyAvailabilityPeriod,
  DateIsoWeekDay,
} from '@mabadive/app-common-model';
import { FieldArrayWithId, useWatch } from 'react-hook-form';
import {
  AppFormControlRHF,
  AppInputBooleanSwitch,
  AppSingleSelect2HeadlessUIRHF,
} from 'src/lib/form';
import { useDailyAvailabilityPeriodOptions } from '../../../../../../_components/options';
import { StaffMemberEditFormModel } from '../../_model';
import { StaffMemberEditDialogLocalState } from '../../useStaffMemberEditDialogLocalState.hook';

/**
 *
 * __NOTE__: Voir aussi le doublon déprécié StaffMemberEditFormDeprecatedAvailabilyDay
 */
export const StaffMemberEditFormAvailabilyDay = ({
  localState,
  field,
  fieldIndex,
  isoWeekDay,
  className,
}: {
  localState: StaffMemberEditDialogLocalState;
  field: FieldArrayWithId<
    StaffMemberEditFormModel,
    'staffMember.availabilities',
    'id'
  >;
  fieldIndex: number;
  isoWeekDay: DateIsoWeekDay;
  className?: string;
}) => {
  const {
    state: { form },
  } = localState;
  const { control, setValue } = form;

  const basePath =
    `staffMember.availabilities.${fieldIndex}.defaultWorkingDays.${isoWeekDay}` as const;

  const [availabilityPeriod]: [ClubStaffMemberDailyAvailabilityPeriod] =
    useWatch({
      control,
      name: [`${basePath}.availabilityPeriod` as any],
    });

  const availabilityPeriodOptions = useDailyAvailabilityPeriodOptions({
    periods: CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS,
  });

  return (
    <div className={className}>
      {!availabilityPeriod || availabilityPeriod === 'none' ? (
        <AppInputBooleanSwitch
          theme={'success'}
          value={false}
          label={'Absent'}
          onChange={() => {
            setValue(`${basePath}.availabilityPeriod` as any, 'day');
          }}
        />
      ) : (
        <AppFormControlRHF
          className="w-full"
          control={control}
          name={`${basePath}.availabilityPeriod` as any}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              theme={'tailwind'}
              options={availabilityPeriodOptions}
              disableClearButton={true}
              optionsStyle={{}}
              className="text-app-primary-light"
            />
          )}
        />
      )}
    </div>
  );
};
