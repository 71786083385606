import { dateService, nameFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  AppGenericStatusIcon,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { PRO_BookingMemberFull } from '../../../models';

export function SelectedDiversTableMemberRow({
  bookingMemberFull,
  onClick,
  isNewMember,
  isNewDiver,
}: {
  bookingMemberFull: PRO_BookingMemberFull;
  onClick?: () => void;
  isNewMember: boolean;
  isNewDiver: boolean;
}) {
  const clubResume = useClubResume();
  const customerSettings = clubResume.clubSettings?.customer;
  const age = useMemo(
    () =>
      bookingMemberFull.diver.birthdate
        ? dateService.getAge(bookingMemberFull.diver.birthdate)
        : bookingMemberFull.diver.age,
    [bookingMemberFull.diver.age, bookingMemberFull.diver.birthdate],
  );

  const medicalCertificateDateBadgeStatus: AppGenericStatus = useMemo(() => {
    if (!bookingMemberFull.diver.medicalCertificateDate) {
      return 'warn';
    }
    if (
      dateService.getAge(bookingMemberFull.diver.medicalCertificateDate) < 1
    ) {
      return 'success';
    }
    return 'error';
  }, [bookingMemberFull.diver.medicalCertificateDate]);

  const medicalCertificateDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(medicalCertificateDateBadgeStatus),
    [medicalCertificateDateBadgeStatus],
  );

  return (
    <tr className={onClick ? 'cursor-pointer' : ''}>
      <td className="w-9 py-1 whitespace-nowrap text-right text-sm font-medium">
        {isNewMember ? (
          isNewDiver ? (
            <AppHeroIcons.actionAdd className="w-6 h-6 text-green-600" />
          ) : (
            <AppHeroIcons.success className="w-6 h-6 text-app-blue" />
          )
        ) : null}
      </td>
      <td
        className="px-2 py-1 whitespace-nowrap"
        onClick={() => onClick && onClick()}
      >
        <div className="flex-shrink block text-sm">
          {/* {onClick && (
            <AppHeroIcons.actionEdit className="mr-2 w-5 h-5 text-gray-600" />
          )} */}
          <p
            className={`font-bold ${
              isNewMember ? 'text-app-blue' : 'text-gray-500'
            } truncate`}
          >
            {nameFormatter.formatFullName(bookingMemberFull.diver, {
              format: 'firstName-first',
            })}
          </p>
          {age > 0 && (
            <p>
              <span className="text-sm font-bold text-gray-500">{age} ans</span>
              {/* <span className="ml-2 text-xs text-gray-500">
                {dateService.formatUTC(
                  bookingMemberFull.diver.birthdate,
                  'DD/MM/YYYY',
                )}
              </span> */}
            </p>
          )}
        </div>
      </td>
      <td
        className="px-2 py-1 whitespace-nowrap"
        onClick={() => onClick && onClick()}
      >
        {bookingMemberFull.bookingMember.mainCertificationReference && (
          <span
            className={`${
              isNewMember ? 'text-app-blue' : 'text-gray-500'
            } font-bold`}
          >
            {bookingMemberFull.bookingMember.mainCertificationReference}
          </span>
        )}
      </td>
      {customerSettings?.diving?.medicalCertificateDate && (
        <td
          className={`hidden md:table-cell px-2 py-1 text-xs whitespace-nowrap ${medicalCertificateDateBadgeStatusClass}`}
          onClick={() => onClick && onClick()}
        >
          <div className="flex items-center">
            <AppGenericStatusIcon
              status={medicalCertificateDateBadgeStatus}
              className={'w-4 h-4 '}
            />
            <div className="grow">
              {bookingMemberFull.diver.medicalCertificateDate ? (
                <span className="ml-1">
                  {dateService.formatUTC(
                    bookingMemberFull.diver.medicalCertificateDate,
                    'DD/MM/YYYY',
                  )}
                </span>
              ) : (
                <span className="ml-1">{'manquant'}</span>
              )}
            </div>
          </div>
        </td>
      )}
      <td
        className={'hidden sm:table-cell px-2 py-1 text-xs whitespace-nowrap'}
        onClick={() => onClick && onClick()}
      >
        <div className="text-gray-600">
          {bookingMemberFull.diver.phoneNumber && (
            <span className="block text-sm sm:text-base">
              {bookingMemberFull.diver.phoneNumber}
            </span>
          )}
          {bookingMemberFull.diver.emailAddress && (
            <span className="block text-sm sm:text-base">
              {bookingMemberFull.diver.emailAddress}
            </span>
          )}
        </div>
      </td>
    </tr>
  );
}
