/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveCenter,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import {
  AppIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiveCenterBrandCard } from 'src/pages/_components/company';
import {
  ClubDialogsProvider,
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import useRouter from 'use-react-router';
import { ClubLegalInformationCard } from './DiveCenterEditDialog';

export const ClubSettingsAccountCompanyInfoPage = () => {
  const { match } = useRouter();

  const redirectTo = useRedirect();

  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const diveCenters: DiveCenter[] = useMemo(
    () =>
      dataSorter.sortMultiple(clubResume.diveCenters ?? [], {
        getSortAttributes: (x) => [
          {
            value: x.publicData?.brand?.name,
            type: 'full-text',
          },
          {
            value: x.publicData?.brand?.locationResume,
            type: 'full-text',
          },
          {
            value: x._createdAt,
            type: 'full-text',
          },
        ],
        asc: true,
      }),
    [clubResume.diveCenters],
  );

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const openEditDiveCenterDialog = useCallback(
    (diveCenter: DiveCenter) => {
      dialogsState.diveCenterEditDialog.openDialog({
        mode: 'edit',
        defaultValue: {
          diveCenter,
          clubSettings,
        },
      });
    },
    [clubSettings, dialogsState.diveCenterEditDialog],
  );

  return (
    <AppPageContainer className={'bg-white'}>
      <ClubDialogsProvider dialogsState={dialogsState}>
        <AppPageContentContainer paddingBottom={false} className="bg-gray-50">
          <AppBreadcrumbBar
            color={AppIconsSettings.account.color}
            items={[
              {
                icon: AppIconsSettings.general,
                label: 'Paramètres',
                url: '/club/settings',
              },
              {
                icon: AppIconsSettings.account.main,
                label: 'Mon compte',
                url: '/club/settings',
              },
              {
                label: 'Coordonnées du centre',
              },
            ]}
          />
          <div className="app-p-content">
            <h2 className="my-3 mx-2 text-left uppercase text-base md:text-lg leading-6 font-medium text-gray-800">
              {isMultiDiveCenters ? 'Centres de plongée' : 'Centre de plongée'}
            </h2>
            <div className="flex flex-col md:flex-row md:grid lg:grid-cols-2 2xl:grid-cols-4 gap-x-4 gap-y-4">
              {diveCenters.map((diveCenter) => (
                <div
                  key={diveCenter._id}
                  className={clsx('w-full app-card p-4 app-card-highlight')}
                  onClick={() => {
                    openEditDiveCenterDialog(diveCenter);
                  }}
                >
                  {/* <div className="flex justify-center xs:justify-start"> */}
                  <DiveCenterBrandCard
                    className="w-full"
                    clubPublicData={clubSettings.publicData}
                    clubPublicSettings={clubSettings.publicSettings}
                    diveCenterPublicData={diveCenter.publicData}
                    diveCenterPublicSettings={diveCenter.publicSettings}
                  />
                  {clubSettings.publicData?.legalInformation?.legalForm && (
                    <div className="mt-8">
                      <h4 className="font-bold text-app-secondary uppercase">
                        Informations légales
                      </h4>
                      <ClubLegalInformationCard
                        className="mt-4"
                        legalInformation={
                          clubSettings.publicData.legalInformation
                        }
                      />
                    </div>
                  )}
                  <div className="mt-8 flex gap-4 flex-wrap">
                    <AppButton
                      color={'primary-outline-light'}
                      size="normal"
                      icon={AppIcons.action?.edit}
                      onClick={(e) => {
                        e.stopPropagation();
                        openEditDiveCenterDialog(diveCenter);
                      }}
                    >
                      Modifier
                    </AppButton>
                    {isSuperAdmin && (
                      <AppButton
                        color={'primary-outline-light'}
                        size="normal"
                        icon={AppIcons.action?.clone}
                        onClick={(e) => {
                          e.stopPropagation();
                          dialogsState.diveCenterEditDialog.openDialog({
                            mode: 'clone',
                            defaultValue: {
                              diveCenter,
                              clubSettings,
                            },
                          });
                        }}
                      >
                        Dupliquer
                      </AppButton>
                    )}
                    {isSuperAdmin &&
                      diveCenter.publicData.brand.name.toLowerCase() ===
                        'xxx' && (
                        <AppButton
                          color={'danger-bg'}
                          size="normal"
                          icon={AppIcons.action?.clone}
                          onClick={async (e) => {
                            e.stopPropagation();
                            const payload: ProMultiOperationPayload =
                              clubMassiveUpdatorClient.createEmptyPayload({
                                logContext: 'delete dive center',
                                bookingIdsToClean: [],
                              });
                            payload.diveCenters.deletedIds = [diveCenter._id];

                            await clubMassiveUpdatorClient.update(payload);
                          }}
                        >
                          Supprimer
                        </AppButton>
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </AppPageContentContainer>
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
