import { Box } from '@material-ui/core';
import React from 'react';
import { Switch } from 'react-router-dom';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { LoginPage } from '../LoginPage';
import { CreateClubAccountPage } from '../LoginPage/CreateClubAccountPage';
import { ResetPasswordQueryPage } from '../ResetPasswordQueryPage';
import { useAuthRootSpace } from './AuthRootSpace.facade';

export const AuthRootSpace = () => {
  const { loaded } = useAuthRootSpace();

  const { match } = useRouter();

  return loaded ? (
    <>
      <Box
        component="main"
        display="flex"
        p={2}
        pt={8}
        width="100vw"
        style={{ overflowX: 'hidden' }}
      >
        <Switch>
          <AppRoute path={'/'} exact component={CreateClubAccountPage} />
          <AppRoute path={`${match.url}/login`} exact component={LoginPage} />

          <AppRoute
            path={`${match.url}/reset/password/query`}
            exact={true}
            component={ResetPasswordQueryPage}
          />
          <AppRoute path={`${match.url}/*`} redirectToUrl="/" />
        </Switch>
      </Box>
    </>
  ) : null;
};
