import { BookingMember } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  AppGenericStatusIcon,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';

export const ClubDiverCardAttributeMedicalFormStatus = ({
  bookingMember,
  className = '',
}: {
  bookingMember: Pick<BookingMember, 'details'>;
  className?: string;
}) => {
  const medicalFormDateBadgeStatus: AppGenericStatus = useMemo(() => {
    if (!bookingMember?.details?.medicalForm?.date) {
      if (bookingMember?.details?.medicalForm?.filled) {
        return 'success';
      }
      return 'warn';
    }
    if (dateService.getAge(bookingMember?.details?.medicalForm?.date) < 1) {
      return 'success';
    }
    return 'error';
  }, [
    bookingMember?.details?.medicalForm?.filled,
    bookingMember?.details?.medicalForm?.date,
  ]);

  const medicalFormDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(medicalFormDateBadgeStatus),
    [medicalFormDateBadgeStatus],
  );
  return (
    <div className={`${className} ${medicalFormDateBadgeStatusClass}`}>
      <div className="flex gap-1 items-center">
        <AppGenericStatusIcon
          status={medicalFormDateBadgeStatus}
          className={'w-4 h-4 '}
        />
        {bookingMember?.details?.medicalForm?.date ? (
          <div className="grow">
            {dateService.formatUTC(
              bookingMember?.details?.medicalForm?.date,
              'DD/MM/YYYY',
            )}
          </div>
        ) : bookingMember?.details?.medicalForm?.filled === true ? (
          <div className="text-xs">OUI</div>
        ) : null}
      </div>
    </div>
  );
};
