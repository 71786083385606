/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveMode,
  DiveSessionTheme,
  DiveSessionType,
} from '@mabadive/app-common-model';
import { diveSessionThemeAttributesFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppInputTimeRHF,
} from 'src/lib/form';
import { AppEmojiPickerPopoverRHF } from 'src/lib/form/components/AppEmojiPicker';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppMultiCheckboxesRHF } from 'src/lib/form/components/AppMultiCheckboxes';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useDiveModesOptions,
  useDiveSessionThemesOptions,
  useDiveSessionTypesOptions,
} from 'src/pages/_components/options';
import { useClubBoatOptions } from 'src/pages/_components/options/useClubBoatOptions.hook';
import { useDiveSitesOptions } from 'src/pages/_components/options/useDiveSitesOptions.hook';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { DiveSessionEditorDialogStateMode } from '../../models';
import { DiveSessionEditorFormModel } from './model';
export const DiveSessionEditFormRHF = ({
  mode,
  form,
  cloneParticipantsCount,
}: {
  mode: DiveSessionEditorDialogStateMode;
  form: UseFormReturn<DiveSessionEditorFormModel>;
  cloneParticipantsCount?: number;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const clubSettings = diveCenterResume?.clubResume?.clubSettings;
  const sessionConfig = clubSettings?.general?.onlineBooking?.sessionConfig;

  const firstDiveDefaultMaxParticipants =
    clubSettings.general?.onlineBooking?.sessionConfig?.firstDive
      ?.defaultMaxParticipants;
  const snorkelingSupervisedDefaultMaxParticipants =
    clubSettings.general?.onlineBooking?.sessionConfig?.snorkelingSupervised
      ?.defaultMaxParticipants;

  const hasMultiSessionsEnabledRole =
    clubResume.clubSettings.general?.sessions?.multiSessions?.enabled;

  const currentDiveSitesIds = useWatch({
    control,
    name: ['diveSession.diveSiteId', 'diveSession.diveTourSession2.diveSiteId'],
  });

  const [diveSessionBoatsIds] = useWatch({
    control,
    name: ['diveSession.boatsIds'],
  });

  const [
    emojiId,
    diveTour1Time,
    diveTourDiveSiteId,
    theme,
    type,
    defaultDiveMode,
  ] = useWatch({
    control,
    name: [
      'diveSession.emojiId',
      'diveSession.time',
      'diveSession.diveSiteId',
      'diveSession.theme',
      'diveSession.type',
      'diveSession.details.defaultDiveMode',
    ],
  });

  const diveSiteOptions = useDiveSitesOptions({ currentDiveSitesIds });

  const boatsOptions = useClubBoatOptions({
    currentClubBoatsIds: diveSessionBoatsIds,
  });

  const diveSessionThemesOptions = useDiveSessionThemesOptions({
    scope: 'session-settings',
    currentTheme: theme,
  });
  const diveSessionTypesOptions = useDiveSessionTypesOptions({
    scope: 'session-settings',
    currentType: type,
  });
  const diveModesOptions: ValueLabel<DiveMode>[] = useDiveModesOptions({
    format: 'short-ref-label',
    currentDiveMode: defaultDiveMode,
  });

  const showDate = mode === 'create-session' || mode === 'clone-session';

  const cloneParticipants: boolean = useWatch({
    control,
    name: 'cloneParticipants',
  });
  const cloneParticipantsGroups: boolean = useWatch({
    control,
    name: 'cloneParticipantsGroups',
  });
  const multiSessionsEnabled: boolean = useWatch({
    control,
    name: 'multiSessionsEnabled',
  });

  const [
    diveTourFirstDiveEnabled,
    diveTourFirstDiveMaxParticipants,
    diveTourSnorkelingSupervisedEnabled,
    diveTourSnorkelingSupervisedMaxParticipants,
  ] = useWatch({
    control,
    name: [
      'diveSession.bookingConfig.firstDive.enabled',
      'diveSession.bookingConfig.firstDive.maxParticipants',
      'diveSession.bookingConfig.snorkelingSupervised.enabled',
      'diveSession.bookingConfig.snorkelingSupervised.maxParticipants',
    ],
  });

  const onChangeDiveSessionType = useCallback(
    (value: DiveSessionType) => {
      // NOTE: fonction similaire dans ClubSettingsPlanningViewWeekDiveTourForm
      switch (value) {
        case 'first-dive': {
          setValue('diveSession.details.defaultDiveMode', 'first-dive');
          setValue('diveSession.emojiId', 'whale');
          break;
        }
        case 'snorkeling': {
          setValue('diveSession.details.defaultDiveMode', 'snorkeling');
          setValue('diveSession.emojiId', 'diving_mask');
          break;
        }
        case 'snorkeling-tour': {
          setValue(
            'diveSession.details.defaultDiveMode',
            'snorkelingSupervised',
          );
          setValue('diveSession.emojiId', 'diving_mask');
          break;
        }
        case 'watching-tour': {
          setValue('diveSession.details.defaultDiveMode', 'watchingTour');
          setValue('diveSession.emojiId', 'whale');
          break;
        }
        case 'theoretical-training': {
          setValue(
            'diveSession.details.defaultDiveMode',
            'theoretical-training',
          );
          setValue('diveSession.emojiId', 'open_book');

          break;
        }
        case 'dive-auto-supervised': {
          setValue('diveSession.details.defaultDiveMode', 'autoSupervised');
          // setValue('diveSession.emojiId', '🔄'); // TODO: 🔄

          break;
        }
      }
    },
    [setValue],
  );

  const onChangeDiveSessionTheme = useCallback(
    (value: DiveSessionTheme) => {
      // NOTE: fonction similaire dans ClubSettingsPlanningViewWeekDiveTourForm
      const { defaultDiveMode, emojiId } =
        diveSessionThemeAttributesFormatter.getDefaultAttributes(value);
      if (defaultDiveMode) {
        setValue('diveSession.details.defaultDiveMode', defaultDiveMode);
      }
      if (emojiId) {
        setValue('diveSession.emojiId', emojiId);
      }
    },
    [setValue],
  );

  const showDiveSessionType = diveSessionTypesOptions.length > 0;
  const showDiveSessionTheme = diveSessionThemesOptions.length > 0;

  return (
    <>
      <div
        className={'flex flex-col gap-2 md:grid md:grid-cols-2 md:gap-4 p-2'}
      >
        {showDiveSessionType && (
          <AppFormControlRHF
            className={clsx('w-full', !showDiveSessionTheme && 'md:col-span-2')}
            label={'Type de sortie'}
            control={control}
            name={'diveSession.type'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                theme="material-ui"
                {...props}
                options={diveSessionTypesOptions}
                onChange={(value) => onChangeDiveSessionType(value)}
              />
            )}
          />
        )}
        {showDiveSessionTheme && (
          <AppFormControlRHF
            className={clsx('w-full', !showDiveSessionType && 'md:col-span-2')}
            label={'Thème de la sortie'}
            control={control}
            name={'diveSession.theme'}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                theme="material-ui"
                {...props}
                options={diveSessionThemesOptions}
                onChange={(value) => onChangeDiveSessionTheme(value)}
              />
            )}
          />
        )}
        <div className={'grid grid-cols-6 gap-4'}>
          {showDate && (
            <AppFormControlRHF
              className="w-full col-span-2"
              label={'Date'}
              control={control}
              name="date"
              renderComponent={(props) => (
                <AppInputDatePickerMaterialRHF className="w-full" {...props} />
              )}
            />
          )}
          <AppFormControlRHF
            className={`w-full ${showDate ? 'col-span-4' : 'col-span-6'}`}
            label={'Nom'}
            control={control}
            name={'diveSession.name'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
        </div>
        <div className={'w-full flex flex-wrap gap-4'}>
          <AppFormControlRHF
            className={''}
            label={'Spéciale?'}
            control={control}
            name={'diveSession.special'}
            renderComponent={(props) => (
              <AppInputBooleanRHF {...props} type="checkbox" />
            )}
          />
          <AppFormControlRHF
            className={''}
            label={'Icône Emoji'}
            control={control}
            name={'diveSession.emojiId'}
            renderComponent={(props) => <AppEmojiPickerPopoverRHF {...props} />}
          />
        </div>
        <AppFormControlRHF
          className="w-full"
          label={'Prestation forcée'}
          helpDescription="Si vous remplissez ce champ, tous les participants seront inscrit par défaut avec cette prestation."
          control={control}
          name={'diveSession.details.defaultDiveMode'}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              theme="material-ui"
              {...props}
              options={diveModesOptions}
            />
          )}
        />
        <div className={'w-full flex flex-wrap gap-4'}>
          {hasMultiSessionsEnabledRole && (
            <AppFormControlRHF
              className={''}
              label={'Plongées multiples?'}
              control={control}
              name={'multiSessionsEnabled'}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  {...props}
                  type="checkbox"
                  description="X2 BLOCS"
                  onChange={(multiSessionsEnabled) => {
                    if (multiSessionsEnabled) {
                      setValue(
                        'diveSession.diveTourSession2',
                        {
                          // default value
                          time: new Date(diveTour1Time ?? new Date()),
                          diveSiteId: diveTourDiveSiteId,
                          sameStaffSession1: true,
                        },
                        {
                          shouldValidate: true,
                        },
                      );
                    } else {
                      setValue('diveSession.diveTourSession2', null, {
                        shouldValidate: true,
                      });
                    }
                  }}
                />
              )}
            />
          )}
          <AppFormControlRHF
            className={''}
            label={'COMPLET'}
            control={control}
            name={'diveSession.details.isFull'}
            renderComponent={(props) => (
              <AppInputBooleanRHF
                {...props}
                type="checkbox"
                // description="X2 BLOCS"
              />
            )}
          />
        </div>
        <div className={'flex gap-2'}>
          <AppFormControlRHF
            label={'Heure'}
            control={control}
            name={'diveSession.time'}
            required={true}
            renderComponent={(props) => (
              <AppInputTimeRHF
                {...props}
                onChange={(value) => {
                  if (value) {
                    setValue('diveSession.diveTourSession2.time', value, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
            )}
          />

          <AppFormControlRHF
            className="w-full"
            label="Site de plongée"
            control={control}
            name={'diveSession.diveSiteId'}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF {...props} options={diveSiteOptions} />
            )}
          />
        </div>
        {multiSessionsEnabled && (
          <div className={'flex gap-2'}>
            <>
              <AppFormControlRHF
                label={'Heure 2'}
                control={control}
                name={'diveSession.diveTourSession2.time'}
                required={true}
                renderComponent={(props) => <AppInputTimeRHF {...props} />}
              />
              <AppFormControlRHF
                className="w-full"
                label="Site de plongée 2"
                control={control}
                name={'diveSession.diveTourSession2.diveSiteId'}
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={diveSiteOptions}
                  />
                )}
              />
            </>
          </div>
        )}
        <div className="col-span-2">
          <AppFormControlRHF
            className="w-full"
            label={'Bateaux ou ressources'}
            control={control}
            name={'diveSession.boatsIds'}
            renderComponent={(props) => (
              <AppMultiCheckboxesRHF {...props} options={boatsOptions} />
            )}
          />
        </div>
        <div className="col-span-2 flex gap-4 lg:gap-8">
          {sessionConfig?.firstDive?.enabled && (
            <div className={clsx('flex gap-4')}>
              <AppFormControlRHF
                label={'Résa auto Baptême'}
                control={control}
                name={'diveSession.bookingConfig.firstDive.enabled' as const}
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF
                    {...props}
                    onChange={(enabled) => {
                      if (
                        enabled &&
                        !diveTourFirstDiveMaxParticipants &&
                        firstDiveDefaultMaxParticipants > 0
                      ) {
                        setValue(
                          'diveSession.bookingConfig.firstDive.maxParticipants',
                          firstDiveDefaultMaxParticipants,
                          {
                            shouldValidate: true,
                          },
                        );
                      }
                    }}
                  />
                )}
              />
              {diveTourFirstDiveEnabled && (
                <>
                  <AppFormControlRHF
                    className="w-24"
                    label={'Nb de places'}
                    control={control}
                    required={true}
                    validation={{
                      rules: {
                        min: 1,
                      },
                    }}
                    name={
                      'diveSession.bookingConfig.firstDive.maxParticipants' as const
                    }
                    renderComponent={(props) => (
                      <AppInputRHF {...props} fullWidth type="number" />
                    )}
                  />
                </>
              )}
            </div>
          )}
          {sessionConfig?.snorkelingSupervised?.enabled && (
            <div className={clsx('flex gap-4')}>
              <AppFormControlRHF
                label={'Résa auto Randonnée palmée'}
                control={control}
                name={
                  'diveSession.bookingConfig.snorkelingSupervised.enabled' as const
                }
                renderComponent={(props) => (
                  <AppInputBooleanSwitchRHF
                    {...props}
                    onChange={(enabled) => {
                      if (
                        enabled &&
                        !diveTourSnorkelingSupervisedMaxParticipants &&
                        snorkelingSupervisedDefaultMaxParticipants > 0
                      ) {
                        setValue(
                          'diveSession.bookingConfig.snorkelingSupervised.maxParticipants',
                          snorkelingSupervisedDefaultMaxParticipants,
                          {
                            shouldValidate: true,
                          },
                        );
                      }
                    }}
                  />
                )}
              />
              {diveTourSnorkelingSupervisedEnabled && (
                <>
                  <AppFormControlRHF
                    label={'Nb de places'}
                    className="w-24"
                    control={control}
                    required={true}
                    validation={{
                      rules: {
                        min: 1,
                      },
                    }}
                    name={
                      'diveSession.bookingConfig.snorkelingSupervised.maxParticipants' as const
                    }
                    renderComponent={(props) => (
                      <AppInputRHF {...props} fullWidth type="number" />
                    )}
                  />
                </>
              )}
            </div>
          )}
        </div>

        <div className="col-span-2">
          <div className={'sm:grid sm:grid-cols-2 sm:gap-4'}>
            <AppFormControlRHF
              className="w-full"
              label="Marée"
              control={control}
              name={'diveSession.tide'}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
            <AppFormControlRHF
              className="w-full"
              label="Météo"
              control={control}
              name={'diveSession.weather'}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
          </div>
          <div className={'sm:grid sm:grid-cols-2 sm:gap-4'}>
            <AppFormControlRHF
              className="w-full"
              label="Notes importantes"
              control={control}
              name={'diveSession.importantNotes'}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
            <AppFormControlRHF
              className="w-full"
              label="Commentaires"
              control={control}
              name={'diveSession.comment'}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
          </div>
        </div>
        {mode === 'clone-session' && cloneParticipantsCount > 0 && (
          <div className="col-span-2">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <AppFormControlRHF
                className="w-full"
                label={'Dupliquer les participants'}
                control={control}
                name="cloneParticipants"
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    {...props}
                    type="checkbox"
                    description={`inscrire les ${cloneParticipantsCount} participants`}
                  />
                )}
              />
              {!!cloneParticipants && (
                <AppFormControlRHF
                  className="w-full"
                  label={'Dupliquer les palanquées'}
                  control={control}
                  name="cloneParticipantsGroups"
                  renderComponent={(props) => (
                    <AppInputBooleanRHF
                      {...props}
                      type="checkbox"
                      description="conserver les mêmes palanquées"
                    />
                  )}
                />
              )}
              {!!cloneParticipantsGroups && (
                <AppFormControlRHF
                  className="w-full"
                  label={'Dupliquer les moniteurs et guides de palanquées'}
                  control={control}
                  name="cloneGuidesAndInstructors"
                  renderComponent={(props) => (
                    <AppInputBooleanRHF
                      {...props}
                      type="checkbox"
                      description="conserver les mêmes moniteurs"
                    />
                  )}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
