/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';

import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ClubSettingsProductOffersFormModel } from '../../_model';
import { ClubSettingsProductOffersFormOfferProduct } from '../ClubSettingsProductOffersFormOfferProduct';
import { ClubSettingsProductOffersFormOfferProductDetails } from '../ClubSettingsProductOffersFormOfferProductDetails';
import {
  ClubSettingsProductOffersFormOfferCommon_Others_TO_REFACTOR,
  ClubSettingsProductOffersFormOfferCommon_Tarif,
} from '../ClubSettingsProductOffersFormOffer_Common';

export const ClubSettingsProductOffersFormOffer_AdditionalProduct = ({
  form,
  offerField,
  offerFieldIndex,
  offersFieldArray,
}: {
  form: UseFormReturn<ClubSettingsProductOffersFormModel>;
  offerField: FieldArrayWithId<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
  offerFieldIndex: number;
  offersFieldArray: UseFieldArrayReturn<
    ClubSettingsProductOffersFormModel,
    'offers',
    'id'
  >;
}) => {
  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const generalSettings = clubResume.clubSettings.general;
  const publicSettings = clubResume.clubSettings.publicSettings;
  const services = clubResume.clubSettings.services;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const isProduct = offerField.productPackage.type === 'product';

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const i = offerFieldIndex;

  const showDetails = offersFieldArray.fields.length === 1;

  return (
    <div className="grid gap-2 ">
      <div className="grid gap-2 grid-cols-2 sm:grid-cols-4 2xl:grid-cols-8">
        {isProduct && (
          <ClubSettingsProductOffersFormOfferProduct
            form={form}
            offerField={offerField}
            offersFieldArray={offersFieldArray}
          />
        )}
        <ClubSettingsProductOffersFormOfferCommon_Tarif
          form={form}
          offerFieldIndex={offerFieldIndex}
        />
        <ClubSettingsProductOffersFormOfferCommon_Others_TO_REFACTOR
          form={form}
          offerField={offerField}
          offerFieldIndex={offerFieldIndex}
          offersFieldArray={offersFieldArray}
        />
      </div>
      {showDetails && offerField.productPackage.type === 'product' && (
        <ClubSettingsProductOffersFormOfferProductDetails
          form={form}
          offerField={offerField}
          offersFieldArray={offersFieldArray}
        />
      )}

      <div className="">
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Commentaire"
          control={control}
          name={`offers.${i}.productPackage.comment`}
          renderComponent={(props) => (
            <AppInputRHF
              className="w-full"
              {...props}
              placeholder="Commentaire"
              type="text"
              multiline={true}
              rowsMin={2}
            />
          )}
        />
      </div>
    </div>
  );
};
