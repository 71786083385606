import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { DiveSessionTimeDayPeriod } from '@mabadive/app-common-model';
import { Placement } from '@popperjs/core';
import clsx from 'clsx';
import React, { Fragment, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';

export function AppButtonMultiActionsForSession({
  items: itemsInput = [],
  timeDayPeriod,
  isPast,
  position,
  className,
  children,
}: {
  items: AppHamburgerMenuItem[];
  timeDayPeriod: DiveSessionTimeDayPeriod;
  position?: Placement;
  isPast: boolean;
  className?: string;
  children?: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    useState<HTMLDivElement | null>(null);

  const mediaSize = useMediaSizeTailwind();

  const placement: Placement =
    mediaSize === 'xxs' || mediaSize === 'xs' ? 'auto' : position;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    // add offset of 5px to the left:
    // modifiers:
    //   placement === 'right-start'
    //     ? [
    //         {
    //           name: 'offset',
    //           options: {
    //             offset: [0, 5],
    //           },
    //         },
    //       ]
    //     : placement === 'bottom-end'
    //     ? [
    //         {
    //           name: 'offset',
    //           options: {
    //             offset: [0, 5],
    //           },
    //         },
    //       ]
    //     : undefined,
  });
  const items = useMemo(
    () => (itemsInput ?? []).filter((x) => !x.disableMessage),
    [itemsInput],
  );

  const filteredItems = useMemo(() => items.filter((x) => !x.hidden), [items]);

  const primaryItem = items?.[0];

  const secondaryItems = items; // .slice(1, items.length);

  return !filteredItems?.length ? null : (
    <div
      className={clsx('w-full flex rounded-md shadow-sm', className)}
      onClick={(e) => e.stopPropagation()}
    >
      {secondaryItems.length > 0 && (
        <Menu as="div" className={clsx('w-full relative block')}>
          <Menu.Button
            ref={setReferenceElement}
            className={clsx(
              'items-center focus:z-10 justify-between',
              'w-full flex px-1 py-0.5',
              `bg-day-period-${timeDayPeriod}-light2 text-white`,
              `hover:bg-day-period-${timeDayPeriod}-dark`,
              `border border-day-period-${timeDayPeriod}-dark2`,
              isPast && 'opacity-80',
            )}
          >
            {children ? (
              children
            ) : (
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            )}
            <span className="sr-only">Ouvrir le menu</span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-10 origin-top-right rounded-md bg-white px-1 border border-gray-400 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="w-full py-1">
                {secondaryItems.map((item, i) => (
                  <Menu.Item key={i}>
                    {({ active }) => (
                      // <AppButton
                      //   color={'gray-outline-light'}
                      //   size="normal"
                      //   id={`primaryItem${item.id ? `-${item.id}` : ''}`}
                      //   icon={item?.icon}
                      //   onClick={item?.onClick}
                      // >
                      //   {item?.titleShort ?? item?.title}
                      // </AppButton>
                      <button
                        onClick={!item.disableMessage && item.onClick}
                        className={clsx(
                          active
                            ? 'bg-gray-200 text-gray-900'
                            : 'bg-white text-gray-700 hover:bg-gray-200',
                          'w-full text-left font-medium px-2 py-2 text-sm truncate flex items-center gap-2',
                        )}
                      >
                        {item.icon && (
                          <item.icon
                            className={clsx(
                              'flex-shrink-0 h-4 w-4',
                              item.disableMessage
                                ? 'text-gray-400'
                                : item.severity === 'danger'
                                ? 'text-app-red'
                                : item.severity === 'warn'
                                ? 'text-app-orange'
                                : item.severity === 'success'
                                ? 'text-status-success'
                                : 'text-app-blue',
                            )}
                            aria-hidden="true"
                          />
                        )}
                        {item.title}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
}
