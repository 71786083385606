import {
  AppEntityUpdatePatch,
  ClubDiver,
  ClubSettings,
  DiveMode,
  JsonPatchOperation,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  diveModeFormatter,
} from '@mabadive/app-common-services';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { BookingMassiveEditorInnerLocalState } from '../../../../components/BookingMassiveEditorDialog/useBookingMassiveEditorInnerLocalState.hook';
import {
  bookingParticipantEditorDialogChangesBuilderEditDiveMode,
  clubDiverChangesBuilder,
} from '../../../../components/BookingParticipantDialog/BookingParticipantEditorDialog/services/changes-builder';
import {
  BookingResumeParticipantForSession,
  DiverBookingPageUpdateState,
} from '../../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../../useDiverBookingPageGlobalState.hook';

export const defaultParametersUpdator = {
  updateDefaultMember,
  applyDefaultDiveConfigChanges,
};

async function updateDefaultMember({
  localState,
  updatedDivers,
  updatedBookingMembers,
}: {
  localState: BookingMassiveEditorInnerLocalState;
  updatedDivers: AppEntityUpdatePatch[];
  updatedBookingMembers: AppEntityUpdatePatch[];
}): Promise<{
  updateStateLocal: DiverBookingPageUpdateState;
}> {
  const { updateState, setUpdateState } = localState;

  let updateStateLocal = updateState;

  if (updatedDivers?.length) {
    const diversChanges = changeDescriptorManager.updateMany(updatedDivers, {
      changeDescriptors: updateStateLocal.diversChanges,
    });
    updateStateLocal = {
      ...updateStateLocal,
      diversChanges,
    };
  }

  if (updatedBookingMembers?.length) {
    const bookingMembersChanges = changeDescriptorManager.updateMany(
      updatedBookingMembers,
      {
        changeDescriptors: updateStateLocal.bookingMembersChanges,
      },
    );
    updateStateLocal = {
      ...updateStateLocal,
      bookingMembersChanges,
    };
  }

  setUpdateState(updateStateLocal, {
    action: '',
  });

  return { updateStateLocal };
}

async function applyDefaultDiveConfigChanges({
  defaultDiveMode,
  initialDiver,
  finalDiver,
  bookingMemberId,
  updateStateLocal,
  setUpdateState,
  dontConfirmToApplyChanges,
  bookingParticipants,
  clubSettings,
}: {
  defaultDiveMode: DiveMode;
  initialDiver: Pick<ClubDiver, 'defaultDiveConfig'>;
  finalDiver: Pick<ClubDiver, 'defaultDiveConfig'>;
  bookingMemberId: string;
  updateStateLocal: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  dontConfirmToApplyChanges: boolean;
  bookingParticipants: BookingResumeParticipantForSession[];
  clubSettings: ClubSettings;
}) {
  if (defaultDiveMode) {
    const defaultDiveConfigJsonPatchOperations: JsonPatchOperation[] =
      clubDiverChangesBuilder.buildDefaultDiveConfigDiff({
        initialDiver,
        finalDiver,
      });

    // on regarde si des modifs ont eu lieu sur la config par défaut
    // const defaultDiveConfigJsonPatchOperations = updatedDivers[0].patchOperations
    //   .filter(
    //     (po) =>
    //       po.path.startsWith('/defaultDiveConfig') &&
    //       !po.path.startsWith('/defaultDiveConfig/diveMode'),
    //   )
    //   .map((po) => ({
    //     ...po,
    //     path: po.path.substring('/defaultDiveConfig'.length),
    //   }));

    if (defaultDiveConfigJsonPatchOperations.length !== 0) {
      const memberSameDiveModeParticipantsFull = bookingParticipants.filter(
        (x) =>
          x.bookingMemberFull.bookingMember._id === bookingMemberId &&
          x.bookingParticipantFull?.diveSessionParticipant?.diveMode ===
            defaultDiveMode,
      );

      if (memberSameDiveModeParticipantsFull.length > 0) {
        // on propose d'appliquer ces modifs aux inscriptions ayant le même dive mode
        // memberSameDiveModeParticipantsFull.length === 1 &&
        // participantsBySessions.length === 1;
        if (
          dontConfirmToApplyChanges ||
          (await confirmDialog.confirmPromise({
            title: 'Vous avez modifié les paramètres par défaut',
            message:
              memberSameDiveModeParticipantsFull.length === 1
                ? `Souhaitez-vous appliquer ces paramètres à l"inscription "${diveModeFormatter.formatDiveMode(
                    defaultDiveMode,
                    {
                      format: 'long-label',
                      diveModesConf: clubSettings?.ui?.diveMode,
                    },
                  )}" existante?`
                : `Souhaitez-vous appliquer ces paramètres aux ${
                    memberSameDiveModeParticipantsFull.length
                  } inscriptions "${diveModeFormatter.formatDiveMode(
                    defaultDiveMode,
                    {
                      format: 'long-label',
                      diveModesConf: clubSettings?.ui?.diveMode,
                    },
                  )}" existante?`,
            type: 'noYesInfo',
          }))
        ) {
          const { updateState, hasChanges } =
            bookingParticipantEditorDialogChangesBuilderEditDiveMode.updateParticipantsAndProductsByPatchOperations(
              {
                participantsFull: memberSameDiveModeParticipantsFull,
                updateState: updateStateLocal,
                patchOperations: defaultDiveConfigJsonPatchOperations,
              },
            );

          if (hasChanges) {
            setUpdateState(updateState, {
              action: 'apply default dive mode changes',
            });
          }
        }
      }
    }
  }
}
