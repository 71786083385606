/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { useAppBookingViewAuth } from 'src/business/auth/services';
import {
  BookingTabModel,
  BookingTabSingleBookingAutoFixSuggestions,
} from '../../../models';
import { bookingTabSingleBookingsAutoFixSuggestionDetector } from '../../../services';

export function useDiverBookingCardHeaderAutoFix({
  enableAutoFix,
  bookingTabModel,
}: {
  enableAutoFix: boolean;
  bookingTabModel: BookingTabModel;
}): BookingTabSingleBookingAutoFixSuggestions {
  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: bookingTabModel.bookingDiveCenterId,
    isSharedBooking: bookingTabModel.isSharedBooking,
  });

  const isEnabled = enableAutoFix && bookingViewAuth?.edit;

  const {
    bookingCustomerUpdates,
    splitBookings,
    hasAutoFixes,
  }: BookingTabSingleBookingAutoFixSuggestions = useMemo(() => {
    if (!isEnabled) {
      return {
        bookingCustomerUpdates: [],
        splitBookings: undefined,
        hasAutoFixes: false,
      };
    }

    if (bookingTabModel.aggregatedBooking.booking.active) {
      return bookingTabSingleBookingsAutoFixSuggestionDetector.detectAll(
        bookingTabModel,
        {
          inactivePeriod: {
            large: {
              minChunkSessionsSize: 5,
              minDaysBetween2Sessions: 90,
            },
            medium: {
              minChunkSessionsSize: 10,
              minDaysBetween2Sessions: 30,
            },
          },
          occasionalDiver: {
            minChunkSessionsSize: 5,
            maxChunkSessionsSize: 10,
          },
        },
      );
    } else {
      return bookingTabSingleBookingsAutoFixSuggestionDetector.detectOnInactiveBooking(
        bookingTabModel,
      );
    }
  }, [bookingTabModel, isEnabled]);

  return {
    bookingCustomerUpdates,
    splitBookings,
    hasAutoFixes,
  };
}
