import {
  AppUserTrackingSession,
  ClubBaseDataLoadingParams,
  ClubBaseDataLoadingState,
} from '@mabadive/app-common-model';
import { loadableAttributeStoreFactory } from '@mabadive/app-common-services';
import { getBaseStore } from './baseStore';

export const clubStore = {
  trackingSession: loadableAttributeStoreFactory.create<AppUserTrackingSession>(
    getBaseStore(),
    'trackingSession',
  ),
  initialDataLoaded: loadableAttributeStoreFactory.create<boolean>(
    getBaseStore(),
    'clu_initialDataLoaded',
  ),
  loadingParams:
    loadableAttributeStoreFactory.create<ClubBaseDataLoadingParams>(
      getBaseStore(),
      'clu_loadingParams',
    ),
  loadingState: loadableAttributeStoreFactory.create<ClubBaseDataLoadingState>(
    getBaseStore(),
    'clu_loadingState',
  ),
};
