import {
  ClubResumeStaffMember,
  DiveCenterDailyConfig,
  DiveCenterPrivateSettingsEquipment,
  DiveSessionResumeFull,
  DiveSessionTimeDayPeriod,
  SingleEquipmentStats,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  commonDiveSessionReferenceParser,
  equipmentStatsBuilder,
  staffMemberSessionAssignmentBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { DiveSessionCardCommonHeader } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/session-security-sheet-card/DiveSessionCardCommonHeader';
import { clubPlanningUrlBuilder } from 'src/business/club/modules/url-builders';
import { DailySessionsResumeEquipmentStatsTable } from './DailySessionsResumeEquipmentStatsTable';
import { DailySessionsResumeStaffMembersTable } from './DailySessionsResumeStaffMembersTable';

export const DailySessionsResumeRow = ({
  dailyConfigs,
  session,
  sessionNumber,
}: {
  dailyConfigs: DiveCenterDailyConfig[];
  session: DiveSessionResumeFull;
  sessionNumber: number;
}) => {
  const redirectTo = useRedirect();
  const diveCenterResume = useDiveCenterResume();

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );

  const url = useMemo(() => {
    const { dayReference } =
      commonDiveSessionReferenceParser.parseSessionReference(session.reference);
    const focusDate =
      commonDiveSessionReferenceParser.parseDayReference(dayReference);
    const url = clubPlanningUrlBuilder.buildPlanningUrl({
      viewPeriod: 'session',
      focusSessionReference: session.reference,
      focusDate,
    });
    return url;
  }, [session.reference]);

  const display = {
    tide: true,
    weather: true,
    comment: true,
  };

  const participants = session.participants;

  const settingsEquipment: DiveCenterPrivateSettingsEquipment =
    diveCenterResume.privateSettings.equipment;

  const instructorsStaffMembers: ClubResumeStaffMember[] =
    staffMemberSessionAssignmentBuilder.getSessionStaffMembersScubaInstructor({
      session,
      staffMembers: diveCenterResume.staffMembers,
    });

  const equipmentsStats: SingleEquipmentStats[] = useMemo(() => {
    return equipmentStatsBuilder.buildEquipmentsStatsArray({
      participants,
      settingsEquipment,
      session,
      instructorsStaffMembers,
    });
  }, [instructorsStaffMembers, participants, session, settingsEquipment]);

  return (
    <Link
      to={url}
      className="border-4 border-gray-500 bg-gray-50 hover:bg-gray-100 divide-x divide-gray-200 text-gray-600 grid cursor-pointer overflow-hidden"
    >
      <div className="flex items-stretch gap-px overflow-hidden">
        <div
          className={clsx(
            'font-bold px-1 py-0.5 text-white flex flex-col justify-around',
            `bg-day-period-${timeDayPeriod}-dark`,
          )}
        >
          N°{sessionNumber}
        </div>
        <div className="flex-grow">
          <DiveSessionCardCommonHeader
            session={session}
            display={display}
            className="flex-grow overflow-hidden"
          />
        </div>
      </div>
      <div className="py-1 flex flex-col gap-2 lg:flex-row overflow-hidden">
        {session.participants.length > 0 && (
          <div className="lg:min-w-[50%] xl:min-w-[40%] 2xl:min-w-[30%]">
            <DailySessionsResumeStaffMembersTable
              className="w-full border border-gray-500"
              session={session}
              dailyConfigs={dailyConfigs}
            />
          </div>
        )}
        {equipmentsStats.length > 0 && (
          <DailySessionsResumeEquipmentStatsTable
            className="flex-grow w-full lg:w-auto border border-gray-500"
            equipmentsStats={equipmentsStats}
          />
        )}
      </div>
    </Link>
  );
};
