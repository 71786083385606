/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveCenterDailyConfig,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React from 'react';
import { AppTransition } from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIcons,
  AppIconsMaterial,
} from 'src/business/_core/modules/layout/icons';
import { PlanningViewConfig } from 'src/business/club/modules/club-planning/_model';
import { ClubSettingsPlanningBookingConfigInfo } from '../../../../../../../pages/SE-settings/SE-01_dive-center/default-planning/ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek/ClubSettingsPlanningBookingConfigInfo';
import { ClubDialogsState } from '../../../../../../../pages/_dialogs';
import { ClubDiveSessionHeaderComments } from '../../../../club-dive-session/components';
import { PlanningParticipantsLocalState } from '../../../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import {
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayStyle,
  DiveSessionSimpleCardDisplayOptions,
} from '../../../model';
import { CalendarSessionWidthMode } from '../session-common';
import { CalendarStaffInstructorCell } from '../session-common/CalendarParticipantCell/CalendarStaffInstructorCell';
import { DiveSessionSimpleCardDiveGroups } from './DiveSessionSimpleCardDiveGroups';
import { DiveSessionSimpleCardHamburgerMenu } from './DiveSessionSimpleCardHamburgerMenu';
import { DiveSessionSimpleCardHeader } from './DiveSessionSimpleCardHeader';
import { DiveSessionSimpleCardStaffRoles } from './DiveSessionSimpleCardStaffRoles';
import { DiveSessionSimpleCardTime } from './DiveSessionSimpleCardTime';
import { DiveSessionSimpleCardTotalExpandable } from './DiveSessionSimpleCardTotalExpandable';
import {
  UseDiveSessionSimpleCardLocalState,
  useDiveSessionSimpleCardLocalState,
} from './useDiveSessionSimpleCardLocalState.hook';

export const DiveSessionSimpleCard = ({
  className,
  planningParticipantsLocalState,
  dialogsState,
  ...inputParameters
}: {
  session: ClubPlanningLightSessionDef;
  dailyConfig: DiveCenterDailyConfig;
  planningConfig: PlanningViewConfig;
  display: DiveSessionSimpleCardDisplayOptions;
  selectionColor?: ClubPlanningPanelSessionDisplayStyle;
  expandByDefault?: boolean;
  emptyParticipantsCount?: number;
  flexGrow: boolean;
  clickActions: ClubPlanningLightPanelViewClickActions;
  widthMode: CalendarSessionWidthMode;
  planningParticipantsLocalState?: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
  dialogsState: ClubDialogsState; // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  className?: string;
}) => {
  const localState: UseDiveSessionSimpleCardLocalState =
    useDiveSessionSimpleCardLocalState({ ...inputParameters });

  const { state, data, actions } = localState;
  const {
    isExpanded,
    display,
    sessionBorderStyle,
    isEditParticipantRole,
    planningConfig,
    flexGrow,
    widthMode,
    timeDayPeriod,
  } = state;
  const {
    isPast,
    session,
    selectionColor,
    emptyParticipantsCount,
    diveSessionParticipantsStats,
    surfaceSecurityStaffMember,
  } = data;

  return (
    <div
      className={`bg-white border ${sessionBorderStyle}
      ${isExpanded ? 'mb-2' : ''} ${className ?? ''} text-left flex flex-col`}
    >
      <>
        <div
          className={`cursor-pointer ring-opacity-30 ring-day-period-${timeDayPeriod}-dark hover:ring-2`}
          onClick={() => actions.onClickSession(session)}
        >
          <div className="flex flex-col gap-1 max-w-full">
            {planningParticipantsLocalState && dialogsState ? (
              <DiveSessionSimpleCardHamburgerMenu
                className="flex-no-shrink"
                localState={localState}
                planningParticipantsLocalState={planningParticipantsLocalState}
                dialogsState={dialogsState}
              >
                <div className="w-full flex justify-between">
                  {widthMode !== 'tiny' && display.sessionIndex && (
                    <div className="font-medium text-app-xxs2 sm:text-xs text-gray-900">
                      {display.sessionIndex.index}/{display.sessionIndex.total}
                    </div>
                  )}
                  <div className="font-bold px-1 sm:px-5 py-0.5 text-xs text-white">
                    {dateService.formatUTC(session.time, 'HH:mm')}
                  </div>
                  <AppIcons.ui.menu className="h-5 w-5" aria-hidden="true" />
                </div>
              </DiveSessionSimpleCardHamburgerMenu>
            ) : (
              <DiveSessionSimpleCardTime
                displaySessionIndex={display.sessionIndex}
                session={session}
                selectionColor={selectionColor}
                widthMode={widthMode}
              />
            )}
            <DiveSessionSimpleCardHeader
              className="mx-0.5 sm:mx-1 lg:basis-1/2 lg:flex-grow lg:flex-shrink py-1 text-app-xxs leading-4 overflow-hidden"
              session={session}
              display={display}
              widthMode={widthMode}
            />
          </div>
        </div>
        {diveSessionParticipantsStats.sessionsCount > 1 && (
          <div
            className={`block w-full text-xs text-white font-bold text-center px-1 py-0 align-middle bg-day-period-${diveSessionParticipantsStats.timeDayPeriod}-dark`}
          >
            x{diveSessionParticipantsStats.sessionsCount}{' '}
            <span className="hidden sm:inline">TANKS</span>
          </div>
        )}
        <DiveSessionSimpleCardTotalExpandable
          className={`cursor-pointer border-t border-day-period-${timeDayPeriod}-dark group group-hover:bg-gray-200`}
          planningConfig={planningConfig}
          diveSessionParticipantsStats={diveSessionParticipantsStats}
          isExpandable={display.isExpandable}
          isExpanded={isExpanded}
          onClick={() => {
            if (display.isExpandable) {
              state.setIsExpanded(!isExpanded);
            } else {
              actions.onClickSession(session);
            }
          }}
        />
        <ClubSettingsPlanningBookingConfigInfo
          className={'hidden md:block text-xs'}
          bookingConfig={session.bookingConfig}
          diveSessionParticipantsStats={diveSessionParticipantsStats}
        />
      </>
      <ClubDiveSessionHeaderComments
        className="py-1"
        session={session}
        display={display}
        onClick={() => actions.onClickSession(session)}
      />
      {display.addParticipantButton1 &&
        isEditParticipantRole &&
        !session.details?.isFull &&
        diveSessionParticipantsStats.groups.supervisedAll !== 0 && (
          <div
            className={
              'mb-1 mx-1 py-1 text-center border-2 border-blue-100 bg-blue-50 text-blue-200 hover:border-app-blue hover:bg-gray-100 hover:text-app-blue cursor-pointer'
            }
            onClick={() => actions.onClickAddParticipant(session)}
          >
            <AppIconsMaterial.add />
          </div>
        )}
      <AppTransition
        type="scale"
        show={isExpanded}
        className={`bg-day-period-${session.timeDayPeriod}-light`}
      >
        {display.showStaffRoles && (
          <DiveSessionSimpleCardStaffRoles localState={localState} />
        )}
        {display.showDiveGroups !== false &&
          planningConfig.showStaff &&
          surfaceSecurityStaffMember && (
            <CalendarStaffInstructorCell
              className={'ml-1 mt-px'}
              staffFirstNameBefore={planningConfig.staffFirstNameBefore}
              staffMember={surfaceSecurityStaffMember}
              diveSession={session}
              isPast={isPast}
              role={'surface-security'}
              widthMode={widthMode}
            />
          )}
        {display.showDiveGroups !== false && (
          <DiveSessionSimpleCardDiveGroups localState={localState} />
        )}
        {emptyParticipantsCount !== 0 && (
          <div
            className={'bg-white mx-0 border-l-4 pl-px cursor-pointer'}
            onClick={() => actions.onClickSession(session)}
          >
            <div className={''}>
              {Array.from(Array(emptyParticipantsCount), (e, i) => {
                return <div key={i} className={'h-8 mt-px'}></div>;
              })}
            </div>
          </div>
        )}
      </AppTransition>
      {flexGrow && <div className="flex-grow"></div>}
      {(display.openSessionButton || display.addParticipantButton2) &&
        isEditParticipantRole && (
          <div className="mt-2">
            {display.addParticipantButton2 && !session.details?.isFull && (
              <div
                className={
                  'mb-1 mx-1 py-1 text-center border-2 border-blue-100 bg-blue-50 text-blue-200 hover:border-app-blue hover:bg-gray-100 hover:text-app-blue cursor-pointer'
                }
                title="Ajouter un participant"
                onClick={() =>
                  actions.onClickAddParticipant &&
                  actions.onClickAddParticipant(session)
                }
              >
                <AppIconsMaterial.add />
              </div>
            )}
            {display.openSessionButton && (
              <div className="mb-1 mx-1 flex flex-col sm:flex-row sm:justify-between gap-1">
                <div
                  className={
                    'w-full py-0.5 text-center border-2 border-red-100 bg-red-50 text-red-200 hover:border-red-500 hover:bg-gray-100 hover:text-red-500 cursor-pointer flex justify-center'
                  }
                  title="Ouvrir le détail de la session"
                  onClick={() =>
                    actions.onClickSessionOpenButton &&
                    actions.onClickSessionOpenButton(session)
                  }
                >
                  <AppHeroIcons.actionOpen className="w-6" />
                </div>
                {actions.onClickEditSessionDiveGroups &&
                  !(session.status === 'deleted') &&
                  !isPast && (
                    <div
                      className={
                        'w-full py-0.5 text-center border-2 border-red-100 bg-red-50 text-red-200 hover:border-red-500 hover:bg-gray-100 hover:text-red-500 cursor-pointer flex justify-center'
                      }
                      title="Editer les palanquées"
                      onClick={() => {
                        actions.onClickEditSessionDiveGroups(session);
                      }}
                    >
                      <AppHeroIcons.group className="w-6" />
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
    </div>
  );
};
