import { ClubParticipant } from '@mabadive/app-common-model';
import React, { CSSProperties, FC, useMemo } from 'react';

export const DiveGuideLevelBadge: FC<{
  participant: Pick<ClubParticipant, 'certificationReference'>;
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
}> = ({ participant, className, style, onClick }) => {
  const label = useMemo(() => {
    const level = participant.certificationReference;
    if (level) {
      return level;
    }
    return 'DG';
  }, [participant.certificationReference]);

  return (
    <div
      style={style}
      className={`text-center rounded text-white 
      bg-dive-mode-instructor
      ${className ?? ''}
    `}
      onClick={onClick}
    >
      {label}
    </div>
  );
};
