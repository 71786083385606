import React, { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export const AppLinkStopPropagation = ({
  children,
  ...props
}: PropsWithChildren<LinkProps<any>>) => (
  <Link
    {...props}
    onClick={(e) => {
      e.stopPropagation();
      props.onClick && props.onClick(e);
    }}
  >
    {children}
  </Link>
);
