import { useAppSecurityUser } from './useAppSecurityUser.hook';
import { useAppSecurityUserClubAuthorizations } from './useAppSecurityUserClubAuthorizations.hook';

export type AppBookingViewAuth = {
  view: boolean;
  edit: boolean;
  switchToEdit: boolean;
};

export function useAppBookingViewAuth({
  bookingDiveCenterId,
  isSharedBooking,
}: {
  bookingDiveCenterId: string;
  isSharedBooking: boolean;
}): AppBookingViewAuth {
  const securityUser = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();
  const isCurrentUserDiveCenter =
    isSharedBooking || securityUser?.diveCenterIdFocus === bookingDiveCenterId;

  return {
    view: az.view?.participant?.booking,
    edit: az?.edit?.participant?.booking && isCurrentUserDiveCenter,
    switchToEdit:
      az?.edit?.participant?.booking &&
      !isCurrentUserDiveCenter &&
      az.multiDiveCenters.switchCenter,
  };
}
