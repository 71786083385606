/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubDiverSelectorDialogCreateDiverFormModel } from '@mabadive/app-common-model';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  clubParticipantUrlBuilder,
  useParticipantBackUrl,
} from 'src/business/club/modules/url-builders';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButtonsBar,
  AppPageContainerWithFixedBars,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { GenericNavigationContext } from '../../../ClubParticipantNavigationContext/GenericNavigationContext.type';
import { ClubDiverSelectorDialogCreateDiverMemberForm } from '../../DiverBookingPage/components/ClubDiverSelectorDialog/ClubDiverSelectorDialogCreateDiverMemberForm';
import { ClubDiverSelectorDialogInputState } from '../../DiverBookingPage/components/ClubDiverSelectorDialog/model';
import { createSimpleDiverAndBookingPagePersister } from './createSimpleDiverAndBookingPagePersister.service';
const DEFAULT_FORM_VALUE: ClubDiverSelectorDialogCreateDiverFormModel = {
  diver: {},
  massiveCreation: {
    enabled: false,
    quantity: 10,
    firstNumber: 1,
  },
};

export const CreateSimpleDiverAndBookingPage = ({
  navigationContext,
}: {
  navigationContext: GenericNavigationContext;
}) => {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const [diverSelectorDialogState, setDiverSelectorDialogState] =
    useState<ClubDiverSelectorDialogInputState>({
      isOpen: true,
      booking: {} as any, // fake booking
    });

  const form = useForm<ClubDiverSelectorDialogCreateDiverFormModel>({
    defaultValues: DEFAULT_FORM_VALUE,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });

  const hasFormChanges = useMemo(
    () =>
      form &&
      (formState.isDirty || formState.isValid) &&
      formValue?.diver &&
      Object.values(formValue.diver).filter((x) => !!x).length !== 0,
    [form, formState.isDirty, formState.isValid, formValue.diver],
  );
  const backUrl = useParticipantBackUrl({
    navigationContext,
  });

  const back = useCallback(() => {
    redirectTo(backUrl);
  }, [backUrl, redirectTo]);

  const cancel = useCallback(() => {
    back();
    // if (hasFormChanges) {
    //   form.reset(DEFAULT_FORM_VALUE);
    // } else {
    //   back();
    // }
  }, [back]);

  const redirectoToBookingCreateParticipant = useCallback(
    ({ diverId }: { diverId: string }) => {
      redirectTo(
        clubParticipantUrlBuilder.buildTabUrl({
          tabId: 'booking',
          navigationContext: {
            origin: navigationContext?.origin ?? 'planning',
            diveSessionReference: navigationContext.diveSessionReference,
            diverId,
            action: 'create-booking-participant',
          },
        }),
      );
    },
    [
      navigationContext.diveSessionReference,
      navigationContext?.origin,
      redirectTo,
    ],
  );

  const createDiver = useCallback(() => {
    handleSubmit(
      async (formValue: ClubDiverSelectorDialogCreateDiverFormModel, event) => {
        const { success, diverId } =
          await createSimpleDiverAndBookingPagePersister.persistChanges({
            formValue,
            clubReference,
          });
        if (success) {
          redirectoToBookingCreateParticipant({
            diverId,
          });
        }
      },
      (err) => {
        appLogger.error('Unexpected error', err);
      },
    )();
  }, [clubReference, handleSubmit, redirectoToBookingCreateParticipant]);

  return (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-white"
      footerBar={() => (
        <AppButtonsBar hasChanges={true}>
          <>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionCancel}
              tabIndex={500}
              color="gray-outline-light"
              onClick={cancel}
            >
              {hasFormChanges ? 'Annuler' : 'Fermer'}
            </AppButton>
            {hasFormChanges && (
              <AppButton
                disabled={!formState.isValid}
                size="normal"
                icon={AppHeroIcons.actionAdd}
                color="primary-outline-light"
                onClick={() => {
                  createDiver();
                }}
              >
                Ajouter
              </AppButton>
            )}
          </>
        </AppButtonsBar>
      )}
    >
      <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
        Nouveau plongeur
      </h3>
      <ClubDiverSelectorDialogCreateDiverMemberForm
        showConfirmButton={false}
        allowNameOnly={false}
        className="my-5 mx-2"
        form={form}
        autoFillLastName={undefined}
        onSubmit={() => createDiver()}
        isDiverWithMember={false}
      />
    </AppPageContainerWithFixedBars>
  );
};
