/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubDiverFull } from '@mabadive/app-common-model';
import React from 'react';
import {
  useAutoFocus,
  useMediaSizeTailwind,
} from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import { SelectDiverComponent } from 'src/business/club/modules/_common/form';

export const SelectOrCreateDiverAndBookingPannel = ({
  onSelectExistingDiver,
  onSelectCreate1Diver,
  onSelectCreateManyDivers,
}: {
  onSelectExistingDiver: ({ diver }: { diver: ClubDiverFull }) => void;
  onSelectCreate1Diver: () => void;
  onSelectCreateManyDivers: () => void;
}) => {
  const autoFocus = useAutoFocus();
  const mediaSize = useMediaSizeTailwind();

  return (
    <>
      <h3 className="mt-2 text-center lg:text-left px-2 text-xl font-extrabold bg-gray-600 text-white uppercase">
        Nouvelle réservation
      </h3>
      <div className="mt-5 h-full flex flex-col gap-8">
        <div>
          <h3 className="mt-2 text-left text-base font-extrabold text-gray-600 uppercase">
            Plongeur existant
          </h3>
          <SelectDiverComponent
            className={'mt-3'}
            searchSuggestions={[]}
            placeholder={
              mediaSize === 'xxs' || mediaSize === 'xs' || mediaSize === 'sm'
                ? 'NOM ou PRENOM'
                : 'Recherche par NOM ou PRÉNOM'
            }
            autoFocus={autoFocus}
            onChange={(diver) => {
              if (diver) {
                onSelectExistingDiver({
                  diver,
                });
              }
            }}
          />
          <div className="w-full mt-2 text-gray-600 ">
            <AppHeroIcons.infoArrow className="inline w-4 h-4 mr-1" /> recherche
            d'un plongeur existant
          </div>
        </div>
        <div className="">
          <h3 className="mt-2 text-left text-base font-extrabold text-gray-600 uppercase">
            Nouveau plongeur{' '}
          </h3>

          <AppButton
            className="mt-2 sm:mt-4 w-48 uppercase font-bold"
            size="normal"
            icon={EntitiesIcons.divers}
            color="primary-bg"
            onClick={() => {
              onSelectCreate1Diver();
            }}
          >
            + plongeur
          </AppButton>
          <div className="w-full mt-2 text-gray-600 ">
            <AppHeroIcons.infoArrow className="inline w-4 h-4 mr-1" />
            ajout d'un seul plongeur
          </div>
          <div className="w-full mt-2 text-gray-600 ">
            <AppHeroIcons.infoArrow className="inline w-4 h-4 mr-1" />
            inscription en 2 étapes (contact, puis plongeur)
          </div>
        </div>
        <div className="">
          <h3 className="mt-2 text-left text-base font-extrabold text-gray-600 uppercase">
            Nouveau groupe de plongeurs
          </h3>

          <AppButton
            className="mt-2 sm:mt-4 w-48 uppercase font-bold"
            size="normal"
            icon={EntitiesIcons.divers}
            color="secondary-bg"
            onClick={() => {
              onSelectCreateManyDivers();
            }}
          >
            + réservation
          </AppButton>
          <div className="w-full mt-2 text-gray-600 ">
            <AppHeroIcons.infoArrow className="inline w-4 h-4 mr-1" /> familles,
            amis, groupes, clubs...
          </div>
        </div>
      </div>
    </>
  );
};
