import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ClubSettingsOnlineBookingProductsPageViewState } from './_model';
import { clubSettingsOnlineBookingProductsPageUrlManager } from './_services/clubSettingsOnlineBookingProductsPageUrlManager.service';
export function useClubSettingsOnlineBookingProductsPageUpdateURL(
  viewState: ClubSettingsOnlineBookingProductsPageViewState,
  { enableRouteParams }: { enableRouteParams: boolean },
) {
  const history = useHistory();
  useEffect(() => {
    if (enableRouteParams) {
      // update url params
      const searchParams: string[] =
        clubSettingsOnlineBookingProductsPageUrlManager.buildSearchParams(
          viewState,
        );

      history.replace({
        pathname: window.location.pathname,
        search: searchParams?.length ? `?${searchParams.join('&')}` : undefined,
      });
    }
  }, [enableRouteParams, history, viewState]);
}
