/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { DiveSessionEditorMassiveFormModel } from '../_model';

export function useFormParticipantsCheckedState({
  participantsIds,
  participantsFormIndexesById,
  form,
}: {
  participantsIds: string[];
  form: UseFormReturn<DiveSessionEditorMassiveFormModel, any>;
  participantsFormIndexesById: {
    [participantId: string]: number;
  };
}) {
  const { control, setValue } = form;

  const participantCheckFormNames = useMemo(
    () =>
      participantsIds.map((participantIds) => {
        const participantFormIndex =
          participantsFormIndexesById[participantIds];
        return `participants.${participantFormIndex}.selected` as const;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [participantsFormIndexesById, ...participantsIds],
  );

  const participantsChecked = useWatch({
    control,
    name: participantCheckFormNames,
  });

  const participantsCheckedIds = useMemo(
    () =>
      participantsIds.filter((participantIds) => {
        const participantFormIndex =
          participantsFormIndexesById[participantIds];
        return participantsChecked[participantFormIndex];
      }),
    [participantsChecked, participantsFormIndexesById, participantsIds],
  );

  const participantsCheckedCount = participantsCheckedIds.length;

  const isGroupSelected = useMemo(() => {
    const firstUnchecked = participantsChecked.find((x) => !x);
    return firstUnchecked === undefined;
  }, [participantsChecked]);

  const setGroupCheck = useCallback(
    (value: boolean) => {
      participantCheckFormNames.forEach((participantCheckFormName, i) => {
        setValue(participantCheckFormName, value);
      });
    },
    [participantCheckFormNames, setValue],
  );
  const toggleGroupCheck = useCallback(() => {
    setGroupCheck(!isGroupSelected);
  }, [isGroupSelected, setGroupCheck]);
  return {
    formNames: participantCheckFormNames,
    participantsCheckedIds,
    participantsCheckedCount,
    isGroupSelected,
    toggleGroupCheck,
    setGroupCheck,
  };
}
