import { AppTabsBarTab } from 'src/business/_core/modules/layout';
import { ClubListsTabId } from './_common';

export const clubListsUrlBuilder = {
  buildDiversListUrl,
  buildDuplicatedUrl,
  buildPaymentsListUrl,
  buildBookingsListUrl,
  buildOrdersListUrl,
  buildClubListsTabUrl,
  buildDepositsListUrl,
};

function buildDiversListUrl() {
  return '/club/lists/divers';
}

function buildDuplicatedUrl() {
  return '/club/plongeurs/doublons';
}

function buildPaymentsListUrl() {
  return '/club/lists/payments';
}
function buildDepositsListUrl() {
  return '/club/lists/deposits';
}
function buildBookingsListUrl() {
  return '/club/lists/bookings';
}
function buildOrdersListUrl() {
  return '/club/lists/orders';
}

function buildClubListsTabUrl(tab: AppTabsBarTab<ClubListsTabId>) {
  if (tab) {
    return `/club/lists/${tab.urlSuffix}`;
  }

  return '/club/lists';
}
