import {
  ClubSettings,
  ClubSettingsPlanningPeriodConfig,
} from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';
import {
  ClubSettingsPlanningViewDayDiveTourModel,
  ClubSettingsPlanningViewDayDiveTourSessionModel,
  ClubSettingsPlanningViewDayModel,
} from '../../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek';
import {
  ClubSettingsPlanningEditPageMode,
  ClubSettingsPlanningViewWeekDayDiveTourFormModel,
  ClubSettingsPlanningViewWeekDayDiveTourSessionFormModel,
  ClubSettingsPlanningViewWeekDayFormModel,
  ClubSettingsPlanningViewWeekFormModel,
} from '../model';

export const clubSettingsPlanningViewWeekFormInitialValueBuilder = {
  buildInitialFormValue,
};

function buildInitialFormValue({
  weekDays,
  initialPeriodConfig,
  mode,
  clubSettings,
}: {
  weekDays: ClubSettingsPlanningViewDayModel[];
  initialPeriodConfig: ClubSettingsPlanningPeriodConfig;
  mode: ClubSettingsPlanningEditPageMode;
  clubSettings: ClubSettings;
}) {
  const model: ClubSettingsPlanningViewWeekFormModel = {
    enabled: initialPeriodConfig.enabled,
    name:
      mode === 'edit' || mode === 'edit-session-booking'
        ? initialPeriodConfig.name
        : 'Saison XXX',
    conflictMode: initialPeriodConfig.conflictMode,
    beginDate: initialPeriodConfig.beginDate,
    endDate: initialPeriodConfig.endDate,
    days: weekDays.map((wd) => {
      const day: ClubSettingsPlanningViewWeekDayFormModel = {
        diveTours: wd.diveTours.map((dt) =>
          buildDiveTourModel({ dt, mode, clubSettings }),
        ),
      };
      return day;
    }),
  };
  return model;
}

function buildDiveTourModel({
  dt,
  mode,
  clubSettings,
}: {
  dt: ClubSettingsPlanningViewDayDiveTourModel;
  mode: ClubSettingsPlanningEditPageMode;
  clubSettings: ClubSettings;
}): ClubSettingsPlanningViewWeekDayDiveTourFormModel {
  const diveTour: ClubSettingsPlanningViewWeekDayDiveTourFormModel = {
    reference: dt.reference,
    isOpen: dt.isOpen,
    type: dt.type,
    theme: dt.theme,
    defaultDiveMode: dt.defaultDiveMode,
    session1: buildDiveTourSessionModel(dt.session1),
    session2: buildDiveTourSessionModel(dt.session2),
    multiSessionsEnabled: !!dt.session2,
    bookingConfigs: dt.bookingConfigs ?? [],
  };
  if (
    mode === 'edit-session-booking' &&
    diveTour.bookingConfigs?.length === 0
  ) {
    const sessionConfig = clubSettings?.general?.onlineBooking?.sessionConfig;
    diveTour.bookingConfigs = [
      {
        reference: uuidGenerator.random(),
        conf: {
          firstDive: {
            enabled: false,
            maxParticipants:
              sessionConfig?.firstDive?.defaultMaxParticipants ?? 0,
          },
          snorkelingSupervised: {
            enabled: false,
            maxParticipants:
              sessionConfig?.snorkelingSupervised?.defaultMaxParticipants ?? 0,
          },
        },
      },
    ];
  }
  return diveTour;
}

function buildDiveTourSessionModel(
  s: ClubSettingsPlanningViewDayDiveTourSessionModel,
): ClubSettingsPlanningViewWeekDayDiveTourSessionFormModel {
  if (!s) {
    return;
  }
  const diveTourSession: ClubSettingsPlanningViewWeekDayDiveTourSessionFormModel =
    {
      name: s.name,
      special: s.special,
      diveSiteId: s.diveSite?._id,
      boatsIds: (s.boats ?? []).map((x) => x._id),
      emojiId: s.emojiId,
      time: s.time,
    };
  return diveTourSession;
}
