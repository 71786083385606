import { DiveCenterDailyConfig } from '@mabadive/app-common-model';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import {
  appLoader,
  LoadableContentLoadingMode,
  LoadableContentPartial,
} from '../../app-loading';
import { dailyConfigGraphqlFetcher } from './graphql';
import { dailyConfigStore } from './store';

export const dailyConfigRepository = {
  findManyWithSubscription,
};

function findManyWithSubscription(
  criteria: {
    clubReference: string;
    diveCenterId: string;
    minDateInclusive: Date;
    maxDateExclusive: Date;
  },
  {
    mode,
  }: {
    mode: LoadableContentLoadingMode;
  },
): Observable<LoadableContentPartial<DiveCenterDailyConfig[]>> {
  if (!criteria) {
    const loadableContent: LoadableContentPartial<DiveCenterDailyConfig[]> = {
      contentState: 'partial',
      lastActionStatus: 'in-progress',
      content: [],
    };
    return of(loadableContent);
  }

  const { clubReference, diveCenterId, minDateInclusive, maxDateExclusive } =
    criteria;

  const localLoader$ = appLoader.load(
    _findManyLocal({ diveCenterId, minDateInclusive, maxDateExclusive }),
    { type: mode === 'force-reload' ? 'partial' : 'full', defaultValue: [] },
  );
  const remoteLoader$ = appLoader.load(
    _findManyRemote({
      clubReference,
      diveCenterId,
      minDateInclusive,
      maxDateExclusive,
    }),
    {
      type: 'full',
      defaultValue: [],
      isSubscription: true,
    },
  );

  if (mode === 'local-only') {
    return localLoader$;
  }

  return localLoader$.pipe(
    switchMap((localResults) =>
      remoteLoader$.pipe(
        filter(
          (remoteResults) => remoteResults.lastActionStatus !== 'in-progress',
        ),
        startWith(localResults),
      ),
    ),
  );
}

function _findManyLocal({
  diveCenterId,
  minDateInclusive,
  maxDateExclusive,
}: {
  diveCenterId: string;
  minDateInclusive: Date;
  maxDateExclusive: Date;
}) {
  return combineLatest([
    dailyConfigStore.loadingState.get(),
    dailyConfigStore.dailyConfigCollection.getAll(),
  ]).pipe(
    first(),
    map(([loadingState, data]) => {
      if (
        loadingState.loaded.loadedWeekTimestamps.includes(
          minDateInclusive.getTime(),
        )
      ) {
        // data is already in cache
        const content = data.filter((x) => {
          if (x.diveCenterId !== diveCenterId) {
            return false;
          }
          return (
            minDateInclusive <= new Date(x.date) &&
            new Date(x.date) < maxDateExclusive
          );
        });

        return content;
      } else {
        return undefined;
      }
    }),
  );
}

function _findManyRemote({
  clubReference,
  diveCenterId,
  minDateInclusive,
  maxDateExclusive,
}: {
  clubReference: string;
  diveCenterId: string;
  minDateInclusive: Date;
  maxDateExclusive: Date;
}) {
  return dailyConfigGraphqlFetcher
    .findMany(
      {
        clubReference,
        diveCenterId,
        minDateInclusive,
        maxDateExclusive,
      },
      { type: 'subscription' },
    )
    .pipe(
      tap((dailyConfigs) => {
        dailyConfigStore.addManyToStore({
          minDateInclusive,
          dailyConfigs,
        });
      }),
    );
}
