/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubResumeStaffMember } from '@mabadive/app-common-model';
import { staffMemberFormatter } from '@mabadive/app-common-services';
import React, { FC } from 'react';
import { StaffLevelBadge } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppFormLabel } from 'src/lib/form';
import { DiveSessionEditorDialogLocalState } from '../../../../useDiveSessionEditorDialogLocalState.hook';

export const DiveSessionDialogTab3EditableGroupFake: FC<{
  localState: DiveSessionEditorDialogLocalState;
  staffMember: ClubResumeStaffMember;
  onAddSelectedParticipantsToFakeGroup: () => any;
  onRemoveFakeGroup: () => any;
}> = ({
  localState,
  staffMember,
  onAddSelectedParticipantsToFakeGroup,
  onRemoveFakeGroup,
}) => {
  const {
    form,
    clubReference,
    aggregatedData: { diveSession },
    data,
    actions,
    isMultiSessionConfigForStaff,
    staffMembersFull,
  } = localState;

  return (
    <div className={'w-full border-2 border-l-4 pl-px p-1 text-gray-600'}>
      <div
        className={'w-full flex justify-between gap-2 px-2 py-1 items-start'}
      >
        <div
          className={`w-full ${
            isMultiSessionConfigForStaff
              ? 'flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-2'
              : ''
          } px-2 py-1`}
        >
          <div className={''}>
            <AppFormLabel label="Moniteur de plongée" />
            <div className="flex gap-1">
              <StaffLevelBadge
                className="rounded px-0.5 py-0.5 w-12 text-xs"
                staffMember={staffMember}
                date={diveSession.time}
              />
              {staffMemberFormatter.formatStaffMember(staffMember, {
                format: 'name',
              })}
            </div>
          </div>
        </div>
        <div className="flex justify-end flex-wrap gap-2">
          {
            <div>
              <AppButton
                className={
                  data.selectedUngrouppedParticipantsIds.length !== 0
                    ? ''
                    : 'invisible'
                }
                size="normal"
                icon={AppHeroIcons.actionAddUser}
                color={'primary-outline-light'}
                onClick={() => {
                  onAddSelectedParticipantsToFakeGroup();
                }}
              >
                {/* <span className="font-bold hidden xl:flex">Ajouter</span> */}
              </AppButton>
            </div>
          }
          <div>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionDelete}
              color={'gray-outline-light'}
              onClick={() => {
                onRemoveFakeGroup();
              }}
            >
              {/* <span className="font-bold hidden xl:flex">Supprimer</span> */}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};
