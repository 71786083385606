/* eslint-disable @typescript-eslint/no-unused-vars */
import { arrayBuilder } from '@mabadive/app-common-services';
import { SetStateAction, useCallback } from 'react';
import { OnClickAssignProductFn } from '../DiverBillingSessionsTable/OnClickAssignProductFn.type';

export function useDiverPurchaseCommonEditorDialogOnClickAssignProducts({
  updatedAssociatedBookingProductIds,
  setAssociatedBookingProductIds,
}: {
  updatedAssociatedBookingProductIds: string[];
  setAssociatedBookingProductIds: (value: SetStateAction<string[]>) => void;
}): {
  onClickAssignProducts: OnClickAssignProductFn;
} {
  const onClickAssignProducts: OnClickAssignProductFn = useCallback(
    ({ bookingProducts, operation }): void => {
      const bookingProductsIds = bookingProducts.map((x) => x._id);
      const associatedBookingProductIds =
        // bookingProduct.purchaseStatus === 'purchased'
        operation === 'unassign'
          ? updatedAssociatedBookingProductIds.filter(
              (x) => !bookingProductsIds.includes(x),
            )
          : arrayBuilder.filterDuplicated(
              updatedAssociatedBookingProductIds.concat(bookingProductsIds),
            );

      setAssociatedBookingProductIds(associatedBookingProductIds);
    },
    [setAssociatedBookingProductIds, updatedAssociatedBookingProductIds],
  );

  return {
    onClickAssignProducts,
  };
}
