import { AdminBaseData } from '@mabadive/app-common-model';
import { accountStore, adminStore } from 'src/business/_core/store';

export const adminBaseDataStore = {
  setData,
  clearData,
};

function setData(adminBaseData: AdminBaseData, actionId?: string): void {

  if (actionId) {
    actionId = 'adminBaseDataStore_setData';
  }
  accountStore.profile.set(adminBaseData.accountProfile, actionId);
  accountStore.authUser.set(adminBaseData.authUser, actionId);
  adminStore.clubs.setAll(adminBaseData.clubs, actionId);
  adminStore.users.setAll(adminBaseData.users, actionId);
  adminStore.accountProfiles.setAll(adminBaseData.accountProfiles, actionId);

  adminStore.initialDataLoaded.set(true, actionId);
}

function clearData(actionId?: string): void {

  if (actionId) {
    actionId = 'adminBaseDataStore_clearData';
  }

  adminStore.initialDataLoaded.set(false, actionId);

  accountStore.profile.unload(actionId);
  accountStore.authUser.unload(actionId);
  adminStore.clubs.unload(actionId);
  adminStore.users.unload(actionId);
}

