import { AppProcessMonitorState } from '../monitor';
import { AppProcessesStats } from './AppProcessesStats';


export const processStatsBuilder = {
  build,
}

function build({
  processes,
}: {
  processes: AppProcessMonitorState<any>[];
}): AppProcessesStats {
  return processes.reduce((acc, process) => {
    return {
      ...acc,
      processes: acc.processes + 1,
      values: acc.values + process.messagesHistory.length,
      running: acc.running + (process.state === 'running' ? 1 : 0),
      success: acc.success + (process.state === 'success' ? 1 : 0),
      error: acc.error + (process.state === 'error' ? 1 : 0),
    }
  }, {
      processes: 0,
      values: 0,
      running: 0,
      success: 0,
      error: 0,
    });
}