/* eslint-disable @typescript-eslint/no-unused-vars */
import { useClubResume } from 'src/business/club/data/hooks';
import { useRedirect } from 'src/business/_core/data/hooks';
export function useClubSettingsPlanningViewPageLocalState() {
  const redirectTo = useRedirect();

  const clubResume = useClubResume();

  return {};
}

export type ClubSettingsPlanningViewPageLocalState = ReturnType<
  typeof useClubSettingsPlanningViewPageLocalState
>;
