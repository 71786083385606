/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC, useCallback, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppInputBoolean } from 'src/lib/form';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DiverPurchasePaymentEditorDialogStateMode } from '../../../models';
import { DiverPurchasePaymentEditorPaymentChangesModel } from '../DiverPurchasePaymentEditorPaymentChangesModel.type';
import { DiverPurchasePaymentEditorFormModel } from './DiverPurchasePaymentEditorForm';
import { DiverPurchasePaymentEditorPackagesTableRow_BookingDeposit } from './DiverPurchasePaymentEditorPackagesTableRow_BookingDeposit';
import { DiverPurchasePaymentEditorPackagesTableRow_Package } from './DiverPurchasePaymentEditorPackagesTableRow_Package';

export const DiverPurchasePaymentEditorPackagesTable: FC<{
  isEditEnabled: boolean;
  mode: DiverPurchasePaymentEditorDialogStateMode;
  form: UseFormReturn<DiverPurchasePaymentEditorFormModel>;
  paymentChangesModel: DiverPurchasePaymentEditorPaymentChangesModel;
  className?: string;
}> = ({ isEditEnabled, mode, form, paymentChangesModel, className }) => {
  const mainCurrency = useAppCurrencyMain();

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const [
    formPackages,
    bookingDeposits,
    usedCurrencyIsoCode,
    usedCurrencyAmount,
  ] = useWatch({
    control,
    name: [
      'packages',
      'bookingDeposits',
      'usedCurrencyIsoCode',
      'usedCurrencyAmount',
    ],
  });

  const totalRows =
    paymentChangesModel.packages.length +
    paymentChangesModel.bookingDeposits.length;

  const showCheckboxCells = isEditEnabled && mode === 'create' && totalRows > 1;

  const isCheckAllEnabled = useMemo(
    () => formPackages.find((x) => !x.selected) === undefined,
    [formPackages],
  );

  const toogleCheckAll = useCallback(
    (newValue: boolean) => {
      formPackages.forEach((p, i) => {
        if (p.selected !== newValue) {
          setValue(`packages.${i}.selected`, newValue, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }
      });
    },
    [formPackages, setValue],
  );

  return (
    <table
      className={clsx('divide-y divide-gray-200 text-gray-600', className)}
    >
      <thead className="bg-gray-50">
        <tr>
          <th className={`${showCheckboxCells ? '' : 'hidden'}`}>
            <AppInputBoolean
              type="checkbox"
              value={isCheckAllEnabled}
              onChange={(value: boolean) => {
                toogleCheckAll(value);
              }}
            />
          </th>
          <th
            scope="col"
            className="px-2 py-1 sm:px-4 sm:py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Plongeur
          </th>
          <th
            scope="col"
            className="hidden lg:table-cell px-2 py-1 sm:px-4 sm:py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Prestation
          </th>
          <th
            scope="col"
            className="hidden md:table-cell px-2 py-1 sm:px-4 sm:py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Prix
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell px-2 py-1 sm:px-4 sm:py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {mode === 'create' ? 'Déjà payé' : 'Payé avant'}
          </th>
          {mode === 'create' && (
            <th
              scope="col"
              className="hidden lg:table-cell px-2 py-1 sm:px-4 sm:py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Statut avant paiement
            </th>
          )}
          <th
            scope="col"
            className="px-2 py-1 sm:px-4 sm:py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Montant ({mainCurrency.sign})
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell px-2 py-1 sm:px-4 sm:py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Total payé
          </th>
          <th
            scope="col"
            className="px-2 py-1 sm:px-4 sm:py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Reste
          </th>
          <th
            scope="col"
            className="hidden md:table-cell px-2 py-1 sm:px-4 sm:py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Statut
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {paymentChangesModel.bookingDeposits
          .filter((x) => x.selected || isEditEnabled)
          .map((bookingDepositChanges, rowIndex) => (
            <DiverPurchasePaymentEditorPackagesTableRow_BookingDeposit
              key={rowIndex}
              isEditEnabled={isEditEnabled}
              mode={mode}
              form={form}
              bookingDepositChanges={bookingDepositChanges}
              rowIndex={rowIndex}
              showCheckboxCells={showCheckboxCells}
              bookingDeposits={bookingDeposits}
            />
          ))}
        {paymentChangesModel.packages
          .filter((x) => x.selected || isEditEnabled)
          .map((purchasePaymentPackage, rowIndex) => (
            <DiverPurchasePaymentEditorPackagesTableRow_Package
              key={rowIndex}
              isEditEnabled={isEditEnabled}
              mode={mode}
              form={form}
              purchasePaymentPackage={purchasePaymentPackage}
              rowIndex={rowIndex}
              showCheckboxCells={showCheckboxCells}
              formPackages={formPackages}
            />
          ))}
      </tbody>
    </table>
  );
};
