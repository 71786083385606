/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useMediaSizeTailwind } from 'src/business/_core/modules/layout';
import { ClubDialogsState } from '../../../../../pages/_dialogs';
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import {
  ClubPlanningLightDayHeader,
  CreateEventButton,
  CreateSessionButton,
} from '../components';
import { CalendarSessionWidthMode } from '../components/ClubPlanningLightSession/session-common';
import { SetPlanningConfigOptions } from '../hooks';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
} from '../model';
import { ClubPlanningLightPanelViewPeriodWeekSessionsModeCompact } from './ClubPlanningLightPanelViewPeriodWeekSessionsModeCompact';
import { ClubPlanningLightPanelViewPeriodWeekSessionsModeFull } from './ClubPlanningLightPanelViewPeriodWeekSessionsModeFull';

export const ClubPlanningLightPanelViewPeriodWeek = ({
  planningParticipantsLocalState,
  dialogsState,
  context,
  days,
  planningConfig,
  setPlanningConfig,
  clickActions,
  sessionsDisplayConfigs = [],
  isAnyDialogOpen,
  className,
}: {
  planningParticipantsLocalState?: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
  dialogsState?: ClubDialogsState; // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  context: ClubPlanningDisplayConfigurationBarContext;
  days: ClubPlanningDayFull[];
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  clickActions: ClubPlanningLightPanelViewClickActions;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  isAnyDialogOpen: boolean;
  className?: string;
}) => {
  const { displayMode } = planningConfig.viewWeekOptions;

  const {
    onClickSession,
    onClickSessionOpenButton,
    onClickAddParticipant,
    onClickParticipant,
    anchorLinkParticipant,
    onClickCreateNewSession,
    onClickCreateNewEvent,
    onClickEvent,
  } = clickActions;

  const mediaSize = useMediaSizeTailwind();
  const widthMode: CalendarSessionWidthMode =
    mediaSize === 'lg' || mediaSize === 'xl' || mediaSize === '2xl'
      ? 'medium'
      : mediaSize === 'xxs'
      ? 'tiny'
      : 'small';

  // const weekSessions = useMemo(
  //   () =>
  //     days.reduce(
  //       (acc, d) => acc.concat(d.sessions),
  //       [] as ClubPlanningLightSessionDef[],
  //     ),
  //   [days],
  // );

  // const dailyConfigs = useMemo(
  //   () =>
  //     days.reduce((acc, d) => {
  //       if (d.dailyConfig) {
  //         acc.push(d.dailyConfig);
  //       }
  //       return acc;
  //     }, [] as DiveCenterDailyConfig[]),
  //   [days],
  // );

  // const {
  //   diveSessions: weekDiveSessions,
  //   staffMembersResumeSessions,
  // }: {
  //   diveSessions: ClubPlanningLightSessionDef[];
  //   staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
  // } = useStaffMemberResumeSessionsModel({
  //   sessions: weekSessions,
  //   staffFirstNameBefore: planningConfig.staffFirstNameBefore,
  //   staffScope: 'all-available',
  //   dailyConfigs,
  // });

  return (
    <div className={clsx('flex flex-col', className)}>
      {
        <>
          <div
            className={clsx(
              'bg-white w-full grid grid-cols-7 gap-0.5 sm:gap-2',
              !isAnyDialogOpen && 'sticky top-0 z-10',
            )}
          >
            {days.map((day, i) => (
              <ClubPlanningLightDayHeader
                key={i}
                className=""
                viewPeriod={planningConfig.viewPeriod}
                date={day.date}
                theme={day.theme}
                onClick={() => {
                  setPlanningConfig(
                    {
                      ...planningConfig,
                      viewPeriod: 'day',
                      focusDate: day.date,
                    },
                    {
                      origin:
                        '[ClubPlanningLightPanelViewPeriodWeek] ClubPlanningLightDayHeader.click',
                    },
                  );
                }}
              />
            ))}
          </div>

          {displayMode === 'full' || displayMode === 'staff-sessions' ? (
            <ClubPlanningLightPanelViewPeriodWeekSessionsModeFull
              // key= pour forcer la création du composant lors de la navigation (pour afficher plus rapidement les premières sessions)
              key={days?.[0]?.date?.getTime()}
              context={context}
              widthMode={widthMode}
              planningConfig={planningConfig}
              days={days}
              clickActions={clickActions}
              sessionsDisplayConfigs={sessionsDisplayConfigs}
              planningParticipantsLocalState={planningParticipantsLocalState}
              dialogsState={dialogsState}
            />
          ) : displayMode === 'compact' ? (
            <ClubPlanningLightPanelViewPeriodWeekSessionsModeCompact
              // ici le render est rapide, donc pas de "key="
              context={context}
              widthMode={widthMode}
              planningConfig={planningConfig}
              days={days}
              clickActions={clickActions}
              sessionsDisplayConfigs={sessionsDisplayConfigs}
              planningParticipantsLocalState={planningParticipantsLocalState}
              dialogsState={dialogsState}
            />
          ) : null}
          <div
            className={'max-h-full grid grid-cols-7 gap-0.5 sm:gap-2'}
            // pas de overflow sinon le menu ne peut pas s'ouvrir
          >
            {days.map((day, i) => (
              <div className="flex flex-col justify-start" key={i}>
                <div className="my-4 w-full flex flex-col gap-2">
                  <CreateSessionButton
                    className="w-full px-0.5"
                    mode="tiny"
                    day={day}
                    onClickCreateNewSession={onClickCreateNewSession}
                  />
                  <CreateEventButton
                    className="w-full px-0.5"
                    mode="tiny"
                    day={day}
                    onClickCreateNewEvent={onClickCreateNewEvent}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      }
    </div>
  );
};
