/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubMassiveUpdateOperationPayloadSessionMassiveActionId } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanSwitch,
  AppToogleButtonGroupRHF,
} from 'src/lib/form';
import {
  useAppCurrencyMain,
  useBookingAgenciesOptions,
} from 'src/pages/_components/options';
import { DiveSessionEditorMassiveDiveGroup } from '../_components';
import { DiveSessionEditorMassiveDialogLocalState } from '../useDiveSessionEditorMassiveDialogLocalState.hook';

export const DiveSessionEditorMassiveSelectDiversPanel = ({
  localState,
  className,
}: {
  localState: DiveSessionEditorMassiveDialogLocalState;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  const agenciesOptions = useBookingAgenciesOptions();

  const actionIdOptions: ValueLabel<ClubMassiveUpdateOperationPayloadSessionMassiveActionId>[] =
    useMemo(
      () => [
        {
          value: 'move-participants',
          label: 'Déplacer des participants',
        },
        {
          value: 'clone-participants',
          label: 'Copier des participants',
        },
      ],
      [],
    );

  const [actionId] = useWatch({
    control,
    name: ['actionId'],
  });

  return (
    <div className={clsx('grid gap-8', className)}>
      <div className="grid gap-4">
        <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
          Action
        </h3>
        <AppFormControlRHF_Deprecated
          label="Que souhaitez-vous faire?"
          control={control}
          name={'actionId' as const}
          required={false}
          renderComponent={(props) => (
            <AppToogleButtonGroupRHF
              {...props}
              items={actionIdOptions}
              color="green"
              theme="strong"
              // onChange={(diveMode: DiveMode) => {
              //   // onDiveModeChange(diveMode);
              // }}
            />
          )}
        />
      </div>

      {actionId && (
        <div className="grid gap-4">
          <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
            Sélection des participants
          </h3>
          <AppMessageLight>
            <div className="w-full flex justify-between gap-x-8 gap-y-2 flex-wrap">
              <div className="flex-grow">
                Sélectionnez les participants ou les palanquées à{' '}
                {actionId === 'move-participants' ? 'déplacer' : 'copier'}{' '}
                <b>
                  ({data.participantsCheckState.participantsCheckedCount}/
                  {initialState.originalParticipants.length})
                </b>
              </div>
            </div>
          </AppMessageLight>
          <div className={clsx('w-full pl-px')}>
            <div
              className={
                'w-full flex justify-between gap-2 px-2 py-1 items-center'
              }
            >
              <AppInputBooleanSwitch
                theme="danger"
                label="tous les participants"
                value={data.participantsCheckState.isGroupSelected}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(value) => {
                  data.participantsCheckState.setGroupCheck(value);
                }}
              />
            </div>
          </div>
          {data.diveSessionParticipantsByGroup.byGroup.map(
            ({ group, participants }, groupIndex) => (
              <DiveSessionEditorMassiveDiveGroup
                key={group._id}
                group={group}
                participants={participants}
                localState={localState}
                enableSelection={true}
              />
            ),
          )}
          {data.ungrouppedParticipants.length !== 0 && (
            <DiveSessionEditorMassiveDiveGroup
              participants={data.ungrouppedParticipants}
              localState={localState}
              group={undefined}
              enableSelection={true}
            />
          )}
          {data.observers.length !== 0 && (
            <DiveSessionEditorMassiveDiveGroup
              participants={data.observers}
              localState={localState}
              group={undefined}
              enableSelection={true}
            />
          )}
        </div>
      )}
    </div>
  );
};
