/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveCenterEquipmentConfig,
  DiveCenterEquipmentConfigGroup,
  DiveCenterEquipmentModel,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import {
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { AppFormControlRHF, AppInputBooleanSwitchRHF } from 'src/lib/form';
import { clubSettingsUserEquipmentRefBuilder } from '../services';
import {
  ClubSettingsEquipmentEditPageFormModel,
  ClubSettingsEquipmentEditPageFormModelOnClickAction,
} from './ClubSettingsEquipmentEditPageFormModel';
import {
  DiveCenterEquipmentConfigFormModel,
  DiveCenterEquipmentConfigFormModelEquipementModel,
} from './model';

export const ClubSettingsEquipmentEditPageFormModelsOptions = <
  T extends 'club' | 'self',
>({
  form,
  equipmentDefaultAppConfig,
  modelsGroupName,
  className,
}: {
  form: UseFormReturn<DiveCenterEquipmentConfigFormModel, object>;
  equipmentDefaultAppConfig: DiveCenterEquipmentConfig;
  modelsGroupName: T;
  className?: string;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    getValues,
  } = form;

  const [modelEnabled] = useWatch({
    control,
    name: [`${modelsGroupName}.enabled`],
  });

  const modelsFieldArray = useFieldArray({
    control,
    name: `${modelsGroupName}.models`,
    shouldUnregister: true,
    keyName: 'ref',
  });
  const [modelAnimations, setModelAnimations] =
    useState<{
      update: Date;
      up?: number;
      down?: number;
      remove?: number;
      clone?: number;
    }>();

  const isRecentAnimationUpdate =
    new Date().getTime() - modelAnimations?.update?.getTime() < 1000;

  const onClick = useCallback(
    (
      field: FieldArrayWithId<
        DiveCenterEquipmentConfigFormModel,
        `${T}.models`,
        'ref'
      >,
      fieldIndex: number,
      action: ClubSettingsEquipmentEditPageFormModelOnClickAction,
    ) => {
      const from = fieldIndex;
      const update = new Date();
      switch (action) {
        case 'move-down': {
          modelsFieldArray.move(from, from + 1);
          setModelAnimations({ update, up: from + 1, down: from });
          break;
        }
        case 'move-up': {
          modelsFieldArray.move(from, from - 1);
          setModelAnimations({ update, up: from, down: from - 1 });
          break;
        }
        case 'delete': {
          // setModelAnimations({ update, remove: from });
          modelsFieldArray.remove(from);
          // setTimeout(() => modelsFieldArray.remove(from), 100);
          break;
        }
        case 'clone': {
          if (field) {
            const original: DiveCenterEquipmentConfigFormModelEquipementModel =
              getValues(`${modelsGroupName}.models.${fieldIndex}`);
            const clone: DiveCenterEquipmentModel = JSON.parse(
              JSON.stringify(
                clubSettingsUserEquipmentRefBuilder.strip__originalRef(
                  original,
                ),
              ),
            );

            modelsFieldArray.insert(from + 1, clone as any);
            setModelAnimations({ update, clone: from });
            break;
          }
        }
      }
    },
    [getValues, modelsFieldArray, modelsGroupName],
  );

  return (
    <div className={clsx('grid gap-2', className)}>
      <div className="font-medium text-gray-500">
        {modelsGroupName === 'club' ? 'MODÈLES DU CLUB' : 'MODÈLES PERSO'}
      </div>
      <div className={clsx('grid sm:flex gap-4')}>
        <AppFormControlRHF
          label="Choisir la taille ou le modèle?"
          control={control}
          name={`${modelsGroupName}.enabled`}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              className="mt-1"
              label={'Oui'}
              labelToggleFalse="Non"
              onChange={(newValue) => {
                if (newValue && modelsFieldArray.fields.length === 0) {
                  const defaultValue: DiveCenterEquipmentConfigGroup =
                    equipmentDefaultAppConfig?.[modelsGroupName];
                  const newValue: DiveCenterEquipmentConfigFormModelEquipementModel[] =
                    clubSettingsUserEquipmentRefBuilder.append__originalRefs(
                      defaultValue.models,
                    );
                  setValue(
                    `${modelsGroupName}.models` as 'club.models',
                    newValue,
                  );
                }
              }}
            />
          )}
        />
        <AppFormControlRHF
          label="Équipement visible sur la session?"
          helpDescription="Permet d'afficher l'équipement sur la session détaillée du planning"
          control={control}
          name={`${modelsGroupName}.visibleOnSession`}
          renderComponent={(props) => (
            <AppInputBooleanSwitchRHF
              {...props}
              className="mt-1"
              label={'Oui'}
              labelToggleFalse="Non"
            />
          )}
        />
        {modelsGroupName === 'self' && (
          <AppFormControlRHF
            label='Passer à "PERSO" si plongeur "ÉQUIPÉ"'
            helpDescription='Permet de passer le champ à "PERSO" quand le plongeur est coché comme "ÉQUIPÉ"'
            control={control}
            name={`${modelsGroupName}.autoSetOnGlobal`}
            renderComponent={(props) => (
              <AppInputBooleanSwitchRHF
                {...props}
                className="mt-1"
                label={'Oui'}
                labelToggleFalse="Non"
              />
            )}
          />
        )}
      </div>
      {modelEnabled && (
        <div className="grid gap-2">
          {modelsFieldArray.fields.map((field, fieldIndex) => {
            return (
              <ClubSettingsEquipmentEditPageFormModel
                key={`${field.ref}-${fieldIndex}`}
                form={form}
                fieldArray={modelsFieldArray as any}
                modelsGroupName={modelsGroupName}
                field={field as any}
                fieldIndex={fieldIndex}
                type={equipmentDefaultAppConfig.type}
                className={clsx(
                  isRecentAnimationUpdate &&
                    modelAnimations?.up === fieldIndex &&
                    'animate-move-up',
                  isRecentAnimationUpdate &&
                    modelAnimations?.down === fieldIndex &&
                    'animate-move-down',
                  isRecentAnimationUpdate &&
                    modelAnimations?.clone === fieldIndex &&
                    'animate-create',
                  isRecentAnimationUpdate &&
                    modelAnimations?.remove === fieldIndex &&
                    'animate-remove',
                )}
                onClick={(action) => onClick(field, fieldIndex, action)}
              />
            );
          })}
          <AppButton
            className="my-4"
            size="normal"
            icon={AppHeroIcons.actionAdd}
            color="primary-outline-light"
            onClick={() => {
              modelsFieldArray.append({
                ref: '',
                label: '',
              } as any);
            }}
          >
            Ajouter une taille ou un modèle
          </AppButton>
        </div>
      )}
    </div>
  );
};
