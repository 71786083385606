import {
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE,
  ClubStaffMemberDailyAvailabilityPeriod,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { ReactNode, useMemo } from 'react';
import { AppIconsUI } from '../../../business/_core/modules/layout/icons';
import { ValueLabel } from '../../../business/club/modules/_common/form';

export function useDailyAvailabilityPeriodOptions({
  periods,
}: {
  periods: ClubStaffMemberDailyAvailabilityPeriod[];
}) {
  return useMemo(() => {
    const options: ValueLabel<
      ClubStaffMemberDailyAvailabilityPeriod,
      ReactNode
    >[] = periods.map((period) => {
      return {
        value: period,
        label: <OptionAvailabilityPeriodLabel period={period} />,
      };
    });

    return options;
  }, [periods]);
}

function OptionAvailabilityPeriodLabel({
  period,
  colorizeText = true,
  className,
}: {
  period: ClubStaffMemberDailyAvailabilityPeriod;
  colorizeText?: boolean;
  className?: string;
}) {
  const textColorClassName =
    period === 'day'
      ? 'text-status-success bg-white'
      : period === 'am'
      ? 'text-app-primary bg-white'
      : period === 'pm'
      ? 'text-app-secondary bg-white'
      : 'text-status-error bg-white';

  const isAvailablePeriod =
    CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE.includes(period);

  return isAvailablePeriod ? (
    <div className={clsx(colorizeText && textColorClassName, className)}>
      <AppIconsUI.statusValidated
        className={clsx('inline-block h-4 w-4 bg-white', textColorClassName)}
      />{' '}
      {period === 'day' ? (
        <>Dispo</>
      ) : period === 'am' ? (
        <>
          <span className="inline 2xl:hidden">AM</span>
          <span className="hidden 2xl:inline">Matin</span>
        </>
      ) : period === 'pm' ? (
        <>
          <span className="inline 2xl:hidden">PM</span>
          <span className="hidden 2xl:inline">Après-midi</span>
        </>
      ) : null}
    </div>
  ) : period === 'none' ? (
    <div className={clsx(colorizeText && textColorClassName, className)}>
      <AppIconsUI.statusArchived
        className={clsx('inline-block h-4 w-4 bg-white', textColorClassName)}
      />{' '}
      Absent
    </div>
  ) : null;
}
