import { dateService } from '@mabadive/app-common-services';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { dashboardStatsPageUrlManager } from 'src/pages/DA-dashboard/DA-02-dashboard-report/services';
import { AppTitleDateNavigatorPeriodRange } from 'src/pages/_components/title-navigators/model';
import { PaymentsListPageViewParameters } from './model';

export function usePaymentsListPageViewParameters() {
  const query: PaymentsListPageViewParameters = useMemo(
    () => dashboardStatsPageUrlManager.getQueryParameters(),
    [],
  );

  const initialPeriodRange: AppTitleDateNavigatorPeriodRange = useMemo(() => {
    // NOTE: on ne récupère pas les données du cache, ce qui permet de toujours revenir à la date du jour par défaut
    if (query.periodRange?.value?.selectedDate) {
      return query.periodRange;
    }
    const selectedDate = dateService.getUTCDateWithoutTimeFromLocalTime(
      new Date(),
    );
    const beginDate = dateService.add(selectedDate, -1, 'month');
    return {
      period: 'dates-range',
      value: {
        selectedDate: selectedDate,
        beginDate: undefined,
        endDate: undefined,
      },
    };
  }, [query.periodRange]);

  const initialViewParameters: PaymentsListPageViewParameters = useMemo(() => {
    const viewParameters: PaymentsListPageViewParameters = {
      periodRange: initialPeriodRange,
    };
    return viewParameters;
  }, [initialPeriodRange]);

  const [viewParameters, setViewParameters] = useState(initialViewParameters);

  const history = useHistory();
  useEffect(() => {
    // update url if criteria changes
    const searchParams: string[] =
      dashboardStatsPageUrlManager.buildSearchParams(viewParameters);

    history.replace({
      pathname: window.location.pathname,
      search: searchParams?.length ? `?${searchParams.join('&')}` : undefined,
    });
  }, [history, viewParameters]);

  return {
    viewParameters,
    setViewParameters,
  };
}
