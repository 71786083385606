/* eslint-disable @typescript-eslint/no-unused-vars */
import { default as React, useCallback, useState } from 'react';
import { paymentPlatformClient } from 'src/business/_core/data/app-operation';
import {
  AppButton,
  useAppDialogModal,
} from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import { uiStore } from 'src/business/_core/store';

export function usePaymentPlatformOpenActivationDialog() {
  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const appDialogModal = useAppDialogModal();

  const openDialog = useCallback(
    ({ onboardingLink }: { onboardingLink: string }) => {
      appDialogModal.openDialog(({ close }) => ({
        content: (
          <div className="app-p-content">
            <h2 className="my-2 text-xl font-bold text-gray-700">
              Activer mon compte Stripe
            </h2>
            <div className={''}>
              <div className="mt-5 mb-2 text-gray-700">
                En cliquant sur le bouton "Activer", vous allez être redirigé
                sur le site de Stripe, afin de configurer votre compte Stripe.
              </div>
              <div className="mt-5 mb-2 text-gray-700">
                Pour ré-utiliser un compte Stripe existant, renseigner
                simplement l'adresse de ce compte sur la page suivante. Sinon,
                un nouveau compte sera créé.
              </div>
              {operationInProgressMessage && (
                <LoadingProgressBar>
                  {operationInProgressMessage}
                </LoadingProgressBar>
              )}
              {/* <Box style={{ visibility: showProgress ? 'visible' : 'hidden' }}><CircularProgress /></Box> */}
              {!operationInProgressMessage && (
                <div className="mt-8 mb-2 flex-grow flex gap-2 justify-around">
                  <AppButton
                    className={'text-base font-bold uppercase'}
                    style={{ minWidth: '6rem' }}
                    color={'gray-outline-light'}
                    icon={() => (
                      <AppIconsAction.cancel className="w-6 h-6 mr-2" />
                    )}
                    tabIndex={500}
                    onClick={(e) => {
                      close();
                    }}
                  >
                    Annuler
                  </AppButton>

                  <AppButton
                    href={onboardingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base font-bold uppercase"
                    style={{ minWidth: '6rem' }}
                    color={'primary-outline-light'}
                    icon={() => (
                      <AppIconsAction.linkExternal className="w-6 h-6 mr-2" />
                    )}
                    onClick={(e) => {
                      close();
                    }}
                  >
                    Activer
                  </AppButton>
                </div>
              )}
            </div>
          </div>
        ),
      }));
    },
    [appDialogModal, operationInProgressMessage],
  );

  const showOnboardingDialog = useCallback(
    async ({ paymentPlatformId }: { paymentPlatformId: string }) => {
      setOperationInProgressMessage('Création du compte de paiement');
      try {
        const { onboardingLink } =
          await paymentPlatformClient.getConnectedAccountOnboardingLink({
            paymentPlatformId,
          });
        // console.log('xxx onboardingLink:', onboardingLink);
        openDialog({
          onboardingLink,
        });
        // uiStore.snackbarMessage.set({
        //   type: 'success',
        //   content: 'Compté créé avec succès!',
        // });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        appLogger.error(
          'Erreur innatendue lors de la création du compte Stripe',
        );
        uiStore.snackbarMessage.set({
          type: 'error',
          content: 'Erreur innatendue lors de la création du compte!',
        });
      } finally {
        setOperationInProgressMessage(undefined);
      }
    },
    [openDialog],
  );

  return showOnboardingDialog;
}
