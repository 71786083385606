/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  ClubSettingsSection,
  ClubSettingsViewAttribute,
} from 'src/pages/SE-settings/_core';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsGeneralMainViewPanelFeatures } from './ClubSettingsGeneralMainViewPanelFeatures';
import { ClubSettingsGeneralMainViewPanelLocalisation } from './ClubSettingsGeneralMainViewPanelLocalisation';

export const ClubSettingsGeneralMainViewPanel = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const mainCurrency = useAppCurrencyMain();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const generalSettings = clubSettings.general;
  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsGeneralMainViewPanelLocalisation />
      <ClubSettingsGeneralMainViewPanelFeatures />

      {isSuperAdmin && (
        <ClubSettingsSection title="Informations techniques">
          <div className="flex gap-4 md:gap-x-8 flex-wrap">
            <ClubSettingsViewAttribute label={'Référence publique'}>
              {clubSettings.clubPublicReference}
            </ClubSettingsViewAttribute>
            <ClubSettingsViewAttribute label={'Référence interne'}>
              {clubResume.reference}
            </ClubSettingsViewAttribute>
          </div>
          <ClubSettingsViewAttribute label={'Lien de partage d\'écran'}>
            {clubSettings?.general?.internal?.screenSharingUrl && (
              <a
                className="text-app-primary underline"
                href={clubSettings?.general?.internal?.screenSharingUrl}
                target="_blank"
                rel="noreferrer"
              >
                {clubSettings?.general?.internal?.screenSharingUrl}
              </a>
            )}
          </ClubSettingsViewAttribute>
        </ClubSettingsSection>
      )}
    </div>
  );
};
