import { dateService } from '@mabadive/app-common-services';
import {
  BookingResumeParticipantsBySession,
  BookingTabModel,
  BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk,
} from '../../../../models';
import { BookingTabSingleBookingsAutoFixBookingInactivePeriodSplitterOptions } from '../model';
import { bookingChunkSplitter } from './bookingChunkSplitter.service';

export const bookingTabSingleBookingsAutoFixBookingInactivePeriodSplitter = {
  splitByInactivePeriod,
};

function splitByInactivePeriod({
  tabModel,
  chunks,
  options,
}: {
  tabModel: BookingTabModel;
  chunks: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk[];
  options: BookingTabSingleBookingsAutoFixBookingInactivePeriodSplitterOptions;
}): BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk[] {
  const { bookingMembersFull } = tabModel.aggregatedBooking;

  // d'abord, découpage temporel
  chunks = chunks
    .filter((chunk) => chunk.participantsBySessions.length !== 0)
    .reduce((acc, chunk) => {
      const durationInMonths = dateService.getDiffInMonths(
        chunk.maxDate,
        chunk.minDate,
      );
      if (
        chunk.participantsBySessions.length >
          2 * options.minChunkSessionsSize &&
        durationInMonths > 1
      ) {
        // on cherche la plus grosse coupure
        const maxDurationBetween2Sessions = findMaxDurationBetween2Sessions({
          chunk,
          options,
        });

        if (
          maxDurationBetween2Sessions.biggestDurationParticipantsBySessionIndex !==
            -1 &&
          // maxDurationBetween2Sessions.biggestDurationInHours > (24 * 365) / 12 // > 1 month
          maxDurationBetween2Sessions.biggestDurationInHours >
            24 * options.minDaysBetween2Sessions // > 14 days)
        ) {
          // 3 months: split!
          // TODO: en fonction du nombre de session restant, on peut adapter la période nécessaire pour provoquer le split
          const {
            chunk1,
            chunk2,
          }: {
            chunk1: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
            chunk2: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
          } = bookingChunkSplitter.splitChunkBySessionIndex({
            chunk,
            chunk2FirstSessionIndex:
              maxDurationBetween2Sessions.biggestDurationParticipantsBySessionIndex,
            bookingMembersFull,
            comment: `${Math.round(
              maxDurationBetween2Sessions.biggestDurationInHours / 24,
            )} jours sans plongée`,
          });

          if (chunk1 && chunk2) {
            acc.push(chunk1);
            acc.push(chunk2);
          } else {
            // don't split (preserve original chunk)
            acc.push(chunk);
          }
        } else {
          // don't split (preserve original chunk)
          acc.push(chunk);
        }
      } else {
        // don't split (preserve original chunk)
        acc.push(chunk);
      }
      // }
      return acc;
    }, [] as BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk[]);

  // if (bookingMembers.length > 1) {
  //   // on essaie d'isoler les plongeurs occassionnels
  // }

  return chunks;
}

function findMaxDurationBetween2Sessions({
  chunk,
  options,
}: {
  chunk: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
  options: BookingTabSingleBookingsAutoFixBookingInactivePeriodSplitterOptions;
}) {
  const maxDurationBetween2Sessions = chunk.participantsBySessions.reduce(
    (acc, participantsBySession, participantsBySessionIndex) => {
      const s2 = participantsBySession;

      if (
        dateService.isPastUTC(
          participantsBySession.bookingSessionFull.diveSession.time,
        ) &&
        participantsBySessionIndex + 1 > options.minChunkSessionsSize &&
        chunk.participantsBySessions.length - participantsBySessionIndex + 1 >
          options.minChunkSessionsSize
      ) {
        // on ne split pas si il y a moins de MIN_CHUNK_SESSIONS_SIZE d'un côté

        if (acc.previousParticipantsBySessions) {
          const s1 = acc.previousParticipantsBySessions;
          const diffInHours = dateService.getDiffInHours(
            s1.bookingSessionFull.diveSession.time,
            s2.bookingSessionFull.diveSession.time,
          );

          if (diffInHours > acc.biggestDurationInHours) {
            return {
              previousParticipantsBySessions: s2,
              biggestDurationParticipantsBySessionIndex:
                participantsBySessionIndex,
              biggestDurationInHours: diffInHours,
            };
          }
        }
      }

      return {
        ...acc,
        previousParticipantsBySessions: s2,
      };
    },
    {
      previousParticipantsBySessions: undefined,
      biggestDurationParticipantsBySessionIndex: -1,
      biggestDurationInHours: 0,
    } as {
      previousParticipantsBySessions?: BookingResumeParticipantsBySession;
      biggestDurationParticipantsBySessionIndex: number;
      biggestDurationInHours: number;
    },
  );
  return maxDurationBetween2Sessions;
}
