import clsx from 'clsx';
import React, { FC, useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
  AppIconsSocialMedia,
} from 'src/business/_core/modules/layout/icons';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';

export const CreateMessageToCustomersFormStep3_ContentActions: FC<{
  localState: CreateMessageToCustomersLocalState;
  sendActionLink: string;
  mode: 'preview' | 'edit';
  setMode: (mode: 'preview' | 'edit') => void;
  className?: string;
}> = ({ localState, sendActionLink, mode, setMode, className }) => {
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume.clubResume;
  const clubReference = clubResume?.reference;

  const {
    state,
    data: { form, result, initialState },
    actions,
  } = localState;

  const { register, handleSubmit, watch, control, setValue } = form;

  const [
    targetModeEmail,
    messageTarget,
    title,
    body,
    signature,
    recipientType,
  ] = useWatch({
    control,
    name: [
      'targetModeEmail',
      'messageTarget',
      'messageContent.title',
      'messageContent.body',
      'messageContent.signature',
      'recipientType',
    ],
  });

  const goToStep4 = useCallback(() => {
    state.setStep('step4-send-one-by-one');
  }, [state]);

  return (
    <div
      className={clsx('flex gap-4 justify-between md:justify-end', className)}
    >
      {mode === 'preview' ? (
        <AppButton
          className="w-full uppercase sm:max-w-xs"
          fullWidth={true}
          size="normal"
          icon={AppIconsAction.back}
          color={'gray-outline-light'}
          tabIndex={500}
          onClick={() => {
            state.setStep('step2-recipients');
          }}
        >
          Retour
        </AppButton>
      ) : (
        <AppButton
          className="w-full uppercase sm:max-w-xs"
          fullWidth={true}
          size="normal"
          icon={AppHeroIcons.check}
          color={'primary-bg'}
          tabIndex={500}
          onClick={() => {
            actions.autoSetRecipientType({
              nextStep: 'step2-recipients',
            });
            setMode('preview');
          }}
        >
          Confirmer
        </AppButton>
      )}

      {mode === 'preview' && (
        <>
          {messageTarget === 'email' && (
            <AppButton
              className="w-full uppercase sm:max-w-xs"
              fullWidth={true}
              size="normal"
              icon={AppIconsSocialMedia.mail}
              color={'primary-bg'}
              href={targetModeEmail === 'auto' ? undefined : sendActionLink}
              target="mabadive_mail_client"
              onClick={async () => {
                if (sendActionLink) {
                  actions.persistMessageScheduleAndClose();
                } else {
                  goToStep4();
                }
              }}
            >
              {sendActionLink ? 'Envoyer' : 'Continuer'}
            </AppButton>
          )}
          {messageTarget === 'sms' && (
            <AppButton
              className="w-full uppercase sm:max-w-xs"
              fullWidth={true}
              size="normal"
              icon={AppIconsSocialMedia.sms}
              color={'primary-bg'}
              href={sendActionLink}
              target="mabadive_sms_client"
              onClick={async () => {
                if (sendActionLink) {
                  actions.persistMessageScheduleAndClose();
                } else {
                  goToStep4();
                }
              }}
            >
              {sendActionLink ? 'Envoyer' : 'Continuer'}
            </AppButton>
          )}
          {messageTarget === 'whatsapp' && (
            <AppButton
              className="w-full uppercase sm:max-w-xs"
              fullWidth={true}
              size="normal"
              icon={AppIconsSocialMedia.whatsapp}
              color={'primary-bg'}
              onClick={async () => {
                goToStep4();
              }}
            >
              {sendActionLink ? 'Envoyer' : 'Continuer'}
            </AppButton>
          )}
        </>
      )}
    </div>
  );
};
