import { MQueryDescription } from 'src/_common-browser';

export function buildClubDiverCountGraphqlQuery({ clubReference }: { clubReference: string }) {
  const queryDescription: MQueryDescription<{
    aggregate: {
      count: number;
    };
  }> = {
    returnName: 'diversCount',
    queryName: 'club_diver_aggregate',
    returnType: 'all',
    where: `{clubReference: {_eq: "${clubReference}"}}`,
    returnAttributes: `aggregate {
        count
      }`,
  };

  return queryDescription;
}
