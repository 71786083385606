import {
  AppCompanyMessageContext,
  AppCompanyMessageScheduleTargetMessageRecipientMeta,
  AppMessageParamsRecipientType,
} from '@mabadive/app-common-model';
import { appUrlBuilderCore } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import {
  UseAppMessageParamsArgs,
  useAppMessageParams,
} from 'src/pages/_components';
import {
  CreateMessageToCustomersAggregatedRecipient,
  CreateMessageToCustomersDialogInitialState,
} from '../model';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';

export function useCreateMessageParamsToReplace({
  localState,
  recipient: recipientInput,
  recipientIndexIfPersonal,
}: {
  localState: CreateMessageToCustomersLocalState;
  recipient?: CreateMessageToCustomersAggregatedRecipient;
  recipientIndexIfPersonal?: number;
}) {
  const { state, data } = localState;
  const { context, selectedMessageTemplate } = state;
  const { form, initialState, refDate } = data;

  const {
    aggregated: { allRecipients, validRecipients, validSelectedRecipients },
    changes,
  } = data.result;

  const { control } = form;
  // const [messageTarget, recipientType] = useWatch({
  //   control,
  //   name: ['messageTarget', 'recipientType'],
  // });

  let previewRecipient: CreateMessageToCustomersAggregatedRecipient =
    recipientInput ?? validSelectedRecipients?.[recipientIndexIfPersonal];

  const [recipientType] = useWatch({
    control,
    name: ['recipientType'],
  });

  const args: UseAppMessageParamsArgs = useMemo(() => {
    return buildCreateMessageArgs({
      context,
      recipientType,
      previewRecipient,
      validSelectedRecipients,
      initialState,
    });
  }, [
    context,
    initialState,
    previewRecipient,
    recipientType,
    validSelectedRecipients,
  ]);

  return useAppMessageParams(args);
}

export function buildAppCompanyMessageScheduleTargetMessageRecipientMeta({
  context,
  recipientType,
  previewRecipient,
  validSelectedRecipients,
  initialState,
}: {
  context: AppCompanyMessageContext;
  recipientType: AppMessageParamsRecipientType;
  previewRecipient: CreateMessageToCustomersAggregatedRecipient;
  validSelectedRecipients: CreateMessageToCustomersAggregatedRecipient[];
  initialState: CreateMessageToCustomersDialogInitialState;
}): AppCompanyMessageScheduleTargetMessageRecipientMeta {
  const meta: AppCompanyMessageScheduleTargetMessageRecipientMeta = {
    context,
    recipientType,
    diverContext: {},
  };

  if (context === 'booking') {
    let bookingRecipient = previewRecipient;
    if (!bookingRecipient) {
      // first validSelectedRecipients
      bookingRecipient = validSelectedRecipients?.find(
        (x) => x.formRecipient.isBookingContact,
      );
      if (!bookingRecipient) {
        bookingRecipient = validSelectedRecipients?.[0];
      }
    }

    const booking = initialState.bookingContext?.booking;
    meta.bookingContext = initialState.bookingContext
      ? {
          booking: {
            _id: booking?._id,
            bookingCustomerConfig: booking?.bookingCustomerConfig,
            bookingCustomerSpacePrivate: booking?.bookingCustomerSpacePrivate,
            bookingContactDiverId: booking?.bookingContactDiverId,
          },
        }
      : undefined;
  }
  if (context === 'session') {
    const diveSession = initialState.sessionContext?.diveSession;
    meta.sessionContext = initialState.sessionContext
      ? {
          diveSession: {
            _id: diveSession?._id,
            time: diveSession?.time,
          },
        }
      : undefined;
  }
  const nameDiverRecipient =
    recipientType === 'personal' && previewRecipient
      ? previewRecipient
      : validSelectedRecipients.length === 1
      ? validSelectedRecipients[0]
      : undefined;
  if (previewRecipient) {
    let bookingAliasKey =
      previewRecipient?.formRecipient?.bookingMember?.bookingAliasKey;
    const customerSpaceAuthenticationUrl = bookingAliasKey
      ? appUrlBuilderCore.buildShortUrlLinkOnDiverSite({
          aliasKey: bookingAliasKey,
          diverWebUrl: appWebConfig.applications.diverWebUrl,
        })
      : '';
    meta.diverContext.customerSpaceAuthenticationUrl =
      customerSpaceAuthenticationUrl;
  }
  if (nameDiverRecipient) {
    meta.diverContext.diver = {
      firstName: nameDiverRecipient.formRecipient.diver.firstName,
      lastName: nameDiverRecipient.formRecipient.diver.lastName,
    };
  }

  return meta;
}
export function buildCreateMessageArgs({
  context,
  recipientType,
  previewRecipient,
  validSelectedRecipients,
  initialState,
}: {
  context: AppCompanyMessageContext;
  recipientType: AppMessageParamsRecipientType;
  previewRecipient: CreateMessageToCustomersAggregatedRecipient;
  validSelectedRecipients: CreateMessageToCustomersAggregatedRecipient[];
  initialState: CreateMessageToCustomersDialogInitialState;
}): UseAppMessageParamsArgs {
  const args: UseAppMessageParamsArgs = {
    context,
    recipientType,
  };
  const nameDiverRecipient =
    recipientType === 'personal' && previewRecipient
      ? previewRecipient
      : validSelectedRecipients.length === 1
      ? validSelectedRecipients[0]
      : undefined;

  if (context === 'booking') {
    let bookingRecipient = previewRecipient;
    if (!bookingRecipient) {
      // first validSelectedRecipients
      bookingRecipient = validSelectedRecipients?.find(
        (x) => x.formRecipient.isBookingContact,
      );
      if (!bookingRecipient) {
        bookingRecipient = validSelectedRecipients?.[0];
      }
    }
    let bookingAliasKey =
      bookingRecipient?.formRecipient?.bookingMember?.bookingAliasKey;

    const customerSpaceAuthenticationUrl = bookingAliasKey
      ? appUrlBuilderCore.buildShortUrlLinkOnDiverSite({
          aliasKey: bookingAliasKey,
          diverWebUrl: appWebConfig.applications.diverWebUrl,
        })
      : 'https://diver.mabadive.com/xxx';
    args.bookingContext = {
      customerSpaceAuthenticationUrl,
    };
    if (nameDiverRecipient) {
      args.bookingContext.diver = {
        firstName: nameDiverRecipient.formRecipient.diver.firstName,
        lastName: nameDiverRecipient.formRecipient.diver.lastName,
      };
    }
  }
  if (context === 'session') {
    args.sessionContext = {
      diveSession: {
        time: initialState.sessionContext?.diveSession?.time,
      },
    };
    if (nameDiverRecipient) {
      args.sessionContext.diver = {
        firstName: nameDiverRecipient.formRecipient.diver.firstName,
        lastName: nameDiverRecipient.formRecipient.diver.lastName,
      };
    }
  }
  return args;
}
