import React, { FC, useMemo } from 'react';
import { AppIcon } from '../../../icons/AppIcon.type';
import { AppButtonUnstyled } from './AppButtonUnstyled';

export const AppButtonDeprecated: FC<{
  className?: string;
  icon?: AppIcon;
  buttonStyle:
    | 'outline-transparent-on-hover'
    | 'outline-transparent'
    | 'outline-transparent-primary'
    | 'outline-primary'
    | 'primary'
    | 'danger';
  size?: 'small' | 'normal';
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}> = ({
  children,
  icon,
  buttonStyle,
  size = 'normal',
  className,
  href,
  onClick,
  disabled,
}) => {
  const buttonStyleClass = useMemo(() => {
    let classes = size === 'normal' ? 'text-sm px-4 py-2' : 'text-xs px-2 py-1';
    switch (buttonStyle) {
      case 'primary':
        classes +=
          ' border-gray-300 text-gray-200 bg-app-blue hover:bg-app-dark-blue';
        break;
      case 'outline-primary':
        classes +=
          ' border-gray-300 text-gray-200 bg-app-blue hover:bg-app-dark-blue';
        break;
      case 'danger':
        classes +=
          ' border-gray-300 text-gray-200 bg-app-red hover:bg-app-dark-red';
        break;
      case 'outline-transparent':
        classes += ' border-gray-300 text-gray-700 bg-white hover:bg-gray-100';
        break;
      case 'outline-transparent-primary':
        classes += ' border-gray-300 text-app-blue bg-white hover:bg-gray-100';
        break;
      case 'outline-transparent-on-hover':
        classes +=
          'border-transparent hover:border-gray-300 text-gray-700 bg-white hover:bg-gray-100';
        break;
    }
    return classes;
  }, [buttonStyle, size]);

  const iconStyleClass = useMemo(
    () => (size === 'normal' ? 'mr-2 h-5 w-5' : 'mr-1 h-3 w-3'),
    [size],
  );

  const AppButtonIcon = icon;
  return (
    <AppButtonUnstyled
      gap={size === 'small' ? 1 : 2}
      className={`${buttonStyleClass} ${className} rounded-sm`}
      icon={() => {
        if (AppButtonIcon) {
          return (
            <AppButtonIcon
              className={`-ml-1 ${iconStyleClass}`}
              aria-hidden="true"
            />
          );
        }
        return null;
      }}
      href={href}
      onClick={onClick}
      children={children}
      disabled={disabled}
    />
  );
};
