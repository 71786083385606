/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButtonsBar } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiverPurchasePaymentEditorDialogLocalState } from './useDiverPurchasePaymentEditorDialogLocalState';
import { useEditPaymentNavigation } from './useEditPaymentNavigation.hook';

export const DiverPurchasePaymentEditorDialogFooterActionsBar = ({
  localState,
}: {
  localState: DiverPurchasePaymentEditorDialogLocalState;
}) => {
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const {
    form,
    navigation: { currentStep, setCurrentStep, steps },
    inputState,
  } = localState;

  const { goBack, goNext, closeAndCancel } = useEditPaymentNavigation({
    localState,
  });

  const { register, handleSubmit, watch, control, formState, setValue, reset } =
    form;

  return (
    <AppButtonsBar hasChanges={true}>
      <>
        {currentStep === 'select-purchases' && (
          <>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionCancel}
              tabIndex={500}
              color="gray-outline-light"
              onClick={goBack}
            >
              {inputState.mode === 'create' ? 'Annuler' : 'Fermer'}
            </AppButton>
            <AppButton
              className="uppercase font-bold"
              disabled={!formState.isValid}
              size="normal"
              icon={AppHeroIcons.actionConfirm}
              color="primary-outline-light"
              onClick={goNext}
            >
              {'Confirmer'}
            </AppButton>
          </>
        )}
        {currentStep === 'edit-details' && (
          <>
            {inputState.mode === 'create' ? (
              <AppButton
                size="normal"
                icon={AppHeroIcons.actionCancel}
                tabIndex={500}
                color="gray-outline-light"
                onClick={goBack}
              >
                {'Retour'}
              </AppButton>
            ) : (
              <AppButton
                size="normal"
                icon={AppHeroIcons.actionCancel}
                tabIndex={500}
                color="gray-outline-light"
                onClick={closeAndCancel}
              >
                {'Annuler'}
              </AppButton>
            )}
            <AppButton
              className="uppercase font-bold"
              disabled={!formState.isValid}
              size="normal"
              icon={AppHeroIcons.actionConfirm}
              color="primary-outline-light"
              onClick={goNext}
            >
              {'Confirmer'}
            </AppButton>
          </>
        )}
      </>
    </AppButtonsBar>
  );
};
