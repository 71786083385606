import { ClubDiver } from '@mabadive/app-common-model';
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import React, { useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { SessionsCountByDiver } from '../../models';
import { ResidentTypeLabelBadge } from '../ResidentTypeLabelBadge';

export function BillingParticipantPurchaseResumeTableDiverName({
  diver,
  diverSessionsCount,
  opacity,
  showResidentType: showResidentTypeInput,
  className,
}: {
  diver: Pick<
    ClubDiver,
    | 'birthdate'
    | 'mainCertificationReference'
    | 'firstName'
    | 'lastName'
    | 'fakeName'
    | 'age'
    | 'residentType'
  >;
  diverSessionsCount?: SessionsCountByDiver; // attention, pas toujours renseigné!!!
  opacity?: 60;
  showResidentType: boolean;
  className?: string;
}) {
  const clubSettings = useClubSettings();

  const age = useMemo(
    () =>
      diver?.birthdate ? dateService.getAge(diver?.birthdate) : diver?.age,
    [diver?.age, diver?.birthdate],
  );

  const clubResume = useClubResume();
  const showResidentType =
    showResidentTypeInput &&
    clubResume?.clubSettings?.general?.billing?.showResidentType;

  return (
    <div className={`block ${className ?? ''}`}>
      <p
        className={`font-bold truncate text-app-blue ${
          opacity === 60 ? 'text-opacity-60' : ''
        }`}
      >
        {nameFormatter.formatFullName(diver, { format: 'firstName-first' })}
        {diver?.mainCertificationReference && (
          <span
            className={`ml-1 text-gray-600 ${
              opacity === 60 ? 'text-opacity-60' : ''
            }`}
          >
            ({diver?.mainCertificationReference})
          </span>
        )}
        {clubSettings.ui?.billing?.displayBilledExploSessionsCounts &&
          diverSessionsCount?.pastBilledExploSessionsCounts > 0 && (
            <Tippy
              content={`${diverSessionsCount?.pastBilledExploSessionsCounts} explos facturées`}
            >
              <span className="ml-2 bg-gray-600 text-white px-1.5 py-0.5 rounded-full text-xs">
                {diverSessionsCount?.pastBilledExploSessionsCounts}
              </span>
            </Tippy>
          )}
      </p>

      <div className="flex gap-1 text-xs font-bold">
        {showResidentType && diver?.residentType && (
          <ResidentTypeLabelBadge residentType={diver?.residentType} />
        )}
        {age > 0 && (
          <div
            className={`text-gray-500 ${
              opacity === 60 ? 'text-opacity-60' : ''
            }`}
          >
            {age} ANS
          </div>
        )}
      </div>
    </div>
  );
}
