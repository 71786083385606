/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC } from 'react';
import {
  AppNavigationStepsBar,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  DiverPurchasePaymentEditorDialogResult,
  DiverPurchasePaymentEditorDialogState,
} from '../../models';
import { DiverPurchasePaymentEditorDialogFooterActionsBar } from './DiverPurchasePaymentEditorDialogFooterActionsBar';
import { EditPaymentSelectPurchasesPanel } from './panels';
import { EditPaymentManualPaymentDetailsPanel } from './panels/EditPaymentManualPaymentDetailsPanel';
import {
  DiverPurchasePaymentEditorDialogLocalState,
  useDiverPurchasePaymentEditorDialogLocalState,
} from './useDiverPurchasePaymentEditorDialogLocalState';
import { useEditPaymentNavigation } from './useEditPaymentNavigation.hook';

export const DiverPurchasePaymentEditorDialog: FC<{
  state: DiverPurchasePaymentEditorDialogState;
  setState: React.Dispatch<
    React.SetStateAction<DiverPurchasePaymentEditorDialogState>
  >;
  onConfirm?: (result: DiverPurchasePaymentEditorDialogResult) => void;
}> = ({ onConfirm, state: inputState, setState: setInputState }) => {
  const globalClasses = useGlobalClasses();

  const mainCurrency = useAppCurrencyMain();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const paymentsSettings = clubSettings?.general?.payments;
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const localState: DiverPurchasePaymentEditorDialogLocalState =
    useDiverPurchasePaymentEditorDialogLocalState({
      inputState,
      setInputState,
      onConfirm,
    });

  const { paymentChangesModel, actions, initialFormValue, form, navigation } =
    localState;

  const { steps, currentStep, setCurrentStep } = navigation;

  const { goBack, goNext } = useEditPaymentNavigation({
    localState,
  });

  return !inputState.isOpen ? null : (
    <form
      className="w-full"
      noValidate
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        if (form.formState.isValid) {
          goNext();
        }
      }}
    >
      <AppPageContainerWithFixedBars
        className={clsx('max-w-screen-2xl', inputState.isOpen ? '' : 'hidden')}
        contentClassName="bg-white app-p-content"
        headerBar={() => (
          <div className="bg-white app-p-content">
            <AppNavigationStepsBar
              steps={steps}
              value={currentStep}
              onChange={(step) => {
                setCurrentStep(step.id);
              }}
              disableNavigation={!form.formState.isValid}
            />
          </div>
        )}
        footerBar={() => (
          <DiverPurchasePaymentEditorDialogFooterActionsBar
            localState={localState}
          />
        )}
      >
        <input className="hidden" type="submit" />{' '}
        {currentStep === 'select-purchases' && (
          <>
            <EditPaymentSelectPurchasesPanel localState={localState} />
          </>
        )}
        {currentStep === 'edit-details' && (
          <>
            <EditPaymentManualPaymentDetailsPanel localState={localState} />
          </>
        )}
      </AppPageContainerWithFixedBars>
    </form>
  );
};
