/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { useProgressiveListRendering } from 'src/business/_core/data/hooks';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubEventCardGroup } from '../../club-events';
import { PlanningParticipantsLocalState } from '../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { PlanningViewConfig } from '../../club-planning/_model';
import { ClubPlanningLightPanelStaffDailyCounters } from '../ClubPlanningLightPanelViewPeriodDay';
import { ClubPlanningDisplayConfigurationBarContext } from '../club-planning-navigation';
import { CalendarSessionWidthMode } from '../components/ClubPlanningLightSession/session-common';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
  DiveSessionSimpleCardDisplayOptions,
} from '../model';
import { ClubPlanningLightPanelViewPeriodWeekSessionsModeFullDaySessions } from './ClubPlanningLightPanelViewPeriodWeekSessionsModeFullDaySessions';
import { ClubDialogsState } from '../../../../../pages/_dialogs';

export const ClubPlanningLightPanelViewPeriodWeekSessionsModeFull = ({
  planningParticipantsLocalState,
  dialogsState,
  context,
  days,
  clickActions,
  sessionsDisplayConfigs = [],
  planningConfig,
  widthMode,
  className,
}: {
  planningParticipantsLocalState?: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
  dialogsState: ClubDialogsState; // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
  context: ClubPlanningDisplayConfigurationBarContext;
  days: ClubPlanningDayFull[];
  clickActions: ClubPlanningLightPanelViewClickActions;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  planningConfig: PlanningViewConfig;
  widthMode: CalendarSessionWidthMode;
  className?: string;
}) => {
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const {
    onClickSession,
    onClickSessionOpenButton,
    onClickAddParticipant,
    onClickParticipant,
    anchorLinkParticipant,
    onClickCreateNewSession,
    onClickCreateNewEvent,
    onClickEvent,
  } = clickActions;

  const maxSessionsPerDay = useMemo(
    () =>
      days.reduce((acc, day) => {
        if (acc < day.sessions.length) {
          return day.sessions.length;
        }
        return acc;
      }, 0),
    [days],
  );

  const displayOptions: DiveSessionSimpleCardDisplayOptions = useMemo(
    () => ({
      boats: diveCenterResume?.boats.length > 1,
      tide: true,
      weather: true,
      comment: true,
      addParticipantButton1: false,
      addParticipantButton2: onClickAddParticipant !== undefined,
      openSessionButton: true,
      showDiveGroups: context !== 'planning-staff-page',
      showStaffRoles: context === 'planning-staff-page',
      isExpandable: false, // !day.sessions[iDailySession].isVirtual, // not virtual
    }),
    [diveCenterResume?.boats.length, onClickAddParticipant, context],
  );

  const hasAnyEvent: boolean = useMemo(
    () => days.find((day) => day.clubEvents.length > 0) !== undefined,
    [days],
  );

  const weekDailySessionsIndexes = useProgressiveListRendering(
    Array(maxSessionsPerDay),
    {
      initialSize: 3,
      step: 10,
      stepFrequencyInMs: 50,
    },
  );

  return (
    <div className={className}>
      {planningConfig.showStaffCountsPerDay && (
        <div className={'grid grid-cols-7 gap-0.5 sm:gap-2'}>
          {days.map((day, i) => (
            <div key={i}>
              <ClubPlanningLightPanelStaffDailyCounters
                planningConfig={planningConfig}
                date={day.date}
                showTooltip={true}
                staffMembersResumeSessions={day.staffMembersResumeSessions}
                className="flex my-1 text-app-xxs md:text-xs"
                onClick={() => {
                  clickActions.onClickStaffDailyCounters &&
                    clickActions.onClickStaffDailyCounters({
                      clubReference,
                      day,
                    });
                }}
              />
            </div>
          ))}
        </div>
      )}
      {hasAnyEvent && (
        <div className={'grid grid-cols-7 gap-0.5 sm:gap-2'}>
          {days.map((day, i) => (
            <ClubEventCardGroup
              key={i}
              className="h-full"
              clubEvents={day.clubEvents}
              widthMode={widthMode}
              onClick={onClickEvent}
            />
          ))}
        </div>
      )}
      {Array.from(weekDailySessionsIndexes, (e, iDailySession) => {
        return (
          <div
            key={iDailySession}
            className={'max-h-full grid grid-cols-7 gap-0.5 sm:gap-2 '}
            // pas de overflow sinon le menu ne peut pas s'ouvrir
          >
            {days.map((day, iDay) => (
              <ClubPlanningLightPanelViewPeriodWeekSessionsModeFullDaySessions
                key={iDay}
                day={day}
                clickActions={clickActions}
                sessionsDisplayConfigs={sessionsDisplayConfigs}
                planningParticipantsLocalState={planningParticipantsLocalState}
                dialogsState={dialogsState}
                planningConfig={planningConfig}
                widthMode={widthMode}
                displayOptions={displayOptions}
                iDailySession={iDailySession}
                className="flex flex-col justify-start bg-gray-50 border-l border-r border-gray-200"
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};
