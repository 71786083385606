/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { AppExpansionPanel } from 'src/business/_core/modules/layout';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BillingTabParticipantPurchase } from '../../models';
import { BillingParticipantPurchaseResumeTable } from './../../components/BillingParticipantPurchaseResumeTable';
import { DiverBookingPageActions } from './../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from './../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageBillingTabState } from './useDiverBookingPageBillingTabState.hook';

export const DiverBookingPageBillingTabArchivedPurchasesYearly = ({
  globalState,
  localState,
  dialogs,
  actions,
  year,
  purchases,
}: {
  globalState: DiverBookingPageGlobalState;
  localState: DiverBookingPageBillingTabState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  year: number;
  purchases: BillingTabParticipantPurchase[];
}) => {
  const isThisYear = year === new Date().getFullYear();
  const expandedByDefault = isThisYear && purchases.length < 10;
  const [isExpanded, setIsExpanded] = useState(expandedByDefault);

  const totalPriceSum = purchases.reduce(
    (a, b) => a + b.purchasePackageWithPayments?.purchasePackage?.price ?? 0,
    0,
  );

  return (
    <AppExpansionPanel
      isExpandable={true}
      isExpanded={isExpanded}
      expandPanel={() => {
        return () => setIsExpanded(!isExpanded);
      }}
      panelId="archived-purchases"
      renderSummaryTitle={() => (
        <div className="flex justify-between">
          <span>
            {year} ({purchases.length} prestations
            {totalPriceSum > 0 && ` - ${totalPriceSum}€`})
          </span>
          {!isExpanded && (
            <span className="hidden sm:flex text-xs text-gray-400">
              CLIQUER POUR VOIR LE DÉTAIL
            </span>
          )}
        </div>
      )}
      renderSummaryExtra={() => null}
      renderDetails={() => (
        <BillingParticipantPurchaseResumeTable
          className="mb-1"
          billingTabLocalState={localState}
          participantPurchases={purchases}
          globalState={globalState}
          dialogs={dialogs}
          actions={actions}
        />
      )}
    />
  );
};
