import React from 'react';
import { AppTabsBarTab } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import { DiverBookingPageTabId } from './DiverBookingPageTabId.type';

export const DIVER_BOOKING_TABS: AppTabsBarTab<DiverBookingPageTabId>[] = [
  {
    id: 'edit-participant',
    label: 'Plongeur',
    labelShort: 'Plongeur',
    icon: <EntitiesIcons.diver className="w-6 h-6" />,
    urlSuffix: 'edit-participant',
    auth: { requiredRoles: ['club-view-participant-diver'] },
  },
  {
    id: 'booking',
    label: 'Réservations',
    labelShort: 'Résa.',
    icon: <EntitiesIcons.booking className="w-6 h-6" />,
    urlSuffix: 'booking',
    auth: { requiredRoles: ['club-view-participant-booking'] },
  },
  {
    id: 'booking-sessions-history',
    label: 'Historique',
    labelShort: 'Historique',
    urlSuffix: 'history',
    icon: <AppHeroIcons.history className="w-6 h-6" />,
    auth: { requiredRoles: ['club-view-participant-history'] },
  },
  {
    id: 'billing',
    label: 'Facturation',
    labelShort: 'Fact.',
    icon: <AppHeroIcons.billing className="w-6 h-6" />,
    urlSuffix: 'billing',
    auth: { requiredRoles: ['club-view-participant-billing'] },
  },
  {
    id: 'payment',
    label: 'Paiements',
    labelShort: 'Paie.',
    icon: <EntitiesIcons.payment className="w-6 h-6" />,
    urlSuffix: 'billing',
    auth: { requiredRoles: ['club-view-participant-payments'] },
  },
  {
    id: 'messaging',
    label: 'Messages',
    labelShort: 'Msg.',
    icon: <AppHeroIcons.sms className="w-6 h-6" />,
    urlSuffix: 'messaging',
    // TODO: créer un role à part, et ne plus utiliser ce format de roles (pour ne pas allourdir plus l'entête)
    auth: { requiredRoles: ['club-view-participant-booking'] },
  },
];
