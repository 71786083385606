import { ClubDiverResidentType } from '@mabadive/app-common-model';
import { clubResidentTypeFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export function ResidentTypeLabelBadge({
  residentType,
  className,
}: {
  residentType: ClubDiverResidentType;
  className?: string;
}) {
  return !residentType ? null : (
    <div
      className={clsx(
        'flex font-bold gap-1',
        residentType === 'holidays' ? 'text-pink-400' : 'text-gray-600',
        className,
      )}
    >
      {residentType === 'holidays' ? (
        <AppHeroIcons.residentTypeHolidays className="w-4 h-4" />
      ) : (
        <AppHeroIcons.residentTypeLocal className="w-4 h-4" />
      )}

      {clubResidentTypeFormatter.format(residentType)?.toUpperCase()}
    </div>
  );
}
