/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { DiveSessionSimpleCardParticipantsGroupFakePanel } from './DiveSessionSimpleCardParticipantsGroupFakePanel';
import { DiveSessionSimpleCardParticipantsGroupPanel } from './DiveSessionSimpleCardParticipantsGroupPanel';
import { UseDiveSessionSimpleCardLocalState } from './useDiveSessionSimpleCardLocalState.hook';

export const DiveSessionSimpleCardDiveGroups = ({
  localState,
  className,
}: {
  localState: UseDiveSessionSimpleCardLocalState;
  className?: string;
}) => {
  const { state, data, actions } = localState;
  const { planningConfig, widthMode } = state;
  const { isPast, session, diveSessionParticipantsByGroup } = data;

  const {
    onClickSession,
    onClickParticipant,
    onClickGroupDetails,
    anchorLinkParticipant,
  } = actions;

  return (
    <div
      className={clsx('flex flex-col gap-2', className)}
      onClick={() => {
        !onClickParticipant && onClickSession(session);
      }}
    >
      {diveSessionParticipantsByGroup.byGroupExtended && (
        <>
          {diveSessionParticipantsByGroup.byGroupExtended.map(
            ({ group, participants, type }, groupIndex) => {
              const showExpandableGroupParticipantsResume =
                group?.isVirtualGroup ||
                ((group?.diveMode === 'autoSupervised' ||
                  group?.diveMode === 'theoretical-training') &&
                  participants.length > 5);

              return (
                <DiveSessionSimpleCardParticipantsGroupPanel
                  key={`group-${group?._id ?? 'fake'}-${groupIndex}`}
                  diveSession={session}
                  isPast={isPast}
                  group={group}
                  groupOdd={groupIndex % 2 === 0}
                  settingsUIPlanning={planningConfig}
                  participants={participants}
                  onClickParticipant={
                    onClickParticipant
                      ? (participant) =>
                          onClickParticipant({
                            participant,
                            session,
                          })
                      : undefined
                  }
                  onClickGroupDetails={
                    !onClickGroupDetails
                      ? undefined
                      : ({ participants, group }) =>
                          onClickGroupDetails({
                            diveSessionResume: session,
                            participants,
                            group,
                          })
                  }
                  anchorLinkParticipant={anchorLinkParticipant}
                  widthMode={widthMode}
                  showGroupParticipantsResume={
                    showExpandableGroupParticipantsResume
                  }
                  isExpandable={showExpandableGroupParticipantsResume}
                />
              );
            },
          )}
        </>
      )}
      {diveSessionParticipantsByGroup.assignedFreeInstructors?.length > 0 && (
        <>
          {diveSessionParticipantsByGroup.assignedFreeInstructors.map(
            (staffMember, staffMemberIndex) => (
              <DiveSessionSimpleCardParticipantsGroupFakePanel
                key={staffMember._id}
                staffMember={staffMember}
                diveSession={session}
                isPast={isPast}
                settingsUIPlanning={planningConfig}
                widthMode={widthMode}
              />
            ),
          )}
        </>
      )}
    </div>
  );
};
