/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppIcons, AppIconsUI } from 'src/business/_core/modules/layout/icons';

export const ClubSettingsViewActivableFeature = ({
  isActive,
  label,
  inactiveLabel,
  children,
}: {
  isActive: boolean;
  label?: string;
  inactiveLabel?: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <>
      {!isActive ? (
        inactiveLabel ?? label ? (
          <div className="my-2 font-bold text-gray-400 uppercase">
            <AppIconsUI.disabled className="inline h-5 w-5" />{' '}
            {inactiveLabel ?? label}
          </div>
        ) : null
      ) : (
        <>
          {label && (
            <div className="my-2 font-bold text-gray-500 uppercase">
              <AppIcons.action.confirm className="inline h-5 w-5" /> {label}
            </div>
          )}
          {children && children}
        </>
      )}
    </>
  );
};
