/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  AppBreadcrumbBar,
  AppLoader,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import { ClubDialogsProvider } from 'src/pages/_dialogs';

import { DataManagementArchiveDataPanel } from './DataManagementArchiveDataPanel';
import { DataManagementConfirmDeleteDataPanel } from './DataManagementConfirmDeleteDataPanel';
import {
  DataManagementPageLocalState,
  useDataManagementPageLocalState,
} from './useDataManagementPageLocalState.hook';

export const ClubSettingsAccountDataManagementPage = () => {
  const localState: DataManagementPageLocalState =
    useDataManagementPageLocalState();

  const { dialogsState, state, data } = localState;
  return (
    <AppPageContainer className={'bg-gray-50'}>
      <ClubDialogsProvider dialogsState={dialogsState}>
        <AppPageContentContainer paddingBottom={false} className="bg-gray-50">
          <AppBreadcrumbBar
            color={AppIconsSettings.other.color}
            items={[
              {
                icon: AppIconsSettings.general,
                label: 'Paramètres',
                url: '/club/settings',
              },
              {
                icon: AppIconsSettings.other.main,
                label: 'Configuration',
                url: '/club/settings',
              },
              {
                label: 'Gestion des données',
              },
            ]}
          />
          <div className="app-p-content">
            {state.isPersistInProgress ? (
              <AppLoader type="loading" />
            ) : (
              <>
                {state.mode === 'default' && (
                  <DataManagementArchiveDataPanel localState={localState} />
                )}
                {state.mode === 'confirm-delete-data' && (
                  <DataManagementConfirmDeleteDataPanel
                    localState={localState}
                  />
                )}
              </>
            )}
          </div>
        </AppPageContentContainer>
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
