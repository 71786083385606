import {
  SETTINGS_CUSTOMERS_PAGE_COMMUNICATION,
  SETTINGS_CUSTOMERS_PAGE_INQUIRIES,
  SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING,
  SETTINGS_CUSTOMERS_PAGE_ONLINE_PAYMENT,
} from './SETTINGS_CUSTOMERS.const';
import {
  ClubSettingsCommunicationPageTabId,
  clubSettingsCommunicationPageUrlManager,
} from './communication/_services';

export const clubSettingsCustomersUrlBuilder = {
  communication: {
    view: ({ tabId }: { tabId: ClubSettingsCommunicationPageTabId }) => {
      const searchParams: string[] =
        clubSettingsCommunicationPageUrlManager.buildSearchParams({
          mode: 'view',
          tabId,
        });
      return (
        SETTINGS_CUSTOMERS_PAGE_COMMUNICATION.url + `?${searchParams.join('&')}`
      );
    },
  },
  onlinePayments: {
    list: () => SETTINGS_CUSTOMERS_PAGE_ONLINE_PAYMENT.url,
    create: () => `${SETTINGS_CUSTOMERS_PAGE_ONLINE_PAYMENT.url}/nouveau`,
    // edit: ({ onlinePlatformId }: { onlinePlatformId: string }) =>
    //   `${SETTINGS_CUSTOMERS_PAGE_ONLINE_PAYMENT.url}/${onlinePlatformId}/edit`,
  },
  onlineBooking: {
    products: () => `${SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING.url}`,
    config: () => `${SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING.url}/config`,
  },
  inquiries: {
    list: () => SETTINGS_CUSTOMERS_PAGE_INQUIRIES.url,
    create: () => `${SETTINGS_CUSTOMERS_PAGE_INQUIRIES.url}/create`,
    edit: ({ inquiryId }: { inquiryId: string }) =>
      `${SETTINGS_CUSTOMERS_PAGE_INQUIRIES.url}/edit/${inquiryId}`,
  },
};
