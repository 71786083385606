import { DiveModeGroup } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleSelect2HeadlessUIRHF,
} from 'src/lib/form';

import { AppButton } from '../../../../../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../../../../../business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from '../../../../../../../../business/auth/services';
import { ValueLabel } from '../../../../../../../../business/club/modules/_common/form';
import { useDiveModeGroupsOptions } from '../../../../../../../_components/options';
import { EcommerceProductEditorLocalState } from '../useEcommerceProductEditorPanelLocalState.hook';

export const EcommerceProductEditorFormSettings = ({
  localState,
  className = '',
}: {
  localState: EcommerceProductEditorLocalState;
  className?: string;
}) => {
  const {
    data,
    state: { form },
  } = localState;
  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    formState,
    setValue,
  } = form;

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const [bookletPage, productName, diveModeGroup] = useWatch({
    control,
    name: ['appBookletPage', 'product.name', 'product.diveModeGroup'],
  });
  const diveModeGroupsOptions: ValueLabel<DiveModeGroup>[] =
    useDiveModeGroupsOptions({
      format: 'long',
    });

  const createBooklet = useCallback(() => {
    setValue('appBookletPage', {
      mainContent: {
        languageCode: 'fr',
        content: {
          title: productName,
          items: [],
        },
        cover: {},
      },
    });
  }, [productName, setValue]);

  return (
    <div className={clsx('grid grid-cols-1 gap-4', className)}>
      <h3 className="mx-1 mt-6 mb-2 text-sm font-bold text-app-primary uppercase">
        {'Paramètres'}
      </h3>
      <div className={'flex flex-wrap gap-y-4 gap-x-4'}>
        {/* <AppFormControlRHF
          label="Nom (interface PRO)"
          helpDescription="Nom utilisé sur l'interface PRO uniquement"
          control={control}
          name={'product.name'}
          validation={{
            rules: {
              maxLength: 30,
            },
          }}
          required={true}
          renderComponent={(props) => <AppInputRHF {...props} type="text" />}
        /> */}
        <AppFormControlRHF
          control={control}
          label={'Activé'}
          name="product.enabled"
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        {(data?.productArticlesFull?.length === 0 ||
          !diveModeGroup ||
          isSuperAdmin) && (
          <AppFormControlRHF
            label="Activité"
            className="w-64"
            control={control}
            name={'product.diveModeGroup'}
            required={true}
            // disabled={data?.productArticlesFull?.length > 0}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                {...props}
                theme="tailwind"
                disableClearButton={true}
                options={diveModeGroupsOptions}
              />
            )}
          />
        )}
        <AppFormControlRHF
          control={control}
          label={'Age mini'}
          name="product.details.minAge"
          validation={{
            rules: {
              min: 1,
            },
          }}
          renderComponent={(props) => <AppInputRHF type="number" {...props} />}
        />
        <AppFormControlRHF
          control={control}
          label={'Age maxi'}
          name="product.details.maxAge"
          validation={{
            rules: {
              min: 1,
            },
          }}
          renderComponent={(props) => <AppInputRHF type="number" {...props} />}
        />
      </div>
      <div className="flex gap-4">
        {!bookletPage && (
          <AppButton
            size="normal"
            color="gray-outline-light"
            icon={AppIconsAction.edit}
            onClick={(e) => {
              e.stopPropagation();
              createBooklet();
            }}
          >
            {'Ajouter une description'}
          </AppButton>
        )}
      </div>
    </div>
  );
};
