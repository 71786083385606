import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { of } from 'rxjs';
import { uiStore } from 'src/business/_core/store';
import { AccountProfileRoot } from 'src/business/account/modules/account-profile';
import { LogoutPage } from 'src/business/auth/pages';
import { AuthRootSpace } from 'src/business/auth/pages/AuthRootSpace';
import { ClubRootSpace } from 'src/business/club/modules/club-root/pages';
import { AdminRootSpace } from 'src/pages/AD-super-admin';
import { TopBar, useMediaSizeMaterialUI } from '../../../layout';
import AppSnackbar from '../../../layout/components/AppSnackBar/AppSnackbar';
import { ConfirmDialogManager } from '../../../layout/components/ConfirmDialog';
import { AppRoute } from '../../../router/AppRoute/AppRoute';
import { ShortLinkResolverComponent } from '../short-link-resolver';
import { LoadingProgressBar } from './LoadingProgressBar';
import { appRouteBuilder } from './appRouteBuilder.service';
import { useAppRoot } from './useAppRoot.hook';

const NEVER_RE_RENDER = () => true;

export const AppRoot = React.memo(function AppRootMemo() {
  // TODO: supprimer complètement le data loading du club ici (il ne reste presque rien)
  const { loaded, loadingStats } = useAppRoot(); // manage authentication & load app configuration

  const mediaSize = useMediaSizeMaterialUI();

  useMemo(() => {
    uiStore.isMenuCollapsed.set(mediaSize === 'md');
  }, [mediaSize]);

  return (
    <div className="flex bg-[#f0f0f0] min-h-[100vh] w-screen items-start">
      <TopBar />
      {loaded && loadingStats.running === 0 ? (
        <Switch>
          <AppRoute
            path="/s/:aliasKey"
            component={ShortLinkResolverComponent} // fake component
          />
          <AppRoute path="/auth" component={AuthRootSpace} />
          {/* <AppRoute path="/demo" exact component={LaunchDemoPage} /> */}
          <AppRoute
            path="/club"
            component={ClubRootSpace}
            auth={{ requiredRoles: ['club'] }}
          />
          <AppRoute
            path="/admin"
            component={AdminRootSpace}
            auth={{ requiredRoles: ['super-admin'] }}
          />
          <AppRoute
            path="/account"
            component={AccountProfileRoot}
            auth={{ authenticationRequired: true }}
          />

          <AppRoute
            path="/"
            component={AuthRootSpace}
            exact
            redirectTo={({ appAuth }) =>
              appAuth && appAuth.isAuthenticated
                ? of(appRouteBuilder.getDefaultRouteFromAuth({ appAuth }))
                : of(undefined)
            }
          />
          <AppRoute
            path={'/logout'}
            exact
            auth={{ authenticationRequired: true }}
            component={LogoutPage}
          />
          {/* default route */}
          <AppRoute
            path="/*"
            redirectTo={({ appAuth }) =>
              of(appRouteBuilder.getDefaultRouteFromAuth({ appAuth }))
            }
          />
        </Switch>
      ) : (
        <LoadingProgressBar hasError={loadingStats?.error !== 0} />
      )}
      {/* {appWebConfig.envId === 'dev' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )} */}
      <AppSnackbar />
      <ConfirmDialogManager />
    </div>
  );
}, NEVER_RE_RENDER);
