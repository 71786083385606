import {
  BookingMemberResumeFullGql_Company,
  DiveSessionResumeGql_Company,
} from '@mabadive/app-common-model';
import { editionBookingParticipantResumeAttributesBuilder } from './editionBookingParticipantResumeAttributesBuilder.service';

export const editionBookingProductResumeAttributesBuilder = {
  buildAttributes,
};
/**
 * Build graphql attributes for EditionBookingResumeProduct
 */
function buildAttributes() {
  return `
  _id
  _createdAt
  _updatedAt
  _version
  diveCenterId
  clubReference
  bookingId
  bookingMemberId
  bookingSessionId
  bookingSessionParticipantId
  bookingDate
  bookingLastUpdateDate
  type
  attributes
  purchasePackageId
  purchasePackage {
    purchasePaymentStatus
    purchasePaymentPending
    purchasePaymentWithCreditNote
  }
  bookingProductStatus
  bookingProductState
  bookingProductHistory
  purchaseStatus
  bookingMember {
    ${BookingMemberResumeFullGql_Company}
  }
  bookingSession {
    _id
    diveCenterId
    diveSessionReference
    bookingSessionStatus
    diveSession {
      ${DiveSessionResumeGql_Company}
    }
  }
  bookingSessionParticipant {
   ${editionBookingParticipantResumeAttributesBuilder.buildAttributes()}
  }
`;
}
