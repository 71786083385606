/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { AppDisclosure } from 'src/business/_core/modules/layout';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';
import { DECOP_SessionItem } from '../DailyExportConfigOptionsPanelSheetContentItemSessionItem';

export const DailyExportConfigOptionsPanelSheetContentItemSessions = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}` as const;

  const sessionItemsFieldArray = useFieldArray({
    control,
    name: `${baseFormName}.items`,
    keyName: '_ref',
  });

  // const [selectedSessionDef] = useWatch({
  //   control,
  //   name: ['selectedSessionDef'],
  // });

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  return (
    <div className={clsx('grid gap-2', className)}>
      <h4 className="text-left text-base">
        <span className="font-medium text-gray-800">Sessions:</span>
        {'  '}
        <span className="text-gray-500">liste détaillées des sessions</span>
      </h4>
      {/* {isSuperAdmin && (
        <AppFormControlRHF_Deprecated
          label={'Nombre de colonnes'}
          helpDescription='Renseignez "2" pour afficher les sessions sur 2 colonnes'
          control={control}
          name={`${baseFormName}.columns`}
          validation={{
            rules: {
              min: 1,
            },
          }}
          renderComponent={(props) => <AppInputRHF {...props} type="number" />}
        />
      )} */}
      <AppDisclosure
        defaultExpanded={true}
        className="w-full"
        title={
          <div className="flex-grow text-left text-sm text-gray-400 uppercase">
            Afficher les détails
          </div>
        }
      >
        <div className="grid gap-4">
          {sessionItemsFieldArray.fields.map(
            (sessionItem, sessionItemIndex) => (
              <DECOP_SessionItem
                key={sessionItem.id}
                localState={localState}
                dailyResumeIndex={dailyResumeIndex}
                sheetIndex={sheetIndex}
                contentItemIndex={contentItemIndex}
                sessionItemIndex={sessionItemIndex}
              />
            ),
          )}
        </div>
      </AppDisclosure>
    </div>
  );
};
