import { numberFormatter } from '@mabadive/app-common-services';
import React, { useMemo, useState } from 'react';
import { Control, Path, useController } from 'react-hook-form';

// TODO une fois complètement migré vers react-hook-form, migrer les composants de 'material-ui' vers '@headlessui/react' (voir https://tailwindui.com/components/application-ui/forms)
export function AppInputTimeRHF<T>({
  clearable,
  control,
  name,
  className,
  onChange: onChangeEmmit,
  ...extraProps
}: {
  control: Control<T>;
  name: Path<T>;
  className?: string;
  clearable?: boolean;
  onChange?: (value: Date) => any;
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'type' | 'onChange'
>) {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<T>({
    name,
    control,
    rules: {
      required: extraProps.required,
    },
  });

  const [initialValue] = useState<Date>(value as unknown as Date);

  const isEmptyValue = useMemo(
    () => !value || ((value as string).trim && (value as string).trim() === ''),
    [value],
  );

  return (
    <input
      {...extraProps}
      {...inputProps}
      type={'time'}
      className={`${isEmptyValue ? 'empty' : ''} ${className ?? ''}`}
      value={formatDateToString(value as unknown as Date)}
      onChange={(e) => {
        let value = parseStringToDate({
          value: e.target.value,
          initialValue,
        });
        if (value === undefined) {
          value = null; // null, sinon elle n'est pas bien prise en compte
        }
        onChange(value);
        if (onChangeEmmit) {
          onChangeEmmit(value);
        }
      }}
      // error={invalid}
    />
  );
}

function parseStringToDate({
  value,
  initialValue,
}: {
  value: string;
  initialValue: Date;
}): Date {
  if (!value) {
    return undefined;
  }

  const chunks = value.split(':');
  if (chunks.length !== 2) {
    return undefined;
  }

  const hours = parseInt(chunks[0]);
  const minutes = parseInt(chunks[1]);
  if (isNaN(hours) || isNaN(minutes)) {
    return undefined;
  }
  const newDate = initialValue ? new Date(initialValue.getTime()) : new Date();
  newDate.setUTCHours(hours, minutes, 0, 0);

  return newDate;
}

function formatDateToString(value: Date) {
  if (value) {
    const str = `${numberFormatter.toFixedIntegerString(
      value.getUTCHours(),
      2,
    )}:${numberFormatter.toFixedIntegerString(value.getUTCMinutes(), 2)}`;
    return str;
  }
  return '';
}
