/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BOOKING_GROUP_TYPES,
  BookingGroupType,
  ClubPurchasePackagePaymentStatus,
  DiveServicePurchaseStatus,
  PURCHASE_VALIDITY_STATUSES,
} from '@mabadive/app-common-model';
import {
  bookingGroupTypeFormatter,
  diveServicePurchaseStatusFormatter,
} from '@mabadive/app-common-services';
import { useState } from 'react';
import { PurchaseResumeCollectionCriteriaPurchaseType } from 'src/business/_core/data/store-repository';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { useClubPurchasePackagePaymentStatusOptionsWithPending } from '../../_components/options';
import { PurchaseListPagePurchaseModel } from './model';

export type MonthId = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

const VALIDITY_STATUS_OPTIONS = PURCHASE_VALIDITY_STATUSES.map((value) => {
  const option: ValueLabel<DiveServicePurchaseStatus> = {
    label: diveServicePurchaseStatusFormatter.formatStatus(value).toUpperCase(),
    value,
  };
  return option;
});

const PURCHASE_TYPE_OPTIONS: ValueLabel<
  PurchaseResumeCollectionCriteriaPurchaseType | 'all'
>[] = [
  {
    value: 'training',
    label: 'Formation',
  },
  {
    value: 'package',
    label: 'Forfait',
  },
];

const BOOKING_GROUP_TYPES_OPTIONS = BOOKING_GROUP_TYPES.map((value) => {
  const option: ValueLabel<BookingGroupType> = {
    label: bookingGroupTypeFormatter
      .format(value, {
        format: 'long',
      })
      .toUpperCase(),
    value,
  };
  return option;
});
const BOOKING_GROUP_TYPES_OPTIONS_ALL = (
  [
    {
      label: 'TYPE',
      value: 'all',
    },
  ] as ValueLabel<BookingGroupType | 'all'>[]
).concat(BOOKING_GROUP_TYPES_OPTIONS);

export function useClubPurchasesListPageFilters({
  purchases = [],
}: {
  purchases: PurchaseListPagePurchaseModel[];
}) {
  const [searchTextContactName, setSearchTextContactName] = useState('');
  const [searchTextOfferName, setSearchTextOfferName] = useState('');
  const [bookingAgencyId, setBookingAgencyId] = useState<string>();
  const [validityStatus, setValidityStatus] =
    useState<DiveServicePurchaseStatus>();

  const [paymentStatus, setPaymentStatus] =
    useState<ClubPurchasePackagePaymentStatus | 'pending'>();

  const [purchaseType, setPurchaseType] =
    useState<PurchaseResumeCollectionCriteriaPurchaseType | 'all'>();

  const paymentStatusOptions =
    useClubPurchasePackagePaymentStatusOptionsWithPending();

  return {
    searchTextContactName,
    setSearchTextContactName,
    searchTextOfferName,
    setSearchTextOfferName,
    validityStatus,
    setValidityStatus,
    paymentStatus,
    setPaymentStatus,
    purchaseType,
    setPurchaseType,
    validityStatusOptions: VALIDITY_STATUS_OPTIONS,
    paymentStatusOptions,
    purchaseTypeOptions: PURCHASE_TYPE_OPTIONS,
    bookingAgencyId,
    setBookingAgencyId,
  };
}
