import { ClubResumeBoat } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubResumeClubBoatFormModel } from './model';

export function useClubBoatFormLocalState({
  mode,
  clubBoatId,
}: {
  mode: 'create' | 'edit';
  clubBoatId?: string;
}) {
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const clubBoat: ClubResumeBoat = useMemo(
    () =>
      mode === 'edit'
        ? diveCenterResume?.boats.find((x) => x._id === clubBoatId)
        : undefined,
    [mode, diveCenterResume?.boats, clubBoatId],
  );

  const showIsDefault =
    (mode === 'create' && diveCenterResume?.boats.length > 0) ||
    (mode === 'edit' && diveCenterResume?.boats.length > 1);

  const initialFormValue: Partial<ClubResumeClubBoatFormModel> = useMemo(() => {
    if (mode === 'create') {
      const createInitialValue: Partial<ClubResumeClubBoatFormModel> = {
        isDefault: showIsDefault ? false : true,
      };
      return createInitialValue;
    } else if (clubBoat) {
      const editInitialValue: Partial<ClubResumeClubBoatFormModel> = {
        ...clubBoat,
      };
      return editInitialValue;
    }
  }, [mode, clubBoat, showIsDefault]);

  const form = useForm<ClubResumeClubBoatFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  return {
    mode,
    clubBoat,
    showIsDefault,
    form,
    initialFormValue,
  };
}
