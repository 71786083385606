import { ClubBoatDepartureType } from '@mabadive/app-common-model';
import { CLUB_RESSOURCES_TYPES } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useClubBoatDepartureTypesOptions(): ValueLabel<ClubBoatDepartureType>[] {
  return useMemo(
    () =>
      CLUB_RESSOURCES_TYPES.map(
        ({ departureType, defaultLabel, defaultColor }) => ({
          value: departureType,
          label: defaultLabel,
          color: defaultColor,
        }),
      ),
    [],
  );
}
