import {
  BillingTabDiveSessionBillingResume,
  BillingTabDiveSessionBillingResumeMultiDivers,
  BillingTabDiveSessionBillingResumePurchaseParticipant,
} from '../../../models';

export const billingResumeMultiDiversBuilder = {
  buildMultiDivers,
  updateParticipantStatus,
};

function buildMultiDivers(
  singleDiverResumes: BillingTabDiveSessionBillingResume[],
  {
    updatedAssociatedBookingProductIds,
  }: { updatedAssociatedBookingProductIds?: string[] },
): BillingTabDiveSessionBillingResumeMultiDivers[] {
  return singleDiverResumes.reduce((billingResumes, br) => {
    let purchaseParticipant: BillingTabDiveSessionBillingResumePurchaseParticipant;
    if (updatedAssociatedBookingProductIds) {
      purchaseParticipant =
        billingResumeMultiDiversBuilder.updateParticipantStatus({
          purchaseParticipant: br.purchaseParticipant,
          updatedAssociatedBookingProductIds,
        });
    } else {
      purchaseParticipant = br.purchaseParticipant;
    }

    const existing = billingResumes.find(
      (eBr) => eBr.diveSession?.reference === br.diveSession.reference,
    );
    if (existing) {
      existing.purchaseParticipants.push(purchaseParticipant);
    } else {
      const multiDivers: BillingTabDiveSessionBillingResumeMultiDivers = {
        diveSession: br.diveSession,
        purchaseParticipants: [purchaseParticipant],
      };
      billingResumes.push(multiDivers);
    }

    return billingResumes;
  }, [] as BillingTabDiveSessionBillingResumeMultiDivers[]);
}

function updateParticipantStatus({
  purchaseParticipant,
  updatedAssociatedBookingProductIds,
}: {
  purchaseParticipant: BillingTabDiveSessionBillingResumePurchaseParticipant;
  updatedAssociatedBookingProductIds: string[];
}): BillingTabDiveSessionBillingResumePurchaseParticipant {
  const purchaseStatus = updatedAssociatedBookingProductIds.includes(
    purchaseParticipant.bookingProduct._id,
  )
    ? 'purchased'
    : 'pending';

  return {
    ...purchaseParticipant,
    isAssociatedToPurchase: purchaseStatus === 'purchased',
    bookingProduct: {
      ...purchaseParticipant.bookingProduct,
      purchaseStatus,
    },
  };
}
