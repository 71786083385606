/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageNameDetails,
  ClubProductPackageNameDetailsFormatProps,
  productPackageFormatter,
  productTypeFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';

export const ClubOfferNameDetailsLabel = ({
  nameDetails,
  productPackage,
  className = '',
}: {
  nameDetails?: ClubProductPackageNameDetails;
  productPackage?: ClubProductPackageNameDetailsFormatProps;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;
  const clubSettings = useClubSettings();

  nameDetails = useMemo(() => {
    if (productPackage) {
      if (productPackage.type === 'product') {
        // supplément
        const nameDetails = {
          prefix: '',
          name: productPackage.label,
        };
        return nameDetails;
      }
      const nameDetails = productPackageFormatter.formatNameDetails(
        productPackage,
        {
          publicSettings,
          diveModesConf: clubSettings?.ui?.diveMode,
        },
      );
      return nameDetails;
    }
    return nameDetails;
  }, [productPackage, nameDetails, publicSettings, clubSettings?.ui?.diveMode]);

  return !nameDetails ? null : (
    <div className={` ${className}`}>
      <div className={'flex items-end gap-1'}>
        {nameDetails.prefix && <span className="">{nameDetails.prefix}</span>}
        {nameDetails.name && <span className="">{nameDetails.name}</span>}{' '}
        {nameDetails.label && (
          <span className="text-app-orange">{nameDetails.label}</span>
        )}
        {nameDetails.suffix && (
          <div className="text-gray-500">{nameDetails.suffix}</div>
        )}
      </div>
      {productPackage?.productType && (
        <div className="text-gray-500 font-normal">
          {productTypeFormatter.format(productPackage.productType)}{' '}
        </div>
      )}
    </div>
  );
};
