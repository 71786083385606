/* eslint-disable jsx-a11y/alt-text */
import {
  AppBookletPageGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useAppTheme } from '../../../../../../../business/club/data/hooks';
import { AppRichTextH1 } from '../../../../../../../lib/form/components/AppRichText/rendering/elements';
import { BookletEditorDisplayWidth } from '../../../ClubSettingsOnlineBookingConfigPage/tabs/02.products/view/BookletEditor_EditablePreview';

export const EcommerceProductPreviewContent = ({
  displayWidth,
  product,
  appBookletPage,
  className,
  children,
}: {
  displayWidth: BookletEditorDisplayWidth;
  product: Pick<EcommerceProductGql_Company, 'name'>;
  appBookletPage: Partial<AppBookletPageGql_Company>;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  const theme = useAppTheme();

  const content = appBookletPage?.mainContent?.content;

  return (
    product && (
      <div className={`w-full overflow-y-auto ${className}`}>
        {content ? (
          <>
            <div className="flex flex-col sm:flex-row-reverse md:items-start md:justify-between gap-8">
              {content.image && (
                <img
                  className="hidden xs:block mb-4 relative w-full rounded-xl max-w-[35lvw] max-h-[35lvw]"
                  src={content.image.publicUrl}
                />
              )}
              <div className="w-full flex-grow py-2 px-4">
                <div
                  className={clsx(
                    'uppercase font-bold w-full text-left',
                    displayWidth === 'mobile'
                      ? 'text-base'
                      : 'text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl',
                  )}
                  style={{ color: theme.title1.textColor }}
                >
                  {content?.title}
                </div>
                {content?.subTitle && (
                  <div
                    className={clsx(
                      'uppercase font-medium w-full text-left',
                      displayWidth === 'mobile'
                        ? 'text-sm'
                        : 'text-sm sm:text-base xl:text-lg 2xl:text-2xl',
                    )}
                    style={{ color: theme.title2.textColor }}
                  >
                    {content?.subTitle}
                  </div>
                )}
                {content.image && (
                  <img
                    className="xs:hidden mb-4 relative w-full rounded-xl max-w-[50lvw] max-h-[50lvw]"
                    src={content.image.publicUrl}
                  />
                )}
                {children && <div className="my-4">{children}</div>}
              </div>
            </div>
          </>
        ) : (
          <>
            <AppRichTextH1 theme={theme}>{product.name}</AppRichTextH1>
            {children && <div className="my-4">{children}</div>}
          </>
        )}
      </div>
    )
  );
};
