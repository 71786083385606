/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import React from 'react';
import { ClubSettingsPlanningViewDayModel } from './model';

export const ClubSettingsPlanningViewWeekDayHeader = ({
  day,
  className,
  onClick,
}: {
  day: ClubSettingsPlanningViewDayModel;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className={`rounded-t-lg p-1 bg-white border border-gray-300
      ${onClick ? 'cursor-pointer' : ''}
     py-1 text-center flex flex-col md:flex-row text-xs md:text-sm leading-4 sm:leading-4 md:justify-around md:items-center ${
       className ?? ''
     }
     
      `}
      onClick={onClick}
    >
      <div className={'py-3 text-app-xxs md:text-xs font-bold uppercase'}>
        <div className="lg:hidden">
          {dateService.formatUTC(day.dayDate, 'ddd')}
        </div>
        <div className="hidden lg:block">
          {dateService.formatUTC(day.dayDate, 'dddd')}
        </div>
      </div>
    </div>
  );
};
