import { DashboardReportPageMode } from '../model';

export const dashboardReportPageModeFormatter = { formatMode };

function formatMode(pageMode: DashboardReportPageMode): string {
  switch (pageMode) {
    case 'home': {
      return 'Général';
    }
    case 'first-dives': {
      return 'Baptêmes';
    }
    case 'explos': {
      return 'Explos';
    }
    case 'trainings': {
      return 'Formations';
    }
  }
}
