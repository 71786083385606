import {
  AppCurrency,
  ClubDiver,
  ClubPurchasePaymentPackage,
  ClubSettings,
} from '@mabadive/app-common-model';
import {
  nameFormatter,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppPriceLabelRatio } from 'src/business/club/modules/_common/ui';

export const PurchasePaymentCardProduct_PurchasePackage = ({
  clubSettings,
  purchasePaymentPackage,
  loadedDivers,
  paymentCurrency,
  className,
}: {
  clubSettings: ClubSettings;
  purchasePaymentPackage: ClubPurchasePaymentPackage;
  loadedDivers: Pick<ClubDiver, '_id' | 'lastName' | 'firstName'>[];
  paymentCurrency: AppCurrency;
  className?: string;
}) => {
  const diver = useMemo(
    () =>
      (loadedDivers ?? []).find(
        (d) => d._id === purchasePaymentPackage.purchasePackage.diverId,
      ),
    [loadedDivers, purchasePaymentPackage.purchasePackage.diverId],
  );

  const publicSettings = clubSettings.publicSettings;

  return (
    <li className={clsx('flex gap-4 py-2', className)}>
      <div className="flex-shrink-0">
        <img
          alt="Prestation payée"
          src={
            'https://mabadive.fra1.cdn.digitaloceanspaces.com/images%2Fproducts%2Fscuba-diving-300x300.png'
          }
          className="grayscale brightness-125 h-9 w-9 bg-gray-400 rounded-md"
        />
      </div>

      <div className="flex flex-1 flex-col">
        <h4 className="text-sm text-gray-600 uppercase">
          <div className="flex flex-wrap items-end gap-2">
            <span className="font-bold">
              {productPackageFormatter.formatDescription(
                purchasePaymentPackage.purchaseProductPackage,
                {
                  diveModesConf: clubSettings?.ui?.diveMode,
                },
              )}
            </span>{' '}
            <span className="font-normal">
              {productPackageFormatter.formatNameString(
                purchasePaymentPackage.purchaseProductPackage,
                {
                  publicSettings,
                  diveModesConf: clubSettings?.ui?.diveMode,
                },
              )}
            </span>
          </div>
        </h4>
        <div className="flex flex-wrap gap-2 text-xs font-normal text-gray-500">
          {diver && (
            <span className="font-bold">
              {nameFormatter.formatFullName(diver, {
                format: 'firstName-first',
              })}
            </span>
          )}{' '}
          <AppPriceLabelRatio
            amount={purchasePaymentPackage.amount}
            total={purchasePaymentPackage.purchasePackage.price}
            mainCurrency={paymentCurrency}
            hideTotalIfSameAmount={true}
          />
        </div>
      </div>
    </li>
  );
};
