import {
  BookingDeposit,
  ClubDiver,
  ClubPurchasePayment,
  ClubPurchasePaymentBookingDepositDetails,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { DiverPurchasePaymentEditorBookingDepositInitialModel } from './DiverPurchasePaymentEditorBookingDepositInitialModel.type';
export function useInitialPurchasePaymentBookingDeposits(
  mode: string,
  purchasePayment: ClubPurchasePayment,
  paymentsBookingDepositsDetails: ClubPurchasePaymentBookingDepositDetails[],
  divers: ClubDiver[],
  bookingDeposits: BookingDeposit[],
) {
  const initialPurchasePaymentBookingDeposits: DiverPurchasePaymentEditorBookingDepositInitialModel[] =
    useMemo(() => {
      if (mode === 'edit') {
        return (paymentsBookingDepositsDetails ?? [])
          .filter((x) => x.purchasePaymentId === purchasePayment?._id)
          .map((purchasePaymentBookingDepositDetails) => {
            const bookingDeposit = bookingDeposits.find(
              (upp) =>
                upp._id ===
                purchasePaymentBookingDepositDetails.bookingDepositId,
            );
            if (bookingDeposit) {
              const diver = divers.find(
                (d) => d._id === bookingDeposit.diverId,
              );
              const uppp: DiverPurchasePaymentEditorBookingDepositInitialModel =
                {
                  initialAmountForBookingDeposit:
                    purchasePaymentBookingDepositDetails.amount,
                  updatedAmountForBookingDeposit:
                    purchasePaymentBookingDepositDetails.amount,
                  initialBookingDeposit: bookingDeposit,
                  updatedBookingDeposit: {
                    ...bookingDeposit,
                  },
                  diver,
                };
              return uppp;
            }
          })
          .filter((x) => !!x);
      } else {
        // create
        return bookingDeposits
          .filter((pp) => pp.purchasePaymentStatus !== 'done' && pp.price > 0)
          .map((bookingDeposit) => {
            const diver = divers.find((d) => d._id === bookingDeposit.diverId);
            const { price, payedAmount } = bookingDeposit;

            const remainingAmount = Math.max(0, price - payedAmount);

            const uppp: DiverPurchasePaymentEditorBookingDepositInitialModel = {
              diver,
              initialAmountForBookingDeposit: 0,
              updatedAmountForBookingDeposit: remainingAmount,
              initialBookingDeposit: bookingDeposit,
              updatedBookingDeposit: {
                ...bookingDeposit,
                payedAmount: payedAmount + remainingAmount,
                purchasePaymentStatus:
                  remainingAmount > 0
                    ? 'done'
                    : bookingDeposit.purchasePaymentStatus,
              },
            };
            return uppp;
          });
      }
    }, [
      mode,
      paymentsBookingDepositsDetails,
      purchasePayment?._id,
      bookingDeposits,
      divers,
    ]);

  const purchasePaymentBookingDeposits = useMemo(
    () =>
      dataSorter.sortMultiple(initialPurchasePaymentBookingDeposits, {
        getSortAttributes: (x) => {
          return [
            {
              value: x.diver.birthdate,
              type: 'default',
            },
            {
              value: x.diver.lastName,
              type: 'full-text',
            },
            {
              value: x.diver.firstName,
              type: 'full-text',
            },
            {
              value: x.diver._id,
              type: 'default',
            },
            {
              value: x.updatedBookingDeposit.depositDate,
              type: 'default',
            },
            {
              value: x.updatedBookingDeposit._id,
              type: 'default',
            },
          ];
        },
        asc: true,
      }),
    [initialPurchasePaymentBookingDeposits],
  );
  return purchasePaymentBookingDeposits;
}
