import {
  DiveSessionResumeFull,
  SessionPickup,
} from '@mabadive/app-common-model';
import { diveCenterSessionPickupsBuilder } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
export function useSessionsPickups({
  sessions,
}: {
  sessions: DiveSessionResumeFull[];
}): SessionPickup[] {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const accommodations = diveCenterResume.accommodations;
  return useMemo(
    () =>
      diveCenterSessionPickupsBuilder.buildSessionPickups({
        sessions,
        accommodations,
        diveCenterId,
      }),
    [accommodations, diveCenterId, sessions],
  );
}
