import { AccountProfile, AuthUser } from '@mabadive/app-common-model';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { accountProfileRepository, authUserRepository } from 'src/business/account/data/repository';
import { rxjsFormBuilder } from 'src/lib/form/components/rxjs-form';
import { AccountProfileEditPageFormComponentsModel } from './AccountProfileEditPageFormComponentsModel.type';
import { AccountProfileEditPageFormModel } from './AccountProfileEditPageFormModel.type';

export const accountProfileEditPageRepository = {
  loadInitialData,
}

function loadInitialData() {

  const { actions, components$, form$ } = buildForm();

  const accountProfile$: Observable<AccountProfile> = accountProfileRepository.get();
  const authUser$: Observable<AuthUser> = authUserRepository.get();

  const initialFormValue$: Observable<AccountProfileEditPageFormModel> = accountProfile$;

  return combineLatest([
    authUser$,
    accountProfile$,
    form$,
    components$,
    initialFormValue$.pipe(
      tap(value => {
        actions.updateInitialValue(value, 'external');
      }),
    )
  ], (authUser, accountProfile, form, components) => ({ authUser, accountProfile, form, components }));

}

function buildForm() {

  return rxjsFormBuilder.buildForm<AccountProfileEditPageFormModel, AccountProfileEditPageFormComponentsModel>([{
    name: 'firstName',
    required: true,
  }, {
    name: 'lastName',
    required: true,
  }, {
    name: 'emailAddress',
      required: true,
  }, {
    name: 'phoneNumber',
    required: false,
  }, {
    name: 'staffRole',
    required: false,
  }]);
}