import { AppCurrency, ClubSettingsGeneral } from '@mabadive/app-common-model';
import { useClubResume } from 'src/business/club/data/hooks/useClubResume.hook';
import { useAppCurrency } from './useAppCurrency.hook';

export function useAppCurrencyMain({
  generalSettings,
}: {
  generalSettings?: ClubSettingsGeneral;
} = {}): AppCurrency {
  const clubResume = useClubResume();
  const currencyIsoCode =
    generalSettings?.currencyIsoCode ??
    clubResume?.clubSettings.general.currencyIsoCode;
  return useAppCurrency(currencyIsoCode);
}
