import {
  BookingDeposit,
  ClubDiver,
  ClubPurchasePayment,
  ClubPurchasePaymentBookingDepositDetailsResume,
} from '@mabadive/app-common-model';
import React, { useCallback, useMemo } from 'react';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import {
  AppHeroIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserClubAuthorizations,
} from 'src/business/auth/services';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';

export function useDiverBookingPageBillingTabDepositCardMenuItems({
  diver,
  bookingDeposit,
  globalState,
  dialogs,
  actions,
  isExpanded,
  setIsExpanded,
}: {
  diver: ClubDiver;
  bookingDeposit: BookingDeposit;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const user = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();

  const { aggregatedData, dialogsState } = globalState;

  const { deleteBookingDeposit, openCreatePurchasePaymentDialog } = actions;

  const { setBookingParticipantEditorState, setPaymentEditorDialogState } =
    dialogs;

  const confirmDeleteBookingDeposit = useCallback(async () => {
    if (
      await confirmDialog.confirmPromise({
        title: 'Supprimer un acompte',
        message: 'Êtes-vous sûr de vouloir supprimer cet acompte?',
        type: 'remove',
      })
    ) {
      deleteBookingDeposit({
        deletedBookingDepositId: bookingDeposit._id,
      });
    }
  }, [bookingDeposit._id, deleteBookingDeposit]);

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    if (bookingDeposit?.purchasePaymentStatus !== 'done') {
      if (az?.edit?.participant?.paymentCreate) {
        hamburgerMenuItems.push({
          id: 'pay',
          title: 'Payer',
          description: "Payer l'acompte",
          icon: EntitiesIcons.payment,
          onClick: () => {
            openCreatePurchasePaymentDialog({
              aggregatedData,
              defaultDiverId: diver?._id,
              defaultPackagesIds: [],
              defaultBookingDepositsIds: [bookingDeposit._id],
              bookingDepositsWithPayments:
                aggregatedData.bookingDepositsWithPayments,
            });
          },
        });
      }
    }

    if (az?.edit.participant.paymentEdit) {
      hamburgerMenuItems.push({
        id: 'edit-payment',
        titleShort: 'Modifier',
        title: "Modifier l'acompte",
        description: "Modifier les détails de l'acompte",
        icon: AppHeroIcons.actionEdit,
        onClick: () => {
          dialogsState.bookingDepositEditDialog.openDialog({
            mode: 'edit',
            defaultValue: {
              bookingDeposit,
            },
            aggregatedData,
          });
          // setPaymentEditorDialogState({
          //   isOpen: true,
          //   mode: 'edit',
          //   diver,
          //   divers: aggregatedData.divers,
          //   bookingDeposit,
          //   purchasePackages: aggregatedData.purchasePackages,
          //   bookings: aggregatedData.bookings,
          // });
        },
      });
    }
    if (az?.edit.participant.paymentEdit) {
      const purchasePayments: ClubPurchasePayment[] =
        aggregatedData.bookingDepositsWithPayments
          .filter((x) => x.bookingDeposit?._id === bookingDeposit._id)
          .reduce(
            (acc, x) => acc.concat(x.paymentsDetails),
            [] as ClubPurchasePaymentBookingDepositDetailsResume[],
          )
          .map((x) => x.purchasePayment);
      let i = 0;
      for (const purchasePayment of purchasePayments) {
        hamburgerMenuItems.push({
          id: 'edit-payment',
          titleShort: 'Modifier',
          title: `Modifier le paiement${
            purchasePayments?.length > 1
              ? ` (${++i}/${purchasePayments?.length})`
              : ''
          }`,
          description: 'Modifier les détails du paiement',
          icon: AppHeroIcons.actionEdit,
          onClick: () => {
            setPaymentEditorDialogState({
              isOpen: true,
              mode: 'edit',
              aggregatedData,
              purchasePayment,
              // dans un autre contexte, on met à jour 'bookingDepositsWithPayments' avant d'ouvrir le dialog, donc on ne le prend pas toujours dans aggregatedData
              bookingDepositsWithPayments:
                aggregatedData.bookingDepositsWithPayments,
            });
          },
        });
      }
    }
    if (az?.edit.participant.paymentDelete) {
      hamburgerMenuItems.push({
        id: 'delete-payment',
        titleShort: 'Supprimer',
        title: "Supprimer l'acompte",
        description: "Supprimer définitivement l'acompte",
        icon: AppHeroIcons.actionDelete,
        severity: 'danger',
        // disableMessage:
        //     ? 'Supprimez les avoirs pour pouvoir supprimer le paiement'
        //     : undefined,
        onClick: () => {
          confirmDeleteBookingDeposit();
        },
      });
    }
    if (isExpanded) {
      hamburgerMenuItems.push({
        title: 'Masquer le détail',
        description: 'Masquer le détail de cet acompte',
        icon: AppHeroIcons.eyeOff,
        onClick: () => {
          setIsExpanded(!isExpanded);
        },
      });
    } else {
      hamburgerMenuItems.push({
        title: 'Afficher le détail',
        description: 'Afficher le détail de cet acompte',
        icon: AppHeroIcons.eye,
        onClick: () => {
          setIsExpanded(!isExpanded);
        },
      });
    }
    // if (az?.edit.participant.paymentDelete) {
    //   hamburgerMenuItems.push({
    //     id: 'delete-payment',
    //     titleShort: 'Supprimer',
    //     title: 'Supprimer le paiement',
    //     description: 'Supprimer définitivement le paiement',
    //     icon: AppHeroIcons.actionDelete,
    //     severity: 'danger',
    //     onClick: () => {
    //       confirmDeletePayment();
    //     },
    //   });
    // }
    return hamburgerMenuItems;
  }, [
    aggregatedData,
    az?.edit.participant?.paymentCreate,
    az?.edit.participant.paymentDelete,
    az?.edit.participant.paymentEdit,
    bookingDeposit,
    confirmDeleteBookingDeposit,
    dialogsState.bookingDepositEditDialog,
    diver,
    isExpanded,
    openCreatePurchasePaymentDialog,
    setIsExpanded,
    setPaymentEditorDialogState,
  ]);

  return hamburgerMenuItems;
}
