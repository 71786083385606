import {
  ClubSettingsPlanningPeriodConfig,
  ClubSettingsPlanningPeriodConfigAssistant,
  DiveCenterResume,
  WEEK_DAYS_ISO,
} from '@mabadive/app-common-model';
import { ClubSettingsPlanningAssistantFormModel } from '../model';

export const clubSettingsPlanningAssistantFormInitialValueBuilder = {
  buildInitialFormValue,
};

function buildInitialFormValue({
  diveCenterResume,
  mode,
  originalPeriodConfigId,
  initialPeriodConfig,
}: {
  diveCenterResume: DiveCenterResume;
  initialPeriodConfig: ClubSettingsPlanningPeriodConfig;
} & Pick<
  ClubSettingsPlanningPeriodConfigAssistant,
  'mode' | 'originalPeriodConfigId'
>) {
  const model: ClubSettingsPlanningAssistantFormModel = {
    mode: 'replace-all',
    assistant: {
      mode,
      originalPeriodConfigId,
      diveToursDef: diveCenterResume.boats
        .filter((x) => x.enabled)
        .map((boat) => ({
          attributes: {
            boatId: boat._id,
          },
          dates: {
            isoWeekDays: WEEK_DAYS_ISO,
            dailyTours: [
              {
                session1: {
                  dayTime: null,
                },
              },
              {
                session1: {
                  dayTime: null,
                },
              },
            ],
          },
        })),
    },
  };
  return model;
}
