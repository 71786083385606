/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useMemo, useState } from 'react';
import { ClubPlanningDailyStaffDialogState } from './_model';

import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import {
  PLANNING_CONFIG_CONTEXT_ID_PLANNING_STAFF_VIEW,
  usePlanningConfig,
} from 'src/business/club/modules/club-planning-light/hooks';
import {
  PlanningStaffLocalState,
  usePlanningStaffLocalState,
} from 'src/business/club/modules/club-planning/ClubPlanningStaffPage/usePlanningStaffLocalState.hook';
import { PlanningViewConfig } from 'src/business/club/modules/club-planning/_model';
import { ClubPlanningDailyStaffDialogPanel } from './ClubPlanningDailyStaffDialogPanel';

export const ClubPlanningDailyStaffDialog: FC<ClubPlanningDailyStaffDialogState> =
  (inputState) => {
    const { isOpen } = inputState;

    const [baseStaffPlanningConfig] = usePlanningConfig({
      enableRouteParams: false,
      context: PLANNING_CONFIG_CONTEXT_ID_PLANNING_STAFF_VIEW, // on utilise la configuration de base du planning staff
      defaultFocusDate: undefined,
    });

    const initialPlanningConfig: PlanningViewConfig = useMemo(() => {
      const config: PlanningViewConfig = {
        ...baseStaffPlanningConfig,
        focusDate: inputState?.initialState?.day?.date,
        focusDateRef: inputState?.initialState?.day?.dayReference,
        focusSessionReference: undefined,
        viewPeriod: 'day',
        viewDayOptions: {
          displayMode: 'staff-presence',
        },
        displayModeStaffPresenceOptions: {
          displayRoles: false,
          staffMemberId: undefined,
        },
        showFullSessions: false,
      };
      return config;
    }, [
      baseStaffPlanningConfig,
      inputState?.initialState?.day?.date,
      inputState?.initialState?.day?.dayReference,
    ]);

    const [planningConfig, setPlanningConfig] = useState<PlanningViewConfig>(
      initialPlanningConfig,
    );

    const parentState: PlanningStaffLocalState = usePlanningStaffLocalState({
      planningConfig,
      setPlanningConfig,
    });

    return isOpen ? (
      parentState?.loadableContent?.contentState === 'full' ? (
        <ClubPlanningDailyStaffDialogPanel
          inputState={inputState}
          parentState={parentState}
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
        />
      ) : (
        <LoadingProgressBar>Chargement en cours...</LoadingProgressBar>
      )
    ) : null;
  };
