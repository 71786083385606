/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubProductPackageOfferView } from '@mabadive/app-common-model';
import {
  clubResidentTypeFormatter,
  diveModeAnalyser,
  diveModeFormatter,
  diveSessionThemeFormatter,
  diveSessionTypeFormatter,
  offerEquipmentFormatter,
  participantSpecialDiveTypeFormatter,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import {
  AppHamburgerMenu,
  AppHamburgerMenuItem,
  AppPriceLabel,
} from 'src/business/club/modules/_common/ui';
import { ClubOfferNameDetailsLabel } from 'src/business/club/modules/club-diver-participant/components';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { ClubOfferDiveListPageState } from '../ClubOfferDiveListPageState.type';

export const ClubOfferDiveTableRow = ({
  offer,
  setState,
  onDelete,
  className,
}: {
  offer: ClubProductPackageOfferView;
  setState: (state: ClubOfferDiveListPageState) => void;
  onDelete: () => void;
  className?: string;
}) => {
  const mainCurrency = useAppCurrencyMain();

  const clubResume = useClubResume();
  const clubSettings = useClubSettings();
  const publicSettings = clubResume.clubSettings.publicSettings;

  const confirmDelete = useCallback(() => {
    const productPackageName = productPackageFormatter.formatNameString(
      offer.productPackage,
      {
        publicSettings,
        diveModesConf: clubSettings?.ui?.diveMode,
      },
    );

    confirmDialog
      .confirm({
        title: `Supprimer la prestation "${productPackageName}"`,
        message: 'Êtes-vous sûr de vouloir supprimer cette prestation?',
        type: 'remove',
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          onDelete();
        }
      });
  }, [
    clubSettings?.ui?.diveMode,
    offer.productPackage,
    onDelete,
    publicSettings,
  ]);

  const isAdvancedEditEnabled = useAppSecurityUserHasRole('super-admin');

  const hamburgerMenuItems = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];
    if (isAdvancedEditEnabled) {
      hamburgerMenuItems.push({
        title: 'Modifier',
        description: 'Modifier cette prestation',
        icon: AppHeroIcons.actionEdit,
        onClick: () => {
          setState({
            mode: 'edit-single',
            selectedOffer: offer,
          });
        },
      });
      hamburgerMenuItems.push({
        title: 'Dupliquer',
        description: 'Dupliquer cette prestation',
        icon: AppHeroIcons.actionClone,
        onClick: () => {
          setState({
            mode: 'duplicate-single',
            selectedOffer: offer,
          });
        },
      });
      hamburgerMenuItems.push({
        title: 'Supprimer',
        description: 'Supprimer cette prestation',
        severity: 'danger',
        icon: AppHeroIcons.actionDelete,
        onClick: () => {
          confirmDelete();
        },
      });
    }
    return hamburgerMenuItems;
  }, [confirmDelete, isAdvancedEditEnabled, offer, setState]);

  const productAttributes = offer.productPackage.productAttributes;
  const diveAttributes = offer.productPackage.diveAttributes;
  const salesCriteria = offer.productPackage.salesCriteria;

  const onClickRow = useCallback(() => {
    if (isAdvancedEditEnabled) {
      setState({
        mode: 'edit-single',
        selectedOffer: offer,
      });
    }
  }, [isAdvancedEditEnabled, offer, setState]);

  return (
    <tbody>
      <tr className={className}>
        <td
          onClick={() => onClickRow()}
          className={`relative px-2 py-0.5 ${
            isAdvancedEditEnabled ? 'cursor-pointer' : null
          } whitespace-nowrap`}
        >
          <ClubOfferNameDetailsLabel
            className="font-bold text-app-blue"
            productPackage={offer.productPackage}
          />

          {(productAttributes.minAge > 0 || productAttributes.maxAge > 0) && (
            <div className="text-xs text-app-blue">
              {productPackageFormatter.formatAgeDescription(productAttributes)}
            </div>
          )}
          {salesCriteria?.dontSelectByDefault && (
            <AppHeroIcons.forbidden className="h-4 w-4 text-gray-400 absolute top-0 right-0" />
          )}
          {diveAttributes?.extraDiveModes?.length > 0 && (
            <div className="text-xs">
              +{' '}
              {diveAttributes?.extraDiveModes
                .map((diveMode) =>
                  diveModeFormatter.formatDiveMode(diveMode, {
                    format: 'short-label',
                    diveModesConf: clubSettings?.ui?.diveMode,
                  }),
                )
                .join(', ')}
            </div>
          )}
          {productAttributes.sharedOfferMaxPersonsCount > 1 && (
            <div className="text-xs text-green-700">
              Partagé entre {productAttributes.sharedOfferMaxPersonsCount}{' '}
              personnes max
            </div>
          )}

          {diveAttributes?.specialDiveType && (
            <div className="text-xs text-gray-600">
              {participantSpecialDiveTypeFormatter.formatSpecialDiveType(
                diveAttributes?.specialDiveType,
                {
                  format: 'short',
                },
              )}
            </div>
          )}
          {offer.productPackage.comment?.trim().length > 0 && (
            <div className="text-xs text-gray-600">
              {offer.productPackage.comment}
            </div>
          )}
        </td>
        <td
          onClick={() => onClickRow()}
          className={`px-2 py-0.5 text-right ${
            isAdvancedEditEnabled ? 'cursor-pointer' : null
          } whitespace-nowrap`}
        >
          {offer.price > 0 && (
            <AppPriceLabel amount={offer.price} mainCurrency={mainCurrency} />
          )}
          {offer.thirdPartyCollectPrice > 0 && (
            <span className="text-xs">
              {' '}
              (dont{' '}
              <AppPriceLabel
                amount={offer.thirdPartyCollectPrice}
                mainCurrency={mainCurrency}
              />{' '}
              tiers)
            </span>
          )}
          {salesCriteria?.residentType && (
            <div
              className={clsx(
                'text-xs ',
                salesCriteria?.residentType === 'holidays'
                  ? 'text-green-600'
                  : 'text-blue-600',
              )}
            >
              {clubResidentTypeFormatter.format(salesCriteria?.residentType)}
            </div>
          )}
        </td>
        <td
          onClick={() => onClickRow()}
          className={`px-2 py-0.5 ${
            isAdvancedEditEnabled ? 'cursor-pointer' : null
          } whitespace-nowrap`}
        >
          {diveAttributes?.diveType && (
            <div className="text-sm font-bold">
              {productPackageFormatter.formatDescription(offer.productPackage, {
                diveModesConf: clubSettings?.ui?.diveMode,
              })}
            </div>
          )}
          {(diveAttributes?.minDepth > 0 || diveAttributes?.maxDepth > 0) && (
            <div className="text-xs">
              {productPackageFormatter.formatDepthDescription(diveAttributes)}
            </div>
          )}
          {(diveAttributes?.minDistance > 0 ||
            diveAttributes?.maxDistance > 0) && (
            <div className="text-xs">
              {productPackageFormatter.formatDistanceDescription(
                diveAttributes,
              )}
            </div>
          )}
          {(diveAttributes?.diveSingleAttributes?.minDivesCount > 0 ||
            diveAttributes?.diveSingleAttributes?.maxDivesCount > 0) && (
            <div className="text-xs">
              {productPackageFormatter.formatMinMaxDivesCountDescription(
                diveAttributes?.diveSingleAttributes,
              )}
            </div>
          )}
          {diveAttributes.divePriceType === 'successive' &&
            diveAttributes.successiveDivesCount > 1 && (
              <div className="text-xs">
                {productPackageFormatter.formatSuccessiveDivesDescription(
                  diveAttributes,
                )}
              </div>
            )}
          {diveModeAnalyser.hasExploPricingEquipmentOption(
            diveAttributes.diveMode,
          ) &&
            (diveAttributes.equipment === 'equipped' ||
              diveAttributes.equipment === 'not-equipped') && (
              <div className="text-xs">
                {offerEquipmentFormatter.formatOfferEquipment(
                  diveAttributes.equipment,
                  { format: 'full' },
                )}
              </div>
            )}
          {salesCriteria?.diveSessionType && (
            <div className={clsx('text-xs text-gray-600')}>
              {diveSessionTypeFormatter.formatType(
                salesCriteria?.diveSessionType,
                { diveModesConf: clubSettings?.ui?.diveMode },
              )}
            </div>
          )}
          {salesCriteria?.diveSessionTheme && (
            <div className={clsx('text-xs text-gray-600')}>
              {diveSessionThemeFormatter.formatTheme(
                salesCriteria?.diveSessionTheme,
              )}
            </div>
          )}
        </td>
        <td className="py-1 whitespace-nowrap">
          <div>
            <AppHamburgerMenu
              buttonClassName="bg-gray-600 hover:bg-gray-800 text-gray-200 hover:text-white"
              position="left-start"
              items={hamburgerMenuItems}
              buttonIcon={AppHeroIcons.actionMenu}
            />
          </div>
        </td>
      </tr>
    </tbody>
  );
};
