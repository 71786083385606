/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingAgency } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useBookingAgenciesOptions(): ValueLabel<string, string>[] {
  const clubResume = useClubResume();
  const bookingAgencies: BookingAgency[] = clubResume.bookingAgencies;
  return useMemo(() => {
    const options: ValueLabel<string, string>[] = bookingAgencies.map(
      (value) => ({
        value: value._id,
        label: value.name,
      }),
    );

    return options;
  }, [bookingAgencies]);
}
