/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';

import {
  ClubSettingsGeneralDialogInitialState,
  ClubSettingsGeneralFormModel,
} from '../../../_model';
import { ClubSettingsGeneralPurchaseEditFormBilling } from './ClubSettingsGeneralPurchaseEditFormBilling';
import { ClubSettingsGeneralPurchaseEditFormOptions } from './ClubSettingsGeneralPurchaseEditFormOptions';
import { ClubSettingsGeneralPurchaseEditFormTaxes } from './ClubSettingsGeneralPurchaseEditFormTaxes';

export const ClubSettingsGeneralPurchaseEditForm = ({
  form,
  initialState,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
  initialState: ClubSettingsGeneralDialogInitialState;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const [customerSpaceEnabled, billingEnabled, paymentsEnabled, taxesEnabled] =
    useWatch({
      control,
      name: [
        'clubSettings.publicSettings.customerSpace.enabled',
        'clubSettings.general.billing.enabled',
        'clubSettings.general.payments.enabled',
        'clubSettings.general.taxes.enabled',
      ],
    });

  return (
    <div className="grid gap-4 md:gap-6">
      {(billingEnabled || isSuperAdmin) && (
        <ClubSettingsGeneralPurchaseEditFormBilling form={form} />
      )}
      {billingEnabled && (
        <ClubSettingsGeneralPurchaseEditFormOptions form={form} />
      )}
      {billingEnabled && paymentsEnabled && taxesEnabled && (
        <ClubSettingsGeneralPurchaseEditFormTaxes form={form} />
      )}
    </div>
  );
};
