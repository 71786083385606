import { DiveSessionResumeFull } from '@mabadive/app-common-model';
import { diveSessionsSorter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { map } from 'rxjs/operators';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

import {
  LoadableContent,
  LoadableContentActions,
  LoadableContentLoadingMode,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import { diveSessionResumeFullRepository } from 'src/business/_core/data/store-repository/dive-sessions';
import {
  DiveSessionResumeLoadBounds,
  DiveSessionResumeLoadCriteria,
} from 'src/business/_core/data/store-repository/dive-sessions/model';

export function useFetchCalendarWeekDiveSessionResumes({
  criteria,
  mode,
}: {
  criteria: DiveSessionResumeLoadBounds;
  mode: LoadableContentLoadingMode;
}): LoadableContent<DiveSessionResumeFull[]> & LoadableContentActions {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const loadCriteria: DiveSessionResumeLoadCriteria = useMemo(() => {
    if (clubReference && diveCenterId && criteria) {
      return {
        ...criteria,
        clubReference,
        diveCenterId,
      };
    }
  }, [clubReference, criteria, diveCenterId]);

  return useLoadableContent(
    () =>
      diveSessionResumeFullRepository
        .findManyWithSubscription(loadCriteria, {
          mode,
        })
        .pipe(
          map(({ content: sessions, ...x }) => {
            const sortedSessions = diveSessionsSorter.sortDiveSessionsBy(
              sessions,
              {
                attributeName: 'time',
                asc: true,
              },
            );

            return {
              ...x,
              content: sortedSessions,
            };
          }),
        ),
    [loadCriteria, mode],
    {
      debugName: 'useFetchCalendarWeekDiveSessionResumes',
      useSnapshot: false,
    },
  );
}
