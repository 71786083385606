/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingMember,
  BookingParticipantEditorFormModel,
  ClubDiver,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import {
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResult,
} from '../../../../../../models';

import { bookingMemberFormParser } from './bookingMemberFormParser.service';

export const bookingMemberChangesBuilder = {
  buildBookingMemberChanges,
};

function buildBookingMemberChanges({
  initialFormValue,
  formValue,
  participantData,
  finalDiver,
  result,
}: {
  initialFormValue: BookingParticipantEditorFormModel;
  formValue: BookingParticipantEditorFormModel;
  participantData?: Pick<
    BookingParticipantEditorParticipant,
    'bookingMember' | 'diver'
  >;
  finalDiver: ClubDiver;
  result: BookingParticipantEditorResult;
}): {
  finalBookingMember: BookingMember;
} {
  const diverId = participantData?.diver?._id;

  const initialDiver: ClubDiver = participantData?.diver;

  const bookingMember: BookingMember = participantData?.bookingMember;
  const initialBookingMemberFromForm: Partial<BookingMember> =
    bookingMemberFormParser.parseFormValueBookingMember({
      formValue: initialFormValue,
      initialDiver: initialDiver,
      finalDiver: initialDiver,
    });
  const finalBookingMemberFromForm: Partial<BookingMember> =
    bookingMemberFormParser.parseFormValueBookingMember({
      formValue,
      initialDiver,
      finalDiver,
    });

  result.aggregated.finalBookingMember = {
    ...bookingMember,
    ...finalBookingMemberFromForm,
  };

  const patchOperations = jsonPatcher.compareObjects(
    initialBookingMemberFromForm,
    finalBookingMemberFromForm,
    {
      // else, value won't be deleted by typeorm
      // https://github.com/typeorm/typeorm/issues/2934
      replaceDeleteByNullValue: true,
    },
  );

  if (patchOperations.length) {
    result.changes.updatedBookingMembers.push({
      pk: bookingMember._id,
      patchOperations,
    });
  }

  return {
    finalBookingMember: result.aggregated.finalBookingMember,
  };
}
