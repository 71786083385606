import { useCallback } from 'react';
import { BookingTabModel, DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
import { diverBookingPageUpdateStateManager } from '../../02.update-state';

export function useDiverBookingPageDeleteBooking({
  clubReference,
  updateState,
  setUpdateState,
}: {
  clubReference: string;
  updateState: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
}) {
  return useCallback(
    ({ booking }: { booking: BookingTabModel }) => {
      let updateStateLocal = updateState;

      // delete booking
      updateStateLocal =
        diverBookingPageUpdateStateManager.deleteBookingFromState({
          updateState: updateStateLocal,
          bookingTabModel: booking,
        });

      setUpdateState(updateStateLocal, {
        action: 'DiverBookingPageDeleteBooking',
      });
    },
    [setUpdateState, updateState],
  );
}
