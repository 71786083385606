/* eslint-disable jsx-a11y/alt-text */
import {
  AppBookletPageGql_Company,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useAppTheme } from '../../../../../../../business/club/data/hooks';

export const EcommerceProductPreviewCoverHorizontal = ({
  product,
  appBookletPage,
  children,
  className,
}: {
  product: Pick<EcommerceProductGql_Company, 'name'>;
  appBookletPage: Partial<AppBookletPageGql_Company>;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}) => {
  const theme = useAppTheme();

  const cover = appBookletPage?.mainContent?.cover;

  return (
    product && (
      <div className={clsx('flex flex-col overflow-y-auto', className)}>
        {cover.image && (
          <img
            className="relative object-center w-full rounded-t-xl"
            src={cover.image.publicUrl}
          />
        )}
        <div className="h-full py-2 px-4 flex-grow flex flex-col gap-8 justify-between">
          <div>
            <div
              className={clsx(
                'text-base font-bold w-full text-left',
                // displayWidth === 'mobile'
                //   ? 'text-md'
                //   : 'text-md lg:text-lg',
              )}
              style={{ color: theme.title1.textColor }}
            >
              {cover?.title?.toUpperCase()}
            </div>
            {cover?.subTitle && (
              <div
                className={clsx('text-sm font-medium w-full text-left')}
                style={{ color: theme.title2.textColor }}
              >
                {cover?.subTitle}
              </div>
            )}
          </div>
          {children && children}
        </div>
      </div>
    )
  );
};
