/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { AppPageBlock } from 'src/business/_core/modules/layout';
import { StaffMemberFormLocalState } from 'src/pages/SE-settings/SE-01_dive-center/staff-members/StaffMemberEditPage/model';
import { DiveSessionEditorDialogLocalState } from '../../useDiveSessionEditorDialogLocalState.hook';
import { StaffMemberEditFormDeprecated } from '../../../../../../../../../../pages/SE-settings/SE-01_dive-center/staff-members/StaffMemberEditPage/StaffMemberEditForm/StaffMemberEditFormDeprecated';

export const DiveSessionDialogTab2StaffMemberEdit: FC<{
  localState: DiveSessionEditorDialogLocalState;
  staffMemberFormLocalState: StaffMemberFormLocalState;
}> = ({ localState, staffMemberFormLocalState }) => {
  return (
    <AppPageBlock>
      <StaffMemberEditFormDeprecated localState={staffMemberFormLocalState} />
    </AppPageBlock>
  );
};
