/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppCompanyMessageContext } from '@mabadive/app-common-model';
import { clubDefaultMessageTemplatesBuilder } from '@mabadive/app-common-services';
import React, { useMemo, useState } from 'react';
import {
  AppButton,
  AppTabTailwind,
  AppTabsTailwind,
} from 'src/business/_core/modules/layout';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import {
  useClubResume,
  useClubSettingsMessageChannels,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { AppMessagePreview } from 'src/pages/_components';
import { useAppMessageParams } from 'src/pages/_components/_core/message';
import { ClubSettingsCommunicationPageLocalState } from '../../../useClubSettingsCommunicationPageLocalState.hook';
import { ClubSettingsCommunicationPageBasePanel } from '../../_common';
import { ClubSettingsCommunicationMessageTemplatesViewPanelTemplatesSection } from './ClubSettingsCommunicationMessageTemplatesViewPanelTemplatesSection';

export const ClubSettingsCommunicationMessageTemplatesViewPanel = ({
  localState,
}: {
  localState: ClubSettingsCommunicationPageLocalState;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;

  const paramsToReplace = useAppMessageParams({
    context: 'none',
    recipientType: 'personal',
  });
  const { state, dialogs } = localState;

  // count templates by context
  const templatesCountByContext: Record<AppCompanyMessageContext, number> =
    clubResume.messageTemplates.reduce(
      (acc, mt) => {
        if (acc[mt.context]) {
          acc[mt.context] += 1;
        } else {
          acc[mt.context] = 1;
        }
        return acc;
      },
      {
        booking: 0,
        session: 0,
      } as Record<AppCompanyMessageContext, number>,
    );
  // clubResume.messageTemplates.filter((x) => x.context === context),

  const tabs = useMemo(() => {
    const tabsIds: AppCompanyMessageContext[] = [];

    if (clubSettings.communication?.booking?.enableMessage) {
      tabsIds.push('booking');
    }
    if (clubSettings.communication?.session?.enableMessage) {
      tabsIds.push('session');
    }

    const tabs: AppTabTailwind<AppCompanyMessageContext>[] = tabsIds.map(
      (tabId) => ({
        id: tabId,
        label:
          tabId === 'booking'
            ? `Réservations (${templatesCountByContext[tabId]})`
            : tabId === 'session'
            ? `Sessions (${templatesCountByContext[tabId]})`
            : '?',
      }),
    );
    return tabs;
  }, [
    clubSettings.communication?.booking?.enableMessage,
    clubSettings.communication?.session?.enableMessage,
    templatesCountByContext,
  ]);

  const [currentTabId, setCurrentTabId] = useState<AppCompanyMessageContext>(
    tabs?.[0]?.id,
  );

  const msg = useClubSettingsMessageChannels();

  return (
    <ClubSettingsCommunicationPageBasePanel localState={localState}>
      <div className="grid gap-4 md:gap-6">
        <ClubSettingsSection
          title="Modèles de messages"
          header={
            <div className="my-4 flex flex-col gap-2 sm:flex-row justify-between items-start">
              <AppTabsTailwind
                className="w-full"
                tabs={tabs}
                selected={currentTabId}
                theme="pills"
                breakpoint="sm"
                onChange={(tabId) => setCurrentTabId(tabId)}
              />
              {state.editEnabled && (
                <AppButton
                  color={'primary-bg'}
                  icon={AppIcons.action?.add}
                  onClick={() => {
                    const newMessageTemplate =
                      clubDefaultMessageTemplatesBuilder.buildNewMessageTemplate(
                        {
                          clubReference: clubResume.reference,
                          diveCenterId: diveCenterResume._id,
                          context: currentTabId,
                        },
                      );
                    dialogs.messageTemplate.openCreate(newMessageTemplate);
                  }}
                >
                  <div className="whitespace-nowrap">NOUVEAU MESSAGE</div>
                </AppButton>
              )}
            </div>
          }
        >
          <ClubSettingsCommunicationMessageTemplatesViewPanelTemplatesSection
            localState={localState}
            context={currentTabId}
          />
        </ClubSettingsSection>
        <ClubSettingsSection
          title="Signature des messages"
          comment="Ajouter automatiquement la signature de votre choix à la fin des messages"
          onClick={
            state.editEnabled
              ? () => dialogs.communication.open('templates')
              : undefined
          }
          actions={
            <>
              {state.editEnabled && (
                <div className="">
                  <AppButton
                    color={'primary-outline-light'}
                    size="normal"
                    icon={AppIcons.action?.edit}
                    onClick={() => dialogs.communication.open('templates')}
                  >
                    Modifier
                  </AppButton>
                </div>
              )}
            </>
          }
        >
          <div className="flex flex-wrap justify-between gap-x-4 gap-y-2">
            <div className="flex-grow grid lg:grid-cols-2 xl:grid-cols-3 gap-4 items-start">
              {msg?.email?.channelEnabled &&
                clubSettings.communication?.templates?.emailSignature?.content?.trim()
                  ?.length > 0 && (
                  <div className="grid gap-2">
                    <div className="text-gray-600 font-bold">
                      Signature E-MAIL
                    </div>
                    <AppMessagePreview
                      target="email"
                      signature={
                        clubSettings.communication?.templates?.emailSignature
                          ?.content
                      }
                      paramsToReplace={paramsToReplace}
                    />
                    <AppFeatureBadge
                      enabled={
                        clubSettings.communication?.templates?.emailSignature
                          ?.enabledIfManual
                      }
                      label="Conserver la signature pour les e-mails envoyés manuellement"
                    />
                  </div>
                )}
              {msg?.sms?.channelEnabled &&
                clubSettings.communication?.templates?.smsSignature?.content?.trim()
                  ?.length > 0 && (
                  <div className="grid gap-2">
                    <div className="text-gray-600 font-bold">Signature SMS</div>
                    <AppMessagePreview
                      target="sms"
                      signature={
                        clubSettings.communication?.templates?.smsSignature
                          ?.content
                      }
                      paramsToReplace={paramsToReplace}
                    />
                  </div>
                )}
              {msg?.whatsApp?.channelEnabled &&
                clubSettings.communication?.templates?.whatsAppSignature?.content?.trim()
                  ?.length > 0 && (
                  <div className="grid gap-2">
                    <div className="text-gray-600 font-bold">
                      Signature WHATSAPP
                    </div>
                    <AppMessagePreview
                      target="whatsapp"
                      signature={
                        clubSettings.communication?.templates?.whatsAppSignature
                          ?.content
                      }
                      paramsToReplace={paramsToReplace}
                    />
                  </div>
                )}
            </div>
          </div>
        </ClubSettingsSection>
      </div>
    </ClubSettingsCommunicationPageBasePanel>
  );
};
