import { ClubAuthUserProfile } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { AppInputBooleanSwitchRHF } from 'src/lib/form';

export function ClubSettingsUserProfileEditFormAuthCreditNote({
  form,
  className,
}: {
  form: UseFormReturn<ClubAuthUserProfile, object>;
  className?: string;
}) {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  return (
    <div className="mt-1 grid gap-4 md:grid-cols-2">
      <div className="border-2 border-status-success">
        <div className="font-bold bg-status-success/10 text-status-success border-b border-status-success uppercase text-base flex items-center gap-2 px-2">
          <AppHeroIcons.eye className={'inline w-4 h-4 font-bold'} />
          Accès
        </div>
        <div className="m-2 grid gap-4 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2">
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.creditNote.view.bookingDetails"
            label={'Avoirs sur le détails d\'une réservation'}
            theme={'success'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.creditNote.view.paymentsList"
            label={'Avoirs sur la liste des paiements'}
            theme={'success'}
          />
        </div>
      </div>
      <div className="border-2 border-status-warn">
        <div className="font-bold bg-status-warn/10 text-status-warn border-b border-status-warn uppercase text-base flex items-center gap-2 px-2">
          <AppIconsAction.edit className={'inline w-4 h-4 font-bold'} />
          Actions
        </div>
        <div className="m-2 grid gap-4 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2">
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.creditNote.action.create"
            label={'Créer un avoir'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.creditNote.action.edit"
            label={'Modifier un avoir'}
            theme={'warn'}
          />
          <AppInputBooleanSwitchRHF
            control={control}
            name="authorizations.creditNote.action.delete"
            label={'Supprimer un avoir'}
            theme={'danger'}
          />
        </div>
      </div>
    </div>
  );
}
