/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveCertificationReference,
  DiveMode,
} from '@mabadive/app-common-model';
import React from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume, useDiveModeColor } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import {
  useDiveModesOptions,
  useSupervisedCertificationOptions,
} from 'src/pages/_components/options';
import { BookingInitialPlanEditFormModel } from './model';
export const BookingInitialPlanParticipantGroupsEditFormRFHRow = ({
  form,
  fieldArray,
  field,
  fieldIndex,
}: {
  form: UseFormReturn<BookingInitialPlanEditFormModel>;
  fieldArray: UseFieldArrayReturn<
    BookingInitialPlanEditFormModel,
    'bookingInitialPlan.participantsGroups',
    'id'
  >;
  field: FieldArrayWithId<
    BookingInitialPlanEditFormModel,
    'bookingInitialPlan.participantsGroups',
    'id'
  >;
  fieldIndex: number;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const {
    fields: participantsGroupsFields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
  } = fieldArray;

  const diveModesOptions: ValueLabel<DiveMode>[] = useDiveModesOptions({
    format: 'short-ref-label',
  });
  const supervisedCertificationOptions: ValueLabel<DiveCertificationReference>[] =
    useSupervisedCertificationOptions();

  const formAttributeBaseName =
    `bookingInitialPlan.participantsGroups.${fieldIndex}` as const;

  const [diveMode, participantsCount] = useWatch({
    control,
    name: [
      `${formAttributeBaseName}.expectedDive.diveMode`,
      `${formAttributeBaseName}.participantsCount`,
    ],
  });

  const diveModeColor = useDiveModeColor(diveMode);
  return (
    <div
      className={`border-l-4 ${
        !diveModeColor && 'border-gray-600'
      } pl-2 grid sm:flex gap-4`}
      style={diveModeColor ? { borderColor: diveModeColor } : undefined}
    >
      <div className="w-full sm:max-w-[20rem] flex justify-between items-end gap-2">
        <div
          className={`sm:w-8 my-1 text-base font-bold ${
            participantsCount > 0 ? 'text-gray-600' : 'text-gray-400'
          }`}
        >
          x{participantsCount}
        </div>
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Niveau"
          control={control}
          name={`${formAttributeBaseName}.certification.certificationReference`}
          required={false}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={supervisedCertificationOptions}
            />
          )}
        />
      </div>
      <AppFormControlRHF_Deprecated
        className="w-full sm:max-w-[18rem]"
        label="Prestation souhaitée"
        control={control}
        name={`${formAttributeBaseName}.expectedDive.diveMode`}
        required={false}
        renderComponent={(props) => (
          <AppSingleAutocomplete2RHF
            {...props}
            options={diveModesOptions}
            onChange={(diveMode: DiveMode) => {
              // onDiveModeChange(diveMode);
            }}
          />
        )}
      />
      <div className="flex justify-between items-end gap-4">
        <AppFormControlRHF_Deprecated
          className={'w-full max-w-[8rem]'}
          label={'Participants'}
          control={control}
          name={`${formAttributeBaseName}.participantsCount`}
          required={true}
          renderComponent={(props) => (
            <AppInputRHF
              {...props}
              className={'w-24'}
              placeholder={'0'}
              type="number"
              showIncrementButtons={true}
            />
          )}
        />
        <AppHeroIcons.actionRemove
          onClick={() => {
            remove(fieldIndex);
          }}
          className="p-1 w-8 h-8 bg-gray-400 hover:bg-gray-600 rounded-full text-white cursor-pointer"
        />
      </div>
    </div>
  );
};
