import {
  AppIconsInquiry,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { SettingsCategory, SettingsCategoryPage } from '../_core';

export const SETTINGS_CUSTOMERS_PAGE_COMMUNICATION: SettingsCategoryPage = {
  id: 'communication',
  label: 'Communication',
  icon: AppIconsSettings.customers.communication,
  url: '/club/settings/customers/communication',
  roles: ['club-view-settings-global'],
};

export const SETTINGS_CUSTOMERS_PAGE_ONLINE_PAYMENT: SettingsCategoryPage = {
  id: 'payment-platforms',
  label: 'Paiement en ligne',
  icon: AppIconsSettings.customers.payment,
  url: '/club/settings/customers/payment-platforms',
  checkAuth: ({ az, clubSettings }) =>
    az?.edit?.settings?.onlinePayment &&
    clubSettings.general?.payments?.online?.stripe?.enabled,
};

export const SETTINGS_CUSTOMERS_PAGE_CUSTOMER_SPACE: SettingsCategoryPage = {
  id: 'customer-space',
  label: 'Espace client',
  icon: AppIconsSettings.customers.customerSpace,
  url: '/club/settings/customers/customer-space',
  checkAuth: ({ az, clubSettings }) => az?.edit?.settings?.customerSpace,
};

export const SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING: SettingsCategoryPage = {
  id: 'online-booking',
  label: 'Vente en ligne',
  icon: AppIconsSettings.customers.onlineBooking,
  url: '/club/settings/customers/online-booking',
  checkAuth: ({ az, clubSettings, auth }) =>
    clubSettings.general?.onlineBooking?.enabled &&
    az?.onlineBooking?.action?.editSettings,
};

export const SETTINGS_CUSTOMERS_PAGE_INQUIRIES: SettingsCategoryPage = {
  id: 'inquiries',
  label: 'Questionnaires',
  icon: AppIconsInquiry.all,
  url: '/club/settings/customers/inquiries',
  checkAuth: ({ az, clubSettings }) =>
    az?.edit?.settings?.inquiry && clubSettings.general?.inquiry?.enabled,
};

export const SETTINGS_CUSTOMERS: SettingsCategory = {
  id: 'customers',
  label: 'Clients',
  color: AppIconsSettings.customers.color,
  icon: AppIconsSettings.customers.main,
  pages: [
    SETTINGS_CUSTOMERS_PAGE_COMMUNICATION,
    SETTINGS_CUSTOMERS_PAGE_CUSTOMER_SPACE,
    SETTINGS_CUSTOMERS_PAGE_ONLINE_BOOKING,
    SETTINGS_CUSTOMERS_PAGE_ONLINE_PAYMENT,
    SETTINGS_CUSTOMERS_PAGE_INQUIRIES,
  ],
};
