import {
  ClubDiver,
  ClubParticipant,
  DiveSession,
} from '@mabadive/app-common-model';
import React from 'react';
import {
  ParticipantDiveType,
  ParticipantDiveTypeExtra,
  ParticipantEquipment,
  ParticipantGaz,
  ParticipantRebreather,
  ParticipantTargetDeep,
  ParticipantUnderwaterScooter,
} from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant';
import { ParticipantDiveCard } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant/ParticipantDiveCard';
import { ParticipantDiveTags } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/participant/ParticipantDiveTags';

export function ParticipantCellBadge({
  diver,
  participant,
  diveSession,
  onClick,
  theme,
  includeBookingStateTag,
  includeParticipantSessionNumber,
  className,
}: {
  diver: ClubDiver;
  participant: ClubParticipant;
  diveSession: DiveSession;
  onClick?: () => void;
  theme?: 'normal' | 'light';
  includeBookingStateTag?: boolean;
  includeParticipantSessionNumber?: boolean;
  className?: string;
}) {
  return (
    <ParticipantDiveCard
      onClick={onClick}
      className={`leading-none overflow-hidden w-full h-8 ${className}`}
      diveSession={diveSession}
      participant={participant}
      theme={theme}
      isCancelledLineThrough={true}
    >
      <div className="h-full flex flex-col justify-between">
        <div className={'flex items-center gap-x-1'}>
          <ParticipantDiveType
            className="flex-grow text-left"
            participant={participant}
            diveSession={diveSession}
          />
          <ParticipantDiveTypeExtra
            participant={participant}
            diveSession={diveSession}
          />
          <ParticipantTargetDeep
            targetDeep={participant?.targetDeep}
            mode="badge"
          />
          <ParticipantRebreather
            rebreather={participant.equipment?.rebreather}
            iconClass="w-4 h-4"
            mode="badge"
          />
          <ParticipantUnderwaterScooter
            underwaterScooter={participant.equipment?.underwaterScooter}
            iconClass="w-4 h-4"
            mode="badge"
          />
          <ParticipantGaz gaz={participant.gaz} mode="badge" />
        </div>
        <div
          className={
            'flex flex-row-reverse justify-between items-center gap-x-1'
          }
        >
          <ParticipantDiveTags
            diveSession={diveSession}
            participant={participant}
            diver={diver}
            includeBookingState={includeBookingStateTag}
            includeParticipantSessionNumber={includeParticipantSessionNumber}
          ></ParticipantDiveTags>
          <ParticipantEquipment
            className={'text-center text-app-xxs2'}
            equipment={participant?.equipment}
            mode="badge"
          />
        </div>
      </div>
    </ParticipantDiveCard>
  );
}
