import {
  ClubDiver,
  ClubProductPackageOfferView,
  ClubResume,
  DiveMode,
} from '@mabadive/app-common-model';
import { dateService } from '../../../data/date/date.service';
import { clubProductPackageMetaReader } from './clubProductPackageMetaReader.service';

export const clubProductPackageOfferMatcher = {
  getMatchingOffers,
  getMatchingOffersDiver,
  getMatchingOffersParticipant,
};

export type MatchingOffersDiverCriteria = {
  diver: Pick<ClubDiver, 'age' | 'birthdate' | 'residentType'>;
};

export type MatchingOffersParticipantCriteria = {
  diveModeGroup:
    | Extract<
        DiveMode,
        | 'training'
        | 'observer'
        | 'snorkeling'
        | 'first-dive'
        | 'free-dive'
        | 'snorkelingSupervised'
        | 'watchingTour'
        | 'autoSupervised'
      >
    | 'recreational-dive';
  successiveDivesCount?: number;
};

export type MatchingOffersCriteria = MatchingOffersDiverCriteria &
  MatchingOffersParticipantCriteria;

function getMatchingOffers({
  criteria,
  offers,
  clubResume,
}: {
  criteria: MatchingOffersCriteria;
  offers: ClubProductPackageOfferView[];
  clubResume: ClubResume;
}): ClubProductPackageOfferView[] {
  offers = getMatchingOffersParticipant({
    clubResume,
    criteria,
    offers,
  });
  offers = getMatchingOffersDiver({
    clubResume,
    criteria,
    offers,
  });
  return offers;
}

function getMatchingOffersDiver({
  criteria,
  offers,
  clubResume,
}: {
  criteria: MatchingOffersDiverCriteria;
  offers: ClubProductPackageOfferView[];
  clubResume: ClubResume;
}): ClubProductPackageOfferView[] {
  const diver = criteria.diver;

  const age = diver.birthdate ? dateService.getAge(diver.birthdate) : diver.age;

  return offers.filter((o) => {
    const productAttributes = o.productPackage?.productAttributes;
    const salesCriteria = o.productPackage?.salesCriteria;
    if (
      age > 0 &&
      productAttributes?.minAge > 0 &&
      productAttributes?.minAge > age
    ) {
      // age mini
      return false;
    }
    if (
      age > 0 &&
      productAttributes?.maxAge > 0 &&
      productAttributes?.maxAge < age
    ) {
      // age maxi
      return false;
    }
    if (
      diver.residentType &&
      salesCriteria?.residentType &&
      diver.residentType !== salesCriteria?.residentType
    ) {
      return false; // "vacancier" ou "résident"
    }
    return true;
  });
}

function getMatchingOffersParticipant({
  criteria,
  offers,
  clubResume,
}: {
  criteria: MatchingOffersParticipantCriteria;
  offers: ClubProductPackageOfferView[];
  clubResume: ClubResume;
}): ClubProductPackageOfferView[] {
  if (criteria.diveModeGroup === 'training') {
    return getMatchingOffersTraining({ criteria, offers, clubResume });
  } else if (criteria.diveModeGroup === 'recreational-dive') {
    return getMatchingOffersPlan({ criteria, offers, clubResume });
  } else {
    return getMatchingOffersOther({ criteria, offers, clubResume });
  }
}

function getMatchingOffersPlan({
  criteria,
  offers,
  clubResume,
}: {
  criteria: MatchingOffersParticipantCriteria;
  offers: ClubProductPackageOfferView[];
  clubResume: ClubResume;
}): ClubProductPackageOfferView[] {
  const explorationsGroupsCriteria =
    clubResume.clubSettings.general?.billing?.explorations?.groupsCriteria;

  return offers.filter((x) => {
    const diveAttributes = x.productPackage.diveAttributes;
    if (
      x.productPackage.type === 'dive' &&
      (diveAttributes?.diveMode === 'autonomous' ||
        diveAttributes?.diveMode === 'supervised' ||
        diveAttributes?.diveMode === 'instructor')
    ) {
      if (explorationsGroupsCriteria?.multiSessions) {
        if (criteria.successiveDivesCount > 1) {
          const isSameDivesCount =
            diveAttributes?.successiveDivesCount ===
            criteria.successiveDivesCount;
          return isSameDivesCount;
        } else {
          const isMonoDivecount = !(diveAttributes?.successiveDivesCount > 1);
          return isMonoDivecount;
        }
      }

      return true;
    }
  });
}

function getMatchingOffersTraining({
  criteria,
  offers,
  clubResume,
}: {
  criteria: MatchingOffersParticipantCriteria;
  offers: ClubProductPackageOfferView[];
  clubResume: ClubResume;
}): ClubProductPackageOfferView[] {
  return offers.filter((x) => x.productPackage.type === 'training');
}

function getMatchingOffersOther({
  criteria,
  offers,
  clubResume,
}: {
  criteria: MatchingOffersParticipantCriteria;
  offers: ClubProductPackageOfferView[];
  clubResume: ClubResume;
}): ClubProductPackageOfferView[] {
  return offers.filter((x) => {
    const meta = clubProductPackageMetaReader.readMeta(x.productPackage);
    return (
      meta.isOther &&
      x.productPackage.diveAttributes?.diveMode === criteria.diveModeGroup
    );
  });
}
