/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { useClubPlanningDayFull } from '../../../club-planning-light/useClubPlanningDayFull';

import { DiveCenterDailyConfig, DiveSession } from '@mabadive/app-common-model';
import { jsonParser } from '@mabadive/app-common-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowUnloadAlert } from 'src/business/_core/modules/layout';
import { useSideMenu } from 'src/business/_core/modules/layout/components/SideMenu/useSideMenu.hook';
import { uiStore } from 'src/business/_core/store';
import { appWebLogger } from 'src/lib/browser';
import { ClubDialogsState } from 'src/pages/_dialogs';
import { ClubPlanningDayFull } from '../../../club-planning-light/model';
import { PlanningViewConfig } from '../../_model';
import { PlanningDisplayModeStaffPresenceOptions } from '../../_model/PlanningViewConfig/PlanningDisplayModeStaffPresenceOptions.type';
import { clubPlanningStaffWeekStaffPresencePageClientUpdator } from '../ClubPlanningStaffWeekStaffPresence/services';
import {
  PlanningStaffWeekPresenceCache,
  usePlanningStaffWeekPresenceCache,
} from '../cache';
import { PlanningStaffLocalState } from '../usePlanningStaffLocalState.hook';

export function useClubPlanningStaffPanelLocalState({
  parentState,
  dialogsState,
}: {
  parentState: PlanningStaffLocalState;
  dialogsState: ClubDialogsState;
}) {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const { clubReference } = diveCenterResume;

  const {
    planningConfig,
    setPlanningConfig,
    weekPlanning,
    diveSessionResumes,
    clubEvents,
    loadableContent,
    dialogsOld,
    dailyConfigs,
  } = parentState;

  const days: ClubPlanningDayFull[] = useClubPlanningDayFull({
    diveSessionResumes,
    weekPlanning,
    diveCenterResume,
    showVirtualSessions: true,
    clubEvents,
    dailyConfigs,
    planningConfig,
  });
  const dailyConfigsFetched = useMemo(
    () =>
      days.reduce((acc, d) => {
        if (d.dailyConfig) {
          acc.push(d.dailyConfig);
        }
        return acc;
      }, [] as DiveCenterDailyConfig[]),
    [days],
  );
  const diveSessionsFetched = useMemo(
    () =>
      diveSessionResumes.map(
        ({
          participants,
          groups,
          bookingSessionParticipants,
          diveSite,
          ...diveSession
        }) => diveSession as unknown as DiveSession, // @see DiveSessionResumeFull & DiveSessionResume
      ),
    [diveSessionResumes],
  );
  const cache: PlanningStaffWeekPresenceCache =
    usePlanningStaffWeekPresenceCache({
      dailyConfigsFetched,
      diveSessionsFetched,
      planningConfig,
    });

  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState(undefined);

  const filterStaffMemberId =
    planningConfig.displayModeStaffPresenceOptions?.staffMemberId;
  const showOffTimesToggleButtons =
    planningConfig.displayModeStaffPresenceOptions?.editMode === 'times-roles';

  const displayModeStaffPresenceOptions: PlanningDisplayModeStaffPresenceOptions =
    getStaffPresenceOptionsOptionsCopy(planningConfig);

  const setDisplayModeStaffPresenceOptions = useCallback(
    (
      displayModeStaffPresenceOptions: PlanningDisplayModeStaffPresenceOptions,
    ) => {
      setPlanningConfig(
        {
          ...planningConfig,
          displayModeStaffPresenceOptions,
        },
        {
          origin: 'set filter staff member id',
        },
      );
    },
    [planningConfig, setPlanningConfig],
  );

  const updateStaffMemberFilter = useCallback(
    ({ staffMemberId }: { staffMemberId?: string }) => {
      if (!staffMemberId || filterStaffMemberId === staffMemberId) {
        setDisplayModeStaffPresenceOptions({
          ...displayModeStaffPresenceOptions,
          staffMemberId: undefined,
        });
      } else {
        setDisplayModeStaffPresenceOptions({
          ...displayModeStaffPresenceOptions,
          staffMemberId,
        });
      }
    },
    [
      displayModeStaffPresenceOptions,
      filterStaffMemberId,
      setDisplayModeStaffPresenceOptions,
    ],
  );

  useEffect(() => {
    // il faut mettre à jour le cache dès qu'il change, sinon il n'est plus à jour, et ça bug (inconvénient, on pert les changements si quelqu'un d'autre à aussi fait des modifs, et on ne peut plus naviguer)
    // TODO: remonter le cache à un plus haut niveau, et en faire un truc générique (voir avec anim-live, car on y a 2 caches: react-query et appCache)
    cache.reset({
      dailyConfigs: dailyConfigsFetched,
      diveSessions: diveSessionsFetched,
      focusDateRef: planningConfig.focusDateRef,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyConfigsFetched, planningConfig.focusDateRef]);

  const persistChanges = useCallback(async () => {
    if (!cache.updateState.hasChanges || operationInProgressMessage) {
      return;
    } else {
      setOperationInProgressMessage('Enregistrement en cours...');

      try {
        await clubPlanningStaffWeekStaffPresencePageClientUpdator.persistChanges(
          cache.updateState,
          {
            logContext: 'edit planning staff presence',
            bookingIdsToClean: [],
          },
        );
        // TODO
        // const resetState = bookingUpdateStateBuilder.createEmptyUpdateState({
        //   ...updateState,
        //   hasChanges: false,
        // });
        // cache.setUpdateState(resetState);
        cache.reset({
          dailyConfigs: cache.aggregatedDataCore.dailyConfigs,
          diveSessions: cache.aggregatedDataCore.diveSessions,
          focusDateRef: planningConfig.focusDateRef,
        });
        // @see REF TRI MONITEUR
        parentState.callback.onPersistSuccess();
        // setPageMode('view');
      } catch (err) {
        appWebLogger.captureMessage(
          'Error while trying to persist booking changes',
          {
            logContext: 'DiverBookingPage',
            clubReference,
            extra: {
              err,
              updateState: cache.updateState,
            },
          },
        );
        uiStore.snackbarMessage.set({
          type: 'error',
          content:
            'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
        });
      } finally {
        setOperationInProgressMessage(undefined);
      }
    }
  }, [
    cache,
    clubReference,
    operationInProgressMessage,
    parentState.callback,
    planningConfig.focusDateRef,
  ]);

  const cancelChanges = useCallback(() => {
    cache.reset();
  }, [cache]);

  useWindowUnloadAlert(cache.updateState.hasChanges);

  const { disableMenu } = useSideMenu();

  useEffect(() => {
    disableMenu(cache.updateState.hasChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cache.updateState.hasChanges]);

  return {
    state: {
      dialogsState,
      lastPersistDate: parentState.state.lastPersistDate,
    },
    criteria: {
      filterStaffMemberId,
      showOffTimesToggleButtons,
    },
    data: {
      cache,
      clubResume,
      diveCenterResume,
      days,
      operationInProgressMessage,
    },
    actions: {
      updateStaffMemberFilter,
      cancelChanges,
      persistChanges,
    },
  };
}

export type ClubPlanningStaffPanelLocalState = ReturnType<
  typeof useClubPlanningStaffPanelLocalState
>;
function getStaffPresenceOptionsOptionsCopy(
  planningConfig: PlanningViewConfig,
) {
  let displayModeStaffPresenceOptions: PlanningDisplayModeStaffPresenceOptions =
    planningConfig.displayModeStaffPresenceOptions;
  if (!displayModeStaffPresenceOptions) {
    // default value
    displayModeStaffPresenceOptions = {
      displayRoles: false,
    };
  } else {
    // deep copy
    displayModeStaffPresenceOptions = jsonParser.parseJSONWithDates(
      JSON.stringify(displayModeStaffPresenceOptions),
    );
  }
  return displayModeStaffPresenceOptions;
}
