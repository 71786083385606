import queryString from 'query-string';
import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { clubParticipantUrlBuilder } from '../../url-builders';
import { GenericNavigationContext } from '../ClubParticipantNavigationContext/GenericNavigationContext.type';
import { DiverBookingPage } from '../pages/DiverBookingPage';

export const ClubParticipantRoot = React.memo(
  function ClubParticipantRootMemo() {
    const { match } =
      useRouter<{
        diverId: string;
      }>();

    // rebuild match URL to replace diverId value by reference
    const matchUrl = useMemo(
      () =>
        clubParticipantUrlBuilder.buildTabUrl({
          navigationContext: {
            diverId: ':diverId',
          },
          logContext: '[ClubParticipantRoot: build match url]',
        }),
      [],
    );

    const queryParams = queryString.parse(window.location.search) as any;

    const navigationContext: GenericNavigationContext = {
      origin: queryParams.origin,
      diveSessionReference: queryParams.diveSessionReference,
      purchasePaymentId: queryParams.purchasePaymentId,
      diverId: match.params.diverId,
      action: queryParams.action,
    };

    return (
      <Switch>
        <AppRoute
          path={`${matchUrl}`}
          // exact
          component={DiverBookingPage}
          navigationContext={navigationContext}
          auth={{ requiredRoles: ['club-view-participant'] }}
        />
      </Switch>
    );
  },
);
