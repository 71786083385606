/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPurchasePackageExtraCost,
  ClubPurchasePackagePaymentStatus,
  PurchaseResume,
} from '@mabadive/app-common-model';
import { DiverPurchasePriceDetails } from '../model';

export const diverPurchasePackageBuilder = {
  buildPaymentStatus,
  buildTotalPrice,
  buildExtraCostTotals,
  buildTotalExtraCostsSum,
};

function buildExtraCostTotals(
  extraCost: Pick<
    ClubPurchasePackageExtraCost,
    'unitPrice' | 'unitQuantity' | 'multiplier'
  >,
): {
  unitTotalPrice: number;
  totalPrice: number;
} {
  if (!extraCost) {
    return {
      unitTotalPrice: 0,
      totalPrice: 0,
    };
  }
  const { unitPrice, unitQuantity, multiplier } = extraCost;
  const unitTotalPrice = (unitPrice ?? 0) * (unitQuantity ?? 0);
  const totalPrice = (multiplier ?? 0) * (unitTotalPrice ?? 0);
  return {
    unitTotalPrice,
    totalPrice,
  };
}
function buildTotalExtraCostsSum(
  extraCosts: ClubPurchasePackageExtraCost[],
): number {
  return (extraCosts ?? []).reduce((acc, extraCost) => {
    return acc + buildExtraCostTotals(extraCost).totalPrice;
  }, 0);
}

function buildTotalPrice({
  isPaymentEnabled,
  isUnitOffer,
  unitQuantity,
  unitPrice,
  totalPriceThirdPartyCollect,
  discountAmount,
  extraCosts = [],
}: {
  isPaymentEnabled: boolean;
  isUnitOffer: boolean;
  unitQuantity?: number;
  unitPrice?: number;
  totalPriceThirdPartyCollect?: number;
  discountAmount?: number;
  extraCosts?: ClubPurchasePackageExtraCost[];
}): DiverPurchasePriceDetails {
  unitQuantity = isUnitOffer ? unitQuantity ?? 0 : 1;
  unitPrice = unitPrice ?? 0;
  discountAmount = discountAmount ?? 0;

  if (!isPaymentEnabled) {
    return {
      unitQuantity,
      unitPrice: 0,
      discountAmount: 0,
      totalPriceBeforeDiscount: 0,
      totalPrice: 0,
    };
  }
  const totalPriceExtraCosts = buildTotalExtraCostsSum(extraCosts);
  const totalPriceBeforeDiscount: number =
    unitQuantity * unitPrice + totalPriceExtraCosts;

  const totalPrice = !!discountAmount
    ? totalPriceBeforeDiscount - discountAmount
    : totalPriceBeforeDiscount;

  return {
    unitQuantity,
    unitPrice,
    discountAmount,
    totalPriceBeforeDiscount,
    totalPrice,
    totalPriceExtraCosts,
    totalPriceThirdPartyCollect,
  };
}

function buildPaymentStatus({
  price,
  payedAmount,
}: Pick<
  PurchaseResume,
  'price' | 'payedAmount'
>): ClubPurchasePackagePaymentStatus {
  if (!price) {
    return payedAmount > 0 ? 'done' : 'todo';
  } else if (payedAmount >= price) {
    return 'done';
  } else if (payedAmount > 0) {
    return 'partial';
  } else {
    return 'todo';
  }
}
