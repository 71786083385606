import {
  DIVE_TRAININGS_REFERENCE_MAP,
  DashboardPurchasesTrainingsOverPeriod,
  DiveTraining,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';

import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';

export const DashboardReportPageGlobalActivityPurchasesTrainingsOverPeriodTableRow =
  ({
    training,
    comparisonDetails,
    className,
  }: {
    training: DashboardPurchasesTrainingsOverPeriod;
    comparisonDetails: DashboardReportComparisonDetails<any>;
    className?: string;
  }) => {
    const mainCurrency = useAppCurrencyMain();

    const { localState, showPeriodRangeComparison } = comparisonDetails;

    const selectedTraining = localState.state.uiConfig.selection?.training;
    const hoverTraining = localState.state.uiConfig.hover?.training;

    const isSelected =
      selectedTraining?.diveTrainingReference ===
        training.diveTrainingReference &&
      selectedTraining?.offerName === training.offerName;

    const isHover = // on considère comme "hover" si la référence de la sélection est identique
      selectedTraining?.diveTrainingReference ===
        training.diveTrainingReference ||
      (hoverTraining?.diveTrainingReference ===
        training.diveTrainingReference &&
        hoverTraining?.offerName === training.offerName);

    const diveTraining: DiveTraining =
      DIVE_TRAININGS_REFERENCE_MAP[training.diveTrainingReference];

    return (
      <tr
        className={clsx(
          isSelected ? 'app-tr-selected' : isHover && 'app-tr-highlighted',
          className,
        )}
        onMouseEnter={() =>
          localState.state.setHover({
            training: {
              diveTrainingReference: training.diveTrainingReference,
              offerName: training.offerName,
            },
          })
        }
        onMouseLeave={() => localState.state.setHover()}
        onClick={() =>
          localState.state.setSelection({
            training: {
              diveTrainingReference: training.diveTrainingReference,
              offerName: training.offerName,
            },
          })
        }
      >
        <th
          className={clsx(
            'truncate text-left whitespace-nowrap px-1 w-[50%]',
            training.purchasePackageCount === 0 ? 'text-gray-400' : '',
          )}
        >
          <div className="flex flex-wrap items-end gap-x-1 xl:gap-x-1.5 text-xs md:text-sm pt-1">
            <span className="md:min-w-[40px] font-bold text-gray-800">
              {training.diveTrainingReference}
            </span>
            <span
              className={clsx(
                'font-medium text-xs truncate',
                showPeriodRangeComparison
                  ? 'hidden xl:inline'
                  : 'hidden md:inline',
              )}
            >
              {diveTraining?.label ?? '?'}
            </span>
            {training.offerName && (
              <span
                className={clsx(
                  'font-medium uppercase',
                  showPeriodRangeComparison ? 'text-app-xxs md:text-xs' : '',
                )}
              >
                {training.offerName}
              </span>
            )}
          </div>
        </th>
        <td
          className={clsx(
            'text-app-primary text-right text-lg px-4 whitespace-nowrap font-bold',
            training.purchasePackageCount === 0 ? 'opacity-30' : '',
          )}
        >
          {training.purchasePackageCount}{' '}
          <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
        </td>
        <td
          className={clsx(
            'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
            training.purchasePackagePriceAvg === 0 ? 'opacity-30' : '',
            showPeriodRangeComparison
              ? 'hidden xl:table-cell'
              : 'hidden lg:table-cell',
          )}
        >
          <AppPriceLabel
            amount={training.purchasePackagePriceAvg}
            mainCurrency={mainCurrency}
          />
        </td>
        <td
          className={clsx(
            'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
            training.purchasePackagePriceSum === 0 ? 'opacity-30' : '',
          )}
        >
          <AppPriceLabel
            amount={training.purchasePackagePriceSum}
            mainCurrency={mainCurrency}
          />
        </td>
        <td
          className={clsx(
            'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
            training.purchasePackagePayedAmount === 0 ? 'opacity-30' : '',
            showPeriodRangeComparison
              ? 'hidden xl:table-cell'
              : 'hidden lg:table-cell',
          )}
        >
          <AppPriceLabel
            amount={training.purchasePackagePayedAmount}
            mainCurrency={mainCurrency}
          />
        </td>
      </tr>
    );
  };
