import React from 'react';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BillingTabParticipantPurchase } from '../../models';
import { DiverBookingPageBillingTabState } from '../../tabs/DiverBookingPageBillingTab/useDiverBookingPageBillingTabState.hook';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { BillingParticipantPurchaseResumeTableRowGroup } from './BillingParticipantPurchaseResumeTableRowGroup';

export function BillingParticipantPurchaseResumeTable({
  participantPurchases,
  billingTabLocalState,
  globalState,
  dialogs,
  actions,
  className,
}: {
  participantPurchases: BillingTabParticipantPurchase[];
  billingTabLocalState: DiverBookingPageBillingTabState;
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  className?: string;
}) {
  const {
    aggregatedData,
    model,
    selectedDiverId,
    setSelectedDiverId,
    diversFilterOptions,
    createPlan,
    createTraining,
    createFirstDive,
  } = billingTabLocalState;

  const { updateState } = globalState;
  return (
    <table className={`min-w-full divide-y divide-gray-200 ${className ?? ''}`}>
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/3 hidden lg:table-cell px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            <div className="flex">Plongeur</div>
          </th>
          <th
            scope="col"
            className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/3 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Prestation
          </th>
          <th
            scope="col"
            className="hidden md:table-cell px-0 py-1 text-left text-app-xxs lg:text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Plongées
          </th>
          <th
            scope="col"
            className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Statut
          </th>
          <th
            scope="col"
            className="sm:table-cell px-2 py-1 text-left text-app-xxs sm:text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Facturation
          </th>
          <th
            scope="col"
            className="px-2 py-1 text-left text-app-xxs sm:text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Paiement
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {participantPurchases.map((participantPurchase, i) => (
          <BillingParticipantPurchaseResumeTableRowGroup
            className={
              updateState.filteredDiverId &&
              !participantPurchase.divers.find(
                (x) => x._id === updateState.filteredDiverId,
              ) &&
              'hidden'
            }
            key={i}
            participantPurchase={participantPurchase}
            billingTabLocalState={billingTabLocalState}
            globalState={globalState}
            dialogs={dialogs}
            actions={actions}
          />
        ))}
      </tbody>
    </table>
  );
}
