/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubAuthUserProfile,
  DEFAULT_PROFILE_VIEW_PLANNING,
} from '@mabadive/app-common-model';
import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { of } from 'rxjs';
import { useGlobalClasses } from 'src/AppTheme';
import {
  LoadableContentPartial,
  appLoader,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppFixedButtonsBar,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import {
  AppHeroIcons,
  AppIconsMaterial,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import useRouter from 'use-react-router';
import { clubSettingsAccountUrlBuilder } from '../../clubSettingsAccountUrlBuilder.service';
import { ClubSettingsUserAccountCard } from '../ClubSettingsUserAccountsListPage/components';
import {
  ClubSettingsUserProfileOneGraphqlQueryResult,
  clubSettingsUserAccoutsPageGraphqlFetcher,
} from '../ClubSettingsUserAccountsListPage/services';
import { ClubSettingsUserProfileEditForm } from './ClubSettingsUserProfileEditForm';
import { clubSettingsUserProfilePersister } from './services';

export const ClubSettingsUserProfileEditPage = ({
  navigationContext: { mode },
}: {
  navigationContext: {
    mode: 'create' | 'edit';
  };
}) => {
  const { match, history } =
    useRouter<{
      clubProfileId: string;
    }>();

  const params = new URLSearchParams(window.location.search);
  const cloneFromClubProfileId = params.get('cloneFromClubProfileId');

  const [operationPending, setOperationPending] = useState(false);

  const clubProfileId = match.params.clubProfileId;

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const appSecurityUser = useAppSecurityUser();

  const diveCenterResume = useDiveCenterResume();
  const { content, ...loadableContent } = useLoadableContent(
    () => {
      if (mode === 'create') {
        if (cloneFromClubProfileId) {
          return appLoader.load(
            clubSettingsUserAccoutsPageGraphqlFetcher.fetchUserProfile({
              clubReference: diveCenterResume.clubReference,
              diveCenterId: diveCenterResume._id,
              clubProfileId: cloneFromClubProfileId,
            }),
            {
              type: 'full',
              defaultValue: undefined,
              isSubscription: false,
            },
          );
        } else {
          return of({
            content: {
              userAccounts: [],
              clubProfile: null,
            },
            contentState: 'full',
            lastActionStatus: 'success',
          } as LoadableContentPartial<ClubSettingsUserProfileOneGraphqlQueryResult>);
        }
      }
      return appLoader.load(
        clubSettingsUserAccoutsPageGraphqlFetcher.fetchUserProfile({
          clubReference: diveCenterResume.clubReference,
          diveCenterId: diveCenterResume._id,
          clubProfileId,
        }),
        {
          type: 'full',
          defaultValue: undefined,
          isSubscription: false,
        },
      );
    },
    [
      mode,
      diveCenterResume.clubReference,
      diveCenterResume._id,
      clubProfileId,
      cloneFromClubProfileId,
    ],
    {
      initialValue: undefined,
      defaultValue: undefined,
      useSnapshot: false,
      debugName: 'useClubSettingsUserProfileEditPageLocalState',
    },
  );
  const initialValue = content?.clubProfile;
  const userAccounts = content?.userAccounts;

  const form = useForm<ClubAuthUserProfile>({
    defaultValues:
      mode === 'create'
        ? {
            authorizations: DEFAULT_PROFILE_VIEW_PLANNING.authorizations,
          }
        : {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    reset,
    setError,
  } = form;

  useEffect(() => {
    if (initialValue) {
      if (mode === 'create') {
        reset({
          authorizations: initialValue.authorizations,
        });
      } else {
        reset(initialValue);
      }
    }
  }, [reset, initialValue, mode]);

  const back = useCallback(() => {
    return redirectTo(clubSettingsAccountUrlBuilder.userAccounts.list());
  }, [redirectTo]);

  const submitForm = useCallback(() => {
    handleSubmit(async (formValue: ClubAuthUserProfile, event) => {
      setOperationPending(true);

      try {
        if (mode === 'create') {
          await clubSettingsUserProfilePersister.createUserProfile({
            formValue,
          });
          uiStore.snackbarMessage.set({
            type: 'success',
            content: 'Profil créé.',
          });
        } else {
          await clubSettingsUserProfilePersister.updateUsertProfile({
            initialValue,
            formValue,
          });
        }
        back();
      } catch (err) {
        appLogger.error('Unexpecter error persisting profile', err);
      } finally {
        setOperationPending(false);
      }
    })();
  }, [back, handleSubmit, initialValue, mode]);

  const deleteProfile = useCallback(async () => {
    const confirmed = await confirmDialog
      .confirm({
        isTailwind: true,
        title: 'Supprimer ce profil',
        message: 'Il sera définitivement supprimé. Confirmer?',
        type: 'noYesDanger',
      })
      .toPromise();
    if (confirmed) {
      setOperationPending(true);
      try {
        await clubSettingsUserProfilePersister.deleteUserProfile({
          clubProfileId: initialValue?._id,
        });
        uiStore.snackbarMessage.set({
          type: 'success',
          content: 'Profil supprimé!',
        });
        back();
      } catch (err) {
        uiStore.snackbarMessage.set({
          type: 'error',
          content: 'Erreur innatendue lors de la suppression du profil!',
        });
        appLogger.error('Unexpecter error deleting user profile', err);
      } finally {
        setOperationPending(false);
      }
    } else {
      setOperationPending(false);
    }
  }, [back, initialValue?._id]);

  return (
    <AppPageContainerWithFixedBars
      paddingBottom={false}
      className={'bg-gray-50'}
      {...loadableContent}
      footerBar={() => (
        <AppFixedButtonsBar hasChanges={form.formState.isDirty}>
          <>
            <Button
              disabled={operationPending}
              startIcon={<AppIconsMaterial.cancel />}
              variant="outlined"
              tabIndex={500}
              className={globalClasses.buttonCancel}
              onClick={() => back()}
            >
              Annuler
            </Button>
            <Button
              disabled={operationPending}
              startIcon={<AppIconsMaterial.save />}
              variant="contained"
              className={globalClasses.buttonSave}
              onClick={() => submitForm()}
            >
              Enregistrer
            </Button>
          </>
        </AppFixedButtonsBar>
      )}
    >
      <AppBreadcrumbBar
        color={AppIconsSettings.account.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.account.main,
            label: 'Mon compte',
            url: '/club/settings',
          },
          {
            label:
              mode === 'create'
                ? 'NOUVEAU PROFIL'
                : `PROFIL "${initialValue?.label}"`,
          },
        ]}
      />

      <AppPageContentContainer
        paddingBottom={false}
        className={'bg-gray-50 app-px-content'}
      >
        <ClubSettingsUserProfileEditForm
          className={'app-my-content app-card app-p-content'}
          form={form}
        />

        {mode === 'edit' && userAccounts?.length > 0 && (
          <div className="app-card p-4 ">
            <h3 className={'my-2 text-lg font-bold uppercase text-app-primary'}>
              Comptes utilisateurs
            </h3>
            <ul className="border-t border-gray-100 divide-y divide-gray-200">
              {userAccounts.map((userAccount, i) => (
                <ClubSettingsUserAccountCard
                  key={userAccount._id}
                  as="li"
                  userAccount={userAccount}
                />
              ))}
            </ul>
          </div>
        )}

        {mode === 'edit' && userAccounts?.length === 0 && (
          <div className="flex justify-center lg:justify-start gap-4">
            <AppButton
              style={{ minWidth: '6rem' }}
              size="normal"
              color={'danger-outline-light'}
              icon={AppHeroIcons.actionDelete}
              type="button"
              onClick={() => deleteProfile()}
            >
              Supprimer ce profil
            </AppButton>
          </div>
        )}
      </AppPageContentContainer>
    </AppPageContainerWithFixedBars>
  );
};
