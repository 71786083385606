//https://react-icons.github.io/react-icons/icons?name=md
import {
  ArchiveBoxIcon,
  Bars3Icon,
  BellIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckBadgeIcon,
  ClockIcon,
  CurrencyDollarIcon,
  MapPinIcon,
  NoSymbolIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline';
import { ClockIcon as ClockIconSolid } from '@heroicons/react/24/solid';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { ReactComponent as help } from 'src/assets/icons/ui/help.svg';

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
export const AppIconsUI = {
  fullscreen: MdFullscreen,
  fullscreenExit: MdFullscreenExit,
  emptyPhoto: PhotoIcon,
  navLeft: ChevronLeftIcon,
  navLeftFast: ChevronDoubleLeftIcon,
  navRight: ChevronRightIcon,
  navRightFast: ChevronDoubleRightIcon,
  comment: ChatBubbleBottomCenterTextIcon,
  disabled: NoSymbolIcon,
  statusPending: ClockIcon,
  statusPendingSolid: ClockIconSolid,
  statusValidated: CheckBadgeIcon,
  statusArchived: ArchiveBoxIcon,
  statusAuthorized: CurrencyDollarIcon,
  eyeOn: BsEyeFill,
  eyeOff: BsEyeSlashFill,
  location: MapPinIcon,
  notification: BellIcon,
  menu: Bars3Icon,
  help,
};
