import { OnlineBookingOrderArticle } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { useClubSettings } from '../../../../../../data/hooks';
import { AppMultilineComment } from '../../../../../_common/form';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingCardOnlineBookingArticleItem } from './DiverBookingCardOnlineBookingArticleItem';

export const DiverBookingCardOnlineBookingArticle = ({
  globalState,
  orderArticle,
  className,
}: {
  globalState: DiverBookingPageGlobalState;
  orderArticle: OnlineBookingOrderArticle;
  className?: string;
}) => {
  const clubSettings = useClubSettings();
  const appUiTheme = clubSettings?.publicSettings?.appUiTheme;

  const activityDate = orderArticle?.activityDate;
  const diveSessionDateTime = orderArticle?.diveSessionDateTime;
  return (
    <li className={clsx('flex py-4', className)}>
      <div className="w-full flex flex-1 flex-col">
        <div className="flex gap-4 justify-between">
          <div>
            <div
              className={clsx(
                'uppercase font-bold w-full text-left',
                'text-sm sm:text-base',
              )}
              style={{ color: appUiTheme.title1.textColor }}
            >
              {orderArticle?.articleDetails?.name}
            </div>
          </div>
          <div className="font-bold text-gray-800">
            <span className="uppercase">
              {dateService.formatUTC(activityDate, 'dddd DD/MM/YYYY')}
            </span>
            {diveSessionDateTime &&
              ` à ${dateService.formatUTC(diveSessionDateTime, 'HH:mm')}`}
          </div>
        </div>
        <div
          className={clsx(
            'mt-2 flex flex-col items-stretch divide-y divide-gray-200',
          )}
        >
          {orderArticle.items.map((orderArticleItem, i) => (
            <DiverBookingCardOnlineBookingArticleItem
              key={i}
              orderArticle={orderArticle}
              orderArticleItem={orderArticleItem}
              clubSettings={clubSettings}
              globalState={globalState}
            />
          ))}
        </div>
        {orderArticle.comment?.trim()?.length > 0 && (
          <AppMultilineComment type="public" comment={orderArticle.comment} />
        )}
      </div>
    </li>
  );
};
