import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useAppBookingSessionAuth } from 'src/business/auth/services';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSessionWithSession,
} from '../../../models';
import { DiverBookingSessionsTableSessionHeader } from '../../DiverBookingSessionsTable/DiverBookingSessionsTableSessionHeader';
import { BookingMassiveEditorActions } from '../useBookingMassiveEditorDialogActions.hook';
import { BookingMassiveEditorInnerLocalState } from '../useBookingMassiveEditorInnerLocalState.hook';
import { BookingMassiveEditorSessionsTableParticipantCell } from './BookingMassiveEditorSessionsTableParticipantCell';

export function BookingMassiveEditorSessionsTableDay({
  localState,
  bookingSessionFull,
  participants,
  displayName,
  displayLastName,
  displaySessionName,
  displayDiveSite,
  actions,
  filteredDiverId,
  onClickSession,
  className,
}: {
  localState: BookingMassiveEditorInnerLocalState;
  bookingSessionFull: AggregatedBookingSessionFull;
  participants: BookingResumeParticipantForSessionWithSession[];
  displayName?: boolean;
  displayLastName?: boolean;
  displaySessionName: boolean;
  displayDiveSite: boolean;
  onClickSession?: (bookingSessionFull: AggregatedBookingSessionFull) => void;
  actions: Pick<
    BookingMassiveEditorActions,
    | 'onClickParticipant'
    | 'onClickRegisterAllDailyParticipants'
    | 'onClickDeleteAllDailyParticipants'
  >;
  filteredDiverId?: string;
  className?: string;
}) {
  const isPast = useMemo(
    () =>
      dateService.isPastUTC(bookingSessionFull.diveSession.time) &&
      !dateService.isTodayUTC(bookingSessionFull.diveSession.time),
    [bookingSessionFull.diveSession.time],
  );

  const theme = useMemo(() => (isPast ? 'light' : 'normal'), [isPast]);

  const unregisteredParticipantsCount = useMemo(
    () =>
      participants
        .filter(
          ({ bookingParticipantFullAnyBooking, bookingMemberFull }) =>
            !bookingParticipantFullAnyBooking &&
            bookingMemberFull?.diver?.defaultDiveConfig?.diveMode,
        )
        .map(({ bookingMemberFull }) => bookingMemberFull).length,
    [participants],
  );

  const registeredParticipantsCount = useMemo(
    () =>
      participants
        .filter(
          ({ bookingParticipantFullAnyBooking, bookingMemberFull }) =>
            !!bookingParticipantFullAnyBooking,
        )
        .map(({ bookingMemberFull }) => bookingMemberFull).length,
    [participants],
  );
  const bookingSessionAuth = useAppBookingSessionAuth({
    bookingSessionDiveCenterId: bookingSessionFull.bookingSession?.diveCenterId,
  });

  const bookingViewAuth = localState.state.bookingViewAuth;

  const showCheckAllArea =
    bookingViewAuth.edit &&
    participants.length > 1 &&
    unregisteredParticipantsCount + registeredParticipantsCount > 0;

  const showCheckAllButton = showCheckAllArea && bookingSessionAuth.edit;

  return (
    <div
      className={`w-24 px-1 ${className ?? ''}`}
      // className={`${isSelectedDiveSession ? 'w-48' : 'w-24'} px-1 py-0 ${
      //   className ?? ''
      // }`}
    >
      <DiverBookingSessionsTableSessionHeader
        displaySessionName={displaySessionName}
        displayDiveSite={displayDiveSite}
        className={clsx('mb-1', onClickSession && 'cursor-pointer')}
        theme={theme}
        bookingSessionFull={bookingSessionFull}
        onClick={
          onClickSession ? () => onClickSession(bookingSessionFull) : undefined
        }
      />
      {showCheckAllArea && (
        <div
          className={clsx(
            'my-1 w-full font-bold py-1 px-1 cursor-pointer',
            unregisteredParticipantsCount === 0
              ? 'text-app-primary'
              : 'text-gray-400 ',
            showCheckAllButton ? '' : 'invisible',
          )}
          onClick={() => {
            if (unregisteredParticipantsCount === 0) {
              actions.onClickDeleteAllDailyParticipants({
                participants,
              });
            } else {
              actions.onClickRegisterAllDailyParticipants({
                participants,
              });
            }
          }}
        >
          <div className="w-full flex flex-row justify-start items-center gap-1 px-0">
            <input
              className="text-app-primary ring-1 ring-white"
              type="checkbox"
              readOnly={true}
              checked={unregisteredParticipantsCount === 0}
            />
            <div>TOUS</div>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-2">
        {participants
          .filter(
            (x) =>
              !filteredDiverId ||
              filteredDiverId === x.bookingMemberFull?.diver?._id,
          )
          .map((participant, k) => {
            const { bookingMemberFull, bookingParticipantFull, style } =
              participant;

            return (
              <BookingMassiveEditorSessionsTableParticipantCell
                key={k}
                className=""
                theme={theme}
                localState={localState}
                bookingParticipantFull={bookingParticipantFull}
                bookingSessionFull={bookingSessionFull}
                bookingMemberFull={bookingMemberFull}
                displayName={displayName}
                displayLastName={displayLastName}
                style={style}
                onClick={actions.onClickParticipant}
              />
            );
          })}
      </div>
    </div>
  );
}
