import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppMessage } from 'src/business/_core/modules/layout';
import {
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { clubSettingsCustomersUrlBuilder } from 'src/pages/SE-settings/SE-03-customers/clubSettingsCustomersUrlBuilder.service';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';
import { CreateMessageToCustomersFormStep4_ContentTranslateBar } from './CreateMessageToCustomersFormStep4_ContentTranslateBar';

export const CreateMessageToCustomersFormStep3_ContentFormEdit: FC<{
  localState: CreateMessageToCustomersLocalState;
}> = ({ localState }) => {
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume.clubResume;

  const {
    state,
    data: { form, result },
    actions,
  } = localState;

  const { register, handleSubmit, watch, control, setValue } = form;

  const settingsCommunicationTabTemplatesUrl = useMemo(
    () =>
      clubSettingsCustomersUrlBuilder.communication.view({
        tabId: 'templates',
      }),
    [],
  );
  const clubSettings = useClubSettings();

  return (
    <div className="grid gap-2">
      <AppFormControlRHF_Deprecated
        className={'w-full'}
        label={'Titre'}
        control={control}
        name={'messageContent.title'}
        renderComponent={(props) => (
          <AppInputRHF
            multiline={true}
            rows={2}
            {...props}
            fullWidth
            type="text"
          />
        )}
      />
      <AppFormControlRHF_Deprecated
        className={'w-full'}
        label={'Message'}
        control={control}
        name={'messageContent.body'}
        renderComponent={(props) => (
          <AppInputRHF
            multiline={true}
            rows={10}
            rowsMin={10}
            rowsMax={14}
            {...props}
            fullWidth
            type="text"
          />
        )}
      />
      <AppFormControlRHF_Deprecated
        className={'w-full'}
        label={'Signature'}
        control={control}
        name={'messageContent.signature'}
        renderComponent={(props) => (
          <AppInputRHF
            multiline={true}
            rows={3}
            {...props}
            fullWidth
            type="text"
          />
        )}
      />

      <div className="flex justify-end">
        <CreateMessageToCustomersFormStep4_ContentTranslateBar
          localState={localState}
        />
      </div>

      <AppMessage
        className="my-2 text-justify sm:text-left"
        type="info"
        title="Instructions"
        message={
          <>
            <p>
              Pour configurer les modèles de messages, rendez-vous sur{' '}
              <Link
                to={settingsCommunicationTabTemplatesUrl}
                className="font-bold text-app-blue underline"
              >
                la page de configuration
              </Link>
            </p>
          </>
        }
      />
    </div>
  );
};
