import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { clubStore } from 'src/business/_core/store';
import { appWebTracker } from '../../app-user-tracking';
import { BootstrapOptions } from '../appDataLoader.service';
import { ClubBaseFetchResult } from './ClubBaseFetchResult.type';
import { clubBaseDataGraphqlFetcher } from './clubBaseDataGraphqlFetcher.service';
import { clubBaseDataStore } from './clubBaseDataStore.service';

export const clubBaseDataLoader = {
  clear,
  loadClubInitialData,
};

function clear() {
  // clear store
  clubBaseDataStore.clearData();
}

function loadClubInitialData({
  options,
}: {
  options: BootstrapOptions;
}): Observable<any> {
  // const nbWeeksToLoadBeforeCurrent = 1; // n week before
  // const nbWeeksToLoadAfterCurrent = 2; // n weeks after
  // const nbWeeksToLoad =
  //   1 + nbWeeksToLoadBeforeCurrent + nbWeeksToLoadAfterCurrent; // MIN 1
  // const minDateInclusive = dateService.add(
  //   dateService.getWeekDates(new Date())[0].date,
  //   nbWeeksToLoadBeforeCurrent,
  //   'week',
  // );
  // const maxDateExclusive = dateService.add(
  //   minDateInclusive,
  //   nbWeeksToLoad - 1,
  //   'week',
  // );

  // const loadedWeekTimestamps: number[] = [];
  // let dateToIncrement = minDateInclusive;
  // for (let i = 0; i < nbWeeksToLoad; i++) {
  //   loadedWeekTimestamps.push(dateToIncrement.getTime());
  //   dateToIncrement = dateService.add(dateToIncrement, 1, 'week');
  // }

  return loadClubBaseData({
    ...options,
    // minDateInclusive,
    // maxDateExclusive,
  }).pipe(
    first(),
    switchMap((clubBaseData) => {
      if (clubBaseData.authUser) {
        clubBaseDataStore.setData(clubBaseData, { loadedWeekTimestamps: [] });
      } else {
        appLogger.warn('[adminBaseDataLoader] invalid data:', clubBaseData);
        clubBaseDataStore.clearData();
        return throwError(new Error('Error loading data'));
      }

      return of(undefined);
    }),
  );
}

function loadClubBaseData({
  trackingClient,
  clubReference,
  userId,
}: BootstrapOptions & {}): Observable<ClubBaseFetchResult> {
  return clubStore.loadingParams.get().pipe(
    filter((p) => p?.isInitialLoading),
    tap((loadingParams) =>
      appLogger.info(
        '[clubBaseDataLoader] loading GRAPHQL data',
        loadingParams,
      ),
    ),
    switchMap((loadingParams) => {
      const startDate = new Date();
      return clubBaseDataGraphqlFetcher
        .fetch({
          clubReference: clubReference,
          userId: userId,
          // minDate: loadingParams.minDate, // OLD
          // minDateInclusive, // NEW
          // maxDateExclusive,
        })
        .pipe(
          tap((data) => {
            if (data?.authUser?.profile === 'club' && !!data?.accountProfile) {
              appWebTracker.trackDataLoading(
                {
                  data,
                  startDate,
                  endDate: new Date(),
                  pageName: 'ClubRootSpace',
                  dataLabel: 'initial data',
                  context: {},
                  clubReference,
                  limitToRandomPercent: 5, // on ne reporte que 5% du temps
                },
                {
                  trackingClient,
                },
              );
            }
          }),
        );
    }),
    catchError((err) => {
      appLogger.warn('[clubBaseDataLoader] error loading GRAPHQL data', err);
      return throwError(err);
    }),
  );
}
