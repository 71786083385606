import React from 'react';
import { IoPeople } from 'react-icons/io5';
import { DiveSessionSimpleCardTotalCounters } from '../../../../club-planning-light/components';
import { DiveSessionParticipantsStats } from '../../../model';

export const ClubDiveSessionHeaderParticipants = ({
  diveSessionParticipantsStats,
  className = '',
}: {
  diveSessionParticipantsStats: DiveSessionParticipantsStats;
  className?: string;
}) => {
  return (
    <div className={`flex ${className}`}>
      <IoPeople className="ml-0.5 sm:ml-1 w-3 h-3 text-gray-500" />
      <div className="ml-0.5 sm:ml-1 mr-1">PARTICIPANTS: </div>
      <div className={'truncate'}>
        <DiveSessionSimpleCardTotalCounters
          diveSessionParticipantsStats={diveSessionParticipantsStats}
        />
      </div>
    </div>
  );
};
