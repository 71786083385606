/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubProductPackageNamingConfiguration } from '@mabadive/app-common-model';
import { Control, useWatch } from 'react-hook-form';
import { ClubOfferProductEditorFormModel } from './ClubOfferProductEditorFormModel.type';

export function useClubOfferProductEditorFormValue({
  control,
}: {
  control: Control<ClubOfferProductEditorFormModel, object>;
}): ClubOfferProductEditorFormModel {
  const price: number = useWatch({
    control,
    name: 'price',
  });
  const thirdPartyCollectPrice: number = useWatch({
    control,
    name: 'thirdPartyCollectPrice',
  });

  const namingConfiguration: ClubProductPackageNamingConfiguration = useWatch({
    control,
    name: 'namingConfiguration',
  });
  const comment: string = useWatch({
    control,
    name: 'comment',
  });
  const label: string = useWatch({
    control,
    name: 'label',
  });
  const minAge: number = useWatch({
    control,
    name: 'minAge',
  });
  const maxAge: number = useWatch({
    control,
    name: 'maxAge',
  });

  const productType = useWatch({
    control,
    name: 'productType',
  });

  const formValue: ClubOfferProductEditorFormModel = {
    price,
    thirdPartyCollectPrice,
    namingConfiguration,
    comment,
    label,
    minAge,
    maxAge,
    productType,
  };
  return formValue;
}
