import {
  certificationFormatter,
  dateService,
  nameFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { ClubDiverSimilarSearchCandidate } from './model';

export function useClubDiverSimilarSearchCandidatesOptions(
  possibleDuplicated: ClubDiverSimilarSearchCandidate[],
): ValueLabel<string, React.ReactNode>[] {
  return useMemo(() => {
    if (possibleDuplicated) {
      return possibleDuplicated.map(({ candidate, score }) => {
        return {
          value: candidate.diver._id,
          label: (
            <div className="flex gap-2 items-center">
              <div
                className={clsx(
                  'text-white px-0.5 flex justify-center items-center rounded-full h-8 w-8',
                  score >= 90
                    ? 'bg-status-success'
                    : score >= 80
                    ? 'bg-status-warn'
                    : 'bg-gray-500',
                )}
              >
                {score}%
              </div>
              <div className="normal-case">
                <div className="text-gray-500">
                  {nameFormatter.formatFullName(candidate.diver, {
                    format: 'firstName-first',
                  })}
                </div>
                <div className="flex gap-2">
                  {candidate.diver.birthdate && (
                    <div className="">
                      {dateService.getAge(candidate.diver.birthdate)} ans
                    </div>
                  )}
                  {candidate.diver.birthdate && (
                    <div className="">
                      {certificationFormatter.formatCertificationReference(
                        candidate.diver.mainCertificationReference,
                        {
                          format: 'ref',
                        },
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ),
        };
      });
    }
  }, [possibleDuplicated]);
}
