/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { useAppRouter } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppMessageLight,
  AppPageContainer,
  AppPageContentContainer,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import {
  AppIcons,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import { useClubResume } from 'src/business/club/data/hooks';
import useRouter from 'use-react-router';
import { ClubSettingsSection } from '../../../_core';
import { clubSettingsCustomersUrlBuilder } from '../../clubSettingsCustomersUrlBuilder.service';
import { ClubSettingsInquiryCard } from './components';
import { useClubSettingsInquiriesListPageLocalState } from './useClubSettingsInquiriesListPageLocalState';
export const ClubSettingsInquiriesListPage = () => {
  const { match } = useRouter();
  const autoFocus = useAutoFocus();

  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const globalClasses = useGlobalClasses();

  const appRouter = useAppRouter();

  const clubResume = useClubResume();

  const { diveCenterResume, inquiries } =
    useClubSettingsInquiriesListPageLocalState();

  // clubSettingsCustomersUrlBuilder

  return (
    <AppPageContainer className={'bg-gray-50'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.account.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.customers.main,
            label: 'Clients',
            url: '/club/settings',
          },
          {
            label: 'Questionnaires',
          },
        ]}
      />
      <AppPageContentContainer className="bg-white app-p-content">
        <div className={clsx(operationInProgressMessage && 'invisible')}>
          <ClubSettingsSection
            title="Questionnaires client"
            actions={
              <AppButton
                color={'primary-bg'}
                icon={AppIcons.action?.add}
                onClick={() => {
                  appRouter.navigate(
                    clubSettingsCustomersUrlBuilder.inquiries.create(),
                    {
                      cause: 'click-create',
                    },
                  );
                }}
              >
                <div className="whitespace-nowrap">NOUVEAU</div>
              </AppButton>
            }
          >
            <div className="bg-gray-50 flex flex-col gap-4">
              {inquiries.map((inquiry, i) => (
                <ClubSettingsInquiryCard
                  key={i}
                  inquiry={inquiry}
                  onClick={() => {
                    appRouter.navigate(
                      clubSettingsCustomersUrlBuilder.inquiries.edit({
                        inquiryId: inquiry._id,
                      }),
                      {
                        cause: 'click-edit',
                      },
                    );
                  }}
                />
              ))}
            </div>
            {inquiries?.length > 0 && (
              <AppMessageLight
                type="info"
                className="mt-4 mb-2 text-sm md:text-base"
              >
                <div className="grid gap-2">
                  <p>
                    L'activation automatique permet d'activer automatiquement
                    les questionnaires suivant l'âge ou les prestations à venir
                    des participants.
                  </p>
                  <div>
                    <p className="font-bold">Exemple:</p>
                    <ul className="ml-4 list-disc">
                      <li>
                        les enfants de moins de 12 ans qui effectuent un baptême
                      </li>
                      <li>les plongeurs en formation</li>
                    </ul>
                  </div>
                  <p>
                    Vous pouvez également activer ou désactiver chaque
                    formulaire manuellement pour chaque participant
                  </p>
                </div>
              </AppMessageLight>
            )}
          </ClubSettingsSection>
        </div>
        <div className={globalClasses.pageBlock}>
          {operationInProgressMessage && (
            <LoadingProgressBar>
              {operationInProgressMessage}
            </LoadingProgressBar>
          )}
        </div>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
