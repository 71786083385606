import { dateService } from '@mabadive/app-common-services';
import {
  AppTitleDateNavigatorModel,
  AppTitleDateNavigatorModelValue,
  AppTitleDateNavigatorPeriod,
} from '../model';

export const appTitleDateNavigatorPropsBuilder = {
  buildCalculatedProps,
};

function buildCalculatedProps({
  value,
  period,
  minDate,
  maxDateInclusive,
  ajustPeriodRangeToCurrentDate,
}: {
  value: AppTitleDateNavigatorModelValue;
  period: AppTitleDateNavigatorPeriod;
  minDate?: Date;
  maxDateInclusive?: Date;
  ajustPeriodRangeToCurrentDate: boolean;
}): Pick<AppTitleDateNavigatorModel, 'isNow' | 'previousValue' | 'nextValue'> {
  const maxDateExclusive = maxDateInclusive
    ? dateService.add(maxDateInclusive, 1, 'day')
    : undefined;

  let previousValue: AppTitleDateNavigatorModelValue;
  let nextValue: AppTitleDateNavigatorModelValue;

  const now = new Date();

  if (period === 'day') {
    {
      const previousDate = dateService.add(value.selectedDate, -1, 'day');
      if (!minDate || previousDate.getTime() >= new Date(minDate).getTime()) {
        previousValue = {
          selectedDate: previousDate,
          beginDate: previousDate,
          endDate: previousDate,
        };
      }
    }
    {
      const nextDate = dateService.add(value.selectedDate, 1, 'day');
      if (
        !maxDateExclusive ||
        nextDate.getTime() < new Date(maxDateExclusive).getTime()
      ) {
        nextValue = {
          selectedDate: nextDate,
          beginDate: nextDate,
          endDate: nextDate,
        };
      }
    }
  } else if (period === 'week') {
    {
      const previousEndDate = dateService.add(value.endDate, -7, 'day');
      if (
        !minDate ||
        previousEndDate.getTime() >= new Date(minDate).getTime()
      ) {
        previousValue = {
          selectedDate: dateService.add(value.selectedDate, -7, 'day'),
          beginDate: dateService.add(value.beginDate, -7, 'day'),
          endDate: previousEndDate,
        };
      }
    }
    {
      const nextBeginDate = dateService.add(value.beginDate, 7, 'day');
      if (
        !maxDateExclusive ||
        nextBeginDate.getTime() < new Date(maxDateExclusive).getTime()
      ) {
        nextValue = {
          selectedDate: dateService.add(value.selectedDate, 7, 'day'),
          beginDate: nextBeginDate,
          endDate: dateService.add(value.endDate, 7, 'day'),
        };
      }
    }
  } else if (period === 'month') {
    {
      const previousEndDate = dateService.add(value.endDate, -1, 'month');
      const previousBeginDate = dateService.add(value.beginDate, -1, 'month');

      if (
        !minDate ||
        previousEndDate.getTime() >= new Date(minDate).getTime()
      ) {
        // date manquante ou à re-calculer
        const endDate = dateService.getMonthBoundsUTC(previousBeginDate)[1];
        if (ajustPeriodRangeToCurrentDate) {
          endDate.setUTCDate(now.getUTCDate());
        }
        previousValue = {
          selectedDate: previousBeginDate,
          beginDate: previousBeginDate,
          endDate,
        };
      }
    }
    {
      const nextBeginDate = dateService.add(value.beginDate, 1, 'month');

      if (
        !maxDateExclusive ||
        nextBeginDate.getTime() < new Date(maxDateExclusive).getTime()
      ) {
        // date manquante ou à re-calculer
        const endDate = dateService.getMonthBoundsUTC(nextBeginDate)[1];
        if (ajustPeriodRangeToCurrentDate) {
          endDate.setUTCDate(now.getUTCDate());
        }
        nextValue = {
          selectedDate: nextBeginDate,
          beginDate: nextBeginDate,
          endDate,
        };
      }
    }
  } else if (period === 'year' || period === 'year-fiscal') {
    {
      const previousEndDate = dateService.add(value.endDate, -1, 'year');
      if (
        !minDate ||
        previousEndDate.getTime() >= new Date(minDate).getTime()
      ) {
        const endDate = previousEndDate;
        // date manquante ou à re-calculer
        if (ajustPeriodRangeToCurrentDate) {
          if (ajustPeriodRangeToCurrentDate) {
            endDate.setUTCMonth(now.getUTCMonth());
            endDate.setUTCDate(now.getUTCDate());
          }
        }
        previousValue = {
          selectedDate: dateService.add(value.selectedDate, -1, 'year'),
          beginDate: dateService.add(value.beginDate, -1, 'year'),
          endDate,
        };
      }
    }
    {
      const nextBeginDate = dateService.add(value.beginDate, 1, 'year');
      if (
        !maxDateExclusive ||
        nextBeginDate.getTime() < new Date(maxDateExclusive).getTime()
      ) {
        // date manquante ou à re-calculer
        const endDate = dateService.getYearBoundsUTC(nextBeginDate)[1];
        if (ajustPeriodRangeToCurrentDate) {
          if (ajustPeriodRangeToCurrentDate) {
            endDate.setUTCMonth(now.getUTCMonth());
            endDate.setUTCDate(now.getUTCDate());
          }
        }
        nextValue = {
          selectedDate: nextBeginDate,
          beginDate: nextBeginDate,
          endDate,
        };
      }
    }
  } else {
    // not supported
  }

  const calculatedProps: Pick<
    AppTitleDateNavigatorModel,
    'isNow' | 'previousValue' | 'nextValue'
  > = {
    isNow: dateService.isDateWithinRange(now, {
      minDate: value.beginDate,
      maxDateExclusive: dateService.add(value.endDate, 1, 'day'),
    }),
    previousValue,
    nextValue,
  };
  return calculatedProps;
}
