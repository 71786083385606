import { AppOperation } from '@mabadive/app-common-model';
import {
  LoadableAttributeCollectionStore,
  loadableAttributeCollectionStoreFactory,
  LoadableAttributeStore,
  loadableAttributeStoreFactory,
  SimpleStore,
  uuidGenerator,
} from '@mabadive/app-common-services';
import { Observable, of, ReplaySubject } from 'rxjs';
import { debounceTime, first, map, switchMap, tap } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { browserCache } from 'src/_common-browser';
import { CLUB_OPERATIONS_UPDATES_BROWSER_STORAGE_ID } from './CLUB_OPERATIONS_UPDATES_BROWSER_STORAGE_ID.const';
import { ClubAppOperationUpdateClientErrorMode } from './model/ClubAppOperationUpdateClientErrorMode.type';
import { ClubAppOperationUpdateClientPendingPayload } from './model/ClubAppOperationUpdateClientPendingPayload.type';

const DEFAULT_ERROR_MODE: ClubAppOperationUpdateClientErrorMode = 'retry';
const DEFAULT_MAX_ERROR_COUNT = 10;

export type ClubAppOperationUpdateClientStore = {
  pendingPayloads: LoadableAttributeCollectionStore<ClubAppOperationUpdateClientPendingPayload>;
  context: LoadableAttributeStore<{
    userId?: string;
  }>;
};
const browserCacheKey$ = new ReplaySubject<string>(1);

browserCacheKey$
  .pipe(
    debounceTime(3000),
    switchMap((browserCacheKey) =>
      getAll().pipe(
        first(),
        tap((pendingPayloads) => {
          appLogger.debug(
            `[ClubAppOperationUpdateClientStore] Persist ${pendingPayloads?.length} payloads to cache key "${browserCacheKey}"`,
          );
        }),
        switchMap((pendingPayloads) =>
          browserCache.set<ClubAppOperationUpdateClientPendingPayload[]>(
            browserCacheKey,
            pendingPayloads,
            {
              ignoreError: true,
            },
          ),
        ),
      ),
    ),
  )
  .subscribe();

export const clubAppOperationUpdateClientStoreProvider = {
  addOne,
  addMany,
  getAll,
  removeMany,
  init,
  getStore,
  loadInitialPayloadsFromBrowserCache,
};

function addOne({
  userId,
  operation,
  actionId,
  errorMode = DEFAULT_ERROR_MODE,
  maxErrorCount = DEFAULT_MAX_ERROR_COUNT,
}: {
  userId: string;
  operation: AppOperation<any>;
  actionId: string;
  errorMode?: ClubAppOperationUpdateClientErrorMode;
  maxErrorCount?: number;
}) {
  if (!actionId) {
    actionId = 'addPayload';
  }

  const pendingPayload: ClubAppOperationUpdateClientPendingPayload =
    buildNewPendingPayload({ operation, errorMode, maxErrorCount });

  getStore().pendingPayloads.addOne({
    value: pendingPayload,
    actionId,
  });

  browserCacheKey$.next(buildBrowserCacheKey({ userId }));
}

function buildNewPendingPayload({
  operation,
  errorMode,
  maxErrorCount,
}: {
  operation: AppOperation<any>;
  errorMode?: ClubAppOperationUpdateClientErrorMode;
  maxErrorCount?: number;
}): ClubAppOperationUpdateClientPendingPayload {
  return {
    reference: uuidGenerator.random(),
    operation,
    errorMode,
    errorCount: 0,
    maxErrorCount,
  };
}

function addMany({
  userId,
  operations,
  actionId,
  errorMode = DEFAULT_ERROR_MODE,
  maxErrorCount = DEFAULT_MAX_ERROR_COUNT,
}: {
  userId: string;
  operations: AppOperation<any>[];
  actionId: string;
  errorMode?: ClubAppOperationUpdateClientErrorMode;
  maxErrorCount?: number;
}) {
  if (!actionId) {
    actionId = 'addPayloads';
  }

  const pendingPayloads: ClubAppOperationUpdateClientPendingPayload[] =
    operations.map((operation) =>
      buildNewPendingPayload({ operation, errorMode, maxErrorCount }),
    );

  getStore().pendingPayloads.addMany({
    values: pendingPayloads,
    actionId,
  });
  browserCacheKey$.next(buildBrowserCacheKey({ userId }));
}

function removeMany({
  userId,
  pendingPayloads,
  actionId,
}: {
  userId: string;
  pendingPayloads: ClubAppOperationUpdateClientPendingPayload[];
  actionId: string;
}) {
  if (!actionId) {
    actionId = 'removePayloads';
  }

  getStore().pendingPayloads.removeMany({
    identify: (pendingPayload) => {
      const match =
        pendingPayloads.findIndex(
          (pp) => pp.reference === pendingPayload.reference,
        ) !== -1;

      return match;
    },
    actionId,
  });

  browserCacheKey$.next(buildBrowserCacheKey({ userId }));
}

function getAll(): Observable<ClubAppOperationUpdateClientPendingPayload[]> {
  return getStore().pendingPayloads.getAll();
}

let store: ClubAppOperationUpdateClientStore;

function getStore(): ClubAppOperationUpdateClientStore {
  if (!store) {
    throw new Error(
      'Call "clubAppOperationUpdateClientStoreProvider.init()" method first to initialize ClubAppOperationUpdateClientStore.',
    );
  }
  return store;
}

function init(baseStore: SimpleStore<any>): Observable<number> {
  store = {
    pendingPayloads:
      loadableAttributeCollectionStoreFactory.create<ClubAppOperationUpdateClientPendingPayload>(
        baseStore,
        'massive_update_pending_payloads',
      ),
    context: loadableAttributeStoreFactory.create<{
      userId?: string;
    }>(baseStore, 'massive_update_context', {
      isLoaded: true,
      value: {},
    }),
  };

  return of(0);
}

function loadInitialPayloadsFromBrowserCache({
  userId,
}: {
  userId: string;
}): Observable<number> {
  const browserCacheKey = buildBrowserCacheKey({ userId });

  return browserCache
    .get<ClubAppOperationUpdateClientPendingPayload[]>(browserCacheKey, {
      ignoreError: true,
    })
    .pipe(
      tap((subscriptions) => {
        if (subscriptions && subscriptions.length) {
          store.pendingPayloads.setAll(
            subscriptions.concat(store.pendingPayloads.getSnapshot() ?? []),
          );
        } else {
          // store.pendingPayloads.removeAll();
        }
      }),
      map((subscriptions) => (subscriptions ? subscriptions.length : 0)),
    );
}

function buildBrowserCacheKey({ userId }: { userId: string }) {
  return `${CLUB_OPERATIONS_UPDATES_BROWSER_STORAGE_ID}-${userId}`;
}
