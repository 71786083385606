/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { clubEventDialogChangesAggregator } from './clubEventDialogChangesAggregator';
import {
  ClubEventDialogInputState,
  ClubEventDialogUpdateState,
  ClubEventEditorFormModel,
} from './model';

export function useClubEventDialogLocalState({
  inputState,
  initialFormValue,
}: {
  inputState: ClubEventDialogInputState;
  initialFormValue: ClubEventEditorFormModel;
}) {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const participantFirstNameBefore = useMemo(
    () =>
      clubResume.clubSettings.ui?.planning?.participantFirstNameBefore ?? false,
    [clubResume.clubSettings.ui?.planning?.participantFirstNameBefore],
  );
  const staffFirstNameBefore = useMemo(
    () => clubResume.clubSettings.ui?.planning?.staffFirstNameBefore ?? false,
    [clubResume.clubSettings.ui?.planning?.staffFirstNameBefore],
  );

  const [isPersistInProgress, setIsPersistInProgress] = useState(false);

  const { defaultValue, originalEvent, mode, isOpen } = inputState;

  const form = useForm<ClubEventEditorFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  // const formState2 = useFormState({control})

  const formValue = useWatch({
    control,
  }) as ClubEventEditorFormModel;

  const [updateStateInner, setUpdateStateInner] =
    useState<ClubEventDialogUpdateState>({
      hasChanges: false,
      clubEventsChanges: [],
    });

  const setUpdateState = useCallback(
    (updateState: ClubEventDialogUpdateState) =>
      setUpdateStateInner({
        ...updateState,
        hasChanges: true,
      }),
    [setUpdateStateInner],
  );

  // loaded data + changes applied
  const { updateState, aggregatedData } = useMemo(
    () =>
      clubEventDialogChangesAggregator.aggregateChanges({
        diveCenterResume,
        formValue,
        isDirty: formState.isDirty, // Note: il semble que formState n'est pas immuable, donc il faut avoir un observer sur formState.isDirty et pas juste sur formState
        inputState,
        updateState: updateStateInner,
      }),
    [
      diveCenterResume,
      formValue,
      formState.isDirty,
      inputState,
      updateStateInner,
    ],
  );

  return {
    form,
    aggregatedData,
    clubReference,
    isPersistInProgress,
    setIsPersistInProgress,
    mode,
    isOpen,
    defaultValue,
    setUpdateState,
    updateState,
    inputState,
    participantFirstNameBefore,
    staffFirstNameBefore,
  };
}

export type ClubEventDialogLocalState = ReturnType<
  typeof useClubEventDialogLocalState
>;
