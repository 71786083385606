/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { AppFormControlV2, AppSingleAutocomplete2 } from 'src/lib/form';
import { AppSingleSelect2HeadlessUI } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUI';
import { AppInputDatePickerMaterial } from 'src/stories/components/04-form';
import { useDiveCenterResume } from '../../../../business/club/data/hooks';
import { useBookingAgenciesOptions } from '../../../_components/options';
import { PaymentsListPageLocalState } from '../usePaymentsListPageLocalState';

export const PaymentsListPageConfigBarExtraFilters = ({
  localState,
  className,
}: {
  localState: PaymentsListPageLocalState;
  className?: string;
}) => {
  const { viewParameters, setViewParameters, filters } = localState;

  const autoFocus = useAutoFocus();
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const customerSettings = diveCenterResume?.clubResume?.clubSettings?.customer;
  const agenciesOptions = useBookingAgenciesOptions();

  const {
    searchText,
    setSearchText,
    paymentState,
    setPaymentState,
    paymentMethod,
    setPaymentMethod,
    paymentStateOptions,
    paymentMethodOptions,
    bookingAgencyId,
    setBookingAgencyId,
  } = filters;
  return (
    <div className="flex flex-col gap-2">
      <input
        className="xs:hidden mx-1 w-full hover:border-app-blue rounded px-2"
        autoFocus={autoFocus}
        autoComplete="new-password"
        placeholder="Recherche par nom, référence, montant"
        type="text"
        defaultValue={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />

      <div
        className={clsx(
          'grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-x-2 gap-y-1',
          className,
        )}
      >
        <AppFormControlV2
          className="w-full xs:col-span-2 sm:col-span-1 bg-white px-2"
          label="Moyen de paiement"
          renderComponent={() => (
            <AppSingleSelect2HeadlessUI
              className="w-full"
              theme={'material-ui'}
              disableClearButton={false}
              value={paymentMethod}
              options={paymentMethodOptions}
              onChange={(value) => setPaymentMethod(value)}
            />
          )}
        />

        <AppFormControlV2
          className="w-full xs:col-span-2 sm:col-span-1 bg-white px-2"
          label="Statut"
          renderComponent={() => (
            <AppSingleSelect2HeadlessUI
              className="w-full"
              theme={'material-ui'}
              disableClearButton={false}
              value={paymentState}
              options={paymentStateOptions}
              onChange={(value) => setPaymentState(value)}
            />
          )}
        />

        <AppFormControlV2
          className="w-full bg-white px-2"
          label="Date mini"
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-full"
              value={viewParameters.periodRange.value?.beginDate}
              onChange={(beginDate) =>
                setViewParameters({
                  ...viewParameters,
                  periodRange: {
                    ...viewParameters.periodRange,
                    value: {
                      ...viewParameters.periodRange.value,
                      beginDate,
                    },
                  },
                })
              }
            />
          )}
        />
        <AppFormControlV2
          className="w-full bg-white px-2"
          label="Date maxi"
          renderComponent={() => (
            <AppInputDatePickerMaterial
              initialView={'weeks'}
              className="w-full"
              value={viewParameters.periodRange.value?.endDate}
              onChange={(endDate) =>
                setViewParameters({
                  ...viewParameters,
                  periodRange: {
                    ...viewParameters.periodRange,
                    value: {
                      ...viewParameters.periodRange.value,
                      endDate,
                    },
                  },
                })
              }
            />
          )}
        />
        {generalSettings?.agencies?.enabled &&
          generalSettings.agencies?.agencyPayment && (
            <AppFormControlV2
              className="w-full lg:w-72 bg-white px-2"
              label="Agence"
              renderComponent={() => (
                <AppSingleAutocomplete2
                  className="w-full"
                  value={bookingAgencyId}
                  options={agenciesOptions}
                  onChange={(agencyId) => setBookingAgencyId(agencyId)}
                />
              )}
            />
          )}
      </div>
    </div>
  );
};
