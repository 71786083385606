/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  dateService,
  onlineBookingStateFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { ClubOrdersListPageLocalState } from '../useClubOrdersListPageLocalState.hook';

export const ClubOrdersListPageConfigBarFiltersResume = ({
  localState,
  className,
}: {
  localState: ClubOrdersListPageLocalState;
  className?: string;
}) => {
  const { filters } = localState;

  const { searchText, form } = filters;

  const [onlineBookingStates, bookingDate] = useWatch({
    control: form.control,
    name: ['onlineBookingStates', 'bookingDate'],
  });
  return onlineBookingStates.length > 0 ||
    bookingDate ||
    searchText?.trim()?.length !== 0 ? (
    <div
      className={clsx(
        'flex justify-start flex-wrap gap-x-4 text-xs text-gray-400 uppercase',
        className,
      )}
    >
      {searchText?.trim()?.length !== 0 && (
        <div className={clsx('flex xs:hidden gap-1')}>
          <div className="font-bold whitespace-nowrap">Recherche:</div>
          <div className="whitespace-nowrap">{searchText?.trim()}</div>
        </div>
      )}
      {onlineBookingStates?.length > 0 && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Statut:</div>
          {onlineBookingStates
            .map((state) =>
              onlineBookingStateFormatter.formatState(state).toUpperCase(),
            )
            .join(', ')}
        </div>
      )}

      {bookingDate && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Date de la résa:</div>
          {dateService.formatUTC(bookingDate, 'DD/MM/YYYY')}
        </div>
      )}
      {/* {arrivalDate && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Arrivée:</div>
          {dateService.formatUTC(arrivalDate, 'DD/MM/YYYY')}
        </div>
      )}
      {departureDate && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Départ:</div>
          {dateService.formatUTC(departureDate, 'DD/MM/YYYY')}
        </div>
      )} */}
      {/* {orderAgencyId && (
        <div className={clsx('flex gap-1')}>
          <div className="font-bold whitespace-nowrap">Agence:</div>
          <AgencyLabel agencyId={orderAgencyId} />
        </div>
      )} */}
    </div>
  ) : null;
};
