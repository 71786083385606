/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ClubSettingsProductOffersFiltersArea } from 'src/pages/SE-settings/SE-02_account/ClubSettingsProductOffersDialog/form/ClubSettingsProductOffersFiltersArea';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';
import { ClubSettingsServicesOffersPricesContextMode } from '../_model';
import { ClubSettingsServicesViewPanelOffersPricesActionsBar } from './ClubSettingsServicesViewPanelOffersPricesActionsBar';
import { ClubSettingsServicesViewPanelOffersPricesList } from './ClubSettingsServicesViewPanelOffersPricesList';
import {
  ClubSettingsServicesViewPanelOffersPricesLocalState,
  useClubSettingsServicesViewPanelOffersPricesLocalState,
} from './useClubSettingsServicesViewPanelOffersPricesLocalState.hook';

export const ClubSettingsServicesViewPanelOffersPrices = ({
  localState: parentState,
  contextMode,
}: {
  localState: ClubSettingsServicesPageLocalState;
  contextMode: ClubSettingsServicesOffersPricesContextMode;
}) => {
  const localState: ClubSettingsServicesViewPanelOffersPricesLocalState =
    useClubSettingsServicesViewPanelOffersPricesLocalState({
      parentState,
      contextMode,
    });

  const { data, state, actions } = localState;
  const publicSettings = data.publicSettings;
  const clubReference = data.clubReference;
  const clubSettings = data.clubSettings;

  return (
    <ClubSettingsSection
      title="Tarifs"
      comment={
        data.offersAllBeforeSearch.length === 0
          ? 'Définissez vos tarifs'
          : undefined
      }
      // onClick={
      //   state.editEnabled
      //     ? () =>
      //         actions.openOfferEditDialog({
      //           mode: 'edit',
      //           offers: data.offersAll,
      //         })
      //     : undefined
      // }
      search={
        data.offersAllBeforeSearch.length === 0 ? null : (
          <input
            className="w-90 max-w-full hover:border-app-blue rounded px-2"
            // autoFocus={autoFocus}
            autoComplete="new-password"
            placeholder="Recherche"
            type="text"
            defaultValue={state.searchText}
            onChange={(e) => {
              state.setSearchText(e.target.value);
            }}
          />
        )
      }
      actions={
        <ClubSettingsServicesViewPanelOffersPricesActionsBar
          localState={localState}
          contextMode={contextMode}
        />
      }
    >
      <ClubSettingsProductOffersFiltersArea
        className="my-2"
        filtersState={localState.state.filtersState}
      />

      {/* <div className="mt-4 flex gap-4">
        {data.offersAll.length > 0 && (
          <AppButton
            color={'primary-outline-light'}
            size="normal"
            icon={AppIcons.action?.edit}
            onClick={(e) => {
              e.stopPropagation();
              actions.switchToEditMode(data.offersAll);
            }}
          >
            Modifier tout
          </AppButton>
        )}
      </div> */}
      <ClubSettingsServicesViewPanelOffersPricesList
        localState={localState}
        contextMode={contextMode}
      />
      <div className="min-h-[200px]"></div>
    </ClubSettingsSection>
  );
};
