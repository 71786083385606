import { JsonPatchOperation } from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { RxjsForm } from './RxjsForm.type';

export const rxjsFormActionManager = {
  saveChanges,
};

function saveChanges<V, T>({
  form,
  isCreationMode,
  createMethod,
  updateMethod,
  attributesToReplaceFully,
  beforePatchTransform,
}: {
  form: RxjsForm<V>;
  isCreationMode: boolean;
  createMethod: ({ form }: { form: RxjsForm<V> }) => Observable<T>;
  updateMethod: ({ form, patchOperations }: { form: RxjsForm<V>, patchOperations: JsonPatchOperation[] }) => Observable<T>;
  attributesToReplaceFully: (keyof V)[] | (keyof T)[];
  beforePatchTransform?: (value: V, options?: { isInitialValue: boolean }) => Partial<T>;
}): Observable<{ success: boolean, value?: T }> {
  if (form.valid) {
    if (isCreationMode) {
      //create
      return createMethod({ form }).pipe(
        map(value => ({ success: true, value })),
      );
    } else {
      // update
      if (form.hasChanges) {
        let patchOperations: any;
        if (beforePatchTransform) {
          const o1 = beforePatchTransform(form.initialValue, { isInitialValue: true });
          const o2 = beforePatchTransform(form.value, { isInitialValue: false });
          patchOperations = jsonPatcher.compareObjects(o1, o2, {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: true,
            attributesToReplaceFully: (attributesToReplaceFully as unknown as (keyof T)[]),
          });

        } else {
          patchOperations = jsonPatcher.compareObjects(form.initialValue, form.value, {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: true,
            attributesToReplaceFully: (attributesToReplaceFully as unknown as (keyof V)[]),
          });
        }

        if (patchOperations.length) {
          return updateMethod({ form, patchOperations }).pipe(
            map(value => ({ success: true, value })),
          );
        } else {
          // no change
          return of({ success: true });
        }
      } else {
        // no change
        return of({ success: true });
      }
    }

  } else {
    // invalid form
    appLogger.warn('Form is invalid', form)
    form.actions.touchComponents();
  }
  return of({ success: false });

}