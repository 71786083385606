/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { AppLoaderSpinner } from 'src/business/_core/modules/layout';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { ClubSettingsAccountDisplayPageLocalState } from './useClubSettingsAccountDisplayPageLocalState.hook';

export const ClubSettingsAccountDisplayEditCardGlobal = ({
  localState,
  className,
}: {
  localState: ClubSettingsAccountDisplayPageLocalState;
  className?: string;
}) => {
  const {
    planningConfig,
    setPlanningConfig,
    settingsUiPlanning,
    setSettingsUiPlanning,
    operationInProgressUiPlanning,
    operationInProgressFullScreen,
    persistSettingsUiPlanning,
    persistSettingsUiFullScreenOnTouchDevices,
    fullScreenOnTouchDevices,
    setFullScreenOnTouchDevices,
  } = localState;
  return (
    <div className={clsx('app-card app-p-content', className)}>
      <h2 className="text-left uppercase text-base md:text-lg leading-6 mt-4 font-medium text-gray-800 flex justify-between">
        <>Configuration générale de l'affichage</>
        {operationInProgressFullScreen && (
          <AppLoaderSpinner className="ml-2 inline-block w-6 h-6" />
        )}
      </h2>

      <h3 className="mt-2 mb-1 font-bold uppercase text-sm text-app-blue">
        Sur mobile et tablette
      </h3>
      <div className="mb-3 flex flex-col gap-2">
        <AppInputBooleanSwitch
          value={fullScreenOnTouchDevices}
          label="proposer de passer en plein écran à la connexion"
          onChange={async () => {
            setFullScreenOnTouchDevices(!fullScreenOnTouchDevices);
            await persistSettingsUiFullScreenOnTouchDevices(
              !fullScreenOnTouchDevices,
            );
          }}
        />
      </div>
    </div>
  );
};
