import clsx from 'clsx';
import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButton,
  AppDialogModalFixedBar,
  AppMessageLight,
  AppMessageSingleLine,
} from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsAction,
} from 'src/business/_core/modules/layout/icons';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { CreateMessageToCustomersLocalState } from '../useCreateMessageToCustomersLocalState.hook';
import { CreateMessageToCustomersFormStep4_RecipientsTable } from './CreateMessageToCustomersFormStep4_RecipientsTable';

export const CreateMessageToCustomersFormStep4_SendOneByOne: FC<{
  localState: CreateMessageToCustomersLocalState;
  className?: string;
}> = ({ localState, className }) => {
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume.clubResume;
  const clubReference = clubResume?.reference;

  const {
    state,
    data: { form, result },
    actions,
  } = localState;

  const {
    aggregated: { allRecipients, validRecipients, validSelectedRecipients },
    changes,
  } = result;

  const { register, handleSubmit, watch, control, setValue } = form;

  const [messageTarget, recipients] = useWatch({
    control,
    name: ['messageTarget', 'recipients'],
  });

  return (
    <div className={clsx('mb-12 flex flex-col gap-8', className)}>
      <div className="grid gap-4">
        <AppMessageSingleLine type="info">
          Cliquer individuellement sur chaque participant pour lui envoyer le
          message.
        </AppMessageSingleLine>
        <CreateMessageToCustomersFormStep4_RecipientsTable
          localState={localState}
        />
      </div>
      <div className="flex-grow"></div>
      {messageTarget === 'whatsapp' && (
        <AppMessageLight type="warn">
          <div className="">
            <div>
              Whatsapp ne permet pas d'envoyer un message de groupe gratuitement
              depuis Mabadive.
            </div>
          </div>
        </AppMessageLight>
      )}
      <AppDialogModalFixedBar>
        <div className="flex gap-4 justify-between md:justify-end">
          <AppButton
            className="w-full uppercase sm:max-w-xs"
            fullWidth={true}
            size="normal"
            icon={AppIconsAction.back}
            color={'gray-outline-light'}
            tabIndex={500}
            onClick={() => {
              state.setStep('step3-content');
            }}
          >
            Retour
          </AppButton>
          <AppButton
            className="w-full uppercase sm:max-w-xs"
            fullWidth={true}
            size="normal"
            icon={AppHeroIcons.actionClose}
            color={'gray-outline-light'}
            tabIndex={500}
            onClick={() => {
              actions.closeDialog();
            }}
          >
            Fermer
          </AppButton>
        </div>
      </AppDialogModalFixedBar>
    </div>
  );
};
