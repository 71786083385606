import { ClubEvent } from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';

export const clubEventsSorter = {
  sort,
};

function sort(
  items: ClubEvent[],
  {
    asc,
  }: {
    asc: boolean;
  } = { asc: true },
): ClubEvent[] {
  return dataSorter.sortMultiple(items, {
    getSortAttributes: (x: ClubEvent) => [
      {
        value: x.schedule.beginTime ? null : x.beginDate,
      },
      {
        value: x.schedule.beginTime ? null : x._createdAt,
      },
      {
        value: x.schedule.beginTime,
      },
      {
        value: x.schedule.endTime,
      },
      {
        value:
          x.schedule.dayTime === 'morning'
            ? 1
            : x.schedule.dayTime === 'midday'
            ? 2
            : x.schedule.dayTime === 'evening'
            ? 3
            : x.schedule.dayTime === 'afternoon'
            ? 4
            : x.schedule.dayTime === 'night'
            ? 5
            : 6,
      },
      {
        value: x.status === 'confirmed' || x.status === 'pending' ? 1 : 2,
      },
      {
        value: x.priority === 'high' ? 1 : x.priority === 'normal' ? 2 : 3,
      },
      {
        value: x._id,
      },
    ],
    asc,
  });
}
