import { DiveSession } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React from 'react';

export const ClubDiveSessionHeaderSessionDate = function ({
  diveSession: session,
  className = '',
}: {
  diveSession: Pick<DiveSession, 'time'>;
  className?: string;
}) {
  return (
    <div className={`text-white font-bold uppercase text-center ${className}`}>
      {session.time && dateService.formatUTC(session.time, 'dddd DD/MM/YYYY')}
    </div>
  );
};
