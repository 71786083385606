/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { defaultBookingBuilder, defaultDiverBuilder } from '../../services';
import {
  BookingDepositEditDialogInitialState,
  BookingDepositEditFormModel,
} from './model';
export function useBookingDepositEditDialogLocalState({
  initialState,
}: {
  initialState: BookingDepositEditDialogInitialState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const aggregatedData = initialState.aggregatedData;
  const bookings = aggregatedData?.bookings;
  const divers = aggregatedData?.divers;
  const filteredDiverId = aggregatedData?.focus?.filteredDiverId;

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;

  const defaultValues: BookingDepositEditFormModel = useMemo(() => {
    if (initialState.mode === 'create') {
      const initialBookingId = defaultBookingBuilder.getDefaultBookingId(
        aggregatedData.bookingResumesVisible,
        {
          diveCenterId,
          focusDiverId: aggregatedData.focus?.clubDiver._id,
        },
      );
      const defaultDiverId =
        defaultDiverBuilder.getDefaultDiverId(aggregatedData);
      const defaultValues: BookingDepositEditFormModel = {
        bookingDeposit: {
          depositDate: new Date(),
          bookingId: initialBookingId,
          diverId: defaultDiverId,
          creditInitial: undefined,
          creditUsed: 0,
          creditRemaining: 0,
          depositState: 'draft',
          details: {
            bookingAgencyCommissionRate: undefined,
          },
        },
      };
      return defaultValues;
    } else {
      return initialState.defaultValue;
    }
  }, [
    aggregatedData,
    diveCenterId,
    initialState.defaultValue,
    initialState.mode,
  ]);

  const form = useForm<BookingDepositEditFormModel>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  return {
    data: {
      aggregatedData,
      bookings,
      divers,
      filteredDiverId,
    },
    form,
    hasFormChanges,
    initialState,
  };
}

export type BookingDepositEditDialogLocalState = ReturnType<
  typeof useBookingDepositEditDialogLocalState
>;
