import {
  ClubDiverFull,
  ClubDiverFullGql_Company,
} from '@mabadive/app-common-model';
import { MQueryDescription } from 'src/_common-browser';

export function buildClubDiverFullOneGraphqlQuery({
  diverId,
}: {
  diverId: string;
}) {
  const queryDescription: MQueryDescription<ClubDiverFull> = {
    queryName: 'club_diver',
    returnType: 'first',
    returnName: 'clubDiverFull',
    where: `{_id: {_eq: "${diverId}"}}`,
    returnAttributes: ClubDiverFullGql_Company,
  };

  return queryDescription;
}
