import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
  AppSingleSelect2HeadlessUIRHF,
} from 'src/lib/form';

import {
  DiveMode,
  DiveTrainingReference,
  FirstDiveTrainingReference,
} from '@mabadive/app-common-model';
import { AppButton } from '../../../../../../../../business/_core/modules/layout';
import { AppIconsAction } from '../../../../../../../../business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from '../../../../../../../../business/auth/services';
import { useClubResume } from '../../../../../../../../business/club/data/hooks';
import { ValueLabel } from '../../../../../../../../business/club/modules/_common/form';
import {
  useDiveModesOptions,
  useFirstDiveTrainingOptions,
  useProductPackageOffersOptions,
  useTrainingOptions,
} from '../../../../../../../_components/options';
import { EcommerceProductArticleEditorLocalState } from '../useEcommerceProductArticleEditorPanelLocalState.hook';
import { EcommerceProductArticleEditorFormSettingsPrices } from './EcommerceProductArticleEditorFormSettingsPrices';

export const EcommerceProductArticleEditorFormSettings = ({
  localState,
  className = '',
}: {
  localState: EcommerceProductArticleEditorLocalState;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const services = clubResume.clubSettings.services;
  const {
    data,
    state: { form },
  } = localState;
  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    formState,
    setValue,
  } = form;

  const [bookletPage, productName, enabled, diveMode] = useWatch({
    control,
    name: [
      'appBookletPage',
      'productArticle.name',
      'productArticle.enabled',
      'productArticle.diveMode',
    ],
  });
  const offersOptions: ValueLabel<string>[] = useProductPackageOffersOptions(
    data.offers,
  );

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const diveModesOptions: ValueLabel<DiveMode>[] = useDiveModesOptions({
    diveModes: data.diveModeGroup.diveModes,
    format: 'short-ref-label',
  });
  const firstDiveTrainingOptions: ValueLabel<FirstDiveTrainingReference>[] =
    useFirstDiveTrainingOptions(clubSettings, {
      scope: 'enabled',
    });

  const trainingOptions: ValueLabel<DiveTrainingReference>[] =
    useTrainingOptions(services);
  const createBooklet = useCallback(() => {
    setValue('appBookletPage', {
      mainContent: {
        languageCode: 'fr',
        content: {
          title: productName,
          items: [],
        },
        cover: {},
      },
    });
  }, [productName, setValue]);
  return (
    <div className={clsx('grid grid-cols-1 gap-4', className)}>
      <h2 className="mx-1 mt-6 mb-2 text-sm font-bold text-app-primary uppercase">
        {'Paramètres'}
      </h2>
      <div className={'flex flex-wrap gap-y-4 gap-x-4'}>
        <AppFormControlRHF
          label="Offre correspondante"
          control={control}
          name={'productArticle.productPackageOfferId'}
          required={true}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              {...props}
              theme="tailwind"
              disableClearButton={true}
              options={offersOptions}
            />
          )}
        />
        {isSuperAdmin && (
          <>
            <AppFormControlRHF
              className="w-full sm:max-w-[18rem]"
              label="Prestation"
              control={control}
              name={'productArticle.diveMode'}
              required={true}
              renderComponent={(props) => (
                <AppSingleAutocomplete2RHF
                  {...props}
                  options={diveModesOptions}
                  onChange={(diveMode: DiveMode) => {
                    // onDiveModeChange(diveMode);
                  }}
                />
              )}
            />
            {diveMode === 'first-dive' &&
              firstDiveTrainingOptions.length > 1 && (
                <AppFormControlRHF
                  className="w-full sm:max-w-[18rem]"
                  label="Type de baptême"
                  control={control}
                  name={'productArticle.details.firstDiveReference'}
                  required={true}
                  renderComponent={(props) => (
                    <AppSingleAutocomplete2RHF
                      {...props}
                      options={firstDiveTrainingOptions}
                    />
                  )}
                />
              )}
            {(diveMode === 'training' ||
              diveMode === 'theoretical-training') && (
              <AppFormControlRHF
                className="w-full sm:max-w-[18rem]"
                label="Formation"
                control={control}
                name={'productArticle.details.trainingReference'}
                required={false} // pas obligatoire (si ça regroupe plusieurs formations)
                renderComponent={(props) => (
                  <AppSingleAutocomplete2RHF
                    {...props}
                    options={trainingOptions}
                  />
                )}
              />
            )}
            <AppFormControlRHF
              className="w-36"
              control={control}
              label={'Nb participants MIN'}
              name="productArticle.details.minParticipantsCount"
              required={true}
              validation={{
                rules: { min: 0 },
              }}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" />
              )}
            />
            <AppFormControlRHF
              className="w-36"
              control={control}
              label={'Nb participants MAX'}
              name="productArticle.details.maxParticipantsCount"
              required={true}
              validation={{
                rules: { min: 0 },
              }}
              renderComponent={(props) => (
                <AppInputRHF {...props} type="number" />
              )}
            />
          </>
        )}
      </div>
      <div className={'flex flex-wrap gap-y-4 gap-x-4'}>
        <AppFormControlRHF
          control={control}
          label={'Afficher le produit'}
          name="productArticle.enabled"
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        {enabled && (
          <AppFormControlRHF
            control={control}
            label={'Afficher le bouton "Réserver"'}
            name="productArticle.details.onlineBooking.showButton"
            renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
          />
        )}
      </div>
      <EcommerceProductArticleEditorFormSettingsPrices
        className=""
        form={form}
      />
      <div className="flex gap-4">
        {!bookletPage && (
          <AppButton
            size="normal"
            color="gray-outline-light"
            icon={AppIconsAction.edit}
            onClick={(e) => {
              e.stopPropagation();
              createBooklet();
            }}
          >
            {'Ajouter une description'}
          </AppButton>
        )}
      </div>
    </div>
  );
};
