/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveSessionResumeParticipant,
  DiveSessionResumeParticipantsByGroup,
  DiveSessionStaffMemberTableModelStaffMember,
} from '@mabadive/app-common-model';
import {
  diveSessionGroupBuilder,
  diveSessionMultipleBuilder,
  diveSessionStaffMembersTableModelBuilder,
} from '@mabadive/app-common-services';
import { useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ClubPlanningSessionSelectorDialogState } from 'src/business/club/modules/club-planning-light/club-planning-session-selector-dialog/ClubPlanningSessionSelectorDialog';
import { useFormParticipantsCheckedState } from './_hooks';
import {
  DiveSessionEditorMassiveDialogState,
  DiveSessionEditorMassiveFormModel,
} from './_model';
export function useDiveSessionEditorMassiveDialogLocalState({
  inputState,
}: {
  inputState: DiveSessionEditorMassiveDialogState;
}) {
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const { initialState } = inputState;

  const { originalGroups, originalParticipants, originalSessionFull } =
    initialState;

  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();
  const [pageState, setPageState] = useState<{
    step: 'select-divers' | 'select-session' | 'resume-with-options';
  }>({ step: 'select-divers' });

  const diveSession = originalSessionFull;

  const diveCenterResume = useDiveCenterResume();

  const sameStaffSession1 = diveSession.diveTourSession2?.sameStaffSession1;
  const sameParticipantsSession1 =
    (originalParticipants ?? []).find(
      (x) => x.divesCount !== diveSession.sessionsCount,
    ) === undefined;

  const isMultiSessionConfigForStaff =
    !!diveSession.diveTourSession2 &&
    diveSessionMultipleBuilder.isMultiSessionConfigForStaff({
      sameStaffSession1,
    });
  const isMultiSessionConfigForParticipants =
    !!diveSession.diveTourSession2 && !sameParticipantsSession1;

  const participantFirstNameBefore = useMemo(
    () =>
      clubResume.clubSettings.ui?.planning?.participantFirstNameBefore ?? false,
    [clubResume.clubSettings.ui?.planning?.participantFirstNameBefore],
  );

  const staffMembersFull: DiveSessionStaffMemberTableModelStaffMember[] =
    useMemo(
      () =>
        diveSessionStaffMembersTableModelBuilder.buildStaffMembersFull({
          clubParticipants: originalParticipants,
          diveSession,
          groups: originalGroups,
          staffMembers: diveCenterResume.staffMembers,
          filterMode: 'all',
          staffFirstNameBefore: true,
          dailyConfigs: [], // pas nécessaire ici
          clubSettings: diveCenterResume?.clubResume?.clubSettings,
        }),
      [
        diveCenterResume?.clubResume?.clubSettings,
        diveCenterResume.staffMembers,
        diveSession,
        originalGroups,
        originalParticipants,
      ],
    );

  const diveSessionParticipantsByGroup: DiveSessionResumeParticipantsByGroup =
    useMemo(() => {
      return diveSessionGroupBuilder.buildDiveSessionParticipantsGroups({
        diveSession,
        groups: originalGroups,
        participants: originalParticipants,
        staffMembers: diveCenterResume.staffMembers,
        ignoreCancelledParticipants: true,
      });
    }, [
      diveSession,
      originalGroups,
      originalParticipants,
      diveCenterResume.staffMembers,
    ]);

  const ungrouppedParticipants = useMemo(
    () =>
      diveSessionParticipantsByGroup.ungroupped.filter(
        (participant) =>
          participant.bookingState &&
          ['pending', 'confirmed'].includes(participant.bookingState.value) &&
          participant.diveMode !== 'observer',
      ),
    [diveSessionParticipantsByGroup.ungroupped],
  );

  const observers = useMemo(
    () =>
      diveSessionParticipantsByGroup.ungroupped.filter(
        (participant) =>
          participant.bookingState &&
          ['pending', 'confirmed'].includes(participant.bookingState.value) &&
          participant.diveMode === 'observer',
      ),
    [diveSessionParticipantsByGroup.ungroupped],
  );

  // create map of participants by index
  const participantsFormIndexesById = useMemo(
    () =>
      originalParticipants.reduce((acc, p, i) => {
        acc[p._id] = i;
        return acc;
      }, {} as { [participantId: string]: number }),
    [originalParticipants],
  );

  const defaultValues: DiveSessionEditorMassiveFormModel = useMemo(() => {
    return {
      actionId: inputState.initialState.actionId,
      participants: Object.keys(participantsFormIndexesById).map((i) => ({
        selected: false,
      })),
      cloneParticipantsGroups: true,
      cloneGuidesAndInstructors: false,
    };
  }, [inputState.initialState.actionId, participantsFormIndexesById]);

  const form = useForm<DiveSessionEditorMassiveFormModel>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  const participantsCheckState = useFormParticipantsCheckedState({
    form,
    participantsFormIndexesById: participantsFormIndexesById,
    participantsIds: originalParticipants.map((x) => x._id),
  });

  const participantsCheckFull: DiveSessionResumeParticipant[] = useMemo(
    () =>
      originalParticipants.filter((x) =>
        participantsCheckState.participantsCheckedIds.includes(x._id),
      ),
    [originalParticipants, participantsCheckState.participantsCheckedIds],
  );

  const sessionSelectorDialogInputState: ClubPlanningSessionSelectorDialogState =
    useMemo(() => {
      if (pageState.step === 'select-session') {
        const state: ClubPlanningSessionSelectorDialogState = {
          isOpen: true,
          mode: 'single',
          alreadySelectedSessionsReferences: [originalSessionFull.reference],
          areadySelectedDiverIds: [],
          context: {
            focusSessionReference: originalSessionFull?.reference,
            bookingId: undefined,
            defaultFocusDate: undefined,
          },
        };
        return state;
      } else {
        return {
          isOpen: false,
        };
      }
    }, [originalSessionFull.reference, pageState.step]);

  return {
    form,
    hasFormChanges,
    initialState,
    inputState,
    data: {
      diveCenterResume,
      diveSessionParticipantsByGroup,
      ungrouppedParticipants,
      observers,
      staffMembersFull,
      sameStaffSession1,
      sameParticipantsSession1,
      isMultiSessionConfigForStaff,
      isMultiSessionConfigForParticipants,
      participantFirstNameBefore,
      participantsFormIndexesById,
      participantsCheckState,
      participantsCheckFull,
      pageState,
      setPageState,
      sessionSelectorDialogInputState,
      operationInProgressMessage,
      setOperationInProgressMessage,
    },
  };
}

export type DiveSessionEditorMassiveDialogLocalState = ReturnType<
  typeof useDiveSessionEditorMassiveDialogLocalState
>;
