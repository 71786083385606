import {
  ClubSettingsServicesPageMode,
  ClubSettingsServicesPageViewState,
} from '../_model';
import { ClubSettingsServicesPageTabId } from './clubSettingsServicesPageTabLabelFormatter.service';

export type ClubSettingsServicesPagegUrlParameters = Pick<
  ClubSettingsServicesPageViewState,
  'mode' | 'tabId'
>;

export const clubSettingsServicesPageUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const QUERY_PARAMS = {
  mode: 'm',
  tabId: 't',
};

function getQueryParameters(): ClubSettingsServicesPagegUrlParameters {
  const params = new URLSearchParams(window.location.search);

  const mode = params.get(QUERY_PARAMS.mode) as ClubSettingsServicesPageMode;

  const tabId = params.get(QUERY_PARAMS.tabId) as ClubSettingsServicesPageTabId;

  const config: ClubSettingsServicesPagegUrlParameters = {
    mode,
    tabId,
  };
  return config;
}

function buildSearchParams(
  viewConfig: Partial<ClubSettingsServicesPagegUrlParameters>,
): string[] {
  if (!viewConfig) {
    return [];
  }
  const { mode, tabId } = viewConfig;

  const searchParams: string[] = [];

  if (mode) {
    searchParams.push(`${QUERY_PARAMS.mode}=${mode}`);
  }
  if (tabId) {
    searchParams.push(`${QUERY_PARAMS.tabId}=${tabId}`);
  }

  return searchParams;
}
