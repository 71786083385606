"use strict";
exports.__esModule = true;
exports.DIVE_MODES = void 0;
exports.DIVE_MODES = [
    'observer',
    'snorkeling',
    'snorkelingSupervised',
    'watchingTour',
    'first-dive',
    'training',
    'supervised',
    'autonomous',
    'theoretical-training',
    'free-dive',
    'instructor',
    'autoSupervised',
    // 'unknown',
];
