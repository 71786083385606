import {
  ClubProductPackage,
  ClubProductPackageOffer,
  DIVE_TRAININGS_REFERENCE_MAP,
} from '@mabadive/app-common-model';
import {
  GenerateOffersDivePlanScubaTrainingOptions,
  GenerateOffersTrainingOptions,
} from '../model';

export const offersTrainingGenerator = {
  generateTrainingOffers,
};

function generateTrainingOffers({
  clubReference,
  options,
}: {
  clubReference: string;
  options: GenerateOffersTrainingOptions;
}): ClubProductPackageOffer[] {
  const newProductPackageOffers: ClubProductPackageOffer[] = [];

  if (options.enabled) {
    options.offers.forEach((offer) => {
      newProductPackageOffers.push(generateTrainingOfferGeneric(offer));
    });
  }

  return newProductPackageOffers;

  function generateTrainingOfferGeneric(
    options: GenerateOffersDivePlanScubaTrainingOptions,
  ) {
    const {
      trainingAttributes,
      productAttributes,
      diveAttributes,
      price,
      thirdPartyCollectPrice,
      label,
      namingConfiguration,
    } = options;
    const { diveTrainingReference } = trainingAttributes;
    const { defaultOrganizationReference } = productAttributes;
    const { divesCount } = diveAttributes;

    const training = DIVE_TRAININGS_REFERENCE_MAP[diveTrainingReference];
    if (!training) {
      throw new Error(
        `[generateTrainingOfferGeneric] Invalid training reference "${diveTrainingReference}"`,
      );
    }
    const reference = `${clubReference};training;${diveTrainingReference};${defaultOrganizationReference};main-certification;${new Date().getTime()}-${Math.floor(
      Math.random() * 100000,
    )}`;
    const productPackage: ClubProductPackage = {
      // name: 'FSD - Scuba Diver',
      // nameXS: 'FSD',
      label,
      type: 'training',
      comment: null,
      reference,
      diveAttributes: {
        diveMode: 'training',
        diveType: 'scuba',
        maxDepth: null,
        equipment: 'not-equipped',
        divesCount,
        supervision: 'supervised',
      },
      productAttributes: {
        maxAge: null,
        minAge: null,
        includeDives: true,
        certificationReference: training.targetCertification?.reference,
        organizationReferences: [defaultOrganizationReference],
        defaultOrganizationReference,
      },
      trainingAttributes: {
        trainingType: 'main-certification',
        diveTrainingReference,
        theoricalClassesCounts: 0,
      },
      namingConfiguration: namingConfiguration ?? {
        includeEquipment: false,
        includeDefaultName: true,
        includeSupervision: false,
      },
    };
    const offer: ClubProductPackageOffer = {
      reference,
      clubReference,
      productPackage,
      price,
      thirdPartyCollectPrice,
    };
    return offer;
  }
}
