import { useMemo } from 'react';
import { useDiveCenterDiveSites } from 'src/business/club/data/hooks';
import { AppIconsSites } from 'src/business/_core/modules/layout/icons';

export type DiveSiteIconDef = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  colorClass: string;
};

export const useDiveSiteIcon = (...diveSiteIds: string[]): DiveSiteIconDef => {
  const diveSites = useDiveCenterDiveSites(diveSiteIds);

  const diveSiteIcon: DiveSiteIconDef = useMemo(
    () =>
      diveSites
        .map((diveSite) =>
          diveSite?.type === 'pool'
            ? { icon: AppIconsSites.artificial, colorClass: 'text-blue-400' }
            : null,
        )
        .find((x) => !!x),

    [diveSites],
  );

  return diveSiteIcon;
};
