import { WeekDate } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';

const ONE_WEEK_IN_MS = 7 * 24 * 3600 * 1000;

export const appDateNavigatorHelper = { isPlanningNow };

function isPlanningNow({
  focusDate,
  viewPeriod,
  weekDates: weekDatesInputOptional,
}: {
  focusDate: Date;
  viewPeriod: 'week' | string;
  weekDates?: WeekDate[];
}) {
  if (viewPeriod === 'week') {
    const weekDates: WeekDate[] =
      weekDatesInputOptional ?? dateService.getWeekDates(focusDate);
    const diffStartOfWeek = new Date().getTime() - weekDates[0].date.getTime();
    return diffStartOfWeek > 0 && diffStartOfWeek < ONE_WEEK_IN_MS;
  } else {
    return dateService.isTodayUTC(focusDate);
  }
}
