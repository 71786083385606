import {
  CANCEL_DIVER_TAG,
  ClubSettingsUIPlanning,
} from '@mabadive/app-common-model';
import React from 'react';
import { ColoredTagAvatarCard } from 'src/business/_core/modules/layout';
import { AppInputBooleanSwitch } from 'src/lib/form';
import { SetPlanningConfigOptions } from '../hooks';
import { ClubPlanningParticipantsConfigDialogFormPayment } from './ClubPlanningParticipantsConfigDialogFormPayment';
import { ClubPlanningParticipantsConfigDialogFormStaffCounters } from './ClubPlanningParticipantsConfigDialogFormStaffCounters';

export function ClubPlanningParticipantsConfigDialogForm<
  T extends ClubSettingsUIPlanning,
>({
  context,
  settingsUiPlanning,
  setSettingsUiPlanning,
  className,
}: {
  context: 'club-settings' | 'device-settings';
  settingsUiPlanning: T;
  setSettingsUiPlanning: (
    settingsUiPlanning: T,
    options?: SetPlanningConfigOptions,
  ) => void;
  className?: string;
}) {
  return (
    <div className={className}>
      <h3 className="mt-2 mb-1 font-bold uppercase text-sm text-app-blue">
        Participants
      </h3>
      <div className="mb-3 flex flex-col gap-2">
        <AppInputBooleanSwitch
          value={settingsUiPlanning.showInactiveParticipants}
          label={
            <div className="flex gap-1 md:gap-1.5 justify-center items-center">
              <ColoredTagAvatarCard
                className={
                  settingsUiPlanning.showInactiveParticipants
                    ? ''
                    : 'opacity-50'
                }
                size={'normal'}
                tag={CANCEL_DIVER_TAG}
              />
              Participants annulés
            </div>
          }
          onChange={() => {
            setSettingsUiPlanning({
              ...settingsUiPlanning,
              showInactiveParticipants:
                !settingsUiPlanning.showInactiveParticipants,
            });
          }}
        />
        <AppInputBooleanSwitch
          value={settingsUiPlanning.showPaymentFlagTraining}
          label={
            <div className="flex gap-1 md:gap-1.5 justify-center items-center">
              <div className="bg-dive-mode-training px-0.5 text-white">3/5</div>
              Compteur formation
            </div>
          }
          onChange={() => {
            setSettingsUiPlanning({
              ...settingsUiPlanning,
              showPaymentFlagTraining:
                !settingsUiPlanning.showPaymentFlagTraining,
            });
          }}
        />

        <ClubPlanningParticipantsConfigDialogFormPayment
          setSettingsUiPlanning={setSettingsUiPlanning}
          settingsUiPlanning={settingsUiPlanning}
        />

        {context === 'club-settings' && (
          <AppInputBooleanSwitch
            value={settingsUiPlanning.participantFirstNameBefore}
            label="prénom avant le NOM"
            onChange={() => {
              setSettingsUiPlanning({
                ...settingsUiPlanning,
                participantFirstNameBefore:
                  !settingsUiPlanning.participantFirstNameBefore,
              });
            }}
          />
        )}
      </div>
      <h3 className="mt-2 mb-1 font-bold uppercase text-sm text-app-blue">
        Moniteurs
      </h3>
      <div className="mb-3 flex flex-col gap-2">
        <AppInputBooleanSwitch
          value={settingsUiPlanning.showStaff}
          label="Nom des moniteurs"
          onChange={() => {
            setSettingsUiPlanning({
              ...settingsUiPlanning,
              showStaff: !settingsUiPlanning.showStaff,
            });
          }}
        />
        <ClubPlanningParticipantsConfigDialogFormStaffCounters
          setSettingsUiPlanning={setSettingsUiPlanning}
          settingsUiPlanning={settingsUiPlanning}
        />

        <AppInputBooleanSwitch
          value={settingsUiPlanning.showStaffCounts}
          label={
            <div className="flex gap-2 justify-center items-center">
              <div className="flex gap-1">
                <span
                  className={
                    'px-0.5 md:px-1 py-px sm:py-0.5 text-xs border border-violet-400 text-violet-400 font-bold'
                  }
                >
                  2/5
                </span>
              </div>
              <div>Compteur session</div>
            </div>
          }
          onChange={() => {
            setSettingsUiPlanning({
              ...settingsUiPlanning,
              showStaffCounts: !settingsUiPlanning.showStaffCounts,
            });
          }}
        />
        {context === 'club-settings' && (
          <AppInputBooleanSwitch
            value={settingsUiPlanning.staffFirstNameBefore}
            label="Prénom avant le NOM"
            onChange={() => {
              setSettingsUiPlanning({
                ...settingsUiPlanning,
                staffFirstNameBefore: !settingsUiPlanning.staffFirstNameBefore,
              });
            }}
          />
        )}
      </div>
      <h3 className="mt-2 mb-1 font-bold uppercase text-sm text-app-blue">
        Sessions
      </h3>
      <div className="mb-3 flex flex-col gap-2">
        <AppInputBooleanSwitch
          value={settingsUiPlanning.showInactiveSessions}
          label="sessions fermées ou annulées"
          onChange={() => {
            setSettingsUiPlanning({
              ...settingsUiPlanning,
              showInactiveSessions: !settingsUiPlanning.showInactiveSessions,
            });
          }}
        />
      </div>

      {/* PAS pour le moment, car c'est risqué, on ne voit plus certaines sessions! Il faudrait afficher un gros warning sur le planning pour réactiver d'un clic (ou alors, on minimiserait la session au lieu de la masquer, à étudier!)
                     <AppInputBooleanSwitch
                      
                      value={settingsUiPlanning.showFullSessions}
                      label="sessions complètes"
                      onChange={() => {
                        setSettingsUiPlanning({
                          ...settingsUiPlanning,
                          showFullSessions: !settingsUiPlanning.showFullSessions,
                        });
                      }}
                    /> */}
    </div>
  );
}
