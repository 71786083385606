/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  diveSessionThemeFormatter,
  diveSessionTypeFormatter,
} from '@mabadive/app-common-services';
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import {
  ClubSettingsSection,
  ClubSettingsViewAttribute,
} from 'src/pages/SE-settings/_core';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { AppPeriodHoursMinutesLabel } from '../../../../../../../business/club/modules/_common/ui';

export const ClubSettingsGeneralSessionsViewPanel = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const redirectTo = useRedirect();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const mainCurrency = useAppCurrencyMain();

  const generalSettings = clubSettings.general;
  return (
    <div className="grid gap-4 md:gap-6">
      <ClubSettingsSection title="Sessions / sorties">
        {clubSettings.general.sessions?.types?.length > 0 && (
          <ClubSettingsViewAttribute label={'Types de sortie'}>
            {clubSettings.general.sessions?.types
              .map((type) =>
                diveSessionTypeFormatter.formatType(type, {
                  diveModesConf: clubSettings?.ui?.diveMode,
                }),
              )
              .join(', ')}
          </ClubSettingsViewAttribute>
        )}
        {clubSettings.general.sessions?.themes?.length > 0 && (
          <ClubSettingsViewAttribute label={'Thèmes de sortie'}>
            {clubSettings.general.sessions?.themes
              .map((theme) => diveSessionThemeFormatter.formatTheme(theme))
              .join(', ')}
          </ClubSettingsViewAttribute>
        )}
      </ClubSettingsSection>
      <ClubSettingsSection title="Options">
        <AppFeatureBadge
          enabled={clubSettings.general.sessions?.displayAutonomousField}
          label="Valider manuellement les palanquées autonomes"
        />
        <AppFeatureBadge
          enabled={clubSettings.general.sessions?.multiSessions?.enabled}
          label="Plongées 2-tank (double-bloc)"
        />
      </ClubSettingsSection>
      <ClubSettingsSection title="Demi-journées moniteur">
        <div className="grid gap-4">
          <div className="grid gap-1">
            <ClubSettingsViewAttribute
              label={'Heures de dispo des moniteurs du matin'}
            >
              <AppPeriodHoursMinutesLabel
                className="text-app-primary"
                period={clubSettings.ui?.planning?.staffPeriods?.am}
              />
            </ClubSettingsViewAttribute>
          </div>
          <div className="grid gap-1">
            <ClubSettingsViewAttribute
              label={"Heures de dispo des moniteurs de l'après-midi"}
            >
              <AppPeriodHoursMinutesLabel
                className="text-app-primary"
                period={clubSettings.ui?.planning?.staffPeriods?.pm}
              />
            </ClubSettingsViewAttribute>
          </div>
        </div>
      </ClubSettingsSection>
    </div>
  );
};
