import { AuthUserRole } from '@mabadive/app-common-model';
import { useAppSecurityUser } from './useAppSecurityUser.hook';

export function useAppSecurityUserHasRoles(...roles: AuthUserRole[]): boolean {
  const securityUser = useAppSecurityUser();
  if (!securityUser || !securityUser.roles) {
    return false;
  }
  const hasAllRoles = roles.reduce(
    (acc, role) => acc && securityUser?.roles.includes(role),
    true,
  );

  return hasAllRoles;
}
