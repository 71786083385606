import React, { useMemo } from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppBookingViewAuth } from 'src/business/auth/services';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { BookingResumeOnClickMemberAction } from '../../../_from-diver-app';
import {
  AggregatedBookingSessionFull,
  BookingParticipantEditorParticipant,
  BookingTabModel,
  PRO_BookingMemberFull_WithDocs,
} from '../../models';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { BookingMassiveEditorSessionsTable } from '../BookingMassiveEditorDialog/BookingMassiveEditorSessionsTable';
import { BookingMassiveEditorSessionsTableRegisterAddSessionButton } from '../BookingMassiveEditorDialog/BookingMassiveEditorSessionsTable/BookingMassiveEditorSessionsTableRegisterAddSessionButton';
import {
  BookingMassiveEditorActions,
  BookingMassiveEditorEventsTriggers,
  useBookingMassiveEditorDialogActions,
} from '../BookingMassiveEditorDialog/useBookingMassiveEditorDialogActions.hook';
import {
  BookingMassiveEditorInnerLocalState,
  BookingMassiveEditorInnerLocalStateAttrs,
  useBookingMassiveEditorInnerLocalState,
} from '../BookingMassiveEditorDialog/useBookingMassiveEditorInnerLocalState.hook';
import { DiverBookingCardGlobalAddButtons } from './DiverBookingCardGlobalAddButtons';

export function DiverBookingCardFutureSessionsTable({
  globalState,
  dialogs,
  actions,
  bookingTabModel,
  onClickSession,
  onClickMember,
  className,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
  bookingTabModel: BookingTabModel;
  onClickSession: (bookingSessionFull: AggregatedBookingSessionFull) => void;
  onClickMember: (attrs: {
    bookingMemberFull: PRO_BookingMemberFull_WithDocs;
    action: BookingResumeOnClickMemberAction;
  }) => void;
  className?: string;
}) {
  const { isUniqueMember, isSameLastName, futureSessionsCount } =
    bookingTabModel.meta;

  const participantsBySessionsFuture =
    bookingTabModel.participantsBySessionsFuture;
  const participantsBySessionsPast = bookingTabModel.participantsBySessionsPast;

  const bookingSessionsIdsFuture = useMemo(
    () =>
      participantsBySessionsFuture.map(
        (x) => x.bookingSessionFull?.bookingSession?._id,
      ),
    [participantsBySessionsFuture],
  );

  const bookingViewAuth = useAppBookingViewAuth({
    bookingDiveCenterId: bookingTabModel.bookingDiveCenterId,
    isSharedBooking: bookingTabModel.isSharedBooking,
  });

  const massiveEditorLocalStateAttrs: BookingMassiveEditorInnerLocalStateAttrs =
    useMemo(
      () => ({
        bookingId: bookingTabModel.bookingId,
        loadedContent: globalState.loadedContent,
        updateState: globalState.updateState,
        setUpdateState: globalState.setUpdateState,
        bookingSessionsIdsFuture, // future only
        missingData: globalState.state.additionnalDataToLoad,
      }),
      [
        bookingSessionsIdsFuture,
        bookingTabModel.bookingId,
        globalState.loadedContent,
        globalState.setUpdateState,
        globalState.state.additionnalDataToLoad,
        globalState.updateState,
      ],
    );

  const massiveEditorLocalState: BookingMassiveEditorInnerLocalState =
    useBookingMassiveEditorInnerLocalState(massiveEditorLocalStateAttrs);

  const massiveEditorEventsTriggers: BookingMassiveEditorEventsTriggers =
    useMemo(() => {
      const onClickCreateParticipant = (
        participant: BookingParticipantEditorParticipant,
      ) => {
        bookingViewAuth.edit &&
          dialogs.setBookingParticipantEditorState({
            isOpen: true,
            // defaultExpandedPanel: 'panel3',
            mode: 'create-participant',
            participant,
          });
      };

      const onClickEditParticipant = (
        participant: BookingParticipantEditorParticipant,
      ) => {
        bookingViewAuth.edit &&
          dialogs.setBookingParticipantEditorState({
            isOpen: true,
            // defaultExpandedPanel: 'panel3',
            mode: 'edit-participant',
            participant,
          });
      };

      const onClickEditMember = (
        bookingMemberFull: PRO_BookingMemberFull_WithDocs,
      ) => {
        bookingViewAuth.edit &&
          onClickMember({
            bookingMemberFull,
            action: 'edit',
          });
      };

      const eventsTriggers: BookingMassiveEditorEventsTriggers = {
        onClickCreateParticipant,
        onClickEditParticipant,
        onClickEditMember,
      };
      return eventsTriggers;
    }, [bookingViewAuth.edit, dialogs, onClickMember]);

  const massiveEditorActions: BookingMassiveEditorActions =
    useBookingMassiveEditorDialogActions({
      eventsTriggers: massiveEditorEventsTriggers,
      localState: massiveEditorLocalState,
      onMemberUpdated: () => {},
    });

  const displayClickPlusWarning = useMemo(
    () =>
      participantsBySessionsFuture.length !== 0 && // at least 1 session
      participantsBySessionsFuture.find(
        // but 0 participant
        (x) => !x.participants.find((p) => !!p.bookingParticipantFull),
      ),
    [participantsBySessionsFuture],
  );

  const filteredDiverId = globalState.updateState.filteredDiverId;

  return (
    <div className={className}>
      {participantsBySessionsFuture.length === 0 ? (
        <>
          {bookingViewAuth.edit && (
            <DiverBookingCardGlobalAddButtons
              isReverseTheme={false}
              bookingTabModel={bookingTabModel}
              actions={actions}
            />
          )}
          {participantsBySessionsPast.length == 0 && (
            <AppMessageLight className="hidden sm:flex my-5" type="info">
              <div className="flex flex-col gap-2 text-sm sm:text-base">
                <>
                  <h3 className="font-bold">
                    Sélectionner une ou plusieurs sessions
                  </h3>
                  <div>
                    Pour réserver une plongée, cliquer sur le bouton suivant :
                    <BookingMassiveEditorSessionsTableRegisterAddSessionButton
                      className="my-4 w-full max-w-[20rem]"
                      isReverseTheme={false}
                      onClickAddSession={() =>
                        actions.openSessionSelector({
                          bookingId:
                            bookingTabModel.aggregatedBooking.booking._id,
                        })
                      }
                    />
                  </div>
                </>
              </div>
            </AppMessageLight>
          )}
        </>
      ) : (
        <BookingMassiveEditorSessionsTable
          className="mt-4"
          context="booking-card"
          onClickSession={onClickSession}
          localState={massiveEditorLocalState}
          displayName={!isUniqueMember}
          displayLastName={!isSameLastName}
          actions={massiveEditorActions}
          filteredDiverId={filteredDiverId}
          globalButtons={
            bookingViewAuth.edit && (
              <DiverBookingCardGlobalAddButtons
                isReverseTheme={false}
                bookingTabModel={bookingTabModel}
                actions={actions}
              />
            )
          }
        />
      )}

      {bookingViewAuth.edit &&
        participantsBySessionsFuture.length !== 0 &&
        (displayClickPlusWarning ||
          bookingTabModel.participantsBySessionsPast.length === 0) && (
          <AppMessageLight className="hidden sm:flex my-5" type="info">
            <div className="flex flex-col gap-2 text-sm sm:text-base">
              {displayClickPlusWarning ? (
                <>
                  <h3 className="font-bold">
                    INSCRIRE LES PARTICIPANTS AUX SESSIONS
                  </h3>
                  <div>
                    Vous avez ajouté des sessions, cliquez maintenant sur le
                    bouton
                    <div
                      className={
                        'mx-1 -mb-1 inline-block w-5 h-5 rounded-xl bg-status-info-light opacity-50'
                      }
                    >
                      <AppHeroIcons.actionAdd className="w-full h-full fill-current text-white" />
                    </div>
                    de chaque session pour inscrire les participants.
                  </div>
                </>
              ) : (
                <>
                  {/* affiché seulement si il n'y a pas de plongées passées */}
                  <h3 className="font-bold">INSTRUCTIONS</h3>
                  <div className="font-bold">
                    Depuis ce tableau, vous pouvez:
                  </div>
                  <ul className="ml-3 list-disc">
                    <li>
                      inscrire <b>individuellement</b> les participant
                    </li>
                    <li>
                      inscrire <b>d'un seul coup</b> tous les participants
                    </li>
                    <li>
                      modifier les paramètres d'un seul participant en cliquant
                      sur la case correspondante
                    </li>
                    <li>
                      modifier les paramètres de toutes les plongées d'un
                      participant en cliquant sur bouton{' '}
                      <AppHeroIcons.actionEdit className="border border-gray-400 p-px ml-1 -mt-1 inline-block h-5 w-5" />
                    </li>
                  </ul>
                </>
              )}
            </div>
          </AppMessageLight>
        )}
    </div>
  );
}
