import {
  DiveCenterResume,
  DiveCenterResumeGql_pro,
} from '@mabadive/app-common-model';
import { MQueryDescription } from 'src/_common-browser';

export function buildDiveCenterResumeOneGraphqlQuery({
  diveCenterId,
}: {
  diveCenterId: string;
}) {
  const queryDescription: MQueryDescription<DiveCenterResume> = {
    returnName: 'diveCenterResume',
    queryName: 'dive_center',
    returnType: 'first',
    where: `{_id: {_eq: "${diveCenterId}"}}`,
    returnAttributes: DiveCenterResumeGql_pro,
  };

  return queryDescription;
}
