/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  ClubSettingsPlanning,
  ClubSettingsPlanningPeriodConfig,
  WEEK_DAYS_ISO,
} from '@mabadive/app-common-model';
import { jsonPatcher, uuidGenerator } from '@mabadive/app-common-services';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import useRouter from 'use-react-router';
import { clubSettingsDiveCenterUrlBuilder } from '../../clubSettingsDiveCenterUrlBuilder.service';
import { ClubSettingsPlanningViewDayModel } from '../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek';
import { clubSettingsPlanningViewWeekVMBuilder } from '../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek/clubSettingsPlanningViewWeekVMBuilder.service';
import { clubSettingsPlanningViewWeekFormInitialValueBuilder } from './ClubSettingsPlanningViewWeekForm/clubSettingsPlanningViewWeekFormInitialValueBuilder.service';
import { clubSettingsPlanningViewWeekEditorResultBuilder } from './clubSettingsPlanningViewWeekEditorResultBuilder.service';
import {
  ClubSettingsPlanningEditPageNavigationContext,
  ClubSettingsPlanningViewWeekFormModel,
} from './model';
import { virtualDiveTourReferenceBuilder } from './virtualDiveTourReferenceBuilder.service';

export function useClubSettingsPlanningEditPageLocalState({
  navigationContext,
}: {
  navigationContext: ClubSettingsPlanningEditPageNavigationContext;
}) {
  const params = new URLSearchParams(window.location.search);

  const { mode } = navigationContext;

  const { match, history } =
    useRouter<{
      periodConfigId: string;
    }>();
  const periodConfigId = match.params.periodConfigId;

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;
  const redirectTo = useRedirect();

  const back = useCallback(() => {
    redirectTo(clubSettingsDiveCenterUrlBuilder.planning.view());
  }, [redirectTo]);

  const clubSettings = diveCenterResume?.clubResume?.clubSettings;
  const clubSettingsPlanning = diveCenterResume.settingsPlanning;

  const initialPeriodConfig: ClubSettingsPlanningPeriodConfig = useMemo(
    () =>
      mode === 'create'
        ? {
            _id: uuidGenerator.random(),
            enabled: true,
            name: 'Saison XXX',
            conflictMode: 'exclusive',
            beginDate: undefined,
            endDate: undefined,
            weekDays: WEEK_DAYS_ISO.map((isoWeekDay) => ({
              isoWeekDay,
              isOpen: true,
              diveTours: [],
            })),
          }
        : clubSettingsPlanning.periodConfigs.find(
            (x) => x._id === periodConfigId,
          ),
    [clubSettingsPlanning.periodConfigs, mode, periodConfigId],
  );

  const weekDays: ClubSettingsPlanningViewDayModel[] = useMemo(
    () =>
      clubSettingsPlanningViewWeekVMBuilder.buildModel({
        diveCenterResume,
        initialPeriodConfig,
      }),
    [diveCenterResume, initialPeriodConfig],
  );

  const initialFormValue = useMemo(
    () =>
      clubSettingsPlanningViewWeekFormInitialValueBuilder.buildInitialFormValue(
        { weekDays, initialPeriodConfig, mode, clubSettings },
      ),
    [weekDays, initialPeriodConfig, mode, clubSettings],
  );

  const form = useForm<ClubSettingsPlanningViewWeekFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const submitForm = useCallback(() => {
    handleSubmit(
      async (formValue: ClubSettingsPlanningViewWeekFormModel, event) => {
        const updatedPeriodConfig: ClubSettingsPlanningPeriodConfig =
          clubSettingsPlanningViewWeekEditorResultBuilder.buildUpdatedPlanningPeriodConfig(
            { initialPeriodConfig, formValue },
          );
        updatedPeriodConfig.weekDays.forEach((weekDay) =>
          weekDay.diveTours.forEach((diveTour) => {
            if (!diveTour.reference) {
              // generate unique reference from criteria
              diveTour.reference =
                virtualDiveTourReferenceBuilder.generateUniqueDiveTourReference(
                  {
                    diveTour,
                    diveTours: weekDay.diveTours,
                  },
                );
            }
          }),
        );
        const updatedClubSettingsPlanning: ClubSettingsPlanning =
          mode === 'edit' || mode === 'edit-session-booking'
            ? {
                ...diveCenterResume.settingsPlanning,
                periodConfigs:
                  diveCenterResume.settingsPlanning.periodConfigs.map((c) =>
                    c._id === updatedPeriodConfig._id ? updatedPeriodConfig : c,
                  ),
              }
            : // create | clone
              {
                ...diveCenterResume.settingsPlanning,
                periodConfigs:
                  diveCenterResume.settingsPlanning.periodConfigs.concat({
                    ...updatedPeriodConfig,
                    _id: uuidGenerator.random(),
                  }),
              };

        const ClubSettingsPlanningPatchOperations = jsonPatcher.compareObjects(
          diveCenterResume.settingsPlanning,
          updatedClubSettingsPlanning,
          {
            replaceDeleteByNullValue: true,
            attributesToReplaceFully: ['periodConfigs'],
          },
        );
        if (ClubSettingsPlanningPatchOperations.length) {
          const clubSettingsPlanningPatch: AppEntityUpdatePatch = {
            pk: updatedClubSettingsPlanning._id,
            patchOperations: ClubSettingsPlanningPatchOperations,
          };
          await clubMassiveUpdatorClient.update({
            clubSettingsPlanning: { updated: clubSettingsPlanningPatch },
          });
          back();
        } else {
          back();
        }
      },
      (err) => {
        console.log('xxx submitForm error', err);
      },
    )();
  }, [
    back,
    diveCenterResume.settingsPlanning,
    handleSubmit,
    initialPeriodConfig,
    mode,
  ]);

  return {
    mode,
    submitForm,
    back,
    form,
    weekDays,
    initialPeriodConfig,
  };
}

export type ClubSettingsPlanningEditPageLocalState = ReturnType<
  typeof useClubSettingsPlanningEditPageLocalState
>;
