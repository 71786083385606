/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import {
  AppInputColorRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl';
import { useBookingAgencyLocationTypeOptions } from 'src/pages/_components/options';
import { BookingAgencyEditPageLocalState } from '../useBookingAgencyEditPageLocalState.hook';

export const BookingAgencyEditForm = ({
  localState,
}: {
  localState: BookingAgencyEditPageLocalState;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const [name, color, colorText] = useWatch({
    control,
    name: ['name', 'details.color', 'details.colorText'],
  });

  const locationTypeOptions = useBookingAgencyLocationTypeOptions();

  return (
    <div className="px-5">
      <h2 className="text-left text-sm sm:text-lg leading-6 py-2 font-medium text-app-blue uppercase">
        Agence{' '}
        <span className="font-bold text-app-orange">
          {name?.trim()?.length > 0 && `"${name.toUpperCase()}"`}
        </span>
        <AppIconsSettings.customers.agencies
          className="ml-2 inline w-6 h-4 border border-gray-600 py-px px-pt"
          style={{
            background: color,
            color: colorText,
          }}
        />
      </h2>
      <div className="w-full flex flex-col gap-4">
        <div
          className={
            'w-full flex flex-col sm:grid-cols-2 md:col-span-4 sm:grid lg:grid-cols-4 gap-4'
          }
        >
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Nom"
            control={control}
            name={'name'}
            required={true}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          <div className="flex gap-1">
            <AppFormControlRHF_Deprecated
              className={'w-full'}
              label={'Couleur Fond'}
              required={true}
              control={control}
              name={'details.color'}
              renderComponent={(props) => (
                <AppInputColorRHF
                  {...props}
                  required={false}
                  className={'my-2 text-center text-xl font-bold'}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              className={'w-full'}
              label={'Couleur icône'}
              required={true}
              control={control}
              name={'details.colorText'}
              renderComponent={(props) => (
                <AppInputColorRHF
                  {...props}
                  required={false}
                  className={'my-2 text-center text-xl font-bold'}
                />
              )}
            />
          </div>
          <AppFormControlRHF_Deprecated
            className={clsx('w-full')}
            label="Taux commission par défaut (%)"
            required={true}
            control={control}
            name={'details.defaultCommissionRate'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF_Deprecated
            label="Localisation"
            control={control}
            name={'details.locationType'}
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={locationTypeOptions}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="sm:col-span-2"
            label={'Site Internet'}
            control={control}
            name="details.links.webSite.url"
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
        </div>
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Commentaire"
          control={control}
          name={'details.comment'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      </div>
    </div>
  );
};
