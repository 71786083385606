import { CommerceBookingDepositForListGql_Company } from '@mabadive/app-common-model';
import {
  AppDurationParserString,
  HASURA_DATE_ONLY_FORMAT,
  HASURA_DATE_TIME_WITH_MS_FORMAT,
  dateService,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  AppFetchDataCacheStore,
  appFetchDataCacheStoreBuilder,
} from 'src/business/_core/modules/root/app-cache/AppFetchDataCacheStore';
import { useAppFetchDataListEntitiesWithCache } from 'src/business/_core/modules/root/app-cache/AppFetchDataCacheStore/useAppFetchDataListEntitiesWithCache.hook';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { MQueryDescription, graphqlClient } from '../../../_common-browser';
import { appLogger } from '../../../business/_core/modules/root/logger';
import {
  DepositsListPageFetchCriteria,
  DepositsListPageFetchCriteriaFull,
} from './model';

export const clubDepositsCacheStore: AppFetchDataCacheStore<
  DepositsListPageFetchCriteriaFull,
  CommerceBookingDepositForListGql_Company[]
> = appFetchDataCacheStoreBuilder.buildCache({
  baseKey: 'club-deposits',
  cacheValidity: '2 days',
});

export function useFetchDepositsWithCache(
  criteria: DepositsListPageFetchCriteria,
  options: {
    autoRefetchInterval: AppDurationParserString;
    staleInterval: AppDurationParserString;
  },
) {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const clubReference = diveCenterResume.clubReference;

  const fetchCriteria: DepositsListPageFetchCriteriaFull = useMemo(() => {
    return {
      ...criteria,
      clubReference,
      diveCenterId,
    };
  }, [clubReference, criteria, diveCenterId]);

  const cacheState =
    useAppFetchDataListEntitiesWithCache<CommerceBookingDepositForListGql_Company>(
      fetchCriteria,
      {
        store: clubDepositsCacheStore,
        ...options,
        fetch: async (
          fetchCriteria,
          { cacheState, fetchType },
        ): Promise<CommerceBookingDepositForListGql_Company[]> => {
          // NOTE: maxResults ne doit pas être dans les criteria, sinon il va impacter la clé du cache

          return await findMany(
            {
              ...fetchCriteria,
              maxResults: 1000,
              updatedAfter:
                fetchType === 'partial'
                  ? cacheState?.fetchResult?.dataModifiedAt
                  : undefined,
            },
            { type: 'query' },
          ).toPromise();
        },
        getUpdateDate: (item: CommerceBookingDepositForListGql_Company) =>
          item._updatedAt,
      },
    );

  return cacheState;
}

function findMany(
  criteria: DepositsListPageFetchCriteriaFull,
  {
    type,
    name = 'fetchDepositsForList',
  }: {
    type: 'subscription' | 'query';
    name?: string;
  },
): Observable<CommerceBookingDepositForListGql_Company[]> {
  const query: MQueryDescription<any> =
    buildBookingDepositForListGraphqlQuery(criteria);

  return graphqlClient.query
    .runOne<CommerceBookingDepositForListGql_Company[]>(query, {
      type,
      name,
    })
    .pipe(
      tap((res) => {
        appLogger.info(
          '[useFetchDepositsWithCache.findMany] results:',
          res.length,
        );
      }),
      catchError((err) => {
        appLogger.warn('[useFetchDepositsWithCache] error fetching data', err);
        return throwError(err);
      }),
    );
}

export function buildBookingDepositForListGraphqlQuery({
  clubReference,
  diveCenterId,
  beginDate,
  endDate,
  depositStates,
  purchasePaymentPending,
  updatedAfter,
  maxResults,
}: DepositsListPageFetchCriteriaFull) {
  const whereAndClauses: string[] = [];

  whereAndClauses.push(`clubReference: {_eq: "${clubReference}"}`);
  if (diveCenterId) {
    // utilisé sur la liste, mais pas sur l'onglet des paiements de la résa
    whereAndClauses.push(`diveCenterId: {_eq: "${diveCenterId}"}`);
  }

  if (beginDate || endDate) {
    const paymentDateWhereClauses: string[] = [];

    if (beginDate) {
      const beginDateString = dateService.formatUTC(
        beginDate,
        HASURA_DATE_ONLY_FORMAT,
      );
      paymentDateWhereClauses.push(`_gte: "${beginDateString}"`);
    }
    if (endDate) {
      const endDateString = dateService.formatUTC(
        endDate,
        HASURA_DATE_ONLY_FORMAT,
      );
      paymentDateWhereClauses.push(`_lte: "${endDateString}"`);
    }
    whereAndClauses.push(
      `depositDate: {
        ${paymentDateWhereClauses.join(',')}
      }`,
    );
  }
  if (updatedAfter) {
    const updatedAfterString = dateService.formatUTC(
      updatedAfter,
      HASURA_DATE_TIME_WITH_MS_FORMAT,
    );

    whereAndClauses.push(`_updatedAt: {_gt: "${updatedAfterString}"}`);
  }

  // if (depositState) {
  //   whereAndClauses.push(`depositState: {_eq: "${depositState}"}`);
  // }
  if (depositStates?.length > 0) {
    whereAndClauses.push(
      `depositState: {_in: ${JSON.stringify(depositStates)}}`,
    );
  }
  if (purchasePaymentPending) {
    whereAndClauses.push(
      `purchasePaymentPending: {_eq: "${purchasePaymentPending}"}`,
    );
  }

  const where = `{_and: {
    ${whereAndClauses.join(',')}
  }}`;

  const queryDescription: MQueryDescription<CommerceBookingDepositForListGql_Company> =
    {
      returnName: 'CommerceBookingDepositForListGql_Company',
      queryName: 'club_commerce_booking_deposit',
      returnType: 'all',
      where,
      orderBy: '{depositDate: desc, _updatedAt: desc, _createdAt: desc}',
      returnAttributes: CommerceBookingDepositForListGql_Company,
      limit: maxResults,
    };

  return queryDescription;
}
