import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppToggleButtonGroupProps } from './AppToggleButtonGroupProps.type';

export function AppToogleButtonGroupTailwind<
  T extends string | number | boolean,
>({
  fullWidthMobile,
  optionClassName,
  customClassName,
  selected,
  onChanges,
  items,
  className,
  color,
  size = 'lg',
  theme,
  allowUnset,
}: AppToggleButtonGroupProps<T>) {
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  return (
    <RadioGroup
      value={selected}
      onChange={(item) => {
        const newValue = (item as any)?.value;

        if (allowUnset && newValue === selected) {
          onChanges(null);
          return;
        } else {
          onChanges(newValue);
        }
      }}
      className={`mx-0.5 mt-2 ${className}`}
    >
      <RadioGroup.Label className="sr-only">
        Choose a memory option
      </RadioGroup.Label>
      <div
        className={clsx(
          'gap-x-3 gap-y-1',
          customClassName ??
            (items.length === 2
              ? 'grid grid-cols-2'
              : 'flex flex-col sm:grid sm:grid-cols-3'),
        )}
      >
        {items.map((option, i) => (
          <RadioGroup.Option
            key={i}
            value={option}
            className={({ active, checked }) =>
              clsx(
                optionClassName,
                !option.disabled
                  ? 'cursor-pointer focus:outline-none'
                  : 'opacity-25 cursor-not-allowed',
                active
                  ? `ring-2 ring-offset-2 ${
                      color === 'boolean'
                        ? option.value === true
                          ? 'ring-green-500'
                          : 'ring-red-500'
                        : color === 'blue'
                        ? 'ring-blue-500'
                        : 'ring-green-500'
                    }`
                  : '',
                option.value === selected
                  ? color === 'boolean'
                    ? option.value === true
                      ? 'bg-green-500 border-transparent text-white hover:bg-green-600'
                      : 'bg-red-500 border-transparent text-white hover:bg-red-600'
                    : color === 'blue'
                    ? 'bg-blue-500 border-transparent text-white hover:bg-blue-600'
                    : 'bg-green-500 border-transparent text-white hover:bg-green-600'
                  : 'bg-white border-gray-400 text-gray-600 hover:bg-gray-50',
                'border rounded-md  flex items-center justify-center font-medium uppercase sm:flex-1',
                size === 'lg'
                  ? 'py-3 px-3 text-sm'
                  : 'py-2 px-1 sm:px-2 text-xs',
              )
            }
            disabled={option.disabled}
            style={
              option.color && {
                borderColor: option.color,
              }
            }
          >
            <RadioGroup.Label as="span">
              <span className="inline-flex gap-2 items-center">
                {option.icon && (
                  <span
                    className=" border flex items-center py-px px-pt"
                    style={{
                      background: '#fff',
                      color: option.color,
                      borderColor: option.color,
                    }}
                  >
                    <option.icon
                      className={size === 'lg' ? 'h-4 w-4' : 'h-3 w-3'}
                    />
                  </span>
                )}
                {option.color ? (
                  <span
                    className="px-1 font-bold truncate uppercase flex items-center"
                    style={{
                      color: option.color,
                    }}
                  >
                    {option.label}
                  </span>
                ) : (
                  option.label
                )}
              </span>
            </RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
