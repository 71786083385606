/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  BookingProduct,
  BookingSessionParticipant,
  ClubParticipant,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { appWebLogger } from 'src/lib/browser';
import {
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResult,
} from '../../../../../models';
import { bookingMemberChangesBuilder } from './booking-member/bookingMemberChangesBuilder.service';
import { bookingProductChangesBuilder } from './booking-product';
import { clubDiverChangesBuilder } from './diver';
import { participantFormParser } from './participant';
export const bookingParticipantEditorDialogChangesBuilderEditParticipant = {
  buildChangesResult,
};

function buildChangesResult({
  clubReference,
  logContext,
  participantData,
  hasFormChanges,
  formValue,
  initialFormValue,
}: {
  clubReference: string;
  logContext: string;
  participantData?: BookingParticipantEditorParticipant;
  hasFormChanges: boolean;
  formValue: BookingParticipantEditorFormModel;
  initialFormValue: BookingParticipantEditorFormModel;
}): {
  hasChanges: boolean;
  result?: BookingParticipantEditorResult;
} {
  if (!hasFormChanges) {
    return {
      hasChanges: false,
    };
  }
  const bookingId = participantData?.bookingId;
  const clubParticipantId = participantData?.clubParticipant?._id;
  const diveSession = participantData?.diveSession;
  const sessionsCount = diveSession?.sessionsCount;

  const payload = clubMassiveUpdatorClient.createEmptyPayload({
    logContext: 'edit participant',
    bookingIdsToClean: [bookingId],
  });

  const result: BookingParticipantEditorResult = {
    bookingId: bookingId,
    initialDiver: participantData.diver,
    changes: payload,
    aggregated: {},
    bookingMemberId: participantData?.bookingMember?._id,
  };

  const initialParticipant: ClubParticipant = participantData?.clubParticipant;
  if (!initialParticipant) {
    appWebLogger.captureMessage('can not update participant: data not loaded', {
      clubReference,
      logContext: `bookingParticipantEditorDialogChangesBuilderEditParticipant / ${logContext}`,
      extra: {
        bookingId,
        diverId: participantData?.diver?._id,
        formValue,
        participantData,
      },
    });
    return {
      hasChanges: false,
    };
  }
  const initialBookingSessionParticipant: BookingSessionParticipant =
    participantData?.bookingSessionParticipant;

  const { finalDiver } = clubDiverChangesBuilder.buildClubDiverChanges({
    formValue,
    participantData,
    result,
  });

  const {
    clubParticipant: clubParticipantFromForm,
    bookingSessionParticipant: bookingSessionParticipantFromForm,
  } = participantFormParser.parseFormValueClubParticipant({
    formValue,
    existingParticipant: participantData?.clubParticipant,
    sessionsCount,
  });

  const { finalParticipant } = buildClubParticipantChanges({
    initialParticipant,
    clubParticipantFromForm,
    result,
    clubParticipantId,
  });

  // TODO: il faudrait retirer de cette page la partie mise à jour du booking status,
  // afin de mutualiser le traitement avec "participantBookingStateUpdator.generateBookingStatusUpdatePatchs"
  buildBookingProductChanges(participantData, finalParticipant, result);

  buildBookingSessionParticipantChanges(
    initialBookingSessionParticipant,
    bookingSessionParticipantFromForm,
    result,
    participantData,
  );
  bookingMemberChangesBuilder.buildBookingMemberChanges({
    finalDiver,
    participantData,
    result,
    formValue,
    initialFormValue,
  });

  return { hasChanges: true, result };
}

function buildBookingSessionParticipantChanges(
  initialBookingSessionParticipant: BookingSessionParticipant,
  bookingSessionParticipantFromForm: Partial<BookingSessionParticipant>,
  result: BookingParticipantEditorResult,
  participantData: BookingParticipantEditorParticipant,
) {
  const finalBookingSessionParticipant: BookingSessionParticipant = {
    ...initialBookingSessionParticipant,
    ...bookingSessionParticipantFromForm,
  };
  result.aggregated.finalBookingSessionParticipant =
    finalBookingSessionParticipant;
  {
    const patchOperations = jsonPatcher.compareObjects(
      initialBookingSessionParticipant,
      finalBookingSessionParticipant,
      {
        // else, value won't be deleted by typeorm
        // https://github.com/typeorm/typeorm/issues/2934
        replaceDeleteByNullValue: true,
      },
    );

    if (patchOperations.length) {
      result.changes.updatedBookingSessionParticipants.push({
        pk: participantData.bookingSessionParticipant?._id,
        patchOperations,
      });
    }
  }
}

function buildBookingProductChanges(
  participantData: BookingParticipantEditorParticipant,
  finalParticipant: ClubParticipant,
  result: BookingParticipantEditorResult,
) {
  const initialBookingProduct: BookingProduct = participantData?.bookingProduct;

  // updated values
  const finalDiveBookingProduct: BookingProduct =
    bookingProductChangesBuilder.updateBookingProductFromParticipant({
      initialBookingProduct,
      finalParticipant,
    });
  result.aggregated.finalBookingProduct = finalDiveBookingProduct;
  const patchOperations = jsonPatcher.compareObjects(
    initialBookingProduct,
    finalDiveBookingProduct,
    {
      // else, value won't be deleted by typeorm
      // https://github.com/typeorm/typeorm/issues/2934
      replaceDeleteByNullValue: true,
    },
  );

  if (patchOperations.length) {
    result.changes.updatedBookingProducts.push({
      pk: initialBookingProduct._id,
      patchOperations,
    });
  }
}

function buildClubParticipantChanges({
  initialParticipant,
  clubParticipantFromForm,
  result,
  clubParticipantId,
}: {
  initialParticipant: ClubParticipant;
  clubParticipantFromForm: Partial<ClubParticipant>;
  result: BookingParticipantEditorResult;
  clubParticipantId: string;
}) {
  const finalParticipant: ClubParticipant = {
    ...initialParticipant,
    ...clubParticipantFromForm,
  };

  result.aggregated.finalClubParticipant = finalParticipant;
  {
    const patchOperations = jsonPatcher.compareObjects(
      initialParticipant,
      finalParticipant,
      {
        // else, value won't be deleted by typeorm
        // https://github.com/typeorm/typeorm/issues/2934
        replaceDeleteByNullValue: true,
        attributesToReplaceFully: ['details', 'autoSupervisedDetails'],
      },
    );

    if (patchOperations.length) {
      result.changes.updatedClubParticipants.push({
        pk: clubParticipantId,
        patchOperations,
      });
    }
  }
  return { finalParticipant };
}
