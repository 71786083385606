/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingDeposit } from '@mabadive/app-common-model';
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useAutoFill } from 'src/business/_core/data/hooks';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AppFormControlRHF_Deprecated,
  AppToogleButtonGroupRHF,
} from 'src/lib/form';
import {
  useAppPaymentMethodsOptions,
  useAppPaymentPlatformOptions,
  useBookingDepositOptions,
} from 'src/pages/_components/options';
import {
  DiverPurchasePaymentEditorPackagesTable,
  DiverPurchasePaymentEditorTotalToPay,
} from '../components';
import { DiverPurchasePaymentEditorDialogLocalState } from '../useDiverPurchasePaymentEditorDialogLocalState';
import { useEditPaymentNavigation } from '../useEditPaymentNavigation.hook';
import { editPaymentSelectPurchasesPanelAutoFixer } from './editPaymentSelectPurchasesPanelAutoFixer.service';

export const EditPaymentSelectPurchasesPanel = ({
  localState,
}: {
  localState: DiverPurchasePaymentEditorDialogLocalState;
}) => {
  const autoFill = useAutoFill();

  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const paymentsSettings = clubSettings?.general?.payments;
  const { goBack, goNext } = useEditPaymentNavigation({
    localState,
  });

  const { form, inputState, paymentChangesModel, actions, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [onlinePlatformId, bookingDepositId] = useWatch({
    control,
    name: ['onlinePlatformId', 'bookingDepositId'],
  });

  const paymentMethodOptions = useAppPaymentMethodsOptions();
  const allPaymentPlatformOptions = useAppPaymentPlatformOptions({
    format: 'simple',
    filter: 'active',
  });

  const activeBookingDeposits = useMemo(
    () =>
      (localState.inputState.bookingDepositsWithPayments ?? [])
        .filter((x) =>
          (data.linkedData?.diverIdsBookingLoaded ?? []).includes(x.diver?._id),
        )
        .map((x) => x.bookingDeposit)
        .filter((x) => {
          if (x.purchasePaymentStatus === 'done' && !x.purchasePaymentPending) {
            if (inputState.mode === 'create') {
              return x.creditRemaining > 0;
            }
            return (
              x.creditRemaining > 0 ||
              x._id === inputState.purchasePayment?.bookingDepositId
            );
          }
          return false;
        }),
    [
      data.linkedData?.diverIdsBookingLoaded,
      inputState.mode,
      inputState.purchasePayment?.bookingDepositId,
      localState.inputState.bookingDepositsWithPayments,
    ],
  );

  const activeBookingDepositOptions: ValueLabel<string, ReactNode>[] =
    useBookingDepositOptions({
      bookingDeposits: activeBookingDeposits,
      divers: localState.data.divers,
    });

  // const activeBookingDepositOptions: ValueLabel<string>[] = useMemo(() => {
  //   // pour le moment, une seule option possible, mais on pourrait en avoir plusieurs, et la choisir sur cet écran
  //   return (activeBookingDeposits ?? []).map((x) => {
  //     return {
  //       value: x._id,
  //       label: 'Acompte ' + x.creditRemaining,
  //       disabled: false,
  //     };
  //   });
  // }, [activeBookingDeposits]);

  const activePaymentPlatformOptions = useMemo(() => {
    if (
      paymentsSettings?.online?.platformsEnabled &&
      paymentsSettings?.online?.createPayment?.enabled
    ) {
      // pour le moment, une seule option possible, mais on pourrait en avoir plusieurs, et la choisir sur cet écran
      return allPaymentPlatformOptions.filter(
        (x) => !x.disabled && x.value === paymentsSettings?.online?.platformId,
      );
    }
    return [];
  }, [
    allPaymentPlatformOptions,
    paymentsSettings?.online?.createPayment?.enabled,
    paymentsSettings?.online?.platformId,
    paymentsSettings?.online?.platformsEnabled,
  ]);

  const autoFixBookingDepositPayments = useCallback(
    ({ bookingDeposit }: { bookingDeposit: BookingDeposit }) => {
      editPaymentSelectPurchasesPanelAutoFixer.autoFixBookingDepositPayments({
        bookingDeposit,
        paymentChangesModel,
        setValue,
      });
    },
    [paymentChangesModel, setValue],
  );

  return (
    <>
      <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
        <span>Prestations à payer</span>
        <span className="hidden md-inline">
          {' '}
          -{' '}
          {nameFormatter.formatFullName(localState.data.selectedDiver, {
            format: 'firstName-first',
          })}
        </span>
      </h3>
      <div className="max-w-7xl mt-5 mx-1 relative">
        <div className="grid gap-4">
          <div>
            <div className="max-h-[35vh] md:max-h-[50vh] overflow-y-auto">
              <DiverPurchasePaymentEditorPackagesTable
                mode={inputState.mode}
                form={form}
                paymentChangesModel={paymentChangesModel}
                isEditEnabled={
                  inputState.mode === 'create' && !bookingDepositId
                }
              />
            </div>
            <DiverPurchasePaymentEditorTotalToPay
              form={form}
              paymentChangesModel={paymentChangesModel}
            />
          </div>
          {inputState.mode === 'create' &&
            activePaymentPlatformOptions?.length > 0 && (
              <div>
                <h3 className="uppercase text-base sm:text-lg text-app-blue font-bold">
                  Paiement en ligne
                </h3>

                <AppFormControlRHF_Deprecated
                  className={'w-full'}
                  control={control}
                  name="onlinePlatformId"
                  disabled={inputState.mode !== 'create'}
                  renderComponent={(props) => (
                    <AppToogleButtonGroupRHF
                      {...props}
                      color="blue"
                      theme="strong"
                      customClassName="grid sm:grid-cols-2"
                      items={activePaymentPlatformOptions}
                      onChanges={(value) => {
                        if (value && form.formState.isValid) {
                          setValue('paymentMethod', 'stripe');
                          setValue('paymentState', 'pending');
                          setValue('paymentDate', null);
                          setValue(
                            'dueDate',
                            dateService.add(
                              dateService.getUTCDateSetTime(new Date()),
                              3,
                              'day',
                            ),
                          );
                          goNext();
                          goNext();
                        }
                      }}
                    />
                  )}
                />
              </div>
            )}
          {inputState.mode === 'create' &&
            activeBookingDepositOptions?.length > 0 && (
              <div>
                <h3 className="uppercase text-base sm:text-lg text-app-blue font-bold">
                  Paiement par acompte
                </h3>
                <AppFormControlRHF_Deprecated
                  className={'w-full'}
                  control={control}
                  name="bookingDepositId"
                  disabled={inputState.mode !== 'create'}
                  renderComponent={(props) => (
                    <AppToogleButtonGroupRHF
                      {...props}
                      color="blue"
                      theme="strong"
                      customClassName="grid sm:grid-cols-2"
                      items={activeBookingDepositOptions}
                      onChanges={(bookingDepositId) => {
                        if (
                          bookingDepositId &&
                          form.formState.isValid &&
                          inputState.mode === 'create'
                        ) {
                          const bookingDeposit = activeBookingDeposits.find(
                            (x) => x._id === bookingDepositId,
                          );
                          setValue('onlinePlatformId', undefined);
                          setValue('paymentMethod', 'booking-deposit');
                          setValue('paymentState', 'validated');
                          setValue('bookingDepositId', bookingDepositId);
                          setValue('paymentDate', new Date());
                          setValue('dueDate', undefined);
                          if (
                            bookingDeposit.creditRemaining <
                            paymentChangesModel.totalToPay
                          ) {
                            autoFixBookingDepositPayments({
                              bookingDeposit,
                            });
                          }
                          goNext();
                        }
                      }}
                    />
                  )}
                />
              </div>
            )}
          {!bookingDepositId &&
            (inputState.mode === 'create' || !onlinePlatformId) &&
            paymentMethodOptions?.length > 0 && (
              <div>
                <h3 className="uppercase text-base sm:text-lg text-app-blue font-bold">
                  Moyen de paiement
                </h3>

                <AppFormControlRHF_Deprecated
                  className={'w-full'}
                  control={control}
                  name="paymentMethod"
                  renderComponent={(props) => (
                    <AppToogleButtonGroupRHF
                      {...props}
                      color="green"
                      theme="strong"
                      customClassName="grid sm:grid-cols-2"
                      items={paymentMethodOptions}
                      onChanges={(value) => {
                        if (value && form.formState.isValid) {
                          setValue('onlinePlatformId', undefined);
                          goNext();
                        }
                      }}
                    />
                  )}
                />
              </div>
            )}
        </div>
      </div>
    </>
  );
};
