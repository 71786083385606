/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useWatch } from 'react-hook-form';
import {
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';
import { DECOP_SessionItemDive_Group } from './DECOP_SessionItemDive_Group';
import { DECOP_SessionItemDive_Title } from './DECOP_SessionItemDive_Title';

export const DECOP_SessionItemDive = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  sessionItemIndex,
  sessionItemDiveIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  sessionItemIndex: number;
  sessionItemDiveIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}.items.${sessionItemIndex}.items.${sessionItemDiveIndex}` as const;

  const [sessionDiveId] = useWatch({
    control,
    name: [`${baseFormName}.id`],
  });

  const securityUser = useAppSecurityUser();

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  const mainCurrency = useAppCurrencyMain();
  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;
  const clubSettings = clubResume.clubSettings;

  return (
    <div className="border-l-2 border-gray-200 pl-4">
      {sessionDiveId === 'title' ? (
        <DECOP_SessionItemDive_Title
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
          contentItemIndex={contentItemIndex}
          sessionItemIndex={sessionItemIndex}
          sessionItemDiveIndex={sessionItemDiveIndex}
        />
      ) : sessionDiveId === 'dive-groups' ? (
        <DECOP_SessionItemDive_Group
          localState={localState}
          dailyResumeIndex={dailyResumeIndex}
          sheetIndex={sheetIndex}
          contentItemIndex={contentItemIndex}
          sessionItemIndex={sessionItemIndex}
          sessionItemDiveIndex={sessionItemDiveIndex}
        />
      ) : (
        <div className="my-2">{` DECOP_SessionItemDive ID: ${sessionDiveId}`}</div>
      )}
    </div>
  );
};
