import { ClubDiverFull } from '@mabadive/app-common-model';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import {
  appLoader,
  appLoaderChain,
  LoadableContentPartial,
} from '../../app-loading';
import { clubDiverFullGraphqlFetcher } from './graphql/clubDiverFullGraphqlFetcher.service';
import { clubDiverStore } from './store/clubDiverStore';

export const clubDiverFullRepository = {
  findOne,
  getTotalCount,
};

function getTotalCount(): Observable<number> {
  return clubDiverStore.loadingState.get().pipe(
    map((loadingState) => loadingState.loaded.totalCount),
    distinctUntilChanged(),
  );
}

function findOne(
  { diverId }: { diverId: string },
  {
    forceReload,
  }: {
    forceReload: boolean;
  },
): Observable<LoadableContentPartial<ClubDiverFull>> {
  const localLoader$ = appLoader.load(_findLocal({ diverId }), {
    type: forceReload ? 'partial' : 'full',
  });
  const remoteLoader$ = appLoader.load(_findRemote({ diverId }), {
    type: 'full',
  });

  return appLoaderChain.loadChain([localLoader$, remoteLoader$]);
}

function _findLocal({
  diverId,
}: {
  diverId: string;
}): Observable<ClubDiverFull> {
  return clubDiverStore.clubDiverFullCollection.getOne({
    criteria: {
      _id: diverId,
    },
  });
}

function _findRemote({
  diverId,
}: {
  diverId: string;
}): Observable<ClubDiverFull> {
  return clubDiverFullGraphqlFetcher.findOne({ diverId }).pipe(
    tap((diver) => {
      if (diver) {
        clubDiverStore.addToStore(diver);
      }
    }),
  );
}
