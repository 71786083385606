export const clubSettingsOnlineBookingPageTabLabelFormatter = {
  format,
};

export type ClubSettingsOnlineBookingPageTabId =
  | 'products'
  | 'config'
  | 'emails'
  | 'links'
  | 'legal-information';

function format(tabId: ClubSettingsOnlineBookingPageTabId): string {
  switch (tabId) {
    case 'products':
      return 'Catalogue';
    case 'config':
      return 'Configuration';
    case 'emails':
      return 'Emails';
    case 'links':
      return 'Liens et code';
    case 'legal-information':
      return 'Mentions légales';
  }
}
