import {
  AppCompanyMessageTemplate,
  languageFlagConvertor,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppButtonMultiActions } from 'src/business/_core/modules/layout';
import {
  AppHeroIcons,
  AppIconsSocialMedia,
} from 'src/business/_core/modules/layout/icons';
import { useClubSettingsMessageTargets } from 'src/business/club/data/hooks';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubSettingsCommunicationPageLocalState } from '../../../useClubSettingsCommunicationPageLocalState.hook';
import { AutomaticMailConfigForTemplateList } from '../AppCompanyMessageTemplateEditDialog/form/AutomaticMailConfigForTemplateList';
import { useAutomaticMailConfigForTemplate } from '../AppCompanyMessageTemplateEditDialog/form/useAutomaticMailConfigForTemplate.hook';
import { useClubSettingsCommunicationMessageTemplateCardMenuItems } from './useClubSettingsCommunicationMessageTemplateCardMenuItems.hook';

export const ClubSettingsCommunicationMessageTemplateCard = ({
  localState,
  messageTemplate: mt,
  className,
}: {
  localState: ClubSettingsCommunicationPageLocalState;
  messageTemplate: AppCompanyMessageTemplate;
  className?: string;
}) => {
  const msg = useClubSettingsMessageTargets({
    context: mt.context as 'booking' | 'session',
  });

  const firstContent = [
    mt.smsContent,
    mt.whatsAppContent,
    mt.emailContent,
  ].filter((x) => x?.enabled)[0];

  const firstMessage = firstContent?.message;

  const { state, dialogs } = localState;

  const Icon =
    mt.style === 'success'
      ? AppHeroIcons.actionConfirm
      : mt.style === 'warning'
      ? AppHeroIcons.warn
      : AppHeroIcons.actionCancel;

  const hamburgerMenuItems: AppHamburgerMenuItem[] =
    useClubSettingsCommunicationMessageTemplateCardMenuItems({
      localState,
      messageTemplate: mt,
    });

  const { enabledCount } = useAutomaticMailConfigForTemplate(mt._id);

  const isDisabled = (!mt.enabled || mt.archived) && enabledCount === 0;

  return (
    <div
      className={clsx(
        'block group',
        state.editEnabled && 'cursor-pointer hover:bg-gray-50',
      )}
      onClick={() => {
        state.editEnabled && dialogs.messageTemplate.openEdit(mt);
      }}
    >
      <div className="w-full flex flex-col sm:flex-row gap-y-2 gap-x-4 items-start py-1">
        {/* <div className="flex-shrink-0">
          <AppHeroIcons.actionEditAlt className="text-gray-200 group-hover:text-gray-300 h-10 w-10" />
        </div> */}
        <div className={clsx('w-full flex-grow', isDisabled && 'opacity-30')}>
          <div className="flex flex-col md:flex-row gap-x-4 md:items-center md:justify-between">
            <p className="truncate text-sm font-medium text-gray-700 uppercase">
              <Icon
                className={clsx(
                  'inline-block mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400',
                  mt.style === 'success'
                    ? 'text-status-success'
                    : mt.style === 'warning'
                    ? 'text-status-warn'
                    : 'text-status-error',
                )}
              />
              {mt.name} {languageFlagConvertor.getFlag(mt?.sourceLanguageCode)}
            </p>
            <div className="mt-1 flex gap-2 text-app-xxs md:text-xs font-bold uppercase opacity-80">
              {msg.email.enabled && (
                <div
                  className={clsx(
                    'rounded bg-app-email text-white py-0.5 px-1 whitespace-nowrap flex gap-2 items-center',

                    !mt.emailContent?.enabled && 'invisible',
                  )}
                >
                  <AppIconsSocialMedia.mail className="inline w-3.5 h-3.5" />
                  EMAIL
                </div>
              )}
              {msg.sms.enabled && (
                <div
                  className={clsx(
                    'rounded bg-app-sms text-white py-0.5 px-1 whitespace-nowrap flex gap-2 items-center',
                    !mt.smsContent?.enabled && 'invisible',
                  )}
                >
                  <AppIconsSocialMedia.sms className="inline w-4 h-4" />
                  SMS
                </div>
              )}
              {msg.whatsApp.enabled && (
                <div
                  className={clsx(
                    'rounded bg-app-whatsapp text-white py-0.5 px-1 whitespace-nowrap flex gap-2 items-center',
                    !mt.whatsAppContent?.enabled && 'invisible',
                  )}
                >
                  <AppIconsSocialMedia.whatsapp className="inline w-3.5 h-3.5" />
                  WhatsApp
                </div>
              )}
            </div>
          </div>
          <div className="w-full hidden md:block my-1">
            {firstMessage?.body?.trim().length > 0 && (
              <div className="text-sm text-gray-500 italic">
                {firstMessage?.body}
              </div>
            )}
          </div>
          <AutomaticMailConfigForTemplateList
            messageTemplateId={mt._id}
            className="mt-2 text-xs"
          />
        </div>
        {state.editEnabled && (
          <div className="text-gray-600 self-end sm:self-start">
            <AppButtonMultiActions items={hamburgerMenuItems} />
          </div>
        )}
      </div>
    </div>
  );
};
