/* eslint-disable @typescript-eslint/no-unused-vars */
import { arrayBuilder } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { uiStore } from 'src/business/_core/store';
import { sessionMassiveActionClient } from '../BookingParticipantDialog/BookingParticipantViewDialog/services/sessionMassiveActionClient.service';
import { DiveSessionEditorMassiveFormModel } from './_model';
import { DiveSessionEditorMassiveDialogLocalState } from './useDiveSessionEditorMassiveDialogLocalState.hook';

export function useDiveSessionEditorMassiveDialogSubmit({
  localState,
}: {
  localState: DiveSessionEditorMassiveDialogLocalState;
}) {
  const { form, inputState, data } = localState;
  const {
    setOperationInProgressMessage,
    participantsCheckState,
    diveCenterResume,
  } = data;

  const { handleSubmit, control } = form;

  const [selectedSessionDef] = useWatch({
    control,
    name: ['selectedSessionDef'],
  });

  return useCallback(async () => {
    try {
      return handleSubmit(
        async (formValue: DiveSessionEditorMassiveFormModel) => {
          const { onConfirm, closeDialog } = inputState;

          if (localState.hasFormChanges) {
            setOperationInProgressMessage('Enregistrement en cours');
            const sourceParticipantsIds =
              participantsCheckState.participantsCheckedIds;

            const sourceParticipants =
              inputState.initialState.originalParticipants.filter((x) =>
                sourceParticipantsIds.includes(x._id),
              );

            const bookingSessionParticipants =
              inputState.initialState.originalBookingSessionParticipants.filter(
                (x) => sourceParticipantsIds.includes(x.participantId),
              );

            const sourceSessionGroupsIds: string[] =
              arrayBuilder.filterDuplicated(
                sourceParticipants
                  .map((x) => x.diveSessionGroupId)
                  .filter((x) => !!x),
              );

            const bookingsIds: string[] = arrayBuilder.filterDuplicated(
              bookingSessionParticipants
                .map((x) => x.bookingId)
                .filter((x) => !!x),
            );

            await sessionMassiveActionClient
              .persistSessionMassiveAction({
                actionId: formValue.actionId,
                sourceParticipantsIds,
                sourceSession: inputState.initialState.originalSessionFull,
                sourceSessionGroupsIds,
                cloneGroups: formValue.cloneParticipantsGroups,
                cloneGuidesAndInstructors: formValue.cloneGuidesAndInstructors,
                targetSession: selectedSessionDef,
                bookingsIds,
                clubReference: diveCenterResume.clubReference,
                diveCenterId: diveCenterResume._id,
                logContext: 'Session massive action',
              })
              .then(() => {
                return onConfirm({
                  formValue,
                });
              })
              .catch((err) => {
                setOperationInProgressMessage(undefined);

                uiStore.snackbarMessage.set({
                  type: 'error',
                  content:
                    'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
                });
              });
          } else {
            return closeDialog();
          }
        },
        (err) => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
          throw err;
        },
      )();
    } catch (err) {
      // message already displayed
    }
  }, [
    diveCenterResume._id,
    diveCenterResume.clubReference,
    handleSubmit,
    inputState,
    localState,
    participantsCheckState.participantsCheckedIds,
    selectedSessionDef,
    setOperationInProgressMessage,
  ]);
}
