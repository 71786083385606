import {
  ClubDiveSite,
  DiveSession,
  DiveSessionResume,
} from '@mabadive/app-common-model';

export const diveSessionToDiveSessionResumeConvertor = {
  convert,
};

function convert(
  diveSession: DiveSession,
  {
    diveSites,
  }: { diveSites: Pick<ClubDiveSite, '_id' | 'name' | 'buoy' | 'ref'>[] },
): DiveSessionResume {
  return {
    ...diveSession,
    diveSite: diveSession.diveSiteId
      ? diveSites.find((ds) => ds._id === diveSession.diveSiteId)
      : undefined,
  };
}
