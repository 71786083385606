import { useEffect } from 'react';
import { uiStore } from 'src/business/_core/store';
import {
  PlanningViewConfig,
  PlanningViewConfigContextId,
} from '../../club-planning/_model';
export function useUpdateUiStoreFromPlanningConfig(
  planningConfig: PlanningViewConfig,
  { context }: { context: PlanningViewConfigContextId },
) {
  useEffect(() => {
    if (context) {
      // update store
      if (context === 'planning-view') {
        uiStore.planningViewParticipantsConfig.set(planningConfig);
      } else if (context === 'planning-staff') {
        uiStore.planningViewStaffConfig.set(planningConfig);
      } else if (context === 'planning-selector') {
        uiStore.planningSelectorConfig.set(planningConfig);
      }
    }
  }, [planningConfig, context]);
}
