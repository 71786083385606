/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import { clubDiveSessionThemeBuilder } from '@mabadive/app-common-services';
import { PopperPlacementType } from '@material-ui/core';
import React, { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  AppHamburgerMenu,
  AppHamburgerMenuItem,
} from 'src/business/club/modules/_common/ui';
import { PlanningViewConfig } from 'src/business/club/modules/club-planning/_model';
import {
  ClubDiveSessionHeaderParticipants,
  ClubDiveSessionHeaderSecurityStaff,
} from '../../../../club-dive-session/components';
import { DiveSessionParticipantsStats } from '../../../../club-dive-session/model';
import { ClubPlanningPanelSessionDisplayStyle } from '../../../model';
import { diveSessionStyleBuilder } from '../diveSessionStyleBuilder.service';
import { DiveSessionSimpleCardTotalGroupsAndStaffCounters } from '../session-simple-card';
import { DiveSessionCardCommonHeader } from './DiveSessionCardCommonHeader';

export const DiveSessionSecuritySheetCardHeader = ({
  selectionColor,
  planningConfig,
  session,
  display,
  diveSessionParticipantsStats,
  hamburgerMenuPosition,
  onClickSession,
  hamburgerMenuItems,
}: {
  selectionColor?: ClubPlanningPanelSessionDisplayStyle;
  planningConfig: PlanningViewConfig;
  session: ClubPlanningLightSessionDef;
  diveSessionParticipantsStats: DiveSessionParticipantsStats;
  display: {
    sessionIndex?: { index: number; total: number };
    tide: boolean;
    weather: boolean;
    comment: boolean;
  };
  hamburgerMenuPosition: PopperPlacementType;
  onClickSession?: (session: ClubPlanningLightSessionDef) => void;
  hamburgerMenuItems: AppHamburgerMenuItem[];
}) => {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;
  const staffMembers = diveCenterResume?.staffMembers;

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );

  const isMultiSession = !!session.diveTourSession2;
  const groupDiveSite1AndTitle = !isMultiSession && !session.diveSiteId;

  const sessionBorderStyle = diveSessionStyleBuilder.buildBorderStyleFromTime({
    isVirtual: false,
    time: session.time,
  });

  return (
    <div
      className={`cursor-pointer ring-opacity-30 ring-day-period-${timeDayPeriod}-dark hover:ring-2`}
    >
      {/* <ClubDiveSessionHeaderCard className="my-1" diveSession={session} /> */}
      <div
        className={'mb-2 text-sm text-gray-600 hover:bg-gray-50 relative'}
        onClick={() => {
          onClickSession(session);
        }}
      >
        <DiveSessionCardCommonHeader
          session={session}
          display={display}
          selectionColor={selectionColor}
        />

        <div
          className={`flex flex-col-reverse border-t
            ${sessionBorderStyle}`}
        >
          <div className={'py-1 px-0.5 flex'}>
            <ClubDiveSessionHeaderParticipants
              className={'w-1/2 font-bold text-xs  items-start'}
              diveSessionParticipantsStats={diveSessionParticipantsStats}
            />
            <ClubDiveSessionHeaderSecurityStaff
              className={'w-1/2 text-xs'}
              diveSession={session}
              staffFirstNameBefore={planningConfig.staffFirstNameBefore}
            />
          </div>
          <div className={'px-0.5 py-1 flex justify-start gap-4'}>
            <DiveSessionSimpleCardTotalGroupsAndStaffCounters
              showStaffCounts={planningConfig.showStaffCounts}
              context="large"
              diveSessionParticipantsStats={diveSessionParticipantsStats}
            />

            <AppHamburgerMenu
              className=""
              buttonIcon={AppHeroIcons.actionMenu}
              buttonClassName={`w-full py-1 bg-day-period-${timeDayPeriod}-dark hover:bg-day-period-${timeDayPeriod}-dark2 text-gray-200 hover:text-white`}
              items={hamburgerMenuItems}
              position={hamburgerMenuPosition}
              buttonLabel={'Menu'}
              buttonLabelClasses="uppercase"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
