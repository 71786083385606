import {
  AppRichTextContent,
  AppRichTextElement,
  AppUiTheme,
} from '@mabadive/app-common-model';
import { richTextBuilder } from '@mabadive/app-common-services';
import isHotkey from 'is-hotkey';
import React, { useCallback, useMemo, useState } from 'react';
import { Editor, createEditor } from 'slate';
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';
import { useAppTheme } from 'src/business/club/data/hooks';
import { AppRichTextEditorActionsBar } from './actions-bar/AppRichTextEditorActionsBar';
import { appRichTextEditorActionBarHelper } from './actions-bar/appRichTextEditorActionBarHelper.service';
import { AppRichTextSlateElement, AppRichTextSlateLeaf } from './rendering';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const emptyValue: AppRichTextElement[] = richTextBuilder.createRichTextElements(
  {
    text: '',
  },
);

export type AppRichTextEditorProps = {
  value?: AppRichTextContent;
  onChange?: (content: AppRichTextContent) => void;
  className?: string;
};

export const AppRichTextEditor = ({
  value,
  onChange: onContentChange,
  className = '',
  classNameInput = '',
}: AppRichTextEditorProps & {
  classNameInput?: string;
}) => {
  // const [elements, setElements] = useState<AppRichTextElement[]>(
  //   value?.elements ?? emptyValue
  // );

  const elements = useMemo(
    () => value?.elements ?? emptyValue,
    [value?.elements],
  );

  const onChange = useCallback(
    (elements: AppRichTextElement[]) => {
      // setElements(elements);
      const content: AppRichTextContent = {
        elements,
      };
      onContentChange(content);
    },
    [onContentChange],
  );
  const theme: AppUiTheme = useAppTheme();

  const renderElement = useCallback(
    (props: RenderElementProps) => {
      return <AppRichTextSlateElement theme={theme} {...props} />;
    },
    [theme],
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => {
      return <AppRichTextSlateLeaf theme={theme} {...props} />;
    },
    [theme],
  );
  // const editor = useMemo(() => withReact(createEditor()), []);
  // const [editor] = useState(withReact(createEditor()));
  const [editor] = useState(() => withReact(createEditor()));

  const marks = Editor.marks(editor); // pas de memo ici, car sinon c'est pas updaté

  return (
    <div className={className}>
      <Slate
        editor={editor}
        initialValue={elements}
        onChange={(elements) => onChange(elements as AppRichTextElement[])}
        // onSelectionChange
        // onValueChange
      >
        <AppRichTextEditorActionsBar editor={editor} marks={marks} />
        <Editable
          className={`p-2 bg-white cursor-text ${classNameInput}`}
          style={{ minHeight: '5rem' }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          // autoFocus
          // onClick={(e) => {
          //   console.log('xxx click');
          //   // e.preventDefault();
          //   e.stopPropagation();
          // }}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault();
                const format = (HOTKEYS as any)[hotkey];
                appRichTextEditorActionBarHelper.toggleMarkFormat({
                  editor,
                  marks,
                  format,
                  markValue: true, // TODO supporter une valeur spécifique (pour l'alignement par exemple)
                });
              }
            }
          }}
        />
      </Slate>
    </div>
  );
};
AppRichTextEditor.displayName = 'AppRichTextEditor';
