/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppCompanyMessageContext } from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AppMessageSingleLine } from 'src/business/_core/modules/layout';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubSettingsCommunicationPageLocalState } from '../../../useClubSettingsCommunicationPageLocalState.hook';
import { getAutomaticMailConfigForTemplate } from '../AppCompanyMessageTemplateEditDialog/form/useAutomaticMailConfigForTemplate.hook';
import { ClubSettingsCommunicationMessageTemplateCard } from './ClubSettingsCommunicationMessageTemplateCard';

export const ClubSettingsCommunicationMessageTemplatesViewPanelTemplatesSection =
  ({
    localState,
    context,
  }: {
    localState: ClubSettingsCommunicationPageLocalState;
    context: AppCompanyMessageContext;
  }) => {
    const diveCenterResume = useDiveCenterResume();
    const clubResume = diveCenterResume.clubResume;

    const { state, dialogs } = localState;

    const messageTemplates = useMemo(() => {
      return dataSorter.sortMultiple(
        clubResume.messageTemplates.filter((x) => x.context === context),
        {
          getSortAttributes: (mt) => {
            const { enabledCount } = getAutomaticMailConfigForTemplate({
              messageTemplateId: mt._id,
              clubSettings: clubResume.clubSettings,
            });

            return [
              {
                value: mt.archived && enabledCount === 0 ? 1 : 0,
                asc: true,
              },
              {
                value: mt.enabled ? 0 : 1,
                asc: true,
              },
              {
                value: !mt.enabled && enabledCount !== 0 ? 0 : 1,
                asc: true,
              },
              {
                value: mt.name,
                asc: true,
              },
              {
                value:
                  mt.style === 'success' ? 1 : mt.style === 'warning' ? 2 : 3,
                asc: true,
              },
              {
                value: mt._createdAt,
                asc: true,
              },
            ];
          },
        },
      );
    }, [clubResume.clubSettings, clubResume.messageTemplates, context]);

    return (
      <div className="grid gap-4">
        <AppMessageSingleLine type="info">
          {context === 'session' &&
            "Ces messages sont envoyés aux participants d'une session."}
          {context === 'booking' &&
            "Ces messages sont envoyés aux clients d'une reservation."}
        </AppMessageSingleLine>
        {messageTemplates.length > 0 ? (
          <ul className="border-t border-gray-100 divide-y divide-gray-200">
            {messageTemplates.map((messageTemplate, i) => (
              <ClubSettingsCommunicationMessageTemplateCard
                key={messageTemplate._id}
                messageTemplate={messageTemplate}
                localState={localState}
              />
            ))}
          </ul>
        ) : null}
      </div>
    );
  };
