import { useMemo } from 'react';
import { NEVER, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  LoadableContent,
  LoadableContentActions,
  LoadableContentPartial,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  bookingNotificationsFetcher,
  onlineBookingNotificationsFetcher,
} from './gql';

export type ClubRootSpaceNotificationsVM = {
  bookingsUpdatesCount: number;
  onlineBookingSubmittedCount: number;
};

export function useClubRootSpaceNotifications(): LoadableContent<ClubRootSpaceNotificationsVM> &
  LoadableContentActions {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const loadCriteria: {
    clubReference: string;
    diveCenterId: string;
  } = useMemo(() => {
    if (clubReference && diveCenterId) {
      return {
        clubReference,
        diveCenterId,
      };
    }
    return null;
  }, [clubReference, diveCenterId]);

  const loadableContent = useLoadableContent<ClubRootSpaceNotificationsVM>(
    () => {
      if (loadCriteria === null) {
        return NEVER;
      }
      const x = combineLatest([
        bookingNotificationsFetcher.fetchNotificationsCount(loadCriteria),
        onlineBookingNotificationsFetcher.fetchNotificationsCount(loadCriteria),
      ]).pipe(
        map(([bookingsUpdatesCount, onlineBookingSubmittedCount]) => {
          const content: ClubRootSpaceNotificationsVM = {
            bookingsUpdatesCount,
            onlineBookingSubmittedCount,
          };
          return {
            content,
            contentState: 'full',
            lastActionStatus: 'success',
          } as LoadableContentPartial<ClubRootSpaceNotificationsVM>;
        }),
      );
      return x;
    },
    [loadCriteria],
    {
      debugName: 'ClubRootSpaceNotificationsVM',
      useSnapshot: false,
    },
  );
  return loadableContent;
}
