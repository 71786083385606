/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { ClubDialogsState } from '../../../../../../../pages/_dialogs';
import { AppButtonMultiActionsForSession } from '../../../../../../_core/modules/layout/components/_tailwind/AppButton/AppButtonMultiActionsForSession.type';
import { AppHamburgerMenuItem } from '../../../../_common/ui';
import { PlanningParticipantsLocalState } from '../../../../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';
import { useDiveSessionMenu } from './useDiveSessionMenu';
import { UseDiveSessionSimpleCardLocalState } from './useDiveSessionSimpleCardLocalState.hook';

export const DiveSessionSimpleCardHamburgerMenu = ({
  localState,
  planningParticipantsLocalState,
  dialogsState,
  className,
  children,
}: {
  localState: UseDiveSessionSimpleCardLocalState;
  planningParticipantsLocalState: PlanningParticipantsLocalState; // permet d'afficher le menu de la session
  dialogsState: ClubDialogsState;
  className?: string;
  children?: React.ReactNode;
}) => {
  const { state, data, actions } = localState;
  const { timeDayPeriod } = state;
  const { isPast, session: diveSession } = data;
  const { diveSessionResumes, dailyConfigs, dialogsOld } =
    planningParticipantsLocalState;

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useDiveSessionMenu({
    dialogsState,
    actions,
    diveSession,
    dialogsOld,
    diveSessionResumes,
    dailyConfigs,
  });

  return hamburgerMenuItems.length > 0 ? (
    <AppButtonMultiActionsForSession
      className={clsx(className)}
      items={hamburgerMenuItems}
      timeDayPeriod={timeDayPeriod}
      isPast={isPast}
    >
      {children && children}
    </AppButtonMultiActionsForSession>
  ) : null;
};
