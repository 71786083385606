import {
  DiveSessionResumeFull,
  DiveSessionStaffMemberTableModelStaffMember,
} from '@mabadive/app-common-model';
import {
  diveSessionMultipleBuilder,
  nameFormatter,
} from '@mabadive/app-common-services';
import React from 'react';
import { StaffLevelBadge } from 'src/business/_core/modules/layout';
import { DiveSessionStaffMembersTableSession } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components/DiveSessionStaffMembersTable/DiveSessionStaffMembersTableSession';

export const DailySessionsResumeStaffMembersTableRowStaff = ({
  session,
  staffMemberFull,
}: {
  session: DiveSessionResumeFull;
  staffMemberFull: DiveSessionStaffMemberTableModelStaffMember;
}) => {
  const isMultiSessionConfigForStaff =
    diveSessionMultipleBuilder.isMultiSessionConfigForStaff(
      session.diveTourSession2,
    );

  const staffFirstNameBefore = true;

  const date = session.time;
  const { staffMember, session1, session2 } = staffMemberFull;

  return (
    <tr className={''}>
      <th
        className={
          'py-0.5 px-2 whitespace-nowrap text-xs md:text-sm font-bold uppercase '
        }
      >
        <div className={'flex whitespace-nowrap items-center gap-1'}>
          <StaffLevelBadge
            className="rounded px-0.5 w-9 text-xs flex-shrink-0"
            staffMember={staffMember}
            date={date}
          />
          <span className={'truncate font-bold flex-shrink'}>
            {nameFormatter.formatFullName(staffMember.profile, {
              format: staffFirstNameBefore
                ? 'firstName-first'
                : 'lastName-first',
            })}
          </span>
        </div>
      </th>
      <td
        className={'py-0.5 px-2 whitespace-nowrap text-xs md:text-sm font-bold uppercase'}
      >
        <DiveSessionStaffMembersTableSession
          showRoles={true}
          className="text-xs"
          session={session1}
        />
      </td>
      {isMultiSessionConfigForStaff && (
        <>
          <td
            className={'py-0.5 px-2 whitespace-nowrap text-xs md:text-sm font-bold uppercase'}
          >
            <DiveSessionStaffMembersTableSession
              showRoles={true}
              className="text-xs"
              session={session2}
            />
          </td>
        </>
      )}
    </tr>
  );
};
