import { AppCompanyMessageTarget } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppIcons } from 'src/business/_core/modules/layout/icons';

// NOTE: voir aussi useAppCompanyMessageTargetOptions

export function AppMessagePreviewHeaderBar({
  target,
}: {
  target: AppCompanyMessageTarget;
}) {
  return (
    <div
      className={clsx(
        'border-2 border-white rounded-t-2xl px-2 py-1 text-white flex justify-between items-center',
        target === 'sms'
          ? 'bg-app-sms'
          : target === 'whatsapp'
          ? 'bg-app-whatsapp'
          : 'bg-app-email',
      )}
    >
      {target === 'sms' ? (
        <AppIcons.socialMedia.sms className={'w-6 h-6 lg:w-7 lg:h-7'} />
      ) : target === 'whatsapp' ? (
        <AppIcons.socialMedia.whatsapp className={'w-6 h-6 lg:w-7 lg:h-7'} />
      ) : (
        <AppIcons.socialMedia.mail className={'w-6 h-6 lg:w-7 lg:h-7'} />
      )}

      <div className="text-base uppercase font-bold">
        {target === 'sms'
          ? 'SMS'
          : target === 'whatsapp'
          ? 'Whatsapp'
          : 'Email'}
      </div>
      <div></div>
    </div>
  );
}
