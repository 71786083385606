/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { AppPageBlock } from 'src/business/_core/modules/layout';
import { AppMessageLight } from 'src/business/_core/modules/layout/components/_tailwind';
import { EntitiesIcons } from 'src/business/_core/modules/layout/icons';
import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDialogsStateOld } from 'src/pages/_dialogs';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
import { DiverBookingPageBillingTabDepositCard } from '../DiverBookingPageBillingTab';
import { DiverBookingPagePaymentTabPaymentsResumeTable } from './DiverBookingPagePaymentTabActivePayments';
import { DiverBookingPagePaymentTabArchivedPayments } from './DiverBookingPagePaymentTabArchivedPayments';
import { DiverBookingPagePaymentTabCreateBookingDepositArea } from './DiverBookingPagePaymentTabCreateBookingDepositArea';
import { DiverBookingPagePaymentTabFilterCountsBar } from './DiverBookingPagePaymentTabFilterCountsBar';
import {
  DiverBookingPagePaymentTabLocalState,
  useDiverBookingPagePaymentTabLocalState,
} from './useDiverBookingPagePaymentTabLocalState';

export const DiverBookingPagePaymentTab = ({
  globalState,
  dialogs,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  dialogs: ClubDialogsStateOld;
  actions: DiverBookingPageActions;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const { aggregatedData, focus, dialogsState } = globalState;
  const az = useAppSecurityUserClubAuthorizations();

  const localState: DiverBookingPagePaymentTabLocalState =
    useDiverBookingPagePaymentTabLocalState({
      actions,
      dialogs,
      globalState,
    });

  const {
    paymentTabModel,
    diversFilterOptions,
    paymentsSettings,
    depositsSettings,
    lastBookingId,
    operationInProgressMessage,
    setOperationInProgressMessage,
    purchasePaymentsWithDetails,
    activePurchasePaymentsWithDetails,
    inactivePurchasePaymentsWithDetails,
  } = localState;

  return (
    <AppPageBlock className="app-p-content">
      <div className="grid gap-4 md:gap-8">
        <DiverBookingPagePaymentTabCreateBookingDepositArea
          globalState={globalState}
          actions={actions}
        />
        {paymentTabModel && (
          <>
            {paymentTabModel.visibleBookingDeposits.length !== 0 && (
              <div className="app-card app-px-content">
                <h2 className="my-2 text-base sm:text-lg font-medium text-gray-800 leading-6 flex items-center">
                  <EntitiesIcons.deposit className="w-8 h-8 mr-2 text-gray-800" />{' '}
                  Acomptes ({paymentTabModel.visibleBookingDeposits.length})
                </h2>
                <div className="grid divide-y divide-gray-200">
                  {paymentTabModel.visibleBookingDeposits.map(
                    (bookingDeposit, i) => (
                      <div key={`${i}`} className="py-1">
                        <DiverBookingPageBillingTabDepositCard
                          className={clsx(
                            'py-1',
                            paymentTabModel.visibleBookingDeposits.length > 1 &&
                              'border-l-4 pl-2 border-gray-200 ',
                          )}
                          bookingDeposit={bookingDeposit}
                          globalState={globalState}
                          dialogs={dialogs}
                          actions={actions}
                          index={i}
                          total={paymentTabModel.visibleBookingDeposits.length}
                        />
                      </div>
                    ),
                  )}
                </div>
              </div>
            )}
            <div className="block">
              <h2 className="my-2 text-base sm:text-lg font-medium text-gray-800 leading-6 flex items-center">
                <EntitiesIcons.payment className="w-8 h-8 mr-2 text-gray-800" />{' '}
                Paiements ({purchasePaymentsWithDetails.length})
              </h2>
              <DiverBookingPagePaymentTabFilterCountsBar
                globalState={globalState}
                localState={localState}
              />
              {purchasePaymentsWithDetails.length !== 0 ? (
                <>
                  {activePurchasePaymentsWithDetails?.length > 0 && (
                    <DiverBookingPagePaymentTabPaymentsResumeTable
                      globalState={globalState}
                      dialogs={dialogs}
                      actions={actions}
                      purchasePaymentsWithDetails={
                        activePurchasePaymentsWithDetails
                      }
                    />
                  )}
                  {inactivePurchasePaymentsWithDetails.length !== 0 && (
                    <DiverBookingPagePaymentTabArchivedPayments
                      actions={actions}
                      dialogs={dialogs}
                      globalState={globalState}
                      localState={localState}
                      purchasePaymentsWithDetails={
                        inactivePurchasePaymentsWithDetails
                      }
                    />
                  )}
                </>
              ) : (
                <AppMessageLight className="my-2" type="info" hideBorder={true}>
                  Aucun paiement
                </AppMessageLight>
              )}
            </div>
          </>
        )}

        {operationInProgressMessage && (
          <LoadingProgressBar>{operationInProgressMessage}</LoadingProgressBar>
        )}
        {/* {depositsSettings?.enabled &&
          lastBookingId &&
          !operationInProgressMessage && (
            <div className="flex flex-wrap md:flex-nowrap justify-between gap-2">
              <div className="w-full flex gap-2 md:max-w-[50%]">
                <AppButton
                  className="px-4"
                  size="small"
                  color={'primary-outline-light'}
                  // className={'w-full'}
                  // fullWidth={true}
                  onClick={async () => {
                    setOperationInProgressMessage('Enregistrement en cours...');
                    // try {
                    //   await paymentPlatformClient.createBookingDeposit({
                    //     bookingId: lastBookingId,
                    //     amount: 20,
                    //     paymentPlatformId: depositPlatform._id,
                    //   });
                    // } finally {
                    //   setOperationInProgressMessage(undefined);
                    // }
                  }}
                >
                  <div
                    className={clsx(
                      'overflow-hidden flex items-center gap-1 md:gap-2 uppercase',
                      'text-xs sm:text-sm md:text-base',
                    )}
                  >
                    <AppHeroIcons.actionAddCircle className="w-6 h-6" />

                    <div className="">acompte en ligne (Stripe)</div>
                  </div>
                </AppButton>
              </div>
            </div>
          )} */}
      </div>
    </AppPageBlock>
  );
};
