/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import {
  UseClubDialogsProps,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import { ClubSettingsOnlineBookingConfigPageLocalState } from '../../../useClubSettingsOnlineBookingConfigPageLocalState.hook';
import { ClubSettingsOnlineBookingPageBasePanel } from '../../_common';
import { ClubSettingsOnlineBookingMainViewPanel_ConfigGroups } from './ClubSettingsOnlineBookingMainViewPanel_ConfigGroups';
import { ClubSettingsOnlineBookingMainViewPanel_General } from './ClubSettingsOnlineBookingMainViewPanel_General';
import { ClubSettingsOnlineBookingMainViewPanel_SessionConfig } from './ClubSettingsOnlineBookingMainViewPanel_SessionConfig';

export const ClubSettingsOnlineBookingMainViewPanel = ({
  localState,
}: {
  localState: ClubSettingsOnlineBookingConfigPageLocalState;
}) => {
  // document.title = 'Vente en ligne - configuration';
  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  const generalSettings = clubSettings.general;
  const onlineBookingEnabled = clubSettings?.general?.onlineBooking?.enabled;
  return (
    <ClubSettingsOnlineBookingPageBasePanel
      localState={localState}
      onClick={() => {
        state.editEnabled && dialogs.communication.open(selectedTab);
      }}
    >
      <div className="grid gap-4 md:gap-6">
        <ClubSettingsOnlineBookingMainViewPanel_General />
        {onlineBookingEnabled && (
          <>
            <ClubSettingsOnlineBookingMainViewPanel_SessionConfig />
            <ClubSettingsOnlineBookingMainViewPanel_ConfigGroups />
          </>
        )}
      </div>
    </ClubSettingsOnlineBookingPageBasePanel>
  );
};
