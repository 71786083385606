import {
  DiveSessionResumeFull,
  DiveSessionResumeFullGql_Company,
} from '@mabadive/app-common-model';
import {
  dateService,
  HASURA_DATE_ONLY_FORMAT,
} from '@mabadive/app-common-services';
import { MQueryDescription } from 'src/_common-browser';

export function buildDiveSessionResumeFullCollectionGraphqlQuery({
  clubReference,
  diveCenterId,
  minDateInclusive,
  maxDateExclusive,
}: {
  clubReference: string;
  diveCenterId: string;
  minDateInclusive: Date;
  maxDateExclusive: Date;
}) {
  const minDateInclusiveString = dateService.formatUTC(
    minDateInclusive,
    HASURA_DATE_ONLY_FORMAT,
  );
  const maxDateExclusiveString = dateService.formatUTC(
    maxDateExclusive,
    HASURA_DATE_ONLY_FORMAT,
  );

  const queryDescription: MQueryDescription<DiveSessionResumeFull> = {
    returnName: 'diveSessionResumes',
    queryName: 'dive_session',
    returnType: 'all',
    where: `{_and: [{clubReference: {_eq: "${clubReference}"}}, {diveCenterId: {_eq: "${diveCenterId}"}}, {time: {_gte: "${minDateInclusiveString}"}}, {time: {_lt: "${maxDateExclusiveString}"}}]}`,
    orderBy: '{time: asc}',
    returnAttributes: DiveSessionResumeFullGql_Company,
  };

  return queryDescription;
}
