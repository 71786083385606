import { arrayBuilder, dateService } from '@mabadive/app-common-services';
import {
  BookingTabModel,
  BookingTabMultiBookingsAutoFixSuggestions,
  BookingTabMultiBookingsAutoFixSuggestionsDatesOverlap,
} from '../../../models';

export const bookingTabMultiBookingsAutoFixSuggestionDetector = {
  detectAll,
};

function detectAll(
  bookingTabModels: BookingTabModel[],
): BookingTabMultiBookingsAutoFixSuggestions {
  const datesOverlap: BookingTabMultiBookingsAutoFixSuggestionsDatesOverlap[] =
    [];
  // detectDatesOverlap(bookingTabModels);

  // 17/05/2023 : on désactive, car ça pose des problèmes si il y a des customerUpdates en cours (draft ou validation-pending)
  // et de toute façon, les clients ne cliquent pas forcément sur le bouton, et si on le met en automatique, alors parfois ça fait bugger la résa
  // il faut donc revoir tout ça d'une autre façon

  const autoFixSuggestions: BookingTabMultiBookingsAutoFixSuggestions = {
    hasSuggestions: datesOverlap.length !== 0,
    datesOverlap,
  };

  return autoFixSuggestions;
}

/**
 * réservations qui se chevauchent
 */
function detectDatesOverlap(
  bookingTabModels: BookingTabModel[],
): BookingTabMultiBookingsAutoFixSuggestionsDatesOverlap[] {
  return bookingTabModels
    .filter((x) => x.participantsBySessions.length > 0)
    .reduce((acc, tabModel) => {
      const diversIds = tabModel.aggregatedBooking.bookingMembers.map(
        (x) => x.diverId,
      );
      const datesRange = tabModel.meta.datesRange;
      const matching: BookingTabMultiBookingsAutoFixSuggestionsDatesOverlap =
        acc.find(
          (x) =>
            dateService.haveCommonDaysUTC(
              x.minDate,
              x.maxDate,
              datesRange.minDate,
              datesRange.maxDate,
            ) &&
            isArrayIntersect<string>(x.diversIds, diversIds) &&
            x.diveCenterId === tabModel.bookingDiveCenterId,
        );
      if (matching) {
        matching.tabModels.push(tabModel);
        matching.diversIds = arrayBuilder.filterDuplicated(
          matching.diversIds.concat(diversIds),
        );
      } else {
        const overlap: BookingTabMultiBookingsAutoFixSuggestionsDatesOverlap = {
          diversIds,
          minDate: datesRange.minDate,
          maxDate: datesRange.maxDate,
          tabModels: [tabModel],
          diveCenterId: tabModel.bookingDiveCenterId,
        };
        acc.push(overlap);
      }
      return acc;
    }, [] as BookingTabMultiBookingsAutoFixSuggestionsDatesOverlap[])
    .filter((x) => x.tabModels.length > 1);
}

function isArrayIntersect<T>(arr1: T[], arr2: T[]): boolean {
  return arr1.findIndex((i1) => arr2.includes(i1)) !== -1;
}
