/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { of } from 'rxjs';
import { catchError, delay, first, map, switchMap } from 'rxjs/operators';
import { useRedirect } from 'src/business/_core/data/hooks';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { uiStore } from 'src/business/_core/store';
import {
  AppSecurityUser,
  authenticationClient,
  authenticationStore,
  useAppSecurityUser,
} from '../../services';

export const LogoutPage: () => JSX.Element = () => {
  const redirectTo = useRedirect();
  const securityUser = useAppSecurityUser();

  useEffect(() => {
    (async () => {
      const url = await logoutAndGetRedirectionRoute(securityUser);

      redirectTo(url);
    })();

    // only run ONCE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

async function logoutAndGetRedirectionRoute(
  securityUser: AppSecurityUser,
): Promise<string> {
  const isImpersonate = (securityUser?.roles ?? []).includes(
    'impersonate-back',
  );
  if (isImpersonate) {
    return authenticationClient
      .impersonateBack()
      .pipe(
        switchMap(() => {
          uiStore.snackbarMessage.set({
            type: 'success',
            content: 'Impersonate back OK!',
          });
          return authenticationStore.auth.get().pipe(
            first(),
            map((appAuth) =>
              appRouteBuilder.getDefaultRouteFromAuth({ appAuth }),
            ),
          );
        }),
        catchError(() => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content: 'Une erreur innatendue est survenue...',
          });
          return of('/').pipe(delay(2000));
        }),
      )
      .toPromise();
  } else if (securityUser) {
    return authenticationClient
      .logout()
      .pipe(
        switchMap(() => authenticationStore.auth.get()),
        first(),
        map((appAuth) => appRouteBuilder.getDefaultRouteFromAuth({ appAuth })),
        catchError(() => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content: 'Une erreur innatendue est survenue...',
          });
          return of('/').pipe(delay(2000));
        }),
      )
      .toPromise();
  } else {
    // on force quand même la supression du token
    await authenticationClient.logout().toPromise();
    return Promise.resolve(
      appRouteBuilder.getDefaultRouteFromSecurityUser({ securityUser }),
    );
  }
}
