import {
  ClubProductPackageOfferView,
  DIVE_MOVE_GROUPS_FULL,
  EcommerceProductGql_Company,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';

export function useDiveModeGroupOffers({
  product,
  offersAll,
}: {
  product: EcommerceProductGql_Company;
  offersAll: ClubProductPackageOfferView[];
}) {
  const diveModeGroup = useMemo(
    () =>
      DIVE_MOVE_GROUPS_FULL.find(
        (dmg) => dmg.diveModeGroup === product.diveModeGroup,
      ),
    [product.diveModeGroup],
  );

  const offers = useMemo(() => {
    return offersAll.filter((x) => {
      if (
        diveModeGroup &&
        (x.productPackage.type === 'training' ||
          x.productPackage.type === 'dive')
      ) {
        return (diveModeGroup?.diveModes ?? []).includes(
          x.productPackage?.diveAttributes?.diveMode,
        );
      }
      return false;
    });
  }, [diveModeGroup, offersAll]);
  return { diveModeGroup, offers };
}
