import {
  DiveCertificationReference,
  DIVE_CERTIFICATIONS,
} from '@mabadive/app-common-model';
import { certificationFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useSupervisedCertificationOptions(): ValueLabel<
  DiveCertificationReference,
  string
>[] {
  return useMemo(
    () =>
      DIVE_CERTIFICATIONS.map((certification) => ({
        value: certification.reference,
        label: certificationFormatter.formatCertification(certification, {
          format: 'ref-name',
        }),
      })),
    [],
  );
}
