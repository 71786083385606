/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import {
  ClubDialogsState,
  useClubDialogs,
  useClubDialogsActionsPersist,
  UseClubDialogsProps,
} from 'src/pages/_dialogs';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';

export const ClubSettingsGeneralMainViewPanelFeatures = () => {
  const diveCenterResume = useDiveCenterResume();
  const clubResume = useClubResume();
  const redirectTo = useRedirect();

  const clubSettings = clubResume?.clubSettings;

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist();
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const mainCurrency = useAppCurrencyMain();

  const generalSettings = clubSettings.general;
  return (
    <ClubSettingsSection title="Fonctionnalités">
      <div className="grid gap-4 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
        <AppFeatureBadge
          label={'Facturation'}
          enabled={clubSettings.general.billing.enabled}
        />
        <AppFeatureBadge
          label="Taxes"
          enabled={clubSettings.general.taxes.enabled}
        />
        <AppFeatureBadge
          label="Paiements"
          enabled={clubSettings.general.payments.enabled}
        />
        <AppFeatureBadge
          className="col-start-1"
          label="Hébergements"
          enabled={clubSettings.general?.accommodations?.enabled}
        />
        <AppFeatureBadge
          label="Agences"
          enabled={clubSettings.general?.agencies?.enabled}
        />
        <AppFeatureBadge
          label="Questionnaires"
          enabled={clubSettings.general?.inquiry?.enabled}
        />
      </div>
    </ClubSettingsSection>
  );
};
