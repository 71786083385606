import {
  AppCurrency,
  ClubDiver,
  ClubSettingsPublicCustomer,
} from '@mabadive/app-common-model';
import { nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { AppPriceLabelRatio } from 'src/business/club/modules/_common/ui';
import { DiverBookingTitle } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiverBookingCard';
import { AggregatedBookingPurchasePaymentDepositDetails } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';

export const PurchasePaymentCardProduct_BookingDeposit = ({
  clubSettings,
  bookingDepositPaymentDetails,
  loadedDivers,
  paymentCurrency,
  className,
}: {
  clubSettings: ClubSettingsPublicCustomer;
  bookingDepositPaymentDetails: AggregatedBookingPurchasePaymentDepositDetails;
  loadedDivers: Pick<ClubDiver, '_id' | 'lastName' | 'firstName'>[];
  paymentCurrency: AppCurrency;
  className?: string;
}) => {
  const diver = bookingDepositPaymentDetails?.diver;
  const bookingResume = bookingDepositPaymentDetails?.bookingResume;

  const publicSettings = clubSettings.publicSettings;

  return (
    <li className={clsx('flex gap-4 py-2', className)}>
      <div className="flex-shrink-0">
        <img
          alt="Prestation payée"
          src={
            'https://mabadive.fra1.cdn.digitaloceanspaces.com/images%2Fproducts%2Fscuba-diving-300x300.png'
          }
          className="grayscale brightness-125 h-9 w-9 bg-gray-400 rounded-md"
        />
      </div>

      <div className="flex flex-1 flex-col">
        <h4 className="text-sm text-gray-600 uppercase">
          <div className="flex flex-wrap items-end gap-2">
            {/* <EntitiesIcons.deposit className="w-4 h-4" /> */}
            <span className="font-bold">ACOMPTE</span>
          </div>
        </h4>
        <div className="flex flex-wrap gap-2 text-xs font-normal text-gray-500">
          {diver && (
            <span className="font-bold">
              {nameFormatter.formatFullName(diver, {
                format: 'firstName-first',
              })}
            </span>
          )}
          {bookingResume && (
            <DiverBookingTitle
              className="text-xs xl:text-sm font-normal text-gray-500"
              datesRange={bookingResume.datesRange}
              bookingGroup={bookingResume.booking?.bookingGroup}
            ></DiverBookingTitle>
          )}
          <AppPriceLabelRatio
            amount={bookingDepositPaymentDetails.paymentDetails.amount}
            total={bookingDepositPaymentDetails.bookingDeposit.price}
            mainCurrency={paymentCurrency}
            hideTotalIfSameAmount={true}
          />
        </div>
      </div>
    </li>
  );
};
