import { DiveMode, DiveSessionTimeDayPeriod } from '@mabadive/app-common-model';
import { AppButtonStyleColor } from './AppButtonStyleColor.type';

export const appButtonSylesBuilder = {
  diveMode,
  style,
};

function style({
  color,
  disabled,
  opacity,
  size,
  padding: paddingAttr,
  fontSize: fontSizeAttr,
  fullWidth,
  withIcon = true,
  timeDayPeriod,
}: {
  color: AppButtonStyleColor;
  disabled?: boolean;
  opacity?: undefined;
  size?: 'normal' | 'small' | 'hamburger';
  padding?: string;
  fontSize?: string;
  fullWidth?: boolean;
  withIcon?: boolean;
  timeDayPeriod?: DiveSessionTimeDayPeriod;
}) {
  const core = `${fullWidth ? 'w-full' : 'sm:max-w-xs'} items-start ${
    withIcon ? 'justify-start' : 'justify-around'
  } rounded-sm`;
  const padding =
    paddingAttr ??
    (size === 'small'
      ? 'px-1 py-1'
      : size === 'hamburger'
      ? 'px-1'
      : 'px-2 py-2');
  const fontSize =
    fontSizeAttr ??
    (size === 'small'
      ? 'text-xs'
      : size === 'hamburger'
      ? 'text-sm'
      : 'text-sm');
  const opacityClass =
    disabled || opacity === 60 ? 'text-opacity-60 hover:text-opacity-60' : '';

  const colorClasses = buildColorClasses({
    color,
    timeDayPeriod,
  });

  const colorClass = `${colorClasses.className} ${
    disabled ? 'cursor-not-allowed' : colorClasses.hoverClassName
  }`;
  return `${core}
    ${padding}
    ${fontSize}
    ${opacityClass} 
    ${colorClass}
`;
}

function buildColorClasses({
  color,
  timeDayPeriod,
}: {
  color: AppButtonStyleColor;
  timeDayPeriod?: DiveSessionTimeDayPeriod;
}): {
  className: string;
  hoverClassName: string;
} {
  switch (color) {
    default:
    case 'primary-outline-light': {
      return {
        className: 'border-app-primary text-app-primary bg-white',
        hoverClassName:
          'hover:bg-gray-100 hover:border-app-primary-dark hover:text-app-primary-dark',
      };
    }
    case 'secondary-outline-light': {
      return {
        className: 'border-app-secondary text-app-secondary bg-white',
        hoverClassName:
          'hover:bg-gray-100 hover:border-app-secondary-dark hover:text-app-secondary-dark',
      };
    }
    case 'whatsapp-outline': {
      return {
        className: 'border-app-whatsapp text-app-whatsapp bg-white',
        hoverClassName:
          'hover:bg-gray-100 hover:border-app-whatsapp-dark hover:text-app-whatsapp-dark',
      };
    }
    case 'phone-outline': {
      return {
        className: 'border-app-primary text-app-primary bg-white',
        hoverClassName:
          'hover:bg-gray-100 hover:border-app-primary-dark hover:text-app-primary-dark',
      };
    }
    case 'phone-outline-gray': {
      return {
        className: 'border-gray-600 text-app-primary bg-gray-50',
        hoverClassName:
          'hover:bg-gray-200 hover:border-gray-800 hover:text-app-primary-dark',
      };
    }
    case 'mail-outline': {
      return {
        className: 'border-app-mail text-app-mail bg-white',
        hoverClassName:
          'hover:bg-gray-100 hover:border-app-mail-dark hover:text-app-mail-dark',
      };
    }
    case 'mail-outline-gray': {
      return {
        className: 'border-gray-600 text-app-mail bg-gray-50',
        hoverClassName:
          'hover:bg-gray-200 hover:border-gray-800 hover:text-app-mail-dark',
      };
    }

    case 'day-period-bg': {
      return {
        className: `px-1 inline-flex items-center justify-center focus:outline-none w-full bg-opacity-80 bg-day-period-${timeDayPeriod}-dark border border-day-period-${timeDayPeriod}-dark rounded text-white font-bold text-sm `,
        hoverClassName: `hover:bg-opacity-100 hover:bg-day-period-${timeDayPeriod}-dark2 hover:text-gray-100`,
      };
    }
    case 'gray-hamburger-button': {
      return {
        className:
          'px-1 inline-flex items-center justify-center focus:outline-none w-full bg-white border border-gray-300 rounded bg-gray-100 text-gray-400 font-bold text-sm ',
        hoverClassName: 'hover:bg-gray-300 hover:text-gray-800',
      };
    }
    case 'success-outline-light': {
      return {
        className: 'border-status-success text-status-success bg-white',
        hoverClassName:
          'hover:bg-gray-100 hover:border-status-success-dark hover:text-status-success-dark',
      };
    }
    case 'danger-outline-light': {
      return {
        className: 'border-status-error text-status-error bg-white',
        hoverClassName:
          'hover:bg-gray-100 hover:border-status-error-dark hover:text-status-error-dark',
      };
    }
    case 'primary-outline-hover-bg': {
      return {
        className: 'border-app-primary text-app-primary bg-white',
        hoverClassName:
          'hover:bg-app-primary hover:border-app-primary-dark hover:text-gray-200',
      };
    }
    case 'primary-bg': {
      return {
        className: 'border-app-primary bg-app-primary-light text-white',
        hoverClassName:
          'hover:bg-app-primary hover:border-app-primary-dark  hover:text-gray-100',
      };
    }
    case 'secondary-bg': {
      return {
        className: 'border-app-secondary bg-app-secondary-light text-white',
        hoverClassName:
          'hover:bg-app-secondary hover:border-app-secondary-dark  hover:text-gray-100',
      };
    }
    case 'danger-outline-hover-bg': {
      return {
        className: 'border-status-error bg-status-error-light text-white',
        hoverClassName:
          'hover:bg-status-error hover:border-status-error-dark hover:text-gray-100',
      };
    }
    case 'success-outline-hover-bg': {
      return {
        className: 'border-status-success bg-status-success-light text-white',
        hoverClassName:
          'hover:bg-status-success hover:border-status-success-dark hover:text-gray-100',
      };
    }
    case 'danger-bg': {
      return {
        className: 'border-status-error-dark bg-status-error-light text-white',
        hoverClassName:
          'hover:bg-status-error-dark hover:border-gray-800 hover:text-gray-100',
      };
    }
    case 'black-outline-light': {
      return {
        className: 'border-gray-800 text-gray-800 bg-white',
        hoverClassName: 'hover:bg-gray-100 hover:border-black hover:text-black',
      };
    }
    case 'gray-outline-light': {
      return {
        className: 'border-gray-600 text-gray-600 bg-gray-50',
        hoverClassName:
          'hover:bg-gray-200 hover:border-gray-800 hover:text-gray-800',
      };
    }
  }
}

function diveMode(
  diveMode: DiveMode,
  {
    style,
    disabled = false,
    opacity,
  }: { style: 'light' | 'reverse'; disabled?: boolean; opacity?: 60 } = {
    style: 'light',
    disabled: false,
    opacity: undefined,
  },
) {
  if (style === 'light') {
    return `w-full text-sm px-2 py-2 items-start justify-start 
      border-dive-mode-${diveMode}
      text-dive-mode-${diveMode}
      ${
        disabled || opacity === 60
          ? 'text-opacity-60 hover:text-opacity-60'
          : ''
      }
      ${
        disabled
          ? ''
          : `hover:bg-gray-100 hover:border-dive-mode-${diveMode}-dark  hover:text-dive-mode-${diveMode}-dark`
      }
    `;
  } else if (style === 'reverse') {
    return `w-full text-sm px-2 py-2 items-start justify-start 
      border-dive-mode-${diveMode} 
      text-white hover:text-white
      bg-dive-mode-${diveMode}
      ${
        disabled || opacity === 60
          ? 'text-opacity-60 hover:text-opacity-60'
          : ''
      }
      ${
        disabled
          ? ''
          : `hover:border-dive-mode-${diveMode}-dark hover:bg-dive-mode-${diveMode}-dark`
      }
    `;
  }
}
