import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { BookingTabModel, DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
import {
  diverBookingPageUpdateStateManager,
  replaceBookingContact,
} from '../../02.update-state';
export function useDiverBookingPageDeleteMember({
  updateState,
  setUpdateState,
}: {
  updateState: DiverBookingPageUpdateState;
  setUpdateState: DiverBookingPageSetUpdateStateFn;
}) {
  return useCallback(
    ({
      diverId,
      bookingSource,
    }: {
      diverId: string;
      bookingSource: BookingTabModel;
    }) => {
      let updateStateLocal = updateState;

      const sourceMember = bookingSource.aggregatedBooking.bookingMembers.find(
        (m) => m.diverId === diverId,
      );
      const productsToDelete =
        bookingSource.aggregatedBooking.bookingParticipantsFull.filter(
          (sourceProduct) =>
            sourceProduct.bookingMember._id === sourceMember._id,
        );

      // delete products
      for (const sourceProduct of productsToDelete) {
        updateStateLocal =
          diverBookingPageUpdateStateManager.deleteParticipantFromState({
            updateState: updateStateLocal,
            bookingProductId: sourceProduct.bookingProductDive._id,
            clubParticipantId:
              sourceProduct.bookingSessionParticipant.participantId,
            bookingSessionParticipantId:
              sourceProduct.bookingSessionParticipant?._id,
          });
      }

      // delete old member
      const bookingMembersChanges = changeDescriptorManager.deleteOne(
        sourceMember._id,
        {
          changeDescriptors: updateStateLocal.bookingMembersChanges,
        },
      );
      updateStateLocal = {
        ...updateStateLocal,
        bookingMembersChanges,
      };

      if (
        bookingSource.aggregatedBooking.booking.bookingContactDiverId ===
        diverId
      ) {
        updateStateLocal = replaceBookingContact({
          bookingSource,
          updateStateLocal,
        });
      }

      setUpdateState(updateStateLocal, {
        action: 'DiverBookingPageDeleteMember',
      });
    },
    [setUpdateState, updateState],
  );
}
