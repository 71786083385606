/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_TIME_ZONES } from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useTimezoneOptions(): ValueLabel<string, string>[] {
  return useMemo(() => {
    const timeZones = dataSorter.sortMultiple(APP_TIME_ZONES, {
      getSortAttributes: (tz) => [
        {
          value: tz.name,
        },
      ],
    });

    const timeZoneOptions: ValueLabel<string, string>[] = timeZones.map(
      (tz) => ({
        value: tz.name,
        label: tz.name,
      }),
    );

    return timeZoneOptions;
  }, []);
}
