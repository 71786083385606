/**
@see ClubPlanningPanelSessionDisplayStyle
 */
export const TAILWIND_COLORS_DAY_PERIOD = `
    bg-day-period-morning-light bg-day-period-morning-light2 bg-day-period-morning-dark bg-day-period-morning-dark2 bg-day-period-afternoon-light bg-day-period-afternoon-light2 bg-day-period-afternoon-dark bg-day-period-afternoon-dark2 bg-day-period-night-light bg-day-period-night-light2 bg-day-period-night-dark bg-day-period-night-dark2
    text-day-period-morning-light text-day-period-morning-light2 text-day-period-morning-dark text-day-period-morning-dark2 text-day-period-afternoon-light text-day-period-afternoon-light2 text-day-period-afternoon-dark text-day-period-afternoon-dark2 text-day-period-night-light text-day-period-night-light2 text-day-period-night-dark text-day-period-night-dark2
    border-day-period-morning-light border-day-period-morning-light2 border-day-period-morning-dark border-day-period-morning-dark2 border-day-period-afternoon-light border-day-period-afternoon-light2 border-day-period-afternoon-dark border-day-period-afternoon-dark2 border-day-period-night-light border-day-period-night-light2 border-day-period-night-dark border-day-period-night-dark2
    ring-day-period-morning-light ring-day-period-morning-light2 ring-day-period-morning-dark ring-day-period-morning-dark2 ring-day-period-afternoon-light ring-day-period-afternoon-light2 ring-day-period-afternoon-dark ring-day-period-afternoon-dark2 ring-day-period-night-light ring-day-period-night-light2 ring-day-period-night-dark ring-day-period-night-dark2
    hover:bg-day-period-morning-light hover:bg-day-period-morning-light2 hover:bg-day-period-morning-dark hover:bg-day-period-morning-dark2 hover:bg-day-period-afternoon-light hover:bg-day-period-afternoon-light2 hover:bg-day-period-afternoon-dark hover:bg-day-period-morning-dark2 hover:bg-day-period-afternoon-light hover:bg-day-period-afternoon-light2 hover:bg-day-period-afternoon-dark hover:bg-day-period-night-light hover:bg-day-period-night-dark hover:bg-day-period-night-dark2
`;
