import {
  DiveCenterResume,
  DiveSession,
  DiveSessionResume,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  virtualDiveSessionBuilder,
} from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  DiverBookingPageAggregatedData,
  DiverBookingPageUpdateState,
} from '../../../models';
import { diverBookingPageUpdateStateManager } from '../../02.update-state';
import { diveSessionToDiveSessionResumeConvertor } from '../entity-convertors';

export const quickBookingDiveSessionCreator = {
  selectOrCreateDiveSession,
};

function selectOrCreateDiveSession({
  aggregatedData,
  diveSessionReference,
  updateStateLocal,
  diveCenterResume,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  diveSessionReference: string;
  updateStateLocal: DiverBookingPageUpdateState;
  diveCenterResume: DiveCenterResume;
}): {
  diveSessionResume?: DiveSessionResume;
} {
  let diveSession: DiveSession = aggregatedData?.diveSessions.find(
    (d) => d?.reference === diveSessionReference,
  );
  if (!diveSession) {
    appLogger.warn(
      `[quickBookingDiveSessionCreator] Action session "${diveSessionReference}" not found in ${aggregatedData?.diveSessions?.length} loaded sessions: create it`,
    );

    const {
      settingsPlanning,
      clubReference,
      _id: diveCenterId,
    } = diveCenterResume;

    const virtualSession: DiveSession =
      virtualDiveSessionBuilder.getByReference({
        diveSessionReference,
        clubReference,
        diveCenterId,
        settingsPlanning,
      });

    if (!virtualSession) {
      appLogger.error(
        `[quickBookingDiveSessionCreator] Virtual session "${diveSessionReference}" not found`,
      );
      return {};
    }

    diveSession = commonEntityBuilder.buildEntity<DiveSession>({
      ...virtualSession,
      isVirtual: false,
    });

    updateStateLocal.diveSessionsChanges =
      diverBookingPageUpdateStateManager.buildDiveSessionsChanges({
        updateState: updateStateLocal,
        newDiveSessions: [diveSession],
        originalDiveSessions: [],
      });
  }
  const diveSessionResume = diveSessionToDiveSessionResumeConvertor.convert(
    diveSession,
    { diveSites: diveCenterResume.diveSites },
  );
  return { diveSessionResume };
}
