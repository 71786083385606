/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import React, { useEffect, useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppPageContainer,
  AppTabsBar,
} from 'src/business/_core/modules/layout';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { clubPlanningUrlBuilder } from 'src/business/club/modules/url-builders';
import { DashboardReportPageGlobalActivityPayments } from 'src/pages/DA-dashboard/_core';
import { AppTitleDateNavigatorWithPeriodSelector } from 'src/pages/_components/title-navigators';
import { CLUB_LISTS_TABS } from '../_common';
import { clubListsUrlBuilder } from '../clubListsUrlBuilder.service';
import {
  DashboardCashPageLocalState,
  useDashboardCashPageLocalState,
} from './useDashboardCashPageLocalState';

export const DashboardCashPage = () => {
  const clubResume = useClubResume();

  const localState: DashboardCashPageLocalState =
    useDashboardCashPageLocalState();

  const { state, data } = localState;
  const { viewParameters, setViewParameters } = state;
  const { fetchResult } = data;
  const beginDate = localState?.data?.fetchResult?.criteria?.beginDate;
  const endDate = localState?.data?.fetchResult?.criteria?.endDate;

  const redirectTo = useRedirect();
  const user = useAppSecurityUser();

  useEffect(() => {
    if (!user.roles.includes('club-view-dashboard-daily-cash')) {
      redirectTo(clubPlanningUrlBuilder.buildPlanningUrl());
    }
  }, [redirectTo, user.roles]);

  const title = useMemo(() => {
    const dateLabel = dateService.formatUTC(beginDate, 'dddd DD/MM/YYYY');
    return `Caisse du jour - ${dateLabel}`;
  }, [beginDate]);

  return (
    <AppPageContainer className={'bg-white'}>
      <div className="sticky top-0 z-10">
        <AppTabsBar
          theme="primary"
          tabs={CLUB_LISTS_TABS}
          context={{}}
          value={'cash'}
          onChange={(tab) => {
            const url = clubListsUrlBuilder.buildClubListsTabUrl(tab);
            redirectTo(url);
          }}
          // disableNavigation={globalState?.updateState.hasChanges}
        />
      </div>
      {/* <AppPageContentContainer className="bg-gray-50 app-px-content"> */}
      <div className="sticky top-0  px-2 py-2 z-[10] bg-gray-50 flex gap-5 justify-between">
        <AppTitleDateNavigatorWithPeriodSelector
          className="w-full"
          hidePeriodSelector={true}
          periodRange={viewParameters.periodRange}
          onChange={(periodRange) => {
            setViewParameters({
              ...viewParameters,
              periodRange,
            });
          }}
        />
      </div>
      {/* <AppLoadableContentContainer {...localState}> */}
      {fetchResult && (
        <div className="px-2 mt-4 pb-8 w-full flex justify-center">
          <DashboardReportPageGlobalActivityPayments
            title={title}
            className="max-w-6xl"
            paymentsOverPeriod={fetchResult.paymentsOverPeriod}
          />
        </div>
      )}
      {/* </AppLoadableContentContainer> */}
      {/* </AppPageContentContainer> */}
    </AppPageContainer>
  );
};
