import { DiveSessionTimeDayPeriod } from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  diveModeFormatter,
} from '@mabadive/app-common-services';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Tippy from '@tippyjs/react';
import React, { useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';
import { ClubDiveSessionNameLight } from 'src/business/club/modules/club-dive-session/components';
import { diveSessionTitleBuilder } from 'src/business/club/modules/club-dive-session/services';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { AggregatedBookingSessionFull } from '../../models';
import { DiverBookingSessionsTableDayHeader } from './DiverBookingSessionsTableDayHeader';
import { DiverBookingSessionsTableSessionHeaderTime } from './DiverBookingSessionsTableSessionHeaderTime';

export function DiverBookingSessionsTableSessionHeader({
  bookingSessionFull,
  displaySessionName,
  displayDiveSite,
  onClick,
  style,
  theme,
  className,
}: {
  bookingSessionFull: AggregatedBookingSessionFull;
  displaySessionName: boolean;
  displayDiveSite: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  theme: 'light' | 'normal';
  className?: string;
}) {
  const { diveSession } = bookingSessionFull;
  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(diveSession.time),
    [diveSession.time],
  );
  const sessionTitle = useMemo(
    () => diveSessionTitleBuilder.build(diveSession),
    [diveSession],
  );

  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  const diveSessionDiveCenter = useMemo(
    () =>
      clubResume.diveCenters.find((dc) => dc._id === diveSession.diveCenterId),
    [clubResume.diveCenters, diveSession.diveCenterId],
  );
  const clubSettings = useClubSettings();

  return (
    <div
      className={`font-medium uppercase align-top tracking-wider
      ring-opacity-30 ring-day-period-${timeDayPeriod}-dark 
       ${theme === 'light' ? 'text-gray-400' : 'text-gray-500'} 
       ${onClick ? 'group rounded-t-lg hover:ring-2 cursor-pointer' : ''} 
       ${diveSession.status === 'cancelled' ? 'line-through' : ''}
       ${className ?? ''}`}
      style={style as unknown as React.CSSProperties}
      onClick={onClick}
    >
      <DiverBookingSessionsTableDayHeader date={diveSession.time} />
      {isMultiDiveCenters && (
        <DiveCenterProBadge
          className="my-px"
          diveCenter={diveSessionDiveCenter}
        />
      )}
      <div className="flex gap-1">
        <DiverBookingSessionsTableSessionHeaderTime
          theme={theme}
          className="flex-grow text-center text-sm"
          special={!diveSession.diveTourSession2 && diveSession.special}
          time={diveSession.time}
          emojiId={
            !sessionTitle &&
            !diveSession.diveTourSession2 &&
            diveSession.emojiId
          }
          displayDiveSite={displayDiveSite}
          diveSiteId={diveSession.diveSiteId}
          sessionIndex={1}
          isMultiSession={!!diveSession.diveTourSession2}
        />
        {diveSession.diveTourSession2 && (
          <DiverBookingSessionsTableSessionHeaderTime
            theme={theme}
            className="flex-grow text-center text-sm"
            special={undefined}
            time={diveSession.diveTourSession2.time}
            emojiId={undefined}
            displayDiveSite={displayDiveSite}
            diveSiteId={diveSession.diveTourSession2.diveSiteId}
            sessionIndex={2}
            isMultiSession={!!diveSession.diveTourSession2}
          />
        )}
      </div>
      {displaySessionName && (
        <div className="h-3 relative flex flex-row-reverse gap-1 items-center justify-between">
          {diveSession.details?.defaultDiveMode && (
            <div className="flex-shrink overflow-hidden">
              <Tippy
                delay={[500, 100]}
                placement="right"
                content={'Prestation forcée (par défaut)'}
              >
                <div className="text-app-xxs2 text-right">
                  {diveModeFormatter.formatDiveMode(
                    diveSession.details?.defaultDiveMode,
                    {
                      format: 'short-ref',
                      diveModesConf: clubSettings?.ui?.diveMode,
                    },
                  )}
                </div>
              </Tippy>
            </div>
          )}
          <ClubDiveSessionNameLight
            className={'flex-grow font-bold text-app-xxs truncate'}
            iconSize={'small'}
            diveSession={diveSession}
            truncateText={true}
          />
        </div>
      )}

      {/* <DiveSessionSimpleCardHeader
        className="py-1 h-12 text-app-xxs leading-4"
        session={diveSession}
        display={{
          boats: false,
          truncateText: true,
        }}
      /> */}
    </div>
  );
}
