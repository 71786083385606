/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { AppFormControlRHF, AppInputRHF } from 'src/lib/form';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { ClubSettingsPlanningViewDayModel } from '../../ClubSettingsPlanningViewPage/ClubSettingsPlanningViewWeek';
import {
  ClubSettingsPlanningEditPageMode,
  ClubSettingsPlanningViewWeekFormModel,
} from '../model';
import { ClubSettingsPlanningViewWeekDayGeneralForm } from './ClubSettingsPlanningViewWeekDayGeneralForm';
import { ClubSettingsPlanningViewWeekDaySessionBookingForm } from './ClubSettingsPlanningViewWeekDaySessionBookingForm';

export const ClubSettingsPlanningViewWeekForm = ({
  mode,
  form,
  weekDays,
  className,
}: {
  mode: ClubSettingsPlanningEditPageMode;
  form: UseFormReturn<ClubSettingsPlanningViewWeekFormModel, any>;
  weekDays: ClubSettingsPlanningViewDayModel[];
  className?: string;
}) => {
  const clubResume = useClubResume();

  const diveCenterResume = useDiveCenterResume();

  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'days',
    },
  );

  // const sessionsCount = fields.reduce((acc, x) => acc + x.diveTours.length, 0);

  return (
    <div className={className}>
      <div className="flex flex-col md:grid md:grid-cols-3 lg:grid-cols-6 gap-2">
        <AppFormControlRHF
          className={'w-full'}
          label={'Nom'}
          control={control}
          name={'name'}
          required={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className="w-full"
          label={'Date début'}
          control={control}
          name="beginDate"
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              className="w-full"
              {...props}
              onChange={(beginDate) => {
                if (beginDate) {
                  // sync endDate on change
                  setValue('endDate', beginDate);
                }
              }}
            />
          )}
        />
        <AppFormControlRHF
          className="w-full"
          label={'Date fin'}
          control={control}
          name="endDate"
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF className="w-full" {...props} />
          )}
        />
        {/* {sessionsCount > 0 && (
          <div>
            <AppButton
              className={clsx('font-bold uppercase')}
              size="small"
              fontSize="text-xs"
              style={{ minWidth: '6rem' }}
              color={'danger-outline-light'}
              icon={AppHeroIcons.actionDelete}
              tabIndex={500}
              onClick={async () => {
                setValue(
                  'days',
                  arrayBuilder.bySize(7).map(() => ({ diveTours: [] })),
                );
                // if (
                //   await confirmDialog.confirmPromise({
                //     title: `Supprimer toutes les sessions ?`,
                //     message: `Êtes-vous sûr de vouloir supprimer toutes les sessions de cette période ?`,
                //     type: 'noYesDanger',
                //   })
                // ) {

                //   // ok
                //   // // delete all daily tours
                //   // diveToursFieldArray.remove(
                //   //   arrayBuilder
                //   //     .bySize(diveToursFieldArray.fields.length)
                //   //     .map((i) => i),
                //   // );
                // }
              }}
            >
              <span className="whitespace-nowrap truncate">Supprimer</span>
            </AppButton>
          </div>
        )} */}
      </div>
      {(mode === 'edit' || mode === 'clone') && (
        <div className={'mt-4 flex flex-col lg:grid lg:grid-cols-7 gap-2'}>
          {weekDays.map((day, dayIndex) => (
            <ClubSettingsPlanningViewWeekDayGeneralForm
              form={form}
              key={dayIndex}
              day={day}
              dayIndex={dayIndex}
            />
          ))}
        </div>
      )}{' '}
      {mode === 'edit-session-booking' && (
        <div className={'mt-4 flex flex-col lg:grid lg:grid-cols-7 gap-2'}>
          {weekDays.map((day, dayIndex) => (
            <ClubSettingsPlanningViewWeekDaySessionBookingForm
              form={form}
              key={dayIndex}
              day={day}
              dayIndex={dayIndex}
            />
          ))}
        </div>
      )}
    </div>
  );
};
