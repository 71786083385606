import {
  AppDocUploadContext,
  AppTranslationContentItemType,
} from '@mabadive/app-common-model';
import React from 'react';
import {
  FieldArrayPath,
  FieldValues,
  UseFormReturn,
  useFieldArray,
} from 'react-hook-form';
import { AppTranslationContentItemsForm } from './AppTranslationContentItemsForm';
import { AppTranslationContentItemsFormAddSectionBar } from './AppTranslationContentItemsFormAddSectionBar';

export function AppTranslationContentItemsFormPanel<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  // TFieldValues extends FieldValues = FieldValues,
  // TFieldArrayName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>
>({
  form,
  name,
  types,
  appDocUploadContext,
  className,
}: {
  form: UseFormReturn<TFieldValues>;
  name: TFieldArrayName;
  types: AppTranslationContentItemType[];
  appDocUploadContext: AppDocUploadContext;
  className?: string;
}) {
  const { control } = form;

  const fieldsArray = useFieldArray({
    control,
    name,
  });
  return (
    <div className={className}>
      <AppTranslationContentItemsForm
        className="my-3"
        form={form}
        fieldsArray={fieldsArray}
        name={name}
        appDocUploadContext={appDocUploadContext}
      />
      <AppTranslationContentItemsFormAddSectionBar
        types={types}
        fieldsArray={fieldsArray}
        className="mt-2"
      />
    </div>
  );
}
