import { AppCompanyMessageContext } from '@mabadive/app-common-model';
import { useClubSettings } from './useClubSettings.hook';

export function useClubSettingsMessageTargets({
  context,
}: {
  context: Extract<AppCompanyMessageContext, 'booking' | 'session'>;
}): {
  email: {
    enabled: boolean;
    channelEnabled: boolean;
  };
  sms: {
    enabled: boolean;
    channelEnabled: boolean;
  };
  phone: {
    channelEnabled: boolean;
  };
  whatsApp: {
    enabled: boolean;
    channelEnabled: boolean;
  };
} {
  const clubSettings = useClubSettings();

  const [
    channelEmailEnabled,
    channelSmsEnabled,
    channelPhoneEnabled,
    channelWhatsAppEnabled,
    bookingEmailEnabled,
    bookingSmsEnabled,
    bookingWhatsAppEnabled,
    sessionEmailEnabled,
    sessionSmsEnabled,
    sessionWhatsAppEnabled,
  ] = [
    clubSettings?.communication?.channels?.email?.enabled,
    clubSettings?.communication?.channels?.sms?.enabled,
    clubSettings?.communication?.channels?.phone?.enabled,
    clubSettings?.communication?.channels?.whatsApp?.enabled,
    clubSettings?.communication?.booking?.email?.enabled,
    clubSettings?.communication?.booking?.sms?.enabled,
    clubSettings?.communication?.booking?.whatsApp?.enabled,
    clubSettings?.communication?.session?.email?.enabled,
    clubSettings?.communication?.session?.sms?.enabled,
    clubSettings?.communication?.session?.whatsApp?.enabled,
    clubSettings?.communication?.customer?.emailButton?.enabled,
    clubSettings?.communication?.customer?.phoneButton?.enabled,
    clubSettings?.communication?.customer?.whatsAppButton?.enabled,
  ];

  const isEmailContextEnabled =
    channelEmailEnabled &&
    ((context === 'booking' && bookingEmailEnabled) ||
      (context === 'session' && sessionEmailEnabled) ||
      (context === 'session' && sessionEmailEnabled));

  const isSmsContextEnabled =
    channelSmsEnabled &&
    ((context === 'booking' && bookingSmsEnabled) ||
      (context === 'session' && sessionSmsEnabled));

  const isWhatsAppContextEnabled =
    channelWhatsAppEnabled &&
    ((context === 'booking' && bookingWhatsAppEnabled) ||
      (context === 'session' && sessionWhatsAppEnabled));

  return {
    email: {
      enabled: isEmailContextEnabled,
      channelEnabled: channelEmailEnabled,
    },
    sms: {
      enabled: isSmsContextEnabled,
      channelEnabled: channelSmsEnabled,
    },
    phone: {
      channelEnabled: channelPhoneEnabled,
    },
    whatsApp: {
      enabled: isWhatsAppContextEnabled,
      channelEnabled: channelWhatsAppEnabled,
    },
  };
}
