import { ClubPurchasePackage } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import {
  AggregatedBookingPurchasePackageWithPayments,
  AggregatedBookingSessionFull,
  BillingTabModelCounts,
  PRO_BookingParticipantFull,
  PRO_BookingResume,
} from '../../../models';
import { DiverBookingPageClubDiverLinkedData } from '../../01.loaded-content';
import { BillingTabModelBuilderFilterCriteria } from './billingTabModelBuilder.service';

export const billingTabModelBuilderFilter = {
  filterPurchasePackages,
  filterParticipants,
};

function filterPurchasePackages({
  purchasePackagesWithPayments,
  criteria,
  linkedData,
  counts,
}: {
  purchasePackagesWithPayments: AggregatedBookingPurchasePackageWithPayments[];
  criteria: BillingTabModelBuilderFilterCriteria & {
    filteredDiversIds: string[];
    currentDiveCenterId: string;
  };
  linkedData: DiverBookingPageClubDiverLinkedData;
  counts: BillingTabModelCounts;
}) {
  return purchasePackagesWithPayments.filter(({ purchasePackage }) => {
    if (
      !linkedData?.diverIdsBookingLoaded.includes(purchasePackage.diverId) &&
      purchasePackage.diverIds.find((diverId) =>
        linkedData?.diverIdsBookingLoaded.includes(diverId),
      ) === undefined
    ) {
      // pas dans les divers chargés
      return false;
    }
    if (
      criteria.currentDiveCenterId &&
      criteria.currentDiveCenterId !== purchasePackage.diveCenterId
    ) {
      counts.otherDiveCentersPurchasePackages++;
      if (criteria.ignoreOtherDiveCentersPurchasePackages) {
        counts.hiddenPurchasePackages++;
        return false;
      }
    }

    if (criteria.filteredDiversIds.length) {
      if (
        !criteria.filteredDiversIds.includes(purchasePackage.diverId) &&
        purchasePackage.diverIds.find((diverId) =>
          criteria.filteredDiversIds.includes(diverId),
        ) === undefined
      ) {
        // pas dans les divers filtrés
        return false;
      }
    }

    return true;
  });
}

function filterParticipants({
  realPurchasePackages,
  bookingParticipantsFull,
  criteria,
  linkedData,
  bookingResumesLoaded,
  counts,
}: {
  realPurchasePackages: ClubPurchasePackage[];
  bookingParticipantsFull: PRO_BookingParticipantFull[];
  criteria: BillingTabModelBuilderFilterCriteria & {
    filteredDiversIds: string[];
    currentDiveCenterId: string;
  };
  linkedData: DiverBookingPageClubDiverLinkedData;
  bookingResumesLoaded: PRO_BookingResume[];
  counts: BillingTabModelCounts;
}) {
  return bookingParticipantsFull.filter((bookingParticipantFull) => {
    const bookingProduct = bookingParticipantFull.bookingProductDive;
    if (
      !!bookingProduct.purchasePackageId ||
      bookingProduct.purchaseStatus === 'purchased'
    ) {
      // associated to a purchase
      const pp = realPurchasePackages.find(
        (x) => x._id === bookingProduct.purchasePackageId,
      );
      if (!pp) {
        // si c'est à associé à un purchase qui est masqué, alors il faut le masquer aussi
        return false;
      }
      return true;
    }
    if (criteria.filteredDiversIds.length) {
      // participant non facturé et ne correspondant pas au plongeur filtré
      if (
        !criteria.filteredDiversIds.includes(bookingParticipantFull.diver._id)
      ) {
        return false;
      }
    }

    // NOTE on pourrait ajouter bookingSessionDate dans bookingProduct en bdd, ça serait plus simple!
    const bookingSessionFull = bookingResumesLoaded.reduce(
      (fount, bookingResume) => {
        if (fount) {
          return fount;
        }
        const bookingSessionFull = bookingResume.bookingSessionsFull.find(
          (s) => s.bookingSession._id === bookingProduct.bookingSessionId,
        );
        return bookingSessionFull;
      },
      undefined as AggregatedBookingSessionFull,
    );

    const isPending = bookingProduct.purchaseStatus === 'pending';
    const isCancelled =
      bookingProduct.bookingProductState.value === 'cancelled';
    const isFuture = dateService.isFutureDayUTC(
      bookingSessionFull?.diveSession.time,
    );
    const isOlderThan1Month =
      !isFuture &&
      dateService.getAgeInMonths(bookingSessionFull?.diveSession.time) > 1;

    const isOlderThan6Months =
      !isFuture &&
      dateService.getAgeInMonths(bookingSessionFull?.diveSession.time) > 6;

    if (
      criteria.currentDiveCenterId &&
      criteria.currentDiveCenterId !==
        bookingParticipantFull.diveSession.diveCenterId
    ) {
      counts.otherDiveCentersParticipants++;
      if (criteria.ignoreOtherDiveCentersNotPurchasedParticipants) {
        counts.hiddenParticipants++;
        return false;
      }
    }

    if (isFuture && isPending) {
      counts.futureNotPurchasedParticipants++;
      if (criteria.ignoreFutureNotPurchasedParticipants) {
        if (isCancelled) {
          counts.cancelledParticipants++;
        }
        counts.hiddenParticipants++;
        return false;
      }
    }
    if (isCancelled) {
      if (isOlderThan1Month) {
        // old and cancelled: skip
        return false;
      } else if (isPending) {
        // not too old
        counts.cancelledParticipants++;
        if (criteria.ignoreCancelledParticipants) {
          counts.hiddenParticipants++;
          return false;
        }
      }
    }
    if (isOlderThan6Months && isPending) {
      counts.veryOldParticipants++;
      if (criteria.ignoreVeryOldNotPurchasedParticipants) {
        counts.hiddenParticipants++;
        return false;
      }
    }
    return true;
  });
}
