import { BookingJourneyStep } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { AccommodationLabel } from '../BookingLabelsComponents';
import { PeriodDatesLabel } from './DiverBookingCardHeaderAutoFix/PeriodDatesLabel';

export function DiverBookingCardJourneySteps({
  step,
  className,
}: {
  step: BookingJourneyStep;
  className?: string;
}) {
  const clubResume = useClubResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const stepDiveCenter = useMemo(
    () => clubResume.diveCenters.find((dc) => dc._id === step.diveCenterId),
    [clubResume.diveCenters, step.diveCenterId],
  );
  return (
    <div className={clsx('flex gap-2 flex-nowrap items-center', className)}>
      {isMultiDiveCenters && (
        <div className="flex-no-shrink">
          <DiveCenterProBadge
            className="w-24 overflow-hidden my-px"
            diveCenter={stepDiveCenter}
          />
        </div>
      )}
      {(!!step.arrival?.date || !!step.departure?.date) && (
        <span className={'text-xs font-bold text-gray-600'}>
          {'Séjour '}
          <PeriodDatesLabel
            beginDate={step.arrival?.date}
            endDate={step.departure?.date}
          />
        </span>
      )}
      <AccommodationLabel
        className="uppercase text-gray-600 font-bold text-xs"
        accommodationId={step.accommodationId}
        pickupActive={step.pickup?.active}
      />
    </div>
  );
}
