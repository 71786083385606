/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveMode } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import {
  useClubSettings,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import {
  AggregatedBookingSessionFull,
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResult,
  BookingResumeParticipantForSessionWithSession,
  PRO_BookingMemberFull,
  PRO_BookingMemberFull_WithDocs,
  PRO_BookingParticipantFull,
} from '../../models';
import {
  defaultParametersDiveModeUpdator,
  defaultParametersUpdator,
  diverBookingPageUpdateStateManager,
  quickBookingParticipantCreator,
  useDiverBookingPageUpdateDiverAndParticipant,
} from '../../services';
import {
  buildParticipantEditorCreateOpenInputState,
  buildParticipantEditorEditOpenInputState,
} from '../useDiverBookingPageBookingCardLocalState.hook';
import { BookingMassiveEditorInnerLocalState } from './useBookingMassiveEditorInnerLocalState.hook';

export type BookingMassiveEditorEventsTriggers = {
  onClickEditMember: (bookingMemberFull: PRO_BookingMemberFull_WithDocs) => any;
  onClickCreateParticipant: (
    participant: BookingParticipantEditorParticipant,
  ) => any;
  onClickEditParticipant: (
    participant: BookingParticipantEditorParticipant,
  ) => any;
};

export function useBookingMassiveEditorDialogActions({
  localState,
  eventsTriggers,
  onMemberUpdated,
}: {
  localState: BookingMassiveEditorInnerLocalState;
  eventsTriggers: BookingMassiveEditorEventsTriggers;
  onMemberUpdated: () => any;
}) {
  const {
    data: { participantsBySessions },
    state: { updateState, setUpdateState },
  } = localState;

  const diveCenterResume = useDiveCenterResume();

  const deleteParticipants = useCallback(
    ({
      bookingParticipantsFull,
    }: {
      bookingParticipantsFull: PRO_BookingParticipantFull[];
    }) => {
      const updateStateLocal =
        diverBookingPageUpdateStateManager.deleteParticipantsFromState({
          updateState,
          participants: bookingParticipantsFull.map((x) => ({
            bookingProductId: x.bookingProductDive._id,
            clubParticipantId: x.diveSessionParticipant._id,
            bookingSessionParticipantId: x.bookingSessionParticipant._id,
          })),
        });

      setUpdateState(updateStateLocal, {
        action: 'delete multiple participants',
        meta: {
          participants: bookingParticipantsFull.map((x) => ({
            session: dateService.formatUTC(
              x?.diveSession?.time,
              'DD/MM/YYYY HH:mm',
            ),
            participant: x.diveSessionParticipant?._id,
            member: x.bookingMember?._id,
            diver: x.diver?._id,
          })),
        },
      });
    },
    [setUpdateState, updateState],
  );

  const onClickParticipant = useCallback(
    (
      {
        bookingSessionFull,
        bookingParticipantFull,
        bookingMemberFull,
      }: {
        bookingParticipantFull?: PRO_BookingParticipantFull;
        bookingSessionFull: AggregatedBookingSessionFull;
        bookingMemberFull: PRO_BookingMemberFull_WithDocs;
      },
      origin: 'cell' | 'checkbox',
    ) => {
      // TODO vérifier qu'il n'y a pas un autre participant sur autre booking
      if (!bookingParticipantFull) {
        if (bookingMemberFull.diver.defaultDiveConfig?.diveMode) {
          const updateStateLocal =
            quickBookingParticipantCreator.createParticipantsIfPossible({
              context: {
                diveCenterResume,
                participantsBySessions,
                updateState,
              },
              newParticipants: [
                {
                  bookingSessionFull,
                  bookingResumeMembers: [bookingMemberFull],
                },
              ],
            });

          setUpdateState(updateStateLocal, {
            action: 'create single participant from default quick',
            meta: {
              session: dateService.formatUTC(
                bookingSessionFull?.diveSession?.time,
                'DD/MM/YYYY HH:mm',
              ),
              member: bookingMemberFull.bookingMember?._id,
              diver: bookingMemberFull.diver?._id,
            },
          });
        } else {
          // create (open dialog)
          const participant: BookingParticipantEditorParticipant =
            buildParticipantEditorCreateOpenInputState({
              bookingId: bookingMemberFull.booking._id,
              diver: bookingMemberFull.diver,
              bookingMember: bookingMemberFull.bookingMember,
              bookingSessionId: bookingSessionFull.bookingSession._id,
              diveSession: bookingSessionFull.diveSession,
              docResumes: bookingMemberFull.docResumes,
            });
          eventsTriggers.onClickCreateParticipant(participant);
        }
      } else {
        if (origin === 'checkbox') {
          deleteParticipants({
            bookingParticipantsFull: [bookingParticipantFull],
          });
        } else {
          // edit
          const participant: BookingParticipantEditorParticipant =
            buildParticipantEditorEditOpenInputState({
              bookingParticipantFull,
            });
          eventsTriggers.onClickEditParticipant(participant);
        }
      }
    },
    [
      diveCenterResume,
      participantsBySessions,
      updateState,
      setUpdateState,
      eventsTriggers,
      deleteParticipants,
    ],
  );
  const onClickRegisterAllDailyParticipants = useCallback(
    ({
      participants,
    }: {
      participants: BookingResumeParticipantForSessionWithSession[];
    }) => {
      const newParticipants = participants
        .filter(
          ({ bookingParticipantFullAnyBooking }) =>
            !bookingParticipantFullAnyBooking,
        )
        .map(({ bookingMemberFull, bookingSessionFull }) => ({
          bookingSessionFull,
          bookingResumeMembers: [bookingMemberFull],
        }));

      if (newParticipants.length) {
        const updateStateLocal =
          quickBookingParticipantCreator.createParticipantsIfPossible({
            context: {
              diveCenterResume,
              participantsBySessions,
              updateState,
            },
            newParticipants,
          });

        setUpdateState(updateStateLocal, {
          action:
            'massive: createParticipants onClickRegisterAllDailyParticipants',
        });
      }
    },
    [diveCenterResume, participantsBySessions, setUpdateState, updateState],
  );
  const onClickDeleteAllDailyParticipants = useCallback(
    ({
      participants,
    }: {
      participants: BookingResumeParticipantForSessionWithSession[];
    }) => {
      const registeredParticipants = participants
        .filter(
          ({ bookingParticipantFullSameBooking }) =>
            !!bookingParticipantFullSameBooking,
        )
        .map(
          ({ bookingParticipantFullSameBooking }) =>
            bookingParticipantFullSameBooking,
        );
      if (registeredParticipants.length) {
        deleteParticipants({
          bookingParticipantsFull: registeredParticipants,
        });
      }
    },
    [deleteParticipants],
  );

  const onClickRegisterAll = useCallback(() => {
    const sessionsWithUnregisteredParticipants = participantsBySessions
      .map(({ bookingSessionFull, participants }) => {
        const bookingResumeMembers = participants
          .filter(
            ({ bookingParticipantFullAnyBooking }) =>
              !bookingParticipantFullAnyBooking,
          )
          .map(({ bookingMemberFull }) => bookingMemberFull);
        return {
          bookingResumeMembers,
          bookingSessionFull,
        };
      })
      .filter((x) => x.bookingResumeMembers.length > 0);

    if (sessionsWithUnregisteredParticipants.length) {
      const updateStateLocal =
        quickBookingParticipantCreator.createParticipantsIfPossible({
          context: {
            diveCenterResume,
            participantsBySessions,
            updateState,
          },
          newParticipants: sessionsWithUnregisteredParticipants,
        });

      setUpdateState(updateStateLocal, {
        action: 'massive: createParticipants onClickRegisterAll',
      });
    }
  }, [diveCenterResume, participantsBySessions, setUpdateState, updateState]);

  const onUpdateMemberDiveMode = useCallback(
    async ({
      bookingMemberFull,
      diveMode,
    }: {
      bookingMemberFull: PRO_BookingMemberFull;
      diveMode: DiveMode;
    }) => {
      await defaultParametersDiveModeUpdator.updateDefaultDiveMode({
        bookingMemberFull,
        diveMode,
        localState,
      });
    },
    [localState],
  );

  const onConfirmParticipantUpdate =
    useDiverBookingPageUpdateDiverAndParticipant({
      setUpdateState,
      updateState,
    });

  const clubSettings = useClubSettings();

  const onConfirmMemberUpdate = useCallback(
    async ({
      hasChanges,
      result,
    }: {
      hasChanges: boolean;
      result?: BookingParticipantEditorResult;
    }) => {
      if (hasChanges) {
        const { updatedDivers, updatedBookingMembers } = result?.changes;
        const defaultDiveMode =
          result.aggregated.finalDiver.defaultDiveConfig?.diveMode;

        const { updateStateLocal } =
          await defaultParametersUpdator.updateDefaultMember({
            localState,
            updatedDivers,
            updatedBookingMembers,
          });

        onMemberUpdated();

        const {
          data: { bookingParticipantsWithSessionFull },
          state: { setUpdateState },
        } = localState;

        await defaultParametersUpdator.applyDefaultDiveConfigChanges({
          defaultDiveMode,
          initialDiver: result.initialDiver,
          finalDiver: result.aggregated.finalDiver,
          updateStateLocal,
          bookingMemberId: result.bookingMemberId,
          bookingParticipants: bookingParticipantsWithSessionFull,
          setUpdateState,
          dontConfirmToApplyChanges: participantsBySessions.length === 1,
          clubSettings,
        });
      }
      onMemberUpdated();
    },
    [clubSettings, localState, onMemberUpdated, participantsBySessions.length],
  );

  return {
    onClickRegisterAll,
    onClickParticipant,
    onClickRegisterAllDailyParticipants,
    onClickDeleteAllDailyParticipants,
    onUpdateMemberDiveMode,
    onConfirmMemberUpdate,
    onConfirmParticipantUpdate,
    ...eventsTriggers,
  };
}

export type BookingMassiveEditorActions = ReturnType<
  typeof useBookingMassiveEditorDialogActions
>;
