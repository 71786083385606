/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppInquiry, DIVE_MODES } from '@mabadive/app-common-model';
import {
  diveModeFormatter,
  inquiryFormatter,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIconsInquiry } from 'src/business/_core/modules/layout/icons';
import { AppFeatureBadge } from 'src/business/club/modules/_common/ui/AppFeatureBadge';
import { useClubSettings } from '../../../../../../business/club/data/hooks';
import { AppMultilineComment } from '../../../../../../business/club/modules/_common/form';

export const ClubSettingsInquiryCard = ({
  inquiry,
  onClick,
  className,
}: {
  inquiry: AppInquiry;
  onClick?: () => any;
  className?: string;
}) => {
  const type = inquiry.type;
  const Icon = AppIconsInquiry[inquiry.type];

  const inquiryTypeLabel = useMemo(
    () => inquiryFormatter.formatInquiryType(inquiry.type),
    [inquiry.type],
  );
  const sectionsCount = inquiry.sections.length;
  const questionsCount = inquiry.sections.reduce((acc, section) => {
    return acc + section.questions.length;
  }, 0);

  const autoActivation = inquiry.settings?.autoActivation;
  const minAge = autoActivation?.age?.min;
  const maxAge = autoActivation?.age?.max;
  const diveModes = autoActivation?.diveModes;
  const allDiveModes = diveModes?.length === DIVE_MODES.length;
  const clubSettings = useClubSettings();
  return (
    <div
      onClick={onClick}
      className={clsx(
        'app-card p-4 md:divide-y divide-gray-200 border-t-8',
        onClick ? 'cursor-pointer app-card-highlight' : '',
        className,
      )}
      style={{
        borderColor: inquiry.settings.color,
      }}
    >
      <div
        className={clsx(
          'py-2 flex items-center gap-2 text-xl font-bold uppercase',
        )}
        style={{
          color: inquiry.settings.enabled ? inquiry.settings.color : '#9ca3af',
        }}
      >
        {Icon && <Icon className="h-6 w-6" />}
        {inquiry.settings?.shortName && (
          <span
            className={clsx('inline-block px-1 rounded text-white font-bold')}
            style={{
              backgroundColor: inquiry.settings.enabled
                ? inquiry.settings.color
                : '#9ca3af',
            }}
          >
            {inquiry.settings?.shortName}
          </span>
        )}
        {inquiry.name ?? inquiryTypeLabel}
      </div>
      {/* <div className="py-2 font-medium text-gray-600">{inquiry.name}</div> */}
      {inquiry.settings?.privateComment?.trim()?.length && (
        <AppMultilineComment
          type="private"
          comment={inquiry.settings?.privateComment}
        />
        // <div className="my-2">{inquiry.settings?.privateComment}</div>
      )}
      <div className="py-2 grid gap-x-4 gap-y-2 lg:flex lg:justify-between">
        {inquiry.settings.enabled ? (
          <>
            <div className="font-bold text-gray-600">
              {sectionsCount > 1 && (
                <span>
                  {sectionsCount} section{sectionsCount > 1 && 's'},{' '}
                </span>
              )}
              <span>
                {questionsCount} question{questionsCount > 1 && 's'}
              </span>
            </div>
            {autoActivation?.enabled && (
              <div className="text-right">
                <div className="font-bold text-gray-600">
                  Activation automatique
                </div>
                {(minAge > 0 || maxAge > 0) && (
                  <div className="text-right text-app-blue">
                    {productPackageFormatter.formatAgeDescription({
                      minAge,
                      maxAge,
                    })}
                  </div>
                )}
                {diveModes?.length && (
                  <div className="text-right text-app-blue">
                    {allDiveModes
                      ? 'TOUS'
                      : diveModes
                          .map((diveMode) =>
                            diveModeFormatter.formatDiveMode(diveMode, {
                              format: 'short-ref',
                              diveModesConf: clubSettings?.ui?.diveMode,
                            }),
                          )
                          .join(', ')}
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <AppFeatureBadge
            label={'ACTIVÉ'}
            labelDisabled={'DÉSACTIVÉ'}
            enabled={inquiry.settings.enabled}
          />
        )}
      </div>
    </div>
  );
};
