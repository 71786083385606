import { ClubEvent } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { AppEmojiIcon } from 'src/lib/form';
import { CalendarSessionWidthMode } from '../../club-planning-light/components/ClubPlanningLightSession/session-common';

export const ClubEventCardHeader = function ({
  clubEvent,
  widthMode,
  className,
}: {
  clubEvent: ClubEvent;
  widthMode: CalendarSessionWidthMode;
  className?: string;
  onClick?: (clubEvent: ClubEvent) => void;
}) {
  return (
    <div
      className={clsx(
        'block text-left align-middle font-bold',
        'truncate sm:whitespace-normal',
        className,
      )}
    >
      {clubEvent.schedule.beginTime && (
        <div
          className={clsx(
            'inline',
            widthMode === 'large' ? 'px-1' : 'px-0.5',
            clubEvent.priority === 'high'
              ? 'bg-status-error text-white'
              : 'bg-gray-500 text-white',
          )}
        >
          {dateService.formatUTC(clubEvent.schedule.beginTime, 'HH:mm')}
        </div>
      )}
      {clubEvent.description.emojiId && (
        <AppEmojiIcon
          className={clsx(
            'pb-0.5  align-middle inline-block',
            widthMode === 'large' ? 'ml-1' : 'ml-0.5',
          )}
          id={clubEvent.description.emojiId}
          size={widthMode === 'large' ? 16 : 12}
        />
      )}
      <div
        className={clsx(
          'inline',
          widthMode === 'large' ? 'ml-1' : 'ml-0.5',
          clubEvent.priority === 'high' && 'text-status-error uppercase',
          clubEvent.status === 'cancelled' && 'line-through',
        )}
      >
        {clubEvent.description.title?.trim()}
      </div>
    </div>
  );
};
