import {
  monthPlanningBuilder,
  weekPlanningBuilder,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { appLoaderMerger } from 'src/business/_core/data/app-loading/loader/appLoaderMerger.service';
import { DiveSessionResumeLoadBounds } from 'src/business/_core/data/store-repository/dive-sessions/model';
import { ClubDialogsStateOld, useClubDialogsOld } from 'src/pages/_dialogs';
import {
  PLANNING_CONFIG_CONTEXT_ID_PLANNING_PARTICIPANTS_VIEW,
  usePlanningConfig,
} from '../../club-planning-light/hooks';
import {
  useCalendarWeekClubEvents,
  useCalendarWeekDailyConfigs,
  useFetchCalendarWeekDiveSessionResumes,
  useWeekPlanningConfigBounds,
} from '../_components';

export function usePlanningParticipantsLocalState() {
  const [planningConfig, setPlanningConfig] = usePlanningConfig({
    enableRouteParams: true,
    context: PLANNING_CONFIG_CONTEXT_ID_PLANNING_PARTICIPANTS_VIEW,
  });

  const weekPlanning = useMemo(
    () =>
      weekPlanningBuilder.buildWeekPlanning({
        focusDate: planningConfig.focusDate,
      }),
    [planningConfig.focusDate],
  );
  const monthPlanning = useMemo(
    () =>
      monthPlanningBuilder.buildMonthPlanning({
        focusDate: planningConfig.focusDate,
        mode: 'local',
      }),
    [planningConfig.focusDate],
  );

  const criteria: DiveSessionResumeLoadBounds = useWeekPlanningConfigBounds({
    planningConfig,
    weekPlanning,
    monthPlanning,
  });
  const { content: diveSessionResumes, ...loadableContent1 } =
    useFetchCalendarWeekDiveSessionResumes({
      criteria,
      mode: 'force-reload',
    });

  const { content: clubEvents, ...loadableContent2 } =
    useCalendarWeekClubEvents({
      criteria,
      mode: 'force-reload',
    });

  const { content: dailyConfigs, ...loadableContent3 } =
    useCalendarWeekDailyConfigs({
      criteria,
      mode: 'force-reload',
    });

  const loadableContent = useMemo(
    () =>
      appLoaderMerger.mergeLoadableContents(
        loadableContent1,
        loadableContent2,
        loadableContent3,
      ),
    [loadableContent1, loadableContent2, loadableContent3],
  );

  const dialogsOld: ClubDialogsStateOld = useClubDialogsOld();

  return {
    planningConfig,
    setPlanningConfig,
    weekPlanning,
    monthPlanning,
    diveSessionResumes,
    clubEvents,
    dailyConfigs,
    loadableContent,
    dialogsOld,
  };
}

export type PlanningParticipantsLocalState = ReturnType<
  typeof usePlanningParticipantsLocalState
>;
