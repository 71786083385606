import clsx from 'clsx';
import React from 'react';
import {
  DiveModeIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';

import { ProStatsFetchResults } from '@mabadive/app-common-model';
import { certificationFormatter } from '@mabadive/app-common-services';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DashboardReportPageExploParticipantsComparisonState } from './DashboardReportPageExploParticipants';

export const DashboardReportPageExploParticipantsTableRow = ({
  explo,
  comparisonDetails,
  className,
}: {
  explo: ProStatsFetchResults['exploParticipantsOverPeriod']['data']['byCertificationReference'][0];
  comparisonDetails: DashboardReportComparisonDetails<DashboardReportPageExploParticipantsComparisonState>;
  className?: string;
}) => {
  const { localState, showPeriodRangeComparison } = comparisonDetails;

  const selectedExplo = localState.state.uiConfig.selection?.explo;
  const hoverExplo = localState.state.uiConfig.hover?.explo;

  const isSelected =
    selectedExplo?.certificationReference === explo.certificationReference;
  const isHover =
    hoverExplo?.certificationReference === explo.certificationReference;

  const mainCurrency = useAppCurrencyMain();

  return (
    <tr
      className={clsx(
        'overflow-hidden',
        isSelected ? 'app-tr-selected' : isHover && 'app-tr-highlighted',
        className,
      )}
      onMouseEnter={() =>
        localState.state.setHover({
          explo: {
            certificationReference: explo.certificationReference,
          },
        })
      }
      onMouseLeave={() => localState.state.setHover()}
      onClick={() =>
        localState.state.setSelection({
          explo: {
            certificationReference: explo.certificationReference,
          },
        })
      }
    >
      <th
        className={clsx(
          'text-left whitespace-nowrap px-2 w-[50%]',
          explo.diversCount === 0 ? 'text-gray-400' : '',
        )}
      >
        <div className="flex flex-wrap items-end gap-x-1 xl:gap-x-1.5 text-xs md:text-sm pt-1">
          <span className="md:min-w-[40px] font-bold text-gray-800">
            {explo.certificationReference ?? '?'}
          </span>
          <span
            className={clsx(
              'text-xs font-medium truncate',
              showPeriodRangeComparison
                ? 'hidden xl:inline'
                : 'hidden md:inline',
            )}
          >
            {certificationFormatter.formatCertificationReference(
              explo.certificationReference,
              {
                format: 'name',
              },
            )}
          </span>
        </div>
      </th>
      <td
        className={clsx(
          'text-app-primary text-right text-lg px-4 whitespace-nowrap font-bold',
          explo.diversCount === 0 ? 'opacity-30' : '',
        )}
      >
        {explo.diversCount}{' '}
        <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
      </td>
      <td
        className={clsx(
          'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
          explo.participantsCount === 0 ? 'opacity-30' : '',
        )}
      >
        <span
          className={clsx(
            explo.participantsCountPurchased === explo.participantsCount
              ? 'font-bold'
              : 'font-normal',
          )}
        >
          {explo.participantsCountPurchased}
        </span>{' '}
        / {explo.participantsCount}{' '}
        <DiveModeIcons.autonomous className={clsx('inline w-6 h-6')} />
      </td>
      <td
        className={clsx(
          'text-right text-lg px-4 text-gray-500 font-normal whitespace-nowrap',
          showPeriodRangeComparison
            ? 'hidden xl:table-cell'
            : 'hidden lg:table-cell',
        )}
      >
        {explo.diversCount !== 0
          ? (
              Math.round((10 * explo.participantsCount) / explo.diversCount) /
              10
            ).toFixed(1)
          : null}
      </td>
      <td
        className={clsx(
          'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
          explo.participantsCount === 0 ? 'opacity-30' : '',
        )}
      >
        <AppPriceLabel amount={explo.priceAvg} mainCurrency={mainCurrency} />
      </td>
    </tr>
  );
};
