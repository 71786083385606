/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import {
  APP_FORM_CLASSNAME,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useClubResume } from 'src/business/club/data/hooks';
import { DailyExportConfigDialogActionsBar } from './DailyExportConfigDialogActionsBar';
import { DailyExportConfigDialogState } from './_model';
import { DailyExportConfigOptionsPanel } from './panels';
import {
  DailyExportConfigDialogLocalState,
  useDailyExportConfigDialogLocalState,
} from './useDailyExportConfigDialogLocalState.hook';

export const DailyExportConfigDialog: FC<DailyExportConfigDialogState> = (
  inputState,
) => {
  const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] = useState(false);
  const globalClasses = useGlobalClasses();

  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const clubReference = clubResume?.reference;

  const { isOpen, initialState } = inputState;

  const { exportRef } = initialState;

  const localState: DailyExportConfigDialogLocalState =
    useDailyExportConfigDialogLocalState({
      inputState,
    });

  const { data, form } = localState;
  const { pageState } = data;

  return !isOpen ? null : (
    <AppPageContainerWithFixedBars
      className="max-w-screen-2xl"
      contentClassName="bg-gray-50"
      footerBar={() => (
        <DailyExportConfigDialogActionsBar localState={localState} />
      )}
    >
      <div className="app-p-content">
        {pageState.step === 'edit-daily-export-activity' && (
          <div className={clsx(APP_FORM_CLASSNAME, 'my-5')}>
            <DailyExportConfigOptionsPanel localState={localState} />
          </div>
        )}
      </div>
    </AppPageContainerWithFixedBars>
  );
};
