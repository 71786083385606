
import { AccountProfile, AuthUser } from '@mabadive/app-common-model';
import { loadableAttributeStoreFactory } from '@mabadive/app-common-services';
import { getBaseStore } from './baseStore';


export const accountStore = {
  profile: loadableAttributeStoreFactory.create<AccountProfile>(getBaseStore(), 'acc_profile'),
  authUser: loadableAttributeStoreFactory.create<AuthUser>(getBaseStore(), 'acc_auth_user'),
};
