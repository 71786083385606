/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingAgency,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import React, { useCallback, useMemo } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppButtonsBar,
  AppPageContainerWithFixedBars,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import useRouter from 'use-react-router';
import { clubSettingsOtherUrlBuilder } from '../../clubSettingsOtherUrlBuilder.service';
import { agencyFormResultBuilder } from './agencyFormResultBuilder.service';
import { BookingAgencyEditForm } from './form';
import {
  BookingAgencyEditPageLocalState,
  useBookingAgencyEditPageLocalState,
} from './useBookingAgencyEditPageLocalState.hook';

export const BookingAgencyEditPage = ({
  navigationContext: { mode },
}: {
  navigationContext: { mode: 'create' | 'edit' };
}) => {
  const { match, history } =
    useRouter<{
      agencyId: string;
    }>();

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const params = new URLSearchParams(window.location.search);

  const agencyId = match.params.agencyId;

  const localState: BookingAgencyEditPageLocalState =
    useBookingAgencyEditPageLocalState({
      mode,
      agencyId,
    });

  const globalClasses = useGlobalClasses();

  const redirectTo = useRedirect();

  const { agency, form, initialFormValue } = localState;
  const { handleSubmit } = form;

  const back = useCallback(() => {
    return redirectTo(clubSettingsOtherUrlBuilder.bookingAgencies.list());
  }, [redirectTo]);

  useMemo(() => {
    if (mode === 'edit' && !agency) {
      appLogger.warn('Invalid agencyId: redirect');
      back();
    }
  }, [back, mode, agency]);

  const persistChanges = useCallback(() => {
    handleSubmit((formValue: BookingAgency, event) => {
      if (mode === 'edit') {
        const value = agencyFormResultBuilder.buildResult(formValue);
        const patchOperations = jsonPatcher.compareObjects(
          initialFormValue,
          value,
          {
            attributesToReplaceFully: [],
            replaceDeleteByNullValue: true,
          },
        );
        if (patchOperations.length) {
          const payload: ProMultiOperationPayload =
            clubMassiveUpdatorClient.createEmptyPayload({
              logContext: 'edit agency',
              bookingIdsToClean: [],
            });
          payload.bookingAgencies = {
            updated: [
              {
                pk: agency?._id,
                patchOperations,
              },
            ],
          };
          clubMassiveUpdatorClient.update(payload).then(() => {
            back();
          });
        } else {
          back();
        }
      } else if (mode === 'create') {
        const value = agencyFormResultBuilder.buildResult(formValue);
        const payload: ProMultiOperationPayload =
          clubMassiveUpdatorClient.createEmptyPayload({
            logContext: 'create agency',
            bookingIdsToClean: [],
          });
        payload.bookingAgencies = {
          created: [
            commonEntityBuilder.buildEntity<BookingAgency>({
              ...value,
              clubReference,
            }),
          ],
        };
        clubMassiveUpdatorClient.update(payload).then(() => {
          back();
        });
      }
    })();
  }, [handleSubmit, mode, initialFormValue, agency?._id, back, clubReference]);

  const { control } = form;

  return (
    <form
      className="w-full"
      onSubmit={(e) => {
        persistChanges();
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <AppPageContainerWithFixedBars
        className="max-w-screen-2xl"
        contentClassName="bg-gray-50"
        footerBar={() => (
          <AppButtonsBar className="" hasChanges={form.formState.isDirty}>
            <>
              <AppButton
                className="my-2 w-full uppercase"
                fullWidth={true}
                color={'primary-outline-light'}
                size="normal"
                icon={AppHeroIcons.actionCancel}
                onClick={() => back()}
              >
                Annuler
              </AppButton>
              <AppButton
                type="submit"
                className="my-2 w-full uppercase"
                fullWidth={true}
                color={'primary-outline-light'}
                size="normal"
                icon={AppHeroIcons.actionSave}
              >
                Enregistrer
              </AppButton>
            </>
          </AppButtonsBar>
        )}
      >
        <AppPageContentContainer className="app-p-content bg-gray-50">
          <BookingAgencyEditForm localState={localState} />
        </AppPageContentContainer>
      </AppPageContainerWithFixedBars>
    </form>
  );
};
