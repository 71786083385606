/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC } from 'react';
import { ClubSettingsOnlineBookingMainForm } from '../tabs';
import { ClubSettingsOnlineBookingEmailsForm } from '../tabs/02.emails';
import { ClubSettingsOnlineBookingLegalInformationForm } from '../tabs/06.legal-information/form';
import { ClubSettingsOnlineBookingDialogLocalState } from './useClubSettingsOnlineBookingDialogLocalState.hook';

export const ClubSettingsOnlineBookingDialogForm: FC<{
  localState: ClubSettingsOnlineBookingDialogLocalState;
}> = ({ localState }) => {
  const { form, initialState, dialogsState } = localState;
  const { tabId } = initialState;

  return (
    <div className={clsx('app-card app-p-content')}>
      {tabId === 'config' ? (
        <ClubSettingsOnlineBookingMainForm
          form={form}
          initialState={initialState}
        />
      ) : tabId === 'emails' ? (
        <ClubSettingsOnlineBookingEmailsForm
          form={form}
          dialogsState={dialogsState}
        />
      ) : tabId === 'legal-information' ? (
        <ClubSettingsOnlineBookingLegalInformationForm
          form={form}
          dialogsState={dialogsState}
        />
      ) : null}
    </div>
  );
};
