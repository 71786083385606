/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageAttributeProductType,
  ClubProductPackageAttributesDive,
  ClubProductPackageAttributesProduct,
  ClubProductPackageAttributesTraining,
  ClubProductPackageOffer,
  ClubProductPackageOfferView,
  ClubProductPackageType,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { ClubOfferDiveEditorContext } from './ClubOfferDiveEditorContext.type';
import { ClubOfferDiveEditorFormModel } from './ClubOfferDiveEditorFormModel.type';
import { ClubOfferDiveEditorResult } from './ClubOfferDiveEditorResult.type';

export const clubOfferDiveEditorResultBuilder = {
  buildResult,
  rebuildReference,
  rebuildReferenceProductExtra,
  testIfReferenceObsolete,
};

function buildResult({
  formValue,
  context,
  clubReference,
}: {
  formValue: ClubOfferDiveEditorFormModel;
  context: ClubOfferDiveEditorContext;
  clubReference: string;
}): {
  hasChanges: boolean;
  result?: ClubOfferDiveEditorResult;
} {
  const type = formValue.type;
  const productType = formValue.productType;
  const productAttributes: ClubProductPackageAttributesProduct = {
    includeDives: true,
    // defaultOrganizationReference: undefined,
    // organizationReferences: undefined,
    // certificationReference: undefined,
    minAge: formValue.productAttributes?.minAge,
    maxAge: formValue.productAttributes?.maxAge,
    sharedOfferMaxPersonsCount:
      formValue.productAttributes?.sharedOfferMaxPersonsCount,
    validityPeriodInDays: formValue.productAttributes?.validityPeriodInDays,
  };
  const diveAttributes: ClubProductPackageAttributesDive = {
    diveMode: formValue.diveMode,
    firstDiveTrainingReference: formValue.firstDiveTrainingReference,
    extraDiveModes: formValue.extraDiveModes,
    diveType: formValue.diveType,
    divePriceType: formValue.divePriceType,
    successiveDivesCount: formValue.successiveDivesCount,
    equipment: formValue.equipment,
    supervision: formValue.supervision,
    specialDiveType: formValue.specialDiveType,
    divesCount: formValue.divesCountInfinite ? 9999 : formValue.divesCount,
    divesCountMax:
      formValue.divesCountMax > formValue.divesCount
        ? formValue.divesCountMax
        : undefined,
    divesCountInfinite: formValue.divesCountInfinite,
    minDepth: formValue.minDepth,
    maxDepth: formValue.maxDepth,
    minDistance: formValue.minDistance,
    maxDistance: formValue.maxDistance,
    diveSingleAttributes:
      formValue.divePriceType === 'single'
        ? formValue.diveSingleAttributes
        : null,
    divePackageAttributes:
      formValue.divePriceType === 'package'
        ? formValue.divePackageAttributes
        : null,
  };

  const newReference = rebuildReference({
    clubReference,
    type,
    productType,
    diveAttributes: formValue,
    productAttributes: undefined,
    trainingAttributes: undefined,
  });

  if (context.mode === 'create' || context.mode === 'duplicate') {
    const reference = newReference;

    const newOffer = commonEntityBuilder.buildEntity<ClubProductPackageOffer>({
      reference,
      clubReference: clubReference,
      price: formValue.price,
      thirdPartyCollectPrice: formValue.thirdPartyCollectPrice,
      productPackage: {
        reference,
        type: 'dive',
        productAttributes,
        diveAttributes,
        salesCriteria: formValue.salesCriteria,
        namingConfiguration: formValue.namingConfiguration,
        comment: formValue.comment,
        label: formValue.label,
      },
    });
    const result: ClubOfferDiveEditorResult = {
      newOffer,
    };
    return {
      hasChanges: true,
      result,
    };
  } else if (context.mode === 'edit') {
    const initialOffer = context.offer;

    const isReferenceObsolete = testIfReferenceObsolete({
      initial: {
        diveAttributes: initialOffer.productPackage.diveAttributes,
      },
      final: {
        diveAttributes: formValue,
      },
    });

    // rebuild reference if main attributes changes
    const reference = isReferenceObsolete
      ? newReference
      : initialOffer.reference;

    const updatedOffer: ClubProductPackageOfferView = {
      ...context.offer,
      reference,
      price: formValue.price,
      thirdPartyCollectPrice: formValue.thirdPartyCollectPrice,
      productPackage: {
        reference,
        type: 'dive',
        productAttributes,
        diveAttributes,
        salesCriteria: formValue.salesCriteria,
        namingConfiguration: formValue.namingConfiguration,
        label: formValue.label,
        comment: formValue.comment,
      },
    };

    const productPatchOperations = jsonPatcher.compareObjects(
      initialOffer,
      updatedOffer,
      {
        attributesToReplaceFully: ['productPackage'],
      },
    );
    if (productPatchOperations.length) {
      const updatedOffer = {
        pk: initialOffer._id,
        patchOperations: productPatchOperations,
      };
      const result: ClubOfferDiveEditorResult = {
        updatedOffer,
      };
      return {
        hasChanges: true,
        result,
      };
    }
  }
  return {
    hasChanges: false,
  };
}

function rebuildReferenceProductExtra({
  clubReference,
  productType,
}: {
  clubReference: string;
  productType: ClubProductPackageAttributeProductType;
}) {
  return `${clubReference};product;${productType};${new Date().getTime()}-${Math.random()}`;
}

function rebuildReference({
  clubReference,
  type,
  productType,
  diveAttributes,
  trainingAttributes,
  productAttributes,
}: {
  clubReference: string;
  type: ClubProductPackageType;
  productType: ClubProductPackageAttributeProductType;
  diveAttributes: Pick<
    ClubProductPackageAttributesDive,
    'divesCount' | 'diveMode' | 'equipment'
  >;
  trainingAttributes?: Pick<
    ClubProductPackageAttributesTraining,
    'trainingType' | 'diveTrainingReference'
  >;
  productAttributes?: Pick<
    ClubProductPackageAttributesProduct,
    'defaultOrganizationReference'
  >;
}) {
  if (type === 'product') {
    return rebuildReferenceProductExtra({
      clubReference,
      productType,
    });
  }
  if (diveAttributes?.diveMode === 'training') {
    const newReference = `${clubReference};training;${
      trainingAttributes.diveTrainingReference
    };${productAttributes.defaultOrganizationReference};${
      trainingAttributes.trainingType
    };${new Date().getTime()}-${Math.random()}`;

    return newReference;
  }

  // explo
  return `${clubReference};dive;x${diveAttributes?.divesCount};${
    diveAttributes?.diveMode
  };${diveAttributes?.equipment};${new Date().getTime()}-${Math.random()}`;
}
function testIfReferenceObsolete({
  initial,
  final,
}: {
  initial: {
    diveAttributes: Pick<
      ClubProductPackageAttributesDive,
      'divesCount' | 'diveMode' | 'equipment'
    >;
    trainingAttributes?: Pick<
      ClubProductPackageAttributesTraining,
      'trainingType' | 'diveTrainingReference'
    >;
    productAttributes?: Pick<
      ClubProductPackageAttributesProduct,
      'defaultOrganizationReference'
    >;
  };
  final: {
    diveAttributes: Pick<
      ClubProductPackageAttributesDive,
      'divesCount' | 'diveMode' | 'equipment'
    >;
    trainingAttributes?: Pick<
      ClubProductPackageAttributesTraining,
      'trainingType' | 'diveTrainingReference'
    >;
    productAttributes?: Pick<
      ClubProductPackageAttributesProduct,
      'defaultOrganizationReference'
    >;
  };
}) {
  if (final.diveAttributes?.diveMode === 'training') {
    const isReferenceObsolete =
      final.trainingAttributes?.trainingType !==
        initial.trainingAttributes?.trainingType ||
      final.trainingAttributes?.diveTrainingReference !==
        initial.trainingAttributes?.diveTrainingReference ||
      final.productAttributes?.defaultOrganizationReference !==
        initial.productAttributes?.defaultOrganizationReference;

    return isReferenceObsolete;
  }

  const isReferenceObsolete =
    final.diveAttributes?.divesCount !== initial.diveAttributes?.divesCount ||
    final.diveAttributes?.diveMode !== initial.diveAttributes?.diveMode ||
    final.diveAttributes?.equipment !== initial.diveAttributes?.equipment;

  return isReferenceObsolete;
}
