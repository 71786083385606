import { apiClient } from 'src/_common-browser';

export const paymentPlatformClient = {
  createConnectedAccount,
  refreshConnectedAccount,
  refreshPaymentIntent,
  capturePaymentIntent,
  getConnectedAccountOnboardingLink,
  createBookingDeposit,
};

function createConnectedAccount(): Promise<any> {
  return apiClient
    .post<any>('/pro/payment-platforms/create-connected-account', {
      authenticate: true,
      json: {},
      maxTries: 1,
    })
    .toPromise();
}

function refreshConnectedAccount({
  paymentPlatformId,
}: {
  paymentPlatformId: string;
}): Promise<any> {
  return apiClient
    .post<any>('/pro/payment-platforms/refresh-connected-account', {
      authenticate: true,
      json: {
        paymentPlatformId,
      },
    })
    .toPromise();
}
function refreshPaymentIntent({
  extPaymentIntentId,
}: {
  extPaymentIntentId: string;
}): Promise<any> {
  return apiClient
    .post<any>('/pro/payment-platforms/refresh-payment-intent', {
      authenticate: true,
      json: {
        extPaymentIntentId,
      },
    })
    .toPromise();
}
function capturePaymentIntent({
  extPaymentIntentId,
  amount,
}: {
  extPaymentIntentId: string;
  amount?: number;
}): Promise<any> {
  return apiClient
    .post<any>('/pro/payment-platforms/capture-payment-intent', {
      authenticate: true,
      json: {
        extPaymentIntentId,
        amount,
      },
    })
    .toPromise();
}

function getConnectedAccountOnboardingLink({
  paymentPlatformId,
}: {
  paymentPlatformId: string;
}): Promise<{ onboardingLink: string }> {
  return apiClient
    .put<any>('/pro/payment-platforms/get-connected-account-onbording-link', {
      authenticate: true,
      json: {
        paymentPlatformId,
      },
    })
    .toPromise();
}

function createBookingDeposit({
  bookingId,
  paymentPlatformId,
  amount,
}: {
  bookingId: string;
  paymentPlatformId: string;
  amount: number;
}): Promise<{ onboardingLink: string }> {
  return apiClient
    .put<any>('/pro/payment-platforms/create-booking-deposit', {
      authenticate: true,
      json: {
        bookingId,
        paymentPlatformId,
        amount,
      },
    })
    .toPromise();
}
