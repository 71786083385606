import {
  monthPlanningBuilder,
  weekPlanningBuilder,
} from '@mabadive/app-common-services';
import { useMemo, useState } from 'react';
import { appLoaderMerger } from 'src/business/_core/data/app-loading/loader/appLoaderMerger.service';
import { DiveSessionResumeLoadBounds } from 'src/business/_core/data/store-repository/dive-sessions/model';
import { ClubDialogsStateOld, useClubDialogsOld } from 'src/pages/_dialogs';
import { SetPlanningConfigOptions } from '../../club-planning-light/hooks';
import {
  useCalendarWeekClubEvents,
  useCalendarWeekDailyConfigs,
  useFetchCalendarWeekDiveSessionResumes,
  useWeekPlanningConfigBounds,
} from '../_components';
import { PlanningViewConfig } from '../_model';

export function usePlanningStaffLocalState({
  planningConfig,
  setPlanningConfig,
}: {
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
}) {
  const weekPlanning = useMemo(
    () =>
      weekPlanningBuilder.buildWeekPlanning({
        focusDate: planningConfig.focusDate,
      }),
    [planningConfig.focusDate],
  );
  const monthPlanning = useMemo(
    () =>
      monthPlanningBuilder.buildMonthPlanning({
        focusDate: planningConfig.focusDate,
        mode: 'local',
      }),
    [planningConfig.focusDate],
  );

  const criteria: DiveSessionResumeLoadBounds = useWeekPlanningConfigBounds({
    planningConfig,
    weekPlanning,
    monthPlanning,
  });
  const { content: diveSessionResumes, ...loadableContent1 } =
    useFetchCalendarWeekDiveSessionResumes({
      criteria,
      mode: 'force-reload',
    });

  const { content: clubEvents, ...loadableContent2 } =
    useCalendarWeekClubEvents({
      criteria,
      mode: 'force-reload',
    });

  const { content: dailyConfigs, ...loadableContent3 } =
    useCalendarWeekDailyConfigs({
      criteria,
      mode: 'force-reload',
    });

  const loadableContent = useMemo(
    () =>
      appLoaderMerger.mergeLoadableContents(
        loadableContent1,
        loadableContent2,
        loadableContent3,
      ),
    [loadableContent1, loadableContent2, loadableContent3],
  );

  // @see REF TRI MONITEUR
  // NOTE: tout ça est très mal orchestré, à revoir
  // on passe un callback pour pouvoir récuperer la date de sauvegarde
  // puis en fonction de la date de sauvegarde, on met à jour le tri de la liste des moniteurs sur le planning des moniteurs
  const [lastPersistDate, setLastPersistDate] = useState<Date>(new Date());

  const dialogsOld: ClubDialogsStateOld = useClubDialogsOld({
    onCloseSessionEditorDialog: () => {
      // @see REF TRI MONITEUR
      // quand on ferme la session (modif ou non)
      setTimeout(() => {
        // on attend que les données aient été mises à jour (ça peut ne pas marcher suivant le délai)
        // TODO: à revoir mais ça fait le job en attendant
        setLastPersistDate(new Date());
      }, 1000);
    },
  });

  return {
    planningConfig,
    setPlanningConfig,
    weekPlanning,
    monthPlanning,
    diveSessionResumes,
    clubEvents,
    dailyConfigs,
    loadableContent,
    dialogsOld,
    state: {
      lastPersistDate,
    },
    callback: {
      onPersistSuccess: () => {
        // @see REF TRI MONITEUR
        // quand on modifie les jours de présence, et qu'on persiste les données
        setLastPersistDate(new Date());
      },
    },
  };
}

export type PlanningStaffLocalState = ReturnType<
  typeof usePlanningStaffLocalState
>;
