import { ClubProductPackageOfferView } from '@mabadive/app-common-model';
import { ClubProductPackageOfferViewPlanGroup } from '@mabadive/app-common-services';
import { useState } from 'react';
import { DiverPurchaseEditorParticipantsConfig } from '../DiverPurchaseCommonEditorDialog/model';

import { DiverPurchasePlanDialogLocalStateInitialData } from './useDiverPurchasePlanDialogLocalStateInitialData.service';

export function useDiverPurchasePlanDialogLocalStateUseStates({
  initialData,
}: {
  initialData: DiverPurchasePlanDialogLocalStateInitialData;
}) {
  const {
    successiveDivesCount,
    productPackageOffersCriteria,
    productPackageOffers,
    planGroups,
    defaultProductPackageOffer,
    initialAssociatedBookingProductIds,
    initialFormValue,
    includeOtherDiversDefaultValue,
  } = initialData;

  const states = {
    openExtraCostsFormPanelState: useState(false),
    pageLoadedState: useState(false),
    updatedAssociatedBookingProductIdsState: useState(
      initialAssociatedBookingProductIds,
    ),
    selectedPlanGroupDataState: useState<{
      filteredProductPackageOffers: ClubProductPackageOfferView[];
      selectedPlanGroup?: ClubProductPackageOfferViewPlanGroup;
    }>({
      filteredProductPackageOffers: productPackageOffers,
      selectedPlanGroup: undefined,
    }),
    participantsConfigState: useState<DiverPurchaseEditorParticipantsConfig>({
      includeOtherDivers: includeOtherDiversDefaultValue,
      includeOtherTypes: false,
    }),
  };
  const [updatedAssociatedBookingProductIds, setAssociatedBookingProductIds] =
    states.updatedAssociatedBookingProductIdsState;

  const [selectedPlanGroupData, setSelectedPlanGroupData] =
    states.selectedPlanGroupDataState;

  const [participantsConfig, setParticipantsConfig] =
    states.participantsConfigState;

  const [pageLoaded, setPageLoaded] = states.pageLoadedState;

  const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] =
    states.openExtraCostsFormPanelState;

  return {
    states,
    updatedAssociatedBookingProductIds,
    setAssociatedBookingProductIds,
    selectedPlanGroupData,
    setSelectedPlanGroupData,
    participantsConfig,
    setParticipantsConfig,
    pageLoaded,
    setPageLoaded,
    openExtraCostsFormPanel,
    setOpenExtraCostsFormPanel,
  };
}

export type DiverPurchasePlanDialogLocalStateUseStates = ReturnType<
  typeof useDiverPurchasePlanDialogLocalStateUseStates
>;
