import React, { FC } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { CreateMessageToCustomersFormStep1_Message } from './CreateMessageToCustomersFormStep1_Message';
import { CreateMessageToCustomersFormStep2_Recipients } from './CreateMessageToCustomersFormStep2_Recipients';
import { CreateMessageToCustomersFormStep3_Content } from './CreateMessageToCustomersFormStep3_Content';
import { CreateMessageToCustomersFormStep4_SendOneByOne } from './CreateMessageToCustomersFormStep4_SendOneByOne';
import { CreateMessageToCustomersLocalState } from './useCreateMessageToCustomersLocalState.hook';
export const CreateMessageToCustomersPanel: FC<{
  localState: CreateMessageToCustomersLocalState;
  className?: string;
}> = ({ localState, className }) => {
  const redirectTo = useRedirect();

  const diveCenterResume = useDiveCenterResume();
  const clubResume = diveCenterResume.clubResume;
  const clubReference = clubResume?.reference;

  const {
    state: { step },
  } = localState;

  return (
    <>
      {step === 'step1-message' ? (
        <CreateMessageToCustomersFormStep1_Message
          className={className}
          localState={localState}
        />
      ) : step === 'step2-recipients' ? (
        <CreateMessageToCustomersFormStep2_Recipients
          className={className}
          localState={localState}
        />
      ) : step === 'step3-content' ? (
        <CreateMessageToCustomersFormStep3_Content
          className={className}
          localState={localState}
        />
      ) : step === 'step4-send-one-by-one' ? (
        <CreateMessageToCustomersFormStep4_SendOneByOne
          className={className}
          localState={localState}
        />
      ) : null}
    </>
  );
};
