/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from '@material-ui/core';
import React from 'react';
import {
  RxjsFormAppTextField,
  RxjsFormControl,
} from 'src/lib/form/components/rxjs-form';
import { CreateClubAccountFormComponentsModel } from './CreateClubAccountFormComponentsModel.type';

export const CreateClubAccountForm = ({
  components,
  autoFocus,
}: {
  components: CreateClubAccountFormComponentsModel;
  autoFocus?: boolean;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <RxjsFormControl
          label="Nom du centre de plongée"
          attrState={components['clubName'].state}
          renderComponent={() => (
            <RxjsFormAppTextField
              fullWidth
              {...components['clubName']}
              autoFocus={autoFocus}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <RxjsFormControl
          label="Ville, Région / Pays"
          attrState={components['clubCity'].state}
          renderComponent={() => (
            <RxjsFormAppTextField fullWidth {...components['clubCity']} />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <RxjsFormControl
          label="Identifiant"
          attrState={components['userAuthEmailLogin'].state}
          renderComponent={() => (
            <RxjsFormAppTextField
              fullWidth
              {...components['userAuthEmailLogin']}
              autoComplete="email"
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <RxjsFormControl
          label="Choisir un mot de passe"
          attrState={components['userAuthPassword'].state}
          renderComponent={() => (
            <RxjsFormAppTextField
              fullWidth
              {...components['userAuthPassword']}
              type="password"
              autoComplete="new-password"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
