import { SessionPickup } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout';
import {
  AppIconsAction,
  AppIconsSettings,
} from 'src/business/_core/modules/layout/icons';
import {
  AppFormControlRHF_Deprecated,
  AppInputTimeHoursMinutesRHF,
} from 'src/lib/form';

export type AccommodationPickupFormModel = {
  accommodationsPickups: {
    accommodationId: string;
    pickupTimeHoursMinutes: {
      hours: number;
      minutes: number;
    };
  }[];
};

export const AccommodationPickupForm = ({
  sessionPickup,
  className,
  onCancel,
  onConfirm,
}: {
  sessionPickup: SessionPickup;
  className?: string;
  onCancel: () => any;
  onConfirm: ({
    formValue,
    defaultValues,
  }: {
    formValue: AccommodationPickupFormModel;
    defaultValues: AccommodationPickupFormModel;
  }) => any;
}) => {
  const defaultValues: AccommodationPickupFormModel = useMemo(
    () => ({
      accommodationsPickups: sessionPickup.accommodationsPickups.map((x) => ({
        accommodationId: x.accommodation._id,
        pickupTimeHoursMinutes: {
          hours: x.pickupTime.getUTCHours(),
          minutes: x.pickupTime.getUTCMinutes(),
        },
      })),
    }),
    [sessionPickup.accommodationsPickups],
  );

  const form = useForm<AccommodationPickupFormModel>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { control, handleSubmit, formState } = form;

  const onSubmit = useCallback(async () => {
    if (formState.isDirty) {
      await handleSubmit(async (formValue) => {
        onConfirm({ formValue, defaultValues });
      })();
    } else {
      onCancel();
    }
  }, [defaultValues, formState.isDirty, handleSubmit, onCancel, onConfirm]);

  return (
    <form
      className={clsx('flex flex-col gap-2', className)}
      onSubmit={onSubmit}
    >
      <div className={clsx('flex flex-col gap-1')}>
        {sessionPickup.accommodationsPickups.map(
          ({ pickupTime, accommodation, participants }, i) => (
            <div className={'flex flex-wrap gap-1 items-center'} key={i}>
              <AppIconsSettings.customers.pickup
                className="w-6 h-4 border border-gray-600 py-px px-pt"
                style={{
                  background: accommodation.color,
                  color: accommodation.colorText,
                }}
              />
              <AppFormControlRHF_Deprecated
                className="border border-gray-400"
                // label={'Heure'}
                control={control}
                name={
                  `accommodationsPickups.${i}.pickupTimeHoursMinutes` as const
                }
                required={false}
                renderComponent={(props) => (
                  <AppInputTimeHoursMinutesRHF {...props} />
                )}
              />

              <span className="font-medium whitespace-nowrap">
                {accommodation.name}
              </span>
              <span className="text-gray-500 whitespace-nowrap text-sm">
                ({participants.length} personne
                {participants.length === 1 ? '' : 's'})
              </span>
            </div>
          ),
        )}
      </div>
      <div className="flex gap-2 px-2">
        <AppButton
          type="button"
          fullWidth={true}
          className=" xs:max-w-[12rem]"
          color="primary-outline-light"
          icon={AppIconsAction.cancel}
          onClick={() => {
            onCancel();
          }}
        >
          Annuler
        </AppButton>
        <AppButton
          type="submit"
          fullWidth={true}
          className=" xs:max-w-[12rem]"
          color="primary-outline-light"
          icon={AppIconsAction.confirm}
        >
          Confirmer
        </AppButton>
      </div>
    </form>
  );
};
