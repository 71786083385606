import { AppCurrency } from '@mabadive/app-common-model';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { CreateMessageToCustomersDialogProps } from './model';

export type CreateMessageToCustomersDialogActionPersistProps = {
  onUpdate: () => any;
};

export function useCreateMessageToCustomersDialogActionPersist(
  props: CreateMessageToCustomersDialogActionPersistProps,
): CreateMessageToCustomersDialogProps {
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = diveCenterResume?.clubResume?.clubSettings?.general;
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  const appCurrency: AppCurrency = useAppCurrencyMain({ generalSettings });

  const action: CreateMessageToCustomersDialogProps = {
    async onUpdate({ formValue }, initialState) {
      props?.onUpdate && props.onUpdate(); // call callback
      
    },
  };
  return action;
}
