import {
  Booking,
  ClubResumeStaffMember,
  ClubStaffMemberRole,
  DiveCenterPrivateSettingsEquipment,
  DiveSession,
} from '@mabadive/app-common-model';
import {
  nameFormatter,
  staffMemberDefaultAvailabilityFinder,
  staffMemberFormatter,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { CalendarSessionWidthMode } from './CalendarSessionWidthMode.type';
import { CalendarStaffInstructorCellEquipment } from './CalendarStaffInstructorCellEquipment';
import { bookingStatusBorderStyleBuilder } from './bookingStatusBorderStyleBuilder.service';

const AGE_RECENT_1_DURATION_S = 2;

export function CalendarStaffInstructorCell({
  staffFirstNameBefore,
  booking,
  staffMember,
  role = 'scuba-diving-instructor',
  diveSession,
  isPast,
  hoverable,
  onClick,
  className,
  bgClassName,
  widthMode,
}: {
  staffFirstNameBefore: boolean;
  booking?: Pick<Booking, 'bookingStatus'>;
  staffMember: ClubResumeStaffMember;
  role?: ClubStaffMemberRole;
  diveSession: Pick<DiveSession, 'time' | 'status'>;
  isPast?: boolean;
  hoverable?: boolean;
  onClick?: (staffMember: ClubResumeStaffMember) => void;
  bgClassName?: string;
  className?: string;
  widthMode: CalendarSessionWidthMode;
}) {
  if (isPast === undefined) {
    isPast = false;
  }

  const currentAvailability = useMemo(
    () =>
      staffMemberDefaultAvailabilityFinder.getDefaultDailyAvailability(
        staffMember,
        {
          date: diveSession.time,
        },
      ),
    [diveSession.time, staffMember],
  );

  const lastChange = useMemo(() => {
    const lastChange = Math.max(
      ...[staffMember?._updatedAt?.getTime()].filter((x) => !!x),
    );
    if (lastChange) {
      return new Date(lastChange);
    }
  }, [staffMember?._updatedAt]);

  const recentUpdate = useMemo(() => isRecentUpdate(lastChange), [lastChange]);

  const diveSessionStatus =
    diveSession?.status === 'cancelled' ? 'cancelled' : diveSession.status;

  const isSessionOpen = diveSessionStatus === 'on';

  const diveTypeLabel = useMemo(
    () =>
      staffMemberFormatter.formatStaffLevelRole({
        staffMember,
        role,
      }),
    [role, staffMember],
  );
  const isCasual = currentAvailability?.jobType === 'casual';

  const lastName = useMemo(
    () => nameFormatter.formatLastName(staffMember.profile),
    [staffMember.profile],
  );
  const firstName = useMemo(
    () => nameFormatter.formatFirstName(staffMember.profile),
    [staffMember.profile],
  );
  const namesLength = (firstName?.length ?? 0) + (lastName?.length ?? 0);

  const diveCenterResume = useDiveCenterResume();
  const equipmentsSettings: DiveCenterPrivateSettingsEquipment =
    diveCenterResume?.privateSettings?.equipment;
  const includeEquipment =
    staffMember?.equipment?.tank?.clubEquipped ||
    staffMember?.equipment?.tank?.selfEquipped;

  return (
    <div
      className={`group flex leading-3 text-xs overflow-hidden
       ${
         widthMode === 'tiny' || widthMode === 'small' ? 'flex-col' : 'h-[28px]'
       } 
       ${isPast ? 'opacity-[75%]' : ''} ${className}`}
      onClick={!onClick ? undefined : () => onClick(staffMember)}
    >
      <div
        className={clsx(
          'px-0.5 flex justify-center items-center text-white font-bold',
          bookingStatusBorderStyleBuilder.buildStyle(booking?.bookingStatus, {
            widthMode,
          }),

          !isSessionOpen
            ? 'bg-gray-400'
            : isCasual
            ? 'bg-app-instructor-casual'
            : 'bg-app-instructor-regular',
          diveSessionStatus === 'cancelled' &&
            'line-through decoration-2 decoration-red-600',
          diveSessionStatus === 'off' &&
            'line-through decoration-2 decoration-gray-600',
          diveSessionStatus === 'deleted' &&
            'line-through decoration-2 decoration-gray-600',
          hoverable && 'group-hover:bg-app-orange3 group-hover:text-white',
          recentUpdate && '  animate-flash-red',
          widthMode === 'tiny' || widthMode === 'small'
            ? 'py-0.5'
            : widthMode === 'medium'
            ? `min-w-[30px] lg:min-w-[35px] xl:min-w-[40px] ${
                diveTypeLabel?.length > 4
                  ? 'text-[.6rem] lg:text-[.65rem] xl:text-[.7rem] 2xl:text-[.75rem]'
                  : diveTypeLabel?.length > 3
                  ? 'text-app-xxs lg:text-[.7rem] xl:text-[.75rem] 2xl:text-[.8rem]'
                  : ''
              }`
            : 'min-w-[70px]', // widthMode === 'large'
        )}
      >
        {diveTypeLabel && diveTypeLabel}
      </div>
      <div
        className={clsx(
          'w-full max-w-full truncate flex gap-2 justify-between items-start',
          isCasual
            ? 'border-app-instructor-casual '
            : 'border-app-instructor-regular ',
          bgClassName ??
            (role === 'surface-security' || role === 'diving-director'
              ? 'bg-gray-200'
              : isCasual
              ? 'bg-app-instructor-casual bg-opacity-20'
              : ' bg-app-instructor-regular bg-opacity-20'),
          widthMode === 'tiny' || widthMode === 'small'
            ? 'h-[28px] border-b border-l border-r'
            : 'pt-px border-b border-t border-r ',
          widthMode === 'tiny' ? 'text-app-xxs' : 'px-0.5',
          hoverable && 'group-hover:bg-gray-50 group-hover:border-app-orange3',
          !isSessionOpen ? 'grayscale  text-gray-500' : ' text-gray-800',
        )}
      >
        {staffMember && (firstName || lastName) ? (
          <div
            className={clsx(
              'flex items-end flex-wrap gap-x-0.5',
              namesLength > 18
                ? ' text-[.65rem] lg:text-[.7rem] xl:text-[.75rem] 2xl:text-[.8rem]'
                : ' text-[.7rem] lg:text-[.75rem] xl:text-[.8rem] 2xl:text-[.85rem]',
            )}
          >
            {staffFirstNameBefore && firstName?.length > 0 && (
              <span className={'whitespace-nowrap font-bold'}>{firstName}</span>
            )}
            {lastName?.length > 0 && (
              <span className={'whitespace-nowrap font-bold'}>{lastName}</span>
            )}
            {!staffFirstNameBefore && firstName?.length > 0 && (
              <span className={'whitespace-nowrap font-bold'}>{firstName}</span>
            )}
          </div>
        ) : (
          <span className="diver-name hidden">x</span>
        )}
        {widthMode === 'large' && includeEquipment && isSessionOpen && (
          <CalendarStaffInstructorCellEquipment
            // className="mt-0.5"
            staffMember={staffMember}
          />
        )}
      </div>
    </div>
  );
}

function getUpdateAge(lastUpdate: Date) {
  const now = new Date();
  return now.getTime() - lastUpdate.getTime();
}

function isRecentUpdate(lastUpdate: Date): boolean {
  if (lastUpdate === undefined) {
    return null;
  }

  const ageMs = getUpdateAge(lastUpdate);
  const maxAgeMs = AGE_RECENT_1_DURATION_S * 1000;

  return ageMs < maxAgeMs;
}
