/* eslint-disable @typescript-eslint/no-unused-vars */
import { EQUIPMENT_TYPES, EquipmentType } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
//import { RegionalTaxLabel } from '../company/RegionalTax/RegionalTaxLabel';
import { equipmentFormatter } from '@mabadive/app-common-services';
export function useEquipmentsTypesOptions(): ValueLabel<
  EquipmentType,
  string
>[] {
  return useMemo(() => {
    const taxesOptions: ValueLabel<EquipmentType, string>[] =
      EQUIPMENT_TYPES.map((type) => ({
        value: type,
        label: equipmentFormatter.formatEquipmentType(type),
      }));

    return taxesOptions;
  }, []);
}
