import { Box, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { Switch } from 'react-router-dom';
import { of } from 'rxjs';
import { AppAuth } from 'src/business/auth/services';
import { responsiveAttributesValues } from 'src/business/_core/modules/layout';
import { useCacheableClasses } from 'src/business/_core/modules/root/theme';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { AccountChoosePasswordPage } from '../AccountChoosePasswordPage';
import { AccountProfileEditPage } from '../AccountProfileEditPage';

export const AccountProfileRoot = React.memo(function AdminRootSpaceMemo() {
  const { match } = useRouter();

  const classes = useCacheableClasses({
    cacheKey: 'AccountProfileRoot',
    buildStyles,
  });

  return (
    <>
      <Box component="main" className={classes.root}>
        <Switch>
          <AppRoute path={`${match.url}/profile`} exact component={AccountProfileEditPage} auth={{ requiredRoles: ['account-profile'] }} />
          <AppRoute path={`${match.url}/choose-password`} exact component={AccountChoosePasswordPage} auth={{ requiredRoles: ['account-reset-password'] }} />

          {/* default route */}
          <AppRoute path={`${match.url}/`} redirectTo={({ appAuth }) => of(redirectToDefaultRoute({ appAuth, matchUrl: match.url }))} />
        </Switch>
      </Box>
    </>
  );
});

function redirectToDefaultRoute({ appAuth, matchUrl }: { appAuth: AppAuth; matchUrl: string }): string {
  if (appAuth) {
    return `${matchUrl}/profile`;
  }
  return '/';
}

function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      display: 'flex',
      ...responsiveAttributesValues(theme, {
        // padding: { values: [0, 0, 8, 8, 8], suffix: 'px' },
        paddingTop: { values: [50, 50, 50, 50, 50], suffix: 'px' },
        height: { values: [50, 50, 50, 50, 50], prefix: 'calc(100vh - ', suffix: 'px)' },
      }),
      width: '100vw',
      overflowX: 'hidden',
      // overflowY: 'hidden',
    },
  });
}
