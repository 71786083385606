/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService } from '@mabadive/app-common-services';
import { DiveSessionEditorFormModel } from '../../../forms/DiveSessionEditForm/model';
import { DiveSessionEditorDialogInputState } from '../../../models';
import { DiveSessionEditorInitialValue } from '../model';

export const diveSessionEditorDialogInitialFormValueBuilder = {
  buildInitialFormValue,
};

function buildInitialFormValue({
  initialValue,
  inputState,
}: {
  initialValue: DiveSessionEditorInitialValue;
  inputState: DiveSessionEditorDialogInputState;
}): DiveSessionEditorFormModel {
  const date = dateService.getUTCDateWithoutTime(
    initialValue.initialDiveSession?.time ?? new Date(),
  );

  const multiSessionsEnabled =
    !!initialValue.initialDiveSession.diveTourSession2;

  const sameParticipantsSession1 =
    (initialValue.initialParticipants ?? []).find(
      (x) => x.divesCount !== initialValue.initialDiveSession.sessionsCount,
    ) === undefined;

  const initialFormValue: DiveSessionEditorFormModel = {
    diveSession: initialValue.initialDiveSession,
    date,
    sameParticipantsSession1,
    multiSessionsEnabled,
    cloneParticipants: inputState.mode === 'clone-session', // visible seulement en mode 'clone' (TODO: déplacer le clone de session vers le nouvel écran de clone de palanquées)
    cloneParticipantsGroups: inputState.mode === 'clone-session', // visible seulement en mode 'clone'
    cloneGuidesAndInstructors: false, // visible seulement en mode 'clone'
  };
  return initialFormValue;
}
