/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  AppButton,
  AppMessageSingleLine,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import { useBookingOptions } from 'src/pages/_components/options';
import { BookingTabModel } from '../../models';
import { bookingTabModelBuilder, defaultBookingBuilder } from '../../services';
import { DiverBookingPageActions } from '../../useDiverBookingPageActions.hook';
import { DiverBookingPageGlobalState } from '../../useDiverBookingPageGlobalState.hook';
export const DiverBookingPagePaymentTabCreateBookingDepositArea = ({
  globalState,
  actions,
}: {
  globalState: DiverBookingPageGlobalState;
  actions: DiverBookingPageActions;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume.clubResume.clubSettings;
  const diveCenterId = diveCenterResume._id;
  const az = useAppSecurityUserClubAuthorizations();

  const {
    aggregatedData,
    updateState,
    setUpdateState,
    focus,
    navigationContext,
    loadedContent,
    includeArchivedBookings,
    linkedData,
    dialogsState,
  } = globalState;

  const createBookingEnabled =
    az.edit?.participant?.payment &&
    az.edit?.participant?.paymentCreate &&
    clubSettings.general?.deposits?.enabled;

  // view model
  const bookingTabModels: BookingTabModel[] = useMemo(
    () =>
      bookingTabModelBuilder.buildBookingTabModel({
        loadedContent,
        aggregatedData,
        focus,
        diveCenterId,
        includeArchivedBookings,
      }),
    [
      loadedContent,
      aggregatedData,
      focus,
      diveCenterId,
      includeArchivedBookings,
    ],
  );

  const defaultBookingId = defaultBookingBuilder.getDefaultBookingId(
    aggregatedData.bookingResumesVisible,
    {
      diveCenterId,
      focusDiverId: aggregatedData.focus?.clubDiver._id,
    },
  );

  const bookingOptions: ValueLabel<string, React.ReactNode>[] =
    useBookingOptions({
      aggregatedData,
      onlyActive: true,
      initialBookingId: defaultBookingId,
      diveCenterId,
    });
  return !createBookingEnabled ? null : bookingOptions.length > 0 ? (
    <div className="flex justify-end">
      <AppButton
        size="small"
        color={'primary-bg'}
        fullWidth={false}
        onClick={() => {
          dialogsState.bookingDepositEditDialog.openDialog({
            mode: 'create',
            defaultValue: {
              bookingDeposit: {},
            },
            aggregatedData,
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }}
      >
        <div
          className={clsx(
            'mx-2 overflow-hidden flex items-center gap-1 md:gap-2 uppercase',
            'text-xs sm:text-sm md:text-base',
          )}
        >
          <AppHeroIcons.actionAddCircle className="w-6 h-6" />

          <div className="">Acompte</div>
        </div>
      </AppButton>
    </div>
  ) : (
    <div className="flex flex-col gap-4">
      <AppMessageSingleLine>
        Pour créer un acompte, créez d'abord une réservation
      </AppMessageSingleLine>
      <AppButton
        icon={AppHeroIcons.actionAdd}
        color="primary-bg"
        onClick={() => actions.createNewBooking()}
      >
        <span>Créer une réservation</span>
      </AppButton>
    </div>
  );
};
