import clsx from 'clsx';
import React from 'react';
import {
  DiveModeIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';

import {
  DIVE_TRAININGS_REFERENCE_MAP,
  DiveTraining,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DashboardReportPageTrainingParticipantsComparisonState } from './DashboardReportPageTrainingParticipants';

export const DashboardReportPageTrainingParticipantsTableRow = ({
  training,
  comparisonDetails,
  className,
}: {
  training: ProStatsFetchResults['trainingParticipantsOverPeriod']['data']['byTrainingReference'][0];
  comparisonDetails: DashboardReportComparisonDetails<DashboardReportPageTrainingParticipantsComparisonState>;
  className?: string;
}) => {
  const { localState, showPeriodRangeComparison } = comparisonDetails;

  const selectedTraining = localState.state.uiConfig.selection?.training;
  const hoverTraining = localState.state.uiConfig.hover?.training;

  const isSelected =
    selectedTraining?.diveTrainingReference === training.trainingReference;
  const isHover =
    hoverTraining?.diveTrainingReference === training.trainingReference;

  const diveTraining: DiveTraining =
    DIVE_TRAININGS_REFERENCE_MAP[training.trainingReference];

  const mainCurrency = useAppCurrencyMain();

  return (
    <tr
      className={clsx(
        'overflow-hidden',
        isSelected ? 'app-tr-selected' : isHover && 'app-tr-highlighted',
        className,
      )}
      onMouseEnter={() =>
        localState.state.setHover({
          training: {
            diveTrainingReference: training.trainingReference,
          },
        })
      }
      onMouseLeave={() => localState.state.setHover()}
      onClick={() =>
        localState.state.setSelection({
          training: {
            diveTrainingReference: training.trainingReference,
          },
        })
      }
    >
      <th
        className={clsx(
          'text-left whitespace-nowrap px-2 w-[50%]',
          training.diversCount === 0 ? 'text-gray-400' : '',
        )}
      >
        <div className="flex flex-wrap items-end gap-x-1 xl:gap-x-1.5 text-xs md:text-sm pt-1">
          <span className="md:min-w-[40px] font-bold text-gray-800">
            {training.trainingReference}
          </span>
          <span
            className={clsx(
              'text-xs font-medium truncate',
              showPeriodRangeComparison
                ? 'hidden xl:inline'
                : 'hidden md:inline',
            )}
          >
            {diveTraining?.label ?? '?'}
          </span>
        </div>
      </th>
      <td
        className={clsx(
          'text-app-primary text-right text-lg px-4 whitespace-nowrap font-bold',
          training.diversCount === 0 ? 'opacity-30' : '',
        )}
      >
        {training.diversCount}{' '}
        <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
      </td>
      <td
        className={clsx(
          'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
          training.participantsCount === 0 ? 'opacity-30' : '',
        )}
      >
        <span
          className={clsx(
            training.participantsCountPurchased === training.participantsCount
              ? 'font-bold'
              : 'font-normal',
          )}
        >
          {training.participantsCountPurchased}
        </span>{' '}
        / {training.participantsCount}{' '}
        <DiveModeIcons.training className={clsx('inline w-6 h-6')} />
      </td>
      <td
        className={clsx(
          'text-right text-lg px-4 text-gray-500 font-normal whitespace-nowrap',
          showPeriodRangeComparison
            ? 'hidden xl:table-cell'
            : 'hidden lg:table-cell',
        )}
      >
        {training.diversCount !== 0
          ? (
              Math.round(
                (10 * training.participantsCount) / training.diversCount,
              ) / 10
            ).toFixed(1)
          : null}
      </td>
      <td
        className={clsx(
          'text-app-secondary text-right text-lg px-4 whitespace-nowrap font-bold',
          training.participantsCount === 0 ? 'opacity-30' : '',
        )}
      >
        <AppPriceLabel amount={training.priceAvg} mainCurrency={mainCurrency} />
      </td>
    </tr>
  );
};
