import {
  AppUserTrackingAction,
  AppUserTrackingClient,
  AppUserTrackingSession,
  ClubTrackerUpdateOperationPayload,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import { authenticationStore } from 'src/business/auth/services';
import { clubStore } from '../../store';
import { clubAppOperationUpdateClientSender } from '../app-operation/club-operations-update';
import { repositoryEntityBuilder } from '../repository/repositoryEntityBuilder.service';

export type TrackDataLoadingPropsBase = {
  startDate?: Date;
  endDate?: Date;
  dataLabel: string;
  context: Object;
  pageName: string;
  clubReference: string;
  durationInMs?: number;
  limitToRandomPercent: number; // permet de ne pas faire trop de tracking, juste un peu pour avoir des stats, car ça bouffe du réseau!
  // TODO, il faudrait inclure les temps de chargemetn dans les steps du MultiPayload
};

export type TrackDataLoadingPropsWithFetchSize = TrackDataLoadingPropsBase & {
  fetchSize: number;
};

export type TrackDataLoadingPropsWithFetchData = TrackDataLoadingPropsBase & {
  data: any;
};

export type TrackDataLoadingProps =
  | TrackDataLoadingPropsWithFetchData
  | TrackDataLoadingPropsWithFetchSize;

export const appWebTracker = {
  trackDataLoading,
  getFetchSize,
};

function getFetchSize(data?: any): number {
  const fetchSize = !data ? undefined : JSON.stringify(data).length;
  return fetchSize;
}

function trackDataLoading(
  {
    startDate,
    endDate,
    dataLabel,
    context,
    pageName,
    clubReference,
    durationInMs,
    limitToRandomPercent,
    ...props
  }: TrackDataLoadingProps,
  {
    trackingClient,
  }: {
    trackingClient: AppUserTrackingClient;
  },
) {
  if (limitToRandomPercent <= 0) {
    return; // always skip
  }
  if (limitToRandomPercent > 0) {
    if (Math.random() > limitToRandomPercent / 100) {
      return; // ignore this one
    }
  }
  let fetchSize = (props as TrackDataLoadingPropsWithFetchSize).fetchSize;
  if (!fetchSize) {
    fetchSize = getFetchSize(
      (props as TrackDataLoadingPropsWithFetchData).data,
    );
  }

  const auth = authenticationStore.auth.getSnapshot();
  const userId = auth?.user?.userId;
  if (
    userId &&
    startDate &&
    auth?.user?.impersonate?.root?.profile !== 'super-admin'
  ) {
    const trackingSessionId = getTrackingSessionId(userId, trackingClient);

    if (!durationInMs && startDate && endDate) {
      durationInMs = endDate.getTime() - startDate.getTime();
    }

    return trackAction({
      dataLabel,
      trackingSessionId,
      startDate,
      endDate,
      durationInMs,
      fetchSize,
      context,
      pageName,
      userId,
    });
  }
}
function trackAction({
  trackingSessionId,
  startDate,
  endDate,
  dataLabel,
  context,
  pageName,
  durationInMs,
  fetchSize,
  userId,
}: {
  trackingSessionId: string;
  startDate: Date;
  endDate: Date;
  dataLabel: string;
  context: Object;
  pageName: string;
  durationInMs: number;
  fetchSize: number;
  userId: string;
}) {
  const trackingAction = repositoryEntityBuilder.buildEntity({
    label: `Fetch data ${dataLabel}`,
    trackingSessionId,
    startDate,
    endDate,
    durationInMs,
    fetchSize,
    browserActionType: 'page-load',
    context: {
      ...context,
      pageName,
    },
  } as AppUserTrackingAction);

  const payload: ClubTrackerUpdateOperationPayload = {
    newAppUserTrackingsActions: [trackingAction],
  };
  return clubAppOperationUpdateClientSender.persistOneLater({
    userId,
    operation: {
      id: 'club.performances.track',
      payload,
    },
    actionId: 'track-data-loading',
    errorMode: 'skip',
  });
}

function getTrackingSessionId(
  userId: string,
  trackingClient: AppUserTrackingClient,
) {
  let trackingSessionId = clubStore.trackingSession.getSnapshot()?._id;
  if (!trackingSessionId) {
    const trackingSession = repositoryEntityBuilder.buildEntity({
      startDate: new Date(),
      processType: 'client',
      client: trackingClient,
    } as AppUserTrackingSession);
    const payload: ProMultiOperationPayload = {
      logContext: 'track action',
      newAppUserTrackingsSessions: [trackingSession],
    };
    clubAppOperationUpdateClientSender.persistOneLater({
      userId,
      operation: {
        id: 'club.performances.track',
        payload,
      },
      actionId: 'create-tracking-session',
      errorMode: 'retry', // on ne veut pas skipper le tracking session, sinon on aura un pb de FK ensuite
    });

    clubStore.trackingSession.set(trackingSession);
    trackingSessionId = trackingSession?._id;
  }
  return trackingSessionId;
}
