/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubEvent } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ClubEventCardGroup } from '../../../club-events';
import { SetPlanningConfigOptions } from '../../../club-planning-light/hooks';
import { ClubPlanningDayFull } from '../../../club-planning-light/model';
import { PlanningViewConfig } from '../../_model';
import { ClubPlanningStaffWeekStaffPresenceLocalState } from './useClubPlanningStaffWeekStaffPresenceLocalState.hook';

export const ClubPlanningStaffWeekStaffPresenceHeaderEvents = ({
  planningConfig,
  setPlanningConfig,
  days,
  onClickEvent,
  localState,
  gridClassName,
  className,
}: {
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  days: ClubPlanningDayFull[];
  onClickEvent?: (clubEvent: ClubEvent) => void;
  localState: ClubPlanningStaffWeekStaffPresenceLocalState;
  gridClassName?: string;
  className?: string;
}) => {
  const { data, actions, criteria } = localState;

  const dailyConfigs = data.aggregateData.core.dailyConfigs;

  const hasAnyEvent: boolean = useMemo(
    () => days.find((day) => day.clubEvents.length > 0) !== undefined,
    [days],
  );

  return hasAnyEvent ? (
    <div className={gridClassName}>
      <div
        className={clsx(
          'py-1 px-2 lg:col-span-2 text-right text-gray-500 font-medium',
          planningConfig.viewPeriod === 'week' && 'hidden lg:block',
        )}
      ></div>
      {days.map((day, i) => (
        <ClubEventCardGroup
          key={i}
          className="h-full"
          clubEvents={day.clubEvents}
          widthMode={'medium'}
          onClick={onClickEvent}
        />
      ))}
    </div>
  ) : null;
};
