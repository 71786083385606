import { ClubEvent } from '@mabadive/app-common-model';
import {
  attributeStoreFactory,
  loadableAttributeCollectionStoreFactory,
} from '@mabadive/app-common-services';
import { getBaseStore } from 'src/business/_core/store/baseStore';
import { ClubEventCollectionLoadingState } from './ClubEventCollectionLoadingState.type';

export const CLUB_EVENT_COLLECTION_INITIAL_LOADING_STATE: ClubEventCollectionLoadingState =
  {
    loaded: { loadedState: 'none', loadedWeekTimestamps: [] },
    initialLoading: {
      status: 'not-started',
    },
  };

const clubEventCollection =
  loadableAttributeCollectionStoreFactory.create<ClubEvent>(
    getBaseStore(),
    'c_club_events',
  );
const loadingState =
  attributeStoreFactory.create<ClubEventCollectionLoadingState>(
    getBaseStore(),
    'c_club_events_load',
    CLUB_EVENT_COLLECTION_INITIAL_LOADING_STATE,
  );

export const clubEventStore = {
  clubEventCollection,
  loadingState,
  setInitialData,
  resetStore,
  addOneToStore,
  addManyToStore,
};

function setInitialData({
  loadedWeekTimestamps,
  clubEvents,
}: {
  loadedWeekTimestamps: number[];
  clubEvents: ClubEvent[];
}) {
  clubEventCollection.setAll(clubEvents);

  loadingState.reduce((state) => ({
    ...state,
    initialLoading: {
      status: 'success',
    },
    loaded: {
      loadedWeekTimestamps,
      loadedState: 'partial',
    },
  }));
}

function resetStore() {
  loadingState.set(CLUB_EVENT_COLLECTION_INITIAL_LOADING_STATE);
  clubEventCollection.removeAll();
}

function addOneToStore(diveSessionResume: ClubEvent) {
  clubEventCollection.setOne({
    value: diveSessionResume,
    actionId: 'addOneToStore(upd)',
    identify: (x) => x._id === diveSessionResume._id,
  });
}

function addManyToStore({
  minDateInclusive,
  clubEvents,
}: {
  minDateInclusive: Date;
  clubEvents: ClubEvent[];
}) {
  // NOTE: contrairement aux sessions, un évenement peut être à cheval sur plusieurs périodes,
  // donc il faut potentiellement le mettre à jour, même si la période n'a pas encore été chargée
  // update
  clubEventCollection.addOrUpdateMany({
    values: clubEvents,
    actionId: 'addManyToStore(upd)',
    identify: (a, b) => a._id === b._id,
  });

  loadingState.reduce((x) => {
    const loadedWeekTimestamps = [].concat(...x.loaded.loadedWeekTimestamps);
    if (!loadedWeekTimestamps.includes(minDateInclusive.getTime())) {
      loadedWeekTimestamps.push(minDateInclusive.getTime());
    }

    return {
      ...x,
      loaded: {
        ...x.loaded,
        loadedState: 'partial',
        loadedWeekTimestamps,
      },
    };
  });
}
