import { BookingDeposit } from '@mabadive/app-common-model';
import {
  DiverBookingPageAggregatedData,
  DiverBookingPageLoadedContentFocus,
  PaymentTabModel,
  PaymentTabModelCounts,
  PaymentTabParticipantPayments,
} from '../../../models';
import { billingTabModelBuilderBookingDepositsFilter } from './billingTabModelBuilderBookingDepositsFilter.service';
import { billingTabModelBuilderPaymentsFilter } from './billingTabModelBuilderPaymentsFilter.service';
import { paymentTabModelSorter } from './paymentTabModelSorter.service';

export const paymentTabModelBuilder = { buildPaymentTabModel };

export type PaymentTabModelBuilderFilterCriteria = {
  ignoreOtherDiveCenters: boolean;
};

function buildPaymentTabModel({
  aggregatedData,
  focus,
  criteria,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  focus: DiverBookingPageLoadedContentFocus;
  criteria: PaymentTabModelBuilderFilterCriteria & {
    filteredDiverId: string;
    currentDiveCenterId: string;
  };
}): PaymentTabModel {
  const {
    counts,
    participantPayments,
  }: {
    counts: PaymentTabModelCounts;
    participantPayments: PaymentTabParticipantPayments[];
  } = buildPayments({
    aggregatedData,
    criteria,
  });

  const { visibleBookingDeposits } = buildBookingDeposits({
    aggregatedData,
    criteria,
    counts,
  });

  const model: PaymentTabModel = {
    counts,
    participantPayments:
      paymentTabModelSorter.sortPaymentTabParticipantPayments(
        participantPayments,
      ),
    visibleBookingDeposits,
    meta: {
      isUniqueMember: aggregatedData.divers?.length === 1,
      isSameLastName:
        new Set(aggregatedData.divers?.map((x) => x.lastName?.toLowerCase()))
          .size <= 1,
    },
  };

  return model;
}
function buildBookingDeposits({
  aggregatedData,
  criteria,
  counts,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  criteria: PaymentTabModelBuilderFilterCriteria & {
    filteredDiverId: string;
    currentDiveCenterId: string;
  };
  counts: PaymentTabModelCounts;
}): {
  visibleBookingDeposits: BookingDeposit[];
} {
  const bookingDeposits =
    billingTabModelBuilderBookingDepositsFilter.filterBookingDeposits({
      bookingDeposits: aggregatedData.bookingDeposits,
      criteria,
      counts,
      linkedData: aggregatedData.linkedData,
    });

  return bookingDeposits.reduce(
    (acc, x) => {
      if (
        x.purchasePaymentStatus !== 'done' || // pas de paiement créé, on affiche (en principe, ça ne devrait pas arriver)
        (x.purchasePaymentStatus === 'done' &&
          !x.purchasePaymentPending && // paiement complet + crédit non épuisé
          x.creditRemaining > 0)
      ) {
        acc.visibleBookingDeposits.push(x);
      }
      return acc;
    },
    {
      visibleBookingDeposits: [],
    } as {
      visibleBookingDeposits: BookingDeposit[];
    },
  );
}

function buildPayments({
  aggregatedData,
  criteria,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  criteria: PaymentTabModelBuilderFilterCriteria & {
    filteredDiverId: string;
    currentDiveCenterId: string;
  };
}): {
  counts: PaymentTabModelCounts;
  participantPayments: PaymentTabParticipantPayments[];
} {
  const counts: PaymentTabModelCounts = {
    hiddenPayments: 0,
    hiddenBookingDeposits: 0,
    hiddenParticipants: 0,
    otherDiveCentersBookingDeposit: 0,
    otherDiveCentersPayments: 0,
    otherParticipants: 0,
  };

  const purchasePaymentsWithDetails =
    billingTabModelBuilderPaymentsFilter.filterPayments({
      purchasePaymentsWithDetails: aggregatedData.purchasePaymentsWithDetails,
      criteria,
      counts,
      linkedData: aggregatedData.linkedData,
    });

  const participantPayments: PaymentTabParticipantPayments[] =
    purchasePaymentsWithDetails.reduce((acc, purchasePaymentWithDetails) => {
      const purchasePayment = purchasePaymentWithDetails.purchasePayment;
      const existing: PaymentTabParticipantPayments = acc.find(
        (pp) => pp.diver?._id === purchasePayment.diverId,
      );
      if (existing) {
        existing.purchasePaymentsWithDetails.push(purchasePaymentWithDetails);
      } else {
        const created: PaymentTabParticipantPayments = {
          diver: aggregatedData.divers.find(
            (d) => d._id === purchasePayment.diverId,
          ),
          lastPaymentDate: null,
          purchasePaymentsWithDetails: [purchasePaymentWithDetails],
        };
        acc.push(created);
      }
      return acc;
    }, [] as PaymentTabParticipantPayments[]);

  participantPayments.forEach((x) => {
    x.purchasePaymentsWithDetails =
      paymentTabModelSorter.sortClubPurchasePayments(
        x.purchasePaymentsWithDetails,
      );
    const firstPayment = x.purchasePaymentsWithDetails[0]?.purchasePayment;
    x.lastPaymentDate =
      firstPayment?.paymentDate ??
      firstPayment?.creationDate ??
      firstPayment?.dueDate;
  });
  return {
    participantPayments,
    counts,
  };
}
