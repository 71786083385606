import {
  ClubDiveSiteType,
  CLUB_DIVE_SITE_TYPES,
} from '@mabadive/app-common-model';
import { diveSiteFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export function useDiveSitesTypesOptions(): ValueLabel<ClubDiveSiteType>[] {
  return useMemo(
    () =>
      CLUB_DIVE_SITE_TYPES.map((value) => ({
        value,
        label: diveSiteFormatter.formatType(value),
      })),
    [],
  );
}
