/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferViewPlanGroup,
  arrayBuilder,
  productPackageFormatter,
} from '@mabadive/app-common-services';
import { Box, FormControl, FormLabel } from '@material-ui/core';
import React, { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useAppCurrencyMain,
  useBookingAgenciesOptions,
  useDiveServicePurchaseStatusesOptions,
} from 'src/pages/_components/options';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { AppPackageExtraCostFormDialog } from 'src/stories/components/05-form-panel/AppPackageExtraCostFormPanel/AppPackageExtraCostFormDialog';
import { AppPackageExtraCostFormModel } from 'src/stories/components/05-form-panel/AppPackageExtraCostFormPanel/model';
import { DiverPurchaseCommonFormBookingAndAgency } from '../../../DiverPurchaseCommonEditorDialog/components';
import { DiverPurchasePlanDialogLocalState } from '../../useDiverPurchasePlanDialogLocalState.service';

export const DiverPurchasePlanFormRHF = ({
  localState,
}: {
  localState: DiverPurchasePlanDialogLocalState;
}) => {
  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;

  const clubSettings = clubResume.clubSettings;
  const agenciesOptions = useBookingAgenciesOptions();
  const generalSettings = clubResume.clubSettings.general;

  const mainCurrency = useAppCurrencyMain();
  const securityUser = useAppSecurityUser();

  const { formContext, actions, data, states, inputState } = localState;

  const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] =
    states.openExtraCostsFormPanelState;

  const { register, handleSubmit, watch, formState, control, setValue } =
    formContext.form;

  const {
    isUnitOffer,
    diver,
    productPackageOffersCriteria,
    planGroups,
    selectedOffer,
    selectedOfferProductPackageMeta,
    extraCostUnitDiveDefaultMultiplier,
    divesCountExtensionMode,
    priceDetails,
  } = data;

  const { totalPrice, totalPriceBeforeDiscount, totalPriceExtraCosts } =
    priceDetails;
  const productPackageOffers = data.filteredProductPackageOffers;

  const isCreation = inputState.mode === 'create';
  const onChangePlanGroup = actions.onChangePlanGroup;

  const diveServicePurchaseStatusesOptions =
    useDiveServicePurchaseStatusesOptions();

  const offersOptions = useMemo(() => {
    const showDepthInBilling =
      clubResume.clubSettings.general.billing?.showDepth &&
      // && productPackageOffersCriteria.diveModeGroup === 'recreational-dive'
      selectedOfferProductPackageMeta?.diveMode === 'supervised'; // juste pour les encadrés

    const options = productPackageOffers.map((offer) => {
      const label = productPackageFormatter.formatNameString(
        offer?.productPackage,
        {
          publicSettings,
          options: {
            showResidentType: !diver.residentType, // on affiche le type de résidence seulement si il n'est pas précisé pour le plongeur
            showDepthInBilling,
          },
          diveModesConf: clubSettings?.ui?.diveMode,
        },
      );
      // React.ReactNode pas encore supporté
      // const option: ValueLabel<string, React.ReactNode> = {
      //   label: (
      //     <div className="flex gap-2 justify-between">
      //       <div>{label}</div>
      //       <div>{offer?.price}</div>
      //     </div>
      //   ),
      //   value: offer.reference,
      // };
      // const priceLabel =
      //   offer.price > 0
      //     ? ` (${offer.price.toFixed(mainCurrency.cents)} ${mainCurrency?.sign})`
      //     : '';
      // NOTE: l'idée est bonne, mais il faut d'abord ré-écrire l'autocomplete avec tailwind, pour pouvoir supporter un React.ReactNode comme label
      const option: ValueLabel<string> = {
        label: `${label}`,
        value: offer.reference,
      };
      return option;
    });
    return options;
  }, [
    clubResume.clubSettings.general.billing?.showDepth,
    clubSettings?.ui?.diveMode,
    diver.residentType,
    productPackageOffers,
    publicSettings,
    selectedOfferProductPackageMeta?.diveMode,
  ]);

  const planGroupsOptions = useMemo(() => {
    const options = planGroups.map((planGroup, planGroupIndex) => {
      const { successiveDivesCount, divesCount, minDivesCount, maxDivesCount } =
        planGroup;

      let label;
      if (minDivesCount > 1 && maxDivesCount > 1) {
        label = `${minDivesCount}-${maxDivesCount}`;
      } else if (minDivesCount > 1) {
        label = `>${minDivesCount}`;
      } else if (maxDivesCount > 1) {
        label = `<${maxDivesCount}`;
      } else {
        label = `${divesCount}`;
      }

      if (successiveDivesCount > 1) {
        label += ` x ${successiveDivesCount} plongées successives`;
      } else {
        label += ' plongées';
      }

      const option: ValueLabel<number> = {
        label,
        value: planGroupIndex,
      };
      return option;
    });
    return options;
  }, [planGroups]);

  const creditsInitialCountOptions: ValueLabel<number, string>[] =
    useMemo(() => {
      const divesCount =
        selectedOfferProductPackageMeta?.diveAttributes?.divesCount;
      const divesCountMax =
        selectedOfferProductPackageMeta?.diveAttributes?.divesCountMax;
      if (
        divesCount > 0 &&
        divesCountMax > divesCount &&
        divesCountMax - divesCount < 20
      ) {
        // 20 options maxi
        return arrayBuilder.bySize(divesCountMax).map((x, i) => {
          const value = i + 1;
          const option: ValueLabel<number, string> = {
            value,
            label: `${value}`,
          };
          return option;
        });
      }
      return [];
    }, [
      selectedOfferProductPackageMeta?.diveAttributes?.divesCount,
      selectedOfferProductPackageMeta?.diveAttributes?.divesCountMax,
    ]);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Offre/Forfait"
          control={control}
          name="planGroupIndex"
          required={true}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={planGroupsOptions}
              onChange={(planGroupIndex: number) => {
                const planGroup: ClubProductPackageOfferViewPlanGroup =
                  selectPlanGroup({
                    planGroupIndex,
                    planGroups,
                  });
                onChangePlanGroup({
                  planGroup,
                });
              }}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Options"
          control={control}
          name="productPackageOfferReference"
          required={true}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              disableClearable={true}
              {...props}
              options={offersOptions}
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Date de souscription"
          control={control}
          name="purchaseDate"
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF className="w-full" {...props} />
          )}
        />
        <AppFormControlRHF_Deprecated
          className="w-full"
          label="Statut"
          control={control}
          name="validityStatus"
          required={true}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              theme="material-ui"
              {...props}
              options={diveServicePurchaseStatusesOptions}
              onChange={() => actions.setValidityStatusManuallyDefined(true)}
            />
          )}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <AppFormControlRHF_Deprecated
          className={data.isUnitOffer ? 'w-full' : 'hidden'}
          label={'Quantité'}
          control={control}
          name="unitQuantity"
          required={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        {creditsInitialCountOptions.length > 0 ? (
          <AppFormControlRHF_Deprecated
            className={isUnitOffer ? 'hidden' : 'w-full'}
            label={'Plongées forfait'}
            control={control}
            name="creditsInitialCount"
            required={true}
            renderComponent={(props) => (
              <AppSingleSelect2HeadlessUIRHF
                {...props}
                theme={'material-ui'}
                options={creditsInitialCountOptions}
                onChange={(value) =>
                  actions.onCreditsInitialCountChanges(value)
                }
              />
            )}
          />
        ) : (
          <AppFormControlRHF_Deprecated
            className={isUnitOffer ? 'hidden' : 'w-full'}
            label={'Plongées forfait'}
            control={control}
            name="creditsInitialCount"
            required={true}
            disabled={divesCountExtensionMode === 'disabled'}
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="number"
                onChange={(value) =>
                  actions.onCreditsInitialCountChanges(value)
                }
              />
            )}
          />
        )}

        <AppFormControlRHF_Deprecated
          className={isUnitOffer ? 'hidden' : 'w-full'}
          label="Plongées supp."
          control={control}
          name="creditsAdditionalCount"
          required={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />

        <AppFormControlRHF_Deprecated
          className={isUnitOffer ? 'hidden' : 'w-full'}
          label="Arriéré"
          control={control}
          name="consumedExternalCount"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <AppFormControlRHF_Deprecated
          className="w-full"
          label={
            isUnitOffer
              ? `Prix unitaire (${mainCurrency.sign})`
              : `Prix forfait (${mainCurrency.sign})`
          }
          control={control}
          name="unitPrice"
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="number" />
          )}
        />
        {generalSettings?.billing?.thirdPartyCollectEnabled && (
          <AppFormControlRHF_Deprecated
            control={control}
            label="Total tiers"
            name={'totalPriceThirdPartyCollect' as any}
            renderComponent={(props) => (
              <AppInputRHF {...props} type="number" />
            )}
          />
        )}
        <div className="grid grid-cols-2 gap-2">
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={`Remise (${mainCurrency.sign})`}
            control={control}
            name="discountAmount"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="number"
                onChange={actions.onChangeDiscountAmount}
              />
            )}
          />
          <AppFormControlRHF_Deprecated
            className="w-full"
            label={'Remise (%)'}
            control={control}
            name="discountPercentage"
            renderComponent={(props) => (
              <AppInputRHF
                {...props}
                fullWidth
                type="number"
                onChange={actions.onChangeDiscountPercentage}
              />
            )}
          />
        </div>
        <AppPackageExtraCostFormDialog
          context={'plan'}
          open={openExtraCostsFormPanel}
          form={
            formContext.form as unknown as UseFormReturn<
              AppPackageExtraCostFormModel,
              any
            >
          }
          setOpen={setOpenExtraCostsFormPanel}
          extraCostUnitDiveDefaultMultiplier={
            extraCostUnitDiveDefaultMultiplier
          }
        >
          <AppButton
            className={'w-full sm:w-auto text-xs font-bold uppercase'}
            style={{ minWidth: '6rem' }}
            color={'primary-outline-light'}
            icon={AppHeroIcons.actionEdit}
            onClick={() => setOpenExtraCostsFormPanel(!openExtraCostsFormPanel)}
          >
            Suppléments{' '}
            {totalPriceExtraCosts > 0 && (
              <AppPriceLabel
                amount={totalPriceExtraCosts}
                mainCurrency={mainCurrency}
              />
            )}
          </AppButton>
        </AppPackageExtraCostFormDialog>
        <div className={'flex flex-col w-full'}>
          <FormControl>
            <FormLabel className="form-label pl-1" component="legend">
              <span className="font-bold text-app-primary uppercase text-xs leading-3">
                Prix total
              </span>
            </FormLabel>
            <Box className="form-input">
              {totalPrice > 0 && (
                <span className="font-bold text-app-primary text-xl">
                  <AppPriceLabel
                    amount={totalPrice}
                    mainCurrency={mainCurrency}
                  />
                </span>
              )}
            </Box>
          </FormControl>
        </div>
      </div>

      <DiverPurchaseCommonFormBookingAndAgency
        isCreation={isCreation}
        form={formContext.form}
        initialBookingId={localState?.inputState?.purchasePackage?.bookingId}
        aggregatedData={localState?.data?.aggregatedData}
      />
      <AppFormControlRHF_Deprecated
        className="md:col-span-3 w-full"
        label="Commentaire"
        control={control}
        name="comment"
        renderComponent={(props) => (
          <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
        )}
      />
    </>
  );
};
function selectPlanGroup({
  planGroupIndex,
  planGroups,
}: {
  planGroupIndex: number;
  planGroups: ClubProductPackageOfferViewPlanGroup[];
}): ClubProductPackageOfferViewPlanGroup {
  return planGroupIndex >= 0 && planGroupIndex < planGroups.length
    ? planGroups[planGroupIndex]
    : undefined;
}
