/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  diveSessionsSorter,
  weekPlanningBuilder,
} from '@mabadive/app-common-services';
import { useMemo, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { DiveSessionResumeLoadBounds } from 'src/business/_core/data/store-repository/dive-sessions/model';
import {
  useFetchCalendarWeekDiveSessionResumes,
  useWeekPlanningBounds,
} from '../club-planning/_components';
import { PlanningViewConfig } from '../club-planning/_model';
import { clubPlanningUrlBuilder } from '../url-builders';
import { SetPlanningConfigOptions } from './hooks';

export function usePlanningViewPeriosSessionSwipe({
  sessionReference,
  planningConfig,
  setPlanningConfig,
}: {
  sessionReference: string;
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
}) {
  const weekPlanning = useMemo(
    () =>
      weekPlanningBuilder.buildWeekPlanning({
        focusDate: planningConfig.focusDate,
      }),
    [planningConfig.focusDate],
  );
  const criteria: DiveSessionResumeLoadBounds = useWeekPlanningBounds({
    weekPlanning,
  });
  const { content: diveSessionResumes, ...loadableContent } =
    useFetchCalendarWeekDiveSessionResumes({
      criteria,
      mode: 'local-only',
    });

  const { previousSession, nextSession } = useMemo(() => {
    if (
      planningConfig.viewPeriod === 'session' &&
      planningConfig.focusDateRef &&
      diveSessionResumes
    ) {
      const dailyDiveSessionResumes = diveSessionsSorter.sortDiveSessionsBy(
        diveSessionResumes.filter((x) => {
          const sessionDateRef =
            clubPlanningUrlBuilder.buildFocusDateRefFromDate(x.time);
          return sessionDateRef === planningConfig.focusDateRef;
        }),
        {
          attributeName: 'time',
          asc: true,
        },
      );

      const currentSessionIndex = dailyDiveSessionResumes.findIndex(
        (x) => x.reference === sessionReference,
      );
      const previousSession =
        currentSessionIndex !== -1 && currentSessionIndex - 1 >= 0
          ? dailyDiveSessionResumes[currentSessionIndex - 1]
          : undefined;
      const nextSession =
        currentSessionIndex !== -1 &&
        currentSessionIndex + 1 < dailyDiveSessionResumes.length
          ? dailyDiveSessionResumes[currentSessionIndex + 1]
          : undefined;

      return { previousSession, nextSession };
    }
    return {};
  }, [
    diveSessionResumes,
    planningConfig.focusDateRef,
    planningConfig.viewPeriod,
    sessionReference,
  ]);

  const [transitionDirection, settransitionDirection] =
    useState<boolean>(undefined);

  const transitionHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Right') {
        if (previousSession) {
          settransitionDirection(true);
          setPlanningConfig(
            {
              ...planningConfig,
              focusSessionReference: previousSession.reference,
            },
            {
              origin: '[usePlanningViewPeriosSessionSwipe] useSwipeable right',
            },
          );
        }
      } else if (eventData.dir === 'Left') {
        if (nextSession) {
          settransitionDirection(false);
          setPlanningConfig(
            {
              ...planningConfig,
              focusSessionReference: nextSession.reference,
            },
            {
              origin: '[usePlanningViewPeriosSessionSwipe] useSwipeable left',
            },
          );
        }
      }
    },
  });
  return {
    transitionDirection,
    transitionHandlers,
  };
}
