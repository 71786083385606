/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubResume } from 'src/business/club/data/hooks';
import { DiverPurchasePaymentEditorDialogLocalState } from './useDiverPurchasePaymentEditorDialogLocalState';
export function useEditPaymentNavigation({
  localState,
}: {
  localState: DiverPurchasePaymentEditorDialogLocalState;
}): {
  closeAndCancel: () => void;
  goBack: () => void;
  goNext: () => void;
} {
  const redirectTo = useRedirect();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const {
    form,
    navigation: { currentStep, setCurrentStep, steps },
    actions,
  } = localState;

  const { register, handleSubmit, watch, control, formState, setValue, reset } =
    form;

  const closeAndCancel = useCallback(() => {
    localState.actions.setIsOpen(false);
  }, [localState.actions]);

  const goBack = useCallback(() => {
    // pas besoin que ça soit valide pour aller en arrière
    if (currentStep === 'select-purchases') {
      closeAndCancel();
    } else if (currentStep === 'edit-details') {
      setCurrentStep('select-purchases');
    }
  }, [closeAndCancel, currentStep, setCurrentStep]);

  const goNext = useCallback(() => {
    if (formState.isValid) {
      if (currentStep === 'select-purchases') {
        setCurrentStep('edit-details');
      } else if ('edit-details') {
        actions.submitForm();
      }
    }
  }, [actions, currentStep, formState.isValid, setCurrentStep]);

  return {
    goBack,
    goNext,
    closeAndCancel,
  };
}
