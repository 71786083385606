import { AppCompanyMessageTemplate } from '@mabadive/app-common-model';
import { commonEntityBuilder } from '@mabadive/app-common-services';
import { useCallback, useMemo } from 'react';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import {
  useAppSecurityUser,
  useAppSecurityUserClubAuthorizations,
} from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppHamburgerMenuItem } from 'src/business/club/modules/_common/ui';
import { ClubSettingsCommunicationPageLocalState } from '../../../useClubSettingsCommunicationPageLocalState.hook';
import { useAutomaticMailConfigForTemplate } from '../AppCompanyMessageTemplateEditDialog/form/useAutomaticMailConfigForTemplate.hook';

export function useClubSettingsCommunicationMessageTemplateCardMenuItems({
  localState,
  messageTemplate: mt,
}: {
  localState: ClubSettingsCommunicationPageLocalState;
  messageTemplate: AppCompanyMessageTemplate;
}) {
  const user = useAppSecurityUser();
  const az = useAppSecurityUserClubAuthorizations();

  const clubResume = useClubResume();
  const publicSettings = clubResume.clubSettings.publicSettings;

  const { state, dialogs } = localState;

  const { enabledCount } = useAutomaticMailConfigForTemplate(mt._id);

  const confirmDelete = useCallback(() => {
    const templateName = mt?.name;

    confirmDialog
      .confirm({
        title: `Supprimer le modèle "${templateName}"`,
        message: 'Êtes-vous sûr de vouloir supprimer ce modèle de message?',
        type: 'remove',
      })
      .subscribe(async (confirmed) => {
        if (confirmed && mt) {
          await clubMassiveUpdatorClient.update({
            appCompanyMessageTemplates: {
              deletedIds: [mt._id],
            },
          });
        }
      });
  }, [mt]);

  const hamburgerMenuItems: AppHamburgerMenuItem[] = useMemo(() => {
    const hamburgerMenuItems: AppHamburgerMenuItem[] = [];

    // if (az?.edit.participant.paymentEdit) {
    hamburgerMenuItems.push({
      id: 'edit-offer',
      titleShort: 'Modifier',
      title: 'Modifier',
      // description: 'Modifier les détails du tarif',
      icon: AppHeroIcons.actionEdit,
      onClick: () => {
        dialogs.messageTemplate.openEdit(mt);
      },
    });
    hamburgerMenuItems.push({
      id: 'clone-offer',
      titleShort: 'Dupliquer',
      title: 'Dupliquer',
      // description: 'Dupliquer les détails du tarif',
      icon: AppHeroIcons.actionClone,
      onClick: () => {
        dialogs.messageTemplate.openCreate(
          commonEntityBuilder.buildEntity({
            ...mt,
            _id: null,
          }),
        );
      },
    });
    if (enabledCount === 0) {
      hamburgerMenuItems.push({
        id: 'delete-offer',
        titleShort: 'Supprimer',
        title: 'Supprimer',
        severity: 'danger',
        // description: 'Modifier les détails du tarif',
        icon: AppHeroIcons.actionDelete,
        onClick: () => {
          confirmDelete();
        },
      });
    }
    // }
    return hamburgerMenuItems;
  }, [confirmDelete, dialogs.messageTemplate, enabledCount, mt]);

  return hamburgerMenuItems;
}
