import {
  AppTranslationContentItem,
  AppTranslationContentItemType,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { ArrayPath, FieldValues, UseFieldArrayReturn } from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';

type AppTranslationContentItemWithoutType = Partial<
  Pick<AppTranslationContentItem, 'text' | 'richText' | 'appDoc' | 'button'>
>;

const TYPES_DESCRIPTIONS: (AppTranslationContentItemWithoutType & {
  type: AppTranslationContentItemType;
  label: string;
})[] = [
  {
    type: 'title',
    label: 'Titre',
  },
  {
    type: 'text',
    label: 'Texte',
    text: '',
  },
  {
    type: 'rich-text',
    label: 'Paragraphe',
  },
  {
    type: 'app-doc-image',
    label: 'Image',
  },
  // {
  //   type: 'responsive-image', // anim-live
  //   label: 'Image responsive',
  // },
  {
    type: 'button',
    label: 'Bouton',
  },
];

export function AppTranslationContentItemsFormAddSectionBar<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>,
>({
  fieldsArray,
  types,
  className,
}: {
  fieldsArray: UseFieldArrayReturn<TFieldValues, TFieldArrayName, 'id'>;
  types: AppTranslationContentItemType[];
  className?: string;
}) {
  const { append } = fieldsArray;

  const typesDescriptions = useMemo(
    () =>
      TYPES_DESCRIPTIONS.filter((t) => types.includes(t.type)).map((x) => ({
        ...x,
        label: x.label,
      })),
    [types],
  );

  return (
    <div className={`flex space-x-2 ${className}`}>
      {typesDescriptions.map(({ label, ...props }, i) => (
        <AppButton
          key={i}
          onClick={() => {
            append({
              ...props,
            } as any);
          }}
          color={'gray-outline-light'}
        >
          <AppIconsAction.add
            className={
              'rounded-full truncate text-white w-5 h-5 mr-1 bg-gray-800'
            }
          />
          {label.toUpperCase()}
        </AppButton>
      ))}
    </div>
  );
}
