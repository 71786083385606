/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';
import { DailyExportConfigDialogLocalState } from '../useDailyExportConfigDialogLocalState.hook';

export const DailyExportConfigOptionsPanelFormGeneralSettings = ({
  localState,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  className?: string;
}) => {
  const { form } = localState;

  const { control } = form;

  const dailyResumeIndex = 0; // pour l'instant, un seul export
  const baseFormName = `dailyResumes.${dailyResumeIndex}` as const;

  const [isExportEnabled, hideOldSecuritySheetExport] = useWatch({
    control,
    name: [`${baseFormName}.enabled`, 'hideOldSecuritySheetExport'],
  });

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return (
    <div className={clsx('grid gap-4', className)}>
      <div className="w-full flex justify-between gap-x-8 gap-y-2 flex-wrap text-base text-app-primary font-medium uppercase">
        <div className="flex-grow">Paramètres généraux</div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
        <AppFormControlRHF_Deprecated
          label={'Nom'}
          control={control}
          name={`${baseFormName}.label`}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        {isSuperAdmin && (
          <AppFormControlRHF_Deprecated
            label={'Nom du fichier'}
            control={control}
            name={`${baseFormName}.exportConfig.baseFileName`}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
        )}
      </div>
      {isSuperAdmin && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
          <AppFormControlRHF_Deprecated
            label={"Durée par défaut d'un baptême"}
            control={control}
            name={`${baseFormName}.exportConfig.settings.defaultTargetDurations.BPT`}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF_Deprecated
            label={"Durée par défaut d'un baptême XL"}
            control={control}
            name={`${baseFormName}.exportConfig.settings.defaultTargetDurations.BPT_XL`}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
          <AppFormControlRHF_Deprecated
            label={"Durée par défaut d'une explo"}
            control={control}
            name={`${baseFormName}.exportConfig.settings.defaultTargetDurations.EXPLO`}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="number" />
            )}
          />
        </div>
      )}
      <AppFormControlRHF_Deprecated
        label={'Description'}
        control={control}
        name={`${baseFormName}.description`}
        renderComponent={(props) => (
          <AppInputRHF {...props} fullWidth type="text" />
        )}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:flex gap-4">
        <AppFormControlRHF_Deprecated
          label={'Export activé'}
          control={control}
          name={`${baseFormName}.enabled`}
          renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
        />
        {isExportEnabled && (
          <>
            <AppFormControlRHF_Deprecated
              label={'Dashboard journée'}
              control={control}
              name={`${baseFormName}.visibility.dashboardDaily`}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  type="checkbox"
                  description="export de toutes les sessions du jour"
                  {...props}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              label={'Planning journée'}
              control={control}
              name={`${baseFormName}.visibility.planningDaily`}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  type="checkbox"
                  description="export de toutes les sessions du jour"
                  {...props}
                />
              )}
            />
            <AppFormControlRHF_Deprecated
              label={'Planning session'}
              control={control}
              name={`${baseFormName}.visibility.planningSession`}
              renderComponent={(props) => (
                <AppInputBooleanRHF
                  type="checkbox"
                  description="export par session"
                  {...props}
                />
              )}
            />
            {(isSuperAdmin || !hideOldSecuritySheetExport) && (
              <AppFormControlRHF_Deprecated
                visibility="super-admin"
                label={'Ancienne feuille de sécurité'}
                control={control}
                name={'hideOldSecuritySheetExport'}
                renderComponent={(props) => (
                  <AppInputBooleanRHF
                    type="checkbox"
                    description="masquer"
                    {...props}
                  />
                )}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
