import { ClubCommerceBookingDepositGql_Company } from '@mabadive/app-common-model';

export const bookingDepositResumeAttributesBuilder = {
  buildAttributes,
};

function buildAttributes() {
  return `
    ${ClubCommerceBookingDepositGql_Company}
    paymentsBookingDepositsDetails {
        _id
        amount
        purchasePaymentId
        bookingDepositId
    }
    contact: diver {
      _id
      firstName
      lastName
      phoneNumber
      emailAddress
      birthdate
      age
    }
`;
  // 11/12/2023 => supprimé les produits, qui n'était plus utilisés, donc chargés pour rien!
  // bookingProducts {
  //   ${bookingProductResumeAttributesBuilder.buildAttributes()}
  // }
}
