import { ClubPurchasePaymentResume } from '@mabadive/app-common-model';
import { paymentMethodFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppDateTimeLabel } from 'src/business/club/modules/_common/ui';
import { BillingParticipantPaymentResumeTableDiverName } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BillingParticipantPaymentResumeTable/BillingParticipantPaymentResumeTableDiverName';
import { clubParticipantUrlBuilder } from 'src/business/club/modules/url-builders';
import { AppLinkStopPropagation } from 'src/lib/browser';
import {
  PurchasePaymentAmountDetails,
  PurchasePaymentStatusDetails,
} from 'src/pages/_components/company';
import { useAppCurrency } from 'src/pages/_components/options';
import {
  useClubResume,
  useDiveCenterResume,
} from '../../../../business/club/data/hooks';
import {
  AgencyLabel,
  BookingGroupTypeLabel,
} from '../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';

export type PaymentsListTableOnClickMemberAction =
  | 'add'
  | 'edit'
  | 'set-purchasePayment-contact'
  | 'extract-from-purchasePayment'
  | 'delete-member-with-participants';

export function PaymentsListTableRow({
  purchasePayment,
  className,
}: {
  purchasePayment: ClubPurchasePaymentResume;
  className?: string;
}) {
  const paymentIntents = purchasePayment.onlinePlatformDetails?.paymentIntents;
  const billingCurrency = useAppCurrency(purchasePayment.currencyIsoCode);
  const usedCurrency = useAppCurrency(purchasePayment.usedCurrencyIsoCode);

  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const generalSettings = clubResume?.clubSettings?.general;

  const url = useMemo(() => {
    const url = clubParticipantUrlBuilder.buildTabUrl({
      navigationContext: {
        origin: 'payments-list',
        diverId: purchasePayment.diver._id,
        purchasePaymentId: purchasePayment._id,
        action: 'open-purchasePayment-edit-dialog',
      },
      tabId: 'payment',
    });
    return url;
  }, [purchasePayment._id, purchasePayment.diver._id]);

  const redirectTo = useRedirect();

  return (
    <tr
      className={clsx('app-tr-highlight', className)}
      onClick={() => {
        // si on clique sur la ligne, mais en dehors d'un lien, on est quand même redirigé
        redirectTo(url);
      }}
    >
      <td className="whitespace-nowrap text-sm text-gray-600">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <AppDateTimeLabel
            timezone="utc"
            date={purchasePayment.paymentDate ?? purchasePayment.creationDate}
            format="date"
          />
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm font-bold text-gray-600">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <PurchasePaymentAmountDetails purchasePayment={purchasePayment} />
        </AppLinkStopPropagation>
      </td>
      <td className="text-sm text-gray-600 truncate">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <BillingParticipantPaymentResumeTableDiverName
            diver={purchasePayment.diver}
            className={'flex-shrink'}
          />
          <BookingGroupTypeLabel
            className={'inline-block md:hidden px-1 py-px text-xs'}
            maxLabelWidthClassName={'max-w-[200px]'}
            type={purchasePayment.booking?.bookingGroup?.type}
            label={purchasePayment.booking?.bookingGroup?.name?.toUpperCase()}
            preferLabelOnly={true}
          />
        </AppLinkStopPropagation>
      </td>
      <td className="hidden md:table-cell text-sm text-gray-600 truncate">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <BookingGroupTypeLabel
            className={'inline-block px-1 py-px text-xs'}
            maxLabelWidthClassName={
              'max-w-[80px] lg:max-w-[140px] 2xl:max-w-[300px]'
            }
            type={purchasePayment.booking?.bookingGroup?.type}
            label={purchasePayment.booking?.bookingGroup?.name?.toUpperCase()}
            preferLabelOnly={true}
          />
        </AppLinkStopPropagation>
      </td>
      {generalSettings?.agencies?.enabled &&
        generalSettings.agencies?.agencyPayment && (
          <td className="text-sm text-gray-600 truncate hidden sm:table-cell">
            <AppLinkStopPropagation className="block px-2 py-1" to={url}>
              {purchasePayment.payedToBookingAgencyId && (
                <div className="flex gap-2 items-center">
                  <AgencyLabel
                    className="text-xs"
                    agencyId={purchasePayment.payedToBookingAgencyId}
                  />
                </div>
              )}
            </AppLinkStopPropagation>
          </td>
        )}
      <td className="whitespace-nowrap text-sm text-gray-600 hidden sm:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {paymentMethodFormatter.formatPaymentMethod(
            purchasePayment.paymentMethod,
          )}
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-600 hidden xs:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          <PurchasePaymentStatusDetails purchasePayment={purchasePayment} />
        </AppLinkStopPropagation>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-600 hidden md:table-cell">
        <AppLinkStopPropagation className="block px-2 py-1" to={url}>
          {purchasePayment.paymentReference}
        </AppLinkStopPropagation>
      </td>
    </tr>
  );
}
// created
// receipt_url
