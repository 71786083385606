import {
  AppCompanyMessageContext,
  AppCompanyMessageSchedule,
  AppCompanyMessageScheduleTargetMessageRecipient,
  AppMessageParamsRecipientType,
} from '@mabadive/app-common-model';
import {
  AppRenderMessageParts,
  appUrlBuilderCore,
  messageParamsArgsBuilder,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  CreateMessageToCustomersAggregatedRecipient,
  CreateMessageToCustomersDialogInitialState,
} from 'src/business/club/modules/club-messaging';
import { UseAppMessageParamsArgs } from 'src/pages/_components';

export function useAppMessageParts({
  messageSchedule,
}: {
  messageSchedule: AppCompanyMessageSchedule;
}): AppRenderMessageParts {
  // FIXME
  const recipient: AppCompanyMessageScheduleTargetMessageRecipient =
    messageSchedule?.mainTarget?.localizedMessages?.[0]?.recipients[0];

  const diveCenterResume = useDiveCenterResume();

  return useMemo(
    () =>
      messageParamsArgsBuilder.renderAppMessageParts(
        {
          messageScheduleTarget: messageSchedule.mainTarget,
          localizedMessage: messageSchedule.mainTarget.localizedMessages[0],
          recipient,
        },
        {
          publicData: diveCenterResume?.publicData,
        },
      ),
    [diveCenterResume?.publicData, messageSchedule, recipient],
  );
}

export function buildCreateMessageArgs({
  context,
  recipientType,
  previewRecipient,
  validSelectedRecipients,
  initialState,
}: {
  context: AppCompanyMessageContext;
  recipientType: AppMessageParamsRecipientType;
  previewRecipient: CreateMessageToCustomersAggregatedRecipient;
  validSelectedRecipients: CreateMessageToCustomersAggregatedRecipient[];
  initialState: CreateMessageToCustomersDialogInitialState;
}): UseAppMessageParamsArgs {
  const args: UseAppMessageParamsArgs = {
    context,
    recipientType,
  };
  const nameDiverRecipient =
    recipientType === 'personal' && previewRecipient
      ? previewRecipient
      : validSelectedRecipients.length === 1
      ? validSelectedRecipients[0]
      : undefined;

  if (context === 'booking') {
    let bookingRecipient = previewRecipient;
    if (!bookingRecipient) {
      // first validSelectedRecipients
      bookingRecipient = validSelectedRecipients?.find(
        (x) => x.formRecipient.isBookingContact,
      );
      if (!bookingRecipient) {
        bookingRecipient = validSelectedRecipients?.[0];
      }
    }
    let bookingAliasKey =
      bookingRecipient?.formRecipient?.bookingMember?.bookingAliasKey;

    const customerSpaceAuthenticationUrl = bookingAliasKey
      ? appUrlBuilderCore.buildShortUrlLinkOnDiverSite({
          aliasKey: bookingAliasKey,
          diverWebUrl: appWebConfig.applications.diverWebUrl,
        })
      : 'https://diver.mabadive.com/xxx';
    args.bookingContext = {
      customerSpaceAuthenticationUrl,
    };
    if (nameDiverRecipient) {
      args.bookingContext.diver = {
        firstName: nameDiverRecipient.formRecipient.diver.firstName,
        lastName: nameDiverRecipient.formRecipient.diver.lastName,
      };
    }
  }
  if (context === 'session') {
    args.sessionContext = {
      diveSession: {
        time: initialState.sessionContext?.diveSession?.time,
      },
    };
    if (nameDiverRecipient) {
      args.sessionContext.diver = {
        firstName: nameDiverRecipient.formRecipient.diver.firstName,
        lastName: nameDiverRecipient.formRecipient.diver.lastName,
      };
    }
  }
  return args;
}
