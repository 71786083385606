import { dataObjectCompare } from '@mabadive/app-common-services';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { buildRxjsFormAttribute, BuildRxjsFormAttributeArgs } from './rxjs-form-attribute/buildRxjsFormAttribute.fn';
import { RxjsFormAttributeActions } from './rxjs-form-attribute/RxjsFormAttributeActions.model';
import { RxjsFormAttributeState } from './rxjs-form-attribute/RxjsFormAttributeState.model';
import { RxjsFormActions } from './RxjsFormActions.type';
import { RxjsFormComponent } from './RxjsFormComponent.type';

export const rxjsFormBuilder = {
  buildComponent,
  buildForm,
};

function buildComponent<AttributeType, AttributeName extends string = string>({
  name,
  initialValue,
  required,
  validators,
}: BuildRxjsFormAttributeArgs<AttributeType, AttributeName>): RxjsFormComponent<AttributeType, AttributeName> {

  const { state$, actions } = buildRxjsFormAttribute({
    name,
    initialValue,
    required,
    validators,
  });

  return {
    name,
    actions,
    model$: state$.pipe(
      map(state => ({
        state,
        actions,
      })),
    ),
  }
}

function buildForm<FormModel, FormComponentModel>(attributesArgs: BuildRxjsFormAttributeArgs<any, string>[]) {

  const components = attributesArgs.map(p => rxjsFormBuilder.buildComponent(p));

  const components$ = combineLatest(components.map(c => c.model$)).pipe(
    map(models => models.reduce((acc, model) => {
      const attributeName = model.state.def.name;
      (acc as unknown as any)[attributeName] = model;
      return acc;
    }, {} as FormComponentModel)));


  const actions: RxjsFormActions<FormModel> = {
    updateInitialValue: (value: Partial<FormModel>, eventSource = 'external') => {
      Object.keys(value).forEach(key => {
        const attributeName = key as keyof FormModel;
        const component = components.find(c => c.name === attributeName);
        if (component) {
          const componentValue = value[attributeName];
          component.actions.updateInitialValue(componentValue, eventSource);
        }
      });
    },
    touchComponents: () => {
      components.forEach(component => component.actions.touchComponent());
    },
    reset: () => {
      Object.values(components).forEach(component => {
        component.actions.resetComponent();
      });
    },
  };

  const value$ = buildFormAttributeFromComponents<FormModel>({
    components,
    attributeEquals: (a, b) => {
      const equals = dataObjectCompare.objectsEquals(a, b, { attributes: attributeState => [attributeState.value] });
      return equals;
    },
    computeAttribute: (acc, attributeState) => {
      acc[attributeState.def.name as keyof FormModel] = attributeState.value;
      return acc;
    },
    defaultAttributeValue: {} as FormModel,
  });

  const initialValue$ = buildFormAttributeFromComponents<FormModel>({
    components,
    attributeEquals: (a, b) => dataObjectCompare.objectsEquals(a, b, { attributes: attributeState => [attributeState.initialValue] }),
    computeAttribute: (acc, attributeState) => {
      acc[attributeState.def.name as keyof FormModel] = attributeState.initialValue;
      return acc;
    },
    defaultAttributeValue: {} as FormModel,
  });

  const hasChanges$ = buildFormAttributeFromComponents<boolean>({
    components,
    attributeEquals: (a, b) => dataObjectCompare.objectsEquals(a, b, { attributes: attributeState => [attributeState.changed] }),
    computeAttribute: (acc, attributeState) => {
      return acc || attributeState.changed;
    },
    defaultAttributeValue: false,
  });

  const valid$ = buildFormAttributeFromComponents<boolean>({
    components,
    attributeEquals: (a, b) => dataObjectCompare.objectsEquals(a, b, { attributes: attributeState => [attributeState.validation.error] }),
    computeAttribute: (acc, attributeState) => {
      return acc && !attributeState.validation.error;
    },
    defaultAttributeValue: true,
  });


  const form$ = combineLatest([
    value$,
    initialValue$,
    hasChanges$,
    valid$
  ], (value, initialValue, hasChanges, valid) => ({ value, initialValue, hasChanges, valid, actions }));


  // const form$ = combineLatest(components.map(c => c.model$.pipe(
  //   map(m => m.state),
  //   distinctUntilChanged(),
  // ))).pipe(
  //   map(attributesStates => {
  //     const formProps = attributesStates.reduce((acc, attributeState) => {
  //       (acc.value as any)[attributeState.def.name] = attributeState.value;
  //       (acc.initialValue as any)[attributeState.def.name] = attributeState.initialValue;
  //       acc.hasChanges = acc.hasChanges || attributeState.changed;
  //       acc.valid = acc.valid && attributeState.valid;
  //       return acc;
  //     }, {
  //       value: {},
  //       initialValue: {},
  //       valid: true,
  //       hasChanges: false,
  //       actions,
  //     } as RxjsForm<FormModel>);
  //     return formProps;
  //   }));

  return {
    components$,
    actions,
    form$,
    value$,
    initialValue$,
    hasChanges$,
    valid$,
  };
}

function buildFormAttributeFromComponents<T>({
  components,
  attributeEquals,
  computeAttribute,
  defaultAttributeValue,
}: {
  components: {
    name: string;
    actions: RxjsFormAttributeActions<any>;
    model$: Observable<{
      state: RxjsFormAttributeState<any, string>;
      actions: RxjsFormAttributeActions<any>;
    }>;
  }[];
  attributeEquals: (attributeState1: RxjsFormAttributeState<any, string>, attributeState2: RxjsFormAttributeState<any, string>) => boolean;
  computeAttribute: (acc: T, item: RxjsFormAttributeState<any, string>) => T;
  defaultAttributeValue: T,
}) {
  return combineLatest(components.map(c => c.model$.pipe(
    map(m => m.state),
    distinctUntilChanged((a, b) => attributeEquals(a, b)),
  ))).pipe(
    map(attributesStates => {
      return attributesStates.reduce((acc, attributeState) => {
        return computeAttribute(acc, attributeState);
      }, defaultAttributeValue);
    }),
  );
}

