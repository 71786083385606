/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';

import clsx from 'clsx';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppHeroIcons } from '../../../../../../../business/_core/modules/layout/icons';
import { ClubSettingsPlanningViewWeekFormModel } from '../../model';

export const ClubSettingsPlanningViewWeekDaySessionBookingForm_TourBookingConfig =
  ({
    dayIndex,
    diveTourIndex,
    diveTourBookingConfigIndex,
    form,
    className = '',
    onClickDelete,
  }: {
    dayIndex: number;
    diveTourIndex: number;
    diveTourBookingConfigIndex: number;
    form: UseFormReturn<ClubSettingsPlanningViewWeekFormModel, any>;
    className?: string;
    onClickDelete: () => void;
  }) => {
    const clubResume = useClubResume();
    const diveCenterResume = useDiveCenterResume();

    const diveCenterId = diveCenterResume?._id;
    const clubReference = diveCenterResume?.clubReference;

    const { register, handleSubmit, watch, formState, control, setValue } =
      form;

    const bookingConfigControlBaseName =
      `days.${dayIndex}.diveTours.${diveTourIndex}.bookingConfigs.${diveTourBookingConfigIndex}` as const;

    /* NOTE: on fera une interface séparée plus tard, qui permettra :  */
    /* - d'ajouter des contraintes de dates (ex: 4 places du 01/04 au 21/05 puis 8 places jusqu'au 15/09) */
    /* - de forcer ces valeurs sur les sessions déjà crées et qui ont encore des places disponibles (ou pas plus de x inscrits) */

    const [diveTourFirstDiveEnabled, diveTourSnorkelingSupervisedEnabled] =
      useWatch({
        control,
        name: [
          `${bookingConfigControlBaseName}.conf.firstDive.enabled`,
          `${bookingConfigControlBaseName}.conf.snorkelingSupervised.enabled`,
        ],
      });

    const clubSettings = diveCenterResume?.clubResume?.clubSettings;
    const sessionConfig = clubSettings?.general?.onlineBooking?.sessionConfig;

    return (
      <div className={clsx('grid gap-2', className)}>
        {sessionConfig?.firstDive?.enabled && (
          <div className={clsx('flex gap-1')}>
            <AppFormControlRHF
              className={'w-full'}
              label={'Baptêmes'}
              control={control}
              name={
                `${bookingConfigControlBaseName}.conf.firstDive.enabled` as const
              }
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
            {diveTourFirstDiveEnabled && (
              <AppFormControlRHF
                className={'w-full'}
                label={'Nb de places'}
                control={control}
                required={true}
                validation={{
                  rules: {
                    min: 1,
                  },
                }}
                name={
                  `${bookingConfigControlBaseName}.conf.firstDive.maxParticipants` as const
                }
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
            )}
          </div>
        )}

        {sessionConfig?.snorkelingSupervised?.enabled && (
          <div className={clsx('flex gap-1')}>
            <AppFormControlRHF
              className={'w-full'}
              label={'RP'}
              control={control}
              name={
                `${bookingConfigControlBaseName}.conf.snorkelingSupervised.enabled` as const
              }
              renderComponent={(props) => (
                <AppInputBooleanSwitchRHF {...props} />
              )}
            />
            {diveTourSnorkelingSupervisedEnabled && (
              <AppFormControlRHF
                className={'w-full'}
                label={'Nb de places'}
                control={control}
                name={
                  `${bookingConfigControlBaseName}.conf.snorkelingSupervised.maxParticipants` as const
                }
                required={true}
                validation={{
                  rules: {
                    min: 1,
                  },
                }}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
            )}
          </div>
        )}

        <div
          className={
            'my-2 cursor-pointer group text-red-400 hover:text-red-600 flex flex-col items-center'
          }
          onClick={(e) => {
            e.stopPropagation();
            onClickDelete();
          }}
        >
          <AppHeroIcons.actionDelete
            className={
              'rounded-full border border-red-600 bg-white group-hover:bg-red-400 hover:text-white w-8 h-8 p-2'
            }
          />
          {/* <span className="uppercase font-bold text-xs">Supprimer</span> */}
        </div>
      </div>
    );
  };
