import { AppOperation } from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';
import { clubDiverStore } from '../../store-repository';

export const clubAppOperationApiClient = {
  update,
  updateMany,
};

function update(operation: AppOperation<any>): Promise<void> {
  // send remote operation
  return updateMany([operation]);
}

function updateMany(operations: AppOperation<any>[]): Promise<void> {
  // send remote operation
  return apiClient
    .post<any>('/operations', {
      authenticate: true,
      json: {
        operations,
      },
    })
    .toPromise()
    .catch((err) => {
      // invalidate cache
      clubDiverStore.clubDiverFullCollection.removeAll();
      throw err;
    });
}
