import {
  DiveMode,
  DiveSessionResumeParticipant,
  DiveSessionResumeParticipantsGroupExtended,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { useClubSettings } from '../../../data/hooks';
import { buildLevelsMap } from './ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroup';

export function useParticipantsLevels({
  diveMode,
  participants,
}: {
  diveMode: DiveMode;
  participants: DiveSessionResumeParticipant[];
}): {
  levelsMap: {
    level: string;
    participants: DiveSessionResumeParticipant[];
  }[];
  commonLevel: any;
} {
  const clubSettings = useClubSettings();

  return useMemo(() => {
    if (diveMode) {
      const diveModeLabel =
        diveModeFormatter.formatDiveMode(diveMode, {
          format: 'short-ref',
          diveModesConf: clubSettings?.ui?.diveMode,
        }) ?? '?';
      if (diveMode === 'supervised' || diveMode === 'autonomous') {
        if (participants.length > 0) {
          // create a map of participants by level
          const map = participants.reduce((acc, participant) => {
            const level = participant.certificationReference ?? diveModeLabel;
            if (!acc[level]) {
              acc[level] = [];
            }
            acc[level].push(participant);
            return acc;
          }, {} as Record<string, DiveSessionResumeParticipantsGroupExtended['participants']>);
          const { commonLevel, levelsMap } = buildLevelsMap(map, diveModeLabel);
          return { commonLevel, levelsMap };
        }
      } else if (diveMode === 'training') {
        if (participants.length > 0) {
          // create a map of participants by level
          const map = participants.reduce((acc, participant) => {
            const level = participant.trainingReference ?? diveModeLabel;
            if (!acc[level]) {
              acc[level] = [];
            }
            acc[level].push(participant);
            return acc;
          }, {} as Record<string, DiveSessionResumeParticipantsGroupExtended['participants']>);
          const { commonLevel, levelsMap } = buildLevelsMap(map, diveModeLabel);
          return { commonLevel, levelsMap };
        }
      }
      const levelsMap: {
        level: string;
        participants: DiveSessionResumeParticipant[];
      }[] = [
        {
          level: diveModeLabel,
          participants,
        },
      ];
      return { commonLevel: diveModeLabel, levelsMap };
    }
    return { levelsMap: [], commonLevel: undefined };
  }, [clubSettings?.ui?.diveMode, diveMode, participants]);
}
