/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  AppBreadcrumbBar,
  AppDocumentationLink,
  AppPageContainer,
} from 'src/business/_core/modules/layout';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import { ClubDialogsProvider } from 'src/pages/_dialogs';

import { useRedirect } from 'src/business/_core/data/hooks';
import { useClubSettings } from 'src/business/club/data/hooks';
import { ClubSettingsCommunicationMainViewPanel } from './tabs';
import { ClubSettingsCommunicationMessageTemplatesViewPanel } from './tabs/02.message-templates';
import { ClubSettingsCommunicationEmailAutoViewPanel } from './tabs/03.email-auto';
import {
  ClubSettingsCommunicationPageLocalState,
  useClubSettingsCommunicationPageLocalState,
} from './useClubSettingsCommunicationPageLocalState.hook';

export const ClubSettingsCommunicationPage = () => {
  const clubSettings = useClubSettings();
  const redirectTo = useRedirect();

  const localState: ClubSettingsCommunicationPageLocalState =
    useClubSettingsCommunicationPageLocalState();

  const { state, dialogs } = localState;

  const selectedTab = state.tabs.selected;

  return (
    <AppPageContainer className={'bg-white'}>
      <AppBreadcrumbBar
        color={AppIconsSettings.customers.color}
        items={[
          {
            icon: AppIconsSettings.general,
            label: 'Paramètres',
            url: '/club/settings',
          },
          {
            icon: AppIconsSettings.customers.main,
            label: 'Clients',
            url: '/club/settings',
          },
          {
            label: 'Communication',
          },
        ]}
      >
        <AppDocumentationLink
          className="block h-9 w-9 md:h-11 md:w-11"
          url={
            state.tabs.selected === 'email-auto'
              ? 'https://docs.mabadive.com/docs/messagerie/configuration_email'
              : 'https://docs.mabadive.com/docs/messagerie/configuration_modeles_messages'
          }
        />
      </AppBreadcrumbBar>
      <ClubDialogsProvider dialogsState={dialogs.state}>
        {selectedTab === 'main' ? (
          <ClubSettingsCommunicationMainViewPanel localState={localState} />
        ) : selectedTab === 'templates' ? (
          <ClubSettingsCommunicationMessageTemplatesViewPanel
            localState={localState}
          />
        ) : selectedTab === 'email-auto' ? (
          <ClubSettingsCommunicationEmailAutoViewPanel
            localState={localState}
          />
        ) : null}

        {/* <AppPageContentContainer
          paddingBottom={false}
          className="bg-gray-50 app-p-content"
        >
          <AppTabsTailwind
            className="w-full"
            tabs={state.tabs.data}
            selected={selectedTab}
            theme="underline"
            breakpoint="sm"
            onChange={(tabId) => state.tabs.setSelected(tabId)}
          />
          <div
            className={clsx(
              'mt-4 app-card p-4',
              state.editEnabled && 'app-card-highlight',
            )}
            onClick={() => {
              state.editEnabled && dialogs.communication.open(selectedTab);
            }}
          >
            {selectedTab === 'main' ? (
              <ClubSettingsCommunicationMainViewPanel />
            ) : selectedTab === 'templates' ? (
              <ClubSettingsCommunicationMessageTemplatesViewPanel />
            ) : null}

            {state.editMode === 'super-admin-only' ? (
              <div className="my-8 text-gray-500 flex md:flex-col flex-wrap gap-4 justify-between items-start">
                <p>Pour modifier la configuration, veuillez nous contacter:</p>

                <AppButton
                  className=""
                  color="primary-outline-light"
                  icon={AppHeroIcons.contact}
                  onClick={() => redirectTo(appRouteBuilder.getContactRoute())}
                >
                  Contactez-nous
                </AppButton>
              </div>
            ) : state.editEnabled ? (
              <div className="mt-8 mb-2">
                <AppButton
                  color={'primary-outline-light'}
                  size="normal"
                  icon={AppIcons.action?.edit}
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  // }}
                >
                  Modifier
                </AppButton>
              </div>
            ) : null}
          </div>
        </AppPageContentContainer> */}
      </ClubDialogsProvider>
    </AppPageContainer>
  );
};
