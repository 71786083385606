// ISO 3166-1 codes - https://github.com/umpirsky/country-list

import {
  CUSTOMER_ORIGIN_COMMON_VALUES,
  CUSTOMER_ORIGIN_SD_VALUES,
} from '@mabadive/app-common-model';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export const customerOriginOptionsBuilder = {
  buildOptions,
};
function buildOptions({
  clubReference,
}: {
  clubReference: string;
}): ValueLabel<string, string>[] {
  const customerOriginValues =
    clubReference === 'seacretdive-port-louis-98825'
      ? CUSTOMER_ORIGIN_SD_VALUES
      : CUSTOMER_ORIGIN_COMMON_VALUES;

  return customerOriginValues.map((label) => {
    const o: ValueLabel<string, string> = {
      value: label,
      label: label,
    };
    return o;
  });
}
