/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSessionTimeDayPeriod } from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { IoStar } from 'react-icons/io5';
import {
  DiveSiteIconDef,
  useDiveSiteIcon,
} from 'src/business/club/modules/club-dive-session/components';
import { AppEmojiIcon } from 'src/lib/form/components/AppEmojiPicker';

export const DiverBookingSessionsTableSessionHeaderTime = ({
  time,
  special,
  theme,
  emojiId,
  diveSiteId,
  displayDiveSite,
  sessionIndex,
  isMultiSession,
  className,
}: {
  time: Date;
  special?: boolean;
  theme: 'light' | 'normal';
  emojiId: string;
  diveSiteId: string;
  displayDiveSite: boolean;
  sessionIndex: number;
  isMultiSession: boolean;
  className?: string;
}) => {
  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(time),
    [time],
  );

  const diveSiteIcon: DiveSiteIconDef = useDiveSiteIcon(diveSiteId);

  return (
    <div
      className={`max-w-full relative font-bold bg-opacity-80 group-hover:bg-opacity-100 text-white
        ${
          theme === 'light'
            ? `bg-day-period-${timeDayPeriod}-light2`
            : `bg-day-period-${timeDayPeriod}-dark`
        }
      ${className ?? ''}`}
    >
      {diveSiteIcon && (
        <diveSiteIcon.icon
          className={clsx(
            'absolute rounded-full',
            'bg-white',
            diveSiteIcon.colorClass,
            isMultiSession
              ? 'w-3 h-3 p-px -bottom-1 -right-2'
              : 'w-4 h-4 p-px top-0.5 right-0.5',
          )}
        />
      )}
      <div className="flex">
        {special && (
          <IoStar
            className={'absolute mt-0.5 mr-0.5 right-0 w-3 h-3 ml-0.5 sm:ml-1'}
          />
        )}
        <div className="flex-grow block text-center">
          {dateService.formatUTC(time, 'HH:mm')}
        </div>
        {emojiId && (
          <div className="absolute top-0.5 left-0.5">
            <AppEmojiIcon id={emojiId} size={16} />
          </div>
        )}
      </div>
    </div>
  );
};
