import { LoadableAttributeCollectionStore } from '@mabadive/app-common-services';
import { Observable } from 'rxjs';
import { CollectionReadableStoreAdapter, CollectionReadableStoreAdapterCriteria, CollectionReadableStoreAdapterFilter } from '../model';

export const collectionSimpleStoreReadableAdapterFactory = {
  createAdapter,
};

function createAdapter<T>(store: LoadableAttributeCollectionStore<T>): CollectionReadableStoreAdapter<T> {

  return {
    getOne,
    getMany,
  };

  function getOne(options?: CollectionReadableStoreAdapterCriteria<T> | CollectionReadableStoreAdapterFilter<T>): Observable<T> {

    return store.getOne({
      filter: (item: T) => {
        if (!options) {
          return undefined;
        }

        const filter = (options as CollectionReadableStoreAdapterFilter<T>).filter;
        if (filter !== undefined) {
          return filter(item);
        }
        const criteria = (options as CollectionReadableStoreAdapterCriteria<T>).criteria;
        return identifyByCriteria(criteria, item);
      },
    })
  }

  function getMany(options?: CollectionReadableStoreAdapterCriteria<T> | CollectionReadableStoreAdapterFilter<T>): Observable<T[]> {

    if (!options) {
      return store.getAll();
    }
    const filter = (options as CollectionReadableStoreAdapterFilter<T>).filter;
    if (filter !== undefined) {
      return store.getMany({
        filter,
      });
    }
    const criteria = (options as CollectionReadableStoreAdapterCriteria<T>).criteria;
    if (criteria) {
      return store.getMany({
        filter: (item: T) => identifyByCriteria<T>(criteria, item),
      });
    }
    return store.getAll();
  }
}

function identifyByCriteria<T>(criteria: Partial<T>, item: T): boolean {

  return Object.keys(criteria).reduce((match, attributeName) => {
    if (match) {
      const a1 = (item as any)[attributeName] as any;
      const a2 = (criteria as any)[attributeName] as any;
      return a1 === a2;
    }
    return match;
  }, true as boolean);
}