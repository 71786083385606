/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveGazType } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIconsDiveEquipment } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserClubAuthorizations } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { clubSettingsDiveCenterUrlBuilder } from 'src/pages/SE-settings/SE-01_dive-center/clubSettingsDiveCenterUrlBuilder.service';
import { useGazTypeOptions } from 'src/pages/_components/options';
import { ClubSettingsCustomerFormFieldsEditPageLocalState } from './useClubSettingsCustomerFormFieldsEditPageLocalState.hook';

export const ClubSettingsCustomerFormFieldsEditForm_Equipment = ({
  localState,
  className,
}: {
  localState: ClubSettingsCustomerFormFieldsEditPageLocalState;
  className?: string;
}) => {
  const { form } = localState;
  const { control, setValue } = form;

  const clubResume = useClubResume();
  const generalSettings = clubResume.clubSettings?.general;
  const customerSettings = clubResume?.clubSettings?.customer;

  const diveGazTypeOptions: ValueLabel<DiveGazType>[] = useGazTypeOptions();

  const az = useAppSecurityUserClubAuthorizations();

  const [
    generalExternalRef,
    generalExternalRefLabel,
    generalCustomField1,
    generalCustomField1Label,
    generalCustomField2,
    generalCustomField2Label,
    generalCustomField3,
    generalCustomField3Label,
    bookingExternalRef,
    bookingExternalRefLabel,
    bookingCustomField1,
    bookingCustomField1Label,
    bookingCustomField2,
    bookingCustomField2Label,
    bookingCustomField3,
    bookingCustomField3Label,
  ] = useWatch({
    control,
    name: [
      'clubSettings.customer.general.externalRef',
      'clubSettings.customer.general.externalRefLabel',
      'clubSettings.customer.general.customField1',
      'clubSettings.customer.general.customField1Label',
      'clubSettings.customer.general.customField2',
      'clubSettings.customer.general.customField2Label',
      'clubSettings.customer.general.customField3',
      'clubSettings.customer.general.customField3Label',
      'clubSettings.customer.booking.externalRef',
      'clubSettings.customer.booking.externalRefLabel',
      'clubSettings.customer.booking.customField1',
      'clubSettings.customer.booking.customField1Label',
      'clubSettings.customer.booking.customField2',
      'clubSettings.customer.booking.customField2Label',
      'clubSettings.customer.booking.customField3',
      'clubSettings.customer.booking.customField3Label',
    ],
  });

  return az?.edit?.settings?.equipment ? (
    <div className={clsx('app-card p-4', className)}>
      <h2 className="text-left uppercase text-lg leading-6 py-2 font-medium text-gray-800">
        Équipements
      </h2>
      <AppButton
        icon={AppIconsDiveEquipment.all}
        color={'gray-outline-light'}
        href={clubSettingsDiveCenterUrlBuilder.equipments.list()}
      >
        Voir la configuration des équipements
      </AppButton>
    </div>
  ) : null;
};
