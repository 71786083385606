import clsx from 'clsx';
import React from 'react';
import { TailwindBreakpoint } from '../../../services';
import { AppTabTailwind } from './AppTabTailwind.type';
export function AppTabsTailwind<TabId extends string>({
  tabs,
  onChange,
  selected,
  breakpoint,
  theme,
  // fullBarTheme = 'primary',
  className,
}: {
  tabs: AppTabTailwind<TabId>[];
  selected?: TabId;
  onChange?: (tabId: TabId) => void;
  breakpoint?: Extract<TailwindBreakpoint, 'xs' | 'sm'>;
  // fullBarTheme?: AppTabBarTheme
  theme: 'underline' | 'pills' | 'full-bar-todo';
  className?: string;
}) {
  return (
    <div
      className={className}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={clsx(
          'w-full',
          breakpoint === 'xs'
            ? 'xs:hidden'
            : breakpoint === 'sm'
            ? 'sm:hidden'
            : 'hidden',
        )}
      >
        <label htmlFor="tabs" className="sr-only">
          Choisir
        </label>
        <select
          id="tabs"
          name="tabs"
          className={clsx(
            'block w-full uppercase',
            theme === 'pills' &&
              'focus:ring-app-primary focus:border-app-primary border-gray-300 rounded-md',
            theme === 'underline' &&
              'rounded-md border-gray-300 focus:border-app-primary focus:ring-app-primary',
            theme === 'full-bar-todo' &&
              'bg-transparent border-transparent focus:border-transparent focus:ring-transparent',
          )}
          value={selected}
          onChange={(e) => {
            onChange(e.target.value as TabId);
          }}
        >
          {tabs.map((tab) => (
            <option
              key={tab.id}
              value={tab.id}
              onClick={
                tab.onClick
                  ? (e) => {
                      e.stopPropagation();
                      tab.onClick();
                    }
                  : undefined
              }
            >
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div
        className={clsx(
          breakpoint === 'xs'
            ? 'hidden xs:block'
            : breakpoint === 'sm'
            ? 'hidden sm:block'
            : 'block',
          theme === 'full-bar-todo' && 'bg-gray-600 text-white uppercase',
        )}
        // style={{
        //   background:
        //     theme === 'full-bar-todo'
        //       ? 'repeating-linear-gradient(45deg, #40194f, #4f3131)'
        //       : undefined,
        // }}
      >
        <nav
          className={clsx(
            'flex flex-col xs:flex-row',
            theme === 'pills' && 'gap-y-2 xs:space-x-4',
            theme === 'underline' && 'xs:space-x-8',
            theme === 'full-bar-todo' && 'xs:space-x-2',
          )}
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <button
              type="button"
              key={tab.id}
              onClick={(e) => {
                e.stopPropagation();
                if (tab.onClick) {
                  tab.onClick();
                } else {
                  onChange && onChange(tab.id);
                }
              }}
              className={clsx(
                'group inline-flex items-center text-sm uppercase',
                theme === 'full-bar-todo'
                  ? tab.id === selected
                    ? 'font-bold'
                    : 'font-medium'
                  : 'font-medium',
                theme === 'pills' && 'px-3 py-2 rounded-md',
                theme === 'pills' &&
                  (tab.id === selected
                    ? 'bg-app-primary text-white'
                    : 'bg-gray-100 text-gray-500 hover:text-gray-700'),
                theme === 'underline' && 'border-b-2 py-4 px-1',
                theme === 'underline' &&
                  (tab.id === selected
                    ? 'border-app-primary text-app-primary-dark'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'),
                theme === 'full-bar-todo' && 'px-4 py-1 text-xl ',
                theme === 'full-bar-todo' &&
                  (tab.id === selected ? 'text-white' : 'text-gray-300'),
              )}
              aria-current={tab.id === selected ? 'page' : undefined}
            >
              {tab.icon && (
                <tab.icon
                  className={clsx(
                    '-ml-0.5 mr-2 h-5 w-5',
                    theme === 'pills' &&
                      (tab.id === selected
                        ? 'text-white'
                        : 'text-gray-400 group-hover:text-gray-500'),
                    theme === 'underline' &&
                      (tab.id === selected
                        ? 'text-app-primary'
                        : 'text-gray-400 group-hover:text-gray-500'),
                    theme === 'full-bar-todo' &&
                      (tab.id === selected ? 'text-white' : 'text-gray-100'),
                  )}
                  aria-hidden="true"
                />
              )}
              {tab.label}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}
