import {
  APP_COMPANY_MESSAGE_CONTEXTES,
  AppCompanyMessageContext,
} from '@mabadive/app-common-model';
import { appCompanyMessageContextFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form';

export function useAppCompanyMessageContextesOptions() {
  return APP_COMPANY_MESSAGE_CONTEXTES.map(
    (context: AppCompanyMessageContext) => {
      const option: ValueLabel<AppCompanyMessageContext, React.ReactNode> = {
        value: context,
        label: appCompanyMessageContextFormatter
          .formatContext(context)
          .toUpperCase(),
      };
      return option;
    },
  );
}
