import { dateService } from '@mabadive/app-common-services';
import {
  PlanningViewConfig,
  PlanningViewConfigPeriod,
  PlanningViewPeriodDayOptions,
  PlanningViewPeriodMonthOptions,
  PlanningViewPeriodWeekOptions,
  PlanningViewSessionOptions,
} from '../club-planning/_model';

export type PlanningViewConfigUrlParameters = Pick<
  PlanningViewConfig,
  | 'focusDate'
  | 'focusSessionReference'
  | 'viewPeriod'
  | 'viewDayOptions'
  | 'viewWeekOptions'
  | 'viewSessionOptions'
  | 'viewMonthOptions'
>;

export const clubPlanningLightPageUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const QUERY_PARAMS = {
  viewPeriod: 'p',
  focusDate: 'd2', // IMPORTANT: le paramètre "d" est supprimé automatiquement, à cause du planning principal, donc ici on utilise un autre nom (rechercher "Remove "d" parameter from URL")
  focusSessionReference: 's',
  viewDayOptions: {
    displayMode: 'd-dm',
  },
  viewWeekOptions: {
    displayMode: 'w-dm',
  },
  viewSessionOptions: {
    displayMode: 's-dm',
  },
  viewMonthOptions: {
    // displayGroupLevelDetails: 'm-gld',
    // displayPastSessions: 'm-ps',
    // displayEmptySessionsPast: 'm-esp',
    // displayEmptySessionsFuture: 'm-esf',
  },
};

function getQueryParameters(): PlanningViewConfigUrlParameters {
  const params = new URLSearchParams(window.location.search);

  const viewPeriod = params.get(
    QUERY_PARAMS.viewPeriod,
  ) as PlanningViewConfigPeriod;

  let focusDate: Date;
  const focusDateString = params.get(QUERY_PARAMS.focusDate);
  if (focusDateString) {
    const chunks = focusDateString.split('-');
    if (chunks.length === 3) {
      focusDate = new Date(
        Date.UTC(
          parseInt(chunks[0], 10),
          parseInt(chunks[1], 10) - 1,
          parseInt(chunks[2], 10),
        ),
      );
    }
  }

  const viewDayOptions: PlanningViewPeriodDayOptions = {
    displayMode: params.get(QUERY_PARAMS.viewDayOptions.displayMode) as any,
  };

  const viewWeekOptions: PlanningViewPeriodWeekOptions = {
    displayMode: params.get(QUERY_PARAMS.viewWeekOptions.displayMode) as any,
  };

  const viewSessionOptions: PlanningViewSessionOptions = {
    displayMode: params.get(QUERY_PARAMS.viewSessionOptions.displayMode) as any,
  };

  const viewMonthOptions: PlanningViewPeriodMonthOptions = {
    // displayGroupLevelDetails: getBoolean(
    //   params.get(QUERY_PARAMS.viewMonthOptions.displayGroupLevelDetails),
    // ),
    // displayPastSessions: getBoolean(
    //   params.get(QUERY_PARAMS.viewMonthOptions.displayPastSessions),
    // ),
    // displayEmptySessionsPast: getBoolean(
    //   params.get(QUERY_PARAMS.viewMonthOptions.displayEmptySessionsPast),
    // ),
    // displayEmptySessionsFuture: getBoolean(
    //   params.get(QUERY_PARAMS.viewMonthOptions.displayEmptySessionsFuture),
    // ),
  };

  const focusSessionReference = params.get(QUERY_PARAMS.focusSessionReference);
  const config: PlanningViewConfigUrlParameters = {
    focusDate,
    focusSessionReference,
    viewPeriod,
    viewDayOptions,
    viewWeekOptions,
    viewSessionOptions,
    viewMonthOptions,
  };
  return config;
}

function buildSearchParams(
  viewConfig: Partial<PlanningViewConfigUrlParameters>,
): string[] {
  if (!viewConfig) {
    return [];
  }
  const {
    focusDate,
    focusSessionReference,
    viewPeriod,
    viewDayOptions,
    viewWeekOptions,
    viewSessionOptions,
    viewMonthOptions,
  } = viewConfig;

  const searchParams: string[] = [];

  if (viewPeriod) {
    searchParams.push(`${QUERY_PARAMS.viewPeriod}=${viewPeriod}`);
  }
  if (viewDayOptions?.displayMode) {
    searchParams.push(
      `${QUERY_PARAMS.viewDayOptions.displayMode}=${viewDayOptions?.displayMode}`,
    );
  }
  if (viewWeekOptions?.displayMode) {
    searchParams.push(
      `${QUERY_PARAMS.viewWeekOptions.displayMode}=${viewWeekOptions?.displayMode}`,
    );
  }
  if (viewSessionOptions?.displayMode) {
    searchParams.push(
      `${QUERY_PARAMS.viewSessionOptions.displayMode}=${viewSessionOptions?.displayMode}`,
    );
  }
  // if (viewMonthOptions?.displayGroupLevelDetails) {
  //   searchParams.push(
  //     `${QUERY_PARAMS.viewMonthOptions.displayGroupLevelDetails}=${
  //       viewMonthOptions?.displayGroupLevelDetails ? 1 : 0
  //     }`,
  //   );
  // }
  // if (viewMonthOptions?.displayPastSessions) {
  //   searchParams.push(
  //     `${QUERY_PARAMS.viewMonthOptions.displayPastSessions}=${
  //       viewMonthOptions?.displayPastSessions ? 1 : 0
  //     }`,
  //   );
  // }
  // if (viewMonthOptions?.displayEmptySessionsPast) {
  //   searchParams.push(
  //     `${QUERY_PARAMS.viewMonthOptions.displayEmptySessionsPast}=${
  //       viewMonthOptions?.displayEmptySessionsPast ? 1 : 0
  //     }`,
  //   );
  // }
  // if (viewMonthOptions?.displayEmptySessionsFuture) {
  //   searchParams.push(
  //     `${QUERY_PARAMS.viewMonthOptions.displayEmptySessionsFuture}=${
  //       viewMonthOptions?.displayEmptySessionsFuture ? 1 : 0
  //     }`,
  //   );
  // }
  if (focusSessionReference) {
    searchParams.push(
      `${QUERY_PARAMS.focusSessionReference}=${focusSessionReference}`,
    );
  }

  if (focusDate) {
    searchParams.push(
      `${QUERY_PARAMS.focusDate}=${dateService.formatUTC(
        focusDate,
        'YYYY-MM-DD',
      )}`,
    );
  }

  return searchParams;
}

function getBoolean(value: string): boolean {
  if (value === '1') {
    return true;
  }
  if (value === '0') {
    return false;
  }
  return undefined;
}
