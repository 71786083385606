import { dateService } from '@mabadive/app-common-services';
import { useMemo, useState } from 'react';
import { AppTitleDateNavigatorPeriodRange } from 'src/pages/_components/title-navigators/model';
import { DataManagementViewParameters } from './model';

export function useDataManagementPageViewParameters() {
  const initialPeriodRange: AppTitleDateNavigatorPeriodRange = useMemo(() => {
    const today = new Date();

    const endDate = dateService.getUTCDateWithoutTimeFromLocalTime(
      new Date(today.getFullYear(), today.getMonth() - 3, 1), // il y a 3 mois
    );
    let beginDate = new Date(endDate.getTime());
    beginDate.setFullYear(beginDate.getFullYear() - 5); // 5 years before
    // beginDate.setMonth(beginDate.getMonth() - 12); // 12 months before

    return {
      period: 'dates-range',
      value: {
        selectedDate: beginDate,
        beginDate,
        endDate,
      },
    };
  }, []);

  const initialViewParameters: DataManagementViewParameters = useMemo(() => {
    const viewParameters: DataManagementViewParameters = {
      periodRange: initialPeriodRange,
    };
    return viewParameters;
  }, [initialPeriodRange]);

  const [viewParameters, setViewParameters] = useState(initialViewParameters);

  return {
    viewParameters,
    setViewParameters,
  };
}
