/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { useClubResume } from 'src/business/club/data/hooks';
import { BookingInitialPlanParticipantGroupsEditFormRFHRow } from './BookingInitialPlanParticipantGroupsEditFormRFHRow';
import { BookingInitialPlanEditFormModel } from './model';

export const BookingInitialPlanParticipantGroupsEditFormRFH = ({
  form,
  fieldArray,
}: {
  form: UseFormReturn<BookingInitialPlanEditFormModel>;
  fieldArray: UseFieldArrayReturn<
    BookingInitialPlanEditFormModel,
    'bookingInitialPlan.participantsGroups',
    'id'
  >;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const securityUser = useAppSecurityUser();

  const clubResume = useClubResume();
  const clubReference = clubResume.reference;

  const {
    fields: participantsGroupsFields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
  } = fieldArray;

  return (
    <div className="flex flex-col gap-2">
      {participantsGroupsFields.map((field, fieldIndex) => {
        return (
          <BookingInitialPlanParticipantGroupsEditFormRFHRow
            key={field._id}
            form={form}
            fieldArray={fieldArray}
            field={field}
            fieldIndex={fieldIndex}
          />
        );
      })}
    </div>
  );
};
