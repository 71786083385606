import { combineLatest } from 'rxjs';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { rxjsFormBuilder } from 'src/lib/form/components/rxjs-form';
import { UserLoginFormComponentsModel } from './UserLoginFormComponentsModel.type';
import { UserLoginFormModel } from './UserLoginFormModel.type';

export const userLoginRepository = {
  loadInitialData,
}

function loadInitialData() {

  const { components$: userLoginComponents$, form$: userLoginForm$ } = buildForm();

  return combineLatest([
    userLoginForm$,
    userLoginComponents$
  ], (userLoginForm, userLoginComponents) => ({ userLoginForm, userLoginComponents }));

}

function buildForm() {

  return rxjsFormBuilder.buildForm<UserLoginFormModel, UserLoginFormComponentsModel>([{
    name: 'userAuthEmailLogin',
    required: true,
    initialValue: appWebConfig.authentication && appWebConfig.authentication.defaultAuthLogin ? appWebConfig.authentication.defaultAuthLogin.login : undefined,
  }, {
    name: 'userAuthPassword',
    required: true,
  }]);
}
