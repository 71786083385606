import { useCallback, useState } from 'react';
import { EcommerceCategoryEditorInputProps } from './useEcommerceCategoryEditorPanelLocalState.hook';

export function useEcommerceCategoryEditorDialogClient() {
  const [state, setState] = useState<{
    isOpen: boolean;
    props?: EcommerceCategoryEditorInputProps;
  }>({
    isOpen: false,
  });

  const openCreateCategory = useCallback(
    (input: Pick<EcommerceCategoryEditorInputProps, 'defaultValue'>) => {
      const props: EcommerceCategoryEditorInputProps = {
        ...input,
        mode: 'create',
      };
      setState({
        isOpen: true,
        props,
      });
    },
    [],
  );
  const openEditCategory = useCallback(
    (input: Pick<EcommerceCategoryEditorInputProps, 'editValue'>) => {
      const props: EcommerceCategoryEditorInputProps = {
        ...input,
        mode: 'edit',
      };
      setState({
        isOpen: true,
        props,
      });
    },
    [],
  );
  const closeDialog = useCallback(() => {
    setState({
      isOpen: false,
      props: undefined,
    });
  }, []);

  return {
    ...state,
    openCreateCategory,
    openEditCategory,
    closeDialog,
  };
}

export type EcommerceCategoryEditorDialogClient = ReturnType<
  typeof useEcommerceCategoryEditorDialogClient
>;
