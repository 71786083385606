import { dataSorter } from '@mabadive/app-common-services';
import {
  BookingTabModel,
  BookingTabSingleBookingAutoFixSuggestions,
  BookingTabSingleBookingAutoFixSuggestionsSplitBooking,
  BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk,
} from '../../../models';
import {
  bookingTabSingleBookingsAutoFixBookingInactivePeriodSplitter,
  bookingTabSingleBookingsAutoFixBookingOccasionalDiverSplitter,
} from './booking-splitter';
import { BookingTabSingleBookingsAutoFixSuggestionDetectorOptions } from './model';

export const bookingTabSingleBookingsAutoFixSuggestionDetector = {
  detectAll,
  detectOnInactiveBooking,
};

function detectOnInactiveBooking(
  bookingTabModel: BookingTabModel,
): BookingTabSingleBookingAutoFixSuggestions {
  const bookingCustomerUpdates = filterCustomerUpdates(bookingTabModel);

  const autoFixSuggestions: BookingTabSingleBookingAutoFixSuggestions = {
    bookingCustomerUpdates,
    splitBookings: undefined,
    hasAutoFixes: bookingCustomerUpdates.length !== 0,
  };

  return autoFixSuggestions;
}

function detectAll(
  bookingTabModel: BookingTabModel,
  options: BookingTabSingleBookingsAutoFixSuggestionDetectorOptions,
): BookingTabSingleBookingAutoFixSuggestions {
  const bookingCustomerUpdates = filterCustomerUpdates(bookingTabModel);

  const splitBookings = bookingCustomerUpdates.length
    ? undefined
    : detectSplitbookings(bookingTabModel, options);

  const autoFixSuggestions: BookingTabSingleBookingAutoFixSuggestions = {
    bookingCustomerUpdates,
    splitBookings,
    hasAutoFixes: bookingCustomerUpdates.length !== 0 || !!splitBookings,
  };

  return autoFixSuggestions;
}

function filterCustomerUpdates(bookingTabModel: BookingTabModel) {
  return bookingTabModel.aggregatedBooking.bookingCustomerUpdates.filter(
    (x) => x.status === 'to-validate',
  );
}

function detectSplitbookings(
  tabModel: BookingTabModel,
  options: BookingTabSingleBookingsAutoFixSuggestionDetectorOptions,
): BookingTabSingleBookingAutoFixSuggestionsSplitBooking {
  if (!tabModel.aggregatedBooking.booking.active) {
    return;
  }

  const datesRange = tabModel.meta.datesRange;

  // put sessions in ASC order
  const participantsBySessions = dataSorter.sortMultiple(
    tabModel.participantsBySessions,
    {
      getSortAttributes: (x) => [
        {
          value: x?.bookingSessionFull?.diveSession.time,
          type: 'default',
          asc: true,
        },
        {
          value: x?.bookingSessionFull?.bookingSession._id,
          type: 'default',
        },
      ],
      asc: true,
    },
  );

  const initialChunk: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk =
    {
      minDate: datesRange.minDate,
      maxDate: datesRange.maxDate,
      bookingMembersFull: tabModel.aggregatedBooking.bookingMembersFull,
      participantsBySessions,
      active: tabModel.aggregatedBooking.booking.active,
      comment: '',
    };
  let chunks: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk[] = [
    initialChunk,
  ];

  {
    // 1) découpe par longue période sans plongée (ex: 3 mois)
    let previousChunksSize: number;
    do {
      previousChunksSize = chunks.length;

      chunks =
        bookingTabSingleBookingsAutoFixBookingInactivePeriodSplitter.splitByInactivePeriod(
          {
            chunks,
            tabModel,
            options: options.inactivePeriod.large,
          },
        );
    } while (chunks.length > previousChunksSize);
  }

  {
    // 2) découpe par plongeur occassionel
    let previousChunksSize: number;
    do {
      previousChunksSize = chunks.length;

      chunks =
        bookingTabSingleBookingsAutoFixBookingOccasionalDiverSplitter.splitByOccasionalDiver(
          {
            chunks,
            tabModel,
            options: options.occasionalDiver,
          },
        );
    } while (chunks.length > previousChunksSize);
  }

  {
    // 3) découpe par moyenne période sans plongée (ex: 2 semaines)
    let previousChunksSize: number;
    do {
      previousChunksSize = chunks.length;

      chunks =
        bookingTabSingleBookingsAutoFixBookingInactivePeriodSplitter.splitByInactivePeriod(
          {
            chunks,
            tabModel,
            options: options.inactivePeriod.medium,
          },
        );
    } while (chunks.length > previousChunksSize);
  }

  if (chunks.length > 1) {
    return {
      tabModel,
      bookingsChunks: chunks,
    };
  }
}
