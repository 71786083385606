/* eslint-disable @typescript-eslint/no-unused-vars */
import { Booking } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import {
  useBookingAgenciesOptions,
  useBookingOptions,
} from 'src/pages/_components/options';
import { DiverBookingPageAggregatedData } from '../../../models';
import { DiverPurchaseOtherFormModel } from '../../DiverPurchaseOtherEditorDialog/components';
import { DiverPurchasePlanFormModel } from '../../DiverPurchasePlanEditorDialog/components';
import { DiverPurchaseTrainingFormModel } from '../../DiverPurchaseTrainingEditorDialog/components';
import { DiverPurchaseCommonFormModel } from '../model';

export const DiverPurchaseCommonFormBookingAndAgency = ({
  aggregatedData,
  form: formInput,
  initialBookingId,
  isCreation,
}: {
  aggregatedData: DiverBookingPageAggregatedData;
  initialBookingId: string;
  isCreation: boolean;
  form:
    | UseFormReturn<DiverPurchasePlanFormModel, any, undefined>
    | UseFormReturn<DiverPurchaseTrainingFormModel, any, undefined>
    | UseFormReturn<DiverPurchaseOtherFormModel, any, undefined>;
}) => {
  const clubResume = useClubResume();

  const clubSettings = clubResume.clubSettings;
  const agenciesOptions = useBookingAgenciesOptions();

  const form: UseFormReturn<DiverPurchaseCommonFormModel, any, undefined> =
    formInput as unknown as UseFormReturn<
      DiverPurchaseCommonFormModel,
      any,
      undefined
    >;
  const { control, setValue } = form;

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume._id;
  const bookingOptions: ValueLabel<string, React.ReactNode>[] =
    useBookingOptions({
      aggregatedData,
      onlyActive: isCreation,
      initialBookingId,
      diveCenterId,
    });

  const [bookingId, billedByBookingAgencyId] = useWatch({
    control,
    name: ['bookingId', 'billedByBookingAgencyId'],
  });
  const bookings: Booking[] = aggregatedData.bookings;
  const selectedBooking = useMemo(() => {
    return !bookingId ? undefined : bookings.find((b) => b._id === bookingId);
  }, [bookingId, bookings]);

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');
  const forceBookingVisibility = isSuperAdmin && !isCreation; // en édition, on affiche toujours le booking pour le super admin

  const isBookingSet = useMemo(() => {
    if (bookingId) {
      // on vérifie que la valeur fait bien partie des options (sinon, il faut afficher le champ)
      return bookingOptions.some((b) => b.value === bookingId);
    }
    return false;
  }, [bookingId, bookingOptions]);

  const isBookingOptionsVisible =
    (forceBookingVisibility ||
      clubSettings.general.billing?.booking?.enabled) &&
    bookingOptions?.length > 0 && // au moins 1 option
    (!isBookingSet || bookingOptions?.length > 1); // plusieurs options, ou pas d'option définie

  const isAgenciesOptionsVisible =
    clubSettings.general.agencies?.enabled &&
    clubSettings.general.agencies?.agencyPayment;

  const isVisible = isBookingOptionsVisible || isAgenciesOptionsVisible;

  return isVisible ? (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      {isBookingOptionsVisible && (
        <AppFormControlRHF_Deprecated
          className=""
          label="Réservation associée"
          control={control}
          name="bookingId"
          required={clubSettings.general.billing?.booking?.required}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              theme={'material-ui'}
              {...props}
              options={bookingOptions}
            />
          )}
        />
      )}
      {isAgenciesOptionsVisible && (
        <div className="flex gap-2">
          <AppFormControlRHF_Deprecated
            className="flex-grow w-full"
            label="Facturé par agence"
            control={control}
            name="billedByBookingAgencyId"
            required={false}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF {...props} options={agenciesOptions} />
            )}
          />
          {!billedByBookingAgencyId && selectedBooking?.details?.agencyId && (
            <AppButton
              className="w-auto text-xs font-bold uppercase mt-2 self-start whitespace-nowrap"
              style={{ minWidth: '6rem' }}
              color={'primary-outline-light'}
              icon={AppHeroIcons.autoFill}
              onClick={() =>
                setValue(
                  'billedByBookingAgencyId',
                  selectedBooking?.details?.agencyId,
                  {
                    shouldTouch: true,
                    shouldDirty: true,
                  },
                )
              }
            >
              Idem résa
            </AppButton>
          )}
        </div>
      )}
    </div>
  ) : null;
};
