/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { COUNTRY_OPTIONS } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms/BookingMemberDiverEditForm/COUNTRY_OPTIONS.const';
import { AppSingleAutocomplete2RHF } from 'src/lib/form';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { useTimezoneOptions } from 'src/pages/_components/options';
import { ClubSettingsGeneralFormModel } from '../../../_model';

export const ClubSettingsGeneralMainFormLocalisation = ({
  form,
}: {
  form: UseFormReturn<ClubSettingsGeneralFormModel>;
}) => {
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const clubResume = useClubResume();

  const timezoneOptions = useTimezoneOptions();

  return (
    <ClubSettingsSection title="Localisation">
      <div className="mt-2 grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Région / Pays"
          control={control}
          name={'clubSettings.general.countryIsoCode'}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={COUNTRY_OPTIONS}
              fullWidth
            />
          )}
        />
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Fuseau horaire"
          control={control}
          name={'clubSettings.general.timeZoneName'}
          renderComponent={(props) => (
            <AppSingleAutocomplete2RHF
              {...props}
              options={timezoneOptions}
              fullWidth
            />
          )}
        />
      </div>
    </ClubSettingsSection>
  );
};
