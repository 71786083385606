/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  ClubResumeStaffMember,
  ClubStaffMemberDailyAvailabilityPeriod,
  ClubStaffMemberRole,
  DiveSession,
  StaffMemberResumeSessionsDayModel,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';

import {
  arrayBuilder,
  staffMemberResumeSessionsBuilder,
  staffMemberResumeSessionsSorter,
} from '@mabadive/app-common-services';
import { staffMemberOptionsBuilder } from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/services';
import { DiveSessionStaffRoleModel } from '../../../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog/tabs/DiveSessionDialogTab3Groups/components';
import { SetPlanningConfigOptions } from '../../../club-planning-light/hooks';
import {
  ClubPlanningDayFull,
  ClubPlanningLightPanelViewClickActions,
} from '../../../club-planning-light/model';
import { PlanningViewConfig } from '../../_model';
import { ClubPlanningStaffPanelLocalState } from '../ClubPlanningStaffPanel';
import {
  PlanningStaffWeekPresenceUpdateState,
  planningStaffWeekPresenceCacheUpdator,
} from '../cache';
import { ClubPlanningStaffPageCacheAggregatedDataFull } from './_model';

export const useClubPlanningStaffWeekStaffPresenceLocalState = ({
  parentState,
  planningConfig,
  setPlanningConfig,
  clickActions,
}: {
  parentState: ClubPlanningStaffPanelLocalState;
  planningConfig: PlanningViewConfig;
  setPlanningConfig: (
    config: PlanningViewConfig,
    options: SetPlanningConfigOptions,
  ) => void;
  clickActions: ClubPlanningLightPanelViewClickActions;
}) => {
  const diveCenterResume = useDiveCenterResume();
  const clubReference = diveCenterResume.clubReference;
  const diveCenterId = diveCenterResume._id;

  const [pageMode, setPageMode] = useState<'view' | 'edit'>('edit');

  const { cache, days: initialDays } = parentState.data;
  const dailyConfigs = cache.aggregatedDataCore.dailyConfigs;

  const weekSessions = useMemo(
    () =>
      initialDays
        .reduce(
          (acc, d) => acc.concat(d.sessions),
          [] as ClubPlanningLightSessionDef[],
        )
        .map((sessionDef) => {
          const session = cache.aggregatedDataCore.diveSessions.find(
            (x) => x.reference === sessionDef.reference,
          );
          if (session) {
            // mise à jour avec les sessions aggrégées
            return { ...sessionDef, ...session };
          }
          return sessionDef;
        }),
    [cache.aggregatedDataCore.diveSessions, initialDays],
  );

  const {
    diveSessions: weekDiveSessions,
    staffMembersResumeSessions: staffMembersResumeSessionsBeforeSortAndFilter,
  }: {
    diveSessions: ClubPlanningLightSessionDef[];
    staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
  } = useMemo(
    () =>
      staffMemberResumeSessionsBuilder.buildStaffMemberResumeSessions({
        diveCenterResume,
        sessions: weekSessions,
        staffFirstNameBefore: planningConfig.staffFirstNameBefore,
        staffScope: 'all-with-active-working-period',
        dailyConfigs,
        days: initialDays,
      }),
    [
      dailyConfigs,
      diveCenterResume,
      initialDays,
      planningConfig.staffFirstNameBefore,
      weekSessions,
    ],
  );

  const displayRoles =
    planningConfig.displayModeStaffPresenceOptions?.displayRoles;

  const filterStaffMemberId = parentState.criteria.filterStaffMemberId;

  // on garde ce tri qui permet d'afficher les inactifs en dernier!
  // const staffMembersResumeSessionsBeforeFilter =
  //   staffMembersResumeSessionsBeforeSortAndFilter;

  // NOTE: ça serait mieux de ne pas tout chambouler, donc prendre l'ordre avant modification par exemple (quand on valide, on tri!)

  // on ne veut pas voir en haut de liste les moniteurs non staffés, mais si on les active, alors on ne veut pas de saut, sauf au moment ou on sauvegarde
  // donc on sauvegarde l'ordre initial, et on le met à jour quand on sauvegarde les modifs

  const [initialStaffMemberIdsSorted, setInitialStaffSortIndex] = useState<
    string[]
  >(
    staffMembersResumeSessionsBeforeSortAndFilter.map((x) => x.staffMember._id),
  );

  const staffMembersResumeSessionsBeforeFilter = useMemo(
    () =>
      staffMemberResumeSessionsSorter.sortByInitialSortIndex(
        staffMembersResumeSessionsBeforeSortAndFilter,
        {
          initialStaffMemberIdsSorted,
        },
      ),
    [
      initialStaffMemberIdsSorted,
      staffMembersResumeSessionsBeforeSortAndFilter,
    ],
  );
  const resetSortOrder = useCallback(() => {
    // on met à jour le tri
    setInitialStaffSortIndex(
      staffMemberResumeSessionsSorter
        .sortByAvailability(staffMembersResumeSessionsBeforeSortAndFilter, {
          staffFirstNameBefore: planningConfig.staffFirstNameBefore,
        })
        .map((x) => x.staffMember._id),
    );
  }, [
    planningConfig.staffFirstNameBefore,
    staffMembersResumeSessionsBeforeSortAndFilter,
  ]);

  useEffect(() => {
    if (parentState.state.lastPersistDate) {
      // @see REF TRI MONITEUR
      resetSortOrder();
    }
    // NOTE: dépendance seulement sur lastPersistDate, pour ne re-trier que si on enregistre
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentState.state.lastPersistDate]);

  const staffMemberOptions = useMemo(
    () =>
      staffMemberOptionsBuilder.buildStaffMemberOptionsString({
        staffMembers: staffMemberResumeSessionsSorter
          .sortByName(staffMembersResumeSessionsBeforeFilter, {
            staffFirstNameBefore: planningConfig.staffFirstNameBefore,
          })
          .map((x) => x.staffMember),
        // date:
        //   weekDiveSessions.length > 0 ? weekDiveSessions[0].time : new Date(),
      }),
    [
      planningConfig.staffFirstNameBefore,
      staffMembersResumeSessionsBeforeFilter,
    ],
  );

  const staffMembersResumeSessions = useMemo(() => {
    return filterStaffMemberId
      ? staffMembersResumeSessionsBeforeFilter.filter(
          (x) => x.staffMember._id === filterStaffMemberId,
        )
      : staffMembersResumeSessionsBeforeFilter; // all (default)
  }, [filterStaffMemberId, staffMembersResumeSessionsBeforeFilter]);

  const availableRoles: ClubStaffMemberRole[] = useMemo(
    () =>
      arrayBuilder.filterDuplicated(
        staffMembersResumeSessions.reduce(
          (acc, x) => acc.concat(x.staffMember.profile.roles),
          [] as ClubStaffMemberRole[],
        ),
      ),
    [staffMembersResumeSessions],
  );

  const aggregateData: ClubPlanningStaffPageCacheAggregatedDataFull =
    useMemo(() => {
      const days: ClubPlanningDayFull[] = initialDays.map((day) => {
        // NOTE: ici le tri des membres par jour dans buildStaffMemberResumeSessions est inutile, car il est fait globalement?
        // à moins que quand on zoom sur la journée? TODO à vérifier!
        const {
          diveSessions,
          staffMembersResumeSessions,
        }: {
          diveSessions: ClubPlanningLightSessionDef[];
          staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
        } = staffMemberResumeSessionsBuilder.buildStaffMemberResumeSessions({
          diveCenterResume,
          sessions: day.sessions.map((sessionDef) => {
            const session = cache.aggregatedDataCore.diveSessions.find(
              (d) => d.reference === sessionDef.reference,
            );
            if (session) {
              return {
                ...sessionDef,
                ...session,
              };
            }
            return sessionDef;
          }),
          staffFirstNameBefore: planningConfig.staffFirstNameBefore,
          staffScope: 'all-with-active-working-period',
          dailyConfigs: cache.aggregatedDataCore.dailyConfigs,
          days: initialDays,
        });

        const dayFull: ClubPlanningDayFull = {
          ...day,
          diveSessions,
          staffMembersResumeSessions,
        };

        return dayFull;
      });
      const result: ClubPlanningStaffPageCacheAggregatedDataFull = {
        days,
        core: cache.aggregatedDataCore,
        weekDiveSessions,
        staffMembersResumeSessions,
        availableRoles,
      };
      return result;
    }, [
      availableRoles,
      cache.aggregatedDataCore,
      diveCenterResume,
      initialDays,
      planningConfig,
      staffMembersResumeSessions,
      weekDiveSessions,
    ]);

  const updateDayConfig = useCallback(
    (params: {
      staffMember: ClubResumeStaffMember;
      staffMemberResumeSessionsDay: StaffMemberResumeSessionsDayModel;
      availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod;
    }) => {
      const { availabilityPeriod, staffMember, staffMemberResumeSessionsDay } =
        params;
      const { dailyConfig, date, dayReference } = staffMemberResumeSessionsDay;
      const localUpdateState =
        planningStaffWeekPresenceCacheUpdator.updateStaffMemberPresence(
          {
            availabilityPeriod,
            date,
            dayReference,
            staffMember,
            dailyConfig,
          },
          {
            clubReference,
            diveCenterId,
            updateState: cache.updateState,
          },
        );

      cache.setUpdateState(localUpdateState);
    },
    [cache, clubReference, diveCenterId],
  );

  type LocalUpdateSessionStaffConfigProps = {
    availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod;
    isAvailableOnSession: boolean;
    toogleRole?: Pick<DiveSessionStaffRoleModel, 'assigned' | 'staffRoleRef'>;
    diveSession: DiveSession;
  };

  const updateSessionStaffConfig = useCallback(
    (
      staffMember: ClubResumeStaffMember,
      {
        diveSession,
        availabilityPeriod,
        isAvailableOnSession,
        toogleRole,
      }: LocalUpdateSessionStaffConfigProps,
    ) => {
      planningStaffWeekPresenceCacheUpdator.updateSessionStaffConfig({
        updateState: cache.updateState,
        diveSession,
        staffMember,
        availabilityPeriod,
        isAvailableOnSession,
        toogleRole,
        form: undefined,
        dailyConfigs,
        setUpdateState: cache.setUpdateState as (
          updateState: PlanningStaffWeekPresenceUpdateState,
        ) => void,
      });
    },
    [cache.setUpdateState, cache.updateState, dailyConfigs],
  );

  const showDayWithoutSessionsDetails = false;

  return {
    state: {
      showDayWithoutSessionsDetails,
      dialogsState: parentState.state.dialogsState,
    },
    data: {
      aggregateData,
      cache,
      planningConfig,
      dailyConfigs,
      days: parentState.data.days,
    },
    actions: {
      ...parentState.actions,
      updateDayConfig,
      updateSessionStaffConfig,
      setPlanningConfig,
      clickActions,
    },
    criteria: {
      ...parentState.criteria,
      staffMemberOptions,
      displayRoles,
      pageMode,
      setPageMode,
    },
  };
};
export type ClubPlanningStaffWeekStaffPresenceLocalState = ReturnType<
  typeof useClubPlanningStaffWeekStaffPresenceLocalState
>;
