import { AppEntityUpdatePatch } from '@mabadive/app-common-model';
import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
export function useDiverBookingPageDeletePurchasePayment({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    ({
      deletedPurchasePaymentId,
      updatedClubPurchasePackages,
      deletedBookingDepositIds,
      deletedPaymentBookingDepositIds,
    }: {
      deletedPurchasePaymentId: string;
      updatedClubPurchasePackages: AppEntityUpdatePatch[];
      deletedBookingDepositIds: string[];
      deletedPaymentBookingDepositIds: string[];
    }) => {
      if (deletedPurchasePaymentId) {
        const purchasePaymentsChanges = changeDescriptorManager.deleteOne(
          deletedPurchasePaymentId,
          {
            changeDescriptors: updateState.purchasePaymentsChanges,
          },
        );
        const purchasePackagesChanges = changeDescriptorManager.updateMany(
          updatedClubPurchasePackages,
          {
            changeDescriptors: updateState.purchasePackagesChanges,
          },
        );
        const bookingDepositsChanges = changeDescriptorManager.deleteMany(
          deletedBookingDepositIds,
          {
            changeDescriptors: updateState.bookingDepositsChanges,
          },
        );
        const paymentsBookingDepositsDetailsChanges =
          changeDescriptorManager.deleteMany(deletedPaymentBookingDepositIds, {
            changeDescriptors:
              updateState.paymentsBookingDepositsDetailsChanges,
          });
        // NOTE: voir aussi useDiverBookingPageDeleteBookingDeposit
        setUpdateState(
          {
            ...updateState,
            purchasePaymentsChanges,
            purchasePackagesChanges,
            bookingDepositsChanges,
            paymentsBookingDepositsDetailsChanges,
          },
          {
            action: 'DiverBookingPageDeletePurchasePayment',
          },
        );
      }
    },
    [setUpdateState, updateState],
  );
}
