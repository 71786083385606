/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { uiStore } from 'src/business/_core/store';

import {
  ClubSettingsOnlineBookingDialogState,
  ClubSettingsOnlineBookingFormModel,
} from '../_model';
import { ClubSettingsOnlineBookingDialogLocalState } from './useClubSettingsOnlineBookingDialogLocalState.hook';

export function useClubSettingsOnlineBookingDialogSubmit({
  handleSubmit,
  localState,
  inputState,
}: Pick<UseFormReturn<ClubSettingsOnlineBookingFormModel>, 'handleSubmit'> & {
  localState: ClubSettingsOnlineBookingDialogLocalState;
  inputState: React.PropsWithChildren<ClubSettingsOnlineBookingDialogState>;
}) {
  return useCallback(async () => {
    try {
      return handleSubmit(
        (formValue: ClubSettingsOnlineBookingFormModel) => {
          const { onConfirm, closeDialog } = inputState;
          if (localState.hasFormChanges) {
            return onConfirm({
              formValue,
            });
          } else {
            return closeDialog();
          }
        },
        (err) => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
          throw err;
        },
      )();
    } catch (err) {
      // message already displayed
    }
  }, [handleSubmit, inputState, localState]);
}
