/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';

export const ClubSettingsCommunicationMain_InfoMessage = () => {
  return (
    <AppMessageLight className="my-4" hideIcon={true}>
      <div className="grid gap-4">
        <div className="grid gap-2">
          <h4 className="font-bold uppercase">Email (auto ⚡)</h4>
          <ul className="ml-3 list-disc">
            <li>Le mail est envoyé automatiquement par mabadive</li>
            <li>
              La réponse se fait directement sur votre adresse de messagerie
            </li>
            <li>Permet de programmer des emails automatiques</li>
          </ul>
        </div>{' '}
        <div className="grid gap-2">
          <h4 className="font-bold uppercase">Email (manuel ❌)</h4>
          <ul className="ml-3 list-disc">
            <li>Ouvre l'appliation de messagerie de votre appareil</li>
            <li>
              L'email des destinataires, le titre et le message sont pré-remplis
            </li>
            <li>Ne fonctionne que si l'adresse e-mail est renseignée.</li>
          </ul>
        </div>
        <div className="grid gap-2">
          <h4 className="font-bold uppercase">Téléphone (manuel)</h4>
          <ul className="ml-3 list-disc">
            <li>Ouvre l'application "Téléphone" de votre appareil</li>
            <li>Le numéro du destinataire est pré-remplis</li>
            <li>Ne fonctionne que si le numéro de téléphone est renseigné.</li>
            <li>
              Ne fonctionne que depuis un téléphone (ou tablette/ordi
              compatible)
            </li>
          </ul>
        </div>
        <div className="grid gap-2">
          <h4 className="font-bold uppercase">SMS (manuel)</h4>
          <ul className="ml-3 list-disc">
            <li>Ouvre l'application "SMS" de votre appareil</li>
            <li>Le numéro des destinataires et le message sont pré-remplis</li>
            <li>Ne fonctionne que si le numéro de téléphone est renseigné.</li>
            <li>
              Ne fonctionne que depuis un téléphone (ou tablette/ordi
              compatible)
            </li>
          </ul>
        </div>
        <div className="grid gap-2">
          <h4 className="font-bold uppercase">Whatsapp (manuel)</h4>
          <ul className="ml-3 list-disc">
            <li>
              Ouvre l'application "Whatsapp" sur "Téléphone ou Tablette. Sur
              ordinateur, ouvre WhatsApp Web.
            </li>
            <li>Le numéro du destinataire et le message sont pré-remplis</li>
            <li>
              Il n'est possible de contacter qu'un seul destinataire à la fois.
            </li>
            <li>
              Ne fonctionne que si le numéro de téléphone commence par le signe
              "+" (ou "06" et "07" que l'on préfixe automatiquement par "+33")
            </li>
          </ul>
        </div>
      </div>
    </AppMessageLight>
  );
};
