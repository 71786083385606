/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { BookingParticipantEditorDialogState } from '../../models';

export function useClubDiverSelectorDialogSubDialogs() {
  const [bookingParticipantEditorState, setBookingParticipantEditorState] =
    useState<BookingParticipantEditorDialogState>({
      isOpen: false,
    });
  const isAnyFakeDialogOpen = bookingParticipantEditorState.isOpen;

  return {
    isAnyFakeDialogOpen,
    bookingParticipantEditorState,
    setBookingParticipantEditorState,
  };
}

export type ClubDiverSelectorDialogSubDialogs = ReturnType<
  typeof useClubDiverSelectorDialogSubDialogs
>;
