import {
  AppCompanyMessageLocalized,
  AppLanguageCode,
} from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';

export const translateApiClient = {
  translateMessage,
  translateMessages,
};
function translateMessages(
  sourceMessages: Pick<AppCompanyMessageLocalized, 'title' | 'body'>[],
  {
    fromLanguageCode,
    toLanguageCode,
  }: {
    fromLanguageCode: AppLanguageCode;
    toLanguageCode: AppLanguageCode;
  },
): Promise<{
  translatedMessages: Pick<AppCompanyMessageLocalized, 'title' | 'body'>[];
}> {
  return apiClient
    .post<any>('/pro/translate/messages', {
      authenticate: true,
      json: {
        fromLanguageCode,
        toLanguageCode,
        sourceMessages,
      },
      maxTries: 2,
      timeout: 120000,
    })
    .toPromise();
}

function translateMessage(
  sourceMessage: Pick<
    AppCompanyMessageLocalized,
    'title' | 'body' | 'languageCode'
  >,
  {
    toLanguageCode,
  }: {
    toLanguageCode: AppLanguageCode;
  },
): Promise<{
  translatedMessage: Pick<
    AppCompanyMessageLocalized,
    'title' | 'body' | 'languageCode'
  >;
}> {
  return apiClient
    .post<any>('/pro/translate/message', {
      authenticate: true,
      json: {
        toLanguageCode,
        sourceMessage,
      },
      maxTries: 2,
      timeout: 120000,
    })
    .toPromise();
}
