import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';

export function useDiverBookingPageDeleteCreditNote({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    ({ deletedCreditNoteId }: { deletedCreditNoteId: string }) => {
      if (deletedCreditNoteId) {
        const creditNotesChanges = changeDescriptorManager.deleteOne(
          deletedCreditNoteId,
          {
            changeDescriptors: updateState.creditNotesChanges,
          },
        );
        setUpdateState(
          {
            ...updateState,
            creditNotesChanges,
          },
          {
            action: 'DiverBookingPageDeleteCreditNote',
          },
        );
      }
    },
    [setUpdateState, updateState],
  );
}
