import { ClubPurchasePayment } from '@mabadive/app-common-model';
import React from 'react';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { useAppCurrency } from 'src/pages/_components/options';

export function PurchasePaymentAmountDetails({
  purchasePayment,
  className,
}: {
  purchasePayment: Pick<
    ClubPurchasePayment,
    | 'amount'
    | 'currencyIsoCode'
    | 'usedCurrencyAmount'
    | 'usedCurrencyIsoCode'
    | 'paymentState'
    | 'onlinePlatformDetails'
  >;
  className?: string;
}) {
  const paymentIntents = purchasePayment.onlinePlatformDetails?.paymentIntents;
  const billingCurrency = useAppCurrency(purchasePayment.currencyIsoCode);
  const usedCurrency = useAppCurrency(purchasePayment.usedCurrencyIsoCode);

  return (
    <div className={className}>
      <div>
        <AppPriceLabel
          amount={purchasePayment.amount}
          mainCurrency={billingCurrency}
        />
        {purchasePayment.paymentState === 'pending' && (
          <AppIcons.ui.statusPendingSolid className="ml-1 inline w-4 h-4 bg-app-red text-white" />
        )}
        {usedCurrency?.isoCode &&
          billingCurrency?.isoCode !== usedCurrency?.isoCode && (
            <>
              <span className="text-gray-600">{' ➔ '}</span>
              <AppPriceLabel
                amount={purchasePayment.usedCurrencyAmount}
                mainCurrency={usedCurrency}
              />
            </>
          )}
      </div>
      {purchasePayment.paymentState === 'authorized' &&
        paymentIntents?.totalAmountCapturable > 0 && (
          <div className="">
            <AppPriceLabel
              className={
                paymentIntents?.totalAmountCapturable ===
                purchasePayment.usedCurrencyAmount
                  ? 'text-status-warn'
                  : 'text-status-error'
              }
              amount={paymentIntents?.totalAmountCapturable}
              mainCurrency={usedCurrency}
            />
            <span className="hidden md:inline text-xs text-gray-500">
              {' (stripe)'}
            </span>
          </div>
        )}
      {purchasePayment.paymentState === 'validated' &&
        paymentIntents?.totalAmountReceived > 0 && (
          <div className="">
            <AppPriceLabel
              className={
                paymentIntents?.totalAmountReceived ===
                purchasePayment.usedCurrencyAmount
                  ? 'text-status-success'
                  : 'text-status-error'
              }
              amount={paymentIntents?.totalAmountReceived}
              mainCurrency={usedCurrency}
            />
            <span className="hidden md:inline text-xs text-gray-500">
              {' (stripe)'}
            </span>
          </div>
        )}
    </div>
  );
}
