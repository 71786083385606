import { useMemo } from 'react';
import { combineLatest } from 'rxjs';
import { useLoadable } from 'src/business/_core/data/hooks';
import { uiStore } from 'src/business/_core/store';
import {
  SIDE_MENU_WIDTH_COLLAPSED,
  SIDE_MENU_WIDTH_OPEN,
} from '../../../root/theme';
import { useMediaSizeTailwind } from '../../services';

export function useSideMenu(
  {
    hidden,
  }: {
    hidden?: boolean;
  } = {
    hidden: false,
  },
) {
  const { isMenuOpen, isMenuDisabled, isMenuCollapsed, isMenuEmpty } =
    useLoadable({
      debugName: 'useSideMenu',
      initialValueFromLoadSnapshot: true,
      load: () =>
        combineLatest(
          [
            uiStore.isMenuOpen.get(),
            uiStore.isMenuDisabled.get(),
            uiStore.isMenuCollapsed.get(),
            uiStore.isMenuEmpty.get(),
          ],
          (isMenuOpen, isMenuDisabled, isMenuCollapsed, isMenuEmpty) => {
            if (hidden) {
              return {
                isMenuOpen: false,
                isMenuDisabled,
                isMenuCollapsed: true,
                isMenuEmpty: true,
              };
            }
            return { isMenuOpen, isMenuDisabled, isMenuCollapsed, isMenuEmpty };
          },
        ),
    });

  function openMenu(open = true) {
    uiStore.isMenuOpen.set(open);
  }

  function closeMenu() {
    openMenu(false);
  }

  function disableMenu(disabled = true) {
    uiStore.isMenuDisabled.set(disabled);
  }

  function toggleMenu() {
    openMenu(!isMenuOpen);
  }

  const mediaSize = useMediaSizeTailwind('xs');
  const isMenuTemporary = useMemo(
    () =>
      mediaSize === 'xxs' ||
      mediaSize === 'xs' ||
      mediaSize === 'sm' ||
      mediaSize === 'md' ||
      mediaSize === 'lg',
    [mediaSize],
  );

  function setCollapsed(collapsed = true) {
    uiStore.isMenuCollapsed.set(collapsed);
  }

  useMemo(() => {
    if (isMenuTemporary) {
      setCollapsed(false);
    }
  }, [isMenuTemporary]);

  const menuWidth = useMemo(() => {
    if (isMenuTemporary) {
      return 0;
    } else {
      return isMenuCollapsed ? SIDE_MENU_WIDTH_COLLAPSED : SIDE_MENU_WIDTH_OPEN;
    }
  }, [isMenuCollapsed, isMenuTemporary]);

  return {
    isMenuEmpty,
    isMenuOpen,
    isMenuDisabled,
    disableMenu,
    isMenuTemporary,
    closeMenu,
    openMenu,
    toggleMenu,
    isMenuCollapsed,
    setCollapsed,
    menuWidth,
  };
}
