/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSession, JsonPatchOperation } from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';

export const sessionUpdateBuilder = {
  buildCreateSession,
  buildUpdateSession,
};
function buildCreateSession(diveSession: DiveSession): {
  diveSessionsPatchOperations: JsonPatchOperation[];
  createdDiveSession?: DiveSession;
} {
  const createdDiveSession = commonEntityBuilder.buildEntity<DiveSession>({
    ...diveSession,
    isVirtual: false,
  });

  return {
    createdDiveSession,
    diveSessionsPatchOperations: [],
  };
}

function buildUpdateSession({
  originalDiveSession,
  updatedDiveSession,
}: {
  originalDiveSession: DiveSession;
  updatedDiveSession: DiveSession;
}): {
  diveSessionsPatchOperations: JsonPatchOperation[];
  createdDiveSession?: DiveSession;
} {
  // update
  const diveSessionsPatchOperations = jsonPatcher.compareObjects(
    originalDiveSession,
    updatedDiveSession,
    {
      attributesToReplaceFully: ['staffConfig'],
    },
  );
  return {
    diveSessionsPatchOperations,
  };
}
