import React, { useMemo } from 'react';

export function DiverBillingSessionsTableParticipantCellEmpty({
  displayName = false,
  displayAssignButton = false,
  className,
}: {
  displayName: boolean;
  displayAssignButton: boolean;
  className?: string;
}) {
  const heightClassName = useMemo(() => {
    if (displayAssignButton || displayName) {
      // NOTE: pour le moment, on ne l'utilise que dans 1 seul contexte, mais il faudra ajuster la hauteur si besoin en fonction des paramètres
    }
    return 'h-[7.45rem]';
  }, [displayAssignButton, displayName]);

  return (
    <div
      className={`${heightClassName}  ${className ?? ''}
      `}
    ></div>
  );
}
