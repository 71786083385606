import { ClubParticipant, DiveSession } from '@mabadive/app-common-model';
import { diveSessionServiceFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';

export const ParticipantDiveType = ({
  participant,
  diveSession,
  isCancelledLineThrough = false,
  className,
}: {
  participant: Pick<
    ClubParticipant,
    'tags' | 'comment' | 'bookingState' | 'diveMode' | 'divesCount'
  >;
  diveSession: Pick<DiveSession, 'status' | 'time' | 'diveTourSession2'>;
  isCancelledLineThrough?: boolean;
  className?: string;
}) => {
  const clubSettings = useClubSettings();
  const diveSessionStatus = useMemo(
    () =>
      participant && participant.bookingState.value === 'cancelled'
        ? 'cancelled'
        : diveSession.status,
    [diveSession.status, participant],
  );

  const isMultiSession = !!diveSession.diveTourSession2;

  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;
  const clubPublicSettings = useClubSettings()?.publicSettings;
  return (
    <div
      className={clsx(
        isCancelledLineThrough &&
          diveSessionStatus === 'cancelled' &&
          'line-through',
        className,
      )}
    >
      {diveSessionServiceFormatter.formatServiceFromParticipant(
        { diver: undefined, participant },
        {
          format: 'short-ref',
          clubReference,
          clubPublicSettings,
          diveModesConf: clubSettings?.ui?.diveMode,
        },
      )}
      {isMultiSession && (
        <span className="ml-0.5 text-xs">(x{participant.divesCount})</span>
      )}
    </div>
  );
};
