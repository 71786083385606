import Tippy from '@tippyjs/react';
import { default as React } from 'react';
import { AppIconsUI } from '../../icons';

export function AppDocumentationLink({
  url,
  className,
}: {
  url: string;
  className?: string;
}) {
  return url ? (
    <a
      className={className}
      href={url}
      target="_mabadive_docs"
      rel="noreferrer"
    >
      <Tippy delay={[300, 100]} content={'Lien vers la documentation'}>
        <AppIconsUI.help className="w-full h-full p-1 bg-blue-200 text-gray-800 hover:text-black cursor-pointer" />
      </Tippy>
    </a>
  ) : null;
}
