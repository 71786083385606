/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { ClubDialogsState, ClubDialogsStateOld } from 'src/pages/_dialogs';
import { bookingUpdateStateBuilder } from '../club-diver-participant/pages/DiverBookingPage/bookingUpdateStateBuilder.service';
import { BookingParticipantViewDialog } from '../club-diver-participant/pages/DiverBookingPage/components/BookingParticipantDialog';
import { DiveSessionEditorDialog } from '../club-diver-participant/pages/DiverBookingPage/components/DiveSessionEditorDialog';
import { diverBookingPageClientUpdator } from '../club-diver-participant/pages/DiverBookingPage/services';
import { ClubEventDialog } from '../club-events';
import { PlanningParticipantsLocalState } from '../club-planning/ClubPlanningParticipantsPage/usePlanningParticipantsLocalState.hook';

export const ClubPlanningLightPageDialogsProvider = ({
  dialogsState,
  localState,
  dialogs,
  children,
  className,
}: PropsWithChildren<{
  dialogsState: ClubDialogsState;
  localState: PlanningParticipantsLocalState;
  dialogs: ClubDialogsStateOld;
  className?: string;
}>) => {
  const {
    isAnyFakeDialogOpen,
    sessionEditorDialogState,
    setSessionEditorDialogState,
    clubEventDialogState,
    setClubEventDialogState,
    bookingParticipantViewState,
    setBookingParticipantViewState,
  } = dialogs;

  const { planningConfig, setPlanningConfig } = localState;

  return (
    <>
      {/* DIALOGS */}

      {bookingParticipantViewState.isOpen && (
        <BookingParticipantViewDialog
          dialogsState={dialogsState}
          state={bookingParticipantViewState}
          setState={setBookingParticipantViewState}
        />
      )}
      {sessionEditorDialogState.isOpen && (
        <DiveSessionEditorDialog
          planningConfig={planningConfig}
          setPlanningConfig={setPlanningConfig}
          state={sessionEditorDialogState}
          setState={setSessionEditorDialogState}
        />
      )}
      {clubEventDialogState.isOpen && (
        <ClubEventDialog
          key={clubEventDialogState.mode}
          planningConfig={planningConfig}
          state={clubEventDialogState}
          setState={setClubEventDialogState}
          onConfirm={async (updateState) => {
            const newState = bookingUpdateStateBuilder.createEmptyUpdateState({
              ...updateState,
              hasChanges: true,
            });

            await diverBookingPageClientUpdator.persistChanges(newState, {
              logContext: 'edit event',
              bookingIdsToClean: [],
            });
            setClubEventDialogState({
              isOpen: false,
            });
          }}
        />
      )}
      <div className={clsx(isAnyFakeDialogOpen ? 'hidden' : '', className)}>
        {children}
      </div>
    </>
  );
};
