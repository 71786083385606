/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ClubSettingsServicesPageBasePanel } from '../../../_common';
import { ClubSettingsServicesViewPanelOffersPrices } from '../../../_common/offers-prices/view';
import { ClubSettingsServicesPageLocalState } from '../../../useClubSettingsServicesPageLocalState.hook';
import { ClubSettingsServicesExplosViewPanel_Settings } from './ClubSettingsServicesExplosViewPanel_Settings';

export const ClubSettingsServicesExplosViewPanel = ({
  localState,
}: {
  localState: ClubSettingsServicesPageLocalState;
}) => {
  return (
    <ClubSettingsServicesPageBasePanel localState={localState}>
      <div className="grid gap-4 md:gap-6">
        <ClubSettingsServicesExplosViewPanel_Settings localState={localState} />
        <ClubSettingsServicesViewPanelOffersPrices
          localState={localState}
          contextMode="explo"
        />
      </div>
    </ClubSettingsServicesPageBasePanel>
  );
};
