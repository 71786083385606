import { dateService } from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  BookingTabModel,
  BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk,
} from '../../../../models';
import { BookingTabSingleBookingsAutoFixBookingOccasionalDiverSplitterOptions } from '../model';
import { bookingChunkSplitter } from './bookingChunkSplitter.service';

export const bookingTabSingleBookingsAutoFixBookingOccasionalDiverSplitter = {
  splitByOccasionalDiver,
};

function splitByOccasionalDiver({
  tabModel,
  chunks,
  options,
}: {
  tabModel: BookingTabModel;
  chunks: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk[];
  options: BookingTabSingleBookingsAutoFixBookingOccasionalDiverSplitterOptions;
}): BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk[] {
  const { bookingMembersFull } = tabModel.aggregatedBooking;

  chunks = chunks.reduce((acc, chunk) => {
    if (
      chunk.participantsBySessions.length >
      2 * options.minChunkSessionsSize
    ) {
      const splitAsc = getFirstSplit({
        chunk,
        options,
        order: 'ASC',
      });
      const splitDesc = getFirstSplit({
        chunk,
        options,
        order: 'DESC',
      });

      const split =
        splitAsc.splitIndex > splitDesc.splitIndex ? splitAsc : splitDesc;
      // const split = splitAsc;

      if (split.splitIndex !== -1) {
        const {
          chunk1,
          chunk2,
        }: {
          chunk1: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
          chunk2: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
        } = bookingChunkSplitter.splitChunkBySessionIndex({
          chunk,
          chunk2FirstSessionIndex: split.splitIndex,
          bookingMembersFull,
          comment: split.comment,
        });
        if (chunk1 && chunk2) {
          acc.push(chunk1);
          acc.push(chunk2);
        } else {
          appLogger.error('Invalid chunks, split:', split);
          acc.push(chunk); // keep original chunk
        }
        // STOP current chunk process
        return acc;
      }
    }
    // don't split (preserve original chunk)
    acc.push(chunk);
    return acc;
  }, [] as BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk[]);

  return chunks;
}

function getFirstSplit({
  chunk,
  options,
  order,
}: {
  chunk: BookingTabSingleBookingAutoFixSuggestionsSplitBookingChunk;
  options: BookingTabSingleBookingsAutoFixBookingOccasionalDiverSplitterOptions;
  order: 'ASC' | 'DESC';
}): { splitIndex: number; comment: string } {
  // on recherche si il y a au moins "maxChunkSessionsSize" au début, au milieu ou à la fin sans qu'un plongeur participe à une plongée
  const cache: {
    [diverId: string]: {
      lastSessionIndex?: number;
    };
  } = {};

  const participantsBySessions =
    order === 'ASC'
      ? chunk.participantsBySessions
      : chunk.participantsBySessions.slice().reverse();
  const sessionsCount = participantsBySessions.length;
  // on recherche d'abord les plongeurs occasionnels, qui ne plongeaient pas au début de la réservation
  return participantsBySessions.reduce(
    (
      { splitIndex, comment },
      { bookingSessionFull, participants },
      sessionIndex,
    ) => {
      if (splitIndex !== -1) {
        return { splitIndex, comment };
      }
      return participants.reduce(
        (
          { splitIndex, comment },
          { bookingMemberFull, bookingParticipantFull },
        ) => {
          if (bookingParticipantFull) {
            // le plongeur participe à cette session
            const lastMemberSessionIndex =
              cache[bookingMemberFull.diver._id]?.lastSessionIndex ?? -1;
            const noParticipationCount =
              sessionIndex - lastMemberSessionIndex - 1;
            if (noParticipationCount > options.maxChunkSessionsSize) {
              // le plongeur re-participe après une longue interruption, c'est le bon moment pour spliter
              if (
                isMinChunkSizeOk({
                  sessionSplitIndex: sessionIndex,
                  options,
                  sessionsCount,
                }) &&
                dateService.isPastUTC(
                  // don't split future sessions
                  participantsBySessions[sessionIndex - 1].bookingSessionFull
                    .diveSession.time,
                )
              ) {
                // la taille mini est OK des 2 côtés
                for (let i = sessionIndex - 1; i > 0; i--) {
                  if (
                    !isMinChunkSizeOk({
                      sessionSplitIndex: i + 1,
                      options,
                      sessionsCount,
                    })
                  ) {
                    // taille de début trop petite: on abandonne
                    break;
                  }
                  const session = participantsBySessions[i];
                  if (
                    !dateService.isSameDayUTC(
                      session.bookingSessionFull.diveSession.time,
                      bookingSessionFull.diveSession.time,
                    )
                    // don't split between sessions that are in same day
                  ) {
                    // split point !
                    const splitIndexFound = i + 1;
                    if (order === 'ASC') {
                      return {
                        splitIndex: splitIndexFound,
                        comment: `plongeur absent pendant ${noParticipationCount} plongées`,
                      };
                    } else {
                      return {
                        splitIndex: sessionsCount - splitIndexFound,
                        comment: `plongeur absent depuis ${noParticipationCount} plongées`,
                      };
                    }
                  }
                }
              }
            }
            // continue
            cache[bookingMemberFull.diver._id] = {
              lastSessionIndex: sessionIndex,
            };
          } else {
            // le plongeur ne participe pas
          }
          return { splitIndex, comment };
        },
        { splitIndex, comment },
      );
    },
    { splitIndex: -1, comment: '' },
  );
}

function isMinChunkSizeOk({
  sessionSplitIndex,
  options,
  sessionsCount,
}: {
  sessionSplitIndex: number;
  options: any;
  sessionsCount: number;
}) {
  return (
    sessionSplitIndex + 1 >= options.minChunkSessionsSize &&
    sessionsCount - (sessionSplitIndex + 1) >= options.minChunkSessionsSize
  );
}
