import { AppUiTheme } from '@mabadive/app-common-model';
import React from 'react';
import { RenderElementProps } from 'slate-react';
import { AppRichTextH1, AppRichTextH2, AppRichTextParagraph } from './elements';

export function AppRichTextSlateElement<A>({
  attributes = {} as any,
  children,
  element,
  theme,
}: Omit<RenderElementProps, 'attributes'> & {
  attributes?: A;
  theme: AppUiTheme;
}) {
  switch (element.type) {
    // case 'block-quote':
    //   return (
    //     <blockquote className={`text-gray-600 ${className}`} {...attributes}>
    //       {children}
    //     </blockquote>
    //   );
    // case 'bulleted-list':
    //   return (
    //     <ul className={`text-gray-600 ${className}`} {...attributes}>
    //       {children}
    //     </ul>
    //   );
    case 'heading-one':
      return (
        <AppRichTextH1
          attributes={attributes}
          textAlign={element?.textAlign}
          theme={theme}
        >
          {children}
        </AppRichTextH1>
      );
    case 'heading-two':
      return (
        <AppRichTextH2
          attributes={attributes}
          textAlign={element?.textAlign}
          theme={theme}
        >
          {children}
        </AppRichTextH2>
      );
    // case 'list-item':
    //   return (
    //     <li className={`text-gray-600 ${className}`} {...attributes}>
    //       {children}
    //     </li>
    //   );
    // case 'numbered-list':
    //   return (
    //     <ol className={`text-gray-600 ${className}`} {...attributes}>
    //       {children}
    //     </ol>
    //   );
    default:
      return (
        <AppRichTextParagraph
          attributes={attributes}
          textAlign={element?.textAlign}
          theme={theme}
        >
          {children}
        </AppRichTextParagraph>
      );
  }
}
