import { Transition } from '@headlessui/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { useCacheableClasses } from '../../../root/theme';
import { AppIconsMaterial } from '../../icons';

export type AppExpansionPanelState = {
  panelId: string;
  isExpanded: boolean;
};

export type AppExpansionPanelProps = {
  panelId: string;
  isExpandable?: boolean;
  isExpanded: boolean;
  renderSummaryTitle: (state?: AppExpansionPanelState) => JSX.Element;
  renderAlwaysFooter?: (state?: AppExpansionPanelState) => JSX.Element;
  renderSummaryExtra: (state?: AppExpansionPanelState) => JSX.Element;
  renderDetails: (state?: AppExpansionPanelState) => JSX.Element;
  expandPanel: (
    panelId: string,
  ) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  className?: string;
};

export function AppExpansionPanel({
  panelId,
  isExpanded,
  renderSummaryTitle,
  renderSummaryExtra,
  renderDetails,
  renderAlwaysFooter,
  expandPanel,
  isExpandable = true,
  className = '',
}: AppExpansionPanelProps) {
  const classes = useCacheableClasses({
    cacheKey: 'AppExpansionPanel',
    buildStyles,
  });

  return (
    <div className={`mb-1 ${className}`}>
      {!isExpandable ? (
        <>
          <div className="my-2 text-base font-bold text-gray-600 uppercase">
            {renderSummaryTitle({ panelId, isExpanded })}
          </div>
          {renderSummaryExtra({ panelId, isExpanded })}
          {renderAlwaysFooter && (
            <div className="w-full my-2 text-sm text-gray-600">
              {renderAlwaysFooter({ panelId, isExpanded })}
            </div>
          )}
        </>
      ) : (
        <Accordion
          className={classes.root}
          expanded={isExpanded}
          onChange={expandPanel(panelId)}
        >
          <AccordionSummary expandIcon={<AppIconsMaterial.expandMore />}>
            <div className="w-full flex flex-col">
              <div className="my-2 text-base font-bold text-gray-600 uppercase">
                {renderSummaryTitle({ panelId, isExpanded })}
              </div>
              <Transition
                show={!isExpanded}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <div className="w-full">
                  <div className="w-full text-sm text-gray-500">
                    {renderSummaryExtra({ panelId, isExpanded })}
                  </div>
                  {renderAlwaysFooter && (
                    <div className="w-full my-2 text-sm text-gray-600">
                      {renderAlwaysFooter({ panelId, isExpanded })}
                    </div>
                  )}
                </div>
              </Transition>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="w-full">
              {renderDetails({ panelId, isExpanded })}
              {renderAlwaysFooter && (
                <div className="w-full my-2 text-sm text-gray-600">
                  {renderAlwaysFooter({ panelId, isExpanded })}
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}
function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      '& .MuiExpansionPanelSummary-root': {
        minHeight: '30px',
        '&.Mui-expanded': {
          minHeight: '30px',
        },
      },
      '& .MuiExpansionPanelSummary-content': {
        marginTop: '20px',
        marginBottom: '5px',
        '&.Mui-expanded': {
          marginTop: '20px',
          marginBottom: '5px',
        },
      },
      '& .Mui-focusVisible': {
        background: 'transparent',
      },
      '& .expand-icon': {
        verticalAlign: 'bottom',
        color: 'grey',
        '&:hover': {
          color: '#333',
        },
        '&:expand-icon-left': {
          marginLeft: '8px',
        },
      },
    },
  });
}
