import { ClubPurchasePackageExtraCost } from '@mabadive/app-common-model';
import React, { useCallback, useMemo } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { diverPurchasePackageBuilder } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/DiverPurchaseCommonEditorDialog';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { AppPackageExtraCostFormEditExtraCost } from './AppPackageExtraCostFormEditExtraCost';
import { AppPackageExtraCostFormListExtraCostItem } from './AppPackageExtraCostFormListExtraCostItem';
import { AppPackageExtraCostCreateButton } from './components';
import {
  AppPackageExtraCostFormDialogDisplayState,
  AppPackageExtraCostFormModel,
} from './model';

export type AppPackageExtraCostFormPanelContext =
  | 'training'
  | 'plan'
  | 'other-dive'
  | 'standalone';

export type AppPackageExtraCostFormPanelProps = {
  form: UseFormReturn<AppPackageExtraCostFormModel, any>;
  context: AppPackageExtraCostFormPanelContext;
  extraCostUnitDiveDefaultMultiplier: number;
  className?: string;
};

export const AppPackageExtraCostFormPanel = ({
  form,
  context,
  extraCostUnitDiveDefaultMultiplier,
  displayState,
  setDisplayState,
  className,
}: AppPackageExtraCostFormPanelProps & {
  displayState: AppPackageExtraCostFormDialogDisplayState;
  setDisplayState: (state: AppPackageExtraCostFormDialogDisplayState) => any;
}) => {
  const { control, register, handleSubmit, reset, watch, trigger, formState } =
    form;

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'extraCosts',
    },
  );

  const mainCurrency = useAppCurrencyMain();

  const clubResume = useClubResume();

  const extraProductPackageOffers = useMemo(
    () =>
      clubResume.productPackageOffers.filter((x) => {
        const salesCriteria = x.productPackage.salesCriteria;
        if (x.productPackage.type === 'product') {
          switch (context) {
            case 'standalone': {
              return salesCriteria?.standalone;
            }
            case 'plan': {
              return salesCriteria?.extraCostDivePlan;
            }
            case 'training': {
              return salesCriteria?.extraCostTraining;
            }
            case 'other-dive': {
              return salesCriteria.extraCostFirstDive;
            }
          }
        }
        return false;
      }),
    [clubResume.productPackageOffers, context],
  );

  const totalPriceExtraCosts = useMemo(
    () => diverPurchasePackageBuilder.buildTotalExtraCostsSum(fields),
    [fields],
  );

  const standaloneProductPackageOffers = useMemo(
    () =>
      clubResume.productPackageOffers.filter((x) => {
        const salesCriteria = x.productPackage.salesCriteria;
        if (x.productPackage.type === 'product') {
          switch (context) {
            case 'standalone': {
              return false;
            }
            case 'plan': {
              return (
                salesCriteria?.standalone && !salesCriteria?.extraCostDivePlan
              );
            }
            case 'training': {
              return (
                salesCriteria?.standalone && !salesCriteria?.extraCostTraining
              );
            }
            case 'other-dive': {
              return (
                salesCriteria?.standalone && !salesCriteria.extraCostFirstDive
              );
            }
          }
        }
        return false;
      }),
    [clubResume.productPackageOffers, context],
  );

  const createExtraCostOffer = useCallback(
    (extraCost: ClubPurchasePackageExtraCost) => {
      append(extraCost, {
        shouldFocus: true,
      });
      setDisplayState({
        mode: 'edit-one',
        extraCostIndex: fields.length,
      });
    },
    [append, fields.length, setDisplayState],
  );

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      <div className={'flex flex-col gap-4'}>
        <h2 className="text-base sm:text-lg font-bold text-gray-600 uppercase text-center">
          Suppléments
        </h2>
        {displayState.mode === 'view-list' && (
          <>
            {fields.length === 0 ? (
              <div>
                <h3 className="font-bold text-gray-400 text-sm sm:text-base uppercase">
                  Ajouter un supplément:
                </h3>
              </div>
            ) : (
              <div className="flex flex-col gap-1">
                {fields.map((field, extraCostIndex) => {
                  return (
                    <AppPackageExtraCostFormListExtraCostItem
                      className=""
                      key={extraCostIndex}
                      form={form}
                      extraCostIndex={extraCostIndex}
                      onClick={() => {
                        setDisplayState({
                          mode: 'edit-one',
                          extraCostIndex,
                        });
                      }}
                    />
                  );
                })}
                <div
                  className={
                    'flex gap-2 justify-end items-start text-xs sm:text-base '
                  }
                >
                  <div>
                    <h3 className="font-bold text-app-primary text-sm sm:text-base uppercase">
                      TOTAL
                    </h3>
                  </div>
                  <AppPriceLabel
                    className="font-bold text-gray-600"
                    amount={totalPriceExtraCosts}
                    mainCurrency={mainCurrency}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {displayState.mode === 'edit-one' && (
          <div className="flex flex-col gap-4">
            {fields.map((field, extraCostIndex) => {
              return extraCostIndex === displayState.extraCostIndex ? (
                <AppPackageExtraCostFormEditExtraCost
                  key={extraCostIndex}
                  form={form}
                  extraCostIndex={extraCostIndex}
                  onClickDelete={async () => {
                    remove(extraCostIndex);
                    setDisplayState({
                      mode: 'view-list',
                    });
                  }}
                />
              ) : null;
            })}
          </div>
        )}
      </div>
      {displayState.mode === 'view-list' && (
        <>
          {extraProductPackageOffers.length > 0 && (
            <div className="flex flex-wrap justify-start gap-4 mt-4">
              {extraProductPackageOffers.map((o, i) => (
                <AppPackageExtraCostCreateButton
                  key={i}
                  offer={o}
                  onClick={createExtraCostOffer}
                  extraCostUnitDiveDefaultMultiplier={
                    extraCostUnitDiveDefaultMultiplier
                  }
                  type={
                    o.productPackage.salesCriteria.favorite
                      ? 'main'
                      : 'secondary'
                  }
                />
              ))}
            </div>
          )}
          <div className="flex flex-wrap justify-start gap-4 mt-4">
            <AppButton
              className={'w-full sm:w-auto text-xs font-bold uppercase'}
              style={{ minWidth: '6rem' }}
              color={'gray-outline-light'}
              icon={AppHeroIcons.actionAdd}
              tabIndex={500}
              onClick={() => {
                const extraCost: ClubPurchasePackageExtraCost = {
                  extraCostUnitDive: false,
                  label: 'Supplément',
                  unitPrice: undefined,
                  unitQuantity: 1,
                  unitTotalPrice: undefined,
                  multiplier: 1,
                  totalPrice: undefined,
                };

                createExtraCostOffer(extraCost);
              }}
            >
              supplément global
            </AppButton>
            {context !== 'standalone' && (
              <>
                <AppButton
                  className={'w-full sm:w-auto text-xs font-bold uppercase'}
                  style={{ minWidth: '6rem' }}
                  color={'gray-outline-light'}
                  icon={AppHeroIcons.actionAdd}
                  tabIndex={500}
                  onClick={() => {
                    const extraCost: ClubPurchasePackageExtraCost = {
                      extraCostUnitDive: true,
                      label: 'Supplément par plongée',
                      unitPrice: undefined,
                      unitQuantity: 1,
                      unitTotalPrice: undefined,
                      multiplier: extraCostUnitDiveDefaultMultiplier,
                      totalPrice: undefined,
                    };

                    createExtraCostOffer(extraCost);
                  }}
                >
                  supplément par plongée
                </AppButton>
              </>
            )}
          </div>
          {context !== 'standalone' &&
            standaloneProductPackageOffers.length > 0 && (
              <div className="flex flex-wrap justify-start gap-4 mt-4">
                {standaloneProductPackageOffers.map((o, i) => (
                  <AppPackageExtraCostCreateButton
                    key={i}
                    offer={o}
                    onClick={createExtraCostOffer}
                    extraCostUnitDiveDefaultMultiplier={
                      extraCostUnitDiveDefaultMultiplier
                    }
                    type={'secondary'}
                  />
                ))}
              </div>
            )}
        </>
      )}
    </div>
  );
};
