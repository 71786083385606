/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BookingParticipantEditorFormModel,
  ClubParticipantAutoSupervisedDiveMode,
  ClubParticipantSpecialDiveType,
  DiveAptitude,
  DiveMode,
  PARTICIPANT_SPECIAL_DIVE_TYPES,
} from '@mabadive/app-common-model';
import { participantSpecialDiveTypeFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppFormControlRHF_Deprecated } from 'src/lib/form/components/AppFormControl/AppFormControlRHF_Deprecated';
import { AppMultiCheckboxesTailwindRHF } from 'src/lib/form/components/AppMultiCheckboxes/AppMultiCheckboxesTailwindRHF';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import {
  useDiveAptitudeOptions,
  useMultipleDiveSessionNumbersOptions,
} from 'src/pages/_components/options';
import { BookingParticipantAutoSupervisedForm } from './BookingParticipantAutoSupervisedForm';
import { BookingParticipantDiveModeSpecificReference } from './BookingParticipantDiveModeSpecificReference';

const PARTICIPANT_SPECIAL_DIVE_TYPES_OPTIONS: ValueLabel<ClubParticipantSpecialDiveType>[] =
  PARTICIPANT_SPECIAL_DIVE_TYPES.map((status) => ({
    value: status,
    label: participantSpecialDiveTypeFormatter.formatSpecialDiveType(status, {
      format: 'long',
    }),
  }));

export const BookingParticipantEditFormRHFGroup1 = ({
  form,
  onDiveModeChange,
  onAutoSupervisedDiveModeChange,
  maxWidthMd,
  className = '',
}: {
  form: UseFormReturn<BookingParticipantEditorFormModel>;
  onDiveModeChange: (diveMode: DiveMode) => void;
  onAutoSupervisedDiveModeChange?: (
    diveMode: ClubParticipantAutoSupervisedDiveMode,
  ) => void;
  maxWidthMd: boolean;
  className?: string;
}) => {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;
  const services = clubResume.clubSettings.services;

  const { control, setValue } = form;

  const [diveMode, sessionsCount] = useWatch({
    control,
    name: ['clubParticipant.diveMode', 'diveSession.sessionsCount'],
  });

  const multipleDiveSessionNumbersOptions =
    useMultipleDiveSessionNumbersOptions({
      diveSession: { sessionsCount },
    });

  const diveAtitudesOptions: ValueLabel<DiveAptitude>[] =
    useDiveAptitudeOptions({
      effectiveDate: new Date(),
    });
  const isMultiSessionConfigForParticipants = sessionsCount > 1;
  return (
    <div
      className={clsx(
        'w-full flex flex-col sm:grid sm:grid-cols-4 gap-4',
        !maxWidthMd && 'lg:grid-cols-8',
        className,
      )}
    >
      <BookingParticipantDiveModeSpecificReference
        className={clsx('sm:col-span-2')}
        form={form}
        mode="edit"
        context={'clubParticipant'}
        onDiveModeChange={onDiveModeChange}
      />

      <BookingParticipantAutoSupervisedForm
        classNameEveryField={clsx('sm:col-span-2')}
        form={form}
        context={'clubParticipant'}
        onAutoSupervisedDiveModeChange={onAutoSupervisedDiveModeChange}
      />
      {diveMode &&
        ['autonomous', 'supervised', 'instructor'].includes(diveMode) && (
          <AppFormControlRHF_Deprecated
            className={clsx('sm:col-span-2 w-full')}
            label="Plongée spéciale"
            control={control}
            name={'clubParticipant.details.specialDiveType'}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={PARTICIPANT_SPECIAL_DIVE_TYPES_OPTIONS}
              />
            )}
          />
        )}
      {diveMode &&
        [
          'autonomous',
          'supervised',
          'training',
          'instructor',
          'autoSupervised',
        ].includes(diveMode) && (
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label="Aptitude"
            control={control}
            name={'clubParticipant.aptitude'}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={diveAtitudesOptions}
              />
            )}
          />
        )}
      {isMultiSessionConfigForParticipants && (
        <AppFormControlRHF_Deprecated
          className={'w-full'}
          label="Double-blocs"
          control={control}
          name={'clubParticipant.details.multiSessionsPresenceNumbers'}
          renderComponent={(props) => (
            <div className="my-2">
              <AppMultiCheckboxesTailwindRHF
                {...props}
                labelPosition="right"
                required={true}
                options={multipleDiveSessionNumbersOptions}
                // defaultValue={participant.details?.multiSessionsPresenceNumbers ?? []}
                // onChange={(numbers: MultipleDiveSessionNumber[]) => {
                //   onUpdateParticipantMultiSessionsPresenceNumbers({
                //     numbers,
                //   });
                // }}
              />
            </div>
          )}
        />
      )}
    </div>
  );
};
