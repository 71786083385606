import clsx from 'clsx';
import React, { useMemo } from 'react';

import {
  FIRST_DIVES_REFERENCE_MAP,
  ProStatsFetchResults,
} from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import {
  DiveModeIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import { AppPriceLabel } from 'src/business/club/modules/_common/ui';
import { DashboardReportComparisonDetails } from 'src/pages/DA-dashboard/DA-02-dashboard-report/DashboardReportComparison';
import { useAppCurrencyMain } from 'src/pages/_components/options';
import { DashboardReportPageFirstDiveParticipantsComparisonState } from './DashboardReportPageFirstDiveParticipants';
import { DashboardReportPageFirstDiveParticipantsTableRow } from './DashboardReportPageFirstDiveParticipantsTableRow';

export const DashboardReportPageFirstDiveParticipantsTable = ({
  firstDiveParticipantsOverPeriod: results,
  comparisonDetails,
  className,
}: {
  firstDiveParticipantsOverPeriod: ProStatsFetchResults['firstDiveParticipantsOverPeriod'];
  comparisonDetails: DashboardReportComparisonDetails<DashboardReportPageFirstDiveParticipantsComparisonState>;
  className?: string;
}) => {
  const data = results?.data;

  const {
    localState,
    comparisonFetchResult,
    showPeriodRangeComparison,
    // sharedState: [sharedState, setSharedState],
  } = comparisonDetails;

  const comparisonData =
    comparisonFetchResult?.firstDiveParticipantsOverPeriod?.data;

  const FirstDivesDetails: ProStatsFetchResults['firstDiveParticipantsOverPeriod']['data']['byFirstDiveReference'] =
    useMemo(() => {
      const baseFirstDives = data?.byFirstDiveReference ?? [];
      const comparisonFirstDives = comparisonData?.byFirstDiveReference ?? [];

      const missingFirstDiveReferences = comparisonFirstDives
        .map((itemB) => itemB.firstDiveReference)
        .filter(
          (refB) =>
            !baseFirstDives.some((itemA) => itemA.firstDiveReference === refB),
        );

      const updatedFirstDives: ProStatsFetchResults['firstDiveParticipantsOverPeriod']['data']['byFirstDiveReference'] =
        [
          ...baseFirstDives,
          ...missingFirstDiveReferences.map((missingRef) => {
            const x = {
              firstDiveReference: missingRef,
              diversCount: 0,
              participantsCount: 0,
              participantsCountPurchased: 0,
              priceAvg: 0,
            };
            return x;
          }),
        ];

      return dataSorter.sortMultiple(updatedFirstDives, {
        getSortAttributes: (firstDive) => {
          // const FirstDiveIndex = DIVE_FIRSTDIVES.map(x=>x.reference).indexOf(firstDive.firstDiveReference)
          return [
            {
              value:
                FIRST_DIVES_REFERENCE_MAP[firstDive.firstDiveReference]
                  ?.sortReference,
            },
            {
              value: firstDive.firstDiveReference,
            },
          ];
        },
      });
    }, [comparisonData?.byFirstDiveReference, data?.byFirstDiveReference]);

  const mainCurrency = useAppCurrencyMain();

  return !FirstDivesDetails?.length ? null : (
    <table
      className={clsx('app-table text-gray-600 overflow-hidden', className)}
    >
      <thead>
        <tr className="uppercase overflow-hidden">
          <th className="text-left text-base px-1 leading-4">Baptême</th>
          <th className="text-left text-base px-1 leading-4">Pers</th>
          <th className="text-left text-base px-1 leading-4">
            Plongées imputées
          </th>
          <th
            className={clsx(
              'text-left text-base px-1 leading-4',
              showPeriodRangeComparison
                ? 'hidden xl:table-cell'
                : 'hidden lg:table-cell',
            )}
          >
            Plong Moy
          </th>{' '}
          <th className={clsx('text-left text-base px-1')}>Prix Moy</th>
        </tr>
      </thead>
      <tbody>
        {FirstDivesDetails.map((firstDive) => (
          <DashboardReportPageFirstDiveParticipantsTableRow
            key={firstDive.firstDiveReference}
            firstDive={firstDive}
            comparisonDetails={comparisonDetails}
          />
        ))}
      </tbody>
      <tbody>
        <tr className="text-left overflow-hidden">
          <th
            className={clsx(
              'text-base bg-gray-500 text-white text-left whitespace-nowrap px-1',
            )}
          >
            TOTAL
          </th>
          <td
            className={clsx(
              'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
              data.diversCount === 0 ? 'opacity-30' : '',
            )}
          >
            {data.diversCount}{' '}
            <EntitiesIcons.diver className={clsx('inline w-6 h-6')} />
          </td>
          <td
            className={clsx(
              'bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold',
              data.participantsCount === 0 ? 'opacity-30' : '',
              showPeriodRangeComparison
                ? 'hidden xl:table-cell'
                : 'hidden lg:table-cell',
            )}
          >
            <span
              className={clsx(
                data.participantsCountPurchased === data.participantsCount
                  ? 'font-bold'
                  : 'font-normal',
              )}
            >
              {data.participantsCountPurchased}
            </span>{' '}
            / {data.participantsCount}{' '}
            <DiveModeIcons.firstDive className={clsx('inline w-6 h-6')} />
          </td>
          <td className="bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold">
            {data.diversCount > 0 && (
              <>
                {Math.round((10 * data.participantsCount) / data.diversCount) /
                  10}
              </>
            )}
          </td>
          <td className="bg-gray-500 text-white text-right text-lg px-4 whitespace-nowrap font-bold">
            <AppPriceLabel
              amount={data.priceAvg}
              mainCurrency={mainCurrency}
              signColorClassName="text-white"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
