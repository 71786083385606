import { BookingMember, BookingSession } from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  diverBookingMemberCreator,
  jsonPatcher,
} from '@mabadive/app-common-services';
import {
  BookingTabModel,
  DiverBookingPageAggregatedDataCore,
  DiverBookingPageUpdateState,
  DiverBookingUpdateProductParticipant,
} from '../../../models';
import { diverBookingPageUpdateStateManager } from '../../02.update-state';
import {
  diverBookingPageSessionCreator,
  diverBookingParticipantCreator,
} from '../entity-creators';

export const bookingPageUpdateStateBookingMerger = { mergeBookings };

function mergeBookings({
  bookingSources,
  bookingTarget,
  clubReference,
  aggregatedData,
  updateState,
}: {
  bookingSources: BookingTabModel[];
  bookingTarget: BookingTabModel;
  clubReference: string;
  aggregatedData: DiverBookingPageAggregatedDataCore;
  updateState: DiverBookingPageUpdateState;
}): DiverBookingPageUpdateState {
  let updateStateLocal = updateState;

  let mergeBookingTarget: MergeBookingTarget = {
    bookingId: bookingTarget.bookingId,
    diveCenterId: bookingTarget.bookingDiveCenterId,
    bookingMembers: bookingTarget.aggregatedBooking.bookingMembers,
    bookingSessions: bookingTarget.aggregatedBooking.bookingSessions,
    bookingProducts:
      bookingTarget.aggregatedBooking.bookingParticipantsFull.map((m) => ({
        diveSessionReference: m.bookingSession.diveSessionReference,
        diverId: m.bookingMember.diverId,
      })),
  };

  for (const bookingSource of bookingSources) {
    const mergeResult = mergeBooking({
      bookingSource,
      mergeBookingTarget,
      clubReference,
      aggregatedData,
      updateState: updateStateLocal,
    });

    updateStateLocal = mergeResult.updateState;

    mergeBookingTarget = {
      ...mergeBookingTarget,
      bookingMembers: mergeBookingTarget.bookingMembers.concat(
        mergeResult.newBookingMembers,
      ),
      bookingSessions: mergeBookingTarget.bookingSessions.concat(
        mergeResult.newBookingSessions,
      ),
      bookingProducts: mergeBookingTarget.bookingProducts.concat(
        mergeResult.newBookingProductParticipants.map((m) => ({
          diveSessionReference: m.clubParticipant.diveSessionReference,
          diverId: m.clubParticipant.diverId,
        })),
      ),
    };
  }

  updateStateLocal = updateBookingTarget({
    updateStateLocal,
    bookingSources,
    bookingTarget,
  });

  return updateStateLocal;
}

type MergeBookingTarget = {
  bookingId: string;
  diveCenterId: string;
  bookingMembers: BookingMember[];
  bookingSessions: BookingSession[];
  bookingProducts: {
    diveSessionReference: string;
    diverId: string;
  }[];
};

function mergeBooking({
  bookingSource,
  mergeBookingTarget,
  clubReference,
  aggregatedData,
  updateState,
}: {
  bookingSource: BookingTabModel;
  mergeBookingTarget: MergeBookingTarget;
  clubReference: string;
  aggregatedData: DiverBookingPageAggregatedDataCore;
  updateState: DiverBookingPageUpdateState;
}): {
  updateState: DiverBookingPageUpdateState;
  newBookingMembers: BookingMember[];
  newBookingSessions: BookingSession[];
  newBookingProductParticipants: DiverBookingUpdateProductParticipant[];
} {
  const newBookingMembers: BookingMember[] = [];
  const newBookingSessions: BookingSession[] = [];
  const newBookingProductParticipants: DiverBookingUpdateProductParticipant[] =
    [];

  let updateStateLocal = updateState;

  const allTargetMembers: {
    diverId: string;
    memberId: string;
  }[] = mergeBookingTarget.bookingMembers.map((x) => ({
    diverId: x.diverId,
    memberId: x._id,
  }));
  const allTargetSessions: {
    diveSessionReference: string;
    bookingSessionId: string;
  }[] = mergeBookingTarget.bookingSessions.map((x) => ({
    diveSessionReference: x.diveSessionReference,
    bookingSessionId: x._id,
  }));

  // create missing members
  for (const sourceMember of bookingSource.aggregatedBooking.bookingMembers) {
    const diverId = sourceMember.diverId;
    const diver = aggregatedData.divers.find((d) => d._id === diverId);
    const targetMember = allTargetMembers.find((m) => m.diverId === diverId);
    if (!targetMember) {
      const newBookingMember =
        diverBookingMemberCreator.createBookingMemberFromSourceMember({
          sourceMember,
          bookingId: mergeBookingTarget.bookingId,
          diveCenterId: mergeBookingTarget.diveCenterId,
          clubReference,
        });
      newBookingMembers.push(newBookingMember);
      allTargetMembers.push({
        diverId: newBookingMember.diverId,
        memberId: newBookingMember._id,
      });
      updateStateLocal =
        diverBookingPageUpdateStateManager.addNewBookingMemberToState({
          updateState: updateStateLocal,
          newBookingMember,
          diver,
        });
    }
  }
  // create missing sessions
  for (const sourceSession of bookingSource.aggregatedBooking.bookingSessions) {
    const diveSessionReference = sourceSession.diveSessionReference;
    // const diveSession = aggregatedData.diveSessions.find(
    //   (d) => d.reference === diveSessionReference,
    // );
    const targetSession = allTargetSessions.find(
      (m) => m.diveSessionReference === diveSessionReference,
    );
    if (!targetSession) {
      const newBookingSession =
        diverBookingPageSessionCreator.createBookingSessionFromSourceSession({
          bookingId: mergeBookingTarget.bookingId,
          clubReference,
          sourceSession,
        });
      newBookingSessions.push(newBookingSession);
      allTargetSessions.push({
        diveSessionReference: newBookingSession.diveSessionReference,
        bookingSessionId: newBookingSession._id,
      });

      const bookingSessionsChanges = changeDescriptorManager.createOne(
        newBookingSession,
        {
          changeDescriptors: updateStateLocal.bookingSessionsChanges,
        },
      );
      updateStateLocal = {
        ...updateStateLocal,
        bookingSessionsChanges,
      };
    }
  }

  // create missing products
  for (const sourceParticipantFullProduct of bookingSource.aggregatedBooking
    .bookingParticipantsFull) {
    const diverId = sourceParticipantFullProduct.bookingMember.diverId;

    const diveSessionReference =
      sourceParticipantFullProduct.bookingSession.diveSessionReference;
    // const diveSessionResume =
    //   bookingSource.aggregatedBooking.diveSessionResumes.find(
    //     (d) => d.reference === diveSessionReference,
    //   );
    const targetProduct = mergeBookingTarget.bookingProducts.find(
      (m) =>
        m.diveSessionReference === diveSessionReference &&
        m.diverId === diverId,
    );
    if (!targetProduct) {
      const targetMember = allTargetMembers.find((m) => m.diverId === diverId);
      const targetSession = allTargetSessions.find(
        (m) => m.diveSessionReference === diveSessionReference,
      );
      const newBookingProductParticipant: DiverBookingUpdateProductParticipant =
        diverBookingParticipantCreator.createParticipantFromSourceProduct({
          participantProductFull: sourceParticipantFullProduct,
          bookingId: mergeBookingTarget.bookingId,
          bookingMemberId: targetMember.memberId,
          bookingSessionId: targetSession.bookingSessionId,
        });
      newBookingProductParticipants.push(newBookingProductParticipant);
      updateStateLocal =
        diverBookingPageUpdateStateManager.addNewParticipantToState({
          updateState: updateStateLocal,
          newBookingProductParticipant,
        });
    }
  }

  // update payments bookingId
  const purchasePaymentsToMove = aggregatedData.purchasePayments.filter(
    (x) => x.bookingId === bookingSource.bookingId,
  );
  // mettre à jour chaque paiement
  for (const payment of purchasePaymentsToMove) {
    const patchOperations = jsonPatcher.compareObjects(
      {
        bookingId: payment.bookingId,
      },
      {
        bookingId: mergeBookingTarget.bookingId,
      },
      {},
    );
    if (patchOperations.length) {
      const purchasePaymentsChanges = changeDescriptorManager.updateOne(
        {
          pk: payment._id,
          patchOperations,
        },
        {
          changeDescriptors: updateStateLocal.purchasePaymentsChanges,
        },
      );
      updateStateLocal = {
        ...updateStateLocal,
        purchasePaymentsChanges,
      };
    }
  }

  // delete original booking
  updateStateLocal = diverBookingPageUpdateStateManager.deleteBookingFromState({
    updateState: updateStateLocal,
    bookingTabModel: bookingSource,
  });

  return {
    updateState: updateStateLocal,
    newBookingMembers,
    newBookingSessions,
    newBookingProductParticipants,
  };
}

function updateBookingTarget({
  updateStateLocal,
  bookingSources,
  bookingTarget,
}: {
  updateStateLocal: DiverBookingPageUpdateState;
  bookingSources: BookingTabModel[];
  bookingTarget: BookingTabModel;
}): DiverBookingPageUpdateState {
  const anyBookingSourceActive =
    bookingSources.find((x) => x.aggregatedBooking.booking.active) !==
    undefined;

  // update active
  if (
    !bookingTarget.aggregatedBooking.booking.active &&
    anyBookingSourceActive
  ) {
    const patchOperations = jsonPatcher.compareObjects(
      {
        bookingId: bookingTarget.bookingId,
        active: bookingTarget.aggregatedBooking.booking.active,
      },
      {
        bookingId: bookingTarget.bookingId,
        active: anyBookingSourceActive,
      },
      {},
    );
    if (patchOperations.length) {
      const bookingsChanges = changeDescriptorManager.updateOne(
        {
          pk: bookingTarget.bookingId,
          patchOperations,
        },
        {
          changeDescriptors: updateStateLocal.bookingsChanges,
        },
      );
      updateStateLocal = {
        ...updateStateLocal,
        bookingsChanges,
      };
    }
  }

  return updateStateLocal;
}
