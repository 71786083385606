import { DiveSession } from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';

export const diveSessionTitleBuilder = {
  build: (
    diveSession: Pick<DiveSession, 'name' | 'theme' | 'type' | 'special'>,
  ): string => {
    if (diveSession.name?.trim()?.length) {
      return diveSession.name?.trim();
    }
    switch (diveSession.theme) {
      case 'night': {
        return 'Nuit'.toUpperCase();
      }
      case 'shark': {
        return 'requins'.toUpperCase();
      }
      case 'whale': {
        return 'baleines'.toUpperCase();
      }
      case 'turtle': {
        return 'tortues'.toUpperCase();
      }
      case 'dolphin': {
        return 'dauphins'.toUpperCase();
      }
    }
    switch (diveSession.type) {
      case 'first-dive': {
        return 'Baptêmes'.toUpperCase();
      }
      case 'snorkeling': {
        return diveModeFormatter
          .formatDiveMode('snorkeling', {
            format: 'short-label',
          })
          .toUpperCase();
      }
      case 'snorkeling-tour': {
        return diveModeFormatter
          .formatDiveMode('snorkelingSupervised', {
            format: 'short-label',
          })
          .toUpperCase();
      }
      case 'watching-tour': {
        return diveModeFormatter
          .formatDiveMode('watchingTour', {
            format: 'short-label',
          })
          .toUpperCase();
      }
      case 'dive-auto-supervised': {
        return diveModeFormatter
          .formatDiveMode('autoSupervised', {
            format: 'short-label',
          })
          .toUpperCase();
      }
      case 'theoretical-training': {
        return 'Théorie'.toUpperCase();
      }
    }
    if (diveSession.special) {
      return 'SPÉCIALE';
    }
  },
};
