/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export const AppFeatureBadge = ({
  children,
  extraBadgeContent,
  enabled,
  label,
  label2,
  icon,
  labelDisabled,
  hideIfDisabled,
  description,
  visibility,
  className = '',
}: {
  extraBadgeContent?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
  enabled: boolean;
  label: string;
  label2?: string;
  icon?: React.ReactNode;
  labelDisabled?: string;
  hideIfDisabled?: boolean;
  description?: string;
  visibility?: 'super-admin';
  className?: string;
}) => {
  return hideIfDisabled && !enabled ? null : (
    <div className={className}>
      <div className={'flex items-start'}>
        {enabled ? (
          <AppHeroIcons.check className="h-6 w-6 mr-2 text-gray-600" />
        ) : (
          <AppHeroIcons.disabled className="h-6 w-6 mr-2 text-gray-400" />
        )}
        <div>
          <div>
            <span className="inline-flex gap-2 items-center">
              <span className="flex items-center gap-1">
                {icon && (
                  <span className={enabled ? '' : 'grayscale opacity-50'}>
                    {icon}
                  </span>
                )}
                <span
                  className={`font-bold ${
                    enabled ? 'text-gray-600' : 'text-gray-400 grayscale'
                  }`}
                >
                  {enabled ? label : labelDisabled ?? label}
                </span>
                {enabled && label2 && (
                  <span className={'text-gray-600'}>{label2}</span>
                )}
              </span>
              {visibility === 'super-admin' && (
                <span className="font-bold text-purple-500">#</span>
              )}
              {extraBadgeContent && extraBadgeContent}
            </span>
            {children && children}
          </div>
          {description && (
            <div className="text-sm text-gray-500">{description}</div>
          )}
        </div>
      </div>
    </div>
  );
};
