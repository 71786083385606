import { MQueryDescription } from 'src/_common-browser';
import { BookingParticipantEditorFetcherCriteria } from '../../model';
import { bookingParticipantEditorAttributesBuilder } from './edition-booking/bookingParticipantEditorAttributesBuilder.service';

export function buildBookingParticipantEditorGraphqlQuery({
  clubReference,
  clubParticipantId,
}: BookingParticipantEditorFetcherCriteria) {
  const whereClauses: string[] = [];
  whereClauses.push(`clubReference: {_eq: "${clubReference}"}`);
  whereClauses.push(`participantId: {_eq: "${clubParticipantId}"}`);

  const where = `{${whereClauses.join(',')}}`;

  const returnAttributes =
    bookingParticipantEditorAttributesBuilder.buildAttributes();

  const queryDescription: MQueryDescription<any> = {
    // returnName: 'bookingGql1s',
    queryName: 'club_commerce_booking_session_participant',
    returnType: 'first',
    where,
    returnAttributes,
  };

  return queryDescription;
}
