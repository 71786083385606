/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiveSessionStaffMemberTableModelStaffMemberSession } from '@mabadive/app-common-model';
import { diveModeColorClassBuilder } from '@mabadive/app-common-services';
import React from 'react';
import { useClubResume, useClubSettings } from 'src/business/club/data/hooks';

export function DiveSessionStaffMembersTableSession({
  session,
  showRoles,
  className = '',
}: {
  session: DiveSessionStaffMemberTableModelStaffMemberSession;
  showRoles: boolean;
  className?: string;
}) {
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;
  const clubSettings = useClubSettings();

  return (
    <div className={`flex flex-wrap items-center gap-2 ${className}`}>
      {session.groups.map((group) => (
        <div
          className={'text-center px-2 py-px rounded text-white font-bold'}
          style={{
            backgroundColor: diveModeColorClassBuilder.getDiveModeColor(
              group.diveMode,
              { clubSettings },
            ),
          }}
          key={group._id}
        >
          x{group.participantsCount}
        </div>
      ))}
      {showRoles && session.isDivingDirector && (
        <div
          className={
            'text-center px-2 py-px rounded bg-white border border-gray-600 text-gray-600 uppercase font-bold'
          }
        >
          DP
        </div>
      )}
      {showRoles && session.isSurfaceSecurity && (
        <div
          className={
            'text-center px-2 py-px rounded bg-white border border-gray-600 text-gray-600 uppercase font-bold'
          }
        >
          Sécu
        </div>
      )}
      {session.groups.length === 0 &&
        !(showRoles && session.isDivingDirector) &&
        !(showRoles && session.isSurfaceSecurity) && (
          <div
            className={
              'text-center px-2 py-px  text-red-400 uppercase font-bold'
            }
          >
            ---
          </div>
        )}
    </div>
  );
}
