import {
  ColoredTag,
  DiveMode,
  DiveSessionStatus,
  ParticipantBookingStatus,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { ColoredTagAvatarCard } from 'src/business/_core/modules/layout';
import { useDiveModeColor } from '../../../../../../data/hooks';
import { CalendarSessionWidthMode } from './CalendarSessionWidthMode.type';
import { bookingStatusBorderStyleBuilder } from './bookingStatusBorderStyleBuilder.service';

export function CalendarParticipantCellDiveModeBadgeInner({
  isSessionOpen,
  diveTypeLabel,
  diveTypeTag,
  diveMode,
  bookingStatus,
  diveSessionStatus,
  extraBadgeLabel,
  clubReference,
  isPast,
  hoverable,
  widthMode,
  recentUpdate,
  children,
  className,
}: {
  isSessionOpen: boolean;
  diveTypeLabel: string;
  diveTypeTag?: ColoredTag;
  diveMode: DiveMode;
  bookingStatus: ParticipantBookingStatus;
  diveSessionStatus: DiveSessionStatus;
  extraBadgeLabel?: string;
  clubReference: string;
  isPast?: boolean;
  hoverable?: boolean;
  widthMode: CalendarSessionWidthMode;
  recentUpdate: boolean;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}) {
  if (isPast === undefined) {
    isPast = false;
  }

  const diveModeColor = useDiveModeColor(diveMode);

  return (
    <div
      className={clsx(
        'relative px-0.5 flex justify-around items-center text-center text-white font-bold',
        bookingStatusBorderStyleBuilder.buildStyle(bookingStatus, {
          widthMode,
        }),
        !isSessionOpen ? 'bg-gray-400' : '',
        diveSessionStatus === 'cancelled' &&
          'line-through decoration-2 decoration-red-600',
        diveSessionStatus === 'off' &&
          'line-through decoration-2 decoration-gray-600',
        diveSessionStatus === 'deleted' &&
          'line-through decoration-2 decoration-gray-600',
        hoverable && 'group-hover:bg-app-orange3 group-hover:text-white',
        recentUpdate && '  animate-flash-red',
        widthMode === 'tiny' || widthMode === 'small'
          ? 'py-0.5'
          : widthMode === 'medium'
          ? `min-w-[30px] lg:min-w-[35px] xl:min-w-[40px] ${
              diveTypeLabel?.length > 4
                ? 'text-[.6rem] lg:text-[.65rem] xl:text-[.7rem] 2xl:text-[.75rem]'
                : diveTypeLabel?.length > 3
                ? 'text-app-xxs lg:text-[.7rem] xl:text-[.75rem] 2xl:text-[.8rem]'
                : ''
            }`
          : 'min-w-[70px]', // widthMode === 'large'
        className,
      )}
      style={
        isSessionOpen
          ? {
              backgroundColor: diveModeColor,
            }
          : undefined
      }
    >
      <div className={widthMode === 'small' ? 'flex gap-2' : ''}>
        {diveTypeLabel && diveTypeLabel}
        {diveTypeTag && widthMode !== 'tiny' && (
          <ColoredTagAvatarCard
            className={`absolute ${
              widthMode === 'small'
                ? 'top-0 right-px'
                : widthMode === 'large'
                ? 'top-px right-px'
                : '-top-1 right-0'
            }`}
            tag={diveTypeTag}
            size={'small'}
          />
        )}

        {extraBadgeLabel && widthMode !== 'tiny' && (
          <div className="text-app-xxs">{extraBadgeLabel}</div>
        )}
      </div>
      {children && children}
    </div>
  );
}
