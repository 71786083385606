import { Observable, combineLatest, of } from 'rxjs';
import { delay, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import {
  useInputModelAsObservable,
  useLoadable,
} from 'src/business/_core/data/hooks';
import {
  AppAuth,
  authenticationChecker,
  authenticationStore,
} from 'src/business/auth/services';
import { useClubSettings } from 'src/business/club/data/hooks';
import { SettingsCategoryPageCheckAuthArgs } from 'src/pages/SE-settings/_core';
import { appLogger } from '../../root/logger';
import { appRouteBuilder } from '../../root/pages';
import { AppRouteArgsAuth } from './AppRouteArgs.model';
import { AppRouteState } from './AppRouteState.model';

export function useAppRoute({
  auth,
  checkAuth,
  redirectTo,
  redirectToUrl,
}: {
  /** @deprecated use checkAuth */
  auth: AppRouteArgsAuth;
  checkAuth?: (args: SettingsCategoryPageCheckAuthArgs) => boolean;
  redirectTo: ({ appAuth }: { appAuth: AppAuth }) => Observable<string>;
  redirectToUrl: string;
}) {
  const routeAuth$ = useInputModelAsObservable(auth, '[useAppRoute] auth');
  const redirectTo$ = useInputModelAsObservable(
    redirectTo,
    '[useAppRoute] redirectTo',
  );
  const redirectToUrl$ = useInputModelAsObservable(
    redirectToUrl,
    '[useAppRoute] redirectToUrl',
  );
  const checkAuth$ = useInputModelAsObservable(
    checkAuth,
    '[useAppRoute] checkAuth',
  );

  const clubSettings = useClubSettings();

  return useLoadable({
    debugName: 'useAppRoute',
    initialValueFromLoadSnapshot: true,
    load: () => {
      const appAuth$ = authenticationStore.auth.get();

      const redirectToUrlFn$ = combineLatest([redirectTo$, appAuth$]).pipe(
        switchMap(([redirectTo, appAuth]) => {
          if (redirectTo) {
            return redirectTo({ appAuth });
          }
          return of(undefined);
        }),
        distinctUntilChanged(),
      );

      const routeAuthRedirectUrl$ = combineLatest([
        routeAuth$,
        appAuth$,
        checkAuth$,
      ]).pipe(
        switchMap(([routeAuth, appAuth, checkAuth]) => {
          try {
            if (checkAuth) {
              if (
                !checkAuth({
                  auth: appAuth?.user,
                  az: appAuth?.user?.club?.authorizations,
                  clubSettings,
                })
              ) {
                return of(undefined).pipe(
                  delay(1000),
                  map(() => {
                    appLogger.warn(
                      '[useAppRoute] checkAuth check failed: redirect to default page',
                    );
                    return appRouteBuilder.getDefaultRouteFromAuth({ appAuth });
                  }),
                );
              }
            } else if (routeAuth) {
              if (routeAuth.requiredRoles) {
                if (
                  !authenticationChecker.hasRequiredRoles(
                    appAuth.user,
                    routeAuth.requiredRoles,
                  )
                ) {
                  return of(undefined).pipe(
                    delay(1000),
                    map(() => {
                      // eslint-disable-next-line no-console
                      console.warn(
                        '[useAppRoute] invalid role: redirect to default page',
                        { requiredRoles: routeAuth.requiredRoles },
                      );
                      appLogger.warn(
                        '[useAppRoute] invalid role: redirect to default page',
                      );
                      return appRouteBuilder.getDefaultRouteFromAuth({
                        appAuth,
                      });
                    }),
                  );
                }
              } else if (routeAuth.requiredRolesOneOf) {
                if (
                  !authenticationChecker.hasRequiredRolesOneOf(
                    appAuth.user,
                    routeAuth.requiredRolesOneOf,
                  )
                ) {
                  return of(undefined).pipe(
                    delay(1000),
                    map(() => {
                      appLogger.warn(
                        '[useAppRoute] invalid role: redirect to default page',
                      );
                      return appRouteBuilder.getDefaultRouteFromAuth({
                        appAuth,
                      });
                    }),
                  );
                }
              } else if (routeAuth.authenticationRequired) {
                if (!appAuth.isAuthenticated) {
                  return of(appRouteBuilder.getLoginUrl());
                }
              }
            }
            return of(undefined);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            appLogger.error('AppRoute auth check failed');
            return appRouteBuilder.getDefaultRouteFromAuth({ appAuth });
          }
        }),
        distinctUntilChanged(),
      );

      return combineLatest([
        redirectToUrl$,
        redirectToUrlFn$,
        routeAuthRedirectUrl$,
      ]).pipe(
        map(([redirectToUrl, redirectToUrlFn, routeAuthRedirectUrl]) => {
          if (redirectToUrl) {
            return redirectToUrl;
          }
          if (redirectToUrlFn) {
            return redirectToUrlFn;
          }
          return routeAuthRedirectUrl;
        }),
        map((redirectUrl) => {
          const appRouteState: AppRouteState = {
            redirectUrl,
          };
          return appRouteState;
        }),
      );
    },
  });
}
