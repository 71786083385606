import React from 'react';
import { IoPeople } from 'react-icons/io5';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { DiveSessionParticipantsStats } from 'src/business/club/modules/club-dive-session/model';
import { PlanningViewConfig } from 'src/business/club/modules/club-planning/_model';
import { DiveSessionSimpleCardTotalCounters } from './DiveSessionSimpleCardTotalCounters';
import { DiveSessionSimpleCardTotalGroupsAndStaffCounters } from './DiveSessionSimpleCardTotalGroupsAndStaffCounters';

export const DiveSessionSimpleCardTotalExpandable = ({
  planningConfig,
  diveSessionParticipantsStats: stats,
  isExpandable,
  isExpanded,
  onClick,
  className,
}: {
  planningConfig: PlanningViewConfig;
  diveSessionParticipantsStats: DiveSessionParticipantsStats;
  isExpandable: boolean;
  isExpanded: boolean;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <div className={className} onClick={onClick}>
      <DiveSessionSimpleCardTotalGroupsAndStaffCounters
        showStaffCounts={planningConfig.showStaffCounts}
        context={
          planningConfig.viewPeriod === 'week' ||
          (planningConfig.viewPeriod === 'day' &&
            planningConfig.viewDayOptions.displayMode !== 'before-dive') ||
          (planningConfig.viewPeriod === 'session' &&
            planningConfig.viewSessionOptions.displayMode !== 'before-dive')
            ? 'tiny'
            : 'large'
        }
        diveSessionParticipantsStats={stats}
      />
      <div
        className={
          'my-0.5 w-full px-1 flex gap-0.5 flex-row font-bold justify-center items-center lg:text-lg'
        }
      >
        {isExpandable && (
          <div>
            {isExpanded ? (
              <AppHeroIcons.actionReduce className="hidden sm:flex h-2 w-2 sm:h-2.5 sm:w-2.5 md:h-2.5 md:w-2.5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
            ) : (
              <AppHeroIcons.actionExtend className="hidden sm:flex h-2 w-2 sm:h-2.5 sm:w-2.5 md:h-2.5 md:w-2.5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
            )}
          </div>
        )}
        <div className="w-full flex flex-col sm:flex-row gap-1 justify-between items-center text-center ">
          <div className="flex gap-0.5 sm:gap-1 items-center text-center ">
            <IoPeople className="hidden sm:flex w-3 h-3 text-gray-500" />
            <DiveSessionSimpleCardTotalCounters
              className="text-xs sm:text-sm md:text-base"
              diveSessionParticipantsStats={stats}
            />
          </div>
        </div>
        {isExpandable && (
          <div>
            {isExpanded ? (
              <AppHeroIcons.actionReduce className="hidden sm:flex h-2 w-2 sm:h-2.5 sm:w-2.5 md:h-2.5 md:w-2.5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
            ) : (
              <AppHeroIcons.actionExtend className="hidden sm:flex h-2 w-2 sm:h-2.5 sm:w-2.5 md:h-2.5 md:w-2.5 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
