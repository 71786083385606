import {
  RegionalSettingsCriteria,
  RegionalTax,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppIconsSettings } from 'src/business/_core/modules/layout/icons';
import { useRegionalSettings } from '../../options/useRegionalSettings.hook';

export const RegionalTaxLabel = ({
  regionalSettingsCriteria,
  taxReference,
  tax: taxInput,
  iconSize = null, // 'w-6 h-6'
  className,
}: {
  regionalSettingsCriteria?: RegionalSettingsCriteria;
  taxReference?: string;
  iconSize?: string;
  tax?: RegionalTax;
  className?: string;
}) => {
  const regionalSettings = useRegionalSettings(regionalSettingsCriteria);

  const tax: RegionalTax = useMemo(
    () =>
      taxReference
        ? (regionalSettings?.taxes ?? []).find(
            (x) => x?.reference === taxReference,
          )
        : taxInput,
    [taxReference, regionalSettings?.taxes, taxInput],
  );

  return !tax ? null : (
    <div className={clsx('flex gap-1 items-stretch', className)}>
      {iconSize && (
        <span className="">
          <AppIconsSettings.other.tax
            className={clsx('text-app-blue', iconSize)}
          />
        </span>
      )}
      <span className="px-1 font-bold truncate uppercase flex items-center text-gray-600">
        <span className="text-sm text-app-blue font-bold">
          {tax.categoryLabel} {tax.ratePercent}%
        </span>
        {tax.rateLabel && (
          <span className="ml-1 text-xs text-gray-500 font-normal">
            ({tax.rateLabel})
          </span>
        )}
      </span>
    </div>
  );
};
