/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubSettings } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { COUNTRY_OPTIONS } from '../../../../../../business/club/modules/club-diver-participant/pages/DiverBookingPage/forms/BookingMemberDiverEditForm/COUNTRY_OPTIONS.const';

export type ClubSettingsForm_LegalAddress_FormType = {
  clubSettings: Pick<ClubSettings, '_id' | 'publicData' | 'publicSettings'>;
};

export const ClubSettingsForm_LegalAddress = <
  FormModel extends ClubSettingsForm_LegalAddress_FormType,
>({
  form: formInput,
  className = '',
}: {
  form: UseFormReturn<FormModel>;
  className?: string;
}) => {
  const form =
    formInput as unknown as UseFormReturn<ClubSettingsForm_LegalAddress_FormType>;

  const { control, setValue } = form;

  return (
    <div className={clsx('grid gap-2', className)}>
      <h4 className="font-bold text-app-secondary uppercase">
        Adresse du siège social
      </h4>{' '}
      <div className={'w-full flex flex-col gap-4'}>
        <AppFormControlRHF
          className={'w-full'}
          label="Numéro et nom de la rue"
          control={control}
          name={
            'clubSettings.publicData.legalInformation.legalAddress.line1' as const
          }
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
        <div className={'w-full flex flex-row gap-4'}>
          <AppFormControlRHF
            label="Code postal"
            control={control}
            name={
              'clubSettings.publicData.legalInformation.legalAddress.postalCode' as const
            }
            renderComponent={(props) => (
              <AppInputRHF {...props} className={'w-24'} />
            )}
          />
          <AppFormControlRHF
            className={'w-full'}
            label="Ville"
            control={control}
            name={
              'clubSettings.publicData.legalInformation.legalAddress.city' as const
            }
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        </div>
        <div className={'w-full flex flex-row gap-4'}>
          <AppFormControlRHF
            className={'w-full'}
            label="État"
            control={control}
            name={
              'clubSettings.publicData.legalInformation.legalAddress.state' as const
            }
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
          <AppFormControlRHF
            className={'w-full'}
            label="Pays"
            control={control}
            name={
              'clubSettings.publicData.legalInformation.legalAddress.country' as const
            }
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={COUNTRY_OPTIONS}
                fullWidth
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
