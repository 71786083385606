import { changeDescriptorManager } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import { DiverBookingPageUpdateState } from '../../../models';
import { DiverBookingPageSetUpdateStateFn } from '../../../useDiverBookingPageGlobalState.hook';
export function useDiverBookingPageDeleteBookingDeposit({
  setUpdateState,
  updateState,
}: {
  setUpdateState: DiverBookingPageSetUpdateStateFn;
  updateState: DiverBookingPageUpdateState;
}) {
  return useCallback(
    ({ deletedBookingDepositId }: { deletedBookingDepositId: string }) => {
      // NOTE: on ne gère pas de mise à jour lié au conséquences de cette suppression
      // voir aussi useDiverBookingPageDeletePurchasePayment
      if (deletedBookingDepositId) {
        const bookingDepositsChanges = changeDescriptorManager.deleteOne(
          deletedBookingDepositId,
          {
            changeDescriptors: updateState.bookingDepositsChanges,
          },
        );

        setUpdateState(
          {
            ...updateState,
            bookingDepositsChanges,
          },
          {
            action: 'DiverBookingPageDeleteBookingDeposit',
          },
        );
      }
    },
    [setUpdateState, updateState],
  );
}
