/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { AppPageContainerWithFixedBars } from 'src/business/_core/modules/layout';
import { LoadingProgressBar } from 'src/business/_core/modules/root/pages/AppRoot/LoadingProgressBar';
import { DiveCenterEditDialogActionsBar } from 'src/pages/SE-settings/SE-02_account/ClubSettingsAccountCompanyInfoPage/DiveCenterEditDialog/DiveCenterEditDialogActionsBar';
import { ClubDialogsState } from 'src/pages/_dialogs';
import { ClubPlanningLightPanelViewPeriodDay } from '../../../club-planning-light/ClubPlanningLightPanelViewPeriodDay';
import { ClubPlanningLightPanelViewPeriodWeek } from '../../../club-planning-light/ClubPlanningLightPanelViewPeriodWeek';
import { ClubPlanningDisplayConfigurationBarContext } from '../../../club-planning-light/club-planning-navigation';
import { DiveSessionSecuritySheetCardMenuActionsDef } from '../../../club-planning-light/components';
import {
  ClubPlanningLightPanelViewClickActions,
  ClubPlanningPanelSessionDisplayConfig,
} from '../../../club-planning-light/model';
import { ClubPlanningStaffWeekStaffPresence } from '../ClubPlanningStaffWeekStaffPresence';
import { ClubPlanningStaffWeekStaffRoles } from '../ClubPlanningStaffWeekStaffRoles';
import { PlanningStaffLocalState } from '../usePlanningStaffLocalState.hook';
import { ClubPlanningStaffConfigBar } from './ClubPlanningStaffConfigBar';
import {
  ClubPlanningStaffPanelLocalState,
  useClubPlanningStaffPanelLocalState,
} from './useClubPlanningStaffPanelLocalState';

export const ClubPlanningStaffPanel = ({
  localState: parentState,
  clickActions,
  navigationBarDisplay,
  sessionsDisplayConfigs = [],
  sessionCardMenuActions,
  context,
  dialogsState,
  isAnyDialogOpen,
  className,
}: {
  localState: PlanningStaffLocalState;
  navigationBarDisplay: {
    view?: boolean;
    mode?: boolean;
    actions?: boolean;
  };
  clickActions: ClubPlanningLightPanelViewClickActions;
  context: ClubPlanningDisplayConfigurationBarContext;
  sessionsDisplayConfigs: ClubPlanningPanelSessionDisplayConfig[];
  sessionCardMenuActions?: DiveSessionSecuritySheetCardMenuActionsDef;
  dialogsState: ClubDialogsState;
  isAnyDialogOpen: boolean;
  className?: string;
}) => {
  const {
    planningConfig,
    setPlanningConfig,
    weekPlanning,
    diveSessionResumes,
    clubEvents,
    loadableContent,
    dialogsOld,
    dailyConfigs,
  } = parentState;

  const localState: ClubPlanningStaffPanelLocalState =
    useClubPlanningStaffPanelLocalState({
      parentState,
      dialogsState,
    });

  const { data, actions } = localState;
  const { clubResume, diveCenterResume, days } = data;

  return clubResume && days ? (
    <div
      className={`h-full flex flex-col bg-white py-2 z-10 ${className ?? ''}`}
    >
      <AppPageContainerWithFixedBars
        maxScreenClassName="max-w-[110rem]"
        contentClassName="bg-white app-p-content"
        footerBar={
          data.operationInProgressMessage
            ? () => (
                <LoadingProgressBar>
                  {data.operationInProgressMessage}
                </LoadingProgressBar>
              )
            : data.cache.updateState.hasChanges
            ? () => (
                <DiveCenterEditDialogActionsBar
                  maxScreenClassName="max-w-[110rem]"
                  onCancel={() => {
                    actions.cancelChanges();
                  }}
                  submitForm={() => {
                    actions.persistChanges();
                  }}
                />
              )
            : undefined
        }
      >
        <ClubPlanningStaffConfigBar
          dialogsState={dialogsState}
          planningConfig={planningConfig}
          display={navigationBarDisplay}
          setPlanningConfig={(planningConfig, options) => {
            setPlanningConfig(planningConfig, options);
          }}
          disableNavigation={data.cache.updateState.hasChanges}
        />
        {planningConfig.viewPeriod === 'week' && (
          <>
            {planningConfig.viewWeekOptions.displayMode === 'staff-presence' ? (
              <ClubPlanningStaffWeekStaffPresence
                className="flex-grow mt-2 mx-2"
                parentState={localState}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
                clickActions={clickActions}
                showHeaderDays={true}
              />
            ) : planningConfig.viewWeekOptions.displayMode === 'staff-roles' ? (
              <ClubPlanningStaffWeekStaffRoles
                className="flex-grow mt-2 mx-2"
                parentState={localState}
                days={days}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
                clickActions={clickActions}
              />
            ) : (
              <ClubPlanningLightPanelViewPeriodWeek
                className={'flex-grow mt-2 mx-2'}
                days={days}
                context={context}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
                clickActions={clickActions}
                sessionsDisplayConfigs={sessionsDisplayConfigs}
                isAnyDialogOpen={isAnyDialogOpen}
              />
            )}
          </>
        )}
        {planningConfig.viewPeriod === 'day' && (
          <>
            {planningConfig.viewDayOptions.displayMode === 'staff-presence' ? (
              <ClubPlanningStaffWeekStaffPresence
                className="flex-grow mt-6 mx-2"
                parentState={localState}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
                clickActions={clickActions}
                showHeaderDays={false}
              />
            ) : (
              <ClubPlanningLightPanelViewPeriodDay
                className={'flex-grow mt-2 mx-2'}
                context={context}
                days={days}
                clickActions={clickActions}
                sessionsDisplayConfigs={sessionsDisplayConfigs}
                planningConfig={planningConfig}
                setPlanningConfig={setPlanningConfig}
                sessionCardMenuActions={sessionCardMenuActions}
                dialogsState={dialogsState} // attention, pour l'instant c'est null si on est sur l'écran de sélection de session
              />
            )}
          </>
        )}
      </AppPageContainerWithFixedBars>
    </div>
  ) : null;
};
