import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { ValueLabel } from 'src/business/club/modules/_common/form';
import {
  AppTitleDateNavigatorDatePicker,
  AppTitleDateNavigatorDateRangePicker,
  AppTitleDateNavigatorNavLeft,
  AppTitleDateNavigatorNavRight,
  AppTitleDateNavigatorPeriodSelector,
} from './components';
import {
  AppTitleDateNavigatorInputState,
  AppTitleDateNavigatorPeriod,
  AppTitleDateNavigatorWithPeriodSelectorProps,
} from './model';
import { useAppTitleDateNavigatorLocalState } from './useAppTitleDateNavigatorLocalState.hook';

export const AppTitleDateNavigatorWithPeriodSelector = ({
  hidePeriodSelector,
  hideNavLeft,
  hideNavRight,
  periodRange,
  minDate,
  maxDate,
  onChange,
  children,
  ajustPeriodRangeToCurrentDate,
  className,
}: AppTitleDateNavigatorWithPeriodSelectorProps) => {
  const now = useMemo(() => new Date(), []);

  const clubResume = useClubResume();
  const clubSettings = clubResume?.clubSettings;
  const fiscalStartMonth = clubSettings.general.fiscalStartMonth;

  const inputState: AppTitleDateNavigatorInputState = useMemo(
    () => ({
      period: periodRange.period ?? 'day',
      value: periodRange.value ?? {
        selectedDate: now,
        beginDate: now,
        endDate: now,
      },
      minDate,
      maxDate,
      onChange: (model) => {
        onChange({
          period: model.period,
          value: model.value,
        });
      },
      fiscalStartMonth,
      ajustPeriodRangeToCurrentDate,
    }),
    [
      periodRange.period,
      periodRange.value,
      now,
      minDate,
      maxDate,
      fiscalStartMonth,
      onChange,
      ajustPeriodRangeToCurrentDate,
    ],
  );

  const localState = useAppTitleDateNavigatorLocalState({ inputState });

  const { model, onSwitchPeriod, onUpdateValue } = localState;

  const periodViewOptions: ValueLabel<AppTitleDateNavigatorPeriod, string>[] =
    useMemo(() => {
      let options: ValueLabel<AppTitleDateNavigatorPeriod, string>[] = [
        {
          value: 'day',
          label: 'JOUR',
        },
        {
          value: 'week',
          label: 'SEMAINE',
        },
        {
          value: 'month',
          label: 'MOIS',
        },
        {
          value: 'year',
          label: 'ANNÉE',
        },
      ];
      if (fiscalStartMonth > 0) {
        options = options.concat([
          {
            value: 'year-fiscal',
            label: 'ANNÉE FISCALE',
          },
        ]);
      }
      options = options.concat([
        {
          value: 'dates-range',
          label: 'DATES',
        },
      ]);
      return options;
    }, [fiscalStartMonth]);

  return (
    <div className={clsx(className)}>
      {(!hidePeriodSelector || children) && (
        <div
          className={clsx(
            'py-2',
            !hidePeriodSelector &&
              'flex sm:gap-3 xl:gap-5 justify-between flex-col-reverse sm:flex-row-reverse',
          )}
        >
          {!hidePeriodSelector && (
            <div
              className={clsx(
                'flex sm:items-center sm:gap-2',
                children
                  ? 'flex-col sm:flex-row xl:flex-row-reverse '
                  : 'flex-row-reverse ',
              )}
            >
              <AppTitleDateNavigatorPeriodSelector
                value={model.period}
                onChanges={(period) => onSwitchPeriod(period)}
                items={periodViewOptions}
              />
            </div>
          )}
          {children && children}
        </div>
      )}
      <div>
        {['day', 'week', 'month', 'year', 'year-fiscal'].includes(
          model.period,
        ) && (
          <div
            className={
              'flex justify-between gap-2 bg-gray-100 place-items-center'
            }
          >
            <AppTitleDateNavigatorNavLeft
              className={hideNavLeft ? 'invisible' : ''}
              previousValue={localState.model.previousValue}
              onChange={(value) => localState.onUpdateValue(value)}
            />
            <AppTitleDateNavigatorDatePicker
              value={localState.model.value}
              onChange={(date) => localState.onUpdateSelectedDate(date)}
              isNow={localState.model.isNow}
            />
            <AppTitleDateNavigatorNavRight
              className={hideNavRight ? 'invisible' : ''}
              nextValue={localState.model?.nextValue}
              onChange={(value) => localState.onUpdateValue(value)}
            />
          </div>
        )}
        {model.period === 'dates-range' && (
          <div
            className={
              'flex justify-center gap-x-2 bg-gray-100 place-items-center'
            }
          >
            <AppTitleDateNavigatorDateRangePicker
              model={localState.model}
              onUpdateEndDate={localState.onUpdateEndDate}
              onUpdateSelectedDate={localState.onUpdateSelectedDate}
            />
          </div>
        )}
      </div>
    </div>
  );
};
