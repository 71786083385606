import { FC, default as React, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppIcon } from '../../../icons/AppIcon.type';

export type AppButtonUnstyledProps = {
  id?: string;
  className?: string;
  iconStyleClass?: string;
  iconPosition?: 'left' | 'right';
  icon?: AppIcon;
  href?: string;
  onClick?: (
    e?:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => any;
  disabled?: boolean;
  tabIndex?: number;
  style?: React.CSSProperties;
  hoverStyle?: React.CSSProperties;
  gap?: 1 | 2;
  setReferenceElement?: React.LegacyRef<HTMLAnchorElement | HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  target?: string;
  rel?: string;
  children?: React.ReactNode | React.ReactNode[];
  download?: string;
};

export const AppButtonUnstyled: FC<AppButtonUnstyledProps> = ({
  id,
  children,
  icon,
  className,
  iconStyleClass,
  iconPosition = 'left',
  href,
  onClick,
  disabled,
  tabIndex,
  style,
  hoverStyle,
  gap = 1,
  setReferenceElement,
  type,
  target,
  rel,
  download,
}) => {
  const [isHover, setIsHover] = useState(false);

  const styleWithHoverSupport = useMemo(() => {
    if (style) {
      return isHover && hoverStyle
        ? {
            ...style,
            ...hoverStyle,
          }
        : style;
    } else {
      return isHover ? hoverStyle : undefined;
    }
  }, [hoverStyle, isHover, style]);

  const AppButtonIcon = icon;
  const isLocalLink = useMemo(() => href?.startsWith('/'), [href]);
  return href && !disabled ? (
    isLocalLink ? (
      <Link
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        id={id}
        to={href}
        // ref={setReferenceElement as React.LegacyRef<HTMLAnchorElement>}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
        target={target}
        rel={rel}
        download={download}
        className={`cursor-pointer inline-flex ${
          gap === 1 ? 'gap-1' : 'gap-2'
        } items-center border shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 
      ${className ?? ''}`}
        tabIndex={tabIndex}
        style={styleWithHoverSupport}
      >
        {iconPosition === 'left' && AppButtonIcon && (
          <AppButtonIcon
            className={`cursor-pointer ${iconStyleClass}`}
            aria-hidden="true"
          />
        )}
        {children}
        {iconPosition === 'right' && AppButtonIcon && (
          <AppButtonIcon
            className={`cursor-pointer ${iconStyleClass}`}
            aria-hidden="true"
          />
        )}
      </Link>
    ) : (
      <a
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        id={id}
        href={href}
        ref={setReferenceElement as React.LegacyRef<HTMLAnchorElement>}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
        target={target}
        rel={rel}
        download={download}
        className={`cursor-pointer inline-flex ${
          gap === 1 ? 'gap-1' : 'gap-2'
        } items-center border shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 
      ${className ?? ''}`}
        tabIndex={tabIndex}
        style={styleWithHoverSupport}
      >
        {iconPosition === 'left' && AppButtonIcon && (
          <AppButtonIcon
            className={`cursor-pointer ${iconStyleClass}`}
            aria-hidden="true"
          />
        )}
        {children}
        {iconPosition === 'right' && AppButtonIcon && (
          <AppButtonIcon
            className={`cursor-pointer ${iconStyleClass}`}
            aria-hidden="true"
          />
        )}
      </a>
    )
  ) : (
    <button
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      type={type}
      id={id}
      ref={setReferenceElement as React.LegacyRef<HTMLButtonElement>}
      disabled={disabled}
      onClick={(e) => {
        if (!disabled && onClick) {
          onClick(e);
        }
      }}
      className={`cursor-pointer inline-flex ${
        gap === 1 ? 'gap-1' : 'gap-2'
      } items-center border shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 
      ${disabled ? 'opacity-50 pointer-events-none' : ''} 
      ${className ?? ''}`}
      tabIndex={tabIndex}
      style={styleWithHoverSupport}
    >
      {iconPosition === 'left' && AppButtonIcon && (
        <AppButtonIcon
          className={`cursor-pointer ${iconStyleClass}`}
          aria-hidden="true"
        />
      )}
      {children}
      {iconPosition === 'right' && AppButtonIcon && (
        <AppButtonIcon
          className={`cursor-pointer ${iconStyleClass}`}
          aria-hidden="true"
        />
      )}
    </button>
  );
};
