/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback } from 'react';
import {
  AppButton,
  AppFixedButtonsBar,
  AppMessage,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { DiverBookingPageUpdateState } from '../../models';
import { BookingMassiveEditorSessionsTable } from './BookingMassiveEditorSessionsTable';
import { BookingMassiveEditorSessionsTableRegisterAllButton } from './BookingMassiveEditorSessionsTable/BookingMassiveEditorSessionsTableRegisterAllButton';
import { BookingMassiveEditorDialogLocalState } from './model';
import { BookingMassiveEditorActions } from './useBookingMassiveEditorDialogActions.hook';

export const BookingMassiveEditorDialogRegistrationPanel: FC<{
  localState: BookingMassiveEditorDialogLocalState;
  actions: BookingMassiveEditorActions;
  onConfirm: (updateState: DiverBookingPageUpdateState) => void;
  onCancel: () => void;
}> = ({ localState, actions, onConfirm = () => {}, onCancel = () => {} }) => {
  const { setInputState, inputState, innerState } = localState;

  const { isUniqueMember, isSameLastName, updateState } = innerState;

  const closeDialog = useCallback(() => {
    setInputState({
      isOpen: false,
    });
  }, [setInputState]);

  return (
    <>
      <div className={`h-full flex-col ${inputState.isOpen ? '' : 'hidden'}`}>
        <AppPageContentContainer className="bg-white mx-1 app-px-content">
          <h3 className="mt-2 lg:text-left px-4 text-center text-sm sm:text-base md:text-lg lg:text-xl font-extrabold bg-gray-600 text-white uppercase">
            Inscription rapide des participants
          </h3>
          <BookingMassiveEditorSessionsTable
            className="mt-4"
            context="massive-booking-dialog"
            localState={innerState}
            displayName={!isUniqueMember}
            displayLastName={!isSameLastName}
            actions={actions}
            globalButtons={
              <BookingMassiveEditorSessionsTableRegisterAllButton
                localState={localState.innerState}
                actions={actions}
              />
            }
          />
          <div className="flex-grow" />
          <div>
            <AppMessage
              isExpanded={true}
              className="my-2"
              type="info"
              title="Instructions"
              message={
                <>
                  <div className="flex flex-col gap-2">
                    <div className="font-bold">
                      Depuis cet écran, vous pouvez:
                    </div>
                    <ul className="ml-3 list-disc">
                      <li>
                        inscrire <b>individuellement</b> les participant
                      </li>
                      <li>
                        inscrire <b>d'un seul coup</b> tous les participants
                      </li>
                      <li>
                        modifier les paramètres d'un seul participant en
                        cliquant sur la case correspondante
                      </li>
                      <li>
                        modifier les paramètres de toutes les plongées d'un
                        participant en cliquant sur bouton{' '}
                        <AppHeroIcons.actionEdit className="border border-gray-400 p-px ml-1 -mt-1 inline-block h-5 w-5" />
                      </li>
                    </ul>
                  </div>
                </>
              }
            />
          </div>
          <div className="mb-8" />
          {/* 'mb-8' pour être sûr que le contenu apparait en bas, sur ipad - TODO mutualiser tout ça proprement dans un composant */}
        </AppPageContentContainer>
        <div className="flex-grow" />
        <AppFixedButtonsBar hasChanges={true}>
          <>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionCancel}
              tabIndex={500}
              color="gray-outline-light"
              onClick={() => {
                onCancel();
                closeDialog();
              }}
            >
              Annuler
            </AppButton>
            <AppButton
              size="normal"
              icon={AppHeroIcons.actionSave}
              color="primary-outline-light"
              onClick={() => {
                onConfirm(updateState);
              }}
            >
              Ok
            </AppButton>
          </>
        </AppFixedButtonsBar>
      </div>
    </>
  );
};
