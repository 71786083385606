/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import {
  useClubResume,
  useDiveCenterResume,
} from 'src/business/club/data/hooks';
import { DiveCenterProBadge } from 'src/pages/_components/company';
import { SettingsCategory } from '../../_core';
import { ClubSettingsCategoryCardPageButton } from './ClubSettingsCategoryCardPageButton';

export const ClubSettingsCategoryCard = ({
  category,
  className,
  as = 'div',
}: {
  category: SettingsCategory;
  className?: string;
  as?: React.ElementType; // TODO personnaliser le componsant (ici, un "li", mais on pourrait vouloir un "div")
}) => {
  const clubResume = useClubResume();
  const diveCenterResume = useDiveCenterResume();
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;

  return React.createElement(
    as,
    {
      className: clsx(
        'group flex flex-col divide-y divide-gray-100 rounded-lg bg-white text-center shadow backdrop-opacity-20',
        className,
      ),
      style: {
        // backgroundColor: colorGenerator.rgba(category.color, 0.1),
      },
    },
    <>
      <div
        className="flex flex-col p-8"
        style={
          {
            // backgroundColor: colorGenerator.rgba(category.color, 0.1),
          }
        }
      >
        <div
          className={clsx(
            'mx-auto p-3.5 flex-shrink-0 rounded-full bg-gray-300',
            // 'group-hover:opacity-100',
          )}
          style={{
            backgroundColor: category.color,
          }}
        >
          <category.icon
            className={clsx(
              'h-14 w-14 flex-shrink-0 bg-transparent text-white opacity-90',
              // 'group-hover:opacity-100',
            )}
          />
        </div>
        <h3
          className="mt-6 text-lg font-bold text-gray-900 uppercase opacity-80"
          style={{
            color: category.color,
          }}
        >
          {category.label}
        </h3>
        {isMultiDiveCenters && category?.isDiveCenterSpecificConfig && (
          <DiveCenterProBadge
            className="w-24 mx-auto"
            diveCenter={diveCenterResume}
          />
        )}
      </div>
      <div
        className=""
        style={
          {
            // backgroundColor: colorGenerator.rgba(category.color, 0.05),
          }
        }
      >
        <ul className="border-b border-gray-100 divide-y divide-gray-200">
          {category.pages.map((page) => (
            <ClubSettingsCategoryCardPageButton
              key={page.id}
              as="li"
              category={category}
              page={page}
            />
          ))}
        </ul>
      </div>
      {/* <div
        className="flex-1"
        style={
          {
            // backgroundColor: colorGenerator.rgba(category.color, 0.1),
          }
        }
      ></div> */}
    </>,
  );
};
