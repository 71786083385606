/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { useWatch } from 'react-hook-form';
import {
  AppFormControlRHF_Deprecated,
  AppInputBooleanSwitchRHF,
} from 'src/lib/form';
import { DailyExportConfigDialogLocalState } from '../../useDailyExportConfigDialogLocalState.hook';

export const DECOP_SessionItemStaffGroups = ({
  localState,
  dailyResumeIndex,
  sheetIndex,
  contentItemIndex,
  sessionItemIndex,
  className,
}: {
  localState: DailyExportConfigDialogLocalState;
  dailyResumeIndex: number;
  sheetIndex: number;
  contentItemIndex: number;
  sessionItemIndex: number;
  className?: string;
}) => {
  const { form, initialState, data } = localState;

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const baseFormName =
    `dailyResumes.${dailyResumeIndex}.exportConfig.sheets.${sheetIndex}.content.items.${contentItemIndex}.items.${sessionItemIndex}` as const;

  const [isEnabled] = useWatch({
    control,
    name: [`${baseFormName}.enabled`],
  });

  return (
    <div className={clsx('flex gap-2', className)}>
      <AppFormControlRHF_Deprecated
        control={control}
        name={`${baseFormName}.enabled`}
        renderComponent={(props) => <AppInputBooleanSwitchRHF {...props} />}
      />
      <div className="grid gap-2">
        <h4 className="text-left text-base font-medium text-gray-600">
          Résumé des activités moniteurs
        </h4>
      </div>
    </div>
  );
};
