/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPlanningLightSessionDef,
  DiveSessionResumeParticipantsByGroup,
} from '@mabadive/app-common-model';
import {
  dateService,
  diveSessionGroupBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { IoPeople } from 'react-icons/io5';
import { diveSessionParticipantStatsBuilder } from 'src/business/club/data';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { DiveSessionParticipantsStats } from 'src/business/club/modules/club-dive-session/model';
import { DiveSessionSimpleCardTotalCounters } from '../components';
import { ClubPlanningAction } from '../useClubPlanningActions.hook';
import { ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroup } from './ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroup';
import { ClubPlanningLightPanelViewPeriodMonthState } from './useClubPlanningLightPanelViewPeriodMonthState.hook';

export const ClubPlanningLightPanelViewPeriodMonthWeekDaySession = ({
  localState,
  clubPlanningActions,
  session,
  sessionIndex,
  className,
}: {
  localState: ClubPlanningLightPanelViewPeriodMonthState;
  clubPlanningActions: ClubPlanningAction;
  session: ClubPlanningLightSessionDef;
  sessionIndex?: {
    index: number;
    total: number;
  };
  className?: string;
}) => {
  const { dailyConfigs } = localState;

  const clickActions = clubPlanningActions?.clickActions;

  const onClickSession = clickActions?.onClickSession;

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const staffMembers = diveCenterResume?.staffMembers;
  const { bookingSessionParticipants, participants, groups } = session;

  const diveSessionParticipantsByGroup: DiveSessionResumeParticipantsByGroup =
    useMemo(
      () =>
        diveSessionGroupBuilder.buildDiveSessionParticipantsGroups({
          diveSession: session,
          groups,
          participants,
          staffMembers,
          ignoreCancelledParticipants: true,
          ignoreGroupsWithoutParticipants: true,
          diveModesToAutoGroup: ['autoSupervised', 'theoretical-training'],
        }),
      [session, groups, participants, staffMembers],
    );

  const stats: DiveSessionParticipantsStats = useMemo(
    () =>
      diveSessionParticipantStatsBuilder.buildDiveSessionParticipantsStats({
        clubReference,
        session,
        participants: diveSessionParticipantsByGroup.all,
        groups: diveSessionParticipantsByGroup.allGroups,
        staffMembers: diveCenterResume?.staffMembers,
        dailyConfigs,
        clubSettings: diveCenterResume?.clubResume?.clubSettings,
      }),
    [
      clubReference,
      dailyConfigs,
      diveCenterResume?.clubResume?.clubSettings,
      diveCenterResume?.staffMembers,
      diveSessionParticipantsByGroup.all,
      diveSessionParticipantsByGroup.allGroups,
      session,
    ],
  );

  const isPast = useMemo(
    () =>
      !dateService.isTodayUTC(session.time) &&
      dateService.isPastUTC(session.time),
    [session.time],
  );

  const { timeDayPeriod, sessionsCount } = stats;

  return (
    <div
      className={clsx(
        'relative select-none text-gray-500 pl-px',
        `border-l-4 border-day-period-${timeDayPeriod}-dark`,
        'grid gap-px',
        'cursor-pointer',
        className,
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClickSession(session);
      }}
    >
      <div
        className={clsx(
          'px-px sm:px-0.5 flex gap-1 justify-center items-center',
          `bg-day-period-${timeDayPeriod}-dark text-white`,
          isPast && 'opacity-80',
        )}
      >
        <div
          className={clsx(
            'md:text-xs font-medium',
            sessionsCount > 1 ? 'text-app-xxs sm:text-xs ' : 'text-xs',
          )}
        >
          {dateService.formatUTC(session.time, 'HH:mm')}
        </div>
        {sessionsCount > 1 && (
          <div
            className={clsx(
              'text-app-xxs sm:text-xs font-bold',
              ' right-0 px-px bg-gray-500 text-white',
              'sm:absolute',
            )}
          >
            <span>x{sessionsCount}</span>
          </div>
        )}
      </div>
      <div className="bg-white text-xs font-bold px-1 flex gap-0.5 sm:gap-1 justify-start items-center text-center border-r-[1px] border-r-gray-400">
        <IoPeople className="hidden sm:flex w-3 h-3 text-gray-500" />
        <DiveSessionSimpleCardTotalCounters
          diveSessionParticipantsStats={stats}
        />
      </div>
      {diveSessionParticipantsByGroup.byGroup.length +
        diveSessionParticipantsByGroup.ungrouppedByVirtualGroup.length ===
      0 ? (
        <div className="h-4 bg-white text-center leading-4 font-bold text-gray-400">
          -
        </div>
      ) : (
        <div className="grid gap-px">
          {diveSessionParticipantsByGroup.byGroup.map(
            ({ group, participants }, groupIndex) => (
              <ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroup
                key={`group-${group?._id ?? 'fake'}-${groupIndex}`}
                group={group}
                virtualGroup={group.isVirtualGroup}
                participants={participants}
                session={session}
                localState={localState}
                clubPlanningActions={clubPlanningActions}
              />
            ),
          )}
          {diveSessionParticipantsByGroup.ungrouppedByVirtualGroup.map(
            ({ group, participants }, groupIndex) => (
              <ClubPlanningLightPanelViewPeriodMonthWeekDaySessionGroup
                key={`ungroupped-${groupIndex}`}
                group={group}
                virtualGroup={group.isVirtualGroup}
                participants={participants}
                session={session}
                localState={localState}
                clubPlanningActions={clubPlanningActions}
              />
            ),
          )}
        </div>
      )}
    </div>
  );
};
