/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Observer } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import { ConfirmDialog } from './ConfirmDialog';
import { confirmDialogStore } from './confirmDialogStore.service';
import { ConfirmDialogType } from './ConfirmDialogType.model';

export const ConfirmDialogManager = () => {
  const [index, setIndex] = useState(1);

  const {
    open,
    observer,
    uiIndex,
    title,
    message,
    type,
    isTailwind,
  }: {
    open: boolean;
    observer?: Observer<boolean>;
    uiIndex?: number;
    title?: string;
    message?: string;
    type?: ConfirmDialogType;
    isTailwind?: boolean;
  } = useObservable(
    () =>
      confirmDialogStore.state.get().pipe(
        map((state) => {
          const uiIndex = index + 1;
          setIndex(uiIndex);
          switch (state.mode) {
            case 'open':
              return {
                open: true,
                ...state,
              };
            case 'closed':
            default:
              return {
                open: false,
              };
          }
        }),
      ),
    {
      open: false, // initial value,
    },
  );

  function closeDialog(confirmed: boolean) {
    confirmDialogStore.state.set({
      mode: 'closed',
    });
    if (observer) {
      observer.next(confirmed);
      observer.complete();
    }
  }

  return open === undefined ? null : (
    <ConfirmDialog
      uiIndex={uiIndex}
      open={open}
      isTailwind={isTailwind}
      title={title}
      message={message}
      closeDialog={closeDialog}
      type={type}
    />
  );
};
