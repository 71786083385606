import { smsLinkBuilder } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
export function AppMessagePreviewSMS({
  title,
  body,
  signature,
  className,
}: {
  title?: string;
  body?: string;
  signature?: string;
  className?: string;
}) {
  const content = smsLinkBuilder.buildMessageFromChunks({
    title,
    body,
    signature,
  });

  return content?.length > 0 ? (
    <div className={clsx('grid gap-4', className)}>{content}</div>
  ) : null;
}
