import { nameFormatter } from '@mabadive/app-common-services';
import React, { FC } from 'react';
import {
  AppButtonsBar,
  AppPageBlock,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { AppButtonDeprecated } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { ClubPurchasesExtraCostDescription } from 'src/pages/LI-lists/LI-03_purchase/ClubPurchasesTable/ClubPurchasesExtraCostDescription';
import {
  DiverPurchaseCommonEditorDialogResult,
  DiverPurchasePlanEditorDialogState,
} from '../../models';
import { DiverBillingSessionsTable } from '../DiverBillingSessionsTable';
import {
  DiverPurchasePlanFormInfoBox,
  DiverPurchasePlanFormRHF,
} from './components';
import {
  DiverPurchasePlanDialogLocalState,
  useDiverPurchasePlanDialogLocalState,
} from './useDiverPurchasePlanDialogLocalState.service';

export const DiverPurchasePlanEditorDialog: FC<{
  state: DiverPurchasePlanEditorDialogState;
  setState: React.Dispatch<
    React.SetStateAction<DiverPurchasePlanEditorDialogState>
  >;
  onConfirm?: (result: DiverPurchaseCommonEditorDialogResult) => void;
}> = ({ onConfirm, state: inputState, setState: setInputState }) => {
  const globalClasses = useGlobalClasses();

  const localState: DiverPurchasePlanDialogLocalState =
    useDiverPurchasePlanDialogLocalState({
      onConfirm,
      inputState,
      setInputState,
    });

  const { formContext, actions, data, states } = localState;

  const { form, formWatch } = formContext;

  const {
    unitPrice,
    creditsInitialCount,
    validityStatus,
    unitQuantity,
    productPackageOfferReference,
    creditsAdditionalCount,
    consumedExternalCount,
    discountAmount,
    extraCosts,
  } = formWatch;
  const [openExtraCostsFormPanel, setOpenExtraCostsFormPanel] =
    states.openExtraCostsFormPanelState;

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  return !inputState.isOpen ? null : (
    <>
      <AppPageContainerWithFixedBars
        className={inputState.isOpen ? '' : 'hidden'}
        contentClassName="bg-white"
        footerBar={() =>
          !openExtraCostsFormPanel && (
            <AppButtonsBar className="" hasChanges={true}>
              <>
                <AppButtonDeprecated
                  icon={AppHeroIcons.actionCancel}
                  buttonStyle="outline-transparent"
                  size="normal"
                  onClick={() => {
                    actions.closeDialog();
                  }}
                >
                  Annuler
                </AppButtonDeprecated>
                {data.purchasePackage && (
                  <AppButtonDeprecated
                    icon={AppHeroIcons.actionRemove}
                    buttonStyle="danger"
                    className="mx-5"
                    onClick={actions.confirmDeletePackage}
                  >
                    Supprimer
                  </AppButtonDeprecated>
                )}
                <AppButtonDeprecated
                  icon={AppHeroIcons.actionSave}
                  buttonStyle="outline-transparent-primary"
                  onClick={() => {
                    actions.submitForm();
                  }}
                >
                  Confirmer
                </AppButtonDeprecated>
              </>
            </AppButtonsBar>
          )
        }
      >
        <AppPageBlock className="px-2">
          {data.diver && (
            <h2 className="text-center text-lg leading-6 p-2 font-medium text-app-blue">
              {nameFormatter.formatFullName(data.diver, {
                format: 'firstName-first',
              })}
            </h2>
          )}
          <div className={globalClasses.pageBlock}>
            {inputState.isOpen && data.initialFormValue && (
              <>
                <DiverPurchasePlanFormRHF localState={localState} />
              </>
            )}
            {data.initialSessionsBillingResumes.all.length > 0 && (
              <DiverBillingSessionsTable
                participantsConfig={data.participantsConfig}
                setParticipantsConfig={actions.setParticipantsConfig}
                sessionsBillingResumesOtherDiversCount={
                  data.initialSessionsBillingResumes.otherTypeOtherDiver
                    .length +
                  data.initialSessionsBillingResumes.sameTypeOtherDiver.length
                }
                sessionsBillingResumesOtherTypeCount={
                  data.initialSessionsBillingResumes.otherTypeOtherDiver
                    .length +
                  data.initialSessionsBillingResumes.otherTypeSameDiver.length
                }
                totalConsumedCount={data.totalConsumedCount}
                totalCreditsCount={data.totalCreditsCount}
                className={'mb-2 sm:mb-5'}
                onClickAssignProducts={actions.onClickAssignProducts}
                displayAssignButton={true}
                sessionsBillingResumes={data.updatedSessionsBillingResumes}
                onClickSession={undefined}
                onClickParticipant={undefined}
              />
            )}
            {formWatch.extraCosts?.length > 0 && (
              <div className="my-2">
                <h2 className="text-left text-lg leading-6 p-2 font-medium text-app-primary">
                  Suppléments
                </h2>
                <ClubPurchasesExtraCostDescription
                  extraCosts={formWatch.extraCosts}
                  className="pl-5 text-base"
                />
              </div>
            )}

            <DiverPurchasePlanFormInfoBox isUnitOffer={data.isUnitOffer} />
          </div>
        </AppPageBlock>
      </AppPageContainerWithFixedBars>
    </>
  );
};
