import React from 'react';
import { AppTabsBarTab } from 'src/business/_core/modules/layout/components';

import {
  AppHeroIcons,
  EntitiesIcons,
} from 'src/business/_core/modules/layout/icons';
import { ClubListsTabId } from './ClubListsTabId.type';

export const CLUB_LISTS_TABS: AppTabsBarTab<ClubListsTabId>[] = [
  {
    id: 'diver',
    label: 'Plongeurs',
    labelShort: 'Plong.',
    icon: <EntitiesIcons.divers className="w-6 h-6" />,
    urlSuffix: 'divers',
    auth: { requiredRoles: ['club-view-lists-diver'] },
  },
  {
    id: 'booking',
    label: 'Réservations',
    labelShort: 'Résa.',
    icon: <EntitiesIcons.booking className="w-6 h-6" />,
    urlSuffix: 'bookings',
    auth: { requiredRoles: ['club-view-lists-participant'] },
  },
  {
    id: 'order',
    label: 'Commandes',
    labelShort: 'Comm.',
    icon: <EntitiesIcons.order className="w-6 h-6" />,
    urlSuffix: 'orders',
    checkAuth: ({ az, clubSettings }) =>
      clubSettings?.general?.onlineBooking?.enabled &&
      clubSettings?.general?.onlineBooking?.enableProOrdersList &&
      az?.orders?.view?.ordersList,
  },
  {
    id: 'billing',
    label: 'Facturation',
    labelShort: 'Fact.',
    icon: <AppHeroIcons.billing className="w-6 h-6" />,
    urlSuffix: 'billing',
    auth: {
      requiredRoles: ['club-view-lists-billing'],
    },
  },
  {
    id: 'payment',
    label: 'Paiements',
    labelShort: 'Paie.',
    icon: <EntitiesIcons.payment className="w-6 h-6" />,
    urlSuffix: 'payments',
    auth: {
      requiredRoles: ['club-view-lists-payment'],
    },
  },
  {
    id: 'deposit',
    label: 'Acomptes',
    labelShort: 'Acc.',
    icon: <EntitiesIcons.deposit className="w-6 h-6" />,
    urlSuffix: 'deposits',
    checkAuth: ({ az, clubSettings }) =>
      clubSettings?.general?.deposits?.enabled &&
      az?.view?.participant?.payment,
  },
  {
    id: 'cash',
    label: 'Caisse',
    labelShort: 'Caisse',
    icon: <AppHeroIcons.billing className="w-6 h-6" />,
    urlSuffix: 'cash',
    auth: {
      requiredRoles: ['club-view-dashboard-daily-cash'],
    },
  },
];
