/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  clubProfilesSorter,
  dataSorter,
  search,
} from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import {
  appLoader,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import { SettingsClubProfileWithAccounts } from './model';
import { clubSettingsUserAccoutsPageGraphqlFetcher } from './services';

export function useClubSettingsUserAccountsListPageLocalState() {
  // const clubResume = useClubResume()
  const diveCenterResume = useDiveCenterResume();
  const [searchText, setSearchText] = useState('');

  const [reloadCount, setReloadCount] = useState(1);
  const refetchData = useCallback(
    () => setReloadCount(reloadCount + 1),
    [reloadCount],
  );
  const { content, ...loadableContent } = useLoadableContent(
    () => {
      if (reloadCount) {
        // IMPORTANT!!! utilisé pour forcer à recharger
      }
      return appLoader.load(
        clubSettingsUserAccoutsPageGraphqlFetcher.fetchAll({
          clubReference: diveCenterResume.clubReference,
          diveCenterId: diveCenterResume._id,
        }),
        {
          type: 'full',
          defaultValue: undefined,
          isSubscription: false,
        },
      );
    },
    [diveCenterResume._id, diveCenterResume.clubReference, reloadCount],
    {
      initialValue: undefined,
      defaultValue: undefined,
      useSnapshot: false,
      debugName: 'useClubSettingsUserAccountsListPageLocalState',
    },
  );

  const isTextSearchActive = useMemo(
    () => search.clean(searchText)?.length,
    [searchText],
  );

  const { filteredProfilesWithAccounts, clubProfiles, userAccounts } =
    useMemo(() => {
      let clubProfiles = content?.clubProfiles ?? [];
      let userAccounts = content?.userAccounts ?? [];
      if (isTextSearchActive) {
        userAccounts = search.filter(userAccounts, {
          searchText,
          getAttributes: (x) => {
            const labels: string[] = [
              x.firstName,
              x.lastName,
              x.emailAddress,
              x.phoneNumber,
              x.staffRole,
            ];
            return labels;
          },
        });
      }
      let filteredProfilesWithAccounts: SettingsClubProfileWithAccounts[] =
        clubProfiles
          .map((clubProfile) => ({
            clubProfile,
            userAccounts: userAccounts.filter(
              (x) => x.authUser.clubProfileId === clubProfile._id,
            ),
          }))
          .filter((x) => !isTextSearchActive || x.userAccounts.length > 0);

      filteredProfilesWithAccounts = dataSorter.sortMultiple(
        filteredProfilesWithAccounts,
        {
          getSortAttributes: (x: SettingsClubProfileWithAccounts) =>
            clubProfilesSorter.getClubProfileSortableAttributes(x.clubProfile),
          asc: true,
        },
      );

      return { filteredProfilesWithAccounts, clubProfiles, userAccounts };
    }, [
      content?.clubProfiles,
      content?.userAccounts,
      isTextSearchActive,
      searchText,
    ]);

  return {
    searchText,
    setSearchText,
    filteredProfilesWithAccounts,
    clubProfiles,
    userAccounts,
    loadableContent,
    refetchData,
  };
}
