/* eslint-disable @typescript-eslint/no-unused-vars */
import { AuthUser } from '@mabadive/app-common-model';
import { collectionRepositoryFactory, collectionSimpleStoreAdaptersFactory } from 'src/business/_core/data/repository';
import { adminStore } from 'src/business/_core/store';

const store = adminStore.users;

const storeAdapters = collectionSimpleStoreAdaptersFactory.createAdapters(store);

export const adminUsersRepository = {
  ...collectionRepositoryFactory.create<AuthUser>({
    mutationEntity: 'authUser',
    synchronous: false,
    storeAdapters,
  }),
};
