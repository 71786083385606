/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { ClubSettingsSection } from 'src/pages/SE-settings/_core';
import { ClubDialogsState } from '../../../../../../../_dialogs';
import { ClubSettingsOnlineBookingFormModel } from '../../../_model';
import { ClubSettingsOnlineBookingEmailsForm_EmailOption } from './ClubSettingsOnlineBookingEmailsForm_EmailOption';

export const ClubSettingsOnlineBookingEmailsForm_01 = ({
  form,
  dialogsState,
}: {
  form: UseFormReturn<ClubSettingsOnlineBookingFormModel>;
  dialogsState: ClubDialogsState;
}) => {
  return (
    <ClubSettingsSection
      title="Mail 1 - à la réservation par le CLIENT"
      comment="Accusez réception des demandes de réservation"
    >
      <div className="grid gap-4">
        <ClubSettingsOnlineBookingEmailsForm_EmailOption
          dialogsState={dialogsState}
          form={form}
          optionName="onCustomerSubmitBookingWithoutPendingDeposit"
          label={"Réservation en ligne sans demande d'acompte 🚀"}
        />
        <ClubSettingsOnlineBookingEmailsForm_EmailOption
          dialogsState={dialogsState}
          form={form}
          optionName="onCustomerSubmitBookingWithPendingDeposit"
          label={"Réservation en ligne avec demande d'acompte 🚀"}
        />
        {/* <ClubSettingsOnlineBookingEmailsForm_EmailOption
        dialogsState={dialogsState}
        form={form}
        optionName="onCustomerSubmitPayment"
        label={"Option 1: réservation en ligne sans demande d'acompte"}
      /> */}
      </div>
    </ClubSettingsSection>
  );
};
