export const clubSettingsServicesPageTabLabelFormatter = {
  format,
};

export type ClubSettingsServicesPageTabId =
  | 'activities'
  | 'first-dive'
  | 'training'
  | 'explo'
  | 'other'
  | 'extra';

export type ClubSettingsServicesPageTabFirstDiveEditMode =
  | 'custom-names' ;

function format(tabId: ClubSettingsServicesPageTabId): string {
  switch (tabId) {
    case 'activities':
      return 'Prestations';
    case 'first-dive':
      return 'Baptêmes';
    case 'training':
      return 'Formations';
    case 'explo':
      return 'Explorations';
    case 'other':
      return 'Autres';
    case 'extra':
      return 'Suppléments';
  }
}
