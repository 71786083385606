import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ValueLabel } from '../../../_common/form/components/ValueLabel.model';
import {
  PlanningViewConfig,
  PlanningViewPeriodDayOptionsDisplayMode,
} from '../../../club-planning/_model';

export const clubPlanningDisplayModesOptionsBuilder = {
  buildOptions,
};

function buildOptions({
  context,
  mediaSize,
  planningConfig,
}: {
  context: string;
  mediaSize: string;
  planningConfig: PlanningViewConfig;
}): ValueLabel<PlanningViewPeriodDayOptionsDisplayMode, string>[] {
  if (context === 'planning-staff-page') {
    return [
      {
        value: 'staff-presence' as PlanningViewPeriodDayOptionsDisplayMode,
        label: 'Présence',
        icon: AppHeroIcons.displayModeIcon,
      },
      {
        value: 'staff-roles' as PlanningViewPeriodDayOptionsDisplayMode,
        label: 'Par moniteur',
        icon: AppHeroIcons.displayModeIcon,
      },
      {
        value: 'staff-sessions' as PlanningViewPeriodDayOptionsDisplayMode,
        label: 'Par sessions',
        icon: AppHeroIcons.displayModeIcon,
      },
    ].filter((x) => {
      if (!!x) {
        // switch (planningConfig.viewPeriod) {
        //   case 'week': {
        //     return x.value !== 'before-dive';
        //   }
        //   case 'day': {
        //     return x.value !== 'compact';
        //   }
        //   case 'session': {
        //     return false;
        //   }
        // }
        return true;
      }
      return false;
    });
  } else {
    return [
      {
        value: 'compact' as PlanningViewPeriodDayOptionsDisplayMode,
        label: 'Compact',
        icon: AppHeroIcons.displayModeIcon,
      },
      {
        value: 'full' as PlanningViewPeriodDayOptionsDisplayMode,
        label: 'Détaillé',
        icon: AppHeroIcons.displayModeIcon,
      },
      {
        value: 'before-dive' as PlanningViewPeriodDayOptionsDisplayMode,
        label:
          mediaSize === 'xxs' || mediaSize === 'xs'
            ? 'Feuille sécu'
            : 'Feuille sécu',
        icon: AppHeroIcons.displayModeIcon,
      },
    ].filter((x) => {
      if (!!x) {
        switch (planningConfig.viewPeriod) {
          case 'week': {
            return x.value !== 'before-dive';
          }
          case 'day': {
            return x.value !== 'compact';
          }
          case 'session': {
            return false;
          }
        }
      }
      return false;
    });
  }
}
