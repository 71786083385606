/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingDepositState } from '@mabadive/app-common-model';
import { useState } from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { useBookingDepositStateOptions } from '../../_components/options';

export type MonthId = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export function useDepositsListPageFilters() {
  const clubResume = useClubResume();
  const clubSettings = clubResume.clubSettings;

  const [searchText, setSearchText] = useState('');
  const [depositState, setDepositState] = useState<BookingDepositState>();
  const [depositStates, setDepositStates] = useState<BookingDepositState[]>([
    'active',
    'draft',
  ]);
  const [purchasePaymentPending, setPurchasePaymentPending] =
    useState<boolean>();
  const [partiallyUsed, setPartiallyUsed] = useState<boolean>();

  const depositStateOptions = useBookingDepositStateOptions();
  return {
    searchText,
    setSearchText,
    depositState,
    setDepositState,
    depositStates,
    setDepositStates,
    depositStateOptions,
    purchasePaymentPending,
    setPurchasePaymentPending,
    partiallyUsed,
    setPartiallyUsed,
  };
}

export type DepositsListPageFilters = ReturnType<
  typeof useDepositsListPageFilters
>;
