import {
  AppEntityUpdatePatch,
  BookingProduct,
} from '@mabadive/app-common-model';
import { jsonPatcher } from '@mabadive/app-common-services';

export const productPurchaseStatusUpdator = {
  removeProductsPurchasedStatus,
};

function removeProductsPurchasedStatus({
  initialDiveBookingProducts,
}: {
  initialDiveBookingProducts: BookingProduct[];
}): AppEntityUpdatePatch[] {
  const updatedProducts: AppEntityUpdatePatch[] = [];

  initialDiveBookingProducts.forEach((p) => {
    const isFinalAssociated = false;
    const isInitialAssociated = p.purchaseStatus === 'purchased';
    if (isFinalAssociated !== isInitialAssociated) {
      const pFinal: BookingProduct = {
        ...p,
        purchaseStatus: 'pending',
        purchasePackageId: null,
      };
      const productPatchOperations = jsonPatcher.compareObjects(p, pFinal, {});
      if (productPatchOperations.length) {
        updatedProducts.push({
          pk: p._id,
          patchOperations: productPatchOperations,
        });
      }
    }
  });
  return updatedProducts;
}
