/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Booking,
  ClubDiver,
  DIVE_MODE_WITH_CERTIFICATION,
  DiveCenterResume,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  commonEntityBuilder,
  diveModeAnalyser,
  diverBookingCreator,
  diverBookingMemberCreator,
  initialBookingBuilder,
} from '@mabadive/app-common-services';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { uiStore } from 'src/business/_core/store';
import { appWebLogger } from 'src/lib/browser';
import { bookingMemberFormParser } from '../../DiverBookingPage/components/BookingParticipantDialog/BookingParticipantEditorDialog/services/changes-builder';
import { clubDiverFormParser } from '../../DiverBookingPage/components/BookingParticipantDialog/BookingParticipantEditorDialog/services/changes-builder/diver';
import { BookingInitialPlanEditFormModel } from '../../DiverBookingPage/forms/BookingInitialPlanEditForm/model';
import { BookingInitialPlanRegistrationMode } from '../../DiverBookingPage/forms/BookingInitialPlanEditForm/model/BookingInitialPlanRegistrationMode.type';
import { DiverBookingPageUpdateState } from '../../DiverBookingPage/models';
import { diverBookingPageClientUpdator } from '../../DiverBookingPage/services';

export const createBookingInitialPlanPagePersister = {
  persistChanges,
};

async function persistChanges({
  formValue,
  registrationMode,
  diveCenterResume,
}: {
  formValue: BookingInitialPlanEditFormModel;
  registrationMode: BookingInitialPlanRegistrationMode;
  diveCenterResume: DiveCenterResume;
}): Promise<{
  success: boolean;
  booking?: Booking;
  diver?: ClubDiver;
}> {
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;
  const updateState: Partial<DiverBookingPageUpdateState> = {
    hasChanges: true,
    bookingsChanges: [],
    diversChanges: [],
    bookingMembersChanges: [],
  };

  const { diver, booking } = createBookingAndContactParticipant({
    diveCenterResume,
    formValue,
    registrationMode,
    updateState,
  });
  if (registrationMode === 'multiple') {
    createOtherParticipants({
      bookingId: booking._id,
      diveCenterResume,
      formValue,
      updateState,
    });
  }
  return diverBookingPageClientUpdator
    .persistChanges(updateState, {
      logContext: 'create booking initial plan',
      bookingIdsToClean: [],
    })
    .then(
      () => {
        return {
          success: true,
          diver,
          booking,
        };
      },
      (err) => {
        // eslint-disable-next-line no-console

        appWebLogger.captureMessage(
          'Error while trying to persist initial booking contact',
          {
            logContext: 'createBookingInitialPlanPagePersister',
            clubReference,
            extra: {
              err,
              updateState,
            },
          },
        );
        if (appWebConfig.envId === 'dev') {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
          });
        }
        return {
          success: false,
        };
      },
    );
}

function createBookingAndContactParticipant({
  diveCenterResume,
  registrationMode,
  formValue,
  updateState,
}: {
  diveCenterResume: DiveCenterResume;
  registrationMode: BookingInitialPlanRegistrationMode;
  formValue: BookingInitialPlanEditFormModel;
  updateState: Partial<DiverBookingPageUpdateState>;
}) {
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  const {
    diver,
    bookingContactDiverId,
    bookingStatus,
    bookingGroup,
    bookingInitialPlan,
    bookingJourney,
    bookingMember,
    details,
    isSharedBooking,
  } = formValue;

  if (registrationMode === 'multiple') {
    const diverFormValue = clubDiverFormParser.parseFormValueDiver({
      diver: formValue.diver,
    });
    const contactParticipantGroupId =
      formValue.bookingInitialPlan?.contactParticipantGroupId;

    const contactParticipantGroup =
      formValue.bookingInitialPlan?.participantsGroups?.find(
        (x) => x._id === contactParticipantGroupId,
      );
    const mainCertificationReference =
      formValue?.diver?.divingCertification1?.mainCertificationReference ??
      contactParticipantGroup?.certification?.certificationReference;

    const deepDiver =
      formValue?.diver?.divingCertification1?.specialties?.deepDiver ??
      contactParticipantGroup?.certification?.certificationDeepDiver;
    const expectedDive = contactParticipantGroup?.expectedDive;

    const isParticipant = !!contactParticipantGroup;
    const initialPlanParticipantGroupId = contactParticipantGroup?._id;

    const diver = commonEntityBuilder.buildEntity<ClubDiver>({
      ...diverFormValue,
      clubReference,
      mainCertificationReference,
      divingCertification1: {
        orgGroup: undefined,
        mainCertificationReference,
      },
      customerOrigin: formValue?.bookingGroup?.customerOrigin,
    });
    if (expectedDive) {
      // mise à jour de la config par défaut
      diver.defaultDiveConfig = {
        ...expectedDive,
      };
      if (
        diveModeAnalyser.hasParticipantCertificationReference(
          expectedDive.diveMode,
        )
      ) {
        // NOTE: il faudrait peut-être aussi gérer autoSupervisedDetails ?
        diver.defaultDiveConfig.certificationReference =
          mainCertificationReference;
        diver.defaultDiveConfig.certificationDeepDiver = deepDiver;
      }

      const isScubaDivingEquipmentRequired =
        diveModeAnalyser.hasScubaDivingEquipments(expectedDive.diveMode);
      const defaultGaz = diveCenterResume?.publicSettings?.diving?.defaultGaz;
      if (
        isScubaDivingEquipmentRequired &&
        (defaultGaz?.gazType ||
          defaultGaz?.gazQuantity ||
          defaultGaz?.gazDescription)
      ) {
        diver.defaultDiveConfig.gaz = defaultGaz;
      }
    }
    updateState.diversChanges = changeDescriptorManager.createOne(diver, {
      changeDescriptors: [],
    });

    const booking = diverBookingCreator.createBooking({
      clubReference,
      diveCenterId,
      bookingContactDiverId: diver._id,
      bookingStatus: formValue.bookingStatus,
      bookingJourney: formValue.bookingJourney,
      bookingInitialPlan: formValue.bookingInitialPlan,
      bookingGroup: formValue.bookingGroup,
      details: formValue.details,
      publicSettings: diveCenterResume?.clubResume.clubSettings.publicSettings,
    });
    updateState.bookingsChanges = changeDescriptorManager.createOne(booking, {
      changeDescriptors: [],
    });

    const newBookingMember =
      diverBookingMemberCreator.createBookingMemberFromDiver({
        diver,
        bookingId: booking._id,
        clubReference,
        diveCenterId,
      });

    newBookingMember.details = {
      isParticipant,
      initialPlanParticipantGroupId,
      expectedDive,
      creationStatus: registrationMode === 'multiple' ? 'draft' : 'ok',
    };
    updateState.bookingMembersChanges = changeDescriptorManager.createOne(
      newBookingMember,
      {
        changeDescriptors: [],
      },
    );
    return { diver, booking };
  } else {
    // SINGLE PARTICIPANT
    const diverFormValue = clubDiverFormParser.parseFormValueDiver({
      diver: formValue.diver,
    });

    const bookingMemberFormValue =
      bookingMemberFormParser.parseFormValueBookingMember({
        formValue,
        initialDiver: {} as any,
        finalDiver: diverFormValue,
      });

    const { diver, booking, bookingMember } =
      initialBookingBuilder.buildNewDiverAndBooking({
        clubReference,
        diveCenterId,
        diverProps: diverFormValue,
        bookingProps: {
          bookingStatus: formValue.bookingStatus,
        },
        bookingMemberProps: bookingMemberFormValue,
        bookingMemberCreationStatus: 'ok',
        publicSettings:
          diveCenterResume?.clubResume.clubSettings.publicSettings,
      });

    updateState.diversChanges = changeDescriptorManager.createOne(diver, {
      changeDescriptors: [],
    });

    updateState.bookingsChanges = changeDescriptorManager.createOne(booking, {
      changeDescriptors: [],
    });

    updateState.bookingMembersChanges = changeDescriptorManager.createOne(
      bookingMember,
      {
        changeDescriptors: [],
      },
    );
    return { diver, booking };
  }
}

function createOtherParticipants({
  bookingId,
  formValue,
  updateState,
  diveCenterResume,
}: {
  bookingId: string;
  formValue: BookingInitialPlanEditFormModel;
  updateState: Partial<DiverBookingPageUpdateState>;
  diveCenterResume: DiveCenterResume;
}) {
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  const contactParticipantGroupId =
    formValue.bookingInitialPlan?.contactParticipantGroupId;

  const participantsGroups =
    formValue.bookingInitialPlan?.participantsGroups?.filter(
      (x) => x.participantsCount > 0,
    );

  const baseName =
    formValue?.bookingGroup?.name ??
    formValue?.diver?.lastName ??
    formValue?.diver?.firstName;

  if (participantsGroups?.length) {
    let memberIndex = 1;
    for (const participantGroup of participantsGroups) {
      let massiveCreationQuantity = participantGroup.participantsCount;
      if (contactParticipantGroupId === participantGroup._id) {
        massiveCreationQuantity -= 1;
      }

      for (let i = 0; i < massiveCreationQuantity; i++) {
        // const firstName = `N°${numberFormatter.toFixedIntegerString(
        //   memberIndex++,
        //   2,
        // )}`;

        const mainCertificationReference =
          participantGroup.certification?.certificationReference;
        const deepDiver =
          participantGroup?.certification?.certificationDeepDiver;

        const diver = commonEntityBuilder.buildEntity<ClubDiver>({
          clubReference,
          mainCertificationReference,
          divingCertification1: {
            orgGroup: undefined,
            mainCertificationReference,
          },
          customerOrigin: formValue?.bookingGroup?.customerOrigin,
          fakeName: {
            memberIndex: memberIndex++,
            baseName,
            bookingId,
            // group:
            //   formValue?.bookingGroup?.name || formValue?.bookingGroup?.type
            //     ? {
            //         name: formValue?.bookingGroup?.name,
            //         type: formValue?.bookingGroup?.type,
            //       }
            //     : undefined,
            // contact: {
            //   firstName: formValue?.diver?.firstName,
            //   lastName: formValue?.diver?.lastName,
            // },
          },
        });
        const expectedDive = participantGroup.expectedDive;
        diver.defaultDiveConfig = {
          ...expectedDive,
        };
        if (
          expectedDive.diveMode === 'autonomous' ||
          expectedDive.diveMode === 'supervised' ||
          expectedDive.diveMode === 'instructor'
        ) {
          diver.defaultDiveConfig.certificationReference =
            mainCertificationReference;
          diver.defaultDiveConfig.certificationDeepDiver = deepDiver;
        }
        const isScubaDivingEquipmentRequired =
          diveModeAnalyser.hasScubaDivingEquipments(expectedDive.diveMode);
        const defaultGaz = diveCenterResume?.publicSettings?.diving?.defaultGaz;
        if (
          isScubaDivingEquipmentRequired &&
          (defaultGaz?.gazType ||
            defaultGaz?.gazQuantity ||
            defaultGaz?.gazDescription)
        ) {
          diver.defaultDiveConfig.gaz = defaultGaz;
        }

        updateState.diversChanges = changeDescriptorManager.createOne(diver, {
          changeDescriptors: updateState.diversChanges,
        });
        const newBookingMember =
          diverBookingMemberCreator.createBookingMemberFromDiver({
            diver,
            bookingId,
            clubReference,
            diveCenterId,
          });
        const initialPlanCertification = participantGroup.certification;
        const hasCertification: boolean =
          DIVE_MODE_WITH_CERTIFICATION.includes(expectedDive?.diveMode) ||
          (!!initialPlanCertification?.certificationReference &&
            initialPlanCertification?.certificationReference !== 'DEB');

        newBookingMember.details = {
          isParticipant: true,
          initialPlanParticipantGroupId: participantGroup._id,
          expectedDive,
          initialPlanCertification,
          hasCertification,
          creationStatus: 'draft',
        };
        updateState.bookingMembersChanges = changeDescriptorManager.createOne(
          newBookingMember,
          {
            changeDescriptors: updateState.bookingMembersChanges,
          },
        );
      }
    }
  }
}
