import React from 'react';
import { Switch } from 'react-router-dom';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { ClubSettingsAccountCompanyInfoPage } from './ClubSettingsAccountCompanyInfoPage';
import { ClubSettingsServicesPage } from './ClubSettingsServices';
import {
  ClubSettingsUserAccountEditPage,
  ClubSettingsUserAccountsListPage,
  ClubSettingsUserProfileEditPage,
} from './ClubSettingsUserAccounts';
import {
  ClubSettingsEquipmentEditPage,
  ClubSettingsEquipmentsListPage,
} from './equipment';

export const ClubSettingsAccountRoutes = React.memo(
  function ClubSettingsSecurityRoutesMemo() {
    const { match } = useRouter();
    return (
      <Switch>
        <AppRoute
          path={`${match.url}/user-accounts`}
          exact
          component={ClubSettingsUserAccountsListPage}
          auth={{ requiredRoles: ['club-edit-settings-user-accounts'] }}
        />

        <AppRoute
          path={`${match.url}/company-services`}
          component={ClubSettingsServicesPage}
          auth={{ requiredRoles: ['club-view-settings'] }}
        />
        <AppRoute
          path={`${match.url}/company-info`}
          component={ClubSettingsAccountCompanyInfoPage}
          auth={{ requiredRoles: ['club-edit-settings'] }}
        />
        <AppRoute
          path={`${match.url}/user-accounts/nouveau`}
          component={ClubSettingsUserAccountEditPage}
          navigationContext={{ mode: 'create' }}
          auth={{ requiredRoles: ['club-view-settings-user-accounts'] }}
        />
        <AppRoute
          path={`${match.url}/user-accounts/:userAccountId/edit`}
          exact
          component={ClubSettingsUserAccountEditPage}
          navigationContext={{ mode: 'edit' }}
          auth={{ requiredRoles: ['club-edit-settings-user-accounts'] }}
        />
        <AppRoute
          path={`${match.url}/user-profiles/nouveau`}
          exact
          component={ClubSettingsUserProfileEditPage}
          navigationContext={{ mode: 'create' }}
          auth={{ requiredRoles: ['club-edit-settings-user-profiles'] }}
        />
        <AppRoute
          path={`${match.url}/user-profiles/:clubProfileId/edit`}
          exact
          component={ClubSettingsUserProfileEditPage}
          navigationContext={{ mode: 'edit' }}
          auth={{ requiredRoles: ['club-edit-settings-user-profiles'] }}
        />

        <AppRoute
          path={`${match.url}/equipments`}
          exact
          component={ClubSettingsEquipmentsListPage}
          checkAuth={({ az }) => az?.edit?.settings?.equipment}
        />
        <AppRoute
          path={`${match.url}/equipments/:equipmentType/edit`}
          exact
          component={ClubSettingsEquipmentEditPage}
          checkAuth={({ az }) => az?.edit?.settings?.equipment}
        />
        {/* default route */}
        <AppRoute
          path={`${match.url}/`}
          exact={false}
          redirectToUrl="/club/settings"
        />
      </Switch>
    );
  },
);
