import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppToggleButtonGroupPropsV2 } from './AppToggleButtonGroupPropsV2.type';
import { AppToogleButtonGroupTailwindV2Option } from './AppToogleButtonGroupTailwindV2Option';
import {
  AppToogleButtonGroupTailwindV2OptionStyleBuilder,
  appToogleButtonGroupTailwindV2OptionStyleBuilder,
} from './appToogleButtonGroupTailwindV2OptionStyleBuilder.service';

export function AppToogleButtonGroupTailwindV2<
  T extends string | number | boolean,
>({
  customClassName,
  selected,
  onChanges,
  items,
  className,
  size = 'lg',
  buildOptionStyle: buildOptionStyleInput,
}: AppToggleButtonGroupPropsV2<T>) {
  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const buildOptionStyle: AppToogleButtonGroupTailwindV2OptionStyleBuilder =
    buildOptionStyleInput ??
    appToogleButtonGroupTailwindV2OptionStyleBuilder.buildStyle;

  return (
    <RadioGroup
      value={selected}
      onChange={(item) => {
        onChanges((item as any)?.value);
      }}
      className={`mx-0.5 mt-2 ${className}`}
    >
      <RadioGroup.Label className="sr-only">Chosir une option</RadioGroup.Label>
      <div
        className={clsx(
          'gap-x-3 gap-y-1',
          customClassName ??
            (items.length === 2
              ? 'grid grid-cols-2'
              : 'flex flex-col sm:grid sm:grid-cols-3'),
        )}
      >
        {items.map((option, i) => (
          <AppToogleButtonGroupTailwindV2Option
            key={i}
            option={option}
            selected={selected}
            size={size}
            buildOptionStyle={buildOptionStyle}
          />
        ))}
      </div>
    </RadioGroup>
  );
}
